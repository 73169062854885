import { StatusDashboardComponent } from "./status-dashboard/status-dashboard.component";
import { PreferentialPricingComponent } from "./setup/preferential-pricing/preferential-pricing.component";
import { AdminEndJourneyComponent } from "./setup/journey-start/admin-end-journey/admin-end-journey.component";
import { VehicleDriverMap } from "./../../interfaces/armada";
import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { SituationTypeComponent } from "./setup/situation-type/situation-type.component";
import { CommanderRankComponent } from "./setup/commander-rank/commander-rank.component";
import { CommanderRegistrationComponent } from "./mobility-administration/commander-registration/commander-registration.component";
import { ServiceRequestComponent } from "./mobility-administration/service-request/service-request.component";
import { CloseJourneyComponent } from "./mobility-administration/close-journey/close-journey.component";
import { ActiveServiceComponent } from "./dashboard/active-service/active-service.component";
import { ArmedEscortRankComponent } from "./setup/armed-escort-rank/armed-escort-rank.component";
import { ArmedEscortTypeComponent } from "./setup/armed-escort-type/armed-escort-type.component";
import { ArmedEscortProfileComponent } from "./setup/armed-escort-profile/armed-escort-profile.component";
import { CompletedJourneyComponent } from "./mobility-administration/completed-journey/completed-journey.component";
import { MyCompletedJourneyComponent } from "./mobility-administration/my-completed-journey/my-completed-journey.component";
import { CommanderTypeComponent } from "./setup/commander-type/commander-type.component";
import { ResourceTypeSetupComponent } from "./setup/resource-type-setup/resource-type-setup.component";
import { RankSettingsComponent } from "./setup/rank-settings/rank-settings.component";
import { ResourceRegistrationComponent } from "./setup/resource-registration/resource-registration.component";
import { RouteregionSegmentComponent } from "./setup/routeregion-segment/routeregion-segment.component";
import { ServiceRegistrationComponent } from "./setup/service-registration/service-registration.component";
import { BusinessRuleComponent } from "./setup/business-rule/business-rule.component";
import { PriceRegisterComponent } from "./setup/price-register/price-register.component";
import { ResourceTieComponent } from "./setup/resource-tie/resource-tie.component";
import { ResourceSchedulingComponent } from "./setup/resource-scheduling/resource-scheduling.component";
import { MasterServiceAssignmentComponent } from "./setup/master-service-assignment/master-service-assignment.component";
import { JourneyStartComponent } from "./setup/journey-start/journey-start.component";
import { RouteStateMapsComponent } from "./setup/routeregion-segment/route-state-maps/route-state-maps.component";
import { ResourceLookupComponent } from "./setup/resource-lookup/resource-lookup.component";
import { VehiclePilotMapComponent } from "./setup/vehicle-pilot-map/vehicle-pilot-map.component";

const routes: Routes = [
  {
    path: "active-service",
    component: ActiveServiceComponent,
  },
  {
    path: "status-dashboard",
    component: StatusDashboardComponent,
  },
  {
    path: "setup",
    children: [
      { path: "situation-type", component: SituationTypeComponent },
      { path: "commander-rank", component: CommanderRankComponent },
      { path: "commander-type", component: CommanderTypeComponent },
      { path: "armed-escort-rank", component: ArmedEscortRankComponent },
      { path: "armed-escort-type", component: ArmedEscortTypeComponent },
      { path: "armed-escort-profile", component: ArmedEscortProfileComponent },
      { path: "resource-type-setup", component: ResourceTypeSetupComponent },
      { path: "rank-settings", component: RankSettingsComponent },
      {
        path: "resource-registration",
        component: ResourceRegistrationComponent,
      },
      {
        path: "resource-lookup",
        component: ResourceLookupComponent,
      },
      { path: "routeregion-segment", component: RouteregionSegmentComponent },
      { path: "service-registration", component: ServiceRegistrationComponent },
      { path: "business-rule", component: BusinessRuleComponent },
      { path: "price-register", component: PriceRegisterComponent },
      { path: "preferential-pricing", component: PreferentialPricingComponent },
      { path: "resource-tie", component: ResourceTieComponent },
      { path: "resource-scheduling", component: ResourceSchedulingComponent },
      {
        path: "master-service-assignment",
        component: MasterServiceAssignmentComponent,
      },
      {
        path: "journey-start",
        component: JourneyStartComponent,
      },
      {
        path: "route-state-maps",
        component: RouteStateMapsComponent,
      },
      {
        path: "vehicle-driver-mapping",
        component: VehiclePilotMapComponent,
      },
      {
        path: "admin-end-journey",
        component: AdminEndJourneyComponent,
      },
    ],
  },
  {
    path: "mobility-administration",
    children: [
      {
        path: "commander-registration",
        component: CommanderRegistrationComponent,
      },
      { path: "service-request", component: ServiceRequestComponent },
      { path: "close-journey", component: CloseJourneyComponent },
      { path: "journey-history", component: CompletedJourneyComponent },
      { path: "my-completed-journey", component: MyCompletedJourneyComponent },
    ],
  },
  {
    path: "",
    redirectTo: "active-service",
    pathMatch: "full",
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ArmadaRoutingModule {}
