import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {Observable} from 'rxjs';
import {ServiceAssignment, SmtpMailer} from '../../interfaces/armada';

@Injectable({
  providedIn: 'root'
})
export class ServiceAssignmentService {
  constructor(
      private httpClient: HttpClient
  ) { }
  baseUrl = environment.armadaBaseUrl+'ServiceAssignment/';
  allServiceAssignment(): Observable<ServiceAssignment>{
    return this.httpClient.get<ServiceAssignment>(this.baseUrl);
  }
  getServiceAssignment(id: any):Observable<ServiceAssignment>{
    return this.httpClient.get<ServiceAssignment>(this.baseUrl +id);
  }
  getServiceAssignmentByName(name: any):Observable<ServiceAssignment>{
    return this.httpClient.get<ServiceAssignment>(this.baseUrl + 'name/' + name);
  }
  postServiceAssignment(data: any){
    return this.httpClient.post(this.baseUrl, data);
  }
  deleteServiceAssignment(id: any){
    return this.httpClient.delete(this.baseUrl +id);
  }
  updateServiceAssignment(id: any, data: any){
    return this.httpClient.put(this.baseUrl +id, data);
  }
  dashboardStat() {
    return this.httpClient.get(this.baseUrl + 'DashboardStatistics');
  }
  getServiceAssignmentInfo(id) {
    return this.httpClient.get(this.baseUrl + 'GetServiceAssignmentInfo/' + id);
  }
  getUnAssignedVehicles() {
    return this.httpClient.get(this.baseUrl + 'GetUnAssignedVehicles/');
  }
  getSuppliersWithUnAssignedVehicles() {
    return this.httpClient.get(this.baseUrl + 'GetSuppliersWithUnAssignedVehicles/');
  }
  getMyCompletedJourneysByCommanderId(commanderId: number): Observable<ServiceAssignment[]> {
    return this.httpClient.get<ServiceAssignment[]>(`${this.baseUrl}GetMyCompletedJourneysByCommanderId/${commanderId}`);
  }
  getMyCompletedJourneys(): Observable<ServiceAssignment[]> {
    return this.httpClient.get<ServiceAssignment[]>(`${this.baseUrl}GetMyCompletedJourneys`);
  }
  sendEmailToClient(data: SmtpMailer) {
    return this.httpClient.post(environment.baseMailUrl + 'SendSMTPMail', data);
  }
}
