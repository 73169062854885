import { CurrencyService } from "./../../../services/currency.service";
import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { ConfirmationService, MessageService } from "primeng/api";
import { BreadcrumbService } from "src/app/breadcrumb.service";
import {
  CreateCurrencyExchangeRateVM,
  CreateCurrencyVM,
  Currency,
  CurrencyExchangeRate,
  UpdateCurrencyVM,
} from "src/app/interfaces/currency";

@Component({
  selector: "app-currency-setup",
  templateUrl: "./currency-setup.component.html",
  styleUrls: ["./currency-setup.component.scss"],
})
export class CurrencySetupComponent implements OnInit {
  @ViewChild("formWrapper") public formWrapper: ElementRef;
  currencyForm: FormGroup;
  exchangeRateForm: FormGroup;
  editingCurrency: boolean;
  fetchingCurrencies: boolean;
  fetchingExchangeRates: boolean;
  allCurrencies: Currency[] = [];
  allExchangeRates: CurrencyExchangeRate[];
  selectedCurrencies: Currency;
  selectedExchangeRates: CurrencyExchangeRate;
  currencyCols: any[];
  theBaseCurrency: Currency;
  theQuoteCurrency: Currency;
  exchangeRateCols: any[];
  currencyToEdit: Currency;

  constructor(
    private fb: FormBuilder,
    private currencyService: CurrencyService,
    private breadcrumbService: BreadcrumbService,
    public confirmationService: ConfirmationService,
    public messageService: MessageService
  ) {
    this.currencyForm = fb.group({
      Caption: ["", Validators.required],
      Description: ["", Validators.required],
      Symbol: ["", Validators.required],
    });

    this.exchangeRateForm = fb.group({
      BaseCurrency: ["", Validators.required],
      QuoteCurrency: ["", Validators.required],
      ExchangeRate: ["", Validators.required],
    });
  }

  ngOnInit(): void {
    this.breadcrumbService.setItems([
      {
        label: "Setup",
      },
      {
        label: "Currency Setup",
        routerLink: ["/home/currency-setup"],
      },
    ]);

    this.currencyCols = [
      { field: "name", header: "Caption" },
      { field: "symbol", header: "Symbol" },
      { field: "description", header: "Description" },
    ];

    this.exchangeRateCols = [
      { field: "GetCurrencyLabel(baseCurrencyId)", header: "Base Currency" },
      { field: "GetCurrencyLabel(quoteCurrencyId)", header: "Quote Currency" },
      { field: "exchangeRate", header: "Rate" },
    ];

    this.FetchAllCurrencies();
    this.FetchAllExchangeRates();
  }

  FetchAllCurrencies() {
    this.fetchingCurrencies = true;
    this.currencyService.GetAllCurrencies().subscribe(
      async (data) => {
        this.allCurrencies = data;
        this.fetchingCurrencies = false;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all currencies at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
        this.fetchingCurrencies = false;
      }
    );
  }

  FetchAllExchangeRates() {
    this.fetchingExchangeRates = true;
    this.currencyService.GetAllCurrencyExchangeRates().subscribe(
      async (data) => {
        this.allExchangeRates = data;
        this.fetchingExchangeRates = false;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all exchange rates at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
        this.fetchingExchangeRates = false;
      }
    );
  }

  CreateCurrency() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Creating Currency...",
    });

    const postData: CreateCurrencyVM = {
      caption: this.currencyForm.get("Caption").value,
      symbol: this.currencyForm.get("Symbol").value,
      description: this.currencyForm.get("Description").value,
    };

    this.currencyService.CreateCurrency(postData).subscribe(
      async () => {
        this.messageService.add({
          severity: "success",
          summary: "Completed",
          detail: "Currency Created Successfully!",
        });

        this.currencyForm.reset();
        this.FetchAllCurrencies();
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to create currency at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  CloseEditingCurrencyForm() {
    this.currencyForm.reset();
    this.editingCurrency = false;
    this.currencyToEdit = null;
  }

  UpdateCurrency() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Updating Currency...",
    });

    const id = this.currencyToEdit.id;
    const postData: UpdateCurrencyVM = {
      caption: this.currencyForm.get("Caption").value,
      symbol: this.currencyForm.get("Symbol").value,
      description: this.currencyForm.get("Description").value,
    };

    this.currencyService.UpdateCurrency(id, postData).subscribe(
      async () => {
        this.messageService.add({
          severity: "success",
          summary: "Completed",
          detail: "Currency Updated Successfully!",
        });

        this.CloseEditingCurrencyForm();
        this.FetchAllCurrencies();
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to update currency at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  DeleteCurrency(item: Currency) {
    this.confirmationService.confirm({
      message: "Are you sure you want to remove currency?",
      accept: () => {
        this.messageService.add({
          severity: "info",
          summary: "Notice",
          detail: "Removing currency...",
        });

        this.currencyService.DeleteCurrency(item.id).subscribe(
          async () => {
            this.messageService.add({
              severity: "success",
              summary: "Removed",
              detail: "Removed successfully",
            });
            this.FetchAllCurrencies();
          },
          (error) => {
            console.log("Error: " + JSON.stringify(error));
            this.messageService.add({
              severity: "error",
              summary: "Notice",
              detail:
                "Unable to remove currency at the moment.. Reason: [" + error
                  ? error.error.message
                  : "request failed - permission" + "]",
            });
          }
        );
      },
    });
  }

  EditCurrency(item: Currency) {
    this.editingCurrency = true;
    this.currencyForm.patchValue({
      Caption: item.name,
      Symbol: item.symbol,
      Description: item.description,
    });
    this.currencyToEdit = item;

    this.formWrapper.nativeElement.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "start",
    });
  }

  CreateExchangeRate() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Creating Exchange Rate...",
    });

    if (this.theBaseCurrency == this.theQuoteCurrency) {
      this.messageService.add({
        severity: "error",
        summary: "Notice",
        detail: "Base currency must not be the same as quote currency",
      });
      return;
    }

    const postData: CreateCurrencyExchangeRateVM = {
      baseCurrencyId: this.theBaseCurrency.id,
      quoteCurrencyId: this.theQuoteCurrency.id,
      exchangeRate: this.exchangeRateForm.get("ExchangeRate").value,
    };

    this.currencyService.CreateCurrencyExchangeRate(postData).subscribe(
      async () => {
        this.messageService.add({
          severity: "success",
          summary: "Completed",
          detail: "Exchange Rate Added Successfully!",
        });

        this.exchangeRateForm.reset();
        this.theBaseCurrency = null;
        this.theQuoteCurrency = null;
        this.FetchAllExchangeRates();
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to create exchange rate at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  GetCurrencyLabel(currencyId: number): string {
    let currency = this.allCurrencies.find((x) => x.id == currencyId);
    if (currency) return currency.name;

    return "N/A";
  }
}
