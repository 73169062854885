import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

@Injectable()
export class SalesDashboardService {
  

  constructor(private http:HttpClient) { }




  getAllDivision():Observable<any>{
    return this.http.get(
      environment.baseUrl + "Division"
    )
  }
  getAllServiceGroups():Observable<any>{
    return this.http.get(
      environment.baseUrl + "ServiceGroup"
    )
  }
  getAllServiceCategories():Observable<any>{
    return this.http.get(
      environment.baseUrl + "ServiceCategory"
    )
  }
  getAllServices():Observable<any>{
    return this.http.get(
      environment.baseUrl + "Services"
    )
  }

  getAmortization(year:any,month:any):Observable<any>{
    return this.http.get(
      environment.baseUrl + "ProjectManagement/GetAmortizationMaster/" + year + "/" + month
    )
  }

  geMarkets():Observable<any>{
    return this.http.get(
      environment.baseUrl + "OperatingEntity"
    )
  }

  getAmortizationDetails():Observable<any>{
    return this.http.get(
      environment.baseUrl + "ProjectManagement/GetAmortizationDetails"
    )
  }

  getEndorsementType():Observable<any>{
    return this.http.get(
      environment.baseUrl + "EndorsementType"
    )
  }
  getAllCustomerDivison(): Observable<any> {
    return this.http.get(
      environment.baseUrl +
        "ProjectManagement/GetCustomerDivision"
    );
  }
 

}
 