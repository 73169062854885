import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { MessageService, ConfirmationService } from "primeng/api";
import { BreadcrumbService } from "src/app/breadcrumb.service";
import {
  CreateExpenseBudgetDetailVM,
  CreateExpenseBudgetVM,
  ExpenseLine,
  ExpenseLineBudget,
  GetAllExpenseBudgetInitItemsYearVM,
  GetAllExpenseBudgetsVM,
} from "src/app/interfaces/finance-expense";
import { ServiceCategory } from "src/app/interfaces/home";
import { FinanceExpenseService } from "src/app/services/finance-expense.service";
import { ServiceCategoryService } from "src/app/services/service-category.service";

@Component({
  selector: "app-expense-budgets",
  templateUrl: "./expense-budgets.component.html",
  styleUrls: ["./expense-budgets.component.scss"],
})
export class ExpenseBudgetsComponent implements OnInit {
  @ViewChild("formWrapper") public formWrapper: ElementRef;
  expenseBudgetForm: FormGroup;
  viewBudgetForm: FormGroup;
  allServiceCategories: ServiceCategory[];
  selectedServiceCategories: ServiceCategory[] = [];
  allYears: GetAllExpenseBudgetInitItemsYearVM[];
  allNewBudgetYears: GetAllExpenseBudgetInitItemsYearVM[];
  selectedYears: GetAllExpenseBudgetInitItemsYearVM[] = [];
  theYear: GetAllExpenseBudgetInitItemsYearVM;
  allExpenseLines: ExpenseLine[];
  allExpenseLinesToShow: ExpenseLine[];
  selectedExpenseLines: ExpenseLine[] = [];
  fetchingExpenseBudgets: boolean;
  allExpenseBudgets: ExpenseLineBudget[];
  selectedExpenseBudgets: ExpenseLineBudget[] = [];
  expenseBudgetsCols: any[];
  openBudgetDialogue: boolean;
  selectedNewExpenseLines: ExpenseLine[] = [];
  allNewExpenseBudgets: CreateExpenseBudgetDetailVM[] = [];

  constructor(
    fb: FormBuilder,
    public financeExpenseService: FinanceExpenseService,
    public serviceCategoryService: ServiceCategoryService,
    public messageService: MessageService,
    private breadcrumbService: BreadcrumbService,
    public confirmationService: ConfirmationService
  ) {
    this.viewBudgetForm = fb.group({
      Year: ["", Validators.required],
      ServiceCateg: [""],
      ExpenseLine: ["", Validators.required],
    });

    this.expenseBudgetForm = fb.group({
      Year: ["", Validators.required],
      StartPeriod: ["", Validators.required],
      EndPeriod: ["", Validators.required],
      ExpenseLine: ["", Validators.required],
    });
  }

  ngOnInit(): void {
    this.breadcrumbService.setItems([
      {
        label: "Finance",
        routerLink: ["/home/finance"],
      },
      {
        label: "Expense Tool",
        routerLink: ["/home/finance/expense"],
      },
      {
        label: "Expense Budgets",
        routerLink: ["/home/finance/expense/budgets"],
      },
    ]);

    this.expenseBudgetsCols = [
      { field: "expenseLine", header: "Expense" },
      { field: "description", header: "Description" },
      { field: "accountMap", header: "Expense Account" },
      { field: "budget", header: "Expense Budget" },
      { field: "amountUsed", header: "Amount Used" },
      { field: "balance", header: "Balance" },
    ];

    this.GetAllServiceCategories();
    this.GetAllExpenseBudgetsInits();
  }

  async GetAllServiceCategories() {
    this.serviceCategoryService.GetAllServiceCategories().subscribe(
      async (data) => {
        if (data.responseCode == "00") {
          this.allServiceCategories = data.responseData;
          this.allServiceCategories.push({ name: "ALL" });
        } else {
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail: data.responseMsg,
          });
        }
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all service categories at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  async GetAllExpenseBudgetsInits() {
    this.financeExpenseService.GetAllExpenseBudgetInitItems().subscribe(
      async (data) => {
        if (data.responseCode == "00") {
          this.allYears = data.responseData.years;
          this.allExpenseLines = data.responseData.budgetExpenseLines;
          this.allExpenseLinesToShow = data.responseData.expenseLines;
          this.allYears.push({ year: "ALL", yearDate: null });
          this.allExpenseLinesToShow.push({ caption: "ALL" });
          this.allNewBudgetYears = data.responseData.newBudgetYears;
        } else {
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail: data.responseMsg,
          });
        }
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all expense budget inits at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  ViewBudgets() {
    this.fetchingExpenseBudgets = true;
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Fetching Expense Line Budgets...",
    });

    const postData: GetAllExpenseBudgetsVM = {
      useYearFilter: this.selectedYears.find((x) => x.year == "ALL") == null,
      years:
        this.selectedYears.find((x) => x.year == "ALL") == null
          ? this.selectedYears.map((x) => x.year)
          : null,
      useExpenseLineFilter:
        this.selectedExpenseLines.find((x) => x.caption == "ALL") == null,
      expenseLines:
        this.selectedExpenseLines.find((x) => x.caption == "ALL") == null
          ? this.selectedExpenseLines.map((x) => x.id)
          : null,
    };

    this.financeExpenseService.GetAllExpenseBudgets(postData).subscribe(
      async (data) => {
        if (data.responseCode != "00") {
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail: data.responseMsg,
          });
          this.fetchingExpenseBudgets = false;
          return;
        }

        this.allExpenseBudgets = data.responseData;
        this.fetchingExpenseBudgets = false;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to get all expense budgets at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
        this.fetchingExpenseBudgets = false;
      }
    );
  }

  OpenAddNewBudget() {
    this.expenseBudgetForm.reset();
    this.theYear = null;
    this.selectedNewExpenseLines = [];
    this.allNewExpenseBudgets = [];
    this.openBudgetDialogue = true;
  }

  HideBudgetDialog() {
    this.expenseBudgetForm.reset();
    this.theYear = null;
    this.selectedNewExpenseLines = [];
    this.allNewExpenseBudgets = [];
    this.openBudgetDialogue = false;
  }

  CreateExpenseBudgets() {
    this.selectedNewExpenseLines.forEach((expenseLine) => {
      let expenseLineBudget = this.allNewExpenseBudgets.find(
        (x) => x.expenseLineId == expenseLine.id && x.year == this.theYear.year
      );
      // if (expenseLineBudget == null)
      {
        let expenseBudget: CreateExpenseBudgetDetailVM = {
          year: this.theYear.year,
          yearDate: this.theYear.yearDate,
          startPeriod: this.expenseBudgetForm.get("StartPeriod").value,
          endPeriod: this.expenseBudgetForm.get("EndPeriod").value,
          expenseLineId: expenseLine.id,
          budgetAmount: 0,
        };
        if (expenseBudget.startPeriod > expenseBudget.endPeriod) {
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail: "End Period must be greater than start period",
          });
          return;
        }

        this.allNewExpenseBudgets.push(expenseBudget);
      }
      // else {
      //   this.messageService.add({
      //     severity: "error",
      //     summary: "Notice",
      //     detail: "Expense Line for the selected year has already been added",
      //   });
      //   return;
      // }
    });

    this.expenseBudgetForm.reset();
    this.theYear = null;
    this.selectedNewExpenseLines = [];
  }

  RemoveExpenseBudget(item: CreateExpenseBudgetDetailVM) {
    const index = this.allNewExpenseBudgets.indexOf(item);
    if (index > -1) {
      this.allNewExpenseBudgets.splice(index, 1);
    }
  }

  SubmitExpenseBudgets() {
    this.confirmationService.confirm({
      message: "Are you sure you want to submit this expense budget(s)?",
      accept: () => {
        this.messageService.add({
          severity: "info",
          summary: "Notice",
          detail: "Submitting Expense Budget(s)...",
        });

        const postData: CreateExpenseBudgetVM = {
          budgets: [],
        };

        this.allNewExpenseBudgets.forEach((expenseBudget) => {
          postData.budgets.push({
            year: expenseBudget.year,
            yearDate: expenseBudget.yearDate,
            startPeriod: expenseBudget.startPeriod,
            endPeriod: expenseBudget.endPeriod,
            expenseLineId: expenseBudget.expenseLineId,
            budgetAmount: expenseBudget.budgetAmount,
          });
        });

        this.financeExpenseService.CreateExpenseBudget(postData).subscribe(
          async (data) => {
            if (data.responseCode != "00") {
              this.messageService.add({
                severity: "error",
                summary: "Notice",
                detail: data.responseMsg,
              });
              return;
            }

            this.HideBudgetDialog();
            this.messageService.add({
              severity: "success",
              summary: "Completed",
              detail: "Expense Budget(s) Submitted Successfully!",
            });
          },
          (error) => {
            console.log("Error: " + JSON.stringify(error));
            this.messageService.add({
              severity: "error",
              summary: "Notice",
              detail:
                "Unable to submit expense budget(s) at the moment.. Reason: [" +
                (error ? error.error.message : "request failed - permission") +
                "]",
            });
          }
        );
      },
    });
  }

  GetExpenseLineLabel(identifier: number) {
    let expenseLine = this.allExpenseLines.find((x) => x.id == identifier);
    if (expenseLine) return expenseLine.caption;

    return "N/A";
  }

  GetExpenseLineDesc(identifier: number) {
    let expenseLine = this.allExpenseLines.find((x) => x.id == identifier);
    if (expenseLine) return expenseLine.description;

    return "N/A";
  }

  GetExpenseLineAccount(identifier: number) {
    let expenseLine = this.allExpenseLines.find((x) => x.id == identifier);
    if (expenseLine) return expenseLine.accountNumber;

    return "N/A";
  }

  GetExpenseLineType(identifier: number) {
    let expenseLine = this.allExpenseLines.find((x) => x.id == identifier);
    if (expenseLine) return expenseLine.type.toString();

    return "N/A";
  }

  GetExpenseBudgetBalance(item: ExpenseLineBudget): number {
    return item.budgetAmount - item.budgetAmountUsed;
  }
}
