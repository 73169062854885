import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import {
  ApprovalComment,
  ApprovalCommentReceipt,
  BulkApprovalComment,
  CheckApprovalComentReadReceiptsVM,
  CreateApprovalCommentVM,
  CreateBulkApprovalCommentVM,
} from "../interfaces/approval-comment";

@Injectable({
  providedIn: "root",
})
export class ApprovalCommentService {
  baseUrl = environment.gmaBaseUrl + "ApprovalComment/";

  constructor(private http: HttpClient) {}

  GetApprovalComments(approvalId: number): Observable<ApprovalComment[]> {
    return this.http.get<ApprovalComment[]>(
      this.baseUrl + "GetApprovalComments/" + approvalId
    );
  }

  CreateApprovalComment(data: CreateApprovalCommentVM) {
    return this.http.post(this.baseUrl + "CreateApprovalComment", data);
  }

  DeleteApprovalComment(id: number) {
    return this.http.delete(this.baseUrl + "DeleteApprovalComment/" + id);
  }

  CreateApprovalCommentReceipt(approvalCommentId: number): Observable<boolean> {
    return this.http.get<boolean>(
      this.baseUrl + "CreateApprovalCommentReceipt/" + approvalCommentId
    );
  }

  GetUserApprovalCommentReceipts(): Observable<ApprovalCommentReceipt[]> {
    return this.http.get<ApprovalCommentReceipt[]>(
      this.baseUrl + "GetUserApprovalCommentReceipts"
    );
  }

  CheckApprovalComentReadReceipts(
    data: CheckApprovalComentReadReceiptsVM
  ): Observable<number[]> {
    return this.http.post<number[]>(
      this.baseUrl + "CheckApprovalComentReadReceipts",
      data
    );
  }

  GetBulkApprovalComments(
    approvalId: number
  ): Observable<BulkApprovalComment[]> {
    return this.http.get<BulkApprovalComment[]>(
      this.baseUrl + "GetBulkApprovalComments/" + approvalId
    );
  }

  CreateBulkApprovalComment(data: CreateBulkApprovalCommentVM) {
    return this.http.post(this.baseUrl + "CreateBulkApprovalComment", data);
  }

  DeleteBulkApprovalComment(id: number) {
    return this.http.delete(this.baseUrl + "DeleteBulkApprovalComment/" + id);
  }
}
