import { Component, OnInit } from "@angular/core";
import { ConfirmationService, MessageService } from "primeng/api";
import { DialogService } from "primeng/dynamicdialog";
import { ProductService } from "src/app/demo/service/productservice";
import { ContactsService } from "../contacts/contacts.service";
import { ActivatedRoute } from "@angular/router";
import { FullCalendar } from "primeng/fullcalendar";
import { EngagementContactsService } from "./engagement-contacts.service";
import { LeadEngagementsService } from "../lead-engagements/lead-engagements.service";
import { DomSanitizer } from "@angular/platform-browser";

@Component({
  selector: "app-engagement-contacts",
  templateUrl: "./engagement-contacts.component.html",
  styleUrls: ["./engagement-contacts.component.scss"],
})
export class EngagementContactsComponent implements OnInit {
  designationList: any[];
  divisionList: any[];
  defaultImage = "https://www.freeiconspng.com/uploads/profile-icon-9.png"
  selectedContactType: any;
  SelectedQualification: any;
  selectedDesignation: any;
  SelectedContact: any;
  data: any;
  contactQualification: { name: string; value: number }[];
  contactPriority: { name: string; value: number }[];
  workspaceIsLoading = false;
  constacts: any[];
  newId: any;
  subscriber: any;
  SuspectInstance: any;
  suspectContact: any[];
  constructor(
    private productService: ProductService,
    private constactsService: ContactsService,
    private route: ActivatedRoute,
    public confirmationService: ConfirmationService,
    public dialogService: DialogService,
    public messageService: MessageService,
    public engagementService: EngagementContactsService,
    public leadEngagementService:LeadEngagementsService,
    private sanitizer: DomSanitizer,

  ) {}

  ngOnInit(): void {
    this.getCustomerDesignationList();
    this.getCustomerDivisionList();
    this.getAllContacts();
    this.contactQualification = [
      { name: "Owner", value: 1 },
      { name: "Influencer", value: 2 },
      { name: "Gate Keeper", value: 3 },
      { name: "Decision Maker", value: 3 },
    ];

    this.contactPriority = [
      { name: "Primary", value: 1 },
      { name: "Secondary", value: 2 },
      { name: "Other", value: 3 },
    ];

    this.subscriber = this.route.paramMap.subscribe((params) => {
      this.newId = params.get("id");
      this.getSuspectById(this.newId);
      this.getAllContactsToSuspect(this.newId);
    });
    console.log(this.newId);
    
  }

  getCustomerDesignationList() {
    this.engagementService.getCustomerDesignationList().subscribe(
      async (res: any) => {
        console.log("res.designation::::",res)
        if (res.responseCode == "00") {
          this.designationList = [];
          this.designationList = res.responseData;
        } else {
          this.designationList = [];
        }
      },
      (error) => {
        this.designationList = [];
      }
    );
  }
  getCustomerDivisionList() {
    this.engagementService.getCustomerDivisionList().subscribe(
      async (res: any) => {
        console.log(res.responseData);
        if (res.responseCode == "00") {
          this.divisionList = [];
          this.divisionList = res.responseData;
        } else {
          this.divisionList = [];
        }
      },
      (error) => {
        this.divisionList = [];
      }
    );
  }


  confirmToDetach(contact) {
    this.confirmationService.confirm({
        message: 'Are you sure that you want to proceed?',
        header: 'Confirmation',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
           this.detachContact(contact)
        },
        reject: (type) => {
        }
    });
}

  detachContact(contact){
    console.log("this is the cotact",contact)
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Currently detaching contact",
    });
    this.workspaceIsLoading = true
    this.engagementService.detachContact(contact.suspectId,contact.contactId)
    .subscribe(async(data:any)=>{
      if(data.responseCode=="00"){
        this.messageService.add({
          severity: "success",
          summary: "Notice",
          detail: "Successfully detached contact",
        });
        this.getAllContactsToSuspect(contact.suspectId);
        this.workspaceIsLoading = false
      }
      else{
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail: "Could not detach contact",
        });
        this.workspaceIsLoading = false
      }
    },error=>{
      this.messageService.add({
        severity: "error",
        summary: "Notice",
        detail: "An error occurred",
      });
      this.workspaceIsLoading = false;
    }
    )
  }

  attachToSuspect(){
    if( this.selectedContactType != null
      &&this.SelectedQualification != null 
      &&this.selectedDesignation != null 
      &&this.SelectedContact != null )
      {
        this.messageService.add({
          severity: "info",
          summary: "Notice",
          detail: "Attaching contact to suspect",
        });

        
        const payload = {
          suspectId:parseInt(this.newId),
          contactId:this.SelectedContact.id,
          contactPriority:this.selectedContactType.value,
          contactDesignation:this.selectedDesignation.id,
          contactQualification:this.SelectedQualification.value,
        }

        console.log("this is the result of payload",payload);
        this.workspaceIsLoading = true;
        this.engagementService.attachContactToSuspect(payload)
        .subscribe(async(res:any)=>{
            if(res.responseCode == "00"){
              console.log("this worked out ",res.responseData);
              this.suspectContact.push(res.responseData);
              this.getAllContactsToSuspect(this.newId);
              this.workspaceIsLoading = false;
              this.selectedContactType = null;
              this.selectedDesignation = null;
              this.SelectedQualification = null;
              this.SelectedContact = null;
              this.messageService.add({
                severity: "success",
                summary: "Notice",
                detail: "Successfully attached contact to suspect",
              });
            }
            else{
              this.workspaceIsLoading = false
              this.messageService.add({
                severity: "error",
                summary: "Notice",
                detail: res.responseMsg,
              });
            }
        },error=>{
          this.workspaceIsLoading = false
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail: "An error occurred",
          })
        }
        )
        
      }
      else{
        return;
      }

     
  }

  transform(image) {
    if(!image)
    return this.sanitizer.bypassSecurityTrustResourceUrl(this.defaultImage);
    else
    return this.sanitizer.bypassSecurityTrustResourceUrl(image);
  }


  getSuspectById(id) {
    this.leadEngagementService
      .getSuspectById(id)
      .subscribe(async (data: any) => {
        if (data.responseCode == "00") {
          this.SuspectInstance = data.responseData
          console.log("this is leads array", this.SuspectInstance);
          this.messageService.add({
            severity: "success",
            summary: "Notice",
            detail: "All leads were fectched successsfully",
          });
        }
      });
  }


  getAllContactsToSuspect(id) {
    this.engagementService.getAllContactsAttachedToSuspects(id).subscribe(
      async (res: any) => {
        console.log("This is from suspectContacts",res.responseData);
        if (res.responseCode == "00") {
          this.suspectContact = [];
          this.suspectContact = res.responseData.filter(x => x.contact.isDeleted == false);
        } else {
          this.suspectContact = [];
        }
      },
      (error) => {
        this.suspectContact = [];
      }
    );
  }

  getAllContacts() {
    this.workspaceIsLoading = true;
    this.engagementService.getContactList().subscribe(
      async (data: any) => {
        if (data.responseCode == "00") {
          this.constacts = data.responseData;
          this.constacts = this.constacts.map((res: any) => {
            return {
              ...res,
              displayLabel: res.firstName + ' ' + res.lastName
            };
          });
          this.workspaceIsLoading = false;
          console.warn("these are the data", this.constacts);
          this.messageService.add({
            severity: "success",
            summary: "Notice",
            detail: "Contacts was fetched successfully",
          });
        } else {
          this.workspaceIsLoading = false;
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail: "No data avaliable",
          });
        }
      },
      (error) => {
        this.workspaceIsLoading = false;
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail: "An error occured",
        });
      }
    );
  }


}
