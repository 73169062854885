import { DeployedGuard } from "./../../../interfaces/employment";
import { Branch, Location } from "./../../../interfaces/home";
import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { MessageService, ConfirmationService, TreeNode } from "primeng/api";
import { BreadcrumbService } from "src/app/breadcrumb.service";
import { GmaEmploymentService } from "src/app/services/gma-employment.service";

@Component({
  selector: "app-gma-zonal-map-view",
  templateUrl: "./gma-zonal-map-view.component.html",
  styleUrls: ["./gma-zonal-map-view.component.scss"],
})
export class GmaZonalMapViewComponent implements OnInit {
  @ViewChild("formWrapper") public formWrapper: ElementRef;
  zoneTree: TreeNode[];
  poolTree: TreeNode[];
  selectedZoneNode: TreeNode;
  allSelectedOperatives: DeployedGuard[];
  totalZones: number = 0;
  totalClusters: number = 0;
  totalLocations: number = 0;
  totalGuards: number = 0;
  selectedNodeData: Location;
  showOperativeList: boolean = false;
  allRegions: Branch[] = [];
  allGuardsInPool: DeployedGuard[] = [];

  constructor(
    private fb: FormBuilder,
    public employmentService: GmaEmploymentService,
    public messageService: MessageService,
    private breadcrumbService: BreadcrumbService,
    public confirmationService: ConfirmationService
  ) {}

  ngOnInit(): void {
    this.breadcrumbService.setItems([
      {
        label: "Guard Management",
        routerLink: ["/home/guard-management/item-settings"],
      },
      {
        label: "Gma Zonal Map View",
        routerLink: ["/home/guard-management/gma-zonal-map-view"],
      },
    ]);

    this.zoneTree = [
      {
        label: "Halogen",
        children: [],
      },
    ];

    this.poolTree = [
      {
        label: "Pool Operatives",
        children: [],
      },
    ];

    this.FetchAllRegions();
    this.FetchZonalMapViewSummary();
    this.AppendPoolOPsToZonalMapView();
  }

  FetchAllRegions() {
    this.employmentService.GetAllZoneMapViewRegions().subscribe(
      async (data) => {
        this.allRegions = data;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch zonal map view regions at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  FetchZonalMapViewSummary() {
    this.employmentService.GetAllZoneMapViewSummary().subscribe(
      async (data) => {
        this.totalZones = data.totalZones;
        this.totalClusters = data.totalClusters;
        this.totalLocations = data.totalLocations;
        this.totalGuards = data.totalGuards;

        this.zoneTree = [
          {
            label: "Halogen",
            children: [],
          },
        ];

        let allRegionTree: any[] = [];
        this.allRegions.forEach((region) => {
          let treeRegion = {
            key: "00",
            label: region.name,
            data: region,
            icon: "pi pi-globe",
            children: [],
            selectable: false,
          };

          region.zones = data.zones.filter((x) => x.regionId == region.id);
          let allZoneTree: any[] = [];
          let countZone = 0;
          region.zones.forEach((zone) => {
            let treeZone = {
              key: countZone,
              label: zone.caption,
              data: "Documents Folder",
              icon: "pi pi-globe",
              children: [],
              selectable: false,
            };

            let countCluster = 0;
            let allClusterTree: any[] = [];
            zone.clusters.forEach((cluster) => {
              let clusterTree = {
                key: countZone + "-" + countCluster,
                label: cluster.caption,
                data: "Documents Folder",
                icon: "pi pi-compass",
                // icon: "pi pi-fw pi-inbox",
                children: [],
                selectable: false,
              };

              let countLocation = 0;
              let allLocationTree: any[] = [];
              cluster.locations.forEach((location) => {
                let locationTree = {
                  key: countZone + "-" + countCluster + "-" + countLocation,
                  label: location.name,
                  data: "Documents Folder",
                  icon: "pi pi-map-marker",
                  children: [
                    {
                      key:
                        countZone +
                        "-" +
                        countCluster +
                        "-" +
                        countLocation +
                        "-0",
                      label:
                        data.guardDeployments.filter(
                          (x) => x.locationId == location.id
                        ).length + " operative(s)",
                      data: location,
                      icon: "pi pi-sitemap",
                      selectable: true,
                    },
                  ],
                  selectable: false,
                };

                allLocationTree.push(locationTree);
                countLocation++;
              });

              clusterTree.children = allLocationTree;
              allClusterTree.push(clusterTree);
              countCluster++;
            });

            treeZone.children = allClusterTree;
            allZoneTree.push(treeZone);
            countZone++;
          });

          treeRegion.children = allZoneTree;
          allRegionTree.push(treeRegion);
        });
        this.zoneTree[0].children = allRegionTree;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch zonal map view summary at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  async ShowLocationOperatives(event) {
    if (event.node.data) {
      if (this.selectedNodeData !== event.node.data) {
        this.selectedNodeData = event.node.data;
      }

      this.employmentService
        .GetAllLocationOperatives(this.selectedNodeData.id)
        .subscribe(
          async (data) => {
            this.allSelectedOperatives = data;
            this.showOperativeList = true;
          },
          (error) => {
            console.log("Error: " + JSON.stringify(error));
            this.messageService.add({
              severity: "error",
              summary: "Notice",
              detail:
                "Unable to fetch all location operatives at the moment.. Reason: [" +
                error
                  ? error.error.message
                  : "request failed - permission" + "]",
            });
          }
        );
    }
  }

  ViewMoreOperativeInfo(item: DeployedGuard) {}

  AppendPoolOPsToZonalMapView() {
    this.employmentService.GetAllOperativesInPool().subscribe(
      async (data) => {
        this.allGuardsInPool = [];
        data.forEach((operative) => {
          this.allGuardsInPool.push(operative.deployedGuard);
          this.poolTree[0].children.push({
            key: "00",
            label:
              operative.deployedGuard.jobApplication.personalInformation
                .lastname +
              " " +
              operative.deployedGuard.jobApplication.personalInformation
                .firstname,
            data: null,
            icon: "pi pi-sitemap",
            children: [],
            selectable: false,
          });
        });

        if (data.length > 0) {
          this.poolTree[0].selectable = true;
          this.poolTree[0].data = data;
        }
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to get all pool operatives at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  async ShowGuardsInPool(event) {
    if (this.allGuardsInPool.length > 0) {
      this.allSelectedOperatives = this.allGuardsInPool;
      this.showOperativeList = true;
    } else this.showOperativeList = false;
  }
}
