import {Component, OnDestroy, OnInit} from '@angular/core';
import {CommanderUserProfile, ServiceAssignment, SupplierServices} from '../../../../../interfaces/armada';
import {DynamicDialogConfig} from 'primeng/dynamicdialog';
import {MessageService} from 'primeng/api';
import {ServiceAssignmentService} from '../../../../../services/armada/service-assignment.service';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

@Component({
  selector: 'app-view-contract-service',
  templateUrl: './view-contract-service.component.html',
  styleUrls: ['./view-contract-service.component.scss'],
  providers: [MessageService]
})
export class ViewContractServiceComponent implements OnInit, OnDestroy {
  private unsubscriber$ = new Subject<void>();
  public commanders: CommanderUserProfile[];
  public selectedCommander: CommanderUserProfile;
  public serviceAssignment: ServiceAssignment;
  public assignedVehicles: SupplierServices[];
  public serviceId: string;
  public emptyServiceAssignment: boolean;
  public loadingAssignment: boolean;
  public selectedVehicle: SupplierServices;
  constructor(
      public dialogData: DynamicDialogConfig,
      private messageService: MessageService,
      private serviceAssignmentService: ServiceAssignmentService,
  ) { }

  ngOnInit(): void {
    this.serviceId = this.dialogData.data.serviceId;
    this.dialogData.width = '98%';
    this.fetchServiceAssignment();
  }
  fetchServiceAssignment(): void {
    if(!this.serviceId) {
      this.messageService.add({
        severity: 'error',
        summary: 'Invalid Request',
        detail: 'Invalid Service Selected',
      });
      this.emptyServiceAssignment = true;
      return;
    }
    this.loadingAssignment = true;
    this.serviceAssignmentService.getServiceAssignmentInfo(this.serviceId).pipe(takeUntil(this.unsubscriber$))
        .subscribe((result: any) => {
          this.loadingAssignment = false;
          this.serviceAssignment = result;
          this.commanders = this.serviceAssignment.commanderUserProfiles;
          this.assignedVehicles = this.serviceAssignment.vehicles;
          this.selectVehicle(this.assignedVehicles[0]);
        }, error => {
          this.connectionError();
          this.loadingAssignment = false;
        });
  }
  selectCommander(commander: CommanderUserProfile) {
    this.selectedCommander = commander;
  }
  selectVehicle(vehicle: SupplierServices) {
    this.selectedVehicle = vehicle;
    this.selectCommander(this.selectedVehicle.commanderUserProfiles[0]);
  }

  viewPassengers(): void {
  }

  private connectionError() {
    this.messageService.add({severity:'error', summary: 'Failed',
      detail:'Connection Error, Please try again'});
  }
  ngOnDestroy(): void {
    this.unsubscriber$.complete();
    this.unsubscriber$.unsubscribe();
  }

}
