import { map } from "rxjs/operators";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { ConfirmationService, MessageService, SelectItem } from "primeng/api";
import { Report } from "../../../interfaces/home";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { BreadcrumbService } from "src/app/breadcrumb.service";

@Component({
  selector: "app-report-view",
  templateUrl: "./report-view.component.html",
  styleUrls: ["./report-view.component.css"],
  providers: [ConfirmationService, MessageService],
})
export class ReportViewComponent implements OnInit {
  @ViewChild("formWrapper") public formWrapper: ElementRef;

  report: boolean = false;
  reportForm: FormGroup;
  reportCategories: any = [];
  reportItems: any = [];
  reportData: any = [];
  selectedCategory: any;
  selectedItem: any;
  reportViewerHost: string;

  constructor(
    private formBuilder: FormBuilder,
    public messageService: MessageService,
    public confirmationService: ConfirmationService,
    private breadcrumbService: BreadcrumbService,
    private http: HttpClient,
    public sanitizer: DomSanitizer // private cookieservice: CookieService
  ) {}

  baseUrl = environment.baseUrl;

  getReports() {
    this.http
      .get(`${this.baseUrl}Reporting/GetAllReportCategories`)
      .subscribe((res: any) => {
        if (res) {
          this.reportData = res.responseData;
          res.responseData.forEach((item) => {
            this.reportCategories.push(item.name);
          });
          console.log(this.reportData);
        }
      });
  }

  ngOnInit(): void {
    this.getReports();
    this.breadcrumbService.setItems([
      { label: "Reporting", routerLink: ["home/report-view"] },
    ]);

    this.reportViewerHost = environment.reportViewerHost;

    this.reportForm = this.formBuilder.group({
      category: [null, Validators.required],
      item: [null, Validators.required],
    });
  }

  loadReport() {
    this.report = true;
    // let externalLocation: string;
    // switch (this.selectedItem) {
    //   case "Amortization":
    //     externalLocation =
    //       "HalobizReports/AmortizationReport?rs:embed=true&pYear=2021";
    //     break;
    //   case "Lead":
    //     externalLocation = "HalobizReports/LeadReports?rs:embed=true";
    //     break;
    //   case "Contracts":
    //     externalLocation = "HalobizReports/ContractsReport?rs:embed=true";
    //     break;
    //   case "Renewals Due":
    //     externalLocation = "HalobizReports/RenewalsDue?rs:embed=true";
    //     break;
    //   default:
    //     externalLocation = "HalobizReports/AmortizationReport?rs:embed=true";
    //     break;
    // }

    // this.report = { externalLocation };
  }

  categorySelected(value) {
    this.report = false;
    this.reportItems = [];
    this.reportData.forEach((report) => {
      if (report.name === value && report.reportItems.length > 0) {
        report.reportItems.map((item) => {
          const { name } = item;
          const { reportName } = item;
          this.reportItems.push({ label: name, value: reportName });
        });
      }
    });
  }

  onReportSelect(value) {
    this.report = false;
    console.log(value);
    this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(
      `${this.reportServer}report/Reports/${value}?rs:Embed=true&rc:Parameters=true&rs:ParameterLanguage=en-us&rc:Toolbar=true`
    );
  }

  urlSafe: SafeResourceUrl;
  reportServer: string = `${environment.reportUrl}`;
  width = "100%";
  height = "1700";
}
