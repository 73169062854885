import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-jo-end',
  templateUrl: './jo-end.component.html',
  styleUrls: ['./jo-end.component.scss']
})
export class JoEndComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
