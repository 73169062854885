import { Complaint } from "./../../../interfaces/complaint";
import { ComplaintService } from "./../../../services/complaint.service";
import { EscalationMatrixService } from "./../../../services/escalation-matrix.service";
import { EscalationMatrix } from "./../../../interfaces/escalation-matrix";
import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { MessageService, ConfirmationService } from "primeng/api";
import { FileStorageService } from "src/app/services/file-storage.service";
import { CommonResponse } from "src/app/interfaces/home";

@Component({
  selector: "app-escalation-matrix",
  templateUrl: "./escalation-matrix.component.html",
  styleUrls: ["./escalation-matrix.component.scss"],
  providers: [ConfirmationService, MessageService],
})
export class EscalationMatrixComponent implements OnInit {
  @ViewChild("formWrapper") public formWrapper: ElementRef;
  form: FormGroup;
  editing = false;
  fetching = true;
  escalationMatrixs: EscalationMatrix[];
  selectedEscalationMatrix: EscalationMatrix;
  cols: any[];
  private escalationMatrixToEdit: EscalationMatrix;
  complaintStages = [
    {
      stage: "Registration",
      value: 1,
    },
    {
      stage: "Assesment",
      value: 2,
    },
    {
      stage: "Investigation",
      value: 3,
    },
    {
      stage: "Resolution",
      value: 4,
    },
    {
      stage: "Closure",
      value: 5,
    },
  ];
  theComplaintStage: {
    stage: string;
    value: number;
  };
  complaints: Complaint[];
  theComplaint: Complaint;
  uploadedEvidences: any[] = [];
  newUpload: boolean;
  imgSrc: string;

  constructor(
    fb: FormBuilder,
    public messageService: MessageService,
    public escalationMatrixService: EscalationMatrixService,
    public complaintService: ComplaintService,
    public confirmationService: ConfirmationService,
    private fileStorageService: FileStorageService
  ) {
    this.form = fb.group({
      Caption: ["", Validators.required],
      ImageUrl: [""],
      Complaint: ["", Validators.required],
      ComplaintStage: ["", Validators.required],
    });
  }

  ngOnInit(): void {
    this.cols = [
      { field: "caption", header: "Caption" },
      { field: "complaint", header: "Complaint" },
      { field: "complaintStage", header: "ComplaintStage" },
    ];

    this.FetchAllComplaints();
    this.FetchAllEscalationMatrixs();
  }

  FetchAllEscalationMatrixs() {
    this.escalationMatrixService.getAll().subscribe(
      async (data: any) => {
        this.escalationMatrixs = data;
        this.fetching = false;
      },
      (error) => {
        this.fetching = false;
        this.ConnectionError();
      }
    );
  }

  FetchAllComplaints() {
    this.complaintService.getAll().subscribe(
      async (data: any) => {
        this.complaints = data;
      },
      (error) => {
        this.ConnectionError();
      }
    );
  }

  async CreateEscalationMatrix() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Creating Escalation Matrix...",
    });

    const postData: EscalationMatrix = {
      caption: this.form.get("Caption").value,
      imageUrl: this.form.get("ImageUrl").value,
      complaintId: this.theComplaint.id,
      complaintStage: this.theComplaintStage.value,
    };

    if (this.uploadedEvidences.length > 0) {
      this.fileStorageService.UploadMultipleFilesFromDataUrl(
        this.uploadedEvidences
      );
      this.fileStorageService.onUploadFinished.subscribe(
        (resp: CommonResponse<string[]>) => {
          if (resp.responseCode != "00") {
            this.messageService.add({
              severity: "error",
              summary: "Notice",
              detail: resp.responseMsg,
            });
          } else {
            postData.imageUrl = resp.responseData[0];
            this._createEscalationMatrix(postData);
          }
        },
        (error) => {
          console.log("Error while uploading files " + error);
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail: "ERR: Unable to upload image to storage",
          });
        }
      );
    } else {
      this._createEscalationMatrix(postData);
    }
  }

  _createEscalationMatrix(postData: EscalationMatrix) {
    this.escalationMatrixService.create(postData).subscribe(
      async () => {
        await this.messageService.add({
          severity: "success",
          summary: "Completed",
          detail: "New Escalation Matrix Created...",
        });

        this.FetchAllEscalationMatrixs();
        this.form.reset();
      },
      (error) => {
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to create escalation matrix at the moment.. Reason: [" +
            JSON.stringify(error) +
            "]",
        });
      }
    );
  }

  EditEscalationMatrix(item: EscalationMatrix) {
    this.editing = true;
    this.form.addControl(
      "ID",
      new FormControl({ value: "", disabled: true }, Validators.required)
    );
    this.escalationMatrixToEdit = item;
    this.form.patchValue({
      ID: item.id,
      ImageUrl: item.imageUrl,
    });
    this.imgSrc = item.imageUrl;
    this.theComplaintStage = this.complaintStages.find(
      (data) => data.value == item.complaintStage
    );
    this.theComplaint = this.complaints.find(
      (data) => data.id == item.complaintId
    );

    this.formWrapper.nativeElement.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "start",
    });
  }

  async UpdateEscalationMatrix() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Updating Selected Escalation Matrix...",
    });

    const id = this.escalationMatrixToEdit.id;
    const postData: EscalationMatrix = {
      caption: this.form.get("Caption").value,
      imageUrl: this.form.get("ImageUrl").value,
      complaintId: this.theComplaint.id,
      complaintStage: this.theComplaintStage.value,
    };

    if (this.newUpload) {
      if (this.uploadedEvidences.length > 0) {
        this.fileStorageService.UploadMultipleFilesFromDataUrl(
          this.uploadedEvidences
        );
        this.fileStorageService.onUploadFinished.subscribe(
          (resp: CommonResponse<string[]>) => {
            if (resp.responseCode != "00") {
              this.messageService.add({
                severity: "error",
                summary: "Notice",
                detail: resp.responseMsg,
              });
            } else {
              postData.imageUrl = resp.responseData[0];
              this._updateEscalationMatrix(id, postData);
            }
          },
          (error) => {
            console.log("Error while uploading files " + error);
            this.messageService.add({
              severity: "error",
              summary: "Notice",
              detail: "ERR: Unable to upload image to storage",
            });
          }
        );
      }
    } else {
      this._updateEscalationMatrix(id, postData);
    }
  }

  _updateEscalationMatrix(id: number, postData: EscalationMatrix) {
    this.escalationMatrixService.update(id, postData).subscribe(
      async () => {
        this.messageService.add({
          severity: "success",
          summary: "Notice",
          detail: "Update Successful!",
        });
        await this.FetchAllEscalationMatrixs();
        await this.CloseEditing();
      },
      (error) => {
        this.messageService.add({
          severity: "error",
          summary: "Unable To Update",
          detail:
            "Unable to update escalation matrix at the moment.. Reason: [" +
            JSON.stringify(error) +
            "]",
        });
      }
    );
  }

  DeleteEscalationMatrix(item: EscalationMatrix) {
    this.confirmationService.confirm({
      message: "Are you sure you want to remove escalation matrix ",
      accept: () => {
        this.messageService.add({
          severity: "info",
          summary: "Notice",
          detail: "Removing escalation matrix...",
        });
        this.escalationMatrixService.delete(item.id).subscribe(
          async () => {
            await this.messageService.add({
              severity: "success",
              summary: "Removed",
              detail: "Removed successfully",
            });
            await this.FetchAllEscalationMatrixs();
          },
          () => {
            this.ConnectionError();
          }
        );
      },
    });
  }

  onUpload(event) {
    this.uploadedEvidences = [];
    for (const file of event.files) {
      this.uploadedEvidences.push(file);
    }

    this.messageService.add({
      severity: "info",
      summary: "Success",
      detail: "Evidence Uploaded",
    });

    this.newUpload = true;
  }

  GetComplaintStageTitle(value: number) {
    return this.complaintStages.find((data) => data.value == value).stage;
  }

  GetComplaintDescription(id: number) {
    return this.complaints.find((data) => data.id == id).complaintDescription;
  }

  CloseEditing() {
    this.editing = false;
    this.form.reset();
    this.escalationMatrixToEdit = null;
  }

  private ConnectionError() {
    this.messageService.add({
      severity: "error",
      summary: "Failed",
      detail: "Connection Error, Please try again",
    });
  }
}
