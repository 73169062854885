import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {DialogService, DynamicDialogRef} from 'primeng/dynamicdialog';
import {FormBuilder} from '@angular/forms';
import {ConfirmationService, MessageService} from 'primeng/api';
import {CommanderRegistrationService} from '../../../../services/armada/commander-registration.service';
import {JourneyStart, JourneyStop, ServiceAssignment, SituationReport, TimeSpan} from '../../../../interfaces/armada';
import {interval, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {BreadcrumbService} from '../../../../breadcrumb.service';
import {StartJourneyComponent} from '../start-journey/start-journey.component';
import {FireBaseAuthService} from '../../../../services/fire-base-auth.service';
import {JourneyStartService} from '../../../../services/armada/journey-start.service';
import {CustomerDivisionService} from '../../../../services/armada/customer-division.service';
import {LeadDivision} from '../../../../interfaces/home';
import {ServiceAssignmentService} from '../../../../services/armada/service-assignment.service';
import {AssignmentAcceptanceStatusService} from '../../../../services/armada/assignment-acceptance-status.service';
import {CaptureSituationReportComponent} from '../capture-situation-report/capture-situation-report.component';
import {CaptureJourneyStopComponent} from '../capture-journey-stop/capture-journey-stop.component';
import {SituationReportService} from '../../../../services/armada/situation-report.service';
import {JourneyStopService} from '../../../../services/armada/journey-stop.service';
import {EndJourneyMessageComponent} from '../end-journey-message/end-journey-message.component';

@Component({
    selector: 'app-close-journey',
    templateUrl: './close-journey.component.html',
    styleUrls: ['./close-journey.component.scss'],
    providers: [MessageService, DynamicDialogRef, ConfirmationService],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CloseJourneyComponent implements OnInit, OnDestroy {
    private unsubscriber$ = new Subject<void>();
    public loading: boolean;
    public commanderTask: ServiceAssignment;
    startingJourney: boolean;
    clientInformation: LeadDivision;
    public journeyStarted: boolean;
    public journeyEnded: boolean;
    private commanderId: number;
    private userId: number;
    private startTime: Date;
    private destroyed$ = new Subject();
    public canStartJourney: boolean | null;
    public commanderAcceptStatus: any;
    public loadingStatus: boolean;
    public journeyData: JourneyStart;
    journeyStops: JourneyStop[] = [];
    situationReports: SituationReport[] = [];

    constructor(
        private dialogRef: DynamicDialogRef,
        private journeyDialog: DynamicDialogRef,
        private confirmationService: ConfirmationService,
        private messageService: MessageService,
        private dialogServices: DialogService,
        private formBuilder: FormBuilder,
        private commanderProfileService: CommanderRegistrationService,
        private breadcrumbService: BreadcrumbService,
        private fireBaseAuthService: FireBaseAuthService,
        private journeyStartService: JourneyStartService,
        private customerDivisionService: CustomerDivisionService,
        private changeDetector: ChangeDetectorRef,
        private serviceAssignmentService: ServiceAssignmentService,
        private assignmentAcceptanceStatus: AssignmentAcceptanceStatusService,
        private situationReportService: SituationReportService,
        private journeyStopService: JourneyStopService,
    ) {
        breadcrumbService.setItems([
            {label: 'Secured Mobility Administration'},
            {label: 'Journey'}
        ])
    }

    ngOnInit(): void {
        this.commanderAcceptStatus = null;
        this.userId = this.fireBaseAuthService.authUserProfile.id;
        this.fetchCommanderTask();
        this.fetchCommanderProfileId();
        interval(1000).subscribe(() => {
            if (!this.changeDetector['destroyed']) {
                this.changeDetector.detectChanges();
            }
        });
        this.changeDetector.detectChanges();
        this.journeyEnded = false;
    }

    fetchCommanderTask() {
        this.loading = true;
        this.commanderProfileService.getAssignedTasks().pipe(takeUntil(this.unsubscriber$))
            .subscribe( (res: any) => {
                this.commanderTask = res;
                this.canStartJourney = this.commanderTask.isAccepted;
                 this.fetchCommandTaskStatus(this.commanderTask.id);
                 this.fetchClientInfo(res.contractServiceId);
                if (this.commanderTask.journeyStarted === true) {
                    this.fetchJourneyStart();
                    this.fetchJourneyStops();
                    this.fetchSituationReports();
                }
            }, error => {
                if (error.status !== 404) {
                    this.connectionError();
                }
                this.loading = false;
            });
    }

    fetchJourneyStart() {
        this.journeyStartService.allJourneyStart()
            .pipe(takeUntil(this.unsubscriber$)).subscribe((res: any) => {
            const _data: JourneyStart[] = res;
            this.journeyData = _data.find(x => x.serviceAssignmentId === this.commanderTask.id);
            if(this.journeyData) {
                this.journeyEnded = this.journeyData.endedById > 0;
                if(this.journeyEnded) {
                    this.showEndJourneyMessage();
                }
            }
        })
    }

    fetchClientInfo(contractServiceId) {
        this.customerDivisionService.getClient(contractServiceId).pipe(takeUntil(this.unsubscriber$))
            .subscribe((result: any) => {
                this.clientInformation = result;
                this.loading = false;
            }, error => {
                this.connectionError();
            })
    }

    fetchCommanderProfileId() {
        this.commanderProfileService.getCommanderId(this.userId).pipe(takeUntil(this.unsubscriber$))
            .subscribe((result: any) => {
                this.commanderId = result;
            }, error => {
                this.connectionError();
            })
    }

    fetchCommandTaskStatus(serviceAssignmentId: number) {
        this.loadingStatus = true;
        this.assignmentAcceptanceStatus.getCommanderAcceptanceStatus(serviceAssignmentId)
            .pipe(takeUntil(this.unsubscriber$))
            .subscribe((res: any) => {
                this.commanderAcceptStatus = res;
                this.loadingStatus = false;
            })
    }

    endJourney() {
        this.confirmationService.confirm({
            message: 'You are about to end this journey',
            acceptLabel: 'Yes, End Journey',
            rejectLabel: 'No, Close',
            header: 'End journey',
            accept: () => {
                // this.dialogRef = this.dialogServices.open(EndJourneyComponent, {
                //     header: 'Close Journey',
                //     closable: true,
                //     data: {},
                // });
                // this.dialogRef.onClose.pipe().subscribe((res) => {
                //     if (res) {
                //     }
                // })
                this.submitEndJourney();
            }
        });
    }
    submitEndJourney() {
        const startJourneyData = {
            serviceAssignmentId: this.commanderTask.id,
            commanderId: this.commanderId,
            journeyStartDateTime: this.journeyData.journeyStartDateTime,
            journeyEndDateTime: new Date().toISOString(),
            vehicleId: this.commanderTask.assignedVehicleId,
            startedById: this.journeyData.startedById,
            endedById: this.userId,
        }
        this.journeyStartService.updateJourneyStart(this.journeyData.id, startJourneyData)
            .pipe(takeUntil(this.unsubscriber$))
            .subscribe( async () => {
                this.messageService.add({
                   severity: 'success',
                    detail: 'You successfully end this Journey',
                    summary: 'Complete'
                });
                await this.fetchJourneyStart();
                await this.fetchCommanderTask();
            }, error => this.connectionError())
    }

    confirmStartJourney() {
        this.confirmationService.confirm({
            header: 'Confirm Changes',
            message: 'You are about to start a journey please confirm to continue',
            acceptLabel: 'Yes, Start journey',
            rejectLabel: 'No, Go back',
            accept: () => {
                this.startJourney();
            },
        })
    }

    startJourney() {
        this.startingJourney = true;
        this.journeyDialog = this.dialogServices.open(StartJourneyComponent, {
            header: 'Start new Journey',
            closable: true,
            width: '360px',
            data: {},
        });
        this.journeyDialog.onClose.pipe(takeUntil(this.unsubscriber$)).subscribe(res => {
            if (res) {
                const startJourneyData = {
                    serviceAssignmentId: this.commanderTask.id,
                    commanderId: this.commanderId,
                    vehicleId: this.commanderTask.assignedVehicleId,
                    comments: res.comment
                }
                this.journeyStartService.postJourneyStart(startJourneyData).pipe(takeUntil(this.unsubscriber$))
                    .subscribe((result: any) => {
                        this.fetchCommanderTask();
                        this.messageService.add({
                            severity: 'success',
                            detail: 'Journey started Successfully',
                            summary: 'Request Complete',
                        });
                        this.startingJourney = false;
                    }, error => {
                        this.connectionError();
                        this.startingJourney = false;
                    });
            } else {
                this.startingJourney = false;
            }
        })
    }

    public journeyStartTimmer(): TimeSpan | boolean {
        let hours = 0;
        let minutes = 0;
        let seconds = 0;
        const endTime = this.journeyEnded ? this.journeyData.journeyEndDateTime : new Date();
        this.journeyStarted = this.commanderTask.journeyStarted ?? false;
        if (!this.journeyStarted) {
            return {
                hours,
                minutes,
                seconds
            };
        }
        this.startTime = new Date(new Date(this.journeyData.journeyStartDateTime).getTime() - (1000));
        let totalSeconds = Math.floor((new Date(endTime).getTime() - this.startTime.getTime()) / 1000);
        if (totalSeconds >= 3600) {
            hours = Math.floor(totalSeconds / 3600);
            totalSeconds -= 3600 * hours;
        }
        if (totalSeconds >= 60) {
            minutes = Math.floor(totalSeconds / 60);
            totalSeconds -= 60 * minutes;
        }
        seconds = totalSeconds;
        return {
            hours,
            minutes,
            seconds
        };
    }

    private connectionError() {
        this.messageService.add({
            severity: 'error', summary: 'Failed',
            detail: 'Connection Error, Please try again'
        });
    }

    ngOnDestroy(): void {
        this.destroyed$.next();
        this.destroyed$.complete();
        this.unsubscriber$.complete();
        this.unsubscriber$.unsubscribe();
    }

    confirmTask(status: string) {
        let message: string;
        let acceptLabel: string;
        let rejectLabel = 'No, Go back';
        if (status === 'accept') {
            message = '<strong>Notice: </strong> Are you sure about accepting the Assignment';
            acceptLabel = 'Yes, Continue';
            rejectLabel = 'No, Go back';
        }
        if (status === 'reject') {
            message = '<strong>Notice: </strong>You are about to reject the Assignment';
            acceptLabel = 'Yes, Continue';
            rejectLabel = 'No, Go back';
        }
        this.confirmationService.confirm({
            blockScroll: true,
            message,
            header: 'confirm your choice',
            acceptLabel,
            rejectLabel,
            accept: () => {
                this.acceptAssignment(status);
            }
        });
    }

    acceptAssignment(status) {
        if (status === 'accept') {
            this.assignmentAcceptanceStatus.acceptServiceAssignment(this.commanderTask.id)
                .pipe(takeUntil(this.unsubscriber$))
                .subscribe(async (result: any) => {
                    await this.fetchCommanderTask();
                });
        }
        if (status === 'reject') {
            this.assignmentAcceptanceStatus.rejectServiceAssignment(this.commanderTask.id)
                .pipe(takeUntil(this.unsubscriber$))
                .subscribe( (result: any) => {
                    this.fetchCommanderTask();
                }, error => {
                    this.fetchCommanderTask();
                    this.connectionError()
                });
        }
    }

    captureSituationReport() {
        this.dialogRef = this.dialogServices.open(CaptureSituationReportComponent, {
            closable: false,
            closeOnEscape: false,
            header: 'Capture Situation Reports',
            data: {serviceAssignmentId: this.commanderTask.id, contractServiceId: this.commanderTask.contractServiceId}
        })
        this.dialogRef.onClose.pipe(takeUntil(this.unsubscriber$))
            .subscribe((res: any) => {
                if (res) {
                    this.submitSituationReport(res);
                }
            })
    }

    submitSituationReport(data) {
        this.situationReportService.postSituationReport(data).pipe(takeUntil(this.unsubscriber$))
            .subscribe((result: any) => {
                // load the situation reports
                this.messageService.add({
                    detail: 'Complete',
                    summary: 'Situation Report  Captured',
                    severity: 'success',
                })
                this.fetchSituationReports();
            }, error => this.connectionError());
    }

    captureJourneyStop() {
        this.dialogRef = this.dialogServices.open(CaptureJourneyStopComponent, {
            closable: false,
            closeOnEscape: false,
            header: 'Capture Journey Stops',
            data: {serviceAssignmentId: this.commanderTask.id, contractServiceId: this.commanderTask.contractServiceId}
        })
        this.dialogRef.onClose.pipe(takeUntil(this.unsubscriber$))
            .subscribe((res: any) => {
                if (res) {
                    this.submitJourneyStop(res);
                }
            })
    }

    submitJourneyStop(data) {
        this.journeyStopService.postJourneyStop(data).pipe(takeUntil(this.unsubscriber$))
            .subscribe((result: any) => {
                this.fetchJourneyStops();
                this.messageService.add({
                    detail: 'Complete',
                    summary: 'Journey Stop Captured',
                    severity: 'success',
                })
            }, error => this.connectionError());
    }
    fetchSituationReports() {
        this.situationReportService.getByContractService(this.commanderTask.contractServiceId)
            .pipe(takeUntil(this.unsubscriber$)).subscribe( (res: SituationReport[]) => {
                this.situationReports = res;
        }, error => {
            if (error.status !== 404) {
                this.connectionError();
            }
        })
    }
    fetchJourneyStops() {
        this.journeyStopService.getByContractService(this.commanderTask.contractServiceId)
            .pipe(takeUntil(this.unsubscriber$)).subscribe((result: JourneyStop[]) => {
                this.journeyStops = result;
    }, error => {if (error.status !== 404) {this.connectionError();}})
    }
    showEndJourneyMessage() {
        this.dialogServices.open(EndJourneyMessageComponent, {
            width: '450px',
            showHeader: false,
            data: {journeyData: this.journeyData, duration: this.journeyStartTimmer()},
        })
    }
}
