import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { QualificationRoutingModule } from "./qualification-routing.module";
import { QualificationComponent } from "./qualification.component";
import { GroupTypeComponent } from "./group-type/group-type.component";
import { LeadTypeComponent } from "./lead-type/lead-type.component";
import { RelationshipTypeComponent } from "./relationship-type/relationship-type.component";
import { AccountVoucherTypeComponent } from "./account-voucher-type/account-voucher-type.component";
import { ButtonModule } from "primeng/button";
import { RippleModule } from "primeng/ripple";
import { ProgressSpinnerModule } from "primeng/progressspinner";
import { TableModule } from "primeng/table";
import { InputTextModule } from "primeng/inputtext";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { DropdownModule } from "primeng/dropdown";
import { ConfirmDialogModule } from "primeng/confirmdialog";
import { ToastModule } from "primeng/toast";
import { TooltipModule } from "primeng/tooltip";
import { MeanOfIdComponent } from "./mean-of-id/mean-of-id.component";
import { LeadOriginComponent } from "./lead-origin/lead-origin.component";
import { EndorsementTypeComponent } from "./endorsement-type/endorsement-type.component";
import { CheckboxModule } from "primeng/checkbox";
import { FieldsetModule } from "primeng/fieldset";
import { InputSwitchModule } from "primeng/inputswitch";

@NgModule({
  declarations: [
    QualificationComponent,
    GroupTypeComponent,
    LeadTypeComponent,
    RelationshipTypeComponent,
    AccountVoucherTypeComponent,
    MeanOfIdComponent,
    LeadOriginComponent,
    EndorsementTypeComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    QualificationRoutingModule,
    ButtonModule,
    RippleModule,
    ProgressSpinnerModule,
    TableModule,
    InputTextModule,
    ReactiveFormsModule,
    DropdownModule,
    ConfirmDialogModule,
    ToastModule,
    TooltipModule,
    CheckboxModule,
    FieldsetModule,
    ToastModule,
    ProgressSpinnerModule,
    InputSwitchModule,
  ],
})
export class QualificationModule {}
