import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { ConfirmationService, MessageService } from "primeng/api";
import { BreadcrumbService } from "src/app/breadcrumb.service";
import {
  GetPayrollVsContractReportVM,
  KeyValuePairTempVM,
  PayGroup,
  PayrollRunOperation,
  PayrollVsContractReportVM,
} from "src/app/interfaces/payroll";
import { PayrollService } from "src/app/services/payroll.service";

@Component({
  selector: "app-payroll-vs-contract",
  templateUrl: "./payroll-vs-contract.component.html",
  styleUrls: ["./payroll-vs-contract.component.scss"],
})
export class PayrollVsContractComponent implements OnInit {
  @ViewChild("formWrapper") public formWrapper: ElementRef;
  allRunTypes: KeyValuePairTempVM[];
  theRunType: KeyValuePairTempVM;
  allRunPeriods: KeyValuePairTempVM[];
  theRunPeriod: KeyValuePairTempVM;
  allPayGroups: PayGroup[];
  thePayGroup: PayGroup;
  allClients: KeyValuePairTempVM[];
  theClient: KeyValuePairTempVM;
  allCriteriaReports: PayrollVsContractReportVM[];
  criteriaReportCols: any[];
  showReport: boolean = false;

  constructor(
    private fb: FormBuilder,
    private payrollService: PayrollService,
    private breadcrumbService: BreadcrumbService,
    public confirmationService: ConfirmationService,
    public messageService: MessageService
  ) {}

  ngOnInit(): void {
    this.breadcrumbService.setItems([
      {
        label: "Payroll",
        routerLink: ["/home/payroll/payroll-settings"],
      },
      {
        label: "Payroll Vs Contract",
        routerLink: ["/home/payroll/payroll-vs-contract"],
      },
    ]);

    this.criteriaReportCols = [
      { field: "clientName", header: "Customer Name" },
      { field: "location", header: "Location" },
      { field: "name", header: "Fullname" },
      { field: "operativeID", header: "OperativeID" },
      { field: "grossPay", header: "Gross Pay" },
      { field: "netPay", header: "Net Pay" },
      { field: "contract", header: "Contract" },
      { field: "frequency", header: "Frequency" },
      { field: "unitPrice", header: "Unit Price" },
      { field: "compareAmount", header: "Compare Amount" },
      { field: "grossPerc", header: "Gross Percentage" },
      { field: "netPerc", header: "Net Percentage" },
      { field: "payGrade", header: "Pay Grade" },
      { field: "runType", header: "Run Type" },
      { field: "payGroup", header: "Pay Group" },
      { field: "payPeriod", header: "Pay Period" },
    ];

    this.FetchFiltersDetails();
  }

  FetchFiltersDetails() {
    this.payrollService.GetPayrollVsContractRequestDits().subscribe(
      async (data) => {
        this.allRunTypes = data.runTypes;
        this.allRunPeriods = data.runPeriods;
        this.allPayGroups = data.payGroups;
        this.allClients = data.clients;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch filter details at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  RunCriteria() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Generating Report...",
    });

    this.showReport = false;
    const postData: GetPayrollVsContractReportVM = {
      runTypeId: this.theRunType.key,
      runPeriodId: this.theRunPeriod.key,
      runPeriodDate: this.theRunPeriod.keyDate,
      payGroupId: this.thePayGroup.id,
      clientId: this.theClient.key,
    };

    this.payrollService.GetPayrollVsContractReport(postData).subscribe(
      async (data) => {
        this.messageService.add({
          severity: "success",
          summary: "Completed",
          detail: "Report Generated Successfully!",
        });

        this.allCriteriaReports = data;
        this.showReport = true;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to generate report at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
        this.showReport = false;
      }
    );
  }
}
