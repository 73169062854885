import { Component, OnInit } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { MessageService } from "primeng/api";
import {
  DynamicDialogRef,
  DynamicDialogConfig,
  DialogService,
} from "primeng/dynamicdialog";
import {
  CData,
  User,
  Branch,
  Office,
  QuoteServices,
  QuoteServiceDocument,
  SBUQuoteServiceProportion,
  GeneralService,
  Services,
  SbuToQuoteServiceProp,
  Constant,
  RequiredServiceDocument,
  ContractService,
  SbuToContractServiceProp,
} from "src/app/interfaces/home";
import { FireBaseAuthService } from "src/app/services/fire-base-auth.service";
import { LeadService } from "src/app/services/lead.service";
import { SbuContractServiceProportionService } from "src/app/services/sbu-contract-service-proportion.service";
import { UserService } from "src/app/services/user.service";

@Component({
  selector: "app-sbu-info",
  templateUrl: "./sbu-info.component.html",
  styleUrls: ["./sbu-info.component.scss"],
})
export class SbuInfoComponent implements OnInit {
  selectedRole1: CData;
  selectedRole2: CData;
  selectedUser1: User;
  selectedUser2: User;
  user: User;
  leadRoles: CData[];
  public authUser: User = null;
  serviceUserForm: FormGroup;
  selectedBranch: Branch;
  branches: Branch[];
  selectedOffice: Office;
  offices: Office[];
  users: User[] = [];
  selectedContractService: ContractService = null;
  quoteService: QuoteServices = null;
  quoteServiceDocuments: QuoteServiceDocument[];
  sbuContractServiceProportions: SBUQuoteServiceProportion[] = [];
  selectedGeneralService: any;
  invoiceIntervals: CData[];
  paymentIntervals: CData[];
  invoiceIntervalValue: CData;
  paymentCycleValue: CData;
  public selectedService: Services;
  public message: any;
  public documentFIle: any;
  public imageSrc: string;
  public awardDocumentUploaded = false;
  public riskAssessmentReportUploaded = false;
  pageLoading = true;
  selectedRole: CData;
  selectedUser0: User;
  selectedUser3: User;
  selectedRole3: CData;
  savingLeadForClosure: boolean = false;
  isGroupUpload: boolean = false;
  quoteId: number = 0;
  allQuoteServices: QuoteServices[];
  anyHasUploadDocument: boolean = false;
  sbuToQuoteProp: SbuToContractServiceProp[] = [];
  validating: boolean = false;

  constructor(
    public closureFormRef: DynamicDialogRef,
    public modalRef: DynamicDialogRef,
    public config: DynamicDialogConfig,
    public messageService: MessageService,
    public firebaseAuthService: FireBaseAuthService,
    public userService: UserService,
    public dialogService: DialogService,
    public constant: Constant,
    public leadService: LeadService,
    private formBuilder: FormBuilder,
    private sbuContractServiceProportionService: SbuContractServiceProportionService
  ) {}

  async ngOnInit() {
    // get the authenticated user
    this.leadRoles = this.constant.leadRole;
    this.authUser = this.firebaseAuthService.authUserProfile;
    this.invoiceIntervals = this.constant.invoicingIntervals;
    this.paymentIntervals = this.constant.paymentCycles;
    // if (this.config.data.hasOwnProperty('service'))
    // {
    //     this.selectedGeneralService = this.config.data.generalService;
    //     this.selectedService = this.selectedGeneralService.service;
    // } else {
    //     this.selectedService = this.config.data.generalService;
    // }

    this.selectedGeneralService = this.config.data.generalService;
    this.selectedService = this.selectedGeneralService.service;
    this.allQuoteServices = this.config.data.allQuoateServices;

    this.isGroupUpload = this.config.data.isGroupUpload;
    this.quoteId = this.config.data.quoteId;

    const defaultRole = this.leadRoles.find(
      (leadRole) => (leadRole.value = "leadGenerator")
    );
    this.serviceUserForm = this.formBuilder.group({
      // user: [this.authUser.firstName],
      user: ["", Validators.required],
      role: [""],
      user1: [""],
      role1: [""],
      user2: [""],
      role2: [""],
      user3: [""],
      role3: [""],
    });
    if (this.users.length > 0) this.selectedUser0 = this.users[0];
    this.selectedRole = defaultRole;
    if (!this.isGroupUpload)
      await this.fetchQuoteService(this.selectedGeneralService);
    else await this.fetchQuoteService(this.allQuoteServices[0]);
  }

  async fetchAvailableSbuUser(id) {
    await this.userService.getUserNotInSbu(id).subscribe(async (res: any) => {
      this.users = res.responseData;
    });
  }

  async fetchQuoteService(contractService: any) {
    // set the invoice interval value
    if (contractService) {
      this.sbuContractServiceProportions =
        contractService.sbutoContractServiceProportions;
      this.selectedContractService = contractService;
    }

    if (this.authUser.sbuId !== 0) {
      await this.fetchAvailableSbuUser(this.authUser.sbuId);
    }

    this.invoiceIntervalValue = this.invoiceIntervals.find(
      (interval) => interval.id === contractService.invoicingInterval
    );
    this.paymentCycleValue = this.paymentIntervals.find(
      (interval) => interval.id === contractService.paymentCycle
    );
    this.pageLoading = false;
  }

  closeModal() {
    this.modalRef.close();
  }

  async saveLeadForClosure() {
    var selectedQuoteService: any = {};
    // get the list of documents
    if (this.authUser == null) {
      await this.messageService.add({
        severity: "error",
        summary: "Canceled",
        detail: "Invalid user detail",
      });
      return;
    }

    if (this.isGroupUpload) {
      selectedQuoteService.id = this.allQuoteServices[0].id;
    } else {
      selectedQuoteService = this.selectedContractService;
    }

    // check if there is user added to the data.
    // save the user in proportion
    this.sbuToQuoteProp = [];
    if (this.authUser.sbuId) {
      this.savingLeadForClosure = true;

      const _userData = {
        status: this.selectedRole.id,
        // userInvolvedId: this.authUser.id,
        userInvolvedId: this.selectedUser0.id,
        contractServiceId: selectedQuoteService.id,
        strategicBusinessUnitId: this.authUser.sbuId,
      };

      this.sbuToQuoteProp.push(_userData);

      const closureUser = [];
      const generatorUser = [];
      const closureAndGeneratorUser = [];
      if (this.selectedRole.value === "leadClosure") {
        closureUser.push(this.selectedRole.value);
      } else if (this.selectedRole.value === "leadGeneratorAndClosure") {
        closureAndGeneratorUser.push(this.selectedRole.value);
      } else {
        generatorUser.push(this.selectedRole.value);
      }
      for (let i = 1; i <= 2; i++) {
        const _user = this.serviceUserForm.get("user" + i).value;
        if (_user) {
          if (!this.serviceUserForm.get("role" + i).value) {
            this.messageService.add({
              severity: "error",
              summary: "Canceled",
              detail: "Add role to all the selected User or remove the user",
              sticky: false,
            });
            this.savingLeadForClosure = false;
            return;
          }
          const role = this.serviceUserForm.get("role" + i).value;
          if (role.value === "leadClosure") {
            closureUser.push(role.status);
          } else if (role.value === "leadGeneratorAndClosure") {
            closureAndGeneratorUser.push(role.status);
          } else {
            generatorUser.push(role.status);
          }
          this.sbuToQuoteProp.push({
            status: role.id,
            userInvolvedId: _user.id,
            contractServiceId: selectedQuoteService.id,
            strategicBusinessUnitId: _user.sbuId,
          });
        }
      }

      if (closureUser.length <= 0 && closureAndGeneratorUser.length <= 0) {
        this.messageService.add({
          severity: "error",
          summary: "Canceled",
          detail: "select at least a user to close the lead",
          sticky: false,
        });
        this.savingLeadForClosure = false;
        return;
      }
      if (generatorUser.length <= 0 && closureAndGeneratorUser.length <= 0) {
        this.messageService.add({
          severity: "error",
          summary: "Canceled",
          detail: "Select at least a lead generator",
          sticky: false,
        });
        this.savingLeadForClosure = false;
        return;
      }

      if (this.isGroupUpload) {
        let postData = [];

        for (let index = 0; index < this.sbuToQuoteProp.length; index++) {
          const value = this.sbuToQuoteProp[index];

          for (let index = 0; index < this.allQuoteServices.length; index++) {
            const _userData = {
              ...value,
              contractServiceId: this.allQuoteServices[index]?.id,
            };

            postData.push(_userData);
          }
        }

        this.sbuToQuoteProp = postData;
      }

      await this.createSbuService(this.sbuToQuoteProp);
      //await this.fetchQuoteService();
    } else {
      await this.messageService.add({
        severity: "error",
        summary: "Canceled",
        detail: `User ${
          this.authUser.firstName + " " + this.authUser.lastName
        } is not attached to an sbu.`,
        sticky: false,
      });
    }
  }

  async createSbuService(data) {
    this.validating = true;
    await this.sbuContractServiceProportionService
      .post(data)
      .subscribe(async (res: any) => {
        this.modalRef.close("complete");
        if (res.responseCode == "00") {
          this.savingLeadForClosure = false;
          await this.messageService.add({
            severity: "success",
            summary: "Completed",
            detail: res.responseMsg,
          });
        } else {
          await this.messageService.add({
            severity: "error",
            summary: "Failure",
            detail: res.responseMsg,
            sticky: true,
          });
        }
      });
  }

  markRoleRequired(id: any) {
    const _userControl = this.serviceUserForm.get("user" + id);
    const _userRole = this.serviceUserForm.get("role" + id);
    if (_userControl.value) {
      _userRole.setValidators(Validators.required);
    }
  }
}
