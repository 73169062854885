import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {
    CommanderUserProfile,
    JourneyStart,
    JourneyStop,
    ServiceAssignment,
    SituationReport,
    SupplierServices,
    TimeSpan
} from '../../../../interfaces/armada';
import {LeadDivision} from '../../../../interfaces/home';
import {interval, Subject} from 'rxjs';
import {JourneyStartService} from '../../../../services/armada/journey-start.service';
import {CustomerDivisionService} from '../../../../services/armada/customer-division.service';
import {ServiceAssignmentService} from '../../../../services/armada/service-assignment.service';
import {AssignmentAcceptanceStatusService} from '../../../../services/armada/assignment-acceptance-status.service';
import {SituationReportService} from '../../../../services/armada/situation-report.service';
import {JourneyStopService} from '../../../../services/armada/journey-stop.service';
import {takeUntil} from 'rxjs/operators';
import {ConfirmationService, MessageService} from 'primeng/api';
import {DialogService, DynamicDialogRef} from 'primeng/dynamicdialog';
import {FormBuilder} from '@angular/forms';
import {CommanderRegistrationService} from '../../../../services/armada/commander-registration.service';
import {BreadcrumbService} from '../../../../breadcrumb.service';
import {FireBaseAuthService} from '../../../../services/fire-base-auth.service';

@Component({
    selector: 'app-completed-journey-view',
    templateUrl: './completed-journey-view.component.html',
    styleUrls: ['./completed-journey-view.component.scss'],
    providers: [MessageService, DynamicDialogRef, ConfirmationService],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CompletedJourneyViewComponent implements OnInit, OnDestroy {
    @Input() public commanderTask: ServiceAssignment;
    @Input() public startLoading: boolean;
    private unsubscriber$ = new Subject<void>();
    public journeyStarted: boolean;
    public journeyEnded: boolean;
    public clientInformation: LeadDivision;
    private startTime: Date;
    private destroyed$ = new Subject();
    public journeyData: JourneyStart;
    journeyStops: JourneyStop[] = [];
    situationReports: SituationReport[] = [];
    public loading: boolean;
    public commanders: CommanderUserProfile[];
    public selectedCommander: CommanderUserProfile;
    public serviceAssignment: ServiceAssignment;
    public assignedVehicles: SupplierServices[];
    public selectedVehicle: SupplierServices;
    constructor(
        private confirmationService: ConfirmationService,
        private messageService: MessageService,
        private dialogServices: DialogService,
        private formBuilder: FormBuilder,
        private commanderProfileService: CommanderRegistrationService,
        private breadcrumbService: BreadcrumbService,
        private fireBaseAuthService: FireBaseAuthService,
        private journeyStartService: JourneyStartService,
        private customerDivisionService: CustomerDivisionService,
        private changeDetector: ChangeDetectorRef,
        private serviceAssignmentService: ServiceAssignmentService,
        private assignmentAcceptanceStatus: AssignmentAcceptanceStatusService,
        private situationReportService: SituationReportService,
        private journeyStopService: JourneyStopService,
    ) {
    }

    ngOnInit(): void {
        interval(1000).subscribe(() => {
            if (!this.changeDetector['destroyed']) {
                this.changeDetector.detectChanges();
            }
        });
        this.changeDetector.detectChanges();
        this.journeyEnded = false;
        if(this.startLoading){
          this.fetchCommanderTask();
        }
    }

    fetchCommanderTask() {
        this.loading = true;
        // this.fetchCommandTaskStatus(this.commanderTask.id);
      if(this.commanderTask) {
          this.serviceAssignment = this.commanderTask;
          this.commanders = this.serviceAssignment.commanderUserProfiles;
          this.assignedVehicles = this.serviceAssignment.vehicles;
          this.selectVehicle(this.assignedVehicles[0]);
        this.fetchClientInfo(this.commanderTask.contractServiceId);
          this.fetchJourneyStart();
          this.fetchJourneyStops();
          this.fetchSituationReports();
      }
      this.startLoading = false;
    }

    fetchJourneyStart() {
        this.journeyStartService.allJourneyStart()
            .pipe(takeUntil(this.unsubscriber$)).subscribe((res: any) => {
            const _data: JourneyStart[] = res;
            this.journeyData = _data.find(x => x.serviceAssignmentId === this.commanderTask.id);
            if (this.journeyData) {
                this.journeyEnded = this.journeyData.endedById > 0;
            }
        })
    }

    fetchClientInfo(contractServiceId) {
        this.customerDivisionService.getClient(contractServiceId).pipe(takeUntil(this.unsubscriber$))
            .subscribe((result: any) => {
                this.clientInformation = result;
                this.loading = false;
            }, error => {
                this.connectionError();
            })
    }

    public journeyStartTimmer(): TimeSpan | boolean {
        let hours = 0;
        let minutes = 0;
        let seconds = 0;
        const endTime = this.journeyEnded ? this.journeyData?.journeyEndDateTime : new Date();
        this.journeyStarted = this.commanderTask?.journeyStarted ?? false;
       /* if (!this.journeyStarted) {
            return {
                hours,
                minutes,
                seconds
            };
        }*/
        this.startTime = new Date(new Date(this.journeyData?.journeyStartDateTime).getTime() - (1000));
        let totalSeconds = Math.floor((new Date(endTime).getTime() - this.startTime.getTime()) / 1000);
        if (totalSeconds >= 3600) {
            hours = Math.floor(totalSeconds / 3600);
            totalSeconds -= 3600 * hours;
        }
        if (totalSeconds >= 60) {
            minutes = Math.floor(totalSeconds / 60);
            totalSeconds -= 60 * minutes;
        }
        seconds = totalSeconds;
        return {
            hours,
            minutes,
            seconds
        };
    }

    fetchSituationReports() {
        this.situationReportService.getByContractService(this.commanderTask.contractServiceId)
            .pipe(takeUntil(this.unsubscriber$)).subscribe((res: SituationReport[]) => {
            this.situationReports = res;
        }, error => {
            if (error.status !== 404) {
                this.connectionError();
            }
        })
    }

    fetchJourneyStops() {
        this.journeyStopService.getByContractService(this.commanderTask.contractServiceId)
            .pipe(takeUntil(this.unsubscriber$)).subscribe((result: JourneyStop[]) => {
            this.journeyStops = result;
        }, error => {
            if (error.status !== 404) {
                this.connectionError();
            }
        })
    }
    selectCommander(commander: CommanderUserProfile) {
        this.selectedCommander = commander;
    }
    selectVehicle(vehicle: SupplierServices) {
        this.selectedVehicle = vehicle;
        this.selectCommander(this.selectedVehicle.commanderUserProfiles[0]);
    }

    private connectionError() {
        this.messageService.add({
            severity: 'error', summary: 'Failed',
            detail: 'Connection Error, Please try again'
        });
    }

    ngOnDestroy(): void {
        this.destroyed$.next();
        this.destroyed$.complete();
        this.unsubscriber$.complete();
        this.unsubscriber$.unsubscribe();
    }
}
