import { Component, OnInit } from "@angular/core";
import {
  DialogService,
  DynamicDialogConfig,
  DynamicDialogRef,
} from "primeng/dynamicdialog";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { AngularFireStorage } from "@angular/fire/storage";
import { MessageService } from "primeng/api";
import {
  Branch,
  CData,
  Constant,
  GeneralService,
  Office,
  QuoteServiceDocument,
  QuoteServices,
  RequiredServiceDocument,
  Role,
  SBUQuoteServiceProportion,
  SbuToQuoteServiceProp,
  Services,
  User,
} from "../../../../interfaces/home";
import { UserService } from "../../../../services/user.service";
import { ServiceDocumentFormComponent } from "../../lead-negotiation/service-document-form/service-document-form.component";
import { LeadService } from "../../../../services/lead.service";
import { QuoteServicesService } from "../../../../services/quote-services.service";
import { SbuQuoteServiceProportionService } from "../../../../services/sbu-quote-service-proportion.service";
import { FireBaseAuthService } from "src/app/services/fire-base-auth.service";

@Component({
  selector: "app-service-closure",
  templateUrl: "./service-closure.component.html",
  styleUrls: ["./service-closure.component.scss"],
  providers: [Constant, DialogService, MessageService],
})
export class ServiceClosureComponent implements OnInit {
  selectedRole1: CData;
  selectedRole2: CData;
  selectedUser1: User;
  selectedUser2: User;
  user: User;
  leadRoles: CData[];
  public authUser: User = null;
  serviceUserForm: FormGroup;
  selectedBranch: Branch;
  branches: Branch[];
  selectedOffice: Office;
  offices: Office[];
  users: User[];
  selectedQuoteService: QuoteServices = null;
  quoteService: QuoteServices = null;
  quoteServiceDocuments: QuoteServiceDocument[];
  sbuQuoteServiceProportions: SBUQuoteServiceProportion[] = [];
  selectedGeneralService: GeneralService;
  invoiceIntervals: CData[];
  paymentIntervals: CData[];
  invoiceIntervalValue: CData;
  paymentCycleValue: CData;
  public selectedService: Services;
  public message: any;
  public documentFIle: any[] = [];
  public imageSrc: string;
  public awardDocumentUploaded = false;
  public riskAssessmentReportUploaded = false;
  pageLoading = true;
  selectedRole: CData;
  selectedUser3: User;
  selectedRole3: CData;
  savingLeadForClosure: boolean = false;
  isGroupUpload: boolean = false;
  quoteId: number = 0;
  allQuoteServices: QuoteServices[];
  anyHasUploadDocument: boolean = false;
  sbuToQuoteProp: SbuToQuoteServiceProp[] = [];

  constructor(
    public closureFormRef: DynamicDialogRef,
    public modalRef: DynamicDialogRef,
    public config: DynamicDialogConfig,
    public messageService: MessageService,
    public firebaseAuthService: FireBaseAuthService,
    public userService: UserService,
    public dialogService: DialogService,
    public constant: Constant,
    public leadService: LeadService,
    private formBuilder: FormBuilder,
    private quoteServiceServices: QuoteServicesService,
    private sbuQuoteServiceProportionService: SbuQuoteServiceProportionService
  ) {}

  async ngOnInit() {
    // get the authenticated user
    this.leadRoles = this.constant.leadRole;
    this.authUser = this.firebaseAuthService.authUserProfile;
    this.invoiceIntervals = this.constant.invoicingIntervals;
    this.paymentIntervals = this.constant.paymentCycles;
    // if (this.config.data.hasOwnProperty('service'))
    // {
    //     this.selectedGeneralService = this.config.data.generalService;
    //     this.selectedService = this.selectedGeneralService.service;
    // } else {
    //     this.selectedService = this.config.data.generalService;
    // }

    this.selectedGeneralService = this.config.data.generalService;
    this.selectedService = this.selectedGeneralService.service;
    this.allQuoteServices = this.config.data.allQuoateServices;

    this.isGroupUpload = this.config.data.isGroupUpload;
    this.quoteId = this.config.data.quoteId;

    const defaultRole = this.leadRoles.find(
      (leadRole) => (leadRole.value = "leadGenerator")
    );
    this.serviceUserForm = this.formBuilder.group({
      user: [this.authUser.firstName],
      role: [""],
      user1: [""],
      role1: [""],
      user2: [""],
      role2: [""],
      user3: [""],
      role3: [""],
    });
    this.selectedRole = defaultRole;
    await this.fetchQuoteService();
  }

  async fetchAvailableSbuUser(id) {
    await this.userService.getUserNotInSbu(id).subscribe(async (res: any) => {
      this.users = res.responseData;
    });
  }

  async fetchQuoteService() {
    await this.quoteServiceServices
      .getQuoteServices(this.selectedGeneralService.quoteService.id)
      .subscribe(async (res) => {
        this.quoteService = res.responseData;
        this.selectedQuoteService = this.quoteService;
        this.quoteServiceDocuments =
          this.selectedQuoteService.quoteServiceDocuments;
        if (this.quoteServiceDocuments.length > 0) {
          // check if a the required document has been uploaded
          const _awardDocument = this.quoteServiceDocuments.find(
            (_quoteService) =>
              _quoteService.caption.toLowerCase() ===
              "Award Document".toLowerCase()
          );
          const _assessmentReport = this.quoteServiceDocuments.find(
            (_quoteService) =>
              _quoteService.caption.toLowerCase() ===
              "Risk Assessment Report".toLowerCase()
          );

          if (_awardDocument) {
            this.awardDocumentUploaded = true;
          }

          if (_assessmentReport) {
            this.riskAssessmentReportUploaded = true;
          }
        }
        // set the invoice interval value
        if (this.quoteService) {
          this.sbuQuoteServiceProportions =
            this.quoteService.sbutoQuoteServiceProportions;
        }

        if (this.authUser.sbuId !== 0) {
          await this.fetchAvailableSbuUser(this.authUser.sbuId);
        }
        this.invoiceIntervalValue = this.invoiceIntervals.find(
          (interval) =>
            interval.id === this.selectedQuoteService.invoicingInterval
        );
        this.paymentCycleValue = this.paymentIntervals.find(
          (interval) => interval.id === this.selectedQuoteService.paymentCycle
        );
        this.pageLoading = false;
      });
  }

  closeModal() {
    this.modalRef.close();
  }

  uploadDocument(serviceDocument: RequiredServiceDocument, id: number) {
    this.closureFormRef = this.dialogService.open(
      ServiceDocumentFormComponent,
      {
        header: "Quote service Document",
        contentStyle: { "min-height": "120px", overflow: "auto" },
        baseZIndex: 999,
        data: {
          document: serviceDocument,
          quoteServiceId: id,
          isGroupUpload: this.isGroupUpload,
          quoteId: this.quoteId,
        },
      }
    );
    this.closureFormRef.onClose.subscribe((res) => {
      if (res === "complete") {
        this.fetchQuoteService();
      }
    });
  }

  async saveLeadForClosure() {
    // get the list of documents
    if (this.authUser == null) {
      await this.messageService.add({
        severity: "error",
        summary: "Canceled",
        detail: "Invalid user detail",
      });
      return;
    }
    if (!this.awardDocumentUploaded) {
      await this.messageService.add({
        severity: "error",
        summary: "Canceled",
        detail: "Upload award document to continue",
        sticky: true,
      });
      return;
    }

    if (this.isGroupUpload) {
      this.selectedQuoteService.id = this.allQuoteServices[0].id;
    }

    // check if there is user added to the data.
    // save the user in proportion
    this.sbuToQuoteProp = [];
    if (this.authUser.sbuId) {
      this.savingLeadForClosure = true;

      const _userData = {
        status: this.selectedRole.id,
        userInvolvedId: this.authUser.id,
        quoteServiceId: this.selectedQuoteService.id,
        strategicBusinessUnitId: this.authUser.sbuId,
      };

      this.sbuToQuoteProp.push(_userData);

      const closureUser = [];
      const generatorUser = [];
      const closureAndGeneratorUser = [];
      if (this.selectedRole.value === "leadClosure") {
        closureUser.push(this.selectedRole.value);
      } else if (this.selectedRole.value === "leadGeneratorAndClosure") {
        closureAndGeneratorUser.push(this.selectedRole.value);
      } else {
        generatorUser.push(this.selectedRole.value);
      }
      for (let i = 1; i <= 2; i++) {
        const _user = this.serviceUserForm.get("user" + i).value;
        if (_user) {
          if (!this.serviceUserForm.get("role" + i).value) {
            this.messageService.add({
              severity: "error",
              summary: "Canceled",
              detail: "Add role to all the selected User or remove the user",
              sticky: false,
            });
            this.savingLeadForClosure = false;
            return;
          }
          const role = this.serviceUserForm.get("role" + i).value;
          if (role.value === "leadClosure") {
            closureUser.push(role.status);
          } else if (role.value === "leadGeneratorAndClosure") {
            closureAndGeneratorUser.push(role.status);
          } else {
            generatorUser.push(role.status);
          }
          this.sbuToQuoteProp.push({
            status: role.id,
            userInvolvedId: _user.id,
            quoteServiceId: this.selectedQuoteService.id,
            strategicBusinessUnitId: _user.sbuId,
          });
        }
      }

      if (closureUser.length <= 0 && closureAndGeneratorUser.length <= 0) {
        this.messageService.add({
          severity: "error",
          summary: "Canceled",
          detail: "select at least a user to close the lead",
          sticky: false,
        });
        this.savingLeadForClosure = false;
        return;
      }
      if (generatorUser.length <= 0 && closureAndGeneratorUser.length <= 0) {
        this.messageService.add({
          severity: "error",
          summary: "Canceled",
          detail: "Select at least a lead generator",
          sticky: false,
        });
        this.savingLeadForClosure = false;
        return;
      }

      if (this.isGroupUpload) {
        let postData = [];

        for (let index = 0; index < this.sbuToQuoteProp.length; index++) {
          const value = this.sbuToQuoteProp[index];

          for (let index = 0; index < this.allQuoteServices.length; index++) {
            const _userData = {
              ...value,
              quoteServiceId: this.allQuoteServices[index]?.id,
            };

            postData.push(_userData);
          }
        }

        this.sbuToQuoteProp = postData;
      }

      await this.createSbuService(this.sbuToQuoteProp);
      await this.fetchQuoteService();
    } else {
      await this.messageService.add({
        severity: "error",
        summary: "Canceled",
        detail: `User ${
          this.authUser.firstName + " " + this.authUser.lastName
        } is not attached to an sbu.`,
        sticky: false,
      });
    }
  }

  async createSbuService(data) {
    await this.sbuQuoteServiceProportionService
      .post(data)
      .subscribe(async (res: any) => {
        this.savingLeadForClosure = false;
        await this.messageService.add({
          severity: "success",
          summary: "Completed",
          detail: "user added successfully",
          sticky: true,
        });
        this.modalRef.close("complete");
      });
  }

  markRoleRequired(id: any) {
    const _userControl = this.serviceUserForm.get("user" + id);
    const _userRole = this.serviceUserForm.get("role" + id);
    if (_userControl.value) {
      _userRole.setValidators(Validators.required);
    }
  }
}
