import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../environments/environment";
import { Observable } from "rxjs";
import { CommonResponse } from "../interfaces/home";

@Injectable({
  providedIn: "root",
})
export class OtherUsersService {
  constructor(private http: HttpClient) {}

  baseUrl = environment.baseUrl + "User/";
  // fetch all other-users in the database
  allOtherUsersData(): Observable<CommonResponse> {
    return this.http.get<CommonResponse>(this.baseUrl + "otherUsers");
  }
  allHalogenUsersData(): Observable<CommonResponse> {
    return this.http.get<CommonResponse>(this.baseUrl + "HalogenUsers");
  }
  getOtherUserById(id: any): Observable<CommonResponse> {
    return this.http.get<CommonResponse>(this.baseUrl + id);
  }
  postOtherUsers(data: any) {
    return this.http.post(this.baseUrl + "CreateOtherUser", data);
  }
  postHalogenUsers(data: any) {
    return this.http.post(this.baseUrl + "CreateHalogenUser", data);
  }

  disableUsers(emails: any[]) {
    return this.http.put(this.baseUrl + "DisableUsers", emails);
  }

  resetPasswordUsers(emails: any[]) {
    return this.http.put(this.baseUrl + "ResetUserPassord", emails);
  }

  updateHalogenUserusers(id: any, data: any) {
    return this.http.put(this.baseUrl + "UpdateHalogenUser/" + id, data);
  }

  updateOtherusers(id: any, data: any) {
    var url = this.baseUrl + "UpdateOtherUser/";
    return this.http.put(url + id, data);
  }

  getUsersByEmail(email) {
    return this.http.get<CommonResponse>(this.baseUrl + "email/" + email);
  }
}
