import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { MessageService, Message, ConfirmationService } from "primeng/api";
import { BreadcrumbService } from "src/app/breadcrumb.service";
import {
  GetAllUnMappedInvoicesAndCreditNotesViewDit,
  CustomerDivision,
  GetGeneratedProfomaInvoiceDetailView,
  ProfomaInvoiceView,
  CloseOutProfomaInvoiceVM,
  User,
} from "src/app/interfaces/home";
import { CustomerDivisionService } from "src/app/services/customer-division.service";
import { FireBaseAuthService } from "src/app/services/fire-base-auth.service";
import { InvoiceService } from "src/app/services/invoice.sevice";
import domtoimage from "dom-to-image";
import { jsPDF } from "jspdf";
import { UtilService } from "src/app/services/util.service";
import { UserService } from "src/app/services/user.service";

@Component({
  selector: "app-express-performa-closeout",
  templateUrl: "./express-performa-closeout.component.html",
  styleUrls: ["./express-performa-closeout.component.scss"],
  providers: [MessageService],
})
export class ExpressPerformaCloseoutComponent implements OnInit {
  @ViewChild("formWrapper") public formWrapper: ElementRef;
  msg: Message[] = [];
  allPerfomaInvoices: ProfomaInvoiceView[] = [];
  selectedPerfomaInvoices: ProfomaInvoiceView[] = [];
  fetchingPerfomas: boolean;
  cols: any[];
  isInVoiceTypeRecurring: number;
  allUnMatchedInvoices: GetAllUnMappedInvoicesAndCreditNotesViewDit[];
  allUnMatchedInvoicesGroup: {
    value: number;
    groupInvoiceNumber: string;
    invoiceNumber: string;
    caption: string;
    contractId: number;
    startDate: string;
    actualStartDate: Date;
    invoices: GetAllUnMappedInvoicesAndCreditNotesViewDit[];
  }[] = [];
  selectedUnMatchedInvoicesGroup: {
    value: number;
    groupInvoiceNumber: string;
    invoiceNumber: string;
    caption: string;
    contractId: number;
    startDate: string;
    actualStartDate: Date;
    invoices: GetAllUnMappedInvoicesAndCreditNotesViewDit[];
  }[] = [];
  selectedUnMatchedInvoices: GetAllUnMappedInvoicesAndCreditNotesViewDit[] = [];
  allUnMatchedInvoicesAdhoc: GetAllUnMappedInvoicesAndCreditNotesViewDit[];
  allUnMatchedInvoicesGroupAdhoc: {
    value: number;
    groupAdhocGroupingId: string;
    invoiceNumber: string;
    caption: string;
    contractId: number;
    startDate: string;
    actualStartDate: Date;
    invoices: GetAllUnMappedInvoicesAndCreditNotesViewDit[];
  }[] = [];
  selectedUnMatchedInvoicesGroupAdhoc: {
    value: number;
    groupAdhocGroupingId: string;
    invoiceNumber: string;
    caption: string;
    contractId: number;
    startDate: string;
    actualStartDate: Date;
    invoices: GetAllUnMappedInvoicesAndCreditNotesViewDit[];
  }[] = [];
  selectedUnMatchedInvoicesAdhoc: GetAllUnMappedInvoicesAndCreditNotesViewDit[] =
    [];
  allUnMatchedCreditNotes: GetAllUnMappedInvoicesAndCreditNotesViewDit[];
  selectedUnMatchedCreditNotes: GetAllUnMappedInvoicesAndCreditNotesViewDit[] =
    [];
  theProfomaInvInView: ProfomaInvoiceView;
  allClients: CustomerDivision[] = [];
  openDetails: boolean;
  fetchingDetails: boolean;
  profomaInvDetails: GetGeneratedProfomaInvoiceDetailView;
  theProfomaInvoiceInView: ProfomaInvoiceView;
  amountInWords: string = "";
  allUsers: User[] = [];

  constructor(
    public utilService: UtilService,
    private userService: UserService,
    private invoiceService: InvoiceService,
    private clientService: CustomerDivisionService,
    public messageService: MessageService,
    private breadcrumbService: BreadcrumbService,
    public confirmationService: ConfirmationService,
    private fireBaseAuthService: FireBaseAuthService
  ) {}

  ngOnInit(): void {
    this.breadcrumbService.setItems([
      {
        label: "Finance",
      },
      {
        label: "Express Performa",
      },
      {
        label: "Close Out",
        routerLink: ["/home/finance/express-performa-closeout"],
      },
    ]);

    this.cols = [
      { field: "customerDivisionId", header: "Client" },
      { field: "contractId", header: "Contract ID" },
      { field: "contractDescription", header: "Contract Desc" },
      { field: "invoiceValue", header: "Invoice Value" },
      { field: "invoiceDate", header: "Invoice Start Date" },
    ];

    this.fetchingPerfomas = true;
    this.FetchAllUsers();
    this.FetchAllClients();
    this.ResetMessageToasters();
  }

  ResetMessageToasters() {
    this.msg = [];
    this.msg.push({
      severity: "info",
      summary:
        "Total of " +
        this.allPerfomaInvoices.length +
        " Express Perfoma Invoices Closed Out.   Please note that invoices used to close out will be within the range of two months till current date.",
    });
  }

  async FetchAllClients() {
    this.clientService.allCustomerDivision().subscribe(
      async (data) => {
        this.allClients = data.responseData;

        this.FetchAllUnclosedProfomas();
      },
      (error) => {
        this.messageService.add({
          severity: "error",
          summary: "Failed",
          detail: error ?? "Some errors occurred",
        });
        this.ResetMessageToasters();
        this.fetchingPerfomas = false;
      }
    );
  }

  async FetchAllUnclosedProfomas() {
    this.fetchingPerfomas = true;
    this.invoiceService.GetAllUnClosedProfomaInvoices().subscribe(
      async (data) => {
        if (data.responseCode != "00") {
          this.messageService.add({
            severity: "error",
            summary: data.responseMsg,
          });
          this.ResetMessageToasters();
          this.fetchingPerfomas = false;
          return;
        }

        this.allPerfomaInvoices = data.responseData;
        this.ResetMessageToasters();
        this.fetchingPerfomas = false;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary:
            "Unable to load unclosed profomas at the moment.. Reason: [" +
            error.error.message +
            "]",
        });
        this.ResetMessageToasters();
        this.fetchingPerfomas = false;
      }
    );
  }

  async FetchAllUsers() {
    this.userService.allUser().subscribe(
      async (r) => {
        var data = r.responseData ?? [];
        this.allUsers = data;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all users at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
        this.ResetMessageToasters();
      }
    );
  }

  GetClientName(clientId: number): string {
    let client = this.allClients.find((x) => x.id == clientId);
    if (client) return client.divisionName;

    return "N/A";
  }

  FormatDateString(date: Date): string {
    let dateString = date.toString();

    try {
      // DateTimeFormatOptions
      const date: Date = new Date(dateString);
      const options: Intl.DateTimeFormatOptions = {
        year: "numeric",
        month: "long",
        day: "numeric",
      };
      const formattedDate: string = new Intl.DateTimeFormat(
        "en-US",
        options
      ).format(date);
      dateString = formattedDate;
    } catch (error) {
      console.log(error);
      console.log(date);
      console.log(
        "Error while converting date string " + date + " exception " + error
      );
    }

    return dateString;
  }

  ViewGeneratedDetail(item: ProfomaInvoiceView) {
    this.openDetails = true;
    this.fetchingDetails = true;
    this.theProfomaInvInView = null;
    this.theProfomaInvoiceInView = item;
    this.invoiceService
      .GetGeneratedProfomaInvoiceDetail(item.transactionId)
      .subscribe(
        async (data) => {
          if (data.responseCode != "00") {
            this.messageService.add({
              severity: "error",
              summary: "Notice",
              detail: data.responseMsg,
            });
            this.ResetMessageToasters();
            this.fetchingDetails = false;
            return;
          }

          this.profomaInvDetails = data.responseData;
          let koboPart = 0;
          let amount = data.responseData.total.toFixed(2);
          let doubleAmt = amount.toString();
          var splitAmt = doubleAmt.split(".");
          var nairaPart = parseInt(splitAmt[0]);
          this.amountInWords =
            this.utilService.NumberToWords(nairaPart) + " Naira";
          if (splitAmt.length > 1) {
            koboPart = parseInt(splitAmt[1]);
            if (koboPart > 0) {
              this.amountInWords +=
                " " + this.utilService.NumberToWords(koboPart) + " Kobo";
            }
          }
          this.fetchingDetails = false;
        },
        (error) => {
          console.log("Error: " + JSON.stringify(error));
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail:
              "Unable to get generated profoma invoice details at the moment.. Reason: [" +
              error.error.message +
              "]",
          });
          this.ResetMessageToasters();
          this.fetchingDetails = false;
        }
      );
  }

  CloseOutGeneratedProfoma(item: ProfomaInvoiceView) {
    this.theProfomaInvoiceInView = null;
    this.allUnMatchedInvoices = [];
    this.selectedUnMatchedInvoices = [];
    this.allUnMatchedInvoicesAdhoc = [];
    this.selectedUnMatchedInvoicesAdhoc = [];
    this.allUnMatchedCreditNotes = [];
    this.selectedUnMatchedCreditNotes = [];
    this.allUnMatchedInvoicesGroup = [];
    this.allUnMatchedInvoicesGroupAdhoc = [];
    this.isInVoiceTypeRecurring = null;
    this.theProfomaInvInView = item;
    this.invoiceService
      .GetAllUnMappedInvoicesAndCreditNotes(item.customerDivisionId)
      .subscribe(
        async (data) => {
          if (data.responseCode != "00") {
            this.messageService.add({
              severity: "error",
              summary: "Notice",
              detail: data.responseMsg,
            });
            this.ResetMessageToasters();
            return;
          }

          this.allUnMatchedInvoices = data.responseData.recurringInvoices;
          this.allUnMatchedInvoicesAdhoc = data.responseData.adhocInvoices;
          this.allUnMatchedCreditNotes = data.responseData.creditNotes;

          this.allUnMatchedInvoicesGroup = [];
          this.allUnMatchedInvoices.forEach((unmatched) => {
            let grpInv = this.allUnMatchedInvoicesGroup.find(
              (x) =>
                x.groupInvoiceNumber == unmatched.groupIdentifier &&
                x.actualStartDate == unmatched.startDate
            );
            if (grpInv) {
              grpInv.value += unmatched.value;
              (grpInv.caption =
                grpInv.startDate +
                " - " +
                grpInv.contractId +
                " - " +
                unmatched.invoiceNumber +
                " - " +
                grpInv.value),
                grpInv.invoices.push(unmatched);
            } else {
              grpInv = {
                value: unmatched.value,
                groupInvoiceNumber: unmatched.groupIdentifier,
                caption:
                  unmatched.startDate +
                  " - " +
                  unmatched.contractId +
                  " - " +
                  unmatched.invoiceNumber +
                  " - " +
                  unmatched.value,
                contractId: unmatched.contractId,
                invoiceNumber: unmatched.invoiceNumber,
                actualStartDate: unmatched.startDate,
                startDate: this.FormatDateString(unmatched.startDate),
                invoices: [unmatched],
              };
              this.allUnMatchedInvoicesGroup.push(grpInv);
            }
          });

          this.allUnMatchedInvoicesGroupAdhoc = [];
          this.allUnMatchedInvoicesAdhoc.forEach((unmatched) => {
            let grpInv = this.allUnMatchedInvoicesGroupAdhoc.find(
              (x) => x.groupAdhocGroupingId == unmatched.groupIdentifier
            );
            if (grpInv) {
              grpInv.value += unmatched.value;
              (grpInv.caption =
                grpInv.startDate +
                " - " +
                grpInv.contractId +
                " - " +
                unmatched.invoiceNumber +
                " - " +
                grpInv.value),
                grpInv.invoices.push(unmatched);
            } else {
              grpInv = {
                value: unmatched.value,
                groupAdhocGroupingId: unmatched.groupIdentifier,
                invoiceNumber: unmatched.invoiceNumber,
                caption:
                  unmatched.startDate +
                  " - " +
                  unmatched.contractId +
                  " - " +
                  unmatched.groupIdentifier +
                  " - " +
                  unmatched.value,
                contractId: unmatched.contractId,
                startDate: this.FormatDateString(unmatched.startDate),
                actualStartDate: unmatched.startDate,
                invoices: [unmatched],
              };
              this.allUnMatchedInvoicesGroupAdhoc.push(grpInv);
            }
          });
        },
        (error) => {
          console.log("Error: " + JSON.stringify(error));
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail:
              "Unable to get unmapped data at the moment.. Reason: [" +
              error.error.message +
              "]",
          });
          this.ResetMessageToasters();
        }
      );
  }

  CloseOutProfomaInvoices() {
    this.confirmationService.confirm({
      header: "Confirmation Dialog",
      icon: "pi pi-exclamation-triangle",
      message:
        "You are about to close out this profoma invoice with the selected mappings, its is an irreversible action. Do you still wish to proceed?",
      acceptLabel: "Yes, Please Proceed",
      rejectLabel: "No, Don't Proceed",
      accept: async () => {
        this.messageService.add({
          severity: "info",
          summary: "Notice",
          detail: "Closing out profoma invoices......",
        });
        this.ResetMessageToasters();

        const postData: CloseOutProfomaInvoiceVM = {
          transactionId: this.theProfomaInvInView.transactionId,
          profomaInvoiceId: this.theProfomaInvInView.id,
          contractId: this.theProfomaInvInView.contractId,
          invoicesIds: [],
          // invoicesIds: this.selectedUnMatchedInvoices.map((x) => x.id),
          creditNoteIds: this.selectedUnMatchedCreditNotes.map((x) => x.id),
        };
        this.selectedUnMatchedInvoicesGroup.forEach((matched) =>
          postData.invoicesIds.push(...matched.invoices.map((x) => x.id))
        );
        this.selectedUnMatchedInvoicesGroupAdhoc.forEach((matched) =>
          postData.invoicesIds.push(...matched.invoices.map((x) => x.id))
        );

        // postData.invoicesIds.push(
        //   ...this.selectedUnMatchedInvoicesAdhoc.map((x) => x.id)
        // );

        this.invoiceService.CloseOutProfomaInvoice(postData).subscribe(
          async (data) => {
            if (data.responseCode != "00") {
              this.messageService.add({
                severity: "error",
                summary: data.responseMsg,
              });
              this.ResetMessageToasters();
              return;
            }

            this.messageService.add({
              severity: "success",
              summary: "Profoma Invoice(s) closed successfully!",
            });
            let item = this.allPerfomaInvoices.find(
              (x) => x.id == this.theProfomaInvInView.id
            );
            const index = this.allPerfomaInvoices.indexOf(item);
            if (index > -1) {
              this.allPerfomaInvoices.splice(index, 1);
            }
            this.theProfomaInvInView = null;
            this.ResetMessageToasters();
          },
          (error) => {
            console.log("Error: " + JSON.stringify(error));
            this.messageService.add({
              severity: "error",
              summary: "Notice",
              detail:
                "Unable to close out profoma invoice at the moment.. Reason: [" +
                error.error.message +
                "]",
            });
            this.ResetMessageToasters();
          }
        );
      },
    });
  }

  HideDetails() {
    this.openDetails = false;
    this.theProfomaInvoiceInView = null;
  }

  PdfDownloadPage() {
    var node = document.getElementById("parentdiv");
    var img;
    var filename;
    var newImage;
    domtoimage
      .toPng(node, { bgcolor: "#fff" })
      .then(function (dataUrl) {
        img = new Image();
        img.src = dataUrl;
        newImage = img.src;
        img.onload = function () {
          var pdfWidth = img.width;
          var pdfHeight = img.height;
          // FileSaver.saveAs(dataUrl, 'my-pdfimage.png'); // Save as Image
          var doc;
          if (pdfWidth > pdfHeight) {
            doc = new jsPDF("l", "px", [pdfWidth, pdfHeight]);
          } else {
            doc = new jsPDF("p", "px", [pdfWidth, pdfHeight]);
          }
          var width = doc.internal.pageSize.getWidth();
          var height = doc.internal.pageSize.getHeight();

          doc.addImage(newImage, "PNG", 10, 10, width, height);
          filename = "receipt" + ".pdf";
          doc.save(filename);
        };
      })
      .catch(function (error) {
        // Error Handling
      });
  }

  PrintPage() {
    window.print();
  }

  GetUserName(userId: number): string {
    let user = this.allUsers.find((x) => x.id == userId);
    if (user) return user.lastName + " " + user.firstName;

    return "N/A";
  }

  get combinedTotalValue(): number {
    let totalSum = 0;
    this.selectedUnMatchedInvoices.forEach((x) => {
      totalSum += x.value;
    });
    this.selectedUnMatchedInvoicesAdhoc.forEach((x) => {
      totalSum += x.value;
    });
    this.selectedUnMatchedCreditNotes.forEach((x) => {
      totalSum -= x.value;
    });

    return totalSum;
  }

  get combinedTotalGroupValue(): number {
    let totalSum = 0;
    this.selectedUnMatchedInvoicesGroup.forEach((x) => {
      totalSum += x.value;
    });
    this.selectedUnMatchedInvoicesGroupAdhoc.forEach((x) => {
      totalSum += x.value;
    });
    this.selectedUnMatchedCreditNotes.forEach((x) => {
      totalSum -= x.value;
    });

    return totalSum;
  }

  get invoiceTotalValue(): number {
    let totalSum = 0;
    this.selectedUnMatchedInvoicesGroup.forEach((x) => {
      totalSum += x.value;
    });
    this.selectedUnMatchedInvoicesGroupAdhoc.forEach((x) => {
      totalSum += x.value;
    });

    return totalSum;
  }

  get creditNoteValue(): number {
    let totalSum = 0;
    this.selectedUnMatchedCreditNotes.forEach((x) => {
      totalSum -= x.value;
    });

    return totalSum;
  }
}
