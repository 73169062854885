import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {CommonResponse, LeadType} from '../../../interfaces/home';
import {LeadTypeService} from '../../../services/lead-type.service';
import {ConfirmationService, MessageService} from 'primeng/api';
import { BreadcrumbService } from 'src/app/breadcrumb.service';

@Component({
  selector: 'app-lead-type',
  templateUrl: './lead-type.component.html',
  styleUrls: ['./lead-type.component.scss'],
  providers: [ConfirmationService, MessageService]
})
export class LeadTypeComponent implements OnInit {
  @ViewChild('formWrapper') public formWrapper:ElementRef;
  fetchingLeadType = true;
  leadTypeForm: FormGroup;
  leadTypes: LeadType[];
  selectedLeadType: LeadType;
  editingLeadType= false;
  public leadTypeCols: any[];
  public exportLeadTypeColumns: any[];
  private edLeadType: LeadType;
  constructor(
      private formBuilder: FormBuilder,
      private leadTypeService: LeadTypeService,
      public messageService: MessageService,
      public confirmationService: ConfirmationService,
      private breadcrumbService: BreadcrumbService
  ) { }

  ngOnInit(): void {
    this.breadcrumbService.setItems([
      { label: 'Qualification', routerLink: ['home/qualification'] },
      { label: 'Lead Type', routerLink: ['home/qualification/lead-type'] },
    ]);

    this.fetchLeadType();
    this.leadTypeForm = this.formBuilder.group({
      description: ['', Validators.required],
      caption: ['', [Validators.required]],
    });
    this.leadTypeCols = [
      { field: 'caption', header: 'Caption' },
      { field: 'description', header: 'Description' },
    ];
    this.exportLeadTypeColumns = this.leadTypeCols.map(col => ({title: col.header, dataKey: col.field}));
  }
  fetchLeadType(){
    this.leadTypeService.allLeadTypeData().subscribe( async (leadType: CommonResponse) =>{
      this.leadTypes = leadType.responseData;
      this.fetchingLeadType = false;
    }, error => {
      this.fetchingLeadType = false;
      this.connectionError();
    })
  }
  private connectionError() {
    this.messageService.add({severity:'error', summary: 'Failed',
      detail:'Connection Error, Please try again'});
  }
  createLeadType(){
    this.messageService.add({severity:'info', summary: 'Notice',
      detail:'Creating new Lead Type Records'});
    const postData = {
      description: this.leadTypeForm.get('description').value,
      caption: this.leadTypeForm.get('caption').value,
    };
    this.leadTypeService.postLeadType(postData).subscribe( async () =>{
      await this.messageService.add({severity:'success', summary: 'Completed',
        detail:'New Lead Type Created'});
      await this.fetchLeadType();
      await this.leadTypeForm.reset();
    }, error => {
      this.messageService.add({severity:'error', summary: 'Notice',
        detail:'Unable to create new Lead Type at the moment'});
    });
  }

  deleteLeadType(leadType) {
    this.confirmationService.confirm({
      message: 'Are you sure you want to drop ' + leadType.caption,
      accept: () => {
        this._deleteLeadType(leadType);
      }
    });
  }
  _deleteLeadType(leadType) {
    this.messageService.add({severity:'info', summary: 'Notice',
      detail:'Dropping leadType record'});
    this.leadTypeService.deleteLeadType(leadType.id).subscribe( async (result:any) => {
      await this.messageService.add({severity:'success', summary: 'Dropped',
        detail:'Lead Type record drop'});
      await this.fetchLeadType();
    }, error => {
      this.connectionError()
    });
  }
  editLeadType(leadTypes) {
    this.editingLeadType = true;
    this.leadTypeForm.addControl('leadTypeId',  new FormControl({value: '', disabled: true}, Validators.required));
    this.edLeadType = leadTypes;
    this.leadTypeForm.patchValue({
      caption: leadTypes.caption,
      description: leadTypes.description,
      leadTypeId: leadTypes.id,
    });
    // this.leadTypeFormIn.nativeElement.focus();
    // document.getElementById('formFields').focus();
    // this.selectedHeadId = this.users.find(user => user.id === leadTypes.head.id);
    this.formWrapper.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'start' });
  }

  updateLeadType() {
    // update the selected leadType
    this.messageService.add({severity:'info', summary: 'Notice',
      detail:'Updating LeadType details'});
    const id = this.edLeadType.id;
    const postData = {
      caption: this.leadTypeForm.get('caption').value,
      description: this.leadTypeForm.get('description').value,
    };
    this.leadTypeService.updateLeadType(id, postData).subscribe( async (result: LeadType) => {
      this.messageService.add({severity:'success', summary: 'Notice',
        detail:'Lead Type details Updated'});
      await this.fetchLeadType();
      await this.closeEditing();
    })
  }

  closeEditing() {
    this.editingLeadType = false;
    this.leadTypeForm.reset();
    this.edLeadType = null;
  }
}
