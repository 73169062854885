import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { MessageService, ConfirmationService, TreeNode } from "primeng/api";
import { BreadcrumbService } from "src/app/breadcrumb.service";
import {
  AddRequestToMaterialRefreshBatchVM,
  ApplicantMaterialRefreshBatch,
  ApplicantMaterialRefreshBatchItem,
  GetMaterialRefreshLocationView,
  MaterialRefreshBatch,
  TrackDetail,
} from "src/app/interfaces/employment";
import { RegionalStore } from "src/app/interfaces/inventory";
import { OperativeItem } from "src/app/interfaces/premployment";
import { GmaEmploymentService } from "src/app/services/gma-employment.service";

@Component({
  selector: "app-gma-material-refresh",
  templateUrl: "./gma-material-refresh.component.html",
  styleUrls: ["./gma-material-refresh.component.scss"],
})
export class GmaMaterialRefreshComponent implements OnInit {
  @ViewChild("formWrapper") public formWrapper: ElementRef;
  ffText: string;
  ssText: string;
  ttText: string;
  ffChk: boolean = true;
  ssChk: boolean = true;
  ttChk: boolean = true;
  allLocationViews: GetMaterialRefreshLocationView[];
  opWithItemsDueRefresh: ApplicantMaterialRefreshBatch[];
  selectedOPWithItemDueRefresh: ApplicantMaterialRefreshBatch[] = [];
  opRefreshItemCols: any[];
  selectedOPsWithItemsDueRefresh: ApplicantMaterialRefreshBatch[] = [];
  opRefreshBatches: MaterialRefreshBatch[] = [];
  opRefreshBatchCols: any[];
  imageUrl: string;
  locationRefreshInView: GetMaterialRefreshLocationView;
  showViewOPPendRefreshDialogue: boolean = false;
  applicantRefreshBatchInView: ApplicantMaterialRefreshBatch;
  allApplicantBatchInViewItems: ApplicantMaterialRefreshBatchItem[];
  selectedApplicantBatchInViewItems: ApplicantMaterialRefreshBatchItem[] = [];
  showItemRefreshDialogue: boolean = false;
  applicantBatchRefreshItems: ApplicantMaterialRefreshBatchItem[] = [];
  openCautionDialogue: boolean = false;
  allRegionStores: RegionalStore[];
  theRegionStore: RegionalStore;
  applicantsWithMaterialReqsTree: TreeNode[];
  openViewMoreDialogue: boolean = false;
  openTrackDialogue: boolean = false;
  materialBatchReqTrackTable: {
    name: string;
    status: number;
    data: TrackDetail[];
  }[];

  constructor(
    public employmentService: GmaEmploymentService,
    public messageService: MessageService,
    private breadcrumbService: BreadcrumbService,
    public confirmationService: ConfirmationService
  ) {}

  ngOnInit(): void {
    this.breadcrumbService.setItems([
      {
        label: "Guard Management",
        routerLink: ["/home/guard-management/set-up"],
      },
      {
        label: "Material Refresh",
        routerLink: ["/home/guard-management/material-refresh"],
      },
    ]);

    this.opRefreshItemCols = [
      {
        field: "jobApplication.personalInformation.firstname",
        header: "Firstname",
      },
      {
        field: "jobApplication.personalInformation.lastname",
        header: "Lastname",
      },
      { field: "jobApplicationId", header: "Operative ID" },
    ];

    this.opRefreshBatchCols = [
      { field: "request", header: "Request" },
      { field: "batchCode", header: "Batch Code" },
      { field: "requestDate", header: "Request Date" },
    ];

    this.materialBatchReqTrackTable = [
      {
        name: "Initial Request",
        status: 0,
        data: null,
      },
      {
        name: "Approval Queue",
        status: 0,
        data: null,
      },
      {
        name: "Confirmation Out",
        status: 0,
        data: null,
      },
      {
        name: "Acceptance In",
        status: 0,
        data: null,
      },
    ];

    this.ffText = "There are 0 Locations under the cluster";
    this.ssText = "There are 0 operatives in total under cluster";
    this.ttText = "There are 0 operatives with items due for replacements";

    this.FetchViewAnalyicsData();
    this.FetchAllRegionStores();
  }

  FetchViewAnalyicsData() {
    this.employmentService.GetMateriaRefreshUserView().subscribe(
      async (data) => {
        this.employmentService
          .GetMateriaRefreshUserViewOngoingBatch()
          .subscribe(
            async (innerData) => {
              this.imageUrl = data.userImageUrl;
              this.ffText =
                "There are " +
                data.locationCount +
                " Locations under the cluster";
              this.ssText =
                "There are " +
                data.operativesCount +
                " operatives in total under cluster";
              this.ttText =
                "There are " +
                data.operativesDueCount +
                " operatives with items due for replacements";

              this.allLocationViews = data.locationViews;
              this.selectedOPsWithItemsDueRefresh = innerData;
              this.opRefreshBatches = data.refreshBatcheMade;
            },
            (error) => {
              this.imageUrl = data.userImageUrl;
              this.ffText =
                "There are " +
                data.locationCount +
                " Locations under the cluster";
              this.ssText =
                "There are " +
                data.operativesCount +
                " operatives in total under cluster";
              this.ttText =
                "There are " +
                data.operativesDueCount +
                " operatives with items due for replacements";

              this.allLocationViews = data.locationViews;
              this.opRefreshBatches = data.refreshBatcheMade;

              console.log("Error: " + JSON.stringify(error));
              this.messageService.add({
                severity: "error",
                summary: "Notice",
                detail:
                  "Unable to get all analytics at the moment.. Reason: [" +
                  error
                    ? error.error.message
                    : "request failed - permission" + "]",
              });
            }
          );
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to get analytics at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  FetchAllRegionStores() {
    this.employmentService.GetAllRegionStores().subscribe(
      async (data) => {
        this.allRegionStores = data;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable get all regional stores at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  ViewOperativeDue(item: GetMaterialRefreshLocationView) {
    this.opWithItemsDueRefresh = item.operativePendingRefresh;
    this.locationRefreshInView = item;
    this.selectedOPWithItemDueRefresh = [];
  }

  MoveOPForItemsRefresh() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Moving Selected Operatives Items For Material Refresh...",
    });

    const postData: AddRequestToMaterialRefreshBatchVM = {
      locationId: this.locationRefreshInView.locationId,
      locationName: this.locationRefreshInView.name,
      batchRefreshItemsPicked: [],
      batchRefreshFullyPicked: [],
    };

    this.selectedOPWithItemDueRefresh.forEach((batch) => {
      let itemsSelected = batch.refreshBatchItems.filter((x) => x.isRequested);
      if (itemsSelected.length > 0) {
        itemsSelected.forEach((batchItem) =>
          postData.batchRefreshItemsPicked.push(batchItem.id)
        );
      } else postData.batchRefreshFullyPicked.push(batch.id);
    });

    this.employmentService.AddRequestToMaterialRefreshBatch(postData).subscribe(
      async () => {
        this.messageService.add({
          severity: "success",
          summary: "Completed",
          detail: "Selected Moved for material refresh Successfully!",
        });

        this.locationRefreshInView = null;
        this.FetchViewAnalyicsData();
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to move selected for material refresh at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  ViewOPItems(item: ApplicantMaterialRefreshBatch) {
    this.applicantRefreshBatchInView = item;
    this.allApplicantBatchInViewItems = item.refreshBatchItems;
    this.selectedApplicantBatchInViewItems = item.refreshBatchItems.filter(
      (x) => x.isRequested == true
    );
    this.showViewOPPendRefreshDialogue = true;
  }

  RaiseMaterialRefreshRequest() {
    this.openCautionDialogue = true;
  }

  ViewSelectedItems(item: ApplicantMaterialRefreshBatch) {
    this.showItemRefreshDialogue = true;
    this.applicantBatchRefreshItems = item.refreshBatchItems;
  }

  ViewBatchInfo(item: MaterialRefreshBatch) {
    this.applicantsWithMaterialReqsTree = [];
    const applicants = [];

    item.refreshBatches.forEach((applicantReq) => {
      const applicantMaterials = [];
      applicantReq.refreshBatchItems.forEach((applicantItem) => {
        applicantMaterials.push({
          label: "Item: " + applicantItem.operativeItem.item.caption,
          key: applicantItem.id,
          icon: "pi pi-fw pi-align-justify",
          data: applicantItem,
          selectable: false,
        });
      });

      applicants.push({
        label:
          "Applicant: " +
          applicantReq.jobApplication.personalInformation.lastname +
          " " +
          applicantReq.jobApplication.personalInformation.othername,
        key: applicantReq.id,
        expandedIcon: "pi pi-minus-circle",
        collapsedIcon: "pi pi-plus-circle",
        expanded: true,
        data: applicantReq,
        children: applicantMaterials,
        selectable: true,
      });
    });

    this.applicantsWithMaterialReqsTree = [
      {
        label: "Material Requests",
        key: "Halogen Folder",
        expandedIcon: "pi pi-minus-circle",
        collapsedIcon: "pi pi-plus-circle",
        expanded: true,
        children: applicants,
        data: 1001,
        selectable: true,
      },
    ];
    this.openViewMoreDialogue = true;
  }

  HideOPPendRefreshDialogue() {
    this.showViewOPPendRefreshDialogue = false;
    this.applicantRefreshBatchInView = null;
    this.allApplicantBatchInViewItems = [];
    this.selectedApplicantBatchInViewItems = [];
  }

  SetApplicantRefreshItems() {
    this.selectedApplicantBatchInViewItems.forEach(
      (x) => (x.isRequested = true)
    );
    this.HideOPPendRefreshDialogue();
  }

  GetDueCount(item: ApplicantMaterialRefreshBatch): number {
    var today = new Date();
    let itemsDue = item.refreshBatchItems.filter(
      (x) => today <= new Date(x.dateToBeRefreshed)
    ).length;

    return itemsDue;
  }

  GetOverDueCount(item: ApplicantMaterialRefreshBatch): number {
    // var today = new Date();

    let itemsOverDue = item.refreshBatchItems.filter(
      (x) =>
        this.GetOperativeItemExpiryNoticeDate(x) > new Date(x.dateToBeRefreshed)
    ).length;

    return itemsOverDue;
  }

  GetOperativeItemExpireNotifyDays(item: OperativeItem): number {
    if (item.is30DaysNotificationEnabled) return 30;
    else if (item.is15DaysNotificationEnabled) return 15;
    else if (item.is10DaysNotificationEnabled) return 10;
    else if (item.is5DaysNotificationEnabled) return 5;
    else if (item.is2DaysNotificationEnabled) return 2;
    else if (item.is1DayNotificationEnabled) return 1;
    else return 30;
  }

  GetOperativeItemExpiryNoticeDate(item: OperativeItem): Date {
    var today = new Date();
    today.setDate(
      today.getDate() + this.GetOperativeItemExpireNotifyDays(item)
    );
    return today;
  }

  IsItemRefreshDue(item: ApplicantMaterialRefreshBatchItem): boolean {
    return (
      this.GetOperativeItemExpiryNoticeDate(item) >
      new Date(item.dateToBeRefreshed)
    );
  }

  GetItemRefreshNotice(item: ApplicantMaterialRefreshBatchItem): string {
    return this.GetOperativeItemExpireNotifyDays(item) + "days";
  }

  RemoveSelectedItemRefresh(item: ApplicantMaterialRefreshBatch) {
    this.confirmationService.confirm({
      message: "Are you sure you want to remove refresh batch?",
      accept: () => {
        this.messageService.add({
          severity: "info",
          summary: "Notice",
          detail: "Removing...",
        });

        this.employmentService
          .RemoveApplicantMaterialRefreshBatch(item.id)
          .subscribe(
            async () => {
              this.messageService.add({
                severity: "success",
                summary: "Removed",
                detail: "Removed successfully",
              });

              this.locationRefreshInView = null;
              this.FetchViewAnalyicsData();
            },
            (error) => {
              console.log("Error: " + JSON.stringify(error));
              this.messageService.add({
                severity: "error",
                summary: "Notice",
                detail:
                  "Unable to remove refresh batch at the moment.. Reason: [" +
                  error
                    ? error.error.message
                    : "request failed - permission" + "]",
              });
            }
          );
      },
    });
  }

  HidItemRefreshDialogue() {
    this.showItemRefreshDialogue = false;
    this.applicantBatchRefreshItems = [];
  }

  HideCautionDialog() {
    this.openCautionDialogue = false;
  }

  InitiateMaterialRequest() {
    this.openCautionDialogue = false;

    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Raising Material Request...",
    });

    this.employmentService
      .RaiseMaterialRefreshMaterialRequest(this.theRegionStore.id)
      .subscribe(
        async () => {
          this.messageService.add({
            severity: "success",
            summary: "Removed",
            detail: "Material Request Raised successfully",
          });

          this.theRegionStore = null;
          this.locationRefreshInView = null;
          this.FetchViewAnalyicsData();
        },
        (error) => {
          console.log("Error: " + JSON.stringify(error));
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail:
              "Unable to raise material request at the moment.. Reason: [" +
              error
                ? error.error.message
                : "request failed - permission" + "]",
          });
        }
      );
  }

  HideViewMoreDialog() {
    this.openViewMoreDialogue = false;
  }

  HideTrackDialog() {
    this.openTrackDialogue = false;
  }

  TrackBatchInfo(item: MaterialRefreshBatch) {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Tracking Batch Current Progress...",
    });

    this.employmentService.TrackBatch(item.refreshBatchMadeId).subscribe(
      async (data) => {
        this.messageService.add({
          severity: "success",
          summary: "Removed",
          detail: "Material Request Batch Tracked Successfully",
        });

        this.materialBatchReqTrackTable[0].status = data[0].status;
        this.materialBatchReqTrackTable[1].status = data[1].status;
        this.materialBatchReqTrackTable[2].status = data[2].status;
        this.materialBatchReqTrackTable[3].status = data[3].status;

        this.materialBatchReqTrackTable[0].data = data[0].trackDetails;
        this.materialBatchReqTrackTable[1].data = data[1].trackDetails;
        this.materialBatchReqTrackTable[2].data = data[2].trackDetails;
        this.materialBatchReqTrackTable[3].data = data[3].trackDetails;

        this.openTrackDialogue = true;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to track material request at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  IsItemRefreshOverDue(item: ApplicantMaterialRefreshBatchItem): boolean {
    var today = new Date();

    return today > new Date(item.dateToBeRefreshed);
  }
}
