import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { ConfirmationService, MessageService } from "primeng/api";
import { DialogService, DynamicDialogRef } from "primeng/dynamicdialog";
import { BreadcrumbService } from "src/app/breadcrumb.service";
import {
  DeliverableFulfillment,
  DeliverableProportion,
  ServiceDivisionDetails,
  ServiceTaskDeliverable,
  TaskFulfillment,
  User,
} from "src/app/interfaces/home";
import { DeliverableFulfillmentService } from "src/app/services/deliverable-fulfillment.service";
import { FireBaseAuthService } from "src/app/services/fire-base-auth.service";
import { TaskFulfillmentService } from "src/app/services/task-fulfillment.service";
import { DeliverableFormComponent } from "../deliverable-form/deliverable-form.component";

@Component({
  selector: "app-redo-project-management",
  templateUrl: "./redo-project-management.component.html",
  styleUrls: ["./redo-project-management.component.scss"],
})
export class RedoProjectManagementComponent implements OnInit {
  @ViewChild("deliverableView") public deliverableView: ElementRef;
  tasks: TaskFulfillment[];
  selectedTask: TaskFulfillment;
  taskLoadingDeliveriesFor: TaskFulfillment;
  taskCols: any;
  authUser: User;
  loadingDeliverable = false;
  taskDeliverables: ServiceTaskDeliverable[];
  public serviceDivisionDetails: ServiceDivisionDetails;
  deliverableCols: any;
  public loadingTaskProportion: boolean;
  public chartData: any;
  public TaskProportionData: DeliverableProportion[];
  assignDialogRef: DynamicDialogRef;
  pickedTask: TaskFulfillment;
  deliverableToLoad: ServiceTaskDeliverable;
  assignTable: {
    name: string;
    data: string;
  }[];
  selectedAssignTable: {
    name: string;
    data: string;
  };
  priorities: { id: number; value: string }[];
  selectedPriority: { id: number; value: string };

  constructor(
    private fb: FormBuilder,
    private firebaseAuthService: FireBaseAuthService,
    private taskFulfillmentService: TaskFulfillmentService,
    private deliverableFulfillmentService: DeliverableFulfillmentService,
    private breadcrumbService: BreadcrumbService,
    public confirmationService: ConfirmationService,
    public messageService: MessageService,
    private dialogService: DialogService
  ) {
    fb.group({
      description: ["", Validators.required],
      // startDate: [
      //   new Date(this.taskLoadingDeliveriesFor.startDate),
      //   Validators.required,
      // ],
      duration: [0, Validators.required],
      // endDate: [new Date(this.taskLoadingDeliveriesFor.startDate)],
      // taskEndDate: [new Date(this.taskLoadingDeliveriesFor.endDate)],
      priority: ["", Validators.required],
      responsibleId: [null, Validators.required], // assigned
      budget: ["", Validators.required],
      escallationTimeDurationForPicking: [0, Validators.required],
    });
  }

  async ngOnInit() {
    this.breadcrumbService.setItems([
      {
        label: "Project Management",
        routerLink: ["/home/redo-project-management"],
      },
      {
        label: "Task and Deliverable Assignment",
        routerLink: ["/home/redo-project-management"],
      },
    ]);

    this.taskCols = [{ field: "caption", header: "caption" }];
    this.authUser = await this.firebaseAuthService.authUserProfile;
    this.deliverableCols = [
      { field: "name", header: "Name" },
      { field: "missionStatement", header: "Mission Statement" },
      { field: "description", header: "Description" },
      { field: "head", header: "Head" },
    ];
    this.FetchAllTasksTiedToTaskOwner();
    await this.fetchTaskAssignedRatio();
    this.assignTable = [
      {
        name: "Project",
        data: "Data",
      },
      {
        name: "Task",
        data: "Data",
      },
      {
        name: "Deliverable",
        data: "Data",
      },
    ];
    this.priorities = [
      {
        id: 0,
        value: "Low",
      },
      {
        id: 1,
        value: "Medium",
      },
      {
        id: 2,
        value: "High",
      },
    ];
  }

  async FetchAllTasksTiedToTaskOwner() {
    await this.taskFulfillmentService
      .getUncompleteTaskByOwner(this.authUser.id)
      .subscribe(async (res: any) => {
        this.tasks = res;
      });
  }

  async LoadDeliverable(item: TaskFulfillment) {
    this.messageService.add({
      severity: "info",
      summary: "Info",
      detail: "Loading Task Deliverables",
    });

    this.loadingDeliverable = true;
    this.taskLoadingDeliveriesFor = item;
    await this.taskFulfillmentService
      .getTaskFulfillment(item.id)
      .subscribe(async (res: any) => {
        this.taskDeliverables = res.deliverableFulfillments;
        this.serviceDivisionDetails = res.serviceDivisionDetails;
        this.loadingDeliverable = false;
      });
  }

  async ViewDeliverable(item: ServiceTaskDeliverable) {
    this.deliverableToLoad = item;
    this.messageService.add({
      severity: "info",
      summary: "Info",
      detail: "Loading Deliverable",
    });
  }

  async fetchTaskAssignedRatio() {
    this.loadingTaskProportion = true;
    this.deliverableFulfillmentService
      .getDeliverableProportion(this.authUser.id)
      .subscribe((res: any) => {
        this.loadingTaskProportion = false;
        this.TaskProportionData = res;
        const _labels = [];
        const _data = [];
        const _color = [];
        const pool = "ABCDEF0123456789";
        if (this.TaskProportionData.length >= 1) {
          this.TaskProportionData.forEach((tPData, key) => {
            // let crypto = require('crypto');
            // const color = crypto.getRandomValues(pool).toString('hex');
            _labels.push(tPData.user.firstName);
            _data.push(tPData.proportion);
            _color.push(this.generateColor());
          });
        }
        this.chartData = {
          labels: _labels,
          datasets: [
            {
              label: "Work Load",
              backgroundColor: _color,
              borderColor: "#1E88E5",
              data: _data,
            },
          ],
        };
      });
  }

  generateRandomNumber(): number {
    return Math.floor(Math.random() * 256);
  }

  generateColor(): string {
    return `rgb(255, ${this.generateRandomNumber()}, ${this.generateRandomNumber()})`;
  }

  async assignDeliverable(
    deliverableFulfillment?: DeliverableFulfillment,
    type?: string
  ) {
    this.pickedTask = this.taskLoadingDeliveriesFor;
    this.assignDialogRef = await this.dialogService.open(
      DeliverableFormComponent,
      {
        styleClass: "px-3 py-4 bg-white",
        showHeader: false,
        width: "71%",
        style: { height: "100%" },
        data: { task: this.pickedTask, deliverableFulfillment, type },
      }
    );
    this.assignDialogRef.onClose.subscribe(async (res) => {
      if (res === "complete") {
        await this.pickTask(this.pickedTask);
        await this.fetchTaskAssignedRatio();
      }
    });
  }

  async pickTask(task: TaskFulfillment) {
    this.loadingDeliverable = true;
    this.pickedTask = task;
    await this.deliverableView.nativeElement.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "start",
    });
    if (task) {
      if (!task.isPicked) {
        await this.taskFulfillmentService
          .setIsPicked(this.pickedTask.id)
          .subscribe(async () => {
            await this.FetchAllTasksTiedToTaskOwner();
          });
      }
      // fetch the task details
      await this.fetchTaskFulfilment(task);
    }
  }

  async fetchTaskFulfilment(task) {
    this.loadingDeliverable = true;
    await this.taskFulfillmentService
      .getTaskFulfillment(task.id)
      .subscribe(async (res: any) => {
        this.taskDeliverables = res.deliverableFulfillments;
        this.serviceDivisionDetails = res.serviceDivisionDetails;
        this.loadingDeliverable = false;
      });
  }
}
