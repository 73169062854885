import { ServiceRegistrationService } from "src/app/services/armada/service-registration.service";
import { PriceRegisterService } from "src/app/services/armada/price-register.service";
import {
  PreferentialPricing,
  ServiceRegistration,
} from "./../../../../interfaces/armada";
import { Component, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Table } from "jspdf-autotable";
import { ConfirmationService, MessageService } from "primeng/api";
import { DialogService, DynamicDialogRef } from "primeng/dynamicdialog";
import { BreadcrumbService } from "src/app/breadcrumb.service";
import {
  CommonResponse,
  Contract,
  ContractService,
  InvoiceMailDTO,
} from "src/app/interfaces/home";
import { ContractServiceService } from "src/app/services/contract-service.service";
import { CustomerDivisionService } from "src/app/services/customer-division.service";
import { InvoiceService } from "src/app/services/invoice.sevice";
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";

@Component({
  selector: "app-preferential-pricing",
  templateUrl: "./preferential-pricing.component.html",
  styleUrls: ["./preferential-pricing.component.scss"],
})
export class PreferentialPricingComponent implements OnInit {
  clientForm: FormGroup;
  pricingForm: FormGroup;
  private unsubscriber$ = new Subject<void>();
  public targetCols: any;
  public submittingData: boolean;
  public loading: boolean;
  customerDivisions: any;
  clientsId: number;
  serviceRegId: number;
  selectedCustomerDivision: any;
  viewedContractService: null;
  customerDivisionInformation: any;
  selectedContract: any;
  contracts: any[];
  primaryContactFullName: string;
  secondaryContactFullName: string;
  fetchingContractServices = false;
  fetchingContractServicesDet = false;
  contractServices: ContractService[];
  selectedContractService: ContractService;
  preferentialPricing: PreferentialPricing[];
  selectedpreferentialPricing: PreferentialPricing;
  serviceRegistration: ServiceRegistration;
  contractServiceCols: any;
  generatePreformalClicked = false;
  contractServiceClicked = false;
  namee: string = "test data";
  hiddenIsGroupedAdhoc = false;
  isGroupInvoice = false;
  isAdHocContractService = false;
  billable: number;
  vat: number;
  value: number;
  unInvoicedAmount: number;
  totalContractBillable: number;
  groupBillable: number;
  groupVat: number;
  groupValue: number;
  groupUnInvoicedAmount: number;
  groupTotalContractBillable: number;
  groupInvoiceContractServices: ContractService[];
  showMaximizableDialog: any;
  fetchingInvoiceDetails = false;
  invoiceDetails: InvoiceMailDTO = null;
  displayMaximizable = false;
  viewInvoiceRef: DynamicDialogRef;
  showCreateProformaForm: boolean = true;
  selectContractGroupSendDate: any = null;
  invalidInvoiceSendDate: boolean = false;
  contractIdTracker: number = 0;
  submitted: boolean = false;
  fetchingFinalInvoices = false;
  fetchingProformaInvoices = false;
  finalInvoices: any[];
  selectedFinalInvoice: any;
  showPreferentialPricing: boolean = false;
  preformalInvoices: any[] = [];
  @ViewChild(Table) proforma: Table;

  constructor(
    public formBuilder: FormBuilder,
    private customerDivisionService: CustomerDivisionService,
    public messageService: MessageService,
    public confirmationService: ConfirmationService,
    private breadcrumbService: BreadcrumbService,
    private invoiceService: InvoiceService,
    private contractServiceService: ContractServiceService,
    private serviceRegistrationService: ServiceRegistrationService,
    private priceRegisterService: PriceRegisterService,
    private dialogService: DialogService
  ) {}

  ngOnInit(): void {
    this.breadcrumbService.setItems([
      {
        label: "Preferential-Pricing",
        routerLink: ["home/armada/setup/preferential-pricing"],
      },
    ]);

    this.getCustomerDivisions();
    this.clientForm = this.formBuilder.group({
      customerId: [null, Validators.required],
    });

    this.pricingForm = this.formBuilder.group({
      clientId: [null, Validators.required],
      contractServiceId: [null, Validators.required],
      registeredServiceId: [null, Validators.required],
      price: [null, Validators.required],
      priceDescription: ["", Validators.required],
    });
    //this.fetchAllPreferentialPricing();
  }

  //PrefPricing
  createPreferentialPricing() {
    this.submittingData = true;
    //this.loading = true;
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Adding new price",
    });
    const formData = this.pricingForm.value;
    const postData = {
      clientId: formData.clientId,
      contractServiceId: formData.contractServiceId,
      registeredServiceId: formData.registeredServiceId,
      priceDescription: formData.priceDescription,
      price: formData.price,
    };
    this.priceRegisterService
      .postPreferentialPrice(postData)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            this.messageService.add({
              summary: "Success",
              severity: "success",
              detail: "Price added",
            });
            //this.fetchAllPreferentialPricing();
            this.priceRegisterService
              .getAllPreferentialPricingsByContractServiceAndClientId(
                this.selectedContractService.id,
                this.selectedCustomerDivision.id
              )
              .pipe(takeUntil(this.unsubscriber$))
              .subscribe((r: CommonResponse) => {
                if (r.responseCode == "00") {
                  var res = r.responseData;
                  this.preferentialPricing = res;
                  this.showPreferentialPricing = false;
                }
              });
            this.submittingData = false;
            this.loading = false;
            this.pricingForm.reset();
          } else {
            this.messageService.add({
              severity: "error",
              summary: "Failed",
              detail: " Error: " + r.responseMsg,
            });
            this.submittingData = false;
            this.loading = false;
          }
        },
        (error) => {
          this.messageService.add({
            severity: "error",
            summary: "Failed",
            detail: "Connection Error " + error.error.message,
          });
          this.submittingData = false;
          this.loading = false;
        }
      );
    this.submittingData = false;
  }
  fetchAllPreferentialPricing() {
    this.loading = true;
    this.priceRegisterService
      .allPreferentialPricings()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            var res = r.responseData;
            this.preferentialPricing = res;
            this.loading = false;
          }
        },
        (error) => {
          //this.loading = false;
          this.connectionError();
        }
      );
    this.submittingData = false;
  }
  fetchAllPreferentialPricingByContractAndClientId(contractServId, clientId) {
    //this.loading = true;
    this.priceRegisterService
      .getAllPreferentialPricingsByContractServiceAndClientId(
        contractServId,
        clientId
      )
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            var res = r.responseData;
            this.preferentialPricing = res;
          }
        },
        (error) => {
          //this.loading = false;
          this.connectionError();
        }
      );
    this.submittingData = false;
  }

  fetchServiceRegByServiceId(serviceId) {
    this.serviceRegistrationService
      .getServiceRegByServiceId(serviceId)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe((r: CommonResponse) => {
        if (r.responseCode == "00") {
          var res = r.responseData;
          this.serviceRegistration = res;
          this.serviceRegId = this.serviceRegistration.id;
          this.fetchingContractServicesDet = false;
          this.showPreferentialPricing = true;
          //console.log(this.serviceRegId);
          this.pricingForm.patchValue({
            contractServiceId: this.selectedContractService.id,
            clientId: this.clientsId,
            registeredServiceId: this.serviceRegistration.id,
          });
        } else {
          this.messageService.add({
            severity: "error",
            summary: "Error",
            detail: `${r.responseMsg} or service is not yet registered`,
          });
          this.showPreferentialPricing = false;
          this.fetchingContractServicesDet = false;
        }
      });
  }
  deletePreferentialPricing(preferentialPricing: PreferentialPricing) {
    this.confirmationService.confirm({
      message: "Are you sure you want to delete this pricing?",
      accept: () => {
        this._deletePreferentialPricing(preferentialPricing);
      },
    });
  }

  _deletePreferentialPricing(preferentialPricing) {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Deleting pricing record",
    });
    this.priceRegisterService
      .deletePreferentialPrice(preferentialPricing.id)
      .subscribe(
        async (result: any) => {
          await this.messageService.add({
            severity: "success",
            summary: "Deleted",
            detail: "pricing record removed",
          });

          await this.fetchAllPreferentialPricing();
        },
        (error) => {
          this.connectionError();
        }
      );
  }
  viewContractService(contractService: ContractService) {
    this.fetchingContractServicesDet = true;
    this.pricingForm.reset();
    this.selectedContractService = contractService;

    this.fetchServiceRegByServiceId(this.selectedContractService.serviceId);
    this.fetchAllPreferentialPricingByContractAndClientId(
      this.selectedContractService.id,
      this.clientsId
    );
  }
  getCustomerDivisions() {
    this.customerDivisionService.allCustomerDivision().subscribe(
      async (res: CommonResponse) => {
        this.customerDivisions = res.responseData;
      },
      (error) => {
        this.messageService.add({
          severity: "error",
          summary: "Failed",
          detail: "Connection error, Try again later",
        });
      }
    );
  }

  searchContractServicesByClient() {
    this.customerDivisionInformation = null;
    this.selectedContract = null;
    this.contracts = null;
    this.viewedContractService = null;
    this.contractServiceClicked = false;
    //this.generatePreformalClicked = false;
    this.getCustomerDivisionById(this.selectedCustomerDivision.id);
    this.clientsId = this.selectedCustomerDivision.id;
  }

  getCustomerDivisionById(customerDivisionId: any) {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Retrieving Client Information",
    });

    this.fetchingContractServices = true;
    this.showPreferentialPricing = false;
    this.customerDivisionService
      .getCustomerDivision(customerDivisionId)
      .subscribe(
        async (r: CommonResponse) => {
          await this.messageService.add({
            severity: "success",
            summary: "Success",
            detail: "Retrieved Client Information Successfully",
          });

          this.customerDivisionInformation = r.responseData;
          const primaryContact =
            this.customerDivisionInformation.primaryContact;
          const secondaryContact =
            this.customerDivisionInformation.secondaryContact;

          if (primaryContact) {
            this.primaryContactFullName = `${primaryContact.firstName} ${primaryContact.lastName}`;
          } else {
            this.primaryContactFullName = "Not Available.";
          }

          if (secondaryContact) {
            this.secondaryContactFullName = `${secondaryContact.firstName} ${secondaryContact.lastName}`;
          } else {
            this.secondaryContactFullName = "Not Available.";
          }

          this.contracts = r.responseData.contracts;
          this.fetchingContractServices = false;
        },
        (error) => {
          this.connectionError();
          this.fetchingContractServices = false;
        }
      );
  }

  refreshContractService() {
    var contractserviceId = this.selectedContractService?.id;
    this.contractServiceService
      .getContractServiceById(contractserviceId)
      .subscribe(
        async (r: CommonResponse) => {
          if (r.responseCode == "00") {
            this.selectedContractService = r.responseData;
            //find the index of this contract service in the array
            var index = this.contractServices.findIndex(
              (x) => x.id == contractserviceId
            );
            this.contractServices[index] = r.responseData;
            this.fetchInvoicesByContractServiceId(
              this.selectedContractService?.id
            );
          }
        },
        (error) => {
          this.connectionError();
          this.fetchingContractServices = false;
        }
      );
  }

  private fetchInvoicesByContractServiceId(contractServiceId: number): void {
    this.fetchingFinalInvoices = true;

    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Fetching Invoices",
    });

    this.invoiceService
      .getProformaInvoicesByContractDivisionId(contractServiceId)
      .subscribe(
        async (res) => {
          this.preformalInvoices = res.responseData ?? [];
          this.messageService.add({
            severity: "success",
            summary: "Successful",
            detail: "Successfully Fetched Proforma Invoices",
          });
          this.fetchingProformaInvoices = false;
        },
        (error: any) => {
          this.messageService.add({
            severity: "error",
            summary: "Failed",
            detail: error.error.message,
          });
        }
      );

    this.invoiceService
      .getInvoicesByContractDivisionId(contractServiceId)
      .subscribe(
        async (res) => {
          this.finalInvoices = res.responseData ?? [];
          this.messageService.add({
            severity: "success",
            summary: "Successful",
            detail: "Successfully Fetched Invoices",
          });

          this.fetchingFinalInvoices = false;
        },
        (error) => {
          this.messageService.add({
            severity: "error",
            summary: "Failed",
            detail: error.error.message,
          });
        }
      );
  }
  selectContract(contract?: Contract) {
    this.selectedContract = contract;
    this.contractServices = contract.contractServices;
    if (contract.groupInvoiceNumber) {
      this.refreshInvoiceSendDateForContract();
    }
  }
  private refreshInvoiceSendDateForContract() {
    if (!this.hiddenIsGroupedAdhoc) {
      return;
    }
  }

  private connectionError() {
    this.messageService.add({
      severity: "error",
      summary: "Failed",
      detail: "Connection Error, Please try again",
    });
  }

  private refreshProformaTable() {
    //if (this.proforma) this.proforma.reset();
  }

  viewContractServiceDetails(contractService: ContractService) {
    this.preformalInvoices = [];
    this.showCreateProformaForm = false;
    this.isAdHocContractService =
      contractService.invoicingInterval === 6 ? true : false;

    this.isGroupInvoice = false;
    if (this.isAdHocContractService) {
      this.hiddenIsGroupedAdhoc = this.selectedContract.groupInvoiceNumber
        ? true
        : false;
    } else {
      this.hiddenIsGroupedAdhoc = false;
    }

    this.fetchInvoicesByContractServiceId(contractService.id);
    this.contractServiceClicked = true;
    this.selectedContractService = contractService;
    this.refreshProformaTable();
  }
}
