import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {Observable} from 'rxjs';
import {CommonResponse} from '../interfaces/home';
import { shareReplay } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class IndustryService {
  iobservable: Observable<CommonResponse>;
  constructor(private http: HttpClient) { }
  baseUrl = environment.baseUrl+'Industry/';
  // fetch all LeadContact in the database
  allIndustry(): Observable<CommonResponse>{
    if(this.iobservable) return this.iobservable
    else {
      this.iobservable = this.http.get<CommonResponse>(this.baseUrl).pipe(shareReplay()); 
      return this.iobservable;
    }
  }
}
