import {Component, OnInit} from '@angular/core';
import {DynamicDialogConfig, DynamicDialogRef} from 'primeng/dynamicdialog';
import {CustomerDivision} from '../../../../../interfaces/home';

@Component({
  selector: 'app-view-division',
  templateUrl: './view-division.component.html',
  styleUrls: ['./view-division.component.scss']
})
export class ViewDivisionComponent implements OnInit {
  public customerDivision: CustomerDivision;
  serviceAssignment: any;
  constructor(
      private dialogRef: DynamicDialogRef,
      private configData: DynamicDialogConfig
  ) { }

  ngOnInit(): void {
    this.customerDivision = this.configData.data.division;
    if(this.customerDivision) {
      this.serviceAssignment = this.customerDivision?.contracts[0].contractServices[0] ?? '';
    }
  }
  onClose() {
    this.dialogRef.close();
  }

}
