import { SupplierServ } from "./../../../interfaces/supplier";
import { SupplierServiceService } from "./../../../services/supplier-service.service";
import { SupplierService } from "src/app/services/supplier.service";
import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { ConfirmationService, MessageService } from "primeng/api";
import { BreadcrumbService } from "src/app/breadcrumb.service";
import { Supplier } from "src/app/interfaces/supplier";
//import { AsYouType } from "libphonenumber-js";
import { ContactsService } from "../../contacts/contacts.service";
import { EventInput } from "@fullcalendar/core";
import { ModelService } from "src/app/services/model.service";
import { MakeService } from "src/app/services/make.service";
import { CommonResponse } from "src/app/interfaces/home";
import { Make } from "src/app/interfaces/make";
import { Model } from "src/app/interfaces/model";
import { FileStorageService } from "src/app/services/file-storage.service";
@Component({
  selector: "app-supplier-service",
  templateUrl: "./supplier-service.component.html",
  styleUrls: ["./supplier-service.component.scss"],
})
export class SupplierServiceComponent implements OnInit {
  @ViewChild("formWrapper") public formWrapper: ElementRef;
  form: FormGroup;
  editing = false;
  fetching = true;
  supplierServices: SupplierServ[];
  selectedSupplierService: SupplierServ;
  suppliers: Supplier[];
  makes: Make[];
  models: Model[];
  theMake: Make;
  theModel: Model;
  contacts: any;
  theSupplier: Supplier;
  cols: any[];
  private supplierServiceToEdit: SupplierServ;
  documentFIle;
  downloadUrl: any;

  constructor(
    fb: FormBuilder,
    public messageService: MessageService,
    public supplierService: SupplierService,
    public supplierServService: SupplierServiceService,
    private breadcrumbService: BreadcrumbService,
    public confirmationService: ConfirmationService,
    private modelService: ModelService,
    private makeService: MakeService,
    private fileStorageService: FileStorageService
  ) {
    this.form = fb.group({
      Name: ["", Validators.required],
      Description: ["", Validators.required],
      Make: ["", Validators.required],
      Model: ["", Validators.required],
      ModelNumber: ["", Validators.required],
      SerialNumber: ["", Validators.required],
      IdentificationNumber: ["", Validators.required],
      //ReferenceNumber1: ["", Validators.required],
      ReferenceNumber1: [""],
      ReferenceNumber2: [""],
      ReferenceNumber3: [""],
      UnitCostPrice: [""],
      // StandardDiscount: ["", Validators.required],
      AveragePrice: [""],
      Supplier: ["", Validators.required],
      FrontViewImage: ["", Validators.required],
      LeftViewImage: [""],
      RightViewImage: [""],
      RearViewImage: [""],
      TopViewImage: [""],
      InteriorViewImage: [""],
    });
  }

  get FrontViewImage() {
    return this.form.get("FrontViewImage").value;
  }
  get LeftViewImage() {
    return this.form.get("LeftViewImage").value;
  }
  get RightViewImage() {
    return this.form.get("RightViewImage").value;
  }
  get RearViewImage() {
    return this.form.get("RearViewImage").value;
  }
  get TopViewImage() {
    return this.form.get("TopViewImage").value;
  }
  get InteriorViewImage() {
    return this.form.get("InteriorViewImage").value;
  }

  ngOnInit(): void {
    this.breadcrumbService.setItems([
      { label: "Suppliers" },
      { label: "Service", routerLink: ["home/supplier/service"] },
    ]);

    this.cols = [
      { field: "name", header: "Name" },
      { field: "description", header: "Description" },
      { field: "make", header: "Make" },
      { field: "model", header: "Model" },
      { field: "modelNumber", header: "ModelNumber" },
      { field: "serialNumber", header: "SerialNumber" },
      { field: "identificationNumber", header: "IdentificationNumber" },
      { field: "referenceNumber1", header: "ReferenceNumber1" },
      { field: "referenceNumber2", header: "ReferenceNumber2" },
      { field: "referenceNumber3", header: "ReferenceNumber3" },
      { field: "unitCostPrice", header: "UnitCostPrice" },
      { field: "standardDiscount", header: "StandardDiscount" },
      { field: "averagePrice", header: "AveragePrice" },
      { field: "supplier", header: "Supplier" },
    ];

    this.fetchAllSuppliers();
    this.fetchingAllSupplierServices();
    this.fetchAllMake();
  }

  populateModel() {
    this.modelService.getByMake(parseInt(this.theMake.id)).subscribe(
      async (model: CommonResponse) => {
        this.models = model.responseData;
      },
      (error) => {
        this.connectionError();
      }
    );
  }

  fetchAllMake() {
    this.makeService.getAll().subscribe(
      async (makes: CommonResponse) => {
        this.makes = makes.responseData;
        this.fetching = false;
      },
      (error) => {
        this.fetching = false;
        this.connectionError();
      }
    );
  }

  formatAmount(event: any, element?: string) {
    let amount = parseInt(event.target.value);
    var formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "NGN",
    });

    let res = formatter.format(amount);
    res = res.substring(3);
    console.log(res);
    if (element === "UnitCostPrice") {
      this.form.controls.UnitCostPrice.setValue(res);
    } else if (element === "AveragePrice") {
      this.form.controls.AveragePrice.setValue(res);
    }
  }

  checkMimeType = (event: EventInput): boolean => {
    const files = event.target.files[0];
    // list allow mime type
    const types = ["image/png", "image/jpeg", "image/jpg", "image/gif"];
    // loop access array
    if (!files) {
      this.messageService.add({
        severity: "error",
        summary: "Failed",
        detail: "Please select an Image file",
      });
      return false;
    }
    // compare file type find doesn't match
    if (types.every((type) => files.type !== type)) {
      // create error message and assign to container
      this.messageService.add({
        severity: "error",
        summary: "Failed",
        detail: "Image format not supported",
      });
      return false;
    }
    return true;
  };

  checkFileSize = (event: EventInput) => {
    const files = event.target.files[0];
    if (!files) return true;
    const size = 400000;
    if (files.size > size) {
      event.target.value = null;
      this.messageService.add({
        severity: "error",
        summary: "Failed",
        detail: "Image is too large, please pick a smaller file",
      });
      return false;
    }
    return true;
  };
  onfileSelect(event, field: string) {
    if (!this.checkMimeType(event) || !this.checkFileSize(event)) {
      return;
    } else {
      if (this.checkMimeType(event)) {
        this.documentFIle = event.target.files[0];
        this.fileStorageService.UploadFileFromDataUrl(this.documentFIle);
        this.fileStorageService.onUploadFinished.subscribe(
          (resp: CommonResponse<string[]>) => {
            if (resp.responseCode != "00") {
              this.messageService.add({
                severity: "error",
                summary: "Notice",
                detail: resp.responseMsg,
              });
            } else {
              this.downloadUrl = resp.responseData;
              switch (field) {
                case "FrontViewImage":
                  this.form.controls.FrontViewImage.setValue(this.downloadUrl);
                  break;
                case "LeftViewImage":
                  this.form.controls.LeftViewImage.setValue(this.downloadUrl);
                  break;
                case "RightViewImage":
                  this.form.controls.RightViewImage.setValue(this.downloadUrl);
                  break;
                case "RearViewImage":
                  this.form.controls.RearViewImage.setValue(this.downloadUrl);
                  break;
                case "TopViewImage":
                  this.form.controls.TopViewImage.setValue(this.downloadUrl);
                  break;
                case "InteriorViewImage":
                  this.form.controls.InteriorViewImage.setValue(
                    this.downloadUrl
                  );
                  break;
              }
              console.log(resp);
            }
          },
          (error) => {
            console.log("Error while creating platform " + error);
            this.messageService.add({
              severity: "error",
              summary: "Notice",
              detail: "ERR: Unable to upload image to storage",
            });
          }
        );
      }
    }
  }
  onfileSelectOld(event, field: string) {
    if (!this.checkMimeType(event) || !this.checkFileSize(event)) {
      return;
    } else {
      const reader = new FileReader();
      if (event.target.files && event.target.files.length) {
        const [file] = event.target.files;
        let files = event.target.files[0];
        reader.readAsDataURL(file);
        reader.onload = (_) => {
          let imageSrc = reader.result as string;

          this.fileStorageService.UploadMultipleFilesFromDataUrl([files]);
          this.fileStorageService.onUploadFinished.subscribe(
            (resp: CommonResponse<string[]>) => {
              if (resp.responseCode != "00") {
                this.messageService.add({
                  severity: "error",
                  summary: "Notice",
                  detail: resp.responseMsg,
                });
              } else {
                let url = resp.responseData[0];
                switch (field) {
                  case "FrontViewImage":
                    this.form.controls.FrontViewImage.setValue(url);
                    break;
                  case "LeftViewImage":
                    this.form.controls.LeftViewImage.setValue(url);
                    break;
                  case "RightViewImage":
                    this.form.controls.RightViewImage.setValue(url);
                    break;
                  case "RearViewImage":
                    this.form.controls.RearViewImage.setValue(url);
                    break;
                  case "TopViewImage":
                    this.form.controls.TopViewImage.setValue(url);
                    break;
                  case "InteriorViewImage":
                    this.form.controls.InteriorViewImage.setValue(url);
                    break;
                }
              }
            },
            (error) => {
              console.log("Error while uploading files " + error);
              this.messageService.add({
                severity: "error",
                summary: "Notice",
                detail: "ERR: Unable to upload image to storage",
              });
            }
          );
        };
      }
    }
  }

  removeImage(field: string) {
    switch (field) {
      case "FrontViewImage":
        this.form.controls.FrontViewImage.setValue("");
        break;
      case "LeftViewImage":
        this.form.controls.LeftViewImage.setValue("");
        break;
      case "RightViewImage":
        this.form.controls.RightViewImage.setValue("");
        break;
      case "RearViewImage":
        this.form.controls.RearViewImage.setValue("");
        break;
      case "TopViewImage":
        this.form.controls.TopViewImage.setValue("");
        break;
      case "InteriorViewImage":
        this.form.controls.InteriorViewImage.setValue("");
        break;
    }
  }

  numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  fetchingAllSupplierServices() {
    this.supplierServService.getAll().subscribe(
      async (supplierServices: any) => {
        this.supplierServices = supplierServices?.responseData;
        this.fetching = false;
      },
      () => {
        this.fetching = false;
        this.connectionError();
      }
    );
  }

  fetchAllSuppliers() {
    this.supplierService.getAll().subscribe(
      async (supplierCategories: any) => {
        this.suppliers = supplierCategories?.responseData;
        this.fetching = false;
      },
      (error) => {
        this.fetching = false;
        this.connectionError();
      }
    );
  }

  createSupplierService() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Creating Supplier Service...",
    });

    let make: Make = this.form.get("Make").value;
    let model: Model = this.form.get("Model").value;
    const postData: SupplierServ = {
      serviceName: this.form.get("Name").value,
      description: this.form.get("Description").value,
      make: make.caption,
      model: model.caption,
      modelNumber: this.form.get("ModelNumber").value,
      serialNumber: this.form.get("SerialNumber").value,
      identificationNumber: this.form.get("IdentificationNumber").value,
      referenceNumber1: this.form.get("ReferenceNumber1").value,
      referenceNumber2: this.form.get("ReferenceNumber2").value,
      referenceNumber3: this.form.get("ReferenceNumber3").value,
      unitCostPrice: this.form.get("UnitCostPrice").value,
      // standardDiscount: this.form.get("StandardDiscount").value,
      averagePrice: this.form.get("AveragePrice").value,
      supplierId: this.theSupplier.id,
      frontViewImage: this.form.get("FrontViewImage").value,
      leftViewImage: this.form.get("LeftViewImage").value,
      rightViewImage: this.form.get("RightViewImage").value,
      rearViewImage: this.form.get("RearViewImage").value,
      topViewImage: this.form.get("TopViewImage").value,
      interiorViewImage: this.form.get("InteriorViewImage").value,
    };

    this.supplierServService.create(postData).subscribe(
      async (res) => {
        if (res.responseCode != "00") {
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail:
              "Unable to create supplier service at the moment.. Reason: [" +
              res.responseMsg +
              "]",
          });
        } else {
          await this.messageService.add({
            severity: "success",
            summary: "Completed",
            detail: "New Supplier Service Created...",
          });

          this.fetchingAllSupplierServices();
          this.form.reset();
        }
      },
      (error) => {
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to create supplier service at the moment.. Reason: [" +
            JSON.stringify(error) +
            "]",
        });
      }
    );
  }

  deleteSupplierService(item: SupplierServ) {
    this.confirmationService.confirm({
      message: "Are you sure you want to remove " + item.serviceName,
      accept: () => {
        this.messageService.add({
          severity: "info",
          summary: "Notice",
          detail: "Removing supplier service " + item.serviceName,
        });
        this.supplierServService.delete(item.id).subscribe(
          async () => {
            await this.messageService.add({
              severity: "success",
              summary: "Removed",
              detail: item.serviceName + " removed successfully",
            });
            await this.fetchingAllSupplierServices();
          },
          (error) => {
            this.connectionError();
          }
        );
      },
    });
  }

  async editSupplierService(item: SupplierServ) {
    this.editing = true;
    this.form.addControl(
      "ID",
      new FormControl({ value: "", disabled: true }, Validators.required)
    );
    this.supplierServiceToEdit = item;
    this.form.patchValue({
      ID: item.id,
      Name: item.serviceName,
      Description: item.description,
      Make: item.make,
      Model: item.model,
      ModelNumber: item.modelNumber,
      SerialNumber: item.serialNumber,
      IdentificationNumber: item.identificationNumber,
      ReferenceNumber1: item.referenceNumber1,
      ReferenceNumber2: item.referenceNumber2,
      ReferenceNumber3: item.referenceNumber3,
      UnitCostPrice: item.unitCostPrice,
      StandardDiscount: item.standardDiscount,
      AveragePrice: item.averagePrice,
      Supplier: item.supplierId,
      FrontViewImage: item.frontViewImage,
      LeftViewImage: item.leftViewImage,
      RightViewImage: item.rightViewImage,
      RearViewImage: item.rearViewImage,
      TopViewImage: item.topViewImage,
      InteriorViewImage: item.interiorViewImage,
    });
    this.theMake = this.makes.find((stat) => stat.caption === item.make);
    this.populateModel();

    const models = await this.modelService
      .getByMake(parseInt(this.theMake.id))
      .toPromise();
    this.theModel = models.responseData.find(
      (model) => model.caption === item.model
    );

    const suppliers = await this.supplierService.getAll().toPromise();
    this.theSupplier = suppliers.responseData.find(
      (sup) => sup.id === item.supplierId
    );
    this.formWrapper.nativeElement.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "start",
    });
  }

  updateSupplierService() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Updating Selected Supplier Service...",
    });
    const id = this.supplierServiceToEdit.id;
    const postData: SupplierServ = {
      serviceName: this.form.get("Name").value,
      description: this.form.get("Description").value,
      make: this.theMake.caption,
      model: this.theModel.caption,
      modelNumber: this.form.get("ModelNumber").value,
      serialNumber: this.form.get("SerialNumber").value,
      identificationNumber: this.form.get("IdentificationNumber").value,
      referenceNumber1: this.form.get("ReferenceNumber1").value,
      referenceNumber2: this.form.get("ReferenceNumber2").value,
      referenceNumber3: this.form.get("ReferenceNumber3").value,
      unitCostPrice: this.form.get("UnitCostPrice").value,
      // standardDiscount: this.form.get("StandardDiscount").value,
      averagePrice: this.form.get("AveragePrice").value,
      supplierId: this.theSupplier.id,
      frontViewImage: this.form.get("FrontViewImage").value,
      leftViewImage: this.form.get("LeftViewImage").value,
      rightViewImage: this.form.get("RightViewImage").value,
      rearViewImage: this.form.get("RearViewImage").value,
      topViewImage: this.form.get("TopViewImage").value,
      interiorViewImage: this.form.get("InteriorViewImage").value,
    };
    this.supplierServService.update(id, postData).subscribe(
      async (result) => {
        if (result.responseCode == "00") {
          this.messageService.add({
            severity: "success",
            summary: "Notice",
            detail: "Update Successful!",
          });
          await this.fetchingAllSupplierServices();
          await this.closeEditing();
        } else {
          this.messageService.add({
            severity: "error",
            summary: "Unable To Update",
            detail:
              "Unable to update supplier service at the moment.. Reason: [" +
              result.responseMsg +
              "]",
          });
        }
      },
      (error) => {
        this.messageService.add({
          severity: "error",
          summary: "Unable To Update",
          detail:
            "Unable to update supplier service at the moment.. Reason: [" +
            JSON.stringify(error) +
            "]",
        });
      }
    );
  }

  closeEditing() {
    this.editing = false;
    this.form.reset();
    this.supplierServiceToEdit = null;
  }

  private connectionError() {
    this.messageService.add({
      severity: "error",
      summary: "Failed",
      detail: "Connection Error, Please try again",
    });
  }
}
