import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import {
  Bank,
  BankCoverageTypeEnum,
  BankReceivingDTO,
  CommonResponse,
} from "../../../interfaces/home";
import { ConfirmationService, MessageService } from "primeng/api";
import { BankService } from "../../../services/bank.service";
import { BreadcrumbService } from "src/app/breadcrumb.service";
import { EventInput } from "@fullcalendar/core";
import { FileStorageService } from "src/app/services/file-storage.service";

@Component({
  selector: "app-bank",
  templateUrl: "./bank.component.html",
  styleUrls: ["./bank.component.scss"],
  providers: [MessageService, ConfirmationService],
})
export class BankComponent implements OnInit {
  @ViewChild("formWrapper") public formWrapper: ElementRef;
  fetchingBank = true;
  bankForm: FormGroup;
  banks: Bank[];
  selectedBank: Bank;
  editingBank = false;
  public bankCols: any[];
  public exportBankColumns: any[];
  private edBank: Bank;
  imageSrc: string;
  uploadingNewPicture = false;
  file: any;
  allCoverageTypes: {
    key: number;
    value: string;
  }[] = [];
  theCoverageType: {
    key: number;
    value: string;
  };

  constructor(
    public formBuilder: FormBuilder,
    public fileStorageService: FileStorageService,
    private bankService: BankService,
    public messageService: MessageService,
    public confirmationService: ConfirmationService,
    private breadcrumbService: BreadcrumbService
  ) {}

  ngOnInit(): void {
    this.breadcrumbService.setItems([
      { label: "Setup", routerLink: ["home/bank"] },
      { label: "Banks", routerLink: ["home/bank"] },
    ]);

    this.fetchBank();
    this.bankForm = this.formBuilder.group({
      name: ["", [Validators.required]],
      slogan: ["", [Validators.required]],
      alias: [""],
      isActive: [true, Validators.required],
      sortCode: ["", [Validators.required]],
      bankCode: ["", [Validators.required]],
      coverageType: ["", [Validators.required]],
      ssrsReportName: [""],
    });

    this.bankCols = [
      { field: "name", header: "Name" },
      { field: "slogan", header: "Slogan" },
      { field: "alias", header: "Alias" },
      { field: "sortCode", header: "Sort Code" },
      { field: "bankCode", header: "Bank Code" },
      { field: "isActive", header: "Status" },
    ];
    this.exportBankColumns = this.bankCols.map((col) => ({
      title: col.header,
      dataKey: col.field,
    }));

    this.allCoverageTypes = [
      {
        key: BankCoverageTypeEnum.State,
        value: "State",
      },
      {
        key: BankCoverageTypeEnum.Regional,
        value: "Regional",
      },
      {
        key: BankCoverageTypeEnum.National,
        value: "National",
      },
    ];
  }

  fetchBank() {
    this.bankService.allBankData().subscribe(
      async (bank: CommonResponse) => {
        this.banks = bank.responseData;
        this.fetchingBank = false;
      },
      (error) => {
        this.fetchingBank = false;
        this.connectionError();
      }
    );
  }

  private connectionError() {
    this.messageService.add({
      severity: "error",
      summary: "Failed",
      detail: "Connection Error, Please try again",
    });
  }

  async createBank() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Creating new Bank Records",
    });
    const postData: BankReceivingDTO = {
      name: this.bankForm.get("name").value,
      slogan: this.bankForm.get("slogan").value,
      alias: this.bankForm.get("alias").value,
      isActive: this.bankForm.get("isActive").value,
      logoUrl: "",
      sortCode: this.bankForm.get("sortCode").value,
      bankCode: this.bankForm.get("bankCode").value,
      coverageType: this.theCoverageType.key,
      ssrsChequeUrl: this.bankForm.get("ssrsReportName").value,
    };

    this.fileStorageService.UploadFileFromDataUrl(this.file);
    this.fileStorageService.onUploadFinished.subscribe(
      (resp: CommonResponse<string>) => {
        if (resp.responseCode != "00") {
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail: resp.responseMsg,
          });
        } else {
          postData.logoUrl = resp.responseData;
          this._createBank(postData);
        }
      },
      (error) => {
        console.log("Error while uploading file " + error);
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail: "ERR: Unable to upload image to storage",
        });
      }
    );
  }

  _createBank(postData: BankReceivingDTO) {
    this.bankService.postBank(postData).subscribe(
      async () => {
        this.messageService.add({
          severity: "success",
          summary: "Completed",
          detail: "New Bank Created",
        });

        this.imageSrc = null;
        this.uploadingNewPicture = false;
        this.file = null;

        this.bankForm.reset();
        this.fetchBank();
      },
      (error) => {
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail: "Unable to create new Bank at the moment",
        });
      }
    );
  }

  deleteBank(bank) {
    this.confirmationService.confirm({
      message: "Are you sure you want to delete " + bank.name,
      accept: () => {
        this._deleteBank(bank);
      },
    });
  }

  _deleteBank(bank) {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Deleting bank record",
    });
    this.bankService.deleteBank(bank.id).subscribe(
      async (result: any) => {
        await this.messageService.add({
          severity: "success",
          summary: "Deleted",
          detail: "Bank record removed",
        });
        await this.fetchBank();
      },
      (error) => {
        this.connectionError();
      }
    );
  }

  editBank(banks: Bank) {
    this.editingBank = true;
    this.bankForm.addControl(
      "bankId",
      new FormControl({ value: "", disabled: true }, Validators.required)
    );
    this.edBank = banks;
    this.bankForm.patchValue({
      name: banks.name,
      slogan: banks.slogan,
      alias: banks.alias,
      isActive: banks.isActive,
      bankId: banks.id,
      sortCode: banks.sortCode,
      bankCode: banks.bankCode,
      ssrsReportName: banks.ssrsChequeUrl,
    });
    this.theCoverageType = this.allCoverageTypes.find(
      (x) => x.key == banks.coverageType
    );
    this.imageSrc = banks.logoUrl;
    // this.bankFormIn.nativeElement.focus();
    // document.getElementById('formFields').focus();
    // this.selectedHeadId = this.users.find(user => user.id === banks.head.id);
    this.formWrapper.nativeElement.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "start",
    });
  }

  async updateBank() {
    // update the selected bank
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Updating Bank details",
    });
    const id = this.edBank.id;
    const postData: BankReceivingDTO = {
      name: this.bankForm.get("name").value,
      slogan: this.bankForm.get("slogan").value,
      alias: this.bankForm.get("alias").value,
      isActive: this.bankForm.get("isActive").value,
      logoUrl: "",
      sortCode: this.bankForm.get("sortCode").value,
      bankCode: this.bankForm.get("bankCode").value,
      coverageType: this.theCoverageType.key,
      ssrsChequeUrl: this.bankForm.get("ssrsReportName").value,
    };

    if (this.editingBank && !this.uploadingNewPicture) {
      postData.logoUrl = this.edBank.logoUrl;
      this._updateBank(id, postData);
    } else {
      this.fileStorageService.UploadFileFromDataUrl(this.file);
      this.fileStorageService.onUploadFinished.subscribe(
        (resp: CommonResponse<string>) => {
          if (resp.responseCode != "00") {
            this.messageService.add({
              severity: "error",
              summary: "Notice",
              detail: resp.responseMsg,
            });
          } else {
            postData.logoUrl = resp.responseData;
            this._updateBank(id, postData);
          }
        },
        (error) => {
          console.log("Error while uploading file " + error);
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail: "ERR: Unable to upload image to storage",
          });
        }
      );
    }
  }

  _updateBank(id: number, postData: BankReceivingDTO) {
    this.bankService
      .updateBank(id, postData)
      .subscribe(async (result: Bank) => {
        this.messageService.add({
          severity: "success",
          summary: "Notice",
          detail: "Bank details Updated",
        });

        this.imageSrc = null;
        this.uploadingNewPicture = false;
        this.file = null;

        this.fetchBank();
        this.closeEditing();
      });
  }

  closeEditing() {
    this.editingBank = false;
    this.bankForm.reset();
    this.edBank = null;
  }

  onFileSelect(event) {
    if (!this.checkMimeType(event) || !this.checkFileSize(event)) {
      return;
    }

    this.uploadingNewPicture = true;
    const reader = new FileReader();
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      this.file = event.target.files[0];
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.imageSrc = reader.result as string;
      };
    }
  }

  checkMimeType = (event: EventInput): boolean => {
    const files = event.target.files[0];
    // list allow mime type
    const types = ["image/png", "image/jpeg", "image/jpg", "image/gif"];
    // loop access array
    if (!files) {
      this.messageService.add({
        severity: "error",
        summary: "Failed",
        detail: "Please select an Image file",
      });
      return false;
    }
    // compare file type find doesn't match
    if (types.every((type) => files.type !== type)) {
      // create error message and assign to container
      this.messageService.add({
        severity: "error",
        summary: "Failed",
        detail: "Image format not supported",
      });
      return false;
    }
    return true;
  };

  checkFileSize = (event: EventInput) => {
    const files = event.target.files[0];
    if (!files) return true;
    const size = 400000;
    if (files.size > size) {
      event.target.value = null;
      this.messageService.add({
        severity: "error",
        summary: "Failed",
        detail: "Image is too large, please pick a smaller file",
      });
      return false;
    }
    return true;
  };
}
