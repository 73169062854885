import { ComplaintHandlingService } from "./../../../services/complaint-handling.service";
import {
  Complaint,
  ComplaintTrackingData,
} from "./../../../interfaces/complaint";
import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MessageService, ConfirmationService } from "primeng/api";
import { BreadcrumbService } from "src/app/breadcrumb.service";
import { ComplaintService } from "src/app/services/complaint.service";
import { CommonResponse } from "src/app/interfaces/home";

@Component({
  selector: "app-complaint-tracking",
  templateUrl: "./complaint-tracking.component.html",
  styleUrls: ["./complaint-tracking.component.scss"],
})
export class ComplaintTrackingComponent implements OnInit {
  @ViewChild("formWrapper") public formWrapper: ElementRef;
  cols: any[];
  form: FormGroup;
  fetching = true;
  theComplaint: Complaint;
  trackingDetails: {
    stage: string;
    status: number;
    details?: any;
    evidences?: string[];
  }[];
  selectedTrackingDetail: {
    stage: string;
    status: number;
    details?: any;
    evidences?: string[];
  };
  complaints: Complaint[];
  imageSrc: string | "assets/no-file.png";
  handlerLoad: {
    cases: number;
    unresolved: number;
    resolved: number;
  };
  complaintTracking: ComplaintTrackingData;
  openDetailsDialogue: boolean;
  openEvidenceDialogue: boolean;
  trackingDetailsInfo: {
    DateCreated: Date;
    Details: string;
    Findings: string;
  };
  evidencesToDisplayUrls: string[];

  constructor(
    fb: FormBuilder,
    public complaintService: ComplaintService,
    public complaintHandlingService: ComplaintHandlingService,
    public messageService: MessageService,
    private breadcrumbService: BreadcrumbService,
    public confirmationService: ConfirmationService
  ) {
    this.form = fb.group({
      TrackingNo: ["", Validators.required],
    });
  }

  ngOnInit(): void {
    this.breadcrumbService.setItems([
      { label: "Complaint Management" },
      {
        label: "Complaint Tracking",
        routerLink: ["home/complaint-management/complaint-tracking"],
      },
    ]);

    this.cols = [
      { field: "stage", header: "Stage" },
      { field: "status", header: "Status" },
    ];

    this.trackingDetails = [
      {
        stage: "Registration",
        status: 3,
        details: null,
        evidences: [],
      },
      {
        stage: "Assessment",
        status: 3,
        details: null,
        evidences: [],
      },
      {
        stage: "Investiagtion",
        status: 3,
        details: null,
        evidences: [],
      },
      {
        stage: "Resolution",
        status: 3,
        details: null,
        evidences: [],
      },
      {
        stage: "Close",
        status: 3,
        details: null,
        evidences: [],
      },
    ];

    this.handlerLoad = {
      cases: 0,
      resolved: 0,
      unresolved: 0,
    };

    //this.FetchAllComplaintsFilteredByType();
  }

  FetchAllComplaintsFilteredByType() {
    this.complaintService.getAll().subscribe(
      async (comp: CommonResponse) => {
        this.complaints = comp.responseData;
        this.fetching = false;
      },
      () => {
        this.fetching = false;
        this.ConnectionError();
      }
    );
  }

  TrackComplaint() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Tracking Complaint...",
    });

    this.complaintHandlingService
      .trackComplaint(this.form.get("TrackingNo").value)
      .subscribe(
        (r: CommonResponse) => {
          var data = r.responseData;
          this.complaintTracking = data;

          this.trackingDetails = [
            {
              stage: "Registration",
              status: data.complaint.isRegistered ? 1 : 0,
              details: null,
              evidences: data.registrationEvidenceUrls,
            },
            {
              stage: "Assessment",
              status: data.complaint.isAssesed
                ? 1
                : this.GetCurrentStatus(data.complaint) == "Ongoing Assessment"
                ? 2
                : 0,
              details: data.assessment,
              evidences: data.assessmentEvidenceUrls,
            },
            {
              stage: "Investiagtion",
              status: data.complaint.isInvestigated
                ? 1
                : this.GetCurrentStatus(data.complaint) ==
                  "Ongoing Investigation"
                ? 2
                : 0,
              details: data.investigation,
              evidences: data.investigationEvidenceUrls,
            },
            {
              stage: "Resolution",
              status: data.complaint.isResolved
                ? 1
                : this.GetCurrentStatus(data.complaint) == "Ongoing Resolution"
                ? 2
                : 0,
              details: data.resolution,
              evidences: data.resolutionEvidenceUrls,
            },
            {
              stage: "Close",
              status: data.complaint.isClosed
                ? 1
                : this.GetCurrentStatus(data.complaint) == "Awaiting Closure"
                ? 2
                : 0,
              details: null,
              evidences: data.closureEvidenceUrls,
            },
          ];

          this.handlerLoad = {
            cases: data.totalHandlerCases,
            unresolved: data.totalHanlderCasesUnresolved,
            resolved: data.totalHandlerCasesResolved,
          };
          if (data.userProfileImageUrl)
            this.imageSrc = data.userProfileImageUrl;

          this.messageService.add({
            severity: "success",
            summary: "Completed",
            detail: "Complaint Data Retrieved...",
          });
        },
        (error) => {
          this.messageService.add({
            severity: "error",
            summary: "Failed",
            detail:
              "Unable to load complaint details...Reason: " +
              error.error.message,
          });
        }
      );
  }

  GetComplainantName(item: Complaint) {
    return item.complainant == null
      ? "No Data"
      : item.complainant.supplierName
      ? item.complainant.supplierName
      : item.complainant.divisionName
      ? item.complainant.divisionName
      : item.complainant.lastName;
  }

  GetCurrentStatus(item: Complaint): string {
    if (item.isResolved) {
      return "Awaiting Closure";
    } else if (item.isInvestigated) {
      return "Ongoing Resolution";
    } else if (item.isAssesed) {
      return "Ongoing Investigation";
    } else {
      return "Ongoing Assessment";
    }
  }

  ShowComplaintDetails(item: any) {
    this.openDetailsDialogue = true;
    this.trackingDetailsInfo = {
      DateCreated: item.createdAt && item.createdAt,
      Findings: item.findings ? item.findings : item.rootCause,
      Details: item.assesmentDetails
        ? item.assesmentDetails
        : item.investigationDetails
        ? item.investigationDetails
        : item.resolutionDetails,
    };
  }

  ShowComplaintEvidenceDetails(item: string[]) {
    this.evidencesToDisplayUrls = item;
    this.openEvidenceDialogue = true;
  }

  HideDetailsDialog() {
    this.openDetailsDialogue = false;
    this.trackingDetailsInfo = null;
  }

  HideEvidenceDialog() {
    this.openEvidenceDialogue = false;
    this.evidencesToDisplayUrls = [];
  }

  private ConnectionError() {
    this.messageService.add({
      severity: "error",
      summary: "Failed",
      detail: "Connection Error, Please try again",
    });
  }
}
