import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ConfirmationService, MessageService } from "primeng/api";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { BreadcrumbService } from "src/app/breadcrumb.service";
import {
  SMORegion,
  SMOReturnRoute,
  SMOReturnRouteAndRouteEdit,
  SMORoute,
} from "src/app/interfaces/armada";
import { CommonResponse } from "src/app/interfaces/home";
import { SMORouteService } from "src/app/services/armada/smoroute.service";

@Component({
  selector: "app-routeregion-segment",
  templateUrl: "./routeregion-segment.component.html",
  styleUrls: ["./routeregion-segment.component.scss"],
})
export class RouteregionSegmentComponent implements OnInit, OnDestroy {
  @ViewChild("formWrapper") public formWrapper: ElementRef;
  private unsubscriber$ = new Subject<void>();

  public targetCols: any;
  private exportTargetColumns: any;
  public loading: boolean;
  submitButtonLabel: string;
  submitRouteButtonLabel: string;
  submitReturnRouteButtonLabel: string;
  public submittingData: boolean;
  public submittingRouteData: boolean;
  public submittingReturnRouteData: boolean;

  //Region
  public smoRegions: SMORegion[];
  public sMORouteRegionForm: FormGroup;
  selectedSMORegion: SMORegion;
  editingSMORoute = false;
  editingSMORegion = false;
  private edSMORegion: SMORegion;

  //Route
  public smoRoutes: SMORoute[];
  public smoActiveRoutes: SMORoute[];
  public sMORouteForm: FormGroup;
  selectedSMORoute: SMORoute;
  //editingSMORoute = false;
  private edSMORoute: SMORoute;
  private edSMORouteEdit: SMOReturnRouteAndRouteEdit;

  //ReturnRoute
  public smoReturnRoutes: SMOReturnRoute[];
  public sMOReturnRouteForm: FormGroup;
  // selectedSMOReturnRoute: SMOReturnRoute;
  // selectedSMOReturnIniRoute:SMOReturnRoute;
  selectedSMOReturnRoute: SMORoute;
  selectedSMOReturnIniRoute: SMORoute;
  editingSMOReturnRoute = false;
  private edSMOReturnRoute: SMOReturnRoute;

  availableReturnRoutes: SMORoute[] = [];
  selectedReturnRouteToSelect: SMORoute;
  //time
  maxDateOfBirth: Date;
  public yearRange: string;
  minimumAge: number;
  isReturnRouteRequired = false;
  rowGroupMetadata: any;

  constructor(
    private breadcrumbService: BreadcrumbService,
    private smoRouteAndRegionService: SMORouteService,
    public messageService: MessageService,
    public confirmationService: ConfirmationService,
    private formBuilder: FormBuilder
  ) {
    this.breadcrumbService.setItems([
      { label: "Setup" },
      { label: "Segment", routerLink: ["/setup/routeregion-segment"] },
    ]);
  }

  ngOnInit(): void {
    this.loading = true;
    this.minimumAge = 18;
    this.maxDateOfBirth = new Date();
    const year = this.maxDateOfBirth.getFullYear();
    this.maxDateOfBirth.setFullYear(year);
    this.yearRange = year - 100 + ":" + year;
    //Region
    this.targetCols = [
      { field: "regionName", header: "Name" },
      { field: "regionDescription", header: "Description" },
      //{ field: 'description', header: 'Description' },
    ];
    this.exportTargetColumns = this.targetCols.map((col) => ({
      title: col.header,
      dataKey: col.field,
    }));
    this.sMORouteRegionForm = this.formBuilder.group({
      regionName: ["", [Validators.required, Validators.minLength(3)]],
      regionDescription: ["", [Validators.required, Validators.minLength(3)]],
    });

    //ReturnRoute
    this.targetCols = [
      { field: "returnRouteId", header: "returnRouteId" },
      { field: "rRecoveryTime", header: "rRecoveryTime" },
      { field: "smoRouteId", header: "routeId" },
    ];
    this.exportTargetColumns = this.targetCols.map((col) => ({
      title: col.header,
      dataKey: col.field,
    }));
    this.sMOReturnRouteForm = this.formBuilder.group({
      returnRouteId: ["", [Validators.required]],
      rRecoveryTime: ["", [Validators.required]],
      smoRouteId: [null, [Validators.required]],
    });

    //Route
    this.targetCols = [
      { field: "routeName", header: "Name" },
      { field: "routeDescription", header: "Description" },
      { field: "smoRegionId", header: "smoRegionId" },
      { field: "isReturnRouteRequired", header: "isReturnRouteRequired" },
      { field: "recoveryTime", header: "recoveryTime" },
    ];
    this.exportTargetColumns = this.targetCols.map((col) => ({
      title: col.header,
      dataKey: col.field,
    }));
    this.sMORouteForm = this.formBuilder.group({
      routeName: ["", [Validators.required, Validators.minLength(3)]],
      routeDescription: ["", [Validators.required, Validators.minLength(3)]],
      smoRegionId: [null, [Validators.required]],
      isReturnRouteRequired: [null, [Validators.required]],
      rRecoveryTime: ["", [Validators.required]],
    });

    this.fetchAllRoutes();
    this.fetchAllActiveRoutes();
    this.fetchAllRegions();
    this.fetchAllReturnRoutes();
    this.submitButtonLabel = "create";
    this.submitRouteButtonLabel = "create";
    this.submitReturnRouteButtonLabel = "create";

    // setTimeout(() => {
    //   this.valSwitch = !this.valSwitch;
    //   console.log("workss");
    //   //this.cd.markForCheck();
    // }, 2000);
  }

  //Region
  fetchAllRegions(): void {
    this.smoRouteAndRegionService
      .allRegions()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            var res = r.responseData;
            this.smoRegions = res;
            this.loading = false;
          }
        },
        (error) => {
          this.messageService.add({
            severity: "error",
            summary: "Failed",
            detail: "Connection Error,  " + error.error.message,
          });
          this.submittingData = false;
        }
      );
  }

  createRegion() {
    this.submittingData = true;
    this.smoRouteAndRegionService
      .postRegion(this.sMORouteRegionForm.value)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            this.messageService.add({
              summary: "Success",
              severity: "success",
              detail: "New SMO Region created",
            });
            //var getId = r.responseData.id;
            //localStorage.setItem("regionID", getId);
            this.fetchAllRegions();
            this.submittingData = false;
            this.sMORouteRegionForm.reset();
          } else {
            this.messageService.add({
              severity: "error",
              summary: "Failed",
              detail: "Error " + r.responseMsg,
            });
            this.submittingData = false;
          }
        },
        (error) => {
          this.messageService.add({
            severity: "error",
            summary: "Failed",
            detail: "Error " + error.error.message,
          });
          this.submittingData = false;
        }
      );
  }

  deleteRegion(sMORegion: SMORegion): void {
    this.confirmationService.confirm({
      message: "Are you sure you want to delete " + sMORegion.regionName,
      accept: () => {
        this._deleteRegion(sMORegion);
      },
    });
  }

  _deleteRegion(sMORegion) {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Deleting Region record",
    });
    this.smoRouteAndRegionService.deleteRegion(sMORegion.id).subscribe(
      async (result: any) => {
        await this.messageService.add({
          severity: "success",
          summary: "Deleted",
          detail: "Region record removed",
        });
        await this.fetchAllRegions();
      },
      (error) => {
        this.connectionError();
      }
    );
  }
  editRegion(sMORegion: SMORegion): void {
    this.formWrapper.nativeElement.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "start",
    });
    this.editingSMORegion = true;
    this.submitButtonLabel = "Update";
    this.edSMORegion = sMORegion;
    this.sMORouteRegionForm.setValue({
      regionName: sMORegion.regionName,
      regionDescription: sMORegion.regionDescription,
    });
    this.submitButtonLabel = "Update";
  }

  updateRegion(): boolean {
    this.submittingData = true;
    if (!this.edSMORegion) {
      return false;
    }
    this.smoRouteAndRegionService
      .updateRegion(this.edSMORegion.id, this.sMORouteRegionForm.value)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            this.messageService.add({
              severity: "success",
              detail: "SMO Region Updated",
              summary: "Completed",
            });
            this.submittingData = false;
            this.closeEditing();
            this.fetchAllRegions();
          } else {
            this.messageService.add({
              severity: "error",
              summary: "Failed",
              detail: " Error: " + r.responseMsg,
            });
            this.submittingData = false;
          }
        },
        (error) => {
          this.connectionError();
          this.submittingData = false;
        }
      );
  }

  //Route
  fetchAllRoutes(): void {
    this.smoRouteAndRegionService
      .allRoutes()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            var res = r.responseData;
            this.smoRoutes = res;
            this.loading = false;
          }
        },
        (error) => {
          this.messageService.add({
            severity: "error",
            summary: "Failed",
            detail: "Connection Error,  " + error.error.message,
          });
          this.submittingData = false;
        }
      );
  }

  fetchAllActiveRoutes(): void {
    this.smoRouteAndRegionService
      .allActiveRoutes()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            var res = r.responseData;
            this.smoActiveRoutes = res;
            this.loading = false;
          }
        },
        (error) => {
          this.messageService.add({
            severity: "error",
            summary: "Failed",
            detail: "Connection Error,  " + error.error.message,
          });
          this.submittingData = false;
        }
      );
  }

  createRoute() {
    this.submittingRouteData = true;
    const formData = this.sMORouteForm.value;
    const postData = {
      routeName: formData.routeName,
      routeDescription: formData.routeDescription,
      isReturnRouteRequired: formData.isReturnRouteRequired,
      smoRegionId: this.selectedSMORegion.id,
      rRecoveryTime: formData.rRecoveryTime,

      // rankId: this.selectedPilotRank.id,
    };
    this.smoRouteAndRegionService
      .postRoute(postData)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            this.messageService.add({
              summary: "Success",
              severity: "success",
              detail: "New SMO Route created",
            });
            this.fetchAllRoutes();
            this.submittingRouteData = false;
            this.isReturnRouteRequired = false;
            this.sMORouteForm.reset();
            this.sMOReturnRouteForm.reset();
          } else {
            this.messageService.add({
              severity: "error",
              summary: "Failed",
              detail: "Error " + r.responseMsg,
            });
            this.submittingRouteData = false;
          }
        },
        (error) => {
          this.messageService.add({
            severity: "error",
            summary: "Failed",
            detail: "Error " + error.error.message,
          });
          this.submittingRouteData = false;
        }
      );
  }

  deleteRoute(sMORoute: SMORoute): void {
    this.confirmationService.confirm({
      message: "Are you sure you want to delete " + sMORoute.routeName,
      accept: () => {
        this._deleteRoute(sMORoute);
      },
    });
  }

  _deleteRoute(sMORoute) {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Deleting Route record",
    });
    this.smoRouteAndRegionService.deleteRoute(sMORoute.id).subscribe(
      async (result: any) => {
        await this.messageService.add({
          severity: "success",
          summary: "Deleted",
          detail: "Route record removed",
        });
        await this.fetchAllRoutes();
        this.sMOReturnRouteForm.reset();
        this.sMORouteForm.reset();
      },
      (error) => {
        this.connectionError();
      }
    );
  }

  editRoute(SMORoute: SMORoute): void {
    this.formWrapper.nativeElement.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "start",
    });
    this.editingSMORoute = true;
    this.submitRouteButtonLabel = "Update";
    this.edSMORoute = SMORoute;
    this.sMORouteForm.setValue({
      routeName: SMORoute.routeName,
      routeDescription: SMORoute.routeDescription,
      rRecoveryTime: SMORoute.rRecoveryTime,
      // recoveryTime: new Date(SMORoute.recoveryTime ?? this.maxDateOfBirth),
      isReturnRouteRequired: SMORoute.isReturnRouteRequired,
      smoRegionId: SMORoute.smoRegionId,
    });

    this.selectedSMORegion = this.smoRegions.find(
      (region) => region.id === SMORoute.smoRegionId
    );
  }

  // editRoute(SMORoute: SMOReturnRouteAndRouteEdit): void {
  //   this.formWrapper.nativeElement.scrollIntoView({
  //     behavior: "smooth",
  //     block: "end",
  //     inline: "start",
  //   });
  //   this.editingSMORoute = true;
  //   this.submitButtonLabel = "Update";
  //   this.edSMORouteEdit = SMORoute;
  //   this.sMORouteForm.setValue({
  //     routeName: SMORoute.routeName,
  //     routeDescription: SMORoute.routeDescription,
  //     recoveryTime: new Date(SMORoute.recoveryTime ?? this.maxDateOfBirth),
  //     isReturnRouteRequired: SMORoute.isReturnRouteRequired,
  //     smoRegionId: SMORoute.smoRegionId,
  //   });
  //   this.sMOReturnRouteForm.setValue({
  //     //routeId: SMORoute.smoReturnRoute.routeId,
  //     recoveryTime: new Date(
  //       SMORoute.smoReturnRoute.recoveryTime ?? this.maxDateOfBirth
  //     ),
  //     returnRouteId: SMORoute.smoReturnRoute.ReturnRouteId,
  //   });

  //   this.selectedSMORegion = this.smoRegions.find(
  //     (region) => region.id === SMORoute.smoRegionId
  //   );
  //   this.selectedSMOReturnRoute = this.smoReturnRoutes.find(
  //     (Type) => Type.id === SMORoute.smoReturnRoute.ReturnRouteId
  //   );
  // }
  updateSMORoute(): boolean {
    this.submittingRouteData = true;
    if (!this.edSMORoute) {
      return false;
    }
    const formData = this.sMORouteForm.value;
    const postData = {
      routeName: formData.routeName,
      routeDescription: formData.routeDescription,
      isReturnRouteRequired: formData.isReturnRouteRequired,
      smoRegionId: this.selectedSMORegion.id,
      rRecoveryTime: formData.rRecoveryTime,
    };
    this.smoRouteAndRegionService
      .updateRoute(this.edSMORoute.id, postData)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            this.messageService.add({
              severity: "success",
              detail: "SMO Route Updated",
              summary: "Completed",
            });
            this.submittingRouteData = false;
            this.closeEditing();
            this.fetchAllRoutes();
            this.isReturnRouteRequired = false;
            this.submitRouteButtonLabel = "Create";
          } else {
            this.messageService.add({
              severity: "error",
              summary: "Failed",
              detail: " Error: " + r.responseMsg,
            });
            this.submittingRouteData = false;
          }
        },
        (error) => {
          this.connectionError();
          this.submittingRouteData = false;
          this.submitRouteButtonLabel = "Create";
        }
      );
  }

  //Return Route
  fetchAllReturnRoutes(): void {
    this.smoRouteAndRegionService
      .allReturnRoutes()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            var res = r.responseData;
            this.smoReturnRoutes = res;
            this.loading = false;
          }
        },
        (error) => {
          this.messageService.add({
            severity: "error",
            summary: "Failed",
            detail: "Connection Error,  " + error.error.message,
          });
          this.submittingData = false;
        }
      );
  }
  createReturnRoute() {
    this.submittingReturnRouteData = true;
    const formData = this.sMOReturnRouteForm.value;
    const postData = {
      // returnRouteId: this.selectedSMOReturnRoute.returnRouteId,
      // routeId: this.selectedSMOReturnRoute.routeId,
      returnRouteId: formData.returnRouteId.id,
      smoRouteId: formData.smoRouteId.id,
      rRecoveryTime: formData.rRecoveryTime,
    };
    this.smoRouteAndRegionService
      .postReturnRoute(postData)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            this.messageService.add({
              summary: "Success",
              severity: "success",
              detail: "Return Route created",
            });
            this.fetchAllReturnRoutes();
            this.submittingReturnRouteData = false;
            this.sMOReturnRouteForm.reset();
          } else {
            this.messageService.add({
              severity: "error",
              summary: "Failed",
              detail: " Error: " + r.responseMsg,
            });
            this.submittingReturnRouteData = false;
          }
        },
        (error) => {
          this.messageService.add({
            severity: "error",
            summary: "Failed",
            detail: "Connection Error " + error.error.message,
          });
          this.submittingReturnRouteData = false;
        }
      );
  }

  editReturnRoute(SMOReturnRoute: SMOReturnRoute): void {
    this.formWrapper.nativeElement.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "start",
    });
    this.editingSMOReturnRoute = true;
    this.submitReturnRouteButtonLabel = "Update";
    this.edSMOReturnRoute = SMOReturnRoute;
    this.sMOReturnRouteForm.setValue({
      smoRouteId: SMOReturnRoute.smoRoute.id,
      returnRouteId: SMOReturnRoute.smoRoute.id,
      rRecoveryTime: SMOReturnRoute.rRecoveryTime,
    });
    this.selectedSMOReturnRoute = this.smoRoutes.find(
      (Type) => Type.id === SMOReturnRoute.returnRouteId
    );

    this.selectedSMOReturnIniRoute = this.smoActiveRoutes.find(
      (Type) => Type.id === SMOReturnRoute.smoRouteId
    );
  }

  updateSMOReturnRoute(): boolean {
    this.submittingReturnRouteData = true;

    if (!this.edSMOReturnRoute) {
      return false;
    }
    const formData = this.sMOReturnRouteForm.value;
    const postData = {
      returnRouteId: this.selectedSMOReturnRoute.id,
      smoRouteId: this.selectedSMOReturnIniRoute.id,
      //smoRouteId: formData.smoRouteId.id,
      rRecoveryTime: formData.rRecoveryTime,
    };
    this.smoRouteAndRegionService
      .updateReturnRoute(this.edSMOReturnRoute.id, postData)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            this.messageService.add({
              severity: "success",
              detail: "SMO ReturnRoute Updated",
              summary: "Completed",
            });
            this.submittingReturnRouteData = false;
            this.closeEditing();
            this.fetchAllReturnRoutes();
            this.sMORouteForm.reset();
            this.sMOReturnRouteForm.reset();
            this.submitReturnRouteButtonLabel = "Create";
          }
        },
        (error) => {
          this.connectionError();
          this.submittingReturnRouteData = false;
          this.submitReturnRouteButtonLabel = "Create";
        }
      );
  }

  deleteReturnRoute(sMOReturnRoute: SMOReturnRoute): void {
    this.confirmationService.confirm({
      message:
        "Are you sure you want to delete " +
        sMOReturnRoute.returnRoute.routeName,
      accept: () => {
        this._deleteSMOReturnRoute(sMOReturnRoute);
      },
    });
  }
  _deleteSMOReturnRoute(sMOReturnRoute) {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Deleting Route record",
    });
    this.smoRouteAndRegionService
      .deleteReturnRoute(sMOReturnRoute.id)
      .subscribe(
        async (result: any) => {
          await this.messageService.add({
            severity: "success",
            summary: "Deleted",
            detail: "Route record removed",
          });
          await this.fetchAllReturnRoutes();
        },
        (error) => {
          this.connectionError();
        }
      );
  }
  //For both calls
  UpdateBothRoutes() {
    this.updateSMORoute();
    if (this.sMOReturnRouteForm.valid) {
      this.updateSMOReturnRoute();
    }
  }
  CreateBothRoutes() {
    if (this.sMOReturnRouteForm.valid) {
      this.createRoute();
      this.createReturnRoute();
    } else {
      this.createRoute();
      // this.messageService.add({
      //   severity: "error",
      //   summary: "Failed",
      //   detail: "Please Input all Return Route Info",
      // });
    }
    // if (this.isReturnRouteRequired === true) {
    //   if (this.sMOReturnRouteForm.valid) {
    //     this.createRoute();
    //     this.createReturnRoute();
    //   } else {
    //     this.messageService.add({
    //       severity: "error",
    //       summary: "Failed",
    //       detail: "Please Input all Return Route Info",
    //     });
    //   }
    // } else {
    //   this.createRoute();
    // }
  }
  editBoth(smoRoute: SMORoute, smoReturnRoute: SMOReturnRoute) {
    this.editingSMORoute = true;
    this.editRoute(smoRoute);
    this.editReturnRoute(smoReturnRoute);
  }
  onSort() {
    this.updateRowGroupMetaData();
  }

  updateRowGroupMetaData() {
    this.rowGroupMetadata = {};

    if (this.smoRoutes) {
      for (let i = 0; i < this.smoRoutes.length; i++) {
        const rowData = this.smoRoutes[i];
        const representativeName = rowData.smoRegion.regionName;

        if (i === 0) {
          this.rowGroupMetadata[representativeName] = { index: 0, size: 1 };
        } else {
          const previousRowData = this.smoRoutes[i - 1];
          const previousRowGroup = previousRowData.smoRegion.regionName;
          if (representativeName === previousRowGroup) {
            this.rowGroupMetadata[representativeName].size++;
          } else {
            this.rowGroupMetadata[representativeName] = { index: i, size: 1 };
          }
        }
      }
    }
    // if (this.smoRegions) {
    //   for (let i = 0; i < this.smoRegions.length; i++) {
    //     const rowData = this.smoRegions[i];
    //     const representativeName = rowData.regionName;

    //     if (i === 0) {
    //       this.rowGroupMetadata[representativeName] = { index: 0, size: 1 };
    //     } else {
    //       const previousRowData = this.smoRegions[i - 1];
    //       const previousRowGroup = previousRowData.regionName;
    //       if (representativeName === previousRowGroup) {
    //         this.rowGroupMetadata[representativeName].size++;
    //       } else {
    //         this.rowGroupMetadata[representativeName] = { index: i, size: 1 };
    //       }
    //     }
    //   }
    // }
  }

  setAvaibaleRoutesToSelect() {
    this.availableReturnRoutes = [];
    this.smoRoutes.forEach((Rank) => {
      if (Rank.routeName !== this.selectedSMOReturnIniRoute.routeName) {
        this.availableReturnRoutes.push(Rank);
        //console.log(Rank);
      }
    });
  }

  closeEditing() {
    this.editingSMORegion = false;
    this.sMORouteRegionForm.reset();
    this.edSMORegion = null;
    //
    this.editingSMORoute = false;
    this.sMORouteForm.reset();
    this.edSMORoute = null;

    this.editingSMOReturnRoute = false;
    this.sMOReturnRouteForm.reset();
    this.edSMOReturnRoute = null;

    this.submitButtonLabel = "Create";
    this.submitRouteButtonLabel = "Create";
    this.submitReturnRouteButtonLabel = "Create";
  }

  private connectionError() {
    this.messageService.add({
      severity: "error",
      summary: "Failed",
      detail: "Connection Error, Please try again",
    });
  }

  ngOnDestroy(): void {
    this.unsubscriber$.complete();
    this.unsubscriber$.unsubscribe();
    //throw new Error('Method not implemented.');
  }
}
