import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { ConfirmationService, MessageService } from "primeng/api";
import { BreadcrumbService } from "src/app/breadcrumb.service";
import {
  ActOnBatchRequest,
  BatchRequestItem,
  StockBatch,
} from "src/app/interfaces/inventory";
import { InventoryService } from "src/app/services/inventory.service";
import { OrganizationService } from "src/app/services/organization.service";

@Component({
  selector: "app-organization-line-manager-approval",
  templateUrl: "./organization-line-manager-approval.component.html",
  styleUrls: ["./organization-line-manager-approval.component.scss"],
})
export class OrganizationLineManagerApprovalComponent implements OnInit {
  @ViewChild("formWrapper") public formWrapper: ElementRef;
  form: FormGroup;
  formAccept: FormGroup;
  allPendingApprovals: StockBatch[];
  selectedPendingApprovals: StockBatch[];
  pendingApprovalCols: any[];
  pendingApprovalDitsCols: any[];
  allPendingApprovalDits: BatchRequestItem[];
  selectedPendingApprovalDits: BatchRequestItem[] = [];
  batchInView: StockBatch;
  openDialogue: boolean;
  openAcceptDialogue: boolean;

  constructor(
    fb: FormBuilder,
    public inventoryService: InventoryService,
    private organizationService: OrganizationService,
    private breadcrumbService: BreadcrumbService,
    public confirmationService: ConfirmationService,
    public messageService: MessageService
  ) {
    this.form = fb.group({
      Comment: ["", Validators.required],
    });
    this.formAccept = fb.group({
      Comment: ["", Validators.required],
    });
  }

  ngOnInit(): void {
    this.breadcrumbService.setItems([
      {
        label: "Organization",
        routerLink: ["/home/ogranization"],
      },
      {
        label: "Line Manager Approval",
        routerLink: ["/home/organization/line-manager-approval"],
      },
    ]);

    this.pendingApprovalCols = [
      { field: "name", header: "Batch Name" },
      { field: "batchNumber", header: "Batch Number" },
      { field: "userRequested.firstName", header: "Requester Firstname" },
      { field: "userRequested.lastName", header: "Requester Lastname" },
    ];
    this.pendingApprovalDitsCols = [{ field: "caption", header: "caption" }];

    this.FetchAllBatchPendingApproval();
  }

  FetchAllBatchPendingApproval() {
    this.organizationService.GetLineManagerPendingInvBatchApprovals().subscribe(
      async (data) => {
        this.allPendingApprovals = data;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch pending approvals at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  ViewBatch(item: StockBatch) {
    this.batchInView = item;
    this.allPendingApprovalDits = item.itemsRequested;
  }

  GetTotalValue(item: StockBatch): number {
    let total = 0;
    item.itemsRequested.forEach((itemReq) => {
      total = total + itemReq.unitPrice * itemReq.quantity;
    });
    return total;
  }

  ShowDeclineBatch() {
    this.openDialogue = true;
  }

  DeclineBatch() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Declining Request" + this.batchInView.name + "...",
    });

    const postData: ActOnBatchRequest = {
      isApproved: false,
      comment: this.form.get("Comment").value,
      isCentralStore: true,
      batch: this.batchInView,
      approvedBatchItemsIds: [],
    };

    this.inventoryService.LineManagerDeclinedBatchApproval(postData).subscribe(
      async () => {
        await this.messageService.add({
          severity: "success",
          summary: "Completed",
          detail: "Request Declined Successfully.",
        });

        this.FetchAllBatchPendingApproval();
        this.batchInView = null;
        this.allPendingApprovalDits = [];
        this.selectedPendingApprovalDits = [];
        this.openDialogue = false;
      },
      (error) => {
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to decline request at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
        this.openDialogue = false;
      }
    );
  }

  HideDeclineBatchDialogue() {
    this.openDialogue = false;
  }

  HideAcceptBatchDialogue() {
    this.openAcceptDialogue = false;
  }

  ApproveBatch() {
    if (this.selectedPendingApprovalDits.length == 0) {
      this.messageService.add({
        severity: "error",
        summary: "Notice",
        detail:
          "Unable to approve request at the moment.. Reason: [You must approve at least one batch item to proceed.]",
      });
      return;
    }

    if (
      this.batchInView.itemsRequested.length !=
      this.selectedPendingApprovalDits.length
    ) {
      this.openAcceptDialogue = true;
    } else {
      this.confirmationService.confirm({
        message:
          "Kindly Confirm that you would like to proceed with this batch approval?",
        header: "Approve Request",
        accept: () => {
          this._performBatchApproval();
        },
      });
    }
  }

  _performBatchApproval() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Approving Request" + this.batchInView.name + "...",
    });

    let approvedBatchItemIds: number[] = [];
    this.selectedPendingApprovalDits.forEach((approved) => {
      approvedBatchItemIds.push(approved.id);
    });

    const postData: ActOnBatchRequest = {
      isApproved: true,
      isCentralStore: true,
      batch: this.batchInView,
      approvedBatchItemsIds: approvedBatchItemIds,
      comment: this.formAccept.get("Comment").value,
    };

    this.inventoryService.LineManagerApprovalInventoryBatch(postData).subscribe(
      async () => {
        await this.messageService.add({
          severity: "success",
          summary: "Completed",
          detail: "Request Approved Successfully.",
        });

        this.openAcceptDialogue = false;
        this.FetchAllBatchPendingApproval();
        this.batchInView = null;
        this.allPendingApprovalDits = [];
        this.selectedPendingApprovalDits = [];
        this.openDialogue = false;
      },
      (error) => {
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to approve request at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }
}
