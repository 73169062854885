import {Component, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ClientContactQualification, CommonResponse, Constant, Contact, Designation, LeadDivision, LeadDivisionKeyPerson} from '../../../../../interfaces/home';
import {LeadService} from '../../../../../services/lead.service';
import {LeadDivisionContactService} from '../../../../../services/lead-division-contact.service';
import {ConfirmationService, MessageService} from 'primeng/api';
import {LeadDivisionService} from '../../../../../services/lead-division.service';
import {LeadDivisionKeyPersonService} from '../../../../../services/lead-division-key-person.service';
import { DesignationService } from 'src/app/services/designation.service';
import { DialogService } from 'primeng/dynamicdialog';
import { KeyPersonnelComponent } from 'src/app/home/client-management/client-view/key-personnel/key-personnel.component';
import { ClientContactQualificationService } from 'src/app/services/client-contact-qualification.service';
import { EventEmitter } from '@angular/core';

@Component({
  selector: 'app-division-key-person',
  templateUrl: './division-key-person.component.html',
  styleUrls: ['./division-key-person.component.scss'],
  providers: [Constant]
})
export class DivisionKeyPersonComponent implements OnInit {
  @Input() suggestedKeyPerson: Contact | null;
  @Input() isPopup: Boolean;
  @Output() saveSuccessful = new EventEmitter<Boolean>();
  divisionKeyPersonForm: FormGroup;
  CurrentLeadDivisionData: LeadDivision = null;
  editingContact: boolean;
  public selectedLeadDivisionKeyPerson: LeadDivisionKeyPerson;
  designations: Designation[] = [];
  selectedDesignation: Designation = null;
  clientContactQualifications: ClientContactQualification[] = [];
  selectedClientContactQualification: ClientContactQualification = null;
  keyPersonnelRef: any;
  leadDivisionKeyPersons: LeadDivisionKeyPerson[] = [];
  titles : any[] = [];
  selectedTitle : any = null;
  genders : any[] = [];
  selectedGender : any = null;
  constructor(
      public leadService: LeadService,
      private formBuilder: FormBuilder,
      private leadDivisionKeyPersonService: LeadDivisionKeyPersonService,
      private designationService: DesignationService,
      private clientContactQualificationService: ClientContactQualificationService,
      private messageService: MessageService,
      private dialogService: DialogService,
      private confirmationService: ConfirmationService,
      private leadDivisionService: LeadDivisionService,
      private constants: Constant
  ) { }
  ngOnInit(): void {
    this.fetchDesignations();
    this.fetchContactQualifications();
    this.editingContact = false;
    this.titles = this.constants.titles;
    this.genders = this.constants.genders;
    this.divisionKeyPersonForm = this.formBuilder.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      mobileNumber: ['', Validators.required],
      designation: [null, Validators.required],
      contactQualification: [null, Validators.required],
      dateOfBirth: [''],    
      title: ['',  Validators.required],
      gender: ['',  Validators.required],
      type: 0,
      leadDivisionId: '',
    });
    this.divisionKeyPersonForm.disable();
    this.leadDivisionService.activeLeadDivision().subscribe( async (res: any) => {
      this.CurrentLeadDivisionData = res;
      this.divisionKeyPersonForm.reset();
      this.editingContact = false;
      this.selectedLeadDivisionKeyPerson = null;
      if(res !== null && res){
        await this.divisionKeyPersonForm.enable();
        const _keyPersonContact = res.leadDivisionKeyPeople ?? [];
        console.log('Lead division key persons ', _keyPersonContact)
        this.leadDivisionKeyPersons = _keyPersonContact;
        if(_keyPersonContact && _keyPersonContact[0]){
          /*this.editingContact = true;
          this.selectedLeadDivisionKeyPerson = _keyPersonContact[0];
          this.divisionKeyPersonForm.patchValue({
            firstName: this.selectedLeadDivisionKeyPerson.firstName,
            lastName: this.selectedLeadDivisionKeyPerson.lastName,
            email: this.selectedLeadDivisionKeyPerson.email,
            mobileNumber: this.selectedLeadDivisionKeyPerson.mobileNumber,
            dateOfBirth: this.selectedLeadDivisionKeyPerson.dateOfBirth,
          });
          this.selectedDesignation = this.designations.find(x => x.id === this.selectedLeadDivisionKeyPerson.designation.id);*/
        }
      }
    });
  }

  createContact() {
    this.messageService.add({severity:'info', summary: 'Notice',
      detail:'Processing Key Person Creation'});
    this.divisionKeyPersonForm.get('leadDivisionId').setValue(this.CurrentLeadDivisionData.id);
    const postData= this.divisionKeyPersonForm.value;
    postData.gender = this.divisionKeyPersonForm.value.gender.value;
    postData.title = this.divisionKeyPersonForm.value.title.value;
    postData.designationId = this.selectedDesignation.id;
    postData.clientContactQualificationId = this.selectedClientContactQualification.id;
    if(this.divisionKeyPersonForm.valid) {
      this.leadDivisionKeyPersonService.postLeadDivisionKeyPerson( postData).subscribe( async (res: any) => {
        this.saveSuccessful.emit(true);
        await this.messageService.add({severity:'success', summary: 'Notice',
          detail:'Key Person Submitted'});
        await this.divisionKeyPersonForm.reset();
        this.leadService.refreshData();
      });
    }
  }
  activeKeyPerson(leadDivisionKeyPerson: LeadDivisionKeyPerson){
    this.selectedLeadDivisionKeyPerson = leadDivisionKeyPerson;
  }

  async fetchDesignations() {
    await this.designationService.allDesignationData().subscribe(async (res: CommonResponse) => {
        this.designations = res.responseData;
    }, error => this.connectionError());  
  }

  async fetchContactQualifications() {
    await this.clientContactQualificationService.allClientContactQualificationData().subscribe(async (res: CommonResponse) => {
        this.clientContactQualifications = res.responseData;
    }, error => this.connectionError());  
  }

  updateContact() {
    this.messageService.add({severity:'info', summary: 'Notice',
      detail:'Processing Lead Key Person Update'});
    const postData = this.divisionKeyPersonForm.value; 
    postData.gender = this.divisionKeyPersonForm.value.gender.value;
    postData.title = this.divisionKeyPersonForm.value.title.value;
    postData.designationId = this.selectedDesignation.id;
    postData.clientContactQualificationId = this.selectedClientContactQualification.id;
    if(this.divisionKeyPersonForm.valid) {
      this.leadDivisionKeyPersonService.updateLeadDivisionKeyPerson(this.selectedLeadDivisionKeyPerson.id, postData).subscribe( (res: any) => {
        this.messageService.add({severity:'success', summary: 'Notice',
          detail:'Lead Key Person Submitted'});
        this.leadService.refreshData();
      }) ;
    }
  }

  async cancelEditContact() {
    this.editingContact = false;
    this.divisionKeyPersonForm.reset();
    await this.divisionKeyPersonForm.enable();
  }

  viewKeyPersonnels(){
    if(this.leadDivisionKeyPersons){
      this.keyPersonnelRef = this.dialogService.open(KeyPersonnelComponent, {
        header: 'Key Personnels',
        width: '80%',
        contentStyle: {'min-height': '350px', overflow: 'auto'},
        baseZIndex: 10000,
        data: {keyPersonnels: this.leadDivisionKeyPersons, editable: true}
      });

      this.keyPersonnelRef.onClose.subscribe(async (res: any) => {
        if(res){
            this.editingContact = true;
            this.selectedLeadDivisionKeyPerson = res;
            this.divisionKeyPersonForm.patchValue({
              firstName: this.selectedLeadDivisionKeyPerson.firstName,
              lastName: this.selectedLeadDivisionKeyPerson.lastName,
              email: this.selectedLeadDivisionKeyPerson.email,
              mobileNumber: this.selectedLeadDivisionKeyPerson.mobileNumber,
              dateOfBirth: this.selectedLeadDivisionKeyPerson.dateOfBirth
            });
            this.selectedTitle = this.titles.find(title => title.value.toLowerCase() === this.selectedLeadDivisionKeyPerson.title.toLowerCase());
            this.selectedGender = this.genders.find(gender => gender.value.toLowerCase() === this.selectedLeadDivisionKeyPerson.gender.toLowerCase());
            this.selectedDesignation = this.designations.find(x => x.id === this.selectedLeadDivisionKeyPerson.designationId);
            this.selectedClientContactQualification = this.clientContactQualifications
                .find(x => x.id === this.selectedLeadDivisionKeyPerson.clientContactQualificationId);
        }
      })
    }
  }

  private connectionError() {
    this.messageService.add({
        severity: 'error', summary: 'Failed',
        detail: 'Connection Error, Please try again'
    });
  }

}
