import { THIS_EXPR } from "@angular/compiler/src/output/output_ast";
import { Component, OnInit } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { ActivatedRoute } from "@angular/router";
import { el } from "date-fns/locale";
import { MessageService } from "primeng/api";
import { DialogService } from "primeng/dynamicdialog";
import { async } from "rxjs/internal/scheduler/async";
import { EventService } from "src/app/demo/service/eventservice";
import { SalesDashboardService } from "./sales-dashboard.service";
import * as FileSaver from "file-saver";
import { IServiceCategory } from "./serviceCategory";
import { forkJoin, Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { map } from "rxjs/operators";
var lodash = require("lodash");

@Component({
  selector: "app-sales-dashboard",
  templateUrl: "./sales-dashboard.component.html",
  styleUrls: ["./sales-dashboard.component.scss"],
})
export class SalesDashboardComponent implements OnInit {
  dates: Date[];
  rangeDates: Date[];
  minDate: Date;
  maxDate: Date;
  currentYear = new Date().getFullYear();
  currentMonth = new Date().getUTCMonth();
  invalidDates: Array<Date>;
  date9: Date;
  date10: Date;
  workspaceIsLoading = false;
  divisionArray: any[] = [];
  serviceGroupArray: any[] = [];
  serviceGroupArrayExtra: any[] = [];
  serviceCategories: any[] = [];
  services: any[];
  dateDropdown: any[] = [];
  dateOption: number;
  displayService = false;
  selectedYear = {
    index: 0,
    year: 0,
  };
  years: number[] = [];
  currentData: { index: number; year: number };
  amortizationArray: any[] = [];
  recordCount = 0;
  monthsArray: any[] = [];
  selectedMonth = {
    value: 0,
    name: "",
  };
  newLabels: any[] = [];
  labels: any;
  datasets: string[];
  getTotalSalesCount: {
    labels: string[];
    datasets: {
      label: string;
      data: number[];
      fill: boolean;
      backgroundColor: string;
      borderColor: string;
    }[];
  };
  monthList: string[];
  monthListArray: any[] = [];
  januaryArray: any[];
  getTotalSalesValue: {
    labels: string[];
    datasets: {
      label: string;
      data: any[];
      fill: boolean;
      backgroundColor: string;
      borderColor: string;
    }[];
  };
  monthListArrayValue: any[] = [];
  SalesDistributionByDivision: {
    labels: string[];
    datasets: { data: number[]; backgroundColor: string[] }[];
  };
  divisionNames: any[] = [];
  divisionNumber: any[] = [];
  repAmortizationDetails: any[] = [];
  SalesDistributionByMarket: {
    labels: any[];
    datasets: { data: any[]; backgroundColor: string[] }[];
  };
  markets: any[] = [];
  marketNames: any[] = [];
  marketNumberArray: any[] = [];
  serviceCategeriesName: any[] = [];
  serviceCatgeoryArray: any[] = [];
  SalesDistributionByServiceCategory: {
    labels: any[];
    datasets: { data: any[]; backgroundColor: string[] }[];
  };
  serviceGroupName: any[] = [];
  serviceGroupList: any[] = [];
  SalesDistributionByServiceGroup: {
    labels: any[];
    datasets: { data: any[]; backgroundColor: string[] }[];
  };
  colorArray: any[] = [];
  divisionColorArray: any[] = [];
  serviceCategoriescolorArray: any[] = [];
  serviceGroupscolorArray: any[] = [];
  marketcolorArray: any[] = [];
  amortizationDetails: any[] = [];
  amortizationComplete: any[] = [];
  amortizationDetailsArray: any[] = [];
  amortizationArrayExtra: any[] = [];
  filteredBydivision: any[] = [];
  filteredByServiceGroup: any[] = [];
  filteredByServiceCategory: any[] = [];
  filteredByService: any[] = [];
  endorsementType: any[] = [];
  amortizationTopLength = 0;
  selectedDivision: any;
  selectedServiceGroups: any;
  selectedServiceCategory: any;
  selectedServices: any;
  amortizationNewSalesLength = 0;
  billerArray: any[] = [];
  topUpTotal = 0;
  totalSalesMade = 0;
  totalNewSales = 0;
  amortizationrepAmortizationDetails: any[] = [];
  totalNewSalesBill: any[] = [];
  totalSalesMadeBill: any[] = [];
  totalTopUpSales = 0;
  amortizationrepAmortizationDetails3: any[] = [];
  amortizationrepAmortizationDetails2: any[] = [];
  amortizationrepAmortizationDetails1: any[] = [];
  totalNewSalesBill1: any[] = [];
  totalSalesMadeBill2: any[] = [];
  totalSalesMadeBill3: any[] = [];
  serviceCategoriesExtra: any[] = [];
  serviceExtra: any[] = [];
  newServiceCategory: any[] = [];
  SelectedServiceCategories: any[];
  SelectedServices: any[];
  selectedOperatingEntity: any;
  serviceCategoryCol: (
    | { field: string; header: string; customExportHeader: string }
    | { field: string; header: string; customExportHeader?: undefined }
  )[];
  serviceCol: (
    | { field: string; header: string; customExportHeader: string }
    | { field: string; header: string; customExportHeader?: undefined }
  )[];
  marketsExtra: any;
  filteredByOperatingEntity: any[] = [];
  divisionArrayExtra: any[] = [];
  service: any[];
  servicesExtra: any[] = [];
  serviceCategoryName: any;
  serviceCategoryId: any;
  newServiceArray: any[] = [];
  serviceColExtra: (
    | { field: string; header: string; customExportHeader: string }
    | { field: string; header: string; customExportHeader?: undefined }
  )[];
  totalNumberOfRenewals = 0;
  totalSumOfRenewals = 0;
  totalRenewalsBills: any[] = [];
  amortizationrepAmortizationDetails4: any;
  totalNumberOfReductions = 0;
  totalSumOfReductions = 0;
  totalReductionsBills: any[] = [];
  customerDivision: any[] = [];
  customerDivisionExtra: any[] = [];
  sampleAmortizationList: any[];
  filteredArrayByServiceCategory: any[];
  initialAmortizationArray: any[] = [];
  amortizationMaster: any[] = [];
  resultingAmortizationArray: any[] = [];

  constructor(
    private route: ActivatedRoute,
    public dialogService: DialogService,
    public messageService: MessageService,
    private sanitizer: DomSanitizer,
    private eventService: EventService,
    private salesdasboardService: SalesDashboardService
  ) {
    this.getAllMonth();
  }

  ngOnInit(): void {
    this.fetchRecordsByMonthOrYear();
    this.getListYear();
    //this.getCurrentMonth();
  }

  getAllMonth() {
    this.monthsArray = [
      { value: 1, name: "January" },
      { value: 2, name: "February" },
      { value: 3, name: "March" },
      { value: 4, name: "April" },
      { value: 5, name: "May" },
      { value: 6, name: "June" },
      { value: 7, name: "July" },
      { value: 8, name: "August" },
      { value: 9, name: "September" },
      { value: 10, name: "October" },
      { value: 11, name: "November" },
      { value: 12, name: "December" },
    ];

    this.serviceCategoryCol = [
      {
        field: "serviceCategory",
        header: "ServiceCategory Name",
        customExportHeader: "Product Code",
      },
      { field: "TotalAmount", header: "Total Amount" },
    ];

    this.serviceCol = [
      { field: "client", header: "Client" },
      { field: "serviceName", header: "Service Name" },
      { field: "endorsement", header: "Endorsement Type" },
      { field: "month", header: "Month" },
      { field: "amount", header: "Amount" },
    ];

    this.serviceColExtra = [
      {
        field: "service.name",
        header: "Service Name",
        customExportHeader: "Product Code",
      },
      { field: "service.description", header: " Service Description" },
      { field: "endorsementType.caption", header: "EndorsementType" },
      {
        field: "getTotalSold(service.repAmortizationDetails)",
        header: "TotalBillableAmount",
      },
    ];
  }

  getListYear() {
    let currentYear = new Date().getFullYear();
    let earliestYear = 2000;
    var index = 0;

    while (currentYear >= earliestYear) {
      this.currentData = {
        index: 0,
        year: 0,
      };
      this.currentData.index = index++;
      this.currentData.year = currentYear + 20;
      this.dateDropdown.push(this.currentData);
      currentYear -= 1;
    }
  }

  //returnAmortizationArray()

  getNewSalesMade(amortization) {
    this.amortizationNewSalesLength = 0;
    this.amortizationNewSalesLength =
      this.amortizationArray.length == 0
        ? 0
        : this.amortizationArray.filter((el) => {
            return el.endorsementTypeId == environment.endorsementType.New;
          }).length;
  }

  getTotalNewSales(amortization) {
    this.totalNewSales = 0;
    this.totalNewSalesBill1 = [];
    this.amortizationrepAmortizationDetails1 = amortization.forEach((el) => {
      if (
        el.endorsementTypeId != environment.endorsementType.ServiceRetention &&
        el.endorsementTypeId != environment.endorsementType.ServiceTopUp
      ) {
        this.totalNewSalesBill1.push(...el.repAmortizationDetails);
      }
    });
    this.totalNewSales = this.totalNewSalesBill1.reduce(
      (accum, item) => accum + item.billableAmount,
      0
    );
    console.log("the getTotalNewSales value", this.totalNewSales);
  }

  getTotalSalesMade(amortization) {
    this.totalSalesMade = 0;
    this.totalSalesMadeBill2 = [];
    this.amortizationrepAmortizationDetails2 = amortization.forEach((el) => {
      if (
        el.endorsementTypeId != environment.endorsementType.ServiceRetention
      ) {
        this.totalSalesMadeBill2.push(...el.repAmortizationDetails);
      }
    });
    this.totalSalesMade = this.totalSalesMadeBill2.reduce(
      (accum, item) => accum + item.billableAmount,
      0
    );
    console.log("the getTotalSalesMade value", this.totalSalesMade);
  }

  getTotalTopUpSales(amortization) {
    this.totalTopUpSales = 0;
    this.totalSalesMadeBill3 = [];
    this.amortizationrepAmortizationDetails3 = amortization.forEach((el) => {
      if (el.endorsementTypeId == environment.endorsementType.ServiceTopUp) {
        this.totalSalesMadeBill3.push(...el.repAmortizationDetails);
      }
    });
    this.totalTopUpSales = this.totalSalesMadeBill3.reduce(
      (accum, item) => accum + item.billableAmount,
      0
    );
  }

  getTotalNumberOfRenewals(amortization) {
    this.totalNumberOfRenewals = 0;
    this.totalNumberOfRenewals =
      this.amortizationArray.length == 0
        ? 0
        : this.amortizationArray.filter((el) => {
            return (
              el.endorsementTypeId ==
              environment.endorsementType.ServiceRetention
            );
          }).length;
  }

  getTotalSumOfRenewals(amortization) {
    this.totalSumOfRenewals = 0;
    this.totalRenewalsBills = [];
    this.amortizationrepAmortizationDetails4 = amortization.forEach((el) => {
      if (
        el.endorsementTypeId == environment.endorsementType.ServiceRetention
      ) {
        this.totalRenewalsBills.push(...el.repAmortizationDetails);
      }
    });
    this.totalSumOfRenewals = this.totalRenewalsBills.reduce(
      (accum, item) => accum + item.billableAmount,
      0
    );
  }

  getTotalNumberOfReductions(amortization) {
    this.totalNumberOfReductions = 0;
    this.totalNumberOfReductions =
      this.amortizationArray.length == 0
        ? 0
        : this.amortizationArray.filter((el) => {
            return (
              el.endorsementTypeId ==
              environment.endorsementType.ServiceReduction
            );
          }).length;
  }

  getTotalSumOfReductions(amortization) {
    this.totalSumOfReductions = 0;
    this.totalReductionsBills = [];
    this.amortizationrepAmortizationDetails4 = amortization.forEach((el) => {
      if (
        el.endorsementTypeId == environment.endorsementType.ServiceReduction
      ) {
        this.totalReductionsBills.push(...el.repAmortizationDetails);
      }
    });
    this.totalSumOfReductions = this.totalReductionsBills.reduce(
      (accum, item) => accum + item.billableAmount,
      0
    );
  }

  filterByDivisions(event) {
    this.selectedServiceCategory = null;
    this.selectedServiceGroups = null;
    this.selectedServices = null;
    this.filteredBydivision = [];
    this.divisionArray = this.divisionArrayExtra;
    this.amortizationArray = this.amortizationArrayExtra;
    this.recordCount = this.amortizationArray.length;
    console.log("this is the Old amortizationArray", this.amortizationArray);
    console.log("this is the selected division", event.value);
    var divisionId = event.value.id;
    this.amortizationArray.forEach((amortizationInstance) => {
      if (amortizationInstance.divisionId == divisionId) {
        this.filteredBydivision.push(amortizationInstance);
      }
    });
    // this.filteredBydivision = this.amortizationArray.filter((amortization)=>{
    //   return amortization.divisionId = divisionId
    // })
    console.log("filtered by division", this.filteredBydivision);
    this.amortizationArray = this.filteredBydivision;
    this.recordCount = this.amortizationArray.length;
    console.log("this is the new amortizationArray", this.amortizationArray);
    this.getTotalSalesByCount(this.amortizationArray);
    this.getTotalSalesByValue(this.amortizationArray);
    this.getSalesDistributionByDivision(this.amortizationArray);
    this.getSalesDistributionByMarket(this.amortizationArray);
    this.getSalesDistributionByServiceCategories(this.amortizationArray);
    //this.newServiceCategory = this.returnAmortizationData(this.amortizationArray)
    this.getSalesDistributionByService(this.amortizationArray);
    this.getNewSalesMade(this.amortizationArray);
    this.getTotalNewSales(this.amortizationArray);
    this.getTotalSalesMade(this.amortizationArray);
    this.getTotalTopUpSales(this.amortizationArray);
    this.getTotalNumberOfRenewals(this.amortizationArray);
    this.getTotalSumOfRenewals(this.amortizationArray);
    this.getTotalNumberOfReductions(this.amortizationArray);
    this.getTotalSumOfReductions(this.amortizationArray);
    console.log("new serviceGroup", this.serviceGroupArray);
    this.markets = event.value.operatingEntities;
    this.workspaceIsLoading = false;
  }

  filterByMarket(event) {
    console.log("this is the selected market", event.value);
    this.selectedServiceGroups = null;
    this.filteredByOperatingEntity = [];
    this.serviceGroupArray = this.serviceGroupArrayExtra;
    this.recordCount = this.amortizationArray.length;
    var operatingEntityId = event.value.id;
    this.amortizationArray.forEach((amortizationInstance) => {
      if (amortizationInstance.operatingEntityId == operatingEntityId) {
        this.filteredByOperatingEntity.push(amortizationInstance);
      }
    });
    console.log(
      "filtered by operatingEntityId",
      this.filteredByOperatingEntity
    );
    this.amortizationArray = this.filteredByOperatingEntity;
    this.recordCount = this.amortizationArray.length;
    console.log("this is the new amortizationArray", this.amortizationArray);
    this.getTotalSalesByCount(this.amortizationArray);
    this.getTotalSalesByValue(this.amortizationArray);
    this.getSalesDistributionByDivision(this.amortizationArray);
    this.getSalesDistributionByMarket(this.amortizationArray);
    this.getSalesDistributionByServiceCategories(this.amortizationArray);
    //this.newServiceCategory = this.returnAmortizationData(this.amortizationArray)
    this.getSalesDistributionByService(this.amortizationArray);
    this.getNewSalesMade(this.amortizationArray);
    this.getTotalNewSales(this.amortizationArray);
    this.getTotalSalesMade(this.amortizationArray);
    this.getTotalTopUpSales(this.amortizationArray);
    this.getTotalNumberOfRenewals(this.amortizationArray);
    this.getTotalSumOfRenewals(this.amortizationArray);
    this.getTotalNumberOfReductions(this.amortizationArray);
    this.getTotalSumOfReductions(this.amortizationArray);
    this.serviceGroupArray = event.value.serviceGroups;
    this.workspaceIsLoading = false;
  }

  filterByServiceGroups(event) {
    //this.selectedServiceCategory = null;
    //this.selectedDivision = null;
    this.selectedServices = null;
    this.filteredByServiceGroup = [];
    var divisionId = event.value.divisionId;
    this.serviceCategories = this.serviceCategoriesExtra;
    //this.amortizationArray = this.amortizationArrayExtra;
    this.recordCount = this.amortizationArray.length;
    console.log("this is the Old amortizationArray", this.amortizationArray);
    console.log("this is the selected serviceGroups", event.value);
    var serviceGroupId = event.value.id;
    this.amortizationArray.forEach((amortizationInstance) => {
      if (amortizationInstance.serviceGroupId == serviceGroupId) {
        this.filteredByServiceGroup.push(amortizationInstance);
      }
    });
    console.log("filtered by division", this.filteredByServiceGroup);
    this.amortizationArray = this.filteredByServiceGroup;
    this.recordCount = this.amortizationArray.length;
    console.log("this is the new amortizationArray", this.amortizationArray);
    this.getTotalSalesByCount(this.amortizationArray);
    this.getTotalSalesByValue(this.amortizationArray);
    this.getSalesDistributionByDivision(this.amortizationArray);
    this.getSalesDistributionByMarket(this.amortizationArray);
    this.getSalesDistributionByServiceCategories(this.amortizationArray);
    //this.newServiceCategory = this.returnAmortizationData(this.amortizationArray)
    this.getSalesDistributionByService(this.amortizationArray);
    this.getNewSalesMade(this.amortizationArray);
    this.getTotalNewSales(this.amortizationArray);
    this.getTotalSalesMade(this.amortizationArray);
    this.getTotalTopUpSales(this.amortizationArray);
    this.getTotalNumberOfRenewals(this.amortizationArray);
    this.getTotalSumOfRenewals(this.amortizationArray);
    this.getTotalNumberOfReductions(this.amortizationArray);
    this.getTotalSumOfReductions(this.amortizationArray);
    this.serviceCategories = event.value.serviceCategories;
    console.log("this is the selected serviceCategory", this.serviceCategories);
    this.workspaceIsLoading = false;
  }

  filterByServiceCategory(event) {
    console.log("this is the selected serviceCategory", event.value);
    this.filteredByServiceCategory = [];
    this.services = this.serviceExtra;
    //this.amortizationArray = this.amortizationArrayExtra;
    this.recordCount = this.amortizationArray.length;
    var serviceCategoryId = event.value.id;
    this.amortizationArray.forEach((amortizationInstance) => {
      if (amortizationInstance.serviceCategoryId == serviceCategoryId) {
        this.filteredByServiceCategory.push(amortizationInstance);
      }
    });
    this.amortizationArray = this.filteredByServiceCategory;
    this.recordCount = this.amortizationArray.length;
    console.log("this is the new amortizationArray", this.amortizationArray);
    this.getTotalSalesByCount(this.amortizationArray);
    this.getTotalSalesByValue(this.amortizationArray);
    this.getSalesDistributionByDivision(this.amortizationArray);
    this.getSalesDistributionByMarket(this.amortizationArray);
    this.getSalesDistributionByServiceCategories(this.amortizationArray);
    //this.newServiceCategory = this.returnAmortizationData(this.amortizationArray)
    this.getSalesDistributionByService(this.amortizationArray);
    this.getNewSalesMade(this.amortizationArray);
    this.getTotalNewSales(this.amortizationArray);
    this.getTotalSalesMade(this.amortizationArray);
    this.getTotalTopUpSales(this.amortizationArray);
    this.getTotalNumberOfRenewals(this.amortizationArray);
    this.getTotalSumOfRenewals(this.amortizationArray);
    this.getTotalNumberOfReductions(this.amortizationArray);
    this.getTotalSumOfReductions(this.amortizationArray);
    this.services = event.value.services;
    this.workspaceIsLoading = false;
  }

  getEndorsementType() {
    this.salesdasboardService
      .getEndorsementType()
      .subscribe(async (data: any) => {
        if (data.responseCode == "00") {
          this.endorsementType = data.responseData;
          console.log("endorsements", this.endorsementType);
        }
      });
  }

  changeEndorsement(event, valueSelected) {
    console.log("this is a changedEvent", event);
    console.log("this is a valueSelected", valueSelected);
  }

  resetFilter() {
    this.selectedDivision = null;
    this.selectedServiceCategory = null;
    this.selectedServiceGroups = null;
    this.selectedServices = null;
    this.selectedOperatingEntity = null;
    this.amortizationArray = this.amortizationArrayExtra;
    this.recordCount = this.amortizationArray.length;
    this.divisionArray = this.divisionArrayExtra;
    this.serviceCategories = this.serviceCategoriesExtra;
    this.serviceGroupArray = this.serviceGroupArrayExtra;
    this.markets = this.marketsExtra;
    this.services = this.servicesExtra;
    this.getTotalSalesByCount(this.amortizationArray);
    this.getTotalSalesByValue(this.amortizationArray);
    this.getSalesDistributionByDivision(this.amortizationArray);
    this.getSalesDistributionByMarket(this.amortizationArray);
    this.getSalesDistributionByServiceCategories(this.amortizationArray);
    //this.newServiceCategory = this.returnAmortizationData(this.amortizationArray)
    this.getSalesDistributionByService(this.amortizationArray);
    this.getNewSalesMade(this.amortizationArray);
    this.getTotalNewSales(this.amortizationArray);
    this.getTotalSalesMade(this.amortizationArray);
    this.getTotalTopUpSales(this.amortizationArray);
    this.getTotalNumberOfRenewals(this.amortizationArray);
    this.getTotalSumOfRenewals(this.amortizationArray);
    this.getTotalNumberOfReductions(this.amortizationArray);
    this.getTotalSumOfReductions(this.amortizationArray);
    this.workspaceIsLoading = false;
  }

  exportExcel() {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(this.newServiceCategory);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer: any = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      this.saveAsExcelFile(excelBuffer, "products");
    });
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    let EXCEL_TYPE =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    let EXCEL_EXTENSION = ".xlsx";
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE,
    });
    FileSaver.saveAs(
      data,
      fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION
    );
  }

  filterByService(event) {
    console.log("this is the new service", event.value);
    this.filteredByService = [];
    this.amortizationArray = this.amortizationArrayExtra;
    this.recordCount = this.amortizationArray.length;
    var serviceId = event.value.id;
    this.amortizationArray.forEach((amortizationInstance) => {
      if (amortizationInstance.serviceId == serviceId) {
        this.filteredByService.push(amortizationInstance);
      }
    });
    this.amortizationArray = this.filteredByService;
    this.recordCount = this.amortizationArray.length;
    console.log("this is the new amortizationArray", this.amortizationArray);
    this.getTotalSalesByCount(this.amortizationArray);
    this.getTotalSalesByValue(this.amortizationArray);
    this.getSalesDistributionByDivision(this.amortizationArray);
    this.getSalesDistributionByMarket(this.amortizationArray);
    this.getSalesDistributionByServiceCategories(this.amortizationArray);
    //this.newServiceCategory = this.returnAmortizationData(this.amortizationArray)
    this.getSalesDistributionByService(this.amortizationArray);
    this.getNewSalesMade(this.amortizationArray);
    this.getTotalNewSales(this.amortizationArray);
    this.getTotalSalesMade(this.amortizationArray);
    this.getTotalTopUpSales(this.amortizationArray);
    this.getTotalNumberOfRenewals(this.amortizationArray);
    this.getTotalSumOfRenewals(this.amortizationArray);
    this.getTotalNumberOfReductions(this.amortizationArray);
    this.getTotalSumOfReductions(this.amortizationArray);
  }

  getTotalSalesByValue(amortizationArray) {
    this.monthListArrayValue = [];
    this.repAmortizationDetails = [];
    console.log(
      "this is the length under getTotalSalesByValue ",
      amortizationArray.length
    );
    amortizationArray.forEach((el) => {
      this.repAmortizationDetails.push(...el.repAmortizationDetails);
    });

    this.monthsArray.forEach((month) => {
      var currentAmount = 0;
      this.repAmortizationDetails.forEach((details) => {
        if (month.value == details.month) {
          currentAmount = currentAmount + details.billableAmount;
        }
      });
      this.monthListArrayValue.push(Math.round(currentAmount));
    });
    console.log(
      "this iss the monthListArrayValue by Value",
      this.monthListArrayValue
    );
    this.getTotalSalesValue = {
      labels: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
      datasets: [
        {
          label: "Sales Value",
          data: this.monthListArrayValue,
          fill: false,
          backgroundColor: "#42A5F5",
          borderColor: "#42A5F5",
        },
      ],
    };
  }

  getSalesDistributionByDivision(amortizationArray) {
    this.divisionNames = [];
    this.divisionNumber = [];
    console.log(
      "this is the length under getSalesDistributionByDivision ",
      amortizationArray.length
    );
    this.divisionArray.forEach((el) => {
      this.divisionNames.push(el.name);
    });

    this.divisionArray.forEach((division) => {
      var currentDivisionNumber = amortizationArray.filter((el) => {
        return division.id == el.divisionId;
      }).length;

      this.divisionNumber.push(currentDivisionNumber);
    });
    this.divisionNames.forEach((el) => {
      this.divisionColorArray.push(
        "rgb(" +
          Math.round(Math.random() * 255) +
          "," +
          Math.round(Math.random() * 255) +
          "," +
          Math.round(Math.random() * 255) +
          ")"
      );
    });

    console.log("divisionColor", this.divisionColorArray);

    this.SalesDistributionByDivision = {
      labels: this.divisionNames,
      datasets: [
        {
          data: this.divisionNumber,
          backgroundColor: this.divisionColorArray,
        },
      ],
    };
  }

  returnAmortizationData(amortizationArray): any[] {
    //let amortizationData = [];
    let newServiceCategory = [];
    this.serviceCategories.forEach((serviceCategory) => {
      this.sampleAmortizationList = [];
      this.filteredArrayByServiceCategory = [];
      this.filteredArrayByServiceCategory = amortizationArray.filter((am) => {
        return am.serviceCategoryId == serviceCategory.id;
      });

      this.filteredArrayByServiceCategory.forEach((el) => {
        el.repAmortizationDetails.forEach((detail) => {
          this.sampleAmortizationList.push({
            serviceName: this.services.filter((service) => {
              return service.id == el.serviceId;
            })[0]?.name,
            endorsement: this.endorsementType.filter((endorsement) => {
              return endorsement.id == el.endorsementTypeId;
            })[0]?.caption,
            client: this.customerDivision.filter((customer) => {
              return customer.id == el.customerDivisionId;
            })[0]?.divisionName,
            amount: detail.billableAmount,
            month: detail.month,
          });
        });
      });
      newServiceCategory.push({
        serviceCategoryId: serviceCategory.id,
        serviceCategory: serviceCategory.name,
        amortizationList: this.sampleAmortizationList.sort(
          (a, b) =>
            a.client?.length - b.client?.length ||
            a.serviceName?.length - b.serviceName?.length ||
            a.endorsement?.length - b.endorsement?.length ||
            a.month - b.month ||
            b.amount - a.amount
        ),
        TotalAmount: this.sampleAmortizationList.reduce(
          (accum, x) => accum + x.amount,
          0
        ),
      });
    });
    return newServiceCategory;
  }

  getSalesDistributionByServiceCategories(amortizationArray) {
    let newServiceCategory = [];
    this.serviceCategories.forEach((serviceCategory) => {
      this.sampleAmortizationList = [];
      this.filteredArrayByServiceCategory = [];
      this.filteredArrayByServiceCategory = amortizationArray.filter((am) => {
        return am.serviceCategoryId == serviceCategory.id;
      });

      this.filteredArrayByServiceCategory.forEach((el) => {
        el.repAmortizationDetails.forEach((detail) => {
          this.sampleAmortizationList.push({
            serviceName: this.services.filter((service) => {
              return service.id == el.serviceId;
            })[0]?.name,
            endorsement: this.endorsementType.filter((endorsement) => {
              return endorsement.id == el.endorsementTypeId;
            })[0]?.caption,
            client: this.customerDivision.filter((customer) => {
              return customer.id == el.customerDivisionId;
            })[0]?.divisionName,
            amount: detail.billableAmount,
            month: detail.month,
          });
        });
      });

      newServiceCategory.push({
        serviceCategoryId: serviceCategory.id,
        serviceCategory: serviceCategory.name,
        amortizationList: this.sampleAmortizationList.sort(
          (a, b) =>
            a.client?.length - b.client?.length ||
            a.serviceName?.length - b.serviceName?.length ||
            a.endorsement?.length - b.endorsement?.length ||
            a.month - b.month ||
            b.amount - a.amount
        ),
        TotalAmount: this.sampleAmortizationList.reduce(
          (accum, x) => accum + x.amount,
          0
        ),
      });

      this.newServiceCategory = newServiceCategory;
    });
    console.log(
      "This are the service Categories in the succession  ",
      this.newServiceCategory
    );
  }

  getMonthName(monthValue): any {
    return this.monthsArray.filter((el) => {
      return el.value == monthValue;
    })[0].name;
  }

  getTotalSold(serviceArray): number {
    return serviceArray.reduce((accum, x) => accum + x.billableAmount, 0);
  }

  getSalesDistributionByService(amortizationArray) {
    this.serviceGroupName = [];
    this.serviceGroupList = [];

    this.services.forEach((el) => {
      this.serviceGroupName.push(el.name);
    });
    console.log("services ", this);

    this.services.forEach((service) => {
      var currentGroupNumber = amortizationArray.filter((el) => {
        return service.id == el.serviceId;
      }).length;

      this.serviceGroupList.push(currentGroupNumber);
    });
    console.log(
      "This is getSalesDistributionByService ",
      this.serviceGroupList
    );
    this.serviceGroupName.forEach((el) => {
      this.serviceGroupscolorArray.push(
        "rgb(" +
          Math.round(Math.random() * 255) +
          "," +
          Math.round(Math.random() * 255) +
          "," +
          Math.round(Math.random() * 255) +
          ")"
      );
    });
    console.log("color array is here", this.colorArray);
    this.SalesDistributionByServiceGroup = {
      labels: this.serviceGroupName,
      datasets: [
        {
          data: this.serviceGroupList,
          backgroundColor: this.serviceGroupscolorArray,
        },
      ],
    };
  }

  transform(image) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(image);
  }

  showServices(value) {
    console.log("this is the clicked instance", value);
    this.displayService = true;
    this.serviceCategoryName = value.name;
    this.serviceCategoryId = value.id;
    var serviceArray = [];
    this.newServiceArray = [];
    console.log(" these are the id", this.serviceCategoryId);
    serviceArray = this.serviceCategories.filter((x) => {
      return x.id == this.serviceCategoryId;
    })[0].services;
    this.amortizationArray.forEach((amortization) => {
      serviceArray.forEach((service) => {
        if (service.id == amortization.serviceId) {
          this.newServiceArray.push(amortization);
        }
      });
    });

    console.log("the services selected", this.newServiceArray);
  }

  closeService() {
    this.displayService = false;
    this.newServiceArray = [];
    this.serviceCategeriesName = null;
    this.serviceCategoryId = null;
  }

  getSalesDistributionByMarket(amortizationArray) {
    let marketNames = [];
    let marketNumberArray = [];
    let SalesDistributionByMarket = null;
    console.log("markets came here to its method ", this.markets);
    this.markets.forEach((el) => {
      marketNames.push(`${el.division.name} / ${el.name}`);
    });
    console.log("marketsNames ", this.marketNames);
    console.log("marketammortization came heres ", amortizationArray);

    this.markets.forEach((market) => {
      var currentMarketsNumber = amortizationArray.filter((el) => {
        return market.id == el.operatingEntityId;
      }).length;

      marketNumberArray.push(currentMarketsNumber);
    });
    marketNames.forEach((el) => {
      this.marketcolorArray.push(
        "rgb(" +
          Math.round(Math.random() * 255) +
          "," +
          Math.round(Math.random() * 255) +
          "," +
          Math.round(Math.random() * 255) +
          ")"
      );
    });

    SalesDistributionByMarket = {
      labels: marketNames,
      datasets: [
        {
          data: marketNumberArray,
          backgroundColor: this.marketcolorArray,
        },
      ],
    };
    this.SalesDistributionByMarket = SalesDistributionByMarket;
  }

  getTotalSalesByCount(amortizationArray) {
    this.monthListArray = [];
    this.repAmortizationDetails = [];
    this.monthList = [];
    console.log(
      "this is the length under getTotalSalesByCount ",
      amortizationArray.length
    );
    this.monthList = [
      "january",
      "february",
      "march",
      "april",
      "may",
      "june",
      "july",
      "august",
      "september",
      "october",
      "november",
      "december",
    ];
    amortizationArray.forEach((el) => {
      this.repAmortizationDetails.push(...el.repAmortizationDetails);
    });

    this.monthsArray.forEach((month) => {
      var currentLength = [];
      currentLength = this.repAmortizationDetails.filter((el) => {
        return el.month == month.value && el.billableAmount != 0;
      });
      this.monthListArray.push(currentLength.length);
    });
    console.log("this iss the monthListArray value", this.monthListArray);

    this.getTotalSalesCount = {
      labels: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
      datasets: [
        {
          label: "Sales Count",
          data: this.monthListArray,
          fill: false,
          backgroundColor: "#42A5F5",
          borderColor: "#42A5F5",
        },
      ],
    };
  }

  resolveMasterDetailsRelationship(master, details): any[] {
    let newMasterArray = [];
    master.forEach((master) => {
      master.repAmortizationDetails = details.filter((details) => {
        return details.repAmortizationMasterId == master.id;
      });
      newMasterArray.push(master);
    });

    //  console.log("this.amortizationArray got delivered via  resolveMasterDetailsRelationship",this.amortizationArray);
    return newMasterArray;
  }

  fetchRecordsByMonthOrYear() {
    this.workspaceIsLoading = true;

    var currentDate = new Date();
    console.log(
      "What was selected",
      this.selectedYear.year,
      this.selectedMonth.value
    );
    if (this.selectedMonth.value == 0) {
      this.selectedMonth.value = new Date().getMonth();
    }
    if (this.selectedYear.year == 0) {
      this.selectedYear.year = new Date().getFullYear();
    }
    let masterInfo = this.salesdasboardService.getAmortization(
      this.selectedYear.year,
      this.selectedMonth.value
    );
    let detailsInfo = this.salesdasboardService.getAmortizationDetails();
    let customerDivision = this.salesdasboardService.getAllCustomerDivison();
    let division = this.salesdasboardService.getAllDivision();
    let serviceGroups = this.salesdasboardService.getAllServiceGroups();
    let serviceCategories = this.salesdasboardService.getAllServiceCategories();
    let service = this.salesdasboardService.getAllServices();
    let markets = this.salesdasboardService.geMarkets();
    let endorsement = this.salesdasboardService.getEndorsementType();

    forkJoin([
      masterInfo,
      detailsInfo,
      customerDivision,
      division,
      serviceGroups,
      serviceCategories,
      service,
      markets,
      endorsement,
    ]).subscribe((results) => {
      this.customerDivision = this.customerDivisionExtra =
        results[2].responseData;
      this.divisionArray = this.divisionArrayExtra = results[3].responseData;
      this.serviceGroupArray = this.serviceGroupArrayExtra =
        results[4].responseData;
      this.serviceCategories = this.serviceCategoriesExtra =
        results[5].responseData;
      this.services = this.serviceExtra = results[6].responseData;
      this.markets = this.marketsExtra = results[7].responseData;
      this.endorsementType = results[8].responseData;
      this.resultingAmortizationArray = results[0].responseData;
      console.log(
        "::: Gotten from results resultingAmortizationArray  ",
        this.resultingAmortizationArray
      );
      //this.resultingAmortizationArray = this.resolveMasterDetailsRelationship(results[0].responseData,results[1].responseData)
      this.amortizationArray = this.resultingAmortizationArray;
      this.getSalesDistributionByMarket(this.amortizationArray);
      console.log("This array has been resolved", this.amortizationArray);
      //this.newServiceCategory = this.returnAmortizationData(this.amortizationArray);
      this.amortizationTopLength =
        this.amortizationArray.length == 0
          ? 0
          : this.amortizationArray.filter((el) => {
              return (
                el.endorsementTypeId == environment.endorsementType.ServiceTopUp
              );
            }).length;
      this.amortizationNewSalesLength =
        this.amortizationArray.length == 0
          ? 0
          : this.amortizationArray.filter((el) => {
              return el.endorsementTypeId == environment.endorsementType.New;
            }).length;
      this.amortizationArrayExtra = this.resultingAmortizationArray;
      this.recordCount = this.amortizationArray.length;
      this.getSalesDistributionByServiceCategories(this.amortizationArray);
      this.getTotalSalesByCount(this.amortizationArray);
      this.getTotalSalesByValue(this.amortizationArray);
      this.getSalesDistributionByDivision(this.amortizationArray);
      this.getNewSalesMade(this.amortizationArray);
      this.getTotalNewSales(this.amortizationArray);
      this.getTotalSalesMade(this.amortizationArray);
      this.getTotalTopUpSales(this.amortizationArray);
      this.getTotalNumberOfRenewals(this.amortizationArray);
      this.getTotalSumOfRenewals(this.amortizationArray);
      this.getTotalNumberOfReductions(this.amortizationArray);
      this.getTotalSumOfReductions(this.amortizationArray);
      this.workspaceIsLoading = false;
    });
  }
}
