import {Component, DoCheck, ElementRef, OnInit, ViewChild} from '@angular/core';
import {
  DeliverableFulfillment, DeliverableProportion,
  ServiceDivisionDetails,
  ServiceTaskDeliverable,
  TaskFulfillment,
  TaskWidget,
  User
} from '../../interfaces/home';
import {MessageService} from 'primeng/api';
import {DialogService, DynamicDialogRef} from 'primeng/dynamicdialog';
import {TaskFulfillmentService} from '../../services/task-fulfillment.service';
import {FireBaseAuthService} from '../../services/fire-base-auth.service';
import {DeliverableFormComponent} from './deliverable-form/deliverable-form.component';
import {DeliverableFulfillmentService} from '../../services/deliverable-fulfillment.service';
import { ApproveDeliverableComponent } from './approve-deliverable/approve-deliverable.component';
import { BreadcrumbService } from 'src/app/breadcrumb.service';

@Component({
  selector: 'app-project-management',
  templateUrl: './project-management.component.html',
  styleUrls: ['./project-management.component.scss'],
  providers: [MessageService]
})
export class ProjectManagementComponent implements OnInit, DoCheck {
  @ViewChild('deliverableView') public deliverableView:ElementRef;
  @ViewChild('taskView') public taskView:ElementRef;
  tasks: TaskFulfillment[];
  selectedTask: TaskFulfillment;
  authUser: User;
  taskDeliverables: ServiceTaskDeliverable[];
  pickedTask: TaskFulfillment;
  loadingDeliverable = false;
  deliverableCols: any;
  exportDivisionColumns: any;
  assignDialogRef: DynamicDialogRef;
  public selectedDeliverableUser: User | null;
  public taskWidget: TaskWidget | null;
  private taskProportions: any[] | [];
  public pickedTaskById: TaskFulfillment;
  public serviceDivisionDetails: ServiceDivisionDetails;
  public taskOwner: User;
  public TaskProportionData: DeliverableProportion[];
  public loadingTaskProportion: boolean;
  public chartData: any;
  constructor(
      private taskFulfillmentService: TaskFulfillmentService,
      private messageService: MessageService,
      private dialogService: DialogService,
      private firebaseAuthService: FireBaseAuthService,
      private deliverableFulfillmentService: DeliverableFulfillmentService,
      private breadcrumbService: BreadcrumbService
  ) { }

  async ngOnInit() {

    this.breadcrumbService.setItems([
      { label: 'Project Management', routerLink: ['/home/project-management'] },
      { label: 'Task and Deliverable Assignment', routerLink: ['/home/project-management'] }
    ]);

    this.deliverableCols = [
      { field: 'name', header: 'Name' },
      { field: 'missionStatement', header: 'Mission Statement' },
      { field: 'description', header: 'Description' },
      { field: 'head', header: 'Head' }
    ];
    this.exportDivisionColumns = this.deliverableCols.map(col => ({title: col.header, dataKey: col.field}));
    this.authUser = await this.firebaseAuthService.authUserProfile;
    this.taskOwner = this.authUser;
    await this.fetchPendingTask();
    // console.log(this.tasks);
    // this.authUser = await JSON.parse(localStorage.getItem('userProfile'));
    await this.fetchTaskWidget();
    await this.fetchTaskAssignedRatio();
  }
  ngDoCheck(){

  }
  async fetchPendingTask(){
    await this.taskFulfillmentService.getUncompleteTaskByOwner(this.authUser.id).subscribe( async (res: any)=>{
      this.tasks = res;
    });
  }
  async fetchTaskWidget(){
    return this.taskFulfillmentService.getTaskOwnerPmWidget(this.authUser.id).subscribe( res => {
      this.taskWidget = res;
    });
  }
  async fetchTaskFulfilment(task){
    this.loadingDeliverable = true;
    await this.taskFulfillmentService.getTaskFulfillment(task.id).subscribe( async (res: any) => {
      this.taskDeliverables = res.deliverableFulfillments;
      this.serviceDivisionDetails = res.serviceDivisionDetails;
      this.loadingDeliverable = false;
    })
  }
  async pickTask(task: TaskFulfillment){
    this.loadingDeliverable = true;
    this.pickedTask = task;
    await this.deliverableView.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'start' });
    if(task){
      if(!task.isPicked){
        await this.taskFulfillmentService.setIsPicked(this.pickedTask.id).subscribe( async () => {
          await this.fetchPendingTask();
        })
      }
      // fetch the task details
      await this.fetchTaskFulfilment(task);
    }
  }
  async fetchTaskAssignedRatio(){
    this.loadingTaskProportion = true;
    this.deliverableFulfillmentService.getDeliverableProportion(this.authUser.id).subscribe( (res: any) => {
      this.loadingTaskProportion = false;
      this.TaskProportionData = res;
      const _labels = [];
      const _data = [];
      const _color = [];
      const pool = 'ABCDEF0123456789';
      if(this.TaskProportionData.length >= 1){
        this.TaskProportionData.forEach( (tPData, key) => {
          // let crypto = require('crypto');
          // const color = crypto.getRandomValues(pool).toString('hex');
          _labels.push(tPData.user.firstName);
          _data.push(tPData.proportion);
          _color.push(this.generateColor());
        });
      }
      this.chartData = {
        labels: _labels,
        datasets: [
          {
            label: 'Work Load',
            backgroundColor: _color,
            borderColor: '#1E88E5',
            data: _data
          }
        ]
      }
    })
  }
  async assignDeliverable(deliverableFulfillment?: DeliverableFulfillment, type?: string){
    this.assignDialogRef = await this.dialogService.open(DeliverableFormComponent, {
      styleClass: 'px-3 py-4 bg-white',
      showHeader: false,
      width: '71%',
      style: {height: '100%'},
      data: {task: this.pickedTask, deliverableFulfillment, type},
    } );
    this.assignDialogRef.onClose.subscribe( async (res) => {
      if(res === 'complete'){
        await this.pickTask(this.pickedTask);
        await this.fetchTaskAssignedRatio();
      }
    })
  }

  showTaskUser(responsible?: User) {
    if(responsible){
      this.selectedDeliverableUser = responsible;
      return;
    }
    this.selectedDeliverableUser = null;
  }

  async approveDeliverable(deliverableFulfillment?: DeliverableFulfillment, type?: string){
    this.assignDialogRef = await this.dialogService.open(ApproveDeliverableComponent, {
      styleClass: 'px-3 py-4 bg-white',
      showHeader: true,
      header: 'Approve Deliverable Fulfillment',
      style: { width: '40rem', 'max-width': '90vw' },
      data: {task: this.pickedTask, deliverableFulfillment, type},
    } );
    this.assignDialogRef.onClose.subscribe( async (res) => {
      if(res === 'ApproveDeliverableSuccess'){
        await this.fetchTaskFulfilment(this.pickedTask);
        await this.fetchTaskAssignedRatio();
        this.messageService.add({
            severity: 'success',
            summary: 'Deliverable Approved',
            detail: 'Deliverable was approved successfully'
          });
      }else if("ApproveDeliverableFailed"){
        this.messageService.add({
          severity: 'error',
          summary: 'Failed to Approve Deliverable',
          detail: 'Could not approve deliverable. Try again later',
        });
      }
    })
  }

  async dropTask(){
    this.taskFulfillmentService.dropPicked(this.pickedTask.id).subscribe(async () => {
      this.setTaskIsPickedToFalse(this.pickedTask.id);
      this.pickedTask = null;
      this.messageService.add({
        severity: 'success',
        summary: 'Task Fulfillment Dropped',
        detail: 'Task Fulfillment dropped successfully'
      });
      await this.taskView.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'start' });
    }, error => {
      this.messageService.add({
        severity: 'error',
        summary: 'Failed to Drop Task',
        detail: error.error.message,
      });
    })
  }

  setTaskIsPickedToFalse(taskId: number): void{
    this.tasks.forEach(task => {
      if(task.id == taskId){
        task.isPicked = false;
      }
    })
  }

  
  generateRandomNumber(): number{
    return  Math.floor(Math.random() * 256);
  }
  
  generateColor(): string{
    return `rgb(255, ${this.generateRandomNumber()}, ${this.generateRandomNumber()})`;
  }


}
