import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MessageService, ConfirmationService } from "primeng/api";
import { BreadcrumbService } from "src/app/breadcrumb.service";
import { GmaEmploymentService } from "src/app/services/gma-employment.service";
import { State, Lga } from "src/app/interfaces/home";
import {
  DeployedGuard,
  GMAEvent,
  GMAFacilitator,
  GMATrainingFacility,
  ScheduleGMATrainingEventVM,
  UpdateGMAEventVM,
} from "src/app/interfaces/employment";

@Component({
  selector: "app-gma-training-schedule",
  templateUrl: "./gma-training-schedule.component.html",
  styleUrls: ["./gma-training-schedule.component.scss"],
})
export class GmaTrainingScheduleComponent implements OnInit {
  @ViewChild("formWrapper") public formWrapper: ElementRef;
  eventForm: FormGroup;
  monthTrainingScheduleCount: number = 0;
  quaterTrainingScheduleCount: number = 0;
  yearTrainingScheduleCount: number = 0;
  notDueTrainingScheduleCount: number = 0;
  events: {
    id: number;
    title: string;
    start: string;
    end?: string;
    url?: string;
  }[];
  options: any;
  header: any;
  eventDialog: boolean;
  changedEvent: any;
  clickedEvent = null;
  openScheduleEventDiaogue: boolean = false;
  allStates: State[];
  theState: State;
  allLgas: Lga[];
  theLga: Lga;
  todayDateString: string;
  eventScheduleStart: Date;
  eventScheduleStop: Date;
  allFacilitators: GMAFacilitator[];
  allSelectedFacilitators: GMAFacilitator[];
  allOperatives: DeployedGuard[] = [];
  allSelectedOperatives: DeployedGuard[] = [];
  allEvents: GMAEvent[];
  clickedGMAEvent: GMAEvent;
  allTrainingFacilities: GMATrainingFacility[];
  theTrainingFacility: GMATrainingFacility;

  constructor(
    fb: FormBuilder,
    public messageService: MessageService,
    public employmentService: GmaEmploymentService,
    private breadcrumbService: BreadcrumbService,
    public confirmationService: ConfirmationService
  ) {
    this.eventForm = fb.group({
      Caption: ["", Validators.required],
      // State: ["", Validators.required],
      // LGA: ["", Validators.required],
      // Street: ["", Validators.required],
      Location: ["", Validators.required],
      StartDate: ["", Validators.required],
      StopDate: ["", Validators.required],
      Operatives: ["", Validators.required],
      Facilitators: ["", Validators.required],
    });
  }

  ngOnInit(): void {
    this.breadcrumbService.setItems([
      {
        label: "Guard Management",
        routerLink: ["/home/guard-management/item-settings"],
      },
      {
        label: "Training Schedule",
        routerLink: ["/home/guard-management/training-schedule"],
      },
    ]);

    this.events = [
      {
        id: 1,
        title: "All Day Event",
        start: "2022-02-01",
      },
      {
        id: 2,
        title: "Long Event",
        start: "2017-02-07",
        end: "2022-02-10",
      },
      {
        id: 3,
        title: "Repeating Event",
        start: "2022-02-09T16:00:00",
      },
      {
        id: 4,
        title: "Repeating Event",
        start: "2022-02-16T16:00:00",
      },
      {
        id: 5,
        title: "Conference",
        start: "2022-02-11",
        end: "2022-02-13",
      },
      {
        id: 6,
        title: "Meeting",
        start: "2022-02-12T10:30:00",
        end: "2022-02-12T12:30:00",
      },
      {
        id: 7,
        title: "Lunch",
        start: "2022-02-12T12:00:00",
      },
      {
        id: 8,
        title: "Meeting",
        start: "2022-02-12T14:30:00",
      },
      {
        id: 9,
        title: "Happy Hour",
        start: "2022-02-12T17:30:00",
      },
      {
        id: 10,
        title: "Dinner",
        start: "2022-02-12T20:00:00",
      },
      {
        id: 11,
        title: "Birthday Party",
        start: "2022-02-13T07:00:00",
      },
      {
        id: 12,
        title: "Click for Google",
        url: "http://google.com/",
        start: "2022-02-28",
      },
    ];

    this.setup();
    this.FetchAllScheduledEvents();
    this.FetchAllStates();
    this.GetAllTrainingFacilities();
    this.GetScheduleEventListData();
  }

  FetchAllScheduledEvents() {
    this.employmentService.GetAllScheduledTrainingEvents().subscribe(
      async (data) => {
        this.allEvents = data;
        this.events = [];
        data.forEach((scheduledEvent) => {
          this.events.push({
            id: scheduledEvent.id,
            title: scheduledEvent.caption,
            start: scheduledEvent.startDate.toString(),
            end: scheduledEvent.stopDate.toString(),
          });
        });
        this.setup();
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all events at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  GetScheduleEventListData() {
    this.employmentService.GetScheduleEventUsersList().subscribe(
      async (data) => {
        this.allFacilitators = data.facilitators;
        this.allOperatives = data.operatives;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all list data at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  GetAllTrainingFacilities() {
    this.employmentService.GetAllTrainingFacilitiesOnly().subscribe(
      async (data) => {
        this.allTrainingFacilities = data;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all training facilities at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  setup() {
    //Set minimum date
    var today = new Date();
    var dd = today.getDate();
    var mm = today.getMonth() + 1; //January is 0!
    var strMM = mm + "";
    if (mm < 10) strMM = "0" + mm;
    var yyyy = today.getFullYear() - 18;
    this.todayDateString = today.getFullYear() + "-" + strMM + "-" + dd;

    this.changedEvent = { title: "", start: null, end: "", allDay: null };
    this.options = {
      plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin],
      defaultDate: today.getFullYear() + "-" + strMM + "-" + dd,
      header: {
        left: "prev,next",
        center: "title",
        right: "dayGridMonth,timeGridWeek,timeGridDay",
      },
      editable: true,
      eventClick: (e) => {
        this.eventDialog = true;
        this.clickedEvent = e.event;
        this.changedEvent.id = this.clickedEvent.id;
        this.changedEvent.title = this.clickedEvent.title;
        this.changedEvent.start = this.clickedEvent.start;
        this.changedEvent.end = this.clickedEvent.end;

        this.clickedGMAEvent = this.allEvents.find(
          (x) => x.id == this.clickedEvent.id
        );

        if (this.clickedGMAEvent) {
          this.theTrainingFacility = this.allTrainingFacilities.find(
            (x) => x.id == this.clickedGMAEvent.trainingFacilityId
          );

          let allEventFacilitators = this.clickedGMAEvent.facilitators.map(
            (x) => x.gmaFacilitatorId
          );
          this.allSelectedFacilitators = this.allFacilitators.filter(
            (x) => allEventFacilitators.find((y) => y == x.id) != null
          );

          this.allSelectedOperatives = this.allOperatives.filter((x) =>
            this.clickedGMAEvent.operatives.find(
              (y) => y.deployedGuardId == x.id
            )
          );
        }
      },
    };
  }

  UpdateScheduleEvent(withNotification: boolean) {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Updating GMA Event...",
    });

    this.clickedEvent.setProp("title", this.changedEvent.title);
    this.clickedEvent.setStart(this.changedEvent.start);
    this.clickedEvent.setEnd(this.changedEvent.end);
    //this.clickedEvent.setAllDay(this.changedEvent.allDay);

    let id = this.changedEvent.id;
    //Update API
    const postData: UpdateGMAEventVM = {
      caption: this.changedEvent.title,
      traningFacilityId: this.theTrainingFacility.id,
      startDate: this.changedEvent.start,
      stopDate: this.changedEvent.end,
      facilitatorIds: this.allSelectedFacilitators.map((x) => x.id),
      operativeIds: this.allSelectedOperatives.map((x) => x.id),
      sendNotifications: withNotification,
    };
    this.changedEvent = { title: "", start: null, end: "", allDay: null };

    this.employmentService.UpdateGMAEvent(id, postData).subscribe(
      async () => {
        this.messageService.add({
          severity: "success",
          summary: "Completed",
          detail: "GMA Event Updated Successfully!",
        });

        this.theTrainingFacility = null;
        this.allSelectedOperatives = [];
        this.allSelectedFacilitators = [];
        this.clickedGMAEvent.caption = postData.caption;
        this.clickedGMAEvent.trainingFacilityId = postData.traningFacilityId;
        this.clickedGMAEvent.startDate = postData.startDate;
        this.clickedGMAEvent.stopDate = postData.stopDate;
        this.clickedGMAEvent = null;
        this.FetchAllScheduledEvents();
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to update schedued event at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );

    this.eventDialog = false;
  }

  reset() {
    this.changedEvent.title = this.clickedEvent.title;
    this.changedEvent.start = this.clickedEvent.start;
    this.changedEvent.end = this.clickedEvent.end;
    this.theTrainingFacility = null;
    this.allSelectedFacilitators = [];
    this.allSelectedOperatives = [];
    this.clickedGMAEvent = null;
    this.eventDialog = false;
  }

  OpenScheduleDialogue() {
    this.openScheduleEventDiaogue = true;
  }

  HideScheduleDialog() {
    this.openScheduleEventDiaogue = false;

    this.eventForm.reset();
    this.theLga = null;
    this.theState = null;
    this.theTrainingFacility = null;
  }

  ScheduleEvent() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Scheduling GMA Event...",
    });

    const postData: ScheduleGMATrainingEventVM = {
      caption: this.eventForm.get("Caption").value,
      // lGAId: this.theLga.id,
      // street: this.eventForm.get("Street").value,
      traningFacilityId: this.theTrainingFacility.id,
      startDate: this.eventScheduleStart,
      stopDate: this.eventScheduleStop,
      facilitatorIds: this.allSelectedFacilitators.map((x) => x.id),
      operativeIds: this.allSelectedOperatives.map((x) => x.id),
    };

    this.employmentService.ScheduleGMATrainingEvent(postData).subscribe(
      async () => {
        this.messageService.add({
          severity: "success",
          summary: "Completed",
          detail: "GMA Event Scheduled Successfully!",
        });

        this.eventForm.reset();
        this.theLga = null;
        this.theState = null;
        this.theTrainingFacility = null;
        this.HideScheduleDialog();
        this.FetchAllScheduledEvents();
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to schedule GMA Event at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  LoadUpStateLGAs() {
    if (this.theState) {
      this.allLgas = this.theState.lgas;
    } else {
      this.allLgas = [];
      this.theLga = null;
    }
  }

  FetchAllStates() {
    this.employmentService.GetAllStates().subscribe(
      async (data) => {
        this.allStates = data;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all states at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  ScheduleEventWithAlert() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Scheduling GMA Event...",
    });

    const postData: ScheduleGMATrainingEventVM = {
      caption: this.eventForm.get("Caption").value,
      // lGAId: this.theLga.id,
      // street: this.eventForm.get("Street").value,
      traningFacilityId: this.theTrainingFacility.id,
      startDate: this.eventScheduleStart,
      stopDate: this.eventScheduleStop,
      facilitatorIds: this.allSelectedFacilitators.map((x) => x.id),
      operativeIds: this.allSelectedOperatives.map((x) => x.id),
      sendNotifications: true,
    };

    this.employmentService.ScheduleGMATrainingEvent(postData).subscribe(
      async () => {
        this.messageService.add({
          severity: "success",
          summary: "Completed",
          detail: "GMA Event Scheduled Successfully!",
        });

        this.eventForm.reset();
        this.theLga = null;
        this.theState = null;
        this.theTrainingFacility = null;
        this.HideScheduleDialog();
        this.FetchAllScheduledEvents();
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to schedule GMA Event at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  get EventScheduleStartDate() {
    return this.eventForm.get("StartDate").value;
  }
}
