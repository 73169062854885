import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MessageService, ConfirmationService } from "primeng/api";
import { BreadcrumbService } from "src/app/breadcrumb.service";
import {
  ApprovalLevel,
  ApprovalLevelSequence,
  CreateApprovingLevelSequenceVM,
  EndorsementType,
} from "src/app/interfaces/home";
import { ApprovalLevelService } from "src/app/services/approval-level.service";
import { EndorsementTypeService } from "src/app/services/endorsement-type.service";

@Component({
  selector: "app-approval-sequence",
  templateUrl: "./approval-sequence.component.html",
  styleUrls: ["./approval-sequence.component.scss"],
})
export class ApprovalSequenceComponent implements OnInit {
  @ViewChild("formWrapper") public formWrapper: ElementRef;
  approvalSequenceForm: FormGroup;
  allApprovalLevels: ApprovalLevel[];
  theApprovalLevel: ApprovalLevel;
  allEndorsementTypes: EndorsementType[];
  theEndorsementType: EndorsementType;
  isEditing: boolean;
  allApprovalSequenceList: any[] = [];
  fetchingApprovalSequences: boolean;
  allApprovalLevelSequences: {
    EndorsementType: string;
    SequencesConfig: ApprovalLevelSequence[];
  }[] = [];
  approvalLevelSequenceCols: any[];

  constructor(
    fb: FormBuilder,
    private endorsementTypeService: EndorsementTypeService,
    private approvalLevelService: ApprovalLevelService,
    public messageService: MessageService,
    private breadcrumbService: BreadcrumbService,
    public confirmationService: ConfirmationService
  ) {
    this.approvalSequenceForm = fb.group({
      EndorsmentType: ["", Validators.required],
      ApproverLevel: ["", Validators.required],
    });

    this.approvalLevelSequenceCols = [
      { field: "level", header: "Approver Level" },
    ];
  }

  ngOnInit(): void {
    this.breadcrumbService.setItems([
      {
        label: "Approval",
      },
      {
        label: "Approval Sequence",
        routerLink: ["/home/approval-sequence"],
      },
    ]);

    this.FetchAllEndorsementTypes();
    this.FetchAllApprovalLevels();
    this.FetchAllApprovalLevelSequences();
  }

  async FetchAllEndorsementTypes() {
    this.endorsementTypeService.allEndorsementTypeData().subscribe(
      async (data) => {
        if (data.responseCode != "00") {
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail: data["responseMsg"],
          });
          return;
        }

        this.allEndorsementTypes = data.responseData ?? [];
        this.allEndorsementTypes.push({
          caption: "Service Creation",
          openingDate: null,
          closingDate: null,
        });
        this.allEndorsementTypes.push({
          caption: "Addition Contract Creation",
          openingDate: null,
          closingDate: null,
        });
        this.allEndorsementTypes.push({
          caption: "Contract Creation",
          openingDate: null,
          closingDate: null,
        });
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all endorsement types at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  async FetchAllApprovalLevels() {
    this.approvalLevelService.allApprovalLevelData().subscribe(
      async (data) => {
        if (data.responseCode != "00") {
          this.messageService.add({
            severity: "error",
            summary: data.responseMsg,
          });
          return;
        }

        this.allApprovalLevels = data.responseData;
        this.allApprovalLevels.push({
          id: 0,
          caption: "Non-Principal",
          alias: "NP",
          description: "Non Principal Approver Level",
        });
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all approval levels at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  async FetchAllApprovalLevelSequences() {
    this.fetchingApprovalSequences = true;
    this.allApprovalLevelSequences = [];
    this.approvalLevelService.GetAllApprovingLevelSequences().subscribe(
      async (data) => {
        if (data.responseCode != "00") {
          this.messageService.add({
            severity: "error",
            summary: data.responseMsg,
          });
          this.fetchingApprovalSequences = false;
          return;
        }

        let groupedByEndorsementType = [
          ...new Set(data.responseData.map((config) => config.typeName)),
        ];
        groupedByEndorsementType.forEach((endorsementType) => {
          this.allApprovalLevelSequences.push({
            EndorsementType: endorsementType,
            SequencesConfig: data.responseData
              .filter((x) => x.typeName == endorsementType)
              .sort((x) => x.sequence),
          });
        });
        this.fetchingApprovalSequences = false;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all approval level sequences at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
        this.fetchingApprovalSequences = false;
      }
    );
  }

  CreateApprovalSequence() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Creating Approval Level Sequence...",
    });

    const postData: CreateApprovingLevelSequenceVM = {
      endorsementTypeId: this.theEndorsementType?.id,
      approvalLevelId: this.theApprovalLevel.id,
      typeName: this.theEndorsementType.caption,
    };

    this.approvalLevelService.CreateApprovingLevelSequence(postData).subscribe(
      async (data) => {
        if (data.responseCode != "00") {
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail: data.responseMsg,
          });
          return;
        }

        this.messageService.add({
          severity: "success",
          summary: "Completed",
          detail: "Approval Level Sequence Created Successfully...",
        });
        this.approvalSequenceForm.reset();
        this.theApprovalLevel = null;
        this.theEndorsementType = null;
        this.FetchAllApprovalLevelSequences();
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to create approval level sequence at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  AddLevelToSequenceList() {}

  CloseEditingApprovalSequence() {}

  UpdateApprovalSequence() {}

  DeleteApprovalSequence(item: ApprovalLevelSequence) {
    this.confirmationService.confirm({
      message:
        "Are you sure you want to remove approval level sequence. It is an irreversible action. Do you still wish to prceed?",
      accept: () => {
        this.messageService.add({
          severity: "info",
          summary: "Notice",
          detail: "Removing approval level sequence...",
        });

        this.approvalLevelService
          .DeleteApprovingLevelSequence(item.id)
          .subscribe(
            async (data) => {
              if (data.responseCode != "00") {
                this.messageService.add({
                  severity: "error",
                  summary: "Notice",
                  detail: data.responseMsg,
                });
                return;
              }

              this.messageService.add({
                severity: "success",
                summary: "Completed",
                detail: "Approval Level Sequence Removed Successfully...",
              });
              this.FetchAllApprovalLevelSequences();
            },
            (error) => {
              console.log("Error: " + JSON.stringify(error));
              this.messageService.add({
                severity: "error",
                summary: "Notice",
                detail:
                  "Unable to remove approval level sequence at the moment.. Reason: [" +
                  error
                    ? error.error.message
                    : "request failed - permission" + "]",
              });
            }
          );
      },
    });
  }

  EditApprovalSequence(item: ApprovalLevelSequence) {}

  MoveLevelSequence(isUpward: boolean, item: ApprovalLevelSequence) {
    this.confirmationService.confirm({
      message: "Are you sure you want to change approval level sequence?",
      accept: () => {
        this.messageService.add({
          severity: "info",
          summary: "Notice",
          detail: "Updating apprval level sequence...",
        });

        this.approvalLevelService
          .UpdateApprovalLevelSequence(item.id, isUpward)
          .subscribe(
            async (data) => {
              if (data.responseCode != "00") {
                this.messageService.add({
                  severity: "error",
                  summary: "Notice",
                  detail: data.responseMsg,
                });
                return;
              }

              this.messageService.add({
                severity: "success",
                summary: "Completed",
                detail: "Update Successfull!...",
              });
              this.FetchAllApprovalLevelSequences();
            },
            (error) => {
              console.log("Error: " + JSON.stringify(error));
              this.messageService.add({
                severity: "error",
                summary: "Notice",
                detail:
                  "Unable to update approval level sequence at the moment.. Reason: [" +
                  (error
                    ? error.error.message
                    : "request failed - permission") +
                  "]",
              });
            }
          );
      },
    });
  }

  GetApprovalLevelCaption(
    approvalLevelId: number,
    isNonPrinicipalLevel: boolean
  ): string {
    let approvalLevel = this.allApprovalLevels.find(
      (x) => x.id == approvalLevelId
    );
    if (approvalLevel) return approvalLevel.caption;

    if (isNonPrinicipalLevel) return "Non-Principal";
    return "N/A";
  }
}
