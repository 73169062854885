import { Component, OnInit, ViewChild } from "@angular/core";
import { FormGroup, FormBuilder } from "@angular/forms";
import { MessageService, ConfirmationService } from "primeng/api";
import { UIChart } from "primeng/chart";
import { BreadcrumbService } from "src/app/breadcrumb.service";
import { RepAmortizationMaster } from "src/app/interfaces/client-merge";
import { BarChartDataFormat } from "src/app/interfaces/convergence";
import {
  Branch,
  CustomerDivision,
  Division,
  Services,
} from "src/app/interfaces/home";
import {
  BusinessOverviewActivityView,
  GetBusinessOverviewDashboardReportVM,
  GetBusinessOverviewDashboardReportView,
  GetLoginDashboardReportVM,
  GetLoginDashboardReportView,
} from "src/app/interfaces/reporting";
import { BranchService } from "src/app/services/branch.service";
import { CustomerDivisionService } from "src/app/services/customer-division.service";
import { DivisionService } from "src/app/services/division.service";
import { ReportingService } from "src/app/services/reporting.service";
import { ServicesService } from "src/app/services/services.service";
import { UtilService } from "src/app/services/util.service";

@Component({
  selector: "app-login-dashboard",
  templateUrl: "./login-dashboard.component.html",
  styleUrls: ["./login-dashboard.component.scss"],
})
export class LoginDashboardComponent implements OnInit {
  @ViewChild("chart1") chart1: UIChart;
  @ViewChild("chart2") chart2: UIChart;
  @ViewChild("chart3") chart3: UIChart;
  @ViewChild("chart4") chart4: UIChart;
  filterForm: FormGroup;
  allDashboardOptions: { key: number; value: string }[] = [];
  theDashboard: { key: number; value: string };
  allDivisions: Division[];
  theDivision: Division;
  theBODivision: Division;
  allYearOptions: { key: number; value: string }[] = [];
  theYearOption: { key: number; value: string };
  allMonthOptions: { key: number; value: string }[] = [];
  theMonthOption: { key: number; value: string };
  allValueOptions: { key: number; value: string }[] = [];
  theValueOption: { key: number; value: string };
  allRegions: Branch[];
  theRegion: Branch;
  theBORegion: Branch;
  preloadLineChartData: BarChartDataFormat;
  dashboardData: GetLoginDashboardReportView;
  isLoadingDashboardData: boolean;
  isLoadingBODashboardData: boolean;
  dateFrom: Date;
  dateTo: Date;
  businessOverviewDashboardData: GetBusinessOverviewDashboardReportView;
  businessOverviewDashboardBackgrounData: GetBusinessOverviewDashboardReportView;
  startDateString: string;
  currentDateString: string;
  isPerformedOneOff: number = 1;
  openViewDialogue: boolean;
  viewDialogueTitle: string;
  viewDialogue: RepAmortizationMaster[];
  viewCols: any[];
  allClients: CustomerDivision[] = [];
  allServices: Services[] = [];
  opportunityGenRate_Percent: number;
  opportunityGenRate_Degree: number;
  leadConversionRate_Percent: number;
  leadConversionRate_Degree: number;

  constructor(
    private fb: FormBuilder,
    private clientService: CustomerDivisionService,
    private branchService: BranchService,
    private servicesService: ServicesService,
    public utilService: UtilService,
    private reportingService: ReportingService,
    private divisionService: DivisionService,
    public messageService: MessageService,
    private breadcrumbService: BreadcrumbService,
    public confirmationService: ConfirmationService
  ) {
    this.filterForm = fb.group({
      Division: [""],
      Region: [""],
      DateFrom: [""],
      DateTo: [""],
    });
  }

  ngOnInit(): void {
    this.breadcrumbService.setItems([
      {
        label: "Login Dashboard",
        routerLink: ["/home/login-dashboard"],
      },
    ]);

    this.allDashboardOptions = [
      {
        key: 1,
        value: "Production Dashboard",
      },
      // {
      //   key: 2,
      //   value: "Management Dashboard",
      // },
      // {
      //   key: 3,
      //   value: "Inventory Dashboard",
      // },
      {
        key: 5,
        value: "Business Overview",
      },
      {
        key: 4,
        value: "Production Vs Budget Dashboard",
      },
    ];
    this.theDashboard = this.allDashboardOptions[1];

    this.preloadLineChartData = {
      labels: [],
      datasets: [],
    };

    this.allValueOptions = [
      {
        key: 2,
        value: "Gross Billing (Amount + Vat)",
      },
      {
        key: 1,
        value: "Gross Billing (Amount Only)",
      },
    ];
    this.theValueOption = this.allValueOptions[0];

    this.dateTo = new Date(Date.now());
    this.dateFrom = new Date(Date.now());
    console.log(this.dateFrom.toISOString());
    let year = this.dateFrom.toISOString().slice(0, 4);
    let month = this.dateFrom.toISOString().slice(5, 7);
    let day = this.dateFrom.toISOString().slice(8, 10);
    this.currentDateString = year + "-" + month + "-" + day;
    this.dateFrom.setDate(1);
    day = this.dateFrom.toISOString().slice(8, 10);
    this.startDateString = year + "-" + month + "-" + day;
    console.log(this.currentDateString);

    this.viewCols = [
      { field: "id", header: "ID" },
      { field: "year", header: "Year" },
      { field: "clientName", header: "Client Name" },
    ];

    this.FetchAllClients();
    this.FetchAllServices();
    this.GetAllRegions();
    this.GetAllDivisions();
    this.PopulateYearsDDL();
    this.PopulateMonthsDDL();
    this.RefreshDashboardData();
    this.RefreshBusinessOverviewDashboardData();
    this.RunBusinessOverviewDashboardBackgroundData()
  }

  async GetAllDivisions() {
    this.divisionService.allDivisionData().subscribe(
      async (data) => {
        this.allDivisions = data.responseData;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all divsions at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  async GetAllRegions() {
    this.branchService.allBranch().subscribe(
      async (data) => {
        this.allRegions = data.responseData;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all regions at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  async FetchAllClients() {
    this.clientService.allCustomerDivision().subscribe(
      async (data) => {
        this.allClients = data.responseData;
      },
      (error) => {
        this.messageService.add({
          severity: "error",
          summary: "Failed",
          detail: error ?? "Some errors occurred",
        });
      }
    );
  }

  async FetchAllServices() {
    this.servicesService.allService().subscribe(
      async (data) => {
        this.allServices = data.responseData;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all services at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  PopulateYearsDDL() {
    this.allYearOptions = [];
    let currentYear = new Date(Date.now()).getFullYear();
    for (let i = 5; i > 0; i--) {
      this.allYearOptions.push({
        key: currentYear - i,
        value: currentYear - i + "",
      });
    }
    this.allYearOptions.push({
      key: currentYear,
      value: currentYear + "",
    });
    for (let i = 1; i <= 5; i++) {
      this.allYearOptions.push({
        key: currentYear + i,
        value: currentYear + i + "",
      });
    }
    this.theYearOption = this.allYearOptions[5];
  }

  PopulateMonthsDDL() {
    this.allMonthOptions = [];
    let currentMonth = new Date(Date.now()).getMonth();
    for (let i = 1; i <= 12; i++) {
      this.allMonthOptions.push({
        key: i,
        value: this.utilService.GetMonthLabel(i),
      });
    }
    this.theMonthOption = this.allMonthOptions.find(
      (x) => x.key == currentMonth + 1
    );
  }

  OnDashboardChange() {}

  async RefreshDashboardData() {
    this.isLoadingDashboardData = true;
    const postData: GetLoginDashboardReportVM = {
      divisionId: this.theDivision?.id,
      year: this.theYearOption?.key,
      month: this.theMonthOption?.key,
      valueOption: this.theValueOption?.key,
      branchId: this.theRegion?.id,
    };

    if (this.theValueOption == null) {
      postData.valueOption = 2;
    }
    if (this.theMonthOption == null) {
      let currentMonth = new Date(Date.now()).getMonth() + 1;
      postData.month = currentMonth;
    }
    this.reportingService.GetLoginDashboardReport(postData).subscribe(
      async (data) => {
        if (data.responseCode != "00") {
          this.messageService.add({
            severity: "error",
            summary: data.responseMsg,
          });
          this.isLoadingDashboardData = false;
          return;
        }

        this.dashboardData = data.responseData;
        if (this.theYearOption == null)
          this.theYearOption = this.allYearOptions.find(
            (x) => x.key == new Date(Date.now()).getFullYear()
          );
        if (this.theValueOption == null)
          this.theValueOption = this.allValueOptions.find((x) => x.key == 2);
        if (this.theMonthOption == null)
          this.theMonthOption = this.allMonthOptions.find(
            (x) => x.key == new Date(Date.now()).getMonth() + 1
          );
        this.isLoadingDashboardData = false;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to refresh dashboard data at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
        this.isLoadingDashboardData = false;
      }
    );
  }

  async RefreshBusinessOverviewDashboardData() {
    if (!this.dateFrom || !this.dateTo) {
      this.messageService.add({
        severity: "error",
        summary: "Date From & Date To (Date Range) Is Required",
      });
      this.isLoadingBODashboardData = false;
      return;
    }

    if (this.dateFrom > this.dateTo) {
      this.messageService.add({
        severity: "error",
        summary: "Date To Must Be Greater Than Date From",
      });
      this.isLoadingBODashboardData = false;
      return;
    }

    this.isLoadingBODashboardData = true;
    const postData: GetBusinessOverviewDashboardReportVM = {
      divisionId: this.theDivision?.id,
      branchId: this.theRegion?.id,
      dateFrom: this.dateFrom,
      dateTo: this.dateTo,
    };

    this.reportingService
      .GetBusinessOverviewDashboardReport(postData)
      .subscribe(
        async (data) => {
          if (data.responseCode != "00") {
            this.messageService.add({
              severity: "error",
              summary: data.responseMsg,
            });
            this.isLoadingBODashboardData = false;
            await new Promise((resolve) => setTimeout(resolve, 2000)); // 2 secs
            this.CalculateCircularProgressValues(0, 0);
            if (this.isPerformedOneOff >= 0) {
              if (this.isPerformedOneOff == 0) {
                this.currentDateString = null;
                this.dateTo = null;
              }
              this.isPerformedOneOff--;
            }
            return;
          }

          this.businessOverviewDashboardData = data.responseData;
          this.isLoadingBODashboardData = false;
          await new Promise((resolve) => setTimeout(resolve, 2000)); // 2 secs
          this.CalculateCircularProgressValues(
            data.responseData.totalOpportunities,
            data.responseData.totalLeadsConverted
          );
          if (this.isPerformedOneOff >= 0) {
            if (this.isPerformedOneOff == 0) {
              this.currentDateString = null;
            }
            this.isPerformedOneOff--;
          }
          this.PopulateClientNames();
        },
        async (error) => {
          console.log("Error: " + JSON.stringify(error));
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail:
              "Unable to refresh business overview dashboard data at the moment.. Reason: [" +
              (error ? error.error.message : "request failed - permission") +
              "]",
          });
          this.isLoadingDashboardData = false;
          await new Promise((resolve) => setTimeout(resolve, 2000)); // 2 secs
          this.CalculateCircularProgressValues(0, 0);
        }
      );
  }

  async RunBusinessOverviewDashboardBackgroundData(){
    const postData: GetBusinessOverviewDashboardReportVM = {
      divisionId: this.theDivision?.id,
      branchId: this.theRegion?.id,
      dateFrom: this.dateFrom,
      dateTo: this.dateTo,
    };

    this.reportingService
    .GetBusinessOverviewDashboardReportBackgroundData(postData)
    .subscribe(
      async (data) => {
        if (data.responseCode != "00") {
          this.messageService.add({
            severity: "error",
            summary: data.responseMsg  + " - Background Task Failed",
          });
          return;
        }

        this.businessOverviewDashboardBackgrounData = data.responseData;
      },
      async (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to refresh business overview dashboard background data at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  DisplayActivities(item: BusinessOverviewActivityView, title?: string) {
    this.openViewDialogue = true;
    this.viewDialogueTitle = title;
    this.viewDialogue = item.dataView;
  }

  CloseViewDialogue() {
    this.openViewDialogue = false;
    this.viewDialogue = [];
  }

  FormatDateString(date: Date): string {
    let dateString = date.toString();

    try {
      // DateTimeFormatOptions
      const date: Date = new Date(dateString);
      const options: Intl.DateTimeFormatOptions = {
        year: "numeric",
        month: "long",
        day: "numeric",
      };
      const formattedDate: string = new Intl.DateTimeFormat(
        "en-US",
        options
      ).format(date);
      dateString = formattedDate;
    } catch (error) {
      console.log(error);
      console.log(date);
      console.log(
        "Error while converting date string " + date + " exception " + error
      );
    }

    return dateString;
  }

  async PopulateClientNames() {
    this.businessOverviewDashboardData.businessExpansionActivities.dataView.forEach(
      (x) => (x.clientName = this.GetClientName(x.clientId))
    );

    this.businessOverviewDashboardData.retentionActivities.dataView.forEach(
      (x) => (x.clientName = this.GetClientName(x.clientId))
    );

    this.businessOverviewDashboardData.topupActivities.dataView.forEach(
      (x) => (x.clientName = this.GetClientName(x.clientId))
    );

    this.businessOverviewDashboardData.reductionActivities.dataView.forEach(
      (x) => (x.clientName = this.GetClientName(x.clientId))
    );
  }

  GetClientName(clientId: number): string {
    let client = this.allClients.find((x) => x.id == clientId);
    if (client) return client.divisionName;

    return "N/A";
  }

  GetServiceName(serviceId: number): string {
    let service = this.allServices.find((x) => x.id == serviceId);
    if (service) return service.name;

    return "N/A - " + serviceId;
  }

  async CalculateCircularProgressValues(
    opportunityGenRate: number,
    leadConversionRate: number
  ) {
    this.opportunityGenRate_Percent = opportunityGenRate;
    this.opportunityGenRate_Degree = 3.6 * opportunityGenRate;
    let opValue = this.opportunityGenRate_Degree / 2;
    document.getElementById("OPFill1").style.transform =
      "rotate(" + opValue + "deg)";
    document.getElementById("OPFill2").style.transform =
      "rotate(" + opValue + "deg)";
    document.getElementById("OPFill3").style.transform =
      "rotate(" + opValue + "deg)";

    this.leadConversionRate_Percent = leadConversionRate;
    this.leadConversionRate_Degree = 3.6 * leadConversionRate;
    let lcValue = this.leadConversionRate_Degree / 2;
    document.getElementById("LCFill1").style.transform =
      "rotate(" + lcValue + "deg)";
    document.getElementById("LCFill2").style.transform =
      "rotate(" + lcValue + "deg)";
    document.getElementById("LCFill3").style.transform =
      "rotate(" + lcValue + "deg)";
  }
}
