import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { AngularFireStorage } from "@angular/fire/storage";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ConfirmationService, MessageService, TreeNode } from "primeng/api";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { BreadcrumbService } from "src/app/breadcrumb.service";
import {
  AllSMORoutesAndRegion,
  PriceRegister,
  ServiceRegistration,
  SMORegion,
  SMORoute,
} from "src/app/interfaces/armada";
import { CommonResponse } from "src/app/interfaces/home";
import { PriceRegisterService } from "src/app/services/armada/price-register.service";
import { ServiceRegistrationService } from "src/app/services/armada/service-registration.service";
import { SMORouteService } from "src/app/services/armada/smoroute.service";
import { InventoryService } from "src/app/services/inventory.service";

@Component({
  selector: "app-price-register",
  templateUrl: "./price-register.component.html",
  styleUrls: ["./price-register.component.scss"],
})
export class PriceRegisterComponent implements OnInit, OnDestroy {
  @ViewChild("formWrapper") public formWrapper: ElementRef;
  private unsubscriber$ = new Subject<void>();
  priceRegisterTree: TreeNode[];
  // selectedStore: CentralStore | RegionalStore | number;
  selectedStore: any;
  loadingPriceRegisters: boolean = true;
  cantCreateRouteUnder: boolean;
  public targetCols: any;
  private exportTargetColumns: any;
  public submittingData: boolean;
  public loading: boolean;
  public submitButtonLabel: string;
  public priceRegisters: PriceRegister[];
  public allSMORoutesAndRegion: AllSMORoutesAndRegion;
  public edPriceRegister: PriceRegister;
  public priceRegisterForm: FormGroup;
  public selectedPriceRegister: PriceRegister = null;
  editingPriceRegister = false;
  userForm: FormGroup;

  public serviceRegistrations: ServiceRegistration[];
  selectedserviceRegistration: ServiceRegistration;

  public smoRoutes: SMORoute[];
  selectedSMORoutes: SMORoute;

  public smoRegions: SMORegion[];
  selectedSMORegions: SMORegion;

  markupPercentage: number;
  costPrice: number;
  sellingPrice: number;
  markupPrice: number;
  files1: TreeNode[];
  selectedFiles3: TreeNode;
  routeDetailTable: {
    //name: string;
    data: string;
  }[];
  selectedrouteDetailTable: {
    //name: string;
    data: string;
  };
  centralStoreName: any;
  files3: TreeNode[];

  //public services: Services[];

  constructor(
    public messageService: MessageService,
    public confirmationService: ConfirmationService,
    public fireStorage: AngularFireStorage,
    public inventoryService: InventoryService,
    private formBuilder: FormBuilder,
    private breadcrumbService: BreadcrumbService,

    private priceRegisterService: PriceRegisterService,
    private serviceRegistrationService: ServiceRegistrationService,
    private sMORouteService: SMORouteService
  ) {
    this.breadcrumbService.setItems([
      { label: "Setup" },
      {
        label: "Price Register",
        routerLink: ["/setup/price-register"],
      },
    ]);
  }

  ngOnInit(): void {
    // this.sMORouteService.getFiles().then((files) => (this.files1 = files));
    // //this.nodeService.getFilesystem().then(files => this.files2 = files);
    // this.sMORouteService.getFiles().then((files) => {
    //   this.files1 = [
    //     {
    //       label: "Root",
    //       children: files,
    //     },
    //   ];
    // });
    this.targetCols = [
      { field: "serviceRegistrationId", header: "serviceRegistrationId" },
      { field: "costPrice", header: "costPrice" },
      {
        field: "markupPercentage",
        header: "markupPercentage",
      },
      {
        field: "sellingPrice",
        header: "sellingPrice",
      },
      { field: "markupPrice", header: "markupPrice" },
      { field: "smoRouteId", header: "smoRouteId" },
      //{ field: "smoRegionId", header: "smoRegionId" },
    ];
    this.exportTargetColumns = this.targetCols.map((col) => ({
      title: col.header,
      dataKey: col.field,
    }));
    this.priceRegisterForm = this.formBuilder.group({
      serviceRegistrationId: [null, [Validators.required]],
      costPrice: [null, [Validators.required]],
      markupPercentage: [null, [Validators.required]],
      sellingPrice: [{ value: null, disabled: true }, [Validators.required]],
      markupPrice: [{ value: null, disabled: true }, [Validators.required]],
      smoRouteId: [{ value: null, disabled: true }, [Validators.required]],
      //smoRegionId: [null, [Validators.required]],
      //sequence: [''],
    });

    this.fetchAllRegisteredServices();
    //this.fetchAllPriceRegistersByRouteId();
    //this.fetchAllPriceRegisters();
    this.FetchAllRoutesAndRegions();
    this.fetchAllRegions();
    //this.fetchAllSMORoutes();
    //this.fetchRoutesForNode();

    this.submitButtonLabel = "Create";
  }

  fetchAllRegisteredServices(): void {
    this.serviceRegistrationService
      .allServiceReg()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            var res = r.responseData;
            this.serviceRegistrations = res;
            this.loading = false;
          }
        },
        (error) => {
          this.loading = false;
          this.connectionError();
        }
      );
    this.submittingData = false;
  }
  fetchAllPriceRegisters(): void {
    this.priceRegisterService
      .allPriceRegisters()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            var res = r.responseData;
            this.priceRegisters = res;
            this.loading = false;
          }
        },
        (error) => {
          this.loading = false;
          this.connectionError();
        }
      );
    this.submittingData = false;
  }

  fetchAllPriceRegistersByRouteId(): void {
    this.priceRegisterService
      .getAllPriceRegisterByRouteId(this.selectedStore.id)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            var res = r.responseData;
            this.priceRegisters = res;
            this.loading = false;
          }
        },
        (error) => {
          this.loading = false;
          this.connectionError();
        }
      );
    this.submittingData = false;
  }

  createPriceRegister() {
    const formData = this.priceRegisterForm.value;
    const postData = {
      serviceRegistrationId: this.selectedserviceRegistration.id,
      costPrice: formData.costPrice,
      markupPercentage: formData.markupPercentage,
      sellingPrice: this.sellingPricee,
      //markupPrice: formData.markupPrice,
      markupPrice: this.markupPricee,
      //smoRouteId: formData.smoRouteId,
      smoRouteId: this.selectedStore.id,
      //smoRegionId: formData.smoRegionId,
    };
    this.priceRegisterService
      .postPriceRegister(postData)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            this.messageService.add({
              summary: "Success",
              severity: "success",
              detail: "New Price created",
            });
            this.fetchAllRegisteredServices();
            this.fetchAllPriceRegistersByRouteId();
            this.submittingData = false;
            this.priceRegisterForm.reset();
            this.userForm.reset();
            this.closeEditing();
          } else {
            this.messageService.add({
              severity: "error",
              summary: "Failed",
              detail: " Error: " + r.responseMsg,
            });
            this.submittingData = false;
          }
        },
        (error) => {
          this.messageService.add({
            severity: "error",
            summary: "Failed",
            detail: "Connection Error " + error.error.message,
          });
          this.submittingData = false;
        }
      );
    this.submittingData = false;
  }

  deletePriceRegister(priceRegister: PriceRegister): void {
    this.confirmationService.confirm({
      message: "Are you sure you want to delete " + "price",
      accept: () => {
        this._deletePriceRegister(priceRegister);
      },
    });
  }
  _deletePriceRegister(priceRegister) {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Deleting Price record",
    });
    this.priceRegisterService.deletePriceRegister(priceRegister.id).subscribe(
      async (result: any) => {
        await this.messageService.add({
          severity: "success",
          summary: "Deleted",
          detail: "Price record removed",
        });
        await this.fetchAllRegisteredServices();
        await this.fetchAllPriceRegistersByRouteId();
        //this.fetchAllPriceRegisters();
      },
      (error) => {
        this.connectionError();
      }
    );
  }
  editPriceRegister(priceRegisters: PriceRegister): void {
    this.formWrapper.nativeElement.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "start",
    });
    this.edPriceRegister = priceRegisters;
    this.editingPriceRegister = true;
    // this.imageSrc = pilotProfiles.imageUrl;
    this.priceRegisterForm.setValue({
      serviceRegistrationId: priceRegisters.serviceRegistrationId,
      costPrice: priceRegisters.costPrice,
      markupPercentage: priceRegisters.markupPercentage,
      sellingPrice: priceRegisters.sellingPrice,
      markupPrice: priceRegisters.markupPrice,
      smoRouteId: priceRegisters.smoRouteId,
      //smoRegionId: priceRegisters.smoRegionId,
    });
    this.selectedserviceRegistration = this.serviceRegistrations.find(
      (Type) => Type.id === priceRegisters.serviceRegistrationId
    );

    this.submitButtonLabel = "Update";
  }
  updatePriceRegister(): boolean {
    this.submittingData = true;
    if (!this.editPriceRegister) {
      return false;
    }
    const formData = this.priceRegisterForm.value;
    const data = {
      //serviceRegistrationId: this.selectedserviceRegistration.id,
      costPrice: formData.costPrice,
      markupPercentage: formData.markupPercentage,
      sellingPrice: this.sellingPricee,
      markupPrice: this.markupPricee,
      //smoRouteId: formData.smoRouteId,
      //smoRegionId: formData.smoRegionId,
      // sequence: 0
    };
    this.priceRegisterService
      .updatePriceRegister(this.edPriceRegister.id, data)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            this.messageService.add({
              severity: "success",
              detail: "Price  Updated",
              summary: "Completed",
            });
            this.submittingData = false;
            this.closeEditing();
            this.fetchAllPriceRegistersByRouteId();
            this.fetchAllRegisteredServices();
          }
        },
        (error) => {
          this.messageService.add({
            severity: "error",
            summary: "Failed",
            detail: "Connection Error " + error.error.message,
          });
          this.submittingData = false;
        }
      );
  }

  //Route and Region
  fetchAllSMORoutes(): void {
    this.sMORouteService
      .allRoutes()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            var res = r.responseData;
            this.smoRoutes = res;
            console.log(res);
            this.loading = false;
          }
        },
        (error) => {
          this.loading = false;
          this.connectionError();
        }
      );
    this.submittingData = false;
  }
  fetchAllRegions(): void {
    this.sMORouteService
      .allRegions()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            var res = r.responseData;
            this.smoRegions = res;
            this.fetchAllSMORoutes();
            console.log(res);
            this.loading = false;
          }
        },
        (error) => {
          this.loading = false;
          this.connectionError();
        }
      );
    this.submittingData = false;
  }

  FetchAllRoutesAndRegions() {
    this.sMORouteService
      .allRegions()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            var res = r.responseData;
            //this.smoRegions = res;

            console.log(res);
            this.loading = false;
          }
        },
        (error) => {
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail:
              "Unable to fetch all inventory stores at the moment.. Reason: [" +
              error.error.message +
              "]",
          });
        }
      );

    this.sMORouteService
      .allRoutes()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            var res = r.responseData;
            this.smoRoutes = res;
            this.PopulateRouteTree();
            console.log(res);
            this.loading = false;
          }
        },
        (error) => {
          this.loading = false;
          this.connectionError();
        }
      );
  }

  // fetchRoutesForNode() {
  //   this.sMORouteService
  //     .allRegions()
  //     .pipe(takeUntil(this.unsubscriber$))
  //     .subscribe(
  //       (r: CommonResponse) => {
  //         if (r.responseCode == "00") {
  //           var res = r.responseData as TreeNode[];
  //           //this.smoRegions = res;
  //           console.log(res);
  //           this.loading = false;
  //         }
  //       },
  //       (error) => {
  //         this.loading = false;
  //         this.connectionError();
  //       }
  //     );
  //   this.submittingData = false;
  // }
  PopulateRouteTree() {
    this.fetchAllSMORoutes();
    this.priceRegisterTree = [];
    const centralStoresTree = [];
    this.loadingPriceRegisters = false;

    this.smoRegions.filter((centralStore) => {
      const RuotesTree = [];
      this.smoRoutes.filter((regionStore) => {
        if (regionStore?.smoRegionId === centralStore?.id) {
          RuotesTree.push({
            label: regionStore.routeName,
            key: regionStore.id,
            icon: "pi pi-fw pi-sitemap",
            data: regionStore,
            selectable: true,
          });
        }
      });

      centralStoresTree.push({
        label: centralStore.regionName,
        key: centralStore.id,
        expandedIcon: "pi pi-map-marker",
        collapsedIcon: "pi pi-map-marker",
        expanded: true,
        data: centralStore,
        children: RuotesTree,
        selectable: true,
      });
    });

    this.priceRegisterTree = [
      {
        label: " Security In Motion",
        key: "Route Folder",
        // expandedIcon: "pi pi-map-marker",
        // collapsedIcon: "pi pi-map-marker",
        expandedIcon: "pi pi-minus-circle",
        collapsedIcon: "pi pi-plus-circle",
        expanded: true,
        children: centralStoresTree,
        data: 1001,
        selectable: true,
      },
    ];
  }

  async ShowStoreData(event) {
    if (event.node.data) {
      if (this.selectedStore !== event.node.data) {
        this.selectedStore = event.node.data;
        this.priceRegisterService
          .getAllPriceRegisterByRouteId(this.selectedStore.id)
          .pipe(takeUntil(this.unsubscriber$))
          .subscribe(
            (r: CommonResponse) => {
              if (r.responseCode == "00") {
                var res = r.responseData;
                this.priceRegisters = res;
                console.log(this.priceRegisterTree);
                this.loading = false;
              }
            },
            (error) => {
              this.loading = false;
              this.connectionError();
            }
          );
      }
    }

    if (this.selectedStore == 1001) {
      this.cantCreateRouteUnder = false;
      this.routeDetailTable[0].data = "Routes";
      this.routeDetailTable[1].data = "Route-00000";
      this.routeDetailTable[2].data = "~";
      this.routeDetailTable[3].data = "~";
      console.log(98);
    } else {
      if (this.selectedStore.centralStoreId) {
        this.cantCreateRouteUnder = true;
        console.log(99);
        this.routeDetailTable[1].data =
          "REGIONAL-" + String(this.selectedStore.id).padStart(5, "0");
      } else {
        this.cantCreateRouteUnder = false;
        console.log(this.selectedStore.id);
        this.routeDetailTable[1].data =
          "CENTRAL-" + String(this.selectedStore.id).padStart(5, "0");

        // this.priceRegisterService
        //   .getAllPriceRegisterByRouteId(this.selectedStore.id)
        //   .pipe(takeUntil(this.unsubscriber$))
        //   .subscribe(
        //     (res: any) => {
        //       this.priceRegisters = res;
        //       console.log(this.priceRegisterTree);
        //       this.loading = false;
        //     },
        //     (error) => {
        //       this.loading = false;
        //       this.connectionError();
        //     }
        //   );
      }

      this.centralStoreName = this.selectedStore.smoRouteId
        ? this.selectedStore.smoRouteId
        : this.selectedStore.smoRegionId;
      this.routeDetailTable[0].data = this.selectedStore.smoRouteId
        ? this.selectedStore.smoRouteId
        : this.selectedStore.smoRegionId;
      this.routeDetailTable[2].data = "~";
      this.routeDetailTable[3].data = "~";
      // await this.CompletedStoreDetails(
      //   this.selectedStore.storeName ? true : false,
      //   this.selectedStore.id
      // );
    }
  }

  get sellingPricee() {
    // var res = this.markupPricee + this.costPrice;
    // return Number(res).toFixed(2);
    return Number(this.markupPricee) + this.costPrice;
  }
  get markupPricee() {
    var res = (this.markupPercentage / 100) * this.costPrice;
    //var res = this.costPrice / this.markupPercentage;
    return res.toFixed(2);
    //return this.costPrice / this.markupPercentage;
  }

  closeEditing() {
    this.editingPriceRegister = false;
    this.priceRegisterForm.reset();
    this.edPriceRegister = null;

    this.submitButtonLabel = "Create";
  }
  private connectionError() {
    this.messageService.add({
      severity: "error",
      summary: "Failed",
      detail: "Connection Error, Please try again",
    });
  }
  ngOnDestroy(): void {
    this.unsubscriber$.complete();
    this.unsubscriber$.unsubscribe();
  }
}
