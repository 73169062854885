import { JobPost } from "./../../../interfaces/premployment";
import {
  AddTrainingSchoolStudent,
  ApplicantEvaluation,
  Student,
  TrainingSchGetAllJobPost,
  TrainingSchool,
} from "./../../../interfaces/employment";
import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MessageService, ConfirmationService } from "primeng/api";
import { BreadcrumbService } from "src/app/breadcrumb.service";
import { CreateTrainingSchool } from "src/app/interfaces/employment";
import { GmaEmploymentService } from "src/app/services/gma-employment.service";

@Component({
  selector: "app-gma-training-school",
  templateUrl: "./gma-training-school.component.html",
  styleUrls: ["./gma-training-school.component.scss"],
})
export class GmaTrainingSchoolComponent implements OnInit {
  @ViewChild("formWrapper") public formWrapper: ElementRef;
  form: FormGroup;
  isCreatingTSchool: boolean;
  fetchingTrainingSchools: boolean = false; //Temp
  allTrainingSchools: TrainingSchool[] = [];
  selectedTrainingSchool: TrainingSchool;
  trainingSchCols: any[] = [];
  trainingSchStudentCols: any[] = [];
  trainingSchoolInView: TrainingSchool;
  jobPostListTable: {
    name: string;
    data: string;
  }[];
  selectedJobPostListTable: {
    name: string;
    data: string;
  };
  allTrainSchJobPosts: TrainingSchGetAllJobPost[] = [];
  theTrainSchJobPost: TrainingSchGetAllJobPost;
  shortlistedCandidates: ApplicantEvaluation[] = [];
  trainingSchoolStudents: Student[] = [];
  selectedTrainingSchoolStudent: Student;

  constructor(
    fb: FormBuilder,
    public employmentService: GmaEmploymentService,
    public messageService: MessageService,
    private breadcrumbService: BreadcrumbService,
    public confirmationService: ConfirmationService
  ) {
    this.form = fb.group({
      SchoolName: ["", Validators.required],
      SchoolStartDate: ["", Validators.required],
      SchoolDescription: ["", Validators.required],
    });
  }

  ngOnInit(): void {
    this.breadcrumbService.setItems([
      {
        label: "Guard Management",
        routerLink: ["/home/guard-management/item-settings"],
      },
      {
        label: "Training School",
        routerLink: ["/home/guard-management/training-school"],
      },
    ]);

    this.trainingSchCols = [
      { field: "name", header: "name" },
      { field: "description", header: "Description" },
    ];

    this.trainingSchStudentCols = [
      {
        field: "jobApplication.personalInformation.lastname",
        header: "JobApplication.personalInformation.lastname",
      },
      {
        field: "jobApplication.personalInformation.firstname",
        header: "JobApplication.personalInformation.firstname",
      },
      {
        field: "jobApplication.jobPost.jobCode",
        header: "JobApplication.jobPost.jobCode",
      },
    ];

    this.jobPostListTable = [
      {
        name: "Job Title",
        data: "Data",
      },
      {
        name: "Job Description",
        data: "Data",
      },
      {
        name: "Date Created",
        data: "Data",
      },
      {
        name: "Shortlisted Candidates",
        data: "Data",
      },
    ];

    this.FetchAllTrainingSchools();
    this.FetchAllTrainingSchJobPosts();
  }

  FetchAllTrainingSchools() {
    this.employmentService.GetAllTrainingSchools().subscribe(
      async (data) => {
        this.allTrainingSchools = data;
        this.fetchingTrainingSchools = false;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all training schools at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
        this.fetchingTrainingSchools = false;
      }
    );
  }

  FetchAllTrainingSchJobPosts() {
    this.employmentService.GetAllTrainingSchJobPosts().subscribe(
      async (data) => {
        this.allTrainSchJobPosts = data;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all job posts at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  CreateTrainingSchool() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Creating Training School...",
    });

    this.isCreatingTSchool = true;
    const postData: CreateTrainingSchool = {
      name: this.form.get("SchoolName").value,
      startDate: this.form.get("SchoolStartDate").value,
      description: this.form.get("SchoolDescription").value,
    };

    this.employmentService.CreateTrainingSchool(postData).subscribe(
      async () => {
        await this.messageService.add({
          severity: "success",
          summary: "Completed",
          detail: "Training School Created Successfully!",
        });

        this.form.reset();
        this.FetchAllTrainingSchools();
        this.isCreatingTSchool = false;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to create training school at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
        this.isCreatingTSchool = false;
      }
    );
  }

  LoadJobPost() {
    this.jobPostListTable[0].data = this.theTrainSchJobPost.job.title;
    this.jobPostListTable[1].data = this.theTrainSchJobPost.job.description;
    this.jobPostListTable[2].data = this.theTrainSchJobPost.job.createdAt
      .toString()
      .substring(0, 10);
    this.jobPostListTable[3].data =
      this.theTrainSchJobPost.shortlistedApplicants.length + "";
    this.shortlistedCandidates = this.theTrainSchJobPost.shortlistedApplicants;
  }

  LoadTrainingSchool(data: TrainingSchool) {
    this.trainingSchoolInView = data;
    this.trainingSchoolStudents = data.students;
  }

  SendForApproval() {
    this.confirmationService.confirm({
      message:
        "Once you send this training school for approval, you won't be able to add any more student(s) to this school. Do you still wish to proceed?",
      accept: () => {
        this.messageService.add({
          severity: "info",
          summary: "Notice",
          detail: "Sending Training School For Approval...",
        });

        this.employmentService
          .SendTrainingSchoolForApproval(this.trainingSchoolInView.id)
          .subscribe(
            async () => {
              await this.messageService.add({
                severity: "success",
                summary: "Completed",
                detail: "Training School Created Successfully!",
              });

              this.trainingSchoolInView = null;
              this.theTrainSchJobPost = null;
              this.FetchAllTrainingSchools();
            },
            (error) => {
              console.log("Error: " + JSON.stringify(error));
              this.messageService.add({
                severity: "error",
                summary: "Notice",
                detail:
                  "Unable to send training school for approval at the moment.. Reason: [" +
                  error
                    ? error.error.message
                    : "request failed - permission" + "]",
              });
            }
          );
      },
    });
  }

  IsCandidateRegisteredInTrainingSchool(jobApplicationId: number): boolean {
    let student = this.trainingSchoolInView.students.filter(
      (x) => x.jobApplicationId == jobApplicationId
    );
    return student.length != 0;
  }

  AddCandidateToTrainingSchool(data: ApplicantEvaluation) {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Adding Candidate To Training School...",
    });

    if (this.trainingSchoolInView.isSentForApproval) {
      this.messageService.add({
        severity: "error",
        summary: "Notice",
        detail:
          "Training School has already been sent for approval, no new student can be added.",
      });
      return;
    }

    const postData: AddTrainingSchoolStudent = {
      trainingSchoolId: this.trainingSchoolInView.id,
      jobApplicantId: data.jobApplicationId,
    };

    this.employmentService.AddTrainingSchoolStudent(postData).subscribe(
      async (data) => {
        await this.messageService.add({
          severity: "success",
          summary: "Completed",
          detail: "Training School Created Successfully!",
        });

        this.trainingSchoolStudents.push(data);
        // this.trainingSchoolInView = null;
        // this.theTrainSchJobPost = null;
        this.FetchAllTrainingSchools();
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to add training school student at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }
}
