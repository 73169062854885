import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { VehicleTracking } from "src/app/interfaces/armada";
import { CommonResponse } from "src/app/interfaces/home";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class JourneyStartAndStopService {
  baseUrl = environment.baseUrl;
  baseUrlPortal = environment.onlineBackendBaseUrl_;
  //baseUrlPortal = "https://localhost:44353/api/";

  // _trackingAPI =
  //   "https://gsh3.net/id22/api/api.php?api=user&ver=1.0&key=530B360EF691F58B9E3B35059D016045&cmd=OBJECT_GET_LOCATIONS,";
  //baseUrl = "https://localhost:44344/api/v1/";
  constructor(private httpClient: HttpClient) {}

  //Journey Start
  allJourneyStarts(): Observable<CommonResponse> {
    return this.httpClient.get<CommonResponse>(
      this.baseUrl + "JourneyStartandStop/GetAllJourneyStarts"
    );
  }

  getJourneyStart(id: any): Observable<CommonResponse> {
    return this.httpClient.get<CommonResponse>(
      this.baseUrl + "JourneyStartandStop/GetJouneyStartById/" + id
    );
  }

  getJourneyStartByAssignmentId(id: any): Observable<CommonResponse> {
    return this.httpClient.get<CommonResponse>(
      this.baseUrl + "JourneyStartandStop/GetJourneyStartByAssignmentId/" + id
    );
  }

  postJourneyStart(data: any) {
    return this.httpClient.post(
      this.baseUrl + "JourneyStartandStop/AddNewJourneyStart",
      data
    );
  }
  postJourneyStartForAdmin(data: any) {
    return this.httpClient.post(
      this.baseUrl + "JourneyStartandStop/AddStartJourneyForAdmin",
      data
    );
  }
  deleteJourneyStart(id: any) {
    return this.httpClient.delete(
      this.baseUrl + "JourneyStartandStop/DeleteJourneyStartById/" + id
    );
  }
  updateCancelJourneyStart(id: any) {
    return this.httpClient.delete(
      this.baseUrl + "JourneyStartandStop/UpdateCancelJourneyStartById/" + id
    );
  }
  updateEndJourneyStart(id: any, data: any) {
    return this.httpClient.put(
      this.baseUrl + "JourneyStartandStop/UpdateEndJourneyStartById/" + id,
      data
    );
  }

  updateEndJourneyStartByAssignId(id: any, data: any) {
    return this.httpClient.put(
      this.baseUrl + "JourneyStartandStop/UpdateStartJourneyForEndTripByAssignmentId/" + id,
      data
    );
  }
 
  //Journey Stop

  allJourneyStops(): Observable<CommonResponse> {
    return this.httpClient.get<CommonResponse>(
      this.baseUrl + "JourneyStartandStop/GetAllJourneyStops"
    );
  }

  allJourneyStopsByStartId(id: any): Observable<CommonResponse> {
    return this.httpClient.get<CommonResponse>(
      this.baseUrl +
        "JourneyStartandStop/GetAllJourneyStopsByJourneyStartId/" +
        id
    );
  }

  getJourneyStop(id: any): Observable<CommonResponse> {
    return this.httpClient.get<CommonResponse>(
      this.baseUrl + "JourneyStartandStop/GetJourneyStopById/" + id
    );
  }

  postJourneyStop(data: any) {
    return this.httpClient.post(
      this.baseUrl + "JourneyStartandStop/AddNewJourneyStop",
      data
    );
  }
  deleteJourneyStop(id: any) {
    return this.httpClient.delete(
      this.baseUrl + "JourneyStartandStop/DeleteJourneyStopById/" + id
    );
  }

  updateJourneyStop(id: any, data: any) {
    return this.httpClient.put(
      this.baseUrl + "JourneyStartandStop/UpdateJourneyStopById/" + id,
      data
    );
  }

  //Journey Incident
  allJourneyIncidents(): Observable<CommonResponse> {
    return this.httpClient.get<CommonResponse>(
      this.baseUrl + "JourneyStartandStop/GetAllJourneyIncidents"
    );
  }

  allJourneyIncidentsByStartId(id: any): Observable<CommonResponse> {
    return this.httpClient.get<CommonResponse>(
      this.baseUrl +
        "JourneyStartandStop/GetAllJourneyIncidentsByJourneyStartId/" +
        id
    );
  }

  getJourneyIncident(id: any): Observable<CommonResponse> {
    return this.httpClient.get<CommonResponse>(
      this.baseUrl + "JourneyStartandStop/GetJourneyIncidentById/" + id
    );
  }

  postJourneyIncident(data: any) {
    return this.httpClient.post(
      this.baseUrl + "JourneyStartandStop/AddNewJourneyIncident",
      data
    );
  }
  deleteJourneyIncident(id: any) {
    return this.httpClient.delete(
      this.baseUrl + "JourneyStartandStop/DeleteJourneyIncidentById/" + id
    );
  }

  updateJourneyIncident(id: any, data: any) {
    return this.httpClient.put(
      this.baseUrl + "JourneyStartandStop/UpdateJourneyIncidentById/" + id,
      data
    );
  }

  //Journey IncidentPics
  allJourneyIncidentPics(): Observable<CommonResponse> {
    return this.httpClient.get<CommonResponse>(
      this.baseUrl + "JourneyStartandStop/GetAllJourneyIncidentPics"
    );
  }

  allJourneyIncidentPicsByIncidentId(id: any): Observable<CommonResponse> {
    return this.httpClient.get<CommonResponse>(
      this.baseUrl +
        "JourneyStartandStop/GetAllJourneyIncidentPicsByJourneyIncidentId/" +
        id
    );
  }

  getJourneyIncidentPic(id: any): Observable<CommonResponse> {
    return this.httpClient.get<CommonResponse>(
      this.baseUrl + "JourneyStartandStop/GetJourneyIncidentPicById/" + id
    );
  }

  postJourneyIncidentPic(data: any) {
    return this.httpClient.post(
      this.baseUrl + "JourneyStartandStop/AddNewJourneyIncidentPic",
      data
    );
  }
  deleteJourneyIncidentPic(id: any) {
    return this.httpClient.delete(
      this.baseUrl + "JourneyStartandStop/DeleteJourneyIncidentPicById/" + id
    );
  }

  //Journey LeadCommander
  allJourneyLeadCommanders(): Observable<CommonResponse> {
    return this.httpClient.get<CommonResponse>(
      this.baseUrl + "JourneyStartandStop/GetAllJourneyLeadCommanders"
    );
  }

  // allJourneyIncidentPicsByIncidentId(id: any): Observable<CommonResponse> {
  //   return this.httpClient.get<CommonResponse>(
  //     this.baseUrl +
  //       "JourneyStartandStop/GetAllJourneyIncidentPicsByJourneyIncidentId/" +
  //       id
  //   );
  // }

  getJourneyLeadCommander(id: any): Observable<CommonResponse> {
    return this.httpClient.get<CommonResponse>(
      this.baseUrl + "JourneyStartandStop/GetJourneyLeadCommanderById/" + id
    );
  }

  postJourneyLeadCommander(data: any) {
    return this.httpClient.post(
      this.baseUrl + "JourneyStartandStop/AddNewJourneyLeadCommander",
      data
    );
  }
  deleteJourneyLeadCommander(id: any) {
    return this.httpClient.delete(
      this.baseUrl + "JourneyStartandStop/DeleteJourneyLeadCommanderById/" + id
    );
  }
  relinquishJourneyLeadCommander(id: any) {
    return this.httpClient.delete(
      this.baseUrl +
        "JourneyStartandStop/RelinquishJourneyLeadCommanderById/" +
        id
    );
  }

  //Journey Note
  allJourneyNotes(): Observable<CommonResponse> {
    return this.httpClient.get<CommonResponse>(
      this.baseUrl + "JourneyStartandStop/GetAllJourneyNotes"
    );
  }

  allJourneyNotesByStartId(id: any): Observable<CommonResponse> {
    return this.httpClient.get<CommonResponse>(
      this.baseUrl + "JourneyStartandStop/GetAllJourneyNotesByStartId/" + id
    );
  }

  getJourneyNote(id: any): Observable<CommonResponse> {
    return this.httpClient.get<CommonResponse>(
      this.baseUrl + "JourneyStartandStop/GetJourneyNoteById/" + id
    );
  }

  postJourneyNote(data: any) {
    return this.httpClient.post(
      this.baseUrl + "JourneyStartandStop/AddNewJourneyNote",
      data
    );
  }
  deleteJourneyNote(id: any) {
    return this.httpClient.delete(
      this.baseUrl + "JourneyStartandStop/DeleteJourneyNoteById/" + id
    );
  }
  //Feedback
  getJourneyStartFeedback(id: any): Observable<CommonResponse> {
    return this.httpClient.get<CommonResponse>(
      this.baseUrlPortal + "SMSTripFeedback/GetJouneyStartById/" + id
    );
  }

  getJourneyStartFeedbackByAssignmentId(id: any): Observable<CommonResponse> {
    return this.httpClient.get<CommonResponse>(
      this.baseUrlPortal + "SMSTripFeedback/GetJourneyStartByAssignmentId/" + id
    );
  }

  getServiceAssignment(id: any): Observable<CommonResponse> {
    return this.httpClient.get<CommonResponse>(
      this.baseUrlPortal +
        "SMSTripFeedback/GetServiceAssignmentMasterById/" +
        id
    );
  }
  postFeedbackMaster(data: any) {
    return this.httpClient.post(
      this.baseUrlPortal + "SMSTripFeedback/AddFeedbackMaster",
      data
    );
  }
  getFeedbackMsterByAssignmentId(id: any): Observable<CommonResponse> {
    return this.httpClient.get<CommonResponse>(
      this.baseUrlPortal +
        "SMSTripFeedback/GetFeedbackMasterByAssignmentId/" +
        id
    );
  }
  getGeneralFeedbackByAssignmentId(id: any): Observable<CommonResponse> {
    return this.httpClient.get<CommonResponse>(
      this.baseUrlPortal +
        "SMSTripFeedback/GetGeneralFeedbackByAssignmentId/" +
        id
    );
  }
  postGeneralFeedback(data: any) {
    return this.httpClient.post(
      this.baseUrlPortal + "SMSTripFeedback/AddGeneralFeedback",
      data
    );
  }
  postArmedEscortFeedback(data: any) {
    return this.httpClient.post(
      this.baseUrlPortal + "SMSTripFeedback/AddArmedEscortFeedback",
      data
    );
  }
  postCommanderFeedback(data: any) {
    return this.httpClient.post(
      this.baseUrlPortal + "SMSTripFeedback/AddCommanderFeedback",
      data
    );
  }
  postPilotFeedback(data: any) {
    return this.httpClient.post(
      this.baseUrlPortal + "SMSTripFeedback/AddPilotFeedback",
      data
    );
  }
  postVehicleFeedback(data: any) {
    return this.httpClient.post(
      this.baseUrlPortal + "SMSTripFeedback/AddVehicleFeedback",
      data
    );
  }

  //Tracking

  vehicleTracking(id: any): Observable<CommonResponse> {
    return this.httpClient.get<CommonResponse>(
      this.baseUrl + "JourneyStartandStop/GetTrackingByIMEI/" + id
    );
  }
}
