import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { CommonResponse, ServiceType } from "../../../interfaces/home";
import { ConfirmationService, MessageService } from "primeng/api";
import { ServiceTypeService } from "../../../services/service-type.service";
import { BreadcrumbService } from "src/app/breadcrumb.service";

@Component({
  selector: "app-service-type",
  templateUrl: "./service-type.component.html",
  styleUrls: ["./service-type.component.scss"],
  providers: [MessageService, ConfirmationService],
})
export class ServiceTypeComponent implements OnInit {
  @ViewChild("formWrapper") public formWrapper: ElementRef;
  fetchingServiceType = true;
  serviceTypeForm: FormGroup;
  serviceTypes: ServiceType[];
  selectedServiceType: ServiceType;
  editingServiceType = false;
  public serviceTypeCols: any[];
  public exportServiceTypeColumns: any[];
  private edServiceType: ServiceType;
  constructor(
    private formBuilder: FormBuilder,
    private serviceTypeService: ServiceTypeService,
    public messageService: MessageService,
    public confirmationService: ConfirmationService,
    private breadcrumbService: BreadcrumbService
  ) {}

  ngOnInit(): void {
    this.breadcrumbService.setItems([
      { label: "Setup", routerLink: ["home/service-type"] },
      { label: "Services", routerLink: ["home/service-type"] },
      { label: "Service Type", routerLink: ["home/service-type"] },
    ]);
    this.fetchServiceType();
    this.serviceTypeForm = this.formBuilder.group({
      description: ["", Validators.required],
      caption: ["", [Validators.required]],
    });
    this.serviceTypeCols = [
      { field: "caption", header: "Caption" },
      { field: "description", header: "Description" },
    ];
    this.exportServiceTypeColumns = this.serviceTypeCols.map((col) => ({
      title: col.header,
      dataKey: col.field,
    }));
  }
  fetchServiceType() {
    this.serviceTypeService.allData().subscribe(
      async (serviceType: CommonResponse) => {
        this.serviceTypes = serviceType.responseData;
        this.fetchingServiceType = false;
      },
      (error) => {
        this.fetchingServiceType = false;
        this.connectionError();
      }
    );
  }
  private connectionError() {
    this.messageService.add({
      severity: "error",
      summary: "Failed",
      detail: "Connection Error, Please try again",
    });
  }
  createServiceType() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Creating new Service Type Records",
    });
    const postData = {
      description: this.serviceTypeForm.get("description").value,
      caption: this.serviceTypeForm.get("caption").value,
    };
    this.serviceTypeService.postServiceType(postData).subscribe(
      async () => {
        await this.messageService.add({
          severity: "success",
          summary: "Completed",
          detail: "New Service Type Created",
        });
        await this.fetchServiceType();
        await this.serviceTypeForm.reset();
      },
      (error) => {
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail: "Unable to create new Service Type at the moment",
        });
      }
    );
  }

  deleteServiceType(serviceType) {
    this.confirmationService.confirm({
      message: "Are you sure you want to delete " + serviceType.name,
      accept: () => {
        this._deleteServiceType(serviceType);
      },
    });
  }
  _deleteServiceType(serviceType) {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Deleting service Type record",
    });
    this.serviceTypeService.deleteServiceType(serviceType.id).subscribe(
      async (result: any) => {
        await this.messageService.add({
          severity: "success",
          summary: "Deleted",
          detail: "Service Type record removed",
        });
        await this.fetchServiceType();
      },
      (error) => {
        this.connectionError();
      }
    );
  }
  editServiceType(serviceTypes) {
    this.editingServiceType = true;
    this.serviceTypeForm.addControl(
      "serviceTypeId",
      new FormControl({ value: "", disabled: true }, Validators.required)
    );
    this.edServiceType = serviceTypes;
    this.serviceTypeForm.patchValue({
      caption: serviceTypes.caption,
      description: serviceTypes.description,
      serviceTypeId: serviceTypes.id,
    });
    // this.serviceTypeFormIn.nativeElement.focus();
    // document.getElementById('formFields').focus();
    // this.selectedHeadId = this.users.find(user => user.id === serviceTypes.head.id);
    this.formWrapper.nativeElement.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "start",
    });
  }

  updateServiceType() {
    // update the selected serviceType
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Updating Service Type details",
    });
    const id = this.edServiceType.id;
    const postData = {
      caption: this.serviceTypeForm.get("caption").value,
      description: this.serviceTypeForm.get("description").value,
    };
    this.serviceTypeService
      .updateServiceType(id, postData)
      .subscribe(async (result: ServiceType) => {
        this.messageService.add({
          severity: "success",
          summary: "Notice",
          detail: "Service Type details Updated",
        });
        await this.fetchServiceType();
        await this.closeEditing();
      });
  }

  closeEditing() {
    this.editingServiceType = false;
    this.serviceTypeForm.reset();
    this.edServiceType = null;
  }
}
