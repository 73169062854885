import {
  ConvergenceAdvisory,
  ConvergenceCategory,
  ConvergenceEntityDefaultSource,
  ConvergenceRegion,
  CreateAdvisoryVM,
  CreateConvergenceCategoryVM,
  CreateConvergenceRegionVM,
  CreateDefaultEventSourceVM,
  CreateEventTypeVM,
  UpdateAdvisoryVM,
  UpdateConvergenceCategoryVM,
  UpdateConvergenceRegionVM,
  UpdateDefaultEventSourceVM,
  UpdateEventSourceVM,
  UpdateEventTypeVM,
} from "./../../../../interfaces/convergence";
import { ConvergenceService } from "./../../../../services/convergence.service";
import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { ConfirmationService, MessageService } from "primeng/api";
import { BreadcrumbService } from "src/app/breadcrumb.service";
import {
  CreateEventSourceVM,
  EventSource,
  EventType,
} from "src/app/interfaces/convergence";
import { CommonResponse, State } from "src/app/interfaces/home";
import { StateService } from "src/app/services/state.service";
import { EventInput } from "@fullcalendar/core";
import { FileStorageService } from "src/app/services/file-storage.service";

@Component({
  selector: "app-incidence-convergence-setup",
  templateUrl: "./incidence-convergence-setup.component.html",
  styleUrls: ["./incidence-convergence-setup.component.css"],
})
export class IncidenceConvergenceSetupComponent implements OnInit {
  @ViewChild("formWrapper") public formWrapper: ElementRef;
  eventSourceForm: FormGroup;
  eventTypeForm: FormGroup;
  regionForm: FormGroup;
  advisoryForm: FormGroup;
  defaultSourceForm: FormGroup;
  categoryForm: FormGroup;
  editingEventSource: boolean;
  fetchingEventSource: boolean;
  allEventSources: EventSource[];
  selectedEventSources: EventSource[];
  eventSourceCols: any[];
  categoryCols: any[];
  editingEventType: boolean;
  fetchingEventType: boolean;
  allEventTypes: EventType[];
  selectedEventTypes: EventType[];
  eventTypeCols: any[];
  eventSourceToEdit: EventSource;
  eventTypeToEdit: EventType;
  allStates: State[];
  selectedStates: State[] = [];
  editingRegion: boolean;
  fetchingConvergenceRegions: boolean;
  allConvergenceRegions: ConvergenceRegion[];
  selectedConvergenceRegions: ConvergenceRegion[];
  regionCols: any[];
  regionToEdit: ConvergenceRegion;
  allReportedType: { id: number; caption: string }[] = [
    {
      id: 1,
      caption: "Supplier",
    },
    {
      id: 2,
      caption: "Client",
    },
    {
      id: 3,
      caption: "Staff",
    },
    {
      id: 4,
      caption: "Operatives",
    },
  ];
  theReportedType: {
    id: number;
    caption: string;
  };
  theEventSource: EventSource;
  editingDefaultSource: boolean;
  fetchingDefaultEventSource: boolean;
  allDefaultEventSources: ConvergenceEntityDefaultSource[];
  selectedDefaultEventSource: ConvergenceEntityDefaultSource[];
  defaultSourceCols: any[];
  defaultSourceToEdit: ConvergenceEntityDefaultSource;
  editingAdvisory: boolean;
  fetchingAdvisories: boolean;
  allConvergenceAdvisories: ConvergenceAdvisory[];
  selectedConvergenceAdvisories: ConvergenceAdvisory[] = [];
  advisoryCols: any[];
  eventTypeImageSrc: string;
  uploadingNewEventTypeImg = false;
  eventTypeFile: any;
  advisoryImageSrc: string;
  uploadingNewAdvisoryImg = false;
  advisoryFile: any;
  convergenceAdvisoryToEdit: ConvergenceAdvisory;
  editingCategory: boolean;
  fetchingCategories: boolean;
  allConvergenceCategories: ConvergenceCategory[];
  selectedConvergenceCategories: ConvergenceCategory[] = [];
  convergenceCategoryToEdit: ConvergenceCategory;
  theConvergenceCategory: ConvergenceCategory;
  isEventTypePublic: boolean = false;
  isCategoryPublic: boolean = false;

  constructor(
    private fb: FormBuilder,
    private stateService: StateService,
    public fileStorageService: FileStorageService,
    public convergenceService: ConvergenceService,
    private breadcrumbService: BreadcrumbService,
    public confirmationService: ConfirmationService,
    public messageService: MessageService
  ) {
    this.eventSourceForm = fb.group({
      Caption: ["", Validators.required],
      Description: ["", Validators.required],
    });

    this.eventTypeForm = fb.group({
      ImageUrl: [""],
      Caption: ["", Validators.required],
      ShortForm: ["", Validators.required],
      Description: ["", Validators.required],
      Category: ["", Validators.required],
      IsPublic: ["", Validators.required],
    });

    this.categoryForm = fb.group({
      Caption: ["", Validators.required],
      Description: ["", Validators.required],
      IsPublic: ["", Validators.required],
    });

    this.regionForm = fb.group({
      Name: ["", Validators.required],
      Description: ["", Validators.required],
      States: ["", Validators.required],
    });

    this.defaultSourceForm = fb.group({
      ReportedByType: ["", Validators.required],
      EventSource: ["", Validators.required],
    });

    this.advisoryForm = fb.group({
      ImageUrl: [""],
      Caption: ["", Validators.required],
      Description: ["", Validators.required],
    });
  }

  ngOnInit(): void {
    this.breadcrumbService.setItems([
      {
        label: "Convergence",
      },
      {
        label: "Incident Management",
      },
      {
        label: "Setup",
        routerLink: ["/home/convergence/incident-management/setup"],
      },
    ]);

    this.eventSourceCols = [
      { field: "caption", header: "Caption" },
      { field: "description", header: "Description" },
    ];

    this.eventTypeCols = [
      { field: "caption", header: "Caption" },
      { field: "description", header: "Description" },
      { field: "shortForm", header: "Short Form" },
      { field: "isPublic", header: "Is Public" },
    ];

    this.categoryCols = [
      { field: "caption", header: "Caption" },
      { field: "description", header: "Description" },
      { field: "isPublic", header: "Is Public" },
    ];

    this.regionCols = [
      { field: "name", header: "Name" },
      { field: "description", header: "Description" },
      { field: "regionStates.count", header: "States In Region" },
    ];

    this.defaultSourceCols = [
      { field: "GetReportedByTypeLabel(entityType)", header: "Entity Type" },
    ];

    this.advisoryCols = [
      { field: "caption", header: "Caption" },
      { field: "description", header: "Description" },
    ];

    this.FetchAllEventSource();
    this.FetchAllEventType();
    this.FetchAllStates();
    this.FetchAllDefaultEventSource();
    this.FetchAllAdvisories();
    this.FetchAllCategories();
  }

  async FetchAllEventSource() {
    this.fetchingEventSource = true;
    this.convergenceService.GetAllEventSources().subscribe(
      async (data) => {
        this.allEventSources = data;
        this.fetchingEventSource = false;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all event sources at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
        this.fetchingEventSource = false;
      }
    );
  }

  async FetchAllEventType() {
    this.fetchingEventType = true;
    this.convergenceService.GetAllEventTypes().subscribe(
      async (data) => {
        this.allEventTypes = data;
        this.fetchingEventType = false;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all event types at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
        this.fetchingEventType = false;
      }
    );
  }

  async FetchAllStates() {
    this.stateService.GetStatesOnly().subscribe(
      async (states: CommonResponse) => {
        this.allStates = states.responseData;
        this.FetchAllConvergenceRegions();
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all states at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  async FetchAllConvergenceRegions() {
    this.fetchingConvergenceRegions = true;
    this.convergenceService.GetAllConvergenceRegions().subscribe(
      async (data) => {
        this.allConvergenceRegions = data;
        this.fetchingConvergenceRegions = false;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all convergence regions at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
        this.fetchingConvergenceRegions = false;
      }
    );
  }

  async FetchAllDefaultEventSource() {
    this.fetchingDefaultEventSource = true;
    this.convergenceService.GetAllDefaultEventSources().subscribe(
      async (data) => {
        this.allDefaultEventSources = data;
        this.fetchingDefaultEventSource = false;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all default event sources at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
        this.fetchingDefaultEventSource = false;
      }
    );
  }

  async FetchAllAdvisories() {
    this.fetchingAdvisories = true;
    this.convergenceService.GetAllAdvisories().subscribe(
      async (data) => {
        this.allConvergenceAdvisories = data;
        this.fetchingAdvisories = false;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all advisories at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
        this.fetchingAdvisories = false;
      }
    );
  }

  async FetchAllCategories() {
    this.fetchingCategories = true;
    this.convergenceService.GetAllConvergenceCategories().subscribe(
      async (data) => {
        this.allConvergenceCategories = data;
        this.fetchingCategories = false;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all categories at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
        this.fetchingCategories = false;
      }
    );
  }

  CreateEventSource() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Creating Event Source...",
    });

    const postData: CreateEventSourceVM = {
      caption: this.eventSourceForm.get("Caption").value,
      description: this.eventSourceForm.get("Description").value,
    };

    this.convergenceService.CreateEventSource(postData).subscribe(
      async () => {
        await this.messageService.add({
          severity: "success",
          summary: "Completed",
          detail: "Event Source Created Successfully!",
        });

        this.eventSourceForm.reset();
        this.FetchAllEventSource();
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to create event source at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  CloseEditingEventSource() {
    this.editingEventSource = false;
    this.eventSourceForm.reset();
    this.eventSourceToEdit = null;
  }

  DeleteEventSource(item: EventSource) {
    this.confirmationService.confirm({
      message: "Are you sure you want to remove event source?",
      accept: () => {
        this.messageService.add({
          severity: "info",
          summary: "Notice",
          detail: "Removing event source...",
        });

        this.convergenceService.DeleteEventSource(item.id).subscribe(
          async () => {
            this.messageService.add({
              severity: "success",
              summary: "Removed",
              detail: "Removed successfully",
            });

            this.fetchingEventSource = true;
            const index = this.allEventSources.indexOf(item);
            if (index > -1) {
              this.allEventSources.splice(index, 1);
            }
            this.fetchingEventSource = false;
          },
          (error) => {
            console.log("Error: " + JSON.stringify(error));
            this.messageService.add({
              severity: "error",
              summary: "Notice",
              detail:
                "Unable to remove event source at the moment.. Reason: [" +
                error
                  ? error.error.message
                  : "request failed - permission" + "]",
            });
          }
        );
      },
    });
  }

  EditEventSource(item: EventSource) {
    this.editingEventSource = true;
    this.eventSourceForm.patchValue({
      Caption: item.caption,
      Description: item.description,
    });
    this.eventSourceToEdit = item;

    this.formWrapper.nativeElement.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "start",
    });
  }

  UpdateEventSource() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Updating Event Source...",
    });

    const id = this.eventSourceToEdit.id;
    const postData: UpdateEventSourceVM = {
      caption: this.eventSourceForm.get("Caption").value,
      description: this.eventSourceForm.get("Description").value,
    };

    this.convergenceService.UpdateEventSource(id, postData).subscribe(
      async () => {
        this.messageService.add({
          severity: "success",
          summary: "Completed",
          detail: "Event Source Updated Successfully.",
        });

        this.FetchAllEventSource();
        this.editingEventSource = false;
        this.eventSourceForm.reset();
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to update event source at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  async CreateEventType() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Creating Event Type...",
    });

    const postData: CreateEventTypeVM = {
      imageUrl: "",
      caption: this.eventTypeForm.get("Caption").value,
      description: this.eventTypeForm.get("Description").value,
      shortForm: this.eventTypeForm.get("ShortForm").value,
      categoryId: this.theConvergenceCategory.id,
      isPublic: this.isEventTypePublic,
    };

    this.fileStorageService.UploadFileFromDataUrl(this.eventTypeFile);
    this.fileStorageService.onUploadFinished.subscribe(
      (resp: CommonResponse<string>) => {
        if (resp.responseCode != "00") {
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail: resp.responseMsg,
          });
        } else {
          postData.imageUrl = resp.responseData;
          this._createEventType(postData);
        }
      },
      (error) => {
        console.log("Error while uploading file " + error);
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail: "ERR: Unable to upload image to storage",
        });
      }
    );
  }

  _createEventType(postData: CreateEventTypeVM) {
    this.convergenceService.CreateEventType(postData).subscribe(
      async () => {
        await this.messageService.add({
          severity: "success",
          summary: "Completed",
          detail: "Event Type Created Successfully!",
        });

        this.eventTypeForm.reset();
        this.FetchAllEventType();
        this.CloseEditingEventType();
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to create event type at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  CloseEditingEventType() {
    this.editingEventType = false;
    this.eventTypeForm.reset();
    this.eventTypeToEdit = null;
    this.eventTypeImageSrc = null;
    this.uploadingNewEventTypeImg = false;
    this.eventTypeFile = null;
    this.theConvergenceCategory = null;
    this.isEventTypePublic = false;
  }

  DeleteEventType(item: EventType) {
    this.confirmationService.confirm({
      message: "Are you sure you want to remove event type?",
      accept: () => {
        this.messageService.add({
          severity: "info",
          summary: "Notice",
          detail: "Removing event type...",
        });

        this.convergenceService.DeleteEventType(item.id).subscribe(
          async () => {
            this.messageService.add({
              severity: "success",
              summary: "Removed",
              detail: "Removed successfully",
            });

            this.fetchingEventType = true;
            const index = this.allEventTypes.indexOf(item);
            if (index > -1) {
              this.allEventTypes.splice(index, 1);
            }
            this.fetchingEventType = false;
          },
          (error) => {
            console.log("Error: " + JSON.stringify(error));
            this.messageService.add({
              severity: "error",
              summary: "Notice",
              detail:
                "Unable to remove event type at the moment.. Reason: [" + error
                  ? error.error.message
                  : "request failed - permission" + "]",
            });
          }
        );
      },
    });
  }

  EditEventType(item: EventType) {
    this.editingEventType = true;
    this.eventTypeForm.patchValue({
      Caption: item.caption,
      Description: item.description,
      ShortForm: item.shortForm,
    });
    this.eventTypeImageSrc = item.imageUrl;
    this.theConvergenceCategory = this.allConvergenceCategories.find(
      (x) => x.id == item.categoryId
    );
    this.isEventTypePublic = item.isPublic;
    this.eventTypeToEdit = item;

    this.formWrapper.nativeElement.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "start",
    });
  }

  async UpdateEventType() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Updating Event Type...",
    });

    const id = this.eventTypeToEdit.id;
    const postData: UpdateEventTypeVM = {
      caption: this.eventTypeForm.get("Caption").value,
      description: this.eventTypeForm.get("Description").value,
      shortForm: this.eventTypeForm.get("ShortForm").value,
      categoryId: this.theConvergenceCategory.id,
      isPublic: this.isEventTypePublic,
    };

    if (this.editingEventType && !this.uploadingNewEventTypeImg) {
      this._updateEventType(id, postData);
    } else {
      this.fileStorageService.UploadFileFromDataUrl(this.eventTypeFile);
      this.fileStorageService.onUploadFinished.subscribe(
        (resp: CommonResponse<string>) => {
          if (resp.responseCode != "00") {
            this.messageService.add({
              severity: "error",
              summary: "Notice",
              detail: resp.responseMsg,
            });
          } else {
            postData.imageUrl = resp.responseData;
            this._updateEventType(id, postData);
          }
        },
        (error) => {
          console.log("Error while uploading file " + error);
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail: "ERR: Unable to upload image to storage",
          });
        }
      );
    }
  }

  _updateEventType(id: number, postData: UpdateEventTypeVM) {
    this.convergenceService.UpdateEventType(id, postData).subscribe(
      async () => {
        this.messageService.add({
          severity: "success",
          summary: "Completed",
          detail: "Event Type Updated Successfully.",
        });

        this.FetchAllEventType();
        this.editingEventType = false;
        this.eventTypeForm.reset();
        this.CloseEditingEventType();
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to update event type at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  CreateRegion() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Creating Convergence Region...",
    });

    const postData: CreateConvergenceRegionVM = {
      name: this.regionForm.get("Name").value,
      description: this.regionForm.get("Description").value,
      statesInRegionIds: this.selectedStates.map((x) => x.id),
    };

    this.convergenceService.CreateConvergenceRegion(postData).subscribe(
      async () => {
        await this.messageService.add({
          severity: "success",
          summary: "Completed",
          detail: "Convergence Region Created Successfully!",
        });

        this.regionForm.reset();
        this.FetchAllConvergenceRegions();
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to create convergence region at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  UpdateRegion() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Updating Convergence Region...",
    });

    const id = this.regionToEdit.id;
    const postData: UpdateConvergenceRegionVM = {
      name: this.regionForm.get("Name").value,
      description: this.regionForm.get("Description").value,
      statesInRegionIds: this.selectedStates.map((x) => x.id),
    };

    this.convergenceService.UpdateConvergenceRegion(id, postData).subscribe(
      async () => {
        this.messageService.add({
          severity: "success",
          summary: "Completed",
          detail: "Convergence Region Updated Successfully.",
        });

        this.CloseEditingRegion();
        this.FetchAllConvergenceRegions();
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to update convergence region at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  CloseEditingRegion() {
    this.editingRegion = false;
    this.regionForm.reset();
    this.selectedStates = [];
    this.regionToEdit = null;
  }

  DeleteRegion(item: ConvergenceRegion) {
    this.confirmationService.confirm({
      message: "Are you sure you want to remove event source?",
      accept: () => {
        this.messageService.add({
          severity: "info",
          summary: "Notice",
          detail: "Removing...",
        });

        this.convergenceService.DeleteConvergenceRegion(item.id).subscribe(
          async () => {
            this.messageService.add({
              severity: "success",
              summary: "Removed",
              detail: "Removed successfully",
            });

            this.fetchingConvergenceRegions = true;
            const index = this.allConvergenceRegions.indexOf(item);
            if (index > -1) {
              this.allConvergenceRegions.splice(index, 1);
            }
            this.CloseEditingRegion();
            this.fetchingConvergenceRegions = false;
          },
          (error) => {
            console.log("Error: " + JSON.stringify(error));
            this.messageService.add({
              severity: "error",
              summary: "Notice",
              detail:
                "Unable to remove region at the moment.. Reason: [" + error
                  ? error.error.message
                  : "request failed - permission" + "]",
            });
          }
        );
      },
    });
  }

  EditRegion(item: ConvergenceRegion) {
    this.editingRegion = true;
    this.regionForm.patchValue({
      Name: item.name,
      Description: item.description,
    });
    this.selectedStates = this.allStates.filter(
      (x) => item.regionStates.find((y) => y.stateId == x.id) != null
    );
    this.regionToEdit = item;

    this.formWrapper.nativeElement.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "start",
    });
  }

  GetReportedByTypeLabel(identifier: number): string {
    let reportedByType = this.allReportedType.find((x) => x.id == identifier);
    if (reportedByType) return reportedByType.caption;

    return "N/A";
  }

  GetEventSourceLabel(identifier: number): string {
    let eventSource = this.allEventSources.find((x) => x.id == identifier);
    if (eventSource) return eventSource.caption;

    return "N/A";
  }

  CreateDefaultEventSource() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Creating Default Event Source...",
    });

    const postData: CreateDefaultEventSourceVM = {
      entityType: this.theReportedType.id,
      defaultEventSourceId: this.theEventSource.id,
    };

    this.convergenceService.CreateDefaultEventSource(postData).subscribe(
      async () => {
        await this.messageService.add({
          severity: "success",
          summary: "Completed",
          detail: "Default Event Source Created Successfully!",
        });

        this.CloseEditingDefaultSource();
        this.FetchAllDefaultEventSource();
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to create default event source at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  CloseEditingDefaultSource() {
    this.editingDefaultSource = false;
    this.theReportedType = null;
    this.theEventSource = null;
    this.defaultSourceForm.reset();
  }

  UpdateDefaultEventSource() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Updating Default Event Source...",
    });

    const id = this.defaultSourceToEdit.id;
    const postData: UpdateDefaultEventSourceVM = {
      defaultEventSourceId: this.theEventSource.id,
    };

    this.convergenceService.UpdateDefaultEventSource(id, postData).subscribe(
      async () => {
        this.messageService.add({
          severity: "success",
          summary: "Completed",
          detail: "Default Event Source Updated Successfully.",
        });

        this.CloseEditingDefaultSource();
        this.FetchAllDefaultEventSource();
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to update default event source at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  DeleteDefaultEventSource(item: ConvergenceEntityDefaultSource) {
    this.confirmationService.confirm({
      message: "Are you sure you want to remove default event source?",
      accept: () => {
        this.messageService.add({
          severity: "info",
          summary: "Notice",
          detail: "Removing default event source...",
        });

        this.convergenceService.DeleteDefaultEventSource(item.id).subscribe(
          async () => {
            this.messageService.add({
              severity: "success",
              summary: "Removed",
              detail: "Removed successfully",
            });

            this.fetchingDefaultEventSource = true;
            const index = this.allDefaultEventSources.indexOf(item);
            if (index > -1) {
              this.allDefaultEventSources.splice(index, 1);
            }
            this.fetchingDefaultEventSource = false;
          },
          (error) => {
            console.log("Error: " + JSON.stringify(error));
            this.messageService.add({
              severity: "error",
              summary: "Notice",
              detail:
                "Unable to remove efault event source at the moment.. Reason: [" +
                error
                  ? error.error.message
                  : "request failed - permission" + "]",
            });
          }
        );
      },
    });
  }

  EditDefaultEventSource(item: ConvergenceEntityDefaultSource) {
    this.editingDefaultSource = true;
    this.theReportedType = this.allReportedType.find(
      (x) => x.id == item.entityType
    );
    this.theEventSource = this.allEventSources.find(
      (x) => x.id == item.defaultEventSourceId
    );
    this.defaultSourceToEdit = item;

    this.formWrapper.nativeElement.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "start",
    });
  }

  async CreateConvergenceAdvisory() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Creating Advisory...",
    });

    const postData: CreateAdvisoryVM = {
      imageUrl: "",
      caption: this.advisoryForm.get("Caption").value,
      description: this.advisoryForm.get("Description").value,
    };

    this.fileStorageService.UploadFileFromDataUrl(this.advisoryFile);
    this.fileStorageService.onUploadFinished.subscribe(
      (resp: CommonResponse<string>) => {
        if (resp.responseCode != "00") {
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail: resp.responseMsg,
          });
        } else {
          postData.imageUrl = resp.responseData;
          this._createConvergenceAdvisory(postData);
        }
      },
      (error) => {
        console.log("Error while uploading file " + error);
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail: "ERR: Unable to upload image to storage",
        });
      }
    );
  }

  _createConvergenceAdvisory(postData: CreateAdvisoryVM) {
    this.convergenceService.CreateAdvisory(postData).subscribe(
      async () => {
        await this.messageService.add({
          severity: "success",
          summary: "Completed",
          detail: "Advisory Created Successfully!",
        });

        this.CloseEditingAdvisory();
        this.FetchAllAdvisories();
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to create advisory at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  CloseEditingAdvisory() {
    this.editingAdvisory = false;
    this.advisoryForm.reset();
    this.convergenceAdvisoryToEdit = null;
    this.advisoryImageSrc = null;
    this.uploadingNewAdvisoryImg = false;
    this.advisoryFile = null;
  }

  async UpdateAdvisory() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Updating Advisory...",
    });

    const id = this.convergenceAdvisoryToEdit.id;
    const postData: UpdateAdvisoryVM = {
      caption: this.advisoryForm.get("Caption").value,
      description: this.advisoryForm.get("Description").value,
    };

    if (this.editingAdvisory && !this.uploadingNewAdvisoryImg) {
      this._updateAdvisory(id, postData);
    } else {
      this.fileStorageService.UploadFileFromDataUrl(this.advisoryFile);
      this.fileStorageService.onUploadFinished.subscribe(
        (resp: CommonResponse<string>) => {
          if (resp.responseCode != "00") {
            this.messageService.add({
              severity: "error",
              summary: "Notice",
              detail: resp.responseMsg,
            });
          } else {
            postData.imageUrl = resp.responseData;
            this._updateAdvisory(id, postData);
          }
        },
        (error) => {
          console.log("Error while uploading file " + error);
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail: "ERR: Unable to upload image to storage",
          });
        }
      );
    }
  }

  _updateAdvisory(id: number, postData: UpdateAdvisoryVM) {
    this.convergenceService.UpdateAdvisory(id, postData).subscribe(
      async () => {
        this.messageService.add({
          severity: "success",
          summary: "Completed",
          detail: "Advisory Updated Successfully.",
        });

        this.CloseEditingAdvisory();
        this.FetchAllAdvisories();
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to update advisory at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  DeleteAdvisory(item: ConvergenceAdvisory) {
    this.confirmationService.confirm({
      message: "Are you sure you want to remove advisory?",
      accept: () => {
        this.messageService.add({
          severity: "info",
          summary: "Notice",
          detail: "Removing advisory...",
        });

        this.convergenceService.DeleteAdvisory(item.id).subscribe(
          async () => {
            this.messageService.add({
              severity: "success",
              summary: "Removed",
              detail: "Removed successfully",
            });

            this.fetchingAdvisories = true;
            const index = this.allConvergenceAdvisories.indexOf(item);
            if (index > -1) {
              this.allConvergenceAdvisories.splice(index, 1);
            }
            this.fetchingAdvisories = false;
          },
          (error) => {
            console.log("Error: " + JSON.stringify(error));
            this.messageService.add({
              severity: "error",
              summary: "Notice",
              detail:
                "Unable to remove advisory at the moment.. Reason: [" + error
                  ? error.error.message
                  : "request failed - permission" + "]",
            });
          }
        );
      },
    });
  }

  EditAdvisory(item: ConvergenceAdvisory) {
    this.editingAdvisory = true;
    this.advisoryForm.patchValue({
      Caption: item.caption,
      Description: item.description,
    });
    this.advisoryImageSrc = item.imageUrl;
    this.convergenceAdvisoryToEdit = item;

    this.formWrapper.nativeElement.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "start",
    });
  }

  onEventTypeFileSelect(event) {
    if (!this.checkMimeType(event) || !this.checkFileSize(event)) {
      return;
    }

    this.uploadingNewEventTypeImg = true;
    const reader = new FileReader();
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      this.eventTypeFile = event.target.files[0];
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.eventTypeImageSrc = reader.result as string;
      };
    }
  }

  checkMimeType = (event: EventInput): boolean => {
    const files = event.target.files[0];
    // list allow mime type
    const types = ["image/png", "image/jpeg", "image/jpg", "image/gif"];
    // loop access array
    if (!files) {
      this.messageService.add({
        severity: "error",
        summary: "Failed",
        detail: "Please select an Image file",
      });
      return false;
    }
    // compare file type find doesn't match
    if (types.every((type) => files.type !== type)) {
      // create error message and assign to container
      this.messageService.add({
        severity: "error",
        summary: "Failed",
        detail: "Image format not supported",
      });
      return false;
    }
    return true;
  };

  checkFileSize = (event: EventInput) => {
    const files = event.target.files[0];
    if (!files) return true;
    const size = 400000;
    if (files.size > size) {
      event.target.value = null;
      this.messageService.add({
        severity: "error",
        summary: "Failed",
        detail: "Image is too large, please pick a smaller file",
      });
      return false;
    }
    return true;
  };

  onAdvisoryFileSelect(event) {
    if (!this.checkMimeType(event) || !this.checkFileSize(event)) {
      return;
    }

    this.uploadingNewAdvisoryImg = true;
    const reader = new FileReader();
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      this.advisoryFile = event.target.files[0];
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.advisoryImageSrc = reader.result as string;
      };
    }
  }

  CreateCategory() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Creating Category...",
    });

    const postData: CreateConvergenceCategoryVM = {
      caption: this.categoryForm.get("Caption").value,
      description: this.categoryForm.get("Description").value,
      isPublic: this.isCategoryPublic,
    };

    this.convergenceService.CreateConvergenceCategory(postData).subscribe(
      async () => {
        await this.messageService.add({
          severity: "success",
          summary: "Completed",
          detail: "Category Created Successfully!",
        });

        this.CloseEditingCategory();
        this.FetchAllCategories();
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to create category at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  CloseEditingCategory() {
    this.editingCategory = false;
    this.isCategoryPublic = false;
    this.categoryForm.reset();
    this.convergenceCategoryToEdit = null;
  }

  UpdateCategory() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Updating Category...",
    });

    const id = this.convergenceCategoryToEdit.id;
    const postData: UpdateConvergenceCategoryVM = {
      caption: this.categoryForm.get("Caption").value,
      description: this.categoryForm.get("Description").value,
      isPublic: this.isCategoryPublic,
    };

    this.convergenceService.UpdateConvergenceCategory(id, postData).subscribe(
      async () => {
        this.messageService.add({
          severity: "success",
          summary: "Completed",
          detail: "Category Updated Successfully.",
        });

        this.CloseEditingCategory();
        this.FetchAllCategories();
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to update category at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  DeleteCategory(item: ConvergenceCategory) {
    this.confirmationService.confirm({
      message: "Are you sure you want to remove category?",
      accept: () => {
        this.messageService.add({
          severity: "info",
          summary: "Notice",
          detail: "Removing category...",
        });

        this.convergenceService.DeleteConvergenceCategory(item.id).subscribe(
          async () => {
            this.messageService.add({
              severity: "success",
              summary: "Removed",
              detail: "Removed successfully",
            });

            this.fetchingCategories = true;
            const index = this.allConvergenceCategories.indexOf(item);
            if (index > -1) {
              this.allConvergenceCategories.splice(index, 1);
            }
            this.fetchingCategories = false;
          },
          (error) => {
            console.log("Error: " + JSON.stringify(error));
            this.messageService.add({
              severity: "error",
              summary: "Notice",
              detail:
                "Unable to remove category at the moment.. Reason: [" + error
                  ? error.error.message
                  : "request failed - permission" + "]",
            });
          }
        );
      },
    });
  }

  EditCategory(item: ConvergenceCategory) {
    this.editingCategory = true;
    this.categoryForm.patchValue({
      Caption: item.caption,
      Description: item.description,
    });
    this.isCategoryPublic = item.isPublic;
    this.convergenceCategoryToEdit = item;

    this.formWrapper.nativeElement.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "start",
    });
  }

  GetCategoryLabel(identifier: number): string {
    let categ = this.allConvergenceCategories.find((x) => x.id == identifier);
    if (categ) return categ.caption;

    return "N/A";
  }
}
