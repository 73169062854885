import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { MessageService, Message, ConfirmationService } from "primeng/api";
import { BreadcrumbService } from "src/app/breadcrumb.service";
import { Supplier } from "src/app/interfaces/armada";
import { DeployedGuard } from "src/app/interfaces/employment";
import {
  ExpenseBatchRequest,
  ExpenseBatchRequestComment,
  ExpenseBatchRequestItem,
  ExpenseBatchRequestLogStatus,
  ExpenseLine,
  LineManagerExpenseBatchRequestActionVM,
  UserExpenseRequestType,
} from "src/app/interfaces/finance-expense";
import { CustomerDivision, User } from "src/app/interfaces/home";
import { CustomerDivisionService } from "src/app/services/customer-division.service";
import { FinanceExpenseService } from "src/app/services/finance-expense.service";
import { FireBaseAuthService } from "src/app/services/fire-base-auth.service";
import { GmaEmploymentService } from "src/app/services/gma-employment.service";
import { ServiceCategoryService } from "src/app/services/service-category.service";
import { SupplierService } from "src/app/services/supplier.service";
import { UserService } from "src/app/services/user.service";
import domtoimage from "dom-to-image";
import { jsPDF } from "jspdf";

@Component({
  selector: "app-expense-line-approval",
  templateUrl: "./expense-line-approval.component.html",
  styleUrls: ["./expense-line-approval.component.scss"],
  providers: [MessageService],
})
export class ExpenseLineApprovalComponent implements OnInit {
  @ViewChild("formWrapper") public formWrapper: ElementRef;
  msg: Message[] = [];
  msg2: Message[] = [];
  fetchingPendingApprovals: boolean;
  allPendingApprovals: ExpenseBatchRequest[] = [];
  requestDate: string = new Date(Date.now()).toDateString();
  requestType: string = "Staff";
  requestSubject: string = "Vendor Payment";
  requestRaisedBy: string = "Omoshola Yusuf";
  requestSituation: string = "Pay";
  requestJustification: string = "Pay";
  requestLineItems: ExpenseBatchRequestItem[];
  requestTotalAmt: number = 15000000;
  requestTotalVAT: number = 16000000;
  requestTotalAmtValue: number = 31000000;
  allUsers: User[];
  showDetails: boolean;
  batchInView: ExpenseBatchRequest;
  allRequestTypes: {
    key: number;
    value: string;
  }[];
  allSuppliers: Supplier[];
  allClients: CustomerDivision[];
  allOperatives: DeployedGuard[];
  allExpenseLines: ExpenseLine[];
  openMemoDialogue: boolean;
  currentDate: string = new Date(Date.now()).toDateString();
  requester: string = "Requester";
  subject: string = "Subject";
  situation: string =
    "father abraham had many sons father abraham had many sons father abraham had many sonsfather abraham had many sonsfather abraham had many sonsfather abraham had many sonsfather abraham had many sonsfather abraham had many sonsfather abraham had many sonsfather abraham had many sons father abraham had many sons";
  justification: string =
    "father abraham had many sons father abraham had many sons father abraham had many sonsfather abraham had many sonsfather abraham had many sonsfather abraham had many sonsfather abraham had many sonsfather abraham had many sonsfather abraham had many sonsfather abraham had many sons father abraham had many sons";
  batchExpenseLines: ExpenseBatchRequestItem[] = [];
  request: string =
    "In view of the above, kindly approve 1,000,000 (one million naira only) being payment for Bayo before Oct 2022 and Nov 2022";
  batchApprovals: {
    office: string;
    officer: string;
  }[] = [
    {
      office: "Line Manager",
      officer: "Omoshola Yusuf",
    },
    {
      office: "Batch Approval",
      officer: "Omoshola Yusuf",
    },
  ];
  currentLoggedUsername: string = "CEO";
  loggedInUser: User;
  openCommentSection: boolean;
  loadingComments: boolean;
  comments: ExpenseBatchRequestComment[] = [];
  newBatchComment: string;
  openDocDialogue: boolean;
  batchDocs: {
    caption: string;
    documents: string;
  }[] = [];
  batchCommentsDropped: {
    batchId: number;
    hasDroppedComment: boolean;
    comment: string;
  }[] = [];

  constructor(
    public financeExpenseService: FinanceExpenseService,
    public userService: UserService,
    public serviceCategoryService: ServiceCategoryService,
    public supplierService: SupplierService,
    public clientService: CustomerDivisionService,
    public employmentService: GmaEmploymentService,
    public messageService: MessageService,
    private breadcrumbService: BreadcrumbService,
    public confirmationService: ConfirmationService,
    private fireBaseAuthService: FireBaseAuthService
  ) {}

  ngOnInit(): void {
    this.breadcrumbService.setItems([
      {
        label: "Organization",
        routerLink: ["/home/ogranization"],
      },
      {
        label: "Expense Approval",
        routerLink: ["/home/organization/expense-line-approval"],
      },
    ]);

    this.allRequestTypes = [
      {
        key: UserExpenseRequestType.Supplier,
        value: "Supplier",
      },
      {
        key: UserExpenseRequestType.Staff,
        value: "Staff",
      },
      {
        key: UserExpenseRequestType.Customer,
        value: "Customer",
      },
      {
        key: UserExpenseRequestType.Operatives,
        value: "Operative",
      },
      {
        key: UserExpenseRequestType.Miscellanous,
        value: "Miscellanous",
      },
    ];

    this.loggedInUser = this.fireBaseAuthService.authUserProfile;

    this.FetchAllUsers();
    this.FetchAllClients();
    this.FetchAllSuppliers();
    this.FetchAllOperatives();
    this.FetchAllExpenseLines();
    this.ResetMessageToasters();
  }

  ResetMessageToasters() {
    this.msg = [];
    this.msg.push({
      severity: "info",
      summary: "Notice:",
      detail:
        "You have " + this.allPendingApprovals.length + " approvals pending.",
    });

    if (this.batchInView) {
      this.msg2 = [];
      this.msg2.push({
        severity: "info",
        summary: "Notice:",
        detail:
          "Request details for " +
          this.batchInView.subject +
          " with BTCH-" +
          this.batchInView.id +
          " for " +
          this.GetEntityName(this.batchInView) +
          ".",
      });
    }
  }

  async FetchPendingBatchRequests() {
    this.fetchingPendingApprovals = true;
    this.financeExpenseService
      .GetPendingLineApprovalExpenseBatchRequests()
      .subscribe(
        async (data) => {
          if (data.responseCode != "00") {
            this.messageService.add({
              severity: "error",
              summary: "Notice",
              detail: data.responseMsg,
            });
            this.ResetMessageToasters();
            return;
          }

          this.allPendingApprovals = data.responseData;
          this.fetchingPendingApprovals = false;
          if (this.allPendingApprovals.length > 0)
            this.LoadBatchCommentsInfo(
              this.allPendingApprovals.map((x) => x.id)
            );
          this.ResetMessageToasters();
        },
        (error) => {
          console.log("Error: " + JSON.stringify(error));
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail:
              "Unable to fetch all operatives at the moment.. Reason: [" +
              (error ? error.error.message : "request failed - permission") +
              "]",
          });
          this.ResetMessageToasters();
          this.fetchingPendingApprovals = false;
        }
      );
  }

  async FetchAllUsers() {
    this.userService.allUser().subscribe(
      async (res) => {
        var data = res.responseData;
        this.allUsers = data;
        // this.allUsers = [];
        // data.forEach((user) => {
        //   user.fullName = user.lastName + " " + user.firstName;
        //   this.allUsers.push(user);
        // });

        this.FetchPendingBatchRequests();
      },
      (err) => {
        console.log(err);
        this.messageService.add({
          severity: "error",
          summary: "Failed",
          detail: err ?? "Some errors occurred",
        });
        this.ResetMessageToasters();
      }
    );
  }

  async FetchAllSuppliers() {
    this.supplierService.getAll().subscribe(
      async (data) => {
        this.allSuppliers = data.responseData;
      },
      (error) => {
        this.messageService.add({
          severity: "error",
          summary: "Failed",
          detail: error ?? "Some errors occurred",
        });
        this.ResetMessageToasters();
      }
    );
  }

  async FetchAllClients() {
    this.clientService.allCustomerDivision().subscribe(
      async (data) => {
        this.allClients = data.responseData;
      },
      (error) => {
        this.messageService.add({
          severity: "error",
          summary: "Failed",
          detail: error ?? "Some errors occurred",
        });
        this.ResetMessageToasters();
      }
    );
  }

  async FetchAllOperatives() {
    this.employmentService.GetAllOperativesDataOnly().subscribe(
      async (data) => {
        this.allOperatives = [];
        data.forEach((x) => {
          x.guardName =
            x.jobApplication.personalInformation.lastname +
            " " +
            x.jobApplication.personalInformation.firstname +
            " " +
            x.jobApplication.personalInformation.othername;
          this.allOperatives.push(x);
        });
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all operatives at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
        this.ResetMessageToasters();
      }
    );
  }

  async FetchAllExpenseLines() {
    this.financeExpenseService.GetAllExpenseLines().subscribe(
      async (data) => {
        if (data.responseCode != "00") {
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail: data.responseMsg,
          });
          this.ResetMessageToasters();
          return;
        }

        this.allExpenseLines = data.responseData;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all expense lines at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
        this.ResetMessageToasters();
      }
    );
  }

  ViewBatchDetails(item: ExpenseBatchRequest) {
    this.requestDate = item.createdAt.toString().substring(0, 10);
    this.requestType = this.allRequestTypes.find(
      (x) => x.key == item.requestType
    ).value;
    this.requestSubject = item.subject;
    this.requestJustification = item.justification;
    this.requestSituation = item.situation;
    this.requestRaisedBy = this.GetEntityName(item);
    this.requestLineItems = item.lineItems;
    this.requestTotalAmt = 0;
    this.requestTotalVAT = 0;
    this.requestTotalAmtValue = 0;
    this.requestLineItems.forEach((lineItem) => {
      this.requestTotalAmt += lineItem.amount;
      this.requestTotalVAT += lineItem.vatAmount;
      this.requestTotalAmtValue += lineItem.amount + lineItem.vatAmount;
    });
    this.batchInView = item;
    this.showDetails = true;
    this.ResetMessageToasters();
  }

  OpenBatchComments(item: ExpenseBatchRequest) {
    this.openCommentSection = true;
    this.batchInView = item;
    this.showDetails = false;
    item.isUnread = false;
    this.LoadBatchComments();
  }

  ReturnBatch(item: ExpenseBatchRequest) {
    this.confirmationService.confirm({
      message:
        "You are about to return this expense batch request. Do you still wish to proceed?",
      accept: () => {
        this.messageService.add({
          severity: "info",
          summary: "Notice",
          detail: "Returning expense batch request...",
        });
        this.ResetMessageToasters();

        if (
          this.batchCommentsDropped.find((x) => x.batchId == item.id) == null
        ) {
          this.messageService.add({
            severity: "error",
            summary: "Policy Violated",
            detail: "You must leave a batch comment to return this batch.",
          });
          this.ResetMessageToasters();
          return;
        }

        const postData: LineManagerExpenseBatchRequestActionVM = {
          action: ExpenseBatchRequestLogStatus.Returned,
          batchRequestId: item.id,
        };

        this.financeExpenseService
          .LineManagerExpenseBatchRequestAction(postData)
          .subscribe(
            async (data) => {
              if (data.responseCode != "00") {
                this.messageService.add({
                  severity: "error",
                  summary: "Notice",
                  detail: data.responseMsg,
                });
                this.ResetMessageToasters();
                return;
              }

              await this.messageService.add({
                severity: "success",
                summary: "Removed",
                detail: "Expense Batch Request Returned Successfully",
              });
              this.ResetMessageToasters();

              const index = this.allPendingApprovals.indexOf(item);
              if (index > -1) {
                this.allPendingApprovals.splice(index, 1);
              }
              this.showDetails = false;
              this.FetchPendingBatchRequests();
            },
            (error) => {
              console.log("Error: " + JSON.stringify(error));
              this.messageService.add({
                severity: "error",
                summary: "Notice",
                detail:
                  "Unable to return expense batch request at the moment.. Reason: [" +
                  error
                    ? error.error.message
                    : "request failed - permission" + "]",
              });
              this.ResetMessageToasters();
            }
          );
      },
    });
  }

  DeclineBatch(item: ExpenseBatchRequest) {
    this.confirmationService.confirm({
      message:
        "You are about to decline this expense batch request. Do you still wish to proceed?",
      accept: () => {
        this.messageService.add({
          severity: "info",
          summary: "Notice",
          detail: "Declining expense batch request...",
        });
        this.ResetMessageToasters();

        const postData: LineManagerExpenseBatchRequestActionVM = {
          action: ExpenseBatchRequestLogStatus.Declined,
          batchRequestId: item.id,
        };

        this.financeExpenseService
          .LineManagerExpenseBatchRequestAction(postData)
          .subscribe(
            async (data) => {
              if (data.responseCode != "00") {
                this.messageService.add({
                  severity: "error",
                  summary: "Notice",
                  detail: data.responseMsg,
                });
                this.ResetMessageToasters();
                return;
              }

              await this.messageService.add({
                severity: "success",
                summary: "Removed",
                detail: "Expense Batch Request Declined Successfully",
              });
              this.ResetMessageToasters();

              const index = this.allPendingApprovals.indexOf(item);
              if (index > -1) {
                this.allPendingApprovals.splice(index, 1);
              }
              this.showDetails = false;
              this.FetchPendingBatchRequests();
            },
            (error) => {
              console.log("Error: " + JSON.stringify(error));
              this.messageService.add({
                severity: "error",
                summary: "Notice",
                detail:
                  "Unable to decline expense batch request at the moment.. Reason: [" +
                  error
                    ? error.error.message
                    : "request failed - permission" + "]",
              });
              this.ResetMessageToasters();
            }
          );
      },
    });
  }

  ApproveBatch(item: ExpenseBatchRequest) {
    this.confirmationService.confirm({
      message:
        "You are about to approve this expense batch request. Do you still wish to proceed?",
      accept: () => {
        this.messageService.add({
          severity: "info",
          summary: "Notice",
          detail: "Approving expense batch request...",
        });
        this.ResetMessageToasters();

        const postData: LineManagerExpenseBatchRequestActionVM = {
          action: ExpenseBatchRequestLogStatus.Approved,
          batchRequestId: item.id,
        };

        this.financeExpenseService
          .LineManagerExpenseBatchRequestAction(postData)
          .subscribe(
            async (data) => {
              if (data.responseCode != "00") {
                this.messageService.add({
                  severity: "error",
                  summary: "Notice",
                  detail: data.responseMsg,
                });
                this.ResetMessageToasters();
                return;
              }

              await this.messageService.add({
                severity: "success",
                summary: "Removed",
                detail: "Expense Batch Request Approved Successfully",
              });
              this.ResetMessageToasters();

              const index = this.allPendingApprovals.indexOf(item);
              if (index > -1) {
                this.allPendingApprovals.splice(index, 1);
              }
              this.showDetails = false;
              this.FetchPendingBatchRequests();
            },
            (error) => {
              console.log("Error: " + JSON.stringify(error));
              this.messageService.add({
                severity: "error",
                summary: "Notice",
                detail:
                  "Unable to approve expense batch request at the moment.. Reason: [" +
                  error
                    ? error.error.message
                    : "request failed - permission" + "]",
              });
              this.ResetMessageToasters();
            }
          );
      },
    });
  }

  ViewRequestAttachments() {
    this.openDocDialogue = true;
    this.batchDocs = [];
    this.batchDocs.push({
      caption: "Batch " + this.batchInView.subject,
      documents: this.batchInView.attachments,
    });
    this.batchInView.lineItems.forEach((lineItem) =>
      this.batchDocs.push({
        caption:
          lineItem.description +
          "-" +
          this.GetExpenseLineCaption(lineItem.expenseLineId),
        documents: lineItem.attachments,
      })
    );
  }

  GetUsername(userId: number): string {
    let sf = this.allUsers.find((x) => x.id == userId);
    if (sf)
      return (
        sf.lastName.toUpperCase() +
        " " +
        sf.firstName.toUpperCase() +
        " " +
        (sf.otherName == null ? "" : sf.otherName.toUpperCase())
      );

    return "N/A";
  }

  GetUserImage(userId: number): string {
    let sf = this.allUsers.find((x) => x.id == userId);
    if (sf) return sf.imageUrl;

    return "assets/no-file.png";
  }

  GetEntityName(item: ExpenseBatchRequest): string {
    if (item.requestType == UserExpenseRequestType.Customer) {
      let customer = this.allClients.find((x) => item.clientId);
      if (customer) return customer.divisionName;
    } else if (item.requestType == UserExpenseRequestType.Operatives) {
      let op = this.allOperatives.find((x) => x.id == item.operativeId);
      if (op)
        return (
          op.jobApplication.personalInformation.lastname +
          " " +
          op.jobApplication.personalInformation.firstname +
          " " +
          op.jobApplication.personalInformation.othername
        );
    } else if (item.requestType == UserExpenseRequestType.Staff) {
      return this.GetUsername(item.staffId);
    } else if (item.requestType == UserExpenseRequestType.Supplier) {
      let suppl = this.allSuppliers.find((x) => x.id == item.supplierId);
      if (suppl) return suppl.supplierName;
    } else if (item.requestType == UserExpenseRequestType.Miscellanous) {
      return "Miscellaneous";
    }

    return "N/A";
  }

  GetExpenseLineCaption(identifier: number): string {
    let expenseLine = this.allExpenseLines.find((x) => x.id == identifier);
    if (expenseLine) return expenseLine.caption;

    return "N/A";
  }

  ViewMemoFormat(item: ExpenseBatchRequest) {
    this.openMemoDialogue = true;
    this.showDetails = false;
    this.currentLoggedUsername =
      this.loggedInUser.lastName.toUpperCase() +
      " " +
      this.loggedInUser.firstName.toUpperCase() +
      " " +
      (this.loggedInUser.otherName == null
        ? ""
        : this.loggedInUser.otherName.toUpperCase());

    let requesterInfo = this.allUsers.find((x) => x.id == item.createdById);
    if (requesterInfo) {
      this.requester =
        requesterInfo.lastName.toUpperCase() +
        " " +
        requesterInfo.firstName.toUpperCase() +
        " " +
        (requesterInfo.otherName == null
          ? ""
          : requesterInfo.otherName.toUpperCase());
    } else this.requester = "N/A";
    this.currentDate = item.createdAt.toString().substring(0, 10);
    this.subject = item.subject;
    this.situation = item.situation;
    this.justification = item.justification;
    this.batchExpenseLines = item.lineItems;
    let sumTotal = 0;
    this.batchExpenseLines.forEach((x) => (sumTotal += x.amount + x.vatAmount));
    this.request =
      "In view of the above, kindly approve N" +
      sumTotal.toLocaleString() +
      " (" +
      this.NumInWords(sumTotal) +
      " naira only) being payment for the itemized above to " +
      this.GetEntityName(item) +
      "." +
      ". Request created by " +
      this.GetUsername(item.createdById);
    localStorage.setItem("memoName", item.subject);
    this.batchApprovals = [];
    item.picks
      .filter((x) => x.status == ExpenseBatchRequestLogStatus.Approved)
      .forEach((log) =>
        this.batchApprovals.push({
          office: log.approvingOfficeName,
          officer: this.GetUsername(log.approvingOfficerId),
        })
      );
  }

  HideMemoDialog() {
    this.openMemoDialogue = false;
    this.currentLoggedUsername = "";
    this.requester = "";
    this.currentDate = new Date(Date.now()).toDateString();
    this.subject = "";
    this.situation = "";
    this.justification = "";
    this.batchExpenseLines = [];
    this.request = "";
    this.batchApprovals = [];
  }

  NumInWords(number: number): string {
    const first = [
      "",
      "one ",
      "two ",
      "three ",
      "four ",
      "five ",
      "six ",
      "seven ",
      "eight ",
      "nine ",
      "ten ",
      "eleven ",
      "twelve ",
      "thirteen ",
      "fourteen ",
      "fifteen ",
      "sixteen ",
      "seventeen ",
      "eighteen ",
      "nineteen ",
    ];
    const tens = [
      "",
      "",
      "twenty",
      "thirty",
      "forty",
      "fifty",
      "sixty",
      "seventy",
      "eighty",
      "ninety",
    ];
    const mad = ["", "thousand", "million", "billion", "trillion"];
    let word = "";

    for (let i = 0; i < mad.length; i++) {
      let tempNumber = number % (100 * Math.pow(1000, i));
      if (Math.floor(tempNumber / Math.pow(1000, i)) !== 0) {
        if (Math.floor(tempNumber / Math.pow(1000, i)) < 20) {
          word =
            first[Math.floor(tempNumber / Math.pow(1000, i))] +
            mad[i] +
            " " +
            word;
        } else {
          word =
            tens[Math.floor(tempNumber / (10 * Math.pow(1000, i)))] +
            "-" +
            first[Math.floor(tempNumber / Math.pow(1000, i)) % 10] +
            mad[i] +
            " " +
            word;
        }
      }

      tempNumber = number % Math.pow(1000, i + 1);
      if (Math.floor(tempNumber / (100 * Math.pow(1000, i))) !== 0)
        word =
          first[Math.floor(tempNumber / (100 * Math.pow(1000, i)))] +
          "hunderd " +
          word;
    }
    return word;
  }

  PdfDownloadPage() {
    let memoName = localStorage.getItem("memoName");
    var node = document.getElementById("memoDiv");
    var img;
    var filename;
    var newImage;
    domtoimage
      .toPng(node, { bgcolor: "#fff" })
      .then(function (dataUrl) {
        img = new Image();
        img.src = dataUrl;
        newImage = img.src;
        img.onload = function () {
          var pdfWidth = img.width;
          var pdfHeight = img.height;
          // FileSaver.saveAs(dataUrl, 'my-pdfimage.png'); // Save as Image
          var doc;
          if (pdfWidth > pdfHeight) {
            doc = new jsPDF("l", "px", [pdfWidth, pdfHeight]);
          } else {
            doc = new jsPDF("p", "px", [pdfWidth, pdfHeight]);
          }
          var width = doc.internal.pageSize.getWidth();
          var height = doc.internal.pageSize.getHeight();

          doc.addImage(newImage, "PNG", 10, 10, width, height);
          filename = memoName + " Memo" + ".pdf";
          doc.save(filename);
        };
      })
      .catch(function (error) {
        // Error Handling
      });
  }

  LoadBatchComments() {
    this.loadingComments = true;
    this.financeExpenseService
      .GetExpenseBatchComments(this.batchInView.id)
      .subscribe(
        async (data) => {
          if (data.responseCode != "00") {
            this.messageService.add({
              severity: "error",
              summary: "Notice",
              detail: data.responseMsg,
            });
            this.ResetMessageToasters();
            return;
          }

          this.comments = data.responseData;
          this.loadingComments = false;
        },
        (error) => {
          console.log("Error: " + JSON.stringify(error));
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail:
              "Unable to fetch batch comments at the moment.. Reason: [" +
              error.error.message +
              "]",
          });
          this.loadingComments = false;
        }
      );
  }

  HideCommentBox() {
    this.openCommentSection = false;
    this.comments = [];
    this.loadingComments = false;
    this.batchInView = null;
  }

  PostComment() {
    this.financeExpenseService
      .CreateBatchComment({
        batchId: this.batchInView.id,
        comment: this.newBatchComment,
        commenator: this.GetUsername(this.loggedInUser.id),
      })
      .subscribe(
        async (data) => {
          if (data.responseCode != "00") {
            this.messageService.add({
              severity: "error",
              summary: "Notice",
              detail: data.responseMsg,
            });
            this.ResetMessageToasters();
            return;
          }

          this.messageService.add({
            severity: "success",
            summary: "Completed",
            detail: "Comment Posted Successfully...",
          });

          if (
            this.batchCommentsDropped.find(
              (x) => x.batchId == this.batchInView.id
            ) == null
          ) {
            this.batchCommentsDropped.push({
              batchId: this.batchInView.id,
              hasDroppedComment: true,
              comment: this.newBatchComment,
            });
          }
          this.newBatchComment = null;
          this.LoadBatchComments();
          this.ResetMessageToasters();
        },
        (error) => {
          console.log("Error: " + JSON.stringify(error));
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail:
              "Unable to post batch approval comment at the moment.. Reason: [" +
              error.error.message +
              "]",
          });
          this.ResetMessageToasters();
        }
      );
  }

  GetCommentAuthor(authorId?: number): string {
    if (authorId && authorId != this.loggedInUser.id)
      return this.GetUsername(authorId);
    else return "Me";
  }

  DeleteComment(item: ExpenseBatchRequestComment) {
    this.confirmationService.confirm({
      message: "Are you sure you want to remove comment?",
      accept: () => {
        this.messageService.add({
          severity: "info",
          summary: "Notice",
          detail: "Removing Comment...",
        });
        this.ResetMessageToasters();

        this.financeExpenseService.DeleteBatchComment(item.id).subscribe(
          async (data) => {
            if (data.responseCode != "00") {
              this.messageService.add({
                severity: "error",
                summary: "Notice",
                detail: data.responseMsg,
              });
              this.ResetMessageToasters();
              return;
            }

            this.messageService.add({
              severity: "success",
              summary: "Completed",
              detail: "Removed Comment Successfully...",
            });
            this.newBatchComment = null;
            this.LoadBatchComments();
            this.ResetMessageToasters();
          },
          (error) => {
            console.log("Error: " + JSON.stringify(error));
            this.messageService.add({
              severity: "error",
              summary: "Notice",
              detail:
                "Unable to delete batch approval comment at the moment.. Reason: [" +
                error.error.message +
                "]",
            });
            this.ResetMessageToasters();
          }
        );
      },
    });
  }

  LoadBatchCommentsInfo(batchIds: number[]) {
    //Simply return unread as true
    this.financeExpenseService
      .CheckBatchComentReadReceipts({ batchIds: batchIds })
      .subscribe(
        async (data) => {
          if (data.responseCode != "00") {
            this.messageService.add({
              severity: "error",
              summary: "Notice",
              detail: data.responseMsg,
            });
            this.ResetMessageToasters();
            return;
          }

          this.allPendingApprovals.forEach((batch) => {
            let isUnread = data.responseData.find((x) => x == batch.id);
            if (isUnread) batch.isUnread = true;
          });
        },
        (error) => {
          console.log("Error: " + JSON.stringify(error));
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail:
              "Unable to check batch approval comment receipts at the moment.. Reason: [" +
              error.error.message +
              "]",
          });
          this.ResetMessageToasters();
        }
      );
  }

  GetRequestTypeCaption(requestTypeId: number): string {
    let reqType = this.allRequestTypes.find((x) => x.key == requestTypeId);
    if (reqType) return reqType.value;
    return "N/A";
  }

  HideDocDialog() {
    this.openDocDialogue = false;
    this.batchDocs = [];
  }
}
