import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import domtoimage from "dom-to-image";
import { jsPDF } from "jspdf";
import { MessageService, Message, ConfirmationService } from "primeng/api";
import { BreadcrumbService } from "src/app/breadcrumb.service";
import { Supplier } from "src/app/interfaces/armada";
import { DeployedGuard } from "src/app/interfaces/employment";
import {
  ExpenseBatchRequest,
  ExpenseBatchRequestItem,
  ExpenseBatchRequestLogStatus,
  ExpenseLine,
  GetPayablesConfigurationsVM,
  PostExpenseBatchPaymentsVM,
  UserExpenseRequestType,
} from "src/app/interfaces/finance-expense";
import {
  User,
  CustomerDivision,
  Account,
  ControlAccount,
} from "src/app/interfaces/home";
import { AccountService } from "src/app/services/account.service";
import { CustomerDivisionService } from "src/app/services/customer-division.service";
import { DivisionService } from "src/app/services/division.service";
import { FinanceExpenseService } from "src/app/services/finance-expense.service";
import { GmaEmploymentService } from "src/app/services/gma-employment.service";
import { ServiceCategoryService } from "src/app/services/service-category.service";
import { SupplierService } from "src/app/services/supplier.service";
import { UserService } from "src/app/services/user.service";

@Component({
  selector: "app-expense-payment",
  templateUrl: "./expense-payment.component.html",
  styleUrls: ["./expense-payment.component.scss"],
  providers: [MessageService],
})
export class ExpensePaymentComponent implements OnInit {
  @ViewChild("formWrapper") public formWrapper: ElementRef;
  paymentInvForm: FormGroup;
  paymentAdvForm: FormGroup;
  paymentDirectAcctForm: FormGroup;
  msgInv: Message[] = [];
  msgAcct: Message[] = [];
  msgAdv: Message[] = [];
  allUsers: User[] = [];
  allSuppliers: Supplier[] = [];
  allClients: CustomerDivision[] = [];
  allOperatives: DeployedGuard[] = [];
  allRequestTypes: {
    key: number;
    value: string;
  }[];
  fetching: boolean;
  allPaymentsInv: ExpenseBatchRequest[] = [];
  selectedPaymentsInv: ExpenseBatchRequest[] = [];
  allPaymentsDirectAcct: ExpenseBatchRequest[] = [];
  selectedPaymentsDirectAcct: ExpenseBatchRequest[] = [];
  allPaymentsAdv: ExpenseBatchRequest[] = [];
  selectedPaymentsAdv: ExpenseBatchRequest[] = [];
  colsInv: any[];
  colsInvGen: any[];
  colsDirectAcct: any[];
  colsDirectAcctGen: any[];
  colsAdv: any[];
  colsAdvGen: any[];
  openMemoDialogue: boolean;
  currentDate: string = new Date(Date.now()).toDateString();
  subject: string = "Subject";
  situation: string =
    "father abraham had many sons father abraham had many sons father abraham had many sonsfather abraham had many sonsfather abraham had many sonsfather abraham had many sonsfather abraham had many sonsfather abraham had many sonsfather abraham had many sonsfather abraham had many sons father abraham had many sons";
  justification: string =
    "father abraham had many sons father abraham had many sons father abraham had many sonsfather abraham had many sonsfather abraham had many sonsfather abraham had many sonsfather abraham had many sonsfather abraham had many sonsfather abraham had many sonsfather abraham had many sons father abraham had many sons";
  batchExpenseLines: ExpenseBatchRequestItem[] = [];
  request: string =
    "In view of the above, kindly approve 1,000,000 (one million naira only) being payment for Bayo before Oct 2022 and Nov 2022";
  batchApprovals: {
    office: string;
    officer: string;
    status: string;
  }[] = [
    {
      office: "Line Manager",
      officer: "Omoshola Yusuf",
      status: "Approved",
    },
    {
      office: "Batch Approval",
      officer: "Omoshola Yusuf",
      status: "Approved",
    },
  ];
  showPaymentInvCapture: boolean;
  showPaymentAdvCapture: boolean;
  showPaymentDirectAcctCapture: boolean;
  requester: string = "Requester";
  batchInView: ExpenseBatchRequest;
  batchInViewInv: ExpenseBatchRequest;
  batchInViewAcct: ExpenseBatchRequest;
  batchInViewAdv: ExpenseBatchRequest;
  isVatApplied: boolean = false;
  isVatApplied2: boolean = false;
  isVatApplied3: boolean = false;
  allAccounts: Account[];
  theAccount: Account;
  theAccount2: Account;
  theAccount3: Account;
  paymentInvChequeNo: string;
  paymentDirectAcctChequeNo: string;
  paymentAdvChequeNo: string;
  allPaymentsInvGenerated: {
    index?: number;
    isTotal: boolean;
    isVat: boolean;
    postingAccount?: Account;
    description?: string;
    drValue: number;
    crValue: number;
  }[] = [];
  allPaymentsDirectAcctGenerated: {
    index?: number;
    isTotal: boolean;
    isVat: boolean;
    postingAccount?: Account;
    description?: string;
    drValue: number;
    crValue: number;
  }[] = [];
  allPaymentsAdvGenerated: {
    index?: number;
    isTotal: boolean;
    isVat: boolean;
    postingAccount?: Account;
    description?: string;
    drValue: number;
    crValue: number;
  }[] = [];
  totalDRValue: number = 0;
  totalCRValue: number = 0;
  // totalDRValueDirectAcct: number = 0;
  // totalCRValueDirectAcct: number = 0;
  paymentDirectAcctDesc: string;
  paymentAdvDesc: string;
  totalDRValueAdv: number = 0;
  totalCRValueAdv: number = 0;
  allExpenseLines: ExpenseLine[];
  requestDate: string = new Date(Date.now()).toDateString();
  requestType: string = "Staff";
  requestSubject: string = "Vendor Payment";
  requestRaisedBy: string = "Omoshola Yusuf";
  requestSituation: string = "Pay";
  requestJustification: string = "Pay";
  requestLineItems: ExpenseBatchRequestItem[];
  requestTotalAmt: number = 15000000;
  requestTotalVAT: number = 16000000;
  requestTotalAmtValue: number = 31000000;
  showDetails: boolean;
  openDocDialogue: boolean;
  batchDocs: {
    caption: string;
    documents: string;
  }[] = [];
  showGenerateScheduleInv: boolean;
  showGenerateScheduleAcct: boolean;
  showGenerateScheduleAdv: boolean;
  allControlAccounts: ControlAccount[];
  PAYABLES_CONFIGURATION: GetPayablesConfigurationsVM;
  showGeneratedSchedulesInv: boolean;
  showGeneratedSchedulesAcct: boolean;
  showGeneratedSchedulesAdv: boolean;
  allAccountInDB: Account[] = [];

  constructor(
    fb: FormBuilder,
    public accountService: AccountService,
    public supplierService: SupplierService,
    public clientService: CustomerDivisionService,
    public employmentService: GmaEmploymentService,
    public divisionService: DivisionService,
    public serviceCategoryService: ServiceCategoryService,
    public financeExpenseService: FinanceExpenseService,
    public userService: UserService,
    public messageService: MessageService,
    private breadcrumbService: BreadcrumbService,
    public confirmationService: ConfirmationService
  ) {
    this.paymentInvForm = fb.group({
      Description: ["", Validators.required],
      AmountRequested: ["", Validators.required],
      TotalVat: ["", Validators.required],
      TotalAmt: ["", Validators.required],
      PercentPay: ["", Validators.required],
      IsVatApplied: ["", Validators.required],
      WHTAmount: ["", Validators.required],
      AmountToPay: ["", Validators.required],
    });

    this.paymentDirectAcctForm = fb.group({
      TotalAmount: ["", Validators.required],
      TotalVat: ["", Validators.required],
      TotalValue: ["", Validators.required],
      AmountPaid: ["", Validators.required],
      AmountPending: ["", Validators.required],
      PercToPay: ["", Validators.required],
      IsVatApplied: ["", Validators.required],
      WHTAmount: ["", Validators.required],
      AmountToPay: ["", Validators.required],
    });

    this.paymentAdvForm = fb.group({
      TotalAmount: ["", Validators.required],
      TotalVat: ["", Validators.required],
      TotalValue: ["", Validators.required],
      AmountPaid: ["", Validators.required],
      AmountPending: ["", Validators.required],
      PercToPay: ["", Validators.required],
      IsVatApplied: ["", Validators.required],
      WHTAmount: ["", Validators.required],
      AmountToPay: ["", Validators.required],
    });
  }

  ngOnInit(): void {
    this.breadcrumbService.setItems([
      {
        label: "Finance",
        routerLink: ["/home/finance"],
      },
      {
        label: "Expense Tool",
        routerLink: ["/home/finance/expense"],
      },
      {
        label: "Payment",
        routerLink: ["/home/finance/expense/payment"],
      },
    ]);

    this.allRequestTypes = [
      {
        key: UserExpenseRequestType.Supplier,
        value: "Supplier",
      },
      {
        key: UserExpenseRequestType.Staff,
        value: "Staff",
      },
      {
        key: UserExpenseRequestType.Customer,
        value: "Customer",
      },
      {
        key: UserExpenseRequestType.Operatives,
        value: "Operative",
      },
      {
        key: UserExpenseRequestType.Miscellanous,
        value: "Miscellanous",
      },
    ];

    this.colsInv = [
      { field: "id", header: "Batch ID" },
      { field: "beneficiary", header: "Beneficiary" },
      { field: "beneficiaryType", header: "Beneficiary Type" },
      { field: "invoiceValue", header: "Invoice Value" },
      { field: "amountPaid", header: "Amount Paid" },
    ];

    this.colsInvGen = [
      { field: "account", header: "Account" },
      { field: "accountName", header: "Account Name" },
      { field: "description", header: "Decription" },
      { field: "debit", header: "Debit" },
      { field: "credit", header: "Credit" },
    ];

    this.colsDirectAcct = [
      { field: "id", header: "Batch ID" },
      { field: "subject", header: "Subject" },
      { field: "beneficiary", header: "Beneficiary" },
      { field: "beneficiaryType", header: "Beneficiary Type" },
      { field: "invoiceValue", header: "Invoice Value" },
      { field: "amountPaid", header: "Amount Paid" },
    ];

    this.colsDirectAcctGen = [
      { field: "account", header: "Account" },
      { field: "accountName", header: "Account Name" },
      { field: "description", header: "Decription" },
      { field: "debit", header: "Debit" },
      { field: "credit", header: "Credit" },
    ];

    this.colsAdv = [
      { field: "id", header: "Batch ID" },
      { field: "beneficiary", header: "Beneficiary" },
      { field: "beneficiaryType", header: "Beneficiary Type" },
      { field: "invoiceValue", header: "Invoice Value" },
      { field: "amountPaid", header: "Amount Paid" },
    ];

    this.colsAdvGen = [
      { field: "account", header: "Account" },
      { field: "accountName", header: "Account Name" },
      { field: "description", header: "Decription" },
      { field: "debit", header: "Debit" },
      { field: "credit", header: "Credit" },
    ];

    this.FetchAllAccounts();
    this.FetchAllUsers();
    this.FetchAllClients();
    this.FetchAllSuppliers();
    this.FetchAllOperatives();
    this.FetchAllExpenseLines();
    this.FetchAllPendingRequets();
    this.FetchAllExpenseAccounts();
    this.ResetMessageToasters();
  }

  ResetMessageToasters() {
    this.msgInv = [];
    this.msgInv.push({
      severity: "info",
      summary:
        "You have " +
        this.allPaymentsInv.length +
        " Invoice Payments Totalling N" +
        this.GetBatchesTotal(this.allPaymentsInv).toLocaleString() +
        " awaiting payments",
    });

    this.msgAcct = [];
    this.msgAcct.push({
      severity: "info",
      summary:
        "You have " +
        this.allPaymentsDirectAcct.length +
        " on Account Payments Totalling N" +
        this.GetBatchesTotal(this.allPaymentsDirectAcct).toLocaleString() +
        " awaiting payments",
    });

    this.msgAdv = [];
    this.msgAdv.push({
      severity: "info",
      summary:
        "You have " +
        this.allPaymentsAdv.length +
        " Invoice Payments Detailing N" +
        this.GetBatchesTotal(this.allPaymentsAdv).toLocaleString() +
        " awaiting payments",
    });
  }

  async FetchAllUsers() {
    this.userService.allUser().subscribe(
      async (res) => {
        var data = res.responseData;
        this.allUsers = data;
      },
      (err) => {
        console.log(err);
        this.messageService.add({
          severity: "error",
          summary: "Failed",
          detail: err ?? "Some errors occurred",
        });
      }
    );
  }

  async FetchAllSuppliers() {
    this.supplierService.getAll().subscribe(
      async (data) => {
        this.allSuppliers = data.responseData;
      },
      (error) => {
        this.messageService.add({
          severity: "error",
          summary: "Failed",
          detail: error ?? "Some errors occurred",
        });
      }
    );
  }

  async FetchAllClients() {
    this.clientService.allCustomerDivision().subscribe(
      async (data) => {
        this.allClients = data.responseData;
      },
      (error) => {
        this.messageService.add({
          severity: "error",
          summary: "Failed",
          detail: error ?? "Some errors occurred",
        });
      }
    );
  }

  async FetchAllOperatives() {
    this.employmentService.GetAllOperativesDataOnly().subscribe(
      async (data) => {
        this.allOperatives = [];
        data.forEach((x) => {
          x.guardName =
            x.jobApplication.personalInformation.lastname +
            " " +
            x.jobApplication.personalInformation.firstname +
            " " +
            x.jobApplication.personalInformation.othername;
          this.allOperatives.push(x);
        });
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all operatives at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  async FetchAllExpenseLines() {
    this.financeExpenseService.GetAllExpenseLines().subscribe(
      async (data) => {
        if (data.responseCode != "00") {
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail: data.responseMsg,
          });
          return;
        }

        this.allExpenseLines = data.responseData;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all expense lines at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  async FetchAllPendingRequets() {
    this.fetching = true;
    this.financeExpenseService.GetAllExpenseBatchPendingPayments().subscribe(
      async (data) => {
        if (data.responseCode != "00") {
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail: data.responseMsg,
          });
          this.fetching = false;
          this.ResetMessageToasters();
          return;
        }

        this.allPaymentsInv = data.responseData.onInvoiceBatches;
        this.allPaymentsDirectAcct = data.responseData.onAccountBatches;
        this.allPaymentsAdv = data.responseData.onAdvanceBatches;
        this.PAYABLES_CONFIGURATION = data.responseData;
        this.fetching = false;
        this.ResetMessageToasters();
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all approvals info at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
        this.fetching = false;
        this.ResetMessageToasters();
      }
    );
  }

  async FetchAllExpenseAccounts() {
    this.allAccounts = [];
    this.financeExpenseService.GetExpenseLineAccounts().subscribe(
      async (data) => {
        if (data.responseCode == "00") {
          this.allControlAccounts = data.responseData;
          this.allControlAccounts.forEach((x) =>
            this.allAccounts.push(...x.accounts)
          );
        } else {
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail: data.responseMsg,
          });
        }
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all expense accounts at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  async FetchAllAccounts() {
    this.accountService.allAccount().subscribe(
      async (data) => {
        this.allAccountInDB = data.responseData;
      },
      (error) => {
        this.messageService.add({
          severity: "error",
          summary: "Failed",
          detail: error ?? "Some errors occurred",
        });
      }
    );
  }

  GetUsername(userId: number): string {
    let sf = this.allUsers.find((x) => x.id == userId);
    if (sf)
      return (
        sf.lastName.toUpperCase() +
        " " +
        sf.firstName.toUpperCase() +
        " " +
        (sf.otherName == null ? "" : sf.otherName.toUpperCase())
      );

    return "N/A";
  }

  GetEntityName(item: ExpenseBatchRequest): string {
    if (item.requestType == UserExpenseRequestType.Customer) {
      let customer = this.allClients.find((x) => item.clientId);
      if (customer) return customer.divisionName;
    } else if (item.requestType == UserExpenseRequestType.Operatives) {
      let op = this.allOperatives.find((x) => x.id == item.operativeId);
      if (op)
        return (
          op.jobApplication.personalInformation.lastname +
          " " +
          op.jobApplication.personalInformation.firstname +
          " " +
          op.jobApplication.personalInformation.othername
        );
    } else if (item.requestType == UserExpenseRequestType.Staff) {
      return this.GetUsername(item.staffId);
    } else if (item.requestType == UserExpenseRequestType.Supplier) {
      let suppl = this.allSuppliers.find((x) => x.id == item.supplierId);
      if (suppl) return suppl.supplierName;
    } else if (item.requestType == UserExpenseRequestType.Miscellanous) {
      return "Miscellaneous";
    }

    return "N/A";
  }

  GetERequestTypeLabel(identifier: number): string {
    let reqType = this.allRequestTypes.find((x) => x.key == identifier);
    if (reqType) return reqType.value;

    return "N/A";
  }

  ViewMemoFormat(item: ExpenseBatchRequest) {
    this.openMemoDialogue = true;
    this.showPaymentInvCapture = false;
    this.showPaymentDirectAcctCapture = false;
    this.showPaymentAdvCapture = false;
    this.showGenerateScheduleInv = false;
    this.showGenerateScheduleAcct = false;
    this.showGenerateScheduleAdv = false;
    this.showGeneratedSchedulesInv = false;
    this.showGeneratedSchedulesAcct = false;
    this.showGeneratedSchedulesAdv = false;

    let requesterInfo = this.allUsers.find((x) => x.id == item.createdById);
    if (requesterInfo) {
      this.requester =
        requesterInfo.lastName.toUpperCase() +
        " " +
        requesterInfo.firstName.toUpperCase() +
        " " +
        (requesterInfo.otherName == null
          ? ""
          : requesterInfo.otherName.toUpperCase());
    } else this.requester = "N/A";
    this.currentDate = item.createdAt.toString().substring(0, 10);
    this.subject = item.subject;
    this.situation = item.situation;
    this.justification = item.justification;
    this.batchExpenseLines = item.lineItems;
    let sumTotal = 0;
    this.batchExpenseLines.forEach((x) => (sumTotal += x.amount + x.vatAmount));
    this.request =
      "In view of the above, kindly approve N" +
      sumTotal.toLocaleString() +
      " (" +
      this.NumInWords(sumTotal) +
      " naira only) being payment for the itemized above to " +
      this.GetEntityName(item) +
      "." +
      ". Request created by " +
      this.GetUsername(item.createdById);
    localStorage.setItem("memoName", item.subject);
    this.batchApprovals = [];
    let lineManagerLog = item.approvalLogs.find(
      (x) =>
        x.isLineManager && x.status == ExpenseBatchRequestLogStatus.Approved
    );
    this.batchApprovals.push({
      office: "Line Manager",
      officer: this.GetUsername(lineManagerLog.createdById),
      status: "Approved",
    });
    item.picks
      .filter(
        (x) =>
          x.status == ExpenseBatchRequestLogStatus.Approved && !x.isAuthorizer
      )
      .forEach((log) =>
        this.batchApprovals.push({
          office: log.approvingOfficeName,
          officer: this.GetUsername(log.createdById),
          status: "Approved",
        })
      );
    item.picks
      .filter(
        (x) =>
          x.status == ExpenseBatchRequestLogStatus.Approved && x.isAuthorizer
      )
      .forEach((log) =>
        this.batchApprovals.push({
          office: log.authorizationOfficeName,
          officer: this.GetUsername(log.createdById),
          status: "Authorized",
        })
      );
  }

  NumInWords(number: number): string {
    const first = [
      "",
      "one ",
      "two ",
      "three ",
      "four ",
      "five ",
      "six ",
      "seven ",
      "eight ",
      "nine ",
      "ten ",
      "eleven ",
      "twelve ",
      "thirteen ",
      "fourteen ",
      "fifteen ",
      "sixteen ",
      "seventeen ",
      "eighteen ",
      "nineteen ",
    ];
    const tens = [
      "",
      "",
      "twenty",
      "thirty",
      "forty",
      "fifty",
      "sixty",
      "seventy",
      "eighty",
      "ninety",
    ];
    const mad = ["", "thousand", "million", "billion", "trillion"];
    let word = "";

    for (let i = 0; i < mad.length; i++) {
      let tempNumber = number % (100 * Math.pow(1000, i));
      if (Math.floor(tempNumber / Math.pow(1000, i)) !== 0) {
        if (Math.floor(tempNumber / Math.pow(1000, i)) < 20) {
          word =
            first[Math.floor(tempNumber / Math.pow(1000, i))] +
            mad[i] +
            " " +
            word;
        } else {
          word =
            tens[Math.floor(tempNumber / (10 * Math.pow(1000, i)))] +
            "-" +
            first[Math.floor(tempNumber / Math.pow(1000, i)) % 10] +
            mad[i] +
            " " +
            word;
        }
      }

      tempNumber = number % Math.pow(1000, i + 1);
      if (Math.floor(tempNumber / (100 * Math.pow(1000, i))) !== 0)
        word =
          first[Math.floor(tempNumber / (100 * Math.pow(1000, i)))] +
          "hunderd " +
          word;
    }
    return word;
  }

  PdfDownloadPage() {
    let memoName = localStorage.getItem("memoName");
    var node = document.getElementById("memoDiv");
    var img;
    var filename;
    var newImage;
    domtoimage
      .toPng(node, { bgcolor: "#fff" })
      .then(function (dataUrl) {
        img = new Image();
        img.src = dataUrl;
        newImage = img.src;
        img.onload = function () {
          var pdfWidth = img.width;
          var pdfHeight = img.height;
          // FileSaver.saveAs(dataUrl, 'my-pdfimage.png'); // Save as Image
          var doc;
          if (pdfWidth > pdfHeight) {
            doc = new jsPDF("l", "px", [pdfWidth, pdfHeight]);
          } else {
            doc = new jsPDF("p", "px", [pdfWidth, pdfHeight]);
          }
          var width = doc.internal.pageSize.getWidth();
          var height = doc.internal.pageSize.getHeight();

          doc.addImage(newImage, "PNG", 10, 10, width, height);
          filename = memoName + " Memo" + ".pdf";
          doc.save(filename);
        };
      })
      .catch(function (error) {
        // Error Handling
      });
  }

  HideMemoDialog() {
    this.openMemoDialogue = false;
    this.requester = "";
    this.currentDate = new Date(Date.now()).toDateString();
    this.subject = "";
    this.situation = "";
    this.justification = "";
    this.batchExpenseLines = [];
    this.request = "";
    this.batchApprovals = [];
  }

  SwitchIsVatApplied() {
    this.CalculateInvPayments();
  }

  GetUserImage(userId: number): string {
    let sf = this.allUsers.find((x) => x.id == userId);
    if (sf) return sf.imageUrl;

    return "assets/no-file.png";
  }

  GetLineManagerId(item: ExpenseBatchRequest): number {
    var lineManagerLog = item.approvalLogs.find(
      (x) =>
        x.isLineManager && x.status == ExpenseBatchRequestLogStatus.Approved
    );
    return lineManagerLog.createdById;
  }

  GetExpenseLineCaption(identifier: number): string {
    let expenseLine = this.allExpenseLines.find((x) => x.id == identifier);
    if (expenseLine) return expenseLine.caption;

    return "N/A";
  }

  ViewBatchDetails(item: ExpenseBatchRequest) {
    this.showPaymentInvCapture = false;
    this.showPaymentDirectAcctCapture = false;
    this.showPaymentAdvCapture = false;
    this.showGenerateScheduleInv = false;
    this.showGenerateScheduleAcct = false;
    this.showGenerateScheduleAdv = false;
    this.showGeneratedSchedulesInv = false;
    this.showGeneratedSchedulesAcct = false;
    this.showGeneratedSchedulesAdv = false;

    this.requestDate = item.createdAt.toString().substring(0, 10);
    this.requestType = this.allRequestTypes.find(
      (x) => x.key == item.requestType
    ).value;
    this.requestSubject = item.subject;
    this.requestJustification = item.justification;
    this.requestSituation = item.situation;
    this.requestRaisedBy = this.GetEntityName(item);
    this.requestLineItems = item.lineItems;
    this.requestTotalAmt = 0;
    this.requestTotalVAT = 0;
    this.requestTotalAmtValue = 0;
    this.requestLineItems.forEach((lineItem) => {
      this.requestTotalAmt += lineItem.amount;
      this.requestTotalVAT += lineItem.vatAmount;
      this.requestTotalAmtValue += lineItem.amount + lineItem.vatAmount;
    });
    this.batchInView = item;
    this.showDetails = true;
  }

  HideBatchDetails() {
    this.showDetails = false;
    this.batchInView = null;
  }

  ViewRequestAttachments() {
    this.openDocDialogue = true;
    this.batchDocs = [];
    this.batchDocs.push({
      caption: "Batch " + this.batchInView.subject,
      documents: this.batchInView.attachments,
    });
    this.batchInView.lineItems.forEach((lineItem) =>
      this.batchDocs.push({
        caption:
          lineItem.description +
          "-" +
          this.GetExpenseLineCaption(lineItem.expenseLineId),
        documents: lineItem.attachments,
      })
    );
  }

  HideDocDialog() {
    this.openDocDialogue = false;
    this.batchDocs = [];
  }

  CaptureInvPayments(item: ExpenseBatchRequest) {
    this.showGenerateScheduleInv = false;
    this.showGeneratedSchedulesInv = false;

    this.batchInViewInv = item;
    let amt = 0;
    let vat = 0;
    item.lineItems.forEach((x) => {
      amt += x.amount;
      vat += x.vatAmount;
    });
    let totAmt = amt + vat;
    let amtPaid = this.GetTotalAmountPaid(item);

    this.paymentInvForm.patchValue({
      AmountRequested: "N" + amt.toLocaleString(),
      TotalVat: "N" + vat.toLocaleString(),
      TotalAmt: "N" + totAmt.toLocaleString(),
      PercentPay: 0,
      WHTAmount: "N0",
      AmountToPay: "N0",
    });
    this.isVatApplied = false;
    this.batchInViewInv.amountPending = totAmt - amtPaid;

    this.showPaymentInvCapture = true;
  }

  CaptureAcctPayments(item: ExpenseBatchRequest) {
    this.showGenerateScheduleAcct = false;
    this.showGeneratedSchedulesAcct = false;

    this.batchInViewAcct = item;
    let amt = 0;
    let vat = 0;
    item.lineItems.forEach((x) => {
      amt += x.amount;
      vat += x.vatAmount;
    });
    let totAmt = amt + vat;
    let amtPaid = this.GetTotalAmountPaid(item);

    this.paymentDirectAcctForm.patchValue({
      TotalAmount: "N" + amt.toLocaleString(),
      TotalVat: "N" + vat.toLocaleString(),
      TotalValue: "N" + totAmt.toLocaleString(),
      AmountPaid: "N" + amtPaid.toLocaleString(),
      AmountPending: "N" + (totAmt - amtPaid).toLocaleString(),
      PercToPay: 0,
      WHTAmount: "N0",
      AmountToPay: "N0",
    });
    this.isVatApplied2 = false;
    this.batchInViewAcct.amountPending = totAmt - amtPaid;

    this.showPaymentDirectAcctCapture = true;
  }

  CaptureAdvPayments(item: ExpenseBatchRequest) {
    this.showGenerateScheduleAdv = false;
    this.showGeneratedSchedulesAdv = false;

    this.batchInViewAdv = item;
    let amt = 0;
    let vat = 0;
    item.lineItems.forEach((x) => {
      amt += x.amount;
      vat += x.vatAmount;
    });
    let totAmt = amt + vat;
    let amtPaid = this.GetTotalAmountPaid(item);

    this.paymentAdvForm.patchValue({
      TotalAmount: "N" + amt.toLocaleString(),
      TotalVat: "N" + vat.toLocaleString(),
      TotalValue: "N" + totAmt.toLocaleString(),
      AmountPaid: "N" + amtPaid.toLocaleString(),
      AmountPending: "N" + (totAmt - amtPaid).toLocaleString(),
      PercToPay: 0,
      WHTAmount: "N0",
      AmountToPay: "N0",
    });
    this.isVatApplied3 = false;
    this.batchInViewAdv.amountPending = totAmt - amtPaid;

    this.showPaymentAdvCapture = true;
  }

  SwitchIsVatApplied2() {
    this.CalculateAcctPayments();
  }

  SwitchIsVatApplied3() {
    this.CalculateAdvPayments();
  }

  CalculateInvPayments() {
    this.batchInViewInv.canProceed = false;
    this.showGenerateScheduleInv = false;
    this.showGeneratedSchedulesInv = false;

    let perc = +this.paymentInvForm.get("PercentPay").value;
    if (!perc || perc == 0) {
      this.paymentInvForm.patchValue({
        WHTAmount: "N0",
        AmountToPay: "N0",
      });
      return;
    }
    if (perc < 0 || perc > 100) {
      this.paymentInvForm.patchValue({
        PercentPay: 0,
        WHTAmount: "N0",
        AmountToPay: "N0",
      });
      return;
    }

    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Applying New Percentage " + perc,
    });
    this.ResetMessageToasters();

    let amtLeftToPay = this.batchInViewInv.amountPending;
    let amtToPay = (perc / 100) * amtLeftToPay;
    if (this.isVatApplied) {
      let whtAmt = (this.PAYABLES_CONFIGURATION.whtPercentage / 100) * amtToPay;
      this.paymentInvForm.patchValue({
        WHTAmount: "N" + whtAmt.toLocaleString(),
        AmountToPay: "N" + (amtToPay - whtAmt).toLocaleString(),
      });
    } else {
      this.paymentInvForm.patchValue({
        WHTAmount: "N0",
        AmountToPay: "N" + amtToPay.toLocaleString(),
      });
    }

    this.batchInViewInv.canProceed = amtToPay > 0;
  }

  CalculateAcctPayments() {
    this.batchInViewAcct.canProceed = false;
    this.showGenerateScheduleAcct = false;
    this.showGeneratedSchedulesAcct = false;

    let perc = +this.paymentDirectAcctForm.get("PercToPay").value;
    if (!perc || perc == 0) {
      this.paymentDirectAcctForm.patchValue({
        WHTAmount: "N0",
        AmountToPay: "N0",
      });
      return;
    }
    if (perc < 0 || perc > 100) {
      this.paymentDirectAcctForm.patchValue({
        PercToPay: 0,
        WHTAmount: "N0",
        AmountToPay: "N0",
      });
      return;
    }

    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Applying New Percentage " + perc,
    });
    this.ResetMessageToasters();

    let amtLeftToPay = this.batchInViewAcct.amountPending;
    let amtToPay = (perc / 100) * amtLeftToPay;
    if (this.isVatApplied2) {
      let whtAmt = (this.PAYABLES_CONFIGURATION.whtPercentage / 100) * amtToPay;
      this.paymentDirectAcctForm.patchValue({
        WHTAmount: "N" + whtAmt.toLocaleString(),
        AmountToPay: "N" + (amtToPay - whtAmt).toLocaleString(),
      });
    } else {
      this.paymentDirectAcctForm.patchValue({
        WHTAmount: "N0",
        AmountToPay: "N" + amtToPay.toLocaleString(),
      });
    }

    this.batchInViewAcct.canProceed = amtToPay > 0;
  }

  CalculateAdvPayments() {
    this.batchInViewAdv.canProceed = false;
    this.showGenerateScheduleAdv = false;
    this.showGeneratedSchedulesAdv = false;

    let perc = +this.paymentAdvForm.get("PercToPay").value;
    if (!perc || perc == 0) {
      this.paymentAdvForm.patchValue({
        WHTAmount: "N0",
        AmountToPay: "N0",
      });
      return;
    }
    if (perc < 0 || perc > 100) {
      this.paymentAdvForm.patchValue({
        PercToPay: 0,
        WHTAmount: "N0",
        AmountToPay: "N0",
      });
      return;
    }

    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Applying New Percentage " + perc,
    });
    this.ResetMessageToasters();

    let amtLeftToPay = this.batchInViewAdv.amountPending;
    let amtToPay = (perc / 100) * amtLeftToPay;
    if (this.isVatApplied3) {
      let whtAmt = (this.PAYABLES_CONFIGURATION.whtPercentage / 100) * amtToPay;
      this.paymentAdvForm.patchValue({
        WHTAmount: "N" + whtAmt.toLocaleString(),
        AmountToPay: "N" + (amtToPay - whtAmt).toLocaleString(),
      });
    } else {
      this.paymentAdvForm.patchValue({
        WHTAmount: "N0",
        AmountToPay: "N" + amtToPay.toLocaleString(),
      });
    }

    this.batchInViewAdv.canProceed = amtToPay > 0;
  }

  ProceedWithPaymentInv() {
    this.theAccount = null;
    this.paymentInvChequeNo = null;
    this.showGenerateScheduleInv = true;
  }

  ProceedWithPaymentDirectAcct() {
    this.theAccount2 = null;
    this.paymentDirectAcctChequeNo = null;
    this.paymentDirectAcctDesc = null;
    this.showGenerateScheduleAcct = true;
  }

  GeneratePaymentInvSchedule() {
    this.allPaymentsInvGenerated = [];

    let perc = +this.paymentInvForm.get("PercentPay").value;
    let amtLeftToPay = this.batchInViewInv.amountPending;
    let amtToPay = (perc / 100) * amtLeftToPay;
    let whtAmt = 0;

    let sumAmt = 0;
    let sumVat = 0;
    let percPaid = this.GetPercentagePaid(this.batchInViewInv);
    this.batchInViewInv.lineItems.forEach((lineItem) => {
      let amtToPay = lineItem.amount - (percPaid / 100) * lineItem.amount;
      let vatToPay = lineItem.vatAmount - (percPaid / 100) * lineItem.vatAmount;

      let actualAmtToPay = (perc / 100) * amtToPay;
      let actualVatToPay = (perc / 100) * vatToPay;

      sumAmt += actualAmtToPay;
      sumVat += actualVatToPay;
    });

    this.allPaymentsInvGenerated.push({
      index: this.allPaymentsInvGenerated.length,
      isTotal: false,
      isVat: false,
      postingAccount: this.allAccountInDB.find(
        (y) => y.id == this.batchInViewInv.payableAccountId
      ),
      description: "Payable Account",
      drValue: sumAmt,
      crValue: 0,
    });

    this.allPaymentsInvGenerated.push({
      index: this.allPaymentsInvGenerated.length,
      isTotal: false,
      isVat: false,
      postingAccount: {
        id: this.PAYABLES_CONFIGURATION.vatAccountId,
        name: this.PAYABLES_CONFIGURATION.vatAccountName,
        description: this.PAYABLES_CONFIGURATION.vatAccountDescription,
        alias: this.PAYABLES_CONFIGURATION.vatAccountAlias,
        accountNumber: this.PAYABLES_CONFIGURATION.vatAccountNumber,
      },
      description: "VAT",
      drValue: sumVat,
      crValue: 0,
    });

    if (this.isVatApplied) {
      whtAmt = (this.PAYABLES_CONFIGURATION.whtPercentage / 100) * amtToPay;
      this.allPaymentsInvGenerated.push({
        index: this.allPaymentsInvGenerated.length,
        isTotal: false,
        isVat: true,
        postingAccount: {
          id: this.PAYABLES_CONFIGURATION.whtAccountId,
          name: this.PAYABLES_CONFIGURATION.whtAccountName,
          description: this.PAYABLES_CONFIGURATION.whtAccountDescription,
          alias: this.PAYABLES_CONFIGURATION.whtAccountAlias,
          accountNumber: this.PAYABLES_CONFIGURATION.whtAccountNumber,
        },
        description: "WHT",
        drValue: 0,
        crValue: whtAmt,
      });
    }
    this.allPaymentsInvGenerated.push({
      index: this.allPaymentsInvGenerated.length,
      isTotal: false,
      isVat: false,
      postingAccount: this.theAccount,
      description: this.paymentInvForm.get("Description").value,
      drValue: 0,
      crValue: amtToPay - whtAmt,
    });
    let sumDr = 0;
    let sumCr = 0;
    this.allPaymentsInvGenerated.forEach((val) => {
      sumDr += val.drValue;
      sumCr += val.crValue;
    });
    this.allPaymentsInvGenerated.push({
      index: this.allPaymentsInvGenerated.length,
      isTotal: true,
      isVat: false,
      drValue: sumDr,
      crValue: sumCr,
    });
    this.showGeneratedSchedulesInv = true;
  }

  ClearGeneratedPaymentInvSchedule() {
    this.allPaymentsInvGenerated = [];
  }

  PostGeneratedPaymentInvSchedule() {
    this.confirmationService.confirm({
      message:
        "You are about to post generated schedule payment for this batch. This is an irreversible action. Do you still wish to proceed?",
      accept: () => {
        this.messageService.add({
          severity: "info",
          summary: "Notice",
          detail: "Posting Generated Schedule Payments...",
        });
        this.ResetMessageToasters();

        let totalRecord = this.allPaymentsInvGenerated.find((x) => x.isTotal);
        if (!totalRecord) {
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail: "Total Record not found. Please generate schedule again",
          });
          return;
        }
        if (totalRecord.drValue != totalRecord.crValue) {
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail: "Total Debit must match total credit",
          });
          return;
        }

        let vatRecord = this.allPaymentsInvGenerated.find((x) => x.isVat);
        const postData: PostExpenseBatchPaymentsVM = {
          batchId: this.batchInViewInv.id,
          totalValue: totalRecord.drValue,
          isAdvancePayment: true,
          description: "Posting " + this.batchInViewInv.subject + " Payments",
          isVatApplied: this.isVatApplied,
          vatAmount: vatRecord ? vatRecord.crValue : 0,
          uniqueRefNo: this.paymentInvChequeNo,
          payments: [],
        };
        this.allPaymentsInvGenerated
          .filter((x) => !x.isTotal)
          .forEach((payment) => {
            postData.payments.push({
              accountId: payment.postingAccount.id,
              description: payment.description,
              debitValue: payment.drValue,
              creditValue: payment.crValue,
            });
          });

        this.financeExpenseService.PostExpenseBatchPayments(postData).subscribe(
          async (data) => {
            if (data.responseCode != "00") {
              this.messageService.add({
                severity: "error",
                summary: "Notice",
                detail: data.responseMsg,
              });
              return;
            }

            this.messageService.add({
              severity: "success",
              summary: "Completed",
              detail: "Payments Posted Successfully!",
            });

            this.fetching = true;
            this.showPaymentInvCapture = false;
            this.showGenerateScheduleInv = false;
            this.showGeneratedSchedulesInv = false;
            this.showDetails = false;
            const index = this.allPaymentsInv.indexOf(this.batchInViewInv);
            if (index > -1) {
              this.allPaymentsInv.splice(index, 1);
            }
            this.batchInViewInv = null;
            this.ResetMessageToasters();
            this.FetchAllPendingRequets();
          },
          (error) => {
            console.log("Error: " + JSON.stringify(error));
            this.messageService.add({
              severity: "error",
              summary: "Notice",
              detail:
                "Unable to post payments at the moment.. Reason: [" +
                (error ? error.error.message : "request failed - permission") +
                "]",
            });
          }
        );
      },
    });
  }

  GeneratePaymentDirectAcctSchedule() {
    this.allPaymentsDirectAcctGenerated = [];

    let perc = +this.paymentDirectAcctForm.get("PercToPay").value;
    let sumVat = 0;
    let percPaid = this.GetPercentagePaid(this.batchInViewAcct);
    this.batchInViewAcct.lineItems.forEach((lineItem) => {
      let amtToPay = lineItem.amount - (percPaid / 100) * lineItem.amount;
      let vatToPay = lineItem.vatAmount - (percPaid / 100) * lineItem.vatAmount;

      let actualAmtToPay = (perc / 100) * amtToPay;
      let actualVatToPay = (perc / 100) * vatToPay;

      let expLine = this.allExpenseLines.find(
        (y) => y.id == lineItem.expenseLineId
      );
      this.allPaymentsDirectAcctGenerated.push({
        index: this.allPaymentsDirectAcctGenerated.length,
        isTotal: false,
        isVat: false,
        postingAccount: this.allAccountInDB.find(
          (y) => y.id == expLine.accountId
        ),
        description: lineItem.description,
        drValue: actualAmtToPay,
        crValue: 0,
      });
      sumVat += actualVatToPay;
    });

    this.allPaymentsDirectAcctGenerated.push({
      index: this.allPaymentsDirectAcctGenerated.length,
      isTotal: false,
      isVat: false,
      postingAccount: {
        id: this.PAYABLES_CONFIGURATION.vatAccountId,
        name: this.PAYABLES_CONFIGURATION.vatAccountName,
        description: this.PAYABLES_CONFIGURATION.vatAccountDescription,
        alias: this.PAYABLES_CONFIGURATION.vatAccountAlias,
        accountNumber: this.PAYABLES_CONFIGURATION.vatAccountNumber,
      },
      description: "VAT",
      drValue: sumVat,
      crValue: 0,
    });

    let amtLeftToPay = this.batchInViewAcct.amountPending;
    let amtToPay = (perc / 100) * amtLeftToPay;
    let whtAmt = 0;
    if (this.isVatApplied2) {
      whtAmt = (this.PAYABLES_CONFIGURATION.whtPercentage / 100) * amtToPay;
      this.allPaymentsDirectAcctGenerated.push({
        index: this.allPaymentsDirectAcctGenerated.length,
        isTotal: false,
        isVat: true,
        postingAccount: {
          id: this.PAYABLES_CONFIGURATION.whtAccountId,
          name: this.PAYABLES_CONFIGURATION.whtAccountName,
          description: this.PAYABLES_CONFIGURATION.whtAccountDescription,
          alias: this.PAYABLES_CONFIGURATION.whtAccountAlias,
          accountNumber: this.PAYABLES_CONFIGURATION.whtAccountNumber,
        },
        description: "WHT",
        drValue: 0,
        crValue: whtAmt,
      });
    }
    this.allPaymentsDirectAcctGenerated.push({
      index: this.allPaymentsDirectAcctGenerated.length,
      isTotal: false,
      isVat: false,
      postingAccount: this.theAccount2,
      description: this.paymentDirectAcctDesc,
      drValue: 0,
      crValue: amtToPay - whtAmt,
    });
    let sumDr = 0;
    let sumCr = 0;
    this.allPaymentsDirectAcctGenerated.forEach((val) => {
      sumDr += val.drValue;
      sumCr += val.crValue;
    });
    this.allPaymentsDirectAcctGenerated.push({
      index: this.allPaymentsDirectAcctGenerated.length,
      isTotal: true,
      isVat: false,
      drValue: sumDr,
      crValue: sumCr,
    });
    this.showGeneratedSchedulesAcct = true;
  }

  ClearGeneratedPaymentDirectAcctSchedule() {
    this.allPaymentsDirectAcctGenerated = [];
  }

  PostGeneratedPaymentDirectAcctSchedule() {
    this.confirmationService.confirm({
      message:
        "You are about to post generated schedule payment for this batch. This is an irreversible action. Do you still wish to proceed?",
      accept: () => {
        this.messageService.add({
          severity: "info",
          summary: "Notice",
          detail: "Posting Generated Schedule Payments...",
        });
        this.ResetMessageToasters();

        let totalRecord = this.allPaymentsDirectAcctGenerated.find(
          (x) => x.isTotal
        );
        if (!totalRecord) {
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail: "Total Record not found. Please generate schedule again",
          });
          return;
        }
        if (totalRecord.drValue != totalRecord.crValue) {
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail: "Total Debit must match total credit",
          });
          return;
        }

        let vatRecord = this.allPaymentsDirectAcctGenerated.find(
          (x) => x.isVat
        );
        const postData: PostExpenseBatchPaymentsVM = {
          batchId: this.batchInViewAcct.id,
          totalValue: totalRecord.drValue,
          isAdvancePayment: false,
          description: "Posting " + this.batchInViewAcct.subject + " Payments",
          isVatApplied: this.isVatApplied2,
          vatAmount: vatRecord.crValue,
          uniqueRefNo: this.paymentDirectAcctChequeNo,
          payments: [],
        };
        this.allPaymentsDirectAcctGenerated
          .filter((x) => !x.isTotal)
          .forEach((payment) => {
            postData.payments.push({
              accountId: payment.postingAccount.id,
              description: payment.description,
              debitValue: payment.drValue,
              creditValue: payment.crValue,
            });
          });

        this.financeExpenseService.PostExpenseBatchPayments(postData).subscribe(
          async (data) => {
            if (data.responseCode != "00") {
              this.messageService.add({
                severity: "error",
                summary: "Notice",
                detail: data.responseMsg,
              });
              return;
            }

            this.messageService.add({
              severity: "success",
              summary: "Completed",
              detail: "Payments Posted Successfully!",
            });

            this.fetching = true;
            this.showPaymentDirectAcctCapture = false;
            this.showGenerateScheduleAcct = false;
            this.showGeneratedSchedulesAcct = false;
            this.showDetails = false;
            const index = this.allPaymentsDirectAcct.indexOf(
              this.batchInViewAcct
            );
            if (index > -1) {
              this.allPaymentsDirectAcct.splice(index, 1);
            }
            this.batchInViewAcct = null;
            this.ResetMessageToasters();
            this.FetchAllPendingRequets();
          },
          (error) => {
            console.log("Error: " + JSON.stringify(error));
            this.messageService.add({
              severity: "error",
              summary: "Notice",
              detail:
                "Unable to post payments at the moment.. Reason: [" +
                (error ? error.error.message : "request failed - permission") +
                "]",
            });
          }
        );
      },
    });
  }

  ProceedWithPaymentAdv() {
    this.theAccount3 = null;
    this.paymentAdvChequeNo = null;
    this.paymentAdvDesc = null;
    this.showGenerateScheduleAdv = true;
  }

  GeneratePaymentAdvSchedule() {
    this.allPaymentsAdvGenerated = [];

    let perc = +this.paymentAdvForm.get("PercToPay").value;
    let sumVat = 0;
    let percPaid = this.GetPercentagePaid(this.batchInViewAdv);
    this.batchInViewAdv.lineItems.forEach((lineItem) => {
      let amtToPay = lineItem.amount - (percPaid / 100) * lineItem.amount;
      let vatToPay = lineItem.vatAmount - (percPaid / 100) * lineItem.vatAmount;

      let actualAmtToPay = (perc / 100) * amtToPay;
      let actualVatToPay = (perc / 100) * vatToPay;

      let expLine = this.allExpenseLines.find(
        (y) => y.id == lineItem.expenseLineId
      );
      this.allPaymentsAdvGenerated.push({
        index: this.allPaymentsAdvGenerated.length,
        isTotal: false,
        isVat: false,
        postingAccount: this.allAccountInDB.find(
          (y) => y.id == expLine.accountId
        ),
        description: lineItem.description,
        drValue: actualAmtToPay,
        crValue: 0,
      });
      sumVat += actualVatToPay;
    });

    this.allPaymentsAdvGenerated.push({
      index: this.allPaymentsAdvGenerated.length,
      isTotal: false,
      isVat: false,
      postingAccount: {
        id: this.PAYABLES_CONFIGURATION.vatAccountId,
        name: this.PAYABLES_CONFIGURATION.vatAccountName,
        description: this.PAYABLES_CONFIGURATION.vatAccountDescription,
        alias: this.PAYABLES_CONFIGURATION.vatAccountAlias,
        accountNumber: this.PAYABLES_CONFIGURATION.vatAccountNumber,
      },
      description: "VAT",
      drValue: sumVat,
      crValue: 0,
    });

    let amtLeftToPay = this.batchInViewAdv.amountPending;
    let amtToPay = (perc / 100) * amtLeftToPay;
    let whtAmt = 0;
    if (this.isVatApplied3) {
      whtAmt = (this.PAYABLES_CONFIGURATION.whtPercentage / 100) * amtToPay;
      this.allPaymentsAdvGenerated.push({
        index: this.allPaymentsAdvGenerated.length,
        isTotal: false,
        isVat: true,
        postingAccount: {
          id: this.PAYABLES_CONFIGURATION.whtAccountId,
          name: this.PAYABLES_CONFIGURATION.whtAccountName,
          description: this.PAYABLES_CONFIGURATION.whtAccountDescription,
          alias: this.PAYABLES_CONFIGURATION.whtAccountAlias,
          accountNumber: this.PAYABLES_CONFIGURATION.whtAccountNumber,
        },
        description: "WHT",
        drValue: 0,
        crValue: whtAmt,
      });
    }
    this.allPaymentsAdvGenerated.push({
      index: this.allPaymentsAdvGenerated.length,
      isTotal: false,
      isVat: false,
      postingAccount: this.theAccount3,
      description: this.paymentAdvDesc,
      drValue: 0,
      crValue: amtToPay - whtAmt,
    });
    let sumDr = 0;
    let sumCr = 0;
    this.allPaymentsAdvGenerated.forEach((val) => {
      sumDr += val.drValue;
      sumCr += val.crValue;
    });
    this.allPaymentsAdvGenerated.push({
      index: this.allPaymentsAdvGenerated.length,
      isTotal: true,
      isVat: false,
      drValue: sumDr,
      crValue: sumCr,
    });
    this.showGeneratedSchedulesAdv = true;
  }

  ClearGeneratedPaymentAdvSchedule() {
    this.allPaymentsAdvGenerated = [];
  }

  PostGeneratedPaymentAdvSchedule() {
    this.confirmationService.confirm({
      message:
        "You are about to post generated schedule payment for this batch. This is an irreversible action. Do you still wish to proceed?",
      accept: () => {
        this.messageService.add({
          severity: "info",
          summary: "Notice",
          detail: "Posting Generated Schedule Payments...",
        });
        this.ResetMessageToasters();

        let totalRecord = this.allPaymentsAdvGenerated.find((x) => x.isTotal);
        if (!totalRecord) {
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail: "Total Record not found. Please generate schedule again",
          });
          return;
        }
        if (totalRecord.drValue != totalRecord.crValue) {
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail: "Total Debit must match total credit",
          });
          return;
        }

        let vatRecord = this.allPaymentsAdvGenerated.find((x) => x.isVat);
        const postData: PostExpenseBatchPaymentsVM = {
          batchId: this.batchInViewAdv.id,
          totalValue: totalRecord.drValue,
          isAdvancePayment: true,
          description: "Posting " + this.batchInViewAdv.subject + " Payments",
          isVatApplied: this.isVatApplied3,
          vatAmount: vatRecord ? vatRecord.crValue : 0,
          uniqueRefNo: this.paymentAdvChequeNo,
          payments: [],
        };
        this.allPaymentsAdvGenerated
          .filter((x) => !x.isTotal)
          .forEach((payment) => {
            postData.payments.push({
              accountId: payment.postingAccount.id,
              description: payment.description,
              debitValue: payment.drValue,
              creditValue: payment.crValue,
            });
          });

        this.financeExpenseService.PostExpenseBatchPayments(postData).subscribe(
          async (data) => {
            if (data.responseCode != "00") {
              this.messageService.add({
                severity: "error",
                summary: "Notice",
                detail: data.responseMsg,
              });
              return;
            }

            this.messageService.add({
              severity: "success",
              summary: "Completed",
              detail: "Payments Posted Successfully!",
            });

            this.fetching = true;
            this.showPaymentAdvCapture = false;
            this.showGenerateScheduleAdv = false;
            this.showGeneratedSchedulesAdv = false;
            this.showDetails = false;
            const index = this.allPaymentsAdv.indexOf(this.batchInViewAdv);
            if (index > -1) {
              this.allPaymentsAdv.splice(index, 1);
            }
            this.batchInViewAdv = null;
            this.ResetMessageToasters();
            this.FetchAllPendingRequets();
          },
          (error) => {
            console.log("Error: " + JSON.stringify(error));
            this.messageService.add({
              severity: "error",
              summary: "Notice",
              detail:
                "Unable to post payments at the moment.. Reason: [" +
                (error ? error.error.message : "request failed - permission") +
                "]",
            });
          }
        );
      },
    });
  }

  GetTotalBatchValue(item: ExpenseBatchRequest): number {
    let amt = 0;
    item.lineItems.forEach((x) => (amt += x.amount + x.vatAmount));

    return amt;
  }

  GetPercentagePaid(item: ExpenseBatchRequest): number {
    let perc = 0;
    let amt = 0;
    let amtPaid = 0;
    item.lineItems.forEach((x) => (amt += x.amount + x.vatAmount));
    item.payments.forEach((x) => (amtPaid += x.amountToPay + x.vatAmount));
    perc = (amtPaid / amt) * 100;

    return perc;
  }

  GetTotalAmountPaid(item: ExpenseBatchRequest): number {
    let amtPaid = 0;
    item.payments.forEach((x) => (amtPaid += x.amountToPay + x.vatAmount));

    return amtPaid;
  }

  GetBatchesTotal(items: ExpenseBatchRequest[]): number {
    let amt = 0;
    items.forEach((batch) => {
      batch.lineItems.forEach((item) => (amt += item.amount + item.vatAmount));
      amt -= this.GetTotalAmountPaid(batch);
    });

    return amt;
  }
}
