import { Component, OnInit } from '@angular/core';
import { BreadcrumbService } from 'src/app/breadcrumb.service';
import { BudgetapiservicesService } from 'src/app/services/budget/budgetapiservices.service';

@Component({
  selector: 'app-budget-distributions',
  templateUrl: './budget-distributions.component.html',
  styleUrls: ['./budget-distributions.component.css']
})
export class BudgetDistributionsComponent implements OnInit {

  budgetCycleLists: any[];
  budgetCycleTotalNumber: number | undefined
  budgetCycleTotalNumberCurrentYear: number | undefined
  loadingData: boolean = false;
  constructor(private apiService: BudgetapiservicesService,
              private breadcrumbService: BreadcrumbService) { }

  ngOnInit(): void {
    this.breadcrumbService.setItems([
      { label: "Budget Distribution Setup", routerLink: ["home/budget-distributions"] },
      // { label: "Role", routerLink: ["home/role"] },
    ]);
  }

  loadBudgetCycles() {
    
    return this.apiService.getBudgetCycleList().subscribe( res => {
      this.budgetCycleLists = res['responseData'];
      this.budgetCycleTotalNumber = res['responseData'].length;  //this.budgetCycleLists.length;
      this.budgetCycleTotalNumberCurrentYear = res['responseData'].filter(e=> Number(e.budgetYear)== new Date().getFullYear()).length
      // this.budgetCycleTotalNumberCurrentYear = this.budgetCycleLists.filter(e=> Number(e.budgetYear)== new Date().getFullYear()).length
      this.budgetCycleLists.forEach((elem, ind) => {
        if (new Date().valueOf() > new Date(elem.endDate).valueOf()){
          this.budgetCycleLists.splice(ind, 1); // (elem)
        }
      })
      this.loadingData = false;
      console.log(`Response from loading Budget cycle: ${JSON.stringify(res['responseData'][0])}`);
      console.log(this.budgetCycleLists)
    }) 
  }

}
