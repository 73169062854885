import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { EventInput } from "@fullcalendar/core";
import { ConfirmationService, MessageService } from "primeng/api";
import { finalize } from "rxjs/operators";
import { BreadcrumbService } from "src/app/breadcrumb.service";
import {
  ConvergenceInteractionType,
  CreateDeviceCategoryVM,
  CreateDeviceSubCategoryVM,
  CreateInteractionTypeVM,
  DeviceCategory,
  DeviceSubCategory,
  IncidentTypeActionEnum,
  UpdateDeviceCategoryVM,
  UpdateDeviceSubCategoryVM,
  UpdateInteractionTypeVM,
} from "src/app/interfaces/convergence";
import { CommonResponse } from "src/app/interfaces/home";
import { ConvergenceService } from "src/app/services/convergence.service";
import { FileStorageService } from "src/app/services/file-storage.service";

@Component({
  selector: "app-device-convergence-setup",
  templateUrl: "./device-convergence-setup.component.html",
  styleUrls: ["./device-convergence-setup.component.scss"],
})
export class DeviceConvergenceSetupComponent implements OnInit {
  @ViewChild("formWrapper") public formWrapper: ElementRef;
  interactionTypeForm: FormGroup;
  deviceCategoryForm: FormGroup;
  deviceSubCategoryForm: FormGroup;
  allInteractionActions: {
    value: string;
    key: number;
  }[];
  theInteractionAction: {
    value: string;
    key: number;
  };
  editingForm: boolean;
  editingDeviceCategForm: boolean;
  editingDeviceSubCategForm: boolean;
  fetchingInteractionTypes: boolean;
  fetchingDeviceCategs: boolean;
  fetchingDeviceSubCategs: boolean;
  allInteractionTypes: ConvergenceInteractionType[];
  selectedInteractionTypes: ConvergenceInteractionType[];
  allDeviceCategories: DeviceCategory[];
  selectedDeviceCategs: DeviceCategory[];
  allDeviceSubCategories: DeviceSubCategory[];
  selectedDeviceSubCategs: DeviceSubCategory[];
  interactionTypeCols: any[];
  deviceCategCols: any[];
  deviceSubCategCols: any[];
  interactionTypeToEdit: ConvergenceInteractionType;
  deviceCategToEdit: DeviceCategory;
  deviceSubCategToEdit: DeviceSubCategory;
  theDeviceCategory: DeviceCategory;
  imageSrc: string;
  uploadingNewPicture = false;
  file: any;
  valColor = "#424242";

  constructor(
    private fb: FormBuilder,
    public fileStorageService: FileStorageService,
    public convergenceService: ConvergenceService,
    private breadcrumbService: BreadcrumbService,
    public confirmationService: ConfirmationService,
    public messageService: MessageService
  ) {
    this.interactionTypeForm = fb.group({
      Caption: ["", Validators.required],
      Description: ["", Validators.required],
      Action: ["", Validators.required],
    });

    this.deviceCategoryForm = fb.group({
      ImageUrl: ["", Validators.required],
      Caption: ["", Validators.required],
      Description: ["", Validators.required],
      Color: ["", Validators.required],
    });

    this.deviceSubCategoryForm = fb.group({
      Caption: ["", Validators.required],
      Description: ["", Validators.required],
      Category: ["", Validators.required],
    });
  }

  ngOnInit(): void {
    this.breadcrumbService.setItems([
      {
        label: "Convergence",
      },
      {
        label: "Device Management",
      },
      {
        label: "Setup",
        routerLink: ["/home/convergence/device-management/setup"],
      },
    ]);

    this.interactionTypeCols = [
      { field: "caption", header: "Caption" },
      { field: "description", header: "Description" },
    ];

    this.deviceCategCols = [
      { field: "caption", header: "Caption" },
      { field: "description", header: "Description" },
    ];

    this.deviceSubCategCols = [
      { field: "caption", header: "Caption" },
      { field: "description", header: "Description" },
    ];

    this.allInteractionActions = [
      {
        key: IncidentTypeActionEnum.POST,
        value: "POST",
      },
      {
        key: IncidentTypeActionEnum.GET,
        value: "GET",
      },
      {
        key: IncidentTypeActionEnum.PATCH,
        value: "PATCH",
      },
      {
        key: IncidentTypeActionEnum.PUT,
        value: "PUT",
      },
    ];

    this.FetchAllInteractionTypes();
    this.FetchAllDeviceCategories();
    this.FetchAllDeviceSubCategories();
  }

  FetchAllInteractionTypes() {
    this.fetchingInteractionTypes = true;
    this.convergenceService.GetAllInteractionTypes().subscribe(
      async (data) => {
        this.allInteractionTypes = data;
        this.fetchingInteractionTypes = false;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all interaction types at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
        this.fetchingInteractionTypes = false;
      }
    );
  }

  FetchAllDeviceCategories() {
    this.fetchingDeviceCategs = true;
    this.convergenceService.GetAllDeviceCategories().subscribe(
      async (data) => {
        this.allDeviceCategories = data;
        this.fetchingDeviceCategs = false;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all interaction types at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
        this.fetchingDeviceCategs = false;
      }
    );
  }

  FetchAllDeviceSubCategories() {
    this.fetchingDeviceSubCategs = true;
    this.convergenceService.GetAllDeviceSubCategories().subscribe(
      async (data) => {
        this.allDeviceSubCategories = data;
        this.fetchingDeviceSubCategs = false;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all device sub-categories at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
        this.fetchingDeviceSubCategs = false;
      }
    );
  }

  CreateInteractionType() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Creating Interaction Types...",
    });

    const postData: CreateInteractionTypeVM = {
      action: this.theInteractionAction.key,
      caption: this.interactionTypeForm.get("Caption").value,
      description: this.interactionTypeForm.get("Description").value,
    };

    this.convergenceService.CreateInteractionType(postData).subscribe(
      async () => {
        await this.messageService.add({
          severity: "success",
          summary: "Completed",
          detail: "Interaction Type Created Successfully!",
        });

        this.theInteractionAction = null;
        this.interactionTypeForm.reset();
        this.FetchAllInteractionTypes();
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to create interaction type at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  UpdateInteractionType() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Updating Interaction Type...",
    });

    const id = this.interactionTypeToEdit.id;
    const postData: UpdateInteractionTypeVM = {
      action: this.theInteractionAction.key,
      caption: this.interactionTypeForm.get("Caption").value,
      description: this.interactionTypeForm.get("Description").value,
    };

    this.convergenceService.UpdateInteractionType(id, postData).subscribe(
      async () => {
        this.messageService.add({
          severity: "success",
          summary: "Completed",
          detail: "Interaction Type Updated Successfully.",
        });

        this.FetchAllInteractionTypes();
        this.editingForm = false;
        this.interactionTypeForm.reset();
        this.theInteractionAction = null;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to update interaction type at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  CloseEditingForm() {
    this.editingForm = false;
    this.interactionTypeForm.reset();
    this.interactionTypeToEdit = null;
    this.theInteractionAction = null;
  }

  DeleteInteractionType(item: ConvergenceInteractionType) {
    this.confirmationService.confirm({
      message: "Are you sure you want to remove interaction type?",
      accept: () => {
        this.messageService.add({
          severity: "info",
          summary: "Notice",
          detail: "Removing interaction type...",
        });

        this.convergenceService.DeleteInteractionType(item.id).subscribe(
          async () => {
            this.messageService.add({
              severity: "success",
              summary: "Removed",
              detail: "Removed successfully",
            });

            this.fetchingInteractionTypes = true;
            const index = this.allInteractionTypes.indexOf(item);
            if (index > -1) {
              this.allInteractionTypes.splice(index, 1);
            }
            this.fetchingInteractionTypes = false;
          },
          (error) => {
            console.log("Error: " + JSON.stringify(error));
            this.messageService.add({
              severity: "error",
              summary: "Notice",
              detail:
                "Unable to remove interaction type at the moment.. Reason: [" +
                error
                  ? error.error.message
                  : "request failed - permission" + "]",
            });
          }
        );
      },
    });
  }

  EditInteraction(item: ConvergenceInteractionType) {
    this.editingForm = true;
    this.interactionTypeForm.patchValue({
      Caption: item.caption,
      Description: item.description,
    });
    this.interactionTypeToEdit = item;
    this.theInteractionAction = this.allInteractionActions.find(
      (x) => x.key == item.incidentTypeAction
    );

    this.formWrapper.nativeElement.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "start",
    });
  }

  GetInteractionActionLabel(action: number): string {
    if (action == IncidentTypeActionEnum.POST) return "POST";
    if (action == IncidentTypeActionEnum.GET) return "GET";
    if (action == IncidentTypeActionEnum.PATCH) return "PATCH";
    if (action == IncidentTypeActionEnum.PUT) return "PUT";

    return "N/A";
  }

  onFileSelect(event) {
    if (!this.checkMimeType(event) || !this.checkFileSize(event)) {
      return;
    }

    this.uploadingNewPicture = true;
    const reader = new FileReader();
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      this.file = event.target.files[0];
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.imageSrc = reader.result as string;
      };
    }
  }

  checkMimeType = (event: EventInput): boolean => {
    const files = event.target.files[0];
    // list allow mime type
    const types = ["image/png", "image/jpeg", "image/jpg", "image/gif"];
    // loop access array
    if (!files) {
      this.messageService.add({
        severity: "error",
        summary: "Failed",
        detail: "Please select an Image file",
      });
      return false;
    }
    // compare file type find doesn't match
    if (types.every((type) => files.type !== type)) {
      // create error message and assign to container
      this.messageService.add({
        severity: "error",
        summary: "Failed",
        detail: "Image format not supported",
      });
      return false;
    }
    return true;
  };

  checkFileSize = (event: EventInput) => {
    const files = event.target.files[0];
    if (!files) return true;
    const size = 400000;
    if (files.size > size) {
      event.target.value = null;
      this.messageService.add({
        severity: "error",
        summary: "Failed",
        detail: "Image is too large, please pick a smaller file",
      });
      return false;
    }
    return true;
  };

  async CreateDeviceCategory() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Creating Device Category...",
    });

    const postData: CreateDeviceCategoryVM = {
      caption: this.deviceCategoryForm.get("Caption").value,
      description: this.deviceCategoryForm.get("Description").value,
      colorCode: this.valColor,
    };

    this.fileStorageService.UploadFileFromDataUrl(this.file);
    this.fileStorageService.onUploadFinished.subscribe(
      (resp: CommonResponse<string>) => {
        if (resp.responseCode != "00") {
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail: resp.responseMsg,
          });
        } else {
          postData.imageUrl = resp.responseData;
          this._createDeviceCategory(postData);
        }
      },
      (error) => {
        console.log("Error while uploading file " + error);
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail: "ERR: Unable to upload image to storage",
        });
      }
    );
  }

  _createDeviceCategory(postData: CreateDeviceCategoryVM) {
    this.convergenceService.CreateDeviceCategory(postData).subscribe(
      async () => {
        await this.messageService.add({
          severity: "success",
          summary: "Completed",
          detail: "Device Category Created Successfully!",
        });

        this.valColor = "#424242";
        this.imageSrc = null;
        this.uploadingNewPicture = false;
        this.file = null;
        this.deviceCategoryForm.reset();
        this.FetchAllDeviceCategories();
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to create device category at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  CloseEditingDeviceCategForm() {
    this.editingDeviceCategForm = false;
    this.deviceCategoryForm.reset();
    this.deviceCategToEdit = null;
    this.valColor = "#424242";
    this.imageSrc = null;
    this.uploadingNewPicture = false;
    this.file = null;
  }

  async UpdateDeviceCategory() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Updating Platform...",
    });

    const id = this.deviceCategToEdit.id;
    const postData: UpdateDeviceCategoryVM = {
      caption: this.deviceCategoryForm.get("Caption").value,
      description: this.deviceCategoryForm.get("Description").value,
      colorCode: this.valColor,
    };

    if (this.editingDeviceCategForm && !this.uploadingNewPicture) {
      this._updateDeviceCategory(id, postData);
    } else {
      this.fileStorageService.UploadFileFromDataUrl(this.file);
      this.fileStorageService.onUploadFinished.subscribe(
        (resp: CommonResponse<string>) => {
          if (resp.responseCode != "00") {
            this.messageService.add({
              severity: "error",
              summary: "Notice",
              detail: resp.responseMsg,
            });
          } else {
            postData.imageUrl = resp.responseData;
            this._updateDeviceCategory(id, postData);
          }
        },
        (error) => {
          console.log("Error while uploading file " + error);
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail: "ERR: Unable to upload image to storage",
          });
        }
      );
    }
  }

  _updateDeviceCategory(id: number, postData: UpdateDeviceCategoryVM) {
    this.convergenceService.UpdateDeviceCategory(id, postData).subscribe(
      async () => {
        await this.messageService.add({
          severity: "success",
          summary: "Completed",
          detail: "Device Category Updated Successfully!",
        });

        this.CloseEditingDeviceCategForm();
        this.FetchAllDeviceCategories();
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to update device category at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  DeleteDeviceCategory(item: DeviceCategory) {
    this.confirmationService.confirm({
      message: "Are you sure you want to remove device category?",
      accept: () => {
        this.messageService.add({
          severity: "info",
          summary: "Notice",
          detail: "Removing Device Category...",
        });

        this.convergenceService.DeleteDeviceCategory(item.id).subscribe(
          async () => {
            this.messageService.add({
              severity: "success",
              summary: "Removed",
              detail: "Removed successfully",
            });

            this.fetchingDeviceCategs = true;
            const index = this.allDeviceCategories.indexOf(item);
            if (index > -1) {
              this.allDeviceCategories.splice(index, 1);
            }
            this.fetchingDeviceCategs = false;
          },
          (error) => {
            console.log("Error: " + JSON.stringify(error));
            this.messageService.add({
              severity: "error",
              summary: "Notice",
              detail:
                "Unable to remove device category at the moment.. Reason: [" +
                error
                  ? error.error.message
                  : "request failed - permission" + "]",
            });
          }
        );
      },
    });
  }

  EditDeviceCateg(item: DeviceCategory) {
    this.editingDeviceCategForm = true;
    this.deviceCategoryForm.patchValue({
      Caption: item.caption,
      Description: item.description,
    });
    this.imageSrc = item.imageUrl;
    this.valColor = item.colorCode;
    this.deviceCategToEdit = item;

    this.formWrapper.nativeElement.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "start",
    });
  }

  CreateDeviceSubCategory() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Creating Device Sub-Category...",
    });

    const postData: CreateDeviceSubCategoryVM = {
      caption: this.deviceSubCategoryForm.get("Caption").value,
      description: this.deviceSubCategoryForm.get("Description").value,
      categoryId: this.theDeviceCategory.id,
    };

    this.convergenceService.CreateDeviceSubCategory(postData).subscribe(
      async () => {
        await this.messageService.add({
          severity: "success",
          summary: "Completed",
          detail: "Device Sub-Category Created Successfully!",
        });

        this.theDeviceCategory = null;
        this.deviceSubCategoryForm.reset();
        this.FetchAllDeviceSubCategories();
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to create device sub-category at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  CloseEditingDeviceSubCategForm() {
    this.editingDeviceSubCategForm = false;
    this.deviceSubCategoryForm.reset();
    this.deviceSubCategToEdit = null;
    this.theDeviceCategory = null;
  }

  UpdateDeviceSubCategory() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Updating Device Sub-Category...",
    });

    const id = this.deviceSubCategToEdit.id;
    const postData: UpdateDeviceSubCategoryVM = {
      caption: this.deviceSubCategoryForm.get("Caption").value,
      description: this.deviceSubCategoryForm.get("Description").value,
      categoryId: this.theDeviceCategory ? this.theDeviceCategory.id : null,
    };

    this.convergenceService.UpdateDeviceSubCategory(id, postData).subscribe(
      async () => {
        this.messageService.add({
          severity: "success",
          summary: "Completed",
          detail: "Device Sub-Category Updated Successfully.",
        });

        this.CloseEditingDeviceSubCategForm();
        this.FetchAllDeviceSubCategories();
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to update device sub-category at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  DeleteDeviceSubCategory(item: DeviceSubCategory) {
    this.confirmationService.confirm({
      message: "Are you sure you want to remove device sub-category?",
      accept: () => {
        this.messageService.add({
          severity: "info",
          summary: "Notice",
          detail: "Removing device sub-category...",
        });

        this.convergenceService.DeleteDeviceSubCategory(item.id).subscribe(
          async () => {
            this.messageService.add({
              severity: "success",
              summary: "Removed",
              detail: "Removed successfully",
            });

            this.fetchingDeviceSubCategs = true;
            const index = this.allDeviceSubCategories.indexOf(item);
            if (index > -1) {
              this.allDeviceSubCategories.splice(index, 1);
            }
            this.fetchingDeviceSubCategs = false;
          },
          (error) => {
            console.log("Error: " + JSON.stringify(error));
            this.messageService.add({
              severity: "error",
              summary: "Notice",
              detail:
                "Unable to remove device sub-category at the moment.. Reason: [" +
                error
                  ? error.error.message
                  : "request failed - permission" + "]",
            });
          }
        );
      },
    });
  }

  EditDeviceSubCateg(item: DeviceSubCategory) {
    this.editingDeviceSubCategForm = true;
    this.deviceSubCategoryForm.patchValue({
      Caption: item.caption,
      Description: item.description,
    });
    this.deviceSubCategToEdit = item;
    this.theDeviceCategory = this.allDeviceCategories.find(
      (x) => x.id == item.categoryId
    );

    this.formWrapper.nativeElement.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "start",
    });
  }

  GetDeviceCategoryLabel(categ: number): string {
    let category = this.allDeviceCategories.find((x) => x.id == categ);
    if (category) return category.caption;

    return "N/A";
  }
}
