import { ComplaintTypeService } from "./../../../services/complaint-type.service";
import { ComplaintType } from "./../../../interfaces/complaint-type";
import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { MessageService, ConfirmationService } from "primeng/api";

@Component({
  selector: "app-complaint-type",
  templateUrl: "./complaint-type.component.html",
  styleUrls: ["./complaint-type.component.scss"],
  providers: [ConfirmationService, MessageService],
})
export class ComplaintTypeComponent implements OnInit {
  @ViewChild("formWrapper") public formWrapper: ElementRef;
  form: FormGroup;
  editing = false;
  fetching = true;
  complaintTypes: ComplaintType[];
  selectedComplaintType: ComplaintType;
  cols: any[];
  private complaintTypeToEdit: ComplaintType;

  constructor(
    fb: FormBuilder,
    public messageService: MessageService,
    public complaintTypeService: ComplaintTypeService,
    public confirmationService: ConfirmationService
  ) {
    this.form = fb.group({
      Code: [""],
      Caption: ["", Validators.required],
      Description: ["", Validators.required],
    });
  }

  ngOnInit(): void {
    this.cols = [
      { field: "code", header: "Code" },
      { field: "caption", header: "Caption" },
      { field: "description", header: "Description" },
    ];
    this.FetchAllComplaintTypes();
  }

  FetchAllComplaintTypes() {
    this.complaintTypeService.getAll().subscribe(
      async (compType) => {
        this.complaintTypes = compType.responseData;
        this.fetching = false;
      },
      (error) => {
        this.fetching = false;
        this.ConnectionError();
      }
    );
  }

  CreateComplaintType() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Creating Complaint Type...",
    });

    const postData: ComplaintType = {
      code: this.form.get("Code").value,
      caption: this.form.get("Caption").value,
      description: this.form.get("Description").value,
    };

    this.complaintTypeService.create(postData).subscribe(
      async () => {
        await this.messageService.add({
          severity: "success",
          summary: "Completed",
          detail: "New Complaint Type Created...",
        });

        this.FetchAllComplaintTypes();
        this.form.reset();
      },
      (error) => {
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to create complaint type at the moment.. Reason: [" +
            JSON.stringify(error) +
            "]",
        });
      }
    );
  }

  EditComplaintType(item: ComplaintType) {
    this.editing = true;
    this.form.addControl(
      "ID",
      new FormControl({ value: "", disabled: true }, Validators.required)
    );
    this.complaintTypeToEdit = item;
    this.form.patchValue({
      ID: item.id,
      Code: item.code,
      Caption: item.caption,
      Description: item.description,
    });
    this.formWrapper.nativeElement.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "start",
    });
  }

  UpdateComplaintType() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Updating Selected Complaint Type...",
    });
    const id = this.complaintTypeToEdit.id;
    const postData: ComplaintType = {
      code: this.form.get("Code").value,
      caption: this.form.get("Caption").value,
      description: this.form.get("Description").value,
    };
    this.complaintTypeService.update(id, postData).subscribe(
      async () => {
        this.messageService.add({
          severity: "success",
          summary: "Notice",
          detail: "Update Successful!",
        });
        await this.FetchAllComplaintTypes();
        await this.CloseEditing();
      },
      (error) => {
        this.messageService.add({
          severity: "error",
          summary: "Unable To Update",
          detail:
            "Unable to update complaint type at the moment.. Reason: [" +
            JSON.stringify(error) +
            "]",
        });
      }
    );
  }

  DeleteComplaintType(item: ComplaintType) {
    this.confirmationService.confirm({
      message: "Are you sure you want to remove " + item.caption,
      accept: () => {
        this.messageService.add({
          severity: "info",
          summary: "Notice",
          detail: "Removing complaint type " + item.caption,
        });
        this.complaintTypeService.delete(item.id).subscribe(
          async () => {
            await this.messageService.add({
              severity: "success",
              summary: "Removed",
              detail: item.caption + " removed successfully",
            });
            await this.FetchAllComplaintTypes();
          },
          (error) => {
            this.ConnectionError();
          }
        );
      },
    });
  }

  CloseEditing() {
    this.editing = false;
    this.form.reset();
    this.complaintTypeToEdit = null;
  }

  private ConnectionError() {
    this.messageService.add({
      severity: "error",
      summary: "Failed",
      detail: "Connection Error, Please try again",
    });
  }
}
