import {
  Cluster,
  CreateApprovingOffice,
  DeployedGuard,
  GMAZone,
  GuardDeployment,
} from "./../interfaces/employment";
import {
  CustomerDivision,
  GMAGenericResponse,
  User,
} from "src/app/interfaces/home";
import { Account } from "./../interfaces/home";
import {
  ActOnOPAbsenceReportVM,
  ActOnPayrollApprovalVM,
  ApprovePayrollOperationsVM,
  ApproveSpecialRequestsVM,
  AssignMembersToPayGradeVM,
  AssignMembersToPayGroupVM,
  ComputeTaxRequestVM,
  CreateDeductionVM,
  CreateOvertimeBatchVM,
  CreatePayGradeVM,
  CreatePayGroupVM,
  CreateSpecialDeductionBatchVM,
  CreateSpecialSettingVM,
  CreateTaxVM,
  Deduction,
  FilteredPayrollPensionAcctVM,
  GeneralPayrunComaprisonResultVM,
  GetAbsenceReportStatisticsVM,
  GetAllActualEntityUnderPayrollRunVM,
  GetAllPayrollApprovalItemsVM,
  GetAllRunPeriodAndPaygroupVM,
  GetFilteredPayrunOperationsVM,
  GetGeneralPayrunComaprisonResultVM,
  GetPaidVsQtyBilledReportsVM,
  GetPayeStateReportsVM,
  GetPayrollRunOperationsWithReportViewVM,
  GetPayrollRunsAnalyticsVM,
  GetPayrollVsContractReportVM,
  GetPayrollVsContractRequestDitsVM,
  GetSpecificOPPayrunComaprisonResultVM,
  InitiatePayrollRunVM,
  LogOperativeAbsenceReportVM,
  OperativeAbsenceReport,
  OperativesAbsent,
  PayGrade,
  PayGroup,
  PayrollApprovingOffice,
  PayrollFinancialPostingVM,
  PayrollRun,
  PayrollRunOperation,
  PayrollSetupAccounts,
  PayrollVsContractReportVM,
  SpecificPayrunComaprisonResultVM,
  Tax,
  TaxComputationResultVM,
  UpdateAllowance,
  UpdateDeductionVM,
  UpdatePayGradeVM,
  UpdatePayGroupVM,
  UpdateSpecialSettingVM,
  UpdateTaxVM,
} from "./../interfaces/payroll";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { Allowance, CreateAllowance } from "../interfaces/payroll";

@Injectable({
  providedIn: "root",
})
export class PayrollService {
  baseUrl = environment.gmaBaseUrl + "Payroll/";

  constructor(private http: HttpClient) {}

  CreateAllowance(data: CreateAllowance) {
    return this.http.post(this.baseUrl + "CreateAllowance", data);
  }

  GetAllAllowances(): Observable<Allowance[]> {
    return this.http.get<Allowance[]>(this.baseUrl + "GetAllAllowances");
  }

  UpdateAllowance(allowanceId: number, data: UpdateAllowance) {
    return this.http.put(this.baseUrl + "UpdateAllowance/" + allowanceId, data);
  }

  DeleteAllowance(allowanceId: number) {
    return this.http.delete(this.baseUrl + "DeleteAllowance/" + allowanceId);
  }

  GetAllAccountsUnderControlAccount(accountKey: number): Observable<Account[]> {
    let controlAccountId = 0;
    if (accountKey == 1) controlAccountId = +environment.assetControlAccountId;
    else if (accountKey == 2)
      controlAccountId = +environment.liabilityControlAccountId;
    else if (accountKey == 3)
      controlAccountId = +environment.expenseControlAccountId;
    else throw "Invalid Account Key";

    return this.http.get<Account[]>(
      this.baseUrl + "GetAllAccountsUnderControlAccount/" + controlAccountId
    );
  }

  GetAllAccountsForPayrollSetup(): Observable<PayrollSetupAccounts> {
    return this.http.get<PayrollSetupAccounts>(
      this.baseUrl + "GetAllAccountsForPayrollSetup"
    );
  }

  CreateDeduction(data: CreateDeductionVM) {
    return this.http.post(this.baseUrl + "CreateDeduction", data);
  }

  GetAllDeductions(): Observable<Deduction[]> {
    return this.http.get<Deduction[]>(this.baseUrl + "GetAllDeductions");
  }

  UpdateDeduction(deductionId: number, data: UpdateDeductionVM) {
    return this.http.put(this.baseUrl + "UpdateDeduction/" + deductionId, data);
  }

  DeleteDeduction(deductionId) {
    return this.http.delete(this.baseUrl + "DeleteDeduction/" + deductionId);
  }

  CreatePayGrade(data: CreatePayGradeVM) {
    return this.http.post(this.baseUrl + "CreatePayGrade", data);
  }

  GetAllPayGrades(): Observable<PayGrade[]> {
    return this.http.get<PayGrade[]>(this.baseUrl + "GetAllPayGrades");
  }

  DeletePayGrade(payGradeId: number) {
    return this.http.delete(this.baseUrl + "DeletePayGrade/" + payGradeId);
  }

  UpdatePayGrade(payGradeId: number, data: UpdatePayGradeVM) {
    return this.http.put(this.baseUrl + "UpdatePayGrade/" + payGradeId, data);
  }

  CreateTax(data: CreateTaxVM) {
    return this.http.post(this.baseUrl + "CreateTax", data);
  }

  GetAllTaxes(): Observable<Tax[]> {
    return this.http.get<Tax[]>(this.baseUrl + "GetAllTaxes");
  }

  DeleteTax(taxId: number) {
    return this.http.delete(this.baseUrl + "DeleteTax/" + taxId);
  }

  UpdateTax(taxId: number, data: UpdateTaxVM) {
    return this.http.put(this.baseUrl + "UpdateTax/" + taxId, data);
  }

  CreatePayGroup(data: CreatePayGroupVM) {
    return this.http.post(this.baseUrl + "CreatePayGroup", data);
  }

  GetAllPayGroups(): Observable<PayGroup[]> {
    return this.http.get<PayGroup[]>(this.baseUrl + "GetAllPayGroups");
  }

  UpdatePayGroup(payGroupId: number, data: UpdatePayGroupVM) {
    return this.http.put(this.baseUrl + "UpdatePayGroup/" + payGroupId, data);
  }

  DeletePayGroup(payGroupId: number) {
    return this.http.delete(this.baseUrl + "DeletePayGroup/" + payGroupId);
  }

  GetAllOAClients(): Observable<CustomerDivision[]> {
    return this.http.get<CustomerDivision[]>(this.baseUrl + "GetAllOAClients");
  }

  GetAllLocationDeployedGuards(
    locationId: number
  ): Observable<GuardDeployment[]> {
    return this.http.get<GuardDeployment[]>(
      this.baseUrl + "GetAllLocationDeployedGuards/" + locationId
    );
  }

  GetAllPayGradeWithMembers(): Observable<PayGrade[]> {
    return this.http.get<PayGrade[]>(
      this.baseUrl + "GetAllPayGradeWithMembers"
    );
  }

  GetAllPayGroupWithMembers(): Observable<PayGroup[]> {
    return this.http.get<PayGroup[]>(
      this.baseUrl + "GetAllPayGroupWithMembers"
    );
  }

  AssignMembersToPayGrade(data: AssignMembersToPayGradeVM) {
    return this.http.post(this.baseUrl + "AssignMembersToPayGrade", data);
  }

  AssignMembersToPayGroup(data: AssignMembersToPayGroupVM) {
    return this.http.post(this.baseUrl + "AssignMembersToPayGroup", data);
  }

  ComputeTax(data: ComputeTaxRequestVM): Observable<TaxComputationResultVM> {
    return this.http.post<TaxComputationResultVM>(
      this.baseUrl + "ComputeTax",
      data
    );
  }

  GetAllClustersTiedToMe(): Observable<Cluster[]> {
    return this.http.get<Cluster[]>(this.baseUrl + "GetAllClustersTiedToMe");
  }

  GetMyActiveAbsenceReport(): Observable<OperativeAbsenceReport> {
    return this.http.get<OperativeAbsenceReport>(
      this.baseUrl + "GetMyActiveAbsenceReport"
    );
  }

  ClearMyOperativeAbsenceReport(): Observable<boolean> {
    return this.http.get<boolean>(
      this.baseUrl + "ClearMyOperativeAbsenceReport"
    );
  }

  SendMyOperativeReportForApproval(): Observable<boolean> {
    return this.http.get<boolean>(
      this.baseUrl + "SendMyOperativeReportForApproval"
    );
  }

  LogOperativeAbsenceReport(
    data: LogOperativeAbsenceReportVM
  ): Observable<boolean> {
    return this.http.post<boolean>(
      this.baseUrl + "LogOperativeAbsenceReport",
      data
    );
  }

  GetAllClustersInMyZone(): Observable<GMAZone[]> {
    return this.http.get<GMAZone[]>(this.baseUrl + "GetAllClustersInMyZone");
  }

  GetAllPendingOPAbsentReportInCluster(
    clusterId: number
  ): Observable<OperativesAbsent[]> {
    return this.http.get<OperativesAbsent[]>(
      this.baseUrl + "GetAllPendingOPAbsentReportInCluster/" + clusterId
    );
  }

  ActOnOPAbsenceReport(data: ActOnOPAbsenceReportVM) {
    return this.http.post(this.baseUrl + "ActOnOPAbsenceReport", data);
  }

  GetCurrentlyLoggedInUserDetails(): Observable<User> {
    return this.http.get<User>(
      this.baseUrl + "GetCurrentlyLoggedInUserDetails"
    );
  }

  GetAbsenceReportStatistics(): Observable<GetAbsenceReportStatisticsVM> {
    return this.http.get<GetAbsenceReportStatisticsVM>(
      this.baseUrl + "GetAbsenceReportStatistics"
    );
  }

  InitiatePayrollRun(data: InitiatePayrollRunVM) {
    return this.http.post(this.baseUrl + "InitiatePayrollRun", data);
  }

  GetPayrollRunsAnalytics(): Observable<GetPayrollRunsAnalyticsVM> {
    return this.http.get<GetPayrollRunsAnalyticsVM>(
      this.baseUrl + "GetPayrollRunsAnalytics"
    );
  }

  GetPayrollRunsWithinDatePeriod(period: Date): Observable<PayrollRun[]> {
    return this.http.post<PayrollRun[]>(
      this.baseUrl + "GetPayrollRunsWithinDatePeriod",
      { period: period }
    );
  }

  RunPayrollRunTask() {
    return this.http.get(this.baseUrl + "RunPayrollRunTask");
  }

  GetPayrollRunOPs(payrollRunId: number): Observable<DeployedGuard[]> {
    return this.http.get<DeployedGuard[]>(
      this.baseUrl + "GetPayrollRunOPs/" + payrollRunId
    );
  }

  GetOperativePayslip(
    payrollRunId: number,
    deployedGuardId: number
  ): Observable<PayrollRunOperation> {
    return this.http.get<PayrollRunOperation>(
      this.baseUrl +
        "GetOperativePayslip/" +
        payrollRunId +
        "/" +
        deployedGuardId
    );
  }

  GetAllRunPeriodAndPaygroup(): Observable<GetAllRunPeriodAndPaygroupVM> {
    return this.http.get<GetAllRunPeriodAndPaygroupVM>(
      this.baseUrl + "GetAllRunPeriodAndPaygroup"
    );
  }

  GetGeneralPayrunComaprisonResult(
    data: GetGeneralPayrunComaprisonResultVM
  ): Observable<GeneralPayrunComaprisonResultVM> {
    return this.http.post<GeneralPayrunComaprisonResultVM>(
      this.baseUrl + "GetGeneralPayrunComaprisonResult",
      data
    );
  }

  GetAllActualEntityUnderPayrollRun(
    payrollRunId: number
  ): Observable<GetAllActualEntityUnderPayrollRunVM> {
    return this.http.get<GetAllActualEntityUnderPayrollRunVM>(
      this.baseUrl + "GetAllActualEntityUnderPayrollRun/" + payrollRunId
    );
  }

  GetSpecificOPPayrunComaprisonResult(
    data: GetSpecificOPPayrunComaprisonResultVM
  ): Observable<SpecificPayrunComaprisonResultVM> {
    return this.http.post<SpecificPayrunComaprisonResultVM>(
      this.baseUrl + "GetSpecificOPPayrunComaprisonResult",
      data
    );
  }

  GetAllApprovingOffice(): Observable<PayrollApprovingOffice[]> {
    return this.http.get<PayrollApprovingOffice[]>(
      this.baseUrl + "GetAllApprovingOffice"
    );
  }

  CreateApprovingOffice(data: CreateApprovingOffice) {
    return this.http.post(this.baseUrl + "CreateApprovingOffice", data);
  }

  UpdateApprovingOffice(officeId: number, data: CreateApprovingOffice) {
    return this.http.put(
      this.baseUrl + "UpdateApprovingOffice/" + officeId,
      data
    );
  }

  DeleteApprovingOffice(officeId: number) {
    return this.http.delete(this.baseUrl + "DeleteApprovingOffice/" + officeId);
  }

  MoveSequence(data: { sequence: number; goesUp: boolean }) {
    return this.http.post(this.baseUrl + "MoveSequence", data);
  }

  SendPayrollRunForApproval(payrollRunId: number) {
    return this.http.get(
      this.baseUrl + "SendPayrollRunForApproval/" + payrollRunId
    );
  }

  GetAllPayrollApprovalItems(): Observable<GetAllPayrollApprovalItemsVM> {
    return this.http.get<GetAllPayrollApprovalItemsVM>(
      this.baseUrl + "GetAllPayrollApprovalItems"
    );
  }

  ActOnPayrollApproval(data: ActOnPayrollApprovalVM) {
    return this.http.post(this.baseUrl + "ActOnPayrollApproval", data);
  }

  GetPayrollRunOperationsWithReportView(
    payrollRunId: number
  ): Observable<GetPayrollRunOperationsWithReportViewVM> {
    return this.http.get<GetPayrollRunOperationsWithReportViewVM>(
      this.baseUrl + "GetPayrollRunOperationsWithReportView/" + payrollRunId
    );
  }

  GetPayrollRunClosureDetails(
    payrollRunId: number
  ): Observable<PayrollFinancialPostingVM[]> {
    return this.http.get<PayrollFinancialPostingVM[]>(
      this.baseUrl + "GetPayrollRunClosureDetails/" + payrollRunId
    );
  }

  CloseFinalPayrollRun(payrollRunId: number): Observable<boolean> {
    return this.http.get<boolean>(
      this.baseUrl + "CloseFinalPayrollRun/" + payrollRunId
    );
  }

  GetFilteredPayrunOperations(
    data: GetFilteredPayrunOperationsVM
  ): Observable<GetPayrollRunOperationsWithReportViewVM> {
    return this.http.post<GetPayrollRunOperationsWithReportViewVM>(
      this.baseUrl + "GetFilteredPayrunOperations",
      data
    );
  }

  GetFilteredAccountList(): Observable<FilteredPayrollPensionAcctVM> {
    return this.http.get<FilteredPayrollPensionAcctVM>(
      this.baseUrl + "GetFilteredAccountList"
    );
  }

  GetPayrollVsContractRequestDits(): Observable<GetPayrollVsContractRequestDitsVM> {
    return this.http.get<GetPayrollVsContractRequestDitsVM>(
      this.baseUrl + "GetPayrollVsContractRequestDits"
    );
  }

  GetPayrollVsContractReport(
    data: GetPayrollVsContractReportVM
  ): Observable<PayrollVsContractReportVM[]> {
    return this.http.post<PayrollVsContractReportVM[]>(
      this.baseUrl + "GetPayrollVsContractReport",
      data
    );
  }

  GetPayeStateReports(
    data: GetPayeStateReportsVM
  ): Observable<GMAGenericResponse> {
    return this.http.post<GMAGenericResponse>(
      this.baseUrl + "GetPayeStateReports",
      data
    );
  }

  GetPaidVsQtyBilledReports(
    data: GetPaidVsQtyBilledReportsVM
  ): Observable<GMAGenericResponse> {
    return this.http.post<GMAGenericResponse>(
      this.baseUrl + "GetPaidVsQtyBilledReports",
      data
    );
  }

  CreateSpecialSetting(
    data: CreateSpecialSettingVM
  ): Observable<GMAGenericResponse> {
    return this.http.post<GMAGenericResponse>(
      this.baseUrl + "CreateSpecialSetting",
      data
    );
  }

  UpdateSpecialSetting(
    settingID: number,
    data: UpdateSpecialSettingVM
  ): Observable<GMAGenericResponse> {
    return this.http.put<GMAGenericResponse>(
      this.baseUrl + "UpdateSpecialSetting/" + settingID,
      data
    );
  }

  RemoveSpecialAllowance(allowanceId: number): Observable<GMAGenericResponse> {
    return this.http.delete<GMAGenericResponse>(
      this.baseUrl + "RemoveSpecialAllowance/" + allowanceId
    );
  }

  RemoveSpecialDeduction(deductionId: number): Observable<GMAGenericResponse> {
    return this.http.delete<GMAGenericResponse>(
      this.baseUrl + "RemoveSpecialDeduction/" + deductionId
    );
  }

  GetAllSpecialAllowances(): Observable<GMAGenericResponse> {
    return this.http.get<GMAGenericResponse>(
      this.baseUrl + "GetAllSpecialAllowances"
    );
  }

  GetAllSpecialDeductions(): Observable<GMAGenericResponse> {
    return this.http.get<GMAGenericResponse>(
      this.baseUrl + "GetAllSpecialDeductions"
    );
  }

  GetOvertimeRequestDits(): Observable<GMAGenericResponse> {
    return this.http.get<GMAGenericResponse>(
      this.baseUrl + "GetOvertimeRequestDits"
    );
  }

  GetCurrentOvertimeBatch(): Observable<GMAGenericResponse> {
    return this.http.get<GMAGenericResponse>(
      this.baseUrl + "GetCurrentOvertimeBatch"
    );
  }

  GetClientOperatives(
    customerDiviionId: number
  ): Observable<GMAGenericResponse> {
    return this.http.get<GMAGenericResponse>(
      this.baseUrl + "GetClientOperatives/" + customerDiviionId
    );
  }

  CreateOvertimeBatch(
    data: CreateOvertimeBatchVM
  ): Observable<GMAGenericResponse> {
    return this.http.post<GMAGenericResponse>(
      this.baseUrl + "CreateOvertimeBatch",
      data
    );
  }

  DeleteOvertimeAllowanceRequest(
    requestId: number
  ): Observable<GMAGenericResponse> {
    return this.http.delete<GMAGenericResponse>(
      this.baseUrl + "DeleteOvertimeAllowanceRequest/" + requestId
    );
  }

  GetSpecialDeductionRequestDits(): Observable<GMAGenericResponse> {
    return this.http.get<GMAGenericResponse>(
      this.baseUrl + "GetSpecialDeductionRequestDits"
    );
  }

  GetCurrentSpecialDeductionBatch(): Observable<GMAGenericResponse> {
    return this.http.get<GMAGenericResponse>(
      this.baseUrl + "GetCurrentSpecialDeductionBatch"
    );
  }

  CreateSpecialDeductionBatch(
    data: CreateSpecialDeductionBatchVM
  ): Observable<GMAGenericResponse> {
    return this.http.post<GMAGenericResponse>(
      this.baseUrl + "CreateSpecialDeductionBatch",
      data
    );
  }

  DeleteSpecialDeductionRequest(
    requestId: number
  ): Observable<GMAGenericResponse> {
    return this.http.delete<GMAGenericResponse>(
      this.baseUrl + "DeleteSpecialDeductionRequest/" + requestId
    );
  }

  GetAllSpecialApprovingOffice(): Observable<PayrollApprovingOffice[]> {
    return this.http.get<PayrollApprovingOffice[]>(
      this.baseUrl + "GetAllSpecialApprovingOffice"
    );
  }

  CreateSpecialApprovingOffice(data: CreateApprovingOffice) {
    return this.http.post(this.baseUrl + "CreateSpecialApprovingOffice", data);
  }

  UpdateSpecialApprovingOffice(officeId: number, data: CreateApprovingOffice) {
    return this.http.put(
      this.baseUrl + "UpdateSpecialApprovingOffice/" + officeId,
      data
    );
  }

  DeleteSpecialApprovingOffice(officeId: number) {
    return this.http.delete(
      this.baseUrl + "DeleteSpecialApprovingOffice/" + officeId
    );
  }

  MoveSpecialSequence(data: { sequence: number; goesUp: boolean }) {
    return this.http.post(this.baseUrl + "MoveSpecialSequence", data);
  }

  SendSpecialAllowanceForApproval(): Observable<GMAGenericResponse> {
    return this.http.get<GMAGenericResponse>(
      this.baseUrl + "SendSpecialAllowanceForApproval"
    );
  }

  SendSpecialDeductionForApproval(): Observable<GMAGenericResponse> {
    return this.http.get<GMAGenericResponse>(
      this.baseUrl + "SendSpecialDeductionForApproval"
    );
  }

  GetAllSpecialRequestsPendingApproval(): Observable<GMAGenericResponse> {
    return this.http.get<GMAGenericResponse>(
      this.baseUrl + "GetAllSpecialRequestsPendingApproval"
    );
  }

  GetAllSpecialApprovingOfficeAlone(): Observable<PayrollApprovingOffice[]> {
    return this.http.get<PayrollApprovingOffice[]>(
      this.baseUrl + "GetAllSpecialApprovingOfficeAlone"
    );
  }

  ApproveSpecialRequests(
    data: ApproveSpecialRequestsVM
  ): Observable<GMAGenericResponse> {
    return this.http.post<GMAGenericResponse>(
      this.baseUrl + "ApproveSpecialRequests",
      data
    );
  }

  ApprovePayrollOperations(
    data: ApprovePayrollOperationsVM
  ): Observable<GMAGenericResponse> {
    return this.http.post<GMAGenericResponse>(
      this.baseUrl + "ApprovePayrollOperations",
      data
    );
  }
}
