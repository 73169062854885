//import { state } from "@angular/animations";
import {
  Branch,
  ContractService,
  CustomerDivision,
  Lead,
  LeadContact,
  LeadDivisionKeyPerson,
  LeadOrigin,
  LeadType,
  Lga,
  Office,
  Services,
  State,
  User,
} from "./home";
import { AssignmentStatus } from "../home/armada/dashboard/assignment-status.enum";

export interface CommanderRank {
  rankName: string;
  alias: string;
  description: string;
  id?: number;
  commanderTypeId?: number;
  commanderType: CommanderType;
  preceedingRankId?: number;
  nextRankId?: number;
  sequence: number;
  preceedingRank?: any;
  nextRank?: any;
}

export interface CommanderType {
  serviceRegistrationId: number;
  serviceRegistration: ServiceRegistration;
  typeName: string;
  typeDesc: string;
  CreatedById: number;
  id?: number;
}
export interface CommanderResourceRequiredPerService {
  id?: number;
  commanderTypeId: number;
  commanderType: CommanderType;
  serviceRegistrationId: number;
  serviceRegistration: ServiceRegistration;
  CreatedById: number;
  isDeleted: boolean;
}

export interface AddResourceRequiredPerService {
  id?: number;
  commanderTypeId: number[];
  pilotTypeId: number[];
  vehicleTypeId: number[];
  armedEscortTypeId: number[];
  //commanderType: CommanderType;
  serviceRegistrationId: number;
  //serviceRegistration: ServiceRegistration;
}

export interface SMORegion {
  regionName: string;
  regionDescription?: string;
  CreatedById?: number;
  CreatedAt?: string;
  UpdatedAt?: string;
  smoRoute: SMORoute[];
  smoRouteId?: number;
  id?: number;
}
export const adhovVal = 6;
export interface SMORoute {
  routeName: string;
  routeDescription?: string;
  smoRegion: SMORegion;
  smoRegionId: number;
  CreatedById?: number;
  isReturnRouteRequired?: boolean;
  rRecoveryTime?: number;
  CreatedAt?: string;
  UpdatedAt: string;
  CreatedBy?: User;
  id?: number;
}

export interface SMORouteAndSateMap {
  comment?: string;
  smoRegion: SMORegion;
  smoRegionId: number;
  smoRoute: SMORoute;
  smoRouteId: number;
  createdById?: number;
  stateId?: number;
  state: State;
  //CreatedAt?: string;
  updatedAt: string;
  createdBy?: User;
  id?: number;
}

export interface ToAndFroMasterStateMap {
  smoRoute: SMORoute;
  toStateMapDetails: ToStateMapDetails[];
  froStateMapDetails: FroStateMapDetails[];
  smoRouteId: number;
  comment: string;
  updatedAt: string;
  createdBy?: User;
  id?: number;
}
export interface ToStateMapDetails {
  updatedAt: string;
  //createdBy?: User;
  id: number;
  toAndFroStateMapMasterId: number;
  toAndFroStateMapMaster: ToAndFroMasterStateMap;
  toStateAllowed: State;
  toStateAllowedId: number;
}
export interface FroStateMapDetails {
  updatedAt: string;
  //createdBy?: User;
  id: number;
  toAndFroStateMapMasterId: number;
  toAndFroStateMapMaster: ToAndFroMasterStateMap;
  froStateAllowed: State;
  froStateAllowedId: number;
}
export interface SMOReturnRoute {
  returnRouteId?: number;
  rRecoveryTime?: number;
  CreatedById?: number;
  CreatedAt?: string;
  UpdatedAt?: string;
  returnRoute: SMORoute;
  smoRouteId: number;
  smoRoute: SMORoute;
  id?: number;
}

export interface SMOReturnRouteAndRouteEdit {
  routeName: string;
  routeDescription?: string;
  smoRegion: SMORegion;
  smoRegionId: number;
  CreatedById?: number;
  isReturnRouteRequired?: boolean;
  recoveryTime?: string;
  CreatedAt?: string;
  UpdatedAt: string;
  CreatedBy?: User;
  id?: number;
  smoReturnRoute: SMOReturnRoute;
}

export interface ServiceRegistration {
  createdAt: string;
  createdBy: User;
  createdById: number;
  applicableArmedEscortTypes: ArmedEscortType;
  applicableVehicleTypes: VehicleType;
  applicablePilotTypes: PilotType;
  applicableCommanderTypes: CommanderType;
  armedEscortQuantityRequired: number;
  vehicleQuantityRequired: number;
  commanderQuantityRequired: number;
  pilotQuantityRequired: number;
  updatedAt: string;
  requiresArmedEscort: boolean;
  requiresPilot: boolean;
  requiresVehicle: boolean;
  requiresCommander: boolean;
  //description: string;
  id: number;
  serviceId: number;
  isDeleted: boolean;

  service: Services;
}

export interface BusinessRule {
  isDeleted: boolean;
  createdAt: string;
  createdBy: User;
  id?: number;
  createdById: number;
  serviceRegistrationId: number;
  serviceRegistration: ServiceRegistration;
  isQuantityRequired: boolean;
  maxQuantity: number;
  isPairingRequired: boolean;
}

export interface BRPairable {
  isDeleted: boolean;
  createdAt: string;
  createdBy: User;
  id?: number;
  createdById: number;
  serviceRegistrationId: number[];
  serviceRegistration: ServiceRegistration;
  businessRule: BusinessRule;
  businessRuleId: number;
}
export interface PurchaseAsPair {
  isDeleted: boolean;
  createdAt: string;
  createdBy: User;
  id?: number;
  createdById: number;
  serviceRegistrationId: number;
  serviceRegistration: ServiceRegistration;
 
}
export interface CommanderProfile {
  attachedBranchId: number;
  attachedOfficeId: number;
  profileId: number;
  profile: User;
  attachedOffice: Office;
  attachedBranch: Branch;
  fullName: string;
  rankId: number;
  rank: CommanderRank;
  commanderType: CommanderType;
  commanderTypeId: number;
  isOwned:boolean;
  id?: number;
  reserveFroPatrolAndOrEmergencyResponse: boolean;
}

export interface VehicleProfile {
  attachedBranchId: number;
  attachedOfficeId: number;
  supplierServiceId: number;
  attachedOffice: Office;
  attachedBranch: Branch;
  vehicleTypeId: number;
  vehicleType: VehicleType;
  supplierService: SupplierServices;
  id: number;
  isOwned:boolean;
  reserveFroPatrolAndOrEmergencyResponse: boolean;
}

export interface VehicleDriverMap {
  id: number;
  registeredVehicleId: number;
  registeredVehicle: VehicleProfile;
  registeredPilotId: number;
  registeredPilot: PilotProfile;
  //createdBy: User;
  isDeleted: boolean;
}
export interface CommanderUserProfile {
  codeName: string;
  firstName: string;
  lastName: string;
  middleName: string;
  dateOfBirth: string;
  email: string;
  mobileNumber: string;
  imageUrl: string;
  address: string;
  branchId: number;
  officeId: number;
  staffId: number;
  userProfileId: number;
  commanderRankId: number;
  id?: number;
  serviceAssignmentId?: number;
  vehicleAssigned?: SupplierServices;
  vehicleAssignedId?: number;
  isAssigned?: boolean;
  dateAssignedCommander?: string;
  dateAssignedVehicle?: string;
  fullName?: string;
  rank?: CommanderRank;
  commanderRank?: CommanderRank;
}
export interface SituationType {
  caption: string;
  description: string;
  codeName: string;
  id?: number;
}
export interface ServiceAssignment {
  contractServiceId: number;
  serviceAssignmentReferenceNumberCommander: string;
  assignedCommanderId: number;
  assignedVehicleId: number;
  serviceReferenceNumberVehicle: string;
  dateAssignedCommander: string;
  dateAssignedVehicle: string;
  acceptanceStatus?: boolean;
  remark?: string;
  detailingOfficer?: string;
  assignedById?: number;
  assignedBy?: User;
  id?: number;
  contractService?: ContractService;
  assignedCommander?: CommanderUserProfile;
  commanderUserProfiles?: CommanderUserProfile[];
  otherPassengers?: OtherPassenger[];
  armedEscortProfiles?: ArmedEscortProfile[];
  journeyStarted?: boolean;
  assignedVehicle?: SupplierServices;
  vehicles?: SupplierServices[];
  isAccepted?: boolean | null;
  assignmentStatus?: AssignmentStatus | null;
  tempName?: string;
}
export interface Supplier {
  supplierName: string;
  description: string;
  supplierCategoryId: number;
  supplierEmail: string;
  mobileNumber: string;
  stateId: number;
  lgaId: number;
  street: string;
  address: string;
  imageUrl: string;
  contacts?: string;
  id?: number;
  supplierServices?: SupplierServices[];
}

// export enum ConfirmEventType {
//   ACCEPT = 0,
//   REJECT = 1,
//   CANCEL = 2
// }


export interface MasterServiceAssignment {
  id: number;
  createdAt: string;
  isReturnJourney: boolean;
  inhouseAssignment: boolean;
  isScheduled: boolean;
  isScheduledTrip: boolean;
  primaryTripAssignmentId: number;
  tripTypeId: number;
  tripType: TripType;
  sMORouteId: number;
  smoRoute: SMORoute;
  sMORegionId: number;
  sMORegion: SMORegion;
  readinessTime: string;
  dropoffLocation: string;
  pickoffLocation: string;
  pickupDate: string;
  createdBy: User;
  dropoffDate: string;
  contractServiceId: number;
  dropoffLocationLatitude: number;
  dropoffLocationLongitude: number;
  pickupLocationLatitude: number;
  pickupLocationLongitude: number;
  pickUpLocationGeometry: string;
  dropOffLocationGeometry: string;
  distanceInKM: number;
  contractService: ContractService;
  customerDivisionId: number;
  customerDivision: CustomerDivision;
  sourceTypeId: number;
  pickoffTime: string;
  readyStatus: boolean;
  assignmentStatus: string;
  hasPassenger: boolean;
  serviceRegistration: ServiceRegistration;
  saExecutionStatus: number;
  serviceRegistrationId: number;
  isDeleted: boolean;
  secondaryServiceAssignment: SecondaryServiceAssignment[];
  description: string;
  supplierAcceptedPilot: string;
  supplierAcceptedVehicle: string;
  preferentialPricing: number;
}

export interface StatusDashboardDetail {
  veTotalRegistered: string;
  veTotalWithActiveContract: string;
  veTotalOnTrip: string;
  veTotalIdle: string;
  veTotalReservedResponse: string;
  veTotalPaired: string;
  veTotalRequired: string;
  veTotalAvailable: string;
  piTotalRegistered: string;
  piTotalWithActiveContract: string;
  piTotalOnTrip: string;
  piTotalIdle: string;
  piTotalReservedResponse: string;
  piTotalPaired: string;
  piTotalRequired: string;
  piTotalAvailable: string;
  comTotalRegistered: string;
  comTotalWithActiveContract: string;
  comTotalOnTrip: string;
  comTotalIdle: string;
  comTotalReservedResponse: string;
  comTotalRequired: string;
  comTotalAvailable: string;
  escTotalRegistered: string;
  escTotalWithActiveContract: string;
  escTotalOnTrip: string;
  escTotalIdle: string;
  escTotalReservedResponse: string;
  escTotalRequired: string;
  escTotalAvailable: string;
  totalOfTripsDue: string;
  totalTripsForToday: string;
  totalTripsStarted: string;
  totalTripsCancelled: string;
  totalTripsNotStarted: string;
  totalTripsEnded: string;
  estimate: string;
  totalOfTripsDueIn48hrs: string;
}
export interface SecondaryServiceAssignment {
  secondaryServiceRegistrationId: number;
  secondaryContractServiceId: number;
  serviceAssignmentId: number;
  isDeleted: boolean;
  serviceRegistration: ServiceRegistration;
  createdBy: User;
}

export interface MasterServiceAssignmentMailVMDTO {
  id: number;
  pickoffLocation: string;
  pickupDate: string;
  pickoffTime: string;
  dropoffLocation: string;
  subject: string;
  passengers: PassengersMailDTO[];
  recepients: string[];
  createdBy: string;
  createdByMobile: string;
  serviceMailDTO: ServiceMailDTO;
  commanders: CommandersMailDTO[];
  armedEscorts: ArmedEscortsMailDTO[];
  pilots: PilotsMailDTO[];
  vehicles: VehiclesMailDTO[];
}

export interface ServiceMailDTO {
  name: string;
  description: string;
}
export interface PassengersMailDTO {
  firstname: string;
  lastname: string;
  mobile: string;
  email: string;
  passengerType: string;
}
export interface CommandersMailDTO {
  firstname: string;
  lastname: string;
  mobile: string;
  imageUrl: string;
  resourceId: number;
  isTemporarilyHeld: boolean;
}
export interface ArmedEscortsMailDTO {
  firstname: string;
  lastname: string;
  mobile: string;
  imageUrl: string;
  forceId: number;
  isTemporarilyHeld: boolean;
}
export interface PilotsMailDTO {
  firstname: string;
  lastname: string;
  mobile: string;
  imageUrl: string;
  resourceId: number;
  isTemporarilyHeld: boolean;
}
export interface VehiclesMailDTO {
  identificationNumber: string;
  serviceName: string;
  isTemporarilyHeld: boolean;
}
export interface ArmedEscortServiceAssignmentDetail {
  id: number;
  isDeleted: boolean;
  actionReleaseTypeId: number;
  tempReleaseTypeId: number;
  actionReleaseType: ReleaseType;
  tempReleaseType: ReleaseType;
  dateActionReleased: string;
  dateHeldForAction: string;
  isHeldForAction: boolean;
  dateTemporarilyHeld: string;
  isTemporarilyHeld: boolean;
  requiredCount: number;
  armedEscortResourceId: number;
  armedEscortResource: ArmedEscortProfile;
  serviceAssignmentId: number;
  serviceAssignment: MasterServiceAssignment;
}

export interface CommanderServiceAssignmentDetail {
  id: number;
  isDeleted: boolean;
  actionReleaseTypeId: number;
  tempReleaseTypeId: number;
  actionReleaseType: ReleaseType;
  tempReleaseType: ReleaseType;
  dateActionReleased: string;
  dateHeldForAction: string;
  isHeldForAction: boolean;
  dateTemporarilyHeld: string;
  dateTemporarilyReleased: string;
  tiedVehicleResourceId: number;
  tiedVehicleResource: VehicleProfile;
  isTemporarilyHeld: boolean;
  requiredCount: number;
  commanderResourceId: number;
  commanderResource: CommanderProfile;
  serviceAssignmentId: number;
  serviceAssignment: MasterServiceAssignment;
}

export interface PilotServiceAssignmentDetail {
  id: number;
  isDeleted: boolean;
  actionReleaseTypeId: number;
  actionReleaseType: ReleaseType;
  tempReleaseType: ReleaseType;
  tempReleaseTypeId: number;
  dateActionReleased: string;
  dateHeldForAction: string;
  isHeldForAction: boolean;
  dateTemporarilyHeld: string;
  dateTemporarilyReleased: string;
  tiedVehicleResourceId: number;
  tiedVehicleResource: VehicleProfile;
  isTemporarilyHeld: boolean;
  requiredCount: number;
  pilotResourceId: number;
  pilotResource: PilotProfile;
  serviceAssignmentId: number;
  serviceAssignment: MasterServiceAssignment;
}

export interface VehicleServiceAssignmentDetail {
  id: number;
  isDeleted: boolean;
  actionReleaseTypeId: number;
  tempReleaseTypeId: number;
  actionReleaseType: ReleaseType;
  tempReleaseType: ReleaseType;
  dateActionReleased: string;
  dateHeldForAction: string;
  isHeldForAction: boolean;
  dateTemporarilyHeld: string;
  isTemporarilyHeld: boolean;
  requiredCount: number;
  vehicleResourceId: number;
  vehicleResource: VehicleProfile;
  serviceAssignmentId: number;
  serviceAssignment: MasterServiceAssignment;
}
export interface Passenger {
  id: number;
  firstname: string;
  isDeleted: boolean;
  passengerTypeId: number;
  passengerType: PassengerType;
  email: string;
  mobile: string;
  instagramHandle: string;
  twitterHandle: string;
  othername: string;
  lastname: string;
  serviceAssignmentId: number;
  serviceAssignment: MasterServiceAssignment;
  facebookHandle: string;
}

export interface ReleaseType {
  typeName: string;
  id?: number;
  CreatedById: number;
  isDeleted: boolean;
}

export interface PassengerType {
  typeName: string;
  id?: number;
  createdById: number;
  isDeleted: boolean;
}
export interface SourceType {
  typeName: string;
  id?: number;
  CreatedById: number;
  isDeleted: boolean;
}
export interface TripType {
  typeName: string;
  id?: number;
  CreatedById: number;
  isDeleted: boolean;
}
export interface SupplierServices {
  title?: string;
  serviceName: string;
  description: string;
  make: string;
  model: string;
  modelNumber: string;
  serailNumber: string;
  identificationNumber: string;
  referenceNumber1: string;
  referenceNumber2: string;
  referenceNumber3: string;
  unitCostPrice: string;
  standardDiscount: string;
  averagePrice: string;
  supplierId: number;
  supplier?: Supplier;
  id?: number;
  commanderUserProfiles?: CommanderUserProfile[];
  journeyStarts?: JourneyStart[];
  armedEscortProfiles?: ArmedEscortProfile[];
  otherPassengers?: OtherPassenger[];
  vehicleName?: string;
  frontViewImage?: string;
  feftViewImage?: string;
  fightViewImage?: string;
}
export interface JourneyStart {
  id?: number;
  serviceAssignmentId: number;
  commanderId: number;
  vehicleId: number;
  journeyStartDateTime: string;
  startedById: number;
  journeyEndDateTime: string;
  endedById: number;
  comments: string;
  journeyStartCordinate: string;
  journeyStartAddress: string;
  journeyEndCordinate: string;
  journeyEndAddress: string;
}
export interface TimeSpan {
  hours: number;
  minutes: number;
  seconds: number;
}

export interface ArmedEscortType {
  id?: number;
  name: string;
  caption: string;
  description: string;
  serviceRegistrationId: number;
  serviceRegistration: ServiceRegistration;
}
export interface ArmedEscortResourceRequiredPerService {
  id?: number;
  armedEscortTypeId: string;
  armedEscortType: ArmedEscortType;
  serviceRegistrationId: number;
  serviceRegistration: ServiceRegistration;
  CreatedById: number;
  isDeleted: boolean;
}

export interface PriceRegister {
  id: number;
  markupPrice: number;
  markupPercentage: number;
  smoRouteId: number;
  smoRegionId: number;
  sellingPrice: number;
  costPrice: number;
  smoRoute: SMORoute;
  smoRegion: SMORegion;
  CreatedById: number;
  ServiceRegistration: ServiceRegistration;
  serviceRegistrationId: number;

  isDeleted: boolean;
}

export interface PreferentialPricing {
  id: number;
  isDeleted: boolean;
  priceDescription: string;
  price: number;
  registeredServiceId: number;
  contractServiceId: number;
  clientId: number;
  client: CustomerDivision;
  contractService: ContractService;
  registeredService: ServiceRegistration;
}

export interface AllSMORoutesAndRegion {
  smoRoute: SMORoute[];
  smoRegion: SMORegion[];
}

export interface ArmedEscortRank {
  rankName: string;
  alias: string;
  description: string;
  armedEscortTypeId: number;
  armedEscortType?: ArmedEscortType;
  sequence: number;
  id?: number;
}

export interface PilotType {
  id?: number;
  typeName: string;
  typeDesc: string;
  serviceRegistrationId: number;
  serviceRegistration: ServiceRegistration;
  //description: string;
}
export interface PilotResourceRequiredPerService {
  id?: number;
  pilotTypeId: string;
  pilotType: PilotType;
  serviceRegistrationId: number;
  serviceRegistration: ServiceRegistration;
  CreatedById: number;
  isDeleted: boolean;
}
// export interface SMORoute {
//   id?: number;
//   name: string;
//   caption: string;
//   description: string;
// }

export interface VehicleType {
  id?: number;
  typeName: string;
  typeDesc: string;
  serviceRegistrationId: number;
  serviceRegistration: ServiceRegistration;
  //CreatedById:number;
  isDeleted: boolean;
}
export interface VehicleResourceRequiredPerService {
  id?: number;
  vehicleTypeId: string;
  vehicleType: VehicleType;
  serviceRegistrationId: number;
  serviceRegistration: ServiceRegistration;
  CreatedById: number;
  isDeleted: boolean;
}

export enum ConfirmEventType {
  ACCEPT = 0,
  REJECT = 1,
  CANCEL = 2
}

export interface MeansOfId {
  id?: number;
  caption: string;
  //typeDesc: string;
  description: string;
}
export interface Gender {
  id: number;
  name: string;
}

export interface PilotProfile {
  id?: number;
  CreatedById: number;
  meansOfIdentificationId: number;
  idNumber: string;
  gender: string;
  imageUrl: string;
  updatedAt: string;
  pilotTypeId: number;
  address: string;
  mobile: string;
  dob: string;
  lastname: string;
  firstname: string;
  rank: PilotRank;
  pilotType: PilotType;
  rankId: number;
  isDeleted: boolean;
  isOwned:boolean;
  fullName: string;
  reserveFroPatrolAndOrEmergencyResponse: boolean;
}

// export interface ArmedEscortProfile {
//     id?: number;
//     CreatedById:number;
//         meansOfIdentificationId:number;
//         idNumber:string;
//         gender:string;
//         imageUrl:string;
//         updatedAt:string;
//         pilotTypeId:number;
//         address:string;
//         mobile:string;
//         dob:string;
//         lastname:string;
//         firstname:string;
//         rankId:number;
//         isDeleted:boolean;

// }

export interface Vehicle {
  id?: number;
  SupplierServiceId: number;

  AttachedBranchId: number;

  AttachedOfficeId: number;

  VehicleTypeId: number;
}

export interface PilotRank {
  rankName: string;
  alias: string;
  description: string;
  sequence: number;
  pilotTypeId: number;
  //pilotType:string;
  id?: number;
}
export interface ArmedEscortProfile {
  gender: string;
  imageUrl: string;
  alias: string;
  mobile: string;
  age?: number;
  dateOfBirth?: number;
  lastName: string;
  firstName: string;
  serviceAssignmentId?: number;
  serviceAssignment?: ServiceAssignment;
  rankId?: number;
  rank?: ArmedEscortRank;
  armedEscortTypeId: number;
  armedEscortType?: ArmedEscortType;
  id?: number;
  fullName?: string;
  createdAt: string;
  updatedAt: string;
  createdById: number;
  isOwned:boolean;
  reserveFroPatrolAndOrEmergencyResponse: boolean;
}

export interface VehicleResourceTie {
  resource: VehicleProfile;
  resourceId: number;
  smoRoute: SMORoute;
  smoRegion: SMORegion;
  smoRouteId: number;
  smoRegionId: number;
  id: number;
  createdAt: string;
  updatedAt: string;
  createdById: number;
  isDeleted: boolean;
  fullName?: string;
}

export interface ArmedEscortDTSMasters {
  armedEscortResourceId: number;
  armedEscortResource: ArmedEscortProfile;
  availabilityStart: string;
  availablilityEnd: string;
  caption: string;
  genericDays: ArmedEscortDTSDetailGenericDay[];
  id: number;
  createdAt: string;
  updatedAt: string;
  createdById: number;
  isDeleted: boolean;
}

export interface ArmedEscortDTSDetailGenericDay {
  monday: boolean;
  tuesday: boolean;
  wednesday: boolean;
  thursday: boolean;
  friday: boolean;
  saturday: boolean;
  sunday: boolean;
  openingTime: string;
  closingTime: string;
  id: number;
  dtsMasterId: number;
  dtsMaster: ArmedEscortDTSMasters;
  createdAt: string;
  updatedAt: string;
  createdById: number;
  isDeleted: boolean;
  caption: string;
}

export interface CommanderDTSMasters {
  commanderResourceId: number;
  commanderResource: CommanderProfile;
  availabilityStart: string;
  availablilityEnd: string;
  caption: string;
  genericDays: CommanderDTSDetailGenericDay[];
  id: number;
  createdAt: string;
  updatedAt: string;
  createdById: number;
  isDeleted: boolean;
}

export interface CommanderDTSDetailGenericDay {
  monday: boolean;
  tuesday: boolean;
  wednesday: boolean;
  thursday: boolean;
  friday: boolean;
  saturday: boolean;
  sunday: boolean;
  openingTime: string;
  closingTime: string;
  id: number;
  dTSMasterId: number;
  dTSMaster: CommanderDTSMasters;
  createdAt: string;
  updatedAt: string;
  createdById: number;
  isDeleted: boolean;
}

export interface PilotDTSMasters {
  pilotResourceId: number;
  pilotResource: PilotProfile;
  availabilityStart: string;
  availablilityEnd: string;
  caption: string;
  genericDays: PilotDTSDetailGenericDay[];
  id: number;
  createdAt: string;
  updatedAt: string;
  createdById: number;
  isDeleted: boolean;
}

export interface RenewContract{
  id:number;
  resourceId:number;
  caption:string;
}

export interface PilotDTSDetailGenericDay {
  monday: boolean;
  tuesday: boolean;
  wednesday: boolean;
  thursday: boolean;
  friday: boolean;
  saturday: boolean;
  sunday: boolean;
  openingTime: string;
  closingTime: string;
  id: number;
  dTSMasterId: number;
  dTSMaster: PilotDTSMasters;
  createdAt: string;
  updatedAt: string;
  createdById: number;
  isDeleted: boolean;
}

export interface VehicleDTSMasters {
  vehicleResourceId: number;
  vehicleResource: VehicleProfile;
  availabilityStart: string;
  availablilityEnd: string;
  caption: string;
  genericDays: VehicleDTSDetailGenericDay[];
  id: number;
  createdAt: string;
  updatedAt: string;
  createdById: number;
  isDeleted: boolean;
}

export interface VehicleDTSDetailGenericDay {
  monday: boolean;
  tuesday: boolean;
  wednesday: boolean;
  thursday: boolean;
  friday: boolean;
  saturday: boolean;
  sunday: boolean;
  openingTime: string;
  closingTime: string;
  id: number;
  dTSMasterId: number;
  dTSMaster: VehicleDTSMasters;
  createdAt: string;
  updatedAt: string;
  createdById: number;
  isDeleted: boolean;
}
export interface ArmedEscortResourceTie {
  resource: ArmedEscortProfile;
  resourceId: number;
  smoRoute: SMORoute;
  smoRegion: SMORegion;
  smoRouteId: number;
  smoRegionId: number;
  id: number;
  createdAt: string;
  updatedAt: string;
  createdById: number;
  isDeleted: boolean;
  fullName?: string;
}

export interface PilotResourceTie {
  resource: PilotProfile;
  resourceId: number;
  smoRoute: SMORoute;
  smoRegion: SMORegion;
  smoRouteId: number;
  smoRegionId: number;
  id: number;
  createdAt: string;
  fullName: string;
  updatedAt: string;
  createdById: number;
  isDeleted: boolean;
}

export interface ArmadaJourneyStart {
  totalTimeSpentOnJourney: number;
  journeyEndActualAddress: string;
  journeyEndActualLongitude: number;
  journeyEndActualLatitude: number;
  journeyEndDatetime: string;
  journeyStartActualAddress: string;
  journeyStartActualLatitude: number;
  journeyStartDatetime: string;
  isJourneyStopped: boolean;
  isJourneyCancelled: boolean;
  isJourneyStarted: boolean;
  serviceAssignment: MasterServiceAssignment;
  serviceAssignmentId: number;
  journeyStartActualLongitude: number;
  id: number;
  createdAt: string;
  updatedAt: string;
  createdById: number;
  isDeleted: boolean;
}
export interface ArmadaJourneyStop {
  journeyStartId: number;
  journeyStart: ArmadaJourneyStart;
  journeyStopCaption: string;
  journeyStopDescription: string;
  journeyStopTime: string;
  journeyStopActualLongitude: number;
  journeyStopActualLatitude: number;
  isJourneyStopPlanned: boolean;
  id: number;
  createdAt: string;
  updatedAt: string;
  createdById: number;
  isDeleted: boolean;
}
export interface JourneyIncident {
  journeyIncidentTime: string;
  journeyIncidentActualAddress: string;
  journeyIncidentActualLongitude: number;
  journeyIncidentActualLatitude: number;
  //journeyIncidentTypeId: number;
  journeyIncidentType: number;
  journeyIncidentDescription: string;
  journeyIncidentCaption: string;
  journeyStart: ArmadaJourneyStart;
  journeyStartId: number;
  journeyIncidentSeverityRating: number;
  id: number;
  createdAt: string;
  updatedAt: string;
  createdById: number;
  isDeleted: boolean;
}
export interface JourneyIncidentPic {
  journeyIncidentId: number;
  journeyIncident: JourneyIncident;
  journeyPicture: string;
  id: number;
  createdAt: string;
  updatedAt: string;
  createdById: number;
  isDeleted: boolean;
}
export interface JourneyLeadCommander {
  journeyStartId: number;
  journeyStart: ArmadaJourneyStart;
  isActive: boolean;
  leadCommanderId: number;
  leadCommander: CommanderProfile;
  id: number;
  createdAt: string;
  updatedAt: string;
  createdById: number;
  isDeleted: boolean;
}
export interface JourneyNote {
  journeyStartId: number;
  journeyStart: ArmadaJourneyStart;
  journeyNoteCaption: string;
  journeyNoteDescription: string;
  id: number;
  createdAt: string;
  updatedAt: string;
  createdById: number;
  isDeleted: boolean;
}

export interface CommanderResourceTie {
  resource: CommanderProfile;
  resourceId: number;
  smoRoute: SMORoute;
  smoRegion: SMORegion;
  smoRouteId: number;
  smoRegionId: number;
  id: number;
  createdAt: string;
  updatedAt: string;
  createdById: number;
  fullName?: string;
  isDeleted: boolean;
}
export interface FeedbackMaster {
  journeyStart: ArmadaJourneyStart;
  ServiceAssignment: MasterServiceAssignment;
  journeyStartId: number;
  masterServiceAssignmentId: number;
  id: number;
  createdAt: string;
  updatedAt: string;
  createdById: number;
  isDeleted: boolean;
}

export interface GeneralFeedbackDetail {
  feedbackMaster: FeedbackMaster;
  feedbackMasterId: number;
  cSATScore: number;
  nPSScore: number;
  WasSafeAndComfortable: boolean;
  id: number;
  ReasonForNotFeelingSafe: string;
  createdAt: string;
  updatedAt: string;
  createdById: number;
  isDeleted: boolean;
}

export interface VehicleTracking {
  name: string;
  dt_server: string;
  dt_tracker: string;
  lat: string;
  lng: string;
  altitude: string;
  angle: string;
  speed: string;
  params: params;
  loc_valid: string;
}

export interface params {
  accv: string;
  ai0: string;
  alm_code: string;
  batv: string;
  cellid: string;
  di0: string;
  di1: string;
  di2: string;
  di3: string;
  do0: string;
  do1: string;
  do2: string;
  do3: string;
  engh: string;
  lac: string;
  mcc: string;
  mnc: string;
  odo: string;
}
export interface OtherPassenger {
  passengerName: string;
  passengerEmail: string;
  passengerNumber?: string;
  passengerPhone?: string;
  contractServiceId?: number;
  contractService?: ContractService;
  serviceAssignmentId?: number;
  serviceAssignment?: ServiceAssignment;
  id?: number;
}

export interface CommanderVehicleInterface {
  commander: CommanderUserProfile;
  vehicle: SupplierServices;
}
export interface ServiceVehicleInterface {
  commanders: CommanderUserProfile[];
  vehicle: SupplierServices;
  passengers?: OtherPassenger[];
  armedEscort?: ArmedEscortProfile[];
}

export interface AssignmentAcceptanceStatus {
  serviceAssignmentId: number;
  serviceAssignment: ServiceAssignment;
  commanderUserProfile: CommanderUserProfile;
  isAccepted: boolean;
  id?: number;
}

export interface SituationReport {
  situationTypeId: number;
  stateId: number;
  lgaId: number;
  address: string;
  location: string;
  nearestLandmark: string;
  serviceAssignmentId: number;
  remark: string;
  situationType?: SituationType;
  id?: number;
  state?: State;
  lga?: Lga;
}

export interface JourneyStop {
  stateId: number;
  lgaId: number;
  address: string;
  location: string;
  landmark: string;
  contractServiceId: number;
  isPlanned: boolean;
  remark: string;
  id?: number;
  state?: State;
  lga?: Lga;
}

//Replace Resource
export interface ArmedEscortDetailReplacement {
  id: number;
  masterServiceAssignmentId: number;
  masterServiceAssignment: MasterServiceAssignment;
  oldResourceId: number;
  oldResource: ArmedEscortProfile;
  newResourceId: number;
  newResource: ArmedEscortProfile;
  dateTimeOfReplacement: string;
  reasonForReplacement: string;
  createdAt: string;
  updatedAt: string;
  createdById: number;
  isDeleted: boolean;
}
export interface CommanderDetailReplacement {
  id: number;
  masterServiceAssignmentId: number;
  masterServiceAssignment: MasterServiceAssignment;
  oldResourceId: number;
  oldResource: CommanderProfile;
  newResourceId: number;
  newResource: CommanderProfile;
  dateTimeOfReplacement: string;
  reasonForReplacement: string;
  createdAt: string;
  updatedAt: string;
  createdById: number;
  isDeleted: boolean;
}
export interface PilotDetailReplacement {
  id: number;
  masterServiceAssignmentId: number;
  masterServiceAssignment: MasterServiceAssignment;
  oldResourceId: number;
  oldResource: PilotProfile;
  newResourceId: number;
  newResource: PilotProfile;
  dateTimeOfReplacement: string;
  reasonForReplacement: string;
  createdAt: string;
  updatedAt: string;
  createdById: number;
  isDeleted: boolean;
}
export interface VehicleDetailReplacement {
  id: number;
  masterServiceAssignmentId: number;
  masterServiceAssignment: MasterServiceAssignment;
  oldResourceId: number;
  oldResource: VehicleProfile;
  newResourceId: number;
  newResource: VehicleProfile;
  dateTimeOfReplacement: string;
  reasonForReplacement: string;
  createdAt: string;
  updatedAt: string;
  createdById: number;
  isDeleted: boolean;
}
export interface SmtpMailer {
  subject: string;
  recipients: string[];
  message: string;
}

export class ArmadaConstant {
  // paymentCycles: CData[] = [
  //   { id: 0, value: "Weekly" },
  //   { id: 1, value: "BiWeekly" },
  //   { id: 2, value: "Monthly" },
  //   { id: 3, value: "BiMonthly" },
  //   { id: 4, value: "Quarterly" },
  //   { id: 5, value: "SemiAnnually" },
  //   { id: 6, value: "Annually" },
  //   { id: 7, value: "BiAnnually" },
  //   { id: 8, value: "One time" },
  // ];
  // invoicingIntervals: CData[] = [
  //   { id: 0, value: "Weekly" },
  //   { id: 1, value: "BiWeekly" },
  //   { id: 2, value: "Monthly" },
  //   { id: 3, value: "BiMonthly" },
  //   { id: 4, value: "Quarterly" },
  //   { id: 5, value: "SemiAnnually" },
  //   { id: 6, value: "Annually" },
  //   { id: 7, value: "BiAnnually" },
  //   { id: 8, value: "One time" },
  //   { id: 9, value: "Adhoc" },
  // ];
  // leadRole: CData[] = [
  //   { id: 0, value: "leadGenerator", description: "Lead Generator" },
  //   { id: 1, value: "leadClosure", description: "Lead Closure" },
  //   {
  //     id: 2,
  //     value: "leadGeneratorAndClosure",
  //     description: "Lead Generator And Closure",
  //   },
  // ];
  titles = [
    { value: "Mr" },
    { value: "Mrs" },
    { value: "Miss" },
    { value: "Dr" },
    { value: "Engr" },
  ];
  resources = [
    { name: "Vehicle" },
    { name: "Pilot" },
    { name: "Commander" },
    { name: "Armed Escort" },
  ];
  genders = [{ value: "Male" }, { value: "Female" }, { value: "Other" }];
  priority = [
    { id: 0, value: "low" },
    { id: 1, value: "medium" },
    { id: 2, value: "high" },
  ];
}
