import { ServiceMailDTO } from './../../../../../interfaces/armada';
import { Component, OnInit } from "@angular/core";
import { ConfirmationService, MessageService } from "primeng/api";
// import { ConfirmationService, MessageService } from "primeng/api";
import { DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";
import {
  ArmedEscortServiceAssignmentDetail,
  CommanderServiceAssignmentDetail,
  MasterServiceAssignment,
  MasterServiceAssignmentMailVMDTO,
  Passenger,
  PilotServiceAssignmentDetail,
  VehicleServiceAssignmentDetail,
  VehiclesMailDTO,
} from "src/app/interfaces/armada";
import { CommonResponse, InvoiceMailDTO } from "src/app/interfaces/home";
import { MasterServiceAssignmentService } from "src/app/services/armada/master-service-assignment.service";
import { ServiceAssignmentDetailService } from "src/app/services/armada/service-assignment-detail.service";
import { InvoiceService } from "src/app/services/invoice.sevice";

@Component({
  selector: "app-jmp-invoice",
  templateUrl: "./jmp-invoice.component.html",
  styleUrls: ["./jmp-invoice.component.scss"],
})
export class JmpInvoiceComponent implements OnInit {
  //invoiceDetails: InvoiceMailDTO;
  //invoiceDetails: MasterServiceAssignment;
  invoiceDetails: MasterServiceAssignmentMailVMDTO;
  fetchingInvoiceDetails: boolean;
  isGroupedAdhoc = false;

  armedEscortDetails: ArmedEscortServiceAssignmentDetail[];
  commanderDetails: CommanderServiceAssignmentDetail[];
  pilotDetails: PilotServiceAssignmentDetail[];

  vehicleDetails: VehicleServiceAssignmentDetail[];
  vehicleDetailsFilter: VehiclesMailDTO[];
  passengersDetails: Passenger[];
  serviceMailDTO: ServiceMailDTO;

  constructor(
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private invoiceService: InvoiceService,
    public modalConfig: DynamicDialogConfig,
    private viewInvoiceModalRef: DynamicDialogRef,
    private serviceAssignmentService: MasterServiceAssignmentService,
    private serviceAssignmentDetailService: ServiceAssignmentDetailService
  ) {}

  ngOnInit(): void {
    this.fetchingInvoiceDetails = true;
    //this.isGroupedAdhoc = this.modalConfig.data.isGroupedAdhoc;
    this.fetchJMPDetails(this.modalConfig.data.id);
    // if (this.isGroupedAdhoc) {
    //   this._fetchForGroupedAdhoc(this.modalConfig.data.id);
    //   //this._fetchEscortForGroupedAdhocByAssignmentId(this.modalConfig.data.id);
    // } else {
    //   this.fetchJMPDetails(this.modalConfig.data.id);
    //   //this.fetchArmedEscortDetailsByAssignmentId(this.modalConfig.data.id);
    // }
  }

  printPage() {
    window.print();
  }
  confirmPrint() {
    //this.viewInvoiceModalRef.close();

    this.confirmationService.confirm({
      message: "Are you sure you want to print this JPM ?",
      //header: "Confirmation",
      //icon: "pi pi-exclamation-triangle",
      accept: () => {
        this.printPage();
      },
      reject: (type) => {
        this.messageService.add({
          severity: "error",
          summary: "Rejected",
          detail: "Declined Sending JMP",
        });
      },
    });
  }
  sendJPM(id: number) {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Sending JMP",
    });

    this.serviceAssignmentService.sendJMP(id).subscribe(
      async (res: CommonResponse) => {
        //var response = res.responseData as boolean;
        if (res.responseCode == "00")
          this.messageService.add({
            severity: "success",
            summary: "Successful",
            detail: "JMP Sent",
          });
      },
      (error) => {
        this.messageService.add({
          severity: "errorr",
          summary: "Failed",
          detail: error.error.message,
        });
      }
    );
  }

  confirmSendEmail(id: number) {
    this.viewInvoiceModalRef.close();
    this.confirmationService.confirm({
      message: "Are you sure you want to send this JPM ?",
      header: "Confirmation",
      //icon: "pi pi-exclamation-triangle",
      accept: () => {
        this.sendJPM(id);
      },
      reject: (type) => {
        this.messageService.add({
          severity: "error",
          summary: "Rejected",
          detail: "Declined Sending JMP",
        });
      },
    });
  }

  // private _fetchForGroupedAdhoc(id: number) {
  //   this.messageService.add({
  //     severity: "info",
  //     summary: "Notice",
  //     detail: "Fetching Plan Details",
  //   });

  //   this.serviceAssignmentService.getServiceAssignment(id).subscribe(
  //     async (r: CommonResponse) => {
  //       //var invoiceDetails = r.responseData as InvoiceMailDTO;
  //       if (r.responseCode == "00") {
  //         var invoiceDetails = r.responseData;
  //         this.invoiceDetails = invoiceDetails;
  //         this.fetchingInvoiceDetails = false;
  //         this.messageService.add({
  //           severity: "success",
  //           summary: "Successful",
  //           detail: "Successfully Fetched plan",
  //         });
  //       } else {
  //         this.messageService.add({
  //           severity: "errorr",
  //           summary: "Failed",
  //           detail: r.responseMsg,
  //         });
  //         this.fetchingInvoiceDetails = false;
  //       }
  //     },
  //     (error) => {
  //       this.messageService.add({
  //         severity: "errorr",
  //         summary: "Failed",
  //         detail: error.error.message,
  //       });
  //       this.fetchingInvoiceDetails = false;
  //     }
  //   );

  //   //Escort
  //   this.serviceAssignmentDetailService
  //     .allArmedEscortDetailsByAssignmentId(id)
  //     .subscribe(
  //       async (r: CommonResponse) => {
  //         //var invoiceDetails = r.responseData as InvoiceMailDTO;
  //         if (r.responseCode == "00") {
  //           var res = r.responseData;
  //           this.armedEscortDetails = res;
  //           //this.fetchingInvoiceDetails = false;
  //           this.messageService.add({
  //             severity: "success",
  //             summary: "Successful",
  //             detail: "Successfully Fetched Plan",
  //           });
  //         } else {
  //           this.messageService.add({
  //             severity: "errorr",
  //             summary: "Failed",
  //             detail: r.responseMsg,
  //           });
  //           //this.fetchingInvoiceDetails = false;
  //         }
  //       },
  //       (error) => {
  //         this.messageService.add({
  //           severity: "errorr",
  //           summary: "Failed",
  //           detail: error.error.message,
  //         });
  //         this.fetchingInvoiceDetails = false;
  //       }
  //     );

  //   //Commander
  //   this.serviceAssignmentDetailService
  //     .allCommanderDetailsByAssignmentId(id)
  //     .subscribe(
  //       async (r: CommonResponse) => {
  //         //var invoiceDetails = r.responseData as InvoiceMailDTO;
  //         if (r.responseCode == "00") {
  //           var res = r.responseData;
  //           this.commanderDetails = res;
  //           //this.fetchingInvoiceDetails = false;
  //           this.messageService.add({
  //             severity: "success",
  //             summary: "Successful",
  //             detail: "Successfully Fetched Plan",
  //           });
  //         } else {
  //           this.messageService.add({
  //             severity: "errorr",
  //             summary: "Failed",
  //             detail: r.responseMsg,
  //           });
  //           //this.fetchingInvoiceDetails = false;
  //         }
  //       },
  //       (error) => {
  //         this.messageService.add({
  //           severity: "errorr",
  //           summary: "Failed",
  //           detail: error.error.message,
  //         });
  //         this.fetchingInvoiceDetails = false;
  //       }
  //     );

  //   //Pilot
  //   this.serviceAssignmentDetailService
  //     .allPilotDetailsByAssignmentId(id)
  //     .subscribe(
  //       async (r: CommonResponse) => {
  //         //var invoiceDetails = r.responseData as InvoiceMailDTO;
  //         if (r.responseCode == "00") {
  //           var res = r.responseData;
  //           this.pilotDetails = res;
  //           //this.fetchingInvoiceDetails = false;
  //           this.messageService.add({
  //             severity: "success",
  //             summary: "Successful",
  //             detail: "Successfully Fetched Plan",
  //           });
  //         } else {
  //           this.messageService.add({
  //             severity: "errorr",
  //             summary: "Failed",
  //             detail: r.responseMsg,
  //           });
  //           //this.fetchingInvoiceDetails = false;
  //         }
  //       },
  //       (error) => {
  //         this.messageService.add({
  //           severity: "errorr",
  //           summary: "Failed",
  //           detail: error.error.message,
  //         });
  //         this.fetchingInvoiceDetails = false;
  //       }
  //     );

  //   //Vehicle
  //   this.serviceAssignmentDetailService
  //     .allVehicleDetailsByAssignmentId(id)
  //     .subscribe(
  //       async (r: CommonResponse) => {
  //         //var invoiceDetails = r.responseData as InvoiceMailDTO;
  //         if (r.responseCode == "00") {
  //           var res = r.responseData;
  //           this.vehicleDetails = res;
  //           //this.fetchingInvoiceDetails = false;
  //           this.messageService.add({
  //             severity: "success",
  //             summary: "Successful",
  //             detail: "Successfully Fetched Plan",
  //           });
  //         } else {
  //           this.messageService.add({
  //             severity: "errorr",
  //             summary: "Failed",
  //             detail: r.responseMsg,
  //           });
  //           //this.fetchingInvoiceDetails = false;
  //         }
  //       },
  //       (error) => {
  //         this.messageService.add({
  //           severity: "errorr",
  //           summary: "Failed",
  //           detail: error.error.message,
  //         });
  //         this.fetchingInvoiceDetails = false;
  //       }
  //     );

  //   this.serviceAssignmentDetailService
  //     .allPassengerDetailsByAssignmentId(id)
  //     .subscribe(
  //       async (r: CommonResponse) => {
  //         //var invoiceDetails = r.responseData as InvoiceMailDTO;
  //         if (r.responseCode == "00") {
  //           var res = r.responseData;
  //           this.passengersDetails = res;
  //           //this.fetchingInvoiceDetails = false;
  //           this.messageService.add({
  //             severity: "success",
  //             summary: "Successful",
  //             detail: "Successfully Fetched Plan",
  //           });
  //         } else {
  //           this.messageService.add({
  //             severity: "errorr",
  //             summary: "Failed",
  //             detail: r.responseMsg,
  //           });
  //           //this.fetchingInvoiceDetails = false;
  //         }
  //       },
  //       (error) => {
  //         this.messageService.add({
  //           severity: "errorr",
  //           summary: "Failed",
  //           detail: error.error.message,
  //         });
  //         this.fetchingInvoiceDetails = false;
  //       }
  //     );
  // }

  fetchJMPDetails(id: number) {
    console.log("clocked");
    this.serviceAssignmentService.getJMPDetails(id).subscribe(
      async (r: CommonResponse) => {
        //var invoiceDetails = r.responseData as InvoiceMailDTO;
        if (r.responseCode == "00") {
          //console.log("clocked passed");
          var invoiceDetails =
            r.responseData as MasterServiceAssignmentMailVMDTO;
          this.invoiceDetails = invoiceDetails;
          console.log("invoice", this.invoiceDetails)
          // this.vehicleDetailsFilter = invoiceDetails.vehicles.filter(
          //   (x) => x.isTemporarilyHeld == true
          // );

          this.fetchingInvoiceDetails = false;
          // this.messageService.add({
          //   severity: "success",
          //   summary: "Successful",
          //   detail: "Successfully Fetched Plan",
          // });
        } else {
          this.messageService.add({
            severity: "errorr",
            summary: "Failed",
            detail: r.responseMsg,
          });
          console.log("clocked fail");
          this.fetchingInvoiceDetails = false;
        }
      },
      (error) => {
        this.messageService.add({
          severity: "errorr",
          summary: "Failed",
          detail: error.error.message,
        });
        this.fetchingInvoiceDetails = false;
      }
    );

    //Escort
    this.serviceAssignmentDetailService
      .allArmedEscortDetailsByAssignmentId(id)
      .subscribe(
        async (r: CommonResponse) => {
          //var invoiceDetails = r.responseData as InvoiceMailDTO;
          if (r.responseCode == "00") {
            var res = r.responseData;
            this.armedEscortDetails = res;
            //this.fetchingInvoiceDetails = false;
            // this.messageService.add({
            //   severity: "success",
            //   summary: "Successful",
            //   detail: "Successfully Fetched Plan",
            // });
          } else {
            this.messageService.add({
              severity: "errorr",
              summary: "Failed",
              detail: r.responseMsg,
            });
            // this.fetchingInvoiceDetails = false;
          }
        },
        (error) => {
          this.messageService.add({
            severity: "errorr",
            summary: "Failed",
            detail: error.error.message,
          });
          this.fetchingInvoiceDetails = false;
        }
      );

    //Commander
    this.serviceAssignmentDetailService
      .allCommanderDetailsByAssignmentId(id)
      .subscribe(
        async (r: CommonResponse) => {
          //var invoiceDetails = r.responseData as InvoiceMailDTO;
          if (r.responseCode == "00") {
            var res = r.responseData;
            this.commanderDetails = res;
            //this.fetchingInvoiceDetails = false;
            this.messageService.add({
              severity: "success",
              summary: "Successful",
              detail: "Successfully Fetched Plan",
            });
          } else {
            this.messageService.add({
              severity: "errorr",
              summary: "Failed",
              detail: r.responseMsg,
            });
            //this.fetchingInvoiceDetails = false;
          }
        },
        (error) => {
          this.messageService.add({
            severity: "errorr",
            summary: "Failed",
            detail: error.error.message,
          });
          this.fetchingInvoiceDetails = false;
        }
      );

    //Pilot
    this.serviceAssignmentDetailService
      .allPilotDetailsByAssignmentId(id)
      .subscribe(
        async (r: CommonResponse) => {
          //var invoiceDetails = r.responseData as InvoiceMailDTO;
          if (r.responseCode == "00") {
            var res = r.responseData;
            this.pilotDetails = res;
            //this.fetchingInvoiceDetails = false;
            // this.messageService.add({
            //   severity: "success",
            //   summary: "Successful",
            //   detail: "Successfully Fetched Plan",
            // });
          } else {
            this.messageService.add({
              severity: "errorr",
              summary: "Failed",
              detail: r.responseMsg,
            });
            //this.fetchingInvoiceDetails = false;
          }
        },
        (error) => {
          this.messageService.add({
            severity: "errorr",
            summary: "Failed",
            detail: error.error.message,
          });
          this.fetchingInvoiceDetails = false;
        }
      );
    //Vehicle
    this.serviceAssignmentDetailService
      .allVehicleDetailsByAssignmentId(id)
      .subscribe(
        async (r: CommonResponse) => {
          //var invoiceDetails = r.responseData as InvoiceMailDTO;

          if (r.responseCode == "00") {
            var res = r.responseData;
            this.vehicleDetails = res;
            // this.messageService.add({
            //   severity: "success",
            //   summary: "Successful",
            //   detail: "Successfully Fetched Plan",
            // });
          } else {
            this.messageService.add({
              severity: "errorr",
              summary: "Failed",
              detail: r.responseMsg,
            });
            //this.fetchingInvoiceDetails = false;
          }
        },
        (error) => {
          this.messageService.add({
            severity: "errorr",
            summary: "Failed",
            detail: error.error.message,
          });
          this.fetchingInvoiceDetails = false;
        }
      );

    //Passengers
    this.serviceAssignmentDetailService
      .allPassengerDetailsByAssignmentId(id)
      .subscribe(
        async (r: CommonResponse) => {
          //var invoiceDetails = r.responseData as InvoiceMailDTO;
          if (r.responseCode == "00") {
            var res = r.responseData;
            this.passengersDetails = res;
            //this.fetchingInvoiceDetails = false;
            // this.messageService.add({
            //   severity: "success",
            //   summary: "Successful",
            //   detail: "Successfully Fetched Plan",
            // });
          } else {
            this.messageService.add({
              severity: "errorr",
              summary: "Failed",
              detail: r.responseMsg,
            });
            //this.fetchingInvoiceDetails = false;
          }
        },
        (error) => {
          this.messageService.add({
            severity: "errorr",
            summary: "Failed",
            detail: error.error.message,
          });
          this.fetchingInvoiceDetails = false;
        }
      );
  }

  // private _fetchEscortForGroupedAdhocByAssignmentId(id: number) {
  //   this.messageService.add({
  //     severity: "info",
  //     summary: "Notice",
  //     detail: "Fetching Plan Details",
  //   });

  //   this.serviceAssignmentDetailService
  //     .allArmedEscortDetailsByAssignmentId(id)
  //     .subscribe(
  //       async (r: CommonResponse) => {
  //         //var invoiceDetails = r.responseData as InvoiceMailDTO;
  //         if (r.responseCode == "00") {
  //           var res = r.responseData;
  //           this.armedEscortDetails = res;
  //           this.fetchingInvoiceDetails = false;
  //           this.messageService.add({
  //             severity: "success",
  //             summary: "Successful",
  //             detail: "Successfully Fetched plan",
  //           });
  //         } else {
  //           this.messageService.add({
  //             severity: "errorr",
  //             summary: "Failed",
  //             detail: r.responseMsg,
  //           });
  //           this.fetchingInvoiceDetails = false;
  //         }
  //       },
  //       (error) => {
  //         this.messageService.add({
  //           severity: "errorr",
  //           summary: "Failed",
  //           detail: error.error.message,
  //         });
  //         this.fetchingInvoiceDetails = false;
  //       }
  //     );
  // }

  // fetchArmedEscortDetailsByAssignmentId(id: number) {
  //   this.messageService.add({
  //     severity: "info",
  //     summary: "Notice",
  //     detail: "Fetching Plan Details",
  //   });

  //   this.serviceAssignmentDetailService
  //     .allArmedEscortDetailsByAssignmentId(id)
  //     .subscribe(
  //       async (r: CommonResponse) => {
  //         //var invoiceDetails = r.responseData as InvoiceMailDTO;
  //         if (r.responseCode == "00") {
  //           var res = r.responseData;
  //           this.armedEscortDetails = res;
  //           this.fetchingInvoiceDetails = false;
  //           this.messageService.add({
  //             severity: "success",
  //             summary: "Successful",
  //             detail: "Successfully Fetched Plan",
  //           });
  //         } else {
  //           this.messageService.add({
  //             severity: "errorr",
  //             summary: "Failed",
  //             detail: r.responseMsg,
  //           });
  //           this.fetchingInvoiceDetails = false;
  //         }
  //       },
  //       (error) => {
  //         this.messageService.add({
  //           severity: "errorr",
  //           summary: "Failed",
  //           detail: error.error.message,
  //         });
  //         this.fetchingInvoiceDetails = false;
  //       }
  //     );
  // }
}
