import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SettlementsService {

  private readonly paystackSettlementsURL = environment.baseUrl+'PaystackGateway/';
  private readonly onlinePortalURL = environment.onlineBackendBaseUrl+'OnlineUsers/';


  constructor(private http: HttpClient) { }

  getAllSettlements(): Observable<any[]> {
    return this.http.get<any[]>(`${this.paystackSettlementsURL}AllSettlements`)
  }

  getIndividualSettlement(id: any): Observable<any[]> {
    // this.invokeLoadBudgetSeasonListFromCreationComponent.emit();
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' })};
    return this.http.get<any[]>(`${this.paystackSettlementsURL}IndividualSettlement/${id}`, {params: id})
  }

  getIndividualDetails(email: any): Observable<any> {
    // this.invokeLoadBudgetSeasonListFromCreationComponent.emit();
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' })};
    return this.http.get<any>(`${this.onlinePortalURL}GetLodgerDetails/${email}`)
  }
}
