import { JobApplication } from "./../../../interfaces/premployment";
import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { ConfirmationService, MessageService } from "primeng/api";
import { BreadcrumbService } from "src/app/breadcrumb.service";
import { GmaEmploymentService } from "src/app/services/gma-employment.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-gma-applicant-listing",
  templateUrl: "./gma-applicant-listing.component.html",
  styleUrls: ["./gma-applicant-listing.component.scss"],
})
export class GmaApplicantListingComponent implements OnInit {
  @ViewChild("formWrapper") public formWrapper: ElementRef;
  fetchingListings: boolean = true;
  allListings: JobApplication[];
  selectedListings: JobApplication[];
  cols: any[];

  constructor(
    public employmentService: GmaEmploymentService,
    private breadcrumbService: BreadcrumbService,
    public confirmationService: ConfirmationService,
    public messageService: MessageService
  ) {}

  ngOnInit(): void {
    this.breadcrumbService.setItems([
      {
        label: "Guard Management",
        routerLink: ["/home/guard-management/item-settings"],
      },
      {
        label: "Applicant Listing",
        routerLink: ["/home/guard-management/applicant-listing"],
      },
    ]);

    this.cols = [
      { field: "firstname", header: "Firstname" },
      { field: "lastname", header: "Lastname" },
      { field: "othername", header: "Othername" },
      { field: "applicantID", header: "ApplicantID" },
      { field: "jobBatchID", header: "JobBatchID" },
      { field: "mobile", header: "Mobile" },
    ];

    this.FetchAllApplicantsListing();
  }

  FetchAllApplicantsListing() {
    this.employmentService.GetAllApplicantsListing().subscribe(
      async (data) => {
        this.allListings = data;
        this.fetchingListings = false;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all applicants listing at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
        this.fetchingListings = false;
      }
    );
  }

  GotoManageJob(item: JobApplication) {
    let url: string =
      environment.frontednUrl + "#/home/guard-management/manage-job";

    localStorage.setItem("Preload_JobPostID", item.jobPostId.toString());
    window.open(url, "_self");
  }
}
