import { Bank, CommonResponse, Lga, State } from "./../../../interfaces/home";
import {
  AddAdditionalInfo,
  AddBackgroundInfo,
  AddEducationalBackground,
  AddEducationalBackgrounds,
  AddMedicalAccountInfo,
  AddNextOfKin,
  AddNextOfKins,
  AddPrevEmployer,
  AddPrevEmployers,
  BankEnum,
  ChildInfo,
  EducationLevelEnum,
  GenderEnum,
  JobApplicationStatusEnum,
  JobPost,
  JobTypeEnum,
  MaritalStatusEnum,
  NextOfKinRelationshipEnum,
  StartJobApplication,
} from "./../../../interfaces/premployment";
import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import {
  ConfirmationService,
  MenuItem,
  Message,
  MessageService,
} from "primeng/api";
import { GmaPremploymentService } from "src/app/services/gma-premployment.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { finalize } from "rxjs/operators";
import { GmaEmploymentService } from "src/app/services/gma-employment.service";
import { FileStorageService } from "src/app/services/file-storage.service";
import { BankService } from "src/app/services/bank.service";
import { PaystackGatewayService } from "src/app/services/paystack-gateway.service";

@Component({
  selector: "app-gma-job-listing",
  templateUrl: "./gma-job-listing.component.html",
  styleUrls: ["./gma-job-listing.component.scss"],
  providers: [MessageService],
})
export class GmaJobListingComponent implements OnInit {
  @ViewChild("formWrapper") public formWrapper: ElementRef;
  allJobOpenings: JobPost[];
  selectedJobOpening: JobPost;
  cols: any[];
  msgs: Message[] = [];
  openDialogue: boolean;
  jobOpeningToView: JobPost;
  isOnJobApplicationPhase: boolean = false;
  jobApplyingFor: JobPost;
  infoTableTable: {
    name: string;
    data: string;
  }[];
  selectedInfoTable: {
    name: string;
    data: string;
  };
  steps: MenuItem[];
  activeStep: number;
  personalForm: FormGroup;
  backgroundForm: FormGroup;
  nextofkinForm: FormGroup;
  educationForm: FormGroup;
  prevEmployerForm: FormGroup;
  medAcctForm: FormGroup;
  additionalForm: FormGroup;
  allStates: State[];
  theState: State;
  theBackgroundState: State;
  theNextOfKinState: State;
  theEmployerState: State;
  passportImageSrc: string;
  uploadedPassport: any[] = [];
  allLgas: Lga[];
  allBackgroundLgas: Lga[];
  allNextOfKinLgas: Lga[];
  allEmployerLgas: Lga[];
  theLga: Lga;
  theBackgroundLga: Lga;
  theNextOfKinLga: Lga;
  theEmployerLga: Lga;
  uploadedApplicationLetters: any[] = [];
  userIsYetToClickUploadApplicationLetter: boolean;
  trackingNo: string;
  inputedTrackingNo: string;
  isApplicationCompleted: boolean;
  isApplicationOngoing: boolean;
  allMaritalStatus: {
    value: string;
    key: number;
  }[];
  theMaritalStatus: {
    value: string;
    key: number;
  };
  isInAClubRadioButton: number;
  allGenders: {
    value: string;
    key: number;
  }[];
  theGender: {
    value: string;
    key: number;
  };
  theApplicantGender: {
    value: string;
    key: number;
  };
  allNxtOfKinRelationships: {
    value: string;
    key: number;
  }[];
  theNxtOfKinRelationship: {
    value: string;
    key: number;
  };
  allEducationalLevels: {
    value: string;
    key: number;
  }[];
  theEducationalLevel: {
    value: string;
    key: number;
  };
  allBanks: Bank[];
  thePrimaryBank: Bank;
  theSecondaryBank: Bank;
  childrenAddedToList: ChildInfo[];
  selectedChildOnList: ChildInfo;
  childerenCols: any[];
  message: string;
  nextOfKins: AddNextOfKin[];
  selectedNextOfKin: AddNextOfKin;
  nxtOfKinCols: any[];
  educationalBackgroundList: AddEducationalBackground[];
  selectedEducationalBackground: AddEducationalBackground;
  educBackgroundCols: any[];
  prevEmployerList: AddPrevEmployer[];
  selectedPrevEmployer: AddPrevEmployer;
  prevEmployerCols: any[];
  isDrinkingRadioButton: number;
  isSmokingRadioButton: number;
  isGamblingRadioButton: number;
  isHypertensiveRadioButton: number;
  isArthritusRadioButton: number;
  isDiabeticRadioButton: number;
  isSufferingUnknownRadioButton: number;
  isExMilitantRadioButton: number;
  isCombatantRadioButton: number;
  isArrestedRadioButton: number;
  isConvictedRadioButton: number;
  isResignedRadioButton: number;
  openPrivacyDialogue: boolean;
  openApplyDialogue: boolean;
  hasDeclared: boolean = false;
  hasAgreed: boolean = false;
  maxDate: string = "2000-01-01";
  childMaxDate: string = "2000-01-01";
  editingNextOfKin: boolean = false;
  nextOfKinToUpdate: AddNextOfKin;
  editingEducInfo: boolean = false;
  educInfoToUpdate: AddEducationalBackground;
  editingPrevEmp: boolean = false;
  prevEmpToUpdate: AddPrevEmployer;
  primaryAcctName: string = "";
  secondaryAcctName: string = "";

  constructor(
    fb: FormBuilder,
    public paystackGatewayService: PaystackGatewayService,
    public bankService: BankService,
    public employmentService: GmaEmploymentService,
    public gmaPremploymentService: GmaPremploymentService,
    public fileStorageService: FileStorageService,
    public messageService: MessageService,
    public confirmationService: ConfirmationService
  ) {
    this.personalForm = fb.group({
      Firstname: ["", Validators.required],
      Email: [
        "",
        Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$"),
      ],
      Lastname: ["", Validators.required],
      State: ["", Validators.required],
      Othername: ["", Validators.required],
      LGA: ["", Validators.required],
      Nickname: ["", Validators.required],
      Street: ["", Validators.required],
      Mobile: ["", Validators.required],
      Address: [""],
      Gender: ["", Validators.required],
      DOB: ["", Validators.required],
    });

    this.backgroundForm = fb.group({
      StateOfOrigin: ["", Validators.required],
      MaritalStatus: ["", Validators.required],
      LGAOfOrigin: ["", Validators.required],
      MarriageDate: [""],
      Tribe: ["", Validators.required],
      MarriageLocation: [""],
      IsInAClub: ["", Validators.required],
      ClubName: [""],
      SpouseFirstname: [""],
      SpouseLastname: [""],
      SpouseOccupation: [""],
      FatherFullname: ["", Validators.required],
      FatherAddress: ["", Validators.required],
      MotherFullname: ["", Validators.required],
      MotherAddress: ["", Validators.required],
      ChildFirstname: [""],
      ChildLastname: [""],
      ChildGender: [""],
      ChildDOB: [""],
    });

    this.nextofkinForm = fb.group({
      Firstname: ["", Validators.required],
      Lastname: ["", Validators.required],
      State: ["", Validators.required],
      LGA: ["", Validators.required],
      Othername: [""],
      Street: ["", Validators.required],
      Relationship: ["", Validators.required],
      Address: [""],
      Mobile: ["", Validators.required],
      Email: [
        "",
        Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$"),
      ],
    });

    this.educationForm = fb.group({
      EducationLevel: ["", Validators.required],
      ProgramStartDate: ["", Validators.required],
      Institution: ["", Validators.required],
      ProgramEndDate: ["", Validators.required],
      QualificationAttained: ["", Validators.required],
      GradeAwarded: ["", Validators.required],
    });

    this.prevEmployerForm = fb.group({
      EmployerName: ["", Validators.required],
      RoleOccupied: ["", Validators.required],
      EmployerState: ["", Validators.required],
      YearOfEmployment: ["", Validators.required],
      EmployerLGA: ["", Validators.required],
      YearOfTermination: ["", Validators.required],
      EmployerStreet: ["", Validators.required],
      ReasonForTermination: ["", Validators.required],
      EmployerAddress: [""],
    });

    this.medAcctForm = fb.group({
      IsDrinking: ["", Validators.required],
      IsSmoking: ["", Validators.required],
      IsGambling: ["", Validators.required],
      IsHypertensive: ["", Validators.required],
      IsArthritis: ["", Validators.required],
      IsDiabetic: ["", Validators.required],
      IsSufferingUnknown: ["", Validators.required],
      UnknownDiseaseDesc: [""],
      BVN: ["", Validators.required],
      PrimaryBank: ["", Validators.required],
      PrimaryAcctNo: ["", Validators.required],
      SecondaryBank: ["", Validators.required],
      SecondaryAcctNo: ["", Validators.required],
      PensionPin: ["", Validators.required],
      PPID: ["", Validators.required],
      TaxIdentificationNo: [""],
      NIN: [""],
    });

    this.additionalForm = fb.group({
      IsMilitary: ["", Validators.required],
      HasCombatTraining: ["", Validators.required],
      HasBeenArrested: ["", Validators.required],
      HasBeenConvicted: ["", Validators.required],
      HasBeenAskedToResign: ["", Validators.required],
      AgreesToTerms: [""],
      Declaration: [""],
    });

    this.message = this.getTimeOfDay();
    //this.loadScripts();
  }

  // Method to dynamically load JavaScript
  // loadScripts() {
  //   // This array contains all the files/CDNs
  //   const dynamicScripts = [
  //     "assets/popup/jquery.min.js",
  //     "assets/popup/popupConsent.js",
  //     "assets/popup/popupConsent.min.js",
  //   ];
  //   for (let i = 0; i < dynamicScripts.length; i++) {
  //     const node = document.createElement("script");
  //     node.src = dynamicScripts[i];
  //     node.type = "text/javascript";
  //     node.async = false;
  //     document.getElementsByTagName("head")[0].appendChild(node);
  //   }
  // }

  ngOnInit(): void {
    this.cols = [
      { field: "title", header: "Title" },
      { field: "jobCode", header: "JobCode" },
    ];
    this.allJobOpenings = [];

    this.msgs = [];
    this.msgs.push({
      severity: "info",
      summary: "Openings:",
      detail:
        this.allJobOpenings.length + " Jop Opening(s) Currently Available.",
    });

    this.allMaritalStatus = [
      {
        value: "Single",
        key: MaritalStatusEnum.Single,
      },
      {
        value: "Married",
        key: MaritalStatusEnum.Married,
      },
      {
        value: "Divorced",
        key: MaritalStatusEnum.Divorced,
      },
      {
        value: "Widowed",
        key: MaritalStatusEnum.Widowed,
      },
    ];

    this.allGenders = [
      {
        value: "Male",
        key: GenderEnum.Male,
      },
      {
        value: "Female",
        key: GenderEnum.Female,
      },
      {
        value: "Unspecified",
        key: GenderEnum.Unspecified,
      },
    ];

    this.allNxtOfKinRelationships = [
      {
        value: "Mother",
        key: NextOfKinRelationshipEnum.Mother,
      },
      {
        value: "Father",
        key: NextOfKinRelationshipEnum.Father,
      },
      {
        value: "Sister",
        key: NextOfKinRelationshipEnum.Sister,
      },
      {
        value: "Brother",
        key: NextOfKinRelationshipEnum.Brother,
      },
      {
        value: "Son",
        key: NextOfKinRelationshipEnum.Son,
      },
      {
        value: "Daughter",
        key: NextOfKinRelationshipEnum.Daughter,
      },
    ];

    this.allEducationalLevels = [
      {
        value: "Primary",
        key: EducationLevelEnum.Primary,
      },
      {
        value: "Secondary",
        key: EducationLevelEnum.Secondary,
      },
      {
        value: "Tertiary",
        key: EducationLevelEnum.Tertiary,
      },
      {
        value: "PostGraduate",
        key: EducationLevelEnum.PostGraduate,
      },
    ];

    // this.allBanks = [
    //   {
    //     value: "Firstbank",
    //     key: BankEnum.Firstbank,
    //   },
    //   {
    //     value: "ZenithBank",
    //     key: BankEnum.ZenithBank,
    //   },
    //   {
    //     value: "GTBank",
    //     key: BankEnum.GTBank,
    //   },
    //   {
    //     value: "PolarisBank",
    //     key: BankEnum.PolarisBank,
    //   },
    //   {
    //     value: "KeystoneBank",
    //     key: BankEnum.KeystoneBank,
    //   },
    //   {
    //     value: "FCMB",
    //     key: BankEnum.FCMB,
    //   },
    //   {
    //     value: "KudaBank",
    //     key: BankEnum.KudaBank,
    //   },
    //   {
    //     value: "SterlingBank",
    //     key: BankEnum.SterlingBank,
    //   },
    //   {
    //     value: "WemaBank",
    //     key: BankEnum.WemaBank,
    //   },
    //   {
    //     value: "ProvidusBank",
    //     key: BankEnum.ProvidusBank,
    //   },
    // ];

    this.infoTableTable = [
      {
        name: "Job",
        data: "Data",
      },
      {
        name: "Job Code",
        data: "Data",
      },
      {
        name: "Location",
        data: "Data",
      },
    ];

    this.steps = [
      {
        label: "Personal Info",
      },
      {
        label: "Background Info",
      },
      {
        label: "Next Of Kin",
      },
      {
        label: "Education",
      },
      {
        label: "Previous Employer",
      },
      {
        label: "Medical & Account",
      },
      {
        label: "Other Info",
      },
    ];
    this.activeStep = 0;
    this.trackingNo = "#######";

    this.childerenCols = [
      { field: "firstname", header: "Firstname" },
      { field: "lastname", header: "Lastname" },
      { field: "dateOfBirth", header: "DateOfBirth" },
    ];
    this.nxtOfKinCols = [
      { field: "firstname", header: "Firstname" },
      { field: "lastname", header: "Lastname" },
      { field: "othername", header: "Othername" },
      { field: "street", header: "Street" },
      { field: "mobile", header: "Mobile" },
      { field: "email", header: "Email" },
      { field: "relationshipValue", header: "RelationshipValue" },
    ];
    this.educBackgroundCols = [
      { field: "educationalLevelValue", header: "EducationalLevelValue" },
      { field: "programStartDate", header: "ProgramStartDate" },
      { field: "institution", header: "Institution" },
      { field: "programEndDate", header: "ProgramEndDate" },
      { field: "qualificationAttained", header: "QualificationAttained" },
      { field: "gradeAwarded", header: "GradeAwarded" },
    ];
    this.prevEmployerCols = [
      { field: "name", header: "Name" },
      { field: "role", header: "Role" },
      { field: "stateName", header: "StateName" },
      { field: "lGAName", header: "LGAName" },
      { field: "street", header: "Street" },
      { field: "yearOfEmployment", header: "YearOfEmployment" },
      { field: "yearOfTermination", header: "YearOfTermination" },
      { field: "reasonForTermination", header: "ReasonForTermination" },
    ];
    this.childrenAddedToList = [];
    this.nextOfKins = [];
    this.educationalBackgroundList = [];
    this.prevEmployerList = [];

    //Set minimum date
    var today = new Date();
    var dd = today.getDate();
    var mm = today.getMonth() + 1; //January is 0!
    var strMM = mm + "";
    if (mm < 10) strMM = "0" + mm;
    var yyyy = today.getFullYear() - 18;
    this.maxDate = yyyy + "-12-31";
    this.childMaxDate = today.getFullYear() + "-" + strMM + "-" + dd;

    this.FetchAllBanks();
    this.FetchAllAvailableJopOpenings();
    this.FetchAllStates();
    this.openPrivacyDialogue = true;
  }

  async FetchAllBanks() {
    this.bankService.allBankData().subscribe(
      async (data) => {
        if (data.responseCode != "00") {
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail: data.responseMsg,
          });
          return;
        }

        this.allBanks = data.responseData;
      },
      (error) => {
        this.messageService.add({
          severity: "error",
          summary: "Failed",
          detail: error ?? "Some errors occurred",
        });
      }
    );
  }

  FetchAllAvailableJopOpenings() {
    this.gmaPremploymentService.GetAllAvailableJobPost().subscribe(
      async (data) => {
        this.allJobOpenings = data;
        this.msgs = [];
        this.msgs.push({
          severity: "info",
          summary: "Openings:",
          detail:
            this.allJobOpenings.length + " Jop Opening(s) Currently Available.",
        });
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all available job postings at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  FetchAllStates() {
    this.employmentService.GetAllStates().subscribe(
      async (data) => {
        this.allStates = data;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all states at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  ShowJobDetails(item: JobPost) {
    this.jobOpeningToView = item;
    this.openDialogue = true;
  }

  HideJobDialogue() {
    this.jobOpeningToView = null;
    this.openDialogue = false;
  }

  HidePrivacyDialogue() {
    this.openPrivacyDialogue = false;
  }

  GetJobTypeLabel(tyepId: number) {
    if (tyepId == JobTypeEnum.Contract) return "Contract";
    if (tyepId == JobTypeEnum.Fulltime) return "Fulltime";
    if (tyepId == JobTypeEnum.PartTime) return "PartTime";
    return "Not Found";
  }

  GetMinEducationLevelLabel(levelId: number) {
    if (levelId == EducationLevelEnum.Primary) return "Primary";
    if (levelId == EducationLevelEnum.Secondary) return "Secondary";
    if (levelId == EducationLevelEnum.Tertiary) return "Tertiary";
    if (levelId == EducationLevelEnum.PostGraduate) return "PostGraduate";
    return "Not Found";
  }

  ApplyToJob(item: JobPost) {
    //this.isOnJobApplicationPhase = true;
    this.openApplyDialogue = true;
    this.jobApplyingFor = item;
    this.infoTableTable[0].data = item.title;
    this.infoTableTable[1].data = item.jobCode;
    this.infoTableTable[2].data = item.location.name;
  }

  ReturningApplicant() {
    this.openApplyDialogue = true;
    //this.isOnJobApplicationPhase = true;
  }

  async AddPersonalInfo() {
    this.confirmationService.confirm({
      message:
        "By Accepting, you hereby give us the rights to store and make use of any data you supply on the platform during the course of your job application. Do you agree?",
      accept: async () => {
        this.messageService.add({
          severity: "info",
          summary: "Notice",
          detail: "Processing Job Application Profile...",
        });

        if (!this.jobApplyingFor) {
          this.messageService.add({
            severity: "error",
            summary: "Error",
            detail:
              "Err: Unable to get job applying for data. Kindly refresh your page",
          });
          return;
        }

        const postData: StartJobApplication = {
          jobPostId: this.jobApplyingFor.id,
          firstname: this.personalForm.get("Firstname").value,
          lastname: this.personalForm.get("Lastname").value,
          email: this.personalForm.get("Email").value,
          stateId: this.theState.id,
          othernames: this.personalForm.get("Othername").value,
          lGAId: this.theLga.id,
          nickname: this.personalForm.get("Nickname").value,
          street: this.personalForm.get("Street").value,
          mobile: this.personalForm.get("Mobile").value,
          dOB: this.personalForm.get("DOB").value,
          gender: this.theApplicantGender.key,
          applicationLetterUrl: [],
        };

        if (postData.stateId != this.jobApplyingFor.locationId) {
          this.messageService.add({
            severity: "error",
            summary: "Error",
            detail:
              "Err: You must reside in the state where this Job is posted",
          });
          return;
        }

        //This section is used while firebase fails
        // postData.passportImageUrl =
        //   "https://source.unsplash.com/user/erondu/1600x900";
        // postData.applicationLetterUrl.push(
        //   "https://source.unsplash.com/user/erondu/1600x900"
        // );
        // this._addPersonalInfo(postData);
        // return;

        this.fileStorageService.UploadFileFromDataUrl(this.uploadedPassport[0]);
        this.fileStorageService.onUploadFinished.subscribe(
          (resp: CommonResponse<string>) => {
            if (resp.responseCode != "00") {
              this.messageService.add({
                severity: "error",
                summary: "Notice",
                detail: resp.responseMsg,
              });
            } else {
              postData.passportImageUrl = resp.responseData;
              this._runApplicationLetterUploads(postData);
            }
          },
          (error) => {
            console.log("Error while uploading file " + error);
            this.messageService.add({
              severity: "error",
              summary: "Notice",
              detail: "ERR: Unable to upload image/file to storage",
            });
          }
        );
      },
    });
  }

  async _runApplicationLetterUploads(postData: StartJobApplication) {
    if (this.uploadedApplicationLetters.length > 0) {
      this.fileStorageService.UploadMultipleFilesFromDataUrl(
        this.uploadedApplicationLetters
      );
      this.fileStorageService.onUploadFinished.subscribe(
        async (resp: CommonResponse<string[]>) => {
          if (resp.responseCode != "00") {
            this.messageService.add({
              severity: "error",
              summary: "Notice",
              detail: resp.responseMsg,
            });
          } else {
            postData.applicationLetterUrl = resp.responseData;
            await this._addPersonalInfo(postData);
          }
        },
        (error) => {
          console.log("Error while uploading files " + error);
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail: "ERR: Unable to upload image(s)/file(s) to storage",
          });
        }
      );
    } else {
      await this._addPersonalInfo(postData);
    }
  }

  async _addPersonalInfo(postData: StartJobApplication) {
    this.gmaPremploymentService.StartJobApplication(postData).subscribe(
      async (data) => {
        this.personalForm.reset();
        this.trackingNo = data;
        this.theApplicantGender = null;
        //this.activeStep = this.activeStep + 1;
        this.RunJobApplicationCurrentStatusJob(this.activeStep + 2);

        await this.messageService.add({
          severity: "success",
          summary: "Completed",
          detail: "Job Application Started Successfully!, Kindly proceed.",
        });
      },
      (error) => {
        console.log("Error Occurred: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail: error
            ? "Unable to start job application at the moment.. Reason: [" +
              (error ? error.error.message : "request failed - permission") +
              "]"
            : "An Error Occurred (Mobile or Email Already used for this job)",
        });
      }
    );
  }

  AddBackgroundInfo() {
    this.confirmationService.confirm({
      message:
        "Kindly confirm that all data provided are true and that you wish to proceed with your application?",
      accept: () => {
        this.messageService.add({
          severity: "info",
          summary: "Notice",
          detail: "Processing Job Application Profile...",
        });

        if (!this.trackingNo) {
          this.messageService.add({
            severity: "error",
            summary: "Error",
            detail:
              "Err: Unable to get job applying for tracking data. Kindly refresh your page",
          });
          return;
        }

        const postData: AddBackgroundInfo = {
          trackingNo: this.trackingNo,
          stateOfOrigin: this.theBackgroundState.id,
          lGAOfOrigin: this.theBackgroundLga.id,
          tribe: this.backgroundForm.get("Tribe").value,
          maritalStatus: this.theMaritalStatus.key,
          marriageDate: this.backgroundForm.get("MarriageDate").value,
          marriageLocation: this.backgroundForm.get("MarriageLocation").value,
          belongsToAClub: this.isInAClubRadioButton == 1 ? true : false,
          clubName: this.backgroundForm.get("ClubName").value,
          spouseFirstname: this.backgroundForm.get("SpouseFirstname").value,
          spouseLastname: this.backgroundForm.get("SpouseLastname").value,
          spouseOccupation: this.backgroundForm.get("SpouseOccupation").value,
          fatherFullname: this.backgroundForm.get("FatherFullname").value,
          fatherAddress: this.backgroundForm.get("FatherAddress").value,
          motherFullname: this.backgroundForm.get("MotherFullname").value,
          motherAddress: this.backgroundForm.get("MotherAddress").value,
          children: [],
        };

        if (this.theMaritalStatus.key != MaritalStatusEnum.Single) {
          if (
            !postData.spouseFirstname ||
            !postData.spouseLastname ||
            !postData.spouseOccupation
          ) {
            this.messageService.add({
              severity: "error",
              summary: "Error",
              detail:
                "Err: You must input all your spouse information to proceed.",
            });
            return;
          }
        }

        if (this.childrenAddedToList.length > 0) {
          this.childrenAddedToList.forEach((child) => {
            postData.children.push({
              firstname: child.firstname,
              lastname: child.lastname,
              gender: child.gender,
              dateOfBirth: child.dateOfBirth,
            });
          });
        }

        this.gmaPremploymentService
          .AddBackgroundInfoToJobApplication(postData)
          .subscribe(
            async () => {
              this.backgroundForm.reset();
              this.RunJobApplicationCurrentStatusJob(this.activeStep + 2);

              await this.messageService.add({
                severity: "success",
                summary: "Completed",
                detail:
                  "Job Application Updated Successfully!, Kindly proceed.",
              });
            },
            (error) => {
              console.log("Error Occurred: " + error);
              this.messageService.add({
                severity: "error",
                summary: "Notice",
                detail:
                  "Unable to process job application at the moment.. Reason: [" +
                  error
                    ? error.error.message
                    : "request failed - permission" + "]",
              });
            }
          );
      },
    });
  }

  onUploadPassport(event, form) {
    this.uploadedPassport = [];
    for (const file of event.files) {
      this.uploadedPassport.push(file);
    }
    this.passportImageSrc = this.uploadedPassport[0].objectURL;

    this.messageService.add({
      severity: "success",
      summary: "Success",
      detail: "Passport Image Uploaded Successfully!",
    });
    form.clear();
    //this.userIsYetToClickUpload = false;
  }

  LoadUpStateLGAs() {
    if (this.theState) {
      // this.allLgas = this.theState.lgAs;
      this.allLgas = this.theState.lgas;
    } else {
      this.allLgas = [];
      this.theLga = null;
    }
  }

  LoadUpBackgroundStateLGAs() {
    if (this.theBackgroundState) {
      this.allBackgroundLgas = this.theBackgroundState.lgas;
    } else {
      this.allBackgroundLgas = [];
      this.theBackgroundLga = null;
    }
  }

  onCancelApplicationLetterUpload() {
    this.uploadedApplicationLetters = [];
  }

  NotifyUserToClickUpload(identifier: number) {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Kindly ensure you click upload.",
    });

    if (identifier == 1) {
      this.userIsYetToClickUploadApplicationLetter = true;
    }
  }

  onUploadApplicationLetter(event) {
    this.uploadedApplicationLetters = [];
    for (const file of event.files) {
      this.uploadedApplicationLetters.push(file);
    }

    this.messageService.add({
      severity: "success",
      summary: "Success",
      detail: "Application Letter(s) Uploaded",
    });

    this.userIsYetToClickUploadApplicationLetter = false;
  }

  get StreetValue() {
    return this.personalForm.get("Street").value;
  }

  get NxtOfKinStreetValue() {
    return this.nextofkinForm.get("Street").value;
  }

  get LGAValue() {
    return this.theLga ? this.theLga.name : "";
  }

  get NxtOfKinLGAValue() {
    return this.theNextOfKinLga ? this.theNextOfKinLga.name : "";
  }

  get StateValue() {
    return this.theState ? this.theState.name : "";
  }

  get NxtOfKinStateValue() {
    return this.theNextOfKinState ? this.theNextOfKinState.name : "";
  }

  StartApplication() {
    this.isApplicationOngoing = true;
  }

  TrackJobApplication() {
    if (!this.inputedTrackingNo) {
      this.messageService.add({
        severity: "error",
        summary: "Notice",
        detail: "You must input your unique job application tracking no.",
      });
    }

    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Tracking Job Application Profile...",
    });

    this.gmaPremploymentService
      .TrackJobApplication(this.inputedTrackingNo)
      .subscribe(
        async (data) => {
          this.jobApplyingFor = data.jobPost;
          this.trackingNo = data.trackingNo;
          this.infoTableTable[0].data = data.jobPost.title;
          this.infoTableTable[1].data = data.jobPost.jobCode;
          this.infoTableTable[2].data = data.jobPost.location.name;
          this.RunJobApplicationCurrentStatusJob(data.currentStatus);

          this.messageService.add({
            severity: "success",
            summary: "Completed",
            detail: "Job Application Tracked Successfully!, Kindly proceed.",
          });
        },
        (error) => {
          console.log("Error ocurred " + error);
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail:
              "Unable to track job application at the moment.. Reason: [" +
              error
                ? error.error.message
                : "request failed - permission" + "]",
          });
        }
      );
  }

  RunJobApplicationCurrentStatusJob(status: number) {
    if (status == JobApplicationStatusEnum.PersonalInfo) this.activeStep = 0;
    else if (status == JobApplicationStatusEnum.BackgroundInfo)
      this.activeStep = 1;
    else if (status == JobApplicationStatusEnum.NextOfKinInfo)
      this.activeStep = 2;
    else if (status == JobApplicationStatusEnum.EducationalInfo) {
      this.msgs = [];
      this.msgs.push({
        severity: "info",
        summary: "Notice:",
        detail:
          "Kindly note that the minimum educational qualification for this job is " +
          this.GetMinEducationLevelLabel(
            this.jobApplyingFor.minimumEducationLevel
          ) +
          " institution.",
      });
      this.activeStep = 3;
    } else if (status == JobApplicationStatusEnum.PrevEmploymentInfo) {
      this.msgs = [];
      this.msgs.push({
        severity: "info",
        summary: "Notice:",
        detail:
          "Kindly note that the minimum years of experience for this job is " +
          this.jobApplyingFor.minimumExperienceYrs +
          " year(s).",
      });

      this.activeStep = 4;
    } else if (status == JobApplicationStatusEnum.MedicalAccountInfo)
      this.activeStep = 5;
    else if (status == JobApplicationStatusEnum.AdditionalInfo)
      this.activeStep = 6;
    else if (status == JobApplicationStatusEnum.Submitted) {
      this.isApplicationCompleted = true;
      this.isApplicationOngoing = false;
      return;
    } else {
      this.isApplicationOngoing = false;
      return;
    }
    this.isApplicationOngoing = true;
  }

  AddChildToList() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Adding Child Info...",
    });

    this.childrenAddedToList.push({
      lastname: this.ChildLastnameValue,
      firstname: this.ChildFirstnameValue,
      gender: this.theGender.key,
      dateOfBirth: this.ChildDOBValue,
    });

    this.backgroundForm.patchValue({
      ChildFirstname: "",
      ChildLastname: "",
      ChildDOB: "",
    });
    this.theGender = null;

    this.messageService.add({
      severity: "success",
      summary: "Completed",
      detail: "Added Successfully.",
    });
  }

  RemoveChildFromList(item: ChildInfo) {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Removing Child Info...",
    });

    this.childrenAddedToList.splice(this.childrenAddedToList.indexOf(item), 1);

    this.messageService.add({
      severity: "success",
      summary: "Completed",
      detail: "Removed Successfully.",
    });
  }

  AddNextOfKinInfo() {
    this.confirmationService.confirm({
      message:
        "Kindly confirm that all data provided are true and that you wish to proceed with your application?",
      accept: () => {
        this.messageService.add({
          severity: "info",
          summary: "Notice",
          detail: "Processing Job Application Profile...",
        });

        if (!this.trackingNo) {
          this.messageService.add({
            severity: "error",
            summary: "Error",
            detail:
              "Err: Unable to get job applying for tracking data. Kindly refresh your page",
          });
        }

        const postData: AddNextOfKins = {
          trackingNo: this.trackingNo,
          nextOfKins: this.nextOfKins,
        };

        this.gmaPremploymentService
          .AddNextOfKinInfoToJobApplication(postData)
          .subscribe(
            async () => {
              this.nextofkinForm.reset();
              this.RunJobApplicationCurrentStatusJob(this.activeStep + 2);

              await this.messageService.add({
                severity: "success",
                summary: "Completed",
                detail:
                  "Job Application Updated Successfully!, Kindly proceed.",
              });
            },
            (error) => {
              console.log("Error Occurred: " + error);
              this.messageService.add({
                severity: "error",
                summary: "Notice",
                detail:
                  "Unable to process job application at the moment.. Reason: [" +
                  error
                    ? error.error.message
                    : "request failed - permission" + "]",
              });
            }
          );
      },
    });
  }

  AddNextOfKinToList() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Adding Next Of Kin Info...",
    });

    this.nextOfKins.push({
      firstname: this.nextofkinForm.get("Firstname").value,
      lastname: this.nextofkinForm.get("Lastname").value,
      state: this.theNextOfKinState.id,
      stateEntity: this.theNextOfKinState,
      lGA: this.theNextOfKinLga.id,
      lGAEntity: this.theNextOfKinLga,
      othername: this.nextofkinForm.get("Othername").value,
      street: this.nextofkinForm.get("Street").value,
      relationship: this.theNxtOfKinRelationship.key,
      relationshipValue: this.theNxtOfKinRelationship.value,
      mobile: this.nextofkinForm.get("Mobile").value,
    });
    this.nextofkinForm.reset();
    this.theNxtOfKinRelationship = null;
    this.theNextOfKinState = null;
    this.theNextOfKinLga = null;

    this.messageService.add({
      severity: "success",
      summary: "Completed",
      detail: "Added Successfully.",
    });
  }

  getTimeOfDay(): string {
    const now = new Date();
    const hour = now.getHours();

    let shift = "Good ";
    shift += hour >= 4 && hour <= 11 ? "Morning" : "";
    shift += hour >= 12 && hour <= 16 ? "Afternoon" : "";
    shift += hour >= 17 && hour <= 20 ? "Evening" : "";
    shift += hour >= 21 || hour <= 3 ? "Evening" : "";
    return shift;
  }

  LoadUpNextofKinStateLGAs() {
    if (this.theNextOfKinState) {
      this.allNextOfKinLgas = this.theNextOfKinState.lgas;
    } else {
      this.allNextOfKinLgas = [];
      this.theNextOfKinLga = null;
    }
  }

  RemoveNextOfKin(item: AddNextOfKin) {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Removing Next Of Kin Info...",
    });

    this.nextOfKins.splice(this.nextOfKins.indexOf(item), 1);

    this.messageService.add({
      severity: "success",
      summary: "Completed",
      detail: "Removed Successfully.",
    });
  }

  AddEducationInfo() {
    this.confirmationService.confirm({
      message:
        "Kindly confirm that all data provided are true and that you wish to proceed with your application?",
      accept: () => {
        this.messageService.add({
          severity: "info",
          summary: "Notice",
          detail: "Processing Job Application Profile...",
        });

        if (!this.trackingNo) {
          this.messageService.add({
            severity: "error",
            summary: "Error",
            detail:
              "Err: Unable to get job applying for tracking data. Kindly refresh your page",
          });

          this.msgs = [];
          this.msgs.push({
            severity: "info",
            summary: "Notice:",
            detail:
              "Kindly note that the minimum educational qualification for this job is " +
              this.GetMinEducationLevelLabel(
                this.jobApplyingFor.minimumEducationLevel
              ) +
              " institution.",
          });
          return;
        }

        const postData: AddEducationalBackgrounds = {
          trackingNo: this.trackingNo,
          backgrounds: this.educationalBackgroundList,
        };

        //New Validation to check if the job's minimum educational level has been met
        let educCriteria = this._checkJobEducationalCriteria();
        if (!educCriteria) {
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail:
              "Apologies, you haven't provided the Job's minimum educational qualification.",
          });

          this.msgs = [];
          this.msgs.push({
            severity: "info",
            summary: "Notice:",
            detail:
              "Kindly note that the minimum educational qualification for this job is " +
              this.GetMinEducationLevelLabel(
                this.jobApplyingFor.minimumEducationLevel
              ) +
              " institution.",
          });
          return;
        }

        this.gmaPremploymentService
          .AddEducationalInfoToJobApplication(postData)
          .subscribe(
            async () => {
              this.educationForm.reset();
              this.RunJobApplicationCurrentStatusJob(this.activeStep + 2);

              await this.messageService.add({
                severity: "success",
                summary: "Completed",
                detail:
                  "Job Application Updated Successfully!, Kindly proceed.",
              });
            },
            (error) => {
              console.log("Error Occurred: " + error);
              this.messageService.add({
                severity: "error",
                summary: "Notice",
                detail:
                  "Unable to process job application at the moment.. Reason: [" +
                  error
                    ? error.error.message
                    : "request failed - permission" + "]",
              });

              this.msgs = [];
              this.msgs.push({
                severity: "info",
                summary: "Notice:",
                detail:
                  "Kindly note that the minimum educational qualification for this job is " +
                  this.GetMinEducationLevelLabel(
                    this.jobApplyingFor.minimumEducationLevel
                  ) +
                  " institution.",
              });
            }
          );
      },
    });
  }

  _checkJobEducationalCriteria(): boolean {
    let result = false;

    this.educationalBackgroundList.forEach((educ) => {
      if (educ.educationalLevel == this.jobApplyingFor.minimumEducationLevel)
        result = true;
    });

    return result;
  }

  AddEducToList() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Adding Educational Background...",
    });

    var startDate = this.educationForm.get("ProgramStartDate").value as Date;
    var endDate = this.educationForm.get("ProgramEndDate").value as Date;

    if (startDate > endDate) {
      this.messageService.add({
        severity: "error",
        summary: "Error",
        detail: "Err: Start Date must not be greater than end date",
      });

      this.msgs = [];
      this.msgs.push({
        severity: "info",
        summary: "Notice:",
        detail:
          "Kindly note that the minimum educational qualification for this job is " +
          this.GetMinEducationLevelLabel(
            this.jobApplyingFor.minimumEducationLevel
          ) +
          " institution.",
      });
      return;
    }

    this.educationalBackgroundList.push({
      educationalLevel: this.theEducationalLevel.key,
      educationalLevelValue: this.theEducationalLevel.value,
      programStartDate: this.educationForm.get("ProgramStartDate").value,
      institution: this.educationForm.get("Institution").value,
      programEndDate: this.educationForm.get("ProgramEndDate").value,
      qualificationAttained: this.educationForm.get("QualificationAttained")
        .value,
      gradeAwarded: this.educationForm.get("GradeAwarded").value,
    });
    this.educationForm.reset();
    this.theEducationalLevel = null;

    this.messageService.add({
      severity: "success",
      summary: "Completed",
      detail: "Added Successfully.",
    });

    this.msgs = [];
    this.msgs.push({
      severity: "info",
      summary: "Notice:",
      detail:
        "Kindly note that the minimum educational qualification for this job is " +
        this.GetMinEducationLevelLabel(
          this.jobApplyingFor.minimumEducationLevel
        ) +
        " institution.",
    });
  }

  RemoveNEducationalBackground(item: AddEducationalBackground) {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Removing Educational Background...",
    });

    this.educationalBackgroundList.splice(
      this.educationalBackgroundList.indexOf(item),
      1
    );

    this.messageService.add({
      severity: "success",
      summary: "Completed",
      detail: "Removed Successfully.",
    });

    this.msgs = [];
    this.msgs.push({
      severity: "info",
      summary: "Notice:",
      detail:
        "Kindly note that the minimum educational qualification for this job is " +
        this.GetMinEducationLevelLabel(
          this.jobApplyingFor.minimumEducationLevel
        ) +
        " institution.",
    });
  }

  AddPrevEmployerInfo() {
    this.confirmationService.confirm({
      message:
        "Kindly confirm that all data provided are true and that you wish to proceed with your application?",
      accept: () => {
        this.messageService.add({
          severity: "info",
          summary: "Notice",
          detail: "Processing Job Application Profile...",
        });

        if (!this.trackingNo) {
          this.messageService.add({
            severity: "error",
            summary: "Error",
            detail:
              "Err: Unable to get job applying for tracking data. Kindly refresh your page",
          });

          this.msgs = [];
          this.msgs.push({
            severity: "info",
            summary: "Notice:",
            detail:
              "Kindly note that the minimum years of experience for this job is " +
              this.jobApplyingFor.minimumExperienceYrs +
              " year(s).",
          });
        }

        const postData: AddPrevEmployers = {
          trackingNo: this.trackingNo,
          employers: this.prevEmployerList,
        };

        //Validation to check that Job's minimum years of experience criteria has been met -- Will run this from the backend
        // let criteriaMet = this._checkJobMinYrsOfExperienceCriteria();
        // if (!criteriaMet) {
        //   this.messageService.add({
        //     severity: "error",
        //     summary: "Notice",
        //     detail:
        //       "Apologies, you haven't met the Job's minimum years of experience criteria.",
        //   });
        //   return;
        // }

        this.gmaPremploymentService
          .AddPrevEmployerInfoToJobApplication(postData)
          .subscribe(
            async () => {
              this.prevEmployerForm.reset();
              this.RunJobApplicationCurrentStatusJob(this.activeStep + 2);

              await this.messageService.add({
                severity: "success",
                summary: "Completed",
                detail:
                  "Job Application Updated Successfully!, Kindly proceed.",
              });
            },
            (error) => {
              console.log("Error Occurred: " + error);
              this.messageService.add({
                severity: "error",
                summary: "Notice",
                detail:
                  "Unable to process job application at the moment.. Reason: [" +
                  error
                    ? error.error.message
                    : "request failed - permission" + "]",
              });

              this.msgs = [];
              this.msgs.push({
                severity: "info",
                summary: "Notice:",
                detail:
                  "Kindly note that the minimum years of experience for this job is " +
                  this.jobApplyingFor.minimumExperienceYrs +
                  " year(s).",
              });
            }
          );
      },
    });
  }

  _checkJobMinYrsOfExperienceCriteria(): boolean {
    let result = false;
    let experienceInMonths = 0;
    let criteria = this.jobApplyingFor.minimumExperienceYrs * 12;
    this.prevEmployerList.forEach((emp) => {
      var yrEmployed = new Date(emp.yearOfEmployment);
      var yrTerminated = new Date(emp.yearOfTermination);
      console.log(yrEmployed);
      console.log(yrTerminated);
      var months;
      months = (yrTerminated.getFullYear() - yrEmployed.getFullYear()) * 12;
      months -= yrEmployed.getMonth();
      months += yrTerminated.getMonth();
      months = months <= 0 ? 0 : months;
      experienceInMonths = experienceInMonths + months;
    });

    if (experienceInMonths >= criteria) result = true;

    return result;
  }

  LoadUpEmployerStateLGAs() {
    if (this.theEmployerState) {
      this.allEmployerLgas = this.theEmployerState.lgas;
    } else {
      this.allEmployerLgas = [];
      this.theEmployerLga = null;
    }
  }

  AddPrevEmpToList() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Adding Previous Employer...",
    });

    var startDate = this.prevEmployerForm.get("YearOfEmployment").value as Date;
    var endDate = this.prevEmployerForm.get("YearOfTermination").value as Date;

    if (startDate > endDate) {
      this.messageService.add({
        severity: "error",
        summary: "Error",
        detail: "Err: Date of Exit must not be greater than Date of Employment",
      });
      return;
    }

    this.prevEmployerList.push({
      name: this.prevEmployerForm.get("EmployerName").value,
      role: this.prevEmployerForm.get("RoleOccupied").value,
      state: this.theEmployerState.id,
      stateName: this.theEmployerState.name,
      lGA: this.theEmployerLga.id,
      lGAName: this.theEmployerLga.name,
      street: this.prevEmployerForm.get("EmployerStreet").value,
      yearOfEmployment: this.prevEmployerForm.get("YearOfEmployment").value,
      yearOfTermination: this.prevEmployerForm.get("YearOfTermination").value,
      reasonForTermination: this.prevEmployerForm.get("ReasonForTermination")
        .value,
    });
    this.prevEmployerForm.reset();
    this.theEmployerState = null;
    this.theEmployerLga = null;
    this.allEmployerLgas = [];

    this.messageService.add({
      severity: "success",
      summary: "Completed",
      detail: "Added Successfully.",
    });

    this.msgs = [];
    this.msgs.push({
      severity: "info",
      summary: "Notice:",
      detail:
        "Kindly note that the minimum years of experience for this job is " +
        this.jobApplyingFor.minimumExperienceYrs +
        " year(s).",
    });
  }

  RemovePreviousEmployer(item: AddPrevEmployer) {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Removing Previous Employer...",
    });

    this.prevEmployerList.splice(this.prevEmployerList.indexOf(item), 1);

    this.messageService.add({
      severity: "success",
      summary: "Completed",
      detail: "Removed Successfully.",
    });

    this.msgs = [];
    this.msgs.push({
      severity: "info",
      summary: "Notice:",
      detail:
        "Kindly note that the minimum years of experience for this job is " +
        this.jobApplyingFor.minimumExperienceYrs +
        " year(s).",
    });
  }

  async AddMedicalAccountInfo() {
    // Validate First Bank Account
    this.paystackGatewayService
      .ResolveAccount(
        this.medAcctForm.get("PrimaryAcctNo").value,
        this.thePrimaryBank.bankCode
      )
      .subscribe(
        async (data) => {
          if (data.responseCode != "00") {
            this.messageService.add({
              severity: "error",
              summary: data.responseMsg,
            });
            return;
          } else {
            if (!data.responseData.status) {
              this.messageService.add({
                severity: "error",
                summary: "Unable to validate primary account details.",
              });
              return;
            }

            this.primaryAcctName = data.responseData.data.account_name;
            if (this.theSecondaryBank) {
              this.paystackGatewayService
                .ResolveAccount(
                  this.medAcctForm.get("SecondaryAcctNo").value,
                  this.theSecondaryBank.bankCode
                )
                .subscribe(
                  async (data2) => {
                    if (data2.responseCode != "00") {
                      this.messageService.add({
                        severity: "error",
                        summary: data2.responseMsg,
                      });
                      return;
                    }

                    if (!data2.responseData.status) {
                      this.messageService.add({
                        severity: "error",
                        summary:
                          "Unable to validate secondary account details.",
                      });
                      return;
                    }

                    this.secondaryAcctName =
                      data2.responseData.data.account_name;
                    await this._addMedicalAccountInfo();
                  },
                  (error) => {
                    console.log("Error Occurred: " + error);
                    this.messageService.add({
                      severity: "error",
                      summary: "Notice",
                      detail:
                        "Unable to validate secondary bank account at the moment.. Reason: [" +
                        error
                          ? error.error.message
                          : "request failed - permission" + "]",
                    });
                  }
                );
            } else await this._addMedicalAccountInfo();
          }
        },
        (error) => {
          console.log("Error Occurred: " + error);
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail:
              "Unable to validate primary bank account at the moment.. Reason: [" +
              error
                ? error.error.message
                : "request failed - permission" + "]",
          });
        }
      );
  }

  async _addMedicalAccountInfo() {
    this.confirmationService.confirm({
      message:
        "Kindly confirm that all data provided are true and that you wish to proceed with your application?",
      accept: () => {
        this.messageService.add({
          severity: "info",
          summary: "Notice",
          detail: "Processing Job Application Profile...",
        });

        if (!this.trackingNo) {
          this.messageService.add({
            severity: "error",
            summary: "Error",
            detail:
              "Err: Unable to get job applying for tracking data. Kindly refresh your page",
          });
        }

        const postData: AddMedicalAccountInfo = {
          trackingNo: this.trackingNo,
          isDrinking: this.isDrinkingRadioButton == 1 ? true : false,
          isSmoking: this.isSmokingRadioButton == 1 ? true : false,
          isGambling: this.isGamblingRadioButton == 1 ? true : false,
          isHypertensive: this.isHypertensiveRadioButton == 1 ? true : false,
          hasArthritis: this.isArthritusRadioButton == 1 ? true : false,
          isDiabetic: this.isDiabeticRadioButton == 1 ? true : false,
          isSufferingUnknown:
            this.isSufferingUnknownRadioButton == 1 ? true : false,
          unknownDiseaseDescription:
            this.medAcctForm.get("UnknownDiseaseDesc").value,
          primaryBank: this.thePrimaryBank.name,
          primaryAccountBankId: this.thePrimaryBank.id,
          primaryBankAcctNo: this.medAcctForm.get("PrimaryAcctNo").value,
          secondaryBank: this.theSecondaryBank.name,
          secondaryAccountBankId: this.theSecondaryBank.id,
          secondaryBankAcctNo: this.medAcctForm.get("SecondaryAcctNo").value,
          pensionPin: this.medAcctForm.get("PensionPin").value,
          tIN: this.medAcctForm.get("TaxIdentificationNo").value,
          bVN: this.medAcctForm.get("BVN").value,
          pensionPayeeID: this.medAcctForm.get("PPID").value,
          nIN: this.medAcctForm.get("NIN").value,
          primaryAccountName: this.primaryAcctName,
          secondaryAccountName: this.secondaryAcctName,
        };

        this.gmaPremploymentService
          .AddMedicalAccountInfoToJobApplication(postData)
          .subscribe(
            async () => {
              this.medAcctForm.reset();
              this.RunJobApplicationCurrentStatusJob(this.activeStep + 2);

              await this.messageService.add({
                severity: "success",
                summary: "Completed",
                detail:
                  "Job Application Updated Successfully!, Kindly proceed.",
              });
            },
            (error) => {
              console.log("Error Occurred: " + error);
              this.messageService.add({
                severity: "error",
                summary: "Notice",
                detail:
                  "Unable to process job application at the moment.. Reason: [" +
                  error
                    ? error.error.message
                    : "request failed - permission" + "]",
              });
            }
          );
      },
    });
  }

  AddAdditionalInfo() {
    this.confirmationService.confirm({
      message:
        "Kindly confirm that all data provided are true and that you wish to submit your application?",
      accept: () => {
        this.messageService.add({
          severity: "info",
          summary: "Notice",
          detail: "Processing Job Application Profile...",
        });

        if (!this.trackingNo) {
          this.messageService.add({
            severity: "error",
            summary: "Error",
            detail:
              "Err: Unable to get job applying for tracking data. Kindly refresh your page",
          });
        }

        const postData: AddAdditionalInfo = {
          trackingNo: this.trackingNo,
          isExMilitant: this.isExMilitantRadioButton == 1 ? true : false,
          hasCombatTraining: this.isCombatantRadioButton == 1 ? true : false,
          hasBeenArrested: this.isArrestedRadioButton == 1 ? true : false,
          hasBeenConvicted: this.isConvictedRadioButton == 1 ? true : false,
          hasBeenAskedToResign: this.isResignedRadioButton == 1 ? true : false,
          agreesToTerms: true,
          hasDeclared: true,
        };

        this.gmaPremploymentService
          .AddAdditionalInfoToJobApplication(postData)
          .subscribe(
            async () => {
              this.additionalForm.reset();
              this.RunJobApplicationCurrentStatusJob(this.activeStep + 2);

              await this.messageService.add({
                severity: "success",
                summary: "Completed",
                detail:
                  "Job Application Submitted Successfully!, Thank you for applying to this job.",
              });
            },
            (error) => {
              console.log("Error Occurred: " + error);
              this.messageService.add({
                severity: "error",
                summary: "Notice",
                detail:
                  "Unable to submit job application at the moment.. Reason: [" +
                  error
                    ? error.error.message
                    : "request failed - permission" + "]",
              });
            }
          );
      },
    });
  }

  MoveToJobApplicationPhase() {
    this.isOnJobApplicationPhase = true;
    this.openApplyDialogue = false;
  }

  EditNextOfKin(item: AddNextOfKin) {
    this.editingNextOfKin = true;
    this.nextofkinForm.patchValue({
      Firstname: item.firstname,
      Lastname: item.lastname,
      Othername: item.othername,
      Street: item.street,
      Mobile: item.mobile,
      Email: item.email,
    });

    this.theNextOfKinState = this.allStates.find((x) => x.id == item.state);
    this.allNextOfKinLgas = this.theNextOfKinState.lgas;
    this.theNextOfKinLga = this.allNextOfKinLgas.find((x) => x.id == item.lGA);
    this.theNxtOfKinRelationship = this.allNxtOfKinRelationships.find(
      (x) => x.key == item.relationship
    );
    this.nextOfKinToUpdate = item;
    this.formWrapper.nativeElement.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "start",
    });
  }

  CloseEditingNextOfKin() {
    this.editingNextOfKin = false;
    this.nextofkinForm.reset();
    this.theNextOfKinLga = null;
    this.theNextOfKinState = null;
    this.theNxtOfKinRelationship = null;
    this.nextOfKinToUpdate = null;
  }

  UpdateNextOfKin() {
    this.nextOfKinToUpdate.firstname =
      this.nextofkinForm.get("Firstname").value;
    this.nextOfKinToUpdate.lastname = this.nextofkinForm.get("Lastname").value;
    this.nextOfKinToUpdate.state = this.theNextOfKinState.id;
    this.nextOfKinToUpdate.stateEntity = this.theNextOfKinState;
    this.nextOfKinToUpdate.lGA = this.theNextOfKinLga.id;
    this.nextOfKinToUpdate.lGAEntity = this.theNextOfKinLga;
    this.nextOfKinToUpdate.othername =
      this.nextofkinForm.get("Othername").value;
    this.nextOfKinToUpdate.street = this.nextofkinForm.get("Street").value;
    this.nextOfKinToUpdate.relationship = this.theNxtOfKinRelationship.key;
    this.nextOfKinToUpdate.relationshipValue =
      this.theNxtOfKinRelationship.value;
    this.nextOfKinToUpdate.mobile = this.nextofkinForm.get("Mobile").value;
    this.nextofkinForm.reset();
    this.theNxtOfKinRelationship = null;
    this.theNextOfKinState = null;
    this.theNextOfKinLga = null;
    this.CloseEditingNextOfKin();

    this.messageService.add({
      severity: "success",
      summary: "Completed",
      detail: "Information Updated Successfully.",
    });
  }

  CloseEditingEduc() {
    this.editingEducInfo = false;
    this.educationForm.reset();
    this.theEducationalLevel = null;
    this.educInfoToUpdate = null;
  }

  EditEducInfo(item: AddEducationalBackground) {
    this.editingEducInfo = true;
    this.educationForm.patchValue({
      ProgramStartDate: item.programStartDate,
      Institution: item.institution,
      ProgramEndDate: item.programEndDate,
      QualificationAttained: item.qualificationAttained,
      GradeAwarded: item.gradeAwarded,
    });

    this.theEducationalLevel = this.allEducationalLevels.find(
      (x) => x.key == item.educationalLevel
    );
    this.educInfoToUpdate = item;
    this.formWrapper.nativeElement.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "start",
    });
  }

  UpdateEducInfo() {
    var startDate = this.educationForm.get("ProgramStartDate").value as Date;
    var endDate = this.educationForm.get("ProgramEndDate").value as Date;

    if (startDate > endDate) {
      this.messageService.add({
        severity: "error",
        summary: "Error",
        detail: "Err: Start Date must not be greater than end date",
      });

      this.msgs = [];
      this.msgs.push({
        severity: "info",
        summary: "Notice:",
        detail:
          "Kindly note that the minimum educational qualification for this job is " +
          this.GetMinEducationLevelLabel(
            this.jobApplyingFor.minimumEducationLevel
          ) +
          " institution.",
      });
      return;
    }

    this.educInfoToUpdate.educationalLevel = this.theEducationalLevel.key;
    this.educInfoToUpdate.educationalLevelValue =
      this.theEducationalLevel.value;
    this.educInfoToUpdate.programStartDate =
      this.educationForm.get("ProgramStartDate").value;
    this.educInfoToUpdate.institution =
      this.educationForm.get("Institution").value;
    this.educInfoToUpdate.programEndDate =
      this.educationForm.get("ProgramEndDate").value;
    this.educInfoToUpdate.qualificationAttained = this.educationForm.get(
      "QualificationAttained"
    ).value;
    this.educInfoToUpdate.gradeAwarded =
      this.educationForm.get("GradeAwarded").value;
    this.CloseEditingEduc();

    this.messageService.add({
      severity: "success",
      summary: "Completed",
      detail: "Information Updated Successfully.",
    });

    this.msgs = [];
    this.msgs.push({
      severity: "info",
      summary: "Notice:",
      detail:
        "Kindly note that the minimum educational qualification for this job is " +
        this.GetMinEducationLevelLabel(
          this.jobApplyingFor.minimumEducationLevel
        ) +
        " institution.",
    });
  }

  CloseEditingPrevEmp() {
    this.editingPrevEmp = false;
    this.prevEmployerForm.reset();
    this.theEmployerLga = null;
    this.allEmployerLgas = [];
    this.theEmployerState = null;
    this.prevEmpToUpdate = null;
  }

  EditPreviousEmployer(item: AddPrevEmployer) {
    this.editingPrevEmp = true;
    this.prevEmployerForm.patchValue({
      EmployerName: item.name,
      RoleOccupied: item.role,
      YearOfEmployment: item.yearOfEmployment,
      YearOfTermination: item.yearOfTermination,
      EmployerStreet: item.street,
      ReasonForTermination: item.reasonForTermination,
    });

    this.theEmployerState = this.allStates.find((x) => x.id == item.state);
    this.allEmployerLgas = this.theEmployerState.lgas;
    this.theEmployerLga = this.allEmployerLgas.find((x) => x.id == item.lGA);
    this.prevEmpToUpdate = item;
    this.formWrapper.nativeElement.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "start",
    });
  }

  UpdatePrevEmpInfo() {
    var startDate = this.prevEmployerForm.get("YearOfEmployment").value as Date;
    var endDate = this.prevEmployerForm.get("YearOfTermination").value as Date;

    if (startDate > endDate) {
      this.messageService.add({
        severity: "error",
        summary: "Error",
        detail: "Err: Date of Exit must not be greater than Date of Employment",
      });

      this.msgs = [];
      this.msgs.push({
        severity: "info",
        summary: "Notice:",
        detail:
          "Kindly note that the minimum years of experience for this job is " +
          this.jobApplyingFor.minimumExperienceYrs +
          " year(s).",
      });
      return;
    }

    this.prevEmpToUpdate.name = this.prevEmployerForm.get("EmployerName").value;
    this.prevEmpToUpdate.role = this.prevEmployerForm.get("RoleOccupied").value;
    this.prevEmpToUpdate.state = this.theEmployerState.id;
    this.prevEmpToUpdate.stateName = this.theEmployerState.name;
    this.prevEmpToUpdate.lGA = this.theEmployerLga.id;
    this.prevEmpToUpdate.lGAName = this.theEmployerLga.name;
    this.prevEmpToUpdate.street =
      this.prevEmployerForm.get("EmployerStreet").value;
    this.prevEmpToUpdate.yearOfEmployment =
      this.prevEmployerForm.get("YearOfEmployment").value;
    this.prevEmpToUpdate.yearOfTermination =
      this.prevEmployerForm.get("YearOfTermination").value;
    this.prevEmpToUpdate.reasonForTermination = this.prevEmployerForm.get(
      "ReasonForTermination"
    ).value;

    this.CloseEditingPrevEmp();

    this.messageService.add({
      severity: "success",
      summary: "Completed",
      detail: "Information Updated Successfully.",
    });

    this.msgs = [];
    this.msgs.push({
      severity: "info",
      summary: "Notice:",
      detail:
        "Kindly note that the minimum years of experience for this job is " +
        this.jobApplyingFor.minimumExperienceYrs +
        " year(s).",
    });
  }

  get ChildFirstnameValue() {
    return this.backgroundForm.get("ChildFirstname").value;
  }
  get ChildLastnameValue() {
    return this.backgroundForm.get("ChildLastname").value;
  }
  get ChildDOBValue() {
    return this.backgroundForm.get("ChildDOB").value;
  }
  get PrevEmpStreetValue() {
    return this.prevEmployerForm.get("EmployerStreet").value;
  }
  get PrevEmpStateValue() {
    return this.theEmployerState ? this.theEmployerState.name : "";
  }
  get PrevEmpLGAValue() {
    return this.theEmployerLga ? this.theEmployerLga.name : "";
  }
}
