import {
  TaxRelief,
  TaxBand,
  CreateTaxVM,
  UpdateTaxVM,
  TaxBandRelationshipEnum,
} from "./../../../interfaces/payroll";
import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ConfirmationService, Message, MessageService } from "primeng/api";
import { BreadcrumbService } from "src/app/breadcrumb.service";
import { Tax } from "src/app/interfaces/payroll";
import { PayrollService } from "src/app/services/payroll.service";
import { Account } from "src/app/interfaces/home";

@Component({
  selector: "app-payroll-tax-settings",
  templateUrl: "./payroll-tax-settings.component.html",
  styleUrls: ["./payroll-tax-settings.component.scss"],
  providers: [MessageService],
})
export class PayrollTaxSettingsComponent implements OnInit {
  @ViewChild("formWrapper") public formWrapper: ElementRef;
  taxForm: FormGroup;
  fetchingTaxes: boolean = true;
  allTaxes: Tax[];
  selectedTax: Tax;
  taxCols: any[];
  editingTaxes: boolean = false;
  taxReliefMsg: Message[] = [];
  taxReliefNxtValue: number;
  taxBandCaption: string;
  taxReliefApplicableRate: number;
  allTaxReliefs: TaxRelief[] = [];
  selectedTaxRelief: TaxRelief;
  taxReliefCols: any[];
  taxBandCols: any[];
  taxBandMsg: Message[] = [];
  isTaxBandOptionRadioButton: number;
  taxBandFlatFigure: number;
  taxBandPercApply: number;
  taxBandDesc: string;
  allTaxBands: TaxBand[] = [];
  selectedTaxBand: TaxBand;
  allTaxBandRelationships: {
    key: number;
    value: string;
  }[];
  theTaxBandRelationship: {
    key: number;
    value: string;
  };
  taxToEdit: Tax;
  allAccounts: Account[];
  theTaxAccount: Account;

  constructor(
    private fb: FormBuilder,
    private payrollService: PayrollService,
    private breadcrumbService: BreadcrumbService,
    public confirmationService: ConfirmationService,
    public messageService: MessageService
  ) {
    this.taxForm = fb.group({
      Caption: ["", Validators.required],
      Description: ["", Validators.required],
      Alias: ["", Validators.required],
      TaxAccount: ["", Validators.required],
    });
  }

  ngOnInit(): void {
    this.breadcrumbService.setItems([
      {
        label: "Payroll Management",
        routerLink: ["/home/payroll/payroll-settings"],
      },
      {
        label: "Tax Settings",
        routerLink: ["/home/payroll/tax-settings"],
      },
    ]);

    this.taxCols = [
      { field: "caption", header: "Caption" },
      { field: "description", header: "Description" },
      { field: "alias", header: "Alias" },
      { field: "taxReliefSetup", header: "TaxReliefSetup" },
      { field: "taxBandSetup", header: "TaxBandSetup" },
    ];

    this.taxReliefCols = [
      { field: "nextValue", header: "NextValue" },
      { field: "rate", header: "Rate" },
      { field: "sequence", header: "Sequence" },
    ];

    this.taxBandCols = [
      { field: "flatFigure", header: "FlatFigure" },
      { field: "percentageToApply", header: "PercentageToApply" },
      { field: "description", header: "Description" },
    ];

    this.allTaxBandRelationships = [
      {
        key: TaxBandRelationshipEnum.OR,
        value: "OR",
      },
      {
        key: TaxBandRelationshipEnum.AND,
        value: "AND",
      },
    ];

    this.ResetMessageToasters();
    this.FetchAllTaxes();
    this.FetchAllFilteredAccounts();
  }

  ResetMessageToasters() {
    this.taxReliefMsg = [];
    this.taxReliefMsg.push({
      severity: "info",
      summary: "Notice:",
      detail: "Only Annual Figures Should be Captured.",
    });

    this.taxBandMsg = [];
    this.taxBandMsg.push({
      severity: "info",
      summary: "Notice:",
      detail: "Only Annual Figures Should be Captured.",
    });
  }

  FetchAllTaxes() {
    this.payrollService.GetAllTaxes().subscribe(
      async (data) => {
        this.allTaxes = data;
        this.allTaxes.forEach((tax) => {
          tax.taxBands = tax.taxBands.filter((x) => x.isDeleted == false);
          tax.taxReliefs = tax.taxReliefs.filter((x) => x.isDeleted == false);
        });
        this.fetchingTaxes = false;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable get all taxes at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
        this.fetchingTaxes = false;
        this.ResetMessageToasters();
      }
    );
  }

  FetchAllFilteredAccounts() {
    this.payrollService.GetFilteredAccountList().subscribe(
      async (data) => {
        data.crAccounts.forEach((acct) => {
          acct.label = acct.accountNumber + " - " + acct.name;
        });
        data.drAccounts.forEach((acct) => {
          acct.label = acct.accountNumber + " - " + acct.name;
        });

        this.allAccounts = data.crAccounts;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable get all filtered accounts at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
        this.ResetMessageToasters();
      }
    );
  }

  CreateTax() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Creating Tax...",
    });
    this.ResetMessageToasters();

    const postData: CreateTaxVM = {
      caption: this.taxForm.get("Caption").value,
      description: this.taxForm.get("Description").value,
      alias: this.taxForm.get("Alias").value,
      taxReliefs: [],
      taxBands: [],
      taxAccountId: this.theTaxAccount.id,
      taxAccountNumber: this.theTaxAccount.accountNumber,
    };
    this.allTaxReliefs.forEach((x) => {
      postData.taxReliefs.push({
        nextValue: x.nextValue,
        rate: x.appliedRate,
        sequence: x.sequence,
      });
    });
    this.allTaxBands.forEach((x) => {
      postData.taxBands.push({
        caption: x.caption,
        description: x.description,
        option: x.payGradeTaxBandOption,
        flatFigure: x.flatFigure,
        percentageToApply: x.percentageToApply,
        relationship: x.taxBandRelationship,
      });
    });

    this.payrollService.CreateTax(postData).subscribe(
      async () => {
        this.messageService.add({
          severity: "success",
          summary: "Completed",
          detail: "Tax Created Successfully!",
        });
        this.ResetMessageToasters();

        this.taxForm.reset();
        this.allTaxBands = [];
        this.allTaxReliefs = [];
        this.FetchAllTaxes();
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to create tax at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
        this.ResetMessageToasters();
      }
    );
  }

  EditTax(item: Tax) {
    this.editingTaxes = true;
    this.taxForm.patchValue({
      Caption: item.caption,
      Description: item.description,
      Alias: item.alias,
    });
    this.allTaxReliefs = item.taxReliefs;
    this.allTaxBands = item.taxBands;
    this.theTaxAccount = this.allAccounts.find(
      (x) => x.id == item.taxAccountId
    );
    this.taxToEdit = item;

    this.formWrapper.nativeElement.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "start",
    });
  }

  DeleteTax(item: Tax) {
    this.confirmationService.confirm({
      message: "Are you sure you want to remove deduction?",
      accept: () => {
        this.messageService.add({
          severity: "info",
          summary: "Notice",
          detail: "Removing tax...",
        });
        this.ResetMessageToasters();

        this.payrollService.DeleteTax(item.id).subscribe(
          async () => {
            this.messageService.add({
              severity: "success",
              summary: "Removed",
              detail: "Removed successfully",
            });
            this.ResetMessageToasters();

            this.fetchingTaxes = true;
            const index = this.allTaxes.indexOf(item);
            if (index > -1) {
              this.allTaxes.splice(index, 1);
            }
            this.fetchingTaxes = false;
          },
          (error) => {
            console.log("Error: " + JSON.stringify(error));
            this.messageService.add({
              severity: "error",
              summary: "Notice",
              detail:
                "Unable to remove tax at the moment.. Reason: [" + error
                  ? error.error.message
                  : "request failed - permission" + "]",
            });
            this.ResetMessageToasters();
          }
        );
      },
    });
  }

  CloseEditingTaxes() {
    this.taxForm.reset();
    this.taxToEdit = null;
    this.editingTaxes = false;
    this.allTaxBands = [];
    this.allTaxReliefs = [];
    this.isTaxBandOptionRadioButton = null;
    this.theTaxAccount = null;
    this.fetchingTaxes = true;
    this.FetchAllTaxes();
  }

  UpdateTaxes() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Updating Tax...",
    });
    this.ResetMessageToasters();

    const id = this.taxToEdit.id;
    const postData: UpdateTaxVM = {
      caption: this.taxForm.get("Caption").value,
      description: this.taxForm.get("Description").value,
      alias: this.taxForm.get("Alias").value,
      taxReliefs: [],
      taxBands: [],
      taxAccountId: this.theTaxAccount.id,
      taxAccountNumber: this.theTaxAccount.accountNumber,
    };
    this.allTaxReliefs.forEach((x) => {
      postData.taxReliefs.push({
        id: x.id ? x.id : 0,
        nextValue: x.nextValue,
        rate: x.appliedRate,
        sequence: x.sequence,
      });
    });
    this.allTaxBands.forEach((x) => {
      postData.taxBands.push({
        id: x.id ? x.id : 0,
        caption: x.caption,
        description: x.description,
        option: x.payGradeTaxBandOption,
        flatFigure: x.flatFigure,
        percentageToApply: x.percentageToApply,
        relationship: x.taxBandRelationship,
      });
    });

    this.payrollService.UpdateTax(id, postData).subscribe(
      async () => {
        this.messageService.add({
          severity: "success",
          summary: "Completed",
          detail: "Tax Updated Successfully.",
        });
        this.ResetMessageToasters();

        this.taxToEdit = null;
        this.taxForm.reset();
        this.editingTaxes = false;
        this.allTaxReliefs = [];
        this.allTaxBands = [];
        this.fetchingTaxes = true;
        this.FetchAllTaxes();
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to update tax at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
        this.ResetMessageToasters();
      }
    );
  }

  SaveTaxRelief() {
    this.allTaxReliefs.reverse();
    this.allTaxReliefs.push({
      nextValue: this.taxReliefNxtValue,
      appliedRate: this.taxReliefApplicableRate,
      sequence: this.allTaxReliefs.length + 1,
    });
    this.allTaxReliefs.reverse();
    this.taxReliefNxtValue = null;
    this.taxReliefApplicableRate = null;
  }

  RemoveTaxRelief(item: TaxRelief) {
    const index = this.allTaxReliefs.indexOf(item);
    if (index > -1) {
      if (item.sequence == this.allTaxReliefs.length) {
        this.allTaxReliefs.splice(index, 1);
      } else {
        this.allTaxReliefs.forEach((x) => {
          if (x.sequence > item.sequence) x.sequence = x.sequence - 1;
        });
        this.allTaxReliefs.splice(index, 1);
      }
    }
  }

  MoveTaxReliefSequence(option: boolean, sequence: number) {
    if (sequence == 1 && option == true) {
      this.messageService.add({
        severity: "error",
        summary: "Notice",
        detail: "Error, Already at top sequence",
      });
      this.ResetMessageToasters();
      return;
    } else if (option == false && sequence == this.allTaxBands.length) {
      this.messageService.add({
        severity: "error",
        summary: "Notice",
        detail: "Error, Already at bottom sequence",
      });
      this.ResetMessageToasters();
      return;
    }

    let item = this.allTaxReliefs[sequence - 1];
    let itemSequence = item.sequence;
    let swtItem =
      option == true
        ? this.allTaxReliefs[sequence - 2]
        : this.allTaxReliefs[sequence];
    item.sequence = swtItem.sequence;
    swtItem.sequence = itemSequence;
  }

  AddTaxBand() {
    if (this.taxBandPercApply && this.taxBandFlatFigure) {
      this.messageService.add({
        severity: "error",
        summary: "Notice",
        detail: "Apologies, You can only input one (% of gross OR Flat figure)",
      });
      this.ResetMessageToasters();
    } else if (
      this.allTaxBands.find((x) => x.caption == this.taxBandCaption) != null
    ) {
      this.messageService.add({
        severity: "error",
        summary: "Notice",
        detail:
          "Apologies, Tax Relief with the same caption has already been added",
      });
      this.ResetMessageToasters();
    } else {
      this.allTaxBands.reverse();
      this.allTaxBands.push({
        caption: this.taxBandCaption,
        description: this.taxBandDesc,
        flatFigure: this.taxBandFlatFigure,
        percentageToApply: this.taxBandPercApply,
        payGradeTaxBandOption: this.isTaxBandOptionRadioButton,
        taxBandRelationship: 1,
      });
      this.allTaxBands.reverse();
      this.isTaxBandOptionRadioButton = null;
      this.taxBandPercApply = null;
      this.taxBandCaption = null;
      this.taxBandDesc = null;
      this.taxBandFlatFigure = null;
      this.theTaxBandRelationship = null;
    }
  }

  DeleteTaxBand(item: TaxBand) {
    const index = this.allTaxBands.indexOf(item);
    if (index > -1) {
      this.allTaxBands.splice(index, 1);
    }
  }

  TaxBandRelationshipSelectionLoader(item: any) {}
}
