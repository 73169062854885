import { UserService } from "src/app/services/user.service";
import { User } from "src/app/interfaces/home";
import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { MessageService, ConfirmationService } from "primeng/api";
import { ProfileEscalationLevel } from "src/app/interfaces/profile-escalation-level";
import { ProfileEscalationLevelService } from "src/app/services/profile-escalation-level.service";
import { EscalationLevel } from "src/app/interfaces/escalation-level";
import { EscalationLevelService } from "src/app/services/escalation-level.service";

@Component({
  selector: "app-profile-escalation-level",
  templateUrl: "./profile-escalation-level.component.html",
  styleUrls: ["./profile-escalation-level.component.scss"],
  providers: [ConfirmationService, MessageService],
})
export class ProfileEscalationLevelComponent implements OnInit {
  @ViewChild("formWrapper") public formWrapper: ElementRef;
  form: FormGroup;
  editing = false;
  fetching = true;
  profileEscalationLevels: ProfileEscalationLevel[];
  selectedProfileEscalationLevel: ProfileEscalationLevel;
  users: User[];
  theUser: User;
  escalationLevels: EscalationLevel[];
  theEscalationLevel: EscalationLevel;
  cols: any[];
  private profileEscalationLevelToEdit: ProfileEscalationLevel;

  constructor(
    fb: FormBuilder,
    public messageService: MessageService,
    public profileEscalationLevelService: ProfileEscalationLevelService,
    public escalationLevelService: EscalationLevelService,
    public userService: UserService,
    public confirmationService: ConfirmationService
  ) {
    this.form = fb.group({
      EscalationLevel: ["", Validators.required],
      UserProfile: ["", Validators.required],
    });
  }

  ngOnInit(): void {
    this.cols = [
      { field: "escalationLevel", header: "EscalationLevel" },
      { field: "userProfile", header: "UserProfile" },
    ];
    this.users = [];
    this.FetchAllUsers();
    this.FetchAllEscalationLevels();
    this.FetchAllProfileEscalationLevels();
  }

  FetchAllProfileEscalationLevels() {
    this.profileEscalationLevelService.getAll().subscribe(
      async (data) => {
        this.profileEscalationLevels = data.responseData;
        this.fetching = false;
      },
      () => {
        this.fetching = false;
        this.ConnectionError();
      }
    );
  }

  FetchAllUsers() {
    this.userService.allUser().subscribe(
      async (r) => {
        var data = r.responseData ?? [];
        let i = 0;
        data.forEach((user) => {
          user.fullName = user.lastName + " " + user.firstName;
          data[i] = user;
          i++;
        });

        this.users = data;
      },
      () => {
        this.ConnectionError();
      }
    );
  }

  FetchAllEscalationLevels() {
    this.escalationLevelService.getAll().subscribe(
      async (data) => {
        this.escalationLevels = data.responseData;
      },
      () => {
        this.ConnectionError();
      }
    );
  }

  CreateProfileEscalationLevel() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Creating Profile Escalation Level...",
    });

    const postData: ProfileEscalationLevel = {
      userProfileId: this.theUser.id,
      escalationLevelId: this.theEscalationLevel.id,
    };

    this.profileEscalationLevelService.create(postData).subscribe(
      async () => {
        await this.messageService.add({
          severity: "success",
          summary: "Completed",
          detail: "New Profile Escalation Level Created...",
        });

        this.FetchAllProfileEscalationLevels();
        this.form.reset();
      },
      (error) => {
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to create profile escalation level at the moment.. Reason: [" +
            JSON.stringify(error.error.message) +
            "]",
        });
      }
    );
  }

  EditProfileEscalationLevel(item: ProfileEscalationLevel) {
    this.editing = true;
    this.form.addControl(
      "ID",
      new FormControl({ value: "", disabled: true }, Validators.required)
    );
    this.profileEscalationLevelToEdit = item;
    this.theEscalationLevel = this.escalationLevels.find(
      (data) => data.id == item.escalationLevelId
    );
    this.theUser = this.users.find((data) => data.id == item.userProfileId);
    this.formWrapper.nativeElement.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "start",
    });
  }

  GetUserProfileLastName(id: number): string {
    let userr = this.users.find((data) => data.id == id);
    return userr ? userr.lastName : "(Removed)";
  }

  GetEscalationLevelCaption(id: number): string {
    let escLevel = this.escalationLevels
      ? this.escalationLevels.find((data) => data.id == id)
      : undefined;
    return escLevel ? escLevel.caption : "(Removed)";
  }

  UpdateProfileEscalationLevel() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Updating Selected Profile Escalation Level...",
    });
    const id = this.profileEscalationLevelToEdit.id;
    const postData: ProfileEscalationLevel = {
      userProfileId: this.theUser.id,
      escalationLevelId: this.theEscalationLevel.id,
    };
    this.profileEscalationLevelService.update(id, postData).subscribe(
      async () => {
        this.messageService.add({
          severity: "success",
          summary: "Notice",
          detail: "Update Successful!",
        });
        await this.FetchAllProfileEscalationLevels();
        await this.CloseEditing();
      },
      (error) => {
        this.messageService.add({
          severity: "error",
          summary: "Unable To Update",
          detail:
            "Unable to update profile escalation level at the moment.. Reason: [" +
            JSON.stringify(error) +
            "]",
        });
      }
    );
  }

  DeleteProfileEscalationLevel(item: ProfileEscalationLevel) {
    this.confirmationService.confirm({
      message: "Are you sure you want to remove this profile escalation level",
      accept: () => {
        this.messageService.add({
          severity: "info",
          summary: "Notice",
          detail: "Removing profile escalation level ",
        });
        this.profileEscalationLevelService.delete(item.id).subscribe(
          async () => {
            await this.messageService.add({
              severity: "success",
              summary: "Removed",
              detail: "Removed successfully",
            });
            await this.FetchAllProfileEscalationLevels();
          },
          (error) => {
            this.ConnectionError();
          }
        );
      },
    });
  }

  CloseEditing() {
    this.editing = false;
    this.form.reset();
    this.profileEscalationLevelToEdit = null;
  }

  private ConnectionError() {
    this.messageService.add({
      severity: "error",
      summary: "Failed",
      detail: "Connection Error, Please try again",
    });
  }
}
