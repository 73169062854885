import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ConfirmationService, MessageService } from "primeng/api";
import { BreadcrumbService } from "src/app/breadcrumb.service";
import {
  Contract,
  ContractService,
  CustomerDivision,
  Invoice,
  UpdateInvoiceInfoVM,
} from "src/app/interfaces/home";
import { ContractServiceService } from "src/app/services/contract-service.service";
import { ContractsService } from "src/app/services/contracts.service";
import { CustomerDivisionService } from "src/app/services/customer-division.service";
import { InvoiceService } from "src/app/services/invoice.sevice";

@Component({
  selector: "app-invoice-update",
  templateUrl: "./invoice-update.component.html",
  styleUrls: ["./invoice-update.component.scss"],
})
export class InvoiceUpdateComponent implements OnInit {
  @ViewChild("formWrapper") public formWrapper: ElementRef;
  allClients: CustomerDivision[];
  theClient: CustomerDivision;
  showRightSide: boolean;
  allContracts: Contract[];
  theContract: Contract;
  allContractServices: ContractService[];
  theContractService: ContractService;
  selectedContractServices: ContractService[] = [];
  fetchingInvoices: boolean;
  allInvoices: Invoice[];
  invCols: any[];
  invoiceForm: FormGroup;
  invoiceInView: Invoice;

  constructor(
    private fb: FormBuilder,
    public contractServiceService: ContractServiceService,
    public contractService: ContractsService,
    public clientService: CustomerDivisionService,
    public invoiceService: InvoiceService,
    private breadcrumbService: BreadcrumbService,
    public confirmationService: ConfirmationService,
    public messageService: MessageService
  ) {
    this.invoiceForm = fb.group({
      InvoiceNumber: ["", Validators.required],
      InvoiceDescription: [""],
      InvoiceValue: ["", Validators.required],
      InvoiceDate: ["", Validators.required],
      ContractServices: [""],
    });
  }

  ngOnInit(): void {
    this.breadcrumbService.setItems([
      {
        label: "Finance",
      },
      {
        label: "Invoice Update",
        routerLink: ["/home/finance/invoice-update"],
      },
    ]);

    this.invCols = [
      { field: "invoiceNumber", header: "Invoice Number" },
      { field: "invoiceValue", header: "Invoice Value" },
    ];

    this.FetchAllClients();
  }

  async FetchAllClients() {
    this.clientService.allCustomerDivision().subscribe(
      async (data) => {
        this.allClients = data.responseData;
      },
      (error) => {
        this.messageService.add({
          severity: "error",
          summary: "Failed",
          detail: error ?? "Some errors occurred",
        });
      }
    );
  }

  OnClientChange() {
    this.showRightSide = false;
    this.theContract = null;
    this.theContractService = null;
    this.fetchingInvoices = false;
    if (this.theClient) {
      this.messageService.add({
        severity: "info",
        summary: "Notice",
        detail: "Fetch Client Contract(s)...",
      });

      this.contractService.GetClientContracts(this.theClient.id).subscribe(
        async (data) => {
          if (data.responseCode != "00") {
            this.messageService.add({
              severity: "error",
              summary: data.responseMsg,
            });
            return;
          }

          this.allContracts = data.responseData;
          this.allContracts.forEach((x) => {
            x.description =
              "Contract-" + x.id + " " + (x.description ? x.description : "");
          });
        },
        (error) => {
          console.log("Error: " + JSON.stringify(error));
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail:
              "Unable to get all client contract(s) at the moment.. Reason: [" +
              (error ? error.error.message : "request failed - permission") +
              "]",
          });
        }
      );
    }
  }

  OnContractChange() {
    this.showRightSide = false;
    this.theContractService = null;
    this.fetchingInvoices = false;
    if (this.theContract) {
      this.messageService.add({
        severity: "info",
        summary: "Notice",
        detail: "Fetch Contract Service(s)...",
      });

      this.contractServiceService
        .GetAllContractServciesByContractId(this.theContract.id)
        .subscribe(
          async (data) => {
            if (data.responseCode != "00") {
              this.messageService.add({
                severity: "error",
                summary: data.responseMsg,
              });
              return;
            }

            this.allContractServices = data.responseData;
            this.allContractServices.forEach(
              (x) =>
                (x.uniqueTag =
                  x.uniqueTag +
                  " | " +
                  this.FormatDateString(new Date(x.contractStartDate)) +
                  " - " +
                  this.FormatDateString(new Date(x.contractEndDate)))
            );
          },
          (error) => {
            console.log("Error: " + JSON.stringify(error));
            this.messageService.add({
              severity: "error",
              summary: "Notice",
              detail:
                "Unable to get all contract service(s) at the moment.. Reason: [" +
                (error ? error.error.message : "request failed - permission") +
                "]",
            });
          }
        );
    }
  }

  OnContractServiceChange() {
    this.showRightSide = false;
    this.fetchingInvoices = false;
    if (this.theContractService) {
      this.messageService.add({
        severity: "info",
        summary: "Notice",
        detail: "Fetch Contract Service Invoice(s)...",
      });
      this.fetchingInvoices = true;

      this.invoiceService
        .GetAllInvoicesTiedToContractService(this.theContractService.id)
        .subscribe(
          async (data) => {
            if (data.responseCode != "00") {
              this.messageService.add({
                severity: "error",
                summary: data.responseMsg,
              });
              return;
            }

            this.allInvoices = data.responseData;
            this.fetchingInvoices = false;
          },
          (error) => {
            console.log("Error: " + JSON.stringify(error));
            this.messageService.add({
              severity: "error",
              summary: "Notice",
              detail:
                "Unable to get all contract service invoice(s) at the moment.. Reason: [" +
                (error ? error.error.message : "request failed - permission") +
                "]",
            });
            this.fetchingInvoices = false;
          }
        );
    }
  }

  FormatDateString(date: Date): string {
    let dateString = date.toString();

    try {
      // DateTimeFormatOptions
      const date: Date = new Date(dateString);
      const options: Intl.DateTimeFormatOptions = {
        year: "numeric",
        month: "long",
        day: "numeric",
      };
      const formattedDate: string = new Intl.DateTimeFormat(
        "en-US",
        options
      ).format(date);
      dateString = formattedDate;
    } catch (error) {
      console.log(error);
      console.log(date);
      console.log(
        "Error while converting date string " + date + " exception " + error
      );
    }

    return dateString;
  }

  ShowDetails(item: Invoice) {
    this.invoiceForm.patchValue({
      InvoiceNumber: item.invoiceNumber,
      InvoiceDescription: item.description,
      InvoiceValue: "N" + item.value.toLocaleString(),
      InvoiceDate: this.FormatDateString(item.startDate),
    });

    this.selectedContractServices = [];
    this.invoiceInView = item;
    this.showRightSide = true;
  }

  UpdateInvoice() {
    this.confirmationService.confirm({
      message:
        "This action will update all invoices with same invoice number. Do you still wish to proceed?",
      accept: () => {
        this.messageService.add({
          severity: "info",
          summary: "Notice",
          detail: "Updating Invoice...",
        });

        const postData: UpdateInvoiceInfoVM = {
          invoiceNumber: this.invoiceForm.get("InvoiceNumber").value,
          contractServicesIds: this.selectedContractServices.map((x) => x.id),
        };
        let invDesc =  this.invoiceForm.get("InvoiceNumber").value;
        if(invDesc)
          postData.invoiceDescription = invDesc;
        this.invoiceService
          .UpdateInvoiceInfo(this.invoiceInView.id, postData)
          .subscribe(
            async (data) => {
              if (data.responseCode != "00") {
                this.messageService.add({
                  severity: "error",
                  summary: data.responseMsg,
                });
                return;
              }

              this.messageService.add({
                severity: "success",
                summary: "Removed",
                detail: "Invoice Updated Successfully",
              });
              this.invoiceInView = null;
              this.showRightSide = false;
              this.allInvoices = [];
              this.theContractService = null;
            },
            (error) => {
              console.log("Error: " + JSON.stringify(error));
              this.messageService.add({
                severity: "error",
                summary: "Notice",
                detail:
                  "Unable to update invoice at the moment.. Reason: [" +
                  (error
                    ? error.error.message
                    : "request failed - permission") +
                  "]",
              });
            }
          );
      },
    });
  }
}
