import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {BreadcrumbService} from '../../../../breadcrumb.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ConfirmationService, MessageService} from 'primeng/api';
import {CommanderRankService} from '../../../../services/armada/commander-rank.service';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {CommanderRank, CommanderType} from '../../../../interfaces/armada';
import { CommanderTypeService } from 'src/app/services/armada/commander-type.service';

@Component({
  selector: 'app-commander-rank',
  templateUrl: './commander-rank.component.html',
  styleUrls: ['./commander-rank.component.scss'],
  providers: [ConfirmationService, MessageService]
})
export class CommanderRankComponent implements OnInit, OnDestroy {
  public commanderRankForm: FormGroup;
  @ViewChild('formWrapper') public formWrapper:ElementRef;
  private unsubscriber$ = new Subject<void>();
  public loading: boolean;
  public submitting: boolean;
  selectedCommanderRank: CommanderRank | null;
  targetCols: any;
  public exportTargetColumns: any;
  public commanderRanks: CommanderRank[];
  public submitButtonLabel: string;
  public editingCommanderRank: boolean;
  private edCommanderRank: CommanderRank;
  public submittingData: boolean;
  public commanderTypes: CommanderType[];
  selectedCommanderType: CommanderType;

  constructor(
      private breadcrumbService: BreadcrumbService,
      private formBuilder: FormBuilder,
      private commanderRankService: CommanderRankService,
      private commanderTypeService: CommanderTypeService,
      public messageService: MessageService,
      public confirmationService: ConfirmationService,
  ) {
    this.breadcrumbService.setItems([
      { label: 'Armada Setup' },
      { label: 'Commander Rank', routerLink: ['/setup/commander-rank'] }
    ]);
  }

  ngOnInit(): void {
    this.loading = false;
    this.submitButtonLabel = 'create';
    this.targetCols = [
      { field: 'rankName', header: 'Rank Name' },
      { field: 'alias', header: 'Alias' },
      { field: 'description', header: 'Description' },
      { field: 'commanderType', header: 'Commander Type' },
     
    ];
    this.exportTargetColumns = this.targetCols.map(col => ({title: col.header, dataKey: col.field}));
    this.fetchCommanderRank();
    this.fetchCommanderType();
    this.commanderRankForm = this.formBuilder.group({
      rankName: ['', [Validators.required, Validators.minLength(5)]],
      alias: ['', [Validators.required, Validators.minLength(5)]],
      description: ['', [Validators.required, Validators.minLength(5)]],
      commanderType: ['', [Validators.required, Validators.minLength(5)]],
      // preceedingRankId: [{value: '', disabled:true}],
      // nextRankId: [{value: '', disabled:true}],
      // sequence: [{value: '', disabled:true}],
    });
  }
  
  fetchCommanderType() {
    this.commanderTypeService.allCommanderType()
        .pipe(takeUntil(this.unsubscriber$))
        .subscribe( (result: any) => {
          this.commanderTypes = result;
        }, error => this.connectionError());
  }

  fetchCommanderRank() {
    this.loading = true;
    this.commanderRankService.allCommanderRank().pipe(takeUntil(this.unsubscriber$))
        .subscribe( (res: any) => {
      this.commanderRanks = res;
      this.loading = false;
    }, error => {
      this.connectionError();
      this.loading = false;
    })
  }
  createCommanderRank() {
    this.submitting  = true;
    const formData = this.commanderRankForm.value;
    const data = {
      rankName: formData.rankName,
      alias: formData.alias,
      description: formData.description,
      //commanderType: this.selectedCommanderRank.commanderType,
    }
    this.commanderRankService.postCommanderRank(this.commanderRankForm.value).pipe(takeUntil(this.unsubscriber$))
        .subscribe( (res: any) => {
          this.submitting = false;
          this.fetchCommanderRank();
          this.closeEditing();
          this.messageService.add({
            summary: 'Successful',
            detail: 'Commander Rank Created',
            severity: 'success'
          });
        }, error => {
          this.connectionError();
          this.submitting = false;
        })
  }
  deleteCommanderRank(commanderRanks: CommanderRank): void {
    this.confirmationService.confirm({
      message: 'Are you sure you want to delete ' + commanderRanks.rankName,
      accept: () => {
        this._deleteCommanderRank(commanderRanks);
      }
    });
  }
  editCommanderRank(commanderRanks: CommanderRank): void{
    this.formWrapper.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'start' });
    this.commanderRankForm.setValue({
      rankName: commanderRanks.rankName,
      alias: commanderRanks.alias,
      description: commanderRanks.description,
      commanderType: commanderRanks.commanderType,
      
    });
    //this.selectedCommanderType = this.commanderTypes.find( commandType => commandType.typeName === commanderRanks.commanderType);
    this.edCommanderRank = commanderRanks;
    this.editingCommanderRank = true;
    this.submitButtonLabel = 'Update';
  }
  updateCommanderRank(): boolean {
    this.submittingData = true;
    if(!this.edCommanderRank) {
      return false;
    }
    const formData = this.commanderRankForm.value;
    const data = {
      rankName: formData.rankName,
      alias: formData.alias,
      description: formData.description,
      commanderType: formData.commanderType,
    }
    this.commanderRankService.updateCommanderRank(this.edCommanderRank.id, data)
        .pipe(takeUntil(this.unsubscriber$))
        .subscribe(() => {
          this.messageService.add({
            severity: 'success',
            detail: 'Commander Rank Updated',
            summary: 'Completed'
          });
          this.submittingData = false;
          this.closeEditing();
          this.fetchCommanderRank();
        }, error => {
          this.connectionError();
          this.submittingData = false;
        })
  }
  _deleteCommanderRank(commanderRanks) {
    this.messageService.add({severity:'info', summary: 'Notice',
      detail:'Deleting commander Rank Type record'});
    this.commanderRankService.delete(commanderRanks.id).subscribe( async (result:any) => {
      await this.messageService.add({severity:'success', summary: 'Deleted',
        detail:'commander Rank  record removed'});
      await this.fetchCommanderRank();
    }, error => {
      this.connectionError();
    });
  }
  closeEditing() {
    this.editingCommanderRank = false;
    this.commanderRankForm.reset();
    this.edCommanderRank = null;
    this.submitButtonLabel = 'create';
  }
  private connectionError() {
    this.messageService.add({severity:'error', summary: 'Failed',
      detail:'Connection Error, Please try again'});
  }
  ngOnDestroy(): void {
    this.unsubscriber$.complete();
    this.unsubscriber$.unsubscribe();
  }
}
