import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { ArmedEscortType } from "../../interfaces/armada";
import { CommonResponse } from "src/app/interfaces/home";

@Injectable({
  providedIn: "root",
})
export class ArmedEscortTypeService {
  // baseUrl = environment.armadaBaseUrl+'ArmedEscortType/';
  baseUrl = environment.baseUrl + "ArmedEscort/";
  constructor(private httpClient: HttpClient) {}
  allArmedEscortType(): Observable<CommonResponse> {
    return this.httpClient.get<CommonResponse>(
      this.baseUrl + "GetAllArmedEscortTypes"
    );
    //return this.http.get<Approval[]>(this.baseUrl + 'GetUserPendingApprovals');
  }
  getArmedEscortType(id: any): Observable<CommonResponse> {
    return this.httpClient.get<CommonResponse>(
      this.baseUrl + "GetTypeById/" + id
    );
  }
  getArmedEscortTypeByName(name: any): Observable<CommonResponse> {
    return this.httpClient.get<CommonResponse>(this.baseUrl + "name/" + name);
  }
  postArmedEscortType(data: any) {
    return this.httpClient.post(this.baseUrl + "AddNewType", data);
  }
  delete(id: any) {
    return this.httpClient.delete(this.baseUrl + "DeleteTypeById/" + id);
  }
  updateArmedEscortType(id: any, data: any) {
    return this.httpClient.put(this.baseUrl + "UpdateTypeById/" + id, data);
  }
}
