import { Component, OnInit } from "@angular/core";
import { DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";
import {
  Branch,
  CData,
  Constant,
  GroupQuoteCategory,
  QuoteServices,
  RequiredServiceQualElement,
  ServicePricing,
  ServiceRelationshipEnum,
  Services,
} from "../../../../interfaces/home";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import * as EventEmitter from "events";
import { ServicesService } from "../../../../services/services.service";
import { ServicePricingService } from "src/app/services/service-pricing.service";
import { forkJoin, interval } from "rxjs";
import { BranchService } from "src/app/services/branch.service";
import { QuoteServicesService } from "src/app/services/quote-services.service";
import { Calendar } from "primeng/calendar";
import { ThrowStmt } from "@angular/compiler";
import { Currency, CurrencyExchangeRate } from "src/app/interfaces/currency";
import { CurrencyService } from "src/app/services/currency.service";
@Component({
  selector: "app-service-qualify",
  templateUrl: "./service-qualify.component.html",
  styleUrls: ["./service-qualify.component.scss"],
  providers: [Constant],
})
export class ServiceQualifyComponent implements OnInit {
  isReadOnly: boolean = false;
  branches: any[];
  matchingDirectToPair: QuoteServices | null;
  selectedBranch: Branch = null;
  serviceQualificationForm: FormGroup;
  isGroupedInvoice: boolean;
  paymentCycles: CData[];
  invoicingIntervals: CData[];
  selectedService: Services | null;
  serviceRequiredFields: RequiredServiceQualElement[];
  selectedPaymentCycle: any;
  selectedInvoicingInterval: any;
  private selectedQuoteServiceToEdit: QuoteServices | null;
  private selectedQuoteServicesList: QuoteServices[] = []; //this excludes the one being edited
  private allSelectedQuoteServicesList: QuoteServices[] = []; //this has all of it

  today: Date;
  minStartDate: Date;
  maxStartDate: Date;
  maxEndDate: Date;
  minEndDate: Date;
  minFulfillmentStartDate: Date;
  minFulfillmentEndDate: Date;
  maxFulfillmentStartDate: Date;
  maxFulfillmentEndDate: Date;
  minActivationDate: Date;
  maxActivationDate: Date;
  minInvoiceDate: Date;
  maxInvoiceDate: Date;
  loadingPage = true;
  serviceIsVatable = false;
  servicePricings: ServicePricing[] = [];
  isSubmitted: boolean = false;
  priceRangeError: string;
  isNewQuoteService: boolean = true;
  addOrUpdateLabel: string;
  updateNoticeForDirectService: string;
  originalUniqueTag: string;
  uniqueTagError: string;
  mustSelectPossibleStartDate: boolean = false;
  possibleStartDates: any[] = [];
  quoteCategoryLevel: GroupQuoteCategory;
  firstRecordAsSample: QuoteServices;
  contractStartConventionError: string;
  firstInvoiceDateConventionError: string;
  isOneOffCycle: boolean = false;
  isNotRecurringInvoicing: boolean = true;
  allCurrencies: Currency[];
  theBaseCurrency: Currency;
  theQuoteCurrency: Currency;
  allExchangeRates: CurrencyExchangeRate[];
  theExchangeRate: CurrencyExchangeRate;
  exchangeValue: number;

  firstSendDateRangeError: string;
  constructor(
    public config: DynamicDialogConfig,
    private currencyService: CurrencyService,
    private formBuilder: FormBuilder,
    public qualifyModalRef: DynamicDialogRef,
    public constant: Constant,
    private servicePricingService: ServicePricingService,
    private quoteServicesService: QuoteServicesService
  ) {
    this.today = new Date();
  }
  async ngOnInit() {
    this.paymentCycles = this.constant.paymentCycles;
    this.invoicingIntervals = this.constant.invoicingIntervals;
    this.minFulfillmentStartDate = this.today;

    let _service;

    this.isGroupedInvoice = this.config.data.groupInvoices ?? false;
    _service = this.config.data.services;
    this.matchingDirectToPair = this.config.data.matchingDirect;
    this.selectedQuoteServicesList = this.config.data.quoteServiceList;
    this.allSelectedQuoteServicesList = this.config.data.allQuoteServiceList;
    this.branches = this.config.data.branches;
    this.selectedQuoteServiceToEdit = this.config.data.quoteService;
    this.isNewQuoteService = this.config.data.isNewQuoteService;
    this.mustSelectPossibleStartDate = this.config.data.startDates
      ? true
      : false;
    this.possibleStartDates = this.config.data.startDates;
    this.addOrUpdateLabel = this.isNewQuoteService
      ? "Add Service"
      : "Update Service";
    this.quoteCategoryLevel =
      this.config.data.quoteCategoryLevel ??
      GroupQuoteCategory.IndividualQoutes;
    this.firstRecordAsSample = this.config.data.firstRecordAsSample;
    this.isNotRecurringInvoicing = this.config.data.isNotRecurringInvoicing;

    this.FetchAllCurrencies();
    this.FetchAllExchangeRates();
    this.setupForm(_service);
    this.setService(_service);
  }

  setupForm(service: Services) {
    this.serviceQualificationForm = this.formBuilder.group({
      id: [""],
      serviceId: [""],
      tempId: [""],
      serviceRelationshipEnum: [""],
      quoteCurrency: [Validators.required],
      quoteValue: [Validators.required],
      exchangeRateUsed: [""],
      exchangeRate: [""],
      quoteCurrencyId: [""],
      exchangeRateId: [""],
      unitPrice: [Validators.required],
      quantity: ["", Validators.required],
      discount: [0, Validators.required],
      vat: [0, Validators.required],
      billableAmount: ["", Validators.required],
      budget: ["", Validators.required],
      uniqueTag: ["", [Validators.required, Validators.minLength(4)]],
      adminDirectTie: [""],
      branchId: [""],
      officeId: ["", Validators.required],
      fulfillmentStartDate: [
        this.today,
        this.isGroupedInvoice ? !Validators.required : Validators.required,
      ],
      fulfillmentEndDate: [
        "",
        this.isGroupedInvoice ? !Validators.required : Validators.required,
      ],
      contractStartDate: [
        "",
        !this.isGroupedInvoice || this.mustSelectPossibleStartDate
          ? Validators.required
          : !Validators.required,
      ],
      contractEndDate: [
        "",
        this.isGroupedInvoice ? !Validators.required : Validators.required,
      ],
      activationDate: [
        "",
        this.isGroupedInvoice ? !Validators.required : Validators.required,
      ],
      paymentCycle: [
        "",
        this.isGroupedInvoice ? !Validators.required : Validators.required,
      ],
      invoicingInterval: [
        "",
        this.isGroupedInvoice ? !Validators.required : Validators.required,
      ],
      firstInvoiceSendDate: [
        "",
        this.isGroupedInvoice ? !Validators.required : Validators.required,
      ],
      whtRate: [""],
      whtLoadingValue: [],
    });

    if (this.isNewQuoteService) {
      this.serviceQualificationForm.patchValue({
        unitPrice: service.unitPrice,
      });
    }

    if (!this.isGroupedInvoice) {
      this.serviceQualificationForm.addControl(
        "fulfillmentStartDate",
        new FormControl("", Validators.required)
      );
      this.serviceQualificationForm.addControl(
        "fulfillmentEndDate",
        new FormControl("", Validators.required)
      );
      this.serviceQualificationForm.addControl(
        "contractStartDate",
        new FormControl("", Validators.required)
      );
      this.serviceQualificationForm.addControl(
        "contractEndDate",
        new FormControl("", Validators.required)
      );
      this.serviceQualificationForm.addControl(
        "activationDate",
        new FormControl("", Validators.required)
      );
      this.serviceQualificationForm.addControl(
        "paymentCycle",
        new FormControl("", Validators.required)
      );
      this.serviceQualificationForm.addControl(
        "invoicingInterval",
        new FormControl("", Validators.required)
      );
      this.serviceQualificationForm.addControl(
        "firstInvoiceSendDate",
        new FormControl("", Validators.required)
      );
    }

    this.verifyDate();
  }

  setService(service: Services) {
    this.selectedService = service;
    this.serviceIsVatable = service.isVatable;

    if (this.isNewQuoteService) {
      this.serviceQualificationForm.patchValue({
        serviceRelationshipEnum: this.selectedService.serviceRelationshipEnum,
      });
    }

    if (this.matchingDirectToPair) {
      this.isReadOnly = true;
      this.prefillForm(this.matchingDirectToPair);
    }

    this.serviceRequiredFields = this.selectedService?.requiredServiceFields;
    if (this.serviceRequiredFields?.length > 0) {
      this.serviceRequiredFields.forEach((value) => {
        this.serviceQualificationForm.addControl(
          value.caption,
          new FormControl("")
        );
      });
    }

    if (this.selectedQuoteServiceToEdit) {
      // loop through the data inside the Quote serve object
      this.prefillForm(this.selectedQuoteServiceToEdit);
    }

    this.loadingPage = false;
  }

  private prefillForm(quoteService: QuoteServices) {
    if (quoteService.serviceRelationshipEnum == ServiceRelationshipEnum.Admin) {
      this.isReadOnly = true;
    }

    if (
      this.selectedQuoteServiceToEdit &&
      quoteService.serviceRelationshipEnum == ServiceRelationshipEnum.Direct
    ) {
      this.updateNoticeForDirectService =
        "Some changes here automatically be applied to the corresponding Admin Service";
    }

    // loop through the data inside the Quote serve object
    this.serviceQualificationForm.patchValue({
      id: quoteService.id,
      adminDirectTie: quoteService.adminDirectTie,
      serviceId: quoteService.serviceId,
      serviceRelationshipEnum: this.selectedService.serviceRelationshipEnum,
      quantity: quoteService.quantity,
      paymentCycle: this.paymentCycles.find(
        (x) => x.id == quoteService.paymentCycle
      ),
      invoicingInterval: this.invoicingIntervals.find(
        (x) => x.id == quoteService.invoicingInterval
      ),
      branchId: this.branches.find((x) => x.branchId == quoteService.branchId),
      officeId: this.branches.find((x) => x.officeId == quoteService.officeId),
      tempId: quoteService.tempId,
    });

    if (this.mustSelectPossibleStartDate && this.possibleStartDates) {
      var startdate = this.possibleStartDates.find((x) =>
        x.startDate.includes(quoteService.contractStartDate)
      );
      this.serviceQualificationForm.patchValue({
        contractStartDate: startdate,
      });
    }

    if (this.selectedQuoteServiceToEdit) {
      this.serviceQualificationForm.patchValue({
        uniqueTag: quoteService.uniqueTag,
        unitPrice: quoteService.unitPrice,
        discount: quoteService.discount,
        vat: quoteService.vat,
        billableAmount: quoteService.billableAmount,
        budget: quoteService.budget,
      });
    }

    if (this.matchingDirectToPair) {
      this.serviceQualificationForm.patchValue({
        unitPrice: this.selectedService.unitPrice,
        vat: this.selectedService.vat,
      });

      this.calculateBillableVat();
    }

    if (
      (this.matchingDirectToPair || this.selectedQuoteServiceToEdit) &&
      !this.isGroupedInvoice
    ) {
      this.serviceQualificationForm.patchValue({
        fulfillmentStartDate: new Date(quoteService.fulfillmentStartDate),
        fulfillmentEndDate: new Date(quoteService.fulfillmentEndDate),
        contractStartDate: new Date(quoteService.contractStartDate),
        contractEndDate: new Date(quoteService.contractEndDate),
        activationDate: new Date(quoteService.activationDate),
        firstInvoiceSendDate: new Date(quoteService.firstInvoiceSendDate),
      });
    }

    //set the original unique tag
    this.originalUniqueTag = quoteService.uniqueTag;

    //this.verifyDate();

    //set mininum and maximum for price range
    this.setMinAndMaxPrice(quoteService.branchId, this.selectedService.id);
  }

  async FetchAllCurrencies() {
    this.currencyService.GetAllCurrencies().subscribe(
      async (data) => {
        this.allCurrencies = data;
        this.theBaseCurrency = this.allCurrencies.find(
          (x) =>
            x.name.toLowerCase() == "nigerian naira" && x.isDeleted == false
        );
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
      }
    );
  }

  async FetchAllExchangeRates() {
    this.currencyService.GetAllCurrencyExchangeRates().subscribe(
      async (data) => {
        this.allExchangeRates = data;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
      }
    );
  }

  checkInvoicingCycle() {
    const serviceValue = this.serviceQualificationForm.value;
    var startDate = serviceValue.contractStartDate;

    if (!startDate) {
      return;
    }

    const date = new Date(startDate);
    var id =
      this.serviceQualificationForm.controls.invoicingInterval?.value?.id;
    if (id == 5) {
      this.isOneOffCycle = true;
      this.serviceQualificationForm.patchValue({
        activationDate: date,
        contractEndDate: date,
        firstInvoiceSendDate: date,
      });
    } else {
      this.isOneOffCycle = false;
    }
  }

  closeQualifyService() {
    this.qualifyModalRef.close();
  }

  checkUniqueTag(event) {
    var tag = this.serviceQualificationForm.controls.uniqueTag?.value;
    //first check if it exist among the selected services for quote
    var isFoundInSelected: boolean = false;

    if (tag && tag?.length > 3) {
      if (tag == this.originalUniqueTag) return;

      for (var quote of this.selectedQuoteServicesList) {
        if (quote?.uniqueTag == tag) {
          this.flagNotUniqueError(tag);
          isFoundInSelected = true;
          break;
        }
      }

      if (!isFoundInSelected) {
        this.quoteServicesService.getQuoteServicesByTag(tag).subscribe(
          async (res) => {
            if (res.responseCode == "00") {
              //does not exist
              this.serviceQualificationForm.get("uniqueTag").setErrors(null);
              this.flagNotUniqueError(tag);
            }
          },
          async (error: any) => {}
        );
      }
    }
  }

  private flagNotUniqueError(tag: string) {
    //value exists, indicate to user
    this.uniqueTagError = `The identifier '${tag}' has been taken`;
    this.serviceQualificationForm
      .get("uniqueTag")
      .setErrors({ "not-unique": true });
  }

  private rangeDate(intervalId: number) {
    switch (intervalId) {
      case 0:
        return 30;
      case 1:
        return 60;
      case 2:
        return 90;
      case 3:
        return 365;
      case 4:
        return 180;
      default:
        return 30;
    }
  }

  private validateInvoiceSendingDateWithinInterval(intervalId: number) {
    //get dates
    var days = this.rangeDate(intervalId);
    //from date contract start date
    const serviceValue = this.serviceQualificationForm.value;
    const fromDate = new Date(serviceValue.contractStartDate);
    const invoiceDate = new Date(serviceValue.firstInvoiceSendDate);
    const toDate = new Date(fromDate);
    toDate.setDate(fromDate.getDate() + days);

    let isTrue =
      invoiceDate.getTime() <= toDate.getTime() &&
      invoiceDate.getTime() >= fromDate.getTime();
    return isTrue;
  }

  private checkContracStartDateConvention(): [boolean, string] {
    var firstRecord =
      this.firstRecordAsSample ?? this.allSelectedQuoteServicesList[0];

    if (
      this.serviceQualificationForm.value?.serviceRelationshipEnum ==
        ServiceRelationshipEnum.Admin ||
      firstRecord.tempId == this.serviceQualificationForm.value?.tempId
    ) {
      return [true, null];
    }
    const frConstractStart = new Date(firstRecord.contractStartDate);
    const contractStart = new Date(
      this.serviceQualificationForm.value?.contractStartDate
    );

    var date = frConstractStart?.getDate();

    if (date == contractStart?.getDate()) return [true, null];
    else
      return [
        false,
        `This contract start date does not align with ${this.ordinalSuffix(
          date
        )} on the first service`,
      ];
  }

  private checkInvoiceSendDateConvention(): [boolean, string] {
    var firstRecord =
      this.firstRecordAsSample ?? this.allSelectedQuoteServicesList[0];

    if (
      this.serviceQualificationForm.value?.serviceRelationshipEnum ==
        ServiceRelationshipEnum.Admin ||
      firstRecord?.tempId == this.serviceQualificationForm.value?.tempId
    ) {
      return [true, null];
    }

    const frInvoiceSend = new Date(firstRecord.firstInvoiceSendDate);
    const invoiceSendDate = new Date(
      this.serviceQualificationForm.value?.firstInvoiceSendDate
    );
    var date = frInvoiceSend?.getDate();
    if (date == invoiceSendDate?.getDate()) return [true, null];
    else
      return [
        false,
        `This invoice send date does not align with ${this.ordinalSuffix(
          date
        )} on the first service`,
      ];
  }

  private ordinalSuffix(i) {
    var j = i % 10,
      k = i % 100;
    if (j == 1 && k != 11) {
      return i + "st";
    }
    if (j == 2 && k != 12) {
      return i + "nd";
    }
    if (j == 3 && k != 13) {
      return i + "rd";
    }
    return i + "th";
  }

  private isStartDateAndSendDateSimilar(): boolean {
    const serviceValue = this.serviceQualificationForm.value;
    const contractStart = new Date(serviceValue?.contractStartDate);
    const invoiceSendDate = new Date(serviceValue?.firstInvoiceSendDate);
    return contractStart?.getDate() == invoiceSendDate?.getDate();
  }

  addServices() {
    this.isSubmitted = true;

    if (!this.isGroupedInvoice) {
      var interval = this.serviceQualificationForm.value?.invoicingInterval;
      if (this.validateInvoiceSendingDateWithinInterval(interval?.id)) {
        this.serviceQualificationForm
          .get("firstInvoiceSendDate")
          .setErrors(null);
      } else {
        this.serviceQualificationForm
          .get("firstInvoiceSendDate")
          .setErrors({ "send-date-range": true });
        this.firstSendDateRangeError = `First invoice sending date is not within initial ${interval?.value?.toLowerCase()} of contract`;
      }

      if (
        this.quoteCategoryLevel ==
        GroupQuoteCategory.GroupQuoteWithIndividualDetails
      ) {
        // if(this.isStartDateAndSendDateSimilar())
        // {
        //   this.serviceQualificationForm.get('firstInvoiceSendDate').setErrors(null);
        // }
        // else
        // {
        //   this.serviceQualificationForm.get('firstInvoiceSendDate').setErrors({"start-and-first-date-error":true});
        // }

        //check that the invoice send date and contract start date align
        if (
          this.firstRecordAsSample ||
          this.allSelectedQuoteServicesList?.length > 0
        ) {
          //do the check on this
          var [constractStartAlign, constartStartMsg] =
            this.checkContracStartDateConvention();
          if (constractStartAlign) {
            this.serviceQualificationForm
              .get("contractStartDate")
              .setErrors(null);
          } else {
            this.serviceQualificationForm
              .get("contractStartDate")
              .setErrors({ "constract-start-convention-error": true });
            this.contractStartConventionError = constartStartMsg;
          }

          var [invoiceStartAlign, invoiceStartMsg] =
            this.checkInvoiceSendDateConvention();
          if (invoiceStartAlign) {
            this.serviceQualificationForm
              .get("firstInvoiceSendDate")
              .setErrors(null);
          } else {
            this.serviceQualificationForm
              .get("firstInvoiceSendDate")
              .setErrors({ "invoice-send-convention-error": true });
            this.firstInvoiceDateConventionError = invoiceStartMsg;
          }
        }
      }
    }

    if (this.serviceQualificationForm.invalid) {
      return;
    }

    //generate admindirectie if this is admin
    if (
      this.selectedService.serviceRelationshipEnum ==
      ServiceRelationshipEnum.Direct
    ) {
      if (this.isNewQuoteService) {
        this.serviceQualificationForm.patchValue({
          adminDirectTie: new Date().valueOf(),
        });
      }
    }

    this.serviceQualificationForm.patchValue({
      branchId: this.serviceQualificationForm.value?.officeId?.branchId,
      officeId: this.serviceQualificationForm.value?.officeId?.officeId,
      paymentCycle: this.serviceQualificationForm.value?.paymentCycle?.id,
      invoicingInterval:
        this.serviceQualificationForm.value?.invoicingInterval?.id,
    });

    if (this.mustSelectPossibleStartDate && this.possibleStartDates) {
      this.serviceQualificationForm.patchValue({
        contractStartDate:
          this.serviceQualificationForm.value?.contractStartDate?.startDate,
      });
    }

    //give all the admins the contract start and end dates of the
    this.qualifyModalRef.close(this.serviceQualificationForm.value);
  }

  private setMinAndMaxPrice(branchId: number, serviceId: number) {
    this.servicePricingService
      .getServicePricingsByServiceId(serviceId)
      .subscribe(async (res) => {
        if (res.responseCode == "00") {
          const servicePricings = res.responseData as ServicePricing[];
          let boundedQuantity = false;
          let min: number;
          let max: number;

          if (branchId && servicePricings.length > 0) {
            const servicePricing = servicePricings.find(
              (x) => x.branchId == branchId
            );
            if (servicePricing) {
              boundedQuantity = true;
              min = servicePricing.minimumAmount;
              max = servicePricing.maximumAmount;

              this.serviceQualificationForm
                .get("unitPrice")
                .setValidators([
                  Validators.required,
                  Validators.min(min),
                  Validators.max(max),
                ]);
              this.priceRangeError = `Unit price must be between ${min} and ${max}`;
            }
          }
        }
      });
  }

  getBranchPriceRange(event) {
    let branchDetail = event.value;
    if (branchDetail) {
      this.setMinAndMaxPrice(branchDetail?.branchId, this.selectedService?.id);
    }
  }

  calculateBillableVat() {
    const unitPrice =
      this.serviceQualificationForm.get("unitPrice").value ??
      this.selectedService.unitPrice;
    const quantity = this.serviceQualificationForm.get("quantity").value ?? 1;
    const _vatControl = this.serviceQualificationForm.get("vat");
    const _billableControl =
      this.serviceQualificationForm.get("billableAmount");
    let _discount = this.serviceQualificationForm.get("discount").value ?? 0;
    if (_discount > 100) {
      _discount = 100;
      this.serviceQualificationForm.get("discount").setValue(100);
    }
    const totalPrice = unitPrice * quantity;

    let vat: number;
    if (this.serviceIsVatable) {
      vat = parseFloat((totalPrice * 0.075).toFixed(2));
    } else {
      vat = 0;
    }

    _vatControl.setValue(vat);

    const rate = this.serviceQualificationForm.get("whtRate").value;
    const whtAmount = parseFloat(
      (unitPrice * quantity * (rate / 100)).toFixed(2)
    );
    this.serviceQualificationForm.get("whtLoadingValue").setValue(whtAmount);

    const billable =
      totalPrice - (_discount / 100) * totalPrice + vat + whtAmount;
    _billableControl.setValue(billable);
    this.serviceQualificationForm.get("budget").setValue(billable);
  }

  verifyDate() {
    const serviceValue = this.serviceQualificationForm.value;
    const fulfillmentStartDate = new Date(serviceValue.fulfillmentStartDate);
    const fulfillmentEndDate = new Date(serviceValue.fulfillmentEndDate);
    const contractStart = new Date(serviceValue.contractStartDate);
    const contractEnd = new Date(serviceValue.contractEndDate);
    const activationDate = new Date(serviceValue.activationDate);
    const invoiceDate = new Date(serviceValue.firstInvoiceSendDate);

    this.minFulfillmentStartDate = this.today;

    // this.maxFulfilmentStartDate: Date;
    this.minFulfillmentEndDate = new Date(fulfillmentStartDate);
    this.minFulfillmentEndDate.setDate(fulfillmentStartDate.getDate() + 1);
    // this.maxFulfilmentEndDate: Date;
    this.minStartDate = fulfillmentEndDate;
    // this.maxStartDate = ;
    this.minEndDate = new Date();
    this.minEndDate = contractStart;
    // this.maxEndDate= Date;
    this.minActivationDate = new Date();
    this.minActivationDate = fulfillmentEndDate;
    this.maxActivationDate = new Date();
    this.maxActivationDate = contractStart;
    this.minInvoiceDate = new Date();
    this.minInvoiceDate = contractStart;
    this.maxInvoiceDate = new Date();
    this.maxInvoiceDate = contractEnd;

    //fullfil-start-error

    if (fulfillmentStartDate) {
      if (fulfillmentEndDate < fulfillmentStartDate) {
        this.serviceQualificationForm
          .get("fulfillmentStartDate")
          .setErrors({ "fullfill-start-error": true });
      } else {
        this.serviceQualificationForm
          .get("fulfillmentStartDate")
          .setErrors(null);
      }

      if (contractStart > contractEnd) {
        this.serviceQualificationForm
          .get("contractStartDate")
          .setErrors({ "contract-start-error": true });
      } else {
        this.serviceQualificationForm.get("contractStartDate").setErrors(null);
      }
    }
  }

  OnQuoteCurrencyChange() {
    return this.OnQuoteCurrencyChangeV2();

    if (this.theQuoteCurrency) {
      if (this.theBaseCurrency) {
        this.theExchangeRate = this.allExchangeRates
          .sort((x) => x.id)
          .reverse()
          .find(
            (x) =>
              x.baseCurrencyId == this.theBaseCurrency.id &&
              x.quoteCurrencyId == this.theQuoteCurrency.id
          );
        if (this.theExchangeRate) {
          this.exchangeValue = this.theExchangeRate.exchangeRate;
        } else {
          this.exchangeValue = 1;
        }
      } else {
        this.exchangeValue = 1;
      }

      let unitPrice = +this.serviceQualificationForm.get("unitPrice").value;
      this.serviceQualificationForm.patchValue({
        quoteValue: unitPrice,
        unitPrice: (unitPrice / this.exchangeValue).toFixed(2),
        exchangeRateUsed: this.exchangeValue,
        exchangeRate: this.theExchangeRate,
        exchangeRateId: this.theExchangeRate?.id,
        quoteCurrencyId: this.theQuoteCurrency.id,
      });
    }
  }

  OnQuoteCurrencyChangeV2() {
    if (this.theQuoteCurrency) {
      if (this.theBaseCurrency) {
        this.theExchangeRate = this.allExchangeRates
          .sort((x) => x.id)
          .reverse()
          .find(
            (x) =>
              x.baseCurrencyId == this.theQuoteCurrency.id &&
              x.quoteCurrencyId == this.theBaseCurrency.id
          );
        if (this.theExchangeRate) {
          this.exchangeValue = this.theExchangeRate.exchangeRate;
        } else {
          this.exchangeValue = 1;
        }
      } else {
        this.exchangeValue = 1;
      }

      let unitPrice = +this.serviceQualificationForm.get("unitPrice").value;
      console.log(unitPrice);
      this.serviceQualificationForm.patchValue({
        quoteValue: unitPrice,
        unitPrice: (unitPrice * this.exchangeValue).toFixed(2),
        exchangeRateUsed: this.exchangeValue,
        exchangeRate: this.theExchangeRate,
        exchangeRateId: this.theExchangeRate?.id,
        quoteCurrencyId: this.theQuoteCurrency.id,
      });
    }
  }

  OnQuoteValueChanged() {
    return this.OnQuoteValueChangedV2();
    this.serviceQualificationForm.patchValue({
      unitPrice: (
        +this.serviceQualificationForm.get("quoteValue").value /
        this.exchangeValue
      ).toFixed(2),
    });
    this.calculateBillableVat();
  }

  OnQuoteValueChangedV2() {
    this.serviceQualificationForm.patchValue({
      unitPrice: (
        +this.serviceQualificationForm.get("quoteValue").value *
        this.exchangeValue
      ).toFixed(2),
    });
    this.calculateBillableVat();
  }
}
