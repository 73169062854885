import { ComplaintService } from "src/app/services/complaint.service";
import {
  ComplaintHandlingStats,
  MoveComplaintToNextStage,
} from "./../../../interfaces/complaint";
import { ComplaintHandlingService } from "./../../../services/complaint-handling.service";
import { Complaint } from "src/app/interfaces/complaint";
import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { MessageService, ConfirmationService, MenuItem } from "primeng/api";
import { BreadcrumbService } from "src/app/breadcrumb.service";
import { FileStorageService } from "src/app/services/file-storage.service";
import { CommonResponse } from "src/app/interfaces/home";

@Component({
  selector: "app-complaints-handling",
  templateUrl: "./complaints-handling.component.html",
  styleUrls: ["./complaints-handling.component.scss"],
})
export class ComplaintsHandlingComponent implements OnInit {
  @ViewChild("formWrapper") public formWrapper: ElementRef;
  stats: ComplaintHandlingStats;
  assignedComplaints: Complaint[];
  selectedAssignedComplaint: Complaint;
  workbenchComplaints: Complaint[];
  selectedWorkbenchComplaint: Complaint;
  assignedTableCols: any[];
  workbenchTableCols: any[];
  stepsItems: MenuItem[];
  activeIndexStep: number;
  openDialogue: boolean;
  complaintToDisplay: Complaint;
  findings: string;
  details: string;
  userIsYetToClickUpload: boolean;
  uploadedEvidences: any[] = [];
  complaintToLoad: Complaint;
  evidencesUrl: string[];
  showLeftGrid: boolean = true;

  constructor(
    public fileStorageService: FileStorageService,
    public complaintService: ComplaintService,
    public complaintHandlingService: ComplaintHandlingService,
    public messageService: MessageService,
    private breadcrumbService: BreadcrumbService,
    public confirmationService: ConfirmationService
  ) {}

  ngOnInit(): void {
    this.breadcrumbService.setItems([
      { label: "Complaint Management" },
      {
        label: "Complaint Handling",
        routerLink: ["home/complaint-management/complaint-handling"],
      },
    ]);

    this.assignedTableCols = [
      { field: "trackingId", header: "trackingId" },
      { field: "complainant", header: "Complainant" },
      { field: "resolutionPromise", header: "ResolutionPromise" },
    ];

    this.workbenchTableCols = [
      { field: "trackingID", header: "TrackingID" },
      { field: "complainant", header: "Complainant" },
      { field: "status", header: "Status" },
      { field: "view", header: "View" },
      { field: "pick", header: "Pick" },
    ];

    this.stepsItems = [
      {
        label: "Assessment",
      },
      {
        label: "Investigation",
      },
      {
        label: "Resolution",
      },
      {
        label: "Close",
      },
    ];

    this.stats = {
      totalComplaintsAssigned: 0,
      totalComplaintsBeingHandled: 0,
      totalComplaintsClosed: 0,
      totalComplaintsInWorkbench: 0,
    };
    this.activeIndexStep = 0;
    this.evidencesUrl = [];
    this.FetchAllComplaintHandlingInfo();
  }

  FetchAllComplaintHandlingInfo(isReloadingComplaint = false) {
    this.complaintHandlingService.getComplaintsToHandle().subscribe(
      async (data) => {
        this.stats = data.responseData;
        this.assignedComplaints = data.responseData.assignedComplaints;
        this.workbenchComplaints = data.responseData.workbenchComplaints;

        if (isReloadingComplaint) {
          this.ReloadComplaintToLoad();
        }
      },
      (error) => {
        this.ConnectionError(JSON.parse(error));
      }
    );
  }

  ShowComplaintDetails(item: Complaint) {
    this.openDialogue = true;
    this.complaintToDisplay = item;
  }

  GetComplainantName(item: Complaint) {
    return item.complainant == null
      ? "No Data"
      : item.complainant.supplierName
      ? item.complainant.supplierName
      : item.complainant.divisionName
      ? item.complainant.divisionName
      : item.complainant.lastName;
  }

  PickComplaintToHandle(item: Complaint) {
    this.confirmationService.confirm({
      message:
        "By picking this complaint, you will be the sole handler of the complaint. Do you accept?",
      accept: () => {
        this.messageService.add({
          severity: "info",
          summary: "Notice",
          detail: "Picking Complaint...",
        });

        this.complaintHandlingService.pickComplaint(item.id).subscribe(
          async () => {
            await this.messageService.add({
              severity: "success",
              summary: "Completed",
              detail: "You've successfully picked complaint",
            });

            this.FetchAllComplaintHandlingInfo();
          },
          (error) => {
            this.messageService.add({
              severity: "error",
              summary: "Failed",
              detail:
                "Unable to pick complaint | Reason: " + error.error.message,
            });
          }
        );
      },
    });
  }

  onUpload(event) {
    this.uploadedEvidences = [];
    for (const file of event.files) {
      this.uploadedEvidences.push(file);
    }

    this.messageService.add({
      severity: "success",
      summary: "Success",
      detail: "Evidence(s) Uploaded",
    });

    this.userIsYetToClickUpload = false;
  }

  onCancelUpload() {
    this.uploadedEvidences = [];
  }

  NotifyUserToClickUpload() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Kindly ensure you click upload, to upload the evidence(s).",
    });

    this.userIsYetToClickUpload = true;
  }

  LoadSelectedComplaint(item: Complaint) {
    this.complaintToLoad = item;

    if (item.isResolved) {
      this.activeIndexStep = 3;
    } else if (item.isInvestigated) {
      this.activeIndexStep = 2;
    } else if (item.isAssesed) {
      this.activeIndexStep = 1;
    } else {
      this.activeIndexStep = 0;
    }
  }

  HideDialog() {
    this.openDialogue = false;
    this.complaintToDisplay = null;
  }

  async MoveToNextStage() {
    if (this.activeIndexStep == 3) {
      this.confirmationService.confirm({
        message:
          "Your are about to close this complaint, are you sure all necessary has been satisfied and you want to go ahead closing the complaint?",
        accept: async () => {
          this.messageService.add({
            severity: "info",
            summary: "Notice",
            detail: "Closing Complaint Now...",
          });

          if (this.uploadedEvidences.length > 0) {
            this.fileStorageService.UploadMultipleFilesFromDataUrl(
              this.uploadedEvidences
            );
            this.fileStorageService.onUploadFinished.subscribe(
              async (resp: CommonResponse<string[]>) => {
                if (resp.responseCode != "00") {
                  this.messageService.add({
                    severity: "error",
                    summary: "Notice",
                    detail: resp.responseMsg,
                  });
                } else {
                  this.evidencesUrl = resp.responseData;
                  this._moveToNextStage();
                }
              },
              (error) => {
                console.log("Error while uploading files " + error);
                this.messageService.add({
                  severity: "error",
                  summary: "Notice",
                  detail: "ERR: Unable to upload image to storage",
                });
              }
            );
          } else {
            this._moveToNextStage();
          }
        },
      });
    } else {
      this.messageService.add({
        severity: "info",
        summary: "Notice",
        detail: "Moving Complaint to next stage.",
      });

      if (this.uploadedEvidences.length > 0) {
        this.fileStorageService.UploadMultipleFilesFromDataUrl(
          this.uploadedEvidences
        );
        this.fileStorageService.onUploadFinished.subscribe(
          async (resp: CommonResponse<string[]>) => {
            if (resp.responseCode != "00") {
              this.messageService.add({
                severity: "error",
                summary: "Notice",
                detail: resp.responseMsg,
              });
            } else {
              this.evidencesUrl = resp.responseData;
              this._moveToNextStage();
            }
          },
          (error) => {
            console.log("Error while uploading files " + error);
            this.messageService.add({
              severity: "error",
              summary: "Notice",
              detail: "ERR: Unable to upload image to storage",
            });
          }
        );
      } else {
        this._moveToNextStage();
      }
    }
  }

  _moveToNextStage() {
    var postData: MoveComplaintToNextStage = {
      findings: this.findings,
      details: this.details,
      complaintID: this.complaintToLoad.id,
      currentStage: this.activeIndexStep + 1,
      evidences: this.evidencesUrl,
    };

    this.complaintHandlingService.moveToNextStage(postData).subscribe(
      async () => {
        await this.messageService.add({
          severity: "success",
          summary: "Completed",
          detail: "You've successfully moved complaint to next stage.",
        });

        this.FetchAllComplaintHandlingInfo(true);
        //this.ReloadComplaintToLoad();

        this.showLeftGrid = false;
        this.uploadedEvidences = [];
        await new Promise((resolve) => setTimeout(resolve, 2000)); // 2 secs
        this.showLeftGrid = true;
      },
      (error) => {
        this.messageService.add({
          severity: "error",
          summary: "Failed",
          detail:
            "Unable to move compaint to next stage | Reason: " +
            error.error.message,
        });
      }
    );
  }

  GetCurrentStatus(item: Complaint): string {
    if (item.isResolved) {
      return "Awaiting Closure";
    } else if (item.isInvestigated) {
      return "Ongoing Resolution";
    } else if (item.isAssesed) {
      return "Ongoing Investigation";
    } else {
      return "Ongoing Assessment";
    }
  }

  GetNextStageLabel(item: Complaint): string {
    if (item.isResolved) {
      return "Close Complaint";
    } else if (item.isInvestigated) {
      return "Complete Resolution";
    } else if (item.isAssesed) {
      return "Complete Investigation";
    } else {
      return "Complete Assessment";
    }
  }

  ReloadComplaintToLoad() {
    this.complaintToLoad = this.workbenchComplaints.find(
      (data) => data.id == this.complaintToLoad.id
    );

    if (this.complaintToLoad) {
      this.findings = "";
      this.details = "";
      this.uploadedEvidences = [];
      if (this.complaintToLoad.isResolved) {
        this.activeIndexStep = 3;
      } else if (this.complaintToLoad.isInvestigated) {
        this.activeIndexStep = 2;
      } else if (this.complaintToLoad.isAssesed) {
        this.activeIndexStep = 1;
      } else {
        this.activeIndexStep = 0;
      }
    }
  }

  private ConnectionError(message?: string) {
    this.messageService.add({
      severity: "error",
      summary: "Failed",
      detail:
        "Connection Error, Please try again " + message &&
        " | Reason: " + message,
    });
  }
}
