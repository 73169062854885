import { MapsAPILoader } from '@agm/core';
import { Component, OnInit } from '@angular/core';
import { MessageService, SelectItem } from 'primeng/api';
import { ProjectDeliverable, ProjectTask, SpecialRequestWorkbenchVM } from 'src/app/interfaces/home';
import { PbmSpecialRequestWorkbenchServiceService } from './pbm-special-request-workbench-service.service';

@Component({
  selector: 'app-pbm-special-request-workbench',
  templateUrl: './pbm-special-request-workbench.component.html',
  styleUrls: ['./pbm-special-request-workbench.component.scss']
})
export class PbmSpecialRequestWorkbenchComponent implements OnInit {

  constructor(
    private workbenchService : PbmSpecialRequestWorkbenchServiceService,
     private mapsAPILoader: MapsAPILoader,
     public messageService: MessageService) { }

  ngOnInit(): void {
    
   this.GetAllSpecialRequests();
    console.log(JSON.stringify(this.specialrequests))
    this.infoWindow = new google.maps.InfoWindow();
    this.statusName = '';
  }
  
  //public messageService: MessageService;
  allRequests: SpecialRequestWorkbenchVM[] = [];
  specialrequest: SpecialRequestWorkbenchVM[];
  options: any;
  dialogVisible: any;
  selectedPosition: any;
  infoWindow: any;
  overlays: any;
  draggable: any;
  longitude: any = 0;
  latitude: any = 0;
  address: any;
  markerPosition: any;
 statuses: any;
 statusesObj: any;
 selectedStatus: any = null;  
 selectedObj: any;                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            
  quantityApproved = 0;
  amount = 0;
  discount = 0;
  discountAmount = 0;
  vat = 0;
  totalAmount = 0;
  description: any;
  requests = 2;
  requestDetails = null;
  supplierRequest: any;
  supplierSelection: any;
  supplierCols: any;

   specialrequests: SpecialRequest[] = [];
   projectTasks: ProjectTask[] = [];
   deliverables: ProjectDeliverable[] = [];
 
  selectedRequest: SpecialRequestWorkbenchVM;
  selectedProjectTask: ProjectTask;

  proceedToRequest(request:SpecialRequestWorkbenchVM){
    this.selectedRequest = null;
  // console.log(request)
   this.selectedRequest = request//this.allRequests.filter(x => x.requestId == request.requestId)[0]
   if(this.selectedRequest != null){
    this.projectTasks = this.selectedRequest.tasks
    this.deliverables = this.selectedRequest.deliverables
    this.longitude = this.selectedRequest.locationLongitude
    this.latitude = this.selectedRequest.locationLatitude
    this.quantityApproved = this.selectedRequest.quantityApproved
    this.vat = this.selectedRequest.vat
    this.amount = this.selectedRequest.amount
    this.discount = this.selectedRequest.disount
    this.totalAmount = this.selectedRequest.totalAmount
    this.description = "Invoice to " + this.selectedRequest.requestBy.toUpperCase() + " for requested service,  " + this.selectedRequest.service.toUpperCase()

    this.calculateAmountValues();
    this.calculateAmountValues2();
    
    this.options = {
      center: {lat: this.latitude, lng: this.longitude},
      zoom: 12
    };
    this.address = this.selectedRequest.location

    this.overlays = [
      new google.maps.Marker({
        position: { lat: this.latitude, lng: this.longitude },
        title: this.address,
        draggable: this.draggable
      })
    ]
  }
}

selectedStatusId: number;
setStatus() {
  if (this.selectedStatus) {
    console.log(this.selectedStatus);
    this.selectedStatusId = this.selectedStatus.id;
    
  }
}


async getDeliverableStatuses(){
  
  this.workbenchService.getTaskStatuses(this.selectedRequest.tasks[0].taskId).subscribe(
    async (data) => {
      if(data.responseCode=="00"){
        this.statusesObj = data.responseData;
        this.statuses = this.statusesObj.map(x => x.caption);
        console.log("List of statuses",data.responseData);
      }
    },
    (error) => {
      console.log("Error: " + JSON.stringify(error));
      this.messageService.add({
        severity: "error",
        summary: "Notice",
        detail:
          "Unable to fetch statuses.. Reason: [" +
          (error ? error.error.message : "request failed - permission") +
          "]",
      });
    }
  );
}

  filteredDelerables: ProjectDeliverable[];
  proceedToDeliverables(task: ProjectTask){
    this.statusName = '';
    this.filteredDelerables = null;
    console.log(task)
     this.filteredDelerables = this.deliverables.filter(x => x.taskId == task.taskId);
     console.log(this.filteredDelerables)
  }

statusName: string = '';

getstatus(){
   return this.statusName;
}

calculateAmountValues(){

if (this.discount > 100){
  this.discount = 100
}

if (this.discount < 0){
  this.discount = 0
}

 this.amount = this.quantityApproved * this.selectedRequest.serviceRate;
 console.log(this.quantityApproved)
 console.log(this.selectedRequest.serviceRate)
 console.log(this.amount)
 let discountedAmount = Number.parseFloat((this.amount - (this.amount * (this.discount / 100))).toFixed(2))
 this.discountAmount = Number.parseFloat((this.amount * (this.discount / 100)).toFixed(2));
 this.vat = Number.parseFloat( (discountedAmount * 0.075).toFixed(2));
this.totalAmount = Number.parseFloat((discountedAmount + (discountedAmount * 0.075)).toFixed(2));
}

calculateAmountValues2(){

  if (this.discount > 100){
    this.discount = 100
  }
  
  if (this.discount < 0){
    this.discount = 0
  }
  
   this.amount = this.quantityApproved * this.selectedRequest.serviceRate;
   console.log(this.quantityApproved)
   console.log(this.selectedRequest.serviceRate)
   console.log(this.amount)
   let discountedAmount = Number.parseFloat((this.amount - (this.amount * (this.discount / 100))).toFixed(2))
   this.discountAmount = Number.parseFloat((this.amount * (this.discount / 100)).toFixed(2));
   this.vat = Number.parseFloat( (discountedAmount * 0.075).toFixed(2));
  this.totalAmount = Number.parseFloat((discountedAmount + (discountedAmount * 0.075)).toFixed(2));
  }

  async updateDeliverableStatus(id){
    console.log(id)
    console.log(this.selectedStatus)
 // this.selectedObj = this.statuses.find(x => x.caption == this.selectedStatus);
   console.log(this.selectedStatus.id);

   this.workbenchService.updateDeliverableStatus(id, this.selectedStatus.id).subscribe(
    async (data) => {
      if(data.responseCode=="00"){this.messageService.add({severity:'success', summary:"deliverbale status updated"})
      console.log("result of conversion",data.responseData);
      this.GetAllSpecialRequests();
      this.statusName = this.selectedStatus.caption;
      }
    },
    (error) => {
      console.log("Error: " + JSON.stringify(error));
      this.messageService.add({
        severity: "error",
        summary: "Notice",
        detail:
          "Unable to fetch all operating entities at the moment.. Reason: [" +
          (error ? error.error.message : "request failed - permission") +
          "]",
      });
    }
  );

  }

  async GetAllSpecialRequests() {

    this.workbenchService.getAllSpecialRequests().subscribe(
      async (data) => {
        this.specialrequests = data.responseData;
        if(data.responseCode=="00"){
          this.allRequests = data.responseData;
          console.log("List of specialRequest",this.supplierRequest);
        }
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all operating entities at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  async UpdateSpecialRequest() {
   
    var updateRequest = new UpdateSpecialRequest();
    updateRequest.amount = this.amount;
    updateRequest.discount = this.discount;
    updateRequest.quantity = this.quantityApproved;
    updateRequest.vat = this.vat;
    updateRequest.requestId = this.selectedRequest.requestId
    updateRequest.totalAmount = this.totalAmount;
   
    console.log(updateRequest);
    
    this.workbenchService.updateSpecialRequest(updateRequest).subscribe(
      async (data) => {
      //  this.specialrequests = data.responseData;
        if(data.responseCode=="00"){
          this.messageService.add({severity:'success', summary:"Request details updated"})
          console.log("result of conversion",data.responseData);
          this.GetAllSpecialRequests();
        }
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to update details.. Reason: [" +
            (error ? error.error.message : "request failed") +
            "]",
        });
      }
    );
  }

  

  markers: any = [
    // {
    //   position: { lat: this.latitude, lng: this.longitude},
    //   title: 'San Francisco',
    //   icon: {
    //     url: 'https://maps.google.com/mapfiles/ms/icons/red-dot.png'
    //   }
    // },

    {
      lat: this.latitude,
      lng: this.longitude,
      label: "A",
      draggable: true
  }
  ];


  async SendInvoices(){
    let requests = this.specialrequest.map(x => x.requestId)
    console.log(requests);

    var sendInvoiceReq = new SendSpecialRequesInvoice();
    sendInvoiceReq.invoices = requests;
  console.log(sendInvoiceReq);
    this.workbenchService.sendInvoices(sendInvoiceReq).subscribe(
      async (data) => {
      //  this.specialrequests = data.responseData;
        if(data.responseCode=="00"){
          this.allRequests = data.responseData;
          this.messageService.add({severity:'success', summary:"Invoices sent successfully"})
          //console.log("result of conversion",data.responseData);
          this.specialrequest = null;
          this.GetAllSpecialRequests();
        }
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to send invoices.. Reason: [" +
            (error ? error.error.message : "request failed") +
            "]",
        });
      }
    );

  }

  specialrequestWithInvoice = null;
  specialrequestWithWithContract = null

  async ConvertRquestsToContracts(){
    var createContractReq : CreateContract = new CreateContract();
   
    console.log(this.specialrequest)
    let requests = this.specialrequest.map(x => x.requestId)
    createContractReq.requestIds = requests;
    console.log(requests)
    
    this.workbenchService.convertRequestsToContracts(createContractReq).subscribe(
      async (data) => {
      //  this.specialrequests = data.responseData;
        if(data.responseCode=="00"){
          this.allRequests = data.responseData;
          this.messageService.add({severity:'success', summary:"Contracts created for requests"})
          console.log("result of conversion",data.responseData);
          this.specialrequest = null;
          this.GetAllSpecialRequests();
        }
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to convert requests to contract.. Reason: [" +
            (error ? error.error.message : "request failed") +
            "]",
        });
      }
    );
  }

  setCordinates(request){
   console.log(this.latitude)
    console.log(this.longitude)
    this.options = {
      center: {lat: this.latitude, lng: this.longitude},
      zoom: 12
    };
  }

  handleMapClick(event) {
    this.dialogVisible = true;
    this.selectedPosition = event.latLng;
}

handleOverlayClick(event) {
    let isMarker = event.overlay.getTitle != undefined;

    if (isMarker) {
        let title = event.overlay.getTitle();
        this.infoWindow.setContent('' + title + '');
        this.infoWindow.open(event.map, event.overlay);
        event.map.setCenter(event.overlay.getPosition());

        this.messageService.add({severity:'info', summary:'Marker Selected', detail: title});
    }
    else {
        this.messageService.add({severity:'info', summary:'Shape Selected', detail: ''});
    }
}

addMarker() {
    this.overlays.push(new google.maps.Marker({position:{lat: this.latitude, lng: this.longitude}, title:this.address, draggable: this.draggable}));
}

handleDragEnd(event) {
    this.messageService.add({severity:'info', summary:'Marker Dragged', detail: event.overlay.getTitle()});
}

zoomIn(map) {
    map.setZoom(map.getZoom()+1);
}

zoomOut(map) {
    map.setZoom(map.getZoom()-1);
}

clear() {
    this.overlays = [];
}

}



export interface SpecialRequest {
  id: number;
  requestPlatform: string;
    requestBy: string;
    requestDate: string;
    locationAddress: string;
    serviceRequested: string;
    tasksGenerated: string;
    invoiceSent: boolean;
    deliverablesGenerated:string;
}

export class UpdateSpecialRequest{
  requestId : number;
  quantity : number;
  discount : number;
  amount : number;
  vat : number;
  totalAmount: number;
}

export class CreateContract{
  requestIds: any
}

export class SendSpecialRequesInvoice{
  invoices: number[]
}
