import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../environments/environment";
import { Observable } from "rxjs";
import { AccountClass, CommonResponse } from "../interfaces/home";

@Injectable({
  providedIn: "root",
})
export class AccountClassService {
  baseUrl = environment.baseUrl + "AccountClass/";

  constructor(private http: HttpClient) {}

  // fetch all branch in the database
  allAccountClasses(): Observable<CommonResponse> {
    return this.http.get<CommonResponse>(this.baseUrl);
  }

  getAccountClass(id: any): Observable<CommonResponse> {
    return this.http.get<CommonResponse>(this.baseUrl + id);
  }

  getAccountClassByName(name: any): Observable<CommonResponse> {
    return this.http.get<CommonResponse>(this.baseUrl + "name/" + name);
  }

  allAccountClassesWithBalance(): Observable<CommonResponse> {
    return this.http.get<CommonResponse>(
      this.baseUrl + "AccountClassBreakDown"
    );
  }

  postAccountClass(data: any) {
    return this.http.post(this.baseUrl, data);
  }

  deleteAccountClass(id: any) {
    return this.http.delete(this.baseUrl + id);
  }

  updateAccountClass(id: any, data: any) {
    return this.http.put(this.baseUrl + id, data);
  }

  GetAccountClassesWithControlAccounts(): Observable<CommonResponse<AccountClass[]>> {
    return this.http.get<CommonResponse<AccountClass[]>>(
      this.baseUrl + "GetAccountClassesWithControlAccounts"
    );
  }
}
