import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Subscription } from 'rxjs';
import { CustomerDivisionService } from 'src/app/services/customer-division.service';
import { StateService } from 'src/app/services/state.service';

@Component({
  selector: 'app-client-state-mapping',
  templateUrl: './client-state-mapping.component.html',
  styleUrls: ['./client-state-mapping.component.css']
})
export class ClientStateMappingComponent implements OnInit, OnDestroy {

  clientStateForm = this.fb.group({
    clients: [''],
    states: ['']
  })
  clientsList: []=[]
  statesList: []=[]

  clientSubscription: Subscription | undefined
  stateSubscription: Subscription | undefined

  constructor(private fb: FormBuilder, 
              private stateApi: StateService,
              private clientService: CustomerDivisionService,) { }

  ngOnInit(): void {
    this.stateSubscription = this.getStates();
    this.clientSubscription = this.getAllClients();
  }

  ngOnDestroy(): void {
    this.stateSubscription.unsubscribe();
    this.clientSubscription.unsubscribe();
    
  }

  getStates() {
    return this.stateApi.allState().subscribe(res => {
      this.statesList = res.responseData
      // console.log(this.statesList);
      this.statesList.sort((a,b) => {
        if (a['name'.toLowerCase()] < b['name'.toLocaleLowerCase()]) {
          return -1
        }
        if (a['name'.toLowerCase()] > b['name'.toLocaleLowerCase()]) {
          return 1
        }
        return 0
      })
      console.log(res.responseData);
      console.log(this.statesList);
    })
  }

  getAllClients() {
    return this.clientService.allCustomerDivision().subscribe( res => {
      console.log(res.responseData);
      this.clientsList = res.responseData;
      
    })
  }

  

}
