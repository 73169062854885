import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import {
  CommonResponse,
  OperatingEntity,
  StandardSLA,
} from "../../../interfaces/home";
import { StandardSlaService } from "../../../services/standard-sla.service";
import { ConfirmationService, MessageService } from "primeng/api";
import { OperatingEntityService } from "../../../services/operating-entity.service";
import { EventInput } from "@fullcalendar/core";
import { Observable } from "rxjs";
import { BreadcrumbService } from "src/app/breadcrumb.service";
import { FileStorageService } from "src/app/services/file-storage.service";

@Component({
  selector: "app-standard-sla-operating",
  templateUrl: "./standard-sla-operating.component.html",
  styleUrls: ["./standard-sla-operating.component.scss"],
  providers: [MessageService, ConfirmationService],
})
export class StandardSlaOperatingComponent implements OnInit, OnDestroy {
  @ViewChild("formWrapper") public formWrapper: ElementRef;
  private fb: any;
  public downloadURL: Observable<any>;
  documentFolder = "StandardSLADocuments";
  selectedFile: any;
  fetchingStandardSLA = true;
  standardSLAForm: FormGroup;
  standardSLAs: StandardSLA[];
  selectedStandardSLA: StandardSLA;
  editingStandardSLA = false;
  public standardSLACols: any[];
  public exportStandardSLAColumns: any[];
  private edStandardSLA: StandardSLA;
  selectedOperatingEntityId: OperatingEntity = null;
  operatingEntities: OperatingEntity[] = [];
  public isEditing: boolean;
  public uploadingNeDocument: boolean;
  selectedFileName: any;
  selectedFileUrl: any;
  submittingForm: boolean;
  constructor(
    public formBuilder: FormBuilder,
    private standardSLAService: StandardSlaService,
    public messageService: MessageService,
    public confirmationService: ConfirmationService,
    private operatingEntityService: OperatingEntityService,
    private breadcrumbService: BreadcrumbService,
    private fileStorageService: FileStorageService
  ) {
    this.submittingForm = false;
  }

  ngOnInit(): void {
    this.breadcrumbService.setItems([
      { label: "Setup", routerLink: ["home/standard-sla-for-operation"] },
      {
        label: "Standard SLA",
        routerLink: ["home/standard-sla-for-operation"],
      },
    ]);
    this.fetchOperatingEntity();
    this.fetchStandardSLA();
    this.standardSLAForm = this.formBuilder.group({
      caption: ["", [Validators.required]],
      description: ["", [Validators.required]],
      operatingEntityId: ["", Validators.required],
      documentUrl: ["", Validators.required],
    });
    this.standardSLACols = [
      { field: "caption", header: "Caption" },
      { field: "description", header: "Description" },
      { field: "operatingEntityId", header: "operating Entity" },
      { field: "documentUrl", header: "Document" },
    ];
    this.exportStandardSLAColumns = this.standardSLACols.map((col) => ({
      title: col.header,
      dataKey: col.field,
    }));
  }
  async fetchOperatingEntity() {
    await this.operatingEntityService.allData().subscribe(
      (res: CommonResponse) => {
        this.operatingEntities = res.responseData;
      },
      (error) => {
        this.connectionError();
      }
    );
  }
  fetchStandardSLA() {
    this.standardSLAService.allStandardSLAData().subscribe(
      async (standardSLA: CommonResponse) => {
        this.standardSLAs = standardSLA.responseData;
        this.fetchingStandardSLA = false;
      },
      (error) => {
        this.fetchingStandardSLA = false;
        this.connectionError();
      }
    );
  }
  private connectionError() {
    this.messageService.add({
      severity: "error",
      summary: "Failed",
      detail: "Connection Error, Please try again",
    });
  }
  async saveForm() {
    this.submittingForm = true;
    if (this.selectedFile) {
      this.fileStorageService.UploadMultipleFilesFromDataUrl([
        this.selectedFile,
      ]);
      this.fileStorageService.onUploadFinished.subscribe(
        (resp: CommonResponse<string[]>) => {
          if (resp.responseCode != "00") {
            this.messageService.add({
              severity: "error",
              summary: "Notice",
              detail: resp.responseMsg,
            });
          } else {
            let url = resp.responseData[0];
            if (url) {
              this.selectedFileUrl = url;
              // post the form data
            }
            this.standardSLAForm.patchValue({
              documentUrl: this.selectedFileUrl,
            });
            console.log(this.selectedFileUrl);
            if (!this.edStandardSLA) {
              this.createStandardSLA();
            } else {
              this.updateStandardSLA();
            }
            this.submittingForm = false;
          }
        },
        (error) => {
          console.log("Error while uploading files " + error);
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail: "ERR: Unable to upload image to storage",
          });
        }
      );
    } else {
      this.standardSLAForm.patchValue({
        documentUrl: this.edStandardSLA.documentUrl,
      });
      this.updateStandardSLA();
      this.submittingForm = false;
    }
    // return false;
  }
  async createStandardSLA() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Creating new Standard SLA Record",
    });
    const postData = {
      caption: this.standardSLAForm.get("caption").value,
      description: this.standardSLAForm.get("description").value,
      operatingEntityId: this.selectedOperatingEntityId.id,
      // documentUrl: this.selectedFileUrl,
    };
    await this.standardSLAService.postStandardSLA(postData).subscribe(
      async () => {
        await this.messageService.add({
          severity: "success",
          summary: "Completed",
          detail: "New StandardSLA Created",
        });
        await this.fetchStandardSLA();
        await this.standardSLAForm.reset();
      },
      (error) => {
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail: "Unable to create new StandardSLA at the moment",
        });
      }
    );
  }

  deleteStandardSLA(standardSLA) {
    this.confirmationService.confirm({
      message: "Are you sure you want to delete " + standardSLA.name,
      accept: () => {
        this._deleteStandardSLA(standardSLA);
      },
    });
  }
  _deleteStandardSLA(standardSLA) {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Deleting standardSLA record",
    });
    this.standardSLAService.deleteStandardSLA(standardSLA.id).subscribe(
      async (result: any) => {
        await this.messageService.add({
          severity: "success",
          summary: "Deleted",
          detail: "StandardSLA record removed",
        });
        await this.fetchStandardSLA();
      },
      (error) => {
        this.connectionError();
      }
    );
  }
  editStandardSLA(standardSLAs: StandardSLA) {
    this.editingStandardSLA = true;
    this.standardSLAForm.addControl(
      "standardSLAId",
      new FormControl({ value: "", disabled: true }, Validators.required)
    );
    this.edStandardSLA = standardSLAs;
    this.standardSLAForm.patchValue({
      caption: standardSLAs.caption,
      description: standardSLAs.description,
      operatingEntityId: standardSLAs.operatingEntityId,
      documentUrl: standardSLAs.documentUrl,
      standardSLAId: standardSLAs.id,
    });
    // this.standardSLAFormIn.nativeElement.focus();
    // document.getElementById('formFields').focus();
    // this.selectedHeadId = this.users.find(user => user.id === standardSLAs.head.id);
    this.formWrapper.nativeElement.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "start",
    });
  }

  updateStandardSLA() {
    // update the selected standardSLA
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Updating StandardSLA details",
    });
    const id = this.edStandardSLA.id;
    const postData = {
      caption: this.standardSLAForm.get("caption").value,
      description: this.standardSLAForm.get("description").value,
      operatingEntityId: this.standardSLAForm.get("operatingEntityId").value,
      // documentUrl: this.standardSLAForm.get('documentUrl').value,
    };
    this.standardSLAService
      .updateStandardSLA(id, postData)
      .subscribe(async (result: StandardSLA) => {
        this.messageService.add({
          severity: "success",
          summary: "Notice",
          detail: "Standard SLA For Operating details Updated",
        });
        await this.fetchStandardSLA();
        await this.closeEditing();
      });
  }

  closeEditing() {
    this.editingStandardSLA = false;
    this.standardSLAForm.reset();
    this.edStandardSLA = null;
  }

  uploadDocument(event: any) {
    if (!this.checkMimeType(event) || !this.checkFileSize(event)) {
      return;
    }
    this.uploadingNeDocument = true;
    const reader = new FileReader();
    const reader1 = new FileReader();
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      this.selectedFile = event.target.files[0];
      this.selectedFileName = this.selectedFile.name;
      reader.readAsDataURL(file);
      reader.onload = () => {
        // this.selectedFile = reader.result as string;
        this.standardSLAForm.patchValue({
          documentUrl: reader.result,
        });
      };
    }
  }

  checkMimeType = (event: EventInput): boolean => {
    const files = event.target.files[0];
    // list allow mime type
    const types = [
      "application/msword",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      "application/pdf",
    ];
    // loop access array
    if (!files) {
      this.messageService.add({
        severity: "error",
        summary: "Failed",
        detail: "Please select a Document to upload first",
      });
      return false;
    }
    // compare file type find doesn't match
    if (types.every((type) => files.type !== type)) {
      // create error message and assign to container
      this.messageService.add({
        severity: "error",
        summary: "Failed",
        detail: "Document format not supported",
      });
      return false;
    }
    return true;
  };

  checkFileSize = (event: EventInput) => {
    const files = event.target.files[0];
    if (!files) return true;
    const size = 1000000;
    if (files.size > size) {
      event.target.value = null;
      this.messageService.add({
        severity: "error",
        summary: "Failed",
        detail: "Selected Document is too large, please pick a smaller file",
      });
      return false;
    }
    return true;
  };

  ngOnDestroy(): void {}
}
