import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { EventService } from 'src/app/demo/service/eventservice';
import { User } from 'src/app/interfaces/home';
import { UserService } from 'src/app/services/user.service';
import { QualificationsService } from './qualifications.service';

@Component({
  selector: 'app-qualifications',
  templateUrl: './qualifications.component.html',
  styleUrls: ['./qualifications.component.scss']
})
export class QualificationsComponent implements OnInit {
  users: any[];
  selectedUser:any;
  endDateAdvanced:any;
  workspaceIsLoading=false;
  startDateAdvanced:any;
  selectedAdvancedUser:User = null;
  UnqualifiedLeads:any[]=[];
  leadsInQualification:any[]=[];
  QualifiedLeads:any[]=[];
  totalLeads: number;
  displayAdvanced=false;
  totalAmount: number;
  displayContacts=false;
  contactList:any[]=[];
  leadInfo: any;
  leadInfoName: any;
  startDate:any;
  endDate:any;
  selectedFilterOption:number;
  sortBy: { name: string; value: number; }[];
  tempUnqualifiedLeads: any[];
  tempQualifiedLeads: any[];
  tempLeadsInQualification: any[];
  tempTotalLeads: number;
  DivisionList: any[]=[];



  constructor(
    private route: ActivatedRoute,
    public dialogService: DialogService,
    public messageService: MessageService,
    private sanitizer: DomSanitizer,
    private eventService: EventService,
    private userService:UserService,
    private qualificationService: QualificationsService
  ) { 

    this.sortBy = [{
      name:"Created User" ,value: 1
    },
    {
      name:"Start date and End date",value:2
    }
  ]
   

  }

  ngOnInit(): void {
    this.getAllUsers();
    this.getQualificationData();
    this.getAllDevisions();
  
  }


  getTotalBudget(result): number {
      if (result.suspectQualifications) {
        if (result.suspectQualifications.length !== 0) {
          var finalAmmount = 0;
          result.suspectQualifications[0].serviceQualifications.forEach(
            (service) => {
              var budget = service.budget * service.estimatedDurationInMonths
              finalAmmount = finalAmmount + budget;
            }
          );
          this.totalAmount = finalAmmount;
        } 
        else {
          return;
        }
      } else {
        this.totalAmount = 0;
      }
    return this.totalAmount;
  }

  getAllUsers(){
    this.userService.allUser()
    .subscribe(async(data:any)=>{
      if(data.responseCode == "00"){
        this.users = data.responseData;
        this.users = this.users.map((res: any) => {
          return {
            ...res,
            displayLabel2: res.firstName + ' ' + res.lastName
          };
        });
      }
    })
  }

  authourizeInput(selectedUser,startDate,endDate):boolean{
    if(selectedUser == null || startDate == null && endDate == null){
      return true
    }

    else if (endDate != null && startDate != null){
      return true;
    }
    else if (startDate == null && endDate != null || startDate != null || endDate == null){
      return false;
    }

    return false;
  }

  // OnselectUser(event){
  //   this.tempUnqualifiedLeads=this.UnqualifiedLeads;
  //   this.tempQualifiedLeads = this.QualifiedLeads;
  //   this.tempLeadsInQualification = this.leadsInQualification;
  //   console.log("this is the evnt",event)
  //   this.messageService.add({
  //     severity: "info",
  //     summary: "Notice",
  //     detail: `Retrieving Qualifications created by ${event.firstName} ${event.lastName}`,
  //   });
  //   this.UnqualifiedLeads = this.tempUnqualifiedLeads.filter(function (el){return el.createdById == event.id});
  //   this.QualifiedLeads = this.tempQualifiedLeads.filter(function (el){return el.createdById == event.id});
  //   this.leadsInQualification = this.tempLeadsInQualification.filter(function (el){return el.createdById == event.id});
  // }

  filterByUser(event){
    this.startDate = null;
    this.endDate = null;
    this.UnqualifiedLeads=this.tempUnqualifiedLeads;
    this.QualifiedLeads = this.tempQualifiedLeads;
    this.leadsInQualification = this.tempLeadsInQualification;
    this.totalLeads = this.tempTotalLeads;
    console.log("this is the evnt",event)
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: `Retrieving Qualifications created by ${event.firstName} ${event.lastName}`,
    });

    this.UnqualifiedLeads = this.tempUnqualifiedLeads.filter(function (el){return el.createdById == event.id});
    this.QualifiedLeads = this.tempQualifiedLeads.filter(function (el){return el.createdById == event.id});
    this.leadsInQualification = this.tempLeadsInQualification.filter(function (el){return el.createdById == event.id});
    this.totalLeads = this.UnqualifiedLeads.length + this.QualifiedLeads.length + this.leadsInQualification.length;

  }

  getQualificationData(){
    this.workspaceIsLoading = true;
    this.qualificationService.getQualificationData()
    .subscribe(async(data:any)=>{
      if(data.responseCode == "00"){
        this.workspaceIsLoading = false;
        this.messageService.add({
          severity: "success",
          summary: "Notice",
          detail: "All Qualification data was fectched successsfully",
        });
         console.log("This is the response from backend",data.responseData);
         this.tempQualifiedLeads = data.responseData.qualifiedLeads;
         this.QualifiedLeads = data.responseData.qualifiedLeads;
         this.tempUnqualifiedLeads = data.responseData.unqualifiedLeads;
         this.UnqualifiedLeads = data.responseData.unqualifiedLeads;
         this.tempLeadsInQualification =data.responseData.leadsInQualification;
         this.leadsInQualification =data.responseData.leadsInQualification;
         this.totalLeads = this.tempQualifiedLeads.length + this.tempUnqualifiedLeads.length + this.tempLeadsInQualification.length;
         this.tempTotalLeads = this.tempQualifiedLeads.length + this.tempUnqualifiedLeads.length + this.tempLeadsInQualification.length;
      }
      else{
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail: "All Qualification data wasn't fectched successsfully",
        });
        this.workspaceIsLoading = false;
      }
    },error=>{
      this.messageService.add({
        severity: "error",
        summary: "Notice",
        detail: "All Qualification data wasn't fectched successsfully",
      });
      this.workspaceIsLoading = false;
    }
    )
  }

  resetFilter(){
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Reseting filter to original dataset",
    });
    this.UnqualifiedLeads = this.tempUnqualifiedLeads
    this.QualifiedLeads = this.tempQualifiedLeads
    this.leadsInQualification = this.tempLeadsInQualification
    this.totalLeads = this.UnqualifiedLeads.length + this.QualifiedLeads.length + this.leadsInQualification.length;
  }

  selectUser(event){
    this.selectedAdvancedUser 
  }


  searchAdvanced(){
    if(this.selectedAdvancedUser == null || this.startDateAdvanced == null || this.endDateAdvanced == null){
      this.messageService.add({
        severity: "error", 
        summary: "Notice",
        detail: `You need to select a creator and a date range to successfully search advanced`,
      });
    }else{
      console.log("this is the selected user1",this.selectedAdvancedUser)
      this.UnqualifiedLeads=this.tempUnqualifiedLeads;
      this.QualifiedLeads = this.tempQualifiedLeads;
      this.leadsInQualification = this.tempLeadsInQualification;
      this.totalLeads = this.tempTotalLeads;
      this.messageService.add({
        severity: "info", 
        summary: "Notice",
        detail: `Retrieving Qualifications created by ${this.selectedAdvancedUser.firstName} ${this.selectedAdvancedUser.lastName}`,
      });
  
      var start = new Date(this.startDateAdvanced).getTime();
      var end = new Date(this.endDateAdvanced).getTime();
      var user = this.selectedAdvancedUser
      console.log("this is user",user);
      this.UnqualifiedLeads = this.tempUnqualifiedLeads.filter(function (el){return el.createdById == user.id && new Date(el.createdAt).getTime() >= start && new Date(el.createdAt).getTime() <= end });
      this.QualifiedLeads = this.tempQualifiedLeads.filter(function (el){return el.createdById == user.id && new Date(el.createdAt).getTime() >= start && new Date(el.createdAt).getTime() <= end });
      this.leadsInQualification = this.tempLeadsInQualification.filter(function (el){return el.createdById == user.id && new Date(el.createdAt).getTime() >= start && new Date(el.createdAt).getTime() <= end });
      this.totalLeads = this.UnqualifiedLeads.length + this.QualifiedLeads.length + this.leadsInQualification.length;
    }
   
  }



  filterByDateRange(){
    if(this.startDate > this.endDate){
      this.messageService.add({
        severity: "error",
        summary: "Notice",
        detail: "Please fix your selection,start date cannot be greater than end date",
      });
    }
    else{
      this.selectedUser = null;
      this.UnqualifiedLeads=this.tempUnqualifiedLeads;
      this.QualifiedLeads = this.tempQualifiedLeads;
      this.leadsInQualification = this.tempLeadsInQualification;
      this.messageService.add({
        severity: "info",
        summary: "Notice",
        detail: `Retrieving Qualifications created created between ${this.startDate} and ${this.endDate}`,
      });
      var start = new Date(this.startDate).getTime();
      var end = new Date(this.endDate).getTime();
      this.UnqualifiedLeads = this.tempUnqualifiedLeads.filter(function (el){return new Date(el.createdAt).getTime() >= start && new Date(el.createdAt).getTime() <= end});
      this.QualifiedLeads = this.tempQualifiedLeads.filter(function (el){return new Date(el.createdAt).getTime() >= start && new Date(el.createdAt).getTime() <= end});
      this.leadsInQualification = this.tempLeadsInQualification.filter(function (el){return new Date(el.createdAt).getTime() >= start && new Date(el.createdAt).getTime() <= end});
      this.totalLeads = this.UnqualifiedLeads.length + this.QualifiedLeads.length + this.leadsInQualification.length;
    }
    
  }


  

  openAModal(lead){
    this.leadInfoName = lead.businessName;
    this.workspaceIsLoading = true
    console.log("this is the value",lead);
    this.qualificationService.getAllContactsAttachedToSuspects(lead.id)
    .subscribe(async(data:any)=>{
      if(data.responseCode == "00"){
        console.log("response from serve",data.responseData);
        this.contactList = data.responseData
        this.messageService.add({
          severity: "success",
          summary: "Notice",
          detail: "All Qualification data was fectched successsfully",
        });
        this.displayContacts = true;
        this.workspaceIsLoading = false
      }
      else{
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail: "All Contacts attached data wasn't fectched successsfully",
        });
        this.workspaceIsLoading = false
      }
    },error=>{
      this.messageService.add({
        severity: "error",
        summary: "Notice",
        detail: "An error occurred",
      });
      this.workspaceIsLoading = false
    }
    )
  }
  getAllDealWorth(lead):number{
    var totalDealWorth = 0
    this.DivisionList.forEach(division=>{
      if(division.suspectId == null || lead.id == null){
        return totalDealWorth;
      }
      if(division.suspectId == lead.id){
          division.quote.quoteServices.forEach(x=>{
            totalDealWorth = totalDealWorth + x.billableAmount
          })
      }
      return totalDealWorth;
    })
    return totalDealWorth;
}

getAllDevisions(){
  this.qualificationService.getAllDevisions()
  .subscribe(async(data:any)=>{
    this.DivisionList = data.responseCode == "00" ? data.responseData : [];
    console.log("these are the division",this.DivisionList);
  })
}


}
