import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { ConfirmationService, MessageService } from "primeng/api";
import { BreadcrumbService } from "src/app/breadcrumb.service";
import { Account } from "src/app/interfaces/account";
import {
  AccountDetail,
  AccountMaster,
  AccountVoucherType,
  AdvancePayment,
  CommonResponse,
  CustomerDivision,
} from "src/app/interfaces/home";
import { AccountMasterService } from "src/app/services/account-master";
import { AccountVoucherTypeService } from "src/app/services/account-voucher-type.service";
import { AccountService } from "src/app/services/account.service";
import { AdvancePaymentsService } from "src/app/services/advance-payments.service";
import { CustomerDivisionService } from "src/app/services/customer-division.service";

@Component({
  selector: "app-advance-payment-reversal",
  templateUrl: "./advance-payment-reversal.component.html",
  styleUrls: ["./advance-payment-reversal.component.scss"],
})
export class AdvancePaymentReversalComponent implements OnInit {
  @ViewChild("formWrapper") public formWrapper: ElementRef;
  allClients: CustomerDivision[];
  theClient: CustomerDivision;
  clientAdvancePayments: AdvancePayment[];
  clientAdvancePayCols: any[];
  clientAcctMasters: AccountMaster[];
  acctMasterDetails: AccountDetail[];
  showClientAP: boolean;
  fetchingClientsAp: boolean;
  showRightSide: boolean;
  showAcctDetails: boolean;
  allAccounts: Account[] = [];
  fetchingAcctMasters: boolean;
  allAccountVoucherTypes: AccountVoucherType[] = [];

  constructor(
    private fb: FormBuilder,
    public accountVoucherTypeService: AccountVoucherTypeService,
    public accountMasterService: AccountMasterService,
    public accountService: AccountService,
    public clientService: CustomerDivisionService,
    public advancePaymentService: AdvancePaymentsService,
    private breadcrumbService: BreadcrumbService,
    public confirmationService: ConfirmationService,
    public messageService: MessageService
  ) {}

  ngOnInit(): void {
    this.breadcrumbService.setItems([
      {
        label: "Finance",
      },
      {
        label: "Advance Payment Reversal",
        routerLink: ["/home/finance/advance-payment/reversal"],
      },
    ]);

    this.clientAdvancePayCols = [
      { field: "amount", header: "Amount" },
      { field: "description", header: "Description" },
      { field: "dateReceived", header: "Date Received" },
      { field: "usedUp", header: "Used Up" },
    ];

    this.FetchAllClients();
    this.FetchAllVoucherTypes();
    this.FetchAllAccounts();
  }

  async FetchAllClients() {
    this.clientService.allCustomerDivision().subscribe(
      async (data) => {
        this.allClients = data.responseData;
      },
      (error) => {
        this.messageService.add({
          severity: "error",
          summary: "Failed",
          detail: error ?? "Some errors occurred",
        });
      }
    );
  }

  async FetchAllVoucherTypes() {
    this.accountVoucherTypeService.allAccountVoucherTypeData().subscribe(
      async (data) => {
        this.allAccountVoucherTypes = data.responseData;
      },
      (error) => {
        this.messageService.add({
          severity: "error",
          summary: "Failed",
          detail: error ?? "Some errors occurred",
        });
      }
    );
  }

  async FetchAllAccounts() {
    this.accountService.allAccount().subscribe(
      async (res) => {
        this.allAccounts = res.responseData;
      },
      (error) => {
        this.messageService.add({
          severity: "error",
          summary: "Failed",
          detail: "Connection error, Try again later",
        });
      }
    );
  }

  OnClientChange() {
    this.showClientAP = false;
    this.showRightSide = false;
    this.showAcctDetails = false;
    this.clientAdvancePayments = [];
    if (this.theClient) {
      this.fetchingClientsAp = true;
      this.advancePaymentService.getAdvancePayment(this.theClient.id).subscribe(
        async (data) => {
          if (data.responseCode == "00") {
            this.clientAdvancePayments = data.responseData;
            this.fetchingClientsAp = false;
            this.showClientAP = true;
          } else {
            this.messageService.add({
              severity: "error",
              summary: "Failure",
              detail:
                "Err: Could not get client advance payments. Reason -> " +
                data.responseMsg,
            });
            this.fetchingClientsAp = false;
          }
        },
        (error) => {
          console.log("Error: " + JSON.stringify(error));
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail:
              "Unable to get client advance payments at the moment.. Reason: [" +
              error.error.message +
              "]",
          });
          this.fetchingClientsAp = false;
        }
      );
    }
  }

  ShowDetails(item: AdvancePayment) {
    this.showRightSide = true;
    this.showAcctDetails = false;
    this.clientAcctMasters = [];
    this.acctMasterDetails = [];
    if (!item.postingId) {
      this.messageService.add({
        severity: "error",
        summary: "Notice",
        detail: "Posting information is missing for this advance payment.",
      });
    } else {
      this.fetchingAcctMasters = true;
      this.accountMasterService.getAccountMaster(item.postingId).subscribe(
        async (data) => {
          if (data.responseCode == "00") {
            this.clientAcctMasters = [];
            this.clientAcctMasters.push(data.responseData);
          } else {
            this.messageService.add({
              severity: "error",
              summary: "Failure",
              detail:
                "Err: Could not get advance payment account masters. Reason -> " +
                data.responseMsg,
            });
          }
          this.fetchingAcctMasters = false;
        },
        (error) => {
          console.log("Error: " + JSON.stringify(error));
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail:
              "Unable to get advance payment account masters at the moment.. Reason: [" +
              error.error.message +
              "]",
          });
          this.fetchingAcctMasters = false;
        }
      );
    }
  }

  ShowAcctDetails(item: AccountMaster) {
    this.acctMasterDetails = item.accountDetails;
    console.log(this.acctMasterDetails);
    this.showAcctDetails = true;
  }

  Reverse(item: AccountMaster) {
    this.confirmationService.confirm({
      message:
        "Are you sure you want to reverse this advance payment, Kindly note that all postings tied to this advance payment will be reversed?",
      accept: () => {
        this.messageService.add({
          severity: "info",
          summary: "Notice",
          detail: "Reversing Advance Payment......",
        });

        this.advancePaymentService.ReversePosting(item.id).subscribe(
          async (res: CommonResponse) => {
            if (res.responseCode == "00") {
              this.messageService.add({
                severity: "success",
                summary: "Removed",
                detail: "Advance Payment Reversed successfully",
              });
            } else {
              this.messageService.add({
                severity: "error",
                summary: "Failure",
                detail:
                  "Err: Could not reverse advance payment. Reason -> " +
                  res.responseMsg,
              });
            }
          },
          (error) => {
            console.log("Error: " + JSON.stringify(error));
            this.messageService.add({
              severity: "error",
              summary: "Notice",
              detail:
                "Unable to reverse advance payment at the moment.. Reason: [" +
                error.error.message +
                "]",
            });
          }
        );
      },
    });
  }

  GetAccountNumber(accountId: number): string {
    let account = this.allAccounts.find((x) => x.id == accountId);
    if (account) return account.accountNumber;

    return "N/A";
  }

  GetAccountName(accountId: number): string {
    let account = this.allAccounts.find((x) => x.id == accountId);
    if (account) return account.name;

    return "N/A";
  }

  GetVoucherTypeLabel(voucherId: number): string {
    let voucherType = this.allAccountVoucherTypes.find(
      (x) => x.id == voucherId
    );
    if (voucherType) return voucherType.voucherType;

    return "N/A";
  }
}
