import { CommonResponse, Location } from "./../../../interfaces/home";
import {
  AddLocationToPolicyCycle,
  ChecklistCategory,
  Cluster,
  ClusterPolicyCycle,
  GuardDeployment,
  SubmitGeneralReportCluster,
  SubmitOperativeSpecificClusterReport,
  VerifyCordinateAgainstLocationCordinateVM,
} from "./../../../interfaces/employment";
import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MessageService, Message, ConfirmationService } from "primeng/api";
import { BreadcrumbService } from "src/app/breadcrumb.service";
import { GmaEmploymentService } from "src/app/services/gma-employment.service";
import { finalize } from "rxjs/operators";
import { FileStorageService } from "src/app/services/file-storage.service";

@Component({
  selector: "app-gma-cluster-reporting",
  templateUrl: "./gma-cluster-reporting.component.html",
  styleUrls: ["./gma-cluster-reporting.component.scss"],
  providers: [MessageService],
})
export class GmaClusterReportingComponent implements OnInit {
  @ViewChild("formWrapper") public formWrapper: ElementRef;
  formCapture: FormGroup;
  clustersManagedMsgs: Message[] = [];
  locMsgs: Message[] = [];
  presentMsgs: Message[] = [];
  carouselResponsiveOptions: any[] = [
    {
      breakpoint: "1024px",
      numVisible: 3,
      numScroll: 3,
    },
    {
      breakpoint: "768px",
      numVisible: 2,
      numScroll: 2,
    },
    {
      breakpoint: "560px",
      numVisible: 1,
      numScroll: 1,
    },
  ];
  allClustersManaged: Cluster[] = [];
  allClusterLocations: Location[] = [];
  allPickedClusterLocations: Location[] = [];
  selectedPickedClusterLocation: Location;
  allgeneralReportItems: {
    name: string;
    key: number;
    status: boolean;
    report?: string;
    checkLists?: ChecklistCategory[];
  }[];
  selectedGeneralReportItem: {
    name: string;
    key: number;
    status: boolean;
    report?: string;
    checkLists?: ChecklistCategory[];
  };
  allLocationOperatives: GuardDeployment[] = [];
  selectedLocationOperative: GuardDeployment;
  clusterInView: Cluster;
  openCaptureDialog: boolean = false;
  captureDialogHeader: string = "Capture Dialog Header";
  captureText: string;
  clientLocationInView: Location;
  captureKey: number = 0;
  operativeInView: GuardDeployment;
  isTodayGeneralReportSubmitted: boolean = false;
  isTodayOperativeSpecificReportSubmitted: boolean = false;
  activeCycle: ClusterPolicyCycle;
  hasPickedToday: boolean = false;
  openChecklistDialogue: boolean = false;
  allCheckListCategories: ChecklistCategory[];
  reportingCheckListCategs: ChecklistCategory[];
  noOfLocationsToPick: number = 0;
  openCaptureComplaintDialog: boolean = false;
  uploadedEvidences: any[] = [];
  userIsYetToClickUpload: boolean;
  uplOperativeEvidences: {
    evidence: any;
    evidenceUrl?: string;
    operativeInViewId: number;
  }[] = [];
  hiddenLoc: Location;

  constructor(
    private fb: FormBuilder,
    public employmentService: GmaEmploymentService,
    public messageService: MessageService,
    private breadcrumbService: BreadcrumbService,
    public confirmationService: ConfirmationService,
    private fileStorageService: FileStorageService
  ) {
    this.formCapture = fb.group({
      Comment: ["", Validators.required],
    });
  }

  ngOnInit(): void {
    this.breadcrumbService.setItems([
      {
        label: "Guard Management",
        routerLink: ["/home/guard-management/item-settings"],
      },
      {
        label: "GMA Cluster Reporting",
        routerLink: ["/home/guard-management/gma-cluster-reporting"],
      },
    ]);

    this.allgeneralReportItems = [
      {
        name: "Checklist",
        status: false,
        key: 1,
      },
      // {
      //   name: "Turn Out",
      //   status: false,
      //   key: 2,
      // },
      {
        name: "Gen Security Conditions",
        status: false,
        key: 2,
      },
      {
        name: "General Remarks",
        status: false,
        key: 3,
      },
      {
        name: "General Complaints",
        status: false,
        key: 4,
      },
    ];

    this.ResetMessageToasters();
    this.FetchAllUserClusters();
    this.FetchAllChecklistCategs();
  }

  FetchAllChecklistCategs() {
    this.employmentService.GetAllChecklistsForReporting().subscribe(
      async (data) => {
        this.allCheckListCategories = data;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable get all checklist categories at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
        this.ResetMessageToasters();
      }
    );
  }

  GetLocationReportSubmissionStatus(locationId: number) {
    this.employmentService
      .CheckLocationReportSubmissionStatus(locationId)
      .subscribe(
        async (data) => {
          this.isTodayGeneralReportSubmitted =
            data.generalReportSubmissionStatus;
          this.isTodayOperativeSpecificReportSubmitted =
            data.operativeSpecififcReportSubmissionStatus;
        },
        (error) => {
          console.log("Error: " + JSON.stringify(error));
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail:
              "Unable get submission status at the moment.. Reason: [" + error
                ? error.error.message
                : "request failed - permission" + "]",
          });
          this.ResetMessageToasters();
        }
      );
  }

  FetchAllUserClusters() {
    this.employmentService.GetAllUserClusters().subscribe(
      async (data) => {
        this.allClustersManaged = data;
        this.allClustersManaged.forEach((cluster) => {
          let activePolicy = cluster.supervisionPolicies.find(
            (x) => x.isDeleted == false
          );

          cluster.hasActivePolicy = activePolicy ? true : false;
          if (activePolicy) {
            this.activeCycle = activePolicy.cycles.find(
              (x) => x.isActive == true
            );
          }
        });
        this.ResetMessageToasters();
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable get all clusters at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
        this.ResetMessageToasters();
      }
    );
  }

  ResetMessageToasters() {
    this.clustersManagedMsgs = [];
    this.clustersManagedMsgs.push({
      severity: "info",
      summary: "Summary:",
      detail:
        "There are " +
        this.allClustersManaged.length +
        " clusters managed under your profile.",
    });

    this.presentMsgs = [];
    this.presentMsgs.push({
      severity: "success",
      summary: "",
      detail: "You have been confirmed has being present at the location.",
    });

    this.locMsgs = [];
    this.locMsgs.push({
      severity: "info",
      summary: "Info:",
      detail:
        "The current active policy enforces you must pick " +
        this.noOfLocationsToPick +
        " location(s).",
    });
  }

  GetClusterLocationsOperativeCount(item: Cluster): number {
    let count = 0;
    item.locations
      .filter((x) => x.isDeleted == false)
      .forEach((x) => {
        count += x.guardDeployments.filter((x) => x.isDeleted == false).length;
      });
    return count;
  }

  LoadClusterDits(item: Cluster) {
    this.allClusterLocations = item.locations.filter(
      (x) => x.isDeleted == false
    );
    this.clusterInView = item;
    this.clientLocationInView = null;
    this.allPickedClusterLocations = [];
    this.allLocationOperatives = [];
    this.operativeInView = null;
    this.noOfLocationsToPick = item.supervisionPolicies.find(
      (x) => x.isDeleted == false
    ).minimumLocationsToCover;
    this.ResetMessageToasters();
    this.GetClusterPolicyCycleStats(item);
  }

  GetClusterPolicyCycleStats(item: Cluster) {
    let activePolicy = item.supervisionPolicies.find(
      (x) => x.isDeleted == false
    );
    if (activePolicy) {
      let activeCycle = activePolicy.cycles.find((x) => x.isActive == true);
      if (activeCycle) {
        this.activeCycle = activeCycle;
        this.employmentService.GetActiveCycleInfo(activeCycle.id).subscribe(
          async (data) => {
            this.hasPickedToday = data.hasAlreadyPickedToday;
            if (this.hasPickedToday) {
              this.allPickedClusterLocations = this.allClusterLocations.filter(
                (x) => data.locationsIds.find((y) => y == x.id) != null
              );
            } else {
              this.allClusterLocations = this.allClusterLocations.filter(
                (x) => data.locationsIds.find((y) => y == x.id) == null
              );
            }
          },
          (error) => {
            console.log("Error: " + JSON.stringify(error));
            this.messageService.add({
              severity: "error",
              summary: "Notice",
              detail:
                "Unable get cluster dit at the moment.. Reason: [" + error
                  ? error.error.message
                  : "request failed - permission" + "]",
            });
            this.ResetMessageToasters();
          }
        );
      } else {
        if (!this.activeCycle) {
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail: "Error no active cycle running",
          });
          this.ResetMessageToasters();
        }
      }
    }
  }

  async LoadClusterLocDits(item: Location) {
    if (!this.hasPickedToday) {
      this.messageService.add({
        severity: "error",
        summary: "Notice",
        detail:
          "You can't proceed until you've picked your locations for today",
      });
      this.ResetMessageToasters();
      return;
    }

    localStorage.clear();
    this.hiddenLoc = item;
    await this.GetCurrentLocation();
  }

  async GetCurrentLocation() {
    var uri =
      "https://www.googleapis.com/geolocation/v1/geolocate?key=AIzaSyCQuetprs2UHb_zKSXznyumyfvw95ERCDY";
    var xmlhttp = new XMLHttpRequest();
    xmlhttp.onreadystatechange = function () {
      if (xmlhttp.readyState == 4 && xmlhttp.status == 200) {
        let result = JSON.parse(xmlhttp.responseText);
        localStorage.setItem("longitude", result.location.lng);
        localStorage.setItem("latitude", result.location.lat);
        document.getElementById("btnVerifyGeo").click();
      } else if (xmlhttp.status == 404) {
        alert(
          "Unable to get current location cordinates = " + xmlhttp.status + "."
        );
      }
    };
    xmlhttp.open("POST", uri, true);
    xmlhttp.send();
    xmlhttp.onerror = function () {
      alert(
        "Unable to get current location cordinates = " +
          xmlhttp.statusText +
          "."
      );
    };
  }

  async VerifyUserClusterReportingLocation() {
    let longitude = localStorage.getItem("longitude");
    let latitude = localStorage.getItem("latitude");
    localStorage.clear();
    console.log("Longitude: " + longitude + " | Latitude: " + latitude);
    if (!longitude || !latitude) {
      this.messageService.add({
        severity: "error",
        summary: "Notice",
        detail:
          "Unable to get your current location cordinates, Kindly check your internet connection and try again.",
      });
      this.ResetMessageToasters();
      this.hiddenLoc = null;
      return;
    }

    const postData: VerifyCordinateAgainstLocationCordinateVM = {
      locationId: this.hiddenLoc.id,
      latitude: latitude,
      longitude: longitude,
    };
    this.employmentService
      .VerifyCordinateAgainstLocationCordinate(postData)
      .subscribe(
        async (data) => {
          // this.messageService.add({
          //   severity: "info",
          //   summary: "Notice",
          //   detail:
          //     "The verify cordinate functionality is currently disabled. Will be enabled on completion.",
          // });
          // this.ResetMessageToasters();
          // await this._loadClusterLocDits(this.hiddenLoc);
          // this.hiddenLoc = null;
          if (data.isNearLocation) {
            await this._loadClusterLocDits(this.hiddenLoc);
            this.hiddenLoc = null;
          } else {
            this.messageService.add({
              severity: "error",
              summary: "Notice",
              detail:
                "Error, your not in the geo-range of the cluster location. Your " +
                data.distance +
                " Kilometers away from the cluster location.",
            });
            this.ResetMessageToasters();
            this.hiddenLoc = null;
          }
        },
        (error) => {
          console.log("Error: " + JSON.stringify(error));
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail:
              "Unable to verify user's location against cluster location at the moment.. Reason: [" +
              error
                ? error.error.message
                : "request failed - permission" + "]",
          });
          this.ResetMessageToasters();
        }
      );
  }

  async _loadClusterLocDits(item: Location) {
    this.employmentService.GetLocationSupervisoryInfo(item.id).subscribe(
      async (data) => {
        //item.guardDeployments = data.guardDeployments.filter(x => x.isDeleted == false);
        this.allLocationOperatives = data.guardDeployments.filter(
          (x) => x.isDeleted == false
        );
        this.clientLocationInView = item;
        this.GetLocationReportSubmissionStatus(item.id);
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to load location info at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
        this.ResetMessageToasters();
      }
    );
  }

  GetLocationGuardCount(item: Location): number {
    return item.guardDeployments.filter((x) => x.isDeleted == false).length;
  }

  CaptureGenReport(item: any) {
    if (item.key == 1) {
      this.openChecklistDialogue = true;
      this.reportingCheckListCategs = this.allCheckListCategories;
      this.reportingCheckListCategs.forEach((categ) => {
        categ.checklists = categ.checklists.filter((x) => x.isDeleted == false);
        categ.checklists.forEach((x) => (x.isChecked = false));
      });
    } else if (item.key == 4) {
      this.openCaptureComplaintDialog = true;
      this.captureDialogHeader = "Capture " + item.name + " Report";
      this.captureText =
        "Capture " +
        item.name +
        " report for client location '" +
        this.clientLocationInView.name +
        "' for today.";
    } else {
      this.openCaptureDialog = true;
      this.captureDialogHeader = "Capture " + item.name + " Report";
      this.captureText =
        "Capture " +
        item.name +
        " report for client location '" +
        this.clientLocationInView.name +
        "' for today.";
    }
    this.captureKey = item.key;
  }

  async SubmitGeneralReport() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Submitting General Report...",
    });
    this.ResetMessageToasters();

    const postData: SubmitGeneralReportCluster = {
      locationId: this.clientLocationInView.id,
      reports: [],
      cycleId: this.activeCycle.id,
      checklistReports: [],
      evidences: [],
    };
    this.allgeneralReportItems.forEach((x) => {
      postData.reports.push({
        key: x.key,
        report: x.report,
      });
    });
    let chkList = this.allgeneralReportItems.find((x) => x.key == 1);
    chkList.checkLists.forEach((x) => {
      x.checklists.forEach((y) => {
        postData.checklistReports.push({
          checklistId: y.id,
          isChecked: y.isChecked,
        });
      });
    });

    if (this.uploadedEvidences.length > 0) {
      this.fileStorageService.UploadMultipleFilesFromDataUrl(
        this.uploadedEvidences
      );
      this.fileStorageService.onUploadFinished.subscribe(
        (resp: CommonResponse<string[]>) => {
          if (resp.responseCode != "00") {
            this.messageService.add({
              severity: "error",
              summary: "Notice",
              detail: resp.responseMsg,
            });
          } else {
            postData.evidences = resp.responseData;
            this._submitGeneralReport(postData);
          }
        },
        (error) => {
          console.log("Error while uploading files " + error);
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail: "ERR: Unable to upload image to storage",
          });
        }
      );
    } else {
      await this._submitGeneralReport(postData);
    }
  }

  async _submitGeneralReport(postData: SubmitGeneralReportCluster) {
    this.employmentService.SubmitGeneralReportCluster(postData).subscribe(
      () => {
        this.messageService.add({
          severity: "success",
          summary: "Notice",
          detail: "General Report Submitted Successfully!",
        });
        this.ResetMessageToasters();
        this.isTodayGeneralReportSubmitted = true;
        this.uploadedEvidences = [];
        this.formCapture.reset();
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to submit general report at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
        this.ResetMessageToasters();
      }
    );
  }

  CaptureSpecificComplaint(item: GuardDeployment) {
    // this.openCaptureDialog = true;
    this.openCaptureComplaintDialog = true;
    this.captureDialogHeader = "Capture Specific Complaint Report";
    this.captureText =
      "Capture specific complaint report for operative " +
      item.deployedGuard.jobApplication.personalInformation.lastname +
      " " +
      item.deployedGuard.jobApplication.personalInformation.firstname +
      " in client location '" +
      this.clientLocationInView.name +
      "' for today.";
    this.captureKey = 7;
    this.operativeInView = item;
  }

  CaptureNonConform(item: GuardDeployment) {
    this.openCaptureDialog = true;
    this.captureDialogHeader = "Capture Non-Conformpliance Report";
    this.captureText =
      "Capture non-conformpliance report for operative " +
      item.deployedGuard.jobApplication.personalInformation.lastname +
      " " +
      item.deployedGuard.jobApplication.personalInformation.firstname +
      " in client location '" +
      this.clientLocationInView.name +
      "' for today.";
    this.captureKey = 6;
    this.operativeInView = item;
  }

  IsOperativeAbsentSwitch($event) {}

  async SubmitSpecificOPReport() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Submitting Operative Specific Report(s)...",
    });
    this.ResetMessageToasters();

    const postData: SubmitOperativeSpecificClusterReport = {
      reports: [],
      cycleId: this.activeCycle.id,
    };

    this.allLocationOperatives.forEach((op) => {
      postData.reports.push({
        guardDeploymentId: op.id,
        isAbsent: op.isAbsent,
        nonConformityReport: op.nonconformingReport,
        specificComplaintReport: op.specificComplaintReport,
        evidences: [],
      });
    });

    if (this.uplOperativeEvidences.length > 0) {
      for (let i = 0; i < this.uplOperativeEvidences.length; i++) {
        this.fileStorageService.UploadMultipleFilesFromDataUrl(
          this.uplOperativeEvidences[i].evidence
        );
        this.fileStorageService.onUploadFinished.subscribe(
          async (resp: CommonResponse<string[]>) => {
            if (resp.responseCode != "00") {
              this.messageService.add({
                severity: "error",
                summary: "Notice",
                detail: resp.responseMsg,
              });
            } else {
              let url = resp.responseData[0];
              if (url) {
                // post the form data
                let opId = this.uplOperativeEvidences[i].operativeInViewId;
                postData.reports
                  .find((x) => x.guardDeploymentId == opId)
                  .evidences.push(url);

                if (i == this.uplOperativeEvidences.length - 1)
                  await this._submitSpecificOPReport(postData);
              }
            }
          },
          (error) => {
            console.log("Error while uploading files " + error);
            this.messageService.add({
              severity: "error",
              summary: "Notice",
              detail: "ERR: Unable to upload image to storage",
            });
          }
        );
      }
    } else {
      await this._submitSpecificOPReport(postData);
    }
  }

  async _submitSpecificOPReport(
    postData: SubmitOperativeSpecificClusterReport
  ) {
    this.employmentService
      .SubmitOperativeSpecificReportCluster(postData)
      .subscribe(
        () => {
          this.messageService.add({
            severity: "success",
            summary: "Notice",
            detail: "Operative Specific Report(s) Submitted Successfully!",
          });
          this.ResetMessageToasters();
          this.isTodayOperativeSpecificReportSubmitted = true;
          this.uplOperativeEvidences = [];
          this.formCapture.reset();
        },
        (error) => {
          console.log("Error: " + JSON.stringify(error));
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail:
              "Unable to submit operative specific report(s) at the moment.. Reason: [" +
              error
                ? error.error.message
                : "request failed - permission" + "]",
          });
          this.ResetMessageToasters();
        }
      );
  }

  HideCaptureDialog() {
    this.openCaptureDialog = false;
    this.captureKey = 0;
    this.captureDialogHeader = "";
    this.captureText = null;
    this.operativeInView = null;
    this.formCapture.reset();
    this.CanSubmitGeneralReport();
  }

  HideCaptureComplaintDialog() {
    this.openCaptureComplaintDialog = false;
    this.captureKey = 0;
    this.captureDialogHeader = "";
    this.captureText = null;
    this.operativeInView = null;
    this.formCapture.reset();
    this.CanSubmitGeneralReport();
  }

  CaptureData() {
    if (this.captureKey > 0 && this.captureKey < 5) {
      this.allgeneralReportItems[this.captureKey - 1].report =
        this.formCapture.get("Comment").value;
      this.CanSubmitGeneralReport();
    } else if (this.captureKey == 6) {
      this.operativeInView.nonconformingReport =
        this.formCapture.get("Comment").value;
      this.CanSubmitOperativeSpecificReport();
    } else if (this.captureKey == 7) {
      this.operativeInView.specificComplaintReport =
        this.formCapture.get("Comment").value;
      this.CanSubmitOperativeSpecificReport();
    }

    this.openCaptureDialog = false;
    this.openCaptureComplaintDialog = false;
    this.captureKey = 0;
    this.captureDialogHeader = "";
    this.captureText = null;
    this.formCapture.reset();
    this.messageService.add({
      severity: "success",
      summary: "Removed",
      detail: "Report Captured Successfully",
    });
    this.ResetMessageToasters();
  }

  CanSubmitGeneralReport(): boolean {
    let report = this.allgeneralReportItems.find(
      (x) => !x.report && x.key != 4
    );
    return report != null;
  }

  CanSubmitOperativeSpecificReport(): boolean {
    //Future Logic would be added
    return false;
  }

  AddTodayLocationsToReport() {
    this.confirmationService.confirm({
      message:
        "You are about to select today's cluster locations to report. You will be unable to change today's location after this action.Do you still want to proceed?",
      accept: () => {
        this.messageService.add({
          severity: "info",
          summary: "Notice",
          detail: "Mapping Today's Location(s)...",
        });
        this.ResetMessageToasters();

        const postData: AddLocationToPolicyCycle = {
          cycleId: this.activeCycle.id,
          locationIds: this.allPickedClusterLocations.map((x) => x.id),
        };

        this.employmentService.AddLocationToPolicyCycle(postData).subscribe(
          () => {
            this.messageService.add({
              severity: "success",
              summary: "Removed",
              detail: "Today Locations Mapped Successfully",
            });
            this.ResetMessageToasters();

            this.hasPickedToday = true;
          },
          (error) => {
            console.log("Error: " + JSON.stringify(error));
            this.messageService.add({
              severity: "error",
              summary: "Notice",
              detail:
                "Unable to map today's location at the moment.. Reason: [" +
                error
                  ? error.error.message
                  : "request failed - permission" + "]",
            });
            this.ResetMessageToasters();
          }
        );
      },
    });
  }

  HideChecklistDialog() {
    this.openChecklistDialogue = false;
  }

  CaptureCheckListGen() {
    this.allgeneralReportItems[this.captureKey - 1].report =
      "Captured1213402057023";
    this.allgeneralReportItems[this.captureKey - 1].checkLists =
      this.reportingCheckListCategs;
    this.HideChecklistDialog();
    this.CanSubmitGeneralReport();

    this.captureKey = 0;
    this.messageService.add({
      severity: "success",
      summary: "Removed",
      detail: "Report Captured Successfully",
    });
    this.ResetMessageToasters();
  }

  onCancelUpload() {
    this.uploadedEvidences = [];
  }

  NotifyUserToClickUpload() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Kindly ensure you click upload, to upload the evidence(s).",
    });
    this.ResetMessageToasters();

    this.userIsYetToClickUpload = true;
  }

  onUpload(event) {
    this.uploadedEvidences = [];
    for (const file of event.files) {
      this.uploadedEvidences.push(file);

      if (this.operativeInView) {
        this.uplOperativeEvidences.push({
          evidence: file,
          operativeInViewId: this.operativeInView.id,
        });
      }
    }

    this.messageService.add({
      severity: "success",
      summary: "Success",
      detail: "Evidence(s) Uploaded",
    });
    this.ResetMessageToasters();

    this.userIsYetToClickUpload = false;
  }
}
