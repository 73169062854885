import { Contact } from "./home";

export const ContactPriorityEnum = {
  PrimaryContact: 1,
  SecondaryContact: 2,
  OtherContact: 3,
};
Object.freeze(ContactPriorityEnum);

export const ContactDesignationEnum = {
  CEO: 1,
  CFO: 2,
  CTO: 3,
  Admin: 4,
  Self: 5,
};
Object.freeze(ContactDesignationEnum);

export const ContactQualificationEnum = {
  Owner: 1,
  Influencer: 2,
  GateKeeper: 3,
  DecisionMaker: 4,
  Self: 5,
};
Object.freeze(ContactQualificationEnum);

export interface UpdateClientInfoVM {
  imageUrl?: string;
  name?: string;
  email?: string;
  mobile?: string;
  industry?: string;
}

export interface CreateClientContactVM {
  customerId: number;
  contactId: number;
  designation: number;
  qualification: number;
  contactType: number;
}

export interface CreateContractContactVM {
  contractId: number;
  contactId: number;
  designation: number;
  qualification: number;
  contactType: number;
}

export interface ContractContact {
  id: number;
  contractId?: number;
  contactId?: number;
  contact?: Contact;
  contactPriority: number;
  contactDesignation: number;
  contactQualification: number;
  isDeleted: boolean;
  createdById: number;
  createdAt: Date;
  updatedAt: Date;
}

export interface CustomerContact {
  id: number;
  customerId?: number;
  contactId?: number;
  contact?: Contact;
  contactPriority: number;
  contactDesignation: number;
  contactQualification: number;
  isDeleted: boolean;
}

export interface UpdateClientContractInfoVM {
  description?: string;
}
