import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { LeadService } from "../../../services/lead.service";
import {
  Branch,
  CommonResponse,
  Constant,
  GeneralService,
  Lead,
  LeadDivision,
  Office,
  QuoteServices,
  Services,
} from "../../../interfaces/home";
import { ServicesService } from "../../../services/services.service";
import { DialogService, DynamicDialogRef } from "primeng/dynamicdialog";
import { ServiceClosureComponent } from "./service-closure/service-closure.component";
import { BranchService } from "../../../services/branch.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { LeadDivisionService } from "../../../services/lead-division.service";
import { ConfirmationService, MessageService } from "primeng/api";
import { BreadcrumbService } from "src/app/breadcrumb.service";
import { CreateKeyPersonComponent } from "../create-key-person/create-key-person.component";
import { KeyPersonnelComponent } from "../../client-management/client-view/key-personnel/key-personnel.component";
import { EngagementContactsService } from "../../engagement-contacts/engagement-contacts.service";
import { DomSanitizer } from "@angular/platform-browser";

@Component({
  selector: "app-conversion",
  templateUrl: "./conversion.component.html",
  styleUrls: ["./conversion.component.css"],
  providers: [Constant, DialogService, MessageService, ConfirmationService],
})
export class ConversionComponent implements OnInit {
  public leadRef: any;
  public currentLead: Lead = null;
  public lead: Lead;
  public leadDivisions: LeadDivision[];
  public selectedDivision: LeadDivision;
  public selectedServiceTotal: number;
  public selectedQuoteService: QuoteServices[];
  private selectedServices: Services[];
  public selectedGeneralService: GeneralService[];
  public services: Services[];
  public loadingLeadInfo: boolean;
  activeConversionStatus: boolean;
  activeLead: false;
  public qualifyServiceRef: DynamicDialogRef;
  branches: Branch[];
  offices: Office[];
  selectedBranch: Branch;
  selectedOffice: Office;
  officeBranchForm: FormGroup;
  divisionOffice: Office | null;
  keyPersonRef: DynamicDialogRef;
  keyPersonnelRef: DynamicDialogRef;
  billableAmountTotal: number;
  isGroupUpload: boolean = false;
  quoteId: number = 0;
  canUploadGroupDocs: boolean = true;
  suspectContact: any[];
  LeadDenialComment = "Disapproval Comments";

  constructor(
    public engagementService: EngagementContactsService,
    private sanitizer: DomSanitizer,
    private route: ActivatedRoute,
    private leadService: LeadService,
    private servicesService: ServicesService,
    public dialogService: DialogService,
    public branchService: BranchService,
    private formBuilder: FormBuilder,
    private leadDivisionService: LeadDivisionService,
    private messageService: MessageService,
    public confirmationService: ConfirmationService,
    private breadcrumbService: BreadcrumbService
  ) {}

  async ngOnInit() {
    this.breadcrumbService.setItems([
      { label: "Lead Administration", routerLink: ["home/lead-admin"] },
      { label: "Conversion", routerLink: ["home/lead-admin/conversion"] },
    ]);

    this.officeBranchForm = this.formBuilder.group({
      officeId: [null, Validators.required],
      branchId: [null, Validators.required],
    });
    this.loadingLeadInfo = true;
    await this.loadServices().then(async () => {
      //await this.loadOldData();
    });
  }

  async loadServices() {
    await this.branchService
      .allBranch()
      .subscribe(async (res: CommonResponse) => {
        this.branches = res.responseData;
      });
    await this.servicesService
      .allService()
      .subscribe(async (res: CommonResponse) => {
        this.services = res.responseData;
        await this.loadOldData();
      });
  }

  async loadOldData() {
    this.leadRef = this.route.snapshot.paramMap.get("referenceNo");
    if (this.leadRef) {
      // get the lead admin data and prefill the fields
      await this.leadService.setCurrentLead(this.leadRef);
      await this.leadService.activeLead().subscribe(async (res: any) => {
        if (res?.responseCode == "00") {
          this.currentLead = res.responseData;
          this.lead = this.currentLead;
          this.getAllContactsToSuspect(this.lead.suspectId);
          this.leadDivisions = this.currentLead.leadDivisions;
          // get the division data
          if (this.leadDivisions.length > 0) {
            // create the required formGroups
            if (!this.selectedDivision) {
              await this.selectDivision(this.leadDivisions[0]);
            } else {
              await this.selectDivision(this.selectedDivision);
            }
          }
          this.loadingLeadInfo = false;
        }
      });
    }
  }

  transform(image) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(image);
  }

  getAllContactsToSuspect(id) {
    this.engagementService.getAllContactsAttachedToSuspects(id).subscribe(
      async (res: any) => {
        if (res.responseCode == "00") {
          this.suspectContact = [];
          this.suspectContact = res.responseData.filter(
            (x) => x.contact.isDeleted == false
          );
        } else {
          this.suspectContact = [];
        }
      },
      (error) => {
        this.suspectContact = [];
      }
    );
  }

  setOffice(_branch: Branch) {
    if (this.selectedBranch) {
      const _selectBranch = this.branches.find(
        (branch) => branch.id === _branch.id
      );
      this.offices = _selectBranch.offices;
    }
  }

  loadDivisionServices() {
    if (this.selectedDivision) {
      this.selectedQuoteService = [];
      this.selectedServices = [];
      this.selectedGeneralService = [];
      if (this.selectedDivision.quote) {
        const _quote = this.selectedDivision.quote;
        if (_quote.quoteServices.length > 0) {
          this.selectedQuoteService = [];
          this.selectedServices = [];
          this.selectedGeneralService = [];
          _quote.quoteServices.forEach((value) => {
            const _service = this.services.find(
              (service) => service.id === value.serviceId
            );
            this.selectedServices = [...this.selectedServices, _service];
            this.selectedQuoteService = [...this.selectedQuoteService, value];
            this.selectedGeneralService = [
              ...this.selectedGeneralService,
              { service: _service, quoteService: value },
            ];
            if (value.sbutoQuoteServiceProportions?.length > 0) {
              this.canUploadGroupDocs = false;
            }
          });
          this.calTotalService();
        }
      }
      // load division office data
      if (this.selectedDivision.branchId > 0) {
        const _divisionBranch = this.branches.find(
          (_branch) => _branch.id === this.selectedDivision.branchId
        );
        this.divisionOffice = _divisionBranch.offices.find(
          (_office) => _office.id === this.selectedDivision.officeId
        );
      }
    }
  }

  selectDivision(leadDivision?: LeadDivision) {
    this.leadDivisionService.setCurrentLeadDivision(leadDivision);
    this.selectedDivision = this.leadDivisions.find(
      (_leadDivision) => _leadDivision.id === leadDivision.id
    );
    this.loadDivisionServices();
  }
  calTotalService() {
    this.selectedServiceTotal = 0;
    this.billableAmountTotal = 0;
    if (this.selectedQuoteService.length >= 1) {
      for (let i = 0; i < this.selectedQuoteService.length; i++) {
        const amount = this.selectedQuoteService[i].unitPrice;
        const quantity = this.selectedQuoteService[i].quantity;
        if (amount && quantity) {
          this.selectedServiceTotal += amount * quantity;
        }
        this.billableAmountTotal += this.selectedQuoteService[i].billableAmount;
      }
    }
  }

  nextPage() {
    // udi
  }

  uploadGroupDocuments() {
    this.quoteId = this.leadDivisions[0].quote?.id;
    this.qualifyService(this.selectedGeneralService[0], true);
  }

  qualifyService(generalService: GeneralService, isGroup: boolean = false) {
    let p = this.isGroupUpload;
    this.qualifyServiceRef = this.dialogService.open(ServiceClosureComponent, {
      showHeader: false,
      width: "70%",
      contentStyle: { "min-height": "550px", height: "100%", overflow: "auto" },
      baseZIndex: 980,
      data: {
        generalService: generalService,
        isGroupUpload: isGroup,
        quoteId: this.quoteId,
        allQuoateServices: this.selectedQuoteService,
      },
    });
    this.qualifyServiceRef.onClose.subscribe((res: any) => {
      console.log(res);
      this.loadOldData();
      if (res) {
        if (isGroup) {
          this.isGroupUpload = true;
        }
      }
    });
  }

  addOffice() {
    if (this.officeBranchForm.valid) {
      const _lead = this.selectedDivision;
      const _primaryContactId =
        _lead.primaryContactId !== null ? _lead.primaryContactId : 0;
      const _postData = {
        leadTypeId: _lead.leadTypeId,
        leadOriginId: _lead.leadOriginId,
        industry: _lead.industry,
        rcNumber: _lead.rcNumber,
        divisionName: _lead.divisionName,
        phoneNumber: _lead.phoneNumber,
        primaryContactId: _primaryContactId,
        email: _lead.email,
        logoUrl: _lead.logoUrl,
        address: _lead.address,
        leadId: _lead.leadId,
        branchId: this.selectedBranch.id,
        officeId: this.selectedOffice.id,
        stateId: _lead.stateId,
        lgaId: _lead.lgaId,
        street: _lead.street,
      };
      this.messageService.add({
        summary: "Request complete",
        detail: "Adding Office and Branch to the " + _lead.divisionName,
        severity: "info",
      });
      this.leadDivisionService
        .updateLeadDivision(_lead.id, _postData)
        .subscribe(async (res: any) => {
          this.messageService.add({
            summary: "Request complete",
            detail: "Office and branch added to the selected division",
            severity: "success",
          });
          await this.loadOldData();
        });
    }
  }

  closeLead() {
    // check if the requirement is satisfied
    let _leadDivisionWithoutOffice: LeadDivision;
    _leadDivisionWithoutOffice = this.currentLead.leadDivisions.find(
      (_leadDivision) => _leadDivision.officeId === 0
    );
    if (_leadDivisionWithoutOffice) {
      this.messageService.add({
        summary: "Canceled",
        detail: "Add office to all the Lead Division",
        severity: "error",
      });
      return;
    }
    // check if there is no unqualify service in any of the division
    let _leadService;
    if (this.currentLead.leadDivisions.length > 0) {
      for (const leadDivisionElement of this.currentLead.leadDivisions) {
        if (leadDivisionElement.quote !== null) {
          _leadService = leadDivisionElement.quote.quoteServices.find(
            (_quoteService) =>
              _quoteService.sbutoQuoteServiceProportions?.length <= 0
          );
        } else {
          _leadService = "quote not found";
        }
        if (_leadService) {
          break;
        }
      }
    }
    if (_leadService) {
      this.messageService.add({
        summary: "Canceled",
        detail:
          "Qualify all the service in the division before progressing the lead",
        severity: "error",
      });
      return;
    }
    // everything is good lets complete the lead
    const oldMessage =
      "Do you want to convert the Lead to a Client? <br>" +
      "if you choose to proceed a final invoice will be created and you be able to modify the lead again";
    this.confirmationService.confirm({
      message: "Do you want to set up the lead for approvals ?",
      header: "Progress Lead",
      icon: "pi pi-info-circle",
      accept: () => {
        this.messageService.add({
          severity: "info",
          summary: "Confirmed",
          detail: "Processing lead approvals, Please wait",
          sticky: true,
        });
        this.completeLead();
      },
      reject: () => {
        this.messageService.add({
          severity: "error",
          summary: "Rejected",
          detail: "Progress canceled",
        });
      },
    });
  }

  async completeLead() {
    let _leadComplete = false;
    await this.leadService
      .updateLeadClosure(this.currentLead.id)
      .subscribe(async (res: CommonResponse) => {
        if (res.responseCode == "00") {
          _leadComplete = true;
          this.setupApprovalForLead();
          await this.loadOldData();
        } else {
          this.messageService.add({
            severity: "error",
            summary: "Rejected",
            detail: res.responseMsg,
          });
        }
      });
  }

  private async setupApprovalForLead() {
    await this.leadService.setUpLeadForApprovals(this.currentLead.id).subscribe(
      async () => {
        await this.messageService.add({
          severity: "success",
          summary: "Confirmed",
          detail: "Approvals Completed.",
        });
        await this.messageService.add({
          severity: "info",
          summary: "loading",
          detail: "Closing the lead.",
        });
      },
      (error) => {
        this.messageService.add({
          severity: "error",
          summary: "Failed",
          detail: "Could not set up lead for approvals. " + error.error.message,
        });
      }
    );
  }
  //(service.quoteService.sbutoQuoteServiceProportions)?.length > 0
  createKeyPerson(isLeadDivision: boolean) {
    if (true) {
      this.keyPersonRef = this.dialogService.open(CreateKeyPersonComponent, {
        header: "Create Divison Key Person",
        width: "50%",
        contentStyle: { "min-height": "350px", overflow: "auto" },
        baseZIndex: 10000,
        data: { isLeadDivision },
      });

      this.keyPersonRef.onClose.subscribe(async (res: any) => {});
    }
  }
  editQuoteServices() {
    this.leadService.navigateToPage("qualification");
  }

  viewKeyPersonnels(isLeadDivision: boolean) {
    let data = [];

    if (isLeadDivision) {
      if (this.selectedDivision.leadDivisionKeyPeople) {
        data = this.selectedDivision.leadDivisionKeyPeople;
      }
    } else {
      if (this.currentLead.leadKeyPeople) {
        data = this.currentLead.leadKeyPeople;
      }
    }

    this.keyPersonnelRef = this.dialogService.open(KeyPersonnelComponent, {
      header: "Key Personnels",
      width: "80%",
      contentStyle: { "min-height": "350px", overflow: "auto" },
      baseZIndex: 10000,
      data: { keyPersonnels: data, editable: false },
    });

    this.keyPersonnelRef.onClose.subscribe(async (res: any) => {});
  }
}
