import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CommonResponse } from 'src/app/interfaces/home';
import { environment } from 'src/environments/environment';

@Injectable()
export class SupplierOfficeSetupService {

  constructor(public http:HttpClient) { }
  userBaseUrl = environment.baseUrl + 'User/';
  supplierBaseUrl = environment.baseUrl + 'SupplierApprovals/';
  allUser(): Observable<CommonResponse>{
    return this.http.get<CommonResponse>(this.userBaseUrl);
  }
  create(office:any): Observable<CommonResponse> {
    return this.http.post<CommonResponse>(this.supplierBaseUrl, office);
  }

  allSuppliers(): Observable<CommonResponse>{
    return this.http.get<CommonResponse>(this.supplierBaseUrl);
  }

  reOrderOffice(officeId:any,reOrderOption:any):Observable<CommonResponse>{
    return this.http.put<CommonResponse>(this.supplierBaseUrl+"Reorder/"+officeId+"/"+reOrderOption,null);
  }
  // moveDown(officeId:any):Observable<CommonResponse>{
  //   return this.http.put<CommonResponse>(this.supplierBaseUrl+"moveDown/"+officeId,null);
  // }

  disableOffice(officeId:any):Observable<CommonResponse>{
    return this.http.put<CommonResponse>(this.supplierBaseUrl+"deactivateOffice/"+officeId,null);
  }

  updateOffice(officeId:any,body:any):Observable<CommonResponse>{
    return this.http.put<CommonResponse>(this.supplierBaseUrl+officeId,body)
  }

  disableOfficer(officerId:any,officeId:any):Observable<CommonResponse>{
    return this.http.put<CommonResponse>(this.supplierBaseUrl+"deactivateOfficer/"+officerId+"/"+officeId,null);
  }
  
  addOfficers(officeId:any,body:any):Observable<CommonResponse>{
    return this.http.post<CommonResponse>(this.supplierBaseUrl+"addOfficers/"+officeId,body);
  }
  
  
}
