import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { Subscription, from } from 'rxjs';
import { groupBy, mergeMap, toArray } from 'rxjs/operators';
import { BreadcrumbService } from 'src/app/breadcrumb.service';
import { NodeService } from 'src/app/demo/service/nodeservice';
import { BudgetapiservicesService } from 'src/app/services/budget/budgetapiservices.service';
import { ConfirmEventType } from 'src/app/interfaces/armada';

interface Category {
  id: number,
  name: string
}

interface BudgetDistributions {
  id: number,
  caption: string
}

@Component({
  selector: 'app-budget-reviewer',
  templateUrl: './budget-reviewer.component.html',
  styleUrls: ['./budget-reviewer.component.css']
})
export class BudgetReviewerComponent implements OnInit, OnDestroy {

  budgetReviewForm = this.fb.group({
    Comment: ['']
  })
  categories: any[];
  budgetCategories: any[]=[];
  allBudgetsForReview: any;
  budgetsForReview: any[]=[];

  budgetsForReview2: {
    name: string,
    distributions: any[]
  }[]=[];
  loadingData=false;
  distributions: any[] = [];
  showBudgetBreakDown: boolean = false;
  showRegionBreakDown: boolean = false;
  showProdCatBreakDown: boolean = false;
  showIndividualBreakDown: boolean = false;
  showMonthBreakDown: boolean = false;

  selectedBudgetDetail = [];
  budgetCreator: string | undefined;
  budgetDescription: string | undefined;
  benchMarkValue: number;
  calculatedTotalValue: number;
  calculatedPercentageValue: number;
  totalAllocated: number;
  totalUnallocated: number;
  totalCycleToReview: number = 0;
  budgetBreakDown: any[]=[];
  reviewerComment: string | undefined;
  showCommentDialog: boolean = false;
  // {
  //   categoryName: string;
  //   distributions: BudgetDistributions[];
  // }[]=[]
  allBudgetsForReviewData: any[]=[];
  allBudgetsDetails: any[]=[];
  usersSubscription: Subscription | undefined;
  allBudgetCats: Subscription | undefined;
  allBudgetForReviewSub: Subscription | undefined;

  constructor(private fb: FormBuilder,
              private apiService: BudgetapiservicesService,
              private messageService: MessageService,
              private confirmationService: ConfirmationService,
              public dialogService: DialogService,
              private breadcrumbService: BreadcrumbService) { }

  ngOnInit(): void {
    this.breadcrumbService.setItems([
      { label: "Budget Reviewer Page", routerLink: ["home/budget-reviewer-page"] },
      // { label: "Role", routerLink: ["home/role"] },
    ]);
    this.allBudgetForReviewSub = this.getAllBudgetsForReview();
  }

  ngOnDestroy(): void {
    // this.allBudgetCats.unsubscribe();
    this.allBudgetForReviewSub.unsubscribe();
  }

  getBudgetDistrinutionCategories() {
    this.loadingData = true;
    return this.apiService.getAllDistributionCategories().subscribe(res => {
      console.log(res['responseData']['distributionCats']);
      if (res['responseCode'] == "00") {
        this.categories=res['responseData'].distributionCats;
        this.categories.forEach(c => console.log(c.name));
        // this.usersSubscription = this.loadUsers();
        this.allBudgetsForReview=[]
        this.categories.forEach((cat) => {
          let allBudgets = {
            categoryName: cat.name,
            distributions: []
          }
          this.allBudgetsForReview.push(allBudgets);
          console.dir(this.allBudgetsForReview);
        })
        this.loadingData = false;
      }
      else {
        this.displayMessage("error", "Error", res['responseMsg']);
        this.loadingData = false;
      }
        
    })
  }

  objectGroupBy(collection, property) {
    var i = 0, val, index,
        values = [], result = [];
    for (; i < collection.length; i++) {
        val = collection[i][property];
        index = values.indexOf(val);
        if (index > -1)
            result[index].push(collection[i]);
        else {
            values.push(val);
            result.push([collection[i]]);
        }
    }
    return result;
  }
  getAllBudgetsForReview()
  {
    this.loadingData = true;
    this.budgetsForReview2 = [];
    this.displayMessage("info", "Information", "Processing...");
    return this.apiService.getBudgetsForReviewing()
    .subscribe(data => {
      // console.log(`This is all budgets for review: ${JSON.stringify(data)}`);
      if (data['responseCode'] == "00") {
        // console.dir(`This is all budgets for review: ${data['responseData'].regionBudgetForReview}`);
        console.dir(data['responseData']);
        
        // this.allBudgetsDetails=data['responseData'].budgetsForReview.budgetDetails;
        this.allBudgetsForReviewData=data['responseData'].budgetsForReview;
        console.dir(this.allBudgetsForReviewData)
        if (this.allBudgetsForReviewData.length === 0) {
          this.loadingData = false;
          return false;
        }
        this.allBudgetsForReviewData.forEach(data => {
          this.totalCycleToReview += data.budgetDetails.length
        });

        
        // this.budgetsForReview = data['responseData'].budgetsForReview;
        console.dir(this.allBudgetsForReviewData);
        console.dir(this.allBudgetsForReviewData);
        
        // let distributions: any[] = [];
        // const budgetsForReview = from(this.allBudgetsForReviewData);
        console.dir(this.allBudgetsDetails);
        console.log(typeof(this.allBudgetsDetails))
        // const groupByName = 
        // const budgetDetailsJxs = from(this.allBudgetsDetails);
        // const groupedRec = budgetDetailsJxs.pipe(
        //   groupBy(b => b.masterId),
        //   mergeMap(grp => grp.pipe(toArray()))
        // );

        // const exp = groupedRec.subscribe(val => {
        //   console.log(val)
        //   this.distributions.push(val);
        // });
        let counta: number = 0;
        this.allBudgetsForReviewData.forEach(det => {
          counta++;
          console.log(`Counter: ${counta}`)
          console.log(typeof(det))
          console.log(typeof(det.budgetDetails))
          console.log(Array.from(new Set(det['budgetDetails'].map((item) => item))))
          const masterId = det.budgetDetails.map((item) => item.masterId).filter((value, index, self) => self.indexOf(value)=== index)
          const caption = det.budgetDetails.map((item) => item.createdBy).filter((value, index, self) => self.indexOf(value)=== index)
          const uniqueBudgetDistribution = [...new Map(det.budgetDetails.map(item =>[item['budgetCaption'], item])).values()]
          console.log(masterId)
          console.log(caption)
          console.log(uniqueBudgetDistribution)
          let approvalData: {
            name: string;
            distributions: any[];
            counta: number
          } = {
            name: det.budgetDistributionCategory,
            distributions: [...new Map(det.budgetDetails.map(item =>[item['budgetCaption'], item])).values()],
            counta: counta
            // Array(det.budgetDetails).map((item) => item.masterId)
            //     .filter((masterId, index, currentVal) => {
            //       currentVal.indexOf(masterId) === index;
            //     })
            //this.objectGroupBy(det.budgetDetails, 'masterId')
            // data['responseData'].filter(
            //   (x) => x.categoryId == categId
            // ),
          };

          approvalData.distributions.forEach(dis => {
            dis.counta = counta
          })
          this.budgetsForReview2.push(approvalData);
          // this.allBudgetsDetails.push(det.budgetDetails)
        })
        // this.budgetsForReview2.distributions = this.distributions;
        console.dir(this.distributions)
        console.dir(this.budgetsForReview2)
        console.dir(this.budgetsForReview2['distributions'])
        this.budgetsForReview2.forEach(r => {
          Array.from(r.distributions).forEach(x=> console.log(x.masterId))
        })

        this.budgetsForReview2.forEach((data, ind) => {
          console.log(ind)
          if (data.distributions.length === 0)
          {
            var radix = this.budgetsForReview2.indexOf(data);
            delete this.allBudgetsForReviewData[radix];
          }
        })

        // let regionBudget = data['responseData'].regionBudgetForReview;
        // let productCatBudget = data['responseData'].productCatBudgetForReview;
        // let monthBudget = data['responseData'].monthBudgetForReview;
        // let individualBudget = data['responseData'].individualBudgetForReview;

        // this.budgetCategories.push({'catId':regionBudget.distributionCategoryId, 'name':regionBudget.budgetDistributionCategory});
        // this.budgetCategories.push({'catId':productCatBudget.distributionCategoryId, 'name':productCatBudget.budgetDistributionCategory});
        // this.budgetCategories.push({'catId':monthBudget.distributionCategoryId, 'name':monthBudget.budgetDistributionCategory});
        // this.budgetCategories.push({'catId':individualBudget.distributionCategoryId, 'name':individualBudget.budgetDistributionCategory});

        // this.distributions.push(regionBudget.budgetDetails)
        // this.distributions.push(productCatBudget.budgetDetails)
        // this.distributions.push(monthBudget.budgetDetails)
        // this.distributions.push(individualBudget.budgetDetails)
        // this.distributions.forEach(rec => console.dir(rec))
        // // this.allBudgetsForReviewData = []
        // let displayBudgetRecord = {
        //   'caption': this.budgetCategories,
        //   'distributions': this.distributions
        // };
        // this.budgetCategories.forEach(rec => {
        //   let reviewingBudget = {
        //     'category': rec.name,
        //     'distributions': this.distributions.filter(d=> d.id == rec.catId)
        //   }

        //   this.budgetsForReview.push(reviewingBudget);
        // })
        // console.dir(this.budgetCategories);
        // console.dir(displayBudgetRecord);
        // // this.budgetsForReview = displayBudgetRecord;
        // console.dir(this.budgetsForReview);

        // this.allBudgetsForReviewData.forEach((dist) => {
        //   let allBudgets = {
        //     categoryName: dist.budgetDistributionCategory,
        //     distributions: []
        //   }
        //   this.allBudgetsForReview.push(allBudgets);
        //   console.log(JSON.stringify(this.allBudgetsForReview));
        // })
        
      }
      this.loadingData = false;
    },
    (error) => {
      console.log("Error: " + JSON.stringify(error));
      this.messageService.add({
        severity: "error",
        summary: "Notice",
        detail: error.error.message
      });
      this.loadingData = false;
    })
  }

  viewDetails(item)
  {
    console.log(item)
    this.showBudgetBreakDown = true;
    this.showRegionBreakDown = item.id === 1 ? true : false
    this.showProdCatBreakDown = item.id === 2 ? true : false
    this.showIndividualBreakDown = item.id === 3 ? true : false
    this.showMonthBreakDown = item.id === 4 ? true : false
    console.log(item);
    console.log(item.id);
    console.log(this.showMonthBreakDown);
    
    console.dir(this.allBudgetsForReviewData);
    let selectedBudget = this.allBudgetsForReviewData.length > 1 ? this.allBudgetsForReviewData[item.counta-1] : this.allBudgetsForReviewData[0]
    console.dir(selectedBudget);
    // console.dir(Object.entries(selectedBudget));
    console.log(typeof(selectedBudget));
    console.dir(selectedBudget.budgetDetails);
    // console.log(typeof(Object.entries(selectedBudget)));
    // if (item.)
    const budgetBreakdown = selectedBudget.budgetDetails.filter((rec) => {
     return rec.budgetCycleId === item.budgetCycleId && 
      rec.budgetCaption === item.budgetCaption
    });
    this.budgetBreakDown = budgetBreakdown;
    console.log(budgetBreakdown);
    this.benchMarkValue = budgetBreakdown[0].benchMarkValue;
    this.budgetCreator = budgetBreakdown[0].createdBy;
    this.budgetDescription = `${budgetBreakdown[0].budgetCaption} - ${budgetBreakdown[0].endorsementTypeName}`;
    if (item.id === 4){ 
      const NAMES_OF_MONTH = ["January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
      ];
      budgetBreakdown.forEach(d => {
        d.nameOfMonth = NAMES_OF_MONTH[d.month-1];
      })
    }
    let totalValue = 0;
    let percentTotal = 0
    budgetBreakdown.forEach(d => {
      totalValue += d.totalValue;
      percentTotal += d.percentageBenchMarkValue
    })
    this.selectedBudgetDetail = budgetBreakdown;
    this.calculatedPercentageValue = percentTotal
    this.calculatedTotalValue = totalValue
    console.log(this.selectedBudgetDetail)

  }
  closeDetails(item) {
    console.log(`1toggle`);
    this.showBudgetBreakDown = !this.showBudgetBreakDown;
    this.showRegionBreakDown = item.id === 1 ? true : false
    this.showProdCatBreakDown = item.id === 2 ? true : false
    this.showIndividualBreakDown = item.id === 3 ? true : false
    this.showMonthBreakDown = item.id === 4 ? true : false
    // if (this.showBudgetBreakDown === false) {
    //   this.showRegionBreakDown = false
    //   this.showProdCatBreakDown = false
    //   this.showIndividualBreakDown = false
    //   this.showMonthBreakDown = false
    // }
  }

  passBudget(){ 
    this.confirmationService.confirm({
      message: `Proceed with passing all records?`,
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        // this.messageService.add({ severity: 'info', summary: 'Confirmed', detail: 'You have accepted' });
        this.displayMessage("info", "Information", "Processing operation")
        this.passReviewedBudget();
        
      },
      reject: (type: ConfirmEventType) => {
          switch (type) {
              case ConfirmEventType.REJECT:
                  this.displayMessage("warn","Cancelled","You have cancelled this operation");
                  break;
              case ConfirmEventType.CANCEL:
                  this.displayMessage("warn","Cancelled","You have cancelled this operation");
                  break;
          }
        }
    });
    
  }

  passReviewedBudget() {
    this.displayMessage("info", "Information", "Processing...")
    console.dir(this.allBudgetsForReviewData)
    console.dir(this.budgetBreakDown);
    let masterIds: any[] = []
    this.budgetsForReview2.forEach(r => {
      Array.from(r.distributions).forEach(x => { masterIds.push(x.masterId)})
    })

    let budgetCycleIds: any[]=[];
    this.budgetsForReview2.forEach(r => {
      Array.from(r.distributions).forEach(x => { budgetCycleIds.push(x.budgetCycleId)})
    })
    console.dir(masterIds)
    console.dir(budgetCycleIds)
    //callAPI for Updating (Passing or Returning Budget)
    let param = {
      'distributionCategoryId': this.budgetBreakDown[0].id,
      'departmentOfficeId': this.budgetBreakDown[0].reviewingOfficeId,
      'masterId': this.budgetBreakDown[0].masterId,
      'masterIdsList': masterIds,
      'budgetCycleIdsList': budgetCycleIds,
      'budgetCycleId': this.budgetBreakDown[0].budgetCycleId,
      'reviewStatusId': 1,
      'comment': this.reviewerComment,
      'processedById': this.budgetBreakDown[0].reviewerId,
      'createdById': this.budgetBreakDown[0].createdById,
      'department': 'Reviewal'
    }

    console.log(param)
    console.dir(param)
    
    // return;
    this.loadingData = true;
    this.apiService.processBudget(param).subscribe(res => {
      if (res['responseCode'] === "00") {
        setTimeout(() => {
          this.displayMessage("success", "Success", "Operation was successful");
          this.showBudgetBreakDown = false;
          this.getAllBudgetsForReview();
        }, 2000);
        

      }
      else {
        setTimeout(() => {
          this.displayMessage("error", "Error", "Error Processing record");
          this.loadingData = false;
        }, 1000);
      }
    })
  }

  returnBudget(){ 
    console.log('returning');
    this.confirmationService.confirm({
      message: `Proceed with returning budget?`,
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        // this.messageService.add({ severity: 'info', summary: 'Confirmed', detail: 'You have accepted' });
        // console.log(this.budgetByRegions.length)
        this.showCommentDialog = true;
        // this.displayMessage("info", "Information", "Processing operation")
        // this.showBudgetBreakDown = false;
        // this.returnReviewedBudget();
        // this.budgetByRegions.splice(this.budgetByRegions.indexOf(item), 1);
        // console.log(this.budgetByRegions.length)
       
      },
      reject: (type: ConfirmEventType) => {
          switch (type) {
              case ConfirmEventType.REJECT:
                  this.displayMessage("warn","Cancelled","You have cancelled this operation");
                  break;
              case ConfirmEventType.CANCEL:
                  this.displayMessage("warn","Cancelled","You have cancelled this operation");
                  break;
          }
        }
    });
  }

  processReturn()
  {
    console.log(`Proceesing returned budget ${this.budgetReviewForm.value}`)
    console.log(`Comment ${this.reviewerComment}`)
    this.showCommentDialog = false;
    this.displayMessage("info", "Information", "Processing operation")
      this.showBudgetBreakDown = false;
    this.returnReviewedBudget();
  }
  returnReviewedBudget() {
    this.displayMessage("info", "Information", "Processing...")
    console.dir(this.allBudgetsForReviewData)
    console.dir(this.budgetBreakDown);
    //callAPI for Updating (Passing or Returning Budget)
    let masterIds: any[] = []
    this.budgetsForReview2.forEach(r => {
      Array.from(r.distributions).forEach(x => { masterIds.push(x.masterId)})
    })

    let budgetCycleIds: any[]=[];
    this.budgetsForReview2.forEach(r => {
      Array.from(r.distributions).forEach(x => { budgetCycleIds.push(x.budgetCycleId)})
    })
    console.dir(masterIds)
    console.dir(budgetCycleIds)
    let param = {
      'distributionCategoryId': this.budgetBreakDown[0].id,
      'departmentOfficeId': this.budgetBreakDown[0].reviewingOfficeId,
      'masterId': this.budgetBreakDown[0].masterId,
      'masterIdsList': masterIds,
      'budgetCycleIdsList': budgetCycleIds,
      'budgetCycleId': this.budgetBreakDown[0].budgetCycleId,
      'budgetEndorsementName': this.budgetDescription,
      'reviewStatusId': 2,
      'comment': this.reviewerComment,
      'processedById': this.budgetBreakDown[0].reviewerId,
      'createdById': this.budgetBreakDown[0].createdById,
      'department': 'Reviewal'
    }
    console.log(param);
    this.loadingData = true;
    this.apiService.processBudget(param).subscribe(res => {
      if (res['responseCode'] === "00") {
        this.displayMessage("success", "Success", "Operation was successful");
        this.getAllBudgetsForReview();

      }
    })
  }

  commentOnBudget() {
    console.log(`Comment`)
  }

 
  displayMessage(severity, summary, message) {
    this.messageService.add({
      // key: 'sideNotification',
      severity: severity,
      summary: summary,
      detail: message,
    });
  }

}
