import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MessageService, ConfirmationService } from "primeng/api";
import { BreadcrumbService } from "src/app/breadcrumb.service";
import {
  CreateExpenseLineVM,
  ExpenseLine,
  ExpenseLineType,
  UpdateExpenseLineVM,
} from "src/app/interfaces/finance-expense";
import {
  Account,
  ControlAccount,
  Division,
  ServiceCategory,
} from "src/app/interfaces/home";
import { DivisionService } from "src/app/services/division.service";
import { FinanceExpenseService } from "src/app/services/finance-expense.service";
import { ServiceCategoryService } from "src/app/services/service-category.service";

@Component({
  selector: "app-expense-line-setup",
  templateUrl: "./expense-line-setup.component.html",
  styleUrls: ["./expense-line-setup.component.scss"],
})
export class ExpenseLineSetupComponent implements OnInit {
  @ViewChild("formWrapper") public formWrapper: ElementRef;
  expenseForm: FormGroup;
  allExpenseLineTypes: { key: number; value: string }[] = [
    {
      key: ExpenseLineType.CostOfSales,
      value: "Cost Of Sales",
    },
    {
      key: ExpenseLineType.Overhead,
      value: "Overhead",
    },
  ];
  theExpenseLineType: { key: number; value: string };
  allAccounts: ControlAccount[];
  allAccountsToShow: Account[] = [];
  theAccount: Account;
  allDivisions: Division[];
  selectedDivisions: Division[] = [];
  allServiceCategories: ServiceCategory[];
  selectedServiceCategories: ServiceCategory[] = [];
  editingExpenseLineSetup: boolean;
  fetchingExpenseLineSetups: boolean;
  allExpenseLines: ExpenseLine[];
  selectedExpenseLines: ExpenseLine[];
  expenseLineCols: any[];
  expenseLineToEdit: ExpenseLine;
  theDivision: Division;

  constructor(
    fb: FormBuilder,
    public financeExpenseService: FinanceExpenseService,
    public serviceCategoryService: ServiceCategoryService,
    public divisionService: DivisionService,
    public messageService: MessageService,
    private breadcrumbService: BreadcrumbService,
    public confirmationService: ConfirmationService
  ) {
    this.expenseForm = fb.group({
      Caption: ["", Validators.required],
      Description: ["", Validators.required],
      LineType: ["", Validators.required],
      AccountMap: ["", Validators.required],
      Alias: [""],
      Division: ["", Validators.required],
      ServiceCategory: ["", Validators.required],
    });
  }

  ngOnInit(): void {
    this.breadcrumbService.setItems([
      {
        label: "Finance",
        routerLink: ["/home/finance"],
      },
      {
        label: "Expense Tool",
        routerLink: ["/home/finance/expense"],
      },
      {
        label: "Expense Line Setup",
        routerLink: ["/home/finance/expense/line-setup"],
      },
    ]);

    this.expenseLineCols = [
      { field: "caption", header: "Caption" },
      { field: "description", header: "Description" },
      { field: "createdAt", header: "Date Created" },
    ];

    this.GetAllDivisions();
    this.GetAllServiceCategories();
    this.GetAllExpenseAccounts();
    this.GetAllExpenseLines();
  }

  async GetAllDivisions() {
    this.divisionService.GetDivisions().subscribe(
      async (data) => {
        if (data.responseCode == "00") {
          this.allDivisions = data.responseData;
        } else {
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail: data.responseMsg,
          });
        }
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all divisions at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  async GetAllServiceCategories() {
    this.serviceCategoryService.GetAllServiceCategories().subscribe(
      async (data) => {
        if (data.responseCode == "00") {
          this.allServiceCategories = data.responseData;
        } else {
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail: data.responseMsg,
          });
        }
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all service categories at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  async GetAllExpenseAccounts() {
    this.financeExpenseService.GetExpenseLineAccounts().subscribe(
      async (data) => {
        if (data.responseCode == "00") {
          this.allAccounts = data.responseData;
          this.allAccounts.forEach((x) =>
            x.accounts.forEach(
              (y) => (y.label = y.accountNumber + "-" + y.name)
            )
          );
        } else {
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail: data.responseMsg,
          });
        }
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all expense accounts at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  async GetAllExpenseLines() {
    this.fetchingExpenseLineSetups = true;
    this.financeExpenseService.GetAllExpenseLines().subscribe(
      async (data) => {
        if (data.responseCode == "00") {
          this.allExpenseLines = data.responseData;
        } else {
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail: data.responseMsg,
          });
        }
        this.fetchingExpenseLineSetups = false;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all expense lines at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
        this.fetchingExpenseLineSetups = false;
      }
    );
  }

  CreateExpenseLine() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Creating Expense Line...",
    });

    const postData: CreateExpenseLineVM = {
      caption: this.expenseForm.get("Caption").value,
      description: this.expenseForm.get("Description").value,
      type: this.theExpenseLineType.key,
      accountId: this.theAccount.id,
      accountNumber: this.theAccount.accountNumber,
      alias: this.expenseForm.get("Alias").value,
      divisionId: this.theDivision.id,
      // divisionIds: this.selectedDivisions.map((x) => x.id),
      serviceCategoryIds: this.selectedServiceCategories.map((x) => x.id),
    };

    this.financeExpenseService.CreateExpenseLine(postData).subscribe(
      async (data) => {
        if (data.responseCode != "00") {
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail: data.responseMsg,
          });
          return;
        }

        this.messageService.add({
          severity: "success",
          summary: "Completed",
          detail: "Expense Line Created Successfully!",
        });

        this.GetAllExpenseLines();
        this.CloseEditingExpenseLine();
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to create expense line at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  OnLineTypeChange() {
    this.allAccountsToShow = [];
    this.theAccount = null;
    if (this.theExpenseLineType) {
      let controlAccts = this.allAccounts.filter((x) =>
        x.caption
          .toLowerCase()
          .includes(this.theExpenseLineType.value.toLowerCase())
      );
      controlAccts.forEach((x) => this.allAccountsToShow.push(...x.accounts));
    }
  }

  CloseEditingExpenseLine() {
    this.editingExpenseLineSetup = false;
    this.expenseForm.reset();
    this.allAccountsToShow = [];
    this.theAccount = null;
    this.selectedDivisions = [];
    this.selectedServiceCategories = [];
    this.expenseLineToEdit = null;
    this.theDivision = null;
  }

  UpdateExpenseLine() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Updating Expense Line...",
    });

    const id = this.expenseLineToEdit.id;
    const postData: UpdateExpenseLineVM = {
      caption: this.expenseForm.get("Caption").value,
      description: this.expenseForm.get("Description").value,
      type: this.theExpenseLineType.key,
      accountId: this.theAccount.id,
      accountNumber: this.theAccount.accountNumber,
      alias: this.expenseForm.get("Alias").value,
      divisionId: this.theDivision.id,
      // divisionIds: this.selectedDivisions.map((x) => x.id),
      serviceCategoryIds: this.selectedServiceCategories.map((x) => x.id),
    };

    this.financeExpenseService.UpdateExpenseLine(id, postData).subscribe(
      async (data) => {
        if (data.responseCode != "00") {
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail: data.responseMsg,
          });
          return;
        }

        this.messageService.add({
          severity: "success",
          summary: "Completed",
          detail: "Expense Line Updated Successfully!",
        });

        this.GetAllExpenseLines();
        this.CloseEditingExpenseLine();
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to update expense line at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  GetLineType(identifier: number): string {
    let lineType = this.allExpenseLineTypes.find((x) => x.key == identifier);
    if (lineType) return lineType.value;

    return "N/A";
  }

  DeleteExpenseLine(item: ExpenseLine) {
    this.confirmationService.confirm({
      message: "Are you sure you want to remove expense line?",
      accept: () => {
        this.messageService.add({
          severity: "info",
          summary: "Notice",
          detail: "Removing expense line...",
        });

        this.financeExpenseService.DeleteExpenseLine(item.id).subscribe(
          async () => {
            await this.messageService.add({
              severity: "success",
              summary: "Removed",
              detail: "Removed successfully",
            });
            this.CloseEditingExpenseLine();
            this.GetAllExpenseLines();
          },
          (error) => {
            console.log("Error: " + JSON.stringify(error));
            this.messageService.add({
              severity: "error",
              summary: "Notice",
              detail:
                "Unable to remove expense line at the moment.. Reason: [" +
                error
                  ? error.error.message
                  : "request failed - permission" + "]",
            });
          }
        );
      },
    });
  }

  EditExpenseLine(item: ExpenseLine) {
    this.editingExpenseLineSetup = true;
    this.expenseForm.patchValue({
      Caption: item.caption,
      Description: item.description,
      Alias: item.alias,
    });
    this.theExpenseLineType = this.allExpenseLineTypes.find(
      (x) => x.key == item.type
    );
    this.OnLineTypeChange();
    this.theAccount = this.allAccountsToShow.find(
      (x) => x.id == item.accountId
    );
    this.theDivision = this.allDivisions.find((x) => x.id == item.divisionId);
    // this.selectedDivisions = this.allDivisions.filter(
    //   (x) => item.divisions.find((y) => y.divisionId == x.id) != null
    // );
    this.selectedServiceCategories = this.allServiceCategories.filter(
      (x) => item.categories.find((y) => y.serviceCategoryId == x.id) != null
    );
    this.expenseLineToEdit = item;

    this.formWrapper.nativeElement.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "start",
    });
  }

  GetDivisionLabel(divisionId?: number): string {
    if (divisionId) {
      let division = this.allDivisions.find((x) => x.id == divisionId);
      if (division) return division.name;
    }

    return "N/A";
  }
}
