import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {take, takeUntil} from 'rxjs/operators';
import {Observable, Subject} from 'rxjs';
import {
  ArmedEscortProfile,
  CommanderUserProfile, CommanderVehicleInterface,
  OtherPassenger,
  ServiceAssignment, ServiceVehicleInterface,
  Supplier,
  SupplierServices
} from '../../../../interfaces/armada';
import {ServiceAssignmentService} from '../../../../services/armada/service-assignment.service';
import {ConfirmationService, MessageService} from 'primeng/api';
import {CommanderRegistrationService} from '../../../../services/armada/commander-registration.service';
import {BreadcrumbService} from '../../../../breadcrumb.service';
import {CustomerDivisionService} from '../../../../services/armada/customer-division.service';
import {ContractService, CustomerDivision} from '../../../../interfaces/home';
import {DialogService, DynamicDialogRef} from 'primeng/dynamicdialog';
import {ViewDivisionComponent} from './view-division/view-division.component';
import {ViewContractServiceComponent} from './view-contract-service/view-contract-service.component';
import {SupplierService} from '../../../../services/armada/supplier.service';
import {SupplierServicesService} from '../../../../services/armada/supplier-services.service';
import {FormBuilder, FormGroup} from '@angular/forms';
import {PassengerComponent} from './passenger/passenger.component';
import {ArmedEscortProfileService} from '../../../../services/armada/armed-escort-profile.service';
import {JourneyManagementPlanComponent} from './journey-management-plan/journey-management-plan.component';
import {Router} from '@angular/router';

@Component({
  selector: 'app-service-request',
  templateUrl: './service-request.component.html',
  styleUrls: ['./service-request.component.scss'],
  providers: [ConfirmationService, MessageService],
})
export class ServiceRequestComponent implements OnInit, OnDestroy {
  public serviceAssignmentForm: FormGroup;
  @ViewChild('formWrapper') public formWrapper:ElementRef;
  private unsubscriber$ = new Subject<void>();
  public serviceAssignments: ServiceAssignment[];
  public customerDivisions: CustomerDivision[];
  assigningTask: boolean;
  loading: boolean;
  selectedValue: string;
  public commanders: CommanderUserProfile[];
  selectedCommanders: CommanderUserProfile[];
  activeCommanderName = 'Selected a Commander';
  public activeCommander: CommanderUserProfile;
  public activeVehicle: SupplierServices;
  public suppliers: Supplier[];
  public supplierServices: SupplierServices[];
  public availableSupplierServices: SupplierServices[];
  public selectedVehicles: SupplierServices[];
  selectedMake: Supplier;
  public commanderVehicle: CommanderVehicleInterface[] = [];
  public selectedModel: SupplierServices;
  canSubmitAssignment: boolean;
  submitting: boolean;
  public edContractService: ContractService;
  public serviceAssignmentStatus: string;
  public serviceVehicles: ServiceVehicleInterface[] = [];
  private passengerDialogRef: DynamicDialogRef;
  public passengers: OtherPassenger[];
  public availableCommanders: CommanderUserProfile[] = [];
  private availableArmedEscort: ArmedEscortProfile[] = [];
  public armedEscortProfiles: ArmedEscortProfile[] =[];
  public activeServiceVehicle: ServiceVehicleInterface;
  constructor(
      private serviceAssignmentService: ServiceAssignmentService,
      private messageService: MessageService,
      private confirmationService: ConfirmationService,
      private commanderRegistrationService: CommanderRegistrationService,
      private breadcrumbService: BreadcrumbService,
      private customerDivisionService: CustomerDivisionService,
      private dialogService: DialogService,
      private formBuilder: FormBuilder,
      private supplierService: SupplierService,
      private supplierServicesService: SupplierServicesService,
      private armedEscortService: ArmedEscortProfileService,
      private router: Router
  ){
    this.breadcrumbService.setItems([
      { label: 'Secured Mobility Administration' },
      { label: 'Service Request Assignment' }
    ])
  }

  ngOnInit(): void {
    this.selectedValue = 'unassigned';
    this.fetchAllCustomerDivision();
    this.fetchAllServiceAssignment();
    this.fetchCommanders();
    this.fetchSupplier();
    this.fetchArmedEscort();
    // this.fetchSupplierServices();
    this.fetchUnAssignedVehicle();
    this.serviceAssignmentForm = this.formBuilder.group({
      serviceName: [{value: '', disabled: true}],
      serviceId: [{value: '', disabled: true}],
      make: [{value: '', disabled: true}],
      model: [{value: '', disabled: true}],
      commanderId: [''],
    });
    this.serviceAssignmentForm.disable();
  }
  fetchAllCustomerDivision() {
    this.loading = true;
    let customerService: Observable<any> = null;
    switch (this.selectedValue) {
      case 'assigned':
        customerService = this.customerDivisionService.allWithAssignedSecureMobility();
        break;
      case 'unassigned':
        customerService = this.customerDivisionService.allWithUnAssignedSecureMobility();
        break;
      default:
        customerService = this.customerDivisionService.allWithSecureMobility();
    }
    this.serviceAssignmentStatus = this.selectedValue;
    customerService.pipe(takeUntil(this.unsubscriber$))
        .subscribe((res: any)=>{
          this.loading = false;
          this.customerDivisions = res;
        }, error => {
          this.connectionError();
          this.loading = false;
        });
  }
  fetchSupplier(): void {
    this.serviceAssignmentService.getSuppliersWithUnAssignedVehicles().pipe(takeUntil(this.unsubscriber$))
        .subscribe((result: any) => {
          this.suppliers = result;
        }, error => {
          this.connectionError();
        });
  }
  setSupplierServices() {
    this.supplierServices = this.selectedMake.supplierServices;
  }
  public fetchUnAssignedVehicle() {
    // this.serviceAssignmentService.getUnAssignedVehicles()
    //     .pipe(takeUntil(this.unsubscriber$))
    //     .subscribe((res: any) => {
    //       this.supplierServices = res;
    //       this.supplierServices.forEach(xd => xd.vehicleName = `${xd.serviceName} - ${xd.identificationNumber}`);
    //     }, error => this.connectionError());
  }

  fetchAllServiceAssignment() {
    this.loading = true;
    this.serviceAssignmentService.allServiceAssignment().pipe(takeUntil(this.unsubscriber$)).subscribe( (res: any) => {
      this.serviceAssignments = res;
      this.loading = false;
    }, error => {
      this.connectionError();
      this.loading = false;
    });
  }
  fetchCommanders(){
    this.commanderRegistrationService.getUnAssignedCommanders().pipe(takeUntil(this.unsubscriber$))
        .subscribe((res: any) => {
          this.commanders = res;
          this.commanders.forEach(commander => commander.fullName = `${commander.firstName} ${commander.lastName}`);
          this.availableCommanders = this.commanders;
        }, error => {
          this.connectionError();
        })
  }
  fetchArmedEscort() {
    this.armedEscortService.getUnAssignedEscorts().pipe(takeUntil(this.unsubscriber$))
        .subscribe((res: any) => {
          this.armedEscortProfiles = res;
          this.armedEscortProfiles.forEach(xc => xc.fullName = `${xc.firstName} ${xc.lastName}`);
          this.availableArmedEscort = this.armedEscortProfiles;
        }, error => {
          this.connectionError();
        })
  }
  viewMore(division: CustomerDivision) {
    this.dialogService.open(ViewDivisionComponent, {
      header: 'Client Division Information',
      closable: true,
      width: '900px',
      data: {division}
    })
  }

  viewContractService(serviceId: number) {
    this.dialogService.open(ViewContractServiceComponent, {
      header: 'Contract Service',
      width: '98%',
      data: {serviceId}
    })
  }
  private connectionError() {
    this.messageService.add({severity:'error', summary: 'Failed',
      detail:'Connection Error, Please try again'});
  }
  ngOnDestroy(): void {
    this.unsubscriber$.complete();
    this.unsubscriber$.unsubscribe();
  }

  activateCommander(commander: CommanderUserProfile) {
    this.activeCommander = commander;
    const _makeControl = this.serviceAssignmentForm.get('make');
    const _modelControl = this.serviceAssignmentForm.get('model');
    this.activeCommanderName = this.activeCommander.firstName + this.activeCommander.lastName;
    _makeControl.enable();
    _modelControl.enable();
    const _commanderVehicleIndex = this.commanderHasVehicle();
    if(_commanderVehicleIndex >=0) {
      const _commanderVehicle = this.commanderVehicle;
      this.selectedMake = this.suppliers.find(_supplier => _supplier.id === _commanderVehicle[_commanderVehicleIndex].vehicle.supplierId);
      this.selectedModel = this.supplierServices.find(_supplierService => _supplierService.id === _commanderVehicle[_commanderVehicleIndex].vehicle.id);
    } else {
      this.selectedMake = null;
      this.selectedModel = null;
    }
  }

  addVehicle() {
    const _commander = this.activeCommander;
    const _vehicle = this.serviceAssignmentForm.get('model').value;
    const commanderVehicleIndex = this.commanderHasVehicle();
    if(commanderVehicleIndex >= 0) {
      this.commanderVehicle[commanderVehicleIndex] = { commander: _commander, vehicle: _vehicle}
    } else {
      this.commanderVehicle.push({
        commander: _commander,
        vehicle: _vehicle
      })
    }
    this.messageService.add({
      severity: 'success',
      summary: 'Commander updated',
      detail: 'Vehicle assigned to '+_commander.firstName + ' '+ _commander.lastName,
    });
  }
  commanderHasVehicle(commander?: CommanderUserProfile) {
    const _commander = commander ?? this.activeCommander;
    return this.commanderVehicle.findIndex(commanderVehicle => commanderVehicle.commander.id === _commander.id);
  }
  vehicleHasCommander(vehicle?: SupplierServices) {
    if(!vehicle) {
      return false;
    }
    return this.serviceVehicles.findIndex(serviceVehicle => serviceVehicle.vehicle.id=== vehicle.id && serviceVehicle.commanders.length >= 1);
  }
  // todo; remove selected commander from list upon selection
  pickCommander() {
    if(!this.selectedCommanders) {
     return false;
    }
    this.selectedCommanders.forEach(commander => {
      const commanderIndex = this.commanders.findIndex(_commander => _commander.id === commander.id);
      if(commanderIndex) {
        // n
      }
    })
  }

  setVehicleMake() {
    this.availableSupplierServices = this.selectedMake.supplierServices;
    this.availableSupplierServices.forEach(xd => xd.vehicleName = `${xd.serviceName} - ${xd.identificationNumber}`);
    // this.availableSupplierServices = this.supplierServices.filter(
    //     supplierService => supplierService.supplierId === this.serviceAssignmentForm.get('make').value.id);
  }

  assignCommander(contractService: ContractService): void {
    this.formWrapper.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'start' });
    this.edContractService = contractService;
    this.serviceAssignmentForm.reset();
    this.serviceAssignmentForm.enable();
    this.serviceAssignmentForm.setValue({
      serviceName: this.edContractService.service.name,
      serviceId: this.edContractService.id,
      make: '',
      model: '',
      commanderId: '',
    });
    this.selectedCommanders = [];
    this.selectedVehicles = [];
    this.selectedModel = null;
    this.selectedMake = null;
    this.commanderVehicle = [];
    this.serviceVehicles = [];
  }

  createServiceAssignment() {
    this.submitting = true;
    if(this.serviceVehicles.length <= 0) {
      this.messageService.add({
        severity: 'error',
        summary: 'Error detected',
        detail: 'Add one or more vehicle to the Assignment',
      });
      this.submitting = false;
      return false;
    }
    if(this.serviceAssignmentForm.invalid) {
      this.messageService.add({
        severity: 'error',
        summary: 'Error Detected',
        detail: 'Provide all the required information.'
      });
      this.submitting = false;
      return false;
    }
    const _formData = this.serviceAssignmentForm.value;
    const _supplierServices = [];
    let _vehicleDontHaveCommander = false;
    this.serviceVehicles.forEach( (value, index) => {
      const _selectedCommanders = value.commanders;
      const _selectedVehicle = value.vehicle;
      const _selectedPassenger = value.passengers;
      const _selectedArmedEscort = value.armedEscort;
      const _commanders = [];
      const _vehicles = [];
      const _passengers = [];
      const _armedEscorts = [];
      if(_selectedCommanders.length > 0){
        _selectedCommanders.forEach(com => _commanders.push(com.id));
      } else {
        _vehicleDontHaveCommander = true;
      }
      if(_selectedPassenger.length > 0) {
        _selectedPassenger.forEach(pas => _passengers.push(pas));
      }
      if (_selectedArmedEscort.length > 0){
       _selectedArmedEscort.forEach(armed => _armedEscorts.push(armed.id));
      }
      _supplierServices.push({
        commanderUserProfileIds: _commanders,
        armedEscortProfileIds: _armedEscorts,
        otherPassengerIds: _passengers,
        id: _selectedVehicle.id,
      })
    })
    if(_vehicleDontHaveCommander) {
      this.messageService.add({
        severity: 'error',
        summary: 'Error Detected',
        detail: 'Add Commander to all Selected Vehicle',
      });
      this.submitting = false;
      return false;
    }
    const _data = {
      contractServiceId: this.edContractService.id,
        remark: _formData.remark??'',
      supplierServices: _supplierServices,
    }
    this.serviceAssignmentService.postServiceAssignment(_data).pipe(takeUntil(this.unsubscriber$))
        .subscribe((res: any) => {
          this.submitting = false;
          this.serviceAssignmentForm.disable();
          this.messageService.add({
            severity: 'success',
            summary: 'completed',
            detail: 'Contract Service Assignment completed',
          });
          this.fetchArmedEscort();
          this.fetchCommanders();
          this.fetchSupplier();
          this.fetchAllCustomerDivision();
          this.activeServiceVehicle = null;
          this.activeVehicle = null;
          this.edContractService = null;
          this.serviceAssignmentForm.reset();
          this.serviceVehicles = [];
          this.selectedVehicles = [];
        }, error => {
          this.submitting = false;
          this.connectionError();
        })
  }
  assignAble(): boolean {
    return this.serviceAssignmentStatus !== 'unassigned';
  }

  vehicleWasSelected(vehicle?: SupplierServices) {
    const _vehicle = vehicle ?? this.activeVehicle;
    return this.selectedVehicles.findIndex(serviceVehicle => serviceVehicle.id === _vehicle.id);
  }

  addNewVehicle() {
    if(!this.selectedModel) {
      return false;
    }
    const _vehicle = this.selectedModel;
    if(this.vehicleWasSelected(_vehicle) < 0) {
      this.selectedVehicles.push(_vehicle);
      return true;
    }
    this.messageService.add({
      severity: 'error',
      detail: 'The vehicle has been selected',
      summary: 'Action Aborted',
    });
    return false;
  }

  activateVehicle(vehicle: SupplierServices) {
    this.activeVehicle = vehicle;
    const _activeVehicleIndex = this.serviceVehicles.findIndex(serviceV => serviceV.vehicle.id === vehicle.id);
    if(_activeVehicleIndex < 0) {
      this.serviceVehicles.push( {
        commanders: [],
        vehicle: this.activeVehicle,
        armedEscort: [],
        passengers: [],
      });
      this.activeServiceVehicle = this.serviceVehicles[this.serviceVehicles.length-1];
    } else {
      this.activeServiceVehicle = this.serviceVehicles[_activeVehicleIndex];
    }
  }

  openCommanderDialog(vehicle?: SupplierServices) {
    const _activeVehicleIndex = this.serviceVehicles.findIndex(serviceV => serviceV.vehicle.id === vehicle.id);
    this.passengerDialogRef = this.dialogService.open(PassengerComponent, {
      header: 'Update vehicle',
      width: '80%',
      data: {contractService: this.edContractService,
        serviceVehicle: this.serviceVehicles[_activeVehicleIndex],
        availableCommanders: this.availableCommanders,
        availableArmedEscort: this.availableArmedEscort,
      }
    })
    this.passengerDialogRef.onClose.pipe(takeUntil(this.unsubscriber$)).
    subscribe((res: ServiceVehicleInterface) => {
      if(res) {
        const vehicleServiceIndex = this.serviceVehicles.findIndex(serviceV => serviceV.vehicle.id === vehicle.id);
        if(vehicleServiceIndex < 0) {
          this.serviceVehicles.push(res);
        } else{
          this.serviceVehicles[vehicleServiceIndex] = res;
        }
        this.activateVehicle(this.activeServiceVehicle.vehicle);
      }
    })
  }

  viewJMP(contractService: ContractService) {
    this.dialogService.open( JourneyManagementPlanComponent, {
     showHeader: false,
      width: '780px',
      data: {contractService}
    })
  }

    navigateTo(s: string): void {
       this.router.navigate([s]);
    }
}
