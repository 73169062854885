import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import {
  CommonResponse,
  ServiceCategoryTask,
  ServiceTaskDeliverable,
} from "../../../interfaces/home";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { ConfirmationService, MessageService } from "primeng/api";
import { ServiceTaskDeliverableService } from "../../../services/service-task-deliverable.service";
import { ServiceCategoryTaskService } from "../../../services/service-category-task.service";
import { BreadcrumbService } from "src/app/breadcrumb.service";

@Component({
  selector: "app-service-task-deliverable",
  templateUrl: "./service-task-deliverable.component.html",
  styleUrls: ["./service-task-deliverable.component.scss"],
  providers: [ConfirmationService, MessageService],
})
export class ServiceTaskDeliverableComponent implements OnInit {
  @ViewChild("formWrapper") public formWrapper: ElementRef;
  serviceCategories: ServiceCategoryTask[];
  selectedServiceCategoryTaskId: ServiceCategoryTask = null;
  fetchingServiceTaskDeliverable = true;
  serviceTaskDeliverableForm: FormGroup;
  serviceTaskDeliverables: ServiceTaskDeliverable[] = [];
  selectedServiceTaskDeliverable: ServiceTaskDeliverable;
  editingServiceTaskDeliverable = false;
  public serviceTaskDeliverableCols: any[];
  public exportServiceTaskDeliverableColumns: any[];
  private edServiceTaskDeliverable: ServiceTaskDeliverable;
  constructor(
    private formBuilder: FormBuilder,
    private serviceTaskDeliverableService: ServiceTaskDeliverableService,
    public messageService: MessageService,
    public confirmationService: ConfirmationService,
    private serviceCategoryTaskService: ServiceCategoryTaskService,
    private breadcrumbService: BreadcrumbService
  ) {}

  ngOnInit(): void {
    this.breadcrumbService.setItems([
      { label: "Setup", routerLink: ["home/service-task-deliverable"] },
      { label: "Services", routerLink: ["home/service-task-deliverable"] },
      {
        label: "Service Task Deliverable",
        routerLink: ["home/service-task-deliverable"],
      },
    ]);
    this.fetchServiceCategories();
    this.fetchServiceTaskDeliverable();
    this.serviceTaskDeliverableForm = this.formBuilder.group({
      description: ["", Validators.required],
      caption: ["", [Validators.required, Validators.minLength(3)]],
      serviceCategoryTaskId: ["", [Validators.required]],
    });
    this.serviceTaskDeliverableCols = [
      { field: "caption", header: "Caption" },
      { field: "description", header: "Description" },
      { field: "serviceCategoryTask", header: "Service Category Task" },
    ];
    this.exportServiceTaskDeliverableColumns =
      this.serviceTaskDeliverableCols.map((col) => ({
        title: col.header,
        dataKey: col.field,
      }));
  }

  async fetchServiceCategories() {
    this.serviceCategoryTaskService
      .allServiceCategoryTask()
      .subscribe((result: CommonResponse) => {
        this.serviceCategories = result.responseData;
      });
  }

  fetchServiceTaskDeliverable() {
    this.serviceTaskDeliverableService.allData().subscribe(
      async (serviceTaskDeliverable: CommonResponse) => {
        this.serviceTaskDeliverables = serviceTaskDeliverable.responseData;
        this.fetchingServiceTaskDeliverable = false;
      },
      (error) => {
        this.fetchingServiceTaskDeliverable = false;
        this.connectionError();
      }
    );
  }

  private connectionError() {
    this.messageService.add({
      severity: "error",
      summary: "Failed",
      detail: "Connection Error, Please try again",
    });
  }

  createServiceTaskDeliverable() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Creating new Service Category Task Task Records",
    });
    const postData = {
      description: this.serviceTaskDeliverableForm.get("description").value,
      caption: this.serviceTaskDeliverableForm.get("caption").value,
      serviceCategoryTaskId: this.selectedServiceCategoryTaskId.id,
    };
    this.serviceTaskDeliverableService
      .postServiceTaskDeliverable(postData)
      .subscribe(
        async () => {
          await this.messageService.add({
            severity: "success",
            summary: "Completed",
            detail: "New Service Category Task Task Created",
          });
          await this.fetchServiceTaskDeliverable();
          await this.serviceTaskDeliverableForm.reset();
        },
        (error) => {
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail:
              "Unable to create new Service Category Task Task at the moment",
          });
        }
      );
  }

  deleteServiceTaskDeliverable(serviceTaskDeliverable) {
    this.confirmationService.confirm({
      message:
        "Are you sure you want to delete <strong>" +
        serviceTaskDeliverable.caption +
        "</strong>",
      accept: () => {
        this._deleteServiceTaskDeliverable(serviceTaskDeliverable);
      },
    });
  }

  _deleteServiceTaskDeliverable(serviceTaskDeliverable) {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Deleting Service Category Task Task record",
    });
    this.serviceTaskDeliverableService
      .deleteServiceTaskDeliverable(serviceTaskDeliverable.id)
      .subscribe(
        async (result: any) => {
          await this.messageService.add({
            severity: "success",
            summary: "Deleted",
            detail: "Service Category Task Task record removed",
          });
          await this.fetchServiceTaskDeliverable();
        },
        (error) => {
          this.connectionError();
        }
      );
  }

  editServiceTaskDeliverable(serviceTaskDeliverables) {
    this.editingServiceTaskDeliverable = true;
    this.serviceTaskDeliverableForm.addControl(
      "serviceTaskDeliverableId",
      new FormControl({ value: "", disabled: true }, Validators.required)
    );
    this.edServiceTaskDeliverable = serviceTaskDeliverables;
    this.serviceTaskDeliverableForm.patchValue({
      caption: serviceTaskDeliverables.caption,
      description: serviceTaskDeliverables.description,
      serviceTaskDeliverableId: serviceTaskDeliverables.id,
    });
    this.selectedServiceCategoryTaskId = this.serviceCategories.find(
      (serviceCategoryTask) =>
        serviceCategoryTask.id ===
        serviceTaskDeliverables.serviceCategoryTask.id
    );
    this.formWrapper.nativeElement.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "start",
    });
  }

  updateServiceTaskDeliverable() {
    // update the selected serviceTaskDeliverable
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Updating Service Category Task Task details",
    });
    const id = this.edServiceTaskDeliverable.id;
    const postData = {
      caption: this.serviceTaskDeliverableForm.get("caption").value,
      description: this.serviceTaskDeliverableForm.get("description").value,
      serviceCategoryTaskId: this.selectedServiceCategoryTaskId.id,
    };
    this.serviceTaskDeliverableService
      .updateServiceTaskDeliverable(id, postData)
      .subscribe(async (result: ServiceTaskDeliverable) => {
        this.messageService.add({
          severity: "success",
          summary: "Notice",
          detail: "Service Category Task Task details Updated",
        });
        await this.fetchServiceTaskDeliverable();
        await this.closeEditing();
      });
  }

  closeEditing() {
    this.editingServiceTaskDeliverable = false;
    this.serviceTaskDeliverableForm.reset();
    this.edServiceTaskDeliverable = null;
  }
}
