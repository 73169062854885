import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { ConfirmationService, MessageService } from "primeng/api";
import { BreadcrumbService } from "src/app/breadcrumb.service";
import {
  ActOnContractExtensionRequestVM,
  ContractExtensionRequest,
  GetPendingCERequestsToApproveView,
} from "src/app/interfaces/contract-extension";
import { CustomerDivision, User } from "src/app/interfaces/home";
import { ContractExtensionService } from "src/app/services/contract-extension.service";
import { CustomerDivisionService } from "src/app/services/customer-division.service";
import { UserService } from "src/app/services/user.service";

@Component({
  selector: "app-contract-extension-approval",
  templateUrl: "./contract-extension-approval.component.html",
  styleUrls: ["./contract-extension-approval.component.scss"],
})
export class ContractExtensionApprovalComponent implements OnInit {
  @ViewChild("formWrapper") public formWrapper: ElementRef;
  fetchingRequests: boolean;
  allPendingRequests: GetPendingCERequestsToApproveView[];
  clientName: string = "GTBANK";
  clientImg: string;
  requesterName: string = "Yusuf Omoshola";
  requesterImg: string;
  clientRequests: ContractExtensionRequest[];
  requestJustification: string = "";
  requestDate: Date = new Date(Date.now());
  requestEvidenceUrls: string[] = [];
  requestComment: string = "";
  allCustomerDivisions: CustomerDivision[];
  clientInView: GetPendingCERequestsToApproveView;
  allUsers: User[];
  requestInView: ContractExtensionRequest;

  constructor(
    public userService: UserService,
    public customerDivisionService: CustomerDivisionService,
    public contractExtensionService: ContractExtensionService,
    private breadcrumbService: BreadcrumbService,
    public confirmationService: ConfirmationService,
    public messageService: MessageService
  ) {}

  ngOnInit(): void {
    this.breadcrumbService.setItems([
      {
        label: "Client Management",
      },
      {
        label: "Contract Extension",
      },
      {
        label: "Approval",
        routerLink: ["/home/client-management/ce-approval"],
      },
    ]);

    this.FetchAllUsers();
    this.FetchAllCustomerDivisions();
  }

  async FetchAllUsers() {
    this.userService.allUser().subscribe(
      async (r) => {
        var data = r.responseData ?? [];
        this.allUsers = [];
        data.forEach((user) => {
          user.fullName = user.lastName + " " + user.firstName;
          this.allUsers.push(user);
        });
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all users at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  async FetchAllCustomerDivisions() {
    this.fetchingRequests = true;
    this.customerDivisionService.allCustomerDivision().subscribe(
      async (data) => {
        if (data.responseCode != "00") {
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail: data.responseMsg,
          });
          this.fetchingRequests = false;
          return;
        }

        this.allCustomerDivisions = data.responseData;
        this.FetchAllPendingRequests();
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all customer divisions the moment.. Reason: [" +
            error.error.message +
            "]",
        });
        this.fetchingRequests = false;
      }
    );
  }

  async FetchAllPendingRequests() {
    this.fetchingRequests = true;
    this.contractExtensionService.GetPendingCERequestsToApprove().subscribe(
      async (data) => {
        if (data.responseCode != "00") {
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail: data.responseMsg,
          });
          return;
        }

        this.allPendingRequests = data.responseData;
        this.fetchingRequests = false;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all pending requests the moment.. Reason: [" +
            error.error.message +
            "]",
        });
        this.fetchingRequests = false;
      }
    );
  }

  GetCustomerDivisionName(customerDivisionId: number): string {
    let customerDivision = this.allCustomerDivisions.find(
      (x) => x.id == customerDivisionId
    );
    if (customerDivision) return customerDivision.divisionName;

    return "N/A";
  }

  GetCustomerDivisionImg(customerDivisionId: number): string {
    let customerDivision = this.allCustomerDivisions.find(
      (x) => x.id == customerDivisionId
    );
    if (customerDivision)
      return customerDivision.logoUrl
        ? customerDivision.logoUrl
        : "assets/no-profile.png";

    return "assets/no-profile.png";
  }

  PickClient(item: GetPendingCERequestsToApproveView) {
    this.clientName = this.GetCustomerDivisionName(item.clientId).toUpperCase();
    this.clientImg = this.GetCustomerDivisionImg(item.clientId);
    this.clientRequests = item.requests;
    this.clientInView = item;
  }

  GetUserName(userId: number): string {
    let user = this.allUsers.find((x) => x.id == userId);
    if (user) return user.fullName;

    return "N/A";
  }

  GetUserImg(userId: number): string {
    let user = this.allUsers.find((x) => x.id == userId);
    if (user) return user.imageUrl ? user.imageUrl : "assets/no-profile.png";

    return "N/A";
  }

  LoadClientRequest(item: ContractExtensionRequest) {
    this.requestJustification = item.justification;
    this.requestDate = item.createdAt;
    this.requesterName = this.GetUserName(item.createdById).toUpperCase();
    this.requestEvidenceUrls = item.evidenceUrls.split(";");
    this.requestInView = item;
  }

  FormatDateString(date: Date): string {
    let dateString = date.toString();

    try {
      // DateTimeFormatOptions
      const date: Date = new Date(dateString);
      const options: Intl.DateTimeFormatOptions = {
        year: "numeric",
        month: "long",
        day: "numeric",
      };
      const formattedDate: string = new Intl.DateTimeFormat(
        "en-US",
        options
      ).format(date);
      dateString = formattedDate;
    } catch (error) {
      console.log(error);
      console.log(date);
      console.log(
        "Error while converting date string " + date + " exception " + error
      );
    }

    return dateString;
  }

  ApproveRequest() {
    this.confirmationService.confirm({
      message:
        "You are about to approve this contract extension request. This is an irreversible action. Do you still wish to proceed?",
      accept: () => {
        this.messageService.add({
          severity: "info",
          summary: "Notice",
          detail: "Approving Contract Extension Request...",
        });

        this.ActOnRequest(true);
      },
    });
  }

  DenyRequest() {
    this.confirmationService.confirm({
      message:
        "You are about to decline this contract extension request. This is an irreversible action. Do you still wish to proceed?",
      accept: () => {
        this.messageService.add({
          severity: "info",
          summary: "Notice",
          detail: "Declining Contract Extension Request...",
        });

        this.ActOnRequest(false);
      },
    });
  }

  ActOnRequest(action: boolean) {
    const postData: ActOnContractExtensionRequestVM = {
      isApproved: action,
      comment: this.requestComment,
      activeSequence: this.GetRequestInViewActiveSequence(),
      contractExtensionId: this.requestInView.id,
    };

    this.contractExtensionService
      .ActOnContractExtensionRequest(postData)
      .subscribe(
        async (data) => {
          if (data.responseCode != "00") {
            this.messageService.add({
              severity: "error",
              summary: "Notice",
              detail: data.responseMsg,
            });
            return;
          }

          this.messageService.add({
            severity: "success",
            summary: "Completed",
            detail: "Operation Successfull!",
          });
          this.ResetView();
        },
        (error) => {
          console.log("Error: " + JSON.stringify(error));
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail:
              "Unable to act on contract extension request at the moment.. Reason: [" +
              error.error.message +
              "]",
          });
        }
      );
  }

  GetRequestInViewActiveSequence(): number {
    if (this.clientInView) {
      let item = this.clientInView.ceSequenceMap.find(
        (x) => x.contractExtensionId == this.requestInView.id
      );
      if (item) return item.activeSequence;
    }

    return 1;
  }

  ResetView() {
    const index = this.clientRequests.indexOf(this.requestInView);
    if (index > -1) {
      this.clientRequests.splice(index, 1);
    }

    this.requestJustification = "";
    this.requestEvidenceUrls = [];
    this.requestDate = new Date(Date.now());
    this.requestComment = "";
    this.requesterName = "";
    this.requestInView = null;
  }
}
