import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MessageService, ConfirmationService, Message } from "primeng/api";
import { BreadcrumbService } from "src/app/breadcrumb.service";
import { DeployedGuard } from "src/app/interfaces/employment";
import {
  CreateExpenseBatchRequestItemVM,
  CreateExpenseBatchRequestVM,
  ExpenseBatchRequest,
  ExpenseBatchRequestComment,
  ExpenseBatchRequestItem,
  ExpenseBatchRequestLogStatus,
  ExpenseLine,
  ExpenseLineBudget,
  UpdateExpenseBatchRequestVM,
  UserExpenseRequestType,
} from "src/app/interfaces/finance-expense";
import {
  CommonResponse,
  CustomerDivision,
  User,
} from "src/app/interfaces/home";
import { Supplier } from "src/app/interfaces/supplier";
import { SMORouteService } from "src/app/services/armada/smoroute.service";
import { CustomerDivisionService } from "src/app/services/customer-division.service";
import { FileStorageService } from "src/app/services/file-storage.service";
import { FinanceExpenseService } from "src/app/services/finance-expense.service";
import { FireBaseAuthService } from "src/app/services/fire-base-auth.service";
import { GmaEmploymentService } from "src/app/services/gma-employment.service";
import { ServiceCategoryService } from "src/app/services/service-category.service";
import { SupplierService } from "src/app/services/supplier.service";
import { UserService } from "src/app/services/user.service";
import domtoimage from "dom-to-image";
import { jsPDF } from "jspdf";

@Component({
  selector: "app-expense-user-request",
  templateUrl: "./expense-user-request.component.html",
  styleUrls: ["./expense-user-request.component.scss"],
  providers: [MessageService],
})
export class ExpenseUserRequestComponent implements OnInit {
  @ViewChild("formWrapper") public formWrapper: ElementRef;
  requestForm: FormGroup;
  requestLineForm: FormGroup;
  msg: Message[] = [];
  msg2: Message[] = [];
  msg3: Message[] = [];
  budgeMsg: Message[] = [];
  allRequestTypes: {
    key: number;
    value: string;
  }[];
  theRequestType: {
    key: number;
    value: string;
  };
  allSuppliers: Supplier[];
  theSupplier: Supplier;
  allUsers: User[];
  theUser: User;
  allClients: CustomerDivision[];
  theClient: CustomerDivision;
  allOperatives: DeployedGuard[];
  theOperative: DeployedGuard;
  uploadedFiles: any[] = [];
  uploadedFiles2: any[] = [];
  userIsYetToClickUploadFile: boolean;
  userIsYetToClickUploadFile2: boolean;
  editing: boolean;
  allUserRequests: ExpenseBatchRequest[];
  cols: any[];
  allExpenseLines: ExpenseLine[];
  theExpenseLine: ExpenseLine;
  isVatCharged: boolean = false;
  openItemsDialogue: boolean;
  batchLineItems: ExpenseBatchRequestItem[];
  selectedBatchLineItems: ExpenseBatchRequestItem[];
  batchItemsCols: any[];
  totalAmount: string = "16000000";
  amount: string = "10000000";
  vat: string = "6000000";
  openMemoDialogue: boolean;
  currentDate: string = new Date(Date.now()).toDateString();
  requester: string = "Requester";
  subject: string = "Subject";
  situation: string =
    "father abraham had many sons father abraham had many sons father abraham had many sonsfather abraham had many sonsfather abraham had many sonsfather abraham had many sonsfather abraham had many sonsfather abraham had many sonsfather abraham had many sonsfather abraham had many sons father abraham had many sons";
  justification: string =
    "father abraham had many sons father abraham had many sons father abraham had many sonsfather abraham had many sonsfather abraham had many sonsfather abraham had many sonsfather abraham had many sonsfather abraham had many sonsfather abraham had many sonsfather abraham had many sons father abraham had many sons";
  batchExpenseLines: ExpenseBatchRequestItem[] = [];
  request: string =
    "In view of the above, kindly approve 1,000,000 (one million naira only) being payment for Bayo before Oct 2022 and Nov 2022";
  batchApprovals: {
    office: string;
    officer: string;
  }[] = [
    {
      office: "Line Manager",
      officer: "Omoshola Yusuf",
    },
    {
      office: "Batch Approval",
      officer: "Omoshola Yusuf",
    },
  ];
  showUplGrid: boolean = true;
  showUplGrid2: boolean = true;
  addingNewItem: boolean;
  currentLoggedUsername: string = "CEO";
  loggedInUser: User;
  batchInView: ExpenseBatchRequest;
  allExpenseLineBudgets: ExpenseLineBudget[];
  showLineBudgetInfo: boolean;
  openCommentSection: boolean;
  loadingComments: boolean;
  comments: ExpenseBatchRequestComment[] = [];
  newBatchComment: string;

  constructor(
    fb: FormBuilder,
    private fileStorageService: FileStorageService,
    public smoRouteService: SMORouteService,
    public supplierService: SupplierService,
    public clientService: CustomerDivisionService,
    public employmentService: GmaEmploymentService,
    public financeExpenseService: FinanceExpenseService,
    public userService: UserService,
    public serviceCategoryService: ServiceCategoryService,
    public messageService: MessageService,
    private breadcrumbService: BreadcrumbService,
    public confirmationService: ConfirmationService,
    private fireBaseAuthService: FireBaseAuthService
  ) {
    this.requestForm = fb.group({
      RequestType: ["", Validators.required],
      Entity: [""],
      Situation: ["", Validators.required],
      Justification: ["", Validators.required],
      Subject: ["", Validators.required],
    });

    this.requestLineForm = fb.group({
      ExpenseLine: ["", Validators.required],
      Description: ["", Validators.required],
      Amount: ["", Validators.required],
      IsVatCharged: ["", Validators.required],
      VatAmount: [""],
    });
  }

  ngOnInit(): void {
    this.breadcrumbService.setItems([
      {
        label: "Finance",
        routerLink: ["/home/finance"],
      },
      {
        label: "Expense Tool",
        routerLink: ["/home/finance/expense"],
      },
      {
        label: "User Request",
        routerLink: ["/home/finance/expense/user-request"],
      },
    ]);

    this.allRequestTypes = [
      {
        key: UserExpenseRequestType.Supplier,
        value: "Supplier",
      },
      {
        key: UserExpenseRequestType.Staff,
        value: "Staff",
      },
      {
        key: UserExpenseRequestType.Customer,
        value: "Customer",
      },
      {
        key: UserExpenseRequestType.Operatives,
        value: "Operative",
      },
      {
        key: UserExpenseRequestType.Miscellanous,
        value: "Miscellanous",
      },
    ];

    this.cols = [
      { field: "subject", header: "Subject" },
      { field: "id", header: "Batch ID" },
      { field: "status", header: "Status" },
    ];

    this.batchItemsCols = [
      { field: "expenseLine", header: "Expense Line" },
      { field: "description", header: "Description" },
      { field: "amount", header: "Amount" },
      { field: "vat", header: "VAT" },
    ];

    this.loggedInUser = this.fireBaseAuthService.authUserProfile;

    this.ResetMessageToasters();
    this.FetchAllClients();
    this.FetchAllSuppliers();
    this.FetchAllUsers();
    this.FetchAllOperatives();
    this.FetchUserBatchRequests();
    this.FetchAllExpenseLines();
    this.FetchAllActiveExpenseBudgets();
  }

  ResetMessageToasters() {
    this.msg = [];
    this.msg.push({
      severity: "info",
      summary: "Notice:",
      detail:
        "You are setup as a requester for the following categories only: #Technology, #Finance, #etc.",
    });

    if (this.batchInView) {
      this.msg2 = [];
      this.msg2.push({
        severity: "info",
        summary: "Notice:",
        detail:
          "Capture expense line for " +
          this.batchInView.subject +
          " for " +
          this.GetRequestTypeCaption(this.batchInView.requestType) +
          " " +
          this.GetEntityName(this.batchInView) +
          " : Batch ID " +
          this.batchInView.id,
      });

      this.msg3 = [];
      this.msg3.push({
        severity: "info",
        summary: "Notice:",
        detail: "Line Items for " + this.batchInView.subject,
      });

      this.budgeMsg = [];
    }
  }

  async FetchAllSuppliers() {
    this.supplierService.getAll().subscribe(
      async (data) => {
        this.allSuppliers = data.responseData;
      },
      (error) => {
        this.messageService.add({
          severity: "error",
          summary: "Failed",
          detail: error ?? "Some errors occurred",
        });
        this.ResetMessageToasters();
      }
    );
  }

  async FetchAllClients() {
    this.clientService.allCustomerDivision().subscribe(
      async (data) => {
        this.allClients = data.responseData;
      },
      (error) => {
        this.messageService.add({
          severity: "error",
          summary: "Failed",
          detail: error ?? "Some errors occurred",
        });
        this.ResetMessageToasters();
      }
    );
  }

  async FetchAllUsers() {
    this.userService.allUser().subscribe(
      async (res) => {
        var data = res.responseData;
        this.allUsers = [];
        data.forEach((user) => {
          user.fullName = user.lastName + " " + user.firstName;
          this.allUsers.push(user);
        });
      },
      (err) => {
        console.log(err);
        this.messageService.add({
          severity: "error",
          summary: "Failed",
          detail: err ?? "Some errors occurred",
        });
        this.ResetMessageToasters();
      }
    );
  }

  async FetchAllOperatives() {
    this.employmentService.GetAllOperativesDataOnly().subscribe(
      async (data) => {
        this.allOperatives = [];
        data.forEach((x) => {
          x.guardName =
            x.jobApplication.personalInformation.lastname +
            " " +
            x.jobApplication.personalInformation.firstname +
            " " +
            x.jobApplication.personalInformation.othername;
          this.allOperatives.push(x);
        });
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all operatives at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
        this.ResetMessageToasters();
      }
    );
  }

  async FetchUserBatchRequests() {
    this.financeExpenseService.GetUserExpenseBatchRequests().subscribe(
      async (data) => {
        if (data.responseCode != "00") {
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail: data.responseMsg,
          });
          this.ResetMessageToasters();
          return;
        }

        this.allUserRequests = data.responseData;
        if (this.allUserRequests.length > 0)
          this.LoadBatchCommentsInfo(this.allUserRequests.map((x) => x.id));
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all operatives at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
        this.ResetMessageToasters();
      }
    );
  }

  async FetchAllExpenseLines() {
    this.financeExpenseService.GetAllExpenseLines().subscribe(
      async (data) => {
        if (data.responseCode != "00") {
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail: data.responseMsg,
          });
          this.ResetMessageToasters();
          return;
        }

        this.allExpenseLines = data.responseData;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all expense lines at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
        this.ResetMessageToasters();
      }
    );
  }

  async FetchAllActiveExpenseBudgets() {
    this.financeExpenseService.GetAllActiveExpenseBudgets().subscribe(
      async (data) => {
        if (data.responseCode != "00") {
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail: data.responseMsg,
          });
          this.ResetMessageToasters();
          return;
        }

        this.allExpenseLineBudgets = data.responseData;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all expense budgets at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
        this.ResetMessageToasters();
      }
    );
  }

  OnRequestTypeChange() {
    this.theClient = null;
    this.theSupplier = null;
    this.theOperative = null;
    this.theUser = null;
  }

  async CreateUserRequest() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Creating Expense Batch Request...",
    });
    this.ResetMessageToasters();

    const postData: CreateExpenseBatchRequestVM = {
      requestType: this.theRequestType.key,
      requestId: 0,
      situation: this.requestForm.get("Situation").value,
      justification: this.requestForm.get("Justification").value,
      attachments: [],
      subject: this.requestForm.get("Subject").value,
    };
    if (this.theRequestType.key == UserExpenseRequestType.Supplier)
      postData.requestId = this.theSupplier.id;
    if (this.theRequestType.key == UserExpenseRequestType.Staff)
      postData.requestId = this.theUser.id;
    if (this.theRequestType.key == UserExpenseRequestType.Customer)
      postData.requestId = this.theClient.id;
    if (this.theRequestType.key == UserExpenseRequestType.Operatives)
      postData.requestId = this.theOperative.id;

    if (this.uploadedFiles.length > 0) {
      this.fileStorageService.UploadMultipleFilesFromDataUrl(
        this.uploadedFiles
      );
      this.fileStorageService.onUploadFinished.subscribe(
        (resp: CommonResponse<string[]>) => {
          if (resp.responseCode != "00") {
            this.messageService.add({
              severity: "error",
              summary: "Notice",
              detail: resp.responseMsg,
            });
            this.ResetMessageToasters();
          } else {
            postData.attachments = resp.responseData;
            this._createUserRequest(postData);
          }
        },
        (error) => {
          console.log("Error while uploading files " + error);
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail: "ERR: Unable to upload file(s) to storage",
          });
          this.ResetMessageToasters();
        }
      );
    } else this._createUserRequest(postData);
  }

  _createUserRequest(postData: CreateExpenseBatchRequestVM) {
    this.financeExpenseService.CreateExpenseBatchRequest(postData).subscribe(
      async (data) => {
        if (data.responseCode != "00") {
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail: data.responseMsg,
          });
          this.ResetMessageToasters();
          return;
        }

        this.messageService.add({
          severity: "success",
          summary: "Completed",
          detail: "Expense Batch Request Created Successfully!",
        });
        this.ResetMessageToasters();

        this.ClearInputs();
        this.FetchUserBatchRequests();
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to create expense batch request at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
        this.ResetMessageToasters();
      }
    );
  }

  onUploadFile(event) {
    this.uploadedFiles = [];
    for (const file of event.files) {
      this.uploadedFiles.push(file);
    }

    this.messageService.add({
      severity: "success",
      summary: "Success",
      detail: "File(s) Uploaded",
    });
    this.ResetMessageToasters();

    this.userIsYetToClickUploadFile = false;
  }

  onCancelUpload() {
    this.uploadedFiles = [];
  }

  NotifyUserToClickUpload() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Kindly ensure you click upload.",
    });
    this.ResetMessageToasters();

    this.userIsYetToClickUploadFile = true;
  }

  async ClearInputs() {
    this.requestForm.reset();
    this.theRequestType = null;
    this.theClient = null;
    this.theSupplier = null;
    this.theOperative = null;
    this.theUser = null;
    this.editing = false;
    this.uploadedFiles = [];
    this.editing = false;
    this.batchInView = null;

    this.showUplGrid = false;
    await new Promise((resolve) => setTimeout(resolve, 2000)); // 2 secs
    this.showUplGrid = true;
  }

  ViewLineItems(item: ExpenseBatchRequest) {
    this.openItemsDialogue = true;
    this.addingNewItem = false;
    let totAmt = 0;
    let amt = 0;
    let vat = 0;

    this.msg3 = [];
    this.msg3.push({
      severity: "info",
      summary: "Notice:",
      detail: "Line Items for " + item.subject,
    });

    item.lineItems.forEach((lineItem) => {
      totAmt += lineItem.amount + lineItem.vatAmount;
      amt += lineItem.amount;
      vat += lineItem.vatAmount;
    });
    this.batchLineItems = item.lineItems;

    this.totalAmount = totAmt.toString();
    this.amount = amt.toString();
    this.vat = vat.toString();
    this.batchInView = item;
    this.ResetMessageToasters();
  }

  AddLineItem(item: ExpenseBatchRequest) {
    this.addingNewItem = true;
    let reqT =
      item.requestType == UserExpenseRequestType.Miscellanous
        ? ""
        : this.allRequestTypes.find((x) => x.key == item.requestType).value;

    this.msg2 = [];
    this.msg2.push({
      severity: "info",
      summary: "Notice:",
      detail:
        "Capture expense line on " +
        item.subject +
        " for " +
        reqT +
        " " +
        this.GetEntityName(item) +
        " : Batch ID BTCH-" +
        item.id,
    });
    this.batchInView = item;
  }

  OpenBatchComments(item: ExpenseBatchRequest) {
    this.openCommentSection = true;
    this.batchInView = item;
    this.addingNewItem = false;
    item.isUnread = false;
    this.LoadBatchComments();
  }

  ViewMemoFormat(item: ExpenseBatchRequest) {
    this.openMemoDialogue = true;
    this.addingNewItem = false;
    this.currentLoggedUsername =
      this.loggedInUser.lastName.toUpperCase() +
      " " +
      this.loggedInUser.firstName.toUpperCase() +
      " " +
      (this.loggedInUser.otherName == null
        ? ""
        : this.loggedInUser.otherName.toUpperCase());

    let requesterInfo = this.allUsers.find((x) => x.id == item.createdById);
    if (requesterInfo) {
      this.requester =
        requesterInfo.lastName.toUpperCase() +
        " " +
        requesterInfo.firstName.toUpperCase() +
        " " +
        (requesterInfo.otherName == null
          ? ""
          : requesterInfo.otherName.toUpperCase());
    } else this.requester = "N/A";
    this.currentDate = item.createdAt.toString().substring(0, 10);
    this.subject = item.subject;
    this.situation = item.situation;
    this.justification = item.justification;
    this.batchExpenseLines = item.lineItems;
    let sumTotal = 0;
    this.batchExpenseLines.forEach((x) => (sumTotal += x.amount + x.vatAmount));
    this.request =
      "In view of the above, kindly approve N" +
      sumTotal.toLocaleString() +
      " (" +
      this.NumInWords(sumTotal) +
      " naira only) being payment for the itemized above to " +
      this.GetEntityName(item) +
      "." +
      ". Request created by " +
      this.GetUsername(item.createdById);
    localStorage.setItem("memoName", item.subject);
    this.batchApprovals = [];
    item.picks
      .filter((x) => x.status == ExpenseBatchRequestLogStatus.Approved)
      .forEach((log) =>
        this.batchApprovals.push({
          office: log.approvingOfficeName,
          officer: this.GetUsername(log.approvingOfficerId),
        })
      );
  }

  SendForApproval(item: ExpenseBatchRequest) {
    this.confirmationService.confirm({
      message:
        "You are about to send this expense batch request for line manager approval. Do you still wish to proceed?",
      accept: () => {
        this.messageService.add({
          severity: "info",
          summary: "Notice",
          detail: "Sending expense batch request for approval...",
        });
        this.ResetMessageToasters();

        if (item.lineItems.length == 0) {
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail: "Expense Batch Request has no line items!",
          });
          this.ResetMessageToasters();
          return;
        }

        this.financeExpenseService
          .SendUserExpenseBatchRequestForApproval(item.id)
          .subscribe(
            async () => {
              await this.messageService.add({
                severity: "success",
                summary: "Removed",
                detail: "Expense Batch Request sent for approval successfully",
              });
              this.ResetMessageToasters();

              const index = this.allUserRequests.indexOf(item);
              if (index > -1) {
                this.allUserRequests.splice(index, 1);
              }
              this.addingNewItem = false;
              this.ClearLineInputs();
              this.ClearInputs();
              this.FetchUserBatchRequests();
            },
            (error) => {
              console.log("Error: " + JSON.stringify(error));
              this.messageService.add({
                severity: "error",
                summary: "Notice",
                detail:
                  "Unable to send expense batch request for approval at the moment.. Reason: [" +
                  error
                    ? error.error.message
                    : "request failed - permission" + "]",
              });
              this.ResetMessageToasters();
            }
          );
      },
    });
  }

  EditBatch(item: ExpenseBatchRequest) {
    this.editing = true;
    this.requestForm.patchValue({
      Situation: item.situation,
      Justification: item.justification,
      Subject: item.subject,
    });
    this.theRequestType = this.allRequestTypes.find(
      (x) => x.key == item.requestType
    );
    this.theClient = this.allClients.find((x) => x.id == item.clientId);
    this.theSupplier = this.allSuppliers.find((x) => x.id == item.supplierId);
    this.theUser = this.allUsers.find((x) => x.id == item.staffId);
    this.theOperative = this.allOperatives.find(
      (x) => x.id == item.operativeId
    );
    this.batchInView = item;
    this.addingNewItem = false;

    this.formWrapper.nativeElement.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "start",
    });
  }

  DeleteBatch(item: ExpenseBatchRequest) {
    this.confirmationService.confirm({
      message:
        "Are you sure you want to remove this expense batch. This is an irreversible action, Do you still wish to proceed?",
      accept: () => {
        this.messageService.add({
          severity: "info",
          summary: "Notice",
          detail: "Removing expense batch...",
        });
        this.ResetMessageToasters();

        this.financeExpenseService.DeleteExpenseBatchRequest(item.id).subscribe(
          async () => {
            await this.messageService.add({
              severity: "success",
              summary: "Removed",
              detail: "Removed successfully",
            });
            this.ResetMessageToasters();

            const index = this.allUserRequests.indexOf(item);
            if (index > -1) {
              this.allUserRequests.splice(index, 1);
            }
            this.ClearInputs();
            this.FetchUserBatchRequests();
          },
          (error) => {
            console.log("Error: " + JSON.stringify(error));
            this.messageService.add({
              severity: "error",
              summary: "Notice",
              detail:
                "Unable to remove expense line at the moment.. Reason: [" +
                error
                  ? error.error.message
                  : "request failed - permission" + "]",
            });
            this.ResetMessageToasters();
          }
        );
      },
    });
  }

  async CreateBatchExpenseLine() {
    this.showLineBudgetInfo = false;
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Capturing Expense Line...",
    });
    this.ResetMessageToasters();

    const postData: CreateExpenseBatchRequestItemVM = {
      expenseBatchRequestId: this.batchInView.id,
      expenseLineId: this.theExpenseLine.id,
      description: this.requestLineForm.get("Description").value,
      attachments: [],
      amount: this.requestLineForm.get("Amount").value,
      isVatCharged: this.isVatCharged,
      vatAmount: this.isVatCharged
        ? this.requestLineForm.get("VatAmount").value
        : 0,
    };

    if (
      postData.amount + postData.vatAmount >
      this.theExpenseLine.availableAmount
    ) {
      this.messageService.add({
        severity: "error",
        summary: "Notice",
        detail:
          "Expense Line Amount must not be greater than available amount ",
      });
      this.ResetMessageToasters();
      return;
    }

    if (postData.vatAmount > postData.amount) {
      this.messageService.add({
        severity: "error",
        summary: "Notice",
        detail: "Expense Line Vat must not be greater than amount ",
      });
      this.ResetMessageToasters();
      return;
    }

    let sumItUp = 0;
    this.batchInView.lineItems
      .filter((x) => x.expenseLineId == this.theExpenseLine.id)
      .forEach((x) => (sumItUp += x.amount + x.vatAmount));
    sumItUp += postData.amount + postData.vatAmount;
    if (sumItUp > this.theExpenseLine.availableAmount) {
      this.messageService.add({
        severity: "error",
        summary: "Notice",
        detail:
          "Total Expense Line(s) amount must not be greater than available amount for expense line budget " +
          this.theExpenseLine.caption,
      });
      this.ResetMessageToasters();
      return;
    }

    if (this.uploadedFiles2.length > 0) {
      this.fileStorageService.UploadMultipleFilesFromDataUrl(
        this.uploadedFiles2
      );
      this.fileStorageService.onUploadFinished.subscribe(
        (resp: CommonResponse<string[]>) => {
          if (resp.responseCode != "00") {
            this.messageService.add({
              severity: "error",
              summary: "Notice",
              detail: resp.responseMsg,
            });
            this.ResetMessageToasters();
          } else {
            postData.attachments = resp.responseData;
            this._createBatchExpenseLine(postData);
          }
        },
        (error) => {
          console.log("Error while uploading files " + error);
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail: "ERR: Unable to upload file(s) to storage",
          });
          this.ResetMessageToasters();
        }
      );
    } else this._createBatchExpenseLine(postData);
  }

  _createBatchExpenseLine(postData: CreateExpenseBatchRequestItemVM) {
    this.financeExpenseService
      .CreateExpenseBatchRequestItem(postData)
      .subscribe(
        async (data) => {
          if (data.responseCode != "00") {
            this.messageService.add({
              severity: "error",
              summary: "Notice",
              detail: data.responseMsg,
            });
            this.ResetMessageToasters();
            return;
          }

          this.messageService.add({
            severity: "success",
            summary: "Completed",
            detail: "Expense Line Captured Successfully!",
          });
          this.ResetMessageToasters();

          this.ClearLineInputs();
          this.addingNewItem = false;
          this.FetchUserBatchRequests();
        },
        (error) => {
          console.log("Error: " + JSON.stringify(error));
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail:
              "Unable to capture expense line at the moment.. Reason: [" +
              (error ? error.error.message : "request failed - permission") +
              "]",
          });
          this.ResetMessageToasters();
        }
      );
  }

  OnExpenseLineChange() {
    if (this.theExpenseLine) {
      let budgets = this.allExpenseLineBudgets.filter(
        (x) => x.expenseLineId == this.theExpenseLine.id
      );
      let sumBudget = 0;
      let sumBudgetAvailable = 0;
      budgets.forEach((budget) => {
        sumBudget += budget.budgetAmount;
        sumBudgetAvailable += budget.budgetAmount - budget.budgetAmountUsed;
      });

      this.budgeMsg = [];
      this.budgeMsg.push({
        severity: "info",
        summary: "Budget: ",
        detail:
          "N" +
          sumBudget.toLocaleString() +
          "  Available: N" +
          sumBudgetAvailable.toLocaleString(),
      });
      this.theExpenseLine.availableAmount = sumBudgetAvailable;
      this.showLineBudgetInfo = true;
    } else {
      this.budgeMsg = [];
      this.theExpenseLine.availableAmount = 0;
      this.showLineBudgetInfo = false;
    }
  }

  onUploadFile2(event) {
    this.uploadedFiles2 = [];
    for (const file of event.files) {
      this.uploadedFiles2.push(file);
    }

    this.messageService.add({
      severity: "success",
      summary: "Success",
      detail: "File(s) Uploaded",
    });
    this.ResetMessageToasters();

    this.userIsYetToClickUploadFile2 = false;
  }

  onCancelUpload2() {
    this.uploadedFiles2 = [];
  }

  NotifyUserToClickUpload2() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Kindly ensure you click upload.",
    });
    this.ResetMessageToasters();

    this.userIsYetToClickUploadFile2 = true;
  }

  SwitchIsVatCharged() {
    // this.messageService.add({
    //   severity: "info",
    //   summary: "Notice",
    //   detail:
    //     "Switched to " +
    //     (this.isVatCharged ? "VAT is charged" : "VAT is not charged"),
    // });
    // this.ResetMessageToasters();
  }

  async ClearLineInputs() {
    this.theExpenseLine = null;
    this.requestLineForm.reset();
    this.isVatCharged = false;
    this.uploadedFiles2 = [];

    this.showUplGrid2 = false;
    await new Promise((resolve) => setTimeout(resolve, 2000)); // 2 secs
    this.showUplGrid2 = true;
  }

  HideLineItemDialog() {
    this.openItemsDialogue = false;
  }

  ViewAttachments(item: any) {}

  HideMemoDialog() {
    this.openMemoDialogue = false;
    this.currentLoggedUsername = "";
    this.requester = "";
    this.currentDate = new Date(Date.now()).toDateString();
    this.subject = "";
    this.situation = "";
    this.justification = "";
    this.batchExpenseLines = [];
    this.batchApprovals = [];
    this.request = "";
  }

  GetEntityName(item: ExpenseBatchRequest): string {
    if (item.requestType == UserExpenseRequestType.Customer) {
      let customer = this.allClients.find((x) => item.clientId);
      if (customer) return customer.divisionName;
    } else if (item.requestType == UserExpenseRequestType.Operatives) {
      let op = this.allOperatives.find((x) => x.id == item.operativeId);
      if (op)
        return (
          op.jobApplication.personalInformation.lastname +
          " " +
          op.jobApplication.personalInformation.firstname +
          " " +
          op.jobApplication.personalInformation.othername
        );
    } else if (item.requestType == UserExpenseRequestType.Staff) {
      return this.GetUsername(item.staffId);
    } else if (item.requestType == UserExpenseRequestType.Supplier) {
      let suppl = this.allSuppliers.find((x) => x.id == item.supplierId);
      if (suppl) return suppl.supplierName;
    } else if (item.requestType == UserExpenseRequestType.Miscellanous) {
      return "Miscellaneous";
    }

    return "N/A";
  }

  GetExpenseLineCaptopm(identifier: number): string {
    let expenseLine = this.allExpenseLines.find((x) => x.id == identifier);
    if (expenseLine) return expenseLine.caption;

    return "N/A";
  }

  RemoveLineItem(item: ExpenseBatchRequestItem) {
    this.confirmationService.confirm({
      message:
        "Are you sure you want to remove expense line item. This is an irreversible action, Do you still wish to proceed?",
      accept: () => {
        this.messageService.add({
          severity: "info",
          summary: "Notice",
          detail: "Removing expense line item...",
        });
        this.ResetMessageToasters();

        this.financeExpenseService
          .DeleteExpenseBatchRequestItem(item.id)
          .subscribe(
            async () => {
              await this.messageService.add({
                severity: "success",
                summary: "Removed",
                detail: "Removed successfully",
              });
              this.ResetMessageToasters();

              this.totalAmount = (+this.totalAmount - item.amount).toString();
              this.amount = (
                +this.amount -
                (item.amount - item.vatAmount)
              ).toString();
              this.vat = (+this.vat - item.vatAmount).toString();
              const index = this.batchLineItems.indexOf(item);
              if (index > -1) {
                this.batchLineItems.splice(index, 1);
              }
              this.FetchUserBatchRequests();
              this.msg3 = [];
              this.msg3.push({
                severity: "info",
                summary: "Notice:",
                detail: "Line Items for " + this.batchInView.subject,
              });
            },
            (error) => {
              console.log("Error: " + JSON.stringify(error));
              this.messageService.add({
                severity: "error",
                summary: "Notice",
                detail:
                  "Unable to remove expense line at the moment.. Reason: [" +
                  error
                    ? error.error.message
                    : "request failed - permission" + "]",
              });
              this.ResetMessageToasters();
            }
          );
      },
    });
  }

  NumInWords(number: number): string {
    const first = [
      "",
      "one ",
      "two ",
      "three ",
      "four ",
      "five ",
      "six ",
      "seven ",
      "eight ",
      "nine ",
      "ten ",
      "eleven ",
      "twelve ",
      "thirteen ",
      "fourteen ",
      "fifteen ",
      "sixteen ",
      "seventeen ",
      "eighteen ",
      "nineteen ",
    ];
    const tens = [
      "",
      "",
      "twenty",
      "thirty",
      "forty",
      "fifty",
      "sixty",
      "seventy",
      "eighty",
      "ninety",
    ];
    const mad = ["", "thousand", "million", "billion", "trillion"];
    let word = "";

    for (let i = 0; i < mad.length; i++) {
      let tempNumber = number % (100 * Math.pow(1000, i));
      if (Math.floor(tempNumber / Math.pow(1000, i)) !== 0) {
        if (Math.floor(tempNumber / Math.pow(1000, i)) < 20) {
          word =
            first[Math.floor(tempNumber / Math.pow(1000, i))] +
            mad[i] +
            " " +
            word;
        } else {
          word =
            tens[Math.floor(tempNumber / (10 * Math.pow(1000, i)))] +
            "-" +
            first[Math.floor(tempNumber / Math.pow(1000, i)) % 10] +
            mad[i] +
            " " +
            word;
        }
      }

      tempNumber = number % Math.pow(1000, i + 1);
      if (Math.floor(tempNumber / (100 * Math.pow(1000, i))) !== 0)
        word =
          first[Math.floor(tempNumber / (100 * Math.pow(1000, i)))] +
          "hunderd " +
          word;
    }
    return word;
  }

  GetUsername(userId: number): string {
    let sf = this.allUsers.find((x) => x.id == userId);
    if (sf)
      return (
        sf.lastName.toUpperCase() +
        " " +
        sf.firstName.toUpperCase() +
        " " +
        (sf.otherName == null ? "" : sf.otherName.toUpperCase())
      );

    return "N/A";
  }

  async UpdateUserExpenseBatchRequest() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Updating Expense Batch...",
    });

    const id = this.batchInView.id;
    const postData: UpdateExpenseBatchRequestVM = {
      requestType: this.theRequestType.key,
      requestId: 0,
      situation: this.requestForm.get("Situation").value,
      justification: this.requestForm.get("Justification").value,
      attachments: [],
      subject: this.requestForm.get("Subject").value,
    };
    if (this.theRequestType.key == UserExpenseRequestType.Supplier)
      postData.requestId = this.theSupplier.id;
    if (this.theRequestType.key == UserExpenseRequestType.Staff)
      postData.requestId = this.theUser.id;
    if (this.theRequestType.key == UserExpenseRequestType.Customer)
      postData.requestId = this.theClient.id;
    if (this.theRequestType.key == UserExpenseRequestType.Operatives)
      postData.requestId = this.theOperative.id;

    if (this.uploadedFiles.length > 0) {
      this.fileStorageService.UploadMultipleFilesFromDataUrl(
        this.uploadedFiles
      );
      this.fileStorageService.onUploadFinished.subscribe(
        (resp: CommonResponse<string[]>) => {
          if (resp.responseCode != "00") {
            this.messageService.add({
              severity: "error",
              summary: "Notice",
              detail: resp.responseMsg,
            });
            this.ResetMessageToasters();
          } else {
            postData.attachments = resp.responseData;
            this._updateUserExpenseBatchRequest(id, postData);
          }
        },
        (error) => {
          console.log("Error while uploading files " + error);
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail: "ERR: Unable to upload file(s) to storage",
          });
          this.ResetMessageToasters();
        }
      );
    } else this._updateUserExpenseBatchRequest(id, postData);
  }

  _updateUserExpenseBatchRequest(
    id: number,
    postData: UpdateExpenseBatchRequestVM
  ) {
    this.financeExpenseService
      .UpdateExpenseBatchRequest(id, postData)
      .subscribe(
        async (data) => {
          if (data.responseCode != "00") {
            this.messageService.add({
              severity: "error",
              summary: "Notice",
              detail: data.responseMsg,
            });
            this.ResetMessageToasters();
            return;
          }

          this.messageService.add({
            severity: "success",
            summary: "Completed",
            detail: "Expense Batch Request Updated Successfully!",
          });
          this.ResetMessageToasters();

          this.ClearInputs();
          this.FetchUserBatchRequests();
        },
        (error) => {
          console.log("Error: " + JSON.stringify(error));
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail:
              "Unable to update expense batch request at the moment.. Reason: [" +
              (error ? error.error.message : "request failed - permission") +
              "]",
          });
          this.ResetMessageToasters();
        }
      );
  }

  PdfDownloadPage() {
    let memoName = localStorage.getItem("memoName");
    var node = document.getElementById("memoDiv");
    var img;
    var filename;
    var newImage;
    domtoimage
      .toPng(node, { bgcolor: "#fff" })
      .then(function (dataUrl) {
        img = new Image();
        img.src = dataUrl;
        newImage = img.src;
        img.onload = function () {
          var pdfWidth = img.width;
          var pdfHeight = img.height;
          // FileSaver.saveAs(dataUrl, 'my-pdfimage.png'); // Save as Image
          var doc;
          if (pdfWidth > pdfHeight) {
            doc = new jsPDF("l", "px", [pdfWidth, pdfHeight]);
          } else {
            doc = new jsPDF("p", "px", [pdfWidth, pdfHeight]);
          }
          var width = doc.internal.pageSize.getWidth();
          var height = doc.internal.pageSize.getHeight();

          doc.addImage(newImage, "PNG", 10, 10, width, height);
          filename = memoName + " Memo" + ".pdf";
          doc.save(filename);
        };
      })
      .catch(function (error) {
        // Error Handling
      });
  }

  LoadBatchComments() {
    this.loadingComments = true;
    this.financeExpenseService
      .GetExpenseBatchComments(this.batchInView.id)
      .subscribe(
        async (data) => {
          if (data.responseCode != "00") {
            this.messageService.add({
              severity: "error",
              summary: "Notice",
              detail: data.responseMsg,
            });
            this.ResetMessageToasters();
            return;
          }

          this.comments = data.responseData;
          this.loadingComments = false;
        },
        (error) => {
          console.log("Error: " + JSON.stringify(error));
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail:
              "Unable to fetch batch comments at the moment.. Reason: [" +
              error.error.message +
              "]",
          });
          this.loadingComments = false;
        }
      );
  }

  HideCommentBox() {
    this.openCommentSection = false;
    this.comments = [];
    this.loadingComments = false;
    this.batchInView = null;
  }

  PostComment() {
    this.financeExpenseService
      .CreateBatchComment({
        batchId: this.batchInView.id,
        comment: this.newBatchComment,
        commenator: this.GetUsername(this.loggedInUser.id),
      })
      .subscribe(
        async (data) => {
          if (data.responseCode != "00") {
            this.messageService.add({
              severity: "error",
              summary: "Notice",
              detail: data.responseMsg,
            });
            this.ResetMessageToasters();
            return;
          }

          this.messageService.add({
            severity: "success",
            summary: "Completed",
            detail: "Comment Posted Successfully...",
          });
          this.newBatchComment = null;
          this.LoadBatchComments();
          this.ResetMessageToasters();
        },
        (error) => {
          console.log("Error: " + JSON.stringify(error));
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail:
              "Unable to post batch approval comment at the moment.. Reason: [" +
              error.error.message +
              "]",
          });
          this.ResetMessageToasters();
        }
      );
  }

  GetCommentAuthor(authorId?: number): string {
    if (authorId && authorId != this.loggedInUser.id)
      return this.GetUsername(authorId);
    else return "Me";
  }

  DeleteComment(item: ExpenseBatchRequestComment) {
    this.confirmationService.confirm({
      message: "Are you sure you want to remove comment?",
      accept: () => {
        this.messageService.add({
          severity: "info",
          summary: "Notice",
          detail: "Removing Comment...",
        });
        this.ResetMessageToasters();

        this.financeExpenseService.DeleteBatchComment(item.id).subscribe(
          async (data) => {
            if (data.responseCode != "00") {
              this.messageService.add({
                severity: "error",
                summary: "Notice",
                detail: data.responseMsg,
              });
              this.ResetMessageToasters();
              return;
            }

            this.messageService.add({
              severity: "success",
              summary: "Completed",
              detail: "Removed Comment Successfully...",
            });
            this.newBatchComment = null;
            this.LoadBatchComments();
            this.ResetMessageToasters();
          },
          (error) => {
            console.log("Error: " + JSON.stringify(error));
            this.messageService.add({
              severity: "error",
              summary: "Notice",
              detail:
                "Unable to delete batch approval comment at the moment.. Reason: [" +
                error.error.message +
                "]",
            });
            this.ResetMessageToasters();
          }
        );
      },
    });
  }

  LoadBatchCommentsInfo(batchIds: number[]) {
    //Simply return unread as true
    this.financeExpenseService
      .CheckBatchComentReadReceipts({ batchIds: batchIds })
      .subscribe(
        async (data) => {
          if (data.responseCode != "00") {
            this.messageService.add({
              severity: "error",
              summary: "Notice",
              detail: data.responseMsg,
            });
            this.ResetMessageToasters();
            return;
          }

          this.allUserRequests.forEach((batch) => {
            let isUnread = data.responseData.find((x) => x == batch.id);
            if (isUnread) batch.isUnread = true;
          });
        },
        (error) => {
          console.log("Error: " + JSON.stringify(error));
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail:
              "Unable to check batch approval comment receipts at the moment.. Reason: [" +
              error.error.message +
              "]",
          });
          this.ResetMessageToasters();
        }
      );
  }

  GetRequestTypeCaption(requestTypeId: number): string {
    let reqType = this.allRequestTypes.find((x) => x.key == requestTypeId);
    if (reqType) return reqType.value;
    return "N/A";
  }
}
