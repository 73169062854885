import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { MessageService, ConfirmationService, Message } from "primeng/api";
import { BreadcrumbService } from "src/app/breadcrumb.service";
import {
  GetOutstandingApproveesInfoView,
  GetOutstandingApproveesInfoView_V2,
  GetPendingApprovalsBulkViewV2,
  User,
} from "src/app/interfaces/home";
import { ApprovalService } from "src/app/services/approval.service";
import { UserService } from "src/app/services/user.service";

@Component({
  selector: "app-approval-dashboard",
  templateUrl: "./approval-dashboard.component.html",
  styleUrls: ["./approval-dashboard.component.scss"],
  providers: [MessageService],
})
export class ApprovalDashboardComponent implements OnInit {
  @ViewChild("formWrapper") public formWrapper: ElementRef;
  msg: Message[] = [];
  dashboardResults:
    | GetOutstandingApproveesInfoView[]
    | GetOutstandingApproveesInfoView_V2[];
  selectedResult:
    | GetOutstandingApproveesInfoView
    | GetOutstandingApproveesInfoView_V2;
  showDetail: any;
  fetchingDetail: boolean;
  fetchingApprovals: boolean;
  approvalsClientBatch: {
    contractName?: string;
    isParticipant: boolean;
    clientBatches: {
      approvalType: string;
      approvalCount: number;
      approvals: GetPendingApprovalsBulkViewV2[];
    }[];
  }[] = [];
  appClientBatchCols: any[];
  appClientAppTypeCols: any[];
  approvalCols: any[];
  allUsers: User[] = [];
  allPendingApprovalsView: GetPendingApprovalsBulkViewV2[] = [];
  allPendingEndorsementsView: GetPendingApprovalsBulkViewV2[] = [];

  constructor(
    private userService: UserService,
    private approvalService: ApprovalService,
    public messageService: MessageService,
    private breadcrumbService: BreadcrumbService,
    public confirmationService: ConfirmationService
  ) {}

  ngOnInit(): void {
    this.breadcrumbService.setItems([
      {
        label: "Management",
      },
      {
        label: "Approval Dashboard",
        routerLink: ["/home/approval-dashboard"],
      },
    ]);

    this.appClientBatchCols = [
      { field: "clientName", header: "Client Name" },
      { field: "isParticipant", header: "Is Participating" },
    ];
    this.appClientAppTypeCols = [
      { field: "approvalType", header: "Approval Type" },
    ];
    this.approvalCols = [
      { field: "type", header: "Type" },
      { field: "name", header: "Name" },
      { field: "description", header: "Description" },
      { field: "createdAt", header: "Created At" },
      { field: "GetContractIdEndorsementView(id)", header: "Contract Id" },
    ];
    // this.dashboardResults = [
    //   {
    //     responsibleUserId: 4,
    //     numberOfApprovalWaiting: 18,
    //     longestWaitingTime: "3H:28M",
    //   },
    // ];
    this.fetchingApprovals = true;
    this.FetchAllUsers();
    this.ResetMessageToasters();
  }

  ResetMessageToasters() {
    this.msg = [];
    this.msg.push({
      severity: "info",
      summary:
        "This is a dashboard view showing all individuals with pending approvals and stats on the approval pane in highest order.",
    });
  }

  async FetchAllUsers() {
    this.userService.allUser().subscribe(
      async (r) => {
        var data = r.responseData ?? [];
        this.allUsers = data;
        this.FetchAllOustandingApprovalsView_V2();
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all users at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
        this.ResetMessageToasters();
        this.fetchingApprovals = false;
      }
    );
  }

  async FetchAllOustandingApprovalsView() {
    this.fetchingApprovals = true;
    this.approvalService.GetOutstandingApproveesInfo().subscribe(
      async (data) => {
        if (data.responseCode != "00") {
          this.messageService.add({
            severity: "error",
            summary: data.responseMsg,
          });
          this.fetchingApprovals = false;
          this.ResetMessageToasters();
          return;
        }

        this.dashboardResults = data.responseData;
        this.fetchingApprovals = false;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all outstanding  approvees at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
        this.ResetMessageToasters();
      }
    );
  }

  async FetchAllOustandingApprovalsView_V2() {
    this.fetchingApprovals = true;
    this.approvalService.GetOutstandingApproveesInfo_V2().subscribe(
      async (data) => {
        if (data.responseCode != "00") {
          this.messageService.add({
            severity: "error",
            summary: data.responseMsg,
          });
          this.fetchingApprovals = false;
          this.ResetMessageToasters();
          return;
        }

        this.dashboardResults = data.responseData;
        (this.dashboardResults as GetOutstandingApproveesInfoView_V2[]).forEach(
          (x) => (x.userResponsible = this.GetUserName(x.responsibleUserId))
        );
        this.fetchingApprovals = false;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all outstanding  approvees at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
        this.ResetMessageToasters();
        this.fetchingApprovals = false;
      }
    );
  }

  ViewPendingDetails(item: GetOutstandingApproveesInfoView) {
    this.selectedResult = item;
    this.showDetail = true;
    this.fetchingDetail = true;
    this.approvalService
      .GetOutstandingApprovalInfo(item.responsibleUserId)
      .subscribe(
        async (data) => {
          if (data.responseCode != "00") {
            this.messageService.add({
              severity: "error",
              summary: "Notice",
              detail: data.responseMsg,
            });
            return;
          }

          this.allPendingApprovalsView =
            data.responseData as GetPendingApprovalsBulkViewV2[];
          var endorsements = this.allPendingApprovalsView.filter(
            (x) => x.className == "Endorsements"
          );
          this.allPendingEndorsementsView = endorsements;

          this.GroupApprovalsView();
          this.fetchingDetail = false;
        },
        (error) => {
          console.log("Error: " + JSON.stringify(error));
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail:
              "Unable to fetch all pending approvals at the moment.. Reason: [" +
              (error ? error.error.message : "request failed - permission") +
              "]",
          });
          this.fetchingDetail = false;
        }
      );
  }

  ViewPendingDetails_V2(item: GetOutstandingApproveesInfoView_V2) {
    this.selectedResult = item;
    this.showDetail = true;
    this.fetchingDetail = true;
    this.allPendingApprovalsView = item.details;
    var endorsements = this.allPendingApprovalsView.filter(
      (x) => x.className == "Endorsements"
    );
    this.allPendingEndorsementsView = endorsements;
    this.GroupApprovalsView();
    this.fetchingDetail = false;
  }

  async GroupApprovalsView() {
    if (this.allPendingApprovalsView.length > 0) {
      this.fetchingApprovals = true;
      this.approvalsClientBatch = [];
      let groupedByClient = [
        ...new Set(
          this.allPendingApprovalsView.map((approval) => approval.name)
        ),
      ];
      let groupedByServiceType = [
        ...new Set(
          this.allPendingApprovalsView.map((approval) => approval.typeName)
        ),
      ];
      groupedByClient.forEach((grpClient) => {
        let apClBatchs: {
          clientName: string;
          isParticipant: boolean;
          clientBatches: {
            approvalType: string;
            approvalCount: number;
            approvals: GetPendingApprovalsBulkViewV2[];
          }[];
        } = {
          clientName: grpClient,
          isParticipant: false,
          clientBatches: [],
        };
        groupedByServiceType.forEach((grpServiceType) => {
          let allClientApprovalsInType = this.allPendingApprovalsView.filter(
            (x) => x.name == grpClient && x.typeName == grpServiceType
          );

          if (allClientApprovalsInType.length > 0) {
            apClBatchs.clientBatches.push({
              approvalType: grpServiceType,
              approvalCount: allClientApprovalsInType.length,
              approvals: allClientApprovalsInType,
            });
          }
        });
        this.approvalsClientBatch.push(apClBatchs);
      });
      this.fetchingApprovals = false;
    }
  }

  HideDetailDialog() {
    this.showDetail = false;
    this.selectedResult = null;
  }

  GetUserName(userId: number): string {
    let user = this.allUsers.find((x) => x.id == userId);
    if (user) return user.lastName + " " + user.firstName;

    return "N/A";
  }

  GetUserImg(userId: number): string {
    let user = this.allUsers.find((x) => x.id == userId);
    if (user) return user.imageUrl;

    return "assets/no-file.png";
  }
}
