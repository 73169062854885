import { Observable } from "rxjs";
import { CommonResponse } from "./../interfaces/home";
import { environment } from "src/environments/environment";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class SuspectService {
  constructor(private http: HttpClient) {}

  baseUrl = environment.baseUrl + "Suspect/";

  // fetch all Suspect in the database
  allSuspect(): Observable<CommonResponse> {
    return this.http.get<CommonResponse>(this.baseUrl);
  }
  allUserSuspects(): Observable<CommonResponse> {
    return this.http.get<CommonResponse>(this.baseUrl + "GetUserSuspects");
  }

  getSuspect(id: any): Observable<CommonResponse> {
    return this.http.get<CommonResponse>(this.baseUrl + id);
  }
  getSuspectByNames(firstname, lastname): Observable<CommonResponse> {
    return this.http.get<CommonResponse>(
      this.baseUrl + "GetSuspectByNames/" + firstname + "/" + lastname
    );
  }
  getSuspectByBusinessName(businessName: any): Observable<CommonResponse> {
    return this.http.get<CommonResponse>(
      this.baseUrl + "GetSuspectByBusinessName/" + businessName
    );
  } //
  getSuspectByRC(rc: any): Observable<CommonResponse> {
    return this.http.get<CommonResponse>(this.baseUrl + "GetSuspectByRC/" + rc);
  }

  getSuspectByEmail(email: any): Observable<CommonResponse> {
    return this.http.get<CommonResponse>(
      this.baseUrl + "GetSuspectByEmail/" + email
    );
  }

  getSuspectByPhone(phone: any): Observable<CommonResponse> {
    return this.http.get<CommonResponse>(
      this.baseUrl + "GetSuspectByPhone/" + phone
    );
  }
  postSuspect(data: any) {
    return this.http.post(this.baseUrl, data);
  }
  deleteSuspect(id: any) {
    return this.http.delete(this.baseUrl + id);
  }
  updateSuspect(id: any, data: any) {
    return this.http.put(this.baseUrl + id, data);
  }
  convertSuspect(id: number) {
    return this.http.post(this.baseUrl + "ConvertSuspect/" + id, {});
  }
}
