import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ConfirmationService, MessageService } from 'primeng/api';
import { forkJoin, Observable } from 'rxjs';
import { BreadcrumbService } from 'src/app/breadcrumb.service';
import { Branch, CommonResponse, ServicePricing, Services } from 'src/app/interfaces/home';
import { BranchService } from 'src/app/services/branch.service';
import { ServicePricingService } from 'src/app/services/service-pricing.service';
import { ServicesService } from 'src/app/services/services.service';

@Component({
  selector: 'app-service-pricing',
  templateUrl: './service-pricing.component.html',
  styleUrls: ['./service-pricing.component.scss']
})
export class ServicePricingComponent implements OnInit {
  @ViewChild('formWrapper') public formWrapper:ElementRef;
  
  services: Services[];
  selectedService: Services = null;

  branches: Branch[];
  selectedBranch: Branch = null;

  fetchingServicePricings = true;
  servicePricingForm: FormGroup;
  servicePricings: ServicePricing[];
  selectedServicePricing: ServicePricing;
  
  editingServicePricing = false;
  
  public servicePricingCols: any[];
  public exportServicePricingColumns: any[];
  private edServicePricing: ServicePricing;
  
  startUpData$: Observable<[CommonResponse, CommonResponse, CommonResponse]>;

  constructor(
    private formBuilder: FormBuilder,
    private servicesService: ServicesService,
    private servicePricingService: ServicePricingService,
    public messageService: MessageService,
    public confirmationService: ConfirmationService,
    private branchService: BranchService,
    private breadcrumbService: BreadcrumbService
  ) { }

  ngOnInit(): void {
    this.fetchStartupData();

    this.breadcrumbService.setItems([
      { label: 'Setup', routerLink: ['home/service-pricing'] },
      { label: 'Services', routerLink: ['home/service-pricing'] },
      { label: 'Service Pricing', routerLink: ['home/service-pricing'] }
    ]);


    this.servicePricingForm = this.formBuilder.group({
      minimumAmount: ['', [Validators.required, Validators.min(0)]],
      maximumAmount: ['', [Validators.required]],
      service: ['', [Validators.required]],
      branch: ['', [Validators.required]],
    });

    this.servicePricingCols = [
      { field: 'minimumAmount', header: 'Minimum Amount' },
      { field: 'maximumAmount', header: 'Maximum Amount' },
      { field: 'service', header: 'Service' },
      { field: 'branch', header: 'Branch' },
    ];

    this.exportServicePricingColumns = this.servicePricingCols.map(
        col => ({title: col.header, dataKey: col.field}));

  }

  fetchStartupData()
  {
    this.startUpData$ = forkJoin([
      this.servicesService.allService(),
      this.branchService.allBranch(),
      this.servicePricingService.allServicePricingData()
    ]); 

    //check if working
    this.startUpData$.subscribe(async (data) => {
      const [services, branches, servicePricings] = data;
      this.services = services.responseData ?? [] as Services[];
      this.branches = branches.responseData ?? [] as Branch[];
      this.servicePricings = servicePricings.responseData ?? [] as ServicePricing[];
      this.fetchingServicePricings = false;
    }, error => {
      this.fetchingServicePricings = false;
      this.connectionError();
    })
  }

  fetchServicePricings(){
    this.fetchingServicePricings = true;
    this.servicePricingService.allServicePricingData().subscribe(async (res) => {
      this.servicePricings = res.responseData ?? [];
      this.fetchingServicePricings = false;
    }, error => {
      this.fetchingServicePricings = false;
      this.connectionError();
    })
  }

  createServicePricing(){
    this.messageService.add({severity:'info', summary: 'Notice',
      detail:'Creating new Service Pricing Record'});
    const postData = {
      minimumAmount: this.servicePricingForm.get('minimumAmount').value,
      maximumAmount: this.servicePricingForm.get('maximumAmount').value,
      serviceId: this.selectedService.id,
      branchId: this.selectedBranch.id
    };
    this.servicePricingService.postServicePricing(postData).subscribe( async () =>{
      await this.messageService.add({severity:'success', summary: 'Completed',
        detail:'New Service Pricing Created'});
      await this.fetchServicePricings();
      await this.servicePricingForm.reset();
    }, error => {
      this.messageService.add({severity:'error', summary: 'Notice',
        detail:'Unable to create new Service Pricing at the moment'});
    });
  }

  editServicePricing(servicePricing){
    this.editingServicePricing = true;
    this.servicePricingForm.addControl(
        'servicePricingId',  new FormControl({value: '', disabled: true}, Validators.required));
    this.edServicePricing = servicePricing;
    this.servicePricingForm.patchValue({
      minimumAmount: parseInt(servicePricing.minimumAmount),
      maximumAmount: parseInt(servicePricing.maximumAmount),
      servicePricingId: servicePricing.id,
    });
    this.selectedService = this.services.find(x => x.id === servicePricing.serviceId);
    this.selectedBranch = this.branches.find(x => x.id === servicePricing.branchId);
    this.formWrapper.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'start' });
  }

  closeEditing(){
    this.editingServicePricing = false;
    this.servicePricingForm.reset();
    this.edServicePricing = null;
  }

  updateServicePricing(){
    this.messageService.add({severity:'info', summary: 'Notice',
      detail:'Updating Service Pricing details'});
    const id = this.edServicePricing.id;
    const postData = {
      minimumAmount: this.servicePricingForm.get('minimumAmount').value,
      maximumAmount: this.servicePricingForm.get('maximumAmount').value,
      serviceId: this.selectedService.id,
      branchId: this.selectedBranch.id,
    };
    this.servicePricingService.updateServicePricing(id, postData).subscribe(
        async (result: ServicePricing) => {
          this.messageService.add({severity:'success', summary: 'Notice',
            detail:'Service Pricing details Updated'});
          await this.fetchServicePricings();
          await this.closeEditing();
        })
  }

  deleteServicePricing(servicePricing){
    this.confirmationService.confirm({
      message: 'Are you sure you want to delete <strong>' + 'Service Pricing ?' + '</strong>',
      accept: () => {
        this._deleteServicePricing(servicePricing);
      }
    });
  }

  _deleteServicePricing(servicePricing){
    this.messageService.add({severity:'info', summary: 'Notice',
      detail:'Deleting Service Pricing record'});
    this.servicePricingService.deleteServicePricing(servicePricing.id).subscribe(async (result:any) => {
      await this.messageService.add({severity:'success', summary: 'Deleted',
        detail:'Service Pricing record removed'});
      await this.fetchServicePricings();
    }, error => {
      this.connectionError()
    });
  }

  private connectionError() {
    this.messageService.add({severity:'error', summary: 'Failed',
      detail:'Connection Error, Please try again'});
  }

}
