import {
  CreatePayGroupVM,
  PayGroup,
  UpdatePayGroupVM,
} from "./../../../interfaces/payroll";
import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { ConfirmationService, MessageService } from "primeng/api";
import { BreadcrumbService } from "src/app/breadcrumb.service";
import { PayrollService } from "src/app/services/payroll.service";
import { finalize } from "rxjs/operators";
import { FileStorageService } from "src/app/services/file-storage.service";
import { CommonResponse } from "src/app/interfaces/home";

@Component({
  selector: "app-payroll-pay-group",
  templateUrl: "./payroll-pay-group.component.html",
  styleUrls: ["./payroll-pay-group.component.scss"],
})
export class PayrollPayGroupComponent implements OnInit {
  @ViewChild("formWrapper") public formWrapper: ElementRef;
  paygroupForm: FormGroup;
  imageUrl: string;
  editingPayGroup: boolean;
  fetchingPaygrade: boolean = true;
  allPaygroups: PayGroup[];
  selectedPaygroup: PayGroup;
  paygroupCols: any[];
  uploadedItemImage: any[] = [];
  payGroupToEdit: PayGroup;
  imageChanged: boolean = false;

  constructor(
    private fb: FormBuilder,
    private payrollService: PayrollService,
    private breadcrumbService: BreadcrumbService,
    public confirmationService: ConfirmationService,
    public messageService: MessageService,
    private fileStorageService: FileStorageService
  ) {
    this.paygroupForm = fb.group({
      Caption: ["", Validators.required],
      Alias: ["", Validators.required],
      Description: ["", Validators.required],
      TrialCutOff: ["", Validators.required],
      PayrollCutOff: ["", Validators.required],
      MaxNo: ["", Validators.required],
      MinNo: ["", Validators.required],
    });
  }

  ngOnInit(): void {
    this.breadcrumbService.setItems([
      {
        label: "Payroll Management",
        routerLink: ["/home/payroll/payroll-settings"],
      },
      {
        label: "Pay Group",
        routerLink: ["/home/payroll/pay-group"],
      },
    ]);

    this.paygroupCols = [
      { field: "caption", header: "Caption" },
      { field: "description", header: "Description" },
      { field: "alias", header: "Alias" },
    ];

    this.FetchAllPayGroups();
  }

  FetchAllPayGroups() {
    this.payrollService.GetAllPayGroups().subscribe(
      async (data) => {
        this.allPaygroups = data;
        this.fetchingPaygrade = false;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable get all paygroups at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
        this.fetchingPaygrade = false;
      }
    );
  }

  async CreatePayGroup() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Creating Paygroup...",
    });

    const postData: CreatePayGroupVM = {
      caption: this.paygroupForm.get("Caption").value,
      description: this.paygroupForm.get("Description").value,
      alias: this.paygroupForm.get("Alias").value,
      trialCutOffDay: this.paygroupForm.get("TrialCutOff").value,
      payrollCutOffDay: this.paygroupForm.get("PayrollCutOff").value,
      maxNoOfMemebers: this.paygroupForm.get("MaxNo").value,
      minNoOfMemebers: this.paygroupForm.get("MinNo").value,
    };

    if (postData.trialCutOffDay > 28 || postData.payrollCutOffDay > 28) {
      this.messageService.add({
        severity: "error",
        summary: "Notice",
        detail: "Cut-Off Period cannot be greater than 28",
      });
      return;
    }

    if (postData.trialCutOffDay > postData.payrollCutOffDay) {
      this.messageService.add({
        severity: "error",
        summary: "Notice",
        detail:
          "Final Payroll Cut-Off Period cannot be less than Trial Payroll Cut-Off",
      });
      return;
    }

    this.fileStorageService.UploadMultipleFilesFromDataUrl(
      this.uploadedItemImage
    );
    this.fileStorageService.onUploadFinished.subscribe(
      (resp: CommonResponse<string[]>) => {
        if (resp.responseCode != "00") {
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail: resp.responseMsg,
          });
        } else {
          postData.imageUrl = resp.responseData[0];
          this.payrollService.CreatePayGroup(postData).subscribe(
            async () => {
              this.messageService.add({
                severity: "success",
                summary: "Completed",
                detail: "Paygroup Created Successfully!",
              });

              this.paygroupForm.reset();
              this.fetchingPaygrade = true;
              this.imageUrl = null;
              this.imageChanged = false;
              this.FetchAllPayGroups();
            },
            (error) => {
              console.log("Error: " + JSON.stringify(error));
              this.messageService.add({
                severity: "error",
                summary: "Notice",
                detail:
                  "Unable to create paygroup at the moment.. Reason: [" + error
                    ? error.error.message
                    : "request failed - permission" + "]",
              });
            }
          );
        }
      },
      (error) => {
        console.log("Error while uploading files " + error);
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail: "ERR: Unable to upload image to storage",
        });
      }
    );
  }

  CloseEditingPaygroup() {
    this.paygroupForm.reset();
    this.payGroupToEdit = null;
    this.imageChanged = false;
    this.imageUrl = null;
    this.editingPayGroup = false;
  }

  async UpdatePaygroup() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Updating Paygroup...",
    });

    const id = this.payGroupToEdit.id;
    const postData: UpdatePayGroupVM = {
      caption: this.paygroupForm.get("Caption").value,
      description: this.paygroupForm.get("Description").value,
      alias: this.paygroupForm.get("Alias").value,
      trialCutOffDay: this.paygroupForm.get("TrialCutOff").value,
      payrollCutOffDay: this.paygroupForm.get("PayrollCutOff").value,
      maxNoOfMemebers: this.paygroupForm.get("MaxNo").value,
      minNoOfMemebers: this.paygroupForm.get("MinNo").value,
    };

    if (postData.trialCutOffDay > 28 || postData.payrollCutOffDay > 28) {
      this.messageService.add({
        severity: "error",
        summary: "Notice",
        detail: "Cut-Off Period cannot be greater than 28",
      });
      return;
    }

    if (postData.trialCutOffDay > postData.payrollCutOffDay) {
      this.messageService.add({
        severity: "error",
        summary: "Notice",
        detail:
          "Final Payroll Cut-Off Period cannot be less than Trial Payroll Cut-Off",
      });
      return;
    }

    if (this.imageChanged) {
      this.fileStorageService.UploadMultipleFilesFromDataUrl(
        this.uploadedItemImage
      );
      this.fileStorageService.onUploadFinished.subscribe(
        async (resp: CommonResponse<string[]>) => {
          if (resp.responseCode != "00") {
            this.messageService.add({
              severity: "error",
              summary: "Notice",
              detail: resp.responseMsg,
            });
          } else {
            postData.imageUrl = resp.responseData[0];
            await this._updatePayGroup(id, postData);
          }
        },
        (error) => {
          console.log("Error while uploading files " + error);
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail: "ERR: Unable to upload image to storage",
          });
        }
      );
    } else {
      await this._updatePayGroup(id, postData);
    }
  }

  async _updatePayGroup(id: number, postData: UpdatePayGroupVM) {
    this.payrollService.UpdatePayGroup(id, postData).subscribe(
      async () => {
        this.messageService.add({
          severity: "success",
          summary: "Completed",
          detail: "Paygroup Updated Successfully.",
        });

        this.paygroupForm.reset();
        this.imageChanged = false;
        this.imageUrl = null;
        this.editingPayGroup = false;
        this.fetchingPaygrade = true;
        this.payGroupToEdit = null;
        this.FetchAllPayGroups();
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to update paygroup at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  DeletePaygroup(item: PayGroup) {
    this.confirmationService.confirm({
      message: "Are you sure you want to remove paygroup?",
      accept: () => {
        this.messageService.add({
          severity: "info",
          summary: "Notice",
          detail: "Removing paygroup...",
        });

        this.payrollService.DeletePayGroup(item.id).subscribe(
          async () => {
            this.messageService.add({
              severity: "success",
              summary: "Removed",
              detail: "Removed successfully",
            });

            this.fetchingPaygrade = true;
            const index = this.allPaygroups.indexOf(item);
            if (index > -1) {
              this.allPaygroups.splice(index, 1);
            }
            this.fetchingPaygrade = false;
          },
          (error) => {
            console.log("Error: " + JSON.stringify(error));
            this.messageService.add({
              severity: "error",
              summary: "Notice",
              detail:
                "Unable to remove paygroup at the moment.. Reason: [" + error
                  ? error.error.message
                  : "request failed - permission" + "]",
            });
          }
        );
      },
    });
  }

  EditPaygroup(item: PayGroup) {
    this.editingPayGroup = true;
    this.paygroupForm.patchValue({
      Caption: item.caption,
      Alias: item.alias,
      Description: item.description,
      TrialCutOff: item.trialRunCutOffDay,
      PayrollCutOff: item.payrollCutoffDay,
      // PayrollCutOff: item.payrollCutoffDay.toString().substring(0, 10),
      MaxNo: item.maximumNoOfMembers,
      MinNo: item.minimumNoOfMembers,
    });
    this.imageUrl = item.imageUrl;
    this.payGroupToEdit = item;

    this.formWrapper.nativeElement.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "start",
    });
  }

  onUpload(event, form) {
    this.uploadedItemImage = [];
    for (const file of event.files) {
      this.uploadedItemImage.push(file);
    }
    this.imageUrl = this.uploadedItemImage[0].objectURL;
    this.imageChanged = true;

    this.messageService.add({
      severity: "success",
      summary: "Success",
      detail: "Imgage Uploaded Successfully!",
    });
    form.clear();
  }
}
