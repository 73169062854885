import { GmaEmploymentService } from "./../../../services/gma-employment.service";
import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { ConfirmationService, MessageService } from "primeng/api";
import { BreadcrumbService } from "src/app/breadcrumb.service";
import {
  ApplicationRequirement,
  CognitiveRequirement,
  CreateApplicationRequirement,
  CreateApprovingOffice,
  CreateCognitiveRequirement,
  CreatePhysicalRequirement,
  CreateTrainingCourse,
  MeasurementTypeEnum,
  PhysicalRequirement,
  TrainingCourse,
  UpdateApplicationRequirement,
  UpdateCognitiveRequirement,
  UpdatePhysicalRequirement,
  UpdateTrainingCourse,
} from "src/app/interfaces/employment";
import { User } from "src/app/interfaces/home";
import {
  ApprovingOffice,
  ApprovingOfficer,
} from "src/app/interfaces/employment";
import { UserService } from "src/app/services/user.service";

@Component({
  selector: "app-gma-setup",
  templateUrl: "./gma-setup.component.html",
  styleUrls: ["./gma-setup.component.scss"],
})
export class GmaSetupComponent implements OnInit {
  @ViewChild("formWrapper") public formWrapper: ElementRef;
  approvingOfficeForm: FormGroup;
  applicationReqForm: FormGroup;
  physicalReqForm: FormGroup;
  cognitiveReqForm: FormGroup;
  trainingReqForm: FormGroup;
  theApprovingUser: User;
  allUsers: User[];
  theUser: User;
  addedApprovingOfficers: ApprovingOfficer[] = [];
  selectedApprovingOfficer: ApprovingOfficer;
  addedAppOfficerCols: any[];
  editingApprovingOffice: boolean;
  editingApplicationReq: boolean;
  editingPhysicalReq: boolean;
  editingCognitiveReq: boolean;
  editingTrainingReq: boolean;
  approvingOfficeToEdit: ApprovingOffice;
  applicationReqToEdit: ApplicationRequirement;
  physicalReqToEdit: PhysicalRequirement;
  cognitiveReqToEdit: CognitiveRequirement;
  trainingReqToEdit: TrainingCourse;
  fetchingApprovalOffices = true;
  fetchingApplicationRequirements = true;
  fetchingPhysicalRequirements = true;
  fetchingCognitiveRequirements = true;
  fetchingTrainingRequirements = true;
  allApprovingOffice: ApprovingOffice[];
  selectedApprovingOffice: ApprovingOffice;
  approvalOfficeCols: any[];
  applicationReqCols: any[];
  physicalReqCols: any[];
  cognitiveReqCols: any[];
  trainingReqCols: any[];
  allApplicationReqs: ApplicationRequirement[];
  allPhysicalReqs: PhysicalRequirement[];
  allCognitiveReqs: CognitiveRequirement[];
  allTrainingReqs: TrainingCourse[];
  selectedApplicationReq: ApplicationRequirement;
  selectedPhysicalReq: PhysicalRequirement;
  selectedCognitiveReq: CognitiveRequirement;
  selectedTrainingReq: TrainingCourse;
  allMeasurements: {
    value: string;
    key: number;
  }[];
  theApplicationMeasurement: {
    value: string;
    key: number;
  };
  thePhysicalMeasurement: {
    value: string;
    key: number;
  };
  theCognitiveMeasurement: {
    value: string;
    key: number;
  };
  theTrainingMeasurement: {
    value: string;
    key: number;
  };

  constructor(
    private fb: FormBuilder,
    public userService: UserService,
    public employmentService: GmaEmploymentService,
    private breadcrumbService: BreadcrumbService,
    public confirmationService: ConfirmationService,
    public messageService: MessageService
  ) {
    this.approvingOfficeForm = fb.group({
      ApprovingUser: [""],
      OfficeName: ["", Validators.required],
    });

    this.applicationReqForm = fb.group({
      Name: ["", Validators.required],
      FormLink: ["", Validators.required],
      Meaurement: ["", Validators.required],
      Description: ["", Validators.required],
    });

    this.physicalReqForm = fb.group({
      Name: ["", Validators.required],
      Meaurement: ["", Validators.required],
      Description: ["", Validators.required],
    });

    this.cognitiveReqForm = fb.group({
      Name: ["", Validators.required],
      Meaurement: ["", Validators.required],
      Description: ["", Validators.required],
    });

    this.trainingReqForm = fb.group({
      Name: ["", Validators.required],
      Meaurement: ["", Validators.required],
      Description: ["", Validators.required],
    });
  }

  ngOnInit(): void {
    this.breadcrumbService.setItems([
      {
        label: "Guard Management",
        routerLink: ["/home/guard-management/item-settings"],
      },
      {
        label: "Set-Up",
        routerLink: ["/home/guard-management/setup"],
      },
    ]);

    this.addedApprovingOfficers = [];

    this.addedAppOfficerCols = [
      { field: "userProfile.lastName", header: "UserProfile.lastName" },
      { field: "createdAt", header: "CreatedAt" },
    ];
    this.applicationReqCols = [
      { field: "name", header: "Name" },
      { field: "description", header: "Description" },
      { field: "formUrl", header: "FormUrl" },
      { field: "createdAt", header: "CreatedAt" },
    ];
    this.physicalReqCols = [
      { field: "name", header: "Name" },
      { field: "description", header: "Description" },
      { field: "createdAt", header: "CreatedAt" },
    ];
    this.cognitiveReqCols = [
      { field: "name", header: "Name" },
      { field: "description", header: "Description" },
      { field: "createdAt", header: "CreatedAt" },
    ];
    this.trainingReqCols = [
      { field: "name", header: "Name" },
      { field: "courseCode", header: "CourseCode" },
      { field: "description", header: "Description" },
      { field: "createdAt", header: "CreatedAt" },
    ];
    this.allMeasurements = [
      {
        value: "Height",
        key: MeasurementTypeEnum.Height,
      },
      {
        value: "Weight",
        key: MeasurementTypeEnum.Weight,
      },
      {
        value: "Body Mass",
        key: MeasurementTypeEnum.Body_Mass,
      },
      {
        value: "Grade",
        key: MeasurementTypeEnum.Grade,
      },
      {
        value: "Verbal",
        key: MeasurementTypeEnum.Verbal,
      },
      {
        value: "Quantitative",
        key: MeasurementTypeEnum.Quantitative,
      },
      {
        value: "Communication",
        key: MeasurementTypeEnum.Communication,
      },
      {
        value: "MartialArt",
        key: MeasurementTypeEnum.MartialArt,
      },
      {
        value: "SelfDefence",
        key: MeasurementTypeEnum.SelfDefence,
      },
      {
        value: "Greeting",
        key: MeasurementTypeEnum.Greeting,
      },
    ];
    this.approvalOfficeCols = [{ field: "name", header: "Name" }];

    this.FetchAllUsers();
    this.FetchAllApprovingOffice();
    this.FetchAllApplicationRequirement();
    this.FetchAllPhysicalRequirement();
    this.FetchAllCognitiveRequirement();
    this.FetchAllTrainingCourse();
  }

  GetMeasurementLabel(identifier: number): string {
    if (identifier == MeasurementTypeEnum.Height) return "Height";
    if (identifier == MeasurementTypeEnum.Weight) return "Weight";
    if (identifier == MeasurementTypeEnum.Body_Mass) return "Body Mass";
    if (identifier == MeasurementTypeEnum.Grade) return "Grade";
    if (identifier == MeasurementTypeEnum.Verbal) return "Verbal";
    if (identifier == MeasurementTypeEnum.Quantitative) return "Quantitative";
    if (identifier == MeasurementTypeEnum.Communication) return "Communication";
    if (identifier == MeasurementTypeEnum.MartialArt) return "MartialArt";
    if (identifier == MeasurementTypeEnum.SelfDefence) return "SelfDefence";
    if (identifier == MeasurementTypeEnum.Greeting) return "Greeting";
    return "";
  }

  CreateApprovingOffice() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Creating Approval Office...",
    });

    const postData: CreateApprovingOffice = {
      officeName: this.approvingOfficeForm.get("OfficeName").value,
      officersIds: this.addedApprovingOfficers.map((x) => x.userId),
    };

    this.employmentService.CreateApprovingOffice(postData).subscribe(
      async () => {
        await this.messageService.add({
          severity: "success",
          summary: "Completed",
          detail: "Approving Office Created Successfully!",
        });

        this.approvingOfficeForm.reset();
        this.addedApprovingOfficers = [];
        this.FetchAllApprovingOffice();
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to create approving office at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  FetchAllUsers() {
    this.userService.allUser().subscribe(
      async (r) => {
        var data = r.responseData ?? [];
        this.allUsers = [];
        data.forEach((user) => {
          user.fullName = user.lastName + " " + user.firstName;
          this.allUsers.push(user);
        });
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all users at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  FetchAllApprovingOffice() {
    this.employmentService.GetAllApprovingOffice().subscribe(
      async (data) => {
        this.allApprovingOffice = data;
        this.fetchingApprovalOffices = false;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all approving office at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
        this.fetchingApprovalOffices = false;
      }
    );
  }

  FetchAllApplicationRequirement() {
    this.employmentService.GetAllApplicationRequirements().subscribe(
      async (data) => {
        this.allApplicationReqs = data;
        this.fetchingApplicationRequirements = false;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all application requirements at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
        this.fetchingApplicationRequirements = false;
      }
    );
  }

  FetchAllPhysicalRequirement() {
    this.employmentService.GetAllPhysicalRequirements().subscribe(
      async (data) => {
        this.allPhysicalReqs = data;
        this.fetchingPhysicalRequirements = false;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all physical requirements at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
        this.fetchingPhysicalRequirements = false;
      }
    );
  }

  FetchAllCognitiveRequirement() {
    this.employmentService.GetAllCognitiveRequirements().subscribe(
      async (data) => {
        this.allCognitiveReqs = data;
        this.fetchingCognitiveRequirements = false;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all cognitive requirements at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
        this.fetchingCognitiveRequirements = false;
      }
    );
  }

  FetchAllTrainingCourse() {
    this.employmentService.GetAllTrainingCourses().subscribe(
      async (data) => {
        this.allTrainingReqs = data;
        this.fetchingTrainingRequirements = false;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all training course at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
        this.fetchingTrainingRequirements = false;
      }
    );
  }

  get ApprovingUser() {
    return this.approvingOfficeForm.get("ApprovingUser").value;
  }

  AddApprovingOfficerToList() {
    if (!this.ApprovingUser) {
      this.messageService.add({
        severity: "error",
        summary: "Failed",
        detail: "You must select the officer to add",
      });
      return;
    }

    let alreadyAdded = false;
    this.addedApprovingOfficers.forEach((item) => {
      if (item.userId == this.ApprovingUser.id) {
        alreadyAdded = true;
      }
    });

    if (alreadyAdded) {
      this.messageService.add({
        severity: "error",
        summary: "Failed",
        detail: "Officer with the same info has already been added to list.",
      });
      return;
    }

    this.addedApprovingOfficers.push({
      userId: this.ApprovingUser.id,
      user: this.ApprovingUser,
    });

    this.messageService.add({
      severity: "success",
      summary: "Added",
      detail: "Approving Officer added successfully",
    });
  }

  RemoveOfficer(item: ApprovingOfficer) {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Removing Approving Officer...",
    });

    const index = this.addedApprovingOfficers.indexOf(item);
    if (index > -1) {
      this.addedApprovingOfficers.splice(index, 1);
    }

    this.messageService.add({
      severity: "success",
      summary: "Removed",
      detail: "Approving Officer Removed successfully",
    });
  }

  CloseEditingApprovalOffice() {
    this.editingApprovingOffice = false;
    this.approvingOfficeForm.reset();
    this.addedApprovingOfficers = [];
    this.approvingOfficeToEdit = null;
  }

  CloseEditingApplicationReq() {
    this.editingApplicationReq = false;
    this.applicationReqForm.reset();
    this.applicationReqToEdit = null;
  }

  CloseEditingPhyscialReq() {
    this.editingPhysicalReq = false;
    this.physicalReqForm.reset();
    this.physicalReqToEdit = null;
  }

  CloseEditingCognitiveReq() {
    this.editingCognitiveReq = false;
    this.cognitiveReqForm.reset();
    this.cognitiveReqToEdit = null;
  }

  CloseEditingTrainingReq() {
    this.editingTrainingReq = false;
    this.trainingReqForm.reset();
    this.trainingReqToEdit = null;
  }

  UpdateApprovalOffice() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Updating Approval Office...",
    });

    let addedApproveOfficerIds: number[] = [];
    this.addedApprovingOfficers.forEach((officer) => {
      addedApproveOfficerIds.push(officer.userId);
    });

    const id = this.approvingOfficeToEdit.id;
    const postData: CreateApprovingOffice = {
      officeName: this.approvingOfficeForm.get("OfficeName").value,
      officersIds: addedApproveOfficerIds,
    };

    this.employmentService.UpdateApprovingOffice(id, postData).subscribe(
      async () => {
        await this.messageService.add({
          severity: "success",
          summary: "Completed",
          detail: "Approval Office Updated Successfully.",
        });

        this.addedApprovingOfficers = [];
        this.FetchAllApprovingOffice();
        this.editingApprovingOffice = false;
        this.approvingOfficeForm.reset();
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to update approving office at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  DeleteApprovalOffice(item: ApprovingOffice) {
    this.confirmationService.confirm({
      message: "Are you sure you want to remove approving office?",
      accept: () => {
        this.messageService.add({
          severity: "info",
          summary: "Notice",
          detail: "Removing approving office...",
        });

        this.employmentService.DeleteApprovingOffice(item.id).subscribe(
          async () => {
            await this.messageService.add({
              severity: "success",
              summary: "Removed",
              detail: "Removed successfully",
            });
            this.FetchAllApprovingOffice();
          },
          (error) => {
            console.log("Error: " + JSON.stringify(error));
            this.messageService.add({
              severity: "error",
              summary: "Notice",
              detail:
                "Unable to remove approving office at the moment.. Reason: [" +
                error
                  ? error.error.message
                  : "request failed - permission" + "]",
            });
          }
        );
      },
    });
  }

  EditApprovalOffice(item: ApprovingOffice) {
    this.editingApprovingOffice = true;
    this.approvingOfficeForm.patchValue({
      OfficeName: item.name,
    });
    this.approvingOfficeToEdit = item;
    this.addedApprovingOfficers = item.officers.filter(
      (x) => x.isDeleted == false
    );
    this.addedApprovingOfficers.forEach((data) => {
      data.user = this.allUsers.find((appUser) => appUser.id == data.userId);
    });

    this.formWrapper.nativeElement.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "start",
    });
  }

  MoveOfficeSequence(option: boolean, sequence: number) {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Moving Approval Office Sequence...",
    });

    this.employmentService
      .MoveSequence({ sequence: sequence, goesUp: option })
      .subscribe(
        async () => {
          await this.messageService.add({
            severity: "success",
            summary: "Removed",
            detail: "Approving office sequence updated successfully",
          });
          this.FetchAllApprovingOffice();
        },
        (error) => {
          console.log("Error: " + JSON.stringify(error));
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail:
              "Unable to move approving office sequence at the moment.. Reason: [" +
              error
                ? error.error.message
                : "request failed - permission" + "]",
          });
        }
      );
  }

  CreateApplicationRequirement() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Creating Application Requirement...",
    });

    const postData: CreateApplicationRequirement = {
      name: this.applicationReqForm.get("Name").value,
      formLink: this.applicationReqForm.get("FormLink").value,
      measurement: this.theApplicationMeasurement.key,
      description: this.applicationReqForm.get("Description").value,
    };

    this.employmentService.CreateApplicationRequirement(postData).subscribe(
      async () => {
        await this.messageService.add({
          severity: "success",
          summary: "Completed",
          detail: "Application Requirement Created Successfully!",
        });

        this.theApplicationMeasurement = null;
        this.applicationReqForm.reset();
        this.FetchAllApplicationRequirement();
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to create application requirement at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  UpdateApplicationRequirement() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Updating Application Requirement...",
    });

    const id = this.applicationReqToEdit.id;
    const postData: UpdateApplicationRequirement = {
      name: this.applicationReqForm.get("Name").value,
      formLink: this.applicationReqForm.get("FormLink").value,
      measurement: this.theApplicationMeasurement.key,
      description: this.applicationReqForm.get("Description").value,
    };

    this.employmentService.UpdateApplicationRequirement(id, postData).subscribe(
      async () => {
        await this.messageService.add({
          severity: "success",
          summary: "Completed",
          detail: "Application Requirement Updated Successfully!",
        });

        this.theApplicationMeasurement = null;
        this.applicationReqForm.reset();
        this.editingApplicationReq = false;
        this.FetchAllApplicationRequirement();
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to update application requirement at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  DeleteApplicationRequirement(item: ApplicationRequirement) {
    this.confirmationService.confirm({
      message: "Are you sure you want to remove application requirement?",
      accept: () => {
        this.messageService.add({
          severity: "info",
          summary: "Notice",
          detail: "Removing application requirement...",
        });

        this.employmentService.DeleteApplicationRequirement(item.id).subscribe(
          async () => {
            await this.messageService.add({
              severity: "success",
              summary: "Removed",
              detail: "Removed successfully",
            });
            this.FetchAllApplicationRequirement();
          },
          (error) => {
            console.log("Error: " + JSON.stringify(error));
            this.messageService.add({
              severity: "error",
              summary: "Notice",
              detail:
                "Unable to remove application requirement at the moment.. Reason: [" +
                error
                  ? error.error.message
                  : "request failed - permission" + "]",
            });
          }
        );
      },
    });
  }

  EditApplicationRequirement(item: ApplicationRequirement) {
    this.editingApplicationReq = true;
    this.applicationReqForm.patchValue({
      Name: item.name,
      FormLink: item.formUrl,
      Description: item.description,
    });
    this.applicationReqToEdit = item;
    this.theApplicationMeasurement = this.allMeasurements.find(
      (data) => data.key == item.measurement
    );

    this.formWrapper.nativeElement.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "start",
    });
  }

  CreatePhysicalRequirement() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Creating Physical Requirement...",
    });

    const postData: CreatePhysicalRequirement = {
      name: this.physicalReqForm.get("Name").value,
      measurement: this.thePhysicalMeasurement.key,
      description: this.physicalReqForm.get("Description").value,
    };

    this.employmentService.CreatePhysicalRequirement(postData).subscribe(
      async () => {
        await this.messageService.add({
          severity: "success",
          summary: "Completed",
          detail: "Physical Requirement Created Successfully!",
        });

        this.thePhysicalMeasurement = null;
        this.physicalReqForm.reset();
        this.FetchAllPhysicalRequirement();
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to create physical requirement at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  UpdatePhysicalRequirement() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Updating Physical Requirement...",
    });

    const id = this.physicalReqToEdit.id;
    const postData: UpdatePhysicalRequirement = {
      name: this.physicalReqForm.get("Name").value,
      measurement: this.thePhysicalMeasurement.key,
      description: this.physicalReqForm.get("Description").value,
    };

    this.employmentService.UpdatePhysicalRequirement(id, postData).subscribe(
      async () => {
        await this.messageService.add({
          severity: "success",
          summary: "Completed",
          detail: "Physical Requirement Updated Successfully!",
        });

        this.thePhysicalMeasurement = null;
        this.physicalReqForm.reset();
        this.FetchAllPhysicalRequirement();
        this.editingPhysicalReq = false;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to update physical requirement at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  DeletePhysicalRequirement(item: PhysicalRequirement) {
    this.confirmationService.confirm({
      message: "Are you sure you want to remove application requirement?",
      accept: () => {
        this.messageService.add({
          severity: "info",
          summary: "Notice",
          detail: "Removing physical requirement...",
        });

        this.employmentService.DeletePhysicalRequirement(item.id).subscribe(
          async () => {
            await this.messageService.add({
              severity: "success",
              summary: "Removed",
              detail: "Removed successfully",
            });
            this.FetchAllPhysicalRequirement();
          },
          (error) => {
            console.log("Error: " + JSON.stringify(error));
            this.messageService.add({
              severity: "error",
              summary: "Notice",
              detail:
                "Unable to remove physical requirement at the moment.. Reason: [" +
                error
                  ? error.error.message
                  : "request failed - permission" + "]",
            });
          }
        );
      },
    });
  }

  EditPhysicalRequirement(item: PhysicalRequirement) {
    this.editingPhysicalReq = true;
    this.physicalReqForm.patchValue({
      Name: item.name,
      Description: item.description,
    });
    this.physicalReqToEdit = item;
    this.thePhysicalMeasurement = this.allMeasurements.find(
      (data) => data.key == item.measurement
    );

    this.formWrapper.nativeElement.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "start",
    });
  }

  CreateCognitiveRequirement() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Creating Cognitive Requirement...",
    });

    const postData: CreateCognitiveRequirement = {
      name: this.cognitiveReqForm.get("Name").value,
      measurement: this.theCognitiveMeasurement.key,
      description: this.cognitiveReqForm.get("Description").value,
    };

    this.employmentService.CreateCognitiveRequirement(postData).subscribe(
      async () => {
        await this.messageService.add({
          severity: "success",
          summary: "Completed",
          detail: "Cognitive Requirement Created Successfully!",
        });

        this.theCognitiveMeasurement = null;
        this.cognitiveReqForm.reset();
        this.FetchAllCognitiveRequirement();
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to create cognitive requirement at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  UpdateCognitiveRequirement() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Updating Cognitive Requirement...",
    });

    const id = this.cognitiveReqToEdit.id;
    const postData: UpdateCognitiveRequirement = {
      name: this.cognitiveReqForm.get("Name").value,
      measurement: this.theCognitiveMeasurement.key,
      description: this.cognitiveReqForm.get("Description").value,
    };

    this.employmentService.UpdateCognitiveRequirement(id, postData).subscribe(
      async () => {
        await this.messageService.add({
          severity: "success",
          summary: "Completed",
          detail: "Cognitive Requirement Updated Successfully!",
        });

        this.theCognitiveMeasurement = null;
        this.cognitiveReqForm.reset();
        this.FetchAllCognitiveRequirement();
        this.editingCognitiveReq = false;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to update cognitive requirement at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  DeleteCognitiveRequirement(item: CognitiveRequirement) {
    this.confirmationService.confirm({
      message: "Are you sure you want to remove application requirement?",
      accept: () => {
        this.messageService.add({
          severity: "info",
          summary: "Notice",
          detail: "Removing cognitive requirement...",
        });

        this.employmentService.DeleteCognitiveRequirement(item.id).subscribe(
          async () => {
            await this.messageService.add({
              severity: "success",
              summary: "Removed",
              detail: "Removed successfully",
            });
            this.FetchAllCognitiveRequirement();
          },
          (error) => {
            console.log("Error: " + JSON.stringify(error));
            this.messageService.add({
              severity: "error",
              summary: "Notice",
              detail:
                "Unable to remove cognitive requirement at the moment.. Reason: [" +
                error
                  ? error.error.message
                  : "request failed - permission" + "]",
            });
          }
        );
      },
    });
  }

  EditCognitiveRequirement(item: CognitiveRequirement) {
    this.editingCognitiveReq = true;
    this.cognitiveReqForm.patchValue({
      Name: item.name,
      Description: item.description,
    });
    this.cognitiveReqToEdit = item;
    this.theCognitiveMeasurement = this.allMeasurements.find(
      (data) => data.key == item.measurement
    );

    this.formWrapper.nativeElement.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "start",
    });
  }

  CreateTrainingRequirement() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Creating Training Course...",
    });

    const postData: CreateTrainingCourse = {
      name: this.trainingReqForm.get("Name").value,
      measurement: this.theTrainingMeasurement.key,
      description: this.trainingReqForm.get("Description").value,
    };

    this.employmentService.CreateTrainingCourse(postData).subscribe(
      async () => {
        await this.messageService.add({
          severity: "success",
          summary: "Completed",
          detail: "Training Course Created Successfully!",
        });

        this.theTrainingMeasurement = null;
        this.trainingReqForm.reset();
        this.FetchAllTrainingCourse();
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to create training course at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  UpdateTrainingRequirement() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Updating Training Course...",
    });

    const id = this.trainingReqToEdit.id;
    const postData: UpdateTrainingCourse = {
      name: this.trainingReqForm.get("Name").value,
      description: this.trainingReqForm.get("Description").value,
    };

    this.employmentService.UpdateTrainingCourse(id, postData).subscribe(
      async () => {
        await this.messageService.add({
          severity: "success",
          summary: "Completed",
          detail: "Training Course Updated Successfully!",
        });

        this.trainingReqForm.reset();
        this.FetchAllTrainingCourse();
        this.editingTrainingReq = false;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to update training course at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  DeleteTrainingRequirement(item: TrainingCourse) {
    this.confirmationService.confirm({
      message: "Are you sure you want to remove application requirement?",
      accept: () => {
        this.messageService.add({
          severity: "info",
          summary: "Notice",
          detail: "Removing training course...",
        });

        this.employmentService.DeleteTrainingCourse(item.id).subscribe(
          async () => {
            await this.messageService.add({
              severity: "success",
              summary: "Removed",
              detail: "Removed successfully",
            });
            this.FetchAllTrainingCourse();
          },
          (error) => {
            console.log("Error: " + JSON.stringify(error));
            this.messageService.add({
              severity: "error",
              summary: "Notice",
              detail:
                "Unable to remove training course at the moment.. Reason: [" +
                error
                  ? error.error.message
                  : "request failed - permission" + "]",
            });
          }
        );
      },
    });
  }

  EditTrainingRequirement(item: TrainingCourse) {
    this.editingTrainingReq = true;
    this.trainingReqForm.patchValue({
      Name: item.name,
      Description: item.description,
    });
    this.theTrainingMeasurement = this.allMeasurements.find(
      (data) => data.key == item.measurement
    );
    this.trainingReqToEdit = item;

    this.formWrapper.nativeElement.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "start",
    });
  }
}
