import { PayGrade, PayGroup } from "./payroll";
import {
  Branch,
  ContractService,
  GuardLocationDeployment,
  Lga,
  Location,
  State,
  User,
} from "./home";
import { StockBatch } from "./inventory";
import {
  AdditionalInfo,
  BackgroundInfo,
  EducationalInfo,
  JobApplication,
  JobPost,
  MedicalAccountInfo,
  NextOfKinInfo,
  OperativeItem,
  PersonalInfo,
  PrevEmployerInfo,
} from "./premployment";
export const MeasurementTypeEnum = {
  Height: 1,
  Weight: 2,
  Body_Mass: 3,
  Grade: 4,
  Verbal: 5,
  Quantitative: 6,
  Communication: 7,
  MartialArt: 8,
  Greeting: 9,
  SelfDefence: 10,
};
Object.freeze(MeasurementTypeEnum);

export const IndicatoryTypeEnum = {
  GreaterThan: 1,
  EqualsTo: 2,
  LessThan: 3,
  GreaterThanOrEqualsTo: 5,
  LessThanOrEqualsTo: 4,
};
Object.freeze(IndicatoryTypeEnum);

export const EvaluationStageEnum = {
  ApplicationAssessment: 1,
  GeneralAssessment: 2,
  TrainingAssessment: 3,
  PostAssessment: 4,
  Onboarding: 5,
  OngoingMaterialRequest: 6,
  AwaitingDeployment: 7,
  Deployed: 8,
};
Object.freeze(EvaluationStageEnum);

export const BackgroundRequirementTypeEnum = {
  CriminalHistoryConfirmation: 1,
  EducationalCredentialConfirmation: 2,
  PrevEmploymentConfirmation: 3,
  ProfessionalPersonalConfirmation: 4,
};
Object.freeze(BackgroundRequirementTypeEnum);

export const ApplicationRequirementTypeEnum = {
  IsDiabetic: 0,
  HasArthritus: 1,
  IsHypertensive: 2,
  IsGambling: 3,
  IsSmoking: 4,
  IsDrinking: 5,
  IsSufferingUnknownDisease: 6,
  IsExMilitant: 7,
  HasCombatTraining: 8,
  HasBeenArrested: 9,
  HasBeenConvicted: 10,
  HasBeenAskedToResign: 11,
};
Object.freeze(ApplicationRequirementTypeEnum);

export const SupervisionCycleEnum = {
  Weekly: 1,
  BiMonthly: 2,
  Monthly: 3,
  Quartely: 4,
};
Object.freeze(SupervisionCycleEnum);

export const SanctionTypeEnum = {
  Warning: 1,
  Suspension: 2,
  Blacklist: 3,
};
Object.freeze(SanctionTypeEnum);

export const SanctionReasonEnum = {
  Theft: 1,
  Fighting: 2,
  Robbery: 3,
  Fraud: 4,
  Kidnapping: 5,
  AwayWithoutLeave: 6,
  AbuseOfPriviledges: 7,
  AidingAndAbetting: 8,
  Collusion: 9,
  FalseRepresentation: 10,
  Impersonation: 11,
  Truancy: 12,
  GivingMisInformationOrDisInformation: 13,
};
Object.freeze(SanctionReasonEnum);

export const SanctionSeverityLevelEnum = {
  Severity1: 1,
  Severity2: 2,
  Severity3: 3,
};
Object.freeze(SanctionSeverityLevelEnum);

export enum BiometricThumbprintEnum {
  Left_Pinky = 1,
  Left_RingFinger,
  Left_MiddleFinger,
  Left_IndexFinger,
  Left_Thumb,
  Right_Thumb,
  Right_IndexFinger,
  Right_MiddleFinger,
  Right_RingFinger,
  Right_Pinky,
}

export interface CreateApprovingOffice {
  officeName: string;
  officersIds: number[];
}

export interface ApprovingOffice {
  id?: number;
  name?: string;
  sequence?: number;
  officers?: ApprovingOfficer[];
  createdAt?: Date;
}

export interface ApprovingOfficer {
  id?: number;
  userId?: number;
  user?: User;
  dateAdded?: Date;
  approvingOfficeId?: number;
  isDeleted?: boolean;
  createdAt?: Date;
}

export interface CreateApplicationRequirement {
  name: string;
  formLink: string;
  measurement: number;
  description: string;
}

export interface CreatePhysicalRequirement {
  name: string;
  measurement: number;
  description: string;
}

export interface CreateCognitiveRequirement {
  name: string;
  measurement: number;
  description: string;
}

export interface CreateTrainingCourse {
  name: string;
  measurement: number;
  description: string;
}

export interface ApplicationRequirement {
  id?: number;
  name?: string;
  formUrl?: string;
  measurement?: number;
  description?: string;
  createdAt?: Date;
}

export interface PhysicalRequirement {
  id?: number;
  name?: string;
  measurement?: number;
  description?: string;
  createdAt?: Date;
}

export interface CognitiveRequirement {
  id?: number;
  name?: string;
  measurement?: number;
  description?: string;
  createdAt?: Date;
}

export interface TrainingCourse {
  id?: number;
  name?: string;
  measurement?: number;
  description?: string;
  courseCode?: string;
  createdAt?: Date;
}

export interface UpdateApplicationRequirement {
  name?: string;
  formLink?: string;
  measurement?: number;
  description?: string;
}

export interface UpdatePhysicalRequirement {
  name?: string;
  measurement?: number;
  description?: string;
}

export interface UpdateCognitiveRequirement {
  name?: string;
  measurement?: number;
  description?: string;
}

export interface UpdateTrainingCourse {
  name?: string;
  description?: string;
}

export interface CreateTrainingExamination {
  caption: string;
  description: string;
}

export interface CreatePhysicalExamination {
  caption: string;
  description: string;
}

export interface CreateApplicationExamination {
  caption: string;
  description: string;
}

export interface CreateCognitiveExamination {
  caption: string;
  description: string;
}

export interface CreateBackgroundExamination {
  caption: string;
  description: string;
}

export interface Evaluation {
  id?: number;
  caption?: string;
  description?: string;
  type?: number;
  trainingRequirements?: TrainingEvaluationReq[];
  applicationRequirements?: ApplicationEvaluationReq[];
  physicalRequirements?: PhysicalEvaluationReq[];
  cognitiveRequirements?: CognitiveEvaluationReq[];
  backgroundRequirements?: BackgroundEvaluationReq[];
}

export interface TrainingEvaluationReq {
  createdById?: number;
  dateAdded?: Date;
  isThresholdRequired?: boolean;
  thresholdValue?: number;
  indicator?: number;
  course?: TrainingCourse;
  courseId?: number;
  id?: number;
  addedById?: number;
  isDeleted?: boolean;
}

export interface CreateTrainingEvaluationReq {
  courseId: number;
  thresholdRequired: boolean;
  thresholdValue?: number;
  indicator: number;
  evaluationId: number;
}

export interface ApplicationEvaluationReq {
  createdById?: number;
  dateAdded?: Date;
  isThresholdRequired?: boolean;
  thresholdValue?: boolean;
  indicator?: number;
  requirement?: ApplicationRequirement;
  requirementType?: number;
  requirementId?: number;
  id?: number;
  addedById?: number;
  isDeleted?: boolean;
}

export interface PhysicalEvaluationReq {
  createdById?: number;
  gender?: number;
  dateAdded?: Date;
  addedById?: number;
  isThresholdRequired?: boolean;
  thresholdValue?: number;
  indicator?: number;
  requirement?: PhysicalRequirement;
  requirementId?: number;
  id?: number;
  createdAt?: Date;
  isDeleted?: boolean;
}

export interface CognitiveEvaluationReq {
  createdById?: number;
  stateId?: number;
  dateAdded?: Date;
  addedById?: number;
  isThresholdRequired?: boolean;
  thresholdValue?: number;
  indicator?: number;
  requirement?: CognitiveRequirement;
  requirementId?: number;
  id?: number;
  isDeleted?: boolean;
}

export interface BackgroundEvaluationReq {
  createdById?: number;
  dateAdded?: Date;
  isThresholdRequired?: boolean;
  thresholdValue?: boolean;
  indicator?: number;
  requirementType?: number;
  id?: number;
  isDeleted?: boolean;
}

export interface CreatePhysicalEvaluationReq {
  gender: number;
  requirementId: number;
  thresholdRequired: boolean;
  thresholdValue?: number;
  indicator: number;
  evaluationId: number;
}

export interface CreateApplicationEvaluationReq {
  requirementType: number;
  thresholdRequired: boolean;
  thresholdValue: boolean;
  evaluationId: number;
}

export interface CreateCognitiveEvaluationReq {
  stateId: number;
  requirementId: number;
  thresholdRequired: boolean;
  thresholdValue?: boolean;
  indicator: number;
  evaluationId: number;
}

export interface CreateBackgroundEvaluationReq {
  requirementType: number;
  thresholdRequired: boolean;
  thresholdValue: boolean;
  evaluationId: number;
}

export interface ManageJobsPreview {
  job: JobPost;
  totalApplicants: number;
  droppedApplicants: ApplicantEvaluation[];
  applicationAssessmentPercentage: number;
  examinationAssessmentPercentage: number;
  trainingPercentage: number;
  postTrainingPercentage: number;
  onboardingPercentage: number;
  applicantsInApplicationPhase: ApplicantEvaluation[];
  applicantsInGeneralPhase: ApplicantEvaluation[];
  applicantsInTrainingPhase: ApplicantEvaluation[];
  applicantsInPostTrainingPhase: ApplicantEvaluation[];
  applicantsInOnboardingPhase: ApplicantEvaluation[];
  notes: Note[];
}

export interface TrainingSchGetAllJobPost {
  job: JobPost;
  shortlistedApplicants: ApplicantEvaluation[];
}

export interface Note {
  id?: number;
  caption?: string;
  details?: string;
  jobPostId?: number;
  createdById?: number;
  createdBy?: User;
  createdAt?: Date;
}

export interface ApplicantEvaluation {
  createdById?: number;
  background?: BackgroundCheck;
  backgroundId?: number;
  traningResult?: TrainingSchoolResult;
  traningResultId?: number;
  cognitive?: CognitiveAssessment;
  cognitiveId?: number;
  physical?: PhysicalAssessment;
  physicalId?: number;
  currentStage?: number;
  id?: number;
  createdAt?: Date;
  applicantsFullName?: string;
  personalInfo?: PersonalInfo;
  backgroundInfo?: BackgroundInfo;
  nextOfKinInfo?: NextOfKinInfo[];
  educationalInfo?: EducationalInfo[];
  prevEmployerInfo?: PrevEmployerInfo[];
  medicalAccountInfo?: MedicalAccountInfo;
  additionalInfo?: AdditionalInfo;
  jobApplicationId?: number;
  status?: number;
  reason?: string;
  guarantors?: Guarantor[];
  guarantorAssessment?: GuarantorAssessment;
  guarantorAssessmentId?: number;
  guardLocationDeploymentId?: number;
  guardLocationDeployment?: GuardLocationDeployment;
  operativeItems?: ApplicantOperativeItem[];
}

export interface Guarantor {
  id?: number;
  firstname?: string;
  lastname?: string;
  mobile?: string;
  email?: string;
  address?: string;
  occupation?: string;
  guarantorDeclarationDoc?: string;
  dateAdded?: Date;
  createdAt?: Date;
}

export interface BackgroundCheck {
  id?: number;
  supportingDoc?: string;
  createdAt?: Date;
  comment?: string;
  dateAdded?: Date;
  status?: number;
  professionalPersonalHistoryConfirmation?: boolean;
  preemploymentHistoryConfirmation?: boolean;
  educationalHistoryConfirmation?: boolean;
  criminalHistoryConfirmation?: boolean;
}

export interface TrainingSchoolResult {
  id?: number;
  martialArt?: number;
  greeting?: number;
  selfDefence?: number;
  overall?: number;
  status?: number;
  dateAdded?: Date;
  supportingDoc?: string;
  scoreKeeper?: string;
}

export interface CognitiveAssessment {
  id?: number;
  scoreKeeper?: string;
  status?: number;
  dateAdded?: Date;
  supportingDoc?: string;
}

export interface PhysicalAssessment {
  id?: number;
  scoreKeeper?: string;
  status?: number;
  dateAdded?: Date;
  supportingDoc?: string;
}

export interface CreateNewJobPostNote {
  caption: string;
  details: string;
  jobPostId: number;
}

export interface ApplicationEvaluationReview {
  applicationEvaluationId: number;
  isPassed: boolean;
  failureReason?: string;
  currentEvaluationStage: number;
}

export interface CalculateApplicantScore {
  jobPostId: number;
  indicator: number;
  g1?: number;
  g2?: number;
  home?: number;
  school?: number;
  work?: number;
  criminalCheck?: number;
  grade?: number;
  hasCriminalHistory?: boolean;
  hasEducationBeenConfirmed?: boolean;
  hasPreEmploymentBeenConfirmed?: boolean;
  hasProfessionalPersonlBeenConfirmed?: boolean;
  martialArt?: number;
  selfDefence?: number;
  greeting?: number;
  scores?: Score[];
  gender?: number;
}

export interface Score {
  requirementId: number;
  grade: number;
}

export interface ReviewApplicantPhysicalAssessment {
  applicationEvaluationId: number;
  scoreTrack: string;
  supportingDocs?: string[];
  isPassed: boolean;
  failureReason?: string;
}

export interface ReviewCognitivePhysicalAssessment {
  applicationEvaluationId: number;
  scoreTrack: string;
  supportingDocs?: string[];
  isPassed: boolean;
  failureReason?: string;
}

export interface GuarantorAssessment {
  id?: number;
  status?: number;
  dateAdded?: Date;
  createdAt?: Date;
  updatedAt?: Date;
}

export interface ReviewApplicantGuarantorAsseessment {
  applicationEvaluationId: number;
  isPassed: boolean;
  guarantors: CreateGuarantor[];
  failureReason?: string;
}

export interface ReviewApplicantOperativeGuarantorAssessment {
  applicationEvaluationId: number;
  isPassed: boolean;
  operativeGuarantors: number[];
  failureReason?: string;
}

export interface CreateGuarantor {
  pictureUrl: string;
  firstName: string;
  lastName: string;
  mobile: string;
  email: string;
  address: string;
  occupation: string;
  guarantorDeclarationFormUrls: string[];
  uploadedDeclarationData?: any[];
  uploadedPictureData?: any[];
}

export interface CreateBackgroundExamination {
  caption: string;
  description: string;
}

export interface ReviewApplicantBackgroundAsseessment {
  applicationEvaluationId: number;
  isPassed: boolean;
  hasCriminalHistory: boolean;
  hasEducationBeenConfirmed: boolean;
  hasPreEmploymentBeenConfirmed: boolean;
  hasProfessionalPersonlBeenConfirmed: boolean;
  supportingDocs?: string[];
  failureReason?: string;
}

export interface CreateTrainingSchool {
  name: string;
  startDate: Date;
  description: string;
}

export interface TrainingSchool {
  id?: number;
  name?: string;
  startDate?: Date;
  description?: string;
  schoolCode?: string;
  students?: Student[];
  approvalLogs?: TrainingSchoolApprovalLog[];
  createdAt?: Date;
  isSentForApproval?: boolean;
}

export interface Student {
  id?: number;
  trainingSchoolId?: number;
  trainingSchool?: TrainingSchool;
  jobApplicationId?: number;
  jobApplication?: JobApplication;
  dateAdded?: Date;
  approvals?: GMAApproval[];
  createdById?: number;
  createdAt?: Date;
}

export interface GMAApproval {
  Id?: number;
  approvingOfficeId?: number;
  approvingOffice?: GMAApprovingOffice;
  status?: number;
  approvingOfficerId?: number;
  approvingOfficer?: GMAApprovingOfficer;
  createdById?: number;
  createdAt?: Date;
}

export interface GMAApprovingOffice {
  id?: number;
  name?: string;
  sequence?: number;
  officers?: GMAApprovingOfficer[];
  createdById?: number;
  createdAt?: Date;
}

export interface GMAApprovingOfficer {
  id?: number;
  userId?: number;
  user?: User;
  addedById?: number;
  dateAdded?: Date;
  approvingOfficeId?: number;
  approvingOffice?: GMAApprovingOffice;
  createdById?: number;
  createdAt?: Date;
}

export interface AddTrainingSchoolStudent {
  jobApplicantId: number;
  trainingSchoolId: number;
}

export interface TrainingSchoolApprovalLog {
  id?: number;
  approvingOfficeId?: number;
  approvingOffice?: GMAApprovingOffice;
  approvingOfficerId?: number;
  approvingOfficer?: GMAApprovingOfficer;
  createdById?: number;
  createdBy?: User;
  createdAt?: Date;
}

export interface TrainingSchoolApprovalInfo {
  awaitingApproval: TrainingSchool[];
  pickedApproval: TrainingSchool[];
  dueCount: number;
  notDueCount: number;
  overDueCount: number;
  currentUserApprovingOfficeId: number;
  currentUserApprovingOfficeSequence: number;
  approvingOffices: GMAApprovingOffice[];
}

export interface TrainingEvaluationReview {
  applicationEvaluationId: number;
  isPassed: boolean;
  failureReason?: string;
  scoreTrack: string;
  supportingDocs: string[];
}

export interface CreateClientLocation {
  imageUrl: string;
  name: string;
  description: string;
  stateId: number;
  lgaId: number;
  street: string;
  serviceCategoriesIds: number[];
  customerDivisionId: number;
  longitude: number;
  latitude: number;
}

export interface UpdateClientLocation {
  imageUrl: string;
  name: string;
  description: string;
  stateId: number;
  lgaId: number;
  street: string;
  serviceCategoriesIds: number[];
  customerDivisionId: number;
}

export interface CreateGuardLocationDeployment {
  applicationEvaluationId: number;
  locationId: number;
  contractServiceId: number;
  isPoolDeployment: boolean;
}

export interface SetApplicationItems {
  applicationEvaluationId: number;
  opertiveItemsIds: number[];
}

export interface ApplicantOperativeItem {
  id?: number;
  operativeItemId?: number;
  operativeItem?: OperativeItem;
  createdById?: number;
}

export interface LogMaterialBatchRequest {
  jobPostId: number;
  regionStoreId: number;
  applicantRequest: ApplicantRequest[];
}

export interface ApplicantRequest {
  jobApplicationId: number;
  operativeItems: number[];
}

export interface ApplicantMaterialRequestBatch {
  id?: number;
  requestDesc?: string;
  batchCode?: string;
  applicantRequests?: ApplicantMaterialRequest[];
  batchRequestId?: number;
  batchRequest?: StockBatch;
  createdAt?: Date;
}

export interface ApplicantMaterialRequest {
  id?: number;
  jobApplication?: JobApplication;
  jobApplicationId?: number;
  itemsRequested?: ApplicantMaterialRequestItem[];
  createdAt?: Date;
}

export interface ApplicantMaterialRequestItem {
  id?: number;
  operativeItemId?: number;
  operativeItem?: OperativeItem;
  qtyDispatched?: number;
  qtyConfirmed?: number;
  isFullyDispatched?: boolean;
  dateFullyDispatched?: Date;
  requestItemConfirmations?: MaterialRequestItemConfirmation[];
  createdAt?: Date;
}

export interface TrackMaterialRequestData {
  phase: number;
  status: number;
  trackDetails: TrackDetail[];
}

export interface TrackDetail {
  actedOnBy: string;
  message: string;
  dateOfAction: Date;
}

export interface FingerPrintScannerResp {
  bMPBase64?: string;
  errorCode?: number;
  iSOTemplateBase64?: string;
  imageDPI?: number;
  imageDataBase64?: string;
  imageHeight?: number;
  imageQuality?: number;
  imageWidth?: number;
  manufacturer?: string;
  model?: string;
  nFIQ?: number;
  serialNumber?: string;
  templateBase64?: string;
  wSQImage?: string;
  wSQImageSize?: number;
}

export interface AddApplicantBioData {
  applicantEvaluationId: number;
  bMPBase64: string;
  biometricThump: number;
}

export interface ApplicantBioData {
  id?: number;
  applicantEvaluationId?: number;
  bmpBase64?: string;
  thumbprint?: number;
  createdAt?: Date;
}

export interface CreateBeatTypeSetup {
  caption: string;
  description: string;
  uploadedImgs: string[];
}

export interface CreateBeatType {
  caption: string;
  description: string;
  visualsUrl: string[];
}

export interface UpdateBeatType {
  caption?: string;
  description?: string;
  visualsUrl?: string[];
}

export interface Beat {
  id?: number;
  caption: string;
  alias: string;
  description: string;
  beatTypeId?: number;
  beatType?: BeatType;
  locationId?: number;
  createdById?: number;
  isDeleted?: boolean;
  createdAt?: Date;
}

export interface BeatType {
  id: number;
  caption: string;
  description: string;
  visual: string;
  createdById: number;
  createdAt: Date;
}

export interface CreateNewBeat {
  caption: string;
  description: string;
  alias: string;
  locationId: number;
  beatTypeId: number;
}

export interface UpdateBeat {
  caption?: string;
  description?: string;
  alias?: string;
  locationId?: number;
  beatTypeId?: number;
}

export interface GMAZone {
  createdById?: number;
  clusters?: Cluster[];
  zonalDeputy?: User;
  zonalDeputyId?: number;
  createdAt?: Date;
  zonalHead?: User;
  description?: string;
  alias?: string;
  caption?: string;
  region?: Branch;
  regionId?: number;
  id: number;
  zonalHeadId?: number;
  isDeleted?: boolean;
}

export interface Cluster {
  id?: number;
  caption?: string;
  alias?: string;
  description?: string;
  clusterHeadId?: number;
  clusterHead?: User;
  clusterDeputyId?: number;
  clusterDeputy?: User;
  zoneId?: number;
  zone?: GMAZone;
  createdById?: number;
  createdAt?: Date;
  isDeleted?: boolean;
  locations?: Location[];
  supervisionPolicies?: ClusterSupervisionPolicy[];
  hasActivePolicy?: boolean;
  pendingAbsenceReportSummary?: string;
}

export interface CreateZoneVM {
  regionId: number;
  caption: string;
  description: string;
  alias: string;
  zonalHeadId: number;
  zonalDeputyId: number;
}

export interface UpdateZoneVM {
  regionId?: number;
  caption?: string;
  description?: string;
  alias?: string;
  zonalHeadId?: number;
  zonalDeputyId?: number;
}

export interface Region {
  id: number;
  name: string;
  description: string;
  headId: number;
  createdById: number;
  isDeleted: boolean;
  createdAt: Date;
  gmaZones: GMAZone[];
}

export interface CreateClusterVM {
  zoneId: number;
  caption: string;
  description: string;
  alias: string;
  clusterHeadId: number;
  clusterDeputyId?: number;
}

export interface UpdateClusterVM {
  caption?: string;
  description?: string;
  alias?: string;
  clusterHeadId?: number;
  clusterDeputyId?: number;
}

export interface AllocateLocationVM {
  clusterId: number;
  locationsId: number[];
}

export interface ChecklistCategory {
  id: number;
  caption: string;
  description: string;
  checklists: GMAChecklist[];
  createdById: number;
  createdAt: Date;
}

export interface GMAChecklist {
  id?: number;
  caption?: string;
  description?: string;
  checklistCategoryId?: number;
  createdById?: number;
  createdAt?: Date;
  isChecked?: boolean;
  isDeleted?: boolean;
}

export interface CreateCheckListCategoryVM {
  caption: string;
  description: string;
}

export interface UpdateChecklistCategoryVM {
  caption?: string;
  description?: string;
}

export interface AddNewCheckListVM {
  caption: string;
  description: string;
  checklistCategoryId: number;
}

export interface UpdateCheckListVM {
  caption?: string;
  description?: string;
}

export interface GeneralPhaseChecks {
  physicalChecks: GeneralPhaseCheckDit[];
  cognitiveChecks: GeneralPhaseCheckDit[];
  backgroundChecks: GeneralPhaseCheckDit[];
  trainingChecks: GeneralPhaseCheckDit[];
}

export interface GeneralPhaseCheckDit {
  requirementId: number;
  caption: string;
}

export interface DeployedGuard {
  id: number;
  jobApplicationId: number;
  jobApplication: JobApplication;
  guardDeployments: GuardDeployment[];
  createdAt: Date;
  operativeCode?: string;
  trainingSchoolCode?: string;
  payGradeId?: number;
  payGrade?: PayGrade;
  payGroupId?: number;
  payGroup?: PayGroup;
  location?: string;
  IsCurrentlySanctioned: boolean;
  guardName?: string;
}

export interface GuardDeployment {
  id: number;
  deployedGuardId?: number;
  deployedGuard?: DeployedGuard;
  locationId?: number;
  location?: Location;
  beatAssignments?: GuardBeatAssignment[];
  ContractServiceId?: number;
  contractService?: ContractService;
  isDeleted?: boolean;
  createdById?: number;
  createdAt?: Date;
  isAbsent?: boolean;
  nonconformingReport?: string;
  specificComplaintReport?: string;
  isLoaded?: boolean;
}

export interface GuardLocationReDeployment {
  deployedGuardIds: number[];
  locationId: number;
  contractServiceId: number;
}

export interface ZonalMapViewSummary {
  totalZones: number;
  totalClusters: number;
  totalLocations: number;
  totalGuards: number;
  zones: GMAZone[];
  guardDeployments: GuardDeployment[];
}

export interface OperativeManagements {
  clusters: Cluster[];
  deployedGuards: DeployedGuard[];
}

export interface DetachGuardOP {
  guardOperatives: number[];
}

export interface DistributeApplicantItems {
  applicantRequestItemId: number;
  qtyDistributed: number;
  mobile: string;
}

export interface DistributeApplicantItemReceivingDTO {
  itemsDistributed: DistributeApplicantItems[];
}

export interface OperativeManagementOPDetails {
  trainingBatchCode: string;
  operativeCode: string;
}

export interface AssignGuardDeploymentsToBeat {
  beatId: number;
  guardDeploymentIds: number[];
}

export interface GuardBeatAssignment {
  id?: number;
  guardDeploymentId?: number;
  beatAssignedToId?: number;
  beatAssignedTo?: Beat;
  isDeleted?: boolean;
  createdById?: number;
  createdAt?: Date;
  updatedById?: number;
  updatedAt?: Date;
}

export interface SubmitGeneralReportCluster {
  locationId: number;
  reports: GeneralClusterReport[];
  cycleId: number;
  checklistReports: GenReportChecklistReport[];
  evidences?: string[];
}

export interface GenReportChecklistReport {
  checklistId: number;
  isChecked: boolean;
}

export interface GeneralClusterReport {
  name?: string;
  key: number;
  status?: boolean;
  report: string;
}

export interface SubmitOperativeSpecificClusterReport {
  reports: OperativeSpecificClusterReport[];
  cycleId: number;
}

export interface OperativeSpecificClusterReport {
  guardDeploymentId: number;
  isAbsent: boolean;
  nonConformityReport: string;
  specificComplaintReport: string;
  evidences?: string[];
}

export interface ClusterReportStatus {
  generalReportSubmissionStatus: boolean;
  operativeSpecififcReportSubmissionStatus: boolean;
}

export interface ClusterSupervisionPolicy {
  id?: number;
  clusterId?: number;
  policyStartDate: Date;
  policyStopDate: Date;
  minimumCoverageExpected: number;
  minimumLocationsToCover: number;
  supervisionCycle: number;
  cycles?: ClusterPolicyCycle[];
  isDeleted: boolean;
  createdById?: number;
  createdAt?: Date;
  updatedAt?: Date;
}

export interface AddNewClusterSupervisionPolicy {
  clusterId: number;
  policyStartDate: Date;
  policyEndDate: Date;
  minimumCoverageExpected: number;
  minimumLocationsToCover: number;
  dailyThresholdCoverage: number;
  supervisionCycle: number;
}

export interface MaterialRequestItemConfirmation {
  id: number;
  qty: number;
  materialRequestItemId?: number;
  materialRequestItem?: ApplicantMaterialRequestItem;
  isDispatch: boolean;
  createdById?: number;
  createdBy?: User;
  createdAt: Date;
}

export interface ClusterPolicyCycle {
  id: number;
  supervisionPolicyId?: number;
  isActive: boolean;
  startDate: Date;
  endDate: Date;
}

export interface AddLocationToPolicyCycle {
  cycleId: number;
  locationIds: number[];
}

export interface GetActiveCycleInfoVM {
  hasAlreadyPickedToday: boolean;
  locationsIds: number[];
}

export interface GetBioDashSummaryVM {
  totalOperatives: number;
  totalOperativesWithoutBioData: number;
  allOperativesWithoutBio: DeployedGuard[];
  allOperatives: DeployedGuard[];
}

export interface VerifyGeoLocation {
  isNearLocation: boolean;
  distance: number;
  distanceUnit: number;
}

export interface VerifyCordinateAgainstLocationCordinateVM {
  longitude: string;
  latitude: string;
  locationId: number;
}

export interface GeocodingGetAddressCordinateVM {
  status: string;
  results: GeocodingGetAddressResult[];
  error_message: string;
}

export interface GeocodingGetAddressResult {
  address_components: GeocodingAddressComponent[];
  formatted_address: string;
  geometry: GeocodingGeometryComponent;
  place_id: string;
  plus_code: ReverseGeocodingPluscode;
  types: string[];
}

export interface GeocodingAddressComponent {
  long_name: string;
  short_name: string;
  types: string[];
}

export interface GeocodingGeometryComponent {
  bounds: GeocodingGeometryViewport;
  location: GeocodingGeometryLocation;
  location_type: string;
  viewport: GeocodingGeometryViewport;
}

export interface ReverseGeocodingPluscode {
  compound_code: string;
  global_code: string;
}

export interface GeocodingGeometryViewport {
  northeast: GeocodingGeometryLocation;
  southwest: GeocodingGeometryLocation;
}

export interface GeocodingGeometryLocation {
  lat: number;
  lng: number;
}

export interface UpdatePersonalBioImage {
  personalInfoId: number;
  imageUrl: string;
}

export interface GetApplicantTrainingResultVM {
  resultData: TrainingSchoolResult;
  dict: TrainingSchoolResultDictVM[];
}

export interface TrainingSchoolResultDictVM {
  courseName: string;
  trainingEvaluationReqId: number;
}

export interface GetMateriaRefreshUserViewVM {
  userImageUrl: string;
  locationCount: number;
  operativesCount: number;
  operativesDueCount: number;
  locationViews: GetMaterialRefreshLocationView[];
  ongoingRefreshBatch: ApplicantMaterialRefreshBatch[];
  refreshBatcheMade: MaterialRefreshBatch[];
}

export interface GetMaterialRefreshLocationView {
  locationId: number;
  name: string;
  operatives: number;
  operativesDue: number;
  operativePendingRefresh: ApplicantMaterialRefreshBatch[];
}

export interface MaterialRefreshBatch {
  id: number;
  refreshBatchMadeId?: number;
  refreshBatchMade?: StockBatch;
  isRefreshRequestMade: boolean;
  dateSentForRefresh?: Date;
  refreshBatches: ApplicantMaterialRefreshBatch[];
  createdById: number;
  createdBy: User;
  createdAt: Date;
  updatedAt: Date;
}

export interface ApplicantMaterialRefreshBatch {
  id: number;
  locationId?: number;
  location?: Location;
  locationName: string;
  jobApplicationId?: number;
  jobApplication?: JobApplication;
  materialRefreshBatchId?: number;
  materialRefreshBatch?: MaterialRefreshBatch;
  refreshBatchItems: ApplicantMaterialRefreshBatchItem[];
  dateAdded: Date;
}

export interface ApplicantMaterialRefreshBatchItem {
  id: number;
  applicantMaterialRefreshBatchId?: number;
  applicantMaterialRefreshBatch?: ApplicantMaterialRefreshBatch;
  operativeItemId?: number;
  operativeItem?: OperativeItem;
  isRequested: boolean;
  quantity: number;
  dateDistributed: Date;
  dateToBeRefreshed?: Date;
}

export interface AddRequestToMaterialRefreshBatchVM {
  locationId: number;
  locationName: string;
  batchRefreshItemsPicked: number[];
  batchRefreshFullyPicked: number[];
  isReplacementRequest?: boolean;
}

export interface GetDashboardAnalyticsVM {
  totalActiveOperatives: number;
  totalOperativesInPool: number;
  totalOperativesFullyAccredited: number;
  totalOperativesNotFullyAccredited: number;
  guardDistributionByGender: GMADashboardPieDataVM;
  guardDistributionByAge: GMADashboardPieDataVM;
  guardDistributionByRegion: GMADashboardPieDataVM;
  guardDistributionByZone: GMADashboardPieDataVM;
  guardDistributionByCluster: GMADashboardPieDataVM;
  guardDistributionByLocation: GMADashboardPieDataVM;
}

export interface GMADashboardPieDataVM {
  labels: string[];
  datas: number[];
  backgroundColors: string[];
}

export interface CreateGMAGuarantorVM {
  imageUrl: string;
  firstname: string;
  lastname: string;
  mobile: string;
  email: string;
  address: string;
  vettingDocUrls: string[];
  guarantorDocUrls: string[];
}

export interface OperativeGuarantor {
  id?: number;
  imageUrl?: string;
  firstname?: string;
  lastname?: string;
  mobile?: string;
  email?: string;
  address?: string;
  vettingDocUrls?: string;
  guarantorFormUrls?: string;
  createdById?: number;
  createdBy?: User;
  createdAt?: Date;
}

export interface AddNewGuarantorUploadsVM {
  isVettingDoc: boolean;
  guarantorId: number;
  vettiongDocs: string[];
  guarantorForms: string[];
  isKnownChanges?: boolean;
}

export interface UpdateOperativeGuarantorVM {
  imageUrl?: string;
  firstname?: string;
  lastname?: string;
  mobile?: string;
  email?: string;
  address?: string;
}

export interface GMAFacilitator {
  id: number;
  facilitatorId?: number;
  facilitator?: User;
  lastname: string;
  firstname: string;
  imageUrl: string;
  mobile: string;
  email: string;
  address: string;
  isExternal: boolean;
  createdById: number;
  isDeleted: boolean;
  createdAt: Date;
  createdBy: User;
}

export interface GetScheduleEventUsersListTransferDTO {
  operatives: DeployedGuard[];
  facilitators: GMAFacilitator[];
}

export interface ScheduleGMATrainingEventVM {
  caption: string;
  // lGAId: number;
  // street: string;
  traningFacilityId: number;
  startDate: Date;
  stopDate: Date;
  facilitatorIds: number[];
  operativeIds: number[];
  sendNotifications?: boolean;
}

export interface GMAEvent {
  id: number;
  caption: string;
  lgaId?: number;
  lga?: Lga;
  street: string;
  startDate: Date;
  stopDate: Date;
  facilitators: GMAEventFacilitator[];
  operatives: GMAEventOperative[];
  trainingFacilityId?: number;
  trainingFacility?: GMATrainingFacility;
  createdById: number;
  createdAt: Date;
  createdBy: User;
}

export interface GMAEventFacilitator {
  id: number;
  gMAEventId?: number;
  gMAEvent?: GMAEvent;
  gmaFacilitatorId?: number;
  gmaFacilitator: GMAFacilitator;
  dateAdded: Date;
}

export interface GMAEventOperative {
  id: number;
  gMAEventId?: number;
  gMAEvent?: GMAEvent;
  deployedGuardId?: number;
  deployedGuard?: DeployedGuard;
  dateAdded: Date;
}

export interface UpdateGMAEventVM {
  caption: string;
  traningFacilityId?: number;
  startDate?: Date;
  stopDate?: Date;
  facilitatorIds?: number[];
  operativeIds?: number[];
  sendNotifications?: boolean;
}

export interface CreateGMAExtFacilitatorVM {
  imageUrl: string;
  firstname: string;
  lastname: string;
  mobile: string;
  email: string;
  address: string;
}

export interface CreateGMAanctionsVM {
  deployedGuardIds: number[];
  sanctionType: number;
  sanctionReason: number;
  sanctionSeverityLevel: number;
  dateOccurred: Date;
  comment: string;
  evidenceUrls: string[];
}

export interface GMASanction {
  id: number;
  deployedGuardId?: number;
  deployedGuard?: DeployedGuard;
  sanctionType: number;
  sanctionReason: number;
  severityLevel: number;
  dateOccurred: Date;
  evidences: string;
  comment: string;
  dateReInstated?: Date;
  reInstatedById?: number;
  reInstatedBy?: User;
  createdById: number;
  createdBy: User;
  createdAt: Date;
}

export interface UnsanctionedDeployedGuardVM {
  id: number;
  imageUrl: string;
  lastname: string;
  firstname: string;
  mobile: string;
  currentDeloymentLocation: string;
  isInPool: boolean;
}

export interface CreateTrainingFacilityVM {
  caption: string;
  description: string;
  stateId: number;
  lgaId: number;
  street: string;
  longitude: number;
  latitude: number;
  geometry: string;
}

export interface GMATrainingFacility {
  id: number;
  caption: string;
  description: string;
  stateId?: number;
  state?: State;
  lgaId?: number;
  lga?: Lga;
  street: string;
  longitutude: number;
  latitude: number;
  geometry: string;
  createdById: number;
  createdBy: User;
}

export interface SpecialRequest {
  id: number;
  operativeId: number;
  createdById: number;
  isDeleted: boolean;
  comment: string;
  dateFullyApproved?: Date;
  isFullyApproved: boolean;
  isBulkEntry: boolean;
  totalValue: number;
  applicableRate: number;
  applicableYearDateTime: Date;
  applicableYear: string;
  operativeName: string;
  createdAt: Date;
  specialOperationName: string;
  specialOperationId?: number;
  isAllowanceOperation: boolean;
  noOfDays: number;
  applicableMonthDateTime: Date;
  applicableMonth: number;
  clientName: string;
  clientId?: number;
  batch?: SpecialRequestBatch;
  batchId?: number;
  approvals: SpecialApprovalVM[];
}

export interface SpecialRequestBatch {
  id: number;
  batchCode: string;
  requests: SpecialRequest[];
  isSentForApproval: boolean;
  dateSentForApproval?: Date;
  isFullyApproved: boolean;
  dateFullyApproved?: Date;
  isAllowanceOperation: boolean;
  isDeleted: boolean;
  createdById: number;
  createdAt: Date;
}

export interface SpecialApprovalVM {
  id: number;
  requestId: number;
  approvingOfficeId: number;
  approvingOfficeName: string;
  approvingOfficerId: number;
  approvingOfficerName: string;
  approvalStatus: number;
  createdById: number;
  createdAt: Date;
}

export interface GMAOperativeRecruitmentReport {
  physicalReports: GMAPhysicalReport[];
  cognitiveReports: GMAPhysicalReport[];
  guarantors: OperativeGuarantor[];
  backgroundCheck: BackgroundInfo;
}

export interface GMAPhysicalReport {
  examination: string;
  score: string;
  isPassed: boolean;
  comment: string;
  dateAdded: Date;
}

export interface GMAOperativeMaterialsAllocationReport {
  item: string;
  quantity: number;
  requestedBy: string;
  dateRequested: Date;
}

export interface PerformBioSearchVM {
  bMPBase64: string;
  biometricThump: number;
}

export interface GetApplicantEvaluationDetailsVM {
  jobApplicationId: number;
  imageUrl: string;
  fullname: string;
  currentDeployment: string;
  lastDeploymentDate: Date;
}

export interface GetReverseGeocodingLocationRespVM {
  plus_code: {
    compound_code: string;
    global_code: string;
  };
  results: {
    address_components: {
      long_name: string;
      short_name: string;
      types: string[];
    }[];
    formatted_address: string;
    geometry: {
      bounds: any;
      location: {
        lat: number;
        lng: number;
      };
      location_type: string;
      viewport: {
        northeast: {
          lat: number;
          lng: number;
        };
        southwest: {
          lat: number;
          lng: number;
        };
      };
    };
    place_id: string;
    plus_code: {
      compound_code: string;
      global_code: string;
    };
    types: string[];
  }[];
  status: string;
}

export interface StandaloneGuardRegistrationVM {
  jobFunctionId: number;
  personalInfo: StandaloneGuardRegistrationVM_Personal;
  backgroundInfo: StandaloneGuardRegistrationVM_Background;
  nextOfKinInfos: StandaloneGuardRegistrationVM_NextOfKin[];
  educationInfos: StandaloneGuardRegistrationVM_Education[];
  prevEmpInfos: StandaloneGuardRegistrationVM_PrevEmp[];
  medAcctInfo: StandaloneGuardRegistrationVM_MedAcct;
  otherInfo: StandaloneGuardRegistrationVM_Other;
}

export interface StandaloneGuardRegistrationVM_Personal {
  passportImageUrl?: string;
  firstname: string;
  lastname: string;
  email?: string;
  stateId?: number;
  othernames: string;
  lGAId: number;
  nickname: string;
  street: string;
  mobile: string;
  gender: number;
  dOB: Date;
  applicationLetterUrl: string[];
}

export interface StandaloneGuardRegistrationVM_Background {
  stateOfOrigin: number;
  lGAOfOrigin: number;
  tribe: string;
  maritalStatus: number;
  marriageDate: Date;
  marriageLocation: string;
  belongsToAClub: boolean;
  clubName?: string;
  spouseFirstname: string;
  spouseLastname: string;
  spouseOccupation: string;
  fatherFullname: string;
  fatherAddress: string;
  motherFullname: string;
  motherAddress: string;
  children?: StandaloneGuardRegistrationVM_Child[];
}

export interface StandaloneGuardRegistrationVM_Child {
  firstname: string;
  lastname: string;
  gender: number;
  dateOfBirth: Date;
}

export interface StandaloneGuardRegistrationVM_NextOfKin {
  firstname: string;
  lastname: string;
  state: number;
  stateEntity?: State;
  lGA: number;
  lGAEntity?: Lga;
  othername?: string;
  street: string;
  relationship: number;
  relationshipValue?: string;
  mobile: string;
  email?: string;
}

export interface StandaloneGuardRegistrationVM_Education {
  educationalLevel: number;
  educationalLevelValue?: string;
  programStartDate: Date;
  institution: string;
  programEndDate: Date;
  qualificationAttained: string;
  gradeAwarded: string;
}

export interface StandaloneGuardRegistrationVM_PrevEmp {
  name: string;
  role: string;
  state: number;
  stateName?: string;
  lGA: number;
  lGAName?: string;
  street: string;
  yearOfEmployment: Date;
  yearOfTermination: Date;
  reasonForTermination: string;
}

export interface StandaloneGuardRegistrationVM_MedAcct {
  isDrinking: boolean;
  isSmoking: boolean;
  isGambling: boolean;
  isHypertensive: boolean;
  hasArthritis: boolean;
  isDiabetic: boolean;
  isSufferingUnknown: boolean;
  unknownDiseaseDescription?: string;
  primaryBank: string;
  primaryBankAcctNo: string;
  secondaryBank: string;
  secondaryBankAcctNo: string;
  pensionPin: string;
  tIN?: string;
  bVN: string;
  pensionPayeeID: string;
  nIN?: string;
  primaryAccountBankId: number;
  secondaryAccountBankId: number;
  primaryAccountName?: string;
  secondaryAccountName?: string;
}

export interface StandaloneGuardRegistrationVM_Other {
  isExMilitant: boolean;
  hasCombatTraining: boolean;
  hasBeenArrested: boolean;
  hasBeenConvicted: boolean;
  hasBeenAskedToResign: boolean;
  agreesToTerms: boolean;
  hasDeclared: boolean;
}

export interface GMAGuardRegistration {
  id: number;
  jobFunctionId?: number;
  personalInfoId?: number;
  personalInfo: GMAGuardRegPersonalInfo;
  backgroundInfoId?: number;
  backgroundInfo: GMAGuardRegBackgroundInfo;
  nextOfKinInfos: GMAGuardRegNextOfKinInfo[];
  educationInfos: GMAGuardRegEducationInfo[];
  prevEmployerInfos: GMAGuardRegPrevEmployerInfo[];
  medicalAccountInfoId?: number;
  medicalAccountInfo: GMAGuardRegMedicalAccountInfo;
  additionalInfoId?: number;
  additionalInfo: GMAGuardRegAdditionalInfo;
  bioDatas: GMAGuardRegBioData[];
  isDeleted: boolean;
  createdById: number;
  createdAt: Date;
}

export interface GMAGuardRegPersonalInfo {
  createdAt?: Date;
  applicationLetters?: string;
  imageUrl?: string;
  dateOfBirth?: Date;
  street?: string;
  lga?: Lga;
  lgaId?: number;
  stateId?: number;
  email?: string;
  mobile?: string;
  nickname?: string;
  othername?: string;
  lastname?: string;
  firstname?: string;
  id?: number;
  state?: State;
  fullname?: string;
  gender?: number;
}

export interface GMAGuardRegBackgroundInfo {
  createdAt?: Date;
  children?: GMAGuardRegChildInfo[];
  motherAddress?: string;
  motherFullname?: string;
  fatherAddress?: string;
  fatherFullname?: string;
  spouseOccupation?: string;
  spouseLastname?: string;
  spouseFirstname?: string;
  isBelongingToAClub?: boolean;
  marriageLocation?: string;
  maritalDate?: Date;
  maritalStatus?: number;
  tribe?: string;
  lgaOfOrigin?: Lga;
  lgaOfOriginId?: number;
  stateOfOrigin?: State;
  stateOfOriginId?: number;
  id?: number;
  clubName?: string;
}

export interface GMAGuardRegChildInfo {
  id?: number;
  firstname?: string;
  lastname?: string;
  gender?: number;
  dateOfBirth?: Date;
}

export interface GMAGuardRegNextOfKinInfo {
  createdAt?: Date;
  email?: string;
  street?: string;
  lga?: Lga;
  state?: State;
  stateId?: number;
  relationship?: number;
  othername?: string;
  lastname?: string;
  firstname?: string;
  id?: number;
  lgaId?: number;
}

export interface GMAGuardRegEducationInfo {
  id?: number;
  level?: number;
  institution?: string;
  qualificationAttained?: string;
  programStartDate?: Date;
  programStopDate?: Date;
  gradeAwarded?: string;
}

export interface GMAGuardRegPrevEmployerInfo {
  createdAt?: Date;
  reasonForTermination?: string;
  yearOfTermination?: Date;
  yearOfEmployment?: Date;
  street?: string;
  lga?: Lga;
  lgaId?: number;
  state?: State;
  stateId?: number;
  name?: string;
  id?: number;
  roleOccupied?: string;
}

export interface GMAGuardRegMedicalAccountInfo {
  createdAt?: Date;
  taxIdentificationNo?: string;
  pensionPin?: string;
  secondaryBankAcctNo?: string;
  primaryBankAcctNo?: string;
  secondaryBank?: number;
  primaryBank?: number;
  isSufferingUnknown?: boolean;
  isDiabetic?: boolean;
  hasArthritis?: boolean;
  isHypertensive?: boolean;
  isGambling?: boolean;
  isSmoking?: boolean;
  isDrinking?: boolean;
  id?: number;
  unknownDescription?: string;
}

export interface GMAGuardRegAdditionalInfo {
  id?: number;
  isExMilitary?: boolean;
  hasCombatTraining?: boolean;
  hasBeenArrested?: boolean;
  hasBeenConvicted?: boolean;
  hasBeenAskedToResign?: boolean;
}

export interface GMAGuardRegBioData {
  id?: number;
  guardRegistrationId?: number;
  bMPBase64: string;
  thumbprint: BiometricThumbprintEnum;
  createdById?: number;
  createdAt?: Date;
}

export interface AddGuardBioDataVM {
  guardRegistrationId: number;
  bMPBase64: string;
  biometricThump: BiometricThumbprintEnum;
}
