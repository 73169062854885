import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { MessageService, ConfirmationService } from "primeng/api";
import { BreadcrumbService } from "src/app/breadcrumb.service";
import {
  CentralStore,
  GetStockReceiptTransfer,
  GetStockReceiptVM,
  ItemGroup,
  RegionalStore,
} from "src/app/interfaces/inventory";
import { InventoryService } from "src/app/services/inventory.service";

@Component({
  selector: "app-inv-stock-receipt",
  templateUrl: "./inv-stock-receipt.component.html",
  styleUrls: ["./inv-stock-receipt.component.scss"],
})
export class InvStockReceiptComponent implements OnInit {
  @ViewChild("formWrapper") public formWrapper: ElementRef;
  isRegional: boolean = false;
  isReceipt: boolean = false;
  allCentralStores: CentralStore[];
  theCentralStore: CentralStore;
  allRegionalStores: RegionalStore[];
  theRegionalStore: RegionalStore;
  requestStartDate: Date;
  requestEndDate: Date;
  allItemGroupType: ItemGroup[];
  theItemGroupType: ItemGroup;
  showReport: boolean = false;
  reports: GetStockReceiptTransfer[];
  reportCols: any[];

  constructor(
    fb: FormBuilder,
    public inventoryService: InventoryService,
    public messageService: MessageService,
    private breadcrumbService: BreadcrumbService,
    public confirmationService: ConfirmationService
  ) {}

  ngOnInit(): void {
    this.breadcrumbService.setItems([
      {
        label: "Inventory Management",
        routerLink: ["/home/inventory-management/set-up"],
      },
      {
        label: "Stock Receipt",
        routerLink: ["/home/inventory-management/stock-receipt"],
      },
    ]);

    this.reportCols = [
      { field: "name", header: "Item Name" },
      { field: "code", header: "Item Code" },
      { field: "quantity", header: "Quantity" },
      { field: "batchNumber", header: "Batch No" },
      { field: "batchName", header: "Batch Name" },
      { field: "receiptValue", header: "Receipt Value" },
    ];

    this.FetchAllInventoryStores();
    this.FetchAllInventoryItemGroups();
  }

  FetchAllInventoryStores() {
    this.inventoryService.GetAllInventoryStores().subscribe(
      (data) => {
        this.allCentralStores = data.centralStores;
        this.allRegionalStores = data.regionalStores;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all inventory stores at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  FetchAllInventoryItemGroups() {
    this.inventoryService.GetAllItemGroup().subscribe(
      (data) => {
        this.allItemGroupType = data;
        this.allItemGroupType.push({
          id: 0,
          caption: "ALL",
          description: "ALL",
        });
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all inventory item groups at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  RunCriteria() {
    if (this.requestEndDate < this.requestStartDate) {
      this.messageService.add({
        severity: "error",
        summary: "Notice",
        detail: "Start Date must not be greater than end date.",
      });
      return;
    }

    this.showReport = false;
    const postData: GetStockReceiptVM = {
      isRegional: this.isRegional,
      isReceipt: this.isReceipt,
      storeId: this.isRegional
        ? this.theRegionalStore.id
        : this.theCentralStore.id,
      requestStartDate: this.requestStartDate,
      requestEndDate: this.requestEndDate,
      itemGroupId: this.theItemGroupType.id,
    };

    this.inventoryService.GetStockReceipt(postData).subscribe(
      async (data) => {
        this.reports = data;
        this.showReport = true;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to generate report at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
        this.showReport = false;
      }
    );
  }
}
