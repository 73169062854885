import { Injectable } from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {JourneyStop, SituationReport} from '../../interfaces/armada';

@Injectable({
  providedIn: 'root'
})
export class JourneyStopService {

  baseUrl = environment.armadaBaseUrl+'JourneyStop/';
  constructor(private httpClient: HttpClient) { }
  allJourneyStop(): Observable<JourneyStop>{
    return this.httpClient.get<JourneyStop>(this.baseUrl);
  }
  getJourneyStop(id: any):Observable<JourneyStop>{
    return this.httpClient.get<JourneyStop>(this.baseUrl +id);
  }
  getJourneyStopByName(name: any):Observable<JourneyStop>{
    return this.httpClient.get<JourneyStop>(this.baseUrl + 'name/' + name);
  }
  postJourneyStop(data: any){
    return this.httpClient.post(this.baseUrl, data);
  }
  delete(id: any){
    return this.httpClient.delete(this.baseUrl +id);
  }
  updateJourneyStop(id: any, data: any){
    return this.httpClient.put(this.baseUrl +id, data);
  }
  getByContractService(contractServiceId: number):Observable<JourneyStop[]>{
    return this.httpClient.get<JourneyStop[]>(`${this.baseUrl}GetByContractService/${contractServiceId}`);
  }
  getByServiceAssignment(contractServiceId: number):Observable<JourneyStop[]>{
    return this.httpClient.get<JourneyStop[]>(`${this.baseUrl}GetByServiceAssignment/${contractServiceId}`);
  }
}
