import { ViewNoteDetailsComponent } from "./view-note-details/view-note-details.component";
import { Component, Input, OnInit } from "@angular/core";
import { DialogService, DynamicDialogRef } from "primeng/dynamicdialog";
import { CommonResponse } from "src/app/interfaces/home";
import { AccountMasterService } from "src/app/services/account-master";
import { ViewInvoiceComponent } from "../invoice-creation/view-invoice/view-invoice.component";
import { async } from "rxjs/internal/scheduler/async";

@Component({
  selector: "app-credit-debitnote-view",
  templateUrl: "./credit-debitnote-view.component.html",
  styleUrls: ["./credit-debitnote-view.component.scss"],
})
export class CreditDebitnoteViewComponent implements OnInit {
  noteValue: number = 1;
  creditDebitNote: any[];
  creditNotes: any = [];
  debitNotes: any = [];
  selectednote: any[] = [];
  fetchNote: boolean = false;
  @Input() customerDivisionId: number;
  viewInvoiceRef: DynamicDialogRef;
  availableNotes: any[];

  constructor(
    private accountMasterService: AccountMasterService,
    private dialogService: DialogService
  ) {}

  ngOnInit(): void {
    this.creditDebitNote = [];
    this.creditNotes = [];
    this.debitNotes = [];
    this.getCreditNote(this.customerDivisionId);
    this.getDebitNote(this.customerDivisionId);
  }

  getCreditNote(customerDivisionId: any) {
    //this.fetchingContractServices = true;
    this.fetchNote = true;
    this.accountMasterService.getCreditNotes(customerDivisionId).subscribe(
      async (r: CommonResponse) => {
        if (r.responseCode == "00") {
          this.fetchNote = false;
          var result = r.responseData;
          this.creditNotes = result;
          this.creditDebitNote = this.creditNotes;
        } else {
          this.fetchNote = false;
        }
      },
      (error) => {
        this.fetchNote = false;
      }
    );
  }

  viewNote() {
    this.viewInvoiceRef = this.dialogService.open(ViewNoteDetailsComponent, {
      width: "950px",
      contentStyle: { "max-width": "100vw", height: "90vh", overflow: "auto" },
      baseZIndex: 100,
      data: {
        note: this.selectednote,
        label: this.noteValue == 1 ? "Credit" : "Debit",
      },
    });

    // this.viewInvoiceRef.onClose.subscribe(async (res: any) => {
    //   if (res.length == 0) return;

    //   this.availableNotes = [];
    //   res.forEach((value, i) => {
    //     this.availableNotes.push(value);
    //   });
    // });
  }

  ToggleNote() {
    this.creditDebitNote =
      this.noteValue == 1 ? this.creditNotes : this.debitNotes;
    this.selectednote = [];
  }

  getDebitNote(customerDivisionId: any) {
    //this.fetchingContractServices = true;
    this.accountMasterService.getDebitNote(customerDivisionId).subscribe(
      async (r: CommonResponse) => {
        if (r.responseCode == "00") {
          var result = r.responseData;
          this.debitNotes = result;
        }
      },
      (error) => {}
    );
  }

  FormatDateString(date: Date): string {
    let dateString = date.toString();

    try {
      // DateTimeFormatOptions
      const date: Date = new Date(dateString);
      const options: Intl.DateTimeFormatOptions = {
        year: "numeric",
        month: "long",
        day: "numeric",
      };
      const formattedDate: string = new Intl.DateTimeFormat(
        "en-US",
        options
      ).format(date);
      dateString = formattedDate;
    } catch (error) {
      console.log(error);
      console.log(date);
      console.log(
        "Error while converting date string " + date + " exception " + error
      );
    }

    return dateString;
  }
}
