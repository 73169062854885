import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable()
export class OpportunitiesService {

  constructor(private http:HttpClient) 
    {

   }

   getOpportunityData(){
    return this.http.get(
      environment.baseUrl + "contact/GetLeadsOpportunity"
    )
  }

  getQuotesService(referenceNumber:number){
    return this.http.get(
      environment.baseUrl + "Contact/GetContractByLead/"+referenceNumber
    )
  }
  getQuote(){
    return this.http.get(
      environment.baseUrl + "Quote"
    )
  }

  getAllDevisions(){
    return this.http.get(
      environment.baseUrl + "Contact/GetAllQuotesWithDivisions"
    )
  }

  getLeadQouteById(leadId:number):Observable<any>{
    return this.http.get(
      environment.baseUrl + "Contact/GetQuotes/"+ leadId
    )
  }

}
