import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {Observable} from 'rxjs';
import {User} from '../../interfaces/home';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(
      private http: HttpClient,
  ) { }
  baseUrl = environment.armadaBaseUrl+'User/';
  allUsers(): Observable<User> {
    return this.http.get<User>(this.baseUrl);
  }
  getUnRegisteredUsers() {
    return this.http.get<User>(this.baseUrl + 'GetUnRegisteredUsers');
  }
}
