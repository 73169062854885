import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { CommonResponse } from "src/app/interfaces/home";

@Component({
  selector: "app-display-qr",
  templateUrl: "./display-qr.component.html",
  styleUrls: ["./display-qr.component.scss"],
})
export class DisplayQrComponent implements OnInit {
  displayQR: boolean = false;
  qrSrc: string = "";
  subject: string = "Visitor Access QR Code";
  date: string = Date.now().toString();
  haloWorldUtilityBaseUrl = environment.HaloWorldRealEstateBaseUrl + "Utility/";

  constructor(private route: ActivatedRoute, private http: HttpClient) {}

  ngOnInit(): void {
    let qrSrcId = +this.route.snapshot.paramMap.get("qrSrcId");
    this.DisplayQR(qrSrcId);
  }

  GetQrCodeImgSourceBySrcId(srcId: number): Observable<CommonResponse<string>> {
    return this.http.get<CommonResponse<string>>(
      this.haloWorldUtilityBaseUrl + "GetQrCodeImgSourceBySrcId/" + srcId
    );
  }

  async DisplayQR(srcId: number) {
    this.GetQrCodeImgSourceBySrcId(srcId).subscribe(
      async (data) => {
        this.qrSrc = data.responseData;
        this.displayQR = true;
      },
      (error) => {
        console.log(error);
        this.displayQR = true;
      }
    );
  }
}
