import {
  ConvergenceEntityDefaultSource,
  ConvergenceIncident,
} from "./../../../../interfaces/convergence";
import { SMORoute } from "./../../../../interfaces/armada";
///<reference types="@types/googlemaps" />

import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ConfirmationService, MessageService } from "primeng/api";
import { BreadcrumbService } from "src/app/breadcrumb.service";
import { Supplier } from "src/app/interfaces/armada";
import {
  EventSource,
  EventType,
  RegisterCapturedIncidentVM,
} from "src/app/interfaces/convergence";
import {
  CommonResponse,
  CustomerDivision,
  Lga,
  State,
  User,
} from "src/app/interfaces/home";
import { SMORouteService } from "src/app/services/armada/smoroute.service";
import { ConvergenceService } from "src/app/services/convergence.service";
import { CustomerDivisionService } from "src/app/services/customer-division.service";
import { GmaEmploymentService } from "src/app/services/gma-employment.service";
import { SupplierService } from "src/app/services/supplier.service";
import { UserService } from "src/app/services/user.service";
import { AngularFireStorage } from "@angular/fire/storage";
import { finalize } from "rxjs/operators";
import { DeployedGuard } from "src/app/interfaces/employment";
import { FileStorageService } from "src/app/services/file-storage.service";

@Component({
  selector: "app-incidence-convergence-capture-event",
  templateUrl: "./incidence-convergence-capture-event.component.html",
  styleUrls: ["./incidence-convergence-capture-event.component.scss"],
})
export class IncidenceConvergenceCaptureEventComponent
  implements OnInit, AfterViewInit
{
  @ViewChild("formWrapper") public formWrapper: ElementRef;
  incidentForm: FormGroup;
  fetching: boolean;
  allIncidents: ConvergenceIncident[];
  selectedIncidents: ConvergenceIncident[];
  cols: any[];
  allEventSource: EventSource[] = [];
  theEventSource: EventSource;
  allEventTypes: EventType[];
  theEventType: EventType;
  allStates: State[];
  theState: State;
  allLgas: Lga[];
  theLga: Lga;
  allRoutes: SMORoute[];
  theRoute: SMORoute;
  riskLevel = 3;
  allReportedType: any[] = [
    {
      id: 1,
      caption: "Supplier",
    },
    {
      id: 2,
      caption: "Client",
    },
    {
      id: 3,
      caption: "Staff",
    },
    {
      id: 4,
      caption: "Operatives",
    },
  ];
  theReportedType: {
    id: number;
    caption: string;
  };
  allReportedBy: {
    id: number;
    caption: string;
  }[];
  theReportedBy: {
    id: number;
    caption: string;
  };
  loadingMapCordinates: boolean = false;
  locLongitude: number;
  locLatitude: number;
  locGeometry: string;
  isDoneLoadingMap: boolean;
  manualLocCordinateDialogue: boolean = false;
  manualLocAddress: string;
  manualLocLatitude: number;
  manualLocLongitude: number;
  uploadedPictures: any[] = [];
  userIsYetToClickUploadPicture: boolean;
  allSuppliers: Supplier[] = [];
  theSupplier: Supplier;
  allClients: CustomerDivision[] = [];
  theClient: CustomerDivision;
  allUsers: User[] = [];
  theUser: User;
  allOperatives: DeployedGuard[] = [];
  theOperative: DeployedGuard;
  showUplGrid: boolean = true;
  openDocDialogue: boolean;
  docIncident: ConvergenceIncident;
  allDefaultEventSources: ConvergenceEntityDefaultSource[] = [];
  dateIncidentOccured: any;

  constructor(
    private fb: FormBuilder,
    private fileStorageService: FileStorageService,
    public smoRouteService: SMORouteService,
    public supplierService: SupplierService,
    public clientService: CustomerDivisionService,
    public userService: UserService,
    public convergenceService: ConvergenceService,
    public employmentService: GmaEmploymentService,
    private breadcrumbService: BreadcrumbService,
    public confirmationService: ConfirmationService,
    public messageService: MessageService,
    public fireStorage: AngularFireStorage
  ) {
    this.incidentForm = fb.group({
      Source: ["", Validators.required],
      Type: ["", Validators.required],
      State: ["", Validators.required],
      Lga: ["", Validators.required],
      Street: ["", Validators.required],
      DateOccurred: ["", Validators.required],
      Caption: ["", Validators.required],
      Description: ["", Validators.required],
      Advisory: ["", Validators.required],
      Route: [""],
      RiskLevel: ["", Validators.required],
    });
  }

  ngOnInit(): void {
    this.breadcrumbService.setItems([
      {
        label: "Convergence",
      },
      {
        label: "Incident Management",
      },
      {
        label: "Capture Incident",
        routerLink: ["/home/convergence/incident-management/capture-incidence"],
      },
    ]);

    this.cols = [
      { field: "sourceName", header: "Source" },
      { field: "typeName", header: "Type" },
      { field: "caption", header: "Caption" },
      { field: "description", header: "Description" },
      { field: "advisory", header: "Advisory" },
      { field: "street", header: "Address" },
      { field: "reportedByEntityName", header: "Reported By" },
      { field: "dateOccurred", header: "Date Occurred" },
      { field: "isReviewed", header: "Is Reviewed" },
      { field: "isApproved", header: "Is Approved" },
    ];

    this.FetchAllEventSource();
    this.FetchAllEventType();
    this.FetchAllDefaultEventSource();
    this.FetchAllStates();
    this.FetchAllRoutes();
    this.FetchAllSuppliers();
    this.FetchAllClients();
    this.FetchAllUsers();
    this.FetchAllOperatives();
    this.FetchAllRegisteredIncidents();
  }

  ngAfterViewInit(): void {
    // Load google maps script after view init
    const DSLScript = document.createElement("script");
    DSLScript.src =
      "https://maps.googleapis.com/maps/api/js?key=AIzaSyCQuetprs2UHb_zKSXznyumyfvw95ERCDY"; // replace by your API key
    DSLScript.type = "text/javascript";
    document.body.appendChild(DSLScript);
    document.body.removeChild(DSLScript);
  }

  FetchAllEventSource() {
    this.convergenceService.GetAllEventSources().subscribe(
      async (data) => {
        this.allEventSource = data;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all event sources at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  FetchAllEventType() {
    this.convergenceService.GetAllEventTypes().subscribe(
      async (data) => {
        this.allEventTypes = data;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all event types at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  FetchAllStates() {
    this.employmentService.GetAllStates().subscribe(
      async (data) => {
        this.allStates = data;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all states at the moment.. Reason: [" +
            error.error.message +
            "]",
        });
      }
    );
  }

  FetchAllRoutes() {
    this.smoRouteService.allRoutes().subscribe(
      async (data) => {
        this.allRoutes = data.responseData;
      },
      (error) => {
        this.messageService.add({
          severity: "error",
          summary: "Failed",
          detail: error ?? "Some errors occurred",
        });
      }
    );
  }

  FetchAllRegisteredIncidents() {
    this.convergenceService.GetAllRegisteredIncidents().subscribe(
      async (data) => {
        this.allIncidents = data;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all registered incidents at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  async FetchAllSuppliers() {
    this.supplierService.getAll().subscribe(
      async (data) => {
        this.allSuppliers = data.responseData;
      },
      (error) => {
        this.messageService.add({
          severity: "error",
          summary: "Failed",
          detail: error ?? "Some errors occurred",
        });
      }
    );
  }

  async FetchAllClients() {
    this.clientService.allCustomerDivision().subscribe(
      async (data) => {
        this.allClients = data.responseData;
      },
      (error) => {
        this.messageService.add({
          severity: "error",
          summary: "Failed",
          detail: error ?? "Some errors occurred",
        });
      }
    );
  }

  async FetchAllUsers() {
    this.userService.allUser().subscribe(
      async (res) => {
        var data = res.responseData;
        data.forEach((user) => {
          user.fullName = user.lastName + " " + user.firstName;
          this.allUsers.push(user);
        });
      },
      (err) => {
        console.log(err);
        this.messageService.add({
          severity: "error",
          summary: "Failed",
          detail: err ?? "Some errors occurred",
        });
      }
    );
  }

  async FetchAllOperatives() {
    this.employmentService.GetAllOperativesDataOnly().subscribe(
      async (data) => {
        this.allOperatives = data;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all operatives at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  async FetchAllDefaultEventSource() {
    this.convergenceService.GetAllDefaultEventSources().subscribe(
      async (data) => {
        this.allDefaultEventSources = data;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all default event sources at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  async CreateIncident() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Registering Incident...",
    });

    const postData: RegisterCapturedIncidentVM = {
      sourceId: this.theEventSource.id,
      sourceName: this.theEventSource.caption,
      // dateOccurred: this.incidentForm.get("DateOccurred").value,
      dateOccurred: this.dateIncidentOccured,
      typeId: this.theEventType.id,
      typeName: this.theEventType.caption,
      stateId: this.theState.id,
      caption: this.incidentForm.get("Caption").value,
      lgaId: this.theLga.id,
      description: this.incidentForm.get("Description").value,
      street: this.incidentForm.get("Street").value,
      riskLevel: this.riskLevel,
      uploads: [],
      advisory: this.incidentForm.get("Advisory").value,
      reportedBy: this.theReportedBy.caption,
      reportedById: this.theReportedBy.id,
      reportedByType: this.theReportedType.id,
      locationLongitude: this.locLongitude,
      locationLatitude: this.locLatitude,
      locationGeometry: this.locGeometry,
    };

    if (this.theRoute) postData.routeId = this.theRoute.id;

    if (this.uploadedPictures.length > 0) {
      this.fileStorageService.UploadMultipleFilesFromDataUrl(
        this.uploadedPictures
      );
      this.fileStorageService.onUploadFinished.subscribe(
        (resp: CommonResponse<string[]>) => {
          if (resp.responseCode != "00") {
            this.messageService.add({
              severity: "error",
              summary: "Notice",
              detail: resp.responseMsg,
            });
          } else {
            postData.uploads = resp.responseData;
            this._registerIncident(postData);
          }
        },
        (error) => {
          console.log("Error while uploading files " + error);
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail: "ERR: Unable to upload image to storage",
          });
        }
      );
    } else {
      this._registerIncident(postData);
    }
  }

  async _registerIncident(postData: RegisterCapturedIncidentVM) {
    this.convergenceService.RegisterCapturedIncident(postData).subscribe(
      async () => {
        await this.messageService.add({
          severity: "success",
          summary: "Completed",
          detail: "Incident Registered Successfully!",
        });

        this.isDoneLoadingMap = false;
        this.theReportedBy = null;
        this.theReportedType = null;
        this.locGeometry = null;
        this.locLatitude = null;
        this.locLongitude = null;
        this.uploadedPictures = [];
        this.incidentForm.reset();
        this.dateIncidentOccured = null;
        this.theEventSource = null;
        this.riskLevel = 3;
        this.FetchAllRegisteredIncidents();

        this.showUplGrid = false;
        await new Promise((resolve) => setTimeout(resolve, 2000)); // 2 secs
        this.showUplGrid = true;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to register incident at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  OnSourceChange() {}

  OnTypeChange() {}

  OnStateChange() {
    this.theLga = null;
    this.allLgas = this.theState.lgas;
  }

  OnLgaChange() {}

  OnRouteChange() {}

  OnReportedTypeChange() {
    this.allReportedBy = [];
    this.theEventSource = null;
    if (this.theReportedType) {
      let defaultSource = this.allDefaultEventSources.find(
        (x) => x.entityType == this.theReportedType.id
      );
      if (defaultSource) {
        this.theEventSource = this.allEventSource.find(
          (x) => x.id == defaultSource.defaultEventSourceId
        );
      }

      if (this.theReportedType.caption.toLowerCase() == "supplier") {
        this.allSuppliers.forEach((x) =>
          this.allReportedBy.push({
            id: x.id,
            caption: x.supplierName,
          })
        );
      } else if (this.theReportedType.caption.toLowerCase() == "client") {
        this.allClients.forEach((x) =>
          this.allReportedBy.push({
            id: x.id,
            caption: x.divisionName,
          })
        );
      } else if (this.theReportedType.caption.toLowerCase() == "staff") {
        this.allUsers.forEach((x) =>
          this.allReportedBy.push({
            id: x.id,
            caption: x.lastName + " " + x.firstName,
          })
        );
      } else if (this.theReportedType.caption.toLowerCase() == "operatives") {
        this.allOperatives.forEach((x) =>
          this.allReportedBy.push({
            id: x.id,
            caption:
              x.jobApplication.personalInformation.lastname +
              " " +
              x.jobApplication.personalInformation.firstname,
          })
        );
      }
    }
  }

  OnReportedByChange() {}

  LoadLocationCordinates() {
    // this.loadingMapCordinates = true;
    let address: string =
      this.incidentForm.get("Street").value +
      "," +
      this.theLga.name +
      "," +
      this.theState.name;
    this.GetInputtedLocationCordinates(address);
  }

  GetInputtedLocationCordinates(address: string) {
    // this.loadingMapCordinates = true;
    this.employmentService.GetAddressGeocodingLocation(address).subscribe(
      async (data) => {
        if (data.status != "OK") {
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail:
              "Unable to get address cordinates at the moment.. " +
              data.error_message,
          });
          return;
        }
        this.locLatitude = data.results[0].geometry.location.lat;
        this.locLongitude = data.results[0].geometry.location.lng;
        this.locGeometry = data.results[0].formatted_address;
        this.initMap();
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to get address cordinates at the moment.. Reason: [" +
            error.error.message +
            "]",
        });
      }
    );
  }

  // Initialize and add the map
  initMap(): void {
    // this.loadingMapCordinates = true;
    this.isDoneLoadingMap = false;
    if (this.locLatitude && this.locLongitude) {
      // The location of Loc
      const location = { lat: this.locLatitude, lng: this.locLongitude };
      // The map, centered at Loc
      const map = new google.maps.Map(
        document.getElementById("map") as HTMLElement,
        {
          zoom: 4,
          center: location,
        }
      );

      // The marker, positioned at Loc
      const marker = new google.maps.Marker({
        position: location,
        map: map,
      });
      this.loadingMapCordinates = false;
      this.isDoneLoadingMap = true;
    }
  }

  ManualLocationCordinate() {
    this.manualLocCordinateDialogue = true;
  }

  LoadManualLocationAddress() {
    this.GetInputtedLocationCordinates(this.manualLocAddress);
    this.manualLocCordinateDialogue = false;
  }

  LoadManualLocationCordinates() {
    this.locLatitude = this.manualLocLatitude;
    this.locLongitude = this.manualLocLongitude;
    this.locGeometry = "Manually Inputted Raw Cordinate(s)";
    this.initMap();
    this.manualLocCordinateDialogue = false;
    this.loadingMapCordinates = true;
  }

  HideManualLocationDialog() {
    this.manualLocCordinateDialogue = false;
  }

  ConfirmLocationAndCreateIncident() {
    if (!this.locLatitude || !this.locLongitude || !this.locGeometry) {
      this.messageService.add({
        severity: "error",
        summary: "Failed",
        detail:
          "Error Missing Cordinates, Kindly click on Confirm Location Cordinates again.",
      });
      return;
    }

    this.CreateIncident();
  }

  onCancelUpload() {
    this.uploadedPictures = [];
  }

  NotifyUserToClickUpload() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Kindly ensure you click upload.",
    });

    this.userIsYetToClickUploadPicture = true;
  }

  onUploadPicture(event) {
    this.uploadedPictures = [];
    for (const file of event.files) {
      this.uploadedPictures.push(file);
    }

    this.messageService.add({
      severity: "success",
      summary: "Success",
      detail: "Picture(s) Uploaded",
    });

    this.userIsYetToClickUploadPicture = false;
  }

  ViewDocs(item: ConvergenceIncident) {
    this.openDocDialogue = true;
    this.docIncident = item;
  }

  HideDocDialog() {
    this.openDocDialogue = false;
    this.docIncident = null;
  }

  DeleteIncident(item: ConvergenceIncident) {
    this.confirmationService.confirm({
      message: "Are you sure you want to remove incident?",
      accept: () => {
        this.messageService.add({
          severity: "info",
          summary: "Notice",
          detail: "Removing incident...",
        });

        this.convergenceService.DeleteCapturedIncident(item.id).subscribe(
          async () => {
            this.messageService.add({
              severity: "success",
              summary: "Removed",
              detail: "Removed successfully",
            });
            this.FetchAllRegisteredIncidents();
          },
          (error) => {
            console.log("Error: " + JSON.stringify(error));
            this.messageService.add({
              severity: "error",
              summary: "Notice",
              detail:
                "Unable to remove incident at the moment.. Reason: [" + error
                  ? error.error.message
                  : "request failed - permission" + "]",
            });
          }
        );
      },
    });
  }
}
