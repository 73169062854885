import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Route, Router } from "@angular/router";
import {
  CommonResponse,
  Customer,
  Division,
  GroupType,
  Industry,
  Lead,
  LeadContact,
  LeadOrigin,
  LeadType,
} from "../../../interfaces/home";
import { DivisionService } from "../../../services/division.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { LeadService } from "../../../services/lead.service";
import { LeadOriginService } from "../../../services/lead-origin.service";
import { CommonService } from "../../../services/common.service";
import { MessageService } from "primeng/api";
import { LeadTypeService } from "../../../services/lead-type.service";
import { GroupTypeService } from "../../../services/group-type.service";
import { EventInput } from "@fullcalendar/core";
import { Observable } from "rxjs";
import { IndustryService } from "src/app/services/industry.service";
import { CustomerService } from "../../../services/customer.service";
import { DialogService, DynamicDialogRef } from "primeng/dynamicdialog";
import { LeadProgressDocumentComponent } from "../lead-progress-document/lead-progress-document.component";
import { BreadcrumbService } from "src/app/breadcrumb.service";
import { KeyPersonnelComponent } from "../../client-management/client-view/key-personnel/key-personnel.component";
import { EngagementContactsService } from "../../engagement-contacts/engagement-contacts.service";
import { DataViewModule } from "primeng/dataview";
import { DomSanitizer } from "@angular/platform-browser";
import { FileStorageService } from "src/app/services/file-storage.service";

export interface GroupCustomer {
  id: number;
  groupName: string;
}

@Component({
  selector: "app-lead-capture",
  templateUrl: "./lead-capture.component.html",
  styleUrls: ["./lead-capture.component.css"],
})
export class LeadCaptureComponent implements OnInit {
  @ViewChild("groupNamePanel") public groupNamePanel: ElementRef;
  edLead: any;
  companyLogoUrl: string;
  personalInformation: any;
  submitted = false;
  selectedDivision: Division[];
  divisions: Division[];
  uploadedFile: any;
  logoForm: FormGroup;
  currentStage: string;
  leadLogoUrl: string;
  activeLead: Lead | null;
  leadOrigins: LeadOrigin[] = [];
  selectedLeadOrigin: LeadOrigin = null;
  leadTypes: LeadType[] = [];
  selectedLeadType: LeadType = null;
  groupTypes: GroupType[] = [];
  selectedGroupType: GroupType = null;
  leadForm: FormGroup;
  public uploadingNewPicture: boolean;
  public isEditing: boolean;
  private imageFile: any;
  public imageSrc: string | "assets/no-file.png";
  public displayLoading: boolean;
  public downloadURL: Observable<any>;
  public leadRef: string = null;
  private fb: any;
  leadPrimaryContactForm: FormGroup;
  canProgress: false;
  activeLeadCaptureStatus = false;
  isRetail = false;
  industry: Industry[] = [];
  selectedIndustry: Industry = null;
  public leadDropStatus = false;
  searchLeadForm: FormGroup;
  searchResult: Lead;
  isSearching: boolean;
  public groupCustomers: GroupCustomer[];
  selectedGroupName: any;
  filteredGroups: any[];
  public leadSuggestion: boolean;
  suggestedPrimaryContact: LeadContact | null;
  suggestedSecondaryContact: LeadContact | null;
  public suggestedCustomer: Customer;
  private leadCaptureDialogRef: DynamicDialogRef;
  keyPersonnelRef: DynamicDialogRef;

  leads: Lead[];
  selectedLead: Lead;
  suspectContact: any[];

  constructor(
    public engagementService: EngagementContactsService,
    private sanitizer: DomSanitizer,

    public leadService: LeadService,
    private router: Router,
    private divisionService: DivisionService,
    private formBuilder: FormBuilder,
    private leadOriginService: LeadOriginService,
    private leadTypeService: LeadTypeService,
    private groupTypeService: GroupTypeService,
    private messageService: MessageService,
    private fileStorageService: FileStorageService,
    private route: ActivatedRoute,
    private industryService: IndustryService,
    private customerService: CustomerService,
    private dialogService: DialogService,
    private breadcrumbService: BreadcrumbService
  ) {
    this.selectedDivision = [];
  }

  ngOnInit(): void {
    this.breadcrumbService.setItems([
      { label: "Lead Administration", routerLink: ["home/lead-admin"] },
      { label: "Capture", routerLink: ["home/lead-admin/capture"] },
    ]);
    this.fetchOrigin();
    this.fetchLeadType();
    this.fetchGroupType();
    this.fetchIndustry();
    this.fetchLeads();
    this.leadForm = this.formBuilder.group({
      leadTypeId: [null, Validators.required],
      leadOriginId: [null, Validators.required],
      industry: ["", Validators.required],
      rcNumber: [{ value: "", disabled: true }],
      groupName: [{ value: "", disabled: true }],
      groupTypeId: [null, Validators.required],
      logoUrl: [""],
    });
    this.logoForm = this.formBuilder.group({
      image: [null],
      name: "exceptional",
    });
    this.searchLeadForm = this.formBuilder.group({
      search: ["", Validators.required],
      leaddropdown: [null],
    });
    // check if url have reference code
    this.oldLeadDivision();
  }

  getAllContactsToSuspect(id) {
    this.engagementService.getAllContactsAttachedToSuspects(id).subscribe(
      async (res: any) => {
        if (res.responseCode == "00") {
          this.suspectContact = [];
          this.suspectContact = res.responseData.filter(
            (x) => x.contact.isDeleted == false
          );
        } else {
          this.suspectContact = [];
        }
      },
      (error) => {
        this.suspectContact = [];
      }
    );
  }
  transform(image) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(image);
  }
  async fetchOrigin() {
    this.leadOriginService.allLeadOrigin().subscribe(
      async (res: CommonResponse) => {
        this.leadOrigins = res.responseData;
      },
      (error) => {
        this.connectionError();
      }
    );
  }

  async fetchLeadType() {
    this.leadTypeService.allLeadTypeData().subscribe(
      async (res: CommonResponse) => {
        this.leadTypes = res.responseData;
      },
      (error) => this.connectionError()
    );
  }

  async fetchGroupType() {
    this.groupTypeService.allGroupTypeData().subscribe(
      async (res: CommonResponse) => {
        this.groupTypes = res.responseData;
      },
      (error) => this.connectionError()
    );
  }

  fetchLeads() {
    this.leadService.allUserLeads().subscribe(
      async (res) => {
        this.leads = res.responseData ?? ([] as Lead[]);
      },
      (error) => this.connectionError()
    );
  }

  async oldLeadDivision() {
    this.leadRef =
      this.route.snapshot.paramMap.get("referenceNo") ??
      this.selectedLead?.referenceNo;
    if (this.leadRef) {
      // get the lead admin data and prefill the fields
      await this.leadService.setCurrentLead(this.leadRef);
      await this.leadService.activeLead().subscribe(async (res: any) => {
        if (res?.responseCode == "00") {
          var lead = res.responseData;
          this.activeLead = lead;
          this.leadDropStatus = this.activeLead.isLeadDropped;
          this.isEditing = true;
          this.edLead = true;
          this.getAllContactsToSuspect(lead.suspectId);
          this.activeLeadCaptureStatus = this.activeLead.leadCaptureStatus;
          await this.leadForm.patchValue({
            groupName: this.activeLead.groupName,
            rcNumber: this.activeLead.rcNumber,
            // logoUrl: this.activeLead.logoUrl
          });
          if (
            this.activeLead.groupType?.caption?.toLowerCase() !==
              "individual" &&
            this.activeLead.groupType?.caption?.toLowerCase() !== "sme"
          ) {
            this.isRetail = false;
            this.leadService.isRetail = false;
          } else {
            this.isRetail = true;
            this.leadService.isRetail = true;
          }
          this.selectedGroupType = this.groupTypes.find(
            (groupType) => groupType.id === this.activeLead.groupType.id
          );
          this.selectedLeadOrigin = this.leadOrigins.find(
            (leadOrigin) => leadOrigin.id === this.activeLead.leadOrigin.id
          );
          this.selectedLeadType = this.leadTypes.find(
            (leadType) => leadType.id === this.activeLead.leadType.id
          );
          this.selectedIndustry = this.industry.find(
            (industry) =>
              industry.caption?.toLowerCase() ===
              this.activeLead?.industry?.toLowerCase()
          );
          this.imageSrc = this.activeLead?.logoUrl;
          if (this.activeLead) {
            await this.renderSuggestion(
              this.activeLead.groupType.id,
              this.activeLead.groupName
            );
          }
        }
      });
    }
  }
  async renderSuggestion(groupTypeId, groupName) {
    this.customerService
      .fetchByGroupType(groupTypeId)
      .subscribe(async (r: CommonResponse) => {
        if (r.responseCode == "00") {
          var res = r.responseData;
          let _customerGroups = [];
          _customerGroups = res;
          const activeGroup = res.find(
            (group) => group.groupName.toLowerCase() === groupName.toLowerCase()
          );
          if (activeGroup) {
            this.customerService
              .getCustomer(activeGroup.id)
              .subscribe((r: CommonResponse) => {
                var result = r.responseData;
                this.suggestedCustomer = result;
                if (this.activeLead) {
                  this.suggestedPrimaryContact = result.primaryContact;
                  this.suggestedSecondaryContact = result.secondaryContact;
                }
              });
          }
        }
      });
  }
  nextPage() {
    this.router.navigate([
      "/home/lead-admin/qualification",
      this.activeLead.referenceNo,
    ]);
    return;
  }

  remove(division) {
    console.log(division);
  }

  showPreview($event: Event) {
    const file = (event.target as HTMLInputElement).files[0];
    this.logoForm.patchValue({
      image: file,
    });
    this.logoForm.get("avatar").updateValueAndValidity();

    // File Preview
    const reader = new FileReader();
    reader.onload = () => {
      this.companyLogoUrl = reader.result as string;
    };
    reader.readAsDataURL(file);
  }

  createLead() {
    // create new lead
    const postData = {
      leadTypeId: this.selectedLeadType.id,
      leadOriginId: this.selectedLeadOrigin.id,
      industry: this.leadForm.value.industry.caption,
      rcNumber: this.isRetail ? "RC00000000" : this.leadForm.value.rcNumber,
      groupName: this.isRetail ? "Retail" : this.leadForm.value.groupName,
      groupTypeId: this.selectedGroupType.id,
      logoUrl: this.imageSrc,
    };
    this.leadService
      .createLead(postData)
      .subscribe(async (res: CommonResponse) => {
        if (res.responseCode == "00") {
          this.leadService.startLead(res.responseData);
          await this.messageService.add({
            severity: "success",
            detail: "Your group lead information has been saved successfully,",
          });
          await this.router.navigate([
            "/home/lead-admin/capture",
            res.responseData?.referenceNo,
          ]);
        }
      });
    return;
  }

  updateLead() {
    // update lead
  }

  private connectionError() {
    this.messageService.add({
      severity: "error",
      summary: "Failed",
      detail: "Connection Error, Please try again",
    });
  }

  async saveLead(type: string) {
    // check if picture is selected
    this.displayLoading = true;
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Saving Lead information",
    });
    if (this.imageFile) {
      this.fileStorageService.UploadMultipleFilesFromDataUrl([this.imageFile]);
      this.fileStorageService.onUploadFinished.subscribe(
        (resp: CommonResponse<string[]>) => {
          if (resp.responseCode != "00") {
            this.messageService.add({
              severity: "error",
              summary: "Notice",
              detail: resp.responseMsg,
            });
          } else {
            let url = resp.responseData[0];
            if (url) {
              this.fb = url;
              this.imageSrc = this.fb;
            }
            this.leadForm.patchValue({ imageUrl: this.fb });
            if (type === "new") {
              this.createLead();
            } else {
              this.updateLead();
            }
            this.displayLoading = false;
          }
        },
        (error) => {
          console.log("Error while uploading files " + error);
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail: "ERR: Unable to upload image to storage",
          });
        }
      );
    } else {
      this.leadForm.patchValue({ logoUrl: "" }); // set the image url to the default set image
      this.updateLead();
      this.displayLoading = false;
    }
    this.displayLoading = false;
  }

  onFileSelect(event) {
    if (!this.checkMimeType(event) || !this.checkFileSize(event)) {
      this.imageSrc = "";
      this.leadForm.get("imageUrl").reset();
      return;
    }
    this.uploadingNewPicture = true;
    this.isEditing = true;
    const reader = new FileReader();
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      this.imageFile = event.target.files[0];
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.imageSrc = reader.result as string;
        this.leadForm.patchValue({
          imageUrl: reader.result,
        });
      };
    }
  }

  checkMimeType(event) {
    const files = event.target.files[0];
    // list allow mime type
    const types = ["image/png", "image/jpeg", "image/jpg", "image/gif"];
    // loop access array
    if (!files) {
      this.messageService.add({
        severity: "error",
        summary: "Failed",
        detail: "Please select an Image file",
      });
      return false;
    }
    // compare file type find doesn't match
    if (types.every((type) => files.type !== type)) {
      // create error message and assign to container
      this.messageService.add({
        severity: "error",
        summary: "Failed",
        detail: "Image format not supported",
      });
      return false;
    }
    return true;
  }

  checkFileSize(event: EventInput) {
    const files = event.target.files[0];
    if (!files) return true;
    const size = 400000;
    if (files.size > size) {
      event.target.value = null;
      this.messageService.add({
        severity: "error",
        summary: "Failed",
        detail: "Image is too large, please pick a smaller file",
      });
      return false;
    }
    return true;
  }

  progressLead() {
    // progress lead
    if (!this.activeLead) {
      this.messageService.add({
        severity: "error",
        summary: "Failed",
        detail: "Create a lead first",
      });
      return false;
    }
    //  else if(this.activeLead.financialInformation){
    //             this.messageService.add({
    //                 severity: 'error', summary: 'Failed',
    //                 detail: 'Add at least a division to the Lead Group'
    //             });
    //             return false;
    // }
    else if (this.activeLead.leadDivisions.length <= 0) {
      this.messageService.add({
        severity: "error",
        summary: "Cancelled",
        detail: "Add at 1 division to the lead before progressing",
      });
      return false;
    } else if (this.activeLead.leadDivisions) {
      const _leadDivisions = this.activeLead.leadDivisions;
      const _divisionContactStatus = [];
      for (const leadDivision of _leadDivisions) {
        if (!leadDivision.primaryContact) {
          _divisionContactStatus.push(leadDivision.id);
        }
      }
      if (_divisionContactStatus.length < 1) {
        this.messageService.add({
          severity: "error",
          summary: "Cancelled",
          detail: "Add primary contact to all the division before progressing",
        });
        return false;
      }
    }
    this.leadCaptureDialogRef = this.dialogService.open(
      LeadProgressDocumentComponent,
      {
        header: "Progress Lead",
        contentStyle: { "min-height": "120px", overflow: "auto" },
        baseZIndex: 100001,
        closeOnEscape: false,
        closable: false,
        data: {
          message:
            "Do you want to progress this opportunity?<br> Upload evidence that this prospect can be captured as a lead",
        },
      }
    );

    this.leadCaptureDialogRef.onClose.subscribe(async (res: any) => {
      if (res) {
        if (res.status) {
          this.leadService
            .updateLeadCaptured(this.activeLead.id, {
              leadCaptureDocumentUrl: res.documentUrl,
            })
            .subscribe(async (result: CommonResponse) => {
              if (result.responseCode == "00") {
                this.messageService.add({
                  severity: "Success",
                  summary: "Message",
                  detail: "Lead Capture Completed",
                });
                await this.nextPage();
              } else {
                this.messageService.add({
                  severity: "error",
                  summary: "Failure",
                  detail: result.responseMsg,
                });
                await this.leadService.refreshData();
              }
            });
        }
      }
    });
  }
  handleGroupTypeChange() {
    const groupType = this.leadForm.get("groupTypeId").value;
    if (
      groupType.caption.toLowerCase() !== "individual" &&
      groupType.caption.toLowerCase() !== "sme"
    ) {
      this.leadForm.get("rcNumber").enable();
      this.leadForm.get("rcNumber").setValidators(Validators.required);
      this.leadForm.get("groupName").enable();
      this.leadForm.get("groupName").setValidators(Validators.required);
      this.isRetail = false;
      this.leadService.isRetail = false;
    } else {
      this.isRetail = true;
      this.leadService.isRetail = true;
    }
    this.customerService
      .fetchByGroupType(groupType.id)
      .subscribe((result: CommonResponse) => {
        this.groupCustomers = result.responseData;
      });
  }
  async fetchIndustry() {
    await this.industryService.allIndustry().subscribe(
      async (res: CommonResponse) => {
        this.industry = res.responseData;
      },
      (error) => {
        this.connectionError();
      }
    );
  }
  searchForLead() {
    const searchControl = this.searchLeadForm.get("search");
    const searchValue: string = searchControl.value ?? "";
    this.isSearching = true;
    this.messageService.add({
      severity: "info",
      detail: "Searching for lead",
      summary: "notice",
    });
    if (searchControl.invalid) {
      this.messageService.add({
        severity: "error",
        detail: "Provide a valid Reference Code",
        summary: "notice",
      });
      this.isSearching = false;
      return;
    }
    this.leadService.searchLead(searchValue.toUpperCase()).subscribe(
      (res: CommonResponse) => {
        this.searchResult = res.responseData;
        this.isSearching = false;
        // check if the lead has been drop
        if (this.searchResult.isLeadDropped) {
          this.messageService.add({
            severity: "error",
            detail: "These lead was dropped",
            summary: "notice",
          });
          return;
        }
        if (this.searchResult.leadClosureStatus) {
          this.messageService.add({
            severity: "error",
            detail: "The selected lead has been closed",
            summary: "notice",
          });
          return;
        }

        // reload the page with the current lead data
        this.leadService.setCurrentLead(searchControl.value);
        this.leadService.activeLead().subscribe(async (res: CommonResponse) => {
          if (res?.responseCode == "00") {
            this.activeLead = res.responseData;
          }
        });
      },
      (error) => {
        this.isSearching = false;
        this.messageService.add({
          severity: "error",
          detail: error,
          summary: "Error",
        });
      }
    );
  }

  filterGroup(event: any) {
    console.log(event);
  }
  onRowSelect(event: any) {
    const data = event.data;
    if (this.selectedGroupName) {
      this.leadForm.get("groupName").setValue(this.selectedGroupName.groupName);
    }
  }
  suggestLeadData() {
    if (this.selectedGroupName) {
      this.leadForm.disable();
      this.customerService
        .getCustomer(this.selectedGroupName.id)
        .subscribe(async (r) => {
          var result = r.responseData as Customer;
          this.leadSuggestion = true;
          const rcNumberControl = this.leadForm.get("rcNumber");
          rcNumberControl.setValue(result.rcNumber);
          this.leadForm.enable();
          rcNumberControl.disable();
        });
    }
  }

  leadChanged() {
    console.log("Lead Changed ", this.selectedLead);
    if (this.searchLeadForm && this.selectedLead) {
      this.searchLeadForm
        .get("search")
        .patchValue(this.selectedLead.referenceNo);
    }
  }
}
