import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { Subject } from "rxjs";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { BreadcrumbService } from "../../../../breadcrumb.service";
import { ArmedEscortRankService } from "../../../../services/armada/armed-escort-rank.service";
import { ArmedEscortProfileService } from "../../../../services/armada/armed-escort-profile.service";
import { ConfirmationService, MessageService } from "primeng/api";
import { CommonResponse, Constant } from "../../../../interfaces/home";
import { takeUntil } from "rxjs/operators";
import { EventInput } from "@fullcalendar/core";
import { DialogService } from "primeng/dynamicdialog";
import {
  ArmedEscortProfile,
  ArmedEscortRank,
  ArmedEscortType,
} from "../../../../interfaces/armada";
import { ArmedEscortTypeService } from "../../../../services/armada/armed-escort-type.service";
import { FileStorageService } from "src/app/services/file-storage.service";

@Component({
  selector: "app-armed-escort-profile",
  templateUrl: "./armed-escort-profile.component.html",
  styleUrls: ["./armed-escort-profile.component.scss"],
  providers: [MessageService, DialogService, Constant],
})
export class ArmedEscortProfileComponent implements OnInit, OnDestroy {
  @ViewChild("formWrapper") public formWrapper: ElementRef;
  private unsubscriber$ = new Subject<void>();
  public armedEscortProfiles: ArmedEscortProfile[];
  public targetCols: any;
  private exportTargetColumns: any;
  public loading: boolean;
  public armedEscortProfileForm: FormGroup;
  public submittingData: boolean;
  selectedArmedEscortProfile: any;
  editingArmedEscortProfile = false;
  private edArmedEscortProfile: ArmedEscortProfile;
  submitButtonLabel: string;
  genders: any[] = [];
  selectedGender: any = null;
  public selectedArmedEscortRank: ArmedEscortRank;
  public armedEscortRanks: ArmedEscortRank[];
  public armedEscortTypes: ArmedEscortType[];
  public selectedArmedEscortType: ArmedEscortType;
  private message: { type: string; value: string };
  public imageSrc: string;
  private documentFIle: any;
  minimumAge: number;
  public yearRange: string;
  maxDateOfBirth: Date;
  availableArmedEscortRanks: ArmedEscortRank[];
  private downloadUrl: any;

  constructor(
    private breadcrumbService: BreadcrumbService,
    private armedEscortRankService: ArmedEscortRankService,
    private armedEscortTypeService: ArmedEscortTypeService,
    private armedEscortProfileService: ArmedEscortProfileService,
    public messageService: MessageService,
    public confirmationService: ConfirmationService,
    private formBuilder: FormBuilder,
    private constants: Constant,
    private fileStorageService: FileStorageService
  ) {
    this.breadcrumbService.setItems([
      { label: "Setup" },
      {
        label: "Armed Escort Profile",
        routerLink: ["/setup/armed-escort-profile"],
      },
    ]);
  }

  ngOnInit(): void {
    this.loading = true;
    this.targetCols = [
      { field: "firstName", header: "first Name" },
      { field: "lastName", header: "Last Name" },
      { field: "codeName", header: "Code Name" },
    ];
    this.armedEscortRanks = [];
    this.minimumAge = 18;
    this.maxDateOfBirth = new Date();
    const year = this.maxDateOfBirth.getFullYear() - 18;
    this.maxDateOfBirth.setFullYear(year);
    this.yearRange = year - 100 + ":" + year;
    this.genders = this.constants.genders;
    this.exportTargetColumns = this.targetCols.map((col) => ({
      title: col.header,
      dataKey: col.field,
    }));
    this.armedEscortProfileForm = this.formBuilder.group({
      gender: ["", [Validators.required, Validators.minLength(3)]],
      imageUrl: [""],
      rankId: [null, [Validators.required]],
      armedEscortTypeId: [null, [Validators.required]],
      alias: ["", [Validators.required]],
      dateOfBirth: ["", [Validators.required]],
      lastName: ["", [Validators.required]],
      firstName: ["", [Validators.required]],
    });
    this.fetchAllArmedEscortProfile();
    this.fetchArmedEscortType();
    this.fetchArmedEscortRank();
    this.submitButtonLabel = "create";
    // this.armedEscortProfileForm.get('armedEscortTypeId').valueChanges
    //     .pipe(takeUntil(this.unsubscriber$))
    //     .subscribe(() => {
    //       this.availableArmedEscortRanks = [];
    //       this.armedEscortRanks.forEach((escortRank) => {
    //        if (escortRank.armedEscortTypeId === this.selectedArmedEscortType.id) {
    //          this.availableArmedEscortRanks.push(escortRank);
    //        }
    //       })
    //     })
  }

  fetchAllArmedEscortProfile(): void {
    this.armedEscortProfileService
      .allArmedEscortProfile()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (res: any) => {
          this.armedEscortProfiles = res;
          this.armedEscortProfiles.forEach(
            (userProfile) =>
              (userProfile.fullName = `${userProfile.firstName} ${userProfile.lastName}`)
          );
          this.loading = false;
        },
        (error) => this.connectionError()
      );
  }

  async fetchArmedEscortRank() {
    await this.armedEscortRankService
      .allArmedEscortRank()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (res: any) => {
          this.armedEscortRanks = res;
        },
        (error) => this.connectionError()
      );
  }

  fetchArmedEscortType() {
    this.armedEscortTypeService
      .allArmedEscortType()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (res: any) => {
          this.armedEscortTypes = res;
        },
        (error) => this.connectionError()
      );
  }
  saveArmedEscortProfile() {
    this.submittingData = true;
    if (this.documentFIle) {
      this.fileStorageService.UploadMultipleFilesFromDataUrl([
        this.documentFIle,
      ]);
      this.fileStorageService.onUploadFinished.subscribe(
        (resp: CommonResponse<string[]>) => {
          if (resp.responseCode != "00") {
            this.messageService.add({
              severity: "error",
              summary: "Notice",
              detail: resp.responseMsg,
            });
          } else {
            this.downloadUrl = resp.responseData[0];
            if (!this.editingArmedEscortProfile) {
              this.createArmedEscortProfile();
            } else {
              this.updateArmedEscortProfile();
            }
          }
        },
        (error) => {
          console.log("Error while uploading files " + error);
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail: "ERR: Unable to upload image to storage",
          });
        }
      );
    } else {
      this.downloadUrl = this.edArmedEscortProfile.imageUrl ?? "";
      if (!this.editingArmedEscortProfile) {
        this.createArmedEscortProfile();
      } else {
        this.updateArmedEscortProfile();
      }
    }
  }
  createArmedEscortProfile() {
    const formData = this.armedEscortProfileForm.value;
    const postData = {
      gender: formData.gender.value,
      imageUrl: this.downloadUrl,
      rankId: this.selectedArmedEscortRank.id,
      armedEscortTypeId: this.selectedArmedEscortType.id,
      alias: formData.alias,
      dateOfBirth: formData.dateOfBirth,
      lastName: formData.lastName,
      firstName: formData.firstName,
    };
    this.armedEscortProfileService
      .postArmedEscortProfile(postData)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (result) => {
          this.messageService.add({
            summary: "Success",
            severity: "success",
            detail: "New Escort Profile  created",
          });
          this.fetchAllArmedEscortProfile();
          this.submittingData = false;
          this.armedEscortProfileForm.reset();
          this.closeEditing();
        },
        (error) => {
          this.connectionError();
        }
      );
  }

  deleteArmedEscortProfile(armedEscortProfiles: ArmedEscortProfile): void {
    this.confirmationService.confirm({
      message:
        "Are you sure you want to delete " + armedEscortProfiles.fullName,
      accept: () => {
        this._deleteArmedEscortProfile(armedEscortProfiles);
      },
    });
  }

  editArmedEscortProfile(armedEscortProfiles: ArmedEscortProfile): void {
    this.formWrapper.nativeElement.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "start",
    });
    this.edArmedEscortProfile = armedEscortProfiles;
    this.editingArmedEscortProfile = true;
    this.submitButtonLabel = "Update";
    this.imageSrc = armedEscortProfiles.imageUrl;
    this.armedEscortProfileForm.setValue({
      gender: armedEscortProfiles.gender,
      imageUrl: "",
      rankId: armedEscortProfiles.rankId,
      armedEscortTypeId: armedEscortProfiles.armedEscortTypeId,
      alias: armedEscortProfiles.alias,
      lastName: armedEscortProfiles.lastName,
      firstName: armedEscortProfiles.firstName,
      dateOfBirth: new Date(
        armedEscortProfiles.dateOfBirth ?? this.maxDateOfBirth
      ),
    });
    this.selectedArmedEscortType = this.armedEscortTypes.find(
      (escortType) => escortType.id === armedEscortProfiles.armedEscortTypeId
    );
    this.selectedArmedEscortRank = this.armedEscortRanks.find(
      (rank) => rank.id === armedEscortProfiles.rankId
    );
    this.selectedGender = this.genders.find(
      (gender) => gender.value === armedEscortProfiles.gender
    );
  }

  _deleteArmedEscortProfile(armedEscortProfiles) {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Deleting Situation Type record",
    });
    this.armedEscortProfileService.delete(armedEscortProfiles.id).subscribe(
      async (result: any) => {
        await this.messageService.add({
          severity: "success",
          summary: "Deleted",
          detail: "Situation Type record removed",
        });
        await this.fetchAllArmedEscortProfile();
      },
      (error) => {
        this.connectionError();
      }
    );
  }

  closeEditing() {
    this.editingArmedEscortProfile = false;
    this.armedEscortProfileForm.reset();
    this.edArmedEscortProfile = null;
    this.submitButtonLabel = "Create";
    this.imageSrc = "";
  }

  onFileSelect(event) {
    if (!this.checkMimeType(event) || !this.checkFileSize(event)) {
      this.armedEscortProfileForm.get("imageUrl").reset();
      return;
    }
    const reader = new FileReader();
    this.message = null;
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      this.documentFIle = event.target.files[0];
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.imageSrc = reader.result as string;
      };
    }
  }

  checkMimeType(event) {
    const files = event.target.files[0];
    // list allow mime type
    const types = ["image/jpeg", "image/png", "image/jpg"];
    // loop access array
    if (!files) {
      this.messageService.add({
        severity: "error",
        summary: "Failed",
        detail: "Please select a file to continue",
      });
      this.message = {
        type: "danger",
        value: "Please select a file to continue",
      };
      return false;
    }
    // compare file type find doesn't match
    if (types.every((type) => files.type !== type)) {
      // create error message and assign to container
      this.messageService.add({
        severity: "error",
        summary: "Failed",
        detail: "Image format not supported",
      });
      this.message = { type: "danger", value: "Image format not supported" };
      return false;
    }
    return true;
  }

  checkFileSize(event: EventInput) {
    const files = event.target.files[0];
    if (!files) return true;
    const size = 1500000;
    if (files.size > size) {
      event.target.value = null;
      this.messageService.add({
        severity: "error",
        summary: "Failed",
        detail: "Image is too large, please pick a smaller file",
      });
      this.message = {
        type: "danger",
        value: "Image is too large, please pick a smaller file",
      };
      return false;
    }
    return true;
  }

  private connectionError() {
    this.messageService.add({
      severity: "error",
      summary: "Failed",
      detail: "Connection Error, Please try again",
    });
  }

  ngOnDestroy(): void {
    this.unsubscriber$.complete();
    this.unsubscriber$.unsubscribe();
  }

  setArmedEscortRank() {
    this.availableArmedEscortRanks = [];
    this.armedEscortRanks.forEach((escortRank) => {
      if (escortRank.armedEscortTypeId === this.selectedArmedEscortType.id) {
        this.availableArmedEscortRanks.push(escortRank);
      }
    });
  }

  updateArmedEscortProfile() {
    const formData = this.armedEscortProfileForm.value;
    const postData = {
      gender: formData.gender.value,
      imageUrl: this.downloadUrl,
      rankId: this.selectedArmedEscortRank.id,
      armedEscortTypeId: this.selectedArmedEscortType.id,
      alias: formData.alias,
      dateOfBirth: formData.dateOfBirth,
      lastName: formData.lastName,
      firstName: formData.firstName,
    };
    this.armedEscortProfileService
      .updateArmedEscortProfile(this.edArmedEscortProfile.id, postData)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (result) => {
          this.messageService.add({
            summary: "Success",
            severity: "success",
            detail: "New Escort Profile created",
          });
          this.fetchAllArmedEscortProfile();
          this.submittingData = false;
          this.armedEscortProfileForm.reset();
          this.closeEditing();
        },
        (error) => {
          this.connectionError();
          this.submittingData = false;
        }
      );
  }
}
