import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { MessageService, ConfirmationService, Message } from "primeng/api";
import { BreadcrumbService } from "src/app/breadcrumb.service";
import {
  Contract,
  ContractService,
  CustomerDivision,
  GenerateProfomaInvoiceVM,
  GetGeneratedProfomaInvoiceDetailView,
  ProfomaInvoiceView,
} from "src/app/interfaces/home";
import { ContractServiceService } from "src/app/services/contract-service.service";
import { ContractsService } from "src/app/services/contracts.service";
import { CustomerDivisionService } from "src/app/services/customer-division.service";
import { FireBaseAuthService } from "src/app/services/fire-base-auth.service";
import { InvoiceService } from "src/app/services/invoice.sevice";
import domtoimage from "dom-to-image";
import { jsPDF } from "jspdf";
import { UtilService } from "src/app/services/util.service";

@Component({
  selector: "app-express-performa-inv",
  templateUrl: "./express-performa-inv.component.html",
  styleUrls: ["./express-performa-inv.component.scss"],
  providers: [MessageService],
})
export class ExpressPerformaInvComponent implements OnInit {
  @ViewChild("formWrapper") public formWrapper: ElementRef;
  msg: Message[] = [];
  msg2: Message[] = [];
  allClients: CustomerDivision[];
  theClient: CustomerDivision;
  fetchingContracts: boolean;
  allClientContracts: Contract[];
  theContract: Contract;
  hasSuccessfulValidation: boolean;
  allContractServsToInvoice: ContractService[] = [];
  transactionDateFrom: Date;
  transactionDateTo: Date;
  allGeneratedPerfomaInvoices: ProfomaInvoiceView[];
  selectedGeneratedPerfomaInvoices: ProfomaInvoiceView[] = [];
  cols: any[];
  fetchingContractServices: boolean;
  fetchingClientProfomas: boolean;
  openDetails: boolean;
  fetchingDetails: boolean;
  theProfomaInvoiceInView: ProfomaInvoiceView;
  profomaInvDetails: GetGeneratedProfomaInvoiceDetailView;
  amountInWords: string = "";

  constructor(
    public utilService: UtilService,
    private invoiceService: InvoiceService,
    private clientService: CustomerDivisionService,
    private contractService: ContractServiceService,
    private contractsService: ContractsService,
    public messageService: MessageService,
    private breadcrumbService: BreadcrumbService,
    public confirmationService: ConfirmationService,
    private fireBaseAuthService: FireBaseAuthService
  ) {}

  ngOnInit(): void {
    this.breadcrumbService.setItems([
      {
        label: "Finance",
      },
      {
        label: "Express Profoma",
      },
      {
        label: "Invoice",
        routerLink: ["/home/finance/express-performa-inv"],
      },
    ]);

    this.cols = [
      { field: "invoiceNo", header: "Invoice Number" },
      { field: "invoiceDate", header: "Invoice Date" },
      { field: "invoiceValue", header: "Invoice Value" },
      { field: "isClosedOut", header: "Is Closed Out" },
    ];

    this.FetchAllClients();
    this.ResetMessageToasters();
  }

  ResetMessageToasters() {
    this.msg = [];
    this.msg.push({
      severity: "info",
      summary:
        "This allows you to generate quick profoma invoices and to  close it out.",
    });

    this.msg2 = [];
    this.msg2.push({
      severity: "warn",
      summary:
        "Please note you have a maximum of one month from issuing a profoma invoice to match your profoma invoice against a final invoice.",
    });
  }

  async FetchAllClients() {
    this.clientService.allCustomerDivision().subscribe(
      async (data) => {
        this.allClients = data.responseData;
      },
      (error) => {
        this.messageService.add({
          severity: "error",
          summary: "Failed",
          detail: error ?? "Some errors occurred",
        });
        this.ResetMessageToasters();
      }
    );
  }

  async LoadContractServices(contractId: number) {
    this.fetchingContractServices = true;
    this.allContractServsToInvoice = [];
    this.transactionDateFrom = null;
    this.transactionDateTo = null;
    this.contractService
      .GetMostRecentGroupedContractServices(contractId)
      .subscribe(
        async (data) => {
          if (data.responseCode != "00") {
            this.messageService.add({
              severity: "error",
              summary: data.responseMsg,
            });
            this.ResetMessageToasters();
            this.fetchingContractServices = false;
            return;
          }

          this.allContractServsToInvoice = data.responseData;
          if (this.allContractServsToInvoice.length > 0) {
            // this.transactionDateFrom =
            //   this.allContractServsToInvoice[0].contractStartDate
            //     .toLocaleString()
            //     .slice(0, 10);
            // this.transactionDateTo =
            //   this.allContractServsToInvoice[0].contractEndDate
            //     .toLocaleString()
            //     .slice(0, 10);
          }
          this.fetchingContractServices = false;
        },
        (error) => {
          console.log("Error: " + JSON.stringify(error));
          this.messageService.add({
            severity: "error",
            summary:
              "Unable to load contract services at the moment.. Reason: [" +
              error.error.message +
              "]",
          });
          this.ResetMessageToasters();
          this.fetchingContractServices = false;
        }
      );
  }

  async LoadClientContractProfomaInvoices(contractId: number) {
    this.fetchingClientProfomas = true;
    this.allGeneratedPerfomaInvoices = [];
    this.selectedGeneratedPerfomaInvoices = [];
    this.invoiceService.GetProfomaInvoicesByContractId(contractId).subscribe(
      async (data) => {
        if (data.responseCode != "00") {
          this.messageService.add({
            severity: "error",
            summary: data.responseMsg,
          });
          this.ResetMessageToasters();
          this.fetchingClientProfomas = false;
          return;
        }

        this.allGeneratedPerfomaInvoices = data.responseData;
        this.fetchingClientProfomas = false;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary:
            "Unable to load profoma invoices at the moment.. Reason: [" +
            error.error.message +
            "]",
        });
        this.ResetMessageToasters();
        this.fetchingClientProfomas = false;
      }
    );
  }

  OnClientChange() {
    this.theContract = null;
    this.hasSuccessfulValidation = false;
    this.allClientContracts = [];
    this.allContractServsToInvoice = [];
    this.allGeneratedPerfomaInvoices = [];
    this.selectedGeneratedPerfomaInvoices = [];
    if (this.theClient) {
      this.fetchingContracts = true;
      this.contractsService.GetClientContracts(this.theClient.id).subscribe(
        async (data) => {
          if (data.responseCode != "00") {
            this.messageService.add({
              severity: "error",
              summary: data.responseMsg,
            });
            this.ResetMessageToasters();
            this.fetchingContracts = false;
            return;
          }

          this.allClientContracts = data.responseData;
          this.fetchingContracts = false;
        },
        (error) => {
          console.log("Error: " + JSON.stringify(error));
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail:
              "Unable to get all client contract(s) at the moment.. Reason: [" +
              (error ? error.error.message : "request failed - permission") +
              "]",
          });
          this.ResetMessageToasters();
          this.fetchingContracts = false;
        }
      );
    }
  }

  SelectContract(item: Contract) {
    this.theContract = item;
    this.hasSuccessfulValidation = false;
    this.LoadContractServices(item.id);
    this.LoadClientContractProfomaInvoices(item.id);
  }

  CheckContractValidity() {
    this.confirmationService.confirm({
      message:
        "This action will check the selected contract (Contract-" +
        this.theContract.id +
        ") validity. Do you still wish to proceed?",
      accept: async () => {
        this.messageService.add({
          severity: "info",
          summary: "Notice",
          detail: "Checking contract validity......",
        });
        this.ResetMessageToasters();

        this.contractsService
          .CheckContractValidity(this.theContract.id)
          .subscribe(
            async (data) => {
              if (data.responseCode != "00") {
                this.messageService.add({
                  severity: "error",
                  summary: "Notice",
                  detail: data.responseMsg,
                });
                this.hasSuccessfulValidation = false;
                this.ResetMessageToasters();
                return;
              }

              this.messageService.add({
                severity: "success",
                summary: "Success",
                detail: "Contract validated successfully. [0 Issue(s) found]",
              });
              this.ResetMessageToasters();
              this.hasSuccessfulValidation = true;
              this.LoadContractServices(this.theContract.id);
            },
            (error) => {
              console.log("Error: " + JSON.stringify(error));
              this.messageService.add({
                severity: "error",
                summary: "Notice",
                detail:
                  "Unable to validate selected contract at the moment.. Reason: [" +
                  error.error.message +
                  "]",
              });
              this.ResetMessageToasters();
              this.hasSuccessfulValidation = false;
            }
          );
      },
    });
  }

  ReValidateContract() {
    this.confirmationService.confirm({
      message:
        "This action will re-validate the selected contract (Contract-" +
        this.theContract.id +
        ") and make necessary correction(s). Do you still wish to proceed?",
      accept: async () => {
        this.messageService.add({
          severity: "info",
          summary: "Notice",
          detail: "Revalidating contract......",
        });
        this.ResetMessageToasters();

        this.contractsService.ReValidateContract(this.theContract.id).subscribe(
          async (data) => {
            if (data.responseCode != "00") {
              if (
                data.responseMsg ==
                "You can't revalidate a contract without any issues."
              ) {
                this.messageService.add({
                  severity: "warn",
                  summary: "Info",
                  detail:
                    data.responseMsg +
                    " Please proceed with your endorsement action(s).",
                });
                this.ResetMessageToasters();
                return;
              }

              this.messageService.add({
                severity: "error",
                summary: "Notice",
                detail: data.responseMsg,
              });
              this.ResetMessageToasters();
              return;
            }

            this.messageService.add({
              severity: "success",
              summary: "Success",
              detail:
                "Contract re-validated successfully. [All Issue(s) resolved.]",
            });
            this.theContract = null;
            this.ResetMessageToasters();
          },
          (error) => {
            console.log("Error: " + JSON.stringify(error));
            this.messageService.add({
              severity: "error",
              summary: "Notice",
              detail:
                "Unable to re-validate selected contract the moment.. Reason: [" +
                error.error.message +
                "]",
            });
            this.ResetMessageToasters();
          }
        );
      },
    });
  }

  ReCalculateTotalValue(item: ContractService) {
    if (item.vat > 0) item.isVatable = true;
    item.billableAmount = item.unitPrice * item.quantity;
    if (item.isVatable) {
      item.vat = 0.075 * item.billableAmount;
      item.billableAmount += item.vat;
    }

    let correspondingTie = this.allContractServsToInvoice.find(
      (x) => x.id != item.id && x.adminDirectTie == item.adminDirectTie
    );
    if (correspondingTie && correspondingTie.quantity != item.quantity) {
      correspondingTie.quantity = item.quantity;
      if (correspondingTie.vat > 0) correspondingTie.isVatable = true;
      correspondingTie.billableAmount =
        correspondingTie.unitPrice * correspondingTie.quantity;
      if (correspondingTie.isVatable) {
        correspondingTie.vat = 0.075 * correspondingTie.billableAmount;
        correspondingTie.billableAmount += correspondingTie.vat;
      }
    }
  }

  GeneratePerformaInvoice() {
    this.confirmationService.confirm({
      header: "Confirmation Dialog",
      icon: "pi pi-exclamation-triangle",
      message:
        "Have you confirmed the details and wish to go ahead with generating the profoma invoice?",
      acceptLabel: "Yes, Please Proceed",
      rejectLabel: "No, Don't Proceed",
      accept: async () => {
        this.messageService.add({
          severity: "info",
          summary: "Notice",
          detail: "Generating profoma invoices......",
        });
        this.ResetMessageToasters();

        if (!this.hasSuccessfulValidation) {
          this.messageService.add({
            severity: "error",
            summary: "Failed",
            detail:
              "You need a successful contract validation to proceed, Kindly make use of the 'Check Contract Validity' button.",
          });
          this.ResetMessageToasters();
          return;
        }

        const postData: GenerateProfomaInvoiceVM = {
          contractId: this.theContract.id,
          customerDivisionId: this.theClient.id,
          transactionDateFrom: new Date(this.transactionDateFrom),
          tranactionDateTo: new Date(this.transactionDateTo),
          contractServices: this.allContractServsToInvoice,
        };

        this.invoiceService.GenerateProfomaInvoice(postData).subscribe(
          async (data) => {
            if (data.responseCode != "00") {
              this.messageService.add({
                severity: "error",
                summary: data.responseMsg,
              });
              this.ResetMessageToasters();
              return;
            }

            this.messageService.add({
              severity: "success",
              summary: "Profoma Invoice(s) generated successfully!",
            });
            this.transactionDateTo = null;
            this.transactionDateFrom = null;
            this.ResetMessageToasters();
            this.SelectContract(this.theContract);
          },
          (error) => {
            console.log("Error: " + JSON.stringify(error));
            this.messageService.add({
              severity: "error",
              summary: "Notice",
              detail:
                "Unable to generate profoma invoice at the moment.. Reason: [" +
                error.error.message +
                "]",
            });
            this.ResetMessageToasters();
          }
        );
      },
    });
  }

  FormatDateString(date: Date): string {
    let dateString = date.toString();

    try {
      // DateTimeFormatOptions
      const date: Date = new Date(dateString);
      const options: Intl.DateTimeFormatOptions = {
        year: "numeric",
        month: "long",
        day: "numeric",
      };
      const formattedDate: string = new Intl.DateTimeFormat(
        "en-US",
        options
      ).format(date);
      dateString = formattedDate;
    } catch (error) {
      console.log(error);
      console.log(date);
      console.log(
        "Error while converting date string " + date + " exception " + error
      );
    }

    return dateString;
  }

  ViewGeneratedDetail(item: ProfomaInvoiceView) {
    this.openDetails = true;
    this.fetchingDetails = true;
    this.theProfomaInvoiceInView = item;
    this.invoiceService
      .GetGeneratedProfomaInvoiceDetail(item.transactionId)
      .subscribe(
        async (data) => {
          if (data.responseCode != "00") {
            this.messageService.add({
              severity: "error",
              summary: "Notice",
              detail: data.responseMsg,
            });
            this.ResetMessageToasters();
            this.fetchingDetails = false;
            return;
          }

          this.profomaInvDetails = data.responseData;
          let koboPart = 0;
          let amount = data.responseData.total.toFixed(2);
          let doubleAmt = amount.toString();
          var splitAmt = doubleAmt.split(".");
          var nairaPart = parseInt(splitAmt[0]);
          this.amountInWords =
            this.utilService.NumberToWords(nairaPart) + " Naira";
          if (splitAmt.length > 1) {
            koboPart = parseInt(splitAmt[1]);
            if (koboPart > 0) {
              this.amountInWords +=
                " " + this.utilService.NumberToWords(koboPart) + " Kobo";
            }
          }
          this.fetchingDetails = false;
        },
        (error) => {
          console.log("Error: " + JSON.stringify(error));
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail:
              "Unable to get generated profoma invoice details at the moment.. Reason: [" +
              error.error.message +
              "]",
          });
          this.ResetMessageToasters();
          this.fetchingDetails = false;
        }
      );
  }

  DeleteGeneratedProfoma(item: ProfomaInvoiceView) {
    this.confirmationService.confirm({
      header: "Confirmation Dialog",
      icon: "pi pi-exclamation-triangle",
      message:
        "Are you sure you want to delete this profoma invoice, this is an irreversible action. Do you still wish to proceed?",
      acceptLabel: "Yes, Please Proceed",
      rejectLabel: "No, Don't Proceed",
      accept: async () => {
        this.messageService.add({
          severity: "info",
          summary: "Notice",
          detail: "Deleting profoma invoices......",
        });
        this.ResetMessageToasters();

        this.invoiceService
          .RemoveGenerateProfomaInvoices(item.transactionId)
          .subscribe(
            async (data) => {
              if (data.responseCode != "00") {
                this.messageService.add({
                  severity: "error",
                  summary: data.responseMsg,
                });
                this.ResetMessageToasters();
                return;
              }

              this.messageService.add({
                severity: "success",
                summary: "Profoma Invoice(s) removed successfully!",
              });
              this.ResetMessageToasters();
              this.LoadClientContractProfomaInvoices(this.theContract.id);
            },
            (error) => {
              console.log("Error: " + JSON.stringify(error));
              this.messageService.add({
                severity: "error",
                summary: "Notice",
                detail:
                  "Unable to remove profoma invoice at the moment.. Reason: [" +
                  error.error.message +
                  "]",
              });
              this.ResetMessageToasters();
            }
          );
      },
    });
  }

  HideDetails() {
    this.openDetails = false;
    this.theProfomaInvoiceInView = null;
  }

  PdfDownloadPage() {
    var node = document.getElementById("parentdiv");
    var img;
    var filename;
    var newImage;
    domtoimage
      .toPng(node, { bgcolor: "#fff" })
      .then(function (dataUrl) {
        img = new Image();
        img.src = dataUrl;
        newImage = img.src;
        img.onload = function () {
          var pdfWidth = img.width;
          var pdfHeight = img.height;
          // FileSaver.saveAs(dataUrl, 'my-pdfimage.png'); // Save as Image
          var doc;
          if (pdfWidth > pdfHeight) {
            doc = new jsPDF("l", "px", [pdfWidth, pdfHeight]);
          } else {
            doc = new jsPDF("p", "px", [pdfWidth, pdfHeight]);
          }
          var width = doc.internal.pageSize.getWidth();
          var height = doc.internal.pageSize.getHeight();

          doc.addImage(newImage, "PNG", 10, 10, width, height);
          filename = "receipt" + ".pdf";
          doc.save(filename);
        };
      })
      .catch(function (error) {
        // Error Handling
      });
  }

  PrintPage() {
    window.print();
  }

  ConfirmSendEmail() {
    this.confirmationService.confirm({
      message: "Are you sure you want to send this profoma invoice ?",
      header: "Confirmation",
      icon: "pi pi-exclamation-triangle",
      accept: () => {
        this.messageService.add({
          severity: "info",
          summary: "Notice",
          detail: "Sending Invoice",
        });

        this.invoiceService
          .SendProfomaInvoice(this.theProfomaInvoiceInView.transactionId)
          .subscribe(
            async (data) => {
              if (data.responseCode != "00") {
                this.messageService.add({
                  severity: "error",
                  summary: data.responseMsg,
                });
                this.ResetMessageToasters();
                return;
              }

              this.messageService.add({
                severity: "success",
                summary: "Profoma Invoice(s) sent successfully!",
              });
            },
            (error) => {
              console.log("Error: " + JSON.stringify(error));
              this.messageService.add({
                severity: "error",
                summary: "Notice",
                detail:
                  "Unable to send profoma invoice at the moment.. Reason: [" +
                  error.error.message +
                  "]",
              });
              this.ResetMessageToasters();
            }
          );
      },
      reject: (type) => {
        this.messageService.add({
          severity: "error",
          summary: "Rejected",
          detail: "Declined Sending Invoice",
        });
      },
    });
  }

  get InvoicesTotalValue(): number {
    let totalSum = 0;
    this.allContractServsToInvoice.forEach((x) => {
      totalSum += x.billableAmount;
    });

    return totalSum;
  }
}
