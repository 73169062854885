import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { LeadAdminRoutingModule } from "./lead-admin-routing.module";
import { LeadCaptureComponent } from "./lead-capture/lead-capture.component";
import { LeadQualificationComponent } from "./lead-qualification/lead-qualification.component";
import { LeadNegotiationComponent } from "./lead-negotiation/lead-negotiation.component";
import { ConversionComponent } from "./conversion/conversion.component";
import { DragDropModule } from "primeng/dragdrop";
import { TableModule } from "primeng/table";
import { ButtonModule } from "primeng/button";
import { RippleModule } from "primeng/ripple";
import { TreeModule } from "primeng/tree";
import { ProgressSpinnerModule } from "primeng/progressspinner";
import { ToastModule } from "primeng/toast";
import { LeadAdminComponent } from "./lead-admin.component";
import { StepsModule } from "primeng/steps";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { CardModule } from "primeng/card";
import { InputTextModule } from "primeng/inputtext";
import { MultiSelectModule } from "primeng/multiselect";
import { FileUploadModule } from "primeng/fileupload";
import { TabViewModule } from "primeng/tabview";
import { DropdownModule } from "primeng/dropdown";
import { ConfirmDialogModule } from "primeng/confirmdialog";
import { PrimaryContactComponent } from "./lead-capture/primary-contact/primary-contact.component";
import { SecondaryContactComponent } from "./lead-capture/secondary-contact/secondary-contact.component";
import { KeyPersonComponent } from "./lead-capture/key-person/key-person.component";
import { LeadDivisionComponent } from "./lead-capture/lead-division/lead-division.component";
import { KeyFilterModule } from "primeng/keyfilter";
import { DivisionPrimaryContactComponent } from "./lead-capture/lead-division/division-primary-contact/division-primary-contact.component";
import { DivisionSecondaryContactComponent } from "./lead-capture/lead-division/division-secondary-contact/division-secondary-contact.component";
import { FinancialInformationComponent } from "./lead-capture/financial-information/financial-information.component";
import { FieldsetModule } from "primeng/fieldset";
import { ServiceQualifyComponent } from "./lead-qualification/service-qualify/service-qualify.component";
import { DynamicDialogModule } from "primeng/dynamicdialog";
import { PanelModule } from "primeng/panel";
import { DivisionKeyPersonComponent } from "./lead-capture/lead-division/division-key-person/division-key-person.component";
import { ServiceDocumentComponent } from "./lead-negotiation/service-document/service-document.component";
import { CheckboxModule } from "primeng/checkbox";
import { TriStateCheckboxModule } from "primeng/tristatecheckbox";
import { ServiceDocumentFormComponent } from "./lead-negotiation/service-document-form/service-document-form.component";
import { InputTextareaModule } from "primeng/inputtextarea";
import { ServiceClosureComponent } from "./conversion/service-closure/service-closure.component";
import { InputNumberModule } from "primeng/inputnumber";
import { LeadDropComponent } from "./lead-drop/lead-drop.component";
import { LeadDropFormComponent } from "./lead-drop/lead-drop-form/lead-drop-form.component";
import { AutoCompleteModule } from "primeng/autocomplete";
import { OverlayPanelModule } from "primeng/overlaypanel";
import { ListboxModule } from "primeng/listbox";
import { CalendarModule } from "primeng/calendar";
import { DialogModule } from "primeng/dialog";
import { LeadProgressDocumentComponent } from "./lead-progress-document/lead-progress-document.component";
import { FinancialInfoViewComponent } from "./financial-info-view/financial-info-view.component";
import { InputSwitchModule } from "primeng/inputswitch";
import { GroupInvoiceFormComponent } from "./group-invoice-form/group-invoice-form.component";
import { TooltipModule } from "primeng/tooltip";
import { CreateKeyPersonComponent } from "./create-key-person/create-key-person.component";
import { CaptureLeadEngagementComponent } from "./capture-lead-engagement/capture-lead-engagement.component";
import { ViewQuoteComponent } from "./lead-qualification/view-quote-component/view-quote.component";
import { DataViewModule } from "primeng/dataview";
import { AvatarModule } from "primeng/avatar";
import { BadgeModule } from "primeng/badge";

@NgModule({
  declarations: [
    LeadAdminComponent,
    LeadCaptureComponent,
    LeadQualificationComponent,
    LeadNegotiationComponent,
    ConversionComponent,
    PrimaryContactComponent,
    SecondaryContactComponent,
    KeyPersonComponent,
    LeadDivisionComponent,
    DivisionPrimaryContactComponent,
    DivisionSecondaryContactComponent,
    FinancialInformationComponent,
    ServiceQualifyComponent,
    DivisionKeyPersonComponent,
    ServiceDocumentComponent,
    ServiceDocumentFormComponent,
    ServiceClosureComponent,
    LeadDropComponent,
    LeadDropFormComponent,
    LeadProgressDocumentComponent,
    FinancialInfoViewComponent,
    GroupInvoiceFormComponent,
    CreateKeyPersonComponent,
    CaptureLeadEngagementComponent,
    ViewQuoteComponent,
  ],
  imports: [
    CommonModule,
    LeadAdminRoutingModule,
    DragDropModule,
    TableModule,
    ButtonModule,
    RippleModule,
    TreeModule,
    ProgressSpinnerModule,
    ToastModule,
    StepsModule,
    FormsModule,
    CardModule,
    InputTextModule,
    MultiSelectModule,
    FileUploadModule,
    ReactiveFormsModule,
    TabViewModule,
    DropdownModule,
    ConfirmDialogModule,
    KeyFilterModule,
    FieldsetModule,
    DynamicDialogModule,
    PanelModule,
    CheckboxModule,
    TriStateCheckboxModule,
    InputTextareaModule,
    InputNumberModule,
    AutoCompleteModule,
    OverlayPanelModule,
    ListboxModule,
    CalendarModule,
    DialogModule,
    InputSwitchModule,
    TooltipModule,
    DataViewModule,
    AvatarModule,
    BadgeModule,
  ],
  entryComponents: [
    ServiceQualifyComponent,
    ServiceDocumentComponent,
    ServiceDocumentFormComponent,
    ServiceClosureComponent,
    GroupInvoiceFormComponent,
    ServiceDocumentFormComponent,
    LeadDropFormComponent,
    LeadProgressDocumentComponent,
    CreateKeyPersonComponent,
    CaptureLeadEngagementComponent,
    ViewQuoteComponent,
  ],
})
export class LeadAdminModule {}
