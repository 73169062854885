import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {SituationType} from '../../interfaces/armada';

@Injectable({
  providedIn: 'root'
})
export class SituationTypeService {

  constructor(
      private httpClient: HttpClient,
  ) { }
  baseUrl = environment.armadaBaseUrl + 'SituationType/';
  allSituationTypes(): Observable<SituationType>{
    return this.httpClient.get<SituationType>(this.baseUrl);
  }
  getSituationType(id: any):Observable<SituationType>{
    return this.httpClient.get<SituationType>(this.baseUrl +id);
  }
  getSituationTypeByName(name: any):Observable<SituationType>{
    return this.httpClient.get<SituationType>(this.baseUrl + 'name/' + name);
  }
  postSituationType(data: any){
    return this.httpClient.post(this.baseUrl, data);
  }
  deleteSituationType(id: any){
    return this.httpClient.delete(this.baseUrl +id);
  }
  updateSituationType(id: any, data: any){
    return this.httpClient.put(this.baseUrl +id, data);
  }
}
