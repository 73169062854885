import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ClientContactQualification, CommonResponse, Constant, Contact, Designation, Lead, LeadDivision} from '../../../../../interfaces/home';
import {LeadService} from '../../../../../services/lead.service';
import {LeadContactService} from '../../../../../services/lead-contact.service';
import {ConfirmationService, MessageService} from 'primeng/api';
import {LeadDivisionService} from '../../../../../services/lead-division.service';
import {LeadDivisionContactService} from '../../../../../services/lead-division-contact.service';
import { DesignationService } from 'src/app/services/designation.service';
import { ClientContactQualificationService } from 'src/app/services/client-contact-qualification.service';

@Component({
  selector: 'app-division-primary-contact',
  templateUrl: './division-primary-contact.component.html',
  styleUrls: ['./division-primary-contact.component.scss'],
  providers: [Constant]
})
export class DivisionPrimaryContactComponent implements OnInit {
  @Input() suggestedPrimaryContact: Contact | null;
  divisionPrimaryContactForm: FormGroup;
  CurrentLeadDivisionData: LeadDivision = null;
  editingContact: boolean;
  titles : any[] = [];
  selectedTitle : any = null;
  genders : any[] = [];
  selectedGender : any = null;
  maxDateOfBirth: Date;
  public yearRange: string;
  designations: Designation[] = [];
  selectedDesignation: Designation = null;
  clientContactQualifications: ClientContactQualification[] = [];
  selectedClientContactQualification: ClientContactQualification = null;
  constructor(
      public leadService: LeadService,
      private formBuilder: FormBuilder,
      private leadDivisionContactService: LeadDivisionContactService,
      private designationService: DesignationService,
      private clientContactQualificationService: ClientContactQualificationService,
      private messageService: MessageService,
      private confirmationService: ConfirmationService,
      private leadDivisionService: LeadDivisionService,
      private constants: Constant
  ) { }
  ngOnInit(): void {
    this.fetchDesignations();
    this.fetchContactQualifications();
    this.editingContact = false;
    this.titles = this.constants.titles;
    this.genders = this.constants.genders;
    this.maxDateOfBirth = new Date();
    const year = this.maxDateOfBirth.getFullYear() -18;
    this.maxDateOfBirth.setFullYear(year);
    this.yearRange = (year - 100) + ':' + year;
    this.divisionPrimaryContactForm = this.formBuilder.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      mobileNumber: ['', Validators.required],
      designation: [null, Validators.required],
      contactQualification: [null, Validators.required],
      dateOfBirth: [new Date(this.maxDateOfBirth)],
      title: ['',  Validators.required],
      gender: ['',  Validators.required],
      type: 0,
      leadDivisionId: '',
    });
    this.divisionPrimaryContactForm.disable();
    this.leadDivisionService.activeLeadDivision().subscribe( async (res: any) => {
      this.CurrentLeadDivisionData = res;
      this.divisionPrimaryContactForm.reset();
      if(res !== null && res){
        await this.divisionPrimaryContactForm.enable();
        const _primaryContact = res.primaryContactId;
        if(_primaryContact && _primaryContact > 0){
          this.divisionPrimaryContactForm.disable();
          this.leadDivisionContactService.getLeadDivisionContact(_primaryContact).subscribe( (r: any) => {
            if(r?.responseCode=='00'){
              var result = r.responseData;
            this.divisionPrimaryContactForm.patchValue({
              firstName: result.firstName,
              lastName: result.lastName,
              email: result.email,
              mobileNumber: result.mobileNumber,
              dateOfBirth: result.dateOfBirth,
              type: 0,
            });
            this.selectedTitle = this.titles.find(title => title.value.toLowerCase() === result.title.toLowerCase());
            this.selectedGender = this.genders.find(gender => gender.value.toLowerCase() === result.gender.toLowerCase());
            this.selectedDesignation = this.designations.find(x => x.id === result.designationId);
            this.selectedClientContactQualification = this.clientContactQualifications
              .find(x => x.id === result.clientContactQualificationId);
            }
          })
        }
      }
    });
  }
  createContact() {
    this.messageService.add({severity:'info', summary: 'Notice',
      detail:'Processing Primary Contact Creation'});
    this.divisionPrimaryContactForm.get('leadDivisionId').setValue(this.CurrentLeadDivisionData.id);
    this.divisionPrimaryContactForm.get('type').setValue(0);
    const postData= this.divisionPrimaryContactForm.value;
    postData.gender = this.divisionPrimaryContactForm.value.gender.value;
    postData.title = this.divisionPrimaryContactForm.value.title.value;
    postData.designationId = this.selectedDesignation.id;
    postData.clientContactQualificationId = this.selectedClientContactQualification.id;
    if(this.divisionPrimaryContactForm.valid) {
      this.leadDivisionContactService.postLeadDivisionContact(this.CurrentLeadDivisionData.id, postData).subscribe( async (res: any) => {
        await this.messageService.add({severity:'success', summary: 'Notice',
          detail:'Primary contact Submitted'});
        await this.divisionPrimaryContactForm.reset();
        // redirect and set the current tab active
        this.leadService.refreshData();
      } ) ;
    }
  }
  fillSuggestedContact() {
    if(!this.suggestedPrimaryContact){
      this.messageService.add({
        severity: 'error',
        detail: 'Resources not loaded',
        summary: 'Notice'
      })
      return;
    }
    this.divisionPrimaryContactForm.patchValue({
      firstName: this.suggestedPrimaryContact.firstName,
      lastName: this.suggestedPrimaryContact.lastName,
      email: this.suggestedPrimaryContact.email,
      mobileNumber: this.suggestedPrimaryContact.mobileNumber,
      dateOfBirth: this.suggestedPrimaryContact.dateOfBirth,
      type: 0,
    })
    this.selectedDesignation = this.designations.find(x => x.id === this.suggestedPrimaryContact.designationId);
  }

  async fetchDesignations() {
    await this.designationService.allDesignationData().subscribe(async (res: CommonResponse) => {
        this.designations = res.responseData;
    }, error => this.connectionError());  
  }

  async fetchContactQualifications() {
    await this.clientContactQualificationService.allClientContactQualificationData().subscribe(async (res: CommonResponse) => {
        this.clientContactQualifications = res.responseData;
    }, error => this.connectionError());  
  }

  updateContact() {
    this.messageService.add({severity:'info', summary: 'Notice',
      detail:'Processing Primary Contact Update'});
    const postData= this.divisionPrimaryContactForm.value;
    postData.gender = this.divisionPrimaryContactForm.value.gender.value;
    postData.title = this.divisionPrimaryContactForm.value.title.value;
    postData.designationId = this.selectedDesignation.id;
    postData.clientContactQualificationId = this.selectedClientContactQualification.id;
    if(this.divisionPrimaryContactForm.valid) {
      this.leadDivisionContactService.updateLeadDivisionContact(this.CurrentLeadDivisionData.primaryContact.id, postData).subscribe( (res: any) => {
        this.messageService.add({severity:'success', summary: 'Notice',
          detail:'Primary contact Submitted'});
          this.cancelEditContact();
          this.leadService.refreshData();    
      } ) ;
    }
  }

  cancelEditContact() {
    console.log('In primary cancel edit contact')
    this.divisionPrimaryContactForm.disable();
    this.editingContact = false;
    const _primaryContact = this.CurrentLeadDivisionData.primaryContact;
    if(_primaryContact){
      this.divisionPrimaryContactForm.disable();
      this.divisionPrimaryContactForm.patchValue({
        firstName: _primaryContact.firstName,
        lastName: _primaryContact.lastName,
        email: _primaryContact.email,
        mobileNumber: _primaryContact.mobileNumber,
        dateOfBirth: _primaryContact.dateOfBirth,
        type: 0
      });
      this.selectedTitle = this.titles.find(title => title.value.toLowerCase() === _primaryContact.title.toLowerCase());
      this.selectedGender = this.genders.find(gender => gender.value.toLowerCase() === _primaryContact.gender.toLowerCase());
      this.selectedDesignation = this.designations.find(x => x.id === _primaryContact.designationId);
      this.selectedClientContactQualification = this.clientContactQualifications
        .find(x => x.id === _primaryContact.clientContactQualificationId);
    }
  }

  startEditing(){
    this.divisionPrimaryContactForm.enable();
    this.editingContact = true;
  }

  private connectionError() {
    this.messageService.add({
        severity: 'error', summary: 'Failed',
        detail: 'Connection Error, Please try again'
    });
  }
}
