import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { ComplaintSource } from "../interfaces/complaint-source";
import { CommonResponse } from "../interfaces/home";

@Injectable({
  providedIn: "root",
})
export class ComplaintSourceService {
  baseUrl = environment.baseUrl + "ComplaintSource/";

  constructor(private http: HttpClient) {}

  getAll(): Observable<CommonResponse> {
    return this.http.get<CommonResponse>(this.baseUrl);
  }

  getByID(id: number): Observable<CommonResponse> {
    return this.http.get<CommonResponse>(this.baseUrl + id);
  }

  getByCaptionName(name: string): Observable<CommonResponse> {
    return this.http.get<CommonResponse>(this.baseUrl + "/caption/" + name);
  }

  create(data: ComplaintSource) {
    return this.http.post(this.baseUrl, data);
  }

  delete(id: number) {
    return this.http.delete(this.baseUrl + id);
  }

  update(id: any, data: ComplaintSource) {
    return this.http.put(this.baseUrl + id, data);
  }
}
