import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../environments/environment";
import { Observable } from "rxjs";
import {
  ApprovalLevel,
  ApprovalLevelSequence,
  ApprovingLevelOfficeReceivingDTO,
  CommonResponse,
  CreateApprovingLevelSequenceVM,
} from "../interfaces/home";

@Injectable({
  providedIn: "root",
})
export class ApprovalLevelService {
  constructor(private http: HttpClient) {}
  baseUrl = environment.baseUrl + "ApproverLevel/";
  // fetch all ApprovalLimit in the database
  allApprovalLevelData(): Observable<CommonResponse<ApprovalLevel[]>> {
    return this.http.get<CommonResponse>(this.baseUrl);
  }

  getApprovalLevel(id: any): Observable<CommonResponse> {
    return this.http.get<CommonResponse>(this.baseUrl + id);
  }

  getApprovalLevelByName(name: any): Observable<CommonResponse> {
    return this.http.get<CommonResponse>(this.baseUrl + "name/" + name);
  }

  postApprovalLevel(data: any) {
    return this.http.post(this.baseUrl, data);
  }

  deleteApprovalLevel(id: any) {
    return this.http.delete(this.baseUrl + id);
  }

  updateApprovalLevel(id: any, data: any) {
    return this.http.put(this.baseUrl + id, data);
  }

  CreateApprovingLevelOffice(
    data: ApprovingLevelOfficeReceivingDTO
  ): Observable<CommonResponse> {
    return this.http.post<CommonResponse>(
      this.baseUrl + "CreateApprovingLevelOffice",
      data
    );
  }

  GetAllApprovingLevelOffices(): Observable<CommonResponse> {
    return this.http.get<CommonResponse>(
      this.baseUrl + "GetAllApprovingLevelOffices"
    );
  }

  DeleteApprovingLevelOfficeById(id: any) {
    return this.http.delete(
      this.baseUrl + "DeleteApprovingLevelOfficeById/" + id
    );
  }

  UpdateApprovingLevelOfficeById(
    id: number,
    data: ApprovingLevelOfficeReceivingDTO
  ) {
    return this.http.put(
      this.baseUrl + "UpdateApprovingLevelOfficeById/" + id,
      data
    );
  }

  GetAllApprovingLevelSequences(): Observable<
    CommonResponse<ApprovalLevelSequence[]>
  > {
    return this.http.get<CommonResponse<ApprovalLevelSequence[]>>(
      this.baseUrl + "GetAllApprovingLevelSequences"
    );
  }

  CreateApprovingLevelSequence(
    data: CreateApprovingLevelSequenceVM
  ): Observable<CommonResponse> {
    return this.http.post<CommonResponse>(
      this.baseUrl + "CreateApprovingLevelSequence",
      data
    );
  }

  DeleteApprovingLevelSequence(id: number): Observable<CommonResponse> {
    return this.http.delete<CommonResponse>(
      this.baseUrl + "DeleteApprovingLevelSequence/" + id
    );
  }

  UpdateApprovalLevelSequence(
    id: number,
    isUpward: boolean
  ): Observable<CommonResponse> {
    return this.http.put<CommonResponse>(
      this.baseUrl + "UpdateApprovalLevelSequence/" + id + "/" + isUpward,
      null
    );
  }
}
