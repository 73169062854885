import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { errorMonitor } from "events";
import { ConfirmationService, MessageService } from "primeng/api";
import {
  DialogService,
  DynamicDialogConfig,
  DynamicDialogRef,
} from "primeng/dynamicdialog";
import { CommonResponse, InvoiceMailDTO } from "src/app/interfaces/home";
import { InvoiceService } from "src/app/services/invoice.sevice";
import { UtilService } from "src/app/services/util.service";
import domtoimage from "dom-to-image";
import { jsPDF } from "jspdf";

import { ViewReceiptComponent } from "../../receipting/view-receipt/view-receipt.component";

@Component({
  selector: "app-view-usage",
  templateUrl: "./view-usage.component.html",
  styleUrls: ["./view-usage.component.scss"],
})
export class ViewUsageComponent implements OnInit {
  invoiceDetails: InvoiceMailDTO;
  loadingSpinner: boolean;
  isGroupedAdhoc = false;
  label = "Invoice";
  amountInWords: string = "";
  usageList: any[];
  pay: any;
  usageTotal: number;

  constructor(
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private invoiceService: InvoiceService,
    public modalConfig: DynamicDialogConfig,
    public utilService: UtilService,
    public dialogService: DialogService,
    private dialogRef: DynamicDialogRef
  ) {}

  ngOnInit(): void {
    this.loadingSpinner = false;
    this.pay = this.modalConfig.data.pay;
    this.usageList = this.pay.advancePaymentUsages;

    this.calcalateTotal();
    this.loadingSpinner = false;
  }

  printPage() {
    window.print();
  }

  pdfDownloadPage() {
    // window.print();

    var node = document.getElementById("parentdiv");
    var img;
    var filename;
    var newImage;
    domtoimage
      .toPng(node, { bgcolor: "#fff" })
      .then(function (dataUrl) {
        img = new Image();
        img.src = dataUrl;
        newImage = img.src;
        img.onload = function () {
          var pdfWidth = img.width;
          var pdfHeight = img.height;
          // FileSaver.saveAs(dataUrl, 'my-pdfimage.png'); // Save as Image
          var doc;
          if (pdfWidth > pdfHeight) {
            doc = new jsPDF("l", "px", [pdfWidth, pdfHeight]);
          } else {
            doc = new jsPDF("p", "px", [pdfWidth, pdfHeight]);
          }
          var width = doc.internal.pageSize.getWidth();
          var height = doc.internal.pageSize.getHeight();

          doc.addImage(newImage, "PNG", 10, 10, width, height);
          filename = "payment-usage" + ".pdf";
          doc.save(filename);
        };
      })
      .catch(function (error) {
        // Error Handling
      });
  }

  cancel() {
    this.dialogRef.close();
  }

  sendInvoice(invoiceId: number) {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Sending Invoice",
    });

    this.invoiceService.sendInvoice(invoiceId).subscribe(
      async (res: CommonResponse) => {
        var response = res.responseData as boolean;
        if (response)
          this.messageService.add({
            severity: "success",
            summary: "Successful",
            detail: "Invoice Sent",
          });
      },
      (error) => {
        this.messageService.add({
          severity: "errorr",
          summary: "Failed",
          detail: error.error.message,
        });
      }
    );
  }

  calcalateTotal() {
    var amountTotal = this.usageList.reduce((a, b) => {
      return a + b.amountUsed;
    }, 0);

    let koboPart = 0;
    this.usageTotal = amountTotal.toFixed(2);
    let doubleAmt = this.usageTotal.toString();
    var splitAmt = doubleAmt.split(".");
    var nairaPart = parseInt(splitAmt[0]);
    this.amountInWords = this.utilService.NumberToWords(nairaPart) + " Naira";
    if (splitAmt.length > 1) {
      koboPart = parseInt(splitAmt[1]);
      if (koboPart > 0) {
        this.amountInWords +=
          " " + this.utilService.NumberToWords(koboPart) + " Kobo";
      }
    }
  }
}
