import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { AngularFireStorage } from "@angular/fire/storage";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ConfirmationService, MessageService, TreeNode } from "primeng/api";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { BreadcrumbService } from "src/app/breadcrumb.service";
import {
  PriceRegister,
  SMORegion,
  SMORoute,
  SMORouteAndSateMap,
  ToAndFroMasterStateMap,
  ToStateMapDetails,
  FroStateMapDetails,
} from "src/app/interfaces/armada";
import { CommonResponse, State } from "src/app/interfaces/home";
import { SMORouteService } from "src/app/services/armada/smoroute.service";
import { InventoryService } from "src/app/services/inventory.service";
import { StateService } from "src/app/services/state.service";

@Component({
  selector: "app-route-state-maps",
  templateUrl: "./route-state-maps.component.html",
  styleUrls: ["./route-state-maps.component.scss"],
})
export class RouteStateMapsComponent implements OnInit {
  @ViewChild("formWrapper") public formWrapper: ElementRef;
  private unsubscriber$ = new Subject<void>();
  priceRegisterTree: TreeNode[];
  // selectedStore: CentralStore | RegionalStore | number;
  selectedStore: any;
  centralStoreName: any;
  loadingPriceRegisters: boolean = true;
  cantCreateRouteUnder: boolean;
  public targetCols: any;
  private exportTargetColumns: any;
  public submittingData: boolean;
  public submittingToandFroData: boolean;
  public loading: boolean;
  public submitButtonLabel: string;
  public sMORouteAndSateMaps: SMORouteAndSateMap[];
  public selectedRouteMap: SMORouteAndSateMap = null;
  public toAndFroMasterStateMap: ToAndFroMasterStateMap[];
  public selectedToAndFroMasterStateMap: ToAndFroMasterStateMap;
  public toStateMapDetails: ToStateMapDetails[];
  public selectedToStateMapDetails: ToStateMapDetails;
  public froStateMapDetails: FroStateMapDetails[];
  public selectedFroStateMapDetails: FroStateMapDetails;
  //public allSMORoutesAndRegion: AllSMORoutesAndRegion;
  public edPriceRegister: PriceRegister;
  public routeMapForm: FormGroup;
  public toandFroStateMapForm: FormGroup;

  editingPriceRegister = false;
  userForm: FormGroup;
  routeDetailTable: {
    //name: string;
    data: string;
  }[];
  public smoRoutes: SMORoute[];
  selectedSMORoutes: SMORoute;

  public smoRegions: SMORegion[];
  selectedSMORegions: SMORegion;

  allStates: State[];
  //selectedMultiState: State;
  public selectedMultiState: State[] = [];
  // public selectedFroMultiState: State[] = [];
  // public selectedToMultiState: State[] = [];
  public selectedFroMultiState: SMORouteAndSateMap[] = [];
  public selectedToMultiState: SMORouteAndSateMap[] = [];
  constructor(
    public messageService: MessageService,
    public confirmationService: ConfirmationService,
    public fireStorage: AngularFireStorage,
    public inventoryService: InventoryService,
    private formBuilder: FormBuilder,
    private breadcrumbService: BreadcrumbService,
    public stateService: StateService,
    private sMORouteService: SMORouteService
  ) {
    this.breadcrumbService.setItems([
      { label: "Setup" },
      {
        label: "Route & State Map",
        routerLink: ["/setup/route-state-maps"],
      },
    ]);
  }

  ngOnInit(): void {
    this.targetCols = [
      { field: "Route", header: "Route" },
      { field: "State", header: "State" },
      { field: "Comment", header: "Comment" },
    ];
    this.exportTargetColumns = this.targetCols.map((col) => ({
      title: col.header,
      dataKey: col.field,
    }));
    this.routeMapForm = this.formBuilder.group({
      stateId: [null, [Validators.required]],
      //smoRouteId: [{ value: null, disabled: true }, [Validators.required]],
      //smoRegionId: [null, [Validators.required]],
      // smoRouteId: [null, [Validators.required]],
      // smoRegionId: [null, [Validators.required]],
      comment: [""],
    });
    this.toandFroStateMapForm = this.formBuilder.group({
      toStateMapId: [null, [Validators.required]],
      froStateMapId: [null, [Validators.required]],
      //smoRouteId: [null, [Validators.required]],
      comment: ["", [Validators.required]],
    });

    this.fetchAllRegions();
    this.FetchAllRoutesAndRegions();
    //this.fetchAllSMORoutes();
    this.fetchAllStates();
    this.submitButtonLabel = "Map State";
  }

  //Route and Region
  fetchAllSMORoutes(): void {
    this.sMORouteService
      .allRoutes()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            var res = r.responseData;
            this.smoRoutes = res;
            console.log(res);
            this.loading = false;
          }
        },
        (error) => {
          this.loading = false;
          this.connectionError();
        }
      );
    this.submittingData = false;
  }
  fetchAllRegions(): void {
    this.sMORouteService
      .allRegions()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            var res = r.responseData;
            this.smoRegions = res;
            this.fetchAllSMORoutes();
            console.log(res);
            this.loading = false;
          }
        },
        (error) => {
          this.loading = false;
          this.connectionError();
        }
      );
    this.submittingData = false;
  }
  FetchAllRoutesAndRegions() {
    this.sMORouteService
      .allRegions()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            var res = r.responseData;
            this.smoRegions = res;

            console.log(res);
            this.loading = false;
          }
        },
        (error) => {
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail:
              "Unable to fetch all inventory stores at the moment.. Reason: [" +
              error.error.message +
              "]",
          });
        }
      );

    this.sMORouteService
      .allRoutes()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            var res = r.responseData;
            this.smoRoutes = res;
            this.PopulateRouteTree();
            console.log(res);
            this.loading = false;
          }
        },
        (error) => {
          this.loading = false;
          this.connectionError();
        }
      );
  }
  fetchAllStates() {
    this.stateService
      .allState()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            var res = r.responseData;
            this.allStates = res;
            console.log(res);
            this.loading = false;
          }
        },
        (error) => {
          this.loading = false;
          this.connectionError();
        }
      );
    this.submittingData = false;
  }

  //
  createRouteMap() {
    this.submittingData = true;
    const formData = this.routeMapForm.value;
    const postData = {
      //smoRouteId: formData.smoRouteId,
      smoRouteId: this.selectedStore.id,
      smoRegionId: this.selectedStore.smoRegionId,
      stateId: this.selectedMultiState.map((x) => x.id),
      comment: formData.comment,
    };
    this.sMORouteService
      .postRouteMap(postData)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            this.messageService.add({
              summary: "Success",
              severity: "success",
              detail: "New Route Mapped",
            });
            this.fetchAllRouteMapsByRouteId();
            this.submittingData = false;
            this.routeMapForm.reset();
            //this.closeEditing();
          } else {
            this.messageService.add({
              severity: "error",
              summary: "Failed",
              detail: " Error: " + r.responseMsg,
            });
            this.submittingData = false;
          }
        },
        (error) => {
          this.messageService.add({
            severity: "error",
            summary: "Failed",
            detail: "Connection Error " + error.error.message,
          });
          this.submittingData = false;
        }
      );
    this.submittingData = false;
  }

  fetchAllRouteMaps(): void {
    this.sMORouteService
      .allRouteMaps()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            var res = r.responseData;
            this.sMORouteAndSateMaps = res;
            console.log(res);
            this.loading = false;
          }
        },
        (error) => {
          this.loading = false;
          this.connectionError();
        }
      );
    this.submittingData = false;
  }
  fetchAllRouteMapsByRouteId() {
    this.sMORouteService
      .getAllRouteMapsByRouteId(this.selectedStore.id)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            var res = r.responseData;
            this.sMORouteAndSateMaps = res;
            this.loading = false;
          }
        },
        (error) => {
          this.loading = false;
          this.connectionError();
        }
      );
  }
  deleteRouteMap(sMORouteAndSateMap: SMORouteAndSateMap): void {
    this.confirmationService.confirm({
      message: "Are you sure you want to delete RouteMap?",
      accept: () => {
        this._deleteRouteMap(sMORouteAndSateMap);
      },
    });
  }
  _deleteRouteMap(sMORouteAndSateMap) {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Deleting RouteMap record",
    });
    this.sMORouteService.deleteRouteMap(sMORouteAndSateMap.id).subscribe(
      async (result: any) => {
        await this.messageService.add({
          severity: "success",
          summary: "Deleted",
          detail: "RouteMap record removed",
        });
        await this.fetchAllRouteMapsByRouteId();
        //this.fetchAllRouteMaps();
      },
      (error) => {
        this.connectionError();
      }
    );
  }

  //To and fro
  createToandFroStateMap() {
    this.submittingToandFroData = true;
    const formData = this.toandFroStateMapForm.value;
    const postData = {
      smoRouteId: this.selectedStore.id,
      toStateMapId: this.selectedToMultiState.map((x) => x.stateId),
      froStateMapId: this.selectedFroMultiState.map((x) => x.stateId),
      comment: formData.comment,
    };
    this.sMORouteService
      .postNewToandFroMaster(postData)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            this.messageService.add({
              summary: "Success",
              severity: "success",
              detail: "New States Mapped",
            });
            this.fetchAllToStateMapsByRouteId();
            this.fetchAllFroStateMapsByRouteId();
            this.submittingToandFroData = false;
            this.toandFroStateMapForm.reset();
            //this.closeEditing();
          } else {
            this.messageService.add({
              severity: "error",
              summary: "Failed",
              detail: " Error: " + r.responseMsg,
            });
            this.submittingToandFroData = false;
          }
        },
        (error) => {
          this.messageService.add({
            severity: "error",
            summary: "Failed",
            detail: "Connection Error " + error.error.message,
          });
          this.submittingToandFroData = false;
        }
      );
    this.submittingToandFroData = false;
  }
  fetchToandFroStateMapsByRouteId() {
    this.sMORouteService
      .getAllToAndFroByRouteId(this.selectedStore.id)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            var res = r.responseData;
            this.toAndFroMasterStateMap = res;
            this.loading = false;
          }
        },
        (error) => {
          this.loading = false;
          this.connectionError();
        }
      );
  }
  fetchAllToStateMapsByRouteId() {
    this.sMORouteService
      .getAllToStateByRouteId(this.selectedStore.id)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            var res = r.responseData;
            this.toStateMapDetails = res;
            this.loading = false;
          }
        },
        (error) => {
          this.loading = false;
          this.connectionError();
        }
      );
  }
  fetchAllFroStateMapsByRouteId() {
    this.sMORouteService
      .getAllFroStateByRouteId(this.selectedStore.id)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            var res = r.responseData;
            this.froStateMapDetails = res;
            this.loading = false;
          }
        },
        (error) => {
          this.loading = false;
          this.connectionError();
        }
      );
  }
  deleteToStateMap(ToStateMapDetails: ToStateMapDetails): void {
    this.confirmationService.confirm({
      message: "Are you sure you want to delete ToState Map?",
      accept: () => {
        this._deleteToStateMap(ToStateMapDetails);
      },
    });
  }
  _deleteToStateMap(ToStateMapDetails) {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Deleting Map record",
    });
    this.sMORouteService.deleteToState(ToStateMapDetails.id).subscribe(
      async (result: any) => {
        await this.messageService.add({
          severity: "success",
          summary: "Deleted",
          detail: "State Map record removed",
        });
        await this.fetchToandFroStateMapsByRouteId();
        await this.fetchAllToStateMapsByRouteId();
        //this.fetchAllRouteMaps();
      },
      (error) => {
        this.connectionError();
      }
    );
  }

  deleteFroStateMap(froStateMapDetails: FroStateMapDetails): void {
    this.confirmationService.confirm({
      message: "Are you sure you want to delete FroState Map?",
      accept: () => {
        this._deleteFroStateMap(froStateMapDetails);
      },
    });
  }
  _deleteFroStateMap(froStateMapDetails) {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Deleting Fro Map record",
    });
    this.sMORouteService.deleteFroState(froStateMapDetails.id).subscribe(
      async (result: any) => {
        await this.messageService.add({
          severity: "success",
          summary: "Deleted",
          detail: "State Map record removed",
        });
        //await this.fetchToandFroStateMapsByRouteId();
        await this.fetchAllFroStateMapsByRouteId();
      },
      (error) => {
        this.connectionError();
      }
    );
  }
  // editPriceRegister(priceRegisters: PriceRegister): void {
  //   this.formWrapper.nativeElement.scrollIntoView({
  //     behavior: "smooth",
  //     block: "end",
  //     inline: "start",
  //   });
  //   this.edPriceRegister = priceRegisters;
  //   this.editingPriceRegister = true;
  //   // this.imageSrc = pilotProfiles.imageUrl;
  //   this.priceRegisterForm.setValue({
  //     serviceRegistrationId: priceRegisters.serviceRegistrationId,
  //     costPrice: priceRegisters.costPrice,
  //     markupPercentage: priceRegisters.markupPercentage,
  //     sellingPrice: priceRegisters.sellingPrice,
  //     markupPrice: priceRegisters.markupPrice,
  //     smoRouteId: priceRegisters.smoRouteId,
  //     //smoRegionId: priceRegisters.smoRegionId,
  //   });
  //   this.selectedserviceRegistration = this.serviceRegistrations.find(
  //     (Type) => Type.id === priceRegisters.serviceRegistrationId
  //   );

  //   this.submitButtonLabel = "Update";
  // }
  PopulateRouteTree() {
    this.fetchAllSMORoutes();
    this.priceRegisterTree = [];
    const centralStoresTree = [];
    this.loadingPriceRegisters = false;

    this.smoRegions.filter((centralStore) => {
      const RuotesTree = [];
      this.smoRoutes.filter((regionStore) => {
        if (regionStore?.smoRegionId === centralStore?.id) {
          RuotesTree.push({
            label: regionStore.routeName,
            key: regionStore.id,
            icon: "pi pi-fw pi-sitemap",
            data: regionStore,
            selectable: true,
          });
        }
      });

      centralStoresTree.push({
        label: centralStore.regionName,
        key: centralStore.id,
        expandedIcon: "pi pi-map-marker",
        collapsedIcon: "pi pi-map-marker",
        expanded: true,
        data: centralStore,
        children: RuotesTree,
        selectable: true,
      });
    });

    this.priceRegisterTree = [
      {
        label: " Routes ",
        key: "Route Folder",
        // expandedIcon: "pi pi-map-marker",
        // collapsedIcon: "pi pi-map-marker",
        expandedIcon: "pi pi-minus-circle",
        collapsedIcon: "pi pi-plus-circle",
        expanded: true,
        children: centralStoresTree,
        data: 1001,
        selectable: true,
      },
    ];
  }

  async ShowStoreData(event) {
    if (event.node.data) {
      if (this.selectedStore !== event.node.data) {
        this.selectedStore = event.node.data;
        this.sMORouteService
          .getAllRouteMapsByRouteId(this.selectedStore.id)
          .pipe(takeUntil(this.unsubscriber$))
          .subscribe(
            (r: CommonResponse) => {
              if (r.responseCode == "00") {
                var res = r.responseData;
                this.sMORouteAndSateMaps = res;
                console.log(this.priceRegisterTree);
                this.loading = false;
              }
            },
            (error) => {
              this.loading = false;
              this.connectionError();
            }
          );

        //toandfro
        this.sMORouteService
          .getAllToStateByRouteId(this.selectedStore.id)
          .pipe(takeUntil(this.unsubscriber$))
          .subscribe(
            (r: CommonResponse) => {
              if (r.responseCode == "00") {
                var res = r.responseData;
                this.toStateMapDetails = res;
                console.log(this.priceRegisterTree);
                this.loading = false;
              }
            },
            (error) => {
              this.loading = false;
              this.connectionError();
            }
          );
        this.sMORouteService
          .getAllFroStateByRouteId(this.selectedStore.id)
          .pipe(takeUntil(this.unsubscriber$))
          .subscribe(
            (r: CommonResponse) => {
              if (r.responseCode == "00") {
                var res = r.responseData;
                this.froStateMapDetails = res;
                this.loading = false;
              }
            },
            (error) => {
              this.loading = false;
              this.connectionError();
            }
          );
      }
    }

    if (this.selectedStore == 1001) {
      this.cantCreateRouteUnder = false;
      this.routeDetailTable[0].data = "Routes";
      this.routeDetailTable[1].data = "Route-00000";
      this.routeDetailTable[2].data = "~";
      this.routeDetailTable[3].data = "~";
      console.log(98);
    } else {
      if (this.selectedStore.centralStoreId) {
        this.cantCreateRouteUnder = true;
        console.log(99);
        this.routeDetailTable[1].data =
          "REGIONAL-" + String(this.selectedStore.id).padStart(5, "0");
      } else {
        this.cantCreateRouteUnder = false;
        console.log(this.selectedStore.id);
        this.routeDetailTable[1].data =
          "CENTRAL-" + String(this.selectedStore.id).padStart(5, "0");
      }

      this.centralStoreName = this.selectedStore.smoRouteId
        ? this.selectedStore.smoRouteId
        : this.selectedStore.smoRegionId;
      this.routeDetailTable[0].data = this.selectedStore.smoRouteId
        ? this.selectedStore.smoRouteId
        : this.selectedStore.smoRegionId;
      this.routeDetailTable[2].data = "~";
      this.routeDetailTable[3].data = "~";
      // await this.CompletedStoreDetails(
      //   this.selectedStore.storeName ? true : false,
      //   this.selectedStore.id
      // );
    }
  }

  private connectionError() {
    this.messageService.add({
      severity: "error",
      summary: "Failed",
      detail: "Connection Error, Please try again",
    });
  }
}
