import { Component, OnInit } from "@angular/core";
import {
  ConfirmationService,
  MessageService,
  TreeDragDropService,
  TreeNode,
} from "primeng/api";
import {
  Branch,
  CommonResponse,
  GeneralService,
  GroupQuoteCategory,
  GroupedServiceCostAnalysis,
  Lead,
  LeadDivision,
  LeadQuote,
  QuoteServices,
  SaveServiceCostAnalysisVM,
  ServiceCategory,
  ServiceCategoryTask,
  ServiceCostAnalysisLineItem,
  ServiceRelationshipEnum,
  Services,
} from "../../../interfaces/home";
import { OperatingEntityService } from "../../../services/operating-entity.service";
import { ServiceCategoryService } from "../../../services/service-category.service";
import { ServicesService } from "../../../services/services.service";
import { ActivatedRoute, Router } from "@angular/router";
import { DivisionService } from "../../../services/division.service";
import { LeadService } from "../../../services/lead.service";
import { DialogService, DynamicDialogRef } from "primeng/dynamicdialog";
import { ServiceQualifyComponent } from "./service-qualify/service-qualify.component";
import { QuoteService } from "../../../services/quote.service";
import { QuoteServicesService } from "../../../services/quote-services.service";
import { GroupInvoiceFormComponent } from "../group-invoice-form/group-invoice-form.component";
import { BreadcrumbService } from "src/app/breadcrumb.service";
import { ServiceCategoryTaskService } from "src/app/services/service-category-task.service";
import { CreateKeyPersonComponent } from "../create-key-person/create-key-person.component";
import { KeyPersonnelComponent } from "../../client-management/client-view/key-personnel/key-personnel.component";
import { CaptureLeadEngagementComponent } from "../capture-lead-engagement/capture-lead-engagement.component";
import { LeadDivisionService } from "src/app/services/lead-division.service";
import { ViewQuoteComponent } from "./view-quote-component/view-quote.component";
import { BranchService } from "src/app/services/branch.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { GenerateGroupInvoiceService } from "src/app/services/generate-group-invoice.service";
import { Quote } from "@angular/compiler";

@Component({
  selector: "app-lead-qualification",
  templateUrl: "./lead-qualification.component.html",
  providers: [
    TreeDragDropService,
    MessageService,
    ConfirmationService,
    DialogService,
  ],
  styleUrls: ["./lead-qualification.component.css"],
})
export class LeadQualificationComponent implements OnInit {
  branches: Branch[] = [];
  offices: any[] = [];
  leadQualificationStatus = false;
  loadingPage: boolean;
  currentLead: Lead = null;
  serviceTree: TreeNode[] = [];
  serviceTreeChildren: TreeNode[] = [];
  loadingServices = true;
  loadingService = true;
  loadingServicesCategory = false;
  availableServices: Services[];
  selectedQuoteService: QuoteServices[];
  selectedCategories: ServiceCategory;
  draggedService: Services;
  //selectedServiceTotal: number;
  leadDivisions: any;
  qualifyRef: DynamicDialogRef;
  private leadRef: any;
  selectedDivision: LeadDivision = null;
  private services: Services[] = [];
  public leadDropStatus = false;
  private invoiceGroupRef: DynamicDialogRef;
  isInvoiceGrouped: boolean;
  private invoiceGroupData: any = {};
  fetchingServiceCategoryTask: boolean;
  serviceCategoryTasks: ServiceCategoryTask[];
  keyPersonRef: DynamicDialogRef;
  keyPersonnelRef: DynamicDialogRef;
  isrequired: boolean = true;

  billableAmountTotal: number;
  leadEngagementRef: DynamicDialogRef;
  viewQuoteRef: DynamicDialogRef;
  tempIdAssignment: number = 0;
  invoiceTypeSelector: FormGroup;
  selectedCategory: any = null;
  contractName: string = "";
  categories: any[] = [
    { name: "Select a category for this quote service", key: "0" },
    { name: "Individual quotes with separate qualifications", key: "1" },
    { name: "Group quotes with same contract qualifications", key: "2" },
    // { name: "Group quotes with different contract qualifications", key: "3" },
  ];
  canChangeInvoiceCategory: boolean = true;
  public newQuote: any = {};

  isNewQuote: boolean = true;
  hasEdittedQuote = false;
  hasSavedChanged: boolean = false;
  allServiceCategories: ServiceCategory[] = [];
  contractNames: any;
  isSubmitted: boolean = false;
  openCostAnalysisDialogue: boolean;
  loadingServiceCLI: boolean;
  allServiceCostAnalysis: GroupedServiceCostAnalysis[] = [];
  serviceCostAnalysisInView: {
    quoteService: QuoteServices;
    quoteServicePartner?: QuoteServices;
    cost: GroupedServiceCostAnalysis;
  }[] = [];

  constructor(
    private operatingEntityService: OperatingEntityService,
    private serviceCategoryService: ServiceCategoryService,
    public divisionService: DivisionService,
    private messageService: MessageService,
    private servicesService: ServicesService,
    public leadService: LeadService,
    public router: Router,
    private route: ActivatedRoute,
    public dialogService: DialogService,
    private quoteService: QuoteService,
    private leadDivisionService: LeadDivisionService,
    private quoteServicesService: QuoteServicesService,
    private serviceCategoryTaskService: ServiceCategoryTaskService,
    private confirmationService: ConfirmationService,
    private breadcrumbService: BreadcrumbService,
    private branchService: BranchService,
    private formBuilder: FormBuilder,
    private generateGroupInvoiceService: GenerateGroupInvoiceService
  ) {
    // this.selectedServiceTotal = 0;
    this.availableServices = [];
    this.selectedQuoteService = [];
  }

  async ngOnInit() {
    this.selectedCategory = this.categories[0];
    this.breadcrumbService.setItems([
      { label: "Lead Administration", routerLink: ["home/lead-admin"] },
      { label: "Qualification", routerLink: ["home/lead-admin/qualification"] },
    ]);

    this.invoiceTypeSelector = this.formBuilder.group({
      category: ["", Validators.required],
    });

    this.GetAllServiceCategories();
    this.fetchServiceCategoryTask();

    this.loadServices();

    this.fetchBranches();

    this.divisionService.allDivisionData().subscribe(
      async (r: CommonResponse) => {
        if (r.responseCode == "00") {
          var res = r.responseData;
          if (res.length > 0) {
            res.forEach((value, key) => {
              const operationEntities = value.operatingEntities;
              const operationEntityTree = [];
              if (operationEntities.length > 0) {
                operationEntities.forEach((valueA, keyA) => {
                  const serviceGroups = valueA.serviceGroups;
                  const serviceGroupsTree = [];
                  if (serviceGroups.length > 0) {
                    serviceGroups.forEach((value1, key2) => {
                      const groupCategory = value1.serviceCategories;
                      const serviceCategoriesTree = [];
                      if (groupCategory.length > 0) {
                        groupCategory.forEach((valueC, keyC) => {
                          serviceCategoriesTree.push({
                            key: valueC.id,
                            label: valueC.name,
                            icon: "pi pi-fw pi-file",
                            data: valueC.description,
                            selectable: true,
                          });
                        });
                      }
                      serviceGroupsTree.push({
                        label: value1.name,
                        data: value1.description,
                        expandedIcon: "pi pi-minus-circle",
                        collapsedIcon: "pi pi-plus-circle",
                        leaf: false,
                        children: serviceCategoriesTree,
                      });
                    });
                  }
                  operationEntityTree.push({
                    label: valueA.name,
                    data: valueA.description,
                    expandedIcon: "pi pi-minus-circle",
                    collapsedIcon: "pi pi-plus-circle",
                    leaf: false,
                    children: serviceGroupsTree,
                  });
                });
              }
              this.serviceTreeChildren.push({
                label: value.name,
                data: value.description,
                expandedIcon: "pi pi-minus-circle",
                collapsedIcon: "pi pi-plus-circle",
                leaf: false,
                children: operationEntityTree,
              });
            });
          }
        }
        this.loadingServices = false;
      },
      (error) => {
        this.loadingServices = false;
        this.messageService.add({
          severity: "error",
          summary: "Failed",
          detail: "Connection failed",
        });
      }
    );

    this.serviceTree = [
      {
        label: "Halogen",
        data: "Storage Folder",
        expandedIcon: "pi pi-minus-circle",
        collapsedIcon: "pi pi-plus-circle",
        expanded: true,
        children: this.serviceTreeChildren,
      },
    ];

    this.GetAllServiceCostAnalysis();
  }

  async GetAllServiceCategories() {
    this.serviceCategoryService
      .GetAllServiceCategoriesWithRelationship()
      .subscribe(
        async (data) => {
          if (data.responseCode != "00") {
            this.messageService.add({
              severity: "error",
              summary: data.responseMsg,
            });
            return;
          }

          this.allServiceCategories = data.responseData;
        },
        (error) => {
          console.log("Error: " + JSON.stringify(error));
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail:
              "Unable to fetch all service categories at the moment.. Reason: [" +
              (error ? error.error.message : "request failed - permission") +
              "]",
          });
        }
      );
  }

  async GetAllServiceCostAnalysis() {
    this.allServiceCostAnalysis = [];
    this.servicesService.GetAllServiceCostAnalysisItems().subscribe(
      async (data) => {
        if (data.responseCode != "00") {
          this.messageService.add({
            severity: "error",
            summary: data.responseMsg,
          });
          return;
        }

        let groupedByServiceId = [
          ...new Set(data.responseData.map((lineItem) => lineItem.serviceId)),
        ];
        groupedByServiceId.forEach((grpService) => {
          let serviceLineItems: ServiceCostAnalysisLineItem[] = [];
          serviceLineItems.push({
            costCaption: "AmountCharged",
            costDescription: "",
          });
          serviceLineItems.push(
            ...data.responseData.filter((x) => x.serviceId == grpService)
          );
          serviceLineItems.push({
            costCaption: "Margin",
            costDescription: "",
          });

          serviceLineItems.forEach((x) => {
            x.quantity = 0;
            x.unitPrice = 0;
          });
          this.allServiceCostAnalysis.push({
            serviceId: grpService,
            serviceName: "",
            lineItemsCount: serviceLineItems.length,
            lineItems: serviceLineItems,
          });
        });
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all service cost analysis at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  private generateInvoiceNumber() {
    this.generateGroupInvoiceService
      .generateInvoice()
      .subscribe((result: CommonResponse) => {
        this.newQuote.groupInvoiceNumber = result.responseData;
      });
  }

  private qualifyGroupInfo(isNewGroupInfo: boolean) {
    this.invoiceGroupRef = this.dialogService.open(GroupInvoiceFormComponent, {
      closeOnEscape: false,
      closable: false,
      header: "Group Invoice Information",
      width: "40rem",
      contentStyle: { "min-height": "500px", overflow: "auto" },
      baseZIndex: 10000,
      data: {
        lead: this.currentLead,
        isNewGroupInfo: isNewGroupInfo,
        invoiceGroupData: this.invoiceGroupData,
      },
    });

    this.invoiceGroupRef.onClose.subscribe((res) => {
      if (res) {
        this.invoiceGroupData = res;
        this.hasEdittedQuote = true;
        if (isNewGroupInfo) {
          this.generateInvoiceNumber();
        }
      }
    });
  }

  updateGroupInfo() {
    var isNewInfo = this.invoiceGroupData?.contractStartDate ? false : true;
    this.qualifyGroupInfo(isNewInfo);
  }

  changeInvoiceCategory() {
    let index = this.selectedCategory?.key;
    if (index == GroupQuoteCategory.GroupQuoteWithSameDetails) {
      this.isInvoiceGrouped = true;
      this.canChangeInvoiceCategory = false;
      this.qualifyGroupInfo(true);
    } else {
      this.invoiceGroupData = null;
      this.canChangeInvoiceCategory = false;

      if (index == GroupQuoteCategory.GroupQuoteWithIndividualDetails) {
        this.isInvoiceGrouped = false;
        this.generateInvoiceNumber();
      }
    }
  }

  fetchBranches() {
    this.branchService.allBranch().subscribe(
      async (result: CommonResponse) => {
        if (result.responseCode == "00") {
          this.branches = result.responseData;
          this.branches.forEach((branch) => {
            branch.offices.forEach((office) => {
              var entry = {
                name: `${office.name}/${branch.name}`,
                branchId: branch.id,
                officeId: office.id,
              };
              this.offices.push(entry);
            });
          });
        }
      },
      (error) => {
        this.connectionError();
      }
    );
  }

  fetchServiceCategoryTask() {
    this.serviceCategoryTaskService.allServiceCategoryTask().subscribe(
      async (serviceCategoryTask: CommonResponse) => {
        this.serviceCategoryTasks = serviceCategoryTask.responseData;
        this.fetchingServiceCategoryTask = false;
      },
      (error) => {
        this.fetchingServiceCategoryTask = false;
        this.connectionError();
      }
    );
  }

  private connectionError() {
    this.messageService.add({
      severity: "error",
      summary: "Failed",
      detail: "Connection Error, Please try again",
    });
  }

  loadServices() {
    this.loadingService = true;
    this.servicesService.allService().subscribe(
      async (res: CommonResponse) => {
        this.loadingService = false;
        this.services = res.responseData;
        this.loadOldData();
      },
      (error) => {
        this.loadingService = false;
        this.connectionError();
      }
    );
  }

  async refreshQuoteServices() {
    if (this.selectedDivision.id) {
      // get the lead admin data and prefill the fields
      //first call is to getLeadDivision is to avoid caching result by backend
      this.leadDivisionService
        .getLeadDivision(this.selectedDivision.id)
        .subscribe(async (result: CommonResponse) => {
          if (result.responseCode == "00") {
            this.quoteService
              .getQuoteByLeadDivisionId(this.selectedDivision.id)
              .subscribe(async (r: CommonResponse) => {
                if (r.responseCode == "00") {
                  var res = r.responseData;
                  this.tempIdAssignment = 0;
                  this.selectedDivision.quote = res;
                  const _quote = res;
                  this.selectedQuoteService = [];
                  if (_quote?.quoteServices.length > 0) {
                    _quote.quoteServices.forEach((value, i) => {
                      const _service = this.services.find(
                        (service) => service.id === value.serviceId
                      );
                      value.serviceRelationshipEnum =
                        _service.serviceRelationshipEnum;
                      value.tempId = ++this.tempIdAssignment;
                      value.serviceName = _service.name;
                      console.log(
                        `unique tag: ${value.uniqueTag}, id: ${value.id}`
                      );
                      this.selectedQuoteService = [
                        ...this.selectedQuoteService,
                        value,
                      ];
                    });
                    this.contractName = _quote.caption; //this.selectedDivision.quote.caption

                    await this.calTotalService();
                  }
                }
              });
          }
        });
    }
  }

  async loadOldData() {
    this.loadingPage = true;
    this.leadRef = this.route.snapshot.paramMap.get("referenceNo");
    if (this.leadRef) {
      // get the lead admin data and prefill the fields
      this.leadService.setCurrentLead(this.leadRef);
      this.leadService.activeLead().subscribe(async (res: any) => {
        if (res?.responseCode == "00") {
          this.loadingPage = false;
          this.currentLead = res.responseData;
          this.leadDivisions = this.currentLead.leadDivisions;
          this.leadQualificationStatus =
            this.currentLead.leadQualificationStatus;
          this.leadDropStatus = this.currentLead.isLeadDropped;
          // get the division data
          if (this.leadDivisions.length > 0) {
            // create the required formGroups
            if (!this.selectedDivision) {
              this.edit(this.leadDivisions[0]);
            } else {
              this.edit(this.selectedDivision);
            }
          }
        }
      });
    }
    this.contractName = this.selectedDivision?.quote?.caption;
  }

  showService(event) {
    if (event.node.key && this.allServiceCategories.length > 0) {
      this.availableServices = null;
      this.loadingServicesCategory = true;
      let serviceCategory = this.allServiceCategories.find(
        (x) => x.id == event.node.key
      );
      if (serviceCategory) {
        this.availableServices = [];
        this.selectedCategories = serviceCategory;
        this.availableServices = serviceCategory.services;
        this.loadingServicesCategory = false;
      }
    } else this.showService__retired(event);
  }

  showService__retired(event) {
    if (event.node.key) {
      this.availableServices = null;
      this.loadingServicesCategory = true;
      this.serviceCategoryService
        .getCategory(event.node.key)
        .subscribe(async (res: CommonResponse) => {
          if (res.responseCode == "00") {
            this.availableServices = [];
            const categories = res.responseData;
            this.selectedCategories = categories;
            this.availableServices = categories.services;
            this.loadingServicesCategory = false;
          }
        });
    }
  }

  async setGroupInvoiceInfo() {
    //use the first quote service to set the info for this invoiceGroupData
    if (this.selectedCategory?.key == 2) {
      let quote = this.selectedQuoteService[0];

      this.invoiceGroupData.fulfillmentStartDate = quote.fulfillmentStartDate;
      this.invoiceGroupData.fulfillmentEndDate = quote.fulfillmentEndDate;
      this.invoiceGroupData.contractStartDate = quote.contractStartDate;
      this.invoiceGroupData.contractEndDate = quote.contractEndDate;
      this.invoiceGroupData.activationDate = quote.activationDate;
      this.invoiceGroupData.paymentCycle = quote.paymentCycle;
      this.invoiceGroupData.invoicingInterval = quote.invoicingInterval;
      this.invoiceGroupData.firstInvoiceSendDate = quote.firstInvoiceSendDate;
    }
  }

  async loadDivisionServices() {
    if (this.selectedDivision) {
      this.selectedQuoteService = [];
      this.contractName = this.selectedDivision.quote.caption;
      if (this.selectedDivision.quote) {
        const _quote = this.selectedDivision.quote;
        if (_quote) {
          this.canChangeInvoiceCategory = false;

          //set the category of quote
          if (_quote.groupQuoteCategory) {
            this.selectedCategory = this.categories.find(
              (x) => x.key == _quote.groupQuoteCategory
            );
          } else {
            this.messageService.add({
              severity: "error",
              summary: "Loading stopped",
              detail: "No group quote category for this invoice",
            });
            return;
          }

          if (
            _quote.groupQuoteCategory ==
            GroupQuoteCategory.GroupQuoteWithSameDetails
          ) {
            this.isInvoiceGrouped = true;
          } else {
            this.invoiceGroupData = null;
          }
        }

        if (_quote?.quoteServices.length > 0) {
          _quote.quoteServices.forEach((value, i) => {
            const _service = this.services.find(
              (service) => service.id === value.serviceId
            );
            value.serviceRelationshipEnum = _service.serviceRelationshipEnum;
            value.tempId = ++this.tempIdAssignment;
            value.serviceName = _service.name;
            this.selectedQuoteService = [...this.selectedQuoteService, value];
          });

          if (this.isInvoiceGrouped) await this.setGroupInvoiceInfo();

          await this.calTotalService();
        }
      }
    }
  }

  drop() {
    if (this.draggedService) {
      if (this.selectedCategory?.key == "0") {
        this.messageService.add({
          severity: "error",
          summary: "Canceled",
          detail: "You have not selected a category",
        });
        return;
      }

      var taskCount = this.serviceCategoryTasks.filter(
        (x) =>
          x.serviceCategory.id == this.draggedService.serviceCategoryId &&
          x.endorsementType?.caption === "New"
      ).length;
      if (taskCount < 1) {
        this.messageService.add({
          severity: "error",
          summary: "Canceled",
          detail:
            "Service category for this service does not have category task(s) for endorsement type 'new'",
        });
        return;
      }
      //check if this service can be added
      var [canAddThisService, message, matchingDirect] =
        this.canAddWithPossibleDirectMatch(this.draggedService);
      if (!canAddThisService) {
        this.messageService.add({
          severity: "error",
          summary: "Canceled",
          detail: message,
        });
        return;
      } else {
        this.qualify(
          true,
          this.offices,
          this.draggedService,
          matchingDirect,
          null
        );
      }

      this.calTotalService();
      this.draggedService = null;
    }
  }

  editQuoteService(quoteService: QuoteServices) {
    this.qualify(false, this.offices, null, null, quoteService);
  }

  dragEnd() {
    this.draggedService = null;
  }

  dragStart(service: Services) {
    this.draggedService = service;
  }

  async remove(quote: QuoteServices) {
    const index = this.selectedQuoteService.findIndex(
      (x) => x.tempId == quote.tempId
    );

    if (index == -1) {
      this.messageService.add({
        severity: "error",
        summary: "Error",
        detail: "Service does not exist in the list",
      });
      return;
    }

    //check if this is a direct and has an admin
    if (quote.serviceRelationshipEnum == ServiceRelationshipEnum.Direct) {
      var [hasAdmin, theAdmin] = this.thisDirectHasAnAdmin(quote);
      if (hasAdmin) {
        this.messageService.add({
          severity: "error",
          summary: "Cannot Remove",
          detail: `First remove the admin with tag '${theAdmin?.uniqueTag}' before this direct'`,
        });
        return;
      }
    }

    if (quote.serviceRelationshipEnum == ServiceRelationshipEnum.Admin) {
      //check if there is no handing direct waiting to be paired to an Admin
      var allDirects = this.selectedQuoteService?.filter(
        (p) => p.serviceRelationshipEnum == ServiceRelationshipEnum.Direct
      );

      let shouldReturn: boolean = false;
      for (var thisDirect of allDirects) {
        var [hasAdmin, theAdmin] = this.thisDirectHasAnAdmin(thisDirect);
        if (!hasAdmin) {
          this.messageService.add({
            severity: "error",
            summary: "Cannot Remove",
            detail: `You have an unpaired direct with tag '${thisDirect?.uniqueTag}'. Please remove it or pair it`,
          });
          shouldReturn = true;
          break;
        }
      }

      if (shouldReturn) return;
    }

    this.selectedQuoteService = this.selectedQuoteService.filter(
      (val, i) => i !== index
    );
    this.draggedService = null;
    this.messageService.add({
      severity: "success",
      summary: "Removed",
      detail: "Service Removed",
    });

    await this.calTotalService();
  }

  //determin if this service can be added to the quote and return the quote service
  private canAddWithPossibleDirectMatch(
    service: Services
  ): [boolean, string, QuoteServices] {
    if (service.serviceRelationshipEnum == ServiceRelationshipEnum.Admin) {
      let directServiceId = service?.adminRelationship?.directServiceId;
      //find all its matching directs
      var matchingDirects = this.selectedQuoteService.filter(
        (x) => x.serviceId == directServiceId
      );
      //loop through this array and check if all are paired; return the quotesevice not paired
      for (var x of matchingDirects) {
        //check if this direct has an Admin tie in the selectedQuoteService
        var thisAdminMatch = this.selectedQuoteService?.find(
          (p) =>
            p.adminDirectTie == x.adminDirectTie &&
            p.serviceRelationshipEnum == ServiceRelationshipEnum.Admin
        );
        if (!thisAdminMatch) {
          return [true, "Can add this admin service", x];
        }
      }

      //all the directs have been paired or there is no direct in the selection
      return [
        false,
        "Please first select a preceding direct before this admin",
        null,
      ];
    } else if (
      service.serviceRelationshipEnum == ServiceRelationshipEnum.Direct
    ) {
      return this.hasNoUnpairedDirects();
    } else {
      return [true, "Can add this singleton service", null];
    }
  }

  hasNoUnpairedDirects(): [boolean, string, QuoteServices] {
    //check if there is a hanging direct waiting to be paired to an admin
    var allDirects = this.selectedQuoteService?.filter(
      (x) => x.serviceRelationshipEnum == ServiceRelationshipEnum.Direct
    );
    for (var x of allDirects) {
      //foreach
      var [hasAnAdmin, theAdmin] = this.thisDirectHasAnAdmin(x);
      if (!hasAnAdmin) {
        var nameOfUnmatched = this.services?.find(
          (p) => p.id == x.serviceId
        )?.name;
        return [
          false,
          `You need an Admin for the direct service ${nameOfUnmatched}`,
          null,
        ];
      }
    }
    return [true, "Can add this another service", null];
  }

  checkConventionAlignement(): [boolean, string] {
    for (let index = 0; index < this.selectedQuoteService.length; index++) {
      if (index > 0) {
        var [firstDateAligns, firstDateErrorMsg] =
          this.checkInvoiceSendDateConvention(this.selectedQuoteService[index]);
        if (!firstDateAligns) {
          return [false, firstDateErrorMsg];
        }

        var [contractDateAligns, contractDateErrorMsg] =
          this.checkContracStartDateConvention(
            this.selectedQuoteService[index]
          );
        if (!contractDateAligns) {
          return [false, contractDateErrorMsg];
        }
      }
    }
    return [true, null];
  }

  private thisDirectHasAnAdmin(x: QuoteServices): [boolean, QuoteServices] {
    //check if this direct has an Admin tie in the selectedQuoteService
    var theAdminMatch = this.selectedQuoteService?.find(
      (p) =>
        p.adminDirectTie == x.adminDirectTie &&
        p.serviceRelationshipEnum == ServiceRelationshipEnum.Admin
    );
    if (!theAdminMatch) {
      return [false, null];
    }
    return [true, theAdminMatch];
  }

  async calTotalService() {
    this.billableAmountTotal = 0;
    this.selectedQuoteService.forEach((x, i) => {
      this.billableAmountTotal += x.billableAmount;
    });
    this.LoadCostAnalysis();
  }

  nextPage() {
    // this.leadService.ticketInformation.seatInformation = this.selectedServices;
    this.router.navigate([
      "/home/lead-admin/negotiation",
      this.currentLead.referenceNo,
    ]);
  }

  prevPage() {
    this.router.navigate([
      "/home/lead-admin/capture",
      this.currentLead.referenceNo,
    ]);
  }

  edit(leadDivision: any) {
    this.selectedDivision = leadDivision;
    this.leadDivisionService.setCurrentLeadDivision(leadDivision);
    this.loadDivisionServices();
  }

  async qualify(
    _isNewQuoteService: boolean,
    _branches: any[],
    service?: Services,
    _matchingDirect?: QuoteServices,
    _quoteServiceToEdit?: QuoteServices
  ) {
    let data;
    let selectedQuoteServicesToPass;
    if (!_isNewQuoteService) {
      //exclude the _quoteServiceToEdit from the list
      selectedQuoteServicesToPass = this.selectedQuoteService.filter(function (
        x
      ) {
        return x.uniqueTag !== _quoteServiceToEdit.uniqueTag;
      });
      if (!service) {
        if (!_quoteServiceToEdit) {
          await this.messageService.add({
            severity: "error",
            summary: "Strange behaviour. No service to add!!!",
            detail: service.name,
          });
          return;
        } else {
          service = this.services.find(
            (x) => x.id == _quoteServiceToEdit.serviceId
          );
        }
      }
    }

    data = {
      groupInvoices: this.isInvoiceGrouped,
      services: service,
      selectedDivision: this.selectedDivision,
      quoteServiceList: _isNewQuoteService
        ? this.selectedQuoteService
        : selectedQuoteServicesToPass,
      allQuoteServiceList: this.selectedQuoteService,
      matchingDirect: _matchingDirect, //for adding matching Admin to have same values
      quoteService: _quoteServiceToEdit, //for update
      branches: _branches,
      isNewQuoteService: _isNewQuoteService,
      quoteCategoryLevel: this.selectedCategory?.key,
    };

    this.qualifyRef = this.dialogService.open(ServiceQualifyComponent, {
      header: "Qualify " + service.name,
      width: "70%",
      contentStyle: {
        "min-height": "500px",
        height: "100vh",
        overflow: "auto",
      },
      baseZIndex: 10000,
      data,
    });

    this.qualifyRef.onClose.subscribe(async (res: any) => {
      if (res) {
        const _selectedQuoteService = res;
        _selectedQuoteService.referenceNumber = this.currentLead.referenceNo;

        if (_isNewQuoteService) {
          if (_selectedQuoteService) {
            //add extra details to this quote service for identification
            _selectedQuoteService.serviceId = service.id;
            _selectedQuoteService.serviceRelationshipEnum =
              service.serviceRelationshipEnum;
            _selectedQuoteService.serviceName = service.name;
            _selectedQuoteService.tempId = ++this.tempIdAssignment;

            this.selectedQuoteService = [
              ...this.selectedQuoteService,
              _selectedQuoteService,
            ];

            await this.messageService.add({
              severity: "success",
              summary: "Service Qualified, Press save to effect the changes",
              detail: service.name,
            });

            //cannot change the invoice category for again
            this.canChangeInvoiceCategory = false;
          }
        } else {
          //find the index of the quoteservice
          var index = this.selectedQuoteService.findIndex(
            (x) => x.tempId === _quoteServiceToEdit.tempId
          );
          //assign service name, tempId back to the edited quote service
          _selectedQuoteService.serviceName = _quoteServiceToEdit.serviceName;
          _selectedQuoteService.tempId = _quoteServiceToEdit.tempId;
          this.selectedQuoteService[index] = _selectedQuoteService;

          if (
            _selectedQuoteService.serviceRelationshipEnum ==
            ServiceRelationshipEnum.Direct
          ) {
            //update the corresponding admin if exists
            this.updateMatchingAdmin(_selectedQuoteService);
          }

          await this.messageService.add({
            severity: "success",
            summary: "Edit Sucesss",
            detail: "The quote service has been edited",
          });
        }

        this.hasEdittedQuote = true;
        this.hasSavedChanged = false;
      }

      await this.calTotalService();
    });
  }

  private updateMatchingAdmin(direct: QuoteServices) {
    //check if this has an admin
    let [hasAdmin, admin] = this.thisDirectHasAnAdmin(direct);
    if (hasAdmin) {
      //change corresponding values of the admin
      admin.quantity = direct.quantity;
      admin.branchId = direct.branchId;
      admin.officeId = direct.officeId;
      admin.contractEndDate = direct.contractEndDate;
      admin.contractStartDate = direct.contractStartDate;
      admin.firstInvoiceSendDate = direct.firstInvoiceSendDate;
      admin.fulfillmentEndDate = direct.fulfillmentEndDate;
      admin.fulfillmentStartDate = direct.fulfillmentStartDate;
      admin.activationDate = direct.activationDate;
      admin.paymentCycle = direct.paymentCycle;
      admin.invoicingInterval = direct.invoicingInterval;

      //find the index of the admin in the selectedQuoteService
      let index = this.selectedQuoteService.findIndex(
        (x) =>
          x.adminDirectTie == admin.adminDirectTie &&
          x.serviceRelationshipEnum == ServiceRelationshipEnum.Admin
      );
      if (index > -1) {
        //now first the service that the quote service matches
        let service = this.services.find((x) => x.id == admin.serviceId);

        //calculate the billable vat on this admin again
        var reCalculatedAdmin = this.calculateBillableVat(admin, service);
        this.selectedQuoteService[index] = reCalculatedAdmin;
      }
    }
  }

  calculateBillableVat(admin: QuoteServices, service: Services): QuoteServices {
    const unitPrice = admin.unitPrice;
    const quantity = admin.quantity;
    let _discount = admin.discount ?? 0;
    if (_discount > 100) {
      _discount = 100;
      admin.discount = 100;
    }

    const totalPrice = unitPrice * quantity;

    let vat: number;
    if (service.isVatable) {
      vat = parseFloat((totalPrice * 0.075).toFixed(2));
    } else {
      vat = 0;
    }

    const billable = totalPrice - (_discount / 100) * totalPrice + vat;
    admin.billableAmount = billable;
    admin.budget = billable;
    admin.vat = vat;
    return admin;
  }

  async createQuote() {
    this.isSubmitted = true;
    if (!this.contractName) {
      this.messageService.add({
        severity: "error",
        summary: "Canceled",
        detail: "Please enter contract name",
      });
      return;
    }
    if (this.isInvoiceGrouped) {
      if (!this.invoiceGroupData.contractStartDate) {
        this.messageService.add({
          severity: "error",
          summary: "Error",
          detail: "You have not selected the group info for the quote",
        });
        this.qualifyGroupInfo(true);
        return;
      }
    }

    // check the necessary fields
    if (this.selectedQuoteService.length < 0) {
      this.messageService.add({
        severity: "error",
        summary: "Removed",
        detail: "Add one or more Service, Before creating a quote",
      });
      return;
    }

    //check that all directs of quotes have corresponding admin
    var [hasNoUnpaired, message, unpiaredQuote] = this.hasNoUnpairedDirects();
    if (!hasNoUnpaired) {
      this.messageService.add({
        severity: "error",
        summary: "Unpaired Directs",
        detail: message,
      });
      return;
    }

    if (
      this.selectedCategory?.key ==
      GroupQuoteCategory.GroupQuoteWithIndividualDetails
    ) {
      //check same date with
      var [isAligned, alignErrorMsg] = this.checkConventionAlignement();
      if (!isAligned) {
        this.messageService.add({
          severity: "error",
          summary: "Date Alignment Error",
          detail: alignErrorMsg,
        });
        return;
      }
    }

    const _quoteServices: QuoteServices[] = this.prepareQuoteData();

    this.newQuote.leadDivisionId = this.selectedDivision.id;
    console.log("lead divison id: ", this.newQuote.leadDivisionId);
    this.newQuote.groupQuoteCategory = this.selectedCategory?.key;
    this.newQuote.caption = this.contractName;

    if (this.isInvoiceGrouped) {
      const _quoteServices: any[] = this.prepareQuoteData();
      this.newQuote.quoteServices = _quoteServices;
    } else {
      this.matchAdminWithDirectDates();
      this.newQuote.quoteServices = this.selectedQuoteService;
    }

    if (!(this.newQuote.groupQuoteCategory in GroupQuoteCategory)) {
      this.messageService.add({
        severity: "error",
        summary: "Cannot post",
        detail: "No group quote category for this quote",
      });
      return;
    }

    if (this.newQuote.groupQuoteCategory > 1) {
      var createNewNumber = false;
      if (!this.newQuote.groupInvoiceNumber) {
        createNewNumber = true;
        this.messageService.add({
          severity: "error",
          summary: "Cannot post",
          detail: "No group quote invoice number for this quote",
        });
        //get the group invoice number
        this.generateGroupInvoiceService
          .generateInvoice()
          .subscribe((result: CommonResponse) => {
            this.newQuote.groupInvoiceNumber = result.responseData;
            this._createQuote(this.newQuote);
          });
      }
    }

    if (!createNewNumber) {
      await this._createQuote(this.newQuote);
    }
  }

  private async _createQuote(newQuote: any) {
    this.messageService.add({
      severity: "info",
      summary: "Wait",
      detail: "Creating new Quote",
    });
    this.quoteService.postQuote(newQuote).subscribe(
      async (res: CommonResponse) => {
        if (res.responseCode == "00") {
          this.messageService.add({
            severity: "success",
            summary: "Quote",
            detail: "New Lead Quote created",
          });
          this.hasEdittedQuote = false;
          this.hasSavedChanged = true;
          await this.refreshQuoteServices();
        } else {
          this.messageService.add({
            severity: "error",
            summary: "Failure",
            detail: res.responseMsg,
          });
        }
      },
      async (error: any) => {
        this.messageService.add({
          severity: "error",
          summary: "Quote",
          detail: "Processing Error",
        });
      }
    );
  }

  private checkContracStartDateConvention(
    quoteService: QuoteServices
  ): [boolean, string] {
    var firstRecord = this.selectedQuoteService[0];
    const frConstractStart = new Date(firstRecord.contractStartDate);
    const contractStart = new Date(quoteService.contractStartDate);

    var date = frConstractStart?.getDate();

    if (date == contractStart?.getDate()) return [true, null];
    else
      return [
        false,
        `Service with tag '${
          quoteService.uniqueTag
        }' does not contract start date aligned with ${this.ordinalSuffix(
          date
        )} on the first service`,
      ];
  }

  private checkInvoiceSendDateConvention(
    quoteService: QuoteServices
  ): [boolean, string] {
    var firstRecord = this.selectedQuoteService[0];
    const frInvoiceSend = new Date(firstRecord.firstInvoiceSendDate);
    const invoiceSendDate = new Date(quoteService.firstInvoiceSendDate);
    var date = frInvoiceSend?.getDate();
    if (date == invoiceSendDate?.getDate()) return [true, null];
    else
      return [
        false,
        `Service with tag '${
          quoteService.uniqueTag
        }' does not have first invoice send date aligned with ${this.ordinalSuffix(
          date
        )} on the first service`,
      ];
  }

  private ordinalSuffix(i) {
    var j = i % 10,
      k = i % 100;
    if (j == 1 && k != 11) {
      return i + "st";
    }
    if (j == 2 && k != 12) {
      return i + "nd";
    }
    if (j == 3 && k != 13) {
      return i + "rd";
    }
    return i + "th";
  }

  private prepareQuoteData() {
    const _quoteServices: QuoteServices[] = [];
    if (this.isInvoiceGrouped) {
      if (this.invoiceGroupData) {
        this.selectedQuoteService.forEach((value) => {
          const valueNew = {
            ...value,
            ...this.invoiceGroupData,
            invoicingInterval: this.invoiceGroupData.invoicingInterval,
            paymentCycle: this.invoiceGroupData.paymentCycle,
            caption: this.contractName,
            constractStartDate: this.getOnlyDate(
              this.invoiceGroupData.contractStartDate
            ),
          };

          _quoteServices.push(valueNew);
        });
      }
    }
    return _quoteServices;
  }

  updateTheQuote() {
    var postData = this.contractName;
    if (this.selectedDivision.quote.caption != this.contractName) {
      this.quoteService
        .updateQuoteData(this.selectedDivision.quote.id, postData)
        .subscribe(async (res: CommonResponse) => {
          if (res.responseCode == "00") {
            this.messageService.add({
              severity: "success",
              summary: "Quote",
              detail: "Lead Quote Updated",
            });
            this.updateQuote();
            this.hasEdittedQuote = false;
            this.hasSavedChanged = true;
          }
        });
    } else {
      this.updateQuote();
    }
  }

  async updateQuote() {
    if (this.selectedQuoteService.length < 0) {
      this.messageService.add({
        severity: "error",
        summary: "Removed",
        detail: "Add one or more Service, Before saving the quote",
      });
      return;
    }

    //check that all directs of quotes have corresponding admin
    var [hasNoUnpaired, message, unpiaredQuote] = this.hasNoUnpairedDirects();
    if (!hasNoUnpaired) {
      this.messageService.add({
        severity: "error",
        summary: "Unpaired Directs",
        detail: message,
      });
      return;
    }

    if (
      this.selectedCategory?.key ==
      GroupQuoteCategory.GroupQuoteWithIndividualDetails
    ) {
      //check same date with
      var [isAligned, alignErrorMsg] = this.checkConventionAlignement();
      if (!isAligned) {
        this.messageService.add({
          severity: "error",
          summary: "Date Alignment Error",
          detail: alignErrorMsg,
        });
        return;
      }
    }

    const _quoteServices: QuoteServices[] = this.prepareQuoteData();

    let postData = [];
    if (this.isInvoiceGrouped) {
      const _quoteServices: any[] = this.prepareQuoteData();
      postData = _quoteServices;
    } else {
      this.matchAdminWithDirectDates();
      postData = this.selectedQuoteService;
    }

    this.messageService.add({
      severity: "info",
      summary: "Wait",
      detail: "Updating Quote",
    });

    this.quoteService
      .updateQuote(this.selectedDivision.quote.id, postData)
      .subscribe(async (res: CommonResponse) => {
        if (res.responseCode == "00") {
          this.messageService.add({
            severity: "success",
            summary: "Quote",
            detail: "Lead Quote Updated",
          });

          this.hasEdittedQuote = false;
          this.hasSavedChanged = true;
        }
      });

    await this.refreshQuoteServices();
  }

  private getOnlyDate(date: any) {
    let d = new Date(date);
    return d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + d.getDate();
  }

  private matchAdminWithDirectDates(): boolean {
    var allDirects = this.selectedQuoteService?.filter(
      (p) => p.serviceRelationshipEnum == ServiceRelationshipEnum.Direct
    );

    let shouldReturn: boolean = false;
    for (var thisDirect of allDirects) {
      var [hasAdmin, theAdmin] = this.thisDirectHasAnAdmin(thisDirect);
      if (!hasAdmin) {
        shouldReturn = true;
        break;
      } else {
        //update this
        this.updatAdminDates(thisDirect, theAdmin);
      }
      if (shouldReturn) return;
    }
  }

  private updatAdminDates(direct: any, admin: any) {
    //check if this has an admin
    if (admin) {
      //change corresponding values of the admin
      admin.quantity = direct.quantity;
      admin.branchId = direct.branchId;
      admin.officeId = direct.officeId;
      admin.contractEndDate = direct.contractEndDate;
      admin.contractStartDate = direct.contractStartDate;
      admin.firstInvoiceSendDate = direct.firstInvoiceSendDate;
      admin.fulfillmentEndDate = direct.fulfillmentEndDate;
      admin.fulfillmentStartDate = direct.fulfillmentStartDate;
      admin.activationDate = direct.activationDate;
      admin.paymentCycle = direct.paymentCycle;
      admin.invoicingInterval = direct.invoicingInterval;

      //find the index of the admin in the selectedQuoteService
      let index = this.selectedQuoteService.findIndex(
        (x) =>
          x.adminDirectTie == admin.adminDirectTie &&
          x.serviceRelationshipEnum == ServiceRelationshipEnum.Admin
      );
      if (index > -1) {
        //calculate the billable vat on this admin again
        this.selectedQuoteService[index] = admin;
      }
    }
  }

  displayQuote(quoteServices: any) {
    if (this.isInvoiceGrouped) {
      quoteServices = this.prepareQuoteData();
    }

    let generalServices: any = [];
    for (let index = 0; index < quoteServices.length; index++) {
      let _service = this.services.find(
        (x) => x.id == quoteServices[index].serviceId
      );
      let general = { service: _service, quoteService: quoteServices[index] };
      generalServices.push(general);
    }

    this.viewQuoteRef = this.dialogService.open(ViewQuoteComponent, {
      width: "900px",
      contentStyle: { "max-width": "100vw", height: "90vh", overflow: "auto" },
      baseZIndex: 100,
      data: {
        generalServices: generalServices,
        quote: this.selectedDivision.quote,
        leadDivision: this.selectedDivision,
      },
    });

    this.viewQuoteRef.onClose.subscribe(() => {});
  }

  progressLead() {
    this.isSubmitted = true;
    if (!this.contractName) {
      this.messageService.add({
        severity: "error",
        summary: "Canceled",
        detail: "Please enter contract name",
      });
      return;
    }
    if (this.currentLead) {
      // check if the lead division quote have service
      if (this.selectedQuoteService.length == 0) {
        this.messageService.add({
          severity: "error",
          summary: "Canceled",
          detail: "add at least a service to the selected division",
        });
        return;
      }
    }

    if (this.hasEdittedQuote && !this.hasSavedChanged) {
      this.messageService.add({
        severity: "error",
        summary: "Canceled",
        detail: "You have not saved changes to the quote",
      });
      return;
    }

    this.confirmationService.confirm({
      icon: "pi pi-exclamation-triangle",
      header: "Confirmation",
      message: "Do you want to progress this lead?",
      accept: () => {
        this.messageService.add({
          severity: "info",
          summary: "processing",
          detail: "Updating Lead Qualification",
          sticky: true,
        });
        this.leadService
          .updateLeadQualification(this.currentLead.id)
          .subscribe(async (res: any) => {
            this.messageService.add({
              severity: "success",
              summary: "Completed",
              detail: "Lead Qualification Progressed",
            });
            await this.loadOldData().then(async () => {
              await this.nextPage();
            });
          });
      },
    });
  }

  toggleGroupInvoice(event) {
    this.isInvoiceGrouped = event.checked;
    if (this.isInvoiceGrouped) {
      if (!this.invoiceGroupData) {
        this.invoiceGroupRef = this.dialogService.open(
          GroupInvoiceFormComponent,
          {
            closeOnEscape: false,
            closable: false,
            header: "Group Invoice Information",
            width: "40rem",
            contentStyle: { "min-height": "500px", overflow: "auto" },
            baseZIndex: 10000,
            data: {
              lead: this.currentLead,
              invoiceGroupData: this.invoiceGroupData,
            },
          }
        );
        this.invoiceGroupRef.onClose.subscribe((res) => {
          if (res) {
            this.invoiceGroupData = res;
          }
        });
      }
    } else {
      this.invoiceGroupData = null;
    }
  }

  createKeyPerson(isLeadDivision: boolean) {
    if (true) {
      this.keyPersonRef = this.dialogService.open(CreateKeyPersonComponent, {
        header: "Create Divison Key Person",
        width: "50%",
        contentStyle: { "min-height": "350px", overflow: "auto" },
        baseZIndex: 10000,
        data: { isLeadDivision },
      });

      this.keyPersonRef.onClose.subscribe(async (res: any) => {});
    }
  }

  captureLeadEngagements() {
    this.leadEngagementRef = this.dialogService.open(
      CaptureLeadEngagementComponent,
      {
        header: "Capture Lead Engagement",
        width: "50%",
        contentStyle: { "min-height": "350px", overflow: "auto" },
        baseZIndex: 10000,
      }
    );

    this.leadEngagementRef.onClose.subscribe(async (res: any) => {});
  }

  viewKeyPersonnels(isLeadDivision: boolean) {
    let data = [];

    if (isLeadDivision) {
      const leadDivision = this.leadDivisions.find(
        (x) => x.id == this.selectedDivision?.id
      );
      if (leadDivision) {
        data = leadDivision?.leadDivisionKeyPeople;
      }
    } else {
      if (this.currentLead?.leadKeyPeople) {
        data = this.currentLead?.leadKeyPeople;
      }
    }

    this.keyPersonnelRef = this.dialogService.open(KeyPersonnelComponent, {
      header: "Key Personnels",
      width: "80%",
      contentStyle: { "min-height": "350px", overflow: "auto" },
      baseZIndex: 10000,
      data: { keyPersonnels: data, editable: false },
    });

    this.keyPersonnelRef.onClose.subscribe(async (res: any) => {});
  }

  StartCostAnalysisView() {
    this.openCostAnalysisDialogue = true;
    this.serviceCostAnalysisInView = [];
    this.selectedDivision.quote.quoteServices.forEach((quoteServ) => {
      let analysis = this.allServiceCostAnalysis.find(
        (x) => x.serviceId == quoteServ.serviceId
      );
      if (analysis) {
        let quoteServPartner: QuoteServices = null;
        if (
          quoteServ.serviceRelationshipEnum !=
          ServiceRelationshipEnum.Standalone
        ) {
          quoteServPartner = this.selectedDivision.quote.quoteServices.find(
            (x) =>
              x.id != quoteServ.id &&
              x.adminDirectTie == quoteServ.adminDirectTie
          );
        }

        this.serviceCostAnalysisInView.push({
          quoteService: quoteServ,
          quoteServicePartner: quoteServPartner,
          cost: analysis,
        });
        this.serviceCostAnalysisInView.forEach((analysis) => {
          analysis.cost.lineItems.forEach((lineItem) => {
            if (analysis.quoteService.serviceCostAnalysisLogs) {
              let analysisData =
                analysis.quoteService.serviceCostAnalysisLogs.find(
                  (x) => x.serviceCostAnalysisLineItemId == lineItem.id
                );
              if (analysisData) {
                lineItem.unitPrice = analysisData.unitPrice;
                lineItem.quantity = analysisData.quantity;
              }
            }
          });
        });
      }
    });
  }

  CloseAnalysisDialogue() {
    this.loadingServiceCLI = false;
    this.openCostAnalysisDialogue = false;
  }

  GetServiceName(serviceId: number): string {
    let serv = this.services.find((x) => x.id == serviceId);
    if (serv) return serv.name;

    return "N/A";
  }

  RunCostAnalysis(item: {
    quoteService: QuoteServices;
    quoteServicePartner?: QuoteServices;
    cost: GroupedServiceCostAnalysis;
  }): number {
    let total = item.quoteService.billableAmount;
    if (item.quoteServicePartner)
      total += item.quoteServicePartner.billableAmount;
    item.cost.lineItems.forEach(
      (lineItem) => (total -= lineItem.quantity * lineItem.unitPrice)
    );

    return total;
  }

  SaveCostAnalysisInfo() {
    this.confirmationService.confirm({
      header: "Save Cost Analysis Confirmation",
      message:
        "This action will save this cost analysis and tie it to it's quote services. Do you wish to proceed with saving this service cost analysis?",
      rejectLabel: "No, Don't Save Cost Analysis",
      rejectIcon: "pi pi-times",
      acceptIcon: "pi pi-check",
      acceptLabel: "Yes, Save Cost Analysis",
      accept: () => {
        this.messageService.add({
          severity: "info",
          summary: "Notice",
          detail: "Saving Cost Analysis...",
        });

        this.CloseAnalysisDialogue();
        const postData: SaveServiceCostAnalysisVM = {
          items: [],
        };

        this.serviceCostAnalysisInView.forEach((analysis) => {
          analysis.cost.lineItems.forEach((costLineItem) => {
            if (costLineItem.id && costLineItem.id != 0) {
              postData.items.push({
                quoteServiceId: analysis.quoteService.id,
                serviceCostAnalysisLineItemId: costLineItem.id,
                quantity: costLineItem.quantity,
                unitPrice: costLineItem.unitPrice,
              });
            }
          });
        });

        this.servicesService.SaveServiceCostAnalysis(postData).subscribe(
          async (data) => {
            if (data.responseCode != "00") {
              this.messageService.add({
                severity: "error",
                summary: data.responseMsg,
              });
              return;
            }

            this.messageService.add({
              severity: "success",
              summary: "Completed",
              detail: "Quote Service Cost Analysis Saved Successfully!",
            });
          },
          (error) => {
            console.log("Error: " + JSON.stringify(error));
            this.messageService.add({
              severity: "error",
              summary: "Notice",
              detail:
                "Unable to save quote service cost analysis at the moment.. Reason: [" +
                (error ? error.error.message : "request failed - permission") +
                "]",
            });
          }
        );
      },
    });
  }

  LoadCostAnalysis() {
    let quoteServiceIds = this.selectedDivision.quote.quoteServices.map(
      (x) => x.id
    );
    if (!this.selectedDivision.quote.hasLoadedCostAnalysis) {
      this.servicesService
        .GetQuoteServiceCostAnalysis({ quoteServiceIds: quoteServiceIds })
        .subscribe(
          async (data) => {
            if (data.responseCode != "00") {
              this.messageService.add({
                severity: "error",
                summary: data.responseMsg,
              });
              return;
            }
            this.selectedDivision.quote.quoteServices.forEach((quoteServ) => {
              quoteServ.serviceCostAnalysisLogs = data.responseData.filter(
                (x) => x.quoteServiceId == quoteServ.id
              );
            });
            this.selectedDivision.quote.hasLoadedCostAnalysis = true;
          },
          (error) => {
            console.log("Error: " + JSON.stringify(error));
            this.messageService.add({
              severity: "error",
              summary: "Notice",
              detail:
                "Unable to load quote service cost analysis at the moment.. Reason: [" +
                (error ? error.error.message : "request failed - permission") +
                "]",
            });
          }
        );
    }
  }

  get RunGrandTotalCostAnalysis(): number {
    let grandTotal = 0;
    if (this.serviceCostAnalysisInView) {
      this.serviceCostAnalysisInView.forEach((analysis) => {
        grandTotal += analysis.quoteService.billableAmount;
        if (analysis.quoteServicePartner)
          grandTotal += analysis.quoteServicePartner.billableAmount;
        analysis.cost.lineItems.forEach((lineItem) => {
          if (
            lineItem.costCaption != "AmountCharged" &&
            lineItem.costCaption != "Margin"
          )
            grandTotal -= lineItem.quantity * lineItem.unitPrice;
        });
      });
    }
    return grandTotal;
  }
}
