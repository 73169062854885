import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { CommanderRank } from "../../interfaces/armada";
import { CommonResponse } from "src/app/interfaces/home";

@Injectable({
  providedIn: "root",
})
export class CommanderRankService {
  //baseUrl = environment.armadaBaseUrl+'CommanderRank/';
  baseUrl = environment.baseUrl + "Commander/";

  constructor(private httpClient: HttpClient) {}
  allCommanderRank(): Observable<CommonResponse> {
    return this.httpClient.get<CommonResponse>(
      this.baseUrl + "GetAllCommanderRanks"
    );
  }
  getCommanderRank(id: any): Observable<CommonResponse> {
    return this.httpClient.get<CommonResponse>(
      this.baseUrl + "GetRankById/" + id
    );
  }
  getCommanderRankByName(name: any): Observable<CommonResponse> {
    return this.httpClient.get<CommonResponse>(this.baseUrl + "name/" + name);
  }
  postCommanderRank(data: any) {
    return this.httpClient.post(this.baseUrl + "AddNewCommanderRank", data);
  }
  delete(id: any) {
    return this.httpClient.delete(this.baseUrl + "DeleterankById/" + id);
  }
  updateCommanderRank(id: any, data: any) {
    return this.httpClient.put(this.baseUrl + "UpdateRankById/" + id, data);
  }
}
