import { Evaluation } from "./../../../interfaces/employment";
import { State } from "src/app/interfaces/home";
import {
  CreateJobPost,
  EducationLevelEnum,
  JobPost,
  JobTypeEnum,
  OperativeType,
} from "./../../../interfaces/premployment";
import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { AngularFireStorage } from "@angular/fire/storage";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MessageService, ConfirmationService } from "primeng/api";
import { BreadcrumbService } from "src/app/breadcrumb.service";
import { GmaPremploymentService } from "src/app/services/gma-premployment.service";
import { GmaEmploymentService } from "src/app/services/gma-employment.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-gma-create-job",
  templateUrl: "./gma-create-job.component.html",
  styleUrls: ["./gma-create-job.component.scss"],
})
export class GmaCreateJobComponent implements OnInit {
  @ViewChild("formWrapper") public formWrapper: ElementRef;
  form: FormGroup;
  allJobTypes: {
    value: string;
    key: number;
  }[];
  theJobType: {
    value: string;
    key: number;
  };
  allEducationLevels: {
    value: string;
    key: number;
  }[];
  theEducationLevel: {
    value: string;
    key: number;
  };
  allOperativeTypes: OperativeType[];
  theOperativeType: OperativeType;
  allJobDescriptions: {
    id: number;
    description: string;
  }[];
  selectedJobDescription: {
    id: number;
    description: string;
  };
  allJobExperiences: {
    id: number;
    description: string;
  }[];
  selectedJobExperience: {
    id: number;
    description: string;
  };
  newDescription: string;
  newExperience: string;
  fetching: boolean;
  allJobPost: JobPost[];
  selectedJobPost: JobPost;
  cols: any[];
  allLocations: State[];
  theLocation: State;
  allTrainingExaminations: Evaluation[];
  theTrainingExamination: Evaluation;
  allPhysicalExaminations: Evaluation[];
  thePhysicalExamination: Evaluation;
  allApplicationExaminations: Evaluation[];
  theApplicationExamination: Evaluation;
  allCognitiveExamination: Evaluation[];
  theCognitiveExamination: Evaluation;
  allBackgroundExamination: Evaluation[];
  theBackgroundExamination: Evaluation;

  constructor(
    fb: FormBuilder,
    public fireStorage: AngularFireStorage,
    public gmaPremploymentService: GmaPremploymentService,
    public employmentService: GmaEmploymentService,
    public messageService: MessageService,
    private breadcrumbService: BreadcrumbService,
    public confirmationService: ConfirmationService
  ) {
    this.form = fb.group({
      JobType: ["", Validators.required],
      JobTitle: ["", Validators.required],
      JobDesc: ["", Validators.required],
      ValidityStart: ["", Validators.required],
      ValidityStop: ["", Validators.required],
      OperativeType: ["", Validators.required],
      Location: ["", Validators.required],
      MinEducationLevel: ["", Validators.required],
      MinYrsExperience: ["", Validators.required],
      MaxApplicantsNo: ["", Validators.required],
      JobRequirements: [""],
      TrainingExam: ["", Validators.required],
      PhysicalExam: ["", Validators.required],
      ApplicationExam: ["", Validators.required],
      CognitiveExam: ["", Validators.required],
      BackgroundExam: ["", Validators.required],
    });
  }

  ngOnInit(): void {
    this.breadcrumbService.setItems([
      {
        label: "Guard Management",
        routerLink: ["/home/guard-management/item-settings"],
      },
      {
        label: "Guard Item Settings",
        routerLink: ["/home/guard-management/create-job"],
      },
    ]);

    this.allJobTypes = [
      {
        value: "Contract",
        key: JobTypeEnum.Contract,
      },
      {
        value: "Fulltime",
        key: JobTypeEnum.Fulltime,
      },
      {
        value: "PartTime",
        key: JobTypeEnum.PartTime,
      },
    ];

    this.allEducationLevels = [
      {
        value: "Primary",
        key: EducationLevelEnum.Primary,
      },
      {
        value: "Secondary",
        key: EducationLevelEnum.Secondary,
      },
      {
        value: "Tertiary",
        key: EducationLevelEnum.Tertiary,
      },
      {
        value: "PostGraduate",
        key: EducationLevelEnum.PostGraduate,
      },
    ];
    this.allJobDescriptions = [];
    this.allJobExperiences = [];
    // this.allJobDescriptions.push({
    //   id: 1,
    //   description:
    //     "This is a test job description text. We are going to try to make this description very very very very very very long, so we can vouch for this.",
    // });
    // this.allJobExperiences.push({
    //   id: 1,
    //   description:
    //     "This is a test job experience text. We are going to try to make this experience very very very very very very long, so we can vouch for this.",
    // });
    this.cols = [
      { field: "title", header: "Title" },
      { field: "description", header: "Description" },
      { field: "validityStart", header: "ValidityStart" },
      { field: "validityStop", header: "ValidityStop" },
      { field: "createdAt", header: "CreatedAt" },
      // { field: "jobCode", header: "JobCode" },
    ];

    this.FetchAllOperativeTypes();
    this.FetchAllJobPosts();
    this.FetchAllLocations();
    this.FetchAllExaminations();
  }

  FetchAllExaminations() {
    this.employmentService.GetAllExaminations().subscribe(
      async (data) => {
        this.allPhysicalExaminations = data.filter((x) => x.type == 1);
        this.allCognitiveExamination = data.filter((x) => x.type == 2);
        this.allApplicationExaminations = data.filter((x) => x.type == 3);
        this.allTrainingExaminations = data.filter((x) => x.type == 4);
        this.allBackgroundExamination = data.filter((x) => x.type == 5);
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all examinations at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  FetchAllLocations() {
    this.employmentService.GetAllStates().subscribe(
      async (data) => {
        this.allLocations = data;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all locations (states) at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  FetchAllOperativeTypes() {
    this.gmaPremploymentService.GetAllOperativeType().subscribe(
      async (data) => {
        this.allOperativeTypes = data;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all operative types at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  FetchAllJobPosts() {
    this.gmaPremploymentService.GetAllJobPosts().subscribe(
      async (data) => {
        this.allJobPost = data;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all job posts at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  CreateJobPost() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Creating Job Post...",
    });

    const postData: CreateJobPost = {
      jobType: this.theJobType.key,
      jobTitle: this.form.get("JobTitle").value,
      validityStartPeriod: this.form.get("ValidityStart").value,
      validityStopPeriod: this.form.get("ValidityStop").value,
      jobSummary: this.form.get("JobDesc").value,
      operativeTypeId: this.theOperativeType.id,
      stateId: this.theLocation.id,
      minEducationLevel: this.theEducationLevel.key,
      minimumYearsOfExperience: this.form.get("MinYrsExperience").value,
      maximumApplicantsNo: this.form.get("MaxApplicantsNo").value,
      jobRequirements: this.form.get("JobRequirements").value,
      jobDescriptions: this.allJobDescriptions.map((x) => x.description),
      jobExperiences: this.allJobExperiences.map((x) => x.description),
      trainingExaminationId: this.theTrainingExamination.id,
      physicalExaminationId: this.thePhysicalExamination.id,
      cognitiveExaminationId: this.theCognitiveExamination.id,
      applicationExaminationId: this.theApplicationExamination.id,
      backgroundExaminationId: this.theBackgroundExamination.id,
    };

    this.gmaPremploymentService.CreateJobPost(postData).subscribe(
      async () => {
        await this.messageService.add({
          severity: "success",
          summary: "Completed",
          detail: "Operative Item Created Successfully!",
        });

        this.form.reset();
        this.allJobExperiences = [];
        this.allJobDescriptions = [];
        this.FetchAllJobPosts();
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to create job post at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  AddDescToList() {
    this.allJobDescriptions.push({
      id:
        this.allJobDescriptions.length == 0
          ? 1
          : this.allJobDescriptions[this.allJobDescriptions.length - 1].id + 1,
      description: this.newDescription,
    });
    this.newDescription = "";

    this.messageService.add({
      severity: "success",
      summary: "Completed",
      detail: "Job Description Added To List Successfully!",
    });
  }

  AddExpToList() {
    this.allJobExperiences.push({
      id:
        this.allJobExperiences.length == 0
          ? 1
          : this.allJobExperiences[this.allJobExperiences.length - 1].id + 1,
      description: this.newExperience,
    });
    this.newExperience = "";

    this.messageService.add({
      severity: "success",
      summary: "Completed",
      detail: "Job Experience Added To List Successfully!",
    });
  }

  RemoveDescFromList(item: { id: number; description: string }) {
    if (this.allJobDescriptions.indexOf(item) > -1) {
      this.allJobDescriptions.splice(this.allJobDescriptions.indexOf(item), 1);

      this.messageService.add({
        severity: "success",
        summary: "Completed",
        detail: "Job Description Removed From List Successfully!",
      });
    }
  }

  RemoveExpFromList(item: { id: number; description: string }) {
    if (this.allJobExperiences.indexOf(item) > -1) {
      this.allJobExperiences.splice(this.allJobExperiences.indexOf(item), 1);

      this.messageService.add({
        severity: "success",
        summary: "Completed",
        detail: "Job Experience Removed From List Successfully!",
      });
    }
  }

  RemoveAllExpFromList() {
    this.allJobExperiences = [];

    this.messageService.add({
      severity: "success",
      summary: "Completed",
      detail: "All Job Experience Removed From List Successfully!",
    });
  }

  RemoveAllDescFromList() {
    this.allJobDescriptions = [];

    this.messageService.add({
      severity: "success",
      summary: "Completed",
      detail: "All Job Description Removed From List Successfully!",
    });
  }

  GetJobType(typeId: number) {
    if (typeId == JobTypeEnum.Contract) return "Contract";
    if (typeId == JobTypeEnum.Fulltime) return "Fulltime";
    if (typeId == JobTypeEnum.PartTime) return "PartTime";
  }

  GetJobStatus(item: JobPost): string {
    var today = new Date();
    var start = new Date(item.validityStart);
    var stop = new Date(item.validityStop);
    if (today < start) return "InActive";
    if (today > stop) return "Expired";
    if (today >= start && today <= stop) return "Active";
    return "Err";
  }

  GoToApplyJob() {
    window.open(environment.frontednUrl + "#/job-listing", "_blank");
  }
}
