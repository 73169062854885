import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { MessageService, ConfirmationService } from "primeng/api";
import { BreadcrumbService } from "src/app/breadcrumb.service";
import { FinanceExpenseService } from "src/app/services/finance-expense.service";
import domtoimage from "dom-to-image";
import { jsPDF } from "jspdf";
import {
  BankAccount,
  ExpenseBatchRequest,
  ExpenseBatchRequestPayment,
  ExpensePaymentVoucherViewItems,
  PostExpensePaymentsVM,
  UserExpenseRequestType,
} from "src/app/interfaces/finance-expense";
import { Supplier } from "src/app/interfaces/armada";
import { DeployedGuard } from "src/app/interfaces/employment";
import { User, CustomerDivision, Bank } from "src/app/interfaces/home";
import { CustomerDivisionService } from "src/app/services/customer-division.service";
import { GmaEmploymentService } from "src/app/services/gma-employment.service";
import { SupplierService } from "src/app/services/supplier.service";
import { UserService } from "src/app/services/user.service";
import { SupplierBank } from "src/app/interfaces/supplier";
import { BankService } from "src/app/services/bank.service";

@Component({
  selector: "app-finance-bank-payment",
  templateUrl: "./finance-bank-payment.component.html",
  styleUrls: ["./finance-bank-payment.component.scss"],
})
export class FinanceBankPaymentComponent implements OnInit {
  @ViewChild("formWrapper") public formWrapper: ElementRef;
  fetching: boolean;
  allBatches: ExpenseBatchRequest[];
  allPayablesWithoutFilter: ExpenseBatchRequestPayment[] = [];
  allPayables: ExpenseBatchRequestPayment[] = [];
  selectedPayables: ExpenseBatchRequestPayment[] = [];
  cols: any[];
  openMemoDialogue: boolean;
  source: string = "POLARIS BANK";
  chequeNo: string = "000006323";
  beneficiary: string = "ADEBAYO & NURSES";
  voucherNo: string = "01.7330783";
  voucherDate: string = new Date(Date.now()).toDateString();
  batchItems: ExpensePaymentVoucherViewItems[] = [
    {
      isTotal: false,
      account: "0099080993",
      accountName: "Acct A",
      expenseLine: "Item 1",
      description: "Being payment for",
      amount: 7324.57,
    },
    {
      isTotal: false,
      account: "0099080993",
      accountName: "Acct A",
      expenseLine: "Item 2",
      description: "VAT payment",
      amount: 6732,
    },
    {
      isTotal: true,
      amount: 133465,
    },
  ];
  amtInWords: string =
    "Thirteen thousand, Three hundred and Fifty Thousand Naira Only";
  batchApprovals: {
    office: string;
    officer: string;
    status: string;
  }[] = [
    {
      office: "Line Manager",
      officer: "Omoshola Yusuf",
      status: "Approved",
    },
    {
      office: "Batch Approval",
      officer: "Omoshola Yusuf",
      status: "Approved",
    },
  ];
  allSuppliers: Supplier[];
  allUsers: User[];
  allClients: CustomerDivision[];
  allOperatives: DeployedGuard[];
  allSupplierBanks: SupplierBank[] = [];
  allBankAccounts: BankAccount[] = [];
  allBanks: Bank[] = [];
  allRequestTypes: {
    key: number;
    value: string;
  }[];
  showPaymentDialogue: boolean;
  paymentDetailTable: {
    key: string;
    value: string;
  }[];
  generatingVoucher: boolean;

  constructor(
    fb: FormBuilder,
    public bankService: BankService,
    public userService: UserService,
    public supplierService: SupplierService,
    public clientService: CustomerDivisionService,
    public employmentService: GmaEmploymentService,
    public financeExpenseService: FinanceExpenseService,
    public messageService: MessageService,
    private breadcrumbService: BreadcrumbService,
    public confirmationService: ConfirmationService
  ) {}

  ngOnInit(): void {
    this.breadcrumbService.setItems([
      {
        label: "Finance",
        routerLink: ["/home/finance"],
      },
      {
        label: "Expense Tool",
        routerLink: ["/home/finance/expense"],
      },
      {
        label: "Bank Payment",
        routerLink: ["/home/finance/expense/bank-payment"],
      },
    ]);

    this.cols = [
      { field: "supplier", header: "Supplier" },
      { field: "paymentBatch", header: "Payment Batch" },
      { field: "amtToPay", header: "Amount To Pay" },
      { field: "isApproved", header: "Is Approved" },
      { field: "isAuthorized", header: "Is Authorized" },
      { field: "isChequeLoaded", header: "Is Cheque Loaded" },
      { field: "primaryBank", header: "Primary Bank" },
      { field: "primaryAccount", header: "Primary Account" },
      { field: "status", header: "Status" },
      { field: "paymentReference", header: "Payment Reference" },
    ];

    this.allRequestTypes = [
      {
        key: UserExpenseRequestType.Supplier,
        value: "Supplier",
      },
      {
        key: UserExpenseRequestType.Staff,
        value: "Staff",
      },
      {
        key: UserExpenseRequestType.Customer,
        value: "Customer",
      },
      {
        key: UserExpenseRequestType.Operatives,
        value: "Operative",
      },
      {
        key: UserExpenseRequestType.Miscellanous,
        value: "Miscellanous",
      },
    ];

    this.paymentDetailTable = [
      {
        key: "Is Posted To Paystack:",
        value: "",
      },
      {
        key: "Date Posted To Paystack:",
        value: "",
      },
      {
        key: "Transaction Reference:",
        value: "",
      },
      {
        key: "Account Number:",
        value: "",
      },
      {
        key: "Account Name:",
        value: "",
      },
      {
        key: "Paystack Response:",
        value: "",
      },
    ];

    this.fetching = true;
    this.FetchAllBanks();
    this.FetchAllSupplierBanks();
    this.FetchAllBankAccounts();
    this.FetchAllClients();
  }

  async FetchAllSuppliers() {
    this.supplierService.getAll().subscribe(
      async (data) => {
        this.allSuppliers = data.responseData;
        this.FetchAllUsers();
      },
      (error) => {
        this.messageService.add({
          severity: "error",
          summary: "Failed",
          detail: error ?? "Some errors occurred",
        });
      }
    );
  }

  async FetchAllClients() {
    this.clientService.allCustomerDivision().subscribe(
      async (data) => {
        this.allClients = data.responseData;
        this.FetchAllSuppliers();
      },
      (error) => {
        this.messageService.add({
          severity: "error",
          summary: "Failed",
          detail: error ?? "Some errors occurred",
        });
      }
    );
  }

  async FetchAllUsers() {
    this.userService.allUser().subscribe(
      async (res) => {
        var data = res.responseData;
        this.allUsers = [];
        data.forEach((user) => {
          user.fullName = user.lastName + " " + user.firstName;
          this.allUsers.push(user);
        });
        this.FetchAllOperatives();
      },
      (err) => {
        console.log(err);
        this.messageService.add({
          severity: "error",
          summary: "Failed",
          detail: err ?? "Some errors occurred",
        });
      }
    );
  }

  async FetchAllOperatives() {
    this.employmentService.GetAllOperativesDataOnly().subscribe(
      async (data) => {
        this.allOperatives = [];
        data.forEach((x) => {
          x.guardName =
            x.jobApplication.personalInformation.lastname +
            " " +
            x.jobApplication.personalInformation.firstname +
            " " +
            x.jobApplication.personalInformation.othername;
          this.allOperatives.push(x);
        });
        this.FetchAllPaymentPayables();
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all operatives at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  async FetchAllPaymentPayables() {
    this.fetching = true;
    this.financeExpenseService.GetExpenseBankPaymentPayables().subscribe(
      async (data) => {
        if (data.responseCode != "00") {
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail: data.responseMsg,
          });
          this.fetching = false;
          return;
        }

        this.messageService.add({
          severity: "success",
          summary: "Completed",
          detail: "Operation Successfull!",
        });

        this.allPayablesWithoutFilter = data.responseData.allPayments;
        this.allPayables = data.responseData.payments;
        this.allBatches = data.responseData.payablesBatches;
        this.fetching = false;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to get payments at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
        this.fetching = false;
      }
    );
  }

  async FetchAllSupplierBanks() {
    this.financeExpenseService.GetAllSupplierBanks().subscribe(
      async (data) => {
        if (data.responseCode != "00") {
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail: data.responseMsg,
          });
          return;
        }

        this.allSupplierBanks = data.responseData;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all supplier banks at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  async FetchAllBankAccounts() {
    this.financeExpenseService.GetAllBankAccouts().subscribe(
      async (data) => {
        if (data.responseCode != "00") {
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail: data.responseMsg,
          });
          return;
        }

        this.allBankAccounts = data.responseData;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all bank accounts at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  async FetchAllBanks() {
    this.bankService.allBankData().subscribe(
      async (data) => {
        if (data.responseCode != "00") {
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail: data.responseMsg,
          });
          return;
        }

        this.allBanks = data.responseData;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all banks at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  GetUsername(userId: number): string {
    let sf = this.allUsers.find((x) => x.id == userId);
    if (sf)
      return (
        sf.lastName +
        " " +
        sf.firstName +
        " " +
        (sf.otherName == null ? "" : sf.otherName)
      );

    return "N/A";
  }

  GetEntityName(item: ExpenseBatchRequest): string {
    if (item.requestType == UserExpenseRequestType.Customer) {
      let customer = this.allClients.find((x) => item.clientId);
      if (customer) return customer.divisionName;
    } else if (item.requestType == UserExpenseRequestType.Operatives) {
      let op = this.allOperatives.find((x) => x.id == item.operativeId);
      if (op)
        return (
          op.jobApplication.personalInformation.lastname +
          " " +
          op.jobApplication.personalInformation.firstname +
          " " +
          op.jobApplication.personalInformation.othername
        );
    } else if (item.requestType == UserExpenseRequestType.Staff) {
      return this.GetUsername(item.staffId);
    } else if (item.requestType == UserExpenseRequestType.Supplier) {
      let suppl = this.allSuppliers.find((x) => x.id == item.supplierId);
      if (suppl) return suppl.supplierName;
    } else if (item.requestType == UserExpenseRequestType.Miscellanous) {
      return "Miscellaneous";
    }

    return "N/A";
  }

  GetPaymentBeneficiary(payment: ExpenseBatchRequestPayment): string {
    let batch = this.allBatches.find((x) => x.id == payment.batchRequestId);
    if (batch) return this.GetEntityName(batch);

    return "N/A";
  }

  GetPaymentBankAccount(item: ExpenseBatchRequestPayment): string {
    let batch = this.allBatches.find((x) => x.id == item.batchRequestId);
    if (batch.requestType == UserExpenseRequestType.Customer) {
      let customer = this.allClients.find((x) => batch.clientId);
      if (customer) {
        let customerAcct = this.allBankAccounts.find(
          (x) => x.customerDivisionId == customer.id
        );
        if (customerAcct) {
          item.bankAccount = customerAcct;
          item.bank = this.GetBankName(customerAcct.bankId);
          return customerAcct.accountNumber;
        }
      }
    } else if (batch.requestType == UserExpenseRequestType.Operatives) {
      let op = this.allOperatives.find((x) => x.id == batch.operativeId);
      if (op) {
        let opAcct = this.allBankAccounts.find(
          (x) => x.deployedGuardId == op.id
        );
        if (opAcct) {
          item.bankAccount = opAcct;
          item.bank = this.GetBankName(opAcct.bankId);
          return opAcct.accountNumber;
        }
      }
    } else if (batch.requestType == UserExpenseRequestType.Staff) {
      let staff = this.allUsers.find((x) => x.id == batch.staffId);
      if (staff) {
        let staffAcct = this.allBankAccounts.find(
          (x) => x.userProfileId == staff.id
        );
        if (staffAcct) {
          item.bankAccount = staffAcct;
          item.bank = this.GetBankName(staffAcct.bankId);
          return staffAcct.accountNumber;
        }
      }
    } else if (batch.requestType == UserExpenseRequestType.Supplier) {
      let suppl = this.allSuppliers.find((x) => x.id == batch.supplierId);
      if (suppl) {
        let supplAcct = this.allSupplierBanks.find(
          (x) => x.supplierId == suppl.id
        );
        if (supplAcct) {
          item.supplerBank = supplAcct;
          item.bank = this.GetBankName(supplAcct.bankId);
          return supplAcct.accountNumber;
        }
      }
    }

    return "N/A";
  }

  GetBankName(bankId: number) {
    let bnk = this.allBanks.find((x) => x.id == bankId);
    if (bnk) return bnk.name;

    return "N/A";
  }

  ProcessSelectedPayments() {
    this.confirmationService.confirm({
      message:
        "You are about to process the selected expense payments for bank payments. This will post the requests to our payment platform and perform actual bank payment. It is an irreversible action, Do you still wish to proceed?",
      accept: () => {
        this.messageService.add({
          severity: "info",
          summary: "Notice",
          detail: "Processing selected expense payments for bank payment...",
        });

        let findError = this.selectedPayables.find(
          (x) => x.supplerBank == null && x.bankAccount == null
        );
        if (findError) {
          this.messageService.add({
            severity: "error",
            summary:
              "Err: One or more of the payments selected has no beneficiary account linked to it",
          });
          return;
        }

        const postData: PostExpensePaymentsVM = {
          payments: [],
        };
        this.selectedPayables.forEach((payable) => {
          postData.payments.push({
            PaymentId: payable.id,
            supplierBankId: payable.supplerBank ? payable.supplerBank.id : null,
            bankAccountId: payable.bankAccount ? payable.bankAccount.id : null,
          });
        });

        this.financeExpenseService.PostExpensePayments(postData).subscribe(
          async (data) => {
            if (data.responseCode != "00") {
              this.messageService.add({
                severity: "error",
                summary: "Notice",
                detail: data.responseMsg,
              });
              return;
            }

            this.messageService.add({
              severity: "success",
              summary: "Removed",
              detail: "Payment(s) processed for bank payments successfully",
            });
            this.allPayables = [];
            this.selectedPayables = [];
            this.FetchAllPaymentPayables();
          },
          (error) => {
            console.log("Error: " + JSON.stringify(error));
            this.messageService.add({
              severity: "error",
              summary: "Notice",
              detail:
                "Unable to process selected expense payment(s) for bank payment at the moment.. Reason: [" +
                (error ? error.error.message : "request failed - permission") +
                "]",
            });
          }
        );
      },
    });
  }

  GetRequestTypeLabel(payment: ExpenseBatchRequestPayment): string {
    let batch = this.allBatches.find((x) => x.id == payment.batchRequestId);
    let reqType = this.allRequestTypes.find((x) => x.key == batch.requestType);
    if (reqType) return reqType.value;

    return "N/A";
  }

  ShowAllAction() {
    this.fetching = true;
    this.allPayables = this.allPayablesWithoutFilter;
    this.fetching = false;
  }

  ViewApprovedDetails(item: any) {}

  ViewAuthorizedDetails(item: any) {}

  ViewChequeDetails(item: any) {}

  ShowAllChequeIssAction() {
    this.fetching = true;
    this.allPayables = this.allPayablesWithoutFilter.filter(
      (x) => x.isChequeLoaded
    );
    this.fetching = false;
  }

  ShowOnlyPendingAction() {
    this.fetching = true;
    this.allPayables = this.allPayablesWithoutFilter.filter(
      (x) => !x.isPostedToPaystack && x.isPosted
    );
    this.fetching = false;
  }

  ShowOnlyPaidAction() {
    this.fetching = true;
    this.allPayables = this.allPayablesWithoutFilter.filter(
      (x) => x.isPostedToPaystack
    );
    this.fetching = false;
  }

  ShowOnlyNotPaidAction() {
    this.fetching = true;
    this.allPayables = this.allPayablesWithoutFilter.filter(
      (x) => !x.isPostedToPaystack && !x.isPosted
    );
    this.fetching = false;
  }

  ViewPaymentVoucher(item: ExpenseBatchRequestPayment) {
    this.openMemoDialogue = true;
    this.generatingVoucher = true;
    this.financeExpenseService.GenerateExpensePaymentVoucher(item.id).subscribe(
      async (data) => {
        if (data.responseCode != "00") {
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail: data.responseMsg,
          });
          this.generatingVoucher = false;
          return;
        }

        this.source = data.responseData.source;
        this.chequeNo = data.responseData.chequeNo
          ? data.responseData.chequeNo
          : "N/A";
        this.beneficiary = data.responseData.beneficiary;
        this.voucherNo = data.responseData.voucherNo;
        this.voucherDate = data.responseData.voucherDate
          .toString()
          .substring(0, 10);
        this.batchItems = data.responseData.lineItems;
        this.amtInWords =
          this.NumInWords(data.responseData.amount) + "naira only";
        this.batchApprovals = [];
        data.responseData.approvals.forEach((app) =>
          this.batchApprovals.push({
            office: app.office,
            officer: this.GetUsername(app.officerId),
            status: app.status,
          })
        );
        this.generatingVoucher = false;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to generate payment voucher at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
        this.generatingVoucher = false;
      }
    );
  }

  PdfDownloadPage() {
    let memoName = localStorage.getItem("memoName");
    var node = document.getElementById("memoDiv");
    var img;
    var filename;
    var newImage;
    domtoimage
      .toPng(node, { bgcolor: "#fff" })
      .then(function (dataUrl) {
        img = new Image();
        img.src = dataUrl;
        newImage = img.src;
        img.onload = function () {
          var pdfWidth = img.width;
          var pdfHeight = img.height;
          // FileSaver.saveAs(dataUrl, 'my-pdfimage.png'); // Save as Image
          var doc;
          if (pdfWidth > pdfHeight) {
            doc = new jsPDF("l", "px", [pdfWidth, pdfHeight]);
          } else {
            doc = new jsPDF("p", "px", [pdfWidth, pdfHeight]);
          }
          var width = doc.internal.pageSize.getWidth();
          var height = doc.internal.pageSize.getHeight();

          doc.addImage(newImage, "PNG", 10, 10, width, height);
          filename = memoName + " Memo" + ".pdf";
          doc.save(filename);
        };
      })
      .catch(function (error) {
        // Error Handling
      });
  }

  HideMemoDialog() {
    this.openMemoDialogue = false;
    this.generatingVoucher = false;
    this.source = "";
    this.chequeNo = "";
    this.beneficiary = "";
    this.voucherNo = "";
    this.voucherDate = new Date(Date.now()).toDateString();
    this.batchItems = [];
    this.amtInWords = "";
    this.batchApprovals = [];
  }

  ShowPaymentDialogue(item: ExpenseBatchRequestPayment) {
    let bankAccountUsed: BankAccount | SupplierBank = null;
    let requestTypeLabel = this.GetRequestTypeLabel(item);
    if (requestTypeLabel == "Supplier")
      bankAccountUsed = this.allSupplierBanks.find(
        (x) => x.paystackTransferReference == item.accountNumberPosted
      );
    else if (requestTypeLabel != "Miscellanous")
      bankAccountUsed = this.allBankAccounts.find(
        (x) => x.paystackTransferReference == item.accountNumberPosted
      );

    this.paymentDetailTable[0].value = item.isPostedToPaystack + "";
    this.paymentDetailTable[1].value =
      item.datePostedToPaystack.toLocaleString();
    this.paymentDetailTable[2].value = item.transactionReference;
    this.paymentDetailTable[3].value =
      bankAccountUsed != null ? bankAccountUsed.accountNumber : "N/A";
    this.paymentDetailTable[4].value =
      bankAccountUsed != null ? bankAccountUsed.accountName : "N/A";
    this.paymentDetailTable[5].value = item.paystackResponse;

    this.showPaymentDialogue = true;
  }

  HidePaymentDialogue() {
    this.showPaymentDialogue = false;
  }

  NumInWords(number: number): string {
    const first = [
      "",
      "one ",
      "two ",
      "three ",
      "four ",
      "five ",
      "six ",
      "seven ",
      "eight ",
      "nine ",
      "ten ",
      "eleven ",
      "twelve ",
      "thirteen ",
      "fourteen ",
      "fifteen ",
      "sixteen ",
      "seventeen ",
      "eighteen ",
      "nineteen ",
    ];
    const tens = [
      "",
      "",
      "twenty",
      "thirty",
      "forty",
      "fifty",
      "sixty",
      "seventy",
      "eighty",
      "ninety",
    ];
    const mad = ["", "thousand", "million", "billion", "trillion"];
    let word = "";

    for (let i = 0; i < mad.length; i++) {
      let tempNumber = number % (100 * Math.pow(1000, i));
      if (Math.floor(tempNumber / Math.pow(1000, i)) !== 0) {
        if (Math.floor(tempNumber / Math.pow(1000, i)) < 20) {
          word =
            first[Math.floor(tempNumber / Math.pow(1000, i))] +
            mad[i] +
            " " +
            word;
        } else {
          word =
            tens[Math.floor(tempNumber / (10 * Math.pow(1000, i)))] +
            "-" +
            first[Math.floor(tempNumber / Math.pow(1000, i)) % 10] +
            mad[i] +
            " " +
            word;
        }
      }

      tempNumber = number % Math.pow(1000, i + 1);
      if (Math.floor(tempNumber / (100 * Math.pow(1000, i))) !== 0)
        word =
          first[Math.floor(tempNumber / (100 * Math.pow(1000, i)))] +
          "hunderd " +
          word;
    }
    return word;
  }
}
