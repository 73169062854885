import { Component, OnInit } from "@angular/core";
import { DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";
import { StrategicBusinessUnit, User } from "../../../../interfaces/home";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ConfirmationService } from "primeng/api";

@Component({
  selector: "app-add-dependent-beneficiary",
  templateUrl: "./add-dependent-beneficiary.component.html",
  styleUrls: ["./add-dependent-beneficiary.component.css"],
  providers: [ConfirmationService],
})
export class AddDependentBeneficiaryComponent implements OnInit {
  addDependentBeneficiaryForm: FormGroup;
  beneficiary: any;

  relationships: any[];
  meansOfId: any[];
  selectedMeansOfId: any;
  selectedRelationship: any;

  constructor(
    public config: DynamicDialogConfig,
    private formBuilder: FormBuilder,
    public attachModalRef: DynamicDialogRef,
    public confirmationService: ConfirmationService
  ) {}

  ngOnInit(): void {
    this.beneficiary = this.config.data.beneficiary;
    this.relationships = this.config.data.relationships;
    this.meansOfId = this.config.data.meansOfId;

    this.addDependentBeneficiaryForm = this.formBuilder.group({
      title: ["", Validators.required],
      firstName: ["", Validators.required],
      lastName: ["", Validators.required],
      middleName: ["", Validators.required],
      phone: ["", Validators.required],
      email: ["", Validators.required],
      address: ["", Validators.required],
      meansOfId: [null, Validators.required],
      relationship: [null, Validators.required],
      identificationNumber: ["", Validators.required],
    });
  }

  closeAddDependentBeneficiary() {
    this.attachModalRef.close();
  }

  addDependentBeneficiary() {
    this.attachModalRef.close(this.addDependentBeneficiaryForm.value);
  }
}
