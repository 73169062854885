import { HttpParams } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ConfirmEventType } from 'src/app/interfaces/armada';
import { Subscription } from 'rxjs';
import { BudgetapiservicesService } from 'src/app/services/budget/budgetapiservices.service';

enum Currency {
  "United State Dollar",
  "Nigerian Naira",
  "Canadian Dollars"
}

@Component({
  selector: 'app-upsert-budget-cycle',
  templateUrl: './upsert-budget-cycle.component.html',
  styleUrls: ['./upsert-budget-cycle.component.scss']
})
export class UpsertBudgetCycleComponent implements OnInit {
  @Input() budgetCycle: any;
  @Input() budgetSeasons: any;
  @Input() editRecord: boolean;
  @Output('reloadPage') reloadPage: EventEmitter<any> = new EventEmitter();
  budgetCycleForm = this.fb.group({
    // id: [''],
    budgetSeasonId: ['', Validators.required],
    caption: ['', Validators.required],
    description: [''],
    startDate: ['',Validators.required],
    endDate: ['',Validators.required],
    budgetYear: ['',Validators.required],
    endorsementType: [''],
    endorsementTypeId: ['',Validators.required],
    baseValue: ['',Validators.required],
    currencyId: ['',Validators.required],
    // baseCurrency: [''],
    quoteCurrency: [''],
    benchMarkValue: ['']
  })

  selectedBudgetSeason: any []= []
  selectedBudgetSeasonCaption= ""
  selectedEndorsementType = ""
  range: any=[];
  years: any=[];
  responseData: any=[];
  currencyList: any[]=[];
  endorsementList: any[];
  budgetSeasonList: any []=[];
  budgetCycleList: any []=[];
  budgetCycleInSeason: any[]=[];
  baseValue: number;
  selectedBaseCurId: any;
  selectedBaseCurrency: string = '';
  selectedQuoteCur: any;
  selectedBudgetSeasonId: number;
  selectedEndorsementTypeId: any;
  selectedBudgetYear: number;
  exchangeRate: number;
  startDate: any;
  endDate: any;
  id = ''
  loadingData: boolean = false;
  existingEdorsementType: boolean = false;
  anyOverlappingDate: boolean = false;
  isBusinessExisting: boolean = false;
  isDateOutsideBudgetYear: boolean = false;
  isCycleYearOutsideSeasonYear: boolean = false;
  captionExist: boolean = false;
  budgetCycleSubscription: Subscription | undefined;
  budgetSeasonSubscription: Subscription | undefined;
  isControlEditable: boolean = false;
  eidtFormRecord: boolean = false;
  isFormBusy: boolean = false;
  isFirstTime: boolean = false;

  constructor(private fb: FormBuilder, 
              private service: BudgetapiservicesService,
              private confirmationService: ConfirmationService,
              private messageService: MessageService) { }

  ngOnInit(): void {
    console.log(this.budgetCycle);
    console.log(this.budgetSeasons);
    this.eidtFormRecord = this.editRecord;
    this.generateCalandarYear();
    this.getCurrencyList();
    this.getEndorsementList();
    console.log(`Endorement List: ${this.endorsementList}` )
    
    // this.budgetSeasonSubscription = this.getBudgetSeaons();
    // this.budgetCycleSubscription = this.getAllBudgetCycles();
    if (this.editRecord)
        this.populateRecord();
    console.log(this.selectedBudgetSeason)

    console.log(`Overlapping Date: ${this.anyOverlappingDate}`);
    // this.selectedBaseCurId= this.budgetCycle.currencyName;
    // this.endDate = this.getDateOnly(this.budgetCycle.endDate)
    // this.startDate = this.getDateOnly(this.budgetCycle.startDate)  
    
  }

  ngOnDestroy(): void {
    // this.allBudgetCats.unsubscribe();
    // this.budgetCycleSubscription.unsubscribe();
    // this.budgetSeasonSubscription.unsubscribe();
    console.log("CLEANING UP!!!!");
    console.log(`Overlapping Date: ${this.anyOverlappingDate}`);
  }

  displayMessage(severity, summary, message) {
    this.messageService.add({
      severity: severity,
      summary: summary,
      detail: message,
    });
  }
  populateRecord() {
    this.budgetCycleForm.patchValue({
      budgetSeasonId: this.budgetCycle.seasonId,
      caption: this.budgetCycle.caption,
      description: this.budgetCycle.description,
      startDate: new Date(this.budgetCycle.startDate),
      endDate: new Date(this.budgetCycle.endDate),
      budgetYear: this.budgetCycle.budgetYear,
      endorsementTypeId: this.budgetCycle.endorsementTypeId,
      endorsementType: this.budgetCycle.endorsementType,
      baseValue: this.budgetCycle.baseValue,
      currencyId: this.budgetCycle.currencyId,
      benchMarkValue: this.budgetCycle.benchMarkValue
    });
    
    if (this.editRecord){
      this.selectedEndorsementTypeId = this.budgetCycle.endorsementTypeId;
      this.endDate = this.getDateOnly(this.budgetCycle.endDate)
      this.startDate = this.getDateOnly(this.budgetCycle.startDate) 
      this.selectedBaseCurId = this.budgetCycle.currencyId;
      this.selectedBudgetSeasonId = this.budgetCycle.budgetSeason.id;
      this.selectedBudgetSeasonCaption = this.budgetCycle.budgetSeason.caption;
      this.selectedEndorsementType = this.budgetCycle.endorsementType;
      this.selectedBaseCurrency = this.budgetCycle.currencyName;
      this.selectedBudgetYear = this.budgetCycle.budgetYear;
      this.selectedBudgetSeason = this.budgetSeasons.filter(season => {
        return season.caption.toLowerCase()=== this.budgetCycle.budgetSeason.caption.toLowerCase()
      })
    }
    
    
    
    
    console.log(this.budgetCycleForm.value)
    console.log(`Endorsement Type: ${this.selectedEndorsementType}`)

    console.log(`Endorsement TypeId: ${this.selectedEndorsementTypeId}`)
    
    console.log(this.selectedBudgetSeason[0])
  }

  getAllBudgetCycles() {
    this.loadingData = true;
    return this.service.getBudgetCycleList().subscribe( res => {
      this.budgetCycleList = res['responseData'];
      console.log(this.budgetCycleList);
      this.loadingData = false;
    }) 
  }  


  getDateOnly(value: any) {
    if ( value == "")
      return new Date(); // value
    let date = [new Date(value).getMonth()+1, new Date(value).getDate(), new Date(value).getFullYear()].join('/');
    console.log(date);
    // this.endDate = 
    return date;
  }
  // setFormValue(){
  //   let values = {
  //     budgetSeasonId: this.budgetCycle.budgetSeasonId,
  //     caption: this.budgetCycle.caption,
  //     description: this.budgetCycle.description,
  //     startDate: this.budgetCycle.startDate,
  //     endDate: this.budgetCycle.endDate,
  //     budgetYear: this.budgetCycle.budgetYear,
  //     endorsementTypeId: this.budgetCycle.endorsementTypeId,
  //     baseValue: this.budgetCycle.baseValue,
  //     currencyId: this.budgetCycle.currencyId,
  //     benchMarkValue: this.budgetCycle.benchMarkValue
  //   }
  // }
  checkStartDate(event){
    console.log(event)
    
    console.log(new Date(this.startDate).getFullYear());
    console.log(this.selectedBudgetYear);
    
    this.startDate = event;
    console.log(new Date(this.startDate));
    this.anyOverlappingDate = false;
    this.isBusinessExisting = false;
    this.isDateOutsideBudgetYear = false;

    if (this.selectedBudgetYear !== new Date(this.startDate).getFullYear())
    {
      this.isDateOutsideBudgetYear = true;
      this.displayMessage("warn", "Warning", "Selection outside budget year in not allowed")
      return false;
    }

    if (this.selectedBudgetYear){
      if (new Date(this.startDate).getFullYear() !== this.selectedBudgetYear){
        this.displayMessage('warn',"Warning", "Date must be within selected budget year");
        this.isDateOutsideBudgetYear = true;
        return false;
      }
    }
    if (new Date(this.startDate).valueOf() > new Date(this.endDate).valueOf()) {        
      this.displayMessage("warn", "information", "End date cannot be lower than start date");
      return false;
    }
    console.log(this.selectedEndorsementTypeId)
    if (this.selectedEndorsementTypeId) {
      console.log("Inside selectedEndorsementType")
      console.log(this.selectedBudgetSeason[0])
      this.selectedBudgetSeason[0].budgetCycles.forEach(el => {
        this.checkOverlappingDates(el.endorsementTypeId, el.startDate, el.endDate);
      });

      if (this.isBusinessExisting){
        this.displayMessage("warn", "information", "The same Business already exist for selected dates.");
        return false;
      }
      
      if (this.anyOverlappingDate){
        this.displayMessage("warn", "information", "Dates cannot overlap for the same business");
        return false;
      }

      
    }
  }

 
  padTo2Digits(num){
    return num.toString().padStart(2, '0');
  }
  compareDates(event) {
    console.log(event)
    this.anyOverlappingDate = false;
    this.isBusinessExisting = false;
    this.isDateOutsideBudgetYear = false;
    this.endDate = event;
    let eDate = new Date(this.endDate);

    if (this.selectedBudgetYear !== new Date(this.endDate).getFullYear())
    {
      this.isDateOutsideBudgetYear = true;
      this.displayMessage("warn", "Warning", "Selection outside budget year in not allowed")
      return false;
    }

    if (this.selectedBudgetYear)
    {
      if (eDate.getFullYear() !== this.selectedBudgetYear){
        this.displayMessage('warn',"Warning", "Date must be within selected budget year");
        this.isDateOutsideBudgetYear = true;
        return false;
      }
    }
    
    console.log(`${eDate.getFullYear()}-${this.padTo2Digits(eDate.getMonth()+1)}-${eDate.getDate()}T${this.padTo2Digits(eDate.getHours())}:${this.padTo2Digits(eDate.getMinutes())}:${this.padTo2Digits(eDate.getSeconds())}`)
    // console.log(this.endDate.getTime() < this.startDate.getTime())
    console.log(new Date(this.startDate).valueOf() > new Date(this.endDate).valueOf())
    
    if (new Date(this.startDate).valueOf() >= new Date(this.endDate).valueOf()) {        
      this.displayMessage("warn", "information", "End date cannot be lower than or equal start date");
      return false;
    }
    if (this.selectedEndorsementTypeId) {
      this.selectedBudgetSeason[0].budgetCycles.forEach(el => {
        this.checkOverlappingDates(el.endorsementTypeId, el.startDate, el.endDate);
      });
    }
    if (this.anyOverlappingDate){
      this.displayMessage("warn", "information", "Dates cannot overlap for the same business");
      return false;
    }
    if (this.isBusinessExisting){
      this.displayMessage("warn", "information", "The same Business already exist for selected dates.");
      return false;
    }
    

  }
  selectBudgetCycleYearChange(e: any){
    if (!this.editRecord)
        this.selectedBudgetYear = e;
    console.log(e);
    console.log(new Date(2024,0))
    let eDate = `${e}/1/1`
    this.endDate = this.getDateOnly(new Date(2024,0))
    this.startDate = this.getDateOnly(new Date(2024,0))
    this.budgetCycleForm.get('startDate').setValue(this.startDate)
    this.budgetCycleForm.get('endDate').setValue(this.endDate)
    this.isCycleYearOutsideSeasonYear = false;
    if (this.selectedBudgetSeason[0].budgetYear !== this.selectedBudgetYear )
    {
      this.displayMessage("warn", "Warning", "Selected cycle year must be the sane year as selected season");
      this.isCycleYearOutsideSeasonYear = true;
      return;
    }
  }
  budgetSeasonChange(e: any) {
    // this.budgetCycleForm.get('budgetSeasonId').setValue(e)
        
    this.budgetCycleInSeason = [];
    console.log(`SELECTED BUDGET SEASON ID: ${this.selectedBudgetSeasonId}`)
    console.log(this.budgetSeasons)
    let selectedSeason: any;
    if (!this.editRecord) {
      this.selectedBudgetSeasonId = e;
      this.selectedBudgetSeason = this.budgetSeasons.filter(season => {
        return season.id == e;
      })
    }
   
    console.log(this.selectedBudgetSeason);
    // this.loadingData = true;
    if(this.selectedBudgetSeason.length > 0) {
      this.selectedBudgetSeason[0]['budgetCycles'].forEach((elem, ind) => {
        if (Number(elem.seasonId) === this.selectedBudgetSeasonId) {
          console.log(`Index: ${ind}, SeasonId: ${elem.seasonId}`);
          this.budgetCycleInSeason.push(elem);
        }
      });
    }
    
    // this.budgetCycleList.forEach((elem, ind) => {
    //   if (Number(elem.seasonId) === this.selectedBudgetSeasonId) {
    //     console.log(`Index: ${ind}, SeasonId: ${elem.seasonId}`);
    //     this.budgetCycleInSeason.push(elem);
    //   }
    // })
    console.log(this.budgetCycleInSeason)
    // this.getAllBudgetCyclesInSeason(this.selectedBudgetSeasonId);
    // this.loadingData = false;
  }

  endorsementTypeChange(e: any) {
    if( !this.editRecord) {
      this.selectedEndorsementTypeId = e;
    }
        
    console.log(` Inside change ${this.selectedEndorsementTypeId}`)
    this.existingEdorsementType = false;
    this.anyOverlappingDate = false;
    this.isBusinessExisting = false;
    console.log(e);
    console.log(this.budgetSeasonList);
    
    // let existingEdorsementType: boolean = false;
    console.log(` Is record editing: ${this.editRecord}`)
    if (!this.editRecord) {
      this.selectedBudgetSeason[0].budgetCycles.forEach(el => {
        console.log(el);
        this.checkOverlappingDates(el.endorsementTypeId, el.startDate, el.endDate);
      });
    }
    

    if (this.anyOverlappingDate){
      this.displayMessage("warn", "information", "Dates cannot overlap for the same business");
      return false;
    }
    if (this.isBusinessExisting){
      this.displayMessage("warn", "information", "The same Business already exist for selected dates.");
      return false;
    }

    // if (this.existingEdorsementType){
    //   this.displayMessage("warn", "Warning", "A budget cycle with same endorsement type already exist.");
    //   return false;
    // }

  }
  baseCurrencyChange(e: any){
    // this.budgetCycleForm.get('currency').value(e.target.value)
    // this.currency?.setValue(e.target.value,{
    //   onlySelf: true
    // })
    // this.baseValue = new Intl.NumberFormat('en-NG').format(this.budgetCycleForm.get('baseValue').value);
    // this.baseValue = this.budgetCycleForm.get('baseValue').value
    // this.budgetCycleForm.get('benchMarkValue').setValue(this.baseValue);
    // let ee = document.querySelector('#baseCur');
    // console.log(ee.textContent)
    if(!this.editRecord)
        this.selectedBaseCurId = e
    console.log(` SELECTED BASE CUR ${this.selectedBaseCurId}`);
    // console.log(e);;
  }
  
  quoteCurrencyChange(e: any) {
    this.baseValue = this.budgetCycleForm.get('baseValue').value

    console.log(this.selectedQuoteCur)
   
    
    let data ={
      'baseCurId': this.selectedBaseCurId,
      'quoteCurId': this.selectedQuoteCur
    };
    // params.append('',data)
    console.log(data);
    this.service.getExchangeRates(data).subscribe(res => {
      // console.log(res);
      this.exchangeRate = res['responseData'];
      // console.log(typeof(this.baseValue));
      let convertedCur = this.baseValue * this.exchangeRate;
      // console.log(convertedCur);
      this.budgetCycleForm.get('benchMarkValue').setValue(convertedCur);
      // console.log(this.selectedQuoteCur);
    }//, err => {
    //   this.displayMessage('warn','Warning',err.message)
    // }
    );
    
  }

  getCurrencyList(){
    return this.service.getCurrencies().subscribe(data => {
      this.currencyList = data['responseData']['currencyList'];
      console.log(data);
    })
  }

  getEndorsementList(){
    return this.service.getEndorsementTypes().subscribe(data => {
      this.endorsementList = data['responseData']['endorseTypeList'];
      console.log(data);
      console.log(this.endorsementList);
      // let endorsementCopy = this.endorsementList.slice();

      this.endorsementList = this.endorsementList.filter((rec) => {
        return rec.isBudgetRelated
      });
      console.log(this.endorsementList);
      console.log(this.budgetCycle)
    })
  }
  generateCalandarYear(){
    var year = new Date().getFullYear();
    console.log(year);
    this.range.push(year);
    this.range.push(++year);
    // for (let i = 1; i < 2; i++) {
    //   this.range.push(year + i);
    // }
    console.log(this.range);
    this.years = this.range;
  }
  getBudgetSeaons(){
    return this.service.getBudgetSeasons().subscribe(data => {
      // this.budgetSeasonList = data['responseData'];
      // this.budgetSeasonList.forEach( elem => {
      //   if (elem.)
      // })
      // console.log(data['responseData']);
      data['responseData'].forEach(element => {
        // console.log(element)
        // console.log((new Date(element.prepEndDate).getTime() > new Date().getTime()))
        if(element.isPublished == true && (new Date(element.prepEndDate).valueOf() > new Date().valueOf()) )
          this.budgetSeasonList.push(element);
          // console.log(element)
      });
      // console.log(data['responseData'][0]);
    });
  }
  createBudgetCycle(){
    this.confirmationService.confirm({
      message: !this.editRecord ? `Proceed with saving this record?` : 'Proceed with updating this record?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        // this.messageService.add({ severity: 'info', summary: 'Confirmed', detail: 'You have accepted' });
        // this.checkIfHasDistributions();
            this.loadingData = true;
            this.isFormBusy = true;
            setTimeout(() => {
              this.displayMessage("info", "Information", "Processing operation")
            }, 1000);
            setTimeout(() => {
              this.confirmedBudgetCycleCreation();
              this.loadingData=false;
              this.isFormBusy=false;
            }, 2000)
            // this.loadingData = false;
            // this.isFormBusy = false;
            
        
      },
      reject: (type: ConfirmEventType) => {
          switch (type) {
              case ConfirmEventType.REJECT:
                  this.messageService.add({ severity: 'warn', summary: 'Cancelled', detail: 'You have cancelled this operation' });
                  break;
              case ConfirmEventType.CANCEL:
                  this.messageService.add({ severity: 'warn', summary: 'Cancelled', detail: 'You have cancelled this operation' });
                  break;
          }
        }
  });
    
  }

  confirmedBudgetCycleCreation() {
    console.log(`isDateOutsideBudgetYear is ${this.isDateOutsideBudgetYear}`)
    if (this.isCycleYearOutsideSeasonYear) {
      this.displayMessage("warn", "Warning", "Selected cycle year must be the sane year as selected season");
      return false;
    }
    if (this.captionExist){
      this.displayMessage("error", "Error", "A caption with this name already exist");
      return false;
    }
    if (new Date(this.startDate).valueOf() > new Date(this.endDate).valueOf()) {        
      this.displayMessage("warn", "information", "End date cannot be lower than start date");
      return false;
    }
    let benchMarkValue = this.budgetCycleForm.get('benchMarkValue').value;
    if (benchMarkValue == 0 || benchMarkValue == '' || benchMarkValue == undefined)
    {
      this.displayMessage("error", "information", "Benchmark value must be greater than zero");
      return false;
    }

    if ( this.existingEdorsementType)
    {
      this.displayMessage("error", "Error", "A budget cycle with same endorsement type already exist.");
      return false;
    }
    if (this.isDateOutsideBudgetYear === true)
    {
      this.displayMessage("error", "Error", "Date is outside selected budget year");
      return false;
    }
    if (this.anyOverlappingDate){
      this.displayMessage("error", "Error", "Dates cannot overlap");
      return false;
    }
    if (this.isBusinessExisting){
      this.displayMessage("error", "Error", "The same Business already exist for selected dates.");
      return false;
    }

    let data = this.budgetCycleForm.value;
    // var data = this.budgetCycle;
    console.log(this.selectedBaseCurId)
    console.log(this.budgetCycle)
    console.log(this.budgetCycleForm.value)
    console.log(`Data: ${data}`)
    data['budgetSeasonId'] = this.selectedBudgetSeasonId == null  ? this.budgetCycle.seasonId : this.selectedBudgetSeasonId; // data['budgetSeasonId'];
    data['endorsementTypeId'] =  this.selectedEndorsementTypeId == null ? this.budgetCycle.endorsementTypeId : this.selectedEndorsementTypeId;
    data['currencyId'] =  this.selectedBaseCurId == null ? this.budgetCycle.currencyId : this.selectedBaseCurId;
    console.log(`Data: ${data}`)
    // return;
    
    if (this.budgetCycle.id != 0)
    {
      console.log(`update record .... with budget id ${this.budgetCycle.id}`)
      data['id'] =  this.budgetCycle.id;
      
      
      this.service.updateBudgetCycle(this.budgetCycle.id, data).subscribe( res => {
        console.log(res);
        if (res['responseData'] == null)
        {
          setTimeout(() => {
            this.displayMessage("error", "Error", "Not successful");
            return false;
          }, 2000);
          this.loadingData = false;
          this.isFormBusy = false;
        }
        else{
          setTimeout(() => {
            this.displayMessage("success", "Success", "Budget cycle updated successfully");
          }, 2000);
          this.loadingData = false;
          this.isFormBusy = false;
          setTimeout(() => {
            this.reloadPage.emit();
          }, 3000);
        }
        
      }, (err) => {
        setTimeout(() => {
          this.displayMessage("error", "Error", `Error updating Budget cycle:\n ${JSON.stringify(err)}`);
          console.clear();
          console.log(err);
          this.loadingData = false;
            this.isFormBusy = false;
        }, 1000);
      });
      // return false;
    }
    else {
            
      // this.displayMessage("info", "information", "Processing...");
      
      this.service.createBudgetCycle(data).subscribe( res => {
        console.log(res);
        if (res['responseCode'] == "00") {
          setTimeout(() => {
            this.displayMessage("success", "Success", "Budget cycle created successfully");
          }, 2000);
          this.loadingData = false;
          this.isFormBusy = false;
          setTimeout(() => {
            this.reloadPage.emit();
          }, 3000);
        }
        
      }, (err) => {
        setTimeout(() => {
          this.displayMessage("error", "Error", `Error saving Budget cycle:\n ${err}`);
          this.loadingData = false;
          this.isFormBusy = false;
        }, 1000);
      });
    }
  }

  checkIfCaptionExist(e: any){
    this.captionExist = false;
    this.selectedBudgetSeason[0].budgetCycles.forEach(item => {
      if (item.caption.toLowerCase() === e.toLowerCase()){
        this.captionExist = true;
      }
    })
  }

  checkOverlappingDates(endorsementTypeID, dbSDate, dbEDate ) {
    const DB_START_DATE = new Date(this.getDateOnly(dbSDate)).getTime();
    const DB_END_DATE = new Date(this.getDateOnly(dbEDate)).getTime();
    const SELECTED_START_DATE = new Date(this.getDateOnly(this.startDate)).getTime();
    const SELECTED_END_DATE = new Date(this.getDateOnly(this.endDate)).getTime()

    console.log(`Selected Start Date: ${this.getDateOnly(this.startDate)}`)
    console.log(`Selected End Date: ${this.getDateOnly(this.endDate)}`)
    console.log(`DB Start Date: ${this.getDateOnly(new Date(dbSDate))}`)
    console.log(`DB End Date: ${this.getDateOnly(new Date(dbEDate))}`)
    
    if (endorsementTypeID === this.selectedEndorsementTypeId
      && (SELECTED_START_DATE === DB_START_DATE && SELECTED_END_DATE === DB_END_DATE) 
   )
  {
      this.isBusinessExisting = true;
      console.log(`Is Business Existing: ${this.isBusinessExisting}`)

  }
    if (
        endorsementTypeID === this.selectedEndorsementTypeId
        &&  ((SELECTED_START_DATE >= DB_START_DATE && SELECTED_START_DATE <= DB_END_DATE) 
        || (SELECTED_START_DATE <= DB_START_DATE && SELECTED_END_DATE >= DB_START_DATE))
      ) //(SELECTED_START_DATE > DB_START_DATE && SELECTED_START_DATE <= DB_END_DATE) 
       //|| (SELECTED_START_DATE < DB_START_DATE && SELECTED_END_DATE > DB_START_DATE)
    {
      this.anyOverlappingDate = true;
    }
    // else{
    //   this.anyOverlappingDate = false;
    // }

    
  }

  checkOverlappingEndDates(endorsementTypeID, dbSDate, dbEDate ) {
    const DB_START_DATE = new Date(dbSDate).getTime();
    const DB_END_DATE = new Date(dbEDate).getTime();
    const SELECTED_START_DATE = new Date(this.startDate).getTime();
    const SELECTED_END_DATE = new Date(this.endDate).getTime()
    
    if (
        endorsementTypeID === this.selectedEndorsementTypeId
        &&  (SELECTED_START_DATE > DB_START_DATE && SELECTED_START_DATE <= DB_END_DATE) 
        || (SELECTED_START_DATE < DB_START_DATE && SELECTED_END_DATE > DB_START_DATE)
          
      ) 
      // (DB_END_DATE < SELECTED_END_DATE && SELECTED_START_DATE <= DB_END_DATE) 
      //  || (SELECTED_START_DATE <= DB_START_DATE && DB_START_DATE <= SELECTED_END_DATE )
      //     || (DB_START_DATE < SELECTED_START_DATE && SELECTED_END_DATE < DB_END_DATE )
    {
      this.anyOverlappingDate = true;
    }

    if (endorsementTypeID === this.selectedEndorsementTypeId
          && (SELECTED_START_DATE === DB_START_DATE && SELECTED_END_DATE === DB_END_DATE) 
       )
      {
          this.isBusinessExisting = true;
      }
  }
}
