import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ConfirmationService, Message, MessageService } from "primeng/api";
import { BreadcrumbService } from "src/app/breadcrumb.service";
import { PayrollService } from "src/app/services/payroll.service";
import { Location } from "src/app/interfaces/home";
import {
  LogOperativeAbsenceReportVM,
  OperativeAbsenceReasonEnum,
  OperativesAbsent,
} from "src/app/interfaces/payroll";
import { DeployedGuard, GuardDeployment } from "src/app/interfaces/employment";

@Component({
  selector: "app-payroll-absence-req",
  templateUrl: "./payroll-absence-req.component.html",
  styleUrls: ["./payroll-absence-req.component.scss"],
  providers: [MessageService],
})
export class PayrollAbsenceReqComponent implements OnInit {
  @ViewChild("formWrapper") public formWrapper: ElementRef;
  absenceForm: FormGroup;
  cautionForm: FormGroup;
  msg: Message[] = [];
  msgInfo: Message[] = [];
  allLocations: Location[] = [];
  fetchingPendingAbsence: boolean = false;
  allPendingAbsenceReq: OperativesAbsent[] = [];
  selectedPendingAbsenceReq: OperativesAbsent;
  cols: any[];
  locationInView: Location;
  locationInViewOperatives: GuardDeployment[] = [];
  openAbsenceReportDialogue: boolean;
  operativeCapturingAbsence: DeployedGuard;
  allReasons: {
    key: number;
    value: string;
  }[];
  theReason: {
    key: number;
    value: string;
  };
  openCautionDialogue: boolean;
  cautionText: string;
  cautionCode: number;

  constructor(
    private fb: FormBuilder,
    private payrollService: PayrollService,
    private breadcrumbService: BreadcrumbService,
    public confirmationService: ConfirmationService,
    public messageService: MessageService
  ) {
    this.absenceForm = fb.group({
      DateAbsent: ["", Validators.required],
      Reason: ["", Validators.required],
      Comment: ["", Validators.required],
    });

    this.cautionForm = fb.group({});
  }

  ngOnInit(): void {
    this.breadcrumbService.setItems([
      {
        label: "Payroll Management",
        routerLink: ["/home/payroll/payroll-settings"],
      },
      {
        label: "Station Absence Request",
        routerLink: ["/home/payroll/station-absence-req"],
      },
    ]);

    this.cols = [
      {
        field: "jobApplication.personalInformation.firstname",
        header: "JobApplication.personalInformation.firstname",
      },
      {
        field: "jobApplication.personalInformation.lastname",
        header: "JobApplication.personalInformation.lastname",
      },
      { field: "dateAbsent", header: "DateAbsent" },
      { field: "comment", header: "Comment" },
    ];

    this.allReasons = [
      {
        key: OperativeAbsenceReasonEnum.Training,
        value: "Training",
      },
      {
        key: OperativeAbsenceReasonEnum.Absendant,
        value: "Absendant",
      },
    ];

    this.ResetMessageToasters();
    this.FetchAllClusterLocations();
    this.FetchUserActiveReport();
  }

  ResetMessageToasters() {
    this.msg = [];
    this.msg.push({
      severity: "info",
      summary: "Notice:",
      detail: "You manage " + this.allLocations.length + " Locations in total.",
    });

    this.msgInfo = [];
    this.msgInfo.push({
      severity: "info",
      summary: "Notice:",
      detail: "Click to indicate absence from station.",
    });
  }

  FetchAllClusterLocations() {
    this.payrollService.GetAllClustersTiedToMe().subscribe(
      async (data) => {
        this.allLocations = [];
        data.forEach((x) => {
          x.locations.forEach((loc) => this.allLocations.push(loc));
        });
        this.ResetMessageToasters();
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable get all clients at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
        this.ResetMessageToasters();
      }
    );
  }

  FetchUserActiveReport() {
    this.payrollService.GetMyActiveAbsenceReport().subscribe(
      async (data) => {
        this.allPendingAbsenceReq = data.operativesAbsent;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable get my active report at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
        this.ResetMessageToasters();
      }
    );
  }

  GetOperativeCount(item: Location): number {
    return item.guardDeployments.filter((x) => x.isDeleted == false).length;
  }

  LoadLocationOperatives(item: Location) {
    this.locationInView = null;
    this.payrollService.GetAllLocationDeployedGuards(item.id).subscribe(
      async (data) => {
        this.locationInViewOperatives = data;
        this.locationInView = item;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable get location guards at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
        this.ResetMessageToasters();
      }
    );
  }

  LogForAbsence(item: DeployedGuard) {
    this.operativeCapturingAbsence = item;
    this.openAbsenceReportDialogue = true;
    this.absenceForm.reset();
    this.theReason = null;
  }

  LogOPAbsenceReport() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Logging Operative Absence Report...",
    });
    this.ResetMessageToasters();

    const postData: LogOperativeAbsenceReportVM = {
      dateAbsent: this.absenceForm.get("DateAbsent").value,
      reason: this.theReason.key,
      comment: this.absenceForm.get("Comment").value,
      deployedGuardId: this.operativeCapturingAbsence.id,
      locationId: this.locationInView.id,
    };

    let opAbsenceExists = this.allPendingAbsenceReq.filter(
      (x) => x.deployedGuardId == postData.deployedGuardId
    );
    if (opAbsenceExists) {
      let canProceed: boolean = true;
      opAbsenceExists.forEach((op) => {
        if (
          op.dateAbsent.toString().slice(0, 10) ==
          postData.dateAbsent.toString()
        ) {
          canProceed = false;
        }
      });

      if (!canProceed) {
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "You have already logged an absence report for this operative for the selected date.",
        });
        this.ResetMessageToasters();
        return;
      }
    }

    this.payrollService.LogOperativeAbsenceReport(postData).subscribe(
      async () => {
        this.messageService.add({
          severity: "success",
          summary: "Completed",
          detail: "Operative Absence Report Logged Successfully!",
        });
        this.ResetMessageToasters();

        this.absenceForm.reset();
        this.theReason = null;
        this.openAbsenceReportDialogue = false;
        this.operativeCapturingAbsence = null;
        this.FetchUserActiveReport();
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to log operative absence report at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
        this.ResetMessageToasters();
      }
    );
  }

  ClearPendingAbsentApprovals() {
    this.cautionText =
      "Are you sure you want to clear your absence report. This is an irreversible action. Do you still wish to proceed?";
    this.cautionCode = 1;
    this.openCautionDialogue = true;
  }

  SendPendingAbsentForApproval() {
    this.cautionText =
      "Are you sure you want to send your absence report for approval. This is an irreversible action. Do you still wish to proceed?";
    this.cautionCode = 2;
    this.openCautionDialogue = true;
  }

  HideOPAbsenceDialog() {
    this.openAbsenceReportDialogue = false;
    this.operativeCapturingAbsence = null;
    this.absenceForm.reset();
    this.theReason = null;
  }

  GetReasonLabel(identifier: number): string {
    if (identifier == OperativeAbsenceReasonEnum.Training) return "Training";
    if (identifier == OperativeAbsenceReasonEnum.Absendant) return "Absendant";
    return "N/A";
  }

  HideCautionDialog() {
    this.openCautionDialogue = false;
    this.cautionCode = null;
    this.cautionText = null;
  }

  CautionAction() {
    if (this.cautionCode == 1) {
      this.messageService.add({
        severity: "info",
        summary: "Notice",
        detail: "Clearing Operative Absence Report...",
      });
      this.ResetMessageToasters();

      this.payrollService.ClearMyOperativeAbsenceReport().subscribe(
        async () => {
          this.messageService.add({
            severity: "success",
            summary: "Completed",
            detail: "Operative Absence Report Logged Successfully!",
          });
          this.ResetMessageToasters();
          this.allPendingAbsenceReq = [];
          this.HideCautionDialog();
        },
        (error) => {
          console.log("Error: " + JSON.stringify(error));
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail:
              "Unable to clear absence report at the moment.. Reason: [" + error
                ? error.error.message
                : "request failed - permission" + "]",
          });
          this.ResetMessageToasters();
          this.HideCautionDialog();
        }
      );
    } else if (this.cautionCode == 2) {
      this.messageService.add({
        severity: "info",
        summary: "Notice",
        detail: "Sending Operative Absence Report For Approval...",
      });
      this.ResetMessageToasters();

      this.payrollService.SendMyOperativeReportForApproval().subscribe(
        async () => {
          this.messageService.add({
            severity: "success",
            summary: "Completed",
            detail: "Operative Absence Report Sent For Approval Successfully!",
          });
          this.ResetMessageToasters();
          this.allPendingAbsenceReq = [];
          this.HideCautionDialog();
        },
        (error) => {
          console.log("Error: " + JSON.stringify(error));
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail:
              "Unable to send absence report for approval at the moment.. Reason: [" +
              error
                ? error.error.message
                : "request failed - permission" + "]",
          });
          this.ResetMessageToasters();
          this.HideCautionDialog();
        }
      );
    }
  }
}
