import { Observable } from "rxjs";
import { AddSupplierBankAccountVM, Supplier } from "./../interfaces/supplier";
import { Supplier as ArmadaSupplier } from "./../interfaces/armada";
import { environment } from "src/environments/environment";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { CommonResponse } from "../interfaces/home";

@Injectable({
  providedIn: "root",
})
export class SupplierService {
  baseUrl = environment.baseUrl + "Supplier/";

  constructor(private http: HttpClient) {}

  getAll(): Observable<CommonResponse> {
    return this.http.get<CommonResponse>(this.baseUrl);
  }

  getByID(id: number): Observable<CommonResponse> {
    return this.http.get<CommonResponse>(this.baseUrl + id);
  }

  create(data: ArmadaSupplier): Observable<CommonResponse> {
    return this.http.post<CommonResponse>(this.baseUrl, data);
  }

  delete(id: number) {
    return this.http.delete(this.baseUrl + id);
  }

  update(id: any, data: ArmadaSupplier): Observable<CommonResponse> {
    return this.http.put<CommonResponse>(this.baseUrl + id, data);
  }

  GetAllSuppliersWithBank(): Observable<CommonResponse<Supplier[]>> {
    return this.http.get<CommonResponse<Supplier[]>>(
      this.baseUrl + "GetAllSuppliersWithBank"
    );
  }

  AddSupplierBankAccount(
    data: AddSupplierBankAccountVM
  ): Observable<CommonResponse<boolean>> {
    return this.http.post<CommonResponse<boolean>>(
      this.baseUrl + "AddSupplierBankAccount",
      data
    );
  }
}
