import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {CommonResponse, Division, OperatingEntity, ServiceGroup, User} from '../../../interfaces/home';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ServiceGroupService} from '../../../services/service-group.service';
import {ConfirmationService, MessageService} from 'primeng/api';
import {OperatingEntityService} from '../../../services/operating-entity.service';
import {DivisionService} from '../../../services/division.service';

@Component({
  selector: 'app-service-group',
  templateUrl: './service-group.component.html',
  styleUrls: ['./service-group.component.scss']
})
export class ServiceGroupComponent implements OnInit {
  @ViewChild('formWrapper') public formWrapper:ElementRef;
  fetchingServiceGroup = true;
  operatingEntities: OperatingEntity[];
  serviceGroupForm: FormGroup;
  serviceGroups: ServiceGroup[];
  selectedServiceGroup: ServiceGroup;
  editingServiceGroup= false;
  public serviceGroupCols: any[];
  public exportServiceGroupColumns: any[];
  selectedOperatingEntityId: OperatingEntity;
  private edServiceGroup: ServiceGroup;
  selectedDivisionId: Division;
  divisions: Division[];
  constructor(
      private formBuilder: FormBuilder,
      private serviceGroupService: ServiceGroupService,
      public messageService: MessageService,
      public confirmationService: ConfirmationService,
      private operatingEntityService: OperatingEntityService,
      private divisionService: DivisionService,
  ) { }

  ngOnInit(): void {
    this.fetchServiceGroup();
    this.serviceGroupForm = this.formBuilder.group({
      name: ['', Validators.required],
      description: ['', Validators.required],
      divisionId: [null, [Validators.required]],
      operatingEntityId: [null, Validators.required]
    });
    this.serviceGroupCols = [
      { field: 'name', header: 'Name' },
      { field: 'description', header: 'Description' },
      { field: 'operatingEntity', header: 'Operating Entity' }
    ];
    this.exportServiceGroupColumns = this.serviceGroupCols.map(col => ({title: col.header, dataKey: col.field}));
    this.divisionService.allDivisionData().subscribe( async (result: CommonResponse) => {
      this.divisions = result.responseData;
    });
    // this.operatingEntityService.allData().subscribe( async (res:any) => {
    //   if(res){
    //     this.operatingEntities = res;
    //   }
    // });
  }
  fetchServiceGroup(){
    this.serviceGroupService.allServiceGroup().subscribe( async (serviceGroup: CommonResponse) =>{
      this.serviceGroups = serviceGroup.responseData;
      this.fetchingServiceGroup = false;
    }, error => {
      this.fetchingServiceGroup = false;
      this.connectionError();
    })
  }
  setOperationEntity(){
   if (this.selectedDivisionId) {
     this.operatingEntities = this.selectedDivisionId.operatingEntities;
   }
  }
  private connectionError() {
    this.messageService.add({severity:'error', summary: 'Failed',
      detail:'Connection Error, Please try again'});
  }
  createServiceGroup(){
    this.messageService.add({severity:'info', summary: 'Notice',
      detail:'Creating new Service Group Records'});
    const postData = {
      name: this.serviceGroupForm.get('name').value,
      description: this.serviceGroupForm.get('description').value,
      operatingEntityId: this.selectedOperatingEntityId.id,
      divisionId: this.selectedDivisionId.id,
    };
    this.serviceGroupService.postServiceGroup(postData).subscribe( async () =>{
      await this.messageService.add({severity:'success', summary: 'Completed',
        detail:'New Service Group Created'});
      await this.fetchServiceGroup();
      await this.serviceGroupForm.reset();
    }, error => {
      this.messageService.add({severity:'error', summary: 'Notice',
        detail:'Unable to create new Service Group at the moment'});
    });
  }

  deleteServiceGroup(serviceGroup) {
    this.confirmationService.confirm({
      message: 'Are you sure you want to delete ' + serviceGroup.name,
      accept: () => {
        this._deleteServiceGroup(serviceGroup);
      }
    });
  }
  _deleteServiceGroup(serviceGroup) {
    this.messageService.add({severity:'info', summary: 'Notice',
      detail:'Deleting service Group record'});
    this.serviceGroupService.deleteServiceGroup(serviceGroup.id).subscribe( async (result:any) => {
      await this.messageService.add({severity:'success', summary: 'Deleted',
        detail:'Service Group record removed'});
      await this.fetchServiceGroup();
    }, error => {
      this.connectionError()
    });
  }
  editServiceGroup(serviceGroups) {
    this.editingServiceGroup = true;
    this.serviceGroupForm.addControl('serviceGroupId',  new FormControl({value: '', disabled: true}, Validators.required));
    this.edServiceGroup = serviceGroups;
    this.serviceGroupForm.patchValue({
      name: serviceGroups.name,
      description: serviceGroups.description,
      serviceGroupId: serviceGroups.id,
    });
    // this.serviceGroupFormIn.nativeElement.focus();
    // document.getElementById('formFields').focus();
    this.selectedDivisionId = this.divisions.find(division => division.id === serviceGroups.divisionId);
    this.setOperationEntity();
    const _operatingEntityId = serviceGroups.operatingEntity.id;
    this.selectedOperatingEntityId = this.operatingEntities.find(operatingEntity => operatingEntity.id === _operatingEntityId);
    this.formWrapper.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'start' });
  }

  updateServiceGroup() {
    // update the selected serviceGroup
    this.messageService.add({severity:'info', summary: 'Notice',
      detail:'Updating ServiceGroup details'});
    const id = this.edServiceGroup.id;
    const postData = {
      name: this.serviceGroupForm.get('name').value,
      divisionId: this.selectedDivisionId.id,
      description: this.serviceGroupForm.get('description').value,
      operatingEntityId: this.selectedOperatingEntityId.id,
    };
    this.serviceGroupService.updateServiceGroup(id, postData).subscribe( async (result: ServiceGroup) => {
      this.messageService.add({severity:'success', summary: 'Notice',
        detail:'ServiceGroup details Updated'});
      await this.fetchServiceGroup();
      await this.closeEditing();
    })
  }

  closeEditing() {
    this.editingServiceGroup = false;
    this.serviceGroupForm.reset();
    this.edServiceGroup = null;
  }
}
