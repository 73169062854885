import { Cluster, DeployedGuard, GMAZone, GuardDeployment } from "./employment";
import { Account, Branch, CustomerDivision, Location, User } from "./home";
import { JobApplication } from "./premployment";

export const PayrollFrequencyEnum = {
  Monthly: 1,
  Quarterly: 2,
  HalfYearly: 3,
  Yearly: 4,
};
Object.freeze(PayrollFrequencyEnum);

export const PayrollMonthEnum = {
  FirstMonth: 1,
  SecondMonth: 2,
  ThirdMonth: 3,
  FourthMonth: 4,
  FifthMonth: 5,
  SixthMonth: 6,
  SeventhMonth: 7,
  EighthMonth: 8,
  NinethMonth: 9,
  TenthMonth: 10,
  EleventhMonth: 11,
  TwelvethMonth: 12,
};
Object.freeze(PayrollMonthEnum);

export const DeductionTypeEnum = {
  EmployeeOnly: 1,
  EmployerOnly: 2,
  Both: 3,
};
Object.freeze(DeductionTypeEnum);

export const AccountTypeEnum = {
  Asset: 1,
  Liability: 2,
};
Object.freeze(AccountTypeEnum);

export const TaxBandRelationshipEnum = {
  OR: 1,
  AND: 2,
};
Object.freeze(AccountTypeEnum);

export const PayGradeOptionEnum = {
  PercentageOfGross: 1,
  DefaultValue: 2,
};
Object.freeze(PayGradeOptionEnum);

export const OperativeAbsenceReasonEnum = {
  Training: 1,
  Absendant: 2,
};
Object.freeze(OperativeAbsenceReasonEnum);

export const OperativeAbsenceApprovalStatusEnum = {
  Pending: 1,
  Approved: 2,
  Declined: 3,
};
Object.freeze(OperativeAbsenceApprovalStatusEnum);

export const PayrollRunTypeEnum = {
  TrialRun: 1,
  FinalRun: 2,
};
Object.freeze(PayrollRunTypeEnum);

export const PayrollRunStatusEnum = {
  NotStarted: 1,
  InProgress: 2,
  Completed: 3,
  Cancelled: 4,
};
Object.freeze(PayrollRunStatusEnum);

export const PayrollItemEnum = {
  TotalGross: 1,
  TotalDeductions: 2,
  NetPay: 3,
};
Object.freeze(PayrollItemEnum);

export const PayrollGroupTypeEnum = {
  Region: 1,
  Zone: 2,
  Cluster: 3,
  Location: 4,
};
Object.freeze(PayrollGroupTypeEnum);

export const PayrollCompareDurationEnum = {
  LastRun: 1,
  Last2Run: 2,
  Last3Run: 3,
};
Object.freeze(PayrollCompareDurationEnum);

export const PayrollApprovalStatusEnum = {
  Pending: 1,
  Approved: 2,
  Declined: 3,
};
Object.freeze(PayrollApprovalStatusEnum);

export const CalendarMonthEnum = {
  January: 1,
  February: 2,
  March: 3,
  April: 4,
  May: 5,
  June: 6,
  July: 7,
  August: 8,
  September: 9,
  October: 10,
  November: 11,
  December: 12,
};
Object.freeze(CalendarMonthEnum);

export interface CreateAllowance {
  caption: string;
  description: string;
  frequency: number;
  payMonth: number;
  expenseAccountId: number;
  isTaxable: boolean;
  isStatutory: boolean;
}

export interface UpdateAllowance {
  caption?: string;
  description?: string;
  frequency?: number;
  payMonth?: number;
  expenseAccountId?: number;
  isTaxable?: boolean;
  isStatutory?: boolean;
}

export interface Allowance {
  id: number;
  caption: string;
  description: string;
  frequency: number;
  payMonth: number;
  isTaxable: boolean;
  isStatutory: boolean;
  expenseAccountId?: number;
  expenseAccount?: Account;
  createdById: number;
  createdAt: Date;
}

export interface PayrollSetupAccounts {
  assetAccounts: Account[];
  liabilityAccounts: Account[];
  expenseAccounts: Account[];
}

export interface CreateDeductionVM {
  caption: string;
  description: string;
  frequency: number;
  deductionMonth: number;
  deductionType: number;
  accountType: number;
  accountId: number;
  isStatutory: boolean;
  isTaxRelief: boolean;
}

export interface UpdateDeductionVM {
  caption?: string;
  description?: string;
  frequency?: number;
  deductionMonth?: number;
  deductionType?: number;
  accountType?: number;
  accountId?: number;
  isStatutory?: boolean;
  isTaxRelief?: boolean;
}

export interface Deduction {
  id: number;
  caption: string;
  description: string;
  createdById: number;
  isStatutory: boolean;
  deductionType: number;
  account: Account;
  createdAt: Date;
  accountId: number;
  accountType: number;
  deductionMonth: number;
  frequency: number;
  isTaxRelief: boolean;
}

export interface CreatePayGradeVM {
  caption: string;
  description: string;
  defaultAnnualGross: number;
  payGradeNoOfDaysToApply: number;
  defaultValue?: number;
  allowances: AddPayGradeAllowanceVM[];
  deductions: AddPayGradeDeductionVM[];
  taxId: number;
  pensionConfig: CreatePaygradePensionConfigVM;
}

export interface CreatePaygradePensionConfigVM {
  isApplicable: boolean;
  creditAccountId: number;
  creditAccountNumber: string;
  debitAccountId: number;
  debitAccountNumber: string;
  pensionOpt?: number;
  flatFee?: number;
  applicableAllowancesIds?: number[];
  pensionPerc?: number;
  calculatedValue?: number;
}

export interface AddPayGradeAllowanceVM {
  allowanceId: number;
  grossPercentage: number;
  defaultValue: number;
}

export interface AddPayGradeDeductionVM {
  deductionId: number;
  option: number;
  flatFee: number;
  applicableAllowanceIds: number[];
  percentageApplicable: number;
  calculatedValue: number;
}

export interface UpdatePayGradeVM {
  caption?: string;
  description?: string;
  defaultAnnualGross?: number;
  payGradeNoOfDaysToApply?: number;
  defaultValue?: number;
  allowances?: UpdatePayGradeAllowanceVM[];
  deductions?: UpdatePayGradeDeductionVM[];
  taxId?: number;
  pensionConfig: CreatePaygradePensionConfigVM;
}

export interface UpdatePayGradeAllowanceVM {
  id: number;
  allowanceId: number;
  grossPercentage: number;
  defaultValue: number;
}

export interface UpdatePayGradeDeductionVM {
  id: number;
  deductionId: number;
  option: number;
  flatFee: number;
  applicableAllowanceIds: number[];
  percentageApplicable: number;
  calculatedValue: number;
}

export interface CreatePayGroupVM {
  caption: string;
  alias: string;
  description: string;
  trialCutOffDay: number;
  payrollCutOffDay: number;
  maxNoOfMemebers: number;
  minNoOfMemebers: number;
  imageUrl?: string;
}

export interface CreateTaxVM {
  caption: string;
  alias: string;
  description: string;
  taxReliefs: AddTaxReliefVM[];
  taxBands: AddTaxBandVM[];
  taxAccountId: number;
  taxAccountNumber: string;
}

export interface AddTaxReliefVM {
  nextValue: number;
  rate: number;
  sequence: number;
}

export interface AddTaxBandVM {
  caption: string;
  description: string;
  option: number;
  flatFigure: number;
  percentageToApply: number;
  relationship: number;
}

export interface UpdateTaxVM {
  caption?: string;
  alias?: string;
  description?: string;
  taxReliefs: UpdateTaxReliefVM[];
  taxBands: UpdateTaxBandVM[];
  taxAccountId?: number;
  taxAccountNumber?: string;
}

export interface UpdateTaxReliefVM {
  id: number;
  nextValue: number;
  rate: number;
  sequence: number;
}

export interface UpdateTaxBandVM {
  id: number;
  caption: string;
  description: string;
  option: number;
  flatFigure: number;
  percentageToApply: number;
  relationship: number;
}

export interface UpdatePayGroupVM {
  caption?: string;
  alias?: string;
  description?: string;
  trialCutOffDay?: number;
  payrollCutOffDay?: number;
  maxNoOfMemebers?: number;
  minNoOfMemebers?: number;
  imageUrl?: string;
}

export interface PayGrade {
  id: number;
  caption: string;
  description: string;
  createdById: number;
  tax: Tax;
  taxId: number;
  createdAt: Date;
  deductions: PayGradeDeduction[];
  defaultValue: number;
  noOfDaysToApply: number;
  defaultAnnualGross: number;
  allowances: PayGradeAllowance[];
  members: DeployedGuard[];
  pensionConfig: PayGradePensionConfig;
}

export interface PayGradePensionConfig {
  updatedBy?: User;
  updatedById?: number;
  createdBy: User;
  createdById: number;
  calculatedValue?: number;
  percentageApplicable?: number;
  applicableAllowanceIds: string;
  flatFee?: number;
  pensionPlanOption?: number;
  debitAccountNumber: string;
  debitAccount?: Account;
  debitAccountId?: number;
  creditAccountNumber: string;
  creditAccount?: Account;
  creditAccountId?: number;
  isApplicable: boolean;
  id: number;
  createdAt: Date;
  updatedAt: Date;
}

export interface Tax {
  id: number;
  caption: string;
  description: string;
  alias: string;
  taxReliefs: TaxRelief[];
  taxBands: TaxBand[];
  isDeleted: boolean;
  taxAccountId: number;
  taxAccountNumber: string;
  taxAccount: Account;
  createdById: number;
  createdAt: Date;
}

export interface PayGradeDeduction {
  id?: number;
  payGradeId?: number;
  payGrade?: PayGrade;
  deductionId?: number;
  deduction?: Deduction;
  payGradeOption?: number;
  flatFee?: number;
  applicableAllowances?: PayGradeDeductionApplicableAllowance[];
  percentageApplicable?: number;
  calculatedValue?: number;
  isDeleted?: boolean;
  createdById?: number;
  createdAt?: Date;
}

export interface PayGradeAllowance {
  id?: number;
  payGradeId?: number;
  payGrade?: PayGrade;
  allowanceId?: number;
  allowance?: Allowance;
  percentageOfGross?: number;
  defaultValue?: number;
  isDeleted?: boolean;
  createdById?: number;
  createdAt?: Date;
}

export interface TaxRelief {
  id?: number;
  taxId?: number;
  tax?: Tax;
  nextValue: number;
  appliedRate: number;
  sequence: number;
  isDeleted?: boolean;
  createdById?: number;
  createdAt?: Date;
}

export interface TaxBand {
  id?: number;
  taxId?: number;
  tax?: Tax;
  caption: string;
  description: string;
  flatFigure: number;
  percentageToApply: number;
  payGradeTaxBandOption: number;
  taxBandRelationship: number;
  isDeleted?: boolean;
  createdById?: number;
  createdAt?: Date;
}

export interface PayGradeDeductionApplicableAllowance {
  id?: number;
  payGradeDeductionId?: number;
  payGradeDeduction?: PayGradeDeduction;
  allowanceId?: number;
  allowance?: Allowance;
  isDeleted?: boolean;
  createdById?: number;
  createdAt?: Date;
}

export interface PayGroup {
  id: number;
  caption: string;
  description: string;
  alias: string;
  imageUrl: string;
  trialRunCutOffDay: number;
  payrollCutoffDay: number;
  maximumNoOfMembers: number;
  minimumNoOfMembers: number;
  members: DeployedGuard[];
  isDeleted: boolean;
  createdById: number;
  createdAt: Date;
}

export interface AssignMembersToPayGradeVM {
  payGradeId: number;
  memberIds: number[];
  locationId: number;
}

export interface AssignMembersToPayGroupVM {
  payGroupId: number;
  memberIds: number[];
  locationId: number;
}

export interface ComputeTaxRequestVM {
  taxId: number;
  annualGrossAmount: number;
  taxExemptDeductions: TaxExemptDeductions[];
}

export interface TaxExemptDeductions {
  deductionName: string;
  deductionId: number;
  option: number;
  flatFee: number;
  applicableAllowanceIds: number[];
  percentageApplicable: number;
  calculatedValue: number;
}

export interface TaxComputationResultVM {
  consolidatedSalary: number;
  consolidatedFlatFigure: number;
  consolidatedPercentage: number;
  consolidatedPercentageAmount: number;
  consolidatedHigherValue: number;
  consolidatedToAddPercentage: number;
  consolidatedToAddAmount: number;
  reliefItems: TaxComputationReliefItems[];
  totalRelief: number;
  taxableIncome: number;
  taxBands: TaxComputationBandItems[];
  minimumTaxAmount: number;
  actualAnnualTax: number;
  totalAnnualTax: number;
}

export interface TaxComputationReliefItems {
  label: string;
  amount: number;
}

export interface TaxComputationBandItems {
  count: number;
  amount: number;
  taxRateApplied: number;
  taxAmount: number;
}

export interface OperativeAbsenceReport {
  id: number;
  reportingOfficerId?: number;
  reportingOfficer?: User;
  isSentForApproval: boolean;
  dateSentForApproval?: Date;
  operativesAbsent: OperativesAbsent[];
  createdById: number;
  createdBy: User;
  createdAt: Date;
}

export interface OperativesAbsent {
  id: number;
  operativeAbsenceReportId?: number;
  operativeAbsenceReport?: OperativeAbsenceReport;
  operativeAbsenceApprovalStatus: number;
  deployedGuardId?: number;
  deployedGuard?: DeployedGuard;
  jobApplicationId?: number;
  jobApplication?: JobApplication;
  operativeAbsenceReason: number;
  comment: string;
  dateAbsent: Date;
  locationId?: number;
  location?: Location;
  createdById: number;
  createdBy: User;
  createdAt: Date;
}

export interface LogOperativeAbsenceReportVM {
  dateAbsent: Date;
  reason: number;
  comment: string;
  deployedGuardId: number;
  locationId: number;
}

export interface ActOnOPAbsenceReportVM {
  oPAbsenceReportIds: number[];
  statusToAssign: number;
}

export interface GetAbsenceReportStatisticsVM {
  pieChartsData: GetAbsenceReportStatisticsChartData[];
  barChartsData: GetAbsenceReportStatisticsChartData[];
}

export interface GetAbsenceReportStatisticsChartData {
  label: string;
  percentage: number;
  color: string;
}

export interface InitiatePayrollRunVM {
  payGroupId: number;
  runType: number;
  payPeriod: string;
  payrollPeriod: Date;
}

export interface GetPayrollRunsAnalyticsVM {
  opsEligibleForPayroll: number;
  opsNotEligibleForPayroll: number;
  last5PayrollRuns: PayrollRun[];
  payPeriods: PayrollPayPeriodsAvailableVM[];
  payGroups: PayGroup[];
}

export interface PayrollRun {
  id: number;
  payPeriodDate: Date;
  runType: number;
  payPeriod: string;
  runStatus: number;
  payGroupId?: number;
  payGroup?: PayGroup;
  percentage: number;
  createdById: number;
  createdAt: Date;
  isSentForApproval: boolean;
  dateSentForApproval?: Date;
  isFullyApproved: boolean;
  dateFullyApproved?: Date;
  isUsingBatchApproval?: boolean;
  isClosed: boolean;
  dateClosed?: Date;
  closedById?: number;
  isDeclined: boolean;
  approvals: PayrollApproval[];
  payrollRunOperations: PayrollRunOperation[];
}

export interface PayrollPayPeriodsAvailableVM {
  dateAppened: Date;
  label: string;
}

export interface SelectedPeriodRuns {
  payGroup: string;
  payGroupId: number;
  trialRunCutOffDay: string;
  finalRunCutOffDay: string;
}

export interface PayrollRunOperation {
  id: number;
  totalDeductionsFigure: number;
  totalAllowancesFigure: number;
  absenceDeductions: number;
  totalDaysAbsent: number;
  appliedTaxAmount: number;
  appliedTax?: Tax;
  appliedTaxId?: number;
  createdAt: Date;
  deductions: PayrollOperationDeduction[];
  netPayFigure: number;
  grossPayFigure: number;
  deployedGuard?: DeployedGuard;
  deployedGuardId?: number;
  payrollRun?: PayrollRun;
  payrollRunId?: number;
  allowances: PayrollOperationAllowance[];
  location?: string;
  totalDaysPaidFor: number;
  totalDaysAttachedToLocation: number;
  deployedGuardLocationCustomerName: string;
  deployedGuardLocationCustomer?: CustomerDivision;
  deployedGuardLocationCustomerId?: number;
  deployedGuardLocationName: string;
  deployedGuardLocation?: Location;
  deployedGuardLocationId?: number;
  approvals: PayrollOperationApproval[];
  taxes: PayrollOperationTax[];
}

export interface PayrollOperationTax {
  id: number;
  payrollRunOperationId?: number;
  taxName: string;
  amount: number;
  taxAccountId?: number;
  taxAccountNumber: string;
  createdAt: Date;
}

export interface PayrollOperationApproval {
  id: number;
  payrollRunOperationId: number;
  payrollApprovingOfficeId: number;
  payrollApprovingOfficeName: string;
  payrollApprovingOfficerId: number;
  payrollApprovingOfficerName: string;
  actedOnSequence: number;
  status: number;
  createdById: number;
  createdAt: Date;
}

export interface PayrollOperationAllowance {
  id: number;
  payrollRunOperationId?: number;
  payrollRunOperation?: PayrollRunOperation;
  allowanceId?: number;
  allowance?: Allowance;
  allowanceName: string;
  amount: number;
  createdAt: Date;
}

export interface PayrollOperationDeduction {
  id: number;
  payrollRunOperationId?: number;
  payrollRunOperation?: PayrollRunOperation;
  deductionId?: number;
  deduction?: Deduction;
  deductionName: string;
  amount: number;
  createdAt: Date;
}

export interface GetAllRunPeriodAndPaygroupVM {
  runPeriods: PayrollRun[];
  payGroups: PayGroup[];
}

export interface GetGeneralPayrunComaprisonResultVM {
  payrollRunId: number;
  payrollItem: number;
  payrollGroupType: number;
  payrollCompareDuration: number;
}

export interface GeneralPayrunComaprisonResultVM {
  comparisonDatas: GeneralPayrunComaprisonDataVM[];
  currentRunLabel: string;
  lastRunLabel: string;
  last2RunLabel: string;
  last3RunLabel: string;
  currentRunColor: string;
  lastRunColor: string;
  last2RunColor: string;
  last3RunColor: string;
}

export interface GeneralPayrunComaprisonDataVM {
  groupType: string;
  currentRunAmount: number;
  lastRunAmount?: number;
  last2RunAmount?: number;
  last3RunAmount?: number;
  diffAmount?: number;
}

export interface GetAllActualEntityUnderPayrollRunVM {
  locations: Location[];
  clusters: Cluster[];
  zones: GMAZone[];
  regions: Branch[];
  totalAllowances: number;
  totalDeductions: number;
  totalTaxes: number;
  totalNetPays: number;
  approvals: PayrollApproval[];
}

export interface SpecificPayrunComaprisonResultVM {
  allowances: GeneralPayrunComaprisonResultVM;
  deductions: GeneralPayrunComaprisonResultVM;
  taxes: GeneralPayrunComaprisonResultVM;
  netPay: GeneralPayrunComaprisonResultVM;
}

export interface GetSpecificOPPayrunComaprisonResultVM {
  payrollRunId: number;
  payrollGroupType: number;
  actualEntityId: number;
  payrollCompareDuration: number;
}

export interface PayrollApprovingOffice {
  id: number;
  name: string;
  sequence: number;
  officers: PayrollApprovingOfficer[];
  createdById: number;
  createdBy: User;
  isDeleted: boolean;
  createdAt: Date;
  isMyOffice: boolean;
}

export interface PayrollApprovingOfficer {
  id?: number;
  userId?: number;
  user?: User;
  addedById?: number;
  addedBy?: User;
  dateAdded?: Date;
  approvingOfficeId?: number;
  approvingOffice?: PayrollApprovingOffice;
  createdById?: number;
  createdBy?: User;
  isDeleted?: boolean;
  createdAt?: Date;
}

export interface GetAllPayrollApprovalItemsVM {
  notPicked: PayrollRun[];
  picked?: PayrollRun[];
  approvingOfficeId: number;
  approvingOfficerId: number;
  approvingOfficeSequence: number;
}

export interface PayrollApproval {
  id?: number;
  payrollRunId?: number;
  payrollRun?: PayrollRun;
  payrollApprovingOfficeId?: number;
  payrollApprovingOffice?: PayrollApprovingOffice;
  payrollApprovingOfficerId?: number;
  payrollApprovingOfficer?: PayrollApprovingOfficer;
  actedOnSequence?: number;
  status?: number;
  comment?: string;
  datePicked?: Date;
  createdById?: number;
  createdBy?: User;
}

export interface ActOnPayrollApprovalVM {
  payrollRunId: number;
  officerId: number;
  actionStatus: number;
  comment?: string;
}

export interface PayrollFinancialPostingVM {
  postingDesc: string;
  postingAmount: number;
  postingData: PayrollFinancialPostingData[];
  voucherInfo: string;
  voucherId: number;
  officePostingTo: string;
  officeId: number;
  branchPostingTo: string;
  branchId: number;
}

export interface PayrollFinancialPostingData {
  cRPostingId?: number;
  dRPostingId?: number;
  subPostingDesc: string;
  subPostingCRAmount: number;
  subPostingDRAmount: number;
  accountNoPostingTo: string;
  accountId: number;
  voucherInfo: string;
  voucherId: number;
  officePostingTo: string;
  officeId: number;
  branchPostingTo: string;
  branchId: number;
}

export interface GetFilteredPayrunOperationsVM {
  payrollRunId: number;
  regionId: number;
  zoneId: number;
  clusterId: number;
  locationId: number;
}

export interface FilteredPayrollPensionAcctVM {
  crAccounts: Account[];
  drAccounts: Account[];
}

export interface GetPayrollRunOperationsWithReportViewVM {
  payrollOperations: PayrollRunOperation[];
  guardDeployments: GuardDeployment[];
}

export interface GetPayrollVsContractRequestDitsVM {
  runTypes: KeyValuePairTempVM[];
  runPeriods: KeyValuePairTempVM[];
  payGroups: PayGroup[];
  clients: KeyValuePairTempVM[];
}

export interface KeyValuePairTempVM {
  key: number;
  value: string;
  keyDate: Date;
}

export interface GetPayrollVsContractReportVM {
  runTypeId: number;
  runPeriodId: number;
  runPeriodDate: Date;
  payGroupId: number;
  clientId: number;
}

export interface PayrollVsContractReportVM {
  imageUrl: string;
  location: string;
  clientName: string;
  name: string;
  operativeID: string;
  grossPay: number;
  netPay: number;
  contract: string;
  frequency: string;
  unitPrice: number;
  compareAmount: number;
  grossPerc: number;
  netPerc: number;
  payGrade: string;
  runType: string;
  payGroup: string;
}

export interface LocationPAYEReportVM {
  id: number;
  state: string;
  payeAmount: number;
  report: LocationPAYEReport[];
}

export interface LocationPAYEReport {
  id: number;
  clientName: string;
  locationName: string;
  clusterName: string;
  zoneName: string;
  operativeName: string;
  operativeID: string;
  payeAmount: number;
}

export interface GetPayeStateReportsVM {
  runTypeId: number;
  runPeriodId: number;
  runPeriodDate: Date;
  payGroupId: number;
  clientId: number;
}

export interface PaidVsQtyReportMaster {
  id: number;
  clientName: string;
  locationName: string;
  qtyPaidFor: number;
  qtyGiven: number;
  report: any[];
}

export interface PaidVsQtyReport {
  id: number;
  operativeFirstname: string;
  operativeLastname: string;
  operativeID: string;
  activeContractService: string;
  netPay: number;
}

export interface GetPaidVsQtyBilledReportsVM {
  runTypeId: number;
  runPeriodId: number;
  runPeriodDate: Date;
  payGroupId: number;
  clientId: number;
}

export interface CreateSpecialSettingVM {
  caption: string;
  description: string;
  accountId: number;
  accountNumber: string;
  isSpecialAllowance: boolean;
  isTaxable?: boolean;
  isUsingPayrollTaxComputation?: boolean;
  taxTypeOption?: number;
  taxTypeValue?: number;
  taxAccountId?: number;
  taxAccountNumber?: string;
}

export interface UpdateSpecialSettingVM {
  caption?: string;
  description?: string;
  accountId?: number;
  accountNumber?: string;
  isSpecialAllowance: boolean;
  isTaxable?: boolean;
  isUsingPayrollTaxComputation?: boolean;
  taxTypeOption?: number;
  taxTypeValue?: number;
  taxAccountId?: number;
  taxAccountNumber?: string;
}

export interface SpecialAllowance {
  id: number;
  caption: string;
  description: string;
  expenseAccountId?: number;
  expenseAccount?: Account;
  expenseAccountNumber?: number;
  taxAccountNumber: string;
  taxAccountId?: number;
  taxTypeValue?: number;
  taxTypeOption?: number;
  isTaxable: boolean;
  isDeleted: boolean;
  createdById: number;
  createdAt: Date;
}

export interface SpecialDeduction {
  id: number;
  caption: string;
  description: string;
  assetAccountId?: number;
  assetAccount?: Account;
  assetAccountNumber?: number;
  isDeleted: boolean;
  createdById: number;
  createdAt: Date;
}

export interface CreateOvertimeBatchVM {
  clientId: number;
  clientName: string;
  applicableMonth: number;
  noOfDays: number;
  applicableAllowanceId: number;
  applicableAllowanceName: string;
  applicableYear: Date;
  applicableRate: number;
  operatives: KeyValuePairTempVM[];
}

export interface CreateSpecialDeductionBatchVM {
  clientId: number;
  clientName: string;
  applicableMonth: number;
  noOfDays: number;
  applicableDeductionId: number;
  applicableDeductionName: string;
  applicableYear: Date;
  applicableRate: number;
  operatives: KeyValuePairTempVM[];
}

export interface GetOvertimeRequestDitsVM {
  clients: CustomerDivision[];
  applicableAllowances: SpecialAllowance[];
  applicableYears: KeyValuePairTempVM[];
}

export interface GetSpecialDeductionRequestDitsVM {
  clients: CustomerDivision[];
  applicableDeductions: SpecialDeduction[];
  applicableYears: KeyValuePairTempVM[];
}

export interface SpecialAllowanceRequest {
  createdBy: User;
  createdById: number;
  isDeleted: boolean;
  comment: string;
  dateApproved?: Date;
  isApproved: boolean;
  isBulkEntry: boolean;
  totalValue: number;
  applicableRate: number;
  applicableYearDateTime: Date;
  applicableYear: string;
  operativeName: string;
  createdAt: Date;
  operative?: DeployedGuard;
  applicableAllowanceName: string;
  applicableAllowance?: SpecialAllowance;
  applicableAllowanceId?: number;
  noOfDays: number;
  applicableMonthDateTime: Date;
  applicableMonth: number;
  clientName: string;
  client?: CustomerDivision;
  clientId?: number;
  batch?: SpecialAllowanceRequestBatch;
  batchId?: number;
  id: number;
  operativeId?: number;
}

export interface SpecialAllowanceRequestBatch {
  id: number;
  batchCode: string;
  requests: SpecialAllowanceRequest[];
  isSentForApproval: boolean;
  dateSentForApproval?: Date;
  createdById: number;
  createdBy: User;
  createdAt: Date;
}

export interface SpecialAllowanceRequestBatch {
  id: number;
  batchCode: string;
  requests: SpecialAllowanceRequest[];
  isSentForApproval: boolean;
  dateSentForApproval?: Date;
  createdById: number;
  createdBy: User;
  createdAt: Date;
}

export interface SpecialDeductionRequestBatch {
  id: number;
  batchCode: string;
  requests: SpecialDeductionRequest[];
  isSentForApproval: boolean;
  dateSentForApproval?: Date;
  createdById: number;
  createdBy: User;
  createdAt: Date;
}

export interface SpecialDeductionRequest {
  createdBy: User;
  createdById: number;
  isDeleted: boolean;
  comment: string;
  dateApproved?: Date;
  isApproved: boolean;
  isBulkEntry: boolean;
  totalValue: number;
  applicableRate: number;
  applicableYearDateTime: Date;
  applicableYear: string;
  operativeName: string;
  createdAt: Date;
  operative?: DeployedGuard;
  applicableDeductionName: string;
  applicableDeduction?: SpecialDeduction;
  applicableDeductionId?: number;
  noOfDays: number;
  applicableMonthDateTime: Date;
  applicableMonth: number;
  clientName: string;
  client?: CustomerDivision;
  clientId?: number;
  batch?: SpecialAllowanceRequestBatch;
  batchId?: number;
  id: number;
  operativeId?: number;
}

export interface ApproveSpecialRequestsVM {
  isAllowanceOperation: boolean;
  batchId: number;
  requestIds: number[];
}

export interface ApprovePayrollOperationsVM {
  payrunId: number;
  requestIds: number[];
}
