import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { MessageService, ConfirmationService } from "primeng/api";
import { BreadcrumbService } from "src/app/breadcrumb.service";
import {
  CreateExpenseApprovalSetupVM,
  ExpenseApprovalSetup,
  ExpenseApprovingOffice,
  MoveExpenseApprovalOfficeSequenceVM,
  UpdateExpenseApprovalSetupVM,
} from "src/app/interfaces/finance-expense";
import { ServiceCategory, User } from "src/app/interfaces/home";
import { FinanceExpenseService } from "src/app/services/finance-expense.service";
import { ServiceCategoryService } from "src/app/services/service-category.service";
import { UserService } from "src/app/services/user.service";

@Component({
  selector: "app-expense-approval-setup",
  templateUrl: "./expense-approval-setup.component.html",
  styleUrls: ["./expense-approval-setup.component.scss"],
})
export class ExpenseApprovalSetupComponent implements OnInit {
  @ViewChild("formWrapper") public formWrapper: ElementRef;
  approvalSetupForm: FormGroup;
  allServiceCategories: ServiceCategory[];
  selectedServiceCategories: ServiceCategory[] = [];
  allUsers: User[];
  theUser: User;
  addedApprovingOfficers: {
    userId: number;
    user: User;
  }[] = [];
  selectedApprovingOfficer: {
    userId: number;
    user: User;
  }[] = [];
  fetchingApprovalSetups: boolean = true;
  allApprovalSetups: {
    serviceCategory: string;
    setups: ExpenseApprovalSetup[];
  }[];
  selectedApprovalSetups: {
    serviceCategory: string;
    setups: ExpenseApprovalSetup[];
  }[] = [];
  approvalSetupCols: any[];
  setupDetailsCols: any[];
  isEditing: boolean;
  officeEditing: ExpenseApprovingOffice;
  allApprovalExpenseSetups: ExpenseApprovalSetup[] = [];

  constructor(
    fb: FormBuilder,
    public financeExpenseService: FinanceExpenseService,
    public userService: UserService,
    public serviceCategoryService: ServiceCategoryService,
    public messageService: MessageService,
    private breadcrumbService: BreadcrumbService,
    public confirmationService: ConfirmationService
  ) {
    this.approvalSetupForm = fb.group({
      ServiceCateg: ["", Validators.required],
      OfficeName: ["", Validators.required],
      Officer: [""],
    });
  }

  ngOnInit(): void {
    this.breadcrumbService.setItems([
      {
        label: "Finance",
        routerLink: ["/home/finance"],
      },
      {
        label: "Expense Tool",
        routerLink: ["/home/finance/expense"],
      },
      {
        label: "Approval Setup",
        routerLink: ["/home/finance/expense/approval-setup"],
      },
    ]);

    this.approvalSetupCols = [
      { field: "serviceCategory", header: "Expense Category" },
    ];

    this.setupDetailsCols = [
      { field: "officer", header: "Offcer " },
      { field: "sequence", header: "Sequence" },
    ];

    this.GetAllServiceCategories();
  }

  async GetAllServiceCategories() {
    this.serviceCategoryService.GetAllServiceCategories().subscribe(
      async (data) => {
        if (data.responseCode == "00") {
          this.allServiceCategories = data.responseData;
          this.GetAllUsers();
        } else {
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail: data.responseMsg,
          });
        }
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all service categories at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  async GetAllUsers() {
    this.userService.allUser().subscribe(
      async (r) => {
        if (r.responseCode == "00") {
          var data = r.responseData ?? [];
          this.allUsers = [];
          data.forEach((user) => {
            user.fullName = user.lastName + " " + user.firstName;
            this.allUsers.push(user);
          });
          this.GetAllExpenseApprovalOffices();
        } else {
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail: r.responseMsg,
          });
        }
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all users at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  async GetAllExpenseApprovalOffices() {
    this.fetchingApprovalSetups = true;
    this.financeExpenseService.GetAllExpenseApprovalSetups().subscribe(
      async (data) => {
        if (data.responseCode == "00") {
          this.allApprovalExpenseSetups = data.responseData;
          let groupedByCateg = [
            ...new Set(data.responseData.map((req) => req.serviceCategoryId)),
          ];

          this.allApprovalSetups = [];
          groupedByCateg.forEach((categId) => {
            let categModel = this.allServiceCategories.find(
              (x) => x.id == categId
            );
            if (categModel) {
              let approvalData: {
                serviceCategory: string;
                setups: ExpenseApprovalSetup[];
              } = {
                serviceCategory: categModel.name,
                setups: data.responseData.filter(
                  (x) => x.serviceCategoryId == categId
                ),
              };

              this.allApprovalSetups.push(approvalData);
            }
          });
          this.fetchingApprovalSetups = false;
        } else {
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail: data.responseMsg,
          });
          this.fetchingApprovalSetups = false;
        }
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all expense approval setups at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
        this.fetchingApprovalSetups = false;
      }
    );
  }

  CreateApprovalSetup() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Creating Expense Approval Setup...",
    });

    const postData: CreateExpenseApprovalSetupVM = {
      serviceCategoriesIds: this.selectedServiceCategories.map((x) => x.id),
      officeName: this.approvalSetupForm.get("OfficeName").value,
      officersIds: this.addedApprovingOfficers.map((x) => x.userId),
    };

    this.financeExpenseService.CreateExpenseApprovalSetup(postData).subscribe(
      async (data) => {
        if (data.responseCode != "00") {
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail: data.responseMsg,
          });
          return;
        }

        this.messageService.add({
          severity: "success",
          summary: "Completed",
          detail: "Approval Setup Created Successfully...",
        });
        this.CloseEditingApprovalSetup();
        this.GetAllExpenseApprovalOffices();
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to create expense approval setups at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  AddApprovingOfficerToList() {
    if (this.theUser == null) {
      this.messageService.add({
        severity: "error",
        summary: "Failed",
        detail: "You must select the officer to add",
      });
      return;
    }

    let alreadyAdded = false;
    this.addedApprovingOfficers.forEach((item) => {
      if (item.userId == this.theUser.id) {
        alreadyAdded = true;
      }
    });

    if (alreadyAdded) {
      this.messageService.add({
        severity: "error",
        summary: "Failed",
        detail: "Officer with the same info has already been added to list.",
      });
      return;
    }

    this.addedApprovingOfficers.push({
      userId: this.theUser.id,
      user: this.theUser,
    });

    this.theUser = null;
    this.messageService.add({
      severity: "success",
      summary: "Added",
      detail: "Approving Officer added successfully",
    });
  }

  RemoveOfficer(item: any) {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Removing Approving Officer...",
    });

    const index = this.addedApprovingOfficers.indexOf(item);
    if (index > -1) {
      this.addedApprovingOfficers.splice(index, 1);
    }

    this.messageService.add({
      severity: "success",
      summary: "Removed",
      detail: "Approving Officer Removed successfully",
    });
  }

  DeleteApprovalOffice(item: ExpenseApprovalSetup) {
    this.confirmationService.confirm({
      message: "Are you sure you want to remove expense approval setup?",
      accept: () => {
        this.messageService.add({
          severity: "info",
          summary: "Notice",
          detail: "Removing expense approval setup...",
        });

        this.financeExpenseService
          .DeleteExpenseApprovalSetup(item.id)
          .subscribe(
            async (data) => {
              if (data.responseCode != "00") {
                this.messageService.add({
                  severity: "error",
                  summary: "Notice",
                  detail: data.responseMsg,
                });
                return;
              }

              this.messageService.add({
                severity: "success",
                summary: "Completed",
                detail: "Approval Office Removed Successfully...",
              });
              this.GetAllExpenseApprovalOffices();
            },
            (error) => {
              console.log("Error: " + JSON.stringify(error));
              this.messageService.add({
                severity: "error",
                summary: "Notice",
                detail:
                  "Unable to remove expense approval setup at the moment.. Reason: [" +
                  error
                    ? error.error.message
                    : "request failed - permission" + "]",
              });
            }
          );
      },
    });
  }

  MoveOfficeSequence(upward: boolean, item: ExpenseApprovalSetup) {
    this.confirmationService.confirm({
      message:
        "Are you sure you want to change expense approval setup sequence?",
      accept: () => {
        this.messageService.add({
          severity: "info",
          summary: "Notice",
          detail: "Updating expense apprval setup sequence...",
        });

        const postData: MoveExpenseApprovalOfficeSequenceVM = {
          isUpward: upward,
          currentSequence: item.sequence,
          serviceCategoryId: item.serviceCategoryId,
        };

        this.financeExpenseService
          .MoveExpenseApprovalOfficeSequence(item.id, postData)
          .subscribe(
            async (data) => {
              if (data.responseCode != "00") {
                this.messageService.add({
                  severity: "error",
                  summary: "Notice",
                  detail: data.responseMsg,
                });
                return;
              }

              this.messageService.add({
                severity: "success",
                summary: "Completed",
                detail: "Update Successfull!...",
              });
              this.GetAllExpenseApprovalOffices();
            },
            (error) => {
              console.log("Error: " + JSON.stringify(error));
              this.messageService.add({
                severity: "error",
                summary: "Notice",
                detail:
                  "Unable to update expense approval setup sequence at the moment.. Reason: [" +
                  (error
                    ? error.error.message
                    : "request failed - permission") +
                  "]",
              });
            }
          );
      },
    });
  }

  GetOfficerFullName(profileId: number): string {
    let profile = this.allUsers.find((x) => x.id == profileId);
    if (profile)
      return (
        profile.lastName + " " + profile.firstName + " " + profile.otherName
      );

    return "N/A";
  }

  EditApprovalOffice(item: ExpenseApprovalSetup) {
    this.isEditing = true;
    this.approvalSetupForm.patchValue({
      OfficeName: item.expenseApprovingOffice.officeName,
    });
    let allCategOffices = this.allApprovalExpenseSetups.filter(
      (x) => x.expenseApprovingOfficeId == item.expenseApprovingOfficeId
    );
    let otherCategsIds = allCategOffices.map((x) => x.serviceCategoryId);
    otherCategsIds.push(item.serviceCategoryId);
    this.selectedServiceCategories = this.allServiceCategories.filter(
      (x) => otherCategsIds.find((y) => y == x.id) != null
    );
    this.officeEditing = item.expenseApprovingOffice;
    this.addedApprovingOfficers = [];
    item.expenseApprovingOffice.officers
      .filter((x) => x.isDeleted == false)
      .forEach((x) => {
        this.addedApprovingOfficers.push({
          userId: x.officerProfileId,
          user: this.allUsers.find((y) => y.id == x.officerProfileId),
        });
      });

    this.formWrapper.nativeElement.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "start",
    });
  }

  CloseEditingApprovalSetup() {
    this.approvalSetupForm.reset();
    this.addedApprovingOfficers = [];
    this.selectedServiceCategories = [];
    this.selectedApprovingOfficer = [];
    this.isEditing = false;
  }

  UpdateApprovalSetup() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Updating Approval Office...",
    });

    const id = this.officeEditing.id;
    const postData: UpdateExpenseApprovalSetupVM = {
      officersIds: this.addedApprovingOfficers.map((x) => x.userId),
    };

    this.financeExpenseService
      .UpdateExpenseApprovalSetup(id, postData)
      .subscribe(
        async (data) => {
          if (data.responseCode != "00") {
            this.messageService.add({
              severity: "error",
              summary: "Notice",
              detail: data.responseMsg,
            });
            return;
          }

          this.messageService.add({
            severity: "success",
            summary: "Completed",
            detail: "Expense Approval Office Updated Successfully!",
          });

          this.CloseEditingApprovalSetup();
          this.GetAllExpenseApprovalOffices();
        },
        (error) => {
          console.log("Error: " + JSON.stringify(error));
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail:
              "Unable to update expense approval office at the moment.. Reason: [" +
              (error ? error.error.message : "request failed - permission") +
              "]",
          });
        }
      );
  }
}
