import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable()
export class EngagementActivitiesService {

  constructor(public http:HttpClient) { }

  getCustomerDivisionList(){
    return this.http.get(
      environment.baseUrl + "Division"
    )
  }

  getMeetingsById(suspectId){
    return this.http.get(
      environment.baseUrl + "contact/GetAllMeetings/"+ suspectId
    )
  }
  
  getCustomerDesignationList(){
    return this.http.get(
      environment.baseUrl + "Designation"
    )
  }


  getTodoListByGoalId(goalId){
    return this.http.get(
      environment.baseUrl + "contact/GetAllTodo/"+goalId
    )
  }
  
  createContact(body:any){
    return this.http.post(
      environment.baseUrl + "contact/CreateNewContact",body
      )
  }

  createTodo(goalId:any,body:any){
    return this.http.post(
      environment.baseUrl + "contact/CreateNewTodo/"+goalId,body
      )
  }

  createGoal(suspectId:any,body:any){
    return this.http.post(
      environment.baseUrl + "contact/CreateNewGoal/"+suspectId,body
      )
  }

  attachContactToSuspect(body:any){
    return this.http.post(
      environment.baseUrl + "contact/AttachContactToSuspect",body
      )
  }
  
  getContactList(){
    return this.http.get(
      environment.baseUrl + "contact/GetAllContacts"
    )
  }

  getGoalsBySuspectId(suspectId:any){
    return this.http.get(
      environment.baseUrl + "contact/GetAllGoal/"+suspectId
    )
  }


  
  disableContact(contactId:number){
    return this.http.delete(
      environment.baseUrl + "contact/DisableContact/" + contactId
    )
  }

  disableMeetingContact(meetingId:number,contactId:number){
    return this.http.delete(
      environment.baseUrl + "contact/DisableMeetingContact/" + meetingId + "/"+ contactId
    )
  }

  disableMeetingStaff(meetingId:number,staffId:number){
    return this.http.delete(
      environment.baseUrl + "contact/DisableMeetingStaff/" + meetingId + "/"+ staffId
    )
  }

  disableMeeting(meetingId:number){
    return this.http.delete(
      environment.baseUrl + "contact/DisableMeeting/" + meetingId
    )
  }
  
  
  updateContacts(contactId:number,body){
    return this.http.put(
      environment.baseUrl + "contact/UpdateContact/" + contactId,body
    )
  }

  updateTodo(todoId:number,body){
    return this.http.put(
      environment.baseUrl + "contact/UpdateTodo/" + todoId,body
    )
  }

  AddmoreMeetingStaff(meetingId:any,body:any){
    return this.http.post(
      environment.baseUrl + "contact/AddMorStaff/"+ meetingId,body
    )
  }

  AddmoreMeetingContact(meetingId:any,body:any){
    return this.http.post(
      environment.baseUrl + "contact/AddMoreContact/"+ meetingId,body
    )
  }

  updateMeeting(meetingId:number,body){
    return this.http.put(
      environment.baseUrl + "contact/UpdateMeeting/" + meetingId,body
    )
  }

  updateGoal(goalId:number,body){
    return this.http.put(
      environment.baseUrl + "contact/UpdateGoal/" + goalId,body
    )
  }

  changeStatus(goalId:number){
    var body;
    return this.http.put(
      environment.baseUrl + "contact/changeTodoStatus/" + goalId,body
    )
  }

  disableGoal(goalId:number){
    return this.http.delete(
      environment.baseUrl + "contact/DisableGoal/" + goalId
    )
  }

  disableTodo(todoId:number){
    return this.http.delete(
      environment.baseUrl + "contact/DisableTodo/" + todoId
    )
  }

  getAllContactsAttachedToSuspects(suspectId:number){
    return this.http.get(
      environment.baseUrl + "contact/GetContactsAttachedToSuspects/" + suspectId
    )
  }

  getContactsAttachedToSuspectById(suspectId:number){
    return this.http.get(
      environment.baseUrl + "contact/GetAllContactsBySuspect/" + suspectId
    )
  }


  saveMeeting(body:any){
    return this.http.post(
      environment.baseUrl + "contact/CreateMeeting",body
      )
  }
 

  detachContact(suspectId:number,contactId:number){
    var body;
    return this.http.put(
      environment.baseUrl + "contact/DetatchContact/" + suspectId + '/'+ contactId,body
    )
  }

  getSuspectById(suspectId: any) {
    return this.http.get(environment.baseUrl + "Suspect/" + suspectId);
  }

  postGoogleEvent(body:any){
    return this.http.post(
      environment.baseUrl + "ProjectManagement/CreateEvent",body
      )
  }

  getAllGoogleEvents() {
    return this.http.get(environment.baseUrl + "ProjectManagement/ListEvent");
  }

  disableGoogleEvent(eventId:number){
    return this.http.delete(
      environment.baseUrl + "ProjectManagement/DeleteEvent/" + eventId
    )
  }
}
