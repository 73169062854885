import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {CommonResponse, MeansOfIdentification} from '../../../interfaces/home';
import {ConfirmationService, MessageService} from 'primeng/api';
import {MeanOfIdService} from '../../../services/mean-of-id.service';
import { BreadcrumbService } from 'src/app/breadcrumb.service';

@Component({
  selector: 'app-mean-of-id',
  templateUrl: './mean-of-id.component.html',
  styleUrls: ['./mean-of-id.component.scss'],
  providers: [ConfirmationService, MessageService]
})
export class MeanOfIdComponent implements OnInit {
  @ViewChild('formWrapper') public formWrapper:ElementRef;
  fetchingMeansOfIdentification = true;
  meansOfIdForm: FormGroup;
  meansOfIdentifications: MeansOfIdentification[];
  selectedMeansOfIdentification: MeansOfIdentification;
  editingMeansOfIdentification= false;
  public meansOfIdentificationCols: any[];
  public exportMeansOfIdentificationColumns: any[];
  private edMeansOfIdentification: MeansOfIdentification;
  constructor(
      private formBuilder: FormBuilder,
      private meansOfIdentificationService: MeanOfIdService,
      public messageService: MessageService,
      public confirmationService: ConfirmationService,
      private breadcrumbService: BreadcrumbService
  ) { }

  ngOnInit(): void {

    this.breadcrumbService.setItems([
      { label: 'Qualification', routerLink: ['home/qualification'] },
      { label: 'Means of Identification', routerLink: ['home/qualification/means-of-identification'] },
    ]);


    this.fetchMeansOfIdentification();
    this.meansOfIdForm = this.formBuilder.group({
      description: ['', Validators.required],
      caption: ['', [Validators.required]],
    });
    this.meansOfIdentificationCols = [
      { field: 'caption', header: 'Caption' },
      { field: 'description', header: 'Description' },
    ];
    this.exportMeansOfIdentificationColumns = this.meansOfIdentificationCols.map(col => ({title: col.header, dataKey: col.field}));
  }
  fetchMeansOfIdentification(){
    this.meansOfIdentificationService.allMeansOfId().subscribe( async (meansOfIdentification: CommonResponse) =>{
      this.meansOfIdentifications = meansOfIdentification.responseData;
      this.fetchingMeansOfIdentification = false;
    }, error => {
      this.fetchingMeansOfIdentification = false;
      this.connectionError();
    })
  }
  private connectionError() {
    this.messageService.add({severity:'error', summary: 'Failed',
      detail:'Connection Error, Please try again'});
  }
  createMeansOfIdentification(){
    this.messageService.add({severity:'info', summary: 'Notice',
      detail:'Creating new Means Of Identification Records'});
    const postData = {
      description: this.meansOfIdForm.get('description').value,
      caption: this.meansOfIdForm.get('caption').value,
    };
    this.meansOfIdentificationService.postMeansOfIdentification(postData).subscribe( async () =>{
      await this.messageService.add({severity:'success', summary: 'Completed',
        detail:'New Means Of Identification Created'});
      await this.fetchMeansOfIdentification();
      await this.meansOfIdForm.reset();
    }, error => {
      this.messageService.add({severity:'error', summary: 'Notice',
        detail:'Unable to create new Means Of Identification at the moment'});
    });
  }

  deleteMeansOfIdentification(meansOfIdentification) {
    this.confirmationService.confirm({
      message: 'Are you sure you want to drop ' + meansOfIdentification.caption,
      accept: () => {
        this._deleteMeansOfIdentification(meansOfIdentification);
      }
    });
  }
  _deleteMeansOfIdentification(meansOfIdentification) {
    this.messageService.add({severity:'info', summary: 'Notice',
      detail:'Dropping means Of Identification record'});
    this.meansOfIdentificationService.deleteMeansOfIdentification(meansOfIdentification.id).subscribe( async (result:any) => {
      await this.messageService.add({severity:'success', summary: 'Dropped',
        detail:'Means Of Identification record dropped'});
      await this.fetchMeansOfIdentification();
    }, error => {
      this.connectionError()
    });
  }
  editMeansOfIdentification(meansOfIdentifications) {
    this.editingMeansOfIdentification = true;
    this.meansOfIdForm.addControl('meansOfIdentificationId',  new FormControl({value: '', disabled: true}, Validators.required));
    this.edMeansOfIdentification = meansOfIdentifications;
    this.meansOfIdForm.patchValue({
      description: meansOfIdentifications.description,
      meansOfIdentificationId: meansOfIdentifications.id,
    });
    // this.meansOfIdFormIn.nativeElement.focus();
    // document.getElementById('formFields').focus();
    // this.selectedHeadId = this.users.find(user => user.id === meansOfIdentifications.head.id);
    this.formWrapper.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'start' });
  }

  updateMeansOfIdentification() {
    // update the selected meansOfIdentification
    this.messageService.add({severity:'info', summary: 'Notice',
      detail:'Updating Means Of Identification details'});
    const id = this.edMeansOfIdentification.id;
    const postData = {
      caption: this.meansOfIdForm.get('caption').value,
      description: this.meansOfIdForm.get('description').value,
    };
    this.meansOfIdentificationService.updateMeansOfIdentification(id, postData).subscribe( async (result: MeansOfIdentification) => {
      this.messageService.add({severity:'success', summary: 'Notice',
        detail:'Means Of Identification details Updated'});
      await this.fetchMeansOfIdentification();
      await this.closeEditing();
    })
  }

  closeEditing() {
    this.editingMeansOfIdentification = false;
    this.meansOfIdForm.reset();
    this.edMeansOfIdentification = null;
  }
}
