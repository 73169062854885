import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {LeadType, LeadOrigin, CommonResponse} from '../../../interfaces/home';
import {ConfirmationService, MessageService} from 'primeng/api';
import {LeadOriginService} from '../../../services/lead-origin.service';
import {LeadTypeService} from '../../../services/lead-type.service';
import { BreadcrumbService } from 'src/app/breadcrumb.service';

@Component({
  selector: 'app-lead-origin',
  templateUrl: './lead-origin.component.html',
  styleUrls: ['./lead-origin.component.scss'],
  providers: [ConfirmationService, MessageService]
})
export class LeadOriginComponent implements OnInit {
  @ViewChild('formWrapper') public formWrapper:ElementRef;
  fetchingLeadOrigin = true;
  leadOriginForm: FormGroup;
  leadOrigins: LeadOrigin[];
  selectedLeadOrigin: LeadOrigin;
  selectedLeadType: LeadType = null;
  leadtypes: LeadType[];
  editingLeadOrigin= false;
  public leadOriginCols: any[];
  public exportLeadOriginColumns: any[];
  private edLeadOrigin: LeadOrigin;
  selectedLeadTypeId: LeadType = null;
  constructor(
      private formBuilder: FormBuilder,
      private leadOriginService: LeadOriginService,
      public messageService: MessageService,
      public confirmationService: ConfirmationService,
      private leadTypeService: LeadTypeService,
      private breadcrumbService: BreadcrumbService
  ) { }

  ngOnInit(): void {

    this.breadcrumbService.setItems([
      { label: 'Qualification', routerLink: ['home/qualification'] },
      { label: 'Lead Origin', routerLink: ['home/qualification/lead-origin'] },
    ]);

    this.fetchLeadOrigin();
    this.fetchLeadType();
    this.leadOriginForm = this.formBuilder.group({
      description: ['', Validators.required],
      caption: ['', [Validators.required]],
      leadTypeId: [null, Validators.required],
    });
    this.leadOriginCols = [
      { field: 'caption', header: 'Caption' },
      { field: 'description', header: 'Description' },
      { field: 'leadType', header: 'Lead Type' },
    ];
    this.exportLeadOriginColumns = this.leadOriginCols.map(col => ({title: col.header, dataKey: col.field}));
  }
  async fetchLeadType(){
    await this.leadTypeService.allLeadTypeData().subscribe( async (result: CommonResponse) => {
      this.leadtypes = result.responseData;
    }, error => {})
  }
  fetchLeadOrigin(){
    this.leadOriginService.allLeadOrigin().subscribe( async (leadOrigin: CommonResponse) =>{
      this.leadOrigins = leadOrigin.responseData;
      this.fetchingLeadOrigin = false;
    }, error => {
      this.fetchingLeadOrigin = false;
      this.connectionError();
    })
  }
  private connectionError() {
    this.messageService.add({severity:'error', summary: 'Failed',
      detail:'Connection Error, Please try again'});
  }
  createLeadOrigin(){
    this.messageService.add({severity:'info', summary: 'Notice',
      detail:'Creating new Lead Origin Records'});
    const postData = {
      description: this.leadOriginForm.get('description').value,
      caption: this.leadOriginForm.get('caption').value,
      leadTypeId: this.selectedLeadTypeId.id,
    };
    this.leadOriginService.postLeadOrigin(postData).subscribe( async () =>{
      await this.messageService.add({severity:'success', summary: 'Completed',
        detail:'New Lead Origin Created'});
      await this.fetchLeadOrigin();
      await this.leadOriginForm.reset();
    }, error => {
      this.messageService.add({severity:'error', summary: 'Notice',
        detail:'Unable to create new Lead Origin at the moment'});
    });
  }

  deleteLeadOrigin(leadOrigin) {
    this.confirmationService.confirm({
      message: 'Are you sure you want to delete ' + leadOrigin.caption,
      accept: () => {
        this._deleteLeadOrigin(leadOrigin);
      }
    });
  }
  _deleteLeadOrigin(leadOrigin) {
    this.messageService.add({severity:'info', summary: 'Notice',
      detail:'Deleting lead Origin record'});
    this.leadOriginService.deleteLeadOrigin(leadOrigin.id).subscribe( async (result:any) => {
      await this.messageService.add({severity:'success', summary: 'Deleted',
        detail:'Lead Origin record removed'});
      await this.fetchLeadOrigin();
    }, error => {
      this.connectionError()
    });
  }
  editLeadOrigin(leadOrigins) {
    this.editingLeadOrigin = true;
    this.leadOriginForm.addControl('leadOriginId',  new FormControl({value: '', disabled: true}, Validators.required));
    this.edLeadOrigin = leadOrigins;
    this.leadOriginForm.patchValue({
      caption: leadOrigins.caption,
      description: leadOrigins.description,
      leadOriginId: leadOrigins.id,
    });
    // this.leadOriginFormIn.nativeElement.focus();
    // document.getElementById('formFields').focus();
    this.selectedLeadTypeId = this.leadtypes.find(leadType => leadType.id === leadOrigins.leadTypeId);
    this.formWrapper.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'start' });
  }

  updateLeadOrigin() {
    // update the selected leadOrigin
    this.messageService.add({severity:'info', summary: 'Notice',
      detail:'Updating Lead Origin details'});
    const id = this.edLeadOrigin.id;
    const postData = {
      caption: this.leadOriginForm.get('caption').value,
      description: this.leadOriginForm.get('description').value,
      leadTypeId: this.selectedLeadTypeId.id,
    };
    this.leadOriginService.updateLeadOrigin(id, postData).subscribe( async (result: LeadOrigin) => {
      this.messageService.add({severity:'success', summary: 'Notice',
        detail:'Lead Origin details Updated'});
      await this.fetchLeadOrigin();
      await this.closeEditing();
    })
  }

  closeEditing() {
    this.editingLeadOrigin = false;
    this.leadOriginForm.reset();
    this.edLeadOrigin = null;
  }
}
