import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {Observable} from 'rxjs';
import {CommonResponse} from '../interfaces/home';

@Injectable({
  providedIn: 'root'
})
export class ClientPolicyService {

  constructor(private http: HttpClient) { }
  baseUrl = environment.baseUrl+'ClientPolicy/';
  // fetch all ClientPolicy in the database
  allClientPolicyData(): Observable<CommonResponse>{
    return this.http.get<CommonResponse>(this.baseUrl);
  }
  getClientPolicy(id: any):Observable<CommonResponse>{
    return this.http.get<CommonResponse>(this.baseUrl + id);
  }
  getClientPolicyByContractId(id: any):Observable<CommonResponse>{
    return this.http.get<CommonResponse>(this.baseUrl + 'GetByContractId/' + id);
  }
  getClientPolicyByContractServiceId(id: any):Observable<CommonResponse>{
    return this.http.get<CommonResponse>(this.baseUrl + 'GetByContractServiceId/' + id);
  }
  getClientPolicyByName(name: any):Observable<CommonResponse>{
    return this.http.get<CommonResponse>(this.baseUrl + 'name/' + name);
  }
  postClientPolicy(data: any){
    return this.http.post(this.baseUrl, data);
  }
  deleteClientPolicy(id: any){
    return this.http.delete(this.baseUrl +id);
  }
  updateClientPolicy( data: any){
    return this.http.put(this.baseUrl, data);
  }
}
