import { Component, OnInit, ViewChild ,ElementRef} from '@angular/core';
import { ConfirmationService, MenuItem, MessageService } from "primeng/api";
import { DialogService } from "primeng/dynamicdialog";
import { ProductService } from "src/app/demo/service/productservice";
import { ContactsService } from "../contacts/contacts.service";
import { ActivatedRoute } from "@angular/router";
import { FullCalendar } from "primeng/fullcalendar";
import { EngagementActivitiesService } from './engagement-activities.service';
import { DomSanitizer } from "@angular/platform-browser";
import { EventService } from 'src/app/demo/service/eventservice';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin, { Draggable } from '@fullcalendar/interaction';
import timeGridPlugin from '@fullcalendar/timegrid';
import esLocale from '@fullcalendar/core/locales/es';
import frLocale from '@fullcalendar/core/locales/fr';
import { UserService } from 'src/app/services/user.service';
import { FormBuilder, Validators } from '@angular/forms';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { async } from 'rxjs/internal/scheduler/async';
//import resourceTimeGridPlugin from '@fullcalendar/resource-timegrid';



@Component({
  selector: 'app-engagement-activities',
  templateUrl: './engagement-activities.component.html',
  styleUrls: ['./engagement-activities.component.scss']
})
export class EngagementActivitiesComponent implements OnInit {
  @ViewChild('target')
  targetRef: ElementRef;
  SuspectInstance: any;
  suspectContact: any[];
  displayMeetingModal=false;
  workspaceIsLoading=false;
  subscriber: any;
  showEditTodo=false;
  newId: string;
  SelectedStaffs:any[]=[];
  SelectedContacts:any[]=[];
  displayEditTodo=false;
  displayEdit= false;
  selectedMeetingType:any;
  startDate:any;
  endDate:any;
  meetingTypes: { name: string; value: number; }[];
  constacts: any;
  users: any[];
  createMeetingForm: any;
  contactUser: any[];
  staffUser: any[];
  meetingInstance: any;
  meetingList: any[];
  meetingResult=null;
  check= true;
  meetingCols: { field: string; header: string; }[];
  EditMeetingForm: any;
  createGoalForm: any;
  goalList: any[];
  currentGoal: any;
  createTodo: any;
  createTodoForm: any;
  responsibleList: any[];
  todoList: any[];
  EditGoalGorm: any;
  goalTodEdit: any;
  currentMeeting: any;
  showEditGoal: boolean;
  EditGoalForm: any;
  EditTodoForm: any;
  status: { name: string; value: number; }[];
  currentTodo: any;
  constactsBySuspect: any[];
  eventResponse: any;
  showGoogleEvents=false;
  // selectedContact: any[];
  // selectedUser: any[];
 
  

  constructor(
    public engagementService:EngagementActivitiesService,
    private route: ActivatedRoute,
    public confirmationService: ConfirmationService,
    public dialogService: DialogService,
    public messageService: MessageService,
    private sanitizer: DomSanitizer,
    private eventService: EventService,
    private userService:UserService,
    private formBuilder: FormBuilder,
  ) { 


    this.createMeetingForm = this.formBuilder.group({
      caption: [null, Validators.required],
      meetingType: ["", Validators.required],
      description: ["", Validators.required],
      reason: ["", Validators.required],
      startDate: [null, Validators.required],
      endDate: [null, Validators.required],
      selectedUsers:[null],
      selectedContact:[null]
    });

    this.createMeetingForm
      .get("endDate")
      .valueChanges.subscribe((selectedValue) => {
        this.validateDate(selectedValue);
      });

    this.EditMeetingForm = this.formBuilder.group({
      caption: [null],
      meetingType: [null],
      description: [null],
      reason: [null],
      startDate: [null],
      endDate: [null],
    });

    this.createGoalForm = this.formBuilder.group({
      caption: [null, Validators.required],
      description: ["", Validators.required],
    });

    this.createTodoForm = this.formBuilder.group({
      caption: [null, Validators.required],
      description: [null, Validators.required],
      dueDate:[null,Validators.required],
      responsible:[null]
    });

    this.EditTodoForm = this.formBuilder.group({
      caption: [null],
      description: [null],
      dueDate:[null],
      status:[null]
    });



    this.EditGoalForm = this.formBuilder.group({
      caption: [null],
      description: [null],
      
    });

  }

    
  

  


  ngOnInit(): void {
    this.subscriber = this.route.paramMap.subscribe((params) => {
      this.newId = params.get("id");
      this.getSuspectById(this.newId);
      this.getAllContactsToSuspect(this.newId);
      this.getMeetings(this.newId);
      this.getAllGoal(this.newId);
      this.getAllContactsAttachedToSusupect(this.newId);
      this.currentGoal = null;
      this.meetingResult = null;
    });
    console.log(this.newId);

    this.meetingTypes = [
      { name: "Webinar", value: 1 },
      { name: "Seminar", value: 2 },
      { name: "Meet And Greet", value: 3 },
      { name: "CloseOut", value: 4},
      { name: "Presentation", value: 5 },
      { name: "Negotiations", value: 6 },
      { name: "Product Briefing", value: 7 },
    ];

    this.status=[
      {name: "True",value:1},
      {name: "False",value:2}
    ]
    
    this.getAllContacts();
    this.getAllUsers();
    this.getMeetings(this.newId);


    this.meetingCols = [
      { field: 'caption', header: 'Caption' },
      { field: 'meetingType', header: 'MeetingType' },
      { field: 'reason', header: 'Reason' },
      { field: 'startDate', header: 'startDate' }
    ]
  
  }

  validateDate(endDate){
    if(endDate != null && this.createMeetingForm.value.startDate != null){
      if( endDate < this.createMeetingForm.value.startDate){
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail: "Start date cannot be greater than end date",
        });
      }
      else{
        return true;
      }
    }
  }

  saveMeeting(){
     if(this.createMeetingForm.value.endDate < this.createMeetingForm.value.startDate)
     {
       return
     }
     else{
      console.log("this is the value of console",this.createMeetingForm.value);
      if(this.createMeetingForm.value.selectedContact != null){
        this.contactUser = []
        
        this.createMeetingForm.value.selectedContact.forEach(contact=>{
          var contactInstance = {
            contactId:0,
            profilePicture:"",
            name:""
          }
          contactInstance.contactId = contact.id,
          contactInstance.name = contact.displayLabel,
          contactInstance.profilePicture = contact.profilePicture
          this.contactUser.push(contactInstance);
        })
        console.log("this is the value of contactUserArr",this.contactUser);
      }
      if(this.createMeetingForm.value.selectedUsers != null){
        this.staffUser = []
        
        this.createMeetingForm.value.selectedUsers.forEach(user=>{
          var contactInstance = {
            StaffId:0,
            StaffProfileImg:"",
            staffName:""
          }
          contactInstance.StaffId = user.id,
          contactInstance.staffName = user.displayLabel2,
          contactInstance.StaffProfileImg = user.imageUrl
          this.staffUser.push(contactInstance);
        })
      }
      const payload = {
        caption:this.createMeetingForm.value.caption,
        suspectId:this.newId,
        reason:this.createMeetingForm.value.reason,
        description:this.createMeetingForm.value.description,
        meetingType:this.createMeetingForm.value.meetingType.value,
        startDate:this.createMeetingForm.value.startDate,
        endDate:this.createMeetingForm.value.endDate,
        staffsInvolved:this.staffUser,
        contactsInvolved:this.contactUser
      }
  
      this.messageService.add({
        severity: "info",
        summary: "Notice",
        detail: "Saving meeting....",
      });
      this.workspaceIsLoading = true;
     this.engagementService.saveMeeting(payload)
     .subscribe(async(data:any)=>{
       if(data.responseCode == "00"){
         this.meetingInstance = data.responseData;
         this.messageService.add({
          severity: "success",
          summary: "Notice",
          detail: "Meeting saved successfully....",
        });
        this.createMeetingForm.reset();
        this.workspaceIsLoading = false;
        this.meetingList.push(this.meetingInstance);
        this.staffUser = [];
        this.contactUser = [];
        this.getMeetings(this.newId);
        this.displayMeetingModal = false
       }
       else{
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail: data.responseMsg,
        });
        this.workspaceIsLoading = false;
       }
     },error=>{
      this.messageService.add({
        severity: "error",
        summary: "Notice",
        detail: "An error occurred....",
      });
      this.workspaceIsLoading = false;
     }
     )

     }
  }



  disableStaff(user) {
    this.confirmationService.confirm({
        message: `Are you sure that you want to remove ${user.staffName}?`,
        header: 'Confirmation',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
           this.removeStaff(user);
        },
        reject: (type) => {
            
        }
    });
}


changeStatus(item) {
  
    this.confirmationService.confirm({
      message: `Are you sure that you want to mark ${item.caption} as completed?`,
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
         this.updateStatus(item);
      },
      reject: (type) => {
          
      }
  });
}

updateStatus(todo){
    this.engagementService.changeStatus(todo.id)
  .subscribe(async(data:any)=>{
    if(data.responseCode == "00"){
      this.messageService.add({
        severity: "success",
        summary: "Notice",
        detail: data.responseMsg,
      });
      this.getAllTodoByGoalId(this.currentGoal.id);
    }
    else{
      this.messageService.add({
        severity: "error",
        summary: "Notice",
        detail: data.responseMsg,
      });
    }
  },error=>{
    this.messageService.add({
      severity: "error",
      summary: "Notice",
      detail: "An error occurred",
    });
  }
  ,)
  
}


disableContact(user) {
  this.confirmationService.confirm({
      message: `Are you sure that you want to remove ${user.name}?`,
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
         this.removeContact(user);
      },
      reject: (type) => {
          
      }
  });
}

disableGoal(goal) {
  this.confirmationService.confirm({
      message: `Are you sure that you want to remove this goal?`,
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
         this.removeGoal(goal);
      },
      reject: (type) => {
          
      }
  });
}

disableTodo(todo) {
  this.confirmationService.confirm({
      message: `Are you sure that you want to remove this todo?`,
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
         this.removeTodo(todo);
      },
      reject: (type) => {
          
      }
  });
}

removeTodo(todo){
  this.workspaceIsLoading = true;
  this.engagementService.disableTodo(todo.id)
  .subscribe(async(data:any)=>{
      if(data.responseCode == "00"){
        this.getAllTodoByGoalId(this.currentGoal.id);
        this.messageService.add({
          severity: "success",
          summary: "Notice",
          detail: data.responseMsg,
        });
        this.workspaceIsLoading = false;
      }
      else{
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail: data.responseMsg,
        });
        this.workspaceIsLoading =false
      }

  },error=>{
    this.messageService.add({
      severity: "error",
      summary: "Notice",
      detail: "An error occurred",
    });
    this.workspaceIsLoading = false;
  }
  )
}

pushToGoogleCalender(){
  if( this.meetingResult.calenderEvents.length != 0){
    return;
  }
  else{
    this.workspaceIsLoading= true;
    console.log("this is what i am pushing to calender",this.meetingResult);
    this.engagementService.postGoogleEvent(this.meetingResult)
    .subscribe(async(data:any)=>{
      if(data.responseCode == "00"){
        this.messageService.add({
          severity: "success",
          summary: "Notice",
          detail: data.responseMsg,
        });
        this.meetingResult = null;
        this.getMeetings(this.newId);
        this.workspaceIsLoading = false;
      }
      else{
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail: data.responseMsg,
        });
        this.workspaceIsLoading = false;
      }
    },error=>{
      this.messageService.add({
        severity: "error",
        summary: "Notice",
        detail: error.error.errorMessage,
      });
      console.log("this are the errors ",error);
      this.workspaceIsLoading = false;
    }
  )
  }
 
}

getAllGoogleEvents(){
  this.workspaceIsLoading = true;
  this.engagementService.getAllGoogleEvents()
  .subscribe(async(data:any)=>{
     if(data.responseCode == "00"){
       this.eventResponse = data.responseData;
       console.log("This is all google event",this.eventResponse);
       this.showGoogleEvents = true;
       this.messageService.add({
        severity: "success",
        summary: "Notice",
        detail: data.responseMsg,
      });
       this.workspaceIsLoading = false;
     }
     else{
      this.messageService.add({
        severity: "error",
        summary: "Notice",
        detail: data.responseMsg,
      });
     }
  },
  error=>{
    this.messageService.add({
      severity: "error",
      summary: "Notice",
      detail: "An error occurred",
    });
    this.workspaceIsLoading = false;
  })
}

removeGoal(goal){
  this.workspaceIsLoading = true;
  this.engagementService.disableGoal(goal.id)
  .subscribe(async(data:any)=>{
      if(data.responseCode == "00"){
        this.getAllGoal(this.newId);
        this.messageService.add({
          severity: "success",
          summary: "Notice",
          detail: data.responseMsg,
        });
        this.workspaceIsLoading = false;
      }
      else{
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail: data.responseMsg,
        });
        this.workspaceIsLoading =false
      }

  },error=>{
    this.messageService.add({
      severity: "error",
      summary: "Notice",
      detail: "An error occurred",
    });
    this.workspaceIsLoading = false;
  }
  )
}

removeContact(user){
  this.engagementService.disableMeetingContact(user.meetingId,user.contactId)
  .subscribe(async(data:any)=>{
      if(data.responseCode == "00"){
        this.getMeetings(this.newId);
        this.meetingResult.contactsInvolved = data.responseData;
        console.log("this is the new array",this.meetingResult.contactsInvolved);
        this.messageService.add({
          severity: "success",
          summary: "Notice",
          detail: data.responseMsg,
        });
      }
      else{
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail: data.responseMsg,
        });
      }

  },error=>{
    this.messageService.add({
      severity: "error",
      summary: "Notice",
      detail: "An error occurred",
    });
  }
  )
}

  removeStaff(user){
      this.engagementService.disableMeetingStaff(user.meetingId,user.staffId)
      .subscribe(async(data:any)=>{
          if(data.responseCode == "00"){
            this.getMeetings(this.newId);
            this.meetingResult.staffsInvolved = data.responseData
            console.log("this is the new array",this.meetingResult.staffsInvolved);
            this.messageService.add({
              severity: "success",
              summary: "Notice",
              detail: data.responseMsg,
            });
          }
          else{
            this.messageService.add({
              severity: "error",
              summary: "Notice",
              detail: data.responseMsg,
            });
          }

      },error=>{
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail: "An error occurred",
        });
      }
      )
  }

  AddmoreStaffs(){
    if(this.SelectedStaffs.length != 0){
      this.workspaceIsLoading = true;
      
      var payload = []
      this.SelectedStaffs.forEach(staff=>{
        var contactInstance = {
          StaffId:0,
          StaffProfileImg:"",
          staffName:""
        }

        contactInstance.StaffId = staff.id,
        contactInstance.staffName = staff.displayLabel2,
        contactInstance.StaffProfileImg = staff.imageUrl
        payload.push(contactInstance);

      })

      this.messageService.add({
        severity: "info",
        summary: "Notice",
        detail: "Adding more staffs",
      });
      this.engagementService.AddmoreMeetingStaff(this.currentMeeting.id,payload)
      .subscribe(async(data:any)=>{
        if(data.responseCode == "00"){
          this.messageService.add({
            severity: "success",
            summary: "Notice",
            detail: data.responseMsg,
          });
          this.meetingResult.staffsInvolved = data.responseData;
          this.workspaceIsLoading = false;
          this.displayEdit = false;
          this.SelectedStaffs = [];
        }
        else{
          this.workspaceIsLoading = false;
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail: data.responseMsg,
          });
          this.workspaceIsLoading = false;
        }
      },error=>{
        this.workspaceIsLoading = false;
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail: "An error occurred",
          });
          this.workspaceIsLoading = false;
      })
    }
  }

 


  AddmoreContacts(){

    console.log("this is an instance",this.SelectedContacts)
    if(this.SelectedContacts.length != 0){
      this.workspaceIsLoading = true;
      
      var payload = []
      this.SelectedContacts.forEach(contact=>{
        var contactInstance = {
          contactId:0,
          profilePicture:"",
          name:""
        }

        contactInstance.contactId = contact.id,
        contactInstance.name = contact.displayLabel,
        contactInstance.profilePicture = contact.profilePicture
        payload.push(contactInstance);

      })

      this.messageService.add({
        severity: "info",
        summary: "Notice",
        detail: "Adding more staffs",
      });
      this.engagementService.AddmoreMeetingContact(this.currentMeeting.id,payload)
      .subscribe(async(data:any)=>{
        if(data.responseCode == "00"){
          this.messageService.add({
            severity: "success",
            summary: "Notice",
            detail: data.responseMsg,
          });
          this.meetingResult.contactsInvolved = data.responseData;
          this.workspaceIsLoading = false;
          this.displayEdit = false;
          this.SelectedContacts = [];
        }
        else{
          this.workspaceIsLoading = false;
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail: data.responseMsg,
          });
          this.workspaceIsLoading = false;
        }
      },error=>{
        this.workspaceIsLoading = false;
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail: "An error occurred",
          });
          this.workspaceIsLoading = false;
      })
    }
  }

  editGoal(goal){
    this.goalTodEdit = goal;
    this.showEditGoal = true;
  }


  updateGoal(){
    if(this.EditGoalForm.dirty){
      this.workspaceIsLoading = true;
      this.messageService.add({
        severity: "info",
        summary: "Notice",
        detail: "Updating Goal",
      });
      const payload ={
        caption:this.EditGoalForm.value.caption == null ? this.goalTodEdit.caption:this.EditGoalForm.value.caption,
        description:this.EditGoalForm.value.description == null ? this.goalTodEdit.description:this.EditGoalForm.value.description
      }
      this.engagementService.updateGoal(this.goalTodEdit.id,payload)
      .subscribe(async(data:any)=>{
           if(data.responseCode == "00"){
             this.showEditGoal = false;
             this.getAllGoal(this.newId);
             this.EditGoalForm.reset();
             this.workspaceIsLoading = false
             this.messageService.add({
              severity: "success",
              summary: "Notice",
              detail: "Successfully updated goal",
            });
           }
           else{
             this.workspaceIsLoading = false
             this.messageService.add({
              severity: "error",
              summary: "Notice",
              detail: data.responseData,
            });
           }
          
      },error=>{
        this.workspaceIsLoading = false
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail: "An error occured",
        });
      }
      )
    }
  }

  updateTodo(){
    if(this.EditTodoForm.dirty){
      this.workspaceIsLoading = true;
      this.messageService.add({
        severity: "info",
        summary: "Notice",
        detail: "Updating Todo",
      });
      const payload ={
        caption:this.EditTodoForm.value.caption == null ? this.currentTodo.caption:this.EditTodoForm.value.caption,
        description:this.EditTodoForm.value.description == null ? this.currentTodo.description:this.EditTodoForm.value.description,
        dueDate:this.EditTodoForm.value.dueDate == null ? this.currentTodo.dueDate:this.EditTodoForm.value.dueDate,
        status:this.currentTodo.status
      }
      this.engagementService.updateTodo(this.currentTodo.id,payload)
      .subscribe(async(data:any)=>{
           if(data.responseCode == "00"){
             this.showEditTodo = false;
             this.getAllTodoByGoalId(this.currentGoal.id);
             this.EditTodoForm.reset();
             this.workspaceIsLoading = false
             this.messageService.add({
              severity: "success",
              summary: "Notice",
              detail: "Successfully updated todo",
            });
           }
           else{
             this.workspaceIsLoading = false
             this.messageService.add({
              severity: "error",
              summary: "Notice",
              detail: data.responseData,
            });
           }
          
      },error=>{
        this.workspaceIsLoading = false
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail: "An error occured",
        });
      }
      )
    }
  }
  
  removeGoogleEvent(eventDetails){
    this.confirmationService.confirm({
      message: 'Are you sure that you want to remove '+ eventDetails.caption + "  event From google calendar?",
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
          this.deleteGoogleEvent(eventDetails.calenderEvents[0].calenderId)
      },
      reject: (type) => {
        
      }
  });
  }

  deleteGoogleEvent(eventId){
    this.workspaceIsLoading = true;
    this.engagementService.disableGoogleEvent(eventId)
    .subscribe(async(data:any)=>{
      if(data.responseCode == "00"){
        this.messageService.add({
         severity: "success",
         summary: "Notice",
         detail: "Successfully removed meeting",
       });
       this.meetingResult = null;
       this.getMeetings(this.newId);
       
      }
      else{
        this.workspaceIsLoading = false;
       this.messageService.add({
         severity: "error",
         summary: "Notice",
         detail: "Could not delete meeting ",
       });
      }
    },error=>{
     this.workspaceIsLoading = false;
     this.messageService.add({
       severity: "error",
       summary: "Notice",
       detail: "An error occurred",
     });
    }
    )
 }


  deleteMeeting(meetingId){
     this.workspaceIsLoading = true;
     this.engagementService.disableMeeting(meetingId)
     .subscribe(async(data:any)=>{
       if(data.responseCode == "00"){
         this.messageService.add({
          severity: "success",
          summary: "Notice",
          detail: "Successfully removed meeting",
        });
        this.meetingResult = null;
        this.getMeetings(this.newId);
        
       }
       else{
         this.workspaceIsLoading = false;
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail: "Could not delete meeting ",
        });
       }
     },error=>{
      this.workspaceIsLoading = false;
      this.messageService.add({
        severity: "error",
        summary: "Notice",
        detail: "An error occurred",
      });
     }
     )
  }

  removeMeeting(meeting){
       this.confirmationService.confirm({
        message: 'Are you sure that you want to proceed?',
        header: 'Confirmation',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
            this.deleteMeeting(meeting.id);
        },
        reject: (type) => {
           
        }
    });

  }

  getSuspectById(id) {
    this.engagementService
      .getSuspectById(id)
      .subscribe(async (data: any) => {
        if (data.responseCode == "00") {
          this.SuspectInstance = data.responseData
          console.log("this is leads new", this.SuspectInstance);
          this.messageService.add({
            severity: "success",
            summary: "Notice",
            detail: "All leads were fectched successsfully",
          });
        }
      });
  }

  getMeetings(id) {
    this.workspaceIsLoading = true;
    this.engagementService
      .getMeetingsById(id)
      .subscribe(async (data: any) => {
        if (data.responseCode == "00") {
          this.meetingList = data.responseData
          console.log("this is leads array", this.meetingList);
          this.messageService.add({
            severity: "success",
            summary: "Notice",
            detail: "All meetings were fectched successsfully",
          });
          this.workspaceIsLoading = false;
        }
      });
  }

  getAllUsers(){
    this.userService.allUser()
    .subscribe(async(data:any)=>{
      if(data.responseCode == "00"){
        this.users = data.responseData;
        this.users = this.users.map((res: any) => {
          return {
            ...res,
            displayLabel2: res.firstName + ' ' + res.lastName
          };
        });
      }
    })
  }

  getAllGoal(suspectId:any){
    this.engagementService.getGoalsBySuspectId(suspectId)
    .subscribe(async(data:any)=>{
      if(data.responseCode == "00"){
        this.goalList = data.responseData;
      }
    })
  }

  selectGoal(goal){
    this.currentGoal = goal;
    this.getAllTodoByGoalId(this.currentGoal.id);
  }

  saveGoal(){
       console.log("this is the Goal",this.createGoalForm.value);
       const payload = {
         caption:this.createGoalForm.value.caption,
         description:this.createGoalForm.value.description,
       }

       this.workspaceIsLoading = true;
       this.engagementService.createGoal(this.newId,payload)
       .subscribe(async(data:any)=>{
         if(data.responseCode == "00"){
           console.log("result from create goal",data.responseData);
           this.createGoalForm.reset();
           this.workspaceIsLoading = false;
           this.goalList.push(data.responseData);
           this.getAllGoal(this.newId);
           this.messageService.add({
            severity: "success",
            summary: "Notice",
            detail: "Successfully created goal",
          });
         }
         else{
          this.workspaceIsLoading = false;
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail: data.responseMsg,
          });
         }
       },error=>{
         this.workspaceIsLoading = false;
         this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail: "An error occurred",
        });
       }
       )

  }

  openUpdateButton(meetingResult){
    this.displayEdit = true;
    this.currentMeeting = meetingResult;
  }


  editMeeting(meeting){
    console.log("this is the meeting gotten",meeting);

    const payload = {
      caption:this.EditMeetingForm.value.caption == null ? meeting.caption : this.EditMeetingForm.value.caption,
      description:this.EditMeetingForm.value.description == null ? meeting.description : this.EditMeetingForm.value.description,
      meetingType:this.EditMeetingForm.value.meetingType == null ? meeting.meetingType : this.EditMeetingForm.value.meetingType.value,
      reason:this.EditMeetingForm.value.reason == null ? meeting.reason : this.EditMeetingForm.value.reason,
      startDate:this.EditMeetingForm.value.startDate == null ? meeting.startDate:this.EditMeetingForm.value.startDate,
      endDate: this.EditMeetingForm.value.endDate == null ? meeting.endDate:this.EditMeetingForm.value.endDate,
      // staffsInvolved:meeting.staffsInvolved,
      // contactsInvolved:meeting.contactsInvolved

    }

    console.log("this is the payload",payload);
    this.workspaceIsLoading = true;
    this.engagementService.updateMeeting(meeting.id,payload)
    .subscribe(async(data:any)=>{
      if(data.responseCode == "00"){
        this.EditMeetingForm.reset;
        this.meetingResult = null;
        this.displayEdit = false;
        this.getMeetings(this.newId);
        
        this.workspaceIsLoading = false;
        this.messageService.add({
          severity: "success",
          summary: "Notice",
          detail: "Successfully updated meeting",
        });
      }
      else{
        this.workspaceIsLoading = false;
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail: data.responseMsg,
        });
      }
    },error=>{
      this.workspaceIsLoading = false;
      this.messageService.add({
        severity: "error",
        summary: "Notice",
        detail: "An error occurred",
      });
    })
  }




  async selectMeeting(meeting){
    console.log("this is what i chose",meeting)
    this.meetingResult = meeting;
    await this.targetRef?.nativeElement.scrollIntoView();
    const payload = {

    }
   
  }

  getMeetingType(id){
    return this.meetingTypes.find(x=>x.value == id);
  }

  getAllContacts() {
    this.engagementService.getContactList().subscribe(
      async (data: any) => {
        if (data.responseCode == "00") {
          this.constacts = data.responseData;
          this.constacts = this.constacts.map((res: any) => {
            return {
              ...res,
              displayLabel: res.firstName + ' ' + res.lastName
            };
          });
          console.warn("these are the data", this.constacts);
          this.messageService.add({
            severity: "success",
            summary: "Notice",
            detail: "Contacts was fetched successfully",
          });
        } else {
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail: "No data avaliable",
          });
        }
      },
      (error) => {
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail: "An error occured",
        });
      }
    );
  }

  getAllContactsAttachedToSusupect(suspectId) {
    this.engagementService.getContactsAttachedToSuspectById(suspectId).subscribe(
      async (data: any) => {
        if (data.responseCode == "00") {
          this.constactsBySuspect = data.responseData;
          this.constactsBySuspect = this.constactsBySuspect.map((res: any) => {
            return {
              ...res,
              displayLabel3: res.firstName + ' ' + res.lastName
            };
          });
          console.warn("these are the dataConnected to suspect", this.constacts);
          this.messageService.add({
            severity: "success",
            summary: "Notice",
            detail: "Contacts was fetched successfully",
          });
        } else {
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail: "No data avaliable",
          });
        }
      },
      (error) => {
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail: "An error occured",
        });
      }
    );
  }

  saveTodo(){
    if(this.createTodoForm.valid){
      if(this.createTodoForm.value.responsible != null){
        this.responsibleList = []
        
        this.createTodoForm.value.responsible.forEach(user=>{
          var responsibleInstance = {
            fullname:"",
            imageUrl:""
          }
          responsibleInstance.fullname = user.displayLabel2,
          responsibleInstance.imageUrl = user.imageUrl
          this.responsibleList.push(responsibleInstance);
        })

        console.log("this is list of userssss",this.responsibleList);

        const payload ={
          caption:this.createTodoForm.value.caption,
          description:this.createTodoForm.value.description,
          dueDate:this.createTodoForm.value.dueDate,
          responsible:this.responsibleList
        }

        this.workspaceIsLoading = true;
        this.engagementService.createTodo(this.currentGoal.id,payload)
        .subscribe(async(data:any)=>{
          if(data.responseCode == "00"){
            console.log("this is the saved Todo",data.responseData);
            this.workspaceIsLoading = false;
            this.createTodoForm.reset();
            this.displayEditTodo = false;
            this.todoList.push(data.responseData);
            this.getAllTodoByGoalId(this.currentGoal.id);
            this.messageService.add({
              severity: "success",
              summary: "Notice",
              detail: "Successfully created Todo",
            });
          }
          else{
            this.workspaceIsLoading = false;
            this.messageService.add({
              severity: "error",
              summary: "Notice",
              detail: data.responseMsg,
            });
          }
        },error=>{
          this.workspaceIsLoading = false;
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail: "An error occurred",
          });
        }
        )
      }
    }
  }


  getAllTodoByGoalId(goalId){
    this.workspaceIsLoading = true;
    this.engagementService.getTodoListByGoalId(goalId)
    .subscribe(async(data:any)=>{
      if(data.responseCode == "00"){
        this.todoList = data.responseData;
        this.workspaceIsLoading = false;
        console.log("this is the the list of Todo",this.todoList);
      }
    })
  }



  selectTodo(todo){
    this.currentTodo = todo
    this.showEditTodo = true;
    console.log("this is a list of todo",this.currentTodo);
  }

  getAllContactsToSuspect(id) {
    this.engagementService.getAllContactsAttachedToSuspects(id).subscribe(
      async (res: any) => {
        console.log("This is from suspectContacts",res.responseData);
        if (res.responseCode == "00") {
          this.suspectContact = [];
          this.suspectContact = res.responseData.filter(x => x.contact.isDeleted == false);
        } else {
          this.suspectContact = [];
        }
      },
      (error) => {
        this.suspectContact = [];
      }
    );
  }


}
