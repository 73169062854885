///<reference types="@types/googlemaps" />
import {
  AfterViewInit,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { ServiceAssignmentService } from "../../../../services/armada/service-assignment.service";
import { Observable, Subject, Subscription, timer } from "rxjs";
import { MessageService } from "primeng/api";
import { takeUntil } from "rxjs/operators";
import {
  ArmadaJourneyStart,
  CommanderUserProfile,
  MasterServiceAssignment,
  ServiceAssignment,
  VehicleServiceAssignmentDetail,
  VehicleTracking,
} from "../../../../interfaces/armada";
import { ViewContractServiceComponent } from "../../mobility-administration/service-request/view-contract-service/view-contract-service.component";
import { DialogService } from "primeng/dynamicdialog";
import { CommanderRegistrationService } from "../../../../services/armada/commander-registration.service";
import {
  AssignmentStatus,
  AssignmentStatusName,
} from "../assignment-status.enum";
import { MasterServiceAssignmentService } from "src/app/services/armada/master-service-assignment.service";
import { CommonResponse } from "src/app/interfaces/home";
import { JourneyStartAndStopService } from "src/app/services/armada/journey-start-and-stop.service";
import { ServiceAssignmentDetailService } from "src/app/services/armada/service-assignment-detail.service";
import { BreadcrumbService } from "src/app/breadcrumb.service";

@Component({
  selector: "app-active-service",
  templateUrl: "./active-service.component.html",
  styleUrls: ["./active-service.component.scss"],
  providers: [MessageService, DialogService],
})
export class ActiveServiceComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  @ViewChild("formWrapper") public formWrapper: ElementRef;
  private unsubscriber$ = new Subject<void>();
  public activeServiceAssignments: ServiceAssignment[];

  public serviceAssignments: ServiceAssignment[];
  public loading: boolean;
  public vehicleTrackingDialog: boolean = false;
  public serviceAssignmentStatus = AssignmentStatus;
  public serviceAssignmentStatusName = AssignmentStatusName;
  public masterServiceAssignments: MasterServiceAssignment[];
  public activeMasterServiceAssignments: MasterServiceAssignment[];
  public activeRoutes: MasterServiceAssignment[];
  public activeRegions: MasterServiceAssignment[];
  activeRoutesCount: any;
  activeRegionsCount: any;
  public fetchActiveMasterServiceAssignmentsCount: MasterServiceAssignment[];
  public fetchInActiveMasterServiceAssignmentsCount: MasterServiceAssignment[];
  public activeFilterMasterServiceAssignments: MasterServiceAssignment[];
  selectedmasterServiceAssignment: any;
  public armadaJourneyStarts: ArmadaJourneyStart[];
  public trackingApi: VehicleTracking;
  public trackingApis: any;
  selectedJourneyStart: any;
  trackingDoalog: boolean;
  selectedServiceAssignments: ServiceAssignment[];
  public vehicleServiceAssignmentDetails: VehicleServiceAssignmentDetail[];
  public vehicleServiceAssignmentDetail: VehicleServiceAssignmentDetail;
  public selectedvehicleServiceAssignmentDetail: VehicleServiceAssignmentDetail;
  public targetCols: any;
  private exportTargetColumns: any;
  topRoute: any;
  commanderActivities: any;
  dashboardStatistics: any;
  public loadingCommanderActivities: boolean;
  public loadDashboardStat: boolean;
  isCordinateHasValue: boolean;
  Lat: number;
  Lon: number;
  speed: any;
  name: any;
  angle: any;
  hasVal: number;
  locLatitude: number;
  locLongitude: number;
  public statusName = {
    1: "On Time",
    2: "Behind Schedule",
    3: "Arrival Overdue",
    4: "--",
  };
  data: any;
  getStatusColor: string;
  activeMasterServCount: number;
  chartActiveMasterServCount: any;
  chartInActiveMasterServCount: any;
  pieData: any;
  routeData: any;
  regionData: any;
  minDate = new Date();
  public statusColor = { 1: "primary", 2: "help", 3: "danger", 4: "--" };
  public datas: {
    datasets: {
      backgroundColor: string[];
      data: number[];
      hoverBackgroundColor: string[];
    }[];
    labels: string[];
  };
  subscription: Subscription;
  everyTwoMinutes: Observable<number> = timer(0, 120000);

  constructor(
    private breadcrumbService: BreadcrumbService,
    private serviceAssignmentService: ServiceAssignmentService,
    private messageService: MessageService,
    private dialogService: DialogService,
    private commanderProfileService: CommanderRegistrationService,
    private serviceAssignmentServices: MasterServiceAssignmentService,
    private journeyStartAndStopService: JourneyStartAndStopService,
    private serviceAssignmentDetailService: ServiceAssignmentDetailService
  ) {
    this.breadcrumbService.setItems([
      { label: "Setup" },
      {
        label: "dashbaord",
        routerLink: ["home/armada/active-service"],
      },
    ]);
  }

  ngOnInit(): void {
    this.loading = true;
    this.targetCols = [
      { field: "serviceAssignmentId", header: "AssignedId" },
      { field: "serviceName", header: "Resource" },
      { field: "preceedingRank", header: "Preceded By" },
      { field: "nextRank", header: "Next Rank" },
      { field: "sequence", header: "Sequence" },
    ];
    this.exportTargetColumns = this.targetCols.map((col) => ({
      title: col.header,
      dataKey: col.field,
    }));
    // this.fetchAllServiceAssign();
    //this.fetchCommanderActivities();
    //this.fetchDashboardStat();
    //this.getVehicleTracking();

    // this.data = {
    //   labels: ["head office3344"],
    //   datasets: [
    //     {
    //       data: [100],
    //       backgroundColor: ["#FF6384"],
    //       hoverBackgroundColor: ["#FF6384"],
    //     },
    //   ],
    // };

    this.subscription = this.everyTwoMinutes.subscribe(() => {
      this.fetchAllMasterServiceAssignments();
    });
    this.fetchAllJourneyStarts();
    this.fetchAllMasterServiceAssignments();

    this.pieData = {
      datasets: [
        {
          data: [
            localStorage.getItem("ActiveCount"),
            localStorage.getItem("InActiveCount"),
          ],
          backgroundColor: [
            "rgb(54, 162, 235)",
            "rgb(255, 99, 132)",
            //"rgb(255, 205, 86)",
            //"rgb(75, 192, 192)",
          ],
        },
      ],
      labels: ["Active", "InActive"],
    };
    this.routeData = {
      datasets: [
        {
          data: [
            localStorage.getItem("ActiveCount"),
            localStorage.getItem("InActiveCount"),
          ],
          backgroundColor: [
            "rgb(54, 162, 235)",
            "rgb(255, 99, 132)",
            "rgb(255, 205, 86)",
            "rgb(75, 192, 192)",
          ],
        },
      ],
      labels: ["Active", "InActive"],
    };

    console.log("countActive " + localStorage.getItem("ActiveCount"));
    console.log("countInActive " + localStorage.getItem("InActiveCount"));

    localStorage.mobile = "mobile";
    this.fetchAllActiveMasterServiceAssignmentsForRouteRegion();
    this.fetchAllVehicleServiceAssignmentDetails();
  }

  ngAfterViewInit(): void {
    // Load google maps script after view init
    const DSLScript = document.createElement("script");
    DSLScript.src =
      "https://maps.googleapis.com/maps/api/js?key=AIzaSyCQuetprs2UHb_zKSXznyumyfvw95ERCDY"; // replace by your API key
    DSLScript.type = "text/javascript";
    document.body.appendChild(DSLScript);
    document.body.removeChild(DSLScript);
    //this.initMap();
  }

  // Initialize and add the map
  initMap(): void {
    //this.Lat && this.Lon
    if (this.Lat && this.Lon) {
      // The location
      console.log("InitMap Lat and Lon: ", this.Lat + " " + this.Lon);
      const location = { lat: this.Lat, lng: this.Lon };

      // The map, centered at pickup Loc
      const map = new google.maps.Map(
        document.getElementById("map") as HTMLElement,
        {
          zoom: 10,
          center: location,
        }
      );

      // The marker, positioned at Loc
      const marker = new google.maps.Marker({
        position: location,
        map: map,
      });
    }
  }
  getItem() {
    this.Lon = Number(localStorage.getItem("longitudeTrack"));
    this.Lat = Number(localStorage.getItem("latitudeTrack"));
    // let inputValLat = (<HTMLInputElement>document.getElementById("lat")).value;
    // let inputValLon = (<HTMLInputElement>document.getElementById("lon")).value;
    //this.Lat = Number(inputValLat);
    //this.Lon = Number(inputValLon);
    this.hasVal = Number(localStorage.getItem("isHasVal"));
    let val = this.hasVal;
    if (this.Lat === 0 || this.Lon === 0) {
      this.isCordinateHasValue = false;
    } else {
      this.isCordinateHasValue = true;
    }
    console.log("For Lat and Lon: ", this.Lat + " " + this.Lon);
    this.initMap();
  }
  goToBottom() {
    window.scrollTo(0, document.body.scrollHeight);
  }
  TrackVehicle(serviceAssignment: VehicleServiceAssignmentDetail) {
    this.isCordinateHasValue = true;
    let trackId =
      serviceAssignment.vehicleResource.supplierService.referenceNumber1;
    let resId = serviceAssignment.vehicleResourceId;
    this.vehicleServiceAssignmentDetail = serviceAssignment;
    console.log("AssignmentDetail: ", this.vehicleServiceAssignmentDetail);

    if (trackId === null || trackId === "") {
      this.isCordinateHasValue = false;
      this.messageService.add({
        severity: "error",
        summary: "Failed",
        detail: " Error: No Imei provided for selected vehicle",
      });
    }
    this.journeyStartAndStopService
      .vehicleTracking(trackId)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            var res = r.responseData;
            console.log(res);
            if (res === null || res.length < 1) {
              this.isCordinateHasValue = false;
              this.messageService.add({
                severity: "error",
                summary: "Failed",
                detail:
                  " Error: Tracking Imei not available for selected vehicle",
              });
            } else {
              this.isCordinateHasValue = true;
              this.formWrapper.nativeElement.scrollIntoView({
                behavior: "smooth",
                block: "end",
                inline: "start",
              });
              this.Lat = parseFloat(res[trackId].lat);
              this.Lon = parseFloat(res[trackId].lng);
              this.speed = res[trackId].speed;
              this.name = res[trackId].name;
              this.angle = res[trackId].angle;
              this.initMap();
              console.log("c# Res Lat and Lon: ", this.angle + "" + this.name);
            }

            //console.log("journeystart Id: ", this.getJourneyId);
            this.loading = false;
          }
        },
        (error) => {
          this.loading = false;
          this.isCordinateHasValue = false;
          this.messageService.add({
            severity: "error",
            summary: "Failed",
            detail:
              "Tracking Error: Incorrect Imei provided  or connection error",
          });
          //this.connectionError();
        }
      );
    console.log("IMEI Id", trackId);

    // if (trackId === null || trackId === "") {
    //   this.messageService.add({
    //     severity: "error",
    //     summary: "Failed",
    //     detail: " Error: Imei not available",
    //   });
    // }
    //let imei = "861693032525859";
    //let imei = trackId;
    //this.isCordinateHasValue = true;
    //this.getVehicleTracking(imei);

    //this.getItem();
  }

  fetchAllJourneyStarts(): void {
    this.journeyStartAndStopService
      .allJourneyStarts()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            var res = r.responseData;
            this.armadaJourneyStarts = res;
            //const _assignments = this.masterServiceAssignments;
            //this.activeMasterServiceAssignments = [];
            this.armadaJourneyStarts.filter(
              (ass) => ass.serviceAssignment.assignmentStatus === "Closed"
            );
            this.loading = false;
          }
        },
        (error) => {
          this.loading = false;
          this.connectionError();
        }
      );
  }
  fetchAllMasterServiceAssignments(): void {
    this.loading = true;
    this.serviceAssignmentServices
      .allServiceAssignmentsForDashboard()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            var res = r.responseData;
            this.masterServiceAssignments = res;
            const _assignments = this.masterServiceAssignments;
            this.activeMasterServiceAssignments = [];
            this.activeMasterServiceAssignments = _assignments;
            // this.activeMasterServiceAssignments = _assignments.filter(
            //   (ass) => ass.assignmentStatus !== "Closed"
            // );
            //get for chart actives
            this.fetchActiveMasterServiceAssignmentsCount = _assignments.filter(
              (ass) =>
                ass.saExecutionStatus === 1 && ass.assignmentStatus !== "Closed"
            );
            //get for chart inActives
            this.fetchInActiveMasterServiceAssignmentsCount =
              _assignments.filter(
                (ass) =>
                  ass.saExecutionStatus === 0 &&
                  ass.assignmentStatus !== "Closed"
              );
            //Counts
            this.activeMasterServCount =
              this.activeMasterServiceAssignments.length;
            this.chartActiveMasterServCount =
              this.fetchActiveMasterServiceAssignmentsCount.length;
            this.chartInActiveMasterServCount =
              this.fetchInActiveMasterServiceAssignmentsCount.length;
            localStorage.setItem(
              "ActiveCount",
              this.chartActiveMasterServCount
            );
            localStorage.setItem(
              "InActiveCount",
              this.chartInActiveMasterServCount
            );

            this.loading = false;
          }
        },
        (error) => {
          this.loading = false;
          this.connectionError();
        }
      );
  }

  fetchAllActiveMasterServiceAssignmentsForRouteRegion(): void {
    //this.loading = true;
    this.serviceAssignmentServices
      .allServiceAssignments()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            var res = r.responseData;
            //var rRes = r.responseData.sMORegion;
            this.masterServiceAssignments = res;
            //this.masterServiceAssignments = rRes;
            const _assignments = this.masterServiceAssignments;
            //this.activeMasterServiceAssignments = [];
            // this.activeMasterServiceAssignments = _assignments.filter(
            //   (ass) => ass.assignmentStatus !== "Closed"
            // );
            //get chart active routes with count
            this.activeRoutes = [];
            this.activeRoutes = _assignments.filter(
              (ass) =>
                ass.saExecutionStatus === 0 && ass.assignmentStatus !== "Closed"
            );
            console.log("activeRoutes" + this.activeRoutes.length);
            //get chart active regions with count
            this.activeRegions = [];
            this.activeRegions = _assignments.filter(
              (ass) =>
                ass.saExecutionStatus === 0 && ass.assignmentStatus !== "Closed"
            );
            console.log("activeRegions" + this.activeRegions.length);
            //Counts
            this.activeRoutesCount = this.activeRoutes.length;

            this.activeRegionsCount = this.activeRegions.length;
            localStorage.setItem("activeRoutesCount", this.activeRoutesCount);
            localStorage.setItem("activeRegionsCount", this.activeRegionsCount);

            this.loading = false;
          }
        },
        (error) => {
          this.loading = false;
          this.connectionError();
        }
      );
  }
  fetchAllServiceAssign() {
    this.loading = true;
    return this.serviceAssignmentService
      .allServiceAssignment()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (result: any) => {
          this.loading = false;
          this.activeServiceAssignments = result;
          this.serviceAssignments = result;
        },
        (error) => {
          this.loading = false;
          this.connectionError();
        }
      );
  }
  fetchCommanderActivities() {
    this.loadingCommanderActivities = true;
    this.commanderProfileService
      .getCommanderActivityByRegion()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (res) => {
          this.commanderActivities = res;
          this.loadingCommanderActivities = false;
        },
        (error) => {
          this.loadingCommanderActivities = false;
          this.connectionError();
        }
      );
  }
  fetchDashboardStat() {
    this.loadDashboardStat = false;
    this.serviceAssignmentService
      .dashboardStat()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe((res) => {
        this.dashboardStatistics = res;
        this.loadDashboardStat = true;
      });
  }
  viewMore(serviceId: number) {
    this.dialogService.open(ViewContractServiceComponent, {
      header: "Contract Service",
      width: "80%",
      data: { serviceId },
    });
  }

  fetchAllVehicleServiceAssignmentDetails(): void {
    this.serviceAssignmentDetailService
      .allVehicleDetails()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            var res = r.responseData;
            //let veh = res.
            this.vehicleServiceAssignmentDetails = res;
            //console.log("detaa", this.vehicleServiceAssignmentDetails);
            this.loading = false;
          }
        },
        (error) => {
          this.loading = false;
          this.connectionError();
        }
      );
    //this.submittingData = false;
  }
  fetchAllVehicleServiceAssignmentDetailsByAssId(id): void {
    this.serviceAssignmentDetailService
      .allVehicleDetailsByAssignmentId(id)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            var res = r.responseData;
            this.vehicleServiceAssignmentDetails = res;
            // let vehArr = this.vehicleServiceAssignmentDetailsFilter.filter(
            //   (x) => x.isTemporarilyHeld === true
            // );
            // this.vehicleServiceAssignmentDetails = vehArr;
            this.loading = false;
          }
        },
        (error) => {
          this.loading = false;
          this.connectionError();
        }
      );
    //this.submittingData = false;
  }

showVehicleTrackDialog(masterServiceAssignment: MasterServiceAssignment){
  this.vehicleTrackingDialog = true;
  this.vehicleServiceAssignmentDetails = [];
  this.fetchAllVehicleServiceAssignmentDetailsByAssId(masterServiceAssignment.id);
}
  private connectionError() {
    this.messageService.add({
      severity: "error",
      summary: "Failed",
      detail: "Connection Error, Please try again",
    });
  }
  ngOnDestroy(): void {
    this.unsubscriber$.complete();
    this.unsubscriber$.unsubscribe();
  }

  formatStatus(assignmentStatus): string {
    return AssignmentStatusName[assignmentStatus];
  }
  filterBehindScheduleMasterService(): void {
    this.loading = true;
    this.serviceAssignmentServices
      .allServiceAssignments()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            var res = r.responseData;
            this.masterServiceAssignments = res;
            const _assignments = this.masterServiceAssignments;
            this.activeMasterServiceAssignments = [];
            const today = new Date();
            this.activeMasterServiceAssignments = _assignments.filter(
              (ass) =>
                today > new Date(ass.pickupDate) && ass.saExecutionStatus === 0
            );
            // this.activeMasterServCount =
            //   this.activeMasterServiceAssignments.length;
            //console.log("count" + this.activeMasterServCount);
            this.loading = false;
          }
        },
        (error) => {
          this.loading = false;
          this.connectionError();
        }
      );
    //const _assignments = this.activeMasterServiceAssignments;
    //this.activeFilterMasterServiceAssignments = [];

    this.loading = false;
  }

  filterOnTimeMasterService(): void {
    this.loading = true;
    this.serviceAssignmentServices
      .allServiceAssignments()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            var res = r.responseData;
            this.masterServiceAssignments = res;
            const _assignments = this.masterServiceAssignments;
            this.activeMasterServiceAssignments = [];
            const today = new Date();
            this.activeMasterServiceAssignments = _assignments.filter(
              (ass) =>
                new Date(ass.pickupDate) > today && ass.saExecutionStatus === 0
            );
            // this.activeMasterServCount =
            //   this.activeMasterServiceAssignments.length;
            //console.log("count" + this.activeMasterServCount);
            this.loading = false;
          }
        },
        (error) => {
          this.loading = false;
          this.connectionError();
        }
      );
    this.loading = false;
  }

  GetServiceAssignmentStatus(item: MasterServiceAssignment): string {
    const today = new Date();
    //var start = new Date(item.journeyStartDatetime);
    var pickUpdate = new Date(item.pickupDate);
    var saExecution = item.saExecutionStatus;
    if (saExecution === 1) {
      //this.getStatusColor = "p-badge p-badge-success";
      return "In Motion";
    }
    if (saExecution === 2) {
      //this.getStatusColor = "p-badge p-badge-success";
      return "Journey Ended";
    }
    if (saExecution === 3) {
      //this.getStatusColor = "p-badge p-badge-success";
      return "Journey Cancelled";
    }
    if (today > pickUpdate && saExecution === 0) {
      //this.getStatusColor = "p-badge p-badge-danger";
      return "Behind Schedule";
    }
    if (today < pickUpdate && saExecution === 0) {
      //this.getStatusColor = "p-badge p-badge-primary";
      return "On Time";
    }
    //if (today >= start && today <= pickUpdate) return "Active";
    return "Null";
  }
  filterService(status: AssignmentStatus) {
    this.loading = true;
    const _assignments = this.serviceAssignments;
    this.activeServiceAssignments = [];
    this.activeServiceAssignments = _assignments.filter(
      (ass) => ass.assignmentStatus === status
    );
    this.loading = false;
  }
  // getColour(item: MasterServiceAssignment): string {
  //   if (this.GetServiceAssignmentStatus(item) === "Behind Schedule") {
  //     return "p-badge p-badge-danger";
  //   }
  //   if (this.GetServiceAssignmentStatus(item) === "On Time") {
  //     return "p-badge p-badge-primary";
  //   }
  //   if (this.GetServiceAssignmentStatus(item) === "In Motion") {
  //     return "p-badge p-badge-success";
  //   } else {
  //     return "p-badge";
  //   }
  // }
}
