import { Injectable } from '@angular/core';
import {environment} from '../../environments/environment';
import {Observable} from 'rxjs';
import {CommonResponse} from '../interfaces/home';
import {HttpClient} from '@angular/common/http';
import { shareReplay } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class LeadOriginService {

  leadOriginsObservable: Observable<CommonResponse>;
  constructor(private http: HttpClient) { }
  baseUrl = environment.baseUrl+'LeadOrigin/';
  // fetch all LeadOrigin in the database
  allLeadOrigin(): Observable<CommonResponse>{
    if(this.leadOriginsObservable) return this.leadOriginsObservable
    else {
      this.leadOriginsObservable = this.http.get<CommonResponse>(this.baseUrl).pipe(shareReplay()); 
      return this.leadOriginsObservable;
    }
  }
  getLeadOrigin(id: any):Observable<CommonResponse>{
    return this.http.get<CommonResponse>(this.baseUrl +id);
  }
  getLeadOriginByName(name: any):Observable<CommonResponse>{
    return this.http.get<CommonResponse>(this.baseUrl + 'name/' + name);
  }
  postLeadOrigin(data: any){
    return this.http.post(this.baseUrl, data);
  }
  deleteLeadOrigin(id: any){
    return this.http.delete(this.baseUrl +id);
  }
  updateLeadOrigin(id: any, data: any){
    return this.http.put(this.baseUrl +id, data);
  }
}
