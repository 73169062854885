import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { Subscription } from 'rxjs';
import { BreadcrumbService } from 'src/app/breadcrumb.service';
import { BudgetapiservicesService } from 'src/app/services/budget/budgetapiservices.service';

interface User {
  code: number,
  name: string,
  headId: number
}

interface Category {
  id: number,
  name: string
}

interface BudgetApprovalSetup {
  id: number;
  categoryId?: number;
  approvingOfficeId?: number;
  approvingOffice?: ApprovingOffice;
  sequence: number;
  isDeleted: boolean;
  createdById: number;
  createdAt: Date;
}

interface ApprovingOffice {
  id: number;
  officeName: string;
  approvingOfficers: ApprovingOfficer[];
  isDeleted: boolean;
  createdById: number;
  createdAt: Date;
}

interface ApprovingOfficer {
  id: number;
  officerProfileId?: number;
  approvingOfficeId?: number;
  isDeleted: boolean;
  createdById: number;
  createdAt: Date;
}


@Component({
  selector: 'app-budget-approval-setup',
  templateUrl: './budget-approval-setup.component.html',
  styleUrls: ['./budget-approval-setup.component.css']
})
export class BudgetApprovalSetupComponent implements OnInit, OnDestroy {
  approvalSetupForm = this.fb.group({
    categories: ['', Validators.required],
    officeName: [{value: '', disabled: false}, Validators.required],
    officerName: [{value: '', disabled: false}],
    // region: ['', Validators.required],
    // allocationType: ['', Validators.required],
    // distributionSetting: ['', Validators.required],
    // totalValue: ['', Validators.required],
    // comment: ['']
  })
  // approvalSetupForm: FormGroup;
  // allServiceCategories: ServiceCategory[];
  // selectedServiceCategories: ServiceCategory[] = [];
 
  addedApprovingOfficers: any[]=[];
  // {
  //   userId: number;
  //   user: User;
  // }[] = [];
  selectedApprovingOfficer: any[]=[]
  // {
  //   userId: number;
  //   user: User;
  // }[] = [];
  fetchingApprovalSetups: boolean = true;
  allApprovalSetups: {
    budgetCategory: string;
    setups: BudgetApprovalSetup[];
  }[];
  selectedApprovalSetups: {
    serviceCategory: string;
    setups: BudgetApprovalSetup[];
  }[] = [];
  approvalSetupCols: any[];
  setupDetailsCols: any[];
  isEditing: boolean;
  officeEditing: ApprovingOffice;
  allApprovalBudgetSetups: BudgetApprovalSetup[] = [];

  users: User[];
  selectedUser: User
  user: User;
  categories: Category[]=[]
  selectedCategories: Category[]

  usersSubscription: Subscription | undefined;
  allBudgetCats: Subscription | undefined;
  allApprovingOfficesSub: Subscription | undefined;
  ApprovalSetupsSub: Subscription | undefined;


  constructor(private fb: FormBuilder,
              private apiService: BudgetapiservicesService,
              private messageService: MessageService,
              private confirmationService: ConfirmationService,
              public dialogService: DialogService,
              private router: Router,
              private route: ActivatedRoute,
              private breadcrumbService: BreadcrumbService) { }

  ngOnInit(): void {
    this.breadcrumbService.setItems([
      { label: "Budget Approver Setup", routerLink: ["home/budget--approval-setup"] },
      // { label: "Role", routerLink: ["home/role"] },
    ]);
    
    this.allBudgetCats = this.getBudgetDistrinutionCategories();
    // this.usersSubscription = this.loadUsers();
    // this.allApprovingOfficesSub = this.getAllApprovalOffices();
    // this.ApprovalSetupsSub = this.getBudgetApprovalSetups();
    // users = [
    //   {"code":1, "name":"Tunji Afolabu", "headId": 1},
    //   {"code":2, "name":"Ngozi Okafor", "headId": 1},
    //   {"code":3, "name":"Adamu Abubakar", "headId": 1},
    // ]

  }

  ngOnDestroy(): void {
    this.usersSubscription.unsubscribe();
    this.allBudgetCats.unsubscribe();
    this.allApprovingOfficesSub.unsubscribe();
    // this.ApprovalSetupsSub.unsubscribe();

  }

  displayMessage(severity, summary, message) {
    this.messageService.add({
      // key: 'sideNotification',
      severity: severity,
      summary: summary,
      detail: message,
    });
  }

  createApprovalSetup(){ 
    console.log('Approval created')
    this.displayMessage("info", "Information", "Creating Approval Setup");

    const postData = {
      'categoriesIds': this.selectedCategories.map((x) => x.id),
      'officeName': this.approvalSetupForm.get("officeName").value,
      'officersIds': this.addedApprovingOfficers.map((x) => x.userId),
      'where': "Approval"
    };
    console.log(postData)
    this.apiService.createApprovalSetup(postData).subscribe(
      async (data) => {
        if (data.responseCode != "00") {
          this.displayMessage("error", "Error", data.responseMsg)
          
          return;
        }

        this.closeEditingApprovalSetup();
        this.getAllApprovalOffices();
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.displayMessage("error", "Error", `Unable to create approval setups at the moment.. Reason: [${error ? error.error.message : " Request failed"}`)
        
      }
    );

  }

  loadUsers() {
    let param = "Individual"
    return this.apiService.getBudgetCatory(param).subscribe( res => {
      // this.users = res['responseData'].budgetDistCatList;
      if (res['responseCode'] == "00") {
        this.users = res['responseData'].budgetDistCatList;
        this.allApprovingOfficesSub = this.getAllApprovalOffices();
      }
      else {
        this.displayMessage("error", "Error", res['responseMsg']);
      }
      // this.usersCopy = [...this.users];
      // console.log(`Individual: ${JSON.stringify(this.users)}`);
    }); 
  }

  AddApprovingOfficerToList()
  {
    if (this.selectedUser == null) {
      this.displayMessage("error", "Stop", "You must select the officer to add")
      
      return;
    }

    let alreadyAdded = false;
    this.addedApprovingOfficers.forEach((item) => {
      if (item.userId == this.selectedUser.code) {
        alreadyAdded = true;
      }
    });

    if (alreadyAdded) {
      this.displayMessage("error", "Error", `Officer with the same info has already been added to list.`)
      return;
    }

    this.addedApprovingOfficers.push({
      'userId': this.selectedUser.code,
      'user': this.selectedUser,
    });

    this.selectedUser = null;
    this.displayMessage("success", "Success", "Approving Officer added successfully")
    
  }

  RemoveOfficer(item: any) {
    this.displayMessage("info", "Information", "Removing Approving Officer...")
    

    const index = this.addedApprovingOfficers.indexOf(item);
    if (index > -1) {
      this.addedApprovingOfficers.splice(index, 1);
    }
    this.displayMessage("success", "Success", "Approving Officer Removed successfully")
    
  }

  deleteApprovalOffice(item: BudgetApprovalSetup) {
    this.confirmationService.confirm({
      message: "Are you sure you want to remove expense approval setup?",
      accept: () => {
        this.displayMessage("success", "Success", "Removing expense approval setup...")
        this.messageService.add({
          severity: "info",
          summary: "Notice",
          detail: "Removing expense approval setup...",
        });

        this.apiService
          .deleteBudgetApprovalSetup(item.id, "Approval")
          .subscribe(
            async (data) => {
              if (data['responseCode'] != "00") {
                this.displayMessage("error", "Error", data['responseMsg'])
                // this.messageService.add({
                //   severity: "error",
                //   summary: "Notice",
                //   detail: data.responseMsg,
                // });
                return;
              }

              this.getAllApprovalOffices();
            },
            (error) => {
              console.log("Error: " + JSON.stringify(error));
              this.displayMessage("error", "Notice", 
                `Unable to remove expense approval setup at the moment.. Reason:${error ? error.message : 'Request Failes'}`)
              
            }
          );
      },
    });
  }

  moveOfficeSequence(upward: boolean, item: BudgetApprovalSetup) {
    this.confirmationService.confirm({
      message:
        "Are you sure you want to change budget approval setup sequence?",
      accept: () => {
        this.messageService.add({
          severity: "info",
          summary: "Notice",
          detail: "Updating budget approval setup sequence...",
        });

        const postData = {
          'isUpward': upward,
          'currentSequence': item.sequence,
          'categoryId': item.categoryId,
          'Where': "Approval"
        };

        this.apiService
          .moveApprovalOfficeSequence(item.id, postData)
          .subscribe(
            async (data) => {
              if (data.responseCode != "00") {
                this.messageService.add({
                  severity: "error",
                  summary: "Notice",
                  detail: data.responseMsg,
                });
                return;
              }

              this.getAllApprovalOffices();
            },
            (error) => {
              console.log("Error: " + JSON.stringify(error));
              this.messageService.add({
                severity: "error",
                summary: "Notice",
                detail:
                  "Unable to update expense approval setup sequence at the moment.. Reason: [" +
                  (error
                    ? error.error.message
                    : "request failed - permission") +
                  "]",
              });
            }
          );
      },
    });
  }

  getBudgetDistrinutionCategories() {
    return this.apiService.getAllDistributionCategories().subscribe(res => {
      console.log(res['responseData']['distributionCats']);
      if (res['responseCode'] == "00") {
        this.categories=res['responseData'].distributionCats;
        this.usersSubscription = this.loadUsers();
      }
      else {
        this.displayMessage("error", "Error", res['responseMsg']);
      }
        
    })
  }

  getBudgetApprovalSetups() {
    return this.apiService.getBudgetApprovalSetups().subscribe( res => {
      console.log(res['responseData']);
      console.log(res);
    })
  }

  getAllApprovalOffices() {
    this.fetchingApprovalSetups = true;
    return this.apiService.getBudgetApprovalSetups().subscribe(
      (data) => {
        if (data['responseCode'] == "00") {
          console.log("Success!!!!!!!!!!!!!")
          this.allApprovalBudgetSetups = data['responseData'];
          console.log(data['responseData']['categoryId'])
          console.log(this.allApprovalBudgetSetups);
          let groupedByCateg = [
            ...new Set(data['responseData'].map((req) => req.categoryId)),
          ];
          console.log(`Grouping by CatId ${groupedByCateg}`)
          this.allApprovalSetups = [];
          groupedByCateg.forEach((categId) => {
            let categModel = this.categories.find(
              (x) => x.id == categId
            );
            if (categModel) {
              console.log(`CateModel: ${JSON.stringify(categModel)}`)
              let approvalData: {
                budgetCategory: string;
                setups: BudgetApprovalSetup[];
              } = {
                budgetCategory: categModel.name,
                setups: data['responseData'].filter(
                  (x) => x.categoryId == categId
                ),
              };``

              this.allApprovalSetups.push(approvalData);
              console.log(`All Approval data:`)
              console.dir(this.allApprovalSetups);
            }
          });
          this.fetchingApprovalSetups = false;
        } else {
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail: data['responseMsg'],
          });
          this.fetchingApprovalSetups = false;
        }
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all expense approval setups at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
        this.fetchingApprovalSetups = false;
      }
    );
  }

  closeEditingApprovalSetup() {
    this.approvalSetupForm.reset();
    this.addedApprovingOfficers = [];
    this.selectedCategories = [];
    this.selectedApprovingOfficer = [];
    this.isEditing = false;
  }

  updateApprovalSetup() {
    this.displayMessage("info", "Information", "Updating Approval Office")

    const id = this.officeEditing.id;
    const postData = {
      'officersIds': this.addedApprovingOfficers.map((x) => x.userId),
      'Where': "Approval"
    };

    this.apiService
      .updateBudgetApprovalSetup(id, postData)
      .subscribe(
        async (data) => {
          if (data.responseCode != "00") {
            this.displayMessage("error", "Error", data.responseMsg);
            
            return;
          }
          this.displayMessage("success", "Success", "Approval Office Updated Successfully!")
          

          this.closeEditingApprovalSetup();
          this.getAllApprovalOffices();
        },
        (error) => {
          console.log("Error: " + JSON.stringify(error));
          this.displayMessage("error", "Error", `Unable to update approval setups at the moment.. Reason: [${error ? error.error.message : " Request failed"}`)
        }
      );
  }

  editApprovalOffice(item: BudgetApprovalSetup) {
    this.isEditing = true;
    console.log(`Item for editing: ${JSON.stringify(item)}`)
    this.approvalSetupForm.patchValue({
      officeName: item.approvingOffice.officeName,
    });
    let allCategOffices = this.allApprovalBudgetSetups.filter(
      (x) => x.approvingOfficeId == item.approvingOfficeId
    );
    let otherCategsIds = allCategOffices.map((x) => x.categoryId);
    otherCategsIds.push(item.categoryId);
    this.selectedCategories = this.categories.filter(
      (x) => otherCategsIds.find((y) => y == x.id) != null
    );
    this.officeEditing = item.approvingOffice;
    this.addedApprovingOfficers = [];
    item.approvingOffice.approvingOfficers
      .filter((x) => x.isDeleted == false)
      .forEach((x) => {
        this.addedApprovingOfficers.push({
          'userId': x.officerProfileId,
          'user': this.users.find((y) => y.code == x.officerProfileId),
        });
      });

    // this.formWrapper.nativeElement.scrollIntoView({
    //   behavior: "smooth",
    //   block: "end",
    //   inline: "start",
    // });
  }
  
}
