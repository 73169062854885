import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import {
  BRPairable,
  BusinessRule,
  ServiceRegistration,
} from "src/app/interfaces/armada";
import { CommonResponse, Services } from "src/app/interfaces/home";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class BusinessRuleService {
  baseUrl = environment.baseUrl;
  //baseUrl = "https://localhost:44344/api/v1/";
  constructor(private httpClient: HttpClient) {}

  allRules(): Observable<CommonResponse> {
    return this.httpClient.get<CommonResponse>(
      this.baseUrl + "BusinessRules/GetAllRules"
    );
  }
  allPairableRules(): Observable<CommonResponse> {
    return this.httpClient.get<CommonResponse>(
      this.baseUrl + "BusinessRules/GetAllPairableRules"
    );
  }

  allPurchasedAsPairs(): Observable<CommonResponse> {
    return this.httpClient.get<CommonResponse>(
      this.baseUrl + "BusinessRules/GetAllPurchaseAsPairs"
    );
  }

  getRule(id: any): Observable<CommonResponse> {
    return this.httpClient.get<CommonResponse>(
      this.baseUrl + "BusinessRules/GetRuleById/" + id
    );
  }
  getPurchaseById(id: any): Observable<CommonResponse> {
    return this.httpClient.get<CommonResponse>(
      this.baseUrl + "BusinessRules/GetPurchaseAsPairById/" + id
    );
  }
  postRule(data: any) {
    return this.httpClient.post(
      this.baseUrl + "BusinessRules/AddNewRule",
      data
    );
  }
  postPurchaseAsPair(data: any) {
    return this.httpClient.post(
      this.baseUrl + "BusinessRules/AddPurchaseAsPair",
      data
    );
  }
  deleteRule(id: any) {
    return this.httpClient.delete(
      this.baseUrl + "BusinessRules/DeleteRuleById/" + id
    );
  }
  deletePurchaseAsPair(id: any) {
    return this.httpClient.delete(
      this.baseUrl + "BusinessRules/DeletePurchaseAsPair/" + id
    );
  }
  updateRule(id: any, data: any) {
    return this.httpClient.put(
      this.baseUrl + "BusinessRules/UpdateRuleById/" + id,
      data
    );
  }

  allServices(): Observable<CommonResponse> {
    return this.httpClient.get<CommonResponse>(this.baseUrl + "Services");
    //return this.http.get<Approval[]>(this.baseUrl + 'GetUserPendingApprovals');
  }

  allRegisteredServices(): Observable<CommonResponse> {
    return this.httpClient.get<CommonResponse>(
      this.baseUrl + "ServiceRegistration/GetAllRegisteredServices"
    );
    //return this.http.get<Approval[]>(this.baseUrl + 'GetUserPendingApprovals');
  }

  //Pairables
  allPairabless(): Observable<CommonResponse> {
    return this.httpClient.get<CommonResponse>(
      this.baseUrl + "BusinessRules/GetAllPairables"
    );
  }

  allActivePairabless(): Observable<CommonResponse> {
    return this.httpClient.get<CommonResponse>(
      this.baseUrl + "BusinessRules/GetAllPairables"
    );
  }

  getPairable(id: any): Observable<CommonResponse> {
    return this.httpClient.get<CommonResponse>(
      this.baseUrl + "BusinessRules/GetPairableById/" + id
    );
  }

  postPairable(data: any) {
    return this.httpClient.post(
      this.baseUrl + "BusinessRules/AddNewPairable",
      data
    );
  }
  deletePairable(id: any) {
    return this.httpClient.delete(
      this.baseUrl + "BusinessRules/DeletePairableById/" + id
    );
  }
  updatePairable(id: any, data: any[]) {
    return this.httpClient.put(
      this.baseUrl + "BusinessRules/UpdatePairableById/" + id,
      data
    );
  }
}
