import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { BreadcrumbService } from 'src/app/breadcrumb.service';
import { CommanderType } from 'src/app/interfaces/armada';
import { CommanderTypeService } from 'src/app/services/armada/commander-type.service';

@Component({
  selector: 'app-commander-type',
  templateUrl: './commander-type.component.html',
  styleUrls: ['./commander-type.component.scss']
})
export class CommanderTypeComponent implements OnInit, OnDestroy {
  @ViewChild('formWrapper') public formWrapper:ElementRef;
  private unsubscriber$ = new Subject<void>();
  public commanderTypes: CommanderType[];
  public targetCols: any;
  private exportTargetColumns: any;
  public loading: boolean;
  public CommanderTypeForm: FormGroup;
  public submittingData: boolean;
  selectedCommanderType: CommanderType;
  editingCommanderType= false;
  private edCommanderType: CommanderType;
  submitButtonLabel: string;

  constructor(
    private breadcrumbService: BreadcrumbService,
      private commanderTypeService: CommanderTypeService,
      public messageService: MessageService,
      public confirmationService: ConfirmationService,
      private formBuilder: FormBuilder,
  ) { 
    this.breadcrumbService.setItems([
      { label: 'Setup' },
      { label: 'Commander Type', routerLink: ['/setup/commander-type'] }
    ]);
  }
 

  ngOnInit(): void {
    this.loading = true;
    this.targetCols = [
      { field: 'typename', header: 'Name' },
      { field: 'typeDesc', header: 'Description' },
      //{ field: 'description', header: 'Description' },
    ];
    this.exportTargetColumns = this.targetCols.map(col => ({title: col.header, dataKey: col.field}));
    this.CommanderTypeForm = this.formBuilder.group( {
      typeName: ['', [Validators.required, Validators.minLength(3)]],
      typeDesc: ['', [Validators.required, Validators.minLength(3)]],
    })
    this.fetchAllCommanderType();
    this.submitButtonLabel = 'create';
  }

  fetchAllCommanderType(): void {
    this.commanderTypeService.allCommanderType().pipe(takeUntil(this.unsubscriber$)).subscribe( (res: any) => {
      this.commanderTypes = res;
      this.loading = false;
    });
  }

  createCommanderType() {
    this.submittingData = true;
    this.commanderTypeService.postCommanderType(this.CommanderTypeForm.value).pipe(takeUntil(this.unsubscriber$))
        .subscribe( (result) => {
          this.messageService.add({
            summary: 'Success', severity: 'success', detail: 'New Commander Type created',
          });
          this.fetchAllCommanderType();
          this.submittingData = false;
          this.CommanderTypeForm.reset();
        })
  }
  deleteCommanderType(commanderType: CommanderType): void {
    this.confirmationService.confirm({
      message: 'Are you sure you want to delete ' + commanderType.typeName,
      accept: () => {
        this._deleteCommanderType(commanderType);
      }
    });
  }
  editCommanderType(commanderType: CommanderType): void{
    this.formWrapper.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'start' });
    this.editingCommanderType = true;
    this.submitButtonLabel = 'Update';
    this.edCommanderType = commanderType;
    this.CommanderTypeForm.setValue({
      typeName: commanderType.typeName,
      typeDesc: commanderType.typeDesc,
    });
  }

  _deleteCommanderType(commanderType) {
    this.messageService.add({severity:'info', summary: 'Notice',
      detail:'Deleting Armed Escort Type record'});
    this.commanderTypeService.delete(commanderType.id).subscribe( async (result:any) => {
      await this.messageService.add({severity:'success', summary: 'Deleted',
        detail:'Armed Escort Type record removed'});
      await this.fetchAllCommanderType();
    }, error => {
      this.connectionError();
    });
  }
  closeEditing() {
    this.editingCommanderType = false;
    this.CommanderTypeForm.reset();
    this.edCommanderType = null;
    this.submitButtonLabel = 'Create';
  }
  updateCommanderType(): boolean {
    this.submittingData = true;
    if(!this.edCommanderType) {
      return false;
    }
    this.commanderTypeService.updateCommanderType(this.edCommanderType.id, this.CommanderTypeForm.value)
        .pipe(takeUntil(this.unsubscriber$))
        .subscribe(() => {
          this.messageService.add({
            severity: 'success',
            detail: 'Commander Type Updated',
            summary: 'Completed'
          });
          this.submittingData = false;
          this.closeEditing();
          this.fetchAllCommanderType();
        }, error => {
          this.connectionError();
          this.submittingData = false;
        })
  }
  private connectionError() {
    this.messageService.add({severity:'error', summary: 'Failed',
      detail:'Connection Error, Please try again'});
  }

  ngOnDestroy(): void {
    this.unsubscriber$.complete();
    this.unsubscriber$.unsubscribe();
    //throw new Error('Method not implemented.');
  }

}
