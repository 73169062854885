import { Cluster } from "./../../../interfaces/employment";
import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { ConfirmationService, Message, MessageService } from "primeng/api";
import { BreadcrumbService } from "src/app/breadcrumb.service";
import { PayrollService } from "src/app/services/payroll.service";
import {
  ActOnOPAbsenceReportVM,
  OperativeAbsenceReasonEnum,
  OperativesAbsent,
} from "src/app/interfaces/payroll";
import { UIChart } from "primeng/chart";

@Component({
  selector: "app-payroll-absence-app",
  templateUrl: "./payroll-absence-app.component.html",
  styleUrls: ["./payroll-absence-app.component.scss"],
  providers: [MessageService],
})
export class PayrollAbsenceAppComponent implements OnInit {
  @ViewChild("chart") chart: UIChart;
  @ViewChild("barChart") barChart: UIChart;
  @ViewChild("formWrapper") public formWrapper: ElementRef;
  msg: Message[] = [];
  carouselResponsiveOptions: any[] = [
    {
      breakpoint: "1024px",
      numVisible: 3,
      numScroll: 3,
    },
    {
      breakpoint: "768px",
      numVisible: 2,
      numScroll: 2,
    },
    {
      breakpoint: "560px",
      numVisible: 1,
      numScroll: 1,
    },
  ];
  allClustersAwaitingApproval: Cluster[] = [];
  allClusterPendingRequests: OperativesAbsent[] = [];
  selectedClusterPendingRequest: OperativesAbsent[] = [];
  cols: any[];
  public data: {
    datasets: {
      backgroundColor: string[];
      data: number[];
      hoverBackgroundColor: string[];
    }[];
    labels: string[];
  };
  pieSummary: {
    title: string;
    short: string;
    count: number;
  }[];
  barData: {
    datasets: {
      label: string;
      backgroundColor: string;
      borderColor: string;
      data: number[];
    }[];
    labels: string[];
  };
  totalPendingRequestsCount: number = 0;
  loggedInUserName: string;
  loggedInUserImg: string;
  loggedInUserId: number;
  clusterInView: Cluster;
  openCommentDialogue: boolean;
  commentToView: string;

  constructor(
    private fb: FormBuilder,
    private payrollService: PayrollService,
    private breadcrumbService: BreadcrumbService,
    public confirmationService: ConfirmationService,
    public messageService: MessageService
  ) {}

  ngOnInit(): void {
    this.breadcrumbService.setItems([
      {
        label: "Payroll Management",
        routerLink: ["/home/payroll/payroll-settings"],
      },
      {
        label: "Station Absence Approval",
        routerLink: ["/home/payroll/station-absence-app"],
      },
    ]);

    this.cols = [
      { field: "location", header: "Location" },
      {
        field: "jobApplication.personalInformation.lastname",
        header: "JobApplication.personalInformation.lastname",
      },
      {
        field: "jobApplication.personalInformation.firstname",
        header: "JobApplication.personalInformation.firstname",
      },
      { field: "dateAbsent", header: "DateAbsent" },
    ];

    this.data = {
      labels: ["C1", "C2", "C3", "C4"],
      datasets: [
        {
          data: [52, 16, 16, 16],
          backgroundColor: ["green", "#36A2EB", "#FFCE56", "#FF6384"],
          hoverBackgroundColor: ["green", "#36A2EB", "#FFCE56", "#FF6384"],
        },
      ],
    };

    this.barData = {
      labels: ["Bayo", "Felicia", "Ahmed", "Josh"],
      datasets: [
        {
          label: "Absence Stats",
          backgroundColor: "blue",
          borderColor: "blue",
          data: [65, 31, 23, 32],
        },
      ],
    };

    this.pieSummary = [
      {
        title: "Cluster 1",
        short: "C1",
        count: 52,
      },
    ];

    this.data.labels = [];
    this.data.datasets[0].data = [];
    this.data.datasets[0].backgroundColor = [];
    this.data.datasets[0].hoverBackgroundColor = [];
    this.pieSummary = [];
    this.barData.labels = [];
    this.barData.datasets[0].data = [];

    this.ResetMessageToasters();
    this.GetLoggedInUserInfo();
    this.FetchAllClusters();
    this.FetchChartsStatistics();
  }

  ResetMessageToasters() {
    this.msg = [];
    if (this.totalPendingRequestsCount) {
      this.msg.push({
        severity: "info",
        summary: "Notice:",
        detail:
          "Total of " +
          this.totalPendingRequestsCount +
          " requests from " +
          this.allClustersAwaitingApproval.length +
          " Clusters awaiting approval.",
      });
    } else {
      this.msg.push({
        severity: "info",
        summary: "Notice:",
        detail:
          "You are currently managing a total  " +
          this.allClustersAwaitingApproval.length +
          " Clusters.",
      });
    }
  }

  FetchAllClusters() {
    this.payrollService.GetAllClustersInMyZone().subscribe(
      async (data) => {
        this.allClustersAwaitingApproval = [];
        data.forEach((x) => {
          x.clusters.forEach((y) => this.allClustersAwaitingApproval.push(y));
        });
        this.ResetMessageToasters();
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable get all clusters at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
        this.ResetMessageToasters();
      }
    );
  }

  FetchChartsStatistics() {
    this.payrollService.GetAbsenceReportStatistics().subscribe(
      async (statsData) => {
        this.data.labels = [];
        this.data.datasets[0].data = [];
        this.data.datasets[0].backgroundColor = [];
        this.data.datasets[0].hoverBackgroundColor = [];
        this.pieSummary = [];
        this.barData.labels = [];
        this.barData.datasets[0].data = [];
        let countCluster: number = 0;
        statsData.pieChartsData.forEach((chartData) => {
          countCluster++;

          this.data.labels.push("C" + countCluster);
          this.data.datasets[0].data.push(chartData.percentage);
          this.data.datasets[0].backgroundColor.push(chartData.color);
          this.data.datasets[0].hoverBackgroundColor.push(chartData.color);
          this.pieSummary.push({
            title: chartData.label,
            short: "C" + countCluster,
            count: chartData.percentage,
          });
        });

        statsData.barChartsData.forEach((chartData) => {
          this.barData.labels.push(chartData.label);
          this.barData.datasets[0].data.push(chartData.percentage);
        });

        this.chart.reinit();
        this.barChart.reinit();
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable get statistics at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
        this.ResetMessageToasters();
      }
    );
  }

  GetLoggedInUserInfo() {
    this.payrollService.GetCurrentlyLoggedInUserDetails().subscribe(
      async (data) => {
        this.loggedInUserName = data.lastName + " " + data.firstName;
        this.loggedInUserImg = data.imageUrl;
        this.loggedInUserId = data.id;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable get logged in user info at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
        this.ResetMessageToasters();
      }
    );
  }

  ViewClusterPendingAbsenceRequests(item: Cluster) {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Getting All Pending Approvals Under Cluster...",
    });
    this.ResetMessageToasters();

    this.clusterInView = null;
    this.allClusterPendingRequests = [];
    this.selectedClusterPendingRequest = [];
    this.payrollService.GetAllPendingOPAbsentReportInCluster(item.id).subscribe(
      async (data) => {
        this.allClusterPendingRequests = data;
        this.clusterInView = item;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to get all pending approvals under cluster at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
        this.ResetMessageToasters();
      }
    );
  }

  GetReasonLabel(identifier: number): string {
    if (identifier == OperativeAbsenceReasonEnum.Training) return "Training";
    if (identifier == OperativeAbsenceReasonEnum.Absendant) return "Absendant";
    return "N/A";
  }

  ViewReason(item: OperativesAbsent) {
    this.commentToView = item.comment;
    this.openCommentDialogue = true;
  }

  DeclineSelectedReports() {
    this.confirmationService.confirm({
      message:
        "Are you sure you want decline selected absence report. This is an irreversible action. Do you still wish to proceed?",
      accept: () => {
        this.messageService.add({
          severity: "info",
          summary: "Notice",
          detail: "Declining Selected Absence Report...",
        });
        this.ResetMessageToasters();

        const postData: ActOnOPAbsenceReportVM = {
          statusToAssign: 3,
          oPAbsenceReportIds: this.selectedClusterPendingRequest.map(
            (x) => x.id
          ),
        };

        this.payrollService.ActOnOPAbsenceReport(postData).subscribe(
          async () => {
            this.messageService.add({
              severity: "success",
              summary: "Completed",
              detail:
                "Selected Operative Absence Report(s) Declined Successfully!",
            });
            this.ResetMessageToasters();

            this.selectedClusterPendingRequest.forEach((x) => {
              const index = this.allClusterPendingRequests.indexOf(x);
              if (index > -1) {
                this.allClusterPendingRequests.splice(index, 1);
              }
            });
            this.selectedClusterPendingRequest = [];
          },
          (error) => {
            console.log("Error: " + JSON.stringify(error));
            this.messageService.add({
              severity: "error",
              summary: "Notice",
              detail:
                "Unable to decline selected absence report(s) at the moment.. Reason: [" +
                error
                  ? error.error.message
                  : "request failed - permission" + "]",
            });
            this.ResetMessageToasters();
          }
        );
      },
    });
  }

  ApproveSelectedReports() {
    this.confirmationService.confirm({
      message:
        "Are you sure you want approve selected absence report. This is an irreversible action. Do you still wish to proceed?",
      accept: () => {
        this.messageService.add({
          severity: "info",
          summary: "Notice",
          detail: "Approving Selected Absence Report...",
        });
        this.ResetMessageToasters();

        const postData: ActOnOPAbsenceReportVM = {
          statusToAssign: 2,
          oPAbsenceReportIds: this.selectedClusterPendingRequest.map(
            (x) => x.id
          ),
        };

        this.payrollService.ActOnOPAbsenceReport(postData).subscribe(
          async () => {
            this.messageService.add({
              severity: "success",
              summary: "Completed",
              detail:
                "Selected Operative Absence Report(s) Approved Successfully!",
            });
            this.ResetMessageToasters();

            this.selectedClusterPendingRequest.forEach((x) => {
              const index = this.allClusterPendingRequests.indexOf(x);
              if (index > -1) {
                this.allClusterPendingRequests.splice(index, 1);
              }
            });
            this.selectedClusterPendingRequest = [];
          },
          (error) => {
            console.log("Error: " + JSON.stringify(error));
            this.messageService.add({
              severity: "error",
              summary: "Notice",
              detail:
                "Unable to approve selected absence report(s) at the moment.. Reason: [" +
                error
                  ? error.error.message
                  : "request failed - permission" + "]",
            });
            this.ResetMessageToasters();
          }
        );
      },
    });
  }

  HideDialog() {
    this.openCommentDialogue = false;
    this.commentToView = null;
  }
}
