import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {Observable} from 'rxjs';
import {CommonResponse} from '../interfaces/home';

@Injectable({
  providedIn: 'root'
})
export class QuoteServicesService {
  constructor(private http: HttpClient) {
  }
  baseUrl = environment.baseUrl + 'QuoteService/';
  // fetch all QuoteServices in the database
  allQuoteServices(): Observable<CommonResponse> {
    return this.http.get<CommonResponse>(this.baseUrl);
  }
  getQuoteServices(id: any): Observable<CommonResponse> {
    return this.http.get<CommonResponse>(this.baseUrl + id);
  }

  getQuoteServicesByName(name: any): Observable<CommonResponse> {
    return this.http.get<CommonResponse>(this.baseUrl + 'name/' + name);
  }

  getQuoteServicesByTag(tag: any): Observable<CommonResponse> {
    return this.http.get<CommonResponse>(this.baseUrl + 'ByTag/' + tag);
  }

  postQuoteServices(data: any) {
    return this.http.post(this.baseUrl, data);
  }

  deleteQuoteServices(id: any) {
    return this.http.delete(this.baseUrl + id);
  }

  updateQuoteServices(id: any, data: any) {
    return this.http.put(this.baseUrl + id, data);
  }
}
