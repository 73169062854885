import { CommonResponse, User } from "src/app/interfaces/home";
import { UserService } from "src/app/services/user.service";
import { CustomerDivision } from "./../../interfaces/home";
import { CustomerDivisionService } from "./../../services/customer-division.service";
import { Supplier } from "src/app/interfaces/supplier";
import { SupplierService } from "src/app/services/supplier.service";
import { ComplaintSourceService } from "./../../services/complaint-source.service";
import { ComplaintSource } from "./../../interfaces/complaint-source";
import { ComplaintOriginService } from "./../../services/complaint-origin.service";
import { ComplaintOrigin } from "./../../interfaces/complaint-origin";
import { BreadcrumbService } from "src/app/breadcrumb.service";
import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MessageService, ConfirmationService } from "primeng/api";
import { Complaint, ComplaintStats } from "src/app/interfaces/complaint";
import { ComplaintService } from "src/app/services/complaint.service";
import { ComplaintTypeService } from "src/app/services/complaint-type.service";
import { ComplaintType } from "src/app/interfaces/complaint-type";
import { FileStorageService } from "src/app/services/file-storage.service";

@Component({
  selector: "app-register-complaints",
  templateUrl: "./register-complaints.component.html",
  styleUrls: ["./register-complaints.component.scss"],
})
export class RegisterComplaintsComponent implements OnInit {
  @ViewChild("formWrapper") public formWrapper: ElementRef;
  form: FormGroup;
  editing = false;
  fetching = true;
  complaints: Complaint[];
  selectedComplaint: Complaint;
  complaintTypes: ComplaintType[];
  theComplaintType: ComplaintType;
  complaintOrigins: ComplaintOrigin[];
  theComplaintOrigin: ComplaintOrigin;
  suppliers: Supplier[];
  theSupplier: Supplier;
  clients: CustomerDivision[];
  theClient: CustomerDivision;
  users: User[];
  theUser: User;
  pieSummary: any[];
  itsUserComplaining: boolean;
  itsSupplierComplaining: boolean;
  itsClientComplaining: boolean;
  complaintToDisplay: Complaint;
  userIsYetToClickUpload: boolean;
  tempComplaintOrigins = [
    {
      caption: "Client",
    },
    {
      caption: "Supplier",
    },
    {
      caption: "Staff",
    },
  ];
  theTempComplaintOrigin: any;
  complaintSources: ComplaintSource[];
  theComplaintSource: ComplaintSource;
  cols: any[];
  complaintStats: ComplaintStats;
  private complaintToEdit: Complaint;
  public data: {
    datasets: {
      backgroundColor: string[];
      data: number[];
      hoverBackgroundColor: string[];
    }[];
    labels: string[];
  };
  uploadedEvidences: any[] = [];
  openDialogue: boolean;
  showLeftGrid: boolean = true;

  constructor(
    fb: FormBuilder,
    public messageService: MessageService,
    public complaintService: ComplaintService,
    public complaintTypeService: ComplaintTypeService,
    public complaintOriginService: ComplaintOriginService,
    public complaintSourceService: ComplaintSourceService,
    public supplierService: SupplierService,
    public clientService: CustomerDivisionService,
    public userService: UserService,
    public confirmationService: ConfirmationService,
    public breadcrumbService: BreadcrumbService,
    public fileStorageService: FileStorageService
  ) {
    this.form = fb.group({
      ComplaintType: [""],
      ComplaintOrigin: ["", Validators.required],
      ComplaintSource: ["", Validators.required],
      Complainant: ["", Validators.required],
      ComplaintDescription: ["", Validators.required],
      DateCreated: ["", Validators.required],
      Evidences: [""],
    });
  }

  ngOnInit(): void {
    this.breadcrumbService.setItems([
      { label: "Complaint Management" },
      {
        label: "Register Complaint",
        routerLink: ["home/complaint-management/register-complaint"],
      },
    ]);

    this.cols = [
      { field: "complaintType", header: "ComplaintType" },
      { field: "complaintOrigin", header: "ComplaintOrigin" },
      { field: "complaintSource", header: "ComplaintSource" },
      { field: "complainant", header: "Complainant" },
      { field: "complaintDescription", header: "ComplaintDescription" },
    ];

    this.users = [];
    this.FetchAllComplaintOrigins();
    this.FetchAllComplaintTypes();
    this.FetchAllComplaintSources();
    this.FetchAllSuppliers();
    this.FetchAllClients();
    this.FetchAllUsers();
    this.GetComplaintStats();
    this.FetchAllComplaints();
  }

  async FetchAllComplaints() {
    this.complaintService.getAllComplaints().subscribe(
      async (comp) => {
        this.complaints = comp.responseData;
        this.fetching = false;
      },
      () => {
        this.fetching = false;
        this.ConnectionError();
      }
    );
  }

  // async FetchAllComplaints() {
  //   this.complaintService.getAll().subscribe(
  //     async (comp) => {
  //       this.complaints = comp.responseData;
  //       this.fetching = false;
  //     },
  //     () => {
  //       this.fetching = false;
  //       this.ConnectionError();
  //     }
  //   );
  // }

  async FetchAllComplaintTypes() {
    this.complaintTypeService.getAll().subscribe(
      async (compType) => {
        this.complaintTypes = compType.responseData;
      },
      () => {
        this.ConnectionError();
      }
    );
  }

  async FetchAllComplaintOrigins() {
    this.complaintOriginService.getAll().subscribe(
      async (compOrigin) => {
        this.complaintOrigins = compOrigin.responseData;
      },
      () => {
        this.ConnectionError();
      }
    );
  }

  async FetchAllComplaintSources() {
    this.complaintSourceService.getAll().subscribe(
      async (compSource) => {
        this.complaintSources = compSource.responseData;
      },
      () => {
        this.ConnectionError();
      }
    );
  }

  async FetchAllSuppliers() {
    this.supplierService.getAll().subscribe(
      async (data) => {
        this.suppliers = data.responseData;
      },
      () => {
        this.ConnectionError();
      }
    );
  }

  async FetchAllClients() {
    this.clientService.allCustomerDivision().subscribe(
      async (data) => {
        this.clients = data.responseData;
      },
      () => {
        this.ConnectionError();
      }
    );
  }

  async FetchAllUsers() {
    this.userService.allUser().subscribe(
      async (res) => {
        // if (res.responseData != "00") {
        //   this.ConnectionError(res.responseMsg);
        //   return;
        // }
        var data = res.responseData;
        data.forEach((user) => {
          user.fullName = user.lastName + " " + user.firstName;
          this.users.push(user);
        });
        //this.users = data;
      },
      (err) => {
        console.log(err);
        this.ConnectionError(err);
      }
    );
  }

  GetComplaintStats() {
    this.complaintService.getComplaintStats().subscribe(
      async (response) => {
        if (response.responseCode != "00") {
          return;
        }

        this.complaintStats = response.responseData;

        if (this.complaintStats != undefined) {
          this.data = {
            labels: ["A", "B", "C", "D", "E"],
            datasets: [
              {
                data: [
                  this.complaintStats.resgisteredPercentage,
                  this.complaintStats.investigatedPercentage,
                  this.complaintStats.assesedPercentage,
                  this.complaintStats.closedPercentage,
                  this.complaintStats.resolvedPercentage,
                ],
                backgroundColor: [
                  "red",
                  "#36A2EB",
                  "#FFCE56",
                  "#FF6384",
                  "green",
                ],
                hoverBackgroundColor: [
                  "red",
                  "#36A2EB",
                  "#FFCE56",
                  "#FF6384",
                  "green",
                ],
              },
            ],
          };

          this.pieSummary = [
            {
              label: "A.",
              title: "Registered",
              short: "RG",
              count: this.complaintStats.resgisteredPercentage,
            },
            {
              label: "B.",
              title: "Under Investigation",
              short: "I",
              count: this.complaintStats.investigatedPercentage,
            },
            {
              label: "C.",
              title: "Assessed",
              short: "A",
              count: this.complaintStats.assesedPercentage,
            },
            {
              label: "D.",
              title: "Closed",
              short: "C",
              count: this.complaintStats.closedPercentage,
            },
            {
              label: "E.",
              title: "Resolved",
              short: "RS",
              count: this.complaintStats.resolvedPercentage,
            },
          ];
        }
      },
      (error) => {
        this.ConnectionError(error);
      }
    );
  }

  async RegisterComplaint() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Registering Complaint...",
    });

    let theComplainantID = 0;
    if (this.theComplaintOrigin.caption.toLowerCase() == "supplier") {
      theComplainantID = this.theSupplier.id;
    } else if (this.theComplaintOrigin.caption.toLowerCase() == "client") {
      theComplainantID = this.theClient.id;
    } else if (this.theComplaintOrigin.caption.toLowerCase() == "staff") {
      theComplainantID = this.theUser.id;
    }

    const postData: Complaint = {
      complaintTypeId: this.theComplaintType.id,
      complaintOriginId: this.theComplaintOrigin.id,
      complaintSourceId: this.theComplaintSource.id,
      complainantId: theComplainantID,
      complaintDescription: this.form.get("ComplaintDescription").value,
      dateCreated: this.form.get("DateCreated").value,
      evidences: [],
    };

    if (this.uploadedEvidences.length > 0) {
      this.fileStorageService.UploadMultipleFilesFromDataUrl(
        this.uploadedEvidences
      );
      this.fileStorageService.onUploadFinished.subscribe(
        async (resp: CommonResponse<string[]>) => {
          if (resp.responseCode != "00") {
            this.messageService.add({
              severity: "error",
              summary: "Notice",
              detail: resp.responseMsg,
            });
          } else {
            resp.responseData.forEach((url) =>
              postData.evidences.push({
                caption: null,
                imageUrl: url,
                complaintId: 0,
                complaintStage: 1,
              })
            );
            this._registerComplaint(postData);
          }
        },
        (error) => {
          console.log("Error while uploading files " + error);
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail: "ERR: Unable to upload image to storage",
          });
        }
      );
    } else {
      this._registerComplaint(postData);
    }
  }

  _registerComplaint(postData: Complaint) {
    this.complaintService.create(postData).subscribe(
      async (data) => {
        if (data.responseCode != "00") {
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail: data.responseMsg,
          });
          return;
        }

        await this.messageService.add({
          severity: "success",
          summary: "Completed",
          detail: "Complaints registered successfully!",
        });

        this.GetComplaintStats();
        this.FetchAllComplaints();
        this.showLeftGrid = false;
        this.form.reset();
        this.uploadedEvidences = [];
        await new Promise((resolve) => setTimeout(resolve, 2000)); // 2 secs
        this.showLeftGrid = true;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to register complaint at the moment.. Reason: [" +
            JSON.stringify(error) +
            "]",
        });
      }
    );
  }

  displayComplaintDropdown() {
    if (this.theComplaintOrigin.caption.toLowerCase() == "supplier") {
      this.itsSupplierComplaining = true;
      this.itsUserComplaining = false;
      this.itsClientComplaining = false;
    } else if (this.theComplaintOrigin.caption.toLowerCase() == "client") {
      this.itsSupplierComplaining = false;
      this.itsUserComplaining = false;
      this.itsClientComplaining = true;
    } else if (this.theComplaintOrigin.caption.toLowerCase() == "staff") {
      this.itsSupplierComplaining = false;
      this.itsUserComplaining = true;
      this.itsClientComplaining = false;
    }
  }

  onUpload(event) {
    this.uploadedEvidences = [];
    for (const file of event.files) {
      this.uploadedEvidences.push(file);
    }

    this.messageService.add({
      severity: "success",
      summary: "Success",
      detail: "Evidence(s) Uploaded",
    });

    this.userIsYetToClickUpload = false;
  }

  NotifyUserToClickUpload() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Kindly ensure you click upload, to upload the evidence(s).",
    });

    this.userIsYetToClickUpload = true;
  }

  onCancelUpload() {
    this.uploadedEvidences = [];
  }

  ShowComplaintDetails(item: Complaint) {
    this.openDialogue = true;
    this.complaintToDisplay = item;
  }

  GetComplainantName(item: Complaint) {
    return item.complainant == null
      ? "No Data"
      : item.complainant.supplierName
      ? item.complainant.supplierName
      : item.complainant.divisionName
      ? item.complainant.divisionName
      : item.complainant.lastName;
  }

  HideDialog() {
    this.openDialogue = false;
    this.complaintToDisplay = null;
  }

  CloseEditing() {
    this.editing = false;
    this.form.reset();
    this.complaintToEdit = null;
  }

  private ConnectionError(error?: any) {
    this.messageService.add({
      severity: "error",
      summary: "Failed",
      detail: error ?? "Some errors occurred",
    });
  }
}
