import { Component, OnInit } from "@angular/core";
import { DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { EventInput } from "@fullcalendar/core";
import { MessageService } from "primeng/api";
import { FileStorageService } from "src/app/services/file-storage.service";
import { CommonResponse } from "src/app/interfaces/home";

@Component({
  selector: "app-lead-progress-document",
  templateUrl: "./lead-progress-document.component.html",
  styleUrls: ["./lead-progress-document.component.scss"],
})
export class LeadProgressDocumentComponent implements OnInit {
  displayLoading: boolean;
  message: any;
  leadProgressForm: FormGroup;
  progressMessage: string | null;
  public dialogData: any | null;
  private documentFIle: any[] = [];
  private imageSrc: string;
  private documentDownloadURL: any;
  constructor(
    private dialogConfig: DynamicDialogConfig,
    private dynamicDialogRef: DynamicDialogRef,
    private formBuilder: FormBuilder,
    private messageService: MessageService,
    private fileStorageService: FileStorageService
  ) {}

  ngOnInit(): void {
    this.dialogData = this.dialogConfig.data;
    this.progressMessage = this.dialogData.message;
    this.leadProgressForm = this.formBuilder.group({
      documentUrl: ["", Validators.required],
    });
  }

  submitLeadInfo() {
    if (this.leadProgressForm.value) {
      // check if picture is selected
      this.displayLoading = true;
      if (this.documentFIle) {
        if (this.documentFIle.length > 0) {
          this.fileStorageService.UploadMultipleFilesFromDataUrl(
            this.documentFIle
          );

          this.fileStorageService.onUploadFinished.subscribe(
            (resp: CommonResponse<string[]>) => {
              if (resp.responseCode != "00") {
                this.messageService.add({
                  severity: "error",
                  summary: "Notice",
                  detail: resp.responseMsg,
                });
              } else {
                var imageUrl = resp.responseData;
                this.documentDownloadURL = imageUrl[0];
                this.dynamicDialogRef.close({
                  status: true,
                  documentUrl: imageUrl[0],
                });
              }
            },
            (error) => {
              console.log("Error while creating platform " + error);
              this.messageService.add({
                severity: "error",
                summary: "Notice",
                detail: "ERR: Unable to upload image to storage",
              });
            }
          );
        }
      }
    }
  }

  closeDialog(state?: any) {
    this.dynamicDialogRef.close({ status: false, documentUrl: null });
  }
  onFileSelect(event) {
    if (!this.checkMimeType(event) || !this.checkFileSize(event)) {
      this.leadProgressForm.get("documentUrl").reset();
      return;
    }
    const reader = new FileReader();
    this.message = null;
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      this.documentFIle.push(event.target.files[0]);
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.imageSrc = reader.result as string;
      };
    }
  }

  checkMimeType(event) {
    const files = event.target.files[0];
    // list allow mime type
    const types = [
      "application/msword",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      "application/pdf",
    ];
    // loop access array
    if (!files) {
      this.messageService.add({
        severity: "error",
        summary: "Failed",
        detail: "Please select a file to continue",
      });
      this.message = {
        type: "danger",
        value: "Please select a file to continue",
      };
      return false;
    }
    // compare file type find doesn't match
    if (types.every((type) => files.type !== type)) {
      // create error message and assign to container
      this.messageService.add({
        severity: "error",
        summary: "Failed",
        detail: "Document format not supported",
      });
      this.message = { type: "danger", value: "Document format not supported" };
      return false;
    }
    return true;
  }

  checkFileSize(event: EventInput) {
    const files = event.target.files[0];
    if (!files) return true;
    const size = 1500000;
    if (files.size > size) {
      event.target.value = null;
      this.messageService.add({
        severity: "error",
        summary: "Failed",
        detail: "Document is too large, please pick a smaller file",
      });
      this.message = {
        type: "danger",
        value: "Document is too large, please pick a smaller file",
      };
      return false;
    }
    return true;
  }
}
