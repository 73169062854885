import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { LeadService } from "../../../services/lead.service";
import {
  CommonResponse,
  GeneralService,
  Lead,
  LeadDivision,
  QuoteServiceDocument,
  QuoteServices,
  RequiredServiceDocument,
  Services,
} from "../../../interfaces/home";
import { ServicesService } from "../../../services/services.service";
import { DialogService, DynamicDialogRef } from "primeng/dynamicdialog";
import { ServiceDocumentComponent } from "./service-document/service-document.component";
import { QuoteServiceDocumentService } from "../../../services/quote-service-document.service";
import { MessageService } from "primeng/api";
import { BreadcrumbService } from "src/app/breadcrumb.service";
import { CreateKeyPersonComponent } from "../create-key-person/create-key-person.component";
import { KeyPersonnelComponent } from "../../client-management/client-view/key-personnel/key-personnel.component";
import { LeadDivisionService } from "src/app/services/lead-division.service";
import { EngagementContactsService } from "../../engagement-contacts/engagement-contacts.service";
import { DomSanitizer } from "@angular/platform-browser";
import { setTimeout } from "timers";
import { SelectedTaskComponent } from "../../workspace-management/Task-Managing-Folder/selected-task/selected-task.component";
import { ServiceDocumentFormComponent } from "./service-document-form/service-document-form.component";

@Component({
  selector: "app-lead-negotiation",
  templateUrl: "./lead-negotiation.component.html",
  styleUrls: ["./lead-negotiation.component.css"],
  providers: [DialogService],
})
export class LeadNegotiationComponent implements OnInit {
  private leadRef: any;
  public currentLead: Lead = null;
  public leadDivisions: LeadDivision[] = [];
  public selectedDivision: LeadDivision = null;
  lead: Lead;
  loadingLeadInfo: boolean;
  quoteServiceStatus = [];
  public services: Services[] = [];
  public selectedServices: Services[] = [];
  public selectedQuoteService: QuoteServices[] = [];
  public selectedGeneralService: any[] = [];
  public selectedServiceTotal: number;
  public qualifyServiceRef: DynamicDialogRef;
  qualifyDocumentFormRef: DynamicDialogRef;
  public selectedQuoteServiceDocuments: QuoteServiceDocument[];
  loadingService = true;
  keyPersonRef: DynamicDialogRef;
  keyPersonnelRef: DynamicDialogRef;
  billableAmountTotal: number;
  suspectContact: any[];
  private allRequiredDocumentUploaded = true;
  completedCompletedLabel = "Completed";
  notCompletedLabel = "Outstanding";
  completedStatuses: any[] = [];
  openDocsDialogue: boolean;
  serviceRequiredDocument: RequiredServiceDocument[] = [];
  serviceReqyiredDocumentInView: RequiredServiceDocument;
  uploadedDocs: string[] = [];

  constructor(
    public engagementService: EngagementContactsService,
    private sanitizer: DomSanitizer,
    private route: ActivatedRoute,
    private leadService: LeadService,
    private leadDivisionService: LeadDivisionService,
    private servicesService: ServicesService,
    public router: Router,
    public dialogService: DialogService,
    private quoteServiceDocumentService: QuoteServiceDocumentService,
    private messageService: MessageService,
    private breadcrumbService: BreadcrumbService
  ) {
    this.selectedServiceTotal = 0;
  }

  async ngOnInit() {
    this.breadcrumbService.setItems([
      { label: "Lead Administration", routerLink: ["home/lead-admin"] },
      { label: "Negotiation", routerLink: ["home/lead-admin/negotiation"] },
    ]);

    this.loadingLeadInfo = true;
    await this.loadServices();
  }

  async loadOldData() {
    this.leadRef = this.route.snapshot.paramMap.get("referenceNo");
    if (this.leadRef) {
      // get the lead admin data and prefill the fields
      await this.leadService.setCurrentLead(this.leadRef);
      await this.leadService.activeLead().subscribe(async (res: any) => {
        if (res?.responseCode == "00") {
          this.currentLead = res.responseData;
          this.lead = this.currentLead;
          this.getAllContactsToSuspect(this.lead.suspectId);
          this.leadDivisions = this.currentLead.leadDivisions;
          // get the division data
          if (this.leadDivisions.length > 0) {
            // create the required formGroups
            if (this.selectedDivision) {
              await this.activate(this.selectedDivision);
            } else {
              await this.activate(this.leadDivisions[0]);
            }
          }
          this.loadingLeadInfo = false;
        }
      });
    }
  }
  getAllContactsToSuspect(id) {
    this.engagementService.getAllContactsAttachedToSuspects(id).subscribe(
      async (res: any) => {
        if (res.responseCode == "00") {
          this.suspectContact = [];
          this.suspectContact = res.responseData.filter(
            (x) => x.contact.isDeleted == false
          );
        } else {
          this.suspectContact = [];
        }
      },
      (error) => {
        this.suspectContact = [];
      }
    );
  }

  transform(image) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(image);
  }

  async loadServices() {
    // f
    this.loadingService = true;

    await this.servicesService.allService().subscribe(
      async (res: CommonResponse) => {
        if (res?.responseCode == "00") {
          this.services = res.responseData;
          this.loadOldData();
        } else {
          this.messageService.add({
            severity: "error",
            detail:
              "Error! Services not fetch completely. Please refresh the page",
            summary: "Error",
          });
        }
      },
      (error) => {
        this.messageService.add({
          severity: "error",
          detail:
            "Error! Services not fetch completely. Please refresh the page",
          summary: "Error",
        });
      }
    );
  }

  activate(leadDivision: LeadDivision) {
    this.leadDivisionService.setCurrentLeadDivision(leadDivision);
    this.loadingService = true;
    this.selectedDivision = this.leadDivisions.find(
      (_leadDivision) => _leadDivision.id === leadDivision.id
    );
    this.quoteServiceStatus = [];
    this.loadDivisionServices();
  }

  async fetchQSDocumentByQS() {
    await this.quoteServiceDocumentService
      .allQuoteServiceDocumentData()
      .subscribe(async (res: CommonResponse) => {
        this.selectedQuoteServiceDocuments = res.responseData;
      });
  }

  loadDivisionServices() {
    this.loadingService = true;
    if (this.selectedDivision) {
      if (this.selectedDivision.quote) {
        const _quote = this.selectedDivision.quote;
        if (_quote.quoteServices.length > 0) {
          this.selectedQuoteService = [];
          this.selectedServices = [];
          this.selectedGeneralService = [];
          this.completedStatuses = [];
          _quote.quoteServices.forEach((value) => {
            var _service = this.services.find(
              (service) => service.id === value.serviceId
            );

            this.selectedServices = [...this.selectedServices, _service];
            this.selectedQuoteService = [...this.selectedQuoteService, value];

            let _serviceRequiredDocument: RequiredServiceDocument[] = [];
            if (_service?.requiredServiceDocument?.length > 0) {
              _serviceRequiredDocument =
                _service?.requiredServiceDocument?.filter(
                  (x) => x.type == "negotiation"
                ) ?? [];
              _service.requiredServiceDocument = _serviceRequiredDocument;
            }

            const _uploadedQuoteServiceDocument =
              value?.quoteServiceDocuments?.filter(
                (x) => x.type == "negotiation"
              );

            var documentComplete = true;
            var documentRequired =
              _serviceRequiredDocument?.length > 0
                ? "Document(s) required"
                : "No document required";
            var documentProgress = `${_uploadedQuoteServiceDocument?.length} of ${_serviceRequiredDocument?.length} uploaded`;

            if (
              _uploadedQuoteServiceDocument?.length <
              _serviceRequiredDocument?.length
            ) {
              documentComplete = false;
            }

            this.completedStatuses.push(documentComplete);

            this.selectedGeneralService = [
              ...this.selectedGeneralService,
              {
                service: _service,
                quoteService: value,
                documentRequired: documentRequired,
                documentComplete: documentComplete,
                documentProgress: documentProgress,
              },
            ];
          });

          this.loadingService = false;
          this.calTotalService();
        }
      }
    }
  }

  calTotalService() {
    this.selectedServiceTotal = 0;
    this.billableAmountTotal = 0;
    if (this.selectedQuoteService.length >= 1) {
      for (let i = 0; i < this.selectedQuoteService.length; i++) {
        const amount = this.selectedQuoteService[i].unitPrice;
        const quantity = this.selectedQuoteService[i].quantity;
        if (amount && quantity) {
          this.selectedServiceTotal += amount * quantity;
        }
        this.billableAmountTotal += this.selectedQuoteService[i].billableAmount;
      }
    }
  }

  goBack() {
    this.router
      .navigate([
        "/home/lead-admin/qualification",
        this.currentLead.referenceNo,
      ])
      .then(() => {
        this.leadService.requalifyService = true;
      });
  }

  goForward() {
    this.router.navigate([
      "/home/lead-admin/conversion",
      this.currentLead.referenceNo,
    ]);
  }

  viewService(generalService: GeneralService) {
    this.qualifyServiceRef = this.dialogService.open(ServiceDocumentComponent, {
      header: "Qualify " + generalService.service.name,
      width: "70%",
      contentStyle: { "min-height": "500px", height: "100%", overflow: "auto" },
      baseZIndex: 10000,
      data: generalService,
    });
    this.qualifyServiceRef.onClose.subscribe(async (res: any) => {
      await this.loadOldData().then(() => {
        this.activate(this.selectedDivision);
      });
    });
  }

  isDocumentUpload(selectedService: GeneralService): boolean {
    // check if all the service have the documents upload
    const _serviceRequiredDocument =
      selectedService.service?.requiredServiceDocument?.filter(
        (x) => x.type == "negotiation"
      );
    const _uploadedQuoteServiceDocument =
      selectedService.quoteService?.quoteServiceDocuments?.filter(
        (x) => x.type == "negotiation"
      );
    const quoteServiceDocumentStatus = [];
    const quoteServiceDocumentStatusError = [];

    if (_uploadedQuoteServiceDocument.length < 0) {
      this.quoteServiceStatus.push(selectedService);
      return false;
    }

    if (_serviceRequiredDocument) {
      _serviceRequiredDocument.forEach((value) => {
        if (value.type === "negotiation") {
          const quoteDoc =
            selectedService.quoteService.quoteServiceDocuments.find(
              (_quoteDocument) => _quoteDocument.caption === value.caption
            );
          if (quoteDoc) {
            quoteServiceDocumentStatus.push(quoteDoc);
          } else {
            quoteServiceDocumentStatusError.push(quoteDoc);
          }
        }
      });
    }
    if (quoteServiceDocumentStatusError.length <= 0) {
      return true;
    } else {
      this.quoteServiceStatus.push(selectedService);
      return false;
    }
  }

  progressLead() {
    // check if all the required document has been uploaded
    this.allRequiredDocumentUploaded = this.completedStatuses.every(
      (x) => x == true
    );

    if (!this.allRequiredDocumentUploaded) {
      this.messageService.add({
        severity: "error",
        detail:
          "Upload all the required documents for the quote service before progressing the lead",
        summary: "Canceled",
      });
      return;
    }

    // check if the
    this.leadService
      .updateLeadOpportunity(this.currentLead.id)
      .subscribe(async (res: any) => {
        if (res.responseCode == "00") {
          await this.messageService.add({
            severity: "success",
            detail: "Lead service is ready for closure",
            summary: "complete",
          });
          await this.leadService.refreshData();
          await this.goForward();
        } else {
          await this.messageService.add({
            severity: "error",
            detail: res.responseMsg,
            summary: "Failure",
          });
        }
      });
  }

  createKeyPerson(isLeadDivision: boolean) {
    if (true) {
      this.keyPersonRef = this.dialogService.open(CreateKeyPersonComponent, {
        header: "Create Divison Key Person",
        width: "50%",
        contentStyle: { "min-height": "350px", overflow: "auto" },
        baseZIndex: 10000,
        data: { isLeadDivision },
      });

      this.keyPersonRef.onClose.subscribe(async (res: any) => {});
    }
  }

  viewKeyPersonnels(isLeadDivision: boolean) {
    let data = [];

    if (isLeadDivision) {
      if (this.selectedDivision.leadDivisionKeyPeople) {
        data = this.selectedDivision.leadDivisionKeyPeople;
      }
    } else {
      if (this.currentLead.leadKeyPeople) {
        data = this.currentLead.leadKeyPeople;
      }
    }

    this.keyPersonnelRef = this.dialogService.open(KeyPersonnelComponent, {
      header: "Key Personnels",
      width: "80%",
      contentStyle: { "min-height": "350px", overflow: "auto" },
      baseZIndex: 10000,
      data: { keyPersonnels: data, editable: false },
    });

    this.keyPersonnelRef.onClose.subscribe(async (res: any) => {});
  }

  UploadDocAll() {
    this.openDocsDialogue = true;
    this.serviceRequiredDocument = [];

    this.selectedGeneralService.forEach((genServ) => {
      genServ.service.requiredServiceDocument.forEach(
        (reqDoc: RequiredServiceDocument) => {
          if (
            this.serviceRequiredDocument.find((x) => x.id == reqDoc.id) == null
          ) {
            this.serviceRequiredDocument.push(reqDoc);
          }
        }
      );
    });

    this.uploadedDocs = [];
    this.serviceRequiredDocument.forEach((doc) => {
      let isDocUploaded = true;
      this.selectedGeneralService.forEach((genServ) => {
        if (
          genServ.service.requiredServiceDocument.find((x) => x.id == doc.id) !=
          null
        ) {
          if (
            genServ.quoteService.quoteServiceDocuments.find(
              (x) => x.caption == doc.caption
            ) == null
          ) {
            isDocUploaded = false;
          }
        }
      });

      if (isDocUploaded) {
        this.uploadedDocs.push(doc.caption);
      }
    });
  }

  HideDocsDialog() {
    this.openDocsDialogue = false;
  }

  IsDocumentUpload(doc: string): boolean {
    if (this.uploadedDocs.find((x) => x == doc) == null) return false;
    return true;
  }

  StartDocumentUpload(document: RequiredServiceDocument) {
    this.qualifyDocumentFormRef = this.dialogService.open(
      ServiceDocumentFormComponent,
      {
        header: "Quote service Document Upload",
        contentStyle: { "min-height": "120px", overflow: "auto" },
        baseZIndex: 100001,
        data: {
          document: document,
          quoteServiceId: 0,
          isGroupUpload: true,
          quoteId: this.selectedGeneralService[0].quoteService.quoteId,
        },
      }
    );
    this.qualifyDocumentFormRef.onClose.subscribe(async (res) => {
      if (res === "complete") {
        if (this.uploadedDocs.find((x) => x == document.caption) == null)
          this.uploadedDocs.push(document.caption);
        await this.loadOldData().then(() => {
          this.activate(this.selectedDivision);
        });
      }
    });
  }
}
