import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { Observable } from "rxjs";
import { Supplier } from "../../interfaces/armada";

@Injectable({
  providedIn: 'root',
})
export class SupplierService {
  constructor(private http: HttpClient) {}
  baseUrl = environment.baseUrl + 'Supplier/';
  allSupplier(): Observable<Supplier> {
    return this.http.get<Supplier>(this.baseUrl);
  }
}
