import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../environments/environment";
import { Observable } from "rxjs";
import {
  CommonResponse,
  ReverseGroupReceiptVM,
  ReverseReceiptVM,
} from "../interfaces/home";

@Injectable({
  providedIn: "root",
})
export class ReceiptService {
  constructor(private http: HttpClient) {}
  baseUrl = environment.baseUrl + "Receipt/";

  getBreakdown(invoiceId: number, totalReceiptAmount: number) {
    return this.http.get(
      this.baseUrl + "ReceiptBreakDown/" + invoiceId + "/" + totalReceiptAmount
    );
  }

  postReceipt(data: any) {
    return this.http.post(this.baseUrl, data);
  }

  getReceiptOnContract(ContractId: number) {
    return this.http.get(this.baseUrl + "ReceiptOnContract/" + ContractId);
  }

  ReverseReceipt(data: ReverseReceiptVM) {
    return this.http.post(this.baseUrl + "ReverseReceipt", data);
  }

  GetGroupedReceiptOnContract(ContractId: number): Observable<CommonResponse> {
    return this.http.get<CommonResponse>(
      this.baseUrl + "GetGroupedReceiptOnContract/" + ContractId
    );
  }

  ReverseGroupReceipt(data: ReverseGroupReceiptVM) {
    return this.http.post(this.baseUrl + "ReverseGroupReceipt", data);
  }

  BulkReceiptingUsingAdvancePayment(
    contractServiceId: number,
    clientId: number
  ): Observable<CommonResponse> {
    return this.http.get<CommonResponse>(
      this.baseUrl +
        "BulkReceiptingUsingAdvancePayment/" +
        contractServiceId +
        "/" +
        clientId
    );
  }
}
