import { Router } from "@angular/router";
import { MasterServiceAssignmentService } from "./../../../services/armada/master-service-assignment.service";
import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { Observable, Subject, Subscription, timer } from "rxjs";
import { BreadcrumbService } from "src/app/breadcrumb.service";
import {
  ArmadaConstant,
  StatusDashboardDetail,
} from "src/app/interfaces/armada";
import { ConfirmationService, MessageService } from "primeng/api";
import { takeUntil } from "rxjs/operators";
import { CommonResponse } from "src/app/interfaces/home";

@Component({
  selector: "app-status-dashboard",
  templateUrl: "./status-dashboard.component.html",
  styleUrls: ["./status-dashboard.component.scss"],
})
export class StatusDashboardComponent implements OnInit {
  @ViewChild("formWrapper") public formWrapper: ElementRef;
  private unsubscriber$ = new Subject<void>();
  public loading: boolean;
  public targetCols: any;
  counts: number = 1;
  public resources: ArmadaConstant[];
  public statusDetail: StatusDashboardDetail;
  resourcesR: any;
  today: any;
  tomorrow: any;
  barData;
  totalOfTripsDue: any;
  totalTripsForToday: any;
  totalTripsStarted: any;
  totalTripsNotStarted: any;
  totalTripsCancelled: any;
  totalTripsEnded: any;
  comTotalRequired: any;
  piTotalRequired: any;
  escTotalRequired: any;
  veTotalRequired: any;
  escTotalAvailable: any;
  comTotalAvailable: any;
  veTotalAvailable: any;
  piTotalAvailable: any;
  totalOfTripsDueIn48hrs: any;
  estimate: any;
  subscription: Subscription;
  everyTwoMinutes: Observable<number> = timer(0, 120000);
  // resourcesR = [
  //   { icon: "car", name: "Vehicle" },
  //   { icon: "car", name: "Pilot" },
  //   { icon: "car", name: "Commander" },
  //   { icon: "car", name: "Armed Escort" },
  // ];
  public resourcess: any[];
  constructor(
    private breadcrumbService: BreadcrumbService,
    private masterServiceAssignment: MasterServiceAssignmentService,
    public messageService: MessageService,
    public confirmationService: ConfirmationService,
    private router: Router
  ) {
    this.breadcrumbService.setItems([
      { label: "Setup" },
      {
        label: "status dashboard",
        routerLink: ["home/armada/setup/status-dashboard"],
      },
    ]);
  }

  ngOnInit(): void {
    this.today = new Date();
    let day = new Date();
    this.tomorrow = day.setDate(day.getDate() + 1);
    this.fetchStatusDashbaord();
    this.subscription = this.everyTwoMinutes.subscribe(() => {
      this.fetchStatusDashbaord();
    });
    // this.barData = {
    //   labels: ["January", "February", "March", "April", "May", "June", "July"],
    //   datasets: [
    //     {
    //       label: "Trips",
    //       backgroundColor: "rgb(255, 99, 132)",
    //       borderColor: "rgb(255, 99, 132)",
    //       data: [65, 59, 80, 81, 56, 55, 40],
    //     }
    //   ],
    // };
  }

  getResources() {
    this.resourcesR;
    console.log(JSON.stringify(this.resourcesR));
  }

  fetchStatusDashbaord(): void {
    this.loading = true;
    this.masterServiceAssignment
      .statusDashboadDetail()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            var res = r.responseData;
            this.statusDetail = res;
            this.totalOfTripsDue = res.totalOfTripsDue;
            this.totalTripsCancelled = res.totalTripsCancelled;
            this.totalTripsForToday = res.totalTripsForToday;
            this.totalTripsNotStarted = res.totalTripsNotStarted;
            this.totalTripsStarted = res.totalTripsStarted;
            this.totalTripsEnded = res.totalTripsEnded;
            this.comTotalRequired = res.comTotalRequired;
            this.veTotalRequired = res.veTotalRequired;
            this.piTotalRequired = res.piTotalRequired;
            this.escTotalRequired = res.escTotalRequired;
            this.comTotalAvailable = res.comTotalAvailable;
            this.escTotalAvailable = res.escTotalAvailable;
            this.piTotalAvailable = res.piTotalAvailable;
            this.veTotalAvailable = res.veTotalAvailable;
            this.estimate = res.estimate;
            this.totalOfTripsDueIn48hrs = res.totalOfTripsDueIn48hrs;
            this.resourcesR = [
              {
                icon: "./assets/sedan.png",
                badge: "p-badge p-badge-primary",
                name: "Vehicle",
                totalReg: res.veTotalRegistered,
                totalWithContract: res.veTotalWithActiveContract,
                totalOnTrip: res.veTotalOnTrip,
                totalIdle: res.veTotalIdle,
                totalForReservedResp: res.veTotalReservedResponse,
                totalPaired: res.veTotalPaired,
              },
              {
                icon: "./assets/user.png",
                badge: "p-badge p-badge-warning",
                name: "Pilot",
                totalReg: res.piTotalRegistered,
                totalWithContract: res.piTotalWithActiveContract,
                totalOnTrip: res.piTotalOnTrip,
                totalIdle: res.piTotalIdle,
                totalForReservedResp: res.piTotalReservedResponse,
                totalPaired: res.piTotalPaired,
              },
              {
                icon: "./assets/user.png",
                badge: "p-badge p-badge-success",
                name: "Commander",
                totalReg: res.comTotalRegistered,
                totalWithContract: res.comTotalWithActiveContract,
                totalOnTrip: res.comTotalOnTrip,
                totalIdle: res.comTotalIdle,
                totalForReservedResp: res.comTotalReservedResponse,
                totalPaired: "N/A",
              },
              {
                icon: "./assets/policeman.png",
                badge: "p-badge p-badge-info",
                name: "Armed Escort",
                totalReg: res.escTotalRegistered,
                totalWithContract: res.escTotalWithActiveContract,
                totalOnTrip: res.escTotalOnTrip,
                totalIdle: res.escTotalIdle,
                totalForReservedResp: res.escTotalReservedResponse,
                totalPaired: "N/A",
              },
            ];
            this.barData = {
              labels: ["Started", "NotStarted/Ontime", "Cancelled", "Ended"],
              datasets: [
                {
                  label: "Trips",
                  backgroundColor: "rgb(255, 99, 132)",
                  borderColor: "rgb(255, 99, 132)",
                  data: [
                    Number(this.totalTripsStarted),
                    Number(this.totalTripsNotStarted),
                    Number(this.totalTripsCancelled),
                    Number(this.totalTripsEnded),
                  ],
                },
              ],
            };
            this.loading = false;
          }
        },
        (error) => {
          this.loading = false;
          this.connectionError();
        }
      );
    //this.submittingData = false;
  }
  private connectionError() {
    this.messageService.add({
      severity: "error",
      summary: "Failed",
      detail: "Connection Error, Please try again",
    });
  }
  GoToResourceReg() {
    this.router.navigateByUrl("/home/armada/setup/resource-registration");
    //alert("Can't go to page yet");
  }
  GoToAssignmentPage() {
    this.router.navigateByUrl("/home/armada/setup/master-service-assignment");
    //alert("Can't go to page yet");
  }
}
