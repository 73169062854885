import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { MessageService, ConfirmationService, Message } from "primeng/api";
import { BreadcrumbService } from "src/app/breadcrumb.service";
import { ApprovalChecklist } from "src/app/interfaces/approval-checklist";
import { BulkApprovalComment } from "src/app/interfaces/approval-comment";
import {
  AllowedAlternateApproversVM,
  AlternateBulkApproveVM,
  ApprovalLevel,
  ApprovalLevelSequence,
  ApprovingLevelOffice,
  ApprovingLevelOfficer,
  BulkApprovalActionVM,
  BulkApprovalExecutionVM,
  GetAllowedAlternateApproversVM,
  GetApprovalSummaryInfoReceivingDTO,
  GetApprovalSummaryInfoTransferDTO,
  GetBulkApprovalDetailViewAdditional,
  GetBulkApprovalDetailViewDoc,
  GetBulkApprovalDetailViewList,
  GetBulkApprovalDetailViewSBU,
  GetBulkApprovalViewModel,
  GetBulkApprovalViewVM,
  GetPendingApprovalsBulkView,
  GroupedServiceCostAnalysis,
  QuoteServices,
  User,
} from "src/app/interfaces/home";
import { MailRequest } from "src/app/interfaces/mail";
import { ApiLoadingService } from "src/app/services/api-loading.service";
import { ApprovalChecklistService } from "src/app/services/approval-checklist.service";
import { ApprovalCommentService } from "src/app/services/approval-comment.service";
import { ApprovalLevelService } from "src/app/services/approval-level.service";
import { ApprovalService } from "src/app/services/approval.service";
import { FireBaseAuthService } from "src/app/services/fire-base-auth.service";
import { MailService } from "src/app/services/mail.service";
import { ServicesService } from "src/app/services/services.service";
import { UserService } from "src/app/services/user.service";

@Component({
  selector: "app-bulk-approval",
  templateUrl: "./bulk-approval.component.html",
  styleUrls: ["./bulk-approval.component.scss"],
  providers: [MessageService],
})
export class BulkApprovalComponent implements OnInit {
  @ViewChild("formWrapper") public formWrapper: ElementRef;
  allPendingApprovalsView: GetPendingApprovalsBulkView[] = [];
  allPendingApprovalsViewData: GetPendingApprovalsBulkView[] = [];
  totalPendingApprovalForMe: number = 0;
  totalApprovalTypesWaitingForMe: number = 0;
  totalApprovalOnQueue: number = 0;
  fetchingApprovals: boolean;
  approvalsClientBatch: {
    contractName?: string;
    isParticipant: boolean;
    clientBatches: {
      approvalType: string;
      approvalCount: number;
      approvals: GetPendingApprovalsBulkView[];
    }[];
  }[] = [];
  appClientBatchCols: any[];
  appClientAppTypeCols: any[];
  approvalCols: any[];
  participatingApprovalsIds: number[] = [];
  isDoneLoadingSummary: boolean;
  allUsers: User[] = [];
  allPendingEndorsementsView: GetPendingApprovalsBulkView[] = [];
  openApprovalDialogue: boolean;
  loadingApprovalParticipants: boolean;
  allApprovalParticipants: GetBulkApprovalViewModel[] = [];
  allServiceDetails: GetBulkApprovalDetailViewList[] = [];
  srContractStart: string;
  srContractEnd: string;
  srContractActivate: string;
  srInvoicingInterval: string = "Monthly";
  srPaymentCycle: string;
  srFInvoiceSeedData: string;
  showDetails: boolean;
  useTable: number = 0;
  loadingDetails: boolean;
  appDivision: string = "PSM";
  appMarket: string = "GUARDING";
  appServGroup: string = "STANDARD SERVICE";
  appServCateg: string = "BUSINESS SECURITY SOLUTION";
  appDateEffect: string = "DATE TO TAKE EFFECT: 02-FEB-2023 12:00 AM";
  servDetailsCols: any[];
  approvalEntityInView: GetPendingApprovalsBulkView;
  openCommentSection: boolean;
  loadingApprovalComments: boolean;
  loggedInUser: User;
  showComment: boolean = false;
  comments: BulkApprovalComment[] = [];
  newApprovalComment: string;
  openBasicInfoSection: boolean;
  openClientInfoSection: boolean;
  clientImageSrc: string;
  clientName: string;
  clientMobile: string;
  clientEmail: string;
  clientIndustry: string;
  openContactInfoSection: boolean;
  carouselResponsiveOptions: any[] = [
    {
      breakpoint: "1024px",
      numVisible: 3,
      numScroll: 3,
    },
    {
      breakpoint: "768px",
      numVisible: 2,
      numScroll: 2,
    },
    {
      breakpoint: "560px",
      numVisible: 1,
      numScroll: 1,
    },
  ];
  clientContacts: GetBulkApprovalDetailViewAdditional[] = [];
  openDocInfoSection: boolean;
  approvalDocs: GetBulkApprovalDetailViewDoc[] = [];
  openApprovalDialogue2: boolean;
  declineComment: string;
  isDecliningRequest: boolean;
  openAltApprovalDialogue: boolean;
  entityForAlternate: GetPendingApprovalsBulkView;
  loadingAlternateOptions: boolean;
  alternateProfiles: AllowedAlternateApproversVM[];
  srDateToTakeEffect: string;
  srDescription: string;
  srDaysAffected: number;
  proportionsCols: any;
  proportions: GetBulkApprovalDetailViewSBU[];
  selectedProportion: GetBulkApprovalDetailViewSBU[] = [];
  openChecklistDialogue: boolean;
  openChecklistAltDialogue: boolean;
  allChecklists: ApprovalChecklist[] = [];
  approvalRequiredChecklists: ApprovalChecklist[] = [];
  approvalOptionalChecklists: ApprovalChecklist[] = [];
  bulkEntityInView: GetPendingApprovalsBulkView;
  bulkEntityInViewAlt: AllowedAlternateApproversVM;
  dateFrom: Date;
  dateTo: Date;
  allEndorsementTypes: {
    key: string;
    value: string;
    isEndorsementType?: boolean;
  }[];
  theEndorsementType: {
    key: string;
    value: string;
    isEndorsementType?: boolean;
  };
  theInitiator: User;
  openApproverAlternate: boolean;
  loadingApproverAlternates: boolean;
  approverAlternates: ApprovingLevelOfficer[] = [];
  msg: Message[] = [];
  readonly MESSAGE_ADHOC: string =
    "This contract endorsement if approved does not generate or update any invoices automatically, it only establishes an umbrella contract within a stipulated start and end period and a maximum value that can be invoiced as controls to guide the invoicing process subsequently. Invoices can then be raised afterwards against the umbrella contract.";
  readonly MESSAGE_ONETIME: string =
    "This contract endorsement if approved will generate or update invoices just once, starting from the date to take effect or the contract start date in the case of a new client/contract creation.";
  readonly MESSAGE_MONTHLYPRORATA: string =
    "This contract endorsement if approved will first prorate the first month invoice value and then generate or update invoices on a monthly basis onward, starting from the date to take effect or the contract start date in the case of a new client/contract creation.";
  readonly MESSAGE_ANNUALLY: string =
    "This contract endorsement if approved will generate or update invoices once every 12months, starting from the date to take effect or the contract start date in the case of a new client/contract creation.";
  readonly MESSAGE_BIANNUALLY: string =
    "This contract endorsement if approved will generate or update invoices once every 6months, starting from the date to take effect or the contract start date in the case of a new client/contract creation.";
  readonly MESSAGE_QUARTERLY: string =
    "This contract endorsement if approved will generate or update invoices once every 3 months, starting from the date to take effect or the contract start date in the case of a new client/contract creation.";
  readonly MESSAGE_BIMONTHLY: string =
    "This contract endorsement if approved will generate or update invoices once every 2 months, starting from the date to take effect or the contract start date in the case of a new client/contract creation.";
  readonly MESSAGE_MONTHLY: string =
    "This contract endorsement if approved will generate or update invoices on a monthly basis, starting from the date to take effect or the contract start date in the case of a new client/contract creation.";
  specificApproverLevelInfo: GetBulkApprovalViewModel[] = [];
  openInfoDialogue: boolean;
  allInsightsOptions: { id: number; value: string }[] = [
    {
      id: 1,
      value:
        "Check this box and click acknowledge if you don't want us to display this dialog box again. (Kindly note a re-login is required for this setting to take effect)",
    },
  ];
  selectedInsightsOptions: { id: number; value: string }[] = [];
  loggedInUserAlternatesConfig: ApprovingLevelOffice[] = [];
  openFAExecutionDialogue: boolean;
  executionPayload: BulkApprovalExecutionVM;
  allApprovalLevels: ApprovalLevel[] = [];
  allApprovalLevelSequences: ApprovalLevelSequence[] = [];
  openCostAnalysisDialogue: boolean;
  loadingServiceCLI: boolean;
  allServiceCostAnalysis: GroupedServiceCostAnalysis[] = [];

  constructor(
    private apiLoadingService: ApiLoadingService,
    private servicesService: ServicesService,
    private mailService: MailService,
    private approvalChecklistService: ApprovalChecklistService,
    private approvalCommentService: ApprovalCommentService,
    private approvalLevelService: ApprovalLevelService,
    private userService: UserService,
    private approvalService: ApprovalService,
    public messageService: MessageService,
    private breadcrumbService: BreadcrumbService,
    public confirmationService: ConfirmationService,
    private fireBaseAuthService: FireBaseAuthService
  ) {}

  ngOnInit(): void {
    this.breadcrumbService.setItems([
      {
        label: "Approval",
        routerLink: ["/home/approvals"],
      },
      {
        label: "Bulk Approval",
        routerLink: ["/home/bulk-approval"],
      },
    ]);

    this.appClientBatchCols = [
      { field: "clientName", header: "Client Name" },
      { field: "isParticipant", header: "Is Participating" },
    ];

    this.appClientAppTypeCols = [
      { field: "approvalType", header: "Approval Type" },
    ];

    this.approvalCols = [
      { field: "type", header: "Type" },
      { field: "name", header: "Name" },
      { field: "description", header: "Description" },
      { field: "createdAt", header: "Created At" },
      { field: "GetContractIdEndorsementView(id)", header: "Contract Id" },
    ];

    this.servDetailsCols = [
      { field: "isTotal", header: "Is Total" },
      { field: "serviceName", header: "Service Name" },
      { field: "uniqueTag", header: "Unique Tag" },
      { field: "unitPrice", header: "Unit Price" },
      { field: "qty", header: "Quantity" },
      { field: "vat", header: "Vat" },
      { field: "totalAmt", header: "Total Amount" },
      { field: "newUnitPrice", header: "New Unit Price" },
      { field: "newQty", header: "New Quantity" },
      { field: "newVat", header: "New Vat" },
      { field: "newTotalAmt", header: "New Total Amount" },
    ];

    this.proportionsCols = [
      { field: "id", header: "Id" },
      { field: "proportion", header: "Proportion" },
      { field: "status", header: "Status" },
      { field: "strategicBU", header: "StrategicBU" },
      { field: "user", header: "User Involved" },
    ];

    this.allEndorsementTypes = [
      {
        key: "Service Creation",
        value: "Service Creation",
      },
      {
        key: "Contract Creation",
        value: "Contract Creation",
      },
      {
        key: "Addition Contract Creation",
        value: "Addition Contract Creation",
      },
      {
        key: "Service Topup",
        value: "Endorsements - Service Topup",
        isEndorsementType: true,
      },
      {
        key: "Service Reduction",
        value: "Endorsements - Service Reduction",
        isEndorsementType: true,
      },
      {
        key: "Debit Note",
        value: "Endorsements - Debit Note",
        isEndorsementType: true,
      },
      {
        key: "Credit Note",
        value: "Endorsements - Credit Note",
        isEndorsementType: true,
      },
    ];

    this.loggedInUser = this.fireBaseAuthService.authUserProfile;
    this.FetchAllUsers();
    this.FetchAllApprovalChecklists();
    this.FetchAllApprovalLevels();
    this.FetchAllApprovalLevelSequences();
  }

  async FetchAllUsers() {
    this.userService.allUser().subscribe(
      async (r) => {
        var data = r.responseData ?? [];
        this.allUsers = data;
        this.allUsers.forEach(
          (x) => (x.fullName = x.lastName + " " + x.firstName)
        );
        if (!this.loggedInUser.optedOutOfBulkApprovalInsights) {
          this.FetchAlternateConfigStatus();
        }
        this.FetchPendingApprovals();
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all users at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  async FetchPendingApprovals() {
    this.fetchingApprovals = true;
    this.approvalService.GetPendingApprovalsBulk().subscribe(
      async (data) => {
        if (data.responseCode != "00") {
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail: data.responseMsg,
          });
          return;
        }

        this.allPendingApprovalsView = data.responseData;
        this.allPendingApprovalsViewData = data.responseData;
        var endorsements = this.allPendingApprovalsView.filter(
          (x) => x.className == "Endorsements"
        );
        this.allPendingEndorsementsView = endorsements;

        await this.LoadApprovalSummaryInfo({
          leadIds: this.allPendingApprovalsView
            .filter((x) => x.className == "Leads")
            .map((x) => x.id),
          serviceIds: this.allPendingApprovalsView
            .filter((x) => x.className == "Services")
            .map((x) => x.id),
          contractServiceIds: endorsements.map((x) => x.id),
          contractIds: this.allPendingApprovalsView
            .filter((x) => x.className == "Contracts")
            .map((x) => x.id),
        });
        this.GroupApprovalsView();
        this.fetchingApprovals = false;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all pending approvals at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
        this.fetchingApprovals = false;
      }
    );
  }

  async LoadApprovalSummaryInfo(data: GetApprovalSummaryInfoReceivingDTO) {
    this.isDoneLoadingSummary = false;
    this.participatingApprovalsIds = [];
    this.approvalService.GetApprovalSummaryInfo(data).subscribe(
      async (r) => {
        var resp =
          (r.responseData as GetApprovalSummaryInfoTransferDTO) ?? null;
        if (resp) {
          this.totalPendingApprovalForMe = resp.totalPendingApprovalForMe;
          this.totalApprovalTypesWaitingForMe =
            resp.totalApprovalTypesWaitingForMe;
          this.totalApprovalOnQueue = resp.totalApprovalOnQueue;
          this.participatingApprovalsIds = resp.approvalsForMeIds;
          this.LoadApprovalParticipating();
        }
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all users at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
        this.isDoneLoadingSummary = true;
      }
    );
  }

  async GroupApprovalsView() {
    this.fetchingApprovals = true;
    this.approvalsClientBatch = [];
    if (this.allPendingApprovalsView.length > 0) {
      let groupedByClient = [
        ...new Set(
          this.allPendingApprovalsView.map((approval) => approval.name)
        ),
      ];
      let groupedByServiceType = [
        ...new Set(
          this.allPendingApprovalsView.map((approval) => approval.typeName)
        ),
      ];
      groupedByClient.forEach((grpClient) => {
        let apClBatchs: {
          clientName: string;
          isParticipant: boolean;
          clientBatches: {
            approvalType: string;
            approvalCount: number;
            approvals: GetPendingApprovalsBulkView[];
          }[];
        } = {
          clientName: grpClient,
          isParticipant: false,
          clientBatches: [],
        };
        groupedByServiceType.forEach((grpServiceType) => {
          let allClientApprovalsInType = this.allPendingApprovalsView.filter(
            (x) => x.name == grpClient && x.typeName == grpServiceType
          );

          if (allClientApprovalsInType.length > 0) {
            apClBatchs.clientBatches.push({
              approvalType: grpServiceType,
              approvalCount: allClientApprovalsInType.length,
              approvals: allClientApprovalsInType,
            });
          }
        });
        this.approvalsClientBatch.push(apClBatchs);
      });
      this.fetchingApprovals = false;

      this.LoadApprovalParticipating();
      this.LoadApprovalStatus();
    }
    this.fetchingApprovals = false;
  }

  async LoadApprovalParticipating() {
    this.participatingApprovalsIds.forEach((participaringId) => {
      this.approvalsClientBatch.forEach((clientBatch) => {
        let isParticipatingApp = clientBatch.clientBatches.find(
          (appType) =>
            appType.approvals.find((app) => app.id == participaringId) != null
        );
        if (!clientBatch.isParticipant && isParticipatingApp)
          clientBatch.isParticipant = true;
      });
    });
    let clApps = this.approvalsClientBatch.filter((x) => x.isParticipant);
    let countAppType: string[] = [];
    let countApp = 0;
    clApps.forEach((btch) => {
      countApp++;
      btch.clientBatches.forEach((dits) => {
        if (countAppType.find((x) => x == dits.approvalType) == null)
          countAppType.push(dits.approvalType);
      });
    });
    this.totalPendingApprovalForMe = countApp;
    this.totalApprovalTypesWaitingForMe = countAppType.length;
    this.isDoneLoadingSummary = true;
  }

  async FetchAllApprovalChecklists() {
    this.approvalChecklistService.GetAllApprovalChecklists().subscribe(
      async (data) => {
        if (data.responseCode != "00") {
          this.messageService.add({
            severity: "error",
            summary: data.responseMsg,
          });
          return;
        }

        this.allChecklists = data.responseData;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all approval checklists at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  async FetchAlternateConfigStatus() {
    this.approvalService.GetAllMyAlternates().subscribe(
      async (data) => {
        if (data.responseCode != "00") {
          this.messageService.add({
            severity: "error",
            summary: data.responseMsg,
          });
          return;
        }

        if (data.responseData.length > 0) {
          this.loggedInUserAlternatesConfig = data.responseData;
          this.openInfoDialogue = true;
        }
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch your alternates config at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  async LoadApprovalStatus() {
    this.approvalsClientBatch
      .map((x) => x.clientBatches)
      .forEach((approvalEntityBatch) => {
        approvalEntityBatch
          .map((x) => x.approvals)
          .forEach((approvalEntityBatchItem) => {
            approvalEntityBatchItem.forEach((approvalEntity) => {
              const postData: GetBulkApprovalViewVM = {
                uniquqIdentifier: approvalEntity.id,
                approvalType: approvalEntity.typeName,
                joinedUniqueIdentifiers: approvalEntity.joinedApprovalIds,
              };
              this.approvalService.GetBulkApprovalView(postData).subscribe(
                async (data) => {
                  if (data.responseCode == "00") {
                    approvalEntity.approversView = data.responseData;
                    let hasAnyApproved = data.responseData
                      .map((x) => x.status)
                      .find((x) => x == 1);
                    let hasAllApproved =
                      data.responseData
                        .map((x) => x.status)
                        .filter((x) => x == 1).length ==
                      data.responseData.length;

                    if (hasAllApproved) {
                      approvalEntity.status = 1;
                    } else if (hasAnyApproved) {
                      approvalEntity.status = 3;
                    } else {
                      approvalEntity.status = 2;
                    }

                    approvalEntity.pendingParticipantsUserIds =
                      data.responseData
                        .filter((x) => x.status != 1)
                        .map((x) => x.id);
                  } else {
                    console.log("Error: " + JSON.stringify(data.responseMsg));
                  }
                },
                (error) => {
                  console.log("Error: " + JSON.stringify(error));
                }
              );
            });
          });
      });
  }

  async FetchAllApprovalLevels() {
    this.approvalLevelService.allApprovalLevelData().subscribe(
      async (data) => {
        if (data.responseCode != "00") {
          this.messageService.add({
            severity: "error",
            summary: data.responseMsg,
          });
          return;
        }

        this.allApprovalLevels = data.responseData;
        this.allApprovalLevels.push({
          id: 0,
          caption: "Non-Principal",
          alias: "NP",
          description: "Non Principal Approver Level",
        });
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all approval levels at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  async FetchAllApprovalLevelSequences() {
    this.allApprovalLevelSequences = [];
    this.approvalLevelService.GetAllApprovingLevelSequences().subscribe(
      async (data) => {
        if (data.responseCode != "00") {
          this.messageService.add({
            severity: "error",
            summary: data.responseMsg,
          });
          return;
        }

        this.allApprovalLevelSequences = data.responseData;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all approval level sequences at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  ResetMessageToaster() {
    this.msg = [];
    let messageToDisplay: string;
    if (this.srInvoicingInterval) {
      if (this.srInvoicingInterval == "Monthly")
        messageToDisplay = this.MESSAGE_MONTHLY;
      if (this.srInvoicingInterval == "BiMonthly")
        messageToDisplay = this.MESSAGE_BIMONTHLY;
      if (this.srInvoicingInterval == "Quarterly")
        messageToDisplay = this.MESSAGE_QUARTERLY;
      if (this.srInvoicingInterval == "BiAnnually")
        messageToDisplay = this.MESSAGE_BIANNUALLY;
      if (this.srInvoicingInterval == "Annually")
        messageToDisplay = this.MESSAGE_ANNUALLY;
      if (this.srInvoicingInterval == "MonthlyProrata")
        messageToDisplay = this.MESSAGE_MONTHLYPRORATA;
      if (this.srInvoicingInterval == "OneTime")
        messageToDisplay = this.MESSAGE_ONETIME;
      if (this.srInvoicingInterval == "Adhoc")
        messageToDisplay = this.MESSAGE_ADHOC;
    }
    if (messageToDisplay) {
      this.msg.push({
        severity: "info",
        summary: messageToDisplay,
      });
    }
  }

  GetUserName(userId: number): string {
    let user = this.allUsers.find((x) => x.id == userId);
    if (user) return user.lastName + " " + user.firstName;

    return "N/A";
  }

  GetUserImage(userId: number): string {
    let user = this.allUsers.find((x) => x.id == userId);
    if (user) return user.imageUrl;

    return "assets/no-file.png";
  }

  GetUserEmail(userId: number): string {
    let user = this.allUsers.find((x) => x.id == userId);
    if (user) return user.email;

    return "";
  }

  GetContractIdEndorsementView(id: number): number {
    let endorse = this.allPendingEndorsementsView.find((x) => x.id == id);
    if (endorse) return endorse.contractId;

    return 0;
  }

  ViewApproval(item: GetPendingApprovalsBulkView) {
    // this.openApprovalDialogue = true;
    this.showDetails = false;
    this.openApprovalDialogue2 = true;
    this.approvalEntityInView = item;
    this.loadingApprovalParticipants = true;
    const postData: GetBulkApprovalViewVM = {
      uniquqIdentifier: item.id,
      approvalType: item.typeName,
      joinedUniqueIdentifiers: item.joinedApprovalIds,
    };
    this.approvalService.GetBulkApprovalView(postData).subscribe(
      async (data) => {
        if (data.responseCode != "00") {
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail: data.responseMsg,
          });
          this.loadingApprovalParticipants = false;
          return;
        }

        // this.allApprovalParticipants = data.responseData;
        this.allApprovalParticipants = [];
        data.responseData.forEach((app) => {
          app.items = [];
          let captions = app.caption.split("--and--");
          let levels = app.level.split("--and--");
          let index = 0;
          captions.forEach((caption) => {
            app.items.push({
              approvalItem: caption,
              level: levels[index] ? levels[index] : "N/A",
            });
            index++;
          });
          this.allApprovalParticipants.push(app);
        });
        this.loadingApprovalParticipants = false;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all approval participants at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
        this.loadingApprovalParticipants = false;
      }
    );
  }

  ViewApprovalDetail(item: GetPendingApprovalsBulkView) {
    this.openApprovalDialogue2 = false;
    this.showDetails = true;
    this.loadingDetails = true;
    this.approvalEntityInView = null;
    this.entityForAlternate = item;
    const postData: GetBulkApprovalViewVM = {
      uniquqIdentifier: item.id,
      approvalType: item.typeName,
      customerDivisionId: item.customerDivisionId,
      joinedUniqueIdentifiers: item.joinedApprovalIds,
    };
    this.approvalService.GetBulkApprovalDetail(postData).subscribe(
      async (data) => {
        if (data.responseCode != "00") {
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail: data.responseMsg,
          });
          this.loadingDetails = false;
          this.showDetails = false;
          return;
        }

        // Populate Details
        this.LoadApprovalDetailInMem(item);
        this.appDivision = data.responseData.division;
        this.appMarket = data.responseData.market;
        this.appServGroup = data.responseData.serviceGroup;
        this.appServCateg = data.responseData.serviceCategory;
        this.appDateEffect =
          "Date To Take Effect: " +
          (data.responseData.dateToTakeEffect
            ? this.FormatDateString(data.responseData.dateToTakeEffect)
            : "N/A");
        this.srContractStart = data.responseData.contractStartDate
          ? this.FormatDateString(data.responseData.contractStartDate)
          : "N/A";
        this.srContractEnd = data.responseData.contractEndDate
          ? this.FormatDateString(data.responseData.contractEndDate)
          : "N/A";
        this.srContractActivate = data.responseData.activationDate
          ? this.FormatDateString(data.responseData.activationDate)
          : "N/A";
        this.srInvoicingInterval = data.responseData.invoicingInterval;
        this.srPaymentCycle = data.responseData.paymentCycle;
        this.srFInvoiceSeedData = data.responseData.firstInvoiceSeedDate
          ? this.FormatDateString(data.responseData.firstInvoiceSeedDate)
          : "N/A";
        this.srDateToTakeEffect = data.responseData.dateToTakeEffect
          ? this.FormatDateString(data.responseData.dateToTakeEffect)
          : "N/A";
        this.srDescription = data.responseData.description;
        this.srDaysAffected = data.responseData.daysAffected;
        this.allServiceDetails = data.responseData.details;
        this.approvalEntityInView = item;
        this.clientImageSrc = data.responseData.customerInfo.imageUrl;
        this.clientName = data.responseData.customerInfo.name;
        this.clientMobile = data.responseData.customerInfo.mobile;
        this.clientEmail = data.responseData.customerInfo.email;
        this.clientIndustry = data.responseData.customerInfo.industry;
        this.clientContacts = data.responseData.contactsInfo;
        if (item.description == "Service Addition") {
          this.srDateToTakeEffect = this.srContractStart;
        }
        this.loadingDetails = false;
        this.LoadCostAnalysis();
        this.ResetMessageToaster();
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all approval details at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
        this.loadingDetails = false;
        this.showDetails = false;
      }
    );

    if (
      item.description == "Service Reduction" ||
      item.description == "Service Topup" ||
      item.description == "Debit Note"
    )
      this.useTable = 2;
    else if (item.description == "Credit Note") this.useTable = 3;
    else this.useTable = 1;

    this.formWrapper.nativeElement.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "start",
    });
  }

  ExecuteApproval(item: GetPendingApprovalsBulkView) {
    // if (this.OpenChecklistDialogue(item)) return;
    this.confirmationService.confirm({
      message:
        "You are about to execute this approval entity. It is an irreversible action, Do you still wish to proceed?",
      accept: () => {
        const postData: BulkApprovalExecutionVM = {
          uniquqIdentifier: item.id,
          approvalType: item.typeName,
          joinedUniqueIdentifiers: item.joinedApprovalIds,
        };
        this.apiLoadingService.show();
        this.approvalService.BulkApprovalExecution(postData).subscribe(
          async (data) => {
            if (data.responseCode != "00") {
              this.messageService.add({
                severity: "error",
                summary: "Notice",
                detail: data.responseMsg,
              });
              this.apiLoadingService.hide();
              return;
            }

            this.messageService.add({
              severity: "success",
              summary: "Completed",
              detail: "Approval Entity Executed Successfully...",
            });
            this.showDetails = false;
            this.apiLoadingService.hide();
            this.FetchPendingApprovals();
          },
          (error) => {
            console.log("Error: " + JSON.stringify(error));
            this.messageService.add({
              severity: "error",
              summary: "Notice",
              detail:
                "Unable to execute approval entity at the moment.. Reason: [" +
                (error ? error.error.message : "request failed - permission") +
                "]",
            });
            this.apiLoadingService.hide();
          }
        );
      },
    });
  }

  HideApprovalDialogue() {
    this.openApprovalDialogue = false;
    this.loadingApprovalParticipants = true;
  }

  HideApprovalDialogue2() {
    this.openApprovalDialogue2 = false;
    this.loadingApprovalParticipants = true;
  }

  ViewBasicInfo() {}

  ViewCustomerInfo() {
    this.openClientInfoSection = true;
  }

  ViewContactInfo() {
    this.openContactInfoSection = true;
  }

  ViewEvidenceClientInfo() {}

  BulkApprove() {
    if (!this.CanApproveBasedOnSequence(this.loggedInUser.id)) return;

    if (this.OpenChecklistDialogue(this.entityForAlternate)) return;

    this.isDecliningRequest = false;
    this.declineComment = null;
    this.confirmationService.confirm({
      message:
        "You are about to approve this approval entity, this would signify as your go ahead for this entity. It is an irreversible action, Do you still wish to proceed?",
      accept: () => {
        this.BulkApprovalAction(true);
      },
    });
  }

  BulkDecline() {
    this.isDecliningRequest = true;
    // this.confirmationService.confirm({
    //   message:
    //     "You are about to decline this approval entity, this would signify as your denial for this entity. It is an irreversible action, Do you still wish to proceed?",
    //   accept: () => {
    //     this.BulkApprovalAction(false);
    //   },
    // });
  }

  BulkApprovalAction(action: boolean) {
    const postData: BulkApprovalActionVM = {
      uniquqIdentifier: this.approvalEntityInView.id,
      approvalType: this.approvalEntityInView.typeName,
      isApproved: action,
      joinedUniqueIdentifiers: this.approvalEntityInView.joinedApprovalIds,
      comment: this.declineComment,
      checklists: [],
    };
    this.approvalRequiredChecklists.forEach((x) =>
      postData.checklists.push({
        approvalChecklistId: x.id,
        isDeclaredCompleted: x.isDeclaredCompleted,
        isDeclaredComment: x.isDeclaredComment,
      })
    );
    this.approvalOptionalChecklists.forEach((x) =>
      postData.checklists.push({
        approvalChecklistId: x.id,
        isDeclaredCompleted: x.isDeclaredCompleted,
        isDeclaredComment: x.isDeclaredComment,
      })
    );

    if (
      this.approvalRequiredChecklists.find((x) => !x.isDeclaredCompleted) !=
      null
    ) {
      if (action) {
        this.messageService.add({
          severity: "error",
          summary:
            "You must check all required checklist before you can proceed",
        });
        return;
      }
    }

    this.approvalService.BulkApprovalAction(postData).subscribe(
      async (data) => {
        if (data.responseCode != "00") {
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail: data.responseMsg,
          });
          return;
        }

        this.messageService.add({
          severity: "success",
          summary: "Removed",
          detail: "Operation Successful",
        });
        if (!action) {
          this.showDetails = false;
          this.declineComment = "";
          this.FetchPendingApprovals();
        } else
          this.PerformFinalApprovalCheck(
            this.approvalEntityInView,
            this.loggedInUser.id
          );
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to act on this approval entity at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  GetCommentAuthor(authorId?: number): string {
    if (authorId && authorId != this.loggedInUser.id)
      return this.GetUserName(authorId);
    else return "Me";
  }

  DropBulkApprovalComment() {
    this.openCommentSection = true;
    this.LoadApprovalComments();
  }

  LoadApprovalComments() {
    this.loadingApprovalComments = true;
    this.approvalCommentService
      .GetBulkApprovalComments(this.approvalEntityInView.id)
      .subscribe(
        async (data) => {
          this.comments = data;
          this.loadingApprovalComments = false;
        },
        (error) => {
          console.log("Error: " + JSON.stringify(error));
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail:
              "Unable to fetch bulk approval comments at the moment.. Reason: [" +
              error.error.message +
              "]",
          });
          this.loadingApprovalComments = false;
        }
      );
  }

  HideCommentBox() {
    this.openCommentSection = false;
    this.comments = [];
    this.loadingApprovalComments = false;
  }

  PostComment() {
    this.approvalCommentService
      .CreateBulkApprovalComment({
        uniqueIdentifierId: this.approvalEntityInView.id,
        comment: this.newApprovalComment,
        commenator: this.GetUserName(this.loggedInUser.id),
        className: this.approvalEntityInView.className,
        approvalType: this.approvalEntityInView.typeName,
        approvalCreatedById: this.approvalEntityInView.createdById,
        clientName: this.clientName,
      })
      .subscribe(
        async () => {
          this.messageService.add({
            severity: "success",
            summary: "Completed",
            detail: "Comment Posted Successfully...",
          });
          this.newApprovalComment = null;
          this.LoadApprovalComments();
        },
        (error) => {
          console.log("Error: " + JSON.stringify(error));
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail:
              "Unable to post bulk approval comment at the moment.. Reason: [" +
              error.error.message +
              "]",
          });
        }
      );
  }

  DeleteComment(item: BulkApprovalComment) {
    this.confirmationService.confirm({
      message: "Are you sure you want to remove comment?",
      accept: () => {
        this.messageService.add({
          severity: "info",
          summary: "Notice",
          detail: "Removing Comment...",
        });

        this.approvalCommentService
          .DeleteBulkApprovalComment(item.id)
          .subscribe(
            async () => {
              this.messageService.add({
                severity: "success",
                summary: "Completed",
                detail: "Removed Posted Comment Successfully...",
              });
              this.newApprovalComment = null;
              this.LoadApprovalComments();
            },
            (error) => {
              console.log("Error: " + JSON.stringify(error));
              this.messageService.add({
                severity: "error",
                summary: "Notice",
                detail:
                  "Unable to delete approval comment at the moment.. Reason: [" +
                  error.error.message +
                  "]",
              });
            }
          );
      },
    });
  }

  OpenBasicInfoBox(item: GetBulkApprovalDetailViewList) {
    // Populate Details
    this.appDivision = item.division;
    this.appMarket = item.market;
    this.appServGroup = item.serviceGroup;
    this.appServCateg = item.serviceCategory;
    this.appDateEffect =
      "Date To Take Effect: " +
      (item.dateToTakeEffect
        ? this.FormatDateString(item.dateToTakeEffect)
        : "N/A");
    this.proportions = item.strategicBUInfos;
    this.openBasicInfoSection = true;
  }

  HideBasicInfoBox() {
    this.openBasicInfoSection = false;
  }

  FormatDateString(date: Date): string {
    let dateString = date.toString();

    try {
      // DateTimeFormatOptions
      const date: Date = new Date(dateString);
      const options: Intl.DateTimeFormatOptions = {
        year: "numeric",
        month: "long",
        day: "numeric",
      };
      const formattedDate: string = new Intl.DateTimeFormat(
        "en-US",
        options
      ).format(date);
      dateString = formattedDate;
    } catch (error) {
      console.log(error);
      console.log(date);
      console.log(
        "Error while converting date string " + date + " exception " + error
      );
    }

    return dateString;
  }

  HideClientInfoBox() {
    this.openClientInfoSection = false;
  }

  HideContactInfoBox() {
    this.openContactInfoSection = false;
  }

  OpenDocInfoBox(item: GetBulkApprovalDetailViewList) {
    this.approvalDocs = item.docs;
    this.openDocInfoSection = true;
  }

  HideDocInfoBox() {
    this.approvalDocs = [];
    this.openDocInfoSection = false;
  }

  StartAlternateApproval() {
    this.openAltApprovalDialogue = true;
    this.loadingAlternateOptions = true;
    const postData: GetAllowedAlternateApproversVM = {
      uniquqIdentifier: this.entityForAlternate.id,
      alternateEntityType: this.GetAlternateApprovalType(),
      approvalType: this.entityForAlternate.typeName,
      joinedUniqueIdentifiers: this.entityForAlternate.joinedApprovalIds,
    };
    this.approvalService.GetAllowedAlternateApprovers(postData).subscribe(
      async (data) => {
        if (data.responseCode != "00") {
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail: data.responseMsg,
          });
          this.loadingAlternateOptions = false;
          this.ResetMessageToaster();
          return;
        }

        this.alternateProfiles = data.responseData;
        this.loadingAlternateOptions = false;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all alternate approval profiles at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
        this.ResetMessageToaster();
        this.loadingAlternateOptions = false;
      }
    );
  }

  HideAltApprovalDialogue() {
    this.openAltApprovalDialogue = false;
    this.loadingAlternateOptions = false;
  }

  ApproveAsAlternate(item: AllowedAlternateApproversVM) {
    if (!this.CanApproveBasedOnSequence(item.userId)) return;
    if (this.OpenChecklistDialogueAlt(item)) return;

    this.confirmationService.confirm({
      message:
        "You are about to approve this approval entity as an alternate for " +
        item.userName +
        ". Do you still wish to proceed?",
      accept: () => {
        this.HideAltApprovalDialogue();

        const postData: AlternateBulkApproveVM = {
          uniquqIdentifier: this.approvalEntityInView.id,
          approvalType: this.approvalEntityInView.typeName,
          isApproved: true,
          joinedUniqueIdentifiers: this.approvalEntityInView.joinedApprovalIds,
          comment: this.declineComment,
          alternateProfileId: item.userId,
          checklists: [],
        };

        this.approvalService.AlternateBulkApprove(postData).subscribe(
          async (data) => {
            if (data.responseCode != "00") {
              this.messageService.add({
                severity: "error",
                summary: "Notice",
                detail: data.responseMsg,
              });
              this.ResetMessageToaster();
              return;
            }

            this.messageService.add({
              severity: "success",
              summary: "Approved",
              detail: "Operation Successful",
            });
            this.ResetMessageToaster();
            this.PerformFinalApprovalCheck(
              this.approvalEntityInView,
              postData.alternateProfileId
            );
          },
          (error) => {
            console.log("Error: " + JSON.stringify(error));
            this.messageService.add({
              severity: "error",
              summary: "Notice",
              detail:
                "Unable to act on this approval entity at the moment.. Reason: [" +
                (error ? error.error.message : "request failed - permission") +
                "]",
            });
            this.ResetMessageToaster();
          }
        );
      },
    });
  }

  OpenChecklistDialogue(item: GetPendingApprovalsBulkView): boolean {
    this.approvalRequiredChecklists = this.allChecklists.filter(
      (x) =>
        (x.typeName == item.typeName || x.typeName == item.description) &&
        x.isRequired &&
        x.approverLevelId == null
    );
    this.approvalOptionalChecklists = this.allChecklists.filter(
      (x) =>
        (x.typeName == item.typeName || x.typeName == item.description) &&
        !x.isRequired &&
        x.approverLevelId == null
    );
    this.PopulateSpecificLevelChecklist(null, item);
    if (
      this.approvalRequiredChecklists.length == 0 &&
      this.approvalOptionalChecklists.length == 0
    )
      return false;

    this.bulkEntityInView = item;
    this.openChecklistDialogue = true;

    return true;
  }

  HideChecklistDialogue() {
    this.openChecklistDialogue = false;
    this.bulkEntityInView = null;
  }

  ProceedBulkExecution() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Executing Bulk Approval...",
    });

    const postData: BulkApprovalExecutionVM = {
      uniquqIdentifier: this.bulkEntityInView.id,
      approvalType: this.bulkEntityInView.typeName,
      joinedUniqueIdentifiers: this.bulkEntityInView.joinedApprovalIds,
      checklists: [],
    };
    this.approvalRequiredChecklists.forEach((x) =>
      postData.checklists.push({
        approvalChecklistId: x.id,
        isDeclaredCompleted: x.isDeclaredCompleted,
        isDeclaredComment: x.isDeclaredComment,
      })
    );
    this.approvalOptionalChecklists.forEach((x) =>
      postData.checklists.push({
        approvalChecklistId: x.id,
        isDeclaredCompleted: x.isDeclaredCompleted,
        isDeclaredComment: x.isDeclaredComment,
      })
    );

    if (
      this.approvalRequiredChecklists.find((x) => !x.isDeclaredCompleted) !=
      null
    ) {
      this.messageService.add({
        severity: "error",
        summary: "You must check all required checklist before you can proceed",
      });
      return;
    }

    this.apiLoadingService.show();
    this.approvalService.BulkApprovalExecution(postData).subscribe(
      async (data) => {
        if (data.responseCode != "00") {
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail: data.responseMsg,
          });
          this.apiLoadingService.hide();
          this.HideChecklistDialogue();
          return;
        }

        this.messageService.add({
          severity: "success",
          summary: "Completed",
          detail: "Approval Entity Executed Successfully...",
        });
        this.showDetails = false;
        this.apiLoadingService.hide();
        this.HideChecklistDialogue();
        this.FetchPendingApprovals();
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to execute approval entity at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
        this.apiLoadingService.hide();
        this.HideChecklistDialogue();
      }
    );
  }

  OpenChecklistDialogueAlt(item: AllowedAlternateApproversVM): boolean {
    this.approvalRequiredChecklists = this.allChecklists.filter(
      (x) =>
        (x.typeName == this.approvalEntityInView.typeName ||
          x.typeName == this.approvalEntityInView.description) &&
        x.isRequired &&
        x.approverLevelId == null &&
        !x.isNonPrinicipalLevel
    );
    this.approvalOptionalChecklists = this.allChecklists.filter(
      (x) =>
        (x.typeName == this.approvalEntityInView.typeName ||
          x.typeName == this.approvalEntityInView.description) &&
        !x.isRequired &&
        x.approverLevelId == null &&
        !x.isNonPrinicipalLevel
    );
    this.PopulateSpecificLevelChecklist(item.userId, null);
    if (
      this.approvalRequiredChecklists.length == 0 &&
      this.approvalOptionalChecklists.length == 0
    )
      return false;

    this.bulkEntityInViewAlt = item;
    this.openChecklistAltDialogue = true;

    return true;
  }

  HideChecklistDialogueAlt() {
    this.openChecklistAltDialogue = false;
    this.bulkEntityInViewAlt = null;
  }

  ProceedAltApproval() {
    const postData: AlternateBulkApproveVM = {
      uniquqIdentifier: this.approvalEntityInView.id,
      approvalType: this.approvalEntityInView.typeName,
      isApproved: true,
      joinedUniqueIdentifiers: this.approvalEntityInView.joinedApprovalIds,
      comment: this.declineComment,
      alternateProfileId: this.bulkEntityInViewAlt.userId,
      checklists: [],
    };
    this.approvalRequiredChecklists.forEach((x) =>
      postData.checklists.push({
        approvalChecklistId: x.id,
        isDeclaredCompleted: x.isDeclaredCompleted,
        isDeclaredComment: x.isDeclaredComment,
      })
    );
    this.approvalOptionalChecklists.forEach((x) =>
      postData.checklists.push({
        approvalChecklistId: x.id,
        isDeclaredCompleted: x.isDeclaredCompleted,
        isDeclaredComment: x.isDeclaredComment,
      })
    );

    if (
      this.approvalRequiredChecklists.find((x) => !x.isDeclaredCompleted) !=
      null
    ) {
      this.messageService.add({
        severity: "error",
        summary: "You must check all required checklist before you can proceed",
      });
      this.ResetMessageToaster();
      return;
    }

    this.confirmationService.confirm({
      message:
        "You are about to approve this approval entity as an alternate for " +
        this.bulkEntityInViewAlt.userName +
        ". Do you still wish to proceed?",
      accept: () => {
        this.HideChecklistDialogueAlt();
        this.HideAltApprovalDialogue();

        this.approvalService.AlternateBulkApprove(postData).subscribe(
          async (data) => {
            if (data.responseCode != "00") {
              this.messageService.add({
                severity: "error",
                summary: "Notice",
                detail: data.responseMsg,
              });
              return;
            }

            this.messageService.add({
              severity: "success",
              summary: "Removed",
              detail: "Operation Successful",
            });
            this.ResetMessageToaster();
            this.PerformFinalApprovalCheck(
              this.approvalEntityInView,
              postData.alternateProfileId
            );
          },
          (error) => {
            console.log("Error: " + JSON.stringify(error));
            this.messageService.add({
              severity: "error",
              summary: "Notice",
              detail:
                "Unable to act on this approval entity at the moment.. Reason: [" +
                (error ? error.error.message : "request failed - permission") +
                "]",
            });
            this.ResetMessageToaster();
          }
        );
      },
    });
  }

  FilterBulkApprovals() {
    this.allPendingApprovalsView = this.allPendingApprovalsViewData;
    if (this.theInitiator) {
      this.allPendingApprovalsView = this.allPendingApprovalsView.filter(
        (x) => x.createdById == this.theInitiator.id
      );
    }

    if (this.theEndorsementType) {
      if (this.theEndorsementType.isEndorsementType) {
        this.allPendingApprovalsView = this.allPendingApprovalsView.filter(
          (x) =>
            x.typeName == "Endorsements" &&
            x.description == this.theEndorsementType.key
        );
      } else {
        this.allPendingApprovalsView = this.allPendingApprovalsView.filter(
          (x) => x.typeName == this.theEndorsementType.key
        );
      }
    }

    if (this.dateFrom) {
      this.allPendingApprovalsView = this.allPendingApprovalsView.filter(
        (x) => x.createdAt >= this.dateFrom
      );
    }

    if (this.dateTo) {
      this.allPendingApprovalsView = this.allPendingApprovalsView.filter(
        (x) => x.createdAt <= this.dateTo
      );
    }

    this.GroupApprovalsView();
  }

  DisplayApproverAlternates(approverUserId: number) {
    this.openApproverAlternate = true;
    this.loadingApproverAlternates = true;

    this.approvalService.GetApproverAlternates(approverUserId).subscribe(
      async (data) => {
        if (data.responseCode != "00") {
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail: data.responseMsg,
          });
          this.loadingAlternateOptions = false;
          return;
        }

        this.approverAlternates = data.responseData;
        this.loadingApproverAlternates = false;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all alternate approval profiles at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
        this.loadingApproverAlternates = false;
      }
    );
  }

  HideApproverAlternate() {
    this.openApproverAlternate = false;
  }

  ResetFilterBulkApprovals() {
    this.allPendingApprovalsView = this.allPendingApprovalsViewData;
    this.theInitiator = null;
    this.theEndorsementType = null;
    this.dateFrom = null;
    this.dateTo = null;
    this.GroupApprovalsView();
  }

  GetAlternateApprovalType(): string {
    let type: string = this.entityForAlternate.typeName;
    if (type == "Endorsements") type = this.entityForAlternate.description;

    return type;
  }

  async LoadApprovalDetailInMem(item: GetPendingApprovalsBulkView) {
    const postData: GetBulkApprovalViewVM = {
      uniquqIdentifier: item.id,
      approvalType: item.typeName,
      joinedUniqueIdentifiers: item.joinedApprovalIds,
    };
    this.approvalService.GetBulkApprovalView(postData).subscribe(
      async (data) => {
        if (data.responseCode != "00") {
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail: data.responseMsg,
          });
          return;
        }

        this.specificApproverLevelInfo = data.responseData;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch  approval levels at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  PopulateSpecificLevelChecklist(
    userId: number,
    item: GetPendingApprovalsBulkView
  ) {
    let userIdToUse = userId ?? this.loggedInUser;
    let levelIds: number[] = [];
    this.specificApproverLevelInfo
      .filter((x) => x.id == userIdToUse)
      .forEach((appLevel) =>
        appLevel.levelIds.forEach((levelId) => {
          if (levelIds.find((x) => x == levelId) == null) {
            levelIds.push(levelId);
          }
        })
      );
    levelIds.forEach((levelId) => {
      if (item) {
        this.approvalRequiredChecklists.push(
          ...this.allChecklists.filter(
            (x) =>
              (x.typeName == item.typeName || x.typeName == item.description) &&
              x.isRequired &&
              (x.approverLevelId == levelId ||
                (levelId == 0 && x.isNonPrinicipalLevel))
          )
        );
        this.approvalOptionalChecklists.push(
          ...this.allChecklists.filter(
            (x) =>
              (x.typeName == item.typeName || x.typeName == item.description) &&
              !x.isRequired &&
              (x.approverLevelId == levelId ||
                (levelId == 0 && x.isNonPrinicipalLevel))
          )
        );
      } else {
        this.approvalRequiredChecklists.push(
          ...this.allChecklists.filter(
            (x) =>
              (x.typeName == this.approvalEntityInView.typeName ||
                x.typeName == this.approvalEntityInView.description) &&
              x.isRequired &&
              (x.approverLevelId == levelId ||
                (levelId == 0 && x.isNonPrinicipalLevel))
          )
        );
        this.approvalOptionalChecklists.push(
          ...this.allChecklists.filter(
            (x) =>
              (x.typeName == this.approvalEntityInView.typeName ||
                x.typeName == this.approvalEntityInView.description) &&
              !x.isRequired &&
              (x.approverLevelId == levelId ||
                (levelId == 0 && x.isNonPrinicipalLevel))
          )
        );
      }
    });
  }

  HideInfoDialogue() {
    this.openInfoDialogue = false;
  }

  OptOutOfBulkApprovalInsight() {
    if (this.selectedInsightsOptions.length <= 0)
      return this.HideInfoDialogue();

    this.approvalService.OptOutOfApprovalInsights().subscribe(
      async (data) => {
        if (data.responseCode != "00") {
          this.messageService.add({
            severity: "error",
            summary: data.responseMsg,
          });
          return;
        }

        this.messageService.add({
          severity: "success",
          summary: "Opted Out Successfully...",
        });
        this.HideInfoDialogue();
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to perform action at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  async PerformFinalApprovalCheck(
    item: GetPendingApprovalsBulkView,
    approvedUserId: number
  ) {
    this.openChecklistDialogue = false;
    item.pendingParticipantsUserIds = item.pendingParticipantsUserIds.filter(
      (x) => x != approvedUserId
    );
    let isFinalApprover = item.pendingParticipantsUserIds.length == 0;

    this.PerformBulkApprovalNotification(item, approvedUserId);
    if (isFinalApprover) {
      this.executionPayload = {
        uniquqIdentifier: item.id,
        approvalType: item.typeName,
        joinedUniqueIdentifiers: item.joinedApprovalIds,
      };
      this.openFAExecutionDialogue = true;
      return;
    }
  }

  async PerformFinalApprovalCheckExecution() {
    this.openFAExecutionDialogue = false;
    this.apiLoadingService.show();
    this.approvalService.BulkApprovalExecution(this.executionPayload).subscribe(
      async (data) => {
        if (data.responseCode != "00") {
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail: data.responseMsg,
          });
          this.apiLoadingService.hide();
          return;
        }

        this.messageService.add({
          severity: "success",
          summary: "Completed",
          detail: "Approval Entity Executed Successfully...",
        });
        this.showDetails = false;
        this.apiLoadingService.hide();
        this.FetchPendingApprovals();
        this.HideFAExecuteialog();
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to execute approval entity at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
        this.apiLoadingService.hide();
        this.HideFAExecuteialog();
      }
    );
  }

  HideFAExecuteialog() {
    this.openFAExecutionDialogue = false;
    this.executionPayload = null;
  }

  GetApproverLevelSequence(level: string): string {
    let applevel = this.allApprovalLevels.find(
      (x) => x.caption.trim() == level.trim()
    );
    if (applevel) {
      let levelsequence = this.allApprovalLevelSequences.find(
        (x) =>
          x.approverLevelId == applevel.id &&
          (this.approvalEntityInView.typeName == x.typeName ||
            this.approvalEntityInView.description == x.typeName)
      );
      if (levelsequence) {
        return levelsequence.sequence + "";
      }
    }

    if (level.toLowerCase().includes("principal") && !applevel) {
      let levelsequence = this.allApprovalLevelSequences.find(
        (x) =>
          x.isNonPrinicipalLevel &&
          (this.approvalEntityInView.typeName == x.typeName ||
            this.approvalEntityInView.description == x.typeName)
      );
      if (levelsequence) {
        return levelsequence.sequence + "";
      }
    }

    return "N/A";
  }

  CanApproveBasedOnSequence(userId: number): boolean {
    let msg =
      "system is currently unable to detect the next approval sequence. Please try again later!";
    if (this.approvalEntityInView) {
      if (this.approvalEntityInView.canApproveBasedOnSequence) return true;
      if (this.approvalEntityInView.canApproveBasedOnSequenceReason) {
        this.messageService.add({
          severity: "error",
          summary:
            "Apologies, you can't proceed with this action because, " +
            this.approvalEntityInView.canApproveBasedOnSequenceReason,
        });
        this.ResetMessageToaster();
        return false;
      } else {
        if (this.approvalEntityInView.approversView) {
          let entityAppSequences = this.allApprovalLevelSequences.filter(
            (x) =>
              x.typeName == this.approvalEntityInView.typeName ||
              x.typeName == this.approvalEntityInView.description
          );
          if (entityAppSequences) {
            let approvedUsers = this.approvalEntityInView.approversView.filter(
              (x) => x.status == 1
            );
            let approvedUsersSequences: number[] = [];
            approvedUsers.forEach((appUser) => {
              appUser.levelIds.forEach((levelId) => {
                let appSequence: ApprovalLevelSequence = null;
                if (levelId == 0) {
                  appSequence = entityAppSequences.find(
                    (x) => x.isNonPrinicipalLevel
                  );
                } else {
                  appSequence = entityAppSequences.find(
                    (x) => x.approverLevelId == levelId
                  );
                }

                if (appSequence) {
                  if (
                    approvedUsersSequences.find(
                      (x) => x == appSequence.sequence
                    ) == null
                  ) {
                    approvedUsersSequences.push(appSequence.sequence);
                  }
                }
              });
            });
            let approverInView = this.approvalEntityInView.approversView.find(
              (x) => x.id == userId
            );
            if (approverInView) {
              let approverUserSequences: number[] = [];
              approverInView.levelIds.forEach((levelId) => {
                let appSequence: ApprovalLevelSequence = null;
                if (levelId == 0) {
                  appSequence = entityAppSequences.find(
                    (x) => x.isNonPrinicipalLevel
                  );
                } else {
                  appSequence = entityAppSequences.find(
                    (x) => x.approverLevelId == levelId
                  );
                }

                if (appSequence) {
                  if (
                    approverUserSequences.find(
                      (x) => x == appSequence.sequence
                    ) == null
                  ) {
                    approverUserSequences.push(appSequence.sequence);
                  }
                }
              });

              let minApproverSequence = Math.min(...approverUserSequences);
              if (minApproverSequence == 1) {
                this.approvalEntityInView.canApproveBasedOnSequence = true;
                return true;
              }
              let previousSequenceApprover = approvedUsersSequences.find(
                (x) => x == minApproverSequence - 1
              );
              if (previousSequenceApprover) {
                this.approvalEntityInView.canApproveBasedOnSequence = true;
                return true;
              }

              let appLevelSequence = entityAppSequences.find(
                (x) => x.sequence == minApproverSequence - 1
              );
              if (appLevelSequence) {
                let appLevelSequenceCaption = this.allApprovalLevels.find(
                  (x) => x.id == appLevelSequence.approverLevelId
                );
                let levelStrs: string[] = [];
                this.approvalEntityInView.approversView.forEach((view) => {
                  let levelStrings = view.level.split("--and--");
                  levelStrings.forEach((lev) => {
                    if (lev) levelStrs.push(lev);
                  });
                });
                let levelExists = levelStrs.find(
                  (x) => x == appLevelSequenceCaption.caption
                );
                if (levelExists) {
                  this.approvalEntityInView.canApproveBasedOnSequenceReason =
                    "Approver Level '" +
                    appLevelSequenceCaption.caption +
                    "' with sequence " +
                    appLevelSequence.sequence +
                    " is required to approve before you can.";
                  this.approvalEntityInView.canApproveBasedOnSequence = false;
                  msg =
                    this.approvalEntityInView.canApproveBasedOnSequenceReason;
                } else {
                  this.approvalEntityInView.canApproveBasedOnSequence = true;
                  return true;
                }
              } else {
                // msg = "Invalid Sequence Configuration";
                this.approvalEntityInView.canApproveBasedOnSequence = true;
                return true;
              }
            } else {
              msg = "You're not among the approving party";
            }
          }
        }
      }
    }

    this.messageService.add({
      severity: "error",
      summary: "Apologies, you can't proceed with this action because, " + msg,
    });
    this.ResetMessageToaster();
    return false;
  }

  async PerformBulkApprovalNotification(
    item: GetPendingApprovalsBulkView,
    approvedUserId: number
  ) {
    let list: string = "<ul>";
    let receipentList: string[] = [this.loggedInUser.email];
    item.approversView.forEach((x) => {
      if (x.id == approvedUserId) x.status = 1;
      list += "<li>";
      list += this.GetUserName(x.id);
      list += " - ";
      list +=
        x.status == 1
          ? "<b style='color:green'>APPROVED</b>"
          : x.status == 2
          ? "<b style='color:red'>DECLINED</b>"
          : "<b style='color:blue'>PENDING APPROVAL</b>";
      list += "</li>";
      receipentList.push(this.GetUserEmail(x.id));
    });
    list += "</ul>";

    let mailReq: MailRequest = {
      subject: item.typeName + " Bulk Approval Notification",
      // recipients: ["adebayo.salami@halogen-group.com"],
      recipients: receipentList,
      message:
        "A new approval has just been made on " +
        item.description +
        " request -" +
        item.name +
        " initiated by <b>" +
        this.GetUserName(item.createdById) +
        "</b> on " +
        this.FormatDateString(item.createdAt) +
        ".</p><br/>" +
        list +
        "<br /> This info is generated to keep all participating approvers up-to-date with this request approval status.",
    };
    this.mailService.SendNotification(mailReq).subscribe(
      async (data) => {
        if (data) {
          console.log(data);
        }
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
      }
    );
  }

  ViewServiceCostAnalysis() {
    this.openCostAnalysisDialogue = true;
  }

  CloseAnalysisDialogue() {
    this.loadingServiceCLI = false;
    this.openCostAnalysisDialogue = false;
  }

  get RunGrandTotalCostAnalysis(): number {
    let grandTotal = 0;
    if (this.allServiceDetails) {
      this.allServiceDetails
        .filter((x) => !x.skipRendering && !x.isTotal)
        .forEach((serviceDetail) => {
          grandTotal += serviceDetail.totalAmt;
          if (serviceDetail.tiedApprovalDetail)
            grandTotal += serviceDetail.tiedApprovalDetail.totalAmt;
          if (serviceDetail.serviceCostAnalysisLogs) {
            serviceDetail.serviceCostAnalysisLogs.forEach((analysis) => {
              grandTotal -= analysis.quantity * analysis.unitPrice;
            });
          }
        });
    }
    return grandTotal;
  }

  async LoadCostAnalysis() {
    let quoteServiceIds = this.allServiceDetails
      .filter((x) => x.quoteServiceId)
      .map((x) => x.quoteServiceId);
    if (quoteServiceIds && quoteServiceIds.length > 0) {
      this.servicesService
        .GetQuoteServiceCostAnalysisWithServiceAnalysis({
          quoteServiceIds: quoteServiceIds,
        })
        .subscribe(
          async (data) => {
            if (data.responseCode != "00") {
              this.messageService.add({
                severity: "error",
                summary: data.responseMsg,
              });
              return;
            }
            this.allServiceDetails.forEach((serviceDetail) => {
              serviceDetail.renderServiceName = serviceDetail.serviceName;
              if (serviceDetail.quoteServiceAdminDirectTie) {
                if (!serviceDetail.skipRendering) {
                  let tiedServiceDit = this.allServiceDetails.find(
                    (x) =>
                      x.quoteServiceAdminDirectTie ==
                        serviceDetail.quoteServiceAdminDirectTie &&
                      x.serviceName != serviceDetail.serviceName
                  );
                  if (tiedServiceDit) {
                    serviceDetail.serviceCostAnalysisLogs = [];
                    serviceDetail.serviceCostAnalysisLogs.push({
                      serviceCostAnalysisLineItem: {
                        costCaption: "AmountCharged",
                        costDescription: "",
                      },
                      quantity: 0,
                      unitPrice: 0,
                    });
                    tiedServiceDit.skipRendering = true;
                    serviceDetail.tiedApprovalDetail = tiedServiceDit;
                    serviceDetail.renderServiceName +=
                      " + " + tiedServiceDit.serviceName;
                    serviceDetail.serviceCostAnalysisLogs.push(
                      ...data.responseData.filter(
                        (x) =>
                          x.quoteServiceId == serviceDetail.quoteServiceId ||
                          x.quoteServiceId == tiedServiceDit.quoteServiceId
                      )
                    );
                    serviceDetail.serviceCostAnalysisLogs.push({
                      serviceCostAnalysisLineItem: {
                        costCaption: "Margin",
                        costDescription: "",
                      },
                      quantity: 0,
                      unitPrice: 0,
                    });
                  }
                }
              } else {
                serviceDetail.serviceCostAnalysisLogs = [];
                serviceDetail.serviceCostAnalysisLogs.push({
                  serviceCostAnalysisLineItem: {
                    costCaption: "AmountCharged",
                    costDescription: "",
                  },
                  quantity: 0,
                  unitPrice: 0,
                });
                serviceDetail.serviceCostAnalysisLogs.push(
                  ...data.responseData.filter(
                    (x) => x.quoteServiceId == serviceDetail.quoteServiceId
                  )
                );
                serviceDetail.serviceCostAnalysisLogs.push({
                  serviceCostAnalysisLineItem: {
                    costCaption: "Margin",
                    costDescription: "",
                  },
                  quantity: 0,
                  unitPrice: 0,
                });
              }
            });
          },
          (error) => {
            console.log("Error: " + JSON.stringify(error));
            this.messageService.add({
              severity: "error",
              summary: "Notice",
              detail:
                "Unable to load quote service cost analysis at the moment.. Reason: [" +
                (error ? error.error.message : "request failed - permission") +
                "]",
            });
          }
        );
    }
  }

  RunCostAnalysis(item: GetBulkApprovalDetailViewList): number {
    let total = item.totalAmt;
    if (item.tiedApprovalDetail) total += item.tiedApprovalDetail.totalAmt;
    if (item.serviceCostAnalysisLogs) {
      item.serviceCostAnalysisLogs.forEach(
        (lineItem) => (total -= lineItem.quantity * lineItem.unitPrice)
      );
    }

    return total;
  }
}
