import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable()
export class QualificationsService {

  constructor(public http:HttpClient) { }

  getQualificationData(){
    return this.http.get(
      environment.baseUrl + "contact/GetLeadClassificationsData"
    )
  }

  getAllContactsAttachedToSuspects(suspectId:number){
    return this.http.get(
      environment.baseUrl + "contact/GetContactsAttachedToSuspects/" + suspectId
    )
  }

  getQualificationDataById(createdBy:any){
    return this.http.get(
      environment.baseUrl + "contact/GetLeadClassificationsDataById/"+ createdBy
    )
  }

  getQualificationDataByDate(startDate:any,EndDate:any){
    return this.http.get(
      environment.baseUrl + "contact/GetLeadClassificationsDataByDate/"+ startDate + "/"+ EndDate
    )
  }

  getAllDevisions(){
    return this.http.get(
      environment.baseUrl + "Contact/GetAllQuotesWithDivisions"
    )
  }

}
