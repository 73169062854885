import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import {
  Account,
  AccountClass,
  AccountStatement,
  AccountVoucherType,
  CommonResponse,
  Contract,
  ContractAutoRenewPolicy,
  ContractService,
  CustomerDivision,
  GroupType,
  Invoice,
  SbuAttachment,
  StrategicBusinessUnit,
  User,
} from "../../../interfaces/home";
import { ConfirmationService, MessageService, TreeNode } from "primeng/api";
import { BankService } from "../../../services/bank.service";
import { CustomerDivisionService } from "../../../services/customer-division.service";
import { GroupTypeService } from "../../../services/group-type.service";
import { InvoiceService } from "../../../services/invoice.sevice";
import { DialogService, DynamicDialogRef } from "primeng/dynamicdialog";
import { InvoicesComponent } from "./invoices/invoices.component";
import { KeyPersonnelComponent } from "./key-personnel/key-personnel.component";
import { ClientBeneficiaryService } from "../../../services/client-beneficiary.service";
import { environment } from "../../../../environments/environment";
import { DeliverableDetailsComponent } from "./deliverable-details/deliverable-details.component";
import { TaskDetailsComponent } from "./task-details/task-details.component";
import { BreadcrumbService } from "src/app/breadcrumb.service";
import { ActivatedRoute } from "@angular/router";
import { DomSanitizer } from "@angular/platform-browser";
import { EngagementContactsService } from "../../engagement-contacts/engagement-contacts.service";
import { AccountVoucherTypeService } from "src/app/services/account-voucher-type.service";
import { ViewAccountStatementComponent } from "../../finance/account-statement/view-account-statement/view-account-statement.component";
import { AccountService } from "src/app/services/account.service";
import { Complaint } from "src/app/interfaces/complaint";
import { ComplaintService } from "src/app/services/complaint.service";
import { ContractServiceService } from "src/app/services/contract-service.service";
import { ContractsService } from "src/app/services/contracts.service";
import { UserService } from "src/app/services/user.service";
import { FireBaseAuthService } from "src/app/services/fire-base-auth.service";
import { StrategicBusinessUnitService } from "src/app/services/strategic-business-unit.service";

@Component({
  selector: "app-client-view",
  templateUrl: "client-view.component.html",
  styleUrls: ["client-view.component.css"],
  providers: [MessageService, ConfirmationService, DialogService],
})
export class ClientViewComponent implements OnInit {
  customerDivisions: any[];
  fetchingCustomerDivisions = false;
  fetchingGroupTypes = false;
  groupTypes: GroupType[];
  selectedGroupType: GroupType;
  valRadio: string;
  filteredClients: any[];
  selectedClient: any;
  customerDivisionInformation: any;
  fetchingCustomerDivisionInformation = false;
  selectedContract: any;
  contracts: any[];
  allSelectedClientContracts: Contract[] = [];
  primaryContactFullName: string;
  secondaryContactFullName: string;
  contractServices: ContractService[];
  filteredContractServices: ContractService[];
  fetchingContractServices = false;
  selectedContractService: any;
  contractServiceCols: any;
  contractServiceExportColumns: any;
  invoices: Invoice[];
  invoicesRef: DynamicDialogRef;
  keyPersonnelRef: DynamicDialogRef;
  taskFulfillments: any;
  fetchingBeneficiaries = false;
  beneficiaries: any[];
  defaultImageUrl: string;
  taskDetailsRef: DynamicDialogRef;
  deliverableDetailsRef: DynamicDialogRef;
  fetchingTaskAndDeliverables: boolean;
  includeExpiredContractService: boolean = false;
  _customerDivisionId: number;
  openAccountStatementView: boolean;
  tranactionStartDate: Date;
  tranactionEndDate: Date;
  voucherTypes: AccountVoucherType[];
  selectedVoucherTypes: AccountVoucherType[] = [];
  loadingAccountStatements: boolean;
  routeId: string;
  groupTypeName: string;
  customerDivisionId: string;
  suspectContact: any[];
  statements: AccountStatement[] = [];
  aggregatedStatements: AccountStatement[] = [];
  selectedStatement: any;
  accountStatementCols: any[];
  exportColumns: any;
  viewAccountStatementRef: DynamicDialogRef;
  statementForm: FormGroup;
  complaints: Complaint[];
  selectedComplaint: Complaint;
  complaintCols: any[];
  openComplaintDialogue: boolean;
  complaintToDisplay: Complaint;
  endorsementTree: TreeNode[];
  endorsementCols: any[];
  selectedZoneTree: any;
  allUsers: User[] = [];
  customerDivisionInView: CustomerDivision;
  allSbuAttachments: SbuAttachment[] = [];
  allSbus: StrategicBusinessUnit[] = [];
  allAutoRenewPolicies: ContractAutoRenewPolicy[] = [];
  showOnlyActive: boolean

  constructor(
    private userService: UserService,
    public contractsService: ContractsService,
    public complaintService: ComplaintService,
    private strategicBusinessUnitService: StrategicBusinessUnitService,
    public contractServiceService: ContractServiceService,
    public engagementService: EngagementContactsService,
    private accountService: AccountService,
    public accountVoucherTypeService: AccountVoucherTypeService,
    private sanitizer: DomSanitizer,
    public formBuilder: FormBuilder,
    private bankService: BankService,
    private customerDivisionService: CustomerDivisionService,
    private groupTypeService: GroupTypeService,
    private invoiceService: InvoiceService,
    private clientBeneficiaryService: ClientBeneficiaryService,
    public messageService: MessageService,
    public dialogService: DialogService,
    public confirmationService: ConfirmationService,
    private route: ActivatedRoute,
    private breadcrumbService: BreadcrumbService,
    private fireStorageService: FireBaseAuthService
  ) {}

  ngOnInit(): void {
    this.breadcrumbService.setItems([
      { label: "Client-View", routerLink: ["home/client-view"] },
    ]);

    this.routeId = this.route.snapshot.paramMap.get("id") || null;
    if (this.routeId) {
      const splitted = this.routeId.split("-");
      if (splitted.length === 1) {
        this.groupTypeName = splitted[0];
      } else if (splitted.length === 2) {
        this.groupTypeName = splitted[0];
        this.customerDivisionId = splitted[1];
      }
    }

    this.FetchAutoRenewPolicies();
    this.FetchAllStrategicBusinessUnits();
    this.FetchAllSBUAttachments();
    this.fetchGroupTypes();
    this.FetchVoucherTypes();
    this.FetchAllUsers();
    this.valRadio = "Client Name";

    this.contractServiceCols = [
      { field: "service", header: "Service" },
      { field: "serviceId", header: "Service Id" },
      { field: "status", header: "Status" },
    ];

    this.accountStatementCols = [
      { field: "transactionDate", header: "Transaction Date" },
      { field: "transactionId", header: "Transaction Id" },
      { field: "description", header: "Description" },
      { field: "debit", header: "Dr" },
      { field: "credit", header: "Cr" },
    ];
    this.exportColumns = this.accountStatementCols.map((col) => ({
      title: col.header,
      dataKey: col.field,
    }));

    this.complaintCols = [
      { field: "complaintType", header: "ComplaintType" },
      { field: "complaintOrigin", header: "ComplaintOrigin" },
      { field: "complaintSource", header: "ComplaintSource" },
      { field: "complainant", header: "Complainant" },
      { field: "complaintDescription", header: "ComplaintDescription" },
    ];

    this.endorsementCols = [
      { field: "serviceName", header: "Service" },
      { field: "tag", header: "Tag" },
      { field: "status", header: "Status" },
      { field: "startDate", header: "Start Date" },
      { field: "endDate", header: "End Date" },
    ];

    this.statementForm = this.formBuilder.group({
      accountClassId: [null, Validators.required],
      controlAccountId: [null, Validators.required],
      subAccountId: [null, Validators.required],
      transactionStartDate: ["", Validators.required],
      transactionEndDate: ["", Validators.required],
      voucherTypeId: [null, Validators.required],
    });

    this.contractServiceExportColumns = this.contractServiceCols.map((col) => ({
      title: col.header,
      dataKey: col.field,
    }));
    this.defaultImageUrl = environment.defaultImage;
    this.endorsementTree = [];
  }

  async FetchAllUsers() {
    this.userService.allUser().subscribe(
      async (r) => {
        var data = r.responseData ?? [];
        this.allUsers = data;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all users at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  async FetchAllSBUAttachments() {
    this.strategicBusinessUnitService.GetAllSBUAttachments().subscribe(
      async (data) => {
        if (data.responseCode != "00") {
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail: data.responseMsg,
          });
          return;
        }

        this.allSbuAttachments = data.responseData;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all sbu attachments at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  FetchAllStrategicBusinessUnits() {
    this.strategicBusinessUnitService.allStrategicBusinessUnit().subscribe(
      async (sbUnit: CommonResponse) => {
        this.allSbus = sbUnit.responseData;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all sbu units at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  async FetchAutoRenewPolicies() {
    this.contractsService.GetAutoRenewContractPolicies().subscribe(
      async (data) => {
        if (data.responseCode != "00") {
          this.messageService.add({
            severity: "error",
            summary: data.responseMsg,
          });
          return;
        }

        this.allAutoRenewPolicies = data.responseData;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary:
            "Unable to fetch auto renew contract policies at the moment.. Reason: [" +
            error.error.message +
            "]",
        });
      }
    );
  }

  fetchGroupTypes() {
    this.groupTypeService.allGroupTypeData().subscribe(
      async (data: CommonResponse) => {
        this.groupTypes = data.responseData;
        if (this.groupTypeName) {
          this.selectedGroupType = this.groupTypes.find(
            (x) => x.caption.toLowerCase() == this.groupTypeName.toLowerCase()
          );
          if (this.customerDivisionId) {
            this.fetchCustomerDivisionsByGroupType(
              this.selectedGroupType.id,
              parseInt(this.customerDivisionId)
            );
            this.fetchCustomerDivisionById(this.customerDivisionId);
          }
        }
        this.fetchingGroupTypes = false;
      },
      (error) => {
        this.fetchingGroupTypes = false;
        this.connectionError();
      }
    );
  }

  toggleExpiredContractService() {
    if (this.includeExpiredContractService)
      this.filteredContractServices = this.contractServices;
    else
      this.filteredContractServices = this.contractServices.filter(
        (x) => x.isActive
      );
  }

  getAllContactsToCustomer(id) {
    this.engagementService.getAllContactsAttachedToCustomer(id).subscribe(
      async (res: any) => {
        if (res.responseCode == "00") {
          this.suspectContact = res.responseData;
        } else {
          this.suspectContact = [];
        }
      },
      (error) => {
        this.suspectContact = [];
      }
    );
  }

  transform(image) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(image);
  }

  fetchClients() {
    this.customerDivisions = null;
    console.log("Fetch Clients Called", this.selectedGroupType);
    if (this.selectedGroupType) {
      this.fetchCustomerDivisionsByGroupType(this.selectedGroupType.id);
    }
  }

  fetchCustomerDivisionsByGroupType(id: any, preselectedClientId?: number) {
    this.customerDivisionService.getCustomerDivisionByGroupType(id).subscribe(
      async (data: CommonResponse) => {
        this.customerDivisions = data.responseData;
        if (preselectedClientId) {
          this.selectedClient = this.customerDivisions.find(
            (x) => x.clientId == preselectedClientId
          );
        }
        this.fetchingCustomerDivisions = false;
      },
      (error) => {
        this.fetchingCustomerDivisions = false;
        this.connectionError();
      }
    );
  }

  fetchCustomerDivisionById(id: any) {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Retrieving Client Information",
    });
    this.fetchingContractServices = true;

    this.customerDivisionService.getCustomerDivision(id).subscribe(
      async (result: CommonResponse) => {
        this.fetchingCustomerDivisionInformation = false;
        await this.messageService.add({
          severity: "success",
          summary: "Success",
          detail: "Retrieved Client Information Successfully",
        });

        var data = result.responseData;
        this.customerDivisionInformation = data;
        this.getAllContactsToCustomer(data?.customerId);

        this.contracts = data?.contracts;
        this.allSelectedClientContracts = data?.contracts;

        // this.taskFulfillments = result.taskFulfillments;

        this.fetchBeneficiaries(id);
        this.fetchingContractServices = false;
      },
      (error) => {
        this.fetchingCustomerDivisionInformation = false;
        this.connectionError();
        this.fetchingContractServices = false;
      }
    );
  }

  fetchBeneficiaries(clientId) {
    this.fetchingBeneficiaries = true;
    this.clientBeneficiaryService.allClientBeneficiaryData().subscribe(
      async (result: CommonResponse) => {
        const beneficiaries = result.responseData ?? [];
        const filtered = beneficiaries.filter((x) => x.clientId === clientId);

        const benTableData = [];

        for (const ben of filtered) {
          if (ben.isPrincipal) {
            ben.dependents = [];
            benTableData.push(ben);
          } else {
            const benData = benTableData.find(
              (x) => x.beneficiaryFamilyCode === x.beneficiaryFamilyCode
            );
            benData.dependents.push(ben);
          }
        }

        console.log("The beneficiaries ", benTableData);
        this.beneficiaries = benTableData;

        this.fetchingBeneficiaries = false;
      },
      (error) => {
        this.fetchingBeneficiaries = false;
        this.messageService.add({
          severity: "error",
          summary: "Failed",
          detail: `An error occurred while fetching beneficiaries ${error}`,
        });
      }
    );
  }

  private connectionError() {
    this.messageService.add({
      severity: "error",
      summary: "Failed",
      detail: "Connection Error, Please try again",
    });
  }

  filterClients(event) {
    const filtered: any[] = [];
    const query = event.query;
    if (this.customerDivisions) {
      if (this.valRadio === "Client Id") {
        // tslint:disable-next-line:prefer-for-of
        for (let i = 0; i < this.customerDivisions.length; i++) {
          const customerDivision = this.customerDivisions[i];
          if (
            customerDivision.clientId
              .toString()
              .toLowerCase()
              .indexOf(query.toLowerCase()) === 0
          ) {
            filtered.push(customerDivision);
          }
        }
      } else {
        // tslint:disable-next-line:prefer-for-of
        for (let i = 0; i < this.customerDivisions.length; i++) {
          const customerDivision = this.customerDivisions[i];
          if (
            customerDivision.clientName
              .toLowerCase()
              .indexOf(query.toLowerCase()) !== -1
          ) {
            filtered.push(customerDivision);
          }
        }
      }

      this.filteredClients = filtered;
    }
  }

  clientSelected() {
    if (this.selectedClient && typeof this.selectedClient === "object") {
      // get us more information about this client
      this.customerDivisionInView = this.selectedClient;
      this.resetClientSpecificInformation();
      this.fetchCustomerDivisionById(this.selectedClient.clientId);
      this._customerDivisionId = this.selectedClient.clientId;
      this.FetchClientComplaintsData();
    }
  }

  //   showNoteDetails(noteDetail: any) {
  //     this.invoicesRef = this.dialogService.open(InvoicesComponent, {
  //       header: "Invoices",
  //       width: "50%",
  //       contentStyle: { "min-height": "350px", overflow: "auto" },
  //       baseZIndex: 99,
  //       data: { invoices },
  //     });

  //     this.invoicesRef.onClose.subscribe(async (res: any) => {});
  //   }

  resetClientSpecificInformation() {
    this.customerDivisionInformation = null;
    this.selectedContract = null;
    this.contracts = null;
    this.allSelectedClientContracts = []
    this.showOnlyActive = false
    this.taskFulfillments = null;
    this.beneficiaries = null;
    this.invoices = null;
  }

  selectContract(contract?: Contract) {
    console.log("Selected Contract ", contract);
    this.selectedContract = contract;
    this.contractServices = contract.contractServices;
    this.taskFulfillments = null;
    this.invoices = null;
    this.toggleExpiredContractService();
    this.LoadEndorsementTree();
  }

  viewOtherContactInfo() {
    if (this.customerDivisionInformation) {
      this.keyPersonnelRef = this.dialogService.open(KeyPersonnelComponent, {
        header: "Key Personnels",
        width: "50%",
        contentStyle: { "min-height": "350px", overflow: "auto" },
        baseZIndex: 10000,
        data: { keyPersonnels: this.customerDivisionInformation.leadKeyPeople },
      });

      this.keyPersonnelRef.onClose.subscribe(async (res: any) => {});
    }
  }

  viewContractServiceDetails(contractService: ContractService) {
    this.invoices = null;
    this.selectedContractService = contractService;
    this.getInvoicesByContractServiceId(contractService.id);
  }

  getInvoicesByContractServiceId(contractServiceId: any) {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Retrieving Invoices",
    });
    this.invoiceService
      .getInvoicesByContractDivisionId(contractServiceId)
      .subscribe(
        async (result: CommonResponse) => {
          await this.messageService.add({
            severity: "success",
            summary: "Success",
            detail: "Invoices Retrieved Successfully.",
          });
          console.log("Invoices ", result.responseCode);
          this.invoices = result.responseData;

          //this.showInvoicesTablePopUp(this.invoices);
        },
        (error) => {
          this.connectionError();
        }
      );
  }

  showInvoicesTablePopUp(invoices: Invoice[]) {
    this.invoicesRef = this.dialogService.open(InvoicesComponent, {
      header: "Invoices",
      width: "50%",
      contentStyle: { "min-height": "350px", overflow: "auto" },
      baseZIndex: 99,
      data: { invoices },
    });

    this.invoicesRef.onClose.subscribe(async (res: any) => {});
  }

  viewTaskDetails(taskFulfillment: any) {
    if (taskFulfillment) {
      this.taskDetailsRef = this.dialogService.open(TaskDetailsComponent, {
        header: "Task Details",
        width: "50%",
        contentStyle: { "min-height": "350px", overflow: "auto" },
        baseZIndex: 10000,
        data: { taskFulfillment },
      });

      this.taskDetailsRef.onClose.subscribe(async (res: any) => {});
    }
  }

  viewDeliverableDetails(deliverable: any) {
    if (deliverable) {
      this.deliverableDetailsRef = this.dialogService.open(
        DeliverableDetailsComponent,
        {
          header: "Deliverable Details",
          width: "50%",
          contentStyle: { "min-height": "350px", overflow: "auto" },
          baseZIndex: 10000,
          data: { deliverable },
        }
      );

      this.deliverableDetailsRef.onClose.subscribe(async (res: any) => {});
    }
  }

  tabSwitched(event) {
    if (event.index === 2) {
      if (!this.taskFulfillments) {
        this.fetchTaskAndDeliverables();
      }
    } else {
      console.log("Ignored event.");
    }
  }

  fetchTaskAndDeliverables() {
    this.fetchingTaskAndDeliverables = true;
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Retrieving deliverables.",
    });
    this.customerDivisionService
      .getTaskAndDeliverablesByCustomerDivisionId(
        this.customerDivisionInformation.id
      )
      .subscribe(
        async (result: CommonResponse) => {
          this.fetchingTaskAndDeliverables = false;
          await this.messageService.add({
            severity: "success",
            summary: "Success",
            detail: "Retrieved task and deliverables.",
          });
          this.taskFulfillments = result.responseData;
          console.log("Task And Deliverables ", result);
        },
        (error) => {
          this.fetchingTaskAndDeliverables = false;
          this.connectionError();
        }
      );
  }

  ShowAccountStatementViewing() {
    this.openAccountStatementView = true;
  }

  HideViewAccountStatement() {
    this.openAccountStatementView = false;
    this.loadingAccountStatements = false;
    this.statements = [];
    this.aggregatedStatements = [];
    this.selectedVoucherTypes = [];
    this.tranactionStartDate = null;
    this.tranactionEndDate = null;
  }

  FetchVoucherTypes() {
    this.accountVoucherTypeService.allAccountVoucherTypeData().subscribe(
      async (res: any) => {
        this.voucherTypes = res.responseData;
      },
      (error) => {
        this.messageService.add({
          severity: "error",
          summary: "Failed",
          detail: "Connection error, Try again later",
        });
      }
    );
  }

  SearchAccountStatements() {
    this.loadingAccountStatements = true;
    let voucherTypes: number[] = [];
    this.selectedVoucherTypes.forEach((value) => {
      voucherTypes.push(value.id);
    });

    const postData = {
      accountId: this.customerDivisionInformation.receivableAccountId,
      transactionStart: this.tranactionStartDate,
      transactionEnd: this.tranactionEndDate,
      voucherTypeIds: voucherTypes,
    };

    this.accountService.getStatements(postData).subscribe(
      async (res: CommonResponse) => {
        if (res.responseCode == "00") {
          this.statements = res.responseData?.accountDetails;
          this.aggregatedStatements = res.responseData?.aggregatedDetails;
          this.loadingAccountStatements = false;
        }
      },
      (error) => {
        this.loadingAccountStatements = false;
        this.connectionError();
      }
    );
  }

  ViewAggregatedAccountStatement() {
    this.statementForm.patchValue({
      accountClassId: environment.AssetAccounClassId,
      controlAccountId: environment.AssetControlAccountReceivableId,
      transactionStartDate: this.tranactionStartDate,
      transactionEndDate: this.tranactionEndDate,
      isAggregatedMode: true,
    });

    this.viewAccountStatementRef = this.dialogService.open(
      ViewAccountStatementComponent,
      {
        width: "900px",
        contentStyle: {
          "max-width": "100vw",
          height: "80vh",
          overflow: "auto",
        },
        baseZIndex: 100,
        data: {
          statements: this.aggregatedStatements,
          statementForm: this.statementForm,
          accountAlias: this.customerDivisionInformation.dTrackCustomerNumber,
        },
        header: "Aggregated Account Statement",
      }
    );

    this.viewAccountStatementRef.onClose.subscribe(() => {});
  }

  viewAccountStatement() {
    this.statementForm.patchValue({
      accountClassId: environment.AssetAccounClassId,
      controlAccountId: environment.AssetControlAccountReceivableId,
      transactionStartDate: this.tranactionStartDate,
      transactionEndDate: this.tranactionEndDate,
    });

    this.viewAccountStatementRef = this.dialogService.open(
      ViewAccountStatementComponent,
      {
        width: "900px",
        contentStyle: {
          "max-width": "100vw",
          height: "80vh",
          overflow: "auto",
        },
        baseZIndex: 100,
        data: {
          statements: this.statements,
          statementForm: this.statementForm,
          accountAlias: this.customerDivisionInformation.dTrackCustomerNumber,
        },
        header: "Account Statement",
      }
    );

    this.viewAccountStatementRef.onClose.subscribe(() => {});
  }

  ShowComplaintDetails(item: Complaint) {
    this.openComplaintDialogue = true;
    this.complaintToDisplay = item;
  }

  GetComplainantName(item: Complaint) {
    return item.complainant == null
      ? "No Data"
      : item.complainant.supplierName
      ? item.complainant.supplierName
      : item.complainant.divisionName
      ? item.complainant.divisionName
      : item.complainant.lastName;
  }

  HideComplaintDialog() {
    this.openComplaintDialogue = false;
    this.complaintToDisplay = null;
  }

  FetchClientComplaintsData() {
    this.complaintService
      .GetComplaintsByClientId(this._customerDivisionId)
      .subscribe(
        async (comp) => {
          this.complaints = comp.responseData;
        },
        (error) => {
          console.log("Error: " + JSON.stringify(error));
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail:
              "Unable to fetch client complaints at the moment.. Reason: [" +
              error.error.message +
              "]",
          });
        }
      );
  }

  LoadEndorsementTree() {
    this.endorsementTree = [];

    if (this.selectedContract) {
      this.contractServiceService
        .GetAllInactiveContractServicesByContractId(this.selectedContract.id)
        .subscribe(
          async (resp: CommonResponse) => {
            if (resp.responseCode == "00") {
              let inactiveContractServices: ContractService[] =
                resp.responseData;
              this.endorsementTree = [];
              this.filteredContractServices.forEach((contractService) => {
                let activeContractServiceTree: TreeNode = {
                  data: {
                    serviceName: contractService.service.name,
                    tag: contractService.uniqueTag,
                    status: contractService.isActive,
                    startDate: contractService.contractStartDate,
                    endDate: contractService.contractEndDate,
                  },
                  children: [],
                };

                inactiveContractServices
                  .filter((x) => x.uniqueTag == contractService.uniqueTag)
                  .forEach((contractServ) => {
                    activeContractServiceTree.children.push({
                      data: {
                        serviceName: contractServ.service.name,
                        tag: contractServ.uniqueTag,
                        status: false,
                        startDate: contractServ.contractStartDate,
                        endDate: contractServ.contractEndDate,
                      },
                    });
                  });

                this.endorsementTree.push(activeContractServiceTree);
              });
            } else {
              this.filteredContractServices.forEach((contractService) => {
                let activeContractServiceTree: TreeNode = {
                  data: {
                    serviceName: contractService.service.name,
                    tag: contractService.uniqueTag,
                    status: contractService.isActive,
                    startDate: contractService.contractStartDate,
                    endDate: contractService.contractEndDate,
                  },
                  children: [],
                };
                this.endorsementTree.push(activeContractServiceTree);
              });
            }
          },
          (error) => {
            console.log("Error: " + JSON.stringify(error));
            this.messageService.add({
              severity: "error",
              summary: "Notice",
              detail:
                "Unable to fetch client complaints at the moment.. Reason: [" +
                error.error.message +
                "]",
            });
          }
        );
    }
  }

  ToggleContractService() {
    if (this.selectedContract) {
      if (
        !this.customerDivisionInView.sbuId ||
        this.fireStorageService.authUserProfile.sbuId !=
          this.customerDivisionInView.sbuId
      ) {
        if (this.customerDivisionInView.sbuId) {
          let secondarySbu = this.allSbuAttachments.find(
            (x) =>
              x.strategicBusinessUnitId == this.customerDivisionInView.sbuId &&
              x.attachedSBUId == this.fireStorageService.authUserProfile.id
          );
          if (!secondarySbu) {
            this.messageService.add({
              severity: "error",
              summary:
                "Sorry, Only this client attached SBU members can perform any endorsement action on this contract.",
            });
            return;
          }
        } else {
          this.messageService.add({
            severity: "error",
            summary:
              "Sorry, Only this client is not attached to any SBU so no endorsement action be taken.",
          });
          return;
        }
      }

      this.contractsService
        .ToggleContractServiceStatus(this.selectedContract.id)
        .subscribe(
          async (data) => {
            if (data.responseCode != "00") {
              this.messageService.add({
                severity: "error",
                summary: data.responseMsg,
              });
              return;
            }

            this.messageService.add({
              severity: "success",
              summary: "Completed",
              detail: "Contract Status Updated Successfully!",
            });
            this.selectedContract.isActive = !this.selectedContract.isActive;
          },
          (error) => {
            console.log("Error: " + JSON.stringify(error));
            this.messageService.add({
              severity: "error",
              summary: "Notice",
              detail:
                "Unable to toggle contract status at the moment.. Reason: [" +
                error.error.message +
                "]",
            });
          }
        );
    } else {
      this.messageService.add({
        severity: "error",
        summary: "No Selected Contract Data",
      });
    }
  }

  GetInvoicingIntervalLabel(interval: number): string {
    if (interval == 0) return "Monthly";
    if (interval == 1) return "BiMonthly";
    if (interval == 2) return "Quarterly";
    if (interval == 3) return "Annually";
    if (interval == 4) return "BiAnnually";
    if (interval == 5) return "OneTime";
    if (interval == 6) return "Adhoc";
    if (interval == 7) return "Monthly Prorata";

    return "N/A";
  }

  GetUserName(userId: number): string {
    let user = this.allUsers.find((x) => x.id == userId);
    if (user) return user.lastName + " " + user.firstName;

    return "N/A";
  }

  GetSBUName(): string {
    if (this.customerDivisionInView) {
      let sbu = this.allSbus.find(
        (x) => x.id == this.customerDivisionInView.sbuId
      );
      if (sbu) return sbu.name;
    }

    return "N/A";
  }

  GetAutoRenewStatus(): string {
    if (this.customerDivisionInView && this.selectedContract) {
      let policy = this.allAutoRenewPolicies.find(
        (x) =>
          x.contract.customerDivisionId ==
            this.customerDivisionInView.clientId &&
          x.isActive &&
          x.contract.id == this.selectedContract.id
      );
      if (policy) return "TRUE";
    }

    return "FALSE";
  }

  FormatDateString(date: Date): string {
    let dateString = date.toString();

    try {
      // DateTimeFormatOptions
      const date: Date = new Date(dateString);
      const options: Intl.DateTimeFormatOptions = {
        year: "numeric",
        month: "long",
        day: "numeric",
      };
      const formattedDate: string = new Intl.DateTimeFormat(
        "en-US",
        options
      ).format(date);
      dateString = formattedDate;
    } catch (error) {
      console.log(error);
      console.log(date);
      console.log(
        "Error while converting date string " + date + " exception " + error
      );
    }

    return dateString;
  }

  onShowActiveContractToggleChange(){
    if(this.showOnlyActive){
      this.contracts = []
      this.allSelectedClientContracts.forEach((contract) => {
        if(contract.isActive){
          let activeContractService = contract.contractServices.find((x) => x.isActive)
          if(activeContractService){
            this.contracts.push(contract)
          }
        }
      })
    }
    else this.contracts = this.allSelectedClientContracts
  }
}
