import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../environments/environment";
import { Observable } from "rxjs";
import {
  CommonResponse,
  CreateServiceCostAnalysisItemsVM,
  GetQuoteServiceCostAnalysisVM,
  QuoteServiceCostAnalysisLog,
  SaveServiceCostAnalysisVM,
  ServiceCostAnalysisLineItem,
  UpdateServiceCostAnalysisItemsVM,
} from "../interfaces/home";

@Injectable({
  providedIn: "root",
})
export class ServicesService {
  constructor(private http: HttpClient) {}
  baseUrl = environment.baseUrl + "Services/";
  // fetch all Services in the database
  allService(): Observable<CommonResponse> {
    return this.http.get<CommonResponse>(this.baseUrl);
  }

  getUnmappedDirectService(): Observable<CommonResponse> {
    return this.http.get<CommonResponse>(
      `${environment.baseUrl}ServiceRelationships/FindAllUnmappedDirects`
    );
  }

  getAllDirectServices(): Observable<CommonResponse> {
    return this.http.get<CommonResponse>(
      `${environment.baseUrl}ServiceRelationships/FindAllDirectServices`
    );
  }

  getAllRelationships(): Observable<CommonResponse> {
    return this.http.get<CommonResponse>(
      `${environment.baseUrl}ServiceRelationships/FindAllRelationships`
    );
  }

  getServices(id: any): Observable<CommonResponse> {
    return this.http.get<CommonResponse>(this.baseUrl + id);
  }

  getServicesPendingApproval(): Observable<CommonResponse> {
    return this.http.get<CommonResponse>(
      this.baseUrl + "GetUnpublishedServices"
    );
  }

  approveService(id: any, sequence: any) {
    return this.http.put(
      this.baseUrl + "approve-service/" + id + "/" + sequence,
      {}
    );
  }

  disapproveService(id: any, sequence: any) {
    return this.http.put(
      this.baseUrl + "disapprove-service/" + id + "/" + sequence,
      {}
    );
  }

  getServicesByName(name: any): Observable<CommonResponse> {
    return this.http.get<CommonResponse>(this.baseUrl + "name/" + name);
  }

  postServices(data: any) {
    return this.http.post(this.baseUrl, data);
  }

  deleteServices(id: any) {
    return this.http.delete(this.baseUrl + id);
  }

  updateServices(id: any, data: any) {
    return this.http.put(this.baseUrl + id, data);
  }

  GetAllServiceCostAnalysisItems(): Observable<
    CommonResponse<ServiceCostAnalysisLineItem[]>
  > {
    return this.http.get<CommonResponse<ServiceCostAnalysisLineItem[]>>(
      this.baseUrl + "GetAllServiceCostAnalysisItems"
    );
  }

  CreateServiceCostAnalysisItems(
    data: CreateServiceCostAnalysisItemsVM
  ): Observable<CommonResponse<boolean>> {
    return this.http.post<CommonResponse<boolean>>(
      this.baseUrl + "CreateServiceCostAnalysisItems",
      data
    );
  }

  UpdateServiceCostAnalysisItems(
    serviceId: number,
    data: UpdateServiceCostAnalysisItemsVM
  ): Observable<CommonResponse<boolean>> {
    return this.http.put<CommonResponse<boolean>>(
      this.baseUrl + "UpdateServiceCostAnalysisItems/" + serviceId,
      data
    );
  }

  DeleteServiceCostAnalysisItems(
    serviceId: number
  ): Observable<CommonResponse<boolean>> {
    return this.http.delete<CommonResponse<boolean>>(
      this.baseUrl + "DeleteServiceCostAnalysisItems/" + serviceId
    );
  }

  SaveServiceCostAnalysis(
    data: SaveServiceCostAnalysisVM
  ): Observable<CommonResponse<boolean>> {
    return this.http.post<CommonResponse<boolean>>(
      this.baseUrl + "SaveServiceCostAnalysis",
      data
    );
  }

  GetQuoteServiceCostAnalysis(
    data: GetQuoteServiceCostAnalysisVM
  ): Observable<CommonResponse<QuoteServiceCostAnalysisLog[]>> {
    return this.http.post<CommonResponse<QuoteServiceCostAnalysisLog[]>>(
      this.baseUrl + "GetQuoteServiceCostAnalysis",
      data
    );
  }

  GetQuoteServiceCostAnalysisWithServiceAnalysis(
    data: GetQuoteServiceCostAnalysisVM
  ): Observable<CommonResponse<QuoteServiceCostAnalysisLog[]>> {
    return this.http.post<CommonResponse<QuoteServiceCostAnalysisLog[]>>(
      this.baseUrl + "GetQuoteServiceCostAnalysisWithServiceAnalysis",
      data
    );
  }
}
