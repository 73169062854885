import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {ConfirmationService, MessageService, TreeNode} from 'primeng/api';
import {Account, AccountClass, AccountStatement, AccountVoucherType, CommonResponse, ControlAccount} from '../../../interfaces/home';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {RoleService} from '../../../services/role.service';
import {AccountService} from '../../../services/account.service';
import {AccountClassService} from '../../../services/account-class.service';
import {ControlAccountService} from '../../../services/control-account.service';
import {AccountVoucherTypeService} from '../../../services/account-voucher-type.service';
import {ActivatedRoute} from '@angular/router';
import { BreadcrumbService } from 'src/app/breadcrumb.service';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ViewAccountStatementComponent } from './view-account-statement/view-account-statement.component';

@Component({
    selector: 'app-account-statement',
    templateUrl: './account-statement.component.html',
    styleUrls: ['./account-statement.component.css'],
    providers: [MessageService, ConfirmationService, DialogService]
})

export class AccountStatementComponent implements OnInit {

    cols: any[];
    exportColumns: any;
    statementForm: FormGroup;
    selectedAccountClassId: AccountClass = null;
    selectedControlAccountId: ControlAccount = null;
    selectedSubAccountId: Account = null;
    selectedVoucherTypeId: AccountVoucherType = null;

    subAccountName: string;

    accountClasses: AccountClass[];
    controlAccounts: ControlAccount[];
    allControlAccounts: ControlAccount[];
    subAccounts: Account[];
    voucherTypes: AccountVoucherType[]
    selectedVoucherTypes: AccountVoucherType[];
    statements: AccountStatement[]

    fetchingStatements = false;
    selectedStatement: any;

    private routeId: string;
    private controlAccountId: string;
    private subAccountId: string;

    viewAccountStatementRef: DynamicDialogRef;

    constructor(
        public formBuilder: FormBuilder,
        private accountService: AccountService,
        public messageService: MessageService,
        public accountClassService: AccountClassService,
        public controlAccountService: ControlAccountService,
        public accountVoucherTypeService: AccountVoucherTypeService,
        public confirmationService: ConfirmationService,
        public dialogService: DialogService,
        private route: ActivatedRoute,
        private breadcrumbService: BreadcrumbService
    ) { }

    ngOnInit(): void {

        this.breadcrumbService.setItems([
            { label: 'Finance', routerLink: ['home/account-statement'] },
            { label: 'Account-Statement', routerLink: ['home/account-statement'] }
          ]);

        this.routeId = this.route.snapshot.paramMap.get('id') || null;
        if(this.routeId){
            const splitted = this.routeId.split('-');
            if (splitted.length === 1){
                this.controlAccountId = splitted[0];
            }else if(splitted.length === 2){
                this.controlAccountId = splitted[0];
                this.subAccountId = splitted[1];
            }
        }

        this.getAccountClasses();
        this.getControlAccounts();
        this.getVoucherTypes();

        this.cols = [
            { field: 'transactionDate', header: 'Transaction Date' },
            { field: 'transactionId', header: 'Transaction Id' },
            { field: 'description', header: 'Description' },
            { field: 'debit', header: 'Dr' },
            { field: 'credit', header: 'Cr' }
        ];
        this.exportColumns = this.cols.map(col => ({title: col.header, dataKey: col.field}));

        this.statementForm = this.formBuilder.group({
            accountClassId: [null, Validators.required],
            controlAccountId: [null, Validators.required],
            subAccountId: [null, Validators.required],
            transactionStartDate: ['', Validators.required],
            transactionEndDate: ['', Validators.required],
            voucherTypeId: [null, Validators.required],
        })
    }

    getAccountClasses(){
        this.accountClassService.allAccountClasses().subscribe( async (r: CommonResponse) => {
            if(r.responseCode=='00'){
                this.accountClasses = r.responseData;
                if(this.controlAccountId){
                    this.selectedAccountClassId = this.accountClasses.find(x => x.controlAccounts.some(y => y.id.toString() === this.controlAccountId));
                }
            } else {
                //this.messageService.add(r.responseMsg);
            }
        }, error => {
            this.messageService.add({severity:'error', summary: 'Failed', detail:'Connection error, Try again later'});
        });
    }

    getControlAccounts(){
        this.controlAccountService.allControlAccounts().subscribe( async (r: CommonResponse) => {
            if(r.responseCode=='00'){
                this.allControlAccounts = r.responseData;

                if(this.controlAccountId){
                    this.controlAccounts = this.allControlAccounts;
                    this.selectedControlAccountId = this.allControlAccounts.find(x => x.id.toString() === this.controlAccountId);
                }

                if(this.subAccountId){
                    if(this.selectedControlAccountId){
                        const controlAccount = this.allControlAccounts.find(x => x.id === this.selectedControlAccountId.id);
                        this.subAccounts = controlAccount.accounts;
                        this.selectedSubAccountId = this.subAccounts.find(x => x.id.toString() === this.subAccountId);
                    }
                }

            } else {
              //  this.messageService.add(res.message);
            }
        }, error => {
            this.messageService.add({severity:'error', summary: 'Failed', detail:'Connection error, Try again later'});
        });
    }

    getVoucherTypes(){
        this.accountVoucherTypeService.allAccountVoucherTypeData().subscribe( async (res: any) => {
            this.voucherTypes = res.responseData;
        },error => {
            this.messageService.add({severity:'error', summary: 'Failed', detail:'Connection error, Try again later'});
        });
    }

    getStatements(){
        const voucherTypes = [];
        this.selectedVoucherTypes.forEach( value => {
            voucherTypes.push(value.id);
        });

        const postData = {
            accountId: this.selectedSubAccountId.id,
            transactionStart: this.statementForm.get('transactionStartDate').value,
            transactionEnd: this.statementForm.get('transactionEndDate').value,
            voucherTypeIds: voucherTypes
        };

        this.fetchingStatements = true;

        this.accountService.getStatements(postData).subscribe( async (res:CommonResponse) => {
            if (res.responseCode=='00') {
                this.statements = res.responseData?.accountDetails;
                this.fetchingStatements = false;
            }
        },error => {
            this.fetchingStatements = false;
            this.connectionError();
        });
    }

    private connectionError() {
        this.messageService.add({severity:'error', summary: 'Failed',
            detail:'Connection Error, Please try again'});
    }

    setControlAccount() {
        if(this.selectedAccountClassId){
            this.controlAccounts = this.selectedAccountClassId.controlAccounts;
        }
    }

    setSubAccount() {
        if(this.selectedControlAccountId){
            const controlAccount = this.allControlAccounts.find(x => x.id === this.selectedControlAccountId.id);
            this.subAccounts = controlAccount.accounts;
        }
    }

    setSubAccountName() {
        if(this.selectedSubAccountId){
            this.subAccountName = this.selectedSubAccountId.name;
        }
    }

    viewAccountStatement(){
        this.viewAccountStatementRef = this.dialogService.open(ViewAccountStatementComponent, {
            width: '900px',
            contentStyle: {'max-width': '100vw', height: '80vh', overflow: 'auto'},
            baseZIndex: 100,
            data: { 
                statements: this.statements, 
                statementForm: this.statementForm, 
                accountAlias: this.selectedSubAccountId.alias 
            },
            header: 'Account Statement'
        });

        this.viewAccountStatementRef.onClose.subscribe(() => {});
    }
}
