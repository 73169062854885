import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import {
  ClientBeneficiary,
  CommonResponse,
  Contract,
  Relationship,
} from "../../../interfaces/home";
import { ConfirmationService, MessageService } from "primeng/api";
import { RoleService } from "../../../services/role.service";
import { UserService } from "../../../services/user.service";
import { environment } from "../../../../environments/environment";
import { CustomerDivisionService } from "../../../services/customer-division.service";
import { ClientBeneficiaryService } from "../../../services/client-beneficiary.service";
import { RelationshipService } from "../../../services/relationship.service";
import { MeanOfIdService } from "../../../services/mean-of-id.service";
import { DialogService, DynamicDialogRef } from "primeng/dynamicdialog";
import { AddDependentBeneficiaryComponent } from "./add-dependent-beneficiary/add-dependent-beneficiary.component";
import { BreadcrumbService } from "src/app/breadcrumb.service";
import { EventInput } from "@fullcalendar/core";
import {
  BeneficiaryBulkUpload,
  BulkCreateBeneficiariesReceivingDTO,
} from "src/app/interfaces/beneficiary";

@Component({
  selector: "app-beneficiary",
  templateUrl: "./beneficiary.component.html",
  styleUrls: ["./beneficiary.component.css"],
  providers: [MessageService, ConfirmationService],
})
export class BeneficiaryComponent implements OnInit {
  clientForm: FormGroup;
  customerDivisions: any;
  selectedCustomerDivision: any;
  viewedContractService: null;
  customerDivisionInformation: any;
  selectedContract: any;
  contracts: any[];
  primaryContactFullName: string;
  secondaryContactFullName: string;
  fetchingContractServices = false;
  contractServices: any;
  selectedContractService: any;
  contractServiceCols: any;
  beneficiaryForm: FormGroup;
  fetchingBeneficiaries = false;
  beneficiaries: any[];
  selectedBeneficiary: any;
  beneficiaryCols: any;
  addBeneficiaryClicked = false;
  viewBeneficiariesClicked = false;
  relationships: Relationship[];
  meansOfId: any[];
  selectedMeansOfId: any;
  selectedRelationship: any;
  addDependentRef: DynamicDialogRef;
  defaultImageUrl: string;
  file: any;
  bulkUploadContents: BeneficiaryBulkUpload[] = [];
  selectedBulkUploadContents: BeneficiaryBulkUpload[];
  bulkCols: any[];
  meansOfIdNote: string;
  openBulkDialogue: boolean;
  bulkFile: any;
  beneficiaryInView: ClientBeneficiary;
  dependantBulkUploadContents: BeneficiaryBulkUpload[] = [];
  selectedDependantBulkUploadContents: BeneficiaryBulkUpload[];
  relationshipsNote: string;

  constructor(
    public formBuilder: FormBuilder,
    private roleService: RoleService,
    private userService: UserService,
    private customerDivisionService: CustomerDivisionService,
    private clientBeneficiaryService: ClientBeneficiaryService,
    private relationshipService: RelationshipService,
    private meansOfIdService: MeanOfIdService,
    public messageService: MessageService,
    public dialogService: DialogService,
    public confirmationService: ConfirmationService,
    private breadcrumbService: BreadcrumbService
  ) {}

  ngOnInit(): void {
    this.breadcrumbService.setItems([
      { label: "Beneficiary", routerLink: ["home/beneficiary"] },
    ]);

    this.getCustomerDivisions();
    this.getRelationships();
    this.getMeansOfIdentification();

    this.clientForm = this.formBuilder.group({
      customerId: [null, Validators.required],
    });

    this.beneficiaryForm = this.formBuilder.group({
      title: ["", Validators.required],
      firstName: ["", Validators.required],
      lastName: ["", Validators.required],
      middleName: ["", Validators.required],
      phone: ["", Validators.required],
      email: ["", Validators.required],
      address: ["", Validators.required],
      meansOfId: [null, Validators.required],
      identificationNumber: ["", Validators.required],
    });

    this.bulkCols = [
      { field: "title", header: "Title" },
      { field: "firstName", header: "First Name" },
      { field: "lastName", header: "Last Name" },
      { field: "middleName", header: "Middle Name" },
      { field: "phoneNumber", header: "Phone Number" },
      { field: "address", header: "Address" },
      { field: "email", header: "Email" },
      { field: "meansOfIdentification", header: "Means Of Identification" },
      { field: "idNumber", header: "Identification Number" },
    ];

    this.defaultImageUrl = environment.defaultImage;
  }

  getCustomerDivisions() {
    this.customerDivisionService.allCustomerDivision().subscribe(
      async (res: CommonResponse) => {
        this.customerDivisions = res.responseData;
      },
      (error) => {
        this.messageService.add({
          severity: "error",
          summary: "Failed",
          detail: "Connection error, Try again later",
        });
      }
    );
  }

  searchContractServicesByClient() {
    this.customerDivisionInformation = null;
    this.selectedContract = null;
    this.contracts = null;
    this.viewedContractService = null;
    this.addBeneficiaryClicked = false;
    this.viewBeneficiariesClicked = false;
    this.getCustomerDivisionById(this.selectedCustomerDivision.id);
  }

  getCustomerDivisionById(customerDivisionId: any) {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Retrieving Client Information",
    });

    this.fetchingContractServices = true;
    this.customerDivisionService
      .getCustomerDivision(customerDivisionId)
      .subscribe(
        async (r: CommonResponse) => {
          await this.messageService.add({
            severity: "success",
            summary: "Success",
            detail: "Retrieved Client Information Successfully",
          });

          this.customerDivisionInformation = r.responseData;
          const primaryContact =
            this.customerDivisionInformation.primaryContact;
          const secondaryContact =
            this.customerDivisionInformation.secondaryContact;

          if (primaryContact) {
            this.primaryContactFullName = `${primaryContact.firstName} ${primaryContact.lastName}`;
          } else {
            this.primaryContactFullName = "Not Available.";
          }

          if (secondaryContact) {
            this.secondaryContactFullName = `${secondaryContact.firstName} ${secondaryContact.lastName}`;
          } else {
            this.secondaryContactFullName = "Not Available.";
          }
          this.contracts = r.responseData.contracts;

          this.getBeneficiaries();
          this.fetchingContractServices = false;
        },
        (error) => {
          this.connectionError();
          this.fetchingContractServices = false;
        }
      );
  }

  selectContract(contract?: Contract) {
    this.selectedContract = contract;
    this.contractServices = contract.contractServices;
  }

  viewContractServiceDetails(contractService: any) {}

  private connectionError() {
    this.messageService.add({
      severity: "error",
      summary: "Failed",
      detail: "Connection Error, Please try again",
    });
  }

  getBeneficiaries() {
    this.fetchingBeneficiaries = true;
    this.clientBeneficiaryService.allClientBeneficiaryData().subscribe(
      async (r: CommonResponse) => {
        const beneficiaries = r.responseData ?? [];
        const filtered = beneficiaries.filter(
          (x) => x.clientId === this.selectedCustomerDivision.id
        );

        const benTableData = [];

        for (const ben of filtered) {
          if (ben.isPrincipal) {
            ben.dependents = [];
            benTableData.push(ben);
          } else {
            const benData = benTableData.find(
              (x) => x.beneficiaryFamilyCode === x.beneficiaryFamilyCode
            );
            benData.dependents.push(ben);
          }
        }

        this.beneficiaries = benTableData;

        this.fetchingBeneficiaries = false;
      },
      (error) => {
        this.fetchingBeneficiaries = false;
        this.messageService.add({
          severity: "error",
          summary: "Failed",
          detail: `An error occurred while fetching beneficiaries ${error}`,
        });
      }
    );
  }

  getRelationships() {
    this.relationshipService
      .allRelationshipData()
      .subscribe(async (result: CommonResponse) => {
        this.relationships = result.responseData;
        this.relationships.forEach((x) => {
          this.relationshipsNote =
            (this.relationshipsNote == null ? "" : this.relationshipsNote) +
            " " +
            x.id +
            " - " +
            x.caption +
            ";";
        });
      });
  }

  getMeansOfIdentification() {
    this.meansOfIdService
      .allMeansOfId()
      .subscribe(async (result: CommonResponse) => {
        this.meansOfId = result.responseData;
        this.meansOfId.forEach((x) => {
          this.meansOfIdNote =
            (this.meansOfIdNote == null ? "" : this.meansOfIdNote) +
            " " +
            x.id +
            " - " +
            x.caption +
            ";";
        });
      });
  }

  createBeneficiary() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Creating Beneficiaries.",
    });

    let beneficiaryFamilyCode = "1";
    if (this.beneficiaries.length > 0) {
      const lastBeneficiaryCode =
        this.beneficiaries[this.beneficiaries.length - 1].beneficiaryFamilyCode;
      beneficiaryFamilyCode = (
        parseInt(lastBeneficiaryCode, 10) + 1
      ).toString();
    }

    const postData = {
      beneficiaryFamilyCode,
      beneficiaryCode: "0",
      isPrincipal: true,
      title: this.beneficiaryForm.get("title").value,
      firstName: this.beneficiaryForm.get("firstName").value,
      lastName: this.beneficiaryForm.get("lastName").value,
      middleName: this.beneficiaryForm.get("middleName").value,
      mobile: this.beneficiaryForm.get("phone").value,
      email: this.beneficiaryForm.get("email").value,
      address: this.beneficiaryForm.get("address").value,
      meansOfIdentifcationId: this.selectedMeansOfId.id,
      identificationNumber: this.beneficiaryForm.get("identificationNumber")
        .value,
      relationshipId: null,
      clientId: this.selectedCustomerDivision.id,
      imageUrl: environment.defaultServiceImage,
    };

    this.clientBeneficiaryService.postClientBeneficiary(postData).subscribe(
      async (r: CommonResponse) => {
        await this.messageService.add({
          severity: "success",
          summary: "Success",
          detail: "Beneficiary Created Successfully.",
        });
        this.getBeneficiaries();
        this.beneficiaryForm.reset();
        this.viewBeneficiariesClicked = true;
      },
      (error) => {
        this.messageService.add({
          severity: "error",
          summary: "Failed",
          detail: `Could not create beneficiary ${error}`,
        });
      }
    );
  }

  addDependentBeneficiary(beneficiary: any) {
    this.addDependentRef = this.dialogService.open(
      AddDependentBeneficiaryComponent,
      {
        header: "Add Dependent Beneficiary",
        width: "30%",
        contentStyle: { "min-height": "350px", overflow: "auto" },
        baseZIndex: 10000,
        data: {
          beneficiary,
          relationships: this.relationships,
          meansOfId: this.meansOfId,
        },
      }
    );

    this.addDependentRef.onClose.subscribe(async (r: any) => {
      if (r != null) {
        const _data = r;
        const postData = {
          beneficiaryFamilyCode: beneficiary.beneficiaryFamilyCode,
          beneficiaryCode: _data.relationship.id.toString(),
          isPrincipal: false,
          title: _data.title,
          firstName: _data.firstName,
          lastName: _data.lastName,
          middleName: _data.middleName,
          mobile: _data.phone,
          email: _data.email,
          address: _data.address,
          meansOfIdentifcationId: _data.meansOfId.id,
          identificationNumber: _data.identificationNumber,
          relationshipId: _data.relationship.id,
          clientId: this.selectedCustomerDivision.id,
          imageUrl: environment.defaultServiceImage,
        };

        console.log("Dependent post data", postData);

        this.confirmationService.confirm({
          message: `Are you sure you want to add dependent beneficiary to ${beneficiary.firstName} ?`,
          accept: () => {
            this.messageService.add({
              severity: "info",
              summary: "Notice",
              detail: `Adding dependent beneficiary to ${beneficiary.firstName}`,
            });
            this.clientBeneficiaryService
              .postClientBeneficiary(postData)
              .subscribe(
                async (r: any) => {
                  await this.messageService.add({
                    severity: "success",
                    summary: "Success",
                    detail: "Added Dependent Beneficiary",
                  });
                  this.getBeneficiaries();
                },
                (error) => {
                  this.messageService.add({
                    severity: "error",
                    summary: "Notice",
                    detail: "Unable to Add dependent beneficiary at the moment",
                  });
                }
              );
          },
        });
      } else {
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail: "Error: Please Try Again",
        });
        console.log(r);
      }
    });
  }

  addBeneficiary() {
    this.addBeneficiaryClicked = true;
  }

  onFileSelect(event) {
    this.bulkUploadContents = [];
    this.file = null;
    if (!this.checkMimeType(event) || !this.checkFileSize(event)) {
      return;
    }

    let beneficiaryFamilyCode = "1";
    if (this.beneficiaries.length > 0) {
      const lastBeneficiaryCode =
        this.beneficiaries[this.beneficiaries.length - 1].beneficiaryFamilyCode;
      beneficiaryFamilyCode = (
        parseInt(lastBeneficiaryCode, 10) + 1
      ).toString();
    }
    const reader = new FileReader();
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      this.file = event.target.files[0];
      reader.readAsText(file);
      reader.onload = () => {
        // Entire file
        const text = reader.result as string;
        // By lines
        var lines = text.split("\n");
        for (var line = 0; line < lines.length; line++) {
          let lineStrArr = lines[line].split(",");
          try {
            this.bulkUploadContents.push({
              title: lineStrArr[0],
              firstName: lineStrArr[1],
              lastName: lineStrArr[2],
              middleName: lineStrArr[3],
              phoneNumber: lineStrArr[4],
              address: lineStrArr[5],
              email: lineStrArr[6],
              meansOfIdentification: +lineStrArr[7],
              idNumber: lineStrArr[8],
              beneficiaryFamilyCode: beneficiaryFamilyCode + "_" + line,
              beneficiaryCode: "0",
            });
          } catch {}
        }
      };
    }
  }

  checkMimeType = (event: EventInput): boolean => {
    const files = event.target.files[0];
    // list allow mime type
    const types = ["text/csv"];
    // loop access array
    if (!files) {
      this.messageService.add({
        severity: "error",
        summary: "Failed",
        detail: "Reset Occurred: Please select a CSV file.",
      });
      return false;
    }
    // compare file type find doesn't match
    if (types.every((type) => files.type !== type)) {
      // create error message and assign to container
      this.messageService.add({
        severity: "error",
        summary: "Failed",
        detail: "Image format not supported",
      });
      return false;
    }
    return true;
  };

  checkFileSize = (event: EventInput) => {
    const files = event.target.files[0];
    if (!files) return true;
    const size = 400000;
    if (files.size > size) {
      event.target.value = null;
      this.messageService.add({
        severity: "error",
        summary: "Failed",
        detail: "Image is too large, please pick a smaller file",
      });
      return false;
    }
    return true;
  };

  RemoveBulkItem(item: BeneficiaryBulkUpload) {
    const index = this.bulkUploadContents.indexOf(item);
    if (index > -1) {
      this.bulkUploadContents.splice(index, 1);
    }
  }

  SaveBenficiaryBulkUpload() {
    this.confirmationService.confirm({
      message:
        "Are you sure you want to send this bulk upload for client beneficiary bulk creation. This is an irreversible action. Do you still wish to proceed?",
      accept: () => {
        this.messageService.add({
          severity: "info",
          summary: "Notice",
          detail: "Processing Client Beneficiary Bulk Creation...",
        });

        const postData: BulkCreateBeneficiariesReceivingDTO = {
          clientId: this.selectedCustomerDivision.id,
          defaultImageUrl: environment.defaultServiceImage,
          isPrincipal: true,
          beneficiaryBulk: this.bulkUploadContents,
        };

        this.clientBeneficiaryService
          .BulkCreateBeneficiaries(postData)
          .subscribe(
            async (data) => {
              if (data.responseCode != "00") {
                this.messageService.add({
                  severity: "error",
                  summary: "Notice",
                  detail: data.responseMsg,
                });
                return;
              }
              this.messageService.add({
                severity: "success",
                summary: "Removed",
                detail: "Bulk Creation Successful",
              });
              this.file = null;
              this.bulkUploadContents = [];
              this.selectedBulkUploadContents = [];
              this.getBeneficiaries();
            },
            (error) => {
              console.log("Error: " + JSON.stringify(error));
              this.messageService.add({
                severity: "error",
                summary: "Notice",
                detail:
                  "Unable to run bulk creation at the moment.. Reason: [" +
                  error
                    ? error.error.message
                    : "request failed - permission" + "]",
              });
            }
          );
      },
    });
  }

  AddBulkDependentBeneficiary(item: ClientBeneficiary) {
    this.beneficiaryInView = item;
    this.openBulkDialogue = true;
  }

  HideBulkDialog() {
    this.openBulkDialogue = false;
    this.beneficiaryInView = null;
  }

  onBulkFileSelect(event) {
    this.dependantBulkUploadContents = [];
    this.bulkFile = null;
    if (!this.checkMimeType(event) || !this.checkFileSize(event)) {
      return;
    }

    const reader = new FileReader();
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      this.bulkFile = event.target.files[0];
      reader.readAsText(file);
      reader.onload = () => {
        // Entire file
        const text = reader.result as string;
        // By lines
        var lines = text.split("\n");
        for (var line = 0; line < lines.length; line++) {
          let lineStrArr = lines[line].split(",");
          try {
            this.dependantBulkUploadContents.push({
              title: lineStrArr[0],
              firstName: lineStrArr[1],
              lastName: lineStrArr[2],
              middleName: lineStrArr[3],
              phoneNumber: lineStrArr[4],
              address: lineStrArr[5],
              email: lineStrArr[6],
              meansOfIdentification: +lineStrArr[7],
              idNumber: lineStrArr[8],
              beneficiaryFamilyCode:
                this.beneficiaryInView.beneficiaryFamilyCode,
              beneficiaryCode: lineStrArr[9],
              relationshipId: +lineStrArr[9],
            });
          } catch {}
        }
      };
    }
  }

  RemoveDependantBulkItem(item: BeneficiaryBulkUpload) {
    const index = this.dependantBulkUploadContents.indexOf(item);
    if (index > -1) {
      this.dependantBulkUploadContents.splice(index, 1);
    }
  }

  SaveBenficiaryDependantBulkUpload() {
    this.confirmationService.confirm({
      message:
        "Are you sure you want to send this bulk upload for client beneficiary dependant bulk creation. This is an irreversible action. Do you still wish to proceed?",
      accept: () => {
        this.messageService.add({
          severity: "info",
          summary: "Notice",
          detail: "Processing Client Beneficiary Dependant Bulk Creation...",
        });

        const postData: BulkCreateBeneficiariesReceivingDTO = {
          clientId: this.selectedCustomerDivision.id,
          defaultImageUrl: environment.defaultServiceImage,
          isPrincipal: false,
          beneficiaryBulk: this.dependantBulkUploadContents,
        };

        this.clientBeneficiaryService
          .BulkCreateBeneficiaries(postData)
          .subscribe(
            async (data) => {
              if (data.responseCode != "00") {
                this.messageService.add({
                  severity: "error",
                  summary: "Notice",
                  detail: data.responseMsg,
                });
                return;
              }
              this.messageService.add({
                severity: "success",
                summary: "Removed",
                detail: "Dependant Bulk Creation Successful",
              });
              this.bulkFile = null;
              this.dependantBulkUploadContents = [];
              this.selectedDependantBulkUploadContents = [];
              this.HideBulkDialog();
              this.getBeneficiaries();
            },
            (error) => {
              console.log("Error: " + JSON.stringify(error));
              this.messageService.add({
                severity: "error",
                summary: "Notice",
                detail:
                  "Unable to run bulk creation at the moment.. Reason: [" +
                  error
                    ? error.error.message
                    : "request failed - permission" + "]",
              });
            }
          );
      },
    });
  }
}
