import { Component, OnInit } from "@angular/core";
import { ConfirmationService, MessageService, TreeNode } from "primeng/api";
import {
  User,
  StrategicBusinessUnit,
  CommonResponse,
  SbuAttachment,
  CreateSBUAttachmentVM,
} from "../../../interfaces/home";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { environment } from "../../../../environments/environment";
import { DivisionService } from "../../../services/division.service";
import { StrategicBusinessUnitService } from "../../../services/strategic-business-unit.service";
import { DialogService, DynamicDialogRef } from "primeng/dynamicdialog";
import { AttachUserSbuComponent } from "./attach-user-sbu/attach-user-sbu.component";
import { UserService } from "../../../services/user.service";
import { BreadcrumbService } from "src/app/breadcrumb.service";

@Component({
  selector: "app-sbu-users",
  templateUrl: "./sbu-users.component.html",
  styleUrls: ["./sbu-users.component.css"],
  providers: [MessageService, ConfirmationService, DialogService],
})
export class SbuUsersComponent implements OnInit {
  attachUserToSBUForm: FormGroup;
  divisionSbuTree: TreeNode[];
  loadingDivisions = true;
  fetchingUsers = false;
  users: User[];
  allUsers: User[];
  theSBUUser: User;
  roleCols: any[];
  sbuCols: any[];
  imageSrc: any;
  viewedUser: User;
  selectedSBU: StrategicBusinessUnit;
  loadingSbus: boolean;
  private allSBUs: StrategicBusinessUnit[];
  attachUserRef: DynamicDialogRef;
  private loadingUsers = true;
  private exportRoleCols: any[];
  secondaryUsers: User[];
  fetchingSBUAttachments: boolean;
  allSbuAttachments: SbuAttachment[] = [];
  allSbuAttachmentsToShow: SbuAttachment[] = [];
  selectedSbuAttachments: SbuAttachment[] = [];
  openAttachmentDialogue: boolean;

  constructor(
    public formBuilder: FormBuilder,
    private userService: UserService,
    private strategicBusinessUnitService: StrategicBusinessUnitService,
    private divisionService: DivisionService,
    public dialogService: DialogService,
    public messageService: MessageService,
    public confirmationService: ConfirmationService,
    private breadcrumbService: BreadcrumbService
  ) {
    this.attachUserToSBUForm = formBuilder.group({
      User: ["", Validators.required],
      SBU: [""],
    });
  }

  ngOnInit(): void {
    this.breadcrumbService.setItems([
      { label: "Setup", routerLink: ["home/sbu-users"] },
      { label: "Attach User To SBU", routerLink: ["home/sbu-users"] },
    ]);

    this.fetchDivisionWithSbu();
    this.fetchAllSbus();
    this.fetchAllUsers();

    this.imageSrc = environment.defaultImage;

    this.roleCols = [
      { field: "firstName", header: "First Name" },
      { field: "lastName", header: "Last Name" },
      { field: "codeName", header: "Code Name" },
      { field: "sbuCode", header: "SBU Code" },
    ];
    this.sbuCols = [
      { field: "attachedSBU.firstName", header: "First Name" },
      { field: "attachedSBU.lastName", header: "Last Name" },
      { field: "attachedSBU.codeName", header: "Code Name" },
    ];
    this.exportRoleCols = this.roleCols.map((col) => ({
      title: col.header,
      dataKey: col.field,
    }));
  }

  fetchDivisionWithSbu() {
    this.divisionService
      .allDivisionDataWithSBU()
      .subscribe(async (res: CommonResponse) => {
        this.loadingDivisions = false;

        const divisions = res.responseData;

        const divisionsTree = [];

        divisions.forEach((division, k) => {
          const opEntitiesTree = [];

          division.operatingEntities.forEach((operatingEntity, kk) => {
            const sbuTree = [];

            operatingEntity.strategicBusinessUnits.forEach((sbu, key) => {
              sbuTree.push({
                label: `${sbu.name}`,
                key: sbu.id,
                icon: "pi pi-fw pi-users",
                data: sbu,
                selectable: true,
              });
            });

            opEntitiesTree.push({
              label: `${operatingEntity.name}`,
              key: operatingEntity.id,
              expandedIcon: "pi pi-minus-circle",
              collapsedIcon: "pi pi-plus-circle",
              expanded: true,
              children: sbuTree,
            });
          });

          divisionsTree.push({
            label: `${division.name}`,
            key: division.id,
            expandedIcon: "pi pi-minus-circle",
            collapsedIcon: "pi pi-plus-circle",
            expanded: true,
            children: opEntitiesTree,
          });
        });

        this.divisionSbuTree = [
          {
            label: "Halogen",
            key: "Halogen Folder",
            expandedIcon: "pi pi-minus-circle",
            collapsedIcon: "pi pi-plus-circle",
            expanded: true,
            children: divisionsTree,
          },
        ];
      });
  }

  fetchAllSbus() {
    this.strategicBusinessUnitService
      .allStrategicBusinessUnit()
      .subscribe(async (res: CommonResponse) => {
        this.loadingSbus = false;
        this.allSBUs = res.responseData;
      });
  }

  fetchAllUsers() {
    this.userService.allUser().subscribe(async (res: CommonResponse) => {
      this.loadingUsers = false;
      this.allUsers = res.responseData;
      this.allUsers.forEach(
        (x) =>
          (x.fullName =
            x.lastName + " " + x.firstName + " " + (x.otherName ?? ""))
      );
      this.FetchAllSBUAttachments();
    });
  }

  async FetchAllSBUAttachments() {
    this.fetchingSBUAttachments = true;
    this.strategicBusinessUnitService.GetAllSBUAttachments().subscribe(
      async (data) => {
        if (data.responseCode != "00") {
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail: data.responseMsg,
          });
          this.fetchingSBUAttachments = false;
          return;
        }

        this.allSbuAttachments = data.responseData;
        this.allSbuAttachments.forEach(
          (x) =>
            (x.attachedSBU = this.allUsers.find((y) => y.id == x.attachedSBUId))
        );
        this.fetchingSBUAttachments = false;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all sbu attachments at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
        this.fetchingSBUAttachments = false;
      }
    );
  }

  showUsers(event) {
    if (event.node.data) {
      if (this.selectedSBU !== event.node.data) {
        this.selectedSBU = event.node.data;
        this.viewedUser = null;
        this.users = this.allSBUs.find(
          (x) => x.id === this.selectedSBU.id
        ).members;
        this.allSbuAttachmentsToShow = this.allSbuAttachments.filter(
          (x) => x.strategicBusinessUnitId == this.selectedSBU.id
        );
      }
    }
  }

  refreshSBUUsers() {
    this.strategicBusinessUnitService
      .allStrategicBusinessUnit()
      .subscribe(async (res: CommonResponse) => {
        this.loadingSbus = false;
        this.allSBUs = res.responseData;
        if (this.selectedSBU) {
          this.users = this.allSBUs.find(
            (x) => x.id === this.selectedSBU.id
          ).members;
        }
      });
  }

  showAddUserPopUp() {
    if (!this.allSBUs || !this.allSBUs) {
      this.messageService.add({
        severity: "info",
        summary: "Notice",
        detail: "Data not fully loaded....Try again.",
      });
      return;
    }

    this.attachUserRef = this.dialogService.open(AttachUserSbuComponent, {
      header: "Attach User To SBU",
      width: "30%",
      contentStyle: { "min-height": "350px", overflow: "auto" },
      baseZIndex: 10000,
      data: { sbus: this.allSBUs, users: this.allUsers },
    });

    this.attachUserRef.onClose.subscribe(async (res: any) => {
      if (res) {
        const _selectedOptions = res;

        const user = _selectedOptions.user;
        const sbu = _selectedOptions.sbu;

        if (user && sbu) {
          if (user.sbuId !== sbu.id) {
            const userId = user.id;
            const sbuId = sbu.id;

            let message = `Are you sure you want to assign ${user.fullName} to ${sbu.name} ?`;
            if (user.sbu) {
              message = `${message} This will detach ${user.fullName} from ${user.sbu.name}`;
            }

            this.confirmationService.confirm({
              message,
              accept: () => {
                this.messageService.add({
                  severity: "info",
                  summary: "Notice",
                  detail: "Attaching SBU to User.",
                });
                this.userService.attachUserToSBU(userId, sbuId).subscribe(
                  async (result: any) => {
                    await this.messageService.add({
                      severity: "success",
                      summary: "Success",
                      detail: "Attached User To SBU",
                    });
                    this.fetchAllUsers();
                    this.refreshSBUUsers();
                  },
                  (error) => {
                    this.messageService.add({
                      severity: "error",
                      summary: "Notice",
                      detail: "Unable to Attach SBU at the moment",
                    });
                  }
                );
              },
            });
          } else {
            this.messageService.add({
              severity: "info",
              summary: "Notice",
              detail: `${user.fullName} is already assigned to ${sbu.name}`,
            });
          }
        }
      }
    });
  }

  viewUser(user: User) {
    this.viewedUser = user;
  }

  detachUserFromSBU() {
    const fullName = `${this.viewedUser.firstName} ${this.viewedUser.lastName}`;
    this.confirmationService.confirm({
      message: `Are you sure you want to detach ${fullName} from ${this.viewedUser.sbu.name} ?`,
      accept: () => {
        this._detachUserFromSBU();
      },
    });
  }

  _detachUserFromSBU() {
    const userId = this.viewedUser.id;
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Detaching SBU..",
    });
    this.userService.detachUserFromSBU(userId).subscribe(
      async (result: any) => {
        await this.messageService.add({
          severity: "success",
          summary: "Success",
          detail: "Detached SBU Successfully.",
        });
        this.viewedUser = null;
        this.refreshSBUUsers();
      },
      (error) => {
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail: "Unable to Detach SBU at the moment",
        });
      }
    );
  }

  ShowSecondaryAddUserPopUp() {
    this.openAttachmentDialogue = true;
  }

  SecondaryDetach(item: SbuAttachment) {
    this.confirmationService.confirm({
      header: "Confirmation",
      message:
        "Are you sure you want to detach this user as a secondary member of the selected sbu?",
      acceptLabel: "Yes, Proceed",
      rejectLabel: "No, Don't proceed",
      accept: () => {
        this.messageService.add({
          severity: "info",
          summary: "Notice",
          detail: "Detaching User...",
        });

        this.strategicBusinessUnitService
          .DetachSBUAttachment(item.id)
          .subscribe(
            async (data) => {
              if (data.responseCode != "00") {
                this.messageService.add({
                  severity: "error",
                  summary: "Notice",
                  detail: data.responseMsg,
                });
                return;
              }

              this.messageService.add({
                severity: "success",
                summary: "Completed",
                detail: "User Attached Successfully!",
              });

              const index = this.allSbuAttachments.indexOf(item);
              if (index > -1) {
                this.allSbuAttachments.splice(index, 1);
              }
              this.allSbuAttachmentsToShow = this.allSbuAttachments.filter(
                (x) => x.strategicBusinessUnitId == item.strategicBusinessUnitId
              );
            },
            (error) => {
              console.log("Error: " + JSON.stringify(error));
              this.messageService.add({
                severity: "error",
                summary: "Notice",
                detail:
                  "Unable to detach user at the moment.. Reason: [" +
                  (error
                    ? error.error.message
                    : "request failed - permission") +
                  "]",
              });
            }
          );
      },
    });
  }

  HideAttachmentDialog() {
    this.openAttachmentDialogue = false;
    this.theSBUUser = null;
  }

  CreateSBUAttach() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Attaching user to SBU (Secondary)...",
    });

    const postData: CreateSBUAttachmentVM = {
      strategicBUId: this.selectedSBU.id,
      userId: this.theSBUUser.id,
    };

    this.strategicBusinessUnitService.CreateSBUAttachment(postData).subscribe(
      async (data) => {
        if (data.responseCode != "00") {
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail: data.responseMsg,
          });
          return;
        }

        this.messageService.add({
          severity: "success",
          summary: "Completed",
          detail: "User Attached Successfully!",
        });
        this.allSbuAttachments.push({
          strategicBusinessUnitId: postData.strategicBUId,
          attachedSBUId: postData.userId,
          attachedSBU: this.allUsers.find((x) => x.id == postData.userId),
        });
        this.allSbuAttachmentsToShow = this.allSbuAttachments.filter(
          (x) => x.strategicBusinessUnitId == postData.strategicBUId
        );
        this.HideAttachmentDialog();
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to attach user at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }
}
