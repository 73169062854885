import { Bank } from "./home";
import { BankAccountTypeEnum, Supplier, SupplierBank } from "./supplier";

export enum ExpenseLineType {
  CostOfSales = 1,
  Overhead = 2,
}

export enum UserExpenseRequestType {
  Supplier = 1,
  Staff,
  Customer,
  Operatives,
  Miscellanous,
}

export enum ExpenseBatchRequesterType {
  UserRequest = 1,
}

export enum ExpenseBatchRequestStatus {
  Preparing = 1,
  PendingLineManagerApproval,
  PendingFinalApproval,
  PendingFinalAuthorization,
  FullyAuthorized,
  CompletePayment,
  Returned,
  Declined,
}

export enum ExpenseBatchRequestLogStatus {
  Approved = 1,
  Declined,
  Returned,
  RequesterSent,
}

export interface CreateExpenseLineVM {
  caption: string;
  description: string;
  type: ExpenseLineType;
  accountId: number;
  accountNumber: string;
  alias: string;
  divisionId: number;
  // divisionIds: number[];
  serviceCategoryIds: number[];
}

export interface UpdateExpenseLineVM {
  caption?: string;
  description?: string;
  type?: ExpenseLineType;
  accountId?: number;
  accountNumber?: string;
  alias?: string;
  divisionId?: number;
  divisionIds?: number[];
  serviceCategoryIds?: number[];
}

export interface ExpenseLine {
  id?: number;
  caption: string;
  alias?: string;
  description?: string;
  type?: ExpenseLineType;
  accountId?: number;
  accountNumber?: string;
  divisionId?: number;
  divisions?: ExpenseLineDivision[];
  categories?: ExpenseLineCategory[];
  createdById?: number;
  createdAt?: Date;
  availableAmount?: number;
}

export interface ExpenseLineDivision {
  id: number;
  expenseLineId?: number;
  divisionId?: number;
  createdById: number;
  createdAt: Date;
}

export interface ExpenseLineCategory {
  id: number;
  expenseLineId?: number;
  serviceCategoryId?: number;
  createdById: number;
  createdAt: Date;
}

export interface ExpenseLineRequester {
  id: number;
  serviceCategoryId?: number;
  requesterId?: number;
  createdById: number;
  createdAt: Date;
}

export interface ExpenseLineBudget {
  id: number;
  expenseLineId?: number;
  budgetYear: string;
  budgetYearDateTime: Date;
  budgetStartPeriod: Date;
  budgetEndPeriod: Date;
  budgetAmount: number;
  budgetAmountUsed: number;
  createdById: number;
  createdAt: Date;
}

export interface CreateExpenseRequesterAttachmentVM {
  applicableCategoriesIds: number[];
  requestersIds: number[];
}

export interface ExpenseApprovingOffice {
  id: number;
  officeName: string;
  officers: ExpenseApprovingOfficer[];
  isDeleted: boolean;
  createdById: number;
  createdAt: Date;
}

export interface ExpenseApprovingOfficer {
  id: number;
  officerProfileId?: number;
  approvingOfficeId?: number;
  isDeleted: boolean;
  createdById: number;
  createdAt: Date;
}

export interface ExpenseApprovalSetup {
  id: number;
  serviceCategoryId?: number;
  expenseApprovingOfficeId?: number;
  expenseApprovingOffice?: ExpenseApprovingOffice;
  sequence: number;
  isDeleted: boolean;
  createdById: number;
  createdAt: Date;
}

export interface CreateExpenseApprovalSetupVM {
  serviceCategoriesIds: number[];
  officeName: string;
  officersIds: number[];
}

export interface UpdateExpenseApprovalSetupVM {
  officersIds?: number[];
}

export interface MoveExpenseApprovalOfficeSequenceVM {
  isUpward: boolean;
  currentSequence: number;
  serviceCategoryId: number;
}

export interface GetAllExpenseBudgetInitItemsVM {
  years: GetAllExpenseBudgetInitItemsYearVM[];
  newBudgetYears: GetAllExpenseBudgetInitItemsYearVM[];
  expenseLines: ExpenseLine[];
  budgetExpenseLines: ExpenseLine[];
}

export interface GetAllExpenseBudgetInitItemsYearVM {
  year: string;
  yearDate: Date;
}

export interface GetAllExpenseBudgetsVM {
  useYearFilter: boolean;
  years: string[];
  useExpenseLineFilter: boolean;
  expenseLines: number[];
}

export interface CreateExpenseBudgetVM {
  budgets: CreateExpenseBudgetDetailVM[];
}

export interface CreateExpenseBudgetDetailVM {
  year: string;
  yearDate: Date;
  startPeriod: Date;
  endPeriod: Date;
  expenseLineId: number;
  budgetAmount: number;
}

export interface CreateExpenseBatchRequestVM {
  requestType: number;
  requestId: number;
  situation: string;
  justification: string;
  attachments: string[];
  subject: string;
}

export interface CreateExpenseBatchRequestItemVM {
  expenseBatchRequestId: number;
  expenseLineId: number;
  description: string;
  attachments: string[];
  amount: number;
  isVatCharged: boolean;
  vatAmount: number;
}

export interface UpdateExpenseBatchRequestVM {
  requestType?: number;
  requestId?: number;
  situation: string;
  justification: string;
  attachments: string[];
  subject: string;
}

export interface ExpenseBatchRequest {
  createdById: number;
  isDeleted: boolean;
  requesterType: ExpenseBatchRequesterType;
  comments: ExpenseBatchRequestComment[];
  approvalLogs: ExpenseBatchRequestLog[];
  lineItems: ExpenseBatchRequestItem[];
  picks: ExpenseBatchRequestPick[];
  payments: ExpenseBatchRequestPayment[];
  status: ExpenseBatchRequestStatus;
  subject: string;
  attachments: string;
  createdAt: Date;
  justification: string;
  operativeId?: number;
  clientId?: number;
  staffId?: number;
  supplierId?: number;
  supplier?: Supplier;
  requestType: UserExpenseRequestType;
  id: number;
  situation: string;
  isUnread?: boolean;
  isPosted: boolean;
  accountPostingId?: number;
  amountPosted?: number;
  datePosted?: Date;
  postedById?: number;
  amountPending?: number;
  canProceed?: boolean;
  payableAccountId?: number;
  payableWHTAccountId?: number;
}

export interface ExpenseBatchRequestItem {
  createdById: number;
  isDeleted: boolean;
  dateFullyApproved: Date;
  isFullyApproved: boolean;
  isVatCharged: boolean;
  vatAmount: number;
  amount: number;
  attachments: string;
  description: string;
  expenseLineId?: number;
  expenseLine?: ExpenseLine;
  batchRequestId?: number;
  id: number;
  createdAt: Date;
}

export interface ExpenseBatchRequestLog {
  createdById: number;
  isDeleted: boolean;
  status: ExpenseBatchRequestLogStatus;
  comment: string;
  isLineManager: boolean;
  approvingOfficerName: string;
  approvingOfficerId?: number;
  approvingOfficeName: string;
  approvingOfficeId?: number;
  authorizationOfficerName: string;
  authorizationOfficerId?: number;
  authorizationOfficeName: string;
  authorizationOfficeId?: number;
  isAuthorizer: boolean;
  batchRequestId?: number;
  id: number;
  createdAt: Date;
}

export interface ExpenseBatchRequestComment {
  id: number;
  batchRequestId?: number;
  comment: string;
  readReceipts: ExpenseBatchRequestCommentReceipt[];
  isDeleted: boolean;
  createdById?: number;
  createdAt: Date;
}

export interface ExpenseBatchRequestCommentReceipt {
  id: number;
  batchRequestCommentId?: number;
  readById?: number;
  dateRead: Date;
}

export interface LineManagerExpenseBatchRequestActionVM {
  action: ExpenseBatchRequestLogStatus;
  batchRequestId: number;
  comment?: string;
}

export interface CreateBatchCommentVM {
  batchId: number;
  comment: string;
  commenator?: string;
}

export interface CheckBatchComentReadReceiptsVM {
  batchIds: number[];
}

export interface GetPendingBatchApprovalExpenseBatchRequestsView {
  batchSummaries: string[];
  unPickedBatches: ExpenseBatchRequest[];
  pickedBatches: ExpenseBatchRequest[];
  allBatches: ExpenseBatchRequest[];
}

export interface BatchApprovalExpenseBatchRequestActionVM {
  action: ExpenseBatchRequestLogStatus;
  batchRequestId: number;
  comment?: string;
}

export interface ExpenseBatchRequestPick {
  createdById: number;
  isDeleted: boolean;
  sequence?: number;
  status: ExpenseBatchRequestLogStatus;
  serviceCategoryId?: number;
  approvingOfficerId?: number;
  approvingOfficeName: string;
  approvingOfficeId?: number;
  authorizationOfficerId?: number;
  authorizationOfficeName: string;
  authorizationOfficeId?: number;
  isAuthorizer: boolean;
  batchRequestId?: number;
  id: number;
  createdAt: Date;
}

export interface ExpenseAuthorizationOffice {
  id: number;
  officeName: string;
  officers: ExpenseAuthorizationOfficer[];
  isDeleted: boolean;
  createdById: number;
  createdAt: Date;
}

export interface ExpenseAuthorizationOfficer {
  id: number;
  officerProfileId?: number;
  authorizationOfficeId?: number;
  isDeleted: boolean;
  createdById: number;
  createdAt: Date;
}

export interface ExpenseAuthorizationSetup {
  id: number;
  serviceCategoryId?: number;
  expenseAuthorizationOfficeId?: number;
  expenseAuthorizationOffice?: ExpenseAuthorizationOffice;
  sequence?: number;
  lowerLimit: number;
  upperLimit: number;
  isDeleted: boolean;
  createdById: number;
  createdAt: Date;
}

export interface CreateExpenseAuthorizationSetupVM {
  serviceCategoriesIds: number[];
  officeName: string;
  officersIds: number[];
  lowerLimit: number;
  upperLimit: number;
}

export interface UpdateExpenseAuthorizationSetupVM {
  officersIds?: number[];
}

export interface ExpenseBatchRequestPayment {
  id: number;
  description: string;
  batchRequestId?: number;
  accountPostingId?: number;
  amountToPay: number;
  isVatApplied: boolean;
  vatAmount: number;
  isDeleted: boolean;
  createdById: number;
  createdAt: Date;
  dateChequeLoaded?: Date;
  isChequeLoaded: boolean;
  supplierBankId?: number;
  datePosted?: Date;
  isPosted: boolean;
  bankAccountPosted: string;
  accountNumberPosted: string;
  datePostedToPaystack?: Date;
  isPostedToPaystack: boolean;
  paystackResponse: string;
  transactionReference: string;
  chequeNoUsed: string;
  chequeNoSet: string;
  supplerBank?: SupplierBank;
  bankAccount?: BankAccount;
  bank?: string;
}

export interface GetExpenseBatchAuthorizationSetupsView {
  authorizerId?: number;
  office: string;
  officeId?: number;
  lowerLimit: number;
  upperLimit: number;
  isActedOn: boolean;
  isApproved: boolean;
}

export interface BatchAuthorizationExpenseBatchRequestActionVM {
  action: ExpenseBatchRequestLogStatus;
  batchRequestId: number;
  comment?: string;
}

export interface GetPayablesConfigurationsVM {
  whtPercentage: number;
  whtAccountId: number;
  whtAccountName: string;
  whtAccountNumber: string;
  whtAccountAlias: string;
  whtAccountDescription: string;
  vatAccountId: number;
  vatAccountName: string;
  vatAccountNumber: string;
  vatAccountAlias: string;
  vatAccountDescription: string;
}

export interface PostExpenseBatchPayablesVM {
  batchId: number;
  totalValue: number;
  description: string;
  cashbookAccountId: number;
  wHTAccountId: number;
  payables: PostExpenseBatchPayablesDetailVM[];
}

export interface PostExpenseBatchPayablesDetailVM {
  accountId: number;
  description: string;
  debitValue: number;
  creditValue: number;
}

export interface GetAllExpenseBatchPendingPaymentsVM
  extends GetPayablesConfigurationsVM {
  onInvoiceBatches: ExpenseBatchRequest[];
  onAccountBatches: ExpenseBatchRequest[];
  onAdvanceBatches: ExpenseBatchRequest[];
}

export interface PostExpenseBatchPaymentsVM {
  batchId: number;
  totalValue: number;
  isAdvancePayment: boolean;
  description: string;
  isVatApplied: boolean;
  vatAmount: number;
  uniqueRefNo: string;
  payments: PostExpenseBatchPaymentDetailVM[];
}

export interface PostExpenseBatchPaymentDetailVM {
  accountId: number;
  description: string;
  debitValue: number;
  creditValue: number;
}

export interface BankAccount {
  createdById?: number;
  isDeleted: boolean;
  isPrimaryAccount: boolean;
  accountType: BankAccountTypeEnum;
  accountName: string;
  accountNumber: string;
  bankId?: number;
  bank?: Bank;
  id: number;
  paystackTransferReference: string;
  customerDivisionId?: number;
  deployedGuardId?: number;
  userProfileId?: number;
  createdAt: Date;
  updatedAt: Date;
}

export interface SearchExpenseBatchesVM {
  dateFrom?: Date;
  dateTo?: Date;
  requestType?: UserExpenseRequestType;
  entityId?: number;
}

export interface TrackExpenseBatchRequestView {
  office: string;
  officer: string;
  status: number; // 1 - Accepted 2 - Rejected 3 - Awaiting
  detail: object;
}

export interface GetExpenseBankPaymentPayablesView {
  payments: ExpenseBatchRequestPayment[];
  payablesBatches: ExpenseBatchRequest[];
  allPayments: ExpenseBatchRequestPayment[];
}

export interface PostExpensePaymentsVM {
  payments: PostExpensePaymentsDetail[];
}

export interface PostExpensePaymentsDetail {
  PaymentId: number;
  bankAccountId?: number;
  supplierBankId?: number;
}

export interface CreateBankAccountVM {
  accountName: string;
  accountNumber: string;
  bankId: number;
  accountType: BankAccountTypeEnum;
  isPrimaryAccount: boolean;
  accountOwnerId: number;
  accountOwnerType: number;
  bankCode: string;
}

export interface UpdateBankAccountVM {
  accountName: string;
  accountNumber: string;
  bankId: number;
  accountType: BankAccountTypeEnum;
  isPrimaryAccount: boolean;
  accountOwnerId: number;
  accountOwnerType: number;
}

export interface GenerateExpensePaymentVoucherView {
  source: string;
  chequeNo: string;
  beneficiary: string;
  voucherNo: string;
  voucherDate: Date;
  lineItems: ExpensePaymentVoucherViewItems[];
  amount: number;
  approvals: ExpensePaymentVoucherViewApprovals[];
}

export interface ExpensePaymentVoucherViewItems {
  isTotal: boolean;
  account?: string;
  accountName?: string;
  expenseLine?: string;
  description?: string;
  amount: number;
}

export interface ExpensePaymentVoucherViewApprovals {
  office: string;
  officerId: number;
  status: string;
}
