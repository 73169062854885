import { StateService } from "./../../../services/state.service";
import { Lga, Location, State } from "./../../../interfaces/home";
import { UserService } from "src/app/services/user.service";
import {
  GMAZone,
  CreateZoneVM,
  UpdateZoneVM,
  Cluster,
  CreateClusterVM,
  UpdateClusterVM,
  AllocateLocationVM,
} from "./../../../interfaces/employment";
import { Branch, User } from "src/app/interfaces/home";
import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { ConfirmationService, MessageService } from "primeng/api";
import { BreadcrumbService } from "src/app/breadcrumb.service";
import { GmaEmploymentService } from "src/app/services/gma-employment.service";

@Component({
  selector: "app-gma-org-structure",
  templateUrl: "./gma-org-structure.component.html",
  styleUrls: ["./gma-org-structure.component.scss"],
})
export class GmaOrgStructureComponent implements OnInit {
  @ViewChild("formWrapper") public formWrapper: ElementRef;
  createZoneForm: FormGroup;
  clusterForm: FormGroup;
  formDecline: FormGroup;
  allRegions: Branch[];
  theRegion: Branch;
  allClusterRegions: Branch[];
  theClusterLocTieRegion: Branch;
  theClusterLocTieZone: GMAZone;
  allUsers: User[] = [];
  theZoneHead: User;
  theZoneDeputy: User;
  editingZone: boolean = false;
  fetchingAllZones: boolean = true;
  allZones: GMAZone[];
  selectedZone: GMAZone;
  zoneCols: any[];
  totalRegionsWithoutZone: number = 0;
  totalRegionsWithZone: number = 0;
  totalActiveZone: number = 0;
  theClusterRegion: Branch;
  carouselResponsiveOptions: any[] = [
    {
      breakpoint: "1024px",
      numVisible: 3,
      numScroll: 3,
    },
    {
      breakpoint: "768px",
      numVisible: 2,
      numScroll: 2,
    },
    {
      breakpoint: "560px",
      numVisible: 1,
      numScroll: 1,
    },
  ];
  allClusterRegionZones: GMAZone[];
  theClusterHead: User;
  theClusterDeputy: User;
  editingCluster: boolean = false;
  fetchingAllZoneClusters: boolean = false;
  allSelectedZoneClusters: Cluster[];
  selectedZoneCluster: Cluster;
  zoneClusterCols: any[];
  zoneToEdit: GMAZone;
  zoneInView: GMAZone;
  showZoneClusterList: boolean = false;
  showAddZoneClusterForm = false;
  clusterToEdit: Cluster;
  allClusterLocTieRegions: Branch[];
  allClusterLocTieZones: GMAZone[];
  allClustLocTieZoneClusters: Cluster[];
  allUnallocatedLocations: Location[] = [];
  allAllocatedLocations: Location[] = [];
  showAllocateLocationBox: boolean = false;
  clusterInView: Cluster;
  openViewClusterInfo: boolean = false;
  allClientLocs: Location[];
  selectedClientLoc: Location;
  cols: any[];
  allStates: State[] = [];
  allLGAs: Lga[] = [];
  openDeleteDialogue: boolean = false;
  deleteText: string;
  deleteCode: number;
  zoneToDelete: GMAZone;

  constructor(
    private fb: FormBuilder,
    public userService: UserService,
    public stateService: StateService,
    public employmentService: GmaEmploymentService,
    private breadcrumbService: BreadcrumbService,
    public confirmationService: ConfirmationService,
    public messageService: MessageService
  ) {
    this.createZoneForm = fb.group({
      Region: ["", Validators.required],
      Caption: ["", Validators.required],
      Alias: ["", Validators.required],
      Description: ["", Validators.required],
      ZonalHead: ["", Validators.required],
      ZonalDeputy: ["", Validators.required],
    });

    this.clusterForm = fb.group({
      Caption: ["", Validators.required],
      Alias: ["", Validators.required],
      Description: ["", Validators.required],
      ClusterHead: ["", Validators.required],
      ClusterDeputy: [""],
    });

    this.formDecline = fb.group({
      Comment: [""],
    });
  }

  ngOnInit(): void {
    this.breadcrumbService.setItems([
      {
        label: "Guard Management",
        routerLink: ["/home/guard-management/item-settings"],
      },
      {
        label: "Gma Org Structure",
        routerLink: ["/home/guard-management/org-structure"],
      },
    ]);

    this.zoneCols = [
      { field: "caption", header: "Caption" },
      { field: "alias", header: "Alias" },
      { field: "description", header: "Description" },
    ];

    this.zoneClusterCols = [
      { field: "caption", header: "Caption" },
      { field: "alias", header: "Alias" },
      { field: "description", header: "Description" },
    ];

    this.cols = [
      { field: "name", header: "Name" },
      { field: "description", header: "Description" },
      { field: "street", header: "Street" },
      { field: "createdAt", header: "CreatedAt" },
    ];

    this.FetchAllZones();
    this.FetchAllZoneRegions();
    this.FetchAllUsers();
    this.FetchAllClusterRegions();
    this.FetchAllClusterLocRegions();
    this.FetchAllUnallocatedLocations();
    this.FetchAllStates();
  }

  async FetchAllZones() {
    this.employmentService.GetAllZones().subscribe(
      async (data) => {
        this.allZones = data;
        this.fetchingAllZones = false;
        this.totalActiveZone = this.allZones.length;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all zones at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
        this.fetchingAllZones = false;
      }
    );
  }

  async FetchAllZoneRegions() {
    this.employmentService.GetAllZoneRegions().subscribe(
      async (data) => {
        this.totalRegionsWithZone = 0;
        this.totalRegionsWithoutZone = 0;
        this.allRegions = data;
        this.allRegions.forEach((region) => {
          if (region.zones.length == 0) this.totalRegionsWithoutZone++;
          if (region.zones.length > 0) this.totalRegionsWithZone++;
        });

        this.FetchAllClusterRegions();
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all zones region at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  async FetchAllUsers() {
    this.userService.allUser().subscribe(
      async (r) => {
        var data = r.responseData;
        this.allUsers = data;
        this.allUsers.forEach((user) => {
          user.fullName = user.lastName + " " + user.firstName;
        });
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all users at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  async FetchAllClusterRegions() {
    this.employmentService.GetAllClusterRegions().subscribe(
      async (data) => {
        this.allClusterRegions = data;
        this.FetchAllZones();
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all cluster regions at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  async FetchAllClusterLocRegions() {
    this.employmentService.GetAllClusterLocRegions().subscribe(
      async (data) => {
        this.allClusterLocTieRegions = data;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all cluster locationtie regions at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  async FetchAllUnallocatedLocations() {
    this.employmentService.GetAllUnallocatedLocations().subscribe(
      async (data) => {
        this.allUnallocatedLocations = data;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all unallocated locations at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  async FetchAllStates() {
    this.employmentService.GetAllStates().subscribe(
      async (data) => {
        this.allStates = data;
        this.allStates.forEach((state) => {
          state.lgas.forEach((lga) => {
            this.allLGAs.push(lga);
          });
        });
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all states at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  CreateZone() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Creating Zone...",
    });

    const postData: CreateZoneVM = {
      regionId: this.theRegion.id,
      caption: this.createZoneForm.get("Caption").value,
      description: this.createZoneForm.get("Description").value,
      alias: this.createZoneForm.get("Alias").value,
      zonalHeadId: this.theZoneHead.id,
      zonalDeputyId: this.theZoneDeputy.id,
    };

    this.employmentService.CreateNewZone(postData).subscribe(
      async () => {
        await this.messageService.add({
          severity: "success",
          summary: "Completed",
          detail: "Zone Created Successfully...",
        });

        this.createZoneForm.reset();
        this.theZoneHead = null;
        this.theZoneDeputy = null;
        this.theRegion = null;
        this.fetchingAllZones = true;
        this.FetchAllZoneRegions();
        this.FetchAllZones();
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to create zone at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  CloseEditingZone() {
    this.editingZone = false;
    this.theZoneHead = null;
    this.theZoneDeputy = null;
    this.theRegion = null;
    this.createZoneForm.reset();
  }

  UpdateZone() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Updating Zone...",
    });
    const id = this.zoneToEdit.id;
    const postData: UpdateZoneVM = {
      regionId: this.theRegion.id,
      caption: this.createZoneForm.get("Caption").value,
      description: this.createZoneForm.get("Description").value,
      alias: this.createZoneForm.get("Alias").value,
      zonalHeadId: this.theZoneHead.id,
      zonalDeputyId: this.theZoneDeputy.id,
    };

    this.employmentService.UpdateZone(id, postData).subscribe(
      async () => {
        this.messageService.add({
          severity: "success",
          summary: "Notice",
          detail: "Update Successful!",
        });

        this.fetchingAllZones = true;
        this.FetchAllZones();
        this.FetchAllZoneRegions();
        this.editingZone = false;
        this.CloseEditingZone();
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to update zone at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  DeleteZone(item: GMAZone) {
    if (item.clusters.filter((x) => x.isDeleted == false).length > 0) {
      this.zoneToDelete = item;
      this.deleteText =
        "The zone you are about to delete has " +
        item.clusters.filter((x) => x.isDeleted == false).length +
        " active cluster(s) under it, removing this zone will also remove all the clusters under it. Do you still wish to proceed?";
      this.deleteCode = 1;
      this.openDeleteDialogue = true;
      return;
    }

    this.confirmationService.confirm({
      message: "Are you sure you want to remove zone?",
      accept: () => {
        this.messageService.add({
          severity: "info",
          summary: "Notice",
          detail: "Removing zone...",
        });

        this.employmentService.DeleteZone(item.id).subscribe(
          async () => {
            await this.messageService.add({
              severity: "success",
              summary: "Removed",
              detail: "Removed successfully",
            });

            const index = this.allZones.indexOf(item);
            if (index > -1) {
              this.allZones.splice(index, 1);
            }
            this.FetchAllZones();
            this.FetchAllZoneRegions();
          },
          (error) => {
            console.log("Error: " + JSON.stringify(error));
            this.messageService.add({
              severity: "error",
              summary: "Notice",
              detail:
                "Unable to delete zone at the moment.. Reason: [" + error
                  ? error.error.message
                  : "request failed - permission" + "]",
            });
          }
        );
      },
    });
  }

  EditZone(item: GMAZone) {
    this.editingZone = true;
    this.createZoneForm.addControl(
      "ID",
      new FormControl({ value: "", disabled: true }, Validators.required)
    );
    this.zoneToEdit = item;
    this.createZoneForm.patchValue({
      ID: item.id,
      Caption: item.caption,
      Description: item.description,
      Alias: item.alias,
    });
    this.theRegion = this.allRegions.find((x) => x.id == item.regionId);
    this.theZoneHead = this.allUsers.find((x) => x.id == item.zonalHeadId);
    this.theZoneDeputy = this.allUsers.find((x) => x.id == item.zonalDeputyId);

    this.formWrapper.nativeElement.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "start",
    });
  }

  GetClusterCount(item: GMAZone): number {
    return item.clusters.filter((x) => x.isDeleted == false).length;
  }

  LoadRegionClusterInfo() {
    this.allClusterRegionZones = this.theClusterRegion.zones.filter(
      (x) => x.isDeleted == false
    );
    this.showZoneClusterList = false;
    this.showAddZoneClusterForm = false;
    this.zoneInView = null;
  }

  SetupNewCluster(item: GMAZone) {
    this.zoneInView = item;
    this.showZoneClusterList = true;
    this.showAddZoneClusterForm = true;
  }

  CreateCluster() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Creating Cluster...",
    });

    const postData: CreateClusterVM = {
      zoneId: this.zoneInView.id,
      caption: this.clusterForm.get("Caption").value,
      description: this.clusterForm.get("Description").value,
      alias: this.clusterForm.get("Alias").value,
      clusterHeadId: this.theClusterHead.id,
    };

    if (this.theClusterDeputy)
      postData.clusterDeputyId = this.theClusterDeputy.id;

    this.employmentService.CreateNewCluster(postData).subscribe(
      async (data) => {
        await this.messageService.add({
          severity: "success",
          summary: "Completed",
          detail: "Cluster Created Successfully...",
        });

        this.showAddZoneClusterForm = false;
        this.showZoneClusterList = false;
        this.clusterForm.reset();
        this.theClusterDeputy = null;
        this.theClusterHead = null;
        this.zoneInView = null;
        this.allClusterRegionZones = [];
        this.theClusterRegion = null;
        this.FetchAllClusterRegions();
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to create cluster at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  CloseEditingCluster() {
    this.editingCluster = false;
    this.clusterForm.reset();
    this.theClusterHead = null;
    this.theClusterDeputy = null;
    this.showAddZoneClusterForm = false;
  }

  DeleteCluster(item: Cluster) {
    this.confirmationService.confirm({
      message: "Are you sure you want to remove cluster?",
      accept: () => {
        this.messageService.add({
          severity: "info",
          summary: "Notice",
          detail: "Removing cluster...",
        });

        this.employmentService.DeleteCluster(item.id).subscribe(
          async () => {
            await this.messageService.add({
              severity: "success",
              summary: "Removed",
              detail: "Removed successfully",
            });

            const index = this.allSelectedZoneClusters.indexOf(item);
            if (index > -1) {
              this.allSelectedZoneClusters.splice(index, 1);
            }
            this.FetchAllClusterRegions();
          },
          (error) => {
            console.log("Error: " + JSON.stringify(error));
            this.messageService.add({
              severity: "error",
              summary: "Notice",
              detail:
                "Unable to remove cluster at the moment.. Reason: [" + error
                  ? error.error.message
                  : "request failed - permission" + "]",
            });
          }
        );
      },
    });
  }

  EditCluster(item: Cluster) {
    this.editingCluster = true;
    this.clusterForm.addControl(
      "ID",
      new FormControl({ value: "", disabled: true }, Validators.required)
    );
    this.clusterToEdit = item;
    this.clusterForm.patchValue({
      ID: item.id,
      Caption: item.caption,
      Description: item.description,
      Alias: item.alias,
    });
    this.theClusterHead = this.allUsers.find((x) => x.id == item.clusterHeadId);
    if (item.clusterDeputyId) {
      this.theClusterDeputy = this.allUsers.find(
        (x) => x.id == item.clusterDeputyId
      );
    }

    this.showAddZoneClusterForm = true;

    this.formWrapper.nativeElement.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "start",
    });
  }

  GetUserFullName(userId: number): string {
    if (!userId) return "";
    let user = this.allUsers.find((x) => x.id == userId);
    if (user) return user.lastName + " " + user.firstName;
    else return "";
  }

  ShowZoneClusters(item: GMAZone) {
    this.zoneInView = item;
    this.allSelectedZoneClusters = item.clusters.filter(
      (x) => x.isDeleted == false
    );
    this.showZoneClusterList = true;
    this.showAddZoneClusterForm = false;
  }

  UpdateCluster() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Updating Zone...",
    });

    const id = this.clusterToEdit.id;
    const postData: UpdateClusterVM = {
      caption: this.clusterForm.get("Caption").value,
      description: this.clusterForm.get("Description").value,
      alias: this.clusterForm.get("Alias").value,
      clusterHeadId: this.theClusterHead.id,
    };

    if (this.theClusterDeputy)
      postData.clusterDeputyId = this.theClusterDeputy.id;

    this.employmentService.UpdateCluster(id, postData).subscribe(
      async () => {
        this.messageService.add({
          severity: "success",
          summary: "Notice",
          detail: "Update Successful!",
        });

        this.theClusterRegion = null;
        this.FetchAllClusterRegions();
        this.CloseEditingCluster();
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to update cluster at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  LoadClusLocRegionInfo() {
    this.allClusterLocTieZones = this.theClusterLocTieRegion.zones;
  }

  LoadClusLocZoneInfo() {
    this.allClustLocTieZoneClusters = this.theClusterLocTieZone.clusters;
  }

  ShowClusterLocations(item: Cluster) {
    console.log(item);
    this.clusterInView = item;
    this.openViewClusterInfo = true;
    this.allClientLocs = item.locations;
  }

  GetLocationCount(item: Cluster): number {
    return item.locations.filter((x) => x.isDeleted == false).length;
  }

  AllocateClusterLocation(item: Cluster) {
    this.allAllocatedLocations = [];

    this.clusterInView = item;
    this.showAllocateLocationBox = true;
  }

  AllocateLocationsToCluster() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Allocating Location(s) to Cluster...",
    });

    const postData: AllocateLocationVM = {
      clusterId: this.clusterInView.id,
      locationsId: this.allAllocatedLocations.map((x) => x.id),
    };

    this.employmentService.AllocateLocationsToCluster(postData).subscribe(
      async () => {
        await this.messageService.add({
          severity: "success",
          summary: "Completed",
          detail: "Locations Allocated To Cluster Successfully...",
        });

        this.allAllocatedLocations = [];
        this.showAllocateLocationBox = false;
        this.clusterInView = null;
        this.allClustLocTieZoneClusters = [];
        this.theClusterLocTieZone = null;
        this.theClusterLocTieRegion = null;
        this.FetchAllClusterLocRegions();
        this.FetchAllUnallocatedLocations();
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to allocate locations to cluster at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  HideViewClusterLocsDialog() {
    this.openViewClusterInfo = false;
  }

  GetLGAName(lgaId: number): string {
    let lga = this.allLGAs.find((x) => x.id == lgaId);
    if (lga) return lga.name;
    else return " ";
  }

  GetStateName(stateId: number): string {
    let state = this.allStates.find((x) => x.id == stateId);
    if (state) return state.name;
    else return " ";
  }

  async DeleteAction() {
    if (this.deleteCode == 1) {
      this.messageService.add({
        severity: "info",
        summary: "Notice",
        detail: "Removing zone...",
      });

      this.employmentService.DeleteZone(this.zoneToDelete.id).subscribe(
        async () => {
          await this.messageService.add({
            severity: "success",
            summary: "Removed",
            detail: "Removed successfully",
          });

          const index = this.allZones.indexOf(this.zoneToDelete);
          if (index > -1) {
            this.allZones.splice(index, 1);
          }
          this.HideDeleteDialog();
          this.FetchAllZones();
          this.FetchAllZoneRegions();
        },
        (error) => {
          console.log("Error: " + JSON.stringify(error));
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail:
              "Unable to delete zone at the moment.. Reason: [" + error
                ? error.error.message
                : "request failed - permission" + "]",
          });
        }
      );
    }
  }

  HideDeleteDialog() {
    this.zoneToDelete = null;
    this.openDeleteDialogue = false;
    this.deleteText = "";
  }

  DeallocateLocation(item: Location) {
    this.confirmationService.confirm({
      message:
        "This action will remove this location from its current cluster. Do you still wish to proceed?",
      accept: () => {
        this.employmentService.DeAllocateClusterLocation(item.id).subscribe(
          async () => {
            await this.messageService.add({
              severity: "success",
              summary: "Removed",
              detail: "Removed successfully",
            });

            const index = this.allClientLocs.indexOf(item);
            if (index > -1) {
              this.allClientLocs.splice(index, 1);
            }
            this.FetchAllUnallocatedLocations();
            this.FetchAllClusterLocRegions();
            this.FetchAllClusterRegions();
          },
          (error) => {
            console.log("Error: " + JSON.stringify(error));
            this.messageService.add({
              severity: "error",
              summary: "Notice",
              detail:
                "Unable to deallocate location at the moment.. Reason: [" +
                error
                  ? error.error.message
                  : "request failed - permission" + "]",
            });
          }
        );
      },
    });
  }
}
