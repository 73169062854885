import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { CommonResponse, User } from "../../../interfaces/home";
import { ConfirmationService, MessageService } from "primeng/api";
import { UserService } from "../../../services/user.service";
import { CompaniesService } from "src/app/services/companies.service";

@Component({
  selector: "app-company",
  templateUrl: "./company.component.html",
  styleUrls: ["./company.component.scss"],
  providers: [ConfirmationService, MessageService],
})
export class CompanyComponent implements OnInit {
  @ViewChild("formWrapper") public formWrapper: ElementRef;
  fetchingCompany = true;
  users: User[];
  companies: any[];
  companyForm: FormGroup;
  employeeForm1: FormGroup;
  editingCompany = false;
  private edCompany: any;
  public divisionCols: any[];
  public exportCompanyColumns: any[];
  constructor(
    private formBuilder: FormBuilder,
    public messageService: MessageService,
    public confirmationService: ConfirmationService,
    private userService: UserService,
    private companyService: CompaniesService
  ) {}

  ngOnInit(): void {
    this.fetchCompanies();

    this.companyForm = this.formBuilder.group({
      name: ["", Validators.required],
      description: ["", Validators.required],
      address: ["", Validators.required],
      headId: [null, Validators.required],
      companyId: [null],
    });
    this.divisionCols = [
      { field: "user", header: "User" },
      { field: "head", header: "Head" },
    ];
    this.fetchCompanies();
    this.userService.allUser().subscribe(async (res: CommonResponse) => {
      if (res.responseCode == "00") {
        this.users = res.responseData;
      }
    });
  }

  fetchCompanies() {
    this.companyService.getAll().subscribe(
      async (r: CommonResponse) => {
        this.companies = r.responseData;
        this.fetchingCompany = false;
      },
      (error) => {
        this.fetchingCompany = false;
      }
    );
  }
  private connectionError() {
    this.messageService.add({
      severity: "error",
      summary: "Failed",
      detail: "Connection Error, Please try again",
    });
  }
  createCompany() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Creating new Company Records",
    });
    const postData = this.companyForm.value;
    (postData.headId = this.companyForm.get("headId").value?.id),
      this.companyService.create(postData).subscribe(
        async (r: CommonResponse) => {
          if (r.responseCode == "00") {
            await this.messageService.add({
              severity: "success",
              summary: "Completed",
              detail: "New Company Created",
            });
            await this.fetchCompanies();
            await this.companyForm.reset();
          } else {
            await this.messageService.add({
              severity: "error",
              summary: "Failure",
              detail: r.responseMsg,
            });
            await this.fetchCompanies();
            await this.companyForm.reset();
          }
        },
        (error) => {
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail: "Unable to create new Company at the moment",
          });
        }
      );
  }

  deleteCompany(division) {
    this.confirmationService.confirm({
      message:
        "Are you sure you want to dropped " +
        division.name +
        ". All Op. Entities, Service Group and Service Category attached to it will also be dropped",
      accept: () => {
        this._deleteCompany(division);
      },
    });
  }
  _deleteCompany(division) {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Dropping company record",
    });
    this.companyService.delete(division.id).subscribe(
      async (result: any) => {
        await this.messageService.add({
          severity: "success",
          summary: "Dropped",
          detail: "Company record dropped",
        });
        await this.fetchCompanies();
      },
      (error) => {
        this.connectionError();
      }
    );
  }
  editCompany(company) {
    this.editingCompany = true;

    this.edCompany = company;
    this.companyForm.patchValue({
      headId: this.users.find((x) => x.id == company.headId),
      companyId: company.id,
      name: company.name,
      address: company.address,
      description: company.description,
    });

    // this.companyFormIn.nativeElement.focus();
    // document.getElementById('formFields').focus();
    // this.selectedHeadId = this.users.find(user => user.id === company.head.id);
    this.formWrapper.nativeElement.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "start",
    });
  }

  updateCompany() {
    // update the selected division
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Updating Company details",
    });
    const id = this.edCompany.id;
    const postData = this.companyForm.value;
    postData.headId = this.companyForm.get("headId").value?.id;
    var p = postData;
    this.companyService.update(id, postData).subscribe(async (result: any) => {
      if (result.responseCode == "00") {
        this.messageService.add({
          severity: "success",
          summary: "Notice",
          detail: "Company details Updated",
        });
        await this.fetchCompanies();
        await this.closeEditing();
      } else {
        this.messageService.add({
          severity: "error",
          summary: "Failure",
          detail: result.responseMsg,
        });
      }
    });
  }

  closeEditing() {
    this.editingCompany = false;
    this.companyForm.reset();
    this.edCompany = null;
  }
}
