import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { ConfirmationService, MessageService, TreeNode } from "primeng/api";
import { BreadcrumbService } from "src/app/breadcrumb.service";
import {
  ConvergenceDevice,
  ConvergenceDeviceAttachment,
  CreateDeviceAttachmentsVM,
  CreateDeviceAttachmentVM,
} from "src/app/interfaces/convergence";
import { CustomerDivision, Location } from "src/app/interfaces/home";
import { Supplier } from "src/app/interfaces/supplier";
import { ConvergenceService } from "src/app/services/convergence.service";
import { CustomerDivisionService } from "src/app/services/customer-division.service";
import { SupplierService } from "src/app/services/supplier.service";

@Component({
  selector: "app-device-convergence-registration",
  templateUrl: "./device-convergence-registration.component.html",
  styleUrls: ["./device-convergence-registration.component.scss"],
})
export class DeviceConvergenceRegistrationComponent implements OnInit {
  @ViewChild("formWrapper") public formWrapper: ElementRef;
  allClients: CustomerDivision[];
  theClient: CustomerDivision;
  allLocations: Location[];
  allClientLocations: Location[];
  theClientLocation: Location;
  allDevices: ConvergenceDevice[];
  allUnattachedDevices: ConvergenceDevice[] = [];
  allUnattachedDevicesToShow: ConvergenceDevice[] = [];
  theUnattachedDevices: ConvergenceDevice[] = [];
  allDevicesToAttach: CreateDeviceAttachmentVM[] = [];
  selectedDevicesToAttach: CreateDeviceAttachmentVM[] = [];
  allDeviceAttachments: ConvergenceDeviceAttachment[];
  selectedAttachedDevices: ConvergenceDevice[];
  mappedDevicesCols: any[];
  clientsWithRegisteredDevices: {
    clientName: string;
    treeNode: TreeNode[];
  }[] = [];
  allSuppliers: Supplier[];
  theSupplier: Supplier;
  totalNoOfClients: string = "N/A";
  totalNoOfDevices: string = "N/A";
  totalNoOfSuppliers: string = "N/A";

  constructor(
    private fb: FormBuilder,
    public supplierService: SupplierService,
    public clientService: CustomerDivisionService,
    public convergenceService: ConvergenceService,
    private breadcrumbService: BreadcrumbService,
    public confirmationService: ConfirmationService,
    public messageService: MessageService
  ) {}

  ngOnInit(): void {
    this.breadcrumbService.setItems([
      {
        label: "Convergence",
      },
      {
        label: "Device Management",
      },
      {
        label: "Map Client Device Registration",
        routerLink: ["/home/convergence/device-management/registration"],
      },
    ]);

    this.mappedDevicesCols = [
      { field: "deviceName", header: "Device Name" },
      { field: "createdAt", header: "Date Created" },
    ];

    this.FetchAllDevices();
    //this.TestPopulateData();
  }

  async FetchAllClients() {
    this.clientService.allCustomerDivision().subscribe(
      async (data) => {
        this.allClients = data.responseData;
        this.totalNoOfClients = this.allClients.length.toLocaleString();
        this.FetchAllDeviceAttachments();
      },
      (error) => {
        this.messageService.add({
          severity: "error",
          summary: "Failed",
          detail: error ?? "Some errors occurred",
        });
      }
    );
  }

  async FetchAllLocations() {
    this.convergenceService.GetAllLocations().subscribe(
      async (data) => {
        this.allLocations = data;
        this.FetchAllClients();
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all locations at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  async FetchAllSuppliers() {
    this.supplierService.getAll().subscribe(
      async (data) => {
        this.allSuppliers = data.responseData;
        this.totalNoOfSuppliers = this.allSuppliers.length.toLocaleString();
        this.FetchAllLocations();
      },
      (error) => {
        this.messageService.add({
          severity: "error",
          summary: "Failed",
          detail: error ?? "Some errors occurred",
        });
      }
    );
  }

  async FetchAllDevices() {
    this.convergenceService.GetAllConvergenceDevices().subscribe(
      async (data) => {
        this.allDevices = data;
        this.totalNoOfDevices = this.allDevices.length.toLocaleString();
        this.FetchAllSuppliers();
        // this.FetchAllLocations();
        // this.FetchAllDeviceAttachments();
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all devices at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  async FetchAllDeviceAttachments() {
    this.convergenceService.GetAllDeviceAttachments().subscribe(
      async (data) => {
        this.allDeviceAttachments = data;
        this.allUnattachedDevices = this.allDevices.filter(
          (device) =>
            data.find((attachment) => attachment.deviceId == device.id) == null
        );
        this.PopulateClientsRegDeviceBox();
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all devices at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  AttachClientDevice() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail:
        "Adding " +
        this.theUnattachedDevices.length +
        " Device Attachmentst...",
    });

    this.theUnattachedDevices.forEach((device) => {
      let find = this.allDevicesToAttach.find((x) => x.deviceId == device.id);
      if (!find) {
        this.allDevicesToAttach.push({
          clientId: this.theClient.id,
          clientLocationId: this.theClientLocation.id,
          supplierId: this.theSupplier.id,
          deviceId: device.id,
        });
      }
    });

    this.ResetAttachDeviceForm();
  }

  ResetAttachDeviceForm() {
    this.theClient = null;
    this.allClientLocations = [];
    this.theClientLocation = null;
    this.theSupplier = null;
    this.allUnattachedDevicesToShow = [];
    this.theUnattachedDevices = [];
  }

  GetDeviceName(identifier: number): string {
    let device = this.allDevices.find((x) => x.id == identifier);
    if (device) return device.deviceName;

    return "N/A";
  }

  GetClientLocation(identifier: number): string {
    let location = this.allLocations.find((x) => x.id == identifier);
    if (location) return location.name;

    return "N/A";
  }

  DetachClientDevice(item: CreateDeviceAttachmentVM) {
    const index = this.allDevicesToAttach.indexOf(item);
    if (index > -1) {
      this.allDevicesToAttach.splice(index, 1);
    }
  }

  GetSupplierName(identifer: number): string {
    let supplier = this.allSuppliers.find((x) => x.id == identifer);
    if (supplier) return supplier.supplierName;

    return "N/A";
  }

  GetClientName(identifer: number): string {
    let client = this.allClients.find((x) => x.id == identifer);
    if (client) return client.divisionName;

    return "N/A";
  }

  OnClientChange() {
    this.allClientLocations = [];
    if (this.theClient) {
      this.allClientLocations = this.allLocations.filter(
        (x) => x.customerDivisionId == this.theClient.id && x.isDeleted == false
      );
    }
  }

  OnSupplierChange() {
    this.allUnattachedDevicesToShow = [];
    if (this.theSupplier) {
      this.allUnattachedDevicesToShow = this.allUnattachedDevices.filter(
        (x) => x.supplierId == this.theSupplier.id
      );
    }
  }

  async ShowNodeInformation(event) {
    if (event.node.data) {
      let deviceAttachment: ConvergenceDeviceAttachment = event.node.data;
      this.confirmationService.confirm({
        message:
          "Are you sure you want to remove attached device " +
          event.node.label +
          " ?",
        accept: () => {
          this.messageService.add({
            severity: "info",
            summary: "Notice",
            detail: "Removing Device Attachment...",
          });

          this.convergenceService
            .DeleteDeviceAttachment(deviceAttachment.id)
            .subscribe(
              async () => {
                this.messageService.add({
                  severity: "success",
                  summary: "Removed",
                  detail: "Removed successfully",
                });

                this.ResetAttachDevices();
                this.FetchAllDeviceAttachments();
              },
              (error) => {
                console.log("Error: " + JSON.stringify(error));
                this.messageService.add({
                  severity: "error",
                  summary: "Notice",
                  detail:
                    "Unable to remove device attachment at the moment.. Reason: [" +
                    error
                      ? error.error.message
                      : "request failed - permission" + "]",
                });
              }
            );
        },
      });
    }
  }

  async PopulateClientsRegDeviceBox() {
    this.clientsWithRegisteredDevices = [];
    this.allClients
      .filter((x) => x.deviceAttachments != null)
      .forEach((client) => {
        client.deviceAttachments = null;
      });
    this.allSuppliers
      .filter((x) => x.supplierDevices != null)
      .forEach((client) => {
        client.supplierDevices = null;
      });

    this.allDeviceAttachments.forEach((deviceAttachment) => {
      let client = this.allClients.find(
        (x) => x.id == deviceAttachment.clientId
      );
      if (!client.deviceAttachments) client.deviceAttachments = [];
      client.deviceAttachments.push(deviceAttachment);
    });

    this.allClients
      .filter((x) => x.deviceAttachments != null)
      .forEach((client) => {
        let locationTree: TreeNode[] = [];
        let clientLocationIds = client.deviceAttachments.map(
          (x) => x.clientLocationId
        );
        clientLocationIds.forEach((clientLocationId) => {
          let location = this.allLocations.find(
            (x) => x.id == clientLocationId
          );
          if (location) {
            let supplierTree: TreeNode[] = [];
            let locationDeviceIds = client.deviceAttachments
              .filter((x) => x.clientLocationId == clientLocationId)
              .map((x) => x.deviceId);
            let locationDevices: ConvergenceDevice[] = [];
            locationDeviceIds.forEach((x) => {
              let deviceInfo = this.allDevices.find((y) => y.id == x);
              if (deviceInfo) locationDevices.push(deviceInfo);
            });
            locationDevices.forEach((locDevice) => {
              let supplier = this.allSuppliers.find(
                (x) => x.id == locDevice.supplierId
              );
              if (supplier) {
                if (!supplier.supplierDevices) supplier.supplierDevices = [];
                supplier.supplierDevices.push(locDevice);
              }
            });
            this.allSuppliers
              .filter((x) => x.supplierDevices != null)
              .forEach((supplier) => {
                let deviceTree: TreeNode[] = [];
                supplier.supplierDevices.forEach((deviceAttached) => {
                  let canAdd = client.deviceAttachments.find(
                    (x) => x.deviceId == deviceAttached.id
                  );
                  if (canAdd) {
                    deviceTree.push({
                      key: "Device - " + deviceAttached.id,
                      label: deviceAttached.deviceName,
                      data: canAdd,
                      icon: "pi pi-sitemap",
                      selectable: true,
                    });
                  }
                });

                if (deviceTree.length > 0) {
                  supplierTree.push({
                    key: "Supplier - " + supplier.id,
                    label: supplier.supplierName,
                    data: supplier,
                    icon: "pi pi-building",
                    selectable: false,
                    children: deviceTree,
                  });
                }
              });

            locationTree.push({
              key: "Location - " + location.id,
              label: location.name,
              data: location,
              icon: "pi pi-globe",
              children: supplierTree,
              selectable: false,
            });
          }
        });

        this.clientsWithRegisteredDevices.push({
          clientName: client.divisionName,
          treeNode: [
            {
              label: client.divisionName,
              children: locationTree,
            },
          ],
        });
      });
  }

  groupBy = (x, f, r = {}) => (x.forEach((v) => (r[f(v)] ??= []).push(v)), r);

  // groupBy(xs, f) {
  //   return xs.reduce((r, v, i, a, k = f(v)) => ((r[k] || (r[k] = [])).push(v), r), {});
  // }

  TestPopulateData() {
    let tree1: TreeNode;
    let tree2: TreeNode;

    tree1 = {
      label: "Client A",
      children: [
        {
          key: "Location 1",
          label: "Location A",
          data: null,
          icon: "pi pi-globe",
          children: [
            {
              key: "Supplier 1",
              label: "Supplier A",
              data: null,
              icon: "pi pi-building",
              selectable: false,
              children: [
                {
                  key: "Device 1",
                  label: "Device A",
                  data: null,
                  icon: "pi pi-sitemap",
                  selectable: false,
                },
                {
                  key: "Device 2",
                  label: "Device B",
                  data: null,
                  icon: "pi pi-sitemap",
                  selectable: false,
                },
              ],
            },
            {
              key: "Supplier 2",
              label: "Supplier B",
              data: null,
              icon: "pi pi-building",
              selectable: false,
              children: [
                {
                  key: "Device 1",
                  label: "Device A",
                  data: null,
                  icon: "pi pi-sitemap",
                  selectable: false,
                },
                {
                  key: "Device 2",
                  label: "Device B",
                  data: null,
                  icon: "pi pi-sitemap",
                  selectable: false,
                },
              ],
            },
          ],
          selectable: false,
        },
        {
          key: "Location 2",
          label: "Location B",
          data: null,
          icon: "pi pi-globe",
          children: [
            {
              key: "Supplier 1",
              label: "Supplier A",
              data: null,
              icon: "pi pi-building",
              selectable: false,
              children: [
                {
                  key: "Device 1",
                  label: "Device A",
                  data: null,
                  icon: "pi pi-sitemap",
                  selectable: false,
                },
                {
                  key: "Device 2",
                  label: "Device B",
                  data: null,
                  icon: "pi pi-sitemap",
                  selectable: false,
                },
              ],
            },
            {
              key: "Supplier 2",
              label: "Supplier B",
              data: null,
              icon: "pi pi-building",
              selectable: false,
              children: [
                {
                  key: "Device 1",
                  label: "Device A",
                  data: null,
                  icon: "pi pi-sitemap",
                  selectable: false,
                },
                {
                  key: "Device 2",
                  label: "Device B",
                  data: null,
                  icon: "pi pi-sitemap",
                  selectable: false,
                },
              ],
            },
          ],
          selectable: false,
        },
      ],
    };

    tree2 = {
      label: "Client B",
      children: [
        {
          key: "Location 1",
          label: "Location A",
          data: null,
          icon: "pi pi-globe",
          children: [
            {
              key: "Supplier 1",
              label: "Supplier A",
              data: null,
              icon: "pi pi-building",
              selectable: false,
              children: [
                {
                  key: "Device 1",
                  label: "Device A",
                  data: null,
                  icon: "pi pi-sitemap",
                  selectable: false,
                },
                {
                  key: "Device 2",
                  label: "Device B",
                  data: null,
                  icon: "pi pi-sitemap",
                  selectable: false,
                },
              ],
            },
            {
              key: "Supplier 2",
              label: "Supplier B",
              data: null,
              icon: "pi pi-building",
              selectable: false,
              children: [
                {
                  key: "Device 1",
                  label: "Device A",
                  data: null,
                  icon: "pi pi-sitemap",
                  selectable: false,
                },
                {
                  key: "Device 2",
                  label: "Device B",
                  data: null,
                  icon: "pi pi-sitemap",
                  selectable: false,
                },
              ],
            },
          ],
          selectable: false,
        },
        {
          key: "Location 2",
          label: "Location B",
          data: null,
          icon: "pi pi-globe",
          children: [
            {
              key: "Supplier 1",
              label: "Supplier A",
              data: null,
              icon: "pi pi-building",
              selectable: false,
              children: [
                {
                  key: "Device 1",
                  label: "Device A",
                  data: null,
                  icon: "pi pi-sitemap",
                  selectable: false,
                },
                {
                  key: "Device 2",
                  label: "Device B",
                  data: null,
                  icon: "pi pi-sitemap",
                  selectable: false,
                },
              ],
            },
            {
              key: "Supplier 2",
              label: "Supplier B",
              data: null,
              icon: "pi pi-building",
              selectable: false,
              children: [
                {
                  key: "Device 1",
                  label: "Device A",
                  data: null,
                  icon: "pi pi-sitemap",
                  selectable: false,
                },
                {
                  key: "Device 2",
                  label: "Device B",
                  data: null,
                  icon: "pi pi-sitemap",
                  selectable: false,
                },
              ],
            },
          ],
          selectable: false,
        },
      ],
    };

    this.clientsWithRegisteredDevices.push({
      clientName: "Client A",
      treeNode: [tree1],
    });
    this.clientsWithRegisteredDevices.push({
      clientName: "Client B",
      treeNode: [tree2],
    });
  }

  ResetAttachDevices() {
    this.allDevicesToAttach = [];
    this.selectedDevicesToAttach = [];
    this.theClient = null;
    this.theSupplier = null;
    this.allClientLocations = [];
    this.allUnattachedDevices = [];
  }

  SaveClientDeviceAttachment() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Saving Device Attachmentst...",
    });

    const postData: CreateDeviceAttachmentsVM = {
      attachments: this.allDevicesToAttach,
    };

    this.convergenceService.CreateDeviceAttachment(postData).subscribe(
      async () => {
        this.messageService.add({
          severity: "success",
          summary: "Completed",
          detail: "Saved Successfully!",
        });

        this.ResetAttachDevices();
        this.FetchAllDeviceAttachments();
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to save device attachments at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }
}
