import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../environments/environment";
import { Observable } from "rxjs";
import { Account, CommonResponse } from "../interfaces/home";

@Injectable({
  providedIn: "root",
})
export class AccountService {
  baseUrl = environment.baseUrl + "Account/";

  constructor(private http: HttpClient) {}

  // fetch all branch in the database
  allAccount(): Observable<CommonResponse> {
    return this.http.get<CommonResponse>(this.baseUrl);
  }

  getIncomeReceivableAccount(): Observable<CommonResponse> {
    return this.http.get<CommonResponse>(this.baseUrl + "TradeIncome");
  }

  getCashBookAccounts(): Observable<CommonResponse> {
    return this.http.get<CommonResponse>(this.baseUrl + "CashBookAccounts");
  }

  //accounts that should be mapped to service for expense accounts
  getAccountForService(): Observable<CommonResponse> {
    return this.http.get<CommonResponse>(this.baseUrl + "GetAccountForService");
  }

  getDtrackIncomeAccounts(): Observable<CommonResponse> {
    return this.http.get<CommonResponse>(
      this.baseUrl + "GetServiceIncomeAccount"
    );
  }

  // fetch all Income Account Services in the database
  allIncomeAccountService(): Observable<CommonResponse> {
    return this.http.get<CommonResponse>(
      this.baseUrl + "getIncomeAccountService"
    );
  }

  //relationships btw accounts and services
  getServiceAccounts(): Observable<CommonResponse> {
    return this.http.get<CommonResponse>(this.baseUrl + "ServiceAccounts");
  }

  getAccount(id: any): Observable<CommonResponse> {
    return this.http.get<CommonResponse>(this.baseUrl + id);
  }

  getAccountByName(name: any): Observable<CommonResponse> {
    return this.http.get<CommonResponse>(this.baseUrl + "name/" + name);
  }

  getStatements(data: any) {
    return this.http.post(this.baseUrl + "SeachAccount", data);
  }

  postAccount(data: any) {
    return this.http.post(this.baseUrl, data);
  }

  postDtrackIncomeAccounts(data: any) {
    return this.http.post(this.baseUrl + "SaveServiceIncomeAccount", data);
  }

  deleteAccount(id: any) {
    return this.http.delete(this.baseUrl + id);
  }

  updateAccount(id: any, data: any) {
    return this.http.put(this.baseUrl + id, data);
  }

  GetAllAccountByControlAccountId(
    controlAccountId: number
  ): Observable<CommonResponse<Account[]>> {
    return this.http.get<CommonResponse<Account[]>>(
      this.baseUrl + "GetAllAccountByControlAccountId/" + controlAccountId
    );
  }

  GetAllAccountsBelongingToControlAccount(data: {
    controlAccountIds: number[];
  }): Observable<CommonResponse<Account[]>> {
    return this.http.post<CommonResponse<Account[]>>(
      this.baseUrl + "GetAllAccountsBelongingToControlAccount",
      data
    );
  }

  CalculateControlAccountsTotal(data: {
    controlAccountIds: number[];
  }): Observable<
    CommonResponse<{ controlAccountId: number; totalValue: number }[]>
  > {
    return this.http.post<
      CommonResponse<{ controlAccountId: number; totalValue: number }[]>
    >(this.baseUrl + "CalculateControlAccountsTotal", data);
  }
}
