import {
  ApplicantEvaluation,
  DeployedGuard,
  UpdatePersonalBioImage,
} from "./../../../interfaces/employment";
import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { MessageService, ConfirmationService } from "primeng/api";
import { BreadcrumbService } from "src/app/breadcrumb.service";
import { AddApplicantBioData } from "src/app/interfaces/employment";
import { BiometricCaptureAction } from "src/app/interfaces/premployment";
import { GmaEmploymentService } from "src/app/services/gma-employment.service";
import { AngularFireStorage } from "@angular/fire/storage";
import { FileStorageService } from "src/app/services/file-storage.service";
import { CommonResponse } from "src/app/interfaces/home";

@Component({
  selector: "app-gma-bio-recapture",
  templateUrl: "./gma-bio-recapture.component.html",
  styleUrls: ["./gma-bio-recapture.component.scss"],
})
export class GmaBioRecaptureComponent implements OnInit {
  @ViewChild("formWrapper") public formWrapper: ElementRef;
  allOperativesWithoutBio: DeployedGuard[];
  theOperativeWithoutBio: DeployedGuard;
  operativeInfosTable: {
    name: string;
    data: string;
  }[];
  selectedOperativeInfoTable: {
    name: string;
    data: string;
  };
  allRightBiometricCaptures: {
    key: number;
    value: string;
  }[];
  allLeftBiometricCaptures: {
    key: number;
    value: string;
  }[];
  theLeftBiometricCapture: {
    key: number;
    value: string;
  };
  theRightBiometricCapture: {
    key: number;
    value: string;
  };
  theBiometricCapture: {
    key: number;
    value: string;
  };
  openCapturePrintsDialogue: boolean = false;
  showCapturePane: boolean = false;
  bioImgSrc: string = "assets/gma/PlaceFinger.bmp";
  applicantInViewBioData: boolean[];
  applicantEvaluationInView: ApplicantEvaluation;
  allOperativesCount: number = 0;
  allOperativesWithoutBioCount: number = 0;
  openUPLDialogue: boolean = false;
  imageSrc: string;
  uploadedItemImage: any[] = [];

  constructor(
    fb: FormBuilder,
    public messageService: MessageService,
    public employmentService: GmaEmploymentService,
    public fileStorageService: FileStorageService,
    private breadcrumbService: BreadcrumbService,
    public confirmationService: ConfirmationService
  ) {}

  ngOnInit(): void {
    this.breadcrumbService.setItems([
      {
        label: "Guard Management",
        routerLink: ["/home/guard-management/item-settings"],
      },
      {
        label: "Bio Recapture",
        routerLink: ["/home/guard-management/gma-bio-recapture"],
      },
    ]);

    this.operativeInfosTable = [
      {
        name: "Deployment Zone",
        data: "Data",
      },
      {
        name: "Deployment Cluster",
        data: "Data",
      },
      {
        name: "Client",
        data: "Data",
      },
      {
        name: "Location",
        data: "Data",
      },
      {
        name: "Beat",
        data: "Data",
      },
      {
        name: "Deployment Date",
        data: "Data",
      },
    ];

    this.allLeftBiometricCaptures = [
      {
        key: BiometricCaptureAction.Left_Pinky,
        value: "Left Pinky",
      },
      {
        key: BiometricCaptureAction.Left_RingFinger,
        value: "Left Ring Finger",
      },
      {
        key: BiometricCaptureAction.Left_MiddleFinger,
        value: "Left Middle Finger",
      },
      {
        key: BiometricCaptureAction.Left_IndexFinger,
        value: "Left Index Finger",
      },
      {
        key: BiometricCaptureAction.Left_Thumb,
        value: "Left Thumb",
      },
    ];

    this.allRightBiometricCaptures = [
      {
        key: BiometricCaptureAction.Right_Thumb,
        value: "Right Thumb",
      },
      {
        key: BiometricCaptureAction.Right_IndexFinger,
        value: "Right Index Finger",
      },
      {
        key: BiometricCaptureAction.Right_MiddleFinger,
        value: "Right Middle Finger",
      },
      {
        key: BiometricCaptureAction.Right_RingFinger,
        value: "Right Ring Finger",
      },
      {
        key: BiometricCaptureAction.Right_Pinky,
        value: "Right Pinky",
      },
    ];

    this.applicantInViewBioData = [
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
    ];

    this.FetchAllOperatives();
  }

  FetchAllOperatives() {
    this.employmentService.GetBioDashSummary().subscribe(
      async (data) => {
        // this.allOperativesWithoutBio = data.allOperativesWithoutBio;
        this.allOperativesWithoutBio = data.allOperatives;
        this.allOperativesCount = data.totalOperatives;
        this.allOperativesWithoutBioCount = data.totalOperativesWithoutBioData;
        data.allOperatives.forEach(
          (x) =>
            (x.jobApplication.personalInformation.fullname =
              x.jobApplication.personalInformation.lastname +
              " " +
              x.jobApplication.personalInformation.firstname)
        );
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable get all operatives at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  OPSelectionLoader() {
    this.operativeInfosTable[0].data = "N/A";
    this.operativeInfosTable[1].data = "N/A";
    this.operativeInfosTable[2].data = "N/A";
    this.operativeInfosTable[3].data = "N/A";
    this.operativeInfosTable[4].data = "N/A";
    this.operativeInfosTable[5].data = "N/A";

    this.applicantEvaluationInView =
      this.theOperativeWithoutBio.jobApplication.applicantEvaluation;
    this.employmentService
      .GetDeploymentInfo(this.theOperativeWithoutBio.id)
      .subscribe(
        async (data) => {
          let activeBeat = data.location.beats.find(
            (x) => x.isDeleted == false
          );
          this.operativeInfosTable[0].data = data.location.cluster.zone.caption;
          this.operativeInfosTable[1].data = data.location.cluster.caption;
          this.operativeInfosTable[2].data =
            data.location.customerDivision.divisionName;
          this.operativeInfosTable[3].data = data.location.name;
          this.operativeInfosTable[4].data = activeBeat
            ? activeBeat.caption
            : "N/A";
          this.operativeInfosTable[5].data = activeBeat
            ? activeBeat.createdAt.toString().substring(0, 10) + ""
            : "N/A";
        },
        (error) => {
          console.log("Error: " + JSON.stringify(error));
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail:
              "Unable get operative deployment info at the moment.. Reason: [" +
              error
                ? error.error.message
                : "request failed - permission" + "]",
          });
        }
      );
  }

  StartCaptureProcess() {
    this.showCapturePane = true;
    this.BiometricsViewHelper(this.applicantEvaluationInView.id);
  }

  CaptureLeftBiometrics() {
    this.theBiometricCapture = this.theLeftBiometricCapture
      ? this.theLeftBiometricCapture
      : null;
    this.openCapturePrintsDialogue = this.theLeftBiometricCapture
      ? true
      : false;
    this.theRightBiometricCapture = null;
  }

  HideCapturePrintsDialog() {
    this.openCapturePrintsDialogue = false;
    this.applicantEvaluationInView = null;
    this.theBiometricCapture = null;
  }

  CaptureRightBiometrics() {
    this.theBiometricCapture = this.theRightBiometricCapture
      ? this.theRightBiometricCapture
      : null;
    this.openCapturePrintsDialogue = this.theRightBiometricCapture
      ? true
      : false;
    this.theLeftBiometricCapture = null;
  }

  SaveFingerPrint() {
    if (!this.theBiometricCapture) {
      this.messageService.add({
        severity: "error",
        summary: "Notice",
        detail: "Select Finger To Scan First!",
      });
    }

    let fp = document.getElementById("FPImageTemp").innerHTML;
    const postData: AddApplicantBioData = {
      applicantEvaluationId: this.applicantEvaluationInView.id,
      biometricThump: this.theBiometricCapture.key,
      bMPBase64: fp,
    };

    this.employmentService.AddApplicantBioData(postData).subscribe(
      async () => {
        await this.messageService.add({
          severity: "success",
          summary: "Removed",
          detail: "Saved Successfully",
        });

        this.theBiometricCapture = null;
        (document.getElementById("FPImage1") as any).src = this.bioImgSrc;
        (document.getElementById("btnSaveFP") as any).disabled = true;
        this.BiometricsViewHelper(postData.applicantEvaluationId);
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to connect connect to FP Store server at the moment.. Reason: [" +
            JSON.stringify(error) +
            "]",
        });
      }
    );
  }

  BiometricsViewHelper(applicantEvaluationId: number) {
    this.applicantInViewBioData[0] = false;
    this.applicantInViewBioData[1] = false;
    this.applicantInViewBioData[2] = false;
    this.applicantInViewBioData[3] = false;
    this.applicantInViewBioData[4] = false;
    this.applicantInViewBioData[5] = false;
    this.applicantInViewBioData[6] = false;
    this.applicantInViewBioData[7] = false;
    this.applicantInViewBioData[8] = false;
    this.applicantInViewBioData[9] = false;
    this.employmentService.GetApplicantBioInfo(applicantEvaluationId).subscribe(
      async (data) => {
        data.forEach((bio) => {
          if (bio.thumbprint == BiometricCaptureAction.Left_Pinky) {
            this.applicantInViewBioData[0] = true;
          } else if (bio.thumbprint == BiometricCaptureAction.Left_RingFinger) {
            this.applicantInViewBioData[1] = true;
          } else if (
            bio.thumbprint == BiometricCaptureAction.Left_MiddleFinger
          ) {
            this.applicantInViewBioData[2] = true;
          } else if (
            bio.thumbprint == BiometricCaptureAction.Left_IndexFinger
          ) {
            this.applicantInViewBioData[3] = true;
          } else if (bio.thumbprint == BiometricCaptureAction.Left_Thumb) {
            this.applicantInViewBioData[4] = true;
          } else if (bio.thumbprint == BiometricCaptureAction.Right_Thumb) {
            this.applicantInViewBioData[5] = true;
          } else if (
            bio.thumbprint == BiometricCaptureAction.Right_IndexFinger
          ) {
            this.applicantInViewBioData[6] = true;
          } else if (
            bio.thumbprint == BiometricCaptureAction.Right_MiddleFinger
          ) {
            this.applicantInViewBioData[7] = true;
          } else if (
            bio.thumbprint == BiometricCaptureAction.Right_RingFinger
          ) {
            this.applicantInViewBioData[8] = true;
          } else if (bio.thumbprint == BiometricCaptureAction.Right_Pinky) {
            this.applicantInViewBioData[9] = true;
          }
        });
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to connect fetch applicant's bio data at the moment.. Reason: [" +
            JSON.stringify(error) +
            "]",
        });
      }
    );
  }

  CallSGIFPGetData() {
    var uri = "https://localhost:8443/SGIFPCapture";

    var xmlhttp = new XMLHttpRequest();
    xmlhttp.onreadystatechange = function () {
      if (xmlhttp.readyState == 4 && xmlhttp.status == 200) {
        let result = JSON.parse(xmlhttp.responseText);
        if (result.ErrorCode == 0) {
          /* 	Display BMP data in image tag
            BMP data is in base 64 format 
        */
          if (result != null && result.BMPBase64.length > 0) {
            (document.getElementById("FPImage1") as any).src =
              "data:image/bmp;base64," + result.BMPBase64;
            document.getElementById("FPImageTemp").innerHTML = result.BMPBase64;
            (document.getElementById("btnSaveFP") as any).disabled = false;
            //localStorage.setItem('src', result.BMPBase64)
          }
        } else
          alert(
            "Fingerprint Capture Error Code:  " +
              result.ErrorCode +
              ".\nDescription:  " +
              JSON.stringify(result.ErrorCode) +
              "."
          );
      } else if (xmlhttp.status == 404) {
        alert(
          "Check if SGIBIOSRV is running; Status = " + xmlhttp.status + ":"
        );
      }
    };
    var params = "Timeout=" + "10000";
    params += "&Quality=" + "50";
    params +=
      "&licstr=" +
      encodeURIComponent(this.fileStorageService.GetSecugenLicenseKeyStr());
    params += "&templateFormat=" + "ISO";
    params += "&imageWSQRate=" + "0.75";
    console.log;
    xmlhttp.open("POST", uri, true);
    xmlhttp.send(params);

    xmlhttp.onerror = function () {
      alert(
        "Check if SGIBIOSRV is running; Status = " + xmlhttp.statusText + ":"
      );
    };
  }

  GetOperativeCode(item: DeployedGuard): string {
    let code = item.jobApplication.trackingNo.split("#")[1];
    return "#OP-" + code;
  }

  HideUPLDialog() {
    this.openUPLDialogue = false;
  }

  UPLProfilePic() {
    this.openUPLDialogue = true;
    this.imageSrc = null;
  }

  onUpload(event) {
    this.uploadedItemImage = [];
    for (const file of event.files) {
      this.uploadedItemImage.push(file);
    }
    this.imageSrc = this.uploadedItemImage[0].objectURL;

    this.messageService.add({
      severity: "success",
      summary: "Success",
      detail: "Imgage Uploaded Successfully!",
    });
  }

  ClearImageUPL() {
    this.imageSrc = null;
  }

  async SaveImageUPL() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Saving Image...",
    });

    const postData: UpdatePersonalBioImage = {
      personalInfoId:
        this.theOperativeWithoutBio.jobApplication.personalInformationId,
      imageUrl: "",
    };

    this.fileStorageService.UploadMultipleFilesFromDataUrl(
      this.uploadedItemImage
    );
    this.fileStorageService.onUploadFinished.subscribe(
      (resp: CommonResponse<string[]>) => {
        if (resp.responseCode != "00") {
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail: resp.responseMsg,
          });
        } else {
          postData.imageUrl = resp.responseData[0];
          this.employmentService.UpdateApplicantPicture(postData).subscribe(
            async () => {
              this.messageService.add({
                severity: "success",
                summary: "Removed",
                detail: "Saved Successfully",
              });
            },
            (error) => {
              console.log("Error: " + JSON.stringify(error));
              this.messageService.add({
                severity: "error",
                summary: "Notice",
                detail:
                  "Unable to save applicant image at the moment.. Reason: [" +
                  JSON.stringify(error) +
                  "]",
              });
            }
          );
          this.theOperativeWithoutBio.jobApplication.personalInformation.imageUrl =
            resp.responseData[0];
          this.HideUPLDialog();
        }
      },
      (error) => {
        console.log("Error while uploading files " + error);
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail: "ERR: Unable to upload image to storage",
        });
      }
    );
  }
}
