import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {Observable} from 'rxjs';
import {CommonResponse} from '../interfaces/home';

@Injectable({
  providedIn: 'root'
})
export class StandardSlaService {

  constructor(private http: HttpClient) { }
  baseUrl = environment.baseUrl+'StandardSLAForOperatingEntities/';
  // fetch all StandardSLA in the database
  allStandardSLAData(): Observable<CommonResponse>{
    return this.http.get<CommonResponse>(this.baseUrl);
  }
  getStandardSLA(id: any):Observable<CommonResponse>{
    return this.http.get<CommonResponse>(this.baseUrl +id);
  }
  getStandardSLAByName(name: any):Observable<CommonResponse>{
    return this.http.get<CommonResponse>(this.baseUrl + 'name/' + name);
  }
  postStandardSLA(data: any){
    return this.http.post(this.baseUrl, data);
  }
  deleteStandardSLA(id: any){
    return this.http.delete(this.baseUrl +id);
  }
  updateStandardSLA(id: any, data: any){
    return this.http.put(this.baseUrl +id, data);
  }
}
