import { CommonResponse, User } from "src/app/interfaces/home";
import { EscalationMatrix } from "./../../../interfaces/escalation-matrix";
import { ComplaintHandlingService } from "./../../../services/complaint-handling.service";
import {
  Complaint,
  ComplaintTrackingData,
  HandlerRating,
} from "./../../../interfaces/complaint";
import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { MessageService, ConfirmationService } from "primeng/api";
import { BreadcrumbService } from "src/app/breadcrumb.service";
import { ComplaintType } from "src/app/interfaces/complaint-type";
import { UIChart } from "primeng/chart";
import { UserService } from "src/app/services/user.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";

@Component({
  selector: "app-escalation-management",
  templateUrl: "./escalation-management.component.html",
  styleUrls: ["./escalation-management.component.scss"],
})
export class EscalationManagementComponent implements OnInit {
  @ViewChild("chart") chart: UIChart;
  @ViewChild("barChart") barChart: UIChart;
  @ViewChild("formWrapper") public formWrapper: ElementRef;
  form: FormGroup;
  overduePickedComplaints: Complaint[];
  selectedOverduePickedComplaint: Complaint;
  overdueCompCols: any[];
  overdueUnpickedComplaints: Complaint[];
  selectedOverdueUnpickedComplaint: Complaint;
  pieSummary: any[];
  totalComplaintsEscalated: number;
  public data: {
    datasets: {
      backgroundColor: string[];
      data: number[];
      hoverBackgroundColor: string[];
    }[];
    labels?: string[];
  };
  complantTypes: ComplaintType[];
  theComplaintType: ComplaintType;
  barData: any;
  openDialogue: boolean;
  openAssignDialogue: boolean;
  openTrackDialogue: boolean;
  complaintToDisplay: Complaint;
  escalationMatrices: EscalationMatrix[];
  handlerEscalationLevelID: Number;
  users: User[];
  handlers: User[];
  theHandler: User;
  theComplaint: Complaint;
  complaintTracking: ComplaintTrackingData;
  fetching: boolean;

  constructor(
    fb: FormBuilder,
    public userService: UserService,
    public complaintHandlingService: ComplaintHandlingService,
    public messageService: MessageService,
    private breadcrumbService: BreadcrumbService,
    public confirmationService: ConfirmationService
  ) {
    this.form = fb.group({
      Handler: ["", Validators.required],
    });
  }

  ngOnInit(): void {
    this.breadcrumbService.setItems([
      { label: "Complaint Management" },
      {
        label: "Escalation Management",
        routerLink: ["home/complaint-management/escalation-management"],
      },
    ]);

    this.overdueCompCols = [{ field: "trackingId", header: "trackingId" }];

    this.data = {
      datasets: [
        {
          data: [20, 20, 20, 20, 20],
          backgroundColor: ["red", "green", "yellow", "blue", "green"],
          hoverBackgroundColor: [
            "red",
            "#36A2EB",
            "#FFCE56",
            "#FF6384",
            "green",
          ],
        },
      ],
    };

    this.pieSummary = [
      {
        color: "red",
        type: "Screen Damage",
        count: 20,
      },
      {
        color: "green",
        type: "Keyboard Damage",
        count: 20,
      },
      {
        color: "yellow",
        type: "Office Damage",
        count: 20,
      },
      {
        color: "blue",
        type: "Electrical Damage",
        count: 20,
      },
      {
        color: "green",
        type: "Car Damage",
        count: 20,
      },
    ];

    this.barData = {
      labels: ["Bayo", "Felicia", "Ahmed", "Josh"],
      datasets: [
        {
          label: "Handler Stats",
          backgroundColor: "blue",
          borderColor: "blue",
          data: [65, 31, 23, 32],
        },
      ],
    };
    this.complantTypes = [];
    this.complantTypes.push({
      caption: "Screen Damage",
      code: "000",
      description: "Test 1",
    });
    this.complantTypes.push({
      caption: "Keyboard Damage",
      code: "000",
      description: "Test 1",
    });
    this.complantTypes.push({
      caption: "Office Damage",
      code: "000",
      description: "Test 1",
    });
    this.complantTypes.push({
      caption: "Electrical Damage",
      code: "000",
      description: "Test 1",
    });
    this.complantTypes.push({
      caption: "Car Damage",
      code: "000",
      description: "Test 1",
    });
    this.totalComplaintsEscalated = 0;

    this.FetchUserEscalationLevelDetails();
    this.FetchAllUsers();
  }

  FetchUserEscalationLevelDetails() {
    this.complaintHandlingService.getUserEscalationLevelDetails().subscribe(
      (r: CommonResponse) => {
        if (r.responseCode == "00") {
          var escalationData = r.responseData;
          this.overduePickedComplaints = escalationData.assignedComplaints;
          this.overdueUnpickedComplaints = escalationData.unassignedComplaints;
          this.totalComplaintsEscalated =
            escalationData.totalEscalatedComplaints;
          this.complantTypes = escalationData.complaintTypes;
          this.escalationMatrices = escalationData.escalationLevelHandlers;
          this.handlerEscalationLevelID =
            escalationData.handlerEscalationLevelID;

          this.pieSummary = [];
          this.data.datasets[0].data = [];
          this.data.datasets[0].backgroundColor = [];
          this.data.datasets[0].hoverBackgroundColor = [];
          escalationData.complaintTypes.forEach((compType, index) => {
            let generatedColor = this.GenerateRandomColor();
            this.pieSummary.push({
              color: generatedColor,
              type: compType.caption,
              count: escalationData.complaintsDistribution[index],
            });

            this.data.datasets[0].data.push(
              escalationData.complaintsDistribution[index]
            );
            this.data.datasets[0].backgroundColor.push(generatedColor);
            this.data.datasets[0].hoverBackgroundColor.push(generatedColor);
            this.chart.reinit();
          });
        }
      },
      (error) => {
        this.messageService.add({
          severity: "error",
          summary: "Failed",
          detail:
            "Connection Error, Unable to retrieve escalation level details - " +
            error.error.message,
        });
      }
    );
  }

  FetchAllUsers() {
    this.userService.allUser().subscribe(
      async (data) => {
        this.users = data.responseData;
      },
      () => {
        this.ConnectionError();
      }
    );
  }

  GetComplainantName(item: Complaint) {
    return item.complainant == null
      ? "No Data"
      : item.complainant.supplierName
      ? item.complainant.supplierName
      : item.complainant.divisionName
      ? item.complainant.divisionName
      : item.complainant.lastName;
  }

  GetCurrentStatus(item: Complaint): string {
    if (item.isResolved) {
      return "Awaiting Closure";
    } else if (item.isInvestigated) {
      return "Ongoing Resolution";
    } else if (item.isAssesed) {
      return "Ongoing Investigation";
    } else {
      return "Ongoing Assessment";
    }
  }

  ShowComplaintDetails(item: Complaint) {
    this.openDialogue = true;
    this.complaintToDisplay = item;
  }

  AssignComplaintToHandler() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Assigning Complaint...",
    });

    this.complaintHandlingService
      .assignComplaint(this.theHandler.id, this.theComplaint.id)
      .subscribe(
        async () => {
          await this.messageService.add({
            severity: "success",
            summary: "Completed",
            detail: "Complaint successfully assigned to handler",
          });

          this.openAssignDialogue = false;
          this.FetchUserEscalationLevelDetails();
        },
        (error) => {
          this.messageService.add({
            severity: "error",
            summary: "Failed",
            detail:
              "Unable to assign complaint to handler -" + error.error.message,
          });
        }
      );
  }

  ShowAssignComplaintDialog(item: Complaint) {
    this.openAssignDialogue = true;
    this.handlers = [];
    this.escalationMatrices.forEach((data) => {
      if (data.complaintTypeId == item.complaintTypeId) {
        data.complaintAttendants.forEach((compAttendant) => {
          if (
            compAttendant.escalationLevelId == this.handlerEscalationLevelID
          ) {
            let handlerToAdd = this.users.find(
              (data) => data.id == compAttendant.userProfileId
            );
            handlerToAdd.fullName =
              handlerToAdd.lastName + " " + handlerToAdd.firstName;
            this.handlers.push(handlerToAdd);
          }
        });
      }
    });

    this.theComplaint = item;
  }

  ShowTrackDialogue(item: Complaint) {
    this.openTrackDialogue = true;
    this.fetching = true;
    this.theComplaint = null;
    this.complaintHandlingService.miniTrackComplaint(item.id).subscribe(
      async (r: CommonResponse) => {
        var data = r.responseData;
        this.complaintTracking = data;
        this.theComplaint = item;
        this.fetching = false;
      },
      () => {
        this.ConnectionError();
      }
    );
  }

  HideDialog() {
    this.openDialogue = false;
    this.complaintToDisplay = null;
  }

  HideAssignDialog() {
    this.openAssignDialogue = false;
    this.theHandler = null;
  }

  HideTrackDialog() {
    this.openTrackDialogue = false;
    this.theComplaint = null;
  }

  GenerateRandomColor(): string {
    var letters = "0123456789ABCDEF";
    var color = "#";
    for (var i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  SetHandlersRating() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Retreiving Handlers Rating For Selected Complaint Type...",
    });

    if (this.theComplaintType) {
      this.barData.labels = [];
      this.barData.datasets[0].data = [];

      let complaintTypeEscalationMatrice = this.escalationMatrices.find(
        (data) => data.complaintTypeId == this.theComplaintType.id
      );

      if (complaintTypeEscalationMatrice) {
        let handlersID: number[] = [];
        complaintTypeEscalationMatrice.complaintAttendants.forEach(
          (compAttendant) => {
            if (
              compAttendant.escalationLevelId == this.handlerEscalationLevelID
            ) {
              handlersID.push(compAttendant.userProfileId);
            }
          }
        );

        if (handlersID) {
          this.complaintHandlingService
            .getHandlersRatings(handlersID)
            .subscribe(
              (r: CommonResponse) => {
                var data = r.responseData;
                data.forEach((handlerRate) => {
                  this.barData.labels.push(handlerRate.username);
                  this.barData.datasets[0].data.push(handlerRate.score);
                });
                //this.barChart.reinit();
                this.barChart.refresh();
              },
              () => {
                this.ConnectionError();
                return;
              }
            );
        }
      } else {
        this.barChart.refresh();
      }

      this.messageService.add({
        severity: "success",
        summary: "Completed",
        detail: "Handlers Rating updated successfully",
      });
    }
  }

  private ConnectionError() {
    this.messageService.add({
      severity: "error",
      summary: "Failed",
      detail: "Connection Error, Please try again",
    });
  }
}
