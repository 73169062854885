import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Subject} from 'rxjs';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {BreadcrumbService} from '../../../../breadcrumb.service';
import {ArmedEscortRankService} from '../../../../services/armada/armed-escort-rank.service';
import {ConfirmationService, MessageService} from 'primeng/api';
import {takeUntil} from 'rxjs/operators';
import {ArmedEscortRank, ArmedEscortType} from '../../../../interfaces/armada';
import {ArmedEscortTypeService} from '../../../../services/armada/armed-escort-type.service';

@Component({
  selector: 'app-armed-escort-rank',
  templateUrl: './armed-escort-rank.component.html',
  styleUrls: ['./armed-escort-rank.component.scss']
})
export class ArmedEscortRankComponent implements OnInit, OnDestroy {
  @ViewChild('formWrapper') public formWrapper:ElementRef;
  private unsubscriber$ = new Subject<void>();
  public armedEscortRanks: ArmedEscortRank[];
  public targetCols: any;
  private exportTargetColumns: any;
  public loading: boolean;
  public armedEscortRankForm: FormGroup;
  public submittingData: boolean;
  selectedArmedEscortRank: any;
  selectedArmedEscortType: ArmedEscortType;
  editingArmedEscortRank= false;
  private edArmedEscortRank: ArmedEscortRank;
  public submitButtonLabel: string;
  public armedEscortTypes: ArmedEscortType[];
  constructor(
      private breadcrumbService: BreadcrumbService,
      private armedEscortRankService: ArmedEscortRankService,
      private armedEscortTypeService: ArmedEscortTypeService,
      public messageService: MessageService,
      public confirmationService: ConfirmationService,
      private formBuilder: FormBuilder,
  ) {
    this.breadcrumbService.setItems([
      { label: 'Setup' },
      { label: 'Armed Escort Rank', routerLink: ['/setup/armed-escort-rank'] }
    ]);
  }
  ngOnInit(): void {
    this.loading = true;
    this.targetCols = [
      { field: 'rankName', header: 'Rank Name' },
      { field: 'alias', header: 'Alias' },
      { field: 'description', header: 'Description' },
      { field: 'armedEscortTypeId', header: 'Escort TypeId' },
      //{ field: 'codeName', header: 'Code Name' }
    ];
    this.exportTargetColumns = this.targetCols.map(col => ({title: col.header, dataKey: col.field}));
    this.armedEscortRankForm = this.formBuilder.group( {
      rankName: ['', [Validators.required, Validators.minLength(3)]],
      alias: ['', [Validators.required, Validators.minLength(3)]],
      description: ['', [Validators.required, Validators.minLength(3)]],
      armedEscortTypeId: [null, [Validators.required]],
      //sequence: [''],
    });
    this.fetchAllArmedEscortType();
    this.fetchAllArmedEscortRank();
    this.submitButtonLabel = 'Create';
  }
  fetchAllArmedEscortType(): void {
    this.armedEscortTypeService.allArmedEscortType()
        .pipe(takeUntil(this.unsubscriber$))
        .subscribe( (result: any) => {
          this.armedEscortTypes = result;
        }, error => this.connectionError());
  }
  fetchAllArmedEscortRank(): void {
    this.armedEscortRankService.allArmedEscortRank().pipe(takeUntil(this.unsubscriber$))
        .subscribe( (res: any) => {
      this.armedEscortRanks = res;
      this.loading = false;
    }, error => {
      this.loading = false;
      this.connectionError();
    });
  }
  createArmedEscortRank() {
    this.submittingData = true;
    const formData = this.armedEscortRankForm.value;
    const data = {
      rankName: formData.rankName,
      alias: formData.alias,
      description: formData.description,
      armedEscortTypeId: this.selectedArmedEscortType.id,
      // sequence: 0
    }
    this.armedEscortRankService.postArmedEscortRank(data).pipe(takeUntil(this.unsubscriber$))
        .subscribe( (result) => {
          this.messageService.add({
            summary: 'Success', severity: 'success', detail: 'New Rank created',
          });
          this.fetchAllArmedEscortRank();
          this.submittingData = false;
          this.armedEscortRankForm.reset();
        });
  }


  deleteArmedEscortRank(armedEscortRank: ArmedEscortRank): void {
    this.confirmationService.confirm({
      message: 'Are you sure you want to delete ' + armedEscortRank.rankName,
      accept: () => {
        this._deleteArmedEscortRank(armedEscortRank);
      }
    });
  }
  _deleteArmedEscortRank(armedEscortRank) {
    this.messageService.add({severity:'info', summary: 'Notice',
      detail:'Deleting Rank record'});
    this.armedEscortRankService.delete(armedEscortRank.id).subscribe( async (result:any) => {
      await this.messageService.add({severity:'success', summary: 'Deleted',
        detail:'Rank record removed'});
      await this.fetchAllArmedEscortRank();
    }, error => {
      this.connectionError()
    });
  }
  editArmedEscortRank(armedEscortRank: ArmedEscortRank): void{
    this.edArmedEscortRank = armedEscortRank;
    this.formWrapper.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'start' });
    this.armedEscortRankForm.setValue({
      rankName: armedEscortRank.rankName,
      alias: armedEscortRank.alias,
      description: armedEscortRank.description,
      //sequence: armedEscortRank.sequence,
      armedEscortTypeId: armedEscortRank.armedEscortTypeId,
    })
    this.selectedArmedEscortType = this.armedEscortTypes.find( escortType => escortType.id === armedEscortRank.armedEscortTypeId);
    this.editingArmedEscortRank = true;
    this.submitButtonLabel = 'Update';
  }
  updateArmedEscortRank(): boolean {
    this.submittingData = true;
    if(!this.edArmedEscortRank) {
      return false;
    }
    const formData = this.armedEscortRankForm.value;
    const data = {
      rankName: formData.rankName,
      alias: formData.alias,
      description: formData.description,
      armedEscortTypeId: this.selectedArmedEscortType.id,
      // sequence: 0
    }
    this.armedEscortRankService.updateArmedEscortRank(this.edArmedEscortRank.id, data)
        .pipe(takeUntil(this.unsubscriber$))
        .subscribe(() => {
          this.messageService.add({
            severity: 'success',
            detail: 'Armed Escort Type Updated',
            summary: 'Completed'
          });
          this.submittingData = false;
          this.closeEditing();
          this.fetchAllArmedEscortRank();
        }, error => {
          this.connectionError();
          this.submittingData = false;
        })
  }
  closeEditing() {
    this.editingArmedEscortRank = false;
    this.armedEscortRankForm.reset();
    this.edArmedEscortRank = null;
    this.submitButtonLabel = 'Create';
  }
  private connectionError() {
    this.messageService.add({severity:'error', summary: 'Failed',
      detail:'Connection Error, Please try again'});
  }
  ngOnDestroy(): void {
    this.unsubscriber$.complete();
    this.unsubscriber$.unsubscribe();
  }
}
