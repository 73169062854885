import { Component, OnInit } from '@angular/core';
import { BreadcrumbService } from "src/app/breadcrumb.service";
import { DialogService, DynamicDialogRef } from "primeng/dynamicdialog";

import { NgModule } from '@angular/core';
import {FormsModule} from "@angular/forms";
import { CommonResponse, Endorsement, EndorsementType, Invoice } from 'src/app/interfaces/home';
import { EndorsementTypeService } from 'src/app/services/endorsement-type.service';
import { EndorsementService } from 'src/app/services/endorsement.service';
import { ConfirmationService, Message, MessageService } from "primeng/api";
import { CalendarModule } from 'primeng/calendar';


@Component({
  selector: 'app-period-opening-closing',
  templateUrl: './period-opening-closing.component.html',
  styleUrls: ['./period-opening-closing.component.scss']
})
export class PeriodOpeningClosingComponent implements OnInit {
  invoices: string[] = [
    "new contract", "top up", "reduction", "cancellation", "credit note"
  ]
  //endorsement: any = ""
  fecthingEndorsementTypes = true
  viewedContractService = true
  endorsementTypes: EndorsementType[] = [];
  openingDateTime: any = new Date();
  closingDateTime: any = new Date();
  postEndorsementOpenAndClose: any
  openingDateTimeResult: any;

  constructor(
    public endorsement: EndorsementService,
    public endorsementType: EndorsementTypeService,
    public messageService: MessageService,
    public dialogService: DialogService,
    public confirmationService: ConfirmationService,
    private breadcrumbService: BreadcrumbService,
    ) { }

  ngOnInit(): void {
    this.fecthingEndorsementTypes = true;
    this.endorsementType.allEndorsementTypeData().subscribe(
      async (data) => {
        this.endorsementTypes = data.responseData;
        var types = [];
        for (let index = 0; index < this.endorsementTypes.length; index++) {
          const element = this.endorsementTypes[index];
          if(element.closingDate && element.openingDate){
            element.closingDate = new Date(element.closingDate);
            element.openingDate = new Date(element.openingDate)
          }
          
          types.push(element);
        }
        this.endorsementTypes = types;
        this.fecthingEndorsementTypes = false; 
      },
      (error) => {
        //this.connectionError();
        //this.apiLoadingService.hide();
      }
    );
    this.fecthingEndorsementTypes = false;

  }

  save(endorse){
    var postDate = {
      openingDate: endorse.openingDate,
      closingDate: endorse.closingDate,
      Id: endorse.id
    }
    
    this.endorsement.endorsementopeningClosing(postDate).subscribe(
      async (r: CommonResponse) => {
        if(r.responseCode =='00'){
         //give success toast
         await this.messageService.add({
          severity: "success",
          summary: "Updated",
          detail: "Saved successfully.",
        });
        }else{
          this.messageService.add({
            severity: "error",
            summary: "Error",
            detail: r.responseMsg,
          });
        }
      },
      (error) => {
        //this.connectionError();
        //this.apiLoadingService.hide();
        this.messageService.add({
          severity: "error",
          summary: "Failed",
          detail: "error sending data",
        });
      }
    );
   }

}
