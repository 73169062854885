import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { ConfirmationService, MessageService } from "primeng/api";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { BreadcrumbService } from "src/app/breadcrumb.service";
import {
  ArmadaJourneyStart,
  ArmedEscortServiceAssignmentDetail,
  CommanderServiceAssignmentDetail,
  FeedbackMaster,
  GeneralFeedbackDetail,
  MasterServiceAssignment,
  PilotServiceAssignmentDetail,
  VehicleServiceAssignmentDetail,
} from "src/app/interfaces/armada";
import { CommonResponse } from "src/app/interfaces/home";
import { JourneyStartAndStopService } from "src/app/services/armada/journey-start-and-stop.service";
import { MasterServiceAssignmentService } from "src/app/services/armada/master-service-assignment.service";
import { ServiceAssignmentDetailService } from "src/app/services/armada/service-assignment-detail.service";
import { SMORouteService } from "src/app/services/armada/smoroute.service";

@Component({
  selector: "app-journey-end-feedback",
  templateUrl: "./journey-end-feedback.component.html",
  styleUrls: ["./journey-end-feedback.component.scss"],
})
export class JourneyEndFeedbackComponent implements OnInit {
  @ViewChild("formWrapper") public formWrapper: ElementRef;
  private unsubscriber$ = new Subject<void>();
  public targetCols: any;
  private exportTargetColumns: any;
  public submittingData: boolean;
  public loading: boolean;
  public submitButtonLabel: string;
  hello: string = "Message";
  constructor(
    private breadcrumbService: BreadcrumbService,
    private route: ActivatedRoute,
    public messageService: MessageService,
    public confirmationService: ConfirmationService,
    private formBuilder: FormBuilder,
    private serviceAssignmentService: MasterServiceAssignmentService,
    private serviceAssignmentDetailService: ServiceAssignmentDetailService,
    private journeyStartAndStopService: JourneyStartAndStopService
  ) {}
  public masterServiceAssignments: MasterServiceAssignment;
  public armadaJourneyStart: ArmadaJourneyStart;
  public feedbackMaster: FeedbackMaster;
  public generalFeedbackDetail: GeneralFeedbackDetail;

  selectedmasterServiceAssignment: any;
  public armedEscortServiceAssignmentDetails: ArmedEscortServiceAssignmentDetail[];
  armedEscortServiceAssignmentDetailsFilter: ArmedEscortServiceAssignmentDetail[];
  selectedArmedEscortServiceAssignmentDetail: any;

  public commanderServiceAssignmentDetails: CommanderServiceAssignmentDetail[];
  commanderServiceAssignmentDetailsFilter: CommanderServiceAssignmentDetail[];
  selectedCommanderServiceAssignmentDetail: any;
  public pilotServiceAssignmentDetails: PilotServiceAssignmentDetail[];
  pilotServiceAssignmentDetailsFilter: PilotServiceAssignmentDetail[];
  selectedPilotServiceAssignmentDetail: any;
  public vehicleServiceAssignmentDetails: VehicleServiceAssignmentDetail[];
  vehicleServiceAssignmentDetailsFilter: VehicleServiceAssignmentDetail[];
  selectedVehicleServiceAssignmentDetail: any;
  //Forms
  public pilotForm: FormGroup;
  public commanderForm: FormGroup;
  public armedEscortForm: FormGroup;
  public vehicleForm: FormGroup;
  public feedbackMasterForm: FormGroup;
  public generalFeedbackForm: FormGroup;
  resourceId: any;
  objLenght: number;
  //public serviceAssignmentForm: FormGroup;
  vehicleSlider: number = 2;
  pilotSlider: number = 2;
  escortSlider: number = 2;
  commanderSlider: number = 2;
  slideVal: number;
  generalslideVal: number;
  generalslideVal2: number;
  valRadio: boolean;
  public getAssignId: number;
  public getJourneyId: number;
  public getFeedbackMasterId: number;
  showFeedbackBox: boolean;
  showTextArea: boolean;
  showSuccessPageOnly: boolean;
  vehicleResId: number;
  isGeneralFeedbackAvailalable: boolean;
  page = "";
  ngOnInit(): void {
    let id = parseInt(this.route.snapshot.paramMap.get("id"));
    this.getAssignId = id;

    this.journeyStartAndStopService
      .getGeneralFeedbackByAssignmentId(this.getAssignId)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            var res = r.responseData;

            this.generalFeedbackDetail = res;
            console.log(this.generalFeedbackDetail);
            let obj = 0;
            if (this.generalFeedbackDetail !== null) {
              obj = 1;
              this.objLenght = obj;
              this.isGeneralFeedbackAvailalable === true;
              console.log("true:false: ", obj);
            } else {
              obj = 0;
              this.objLenght = obj;
              console.log("obj leng.", obj);
              this.isGeneralFeedbackAvailalable === false;
            }
            this.loading = false;
          }
        },
        (error) => {
          this.loading = false;
          this.connectionError();
        }
      );

    this.feedbackMasterForm = this.formBuilder.group({
      journeyStartId: [null, [Validators.required]],
      masterServiceAssignmentId: [null, [Validators.required]],
    });

    this.generalFeedbackForm = this.formBuilder.group({
      //feedbackMasterId: [null, [Validators.required]],
      wasSafeAndComfortable: [null, [Validators.required]],
      cSATScore: [null, [Validators.required]],
      nPSScore: [null, [Validators.required]],
      reasonForNotFeelingSafe: ["", [Validators.minLength(3)]],
      // reasonForNotFeelingSafe: [
      //   "",
      //   [Validators.required, Validators.minLength(3)],
      // ],
      //sequence: [''],
    });

    this.pilotForm = this.formBuilder.group({
      feedbackMasterId: [null, [Validators.required]],
      resourceId: [null, [Validators.required]],
      performanceScore: [null, [Validators.required]],
      comments: ["", [Validators.required, Validators.minLength(3)]],
      // gender: ["", [Validators.required, Validators.minLength(3)]],
      // dob: ["", [Validators.required, Validators.minLength(3)]],

      //sequence: [''],
    });

    this.commanderForm = this.formBuilder.group({
      feedbackMasterId: [null, [Validators.required]],
      resourceId: [null, [Validators.required]],
      performanceScore: [null, [Validators.required]],
      comments: ["", [Validators.required, Validators.minLength(3)]],
    });

    this.armedEscortForm = this.formBuilder.group({
      feedbackMasterId: [null, [Validators.required]],
      resourceId: [null, [Validators.required]],
      performanceScore: [null, [Validators.required]],
      comments: ["", [Validators.required, Validators.minLength(3)]],
    });
    this.vehicleForm = this.formBuilder.group({
      feedbackMasterId: [[], [Validators.required]],
      resourceId: [[], [Validators.required]],
      performanceScore: [[], [Validators.required]],
      comments: [[], [Validators.required, Validators.minLength(3)]],
    });
    // this.vehicleForm = this.formBuilder.group({
    //  'workouts': new FormArray([
    //   'feedbackMasterId', [null, [Validators.required]],
    //   'resourceId', [null, [Validators.required]],
    //   'performanceScore', [null, [Validators.required]],
    //   'comments', [null, [Validators.required, Validators.minLength(3)]],
    //  ])
    // });
    // this.vehicleForm = this.formBuilder.group({
    //   feedbackMasterId: [null, [Validators.required]],
    //   resourceId: [null, [Validators.required]],
    //   performanceScore: [null, [Validators.required]],
    //   comments: ["", [Validators.required, Validators.minLength(3)]],
    // });

    //this.fetchGeneralFbackByAssignmentId();
    this.fetchMasterServiceAssignmentById();
    //this.fetchAllArmedEscortServiceAssignmentDetailsByAssId();
    //this.fetchAllCommanderServiceAssignmentDetailsByAssId();
    //this.fetchAllPilotServiceAssignmentDetailsByAssId();
    // this.serviceAssignmentDetailService
    //   .allVehicleDetailsByAssignmentId(this.getAssignId)
    //   .pipe(takeUntil(this.unsubscriber$))
    //   .subscribe(
    //     (r: CommonResponse) => {
    //       if (r.responseCode == "00") {
    //         var res = r.responseData;
    //         this.vehicleServiceAssignmentDetails = res;

    //         console.log(this.vehicleServiceAssignmentDetails);

    //         this.loading = false;
    //       }
    //     },
    //     (error) => {
    //       this.loading = false;
    //       this.connectionError();
    //     }
    //   );

    //this.fetchAllVehicleServiceAssignmentDetailsByAssId();
    //this.fetchJourneyStartByAssignmentId(id);
    this.fetchFeedBackMasterByAssignmentId();

    console.log("Assign Id: ", this.getAssignId);

    //For JourneyStart
    this.journeyStartAndStopService
      .getJourneyStartFeedbackByAssignmentId(id)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            var res = r.responseData;
            this.armadaJourneyStart = res;
            this.getJourneyId = this.armadaJourneyStart.id;
            console.log("journeystart Id: ", this.getJourneyId);
            this.loading = false;
          }
        },
        (error) => {
          this.loading = false;
          this.connectionError();
        }
      );

    console.log("journeystart Ids: ", this.getJourneyId);
    this.page = "one";
  }

  // fetchAllMasterServiceAssignments(): void {
  //   this.serviceAssignmentService
  //     .allServiceAssignments()
  //     .pipe(takeUntil(this.unsubscriber$))
  //     .subscribe(
  //       (r: CommonResponse) => {
  //         if (r.responseCode == "00") {
  //           var res = r.responseData;
  //           this.masterServiceAssignments = res;
  //           this.loading = false;
  //         }
  //       },
  //       (error) => {
  //         this.loading = false;
  //         this.connectionError();
  //       }
  //     );
  //   this.submittingData = false;
  // }

  fetchMasterServiceAssignmentById(): void {
    this.journeyStartAndStopService
      .getServiceAssignment(this.getAssignId)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            var res = r.responseData;
            this.masterServiceAssignments = res;
            this.loading = false;
          }
        },
        (error) => {
          this.loading = false;
          this.connectionError();
        }
      );
    this.submittingData = false;
  }
  fetchGeneralFbackByAssignmentId(): void {
    this.journeyStartAndStopService
      .getGeneralFeedbackByAssignmentId(this.getAssignId)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            var res = r.responseData;
            this.generalFeedbackDetail = res;
            let objLength = Object.keys(this.generalFeedbackDetail).length;
            console.log("obj leng.", objLength);
            if (objLength > 0) {
              this.isGeneralFeedbackAvailalable === true;
              console.log("true:false: ", this.isGeneralFeedbackAvailalable);
            } else {
              console.log("obj leng.", objLength);
              this.isGeneralFeedbackAvailalable === false;
            }
            this.loading = false;
          }
        },
        (error) => {
          this.loading = false;
          this.connectionError();
        }
      );
    this.submittingData = false;
  }
  fetchJourneyStartByAssignmentId(id): void {
    this.journeyStartAndStopService
      .getJourneyStartFeedbackByAssignmentId(id)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            var res = r.responseData;
            this.armadaJourneyStart = res;
            this.getJourneyId = this.armadaJourneyStart.id;
            //console.log("journeystart Id: ", this.getJourneyId);
            this.loading = false;
          }
        },
        (error) => {
          this.loading = false;
          this.connectionError();
        }
      );
    this.submittingData = false;
  }
  fetchFeedBackMasterByAssignmentId(): void {
    this.journeyStartAndStopService
      .getFeedbackMsterByAssignmentId(this.getAssignId)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            var res = r.responseData;
            this.feedbackMaster = res;
            this.getFeedbackMasterId = this.feedbackMaster.id;
            this.loading = false;
          }
        },
        (error) => {
          this.loading = false;
          this.connectionError();
        }
      );
    this.submittingData = false;
  }
  // fetchAllVehicleServiceAssignmentDetailsByAssId(): void {
  //   this.serviceAssignmentDetailService
  //     .allVehicleDetailsByAssignmentId(this.getAssignId)
  //     .pipe(takeUntil(this.unsubscriber$))
  //     .subscribe(
  //       (r: CommonResponse) => {
  //         if (r.responseCode == "00") {
  //           var res = r.responseData;
  //           this.vehicleServiceAssignmentDetailsFilter = res;
  //           let vehArr = this.vehicleServiceAssignmentDetailsFilter.filter(
  //             (x) => x.isTemporarilyHeld === true
  //           );
  //           this.vehicleServiceAssignmentDetails = vehArr;
  //           console.log(this.vehicleServiceAssignmentDetails);
  //           // this.vehicleServiceAssignmentDetails.forEach((value) => {
  //           //   this.vehicleForm.patchValue({
  //           //     resourceId: value.vehicleResourceId,
  //           //   });
  //           // });
  //           this.loading = false;
  //         }
  //       },
  //       (error) => {
  //         this.loading = false;
  //         this.connectionError();
  //       }
  //     );
  //   this.submittingData = false;
  // }
  // fetchAllPilotServiceAssignmentDetailsByAssId(): void {
  //   this.serviceAssignmentDetailService
  //     .allPilotDetailsByAssignmentId(this.getAssignId)
  //     .pipe(takeUntil(this.unsubscriber$))
  //     .subscribe(
  //       (r: CommonResponse) => {
  //         if (r.responseCode == "00") {
  //           var res = r.responseData;
  //           this.pilotServiceAssignmentDetails = res;
  //           let vehArr = this.pilotServiceAssignmentDetailsFilter.filter(
  //             (x) => x.isTemporarilyHeld === true
  //           );
  //           this.pilotServiceAssignmentDetails = vehArr;
  //           console.log(this.pilotServiceAssignmentDetails);
  //           this.loading = false;
  //         }
  //       },
  //       (error) => {
  //         this.loading = false;
  //         this.connectionError();
  //       }
  //     );
  //   this.submittingData = false;
  // }
  // fetchAllCommanderServiceAssignmentDetailsByAssId(): void {
  //   this.serviceAssignmentDetailService
  //     .allCommanderDetailsByAssignmentId(this.getAssignId)
  //     .pipe(takeUntil(this.unsubscriber$))
  //     .subscribe(
  //       (r: CommonResponse) => {
  //         if (r.responseCode == "00") {
  //           var res = r.responseData;
  //           this.commanderServiceAssignmentDetailsFilter = res;
  //           let vehArr = this.commanderServiceAssignmentDetailsFilter.filter(
  //             (x) => x.isTemporarilyHeld === true
  //           );
  //           this.commanderServiceAssignmentDetails = vehArr;
  //           console.log(this.commanderServiceAssignmentDetails);
  //           this.loading = false;
  //         }
  //       },
  //       (error) => {
  //         this.loading = false;
  //         this.connectionError();
  //       }
  //     );
  //   this.submittingData = false;
  // }
  // fetchAllArmedEscortServiceAssignmentDetailsByAssId(): void {
  //   this.serviceAssignmentDetailService
  //     .allArmedEscortDetailsByAssignmentId(this.getAssignId)
  //     .pipe(takeUntil(this.unsubscriber$))
  //     .subscribe(
  //       (r: CommonResponse) => {
  //         if (r.responseCode == "00") {
  //           var res = r.responseData;
  //           this.armedEscortServiceAssignmentDetailsFilter = res;
  //           let vehArr = this.armedEscortServiceAssignmentDetailsFilter.filter(
  //             (x) => x.isTemporarilyHeld === true
  //           );
  //           this.armedEscortServiceAssignmentDetails = vehArr;
  //           console.log(this.armedEscortServiceAssignmentDetails);
  //           this.loading = false;
  //         }
  //       },
  //       (error) => {
  //         this.loading = false;
  //         this.connectionError();
  //       }
  //     );
  //   this.submittingData = false;
  // }

  //Resource
  createMasterFeedback() {
    const formData = this.feedbackMasterForm.value;
    const postData = {
      journeyStartId: this.getJourneyId,
      masterServiceAssignmentId: this.getAssignId,
    };
    this.journeyStartAndStopService
      .postFeedbackMaster(postData)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            var res = r.responseData;
            this.messageService.add({
              summary: "Success",
              severity: "success",
              detail: "Proceed",
            });
            this.showFeedbackBox = true;
            this.getFeedbackMasterId = res.id;
            this.fetchFeedBackMasterByAssignmentId();
            //this.closeEditing();
          } else {
            this.messageService.add({
              severity: "error",
              summary: "Failed",
              detail: " Error: " + r.responseMsg,
            });
            this.submittingData = false;
          }
        },
        (error) => {
          this.messageService.add({
            severity: "error",
            summary: "Failed",
            detail: "Connection Error " + error.error.message,
          });
          this.submittingData = false;
        }
      );
    this.submittingData = false;
  }
  createGeneralFeedback() {
    const formData = this.generalFeedbackForm.value;
    //const date = formData.pickupDate;
    const postData = {
      feedbackMasterId: this.getFeedbackMasterId,
      wasSafeAndComfortable: formData.wasSafeAndComfortable,
      cSATScore: formData.cSATScore,
      nPSScore: formData.nPSScore,
      reasonForNotFeelingSafe: formData.reasonForNotFeelingSafe,
    };
    this.journeyStartAndStopService
      .postGeneralFeedback(postData)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            this.messageService.add({
              summary: "Success",
              severity: "success",
              detail: "Feedback Submitted Successfully",
            });
            this.fetchGeneralFbackByAssignmentId();
            this.isGeneralFeedbackAvailalable = true;
            this.showFeedbackBox = false;
            this.objLenght = 1;
            //this.closeEditing();
          } else {
            this.messageService.add({
              severity: "error",
              summary: "Failed",
              detail: " Error: " + r.responseMsg,
            });
            this.submittingData = false;
          }
        },
        (error) => {
          this.messageService.add({
            severity: "error",
            summary: "Failed",
            detail: "Connection Error " + error.error.message,
          });
          this.submittingData = false;
        }
      );
    this.submittingData = false;
  }

  createPilotFeedback() {
    const formData = this.pilotForm.value;

    const postData = {
      feedbackMasterId: this.getFeedbackMasterId,
      resourceId: formData.resourceId,
      performanceScore: formData.performanceScore,
      comments: formData.comments,
    };
    this.journeyStartAndStopService
      .postPilotFeedback(postData)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            this.messageService.add({
              summary: "Success",
              severity: "success",
              detail: "Feedback Sent",
            });

            //this.closeEditing();
          } else {
            this.messageService.add({
              severity: "error",
              summary: "Failed",
              detail: " Error: " + r.responseMsg,
            });
            this.submittingData = false;
          }
        },
        (error) => {
          this.messageService.add({
            severity: "error",
            summary: "Failed",
            detail: "Connection Error " + error.error.message,
          });
          this.submittingData = false;
        }
      );
    this.submittingData = false;
  }

  createCommanderFeedback() {
    const formData = this.commanderForm.value;

    const postData = {
      feedbackMasterId: this.getFeedbackMasterId,
      resourceId: formData.resourceId,
      performanceScore: formData.performanceScore,
      comments: formData.comments,
    };
    this.journeyStartAndStopService
      .postCommanderFeedback(postData)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            this.messageService.add({
              summary: "Success",
              severity: "success",
              detail: "Feedback Sent",
            });

            //this.closeEditing();
          } else {
            this.messageService.add({
              severity: "error",
              summary: "Failed",
              detail: " Error: " + r.responseMsg,
            });
            this.submittingData = false;
          }
        },
        (error) => {
          this.messageService.add({
            severity: "error",
            summary: "Failed",
            detail: "Connection Error " + error.error.message,
          });
          this.submittingData = false;
        }
      );
    this.submittingData = false;
  }

  createArmedEscortFeedback() {
    const formData = this.armedEscortForm.value;

    const postData = {
      feedbackMasterId: this.getFeedbackMasterId,
      resourceId: formData.resourceId,
      performanceScore: formData.performanceScore,
      comments: formData.comments,
    };
    this.journeyStartAndStopService
      .postArmedEscortFeedback(postData)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            this.messageService.add({
              summary: "Success",
              severity: "success",
              detail: "Feedback Sent",
            });

            //this.closeEditing();
          } else {
            this.messageService.add({
              severity: "error",
              summary: "Failed",
              detail: " Error: " + r.responseMsg,
            });
            this.submittingData = false;
          }
        },
        (error) => {
          this.messageService.add({
            severity: "error",
            summary: "Failed",
            detail: "Connection Error " + error.error.message,
          });
          this.submittingData = false;
        }
      );
    this.submittingData = false;
  }

  createVehicleFeedback() {
    const formData = this.vehicleForm.value;

    const postData = {
      feedbackMasterId: this.getFeedbackMasterId,
      //resourceId: formData.append(),
      resourceId: formData.resourceId,
      performanceScore: formData.performanceScore,
      comments: formData.comments,
    };
    this.journeyStartAndStopService
      .postVehicleFeedback(postData)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            this.messageService.add({
              summary: "Success",
              severity: "success",
              detail: "Feedback Sent",
            });

            //this.closeEditing();
          } else {
            this.messageService.add({
              severity: "error",
              summary: "Failed",
              detail: " Error: " + r.responseMsg,
            });
            this.submittingData = false;
          }
        },
        (error) => {
          this.messageService.add({
            severity: "error",
            summary: "Failed",
            detail: "Connection Error " + error.error.message,
          });
          this.submittingData = false;
        }
      );
    this.submittingData = false;
  }

  showTextAreaForFeedback() {
    if (this.valRadio === true) {
      this.showTextArea = false;
    } else this.showTextArea = true;
  }

  hideTextAreaForFeedback() {
    this.showTextArea = false;
  }
  openFeedbackBox() {
    // let getresource = Number(
    //   document.getElementById("vehResourceID").textContent
    // );
    // console.log(getresource);
    // let id = ((<HTMLInputElement>document.getElementById("resourceId")).value =
    //   getresource.toString());
    // this.resourceId = getresource;
    // console.log(this.resourceId);
    this.showFeedbackBox = true;
  }
  rate() {
    this.page = "two";
  }
  success() {
    this.page = "three";
  }
  //Feedback
  customTrackBy(index: number, obj: any) {
    return obj ? obj.vehicleResourceId : undefined;
    //return index;
  }

  private connectionError() {
    this.messageService.add({
      severity: "error",
      summary: "Failed",
      detail: "Connection Error, Please try again",
    });
  }
}
