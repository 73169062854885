import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CommonResponse } from '../interfaces/home';

@Injectable({
  providedIn: 'root'
})
export class DesignationService {

  dobservable: Observable<CommonResponse>;

  constructor(private http: HttpClient) { }
  baseUrl = environment.baseUrl+'Designation/';
  // fetch all Designation in the database
  allDesignationData(): Observable<any>{
    if(this.dobservable) return this.dobservable
    else {
      this.dobservable = this.http.get<any>(this.baseUrl).pipe(shareReplay()); 
      return this.dobservable;
    }
  }
  getDesignation(id: any):Observable<CommonResponse>{
    return this.http.get<CommonResponse>(this.baseUrl +id);
  }
  getDesignationByName(name: any):Observable<CommonResponse>{
    return this.http.get<CommonResponse>(this.baseUrl + 'name/' + name);
  }
  postDesignation(data: any){
    return this.http.post(this.baseUrl, data);
  }
  deleteDesignation(id: any){
    return this.http.delete(this.baseUrl +id);
  }
  updateDesignation(id: any, data: any){
    return this.http.put(this.baseUrl +id, data);
  }
}
