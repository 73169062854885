import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {Observable} from 'rxjs';
import {CommonResponse} from '../interfaces/home';

@Injectable({
  providedIn: 'root'
})
export class QuoteServiceDocumentService {
  constructor(private http: HttpClient) {
  }

  baseUrl = environment.baseUrl + 'QuoteServiceDocument/';

  // fetch all QuoteServiceDocument in the database
  allQuoteServiceDocumentData(): Observable<CommonResponse> {
    return this.http.get<CommonResponse>(this.baseUrl);
  }

  getQuoteServiceDocument(id: any): Observable<CommonResponse> {
    return this.http.get<CommonResponse>(this.baseUrl + id);
  }
  getDocumentByQS(quoteServiceId): Observable<CommonResponse>{
    return this.http.get<CommonResponse>(this.baseUrl + 'QuoteService/' + quoteServiceId);
  }
  getQuoteServiceDocumentByName(name: any): Observable<CommonResponse> {
    return this.http.get<CommonResponse>(this.baseUrl + 'name/' + name);
  }

  postQuoteServiceDocument(data: any){
    return this.http.post(this.baseUrl, data);
  }

  deleteQuoteServiceDocument(id: any) {
    return this.http.delete(this.baseUrl + id);
  }

  updateQuoteServiceDocument(id: any, data: any) {
    return this.http.put(this.baseUrl + id, data);
  }
}
