import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { ConfirmationService, Message, MessageService } from "primeng/api";
import { BreadcrumbService } from "src/app/breadcrumb.service";
import {
  ProcessBulkSettlementVM,
  UnUsedAdvancePaymentView,
} from "src/app/interfaces/home";
import { AccountMasterService } from "src/app/services/account-master";
import { AdvancePaymentsService } from "src/app/services/advance-payments.service";

@Component({
  selector: "app-finance-bulk-settlement",
  templateUrl: "./finance-bulk-settlement.component.html",
  styleUrls: ["./finance-bulk-settlement.component.scss"],
  providers: [MessageService],
})
export class FinanceBulkSettlementComponent implements OnInit {
  @ViewChild("formWrapper") public formWrapper: ElementRef;
  msg: Message[] = [];
  msg2: Message[] = [];
  allPendingAdvances: UnUsedAdvancePaymentView[] = [];
  selectedPendingAdvances: UnUsedAdvancePaymentView[] = [];
  isSpecificContract: boolean = false;
  fetchingAdvances: boolean;
  colsAdvances: any[];
  allPendingCreditNotes: UnUsedAdvancePaymentView[] = [];
  selectedCreditNotes: UnUsedAdvancePaymentView[] = [];
  isSpecificContract2: boolean = true;
  fetchingCreditNotes: boolean;
  colsCreditNotes: any[];
  skipRecordsCount: number = 0;
  takeRecordsCount: number = 50;
  skipRecordsCount2: number = 0;
  takeRecordsCount2: number = 50;
  isProcessingAdvancedBulkSettlement: boolean;
  isProcessingCreditNoteBulkSettlement: boolean;

  constructor(
    private fb: FormBuilder,
    public accountMasterService: AccountMasterService,
    public advancePaymentService: AdvancePaymentsService,
    private breadcrumbService: BreadcrumbService,
    public confirmationService: ConfirmationService,
    public messageService: MessageService
  ) {}

  ngOnInit(): void {
    this.breadcrumbService.setItems([
      {
        label: "Finance",
      },
      {
        label: "Bulk Settlement",
        routerLink: ["/home/finance/bulk-settlement"],
      },
    ]);

    this.colsAdvances = [
      { field: "clientNo", header: "Client No" },
      { field: "client", header: "Client" },
      { field: "value", header: "Advance Payment Value" },
      { field: "balance", header: "Advance Payment Balance" },
    ];

    this.colsCreditNotes = [
      { field: "clientNo", header: "Client No" },
      { field: "client", header: "Client" },
      { field: "value", header: "Advance Payment Value" },
      { field: "balance", header: "Advance Payment Balance" },
    ];

    this.ResetMessageToasters();
    this.FetchUnUsedAdvances();
    this.FetchUnUsedCredits();
  }

  ResetMessageToasters() {
    this.msg = [];
    this.msg.push({
      severity: "info",
      summary:
        "Total of " +
        this.allPendingAdvances.length +
        " unused advance payment voucher(s)",
    });

    this.msg2 = [];
    this.msg2.push({
      severity: "info",
      summary:
        "Total of " +
        this.allPendingCreditNotes.length +
        " unused credit note voucher(s)",
    });

    this.isSpecificContract2 = true;
  }

  async FetchUnUsedAdvances() {
    this.fetchingAdvances = true;
    this.selectedPendingAdvances = [];
    this.advancePaymentService
      .GetAllUnusedAdvancePayments(this.skipRecordsCount, this.takeRecordsCount)
      .subscribe(
        async (data) => {
          if (data.responseCode != "00") {
            this.messageService.add({
              severity: "error",
              summary: "Notice",
              detail: data.responseMsg,
            });
            this.fetchingAdvances = false;
            this.ResetMessageToasters();
            return;
          }

          this.allPendingAdvances = data.responseData;
          this.allPendingAdvances.forEach((x) =>
            x.contracts.forEach(
              (y) =>
                (y.uniqueidCaption =
                  "Contract-" + y.id + " | " + (y.description ?? ""))
            )
          );
          this.fetchingAdvances = false;
          this.ResetMessageToasters();
        },
        (error) => {
          console.log("Error: " + JSON.stringify(error));
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail:
              "Unable to get unused advances at the moment.. Reason: [" +
              (error ? error.error.message : "request failed - permission") +
              "]",
          });
          this.fetchingAdvances = false;
          this.ResetMessageToasters();
        }
      );
  }

  async FetchUnUsedCredits() {
    this.fetchingCreditNotes = true;
    this.selectedCreditNotes = [];
    this.accountMasterService
      .GetAllUnusedCreditNotes(this.skipRecordsCount2, this.takeRecordsCount2)
      .subscribe(
        async (data) => {
          if (data.responseCode != "00") {
            this.messageService.add({
              severity: "error",
              summary: "Notice",
              detail: data.responseMsg,
            });
            this.fetchingCreditNotes = false;
            this.ResetMessageToasters();
            return;
          }

          this.allPendingCreditNotes = data.responseData;
          this.allPendingCreditNotes.forEach((x) =>
            x.contracts.forEach(
              (y) =>
                (y.uniqueidCaption =
                  "Contract-" + y.id + " | " + (y.description ?? ""))
            )
          );
          this.fetchingCreditNotes = false;
          this.ResetMessageToasters();
        },
        (error) => {
          console.log("Error: " + JSON.stringify(error));
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail:
              "Unable to get unused credit notes at the moment.. Reason: [" +
              (error ? error.error.message : "request failed - permission") +
              "]",
          });
          this.fetchingCreditNotes = false;
          this.ResetMessageToasters();
        }
      );
  }

  ProcessSelectedAdvances() {
    this.confirmationService.confirm({
      header: "Confirmation",
      message:
        "You are about to process bulk settlement. Do you still wish to proceed?",
      accept: () => {
        this.messageService.add({
          severity: "info",
          summary: "Notice",
          detail: "Processing selected advance payments for bulk settlement...",
        });
        this.ResetMessageToasters();

        const postData: ProcessBulkSettlementVM = {
          isSpecificContracts: this.isSpecificContract,
          details: [],
        };
        this.selectedPendingAdvances.forEach((x) =>
          postData.details.push({
            advancePaymentId: x.id,
            contractId: this.isSpecificContract
              ? x.contract
                ? x.contract.id
                : null
              : null,
          })
        );

        this.isProcessingAdvancedBulkSettlement = true;
        this.advancePaymentService.ProcessBulkSettlement(postData).subscribe(
          async (data) => {
            if (data.responseCode != "00") {
              this.messageService.add({
                severity: "error",
                summary: "Notice",
                detail: data.responseMsg,
              });
              this.ResetMessageToasters();
              return;
            }

            this.messageService.add({
              severity: "success",
              summary: "Removed",
              detail: "Advance Payment(s) bulk settlement(s) successfull",
            });
            this.isProcessingAdvancedBulkSettlement = false;
            this.ResetMessageToasters();
            this.FetchUnUsedAdvances();
          },
          (error) => {
            console.log("Error: " + JSON.stringify(error));
            this.messageService.add({
              severity: "error",
              summary: "Notice",
              detail:
                "Unable to process unused advances at the moment.. Reason: [" +
                (error ? error.error.message : "request failed - permission") +
                "]",
            });
            this.isProcessingCreditNoteBulkSettlement = false;
            this.ResetMessageToasters();
          }
        );
      },
    });
  }

  ProcessSelectedCreditNotes() {
    this.confirmationService.confirm({
      header: "Confirmation",
      message:
        "You are about to process bulk settlement. Do you still wish to proceed?",
      accept: () => {
        this.messageService.add({
          severity: "info",
          summary: "Notice",
          detail: "Processing selected credit note(s) for bulk settlement...",
        });
        this.ResetMessageToasters();

        const postData: ProcessBulkSettlementVM = {
          isSpecificContracts: this.isSpecificContract2,
          details: [],
        };
        this.selectedCreditNotes.forEach((x) =>
          postData.details.push({
            advancePaymentId: x.id,
            contractId: this.isSpecificContract2
              ? x.contract
                ? x.contract.id
                : null
              : null,
          })
        );

        this.isProcessingCreditNoteBulkSettlement = true;
        this.accountMasterService.ProcessBulkSettlement(postData).subscribe(
          async (data) => {
            if (data.responseCode != "00") {
              this.messageService.add({
                severity: "error",
                summary: "Notice",
                detail: data.responseMsg,
              });
              this.ResetMessageToasters();
              return;
            }

            this.messageService.add({
              severity: "success",
              summary: "Removed",
              detail: "Credit Note(s) bulk settlement(s) successfull",
            });
            this.isProcessingCreditNoteBulkSettlement = false;
            this.ResetMessageToasters();
            this.FetchUnUsedCredits();
          },
          (error) => {
            console.log("Error: " + JSON.stringify(error));
            this.messageService.add({
              severity: "error",
              summary: "Notice",
              detail:
                "Unable to process unused credit notes at the moment.. Reason: [" +
                (error ? error.error.message : "request failed - permission") +
                "]",
            });
            this.isProcessingCreditNoteBulkSettlement = false;
            this.ResetMessageToasters();
          }
        );
      },
    });
  }
}
