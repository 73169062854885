import { Component, OnInit } from '@angular/core';
import {DynamicDialogConfig, DynamicDialogRef} from 'primeng/dynamicdialog';
import {Permissions, RoleClaim} from '../../../interfaces/home';
import {ConfirmationService} from 'primeng/api';

@Component({
    selector: 'app-roleclaim-attributes',
    templateUrl: './roleclaim-attributes.component.html',
    styleUrls: ['./roleclaim-attributes.component.css'],
    providers: [ConfirmationService]
})

export class RoleclaimAttributesComponent implements OnInit {

    permissions: Permissions[];
    selectedPermissions: Permissions[] = [];

    constructor(public config: DynamicDialogConfig,
                public attribModalRef: DynamicDialogRef,
                public confirmationService: ConfirmationService
    ) {}

    ngOnInit(): void {
        this.permissions = this.config.data.permmisions;    
    }

    setRoleClaimAttributes() {
        this.attribModalRef.close(this.selectedPermissions);
    }
}