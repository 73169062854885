export enum AssignmentStatus {
    onTime= 1,
    behindSchedule=2,
    arrivalOverdue=3,
}

export class AssignmentStatusName {
    1 = 'On Time';
    2 = 'behind Schedule';
    3 = 'arrival Overdue';
    4 = '--';
}
