import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { MessageService, ConfirmationService, Message } from "primeng/api";
import { BreadcrumbService } from "src/app/breadcrumb.service";
import {
  Contract,
  ContractAutoRenewPolicy,
  ContractAutoRenewPolicyLog,
  CustomerDivision,
  SaveAutoRenewContractPolicyVM,
  UpdateAutoRenewContractPolicyVM,
} from "src/app/interfaces/home";
import { ContractsService } from "src/app/services/contracts.service";
import { CustomerDivisionService } from "src/app/services/customer-division.service";
import { FileStorageService } from "src/app/services/file-storage.service";

@Component({
  selector: "app-auto-renew-policy",
  templateUrl: "./auto-renew-policy.component.html",
  styleUrls: ["./auto-renew-policy.component.scss"],
  providers: [MessageService],
})
export class AutoRenewPolicyComponent implements OnInit {
  @ViewChild("formWrapper") public formWrapper: ElementRef;
  form: FormGroup;
  allClients: CustomerDivision[] = [];
  theClient: CustomerDivision;
  theClientContracts: Contract[] = [];
  theContract: Contract;
  editing: boolean;
  fetching: boolean;
  cols: any[];
  allPolicies: ContractAutoRenewPolicy[];
  selectedPolicies: ContractAutoRenewPolicy[];
  policyInView: ContractAutoRenewPolicy;
  openDialogue: boolean;
  policyLogs: ContractAutoRenewPolicyLog[] = [];
  logCols: any[];
  fetchingLogs: boolean;
  msg: Message[] = [];
  hasSuccessfulValidation: boolean;

  constructor(
    private fb: FormBuilder,
    private contractService: ContractsService,
    private clientService: CustomerDivisionService,
    public messageService: MessageService,
    private breadcrumbService: BreadcrumbService,
    public confirmationService: ConfirmationService,
    private fileStorageService: FileStorageService
  ) {
    this.form = fb.group({
      Client: ["", Validators.required],
      Contract: ["", Validators.required],
      DaysExpiration: ["", Validators.required],
    });
  }

  ngOnInit(): void {
    this.breadcrumbService.setItems([
      {
        label: "Finance",
      },
      {
        label: "Auto=Renew Policy",
        routerLink: ["/home/finance/auto-renew-policy"],
      },
    ]);

    this.cols = [
      { field: "clientName", header: "Client" },
      { field: "contractId", header: "Contract ID" },
      { field: "daysBeforeExpiration", header: "Duration Before Expiration" },
      { field: "isActive", header: "Is Active" },
    ];
    this.logCols = [
      { field: "id", header: "ID" },
      { field: "message", header: "Message" },
      { field: "status", header: "Status" },
      { field: "createdAt", header: "Date Logged" },
    ];
    this.FetchAllClients();
    this.ResetMessageToasters();
  }

  ResetMessageToasters() {
    this.msg = [];
    this.msg.push({
      severity: "info",
      summary:
        "Kindly note that the auto-renew policy will automatically renew all contract(s) for a duration of one year (1 year). Use the module to exclude a contract from auto-renewal",
      // summary: "Kindly note that the auto-renew policy will automatically renew the contract for a duration of one year (1 year)",
    });
  }

  async FetchAllClients() {
    this.fetching = true;
    this.clientService.allCustomerDivision().subscribe(
      async (data) => {
        this.allClients = data.responseData;
        this.FetchAutoRenewPolicies();
      },
      (error) => {
        this.messageService.add({
          severity: "error",
          summary: "Failed",
          detail: error ?? "Some errors occurred",
        });
        this.ResetMessageToasters();
        this.fetching = false;
      }
    );
  }

  async FetchAutoRenewPolicies() {
    this.fetching = true;
    this.contractService.GetAutoRenewContractPolicies().subscribe(
      async (data) => {
        if (data.responseCode != "00") {
          this.messageService.add({
            severity: "error",
            summary: data.responseMsg,
          });
          this.fetching = false;
          this.ResetMessageToasters();
          return;
        }

        this.allPolicies = data.responseData;
        this.allPolicies.forEach((x) => {
          x.clientName = this.GetClientName(x.contract.customerDivisionId);
        });
        this.fetching = false;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary:
            "Unable to fetch auto renew contract policies at the moment.. Reason: [" +
            error.error.message +
            "]",
        });
        this.ResetMessageToasters();
        this.fetching = false;
      }
    );
  }

  SetupAutoRenewPolicy() {
    if (!this.hasSuccessfulValidation) {
      this.messageService.add({
        severity: "error",
        summary: "Failed",
        detail:
          "You need a successful contract validation to proceed, Kindly make use of the 'Check Contract Validity' button.",
      });
      this.ResetMessageToasters();
      return;
    }

    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Saving Contract Auto-Renew Policy...",
    });
    this.ResetMessageToasters();

    const postData: SaveAutoRenewContractPolicyVM = {
      contractId: this.theContract.id,
      daysBeforeExpiration: this.form.get("DaysExpiration").value,
    };

    this.contractService.SaveAutoRenewContractPolicy(postData).subscribe(
      async (data) => {
        if (data.responseCode != "00") {
          this.messageService.add({
            severity: "error",
            summary: data.responseMsg,
          });
          this.ResetMessageToasters();
          return;
        }

        this.messageService.add({
          severity: "success",
          summary: "Completed",
          detail: "Contract Auto-Renew Policy Created Successfully!",
        });
        this.ResetMessageToasters();
        this.CloseEditing();
        this.FetchAutoRenewPolicies();
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to save auto-renew policy at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
        this.ResetMessageToasters();
      }
    );
  }

  OnClientChange() {
    this.theClientContracts = [];
    this.theContract = null;
    this.form.patchValue({ DaysExpiration: null });
    if (this.theClient) {
      this.contractService.GetClientContracts(this.theClient.id).subscribe(
        async (data) => {
          if (data.responseCode != "00") {
            this.messageService.add({
              severity: "error",
              summary: data.responseMsg,
            });
            this.ResetMessageToasters();
            return;
          }

          this.theClientContracts = data.responseData;
          this.theClientContracts.forEach((x) => {
            x.description =
              "Contract-" + x.id + " " + (x.description ? x.description : "");
          });
        },
        (error) => {
          console.log("Error: " + JSON.stringify(error));
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail:
              "Unable to get all client contract(s) at the moment.. Reason: [" +
              (error ? error.error.message : "request failed - permission") +
              "]",
          });
          this.ResetMessageToasters();
        }
      );
    }
  }

  OnContractChange() {}

  CloseEditing() {
    this.editing = false;
    this.hasSuccessfulValidation = false;
    this.theContract = null;
    this.theClientContracts = [];
    this.policyInView = null;
    this.form.reset();
  }

  UpdateAutoRenewPolicy() {
    this.confirmationService.confirm({
      header: "Confirmation Dialog",
      icon: "pi pi-exclamation-triangle",
      message:
        "You are about to update auto-renew policy. Do you still wish to proceed?",
      acceptLabel: "Yes, Please Proceed",
      rejectLabel: "No, Don't Proceed",
      accept: async () => {
        this.messageService.add({
          severity: "info",
          summary: "Notice",
          detail: "Updating Policy......",
        });
        this.ResetMessageToasters();

        const postData: UpdateAutoRenewContractPolicyVM = {
          daysBeforeExpiration: this.form.get("DaysExpiration").value,
          toggleStatus: false,
          applySoftDelete: false,
        };

        this.contractService
          .UpdateAutoRenewContractPolicy(this.policyInView.id, postData)
          .subscribe(
            async (data) => {
              if (data.responseCode != "00") {
                this.messageService.add({
                  severity: "error",
                  summary: data.responseMsg,
                });
                this.ResetMessageToasters();
                return;
              }

              this.messageService.add({
                severity: "success",
                summary: "Completed",
                detail: "Contract Auto-Renew Policy Updated Successfully!",
              });
              this.ResetMessageToasters();
              this.CloseEditing();
              this.FetchAutoRenewPolicies();
            },
            (error) => {
              console.log("Error: " + JSON.stringify(error));
              this.messageService.add({
                severity: "error",
                summary: "Notice",
                detail:
                  "Unable to update auto-renew policy at the moment.. Reason: [" +
                  (error
                    ? error.error.message
                    : "request failed - permission") +
                  "]",
              });
              this.ResetMessageToasters();
            }
          );
      },
    });
  }

  DeactivatePolicy(item: ContractAutoRenewPolicy) {
    this.confirmationService.confirm({
      header: "Confirmation Dialog",
      icon: "pi pi-exclamation-triangle",
      message:
        "You are about to toggle auto-renew policy status. Do you still wish to proceed?",
      acceptLabel: "Yes, Please Proceed",
      rejectLabel: "No, Don't Proceed",
      accept: async () => {
        this.messageService.add({
          severity: "info",
          summary: "Notice",
          detail: "Updating Policy......",
        });
        this.ResetMessageToasters();

        const postData: UpdateAutoRenewContractPolicyVM = {
          toggleStatus: true,
          applySoftDelete: false,
        };

        this.contractService
          .UpdateAutoRenewContractPolicy(item.id, postData)
          .subscribe(
            async (data) => {
              if (data.responseCode != "00") {
                this.messageService.add({
                  severity: "error",
                  summary: data.responseMsg,
                });
                this.ResetMessageToasters();
                return;
              }

              this.messageService.add({
                severity: "success",
                summary: "Completed",
                detail: "Contract Auto-Renew Policy Updated Successfully!",
              });
              this.ResetMessageToasters();
              this.CloseEditing();
              this.FetchAutoRenewPolicies();
            },
            (error) => {
              console.log("Error: " + JSON.stringify(error));
              this.messageService.add({
                severity: "error",
                summary: "Notice",
                detail:
                  "Unable to update auto-renew policy at the moment.. Reason: [" +
                  (error
                    ? error.error.message
                    : "request failed - permission") +
                  "]",
              });
              this.ResetMessageToasters();
            }
          );
      },
    });
  }

  EditPolicy(item: ContractAutoRenewPolicy) {
    this.editing = true;
    this.form.patchValue({
      DaysExpiration: item.daysBeforeExpiration,
    });
    this.theClient = this.allClients.find(
      (x) => x.id == item.contract.customerDivisionId
    );
    this.theClientContracts = [item.contract];
    this.theContract = this.theClientContracts[0];
    this.policyInView = item;

    this.formWrapper.nativeElement.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "start",
    });
  }

  DeletePolicy(item: ContractAutoRenewPolicy) {
    this.confirmationService.confirm({
      header: "Confirmation Dialog",
      icon: "pi pi-exclamation-triangle",
      message:
        "You are about to delete auto-renew policy. Do you still wish to proceed?",
      acceptLabel: "Yes, Please Proceed",
      rejectLabel: "No, Don't Proceed",
      accept: async () => {
        this.messageService.add({
          severity: "info",
          summary: "Notice",
          detail: "Removing Policy......",
        });
        this.ResetMessageToasters();

        const postData: UpdateAutoRenewContractPolicyVM = {
          toggleStatus: false,
          applySoftDelete: true,
        };

        this.contractService
          .UpdateAutoRenewContractPolicy(item.id, postData)
          .subscribe(
            async (data) => {
              if (data.responseCode != "00") {
                this.messageService.add({
                  severity: "error",
                  summary: data.responseMsg,
                });
                this.ResetMessageToasters();
                return;
              }

              this.messageService.add({
                severity: "success",
                summary: "Completed",
                detail: "Contract Auto-Renew Policy Removed Successfully!",
              });
              this.ResetMessageToasters();
              this.CloseEditing();
              this.FetchAutoRenewPolicies();
            },
            (error) => {
              console.log("Error: " + JSON.stringify(error));
              this.messageService.add({
                severity: "error",
                summary: "Notice",
                detail:
                  "Unable to remove auto-renew policy at the moment.. Reason: [" +
                  (error
                    ? error.error.message
                    : "request failed - permission") +
                  "]",
              });
              this.ResetMessageToasters();
            }
          );
      },
    });
  }

  ViewPolicyLogs(item: ContractAutoRenewPolicy) {
    this.openDialogue = true;
    this.fetchingLogs = true;
    this.policyLogs = [];
    this.contractService.GetAutoRenewContractPoliciyLogs(item.id).subscribe(
      async (data) => {
        if (data.responseCode != "00") {
          this.messageService.add({
            severity: "error",
            summary: data.responseMsg,
          });
          this.ResetMessageToasters();
          return;
        }

        this.policyLogs = data.responseData;
        this.fetchingLogs = false;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch auto-renew policy logs at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
        this.ResetMessageToasters();
      }
    );
  }

  GetClientName(clientId: number): string {
    let client = this.allClients.find((x) => x.id == clientId);
    if (client) return client.divisionName;

    return "N/A";
  }

  HideDialog() {
    this.openDialogue = false;
  }

  FormatDateString(date: Date): string {
    let dateString = date.toString();

    try {
      // DateTimeFormatOptions
      const date: Date = new Date(dateString);
      const options: Intl.DateTimeFormatOptions = {
        year: "numeric",
        month: "long",
        day: "numeric",
      };
      const formattedDate: string = new Intl.DateTimeFormat(
        "en-US",
        options
      ).format(date);
      dateString = formattedDate;
    } catch (error) {
      console.log(error);
      console.log(date);
      console.log(
        "Error while converting date string " + date + " exception " + error
      );
    }

    return dateString;
  }

  CheckContractValidity() {
    this.confirmationService.confirm({
      message:
        "This action will check the selected contract (Contract-" +
        this.theContract.id +
        ") validity. Do you still wish to proceed?",
      accept: async () => {
        this.messageService.add({
          severity: "info",
          summary: "Notice",
          detail: "Checking contract validity......",
        });
        this.ResetMessageToasters();

        this.contractService
          .CheckContractValidity(this.theContract.id)
          .subscribe(
            async (data) => {
              if (data.responseCode != "00") {
                this.messageService.add({
                  severity: "error",
                  summary: "Notice",
                  detail: data.responseMsg,
                });
                this.hasSuccessfulValidation = false;
                this.ResetMessageToasters();
                return;
              }

              this.messageService.add({
                severity: "success",
                summary: "Success",
                detail: "Contract validated successfully. [0 Issue(s) found]",
              });
              this.ResetMessageToasters();
              this.hasSuccessfulValidation = true;
            },
            (error) => {
              console.log("Error: " + JSON.stringify(error));
              this.messageService.add({
                severity: "error",
                summary: "Notice",
                detail:
                  "Unable to validate selected contract at the moment.. Reason: [" +
                  error.error.message +
                  "]",
              });
              this.ResetMessageToasters();
              this.hasSuccessfulValidation = false;
            }
          );
      },
    });
  }

  ReValidateContract() {
    this.confirmationService.confirm({
      message:
        "This action will re-validate the selected contract (Contract-" +
        this.theContract.id +
        ") and make necessary correction(s). Do you still wish to proceed?",
      accept: async () => {
        this.messageService.add({
          severity: "info",
          summary: "Notice",
          detail: "Revalidating contract......",
        });
        this.ResetMessageToasters();

        this.contractService.ReValidateContract(this.theContract.id).subscribe(
          async (data) => {
            if (data.responseCode != "00") {
              if (
                data.responseMsg ==
                "You can't revalidate a contract without any issues."
              ) {
                this.messageService.add({
                  severity: "warn",
                  summary: "Info",
                  detail:
                    data.responseMsg +
                    " Please proceed with your endorsement action(s).",
                });
                this.ResetMessageToasters();
                return;
              }

              this.messageService.add({
                severity: "error",
                summary: "Notice",
                detail: data.responseMsg,
              });
              this.ResetMessageToasters();
              return;
            }

            this.messageService.add({
              severity: "success",
              summary: "Success",
              detail:
                "Contract re-validated successfully. [All Issue(s) resolved.]",
            });
            this.ResetMessageToasters();
          },
          (error) => {
            console.log("Error: " + JSON.stringify(error));
            this.messageService.add({
              severity: "error",
              summary: "Notice",
              detail:
                "Unable to re-validate selected contract the moment.. Reason: [" +
                error.error.message +
                "]",
            });
            this.ResetMessageToasters();
          }
        );
      },
    });
  }
}
