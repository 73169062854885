import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { MessageService, ConfirmationService } from "primeng/api";
import { BreadcrumbService } from "src/app/breadcrumb.service";
import {
  AccountMaster,
  CommonResponse,
  CustomerDivision,
  ProcessCreditNoteReversalVM,
} from "src/app/interfaces/home";
import { AccountMasterService } from "src/app/services/account-master";
import { CustomerDivisionService } from "src/app/services/customer-division.service";

@Component({
  selector: "app-credit-note-reversal",
  templateUrl: "./credit-note-reversal.component.html",
  styleUrls: ["./credit-note-reversal.component.scss"],
})
export class CreditNoteReversalComponent implements OnInit {
  @ViewChild("formWrapper") public formWrapper: ElementRef;
  formDecline: FormGroup;
  allClients: CustomerDivision[] = [];
  theClient: CustomerDivision;
  fetchingCreditNotes: boolean;
  allCreditNotes: AccountMaster[];
  selectedCreditNotes: AccountMaster[] = [];
  cols: any[];
  openCautionDialogue: boolean;
  cautionAction: number;
  cautionText: string;

  constructor(
    private accountMasterService: AccountMasterService,
    private customerDivisionService: CustomerDivisionService,
    private fb: FormBuilder,
    public messageService: MessageService,
    private breadcrumbService: BreadcrumbService,
    public confirmationService: ConfirmationService
  ) {
    this.formDecline = fb.group({
      Comment: [""],
    });
  }

  ngOnInit(): void {
    this.breadcrumbService.setItems([
      {
        label: "Fiance",
        routerLink: ["/home"],
      },
      {
        label: "Credit Note Reversal",
        routerLink: ["/home/finance/credit-note-reversal"],
      },
    ]);

    this.cols = [
      { field: "createdAt", header: "Date Issued" },
      { field: "id", header: "Credit Note Id" },
      { field: "value", header: "Credit Note Value" },
      { field: "contractId", header: "Issued On Contract" },
    ];

    this.FetchAllClients();
  }

  async FetchAllClients() {
    this.customerDivisionService.allCustomerDivision().subscribe(
      async (data) => {
        if (data.responseCode != "00") {
          this.messageService.add({
            severity: "error",
            summary: data.responseMsg,
          });
          return;
        }

        this.allClients = data.responseData;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all clients the moment.. Reason: [" +
            error.error.message +
            "]",
        });
      }
    );
  }

  LoadClientCreditNotes() {
    if (this.theClient) {
      this.fetchingCreditNotes = true;
      this.accountMasterService
        .GetUnReversedCreditNotes(this.theClient.id)
        .subscribe(
          async (data: CommonResponse<AccountMaster[]>) => {
            if (data.responseCode != "00") {
              this.messageService.add({
                severity: "error",
                summary: data.responseMsg,
              });
              this.fetchingCreditNotes = false;
              return;
            }

            console.log("Credit Notes");
            console.log(data);
            this.allCreditNotes = data.responseData;
            this.fetchingCreditNotes = false;
          },
          (error) => {
            console.log("Error: " + JSON.stringify(error));
            this.messageService.add({
              severity: "error",
              summary: "Notice",
              detail:
                "Unable to fetch client credit notes the moment.. Reason: [" +
                error.error.message +
                "]",
            });
            this.fetchingCreditNotes = false;
          }
        );
    }
  }

  FormatDateString(date: Date): string {
    let dateString = date.toString();

    try {
      // DateTimeFormatOptions
      const date: Date = new Date(dateString);
      const options: Intl.DateTimeFormatOptions = {
        year: "numeric",
        month: "long",
        day: "numeric",
      };
      const formattedDate: string = new Intl.DateTimeFormat(
        "en-US",
        options
      ).format(date);
      dateString = formattedDate;
    } catch (error) {
      console.log(error);
      console.log(date);
      console.log(
        "Error while converting date string " + date + " exception " + error
      );
    }

    return dateString;
  }

  RequestCreditNoteReversal() {
    if (this.selectedCreditNotes.length > 0) {
      this.cautionAction = 1;
      this.cautionText =
        "You are about to reverse the selected credit notes (" +
        this.selectedCreditNotes.length +
        " Credit Note(s) selected). Please note all usages attached to the selected credit notes will be removed. This is an irreversible action, Do you still wish to proceed?";
      this.openCautionDialogue = true;
    }
  }

  CautionAction() {
    if (this.cautionAction == 1) {
      this.messageService.add({
        severity: "info",
        summary: "Notice",
        detail: "Processing Credit Note(s) reversal...",
      });

      const postData: ProcessCreditNoteReversalVM = {
        creditNoteIds: this.selectedCreditNotes.map((x) => x.id),
      };

      this.accountMasterService.ProcessCreditNoteReversal(postData).subscribe(
        async (data) => {
          if (data.responseCode != "00") {
            this.messageService.add({
              severity: "error",
              summary: data.responseMsg,
            });
            return;
          }

          this.messageService.add({
            severity: "success",
            summary: "Completed",
            detail: "Credit Note Reversal Processed Successfully!",
          });
          this.HideCautionDialog();
          this.LoadClientCreditNotes();
        },
        (error) => {
          console.log("Error: " + JSON.stringify(error));
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail:
              "Unable to process credit note reversals at the moment.. Reason: [" +
              (error ? error.error.message : "request failed - permission") +
              "]",
          });
        }
      );
    }
  }

  HideCautionDialog() {
    this.openCautionDialogue = false;
    this.cautionText = null;
    this.cautionAction = null;
  }
}
