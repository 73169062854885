import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { ConfirmationService, Message, MessageService } from "primeng/api";
import { BreadcrumbService } from "src/app/breadcrumb.service";
import {
  CustomerDivision,
  Contract,
  ContractRenewNotification,
  AddContractRenewNotificationVM,
  AddMultipleContractRenewNotificationVM,
} from "src/app/interfaces/home";
import { ContractsService } from "src/app/services/contracts.service";
import { CustomerDivisionService } from "src/app/services/customer-division.service";
import { FileStorageService } from "src/app/services/file-storage.service";

@Component({
  selector: "app-contract-renew-notification",
  templateUrl: "./contract-renew-notification.component.html",
  styleUrls: ["./contract-renew-notification.component.scss"],
  providers: [MessageService],
})
export class ContractRenewNotificationComponent implements OnInit {
  @ViewChild("formWrapper") public formWrapper: ElementRef;
  form: FormGroup;
  msg: Message[] = [];
  allClients: CustomerDivision[] = [];
  theClient: CustomerDivision;
  theClientContracts: Contract[] = [];
  selectedClientContracts: Contract[] = [];
  theContract: Contract;
  editing: boolean;
  fetching: boolean;
  cols: any[];
  monthsOption: { key: number; value: string }[] = [
    {
      key: 1,
      value: "1 Month",
    },
    {
      key: 2,
      value: "2 Months",
    },
    {
      key: 3,
      value: "3 Months",
    },
  ];
  selectedMonths: { key: number; value: string }[] = [];
  allContractRenewalNotifications: ContractRenewNotification[] = [];
  selectedContractRenewalNotifications: ContractRenewNotification[] = [];
  contractRenewalNotificationInView: ContractRenewNotification;

  constructor(
    private fb: FormBuilder,
    private contractService: ContractsService,
    private clientService: CustomerDivisionService,
    public messageService: MessageService,
    private breadcrumbService: BreadcrumbService,
    public confirmationService: ConfirmationService,
    private fileStorageService: FileStorageService
  ) {
    this.form = fb.group({
      Client: ["", Validators.required],
      Contract: ["", Validators.required],
      ContractView: [""],
      MonthExpiration: ["", Validators.required],
      NotificationEmails: ["", Validators.required],
    });
  }

  ngOnInit(): void {
    this.breadcrumbService.setItems([
      {
        label: "Finance",
      },
      {
        label: "Auto-Renew Policy",
        routerLink: ["/home/finance/auto-renew-policy"],
      },
    ]);

    this.cols = [
      { field: "clientName", header: "Client" },
      { field: "contractDescription", header: "Contract" },
      {
        field: "noticePeriod",
        header: "Months Before Expiration",
      },
    ];
    this.FetchAllClients();
    this.ResetMessageToasters();
  }

  ResetMessageToasters() {
    this.msg = [];
    this.msg.push({
      severity: "info",
      summary:
        "You can set-up notification(s) to be sent out before the expiration of a contract. Either a month, two months or three months before expiration.",
    });
  }

  async FetchAllClients() {
    this.fetching = true;
    this.clientService.allCustomerDivision().subscribe(
      async (data) => {
        this.allClients = data.responseData;
        this.FetchContractRenewalNotifications();
      },
      (error) => {
        this.messageService.add({
          severity: "error",
          summary: "Failed",
          detail: error ?? "Some errors occurred",
        });
        this.ResetMessageToasters();
        this.fetching = false;
      }
    );
  }

  async FetchContractRenewalNotifications() {
    this.fetching = true;
    this.contractService.GetContractRenewNotifications().subscribe(
      async (data) => {
        if (data.responseCode != "00") {
          this.messageService.add({
            severity: "error",
            summary: data.responseMsg,
          });
          this.fetching = false;
          this.ResetMessageToasters();
          return;
        }

        this.allContractRenewalNotifications = data.responseData;
        this.allContractRenewalNotifications.forEach((x) => {
          x.clientName = this.GetClientName(x.contract.customerDivisionId);
          x.contractDescription =
            "Contract-" +
            x.contractId +
            " " +
            (x.contract.description ? x.contract.description : "");
          let noticePeriodStr: string = "";
          if (x.renewalNoticePeriodInMonthsStr) {
            x.renewalNoticePeriodInMonthsStr.split(";").forEach((x) => {
              if (x) noticePeriodStr += " " + x + " Month(s) ";
            });
          }
          x.noticePeriod =
            x.renewalNoticePeriodInMonths != 0
              ? x.renewalNoticePeriodInMonths + " Month(s)"
              : noticePeriodStr;
        });
        this.fetching = false;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary:
            "Unable to fetch all contract renewal notifications at the moment.. Reason: [" +
            error.error.message +
            "]",
        });
        this.ResetMessageToasters();
        this.fetching = false;
      }
    );
  }

  OnClientChange() {
    this.theClientContracts = [];
    this.theContract = null;
    this.form.patchValue({ DaysExpiration: null });
    if (this.theClient) {
      this.contractService.GetClientContracts(this.theClient.id).subscribe(
        async (data) => {
          if (data.responseCode != "00") {
            this.messageService.add({
              severity: "error",
              summary: data.responseMsg,
            });
            this.ResetMessageToasters();
            return;
          }

          this.theClientContracts = data.responseData;
          this.theClientContracts.forEach((x) => {
            x.description =
              "Contract-" + x.id + " " + (x.description ? x.description : "");
          });
        },
        (error) => {
          console.log("Error: " + JSON.stringify(error));
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail:
              "Unable to get all client contract(s) at the moment.. Reason: [" +
              (error ? error.error.message : "request failed - permission") +
              "]",
          });
          this.ResetMessageToasters();
        }
      );
    }
  }

  GetClientName(clientId: number): string {
    let client = this.allClients.find((x) => x.id == clientId);
    if (client) return client.divisionName;

    return "N/A";
  }

  SetupRenewalNotification() {
    return this.SetupRenewalNotificationV2();
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Adding New Contract Renewal Notification...",
    });
    this.ResetMessageToasters();

    const postData: AddContractRenewNotificationVM = {
      contractId: this.theContract.id,
      selectedMonthNotice: this.selectedMonths.map((x) => x.key),
      notificationEmails: this.form.get("NotificationEmails").value,
    };

    this.contractService.AddContractRenewNotification(postData).subscribe(
      async (data) => {
        if (data.responseCode != "00") {
          this.messageService.add({
            severity: "error",
            summary: data.responseMsg,
          });
          this.ResetMessageToasters();
          return;
        }

        this.messageService.add({
          severity: "success",
          summary: "Completed",
          detail: "Contract Renewal Notification Added Successfully!",
        });
        this.ResetMessageToasters();
        this.CloseEditing();
        this.FetchContractRenewalNotifications();
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to save auto-renew policy at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
        this.ResetMessageToasters();
      }
    );
  }

  SetupRenewalNotificationV2() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Adding New Contract Renewal Notification(s)...",
    });
    this.ResetMessageToasters();

    const postData: AddMultipleContractRenewNotificationVM = {
      contractIds: this.selectedClientContracts.map((x) => x.id),
      selectedMonthNotice: this.selectedMonths.map((x) => x.key),
      notificationEmails: this.form.get("NotificationEmails").value,
    };

    this.contractService
      .AddMultipleContractRenewNotification(postData)
      .subscribe(
        async (data) => {
          if (data.responseCode != "00") {
            this.messageService.add({
              severity: "error",
              summary: data.responseMsg,
            });
            this.ResetMessageToasters();
            return;
          }

          this.messageService.add({
            severity: "success",
            summary: "Completed",
            detail: "Contract Renewal Notification(s) Added Successfully!",
          });
          this.ResetMessageToasters();
          this.CloseEditing();
          this.FetchContractRenewalNotifications();
        },
        (error) => {
          console.log("Error: " + JSON.stringify(error));
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail:
              "Unable to save auto-renew policies at the moment.. Reason: [" +
              (error ? error.error.message : "request failed - permission") +
              "]",
          });
          this.ResetMessageToasters();
        }
      );
  }

  CloseEditing() {
    this.editing = false;
    this.theContract = null;
    this.contractRenewalNotificationInView = null;
    this.form.reset();
    this.theClientContracts = [];
    this.selectedMonths = [];
    this.selectedClientContracts = [];
  }

  UpdateRenewNotification() {
    this.confirmationService.confirm({
      header: "Confirmation Dialog",
      icon: "pi pi-exclamation-triangle",
      message:
        "You are about to update contract renewal notification. Do you still wish to proceed?",
      acceptLabel: "Yes, Please Proceed",
      rejectLabel: "No, Don't Proceed",
      accept: async () => {
        this.messageService.add({
          severity: "info",
          summary: "Notice",
          detail: "Updating Notification......",
        });
        this.ResetMessageToasters();

        const postData: AddContractRenewNotificationVM = {
          contractId: this.theContract.id,
          selectedMonthNotice: this.selectedMonths.map((x) => x.key),
          notificationEmails: this.form.get("NotificationEmails").value,
        };

        this.contractService
          .UpdateContractRenewNotification(
            this.contractRenewalNotificationInView.id,
            postData
          )
          .subscribe(
            async (data) => {
              if (data.responseCode != "00") {
                this.messageService.add({
                  severity: "error",
                  summary: data.responseMsg,
                });
                this.ResetMessageToasters();
                return;
              }

              this.messageService.add({
                severity: "success",
                summary: "Completed",
                detail: "Contract Renewal Notification Updated Successfully!",
              });
              this.ResetMessageToasters();
              this.CloseEditing();
              this.FetchContractRenewalNotifications();
            },
            (error) => {
              console.log("Error: " + JSON.stringify(error));
              this.messageService.add({
                severity: "error",
                summary: "Notice",
                detail:
                  "Unable to update contract renewal notification at the moment.. Reason: [" +
                  (error
                    ? error.error.message
                    : "request failed - permission") +
                  "]",
              });
              this.ResetMessageToasters();
            }
          );
      },
    });
  }

  EditNotification(item: ContractRenewNotification) {
    this.editing = true;
    this.form.patchValue({
      NotificationEmails: item.notificationEmails,
    });
    this.theClient = this.allClients.find(
      (x) => x.id == item.contract.customerDivisionId
    );
    this.theClientContracts = [item.contract];
    this.theContract = this.theClientContracts[0];
    this.theContract.description =
      "Contract-" +
      this.theContract.id +
      " " +
      (this.theContract.description ? this.theContract.description : "");
    if (item.renewalNoticePeriodInMonths != 0) {
      this.selectedMonths = this.monthsOption.filter(
        (x) => x.key == item.renewalNoticePeriodInMonths
      );
    } else {
      if (item.renewalNoticePeriodInMonthsStr) {
        item.renewalNoticePeriodInMonthsStr.split(";").forEach((x) => {
          if (x) {
            this.selectedMonths.push(
              this.monthsOption.find((y) => y.key == +x)
            );
          }
        });
      }
    }
    this.contractRenewalNotificationInView = item;

    this.formWrapper.nativeElement.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "start",
    });
  }

  DeleteNotification(item: ContractRenewNotification) {
    this.confirmationService.confirm({
      header: "Confirmation Dialog",
      icon: "pi pi-exclamation-triangle",
      message:
        "You are about to delete contract renewal notification. Do you still wish to proceed?",
      acceptLabel: "Yes, Please Proceed",
      rejectLabel: "No, Don't Proceed",
      accept: async () => {
        this.messageService.add({
          severity: "info",
          summary: "Notice",
          detail: "Removing Notification......",
        });
        this.ResetMessageToasters();

        this.contractService.DeleteContractRenewNotification(item.id).subscribe(
          async (data) => {
            if (data.responseCode != "00") {
              this.messageService.add({
                severity: "error",
                summary: data.responseMsg,
              });
              this.ResetMessageToasters();
              return;
            }

            this.messageService.add({
              severity: "success",
              summary: "Completed",
              detail: "Notification Removed Successfully!",
            });
            this.ResetMessageToasters();
            this.CloseEditing();
            this.FetchContractRenewalNotifications();
          },
          (error) => {
            console.log("Error: " + JSON.stringify(error));
            this.messageService.add({
              severity: "error",
              summary: "Notice",
              detail:
                "Unable to remove contract renewal notification the moment.. Reason: [" +
                (error ? error.error.message : "request failed - permission") +
                "]",
            });
            this.ResetMessageToasters();
          }
        );
      },
    });
  }
}
