import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { MailRequest } from "../interfaces/mail";

@Injectable({
  providedIn: "root",
})
export class MailService {
  private baseUrl = environment.baseMailUrl;

  constructor(private http: HttpClient) {}

  SendNotification(data: MailRequest): Observable<any> {
    return this.http.post<any>(this.baseUrl + "SendNotification/", data);
  }
}
