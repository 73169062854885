import { StateService } from "./../../../services/state.service";
import { State } from "./../../../interfaces/home";
import {
  ApplicationEvaluationReq,
  ApplicationRequirement,
  ApplicationRequirementTypeEnum,
  BackgroundEvaluationReq,
  BackgroundRequirementTypeEnum,
  CognitiveEvaluationReq,
  CognitiveRequirement,
  CreateApplicationEvaluationReq,
  CreateApplicationExamination,
  CreateBackgroundEvaluationReq,
  CreateBackgroundExamination,
  CreateCognitiveEvaluationReq,
  CreateCognitiveExamination,
  CreatePhysicalEvaluationReq,
  CreatePhysicalExamination,
  CreateTrainingEvaluationReq,
  Evaluation,
  MeasurementTypeEnum,
  PhysicalEvaluationReq,
  PhysicalRequirement,
  TrainingCourse,
  TrainingEvaluationReq,
} from "./../../../interfaces/employment";
import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ConfirmationService, MessageService } from "primeng/api";
import { BreadcrumbService } from "src/app/breadcrumb.service";
import {
  CreateTrainingExamination,
  IndicatoryTypeEnum,
} from "src/app/interfaces/employment";
import { GenderEnum } from "src/app/interfaces/premployment";
import { GmaEmploymentService } from "src/app/services/gma-employment.service";

@Component({
  selector: "app-gma-settings",
  templateUrl: "./gma-settings.component.html",
  styleUrls: ["./gma-settings.component.scss"],
})
export class GmaSettingsComponent implements OnInit {
  @ViewChild("formWrapper") public formWrapper: ElementRef;
  trainingForm: FormGroup;
  physicalExamForm: FormGroup;
  applicationExamForm: FormGroup;
  cognitiveExamForm: FormGroup;
  backgroundExamForm: FormGroup;
  addTReqForm: FormGroup;
  addPhyExamReqForm: FormGroup;
  addAppExamReqForm: FormGroup;
  addCognExamReqForm: FormGroup;
  addBGExamReqForm: FormGroup;
  isCreatingTraining: boolean;
  isCreatingPhysicalExam: boolean;
  isCreatingApplicationExam: boolean;
  isCreatingCognitiveExam: boolean;
  isCreatingBackgroundExam: boolean;
  fetchingTrainingExams: boolean = true;
  fetchingPhysicalExams: boolean = true;
  fetchingApplicationExams: boolean = true;
  fetchingCognitiveExams: boolean = true;
  fetchingBackgroundExams: boolean = true;
  allTrainingExams: Evaluation[];
  allPhysicalExams: Evaluation[];
  allApplicationExams: Evaluation[];
  allCognitiveExams: Evaluation[];
  allBackgroundExams: Evaluation[];
  selectedTrainingExam: Evaluation;
  selectedPhysicalExam: Evaluation;
  selectedApplicationExam: Evaluation;
  selectedCognitiveExam: Evaluation;
  selectedBackgroundExam: Evaluation;
  trainingCols: any[];
  physicalCols: any[];
  applicationCols: any[];
  cognitiveCols: any[];
  backgroundCols: any[];
  trainReqCols: any[];
  physicalExamReqCols: any[];
  applicationExamReqCols: any[];
  cognitiveExamReqCols: any[];
  backgroundExamReqCols: any[];
  fetchingTrainingRequirements: boolean;
  fetchingPhysicalExamRequirements: boolean;
  fetchingApplicationExamRequirements: boolean;
  fetchingCognitiveExamRequirements: boolean;
  fetchingBackgroundExamRequirements: boolean;
  allTrainingReq: TrainingEvaluationReq[];
  allPhyscialExamReq: PhysicalEvaluationReq[];
  allApplicationExamReq: ApplicationEvaluationReq[];
  allCognitiveExamReq: CognitiveEvaluationReq[];
  allBackgroundExamReq: BackgroundEvaluationReq[];
  selectedTrainingReq: TrainingEvaluationReq;
  selectedPhysicalExamReq: PhysicalEvaluationReq;
  selectedApplicationExamReq: ApplicationEvaluationReq;
  selectedCognitiveExamReq: CognitiveEvaluationReq;
  selectedBackgroundExamReq: BackgroundEvaluationReq;
  allTrainingCourses: TrainingCourse[];
  allPhysicalRequirements: PhysicalRequirement[];
  allApplicationRequirements: ApplicationRequirement[];
  allCognitiveRequirements: CognitiveRequirement[];
  theTrainingCourse: TrainingCourse;
  thePhysicalRequirement: PhysicalRequirement;
  theApplicationRequirement: ApplicationRequirement;
  theCognitiveRequirement: CognitiveRequirement;
  thresholdOptions: {
    value: string;
    key: boolean;
  }[];
  theTrainigThreshold: {
    value: string;
    key: boolean;
  };
  thePhysicalThreshold: {
    value: string;
    key: boolean;
  };
  theApplicationThreshold: {
    value: string;
    key: boolean;
  };
  theCognitiveThreshold: {
    value: string;
    key: boolean;
  };
  theBackgroundThreshold: {
    value: string;
    key: boolean;
  };
  theBackgroundThresholdValue: {
    value: string;
    key: boolean;
  };
  theApplicationThresholdValue: {
    value: string;
    key: boolean;
  };
  allBackgroundRequirementTypes: {
    value: string;
    key: number;
  }[];
  theBackgroundRequirementType: {
    value: string;
    key: number;
  };
  allApplicationRequirementTypes: {
    value: string;
    key: number;
  }[];
  theApplicationRequirementType: {
    value: string;
    key: number;
  };
  allIndicators: {
    value: string;
    key: number;
  }[];
  theTrainingIndicators: {
    value: string;
    key: number;
  };
  thePhysicalIndicators: {
    value: string;
    key: number;
  };
  theApplicationIndicators: {
    value: string;
    key: number;
  };
  theCognitiveIndicators: {
    value: string;
    key: number;
  };
  allGenders: {
    value: string;
    key: number;
  }[];
  thePhysicalGender: {
    value: string;
    key: number;
  };
  showExamRequirements = false;
  showAddNewExamRequirement = false;
  trainingEvaluationInView: Evaluation;
  PhysicalEvaluationInView: Evaluation;
  applicationEvaluationInView: Evaluation;
  cognitiveEvaluationInView: Evaluation;
  backgroundEvaluationInView: Evaluation;
  showPhysicalExamRequirements = false;
  showAddNewPhysicalExamRequirement = false;
  showApplicationExamRequirements = false;
  showAddNewApplicationExamRequirement = false;
  showCognitiveExamRequirements = false;
  showBackgroundExamRequirements = false;
  showAddNewCognitiveExamRequirement = false;
  showAddNewBackgroundExamRequirement = false;
  allStates: State[];
  theCognReqState: State;

  constructor(
    private fb: FormBuilder,
    public employmentService: GmaEmploymentService,
    private breadcrumbService: BreadcrumbService,
    public confirmationService: ConfirmationService,
    public messageService: MessageService
  ) {
    this.trainingForm = fb.group({
      Caption: ["", Validators.required],
      Description: ["", Validators.required],
    });

    this.physicalExamForm = fb.group({
      Caption: ["", Validators.required],
      Description: ["", Validators.required],
    });

    this.applicationExamForm = fb.group({
      Caption: ["", Validators.required],
      Description: ["", Validators.required],
    });

    this.cognitiveExamForm = fb.group({
      Caption: ["", Validators.required],
      Description: ["", Validators.required],
    });

    this.backgroundExamForm = fb.group({
      Caption: ["", Validators.required],
      Description: ["", Validators.required],
    });

    this.addTReqForm = fb.group({
      TrainingCourse: ["", Validators.required],
      ThresholdRequired: ["", Validators.required],
      ThresholdValue: ["", Validators.required],
      Indicator: ["", Validators.required],
    });

    this.addPhyExamReqForm = fb.group({
      Gender: ["", Validators.required],
      Requirement: ["", Validators.required],
      ThresholdRequired: ["", Validators.required],
      ThresholdValue: ["", Validators.required],
      Indicator: ["", Validators.required],
    });

    this.addAppExamReqForm = fb.group({
      Requirement: ["", Validators.required],
      ThresholdRequired: ["", Validators.required],
      ThresholdValue: ["", Validators.required],
    });

    this.addCognExamReqForm = fb.group({
      State: ["", Validators.required],
      Requirement: ["", Validators.required],
      ThresholdRequired: ["", Validators.required],
      ThresholdValue: ["", Validators.required],
      Indicator: ["", Validators.required],
    });

    this.addBGExamReqForm = fb.group({
      Requirement: ["", Validators.required],
      ThresholdRequired: ["", Validators.required],
      ThresholdValue: ["", Validators.required],
    });
  }

  ngOnInit(): void {
    this.breadcrumbService.setItems([
      {
        label: "Guard Management",
        routerLink: ["/home/guard-management/item-settings"],
      },
      {
        label: "Setting",
        routerLink: ["/home/guard-management/setting"],
      },
    ]);

    this.trainingCols = [
      { field: "caption", header: "Caption" },
      { field: "description", header: "Description" },
    ];

    this.physicalCols = [
      { field: "caption", header: "Caption" },
      { field: "description", header: "Description" },
    ];

    this.applicationCols = [
      { field: "caption", header: "Caption" },
      { field: "description", header: "Description" },
    ];

    this.cognitiveCols = [
      { field: "caption", header: "Caption" },
      { field: "description", header: "Description" },
    ];

    this.backgroundCols = [
      { field: "caption", header: "Caption" },
      { field: "description", header: "Description" },
    ];

    this.trainReqCols = [
      { field: "requirement", header: "Requirement" },
      { field: "threshold", header: "Threshold" },
      { field: "thresholdValue", header: "ThresholdValue" },
    ];

    this.physicalExamReqCols = [
      { field: "requirement", header: "Requirement" },
      { field: "threshold", header: "Threshold" },
      { field: "thresholdValue", header: "ThresholdValue" },
    ];

    this.applicationExamReqCols = [
      { field: "requirementType", header: "RequirementType" },
      { field: "threshold", header: "Threshold" },
      { field: "thresholdValue", header: "ThresholdValue" },
    ];

    this.cognitiveExamReqCols = [
      { field: "requirement", header: "Requirement" },
      { field: "threshold", header: "Threshold" },
      { field: "thresholdValue", header: "ThresholdValue" },
    ];

    this.backgroundExamReqCols = [
      { field: "requirement", header: "Requirement" },
      { field: "threshold", header: "Threshold" },
      { field: "thresholdValue", header: "ThresholdValue" },
    ];

    this.thresholdOptions = [
      {
        value: "Yes",
        key: true,
      },
      {
        value: "No",
        key: false,
      },
    ];

    this.allIndicators = [
      {
        value: "GreaterThan",
        key: IndicatoryTypeEnum.GreaterThan,
      },
      {
        value: "EqualsTo",
        key: IndicatoryTypeEnum.EqualsTo,
      },
      {
        value: "LessThan",
        key: IndicatoryTypeEnum.LessThan,
      },
      {
        value: "GreaterThan Or EqualsTo",
        key: IndicatoryTypeEnum.GreaterThanOrEqualsTo,
      },
      {
        value: "LessThan Or EqualsTo",
        key: IndicatoryTypeEnum.LessThanOrEqualsTo,
      },
    ];

    this.allGenders = [
      {
        value: "Male",
        key: GenderEnum.Male,
      },
      {
        value: "Female",
        key: GenderEnum.Female,
      },
      {
        value: "Unspecified",
        key: GenderEnum.Unspecified,
      },
    ];

    this.allBackgroundRequirementTypes = [
      {
        value: "Criminal History Confirmation",
        key: BackgroundRequirementTypeEnum.CriminalHistoryConfirmation,
      },
      {
        value: "Educational Credential Confirmation",
        key: BackgroundRequirementTypeEnum.EducationalCredentialConfirmation,
      },
      {
        value: "Prev Employment Confirmation",
        key: BackgroundRequirementTypeEnum.PrevEmploymentConfirmation,
      },
      {
        value: "Professional & Personal Confirmation",
        key: BackgroundRequirementTypeEnum.ProfessionalPersonalConfirmation,
      },
    ];

    this.allApplicationRequirementTypes = [
      {
        value: "Is Diabetic",
        key: ApplicationRequirementTypeEnum.IsDiabetic,
      },
      {
        value: "Has Arthritus",
        key: ApplicationRequirementTypeEnum.HasArthritus,
      },
      {
        value: "Is Hypertensive",
        key: ApplicationRequirementTypeEnum.IsHypertensive,
      },
      {
        value: "Is Gambling",
        key: ApplicationRequirementTypeEnum.IsGambling,
      },
      {
        value: "Is Smoking",
        key: ApplicationRequirementTypeEnum.IsSmoking,
      },
      {
        value: "Is Drinking",
        key: ApplicationRequirementTypeEnum.IsDrinking,
      },
      {
        value: "Is Suffering Unknown Disease",
        key: ApplicationRequirementTypeEnum.IsSufferingUnknownDisease,
      },
      {
        value: "Is Ex-Militant",
        key: ApplicationRequirementTypeEnum.IsExMilitant,
      },
      {
        value: "Has Combat Training",
        key: ApplicationRequirementTypeEnum.HasCombatTraining,
      },
      {
        value: "Has Been Arrested",
        key: ApplicationRequirementTypeEnum.HasBeenArrested,
      },
      {
        value: "Has Been Convicted",
        key: ApplicationRequirementTypeEnum.HasBeenConvicted,
      },
      {
        value: "HasBeenAskedToResign",
        key: ApplicationRequirementTypeEnum.HasBeenAskedToResign,
      },
    ];

    this.FetchAllTrainingExaminations();
    this.FetchAllTrainingCourse();
    this.FetchAllPhysicalExamination();
    this.FetchAllPhysicalRequirements();
    this.FetchAllApplicationExamination();
    this.FetchAllApplicationRequirements();
    this.FetchAllCognitiveExamination();
    this.FetchAllCognitiveRequirements();
    this.FetchAllBackgroundExamination();
    this.FetchAllStates();
  }

  GetBackgroundRequirementTypeLabel(type: number): string {
    if (type == BackgroundRequirementTypeEnum.CriminalHistoryConfirmation)
      return "Criminal History Confirmation";
    else if (
      type == BackgroundRequirementTypeEnum.EducationalCredentialConfirmation
    )
      return "Educational Credential Confirmation";
    else if (type == BackgroundRequirementTypeEnum.PrevEmploymentConfirmation)
      return "PrevEmployment Confirmation";
    else if (
      type == BackgroundRequirementTypeEnum.ProfessionalPersonalConfirmation
    )
      return "Professional & Personal Confirmation";
    else return "Invalid";
  }

  GetApplicationRequirementTypeLabel(type: number): string {
    if (type == ApplicationRequirementTypeEnum.IsDiabetic) return "Is Diabetic";
    else if (type == ApplicationRequirementTypeEnum.HasArthritus)
      return "Has Arthritus";
    else if (type == ApplicationRequirementTypeEnum.IsHypertensive)
      return "Is Hypertensive";
    else if (type == ApplicationRequirementTypeEnum.IsGambling)
      return "Is Gambling";
    else if (type == ApplicationRequirementTypeEnum.IsSmoking)
      return "Is Smoking";
    else if (type == ApplicationRequirementTypeEnum.IsDrinking)
      return "Is Drinking";
    else if (type == ApplicationRequirementTypeEnum.IsSufferingUnknownDisease)
      return "Is Suffering Unknown Disease";
    else if (type == ApplicationRequirementTypeEnum.IsExMilitant)
      return "Is Ex-Militant";
    else if (type == ApplicationRequirementTypeEnum.HasCombatTraining)
      return "Has Combat Training";
    else if (type == ApplicationRequirementTypeEnum.HasBeenArrested)
      return "Has Been Arrested";
    else if (type == ApplicationRequirementTypeEnum.HasBeenConvicted)
      return "Has Been Convicted";
    else if (type == ApplicationRequirementTypeEnum.HasBeenAskedToResign)
      return "Has BeenAsked To Resign";
    else return "Invalid";
  }

  GetMeasurementLabel(measurement: number): string {
    if (measurement == MeasurementTypeEnum.Height) return "GreaterThan";
    else if (measurement == MeasurementTypeEnum.Weight) return "EqualsTo";
    else if (measurement == MeasurementTypeEnum.Body_Mass) return "LessThan";
    else if (measurement == MeasurementTypeEnum.Grade) return "Grade";
    else if (measurement == MeasurementTypeEnum.Verbal) return "Verbal";
    else if (measurement == MeasurementTypeEnum.Quantitative)
      return "Quantitative";
    else if (measurement == MeasurementTypeEnum.Communication)
      return "Communication";
    else return "Invalid";
  }

  GetIndicatorLabel(indicator: number): string {
    if (indicator == IndicatoryTypeEnum.GreaterThan) return "GreaterThan";
    else if (indicator == IndicatoryTypeEnum.EqualsTo) return "EqualsTo";
    else if (indicator == IndicatoryTypeEnum.LessThan) return "LessThan";
    else if (indicator == IndicatoryTypeEnum.GreaterThanOrEqualsTo)
      return "GreaterThan Or EqualsTo";
    else if (indicator == IndicatoryTypeEnum.LessThanOrEqualsTo)
      return "LessThan Or EqualsTo";
    else return "Invalid";
  }

  GetGenderLable(gender: number): string {
    if (gender == GenderEnum.Male) return "Male";
    if (gender == GenderEnum.Female) return "Female";
    if (gender == GenderEnum.Unspecified) return "Unspecified";
    return "Unknown";
  }

  FetchAllStates() {
    this.employmentService.GetAllStates().subscribe(
      async (data) => {
        this.allStates = data;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all states at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  FetchAllTrainingCourse() {
    this.employmentService.GetAllTrainingCourses().subscribe(
      async (data) => {
        this.allTrainingCourses = data;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all training courses at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  FetchAllPhysicalRequirements() {
    this.employmentService.GetAllPhysicalRequirements().subscribe(
      async (data) => {
        this.allPhysicalRequirements = data;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all physical requirements at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  FetchAllApplicationRequirements() {
    this.employmentService.GetAllApplicationRequirements().subscribe(
      async (data) => {
        this.allApplicationRequirements = data;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all application requirements at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  FetchAllCognitiveRequirements() {
    this.employmentService.GetAllCognitiveRequirements().subscribe(
      async (data) => {
        this.allCognitiveRequirements = data;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all cognitive requirements at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  FetchAllTrainingExaminations() {
    this.employmentService.GetAllTrainingExamination().subscribe(
      async (data) => {
        this.allTrainingExams = data;
        this.fetchingTrainingExams = false;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all training examinations at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
        this.fetchingTrainingExams = false;
      }
    );
  }

  FetchAllPhysicalExamination() {
    this.employmentService.GetAllPhysicalExaminations().subscribe(
      async (data) => {
        this.allPhysicalExams = data;
        this.fetchingPhysicalExams = false;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all physical examinations at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
        this.fetchingPhysicalExams = false;
      }
    );
  }

  FetchAllApplicationExamination() {
    this.employmentService.GetAllApplicationExaminations().subscribe(
      async (data) => {
        this.allApplicationExams = data;
        this.fetchingApplicationExams = false;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all application examinations at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
        this.fetchingApplicationExams = false;
      }
    );
  }

  FetchAllCognitiveExamination() {
    this.employmentService.GetAllCognitiveExaminations().subscribe(
      async (data) => {
        this.allCognitiveExams = data;
        this.fetchingCognitiveExams = false;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all cognitive examinations at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
        this.fetchingCognitiveExams = false;
      }
    );
  }

  FetchAllBackgroundExamination() {
    this.employmentService.GetAllBackgroundExaminations().subscribe(
      async (data) => {
        this.allBackgroundExams = data;
        this.fetchingBackgroundExams = false;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all background examinations at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
        this.fetchingBackgroundExams = false;
      }
    );
  }

  FetchAllTrainingEvaluationReqs(evaluationId: number) {
    this.employmentService
      .GetAllTrainingExaminationRequirements(evaluationId)
      .subscribe(
        async (data) => {
          this.allTrainingReq = data.filter((x) => x.isDeleted == false);
        },
        (error) => {
          console.log("Error: " + JSON.stringify(error));
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail:
              "Unable to fetch all training examination requirements at the moment.. Reason: [" +
              error
                ? error.error.message
                : "request failed - permission" + "]",
          });
        }
      );
  }

  FetchAllPhysicalEvaluationReqs(evaluationId: number) {
    this.employmentService
      .GetAllPhysicalExaminationRequirements(evaluationId)
      .subscribe(
        async (data) => {
          this.allPhyscialExamReq = data.filter((x) => x.isDeleted == false);
        },
        (error) => {
          console.log("Error: " + JSON.stringify(error));
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail:
              "Unable to fetch all physical examination requirements at the moment.. Reason: [" +
              error
                ? error.error.message
                : "request failed - permission" + "]",
          });
        }
      );
  }

  FetchAllApplicationEvaluationReqs(evaluationId: number) {
    this.employmentService
      .GetAllApplicationExaminationRequirements(evaluationId)
      .subscribe(
        async (data) => {
          this.allApplicationExamReq = data.filter((x) => x.isDeleted == false);
        },
        (error) => {
          console.log("Error: " + JSON.stringify(error));
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail:
              "Unable to fetch all application examination requirements at the moment.. Reason: [" +
              error
                ? error.error.message
                : "request failed - permission" + "]",
          });
        }
      );
  }

  FetchAllCognitiveEvaluationReqs(evaluationId: number) {
    this.employmentService
      .GetAllCognitiveExaminationRequirements(evaluationId)
      .subscribe(
        async (data) => {
          this.allCognitiveExamReq = data.filter((x) => x.isDeleted == false);
        },
        (error) => {
          console.log("Error: " + JSON.stringify(error));
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail:
              "Unable to fetch all cognitive examination requirements at the moment.. Reason: [" +
              error
                ? error.error.message
                : "request failed - permission" + "]",
          });
        }
      );
  }

  FetchAllBackgroundEvaluationReqs(evaluationId: number) {
    this.employmentService
      .GetAllBackgroundExaminationRequirements(evaluationId)
      .subscribe(
        async (data) => {
          this.allBackgroundExamReq = data.filter((x) => x.isDeleted == false);
        },
        (error) => {
          console.log("Error: " + JSON.stringify(error));
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail:
              "Unable to fetch all background examination requirements at the moment.. Reason: [" +
              error
                ? error.error.message
                : "request failed - permission" + "]",
          });
        }
      );
  }

  CreateTraining() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Creating Trainig Examination...",
    });

    this.isCreatingTraining = true;
    const postData: CreateTrainingExamination = {
      caption: this.trainingForm.get("Caption").value,
      description: this.trainingForm.get("Description").value,
    };

    this.employmentService.CreateTrainingExamination(postData).subscribe(
      async () => {
        await this.messageService.add({
          severity: "success",
          summary: "Completed",
          detail: "Training Examination Created Successfully!",
        });

        this.trainingForm.reset();
        this.FetchAllTrainingExaminations();
        this.isCreatingTraining = false;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to create training examination at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
        this.isCreatingTraining = false;
      }
    );
  }

  ViewTrainingRequirements(evaluation: Evaluation) {
    this.showExamRequirements = true;
    this.fetchingTrainingRequirements = true;
    this.allTrainingReq = evaluation.trainingRequirements.filter(
      (x) => x.isDeleted == false
    );
    this.fetchingTrainingRequirements = false;
    this.trainingEvaluationInView = evaluation;
  }

  ShowAddTrainingRequirement() {
    this.showAddNewExamRequirement = true;
  }

  AddTrainingRequirement() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Adding Trainig Examination Requirement...",
    });

    const postData: CreateTrainingEvaluationReq = {
      courseId: this.theTrainingCourse.id,
      thresholdRequired: this.theTrainigThreshold.key,
      thresholdValue: this.addTReqForm.get("ThresholdValue").value,
      indicator: this.theTrainingIndicators.key,
      evaluationId: this.trainingEvaluationInView.id,
    };

    this.employmentService
      .AddTrainingExaminationRequirement(postData)
      .subscribe(
        async () => {
          await this.messageService.add({
            severity: "success",
            summary: "Completed",
            detail: "Training Examination Requirement Added Successfully!",
          });

          this.addTReqForm.reset();
          this.theTrainingCourse = null;
          this.theTrainigThreshold = null;
          this.theTrainingIndicators = null;
          this.showAddNewExamRequirement = false;
          this.FetchAllTrainingEvaluationReqs(this.trainingEvaluationInView.id);
        },
        (error) => {
          console.log("Error: " + JSON.stringify(error));
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail:
              "Unable to add training examination requirement at the moment.. Reason: [" +
              error
                ? error.error.message
                : "request failed - permission" + "]",
          });
        }
      );
  }

  DeleteTrainingRequirement(item: TrainingEvaluationReq) {
    this.confirmationService.confirm({
      message: "Are you sure you want to remove requirement?",
      accept: () => {
        this.messageService.add({
          severity: "info",
          summary: "Notice",
          detail: "Removing requirement...",
        });

        this.employmentService
          .DeleteTrainingExaminationRequirement(item.id)
          .subscribe(
            async () => {
              await this.messageService.add({
                severity: "success",
                summary: "Removed",
                detail: "Removed successfully",
              });

              this.FetchAllTrainingEvaluationReqs(
                this.trainingEvaluationInView.id
              );
            },
            (error) => {
              console.log("Error: " + JSON.stringify(error));
              this.messageService.add({
                severity: "error",
                summary: "Notice",
                detail:
                  "Unable to remove requirement at the moment.. Reason: [" +
                  error
                    ? error.error.message
                    : "request failed - permission" + "]",
              });
            }
          );
      },
    });
  }

  CreatePhysicalExamination() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Creating Physical Examination...",
    });

    this.isCreatingPhysicalExam = true;
    const postData: CreatePhysicalExamination = {
      caption: this.physicalExamForm.get("Caption").value,
      description: this.physicalExamForm.get("Description").value,
    };

    this.employmentService.CreatePhysicalExamination(postData).subscribe(
      async () => {
        await this.messageService.add({
          severity: "success",
          summary: "Completed",
          detail: "Physical Examination Created Successfully!",
        });

        this.physicalExamForm.reset();
        this.FetchAllPhysicalExamination();
        this.isCreatingPhysicalExam = false;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to create physical examination at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
        this.isCreatingPhysicalExam = false;
      }
    );
  }

  ViewPhysicalRequirements(evaluation: Evaluation) {
    this.showPhysicalExamRequirements = true;
    this.fetchingPhysicalExamRequirements = true;
    this.allPhyscialExamReq = evaluation.physicalRequirements.filter(
      (x) => x.isDeleted == false
    );
    this.fetchingPhysicalExamRequirements = false;
    this.PhysicalEvaluationInView = evaluation;
  }

  ShowAddPhysicalRequirement() {
    this.showAddNewPhysicalExamRequirement = true;
  }

  AddPhysicalExamRequirement() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Adding Physical Examination Requirement...",
    });

    const postData: CreatePhysicalEvaluationReq = {
      gender: this.thePhysicalGender.key,
      requirementId: this.thePhysicalRequirement.id,
      thresholdRequired: this.thePhysicalThreshold.key,
      thresholdValue: this.addPhyExamReqForm.get("ThresholdValue").value,
      indicator: this.thePhysicalIndicators.key,
      evaluationId: this.PhysicalEvaluationInView.id,
    };

    this.employmentService
      .AddPhysicalExaminationRequirement(postData)
      .subscribe(
        async () => {
          await this.messageService.add({
            severity: "success",
            summary: "Completed",
            detail: "Physical Examination Requirement Added Successfully!",
          });

          this.addPhyExamReqForm.reset();
          this.thePhysicalRequirement = null;
          this.thePhysicalGender = null;
          this.thePhysicalThreshold = null;
          this.thePhysicalIndicators = null;
          this.showAddNewPhysicalExamRequirement = false;
          this.FetchAllPhysicalEvaluationReqs(this.PhysicalEvaluationInView.id);
        },
        (error) => {
          console.log("Error: " + JSON.stringify(error));
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail:
              "Unable to add physical examination requirement at the moment.. Reason: [" +
              error
                ? error.error.message
                : "request failed - permission" + "]",
          });
        }
      );
  }

  DeletePhysicalExamRequirement(item: PhysicalEvaluationReq) {
    this.confirmationService.confirm({
      message: "Are you sure you want to remove requirement?",
      accept: () => {
        this.messageService.add({
          severity: "info",
          summary: "Notice",
          detail: "Removing requirement...",
        });

        this.employmentService
          .DeletePhysicalExaminationRequirement(item.id)
          .subscribe(
            async () => {
              await this.messageService.add({
                severity: "success",
                summary: "Removed",
                detail: "Removed successfully",
              });

              this.FetchAllPhysicalEvaluationReqs(
                this.PhysicalEvaluationInView.id
              );
            },
            (error) => {
              console.log("Error: " + JSON.stringify(error));
              this.messageService.add({
                severity: "error",
                summary: "Notice",
                detail:
                  "Unable to remove requirement at the moment.. Reason: [" +
                  error
                    ? error.error.message
                    : "request failed - permission" + "]",
              });
            }
          );
      },
    });
  }

  CreateApplicationExamination() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Creating Application Examination...",
    });

    this.isCreatingApplicationExam = true;
    const postData: CreateApplicationExamination = {
      caption: this.applicationExamForm.get("Caption").value,
      description: this.applicationExamForm.get("Description").value,
    };

    this.employmentService.CreateApplicationExamination(postData).subscribe(
      async () => {
        await this.messageService.add({
          severity: "success",
          summary: "Completed",
          detail: "Application Examination Created Successfully!",
        });

        this.applicationExamForm.reset();
        this.FetchAllApplicationExamination();
        this.isCreatingApplicationExam = false;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to create application examination at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
        this.isCreatingApplicationExam = false;
      }
    );
  }

  ViewApplicationRequirements(evaluation: Evaluation) {
    this.showApplicationExamRequirements = true;
    this.fetchingApplicationExamRequirements = true;
    this.allApplicationExamReq = evaluation.applicationRequirements.filter(
      (x) => x.isDeleted == false
    );
    this.fetchingApplicationExamRequirements = false;
    this.applicationEvaluationInView = evaluation;
  }

  ShowAddApplicationRequirement() {
    this.showAddNewApplicationExamRequirement = true;
  }

  DeleteApplicationExamRequirement(item: ApplicationEvaluationReq) {
    this.confirmationService.confirm({
      message: "Are you sure you want to remove requirement?",
      accept: () => {
        this.messageService.add({
          severity: "info",
          summary: "Notice",
          detail: "Removing requirement...",
        });

        this.employmentService
          .DeleteApplicationExaminationRequirement(item.id)
          .subscribe(
            async () => {
              await this.messageService.add({
                severity: "success",
                summary: "Removed",
                detail: "Removed successfully",
              });

              this.FetchAllApplicationEvaluationReqs(
                this.applicationEvaluationInView.id
              );
            },
            (error) => {
              console.log("Error: " + JSON.stringify(error));
              this.messageService.add({
                severity: "error",
                summary: "Notice",
                detail:
                  "Unable to remove requirement at the moment.. Reason: [" +
                  error
                    ? error.error.message
                    : "request failed - permission" + "]",
              });
            }
          );
      },
    });
  }

  AddApplicationExamRequirement() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Adding Application Examination Requirement...",
    });

    const postData: CreateApplicationEvaluationReq = {
      requirementType: this.theApplicationRequirementType.key,
      thresholdRequired: this.theApplicationThreshold.key,
      thresholdValue: this.theApplicationThresholdValue.key,
      evaluationId: this.applicationEvaluationInView.id,
    };

    this.employmentService
      .AddApplicationExaminationRequirement(postData)
      .subscribe(
        async () => {
          await this.messageService.add({
            severity: "success",
            summary: "Completed",
            detail: "Application Examination Requirement Added Successfully!",
          });

          this.addAppExamReqForm.reset();
          this.theApplicationRequirement = null;
          this.theApplicationThreshold = null;
          this.theApplicationIndicators = null;
          this.theApplicationThresholdValue = null;
          this.showAddNewApplicationExamRequirement = false;
          this.FetchAllApplicationEvaluationReqs(
            this.applicationEvaluationInView.id
          );
        },
        (error) => {
          console.log("Error: " + JSON.stringify(error));
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail:
              "Unable to add application examination requirement at the moment.. Reason: [" +
              error
                ? error.error.message
                : "request failed - permission" + "]",
          });
        }
      );
  }

  CreateCognitiveExamination() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Creating Cognitive Examination...",
    });

    this.isCreatingCognitiveExam = true;
    const postData: CreateCognitiveExamination = {
      caption: this.cognitiveExamForm.get("Caption").value,
      description: this.cognitiveExamForm.get("Description").value,
    };

    this.employmentService.CreateCognitiveExamination(postData).subscribe(
      async () => {
        await this.messageService.add({
          severity: "success",
          summary: "Completed",
          detail: "Cognitive Examination Created Successfully!",
        });

        this.cognitiveExamForm.reset();
        this.FetchAllCognitiveExamination();
        this.isCreatingCognitiveExam = false;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to create cognitive examination at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
        this.isCreatingCognitiveExam = false;
      }
    );
  }

  CreateBackgroundExamination() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Creating Background Examination...",
    });

    this.isCreatingBackgroundExam = true;
    const postData: CreateBackgroundExamination = {
      caption: this.backgroundExamForm.get("Caption").value,
      description: this.backgroundExamForm.get("Description").value,
    };

    this.employmentService.CreateBackgroundExamination(postData).subscribe(
      async () => {
        await this.messageService.add({
          severity: "success",
          summary: "Completed",
          detail: "Background Examination Created Successfully!",
        });

        this.backgroundExamForm.reset();
        this.FetchAllBackgroundExamination();
        this.isCreatingBackgroundExam = false;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to create Background examination at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
        this.isCreatingBackgroundExam = false;
      }
    );
  }

  ViewCognitiveRequirements(evaluation: Evaluation) {
    this.showCognitiveExamRequirements = true;
    this.fetchingCognitiveExamRequirements = true;
    this.allCognitiveExamReq = evaluation.cognitiveRequirements.filter(
      (x) => x.isDeleted == false
    );
    this.fetchingCognitiveExamRequirements = false;
    this.cognitiveEvaluationInView = evaluation;
  }

  ViewBackgroundRequirements(evaluation: Evaluation) {
    this.showBackgroundExamRequirements = true;
    this.fetchingBackgroundExamRequirements = true;
    this.allBackgroundExamReq = evaluation.backgroundRequirements.filter(
      (x) => x.isDeleted == false
    );
    this.fetchingBackgroundExamRequirements = false;
    this.backgroundEvaluationInView = evaluation;
  }

  ShowAddCognitiveRequirement() {
    this.showAddNewCognitiveExamRequirement = true;
  }

  ShowAddBackgroundRequirement() {
    this.showAddNewBackgroundExamRequirement = true;
  }

  DeleteCognitiveExamRequirement(item: CognitiveEvaluationReq) {
    this.confirmationService.confirm({
      message: "Are you sure you want to remove requirement?",
      accept: () => {
        this.messageService.add({
          severity: "info",
          summary: "Notice",
          detail: "Removing requirement...",
        });

        this.employmentService
          .DeleteCognitiveExaminationRequirement(item.id)
          .subscribe(
            async () => {
              await this.messageService.add({
                severity: "success",
                summary: "Removed",
                detail: "Removed successfully",
              });

              this.FetchAllCognitiveEvaluationReqs(
                this.cognitiveEvaluationInView.id
              );
            },
            (error) => {
              console.log("Error: " + JSON.stringify(error));
              this.messageService.add({
                severity: "error",
                summary: "Notice",
                detail:
                  "Unable to remove requirement at the moment.. Reason: [" +
                  error
                    ? error.error.message
                    : "request failed - permission" + "]",
              });
            }
          );
      },
    });
  }

  DeleteBackgroundExamRequirement(item: BackgroundEvaluationReq) {
    this.confirmationService.confirm({
      message: "Are you sure you want to remove requirement?",
      accept: () => {
        this.messageService.add({
          severity: "info",
          summary: "Notice",
          detail: "Removing requirement...",
        });

        this.employmentService
          .DeleteBackgroundExaminationRequirement(item.id)
          .subscribe(
            async () => {
              await this.messageService.add({
                severity: "success",
                summary: "Removed",
                detail: "Removed successfully",
              });

              this.FetchAllBackgroundEvaluationReqs(
                this.backgroundEvaluationInView.id
              );
            },
            (error) => {
              console.log("Error: " + JSON.stringify(error));
              this.messageService.add({
                severity: "error",
                summary: "Notice",
                detail:
                  "Unable to remove requirement at the moment.. Reason: [" +
                  error
                    ? error.error.message
                    : "request failed - permission" + "]",
              });
            }
          );
      },
    });
  }

  AddCognitiveExamRequirement() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Adding Cognitive Examination Requirement...",
    });

    const postData: CreateCognitiveEvaluationReq = {
      stateId: this.theCognReqState.id,
      requirementId: this.theCognitiveRequirement.id,
      thresholdRequired: this.theCognitiveThreshold.key,
      thresholdValue: this.addCognExamReqForm.get("ThresholdValue").value,
      indicator: this.theCognitiveIndicators.key,
      evaluationId: this.cognitiveEvaluationInView.id,
    };

    this.employmentService
      .AddCognitiveExaminationRequirement(postData)
      .subscribe(
        async () => {
          await this.messageService.add({
            severity: "success",
            summary: "Completed",
            detail: "Cognitive Examination Requirement Added Successfully!",
          });

          this.addCognExamReqForm.reset();
          this.theCognitiveRequirement = null;
          this.theCognitiveThreshold = null;
          this.theCognitiveIndicators = null;
          this.showAddNewCognitiveExamRequirement = false;
          this.FetchAllCognitiveEvaluationReqs(
            this.cognitiveEvaluationInView.id
          );
        },
        (error) => {
          console.log("Error: " + JSON.stringify(error));
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail:
              "Unable to add cognitive examination requirement at the moment.. Reason: [" +
              error
                ? error.error.message
                : "request failed - permission" + "]",
          });
        }
      );
  }

  AddBackgroundExamRequirement() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Adding Background Examination Requirement...",
    });

    const postData: CreateBackgroundEvaluationReq = {
      thresholdRequired: this.theBackgroundThreshold.key,
      thresholdValue: this.theBackgroundThresholdValue.key,
      evaluationId: this.backgroundEvaluationInView.id,
      requirementType: this.theBackgroundRequirementType.key,
    };

    this.employmentService
      .AddBackgroundExaminationRequirement(postData)
      .subscribe(
        async () => {
          await this.messageService.add({
            severity: "success",
            summary: "Completed",
            detail: "Background Examination Requirement Added Successfully!",
          });

          this.addBGExamReqForm.reset();
          this.theBackgroundThreshold = null;
          this.showAddNewBackgroundExamRequirement = false;
          this.theBackgroundRequirementType = null;
          this.FetchAllBackgroundEvaluationReqs(
            this.backgroundEvaluationInView.id
          );
        },
        (error) => {
          console.log("Error: " + JSON.stringify(error));
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail:
              "Unable to add background examination requirement at the moment.. Reason: [" +
              error
                ? error.error.message
                : "request failed - permission" + "]",
          });
        }
      );
  }
}
