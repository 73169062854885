import { Observable } from "rxjs";
import { CommonResponse } from "./../interfaces/home";
import { environment } from "src/environments/environment";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: 'root'
})
export class SuspectQualificationService {

  constructor(private http: HttpClient) { }

  baseUrl = environment.baseUrl+'SuspectQualification/';

  // fetch all SuspectQualification in the database
  allSuspectQualification(): Observable<CommonResponse>{
    return this.http.get<CommonResponse>(this.baseUrl);
  }
  allUserSuspectQualifications(): Observable<CommonResponse>{
    return this.http.get<CommonResponse>(this.baseUrl + 'GetUserSuspectQualification');
  }
  getSuspectQualification(id: any):Observable<CommonResponse>{
    return this.http.get<CommonResponse>(this.baseUrl +id);
  }
  postSuspectQualification(data: any){
    return this.http.post(this.baseUrl, data);
  }
  deleteSuspectQualification(id: any){
    return this.http.delete(this.baseUrl +id);
  }
  updateSuspectQualification(id: any, data: any){
    return this.http.put(this.baseUrl +id, data);
  }
}
