import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { ConfirmationService, MessageService } from "primeng/api";
import { BreadcrumbService } from "src/app/breadcrumb.service";
import { CommonResponse, CustomerDivision } from "src/app/interfaces/home";
import {
  GetClientsReceivableReportReceivingDTO,
  GetClientsReceivableReportTransferDTO,
} from "src/app/interfaces/reporting";
import { CustomerDivisionService } from "src/app/services/customer-division.service";
import { ReportingService } from "src/app/services/reporting.service";

@Component({
  selector: "app-receivable-report",
  templateUrl: "./receivable-report.component.html",
  styleUrls: ["./receivable-report.component.scss"],
})
export class ReceivableReportComponent implements OnInit {
  @ViewChild("formWrapper") public formWrapper: ElementRef;
  allClients: CustomerDivision[];
  selectedClients: CustomerDivision[] = [];
  fetchingReports: boolean;
  showReport: boolean;
  allReports: GetClientsReceivableReportTransferDTO[];
  selectedReports: GetClientsReceivableReportTransferDTO[];
  reportCols: any[];
  asAtDate: Date;

  constructor(
    private reportingService: ReportingService,
    private customerDivisionService: CustomerDivisionService,
    private breadcrumbService: BreadcrumbService,
    public confirmationService: ConfirmationService,
    public messageService: MessageService
  ) {}

  ngOnInit(): void {
    this.breadcrumbService.setItems([
      {
        label: "Report",
      },
      {
        label: "Receivables",
        routerLink: ["/home/report/receivable"],
      },
    ]);

    this.reportCols = [
      { field: "clientName", header: "Client Name" },
      { field: "contract", header: "Contract" },
      { field: "invoiceDue", header: "Inoice Due" },
      { field: "invoiceValue", header: "Inoice Value" },
      { field: "totalReceiptValue", header: "Total Receipt Value" },
      { field: "range1", header: "0 - 30 days" },
      { field: "range2", header: "31 - 60 days" },
      { field: "range3", header: "61 - 90 days" },
      { field: "range4", header: "91 - 180 days" },
      { field: "range5", header: "181 - 360 days" },
      { field: "range6", header: "More than a year" },
    ];

    this.FetchAllCustomerDivisions();
  }

  FetchAllCustomerDivisions() {
    this.customerDivisionService.allCustomerDivision().subscribe(
      async (data: CommonResponse<CustomerDivision[]>) => {
        if (data.responseCode == "00") {
          this.allClients = data.responseData;
        } else {
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail: data.responseMsg,
          });
        }
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all customer divisions at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  RunReport() {
    this.fetchingReports = true;
    this.allReports = [];
    this.selectedReports = [];

    const postData: GetClientsReceivableReportReceivingDTO = {
      clientIds: this.selectedClients.map((x) => x.id),
      viewInvoicesDueAsAt: this.asAtDate,
    };

    this.reportingService.GetClientsReceivableReport(postData).subscribe(
      async (data) => {
        this.fetchingReports = false;
        if (data.responseCode != "00") {
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail: data.responseMsg,
          });
          return;
        }

        console.log(data);
        this.allReports = data.responseData;
        this.showReport = true;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to get receivables report at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
        this.fetchingReports = false;
      }
    );
  }

  GetContractRowSpan(contractId: number): number {
    let rowSpan = this.allReports.filter(
      (x) => x.contractId == contractId
    ).length;
    console.log("Contract ID: " + contractId + " - RowSpan: " + rowSpan);
    return rowSpan;
  }

  GetClientRowSpan(clientId: number): number {
    let rowSpan = this.allReports.filter((x) => x.clientId == clientId).length;
    console.log("Client ID: " + clientId + " - RowSpan: " + rowSpan);
    return rowSpan;
  }
}
