import { RespTeamReststationMapComponent } from "./response/resp-team-reststation-map/resp-team-reststation-map.component";
import { RestingStationLgaMapComponent } from "./response/resting-station-lga-map/resting-station-lga-map.component";
import { RestingStationsSetupComponent } from "./response/resting-stations-setup/resting-stations-setup.component";
import { ResponseTeamsComponent } from "./response/response-teams/response-teams.component";
import { ResponseTeamTypeComponent } from "./response/response-team-type/response-team-type.component";
import { ResponseTeamType } from "src/app/interfaces/response";
import { HalogenUsersComponent } from "./profile/halogen-users/halogen-users.component";
import { ClientInformationUpdateComponent } from "./client-management/client-information-update/client-information-update.component";
import { CurrencySetupComponent } from "./setups/currency-setup/currency-setup.component";
import { IncidentClientNotificationComponent } from "./convergence/incident-management/incident-client-notification/incident-client-notification.component";
import { IncidentNotificationPolicyComponent } from "./convergence/incident-management/incident-notification-policy/incident-notification-policy.component";
import { IncidentNotificationTemplateComponent } from "./convergence/incident-management/incident-notification-template/incident-notification-template.component";
import { PbmMapServiceBenefitComponent } from "./platform-benefits-manager/pbm-map-service-benefit/pbm-map-service-benefit.component";
import { PbmMapPlatformServiceComponent } from "./platform-benefits-manager/pbm-map-platform-service/pbm-map-platform-service.component";
import { PbmCreateBenefitComponent } from "./platform-benefits-manager/pbm-create-benefit/pbm-create-benefit.component";
import { PbmCreatePlatformComponent } from "./platform-benefits-manager/pbm-create-platform/pbm-create-platform.component";
import { DeviceConvergenceDashboardComponent } from "./convergence/device-management/device-convergence-dashboard/device-convergence-dashboard.component";
import { DeviceConvergenceConnectivityComponent } from "./convergence/device-management/device-convergence-connectivity/device-convergence-connectivity.component";
import { DeviceConvergenceRegistrationComponent } from "./convergence/device-management/device-convergence-registration/device-convergence-registration.component";
import { DeviceConvergenceSetupComponent } from "./convergence/device-management/device-convergence-setup/device-convergence-setup.component";
import { IncidenceConvergenceDashboardComponent } from "./convergence/incident-management/incidence-convergence-dashboard/incidence-convergence-dashboard.component";
import { IncidenceConvergenceApprovalComponent } from "./convergence/incident-management/incidence-convergence-approval/incidence-convergence-approval.component";
import { IncidenceConvergenceReviewComponent } from "./convergence/incident-management/incidence-convergence-review/incidence-convergence-review.component";
import { IncidenceConvergenceCaptureEventComponent } from "./convergence/incident-management/incidence-convergence-capture-event/incidence-convergence-capture-event.component";
import { IncidenceConvergenceSettingComponent } from "./convergence/incident-management/incidence-convergence-setting/incidence-convergence-setting.component";
import { IncidenceConvergenceSetupComponent } from "./convergence/incident-management/incidence-convergence-setup/incidence-convergence-setup.component";
import { OrganizationLineManagerApprovalComponent } from "./organization/organization-line-manager-approval/organization-line-manager-approval.component";
import { OrganizationOrganogramComponent } from "./organization/organization-organogram/organization-organogram.component";
import { InvStockPositionViewComponent } from "./inventory-management/inv-stock-position-view/inv-stock-position-view.component";
import { PayrollSpecialReqApprovalComponent } from "./payroll/payroll-special-req-approval/payroll-special-req-approval.component";
import { PayrollSpecialRequestComponent } from "./payroll/payroll-special-request/payroll-special-request.component";
import { PayrollSpecialSetupComponent } from "./payroll/payroll-special-setup/payroll-special-setup.component";
import { PayrollPaidVsQtyComponent } from "./payroll/payroll-paid-vs-qty/payroll-paid-vs-qty.component";
import { PayrollPayeStateReportComponent } from "./payroll/payroll-paye-state-report/payroll-paye-state-report.component";
import { GmaTrainingFacilityComponent } from "./gma/gma-training-facility/gma-training-facility.component";
import { ConvergenceCaptureEventComponent } from "./convergence/convergence-capture-event/convergence-capture-event.component";
import { GmaApplicantListingComponent } from "./gma/gma-applicant-listing/gma-applicant-listing.component";
import { GmaSanctionsComponent } from "./gma/gma-sanctions/gma-sanctions.component";
import { GmaFacilitatorComponent } from "./gma/gma-facilitator/gma-facilitator.component";
import { GmaTrainingScheduleComponent } from "./gma/gma-training-schedule/gma-training-schedule.component";
import { GmaMaterialReplacementComponent } from "./gma/gma-material-replacement/gma-material-replacement.component";
import { GmaGuarantorComponent } from "./gma/gma-guarantor/gma-guarantor.component";
import { GmaDashboardComponent } from "./gma/gma-dashboard/gma-dashboard.component";
import { GmaMaterialRefreshComponent } from "./gma/gma-material-refresh/gma-material-refresh.component";
import { PayrollVsContractComponent } from "./payroll/payroll-vs-contract/payroll-vs-contract.component";
import { PayrollApprovalSetupComponent } from "./payroll/payroll-approval-setup/payroll-approval-setup.component";
import { PayrollApprovalComponent } from "./payroll/payroll-approval/payroll-approval.component";
import { PayrollManagementComponent } from "./payroll/payroll-management/payroll-management.component";
import { PayrollRunsComponent } from "./payroll/payroll-runs/payroll-runs.component";
import { PayrollAbsenceAppComponent } from "./payroll/payroll-absence-app/payroll-absence-app.component";
import { PayrollAbsenceReqComponent } from "./payroll/payroll-absence-req/payroll-absence-req.component";
import { PayrollOperativeAttachmentComponent } from "./payroll/payroll-operative-attachment/payroll-operative-attachment.component";
import { PayrollPayGroupComponent } from "./payroll/payroll-pay-group/payroll-pay-group.component";
import { PayrollTaxSettingsComponent } from "./payroll/payroll-tax-settings/payroll-tax-settings.component";
import { PayrollPayGradeComponent } from "./payroll/payroll-pay-grade/payroll-pay-grade.component";
import { PayrollSettingsComponent } from "./payroll/payroll-settings/payroll-settings.component";
import { GmaBioRecaptureComponent } from "./gma/gma-bio-recapture/gma-bio-recapture.component";
import { GmaSupervisionPolicyComponent } from "./gma/gma-supervision-policy/gma-supervision-policy.component";
import { GmaClusterReportingComponent } from "./gma/gma-cluster-reporting/gma-cluster-reporting.component";
import { GmaBeatAllocationComponent } from "./gma/gma-beat-allocation/gma-beat-allocation.component";
import { GmaOperativeManagementComponent } from "./gma/gma-operative-management/gma-operative-management.component";
import { GmaMaterialDistributionComponent } from "./gma/gma-material-distribution/gma-material-distribution.component";
import { GmaZonalMapViewComponent } from "./gma/gma-zonal-map-view/gma-zonal-map-view.component";
import { GmaRedeploymentComponent } from "./gma/gma-redeployment/gma-redeployment.component";
import { LocationChecklistSetupComponent } from "./client-management/location-checklist-setup/location-checklist-setup.component";
import { GmaOrgStructureComponent } from "./gma/gma-org-structure/gma-org-structure.component";
import { BeatCreationComponent } from "./client-management/beat-creation/beat-creation.component";
import { BeatTypeSetupComponent } from "./client-management/beat-type-setup/beat-type-setup.component";
import { GmaMaterialRequestComponent } from "./gma/gma-material-request/gma-material-request.component";
import { ClientLocationComponent } from "./client-management/client-location/client-location.component";
import { GmaTrainingSchoolComponent } from "./gma/gma-training-school/gma-training-school.component";
import { GmaManageJobComponent } from "./gma/gma-manage-job/gma-manage-job.component";
import { GmaSettingsComponent } from "./gma/gma-settings/gma-settings.component";
import { GmaSetupComponent } from "./gma/gma-setup/gma-setup.component";
import { InventoryDashboardComponent } from "./inventory-management/inventory-dashboard/inventory-dashboard.component";
import { GmaCreateJobComponent } from "./gma/gma-create-job/gma-create-job.component";
import { GuardItemSettingsComponent } from "./gma/guard-item-settings/guard-item-settings.component";
import { RequestAuditComponent } from "./inventory-management/request-audit/request-audit.component";
import { UserRequestComponent } from "./inventory-management/user-request/user-request.component";
import { StockPolicyComponent } from "./inventory-management/stock-policy/stock-policy.component";
import { BatchConfirmationComponent } from "./inventory-management/batch-confirmation/batch-confirmation.component";
import { BatchApprovalComponent } from "./inventory-management/batch-approval/batch-approval.component";
import { InventoryStockComponent } from "./inventory-management/inventory-stock/inventory-stock.component";
import { InventoryStoreComponent } from "./inventory-management/inventory-store/inventory-store.component";
import { InventoryManagementComponent } from "./inventory-management/inventory-management.component";
import { RedoProjectManagementComponent } from "./project-management/redo-project-management/redo-project-management.component";
import { EscalationManagementComponent } from "./register-complaints/escalation-management/escalation-management.component";
import { ComplaintTrackingComponent } from "./register-complaints/complaint-tracking/complaint-tracking.component";
import { RealEscalationMatrixComponent } from "./complaint-origin/real-escalation-matrix/real-escalation-matrix.component";
import { ComplaintsHandlingComponent } from "./register-complaints/complaints-handling/complaints-handling.component";
import { ComplaintSourceComponent } from "./complaint-origin/complaint-source/complaint-source.component";
import { RegisterComplaintsComponent } from "./register-complaints/register-complaints.component";
import { ComplaintSetupComponent } from "./setups/complaint-setup/complaint-setup.component";
import { ComplaintTypeComponent } from "./complaint-origin/complaint-type/complaint-type.component";
import { ComplaintOriginComponent } from "./complaint-origin/complaint-origin.component";
import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { HomeComponent } from "./home.component";
import { ProfileComponent } from "./profile/profile.component";
import { AccountComponent } from "./account/account.component";
import { BranchOfficeComponent } from "./setups/branch-office/branch-office.component";
import { DivisionOpSetupComponent } from "./setups/division-op-setup/division-op-setup.component";
import { SBUComponent } from "./setups/sbu/sbu.component";
import { TargetComponent } from "./setups/target/target.component";
import { BankComponent } from "./setups/bank/bank.component";
import { StandardSlaOperatingComponent } from "./setups/standard-sla-operating/standard-sla-operating.component";
import { RequiredServiceDocumentComponent } from "./service-setup/required-service-document/required-service-document.component";
import { RequiredServiceQualElementComponent } from "./service-setup/required-service-qual-element/required-service-qual-element.component";
import { ServiceCategoryTaskComponent } from "./service-setup/service-category-task/service-category-task.component";
import { ServiceTaskDeliverableComponent } from "./service-setup/service-task-deliverable/service-task-deliverable.component";
import { ServiceTypeComponent } from "./service-setup/service-type/service-type.component";
import { ServiceComponent } from "./service-setup/service/service.component";
import { ProjectManagementComponent } from "./project-management/project-management.component";
import { RoleComponent } from "./role/role.component";
import { ApprovalsComponent } from "./approvals/approvals.component";
import { AccountsChartComponent } from "./finance/account-chart/account-chart.component";
import { AccountStatementComponent } from "./finance/account-statement/account-statement.component";
import { AccountTransactionMdComponent } from "./finance/account-transaction-md/account.transaction-md.component";
import { UserDeliverableComponent } from "./project-management/user-deliverable/user-deliverable.component";
import { SbuUsersComponent } from "./setups/sbu-users/sbu-users.component";
import { ReceiptingComponent } from "./finance/receipting/receipting.component";
import { ApprovalSetupComponent } from "./setups/approval-setup/approval-setup.component";
import { ClientViewComponent } from "./client-management/client-view/client-view.component";
import { ClientEndorsementComponent } from "./client-management/client-endorsement/client-endorsement.component";
import { InvoiceCreationComponent } from "./client-management/invoice-creation/invoice-creation.component";
import { BeneficiaryComponent } from "./client-management/beneficiary/beneficiary.component";
import { CorporateAdjustmentSetupComponent } from "./setups/corporate-adjustment-setup/corporate-adjustment-setup.component";
import { ReportViewComponent } from "./reporting/report-view/report-view.component";
import { ServicePricingComponent } from "./service-setup/service-pricing/service-pricing.component";
import { ModeoftransportComponent } from "./modeoftransport/modeoftransport.component";
import { SupplierCategoryComponent } from "./supplier/supplier-category/supplier-category.component";
import { SupplierServiceComponent } from "./supplier/supplier-service/supplier-service.component";
import { SupplierComponent } from "./supplier/supplier.component";
import { EscalationLevelComponent } from "./complaint-origin/escalation-level/escalation-level.component";
import { ProfileEscalationLevelComponent } from "./complaint-origin/profile-escalation-level/profile-escalation-level.component";
import { EscalationMatrixComponent } from "./complaint-origin/escalation-matrix/escalation-matrix.component";
import { AttachClientToRMComponent } from "./client-management/attach-client-to-rm/attach-client-to-rm.component";
import { ClientListingComponent } from "./client-management/client-listing/client-listing.component";
import { ClientPolicyComponent } from "./client-management/client-policy/client-policy.component";
import { SuspectCaptureComponent } from "./prospects/suspect-capture/suspect-capture.component";
import { SuspectQualificationComponent } from "./prospects/suspect-qualification/suspect-qualification.component";
import { RegionalRequestComponent } from "./inventory-management/regional-request/regional-request.component";
import { NewLeadDashboardComponent } from "./new-lead-admin/new-lead-dashboard/new-lead-dashboard.component";
import { GmaApprovalComponent } from "./gma/gma-approval/gma-approval.component";
import { ContactsComponent } from "./contacts/contacts.component";
import { LeadEngagementsComponent } from "./lead-engagements/lead-engagements.component";
import { EngagementDashboardComponent } from "./engagement-dashboard/engagement-dashboard.component";
import { EngagementContactsComponent } from "./engagement-contacts/engagement-contacts.component";
import { EngagementActivitiesComponent } from "./engagement-activities/engagement-activities.component";
import { MakeSettingsComponent } from "./supplier/make-settings/make-settings.component";
import { ModelSettingsComponent } from "./supplier/model-settings/model-settings.component";
import { QualificationComponent } from "./qualification/qualification.component";
import { QualificationsComponent } from "./qualifications/qualifications.component";
import { OpportunitiesComponent } from "./opportunities/opportunities.component";
import { DeliverableDashboardComponent } from "./workspace-management/deliverable-dashboard/deliverable-dashboard.component";
import { SalesDashboardComponent } from "./sales-dashboard/sales-dashboard.component";
import { EngagementLeadQualificationComponent } from "./engagement-lead-qualification/engagement-lead-qualification.component";
import { SendMailComponent } from "./send-mail/send-mail.component";
import { PayrollSpecialRequest2Component } from "./payroll/payroll-special-request2/payroll-special-request2.component";
import { PayrollApproval2Component } from "./payroll/payroll-approval2/payroll-approval2.component";
import { ApprovalSetup2Component } from "./setups/approval-setup2/approval-setup2.component";
import { InvStockReceiptComponent } from "./inventory-management/inv-stock-receipt/inv-stock-receipt.component";
import { AdvancePaymentComponent } from "./finance/advance-payment/advance-payment.component";
import { InvStockIssueComponent } from "./inventory-management/inv-stock-issue/inv-stock-issue.component";
import { SupplierOfficeSetupComponent } from "./supplier-office-setup/supplier-office-setup.component";
import { SupplierApprovalRequestComponent } from "./supplier-approval-request/supplier-approval-request.component";
import { SupplierApprovalListingComponent } from "./supplier-approval-listing/supplier-approval-listing.component";
import { OtherUsersComponent } from "./profile/other-users/other-users.component";
import { NonPrincipalApprovalSetupComponent } from "./setups/non-principal-approval-setup/non-principal-approval-setup.component";
import { GmaBioSearchComponent } from "./gma/gma-bio-search/gma-bio-search.component";
import { ReverseReceiptingComponent } from "./finance/reverse-receipting/reverse-receipting.component";
import { ReverseInvoiceComponent } from "./finance/reverse-invoice/reverse-invoice.component";
import { ServiceIncomeAccountComponent } from "./service-setup/service-income-account/service-income-account.component";
import { IncidenceConvergenceReportComponent } from "./convergence/incident-management/incidence-convergence-report/incidence-convergence-report.component";
import { ReceivableReportComponent } from "./reporting/receivable-report/receivable-report.component";
import { ReversalOfficeComponent } from "./finance/reversal-office/reversal-office.component";
import { ReverseVoucherComponent } from "./finance/reverse-voucher/reverse-voucher.component";
import { ManagementDashboardComponent } from "./management-dashboard/management-dashboard.component";
import { BatchRequestTrackingComponent } from "./inventory-management/batch-request-tracking/batch-request-tracking.component";
import { ExpenseLineSetupComponent } from "./finance/expense-line-setup/expense-line-setup.component";
import { ExpenseRequesterAttachmentComponent } from "./finance/expense-requester-attachment/expense-requester-attachment.component";
import { ExpenseApprovalSetupComponent } from "./finance/expense-approval-setup/expense-approval-setup.component";
import { ExpenseBudgetsComponent } from "./finance/expense-budgets/expense-budgets.component";
import { IncidenceConvergenceCaptureMobileComponent } from "./convergence/incident-management/incidence-convergence-capture-mobile/incidence-convergence-capture-mobile.component";
import { ExpenseUserRequestComponent } from "./finance/expense-user-request/expense-user-request.component";
import { ExpenseLineApprovalComponent } from "./organization/expense-line-approval/expense-line-approval.component";
import { AdvancePaymentReversalComponent } from "./finance/advance-payment/advance-payment-reversal/advance-payment-reversal.component";
import { VoucherPostingComponent } from "./finance/voucher-posting/voucher-posting.component";
import { PbmSpecialRequestWorkbenchComponent } from "./platform-benefits-manager/pbm-special-request-workbench/pbm-special-request-workbench.component";
import { PeriodOpeningClosingComponent } from "./finance/period-opening-closing/period-opening-closing.component";
import { ExpenseAuthorizationComponent } from "./finance/expense-authorization/expense-authorization.component";
import { ExpenseApprovalComponent } from "./finance/expense-approval/expense-approval.component";
import { ExpenseAuthorizationSetupComponent } from "./finance/expense-authorization-setup/expense-authorization-setup.component";
import { ExpensePaymentComponent } from "./finance/expense-payment/expense-payment.component";
import { ExpensePayablesComponent } from "./finance/expense-payables/expense-payables.component";
import { SupplierBankAccountComponent } from "./supplier/supplier-bank-account/supplier-bank-account.component";
import { FinancePrintChequeComponent } from "./finance/finance-print-cheque/finance-print-cheque.component";
import { FinanceBankPaymentComponent } from "./finance/finance-bank-payment/finance-bank-payment.component";
import { ExpenseRequestTrackerComponent } from "./finance/expense-request-tracker/expense-request-tracker.component";
import { BulkApprovalComponent } from "./approvals/bulk-approval/bulk-approval.component";
import { BankAccountSetupComponent } from "./setups/bank-account-setup/bank-account-setup.component";
import { ContractExtensionApprovalSetupComponent } from "./client-management/contract-extension-approval-setup/contract-extension-approval-setup.component";
import { ContractExtensionRequestComponent } from "./client-management/contract-extension-request/contract-extension-request.component";
import { ContractExtensionApprovalComponent } from "./client-management/contract-extension-approval/contract-extension-approval.component";
import { BudgetSeasonComponent } from "./budget/budget-season/budget-season.component";
import { BudgetCycleComponent } from "./budget/budget-cycle/budget-cycle.component";
import { ShowBudgetCyclesComponent } from "./budget/budget-cycle/show-budget-cycles/show-budget-cycles.component";
import { BudgetDistributionsComponent } from "./budget/budget-distributions/budget-distributions.component";
import { BudgetApprovalSetupComponent } from "./budget/budget-approval-setup/budget-approval-setup.component";
import { BudgetApprovalComponent } from "./budget/budget-approval/budget-approval.component";
import { BudgetReviewerComponent } from "./budget/budget-reviewer/budget-reviewer.component";
import { BudgetReviewerSetupComponent } from "./budget/budget-reviewer-setup/budget-reviewer-setup.component";
import { InvoiceUpdateComponent } from "./finance/invoice-update/invoice-update.component";
import { FinanceBulkSettlementComponent } from "./finance/finance-bulk-settlement/finance-bulk-settlement.component";
import { GmaGuardRegistrationComponent } from "./gma/gma-guard-registration/gma-guard-registration.component";
import { ClientMergeComponent } from "./client-management/client-merge/client-merge.component";
import { ClientMergeRequestComponent } from "./client-management/client-merge-request/client-merge-request.component";
import { ClientMergeApprovalComponent } from "./client-management/client-merge-approval/client-merge-approval.component";
import { AccountReconciliationComponent } from "./finance/account-reconciliation/account-reconciliation.component";
import { RevenueArmotizationViewComponent } from "./client-management/revenue-armotization-view/revenue-armotization-view.component";
import { RenewalViewComponent } from "./client-management/renewal-view/renewal-view.component";
import { ProductionDashboardComponent } from "./production-dashboard/production-dashboard.component";
import { ApprovalDashboardComponent } from "./approval-dashboard/approval-dashboard.component";
import { CreditNoteReversalComponent } from "./finance/credit-note-reversal/credit-note-reversal.component";
import { InvoiceReplacementComponent } from "./finance/invoice-replacement/invoice-replacement.component";
import { ApprovalChecklistComponent } from "./approvals/approval-checklist/approval-checklist.component";
import { ExpressPerformaInvComponent } from "./finance/express-performa-inv/express-performa-inv.component";
import { ExpressPerformaCloseoutComponent } from "./finance/express-performa-closeout/express-performa-closeout.component";
import { RawInflowUplComponent } from "./finance/raw-inflow-upl/raw-inflow-upl.component";
import { RawInflowConfirmationComponent } from "./finance/raw-inflow-confirmation/raw-inflow-confirmation.component";
import { AutoRenewPolicyComponent } from "./finance/auto-renew-policy/auto-renew-policy.component";
import { DtrackTransmissionComponent } from "./finance/dtrack-transmission/dtrack-transmission.component";
import { InvAggregatedViewComponent } from "./inventory-management/inv-aggregated-view/inv-aggregated-view.component";
import { LoginDashboardComponent } from "./login-dashboard/login-dashboard.component";
import { ClientStateMappingComponent } from "./convergence/incident-management/client-state-mapping/client-state-mapping.component";
import { ApprovalSequenceComponent } from "./approvals/approval-sequence/approval-sequence.component";
import { ContractRenewNotificationComponent } from "./client-management/contract-renew-notification/contract-renew-notification.component";
import { ServiceCostAnalysisComponent } from "./client-management/service-cost-analysis/service-cost-analysis.component";

const routes: Routes = [
  {
    path: "",
    component: HomeComponent,
    children: [
      { path: "", redirectTo: "profile", pathMatch: "full" },
      { path: "profile", component: ProfileComponent },
      { path: "account/soa", component: AccountComponent },
      { path: "account/branch-office", component: BranchOfficeComponent },
      { path: "division-op-setup", component: DivisionOpSetupComponent },
      {
        path: "supplier-office-setup",
        component: SupplierOfficeSetupComponent,
      },
      {
        path: "supplier-approval-request",
        component: SupplierApprovalRequestComponent,
      },
      {
        path: "supplier-approval-listing",
        component: SupplierApprovalListingComponent,
      },
      {
        path: "supplier/bank-account",
        component: SupplierBankAccountComponent,
      },
      {
        path: "lead-admin",
        loadChildren: () =>
          import("./lead-admin/lead-admin.module").then(
            (m) => m.LeadAdminModule
          ),
      },
      {
        path: "qualification",
        loadChildren: () =>
          import("./qualification/qualification.module").then(
            (m) => m.QualificationModule
          ),
      },
      { path: "SBU", component: SBUComponent },
      { path: "target", component: TargetComponent },
      { path: "bank", component: BankComponent },
      {
        path: "standard-sla-for-operation",
        component: StandardSlaOperatingComponent,
      },
      {
        path: "required-service-document",
        component: RequiredServiceDocumentComponent,
      },
      {
        path: "required-service-qualification-element",
        component: RequiredServiceQualElementComponent,
      },

      {
        path: "service-category-task",
        component: ServiceCategoryTaskComponent,
      },
      {
        path: "service-income-account",
        component: ServiceIncomeAccountComponent,
      },
      {
        path: "sales-dashboard",
        component: SalesDashboardComponent,
      },
      {
        path: "managenement-dashboard",
        component: ManagementDashboardComponent,
      },
      {
        path: "login-dashboard",
        component: LoginDashboardComponent,
      },
      {
        path: "production-dashboard",
        component: ProductionDashboardComponent,
      },
      {
        path: "approval-dashboard",
        component: ApprovalDashboardComponent,
      },
      {
        path: "service-task-deliverable",
        component: ServiceTaskDeliverableComponent,
      },
      { path: "service-type", component: ServiceTypeComponent },
      { path: "services", component: ServiceComponent },
      { path: "project-management", component: ProjectManagementComponent },
      { path: "my-task", component: UserDeliverableComponent },
      { path: "role", component: RoleComponent },
      { path: "approvals", component: ApprovalsComponent },
      { path: "bulk-approval", component: BulkApprovalComponent },
      { path: "approval-checklist", component: ApprovalChecklistComponent },
      { path: "approval-sequence", component: ApprovalSequenceComponent },
      { path: "account-chart", component: AccountsChartComponent },
      { path: "account-statement/:id", component: AccountStatementComponent },
      { path: "account-statement", component: AccountStatementComponent },
      {
        path: "account-transaction-md",
        component: AccountTransactionMdComponent,
      },
      { path: "sbu-users", component: SbuUsersComponent },
      { path: "receipting", component: ReceiptingComponent },
      { path: "reverse-receipting", component: ReverseReceiptingComponent },
      { path: "reverse-invoice", component: ReverseInvoiceComponent },
      { path: "reverse-voucher", component: ReverseVoucherComponent },
      { path: "reversal-office", component: ReversalOfficeComponent },
      {
        path: "finance/expense/line-setup",
        component: ExpenseLineSetupComponent,
      },
      {
        path: "finance/expense/requester-attachment",
        component: ExpenseRequesterAttachmentComponent,
      },
      {
        path: "finance/expense/approval-setup",
        component: ExpenseApprovalSetupComponent,
      },
      {
        path: "finance/expense/budgets",
        component: ExpenseBudgetsComponent,
      },
      {
        path: "finance/expense/user-request",
        component: ExpenseUserRequestComponent,
      },
      {
        path: "finance/expense/approval",
        component: ExpenseApprovalComponent,
      },
      {
        path: "finance/expense/authorization-setup",
        component: ExpenseAuthorizationSetupComponent,
      },
      {
        path: "finance/expense/authorization",
        component: ExpenseAuthorizationComponent,
      },
      {
        path: "finance/expense/payables",
        component: ExpensePayablesComponent,
      },
      {
        path: "finance/expense/payment",
        component: ExpensePaymentComponent,
      },
      {
        path: "finance/expense/print-cheque",
        component: FinancePrintChequeComponent,
      },
      {
        path: "finance/expense/bank-payment",
        component: FinanceBankPaymentComponent,
      },
      {
        path: "finance/expense/request-tracker",
        component: ExpenseRequestTrackerComponent,
      },
      {
        path: "finance/account-reconciliation",
        component: AccountReconciliationComponent,
      },
      {
        path: "finance/advance-payment/reversal",
        component: AdvancePaymentReversalComponent,
      },
      {
        path: "finance/voucher-posting",
        component: VoucherPostingComponent,
      },
      {
        path: "finance/invoice-update",
        component: InvoiceUpdateComponent,
      },
      {
        path: "finance/bulk-settlement",
        component: FinanceBulkSettlementComponent,
      },
      {
        path: "finance/credit-note-reversal",
        component: CreditNoteReversalComponent,
      },
      {
        path: "finance/invoice-replacement",
        component: InvoiceReplacementComponent,
      },
      {
        path: "finance/express-performa-inv",
        component: ExpressPerformaInvComponent,
      },
      {
        path: "finance/raw-inflow-upload",
        component: RawInflowUplComponent,
      },
      {
        path: "finance/raw-inflow-confirmation",
        component: RawInflowConfirmationComponent,
      },
      {
        path: "finance/express-performa-closeout",
        component: ExpressPerformaCloseoutComponent,
      },
      {
        path: "finance/auto-renew-policy",
        component: AutoRenewPolicyComponent,
      },
      {
        path: "finance/dtrack-transmission",
        component: DtrackTransmissionComponent,
      },
      { path: "advance-payment", component: AdvancePaymentComponent },
      {
        path: "period-opening-closing",
        component: PeriodOpeningClosingComponent,
      },

      { path: "approval-setup", component: ApprovalSetupComponent },
      { path: "approval-setup2", component: ApprovalSetup2Component },
      {
        path: "non-principal-approval-setup",
        component: NonPrincipalApprovalSetupComponent,
      },
      { path: "currency-setup", component: CurrencySetupComponent },
      { path: "setup/bank-account", component: BankAccountSetupComponent },
      { path: "client-view/:id", component: ClientViewComponent },
      { path: "client-view", component: ClientViewComponent },
      { path: "client-endorsement", component: ClientEndorsementComponent },
      {
        path: "client-management/ce-approval-setup",
        component: ContractExtensionApprovalSetupComponent,
      },
      {
        path: "client-management/ce-request",
        component: ContractExtensionRequestComponent,
      },
      {
        path: "client-management/ce-approval",
        component: ContractExtensionApprovalComponent,
      },
      {
        path: "client-management/client-merge",
        component: ClientMergeComponent,
      },
      {
        path: "client-management/client-merge-request",
        component: ClientMergeRequestComponent,
      },
      {
        path: "client-management/client-merge-approval",
        component: ClientMergeApprovalComponent,
      },
      {
        path: "client-management/contract-renew-notification",
        component: ContractRenewNotificationComponent,
      },
      {
        path: "client-management/service-cost-analysis",
        component: ServiceCostAnalysisComponent,
      },
      { path: "invoice-creation", component: InvoiceCreationComponent },
      { path: "beneficiary", component: BeneficiaryComponent },
      {
        path: "corporate-adjustment-setup",
        component: CorporateAdjustmentSetupComponent,
      },
      { path: "report-view", component: ReportViewComponent },
      { path: "report/receivable", component: ReceivableReportComponent },
      { path: "service-pricing", component: ServicePricingComponent },
      {
        path: "armada",
        loadChildren: () =>
          import("./armada/armada.module").then((m) => m.ArmadaModule),
      },

      {
        path: "required-service-document",
        component: RequiredServiceDocumentComponent,
      },
      {
        path: "required-service-qualification-element",
        component: RequiredServiceQualElementComponent,
      },
      {
        path: "service-category-task",
        component: ServiceCategoryTaskComponent,
      },

      {
        path: "other-users",
        component: OtherUsersComponent,
      },
      {
        path: "halogen-users",
        component: HalogenUsersComponent,
      },

      {
        path: "service-task-deliverable",
        component: ServiceTaskDeliverableComponent,
      },
      { path: "service-type", component: ServiceTypeComponent },
      { path: "services", component: ServiceComponent },
      { path: "project-management", component: ProjectManagementComponent },
      {
        path: "redo-project-management",
        component: RedoProjectManagementComponent,
      },
      { path: "my-task", component: UserDeliverableComponent },
      { path: "role", component: RoleComponent },
      { path: "approvals", component: ApprovalsComponent },
      { path: "account-chart", component: AccountsChartComponent },
      { path: "account-statement/:id", component: AccountStatementComponent },
      { path: "account-statement", component: AccountStatementComponent },
      {
        path: "account-transaction-md",
        component: AccountTransactionMdComponent,
      },
      { path: "sbu-users", component: SbuUsersComponent },
      { path: "receipting", component: ReceiptingComponent },
      { path: "approval-setup", component: ApprovalSetupComponent },
      { path: "client-view", component: ClientViewComponent },
      { path: "client-endorsement", component: ClientEndorsementComponent },
      { path: "invoice-creation", component: InvoiceCreationComponent },
      { path: "attach-client-to-rm", component: AttachClientToRMComponent },
      { path: "client-policy/:id", component: ClientPolicyComponent },
      { path: "client-policy", component: ClientPolicyComponent },
      { path: "client-listing", component: ClientListingComponent },
      {
        path: "corporate-adjustment-setup",
        component: CorporateAdjustmentSetupComponent,
      },
      { path: "report-view", component: ReportViewComponent },
      {
        path: "armada",
        loadChildren: () =>
          import("./armada/armada.module").then((m) => m.ArmadaModule),
      },
      {
        path: "workspace-management",
        loadChildren: () =>
          import("./workspace-management/workspace-management.module").then(
            (m) => m.WorkspaceManagementModule
          ),
      },
      { path: "supplier/category", component: SupplierCategoryComponent },
      { path: "supplier/service", component: SupplierServiceComponent },
      { path: "supplier/settings/make", component: MakeSettingsComponent },
      { path: "supplier/settings/model", component: ModelSettingsComponent },
      { path: "supplier", component: SupplierComponent },
      { path: "modeoftransport", component: ModeoftransportComponent },
      {
        path: "complaint/complaintorigin",
        component: ComplaintOriginComponent,
      },
      { path: "complaint/complaint-type", component: ComplaintTypeComponent },
      {
        path: "complaint/complaint-source",
        component: ComplaintSourceComponent,
      },
      {
        path: "complaint/escalation-level",
        component: EscalationLevelComponent,
      },
      {
        path: "engagement-lead-qualification",
        component: EngagementLeadQualificationComponent,
      },
      {
        path: "complaint/profile-escalation-level",
        component: ProfileEscalationLevelComponent,
      },
      {
        path: "complaint-setup",
        component: ComplaintSetupComponent,
      },
      {
        path: "complaint/escalation-matrix",
        component: EscalationMatrixComponent,
      },
      {
        path: "complaint/real-escalation-matrix",
        component: RealEscalationMatrixComponent,
      },

      {
        path: "complaint-management/register-complaint",
        component: RegisterComplaintsComponent,
      },
      {
        path: "complaint-management/complaint-handling",
        component: ComplaintsHandlingComponent,
      },
      {
        path: "complaint-management/complaint-tracking",
        component: ComplaintTrackingComponent,
      },
      {
        path: "complaint-management/escalation-management",
        component: EscalationManagementComponent,
      },
      { path: "suspect-capture", component: SuspectCaptureComponent },
      {
        path: "suspect-qualification/:id",
        component: SuspectQualificationComponent,
      },
      {
        path: "contacts",
        component: ContactsComponent,
      },
      {
        path: "suspect-qualification",
        component: SuspectQualificationComponent,
      },
      // {
      //   path: "lead-engagements",
      //   component: LeadEngagementsComponent,
      // },
      {
        path: "lead-engagements",
        component: LeadEngagementsComponent,
        children: [
          {
            path: "engagement-dashboard/:id",
            component: EngagementDashboardComponent,
          },
          {
            path: "engagement-contacts/:id",
            component: EngagementContactsComponent,
          },
          {
            path: "engagement-activities/:id",
            component: EngagementActivitiesComponent,
          },
          {
            path: "engagement-lead-qualification/:id",
            component: EngagementLeadQualificationComponent,
          },
          {
            path: "send-mail/:id",
            component: SendMailComponent,
          },
        ],
      },
      {
        path: "lead-dashboard",
        component: NewLeadDashboardComponent,
      },
      {
        path: "inventory-management/set-up",
        component: InventoryManagementComponent,
      },
      {
        path: "qualification-pipeline",
        component: QualificationsComponent,
      },
      {
        path: "opportunities-pipeline",
        component: OpportunitiesComponent,
      },

      {
        path: "inventory-management/inventory-store",
        component: InventoryStoreComponent,
      },
      {
        path: "inventory-management/central-request",
        component: InventoryStockComponent,
      },
      {
        path: "inventory-management/batch-approval",
        component: BatchApprovalComponent,
      },
      {
        path: "inventory-management/batch-confirmation",
        component: BatchConfirmationComponent,
      },
      {
        path: "inventory-management/stock-policy",
        component: StockPolicyComponent,
      },
      {
        path: "inventory-management/regional-request",
        component: RegionalRequestComponent,
      },
      {
        path: "inventory-management/user-request",
        component: UserRequestComponent,
      },
      {
        path: "inventory-management/request-audit",
        component: RequestAuditComponent,
      },
      {
        path: "inventory-management/dashboard",
        component: InventoryDashboardComponent,
      },
      {
        path: "inventory-management/stock-position",
        component: InvStockPositionViewComponent,
      },
      {
        path: "inventory-management/stock-receipt",
        component: InvStockReceiptComponent,
      },
      {
        path: "inventory-management/stock-issue",
        component: InvStockIssueComponent,
      },
      {
        path: "inventory-management/batch-request-tracking",
        component: BatchRequestTrackingComponent,
      },
      {
        path: "inventory-management/aggregated-view",
        component: InvAggregatedViewComponent,
      },
      {
        path: "guard-management/item-settings",
        component: GuardItemSettingsComponent,
      },
      {
        path: "guard-management/create-job",
        component: GmaCreateJobComponent,
      },
      {
        path: "guard-management/setup",
        component: GmaSetupComponent,
      },
      {
        path: "guard-management/settings",
        component: GmaSettingsComponent,
      },
      {
        path: "guard-management/manage-job",
        component: GmaManageJobComponent,
      },
      {
        path: "guard-management/training-school",
        component: GmaTrainingSchoolComponent,
      },
      {
        path: "guard-management/approval",
        component: GmaApprovalComponent,
      },
      {
        path: "client-management/client-location",
        component: ClientLocationComponent,
      },
      {
        path: "guard-management/material-request",
        component: GmaMaterialRequestComponent,
      },
      {
        path: "guard-management/material-refresh",
        component: GmaMaterialRefreshComponent,
      },
      {
        path: "guard-management/material-replace",
        component: GmaMaterialReplacementComponent,
      },
      {
        path: "guard-management/training-schedule",
        component: GmaTrainingScheduleComponent,
      },
      {
        path: "client-management/beat-type-setup",
        component: BeatTypeSetupComponent,
      },
      {
        path: "client-management/beat-creation",
        component: BeatCreationComponent,
      },
      {
        path: "client-management/information-update",
        component: ClientInformationUpdateComponent,
      },
      {
        path: "guard-management/org-structure",
        component: GmaOrgStructureComponent,
      },
      {
        path: "client-management/location-checklist-setup",
        component: LocationChecklistSetupComponent,
      },
      {
        path: "client-management/revenue-armotization-view",
        component: RevenueArmotizationViewComponent,
      },
      {
        path: "client-management/renewal-view",
        component: RenewalViewComponent,
      },
      {
        path: "guard-management/gma-redeployment",
        component: GmaRedeploymentComponent,
      },
      {
        path: "deliverable-dashboard",
        component: DeliverableDashboardComponent,
      },
      {
        path: "guard-management/gma-zonal-map-view",
        component: GmaZonalMapViewComponent,
      },
      {
        path: "guard-management/gma-material-distribution",
        component: GmaMaterialDistributionComponent,
      },
      {
        path: "guard-management/gma-operative-management",
        component: GmaOperativeManagementComponent,
      },
      {
        path: "guard-management/gma-beat-allocation",
        component: GmaBeatAllocationComponent,
      },
      {
        path: "guard-management/gma-cluster-reporting",
        component: GmaClusterReportingComponent,
      },
      {
        path: "guard-management/gma-supervision-policy",
        component: GmaSupervisionPolicyComponent,
      },
      {
        path: "guard-management/gma-bio-recapture",
        component: GmaBioRecaptureComponent,
      },
      {
        path: "guard-management/dashboard",
        component: GmaDashboardComponent,
      },
      {
        path: "guard-management/guarantor",
        component: GmaGuarantorComponent,
      },
      {
        path: "guard-management/facilitator",
        component: GmaFacilitatorComponent,
      },
      {
        path: "guard-management/sanctions",
        component: GmaSanctionsComponent,
      },
      {
        path: "guard-management/applicant-listing",
        component: GmaApplicantListingComponent,
      },
      {
        path: "guard-management/training-facility",
        component: GmaTrainingFacilityComponent,
      },
      {
        path: "guard-management/bio-search",
        component: GmaBioSearchComponent,
      },
      {
        path: "guard-management/guard-registration",
        component: GmaGuardRegistrationComponent,
      },
      {
        path: "payroll/payroll-settings",
        component: PayrollSettingsComponent,
      },
      {
        path: "payroll/pay-grade",
        component: PayrollPayGradeComponent,
      },
      {
        path: "payroll/tax-settings",
        component: PayrollTaxSettingsComponent,
      },
      {
        path: "payroll/pay-group",
        component: PayrollPayGroupComponent,
      },
      {
        path: "payroll/operative-attachment",
        component: PayrollOperativeAttachmentComponent,
      },
      {
        path: "payroll/station-absence-req",
        component: PayrollAbsenceReqComponent,
      },
      {
        path: "payroll/station-absence-app",
        component: PayrollAbsenceAppComponent,
      },
      {
        path: "payroll/runs",
        component: PayrollRunsComponent,
      },
      {
        path: "payroll/management",
        component: PayrollManagementComponent,
      },
      {
        path: "payroll/approval",
        component: PayrollApprovalComponent,
      },
      {
        path: "payroll/approval2",
        component: PayrollApproval2Component,
      },
      {
        path: "payroll/approval-setup",
        component: PayrollApprovalSetupComponent,
      },
      {
        path: "payroll/payroll-vs-contract",
        component: PayrollVsContractComponent,
      },
      {
        path: "payroll/paye-state-report",
        component: PayrollPayeStateReportComponent,
      },
      {
        path: "payroll/paid-vs-qty",
        component: PayrollPaidVsQtyComponent,
      },
      {
        path: "payroll/special-setup",
        component: PayrollSpecialSetupComponent,
      },
      {
        path: "payroll/special-request",
        component: PayrollSpecialRequestComponent,
      },
      {
        path: "payroll/special-request2",
        component: PayrollSpecialRequest2Component,
      },
      {
        path: "payroll/special-request-approval",
        component: PayrollSpecialReqApprovalComponent,
      },
      {
        path: "convergence/capture-event",
        component: ConvergenceCaptureEventComponent,
      },
      {
        path: "organization/organogram",
        component: OrganizationOrganogramComponent,
      },
      {
        path: "organization/line-manager-approval",
        component: OrganizationLineManagerApprovalComponent,
      },
      {
        path: "organization/expense-line-approval",
        component: ExpenseLineApprovalComponent,
      },
      {
        path: "convergence/incident-management/setup",
        component: IncidenceConvergenceSetupComponent,
      },
      {
        path: "convergence/incident-management/setting",
        component: IncidenceConvergenceSettingComponent,
      },
      {
        path: "convergence/incident-management/capture-incidence",
        component: IncidenceConvergenceCaptureEventComponent,
      },
      {
        path: "convergence/incident-management/review-incident",
        component: IncidenceConvergenceReviewComponent,
      },
      {
        path: "convergence/incident-management/approval",
        component: IncidenceConvergenceApprovalComponent,
      },
      {
        path: "convergence/incident-management/dashboard",
        component: IncidenceConvergenceDashboardComponent,
      },
      {
        path: "convergence/incident-management/notification-template",
        component: IncidentNotificationTemplateComponent,
      },
      {
        path: "convergence/incident-management/notification-policy",
        component: IncidentNotificationPolicyComponent,
      },
      {
        path: "convergence/incident-management/client-notification",
        component: IncidentClientNotificationComponent,
      },
      {
        path: "convergence/incident-management/client-state-mapping",
        component: ClientStateMappingComponent,
      },
      {
        path: "convergence/incident-management/report",
        component: IncidenceConvergenceReportComponent,
      },
      {
        path: "convergence/incident-management/mobile-capture",
        component: IncidenceConvergenceCaptureMobileComponent,
      },
      {
        path: "convergence/device-management/setup",
        component: DeviceConvergenceSetupComponent,
      },
      {
        path: "convergence/device-management/registration",
        component: DeviceConvergenceRegistrationComponent,
      },
      {
        path: "convergence/device-management/connectivity",
        component: DeviceConvergenceConnectivityComponent,
      },
      {
        path: "convergence/device-management/dashboard",
        component: DeviceConvergenceDashboardComponent,
      },
      {
        path: "event-management/platform-benefit/create-platform",
        component: PbmCreatePlatformComponent,
      },
      {
        path: "event-management/platform-benefit/create-benefit",
        component: PbmCreateBenefitComponent,
      },
      {
        path: "event-management/platform-benefit/map-platform-service",
        component: PbmMapPlatformServiceComponent,
      },
      {
        path: "event-management/platform-benefit/map-service-benefit",
        component: PbmMapServiceBenefitComponent,
      },
      {
        path: "event-management/platform-benefit/special-request-workbench",
        component: PbmSpecialRequestWorkbenchComponent,
      },
      {
        path: "response/response-team-type",
        component: ResponseTeamTypeComponent,
      },
      {
        path: "response/response-teams",
        component: ResponseTeamsComponent,
      },
      {
        path: "response/resting-stations-setup",
        component: RestingStationsSetupComponent,
      },
      {
        path: "response/resting-station-lga-map",
        component: RestingStationLgaMapComponent,
      },
      {
        path: "response/resp-team-reststation-map",
        component: RespTeamReststationMapComponent,
      },
      {
        path: "budget-season",
        component: BudgetSeasonComponent,
      },
      {
        path: "budget-cycle",
        component: BudgetCycleComponent,
      },
      {
        path: "budget-distributions",
        component: BudgetDistributionsComponent,
      },
      {
        path: "budget-approval-setup",
        component: BudgetApprovalSetupComponent,
      },
      {
        path: "budget-approval-page",
        component: BudgetApprovalComponent,
      },
      {
        path: "budget-reviewer-page",
        component: BudgetReviewerComponent,
      },
      {
        path: "budget-reviewer-setup",
        component: BudgetReviewerSetupComponent,
      },
    ],
  },
  {
    path: "",
    redirectTo: "home/profile",
    pathMatch: "full",
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class HomeRoutingModule {}
