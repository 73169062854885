import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { ConfirmationService, MessageService } from "primeng/api";
import { BreadcrumbService } from "src/app/breadcrumb.service";
import {
  Division,
  OperatingEntity,
  ServiceCategory,
  ServiceGroup,
  Services,
} from "src/app/interfaces/home";
import {
  CreatePlatformServiceVM,
  Platform,
  PlatformService,
} from "src/app/interfaces/platform-benefit";
import { DivisionService } from "src/app/services/division.service";
import { OperatingEntityService } from "src/app/services/operating-entity.service";
import { PlatformBenefitService } from "src/app/services/platform-benefit.service";
import { ServiceCategoryService } from "src/app/services/service-category.service";
import { ServiceGroupService } from "src/app/services/service-group.service";
import { ServicesService } from "src/app/services/services.service";

@Component({
  selector: "app-pbm-map-platform-service",
  templateUrl: "./pbm-map-platform-service.component.html",
  styleUrls: ["./pbm-map-platform-service.component.scss"],
})
export class PbmMapPlatformServiceComponent implements OnInit {
  @ViewChild("formWrapper") public formWrapper: ElementRef;
  allPlatforms: Platform[];
  platformInView: Platform;
  allPlatformMappedServices: PlatformService[];
  selectedPlatformMappedServices: PlatformService[];
  mappedServCols: any[];
  allDivisions: Division[];
  theDivision: Division;
  allMarkets: OperatingEntity[];
  allMarketsToShow: OperatingEntity[];
  theMarket: OperatingEntity;
  allServiceGroups: ServiceGroup[];
  allServiceGroupsToShow: ServiceGroup[];
  theServiceGroup: ServiceGroup;
  allServiceCategories: ServiceCategory[];
  allServiceCategoriesToShow: ServiceCategory[];
  theServiceCategory: ServiceCategory;
  allServices: Services[];
  allServicesToShow: Services[];
  selectedServices: Services[] = [];
  mapNewPlatformService: boolean;

  constructor(
    private fb: FormBuilder,
    private divisionService: DivisionService,
    private operatingEntityService: OperatingEntityService,
    private serviceGroupService: ServiceGroupService,
    private serviceCategoryService: ServiceCategoryService,
    private servicesService: ServicesService,
    public platformBenefitService: PlatformBenefitService,
    private breadcrumbService: BreadcrumbService,
    public confirmationService: ConfirmationService,
    public messageService: MessageService
  ) {}

  ngOnInit(): void {
    this.breadcrumbService.setItems([
      {
        label: "Platform Benefits Management",
        routerLink: ["/home/platform-benefit"],
      },
      {
        label: "Map Platform Service",
        routerLink: ["/home/platform-benefit/map-platform-service"],
      },
    ]);

    this.mappedServCols = [
      { field: "serviceName", header: "Service Name" },
      { field: "serviceCategoryName", header: "Category" },
    ];

    this.GetAllDivisions();
    this.GetAllMarkets();
    this.GetAllServiceGroup();
    this.GetAllServiceCategory();
    this.GetAllServices();
    this.GetAllPlatformWithServices();
  }

  async GetAllDivisions() {
    this.divisionService.allDivisionData().subscribe(
      async (data) => {
        this.allDivisions = data.responseData;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all divsions at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  async GetAllMarkets() {
    this.operatingEntityService.allData().subscribe(
      async (data) => {
        this.allMarkets = data.responseData;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all operating entities at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  async GetAllServiceGroup() {
    this.serviceGroupService.allServiceGroup().subscribe(
      async (data) => {
        this.allServiceGroups = data.responseData;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all service group at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  async GetAllServiceCategory() {
    this.serviceCategoryService.allServiceCategory().subscribe(
      async (data) => {
        this.allServiceCategories = data.responseData;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all service categories at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  async GetAllServices() {
    this.servicesService.allService().subscribe(
      async (data) => {
        this.allServices = data.responseData;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all services at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  async GetAllPlatformWithServices() {
    this.platformBenefitService.GetAllPlatformsWithServices().subscribe(
      async (data) => {
        this.allPlatforms = data;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all platform services at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  GetMappedServices(item: Platform): number {
    return item.services.filter((x) => x.isDeleted == false).length;
  }

  ViewMappings(item: Platform) {
    this.platformInView = item;
    this.allPlatformMappedServices = item.services.filter(
      (x) => x.isDeleted == false
    );
  }

  MapNewPlatformService() {
    this.mapNewPlatformService = true;
  }

  OnDivisionChange() {
    this.theMarket = null;

    if (this.theDivision) {
      this.allMarketsToShow = this.allMarkets.filter(
        (x) => x.divisionId == this.theDivision.id
      );
    } else {
      this.allMarketsToShow = [];
    }

    this.allServiceGroupsToShow = [];
    this.theServiceGroup = null;
    this.allServiceCategoriesToShow = [];
    this.theServiceCategory = null;
    this.allServicesToShow = [];
    this.selectedServices = [];
  }

  OnMarketChange() {
    this.theServiceGroup = null;

    if (this.theMarket) {
      this.allServiceGroupsToShow = this.allServiceGroups.filter(
        (x) => x.operatingEntity.id == this.theMarket.id
      );
    } else {
      this.allServiceGroupsToShow = [];
    }

    this.allServiceCategoriesToShow = [];
    this.theServiceCategory = null;
    this.allServicesToShow = [];
    this.selectedServices = [];
  }

  OnServiceGroupChange() {
    this.theServiceCategory = null;

    if (this.theServiceGroup) {
      this.allServiceCategoriesToShow = this.allServiceCategories.filter(
        (x) => x.serviceGroupId == this.theServiceGroup.id
      );
    } else {
      this.allServiceCategoriesToShow = [];
    }

    this.allServicesToShow = [];
    this.selectedServices = [];
  }

  OnServiceCategoryChange() {
    this.selectedServices = [];

    if (this.theServiceCategory) {
      this.allServicesToShow = this.allServices.filter(
        (x) => x.serviceCategoryId == this.theServiceCategory.id
      );
    } else {
      this.allServicesToShow = [];
    }
  }

  DeleteMappedService(item: PlatformService) {
    this.confirmationService.confirm({
      message: "Are you sure you want to remove platform service?",
      accept: () => {
        this.messageService.add({
          severity: "info",
          summary: "Notice",
          detail: "Removing platform service...",
        });

        this.platformBenefitService.DeletePlatformService(item.id).subscribe(
          async () => {
            this.messageService.add({
              severity: "success",
              summary: "Removed",
              detail: "Removed successfully",
            });

            const index = this.allPlatformMappedServices.indexOf(item);
            if (index > -1) {
              this.allPlatformMappedServices.splice(index, 1);
            }
          },
          (error) => {
            console.log("Error: " + JSON.stringify(error));
            this.messageService.add({
              severity: "error",
              summary: "Notice",
              detail:
                "Unable to remove platform service at the moment.. Reason: [" +
                error
                  ? error.error.message
                  : "request failed - permission" + "]",
            });
          }
        );
      },
    });
  }

  MapService() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Creating Platform Service...",
    });

    const postData: CreatePlatformServiceVM = {
      platformId: this.platformInView.id,
      services: [],
    };

    this.selectedServices.forEach((serv) => {
      postData.services.push({
        platformId: this.platformInView.id,
        divisionId: this.theDivision.id,
        divisionName: this.theDivision.name,
        marketId: this.theMarket.id,
        marketName: this.theMarket.name,
        serviceGroupId: this.theServiceGroup.id,
        serviceGroupName: this.theServiceGroup.name,
        serviceCategoryId: this.theServiceCategory.id,
        serviceCategoryName: this.theServiceCategory.name,
        serviceId: serv.id,
        serviceName: serv.name,
      });
    });

    this.platformBenefitService.CreatePlatformService(postData).subscribe(
      async () => {
        this.messageService.add({
          severity: "success",
          summary: "Completed",
          detail: "Platform Service(s) Created Successfully!",
        });

        this.theDivision = null;
        this.allMarketsToShow = [];
        this.theMarket = null;
        this.allServiceGroupsToShow = [];
        this.theServiceGroup = null;
        this.allServiceCategoriesToShow = [];
        this.theServiceCategory = null;
        this.allServicesToShow = [];
        this.selectedServices = [];
        postData.services.forEach((addedServ) => {
          this.allPlatformMappedServices.push({
            serviceCategoryName: addedServ.serviceCategoryName,
            serviceCategoryId: addedServ.serviceCategoryId,
            serviceGroupName: addedServ.serviceGroupName,
            serviceGroupId: addedServ.serviceGroupId,
            marketName: addedServ.marketName,
            marketId: addedServ.marketId,
            divisionName: addedServ.divisionName,
            divisionId: addedServ.divisionId,
            platformId: addedServ.platformId,
            serviceName: addedServ.serviceName,
          });
        });
        this.GetAllPlatformWithServices();
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to create platform services at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }
}
