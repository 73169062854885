import {
  ConvergenceDevice,
  ConvergenceDeviceInteraction,
  ConvergenceInteractionType,
  CreateConvergenceDeviceVM,
  DeviceCategory,
  DeviceSubCategory,
  UpdateConvergenceDeviceVM,
} from "./../../../../interfaces/convergence";
import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { ConfirmationService, Message, MessageService } from "primeng/api";
import { BreadcrumbService } from "src/app/breadcrumb.service";
import { Supplier } from "src/app/interfaces/supplier";
import { ConvergenceService } from "src/app/services/convergence.service";
import { SupplierService } from "src/app/services/supplier.service";

@Component({
  selector: "app-device-convergence-connectivity",
  templateUrl: "./device-convergence-connectivity.component.html",
  styleUrls: ["./device-convergence-connectivity.component.scss"],
  providers: [MessageService],
})
export class DeviceConvergenceConnectivityComponent implements OnInit {
  @ViewChild("formWrapper") public formWrapper: ElementRef;
  msg: Message[] = [];
  allDevices: ConvergenceDevice[];
  selectedDevices: ConvergenceDevice[];
  deviceCols: any[];
  fetching: boolean = true;
  allDeviceCategories: DeviceCategory[];
  theDeviceCategory: DeviceCategory;
  allDeviceSubCategories: DeviceSubCategory[];
  allDeviceSubCategoriesToShow: DeviceSubCategory[];
  theDeviceSubCategory: DeviceSubCategory;
  allInteractionTypes: ConvergenceInteractionType[];
  theInteractionType: ConvergenceInteractionType;
  deviceName: string;
  editingDevice: boolean;
  deviceApiBaseUrl: string;
  apiInputParams: string[] = [];
  apiOutputParams: string[] = [];
  apiToken: string;
  addedDeviceInteractions: ConvergenceDeviceInteraction[] = [];
  allSuppliers: Supplier[];
  theSupplier: Supplier;
  deviceToEdit: ConvergenceDevice;

  constructor(
    private fb: FormBuilder,
    public supplierService: SupplierService,
    public convergenceService: ConvergenceService,
    private breadcrumbService: BreadcrumbService,
    public confirmationService: ConfirmationService,
    public messageService: MessageService
  ) {}

  ngOnInit(): void {
    this.breadcrumbService.setItems([
      {
        label: "Convergence",
      },
      {
        label: "Device Management",
      },
      {
        label: "Device Registration",
        routerLink: ["/home/convergence/device-management/connectivity"],
      },
    ]);

    this.deviceCols = [{ field: "name", header: "Name" }];

    this.FetchAllSuppliers();
    this.ResetMessageToasters();
  }

  ResetMessageToasters() {
    this.msg = [];
    this.msg.push({
      severity: "info",
      summary: "Notice:",
      detail:
        "You are required to add at least one interaction type before the device can be registered.",
    });
  }

  FetchAllSuppliers() {
    this.supplierService.getAll().subscribe(
      async (data) => {
        this.allSuppliers = data.responseData as Supplier[];
        this.FetchAllDeviceCategories();
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all suppliers at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
        this.ResetMessageToasters();
        this.fetching = false;
      }
    );
  }

  FetchAllDeviceCategories() {
    this.convergenceService.GetAllDeviceCategories().subscribe(
      async (data) => {
        this.allDeviceCategories = data;
        this.FetchAllDeviceSubCategories();
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all interaction types at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
        this.ResetMessageToasters();
      }
    );
  }

  FetchAllDeviceSubCategories() {
    this.convergenceService.GetAllDeviceSubCategories().subscribe(
      async (data) => {
        this.allDeviceSubCategories = data;
        this.FetchAllInteractionTypes();
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all device sub-categories at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
        this.ResetMessageToasters();
      }
    );
  }

  FetchAllInteractionTypes() {
    this.convergenceService.GetAllInteractionTypes().subscribe(
      async (data) => {
        this.allInteractionTypes = data;
        this.FetchAllRegisteredDevices();
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all interaction types at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
        this.ResetMessageToasters();
      }
    );
  }

  FetchAllRegisteredDevices() {
    this.fetching = true;
    this.convergenceService.GetAllConvergenceDevices().subscribe(
      async (data) => {
        this.allDevices = data;
        this.fetching = false;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all devices at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
        this.ResetMessageToasters();
        this.fetching = false;
      }
    );
  }

  Delete(item: ConvergenceDevice) {
    this.confirmationService.confirm({
      message: "Are you sure you want to remove device?",
      accept: () => {
        this.messageService.add({
          severity: "info",
          summary: "Notice",
          detail: "Removing device...",
        });

        this.convergenceService.DeleteConvergenceDevice(item.id).subscribe(
          async () => {
            this.messageService.add({
              severity: "success",
              summary: "Removed",
              detail: "Removed successfully",
            });

            this.fetching = true;
            const index = this.allDevices.indexOf(item);
            if (index > -1) {
              this.allDevices.splice(index, 1);
            }
            this.fetching = false;
            this.ResetMessageToasters();
          },
          (error) => {
            console.log("Error: " + JSON.stringify(error));
            this.messageService.add({
              severity: "error",
              summary: "Notice",
              detail:
                "Unable to remove device at the moment.. Reason: [" + error
                  ? error.error.message
                  : "request failed - permission" + "]",
            });
            this.ResetMessageToasters();
          }
        );
      },
    });
  }

  Edit(item: ConvergenceDevice) {
    this.editingDevice = true;
    this.deviceToEdit = item;
    this.deviceName = item.deviceName;
    this.theSupplier = this.allSuppliers.find((x) => x.id == item.supplierId);
    this.theDeviceCategory = this.allDeviceCategories.find(
      (x) => x.id == item.deviceCategoryId
    );
    this.theDeviceSubCategory = this.allDeviceSubCategories.find(
      (x) => x.id == item.deviceSubCategoryId
    );
    this.addedDeviceInteractions = item.deviceInteractions;

    this.formWrapper.nativeElement.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "start",
    });
  }

  OnCategoryChange() {
    if (this.theDeviceCategory)
      this.allDeviceSubCategoriesToShow = this.allDeviceSubCategories.filter(
        (x) => x.categoryId == this.theDeviceCategory.id
      );
    else this.allDeviceSubCategoriesToShow = [];
  }

  RegisterDevice() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Registering Device...",
    });

    const postData: CreateConvergenceDeviceVM = {
      deviceName: this.deviceName,
      deviceCategoryId: this.theDeviceCategory.id,
      deviceSubCategoryId: this.theDeviceSubCategory.id,
      supplierId: this.theSupplier.id,
      interactions: [],
    };
    this.addedDeviceInteractions.forEach((interact) => {
      postData.interactions.push({
        apiBaseUrl: interact.apiBaseUrl,
        inputParams: interact.inputParamsList,
        outputParams: interact.outputParamsList,
        token: interact.apiToken,
        interactionTypeId: interact.interactionTypeId,
      });
    });

    this.convergenceService.CreateConvergenceDevice(postData).subscribe(
      async () => {
        await this.messageService.add({
          severity: "success",
          summary: "Completed",
          detail: "Device Registered Successfully!",
        });

        this.ResetMessageToasters();
        this.CancelEditingDevice();
        this.FetchAllRegisteredDevices();
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to register device at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
        this.ResetMessageToasters();
      }
    );
  }

  CancelEditingDevice() {
    this.editingDevice = false;
    this.deviceName = null;
    this.theSupplier = null;
    this.theDeviceCategory = null;
    this.theDeviceSubCategory = null;
    this.addedDeviceInteractions = [];
    this.ResetAddInteraction();
    this.FetchAllRegisteredDevices();
  }

  UpdateDeviceInfo() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Updating Device...",
    });

    const id = this.deviceToEdit.id;
    const postData: UpdateConvergenceDeviceVM = {
      deviceName: this.deviceName,
      deviceCategoryId: this.theDeviceCategory.id,
      deviceSubCategoryId: this.theDeviceSubCategory.id,
      supplierId: this.theSupplier.id,
      interactions: [],
    };
    this.addedDeviceInteractions.forEach((interact) => {
      postData.interactions.push({
        id: interact.id,
        apiBaseUrl: interact.apiBaseUrl,
        inputParams: interact.inputParamsList,
        outputParams: interact.outputParamsList,
        token: interact.apiToken,
        interactionTypeId: interact.interactionTypeId,
      });
    });

    this.convergenceService.UpdateConvergenceDevice(id, postData).subscribe(
      async () => {
        this.messageService.add({
          severity: "success",
          summary: "Completed",
          detail: "Device Updated Successfully.",
        });

        this.ResetMessageToasters();
        this.CancelEditingDevice();
        this.FetchAllRegisteredDevices();
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to update device at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
        this.ResetMessageToasters();
      }
    );
  }

  ResetAddInteraction() {
    this.deviceApiBaseUrl = null;
    this.apiInputParams = [];
    this.apiOutputParams = [];
    this.apiToken = null;
    this.theInteractionType = null;
  }

  AddDeviceInteraction() {
    if (
      this.addedDeviceInteractions.find(
        (x) => x.interactionTypeId == this.theInteractionType.id
      ) != null
    ) {
      this.messageService.add({
        severity: "error",
        summary: "Notice",
        detail: "Interaction Type has already been added",
      });
      this.ResetMessageToasters();
      return;
    }

    this.addedDeviceInteractions.push({
      apiBaseUrl: this.deviceApiBaseUrl,
      inputParamsList: this.apiInputParams,
      outputParamsList: this.apiOutputParams,
      apiToken: this.apiToken,
      interactionTypeId: this.theInteractionType.id,
    });
    this.ResetAddInteraction();
  }

  GetInteractionTypeLabel(identifier: number): string {
    let interactionType = this.allInteractionTypes.find(
      (x) => x.id == identifier
    );
    if (interactionType) return interactionType.caption;

    return "N/A";
  }

  RemoveDeviceInteraction(item: ConvergenceDeviceInteraction) {
    const index = this.addedDeviceInteractions.indexOf(item);
    if (index > -1) {
      this.addedDeviceInteractions.splice(index, 1);
    }
  }

  GetCategoryLabel(identifier: number): string {
    let categ = this.allDeviceCategories.find((x) => x.id == identifier);
    if (categ) return categ.caption;

    return "N/A";
  }

  GetSubCategoryLabel(identifier: number): string {
    let subcateg = this.allDeviceSubCategories.find((x) => x.id == identifier);
    if (subcateg) return subcateg.caption;

    return "N/A";
  }

  GetSupplierLabel(identifier: number): string {
    let supplier = this.allSuppliers.find((x) => x.id == identifier);
    if (supplier) return supplier.supplierName;

    return "N/A";
  }
}
