import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { MessageService, ConfirmationService } from "primeng/api";
import { BreadcrumbService } from "src/app/breadcrumb.service";
import {
  ExpenseBatchRequest,
  ExpenseBatchRequestItem,
  ExpenseBatchRequestLogStatus,
  ExpenseLine,
  GetPayablesConfigurationsVM,
  PostExpenseBatchPayablesVM,
  UserExpenseRequestType,
} from "src/app/interfaces/finance-expense";
import {
  Account,
  ControlAccount,
  CustomerDivision,
  Division,
  ServiceCategory,
  User,
} from "src/app/interfaces/home";
import { FinanceExpenseService } from "src/app/services/finance-expense.service";
import { FireBaseAuthService } from "src/app/services/fire-base-auth.service";
import { UserService } from "src/app/services/user.service";
import domtoimage from "dom-to-image";
import { jsPDF } from "jspdf";
import { Supplier } from "src/app/interfaces/armada";
import { DeployedGuard } from "src/app/interfaces/employment";
import { CustomerDivisionService } from "src/app/services/customer-division.service";
import { GmaEmploymentService } from "src/app/services/gma-employment.service";
import { SupplierService } from "src/app/services/supplier.service";
import { DivisionService } from "src/app/services/division.service";
import { ServiceCategoryService } from "src/app/services/service-category.service";
import { AccountService } from "src/app/services/account.service";

@Component({
  selector: "app-expense-payables",
  templateUrl: "./expense-payables.component.html",
  styleUrls: ["./expense-payables.component.scss"],
})
export class ExpensePayablesComponent implements OnInit {
  @ViewChild("formWrapper") public formWrapper: ElementRef;
  fetchingExpenseBatches: boolean;
  allAuthorizedBatches: ExpenseBatchRequest[];
  selectedAuthorizedBatches: ExpenseBatchRequest[] = [];
  cols: any[];
  allUsers: User[] = [];
  openMemoDialogue: boolean;
  currentDate: string = new Date(Date.now()).toDateString();
  subject: string = "Subject";
  situation: string =
    "father abraham had many sons father abraham had many sons father abraham had many sonsfather abraham had many sonsfather abraham had many sonsfather abraham had many sonsfather abraham had many sonsfather abraham had many sonsfather abraham had many sonsfather abraham had many sons father abraham had many sons";
  justification: string =
    "father abraham had many sons father abraham had many sons father abraham had many sonsfather abraham had many sonsfather abraham had many sonsfather abraham had many sonsfather abraham had many sonsfather abraham had many sonsfather abraham had many sonsfather abraham had many sons father abraham had many sons";
  batchExpenseLines: ExpenseBatchRequestItem[] = [];
  request: string =
    "In view of the above, kindly approve 1,000,000 (one million naira only) being payment for Bayo before Oct 2022 and Nov 2022";
  batchApprovals: {
    office: string;
    officer: string;
    status: string;
  }[] = [
    {
      office: "Line Manager",
      officer: "Omoshola Yusuf",
      status: "Approved",
    },
    {
      office: "Batch Approval",
      officer: "Omoshola Yusuf",
      status: "Approved",
    },
  ];
  showDetails: boolean;
  requester: string = "Requester";
  allSuppliers: Supplier[] = [];
  allClients: CustomerDivision[] = [];
  allOperatives: DeployedGuard[] = [];
  requestDate: string = new Date(Date.now()).toDateString();
  requestType: string = "Staff";
  requestSubject: string = "Vendor Payment";
  requestRaisedBy: string = "Omoshola Yusuf";
  requestSituation: string = "Pay";
  requestJustification: string = "Pay";
  requestLineItems: ExpenseBatchRequestItem[];
  requestTotalAmt: number = 15000000;
  requestTotalVAT: number = 16000000;
  requestTotalAmtValue: number = 31000000;
  batchInView: ExpenseBatchRequest;
  allRequestTypes: {
    key: number;
    value: string;
  }[];
  allDivisions: Division[];
  allServiceCategories: ServiceCategory[];
  openDocDialogue: boolean;
  batchDocs: {
    caption: string;
    documents: string;
  }[] = [];
  allExpenseLines: ExpenseLine[];
  payableDetails: {
    index?: number;
    isTotal: boolean;
    postingAccount?: Account;
    expenseLine?: ExpenseLine;
    division?: Division;
    allCategories?: ServiceCategory[];
    category?: ServiceCategory;
    description?: string;
    drValue: number;
    crValue: number;
  }[] = [];
  totalDRValue: number = 0;
  totalCRValue: number = 0;
  showCapturePayables: boolean;
  allControlAccounts: ControlAccount[];
  allAccounts: Account[];
  allAccountInDB: Account[] = [];
  PAYABLES_CONFIGURATION: GetPayablesConfigurationsVM;

  constructor(
    public accountService: AccountService,
    public supplierService: SupplierService,
    public clientService: CustomerDivisionService,
    public employmentService: GmaEmploymentService,
    public divisionService: DivisionService,
    public serviceCategoryService: ServiceCategoryService,
    public financeExpenseService: FinanceExpenseService,
    public userService: UserService,
    public messageService: MessageService,
    private breadcrumbService: BreadcrumbService,
    public confirmationService: ConfirmationService,
    private fireBaseAuthService: FireBaseAuthService
  ) {}

  ngOnInit(): void {
    this.breadcrumbService.setItems([
      {
        label: "Finance",
        routerLink: ["/home/finance"],
      },
      {
        label: "Expense Tool",
        routerLink: ["/home/finance/expense"],
      },
      {
        label: "Payables",
        routerLink: ["/home/finance/expense/payables"],
      },
    ]);

    this.allRequestTypes = [
      {
        key: UserExpenseRequestType.Supplier,
        value: "Supplier",
      },
      {
        key: UserExpenseRequestType.Staff,
        value: "Staff",
      },
      {
        key: UserExpenseRequestType.Customer,
        value: "Customer",
      },
      {
        key: UserExpenseRequestType.Operatives,
        value: "Operative",
      },
      {
        key: UserExpenseRequestType.Miscellanous,
        value: "Miscellanous",
      },
    ];

    this.cols = [
      { field: "id", header: "Batch ID" },
      { field: "subject", header: "Subject" },
    ];

    this.payableDetails.push({
      isTotal: true,
      crValue: 0,
      drValue: 0,
    });

    this.FetchAllAccounts();
    this.FetchPayablesConfiguration();
    this.FetchAllUsers();
    this.FetchAllClients();
    this.FetchAllSuppliers();
    this.FetchAllOperatives();
    this.FetchAllDivisions();
    this.FetchAllServiceCategories();
    this.FetchAllExpenseLines();
    this.FetchAllPendingRequets();
    this.FetchAllExpenseAccounts();
  }

  async FetchAllUsers() {
    this.userService.allUser().subscribe(
      async (res) => {
        var data = res.responseData;
        this.allUsers = data;
      },
      (err) => {
        console.log(err);
        this.messageService.add({
          severity: "error",
          summary: "Failed",
          detail: err ?? "Some errors occurred",
        });
      }
    );
  }

  async FetchAllSuppliers() {
    this.supplierService.getAll().subscribe(
      async (data) => {
        this.allSuppliers = data.responseData;
      },
      (error) => {
        this.messageService.add({
          severity: "error",
          summary: "Failed",
          detail: error ?? "Some errors occurred",
        });
      }
    );
  }

  async FetchAllClients() {
    this.clientService.allCustomerDivision().subscribe(
      async (data) => {
        this.allClients = data.responseData;
      },
      (error) => {
        this.messageService.add({
          severity: "error",
          summary: "Failed",
          detail: error ?? "Some errors occurred",
        });
      }
    );
  }

  async FetchAllOperatives() {
    this.employmentService.GetAllOperativesDataOnly().subscribe(
      async (data) => {
        this.allOperatives = [];
        data.forEach((x) => {
          x.guardName =
            x.jobApplication.personalInformation.lastname +
            " " +
            x.jobApplication.personalInformation.firstname +
            " " +
            x.jobApplication.personalInformation.othername;
          this.allOperatives.push(x);
        });
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all operatives at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  async FetchAllDivisions() {
    this.divisionService.GetDivisions().subscribe(
      async (data) => {
        if (data.responseCode == "00") {
          this.allDivisions = data.responseData;
        } else {
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail: data.responseMsg,
          });
        }
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all divisions at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  async FetchAllServiceCategories() {
    this.serviceCategoryService.GetAllServiceCategories().subscribe(
      async (data) => {
        if (data.responseCode == "00") {
          this.allServiceCategories = data.responseData;
        } else {
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail: data.responseMsg,
          });
        }
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all service categories at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  async FetchAllExpenseLines() {
    this.financeExpenseService.GetAllExpenseLines().subscribe(
      async (data) => {
        if (data.responseCode != "00") {
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail: data.responseMsg,
          });
          return;
        }

        this.allExpenseLines = data.responseData;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all expense lines at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  async FetchAllPendingRequets() {
    this.fetchingExpenseBatches = true;
    this.financeExpenseService.GetAllExpenseBatchPendingPayables().subscribe(
      async (data) => {
        if (data.responseCode != "00") {
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail: data.responseMsg,
          });
          this.fetchingExpenseBatches = false;
          return;
        }

        this.allAuthorizedBatches = data.responseData;
        this.fetchingExpenseBatches = false;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all approvals info at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
        this.fetchingExpenseBatches = false;
      }
    );
  }

  async FetchAllExpenseAccounts() {
    this.allAccounts = [];
    this.financeExpenseService.GetExpenseLineAccounts().subscribe(
      async (data) => {
        if (data.responseCode == "00") {
          this.allControlAccounts = data.responseData;
          this.allControlAccounts.forEach((x) =>
            this.allAccounts.push(...x.accounts)
          );
        } else {
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail: data.responseMsg,
          });
        }
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all expense accounts at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  async FetchPayablesConfiguration() {
    this.financeExpenseService.GetPayablesConfigurations().subscribe(
      async (data) => {
        if (data.responseCode != "00") {
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail: data.responseMsg,
          });
          return;
        }

        this.PAYABLES_CONFIGURATION = data.responseData;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch payables configuration at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  async FetchAllAccounts() {
    this.accountService.allAccount().subscribe(
      async (data) => {
        this.allAccountInDB = data.responseData;
      },
      (error) => {
        this.messageService.add({
          severity: "error",
          summary: "Failed",
          detail: error ?? "Some errors occurred",
        });
      }
    );
  }

  GetUserImage(userId: number): string {
    let sf = this.allUsers.find((x) => x.id == userId);
    if (sf) return sf.imageUrl;

    return "assets/no-file.png";
  }

  GetUsername(userId: number): string {
    let sf = this.allUsers.find((x) => x.id == userId);
    if (sf)
      return (
        sf.lastName.toUpperCase() +
        " " +
        sf.firstName.toUpperCase() +
        " " +
        (sf.otherName == null ? "" : sf.otherName.toUpperCase())
      );

    return "N/A";
  }

  GetLineManagerId(item: ExpenseBatchRequest): number {
    var lineManagerLog = item.approvalLogs.find(
      (x) =>
        x.isLineManager && x.status == ExpenseBatchRequestLogStatus.Approved
    );
    return lineManagerLog.createdById;
  }

  ViewMemoFormat(item: ExpenseBatchRequest) {
    this.openMemoDialogue = true;
    this.showDetails = false;
    this.showCapturePayables = false;

    let requesterInfo = this.allUsers.find((x) => x.id == item.createdById);
    if (requesterInfo) {
      this.requester =
        requesterInfo.lastName.toUpperCase() +
        " " +
        requesterInfo.firstName.toUpperCase() +
        " " +
        (requesterInfo.otherName == null
          ? ""
          : requesterInfo.otherName.toUpperCase());
    } else this.requester = "N/A";
    this.currentDate = item.createdAt.toString().substring(0, 10);
    this.subject = item.subject;
    this.situation = item.situation;
    this.justification = item.justification;
    this.batchExpenseLines = item.lineItems;
    let sumTotal = 0;
    this.batchExpenseLines.forEach((x) => (sumTotal += x.amount + x.vatAmount));
    this.request =
      "In view of the above, kindly approve N" +
      sumTotal.toLocaleString() +
      " (" +
      this.NumInWords(sumTotal) +
      " naira only) being payment for the itemized above to " +
      this.GetEntityName(item) +
      "." +
      ". Request created by " +
      this.GetUsername(item.createdById);
    localStorage.setItem("memoName", item.subject);
    this.batchApprovals = [];
    let lineManagerLog = item.approvalLogs.find(
      (x) =>
        x.isLineManager && x.status == ExpenseBatchRequestLogStatus.Approved
    );
    this.batchApprovals.push({
      office: "Line Manager",
      officer: this.GetUsername(lineManagerLog.createdById),
      status: "Approved",
    });
    item.picks
      .filter(
        (x) =>
          x.status == ExpenseBatchRequestLogStatus.Approved && !x.isAuthorizer
      )
      .forEach((log) =>
        this.batchApprovals.push({
          office: log.approvingOfficeName,
          officer: this.GetUsername(log.createdById),
          status: "Approved",
        })
      );
    item.picks
      .filter(
        (x) =>
          x.status == ExpenseBatchRequestLogStatus.Approved && x.isAuthorizer
      )
      .forEach((log) =>
        this.batchApprovals.push({
          office: log.authorizationOfficeName,
          officer: this.GetUsername(log.createdById),
          status: "Authorized",
        })
      );
  }

  NumInWords(number: number): string {
    const first = [
      "",
      "one ",
      "two ",
      "three ",
      "four ",
      "five ",
      "six ",
      "seven ",
      "eight ",
      "nine ",
      "ten ",
      "eleven ",
      "twelve ",
      "thirteen ",
      "fourteen ",
      "fifteen ",
      "sixteen ",
      "seventeen ",
      "eighteen ",
      "nineteen ",
    ];
    const tens = [
      "",
      "",
      "twenty",
      "thirty",
      "forty",
      "fifty",
      "sixty",
      "seventy",
      "eighty",
      "ninety",
    ];
    const mad = ["", "thousand", "million", "billion", "trillion"];
    let word = "";

    for (let i = 0; i < mad.length; i++) {
      let tempNumber = number % (100 * Math.pow(1000, i));
      if (Math.floor(tempNumber / Math.pow(1000, i)) !== 0) {
        if (Math.floor(tempNumber / Math.pow(1000, i)) < 20) {
          word =
            first[Math.floor(tempNumber / Math.pow(1000, i))] +
            mad[i] +
            " " +
            word;
        } else {
          word =
            tens[Math.floor(tempNumber / (10 * Math.pow(1000, i)))] +
            "-" +
            first[Math.floor(tempNumber / Math.pow(1000, i)) % 10] +
            mad[i] +
            " " +
            word;
        }
      }

      tempNumber = number % Math.pow(1000, i + 1);
      if (Math.floor(tempNumber / (100 * Math.pow(1000, i))) !== 0)
        word =
          first[Math.floor(tempNumber / (100 * Math.pow(1000, i)))] +
          "hunderd " +
          word;
    }
    return word;
  }

  PdfDownloadPage() {
    let memoName = localStorage.getItem("memoName");
    var node = document.getElementById("memoDiv");
    var img;
    var filename;
    var newImage;
    domtoimage
      .toPng(node, { bgcolor: "#fff" })
      .then(function (dataUrl) {
        img = new Image();
        img.src = dataUrl;
        newImage = img.src;
        img.onload = function () {
          var pdfWidth = img.width;
          var pdfHeight = img.height;
          // FileSaver.saveAs(dataUrl, 'my-pdfimage.png'); // Save as Image
          var doc;
          if (pdfWidth > pdfHeight) {
            doc = new jsPDF("l", "px", [pdfWidth, pdfHeight]);
          } else {
            doc = new jsPDF("p", "px", [pdfWidth, pdfHeight]);
          }
          var width = doc.internal.pageSize.getWidth();
          var height = doc.internal.pageSize.getHeight();

          doc.addImage(newImage, "PNG", 10, 10, width, height);
          filename = memoName + " Memo" + ".pdf";
          doc.save(filename);
        };
      })
      .catch(function (error) {
        // Error Handling
      });
  }

  GetEntityName(item: ExpenseBatchRequest): string {
    if (item.requestType == UserExpenseRequestType.Customer) {
      let customer = this.allClients.find((x) => item.clientId);
      if (customer) return customer.divisionName;
    } else if (item.requestType == UserExpenseRequestType.Operatives) {
      let op = this.allOperatives.find((x) => x.id == item.operativeId);
      if (op)
        return (
          op.jobApplication.personalInformation.lastname +
          " " +
          op.jobApplication.personalInformation.firstname +
          " " +
          op.jobApplication.personalInformation.othername
        );
    } else if (item.requestType == UserExpenseRequestType.Staff) {
      return this.GetUsername(item.staffId);
    } else if (item.requestType == UserExpenseRequestType.Supplier) {
      let suppl = this.allSuppliers.find((x) => x.id == item.supplierId);
      if (suppl) return suppl.supplierName;
    } else if (item.requestType == UserExpenseRequestType.Miscellanous) {
      return "Miscellaneous";
    }

    return "N/A";
  }

  ViewBatchDetails(item: ExpenseBatchRequest) {
    this.showCapturePayables = false;
    this.requestDate = item.createdAt.toString().substring(0, 10);
    this.requestType = this.allRequestTypes.find(
      (x) => x.key == item.requestType
    ).value;
    this.requestSubject = item.subject;
    this.requestJustification = item.justification;
    this.requestSituation = item.situation;
    this.requestRaisedBy = this.GetEntityName(item);
    this.requestLineItems = item.lineItems;
    this.requestTotalAmt = 0;
    this.requestTotalVAT = 0;
    this.requestTotalAmtValue = 0;
    this.requestLineItems.forEach((lineItem) => {
      this.requestTotalAmt += lineItem.amount;
      this.requestTotalVAT += lineItem.vatAmount;
      this.requestTotalAmtValue += lineItem.amount + lineItem.vatAmount;
    });
    this.batchInView = item;
    this.showDetails = true;
  }

  LoadPayables(item: ExpenseBatchRequest) {
    if (this.PAYABLES_CONFIGURATION == null) {
      this.messageService.add({
        severity: "error",
        summary: "Notice",
        detail: "Can't load payable because configuration is missing",
      });
      return;
    }
    if (!item.supplier.payableAccountId) {
      this.messageService.add({
        severity: "error",
        summary: "Notice",
        detail:
          "Can't load payable because supplier payable account information is missing",
      });
      return;
    }
    this.batchInView = item;
    this.showCapturePayables = true;
    this.payableDetails = [];
    let totDr = 0;
    let totVat = 0;
    let percPaid = this.GetPercentagePaid(item);
    item.lineItems.forEach((lineItem) => {
      let expLine = this.allExpenseLines.find(
        (x) => x.id == lineItem.expenseLineId
      );
      let categsIds = expLine.categories.map((x) => x.serviceCategoryId);
      let amtToPay = lineItem.amount - (percPaid / 100) * lineItem.amount;
      let vatToPay = lineItem.vatAmount - (percPaid / 100) * lineItem.vatAmount;
      totDr += amtToPay + vatToPay;
      totVat += vatToPay;
      this.payableDetails.push({
        index: this.payableDetails.length,
        isTotal: false,
        postingAccount: this.allAccountInDB.find(
          (x) => x.id == expLine.accountId
        ),
        expenseLine: expLine,
        division: this.allDivisions.find((x) => x.id == expLine.divisionId),
        allCategories: this.allServiceCategories.filter((x) =>
          categsIds.includes(x.id)
        ),
        category: null,
        description: lineItem.description,
        drValue: amtToPay,
        crValue: 0,
      });
    });

    this.payableDetails.push({
      index: this.payableDetails.length,
      isTotal: false,
      postingAccount: {
        id: this.PAYABLES_CONFIGURATION.vatAccountId,
        name: this.PAYABLES_CONFIGURATION.vatAccountName,
        description: this.PAYABLES_CONFIGURATION.vatAccountDescription,
        alias: this.PAYABLES_CONFIGURATION.vatAccountAlias,
        accountNumber: this.PAYABLES_CONFIGURATION.vatAccountNumber,
      },
      expenseLine: null,
      division: null,
      allCategories: [],
      category: null,
      description: "Value Added Tax",
      drValue: totVat,
      crValue: 0,
    });

    this.payableDetails.push({
      index: this.payableDetails.length,
      isTotal: false,
      postingAccount: this.allAccountInDB.find(
        (x) => x.id == item.supplier.payableAccountId
      ),
      expenseLine: null,
      division: null,
      allCategories: [],
      category: null,
      description: "Total Payables for " + item.subject,
      drValue: 0,
      crValue: totDr,
    });

    this.payableDetails.push({
      isTotal: true,
      crValue: totDr,
      drValue: totDr,
    });
  }

  GetDivisionLabel(divisionId?: number): string {
    if (divisionId) {
      let division = this.allDivisions.find((x) => x.id == divisionId);
      if (division) return division.name;
    }

    return "N/A";
  }

  HideBatchDetails() {
    this.showDetails = false;
    this.batchInView = null;
  }

  ViewRequestAttachments() {
    this.openDocDialogue = true;
    this.batchDocs = [];
    this.batchDocs.push({
      caption: "Batch " + this.batchInView.subject,
      documents: this.batchInView.attachments,
    });
    this.batchInView.lineItems.forEach((lineItem) =>
      this.batchDocs.push({
        caption:
          lineItem.description +
          "-" +
          this.GetExpenseLineCaption(lineItem.expenseLineId),
        documents: lineItem.attachments,
      })
    );
  }

  GetExpenseLineCaption(identifier: number): string {
    let expenseLine = this.allExpenseLines.find((x) => x.id == identifier);
    if (expenseLine) return expenseLine.caption;

    return "N/A";
  }

  HideDocDialog() {
    this.openDocDialogue = false;
    this.batchDocs = [];
  }

  PostPayables() {
    this.confirmationService.confirm({
      message:
        "You are about to post payables for this batch. This is an irreversible action. Do you still wish to proceed?",
      accept: () => {
        this.messageService.add({
          severity: "info",
          summary: "Notice",
          detail: "Posting Payables...",
        });

        let usePayableDetails = this.payableDetails.filter(
          (x) =>
            !x.isTotal &&
            x.postingAccount.id != this.PAYABLES_CONFIGURATION.vatAccountId
        );
        let record = usePayableDetails.find(
          (x) =>
            x.postingAccount.id != this.batchInView.supplier.payableAccountId &&
            x.category == null
        );
        if (record != null) {
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail: "You must select the category for all expense lines",
          });
          return;
        }

        const postData: PostExpenseBatchPayablesVM = {
          batchId: this.batchInView.id,
          totalValue: this.payableDetails.find((x) => x.isTotal).drValue,
          description: "Posting " + this.batchInView.subject + " Payables",
          cashbookAccountId: this.batchInView.supplier.payableAccountId,
          wHTAccountId: this.PAYABLES_CONFIGURATION.vatAccountId,
          payables: [],
        };
        this.payableDetails
          .filter((x) => !x.isTotal)
          .forEach((payable) => {
            postData.payables.push({
              accountId: payable.postingAccount.id,
              description: payable.description,
              debitValue: payable.drValue,
              creditValue: payable.crValue,
            });
          });

        this.financeExpenseService.PostExpenseBatchPayables(postData).subscribe(
          async (data) => {
            if (data.responseCode != "00") {
              this.messageService.add({
                severity: "error",
                summary: "Notice",
                detail: data.responseMsg,
              });
              return;
            }

            this.messageService.add({
              severity: "success",
              summary: "Completed",
              detail: "Payables Posted Successfully!",
            });

            this.fetchingExpenseBatches = true;
            this.showDetails = false;
            const index = this.allAuthorizedBatches.indexOf(this.batchInView);
            if (index > -1) {
              this.allAuthorizedBatches.splice(index, 1);
            }
            this.payableDetails = [];
            this.showCapturePayables = false;
            this.batchInView = null;
            this.fetchingExpenseBatches = false;
          },
          (error) => {
            console.log("Error: " + JSON.stringify(error));
            this.messageService.add({
              severity: "error",
              summary: "Notice",
              detail:
                "Unable to post payables at the moment.. Reason: [" +
                (error ? error.error.message : "request failed - permission") +
                "]",
            });
          }
        );
      },
    });
  }

  HideMemoDialog() {
    this.openMemoDialogue = false;
    this.requester = "";
    this.currentDate = new Date(Date.now()).toDateString();
    this.subject = "";
    this.situation = "";
    this.justification = "";
    this.batchExpenseLines = [];
    this.request = "";
    this.batchApprovals = [];
  }

  GetPercentagePaid(item: ExpenseBatchRequest): number {
    let perc = 0;
    let amt = 0;
    let amtPaid = 0;
    item.lineItems.forEach((x) => (amt += x.amount + x.vatAmount));
    item.payments.forEach((x) => (amtPaid += x.amountToPay + x.vatAmount));
    perc = (amtPaid / amt) * 100;

    return perc;
  }
}
