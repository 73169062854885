import { EscalationLevelService } from "./../../../services/escalation-level.service";
import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { EscalationLevel } from "src/app/interfaces/escalation-level";
import { MessageService, ConfirmationService } from "primeng/api";

@Component({
  selector: "app-escalation-level",
  templateUrl: "./escalation-level.component.html",
  styleUrls: ["./escalation-level.component.scss"],
  providers: [ConfirmationService, MessageService],
})
export class EscalationLevelComponent implements OnInit {
  @ViewChild("formWrapper") public formWrapper: ElementRef;
  form: FormGroup;
  editing = false;
  fetching = true;
  escalationLevels: EscalationLevel[];
  selectedEscalationLevel: EscalationLevel;
  cols: any[];
  private escalationLevelToEdit: EscalationLevel;

  constructor(
    fb: FormBuilder,
    public messageService: MessageService,
    public escalationLevelService: EscalationLevelService,
    public confirmationService: ConfirmationService
  ) {
    this.form = fb.group({
      Caption: ["", Validators.required],
      Description: ["", Validators.required],
    });
  }

  ngOnInit(): void {
    this.cols = [
      { field: "caption", header: "Caption" },
      { field: "description", header: "Description" },
    ];
    this.FetchAllEscalationLevels();
  }

  FetchAllEscalationLevels() {
    this.escalationLevelService.getAll().subscribe(
      async (data) => {
        this.escalationLevels = data.responseData;
        this.fetching = false;
      },
      (error) => {
        this.fetching = false;
        this.ConnectionError();
      }
    );
  }

  CreateEscalationLevel() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Creating Escalation Level...",
    });

    const postData: EscalationLevel = {
      caption: this.form.get("Caption").value,
      description: this.form.get("Description").value,
    };

    this.escalationLevelService.create(postData).subscribe(
      async () => {
        await this.messageService.add({
          severity: "success",
          summary: "Completed",
          detail: "New Escalation Level Created...",
        });

        this.FetchAllEscalationLevels();
        this.form.reset();
      },
      (error) => {
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to create escalation level at the moment.. Reason: [" +
            JSON.stringify(error) +
            "]",
        });
      }
    );
  }

  EditEscalationLevel(item: EscalationLevel) {
    this.editing = true;
    this.form.addControl(
      "ID",
      new FormControl({ value: "", disabled: true }, Validators.required)
    );
    this.escalationLevelToEdit = item;
    this.form.patchValue({
      ID: item.id,
      Caption: item.caption,
      Description: item.description,
    });
    this.formWrapper.nativeElement.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "start",
    });
  }

  UpdateEscalationLevel() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Updating Selected Escalation Level...",
    });
    const id = this.escalationLevelToEdit.id;
    const postData: EscalationLevel = {
      caption: this.form.get("Caption").value,
      description: this.form.get("Description").value,
    };
    this.escalationLevelService.update(id, postData).subscribe(
      async () => {
        this.messageService.add({
          severity: "success",
          summary: "Notice",
          detail: "Update Successful!",
        });
        await this.FetchAllEscalationLevels();
        await this.CloseEditing();
      },
      (error) => {
        this.messageService.add({
          severity: "error",
          summary: "Unable To Update",
          detail:
            "Unable to update escalation level at the moment.. Reason: [" +
            JSON.stringify(error) +
            "]",
        });
      }
    );
  }

  DeleteEscalationLevel(item: EscalationLevel) {
    this.confirmationService.confirm({
      message: "Are you sure you want to remove " + item.caption,
      accept: () => {
        this.messageService.add({
          severity: "info",
          summary: "Notice",
          detail: "Removing escalation level " + item.caption,
        });
        this.escalationLevelService.delete(item.id).subscribe(
          async () => {
            await this.messageService.add({
              severity: "success",
              summary: "Removed",
              detail: item.caption + " removed successfully",
            });
            await this.FetchAllEscalationLevels();
          },
          (error) => {
            this.ConnectionError();
          }
        );
      },
    });
  }

  CloseEditing() {
    this.editing = false;
    this.form.reset();
    this.escalationLevelToEdit = null;
  }

  private ConnectionError() {
    this.messageService.add({
      severity: "error",
      summary: "Failed",
      detail: "Connection Error, Please try again",
    });
  }
}
