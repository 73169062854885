import { ComplaintSource } from "./../../../interfaces/complaint-source";
import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { MessageService, ConfirmationService } from "primeng/api";
import { ComplaintSourceService } from "src/app/services/complaint-source.service";

@Component({
  selector: "app-complaint-source",
  templateUrl: "./complaint-source.component.html",
  styleUrls: ["./complaint-source.component.scss"],
})
export class ComplaintSourceComponent implements OnInit {
  @ViewChild("formWrapper") public formWrapper: ElementRef;
  form: FormGroup;
  editing = false;
  fetching = true;
  complaintSources: ComplaintSource[];
  selectedComplaintSource: ComplaintSource;
  cols: any[];
  private complaintTSourceToEdit: ComplaintSource;

  constructor(
    fb: FormBuilder,
    public messageService: MessageService,
    public complaintSourceService: ComplaintSourceService,
    public confirmationService: ConfirmationService
  ) {
    this.form = fb.group({
      Code: [""],
      Caption: ["", Validators.required],
      Description: ["", Validators.required],
    });
  }

  ngOnInit(): void {
    this.cols = [
      { field: "caption", header: "Caption" },
      { field: "description", header: "Description" },
    ];
    this.FetchAllComplaintSources();
  }

  FetchAllComplaintSources() {
    this.complaintSourceService.getAll().subscribe(
      async (data) => {
        this.complaintSources = data.responseData;
        this.fetching = false;
      },
      (error) => {
        this.fetching = false;
        this.ConnectionError();
      }
    );
  }

  CreateComplaintSource() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Creating Complaint Source...",
    });

    const postData: ComplaintSource = {
      code: this.form.get("Code").value,
      caption: this.form.get("Caption").value,
      description: this.form.get("Description").value,
    };

    this.complaintSourceService.create(postData).subscribe(
      async () => {
        await this.messageService.add({
          severity: "success",
          summary: "Completed",
          detail: "New Complaint Source Created...",
        });

        this.FetchAllComplaintSources();
        this.form.reset();
      },
      (error) => {
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to create complaint source at the moment.. Reason: [" +
            JSON.stringify(error) +
            "]",
        });
      }
    );
  }

  EditComplaintSource(item: ComplaintSource) {
    this.editing = true;
    this.form.addControl(
      "ID",
      new FormControl({ value: "", disabled: true }, Validators.required)
    );
    this.complaintTSourceToEdit = item;
    this.form.patchValue({
      ID: item.id,
      Caption: item.caption,
      Description: item.description,
    });
    this.formWrapper.nativeElement.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "start",
    });
  }

  UpdateComplaintSource() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Updating Selected Complaint Source...",
    });
    const id = this.complaintTSourceToEdit.id;
    const postData: ComplaintSource = {
      code: this.form.get("Code").value,
      caption: this.form.get("Caption").value,
      description: this.form.get("Description").value,
    };
    this.complaintSourceService.update(id, postData).subscribe(
      async () => {
        this.messageService.add({
          severity: "success",
          summary: "Notice",
          detail: "Update Successful!",
        });
        await this.FetchAllComplaintSources();
        await this.CloseEditing();
      },
      (error) => {
        this.messageService.add({
          severity: "error",
          summary: "Unable To Update",
          detail:
            "Unable to update complaint source at the moment.. Reason: [" +
            JSON.stringify(error) +
            "]",
        });
      }
    );
  }

  DeleteComplaintSource(item: ComplaintSource) {
    this.confirmationService.confirm({
      message: "Are you sure you want to remove " + item.caption,
      accept: () => {
        this.messageService.add({
          severity: "info",
          summary: "Notice",
          detail: "Removing complaint source " + item.caption,
        });
        this.complaintSourceService.delete(item.id).subscribe(
          async () => {
            await this.messageService.add({
              severity: "success",
              summary: "Removed",
              detail: item.caption + " removed successfully",
            });
            await this.FetchAllComplaintSources();
          },
          (error) => {
            this.ConnectionError();
          }
        );
      },
    });
  }

  CloseEditing() {
    this.editing = false;
    this.form.reset();
    this.complaintTSourceToEdit = null;
  }

  private ConnectionError() {
    this.messageService.add({
      severity: "error",
      summary: "Failed",
      detail: "Connection Error, Please try again",
    });
  }
}
