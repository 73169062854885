import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { CommonResponse } from "../interfaces/home";
import {
  ActOnMergeRequestVM,
  ClientMergeApprovingOffice,
  ClientMergeRequest,
  CreateApprovalOfficeVM,
  CreateMergeRequestVM,
  GetClientMergeDetailsView,
  UpdateApprovalOfficeVM,
} from "../interfaces/client-merge";

@Injectable({
  providedIn: "root",
})
export class ClientMergeService {
  baseUrl = environment.baseUrl + "ClientMerge/";

  constructor(private http: HttpClient) {}

  GetAllApprovalOffices(): Observable<
    CommonResponse<ClientMergeApprovingOffice[]>
  > {
    return this.http.get<CommonResponse<ClientMergeApprovingOffice[]>>(
      this.baseUrl + "GetAllApprovalOffices"
    );
  }

  CreateApprovalOffice(
    data: CreateApprovalOfficeVM
  ): Observable<CommonResponse<boolean>> {
    return this.http.post<CommonResponse<boolean>>(
      this.baseUrl + "CreateApprovalOffice",
      data
    );
  }

  UpdateApprovalOffice(
    id: number,
    data: UpdateApprovalOfficeVM
  ): Observable<CommonResponse<boolean>> {
    return this.http.put<CommonResponse<boolean>>(
      this.baseUrl + "UpdateApprovalOffice/" + id,
      data
    );
  }

  DeleteApprovalOffice(id: number): Observable<CommonResponse<boolean>> {
    return this.http.delete<CommonResponse<boolean>>(
      this.baseUrl + "DeleteApprovalOffice/" + id
    );
  }

  MoveApprovingOfficeSequence(data: {
    sequence: number;
    goesUp: boolean;
  }): Observable<CommonResponse<boolean>> {
    return this.http.post<CommonResponse<boolean>>(
      this.baseUrl + "MoveApprovingOfficeSequence",
      data
    );
  }

  CreateMergeRequest(data: CreateMergeRequestVM): Observable<CommonResponse> {
    return this.http.post<CommonResponse>(
      this.baseUrl + "CreateMergeRequest",
      data
    );
  }

  GetPendingRequestsToApprove(): Observable<
    CommonResponse<ClientMergeRequest[]>
  > {
    return this.http.get<CommonResponse<ClientMergeRequest[]>>(
      this.baseUrl + "GetPendingRequestsToApprove"
    );
  }

  ActOnMergeRequest(data: ActOnMergeRequestVM): Observable<CommonResponse> {
    return this.http.post<CommonResponse>(
      this.baseUrl + "ActOnMergeRequest",
      data
    );
  }

  GetClientMergeDetails(
    clientId: number
  ): Observable<CommonResponse<GetClientMergeDetailsView>> {
    return this.http.get<CommonResponse<GetClientMergeDetailsView>>(
      this.baseUrl + "GetClientMergeDetails/" + clientId
    );
  }
}
