import { SupplierCategoryService } from "./../../services/supplier-category.service";
import { Supplier } from "./../../interfaces/armada";
import { StateService } from "./../../services/state.service";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { ElementRef, ViewChild } from "@angular/core";
import { Component, OnInit } from "@angular/core";
import { ConfirmationService, MessageService } from "primeng/api";
import { CommonResponse, Lga, State } from "src/app/interfaces/home";
import { BreadcrumbService } from "src/app/breadcrumb.service";
import { SupplierService } from "src/app/services/supplier.service";
import { SupplierCategory } from "src/app/interfaces/supplier";
import { ContactsService } from "../contacts/contacts.service";
import { Contact } from "src/app/interfaces/contact";

@Component({
  selector: "app-supplier",
  templateUrl: "./supplier.component.html",
  styleUrls: ["./supplier.component.scss"],
})
export class SupplierComponent implements OnInit {
  @ViewChild("formWrapper") public formWrapper: ElementRef;
  form: FormGroup;
  editing = false;
  fetching = true;
  suppliers: Supplier[];
  contacts: Contact[];
  selectedSupplier: Supplier;
  supplierCategories: SupplierCategory[];
  supplierCategory: SupplierCategory;
  genders = [
    {
      name: "Male",
    },
    {
      name: "Female",
    },
    {
      name: "Not Specified",
    },
  ];
  gender: any;
  states: State[];
  theState: State;
  theContact: Contact[];
  theContact2: string;
  lgas: Lga[];
  theLga: Lga;
  cols: any[];
  private supplierToEdit: Supplier;

  constructor(
    fb: FormBuilder,
    public messageService: MessageService,
    public supplierService: SupplierService,
    public stateService: StateService,
    private breadcrumbService: BreadcrumbService,
    public confirmationService: ConfirmationService,
    private contactService: ContactsService,
    public supplierCategoryService: SupplierCategoryService
  ) {
    this.form = fb.group({
      Name: ["", Validators.required],
      Description: ["", Validators.required],
      Category: ["", Validators.required],
      Email: ["", Validators.compose([Validators.email, Validators.required])],
      Mobile: ["", Validators.required],
      State: ["", Validators.required],
      LGA: ["", Validators.required],
      Street: ["", Validators.required],
      Address: ["", Validators.required],
      ImageUrl: [""],
      Contacts: [""],
    });
  }

  ngOnInit(): void {
    this.breadcrumbService.setItems([
      { label: "Suppliers" },
      { label: "Supplier", routerLink: ["home/supplier"] },
    ]);

    this.cols = [
      { field: "name", header: "Name" },
      { field: "description", header: "Description" },
      { field: "category", header: "Categories" },
      { field: "email", header: "Email" },
      { field: "mobile", header: "Mobile" },
      { field: "state", header: "State" },
      { field: "lga", header: "LGA" },
      { field: "street", header: "Street" },
      { field: "address", header: "Address" },
      { field: "primaryContactName", header: "PrimaryContactName" },
      { field: "primaryContactEmail", header: "PrimaryContactEmail" },
      { field: "primaryContactMobile", header: "PrimaryContactMobile" },
      { field: "primaryContactGender", header: "PrimaryContactGender" },
    ];

    this.fetchAllStates();
    this.fetchAllSupplierCategories();
    this.fetchAllSuppliers();
    this.fetchAllContacts();
    this.onChanges();
  }

  onChanges(): void {
    this.form.get('Street').valueChanges.subscribe(val => {
      if (this.theLga) {
        let address: string = `${val}, ${this.theLga.name}, ${this.theState.name}`;
        this.form.controls.Address.setValue(address);

      }
    });
    this.form.get('LGA').valueChanges.subscribe(val => {
      if (this.theLga) {
        let street = this.form.controls.Street.value;
        let address: string = `${street}, ${val.name}, ${this.theState.name}`;
        this.form.controls.Address.setValue(address);

      }
    });
  }

  fetchAllSuppliers() {
    this.supplierService.getAll().subscribe(
      async (supplierCategories: CommonResponse) => {
        this.suppliers = supplierCategories.responseData;
        this.fetching = false;
      },
      (error) => {
        this.fetching = false;
        this.connectionError();
      }
    );
  }

  fetchAllSupplierCategories() {
    this.supplierCategoryService.getAll().subscribe(
      async (supplierCategories: CommonResponse) => {
        this.supplierCategories = supplierCategories.responseData;
        this.fetching = false;
      },
      (error) => {
        this.fetching = false;
        this.connectionError("Could Not Fetch All Supplier Categories");
      }
    );
  }

  populateLGAS() {
    this.stateService.getByID(this.theState.id).subscribe(
      async (state: CommonResponse) => {
        this.lgas = state.responseData?.lgAs;
      },
      (error) => {
        this.connectionError("Could not populate LGA's");
      }
    );
  }

  setContact() {
    //construct comma separated string of ids 
    let contacts: string = "";
    let ids: number[] = this.theContact.map(x => x.id);
    contacts = ids.join(",");
    this.theContact2 = contacts;
  }

  fetchAllStates() {
    this.stateService.allState().subscribe(
      async (states: CommonResponse) => {
        this.states = states.responseData;
      },
      (error) => {
        this.connectionError("Could not fetch all states");
      }
    );
  }

  fetchAllContacts() {
    this.contactService.getEntireContactList().subscribe(
      async (contacts: any) => {
        this.contacts = contacts?.responseData;
        this.fetching = false;
      },
      (error) => {
        this.fetching = false;
        this.connectionError();
      }
    );
  }

  createSupplier() {

    if (!this.form.valid) return;

    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Creating Supplier...",
    });

    const postData: Supplier = {
      supplierName: this.form.get("Name").value,
      description: this.form.get("Description").value,
      supplierCategoryId: this.supplierCategory.id,
      supplierEmail: this.form.get("Email").value,
      mobileNumber: this.form.get("Mobile").value,
      stateId: this.theState.id,
      lgaId: this.theLga.id,
      street: this.form.get("Street").value,
      address: this.form.get("Address").value,
      imageUrl: this.form.get("ImageUrl").value,
      contacts: this.theContact2
    };

    this.supplierService.create(postData).subscribe(
      async (res) => {
        if (res.responseCode != "00") {
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail:
              "Unable to create supplier at the moment.. Reason: [" +
              res.responseMsg +
              "]",
          });
        } else {
          await this.messageService.add({
            severity: "success",
            summary: "Completed",
            detail: "New Supplier Created...",
          });
          this.form.reset();
          this.fetchAllSuppliers();

        }
      },
      (error) => {
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to create supplier at the moment.. Reason: [" +
            JSON.stringify(error) +
            "]",
        });
      }
    );
  }

  deleteSupplier(item: Supplier) {
    this.confirmationService.confirm({
      message: "Are you sure you want to remove " + item.supplierName,
      accept: () => {
        this.messageService.add({
          severity: "info",
          summary: "Notice",
          detail: "Removing supplier " + item.supplierName,
        });
        this.supplierService.delete(item.id).subscribe(
          async () => {
            await this.messageService.add({
              severity: "success",
              summary: "Removed",
              detail: item.supplierName + " removed successfully",
            });
            await this.fetchAllSuppliers();
          },
          (error) => {
            this.connectionError();
          }
        );
      },
    });
  }

  editSupplier(item: Supplier) {
    this.editing = true;
    this.form.addControl(
      "ID",
      new FormControl({ value: "", disabled: true }, Validators.required)
    );
    this.supplierToEdit = item;

    this.form.patchValue({
      ID: item.id,
      Name: item.supplierName,
      Description: item.description,
      Category: item.supplierCategoryId,
      Email: item.supplierEmail,
      Mobile: item.mobileNumber,
      State: item.stateId,
      LGA: item.lgaId,
      Street: item.street,
      Address: item.address,
      ImageUrl: item.imageUrl,
      Contacts: item.contacts
    });

    this.supplierCategory = this.supplierCategories.find(
      (supCateg) => supCateg.id === item.supplierCategoryId
    );
    this.theState = this.states.find((stat) => stat.id === item.stateId);
    this.populateLGAS();
    this.theLga = this.theState.lgAs.find((lg) => lg.id === item.lgaId);

    let foundContacts: Contact[] = item.contacts.split(',').map(x => {
      return this.contacts.find(y => y.id === parseInt(x));
    });
    this.theContact = foundContacts;
    this.setContact();

    this.formWrapper.nativeElement.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "start",
    });
  }

  updateSupplier() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Updating Selected Supplier...",
    });
    const id = this.supplierToEdit.id;
    const postData: Supplier = {
      supplierName: this.form.get("Name").value,
      description: this.form.get("Description").value,
      supplierCategoryId: this.supplierCategory.id,
      supplierEmail: this.form.get("Email").value,
      mobileNumber: this.form.get("Mobile").value,
      stateId: this.theState.id,
      lgaId: this.theLga.id,
      street: this.form.get("Street").value,
      address: this.form.get("Address").value,
      imageUrl: this.form.get("ImageUrl").value,
      contacts: this.theContact2
    };

    this.supplierService.update(id, postData).subscribe(
      async (res) => {
        if (res.responseCode != "00") {
          this.messageService.add({
            severity: "error",
            summary: "Unable To Update",
            detail:
              "Unable to update supplier at the moment.. Reason: [" +
              res.responseMsg +
              "]",
          });
        } else {
          this.messageService.add({
            severity: "success",
            summary: "Notice",
            detail: "Update Successful!",
          });
          await this.fetchAllSuppliers();
          await this.closeEditing();
        }
      },
      (error) => {
        this.messageService.add({
          severity: "error",
          summary: "Unable To Update",
          detail:
            "Unable to update supplier at the moment.. Reason: [" +
            JSON.stringify(error) +
            "]",
        });
      }
    );
  }

  closeEditing() {
    this.editing = false;
    this.form.reset();
    this.supplierToEdit = null;
  }

  private connectionError(message: string = "") {
    this.messageService.add({
      severity: "error",
      summary: "Failed",
      detail: "Connection Error, Please try again " + message,
    });
  }
}
