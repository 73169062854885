import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";
import { EventInput } from "@fullcalendar/core";
import { AngularFireStorage } from "@angular/fire/storage";
import { MessageService } from "primeng/api";
import { QuoteServiceDocumentService } from "../../../../services/quote-service-document.service";
import {
  CommonResponse,
  QuoteServiceDocument,
} from "../../../../interfaces/home";
import { FileStorageService } from "src/app/services/file-storage.service";

@Component({
  selector: "app-service-document-form",
  templateUrl: "./service-document-form.component.html",
  styleUrls: ["./service-document-form.component.scss"],
})
export class ServiceDocumentFormComponent implements OnInit {
  serviceDocumentForm: FormGroup;
  displayLoading: boolean;
  public documentFIle: any[] = [];
  private imageSrc: string;
  public documentDownloadURL: any;
  private fb: any;
  private selectedServiceDocument: QuoteServiceDocument;
  public uploadStatus: string;
  message: { type: string; value: string };
  isGroupUpload: boolean = false;
  quoteId: number = 0;

  constructor(
    public modalRef: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private formBuilder: FormBuilder,
    private fileStorageService: FileStorageService,
    public messageService: MessageService,
    public quoteServiceDocumentService: QuoteServiceDocumentService
  ) {
    this.displayLoading = false;
  }

  ngOnInit(): void {
    this.selectedServiceDocument = {};
    this.serviceDocumentForm = this.formBuilder.group({
      caption: ["", Validators.required],
      description: ["", Validators.required],
      documentUrl: ["", Validators.required],
      quoteServiceId: [""],
      type: [""],
    });
    this.config.closable = true;
    this.config.closeOnEscape = false;
    if (this.config.data) {
      const _quoteServiceId = this.config.data.quoteServiceId;
      const _document = this.config.data.document;
      this.isGroupUpload = this.config.data.isGroupUpload;
      this.quoteId = this.config.data.quoteId;

      this.selectedServiceDocument = _document;
      this.serviceDocumentForm.patchValue({
        caption: _document.caption,
        quoteServiceId: _quoteServiceId,
      });
    }
  }

  async submitServiceDocument() {
    if (this.serviceDocumentForm.value) {
      // check if picture is selected
      this.displayLoading = true;
      if (this.documentFIle.length > 0) {
        this.fileStorageService.UploadMultipleFilesFromDataUrl(
          this.documentFIle
        );

        this.fileStorageService.onUploadFinished.subscribe(
          (resp: CommonResponse<string[]>) => {
            if (resp.responseCode != "00") {
              this.messageService.add({
                severity: "error",
                summary: "Notice",
                detail: resp.responseMsg,
              });
            } else {
              var imageUrl = resp.responseData;
              this.documentDownloadURL = imageUrl[0];
              this.saveServiceDocument();
            }
          },
          (error) => {
            console.log("Error while creating platform " + error);
            this.messageService.add({
              severity: "error",
              summary: "Notice",
              detail: "ERR: Unable to upload image to storage",
            });
          }
        );
      }
    }
  }
  async saveServiceDocument() {
    const postData = {
      type: this.selectedServiceDocument.type,
      description: this.serviceDocumentForm.value.description,
      caption: this.selectedServiceDocument.caption,
      documentUrl: this.documentDownloadURL,
      quoteServiceId: this.serviceDocumentForm.value.quoteServiceId,
      isGroupUpload: this.isGroupUpload,
      quoteId: this.quoteId,
    };

    this.quoteServiceDocumentService
      .postQuoteServiceDocument(postData)
      .subscribe((res) => {
        this.displayLoading = false;
        this.uploadStatus = "complete";
        this.message = { type: "success", value: "Document uploaded" };
        this.modalRef.close("complete");
      });
  }
  onFileSelect(event) {
    if (!this.checkMimeType(event) || !this.checkFileSize(event)) {
      this.serviceDocumentForm.get("documentUrl").reset();
      return;
    }
    const reader = new FileReader();
    this.message = null;
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      this.documentFIle.push(event.target.files[0]);
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.imageSrc = reader.result as string;
      };
    }
  }

  checkMimeType(event) {
    const files = event.target.files[0];
    // list allow mime type
    const types = [
      "application/msword",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      "application/pdf",
    ];
    // loop access array
    if (!files) {
      this.messageService.add({
        severity: "error",
        summary: "Failed",
        detail: "Please select a file to continue",
      });
      this.message = {
        type: "danger",
        value: "Please select a file to continue",
      };
      return false;
    }
    // compare file type find doesn't match
    if (types.every((type) => files.type !== type)) {
      // create error message and assign to container
      this.messageService.add({
        severity: "error",
        summary: "Failed",
        detail: "Document format not supported",
      });
      this.message = { type: "danger", value: "Document format not supported" };
      return false;
    }
    return true;
  }

  checkFileSize(event: EventInput) {
    const files = event.target.files[0];
    if (!files) return true;
    const size = 1500000;
    if (files.size > size) {
      event.target.value = null;
      this.messageService.add({
        severity: "error",
        summary: "Failed",
        detail: "Document is too large, please pick a smaller file",
      });
      this.message = {
        type: "danger",
        value: "Document is too large, please pick a smaller file",
      };
      return false;
    }
    return true;
  }
}
