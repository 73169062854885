import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {Observable} from 'rxjs';
import {CommonResponse, Customer} from '../interfaces/home';
import { shareReplay } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class CustomerService {

    observablesCache: any = {};

    constructor(private http: HttpClient) { }
    baseUrl = environment.baseUrl+'Customer/';
    // fetch all customer in the database
    allCustomer(): Observable<CommonResponse>{
        return this.http.get<CommonResponse>(this.baseUrl);
    }
    getCustomer(id: any):Observable<CommonResponse>{
        return this.http.get<CommonResponse>(this.baseUrl +id);
    }
    getCustomerByName(name: any):Observable<CommonResponse>{
        return this.http.get<CommonResponse>(this.baseUrl + 'name/' + name);
    }
    postCustomer(data: any){
        return this.http.post(this.baseUrl, data);
    }
    deleteCustomer(id: any){
        return this.http.delete(this.baseUrl +id);
    }
    updateCustomer(id: any, data: any){
        return this.http.put(this.baseUrl +id, data);
    }
    fetchByGroupType(groupId: number){
        if(this.observablesCache[groupId]) return this.observablesCache[groupId]
        else {
            this.observablesCache[groupId] = this.http.get(this.baseUrl +'GroupType/'+groupId).pipe(shareReplay()); 
            return this.observablesCache[groupId];
        }
    }
}
