import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/storage';
import { FormBuilder } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { async } from 'rxjs/internal/scheduler/async';
import { finalize } from 'rxjs/operators';
import { AppTopBarComponent } from 'src/app/app.topbar.component';
import { Product } from 'src/app/demo/domain/product';
import { EventService } from 'src/app/demo/service/eventservice';
import { ProductService } from 'src/app/demo/service/productservice';
import { Contact, User } from 'src/app/interfaces/home';
import { FireBaseAuthService } from 'src/app/services/fire-base-auth.service';
import { UserService } from 'src/app/services/user.service';
import { ContactsService } from '../contacts/contacts.service';
import { EngagementActivitiesService } from '../engagement-activities/engagement-activities.service';
import { SendMailService } from './send-mail.service';

@Component({
  selector: 'app-send-mail',
  templateUrl: './send-mail.component.html',
  styleUrls: ['./send-mail.component.scss']
})
export class SendMailComponent implements OnInit {
  @ViewChild(AppTopBarComponent) topBar;
  @Input() authUser: any; 
  SuspectInstance: any;
  defaultImage = "https://www.freeiconspng.com/uploads/profile-icon-9.png"
  subscriber: any;
  uploadedFiles: any[] = [];
  selectCategory=null;
  newId: string;
  suspectContact: any[]=[];
  productDialog: boolean;
  workspaceIsLoading=false;
  meetingList: any[]=[];
  position: string;
  meetingTypes: { name: string; value: number; }[];
  status: { name: string; value: number; }[];
  displayPosition=false;
  senderOptions: { name: string; value: number; }[];
  categoryValue: number;
  users: User[];
  contactList:Contact[];
  showAttachFiles=false;
  fileToBeUploaded: any;
  imageUrl: any;
  myfiles: any[]=[];
  fileTobeSaved: any[]=[];
  uploadedFile: any[]=[];
  destinationEmail: any[]=[];
  emailBody:any;
  emailSubject:any;
  destinationId: any;
  formData:any[]=[];
  currentAudit: any;
  allMail: any[];
  selectedContacts:any[]=[];
  selectedStaffs:any[]=[];
  destinationEmails:any[]=[];
  mailInfo: any;
  showInfo: boolean;
  destinationUsers: User[]=[];


  constructor(
    public engagementService:EngagementActivitiesService,
    private route: ActivatedRoute,
    public confirmationService: ConfirmationService,
    public dialogService: DialogService,
    public contactsService:ContactsService,
    public messageService: MessageService,
    public sendMailService:SendMailService,
    private sanitizer: DomSanitizer,
    private eventService: EventService,
    private userService:UserService,
    private formBuilder: FormBuilder,
    productService: ProductService,
    public fireBaseAuthService: FireBaseAuthService,
    public fireStorage: AngularFireStorage,
  ) { }

  ngOnInit(): void {
    this.subscriber = this.route.paramMap.subscribe((params) => {
      this.newId = params.get("id");
      this.getSuspectById(this.newId);
      this.getAllContactsToSuspect(this.newId);
      this.getMeetings(this.newId);
      this.getAllContacts();
      this.getAllUsers();
      this.getAllMails();
      this.authUser = this.fireBaseAuthService?.authUserProfile;
		  // this.getCurrentloggedInUser(this.authUser);
    });
    console.log(this.newId);
    console.log("This is authUser ",this.authUser);

    this.meetingTypes = [
      { name: "Webinar", value: 1 },
      { name: "Seminar", value: 2 },
      { name: "Meet And Greet", value: 3 },
      { name: "CloseOut", value: 4},
      { name: "Presentation", value: 5 },
      { name: "Negotiations", value: 6 },
      { name: "Product Briefing", value: 7 },
    ];

    this.status=[
      {name: "True",value:1},
      {name: "False",value:2}
    ]

    this.senderOptions=[
      {name: "Contact List",value:1},
      {name: "Staff List",value:2}
    ]

    // this.selectCategory.value = 1;
    
  }


  pushFileToStorage(event) {
    this.messageService.add({
      severity: "success",
      summary: "Notice",
      detail: "Illustration Image has been uploaded",
    });
    this.fileToBeUploaded = event.files[0] as File;
    console.log("this is eventfiles", event.files[0]);
    this.uploadedFile.push(this.fileToBeUploaded)
    const formData: FormData = new FormData();
    formData.append('file', this.fileToBeUploaded);
    this.formData.push(formData);
    console.log("This is the list of formData",this.formData);
    this.fileTobeSaved.push(this.imageUrl)
    this.myfiles = [];
  }

  showEmail(e){
    console.log("This is the detail of the mail",e);
    this.mailInfo = e
    this.showInfo = true;
  }
  
  getAllContacts(){
    this.contactsService.getContactList()
    .subscribe(async(data:any)=>{
      console.log("This is the list of contact",data.responseData);
      this.contactList = data.responseData;
      this.contactList.forEach(el=>{
        el.fullname = el.firstName + ' ' + el.lastName
        el.imageUrl = el.profilePicture
       })
    })
  }


  removeFile(file){
    console.log("this is the file selected",file)
    var newArray = this.uploadedFile.filter((item) => item.name !== file.name);
    this.uploadedFile = newArray;
  }
  

  getAllUsers(){
    this.userService.allUser()
    .subscribe(async(data:any)=>{
     this.users = data.responseData;
     this.users.forEach(el=>{
      el.fullname = el.firstName + ' ' + el.lastName
     })
     console.log("This is the list of users",this.users);
    })
  }

  getMeetingType(id){
    return this.meetingTypes.find(x=>x.value == id);
  }

  removeFromContact(contact,contactList){
   var newContactList = contactList.filter((el)=> el.id !== contact.id)
   this.selectedContacts = newContactList;
   this.selectedContacts.forEach((e)=>{
    this.destinationEmails.push(e.email);
  })
  }

  removeFromStaff(staff,staffList){
    var newStaffList = staffList.filter((el)=> el.id !== staff.id)
    this.selectedStaffs = newStaffList;
    this.selectedStaffs.forEach((e)=>{
     this.destinationEmails.push(e.email);
   })
   }

  toggleBetweenCategories(e){
        if(this.selectCategory == 1 ){
          this.categoryValue = 1;
          //this.selectedStaffs = [];
          console.log("this is what i chose  ",this.categoryValue)
        }
        else if(this.selectCategory == 2){
          this.categoryValue = 2;
          //this.selectedContacts = [];
          console.log("this is what i chose  ",this.categoryValue)
        }
  }

  discardMail(){
    this.displayPosition = false
    this.selectCategory = null;
    this.selectedContacts = [];
    this.selectedStaffs = [];
    this.destinationEmails = [];
    this.destinationUsers = [];
    this.emailBody = null;
    this.emailSubject = null;
  }


  sendEmail(){
    this.workspaceIsLoading = true;
    this.selectedContacts?.forEach(el=>{
      this.destinationEmail.push(el.email);
    })
    this.selectedStaffs?.forEach(el=>{
      this.destinationEmail.push(el.email);
    })
    console.log("This is destinationEmail",this.destinationEmail)
    const payload = {
       emailsender: this.authUser.email,
       emailSenderName:this.authUser.firstName + " " + this.authUser.lastName,
       emailReceivers: this.destinationEmails,
       emailSubject:this.emailSubject,
       emailBody:this.emailBody,
       destinationId:this.destinationId,
       attachments:this.formData
    }
    console.log("this is the mail info",payload);
    this.sendMailService.SendEmail(payload)
    .subscribe(async(data:any)=>{
      console.log("this is the result ",data);
      if(data.responseCode = "00"){
        this.currentAudit = data.responseData;
        this.getAllMails();
        this.messageService.add({
          severity: "success",
          summary: "Notice",
          detail: "Mail was sent successfully to " + this.destinationEmail,
        });
        this.displayPosition = false;
        this.workspaceIsLoading = false;
        this.discardMail();
      }
      else{
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail: "Could not send mail to " + this.destinationEmail,
        });
        this.workspaceIsLoading = false;
      }
    },error=>{
      this.messageService.add({
        severity: "error",
        summary: "Notice",
        detail: "An error occurred",
      });
      this.workspaceIsLoading = false;
    })

  }

  getAllMails() {
    this.sendMailService
      .getAllMail()
      .subscribe(async (data: any) => {
        if (data.responseCode == "00") {
          this.allMail = data.responseData
          console.log("this are the mails", this.allMail);
          this.messageService.add({
            severity: "success",
            summary: "Notice",
            detail: "All mails were fectched successsfully",
          });
        }
      });
  }

  disableMail(e){
    this.sendMailService.removeMail(e.id)
    .subscribe(async(data:any)=>{
      if(data.responseCode == "00"){
        this.messageService.add({
          severity: "success",
          summary: "Notice",
          detail: "Successfully removed " + e.subject,
        });
        this.getAllMails();
      }
      else{
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail: data.responseMsg,
        });
      }
    })
  }

  selectDestination(e){
   
    if(this.categoryValue == 1){
      //this.selectedContacts = []
      this.selectedContacts = e.value
      console.log("This is what was selected on contacts block ",this.selectedContacts)
      this.destinationEmails = []
      this.selectedContacts.forEach((e)=>{
        this.destinationEmails.push(e.email);
      })
      //this.selectedStaffs = [];
    }
    else{
      this.selectedStaffs = [];
      this.selectedStaffs = e.value
      console.log("This is what was selected on staffs block ",this.selectedStaffs)
      this.destinationEmails = []
      this.selectedStaffs.forEach((e)=>{
        this.destinationEmails.push(e.email);
      })
      //this.selectedContacts = [];
    }
  }


  showPositionDialog(position: string) {
    this.position = position;
    this.displayPosition = true;
}

  getSuspectById(id) {
    this.engagementService
      .getSuspectById(id)
      .subscribe(async (data: any) => {
        if (data.responseCode == "00") {
          this.SuspectInstance = data.responseData
          console.log("this is leads new", this.SuspectInstance);
          this.messageService.add({
            severity: "success",
            summary: "Notice",
            detail: "All leads were fectched successsfully",
          });
        }
      });
  }

  getMeetings(id) {
    this.workspaceIsLoading = true;
    this.engagementService
      .getMeetingsById(id)
      .subscribe(async (data: any) => {
        if (data.responseCode == "00") {
          this.meetingList = data.responseData
          console.log("this is leads array", this.meetingList);
          this.messageService.add({
            severity: "success",
            summary: "Notice",
            detail: "All meetings were fectched successsfully",
          });
          this.workspaceIsLoading = false;
        }
      });
  }

  getAllContactsToSuspect(id) {
    this.engagementService.getAllContactsAttachedToSuspects(id).subscribe(
      async (res: any) => {
        console.log("This is from suspectContacts",res.responseData);
        if (res.responseCode == "00") {
          this.suspectContact = [];
          this.suspectContact = res.responseData.filter(x => x.contact.isDeleted == false);
        } else {
          this.suspectContact = [];
        }
      },
      (error) => {
        this.suspectContact = [];
      }
    );
  }

}
