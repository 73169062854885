import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import {
  CommonResponse,
  Division,
  OperatingEntity,
  ServiceCategory,
  ServiceGroup,
} from "../../../interfaces/home";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { ServiceCategoryService } from "../../../services/service-category.service";
import { ConfirmationService, MessageService } from "primeng/api";
import { OperatingEntityService } from "../../../services/operating-entity.service";
import { DivisionService } from "../../../services/division.service";
import { Observable } from "rxjs";
import { EventInput } from "@fullcalendar/core";
import { environment } from "src/environments/environment";
import { FileStorageService } from "src/app/services/file-storage.service";

@Component({
  selector: "app-service-category",
  templateUrl: "./service-category.component.html",
  styleUrls: ["./service-category.component.scss"],
})
export class ServiceCategoryComponent implements OnInit {
  @ViewChild("formWrapper") public formWrapper: ElementRef;
  fetchingServiceCategory = true;
  operatingEntities: OperatingEntity[];
  serviceCategoryForm: FormGroup;
  serviceCategories: ServiceCategory[];
  selectedServiceCategory: ServiceCategory;
  editingServiceCategory = false;
  public serviceCategoryCols: any[];
  public exportServiceCategoryColumns: any[];
  selectedOperatingEntityId: OperatingEntity;
  private edServiceCategory: ServiceCategory;
  selectedDivisionId: Division;
  divisions: Division[];
  serviceGroups: ServiceGroup[];
  selectedServiceGroupId: ServiceGroup;
  file: any;
  downloadURL: Observable<string>;
  serviceImg: any;
  isCreateService = false;
  constructor(
    private formBuilder: FormBuilder,
    private serviceCategoryService: ServiceCategoryService,
    public messageService: MessageService,
    public confirmationService: ConfirmationService,
    private operatingEntityService: OperatingEntityService,
    private divisionService: DivisionService,
    private fileStorageService: FileStorageService
  ) {}

  ngOnInit(): void {
    this.serviceImg = environment.defaultServiceImage;

    this.fetchServiceCategory();
    this.serviceCategoryForm = this.formBuilder.group({
      name: ["", Validators.required],
      description: ["", Validators.required],
      divisionId: [null, [Validators.required]],
      serviceGroupId: [null, [Validators.required]],
      operatingEntityId: [null, Validators.required],
    });
    this.serviceCategoryCols = [
      { field: "name", header: "Name" },
      { field: "description", header: "Description" },
      { field: "operatingEntity", header: "Operating Entity" },
      { field: "services", header: "services" },
    ];
    this.exportServiceCategoryColumns = this.serviceCategoryCols.map((col) => ({
      title: col.header,
      dataKey: col.field,
    }));
    this.divisionService
      .allDivisionData()
      .subscribe(async (result: CommonResponse) => {
        this.divisions = result.responseData;
      });
    this.operatingEntityService
      .allData()
      .subscribe(async (res: CommonResponse) => {
        if (res.responseCode == "00") {
          this.operatingEntities = res.responseData;
        }
      });
  }
  fetchServiceCategory() {
    this.serviceCategoryService.allServiceCategory().subscribe(
      async (serviceCategory: CommonResponse) => {
        this.serviceCategories = serviceCategory.responseData;
        this.fetchingServiceCategory = false;
      },
      (error) => {
        this.fetchingServiceCategory = false;
        this.connectionError();
      }
    );
  }
  setOperationEntity() {
    if (this.selectedDivisionId) {
      this.operatingEntities = this.selectedDivisionId.operatingEntities;
    }
  }
  setServiceGroup() {
    if (this.selectedOperatingEntityId) {
      this.serviceGroups = this.selectedOperatingEntityId.serviceGroups;
    }
  }
  private connectionError() {
    this.messageService.add({
      severity: "error",
      summary: "Failed",
      detail: "Connection Error, Please try again",
    });
  }
  createServiceCategory() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Creating new Service Category Records",
    });
    const postData = {
      name: this.serviceCategoryForm.get("name").value,
      description: this.serviceCategoryForm.get("description").value,
      operatingEntityId: this.selectedOperatingEntityId.id,
      divisionId: this.selectedDivisionId.id,
      serviceGroupId: this.selectedServiceGroupId.id,
    };

    this.isCreateService = true;
    if (this.file) this.fetchImageURL(postData);
    else this._create(postData);
  }

  private _create(postData) {
    this.serviceCategoryService.postServiceCategory(postData).subscribe(
      async (r: CommonResponse) => {
        if (r.responseCode == "00") {
          await this.messageService.add({
            severity: "success",
            summary: "Completed",
            detail: "New Service Category Created",
          });
          await this.fetchServiceCategory();
          await this.serviceCategoryForm.reset();
        } else {
          this.messageService.add({
            severity: "error",
            summary: "Failure",
            detail: r.responseMsg,
          });
        }
      },
      (error) => {
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail: "Unable to create new Service Category at the moment",
        });
      }
    );
  }

  deleteServiceCategory(serviceCategory) {
    this.confirmationService.confirm({
      header: "Warning",
      message:
        'Are you sure you want to drop <span class="text-danger">' +
        serviceCategory.name +
        "</span>" +
        ". All services tied to it will also be dropped",
      accept: () => {
        this._deleteServiceCategory(serviceCategory);
      },
    });
  }
  _deleteServiceCategory(serviceCategory) {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Droping service Group record",
    });
    this.serviceCategoryService
      .deleteServiceCategory(serviceCategory.id)
      .subscribe(
        async (r: CommonResponse) => {
          if (r.responseCode == "00") {
            await this.messageService.add({
              severity: "success",
              summary: "Dropped",
              detail: "Service Category record dropped",
            });
            await this.fetchServiceCategory();
          } else {
            this.messageService.add({
              severity: "error",
              summary: "Failure",
              detail: r.responseMsg,
            });
          }
        },
        (error) => {
          this.connectionError();
        }
      );
  }
  editServiceCategory(serviceCategories) {
    this.editingServiceCategory = true;
    this.serviceCategoryForm.addControl(
      "serviceCategoryId",
      new FormControl({ value: "", disabled: true }, Validators.required)
    );
    this.edServiceCategory = serviceCategories;
    this.serviceCategoryForm.patchValue({
      name: serviceCategories.name,
      description: serviceCategories.description,
      serviceCategoryId: serviceCategories.id,
    });

    if (serviceCategories.imageUrl) {
      this.serviceImg = serviceCategories.imageUrl;
    } else {
      this.serviceImg = environment.defaultServiceImage;
    }
    // this.serviceCategoryFormIn.nativeElement.focus();
    // document.getElementById('formFields').focus();
    this.selectedDivisionId = this.divisions.find(
      (division) => division.id === serviceCategories.divisionId
    );
    this.setOperationEntity();
    const _operatingEntityId = serviceCategories.operatingEntityId;
    this.selectedOperatingEntityId = this.operatingEntities.find(
      (operatingEntity) => operatingEntity.id === _operatingEntityId
    );
    this.setServiceGroup();
    this.selectedServiceGroupId = this.serviceGroups.find(
      (serviceGroup) => serviceGroup.id === serviceCategories.serviceGroupId
    );
    this.formWrapper.nativeElement.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "start",
    });
  }

  updateServiceCategory() {
    // update the selected serviceCategory
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Updating Service Category details",
    });
    const id = this.edServiceCategory.id;
    var postData = {
      name: this.serviceCategoryForm.get("name").value,
      divisionId: this.selectedDivisionId.id,
      description: this.serviceCategoryForm.get("description").value,
      operatingEntityId: this.selectedOperatingEntityId.id,
      serviceGroupId: this.selectedServiceGroupId.id,
      id: this.serviceCategoryForm.get("serviceCategoryId").value,
    };

    this.isCreateService = false;
    if (this.file) {
      this.fetchImageURL(postData);
    } else this._update(postData.id, postData);
  }

  private _update(id, postData) {
    this.serviceCategoryService
      .updateServiceCategory(id, postData)
      .subscribe(async (r: CommonResponse) => {
        if (r.responseCode == "00") {
          this.messageService.add({
            severity: "success",
            summary: "Notice",
            detail: "Service Category details Updated",
          });
          await this.fetchServiceCategory();
          await this.closeEditing();
        } else {
          this.messageService.add({
            severity: "error",
            summary: "Failure",
            detail: r.responseMsg,
          });
        }
      });
  }
  closeEditing() {
    this.editingServiceCategory = false;
    this.serviceCategoryForm.reset();
    this.edServiceCategory = null;
  }

  onFileSelect(event) {
    if (!this.checkMimeType(event) || !this.checkFileSize(event)) {
      return;
    }

    const reader = new FileReader();
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      this.file = event.target.files[0];
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.serviceImg = reader.result as string;
      };
    }
  }

  fetchImageURL(postData) {
    this.fileStorageService.UploadMultipleFilesFromDataUrl([this.file]);
    this.fileStorageService.onUploadFinished.subscribe(
      (resp: CommonResponse<string[]>) => {
        if (resp.responseCode != "00") {
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail: resp.responseMsg,
          });
        } else {
          postData.imageUrl = resp.responseData[0];
          if (this.isCreateService) {
            this._create(postData);
          } else {
            this._update(postData.id, postData);
          }
        }
      },
      (error) => {
        console.log("Error while uploading files " + error);
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail: "ERR: Unable to upload image to storage",
        });
      }
    );
  }

  checkMimeType = (event: EventInput): boolean => {
    const files = event.target.files[0];
    // list allow mime type
    const types = ["image/png", "image/jpeg", "image/jpg", "image/gif"];
    // loop access array
    if (!files) {
      this.messageService.add({
        severity: "error",
        summary: "Failed",
        detail: "Please select an Image file",
      });
      return false;
    }
    // compare file type find doesn't match
    if (types.every((type) => files.type !== type)) {
      // create error message and assign to container
      this.messageService.add({
        severity: "error",
        summary: "Failed",
        detail: "Image format not supported",
      });
      return false;
    }
    return true;
  };
  checkFileSize = (event: EventInput) => {
    const files = event.target.files[0];
    if (!files) return true;
    const size = 400000;
    if (files.size > size) {
      event.target.value = null;
      this.messageService.add({
        severity: "error",
        summary: "Failed",
        detail: "Image is too large, please pick a smaller file",
      });
      return false;
    }
    return true;
  };
}
