import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { CommonResponse } from "../interfaces/home";
import { environment } from "../../environments/environment";
import { Observable } from "rxjs";
import { shareReplay } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class StateService {
  sobservable: Observable<CommonResponse>;
  baseUrl = environment.baseUrl + "State";
  //baseUrl =  "https://localhost:44344/api/v1/State";
  constructor(private http: HttpClient) {}

  allState(): Observable<CommonResponse> {
    if (this.sobservable) return this.sobservable;
    else {
      this.sobservable = this.http
        .get<CommonResponse>(this.baseUrl)
        .pipe(shareReplay());
      return this.sobservable;
    }
  }

  getByID(id: number): Observable<CommonResponse> {
    return this.http.get<CommonResponse>(this.baseUrl + "/" + id);
  }

  allLgas(): Observable<CommonResponse> {
    return this.http.get<CommonResponse>(this.baseUrl + "/GetAllLgas");
  }

  GetStatesOnly(): Observable<CommonResponse> {
    if (this.sobservable) return this.sobservable;
    else {
      this.sobservable = this.http
        .get<CommonResponse>(this.baseUrl + "/GetStatesOnly")
        .pipe(shareReplay());
      return this.sobservable;
    }
  }
}
