import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";

@Injectable()
export class LeadEngagementsService {
  constructor(private http: HttpClient) {}

  getAllSuspects() {
    return this.http.get(environment.baseUrl + "contact/GetAllSupects");
  }

  getSuspectById(suspectId: any) {
    return this.http.get(environment.baseUrl + "Suspect/" + suspectId);
  }
}
