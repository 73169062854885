import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Subject} from 'rxjs';
import {ArmedEscortType} from '../../../../interfaces/armada';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {BreadcrumbService} from '../../../../breadcrumb.service';
import {ArmedEscortTypeService} from '../../../../services/armada/armed-escort-type.service';
import {ConfirmationService, MessageService} from 'primeng/api';
import {takeUntil} from 'rxjs/operators';

@Component({
  selector: 'app-armed-escort-type',
  templateUrl: './armed-escort-type.component.html',
  styleUrls: ['./armed-escort-type.component.scss']
})
export class ArmedEscortTypeComponent implements OnInit, OnDestroy {
  @ViewChild('formWrapper') public formWrapper:ElementRef;
  private unsubscriber$ = new Subject<void>();
  public targetCols: any;
  private exportTargetColumns: any;
  public loading: boolean;
  public submittingData: boolean;
  submitButtonLabel: string;

  public armedEscortTypes: ArmedEscortType[];
  public armedEscortTypeForm: FormGroup;
  selectedArmedEscortType: ArmedEscortType;
  editingArmedEscortType= false;
  private edArmedEscortType: ArmedEscortType;
  
  constructor(
      private breadcrumbService: BreadcrumbService,
      private armedEscortTypeService: ArmedEscortTypeService,
      public messageService: MessageService,
      public confirmationService: ConfirmationService,
      private formBuilder: FormBuilder,
  ) {
    this.breadcrumbService.setItems([
      { label: 'Setup' },
      { label: 'Armed Escort Type', routerLink: ['/setup/armed-escort-type'] }
    ]);
  }
  ngOnInit(): void {
    this.loading = true;
    this.targetCols = [
      { field: 'name', header: 'Name' },
      { field: 'caption', header: 'Caption' },
      { field: 'description', header: 'Description' },
    ];
    this.exportTargetColumns = this.targetCols.map(col => ({title: col.header, dataKey: col.field}));
    this.armedEscortTypeForm = this.formBuilder.group( {
      name: ['', [Validators.required, Validators.minLength(3)]],
      caption: ['', [Validators.required, Validators.minLength(3)]],
      description: ['', [Validators.required, Validators.minLength(3)]],
    })
    this.fetchAllArmedEscortType();
    this.submitButtonLabel = 'create';
  }
  
  fetchAllArmedEscortType(): void {
    this.armedEscortTypeService.allArmedEscortType().pipe(takeUntil(this.unsubscriber$)).subscribe( (res: any) => {
      this.armedEscortTypes = res;
      this.loading = false;
    });
  }
  createArmedEscortType() {
    this.submittingData = true;
    this.armedEscortTypeService.postArmedEscortType(this.armedEscortTypeForm.value).pipe(takeUntil(this.unsubscriber$))
        .subscribe( (result) => {
          this.messageService.add({
            summary: 'Success', severity: 'success', detail: 'New Armed Escort Type created',
          });
          this.fetchAllArmedEscortType();
          this.submittingData = false;
          this.armedEscortTypeForm.reset();
        })
  }
  deleteArmedEscortType(armedEscortType: ArmedEscortType): void {
    this.confirmationService.confirm({
      message: 'Are you sure you want to delete ' + armedEscortType.caption,
      accept: () => {
        this._deleteArmedEscortType(armedEscortType);
      }
    });
  }
  editArmedEscortType(armedEscortType: ArmedEscortType): void{
    this.formWrapper.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'start' });
    this.editingArmedEscortType = true;
    this.submitButtonLabel = 'Update';
    this.edArmedEscortType = armedEscortType;
    this.armedEscortTypeForm.setValue({
      name: armedEscortType.name,
      caption: armedEscortType.caption,
      description: armedEscortType.description,
    });
  }
  _deleteArmedEscortType(armedEscortType) {
    this.messageService.add({severity:'info', summary: 'Notice',
      detail:'Deleting Armed Escort Type record'});
    this.armedEscortTypeService.delete(armedEscortType.id).subscribe( async (result:any) => {
      await this.messageService.add({severity:'success', summary: 'Deleted',
        detail:'Armed Escort Type record removed'});
      await this.fetchAllArmedEscortType();
    }, error => {
      this.connectionError();
    });
  }
 
  updateArmedEscortType(): boolean {
    this.submittingData = true;
    if(!this.edArmedEscortType) {
      return false;
    }
    this.armedEscortTypeService.updateArmedEscortType(this.edArmedEscortType.id, this.armedEscortTypeForm.value)
        .pipe(takeUntil(this.unsubscriber$))
        .subscribe(() => {
          this.messageService.add({
            severity: 'success',
            detail: 'Armed Escort Type Updated',
            summary: 'Completed'
          });
          this.submittingData = false;
          this.closeEditing();
          this.fetchAllArmedEscortType();
        }, error => {
          this.connectionError();
          this.submittingData = false;
        })
  }

  closeEditing() {
    this.editingArmedEscortType = false;
    this.armedEscortTypeForm.reset();
    this.edArmedEscortType = null;
    this.submitButtonLabel = 'Create';
  }
  private connectionError() {
    this.messageService.add({severity:'error', summary: 'Failed',
      detail:'Connection Error, Please try again'});
  }
  ngOnDestroy(): void {
    this.unsubscriber$.complete();
    this.unsubscriber$.unsubscribe();
  }

}
