import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { AngularFireStorage } from "@angular/fire/storage";
import { FormBuilder } from "@angular/forms";
import { MessageService, ConfirmationService, TreeNode } from "primeng/api";
import { DialogService, DynamicDialogRef } from "primeng/dynamicdialog";
import { BreadcrumbService } from "src/app/breadcrumb.service";
import {
  CommonResponse,
  Contract,
  CustomerDivision,
  GroupedReceipt,
  RealGroupedReceipt,
  Receipt,
  ReverseGroupReceiptVM,
  ReverseReceiptVM,
} from "src/app/interfaces/home";
import { GmaCustomerService } from "src/app/services/gma-customer.service";
import { ReceiptService } from "src/app/services/receipt.service";
import { ViewReceiptComponent } from "../receipting/view-receipt/view-receipt.component";

@Component({
  selector: "app-reverse-receipting",
  templateUrl: "./reverse-receipting.component.html",
  styleUrls: ["./reverse-receipting.component.scss"],
})
export class ReverseReceiptingComponent implements OnInit {
  @ViewChild("formWrapper") public formWrapper: ElementRef;
  allClients: CustomerDivision[] = [];
  theClient: CustomerDivision;
  allContracts: Contract[] = [];
  allClientContracts: Contract[] = [];
  theContract: Contract;
  showReceipts: boolean;
  receiptCols: any[];
  receipts: any[];
  viewBreakdownRef: DynamicDialogRef;
  loadingReceipts: boolean;
  receiptTree: TreeNode[];
  selectedTree: TreeNode;
  selectedContractName: string;

  constructor(
    private fb: FormBuilder,
    public receiptService: ReceiptService,
    public dialogService: DialogService,
    public fireStorage: AngularFireStorage,
    public customerService: GmaCustomerService,
    public messageService: MessageService,
    private breadcrumbService: BreadcrumbService,
    public confirmationService: ConfirmationService
  ) {}

  ngOnInit(): void {
    this.breadcrumbService.setItems([
      {
        label: "Fiance",
        routerLink: ["/home"],
      },
      {
        label: "Reverse Receipting",
        routerLink: ["/home/reverse-receipting"],
      },
    ]);

    this.receiptCols = [
      { field: "caption", header: "Narration" },
      { field: "receiptNumber", header: "Receipt Number" },
      { field: "receiptValue", header: "Receipt Value" },
      { field: "transactionId", header: "Transaction ID" },
      { field: "receiptDepositor", header: "Depositor" },
      // { field: "receiptView", header: "View" },
      { field: "dateReceipt", header: "Date Receipted" },
      { field: "receiptReverse", header: "Reverse" },
    ];

    this.receiptTree = [];
    this.FetchAllContracts();
  }

  FetchAllContracts() {
    this.customerService.GetAllContracts().subscribe(
      async (data) => {
        this.allContracts = data;
        this.allContracts.forEach(
          (x) =>
            (x.caption =
              "Contract-" +
              x.id.toString().padStart(5, "0") +
              "-" +
              x.description)
        );
        this.FetchAllClients();
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all contracts the moment.. Reason: [" +
            error.error.message +
            "]",
        });
      }
    );
  }

  FetchAllClients() {
    this.customerService.GetAllClients().subscribe(
      async (data) => {
        this.allClients = data;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all clients the moment.. Reason: [" +
            error.error.message +
            "]",
        });
      }
    );
  }

  LoadClientContracts() {
    if (this.theClient)
      this.allClientContracts = this.allContracts.filter(
        (x) => x.customerDivisionId == this.theClient.id
      );
    else this.allClientContracts = [];
  }

  LoadContractReceipts() {
    this.loadingReceipts = true;
    if (this.theContract) this.GetReceipt(this.theContract.id);
    else this.receipts = [];
  }

  DisplayReceipt(receipt: any) {
    this.viewBreakdownRef = this.dialogService.open(ViewReceiptComponent, {
      width: "950px",
      contentStyle: { "max-width": "100vw", height: "90vh", overflow: "auto" },
      baseZIndex: 100,
      data: { receipt: receipt },
    });

    this.viewBreakdownRef.onClose.subscribe(() => {});
  }

  ReverseReceipt(receipt: Receipt) {
    this.confirmationService.confirm({
      message: "Are you sure you want to reverse this receipt?",
      accept: () => {
        this.messageService.add({
          severity: "info",
          summary: "Notice",
          detail: "Reversingy Receipt......",
        });

        const postData: ReverseReceiptVM = {
          receiptId: receipt.id,
        };

        this.receiptService.ReverseReceipt(postData).subscribe(
          async (res: CommonResponse) => {
            if (res.responseCode == "00") {
              this.messageService.add({
                severity: "success",
                summary: "Removed",
                detail: "Receipt Reverse successfully",
              });
              this.LoadContractReceipts();
            } else {
              this.messageService.add({
                severity: "error",
                summary: "Failure",
                detail:
                  "Err: Could not reverse receipt. Reason -> " +
                  res.responseMsg,
              });
            }
          },
          (error) => {
            console.log("Error: " + JSON.stringify(error));
            this.messageService.add({
              severity: "error",
              summary: "Notice",
              detail:
                "Unable to reverse receipt at the moment.. Reason: [" +
                error.error.message +
                "]",
            });
          }
        );
      },
    });
  }

  ReverseGroupReceipt(receiptNumber: string) {
    this.confirmationService.confirm({
      message:
        "Are you sure you want to reverse this invoice receipts, Kindly note that all receipts in the this group invoice will be reversed?",
      accept: () => {
        this.messageService.add({
          severity: "info",
          summary: "Notice",
          detail: "Reversingy Receipt......",
        });

        const postData: ReverseGroupReceiptVM = {
          invoiceNumber: receiptNumber,
        };

        this.receiptService.ReverseGroupReceipt(postData).subscribe(
          async (res: CommonResponse) => {
            if (res.responseCode == "00") {
              this.messageService.add({
                severity: "success",
                summary: "Removed",
                detail: "Receipt(s) Reversed successfully",
              });
              this.LoadContractReceipts();
            } else {
              this.messageService.add({
                severity: "error",
                summary: "Failure",
                detail:
                  "Err: Could not reverse receipt(s). Reason -> " +
                  res.responseMsg,
              });
            }
          },
          (error) => {
            console.log("Error: " + JSON.stringify(error));
            this.messageService.add({
              severity: "error",
              summary: "Notice",
              detail:
                "Unable to reverse receipt(s) at the moment.. Reason: [" +
                error.error.message +
                "]",
            });
          }
        );
      },
    });
  }

  GetReceipt(contractId: number) {
    this.showReceipts = false;
    this.selectedContractName = this.theContract.caption;
    this.receiptService.GetGroupedReceiptOnContract(contractId).subscribe(
      async (r: CommonResponse) => {
        if (r.responseCode == "00") {
          let resp: RealGroupedReceipt[] = r.responseData;
          this.receiptTree = [];
          resp.forEach((grp) => {
            let isReceiptReversable: boolean = false;
            let invReceipts = resp.find(
              (x) =>
                x.invoiceNumber == grp.invoiceNumber &&
                x.receipts[0].receiptId > grp.receipts[0].receiptId
            );
            if (invReceipts == null) isReceiptReversable = true;
            console.log(
              grp.narration + " is reversible: " + isReceiptReversable
            );
            let groupTree: TreeNode = {
              data: {
                caption: grp.narration,
                receiptNumber: grp.receiptNumber,
                receiptReverse: isReceiptReversable ? grp.receiptNumber : null,
                receiptValue: grp.totalValue,
                isReversable: true,
              },
              children: [],
            };
            grp.receipts.forEach((receip) => {
              groupTree.children.push({
                data: {
                  transactionId: receip.receipt.transactionId,
                  receiptValue: receip.receiptValue,
                  receiptDepositor: receip.depositor,
                  receiptView: receip.receipt,
                  dateReceipt: receip.dateReceipted,
                },
              });
            });
            this.receiptTree.push(groupTree);
          });
          this.showReceipts = true;
        } else {
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail: r.responseMsg,
          });
        }
        this.loadingReceipts = false;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to reverse receipt at the moment.. Reason: [" +
            error.error.message +
            "]",
        });
        this.loadingReceipts = false;
      }
    );
  }
}
