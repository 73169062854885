import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
//import { AngularFireStorage } from "@angular/fire/storage";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ConfirmationService, MessageService } from "primeng/api";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { BreadcrumbService } from "src/app/breadcrumb.service";
import {
  BRPairable,
  BusinessRule,
  PurchaseAsPair,
  ServiceRegistration,
} from "src/app/interfaces/armada";
import { CommonResponse } from "src/app/interfaces/home";
import { BusinessRuleService } from "src/app/services/armada/business-rule.service";
import { ServiceRegistrationService } from "src/app/services/armada/service-registration.service";
import { InventoryService } from "src/app/services/inventory.service";

@Component({
  selector: "app-business-rule",
  templateUrl: "./business-rule.component.html",
  styleUrls: ["./business-rule.component.scss"],
})
export class BusinessRuleComponent implements OnInit, OnDestroy {
  @ViewChild("formWrapper") public formWrapper: ElementRef;
  private unsubscriber$ = new Subject<void>();

  public targetCols: any;
  public PairtargetCols: any;
  private exportTargetColumns: any;
  private exportPairTargetColumns: any;
  public submittingData: boolean;
  public loading: boolean = true;
  public submitButtonLabel: string;
  public serviceRegistrations: ServiceRegistration[] = [];
  private edBusinessRule: BusinessRule;
  selectedBusinessRule: BusinessRule;
  public businessRules: BusinessRule[];

  selectedPairableBusinessRule: BusinessRule;
  public pairableBusinessRules: BusinessRule[];
  public businessRuleForm: FormGroup;
  userForm: FormGroup;
  public selectedRegistrationService: ServiceRegistration = null;
  public selectedMultiRegistrationService: ServiceRegistration[] = [];
  availableMultiRegServices: ServiceRegistration[] = [];
  //public services: Services[];
  //selectedserviceRegistration: any;
  //selectedServiceRegistration: ServiceRegistration;
  editingBusinessRule = false;
  isQuantityRequired = false;
  isPairingRequired = false;

  private edBRPairable: BRPairable;
  selectedBRPairable: BRPairable;
  public bRPairables: BRPairable[];
  selectedPurchaseAsPair: PurchaseAsPair;
  public purchaseAsPairs: PurchaseAsPair[];
  public bRPairableForm: FormGroup;
  public purchaseAsPairForm: FormGroup;
  public submitPairableButtonLabel: string;
  selectedMulti: number[] = [];
  //selectedMulti: string[] = [];
  editingBRPairable = false;
  filteredCountries: any[];
  rowGroupMetadata: any;

  constructor(
    public messageService: MessageService,
    public confirmationService: ConfirmationService,
    //public fireStorage: AngularFireStorage,
    public inventoryService: InventoryService,
    //private constants: ArmadaConstant,
    private formBuilder: FormBuilder,
    private breadcrumbService: BreadcrumbService,
    private serviceRegistrationService: ServiceRegistrationService,
    private businessRuleService: BusinessRuleService
  ) {
    this.breadcrumbService.setItems([
      { label: "Setup" },
      {
        label: "Business Rule",
        routerLink: ["/setup/business-rule"],
      },
    ]);
  }

  ngOnInit(): void {
    this.targetCols = [
      { field: "serviceRegistrationId", header: "serviceRegistrationId" },
      { field: "isQuantityRequired", header: "isQuantityRequired" },
      {
        field: "maxQuantity",
        header: "maxQuantity",
      },
      {
        field: "isPairingRequired",
        header: "isPairingRequired",
      },
    ];
    this.exportTargetColumns = this.targetCols.map((col) => ({
      title: col.header,
      dataKey: col.field,
    }));
    this.businessRuleForm = this.formBuilder.group({
      serviceRegistrationId: [
        { value: null, disabled: true },
        [Validators.required],
      ],
      maxQuantity: [1, [Validators.min(1)]],

      isQuantityRequired: [null, [Validators.required]],
      isPairingRequired: [null, [Validators.required]],

      //sequence: [''],
    });

    //Pairables
    this.PairtargetCols = [
      { field: "businessRuleId", header: "businessRuleId" },
      { field: "serviceRegistrationId", header: "serviceRegistrationId" },
    ];
    this.exportPairTargetColumns = this.PairtargetCols.map((col) => ({
      title: col.header,
      dataKey: col.field,
    }));
    this.bRPairableForm = this.formBuilder.group({
      businessRuleId: [null, [Validators.required]],
      serviceRegistrationId: [[null], [Validators.required]],
    });
    this.purchaseAsPairForm = this.formBuilder.group({
      serviceRegistrationId: [null, [Validators.required]],
    });

    this.userForm = this.formBuilder.group({
      userProfile: [""],
    });
    this.userForm.valueChanges
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(() => {
        this.selectedRegistrationService =
          this.userForm.get("userProfile").value;
      });
    this.fetchAllRegisteredServices();
    this.fetchAllBusinessRules();
    this.fetchAllPairableBusinessRules();
    this.fetchAllPairables();
    this.fetchAllPurchasedAsPairs();

    this.submitButtonLabel = "Create";
  }

  fetchAllRegisteredServices(): void {
    this.serviceRegistrationService
      .allServiceReg()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            var res = r.responseData;
            this.serviceRegistrations = res;
            this.loading = false;
          }
        },
        (error) => {
          this.loading = false;
          this.connectionError();
        }
      );
    this.submittingData = false;
  }

  fetchAllBusinessRules(): void {
    this.businessRuleService
      .allRules()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            var res = r.responseData;
            this.businessRules = res;
            this.loading = false;
          }
        },
        (error) => {
          this.loading = false;
          this.connectionError();
        }
      );
    this.submittingData = false;
  }

  fetchAllPairableBusinessRules(): void {
    this.businessRuleService
      .allPairableRules()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            var res = r.responseData;
            this.pairableBusinessRules = res;
            this.loading = false;
          }
        },
        (error) => {
          this.loading = false;
          this.connectionError();
        }
      );
    this.submittingData = false;
  }

  createBusinessRule() {
    this.submittingData = true;
    const formData = this.businessRuleForm.value;
    const postData = {
      serviceRegistrationId: this.selectedRegistrationService.id,
      isQuantityRequired: formData.isQuantityRequired,
      maxQuantity: formData.maxQuantity,
      isPairingRequired: formData.isPairingRequired,
      //pilotTypeId: this.selectedPilotType.id,
    };
    this.businessRuleService
      .postRule(postData)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            this.messageService.add({
              summary: "Success",
              severity: "success",
              detail: "New Rule  created",
            });
            this.fetchAllBusinessRules();
            this.submittingData = false;
            this.businessRuleForm.reset();
            this.userForm.reset();
            this.closeEditing();
          } else {
            this.messageService.add({
              severity: "error",
              summary: "Failed",
              detail: " Error: " + r.responseMsg,
            });
            this.submittingData = false;
          }
        },
        (error) => {
          this.messageService.add({
            severity: "error",
            summary: "Failed",
            detail: "Connection Error " + error.error.message,
          });
          this.submittingData = false;
        }
      );
    this.submittingData = false;
  }

  deleteBusinessRule(businessRule: BusinessRule): void {
    this.confirmationService.confirm({
      message: "Are you sure you want to delete " + "Business Rule",
      accept: () => {
        this._deleteBusinessRule(businessRule);
      },
    });
  }
  _deleteBusinessRule(businessRule) {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Deleting Rule ",
    });
    this.businessRuleService.deleteRule(businessRule.id).subscribe(
      async (result: any) => {
        await this.messageService.add({
          severity: "success",
          summary: "Deleted",
          detail: "Rule removed",
        });
        await this.fetchAllBusinessRules();
      },
      (error) => {
        this.connectionError();
      }
    );
  }
  editBusinessRule(businessRules: BusinessRule): void {
    this.formWrapper.nativeElement.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "start",
    });
    this.edBusinessRule = businessRules;
    this.editingBusinessRule = true;
    // this.imageSrc = pilotProfiles.imageUrl;

    this.businessRuleForm.setValue({
      serviceRegistrationId: businessRules.serviceRegistrationId,
      isQuantityRequired: businessRules.isQuantityRequired,
      maxQuantity: businessRules.maxQuantity,
      isPairingRequired: businessRules.isPairingRequired,
    });
    // this.selectedPilotType = this.pilotTypes.find(
    //   (Type) => Type.id === pilotProfiles.pilotTypeId
    // );

    this.submitButtonLabel = "Update";
  }
  updateBusinessRule(): boolean {
    this.submittingData = true;
    if (!this.edBusinessRule) {
      return false;
    }
    const formData = this.businessRuleForm.value;
    const data = {
      serviceRegistrationId: formData.serviceRegistrationId,
      isQuantityRequired: formData.isQuantityRequired,
      maxQuantity: formData.maxQuantity,
      isPairingRequired: formData.isPairingRequired,

      //rankId: this.selectedPilotRank.id,
      // sequence: 0
    };
    this.businessRuleService
      .updateRule(this.edBusinessRule.id, data)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            this.messageService.add({
              severity: "success",
              detail: "Business Rule Updated",
              summary: "Completed",
            });
            this.submittingData = false;
            this.closeEditing();
            this.fetchAllBusinessRules();
          } else {
            this.messageService.add({
              severity: "error",
              summary: "Failed",
              detail: " Error: " + r.responseMsg,
            });
            this.submittingData = false;
          }
        },
        (error) => {
          this.connectionError();
          this.submittingData = false;
        }
      );
  }

  //Pairables
  fetchAllPairables(): void {
    this.businessRuleService
      .allPairabless()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            var res = r.responseData;
            this.bRPairables = res;
            this.loading = false;
          }
        },
        (error) => {
          this.loading = false;
          this.connectionError();
        }
      );
    this.submittingData = false;
  }

  createPairable() {
    this.submittingData = true;
    const formData = this.bRPairableForm.value;
    const postData = {
      //serviceRegistrationId: this.selectedRegistrationService.id,
      businessRuleId: this.selectedPairableBusinessRule.id,
      //businessRuleId: formData.businessRuleId,
      serviceRegistrationId: this.selectedMultiRegistrationService.map(
        (x) => x.id
      ),
    };
    this.businessRuleService
      .postPairable(postData)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            this.messageService.add({
              summary: "Success",
              severity: "success",
              detail: "New Pair  created",
            });
            this.fetchAllBusinessRules();
            this.fetchAllPairables();
            this.submittingData = false;
            this.businessRuleForm.reset();
            this.bRPairableForm.reset();
            this.userForm.reset();
            this.closeEditing();
          } else {
            this.messageService.add({
              severity: "error",
              summary: "Failed",
              detail: " Error: " + r.responseMsg,
            });
            this.submittingData = false;
          }
        },
        (error) => {
          this.messageService.add({
            severity: "error",
            summary: "Failed",
            detail: "Connection Error " + error.error.message,
          });
          this.submittingData = false;
        }
      );
    this.submittingData = false;
  }

  deletePairable(bRPairable: BRPairable): void {
    this.confirmationService.confirm({
      message: "Are you sure you want to delete " + "Pair",
      accept: () => {
        this._deletePairable(bRPairable);
      },
    });
  }
  _deletePairable(bRPairable) {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Deleting Pair ",
    });
    this.businessRuleService.deletePairable(bRPairable.id).subscribe(
      async (result: any) => {
        await this.messageService.add({
          severity: "success",
          summary: "Deleted",
          detail: "Pair removed",
        });
        await this.fetchAllPairables();
      },
      (error) => {
        this.connectionError();
      }
    );
  }
  editPairable(bRPairables: BRPairable): void {
    this.formWrapper.nativeElement.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "start",
    });
    this.edBRPairable = bRPairables;
    this.editingBRPairable = true;
    // this.imageSrc = pilotProfiles.imageUrl;

    this.bRPairableForm.setValue({
      serviceRegistrationId: bRPairables.serviceRegistrationId,
      businessRuleId: bRPairables.businessRuleId,
    });
    // this.selectedPilotType = this.pilotTypes.find(
    //   (Type) => Type.id === pilotProfiles.pilotTypeId
    // );

    this.submitButtonLabel = "Update";
  }

  //Purchase
  fetchAllPurchasedAsPairs(): void {
    this.businessRuleService
      .allPurchasedAsPairs()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            var res = r.responseData;
            this.purchaseAsPairs = res;
            this.loading = false;
          }
        },
        (error) => {
          this.loading = false;
          this.connectionError();
        }
      );
    this.submittingData = false;
  }

  createPurchaseAspair() {
    this.submittingData = true;
    //const formData = this.businessRuleForm.value;
    const postData = {
      serviceRegistrationId: this.selectedRegistrationService.id,
      
    };
    this.businessRuleService
      .postPurchaseAsPair(postData)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            this.messageService.add({
              summary: "Success",
              severity: "success",
              detail: "New Purchase pair  created",
            });
            this.fetchAllPurchasedAsPairs();
            this.submittingData = false;
            this.purchaseAsPairForm.reset();
            this.closeEditing();
          } else {
            this.messageService.add({
              severity: "error",
              summary: "Failed",
              detail: " Error: " + r.responseMsg,
            });
            this.submittingData = false;
          }
        },
        (error) => {
          this.messageService.add({
            severity: "error",
            summary: "Failed",
            detail: "Connection Error " + error.error.message,
          });
          this.submittingData = false;
        }
      );
    this.submittingData = false;
  }

  deletePurchase(purchase: PurchaseAsPair): void {
    this.confirmationService.confirm({
      message: "Are you sure you want to delete purchase?",
      accept: () => {
        this._deletePurchase(purchase);
      },
    });
  }
  _deletePurchase(purchase) {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Deleting purchase ",
    });
    this.businessRuleService.deletePurchaseAsPair(purchase.id).subscribe(
      async (result: any) => {
        await this.messageService.add({
          severity: "success",
          summary: "Deleted",
          detail: "Purchase removed",
        });
        await this.fetchAllPurchasedAsPairs();
      },
      (error) => {
        this.connectionError();
      }
    );
  }
  onSort() {
    this.updateRowGroupMetaData();
  }

  updateRowGroupMetaData() {
    this.rowGroupMetadata = {};

    if (this.bRPairables) {
      for (let i = 0; i < this.bRPairables.length; i++) {
        const rowData = this.bRPairables[i];
        const representativeName =
          rowData.businessRule.serviceRegistration.service.name;

        if (i === 0) {
          this.rowGroupMetadata[representativeName] = { index: 0, size: 1 };
        } else {
          const previousRowData = this.bRPairables[i - 1];
          const previousRowGroup =
            previousRowData.businessRule.serviceRegistration.service.name;
          if (representativeName === previousRowGroup) {
            this.rowGroupMetadata[representativeName].size++;
          } else {
            this.rowGroupMetadata[representativeName] = { index: i, size: 1 };
          }
        }
      }
    }
  }
  // updateBusinessRule(): boolean {
  //   this.submittingData = true;
  //   if (!this.edBusinessRule) {
  //     return false;
  //   }
  //   const formData = this.businessRuleForm.value;
  //   const data = {
  //     serviceRegistrationId: formData.serviceRegistrationId,
  //     isQuantityRequired: formData.isQuantityRequired,
  //     maxQuantity: formData.maxQuantity,
  //     isPairingRequired: formData.isPairingRequired,

  //     //rankId: this.selectedPilotRank.id,
  //     // sequence: 0
  //   };
  //   this.businessRuleService
  //     .updateRule(this.edBusinessRule.id, data)
  //     .pipe(takeUntil(this.unsubscriber$))
  //     .subscribe(
  //       () => {
  //         this.messageService.add({
  //           severity: "success",
  //           detail: "Business Rule Updated",
  //           summary: "Completed",
  //         });
  //         this.submittingData = false;
  //         this.closeEditing();
  //         this.fetchAllBusinessRules();
  //       },
  //       (error) => {
  //         this.connectionError();
  //         this.submittingData = false;
  //       }
  //     );
  // }

  setAvaibaleServicesToSelect() {
    this.availableMultiRegServices = [];
    this.serviceRegistrations.forEach((Rank) => {
      if (
        Rank.service.name !==
        this.selectedPairableBusinessRule.serviceRegistration.service.name
      ) {
        this.availableMultiRegServices.push(Rank);
        //console.log(Rank);
      }
    });
  }

  filterCountry(event) {
    const filtered: any[] = [];
    const query = event.query;
    for (let i = 0; i < this.serviceRegistrations.length; i++) {
      const country = this.serviceRegistrations[i];
      if (
        country.service.name.toLowerCase().indexOf(query.toLowerCase()) == 0
      ) {
        filtered.push(country);
      }
    }

    this.filteredCountries = filtered;
  }
  setAvaibaleServicesToSelects() {
    //this.fetchAllRegisteredServices();
    this.serviceRegistrations.forEach((Rank, index) => {
      if (
        Rank.service.name ===
        this.selectedBusinessRule.serviceRegistration.service.name
      ) {
        this.serviceRegistrations.splice(index, 1);
        //console.log(Rank);
      }
    });
  }
  closeEditing() {
    this.editingBusinessRule = false;
    this.businessRuleForm.reset();
    this.edBusinessRule = null;

    this.submitButtonLabel = "Create";
  }
  private connectionError() {
    this.messageService.add({
      severity: "error",
      summary: "Failed",
      detail: "Connection Error, Please try again",
    });
  }
  ngOnDestroy(): void {
    this.unsubscriber$.complete();
    this.unsubscriber$.unsubscribe();
  }
}
