import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { CommonResponse } from "../interfaces/home";
import {
  GetBusinessOverviewDashboardReportVM,
  GetBusinessOverviewDashboardReportView,
  GetClientsReceivableReportReceivingDTO,
  GetClientsReceivableReportTransferDTO,
  GetLoginDashboardReportVM,
  GetLoginDashboardReportView,
  GetManagementDashboardReportTransferDTO,
  GetProductionLowerDashboardReportVM,
  GetProductionLowerDashboardReportView,
  GetProductionUpperDashboardReportView,
  GetRenewalReportReceivingDTO,
  GetRenewalReportTransferDTO,
  GetRevenueAmortizationReportReceivingDTO,
  GetRevenueAmortizationReportTransferDTO,
  ReportCategory,
} from "../interfaces/reporting";

@Injectable({
  providedIn: "root",
})
export class ReportingService {
  baseUrl = environment.baseUrl + "Reporting/";

  constructor(private http: HttpClient) {}

  GetAllReportCategories(): Observable<CommonResponse<ReportCategory[]>> {
    return this.http.get<CommonResponse<ReportCategory[]>>(
      this.baseUrl + "GetAllReportCategories"
    );
  }

  GetClientsReceivableReport(
    data: GetClientsReceivableReportReceivingDTO
  ): Observable<CommonResponse<GetClientsReceivableReportTransferDTO[]>> {
    return this.http.post<
      CommonResponse<GetClientsReceivableReportTransferDTO[]>
    >(this.baseUrl + "GetClientsReceivableReport", data);
  }

  GetManagementDashboardReport(): Observable<
    CommonResponse<GetManagementDashboardReportTransferDTO>
  > {
    return this.http.get<
      CommonResponse<GetManagementDashboardReportTransferDTO>
    >(this.baseUrl + "GetManagementDashboardReport");
  }

  GetRevenueAmortizationReport(
    data: GetRevenueAmortizationReportReceivingDTO
  ): Observable<CommonResponse<GetRevenueAmortizationReportTransferDTO[]>> {
    return this.http.post<
      CommonResponse<GetRevenueAmortizationReportTransferDTO[]>
    >(this.baseUrl + "GetRevenueAmortizationReport", data);
  }

  GetRenewalReport(
    data: GetRenewalReportReceivingDTO
  ): Observable<CommonResponse<GetRenewalReportTransferDTO[]>> {
    return this.http.post<CommonResponse<GetRenewalReportTransferDTO[]>>(
      this.baseUrl + "GetRenewalReport",
      data
    );
  }

  GetProductionUpperDashboardReport(
    year: number,
    budgetSeasonId: number
  ): Observable<CommonResponse<GetProductionUpperDashboardReportView>> {
    return this.http.get<CommonResponse<GetProductionUpperDashboardReportView>>(
      this.baseUrl +
        "GetProductionUpperDashboardReport/" +
        year +
        "/" +
        budgetSeasonId
    );
  }

  GetProductionLowerDashboardReport(
    data: GetProductionLowerDashboardReportVM
  ): Observable<CommonResponse<GetProductionLowerDashboardReportView>> {
    return this.http.post<
      CommonResponse<GetProductionLowerDashboardReportView>
    >(this.baseUrl + "GetProductionLowerDashboardReport/", data);
  }

  GetLoginDashboardReport(
    data: GetLoginDashboardReportVM
  ): Observable<CommonResponse<GetLoginDashboardReportView>> {
    return this.http.post<CommonResponse<GetLoginDashboardReportView>>(
      this.baseUrl + "GetLoginDashboardReport/",
      data
    );
  }

  GetBusinessOverviewDashboardReport(
    data: GetBusinessOverviewDashboardReportVM
  ): Observable<CommonResponse<GetBusinessOverviewDashboardReportView>> {
    return this.http.post<
      CommonResponse<GetBusinessOverviewDashboardReportView>
    >(this.baseUrl + "GetBusinessOverviewDashboardReport/", data);
  }

  GetBusinessOverviewDashboardReportBackgroundData(
    data: GetBusinessOverviewDashboardReportVM
  ): Observable<CommonResponse<GetBusinessOverviewDashboardReportView>> {
    return this.http.post<
      CommonResponse<GetBusinessOverviewDashboardReportView>
    >(this.baseUrl + "GetBusinessOverviewDashboardReportBackgroundData/", data);
  }
}
