import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { StockBatch } from "../interfaces/inventory";
import {
  AddCompanyNodeVM,
  AddOrganizationNodeVM,
  Company,
  CreateNonPrincipalApprovingOfficeVM,
  MoveNonPrincipalApprovingOfficeSequenceVM,
  NonPrincipalApprovingOffice,
  OrganizationNode,
  OrganizationNodeMember,
  UpdateNonPrincipalApprovingOfficeVM,
  UpdateOrganizationNodeVM,
} from "../interfaces/organization";

@Injectable({
  providedIn: "root",
})
export class OrganizationService {
  baseUrl = environment.gmaBaseUrl + "Organization/";
  organogramBaseUrl = environment.gmaBaseUrl + "Organogram/";
  lineManagerApproval = environment.gmaBaseUrl + "LineManagerApproval/";

  constructor(private http: HttpClient) {}

  GetAllCompanies(): Observable<Company[]> {
    return this.http.get<Company[]>(this.organogramBaseUrl + "GetAllCompanies");
  }

  GetCompanyNodes(companyId: number): Observable<OrganizationNode[]> {
    return this.http.get<OrganizationNode[]>(
      this.organogramBaseUrl + "GetCompanyNodes/" + companyId
    );
  }

  AddCompanyNode(data: AddCompanyNodeVM) {
    return this.http.post(this.organogramBaseUrl + "AddCompanyNode", data);
  }

  GetSubNodes(nodeId: number): Observable<OrganizationNode[]> {
    return this.http.get<OrganizationNode[]>(
      this.organogramBaseUrl + "GetSubNodes/" + nodeId
    );
  }

  AddOrganizationNode(data: AddOrganizationNodeVM) {
    return this.http.post(this.organogramBaseUrl + "AddOrganizationNode", data);
  }

  GetOrganizationNodeMembers(
    organizationNodeId: number
  ): Observable<OrganizationNodeMember[]> {
    return this.http.get<OrganizationNodeMember[]>(
      this.organogramBaseUrl +
        "GetOrganizationNodeMembers/" +
        organizationNodeId
    );
  }

  RemoveOrganizationNodeMember(memberId: number) {
    return this.http.delete(
      this.organogramBaseUrl + "RemoveOrganizationNodeMember/" + memberId
    );
  }

  UpdateOrganizationNode(nodeId: number, data: UpdateOrganizationNodeVM) {
    return this.http.put(
      this.organogramBaseUrl + "UpdateOrganizationNode/" + nodeId,
      data
    );
  }

  GetLineManagerPendingInvBatchApprovals(): Observable<StockBatch[]> {
    return this.http.get<StockBatch[]>(
      this.lineManagerApproval + "GetLineManagerPendingInvBatchApprovals"
    );
  }

  GetAllNonPrincipalApprovingOffices(): Observable<
    NonPrincipalApprovingOffice[]
  > {
    return this.http.get<NonPrincipalApprovingOffice[]>(
      this.organogramBaseUrl + "GetAllNonPrincipalApprovingOffices"
    );
  }

  CreateNonPrincipalApprovingOffice(data: CreateNonPrincipalApprovingOfficeVM) {
    return this.http.post(
      this.organogramBaseUrl + "CreateNonPrincipalApprovingOffice",
      data
    );
  }

  UpdateNonPrincipalApprovingOffice(
    id: number,
    data: UpdateNonPrincipalApprovingOfficeVM
  ) {
    return this.http.put(
      this.organogramBaseUrl + "UpdateNonPrincipalApprovingOffice/" + id,
      data
    );
  }

  DeleteNonPrincipalApprovingOffice(id: number) {
    return this.http.delete(
      this.organogramBaseUrl + "DeleteNonPrincipalApprovingOffice/" + id
    );
  }

  MoveNonPrincipalApprovingOfficeSequence(
    data: MoveNonPrincipalApprovingOfficeSequenceVM
  ) {
    return this.http.post(
      this.organogramBaseUrl + "MoveNonPrincipalApprovingOfficeSequence",
      data
    );
  }

  DeleteOrganizationNode(organizationNodeId: number) {
    return this.http.delete(
      this.organogramBaseUrl + "DeleteOrganizationNode/" + organizationNodeId
    );
  }
}
