import {
  AddItemToBatchRequest,
  BatchRequestItem,
  CentralStore,
  CreateStockBatch,
  Item,
  RegionalStore,
  StockBatch,
} from "./../../../interfaces/inventory";
import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MessageService, ConfirmationService } from "primeng/api";
import { BreadcrumbService } from "src/app/breadcrumb.service";
import { InventoryService } from "src/app/services/inventory.service";
import { finalize } from "rxjs/operators";
import { FileStorageService } from "src/app/services/file-storage.service";
import { CommonResponse } from "src/app/interfaces/home";

@Component({
  selector: "app-inventory-stock",
  templateUrl: "./inventory-stock.component.html",
  styleUrls: ["./inventory-stock.component.scss"],
})
export class InventoryStockComponent implements OnInit {
  @ViewChild("formWrapper") public formWrapper: ElementRef;
  form: FormGroup;
  addItemForm: FormGroup;
  uploadedLPOs: any[] = [];
  uploadedDeliveryNotes: any[] = [];
  uploadedSupplyInvs: any[] = [];
  uploadedSupportDoc1: any[] = [];
  uploadedSupportDoc2: any[] = [];
  userIsYetToClickUploadLPO: boolean;
  userIsYetToClickUploadDeliveryNote: boolean;
  userIsYetToClickUploadSupplyInv: boolean;
  userIsYetToClickUploadSupportDoc1: boolean;
  userIsYetToClickUploadSupportDoc2: boolean;
  allRegionalStores: RegionalStore[];
  theRegionalStore: RegionalStore;
  allCentralStores: CentralStore[];
  theCentralStore: CentralStore;
  fetchingStockBatches: boolean;
  allStockBatches: StockBatch[];
  selectedStockBatch: StockBatch;
  stockBatchCols: any[];
  allItems: Item[];
  theItem: Item;
  uploadDataUrls: {
    lpoUploads: string[];
    deliveryNoteUploads: string[];
    supplyInvUploads: string[];
    supportDoc1Uploads: string[];
    supportDoc2Uploads: string[];
  };
  isCreatingStoreBatch: boolean;
  openDialogue: boolean;
  openItemsDialogue: boolean;
  stockBatchToDisplay: StockBatch;
  selectedBatchDetailItems: BatchRequestItem[];
  singleSelectBatchDetailItem: BatchRequestItem;
  batchInUse: StockBatch;
  fetchingBatchItems: boolean;
  batchDetailItemsCols: any[];
  showLeftGrid: boolean = true;

  constructor(
    fb: FormBuilder,
    public fileStorageService: FileStorageService,
    public inventoryService: InventoryService,
    public messageService: MessageService,
    private breadcrumbService: BreadcrumbService,
    public confirmationService: ConfirmationService
  ) {
    this.form = fb.group({
      SRBatchName: ["", Validators.required],
      SRBatchDescription: ["", Validators.required],
      CentralStore: ["", Validators.required],
    });

    this.addItemForm = fb.group({
      Item: [""],
      Qty: ["", Validators.required],
      UnitPrice: ["", Validators.required],
    });
  }

  ngOnInit(): void {
    this.breadcrumbService.setItems([
      {
        label: "Inventory Management",
        routerLink: ["/home/inventory-management/set-up"],
      },
      {
        label: "Central Request",
        routerLink: ["/home/inventory-management/central-request"],
      },
    ]);

    this.stockBatchCols = [
      { field: "name", header: "Name" },
      { field: "description", header: "Description" },
      { field: "batchDescription", header: "BatchDescription" },
    ];

    this.batchDetailItemsCols = [
      { field: "item.name", header: "Item.name" },
      { field: "quantity", header: "Quantity" },
      { field: "unitPrice", header: "UnitPrice" },
    ];

    this.uploadDataUrls = {
      lpoUploads: [],
      deliveryNoteUploads: [],
      supplyInvUploads: [],
      supportDoc1Uploads: [],
      supportDoc2Uploads: [],
    };

    //this.FetchAllRegionalStores();
    this.FetchAllCentralStores();
    this.FetchAllItems();
    this.FetchAllRegionStockBatcheRequests();
  }

  async CreateBatch() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Creating Region Batch Request...",
    });

    this.isCreatingStoreBatch = true;
    await this._startGetUploadDatasChain();
  }

  async _createStoreBatch() {
    const postData: CreateStockBatch = {
      sRBatchName: this.form.get("SRBatchName").value,
      sRBatchdDescription: this.form.get("SRBatchDescription").value,
      lPOUrl: this.uploadDataUrls.lpoUploads,
      deliveryNoteUrls: this.uploadDataUrls.deliveryNoteUploads,
      supplyInvUrls: this.uploadDataUrls.supplyInvUploads,
      supportingDoc1Urls: this.uploadDataUrls.supportDoc1Uploads,
      supportingDoc2Urls: this.uploadDataUrls.supportDoc2Uploads,
      centralStoreId: this.theCentralStore.id,
      isUserRequest: false,
    };

    this.inventoryService.LogRegionStockBatchRequest(postData).subscribe(
      async () => {
        await this.messageService.add({
          severity: "success",
          summary: "Completed",
          detail: "Region Stock Batch Request Created Successfully!",
        });

        this.isCreatingStoreBatch = false;
        this.showLeftGrid = false;
        this.uploadedLPOs = [];
        this.uploadedDeliveryNotes = [];
        this.uploadedSupplyInvs = [];
        this.uploadedSupportDoc1 = [];
        this.uploadedSupportDoc2 = [];
        this.form.reset();
        await new Promise((resolve) => setTimeout(resolve, 2000)); // 2 secs
        this.showLeftGrid = true;
        this.FetchAllRegionStockBatcheRequests();
      },
      (error) => {
        this.messageService.add({
          severity: "error",
          summary: "Error",
          detail:
            "Unable to create region batch stock at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
        this.isCreatingStoreBatch = false;
      }
    );
  }

  async _startGetUploadDatasChain() {
    this.uploadDataUrls.lpoUploads = [];
    this.uploadDataUrls.deliveryNoteUploads = [];
    this.uploadDataUrls.supplyInvUploads = [];
    this.uploadDataUrls.supportDoc1Uploads = [];
    this.uploadDataUrls.supportDoc2Uploads = [];

    //Get LPO Url(s)
    await this._loadLPOUrls();
  }

  async _loadLPOUrls() {
    if (this.uploadedLPOs.length > 0) {
      this.fileStorageService.UploadMultipleFilesFromDataUrl(this.uploadedLPOs);
      this.fileStorageService.onUploadFinished.subscribe(
        async (resp: CommonResponse<string[]>) => {
          if (resp.responseCode != "00") {
            this.messageService.add({
              severity: "error",
              summary: "Notice",
              detail: resp.responseMsg,
            });
          } else {
            this.uploadDataUrls.lpoUploads = resp.responseData;
            await this._loadDeliveryNoteUrls();
          }
        },
        (error) => {
          console.log("Error while uploading files " + error);
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail: "ERR: Unable to upload image to storage",
          });
        }
      );
    } else {
      await this._loadDeliveryNoteUrls();
    }
  }

  async _loadDeliveryNoteUrls() {
    if (this.uploadedDeliveryNotes.length > 0) {
      this.fileStorageService.UploadMultipleFilesFromDataUrl(
        this.uploadedDeliveryNotes
      );
      this.fileStorageService.onUploadFinished.subscribe(
        async (resp: CommonResponse<string[]>) => {
          if (resp.responseCode != "00") {
            this.messageService.add({
              severity: "error",
              summary: "Notice",
              detail: resp.responseMsg,
            });
          } else {
            this.uploadDataUrls.deliveryNoteUploads = resp.responseData;
            await this._loadSupplyInvUrls();
          }
        },
        (error) => {
          console.log("Error while uploading files " + error);
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail: "ERR: Unable to upload image to storage",
          });
        }
      );
    } else {
      await this._loadSupplyInvUrls();
    }
  }

  async _loadSupplyInvUrls() {
    if (this.uploadedSupplyInvs.length > 0) {
      this.fileStorageService.UploadMultipleFilesFromDataUrl(
        this.uploadedSupplyInvs
      );
      this.fileStorageService.onUploadFinished.subscribe(
        async (resp: CommonResponse<string[]>) => {
          if (resp.responseCode != "00") {
            this.messageService.add({
              severity: "error",
              summary: "Notice",
              detail: resp.responseMsg,
            });
          } else {
            this.uploadDataUrls.supplyInvUploads = resp.responseData;
            await this._loadSupportDoc1Urls();
          }
        },
        (error) => {
          console.log("Error while uploading files " + error);
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail: "ERR: Unable to upload image to storage",
          });
        }
      );
    } else {
      await this._loadSupportDoc1Urls();
    }
  }

  async _loadSupportDoc1Urls() {
    if (this.uploadedSupportDoc1.length > 0) {
      this.fileStorageService.UploadMultipleFilesFromDataUrl(
        this.uploadedSupportDoc1
      );
      this.fileStorageService.onUploadFinished.subscribe(
        async (resp: CommonResponse<string[]>) => {
          if (resp.responseCode != "00") {
            this.messageService.add({
              severity: "error",
              summary: "Notice",
              detail: resp.responseMsg,
            });
          } else {
            this.uploadDataUrls.supportDoc1Uploads = resp.responseData;
            await this._loadSupportDoc2Urls();
          }
        },
        (error) => {
          console.log("Error while uploading files " + error);
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail: "ERR: Unable to upload image to storage",
          });
        }
      );
    } else {
      await this._loadSupportDoc2Urls();
    }
  }

  async _loadSupportDoc2Urls() {
    if (this.uploadedSupportDoc2.length > 0) {
      this.fileStorageService.UploadMultipleFilesFromDataUrl(
        this.uploadedSupportDoc2
      );
      this.fileStorageService.onUploadFinished.subscribe(
        async (resp: CommonResponse<string[]>) => {
          if (resp.responseCode != "00") {
            this.messageService.add({
              severity: "error",
              summary: "Notice",
              detail: resp.responseMsg,
            });
          } else {
            this.uploadDataUrls.supportDoc2Uploads = resp.responseData;
            await this._createStoreBatch();
          }
        },
        (error) => {
          console.log("Error while uploading files " + error);
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail: "ERR: Unable to upload image to storage",
          });
        }
      );
    } else {
      await this._createStoreBatch();
    }
  }

  onCancelLPOUpload() {
    this.uploadedLPOs = [];
  }

  onCancelDeliveryNoteUpload() {
    this.uploadedDeliveryNotes = [];
  }

  onCancelDSupplyInvUpload() {
    this.uploadedSupplyInvs = [];
  }

  onCancelDSupportDoc1Upload() {
    this.uploadedSupportDoc1 = [];
  }

  onCancelDSupportDoc2Upload() {
    this.uploadedSupportDoc2 = [];
  }

  NotifyUserToClickUpload(identifier: number) {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Kindly ensure you click upload.",
    });

    if (identifier == 1) {
      this.userIsYetToClickUploadLPO = true;
    } else if (identifier == 2) {
      this.userIsYetToClickUploadDeliveryNote = true;
    } else if (identifier == 3) {
      this.userIsYetToClickUploadSupplyInv = true;
    } else if (identifier == 4) {
      this.userIsYetToClickUploadSupportDoc1 = true;
    } else if (identifier == 5) {
      this.userIsYetToClickUploadSupportDoc2 = true;
    }
  }

  onUploadLPO(event) {
    this.uploadedLPOs = [];
    for (const file of event.files) {
      this.uploadedLPOs.push(file);
    }

    this.messageService.add({
      severity: "success",
      summary: "Success",
      detail: "LPO(s) Uploaded",
    });

    this.userIsYetToClickUploadLPO = false;
  }

  onUploadDeliveryNote(event) {
    this.uploadedDeliveryNotes = [];
    for (const file of event.files) {
      this.uploadedDeliveryNotes.push(file);
    }

    this.messageService.add({
      severity: "success",
      summary: "Success",
      detail: "Delivery Note(s) Uploaded",
    });

    this.userIsYetToClickUploadDeliveryNote = false;
  }

  onUploadSupplyInv(event) {
    this.uploadedSupplyInvs = [];
    for (const file of event.files) {
      this.uploadedSupplyInvs.push(file);
    }

    this.messageService.add({
      severity: "success",
      summary: "Success",
      detail: "Supply Invoice(s) Uploaded",
    });

    this.userIsYetToClickUploadSupplyInv = false;
  }

  onUploadSupportDoc1(event) {
    this.uploadedSupportDoc1 = [];
    for (const file of event.files) {
      this.uploadedSupportDoc1.push(file);
    }

    this.messageService.add({
      severity: "success",
      summary: "Success",
      detail: "Support Doc(s) 1 Uploaded",
    });

    this.userIsYetToClickUploadSupportDoc1 = false;
  }

  onUploadSupportDoc2(event) {
    this.uploadedSupportDoc2 = [];
    for (const file of event.files) {
      this.uploadedSupportDoc2.push(file);
    }

    this.messageService.add({
      severity: "success",
      summary: "Success",
      detail: "Support Doc(s) 2 Uploaded",
    });

    this.userIsYetToClickUploadSupportDoc2 = false;
  }

  FetchAllRegionalStores() {
    this.inventoryService.GetAllRegionalInventoryStore().subscribe(
      (data) => {
        this.allRegionalStores = data;
      },
      (error) => {
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all regional inventory stores at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  FetchAllCentralStores() {
    this.inventoryService.GetAllManagerCentralInventoryStore().subscribe(
      (data) => {
        this.allCentralStores = data;
      },
      (error) => {
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all central inventory stores at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  FetchAllItems() {
    this.inventoryService.GetAllItem().subscribe(
      (data) => {
        this.allItems = data;
      },
      (error) => {
        this.messageService.add({
          severity: "error",
          summary: "Failed",
          detail:
            "Connection Error, Unable to fetch all items " +
            (error ? error.error.message : "request failed - permission"),
        });
      }
    );
  }

  FetchAllInventoryStockBatches() {
    this.inventoryService.GetAllInventoryStockBatches().subscribe(
      (data) => {
        this.allStockBatches = data;
      },
      (error) => {
        this.messageService.add({
          severity: "error",
          summary: "Failed",
          detail:
            "Connection Error, Unable to fetch all inventory stock batches for user " +
            (error ? error.error.message : "request failed - permission"),
        });
      }
    );
  }

  FetchAllRegionStockBatcheRequests() {
    this.inventoryService.GetAllRegionStockBatchRequests().subscribe(
      (data) => {
        // this.allStockBatches = data;
        this.allStockBatches = [];
        data.forEach((batch) => {
          if (batch.centralStoreId) this.allStockBatches.push(batch);
        });
      },
      (error) => {
        this.messageService.add({
          severity: "error",
          summary: "Failed",
          detail:
            "Connection Error, Unable to fetch all region stock batch requests for user " +
            (error ? error.error.message : "request failed - permission"),
        });
      }
    );
  }

  HideDialog() {
    this.openDialogue = false;
    this.stockBatchToDisplay = null;
  }

  HideItemDialog() {
    this.openItemsDialogue = false;
    this.selectedBatchDetailItems = null;
  }

  async ViewItemsInStockBatch(item: StockBatch) {
    this.openItemsDialogue = true;
    this.fetchingBatchItems = true;
    this.inventoryService.GetItemsInBatchRequest(item.id).subscribe(
      (data) => {
        this.selectedBatchDetailItems = data;
        this.fetchingBatchItems = false;
      },
      (error) => {
        this.messageService.add({
          severity: "error",
          summary: "Failed",
          detail:
            "Connection Error, Unable to get items in batch | reason: " +
            (error ? error.error.message : "request failed - permission"),
        });
        this.fetchingBatchItems = false;
      }
    );
  }

  LoadBatchDetails(item: StockBatch) {
    this.openDialogue = true;
    this.stockBatchToDisplay = item;
  }

  AddItemToBatchRequest() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Adding Item To Batch Request...",
    });

    const postData: AddItemToBatchRequest = {
      itemId: this.theItem.id,
      quantity: this.addItemForm.get("Qty").value,
      unitPrice: this.addItemForm.get("UnitPrice").value,
      batchRequestId: this.batchInUse.id,
    };

    this.inventoryService.AddItemToBatchRequest(postData).subscribe(
      async () => {
        await this.messageService.add({
          severity: "success",
          summary: "Completed",
          detail: "Item added to batch request successfully!",
        });
        this.addItemForm.reset();
        this.batchInUse = null;
        this.theItem = null;
      },
      (error) => {
        this.messageService.add({
          severity: "error",
          summary: "Failed",
          detail:
            "Unable to add item to batch request at the moment | reason: " +
            (error ? error.error.message : "request failed - permission"),
        });
      }
    );
  }

  MakeBatchRequest() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Logging Batch Request For Approval...",
    });

    this.inventoryService
      .LogBatchRequestForApproval(this.batchInUse.id)
      .subscribe(
        async () => {
          await this.messageService.add({
            severity: "success",
            summary: "Completed",
            detail: "Batch request logged for approval successfully!",
          });
          this.addItemForm.reset();
          this.batchInUse = null;
          this.theItem = null;
          this.FetchAllRegionStockBatcheRequests();
        },
        (error) => {
          this.messageService.add({
            severity: "error",
            summary: "Failed",
            detail:
              "Unable to log batch request for approval at the moment | reason: " +
              error
                ? error.error.message
                : "request failed - permission",
          });
        }
      );
  }

  RemoveItemFromBatch(item: BatchRequestItem) {
    this.confirmationService.confirm({
      message: "Are you sure you want to remove item from batch request?",
      accept: () => {
        this.messageService.add({
          severity: "info",
          summary: "Notice",
          detail: "Removing Item From Batch Request...",
        });
        this.inventoryService.RemoveItemFromBatchRequest(item.id).subscribe(
          async () => {
            await this.messageService.add({
              severity: "success",
              summary: "Removed",
              detail: "Item removed from batch request successfully",
            });
            this.openItemsDialogue = false;
          },
          (error) => {
            this.messageService.add({
              severity: "error",
              summary: "Failed",
              detail:
                "Unable to remove item from bacth request at the moment | reason: " +
                error
                  ? error.error.message
                  : "request failed - permission",
            });
          }
        );
      },
    });
  }

  AddItemToStockBatch(item: StockBatch) {
    this.batchInUse = item;
  }
}
