import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { ConfirmationService, MessageService } from "primeng/api";
import { BreadcrumbService } from "src/app/breadcrumb.service";
import {
  CreateServiceCostAnalysisItemsVM,
  ServiceCostAnalysisLineItem,
  ServiceRelationshipEnum,
  Services,
  GroupedServiceCostAnalysis,
  UpdateServiceCostAnalysisItemsVM,
} from "src/app/interfaces/home";
import { ServicesService } from "src/app/services/services.service";

@Component({
  selector: "app-service-cost-analysis",
  templateUrl: "./service-cost-analysis.component.html",
  styleUrls: ["./service-cost-analysis.component.scss"],
})
export class ServiceCostAnalysisComponent implements OnInit {
  @ViewChild("formWrapper") public formWrapper: ElementRef;
  serviceCostAnalysisForm: FormGroup;
  allServices: Services[] = [];
  selectedServices: Services[] = [];
  editing: boolean;
  costCaption: string;
  addedCostLineItems: ServiceCostAnalysisLineItem[] = [];
  fetching: boolean;
  allServiceCostAnalysis: GroupedServiceCostAnalysis[];
  selectedServiceCostAnalysis: GroupedServiceCostAnalysis[] = [];
  cols: any[];
  serviceCostAnalysisToEdit: GroupedServiceCostAnalysis;

  constructor(
    private fb: FormBuilder,
    private breadcrumbService: BreadcrumbService,
    private servicesService: ServicesService,
    public confirmationService: ConfirmationService,
    public messageService: MessageService
  ) {
    this.serviceCostAnalysisForm = fb.group({
      Services: ["", Validators.required],
      CostCaption: [""],
      CostDescription: [""],
    });
  }

  ngOnInit(): void {
    this.breadcrumbService.setItems([
      {
        label: "CRM",
      },
      {
        label: "Service Cost Analysis",
        routerLink: ["/home/client-management/service-cost-analysis"],
      },
    ]);

    this.cols = [
      { field: "serviceName", header: "ServiceName" },
      { field: "lineItemsCount", header: "Number of Line Items" },
    ];

    this.fetching = true;
    this.GetAllServices();
  }

  get CostCaption() {
    return this.serviceCostAnalysisForm.get("CostCaption").value;
  }

  get CostDescription() {
    return this.serviceCostAnalysisForm.get("CostDescription").value;
  }

  async GetAllServices() {
    this.servicesService.allService().subscribe(
      async (data) => {
        if (data.responseCode != "00") {
          this.messageService.add({
            severity: "error",
            summary: data.responseMsg,
          });
          this.fetching = false;
          return;
        }

        this.allServices = data.responseData;
        this.allServices = this.allServices.filter(
          (x) => x.serviceRelationshipEnum != ServiceRelationshipEnum.Admin
        );
        this.GetAllServiceCostAnalysis();
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all services at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
        this.fetching = false;
      }
    );
  }

  async GetAllServiceCostAnalysis() {
    this.allServiceCostAnalysis = [];
    this.selectedServiceCostAnalysis = [];
    this.servicesService.GetAllServiceCostAnalysisItems().subscribe(
      async (data) => {
        if (data.responseCode != "00") {
          this.messageService.add({
            severity: "error",
            summary: data.responseMsg,
          });
          this.fetching = false;
          return;
        }

        let groupedByServiceId = [
          ...new Set(data.responseData.map((lineItem) => lineItem.serviceId)),
        ];
        groupedByServiceId.forEach((grpService) => {
          let serviceLineItems = data.responseData.filter(
            (x) => x.serviceId == grpService
          );
          this.allServiceCostAnalysis.push({
            serviceId: grpService,
            serviceName: this.allServices.find((x) => x.id == grpService)?.name,
            lineItemsCount: serviceLineItems.length,
            lineItems: serviceLineItems,
          });
        });
        this.fetching = false;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all service cost analysis at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
        this.fetching = false;
      }
    );
  }

  CreateServiceCostAnalysis() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Creating Service(s) Cost Analysis...",
    });

    const postData: CreateServiceCostAnalysisItemsVM = {
      serviceIds: this.selectedServices.map((x) => x.id),
      lineItems: [],
    };
    this.addedCostLineItems.forEach((x) =>
      postData.lineItems.push({
        caption: x.costCaption,
        description: x.costDescription,
      })
    );

    this.servicesService.CreateServiceCostAnalysisItems(postData).subscribe(
      async (data) => {
        if (data.responseCode != "00") {
          this.messageService.add({
            severity: "error",
            summary: data.responseMsg,
          });
          return;
        }

        this.messageService.add({
          severity: "success",
          summary: "Completed",
          detail: "Service(s) Cost Analysis Created Successfully!",
        });

        this.CloseEditing();
        this.GetAllServiceCostAnalysis();
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to create service cost analysis at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  AddCostLineItemToList() {
    let alreadyAdded = false;
    this.addedCostLineItems.forEach((item) => {
      if (item.costCaption == this.CostCaption.trim()) {
        alreadyAdded = true;
      }
    });

    if (alreadyAdded) {
      this.messageService.add({
        severity: "error",
        summary: "Failed",
        detail:
          "Cost Line Item with the same caption has already been added to list.",
      });
      return;
    }

    this.addedCostLineItems.push({
      costCaption: this.CostCaption.trim(),
      costDescription: this.CostDescription,
    });

    this.serviceCostAnalysisForm.patchValue({
      CostCaption: "",
      CostDescription: "",
    });
    this.messageService.add({
      severity: "success",
      summary: "Added",
      detail: "Cost Line Item added successfully",
    });
  }

  RemoveCostLineItem(item: ServiceCostAnalysisLineItem) {
    const index = this.addedCostLineItems.indexOf(item);
    if (index > -1) {
      this.addedCostLineItems.splice(index, 1);
    }
  }

  CloseEditing() {
    this.editing = false;
    this.serviceCostAnalysisForm.reset();
    this.addedCostLineItems = [];
    this.serviceCostAnalysisToEdit = null;
    this.selectedServiceCostAnalysis = [];
  }

  UpdateServiceCostAnalysis() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Updating Service Cost Analysis...",
    });

    const postData: UpdateServiceCostAnalysisItemsVM = {
      lineItems: [],
    };
    this.addedCostLineItems.forEach((x) =>
      postData.lineItems.push({
        id: x.id,
        caption: x.costCaption,
        description: x.costDescription,
      })
    );

    this.servicesService
      .UpdateServiceCostAnalysisItems(
        this.serviceCostAnalysisToEdit.serviceId,
        postData
      )
      .subscribe(
        async (data) => {
          if (data.responseCode != "00") {
            this.messageService.add({
              severity: "error",
              summary: data.responseMsg,
            });
            return;
          }

          this.messageService.add({
            severity: "success",
            summary: "Completed",
            detail: "Service(s) Cost Analysis Updated Successfully!",
          });

          this.CloseEditing();
          this.GetAllServiceCostAnalysis();
        },
        (error) => {
          console.log("Error: " + JSON.stringify(error));
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail:
              "Unable to update service cost analysis at the moment.. Reason: [" +
              (error ? error.error.message : "request failed - permission") +
              "]",
          });
        }
      );
  }

  DeleteServiceCostAnalysis(item: GroupedServiceCostAnalysis) {
    this.confirmationService.confirm({
      message: "Are you sure you want to remove service cost analysis?",
      accept: () => {
        this.messageService.add({
          severity: "info",
          summary: "Notice",
          detail: "Removing service cost analysis...",
        });

        this.servicesService
          .DeleteServiceCostAnalysisItems(item.serviceId)
          .subscribe(
            async () => {
              await this.messageService.add({
                severity: "success",
                summary: "Removed",
                detail: "Removed successfully",
              });
              this.GetAllServiceCostAnalysis();
            },
            (error) => {
              console.log("Error: " + JSON.stringify(error));
              this.messageService.add({
                severity: "error",
                summary: "Notice",
                detail:
                  "Unable to remove service cost analysis at the moment.. Reason: [" +
                  error
                    ? error.error.message
                    : "request failed - permission" + "]",
              });
            }
          );
      },
    });
  }

  EditServiceCostAnalysis(item: GroupedServiceCostAnalysis) {
    this.editing = true;
    this.serviceCostAnalysisToEdit = item;
    this.selectedServices = this.allServices.filter(
      (x) => x.id == item.serviceId
    );
    this.addedCostLineItems = item.lineItems;

    this.formWrapper.nativeElement.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "start",
    });
  }
}
