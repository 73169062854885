import { InventoryService } from "src/app/services/inventory.service";
import { GmaPremploymentService } from "./../../../services/gma-premployment.service";
import {
  CreateOperativeItem,
  CreateOperativeType,
  ExpirationActionEnum,
  OperativeItem,
  OperativeType,
  RemoveOperativeItems,
} from "./../../../interfaces/premployment";
import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MessageService, ConfirmationService } from "primeng/api";
import { BreadcrumbService } from "src/app/breadcrumb.service";
import { Item } from "src/app/interfaces/inventory";
import { FileStorageService } from "src/app/services/file-storage.service";
import { CommonResponse } from "src/app/interfaces/home";

@Component({
  selector: "app-guard-item-settings",
  templateUrl: "./guard-item-settings.component.html",
  styleUrls: ["./guard-item-settings.component.scss"],
})
export class GuardItemSettingsComponent implements OnInit {
  @ViewChild("formWrapper") public formWrapper: ElementRef;
  form: FormGroup;
  addItemForm: FormGroup;
  uploadedDocs: any[] = [];
  userIsYetToClickUploadDoc: boolean;
  userIsYetToClickUploadPolicyDoc: boolean;
  isCreatingOperativeType: boolean;
  allOperativeTypes: OperativeType[];
  selectedOperativeType: OperativeType;
  opTypeCols: any[];
  operativeTypeToLoad: OperativeType;
  opTypeItemsCols: any[];
  allOperativeTypeItems: OperativeItem[];
  selectedOperativeTypeItem: OperativeItem;
  operativeTypeItemsToRemove: OperativeItem[];
  allItems: Item[];
  theItem: Item;
  isAddingNewItemToOP: boolean = false;
  allExpirationAction: {
    value: string;
    key: number;
  }[];
  theExpirationAction: {
    value: string;
    key: number;
  };
  valCheck: number[] = [];
  uploadedSupportPolicyDoc: any[] = [];

  constructor(
    fb: FormBuilder,
    public gmaPremploymentService: GmaPremploymentService,
    public inventoryService: InventoryService,
    public messageService: MessageService,
    private breadcrumbService: BreadcrumbService,
    public confirmationService: ConfirmationService,
    private fileStorageService: FileStorageService
  ) {
    this.form = fb.group({
      Caption: ["", Validators.required],
      Description: ["", Validators.required],
    });

    this.addItemForm = fb.group({
      Item: ["", Validators.required],
      QtyIssued: ["", Validators.required],
      ItemLifeSpan: ["", Validators.required],
      ExpirationAction: ["", Validators.required],
      Notification: [""],
    });
  }

  ngOnInit(): void {
    this.breadcrumbService.setItems([
      {
        label: "Guard Management",
        routerLink: ["/home/guard-management/item-settings"],
      },
      {
        label: "Guard Item Settings",
        routerLink: ["/home/guard-management/item-settings"],
      },
    ]);

    this.opTypeCols = [
      { field: "caption", header: "Caption" },
      { field: "code", header: "Code" },
    ];

    this.opTypeItemsCols = [
      { field: "item.caption", header: "Item.caption" },
      { field: "item.code", header: "Item.code" },
      { field: "item.description", header: "Item.description" },
    ];

    this.allExpirationAction = [
      {
        value: "Recycle",
        key: ExpirationActionEnum.Recycle,
      },
      {
        value: "Discard",
        key: ExpirationActionEnum.Discard,
      },
      {
        value: "Allocate To Assignee",
        key: ExpirationActionEnum.Allocate_To_Issuer,
      },
      {
        value: "Allocate To Other",
        key: ExpirationActionEnum.Allocate_To_Other,
      },
    ];

    this.operativeTypeItemsToRemove = [];
    this.FetchAllOperativeTypes();
    this.FetchAllInventoryItems();
  }

  FetchAllInventoryItems() {
    this.inventoryService.GetAllItem().subscribe(
      (data) => {
        this.allItems = data;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Failed",
          detail:
            "Connection Error, Unable to fetch all items " +
            (error ? error.error.message : "request failed - permission"),
        });
      }
    );
  }

  FetchAllOperativeTypes() {
    this.gmaPremploymentService.GetAllOperativeType().subscribe(
      async (data) => {
        this.allOperativeTypes = data;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all operative types at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  async CreateOperativeType() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Creating Operative Type...",
    });

    const postData: CreateOperativeType = {
      caption: this.form.get("Caption").value,
      description: this.form.get("Description").value,
      uploadUrls: [],
    };

    if (this.uploadedDocs.length > 0) {
      this.fileStorageService.UploadMultipleFilesFromDataUrl(this.uploadedDocs);
      this.fileStorageService.onUploadFinished.subscribe(
        (resp: CommonResponse<string[]>) => {
          if (resp.responseCode != "00") {
            this.messageService.add({
              severity: "error",
              summary: "Notice",
              detail: resp.responseMsg,
            });
          } else {
            postData.uploadUrls = resp.responseData;
            this._createOperativeType(postData);
          }
        },
        (error) => {
          console.log("Error while uploading files " + error);
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail: "ERR: Unable to upload image to storage",
          });
        }
      );
    } else {
      this.messageService.add({
        severity: "error",
        summary: "Error",
        detail: "You must upload at least one doc.",
      });
    }
  }

  _createOperativeType(data: CreateOperativeType) {
    this.gmaPremploymentService.CreateOperativeType(data).subscribe(
      async () => {
        await this.messageService.add({
          severity: "success",
          summary: "Completed",
          detail: "Operative Type Created Successfully!",
        });

        this.FetchAllOperativeTypes();
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to craate operative type at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  onCancelUpload() {
    this.uploadedDocs = [];
  }

  NotifyUserToClickUpload() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Kindly ensure you click upload.",
    });

    this.userIsYetToClickUploadDoc = true;
  }

  OnUploadDoc(event) {
    this.uploadedDocs = [];
    for (const file of event.files) {
      this.uploadedDocs.push(file);
    }

    this.messageService.add({
      severity: "success",
      summary: "Success",
      detail: "Doc(s) Uploaded",
    });

    this.userIsYetToClickUploadDoc = false;
  }

  LoadItems(item: OperativeType) {
    this.isAddingNewItemToOP = false;
    this.operativeTypeToLoad = item;
    this.allOperativeTypeItems = item.operativeItems;
    this.operativeTypeItemsToRemove = [];
  }

  AddOPItemToSelected(item: OperativeItem) {
    if (this.operativeTypeItemsToRemove.indexOf(item) > -1) {
      this.operativeTypeItemsToRemove.splice(
        this.operativeTypeItemsToRemove.indexOf(item),
        1
      );
    } else {
      this.operativeTypeItemsToRemove.push(item);
    }
  }

  RemoveSelectedItems() {
    if (this.operativeTypeItemsToRemove.length == 0) {
      this.messageService.add({
        severity: "error",
        summary: "Error",
        detail: "You must select at least one item to remove.",
      });
      return;
    }

    this.confirmationService.confirm({
      message:
        "A total of " +
        this.operativeTypeItemsToRemove.length +
        " operative items will be removed. Do you still wish to proceed?",
      accept: () => {
        this.messageService.add({
          severity: "info",
          summary: "Notice",
          detail: "Removing Items...",
        });

        let operativeItemIds: number[] = [];
        this.operativeTypeItemsToRemove.forEach((item) => {
          operativeItemIds.push(item.id);
        });

        const postData: RemoveOperativeItems = {
          operativeTypeId: this.operativeTypeToLoad.id,
          operativeItemIds: operativeItemIds,
        };

        this.gmaPremploymentService
          .RemoveItemsFromOperativeType(postData)
          .subscribe(
            async () => {
              await this.messageService.add({
                severity: "success",
                summary: "Completed",
                detail: "Operative Items Removed Successfully!",
              });

              this.operativeTypeItemsToRemove = [];
              this.LoadItemsForOperativeType();
              this.FetchAllOperativeTypes();
            },
            (error) => {
              console.log("Error: " + JSON.stringify(error));
              this.messageService.add({
                severity: "error",
                summary: "Notice",
                detail:
                  "Unable to remove operative items at the moment.. Reason: [" +
                  error
                    ? error.error.message
                    : "request failed - permission" + "]",
              });
            }
          );
      },
    });
  }

  ShowAddNewOpTypeItem() {
    this.isAddingNewItemToOP = true;
  }

  async AddItemToOpType() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Creating Operative Item...",
    });

    const postData: CreateOperativeItem = {
      operativeTypeId: this.operativeTypeToLoad.id,
      itemId: this.theItem.id,
      qtyIssued: this.addItemForm.get("QtyIssued").value,
      daysSpan: this.addItemForm.get("ItemLifeSpan").value,
      expirationAction: this.theExpirationAction.key,
      notificationSettings: this.valCheck,
      supportingDoc: [],
    };

    if (this.uploadedSupportPolicyDoc.length > 0) {
      this.fileStorageService.UploadMultipleFilesFromDataUrl(
        this.uploadedSupportPolicyDoc
      );
      this.fileStorageService.onUploadFinished.subscribe(
        (resp: CommonResponse<string[]>) => {
          if (resp.responseCode != "00") {
            this.messageService.add({
              severity: "error",
              summary: "Notice",
              detail: resp.responseMsg,
            });
          } else {
            postData.supportingDoc = resp.responseData;
            this._addAddItemToOPType(postData);
          }
        },
        (error) => {
          console.log("Error while uploading files " + error);
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail: "ERR: Unable to upload image to storage",
          });
        }
      );
    } else await this._addAddItemToOPType(postData);
  }

  async _addAddItemToOPType(postData: CreateOperativeItem) {
    this.gmaPremploymentService.AddItemToOperativeType(postData).subscribe(
      async () => {
        await this.messageService.add({
          severity: "success",
          summary: "Completed",
          detail: "Operative Item Created Successfully!",
        });

        this.isAddingNewItemToOP = false;
        this.theItem = null;
        this.valCheck = [];
        this.uploadedSupportPolicyDoc = [];
        this.addItemForm.reset();
        this.LoadItemsForOperativeType();
        this.FetchAllOperativeTypes();
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to create operative item at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  LoadItemsForOperativeType() {
    if (this.operativeTypeToLoad) {
      this.gmaPremploymentService
        .GetAllItemsForOperativeType(this.operativeTypeToLoad.id)
        .subscribe(
          async (data) => {
            this.allOperativeTypeItems = data;
            this.operativeTypeItemsToRemove = [];
          },
          (error) => {
            console.log("Error: " + JSON.stringify(error));
            this.messageService.add({
              severity: "error",
              summary: "Notice",
              detail:
                "Unable to load all operative items for this operative type at the moment.. Reason: [" +
                error
                  ? error.error.message
                  : "request failed - permission" + "]",
            });
          }
        );
    }
  }

  onCancelDSupportPolicyUpload() {
    this.uploadedSupportPolicyDoc = [];
  }

  NotifyUserToClickUpload2() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Kindly ensure you click upload.",
    });

    this.userIsYetToClickUploadPolicyDoc = true;
  }

  onUploadPolicyDoc(event) {
    this.uploadedSupportPolicyDoc = [];
    for (const file of event.files) {
      this.uploadedSupportPolicyDoc.push(file);
    }

    this.messageService.add({
      severity: "success",
      summary: "Success",
      detail: "Supporting Policy Doc Uploaded",
    });

    this.userIsYetToClickUploadPolicyDoc = false;
  }
}
