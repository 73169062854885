import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {Observable} from 'rxjs';
import {CommonResponse, LeadContact} from '../interfaces/home';

@Injectable({
  providedIn: 'root'
})
export class LeadDivisionKeyPersonService {
  constructor(private http: HttpClient) { }
  baseUrl = environment.baseUrl+'LeadDivisionKeyPerson/';
  // fetch all LeadContact in the database
  allLeadDivisionKeyPerson(): Observable<CommonResponse>{
    return this.http.get<CommonResponse>(this.baseUrl);
  }
  getLeadDivisionKeyPerson(id: any):Observable<CommonResponse>{
    return this.http.get<CommonResponse>(this.baseUrl +id);
  }
  postLeadDivisionKeyPerson( data: any):Observable<CommonResponse>{
    return this.http.post<CommonResponse>(this.baseUrl, data);
  }
  deleteLeadDivisionKeyPerson(id: any){
    return this.http.delete(this.baseUrl +id);
  }
  updateLeadDivisionKeyPerson(id: any, data: any):Observable<CommonResponse>{
    return this.http.put<CommonResponse>(this.baseUrl +id, data);
  }
}
