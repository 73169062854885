import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { EngagementReason,CommonResponse } from '../interfaces/home';

@Injectable({
  providedIn: 'root'
})
export class EngagementReasonService {
  erobservable: Observable<CommonResponse>;
  constructor(private http: HttpClient) { }
  baseUrl = environment.baseUrl+'EngagementReason/';
  // fetch all EngagementReason in the database
  allEngagementReasonData(): Observable<CommonResponse>{
    if(this.erobservable) return this.erobservable
    else {
      this.erobservable = this.http.get<CommonResponse>(this.baseUrl).pipe(shareReplay()); 
      return this.erobservable;
    }
  }
  getEngagementReason(id: any):Observable<CommonResponse>{
    return this.http.get<CommonResponse>(this.baseUrl +id);
  }
  getEngagementReasonByName(name: any):Observable<CommonResponse>{
    return this.http.get<CommonResponse>(this.baseUrl + 'name/' + name);
  }
  postEngagementReason(data: any){
    return this.http.post(this.baseUrl, data);
  }
  deleteEngagementReason(id: any){
    return this.http.delete(this.baseUrl +id);
  }
  updateEngagementReason(id: any, data: any){
    return this.http.put(this.baseUrl +id, data);
  }
}
