import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { ConfirmationService, MessageService, TreeNode } from "primeng/api";
import { BreadcrumbService } from "src/app/breadcrumb.service";
import {
  Division,
  OperatingEntity,
  ServiceGroup,
  ServiceCategory,
  Services,
} from "src/app/interfaces/home";
import {
  Benefit,
  CreateServiceBenefitVM,
  ServiceBenefit,
} from "src/app/interfaces/platform-benefit";
import { DivisionService } from "src/app/services/division.service";
import { OperatingEntityService } from "src/app/services/operating-entity.service";
import { PlatformBenefitService } from "src/app/services/platform-benefit.service";
import { ServiceCategoryService } from "src/app/services/service-category.service";
import { ServiceGroupService } from "src/app/services/service-group.service";

@Component({
  selector: "app-pbm-map-service-benefit",
  templateUrl: "./pbm-map-service-benefit.component.html",
  styleUrls: ["./pbm-map-service-benefit.component.scss"],
})
export class PbmMapServiceBenefitComponent implements OnInit {
  @ViewChild("formWrapper") public formWrapper: ElementRef;
  plansTree: TreeNode[];
  editNode: TreeNode;
  loadingTree: boolean = true;
  selectedService: Services;
  allServiceMappedBenefits: ServiceBenefit[] = [];
  selectedServiceMappedBenefits: ServiceBenefit[];
  mappedBenefitCols: any[];
  mapNewServiceBenefit: boolean;
  allBenefits: Benefit[];
  theBenefit: Benefit;
  benefitPrice: number;
  benefitDuration: number;
  benefitQty: number;
  allDivisions: Division[];
  allMarkets: OperatingEntity[];
  allServiceGroup: ServiceGroup[];
  allServiceCategories: ServiceCategory[];
  allServices: Services[];

  constructor(
    private fb: FormBuilder,
    private divisionService: DivisionService,
    private operatingEntityService: OperatingEntityService,
    private serviceGroupService: ServiceGroupService,
    private serviceCategoryService: ServiceCategoryService,
    public platformBenefitService: PlatformBenefitService,
    private breadcrumbService: BreadcrumbService,
    public confirmationService: ConfirmationService,
    public messageService: MessageService
  ) {}

  ngOnInit(): void {
    this.breadcrumbService.setItems([
      {
        label: "Platform Benefits Management",
        routerLink: ["/home/platform-benefit"],
      },
      {
        label: "Map Service Benefit",
        routerLink: ["/home/platform-benefit/map-service-benefit"],
      },
    ]);

    this.mappedBenefitCols = [
      { field: "GetBenefitName(benefitId)", header: "Benefit Name" },
      { field: "duration", header: "Duration" },
      { field: "quantityLimit", header: "Quantity Limit" },
      { field: "amount", header: "Amount" },
    ];

    this.GetAllDivisions();
    this.GetAllBenefits();
  }

  async GetAllDivisions() {
    this.divisionService.allDivisionData().subscribe(
      async (data) => {
        this.allDivisions = data.responseData;
        this.GetAllMarkets();
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all divsions at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  async GetAllMarkets() {
    this.operatingEntityService.allData().subscribe(
      async (data) => {
        this.allMarkets = data.responseData;
        this.GetAllServiceGroup();
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all operating entities at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  async GetAllServiceGroup() {
    this.serviceGroupService.allServiceGroup().subscribe(
      async (data) => {
        this.allServiceGroup = data.responseData;
        this.GetAllServiceCategory();
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all service group at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  async GetAllServiceCategory() {
    this.serviceCategoryService.allServiceCategory().subscribe(
      async (data) => {
        this.allServiceCategories = data.responseData;
        this.GetAllServices();
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all service categories at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  async GetAllServices() {
    this.platformBenefitService.GetAllServicesWithBenefits().subscribe(
      async (data) => {
        this.allServices = data;
        this.PopulateServicesTree();
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all services at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  async GetAllBenefits() {
    this.platformBenefitService.GetAllBenefits().subscribe(
      async (data) => {
        this.allBenefits = data;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all benefits at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  async ShowData(event) {
    if (event.node.data) {
      this.editNode = event.node;
      if (this.selectedService !== event.node.data) {
        this.selectedService = event.node.data;
        this.allServiceMappedBenefits =
          this.selectedService.serviceBenefits.filter(
            (x) => x.isDeleted == false
          );
        this.mapNewServiceBenefit = false;
      }
    }
  }

  PopulateServicesTree() {
    this.plansTree = [];
    let divisionTree: TreeNode[] = [];

    this.allDivisions.forEach((division) => {
      let marketTree: TreeNode[] = [];
      this.allMarkets
        .filter((x) => x.divisionId == division.id)
        .forEach((market) => {
          let serviceGroupTree: TreeNode[] = [];
          this.allServiceGroup
            .filter((x) => x.operatingEntity.id == market.id)
            .forEach((serviceGroup) => {
              let serviceCategoryTree: TreeNode[] = [];
              this.allServiceCategories
                .filter((x) => x.serviceGroupId == serviceGroup.id)
                .forEach((serviceCategory) => {
                  let serviceTree: TreeNode[] = [];
                  this.allServices
                    .filter((x) => x.serviceCategoryId == serviceCategory.id)
                    .forEach((service) => {
                      serviceTree.push({
                        label: service.name,
                        key: "Service-" + service.id,
                        icon: "pi pi-sitemap",
                        data: service,
                        selectable: true,
                      });
                    });

                  serviceCategoryTree.push({
                    label: serviceCategory.name,
                    key: "ServiceCategory-" + serviceCategory.id,
                    expandedIcon: "pi pi-minus-circle",
                    collapsedIcon: "pi pi-plus-circle",
                    expanded: true,
                    data: null,
                    children: serviceTree,
                    selectable: false,
                  });
                });

              serviceGroupTree.push({
                label: serviceGroup.name,
                key: "ServiceGroup-" + serviceGroup.id,
                expandedIcon: "pi pi-minus-circle",
                collapsedIcon: "pi pi-plus-circle",
                expanded: true,
                data: null,
                children: serviceCategoryTree,
                selectable: false,
              });
            });

          marketTree.push({
            label: market.name,
            key: "Market-" + market.id,
            expandedIcon: "pi pi-minus-circle",
            collapsedIcon: "pi pi-plus-circle",
            expanded: true,
            data: null,
            children: serviceGroupTree,
            selectable: false,
          });
        });

      divisionTree.push({
        label: division.name,
        key: "Division-" + division.id,
        expandedIcon: "pi pi-minus-circle",
        collapsedIcon: "pi pi-plus-circle",
        expanded: true,
        data: null,
        children: marketTree,
        selectable: false,
      });
    });

    this.plansTree = [
      {
        label: "Halogen",
        key: "Halogen Folder",
        expandedIcon: "pi pi-minus-circle",
        collapsedIcon: "pi pi-plus-circle",
        expanded: true,
        children: divisionTree,
        data: null,
        selectable: false,
      },
    ];

    this.loadingTree = false;
  }

  MapNewServiceBenefit() {
    this.mapNewServiceBenefit = true;
  }

  GetBenefitName(benefitId: number): string {
    let benefit = this.allBenefits.find((x) => x.id == benefitId);
    if (benefit) return benefit.name;
    return "N/A";
  }

  GetBenefitDecription(benefitId: number): string {
    let benefit = this.allBenefits.find((x) => x.id == benefitId);
    if (benefit) return benefit.description;
    return "N/A";
  }

  DeleteMappedBenefit(item: ServiceBenefit) {
    this.confirmationService.confirm({
      message: "Are you sure you want to remove service benefit?",
      accept: () => {
        this.messageService.add({
          severity: "info",
          summary: "Notice",
          detail: "Removing service benefit...",
        });

        this.platformBenefitService.DeleteServiceBenefit(item.id).subscribe(
          async () => {
            this.messageService.add({
              severity: "success",
              summary: "Removed",
              detail: "Removed successfully",
            });

            const index = this.allServiceMappedBenefits.indexOf(item);
            if (index > -1) {
              this.allServiceMappedBenefits.splice(index, 1);
            }
            this.GetAllServices();
          },
          (error) => {
            console.log("Error: " + JSON.stringify(error));
            this.messageService.add({
              severity: "error",
              summary: "Notice",
              detail:
                "Unable to remove service benefits at the moment.. Reason: [" +
                error
                  ? error.error.message
                  : "request failed - permission" + "]",
            });
          }
        );
      },
    });
  }

  MapBenefit() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Creating Service Benefit...",
    });

    const postData: CreateServiceBenefitVM = {
      serviceId: this.selectedService.id,
      benefitId: this.theBenefit.id,
      benefitPrice: this.benefitPrice,
      durationLimit: this.benefitDuration,
      quantityLimit: this.benefitQty,
    };

    this.platformBenefitService.CreateServiceBenefit(postData).subscribe(
      async () => {
        this.messageService.add({
          severity: "success",
          summary: "Completed",
          detail: "Service Benefit Created Successfully!",
        });

        this.theBenefit = null;
        this.benefitDuration = null;
        this.benefitQty = null;
        this.benefitPrice = null;

        this.allServiceMappedBenefits.push({
          benefitId: postData.benefitId,
          serviceId: postData.serviceId,
          price: postData.benefitPrice,
          quantityLimit: postData.quantityLimit,
          duration: postData.durationLimit,
        });
        this.GetAllServices();
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to create service benefit at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }
}
