import {
  DeployedGuard,
  DetachGuardOP,
  GuardDeployment,
  GuardLocationReDeployment,
} from "./../../../interfaces/employment";
import {
  Contract,
  ContractService,
  Location,
  ServiceRelationshipEnum,
} from "./../../../interfaces/home";
import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { MessageService, ConfirmationService, Message } from "primeng/api";
import { BreadcrumbService } from "src/app/breadcrumb.service";
import { CustomerDivision, GroupType } from "src/app/interfaces/home";
import { GmaEmploymentService } from "src/app/services/gma-employment.service";

@Component({
  selector: "app-gma-redeployment",
  templateUrl: "./gma-redeployment.component.html",
  styleUrls: ["./gma-redeployment.component.scss"],
  providers: [MessageService],
})
export class GmaRedeploymentComponent implements OnInit {
  @ViewChild("formWrapper") public formWrapper: ElementRef;
  activeSwitch: boolean;
  msgs: Message[] = [];
  allOperatives: DeployedGuard[] = [];
  tempAllOperatives: DeployedGuard[] = [];
  theOperative: DeployedGuard;
  allClientTypes: GroupType[];
  theClientType: GroupType;
  allClients: CustomerDivision[];
  theClient: CustomerDivision;
  allFetchedClientsData: CustomerDivision[] = [];
  allLocations: Location[];
  theLocation: Location;
  allContractServices: ContractService[];
  theContractService: ContractService;
  allContracts: Contract[];
  theContract: Contract;
  locDeployTable: {
    name: string;
    data: string;
  }[];
  selectedLocDeployTable: {
    name: string;
    data: string;
  };
  allSelectedOperatives: DeployedGuard[] = [];
  isDetachingGuards: boolean = false;
  allGuardOperativeDeployments: GuardDeployment[];

  constructor(
    private fb: FormBuilder,
    public employmentService: GmaEmploymentService,
    public messageService: MessageService,
    private breadcrumbService: BreadcrumbService,
    public confirmationService: ConfirmationService
  ) {}

  ngOnInit(): void {
    this.breadcrumbService.setItems([
      {
        label: "Guard Management",
        routerLink: ["/home/guard-management/item-settings"],
      },
      {
        label: "GMA Redeployment",
        routerLink: ["/home/guard-management/gma-redeployment"],
      },
    ]);

    this.Reset();

    this.locDeployTable = [
      {
        name: "Name",
        data: "Data",
      },
      {
        name: "Address",
        data: "Data",
      },
      {
        name: "Client",
        data: "Data",
      },
      {
        name: "Avalaible Slots",
        data: "Data",
      },
      {
        name: "Contract Service",
        data: "Data",
      },
    ];

    this.FetchAllGuardOperatives();
    this.FetchAllGroupTypes();
  }

  // FetchAllGuardOperatives() {
  //   this.employmentService.GetAllGuardOperatives().subscribe(
  //     async (data) => {
  //       this.allOperatives = data;
  //       this.tempAllOperatives = data;
  //       this.Reset();
  //       this.LoadAllOperativesLocation();
  //     },
  //     (error) => {
  //       console.log("Error: " + JSON.stringify(error));
  //       this.messageService.add({
  //         severity: "error",
  //         summary: "Notice",
  //         detail:
  //           "Unable get all guard operatives at the moment.. Reason: [" +
  //           error ? error.error.message : "request failed - permission" +
  //           "]",
  //       });
  //       this.Reset();
  //     }
  //   );
  // }

  FetchAllGuardOperatives() {
    this.employmentService.GetAllGuardOperativesAlone().subscribe(
      async (data) => {
        let deployedOperativeIds = data.map((x) => x.id);
        this.employmentService
          .GetAllGuardOperativesDeployments({
            deployedGuardIds: deployedOperativeIds,
          })
          .subscribe(
            async (innerData) => {
              this.allGuardOperativeDeployments = innerData;

              this.allOperatives = data;
              this.tempAllOperatives = data;
              this.Reset();
              this.LoadAllOperativesLocation();
            },
            (error) => {
              console.log("Error: " + JSON.stringify(error));
              this.messageService.add({
                severity: "error",
                summary: "Notice",
                detail:
                  "Unable get all guard operatives deployments at the moment.. Reason: [" +
                  error
                    ? error.error.message
                    : "request failed - permission" + "]",
              });
              this.Reset();
            }
          );
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable get all guard operatives at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
        this.Reset();
      }
    );
  }

  LoadAllOperativesLocation() {
    this.allOperatives.forEach((operative) => {
      operative.location = this.GetOperativeLocation(operative);
    });

    this.SwitchActiveStatus(event);
  }

  FetchAllGroupTypes() {
    this.employmentService.GetAllClientTypesAlone().subscribe(
      async (data) => {
        this.allClientTypes = data;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable get all client types at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
        this.Reset();
      }
    );
  }

  FetchAllClients() {
    this.employmentService.GetAllClientsAlone().subscribe(
      async (data) => {
        this.allFetchedClientsData = data;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all clients at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
        this.Reset();
      }
    );
  }

  LoadClientsUnderClientType() {
    this.theClient = null;
    this.theLocation = null;
    this.theContractService = null;
    if (this.theClientType) {
      this.employmentService
        .GetAllGroupTypeClients(this.theClientType.id)
        .subscribe(
          async (data) => {
            this.allClients = data;
          },
          (error) => {
            console.log("Error: " + JSON.stringify(error));
            this.messageService.add({
              severity: "error",
              summary: "Notice",
              detail:
                "Unable to fetch group type clients at the moment.. Reason: [" +
                error
                  ? error.error.message
                  : "request failed - permission" + "]",
            });
          }
        );
    }
  }

  LoadClientLocations() {
    this.theLocation = null;
    this.theContractService = null;
    if (this.theClient) {
      this.employmentService
        .GetAllClientsLocations(this.theClient.id)
        .subscribe(
          async (data) => {
            this.allLocations = data;
            this.FetchClientContracts(this.theClient.id);
          },
          (error) => {
            console.log("Error: " + JSON.stringify(error));
            this.messageService.add({
              severity: "error",
              summary: "Notice",
              detail:
                "Unable to fetch client locations at the moment.. Reason: [" +
                error
                  ? error.error.message
                  : "request failed - permission" + "]",
            });
          }
        );
    }
  }

  FetchClientContracts(customerDivisionId: number) {
    this.employmentService
      .GetAllClientLocationContractServices(customerDivisionId)
      .subscribe(
        async (data) => {
          this.theClient.contracts = data;
        },
        (error) => {
          console.log("Error: " + JSON.stringify(error));
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail:
              "Unable to fetch client contracts at the moment.. Reason: [" +
              error
                ? error.error.message
                : "request failed - permission" + "]",
          });
        }
      );
  }

  LoadLocationContracts() {
    this.theContract = null;
    this.allContractServices = [];
    this.theContractService = null;
    if (this.theLocation) {
      this.allContracts = this.theClient.contracts;

      this.locDeployTable[0].data = this.theLocation.name;
      this.locDeployTable[1].data = this.theLocation.street;
      this.locDeployTable[2].data = this.theClient.divisionName;
    }
  }

  LoadLocationContractServices_V2() {
    this.theContractService = null;
    if (this.theLocation && this.theContract) {
      this.allContractServices = [];
      this.theClient.contracts
        .filter((x) => x.id == this.theContract.id)
        .forEach((contract) => {
          contract.contractServices.forEach((contractService) => {
            let checkValid = this.theLocation.serviceCategories.find(
              (x) =>
                x.serviceCategoryId == contractService.service.serviceCategoryId
            );
            if (checkValid) {
              const index = this.allContractServices.indexOf(contractService);
              if (index <= -1) {
                this.allContractServices.push(contractService);
              }
            }
          });
        });

      this.IncludeContractServiceLabels();
    }
  }

  LoadLocationContractServices() {
    this.theContractService = null;
    if (this.theLocation) {
      this.allContractServices = [];
      this.theClient.contracts.forEach((contract) => {
        contract.contractServices.forEach((contractService) => {
          let checkValid = this.theLocation.serviceCategories.find(
            (x) =>
              x.serviceCategoryId == contractService.service.serviceCategoryId
          );
          if (checkValid) {
            const index = this.allContractServices.indexOf(contractService);
            if (index <= -1) {
              this.allContractServices.push(contractService);
            }
          }
        });
      });

      this.locDeployTable[0].data = this.theLocation.name;
      this.locDeployTable[1].data = this.theLocation.street;
      this.locDeployTable[2].data = this.theClient.divisionName;
      this.IncludeContractServiceLabels();
    }
  }

  LocationAttachmentAnanlysis() {
    if (this.theContractService) {
      this.locDeployTable[3].data =
        this.theContractService.quantity -
        this.theContractService.guardDeployments.filter(
          (x) => x.isDeleted == false
        ).length +
        "";
      this.locDeployTable[4].data = this.theContractService.service.name;
    }
  }

  ReDeployGuard() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Redeploying Guard...",
    });
    this.Reset();

    const postData: GuardLocationReDeployment = {
      deployedGuardIds: this.allSelectedOperatives.map((x) => x.id),
      locationId: this.theLocation.id,
      contractServiceId: this.theContractService.id,
    };

    this.employmentService.RedeployDeployedGuard(postData).subscribe(
      () => {
        this.messageService.add({
          severity: "success",
          summary: "Completed",
          detail: "Guard(s) Redeployed Successfully...",
        });
        //this.Reset();

        this.theContractService = null;
        this.theLocation = null;
        this.theClient = null;
        this.allSelectedOperatives = [];
        this.allOperatives = [];
        this.tempAllOperatives = [];
        this.FetchAllGuardOperatives();
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to redeploy guards at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
        this.Reset();
      }
    );
  }

  Reset() {
    this.msgs = [];
    this.msgs.push({
      severity: "info",
      summary: "Summary:",
      detail:
        "There are " +
        this.allOperatives.length +
        " operatives ready for your deployment/redeployment.",
    });
  }

  RemoveChip(item: any) {
    const index = this.allSelectedOperatives.indexOf(item);
    if (index > -1) {
      this.allSelectedOperatives.splice(index, 1);
    }
    this.allSelectedOperatives = [...this.allSelectedOperatives];
  }

  SwitchActiveStatus($event) {
    if (this.activeSwitch)
      this.allOperatives = this.tempAllOperatives.filter(
        (x) => this.GetOperativeLocation(x) != "Pool"
      );
    else
      this.allOperatives = this.tempAllOperatives.filter(
        (x) => this.GetOperativeLocation(x) == "Pool"
      );
  }

  DetachGuardOperatives() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Detaching Guard(s)...",
    });
    this.Reset();

    const postData: DetachGuardOP = {
      guardOperatives: this.allSelectedOperatives.map((x) => x.id),
    };

    this.employmentService.DetachGuardOperatives(postData).subscribe(
      () => {
        this.messageService.add({
          severity: "success",
          summary: "Completed",
          detail: "Guard(s) Detached Successfully...",
        });
        //this.Reset();

        this.isDetachingGuards = false;
        this.theContractService = null;
        this.theLocation = null;
        this.theClient = null;
        this.allSelectedOperatives = [];
        this.allOperatives = [];
        this.tempAllOperatives = [];
        this.FetchAllGuardOperatives();
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to detach guards at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
        this.Reset();
      }
    );
  }

  IncludeContractServiceLabels() {
    this.allContractServices.forEach((contractServ) => {
      contractServ.label =
        contractServ.service.name + "-" + contractServ.uniqueTag;
    });

    this.allContractServices = this.allContractServices.filter(
      (x) => x.service.serviceRelationshipEnum == ServiceRelationshipEnum.Direct
    );
  }

  GetOperativeLocation(item: DeployedGuard): string {
    item.guardDeployments = this.allGuardOperativeDeployments.filter(
      (x) => x.deployedGuardId == item.id
    );

    if (item.guardDeployments.length <= 0) return "Pool";
    let currentDeployment =
      item.guardDeployments[item.guardDeployments.length - 1];

    if (currentDeployment && currentDeployment.location)
      return currentDeployment.location.name;
    else return "Pool";
  }
}
