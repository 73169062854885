import { Component, OnInit } from '@angular/core';
import {
  ArmedEscortProfile,
  CommanderUserProfile,
  OtherPassenger,
  ServiceVehicleInterface,
  SupplierServices
} from '../../../../../interfaces/armada';
import {DynamicDialogConfig, DynamicDialogRef} from 'primeng/dynamicdialog';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ContractService} from '../../../../../interfaces/home';

@Component({
  selector: 'app-passenger',
  templateUrl: './passenger.component.html',
  styleUrls: ['./passenger.component.scss']
})
export class PassengerComponent implements OnInit {
  armedEscorts: ArmedEscortProfile[] = [];
  commanders: CommanderUserProfile[] = [];
  passengers: OtherPassenger[] = [];
  passengerForm: FormGroup;
  vehicle: SupplierServices;
  private contractService: ContractService;
  public showPassengerForm: boolean;
  public showCommanderForm: boolean;
  public commanderForm: FormGroup;
  selectedArmedEscort: ArmedEscortProfile[] = [];
  selectedCommanders: CommanderUserProfile[];
  availableCommanders: CommanderUserProfile[] =[];
  availableArmedEscort: ArmedEscortProfile[] = [];
  public edPassenger: OtherPassenger;
  private editingIndex: any;
  constructor(
      private dynamicDialogRef: DynamicDialogRef,
      private configData: DynamicDialogConfig,
      private formBuilder: FormBuilder,
  ) { }

  ngOnInit(): void {
    this.passengers = this.configData.data.serviceVehicle.passengers;
    this.commanders = this.configData.data.serviceVehicle.commanders;
    this.armedEscorts = this.configData.data.serviceVehicle.armedEscort;
    this.vehicle = this.configData.data.serviceVehicle.vehicle;
    this.contractService = this.configData.data.contractService;
    this.availableCommanders = this.configData.data.availableCommanders;
    this.availableArmedEscort = this.configData.data.availableArmedEscort;
    this.passengerForm = this.formBuilder.group({
      passengerName: ['', [Validators.required, Validators.minLength(3)]],
      passengerEmail: ['', [Validators.required, Validators.email]],
      passengerPhone: ['', [Validators.required, Validators.minLength(10)]],
      contractServiceId: [this.contractService.id],
    });
    this.commanderForm = this.formBuilder.group({
      commanderIds: [null, Validators.required],
      armedEscortIds: [null],
    })
  }

  addPassenger() {
    this.passengers.push(this.passengerForm.value);
   this.closePassengerForm();
  }
  updatePassenger() {
    this.passengers[this.editingIndex] = this.passengerForm.value;
    // this.passengers.push(this.passengerForm.value);
   this.closePassengerForm();
  }

  closePassengerForm() {
    this.edPassenger = null;
    this.passengerForm.reset();
    return this.showPassengerForm = false;
  }

  openPassengerForm() {
    return this.showPassengerForm = true;
  }

  addCommander() {
    this.commanders.push(this.commanderForm.value)
    this.closeCommanderForm();
  }

  private closeCommanderForm() {
    this.showCommanderForm = false;
  }

  closeDialog(data?: any) {
    this.dynamicDialogRef.close(data);
  }

  complete() {
    const data: ServiceVehicleInterface = {
      commanders: this.selectedCommanders,
      vehicle: this.vehicle,
      armedEscort: this.selectedArmedEscort,
      passengers: this.passengers,
    }
    return this.closeDialog(data);
  }

    edit(index: any) {
    this.editingIndex = index;
    this.edPassenger = this.passengers[index];
    this.passengerForm.setValue({
      passengerName: this.edPassenger.passengerName,
      passengerEmail: this.edPassenger.passengerEmail,
      passengerPhone: this.edPassenger.passengerPhone,
      contractServiceId: [this.contractService.id],
    })
    this.openPassengerForm();
    }

  remove(index: any) {
    this.closePassengerForm();
    this.passengers.splice(index, 1);
  }
}
