import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {Observable} from 'rxjs';
import {CommonResponse} from '../interfaces/home';
import { SmtpMailer } from '../interfaces/armada';

@Injectable({
  providedIn: 'root'
})
export class QuoteService {
  constructor(private http: HttpClient) {
  }
  baseUrl = environment.baseUrl + 'Quote/';
  // fetch all Quote in the database
  allQuote(): Observable<CommonResponse> {
    return this.http.get<CommonResponse>(this.baseUrl);
  }
  getQuote(id: any): Observable<CommonResponse> {
    return this.http.get<CommonResponse>(this.baseUrl + id);
  }

  getQuoteByLeadDivisionId(id){
    return this.http.get(this.baseUrl + 'ByLeadDivision/' + id);

  }
  getQuoteByName(name: any): Observable<CommonResponse> {
    return this.http.get<CommonResponse>(this.baseUrl + 'name/' + name);
  }

  postQuote(data: any) {
    return this.http.post(this.baseUrl, data);
  }

  deleteQuote(id: any) {
    return this.http.delete(this.baseUrl + id);
  }

  updateQuote(id: any, data: any) {
    return this.http.put(environment.baseUrl + 'QuoteService/' + id, data);
  }

  updateQuoteData(id: any, data: any) {
    return this.http.put(this.baseUrl + 'QuoteNameUpdate/' + id + "/" + data, "");
  }

  sendEmailToClient(data: SmtpMailer) {
    return this.http.post(environment.baseMailUrl + 'SendSMTPMail', data);
  }
}
