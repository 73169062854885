import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {QualificationComponent} from './qualification.component';
import {GroupTypeComponent} from './group-type/group-type.component';
import {LeadTypeComponent} from './lead-type/lead-type.component';
import {RelationshipTypeComponent} from './relationship-type/relationship-type.component';
import {AccountVoucherTypeComponent} from './account-voucher-type/account-voucher-type.component';
import {MeanOfIdComponent} from './mean-of-id/mean-of-id.component';
import {LeadOriginComponent} from './lead-origin/lead-origin.component';
import { EndorsementTypeComponent } from './endorsement-type/endorsement-type.component';

const routes: Routes = [
  {
    path: '',
    component: QualificationComponent,
    children: [
      {
        path: 'group-type',
        component: GroupTypeComponent,
      },
      {
        path: 'lead-type',
        component: LeadTypeComponent,
      },
      {
        path: 'relationship-type',
        component: RelationshipTypeComponent,
      },
      {
        path: 'account-voucher-type',
        component: AccountVoucherTypeComponent,
      },
      {
        path: 'means-of-identification',
        component: MeanOfIdComponent,
      },
      {
        path: 'lead-origin',
        component: LeadOriginComponent,
      },
      {
        path: 'endorsement-type',
        component: EndorsementTypeComponent,
      },
      {
        path: '**',
        redirectTo: 'group-type',
        pathMatch: 'full'
      }
    ]
  },
  {
    path: '',
    redirectTo: '/qualification/group-type',
    pathMatch: 'full'
  },
  {
    path: '**',
    redirectTo: '/qualification/group-type',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class QualificationRoutingModule { }
