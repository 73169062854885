import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {Observable} from 'rxjs';
import {CommonResponse, Role} from '../interfaces/home';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private http: HttpClient) { }
  baseUrl = environment.baseUrl + 'User/';
  allUser(): Observable<CommonResponse>{
    return this.http.get<CommonResponse>(this.baseUrl);
  }
  getUser(id: any):Observable<CommonResponse>{
    return this.http.get<CommonResponse>(this.baseUrl +id);
  }
  postUser(data: any){
    return this.http.post(this.baseUrl, data);
  }
  deleteUser(id: any){
    return this.http.delete(this.baseUrl +id);
  }
  updateUser(id: any, data: any){
    return this.http.put(this.baseUrl +id, data);
  }
  getUserByEmail(email: any):Observable<CommonResponse>{
    return this.http.get<CommonResponse>(this.baseUrl + 'email/' + email);
  }
  getUserNotInSbu(id):Observable<CommonResponse>{
    return this.http.get<CommonResponse>(this.baseUrl + 'NotInSbu/' + id);
  }

  createPassword(request): Observable<CommonResponse>{
    return this.http.post<CommonResponse>(environment.baseUrl + 'Auth/CreatePassword', request)
  }
  
  updatePassword(request): Observable<CommonResponse>{
    return this.http.put<CommonResponse>(environment.baseUrl + 'Auth/UpdatePassword', request)
  }

  authenticateUser(token: string): Observable<CommonResponse>{
    return this.http.post<CommonResponse>(environment.baseUrl + 'Auth/googleLogin', {idToken: token})
  }
  authenticateOtherUser(email: string, password: string): Observable<CommonResponse>{
    return this.http.post<CommonResponse>(environment.baseUrl + 'Auth/login', {email: email, password: password})
  }
  createAndAuthenticateUser(data: any): Observable<CommonResponse>{
    return this.http.post<CommonResponse>(environment.baseUrl + 'Auth/createuser', data)
  }
  updateUserRole(userId: any, roles: Role[]){
    return this.http.put(this.baseUrl + 'UpdateUserRole/' + userId, roles);
  }
  attachUserToSBU(userId: any, sbuId: any){
    return this.http.put(this.baseUrl + userId + '/StrategicBusinessUnit/' + sbuId, {});
  }
  detachUserFromSBU(userId: any){
    return this.http.put(this.baseUrl + userId + '/DetachStrategicBusinessUnit', {});
  }
}
