import {
  ApprovingLevelOffice,
  ApprovingLevelOfficeReceivingDTO,
  EndorsementType,
} from "./../../../interfaces/home";
import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ConfirmationService, MessageService } from "primeng/api";
import { BreadcrumbService } from "src/app/breadcrumb.service";
import { ApprovalLevel, CommonResponse, User } from "src/app/interfaces/home";
import { ApprovalLevelService } from "src/app/services/approval-level.service";
import { EndorsementTypeService } from "src/app/services/endorsement-type.service";
import { UserService } from "src/app/services/user.service";

@Component({
  selector: "app-approval-setup2",
  templateUrl: "./approval-setup2.component.html",
  styleUrls: ["./approval-setup2.component.scss"],
})
export class ApprovalSetup2Component implements OnInit {
  @ViewChild("formWrapper") public formWrapper: ElementRef;
  approvingOfficeForm: FormGroup;
  theApprovingUser: User;
  theUser: User;
  allUsers: User[] = [];
  addedApprovingOfficers: any[];
  selectedApprovingOfficer: any;
  editingApprovingOffice: boolean;
  approvingOfficeToEdit: any;
  fetchingApprovalOffices = true;
  allApprovingOffice: ApprovingLevelOffice[];
  selectedApprovingOffice: ApprovingLevelOffice;
  approvalOfficeCols: any[];
  allEndorsementTypes: EndorsementType[];
  selectedEndorsementTypes: EndorsementType[] = [];
  // allApprovalLevels: ApprovalLevel[];
  // theApprovingLevel: ApprovalLevel;

  constructor(
    private fb: FormBuilder,
    public userService: UserService,
    private endorsementTypeService: EndorsementTypeService,
    public approvalLevelService: ApprovalLevelService,
    private breadcrumbService: BreadcrumbService,
    public confirmationService: ConfirmationService,
    public messageService: MessageService
  ) {
    this.approvingOfficeForm = fb.group({
      EndorsmentType: [""],
      ApprovingUser: [""],
      OfficeName: ["", Validators.required],
    });
  }

  ngOnInit(): void {
    this.breadcrumbService.setItems([
      {
        label: "Setup",
        routerLink: ["/home/setups"],
      },
      {
        label: "Approval-Backup-Setup",
        routerLink: ["/home/approval-setup2"],
      },
    ]);

    this.addedApprovingOfficers = [];
    this.approvalOfficeCols = [
      { field: "name", header: "Name" },
      { field: "typeName", header: "Type" },
    ];

    this.FetchAllUsers();
    this.FetchAllApprovingOffice();
    this.FetchAllEndorsementTypes();
  }

  get ApprovingUser() {
    return this.approvingOfficeForm.get("ApprovingUser").value;
  }

  FetchAllUsers() {
    this.userService.allUser().subscribe(
      async (r) => {
        var data = r.responseData ?? [];
        this.allUsers = [];
        data.forEach((user) => {
          user.fullName = user.lastName + " " + user.firstName;
          this.allUsers.push(user);
        });
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all users at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  async FetchAllEndorsementTypes() {
    this.endorsementTypeService.allEndorsementTypeData().subscribe(
      async (data) => {
        if (data.responseCode != "00") {
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail: data["responseMsg"],
          });
          return;
        }

        this.allEndorsementTypes = data.responseData ?? [];
        this.allEndorsementTypes.push({
          caption: "Service Creation",
          openingDate: null,
          closingDate: null,
        });
        this.allEndorsementTypes.push({
          caption: "Addition Contract Creation",
          openingDate: null,
          closingDate: null,
        });
        this.allEndorsementTypes.push({
          caption: "Contract Creation",
          openingDate: null,
          closingDate: null,
        });
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all endorsement types at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  AddApprovingOfficerToList() {
    if (!this.ApprovingUser) {
      this.messageService.add({
        severity: "error",
        summary: "Failed",
        detail: "You must select the officer to add",
      });
      return;
    }

    if (this.theUser) {
      if (this.theApprovingUser == this.theUser) {
        this.messageService.add({
          severity: "error",
          summary: "Failed",
          detail: "You can't select same user as a temp approving officer",
        });
        return;
      }
    }

    let alreadyAdded = false;
    this.addedApprovingOfficers.forEach((item) => {
      if (item.userId == this.ApprovingUser.id) {
        alreadyAdded = true;
      }
    });

    if (alreadyAdded) {
      this.messageService.add({
        severity: "error",
        summary: "Failed",
        detail: "Officer with the same info has already been added to list.",
      });
      return;
    }

    this.addedApprovingOfficers.push({
      userId: this.ApprovingUser.id,
      user: this.ApprovingUser,
    });

    this.theApprovingUser = null;
    this.messageService.add({
      severity: "success",
      summary: "Added",
      detail: "Approving Officer added successfully",
    });
  }

  RemoveOfficer(item: any) {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Removing Approving Officer...",
    });

    const index = this.addedApprovingOfficers.indexOf(item);
    if (index > -1) {
      this.addedApprovingOfficers.splice(index, 1);
    }

    this.messageService.add({
      severity: "success",
      summary: "Removed",
      detail: "Approving Officer Removed successfully",
    });
  }

  FetchAllApprovingOffice() {
    this.approvalLevelService.GetAllApprovingLevelOffices().subscribe(
      async (data) => {
        this.allApprovingOffice = data.responseData;
        this.fetchingApprovalOffices = false;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all approving level offices at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
        this.fetchingApprovalOffices = false;
      }
    );
  }

  CreateApprovingOffice() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Creating Approving User Office...",
    });

    const postData: ApprovingLevelOfficeReceivingDTO = {
      userId: this.theUser.id,
      officersIds: this.addedApprovingOfficers.map((x) => x.userId),
      endorsementTypes: [],
    };

    this.selectedEndorsementTypes.forEach((x) =>
      postData.endorsementTypes.push({
        endorsementTypeId: x.id,
        typeName: x.caption,
      })
    );

    this.approvalLevelService.CreateApprovingLevelOffice(postData).subscribe(
      async (data) => {
        if (data.responseCode != "00") {
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail: data.responseMsg,
          });
          return;
        }

        this.messageService.add({
          severity: "success",
          summary: "Completed",
          detail: "Approving User Office Created Successfully!",
        });

        this.approvingOfficeForm.reset();
        this.theUser = null;
        this.theApprovingUser = null;
        this.addedApprovingOfficers = [];
        this.FetchAllApprovingOffice();
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to create approving user office at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  CloseEditingApprovalOffice() {
    this.editingApprovingOffice = false;
    this.approvingOfficeForm.reset();
    this.theUser = null;
    this.theApprovingUser = null;
    this.addedApprovingOfficers = [];
    this.approvingOfficeToEdit = null;
  }

  EditApprovalOffice(item: ApprovingLevelOffice) {
    this.editingApprovingOffice = true;
    this.theUser = this.allUsers.find((x) => x.id == item.userId);
    this.approvingOfficeToEdit = item;
    this.addedApprovingOfficers = item.approvingOfficers;
    this.addedApprovingOfficers.forEach((data) => {
      data.user = this.allUsers.find((appUser) => appUser.id == data.userId);
    });

    this.formWrapper.nativeElement.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "start",
    });
  }

  DeleteApprovalOffice(item: ApprovingLevelOffice) {
    this.confirmationService.confirm({
      message: "Are you sure you want to remove approving user office?",
      accept: () => {
        this.messageService.add({
          severity: "info",
          summary: "Notice",
          detail: "Removing approving user office...",
        });

        this.approvalLevelService
          .DeleteApprovingLevelOfficeById(item.id)
          .subscribe(
            async () => {
              await this.messageService.add({
                severity: "success",
                summary: "Removed",
                detail: "Removed successfully",
              });
              this.fetchingApprovalOffices = true;
              this.FetchAllApprovingOffice();
            },
            (error) => {
              console.log("Error: " + JSON.stringify(error));
              this.messageService.add({
                severity: "error",
                summary: "Notice",
                detail:
                  "Unable to remove approving user office at the moment.. Reason: [" +
                  error
                    ? error.error.message
                    : "request failed - permission" + "]",
              });
            }
          );
      },
    });
  }

  UpdateApprovalOffice() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Updating Approving Level Office...",
    });

    let addedApproveOfficerIds: number[] = [];
    this.addedApprovingOfficers.forEach((officer) => {
      addedApproveOfficerIds.push(officer.userId);
    });

    const id = this.approvingOfficeToEdit.id;
    const postData: ApprovingLevelOfficeReceivingDTO = {
      userId: this.theUser.id,
      officersIds: addedApproveOfficerIds,
    };

    this.approvalLevelService
      .UpdateApprovingLevelOfficeById(id, postData)
      .subscribe(
        async () => {
          await this.messageService.add({
            severity: "success",
            summary: "Completed",
            detail: "Approving User Office Updated Successfully.",
          });

          this.theUser = null;
          this.theApprovingUser = null;
          this.addedApprovingOfficers = [];
          this.FetchAllApprovingOffice();
          this.editingApprovingOffice = false;
          this.approvingOfficeForm.reset();
        },
        (error) => {
          console.log("Error: " + JSON.stringify(error));
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail:
              "Unable to update approving user office at the moment.. Reason: [" +
              (error ? error.error.message : "request failed - permission") +
              "]",
          });
        }
      );
  }

  GetApprovingLevelString(levelId: number): string {
    let level = this.allUsers.find((x) => x.id == levelId);
    if (level) return level.lastName + " " + level.firstName;
    return "N/A";
  }
}
