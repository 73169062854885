import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { CommonResponse } from "../interfaces/home";
import {
  CreateJobFunctionVM,
  GMAJobFunction,
  UpdateJobFunctionVM,
} from "../interfaces/job-function";

@Injectable({
  providedIn: "root",
})
export class JobFunctionService {
  baseUrl = environment.gmaBaseUrl + "JobFunction/";

  constructor(private http: HttpClient) {}

  GetAllJobFunctions(): Observable<CommonResponse<GMAJobFunction[]>> {
    return this.http.get<CommonResponse<GMAJobFunction[]>>(
      this.baseUrl + "GetAllJobFunctions"
    );
  }

  CreateJobFunction(
    data: CreateJobFunctionVM
  ): Observable<CommonResponse<boolean>> {
    return this.http.post<CommonResponse<boolean>>(
      this.baseUrl + "CreateJobFunction",
      data
    );
  }

  UpdateJobFunction(
    id: number,
    data: UpdateJobFunctionVM
  ): Observable<CommonResponse<boolean>> {
    return this.http.put<CommonResponse<boolean>>(
      this.baseUrl + "UpdateJobFunction/" + id,
      data
    );
  }

  DeleteJobFunction(id: number): Observable<CommonResponse<boolean>> {
    return this.http.delete<CommonResponse<boolean>>(
      this.baseUrl + "DeleteJobFunction/" + id
    );
  }
}
