import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { FormGroup, FormBuilder } from "@angular/forms";
import { ConfirmationService, Message, MessageService } from "primeng/api";
import { BreadcrumbService } from "src/app/breadcrumb.service";
import {
  CustomerDivision,
  Contract,
  InvoiceValidForReplacementDitsVM,
  InvoiceValidForReplacementVM,
  PerformInvoiceReplacementVM,
} from "src/app/interfaces/home";
import { ContractsService } from "src/app/services/contracts.service";
import { CustomerDivisionService } from "src/app/services/customer-division.service";
import { FireBaseAuthService } from "src/app/services/fire-base-auth.service";
import { InvoiceService } from "src/app/services/invoice.sevice";

@Component({
  selector: "app-invoice-replacement",
  templateUrl: "./invoice-replacement.component.html",
  styleUrls: ["./invoice-replacement.component.scss"],
  providers: [MessageService],
})
export class InvoiceReplacementComponent implements OnInit {
  @ViewChild("formWrapper") public formWrapper: ElementRef;
  formDecline: FormGroup;
  msg: Message[] = [];
  allClients: CustomerDivision[];
  theClient: CustomerDivision;
  allContracts: Contract[];
  theContract: Contract;
  allContractInvoices: InvoiceValidForReplacementVM[];
  allInvoicesToReplace: InvoiceValidForReplacementDitsVM[] = [];
  invoicesInView: InvoiceValidForReplacementDitsVM[] = [];
  showReplacementDetailsView: boolean;
  showReplacementBuildView: boolean;
  openCautionDialogue: boolean;
  cautionAction: number;
  cautionText: string;
  replacementSummary: {
    serviceName: string;
    tag: string;
    oldTotal: number;
    newTotal: number;
  }[] = [];
  totalSumOldInvValue: number = 0;
  totalSumNewInvValue: number = 0;
  hasSuccessfulValidation: boolean;

  constructor(
    private fb: FormBuilder,
    private fireStorageService: FireBaseAuthService,
    private contractService: ContractsService,
    private clientService: CustomerDivisionService,
    public invoiceService: InvoiceService,
    private breadcrumbService: BreadcrumbService,
    public confirmationService: ConfirmationService,
    public messageService: MessageService
  ) {
    this.formDecline = fb.group({
      Comment: [""],
    });
  }

  ngOnInit(): void {
    this.breadcrumbService.setItems([
      {
        label: "Finance",
      },
      {
        label: "Invoice Replacement",
        routerLink: ["/home/finance/invoice-replacement"],
      },
    ]);

    this.FetchAllClients();
    this.ResetMessageToasters();
  }

  ResetMessageToasters() {
    this.msg = [];
    this.msg.push({
      severity: "info",
      summary:
        "Only Invoices for Current month and previous month are eligible for replacement",
    });
  }

  async FetchAllClients() {
    this.clientService.allCustomerDivision().subscribe(
      async (data) => {
        this.allClients = data.responseData;
      },
      (error) => {
        this.messageService.add({
          severity: "error",
          summary: "Failed",
          detail: error ?? "Some errors occurred",
        });
        this.ResetMessageToasters();
      }
    );
  }

  GoCriteria() {
    this.showReplacementBuildView = false;
    this.showReplacementDetailsView = false;
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Loading Invoices) Valid For Replacement...",
    });
    this.ResetMessageToasters();

    this.invoiceService
      .GetInvoiceValidForReplacementByContractId(this.theContract.id)
      .subscribe(
        async (data) => {
          if (data.responseCode != "00") {
            this.messageService.add({
              severity: "error",
              summary: data.responseMsg,
            });
            this.ResetMessageToasters();
            return;
          }

          this.allContractInvoices = data.responseData;
          this.showReplacementDetailsView = true;
        },
        (error) => {
          console.log("Error: " + JSON.stringify(error));
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail:
              "Unable to get contract invoice(s) valid for replacement at the moment.. Reason: [" +
              (error ? error.error.message : "request failed - permission") +
              "]",
          });
          this.ResetMessageToasters();
        }
      );
  }

  OnClientChange() {
    this.showReplacementBuildView = false;
    this.showReplacementDetailsView = false;
    this.hasSuccessfulValidation = false;
    if (this.theClient) {
      this.contractService.GetClientContracts(this.theClient.id).subscribe(
        async (data) => {
          if (data.responseCode != "00") {
            this.messageService.add({
              severity: "error",
              summary: data.responseMsg,
            });
            this.ResetMessageToasters();
            return;
          }

          this.allContracts = data.responseData;
          this.allContracts.forEach((x) => {
            x.description =
              "Contract-" + x.id + " " + (x.description ? x.description : "");
          });
        },
        (error) => {
          console.log("Error: " + JSON.stringify(error));
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail:
              "Unable to get all client contract(s) at the moment.. Reason: [" +
              (error ? error.error.message : "request failed - permission") +
              "]",
          });
          this.ResetMessageToasters();
        }
      );
    } else {
      this.theContract = null;
      this.allContracts = [];
    }
  }

  OnContractChange() {
    this.showReplacementBuildView = false;
    this.showReplacementDetailsView = false;
    this.hasSuccessfulValidation = false;
    this.allContractInvoices = [];
  }

  FormatDateString(date: Date): string {
    let dateString = date.toString();

    try {
      // DateTimeFormatOptions
      const date: Date = new Date(dateString);
      const options: Intl.DateTimeFormatOptions = {
        year: "numeric",
        month: "long",
        day: "numeric",
      };
      const formattedDate: string = new Intl.DateTimeFormat(
        "en-US",
        options
      ).format(date);
      dateString = formattedDate;
    } catch (error) {
      console.log(error);
      console.log(date);
      console.log(
        "Error while converting date string " + date + " exception " + error
      );
    }

    return dateString;
  }

  StartReplacement(item: InvoiceValidForReplacementVM) {
    this.allInvoicesToReplace = [];
    this.invoicesInView = item.details;
    item.details.forEach((x) =>
      this.allInvoicesToReplace.push({
        invoiceId: x.invoiceId,
        serviceName: x.serviceName,
        uniqueTag: x.uniqueTag,
        adminDirectTie: x.adminDirectTie,
        unitPrice: x.unitPrice,
        vat: x.vat,
        quantity: x.quantity,
        total: x.total,
        serviceRelationshipEnum: x.serviceRelationshipEnum,
        percentageReduction: x.percentageReduction,
        isVatable: x.vat > 0,
      })
    );
    this.showReplacementBuildView = true;
  }

  ProcessInvoiceReplacement() {
    if (
      !this.theClient.sbuId ||
      this.fireStorageService.authUserProfile.sbuId != this.theClient.sbuId
    ) {
      this.messageService.add({
        severity: "error",
        summary:
          "Sorry, Only this client attached SBU members can perform any endorsement action on this contract.",
      });
      this.ResetMessageToasters();
      return;
    }

    if (!this.hasSuccessfulValidation) {
      this.messageService.add({
        severity: "error",
        summary: "Failed",
        detail:
          "You need a successful contract validation to proceed, Kindly make use of the 'Check Contract Validity' button.",
      });
      this.ResetMessageToasters();
      return;
    }

    this.replacementSummary = [];
    this.totalSumOldInvValue = 0;
    this.totalSumNewInvValue = 0;
    this.allInvoicesToReplace.forEach((x) => {
      let record = {
        serviceName: x.serviceName,
        tag: x.uniqueTag,
        oldTotal: this.invoicesInView.find((y) => y.invoiceId == x.invoiceId)
          .total,
        newTotal: x.total,
      };

      this.totalSumOldInvValue += record.oldTotal;
      this.totalSumNewInvValue += record.newTotal;
      this.replacementSummary.push(record);
    });
    this.cautionAction = 1;
    this.cautionText =
      "You are about to replace the following invoice(s). This is an irreversible action, Do you still wish to proceed?";
    this.openCautionDialogue = true;
  }

  CautionAction() {
    if (this.cautionAction == 1) {
      this.messageService.add({
        severity: "info",
        summary: "Notice",
        detail: "Processing Invoice(s) replacement...",
      });
      this.ResetMessageToasters();

      const postData: PerformInvoiceReplacementVM = {
        contractId: this.theContract.id,
        invoicesToReplace: this.allInvoicesToReplace,
      };

      this.invoiceService.PerformInvoiceReplacement(postData).subscribe(
        async (data) => {
          if (data.responseCode != "00") {
            this.messageService.add({
              severity: "error",
              summary: data.responseMsg,
            });
            this.ResetMessageToasters();
            return;
          }

          this.messageService.add({
            severity: "success",
            summary: "Completed",
            detail: "Invoice(s) Replacement Processed Successfully!",
          });
          this.showReplacementBuildView = false;
          this.showReplacementDetailsView = false;
          this.theContract = null;
          this.theClient = null;
          this.allInvoicesToReplace = [];
          this.allContracts = [];
          this.hasSuccessfulValidation = false;
          this.ResetMessageToasters();
          this.HideCautionDialog();
        },
        (error) => {
          console.log("Error: " + JSON.stringify(error));
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail:
              "Unable to process invoice(s) replacement at the moment.. Reason: [" +
              (error ? error.error.message : "request failed - permission") +
              "]",
          });
          this.ResetMessageToasters();
        }
      );
    }
  }

  HideCautionDialog() {
    this.openCautionDialogue = false;
    this.cautionText = null;
    this.cautionAction = null;
  }

  ReCalculateTotalValue(detail: InvoiceValidForReplacementDitsVM) {
    return this.ApplyPercentageReduction(detail);
    detail.total = detail.unitPrice * detail.quantity;

    let related = this.allInvoicesToReplace.find(
      (x) =>
        x.adminDirectTie == detail.adminDirectTie &&
        x.invoiceId != detail.invoiceId
    );
    if (related) {
      related.quantity = detail.quantity;
      related.total = related.unitPrice * related.quantity;
    }
  }

  ApplyPercentageReduction(detail: InvoiceValidForReplacementDitsVM) {
    detail.total = detail.unitPrice * detail.quantity;
    if (detail.percentageReduction > 0 && detail.percentageReduction <= 100) {
      detail.total =
        detail.total - (detail.percentageReduction / 100) * detail.total;
    }

    let related = this.allInvoicesToReplace.find(
      (x) =>
        x.adminDirectTie == detail.adminDirectTie &&
        x.invoiceId != detail.invoiceId
    );
    if (related) {
      related.quantity = detail.quantity;
      related.total = related.unitPrice * related.quantity;
      related.percentageReduction = detail.percentageReduction;
      if (detail.percentageReduction > 0 && detail.percentageReduction <= 100) {
        related.total =
          related.total - (detail.percentageReduction / 100) * related.total;
      }
      this.RecalculateVat(related);
    }
    this.RecalculateVat(detail);
  }

  GetVatAmount(item: InvoiceValidForReplacementDitsVM): number {
    item.vat = 0;
    let actualTot = item.unitPrice * item.quantity;
    let res = item.total - actualTot;
    if (res > 0) {
      item.vat = res;
    }

    return item.vat;
  }

  RecalculateVat(item: InvoiceValidForReplacementDitsVM) {
    if (item.isVatable) {
      item.vat = 0.075 * item.total;
      item.total += item.vat;
    }
  }

  CheckContractValidity() {
    this.confirmationService.confirm({
      message:
        "This action will check the selected contract (Contract-" +
        this.theContract.id +
        ") validity. Do you still wish to proceed?",
      accept: async () => {
        this.messageService.add({
          severity: "info",
          summary: "Notice",
          detail: "Checking contract validity......",
        });
        this.ResetMessageToasters();

        this.contractService
          .CheckContractValidity(this.theContract.id)
          .subscribe(
            async (data) => {
              if (data.responseCode != "00") {
                this.messageService.add({
                  severity: "error",
                  summary: "Notice",
                  detail: data.responseMsg,
                });
                this.hasSuccessfulValidation = false;
                this.ResetMessageToasters();
                return;
              }

              this.messageService.add({
                severity: "success",
                summary: "Success",
                detail: "Contract validated successfully. [0 Issue(s) found]",
              });
              this.ResetMessageToasters();
              this.hasSuccessfulValidation = true;
            },
            (error) => {
              console.log("Error: " + JSON.stringify(error));
              this.messageService.add({
                severity: "error",
                summary: "Notice",
                detail:
                  "Unable to validate selected contract at the moment.. Reason: [" +
                  error.error.message +
                  "]",
              });
              this.ResetMessageToasters();
              this.hasSuccessfulValidation = false;
            }
          );
      },
    });
  }

  ReValidateContract() {
    this.confirmationService.confirm({
      message:
        "This action will re-validate the selected contract (Contract-" +
        this.theContract.id +
        ") and make necessary correction(s). Do you still wish to proceed?",
      accept: async () => {
        this.messageService.add({
          severity: "info",
          summary: "Notice",
          detail: "Revalidating contract......",
        });
        this.ResetMessageToasters();

        this.contractService.ReValidateContract(this.theContract.id).subscribe(
          async (data) => {
            if (data.responseCode != "00") {
              if (
                data.responseMsg ==
                "You can't revalidate a contract without any issues."
              ) {
                this.messageService.add({
                  severity: "warn",
                  summary: "Info",
                  detail:
                    data.responseMsg +
                    " Please proceed with your endorsement action(s).",
                });
                this.ResetMessageToasters();
                return;
              }

              this.messageService.add({
                severity: "error",
                summary: "Notice",
                detail: data.responseMsg,
              });
              this.ResetMessageToasters();
              return;
            }

            this.messageService.add({
              severity: "success",
              summary: "Success",
              detail:
                "Contract re-validated successfully. [All Issue(s) resolved.]",
            });
            this.theContract = null;
            this.OnContractChange();
            this.ResetMessageToasters();
          },
          (error) => {
            console.log("Error: " + JSON.stringify(error));
            this.messageService.add({
              severity: "error",
              summary: "Notice",
              detail:
                "Unable to re-validate selected contract the moment.. Reason: [" +
                error.error.message +
                "]",
            });
            this.ResetMessageToasters();
          }
        );
      },
    });
  }

  get InvoicesToReplaceTotalValue(): number {
    let totalSum = 0;
    this.allInvoicesToReplace.forEach((x) => {
      totalSum += x.total;
    });

    return totalSum;
  }
}
