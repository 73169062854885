import {Component, Input, OnInit} from '@angular/core';
import {CommonResponse, Lead, LeadDivision, OtherLeadCaptureInfo} from '../../../../interfaces/home';
import {MessageService} from 'primeng/api';
import {LeadService} from '../../../../services/lead.service';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {OtherLeadCaptureInfoService} from '../../../../services/other-lead-capture-info.service';

@Component({
  selector: 'app-financial-information',
  templateUrl: './financial-information.component.html',
  styleUrls: ['./financial-information.component.scss']
})
export class FinancialInformationComponent implements OnInit {
  activeLead: Lead;
  noData: any= [];
  financialInformationForm: FormGroup;
  public groupType: number;
  leadDivisions: LeadDivision[] = [];
  public loadingService: boolean;
  public selectedDivision: LeadDivision = null;
  public selectedDivisionOtherInfoStatus: boolean;
  constructor(
      private messageService: MessageService,
      private leadService: LeadService,
      private formBuilder: FormBuilder,
      private otherLeadCaptureInfoService: OtherLeadCaptureInfoService,
  ) { }
  ngOnInit(): void {
    this.financialInformationForm = this.formBuilder.group({
      groupTypeId: [''],
      leadDivisionId: ['']
    })
    this.loadLeadInfo();
  }
  loadLeadInfo(){
    this.leadService.activeLead().subscribe( async (res: any) => {
      this.activeLead = res;
      if(this.activeLead){
        this.leadDivisions = this.activeLead.leadDivisions;
        if(this.leadDivisions){
          this.financialInformationForm.patchValue( {
            groupTypeId: this.activeLead.groupType.id,
          })
          const _groupType =  this.activeLead.groupType?this.activeLead.groupType.caption:'';
          if(_groupType === 'Corporate' || _groupType === 'Government' ){
            this.groupType = 1;
            this.financialInformationForm.addControl('cooperateEstimatedAnnualIncome', new FormControl('', Validators.required));
            this.financialInformationForm.addControl('cooperateEstimatedAnnualProfit', new FormControl('', Validators.required));
            this.financialInformationForm.addControl('cooperateBalanceSheetSize', new FormControl('', Validators.required));
            this.financialInformationForm.addControl('cooperateStaffStrength', new FormControl('', Validators.required));
            this.financialInformationForm.addControl('cooperateCashBookSize', new FormControl('', Validators.required));
          } else {
            this.groupType = 2;
            this.financialInformationForm.addControl('individualEstimatedAnnualIncome', new FormControl('', Validators.required));
            this.financialInformationForm.addControl('individualDisposableIncome', new FormControl('', Validators.required));
            this.financialInformationForm.addControl('individualResidenceSize', new FormControl('', Validators.required));
          }
          if(this.selectedDivision){
            this.activate(this.selectedDivision);
          } else {
            this.activate(this.activeLead.leadDivisions[0]);
          }
        } else{
          this.noData.push({severity:'warn', summary:'warn', detail:'Create Lead Division first'});
        }
      } else{
        this.noData.push({severity:'warn', summary:'warn', detail:'Create Lead Information first'});
      }
    })
  }
  activate(leadDivision: LeadDivision) {
    this.loadingService = true;
    this.selectedDivisionOtherInfoStatus = false;
    this.selectedDivision = this.leadDivisions.find(_leadDivision => _leadDivision.id === leadDivision.id);
    this.loadDivisionServices();
  }
  loadDivisionServices(){
    if(this.selectedDivision){
      // prefill all form
      this.otherLeadCaptureInfoService.fetchByDivisionId(this.selectedDivision.id).subscribe( (r:CommonResponse)=> {
       if(r.responseCode=='00')
       {
        var result = r.responseData;
        const _otherCapture: OtherLeadCaptureInfo = result;
        const _groupType =  _otherCapture.groupType?_otherCapture.groupType.caption:'';
        if(_groupType === 'Corporate' || _groupType === 'Government'){
            this.financialInformationForm.get('cooperateEstimatedAnnualIncome').setValue(_otherCapture.cooperateEstimatedAnnualIncome);
            this.financialInformationForm.get('cooperateEstimatedAnnualProfit').setValue(_otherCapture.cooperateEstimatedAnnualProfit);
            this.financialInformationForm.get('cooperateBalanceSheetSize').setValue(_otherCapture.cooperateBalanceSheetSize);
            this.financialInformationForm.get('cooperateStaffStrength').setValue(_otherCapture.cooperateStaffStrength);
            this.financialInformationForm.get('cooperateCashBookSize').setValue(_otherCapture.cooperateCashBookSize);
        } else{
          this.financialInformationForm.get('individualEstimatedAnnualIncome').setValue(_otherCapture.individualEstimatedAnnualIncome);
          this.financialInformationForm.get('individualDisposableIncome').setValue(_otherCapture.individualDisposableIncome);
          this.financialInformationForm.get('individualResidenceSize').setValue(_otherCapture.individualResidenceSize);
        }
        this.selectedDivisionOtherInfoStatus = true;
       }
      })
      this.financialInformationForm.get('leadDivisionId').setValue(this.selectedDivision.id);
    }
  }
  createOtherLeadCaptureInfo() {
    this.messageService.add({
      severity: 'info',
      detail: 'Saving Financial Information',
      summary: 'Notice'
    })
    if(this.financialInformationForm.valid){
      this.otherLeadCaptureInfoService.postOtherLeadCaptureInfo(this.financialInformationForm.value).subscribe( async (result: any) => {
        await this.leadService.refreshData();
        await this.loadLeadInfo();
        this.messageService.add( {
          severity: 'success',
          detail: 'Financial Information saved',
          summary: 'Notice'
        })
      })
    }
  }
}
