import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ConfirmationService, MessageService } from "primeng/api";
import { BreadcrumbService } from "src/app/breadcrumb.service";
import { SMORoute } from "src/app/interfaces/armada";
import {
  EventType,
  EventSource,
  ConvergenceIncident,
  UpdateCapturedIncidentVM,
  AddIncidentCommentVM,
  ConvergenceCategory,
} from "src/app/interfaces/convergence";
import { State, Lga, User } from "src/app/interfaces/home";
import { SMORouteService } from "src/app/services/armada/smoroute.service";
import { ConvergenceService } from "src/app/services/convergence.service";
import { CustomerDivisionService } from "src/app/services/customer-division.service";
import { GmaEmploymentService } from "src/app/services/gma-employment.service";
import { SupplierService } from "src/app/services/supplier.service";
import { UserService } from "src/app/services/user.service";

@Component({
  selector: "app-incidence-convergence-review",
  templateUrl: "./incidence-convergence-review.component.html",
  styleUrls: ["./incidence-convergence-review.component.scss"],
})
export class IncidenceConvergenceReviewComponent
  implements OnInit, AfterViewInit
{
  @ViewChild("formWrapper") public formWrapper: ElementRef;
  eventForm: FormGroup;
  commentForm: FormGroup;
  stats: {
    awaitingReview: string;
    inWorkbench: string;
    eventTypes: string;
    inView: string;
  };
  generalWorkbenchEvents: ConvergenceIncident[];
  selectedGenWorkbenchEvent: ConvergenceIncident[];
  genWorkbenchCols: any[];
  myWorkbenchEvents: ConvergenceIncident[];
  selectedWorkbenchEvent: ConvergenceIncident[];
  myWorkbenchCols: any[];
  loadedEvent: ConvergenceIncident;
  allEventSource: EventSource[] = [];
  theEventSource: EventSource;
  allEventTypes: EventType[] = [];
  theEventType: EventType;
  allStates: State[] = [];
  theState: State;
  allLgas: Lga[] = [];
  theLga: Lga;
  riskLevel: number = 0;
  allRoutes: SMORoute[] = [];
  theRoute: SMORoute;
  showAddCommentDialogue: boolean;
  openDocDialogue: boolean;
  openCordinatesDialogue: boolean;
  loadingCordinates: boolean;
  locationGeometry: string = "N/A";
  allConvergenceCategories: ConvergenceCategory[];
  allUsers: User[] = [];
  raisedByImgSrc: string;
  raisedByName: string;
  raisedByMobile: string;
  raisedByEmail: string;

  constructor(
    private fb: FormBuilder,
    public smoRouteService: SMORouteService,
    public supplierService: SupplierService,
    public clientService: CustomerDivisionService,
    public userService: UserService,
    public convergenceService: ConvergenceService,
    public employmentService: GmaEmploymentService,
    private breadcrumbService: BreadcrumbService,
    public confirmationService: ConfirmationService,
    public messageService: MessageService
  ) {
    this.eventForm = fb.group({
      Source: ["", Validators.required],
      Type: ["", Validators.required],
      State: ["", Validators.required],
      Lga: ["", Validators.required],
      Street: ["", Validators.required],
      DateOccurred: ["", Validators.required],
      Caption: ["", Validators.required],
      Description: ["", Validators.required],
      Advisory: ["", Validators.required],
      Route: [""],
      RiskLevel: ["", Validators.required],
    });

    this.commentForm = fb.group({
      Comment: ["", Validators.required],
    });
  }

  ngOnInit(): void {
    this.breadcrumbService.setItems([
      {
        label: "Convergence",
      },
      {
        label: "Incident Management",
      },
      {
        label: "Review Incident",
        routerLink: ["/home/convergence/incident-management/review-incident"],
      },
    ]);

    this.genWorkbenchCols = [
      { field: "id", header: "Event ID" },
      { field: "caption", header: "Caption" },
      { field: "riskLevel", header: "Risk Level" },
    ];

    this.myWorkbenchCols = [
      { field: "id", header: "Event ID" },
      { field: "caption", header: "Caption" },
      { field: "riskLevel", header: "Risk Level" },
    ];

    this.stats = {
      awaitingReview: "~",
      inView: "~",
      inWorkbench: "~",
      eventTypes: "~",
    };

    this.FetchAllUsers();
    this.FetchAllCategories();
  }

  ngAfterViewInit(): void {
    // Load google maps script after view init
    const DSLScript = document.createElement("script");
    DSLScript.src =
      "https://maps.googleapis.com/maps/api/js?key=AIzaSyCQuetprs2UHb_zKSXznyumyfvw95ERCDY"; // replace by your API key
    DSLScript.type = "text/javascript";
    document.body.appendChild(DSLScript);
    document.body.removeChild(DSLScript);
  }

  async FetchAllUsers() {
    this.userService.allUser().subscribe(
      async (r) => {
        var data = r.responseData ?? [];
        this.allUsers = data;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all users at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  async FetchAllCategories() {
    this.convergenceService.GetAllConvergenceCategories().subscribe(
      async (data) => {
        this.allConvergenceCategories = data;
        this.FetchAllEventSource();
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all categories at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  async FetchAllEventSource() {
    this.convergenceService.GetAllEventSources().subscribe(
      async (data) => {
        this.allEventSource = data;
        this.FetchAllEventType();
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all event sources at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  async FetchAllEventType() {
    this.convergenceService.GetAllEventTypes().subscribe(
      async (data) => {
        this.allEventTypes = data;
        this.stats.eventTypes = data.length.toLocaleString();
        this.FetchAllStates();
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all event types at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  async FetchAllStates() {
    this.employmentService.GetAllStates().subscribe(
      async (data) => {
        this.allStates = data;
        this.FetchAllRoutes();
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all states at the moment.. Reason: [" +
            error.error.message +
            "]",
        });
      }
    );
  }

  async FetchAllRoutes() {
    this.smoRouteService.allRoutes().subscribe(
      async (data) => {
        this.allRoutes = data.responseData;
        this.FetchPendingIncidents();
      },
      (error) => {
        this.messageService.add({
          severity: "error",
          summary: "Failed",
          detail: error ?? "Some errors occurred",
        });
      }
    );
  }

  async FetchPendingIncidents() {
    this.convergenceService.GetPendingIncidentsList().subscribe(
      async (data) => {
        this.stats.awaitingReview =
          data.unpickedIncidents.length.toLocaleString();
        this.stats.inWorkbench = data.pickedIncidents.length.toLocaleString();
        this.stats.inView = (
          data.pickedIncidents.length + data.unpickedIncidents.length
        ).toLocaleString();
        this.generalWorkbenchEvents = data.unpickedIncidents;
        this.myWorkbenchEvents = data.pickedIncidents;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all pending incidents at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  PickEvent(item: ConvergenceIncident) {
    this.confirmationService.confirm({
      message:
        "By picking this incident, you will be taking full responsibility for the review of this incident. Do you still wish to proceed?",
      accept: () => {
        this.messageService.add({
          severity: "info",
          summary: "Notice",
          detail: "Picking incident...",
        });

        this.convergenceService.PickIncidentToReview(item.id).subscribe(
          async () => {
            this.messageService.add({
              severity: "success",
              summary: "Removed",
              detail: "Picked successfully",
            });

            const unpickedIndex = this.generalWorkbenchEvents.indexOf(item);
            if (unpickedIndex > -1) {
              let picked = this.generalWorkbenchEvents[unpickedIndex];
              this.myWorkbenchEvents.push(picked);
              this.generalWorkbenchEvents.splice(unpickedIndex, 1);
            }
          },
          (error) => {
            console.log("Error: " + JSON.stringify(error));
            this.messageService.add({
              severity: "error",
              summary: "Notice",
              detail:
                "Unable to pick incident at the moment.. Reason: [" + error
                  ? error.error.message
                  : "request failed - permission" + "]",
            });
          }
        );
      },
    });
  }

  OnSourceChange() {}

  OnTypeChange() {}

  OnStateChange() {
    this.theLga = null;
    this.allLgas = this.theState.lgas;
  }

  LoadEvent(item: ConvergenceIncident) {
    this.loadedEvent = item;
    this.theEventSource = this.allEventSource.find(
      (x) => x.id == item.sourceId
    );
    this.theEventType = this.allEventTypes.find((x) => x.id == item.typeId);
    this.theState = this.allStates.find((x) => x.id == item.stateId);
    this.OnStateChange();
    this.theLga = this.allLgas.find((X) => X.id == item.lgaId);
    this.theRoute = this.allRoutes.find((x) => x.id == item.routeId);
    this.riskLevel = item.riskLevel;

    const raisedBy = this.allUsers.find((x) => x.id == item.createdById);
    if (raisedBy) {
      this.raisedByImgSrc = raisedBy.imageUrl;
      this.raisedByName =
        raisedBy.lastName +
        " " +
        raisedBy.firstName +
        " " +
        (raisedBy.otherName ?? "");
      this.raisedByEmail = raisedBy.email;
      this.raisedByMobile = raisedBy.mobileNumber;
    }

    this.eventForm.patchValue({
      Caption: item.caption,
      Description: item.description,
      Advisory: item.advisory,
      Street: item.street,
      DateOccurred: item.dateOccurred.toLocaleString().slice(0, 10),
    });
  }

  UpdateEvent() {
    this.confirmationService.confirm({
      message:
        "You are about to update this incident info and it's irreversible. Do you still wish to proceed?",
      accept: () => {
        this.messageService.add({
          severity: "info",
          summary: "Notice",
          detail: "Updating...",
        });

        const id = this.loadedEvent.id;
        const postData: UpdateCapturedIncidentVM = {
          sourceId: this.theEventSource.id,
          sourceName: this.theEventSource.caption,
          dateOccurred: this.eventForm.get("DateOccurred").value,
          typeId: this.theEventType.id,
          typeName: this.theEventType.caption,
          stateId: this.theState.id,
          lgaId: this.theLga.id,
          caption: this.eventForm.get("Caption").value,
          description: this.eventForm.get("Description").value,
          street: this.eventForm.get("Street").value,
          riskLevel: this.riskLevel,
          routeId: this.theRoute ? this.theRoute.id : null,
          advisory: this.eventForm.get("Advisory").value,
        };

        this.convergenceService.UpdateCapturedIncident(id, postData).subscribe(
          async () => {
            this.messageService.add({
              severity: "success",
              summary: "Completed",
              detail: "Updated Successfully.",
            });

            this.loadedEvent.sourceId = postData.sourceId;
            this.loadedEvent.sourceName = postData.sourceName;
            this.loadedEvent.dateOccurred = postData.dateOccurred;
            this.loadedEvent.typeId = postData.typeId;
            this.loadedEvent.typeName = postData.typeName;
            this.loadedEvent.stateId = postData.stateId;
            this.loadedEvent.lgaId = postData.lgaId;
            this.loadedEvent.caption = postData.caption;
            this.loadedEvent.description = postData.description;
            this.loadedEvent.street = postData.street;
            this.loadedEvent.riskLevel = postData.riskLevel;
            this.loadedEvent.routeId = postData.routeId;
            this.loadedEvent.advisory = postData.advisory;
          },
          (error) => {
            console.log("Error: " + JSON.stringify(error));
            this.messageService.add({
              severity: "error",
              summary: "Notice",
              detail:
                "Unable to update incident at the moment.. Reason: [" +
                (error ? error.error.message : "request failed - permission") +
                "]",
            });
          }
        );
      },
    });
  }

  AddAdditionalComment() {
    this.showAddCommentDialogue = true;
  }

  HideCommentDialog() {
    this.showAddCommentDialogue = false;
    this.commentForm.reset();
  }

  AddIncidentComment() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Adding Comment...",
    });

    const postData: AddIncidentCommentVM = {
      comment: this.commentForm.get("Comment").value,
      incidentId: this.loadedEvent.id,
    };

    this.convergenceService.AddIncidentComment(postData).subscribe(
      async () => {
        this.messageService.add({
          severity: "success",
          summary: "Completed",
          detail: "Comment Added Successfully.",
        });

        this.HideCommentDialog();
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to add comment at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  ViewUpls() {
    this.openDocDialogue = true;
  }

  HideDocDialog() {
    this.openDocDialogue = false;
  }

  async ViewCordinates() {
    this.openCordinatesDialogue = true;

    this.loadingCordinates = true;
    await new Promise((resolve) => setTimeout(resolve, 5000)); // 5 secs
    this.initMap();
  }

  HideCordinatesDialog() {
    this.openCordinatesDialogue = false;
    this.loadingCordinates = false;
  }

  // Initialize and add the map
  initMap(): void {
    // The location of Loc
    const location = {
      lat: this.loadedEvent.locationLatitude,
      lng: this.loadedEvent.locationLongitude,
    };
    // The map, centered at Loc
    const map = new google.maps.Map(
      document.getElementById("map") as HTMLElement,
      {
        zoom: 4,
        center: location,
      }
    );

    // The marker, positioned at Loc
    const marker = new google.maps.Marker({
      position: location,
      map: map,
    });
    this.locationGeometry = this.loadedEvent.locationGeometry;
    this.loadingCordinates = false;
  }

  ActOnRequest(action: boolean) {
    this.convergenceService
      .ReviewIncident(this.loadedEvent.id, action)
      .subscribe(
        async () => {
          this.messageService.add({
            severity: "success",
            summary: "Completed",
            detail: "Reviewed Successfully.",
          });

          const index = this.myWorkbenchEvents.indexOf(this.loadedEvent);
          if (index > -1) {
            this.myWorkbenchEvents.splice(index, 1);
            this.eventForm.reset();
            this.loadedEvent = null;
          }
        },
        (error) => {
          console.log("Error: " + JSON.stringify(error));
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail:
              "Unable to review incident at the moment.. Reason: [" +
              (error ? error.error.message : "request failed - permission") +
              "]",
          });
        }
      );
  }

  DenyEventRequest() {
    this.confirmationService.confirm({
      message:
        "You are about to deny this incident and it's irreversible. Do you still wish to proceed?",
      accept: () => {
        this.ActOnRequest(false);
      },
    });
  }

  ApproveEvent() {
    this.confirmationService.confirm({
      message:
        "You are about to approve this incident and it's irreversible. Do you still wish to proceed?",
      accept: () => {
        this.ActOnRequest(true);
      },
    });
  }

  GetIncidenceCategoryLabel(typeId: number): string {
    let incidenceType = this.allEventTypes.find((x) => x.id == typeId);
    if (incidenceType) {
      let incidenceCategory = this.allConvergenceCategories.find(
        (x) => x.id == incidenceType.categoryId
      );
      if (incidenceCategory) return incidenceCategory.caption;
    }

    return "N/A";
  }
}
