import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { CommonResponse } from "../interfaces/home";
import { Make, MakeDTO } from "../interfaces/make";

@Injectable({
  providedIn: "root",
})
export class MakeService {
  baseUrl = environment.baseUrl + "Make/";
  //baseUrl = "https://localhost:44344/api/v1/Make/";

  constructor(private http: HttpClient) {}

  getAll(): Observable<CommonResponse> {
    return this.http.get<CommonResponse>(this.baseUrl);
  }
  getAllPlatforms(): Observable<CommonResponse> {
    return this.http.get<CommonResponse>(`${this.baseUrl}GetAllPlatforms`);
  }

  getByID(id: number): Observable<CommonResponse> {
    return this.http.get<CommonResponse>(this.baseUrl + id);
  }

  create(data: MakeDTO): Observable<CommonResponse> {
    return this.http.post<CommonResponse>(this.baseUrl, data);
  }

  update(data: MakeDTO, id: number): Observable<CommonResponse> {
    return this.http.put<CommonResponse>(this.baseUrl + id, data);
  }

  delete(id: number) {
    return this.http.delete(this.baseUrl + id);
  }
}
