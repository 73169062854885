import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { MessageService, ConfirmationService, Message } from "primeng/api";
import { BreadcrumbService } from "src/app/breadcrumb.service";
import { ItemGroupConsumption, User } from "src/app/interfaces/home";
import { Item, ItemGroup } from "src/app/interfaces/inventory";
import { AccountMasterService } from "src/app/services/account-master";
import { FileStorageService } from "src/app/services/file-storage.service";
import { InventoryService } from "src/app/services/inventory.service";
import { UserService } from "src/app/services/user.service";

@Component({
  selector: "app-inv-aggregated-view",
  templateUrl: "./inv-aggregated-view.component.html",
  styleUrls: ["./inv-aggregated-view.component.scss"],
  providers: [MessageService],
})
export class InvAggregatedViewComponent implements OnInit {
  @ViewChild("formWrapper") public formWrapper: ElementRef;
  msg: Message[] = [];
  fetching: boolean;
  allAggregatedInvs: ItemGroupConsumption[] = [];
  cols: any[];
  allItemGroups: ItemGroup[] = [];
  openDetail: boolean;
  selectedAggregationGroup: ItemGroupConsumption;
  allItems: Item[] = [];
  allUsers: User[] = [];

  constructor(
    private userService: UserService,
    public inventoryService: InventoryService,
    public accountMasterService: AccountMasterService,
    public messageService: MessageService,
    private breadcrumbService: BreadcrumbService,
    public confirmationService: ConfirmationService,
    private fileStorageService: FileStorageService
  ) {}

  ngOnInit(): void {
    this.breadcrumbService.setItems([
      {
        label: "Inventory",
      },
      {
        label: "Aggregation View",
        routerLink: ["/home/inventory-management/aggregated-view"],
      },
    ]);

    this.cols = [
      { field: "itemGroup", header: "ItemGroup" },
      { field: "year", header: "Year" },
      { field: "month", header: "Month" },
      { field: "totalAmount", header: "Total Amount" },
      { field: "isPosted", header: "Is Posted" },
      { field: "createdAt", header: "Created At" },
    ];

    this.FetchAllUsers();
    this.FetchAllItems();
    this.FetchAllItemGroup();
    this.FetchAllUnpostedAggregations();
    this.ResetMessageToasters();
  }

  ResetMessageToasters() {
    this.msg = [];
    this.msg.push({
      severity: "info",
      summary:
        "You have a total of " +
        this.allAggregatedInvs.length +
        " inventory aggregations to post",
    });
  }

  async FetchAllItemGroup() {
    this.inventoryService.GetAllItemGroup().subscribe(
      (data) => {
        this.allItemGroups = data;
      },
      (error) => {
        this.messageService.add({
          severity: "error",
          summary: "Failed",
          detail:
            "Connection Error, Unable to fetch all item groups " +
            (error ? error.error.message : "request failed - permission"),
        });
        this.ResetMessageToasters();
      }
    );
  }

  async FetchAllUnpostedAggregations() {
    this.fetching = true;
    this.accountMasterService.GetUnpostedItemConsumptionGroup().subscribe(
      async (data) => {
        if (data.responseCode != "00") {
          this.messageService.add({
            severity: "error",
            summary: data.responseMsg,
          });
          this.fetching = false;
          this.ResetMessageToasters();
          return;
        }

        this.allAggregatedInvs = data.responseData;
        this.ResetMessageToasters();
        this.fetching = false;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary:
            "Unable to fetch unposted aggregations at the moment.. Reason: [" +
            error.error.message +
            "]",
        });
        this.ResetMessageToasters();
        this.fetching = false;
      }
    );
  }

  async FetchAllItems() {
    this.inventoryService.GetAllItem().subscribe(
      (data) => {
        this.allItems = data;
      },
      (error) => {
        this.messageService.add({
          severity: "error",
          summary: "Failed",
          detail:
            "Connection Error, Unable to fetch all items " +
            (error ? error.error.message : "request failed - permission"),
        });
        this.ResetMessageToasters();
      }
    );
  }

  async FetchAllUsers() {
    this.userService.allUser().subscribe(
      async (r) => {
        var data = r.responseData ?? [];
        this.allUsers = data;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all users at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
        this.ResetMessageToasters();
      }
    );
  }

  GetItemGroupName(itemGroupId: number): string {
    let itemGrp = this.allItemGroups.find((x) => x.id == itemGroupId);
    if (itemGrp) return itemGrp.caption;

    return "N/A";
  }

  GetItemName(itemId: number): string {
    let item = this.allItems.find((x) => x.id == itemId);
    if (item) return item.caption;

    return "N/A";
  }

  GetUserName(userId: number): string {
    let user = this.allUsers.find((x) => x.id == userId);
    if (user) return user.lastName + " " + user.firstName;

    return "N/A";
  }

  ViewDetails(item: ItemGroupConsumption) {
    this.openDetail = true;
    this.selectedAggregationGroup = item;
  }

  FormatDateString(date: Date): string {
    let dateString = date.toString();

    try {
      // DateTimeFormatOptions
      const date: Date = new Date(dateString);
      const options: Intl.DateTimeFormatOptions = {
        year: "numeric",
        month: "long",
        day: "numeric",
      };
      const formattedDate: string = new Intl.DateTimeFormat(
        "en-US",
        options
      ).format(date);
      dateString = formattedDate;
    } catch (error) {
      console.log(error);
      console.log(date);
      console.log(
        "Error while converting date string " + date + " exception " + error
      );
    }

    return dateString;
  }

  PostAggregation(item: ItemGroupConsumption) {
    this.confirmationService.confirm({
      header: "Confirmation Dialog",
      icon: "pi pi-exclamation-triangle",
      message:
        "You are about to post this item group consumption againt the financial system, It is an irreversible action. Do you still wish to proceed?",
      acceptLabel: "Yes, Please Proceed",
      rejectLabel: "No, Don't Proceed",
      accept: async () => {
        this.messageService.add({
          severity: "info",
          summary: "Notice",
          detail: "Posting Voucher......",
        });

        this.accountMasterService.PostItemGroupConsumption(item.id).subscribe(
          async (data) => {
            if (data.responseCode != "00") {
              this.messageService.add({
                severity: "error",
                summary: data.responseMsg,
              });
              this.ResetMessageToasters();
              return;
            }

            this.messageService.add({
              severity: "success",
              summary: "Completed",
              detail: "Voucher Posted Successfully!",
            });
            this.FetchAllUnpostedAggregations();
            this.ResetMessageToasters();
          },
          (error) => {
            console.log("Error: " + JSON.stringify(error));
            this.messageService.add({
              severity: "error",
              summary: "Notice",
              detail:
                "Unable to post voucher at the moment.. Reason: [" +
                (error ? error.error.message : "request failed - permission") +
                "]",
            });
            this.ResetMessageToasters();
          }
        );
      },
    });
  }

  HideDetail() {
    this.openDetail = false;
    this.selectedAggregationGroup = null;
  }
}
