import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable()
export class SendMailService {

  constructor(private http:HttpClient) { }

  SendEmail(body:any){
    return this.http.post(
      environment.baseUrl + "ProjectManagement/SendMail",body
      )
  }

  getAllMail(){
    return this.http.get(
      environment.baseUrl +
        "ProjectManagement/GetAllMail"
    );
  }

  removeMail(emailId: any) {
    return this.http.delete(environment.baseUrl + "ProjectManagement/DisableMail/" + emailId);
  }

  
}
