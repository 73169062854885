import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { ConfirmationService, MessageService } from "primeng/api";
import {
  AccountDetail,
  AccountMaster,
  AccountVoucherType,
  CommonResponse,
  Customer,
  CustomerDivision,
} from "../../../interfaces/home";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { EventInput } from "@fullcalendar/core";
import { environment } from "../../../../environments/environment";
import { AccountVoucherTypeService } from "../../../services/account-voucher-type.service";
import { CustomerService } from "../../../services/customer.service";
import { AccountMasterService } from "../../../services/account-master";
import { CustomerDivisionService } from "../../../services/customer-division.service";
import { BreadcrumbService } from "src/app/breadcrumb.service";

@Component({
  selector: "app-account-transaction-md",
  templateUrl: "./account-transaction-md.component.html",
  styleUrls: ["./account-transaction-md.component.css"],
  providers: [MessageService, ConfirmationService],
})
export class AccountTransactionMdComponent implements OnInit {
  @ViewChild("formWrapper") public formWrapper: ElementRef;

  customerDivisions: CustomerDivision[];
  voucherTypes: AccountVoucherType[];

  byClientForm: FormGroup;
  byTransactionIdForm: FormGroup;
  byVoucherTypeForm: FormGroup;

  selectedCustomerId: Customer;
  selectedVoucherTypeId: AccountVoucherType;

  accountMasters: AccountMaster[];
  transactionDetails: AccountDetail[];
  fetchingAccountDetails: false;
  selectedAccountDetail: AccountDetail;
  cols: any;
  exportColumns: any;
  fetchingAccountMasters = false;
  detailsCols: any;
  exportDetailsColumns: any;
  selectedAccountMaster: AccountMaster;
  selectedVoucherTypes: AccountVoucherType[];
  accountMaster: AccountMaster;

  constructor(
    public formBuilder: FormBuilder,
    public messageService: MessageService,
    public customerDivisionService: CustomerDivisionService,
    public accountVoucherTypeService: AccountVoucherTypeService,
    public accountMasterService: AccountMasterService,
    public confirmationService: ConfirmationService,
    private breadcrumbService: BreadcrumbService
  ) {}

  ngOnInit(): void {
    this.breadcrumbService.setItems([
      { label: "Finance", routerLink: ["home/account-transaction-md"] },
      {
        label: "Account-Transactions",
        routerLink: ["home/account-transaction-md"],
      },
    ]);

    this.getCustomerDivisions();
    this.getVoucherTypes();

    this.cols = [
      { field: "id", header: "Id" },
      { field: "voucherType", header: "Voucher Type Id" },
      { field: "value", header: "Value" },
      { field: "transactionId", header: "Transaction Id" },
      { field: "description", header: "Description" },
      { field: "integrationFlag", header: "Integration Flag" },
    ];

    this.detailsCols = [
      { field: "id", header: "Transaction ID" },
      { field: "date", header: "Transaction Date" },
      { field: "description", header: "Description" },
      { field: "dr", header: "Dr" },
      { field: "cr", header: "Cr" },
    ];

    this.exportColumns = this.cols.map((col) => ({
      title: col.header,
      dataKey: col.field,
    }));
    this.exportDetailsColumns = this.detailsCols.map((col) => ({
      title: col.header,
      dataKey: col.field,
    }));

    this.byClientForm = this.formBuilder.group({
      customerId: [null, Validators.required],
      contractYear: [null, Validators.required],
    });
    this.byTransactionIdForm = this.formBuilder.group({
      transactionId: ["", Validators.required],
    });
    this.byVoucherTypeForm = this.formBuilder.group({
      voucherTypeId: [null, Validators.required],
      startDate: ["", Validators.required],
      endDate: ["", Validators.required],
    });
  }

  getCustomerDivisions() {
    this.customerDivisionService.allCustomerDivision().subscribe(
      async (res: CommonResponse) => {
        this.customerDivisions = res.responseData;
      },
      (error) => {
        this.messageService.add({
          severity: "error",
          summary: "Failed",
          detail: "Connection error, Try again later",
        });
      }
    );
  }

  getVoucherTypes() {
    this.accountVoucherTypeService.allAccountVoucherTypeData().subscribe(
      async (res: any) => {
        this.voucherTypes = res.responseData;
      },
      (error) => {
        this.messageService.add({
          severity: "error",
          summary: "Failed",
          detail: "Connection error, Try again later",
        });
      }
    );
  }

  searchByClient() {
    this.searchStart();

    const postData = {
      clientId: this.selectedCustomerId.id,
      years: [this.byClientForm.get("contractYear").value],
    };

    this.accountMasterService.getAccountMasterByCustomerId(postData).subscribe(
      async (r: CommonResponse) => {
        var data = r.responseData;
        await this.searchComplete(data);
        await this.byClientForm.reset();
      },
      (error) => {
        this.fetchingAccountMasters = false;
        this.connectionError();
      }
    );
  }

  searchByTransactionId() {
    this.searchStart();

    const transactionId = this.byTransactionIdForm.get("transactionId").value;

    this.accountMasterService
      .getAccountMasterByTransactionId(encodeURIComponent(transactionId))
      .subscribe(
        async (data: CommonResponse) => {
          await this.searchComplete(data.responseData);
          this.byTransactionIdForm.reset();
        },
        (error) => {
          this.fetchingAccountMasters = false;
          this.connectionError();
        }
      );
  }

  searchByVoucherType() {
    let voucherTypes = "";
    this.selectedVoucherTypes.forEach((value) => {
      voucherTypes = voucherTypes + `&VoucherTypeIds=${value.id}`;
    });

    this.searchStart();

    const voucherType = voucherTypes;
    const startDate = this.byVoucherTypeForm.get("startDate").value;
    const endDate = this.byVoucherTypeForm.get("endDate").value;

    this.accountMasterService
      .getAccountMasterByVoucherType(startDate, endDate, voucherType)
      .subscribe(
        async (data: CommonResponse) => {
          var p = data?.responseData;
          await this.searchComplete(p);
          this.byVoucherTypeForm.reset();
        },
        (error) => {
          this.fetchingAccountMasters = false;
          this.connectionError();
        }
      );
  }

  searchStart() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Searching ..",
    });
    this.accountMasters = null;
    this.transactionDetails = null;
    this.fetchingAccountMasters = true;
  }

  async searchComplete(data: any) {
    await this.messageService.add({
      severity: "success",
      summary: "Completed",
      detail: "Search Successful.",
    });
    this.accountMasters = data;
    this.fetchingAccountMasters = false;
  }

  viewAccountMasterDetails(accountMaster: AccountMaster) {
    if (accountMaster) {
      this.accountMaster = accountMaster;
      this.transactionDetails = accountMaster.accountDetails;
      this.formWrapper.nativeElement.scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "start",
      });
    }
  }

  private connectionError() {
    this.messageService.add({
      severity: "error",
      summary: "Failed",
      detail: "Connection Error, Please try again",
    });
  }
}
