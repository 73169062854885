import { Component, OnDestroy, OnInit } from '@angular/core';
import { ReactiveFormsModule, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { Subscription } from 'rxjs';
import { BudgetapiservicesService } from 'src/app/services/budget/budgetapiservices.service';

export interface BudgetSeason {
  id: number;
  caption: string;
  budgetYear: number;
  createdAt: Date;
  description: string;
  isPublished: boolean;
  prepEndDate: Date;
  prepStartDate: Date;
}

@Component({
  selector: "app-budget-season",
  templateUrl: "./budget-season.component.html",
  styleUrls: ["./budget-season.component.css"],
})
export class BudgetSeasonComponent implements OnInit, OnDestroy {
  
  listOfBudgetSeason: any[] = [];
  budgetSeasonData: any;
  budgetSeasonSubscription: Subscription | undefined;
  constructor(private router: Router,
              private route: ActivatedRoute,
              private service: BudgetapiservicesService) { }

  items: MenuItem[] | undefined;

  home: MenuItem | undefined;
  isEditingRecord: boolean = false;
  // createBudgetSeason(){
  //   console.log(this.budgetSeasonForm.value);
  // }
  ngOnInit(): void {
    this.items = [{ label: 'Computer' }, { label: 'Notebook' }, { label: 'Accessories' }, { label: 'Backpacks' }, { label: 'Item' }];
    console.log('benigin');
    this.budgetSeasonSubscription = this.loadBudgetSeasonList();

    this.home = { icon: 'pi pi-home', routerLink: '/' };
  }
  
  ngOnDestroy(): void {
    this.budgetSeasonSubscription.unsubscribe();
  }

  loadBudgetSeasonList() {
    return this.service.getBudgetSeasons().subscribe(data => {
      
      this.listOfBudgetSeason = data['responseData'];
      console.log(data);
      // this.loadingData = false;
      console.log(this.listOfBudgetSeason.length);
      console.log(typeof this.listOfBudgetSeason);

      // this.caption = this.seasonData.Caption;
      // this.prepEndDate = this.seasonData.PrepEndDate;
      // this.cyclesCreated = this.seasonData.CreatedCycle;
      // this.isPublished = this.seasonData.isPublished;
    }, err => {
      console.log("Error loading season data");
    });
  }

  // get listOfBudgetSeasons(){
  //   return this.listOfBudgetSeason;
  // }
  reloadPage(){
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = "reload";
    this.router.navigate(["./"], {
      relativeTo: this.route,
    });
  }

  enableEdit(enableEdit: boolean) {
    this.isEditingRecord = enableEdit;
  }
  passBudgetSeasonData(args: any) {
    this.budgetSeasonData = args
  }

}
