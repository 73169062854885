import { OrganizationService } from "src/app/services/organization.service";
import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import {
  ConfirmationService,
  Message,
  MessageService,
  TreeNode,
} from "primeng/api";
import {
  Approval,
  SendFinalApprovalVM,
  ApprovingLevelBackUp,
  CommonResponse,
  Contract,
  ContractService,
  Endorsement,
  Lead,
  LeadQuote,
  Services,
  User,
  GetApprovalSummaryInfoReceivingDTO,
  GetApprovalSummaryInfoTransferDTO,
} from "../../interfaces/home";
import { ServicesService } from "../../services/services.service";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { EventInput } from "@fullcalendar/core";
import { environment } from "../../../environments/environment";
import { ApprovalService } from "../../services/approval.service";
import { QuoteService } from "../../services/quote.service";
import { LeadService } from "../../services/lead.service";
import { FireBaseAuthService } from "../../services/fire-base-auth.service";
import { DialogService, DynamicDialogRef } from "primeng/dynamicdialog";
import { ViewQuoteserviceDetailsComponent } from "./view-quoteservice-details/view-quoteservice-details.component";
import { BreadcrumbService } from "src/app/breadcrumb.service";
import { EndorsementService } from "../../services/endorsement.service";
import { ContractServiceService } from "src/app/services/contract-service.service";
import { forkJoin, Observable } from "rxjs";
import { ContractsService } from "src/app/services/contracts.service";
import { DomSanitizer } from "@angular/platform-browser";
import { EngagementContactsService } from "../engagement-contacts/engagement-contacts.service";
import { ProjectAllocationService } from "../project-allocation/project-allocation.service";
import { NonPrincipalApprovingOffice } from "src/app/interfaces/organization";
import { ApiLoadingService } from "src/app/services/api-loading.service";
import { UserService } from "src/app/services/user.service";
import { ApprovalComment } from "src/app/interfaces/approval-comment";
import { ApprovalCommentService } from "src/app/services/approval-comment.service";
import { EndorsementTypeService } from "src/app/services/endorsement-type.service";

@Component({
  selector: "app-approval",
  templateUrl: "./approvals.component.html",
  styleUrls: ["./approvals.component.css"],
  providers: [MessageService, ConfirmationService],
})
export class ApprovalsComponent implements OnInit {
  @ViewChild("approvalLevelsId") public approvalLevelsId: ElementRef;
  @ViewChild("detailsId") public detailsId: ElementRef;

  fetchingApprovals = true;
  approvals: Approval[] = [];
  selectedApproval: Approval;

  services: Services[];

  viewService: Services;
  requesterName: string;
  timeSpent: string;

  public approvalCols: any[];
  public exportRoleColumns: any[];

  serviceForm: FormGroup;
  deliverableForm: FormGroup;

  viewingService: false;
  viewingDeliverable: false;

  defaultImage: string;
  fetchingApprovalLevels = false;
  approvalLevels: any[];
  selectedApprovalLevel: any;
  approvalLevelsCols: any[];
  divisions: any[];
  selectedDivision: any;
  quotes: any[];
  fetchingQuoteServices = false;
  selectedQuoteService: any;
  contractServiceCols: any;
  contractServiceExportColumns: any;
  viewLead: any;
  isQuote: boolean = true;
  quoteLabel: string = "Quote";

  servicesCache: any = {};
  loggedInUser: User;
  approvalSelected: Approval;
  leadInfoCache: any = {};
  contractInfoCache: any = {};

  approvalLevelSelected: any;
  showManualConvertButton = false;
  quoteServiceDocuments: any[];
  quoteServiceDetailsRef: DynamicDialogRef;
  unApprovedEndorsements: Endorsement[];
  viewEndorsement: Endorsement;
  viewEndorsementDetails: Endorsement;
  loadingEndorsementDetails: boolean = false;
  isValueChangeEndorsement: boolean;
  prevContractService: ContractService;
  suspectContact: any[];

  allNonApprovalOffices: NonPrincipalApprovingOffice[] = [];
  allUsers: User[];

  pendingApprovals$: Observable<
    [CommonResponse, CommonResponse, CommonResponse, Contract[]]
  >;
  documents: string[];
  valueToPush: any;
  showApprovalPane: boolean = false;
  approvingLevelOfficeInfo: ApprovingLevelBackUp;
  DisapprovalComment: string = "";
  showComment: boolean = false;
  openCommentSection: boolean;
  commentApproval: Approval;
  comments: ApprovalComment[] = [];
  newApprovalComment: string;
  loadingApprovalComments: boolean;

  finalApprovalNotificationBody: SendFinalApprovalVM;
  totalApprovalRequests: number = 0;
  totalPendingApprovalForMe: number = 0;
  totalApprovalTypesWaitingForMe: number = 0;
  totalApprovalOnQueue: number = 0;
  approvalsClientBatch: {
    clientName: string;
    isParticipant: boolean;
    clientBatches: {
      approvalType: string;
      approvalCount: number;
      approvals: Approval[];
    }[];
  }[] = [];
  appClientBatchCols: any[];
  appTypeBatchCols: any[];
  participatingApprovalsIds: number[] = [];
  isDoneLoadingSummary: boolean;
  endorsementTypes: any;

  constructor(
    private apiLoadingService: ApiLoadingService,
    private messageService: MessageService,
    private organizationService: OrganizationService,
    private approvalCommentService: ApprovalCommentService,
    private formBuilder: FormBuilder,
    private confirmationService: ConfirmationService,
    private serviceService: ServicesService,
    private quoteService: QuoteService,
    private leadService: LeadService,
    private contractServiceService: ContractServiceService,
    private approvalService: ApprovalService,
    private endorsementService: EndorsementService,
    private userService: UserService,
    private dialogService: DialogService,
    private fireBaseAuthService: FireBaseAuthService,
    private breadcrumbService: BreadcrumbService,
    private contractsService: ContractsService,
    public engagementService: EngagementContactsService,
    private sanitizer: DomSanitizer,
    public projectAllocationService: ProjectAllocationService,
    public endorsementType: EndorsementTypeService,
  ) {}

  ngOnInit(): void {
    this.breadcrumbService.setItems([
      { label: "Approvals", routerLink: ["home/approval"] },
    ]);

    this.loggedInUser = this.fireBaseAuthService.authUserProfile;
    this.GetUserApprovingOfficeDits();
    this.FetchAllUsers();
    //this.FetchAllNonPrincipalApprovingOffice();

    this.approvalCols = [
      { field: "type", header: "Type" },
      { field: "name", header: "Name" },
      { field: "description", header: "Description" },
      { field: "createdAt", header: "Created At" },
    ];

    this.contractServiceCols = [
      { field: "service", header: "Service" },
      { field: "serviceId", header: "Service Id" },
      { field: "status", header: "Status" },
    ];

    this.appClientBatchCols = [
      { field: "clientName", header: "Client Name" },
      { field: "isParticipant", header: "Is Participating" },
    ];
    this.appClientBatchCols = [
      { field: "approvalType", header: "Approval Type" },
    ];

    this.contractServiceExportColumns = this.contractServiceCols.map((col) => ({
      title: col.header,
      dataKey: col.field,
    }));

    this.serviceForm = this.formBuilder.group({});

    this.deliverableForm = this.formBuilder.group({});

    this.defaultImage = environment.defaultImage;

    this.exportRoleColumns = this.approvalCols.map((col) => ({
      title: col.header,
      dataKey: col.field,
    }));

    
  }

  async FetchAllUsers() {
    this.userService.allUser().subscribe(
      async (r) => {
        var data = r.responseData ?? [];
        this.allUsers = data;
        this.fetchApprovals();
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all users at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  async FetchAllNonPrincipalApprovingOffice() {
    this.organizationService.GetAllNonPrincipalApprovingOffices().subscribe(
      async (data) => {
        this.allNonApprovalOffices = data;
        this.apiLoadingService.hide();
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all non principal approving offices at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
        this.apiLoadingService.hide();
      }
    );
  }

  GetUserApprovingOfficeDits() {
    this.approvalService.GetApprovingLevelOfficeData().subscribe(
      async (data) => {
        this.approvingLevelOfficeInfo = data.responseData;
        this.apiLoadingService.hide();
      },
      (error) => {
        this.connectionError();
        this.apiLoadingService.hide();
      }
    );
  }

  presentComment() {
    this.showComment = true;
    this.showApprovalPane = false;
  }

  cancelDisapproval() {
    this.showComment = false;
    this.showApprovalPane = true;
  }

  async fetchApprovals() {
    this.clearInfoSpecificToApproval();
    this.fetchingApprovals = true;

    this.pendingApprovals$ = forkJoin([
      this.serviceService.getServicesPendingApproval(),
      this.leadService.getUnApprovedLeads(),
      this.endorsementService.allUnapprovedEndorsements(),
      this.contractsService.gMAGetUnapprovedContracts(),
    ]);

    this.pendingApprovals$.subscribe(
      async (res) => {
        console.log(
          "This is the response once the pending service is loaded ",
          res
        );
        const approvals = [];

        const [services, leads, endorsements, contracts] = res;

        this.unApprovedEndorsements =
          endorsements == null ? [] : endorsements.responseData ?? [];
        this.services = services == null ? [] : services.responseData ?? [];
        var leads_ = leads == null ? [] : leads.responseData ?? [];
        var _contracts = contracts == null ? [] : contracts ?? [];

        this.services.forEach((service) => {
          approvals.push({
            class: "Services",
            id: service.id,
            type: "Service Creation",
            name: service.name,
            description: service.description,
            createdAt: service.createdAt,
            createdById: service.createdById,
          });
        });

        leads_.forEach((lead) => {
          approvals.push({
            class: "Leads",
            id: lead.id,
            type: "Contract Creation",
            name: lead.groupName,
            description: "Contract Service Creation",
            createdAt: lead.createdAt,
            createdById: lead.createdById,
          });
        });

        _contracts.forEach((c) => {
          if (!this.contractInfoCache[c.id]) {
            this.contractInfoCache[c.id] = c;
          }
          approvals.push({
            class: "Contracts",
            id: c.id,
            type: "Addition Contract Creation",
            name: c.customerDivision.divisionName,
            description: "Addition Contract Creation",
            createdAt: c.createdAt,
            createdById: c.createdById,
          });
        });

        this.unApprovedEndorsements.forEach((endorsement) => {
          approvals.push({
            class: "Endorsements",
            id: endorsement.id,
            type: "Endorsements",
            name: endorsement.customerDivision?.divisionName,
            description: endorsement.endorsementType.caption,
            createdAt: endorsement.createdAt,
            documentUrl: endorsement.documentUrl,
            createdById: endorsement.createdById,
          });
        });

        //order by descending b - a
        this.approvals = approvals.sort(
          (a, b) => Date.parse(b.createdAt) - Date.parse(a.createdAt)
        );
        console.log("This is the final deficiency ", this.approvals);
        this.fetchingApprovals = false;

        const postData: GetApprovalSummaryInfoReceivingDTO = {
          leadIds: leads_.map((x) => x.id),
          serviceIds: this.services.map((x) => x.id),
          contractServiceIds: this.unApprovedEndorsements.map((x) => x.id),
          contractIds: _contracts.map((x) => x.id),
        };
        await this.LoadApprovalSummaryInfo(postData);
        this.GroupApprovalsView();
        this.apiLoadingService.hide();
      },
      (error) => {
        this.fetchingApprovals = false;
        this.connectionError();
        this.apiLoadingService.hide();
      }
    );
  }

  CreateFulfillmentProject() {
    this.approvalSelected;
    const payload = {
      requestId: this.approvalSelected.id,
      requestClass: this.approvalSelected.class,
    };
    this.projectAllocationService.createProjectFufilment(payload).subscribe(
      async (data: any) => {
        if (data.responseCode == "00") {
          this.messageService.add({
            severity: "success",
            summary: "Success",
            detail: "Fulfilment Successfully created.",
          });
        } else {
          this.messageService.add({
            severity: "error",
            summary: "Error",
            detail: data.responseMsg,
          });
        }
        this.apiLoadingService.hide();
      },
      (error) => {
        this.messageService.add({
          severity: "error",
          summary: "Error",
          detail: "A network Error occurred.",
        });
        this.apiLoadingService.hide();
      }
    );
  }

  clearInfoSpecificToApproval() {
    this.viewLead = null;
    this.viewService = null;
    this.viewEndorsement = null;
    this.viewEndorsementDetails = null;
    this.divisions = null;
    this.quotes = [];
    this.approvalLevelSelected = null;
    this.approvalLevels = null;
    this.showManualConvertButton = false;
    this.quoteServiceDocuments = null;
    this.documents = [];
  }

  CanAlsoApprove(responsibleId: number): boolean {
    let find = this.approvingLevelOfficeInfo.backUpInfos.find(
      (x) => x.userId == responsibleId
    );
    return find ? true : false;
  }

  IsNonPrincipalApprovalApplicable(responsibleId: number): boolean {
    return this.loggedInUser.id == responsibleId;
  }

  viewApprovalLevels(approval: Approval) {
    console.log("This is the approval before saturation ", approval);
    this.clearInfoSpecificToApproval();

    this.approvalSelected = approval;
    console.log("This is the approval selected ", this.approvalSelected);

    this.approvalLevels = [];

    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Retrieving Approval Levels",
    });
    this.fetchingApprovalLevels = true;

    this.approvalLevelsId.nativeElement.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "start",
    });

    const approvalType = approval.type;

    //Prep final notification body
    this.finalApprovalNotificationBody = {
      approvals: [],
      subject: approvalType + " Fully Approved",
      requestName: approval.name,
      requestType: approval.type,
      initiator: this.GetUserName(approval.createdById),
      decription: approval.description,
    };

    if (approvalType === "Service Creation") {
      this.approvalService.getApprovalsByServiceId(approval.id).subscribe(
        async (r: CommonResponse) => {
          var approvals = r.responseData;
          const sorted = approvals.sort((a, b) => a.sequence - b.sequence);

          for (const sortedApproval of sorted) {
            if (!sortedApproval.isApproved) {
              sortedApproval.canBeApproved = true;
              break;
            }
          }

          this.approvalLevels = sorted;
          let approvalIds = this.approvalLevels.map((x) => x.id);
          this.LoadApprovalCommentsInfo(approvalIds);
          this.finalApprovalNotificationBody.approvals.push(...sorted);

          this.fetchingApprovalLevels = false;
          await this.messageService.add({
            severity: "success",
            summary: "Success",
            detail: "Approval Levels Retrieved Successfully.",
          });
          this.GroupApprovalsView();
          this.apiLoadingService.hide();
        },
        (error) => {
          this.fetchingApprovalLevels = false;
          this.connectionError();
          this.GroupApprovalsView();
          this.apiLoadingService.hide();
        }
      );
    }

    //Addition Contract Creation
    else if (approvalType === "Addition Contract Creation") {
      var id = approval.id;
      let observables: Observable<Approval[]>[] = [];
      this.approvalService.getApprovalsByContractId(id).subscribe(
        async (r: CommonResponse) => {
          if (r.responseCode == "00") {
            const approvalLevels = [];
            this.approvalLevels = [];
            var approvals = r.responseData;

            const sortedApprovals = approvals.sort(
              (a, b) => a.sequence - b.sequence
            );

            for (const sortedApproval of sortedApprovals) {
              if (!sortedApproval.isApproved) {
                sortedApproval.canBeApproved = true;
                break;
              }
            }

            approvalLevels.push(...sortedApprovals);
            this.finalApprovalNotificationBody.approvals.push(
              ...sortedApprovals
            );

            this.approvalLevels = approvalLevels;
            let approvalIds = this.approvalLevels.map((x) => x.id);
            this.LoadApprovalCommentsInfo(approvalIds);
            this.fetchingApprovalLevels = false;
            await this.messageService.add({
              severity: "success",
              summary: "Success",
              detail: "Approval Levels Retrieved Successfully.",
            });
            this.GroupApprovalsView();
          } else {
            await this.messageService.add({
              severity: "error",
              summary: "Failure",
              detail: r.responseMsg,
            });
          }
          this.GroupApprovalsView();
          this.apiLoadingService.hide();
        },
        (error) => {
          this.fetchingApprovalLevels = false;
          this.connectionError();
          this.GroupApprovalsView();
          this.apiLoadingService.hide();
        }
      );

      forkJoin([observables]).subscribe(
        async (r: any) => {
          const approvalLevels = [];
          var res = r.responseData;
          res.forEach((approvals) => {
            if (approvals.length > 0) {
              const sortedApprovals = approvals.sort(
                (a, b) => a.sequence - b.sequence
              );

              for (const sortedApproval of sortedApprovals) {
                if (!sortedApproval.isApproved) {
                  sortedApproval.canBeApproved = true;
                  break;
                }
              }

              approvalLevels.push(...sortedApprovals);
              this.finalApprovalNotificationBody.approvals.push(
                ...sortedApprovals
              );
            }
          });

          console.log("Setting the approval levels ", approvalLevels);
          this.showManualConvertButton = approvalLevels.every(
            (x) => x.isApproved
          );
          this.approvalLevels = approvalLevels;
          this.fetchingApprovalLevels = false;
          await this.messageService.add({
            severity: "success",
            summary: "Success",
            detail: "Approval Levels Retrieved Successfully.",
          });
        },
        (error) => {
          this.fetchingApprovalLevels = false;
          this.connectionError();
        }
      );
    } else if (approvalType === "Contract Creation") {
      this.leadService.getLeadById(approval.id).subscribe(
        async (r: any) => {
          var lead: Lead = r.responseData;

          if (!this.leadInfoCache[approval.id]) {
            this.leadInfoCache[approval.id] = lead;
          }

          let observables: Observable<Approval[]>[] = [];

          const leadDivisions = lead.leadDivisions;
          var division = leadDivisions[0];
          for (const quoteService of division.quote.quoteServices) {
            if (quoteService.serviceId) {
              if (this.servicesCache[quoteService.serviceId])
                quoteService.service =
                  this.servicesCache[quoteService.serviceId];
              else {
                this.serviceService
                  .getServices(quoteService.serviceId)
                  .subscribe(
                    async (service: any) => {
                      if (service.responseCode == "00") {
                        this.servicesCache[service.id] = service.responseData;
                        quoteService.service = service.responseData;
                      }
                      this.GroupApprovalsView();
                      this.apiLoadingService.hide();
                    },
                    (error) => {
                      console.log(error.error.message);
                      this.GroupApprovalsView();
                      this.apiLoadingService.hide();
                    }
                  );
              }
            }
          }

          this.approvalService
            .getApprovalsByQuoteId(division.quote.id)
            .subscribe(
              async (r: CommonResponse) => {
                const approvalLevels = [];
                this.approvalLevels = [];
                var approvals = r.responseData;

                const sortedApprovals = approvals.sort(
                  (a, b) => a.sequence - b.sequence
                );

                for (const sortedApproval of sortedApprovals) {
                  if (!sortedApproval.isApproved) {
                    sortedApproval.canBeApproved = true;
                    break;
                  }
                }

                approvalLevels.push(...sortedApprovals);
                this.finalApprovalNotificationBody.approvals.push(
                  ...sortedApprovals
                );

                console.log("Setting the approval levels ", approvalLevels);
                this.showManualConvertButton = approvalLevels.every(
                  (x) => x.isApproved
                );
                this.approvalLevels = approvalLevels;
                let approvalIds = this.approvalLevels.map((x) => x.id);
                this.LoadApprovalCommentsInfo(approvalIds);
                this.fetchingApprovalLevels = false;
                await this.messageService.add({
                  severity: "success",
                  summary: "Success",
                  detail: "Approval Levels Retrieved Successfully.",
                });
                this.GroupApprovalsView();
                this.apiLoadingService.hide();
              },
              (error) => {
                this.fetchingApprovalLevels = false;
                this.connectionError();
                this.GroupApprovalsView();
                this.apiLoadingService.hide();
              }
            );

          forkJoin([observables]).subscribe(
            async (r: any) => {
              const approvalLevels = [];
              var res = r.responseData;
              res.forEach((approvals) => {
                if (approvals.length > 0) {
                  const sortedApprovals = approvals.sort(
                    (a, b) => a.sequence - b.sequence
                  );

                  for (const sortedApproval of sortedApprovals) {
                    if (!sortedApproval.isApproved) {
                      sortedApproval.canBeApproved = true;
                      break;
                    }
                  }

                  approvalLevels.push(...sortedApprovals);
                  this.finalApprovalNotificationBody.approvals.push(
                    ...sortedApprovals
                  );
                }
              });

              console.log("Setting the approval levels ", approvalLevels);
              this.showManualConvertButton = approvalLevels.every(
                (x) => x.isApproved
              );
              this.approvalLevels = approvalLevels;
              this.fetchingApprovalLevels = false;
              await this.messageService.add({
                severity: "success",
                summary: "Success",
                detail: "Approval Levels Retrieved Successfully.",
              });
              this.GroupApprovalsView();
              this.apiLoadingService.hide();
            },
            (error) => {
              this.fetchingApprovalLevels = false;
              this.connectionError();
              this.GroupApprovalsView();
              this.apiLoadingService.hide();
            }
          );
        },
        (error) => {
          this.fetchingApprovalLevels = false;
          this.connectionError();
          this.GroupApprovalsView();
          this.apiLoadingService.hide();
        }
      );
    } else if (approvalType === "Endorsements") {
      this.approvalService.getApprovalsByEndorsementId(approval.id).subscribe(
        async (r: CommonResponse) => {
          var approvals = r.responseData;
          const sorted = approvals.sort((a, b) => a.sequence - b.sequence);

          for (const sortedApproval of sorted) {
            if (!sortedApproval.isApproved) {
              sortedApproval.canBeApproved = true;
              break;
            }
          }

          this.approvalLevels = sorted;
          let approvalIds = this.approvalLevels.map((x) => x.id);
          this.LoadApprovalCommentsInfo(approvalIds);

          this.fetchingApprovalLevels = false;
          await this.messageService.add({
            severity: "success",
            summary: "Success",
            detail: "Approval Levels Retrieved Successfully.",
          });
          this.GroupApprovalsView();
          this.apiLoadingService.hide();
        },
        (error) => {
          this.fetchingApprovalLevels = false;
          this.connectionError();
          this.GroupApprovalsView();
          this.apiLoadingService.hide();
        }
      );
    }
  }

  clearInfoSpecificToApprovalLevel() {
    this.viewLead = null;
    this.divisions = null;
    this.viewService = null;
    this.selectedDivision = null;
    this.documents = [];
  }

  JustViewEntityTiedToApprovalLevel(approvalLevel: any) {
    this.showApprovalPane = false;
    this.clearInfoSpecificToApprovalLevel();

    this.approvalLevelSelected = approvalLevel;
    const approval = this.approvalSelected;

    if (approval.type === "Service Creation") {
      this.valueToPush = approval;
      this.viewService = this.services.find(
        (service) => service.id === approval.id
      );
      const createdBy = this.viewService.createdBy;
      if (createdBy) {
        this.requesterName = `${createdBy.firstName} ${createdBy.lastName}`;
      }
      const ms = this.convertMilliseconds(
        new Date().valueOf() - new Date(this.viewService.createdAt).valueOf()
      );
      this.timeSpent = `${ms.d} Days : ${ms.h} Hours : ${ms.m} Minutes : ${ms.s} Seconds.`;
    } else if (approval.type === "Addition Contract Creation") {
      this.valueToPush = approval;
      this.isQuote = false;
      this.quoteLabel = "Contract";

      const theLead = JSON.parse(
        JSON.stringify(this.contractInfoCache[approval.id])
      );

      var doc = {
        documentUrl: theLead.caption,
        caption: "endorsement evidence",
      };

      this.quoteServiceDocuments = [doc];
      this.selectedDivision = theLead.customerDivision;

      var selectedQuoteService = theLead.contractServices.find(
        (x) => x.id == approvalLevel.contractServiceId
      );

      selectedQuoteService.approvalId = approvalLevel.id;

      var quote = {
        id: selectedQuoteService.contractId,
        leadDivisionId: theLead.customerDivision.id,
        quoteServices: [selectedQuoteService],
      };

      this.divisions = theLead.customerDivision;

      this.divisions = theLead.customerDivision;
      this.quotes = [quote];
      this.viewLead = theLead.customerDivision;
      this.viewLead.groupName = theLead.customerDivision.divisionName;
      this.getAllContactsToCustomer(theLead.customerDivision.customerId);
    } else if (approval.type === "Contract Creation") {
      this.valueToPush = approval;
      this.isQuote = true;
      this.quoteLabel = "Quote";

      const theLead = JSON.parse(
        JSON.stringify(this.leadInfoCache[approval.id])
      );

      const leadDivisions = theLead.leadDivisions;
      const quoteId = approvalLevel.quoteId;
      const quoteServiceId = approvalLevel.quoteServiceId;

      let selectedLeadDivision = null;

      for (const leadDivision of leadDivisions) {
        const quote = leadDivision.quote;
        if (quoteId === quote.id) selectedLeadDivision = leadDivision;
      }

      if (selectedLeadDivision) {
        let selectedQuoteService = null;

        for (const quoteService of selectedLeadDivision.quote.quoteServices) {
          if (quoteServiceId === quoteService.id)
            selectedQuoteService = quoteService;
        }

        if (selectedQuoteService) {
          this.quoteServiceDocuments =
            selectedQuoteService.quoteServiceDocuments;
          selectedLeadDivision.quote.quoteServices = [selectedQuoteService];
          theLead.leadDivisions = [selectedLeadDivision];
          this.divisions = theLead.leadDivisions;
          this.selectDivision(selectedLeadDivision);
          this.viewLead = theLead;
          console.log("View Lead", this.viewLead);
          this.getAllContactsToSuspect(theLead.suspectId);
        }
      }
    } else if (
      approval.type === "Endorsements" &&
      approval?.description?.toLowerCase() == "service addition"
    ) {
      this.valueToPush = approval;
      this.isQuote = false;
      this.quoteLabel = "Contract";
      const endorsementId = approval.id;
      const documentUrl = approval?.documentUrl;

      this.endorsementService
        .getEndorsementOnServiceAddition(endorsementId)
        .subscribe(
          async (res) => {
            if (res.responseCode == "00") {
              const theLead = res.responseData[0];

              var doc = {
                documentUrl: documentUrl,
                caption: "endorsement evidence",
              };

              this.quoteServiceDocuments = [doc];
              this.selectedDivision = theLead.customerDivision;

              var selectedQuoteService = theLead.contractServices[0];

              selectedQuoteService.approvalId = approvalLevel.id;

              var quote = {
                id: selectedQuoteService.contractId,
                leadDivisionId: theLead.customerDivision.id,
                quoteServices: [selectedQuoteService],
              };

              this.divisions = theLead.customerDivision;

              this.divisions = theLead.customerDivision;
              this.quotes = [quote];
              this.viewLead = theLead.customerDivision;
              this.viewLead.groupName = theLead.customerDivision.divisionName;
              this.getAllContactsToCustomer(
                theLead.customerDivision.customerId
              );
              this.apiLoadingService.hide();
            } else {
              this.apiError(res.responseMsg);
              this.apiLoadingService.hide();
            }
          },
          (error) => {
            this.apiError(error.error.message);
            this.apiLoadingService.hide();
          }
        );
    } else if (approval.type === "Endorsements") {
      this.viewEndorsementDetails = null;
      this.prevContractService = null;
      this.valueToPush = approval;

      const endorsementId = approval.id;
      this.viewEndorsement = this.unApprovedEndorsements.find(
        (x) => x.id === endorsementId
      );

      const caption = this.viewEndorsement.endorsementType.caption;
      this.isValueChangeEndorsement =
        caption === "Service Topup" ||
        caption === "Service Reduction" ||
        caption == "Debit Note" ||
        caption == "Credit Note";

      this.endorsementService.getEndorsementDetails(endorsementId).subscribe(
        async (res) => {
          if (res.responseCode == "00") {
            this.viewEndorsementDetails = res.responseData;
            console.log(
              "View endorsement Details",
              this.viewEndorsementDetails
            );
            const createdBy = this.viewEndorsementDetails.createdBy;
            if (createdBy) {
              this.requesterName = `${createdBy.firstName} ${createdBy.lastName}`;
            }
            if (this.viewEndorsementDetails.documentUrl) {
              this.documents =
                this.viewEndorsementDetails.documentUrl.split(",");
            }
          } else {
            this.apiError(res.responseMsg);
          }
        },
        (error) => {
          this.apiError(error.error.message);
        }
      );

      const prevContractId = this.viewEndorsement.previousContractServiceId;
      if (prevContractId && prevContractId !== 0) {
        this.contractServiceService
          .getContractServiceById(prevContractId)
          .subscribe(
            async (prev: CommonResponse) => {
              this.prevContractService = prev.responseData;
            },
            (error) => {
              this.apiError(error.error.message);
            }
          );
      }

      const ms = this.convertMilliseconds(
        new Date().valueOf() -
          new Date(this.viewEndorsement.createdAt).valueOf()
      );
      this.timeSpent = `${ms.d} Days : ${ms.h} Hours : ${ms.m} Minutes : ${ms.s} Seconds.`;
    }

    this.detailsId.nativeElement.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "start",
    });
  }

  viewEntityTiedToApprovalLevel(approvalLevel: any) {
    this.showApprovalPane = true;
    this.clearInfoSpecificToApprovalLevel();
    this.showComment = false;

    this.approvalLevelSelected = approvalLevel;
    const approval = this.approvalSelected;

    console.log(
      "this is the approvalselected in this paradigm ",
      this.approvalLevels
    );

    if (approval.type === "Service Creation") {
      this.valueToPush = approval;
      this.viewService = this.services.find(
        (service) => service.id === approval.id
      );
      const createdBy = this.viewService.createdBy;
      if (createdBy) {
        this.requesterName = `${createdBy.firstName} ${createdBy.lastName}`;
      }
      const ms = this.convertMilliseconds(
        new Date().valueOf() - new Date(this.viewService.createdAt).valueOf()
      );
      this.timeSpent = `${ms.d} Days : ${ms.h} Hours : ${ms.m} Minutes : ${ms.s} Seconds.`;
    } else if (approval.type === "Addition Contract Creation") {
      this.valueToPush = approval;
      this.isQuote = false;
      this.quoteLabel = "Contract";

      const theLead = JSON.parse(
        JSON.stringify(this.contractInfoCache[approval.id])
      );

      var doc = {
        documentUrl: theLead.caption,
        caption: "endorsement evidence",
      };

      this.quoteServiceDocuments = [doc];
      this.selectedDivision = theLead.customerDivision;

      var selectedQuoteService = theLead.contractServices.find(
        (x) => x.id == approvalLevel.contractServiceId
      );

      selectedQuoteService.approvalId = approvalLevel.id;

      var quote = {
        id: selectedQuoteService.contractId,
        leadDivisionId: theLead.customerDivision.id,
        quoteServices: [selectedQuoteService],
      };

      this.divisions = theLead.customerDivision;

      this.divisions = theLead.customerDivision;
      this.quotes = [quote];
      this.viewLead = theLead.customerDivision;
      this.viewLead.groupName = theLead.customerDivision.divisionName;
      this.getAllContactsToCustomer(theLead.customerDivision.customerId);
    } else if (approval.type === "Contract Creation") {
      this.valueToPush = approval;
      this.isQuote = true;
      this.quoteLabel = "Quote";

      const theLead = JSON.parse(
        JSON.stringify(this.leadInfoCache[approval.id])
      );

      const leadDivisions = theLead.leadDivisions;
      const quoteId = approvalLevel.quoteId;
      const quoteServiceId = approvalLevel.quoteServiceId;

      let selectedLeadDivision = null;

      for (const leadDivision of leadDivisions) {
        const quote = leadDivision.quote;
        if (quoteId === quote.id) selectedLeadDivision = leadDivision;
      }

      if (selectedLeadDivision) {
        let selectedQuoteService = null;

        for (const quoteService of selectedLeadDivision.quote.quoteServices) {
          if (quoteServiceId === quoteService.id)
            selectedQuoteService = quoteService;
        }

        if (selectedQuoteService) {
          this.quoteServiceDocuments =
            selectedQuoteService.quoteServiceDocuments;
          selectedLeadDivision.quote.quoteServices = [selectedQuoteService];
          theLead.leadDivisions = [selectedLeadDivision];
          this.divisions = theLead.leadDivisions;
          this.selectDivision(selectedLeadDivision);
          this.viewLead = theLead;
          console.log("View Lead", this.viewLead);
          this.getAllContactsToSuspect(theLead.suspectId);
        }
      }
    } else if (
      approval.type === "Endorsements" &&
      approval?.description?.toLowerCase() == "service addition"
    ) {
      this.valueToPush = approval;
      this.isQuote = false;
      this.quoteLabel = "Contract";
      const endorsementId = approval.id;
      const documentUrl = approval?.documentUrl;

      this.endorsementService
        .getEndorsementOnServiceAddition(endorsementId)
        .subscribe(
          async (res) => {
            if (res.responseCode == "00") {
              const theLead = res.responseData[0];

              var doc = {
                documentUrl: documentUrl,
                caption: "endorsement evidence",
              };

              this.quoteServiceDocuments = [doc];
              this.selectedDivision = theLead.customerDivision;

              var selectedQuoteService = theLead.contractServices[0];

              selectedQuoteService.approvalId = approvalLevel.id;

              var quote = {
                id: selectedQuoteService.contractId,
                leadDivisionId: theLead.customerDivision.id,
                quoteServices: [selectedQuoteService],
              };

              this.divisions = theLead.customerDivision;

              this.divisions = theLead.customerDivision;
              this.quotes = [quote];
              this.viewLead = theLead.customerDivision;
              this.viewLead.groupName = theLead.customerDivision.divisionName;
              this.getAllContactsToCustomer(
                theLead.customerDivision.customerId
              );
              this.apiLoadingService.hide();
            } else {
              this.apiError(res.responseMsg);
            }
          },
          (error) => {
            this.apiError(error.error.message);
          }
        );
    } else if (approval.type === "Endorsements") {
      this.viewEndorsementDetails = null;
      this.prevContractService = null;
      this.valueToPush = approval;

      const endorsementId = approval.id;
      this.viewEndorsement = this.unApprovedEndorsements.find(
        (x) => x.id === endorsementId
      );

      const caption = this.viewEndorsement.endorsementType.caption;
      this.isValueChangeEndorsement =
        caption === "Service Topup" ||
        caption === "Service Reduction" ||
        caption == "Debit Note" ||
        caption == "Credit Note";

      this.endorsementService.getEndorsementDetails(endorsementId).subscribe(
        async (res) => {
          if (res.responseCode == "00") {
            this.viewEndorsementDetails = res.responseData;
            console.log(
              "View endorsement Details",
              this.viewEndorsementDetails
            );
            const createdBy = this.viewEndorsementDetails.createdBy;
            if (createdBy) {
              this.requesterName = `${createdBy.firstName} ${createdBy.lastName}`;
            }
            if (this.viewEndorsementDetails.documentUrl) {
              this.documents =
                this.viewEndorsementDetails.documentUrl.split(",");
            }
            this.apiLoadingService.hide();
          } else {
            this.apiError(res.responseMsg);
          }
        },
        (error) => {
          this.apiError(error.error.message);
        }
      );

      const prevContractId = this.viewEndorsement.previousContractServiceId;
      if (prevContractId && prevContractId !== 0) {
        this.contractServiceService
          .getContractServiceById(prevContractId)
          .subscribe(
            async (prev: CommonResponse) => {
              this.prevContractService = prev.responseData;
              this.apiLoadingService.hide();
            },
            (error) => {
              this.apiError(error.error.message);
            }
          );
      }

      const ms = this.convertMilliseconds(
        new Date().valueOf() -
          new Date(this.viewEndorsement.createdAt).valueOf()
      );
      this.timeSpent = `${ms.d} Days : ${ms.h} Hours : ${ms.m} Minutes : ${ms.s} Seconds.`;
    }

    this.detailsId.nativeElement.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "start",
    });
  }

  private connectionError() {
    this.messageService.add({
      severity: "error",
      summary: "Failed",
      detail: "Connection Error, Please try again",
    });
    this.apiLoadingService.hide();
  }

  private apiError(errorMessage: string) {
    this.messageService.add({
      severity: "error",
      summary: "Failed",
      detail: `An application error occured, ${errorMessage}. Please try again.`,
    });
    this.apiLoadingService.hide();
  }

  approveService() {
    const currentSequence = this.approvalLevelSelected.sequence;
    const everyOtherLevelIsApproved = this.approvalLevels
      .filter((x) => x.sequence !== currentSequence)
      .every((x) => x.isApproved);

    let message = "Are you sure you want to approve " + this.viewService.name;
    if (everyOtherLevelIsApproved) {
      message =
        message +
        ". Kindly note that approving this will complete approval process for the service.";
    }

    this.confirmationService.confirm({
      message,
      accept: () => {
        this._approveService(everyOtherLevelIsApproved);
        this.CreateFulfillmentProject();
        if (everyOtherLevelIsApproved) this.SendFinalApprovalNotification();
      },
    });
  }

  _approveService(everyOtherLevelIsApproved) {
    console.log("everyOtherLevelIsApproved", everyOtherLevelIsApproved);

    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Approving Service Record",
    });
    this.serviceService
      .approveService(this.viewService.id, this.approvalLevelSelected.sequence)
      .subscribe(
        async () => {
          this.messageService.add({
            severity: "success",
            summary: "Completed",
            detail: "Service Record Approved.",
          });
          if (everyOtherLevelIsApproved) this.fetchApprovals();
          else this.viewApprovalLevels(this.approvalSelected);
          this.GroupApprovalsView();
          this.apiLoadingService.hide();
        },
        (error) => {
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail: "Unable to approve service at the moment",
          });
          this.viewApprovalLevels(this.approvalSelected);
          this.GroupApprovalsView();
          this.apiLoadingService.hide();
        }
      );
  }

  disapproveService() {
    this.confirmationService.confirm({
      message: "Are you sure you want to disapprove " + this.viewService.name,
      accept: () => {
        this._disapproveService();
      },
    });
  }

  _disapproveService() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Disapproving Service Record",
    });
    this.serviceService
      .disapproveService(
        this.viewService.id,
        this.approvalLevelSelected.sequence
      )
      .subscribe(
        async () => {
          await this.messageService.add({
            severity: "success",
            summary: "Completed",
            detail: "Service Record Disapproved.",
          });
          await this.fetchApprovals();
          this.GroupApprovalsView();
          this.apiLoadingService.hide();
        },
        (error) => {
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail: "Unable to disapprove service at the moment",
          });
          this.GroupApprovalsView();
          this.apiLoadingService.hide();
        }
      );

    this.viewService = null;
  }

  approveEndorsement() {
    
    console.log("These are the approvals ", this.approvalLevels);
    const currentSequence = this.approvalLevelSelected.sequence;
    const everyOtherLevelIsApproved = this.approvalLevels
      .filter((x) => x.sequence !== currentSequence)
      .every((x) => x.isApproved);

    let message =
      "Are you sure you want to approve " +
      this.viewEndorsement.endorsementType.caption;
    if (everyOtherLevelIsApproved) {
      message =
        message +
        ". Kindly note that approving this will complete approval process for the endorsement.";
    }

    this.confirmationService.confirm({
      message,
      accept: () => {
        this._approveEndorsement(everyOtherLevelIsApproved);
        if (everyOtherLevelIsApproved) {
          this.CreateFulfillmentProject();
          this.SendFinalApprovalNotification();
        }
      },
    });
  }

  _approveEndorsement(everyOtherLevelIsApproved) {
    console.log("everyOtherLevelIsApproved", everyOtherLevelIsApproved);

    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Approving endorsement record",
    });

    this.endorsementService
      .approveEndorsement(
        this.viewEndorsement.id,
        this.approvalLevelSelected.sequence
      )
      .subscribe(
        async (r: CommonResponse) => {
          this.apiLoadingService.hide();
          if (r.responseCode == "00") {
            this.messageService.add({
              severity: "success",
              summary: "Completed",
              detail: r.responseMsg,
            });
            this.viewApprovalLevels(this.approvalSelected);
            this.GroupApprovalsView();
            // var approveCompleted = this.approvalLevels.filter((el)=>{
            //   return el.isApproved == false
            // }).length
            // if(approveCompleted == 0){
            //   console.log("this is the last calling card ",this.approvalSelected);
            // }
          } else if (r.responseCode == "10") {
            this.messageService.add({
              severity: "success",
              summary: "Completed",
              detail: r.responseMsg,
            });
            // var approveCompleted = this.approvalLevels.filter((el)=>{
            //   return el.isApproved == false
            // }).length
            // if(approveCompleted == 0){
            //   console.log("this is the last calling card ",this.approvalSelected);
            // }
            this.fetchApprovals();
          } else {
            this.messageService.add({
              severity: "error",
              summary: "Failure",
              detail: r.responseMsg,
            });
          }

          // if (everyOtherLevelIsApproved)
          // {
          //   const edType =
          //     this.viewEndorsement.endorsementType.caption.toLowerCase();
          //   console.log("EdType", edType);
          //   if (edType == "credit note" || edType == "debit note") {
          //     this.endorsementService
          //       .endorsementConvertDebitCreditNote(this.viewEndorsement.id)
          //       .subscribe(
          //         async (res) => {
          //           console.log("The result for converting to contract ", res);
          //           this.messageService.add({
          //             severity: "success",
          //             summary: "Completed",
          //             detail: "Endorsement converted to contract successfully.",
          //           });
          //         },
          //         (error) => {
          //           this.messageService.add({
          //             severity: "error",
          //             summary: "Notice",
          //             detail: "Unable to convert endorsement to contract",
          //           });
          //         }
          //       );
          //   } else {
          //     console.log("converting to contr");

          //     this.endorsementService
          //       .endorsementConvertToContract(this.viewEndorsement.id)
          //       .subscribe(
          //         async (res: CommonResponse) => {
          //          if(res.responseCode=='00'){
          //           this.messageService.add({
          //             severity: "success",
          //             summary: "Completed",
          //             detail: "Endorsement converted to contract successfully.",
          //           });
          //          }else{
          //           this.messageService.add({
          //             severity: "error",
          //             summary: "Failure",
          //             detail: res.responseMsg,
          //           });
          //          }
          //         },
          //         (error) => {
          //           this.messageService.add({
          //             severity: "error",
          //             summary: "Notice",
          //             detail: "Unable to convert endorsement to contract",
          //           });
          //         }
          //       );
          //   }

          //   this.fetchApprovals();
          // } else this.viewApprovalLevels(this.approvalSelected);
        },
        (error) => {
          this.messageService.add({
            severity: "error",
            summary: "Error",
            detail: "Unable to approve endorsement at the moment.",
          });
          this.viewApprovalLevels(this.approvalSelected);
          this.GroupApprovalsView();
          this.apiLoadingService.hide();
        }
      );
  }

  disapproveEndorsement() {
    this.confirmationService.confirm({
      message:
        "Are you sure you want to disapprove " +
        this.viewEndorsement.endorsementType.caption,
      accept: () => {
        this._disapproveEndorsement();
      },
    });
  }

  _disapproveEndorsement() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Disapproving endorsement record.",
    });
    this.endorsementService
      .declineEndorsementApproval(
        this.viewEndorsement.id,
        this.approvalLevelSelected.sequence
      )
      .subscribe(
        async (r: CommonResponse) => {
          if (r.responseCode == "00") {
            await this.messageService.add({
              severity: "success",
              summary: "Completed",
              detail: "Endorsement record disapproved.",
            });
            await this.fetchApprovals();
            this.GroupApprovalsView();
            this.apiLoadingService.hide();
          } else {
            this.messageService.add({
              severity: "error",
              summary: "Failure",
              detail: r.responseMsg,
            });
          }
        },
        (error) => {
          this.messageService.add({
            severity: "error",
            summary: "Error",
            detail: "Unable to disapprove endorsement at the moment.",
          });
          this.GroupApprovalsView();
          this.apiLoadingService.hide();
        }
      );

    this.viewEndorsement = null;
  }

  convertMilliseconds(milliseconds) {
    let seconds = Math.floor(milliseconds / 1000);
    let minute = Math.floor(seconds / 60);
    seconds = seconds % 60;
    let hour = Math.floor(minute / 60);
    minute = minute % 60;
    const day = Math.floor(hour / 24);
    hour = hour % 24;

    return {
      d: day,
      h: hour,
      m: minute,
      s: seconds,
    };
  }

  transform(image) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(image);
  }

  getAllContactsToSuspect(id) {
    if (id)
      this.engagementService.getAllContactsAttachedToSuspects(id).subscribe(
        async (res: any) => {
          if (res.responseCode == "00") {
            this.suspectContact = [];
            this.suspectContact = res.responseData;
          } else {
            this.suspectContact = [];
          }
          this.apiLoadingService.hide();
        },
        (error) => {
          this.suspectContact = [];
          this.apiLoadingService.hide();
        }
      );
  }

  getAllContactsToCustomer(id) {
    if (id)
      this.engagementService.getAllContactsAttachedToCustomer(id).subscribe(
        async (res: any) => {
          if (res.responseCode == "00") {
            this.suspectContact = [];
            this.suspectContact = res.responseData;
          } else {
            this.suspectContact = [];
          }
        },
        (error) => {
          this.suspectContact = [];
        }
      );
  }

  selectDivision(division: any) {
    this.selectedDivision = division;
    this.quotes = division.quote ? [division.quote] : [];
    console.log("Quote from selectDivision", this.quotes);
  }

  viewQuoteServiceDetails(quoteService: any, fetchProportions: boolean = true) {
    if (quoteService) {
      var isQuote = this.isQuote;
      this.quoteServiceDetailsRef = this.dialogService.open(
        ViewQuoteserviceDetailsComponent,
        {
          header: `${this.quoteLabel} Service Details`,
          width: "70%",
          contentStyle: { "min-height": "350px", overflow: "auto" },
          baseZIndex: 10000,
          data: { quoteService, fetchProportions, isQuote },
        }
      );

      this.quoteServiceDetailsRef.onClose.subscribe(async (res: any) => {});
      this.apiLoadingService.hide();
    }
  }

  disapproveLead() {
    if (!this.DisapprovalComment) {
      this.messageService.add({
        severity: "error",
        summary: "Failure",
        detail: "Please enter comment for this disapproval",
      });
      return;
    }

    this.confirmationService.confirm({
      message:
        "Are you sure you want to disapprove quote service ? <br>" +
        "Kindly note that this will move the lead back to conversion stage.",
      header: "Confirmation",
      icon: "pi pi-exclamation-triangle",
      acceptLabel: "Yes, disapprove lead.",
      acceptButtonStyleClass: "bg-danger",
      defaultFocus: "reject",
      accept: () => {
        this._disapproveLead();
      },
      reject: () => {
        this.operationCancelled();
      },
    });
  }

  operationCancelled() {
    this.messageService.add({
      severity: "error",
      summary: "Operation Cancelled",
      detail: "Operation Cancelled",
    });
  }

  _disapproveLead() {
    const leadId = this.viewLead.id;
    const sequence = this.approvalLevelSelected.sequence;
    const quoteServiceId =
      this.viewLead.leadDivisions[0].quote.quoteServices[0].id;
    var comment = this.DisapprovalComment;

    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Disapproving quote service record",
    });

    this.leadService
      .disapproveQuoteService(leadId, quoteServiceId, sequence, comment)
      .subscribe(
        async () => {
          await this.messageService.add({
            severity: "success",
            summary: "Completed",
            detail: "Service Record disapproved.",
          });
          this.fetchApprovals();
          this.GroupApprovalsView();
          this.apiLoadingService.hide();
        },
        (error) => {
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail: "Unable to disapprove quote service at the moment",
          });
          this.fetchApprovals();
          this.GroupApprovalsView();
          this.apiLoadingService.hide();
        }
      );
  }

  approveLead() {
    const quoteServiceId =
      this.viewLead.leadDivisions[0].quote.quoteServices[0].id;
    const everyOtherLevelIsApproved = this.approvalLevels
      .filter((x) => x.id !== this.approvalLevelSelected.id)
      .every((x) => x.isApproved);

    if (everyOtherLevelIsApproved) {
      this.convertLeadToClient(quoteServiceId);
    } else {
      this.confirmationService.confirm({
        message: `Are you sure you want to approve this quote service ?`,
        header: "Confirmation",
        icon: "pi pi-exclamation-triangle",
        acceptButtonStyleClass: "bg-success",
        accept: async () => {
          await this._approveLead(quoteServiceId, false);
        },
        reject: () => {
          this.operationCancelled();
        },
      });
    }
  }

  approveContractService(approvalId, contractId) {
    this.confirmationService.confirm({
      message: `Are you sure you want to approve this contract service ?`,
      header: "Confirmation",
      icon: "pi pi-exclamation-triangle",
      acceptButtonStyleClass: "bg-success",
      accept: async () => {
        await this._approveOrDisapprove(true, approvalId, contractId);
        this.CreateFulfillmentProject();
      },
      reject: () => {
        this.operationCancelled();
      },
    });
  }

  disapproveContractService(approvalId, contractId) {
    this.confirmationService.confirm({
      message:
        `Are you sure you want to disapprove? <br>` +
        "Kindly note that this will end the proposed contract.",
      header: "Confirmation",
      icon: "pi pi-exclamation-triangle",
      acceptLabel: "Yes.",
      acceptButtonStyleClass: "bg-danger",
      defaultFocus: "reject",
      accept: () => {
        this._approveOrDisapprove(false, approvalId, contractId);
      },
      reject: () => {
        this.operationCancelled();
      },
    });
  }

  async _approveOrDisapprove(isApproved, approvalId, contractId) {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: isApproved
        ? "Approving contract service"
        : "Disapproving contract service",
    });

    this.approvalService
      .disapproveOrApproveContractService(isApproved, approvalId, contractId)
      .subscribe(
        async (res: CommonResponse) => {
          this.apiLoadingService.hide();
          if (res.responseCode == "00") {
            this.messageService.add({
              severity: "success",
              summary: "Completed",
              detail: res.responseMsg,
            });
            this.viewApprovalLevels(this.approvalSelected);
          } else if (res.responseCode == "10") {
            this.messageService.add({
              severity: "success",
              summary: "Completed",
              detail: res.responseMsg,
            });
            this.fetchApprovals();
            this.GroupApprovalsView();
          } else {
            this.messageService.add({
              severity: "error",
              summary: "Error",
              detail: res.responseMsg,
            });
          }
        },
        (error) => {
          this.connectionError();
          this.viewApprovalLevels(this.approvalSelected);
          this.GroupApprovalsView();
        }
      );
  }

  async _approveLead(quoteServiceId, finalApproval: boolean) {
    const leadId = this.viewLead.id;
    const sequence = this.approvalLevelSelected.sequence;

    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Approving quote service record",
    });

    this.leadService
      .approveQuoteService(leadId, quoteServiceId, sequence)
      .subscribe(
        async (res: boolean) => {
          if (res) {
            this.messageService.add({
              severity: "success",
              summary: "Completed",
              detail: "Quote service approved.",
            });
          } else {
            this.messageService.add({
              severity: "error",
              summary: "Error",
              detail: "Unable to approve quote service at the moment",
            });
          }
          if (!finalApproval) this.viewApprovalLevels(this.approvalSelected);
          if (finalApproval) this.SendFinalApprovalNotification();
          this.apiLoadingService.hide();
          this.GroupApprovalsView();
        },
        (error) => {
          this.connectionError();
          this.viewApprovalLevels(this.approvalSelected);
        }
      );
  }

  convertLeadToClient(quoteServiceId) {
    const groupName = this.approvalSelected.name;

    const message =
      "As the final approval entity of this workflow, you are now presented with the last leg in the lead conversion process for <b>" +
      groupName +
      "</b><br><br> Kindly click yes to convert lead(s) within this group to client(s) officially." +
      "Please note that clicking yes will automatically do 4 things <br><br>" +
      "1. <b>Generate the amortization records</b> <br>" +
      "2. <b>Post receivable accounting entries and book the income where applicable</b> <br>" +
      "3. <b>Generate project management framework for contract fulfillment</b><br>" +
      "4. <b>Creates the client and registers a unique client identification number and sends a notification to client contacts confirming the completion of the on-boarding process</b>" +
      "<br><br>Or click no to convert later. <br><br>" +
      "Kindly indicate how you would like to proceed.";

    this.confirmationService.confirm({
      message,
      header: "Confirmation",
      icon: "pi pi-exclamation-triangle",
      acceptButtonStyleClass: "bg-success",
      acceptLabel: "Yes, Convert lead to client.",
      accept: async () => {
        console.log("Accepted");
        if (quoteServiceId) {
          await this._approveLead(quoteServiceId, true);
        }
        this._convertLeadToClient();
        this.CreateFulfillmentProject();
      },
      reject: () => {
        console.log("Rejected");
        this.messageService.add({
          severity: "info",
          summary: "Notice",
          detail: "Kindly ensure that you convert the lead to client.",
        });
        // this.viewApprovalLevels(this.approvalSelected);
      },
    });
  }

  _convertLeadToClient() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Converting Lead..",
    });
    this.leadService.convertLeadCustomer(this.approvalSelected.id).subscribe(
      async (r: CommonResponse) => {
        if (r.responseCode == "00") {
          this.messageService.add({
            severity: "success",
            summary: "Completed",
            detail: "Converted Successfully.",
          });
          this.fetchApprovals();
          this.GroupApprovalsView();
        } else {
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail: `An error occurred when converting lead. ${r.responseMsg}`,
          });
        }
        this.apiLoadingService.hide();
        this.GroupApprovalsView();
      },
      (error) => {
        console.log("Error ", error);
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail: `An error occurred when converting lead. ${error.error.message}`,
        });
        this.apiLoadingService.hide();
        this.GroupApprovalsView();
      }
    );
  }

  GetUserName(userId: number): string {
    let user = this.allUsers.find((x) => x.id == userId);
    if (user) return user.lastName + " " + user.firstName;

    return "N/A";
  }

  GetUserEmail(userId: number): string {
    let user = this.allUsers.find((x) => x.id == userId);
    if (user) return user.email;

    return "";
  }

  OpenChatBox(item: Approval) {
    this.openCommentSection = true;
    this.commentApproval = item;
    item.isUnread = false;
    this.LoadApprovalComments();
  }

  LoadApprovalComments() {
    this.loadingApprovalComments = true;
    this.approvalCommentService
      .GetApprovalComments(this.commentApproval.id)
      .subscribe(
        async (data) => {
          this.comments = data;
          this.loadingApprovalComments = false;
        },
        (error) => {
          console.log("Error: " + JSON.stringify(error));
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail:
              "Unable to fetch approval comments at the moment.. Reason: [" +
              error.error.message +
              "]",
          });
          this.loadingApprovalComments = false;
        }
      );
  }

  HideCommentBox() {
    this.openCommentSection = false;
    this.comments = [];
    this.loadingApprovalComments = false;
    this.commentApproval = null;
  }

  PostComment() {
    this.approvalCommentService
      .CreateApprovalComment({
        approvalId: this.commentApproval.id,
        comment: this.newApprovalComment,
        commenator: this.GetUserName(this.loggedInUser.id),
      })
      .subscribe(
        async () => {
          this.messageService.add({
            severity: "success",
            summary: "Completed",
            detail: "Comment Posted Successfully...",
          });
          this.newApprovalComment = null;
          this.LoadApprovalComments();
        },
        (error) => {
          console.log("Error: " + JSON.stringify(error));
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail:
              "Unable to post approval comment at the moment.. Reason: [" +
              error.error.message +
              "]",
          });
        }
      );
  }

  DeleteComment(item: ApprovalComment) {
    this.confirmationService.confirm({
      message: "Are you sure you want to remove comment?",
      accept: () => {
        this.messageService.add({
          severity: "info",
          summary: "Notice",
          detail: "Removing Comment...",
        });

        this.approvalCommentService.DeleteApprovalComment(item.id).subscribe(
          async () => {
            this.messageService.add({
              severity: "success",
              summary: "Completed",
              detail: "Removed Posted Comment Successfully...",
            });
            this.newApprovalComment = null;
            this.LoadApprovalComments();
            // const index = this.comments.indexOf(item);
            // if (index > -1) {
            //   this.comments.splice(index, 1);
            // }
          },
          (error) => {
            console.log("Error: " + JSON.stringify(error));
            this.messageService.add({
              severity: "error",
              summary: "Notice",
              detail:
                "Unable to delete approval comment at the moment.. Reason: [" +
                error.error.message +
                "]",
            });
          }
        );
      },
    });
  }

  GetCommentAuthor(authorId?: number): string {
    if (authorId && authorId != this.loggedInUser.id)
      return this.GetUserName(authorId);
    else return "Me";
  }

  SendFinalApprovalNotification() {
    if (this.finalApprovalNotificationBody) {
      this.approvalService
        .SendFinalApprovalNotification(this.finalApprovalNotificationBody)
        .subscribe(
          async () => {
            console.log("Final Approver Notification Sent Successfully");
            this.GroupApprovalsView();
          },
          (error) => {
            console.log("Error: " + JSON.stringify(error));
            this.messageService.add({
              severity: "error",
              summary: "Notice",
              detail:
                "Unable to send final approval notification at the moment.. Reason: [" +
                error.error.message +
                "]",
            });
          }
        );
    }
  }

  async LoadApprovalSummaryInfo(data: GetApprovalSummaryInfoReceivingDTO) {
    this.isDoneLoadingSummary = false;
    this.participatingApprovalsIds = [];
    this.approvalService.GetApprovalSummaryInfo(data).subscribe(
      async (r) => {
        var resp =
          (r.responseData as GetApprovalSummaryInfoTransferDTO) ?? null;
        if (resp) {
          this.totalPendingApprovalForMe = resp.totalPendingApprovalForMe;
          this.totalApprovalTypesWaitingForMe =
            resp.totalApprovalTypesWaitingForMe;
          this.totalApprovalOnQueue = resp.totalApprovalOnQueue;
          this.participatingApprovalsIds = resp.approvalsForMeIds;
          this.LoadApprovalParticipating();
        }
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all users at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
        this.isDoneLoadingSummary = true;
      }
    );
  }

  LoadApprovalCommentsInfo(approvalIds: number[]) {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Loading Selected Approval Comment(s) Info",
    });

    //Simply return unread as true
    this.approvalCommentService
      .CheckApprovalComentReadReceipts({ approvalIds: approvalIds })
      .subscribe(
        async (data) => {
          this.approvalLevels.forEach((approvalLevel) => {
            let isUnread = data.find((x) => x == approvalLevel.id);
            if (isUnread) approvalLevel.isUnread = true;
          });
        },
        (error) => {
          console.log("Error: " + JSON.stringify(error));
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail:
              "Unable to check approval comment receipts at the moment.. Reason: [" +
              error.error.message +
              "]",
          });
        }
      );
  }

  GroupByArray(xs, key) {
    return xs.reduce(function (rv, x) {
      let v = key instanceof Function ? key(x) : x[key];
      let el = rv.find((r) => r && r.key === v);
      if (el) {
        el.values.push(x);
      } else {
        rv.push({
          key: v,
          values: [x],
        });
      }
      return rv;
    }, []);
  }

  GroupApprovalsView() {
    if (this.approvals.length > 0) {
      this.fetchingApprovals = true;
      this.approvalsClientBatch = [];
      let groupedByClient = [
        ...new Set(this.approvals.map((approval) => approval.name)),
      ];
      let groupedByServiceType = [
        ...new Set(this.approvals.map((approval) => approval.type)),
      ];
      groupedByClient.forEach((grpClient) => {
        let apClBatchs: {
          clientName: string;
          isParticipant: boolean;
          clientBatches: {
            approvalType: string;
            approvalCount: number;
            approvals: Approval[];
          }[];
        } = {
          clientName: grpClient,
          isParticipant: false,
          clientBatches: [],
        };
        groupedByServiceType.forEach((grpServiceType) => {
          let allClientApprovalsInType = this.approvals.filter(
            (x) => x.name == grpClient && x.type == grpServiceType
          );

          if (allClientApprovalsInType.length > 0) {
            apClBatchs.clientBatches.push({
              approvalType: grpServiceType,
              approvalCount: allClientApprovalsInType.length,
              approvals: allClientApprovalsInType,
            });
          }
        });
        this.approvalsClientBatch.push(apClBatchs);
      });
      this.fetchingApprovals = false;

      this.LoadApprovalParticipating();
    }
  }

  async LoadApprovalParticipating() {
    this.participatingApprovalsIds.forEach((participaringId) => {
      this.approvalsClientBatch.forEach((clientBatch) => {
        let isParticipatingApp = clientBatch.clientBatches.find(
          (appType) =>
            appType.approvals.find((app) => app.id == participaringId) != null
        );
        if (!clientBatch.isParticipant && isParticipatingApp)
          clientBatch.isParticipant = true;
      });
    });
    let clApps = this.approvalsClientBatch.filter((x) => x.isParticipant);
    let countAppType: string[] = [];
    let countApp = 0;
    clApps.forEach((btch) => {
      countApp++;
      btch.clientBatches.forEach((dits) => {
        if (countAppType.find((x) => x == dits.approvalType) == null)
          countAppType.push(dits.approvalType);
      });
    });
    this.totalPendingApprovalForMe = countApp;
    this.totalApprovalTypesWaitingForMe = countAppType.length;
    this.isDoneLoadingSummary = true;
  }
}
