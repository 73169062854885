import {Component, OnInit} from '@angular/core';
import {CommonResponse, Lead, LeadDivision, OtherLeadCaptureInfo} from '../../../interfaces/home';
import {LeadService} from '../../../services/lead.service';
import {OtherLeadCaptureInfoService} from '../../../services/other-lead-capture-info.service';

@Component({
    selector: 'app-financial-info-view',
    templateUrl: './financial-info-view.component.html',
    styleUrls: ['./financial-info-view.component.scss']
})
export class FinancialInfoViewComponent implements OnInit {
    leadDivisions: LeadDivision[] = [];
    selectedDivision: LeadDivision | null;
    currentLead: Lead | null;
    public groupType: number;
  public loadingService: boolean;
  public selectedDivisionOtherInfo: OtherLeadCaptureInfo;
  public selectedDivisionOtherInfoStatus: boolean;

    constructor(
        public leadService: LeadService,
        private otherLeadCaptureInfoService: OtherLeadCaptureInfoService
    ) {
    }
    ngOnInit(): void {
      console.log('here');
        this.leadService.activeLead().subscribe(async (res: any) => {
            if (res) {
                this.currentLead = res;
                this.leadDivisions = this.currentLead.leadDivisions;
                const _groupType = this.currentLead.groupType ? this.currentLead.groupType.caption : '';
                if (_groupType === 'Corporate' || _groupType === 'Government') {
                    this.groupType = 1;
                } else {
                  this.groupType =2;
                }
                if (this.leadDivisions.length > 0) {
                    this.activate(this.leadDivisions[0]);
                }
            }
        })
    }

    activate(leadDivision: LeadDivision) {
      this.loadingService = true;
      this.selectedDivision = this.leadDivisions.find(_leadDivision => _leadDivision.id === leadDivision.id);
      if(this.selectedDivision){
        // prefill all form
        this.otherLeadCaptureInfoService.fetchByDivisionId(this.selectedDivision.id).subscribe( (result:CommonResponse)=> {
         this.selectedDivisionOtherInfo = result.responseData;
          this.selectedDivisionOtherInfoStatus = true;
        })
      }
    }
}
