import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { ConfirmationService, MessageService } from "primeng/api";
import { BreadcrumbService } from "src/app/breadcrumb.service";
import {
  CommonResponse,
  Contract,
  ContractService,
  CustomerDivision,
  GroupedInvoice,
  Invoice,
  ReverseGroupInvoiceVM,
} from "src/app/interfaces/home";
import { ContractServiceService } from "src/app/services/contract-service.service";
import { CustomerDivisionService } from "src/app/services/customer-division.service";
import { InvoiceService } from "src/app/services/invoice.sevice";

@Component({
  selector: "app-reverse-invoice",
  templateUrl: "./reverse-invoice.component.html",
  styleUrls: ["./reverse-invoice.component.scss"],
})
export class ReverseInvoiceComponent implements OnInit {
  @ViewChild("formWrapper") public formWrapper: ElementRef;
  allClients: CustomerDivision[];
  theClient: CustomerDivision;
  allContracts: Contract[];
  theContract: Contract;
  fetchingContractServices: boolean;
  allContractServices: ContractService[];
  theContractService: ContractService;
  contractServCols: any[];
  clientInView: CustomerDivision;
  contractInView: Contract;
  invoiceGroups: GroupedInvoice[];
  invGroupCols: any[];
  fetchingContractServiceInvoices: boolean;
  contractServiceInView: ContractService;
  invoiceGroupInView: GroupedInvoice;

  constructor(
    public invoiceService: InvoiceService,
    public customerDivisionService: CustomerDivisionService,
    public contractServiceService: ContractServiceService,
    private breadcrumbService: BreadcrumbService,
    public confirmationService: ConfirmationService,
    public messageService: MessageService
  ) {}

  ngOnInit(): void {
    this.breadcrumbService.setItems([
      {
        label: "Finance",
      },
      {
        label: "Reverse Invoice",
        routerLink: ["/home/reverse-invoice"],
      },
    ]);

    this.contractServCols = [
      { field: "service.name", header: "Service" },
      { field: "serviceId", header: "Service ID" },
      { field: "version", header: "Version" },
    ];

    this.invGroupCols = [
      { field: "invoiceNumber", header: "Invoice Number" },
      { field: "totalInvoiceValue", header: "Invoice Value" },
    ];

    this.FetchAllClients();
  }

  FetchAllClients() {
    this.customerDivisionService.GetCustomerDivisionswWithContracts().subscribe(
      async (res: CommonResponse) => {
        if (res.responseCode == "00") {
          this.allClients = res.responseData;
        } else {
          this.messageService.add({
            severity: "error",
            summary: "Failure",
            detail:
              "Err: Could not get all clients. Reason -> " + res.responseMsg,
          });
        }
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to get all clients at the moment.. Reason: [" +
            error.error.message +
            "]",
        });
      }
    );
  }

  OnClientChange() {
    this.theContract = null;
    this.allContracts = [];
    if (this.theClient) {
      this.allContracts = this.theClient.contracts;
      this.allContracts.forEach(
        (x) =>
          (x.caption =
            "Contract-" +
            x.id.toString().padStart(5, "0") +
            "-" +
            (x.description ?? ""))
      );
    }
  }

  LoadContractServices() {
    this.allContractServices = [];
    this.clientInView = null;
    this.contractInView = null;
    this.contractServiceInView = null;
    if (this.theContract) {
      this.clientInView = this.theClient;
      this.contractInView = this.theContract;
      this.fetchingContractServices = true;
      this.contractServiceService
        .GetAllContractServciesByContractId(this.theContract.id)
        .subscribe(
          async (res: CommonResponse) => {
            if (res.responseCode == "00") {
              this.allContractServices = res.responseData;
              this.fetchingContractServices = false;
            } else {
              this.messageService.add({
                severity: "error",
                summary: "Failure",
                detail:
                  "Err: Could not get all contract services. Reason -> " +
                  res.responseMsg,
              });
            }
          },
          (error) => {
            console.log("Error: " + JSON.stringify(error));
            this.messageService.add({
              severity: "error",
              summary: "Notice",
              detail:
                "Unable to get contract services at the moment.. Reason: [" +
                error.error.message +
                "]",
            });
            this.fetchingContractServices = false;
          }
        );
    } else {
      this.fetchingContractServices = false;
    }
  }

  LoadContractServiceInvoices(item: ContractService) {
    this.invoiceGroups = [];
    this.contractServiceInView = null;
    this.fetchingContractServiceInvoices = true;
    this.invoiceGroupInView = null;
    this.invoiceService.GetContractServiceInvoicesGrouped(item.id).subscribe(
      async (res: CommonResponse) => {
        if (res.responseCode == "00") {
          this.invoiceGroups = res.responseData;
          this.fetchingContractServiceInvoices = false;
          this.contractServiceInView = item;
        } else {
          this.messageService.add({
            severity: "error",
            summary: "Failure",
            detail:
              "Err: Could not get contract service invoices. Reason -> " +
              res.responseMsg,
          });
        }
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to get contract service invoices at the moment.. Reason: [" +
            error.error.message +
            "]",
        });
        this.fetchingContractServiceInvoices = false;
      }
    );
  }

  ViewGroupInvoices(item: GroupedInvoice) {
    this.invoiceGroupInView = item;
  }

  ReverseInvoice(item: Invoice) {
    this.confirmationService.confirm({
      message:
        "Are you sure you want to reverse this invoice, Kindly note that all postings tied to this invoice  will be reversed?",
      accept: () => {
        this.messageService.add({
          severity: "info",
          summary: "Notice",
          detail: "Reversingy Receipt......",
        });

        const postData: ReverseGroupInvoiceVM = {
          invoiceNumber: item.invoiceNumber,
          invoiceId: item.id,
        };

        this.invoiceService.ReverseGroupInvoice(postData).subscribe(
          async (res: CommonResponse) => {
            if (res.responseCode == "00") {
              this.messageService.add({
                severity: "success",
                summary: "Removed",
                detail: "Invoice Reversed successfully",
              });
              this.LoadContractServiceInvoices(this.contractServiceInView);
            } else {
              this.messageService.add({
                severity: "error",
                summary: "Failure",
                detail:
                  "Err: Could not reverse invoice. Reason -> " +
                  res.responseMsg,
              });
            }
          },
          (error) => {
            console.log("Error: " + JSON.stringify(error));
            this.messageService.add({
              severity: "error",
              summary: "Notice",
              detail:
                "Unable to reverse invoice at the moment.. Reason: [" +
                error.error.message +
                "]",
            });
          }
        );
      },
    });
  }
}
