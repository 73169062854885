import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { PilotRank } from "src/app/interfaces/armada";
import { CommonResponse } from "src/app/interfaces/home";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class PilotRankService {
  baseUrl = environment.baseUrl + "Pilot/";
  constructor(private httpClient: HttpClient) {}
  allPilotRank(): Observable<CommonResponse> {
    return this.httpClient.get<CommonResponse>(
      this.baseUrl + "GetAllPilotRanks"
    );
    //return this.http.get<Approval[]>(this.baseUrl + 'GetUserPendingApprovals');
  }
  getPilotRank(id: any): Observable<CommonResponse> {
    return this.httpClient.get<CommonResponse>(
      this.baseUrl + "GetRankById/" + id
    );
  }

  postPilotRank(data: any) {
    return this.httpClient.post(this.baseUrl + "AddNewRank", data);
  }
  delete(id: any) {
    return this.httpClient.delete(this.baseUrl + "DeleteRankById/" + id);
  }
  updatePilotRank(id: any, data: any) {
    return this.httpClient.put(this.baseUrl + "UpdateRankById/" + id, data);
  }
}
