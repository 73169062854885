import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../environments/environment";
import { Observable } from "rxjs";
import { ClientBeneficiary, CommonResponse } from "../interfaces/home";
import { BulkCreateBeneficiariesReceivingDTO } from "../interfaces/beneficiary";

@Injectable({
  providedIn: "root",
})
export class ClientBeneficiaryService {
  constructor(private http: HttpClient) {}
  baseUrl = environment.baseUrl + "ClientBeneficiary/";
  // fetch all ClientBeneficiary in the database
  allClientBeneficiaryData(): Observable<CommonResponse> {
    return this.http.get<CommonResponse>(this.baseUrl);
  }

  getClientBeneficiary(id: any): Observable<CommonResponse> {
    return this.http.get<CommonResponse>(this.baseUrl + id);
  }

  getClientBeneficiaryByCode(code: any): Observable<CommonResponse> {
    return this.http.get<CommonResponse>(this.baseUrl + "code/" + code);
  }

  postClientBeneficiary(data: any) {
    return this.http.post(this.baseUrl, data);
  }

  deleteClientBeneficiary(id: any) {
    return this.http.delete(this.baseUrl + id);
  }

  updateClientBeneficiary(id: any, data: any) {
    return this.http.put(this.baseUrl + id, data);
  }

  BulkCreateBeneficiaries(
    data: BulkCreateBeneficiariesReceivingDTO
  ): Observable<CommonResponse> {
    return this.http.post<CommonResponse>(
      this.baseUrl + "BulkCreateBeneficiaries",
      data
    );
  }

  GetClientsBeneficiary(
    clientId: number
  ): Observable<CommonResponse<ClientBeneficiary[]>> {
    return this.http.get<CommonResponse<ClientBeneficiary[]>>(
      this.baseUrl + "GetClientsBeneficiary/" + clientId
    );
  }
}
