import {
  BackgroundCheck,
  Cluster,
  DeployedGuard,
  GMAOperativeMaterialsAllocationReport,
  GMAPhysicalReport,
  GuardDeployment,
  OperativeGuarantor,
} from "./../../../interfaces/employment";
import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { MessageService, ConfirmationService, TreeNode } from "primeng/api";
import { BreadcrumbService } from "src/app/breadcrumb.service";
import { GmaEmploymentService } from "src/app/services/gma-employment.service";
import {
  BankEnum,
  BiometricCaptureAction,
  EducationLevelEnum,
  MaritalStatusEnum,
  NextOfKinRelationshipEnum,
} from "src/app/interfaces/premployment";

@Component({
  selector: "app-gma-operative-management",
  templateUrl: "./gma-operative-management.component.html",
  styleUrls: ["./gma-operative-management.component.scss"],
})
export class GmaOperativeManagementComponent implements OnInit {
  @ViewChild("formWrapper") public formWrapper: ElementRef;
  allOperatives: DeployedGuard[];
  theOperative: DeployedGuard;
  ditsTable: {
    name: string;
    data: string;
  }[];
  selectedDitTable: {
    name: string;
    data: string;
  };
  prevDeployedTable: {
    name: string;
    data: string;
  }[];
  selectedPrevDeployedTable: {
    name: string;
    data: string;
  };
  currentDeployedTable: {
    name: string;
    data: string;
  }[];
  selectedCurrentDeployedTable: {
    name: string;
    data: string;
  };
  deploymentTree: TreeNode[];
  selectedDeploymentNode: TreeNode;
  allClusters: Cluster[];
  selectedNodeData: GuardDeployment;
  applicantPhysicalReports: GMAPhysicalReport[];
  applicantCognitiveReports: GMAPhysicalReport[];
  applicantGuarantors: OperativeGuarantor[];
  applicantBackgroundCheck: BackgroundCheck;
  applicantMaterialsAllocation: GMAOperativeMaterialsAllocationReport[];
  applicantInViewBioData: boolean[];
  leftBioPercentage: number = 0;
  rightBioPercentage: number = 0;
  bioImgSrc: string = "assets/gma/PlaceFinger.bmp";
  allBiometricCaptures: {
    key: number;
    value: string;
  }[];
  theBiometricCapture: {
    key: number;
    value: string;
  };

  constructor(
    public employmentService: GmaEmploymentService,
    public messageService: MessageService,
    private breadcrumbService: BreadcrumbService,
    public confirmationService: ConfirmationService
  ) {}

  ngOnInit(): void {
    this.breadcrumbService.setItems([
      {
        label: "Guard Management",
        routerLink: ["/home/guard-management/set-up"],
      },
      {
        label: "Operative Management",
        routerLink: ["/home/guard-management/gma-operative-management"],
      },
    ]);

    this.ditsTable = [
      {
        name: "Zone",
        data: "Data",
      },
      {
        name: "Cluster",
        data: "Data",
      },
      {
        name: "Location",
        data: "Data",
      },
      {
        name: "Beat",
        data: "Data",
      },
    ];

    this.deploymentTree = [
      {
        label: "Halogen",
        children: [
          // {
          //   key: "0",
          //   label: "Documents",
          //   data: "Documents Folder",
          //   icon: "pi pi-fw pi-inbox",
          //   children: [
          //     {
          //       key: "0-0",
          //       label: "Work",
          //       data: "Work Folder",
          //       icon: "pi pi-fw pi-cog",
          //       children: [
          //         {
          //           key: "0-0-0",
          //           label: "Expenses.doc",
          //           icon: "pi pi-fw pi-file",
          //           data: "Expenses Document",
          //         },
          //         {
          //           key: "0-0-1",
          //           label: "Resume.doc",
          //           icon: "pi pi-fw pi-file",
          //           data: "Resume Document",
          //         },
          //       ],
          //     },
          //     {
          //       key: "0-1",
          //       label: "Home",
          //       data: "Home Folder",
          //       icon: "pi pi-fw pi-home",
          //       children: [
          //         {
          //           key: "0-1-0",
          //           label: "Invoices.txt",
          //           icon: "pi pi-fw pi-file",
          //           data: "Invoices for this month",
          //         },
          //       ],
          //     },
          //   ],
          // },
        ],
      },
    ];

    this.prevDeployedTable = [
      {
        name: "Zone",
        data: "Data",
      },
      {
        name: "Cluster",
        data: "Data",
      },
      {
        name: "Client",
        data: "Data",
      },
      {
        name: "Location",
        data: "Data",
      },
      {
        name: "Beat",
        data: "Data",
      },
      {
        name: "Deployment Date",
        data: "Data",
      },
    ];

    this.currentDeployedTable = [
      {
        name: "Zone",
        data: "Data",
      },
      {
        name: "Cluster",
        data: "Data",
      },
      {
        name: "Client",
        data: "Data",
      },
      {
        name: "Location",
        data: "Data",
      },
      {
        name: "Beat",
        data: "Data",
      },
      {
        name: "Deployment Date",
        data: "Data",
      },
    ];

    this.applicantInViewBioData = [
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
    ];

    this.allBiometricCaptures = [
      {
        key: BiometricCaptureAction.Left_Pinky,
        value: "Left Pinky",
      },
      {
        key: BiometricCaptureAction.Left_RingFinger,
        value: "Left Ring Finger",
      },
      {
        key: BiometricCaptureAction.Left_MiddleFinger,
        value: "Left Middle Finger",
      },
      {
        key: BiometricCaptureAction.Left_IndexFinger,
        value: "Left Index Finger",
      },
      {
        key: BiometricCaptureAction.Left_Thumb,
        value: "Left Thumb",
      },
      {
        key: BiometricCaptureAction.Right_Thumb,
        value: "Right Thumb",
      },
      {
        key: BiometricCaptureAction.Right_IndexFinger,
        value: "Right Index Finger",
      },
      {
        key: BiometricCaptureAction.Right_MiddleFinger,
        value: "Right Middle Finger",
      },
      {
        key: BiometricCaptureAction.Right_RingFinger,
        value: "Right Ring Finger",
      },
      {
        key: BiometricCaptureAction.Right_Pinky,
        value: "Right Pinky",
      },
    ];

    this.FetchAllOperatives();
    this.FetchAllClusters();
  }

  FetchAllOperatives() {
    this.employmentService.GetAllOperativesForMG().subscribe(
      async (data) => {
        this.allOperatives = data.deployedGuards;
        // this.allClusters = data.clusters;
        this.allOperatives.forEach((guard) => {
          guard.jobApplication.personalInformation.fullname =
            guard.jobApplication.personalInformation.lastname +
            " " +
            guard.jobApplication.personalInformation.firstname;
        });
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to get all operatives at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  FetchAllClusters() {
    this.employmentService.GetAllClustersWithZoneMG().subscribe(
      async (data) => {
        this.allClusters = data;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to get all clusters at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  async LoadOperativeData() {
    if (this.theOperative) {
      if (this.theOperative.guardDeployments) {
        this.employmentService
          .GetAllOperativesDeploymentsForMG(this.theOperative.id)
          .subscribe(
            async (data) => {
              this.theOperative.guardDeployments = data;
              this._loadOPDataExists();
              this._loadOPData();
            },
            (error) => {
              console.log("Error: " + JSON.stringify(error));
              this.messageService.add({
                severity: "error",
                summary: "Notice",
                detail:
                  "Unable to get operative deployments at the moment.. Reason: [" +
                  error
                    ? error.error.message
                    : "request failed - permission" + "]",
              });
            }
          );
      } else {
        this._loadOPDataExists();
        this._loadOPData();
      }

      this.GetApplicantRecruitmentReport(this.theOperative.jobApplicationId);
      this.BiometricsViewHelper(
        this.theOperative.jobApplication.applicantEvaluationId
      );
      this.GetApplicantMaterialsAllocationReport(
        this.theOperative.jobApplicationId
      );
    } else {
      this._loadOPData();
    }
  }

  _loadOPDataExists() {
    if (this.theOperative) {
      let currentDeployment = this.theOperative.guardDeployments.find(
        (x) => x.isDeleted == false
      );

      if (currentDeployment) {
        if (currentDeployment.location) {
          let currentCluster = currentDeployment
            ? this.allClusters.find(
                (x) => x.id == currentDeployment.location.clusterId
              )
            : null;

          this.ditsTable[0].data = currentCluster
            ? currentCluster.zone.caption
            : "Pool";
          this.ditsTable[1].data = currentCluster
            ? currentCluster.caption
            : "Pool";
          this.ditsTable[2].data = currentDeployment
            ? currentDeployment.location.name
            : "Pool";
          this.ditsTable[3].data = "N/A";
        } else {
          this.ditsTable[0].data = "Pool";
          this.ditsTable[1].data = "Pool";
          this.ditsTable[2].data = "Pool";
          this.ditsTable[3].data = "Pool";
        }
      }

      this.GetOperativeDetails(this.theOperative.jobApplicationId);
      this.LoadCompleteApplicantDetails();
    }
  }

  _loadOPData() {
    this.deploymentTree = [
      {
        label:
          this.theOperative.jobApplication.personalInformation.fullname +
          " Deployment History Tree",
        children: [
          // {
          //   key: "0",
          //   label: "Pool",
          //   data: null,
          //   icon: "pi pi-fw pi-inbox",
          //   selectable: false,
          // },
        ],
        selectable: false,
      },
    ];

    let countOP = 0;
    this.theOperative.guardDeployments.forEach((deployment) => {
      this.deploymentTree[0].children.push({
        key: "0-" + countOP,
        label: deployment.location ? deployment.location.name : "Pool",
        data: deployment,
        icon: "pi pi-fw pi-inbox",
        selectable: true,
      });

      countOP++;
    });

    this.ReverseDeploymentTree();
  }

  ReverseDeploymentTree() {
    let deploymentTreeChildern = this.deploymentTree[0].children;
    this.deploymentTree[0].children = [];
    for (let i = deploymentTreeChildern.length; i > 0; i--) {
      this.deploymentTree[0].children.push(deploymentTreeChildern[i - 1]);
    }
  }

  GetMaritalStatusValue(marritalStat: number): string {
    if (marritalStat == MaritalStatusEnum.Divorced) return "Divorced";
    if (marritalStat == MaritalStatusEnum.Married) return "Married";
    if (marritalStat == MaritalStatusEnum.Single) return "Single";
    if (marritalStat == MaritalStatusEnum.Widowed) return "Widowed";
    return "Nil";
  }

  GetNextOfKinRelationshipValue(relationship: number): string {
    if (relationship == NextOfKinRelationshipEnum.Brother) return "Brother";
    if (relationship == NextOfKinRelationshipEnum.Daughter) return "Daughter";
    if (relationship == NextOfKinRelationshipEnum.Father) return "Father";
    if (relationship == NextOfKinRelationshipEnum.Mother) return "Mother";
    if (relationship == NextOfKinRelationshipEnum.Sister) return "Sister";
    if (relationship == NextOfKinRelationshipEnum.Son) return "Son";
    return "Nil";
  }

  GetEducationalLevel(level: number): string {
    if (level == EducationLevelEnum.PostGraduate) return "PostGraduate";
    if (level == EducationLevelEnum.Primary) return "Primary";
    if (level == EducationLevelEnum.Secondary) return "Secondary";
    if (level == EducationLevelEnum.Tertiary) return "Tertiary";
    return "Nil";
  }

  GetBankValue(bank: number): string {
    if (bank == BankEnum.FCMB) return "FCMB";
    if (bank == BankEnum.Firstbank) return "Firstbank";
    if (bank == BankEnum.GTBank) return "GTBank";
    if (bank == BankEnum.KeystoneBank) return "KeystoneBank";
    if (bank == BankEnum.KudaBank) return "KudaBank";
    if (bank == BankEnum.PolarisBank) return "PolarisBank";
    if (bank == BankEnum.ProvidusBank) return "ProvidusBank";
    if (bank == BankEnum.SterlingBank) return "SterlingBank";
    if (bank == BankEnum.WemaBank) return "WemaBank";
    if (bank == BankEnum.ZenithBank) return "ZenithBank";
    return "Nil";
  }

  LoadCompleteApplicantDetails() {
    this.employmentService
      .GetCompleteApplicantDetails(this.theOperative.jobApplicationId)
      .subscribe(
        async (data) => {
          this.theOperative.jobApplication.backgroundInformation =
            data.backgroundInformation;
          this.theOperative.jobApplication.nextOfKinInformations =
            data.nextOfKinInformations;
          this.theOperative.jobApplication.educationalInformations =
            data.educationalInformations;
          this.theOperative.jobApplication.prevEmployerInformations =
            data.prevEmployerInformations;
          this.theOperative.jobApplication.additionalInformation =
            data.medicalAccountInformation;
          this.theOperative.jobApplication.medicalAccountInformation =
            data.additionalInformation;
        },
        (error) => {
          console.log("Error: " + JSON.stringify(error));
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail:
              "Unable to load complete applicant details at the moment.. Reason: [" +
              error
                ? error.error.message
                : "request failed - permission" + "]",
          });
        }
      );
  }

  GetOperativeDetails(jobApplicationId: number) {
    this.employmentService.GetOperativeDetailsMG(jobApplicationId).subscribe(
      async (data) => {
        if (this.theOperative) {
          this.theOperative.operativeCode = data.operativeCode;
          this.theOperative.trainingSchoolCode = data.trainingBatchCode;
        }
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to get all operatives at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  async ShowRedeploymentInfo(event) {
    if (event.node.data) {
      if (this.selectedNodeData !== event.node.data) {
        this.selectedNodeData = event.node.data;
      }

      if (this.selectedNodeData.location) {
        let currentCluster = this.allClusters.find(
          (x) => x.id == this.selectedNodeData.location.clusterId
        );
        this.currentDeployedTable[0].data = currentCluster
          ? currentCluster.zone.caption
          : "Pool";
        this.currentDeployedTable[1].data = currentCluster
          ? currentCluster.caption
          : "Pool";
        this.currentDeployedTable[2].data = "Client N/A";
        this.currentDeployedTable[3].data = this.selectedNodeData
          ? this.selectedNodeData.location.name
          : "Pool";
        this.currentDeployedTable[4].data = "N/A";
      } else {
        this.currentDeployedTable[0].data = "Pool";
        this.currentDeployedTable[1].data = "Pool";
        this.currentDeployedTable[2].data = "Pool";
        this.currentDeployedTable[3].data = "Pool";
        this.currentDeployedTable[4].data = "Pool";
      }
      this.currentDeployedTable[5].data = this.selectedNodeData.createdAt
        .toString()
        .substring(0, 10);

      let prevGuardDeployment: GuardDeployment = null;
      let index = this.theOperative.guardDeployments.indexOf(
        this.selectedNodeData
      );
      if (index > 0) {
        prevGuardDeployment = this.theOperative.guardDeployments[index - 1];
      }

      if (prevGuardDeployment) {
        if (prevGuardDeployment.location) {
          let currentCluster = this.allClusters.find(
            (x) => x.id == prevGuardDeployment.location.clusterId
          );
          this.prevDeployedTable[0].data = currentCluster
            ? currentCluster.zone.caption
            : "Pool";
          this.prevDeployedTable[1].data = currentCluster
            ? currentCluster.caption
            : "Pool";
          this.prevDeployedTable[2].data = "Client N/A";
          this.prevDeployedTable[3].data = prevGuardDeployment
            ? prevGuardDeployment.location.name
            : "Pool";
          this.prevDeployedTable[4].data = "N/A";
          this.prevDeployedTable[5].data = "N/A";
        } else {
          this.prevDeployedTable[0].data = "Pool";
          this.prevDeployedTable[1].data = "Pool";
          this.prevDeployedTable[2].data = "Pool";
          this.prevDeployedTable[3].data = "Pool";
          this.prevDeployedTable[4].data = "Pool";
          this.prevDeployedTable[5].data = prevGuardDeployment.createdAt
            .toString()
            .substring(0, 10);
        }
      } else {
        this.prevDeployedTable[0].data = "Pool";
        this.prevDeployedTable[1].data = "Pool";
        this.prevDeployedTable[2].data = "Pool";
        this.prevDeployedTable[3].data = "Pool";
        this.prevDeployedTable[4].data = "Pool";
        this.prevDeployedTable[5].data = "Pool";
      }
    }
  }

  async GetApplicantRecruitmentReport(jobApplicationId: number) {
    this.employmentService
      .GetApplicantRecruitmentReport(jobApplicationId)
      .subscribe(
        async (data) => {
          this.applicantPhysicalReports = data.physicalReports;
          this.applicantCognitiveReports = data.cognitiveReports;
          this.applicantGuarantors = data.guarantors;
          this.applicantBackgroundCheck = data.backgroundCheck;
        },
        (error) => {
          console.log("Error: " + JSON.stringify(error));
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail:
              "Unable to get applicant recruitment report at the moment.. Reason: [" +
              error
                ? error.error.message
                : "request failed - permission" + "]",
          });
        }
      );
  }

  async BiometricsViewHelper(applicantEvaluationId: number) {
    this.applicantInViewBioData[0] = false;
    this.applicantInViewBioData[1] = false;
    this.applicantInViewBioData[2] = false;
    this.applicantInViewBioData[3] = false;
    this.applicantInViewBioData[4] = false;
    this.applicantInViewBioData[5] = false;
    this.applicantInViewBioData[6] = false;
    this.applicantInViewBioData[7] = false;
    this.applicantInViewBioData[8] = false;
    this.applicantInViewBioData[9] = false;
    this.leftBioPercentage = 0;
    this.rightBioPercentage = 0;
    this.employmentService.GetApplicantBioInfo(applicantEvaluationId).subscribe(
      async (data) => {
        data.forEach((bio) => {
          if (bio.thumbprint == BiometricCaptureAction.Left_Pinky) {
            this.applicantInViewBioData[0] = true;
            this.leftBioPercentage = this.leftBioPercentage + 20;
          } else if (bio.thumbprint == BiometricCaptureAction.Left_RingFinger) {
            this.leftBioPercentage = this.leftBioPercentage + 20;
            this.applicantInViewBioData[1] = true;
          } else if (
            bio.thumbprint == BiometricCaptureAction.Left_MiddleFinger
          ) {
            this.leftBioPercentage = this.leftBioPercentage + 20;
            this.applicantInViewBioData[2] = true;
          } else if (
            bio.thumbprint == BiometricCaptureAction.Left_IndexFinger
          ) {
            this.leftBioPercentage = this.leftBioPercentage + 20;
            this.applicantInViewBioData[3] = true;
          } else if (bio.thumbprint == BiometricCaptureAction.Left_Thumb) {
            this.leftBioPercentage = this.leftBioPercentage + 20;
            this.applicantInViewBioData[4] = true;
          } else if (bio.thumbprint == BiometricCaptureAction.Right_Thumb) {
            this.rightBioPercentage = this.rightBioPercentage + 20;
            this.applicantInViewBioData[5] = true;
          } else if (
            bio.thumbprint == BiometricCaptureAction.Right_IndexFinger
          ) {
            this.rightBioPercentage = this.rightBioPercentage + 20;
            this.applicantInViewBioData[6] = true;
          } else if (
            bio.thumbprint == BiometricCaptureAction.Right_MiddleFinger
          ) {
            this.rightBioPercentage = this.rightBioPercentage + 20;
            this.applicantInViewBioData[7] = true;
          } else if (
            bio.thumbprint == BiometricCaptureAction.Right_RingFinger
          ) {
            this.rightBioPercentage = this.rightBioPercentage + 20;
            this.applicantInViewBioData[8] = true;
          } else if (bio.thumbprint == BiometricCaptureAction.Right_Pinky) {
            this.rightBioPercentage = this.rightBioPercentage + 20;
            this.applicantInViewBioData[9] = true;
          }
        });

        if (this.leftBioPercentage > 100) this.leftBioPercentage = 100;
        if (this.rightBioPercentage > 100) this.rightBioPercentage = 100;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to connect fetch applicant's bio data at the moment.. Reason: [" +
            JSON.stringify(error) +
            "]",
        });
      }
    );
  }

  async GetApplicantMaterialsAllocationReport(jobApplicationId: number) {
    this.employmentService
      .GetApplicantMaterialsAllocation(jobApplicationId)
      .subscribe(
        async (data) => {
          this.applicantMaterialsAllocation = data;
        },
        (error) => {
          console.log("Error: " + JSON.stringify(error));
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail:
              "Unable to get applicant recruitment report at the moment.. Reason: [" +
              error
                ? error.error.message
                : "request failed - permission" + "]",
          });
        }
      );
  }
}
