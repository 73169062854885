import {
  PayGrade,
  Allowance,
  PayGradeAllowance,
  Deduction,
  PayGradeDeductionApplicableAllowance,
  PayGradeDeduction,
  Tax,
  CreatePayGradeVM,
  UpdatePayGradeVM,
  PayGradeOptionEnum,
  TaxRelief,
  ComputeTaxRequestVM,
  CreatePaygradePensionConfigVM,
} from "./../../../interfaces/payroll";
import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ConfirmationService, Message, MessageService } from "primeng/api";
import { BreadcrumbService } from "src/app/breadcrumb.service";
import { PayrollService } from "src/app/services/payroll.service";
import { Account } from "./../../../interfaces/home";

@Component({
  selector: "app-payroll-pay-grade",
  templateUrl: "./payroll-pay-grade.component.html",
  styleUrls: ["./payroll-pay-grade.component.scss"],
  providers: [MessageService],
})
export class PayrollPayGradeComponent implements OnInit {
  @ViewChild("formWrapper") public formWrapper: ElementRef;
  payGradeForm: FormGroup;
  fetchingPayGrades: boolean = true;
  payGradeCols: any[];
  allPayGrades: PayGrade[];
  selectedPayGrade: PayGrade;
  isNoOfDaysRadioButton: number;
  editingPayGrade: boolean = false;
  allowanceMsg: Message[] = [];
  taxMsg: Message[] = [];
  allAllowances: Allowance[];
  theAllowance: Allowance;
  perGross: number;
  allPaygradeAllowances: PayGradeAllowance[] = [];
  selectedPaygradeAllowance: PayGradeAllowance;
  allowanceCols: any[];
  deductibleCols: any[];
  allDeductibles: Deduction[];
  theDeductible: Deduction;
  isDeductibleOption: number;
  isPensionApplicableOption: number;
  defaultAllowanceValue: number;
  allSelectedApplicableAllowance: Allowance[] = [];
  allSelectedPensionAllowance: Allowance[] = [];
  deductionFlatFee: number;
  pensionFlatFee: number;
  deductionPercFee: number;
  pensionPercFee: number;
  deductionCalcValue: number;
  pensionCalcValue: number;
  allPaygradeDeductibles: PayGradeDeduction[] = [];
  selectedPaygradeDeductible: PayGradeDeduction;
  allTaxes: Tax[];
  theTax: Tax;
  taxFlatFigure: string;
  taxPercGross: string;
  taxHigherValue: string;
  taxXPercGross: string;
  allTaxReliefItems: {
    name: string;
    data: string;
  }[];
  selectedTaxReliefItem: {
    name: string;
    data: string;
  };
  allTaxComputations: {
    sequence: number;
    nextValue: string;
    appliedRate: number;
    taxamount: string;
  }[];
  selectedTaxComputation: {
    sequence: number;
    nextValue: number;
    appliedRate: number;
    taxamount: number;
  };
  payGradeToEdit: PayGrade;
  taxPercToApply: number;
  taxPercToApply2: number;
  totalTaxRelief: string;
  taxableIncome: string;
  calculatedAnnualTax: string;
  totalAnnualTax: string;
  minAnnualTax: string;
  pensionApplicabilityOption: {
    key: number;
    value: string;
  }[];
  thePensionApplicableOpt: {
    key: number;
    value: string;
  };
  isPensionConfigured: boolean = false;
  allCRAccounts: Account[];
  allDRAccounts: Account[];
  thePensionCRAccount: Account;
  thePensionDRAccount: Account;
  pensionConifguration: CreatePaygradePensionConfigVM;

  constructor(
    private fb: FormBuilder,
    private payrollService: PayrollService,
    private breadcrumbService: BreadcrumbService,
    public confirmationService: ConfirmationService,
    public messageService: MessageService
  ) {
    this.payGradeForm = fb.group({
      Caption: ["", Validators.required],
      Description: ["", Validators.required],
      AnnualGross: ["", Validators.required],
      NoDaysCalendar: ["", Validators.required],
      DefaultValue: [""],
    });
  }

  ngOnInit(): void {
    this.breadcrumbService.setItems([
      {
        label: "Payroll Management",
        routerLink: ["/home/payroll/payroll-settings"],
      },
      {
        label: "Pay Grade",
        routerLink: ["/home/payroll/pay-grade"],
      },
    ]);

    this.payGradeCols = [
      { field: "caption", header: "Caption" },
      { field: "description", header: "Description" },
      { field: "defaultAnnualGross", header: "DefaultAnnualGross" },
      { field: "defaultValue", header: "DefaultValue" },
    ];

    this.allowanceCols = [
      { field: "allowance.caption", header: "allowance.caption" },
      { field: "defaultValue", header: "DefaultValue" },
      { field: "percentageOfGross", header: "PercentageOfGross" },
    ];

    this.deductibleCols = [
      { field: "deduction.caption", header: "Deduction.caption" },
      { field: "flatFee", header: "FlatFee" },
      { field: "percentageApplicable", header: "PercentageApplicable" },
    ];

    this.pensionApplicabilityOption = [
      {
        key: 1,
        value: "Yes",
      },
      {
        key: 0,
        value: "No",
      },
    ];

    this.ResetMessageToasters();
    this.FetchAllPayGrades();
    this.FetchAllAllowances();
    this.FetchAllDeductions();
    this.FetchAllTaxes();
    this.FetchAllFilteredAccounts();
  }

  ResetMessageToasters() {
    this.allowanceMsg = [];
    this.allowanceMsg.push({
      severity: "info",
      summary: "Notice:",
      detail: "All figures captured should be annual figures.",
    });

    this.taxMsg = [];
    this.taxMsg.push({
      severity: "info",
      summary: "Notice:",
      detail: "All figures captured should be annual figures.",
    });
  }

  FetchAllPayGrades() {
    this.payrollService.GetAllPayGrades().subscribe(
      async (data) => {
        this.allPayGrades = data;
        this.fetchingPayGrades = false;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable get all paygrades at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
        this.fetchingPayGrades = false;
        this.ResetMessageToasters();
      }
    );
  }

  FetchAllAllowances() {
    this.payrollService.GetAllAllowances().subscribe(
      async (data) => {
        this.allAllowances = data;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable get all allowances at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
        this.ResetMessageToasters();
      }
    );
  }

  FetchAllDeductions() {
    this.payrollService.GetAllDeductions().subscribe(
      async (data) => {
        this.allDeductibles = data;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable get all deductions at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
        this.ResetMessageToasters();
      }
    );
  }

  FetchAllTaxes() {
    this.payrollService.GetAllTaxes().subscribe(
      async (data) => {
        this.allTaxes = data;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable get all taxes at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
        this.ResetMessageToasters();
      }
    );
  }

  FetchAllFilteredAccounts() {
    this.payrollService.GetFilteredAccountList().subscribe(
      async (data) => {
        data.crAccounts.forEach((acct) => {
          acct.label = acct.accountNumber + " - " + acct.name;
        });
        data.drAccounts.forEach((acct) => {
          acct.label = acct.accountNumber + " - " + acct.name;
        });

        this.allCRAccounts = data.crAccounts;
        this.allDRAccounts = data.drAccounts;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable get all filtered accounts at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
        this.ResetMessageToasters();
      }
    );
  }

  CreatePayGrade() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Creating Pay Grade...",
    });
    this.ResetMessageToasters();

    const postData: CreatePayGradeVM = {
      caption: this.payGradeForm.get("Caption").value,
      description: this.payGradeForm.get("Description").value,
      defaultAnnualGross: this.payGradeForm.get("AnnualGross").value,
      defaultValue: this.payGradeForm.get("DefaultValue").value,
      payGradeNoOfDaysToApply: this.isNoOfDaysRadioButton,
      allowances: [],
      deductions: [],
      taxId: this.theTax.id,
      pensionConfig: this.pensionConifguration,
    };

    if (this.isNoOfDaysRadioButton == 2 && !postData.defaultValue) {
      this.messageService.add({
        severity: "error",
        summary: "Notice",
        detail:
          "You must input a default value if number of days to apply is set to default",
      });
      this.ResetMessageToasters();
      return;
    } else if (this.isNoOfDaysRadioButton == 1) postData.defaultValue = null;

    if (postData.defaultValue) {
      if (postData.defaultValue > 31) {
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail: "Default Value (In Days) cannot be greater than 31",
        });
        this.ResetMessageToasters();
        return;
      }
    }

    if (this.pensionConifguration == null) {
      this.messageService.add({
        severity: "error",
        summary: "Notice",
        detail: "Pension Configuration is required",
      });
      this.ResetMessageToasters();
      return;
    }

    this.allPaygradeAllowances.forEach((allowance) => {
      postData.allowances.push({
        allowanceId: allowance.allowanceId,
        grossPercentage: allowance.percentageOfGross,
        defaultValue: allowance.defaultValue,
      });
    });
    this.allPaygradeDeductibles.forEach((deduction) => {
      postData.deductions.push({
        deductionId: deduction.deductionId,
        option: deduction.payGradeOption,
        flatFee: deduction.flatFee,
        applicableAllowanceIds: deduction.applicableAllowances.map(
          (x) => x.allowanceId
        ),
        calculatedValue: deduction.calculatedValue,
        percentageApplicable: deduction.percentageApplicable,
      });
    });

    //Add Check that default annual gross must equals to total allowances
    let defAnnualGross: number = postData.defaultAnnualGross;
    let totAllowance: number = 0;
    postData.allowances.forEach((x) => {
      if (x.defaultValue) {
        totAllowance = totAllowance + x.defaultValue;
      } else {
        let calc = (x.grossPercentage / 100) * defAnnualGross;
        totAllowance = totAllowance + calc;
      }
    });
    if (totAllowance != defAnnualGross) {
      this.messageService.add({
        severity: "error",
        summary: "Notice",
        detail:
          "Default Annual Gross Must Be Equals to the total sum of all the allowances",
      });
      this.ResetMessageToasters();
      return;
    }

    this.payrollService.CreatePayGrade(postData).subscribe(
      async () => {
        this.messageService.add({
          severity: "success",
          summary: "Completed",
          detail: "PayGrade Created Successfully!",
        });

        this.ResetMessageToasters();
        this.payGradeForm.reset();
        this.isNoOfDaysRadioButton = null;
        this.theTax = null;
        this.allPaygradeAllowances = [];
        this.allPaygradeDeductibles = [];
        this.fetchingPayGrades = true;
        this.isPensionConfigured = false;
        this.CloseEditingPayGrade();
        this.FetchAllPayGrades();
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to create paygrade at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
        this.ResetMessageToasters();
      }
    );
  }

  EditPayGrade(item: PayGrade) {
    this.editingPayGrade = true;
    this.payGradeForm.patchValue({
      Caption: item.caption,
      Description: item.description,
      AnnualGross: item.defaultAnnualGross,
      DefaultValue: item.defaultValue,
    });
    this.isNoOfDaysRadioButton = item.noOfDaysToApply;
    this.allPaygradeAllowances = item.allowances.filter(
      (x) => x.isDeleted == false
    );
    this.allPaygradeDeductibles = item.deductions.filter(
      (x) => x.isDeleted == false
    );
    this.allSelectedApplicableAllowance = [];
    this.theTax = this.allTaxes.find((x) => x.id == item.taxId);
    this.TaxSelectionLoader();
    this.payGradeToEdit = item;
    this.isPensionConfigured = false;

    if (item.pensionConfig) {
      this.thePensionApplicableOpt = this.pensionApplicabilityOption.find((x) =>
        item.pensionConfig.isApplicable == true ? x.key == 1 : x.key == 0
      );
      this.thePensionCRAccount = this.allCRAccounts.find(
        (x) => x.id == item.pensionConfig.creditAccountId
      );
      this.thePensionDRAccount = this.allDRAccounts.find(
        (x) => x.id == item.pensionConfig.debitAccountId
      );
      this.isPensionApplicableOption = item.pensionConfig.pensionPlanOption;
      this.pensionFlatFee = item.pensionConfig.flatFee;
      this.pensionPercFee = item.pensionConfig.percentageApplicable;
      this.pensionCalcValue = item.pensionConfig.calculatedValue;

      let appicablePensAllowances: string[];
      if (item.pensionConfig.applicableAllowanceIds)
        appicablePensAllowances =
          item.pensionConfig.applicableAllowanceIds.split(";");

      this.allSelectedPensionAllowance = [];
      appicablePensAllowances.forEach((selectedAllowance) => {
        if (selectedAllowance) {
          let selected = this.allAllowances.find(
            (x) => x.id == +selectedAllowance
          );
          if (selected) this.allSelectedPensionAllowance.push(selected);
        }
      });
    }

    this.formWrapper.nativeElement.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "start",
    });
  }

  DeletePayGrade(item: PayGrade) {
    this.confirmationService.confirm({
      message: "Are you sure you want to remove paygrade?",
      accept: () => {
        this.messageService.add({
          severity: "info",
          summary: "Notice",
          detail: "Removing paygrade...",
        });
        this.ResetMessageToasters();

        this.payrollService.DeletePayGrade(item.id).subscribe(
          async () => {
            this.messageService.add({
              severity: "success",
              summary: "Removed",
              detail: "Removed successfully",
            });
            this.ResetMessageToasters();

            this.fetchingPayGrades = true;
            const index = this.allPayGrades.indexOf(item);
            if (index > -1) {
              this.allPayGrades.splice(index, 1);
            }
            this.fetchingPayGrades = false;

            this.CloseEditingPayGrade();
          },
          (error) => {
            console.log("Error: " + JSON.stringify(error));
            this.messageService.add({
              severity: "error",
              summary: "Notice",
              detail:
                "Unable to remove paygrade at the moment.. Reason: [" + error
                  ? error.error.message
                  : "request failed - permission" + "]",
            });
            this.ResetMessageToasters();
          }
        );
      },
    });
  }

  CloseEditingPayGrade() {
    this.payGradeForm.reset();
    this.theTax = null;
    this.editingPayGrade = false;
    this.isNoOfDaysRadioButton = null;
    this.payGradeToEdit = null;
    this.allPaygradeAllowances = [];
    this.allPaygradeDeductibles = [];
    this.allSelectedPensionAllowance = [];
    this.thePensionApplicableOpt = null;
    this.thePensionCRAccount = null;
    this.thePensionDRAccount = null;
    this.isPensionConfigured = false;
    this.isPensionApplicableOption = null;
    this.pensionFlatFee = null;
    this.pensionPercFee = null;
  }

  UpdatePayGrade() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Updating Paygrade...",
    });
    this.ResetMessageToasters();

    const id = this.payGradeToEdit.id;
    const postData: UpdatePayGradeVM = {
      caption: this.payGradeForm.get("Caption").value,
      description: this.payGradeForm.get("Description").value,
      defaultAnnualGross: this.payGradeForm.get("AnnualGross").value,
      defaultValue: this.payGradeForm.get("DefaultValue").value,
      payGradeNoOfDaysToApply: this.isNoOfDaysRadioButton,
      allowances: [],
      deductions: [],
      taxId: this.theTax.id,
      pensionConfig: this.pensionConifguration,
    };

    if (postData.defaultValue) {
      if (postData.defaultValue > 31) {
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail: "Default Value (In Days) cannot be greater than 31",
        });
        this.ResetMessageToasters();
        return;
      }
    }

    if (this.pensionConifguration == null) {
      this.messageService.add({
        severity: "error",
        summary: "Notice",
        detail: "Pension Configuration is required",
      });
      this.ResetMessageToasters();
      return;
    }

    this.allPaygradeAllowances.forEach((allowance) => {
      postData.allowances.push({
        id: allowance.id ? allowance.id : 0,
        allowanceId: allowance.allowanceId,
        grossPercentage: allowance.percentageOfGross,
        defaultValue: allowance.defaultValue,
      });
    });
    this.allPaygradeDeductibles.forEach((deduction) => {
      postData.deductions.push({
        id: deduction.id ? deduction.id : 0,
        deductionId: deduction.deductionId,
        option: deduction.payGradeOption,
        flatFee: deduction.flatFee,
        applicableAllowanceIds: deduction.applicableAllowances.map(
          (x) => x.allowanceId
        ),
        calculatedValue: deduction.calculatedValue,
        percentageApplicable: deduction.percentageApplicable,
      });
    });

    //Add Check that default annual gross must equals to total allowances
    let defAnnualGross: number = postData.defaultAnnualGross;
    let totAllowance: number = 0;
    postData.allowances.forEach((x) => {
      if (x.defaultValue) {
        totAllowance = totAllowance + x.defaultValue;
      } else {
        let calc = (x.grossPercentage / 100) * defAnnualGross;
        totAllowance = totAllowance + calc;
      }
    });
    if (totAllowance != defAnnualGross) {
      this.messageService.add({
        severity: "error",
        summary: "Notice",
        detail:
          "Default Annual Gross Must Be Equals to the total sum of all the allowances",
      });
      this.ResetMessageToasters();
      return;
    }

    this.payrollService.UpdatePayGrade(id, postData).subscribe(
      async () => {
        this.messageService.add({
          severity: "success",
          summary: "Completed",
          detail: "PayGrade Updated Successfully.",
        });
        this.ResetMessageToasters();

        this.payGradeForm.reset();
        this.theTax = null;
        this.isNoOfDaysRadioButton = null;
        this.editingPayGrade = false;
        this.fetchingPayGrades = true;
        this.allPaygradeAllowances = [];
        this.allPaygradeDeductibles = [];
        this.payGradeToEdit = null;
        this.isPensionConfigured = false;
        this.CloseEditingPayGrade();
        this.FetchAllPayGrades();
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to update paygrade at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
        this.ResetMessageToasters();
      }
    );
  }

  AllowanceSelectionLoader() {}

  DeductibleSelectionLoader() {}

  DeletePaygradeAllowance(item: PayGradeAllowance) {
    const index = this.allPaygradeAllowances.indexOf(item);
    if (index > -1) {
      this.allPaygradeAllowances.splice(index, 1);
    }
  }

  DeletePaygradeDeductible(item: PayGradeDeduction) {
    const index = this.allPaygradeDeductibles.indexOf(item);
    if (index > -1) {
      this.allPaygradeDeductibles.splice(index, 1);
    }
  }

  TaxSelectionLoader() {
    let defaultAnnualGross = +this.payGradeForm.get("AnnualGross").value;
    if (this.theTax && defaultAnnualGross) {
      const postData: ComputeTaxRequestVM = {
        taxId: this.theTax.id,
        annualGrossAmount: defaultAnnualGross,
        taxExemptDeductions: [],
      };
      this.allPaygradeDeductibles
        .filter((x) => x.deduction.isTaxRelief == true)
        .forEach((deductible) => {
          postData.taxExemptDeductions.push({
            deductionName: deductible.deduction.caption,
            deductionId: deductible.deductionId,
            option: deductible.payGradeOption,
            flatFee: deductible.flatFee,
            applicableAllowanceIds: deductible.applicableAllowances.map(
              (x) => x.allowanceId
            ),
            percentageApplicable: deductible.percentageApplicable,
            calculatedValue: deductible.calculatedValue,
          });
        });
      this.payrollService.ComputeTax(postData).subscribe(
        async (data) => {
          this.taxFlatFigure = data.consolidatedFlatFigure.toLocaleString();
          this.taxPercToApply = data.consolidatedPercentage;
          this.taxPercGross =
            data.consolidatedPercentageAmount.toLocaleString();
          this.taxHigherValue = data.consolidatedHigherValue.toLocaleString();
          this.taxPercToApply2 = data.consolidatedToAddPercentage;
          this.taxXPercGross = data.consolidatedToAddAmount.toLocaleString();
          this.allTaxReliefItems = [];
          data.reliefItems.forEach((x) => {
            this.allTaxReliefItems.push({
              name: x.label,
              data: "N" + x.amount.toLocaleString() + "",
            });
          });
          this.totalTaxRelief = data.totalRelief.toLocaleString();
          this.taxableIncome = data.taxableIncome.toLocaleString();
          this.allTaxComputations = [];
          data.taxBands.forEach((x) => {
            this.allTaxComputations.push({
              sequence: x.count,
              nextValue: x.amount.toLocaleString(),
              appliedRate: x.taxRateApplied,
              taxamount: x.taxAmount.toLocaleString(),
            });
          });
          this.calculatedAnnualTax = data.actualAnnualTax.toLocaleString();
          this.minAnnualTax = data.minimumTaxAmount.toLocaleString();
          this.totalAnnualTax = data.totalAnnualTax.toLocaleString();
        },
        (error) => {
          console.log("Error: " + JSON.stringify(error));
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail:
              "Unable get tax computations at the moment.. Reason: [" + error
                ? error.error.message
                : "request failed - permission" + "]",
          });
          this.ResetMessageToasters();
        }
      );
    } else {
      this.taxFlatFigure = null;
      this.taxPercGross = null;
      this.taxPercToApply = null;
      this.taxHigherValue = null;
      this.taxPercToApply2 = null;
      this.taxXPercGross = null;
      this.taxableIncome = null;
      this.totalTaxRelief = null;
      this.allTaxReliefItems = [];

      if (!defaultAnnualGross && this.theTax) {
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail: "Kindly input Default annual gross before selecting tax",
        });
        this.ResetMessageToasters();
        this.theTax = null;
      }
    }
  }

  CalculateTotalDeductions(item: PayGrade): number {
    let total: number = 0;
    item.deductions.forEach((deduction) => {
      if (deduction.payGradeOption == PayGradeOptionEnum.DefaultValue) {
        total = total + deduction.flatFee;
      } else if (
        deduction.payGradeOption == PayGradeOptionEnum.PercentageOfGross
      ) {
        let value =
          (deduction.percentageApplicable / 100) * item.defaultAnnualGross;
        total = total + value;
      }
    });
    return total;
  }

  AddPaygradeAllowance() {
    if (this.perGross && this.defaultAllowanceValue) {
      this.messageService.add({
        severity: "error",
        summary: "Notice",
        detail:
          "Apologies, You can only input one (% of gross OR Default value)",
      });
      this.ResetMessageToasters();
    } else if (
      this.allPaygradeAllowances.find(
        (x) => x.allowanceId == this.theAllowance.id
      ) != null
    ) {
      this.messageService.add({
        severity: "error",
        summary: "Notice",
        detail: "Apologies, This allowance has already been added",
      });
      this.ResetMessageToasters();
    } else {
      this.allPaygradeAllowances.reverse();
      this.allPaygradeAllowances.push({
        allowanceId: this.theAllowance.id,
        allowance: this.theAllowance,
        percentageOfGross: this.perGross,
        defaultValue: this.defaultAllowanceValue,
      });
      this.allPaygradeAllowances.reverse();
      this.theAllowance = null;
      this.perGross = null;
      this.defaultAllowanceValue = null;
    }
  }

  AddPaygradeDeduction() {
    if (
      this.isDeductibleOption == 1 &&
      this.allSelectedApplicableAllowance.length == 0
    ) {
      this.messageService.add({
        severity: "error",
        summary: "Notice",
        detail:
          "Apologies, Applicable Allowances must be selected for % of Allowance(s) option",
      });
      this.ResetMessageToasters();
      return;
    }

    if (
      this.allPaygradeDeductibles.find(
        (x) => x.deductionId == this.theDeductible.id
      ) != null
    ) {
      this.messageService.add({
        severity: "error",
        summary: "Notice",
        detail: "Apologies, This deductible has already been added",
      });
      this.ResetMessageToasters();
    } else {
      this.allPaygradeDeductibles.reverse();
      let paygradeDeduct: PayGradeDeduction = {
        deductionId: this.theDeductible.id,
        deduction: this.theDeductible,
        calculatedValue: this.deductionCalcValue,
        percentageApplicable:
          this.isDeductibleOption == 1 ? this.deductionPercFee : null,
        applicableAllowances: [],
        flatFee: this.isDeductibleOption == 2 ? this.deductionFlatFee : null,
        payGradeOption: this.isDeductibleOption,
      };
      this.allSelectedApplicableAllowance.forEach((x) => {
        paygradeDeduct.applicableAllowances.push({
          allowance: x,
          allowanceId: x.id,
        });
      });
      this.allPaygradeDeductibles.push(paygradeDeduct);
      this.allPaygradeDeductibles.reverse();
      this.theDeductible = null;
      this.isDeductibleOption = null;
      this.deductionFlatFee = null;
      this.deductionPercFee = null;
      this.deductionCalcValue = null;
      this.allSelectedApplicableAllowance = [];
    }
  }

  CalculatePercDeductible() {
    let amt = 0;
    let defaultAnnualGross = +this.payGradeForm.get("AnnualGross").value;
    if (!defaultAnnualGross) defaultAnnualGross = 0;
    this.allSelectedApplicableAllowance.forEach((allowance) => {
      let valid = this.allPaygradeAllowances.find(
        (x) => x.allowanceId == allowance.id
      );
      if (valid) {
        let calcFig =
          valid.defaultValue > 0
            ? valid.defaultValue
            : (valid.percentageOfGross / 100) * defaultAnnualGross;
        let calc = (this.deductionPercFee / 100) * calcFig;
        amt = amt + calc;
      }
    });
    this.deductionCalcValue = amt;
  }

  CalculateFlatFeeDeductible() {
    this.deductionCalcValue = this.deductionFlatFee;
  }

  CalculatePercPension() {
    let amt = 0;
    let defaultAnnualGross = +this.payGradeForm.get("AnnualGross").value;
    if (!defaultAnnualGross) defaultAnnualGross = 0;
    this.allSelectedPensionAllowance.forEach((allowance) => {
      let valid = this.allPaygradeAllowances.find(
        (x) => x.allowanceId == allowance.id
      );
      if (valid) {
        let calcFig =
          valid.defaultValue > 0
            ? valid.defaultValue
            : (valid.percentageOfGross / 100) * defaultAnnualGross;
        let calc = (this.pensionPercFee / 100) * calcFig;
        amt = amt + calc;
      }
    });
    this.pensionCalcValue = amt;
  }

  CalculateFlatFeePension() {
    this.pensionCalcValue = this.pensionFlatFee;
  }

  GetApplicableAllowancesCaption(item: PayGradeDeduction): string {
    let labels: string = "";
    item.applicableAllowances.forEach(
      (x) => (labels = labels + x.allowance.caption + ",")
    );
    return labels;
  }

  GetTaxReliefTax(item: TaxRelief): string {
    let tax = (item.appliedRate / 100) * item.nextValue;
    return "N" + tax;
  }

  pensionApplicableSelectionLoader() {}

  SavePensionConfiguration() {
    if (this.thePensionApplicableOpt) {
      if (this.thePensionApplicableOpt.key == 1) {
        if (
          this.isPensionApplicableOption == 1 &&
          this.allSelectedPensionAllowance.length == 0
        ) {
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail:
              "Apologies, Applicable Allowances must be selected for % of Allowance(s) option",
          });
          this.ResetMessageToasters();
          return;
        }

        this.pensionConifguration = {
          isApplicable: this.thePensionApplicableOpt.key == 1 ? true : false,
          creditAccountId: this.thePensionCRAccount.id,
          debitAccountId: this.thePensionDRAccount.id,
          pensionOpt: this.isPensionApplicableOption,
          flatFee:
            this.isPensionApplicableOption == 2 ? this.pensionFlatFee : null,
          applicableAllowancesIds: [],
          pensionPerc:
            this.isPensionApplicableOption == 1 ? this.pensionPercFee : null,
          calculatedValue: this.pensionCalcValue,
          creditAccountNumber: this.thePensionCRAccount.accountNumber,
          debitAccountNumber: this.thePensionDRAccount.accountNumber,
        };

        this.allSelectedPensionAllowance.forEach((x) => {
          this.pensionConifguration.applicableAllowancesIds.push(x.id);
        });
        this.isPensionConfigured = true;
      } else if (this.thePensionApplicableOpt.key == 0)
        this.isPensionConfigured = true;
      else this.isPensionConfigured = false;
    } else this.isPensionConfigured = false;
  }
}
