import { Component, OnInit } from "@angular/core";
import { AngularFireStorage } from "@angular/fire/storage";
import { FormBuilder } from "@angular/forms";
import { ConfirmationService, MessageService } from "primeng/api";
import { DialogService } from "primeng/dynamicdialog";
import { LeadEngagementService } from "src/app/services/lead-engagement.service";
import { ContactsService } from "../contacts/contacts.service";
import { LeadEngagementsService } from "./lead-engagements.service";
import { DomSanitizer } from "@angular/platform-browser";
import { Router } from "@angular/router";

@Component({
  selector: "app-lead-engagements",
  templateUrl: "./lead-engagements.component.html",
  styleUrls: ["./lead-engagements.component.scss"],
})
export class LeadEngagementsComponent implements OnInit {
  workspaceIsLoading = false;
  leadList: any;
  leadInstance: any;
  suspectList: any[];
  SuspectInstance: any;
  SelectedSuspect: any;
  suspectGotten: any;
  activeAccordionTabIndex = 0;
  suspectInformation: any;
  gottenImage: any;
  serviceQualifications: any[] = [];
  totalAmount = 0;
  totalDealWorth = 0;
  suspectId: any;
  constructor(
    public leadEngagementService: LeadEngagementsService,
    private formBuilder: FormBuilder,
    private constactsService: ContactsService,
    public confirmationService: ConfirmationService,
    public dialogService: DialogService,
    public messageService: MessageService,
    public fireStorage: AngularFireStorage,
    private sanitizer: DomSanitizer,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.getAllSuspects();
  }

  getAllSuspects() {
    this.workspaceIsLoading = true;
    this.leadEngagementService.getAllSuspects().subscribe(async (data: any) => {
      if (data.responseCode == "00") {
        console.log("we have a value ",data.responseData)
        this.suspectList = data.responseData;
        this.suspectList = data.responseData.map(x=>{
          if(!x.businessName){
            var name = x.firstName + ' ' + x.lastName;
            if(x.othername)
                name = name + ' ' + x.othername;
             return {...x, businessName:  name} 
          }
          return x;
        });
        this.workspaceIsLoading = false;
        console.log("this is leads array", this.leadList);
        this.messageService.add({
          severity: "success",
          summary: "Notice",
          detail: "All leads were fectched successsfully",
        });
      }
    });
  }

  getTotalBudget(result): number {
    if (result.suspectQualifications) {
      if (result.suspectQualifications.length !== 0) {
        var finalAmmount = 0;
        result.suspectQualifications[0].serviceQualifications.forEach(
          (service) => {
            // var budget = service.budget * service.quantityEstimate
            // finalAmmount = finalAmmount + budget;
            var budget = service.budget * service.estimatedDurationInMonths
            finalAmmount = finalAmmount + budget;
          }
        );
        this.totalAmount = finalAmmount;
      } else {
        return;
      }
    } else {
      this.totalAmount = 0;
    }
    return this.totalAmount;
  }

  transform() {
    return this.sanitizer.bypassSecurityTrustResourceUrl(this.gottenImage);
  }

  gotoEngagementContact(suspectId){
    this.router.navigate(["/home/lead-engagements/engagement-contacts",suspectId]);
  }

  gotoEngagementActivities(suspectId){
    this.router.navigate(["/home/lead-engagements/engagement-activities",suspectId]);
  }

  gotoEngagementLead(suspectId){
    this.router.navigate(["/home/lead-engagements/engagement-lead-qualification",suspectId]);
  }
  

  gotoEngagementDasboard(suspectId){
    this.router.navigate(["/home/lead-engagements/engagement-dashboard",suspectId]);
  }

  selectSuspect() {
    console.log("This is logger", this.SelectedSuspect);
    this.workspaceIsLoading = true;
    this.suspectId = this.SelectedSuspect.id;
    this.leadEngagementService
      .getSuspectById(this.SelectedSuspect.id)
      .subscribe(
        async (data: any) => {
          if (data.responseCode == "00") {
            this.suspectGotten = data.responseData;
            console.log("ths is for cearance", this.suspectGotten);
            this.gottenImage = this.suspectGotten.imageUrl;
            this.totalDealWorth = this.getTotalBudget(this.suspectGotten);
            this.workspaceIsLoading = false;
            this.suspectInformation =
              this.suspectGotten.suspectQualifications[0];
            this.serviceQualifications =
              this.suspectInformation == undefined
                ? null
                : this.suspectInformation.serviceQualifications;
            console.log("the suspect that we need", this.suspectGotten);
            console.log("the suspect that was found", this.suspectInformation);
            this.messageService.add({
              severity: "success",
              summary: "Notice",
              detail: "Selected suspect retrieved",
            });
           // this.gotoEngagementContact(this.SelectedSuspect.id);
           this.gotoEngagementDasboard(this.SelectedSuspect.id);
          } else {
            this.messageService.add({
              severity: "error",
              summary: "Notice",
              detail: "Selected suspect was not retrieved",
            });
            this.workspaceIsLoading = false;
          }
        },
        (error) => {
          this.workspaceIsLoading = false;
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail: "An error occurred",
          });
        }
      );
  }

  getSuspectById(id) {
    this.leadEngagementService
      .getSuspectById(id)
      .subscribe(async (data: any) => {
        if (data.responseCode == "00") {
          this.SuspectInstance = data.responseData;
          //  data.responseData.forEach(result=>{

          //  })
          console.log("this is leads array", this.SuspectInstance);
          this.messageService.add({
            severity: "success",
            summary: "Notice",
            detail: "All leads were fectched successsfully",
          });
        }
      });
  }
}
