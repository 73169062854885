import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { CommonResponse, Division, User } from "../../../interfaces/home";
import { DivisionService } from "../../../services/division.service";
import { ConfirmationService, MessageService } from "primeng/api";
import { UserService } from "../../../services/user.service";
import { CompaniesService } from "src/app/services/companies.service";

@Component({
  selector: "app-division",
  templateUrl: "./division.component.html",
  styleUrls: ["./division.component.scss"],
  providers: [ConfirmationService, MessageService],
})
export class DivisionComponent implements OnInit {
  @ViewChild("formWrapper") public formWrapper: ElementRef;
  fetchingDivision = true;
  users: User[];
  companies: any[];
  divisionForm: FormGroup;
  employeeForm1: FormGroup;
  divisions: Division[];
  selectedDivision: Division;
  editingDivision = false;
  private edDivision: Division;
  public divisionCols: any[];
  public exportDivisionColumns: any[];
  selectedHeadId: User;
  constructor(
    private formBuilder: FormBuilder,
    private divisionService: DivisionService,
    public messageService: MessageService,
    public confirmationService: ConfirmationService,
    private userService: UserService,
    private companyService: CompaniesService
  ) {}

  ngOnInit(): void {
    this.fetchDivision();

    this.divisionForm = this.formBuilder.group({
      name: ["", Validators.required],
      description: ["", Validators.required],
      missionStatement: ["", [Validators.required]],
      headId: [null, Validators.required],
      companyId: [null, Validators.required],
    });
    this.divisionCols = [
      { field: "name", header: "Name" },
      { field: "missionStatement", header: "Mission Statement" },
      { field: "description", header: "Description" },
      { field: "head", header: "Head" },
    ];
    this.fetchCompanies();
    this.exportDivisionColumns = this.divisionCols.map((col) => ({
      title: col.header,
      dataKey: col.field,
    }));
    this.userService.allUser().subscribe(async (res: CommonResponse) => {
      if (res.responseCode == "00") {
        this.users = res.responseData;
      }
    });
  }
  fetchDivision() {
    this.divisionService.allDivisionData().subscribe(
      async (division: CommonResponse) => {
        this.divisions = division.responseData;
        this.fetchingDivision = false;
      },
      (error) => {
        this.fetchingDivision = false;
        this.connectionError();
      }
    );
  }

  fetchCompanies() {
    this.companyService.getAll().subscribe(
      async (r: CommonResponse) => {
        this.companies = r.responseData;
      },
      (error) => {}
    );
  }
  private connectionError() {
    this.messageService.add({
      severity: "error",
      summary: "Failed",
      detail: "Connection Error, Please try again",
    });
  }
  createDivision() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Creating new Division Records",
    });
    const postData = {
      name: this.divisionForm.get("name").value,
      description: this.divisionForm.get("description").value,
      missionStatement: this.divisionForm.get("missionStatement").value,
      headId: this.selectedHeadId.id,
      companyId: this.divisionForm.get("companyId").value?.id,
    };
    this.divisionService.postDivision(postData).subscribe(
      async (r: CommonResponse) => {
        if (r.responseCode == "00") {
          await this.messageService.add({
            severity: "success",
            summary: "Completed",
            detail: "New Division Created",
          });
          await this.fetchDivision();
          await this.divisionForm.reset();
        } else {
          await this.messageService.add({
            severity: "error",
            summary: "Failure",
            detail: r.responseMsg,
          });
          await this.fetchDivision();
          await this.divisionForm.reset();
        }
      },
      (error) => {
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail: "Unable to create new Division at the moment",
        });
      }
    );
  }

  deleteDivision(division) {
    this.confirmationService.confirm({
      message:
        "Are you sure you want to dropped " +
        division.name +
        ". All Op. Entities, Service Group and Service Category attached to it will also be dropped",
      accept: () => {
        this._deleteDivision(division);
      },
    });
  }
  _deleteDivision(division) {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Dropping division record",
    });
    this.divisionService.deleteDivision(division.id).subscribe(
      async (result: any) => {
        await this.messageService.add({
          severity: "success",
          summary: "Dropped",
          detail: "Division record dropped",
        });
        await this.fetchDivision();
      },
      (error) => {
        this.connectionError();
      }
    );
  }
  editDivision(divisions) {
    this.editingDivision = true;
    this.divisionForm.addControl(
      "divisionId",
      new FormControl({ value: "", disabled: true }, Validators.required)
    );
    this.edDivision = divisions;
    this.divisionForm.patchValue({
      name: divisions.name,
      missionStatement: divisions.missionStatement,
      description: divisions.description,
      divisionId: divisions.id,
      companyId: this.companies.find((x) => x.id == divisions.companyId),
    });

    // this.divisionFormIn.nativeElement.focus();
    // document.getElementById('formFields').focus();
    // this.selectedHeadId = this.users.find(user => user.id === divisions.head.id);
    this.selectedHeadId = divisions.head;
    this.formWrapper.nativeElement.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "start",
    });
  }

  updateDivision() {
    // update the selected division
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Updating Division details",
    });
    const id = this.edDivision.id;
    const postData = {
      name: this.divisionForm.get("name").value,
      missionStatement: this.divisionForm.get("missionStatement").value,
      description: this.divisionForm.get("description").value,
      headId: this.selectedHeadId.id,
      companyId: this.divisionForm.get("companyId").value?.id,
    };
    this.divisionService
      .updateDivision(id, postData)
      .subscribe(async (result: Division) => {
        this.messageService.add({
          severity: "success",
          summary: "Notice",
          detail: "Division details Updated",
        });
        await this.fetchDivision();
        await this.closeEditing();
      });
  }

  closeEditing() {
    this.editingDivision = false;
    this.divisionForm.reset();
    this.edDivision = null;
  }
}
