import { Injectable } from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {CommanderUserProfile} from '../../interfaces/armada';

@Injectable({
  providedIn: 'root'
})
export class CommanderRegistrationService {

  constructor(
      private httpClient: HttpClient
  ) { }
  baseUrl = environment.armadaBaseUrl+'CommanderUserProfile/';
  allCommanderUserProfile(): Observable<CommanderUserProfile[]>{
    return this.httpClient.get<CommanderUserProfile[]>(this.baseUrl);
  }
  getCommanderUserProfile(id: any):Observable<CommanderUserProfile>{
    return this.httpClient.get<CommanderUserProfile>(this.baseUrl +id);
  }
  getCommanderUserProfileByName(name: any):Observable<CommanderUserProfile>{
    return this.httpClient.get<CommanderUserProfile>(this.baseUrl + 'name/' + name);
  }
  postCommanderUserProfile(data: any){
    return this.httpClient.post(this.baseUrl, data);
  }
  deleteCommanderUserProfile(id: any){
    return this.httpClient.delete(this.baseUrl +id);
  }
  getUnAssignedCommanders(): Observable<CommanderUserProfile>{
    return this.httpClient.get<CommanderUserProfile>(`${this.baseUrl}GetUnAssignedCommanders`);
  }
  updateCommanderUserProfile(id: any, data: any){
    return this.httpClient.put(this.baseUrl +id, data);
  }
  getAssignedTasks() {
    return this.httpClient.get(this.baseUrl + 'GetAssignedTasks');
  }
  getCommanderActivityByRegion() {
    return this.httpClient.get(this.baseUrl + 'GetCommanderActivityByRegion');
  }
  getCommanderId(commanderProfileId) {
    return this.httpClient.get(this.baseUrl + 'GetCommanderId/'+ commanderProfileId);
  }
}
