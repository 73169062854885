import {
  ActOnBatchRequest,
  AddItemToBatchRequest,
  ApprovalConfig,
  ApprovingOffice,
  BatchRequestDetail,
  BatchRequestItem,
  CentralStore,
  CompleteStoreDetails,
  CreateApprovalConfiguration,
  CreateApprovingOffice,
  CreateStockBatch,
  CreateStore,
  GetINVDashboardAnalyticsVM,
  GetInvStoreReportTransferVM,
  GetJustUserBatchRequestsVM,
  GetStockIssueTransfer,
  GetStockIssueVM,
  GetStockReceiptTransfer,
  GetStockReceiptVM,
  GetUserBatchRequestsTransferDTO,
  GetUserBatchRequestsVM,
  InventoryStores,
  Item,
  ItemGroup,
  RegionalStore,
  RequestBatchesMade,
  SetStockPolicy,
  StockBatch,
  StoreItemBatchHistory,
  StoreItems,
  StoreManager,
  TrackCentralRequestVM,
  TrackRegionalRequestVM,
  UpdateStoreReceivingDTO,
} from "./../interfaces/inventory";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class InventoryService {
  baseUrl = environment.gmaBaseUrl + "Inventory/";
  baseUrl_invConf = environment.gmaBaseUrl + "InventoryConfirmation/";
  baseUrl_invPol = environment.gmaBaseUrl + "InventoryPolicy/";
  baseUrl_invReq = environment.gmaBaseUrl + "InventoryRequest/";
  baseUrl_store = environment.gmaBaseUrl + "InventoryStore/";

  constructor(private http: HttpClient) {}

  //Base Url
  CreateItemGroup(data: ItemGroup) {
    return this.http.post(this.baseUrl + "CreateInventoryItemGroup", data);
  }

  GetAllItemGroup(): Observable<ItemGroup[]> {
    return this.http.get<ItemGroup[]>(
      this.baseUrl + "GetAllInventoryItemGroups"
    );
  }

  UpdateItemGroup(id: number, data: ItemGroup): Observable<ItemGroup> {
    return this.http.put<ItemGroup>(
      this.baseUrl + "UpdateInventoryItemGroup/" + id,
      data
    );
  }

  DeleteItemGroup(id: number) {
    return this.http.delete(this.baseUrl + "DeleteInventoryItemGroup/" + id);
  }

  CreateItem(data: Item) {
    return this.http.post(this.baseUrl + "CreateInventoryItem", data);
  }

  UpdateInventoryItem(id: number, data: Item): Observable<Item> {
    return this.http.put<Item>(
      this.baseUrl + "UpdateInventoryItem/" + id,
      data
    );
  }

  GetAllItem(): Observable<Item[]> {
    return this.http.get<Item[]>(this.baseUrl + "GetAllInventoryItems");
  }

  GetAllInventoryStores(): Observable<InventoryStores> {
    return this.http.get<InventoryStores>(
      this.baseUrl + "GetAllInventoryStores"
    );
  }

  GetAllManagerInventoryStores(): Observable<InventoryStores> {
    return this.http.get<InventoryStores>(
      this.baseUrl + "GetAllManagerInventoryStores"
    );
  }

  GetAllStoreManagers(): Observable<StoreManager[]> {
    return this.http.get<StoreManager[]>(
      this.baseUrl + "GetAllInventoryStoreManagers"
    );
  }

  CreateInventoryStore(data: CreateStore) {
    return this.http.post(this.baseUrl + "CreateInventoryStore", data);
  }

  GetStockReceipt(
    data: GetStockReceiptVM
  ): Observable<GetStockReceiptTransfer[]> {
    return this.http.post<GetStockReceiptTransfer[]>(
      this.baseUrl + "GetStockReceipt",
      data
    );
  }

  GetStockIssue(data: GetStockIssueVM): Observable<GetStockIssueTransfer[]> {
    return this.http.post<GetStockIssueTransfer[]>(
      this.baseUrl + "GetStockIssue",
      data
    );
  }

  //Store Url
  GetAllRegionStoreItems(id: number): Observable<StoreItems[]> {
    return this.http.get<StoreItems[]>(
      this.baseUrl_store + "GetAllRegionStoreItems/" + id
    );
  }

  GetAllCentralStoreItems(id: number): Observable<StoreItems[]> {
    return this.http.get<StoreItems[]>(
      this.baseUrl_store + "GetAllCentralStoreItems/" + id
    );
  }

  GetAllCentralInventoryStore(): Observable<CentralStore[]> {
    return this.http.get<CentralStore[]>(
      this.baseUrl_store + "GetAllCentralInventoryStores"
    );
  }

  GetAllManagerCentralInventoryStore(): Observable<CentralStore[]> {
    return this.http.get<CentralStore[]>(
      this.baseUrl_store + "GetAllManagerCentralInventoryStores"
    );
  }

  GetAllRegionalInventoryStore(): Observable<RegionalStore[]> {
    return this.http.get<RegionalStore[]>(
      this.baseUrl_store + "GetAllRegionalInventoryStores"
    );
  }

  GetAllManagerRegionalInventoryStore(): Observable<RegionalStore[]> {
    return this.http.get<RegionalStore[]>(
      this.baseUrl_store + "GetAllManagerRegionalInventoryStores"
    );
  }

  CreateInventoryStockBatch(data: CreateStockBatch) {
    return this.http.post(
      this.baseUrl_store + "CreateInventoryStockBatch",
      data
    );
  }

  GetAllInventoryStockBatches(): Observable<StockBatch[]> {
    return this.http.get<StockBatch[]>(
      this.baseUrl_store + "GetAllInventoryStockBatches"
    );
  }

  LogUserStockBatchRequest(data: CreateStockBatch) {
    return this.http.post(
      this.baseUrl_store + "LogUserStockBatchRequests",
      data
    );
  }

  GetAllUserStockBatchRequests(): Observable<StockBatch[]> {
    return this.http.get<StockBatch[]>(
      this.baseUrl_store + "GetAllUserStockBatchRequests"
    );
  }

  //Req Url
  LogRegionStockBatchRequest(data: CreateStockBatch) {
    return this.http.post(
      this.baseUrl_invReq + "LogRegionStockBatchRequests",
      data
    );
  }

  GetAllRegionStockBatchRequests(): Observable<StockBatch[]> {
    return this.http.get<StockBatch[]>(
      this.baseUrl_invReq + "GetAllRegionStockBatchRequests"
    );
  }

  GetBatchRequestDetails(batchId: number): Observable<BatchRequestDetail[]> {
    return this.http.get<BatchRequestDetail[]>(
      this.baseUrl_invReq +
        "GetAllRegionBatchRequestDetailsByBatchID/" +
        batchId
    );
  }

  GetBatchRequestDetailItems(batchId: number): Observable<StoreItems[]> {
    return this.http.get<StoreItems[]>(
      this.baseUrl_invReq +
        "GetAllRegionBatchRequestDetailItemsByBatchID/" +
        batchId
    );
  }

  MakeUserAStoreManager(userId: number) {
    return this.http.post(
      this.baseUrl_invReq + "MakeUserStoreManager/" + userId,
      null
    );
  }

  GetAllStoreManagerStores(
    storeManagerId: number
  ): Observable<InventoryStores> {
    return this.http.get<InventoryStores>(
      this.baseUrl_invReq + "GetAllStoreManagerStores/" + storeManagerId
    );
  }

  CreateInventoryApprovalOffice(data: CreateApprovingOffice) {
    return this.http.post(
      this.baseUrl_invReq + "CreateInventoryApprovingOffice",
      data
    );
  }

  GetAllInventoryApprovalOffices(): Observable<ApprovingOffice[]> {
    return this.http.get<ApprovingOffice[]>(
      this.baseUrl_invReq + "GetAllInventoryApprovingOffices"
    );
  }

  RemoveInventoryApprovingOffice(id: number) {
    return this.http.delete(
      this.baseUrl_invReq + "DeleteInventoryApprovingOffice/" + id
    );
  }

  UpdateInventoryApprovalOffice(id: number, data: CreateApprovingOffice) {
    return this.http.put(
      this.baseUrl_invReq + "UpdateInventoryApprovingOffice/" + id,
      data
    );
  }

  //Pol Url
  CreateInventoryApprovalConfiguration(data: CreateApprovalConfiguration) {
    return this.http.post(
      this.baseUrl_invPol + "CreateInventoryApprovalConfiguration",
      data
    );
  }

  GetAllInventoryApprovalConfigurations(): Observable<ApprovalConfig[]> {
    return this.http.get<ApprovalConfig[]>(
      this.baseUrl_invPol + "GetAllInventoryApprovalConfigurations"
    );
  }

  RemoveInventoryApprovalConfiguration(id: number) {
    return this.http.delete(
      this.baseUrl_invPol + "DeleteInventoryApprovalConfiguration/" + id
    );
  }

  UpdateInventoryApprovalConfiguration(
    id: number,
    data: CreateApprovalConfiguration
  ) {
    return this.http.put(
      this.baseUrl_invPol + "UpdateInventoryApprovalConfiguration/" + id,
      data
    );
  }

  AddItemToBatchRequest(data: AddItemToBatchRequest) {
    return this.http.post(this.baseUrl_invPol + "AddItemToBatchRequest", data);
  }

  RemoveItemFromBatchRequest(batchRequestId: number) {
    return this.http.delete(
      this.baseUrl_invPol + "RemoveItemFromBatchRequest/" + batchRequestId
    );
  }

  GetItemsInBatchRequest(batchId: number): Observable<BatchRequestItem[]> {
    return this.http.get<BatchRequestItem[]>(
      this.baseUrl_invPol + "GetAllItemsInBatchRequest/" + batchId
    );
  }

  LogBatchRequestForApproval(batchId: number) {
    return this.http.post(
      this.baseUrl_invPol + "LogBatchRequestForApproval/" + batchId,
      null
    );
  }

  GetPendingBatchRequestApproval(): Observable<StockBatch[]> {
    return this.http.get<StockBatch[]>(
      this.baseUrl_invPol + "GetPendingBatchRequestApproval"
    );
  }

  ActOnInventoryBatch(data: ActOnBatchRequest) {
    return this.http.post(
      this.baseUrl_invPol + "ActOnInventoryBatchApproval",
      data
    );
  }

  LineManagerApprovalInventoryBatch(data: ActOnBatchRequest) {
    return this.http.post(
      this.baseUrl_invPol + "LineManagerApprovalInventoryBatch",
      data
    );
  }

  //Conf Url
  GetAllStoreManagerInventoryStores(): Observable<InventoryStores> {
    return this.http.get<InventoryStores>(
      this.baseUrl_invConf + "GetAllStoreManagerInventoryStores"
    );
  }

  GetAllStoreApprovedBatches(data: {
    storeId: number;
    isCentral: boolean;
    isDispatching: boolean;
  }): Observable<StockBatch[]> {
    return this.http.post<StockBatch[]>(
      this.baseUrl_invConf + "GetAllStoreApprovedBatches",
      data
    );
  }

  GetAllUserRequestApprovedBatches(): Observable<StockBatch[]> {
    return this.http.get<StockBatch[]>(
      this.baseUrl_invConf + "GetAllUserRequestApprovedBatches"
    );
  }

  ConfirmBatchRequestItems(data: {
    itemIds: number[];
    batchId: number;
    quantitiesReceived: { key: number; value: number }[];
  }) {
    return this.http.post(
      this.baseUrl_invConf + "ConfirmBatchRequestItems",
      data
    );
  }

  DispatchBatchRequestItems(data: {
    itemIds: number[];
    batchId: number;
    quantitiesDispatched: { key: number; value: number }[];
  }) {
    return this.http.post(
      this.baseUrl_invConf + "DispatchBatchRequestItems",
      data
    );
  }

  GetStoreItemBatchHistory(id: number): Observable<StoreItemBatchHistory[]> {
    return this.http.get<StoreItemBatchHistory[]>(
      this.baseUrl_invConf + "GetStoreItemBatchHistory/" + id
    );
  }

  GetCentralStoreByID(id: number): Observable<CentralStore> {
    return this.http.get<CentralStore>(
      this.baseUrl_invConf + "GetCentralStoreByID/" + id
    );
  }

  GetRegionalStoreByID(id: number): Observable<RegionalStore> {
    return this.http.get<RegionalStore>(
      this.baseUrl_invConf + "GetRegionalStoreByID/" + id
    );
  }

  CompletStoreDetail(data: {
    isCentral: boolean;
    storeId: number;
  }): Observable<CompleteStoreDetails> {
    return this.http.post<CompleteStoreDetails>(
      this.baseUrl_invConf + "CompleteStoreDetails",
      data
    );
  }

  SetStockPolicy(data: SetStockPolicy) {
    return this.http.post(this.baseUrl_invConf + "SetStockPolicy", data);
  }

  GetStoreItemAvgDailyDisbursementSuggestion(id: number): Observable<number> {
    return this.http.get<number>(
      this.baseUrl_invConf + "GetStoreItemAvgDailyDisbursementSuggestion/" + id
    );
  }

  GetRequestBatchesMade(data: RequestBatchesMade): Observable<StockBatch[]> {
    return this.http.post<StockBatch[]>(
      this.baseUrl_invConf + "GetRequestBatchesMade",
      data
    );
  }

  GetDashboardAnalytics(): Observable<GetINVDashboardAnalyticsVM> {
    return this.http.get<GetINVDashboardAnalyticsVM>(
      this.baseUrl_invConf + "GetDashboardAnalytics"
    );
  }

  UpdateCentralStore(id: number, data: UpdateStoreReceivingDTO) {
    return this.http.put(
      this.baseUrl_invConf + "UpdateCentralStore/" + id,
      data
    );
  }

  UpdateRegionalStore(id: number, data: UpdateStoreReceivingDTO) {
    return this.http.put(
      this.baseUrl_invConf + "UpdateRegionalStore/" + id,
      data
    );
  }

  GetInvStoreReport(
    isRegional: boolean,
    storeId: number
  ): Observable<GetInvStoreReportTransferVM[]> {
    return this.http.get<GetInvStoreReportTransferVM[]>(
      this.baseUrl_invConf + "GetInvStoreReport/" + isRegional + "/" + storeId
    );
  }

  GetUserBatchRequests(
    data: GetUserBatchRequestsVM
  ): Observable<GetUserBatchRequestsTransferDTO[]> {
    return this.http.post<GetUserBatchRequestsTransferDTO[]>(
      this.baseUrl + "GetUserBatchRequests",
      data
    );
  }

  GetJustUserBatchRequests(
    data: GetJustUserBatchRequestsVM
  ): Observable<StockBatch[]> {
    return this.http.post<StockBatch[]>(
      this.baseUrl + "GetJustUserBatchRequests",
      data
    );
  }

  TrackRegionalRequest(
    data: TrackRegionalRequestVM
  ): Observable<GetUserBatchRequestsTransferDTO[]> {
    return this.http.post<GetUserBatchRequestsTransferDTO[]>(
      this.baseUrl + "TrackRegionalRequest",
      data
    );
  }

  TrackCentralRequest(
    data: TrackCentralRequestVM
  ): Observable<GetUserBatchRequestsTransferDTO[]> {
    return this.http.post<GetUserBatchRequestsTransferDTO[]>(
      this.baseUrl + "TrackCentralRequest",
      data
    );
  }

  LineManagerDeclinedBatchApproval(data: ActOnBatchRequest) {
    return this.http.post(
      this.baseUrl_invPol + "LineManagerDeclinedBatchApproval",
      data
    );
  }
}
