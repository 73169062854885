import { Component, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import {DynamicDialogConfig, DynamicDialogRef} from 'primeng/dynamicdialog';
import { DeliverableFulfillmentService } from 'src/app/services/deliverable-fulfillment.service';
import { FireBaseAuthService } from 'src/app/services/fire-base-auth.service';


@Component({
  selector: 'app-approve-deliverable',
  templateUrl: './approve-deliverable.component.html',
  styleUrls: ['./approve-deliverable.component.scss']
})
export class ApproveDeliverableComponent implements OnInit {

  deliverable : any;
  displayHeader: boolean;
  task: any;
  

  constructor(
    private modalRef: DynamicDialogRef,
    private modalConfig: DynamicDialogConfig,
    private messageService: MessageService,
    private deliverableFulfilmentService: DeliverableFulfillmentService,
    private firebaseAuthService: FireBaseAuthService,

  ) {
    
   }

  ngOnInit(): void {
    this.deliverable = this.modalConfig.data.deliverableFulfillment;
    this.task = this.modalConfig.data.task;
    this.firebaseAuthService.getDownloadRef(this.deliverable.deliverableCompletionReferenceUrl)
  }

  closeDialog(res?: any) {
    return this.modalRef.close(res);
  }

  async approveDeliverable(id: number){
    this.deliverableFulfilmentService.setDeliverableStatus(id, this.deliverable)
        .subscribe(async (res: any) => {
          this.deliverable = res;
          await this.closeDialog('ApproveDeliverableSuccess');
        }, async error => {
          await this.closeDialog('ApproveDeliverableFailed');
        })
  }

}
