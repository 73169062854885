import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { MessageService, ConfirmationService } from "primeng/api";
import { BreadcrumbService } from "src/app/breadcrumb.service";
import {
  ApprovalChecklist,
  CreateApprovalChecklistVM,
  MoveApprovalChecklistSequenceVM,
  UpdateApprovalChecklistVM,
} from "src/app/interfaces/approval-checklist";
import { ApprovalLevel, EndorsementType } from "src/app/interfaces/home";
import { ApprovalChecklistService } from "src/app/services/approval-checklist.service";
import { ApprovalLevelService } from "src/app/services/approval-level.service";
import { EndorsementTypeService } from "src/app/services/endorsement-type.service";

@Component({
  selector: "app-approval-checklist",
  templateUrl: "./approval-checklist.component.html",
  styleUrls: ["./approval-checklist.component.scss"],
})
export class ApprovalChecklistComponent implements OnInit {
  @ViewChild("formWrapper") public formWrapper: ElementRef;
  approvalChecklistForm: FormGroup;
  allEndorsementTypes: EndorsementType[];
  selectedEndorsementTypes: EndorsementType[] = [];
  chklist: string;
  chklistIsRequired: boolean;
  allCheckLists: {
    checklist: string;
    isRequired: boolean;
  }[] = [];
  isEditing: boolean;
  fetchingApprovalChecklists: boolean;
  allApprovalChecklistLevels: {
    level: string;
    approvalTypes: {
      typeName: string;
      checkLists: ApprovalChecklist[];
    }[];
  }[] = [];
  allApprovalChecklists: {
    typeName: string;
    checkLists: ApprovalChecklist[];
  }[] = [];
  approvalChecklistCols: any[];
  approvalChecklistLevelCols: any[];
  approvalChecklistInView: ApprovalChecklist;
  allApprovalLevels: ApprovalLevel[];
  selectedApprovalLevels: ApprovalLevel[] = [];

  constructor(
    fb: FormBuilder,
    private approvalLevelService: ApprovalLevelService,
    private approvalChecklistService: ApprovalChecklistService,
    private endorsementTypeService: EndorsementTypeService,
    public messageService: MessageService,
    private breadcrumbService: BreadcrumbService,
    public confirmationService: ConfirmationService
  ) {
    this.approvalChecklistForm = fb.group({
      ApprovalLevel: [""],
      EndorsmentType: ["", Validators.required],
      Checklist: [""],
      IsRequired: [""],
    });
  }

  ngOnInit(): void {
    this.breadcrumbService.setItems([
      {
        label: "Approval",
      },
      {
        label: "Approval Checklist",
        routerLink: ["/home/approval-checklist"],
      },
    ]);

    this.approvalChecklistCols = [
      { field: "checkList", header: "Checklist " },
      { field: "isRequired", header: "Is Required" },
    ];

    this.approvalChecklistLevelCols = [{ field: "level", header: "Level " }];

    this.fetchingApprovalChecklists = true;
    this.FetchAllEndorsementTypes();
    this.FetchAllApprovalLevels();
  }

  async FetchAllEndorsementTypes() {
    this.endorsementTypeService.allEndorsementTypeData().subscribe(
      async (data) => {
        if (data.responseCode != "00") {
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail: data["responseMsg"],
          });
          this.fetchingApprovalChecklists = false;
          return;
        }

        this.allEndorsementTypes = data.responseData ?? [];
        this.allEndorsementTypes.push({
          caption: "Service Creation",
          openingDate: null,
          closingDate: null,
        });
        this.allEndorsementTypes.push({
          caption: "Addition Contract Creation",
          openingDate: null,
          closingDate: null,
        });
        this.allEndorsementTypes.push({
          caption: "Contract Creation",
          openingDate: null,
          closingDate: null,
        });

        this.FetchAllApprovalChecklists();
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all endorsement types at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
        this.fetchingApprovalChecklists = false;
      }
    );
  }

  async FetchAllApprovalChecklists() {
    this.fetchingApprovalChecklists = true;
    this.approvalChecklistService.GetAllApprovalChecklists().subscribe(
      async (data) => {
        if (data.responseCode != "00") {
          this.messageService.add({
            severity: "error",
            summary: data.responseMsg,
          });
          this.fetchingApprovalChecklists = false;
          return;
        }

        data.responseData.forEach((x) => {
          if (x.approverLevelId == null && x.isNonPrinicipalLevel)
            x.approverLevelId = 0;
        });
        this.allApprovalChecklistLevels = [];
        let groupedByLevel = [
          ...new Set(data.responseData.map((chk) => chk.approverLevelId)),
        ];
        groupedByLevel.forEach((levelId) => {
          this.allApprovalChecklists = [];
          let groupedByTypeName = [
            ...new Set(
              data.responseData
                .filter((x) => x.approverLevelId == levelId)
                .map((chk) => chk.typeName)
            ),
          ];
          groupedByTypeName.forEach((typeName) => {
            let chkLists = data.responseData.filter(
              (x) => x.typeName == typeName && x.approverLevelId == levelId
            );
            chkLists.sort((x) => x.sequence).reverse();
            this.allApprovalChecklists.push({
              typeName: typeName,
              checkLists: chkLists,
            });
          });
          this.allApprovalChecklistLevels.push({
            level: this.GetApprovalLevelCaption(levelId),
            approvalTypes: this.allApprovalChecklists,
          });
        });
        this.fetchingApprovalChecklists = false;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all approval checklists at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
        this.fetchingApprovalChecklists = false;
      }
    );
  }

  async FetchAllApprovalLevels() {
    this.approvalLevelService.allApprovalLevelData().subscribe(
      async (data) => {
        if (data.responseCode != "00") {
          this.messageService.add({
            severity: "error",
            summary: data.responseMsg,
          });
          return;
        }

        this.allApprovalLevels = data.responseData;
        this.allApprovalLevels.push({
          id: 0,
          caption: "Non-Principal",
          alias: "NP",
          description: "Non Principal Approver Level",
        });
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all approval levels at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  CreateApprovalChecklist() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Creating Approval Checklist...",
    });

    const postData: CreateApprovalChecklistVM = {
      approvalLevelIds:
        this.selectedApprovalLevels.length == 0
          ? [null]
          : this.selectedApprovalLevels.map((x) => x.id),
      endorsementTypes: [],
      checklistDatas: [],
    };

    this.selectedEndorsementTypes.forEach((x) =>
      postData.endorsementTypes.push({
        endorsementTypeId: x.id,
        typeName: x.caption,
      })
    );

    this.allCheckLists.forEach((x) =>
      postData.checklistDatas.push({
        checklist: x.checklist,
        isRequired: x.isRequired,
      })
    );

    this.approvalChecklistService.CreateApprovalChecklist(postData).subscribe(
      async (data) => {
        if (data.responseCode != "00") {
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail: data.responseMsg,
          });
          return;
        }

        this.messageService.add({
          severity: "success",
          summary: "Completed",
          detail: "Checklist Created Successfully...",
        });
        this.CloseEditingApprovalChecklist();
        this.FetchAllApprovalChecklists();
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to create approval checklists at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  AddChecklistToList() {
    if (!this.chklist) {
      this.messageService.add({
        severity: "error",
        summary: "Failed",
        detail: "Please provide a checklist",
      });
      return;
    }

    let alreadyAdded = false;
    this.allCheckLists.forEach((item) => {
      if (item.checklist == this.chklist) {
        alreadyAdded = true;
      }
    });

    if (alreadyAdded) {
      this.messageService.add({
        severity: "error",
        summary: "Failed",
        detail: "Checklist has already been added to list.",
      });
      return;
    }

    this.allCheckLists.push({
      checklist: this.chklist,
      isRequired: true,
    });

    this.chklist = null;
    this.messageService.add({
      severity: "success",
      summary: "Added",
      detail: "Checklist added successfully",
    });
  }

  RemoveChecklist(item: any) {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Removing Checklist...",
    });

    const index = this.allCheckLists.indexOf(item);
    if (index > -1) {
      this.allCheckLists.splice(index, 1);
    }

    this.messageService.add({
      severity: "success",
      summary: "Removed",
      detail: "Checklist Removed successfully",
    });
  }

  CloseEditingApprovalChecklist() {
    this.approvalChecklistForm.reset();
    this.allCheckLists = [];
    this.selectedEndorsementTypes = [];
    this.selectedApprovalLevels = [];
    this.chklist = null;
    this.approvalChecklistInView = null;
    this.chklistIsRequired = false;
    this.isEditing = false;
  }

  UpdateApprovalChecklist() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Updating Approval Checklist...",
    });

    const id = this.approvalChecklistInView.id;
    const postData: UpdateApprovalChecklistVM = {
      checklist: this.chklist,
      isRequired: this.chklistIsRequired,
    };

    this.approvalChecklistService
      .UpdateApprovalChecklist(id, postData)
      .subscribe(
        async (data) => {
          if (data.responseCode != "00") {
            this.messageService.add({
              severity: "error",
              summary: "Notice",
              detail: data.responseMsg,
            });
            return;
          }

          this.messageService.add({
            severity: "success",
            summary: "Completed",
            detail: "Approval Checklist Updated Successfully!",
          });
          this.CloseEditingApprovalChecklist();
          this.FetchAllApprovalChecklists();
        },
        (error) => {
          console.log("Error: " + JSON.stringify(error));
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail:
              "Unable to update approval checklist at the moment.. Reason: [" +
              (error ? error.error.message : "request failed - permission") +
              "]",
          });
        }
      );
  }

  DeleteApprovalChecklist(item: ApprovalChecklist) {
    this.confirmationService.confirm({
      message:
        "Are you sure you want to remove approval checklist. It is an irreversible action. Do you still wish to prceed?",
      accept: () => {
        this.messageService.add({
          severity: "info",
          summary: "Notice",
          detail: "Removing approval checklist...",
        });

        this.approvalChecklistService
          .DeleteApprovalChecklist(item.id)
          .subscribe(
            async (data) => {
              if (data.responseCode != "00") {
                this.messageService.add({
                  severity: "error",
                  summary: "Notice",
                  detail: data.responseMsg,
                });
                return;
              }

              this.messageService.add({
                severity: "success",
                summary: "Completed",
                detail: "Checklist Removed Successfully...",
              });
              this.FetchAllApprovalChecklists();
            },
            (error) => {
              console.log("Error: " + JSON.stringify(error));
              this.messageService.add({
                severity: "error",
                summary: "Notice",
                detail:
                  "Unable to remove approval checklist at the moment.. Reason: [" +
                  error
                    ? error.error.message
                    : "request failed - permission" + "]",
              });
            }
          );
      },
    });
  }

  EditApprovalChecklist(item: ApprovalChecklist) {
    this.isEditing = true;
    this.selectedEndorsementTypes = this.allEndorsementTypes.filter(
      (x) => x.caption == item.typeName
    );
    this.allCheckLists = [];
    this.chklist = item.checkList;
    this.chklistIsRequired = true;
    this.approvalChecklistInView = item;

    this.formWrapper.nativeElement.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "start",
    });
  }

  MoveOfficeSequence(isUpward: boolean, item: ApprovalChecklist) {
    this.confirmationService.confirm({
      message: "Are you sure you want to change approval checklist sequence?",
      accept: () => {
        this.messageService.add({
          severity: "info",
          summary: "Notice",
          detail: "Updating apprval checklist sequence...",
        });

        const postData: MoveApprovalChecklistSequenceVM = {
          isUpward: isUpward,
          currentSequence: item.sequence,
        };

        this.approvalChecklistService
          .MoveApprovalChecklistSequence(item.id, postData)
          .subscribe(
            async (data) => {
              if (data.responseCode != "00") {
                this.messageService.add({
                  severity: "error",
                  summary: "Notice",
                  detail: data.responseMsg,
                });
                return;
              }

              this.messageService.add({
                severity: "success",
                summary: "Completed",
                detail: "Update Successfull!...",
              });
              this.FetchAllApprovalChecklists();
            },
            (error) => {
              console.log("Error: " + JSON.stringify(error));
              this.messageService.add({
                severity: "error",
                summary: "Notice",
                detail:
                  "Unable to update approval checklist sequence at the moment.. Reason: [" +
                  (error
                    ? error.error.message
                    : "request failed - permission") +
                  "]",
              });
            }
          );
      },
    });
  }

  GetApprovalLevelCaption(levelId: number): string {
    let level = this.allApprovalLevels.find((x) => x.id == levelId);
    if (level) return level.caption;
    if (levelId == 0) return "Non Principal";

    return "All Levels";
  }
}
