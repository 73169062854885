import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { AngularFireStorage } from "@angular/fire/storage";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ConfirmationService, MessageService } from "primeng/api";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { BreadcrumbService } from "src/app/breadcrumb.service";
import {
  ArmedEscortResourceRequiredPerService,
  ArmedEscortType,
  CommanderResourceRequiredPerService,
  CommanderType,
  PilotResourceRequiredPerService,
  PilotType,
  ServiceRegistration,
  VehicleResourceRequiredPerService,
  VehicleType,
} from "src/app/interfaces/armada";
import { CommonResponse, Services } from "src/app/interfaces/home";
import { ArmedEscortTypeService } from "src/app/services/armada/armed-escort-type.service";
import { CommanderTypeService } from "src/app/services/armada/commander-type.service";
import { PilotTypeService } from "src/app/services/armada/pilot-type.service";
import { ServiceRegistrationService } from "src/app/services/armada/service-registration.service";
import { VehicleTypeService } from "src/app/services/armada/vehicle-type.service";
import { InventoryService } from "src/app/services/inventory.service";

@Component({
  selector: "app-service-registration",
  templateUrl: "./service-registration.component.html",
  styleUrls: ["./service-registration.component.scss"],
})
export class ServiceRegistrationComponent implements OnInit, OnDestroy {
  @ViewChild("formWrapper") public formWrapper: ElementRef;
  private unsubscriber$ = new Subject<void>();

  public targetCols: any;
  private exportTargetColumns: any;
  public submittingData: boolean;
  public loading: boolean;
  public submitButtonLabel: string;
  public serviceRegistrations: ServiceRegistration[];
  selectedserviceRegistration: ServiceRegistration;
  private edServiceRegistration: ServiceRegistration;
  public serviceRegistrationForm: FormGroup;
  userForm: FormGroup;
  public selectedService: Services = null;
  public services: Services[];
  //selectedserviceRegistration: any;

  editingServiceRegistration = false;
  requiresArmedEscort = false;
  requiresCommander = false;
  requiresVehicle = false;
  requiresPilot = false;
  vehicleQuantityRequired = 1;
  commanderQuantityRequired = 1;
  pilotQuantityRequired = 1;
  armedEscortQuantityRequired = 1;

  //Commander
  public CommanderTypes: CommanderType[] = [];
  private edCommanderTypes: CommanderType;
  public selectedCommanderTypes: CommanderType = null;
  public selectedMultiCommanderTypes: CommanderType[] = [];
  public CommanderResourceRequiredServices: CommanderResourceRequiredPerService[];
  public selectedCommanderResourceRequiredServices: CommanderResourceRequiredPerService =
    null;

  //Pilot
  public PilotTypes: PilotType[] = [];
  private edPilotType: PilotType;
  public selectedPilotType: PilotType = null;
  public selectedMultiPilotTypes: PilotType[] = [];
  public PilotResourceRequiredServices: PilotResourceRequiredPerService[];
  public selectedPilotResourceRequiredServices: PilotResourceRequiredPerService =
    null;

  //ArmedEscort
  public ArmedEscortTypes: ArmedEscortType[] = [];
  private edArmedEscortType: ArmedEscortType;
  public selectedArmedEscortType: ArmedEscortType = null;
  public selectedMultiArmedEscortTypes: ArmedEscortType[] = [];
  public ArmedEscortResourceRequiredServices: ArmedEscortResourceRequiredPerService[];
  public selectedArmedEscortResourceRequiredServices: ArmedEscortResourceRequiredPerService =
    null;

  //Vehicle
  public VehicleTypes: VehicleType[] = [];
  private edVehicleType: VehicleType;
  public selectedVehicleType: VehicleType = null;
  public selectedMultiVehicleTypes: VehicleType[] = [];
  public VehicleResourceRequiredServices: VehicleResourceRequiredPerService[];
  public selectedVehicleResourceRequiredServices: VehicleResourceRequiredPerService =
    null;
  public ResourceRequiredPerServiceForm: FormGroup;

  constructor(
    public messageService: MessageService,
    public confirmationService: ConfirmationService,
    public fireStorage: AngularFireStorage,
    public inventoryService: InventoryService,
    private formBuilder: FormBuilder,
    private breadcrumbService: BreadcrumbService,
    private serviceRegistrationService: ServiceRegistrationService,
    private armedEscortTypeService: ArmedEscortTypeService,
    private pilotTypeService: PilotTypeService,
    private commanderTypeService: CommanderTypeService,
    private vehicleTypeService: VehicleTypeService
  ) {
    this.breadcrumbService.setItems([
      { label: "Setup" },
      {
        label: "Service Registration",
        routerLink: ["/setup/service-registration"],
      },
    ]);
  }

  ngOnInit(): void {
    this.targetCols = [
      { field: "serviceId", header: "serviceId" },
      { field: "vehicleQuantityRequired", header: "vehicleQuantityRequired" },
      // {
      //   field: "description",
      //   header: "description",
      // },
      {
        field: "commanderQuantityRequired",
        header: "commanderQuantityRequired",
      },
      {
        field: "armedEscortQuantityRequired",
        header: "armedEscortQuantityRequired",
      },
      { field: "pilotQuantityRequired", header: "pilotQuantityRequired" },
      { field: "requiresArmedEscort", header: "requiresArmedEscort" },
      { field: "requiresPilot", header: "requiresPilot" },
      { field: "requiresVehicle", header: "requiresVehicle" },
      { field: "requiresCommander", header: "requiresCommander" },
    ];
    this.exportTargetColumns = this.targetCols.map((col) => ({
      title: col.header,
      dataKey: col.field,
    }));
    this.serviceRegistrationForm = this.formBuilder.group({
      serviceId: [{ value: null, disabled: true }, [Validators.required]],
      //description: ["", [Validators.required, Validators.minLength(3)]],
      vehicleQuantityRequired: [1, [Validators.min(1)]],
      commanderQuantityRequired: [1, [Validators.min(1)]],
      armedEscortQuantityRequired: [1, [Validators.min(1)]],
      pilotQuantityRequired: [1, [Validators.min(1)]],
      requiresArmedEscort: [false, [Validators.required]],
      requiresPilot: [true, [Validators.required]],
      requiresVehicle: [true, [Validators.required]],

      requiresCommander: [false, [Validators.required]],
      //sequence: [''],
    });

    //ApplicableTypes
    this.targetCols = [
      { field: "name", header: "Registered Service" },
      {
        field: "commanderTypeId",
        header: "commanderTypeId",
      },
      { field: "caption", header: "Applicable Type" },
      {
        field: "armedEscortTypeId",
        header: "armedEscortTypeId",
      },
      { field: "pilotTypeId", header: "pilotTypeId" },
    ];
    this.exportTargetColumns = this.targetCols.map((col) => ({
      title: col.header,
      dataKey: col.field,
    }));
    this.ResourceRequiredPerServiceForm = this.formBuilder.group({
      serviceRegistrationId: ["", [Validators.required]],
      commanderTypeId: [null, [Validators.required]],
      vehicleTypeId: [null, [Validators.required]],
      armedEscortTypeId: [null, [Validators.required]],

      pilotTypeId: [null, [Validators.required]],
      //sequence: [''],
    });

    this.userForm = this.formBuilder.group({
      userProfile: [""],
    });
    this.userForm.valueChanges
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(() => {
        this.selectedService = this.userForm.get("userProfile").value;
        this.getQuantities();
      });

    this.ResourceRequiredPerServiceForm.valueChanges
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(() => {
        this.selectedserviceRegistration =
          this.ResourceRequiredPerServiceForm.get(
            "serviceRegistrationId"
          ).value;
      });

    this.fetchAllVehicleResource();
    this.fetchAllPilotResource();
    this.fetchAllCommanderResource();
    this.fetchAllArmedEscortResource();
    this.fetchAllRegisteredServices();
    this.fetchAllVehicleType();
    this.fetchAllPilotType();
    this.fetchAllCommanderType();
    this.fetchAllArmedEscortType();
    this.fetchAllServices();
    this.submitButtonLabel = "Create";
  }

  fetchAllRegisteredServices(): void {
    this.serviceRegistrationService
      .allServiceReg()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            var res = r.responseData;
            this.serviceRegistrations = res;
            this.loading = false;
          }
        },
        (error) => {
          this.loading = false;
          this.connectionError();
        }
      );
    this.submittingData = false;
  }
  fetchAllServices(): void {
    this.serviceRegistrationService
      .allSecuredMobilityServices()
      //.allServices()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            var res = r.responseData;
            this.services = res;
            console.log("Sec Mob: ", this.services);
            this.loading = false;
          }
        },
        (error) => {
          this.loading = false;
          this.connectionError();
        }
      );
    this.submittingData = false;
  }

  createServiceRegister() {
     this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Adding new service record",
    });
    const formData = this.serviceRegistrationForm.value;
    const postData = {
      serviceId: this.selectedService.id,
      //description: formData.description,
      vehicleQuantityRequired: formData.vehicleQuantityRequired,
      commanderQuantityRequired: formData.commanderQuantityRequired,
      armedEscortQuantityRequired: formData.armedEscortQuantityRequired,
      pilotQuantityRequired: formData.pilotQuantityRequired,
      requiresArmedEscort: formData.requiresArmedEscort,
      requiresPilot: formData.requiresPilot,
      requiresVehicle: formData.requiresVehicle,
      //pilotTypeId: this.selectedPilotType.id,
      requiresCommander: formData.requiresCommander,
    };
    this.serviceRegistrationService
      .postServiceReg(postData)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            this.messageService.add({
              summary: "Success",
              severity: "success",
              detail: "New Service Created",
            });
            this.fetchAllRegisteredServices();
            this.fetchAllServices();
            this.submittingData = false;
            //this.serviceRegistrationForm.reset();
            //this.getQuantities();
            //window.location.reload();
            this.userForm.reset();
            this.closeEditing();
          } else {
            this.messageService.add({
              severity: "error",
              summary: "Failed",
              detail: " Error: " + r.responseMsg,
            });
            this.submittingData = false;
          }
        },
        (error) => {
          this.messageService.add({
            severity: "error",
            summary: "Failed",
            detail: "Connection Error " + error.error.message,
          });
          this.submittingData = false;
        }
      );
    this.getQuantities();
    this.submittingData = false;
  }

  deleteRegisteredService(serviceRegistration: ServiceRegistration): void {
    this.confirmationService.confirm({
      message: "Are you sure you want to delete " + "Service",
      accept: () => {
        this._deleteRegisteredService(serviceRegistration);
      },
    });
  }
  _deleteRegisteredService(serviceRegistration) {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Deleting Service record",
    });
    this.serviceRegistrationService
      .deleteServiceReg(serviceRegistration.id)
      .subscribe(
        async (result: any) => {
          await this.messageService.add({
            severity: "success",
            summary: "Deleted",
            detail: "Service record removed",
          });
          await this.fetchAllRegisteredServices();
        },
        (error) => {
          this.connectionError();
        }
      );
  }
  editRegistredService(serviceRegistrations: ServiceRegistration): void {
    this.formWrapper.nativeElement.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "start",
    });
    this.edServiceRegistration = serviceRegistrations;
    this.editingServiceRegistration = true;
    // this.imageSrc = pilotProfiles.imageUrl;
    this.serviceRegistrationForm.setValue({
      serviceId: serviceRegistrations.serviceId,
      //description: serviceRegistrations.description,
      requiresPilot: serviceRegistrations.requiresPilot,
      pilotQuantityRequired: serviceRegistrations.pilotQuantityRequired,
      requiresVehicle: serviceRegistrations.requiresVehicle,
      vehicleQuantityRequired: serviceRegistrations.vehicleQuantityRequired,
      requiresCommander: serviceRegistrations.requiresCommander,
      commanderQuantityRequired: serviceRegistrations.commanderQuantityRequired,
      requiresArmedEscort: serviceRegistrations.requiresArmedEscort,
      armedEscortQuantityRequired:
        serviceRegistrations.armedEscortQuantityRequired,
    });
    // this.selectedPilotType = this.pilotTypes.find(
    //   (Type) => Type.id === pilotProfiles.pilotTypeId
    // );

    this.submitButtonLabel = "Update";
  }
  updateRegservice(): boolean {
    this.submittingData = true;
    if (!this.edServiceRegistration) {
      return false;
    }
    const formData = this.serviceRegistrationForm.value;
    const data = {
      serviceId: formData.serviceId,
      //description: formData.description,
      requiresPilot: formData.requiresPilot,
      pilotQuantityRequired: formData.pilotQuantityRequired,
      requiresVehicle: formData.requiresVehicle,
      vehicleQuantityRequired: formData.vehicleQuantityRequired,
      requiresCommander: formData.requiresCommander,
      commanderQuantityRequired: formData.commanderQuantityRequired,
      requiresArmedEscort: formData.requiresArmedEscort,
      armedEscortQuantityRequired: formData.armedEscortQuantityRequired,
      //rankId: this.selectedPilotRank.id,
      // sequence: 0
    };
    this.serviceRegistrationService
      .updateServiceReg(this.edServiceRegistration.id, data)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            this.messageService.add({
              severity: "success",
              detail: "Registered Service Updated",
              summary: "Completed",
            });
            this.submittingData = false;
            this.closeEditing();
            this.fetchAllRegisteredServices();
          }
        },
        (error) => {
          this.connectionError();
          this.submittingData = false;
        }
      );
  }

  getQuantities() {
    this.serviceRegistrationForm.patchValue({
      vehicleQuantityRequired: 1,
      commanderQuantityRequired: 1,
      armedEscortQuantityRequired: 1,
      pilotQuantityRequired: 1,
      requiresArmedEscort: false,
      requiresVehicle: false,
      requiresCommander: false,
      requiresPilot: false,
    });

    //this.vehicleQuantityRequired = 1;
    // this.commanderQuantityRequired = 1;
    // this.armedEscortQuantityRequired = 1;
    // this.pilotQuantityRequired = 1;
  }
  //ApplicableTypes
  //Pilot
  fetchAllPilotType(): void {
    this.pilotTypeService
      .allPilotType()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            var result = r.responseData;
            this.PilotTypes = result;
            //console.log(this.PilotTypes);
          }
        },
        (error) => this.connectionError()
      );
    this.submittingData = false;
  }

  fetchAllPilotResource(): void {
    this.serviceRegistrationService
      .allPilotResource()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            var result = r.responseData;
            this.PilotResourceRequiredServices = result;
            console.log(this.PilotResourceRequiredServices);
          }
        },
        (error) => this.connectionError()
      );
    this.submittingData = false;
  }
  deletePilotResource(
    PilotResourceRequiredPerService: PilotResourceRequiredPerService
  ): void {
    this.confirmationService.confirm({
      message: "Are you sure you want to delete " + "Pair",
      accept: () => {
        this._deletePilotResource(PilotResourceRequiredPerService);
      },
    });
  }
  _deletePilotResource(pilot) {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Deleting Pair ",
    });
    this.serviceRegistrationService.deletePilotResource(pilot.id).subscribe(
      async (result: any) => {
        await this.messageService.add({
          severity: "success",
          summary: "Deleted",
          detail: "Pair removed",
        });
        await this.fetchAllPilotResource();
      },
      (error) => {
        this.connectionError();
      }
    );
  }

  //Commander
  fetchAllCommanderType(): void {
    this.commanderTypeService
      .allCommanderType()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            var result = r.responseData;
            this.CommanderTypes = result;
            //console.log(this.CommanderTypes);
          }
        },
        (error) => this.connectionError()
      );
    this.submittingData = false;
  }
  fetchAllCommanderResource(): void {
    this.serviceRegistrationService
      .allCommanderResource()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            var result = r.responseData;
            this.CommanderResourceRequiredServices = result;
            console.log(this.CommanderResourceRequiredServices);
          }
        },
        (error) => this.connectionError()
      );
    this.submittingData = false;
  }
  deleteCommanderResource(
    CommanderResourceRequiredPerService: CommanderResourceRequiredPerService
  ): void {
    this.confirmationService.confirm({
      message:
        "Are you sure you want to delete " +
        CommanderResourceRequiredPerService.commanderType.typeName,
      accept: () => {
        this._deleteCommanderResource(CommanderResourceRequiredPerService);
      },
    });
  }
  _deleteCommanderResource(commander) {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Deleting Pair ",
    });
    this.serviceRegistrationService
      .deleteCommanderResource(commander.id)
      .subscribe(
        async (result: any) => {
          await this.messageService.add({
            severity: "success",
            summary: "Deleted",
            detail: "Pair removed",
          });
          await this.fetchAllCommanderResource();
        },
        (error) => {
          this.connectionError();
        }
      );
  }

  //ArmedEscort
  fetchAllArmedEscortType(): void {
    this.armedEscortTypeService
      .allArmedEscortType()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            var result = r.responseData;
            this.ArmedEscortTypes = result;
            //console.log(this.ArmedEscortTypes);
          }
        },
        (error) => this.connectionError()
      );
    this.submittingData = false;
  }
  fetchAllArmedEscortResource(): void {
    this.serviceRegistrationService
      .allArmedEscortResource()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            var result = r.responseData;
            this.ArmedEscortResourceRequiredServices = result;
            console.log(this.ArmedEscortResourceRequiredServices);
          }
        },
        (error) => this.connectionError()
      );
    this.submittingData = false;
  }
  deleteArmedEscortResource(
    EscortResourceRequiredPerService: ArmedEscortResourceRequiredPerService
  ): void {
    this.confirmationService.confirm({
      message:
        "Are you sure you want to delete " +
        EscortResourceRequiredPerService.armedEscortType.caption,
      accept: () => {
        this._deleteArmedEscortResource(EscortResourceRequiredPerService);
      },
    });
  }
  _deleteArmedEscortResource(escort) {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Deleting Pair ",
    });
    this.serviceRegistrationService
      .deleteArmedEscortResource(escort.id)
      .subscribe(
        async (result: any) => {
          await this.messageService.add({
            severity: "success",
            summary: "Deleted",
            detail: "Pair removed",
          });
          await this.fetchAllArmedEscortResource();
        },
        (error) => {
          this.connectionError();
        }
      );
  }

  //Vehicle
  fetchAllVehicleType(): void {
    this.vehicleTypeService
      .allVehicleType()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            var result = r.responseData;
            this.VehicleTypes = result;
            //console.log(this.VehicleTypes);
          }
        },
        (error) => this.connectionError()
      );
    this.submittingData = false;
  }
  fetchAllVehicleResource(): void {
    this.serviceRegistrationService
      .allVehicleResource()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            var result = r.responseData;
            this.VehicleResourceRequiredServices = result;
            console.log(this.VehicleResourceRequiredServices);
          }
        },
        (error) => this.connectionError()
      );
    this.submittingData = false;
  }
  deleteVehicleResource(
    VehicleResourceRequiredPerService: VehicleResourceRequiredPerService
  ): void {
    this.confirmationService.confirm({
      message:
        "Are you sure you want to delete " +
        VehicleResourceRequiredPerService.vehicleType.typeName,
      accept: () => {
        this._deleteVehicleResource(VehicleResourceRequiredPerService);
      },
    });
  }
  _deleteVehicleResource(vehicle) {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Deleting Pair ",
    });
    this.serviceRegistrationService.deleteVehicleResource(vehicle.id).subscribe(
      async (result: any) => {
        await this.messageService.add({
          severity: "success",
          summary: "Deleted",
          detail: "Pair removed",
        });
        await this.fetchAllVehicleResource();
      },
      (error) => {
        this.connectionError();
      }
    );
  }
  createResourceRequired() {
    const formData = this.ResourceRequiredPerServiceForm.value;
    const postData = {
      armedEscortTypeId: this.selectedMultiArmedEscortTypes.map((x) => x.id),
      pilotTypeId: this.selectedMultiPilotTypes.map((x) => x.id),
      commanderTypeId: this.selectedMultiCommanderTypes.map((x) => x.id),
      vehicleTypeId: this.selectedMultiVehicleTypes.map((x) => x.id),

      serviceRegistrationId: this.selectedserviceRegistration.id,
    };
    this.serviceRegistrationService
      .postResourceRequired(postData)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            this.messageService.add({
              summary: "Success",
              severity: "success",
              detail: "New Resouce Required Service(s)  created",
            });
            this.fetchAllArmedEscortResource();
            this.fetchAllCommanderResource();
            this.fetchAllPilotResource();
            this.fetchAllVehicleResource();
            this.submittingData = false;
            this.ResourceRequiredPerServiceForm.reset();
            this.userForm.reset();
            this.closeEditing();
          } else {
            this.messageService.add({
              severity: "error",
              summary: "Failed",
              detail: "Connection Error " + r.responseMsg,
            });
            this.submittingData = false;
          }
        },
        (error) => {
          this.messageService.add({
            severity: "error",
            summary: "Failed",
            detail: "Connection Error " + error.error.message,
          });
          this.submittingData = false;
        }
      );
    this.submittingData = false;
  }

  //

  closeEditing() {
    this.editingServiceRegistration = false;
    this.serviceRegistrationForm.reset();
    this.edServiceRegistration = null;

    this.submitButtonLabel = "Create";
  }
  private connectionError() {
    this.messageService.add({
      severity: "error",
      summary: "Failed",
      detail: "Connection Error, Please try again",
    });
  }
  ngOnDestroy(): void {
    this.unsubscriber$.complete();
    this.unsubscriber$.unsubscribe();
  }
}
