import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { CommonResponse } from 'src/app/interfaces/home';
import { CustomerDivisionService } from 'src/app/services/customer-division.service';
import { SharedService } from 'src/app/services/shared.service';
import { EngagementContactsService } from '../engagement-contacts/engagement-contacts.service';

@Component({
  selector: 'app-client-info-widget',
  templateUrl: './client-info-widget.component.html',
  styleUrls: ['./client-info-widget.component.scss']
})
export class ClientInfoWidgetComponent implements OnInit {
  clickEventsubscription:Subscription;
  customerDivisionInformation: any;
  fetchingClientInfo: boolean = false;
  suspectContact: any[];
  @Input() customerDivisionId;

  constructor(
      public engagementService: EngagementContactsService,
      private customerDivisionService: CustomerDivisionService,
      private sanitizer: DomSanitizer,
      private sharedService: SharedService ) {

        this.clickEventsubscription=    this.sharedService.getClickEvent().subscribe(()=>{
          this.getCustomerDivisionById(this.customerDivisionId);          
        })
        
       }

  ngOnInit(): void {
    if(this.customerDivisionId)
     {
        this.getCustomerDivisionById(this.customerDivisionId);
     }
  }

  getCustomerDivisionById(customerDivisionId: any) {
    this.fetchingClientInfo = true;
   
    this.customerDivisionService
      .getCustomerDivision(customerDivisionId)
      .subscribe(
        async (r: CommonResponse) => {
         
          var result = r.responseData;
          this.customerDivisionInformation = result;
          this.getAllContactsToCustomer(result.customerId)        
        },
        (error) => {
          
        }
      );
  }

  getAllContactsToCustomer(id) {
    this.engagementService.getAllContactsAttachedToCustomer(id).subscribe(
      async (res: any) => {
        if (res.responseCode == "00") {
          this.suspectContact = res.responseData;
        } else {
          this.suspectContact = [];
        }
      },
      (error) => {
        this.suspectContact = [];
      }
    );
  }

  transform(image) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(image);
  }
}
