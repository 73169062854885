import {
  Permission,
  PermissionEnum,
  PermissionGroupEnum,
} from "../interfaces/permission";

export class SystemPermission {
  LoggedInUserPermission: Permission[] = [];

  constructor() {
    this.LoadLoggedInUserPermission();
  }

  //Add your own system permissions to this enum - under the specific region
  private SystemPermissions: Permission[] = [
    //#region Inventory Module
    {
      PermissionGroup: PermissionGroupEnum.Inventory,
      PermissionCode: PermissionEnum.Inventory_Dashboard,
      PermissionAction: "Inventory Dashboard",
    },
    {
      PermissionGroup: PermissionGroupEnum.Inventory,
      PermissionCode: PermissionEnum.Inventory_Setup,
      PermissionAction: "Inventory Setup",
    },
    {
      PermissionGroup: PermissionGroupEnum.Inventory,
      PermissionCode: PermissionEnum.Inventory_InventoryStore,
      PermissionAction: "Inventory Store",
    },
    {
      PermissionGroup: PermissionGroupEnum.Inventory,
      PermissionCode: PermissionEnum.Inventory_CentralRequest,
      PermissionAction: "Inventory Central Request",
    },
    {
      PermissionGroup: PermissionGroupEnum.Inventory,
      PermissionCode: PermissionEnum.Inventory_RegionalRequest,
      PermissionAction: "Inventory Regional Request",
    },
    {
      PermissionGroup: PermissionGroupEnum.Inventory,
      PermissionCode: PermissionEnum.Inventory_UserRequest,
      PermissionAction: "Inventory User Request",
    },
    {
      PermissionGroup: PermissionGroupEnum.Inventory,
      PermissionCode: PermissionEnum.Inventory_BatchApproval,
      PermissionAction: "Inventory Batch Approval",
    },
    {
      PermissionGroup: PermissionGroupEnum.Inventory,
      PermissionCode: PermissionEnum.Inventory_BatchConfirmation,
      PermissionAction: "Inventory Batch Confirmation",
    },
    {
      PermissionGroup: PermissionGroupEnum.Inventory,
      PermissionCode: PermissionEnum.Inventory_StockPolicy,
      PermissionAction: "Inventory Stock Policy",
    },
    {
      PermissionGroup: PermissionGroupEnum.Inventory,
      PermissionCode: PermissionEnum.Inventory_RequestAudit,
      PermissionAction: "Inventory Request Audit",
    },
    {
      PermissionGroup: PermissionGroupEnum.Inventory,
      PermissionCode: PermissionEnum.Inventory_StockPosition,
      PermissionAction: "Inventory Stock Position",
    },
    {
      PermissionGroup: PermissionGroupEnum.Inventory,
      PermissionCode: PermissionEnum.Inventory_StockReceipt,
      PermissionAction: "Inventory Stock Receipt",
    },
    {
      PermissionGroup: PermissionGroupEnum.Inventory,
      PermissionCode: PermissionEnum.Inventory_StockIssue,
      PermissionAction: "Inventory Stock Issue",
    },
    {
      PermissionGroup: PermissionGroupEnum.Inventory,
      PermissionCode: PermissionEnum.Inventory_BatchRequestTracking,
      PermissionAction: "Inventory Batch Request Tracking",
    },
    {
      PermissionGroup: PermissionGroupEnum.Inventory,
      PermissionCode: PermissionEnum.Inventory_AggregatedView,
      PermissionAction: "Inventory Aggregated View",
    },
    //#endregion

    //#region  Convergence Module - Incidence Management
    {
      PermissionGroup: PermissionGroupEnum.Convergence_Incident,
      PermissionCode: PermissionEnum.Convergence_Incident_Setup,
      PermissionAction: "Convergence Incident Setup",
    },
    {
      PermissionGroup: PermissionGroupEnum.Convergence_Incident,
      PermissionCode: PermissionEnum.Convergence_Incident_Setting,
      PermissionAction: "Convergence Incident Setting",
    },
    {
      PermissionGroup: PermissionGroupEnum.Convergence_Incident,
      PermissionCode: PermissionEnum.Convergence_Incident_CaptureIncidence,
      PermissionAction: "Convergence Incident Capture Incidence",
    },
    {
      PermissionGroup: PermissionGroupEnum.Convergence_Incident,
      PermissionCode: PermissionEnum.Convergence_Incident_ReviewIncidence,
      PermissionAction: "Convergence Incident Review Incidence",
    },
    {
      PermissionGroup: PermissionGroupEnum.Convergence_Incident,
      PermissionCode: PermissionEnum.Convergence_Incident_ApproveIncidence,
      PermissionAction: "Convergence Incident Approve Incidence",
    },
    {
      PermissionGroup: PermissionGroupEnum.Convergence_Incident,
      PermissionCode: PermissionEnum.Convergence_Incident_NotificationTemplate,
      PermissionAction: "Convergence_Incident_NotificationTemplate",
    },
    {
      PermissionGroup: PermissionGroupEnum.Convergence_Incident,
      PermissionCode: PermissionEnum.Convergence_Incident_NotificationPolicy,
      PermissionAction: "Convergence Incident Notification Policy",
    },
    {
      PermissionGroup: PermissionGroupEnum.Convergence_Incident,
      PermissionCode: PermissionEnum.Convergence_Incident_ClientNotification,
      PermissionAction: "Convergence Incident Client Notification",
    },
    {
      PermissionGroup: PermissionGroupEnum.Convergence_Incident,
      PermissionCode: PermissionEnum.Convergence_Incident_ClientMappingByState,
      PermissionAction: "Convergence Incident Client Mapping By State",
    },
    {
      PermissionGroup: PermissionGroupEnum.Convergence_Incident,
      PermissionCode: PermissionEnum.Convergence_Incident_Dashboard,
      PermissionAction: "Convergence Incident Dashboard",
    },
    {
      PermissionGroup: PermissionGroupEnum.Convergence_Incident,
      PermissionCode: PermissionEnum.Convergence_Incident_Report,
      PermissionAction: "Convergence Incident Report",
    },
    {
      PermissionGroup: PermissionGroupEnum.Convergence_Incident,
      PermissionCode: PermissionEnum.Convergence_Incident_CaptureMobile,
      PermissionAction: "Convergence Incident Capture Mobile",
    },
    //#endregion

    //#region Convergence Module - Device Management
    {
      PermissionGroup: PermissionGroupEnum.Convergence_Device,
      PermissionCode: PermissionEnum.Convergence_Device_Setup,
      PermissionAction: "Convergence Device Setup",
    },
    {
      PermissionGroup: PermissionGroupEnum.Convergence_Device,
      PermissionCode: PermissionEnum.Convergence_Device_DeviceRegistration,
      PermissionAction: "Convergence Device Registration",
    },
    {
      PermissionGroup: PermissionGroupEnum.Convergence_Device,
      PermissionCode: PermissionEnum.Convergence_Device_MapClientDevice,
      PermissionAction: "Convergence Device Map Client Device",
    },
    {
      PermissionGroup: PermissionGroupEnum.Convergence_Device,
      PermissionCode:
        PermissionEnum.Convergence_Device_DashboardClientDistribution,
      PermissionAction: "Convergence Device Dashboard Client Distribution",
    },
    {
      PermissionGroup: PermissionGroupEnum.Convergence_Device,
      PermissionCode: PermissionEnum.Convergence_Device_DashboardControlView,
      PermissionAction: "Convergence Device Dashboard Control View",
    },
    //#endregion

    //#region Organization Module
    {
      PermissionGroup: PermissionGroupEnum.Organization,
      PermissionCode: PermissionEnum.Organization_Organogram,
      PermissionAction: "Organization Organogram",
    },
    {
      PermissionGroup: PermissionGroupEnum.Organization,
      PermissionCode: PermissionEnum.Organization_LineManagerApproval,
      PermissionAction: "Organization Line Manager Approval",
    },
    //#endregion

    //#region Secured Mobility
    {
      PermissionGroup: PermissionGroupEnum.SecuredMobility,
      PermissionCode: PermissionEnum.Secured_Mobility_Dashboard,
      PermissionAction: "Secured Mobility Dashboard",
    },
    {
      PermissionGroup: PermissionGroupEnum.SecuredMobility,
      PermissionCode: PermissionEnum.Secured_Mobility_StatusDashboard,
      PermissionAction: "Secured Mobility Status Dashboard",
    },
    {
      PermissionGroup: PermissionGroupEnum.SecuredMobility,
      PermissionCode: PermissionEnum.Secured_Mobility_ResourceType,
      PermissionAction: "Secured Mobility Resource Type",
    },
    {
      PermissionGroup: PermissionGroupEnum.SecuredMobility,
      PermissionCode: PermissionEnum.Secured_Mobility_ResourceRank,
      PermissionAction: "Secured Mobility Resource Rank",
    },
    {
      PermissionGroup: PermissionGroupEnum.SecuredMobility,
      PermissionCode: PermissionEnum.Secured_Mobility_ResourceRegistration,
      PermissionAction: "Secured Mobility Resource Registration",
    },
    {
      PermissionGroup: PermissionGroupEnum.SecuredMobility,
      PermissionCode: PermissionEnum.Secured_Mobility_ResourceLookUp,
      PermissionAction: "Secured Mobility Resource LookUp",
    },
    {
      PermissionGroup: PermissionGroupEnum.SecuredMobility,
      PermissionCode: PermissionEnum.Secured_Mobility_SMORouteAndRegion,
      PermissionAction: "Secured Mobility SMORoute And Region",
    },
    {
      PermissionGroup: PermissionGroupEnum.SecuredMobility,
      PermissionCode: PermissionEnum.Secured_Mobility_RouteStateMap,
      PermissionAction: "Secured Mobility Route State Map",
    },
    {
      PermissionGroup: PermissionGroupEnum.SecuredMobility,
      PermissionCode: PermissionEnum.Secured_Mobility_VehicleDriverMap,
      PermissionAction: "Secured Mobility Vehicle Driver Map",
    },
    {
      PermissionGroup: PermissionGroupEnum.SecuredMobility,
      PermissionCode: PermissionEnum.Secured_Mobility_ServiceRegistration,
      PermissionAction: "Secured Mobility Service Registration",
    },
    {
      PermissionGroup: PermissionGroupEnum.SecuredMobility,
      PermissionCode: PermissionEnum.Secured_Mobility_BusinessRule,
      PermissionAction: "Secured Mobility Business Rule",
    },
    {
      PermissionGroup: PermissionGroupEnum.SecuredMobility,
      PermissionCode: PermissionEnum.Secured_Mobility_PriceRegister,
      PermissionAction: "Secured Mobility Price Register",
    },
    {
      PermissionGroup: PermissionGroupEnum.SecuredMobility,
      PermissionCode: PermissionEnum.Secured_Mobility_PreferentialPricing,
      PermissionAction: "Secured Mobility Preferential Pricing",
    },
    {
      PermissionGroup: PermissionGroupEnum.SecuredMobility,
      PermissionCode: PermissionEnum.Secured_Mobility_ResourceTie,
      PermissionAction: "Secured Mobility Resource Tie",
    },
    {
      PermissionGroup: PermissionGroupEnum.SecuredMobility,
      PermissionCode: PermissionEnum.Secured_Mobility_ResourceScheduling,
      PermissionAction: "Secured Mobility Resource Scheduling",
    },
    {
      PermissionGroup: PermissionGroupEnum.SecuredMobility,
      PermissionCode: PermissionEnum.Secured_Mobility_ServiceAssignment,
      PermissionAction: "Secured Mobility Service Assignment",
    },
    //#endregion

    //#region Guard Management Module
    {
      PermissionGroup: PermissionGroupEnum.GMA,
      PermissionCode: PermissionEnum.GMA_Dashboard,
      PermissionAction: "GMA Dashboard",
    },
    {
      PermissionGroup: PermissionGroupEnum.GMA_Settings,
      PermissionCode: PermissionEnum.GMA_Settings,
      PermissionAction: "GMA Settings",
    },
    {
      PermissionGroup: PermissionGroupEnum.GMA_Settings,
      PermissionCode: PermissionEnum.GMA_ItemSetup,
      PermissionAction: "GMA Item Setup",
    },
    {
      PermissionGroup: PermissionGroupEnum.GMA_Settings,
      PermissionCode: PermissionEnum.GMA_Setup,
      PermissionAction: "GMA Setup",
    },
    {
      PermissionGroup: PermissionGroupEnum.GMA_JobAndOperatives,
      PermissionCode: PermissionEnum.GMA_CreateJob,
      PermissionAction: "GMA Create Job",
    },
    {
      PermissionGroup: PermissionGroupEnum.GMA_JobAndOperatives,
      PermissionCode: PermissionEnum.GMA_ManageJob,
      PermissionAction: "GMA Manage Job",
    },
    {
      PermissionGroup: PermissionGroupEnum.GMA_JobAndOperatives,
      PermissionCode: PermissionEnum.GMA_Redeployment,
      PermissionAction: "GMA Redeployment",
    },
    {
      PermissionGroup: PermissionGroupEnum.GMA_JobAndOperatives,
      PermissionCode: PermissionEnum.GMA_Redeployment,
      PermissionAction: "GMA Redeployment",
    },
    {
      PermissionGroup: PermissionGroupEnum.GMA_JobAndOperatives,
      PermissionCode: PermissionEnum.GMA_OperativeManagement,
      PermissionAction: "GMA Operative Management",
    },
    {
      PermissionGroup: PermissionGroupEnum.GMA_JobAndOperatives,
      PermissionCode: PermissionEnum.GMA_TrainingSchool,
      PermissionAction: "GMA Training School",
    },
    {
      PermissionGroup: PermissionGroupEnum.GMA_JobAndOperatives,
      PermissionCode: PermissionEnum.GMA_Reaccreditation,
      PermissionAction: "GMA Re-accreditation",
    },
    {
      PermissionGroup: PermissionGroupEnum.GMA_Materials,
      PermissionCode: PermissionEnum.GMA_MaterialRequest,
      PermissionAction: "GMA Material Request",
    },
    {
      PermissionGroup: PermissionGroupEnum.GMA_Materials,
      PermissionCode: PermissionEnum.GMA_MaterialRefresh,
      PermissionAction: "GMA Material Refresh",
    },
    {
      PermissionGroup: PermissionGroupEnum.GMA_Materials,
      PermissionCode: PermissionEnum.GMA_MaterialReplacement,
      PermissionAction: "GMA Material Replacement",
    },
    {
      PermissionGroup: PermissionGroupEnum.GMA_Materials,
      PermissionCode: PermissionEnum.GMA_MaterialDistribution,
      PermissionAction: "GMA Material Distribution",
    },
    {
      PermissionGroup: PermissionGroupEnum.GMA_Supervision,
      PermissionCode: PermissionEnum.GMA_SupervisionPolicy,
      PermissionAction: "GMA Supervision Policy",
    },
    {
      PermissionGroup: PermissionGroupEnum.GMA_Supervision,
      PermissionCode: PermissionEnum.GMA_ClusterReporting,
      PermissionAction: "GMA Cluster Reporting",
    },
    {
      PermissionGroup: PermissionGroupEnum.GMA_Supervision,
      PermissionCode: PermissionEnum.GMA_StationAbsenceRequest,
      PermissionAction: "GMA Station Absence Request",
    },
    {
      PermissionGroup: PermissionGroupEnum.GMA_Supervision,
      PermissionCode: PermissionEnum.GMA_StationAbsenceApproval,
      PermissionAction: "GMA Station Absence Approval",
    },
    {
      PermissionGroup: PermissionGroupEnum.GMA_OgranizationStructure,
      PermissionCode: PermissionEnum.GMA_ZonalMapView,
      PermissionAction: "GMA Zonal Map View",
    },
    {
      PermissionGroup: PermissionGroupEnum.GMA_OgranizationStructure,
      PermissionCode: PermissionEnum.GMA_OrgStructure,
      PermissionAction: "GMA Org Structure",
    },
    {
      PermissionGroup: PermissionGroupEnum.GMA_OgranizationStructure,
      PermissionCode: PermissionEnum.GMA_Location,
      PermissionAction: "GMA Location",
    },
    {
      PermissionGroup: PermissionGroupEnum.GMA_OgranizationStructure,
      PermissionCode: PermissionEnum.GMA_BeatAllocation,
      PermissionAction: "GMA Beat Allocation",
    },
    {
      PermissionGroup: PermissionGroupEnum.GMA_Listings,
      PermissionCode: PermissionEnum.GMA_Guarantor,
      PermissionAction: "GMA Guarantor",
    },
    {
      PermissionGroup: PermissionGroupEnum.GMA_Listings,
      PermissionCode: PermissionEnum.GMA_Facilitator,
      PermissionAction: "GMA Facilitator",
    },
    {
      PermissionGroup: PermissionGroupEnum.GMA_Listings,
      PermissionCode: PermissionEnum.GMA_TrainingFacility,
      PermissionAction: "GMA Training Facility",
    },
    {
      PermissionGroup: PermissionGroupEnum.GMA_Listings,
      PermissionCode: PermissionEnum.GMA_ApplicantListing,
      PermissionAction: "GMA Applicant Listing",
    },
    {
      PermissionGroup: PermissionGroupEnum.GMA,
      PermissionCode: PermissionEnum.GMA_Approval,
      PermissionAction: "GMA Approval",
    },
    {
      PermissionGroup: PermissionGroupEnum.GMA,
      PermissionCode: PermissionEnum.GMA_TrainingSchedule,
      PermissionAction: "GMA Training Schedule",
    },
    {
      PermissionGroup: PermissionGroupEnum.GMA,
      PermissionCode: PermissionEnum.GMA_Sanction,
      PermissionAction: "GMA Sanction",
    },
    {
      PermissionGroup: PermissionGroupEnum.GMA,
      PermissionCode: PermissionEnum.GMA_BioSearch,
      PermissionAction: "GMA Bio-Search",
    },
    {
      PermissionGroup: PermissionGroupEnum.GMA,
      PermissionCode: PermissionEnum.GMA_GuardRegistration,
      PermissionAction: "GMA GuardRegistration",
    },
    //#endregion

    //#region Payroll Management
    {
      PermissionGroup: PermissionGroupEnum.Payroll_Settings,
      PermissionCode: PermissionEnum.Payroll_GenericSettings,
      PermissionAction: "Payroll Generic Settings",
    },
    {
      PermissionGroup: PermissionGroupEnum.Payroll_Settings,
      PermissionCode: PermissionEnum.Payroll_SpecialSettings,
      PermissionAction: "Payroll Special Settings",
    },
    {
      PermissionGroup: PermissionGroupEnum.Payroll_Settings,
      PermissionCode: PermissionEnum.Payroll_TaxSettings,
      PermissionAction: "Payroll Tax Settings",
    },
    {
      PermissionGroup: PermissionGroupEnum.Payroll,
      PermissionCode: PermissionEnum.Payroll_PayGrade,
      PermissionAction: "Payroll Pay Grade",
    },
    {
      PermissionGroup: PermissionGroupEnum.Payroll,
      PermissionCode: PermissionEnum.Payroll_PayGroup,
      PermissionAction: "Payroll Pay Group",
    },
    {
      PermissionGroup: PermissionGroupEnum.Payroll,
      PermissionCode: PermissionEnum.Payroll_OperativeAttachment,
      PermissionAction: "Payroll Operative Attachment",
    },
    {
      PermissionGroup: PermissionGroupEnum.Payroll,
      PermissionCode: PermissionEnum.Payroll_PayrollRuns,
      PermissionAction: "Payroll Runs",
    },
    {
      PermissionGroup: PermissionGroupEnum.Payroll,
      PermissionCode: PermissionEnum.Payroll_PayrollManagement,
      PermissionAction: "Payroll Management",
    },
    {
      PermissionGroup: PermissionGroupEnum.Payroll_PayrollViews,
      PermissionCode: PermissionEnum.Payroll_PayrollVsContract,
      PermissionAction: "Payroll Vs Contract",
    },
    {
      PermissionGroup: PermissionGroupEnum.Payroll_PayrollViews,
      PermissionCode: PermissionEnum.Payroll_PayeByState,
      PermissionAction: "Payroll P.A.Y.E By State",
    },
    {
      PermissionGroup: PermissionGroupEnum.Payroll_PayrollViews,
      PermissionCode: PermissionEnum.Payroll_QtyPaidVsQtyBilled,
      PermissionAction: "Payroll Qty Paid Vs Qty Billed",
    },
    {
      PermissionGroup: PermissionGroupEnum.Payroll,
      PermissionCode: PermissionEnum.Payroll_ApprovalSetup,
      PermissionAction: "Payroll Approval Setup",
    },
    {
      PermissionGroup: PermissionGroupEnum.Payroll,
      PermissionCode: PermissionEnum.Payroll_BatchApproval,
      PermissionAction: "Payroll Batch Approval",
    },
    {
      PermissionGroup: PermissionGroupEnum.Payroll,
      PermissionCode: PermissionEnum.Payroll_SingleApproval,
      PermissionAction: "Payroll Single Approval",
    },
    {
      PermissionGroup: PermissionGroupEnum.Payroll,
      PermissionCode: PermissionEnum.Payroll_SpecialAllowanceRequest,
      PermissionAction: "Payroll Special Allowance Request",
    },
    {
      PermissionGroup: PermissionGroupEnum.Payroll,
      PermissionCode: PermissionEnum.Payroll_SpecialDeductionRequest,
      PermissionAction: "Payroll Special Deduction Request",
    },
    {
      PermissionGroup: PermissionGroupEnum.Payroll,
      PermissionCode: PermissionEnum.Payroll_SpecialRequestApproval,
      PermissionAction: "Payroll Special Request Approval",
    },
    //#endregion

    //#region Complaints Management Module
    {
      PermissionGroup: PermissionGroupEnum.Complaint,
      PermissionCode: PermissionEnum.Complaints_Registration,
      PermissionAction: "Complaints Registration",
    },
    {
      PermissionGroup: PermissionGroupEnum.Complaint,
      PermissionCode: PermissionEnum.Complaints_Handling,
      PermissionAction: "Complaints Handling",
    },
    {
      PermissionGroup: PermissionGroupEnum.Complaint,
      PermissionCode: PermissionEnum.Complaints_Tracking,
      PermissionAction: "Complaints Tracking",
    },
    {
      PermissionGroup: PermissionGroupEnum.Complaint,
      PermissionCode: PermissionEnum.Complaints_EscalationManagement,
      PermissionAction: "Complaints Escalation Management",
    },
    //#endregion

    //#region Platform Benefit Management Module
    {
      PermissionGroup: PermissionGroupEnum.PlatformBenefit,
      PermissionCode: PermissionEnum.PlatformBenefit_CreatePlatform,
      PermissionAction: "Platform Benefit Create Platform",
    },
    {
      PermissionGroup: PermissionGroupEnum.PlatformBenefit,
      PermissionCode: PermissionEnum.PlatformBenefit_CreateBenefit,
      PermissionAction: "Platform Benefit Create Benefit",
    },
    {
      PermissionGroup: PermissionGroupEnum.PlatformBenefit,
      PermissionCode: PermissionEnum.PlatformBenefit_MapPlatformService,
      PermissionAction: "Platform Benefit Map Platform Service",
    },
    {
      PermissionGroup: PermissionGroupEnum.PlatformBenefit,
      PermissionCode: PermissionEnum.PlatformBenefit_MapServiceBenefit,
      PermissionAction: "Platform Benefit Service Benefit",
    },
    {
      PermissionGroup: PermissionGroupEnum.PlatformBenefit,
      PermissionCode: PermissionEnum.PlatformBenefit_SpecialRequestWorkbench,
      PermissionAction: "Platform Special Request Workbench",
    },
    //#endregion

    {
      PermissionGroup: PermissionGroupEnum.Management,
      PermissionCode: PermissionEnum.ManagementDashboard,
      PermissionAction: "Management Dashboard",
    },
    {
      PermissionGroup: PermissionGroupEnum.Management,
      PermissionCode: PermissionEnum.Management_ProductionDashboard,
      PermissionAction: "Production Dashboard",
    },
    {
      PermissionGroup: PermissionGroupEnum.Management,
      PermissionCode: PermissionEnum.Management_ApprovalDashboard,
      PermissionAction: "Approval Dashboard",
    },

    //#region Finance Management Module
    {
      PermissionGroup: PermissionGroupEnum.Finance_Reversals,
      PermissionCode: PermissionEnum.Finance_Reversal_ReverseReceipts,
      PermissionAction: "Finance Reversal Reverse Receipts",
    },
    {
      PermissionGroup: PermissionGroupEnum.Finance_Reversals,
      PermissionCode: PermissionEnum.Finance_Reversal_ReverseInvoice,
      PermissionAction: "Finance Reversal Reverse Invoice",
    },
    {
      PermissionGroup: PermissionGroupEnum.Finance_Reversals,
      PermissionCode: PermissionEnum.Finance_Reversal_ReverseVoucher,
      PermissionAction: "Finance Reversal Reverse Voucher",
    },
    {
      PermissionGroup: PermissionGroupEnum.Finance,
      PermissionCode: PermissionEnum.Finance_ReversalOffice,
      PermissionAction: "Finance Reversal Office",
    },
    {
      PermissionGroup: PermissionGroupEnum.Finance_ExpenseTool,
      PermissionCode: PermissionEnum.Finance_ExpenseTool_ExpenseLineSetup,
      PermissionAction: "Finance Expense Tool Expense Line Setup",
    },
    {
      PermissionGroup: PermissionGroupEnum.Finance_ExpenseTool,
      PermissionCode: PermissionEnum.Finance_ExpenseTool_RequesterAttachment,
      PermissionAction: "Finance Expense Tool Requester Attachment",
    },
    {
      PermissionGroup: PermissionGroupEnum.Finance_ExpenseTool,
      PermissionCode: PermissionEnum.Finance_ExpenseTool_ApprovalSetup,
      PermissionAction: "Finance Expense Tool Approval Setup",
    },
    {
      PermissionGroup: PermissionGroupEnum.Finance_ExpenseTool,
      PermissionCode: PermissionEnum.Finance_ExpenseTool_ExpenseBudget,
      PermissionAction: "Finance Expense Tool Expense Budget",
    },
    {
      PermissionGroup: PermissionGroupEnum.Finance_ExpenseTool,
      PermissionCode: PermissionEnum.Finance_ExpenseTool_UserRequest,
      PermissionAction: "Finance Expense Tool User Request",
    },
    {
      PermissionGroup: PermissionGroupEnum.Finance_ExpenseTool,
      PermissionCode: PermissionEnum.Finance_ExpenseTool_LineApproval,
      PermissionAction: "Finance Expense Tool Line Approval",
    },
    {
      PermissionGroup: PermissionGroupEnum.Finance,
      PermissionCode: PermissionEnum.Finance_AdvancePayment_Reversal,
      PermissionAction: "Finance Advacne Payment Reversal",
    },
    {
      PermissionGroup: PermissionGroupEnum.Finance,
      PermissionCode: PermissionEnum.Finance_VoucherPosting,
      PermissionAction: "Finance Voucher Posting",
    },
    {
      PermissionGroup: PermissionGroupEnum.Finance_ExpenseTool,
      PermissionCode: PermissionEnum.Finance_ExpenseTool_Approval,
      PermissionAction: "Finance Expense Tool Expense Approval",
    },
    {
      PermissionGroup: PermissionGroupEnum.Finance_ExpenseTool,
      PermissionCode: PermissionEnum.Finance_ExpenseTool_AuthorizationSetup,
      PermissionAction: "Finance Expense Tool Expense Authorization Setup",
    },
    {
      PermissionGroup: PermissionGroupEnum.Finance_ExpenseTool,
      PermissionCode: PermissionEnum.Finance_ExpenseTool_Authorization,
      PermissionAction: "Finance Expense Tool Expense Authorization",
    },
    {
      PermissionGroup: PermissionGroupEnum.Finance_ExpenseTool,
      PermissionCode: PermissionEnum.Finance_ExpenseTool_Payables,
      PermissionAction: "Finance Expense Tool Expense Payables",
    },
    {
      PermissionGroup: PermissionGroupEnum.Finance_ExpenseTool,
      PermissionCode: PermissionEnum.Finance_ExpenseTool_Payment,
      PermissionAction: "Finance Expense Tool Expense Payment",
    },
    {
      PermissionGroup: PermissionGroupEnum.Finance_ExpenseTool,
      PermissionCode: PermissionEnum.Finance_ExpenseTool_BankPayment,
      PermissionAction: "Finance Expense Tool Bank Payment",
    },
    {
      PermissionGroup: PermissionGroupEnum.Finance_ExpenseTool,
      PermissionCode: PermissionEnum.Finance_ExpenseTool_RequestTracker,
      PermissionAction: "Finance Expense Tool Request Tracker",
    },
    {
      PermissionGroup: PermissionGroupEnum.Finance,
      PermissionCode: PermissionEnum.Finance_PrintCheque,
      PermissionAction: "Finance Print Cheque",
    },
    {
      PermissionGroup: PermissionGroupEnum.Finance,
      PermissionCode: PermissionEnum.Finance_BankAccount,
      PermissionAction: "Finance Bank Account",
    },
    {
      PermissionGroup: PermissionGroupEnum.Finance,
      PermissionCode: PermissionEnum.Finance_InvoiceUpdate,
      PermissionAction: "Finance Invoice Update",
    },
    {
      PermissionGroup: PermissionGroupEnum.Finance,
      PermissionCode: PermissionEnum.Finance_BulkSettlement,
      PermissionAction: "Finance Bulk Settlement",
    },
    {
      PermissionGroup: PermissionGroupEnum.Finance_Reversals,
      PermissionCode: PermissionEnum.Finance_CreditNoteReversal,
      PermissionAction: "Finance Reversal Credit Note Reversal",
    },
    {
      PermissionGroup: PermissionGroupEnum.Finance,
      PermissionCode: PermissionEnum.Finance_InvoiceReplacement,
      PermissionAction: "Finance Invoice Replacement",
    },
    {
      PermissionGroup: PermissionGroupEnum.Finance,
      PermissionCode: PermissionEnum.Finance_RawInflowUpload,
      PermissionAction: "Finance Raw Inflow Upload",
    },
    {
      PermissionGroup: PermissionGroupEnum.Finance,
      PermissionCode: PermissionEnum.Finance_RawInflowConfirmation,
      PermissionAction: "Finance Raw Inflow Confirmation",
    },
    {
      PermissionGroup: PermissionGroupEnum.Finance,
      PermissionCode: PermissionEnum.Finance_AutoRenewPolicy,
      PermissionAction: "Finance Auto-Renew Policy",
    },
    {
      PermissionGroup: PermissionGroupEnum.Finance,
      PermissionCode: PermissionEnum.Finance_DtrackTransmission,
      PermissionAction: "Finance Dtrack Transmission",
    },
    //#endregion

    //#region Supplier Module
    {
      PermissionGroup: PermissionGroupEnum.Supplier,
      PermissionCode: PermissionEnum.Supplier_BankAccounts,
      PermissionAction: "Supplier Bank Account",
    },
    //#endregion

    //#region Approval Module
    {
      PermissionGroup: PermissionGroupEnum.System,
      PermissionCode: PermissionEnum.System_SingleApproval,
      PermissionAction: "System Single Approval",
    },
    {
      PermissionGroup: PermissionGroupEnum.System,
      PermissionCode: PermissionEnum.System_BulkApproval,
      PermissionAction: "System Bulk Approval",
    },
    {
      PermissionGroup: PermissionGroupEnum.System,
      PermissionCode: PermissionEnum.System_ApprovalChecklist,
      PermissionAction: "System Approval Checklist",
    },
    {
      PermissionGroup: PermissionGroupEnum.System,
      PermissionCode: PermissionEnum.System_ApprovalSequence,
      PermissionAction: "System Approval Sequence",
    },
    //#endregion

    //#region Client Management Module
    {
      PermissionGroup: PermissionGroupEnum.Client,
      PermissionCode: PermissionEnum.Client_CE_ApprovalPage,
      PermissionAction: "Client CE Approval Page",
    },
    {
      PermissionGroup: PermissionGroupEnum.Client,
      PermissionCode: PermissionEnum.Client_CE_Request,
      PermissionAction: "Client CE Request",
    },
    {
      PermissionGroup: PermissionGroupEnum.Client,
      PermissionCode: PermissionEnum.Client_CE_Approval,
      PermissionAction: "Client CE Approval",
    },
    {
      PermissionGroup: PermissionGroupEnum.ClientMerge,
      PermissionCode: PermissionEnum.Client_ClientMerge_ApprovalSetup,
      PermissionAction: "Client CE Approval",
    },
    {
      PermissionGroup: PermissionGroupEnum.ClientMerge,
      PermissionCode: PermissionEnum.Client_ClientMerge_Request,
      PermissionAction: "Client CE Approval",
    },
    {
      PermissionGroup: PermissionGroupEnum.ClientMerge,
      PermissionCode: PermissionEnum.Client_ClientMerge_Approval,
      PermissionAction: "Client CE Approval",
    },
    {
      PermissionGroup: PermissionGroupEnum.Client,
      PermissionCode: PermissionEnum.Client_AmortizationView,
      PermissionAction: "Client Amortization View",
    },
    {
      PermissionGroup: PermissionGroupEnum.Client,
      PermissionCode: PermissionEnum.Client_RenewalView,
      PermissionAction: "Client Renewal View",
    },
    {
      PermissionGroup: PermissionGroupEnum.Client,
      PermissionCode: PermissionEnum.Client_ExpressProfomaInvoice,
      PermissionAction: "Client Express Profoma Invoice",
    },
    {
      PermissionGroup: PermissionGroupEnum.Client,
      PermissionCode: PermissionEnum.Client_ExpressProfomaCloseOut,
      PermissionAction: "Client Express Profoma CloseOut",
    },
    {
      PermissionGroup: PermissionGroupEnum.Client,
      PermissionCode: PermissionEnum.Client_Contract_Renew_Notification,
      PermissionAction: "Client Contract Renew Notification",
    },
    {
      PermissionGroup: PermissionGroupEnum.Client,
      PermissionCode: PermissionEnum.Client_Service_Cost_Analysis,
      PermissionAction: "Client Service Cost Analysis",
    },
    {
      PermissionGroup: PermissionGroupEnum.Client,
      PermissionCode: PermissionEnum.Client_InformationUpdate,
      PermissionAction: "Client Information Update",
    },
    {
      PermissionGroup: PermissionGroupEnum.Client,
      PermissionCode: PermissionEnum.Client_InformationUpdate_ContractService,
      PermissionAction: "Client Information Update -  Contract Service",
    },
    //#endregion
    //#region Budget  Module
    {
      PermissionGroup: PermissionGroupEnum.Budget,
      PermissionCode: PermissionEnum.Budget_SeasonSettings,
      PermissionAction: "Budget Season Settings",
    },
    {
      PermissionGroup: PermissionGroupEnum.Budget,
      PermissionCode: PermissionEnum.Budget_CycleSettings,
      PermissionAction: "Budget Cycle Settings",
    },
    {
      PermissionGroup: PermissionGroupEnum.Budget,
      PermissionCode: PermissionEnum.Budget_Distributions,
      PermissionAction: "Budget Distributions",
    },

    {
      PermissionGroup: PermissionGroupEnum.Budget_Reviewal,
      PermissionCode: PermissionEnum.Budget_Reviewer_Setup,
      PermissionAction: "Budget Reviewer Setup",
    },
    {
      PermissionGroup: PermissionGroupEnum.Budget_Reviewal,
      PermissionCode: PermissionEnum.Budget_Reviewer_Page,
      PermissionAction: "Budget Reviewer Page",
    },
    {
      PermissionGroup: PermissionGroupEnum.Budget_Approval,
      PermissionCode: PermissionEnum.Budget_Approval_Setup,
      PermissionAction: "Budget Approval Setup",
    },
    {
      PermissionGroup: PermissionGroupEnum.Budget_Approval,
      PermissionCode: PermissionEnum.Budget_Approval_Page,
      PermissionAction: "Budget Approval Page",
    },
    //#endregion
  ];

  private IntToHexValue(value: number): string {
    if (value < 0) {
      value = 0xffffffff + value + 1;
    }

    return value.toString(16).toUpperCase();
  }

  private HexToIntValue(value: string): number {
    return parseInt(value, 16);
  }

  private LoadLoggedInUserPermission() {
    this.LoggedInUserPermission = [];
    let authUserPermissions = this.authUserPermissions as string[];
    authUserPermissions.forEach((permissionCode) => {
      let permission = this.SystemPermissions.find(
        (x) => x.PermissionCode == +permissionCode
      );
      if (permission) this.LoggedInUserPermission.push(permission);
    });
  }

  public HasPermission(permissionCode: number): boolean {
    return (
      this.LoggedInUserPermission.find(
        (x) => x.PermissionCode == permissionCode
      ) != null
    );
  }

  public HasPermissionGroup(permissionGroupCode: number): boolean {
    return (
      this.LoggedInUserPermission.find(
        (x) => x.PermissionGroup == permissionGroupCode
      ) != null
    );
  }

  //#region   Helper Methods

  private get authUserPermissions() {
    return this.getSessionStorageItem("userPermissions");
  }

  private getSessionStorageItem(key: string): any {
    return JSON.parse(sessionStorage.getItem(key));
  }

  //#endregion
}
