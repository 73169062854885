import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {CommonResponse, Division, OperatingEntity, User} from '../../../interfaces/home';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ConfirmationService, MessageService} from 'primeng/api';
import {UserService} from '../../../services/user.service';
import {OperatingEntityService} from '../../../services/operating-entity.service';
import {DivisionService} from '../../../services/division.service';

@Component({
  selector: 'app-operating-entity',
  templateUrl: './operating-entity.component.html',
  styleUrls: ['./operating-entity.component.scss']
})
export class OperatingEntityComponent implements OnInit {
  @ViewChild('formWrapper') public formWrapper:ElementRef;
  fetchingOperatingEntity = true;
  users: User[];
  operatingEntityForm: FormGroup;
  operatingEntities: OperatingEntity[];
  selectedOperatingEntity: OperatingEntity;
  editingOperatingEntity= false;
  public operatingEntityCols: any[];
  public exportOperatingEntityColumns: any[];
  selectedHeadId: User;
  private edOperatingEntity: OperatingEntity;
  divisions: Division[];
  selectedDivisionId: Division;
  constructor(
      private formBuilder: FormBuilder,
      private operatingEntityService: OperatingEntityService,
      public messageService: MessageService,
      public confirmationService: ConfirmationService,
      private userService: UserService,
      private divisionService: DivisionService,
  ) { }

  ngOnInit(): void {
    this.fetchOperatingEntity();
    this.operatingEntityForm = this.formBuilder.group({
      name: ['', Validators.required],
      alias: ['', Validators.required],
      description: ['', Validators.required],
      divisionId: [null, [Validators.required]],
      headId: [null, Validators.required]
    });
    this.operatingEntityCols = [
      { field: 'name', header: 'Name' },
      { field: 'divisionId', header: 'Division' },
      { field: 'description', header: 'Description' },
      { field: 'alias', header: 'Alias' },
      { field: 'headId', header: 'Head' }
    ];
    this.exportOperatingEntityColumns = this.operatingEntityCols.map(col => ({title: col.header, dataKey: col.field}));
    this.userService.allUser().subscribe( async (res:CommonResponse) => {
      if(res.responseCode=='00'){
        this.users = res.responseData;
      }
    });
    this.divisionService.allDivisionData().subscribe( (res: CommonResponse) => {
      this.divisions = res.responseData;
    });
  }
  fetchOperatingEntity(){
    this.operatingEntityService.allData().subscribe( async (operatingEntity: CommonResponse) =>{
      this.operatingEntities = operatingEntity.responseData;
      this.fetchingOperatingEntity = false;
    }, error => {
      this.fetchingOperatingEntity = false;
      this.connectionError();
    })
  }
  private connectionError() {
    this.messageService.add({severity:'error', summary: 'Failed',
      detail:'Connection Error, Please try again'});
  }
  createOperatingEntity(){
    this.messageService.add({severity:'info', summary: 'Notice',
      detail:'Creating new Operating Entity Records'});
    const postData = {
      name: this.operatingEntityForm.get('name').value,
      description: this.operatingEntityForm.get('description').value,
      alias: this.operatingEntityForm.get('alias').value,
      divisionId: this.selectedDivisionId.id,
      headId: this.selectedHeadId.id,
    };
    this.operatingEntityService.postOperatingEntity(postData).subscribe( async () =>{
      await this.messageService.add({severity:'success', summary: 'Completed',
        detail:'New OperatingEntity Created'});
      await this.fetchOperatingEntity();
      await this.operatingEntityForm.reset();
    }, error => {
      this.messageService.add({severity:'error', summary: 'Notice',
        detail:'Unable to create new OperatingEntity at the moment'});
    });
  }

  deleteOperatingEntity(operatingEntity) {
    this.confirmationService.confirm({
      message: 'Are you sure you want to drop ' + operatingEntity.name + ". All Service Group, Service Category and Services under it will also be dropped",
      accept: () => {
        this._deleteOperatingEntity(operatingEntity);
      }
    });
  }
  _deleteOperatingEntity(operatingEntity) {
    this.messageService.add({severity:'info', summary: 'Notice',
      detail:'Dropping operatingEntity record'});
    this.operatingEntityService.deleteOperatingEntity(operatingEntity.id).subscribe( async (result:any) => {
      await this.messageService.add({severity:'success', summary: 'Dropped',
        detail:'OperatingEntity record dropped'});
      await this.fetchOperatingEntity();
    }, error => {
      this.connectionError()
    });
  }
  editOperatingEntity(operatingEntities) {
    // todo: fix editing op
    this.editingOperatingEntity = true;
    this.operatingEntityForm.addControl('operatingEntityId',  new FormControl({value: '', disabled: true}, Validators.required));
    this.edOperatingEntity = operatingEntities;
    // this.selectedDivisionId = operatingEntities.division;
    this.selectedHeadId = operatingEntities.head;
    this.operatingEntityForm.patchValue({
      name: operatingEntities.name,
      divisionId: operatingEntities.division,
      description: operatingEntities.description,
      alias: operatingEntities.alias,
      operatingEntityId: operatingEntities.id,
    });
    // this.FormIn.nativeElement.focus();
    // document.getElementById('formFields').focus();
    this.selectedHeadId = this.users.find(user => user.id === operatingEntities.head.id);
    this.selectedDivisionId = this.divisions.find(division => division.id === operatingEntities.divisionId);
    this.formWrapper.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'start' });
  }

  updateOperatingEntity() {
    // update the selected operatingEntity
    this.messageService.add({severity:'info', summary: 'Notice',
      detail:'Updating OperatingEntity details'});
    const id = this.edOperatingEntity.id;
    const postData = {
      name: this.operatingEntityForm.get('name').value,
      divisionId: this.selectedDivisionId.id,
      description: this.operatingEntityForm.get('description').value,
      alias: this.operatingEntityForm.get('alias').value,
      headId: this.selectedHeadId.id,
    };
    this.operatingEntityService.updateOperatingEntity(id, postData).subscribe( async (result: OperatingEntity) => {
      this.messageService.add({severity:'success', summary: 'Notice',
        detail:'OperatingEntity details Updated'});
      await this.fetchOperatingEntity();
      await this.closeEditing();
    }, error => {
      this.connectionError();
    })
  }

  closeEditing() {
    this.editingOperatingEntity = false;
    this.operatingEntityForm.reset();
    this.edOperatingEntity = null;
  }
}
