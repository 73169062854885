import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {Observable} from 'rxjs';
import {CommonResponse, DeliverableFulfillment} from '../interfaces/home';

@Injectable({
  providedIn: 'root'
})
export class DeliverableFulfillmentService {
  constructor(private http: HttpClient) {
  }
  baseUrl = environment.baseUrl+'DeliverableFulfillment/';
  // fetch all branch in the database
  allDeliverableFulfillment(): Observable<CommonResponse>{
    return this.http.get<CommonResponse>(this.baseUrl);
  }
  getDeliverableFulfillment(id: any):Observable<CommonResponse>{
    return this.http.get<CommonResponse>(this.baseUrl +id);
  }
  getDeliverableFulfillmentByName(name: any):Observable<CommonResponse>{
    return this.http.get<CommonResponse>(this.baseUrl + 'caption/' + name);
  }
  setIsPicked(id): Observable<any>{
    return this.http.put<CommonResponse>(this.baseUrl + 'SetIsPicked/' + id, '');
  }
  postDeliverableFulfillment(data: any){
    return this.http.post(this.baseUrl, data);
  }
  deleteDeliverableFulfillment(id: any){
    return this.http.delete(this.baseUrl +id);
  }
  updateDeliverableFulfillment(id: any, data: any): Observable<any>{
    return this.http.put(this.baseUrl +id, data);
  }
  getDeliverableProportion(id){
    return this.http.get(this.baseUrl + 'GetAssignedRation/' + id);
  }
  reAssignTask(id, data){
    return this.http.post(this.baseUrl + 'ReAssignDeliverableFulfillment/' + id, data);
  }
  getDeliverableStat(id){
    return this.http.get(this.baseUrl + 'GetDeliverableStat/' + id);
  }
  getDeliverableDashboard(id){
    return this.http.get(this.baseUrl + 'GetDeliverableDashboard/' + id);
  }
  setRequestForValidation(id, data){
    return  this.http.put(this.baseUrl + 'SetRequestedForValidation/' + id, data)
  }

  setDeliverableStatus(id: number,  data){
    return this.http.put(this.baseUrl + 'SetDeliveredStatus/' + id, data);
  }
}
