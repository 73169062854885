import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { ArmedEscortRank } from "../../interfaces/armada";
import { CommonResponse } from "src/app/interfaces/home";

@Injectable({
  providedIn: "root",
})
export class ArmedEscortRankService {
  // baseUrl = environment.armadaBaseUrl+'ArmedEscortRank/';
  baseUrl = environment.baseUrl + "ArmedEscort/";
  constructor(private httpClient: HttpClient) {}
  allArmedEscortRank(): Observable<CommonResponse> {
    return this.httpClient.get<CommonResponse>(
      this.baseUrl + "GetAllArmedEscortRanks"
    );
  }
  getArmedEscortRank(id: any): Observable<CommonResponse> {
    return this.httpClient.get<CommonResponse>(
      this.baseUrl + "GetRankById/" + id
    );
  }
  // getArmedEscortRankByName(name: any):Observable<CommonResponse>{
  //   return this.httpClient.get<CommonResponse>(this.baseUrl + 'name/' + name);
  // }
  postArmedEscortRank(data: any) {
    return this.httpClient.post(this.baseUrl + "AddNewRank", data);
  }
  delete(id: any) {
    return this.httpClient.delete(this.baseUrl + "DeleterankById/" + id);
  }
  updateArmedEscortRank(id: any, data: any) {
    return this.httpClient.put(this.baseUrl + "UpdateRankById/" + id, data);
  }
}
