import { Component, OnDestroy, OnInit } from "@angular/core";
import { DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";
import { ContractService, LeadDivision } from "../../../../../interfaces/home";
import { takeUntil } from "rxjs/operators";
import {
  CommanderUserProfile,
  JourneyStart,
  OtherPassenger,
  ServiceAssignment,
  SmtpMailer,
  SupplierServices,
} from "../../../../../interfaces/armada";
import { ConfirmationService, MessageService } from "primeng/api";
import { ServiceAssignmentService } from "../../../../../services/armada/service-assignment.service";
import { Subject } from "rxjs";
import { JourneyStartService } from "../../../../../services/armada/journey-start.service";
import { CustomerDivisionService } from "../../../../../services/armada/customer-division.service";
import { formatDate } from "@angular/common";
import { ApiLoadingService } from "../../../../../services/api-loading.service";

@Component({
  selector: "app-journey-management-plan",
  templateUrl: "./journey-management-plan.component.html",
  styleUrls: ["./journey-management-plan.component.scss"],
  providers: [MessageService, ConfirmationService, ApiLoadingService],
})
export class JourneyManagementPlanComponent implements OnInit, OnDestroy {
  contractService: ContractService;
  public dutyOfficerImage: string;
  private unsubscriber$ = new Subject<void>();
  public commanders: CommanderUserProfile[];
  public selectedCommander: CommanderUserProfile;
  public serviceAssignment: ServiceAssignment;
  public assignedVehicles: SupplierServices[];
  public serviceId: string | number;
  public emptyServiceAssignment: boolean;
  public loadingAssignment: boolean;
  public selectedVehicle: SupplierServices;
  clientInformation: LeadDivision;
  public loadingClient: boolean;
  public journeyData: JourneyStart;
  public journeyEnded: boolean;
  otherPassengers: OtherPassenger[];
  private mailMessage: SmtpMailer;
  private messageBody: string;
  public sendingEmail: boolean;
  constructor(
    private dialogConfig: DynamicDialogConfig,
    private dialogRef: DynamicDialogRef,
    private messageService: MessageService,
    private serviceAssignmentService: ServiceAssignmentService,
    private journeyStartService: JourneyStartService,
    private customerDivisionService: CustomerDivisionService,
    private confirmationService: ConfirmationService,
    private apiLoadingService: ApiLoadingService
  ) {}

  ngOnInit(): void {
    this.contractService = this.dialogConfig.data.contractService;
    this.serviceId = this.contractService.id;
    this.fetchServiceAssignment();
  }
  onClose() {
    this.dialogRef.close();
  }
  fetchServiceAssignment(): void {
    if (!this.serviceId) {
      this.messageService.add({
        severity: "error",
        summary: "Invalid Request",
        detail: "Invalid Service Selected",
      });
      this.emptyServiceAssignment = true;
      return;
    }
    this.loadingAssignment = true;
    this.serviceAssignmentService
      .getServiceAssignmentInfo(this.serviceId)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        async (result: any) => {
          this.loadingAssignment = false;
          this.serviceAssignment = result;
          this.commanders = this.serviceAssignment.commanderUserProfiles;
          this.assignedVehicles = this.serviceAssignment.vehicles;
          this.otherPassengers = this.serviceAssignment.otherPassengers;
          this.selectVehicle(this.assignedVehicles[0]);
          await this.fetchClientInfo(this.serviceAssignment.contractServiceId);
          if (this.serviceAssignment.journeyStarted === true) {
            await this.fetchJourneyStart();
          }
          this.loadingAssignment = false;
        },
        (error) => {
          this.connectionError();
          this.loadingAssignment = false;
        }
      );
  }
  selectCommander(commander: CommanderUserProfile) {
    this.selectedCommander = commander;
  }
  selectVehicle(vehicle: SupplierServices) {
    this.selectedVehicle = vehicle;
    this.selectCommander(this.selectedVehicle.commanderUserProfiles[0]);
  }

  fetchJourneyStart() {
    this.journeyStartService
      .allJourneyStart()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe((res: any) => {
        const _data: JourneyStart[] = res;
        this.journeyData = _data.find(
          (x) => x.serviceAssignmentId === this.serviceAssignment.id
        );
        if (this.journeyData) {
          this.journeyEnded = this.journeyData.endedById > 0;
        }
      });
  }
  fetchClientInfo(contractServiceId) {
    this.customerDivisionService
      .getClient(contractServiceId)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (result: any) => {
          this.clientInformation = result;
          this.loadingClient = false;
        },
        (error) => {
          this.loadingClient = false;
          this.connectionError();
        }
      );
  }
  private connectionError() {
    this.messageService.add({
      severity: "error",
      summary: "Failed",
      detail: "Connection Error, Please try again",
    });
  }
  ngOnDestroy(): void {
    this.unsubscriber$.complete();
    this.unsubscriber$.unsubscribe();
  }

  sendEmail() {
    this.confirmationService.confirm({
      header: "Confirm Request",
      message: "You are about to send the JMP to the client, Continue?",
      acceptLabel: "Yes Please",
      rejectLabel: "No",
      rejectButtonStyleClass: "pi-button-danger",
      accept: () => {
        this.processEmail();
      },
    });
  }
  processEmail() {
    // this.apiLoadingService.show('sending Mail');
    this.sendingEmail = true;
    this.messageService.add({
      detail: "Sending message to client",
      severity: "info",
      summary: "Processing",
    });
    let commandersTemp = "";
    let vehiclesTemp = "";
    for (const commander of this.commanders) {
      commandersTemp += `<div class="d-col">
                            <div class="commander"><img src="${commander.imageUrl}">
                                <div class="commander-content">
                                    <div class="commander-content-item"><span><strong>Name</strong></span><span>${commander.firstName} ${commander.lastName}</span></div>
                                    <div><span><strong>Phone</strong></span><span>${commander.mobileNumber}</span></div>
                                </div>
                            </div>
                        </div>`;
    }
    for (const assignedVehicle of this.assignedVehicles) {
      let armedEscortTemp = "";
      const _armedEscort = assignedVehicle.armedEscortProfiles;
      for (const armedEscortProfile of _armedEscort) {
        armedEscortTemp += `${armedEscortProfile.firstName} ${armedEscortProfile.lastName}, `;
      }
      vehiclesTemp +=
        `<tr>
                                    <td>${assignedVehicle.make} ${assignedVehicle.model} (${assignedVehicle.identificationNumber}}) </td>
                                    <td>` +
        armedEscortTemp +
        `</td>
                                    <td>-</td>
                                </tr>`;
    }

    this.messageBody =
      `<!DOCTYPE html>
<html>
<head>
    <meta charset="utf-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0, shrink-to-fit=no">
    <title>Journey Management Plan</title>
    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/twitter-bootstrap/4.5.2/css/bootstrap.min.css">
    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css">
    <style type="text/css">
        .welcome-footer a {
  display: inline-block;
  padding: 2px 6px;
  margin-right: 4px;
  font-size: 25px;
}

.welcome-footer {
  text-align: center;
}

.content {
  padding: 0 5px 13px;
}

.body-wrapper {
  padding: 16px 23px;
  font-size: 15px;
  min-width: 918px;
}

.header h2 {
  margin-bottom: 0;
  color: #f7f6f6;
}

.border {
  border: 1px solid #cccccc;
}

.field-set .legend {
  margin: 0 auto;
  padding: 12px 12px;
  background-color: #07104d;
  font-size: 21px;
  color: #ffffff;
}

.field-set-body {
  padding: 12px 15px;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.ml-2, .mx-2 {
  margin-left: .5rem!important;
}

.list-unstyled > li {
  margin-bottom: 8px;
}

.field-set {
  margin-bottom: 22px;
}

.d-row {
  position: relative;
  overflow: auto;
}

.d-col {
  display: inline-block;
  padding: 12px 11px;
  width: 48.99%;
  box-sizing: border-box;
}

.commander {
  margin: 6px 0;
  padding: 12px 10px;
  box-shadow: 2px 2px 5px #cccccc;
  display: flex;
  align-items: center;
}

.commander img {
  width: 91px;
  height: 91px;
  border-radius: 50%;
}

.commander-content {
  width: 67%;
  display: inline-block;
  margin-left: 18px;
}

.commander-content-item {
  margin-bottom: .5rem;
}

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
}

table {
  border-collapse: collapse;
}

.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
}

.table td, .table th {
  padding: .75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}

th {
  text-align: inherit;
}

.title {
  font-weight: 600;
  font-size: large;
  margin-right: 4px;
}

.mb-1, .my-1 {
  margin-bottom: .25rem!important;
}

hr {
  margin-top: .5rem;
  margin-bottom: .5rem;
  border: 0;
  border-top: .5px solid rgba(0,0,0,0.8);
}

.txt-bold {
  font-weight: 600;
}

.mb-0, .my-0 {
  margin-bottom: 0!important;
}


    </style>
</head>

<body>
    <div class="body-wrapper">
        <div class="header" style="padding: 13px;text-align: center;background-color: #05084d;border-bottom: 2px solid;">
            <h2>Journey Management Plan</h2>
        </div>
        <div class="content">
            <div class="d-row">
                <div class="d-col">
                    <div style="display: inline-block;text-align: center;"><img src="https://halobiz.herokuapp.com/assets/layout/images/HALOGENLOGO.png" width="87px" height="87px">
                        <p class="mb-0">Halogen Security Group</p>
                    </div>
                </div>
                <div class="d-col" style="text-align: right;">
                    <div style="display: inline-block;text-align: center;padding: 12px 7px;border: 1px solid;border-radius: 4px;">
                    <p class="mb-1">Detail Officer</p>
                        <img src="${
                          this.serviceAssignment.assignedBy.imageUrl
                        }" width="87px" height="87px">
                        <p class="mb-0">${
                          this.serviceAssignment.detailingOfficer
                        }</p>
                    </div>
                </div>
            </div>
            <div class="field-set border">
                <div class="legend"><span>Request Detail</span></div>
                <div class="field-set-body">
                    <ul class="list-unstyled">
                        <li><span class="txt-bold">Pick Up / Service Date / Time :&nbsp;</span><span class="ml-2">${formatDate(
                          this.serviceAssignment.contractService.pickupDateTime,
                          "MMM d, y, h:mm:ss a",
                          "en-NG"
                        )}</span></li>
                        <li><span class="txt-bold">Principal's Name/Code :</span><span class="ml-2">${
                          this.clientInformation?.divisionName
                        }</span></li>
                        <li><span class="txt-bold">Principal's Mobile No :</span><span class="ml-2">${
                          this.clientInformation?.phoneNumber
                        }</span></li>
                        <li><span class="txt-bold">Principal's Email Address :</span><span class="ml-2">${
                          this.clientInformation?.email
                        }</span></li>
                        <li><span class="txt-bold">PickUp Location :</span><span class="ml-2">${
                          this.serviceAssignment.contractService?.pickupLocation
                        }</span></li>
                        <li><span class="txt-bold">PickUp DateTime :</span><span class="ml-2">${formatDate(
                          this.serviceAssignment.contractService
                            ?.pickupDateTime,
                          "MMM d, y, h:mm:ss a",
                          "en-NG"
                        )}</span></li>
                        <li><span class="txt-bold">DropOff Location :</span><span class="ml-2">${
                          this.serviceAssignment.contractService
                            ?.dropofflocation
                        }</span></li>
                        <li><span class="txt-bold">DropOff DateTime :</span><span class="ml-2">${formatDate(
                          this.serviceAssignment.contractService
                            ?.dropoffDateTime,
                          "MMM d, y, h:mm:ss a",
                          "en-NG"
                        )}</span></li>
<!--                        passeners-->
                    </ul>
                </div>
            </div>
            <div class="field-set border">
                <div class="legend"><span>Service Details</span></div>
                <div class="field-set-body">
            <span class="list-title">
                ${this.contractService?.service.name}</span>
            <i class="fa fa fa-arrow-right p-mr-4"></i>
            ${formatDate(
              this.contractService.contractStartDate,
              "MMM d, y",
              "en-NG"
            )}
            -
            <span> ${formatDate(
              this.contractService.contractEndDate,
              "MMM d, y",
              "en-NG"
            )} </span>
                    </ul>
                </div>
            </div>
            <div class="field-set border">
                <div class="legend"><span>Assigned Commander Details</span></div>
                <div class="field-set-body">
                    <div class="d-row">` +
      commandersTemp +
      `</div>
                </div>
            </div>
            <div class="field-set border">
                <div class="legend"><span>Assigned Resources</span></div>
                <div class="field-set-body">
                    <div class="table-responsive">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th>Vehicles</th>
                                    <th>Armed Escort</th>
                                    <th>Other Tools</th>
                                </tr>
                            </thead>
                            <tbody>` +
      vehiclesTemp +
      `</tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div class="field-set border">
                <div class="legend"><span>Route Details</span></div>
                <div class="field-set-body">
                    <div class="d-row">
                        <div class="d-col">
                            <div class="mb-1"><span class="title">Start:&nbsp;</span><span>${
                              this.journeyData?.journeyStartAddress ?? "NA"
                            }</span></div>
                            <div><span class="title">Stop:&nbsp;</span><span>${
                              this.journeyData?.journeyEndAddress ?? "NA"
                            }</span></div>
                        </div>
                        <div class="d-col">
                            <div class="mb-1"><span class="title">Start Cordinate:&nbsp;</span><span>${
                              this.journeyData?.journeyStartCordinate ?? "NA"
                            }</span></div>
                            <div><span class="title">Stop Cordinate:&nbsp;</span><span>${
                              this.journeyData?.journeyEndCordinate ?? "NA"
                            }</span></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="footer"></div>
    </div>
    <script src="https://cdnjs.cloudflare.com/ajax/libs/jquery/3.5.1/jquery.min.js"></script>
    <script src="https://cdnjs.cloudflare.com/ajax/libs/twitter-bootstrap/4.5.2/js/bootstrap.bundle.min.js"></script>
</body>
</html>`;
    this.mailMessage = {
      recipients: [this.clientInformation?.email],
      message: this.messageBody,
      subject: "Journey Management Plan",
    };
    this.serviceAssignmentService.sendEmailToClient(this.mailMessage).subscribe(
      (res) => {
        this.messageService.add({
          detail: "Message sent to the client",
          summary: "Request Complete",
          severity: "success",
        });
        this.sendingEmail = false;
        // this.apiLoadingService.hide();
      },
      (error) => {
        this.connectionError();
        this.sendingEmail = false;
      }
    );
  }
  print() {
    window.print();
  }
}
