import { ProfileEscalationLevel } from "src/app/interfaces/profile-escalation-level";
import { ProfileEscalationLevelService } from "src/app/services/profile-escalation-level.service";
import { EscalationLevelService } from "./../../../services/escalation-level.service";
import { UserService } from "src/app/services/user.service";
import { ComplaintTypeService } from "src/app/services/complaint-type.service";
import { ComplaintType } from "src/app/interfaces/complaint-type";
import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import {
  ComplaintAttendant,
  RealEscalationMatrix,
} from "src/app/interfaces/escalation-matrix";
import { ConfirmationService, MessageService } from "primeng/api";
import { RealEscalationMatrixService } from "src/app/services/real-escalation-matrix.service";
import { User } from "src/app/interfaces/home";
import { EscalationLevel } from "src/app/interfaces/escalation-level";

@Component({
  selector: "app-real-escalation-matrix",
  templateUrl: "./real-escalation-matrix.component.html",
  styleUrls: ["./real-escalation-matrix.component.scss"],
  providers: [ConfirmationService, MessageService],
})
export class RealEscalationMatrixComponent implements OnInit {
  @ViewChild("formWrapper") public formWrapper: ElementRef;
  form: FormGroup;
  editing = false;
  fetching = true;
  escalationMatrixs: RealEscalationMatrix[];
  selectedEscalationMatrix: RealEscalationMatrix;
  cols: any[];
  private escalationMatrixToEdit: RealEscalationMatrix;
  complaintTypes: ComplaintType[];
  theComplaintType: ComplaintType;
  users: User[];
  theUser: User;
  escalationLevels: EscalationLevel[];
  theEscalationLevel: EscalationLevel;
  addedComplaintAttendants: ComplaintAttendant[];
  selectedAddedComplaintAttendant: ComplaintAttendant;
  addedCompAttendantsCols: any[];
  profileEscalationLevels: ProfileEscalationLevel[];
  theProfileEscalationLevel: ProfileEscalationLevel;

  constructor(
    fb: FormBuilder,
    public messageService: MessageService,
    public escalationMatrixService: RealEscalationMatrixService,
    public complaintTypeService: ComplaintTypeService,
    public escalationLevelService: EscalationLevelService,
    public userService: UserService,
    public profileEscalationLevelService: ProfileEscalationLevelService,
    public confirmationService: ConfirmationService
  ) {
    this.form = fb.group({
      ComplaintType: ["", Validators.required],
      Level1MaxResolutionTimeInHrs: ["", Validators.required],
      Level2MaxResolutionTimeInHrs: ["", Validators.required],
      ComplaintAttendant: [""],
      AttendantUser: [""],
      AttendantEscalationLevel: [""],
    });
  }

  ngOnInit(): void {
    this.cols = [
      { field: "complaintType", header: "ComplaintType" },
      {
        field: "level1MaxResolutionTimeInHrs",
        header: "Level1MaxResolutionTimeInHrs",
      },
      {
        field: "level2MaxResolutionTimeInHrs",
        header: "Level2MaxResolutionTimeInHrs",
      },
    ];

    this.addedCompAttendantsCols = [
      { field: "user", header: "User" },
      { field: "escalationlevel", header: "Escalation Level" },
    ];

    this.addedComplaintAttendants = [];

    this.FetchAllEscalationLevels();
    this.FetchAllComplaintTypes();
    this.FetchAllUsersWithProfileEscalationConfig();
    this.FetchAllUsers();
    this.FetchAllEscalationMatrixs();
  }

  FetchAllEscalationMatrixs() {
    this.escalationMatrixService.getAll().subscribe(
      async (data) => {
        this.escalationMatrixs = data.responseData;
        this.fetching = false;
      },
      () => {
        this.fetching = false;
        this.ConnectionError();
      }
    );
  }

  FetchAllComplaintTypes() {
    this.complaintTypeService.getAll().subscribe(
      async (data) => {
        this.complaintTypes = data.responseData;
      },
      () => {
        this.ConnectionError();
      }
    );
  }

  FetchAllUsers() {
    this.userService.allUser().subscribe(
      async (r) => {
        var data = r.responseData ?? [];
        this.users = data;
      },
      () => {
        this.ConnectionError();
      }
    );
  }

  FetchAllUsersWithProfileEscalationConfig() {
    // this.profileEscalationLevelService.geAllHandlers().subscribe(
    this.profileEscalationLevelService.getAll().subscribe(
      async (data) => {
        let i = 0;
        data.responseData.forEach((profEsc) => {
          profEsc.userProfile.fullName =
            profEsc.userProfile.lastName + " " + profEsc.userProfile.firstName;
          data[i] = profEsc;
          i++;
        });
        this.profileEscalationLevels = data.responseData;
      },
      () => {
        this.ConnectionError();
      }
    );
  }

  FetchAllEscalationLevels() {
    this.escalationLevelService.getAll().subscribe(
      async (data) => {
        this.escalationLevels = data.responseData;
      },
      () => {
        this.ConnectionError();
      }
    );
  }

  CreateEscalationMatrix() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Creating Escalation Matrix...",
    });

    const postData: RealEscalationMatrix = {
      complaintTypeId: this.theComplaintType.id,
      level1MaxResolutionTimeInHrs: this.form.get(
        "Level1MaxResolutionTimeInHrs"
      ).value,
      level2MaxResolutionTimeInHrs: this.form.get(
        "Level2MaxResolutionTimeInHrs"
      ).value,
      complaintAttendants: this.addedComplaintAttendants,
    };

    this.escalationMatrixService.create(postData).subscribe(
      async () => {
        await this.messageService.add({
          severity: "success",
          summary: "Completed",
          detail: "New Escalation Matrix Created...",
        });

        this.addedComplaintAttendants = [];
        this.FetchAllEscalationMatrixs();
        this.form.reset();
      },
      (error) => {
        console.log("Response " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to create escalation matrix at the moment.. Reason: [" +
            JSON.stringify(error.error) +
            "]",
        });
      }
    );
  }

  EditEscalationMatrix(item: RealEscalationMatrix) {
    this.editing = true;
    this.form.addControl(
      "ID",
      new FormControl({ value: "", disabled: true }, Validators.required)
    );
    this.escalationMatrixToEdit = item;
    this.form.patchValue({
      ID: item.id,
      Level1MaxResolutionTimeInHrs: item.level1MaxResolutionTimeInHrs,
      Level2MaxResolutionTimeInHrs: item.level2MaxResolutionTimeInHrs,
    });
    this.addedComplaintAttendants = item.complaintAttendants;
    this.theComplaintType = this.complaintTypes.find(
      (data) => data.id == item.complaintTypeId
    );

    this.formWrapper.nativeElement.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "start",
    });
  }

  UpdateEscalationMatrix() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Updating Selected Escalation Matrix...",
    });

    const id = this.escalationMatrixToEdit.id;
    const postData: RealEscalationMatrix = {
      complaintTypeId: this.theComplaintType.id,
      level1MaxResolutionTimeInHrs: this.form.get(
        "Level1MaxResolutionTimeInHrs"
      ).value,
      level2MaxResolutionTimeInHrs: this.form.get(
        "Level2MaxResolutionTimeInHrs"
      ).value,
      complaintAttendants: this.addedComplaintAttendants,
    };

    this.escalationMatrixService.update(id, postData).subscribe(
      async () => {
        await this.messageService.add({
          severity: "success",
          summary: "Notice",
          detail: "Update Successful!",
        });
        this.addedComplaintAttendants = [];
        await this.FetchAllEscalationMatrixs();
        await this.CloseEditing();
      },
      (error) => {
        this.messageService.add({
          severity: "error",
          summary: "Unable To Update",
          detail:
            "Unable to update escalation matrix at the moment.. Reason: [" +
            JSON.stringify(error.error) +
            "]",
        });
      }
    );
  }

  DeleteEscalationMatrix(item: RealEscalationMatrix) {
    this.confirmationService.confirm({
      message: "Are you sure you want to remove escalation matrix ",
      accept: () => {
        this.messageService.add({
          severity: "info",
          summary: "Notice",
          detail: "Removing escalation matrix...",
        });
        this.escalationMatrixService.delete(item.id).subscribe(
          async () => {
            await this.messageService.add({
              severity: "success",
              summary: "Removed",
              detail: "Removed successfully",
            });
            await this.FetchAllEscalationMatrixs();
          },
          () => {
            this.ConnectionError();
          }
        );
      },
    });
  }

  AddAttendantToList() {
    if (!this.AttedantUser) {
      this.messageService.add({
        severity: "error",
        summary: "Failed",
        detail: "You must select the user to add",
      });
      return;
    }

    let alreadyAdded = false;
    this.addedComplaintAttendants.forEach((item) => {
      if (item.userProfileId == this.AttedantUser.userProfileId) {
        alreadyAdded = true;
      }
    });

    if (alreadyAdded) {
      this.messageService.add({
        severity: "error",
        summary: "Failed",
        detail: "Attendant with the same info has already been added to list.",
      });
      return;
    }

    this.addedComplaintAttendants.push({
      userProfileId: this.AttedantUser.userProfileId,
      escalationLevelId: this.AttedantUser.escalationLevelId,
    });

    this.messageService.add({
      severity: "success",
      summary: "Added",
      detail: "Complaint Attendant added successfully",
    });
  }

  RemoveAttendant(item: ComplaintAttendant) {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Removing Complaint Attendant...",
    });

    const index = this.addedComplaintAttendants.indexOf(item);
    if (index > -1) {
      this.addedComplaintAttendants.splice(index, 1);
    }

    this.messageService.add({
      severity: "success",
      summary: "Removed",
      detail: "Complaint Attendant Removed successfully",
    });
  }

  GetUserLastName(id: number) {
    let userr = this.users.find((data) => data.id == id);
    return userr ? userr.lastName + " " + userr.firstName : "(Removed)";
  }

  GetEscalationLevelCaption(id: number) {
    let escLevel = this.escalationLevels.find((data) => data.id == id);
    return escLevel ? escLevel.caption : "(Removed)";
  }

  GetComplaintTypeCaption(id: number) {
    let compType = this.complaintTypes.find((data) => data.id == id);
    return compType ? compType.caption : "(Removed)";
  }

  CloseEditing() {
    this.editing = false;
    this.form.reset();
    this.escalationMatrixToEdit = null;
    this.addedComplaintAttendants = [];
  }

  private ConnectionError() {
    this.messageService.add({
      severity: "error",
      summary: "Failed",
      detail: "Connection Error, Please try again",
    });
  }

  get AttedantUser() {
    return this.form.get("AttendantUser").value;
  }

  // get AttendantEscalationLevel() {
  //   return this.form.get("AttendantEscalationLevel").value;
  // }
}
