import {Component, Inject, forwardRef} from '@angular/core';
import {AppMainComponent} from './app.main.component';
import { DatePipe } from '@angular/common';

@Component({
	selector: 'app-rightpanel',
	providers: [DatePipe],
    template: `
        <div class="layout-rightpanel" (click)="app.onRightPanelClick($event)">
			<div class="right-panel-header">
				<div class="title">
					<span>Today</span>
					<h1>{{myDate | date: 'fullDate' }}</h1>
				</div>
				<a href="#" class="rightpanel-exit-button" (click)="app.onRightPanelClose($event)">
					<i class="pi pi-times"></i>
				</a>
			</div>
			<div class="right-panel-content">
				<div class="right-panel-content-row">
					<div class="tasks">
						<div class="tasks-header">
							<div class="title">
								<h1>Tasks</h1>
							</div>
							<div class="tasks-info">
								<span>You have</span><span class="highlighted"> 0 tasks</span><span> today</span>
							</div>
						</div>
						<ul class="tasks-list">
							<li class="tasks-list-item">
								<div class="checkbox">
									<p-checkbox binary="true"></p-checkbox>
									<p></p>
								</div>
								<div class="tasks-day">
									<span class="time"></span>
								</div>
							</li>

							<li class="tasks-list-item">
								<div class="checkbox">
									<p-checkbox binary="true"></p-checkbox>
									<p></p>
								</div>
								<div class="tasks-day">
									<span class="time"></span>
								</div>
							</li>

							<li class="tasks-list-item">
								<div class="checkbox">
									<p-checkbox binary="true"></p-checkbox>
									<p></p>
								</div>
								<div class="tasks-day">
									<span class="time later"></span>
								</div>
							</li>

						</ul>
                    </div>
                </div>
				<div class="right-panel-content-row">
					<div class="calendar">
						<h1>Calendar</h1>
						<p-calendar [inline]="true"></p-calendar>
					</div>
				</div>
				
            </div>
        </div>
    `
})

export class AppRightPanelComponent {
	myDate = "";
    constructor(public app: AppMainComponent, private datePipe: DatePipe) {
		this.myDate = this.datePipe.transform(new Date(), 'longDate');
	}
}
