import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ConfirmationService, Message, MessageService } from "primeng/api";
import { BreadcrumbService } from "src/app/breadcrumb.service";
import {
  ContractExtensionRequest,
  CreateContractExtensionRequestVM,
  GetContractExtensionRequestApprovalsVM,
} from "src/app/interfaces/contract-extension";
import { CustomerContact } from "src/app/interfaces/customer";
import {
  CommonResponse,
  Contract,
  CustomerDivision,
} from "src/app/interfaces/home";
import { ContractExtensionService } from "src/app/services/contract-extension.service";
import { FileStorageService } from "src/app/services/file-storage.service";
import { GmaCustomerService } from "src/app/services/gma-customer.service";

@Component({
  selector: "app-contract-extension-request",
  templateUrl: "./contract-extension-request.component.html",
  styleUrls: ["./contract-extension-request.component.scss"],
  providers: [MessageService],
})
export class ContractExtensionRequestComponent implements OnInit {
  @ViewChild("formWrapper") public formWrapper: ElementRef;
  msg: Message[] = [];
  clientForm: FormGroup;
  clientContractForm: FormGroup;
  allClients: CustomerDivision[] = [];
  theClient: CustomerDivision;
  showClientInfo: boolean;
  imageSrc: string;
  clientInView: CustomerDivision;
  allIndustries: {
    key: string;
    value: string;
  }[] = [
    {
      key: "Advertising",
      value: "Advertising",
    },
    {
      key: "Agriculture",
      value: "Agriculture",
    },
    {
      key: "Banking & Finance",
      value: "Banking & Finance",
    },
    {
      key: "Construction",
      value: "Construction",
    },
    {
      key: "Education",
      value: "Education",
    },
    {
      key: "Engineering",
      value: "Engineering",
    },
    {
      key: "Health Care",
      value: "Health Care",
    },
    {
      key: "Hospitality",
      value: "Hospitality",
    },
    {
      key: "ICT",
      value: "ICT",
    },
    {
      key: "Insurance",
      value: "Insurance",
    },
    {
      key: "Manufacturing",
      value: "Manufacturing",
    },
    {
      key: "Real Estate",
      value: "Real Estate",
    },
    {
      key: "Safety & Security",
      value: "Safety & Security",
    },
    {
      key: "Telecommunication",
      value: "Telecommunication",
    },
    {
      key: "Transport",
      value: "Transport",
    },
    {
      key: "Other",
      value: "Other",
    },
  ];
  theIndustry: {
    key: string;
    value: string;
  };
  attachedClientContacts: CustomerContact[];
  allClientContracts: Contract[] = [];
  selectedClientContracts: Contract[] = [];
  theClientContract: Contract;
  carouselResponsiveOptions: any[] = [
    {
      breakpoint: "1024px",
      numVisible: 3,
      numScroll: 3,
    },
    {
      breakpoint: "768px",
      numVisible: 2,
      numScroll: 2,
    },
    {
      breakpoint: "560px",
      numVisible: 1,
      numScroll: 1,
    },
  ];
  allContractExtensionDurations: {
    key: number;
    value: string;
  }[] = [];
  theContactExtensionDuration: {
    key: number;
    value: string;
  };
  contractExtensionJustification: string;
  showUplGrid: boolean = true;
  uploadedFiles: any[] = [];
  userIsYetToClickUploadFile: boolean;
  allClientCERequests: ContractExtensionRequest[] = [];
  requestsCols: any[];
  openApprovalsDialogue: boolean;
  requestApprovals: GetContractExtensionRequestApprovalsVM[] = [];
  openDetailsDialogue: boolean;
  requestContracts: string;
  requestDuration: string;
  requestJustification: string;
  requestEvidences: string[] = [];
  loadingApprovals: boolean;

  constructor(
    private fb: FormBuilder,
    private fileStorageService: FileStorageService,
    public customerService: GmaCustomerService,
    public contractExtensionService: ContractExtensionService,
    private breadcrumbService: BreadcrumbService,
    public confirmationService: ConfirmationService,
    public messageService: MessageService
  ) {
    this.clientForm = fb.group({
      ImageUrl: [""],
      Name: ["", Validators.required],
      Email: ["", Validators.required],
      Mobile: ["", Validators.required],
      Industry: ["", Validators.required],
    });

    this.clientContractForm = fb.group({
      SelectedContract: [""],
    });
  }

  ngOnInit(): void {
    this.breadcrumbService.setItems([
      {
        label: "Client Management",
      },
      {
        label: "Contract Extension",
      },
      {
        label: "Request",
        routerLink: ["/home/client-management/ce-request"],
      },
    ]);

    this.allContractExtensionDurations = [
      {
        key: 1,
        value: "1 Month",
      },
      {
        key: 2,
        value: "2 Months",
      },
      {
        key: 3,
        value: "3 Months",
      },
      {
        key: 4,
        value: "4 Months",
      },
      {
        key: 5,
        value: "5 Months",
      },
      {
        key: 6,
        value: "6 Months",
      },
    ];

    this.requestsCols = [
      { field: "durationInMonths", header: "Duration" },
      { field: "createdBy.lastName", header: "Requested By Last Name" },
      { field: "createdBy.firstName", header: "Requested By First Name" },
      { field: "createdAt", header: "Date Requested" },
      { field: "isFullyApproved", header: "Is Fully Approved" },
    ];

    this.ResetMessageToasters();
    this.FetchAllClients();
  }

  ResetMessageToasters() {
    this.msg = [];
    this.msg.push({
      severity: "info",
      summary:
        "Only contracts with same contract service parameters are allowed for the operation.",
    });
  }

  async FetchAllClients() {
    this.customerService.GetAllClients().subscribe(
      async (data) => {
        this.allClients = data;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all clients the moment.. Reason: [" +
            error.error.message +
            "]",
        });
        this.ResetMessageToasters();
      }
    );
  }

  async FetchClientContractExtensionRequests(client: CustomerDivision) {
    this.contractExtensionService
      .GetClientContractExtensionRequests(client.id)
      .subscribe(
        async (data) => {
          if (data.responseCode != "00") {
            this.messageService.add({
              severity: "error",
              summary: "Notice",
              detail: data.responseMsg,
            });
            this.ResetMessageToasters();
            return;
          }

          this.allClientCERequests = data.responseData;
        },
        (error) => {
          console.log("Error: " + JSON.stringify(error));
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail:
              "Unable to fetch all client contract extension(s) at the moment.. Reason: [" +
              error.error.message +
              "]",
          });
          this.ResetMessageToasters();
        }
      );
  }

  async FetchClientAllowedContracts(client: CustomerDivision) {
    this.contractExtensionService
      .GetClientAllowedContracts(client.id)
      .subscribe(
        async (data) => {
          if (data.responseCode != "00") {
            this.messageService.add({
              severity: "error",
              summary: "Notice",
              detail: data.responseMsg,
            });
            this.ResetMessageToasters();
            return;
          }

          this.PopulateClientContractData(data.responseData);
        },
        (error) => {
          console.log("Error: " + JSON.stringify(error));
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail:
              "Unable to fetch all client contract(s) at the moment.. Reason: [" +
              error.error.message +
              "]",
          });
          this.ResetMessageToasters();
        }
      );
  }

  LoadClientInformation() {
    this.showClientInfo = false;
    this.clientInView = null;
    this.allClientContracts = [];
    if (this.theClient) {
      this.clientInView = this.theClient;
      this.FetchClientContractExtensionRequests(this.theClient);
      this.FetchClientAllowedContracts(this.theClient);
      if (this.theClient.customer) this.PopulateClientFormData();
      else {
        this.customerService.GetClientInformation(this.theClient.id).subscribe(
          async (data) => {
            this.theClient = data;
            this.clientInView = data;
            this.PopulateClientFormData();
          },
          (error) => {
            console.log("Error: " + JSON.stringify(error));
            this.messageService.add({
              severity: "error",
              summary: "Notice",
              detail:
                "Unable to fetch client info at the moment.. Reason: [" +
                error.error.message +
                "]",
            });
            this.ResetMessageToasters();
          }
        );
      }
    }
  }

  PopulateClientFormData() {
    this.clientForm.patchValue({
      ImageUrl: "",
      Name: this.theClient.divisionName,
      Email: this.theClient.email,
      Mobile: this.theClient.phoneNumber,
    });
    this.theIndustry = this.allIndustries.find(
      (x) => x.key == this.theClient.industry
    );
    this.attachedClientContacts = this.theClient.customer.contacts.filter(
      (x) => x.isDeleted == false
    );
    this.imageSrc = this.theClient.logoUrl;
    this.showClientInfo = true;
  }

  async PopulateClientContractData(contracts: Contract[]) {
    this.theClient.contracts = contracts;
    this.allClientContracts = this.theClient.contracts;
    this.allClientContracts.forEach(
      (x) =>
        (x.caption =
          "Contract-" +
          x.id.toString().padStart(5, "0") +
          "-" +
          (x.description ?? ""))
    );
  }

  RemoveSelectedContract(item: Contract) {
    const index = this.selectedClientContracts.indexOf(item);
    if (index > -1) {
      this.selectedClientContracts.splice(index, 1);
    }

    var tempIds = this.selectedClientContracts.map((x) => x.id);
    this.clientContractForm.reset();
    this.selectedClientContracts = this.allClientContracts.filter((x) =>
      tempIds.includes(x.id)
    );
  }

  onUploadFile(event) {
    this.uploadedFiles = [];
    for (const file of event.files) {
      this.uploadedFiles.push(file);
    }

    this.messageService.add({
      severity: "success",
      summary: "Success",
      detail: "File(s) Uploaded",
    });
    this.ResetMessageToasters();
    this.userIsYetToClickUploadFile = false;
  }

  onCancelUpload() {
    this.uploadedFiles = [];
  }

  NotifyUserToClickUpload() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Kindly ensure you click upload.",
    });

    this.ResetMessageToasters();
    this.userIsYetToClickUploadFile = true;
  }

  async SendContractExtensionRequest() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Creating Contract Extension Request...",
    });
    this.ResetMessageToasters();

    const postData: CreateContractExtensionRequestVM = {
      clientId: this.theClient.id,
      contractIds: this.selectedClientContracts.map((x) => x.id),
      duration: this.theContactExtensionDuration.key,
      justification: this.contractExtensionJustification,
      evidencesUrls: [],
    };

    if (this.uploadedFiles.length > 0) {
      this.fileStorageService.UploadMultipleFilesFromDataUrl(
        this.uploadedFiles
      );
      this.fileStorageService.onUploadFinished.subscribe(
        (resp: CommonResponse<string[]>) => {
          if (resp.responseCode != "00") {
            this.messageService.add({
              severity: "error",
              summary: "Notice",
              detail: resp.responseMsg,
            });
            this.ResetMessageToasters();
          } else {
            postData.evidencesUrls = resp.responseData;
            this._sendContractExtensionRequest(postData);
          }
        },
        (error) => {
          console.log("Error while uploading files " + error);
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail: "ERR: Unable to upload file(s) to storage",
          });
          this.ResetMessageToasters();
        }
      );
    } else this._sendContractExtensionRequest(postData);
  }

  _sendContractExtensionRequest(postData: CreateContractExtensionRequestVM) {
    this.contractExtensionService
      .CreateContractExtensionRequest(postData)
      .subscribe(
        async (data) => {
          if (data.responseCode != "00") {
            this.messageService.add({
              severity: "error",
              summary: "Notice",
              detail: data.responseMsg,
            });
            this.ResetMessageToasters();
            return;
          }

          this.messageService.add({
            severity: "success",
            summary: "Completed",
            detail: "Contract Extension Request Submitted Successfully!",
          });

          this.ResetMessageToasters();
          this.ResetContractExtensionRequestForm();
          this.FetchClientContractExtensionRequests(this.theClient);
        },
        (error) => {
          console.log("Error: " + JSON.stringify(error));
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail:
              "Unable to process contract extension request at the moment.. Reason: [" +
              (error ? error.error.message : "request failed - permission") +
              "]",
          });
          this.ResetMessageToasters();
        }
      );
  }

  async ResetContractExtensionRequestForm() {
    this.selectedClientContracts = [];
    this.theContactExtensionDuration = null;
    this.contractExtensionJustification = "";
    this.userIsYetToClickUploadFile = false;
    this.uploadedFiles = [];

    this.showUplGrid = false;
    await new Promise((resolve) => setTimeout(resolve, 2000)); // 2 secs
    this.showUplGrid = true;
  }

  FormatDateString(date: Date): string {
    let dateString = date.toString();

    try {
      // DateTimeFormatOptions
      const date: Date = new Date(dateString);
      const options: Intl.DateTimeFormatOptions = {
        year: "numeric",
        month: "long",
        day: "numeric",
      };
      const formattedDate: string = new Intl.DateTimeFormat(
        "en-US",
        options
      ).format(date);
      dateString = formattedDate;
    } catch (error) {
      console.log(error);
      console.log(date);
      console.log(
        "Error while converting date string " + date + " exception " + error
      );
    }

    return dateString;
  }

  ViewRequestDetails(item: ContractExtensionRequest) {
    this.requestContracts = "Contract-" + item.contractId;
    this.requestDuration = item.durationInMonths + " Month(s)";
    this.requestJustification = item.justification;
    this.requestEvidences = item.evidenceUrls.split(";");
    this.openDetailsDialogue = true;
  }

  ViewRequestApprovals(item: ContractExtensionRequest) {
    this.openApprovalsDialogue = true;
    this.loadingApprovals = true;
    this.contractExtensionService
      .GetContractExtensionRequestApprovals(item.id)
      .subscribe(
        async (data) => {
          if (data.responseCode != "00") {
            this.messageService.add({
              severity: "error",
              summary: "Notice",
              detail: data.responseMsg,
            });
            this.ResetMessageToasters();
            this.loadingApprovals = false;
            return;
          }

          this.requestApprovals = data.responseData;
          this.loadingApprovals = false;
        },
        (error) => {
          console.log("Error: " + JSON.stringify(error));
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail:
              "Unable to fetch contract extension request approvals at the moment.. Reason: [" +
              (error ? error.error.message : "request failed - permission") +
              "]",
          });
          this.ResetMessageToasters();
          this.loadingApprovals = false;
        }
      );
  }

  HideApprovalsDialog() {
    this.openApprovalsDialogue = false;
  }

  HideDetailsDialog() {
    this.openDetailsDialogue = false;
  }
}
