import { Component, OnInit } from "@angular/core";
import { ConfirmationService, MessageService } from "primeng/api";
import { BreadcrumbService } from "src/app/breadcrumb.service";

@Component({
  selector: "app-complaint-setup",
  templateUrl: "./complaint-setup.component.html",
  styleUrls: ["./complaint-setup.component.scss"],
  providers: [ConfirmationService, MessageService],
})
export class ComplaintSetupComponent implements OnInit {
  constructor(
    public confirmationService: ConfirmationService,
    public messageService: MessageService,
    private breadcrumbService: BreadcrumbService
  ) {
    this.breadcrumbService.setItems([
      { label: "Setup", routerLink: ["/home/complaint-setup"] },
      { label: "Complaint Setup", routerLink: ["/home/complaint-setup"] },
    ]);
  }

  ngOnInit(): void {}
}
