import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ConfirmationService, MessageService } from "primeng/api";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { BreadcrumbService } from "src/app/breadcrumb.service";
import {
  ArmedEscortType,
  CommanderType,
  PilotType,
  VehicleType,
} from "src/app/interfaces/armada";
import { CommonResponse } from "src/app/interfaces/home";
import { ArmedEscortTypeService } from "src/app/services/armada/armed-escort-type.service";
import { CommanderTypeService } from "src/app/services/armada/commander-type.service";
import { PilotTypeService } from "src/app/services/armada/pilot-type.service";
import { VehicleTypeService } from "src/app/services/armada/vehicle-type.service";

@Component({
  selector: "app-resource-type-setup",
  templateUrl: "./resource-type-setup.component.html",
  styleUrls: ["./resource-type-setup.component.scss"],
})
export class ResourceTypeSetupComponent implements OnInit, OnDestroy {
  @ViewChild("formWrapper") public formWrapper: ElementRef;
  private unsubscriber$ = new Subject<void>();

  public targetCols: any;
  private exportTargetColumns: any;
  public loading: boolean;
  submitButtonLabel: string;
  public submittingData: boolean;

  //Commander
  public commanderTypes: CommanderType[];
  public CommanderTypeForm: FormGroup;
  selectedCommanderType: CommanderType;
  editingCommanderType = false;
  private edCommanderType: CommanderType;

  //Armed Escort
  public armedEscortTypes: ArmedEscortType[];
  public armedEscortTypeForm: FormGroup;
  selectedArmedEscortType: ArmedEscortType;
  editingArmedEscortType = false;
  private edArmedEscortType: ArmedEscortType;
  //Pilot
  public PilotTypes: PilotType[];
  public pilotTypeForm: FormGroup;
  selectedPilotType: PilotType;
  editingPilotType = false;
  private edPilotType: PilotType;

  //Vehicle
  public VehicleTypes: VehicleType[];
  public vehicleTypeForm: FormGroup;
  selectedVehicleType: VehicleType;
  editingVehicleType = false;
  private edVehicleType: VehicleType;

  constructor(
    private breadcrumbService: BreadcrumbService,
    private commanderTypeService: CommanderTypeService,
    private armedEscortTypeService: ArmedEscortTypeService,
    private pilotTypeService: PilotTypeService,
    private vehicleTypeService: VehicleTypeService,
    public messageService: MessageService,
    public confirmationService: ConfirmationService,
    private formBuilder: FormBuilder
  ) {
    this.breadcrumbService.setItems([
      { label: "Setup" },
      { label: "Resource Type", routerLink: ["/setup/resource-type"] },
      // { label: 'Armed Escort Type', routerLink: ['/setup/armed-escort-type'] },
      // { label: 'Pilot Type', routerLink: ['/setup/pilot-type'] }
    ]);
  }

  ngOnInit(): void {
    this.loading = true;
    //commander
    this.targetCols = [
      { field: "typename", header: "Name" },
      { field: "typeDesc", header: "Description" },
      //{ field: 'description', header: 'Description' },
    ];
    this.exportTargetColumns = this.targetCols.map((col) => ({
      title: col.header,
      dataKey: col.field,
    }));
    this.CommanderTypeForm = this.formBuilder.group({
      typeName: ["", [Validators.required, Validators.minLength(3)]],
      typeDesc: ["", [Validators.required, Validators.minLength(3)]],
    });

    //armed escort
    this.targetCols = [
      { field: "name", header: "Name" },
      { field: "caption", header: "Caption" },
      { field: "description", header: "Description" },
    ];
    this.exportTargetColumns = this.targetCols.map((col) => ({
      title: col.header,
      dataKey: col.field,
    }));
    this.armedEscortTypeForm = this.formBuilder.group({
      name: ["", [Validators.required, Validators.minLength(3)]],
      caption: ["", [Validators.required, Validators.minLength(3)]],
      description: ["", [Validators.required, Validators.minLength(3)]],
    });

    //pilot
    this.targetCols = [
      { field: "typename", header: "Name" },
      { field: "typeDesc", header: "Description" },
      //{ field: 'description', header: 'Description' },
    ];
    this.exportTargetColumns = this.targetCols.map((col) => ({
      title: col.header,
      dataKey: col.field,
    }));
    this.pilotTypeForm = this.formBuilder.group({
      typeName: ["", [Validators.required, Validators.minLength(3)]],
      typeDesc: ["", [Validators.required, Validators.minLength(3)]],
    });

    //Vehicle
    this.targetCols = [
      { field: "typename", header: "Name" },
      { field: "typeDesc", header: "Description" },
      //{ field: 'description', header: 'Description' },
    ];
    this.exportTargetColumns = this.targetCols.map((col) => ({
      title: col.header,
      dataKey: col.field,
    }));
    this.vehicleTypeForm = this.formBuilder.group({
      typeName: ["", [Validators.required, Validators.minLength(3)]],
      typeDesc: ["", [Validators.required, Validators.minLength(3)]],
    });

    this.fetchAllArmedEscortType();
    this.fetchAllVehicleType();
    this.fetchAllCommanderType();
    this.fetchAllPilotType();
    this.submitButtonLabel = "create";
  }

  //commander
  fetchAllCommanderType(): void {
    this.commanderTypeService
      .allCommanderType()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe((r: CommonResponse) => {
        if (r.responseCode == "00") {
          var res = r.responseData;
          this.commanderTypes = res;
          this.loading = false;
        }
      });
  }

  createCommanderType() {
    this.submittingData = true;
    this.commanderTypeService
      .postCommanderType(this.CommanderTypeForm.value)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            this.messageService.add({
              summary: "Success",
              severity: "success",
              detail: "New Commander Type created",
            });
            this.fetchAllCommanderType();
            this.submittingData = false;
            this.CommanderTypeForm.reset();
          } else {
            this.messageService.add({
              severity: "error",
              summary: "Failed",
              detail: " Error: " + r.responseMsg,
            });
            this.submittingData = false;
          }
        },
        (error) => {
          this.messageService.add({
            severity: "error",
            summary: "Failed",
            detail: "Connection Error " + error.error.message,
          });
          this.submittingData = false;
        }
      );
  }
  deleteCommanderType(commanderType: CommanderType): void {
    this.confirmationService.confirm({
      message: "Are you sure you want to delete " + commanderType.typeName,
      accept: () => {
        this._deleteCommanderType(commanderType);
      },
    });
  }
  editCommanderType(commanderType: CommanderType): void {
    this.formWrapper.nativeElement.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "start",
    });
    this.editingCommanderType = true;
    this.submitButtonLabel = "Update";
    this.edCommanderType = commanderType;
    this.CommanderTypeForm.setValue({
      typeName: commanderType.typeName,
      typeDesc: commanderType.typeDesc,
    });
  }

  _deleteCommanderType(commanderType) {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Deleting Commander Type record",
    });
    this.commanderTypeService.delete(commanderType.id).subscribe(
      async (result: any) => {
        await this.messageService.add({
          severity: "success",
          summary: "Deleted",
          detail: "Commander Type record removed",
        });
        await this.fetchAllCommanderType();
      },
      (error) => {
        this.connectionError();
      }
    );
  }
  updateCommanderType(): boolean {
    this.submittingData = true;
    if (!this.edCommanderType) {
      return false;
    }
    this.commanderTypeService
      .updateCommanderType(
        this.edCommanderType.id,
        this.CommanderTypeForm.value
      )
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            this.messageService.add({
              severity: "success",
              detail: "Commander Type Updated",
              summary: "Completed",
            });
            this.submittingData = false;
            this.closeEditing();
            this.fetchAllCommanderType();
          }
        },
        (error) => {
          this.connectionError();
          this.submittingData = false;
        }
      );
  }

  //Armed Escort
  fetchAllArmedEscortType(): void {
    this.armedEscortTypeService
      .allArmedEscortType()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe((r: CommonResponse) => {
        if (r.responseCode == "00") {
          var res = r.responseData;
          this.armedEscortTypes = res;
          this.loading = false;
        }
      });
  }
  createArmedEscortType() {
    this.submittingData = true;
    this.armedEscortTypeService
      .postArmedEscortType(this.armedEscortTypeForm.value)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            this.messageService.add({
              summary: "Success",
              severity: "success",
              detail: "New Armed Escort Type created",
            });
            this.fetchAllArmedEscortType();
            this.submittingData = false;
            this.armedEscortTypeForm.reset();
          } else {
            this.messageService.add({
              severity: "error",
              summary: "Failed",
              detail: " Error:  " + r.responseMsg,
            });
            this.submittingData = false;
          }
        },
        (error) => {
          this.messageService.add({
            severity: "error",
            summary: "Failed",
            detail: "Connection Error,  " + error.error.message,
          });
          //this.fetchingItemGroup = false;
          this.submittingData = false;
        }
      );
  }
  deleteArmedEscortType(armedEscortType: ArmedEscortType): void {
    this.confirmationService.confirm({
      message: "Are you sure you want to delete " + armedEscortType.caption,
      accept: () => {
        this._deleteArmedEscortType(armedEscortType);
      },
    });
  }
  editArmedEscortType(armedEscortType: ArmedEscortType): void {
    this.formWrapper.nativeElement.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "start",
    });
    this.editingArmedEscortType = true;
    this.submitButtonLabel = "Update";
    this.edArmedEscortType = armedEscortType;
    this.armedEscortTypeForm.setValue({
      name: armedEscortType.name,
      caption: armedEscortType.caption,
      description: armedEscortType.description,
    });
  }
  _deleteArmedEscortType(armedEscortType) {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Deleting Armed Escort Type record",
    });
    this.armedEscortTypeService.delete(armedEscortType.id).subscribe(
      async (result: any) => {
        await this.messageService.add({
          severity: "success",
          summary: "Deleted",
          detail: "Armed Escort Type record removed",
        });
        await this.fetchAllArmedEscortType();
      },
      (error) => {
        this.connectionError();
      }
    );
  }

  updateArmedEscortType(): boolean {
    this.submittingData = true;
    if (!this.edArmedEscortType) {
      return false;
    }
    this.armedEscortTypeService
      .updateArmedEscortType(
        this.edArmedEscortType.id,
        this.armedEscortTypeForm.value
      )
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            this.messageService.add({
              severity: "success",
              detail: "Armed Escort Type Updated",
              summary: "Completed",
            });
            this.submittingData = false;
            this.closeEditing();
            this.fetchAllArmedEscortType();
            this.armedEscortTypeForm.reset();
          }
          else {
            this.messageService.add({
              severity: "error",
              summary: "Failed",
              detail: " Error:  " + r.responseMsg,
            });
            this.submittingData = false;
          }
        },
        (error) => {
          this.connectionError();
          this.submittingData = false;
        }
      );
  }

  //pilot
  fetchAllPilotType(): void {
    this.pilotTypeService
      .allPilotType()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe((r: CommonResponse) => {
        if (r.responseCode == "00") {
          var res = r.responseData;
          this.PilotTypes = res;
          this.loading = false;
        }
      });
  }

  createPilotType() {
    this.submittingData = true;
    this.pilotTypeService
      .postPilotType(this.pilotTypeForm.value)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            this.messageService.add({
              summary: "Success",
              severity: "success",
              detail: "New Pilot Type created",
            });
            this.fetchAllPilotType();
            this.submittingData = false;
            this.pilotTypeForm.reset();
          } else {
            this.messageService.add({
              severity: "error",
              summary: "Failed",
              detail: " Error: " + r.responseMsg,
            });
            this.submittingData = false;
          }
        },
        (error) => {
          this.messageService.add({
            severity: "error",
            summary: "Failed",
            detail: "Connection Error " + error.error.message,
          });
          this.submittingData = false;
        }
      );
  }
  deletePilotType(pilotType: PilotType): void {
    this.confirmationService.confirm({
      message: "Are you sure you want to delete " + pilotType.typeName,
      accept: () => {
        this._deletePilotType(pilotType);
      },
    });
  }
  editPilotType(pilotType: PilotType): void {
    this.formWrapper.nativeElement.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "start",
    });
    this.editingPilotType = true;
    this.submitButtonLabel = "Update";
    this.edPilotType = pilotType;
    this.pilotTypeForm.setValue({
      typeName: pilotType.typeName,
      typeDesc: pilotType.typeDesc,
    });
  }

  _deletePilotType(pilotType) {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Deleting Pilot Type record",
    });
    this.pilotTypeService.delete(pilotType.id).subscribe(
      async (result: any) => {
        await this.messageService.add({
          severity: "success",
          summary: "Deleted",
          detail: "Pilot Type record removed",
        });
        await this.fetchAllPilotType();
      },
      (error) => {
        this.connectionError();
      }
    );
  }
  updatePilotType(): boolean {
    this.submittingData = true;
    if (!this.edPilotType) {
      return false;
    }
    this.pilotTypeService
      .updatePilotType(this.edPilotType.id, this.pilotTypeForm.value)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            this.messageService.add({
              severity: "success",
              detail: "Pilot Type Updated",
              summary: "Completed",
            });
            this.submittingData = false;
            this.closeEditing();
            this.fetchAllPilotType();
            this.pilotTypeForm.reset();
          }
        },
        (error) => {
          this.connectionError();
          this.submittingData = false;
        }
      );
  }

  //Vehicle
  fetchAllVehicleType(): void {
    this.vehicleTypeService
      .allVehicleType()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe((r: CommonResponse) => {
        if (r.responseCode == "00") {
          var res = r.responseData;
          this.VehicleTypes = res;
          this.loading = false;
        }
      });
  }

  createVehicleType() {
    this.submittingData = true;
    this.vehicleTypeService
      .postVehicleType(this.vehicleTypeForm.value)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            this.messageService.add({
              summary: "Success",
              severity: "success",
              detail: "New Vehicle Type created",
            });
            this.fetchAllVehicleType();
            this.submittingData = false;
            this.vehicleTypeForm.reset();
          } else {
            this.messageService.add({
              severity: "error",
              summary: "Failed",
              detail: " Error: " + r.responseMsg,
            });
            this.submittingData = false;
          }
        },
        (error) => {
          this.messageService.add({
            severity: "error",
            summary: "Failed",
            detail: "Connection Error " + error.error.message,
          });
          this.submittingData = false;
        }
      );
  }
  deleteVehicleType(vehicleType: VehicleType): void {
    this.confirmationService.confirm({
      message: "Are you sure you want to delete " + vehicleType.typeName,
      accept: () => {
        this._deleteVehicleType(vehicleType);
      },
    });
  }
  editVehicleType(vehicleType: VehicleType): void {
    this.editingVehicleType = true;
    this.formWrapper.nativeElement.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "start",
    });
    this.submitButtonLabel = "Update";
    this.edVehicleType = vehicleType;
    console.log(this.edVehicleType.id);
    this.vehicleTypeForm.setValue({
      typeName: vehicleType.typeName,
      typeDesc: vehicleType.typeDesc,
    });
  }

  _deleteVehicleType(vehicleType) {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Deleting Vehicle Type record",
    });
    this.vehicleTypeService.delete(vehicleType.id).subscribe(
      async (result: any) => {
        await this.messageService.add({
          severity: "success",
          summary: "Deleted",
          detail: "Vehicle Type record removed",
        });
        await this.fetchAllVehicleType();
      },
      (error) => {
        this.connectionError();
      }
    );
  }
  updateVehicleType(): boolean {
    this.submittingData = true;
    if (!this.edVehicleType) {
      return false;
    }
    this.vehicleTypeService
      .updateVehicleType(this.edVehicleType.id, this.vehicleTypeForm.value)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            this.messageService.add({
              severity: "success",
              detail: "Vehicle Type Updated",
              summary: "Completed",
            });
            this.submittingData = false;
            this.closeEditing();
            this.fetchAllVehicleType();
            this.vehicleTypeForm.reset();
          }
          else {
            this.messageService.add({
              severity: "error",
              summary: "Failed",
              detail: " Error:  " + r.responseMsg,
            });
            this.submittingData = false;
          }
        },
        (error) => {
          this.connectionError();
          this.submittingData = false;
        }
      );
  }
  closeEditing() {
    this.editingCommanderType = false;
    this.CommanderTypeForm.reset();
    this.edCommanderType = null;
    //
    this.editingPilotType = false;
    this.pilotTypeForm.reset();
    this.edPilotType = null;
    //
    this.editingArmedEscortType = false;
    this.armedEscortTypeForm.reset();
    this.edArmedEscortType = null;
    //
    this.editingVehicleType = false;
    this.vehicleTypeForm.reset();
    this.edVehicleType = null;
    this.submitButtonLabel = "Create";
  }

  private connectionError() {
    this.messageService.add({
      severity: "error",
      summary: "Failed",
      detail: "Connection Error, Please try again",
    });
  }

  ngOnDestroy(): void {
    this.unsubscriber$.complete();
    this.unsubscriber$.unsubscribe();
    //throw new Error('Method not implemented.');
  }
}
