import {
  CommonResponse,
  ContractService,
  CustomerDivision,
  GroupType,
  Location,
  ServiceRelationshipEnum,
} from "./../../../interfaces/home";
import {
  BankEnum,
  BiometricCaptureAction,
  EducationLevelEnum,
  MaritalStatusEnum,
  NextOfKinRelationshipEnum,
} from "./../../../interfaces/premployment";
import { State } from "src/app/interfaces/home";
import {
  ApplicantEvaluation,
  ApplicationEvaluationReview,
  CalculateApplicantScore,
  CreateNewJobPostNote,
  EvaluationStageEnum,
  ReviewApplicantPhysicalAssessment,
  ManageJobsPreview,
  Note,
  ReviewCognitivePhysicalAssessment,
  CreateGuarantor,
  ReviewApplicantGuarantorAsseessment,
  ReviewApplicantBackgroundAsseessment,
  ApplicationRequirementTypeEnum,
  TrainingEvaluationReview,
  CreateGuardLocationDeployment,
  AddApplicantBioData,
  GeneralPhaseChecks,
  OperativeGuarantor,
  ReviewApplicantOperativeGuarantorAssessment,
} from "./../../../interfaces/employment";
import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ConfirmationService, Message, MessageService } from "primeng/api";
import { BreadcrumbService } from "src/app/breadcrumb.service";
import { GmaEmploymentService } from "src/app/services/gma-employment.service";
import { GenderEnum, JobPost } from "src/app/interfaces/premployment";
import { StateService } from "src/app/services/state.service";
import { finalize } from "rxjs/operators";
import { FileStorageService } from "src/app/services/file-storage.service";

@Component({
  selector: "app-gma-manage-job",
  templateUrl: "./gma-manage-job.component.html",
  styleUrls: ["./gma-manage-job.component.scss"],
  providers: [MessageService],
})
export class GmaManageJobComponent implements OnInit {
  @ViewChild("formWrapper") public formWrapper: ElementRef;
  noteForm: FormGroup;
  formDecline: FormGroup;
  formApproveOverride: FormGroup;
  allManageJobs: ManageJobsPreview[];
  theManageJob: ManageJobsPreview;
  jobBatchDetailTable: {
    name: string;
    data: string;
    hasButton?: boolean;
    numericData?: number;
    holdingData?: ApplicantEvaluation[];
  }[];
  selectedJobBatchDetailTable: {
    name: string;
    data: string;
    hasButton?: boolean;
    numericData?: number;
    holdingData?: ApplicantEvaluation[];
  };
  jobBatchInfoTable: {
    name: string;
    data: string;
  }[];
  selectedJobBatchInfoTable: {
    name: string;
    data: string;
  };
  jobBatchPercentage: {
    general: number;
    training: number;
    postTraining: number;
    onboarding: number;
  };
  jobBatchRecruitmentTable: {
    name: string;
    data: ApplicantEvaluation[];
  }[];
  selectedJobBatchRecruitmentTable: {
    name: string;
    data: ApplicantEvaluation[];
  };
  allNotes: Note[];
  selectedNote: Note;
  showRightSide: boolean;
  isInReview: boolean = false;
  allGenders: {
    key: number;
    value: string;
  }[];
  theApplicationPhaseGender: {
    key: number;
    value: string;
  };
  allStates: State[];
  theApplicationPhaseState: State;
  theApplicationPhaseStateOfResidence: State;
  allAgeRanges: {
    key: number;
    value: string;
  }[];
  theApplicationPhaseAgeRange: {
    key: number;
    value: string;
  };
  applicantsAppReqCheckList: {
    key: string;
    value: string;
    score: string;
  }[];
  selectedAppReqCheckList: {
    key: string;
    value: string;
    score: string;
  };
  allMaritalStatus: {
    key: number;
    value: string;
  }[];
  theApplicationPhaseMaritalStatus: {
    key: number;
    value: string;
  };
  applicationPhaseApplicants: ApplicantEvaluation[];
  selectedAppPhaseApplicant: ApplicantEvaluation;
  generalPhaseApplicants: ApplicantEvaluation[];
  selectedGenPhaseApplicant: ApplicantEvaluation;
  trainingPhaseApplicants: ApplicantEvaluation[];
  selectedTrainPhaseApplicant: ApplicantEvaluation;
  postTrainingPhaseApplicants: ApplicantEvaluation[];
  selectedPostTrainPhaseApplicant: ApplicantEvaluation;
  onboardingPhaseApplicants: ApplicantEvaluation[];
  selectedOnboardingPhaseApplicant: ApplicantEvaluation;
  appPhaseCols: any[];
  genPhaseCols: any[];
  trainPhaseCols: any[];
  postTrainPhaseCols: any[];
  onboardingTrainPhaseCols: any[];
  openViewApplicantsDialogue: boolean;
  openAppRequirementDialogue: boolean;
  applicantsToViewList: ApplicantEvaluation[];
  selectedApplicantToView: ApplicantEvaluation;
  applicantsToViewCols: any[];
  openAddNewNoteDialogue: boolean;
  isCreatingNote: boolean;
  appPhaseApplicantInView: ApplicantEvaluation;
  selectedAppPhaseApp: any;
  genPhaseApplicantInView: ApplicantEvaluation;
  selectedGenPhaseApp: any;
  trainPhaseApplicantInView: ApplicantEvaluation;
  selectedTrainPhaseApp: any;
  postTrainPhaseApplicantInView: ApplicantEvaluation;
  selectedPostTrainPhaseApp: any;
  onboardingTrainPhaseApplicantInView: ApplicantEvaluation;
  tempApplicationPhaseApplicants: ApplicantEvaluation[] = [];
  tempGeneralPhaseApplicants: ApplicantEvaluation[] = [];
  tempTrainingPhaseApplicants: ApplicantEvaluation[] = [];
  tempPostTrainingPhaseApplicants: ApplicantEvaluation[] = [];
  tempOnboardingTrainingPhaseApplicants: ApplicantEvaluation[] = [];
  failureReason: string;
  currentPhaseInView: number;
  uploadedSupportDoc: any[] = [];
  uploadedSupportDocCogn: any[] = [];
  uploadedSupportDocGua: any[] = [];
  uploadedSupportDocBG: any[] = [];
  uploadedTrainingResults: any[] = [];
  userIsYetToClickUploadSupportDoc: boolean;
  userIsYetToClickUploadSupportDocCogn: boolean;
  userIsYetToClickUploadSupportDocGua: boolean;
  userIsYetToClickUploadSupportDocBG: boolean;
  userIsYetToClickUploadGuaDeclare: boolean;
  userIsYetToClickUploadTrainingResult: boolean;
  PhyScore: string;
  CognScore: string;
  TrainingScore: string;
  BGScore: string;
  GuaScore: string;
  GuaFirstName: string;
  GuaLastName: string;
  GuaMobile: string;
  GuaEmail: string;
  GuaAddress: string;
  GuaOccupation: string;
  uploadedGuarantorDeclarations: any[] = [];
  GuaGrade: number;
  msgs: Message[] = [];
  imageSrcGua: string;
  criminalHistoryRadioButton: number;
  educationConfirmationRadioButton: number;
  preemployConfirmationRadioButton: number;
  properConfirmationRadioButton: number;
  uploadedGuarantorImage: any[] = [];
  guarantorsToAddList: CreateGuarantor[] = [];
  AppScore: string;
  AppScoreLoading: boolean = true;
  openDeclineDialogue: boolean;
  declineText: string;
  declineCode: number;
  approveOverrideCode: number;
  approveOverrideText: string;
  trainingClassName: string = "Loading...";
  jobBatchCode: string = "Loading...";
  appmsgs: Message[] = [];
  openApproveOverrideDialogue: boolean;
  bgmsgs: Message[] = [];
  cognmsgs: Message[] = [];
  phymsgs: Message[] = [];
  trainmsgs: Message[] = [];
  allFetchedClientsData: CustomerDivision[];
  allClientTypes: GroupType[];
  theClientType: GroupType;
  allClients: CustomerDivision[];
  theClient: CustomerDivision;
  allLocations: Location[];
  theLocation: Location;
  allContractServices: ContractService[];
  theContractService: ContractService;
  locDeployTable: {
    name: string;
    data: string;
  }[];
  selectedLocDeployTable: {
    name: string;
    data: string;
  };
  finalDeploymentStatus: boolean = false;
  openViewTrainScoreDialogue: boolean = false;
  applicantInViewTrainingScores: {
    name: string;
    data: string;
  }[];
  selectedApplicantScore: {
    name: string;
    data: string;
  };
  openCapturePrintsDialogue: boolean = false;
  allBiometricCaptures: {
    key: number;
    value: string;
  }[];
  theBiometricCapture: {
    key: number;
    value: string;
  };
  bioImgSrc: string = "assets/gma/PlaceFinger.bmp";
  applicantInViewBioData: boolean[];
  leftBioPercentage: number = 0;
  rightBioPercentage: number = 0;
  generalPhaseChecksForJob: GeneralPhaseChecks;
  scoreKeeper: {
    requirementId: number;
    score: number;
    indicator: string;
  }[] = [];
  showGuaDeclarationForm: boolean = true;
  allOperativeGuarantors: OperativeGuarantor[];
  selectedOperativeGuarantor: OperativeGuarantor[];
  isPreLoaded: boolean = false;
  preLoadedJobID: number;

  constructor(
    private fb: FormBuilder,
    public stateService: StateService,
    public employmentService: GmaEmploymentService,
    private breadcrumbService: BreadcrumbService,
    public confirmationService: ConfirmationService,
    public fileStorageService: FileStorageService,
    public messageService: MessageService
  ) {
    this.noteForm = fb.group({
      Caption: ["", Validators.required],
      Detail: ["", Validators.required],
    });

    this.formDecline = fb.group({
      Comment: ["", Validators.required],
    });

    this.formApproveOverride = fb.group({
      Comment: ["", Validators.required],
    });
  }

  async ngOnInit(): Promise<void> {
    this.breadcrumbService.setItems([
      {
        label: "Guard Management",
        routerLink: ["/home/guard-management/item-settings"],
      },
      {
        label: "Manage Job",
        routerLink: ["/home/guard-management/manage-job"],
      },
    ]);

    //Preload Config
    let preloadId = localStorage.getItem("Preload_JobPostID");
    if (preloadId) {
      this.isPreLoaded = true;
      this.preLoadedJobID = +preloadId;
      localStorage.removeItem("Preload_JobPostID");
    }

    this.jobBatchDetailTable = [
      {
        name: "Job Title",
        data: "Data",
      },
      {
        name: "Job Code",
        data: "Data",
      },
      {
        name: "Job Description",
        data: "Data",
      },
      {
        name: "Post Date",
        data: "Data",
      },
      {
        name: "Expiry Date",
        data: "Data",
      },
      {
        name: "Status",
        data: "Data",
      },
      {
        name: "Total Applicants",
        data: "0",
      },
      {
        name: "Dropped Applicants",
        data: "Data",
        numericData: 0,
        hasButton: true,
        holdingData: [],
      },
    ];

    this.jobBatchRecruitmentTable = [
      {
        name: "Application Assessment",
        data: null,
      },
      {
        name: "General Assessment",
        data: null,
      },
      {
        name: "Training Result",
        data: null,
      },
      {
        name: "Post Training Evaluation",
        data: null,
      },
      {
        name: "Onboarding",
        data: null,
      },
    ];

    this.jobBatchInfoTable = [
      {
        name: "Job Title",
        data: "data",
      },
      {
        name: "Job Code",
        data: "data",
      },
      {
        name: "Job Description",
        data: "data",
      },
      {
        name: "Application Count",
        data: "data",
      },
    ];

    this.allGenders = [
      {
        key: GenderEnum.Male,
        value: "Male",
      },
      {
        key: GenderEnum.Female,
        value: "Female",
      },
      {
        key: GenderEnum.Unspecified,
        value: "Unspecified",
      },
    ];

    this.allAgeRanges = [
      {
        key: 1,
        value: "Below - 20",
      },
      {
        key: 2,
        value: "21 - 40",
      },
      {
        key: 3,
        value: "41 - above",
      },
    ];

    this.allMaritalStatus = [
      {
        key: MaritalStatusEnum.Single,
        value: "Single",
      },
      {
        key: MaritalStatusEnum.Married,
        value: "Married",
      },
      {
        key: MaritalStatusEnum.Divorced,
        value: "Divorced",
      },
      {
        key: MaritalStatusEnum.Widowed,
        value: "Widowed",
      },
    ];

    this.allBiometricCaptures = [
      {
        key: BiometricCaptureAction.Left_Pinky,
        value: "Left Pinky",
      },
      {
        key: BiometricCaptureAction.Left_RingFinger,
        value: "Left Ring Finger",
      },
      {
        key: BiometricCaptureAction.Left_MiddleFinger,
        value: "Left Middle Finger",
      },
      {
        key: BiometricCaptureAction.Left_IndexFinger,
        value: "Left Index Finger",
      },
      {
        key: BiometricCaptureAction.Left_Thumb,
        value: "Left Thumb",
      },
      {
        key: BiometricCaptureAction.Right_Thumb,
        value: "Right Thumb",
      },
      {
        key: BiometricCaptureAction.Right_IndexFinger,
        value: "Right Index Finger",
      },
      {
        key: BiometricCaptureAction.Right_MiddleFinger,
        value: "Right Middle Finger",
      },
      {
        key: BiometricCaptureAction.Right_RingFinger,
        value: "Right Ring Finger",
      },
      {
        key: BiometricCaptureAction.Right_Pinky,
        value: "Right Pinky",
      },
    ];

    this.locDeployTable = [
      {
        name: "Name",
        data: "Data",
      },
      {
        name: "Address",
        data: "Data",
      },
      {
        name: "Client",
        data: "Data",
      },
      {
        name: "Avalaible Slots",
        data: "Data",
      },
      {
        name: "Contract Service",
        data: "Data",
      },
    ];

    this.applicantInViewTrainingScores = [
      {
        name: "Martial Art",
        data: "Nil",
      },
      {
        name: "Greetings",
        data: "Nil",
      },
      {
        name: "Self Defence",
        data: "Nil",
      },
      {
        name: "Overall",
        data: "Nil",
      },
    ];

    this.applicantInViewBioData = [
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
    ];

    this.jobBatchPercentage = {
      general: 70,
      training: 60,
      postTraining: 55,
      onboarding: 33,
    };

    this.applicantsToViewCols = [
      { field: "applicantsFullName", header: "ApplicantsFullName" },
      { field: "createdAt", header: "CreatedAt" },
    ];

    this.appPhaseCols = [
      { field: "personalInfo.lastname", header: "PersonalInfo.lastname" },
      { field: "personalInfo.firstname", header: "PersonalInfo.firstname" },
      { field: "personalInfo.street", header: "PersonalInfo.street" },
      { field: "personalInfo.mobile", header: "PersonalInfo.mobile" },
    ];

    this.genPhaseCols = [
      { field: "personalInfo.lastname", header: "PersonalInfo.lastname" },
      { field: "personalInfo.firstname", header: "PersonalInfo.firstname" },
      { field: "personalInfo.street", header: "PersonalInfo.street" },
      { field: "personalInfo.mobile", header: "PersonalInfo.mobile" },
    ];

    this.trainPhaseCols = [
      { field: "personalInfo.lastname", header: "PersonalInfo.lastname" },
      { field: "personalInfo.firstname", header: "PersonalInfo.firstname" },
      { field: "personalInfo.street", header: "PersonalInfo.street" },
      { field: "personalInfo.mobile", header: "PersonalInfo.mobile" },
    ];

    this.postTrainPhaseCols = [
      { field: "personalInfo.lastname", header: "PersonalInfo.lastname" },
      { field: "personalInfo.firstname", header: "PersonalInfo.firstname" },
      { field: "personalInfo.street", header: "PersonalInfo.street" },
      { field: "personalInfo.mobile", header: "PersonalInfo.mobile" },
    ];

    this.onboardingTrainPhaseCols = [
      { field: "personalInfo.lastname", header: "PersonalInfo.lastname" },
      { field: "personalInfo.firstname", header: "PersonalInfo.firstname" },
      { field: "personalInfo.street", header: "PersonalInfo.street" },
      { field: "personalInfo.mobile", header: "PersonalInfo.mobile" },
    ];

    this.TempReset();

    this.PhyScore = "Yet to be calculated";
    this.CognScore = "Yet to be calculated";
    this.TrainingScore = "Yet to be calculated";
    this.GuaScore = "Yet to be calculated";
    this.BGScore = "Yet to be calculated";
    this.AppScore = "Loading...";

    await this.FetchAllJobPosts();
    await this.FetchAllStates();
    await this.FetchAllClients();
    await this.FetchAllOperativeGuarantors();
  }

  RunAppPhaseMessageJob(pass: boolean) {
    this.appmsgs = [];
    if (pass) {
      this.appmsgs.push({
        severity: "success",
        summary: "Notice:",
        detail: "Recommendation: User Passed",
      });
    } else {
      this.appmsgs.push({
        severity: "error",
        summary: "Notice:",
        detail: "Recommendation: User Failed",
      });
    }
  }

  RunAppPhaseFormMessageJob() {
    this.bgmsgs = [];
    this.cognmsgs = [];
    this.phymsgs = [];
    this.trainmsgs = [];

    this.bgmsgs.push({
      severity: "info",
      summary: "Notice:",
      detail: "You must upload Background Declaration Form.",
    });

    this.cognmsgs.push({
      severity: "info",
      summary: "Notice:",
      detail: "You must upload at least one(1) supporting doc.",
    });

    this.phymsgs.push({
      severity: "info",
      summary: "Notice:",
      detail: "You must upload at least one(1) supporting doc.",
    });

    this.trainmsgs.push({
      severity: "info",
      summary: "Notice:",
      detail: "You must upload training result.",
    });
  }

  GetApplicationRequirementTypeLabel(type: number): string {
    if (type == ApplicationRequirementTypeEnum.IsDiabetic) return "Is Diabetic";
    else if (type == ApplicationRequirementTypeEnum.HasArthritus)
      return "Has Arthritus";
    else if (type == ApplicationRequirementTypeEnum.IsHypertensive)
      return "Is Hypertensive";
    else if (type == ApplicationRequirementTypeEnum.IsGambling)
      return "Is Gambling";
    else if (type == ApplicationRequirementTypeEnum.IsSmoking)
      return "Is Smoking";
    else if (type == ApplicationRequirementTypeEnum.IsDrinking)
      return "Is Drinking";
    else if (type == ApplicationRequirementTypeEnum.IsSufferingUnknownDisease)
      return "Is Suffering Unknown Disease";
    else if (type == ApplicationRequirementTypeEnum.IsExMilitant)
      return "Is Ex-Militant";
    else if (type == ApplicationRequirementTypeEnum.HasCombatTraining)
      return "Has Combat Training";
    else if (type == ApplicationRequirementTypeEnum.HasBeenArrested)
      return "Has Been Arrested";
    else if (type == ApplicationRequirementTypeEnum.HasBeenConvicted)
      return "Has Been Convicted";
    else if (type == ApplicationRequirementTypeEnum.HasBeenAskedToResign)
      return "Has BeenAsked To Resign";
    else return "Invalid";
  }

  async FetchAllJobPosts() {
    this.employmentService.GetManageJobsList().subscribe(
      async (data) => {
        this.allManageJobs = data;
        if (this.isPreLoaded) {
          this.theManageJob = this.allManageJobs.find(
            (x) => x.job.id == this.preLoadedJobID
          );
          this.LoadJobBatch();
          this.showRightSide = true;
          this.ProcessApplicants(this.jobBatchRecruitmentTable[0].data);
        }
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all mange jobs data at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  async FetchAllOperativeGuarantors() {
    this.employmentService.GetAllOperativeGuarantors().subscribe(
      async (data) => {
        this.allOperativeGuarantors = data;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all operative guarantors at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  FetchJobPostNotes() {
    this.employmentService
      .GetAllJobPostNotes(this.theManageJob.job.id)
      .subscribe(
        async (data) => {
          this.allNotes = data;
        },
        (error) => {
          console.log("Error: " + JSON.stringify(error));
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail:
              "Unable to fetch all job post notes at the moment.. Reason: [" +
              error
                ? error.error.message
                : "request failed - permission" + "]",
          });
        }
      );
  }

  GetJobGeneralPhaseChecks(jobPostId: number) {
    let applicantEvaluation = this.genPhaseApplicantInView
      ? this.genPhaseApplicantInView
      : this.trainPhaseApplicantInView;
    if (applicantEvaluation) {
      this.employmentService
        .GetAllGeneralPhaseChecks(
          jobPostId,
          applicantEvaluation ? applicantEvaluation.personalInfo.gender : 1,
          applicantEvaluation.personalInfo.stateId
        )
        .subscribe(
          async (data) => {
            this.generalPhaseChecksForJob = data;
          },
          (error) => {
            console.log("Error: " + JSON.stringify(error));
            this.messageService.add({
              severity: "error",
              summary: "Notice",
              detail:
                "Unable to fetch all general phase checks at the moment.. Reason: [" +
                error
                  ? error.error.message
                  : "request failed - permission" + "]",
            });
          }
        );
    } else {
      console.log("Err: Missing Info");
    }
  }

  async FetchAllStates() {
    this.employmentService.GetAllStates().subscribe(
      async (data) => {
        this.allStates = data;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all states at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  LoadJobBatch() {
    this.jobBatchDetailTable[0].data = this.theManageJob.job.title;
    this.jobBatchDetailTable[1].data = this.theManageJob.job.jobCode;
    this.jobBatchDetailTable[2].data = this.theManageJob.job.description;
    this.jobBatchDetailTable[3].data = this.theManageJob.job.createdAt
      .toString()
      .substring(0, 10);
    this.jobBatchDetailTable[4].data = this.theManageJob.job.validityStop
      .toString()
      .substring(0, 10);
    this.jobBatchDetailTable[5].data = this.GetJobStatus(this.theManageJob.job);
    this.jobBatchDetailTable[6].data = this.theManageJob.totalApplicants + "";
    this.jobBatchDetailTable[7].numericData =
      this.theManageJob.droppedApplicants.length;
    this.jobBatchDetailTable[7].holdingData =
      this.theManageJob.droppedApplicants;
    this.jobBatchPercentage = {
      general: this.theManageJob.examinationAssessmentPercentage,
      training: this.theManageJob.trainingPercentage,
      postTraining: this.theManageJob.postTrainingPercentage,
      onboarding: this.theManageJob.onboardingPercentage,
    };
    this.allNotes = this.theManageJob.notes;
    this.jobBatchRecruitmentTable[0].data =
      this.theManageJob.applicantsInApplicationPhase;
    this.jobBatchRecruitmentTable[1].data =
      this.theManageJob.applicantsInGeneralPhase;
    this.jobBatchRecruitmentTable[2].data =
      this.theManageJob.applicantsInTrainingPhase;
    this.jobBatchRecruitmentTable[3].data =
      this.theManageJob.applicantsInPostTrainingPhase;
    this.jobBatchRecruitmentTable[4].data =
      this.theManageJob.applicantsInOnboardingPhase;
    //this.GetJobGeneralPhaseChecks(this.theManageJob.job.id);
  }

  GetJobStatus(item: JobPost): string {
    var today = new Date();
    var start = new Date(item.validityStart);
    var stop = new Date(item.validityStop);
    if (today < start) return "InActive";
    if (today > stop) return "Expired";
    if (today >= start && today <= stop) return "Active";
    return "Err";
  }

  manageJobBatch() {
    this.showRightSide = true;
  }

  ProcessApplicants(data: ApplicantEvaluation[]) {
    this.isInReview = true;
    this.jobBatchInfoTable[0].data = this.theManageJob.job.title;
    this.jobBatchInfoTable[1].data = this.theManageJob.job.jobCode;
    this.jobBatchInfoTable[2].data = this.theManageJob.job.description;
    this.jobBatchInfoTable[3].data = this.theManageJob.totalApplicants + "";
    this.applicationPhaseApplicants =
      this.theManageJob.applicantsInApplicationPhase;
    this.tempApplicationPhaseApplicants = this.applicationPhaseApplicants;
    this.generalPhaseApplicants = this.theManageJob.applicantsInGeneralPhase;
    this.tempGeneralPhaseApplicants = this.generalPhaseApplicants;
    this.trainingPhaseApplicants = this.theManageJob.applicantsInTrainingPhase;
    this.tempTrainingPhaseApplicants = this.trainingPhaseApplicants;
    this.postTrainingPhaseApplicants =
      this.theManageJob.applicantsInPostTrainingPhase;
    this.tempPostTrainingPhaseApplicants = this.postTrainingPhaseApplicants;
    this.onboardingPhaseApplicants =
      this.theManageJob.applicantsInOnboardingPhase;
    this.tempOnboardingTrainingPhaseApplicants = this.onboardingPhaseApplicants;
    this.SetCurrentPhaseInView(data[0]);
  }

  SetCurrentPhaseInView(data: ApplicantEvaluation) {
    if (data.currentStage == EvaluationStageEnum.ApplicationAssessment)
      this.currentPhaseInView = 1;
    if (data.currentStage == EvaluationStageEnum.GeneralAssessment)
      this.currentPhaseInView = 2;
    if (data.currentStage == EvaluationStageEnum.TrainingAssessment)
      this.currentPhaseInView = 3;
    if (data.currentStage == EvaluationStageEnum.PostAssessment)
      this.currentPhaseInView = 4;
    if (
      data.currentStage == EvaluationStageEnum.Onboarding ||
      data.currentStage == EvaluationStageEnum.OngoingMaterialRequest ||
      data.currentStage == EvaluationStageEnum.AwaitingDeployment ||
      data.currentStage == EvaluationStageEnum.Deployed
    )
      this.currentPhaseInView = 5;
  }

  DisplayApplicants(data: ApplicantEvaluation[]) {
    this.openViewApplicantsDialogue = true;
    this.applicantsToViewList = data;
  }

  HideViewApplicantsDialog() {
    this.openViewApplicantsDialogue = false;
  }

  HideViewApplicantsAppReqDialog() {
    this.openAppRequirementDialogue = false;
  }

  HideDeclineDialog() {
    this.openDeclineDialogue = false;
  }

  HideApproveOverrideDialog() {
    this.openApproveOverrideDialogue = false;
  }

  ShowAddNewNoteDialogue() {
    this.openAddNewNoteDialogue = true;
  }

  HideAddNewNoteDialog() {
    this.openAddNewNoteDialogue = false;
  }

  CreateNote() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Creating Note...",
    });

    this.isCreatingNote = true;
    const postData: CreateNewJobPostNote = {
      caption: this.noteForm.get("Caption").value,
      details: this.noteForm.get("Detail").value,
      jobPostId: this.theManageJob.job.id,
    };

    this.employmentService.AddNewJobPostNote(postData).subscribe(
      async () => {
        await this.messageService.add({
          severity: "success",
          summary: "Completed",
          detail: "Note Created Successfully!",
        });

        this.noteForm.reset();
        this.isCreatingNote = false;
        this.FetchJobPostNotes();
        this.openAddNewNoteDialogue = false;
        this.FetchAllJobPosts();
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to createnote at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
        this.isCreatingNote = false;
      }
    );
  }

  ViewApplicationPhaseApplicant(data: ApplicantEvaluation) {
    this.appPhaseApplicantInView = data;
    this.LoadCompleteApplicantDetails();
  }

  LoadCompleteApplicantDetails() {
    this.employmentService
      .GetCompleteApplicantDetails(
        this.appPhaseApplicantInView.jobApplicationId
      )
      .subscribe(
        async (data) => {
          this.appPhaseApplicantInView.backgroundInfo =
            data.backgroundInformation;
          this.appPhaseApplicantInView.nextOfKinInfo =
            data.nextOfKinInformations;
          this.appPhaseApplicantInView.educationalInfo =
            data.educationalInformations;
          this.appPhaseApplicantInView.prevEmployerInfo =
            data.prevEmployerInformations;
          this.appPhaseApplicantInView.medicalAccountInfo =
            data.additionalInformation;
          this.appPhaseApplicantInView.additionalInfo =
            data.medicalAccountInformation;

          this.CalculateApplicantsAPPScore();
        },
        (error) => {
          console.log("Error: " + JSON.stringify(error));
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail:
              "Unable to load complete applicant details at the moment.. Reason: [" +
              error
                ? error.error.message
                : "request failed - permission" + "]",
          });
        }
      );
  }

  GetNextOfKinRelationshipValue(relationship: number): string {
    if (relationship == NextOfKinRelationshipEnum.Brother) return "Brother";
    if (relationship == NextOfKinRelationshipEnum.Daughter) return "Daughter";
    if (relationship == NextOfKinRelationshipEnum.Father) return "Father";
    if (relationship == NextOfKinRelationshipEnum.Mother) return "Mother";
    if (relationship == NextOfKinRelationshipEnum.Sister) return "Sister";
    if (relationship == NextOfKinRelationshipEnum.Son) return "Son";
    return "Nil";
  }

  GetEducationalLevel(level: number): string {
    if (level == EducationLevelEnum.PostGraduate) return "PostGraduate";
    if (level == EducationLevelEnum.Primary) return "Primary";
    if (level == EducationLevelEnum.Secondary) return "Secondary";
    if (level == EducationLevelEnum.Tertiary) return "Tertiary";
    return "Nil";
  }

  GetGenderValue(gender: number): string {
    if (gender == GenderEnum.Male) return "Male";
    if (gender == GenderEnum.Female) return "Female";
    if (gender == GenderEnum.Unspecified) return "Unspecified";
    return "Nil";
  }

  GetBankValue(bank: number | string): string {
    return bank + "";
    if (bank == BankEnum.FCMB) return "FCMB";
    if (bank == BankEnum.Firstbank) return "Firstbank";
    if (bank == BankEnum.GTBank) return "GTBank";
    if (bank == BankEnum.KeystoneBank) return "KeystoneBank";
    if (bank == BankEnum.KudaBank) return "KudaBank";
    if (bank == BankEnum.PolarisBank) return "PolarisBank";
    if (bank == BankEnum.ProvidusBank) return "ProvidusBank";
    if (bank == BankEnum.SterlingBank) return "SterlingBank";
    if (bank == BankEnum.WemaBank) return "WemaBank";
    if (bank == BankEnum.ZenithBank) return "ZenithBank";
    return "Nil";
  }

  GetMaritalStatusValue(marritalStat: number): string {
    if (marritalStat == MaritalStatusEnum.Divorced) return "Divorced";
    if (marritalStat == MaritalStatusEnum.Married) return "Married";
    if (marritalStat == MaritalStatusEnum.Single) return "Single";
    if (marritalStat == MaritalStatusEnum.Widowed) return "Widowed";
    return "Nil";
  }

  ApplyFilters() {
    this.appPhaseApplicantInView = null;
    this.genPhaseApplicantInView = null;
    this.trainPhaseApplicantInView = null;
    this.postTrainPhaseApplicantInView = null;
    this.onboardingTrainPhaseApplicantInView = null;

    this.applicationPhaseApplicants = this.tempApplicationPhaseApplicants;
    this.generalPhaseApplicants = this.tempGeneralPhaseApplicants;
    this.trainingPhaseApplicants = this.tempTrainingPhaseApplicants;
    this.postTrainingPhaseApplicants = this.tempPostTrainingPhaseApplicants;
    this.onboardingPhaseApplicants = this.tempOnboardingTrainingPhaseApplicants;

    this.ApplyApplicationPhaseFilers();
    this.ApplyGeneralPhaseFilers();
    this.ApplyTrainingPhaseFilers();
    this.ApplyPostTrainingPhaseFilers();
    this.ApplyOnboardingPhaseFilers();
  }

  ApplyApplicationPhaseFilers() {
    if (this.theApplicationPhaseGender) {
      this.applicationPhaseApplicants = this.applicationPhaseApplicants.filter(
        (x) => x.personalInfo.gender == this.theApplicationPhaseGender.key
      );
    }
    if (this.theApplicationPhaseState) {
      this.applicationPhaseApplicants = this.applicationPhaseApplicants.filter(
        (x) =>
          x.backgroundInfo.stateOfOriginId == this.theApplicationPhaseState.id
      );
    }
    if (this.theApplicationPhaseAgeRange) {
      if (this.theApplicationPhaseAgeRange.key == 1) {
        //Below - 20
        this.applicationPhaseApplicants =
          this.applicationPhaseApplicants.filter(
            (x) =>
              this.GetApplicantAge(new Date(x.personalInfo.dateOfBirth)) <= 20
          );
      } else if (this.theApplicationPhaseAgeRange.key == 2) {
        //21 - 40
        this.applicationPhaseApplicants =
          this.applicationPhaseApplicants.filter(
            (x) =>
              this.GetApplicantAge(new Date(x.personalInfo.dateOfBirth)) >=
                21 &&
              this.GetApplicantAge(new Date(x.personalInfo.dateOfBirth)) <= 40
          );
      } else if (this.theApplicationPhaseAgeRange.key == 3) {
        //41 - above
        this.applicationPhaseApplicants =
          this.applicationPhaseApplicants.filter(
            (x) =>
              this.GetApplicantAge(new Date(x.personalInfo.dateOfBirth)) >= 41
          );
      }
    }
    if (this.theApplicationPhaseStateOfResidence) {
      this.applicationPhaseApplicants = this.applicationPhaseApplicants.filter(
        (x) =>
          x.personalInfo.stateId == this.theApplicationPhaseStateOfResidence.id
      );
    }
    if (this.theApplicationPhaseMaritalStatus) {
      this.applicationPhaseApplicants = this.applicationPhaseApplicants.filter(
        (x) =>
          x.backgroundInfo.maritalStatus ==
          this.theApplicationPhaseMaritalStatus.key
      );
    }
  }

  ApplyGeneralPhaseFilers() {
    if (this.theApplicationPhaseGender) {
      this.generalPhaseApplicants = this.generalPhaseApplicants.filter(
        (x) => x.personalInfo.gender == this.theApplicationPhaseGender.key
      );
    }
    if (this.theApplicationPhaseState) {
      this.generalPhaseApplicants = this.generalPhaseApplicants.filter(
        (x) =>
          x.backgroundInfo.stateOfOriginId == this.theApplicationPhaseState.id
      );
    }
    if (this.theApplicationPhaseAgeRange) {
      if (this.theApplicationPhaseAgeRange.key == 1) {
        //Below - 20
        this.generalPhaseApplicants = this.generalPhaseApplicants.filter(
          (x) =>
            this.GetApplicantAge(new Date(x.personalInfo.dateOfBirth)) <= 20
        );
      } else if (this.theApplicationPhaseAgeRange.key == 2) {
        //21 - 40
        this.generalPhaseApplicants = this.generalPhaseApplicants.filter(
          (x) =>
            this.GetApplicantAge(new Date(x.personalInfo.dateOfBirth)) >= 21 &&
            this.GetApplicantAge(new Date(x.personalInfo.dateOfBirth)) <= 40
        );
      } else if (this.theApplicationPhaseAgeRange.key == 3) {
        //41 - above
        this.generalPhaseApplicants = this.generalPhaseApplicants.filter(
          (x) =>
            this.GetApplicantAge(new Date(x.personalInfo.dateOfBirth)) >= 41
        );
      }
    }
    if (this.theApplicationPhaseStateOfResidence) {
      this.generalPhaseApplicants = this.generalPhaseApplicants.filter(
        (x) =>
          x.personalInfo.stateId == this.theApplicationPhaseStateOfResidence.id
      );
    }
    if (this.theApplicationPhaseMaritalStatus) {
      this.generalPhaseApplicants = this.generalPhaseApplicants.filter(
        (x) =>
          x.backgroundInfo.maritalStatus ==
          this.theApplicationPhaseMaritalStatus.key
      );
    }
  }

  ApplyTrainingPhaseFilers() {
    if (this.theApplicationPhaseGender) {
      this.trainingPhaseApplicants = this.trainingPhaseApplicants.filter(
        (x) => x.personalInfo.gender == this.theApplicationPhaseGender.key
      );
    }
    if (this.theApplicationPhaseState) {
      this.trainingPhaseApplicants = this.trainingPhaseApplicants.filter(
        (x) =>
          x.backgroundInfo.stateOfOriginId == this.theApplicationPhaseState.id
      );
    }
    if (this.theApplicationPhaseAgeRange) {
      if (this.theApplicationPhaseAgeRange.key == 1) {
        //Below - 20
        this.trainingPhaseApplicants = this.trainingPhaseApplicants.filter(
          (x) =>
            this.GetApplicantAge(new Date(x.personalInfo.dateOfBirth)) <= 20
        );
      } else if (this.theApplicationPhaseAgeRange.key == 2) {
        //21 - 40
        this.trainingPhaseApplicants = this.trainingPhaseApplicants.filter(
          (x) =>
            this.GetApplicantAge(new Date(x.personalInfo.dateOfBirth)) >= 21 &&
            this.GetApplicantAge(new Date(x.personalInfo.dateOfBirth)) <= 40
        );
      } else if (this.theApplicationPhaseAgeRange.key == 3) {
        //41 - above
        this.trainingPhaseApplicants = this.trainingPhaseApplicants.filter(
          (x) =>
            this.GetApplicantAge(new Date(x.personalInfo.dateOfBirth)) >= 41
        );
      }
    }
    if (this.theApplicationPhaseStateOfResidence) {
      this.trainingPhaseApplicants = this.trainingPhaseApplicants.filter(
        (x) =>
          x.personalInfo.stateId == this.theApplicationPhaseStateOfResidence.id
      );
    }
    if (this.theApplicationPhaseMaritalStatus) {
      this.trainingPhaseApplicants = this.trainingPhaseApplicants.filter(
        (x) =>
          x.backgroundInfo.maritalStatus ==
          this.theApplicationPhaseMaritalStatus.key
      );
    }
  }

  ApplyPostTrainingPhaseFilers() {
    if (this.theApplicationPhaseGender) {
      this.postTrainingPhaseApplicants =
        this.postTrainingPhaseApplicants.filter(
          (x) => x.personalInfo.gender == this.theApplicationPhaseGender.key
        );
    }
    if (this.theApplicationPhaseState) {
      this.postTrainingPhaseApplicants =
        this.postTrainingPhaseApplicants.filter(
          (x) =>
            x.backgroundInfo.stateOfOriginId == this.theApplicationPhaseState.id
        );
    }
    if (this.theApplicationPhaseAgeRange) {
      if (this.theApplicationPhaseAgeRange.key == 1) {
        //Below - 20
        this.postTrainingPhaseApplicants =
          this.postTrainingPhaseApplicants.filter(
            (x) =>
              this.GetApplicantAge(new Date(x.personalInfo.dateOfBirth)) <= 20
          );
      } else if (this.theApplicationPhaseAgeRange.key == 2) {
        //21 - 40
        this.postTrainingPhaseApplicants =
          this.postTrainingPhaseApplicants.filter(
            (x) =>
              this.GetApplicantAge(new Date(x.personalInfo.dateOfBirth)) >=
                21 &&
              this.GetApplicantAge(new Date(x.personalInfo.dateOfBirth)) <= 40
          );
      } else if (this.theApplicationPhaseAgeRange.key == 3) {
        //41 - above
        this.postTrainingPhaseApplicants =
          this.postTrainingPhaseApplicants.filter(
            (x) =>
              this.GetApplicantAge(new Date(x.personalInfo.dateOfBirth)) >= 41
          );
      }
    }
    if (this.theApplicationPhaseStateOfResidence) {
      this.postTrainingPhaseApplicants =
        this.postTrainingPhaseApplicants.filter(
          (x) =>
            x.personalInfo.stateId ==
            this.theApplicationPhaseStateOfResidence.id
        );
    }
    if (this.theApplicationPhaseMaritalStatus) {
      this.postTrainingPhaseApplicants =
        this.postTrainingPhaseApplicants.filter(
          (x) =>
            x.backgroundInfo.maritalStatus ==
            this.theApplicationPhaseMaritalStatus.key
        );
    }
  }

  ApplyOnboardingPhaseFilers() {
    if (this.theApplicationPhaseGender) {
      this.onboardingPhaseApplicants = this.onboardingPhaseApplicants.filter(
        (x) => x.personalInfo.gender == this.theApplicationPhaseGender.key
      );
    }
    if (this.theApplicationPhaseState) {
      this.onboardingPhaseApplicants = this.onboardingPhaseApplicants.filter(
        (x) =>
          x.backgroundInfo.stateOfOriginId == this.theApplicationPhaseState.id
      );
    }
    if (this.theApplicationPhaseAgeRange) {
      if (this.theApplicationPhaseAgeRange.key == 1) {
        //Below - 20
        this.onboardingPhaseApplicants = this.onboardingPhaseApplicants.filter(
          (x) =>
            this.GetApplicantAge(new Date(x.personalInfo.dateOfBirth)) <= 20
        );
      } else if (this.theApplicationPhaseAgeRange.key == 2) {
        //21 - 40
        this.onboardingPhaseApplicants = this.onboardingPhaseApplicants.filter(
          (x) =>
            this.GetApplicantAge(new Date(x.personalInfo.dateOfBirth)) >= 21 &&
            this.GetApplicantAge(new Date(x.personalInfo.dateOfBirth)) <= 40
        );
      } else if (this.theApplicationPhaseAgeRange.key == 3) {
        //41 - above
        this.onboardingPhaseApplicants = this.onboardingPhaseApplicants.filter(
          (x) =>
            this.GetApplicantAge(new Date(x.personalInfo.dateOfBirth)) >= 41
        );
      }
    }
    if (this.theApplicationPhaseStateOfResidence) {
      this.onboardingPhaseApplicants = this.onboardingPhaseApplicants.filter(
        (x) =>
          x.personalInfo.stateId == this.theApplicationPhaseStateOfResidence.id
      );
    }
    if (this.theApplicationPhaseMaritalStatus) {
      this.onboardingPhaseApplicants = this.onboardingPhaseApplicants.filter(
        (x) =>
          x.backgroundInfo.maritalStatus ==
          this.theApplicationPhaseMaritalStatus.key
      );
    }
  }

  ResetFilters() {
    this.theApplicationPhaseAgeRange = null;
    this.theApplicationPhaseGender = null;
    this.theApplicationPhaseMaritalStatus = null;
    this.theApplicationPhaseState = null;
    this.theApplicationPhaseStateOfResidence = null;
    this.applicationPhaseApplicants = this.tempApplicationPhaseApplicants;
    this.generalPhaseApplicants = this.tempGeneralPhaseApplicants;
    this.trainingPhaseApplicants = this.tempTrainingPhaseApplicants;
    this.postTrainingPhaseApplicants = this.tempPostTrainingPhaseApplicants;
    this.onboardingPhaseApplicants = this.tempOnboardingTrainingPhaseApplicants;
  }

  GetApplicantAge(dateold) {
    var datenew = new Date();
    var ynew = datenew.getFullYear();
    var mnew = datenew.getMonth();
    var dnew = datenew.getDate();
    var yold = dateold.getFullYear();
    var mold = dateold.getMonth();
    var dold = dateold.getDate();
    var diff = ynew - yold;
    if (mold > mnew) diff--;
    else {
      if (mold == mnew) {
        if (dold > dnew) diff--;
      }
    }

    //console.log("Applicant's Age: " + diff);
    return diff;
  }

  FailApplicationEvaluation() {
    this.declineText =
      "This action will fail the applicant's evaluation and wont proceed to the next stage. Do you still want to proceed?";
    this.declineCode = 1;
    this.openDeclineDialogue = true;
  }

  FailApplicationEvaluation_old() {
    this.confirmationService.confirm({
      message:
        "This action will fail the applicant's evaluation and wont proceed to the next stage. Do you still want to proceed?",
      accept: () => {
        this.messageService.add({
          severity: "info",
          summary: "Notice",
          detail: "Failing Applicant's Evaluation...",
        });

        const postData: ApplicationEvaluationReview = {
          applicationEvaluationId: this.appPhaseApplicantInView.id,
          isPassed: false,
          failureReason: this.formDecline.get("Comment").value,
          currentEvaluationStage: EvaluationStageEnum.ApplicationAssessment,
        };

        this.employmentService.ApplicantEvaluationReview(postData).subscribe(
          async () => {
            await this.messageService.add({
              severity: "success",
              summary: "Removed",
              detail: "Removed successfully",
            });

            var applicantEvaluation = this.applicationPhaseApplicants.find(
              (x) => x.id == postData.applicationEvaluationId
            );

            //Remove from list
            const index =
              this.applicationPhaseApplicants.indexOf(applicantEvaluation);
            if (index > -1) {
              this.applicationPhaseApplicants.splice(index, 1);
            }

            //Refresh List
            this.appPhaseApplicantInView = null;
          },
          (error) => {
            console.log("Error: " + JSON.stringify(error));
            this.messageService.add({
              severity: "error",
              summary: "Notice",
              detail:
                "Unable to fail applicant's evaluation at the moment.. Reason: [" +
                error
                  ? error.error.message
                  : "request failed - permission" + "]",
            });
          }
        );
      },
    });
  }

  PassApplicationEvaluation() {
    this.confirmationService.confirm({
      message:
        "This action will move the applicant to the next stage. Do you still want to proceed?",
      accept: () => {
        this.messageService.add({
          severity: "info",
          summary: "Notice",
          detail: "Passing Applicant's Evaluation...",
        });

        const postData: ApplicationEvaluationReview = {
          applicationEvaluationId: this.appPhaseApplicantInView.id,
          isPassed: true,
          currentEvaluationStage: EvaluationStageEnum.ApplicationAssessment,
        };

        this.employmentService.ApplicantEvaluationReview(postData).subscribe(
          async () => {
            await this.messageService.add({
              severity: "success",
              summary: "Removed",
              detail: "Applicant Passed Current Phase Successfully",
            });

            var applicantEvaluation = this.applicationPhaseApplicants.find(
              (x) => x.id == postData.applicationEvaluationId
            );

            //Remove from list
            const index =
              this.applicationPhaseApplicants.indexOf(applicantEvaluation);
            if (index > -1) {
              this.applicationPhaseApplicants.splice(index, 1);
            }

            //Push to next list
            this.generalPhaseApplicants.push(applicantEvaluation);

            //Refresh List
            this.appPhaseApplicantInView = null;
          },
          (error) => {
            console.log("Error: " + JSON.stringify(error));
            this.messageService.add({
              severity: "error",
              summary: "Notice",
              detail:
                "Unable to pass applicant's evaluation at the moment.. Reason: [" +
                error
                  ? error.error.message
                  : "request failed - permission" + "]",
            });
          }
        );
      },
    });
  }

  onCancelUpload(identifier: number) {
    if (identifier == 1) {
      this.uploadedSupportDoc = [];
    } else if (identifier == 2) {
      this.uploadedSupportDocCogn = [];
    } else if (identifier == 3) {
      this.uploadedSupportDocGua = [];
    } else if (identifier == 4) {
      this.uploadedSupportDocBG = [];
    } else if (identifier == 5) {
      this.uploadedTrainingResults = [];
    } else if (identifier == 6) {
      this.uploadedGuarantorDeclarations = [];
    }
  }

  Pause(ms: any) {
    var start = new Date().getTime();
    var end = start;
    while (end < start + ms) {
      end = new Date().getTime();
    }
  }

  async ViewGeneralPhaseApplicant(data: ApplicantEvaluation) {
    this.genPhaseApplicantInView = null;
    this.uploadedSupportDoc = [];
    this.PhyScore = "Yet to be calculated";
    this.CognScore = "Yet to be calculated";
    this.GuaScore = "Yet to be calculated";
    this.BGScore = "Yet to be calculated";
    await new Promise((resolve) => setTimeout(resolve, 3000)); // 3 sec
    this.genPhaseApplicantInView = data;
    this.LoadApplicantGeneralPhaseDetails();
  }

  ViewTrainingPhaseApplicant(data: ApplicantEvaluation) {
    this.trainPhaseApplicantInView = data;
    this.GetApplicantsTrainingInfo(data.jobApplicationId);
  }

  NotifyUserToClickUpload(identifier: number) {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Kindly ensure you click upload.",
    });

    if (identifier == 1) {
      this.userIsYetToClickUploadSupportDoc = true;
    } else if (identifier == 2) {
      this.userIsYetToClickUploadSupportDocCogn = true;
    } else if (identifier == 3) {
      this.userIsYetToClickUploadSupportDocGua = true;
    } else if (identifier == 4) {
      this.userIsYetToClickUploadSupportDocBG = true;
    } else if (identifier == 5) {
      this.userIsYetToClickUploadTrainingResult = true;
    } else if (identifier == 6) {
      this.userIsYetToClickUploadGuaDeclare = true;
    }

    this.TempReset();
  }

  onUploadSupportDoc1(event) {
    this.uploadedSupportDoc = [];
    for (const file of event.files) {
      this.uploadedSupportDoc.push(file);
    }

    this.messageService.add({
      severity: "success",
      summary: "Success",
      detail: "Support Doc(s) Uploaded",
    });

    this.userIsYetToClickUploadSupportDoc = false;
    this.TempReset();
  }

  onUploadSupportDocCogn(event) {
    this.uploadedSupportDocCogn = [];
    for (const file of event.files) {
      this.uploadedSupportDocCogn.push(file);
    }

    this.messageService.add({
      severity: "success",
      summary: "Success",
      detail: "Support Doc(s) Uploaded",
    });

    this.userIsYetToClickUploadSupportDocCogn = false;
    this.TempReset();
  }

  onUploadSupportDocGua(event) {
    this.uploadedSupportDocGua = [];
    for (const file of event.files) {
      this.uploadedSupportDocGua.push(file);
    }

    this.messageService.add({
      severity: "success",
      summary: "Success",
      detail: "Support Doc(s) Uploaded",
    });

    this.userIsYetToClickUploadSupportDocGua = false;
    this.TempReset();
  }

  onUploadSupportDocBG(event) {
    this.uploadedSupportDocBG = [];
    for (const file of event.files) {
      this.uploadedSupportDocBG.push(file);
    }

    this.messageService.add({
      severity: "success",
      summary: "Success",
      detail: "Support Doc(s) Uploaded",
    });

    this.userIsYetToClickUploadSupportDocBG = false;
    this.TempReset();
  }

  onUploadGuaDeclaration(event) {
    this.uploadedGuarantorDeclarations = [];
    for (const file of event.files) {
      this.uploadedGuarantorDeclarations.push(file);
    }

    this.messageService.add({
      severity: "success",
      summary: "Success",
      detail: "Guarantor Declaration Form(s) Uploaded",
    });

    this.userIsYetToClickUploadGuaDeclare = false;
    this.TempReset();
  }

  onUploadTrainingResult(event) {
    this.uploadedTrainingResults = [];
    for (const file of event.files) {
      this.uploadedTrainingResults.push(file);
    }

    this.messageService.add({
      severity: "success",
      summary: "Success",
      detail: "Support Doc(s) Uploaded",
    });

    this.userIsYetToClickUploadTrainingResult = false;
    this.TempReset();
  }

  CalculatePhy() {
    if (
      this.generalPhaseChecksForJob.physicalChecks.length !=
      this.scoreKeeper.filter((x) => x.indicator == "PHY").length
    ) {
      this.messageService.add({
        severity: "error",
        summary: "Notice",
        detail: "Kindly input all applicant's scores to calculate.",
      });

      this.PhyScore = "Yet to be calculated";
      this.TempReset();
      return;
    }

    const postData: CalculateApplicantScore = {
      jobPostId: this.theManageJob.job.id,
      indicator: 1,
      scores: [],
      gender: this.genPhaseApplicantInView.personalInfo.gender,
    };

    this.scoreKeeper
      .filter((x) => x.indicator == "PHY")
      .forEach((score) => {
        postData.scores.push({
          requirementId: score.requirementId,
          grade: score.score,
        });
      });

    this.employmentService.CalculateApplicantScore(postData).subscribe(
      async (data) => {
        await this.messageService.add({
          severity: "success",
          summary: "Completed",
          detail: "Applicant Score Calcualted Successfully!",
        });

        this.PhyScore = data;
        this.TempReset();
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to calculate applicant's score at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
        this.PhyScore = "Yet to be calculate";
        this.TempReset();
      }
    );
  }

  FailApplicantPhy() {
    if (
      this.generalPhaseChecksForJob.physicalChecks.length !=
      this.scoreKeeper.filter((x) => x.indicator == "PHY").length
    ) {
      this.messageService.add({
        severity: "error",
        summary: "Notice",
        detail: "Kindly input all applicant's scores to proceed.",
      });

      this.PhyScore = "Yet to be calculated";
      this.TempReset();
      return;
    }

    this.declineText =
      "Are you sure you want to fail this applicant's physical assessment, User will be dropped and not proceed to the next stage after this action is completed. Do you still wish to proceed?";
    this.declineCode = 2;
    this.openDeclineDialogue = true;
  }

  async _reviewApplicantPhy(postData: ReviewApplicantPhysicalAssessment) {
    this.employmentService.ReviewApplicantPhysicalAssessent(postData).subscribe(
      async () => {
        await this.messageService.add({
          severity: "success",
          summary: "Completed",
          detail: "Action Successfull!",
        });

        var applicantEvaluation = this.generalPhaseApplicants.find(
          (x) => x.id == postData.applicationEvaluationId
        );

        const index = this.generalPhaseApplicants.indexOf(applicantEvaluation);
        if (postData.isPassed) {
          this.generalPhaseApplicants[index].physical = {
            scoreKeeper: postData.scoreTrack,
            status: 1,
          };

          if (
            applicantEvaluation.background &&
            applicantEvaluation.guarantorAssessment &&
            applicantEvaluation.cognitive &&
            applicantEvaluation.physical
          ) {
            if (index > -1) {
              this.generalPhaseApplicants.splice(index, 1);
            }

            this.trainingPhaseApplicants.push(applicantEvaluation);

            this.genPhaseApplicantInView = null;
          }
        } else {
          if (index > -1) {
            this.generalPhaseApplicants.splice(index, 1);
          }

          this.genPhaseApplicantInView = null;
        }
        this.TempReset();
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Action Failed",
          detail:
            "Applicant's physical assessment review failed at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
        this.TempReset();
      }
    );
  }

  async PassApplicantPhy() {
    if (
      this.generalPhaseChecksForJob.physicalChecks.length !=
      this.scoreKeeper.filter((x) => x.indicator == "PHY").length
    ) {
      this.messageService.add({
        severity: "error",
        summary: "Notice",
        detail: "Kindly input all applicant's scores to proceed.",
      });

      this.PhyScore = "Yet to be calculated";
      this.TempReset();
      return;
    }

    if (this.uploadedSupportDoc.length <= 0) {
      this.messageService.add({
        severity: "error",
        summary: "Notice",
        detail: "You must upload at least one(1) document.",
      });

      this.TempReset();
      return;
    }

    if (this.PhyScore == "Fail") {
      this.approveOverrideText =
        "Are you sure you want to pass this applicant's physical assessment even though the applicant has a fail recommendation, User will be proceeding to the next stage after this action is completed. Do you still wish to proceed?";
      this.approveOverrideCode = 1;
      this.openApproveOverrideDialogue = true;
    } else {
      this.confirmationService.confirm({
        message: "This action is irreversible, are you sure?",
        accept: async () => {
          this.messageService.add({
            severity: "info",
            summary: "Notice",
            detail: "Performing Action...",
          });

          let scoreTracker: string = "";
          this.scoreKeeper
            .filter((x) => x.indicator == "PHY")
            .forEach((sco) => {
              scoreTracker =
                scoreTracker + sco.requirementId + ":" + sco.score + ";";
            });

          const postData: ReviewApplicantPhysicalAssessment = {
            applicationEvaluationId: this.genPhaseApplicantInView.id,
            scoreTrack: scoreTracker,
            supportingDocs: [],
            isPassed: true,
          };

          if (this.uploadedSupportDoc.length > 0) {
            this.fileStorageService.UploadMultipleFilesFromDataUrl(
              this.uploadedSupportDoc
            );
            this.fileStorageService.onUploadFinished.subscribe(
              async (resp: CommonResponse<string[]>) => {
                if (resp.responseCode != "00") {
                  this.messageService.add({
                    severity: "error",
                    summary: "Notice",
                    detail: resp.responseMsg,
                  });
                } else {
                  postData.supportingDocs = resp.responseData;
                  await this._reviewApplicantPhy(postData);
                }
              },
              (error) => {
                console.log("Error while uploading files " + error);
                this.messageService.add({
                  severity: "error",
                  summary: "Notice",
                  detail: "ERR: Unable to upload image(s)/file(s) to storage",
                });
              }
            );
          } else {
            await this._reviewApplicantPhy(postData);
          }
          this.TempReset();
        },
      });
    }
  }

  async ApplicantPhy() {
    if (this.PhyScore == "Yet to be calculated") {
      this.messageService.add({
        severity: "error",
        summary: "Notice",
        detail: "Kindly calculate applicant's scores to proceed.",
      });
      this.TempReset();
      return;
    }

    if (this.uploadedSupportDoc.length <= 0) {
      this.messageService.add({
        severity: "error",
        summary: "Notice",
        detail: "You must upload at least one(1) document.",
      });

      this.TempReset();
      return;
    }

    this.confirmationService.confirm({
      message:
        "The calculated scores will be used in grading applicant and is an irreversible action. Do you still wish to proceed?",
      accept: async () => {
        this.messageService.add({
          severity: "info",
          summary: "Notice",
          detail: "Performing Action...",
        });

        let scoreTracker: string = "";
        this.scoreKeeper
          .filter((x) => x.indicator == "PHY")
          .forEach((sco) => {
            scoreTracker =
              scoreTracker + sco.requirementId + ":" + sco.score + ";";
          });

        const postData: ReviewApplicantPhysicalAssessment = {
          applicationEvaluationId: this.genPhaseApplicantInView.id,
          scoreTrack: scoreTracker,
          supportingDocs: [],
          isPassed: this.PhyScore == "Pass" ? true : false,
        };

        if (this.uploadedSupportDoc.length > 0) {
          this.fileStorageService.UploadMultipleFilesFromDataUrl(
            this.uploadedSupportDoc
          );
          this.fileStorageService.onUploadFinished.subscribe(
            async (resp: CommonResponse<string[]>) => {
              if (resp.responseCode != "00") {
                this.messageService.add({
                  severity: "error",
                  summary: "Notice",
                  detail: resp.responseMsg,
                });
              } else {
                postData.supportingDocs = resp.responseData;
                await this._reviewApplicantPhy(postData);
              }
            },
            (error) => {
              console.log("Error while uploading files " + error);
              this.messageService.add({
                severity: "error",
                summary: "Notice",
                detail: "ERR: Unable to upload image(s)/file(s) to storage",
              });
            }
          );
        } else {
          await this._reviewApplicantPhy(postData);
        }
      },
    });
  }

  TempReset() {
    this.msgs = [];
    this.msgs.push({
      severity: "info",
      summary: "Notice:",
      // detail: "Minimum of 2 Guarantors information is required per applicant.",
      detail:
        "Please be informed that you are required to attach at least 2 guarantors.",
    });

    this.RunAppPhaseFormMessageJob();
  }

  CalculateCogn() {
    if (
      this.generalPhaseChecksForJob.cognitiveChecks.length !=
      this.scoreKeeper.filter((x) => x.indicator == "COGN").length
    ) {
      this.messageService.add({
        severity: "error",
        summary: "Notice",
        detail: "Kindly input all applicant's scores to calculate.",
      });

      this.CognScore = "Yet to be calculated";
      this.TempReset();
      return;
    }

    const postData: CalculateApplicantScore = {
      jobPostId: this.theManageJob.job.id,
      indicator: 2,
      scores: [],
    };

    this.scoreKeeper
      .filter((x) => x.indicator == "COGN")
      .forEach((score) => {
        postData.scores.push({
          requirementId: score.requirementId,
          grade: score.score,
        });
      });

    this.employmentService.CalculateApplicantScore(postData).subscribe(
      async (data) => {
        await this.messageService.add({
          severity: "success",
          summary: "Completed",
          detail: "Applicant Score Calcualted Successfully!",
        });

        this.CognScore = data;
        this.TempReset();
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to calculate applicant's score at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
        this.CognScore = "Yet to be calculate";
        this.TempReset();
      }
    );
  }

  FailApplicantCogn() {
    if (
      this.generalPhaseChecksForJob.cognitiveChecks.length !=
      this.scoreKeeper.filter((x) => x.indicator == "COGN").length
    ) {
      this.messageService.add({
        severity: "error",
        summary: "Notice",
        detail: "Kindly input all applicant's scores to proceed.",
      });

      this.CognScore = "Yet to be calculated";
      this.TempReset();
      return;
    }

    this.declineText =
      "Are you sure you want to fail this applicant's cognitive assessment, User will be dropped and not proceed to the next stage after this action is completed. Do you still wish to proceed?";
    this.declineCode = 3;
    this.openDeclineDialogue = true;
  }

  async _reviewApplicantCogn(postData: ReviewCognitivePhysicalAssessment) {
    this.employmentService
      .ReviewApplicantCognitiveAssessment(postData)
      .subscribe(
        async () => {
          await this.messageService.add({
            severity: "success",
            summary: "Completed",
            detail: "Action Successfull!",
          });

          var applicantEvaluation = this.generalPhaseApplicants.find(
            (x) => x.id == postData.applicationEvaluationId
          );

          const index =
            this.generalPhaseApplicants.indexOf(applicantEvaluation);
          if (postData.isPassed) {
            this.generalPhaseApplicants[index].cognitive = {
              scoreKeeper: postData.scoreTrack,
              status: 1,
            };

            if (
              applicantEvaluation.background &&
              applicantEvaluation.guarantorAssessment &&
              applicantEvaluation.cognitive &&
              applicantEvaluation.physical
            ) {
              if (index > -1) {
                this.generalPhaseApplicants.splice(index, 1);
              }

              this.trainingPhaseApplicants.push(applicantEvaluation);

              this.genPhaseApplicantInView = null;
            }
          } else {
            if (index > -1) {
              this.generalPhaseApplicants.splice(index, 1);
            }

            this.genPhaseApplicantInView = null;
          }
          this.TempReset();
        },
        (error) => {
          console.log("Error: " + JSON.stringify(error));
          this.messageService.add({
            severity: "error",
            summary: "Action Failed",
            detail:
              "Applicant's cognitive assessment review failed at the moment.. Reason: [" +
              error
                ? error.error.message
                : "request failed - permission" + "]",
          });
          this.TempReset();
        }
      );
  }

  async PassApplicantCogn() {
    if (
      this.generalPhaseChecksForJob.cognitiveChecks.length !=
      this.scoreKeeper.filter((x) => x.indicator == "COGN").length
    ) {
      this.messageService.add({
        severity: "error",
        summary: "Notice",
        detail: "Kindly input all applicant's scores to proceed.",
      });

      this.CognScore = "Yet to be calculated";
      this.TempReset();
      return;
    }

    if (this.uploadedSupportDocCogn.length <= 0) {
      this.messageService.add({
        severity: "error",
        summary: "Notice",
        detail: "You must upload at least one(1) document.",
      });

      this.TempReset();
      return;
    }

    if (this.CognScore == "Fail") {
      this.approveOverrideText =
        "Are you sure you want to pass this applicant's cognitive assessment even though the applicant has a fail recommendation, User will be proceeding to the next stage after this action is completed. Do you still wish to proceed?";
      this.approveOverrideCode = 2;
      this.openApproveOverrideDialogue = true;
    } else {
      this.confirmationService.confirm({
        message: "This action is irreversible, are you sure?",
        accept: async () => {
          this.messageService.add({
            severity: "info",
            summary: "Notice",
            detail: "Performing Action...",
          });

          let scoreTracker: string = "";
          this.scoreKeeper
            .filter((x) => x.indicator == "COGN")
            .forEach((sco) => {
              scoreTracker =
                scoreTracker + sco.requirementId + ":" + sco.score + ";";
            });

          const postData: ReviewCognitivePhysicalAssessment = {
            applicationEvaluationId: this.genPhaseApplicantInView.id,
            scoreTrack: scoreTracker,
            supportingDocs: [],
            isPassed: true,
          };

          if (this.uploadedSupportDocCogn.length > 0) {
            this.fileStorageService.UploadMultipleFilesFromDataUrl(
              this.uploadedSupportDocCogn
            );
            this.fileStorageService.onUploadFinished.subscribe(
              async (resp: CommonResponse<string[]>) => {
                if (resp.responseCode != "00") {
                  this.messageService.add({
                    severity: "error",
                    summary: "Notice",
                    detail: resp.responseMsg,
                  });
                } else {
                  postData.supportingDocs = resp.responseData;
                  await this._reviewApplicantCogn(postData);
                }
              },
              (error) => {
                console.log("Error while uploading files " + error);
                this.messageService.add({
                  severity: "error",
                  summary: "Notice",
                  detail: "ERR: Unable to upload image(s)/file(s) to storage",
                });
              }
            );
          } else {
            await this._reviewApplicantCogn(postData);
          }
        },
      });
    }
  }

  async ApplicantCogn() {
    if (this.CognScore == "Yet to be calculated") {
      this.messageService.add({
        severity: "error",
        summary: "Notice",
        detail: "Kindly calculate applicant's scores to proceed.",
      });
      this.TempReset();
      return;
    }

    if (this.uploadedSupportDocCogn.length <= 0) {
      this.messageService.add({
        severity: "error",
        summary: "Notice",
        detail: "You must upload at least one(1) document.",
      });

      this.TempReset();
      return;
    }

    this.confirmationService.confirm({
      message:
        "The calculated scores will be used in grading applicant and is an irreversible action. Do you still wish to proceed?",
      accept: async () => {
        this.messageService.add({
          severity: "info",
          summary: "Notice",
          detail: "Performing Action...",
        });

        let scoreTracker: string = "";
        this.scoreKeeper
          .filter((x) => x.indicator == "COGN")
          .forEach((sco) => {
            scoreTracker =
              scoreTracker + sco.requirementId + ":" + sco.score + ";";
          });

        const postData: ReviewCognitivePhysicalAssessment = {
          applicationEvaluationId: this.genPhaseApplicantInView.id,
          scoreTrack: scoreTracker,
          supportingDocs: [],
          isPassed: this.CognScore == "Pass" ? true : false,
        };

        if (this.uploadedSupportDocCogn.length > 0) {
          this.fileStorageService.UploadMultipleFilesFromDataUrl(
            this.uploadedSupportDocCogn
          );
          this.fileStorageService.onUploadFinished.subscribe(
            async (resp: CommonResponse<string[]>) => {
              if (resp.responseCode != "00") {
                this.messageService.add({
                  severity: "error",
                  summary: "Notice",
                  detail: resp.responseMsg,
                });
              } else {
                postData.supportingDocs = resp.responseData;
                await this._reviewApplicantCogn(postData);
              }
            },
            (error) => {
              console.log("Error while uploading files " + error);
              this.messageService.add({
                severity: "error",
                summary: "Notice",
                detail: "ERR: Unable to upload image(s)/file(s) to storage",
              });
            }
          );
        } else {
          await this._reviewApplicantCogn(postData);
        }
      },
    });
  }

  PopulateGenPhaseFields() {
    // if (this.genPhaseApplicantInView.physical) {
    //   this.PhyHeight = this.genPhaseApplicantInView.physical.height;
    //   this.PhyWeight = this.genPhaseApplicantInView.physical.weight;
    //   this.PhyBodyMass = this.genPhaseApplicantInView.physical.bodyMass;
    //   this.PhyScore =
    //     this.genPhaseApplicantInView.physical.status == 1 ? "Pass" : "Fail";
    // }
    // if (this.genPhaseApplicantInView.cognitive) {
    //   this.CognVerbal = this.genPhaseApplicantInView.cognitive.verbal;
    //   this.CognQuantitative =
    //     this.genPhaseApplicantInView.cognitive.quantitative;
    //   this.CognCommunication =
    //     this.genPhaseApplicantInView.cognitive.communication;
    //   this.CognScore =
    //     this.genPhaseApplicantInView.cognitive.status == 1 ? "Pass" : "Fail";
    // }

    this.criminalHistoryRadioButton = null;
    this.educationConfirmationRadioButton = null;
  }

  LoadApplicantGeneralPhaseDetails() {
    this.employmentService
      .GetApplicantGeneralPhaseDetails(
        this.genPhaseApplicantInView.jobApplicationId
      )
      .subscribe(
        async (data) => {
          this.genPhaseApplicantInView.physical =
            data.applicantEvaluation.physical;
          this.genPhaseApplicantInView.cognitive =
            data.applicantEvaluation.cognitive;
          this.genPhaseApplicantInView.guarantorAssessment =
            data.applicantEvaluation.guarantorAssessment;
          this.genPhaseApplicantInView.background =
            data.applicantEvaluation.background;

          this.GetJobGeneralPhaseChecks(this.theManageJob.job.id);
          this.PopulateGenPhaseFields();
        },
        (error) => {
          console.log("Error: " + JSON.stringify(error));
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail:
              "Unable to load complete applicant details at the moment.. Reason: [" +
              error
                ? error.error.message
                : "request failed - permission" + "]",
          });
        }
      );
  }

  CalculateGua() {
    if (!this.GuaGrade) {
      this.messageService.add({
        severity: "error",
        summary: "Notice",
        detail: "Kindly input all applicant's scores to calculate.",
      });

      this.GuaScore = "Yet to be calculated";
      return;
    }

    const postData: CalculateApplicantScore = {
      jobPostId: this.theManageJob.job.id,
      indicator: 3,
      grade: this.GuaGrade,
    };

    this.employmentService.CalculateApplicantScore(postData).subscribe(
      async (data) => {
        await this.messageService.add({
          severity: "success",
          summary: "Completed",
          detail: "Applicant Score Calcualted Successfully!",
        });

        this.GuaScore = data;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to calculate applicant's score at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
        this.GuaScore = "Yet to be calculate";
      }
    );
  }

  onUpload(event, form) {
    this.uploadedGuarantorImage = [];
    for (const file of event.files) {
      this.uploadedGuarantorImage.push(file);
    }
    this.imageSrcGua = this.uploadedGuarantorImage[0].objectURL;

    this.messageService.add({
      severity: "success",
      summary: "Success",
      detail: "Picture Uploaded Successfully!",
    });
    form.clear();
    this.TempReset();
  }

  async AddGuarantorToList() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Adding Guarantor...",
    });

    const guarantor: CreateGuarantor = {
      pictureUrl: this.imageSrcGua,
      firstName: this.GuaFirstName,
      lastName: this.GuaLastName,
      mobile: this.GuaMobile,
      email: this.GuaEmail,
      address: this.GuaAddress,
      occupation: this.GuaOccupation,
      guarantorDeclarationFormUrls: [],
      uploadedDeclarationData: this.uploadedGuarantorDeclarations,
      uploadedPictureData: this.uploadedGuarantorImage,
    };
    this.guarantorsToAddList.push(guarantor);
    this.messageService.add({
      severity: "success",
      summary: "Success",
      detail: "Guarantor Added Successfully!",
    });

    this.imageSrcGua = null;
    this.GuaFirstName = null;
    this.GuaLastName = null;
    this.GuaMobile = null;
    this.GuaEmail = null;
    this.GuaAddress = null;
    this.GuaOccupation = null;
    this.uploadedGuarantorImage = [];
    this.uploadedGuarantorDeclarations = [];
    this.TempReset();
    this.showGuaDeclarationForm = false;
    await new Promise((resolve) => setTimeout(resolve, 2000)); // 2 secs
    this.showGuaDeclarationForm = true;
  }

  async PassApplicantGua() {
    if (this.guarantorsToAddList.length < 2) {
      this.messageService.add({
        severity: "error",
        summary: "Notice",
        detail: "You need a minimum of two guarantors to pass this applicant.",
      });

      this.TempReset();
      return;
    }

    this.confirmationService.confirm({
      message: "This action is irreversible, are you sure?",
      accept: async () => {
        this.messageService.add({
          severity: "info",
          summary: "Notice",
          detail: "Performing Action...",
        });
        this.TempReset();

        const postData: ReviewApplicantGuarantorAsseessment = {
          applicationEvaluationId: this.genPhaseApplicantInView.id,
          isPassed: true,
          guarantors: this.guarantorsToAddList,
        };

        //Get First Guarantor Image Url
        this.fileStorageService.UploadFileFromDataUrl(
          postData.guarantors[0].uploadedPictureData[0]
        );
        this.fileStorageService.onUploadFinished.subscribe(
          (resp: CommonResponse<string>) => {
            if (resp.responseCode != "00") {
              this.messageService.add({
                severity: "error",
                summary: "Notice",
                detail: resp.responseMsg,
              });
            } else {
              postData.guarantors[0].pictureUrl = resp.responseData;
              this._getSecondGuarantorImage(postData);
            }
          },
          (error) => {
            console.log("Error while uploading file " + error);
            this.messageService.add({
              severity: "error",
              summary: "Notice",
              detail: "ERR: Unable to upload image/file to storage",
            });
          }
        );
      },
    });
  }

  async PassApplicantOperativeGua() {
    if (this.selectedOperativeGuarantor.length < 2) {
      this.messageService.add({
        severity: "error",
        summary: "Notice",
        detail: "You need a minimum of two guarantors to pass this applicant.",
      });

      this.TempReset();
      return;
    }

    if (this.selectedOperativeGuarantor.find((x) => x.vettingDocUrls == null)) {
      this.messageService.add({
        severity: "error",
        summary: "Notice",
        detail: "All guarantors must be vetted to pass this applicant.",
      });

      this.TempReset();
      return;
    }

    this.confirmationService.confirm({
      message: "This action is irreversible, are you sure?",
      accept: async () => {
        this.messageService.add({
          severity: "info",
          summary: "Notice",
          detail: "Performing Action...",
        });
        this.TempReset();

        const postData: ReviewApplicantOperativeGuarantorAssessment = {
          applicationEvaluationId: this.genPhaseApplicantInView.id,
          isPassed: true,
          operativeGuarantors: this.selectedOperativeGuarantor.map((x) => x.id),
        };

        this.employmentService
          .ReviewApplicantOperativeGuarantorAssessment(postData)
          .subscribe(
            async () => {
              await this.messageService.add({
                severity: "success",
                summary: "Completed",
                detail: "Action Successfull!",
              });

              var applicantEvaluation = this.generalPhaseApplicants.find(
                (x) => x.id == postData.applicationEvaluationId
              );

              const index =
                this.generalPhaseApplicants.indexOf(applicantEvaluation);
              if (postData.isPassed) {
                this.generalPhaseApplicants[index].guarantorAssessment = {
                  status: 1,
                };

                if (
                  applicantEvaluation.background &&
                  applicantEvaluation.guarantorAssessment &&
                  applicantEvaluation.cognitive &&
                  applicantEvaluation.physical
                ) {
                  if (index > -1) {
                    this.generalPhaseApplicants.splice(index, 1);
                  }

                  this.trainingPhaseApplicants.push(applicantEvaluation);

                  this.genPhaseApplicantInView = null;
                }
                this.guarantorsToAddList = [];
              } else {
                if (index > -1) {
                  this.generalPhaseApplicants.splice(index, 1);
                }

                this.genPhaseApplicantInView = null;
              }
              this.TempReset();
            },
            (error) => {
              console.log("Error: " + JSON.stringify(error));
              this.messageService.add({
                severity: "error",
                summary: "Action Failed",
                detail:
                  "Applicant's guarantor assessment review failed at the moment.. Reason: [" +
                  error
                    ? error.error.message
                    : "request failed - permission" + "]",
              });
              this.TempReset();
            }
          );
      },
    });
  }

  async SkipPassApplicantOperativeGua() {
    this.confirmationService.confirm({
      message: "This action is irreversible, are you sure?",
      accept: async () => {
        this.messageService.add({
          severity: "info",
          summary: "Notice",
          detail: "Performing Action...",
        });
        this.TempReset();

        const postData: ReviewApplicantOperativeGuarantorAssessment = {
          applicationEvaluationId: this.genPhaseApplicantInView.id,
          isPassed: true,
          operativeGuarantors:
            this.selectedOperativeGuarantor.length <= 0
              ? []
              : this.selectedOperativeGuarantor.map((x) => x.id),
        };

        this.employmentService
          .ReviewApplicantOperativeGuarantorAssessment(postData)
          .subscribe(
            async () => {
              await this.messageService.add({
                severity: "success",
                summary: "Completed",
                detail: "Action Successfull!",
              });

              var applicantEvaluation = this.generalPhaseApplicants.find(
                (x) => x.id == postData.applicationEvaluationId
              );

              const index =
                this.generalPhaseApplicants.indexOf(applicantEvaluation);
              if (postData.isPassed) {
                this.generalPhaseApplicants[index].guarantorAssessment = {
                  status: 1,
                };

                if (
                  applicantEvaluation.background &&
                  applicantEvaluation.guarantorAssessment &&
                  applicantEvaluation.cognitive &&
                  applicantEvaluation.physical
                ) {
                  if (index > -1) {
                    this.generalPhaseApplicants.splice(index, 1);
                  }

                  this.trainingPhaseApplicants.push(applicantEvaluation);

                  this.genPhaseApplicantInView = null;
                }
                this.guarantorsToAddList = [];
              } else {
                if (index > -1) {
                  this.generalPhaseApplicants.splice(index, 1);
                }

                this.genPhaseApplicantInView = null;
              }
              this.TempReset();
            },
            (error) => {
              console.log("Error: " + JSON.stringify(error));
              this.messageService.add({
                severity: "error",
                summary: "Action Failed",
                detail:
                  "Applicant's guarantor assessment review failed at the moment.. Reason: [" +
                  error
                    ? error.error.message
                    : "request failed - permission" + "]",
              });
              this.TempReset();
            }
          );
      },
    });
  }

  async _getSecondGuarantorImage(
    postData: ReviewApplicantGuarantorAsseessment
  ) {
    this.fileStorageService.UploadFileFromDataUrl(
      postData.guarantors[1].uploadedPictureData[0]
    );
    this.fileStorageService.onUploadFinished.subscribe(
      (resp: CommonResponse<string>) => {
        if (resp.responseCode != "00") {
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail: resp.responseMsg,
          });
        } else {
          postData.guarantors[1].pictureUrl = resp.responseData;
          this._getFirstGuarantorDeclarations(postData);
        }
      },
      (error) => {
        console.log("Error while uploading file " + error);
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail: "ERR: Unable to upload image/file to storage",
        });
      }
    );
  }

  async _getFirstGuarantorDeclarations(
    postData: ReviewApplicantGuarantorAsseessment
  ) {
    this.fileStorageService.UploadMultipleFilesFromDataUrl(
      postData.guarantors[0].uploadedDeclarationData
    );
    this.fileStorageService.onUploadFinished.subscribe(
      async (resp: CommonResponse<string[]>) => {
        if (resp.responseCode != "00") {
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail: resp.responseMsg,
          });
        } else {
          postData.guarantors[0].guarantorDeclarationFormUrls =
            resp.responseData;
          await this._getSecondGuarantorDeclarations(postData);
        }
      },
      (error) => {
        console.log("Error while uploading files " + error);
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail: "ERR: Unable to upload image(s)/file(s) to storage",
        });
      }
    );
  }

  async _getSecondGuarantorDeclarations(
    postData: ReviewApplicantGuarantorAsseessment
  ) {
    this.fileStorageService.UploadMultipleFilesFromDataUrl(
      postData.guarantors[1].uploadedDeclarationData
    );
    this.fileStorageService.onUploadFinished.subscribe(
      async (resp: CommonResponse<string[]>) => {
        if (resp.responseCode != "00") {
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail: resp.responseMsg,
          });
        } else {
          postData.guarantors[1].guarantorDeclarationFormUrls =
            resp.responseData;
          await this._reviewApplicantGuarantor(postData);
        }
      },
      (error) => {
        console.log("Error while uploading files " + error);
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail: "ERR: Unable to upload image(s)/file(s) to storage",
        });
      }
    );
  }

  async _reviewApplicantGuarantor(
    postData: ReviewApplicantGuarantorAsseessment
  ) {
    console.log("Entered Review");
    this.employmentService
      .ReviewApplicantGuarantorAssessment(postData)
      .subscribe(
        async () => {
          await this.messageService.add({
            severity: "success",
            summary: "Completed",
            detail: "Action Successfull!",
          });

          var applicantEvaluation = this.generalPhaseApplicants.find(
            (x) => x.id == postData.applicationEvaluationId
          );

          const index =
            this.generalPhaseApplicants.indexOf(applicantEvaluation);
          if (postData.isPassed) {
            this.generalPhaseApplicants[index].guarantorAssessment = {
              status: 1,
            };

            if (
              applicantEvaluation.background &&
              applicantEvaluation.guarantorAssessment &&
              applicantEvaluation.cognitive &&
              applicantEvaluation.physical
            ) {
              if (index > -1) {
                this.generalPhaseApplicants.splice(index, 1);
              }

              this.trainingPhaseApplicants.push(applicantEvaluation);

              this.genPhaseApplicantInView = null;
            }
            this.guarantorsToAddList = [];
          } else {
            if (index > -1) {
              this.generalPhaseApplicants.splice(index, 1);
            }

            this.genPhaseApplicantInView = null;
          }
          this.TempReset();
        },
        (error) => {
          console.log("Error: " + JSON.stringify(error));
          this.messageService.add({
            severity: "error",
            summary: "Action Failed",
            detail:
              "Applicant's guarantor assessment review failed at the moment.. Reason: [" +
              error
                ? error.error.message
                : "request failed - permission" + "]",
          });
          this.TempReset();
        }
      );
  }

  FailApplicantGua() {
    this.declineText =
      "Are you sure you want to fail this applicant's guarantor assessment, User will be dropped and not proceed to the next stage after this action is completed. Do you still wish to proceed?";
    this.declineCode = 4;
    this.openDeclineDialogue = true;
  }

  async FailApplicantGua_old() {
    this.confirmationService.confirm({
      message:
        "Are you sure you want to fail this applicant's guarantor assessment, User will be dropped and not proceed to the next stage after this action is completed. Do you still wish to proceed?",
      accept: async () => {
        this.messageService.add({
          severity: "info",
          summary: "Notice",
          detail: "Performing Action...",
        });

        const postData: ReviewApplicantGuarantorAsseessment = {
          applicationEvaluationId: this.genPhaseApplicantInView.id,
          isPassed: false,
          guarantors: [],
        };

        await this._reviewApplicantGuarantor(postData);
      },
    });
  }

  CalculateBG() {
    let failedCheckSize: boolean = false;
    this.generalPhaseChecksForJob.backgroundChecks.forEach((check) => {
      if (
        check.caption == "CriminalHistoryConfirmation" &&
        !this.criminalHistoryRadioButton
      )
        failedCheckSize = true;
      if (
        check.caption == "EducationalCredentialConfirmation" &&
        !this.educationConfirmationRadioButton
      )
        failedCheckSize = true;
      if (
        check.caption == "PrevEmploymentConfirmation" &&
        !this.preemployConfirmationRadioButton
      )
        failedCheckSize = true;
      if (
        check.caption == "ProfessionalPersonalConfirmation" &&
        !this.properConfirmationRadioButton
      )
        failedCheckSize = true;
    });

    if (failedCheckSize) {
      this.messageService.add({
        severity: "error",
        summary: "Notice",
        detail: "Kindly input all applicant's scores to calculate.",
      });

      this.BGScore = "Yet to be calculated";
      this.TempReset();
      return;
    }

    const postData: CalculateApplicantScore = {
      jobPostId: this.theManageJob.job.id,
      indicator: 3,
      hasCriminalHistory: this.criminalHistoryRadioButton == 1 ? true : false,
      hasEducationBeenConfirmed:
        this.educationConfirmationRadioButton == 1 ? true : false,
      hasPreEmploymentBeenConfirmed:
        this.preemployConfirmationRadioButton == 1 ? true : false,
      hasProfessionalPersonlBeenConfirmed:
        this.properConfirmationRadioButton == 1 ? true : false,
    };

    this.employmentService.CalculateApplicantScore(postData).subscribe(
      async (data) => {
        await this.messageService.add({
          severity: "success",
          summary: "Completed",
          detail: "Applicant Score Calcualted Successfully!",
        });

        this.BGScore = data;
        this.TempReset();
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to calculate applicant's score at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
        this.BGScore = "Yet to be calculate";
        this.TempReset();
      }
    );
  }

  FailApplicantBG() {
    let failedCheckSize: boolean = false;
    this.generalPhaseChecksForJob.backgroundChecks.forEach((check) => {
      if (
        check.caption == "CriminalHistoryConfirmation" &&
        !this.criminalHistoryRadioButton
      )
        failedCheckSize = true;
      if (
        check.caption == "EducationalCredentialConfirmation" &&
        !this.educationConfirmationRadioButton
      )
        failedCheckSize = true;
      if (
        check.caption == "PrevEmploymentConfirmation" &&
        !this.preemployConfirmationRadioButton
      )
        failedCheckSize = true;
      if (
        check.caption == "ProfessionalPersonalConfirmation" &&
        !this.properConfirmationRadioButton
      )
        failedCheckSize = true;
    });

    if (failedCheckSize) {
      this.messageService.add({
        severity: "error",
        summary: "Notice",
        detail: "Kindly input all applicant's scores to calculate.",
      });

      this.BGScore = "Yet to be calculated";
      this.TempReset();
      return;
    }

    this.declineText =
      "Are you sure you want to fail this applicant's background assessment, User will be dropped and not proceed to the next stage after this action is completed. Do you still wish to proceed?";
    this.declineCode = 5;
    this.openDeclineDialogue = true;
  }

  async FailApplicantBG_old() {
    if (
      !this.criminalHistoryRadioButton ||
      !this.educationConfirmationRadioButton ||
      !this.preemployConfirmationRadioButton ||
      !this.properConfirmationRadioButton
    ) {
      this.messageService.add({
        severity: "error",
        summary: "Notice",
        detail: "Kindly input all applicant's scores to calculate.",
      });

      this.BGScore = "Yet to be calculated";
      this.TempReset();
      return;
    }

    this.confirmationService.confirm({
      message:
        "Are you sure you want to fail this applicant's background assessment, User will be dropped and not proceed to the next stage after this action is completed. Do you still wish to proceed?",
      accept: async () => {
        this.messageService.add({
          severity: "info",
          summary: "Notice",
          detail: "Performing Action...",
        });

        const postData: ReviewApplicantBackgroundAsseessment = {
          applicationEvaluationId: this.genPhaseApplicantInView.id,
          hasCriminalHistory:
            this.criminalHistoryRadioButton == 1 ? true : false,
          hasEducationBeenConfirmed:
            this.educationConfirmationRadioButton == 1 ? true : false,
          hasPreEmploymentBeenConfirmed:
            this.preemployConfirmationRadioButton == 1 ? true : false,
          hasProfessionalPersonlBeenConfirmed:
            this.properConfirmationRadioButton == 1 ? true : false,
          supportingDocs: [],
          isPassed: false,
        };

        if (this.uploadedSupportDocBG.length > 0) {
          this.fileStorageService.UploadMultipleFilesFromDataUrl(
            this.uploadedSupportDocBG
          );
          this.fileStorageService.onUploadFinished.subscribe(
            async (resp: CommonResponse<string[]>) => {
              if (resp.responseCode != "00") {
                this.messageService.add({
                  severity: "error",
                  summary: "Notice",
                  detail: resp.responseMsg,
                });
              } else {
                postData.supportingDocs = resp.responseData;
                await this._reviewApplicantBG(postData);
              }
            },
            (error) => {
              console.log("Error while uploading files " + error);
              this.messageService.add({
                severity: "error",
                summary: "Notice",
                detail: "ERR: Unable to upload image(s)/file(s) to storage",
              });
            }
          );
        } else {
          await this._reviewApplicantBG(postData);
        }
      },
    });
  }

  async _reviewApplicantBG(postData: ReviewApplicantBackgroundAsseessment) {
    this.employmentService
      .ReviewApplicantBackgroundAssessment(postData)
      .subscribe(
        async () => {
          await this.messageService.add({
            severity: "success",
            summary: "Completed",
            detail: "Action Successfull!",
          });

          var applicantEvaluation = this.generalPhaseApplicants.find(
            (x) => x.id == postData.applicationEvaluationId
          );

          const index =
            this.generalPhaseApplicants.indexOf(applicantEvaluation);
          if (postData.isPassed) {
            this.generalPhaseApplicants[index].background = {
              status: 1,
            };

            if (
              applicantEvaluation.background &&
              applicantEvaluation.guarantorAssessment &&
              applicantEvaluation.cognitive &&
              applicantEvaluation.physical
            ) {
              if (index > -1) {
                this.generalPhaseApplicants.splice(index, 1);
              }

              this.trainingPhaseApplicants.push(applicantEvaluation);

              this.genPhaseApplicantInView = null;
            }
          } else {
            if (index > -1) {
              this.generalPhaseApplicants.splice(index, 1);
            }

            this.genPhaseApplicantInView = null;
          }
          this.TempReset();
        },
        (error) => {
          console.log("Error: " + JSON.stringify(error));
          this.messageService.add({
            severity: "error",
            summary: "Action Failed",
            detail:
              "Applicant's background assessment review failed at the moment.. Reason: [" +
              error
                ? error.error.message
                : "request failed - permission" + "]",
          });
          this.TempReset();
        }
      );
  }

  async PassApplicantBG() {
    let failedCheckSize: boolean = false;
    this.generalPhaseChecksForJob.backgroundChecks.forEach((check) => {
      if (
        check.caption == "CriminalHistoryConfirmation" &&
        !this.criminalHistoryRadioButton
      )
        failedCheckSize = true;
      if (
        check.caption == "EducationalCredentialConfirmation" &&
        !this.educationConfirmationRadioButton
      )
        failedCheckSize = true;
      if (
        check.caption == "PrevEmploymentConfirmation" &&
        !this.preemployConfirmationRadioButton
      )
        failedCheckSize = true;
      if (
        check.caption == "ProfessionalPersonalConfirmation" &&
        !this.properConfirmationRadioButton
      )
        failedCheckSize = true;
    });

    if (failedCheckSize) {
      this.messageService.add({
        severity: "error",
        summary: "Notice",
        detail: "Kindly input all applicant's scores to calculate.",
      });

      this.BGScore = "Yet to be calculated";
      this.TempReset();
      return;
    }

    if (this.uploadedSupportDocBG.length <= 0) {
      this.messageService.add({
        severity: "error",
        summary: "Notice",
        detail: "You must upload at least one(1) document.",
      });

      this.TempReset();
      return;
    }

    if (this.BGScore == "Fail") {
      this.approveOverrideText =
        "Are you sure you want to pass this applicant's background assessment even though the applicant has a fail recommendation, User will be proceeding to the next stage after this action is completed. Do you still wish to proceed?";
      this.approveOverrideCode = 3;
      this.openApproveOverrideDialogue = true;
    } else {
      this.confirmationService.confirm({
        message: "This action is irreversible, are you sure?",
        accept: async () => {
          this.messageService.add({
            severity: "info",
            summary: "Notice",
            detail: "Performing Action...",
          });

          const postData: ReviewApplicantBackgroundAsseessment = {
            applicationEvaluationId: this.genPhaseApplicantInView.id,
            hasCriminalHistory:
              this.criminalHistoryRadioButton == 1 ? true : false,
            hasEducationBeenConfirmed:
              this.educationConfirmationRadioButton == 1 ? true : false,
            hasPreEmploymentBeenConfirmed:
              this.preemployConfirmationRadioButton == 1 ? true : false,
            hasProfessionalPersonlBeenConfirmed:
              this.properConfirmationRadioButton == 1 ? true : false,
            supportingDocs: [],
            isPassed: true,
          };

          if (this.uploadedSupportDocBG.length > 0) {
            this.fileStorageService.UploadMultipleFilesFromDataUrl(
              this.uploadedSupportDocBG
            );
            this.fileStorageService.onUploadFinished.subscribe(
              async (resp: CommonResponse<string[]>) => {
                if (resp.responseCode != "00") {
                  this.messageService.add({
                    severity: "error",
                    summary: "Notice",
                    detail: resp.responseMsg,
                  });
                } else {
                  postData.supportingDocs = resp.responseData;
                  await this._reviewApplicantBG(postData);
                }
              },
              (error) => {
                console.log("Error while uploading files " + error);
                this.messageService.add({
                  severity: "error",
                  summary: "Notice",
                  detail: "ERR: Unable to upload image(s)/file(s) to storage",
                });
              }
            );
          } else {
            await this._reviewApplicantBG(postData);
          }
        },
      });
    }
  }

  async ApplicantBG() {
    if (this.BGScore == "Yet to be calculated") {
      this.messageService.add({
        severity: "error",
        summary: "Notice",
        detail: "Kindly calculate applicant's scores to proceed.",
      });
      this.TempReset();
      return;
    }

    if (this.uploadedSupportDocBG.length <= 0) {
      this.messageService.add({
        severity: "error",
        summary: "Notice",
        detail: "You must upload at least one(1) document.",
      });

      this.TempReset();
      return;
    }

    this.confirmationService.confirm({
      message:
        "The calculated scores will be used in grading applicant and is an irreversible action. Do you still wish to proceed?",
      accept: async () => {
        this.messageService.add({
          severity: "info",
          summary: "Notice",
          detail: "Performing Action...",
        });

        const postData: ReviewApplicantBackgroundAsseessment = {
          applicationEvaluationId: this.genPhaseApplicantInView.id,
          hasCriminalHistory:
            this.criminalHistoryRadioButton == 1 ? true : false,
          hasEducationBeenConfirmed:
            this.educationConfirmationRadioButton == 1 ? true : false,
          hasPreEmploymentBeenConfirmed:
            this.preemployConfirmationRadioButton == 1 ? true : false,
          hasProfessionalPersonlBeenConfirmed:
            this.properConfirmationRadioButton == 1 ? true : false,
          supportingDocs: [],
          isPassed: this.BGScore == "Pass" ? true : false,
        };

        if (this.uploadedSupportDocBG.length > 0) {
          this.fileStorageService.UploadMultipleFilesFromDataUrl(
            this.uploadedSupportDocBG
          );
          this.fileStorageService.onUploadFinished.subscribe(
            async (resp: CommonResponse<string[]>) => {
              if (resp.responseCode != "00") {
                this.messageService.add({
                  severity: "error",
                  summary: "Notice",
                  detail: resp.responseMsg,
                });
              } else {
                postData.supportingDocs = resp.responseData;
                await this._reviewApplicantBG(postData);
              }
            },
            (error) => {
              console.log("Error while uploading files " + error);
              this.messageService.add({
                severity: "error",
                summary: "Notice",
                detail: "ERR: Unable to upload image(s)/file(s) to storage",
              });
            }
          );
        } else {
          await this._reviewApplicantBG(postData);
        }
      },
    });
  }

  CalculateApplicantsAPPScore() {
    this.AppScoreLoading = false;
    //this.AppScore = "Recommendation: Invalid (Click to view why)";
    this.RunAppPhaseMessageJob(true);
    this.AppScore = "Click to view why";

    this.applicantsAppReqCheckList = [];
    this.theManageJob.job.applicationExamination.applicationRequirements
      .filter((x) => x.isDeleted == false)
      .forEach((req) => {
        let applicantScore = this.GetApplicantScoreForRequirement(
          req.requirementType,
          req.thresholdValue
        );
        let gradeExpected = req.thresholdValue ? "Yes" : "No";

        this.applicantsAppReqCheckList.push({
          key: this.GetApplicationRequirementTypeLabel(req.requirementType),
          value: gradeExpected,
          score: applicantScore,
        });

        if (applicantScore != gradeExpected) {
          this.RunAppPhaseMessageJob(false);
          this.AppScore = "Click to view why";
        }
      });
  }

  GetApplicantScoreForRequirement(type: number, grade: boolean): string {
    if (type == ApplicationRequirementTypeEnum.IsDiabetic)
      return this.appPhaseApplicantInView.additionalInfo.isDiabetic
        ? "Yes"
        : "No";
    else if (type == ApplicationRequirementTypeEnum.HasArthritus)
      return this.appPhaseApplicantInView.additionalInfo.hasArthritis
        ? "Yes"
        : "No";
    else if (type == ApplicationRequirementTypeEnum.IsHypertensive)
      return this.appPhaseApplicantInView.additionalInfo.isHypertensive
        ? "Yes"
        : "No";
    else if (type == ApplicationRequirementTypeEnum.IsGambling)
      return this.appPhaseApplicantInView.additionalInfo.isGambling
        ? "Yes"
        : "No";
    else if (type == ApplicationRequirementTypeEnum.IsSmoking)
      return this.appPhaseApplicantInView.additionalInfo.isSmoking
        ? "Yes"
        : "No";
    else if (type == ApplicationRequirementTypeEnum.IsDrinking)
      return this.appPhaseApplicantInView.additionalInfo.isDrinking
        ? "Yes"
        : "No";
    else if (type == ApplicationRequirementTypeEnum.IsSufferingUnknownDisease)
      return this.appPhaseApplicantInView.additionalInfo.isSufferingUnknown
        ? "Yes"
        : "No";
    else if (type == ApplicationRequirementTypeEnum.IsExMilitant)
      return this.appPhaseApplicantInView.medicalAccountInfo.isExMilitary
        ? "Yes"
        : "No";
    else if (type == ApplicationRequirementTypeEnum.HasCombatTraining)
      return this.appPhaseApplicantInView.medicalAccountInfo.hasCombatTraining
        ? "Yes"
        : "No";
    else if (type == ApplicationRequirementTypeEnum.HasBeenArrested)
      return this.appPhaseApplicantInView.medicalAccountInfo.hasBeenArrested
        ? "Yes"
        : "No";
    else if (type == ApplicationRequirementTypeEnum.HasBeenConvicted)
      return this.appPhaseApplicantInView.medicalAccountInfo.hasBeenConvicted
        ? "Yes"
        : "No";
    else if (type == ApplicationRequirementTypeEnum.HasBeenAskedToResign)
      return this.appPhaseApplicantInView.medicalAccountInfo
        .hasBeenAskedToResign
        ? "Yes"
        : "No";
    else return "Invalid";
  }

  ViewApplicationReqStats() {
    this.openAppRequirementDialogue = true;
  }

  async DeclineApplicant() {
    if (this.declineCode == 1) {
      this.messageService.add({
        severity: "info",
        summary: "Notice",
        detail: "Failing Applicant's Evaluation...",
      });

      const postData: ApplicationEvaluationReview = {
        applicationEvaluationId: this.appPhaseApplicantInView.id,
        isPassed: false,
        failureReason: this.formDecline.get("Comment").value,
        currentEvaluationStage: EvaluationStageEnum.ApplicationAssessment,
      };

      this.employmentService.ApplicantEvaluationReview(postData).subscribe(
        async () => {
          await this.messageService.add({
            severity: "success",
            summary: "Removed",
            detail: "Removed successfully",
          });

          var applicantEvaluation = this.applicationPhaseApplicants.find(
            (x) => x.id == postData.applicationEvaluationId
          );

          //Remove from list
          const index =
            this.applicationPhaseApplicants.indexOf(applicantEvaluation);
          if (index > -1) {
            this.applicationPhaseApplicants.splice(index, 1);
          }

          //Refresh List
          this.appPhaseApplicantInView = null;
        },
        (error) => {
          console.log("Error: " + JSON.stringify(error));
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail:
              "Unable to fail applicant's evaluation at the moment.. Reason: [" +
              error
                ? error.error.message
                : "request failed - permission" + "]",
          });
        }
      );
    } else if (this.declineCode == 2) {
      this.messageService.add({
        severity: "info",
        summary: "Notice",
        detail: "Performing Action...",
      });

      let scoreTracker: string = "";
      this.scoreKeeper
        .filter((x) => x.indicator == "PHY")
        .forEach((sco) => {
          scoreTracker =
            scoreTracker + sco.requirementId + ":" + sco.score + ";";
        });

      const postData: ReviewApplicantPhysicalAssessment = {
        applicationEvaluationId: this.genPhaseApplicantInView.id,
        scoreTrack: scoreTracker,
        supportingDocs: [],
        isPassed: false,
        failureReason: this.formDecline.get("Comment").value,
      };

      if (this.uploadedSupportDoc.length > 0) {
        this.fileStorageService.UploadMultipleFilesFromDataUrl(
          this.uploadedSupportDoc
        );
        this.fileStorageService.onUploadFinished.subscribe(
          async (resp: CommonResponse<string[]>) => {
            if (resp.responseCode != "00") {
              this.messageService.add({
                severity: "error",
                summary: "Notice",
                detail: resp.responseMsg,
              });
            } else {
              postData.supportingDocs = resp.responseData;
              await this._reviewApplicantPhy(postData);
            }
          },
          (error) => {
            console.log("Error while uploading files " + error);
            this.messageService.add({
              severity: "error",
              summary: "Notice",
              detail: "ERR: Unable to upload image(s)/file(s) to storage",
            });
          }
        );
      } else {
        await this._reviewApplicantPhy(postData);
      }
    } else if (this.declineCode == 3) {
      this.messageService.add({
        severity: "info",
        summary: "Notice",
        detail: "Performing Action...",
      });

      let scoreTracker: string = "";
      this.scoreKeeper
        .filter((x) => x.indicator == "COGN")
        .forEach((sco) => {
          scoreTracker =
            scoreTracker + sco.requirementId + ":" + sco.score + ";";
        });

      const postData: ReviewCognitivePhysicalAssessment = {
        applicationEvaluationId: this.genPhaseApplicantInView.id,
        scoreTrack: scoreTracker,
        supportingDocs: [],
        isPassed: false,
        failureReason: this.formDecline.get("Comment").value,
      };

      if (this.uploadedSupportDocCogn.length > 0) {
        this.fileStorageService.UploadMultipleFilesFromDataUrl(
          this.uploadedSupportDocCogn
        );
        this.fileStorageService.onUploadFinished.subscribe(
          async (resp: CommonResponse<string[]>) => {
            if (resp.responseCode != "00") {
              this.messageService.add({
                severity: "error",
                summary: "Notice",
                detail: resp.responseMsg,
              });
            } else {
              postData.supportingDocs = resp.responseData;
              await this._reviewApplicantCogn(postData);
            }
          },
          (error) => {
            console.log("Error while uploading files " + error);
            this.messageService.add({
              severity: "error",
              summary: "Notice",
              detail: "ERR: Unable to upload image(s)/file(s) to storage",
            });
          }
        );
      } else {
        await this._reviewApplicantCogn(postData);
      }
    } else if (this.declineCode == 4) {
      this.messageService.add({
        severity: "info",
        summary: "Notice",
        detail: "Performing Action...",
      });

      const postData: ReviewApplicantGuarantorAsseessment = {
        applicationEvaluationId: this.genPhaseApplicantInView.id,
        isPassed: false,
        guarantors: [],
        failureReason: this.formDecline.get("Comment").value,
      };

      await this._reviewApplicantGuarantor(postData);
    } else if (this.declineCode == 5) {
      this.messageService.add({
        severity: "info",
        summary: "Notice",
        detail: "Performing Action...",
      });

      const postData: ReviewApplicantBackgroundAsseessment = {
        applicationEvaluationId: this.genPhaseApplicantInView.id,
        hasCriminalHistory: this.criminalHistoryRadioButton == 1 ? true : false,
        hasEducationBeenConfirmed:
          this.educationConfirmationRadioButton == 1 ? true : false,
        hasPreEmploymentBeenConfirmed:
          this.preemployConfirmationRadioButton == 1 ? true : false,
        hasProfessionalPersonlBeenConfirmed:
          this.properConfirmationRadioButton == 1 ? true : false,
        supportingDocs: [],
        isPassed: false,
        failureReason: this.formDecline.get("Comment").value,
      };

      if (this.uploadedSupportDocBG.length > 0) {
        this.fileStorageService.UploadMultipleFilesFromDataUrl(
          this.uploadedSupportDocBG
        );
        this.fileStorageService.onUploadFinished.subscribe(
          async (resp: CommonResponse<string[]>) => {
            if (resp.responseCode != "00") {
              this.messageService.add({
                severity: "error",
                summary: "Notice",
                detail: resp.responseMsg,
              });
            } else {
              postData.supportingDocs = resp.responseData;
              await this._reviewApplicantBG(postData);
            }
          },
          (error) => {
            console.log("Error while uploading files " + error);
            this.messageService.add({
              severity: "error",
              summary: "Notice",
              detail: "ERR: Unable to upload image(s)/file(s) to storage",
            });
          }
        );
      } else {
        await this._reviewApplicantBG(postData);
      }
    } else if (this.declineCode == 6) {
      this.messageService.add({
        severity: "info",
        summary: "Notice",
        detail: "Performing Action...",
      });

      let scoreTracker: string = "";
      this.scoreKeeper
        .filter((x) => x.indicator == "TRAIN")
        .forEach((sco) => {
          scoreTracker =
            scoreTracker + sco.requirementId + ":" + sco.score + ";";
        });

      const postData: TrainingEvaluationReview = {
        applicationEvaluationId: this.trainPhaseApplicantInView.id,
        isPassed: false,
        failureReason: this.formDecline.get("Comment").value,
        scoreTrack: scoreTracker,
        supportingDocs: [],
      };

      if (this.uploadedTrainingResults.length > 0) {
        this.fileStorageService.UploadMultipleFilesFromDataUrl(
          this.uploadedTrainingResults
        );
        this.fileStorageService.onUploadFinished.subscribe(
          async (resp: CommonResponse<string[]>) => {
            if (resp.responseCode != "00") {
              this.messageService.add({
                severity: "error",
                summary: "Notice",
                detail: resp.responseMsg,
              });
            } else {
              postData.supportingDocs = resp.responseData;
              await this._reviewApplicantTraining(postData);
            }
          },
          (error) => {
            console.log("Error while uploading files " + error);
            this.messageService.add({
              severity: "error",
              summary: "Notice",
              detail: "ERR: Unable to upload image(s)/file(s) to storage",
            });
          }
        );
      } else {
        await this._reviewApplicantTraining(postData);
      }
    } else if (this.declineCode == 7) {
      this.messageService.add({
        severity: "info",
        summary: "Notice",
        detail: "Failing Applicant's Evaluation...",
      });

      const postData: ApplicationEvaluationReview = {
        applicationEvaluationId: this.postTrainPhaseApplicantInView.id,
        isPassed: false,
        failureReason: this.formDecline.get("Comment").value,
        currentEvaluationStage: EvaluationStageEnum.PostAssessment,
      };

      this.employmentService.ApplicantEvaluationReview(postData).subscribe(
        async () => {
          await this.messageService.add({
            severity: "success",
            summary: "Removed",
            detail: "Removed successfully",
          });

          var applicantEvaluation = this.postTrainingPhaseApplicants.find(
            (x) => x.id == postData.applicationEvaluationId
          );

          //Remove from list
          const index =
            this.postTrainingPhaseApplicants.indexOf(applicantEvaluation);
          if (index > -1) {
            this.postTrainingPhaseApplicants.splice(index, 1);
          }

          //Refresh List
          this.postTrainPhaseApplicantInView = null;
        },
        (error) => {
          console.log("Error: " + JSON.stringify(error));
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail:
              "Unable to fail applicant's evaluation at the moment.. Reason: [" +
              error
                ? error.error.message
                : "request failed - permission" + "]",
          });
        }
      );
    } else if (this.declineCode == 8) {
      this.messageService.add({
        severity: "info",
        summary: "Notice",
        detail: "Failing Applicant's Evaluation...",
      });

      const postData: ApplicationEvaluationReview = {
        applicationEvaluationId: this.onboardingTrainPhaseApplicantInView.id,
        isPassed: false,
        failureReason: this.formDecline.get("Comment").value,
        currentEvaluationStage: EvaluationStageEnum.Onboarding,
      };

      this.employmentService.ApplicantEvaluationReview(postData).subscribe(
        async () => {
          await this.messageService.add({
            severity: "success",
            summary: "Removed",
            detail: "Removed successfully",
          });

          var applicantEvaluation = this.onboardingPhaseApplicants.find(
            (x) => x.id == postData.applicationEvaluationId
          );

          //Remove from list
          const index =
            this.onboardingPhaseApplicants.indexOf(applicantEvaluation);
          if (index > -1) {
            this.onboardingPhaseApplicants.splice(index, 1);
          }

          //Refresh List
          this.onboardingTrainPhaseApplicantInView = null;
        },
        (error) => {
          console.log("Error: " + JSON.stringify(error));
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail:
              "Unable to fail applicant's evaluation at the moment.. Reason: [" +
              error
                ? error.error.message
                : "request failed - permission" + "]",
          });
        }
      );
    }

    this.formDecline.reset();
  }

  async ApproveOverrideApplicant() {
    if (this.approveOverrideCode == 1) {
      this.messageService.add({
        severity: "info",
        summary: "Notice",
        detail: "Performing Action...",
      });

      let scoreTracker: string = "";
      this.scoreKeeper
        .filter((x) => x.indicator == "PHY")
        .forEach((sco) => {
          scoreTracker =
            scoreTracker + sco.requirementId + ":" + sco.score + ";";
        });

      const postData: ReviewApplicantPhysicalAssessment = {
        applicationEvaluationId: this.genPhaseApplicantInView.id,
        scoreTrack: scoreTracker,
        supportingDocs: [],
        isPassed: true,
        failureReason: this.formApproveOverride.get("Comment").value,
      };

      this.openApproveOverrideDialogue = false;
      this.formApproveOverride.reset();

      if (this.uploadedSupportDoc.length > 0) {
        this.fileStorageService.UploadMultipleFilesFromDataUrl(
          this.uploadedSupportDoc
        );
        this.fileStorageService.onUploadFinished.subscribe(
          async (resp: CommonResponse<string[]>) => {
            if (resp.responseCode != "00") {
              this.messageService.add({
                severity: "error",
                summary: "Notice",
                detail: resp.responseMsg,
              });
            } else {
              postData.supportingDocs = resp.responseData;
              await this._reviewApplicantPhy(postData);
            }
          },
          (error) => {
            console.log("Error while uploading files " + error);
            this.messageService.add({
              severity: "error",
              summary: "Notice",
              detail: "ERR: Unable to upload image(s)/file(s) to storage",
            });
          }
        );
      } else {
        await this._reviewApplicantPhy(postData);
      }
      this.TempReset();
    } else if (this.approveOverrideCode == 2) {
      this.messageService.add({
        severity: "info",
        summary: "Notice",
        detail: "Performing Action...",
      });

      let scoreTracker: string = "";
      this.scoreKeeper
        .filter((x) => x.indicator == "COGN")
        .forEach((sco) => {
          scoreTracker =
            scoreTracker + sco.requirementId + ":" + sco.score + ";";
        });

      const postData: ReviewCognitivePhysicalAssessment = {
        applicationEvaluationId: this.genPhaseApplicantInView.id,
        scoreTrack: scoreTracker,
        supportingDocs: [],
        isPassed: true,
        failureReason: this.formApproveOverride.get("Comment").value,
      };

      this.openApproveOverrideDialogue = false;
      this.formApproveOverride.reset();

      if (this.uploadedSupportDocCogn.length > 0) {
        this.fileStorageService.UploadMultipleFilesFromDataUrl(
          this.uploadedSupportDocCogn
        );
        this.fileStorageService.onUploadFinished.subscribe(
          async (resp: CommonResponse<string[]>) => {
            if (resp.responseCode != "00") {
              this.messageService.add({
                severity: "error",
                summary: "Notice",
                detail: resp.responseMsg,
              });
            } else {
              postData.supportingDocs = resp.responseData;
              await this._reviewApplicantCogn(postData);
            }
          },
          (error) => {
            console.log("Error while uploading files " + error);
            this.messageService.add({
              severity: "error",
              summary: "Notice",
              detail: "ERR: Unable to upload image(s)/file(s) to storage",
            });
          }
        );
      } else {
        await this._reviewApplicantCogn(postData);
      }
    } else if (this.approveOverrideCode == 3) {
      this.messageService.add({
        severity: "info",
        summary: "Notice",
        detail: "Performing Action...",
      });

      const postData: ReviewApplicantBackgroundAsseessment = {
        applicationEvaluationId: this.genPhaseApplicantInView.id,
        hasCriminalHistory: this.criminalHistoryRadioButton == 1 ? true : false,
        hasEducationBeenConfirmed:
          this.educationConfirmationRadioButton == 1 ? true : false,
        hasPreEmploymentBeenConfirmed:
          this.preemployConfirmationRadioButton == 1 ? true : false,
        hasProfessionalPersonlBeenConfirmed:
          this.properConfirmationRadioButton == 1 ? true : false,
        supportingDocs: [],
        isPassed: true,
        failureReason: this.formApproveOverride.get("Comment").value,
      };

      this.openApproveOverrideDialogue = false;
      this.formApproveOverride.reset();

      if (this.uploadedSupportDocBG.length > 0) {
        this.fileStorageService.UploadMultipleFilesFromDataUrl(
          this.uploadedSupportDocBG
        );
        this.fileStorageService.onUploadFinished.subscribe(
          async (resp: CommonResponse<string[]>) => {
            if (resp.responseCode != "00") {
              this.messageService.add({
                severity: "error",
                summary: "Notice",
                detail: resp.responseMsg,
              });
            } else {
              postData.supportingDocs = resp.responseData;
              await this._reviewApplicantBG(postData);
            }
          },
          (error) => {
            console.log("Error while uploading files " + error);
            this.messageService.add({
              severity: "error",
              summary: "Notice",
              detail: "ERR: Unable to upload image(s)/file(s) to storage",
            });
          }
        );
      }
    } else if (this.approveOverrideCode == 4) {
      this.messageService.add({
        severity: "info",
        summary: "Notice",
        detail: "Performing Action...",
      });

      let scoreTracker: string = "";
      this.scoreKeeper
        .filter((x) => x.indicator == "TRAIN")
        .forEach((sco) => {
          scoreTracker =
            scoreTracker + sco.requirementId + ":" + sco.score + ";";
        });

      const postData: TrainingEvaluationReview = {
        applicationEvaluationId: this.trainPhaseApplicantInView.id,
        isPassed: true,
        scoreTrack: scoreTracker,
        supportingDocs: [],
      };

      if (this.uploadedTrainingResults.length > 0) {
        this.fileStorageService.UploadMultipleFilesFromDataUrl(
          this.uploadedTrainingResults
        );
        this.fileStorageService.onUploadFinished.subscribe(
          async (resp: CommonResponse<string[]>) => {
            if (resp.responseCode != "00") {
              this.messageService.add({
                severity: "error",
                summary: "Notice",
                detail: resp.responseMsg,
              });
            } else {
              postData.supportingDocs = resp.responseData;
              await this._reviewApplicantTraining(postData);
            }
          },
          (error) => {
            console.log("Error while uploading files " + error);
            this.messageService.add({
              severity: "error",
              summary: "Notice",
              detail: "ERR: Unable to upload image(s)/file(s) to storage",
            });
          }
        );
      } else {
        await this._reviewApplicantTraining(postData);
      }
    }
  }

  GetApplicantsTrainingInfo(jobApplicationId: number) {
    this.trainingClassName = "Loading...";
    this.employmentService.GetApplicantStudentInfo(jobApplicationId).subscribe(
      async (data) => {
        this.trainingClassName = data.trainingSchool.name;
        this.jobBatchCode = data.jobApplication.jobPost.jobCode;
        this.GetJobGeneralPhaseChecks(this.theManageJob.job.id);
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to get applicant's training info at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
        this.TempReset();
      }
    );
  }

  CalculateTraining() {
    if (
      this.generalPhaseChecksForJob.trainingChecks.length !=
      this.scoreKeeper.filter((x) => x.indicator == "TRAIN").length
    ) {
      this.messageService.add({
        severity: "error",
        summary: "Notice",
        detail: "Kindly input all applicant's scores to calculate.",
      });

      this.TrainingScore = "Yet to be calculated";
      this.TempReset();
      return;
    }

    const postData: CalculateApplicantScore = {
      jobPostId: this.theManageJob.job.id,
      indicator: 4,
      scores: [],
    };

    this.scoreKeeper
      .filter((x) => x.indicator == "TRAIN")
      .forEach((score) => {
        postData.scores.push({
          requirementId: score.requirementId,
          grade: score.score,
        });
      });

    this.employmentService.CalculateApplicantScore(postData).subscribe(
      async (data) => {
        await this.messageService.add({
          severity: "success",
          summary: "Completed",
          detail: "Applicant Score Calcualted Successfully!",
        });

        this.TrainingScore = data;
        this.TempReset();
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to calculate applicant's score at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
        this.TrainingScore = "Yet to be calculate";
        this.TempReset();
      }
    );
  }

  GetTrainingOverallScore(): number {
    // if (!this.TrainGreeting || !this.TrainMartialArt || !this.TrainSelfDefence)
    //   return 0;
    // else
    //   return this.TrainSelfDefence + this.TrainMartialArt + this.TrainGreeting;
    return 0;
  }

  FailApplicantTrain() {
    if (
      this.generalPhaseChecksForJob.trainingChecks.length !=
      this.scoreKeeper.filter((x) => x.indicator == "TRAIN").length
    ) {
      this.messageService.add({
        severity: "error",
        summary: "Notice",
        detail: "Kindly input all applicant's scores to proceed.",
      });

      this.TrainingScore = "Yet to be calculated";
      this.TempReset();
      return;
    }

    if (this.trainingClassName == "Loading...") {
      this.messageService.add({
        severity: "error",
        summary: "Notice",
        detail: "You can't act on applicant data till student info is created.",
      });

      this.TrainingScore = "Yet to be calculated";
      this.TempReset();
      return;
    }

    this.declineText =
      "Are you sure you want to fail this applicant's training assessment, User will be dropped and not proceed to the next stage after this action is completed. Do you still wish to proceed?";
    this.declineCode = 6;
    this.openDeclineDialogue = true;
  }

  async _reviewApplicantTraining(postData: TrainingEvaluationReview) {
    this.employmentService.TrainingEvaluationReview(postData).subscribe(
      async () => {
        await this.messageService.add({
          severity: "success",
          summary: "Completed",
          detail: "Action Successfull!",
        });

        var applicantEvaluation = this.trainingPhaseApplicants.find(
          (x) => x.id == postData.applicationEvaluationId
        );

        const index = this.trainingPhaseApplicants.indexOf(applicantEvaluation);
        if (index > -1) {
          this.trainingPhaseApplicants.splice(index, 1);
        }
        if (postData.isPassed) {
          this.postTrainingPhaseApplicants.push(applicantEvaluation);
        }
        this.trainPhaseApplicantInView = null;
        this.TempReset();
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Action Failed",
          detail:
            "Applicant's training assessment review failed at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
        this.TempReset();
      }
    );
  }

  async ApplicantTrain() {
    if (this.TrainingScore == "Yet to be calculated") {
      this.messageService.add({
        severity: "error",
        summary: "Notice",
        detail: "Kindly calculate applicant's scores to proceed.",
      });
      this.TempReset();
      return;
    }

    if (this.trainingClassName == "Loading...") {
      this.messageService.add({
        severity: "error",
        summary: "Notice",
        detail: "You can't act on applicant data till student info is created.",
      });

      this.TrainingScore = "Yet to be calculated";
      this.TempReset();
      return;
    }

    if (this.uploadedTrainingResults.length <= 0) {
      this.messageService.add({
        severity: "error",
        summary: "Notice",
        detail: "You must upload training result(s).",
      });

      this.TempReset();
      return;
    }

    this.confirmationService.confirm({
      message:
        "The calculated scores will be used in grading applicant and is an irreversible action. Do you still wish to proceed?",
      accept: async () => {
        this.messageService.add({
          severity: "info",
          summary: "Notice",
          detail: "Performing Action...",
        });

        let scoreTracker: string = "";
        this.scoreKeeper
          .filter((x) => x.indicator == "TRAIN")
          .forEach((sco) => {
            scoreTracker =
              scoreTracker + sco.requirementId + ":" + sco.score + ";";
          });

        const postData: TrainingEvaluationReview = {
          applicationEvaluationId: this.trainPhaseApplicantInView.id,
          isPassed: this.TrainingScore == "Pass" ? true : false,
          scoreTrack: scoreTracker,
          supportingDocs: [],
        };

        if (this.uploadedTrainingResults.length > 0) {
          this.fileStorageService.UploadMultipleFilesFromDataUrl(
            this.uploadedTrainingResults
          );
          this.fileStorageService.onUploadFinished.subscribe(
            async (resp: CommonResponse<string[]>) => {
              if (resp.responseCode != "00") {
                this.messageService.add({
                  severity: "error",
                  summary: "Notice",
                  detail: resp.responseMsg,
                });
              } else {
                postData.supportingDocs = resp.responseData;
                await this._reviewApplicantTraining(postData);
              }
            },
            (error) => {
              console.log("Error while uploading files " + error);
              this.messageService.add({
                severity: "error",
                summary: "Notice",
                detail: "ERR: Unable to upload image(s)/file(s) to storage",
              });
            }
          );
        } else {
          await this._reviewApplicantTraining(postData);
        }
      },
    });
  }

  async PassApplicantTrain() {
    if (
      this.generalPhaseChecksForJob.trainingChecks.length !=
      this.scoreKeeper.filter((x) => x.indicator == "TRAIN").length
    ) {
      this.messageService.add({
        severity: "error",
        summary: "Notice",
        detail: "Kindly input all applicant's scores to proceed.",
      });

      this.TrainingScore = "Yet to be calculated";
      this.TempReset();
      return;
    }

    if (this.trainingClassName == "Loading...") {
      this.messageService.add({
        severity: "error",
        summary: "Notice",
        detail: "You can't act on applicant data till student info is created.",
      });

      this.TrainingScore = "Yet to be calculated";
      this.TempReset();
      return;
    }

    if (this.uploadedTrainingResults.length <= 0) {
      this.messageService.add({
        severity: "error",
        summary: "Notice",
        detail: "You must upload training result(s).",
      });

      this.TempReset();
      return;
    }

    if (this.TrainingScore == "Fail") {
      this.approveOverrideText =
        "Are you sure you want to pass this applicant's training assessment even though the applicant has a fail recommendation, User will be proceeding to the next stage after this action is completed. Do you still wish to proceed?";
      this.approveOverrideCode = 4;
      this.openApproveOverrideDialogue = true;
    } else {
      this.confirmationService.confirm({
        message: "This action is irreversible, are you sure?",
        accept: async () => {
          this.messageService.add({
            severity: "info",
            summary: "Notice",
            detail: "Performing Action...",
          });

          let scoreTracker: string = "";
          this.scoreKeeper
            .filter((x) => x.indicator == "TRAIN")
            .forEach((sco) => {
              scoreTracker =
                scoreTracker + sco.requirementId + ":" + sco.score + ";";
            });

          const postData: TrainingEvaluationReview = {
            applicationEvaluationId: this.trainPhaseApplicantInView.id,
            isPassed: true,
            scoreTrack: scoreTracker,
            supportingDocs: [],
          };

          if (this.uploadedTrainingResults.length > 0) {
            this.fileStorageService.UploadMultipleFilesFromDataUrl(
              this.uploadedTrainingResults
            );
            this.fileStorageService.onUploadFinished.subscribe(
              async (resp: CommonResponse<string[]>) => {
                if (resp.responseCode != "00") {
                  this.messageService.add({
                    severity: "error",
                    summary: "Notice",
                    detail: resp.responseMsg,
                  });
                } else {
                  postData.supportingDocs = resp.responseData;
                  await this._reviewApplicantTraining(postData);
                }
              },
              (error) => {
                console.log("Error while uploading files " + error);
                this.messageService.add({
                  severity: "error",
                  summary: "Notice",
                  detail: "ERR: Unable to upload image(s)/file(s) to storage",
                });
              }
            );
          } else {
            await this._reviewApplicantTraining(postData);
          }
        },
      });
    }
  }

  ViewPostTrainingPhaseApplicant(data: ApplicantEvaluation) {
    this.postTrainPhaseApplicantInView = data;
    this.BiometricsViewHelper(this.postTrainPhaseApplicantInView.id);
    this.GetApplicantsTrainingSchoolResult(
      this.postTrainPhaseApplicantInView.id
    );
  }

  GetApplicantsTrainingSchoolResult(applicantEvaluationId: number) {
    this.applicantInViewTrainingScores = [];
    this.employmentService
      .GetApplicantTrainingResult(applicantEvaluationId)
      .subscribe(
        async (data) => {
          var results = data.resultData.scoreKeeper
            ? data.resultData.scoreKeeper.split(";")
            : [];
          results.forEach((result) => {
            var resultInfo = result.split(":");
            this.applicantInViewTrainingScores.push({
              name: data.dict.find(
                (x) => x.trainingEvaluationReqId == +resultInfo[0]
              ).courseName,
              data: resultInfo[1],
            });
          });
        },
        (error) => {
          console.log("Error: " + JSON.stringify(error));
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail:
              "Unable to retrieve applicant's traininig school results at the moment.. Reason: [" +
              error
                ? error.error.message
                : "request failed - permission" + "]",
          });
        }
      );
  }

  FailApplicantPostTraining() {
    this.declineText =
      "This action will fail the applicant's evaluation and wont proceed to the next stage. Do you still want to proceed?";
    this.declineCode = 7;
    this.openDeclineDialogue = true;
  }

  PassApplicantPostTraining() {
    if (this.applicantInViewBioData.find((x) => x == false) != null) {
      this.messageService.add({
        severity: "error",
        summary: "Notice",
        detail: "You can't pass applicant until all biometrics are taken",
      });
      return;
    }

    this.confirmationService.confirm({
      message:
        "This action will move the applicant to the next stage. Do you still want to proceed?",
      accept: () => {
        this.messageService.add({
          severity: "info",
          summary: "Notice",
          detail: "Passing Applicant's Evaluation...",
        });

        const postData: ApplicationEvaluationReview = {
          applicationEvaluationId: this.postTrainPhaseApplicantInView.id,
          isPassed: true,
          currentEvaluationStage: EvaluationStageEnum.PostAssessment,
        };

        this.employmentService.ApplicantEvaluationReview(postData).subscribe(
          async () => {
            await this.messageService.add({
              severity: "success",
              summary: "Removed",
              detail: "Applicant Passed Current Phase Successfully",
            });

            var applicantEvaluation = this.postTrainingPhaseApplicants.find(
              (x) => x.id == postData.applicationEvaluationId
            );

            //Remove from list
            const index =
              this.postTrainingPhaseApplicants.indexOf(applicantEvaluation);
            if (index > -1) {
              this.postTrainingPhaseApplicants.splice(index, 1);
            }

            //Push to next list
            this.onboardingPhaseApplicants.push(applicantEvaluation);

            //Refresh List
            this.postTrainPhaseApplicantInView = null;
          },
          (error) => {
            console.log("Error: " + JSON.stringify(error));
            this.messageService.add({
              severity: "error",
              summary: "Notice",
              detail:
                "Unable to pass applicant's evaluation at the moment.. Reason: [" +
                error
                  ? error.error.message
                  : "request failed - permission" + "]",
            });
          }
        );
      },
    });
  }

  ViewOnboardingTrainingPhaseApplicant(data: ApplicantEvaluation) {
    this.finalDeploymentStatus = false;
    this.onboardingTrainPhaseApplicantInView = data;
    this.GetApplicantsTrainingInfo(data.jobApplicationId);
    this.CheckApplicantDeploymentStatus(data.jobApplicationId);
  }

  CheckApplicantDeploymentStatus(jobApplicationId: number) {
    this.employmentService
      .CheckApplicantFinalDeploymentStatus(jobApplicationId)
      .subscribe(
        async (data) => {
          this.finalDeploymentStatus = data;
          if (
            data == true &&
            this.onboardingTrainPhaseApplicantInView.currentStage == 6
          ) {
            this.onboardingTrainPhaseApplicantInView.currentStage = 7;
          }
        },
        (error) => {
          console.log("Error: " + JSON.stringify(error));
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail:
              "Unable to confirm applicant's final deployment status at the moment.. Reason: [" +
              error
                ? error.error.message
                : "request failed - permission" + "]",
          });
        }
      );
  }

  async FetchAllClients() {
    this.employmentService.GetAllClients().subscribe(
      async (data) => {
        this.allFetchedClientsData = data;
        this.allClientTypes = [];
        data
          .map((x) => x.customer.groupType)
          .forEach((groupType) => {
            if (!this.allClientTypes.find((y) => y.id == groupType.id)) {
              this.allClientTypes.push(groupType);
            }
          });
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all clients at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  LoadClientsUnderClientType() {
    if (this.theClientType) {
      this.allClients = this.allFetchedClientsData.filter(
        (x) => x.customer.groupTypeId == this.theClientType.id
      );
    }
  }

  LoadClientLocations() {
    if (this.theClient) {
      this.allLocations = this.theClient.locations;
    }
  }

  LoadLocationContractServices() {
    if (this.theLocation) {
      this.allContractServices = [];
      this.theClient.contracts.forEach((contract) => {
        contract.contractServices.forEach((contractService) => {
          let checkValid = this.theLocation.serviceCategories.find(
            (x) =>
              x.serviceCategoryId == contractService.service.serviceCategoryId
          );
          if (checkValid) {
            const index = this.allContractServices.indexOf(contractService);
            if (index <= -1) {
              this.allContractServices.push(contractService);
            }
          }
        });
      });
      this.locDeployTable[0].data = this.theLocation.name;
      this.locDeployTable[1].data = this.theLocation.street;
      this.locDeployTable[2].data = this.theClient.divisionName;
      this.IncludeContractServiceLabels();
    }
  }

  LocationAttachmentAnanlysis() {
    if (this.theContractService) {
      this.locDeployTable[3].data =
        this.theContractService.quantity -
        this.theContractService.guardDeployments.filter(
          (x) => x.isDeleted == false
        ).length +
        "";
      this.locDeployTable[4].data = this.theContractService.service.name;
    }
  }

  DeployGuardToPool() {
    this.confirmationService.confirm({
      message:
        "You are about to move applicant to pool. Do you still want to proceed?",
      accept: () => {
        this.messageService.add({
          severity: "info",
          summary: "Notice",
          detail: "Moving Applicant To Pool...",
        });

        const postData: CreateGuardLocationDeployment = {
          applicationEvaluationId: this.onboardingTrainPhaseApplicantInView.id,
          locationId: 0,
          contractServiceId: 0,
          isPoolDeployment: true,
        };

        this.employmentService
          .DeployGuardApplicantToLocation(postData)
          .subscribe(
            async () => {
              await this.messageService.add({
                severity: "success",
                summary: "Removed",
                detail: "Applicant Moved To Pool Successfully",
              });

              var applicantEvaluation = this.onboardingPhaseApplicants.find(
                (x) => x.id == postData.applicationEvaluationId
              );

              applicantEvaluation.guardLocationDeployment = {
                locationId: postData.locationId,
                contractServiceId: postData.contractServiceId,
              };

              this.theContractService = null;
              this.theLocation = null;
              this.theClient = null;
              this.theClientType = null;
            },
            (error) => {
              console.log("Error: " + JSON.stringify(error));
              this.messageService.add({
                severity: "error",
                summary: "Notice",
                detail:
                  "Unable to move applicant to pool at the moment.. Reason: [" +
                  error
                    ? error.error.message
                    : "request failed - permission" + "]",
              });
            }
          );
      },
    });
  }

  DeployGuard() {
    this.confirmationService.confirm({
      message:
        "You are about to attach applicant to a location, once this is done, you can't attach applicant to another location. Do you still want to proceed?",
      accept: () => {
        this.messageService.add({
          severity: "info",
          summary: "Notice",
          detail: "Attaching Applicant To Location...",
        });

        const postData: CreateGuardLocationDeployment = {
          applicationEvaluationId: this.onboardingTrainPhaseApplicantInView.id,
          locationId: this.theLocation.id,
          contractServiceId: this.theContractService.id,
          isPoolDeployment: false,
        };

        if (+this.locDeployTable[3].data <= 0) {
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail:
              "Apologies, The number of operatives deployed to this location can't be more than the contract service quantity",
          });
          return;
        }

        this.employmentService
          .DeployGuardApplicantToLocation(postData)
          .subscribe(
            async () => {
              await this.messageService.add({
                severity: "success",
                summary: "Removed",
                detail: "Applicant Attached To Location Successfully",
              });

              var applicantEvaluation = this.onboardingPhaseApplicants.find(
                (x) => x.id == postData.applicationEvaluationId
              );

              applicantEvaluation.guardLocationDeployment = {
                locationId: postData.locationId,
                contractServiceId: postData.contractServiceId,
              };

              //Refresh List
              //this.onboardingTrainPhaseApplicantInView = null;
              this.theContractService = null;
              this.theLocation = null;
              this.theClient = null;
              this.theClientType = null;
            },
            (error) => {
              console.log("Error: " + JSON.stringify(error));
              this.messageService.add({
                severity: "error",
                summary: "Notice",
                detail:
                  "Unable to attach applicant to location at the moment.. Reason: [" +
                  error
                    ? error.error.message
                    : "request failed - permission" + "]",
              });
            }
          );
      },
    });
  }

  FailApplicantDeployment() {
    this.declineText =
      "This action will fail the applicant's evaluation and the applicant will be dropped. Do you still want to proceed?";
    this.declineCode = 8;
    this.openDeclineDialogue = true;
    this.theClientType = null;
    this.theClient = null;
    this.theLocation = null;
    this.theContractService = null;
  }

  MoveApplicantToMaterialRequest() {
    this.confirmationService.confirm({
      message:
        "This action will log the applicant to material request. Do you still want to proceed?",
      accept: () => {
        this.messageService.add({
          severity: "info",
          summary: "Notice",
          detail: "Moving Applicant To Material Request...",
        });

        const postData: ApplicationEvaluationReview = {
          applicationEvaluationId: this.onboardingTrainPhaseApplicantInView.id,
          isPassed: true,
          currentEvaluationStage: EvaluationStageEnum.Onboarding,
        };

        this.employmentService.ApplicantEvaluationReview(postData).subscribe(
          async () => {
            await this.messageService.add({
              severity: "success",
              summary: "Removed",
              detail: "Applicant Logged for material request Successfully",
            });

            var applicantEvaluation = this.onboardingPhaseApplicants.find(
              (x) => x.id == postData.applicationEvaluationId
            );

            applicantEvaluation.currentStage = 6;

            //Refresh List
            this.theClientType = null;
            this.theClient = null;
            this.theLocation = null;
            this.theContractService = null;
            this.onboardingTrainPhaseApplicantInView = null;
          },
          (error) => {
            console.log("Error: " + JSON.stringify(error));
            this.messageService.add({
              severity: "error",
              summary: "Notice",
              detail:
                "Unable to pass applicant's evaluation at the moment.. Reason: [" +
                error
                  ? error.error.message
                  : "request failed - permission" + "]",
            });
          }
        );
      },
    });
  }

  CompleteApplicantDeployment() {
    this.confirmationService.confirm({
      message:
        "This action will complete this applicant's onboaring phase and deploy the applicant to the attached. Do you still want to proceed?",
      accept: () => {
        this.messageService.add({
          severity: "info",
          summary: "Notice",
          detail: "Deploying Applicant...",
        });

        const postData: ApplicationEvaluationReview = {
          applicationEvaluationId: this.onboardingTrainPhaseApplicantInView.id,
          isPassed: true,
          currentEvaluationStage: EvaluationStageEnum.AwaitingDeployment,
        };

        this.employmentService.ApplicantEvaluationReview(postData).subscribe(
          async () => {
            await this.messageService.add({
              severity: "success",
              summary: "Removed",
              detail: "Applicant Deployed Successfully",
            });

            var applicantEvaluation = this.onboardingPhaseApplicants.find(
              (x) => x.id == postData.applicationEvaluationId
            );

            //Remove from list
            const index =
              this.onboardingPhaseApplicants.indexOf(applicantEvaluation);
            if (index > -1) {
              this.onboardingPhaseApplicants.splice(index, 1);
            }

            //Refresh List
            this.theClientType = null;
            this.theClient = null;
            this.theLocation = null;
            this.theContractService = null;
            this.onboardingTrainPhaseApplicantInView = null;
          },
          (error) => {
            console.log("Error: " + JSON.stringify(error));
            this.messageService.add({
              severity: "error",
              summary: "Notice",
              detail:
                "Unable to pass applicant's evaluation at the moment.. Reason: [" +
                error
                  ? error.error.message
                  : "request failed - permission" + "]",
            });
          }
        );
      },
    });
  }

  HideViewTrainScoreDialog() {
    this.openViewTrainScoreDialogue = false;
  }

  ViewApplicantTrainingScore() {
    this.openViewTrainScoreDialogue = true;
  }

  CaptureApplicantBiometrics() {
    this.openCapturePrintsDialogue = true;
  }

  HideCapturePrintsDialog() {
    this.openCapturePrintsDialogue = false;
  }

  CaptureBiometrics() {}

  CaptureFingerPrint() {
    this.CallSGIFPGetData();
  }

  CallSGIFPGetData() {
    var uri = "https://localhost:8443/SGIFPCapture";

    var xmlhttp = new XMLHttpRequest();
    xmlhttp.onreadystatechange = function () {
      if (xmlhttp.readyState == 4 && xmlhttp.status == 200) {
        let result = JSON.parse(xmlhttp.responseText);
        if (result.ErrorCode == 0) {
          /* 	Display BMP data in image tag
            BMP data is in base 64 format 
        */
          if (result != null && result.BMPBase64.length > 0) {
            (document.getElementById("FPImage1") as any).src =
              "data:image/bmp;base64," + result.BMPBase64;
            document.getElementById("FPImageTemp").innerHTML = result.BMPBase64;
            document.getElementById("FPBaseImageTemp").innerHTML =
              result.TemplateBase64;
            (document.getElementById("btnSaveFP") as any).disabled = false;
            //localStorage.setItem('src', result.BMPBase64)
          }
        } else
          alert(
            "Fingerprint Capture Error Code:  " +
              result.ErrorCode +
              ".\nDescription:  " +
              JSON.stringify(result.ErrorCode) +
              "."
          );
      } else if (xmlhttp.status == 404) {
        alert(
          "Check if SGIBIOSRV is running; Status = " + xmlhttp.status + ":"
        );
      }
    };
    var params = "Timeout=" + "10000";
    params += "&Quality=" + "50";
    params +=
      "&licstr=" +
      encodeURIComponent(this.fileStorageService.GetSecugenLicenseKeyStr());
    params += "&templateFormat=" + "ISO";
    params += "&imageWSQRate=" + "0.75";
    console.log;
    xmlhttp.open("POST", uri, true);
    xmlhttp.send(params);

    xmlhttp.onerror = function () {
      alert(
        "Check if SGIBIOSRV is running; Status = " + xmlhttp.statusText + ":"
      );
    };
  }

  SaveFingerPrint() {
    if (!this.theBiometricCapture) {
      this.messageService.add({
        severity: "error",
        summary: "Notice",
        detail: "Select Finger To Scan First!",
      });
      return;
    }

    // let fp = (document.getElementById("FPImage1") as any).src;
    // let fp = document.getElementById("FPImageTemp").innerHTML;
    let fp = document.getElementById("FPBaseImageTemp").innerHTML;
    const postData: AddApplicantBioData = {
      applicantEvaluationId: this.postTrainPhaseApplicantInView.id,
      biometricThump: this.theBiometricCapture.key,
      bMPBase64: fp,
    };

    this.employmentService.AddApplicantBioData(postData).subscribe(
      async () => {
        await this.messageService.add({
          severity: "success",
          summary: "Removed",
          detail: "Saved Successfully",
        });

        this.theBiometricCapture = null;
        (document.getElementById("FPImage1") as any).src = this.bioImgSrc;
        (document.getElementById("btnSaveFP") as any).disabled = true;
        this.BiometricsViewHelper(postData.applicantEvaluationId);
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to connect connect to FP Store server at the moment.. Reason: [" +
            JSON.stringify(error) +
            "]",
        });
      }
    );
  }

  BiometricsViewHelper(applicantEvaluationId: number) {
    this.applicantInViewBioData[0] = false;
    this.applicantInViewBioData[1] = false;
    this.applicantInViewBioData[2] = false;
    this.applicantInViewBioData[3] = false;
    this.applicantInViewBioData[4] = false;
    this.applicantInViewBioData[5] = false;
    this.applicantInViewBioData[6] = false;
    this.applicantInViewBioData[7] = false;
    this.applicantInViewBioData[8] = false;
    this.applicantInViewBioData[9] = false;
    this.leftBioPercentage = 0;
    this.rightBioPercentage = 0;
    this.employmentService.GetApplicantBioInfo(applicantEvaluationId).subscribe(
      async (data) => {
        data.forEach((bio) => {
          if (bio.thumbprint == BiometricCaptureAction.Left_Pinky) {
            this.applicantInViewBioData[0] = true;
            this.leftBioPercentage = this.leftBioPercentage + 20;
          } else if (bio.thumbprint == BiometricCaptureAction.Left_RingFinger) {
            this.leftBioPercentage = this.leftBioPercentage + 20;
            this.applicantInViewBioData[1] = true;
          } else if (
            bio.thumbprint == BiometricCaptureAction.Left_MiddleFinger
          ) {
            this.leftBioPercentage = this.leftBioPercentage + 20;
            this.applicantInViewBioData[2] = true;
          } else if (
            bio.thumbprint == BiometricCaptureAction.Left_IndexFinger
          ) {
            this.leftBioPercentage = this.leftBioPercentage + 20;
            this.applicantInViewBioData[3] = true;
          } else if (bio.thumbprint == BiometricCaptureAction.Left_Thumb) {
            this.leftBioPercentage = this.leftBioPercentage + 20;
            this.applicantInViewBioData[4] = true;
          } else if (bio.thumbprint == BiometricCaptureAction.Right_Thumb) {
            this.rightBioPercentage = this.rightBioPercentage + 20;
            this.applicantInViewBioData[5] = true;
          } else if (
            bio.thumbprint == BiometricCaptureAction.Right_IndexFinger
          ) {
            this.rightBioPercentage = this.rightBioPercentage + 20;
            this.applicantInViewBioData[6] = true;
          } else if (
            bio.thumbprint == BiometricCaptureAction.Right_MiddleFinger
          ) {
            this.rightBioPercentage = this.rightBioPercentage + 20;
            this.applicantInViewBioData[7] = true;
          } else if (
            bio.thumbprint == BiometricCaptureAction.Right_RingFinger
          ) {
            this.rightBioPercentage = this.rightBioPercentage + 20;
            this.applicantInViewBioData[8] = true;
          } else if (bio.thumbprint == BiometricCaptureAction.Right_Pinky) {
            this.rightBioPercentage = this.rightBioPercentage + 20;
            this.applicantInViewBioData[9] = true;
          }
        });

        if (this.leftBioPercentage > 100) this.leftBioPercentage = 100;
        if (this.rightBioPercentage > 100) this.rightBioPercentage = 100;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to connect fetch applicant's bio data at the moment.. Reason: [" +
            JSON.stringify(error) +
            "]",
        });
      }
    );
  }

  onInputGenScores(event, requirementId: number, indicate: string) {
    //Indicators: PHY COGN BG TRAIN
    const inputValue = event.target.value;
    let score = this.scoreKeeper.find((x) => x.requirementId == requirementId);
    if (score) {
      if (inputValue) {
        score.score = inputValue;
      } else {
        const index = this.scoreKeeper.indexOf(score);
        if (index > -1) {
          this.scoreKeeper.splice(index, 1);
        }
      }
    } else {
      if (inputValue) {
        this.scoreKeeper.push({
          requirementId: requirementId,
          score: inputValue,
          indicator: indicate,
        });
      }
    }
  }

  IsAmongBGCheck(identifier: number): boolean {
    if (this.generalPhaseChecksForJob) {
      if (identifier == 1)
        return (
          this.generalPhaseChecksForJob.backgroundChecks.find(
            (x) => x.caption == "CriminalHistoryConfirmation"
          ) != null
        );
      else if (identifier == 2)
        return (
          this.generalPhaseChecksForJob.backgroundChecks.find(
            (x) => x.caption == "EducationalCredentialConfirmation"
          ) != null
        );
      else if (identifier == 3)
        return (
          this.generalPhaseChecksForJob.backgroundChecks.find(
            (x) => x.caption == "PrevEmploymentConfirmation"
          ) != null
        );
      else if (identifier == 4)
        return (
          this.generalPhaseChecksForJob.backgroundChecks.find(
            (x) => x.caption == "ProfessionalPersonalConfirmation"
          ) != null
        );
    }

    return false;
  }

  IncludeContractServiceLabels() {
    this.allContractServices.forEach((contractServ) => {
      contractServ.label =
        contractServ.service.name + "-" + contractServ.uniqueTag;
    });

    this.allContractServices = this.allContractServices.filter(
      (x) => x.service.serviceRelationshipEnum == ServiceRelationshipEnum.Direct
    );
  }

  ViewOperative() {}
}
