import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { PilotType, VehicleType } from "src/app/interfaces/armada";
import { CommonResponse } from "src/app/interfaces/home";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class VehicleTypeService {
  //baseUrl = "https://localhost:44344/api/v1/Vehicles/";
  baseUrl = environment.baseUrl + "Vehicles/";
  constructor(private httpClient: HttpClient) {}

  allVehicleType(): Observable<CommonResponse> {
    return this.httpClient.get<CommonResponse>(
      this.baseUrl + "GetAllVehicleTypes"
    );
    //return this.http.get<Approval[]>(this.baseUrl + 'GetUserPendingApprovals');
  }
  getVehicleType(id: any): Observable<CommonResponse> {
    return this.httpClient.get<CommonResponse>(
      this.baseUrl + "GetTypeById/" + id
    );
  }

  postVehicleType(data: any) {
    return this.httpClient.post(this.baseUrl + "AddNewType", data);
  }
  delete(id: any) {
    return this.httpClient.delete(this.baseUrl + "DeleteTypeById/" + id);
  }
  updateVehicleType(id: any, data: any) {
    return this.httpClient.put(this.baseUrl + "UpdateTypeById/" + id, data);
  }

  //VehicleDriverMap
  allVehicleDriverMaps(): Observable<CommonResponse> {
    return this.httpClient.get<CommonResponse>(
      this.baseUrl + "GetAllVehicleDriverMaps"
    );
    //return this.http.get<Approval[]>(this.baseUrl + 'GetUserPendingApprovals');
  }
  getVehicleDriverMap(id: any): Observable<CommonResponse> {
    return this.httpClient.get<CommonResponse>(
      this.baseUrl + "GetVehicleDriverMapById/" + id
    );
  }

  getVehicleDriverMapByVehicleRegId(id: any): Observable<CommonResponse> {
    return this.httpClient.get<CommonResponse>(
      this.baseUrl + "GetVehicleDriverMapByVehicleRegId/" + id
    );
  }

  postVehicleDrivermap(data: any) {
    return this.httpClient.post(this.baseUrl + "AddNewVehicleDriverMap", data);
  }
  deleteVehicleDriverMap(id: any) {
    return this.httpClient.delete(
      this.baseUrl + "DeleteVehicleDriverMapById/" + id
    );
  }
}
