import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { AngularFireStorage } from "@angular/fire/storage";
import { FormBuilder } from "@angular/forms";
import { MessageService, ConfirmationService, Message } from "primeng/api";
import { BreadcrumbService } from "src/app/breadcrumb.service";
import { ApplicantBioData } from "src/app/interfaces/employment";
import { BiometricCaptureAction } from "src/app/interfaces/premployment";
import { FileStorageService } from "src/app/services/file-storage.service";
import { GmaEmploymentService } from "src/app/services/gma-employment.service";

@Component({
  selector: "app-gma-bio-search",
  templateUrl: "./gma-bio-search.component.html",
  styleUrls: ["./gma-bio-search.component.scss"],
  providers: [MessageService],
})
export class GmaBioSearchComponent implements OnInit {
  @ViewChild("formWrapper") public formWrapper: ElementRef;
  msg: Message[] = [];
  handSelected: number;
  allBiometricThumbs: {
    key: number;
    value: string;
  }[];
  theBiometricThumb: {
    key: number;
    value: string;
  };
  bioImgSrc: string = "assets/gma/PlaceFinger.bmp";
  showRightSide: boolean;
  allBioDatas: ApplicantBioData[] = [];
  searching: boolean;
  bioHit: ApplicantBioData;
  bioIndex: number = 0;
  allBioDatasInView: ApplicantBioData[] = [];
  showSearchResult: boolean;
  matchFound: boolean;
  matchImageUrl = "assets/no-profile.png";
  matchName = "N/A";

  constructor(
    fb: FormBuilder,
    public messageService: MessageService,
    public employmentService: GmaEmploymentService,
    public fireStorage: AngularFireStorage,
    public fileStorageService: FileStorageService,
    private breadcrumbService: BreadcrumbService,
    public confirmationService: ConfirmationService
  ) {}

  ngOnInit(): void {
    this.breadcrumbService.setItems([
      {
        label: "Guard Management",
        routerLink: ["/home/guard-management/item-settings"],
      },
      {
        label: "Bio-Search",
        routerLink: ["/home/guard-management/bio-search"],
      },
    ]);

    this.ResetMessageToasters();
    this.GetAllBioData();
  }

  ResetMessageToasters() {
    this.msg = [];
    this.msg.push({
      severity: "info",
      summary: "Notice:",
      detail: "You can search for operaties using fingerprint.",
    });
  }

  GetAllBioData() {
    this.employmentService.GetAllBiometricData().subscribe(
      async (data) => {
        this.allBioDatas = data;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to get required biometric data components at the moment.. Reason: [" +
            error
              ? error.error.message
              : "request failed - permission" + "]",
        });
      }
    );
  }

  OnFingerSelectionClick() {
    this.showRightSide = false;
    this.theBiometricThumb = null;
    if (this.handSelected == 1) {
      this.allBiometricThumbs = [
        {
          key: BiometricCaptureAction.Left_Pinky,
          value: "Left Pinky",
        },
        {
          key: BiometricCaptureAction.Left_RingFinger,
          value: "Left Ring Finger",
        },
        {
          key: BiometricCaptureAction.Left_MiddleFinger,
          value: "Left Middle Finger",
        },
        {
          key: BiometricCaptureAction.Left_IndexFinger,
          value: "Left Index Finger",
        },
        {
          key: BiometricCaptureAction.Left_Thumb,
          value: "Left Thumb",
        },
      ];
    } else if (this.handSelected == 2) {
      this.allBiometricThumbs = [
        {
          key: BiometricCaptureAction.Right_Thumb,
          value: "Right Thumb",
        },
        {
          key: BiometricCaptureAction.Right_IndexFinger,
          value: "Right Index Finger",
        },
        {
          key: BiometricCaptureAction.Right_MiddleFinger,
          value: "Right Middle Finger",
        },
        {
          key: BiometricCaptureAction.Right_RingFinger,
          value: "Right Ring Finger",
        },
        {
          key: BiometricCaptureAction.Right_Pinky,
          value: "Right Pinky",
        },
      ];
    }
  }

  OnBioThumbChange() {
    this.showRightSide = false;
  }

  CaptureFingerPrint() {
    this.showRightSide = false;
    this.CallSGIFPGetData();
  }

  CallSGIFPGetData() {
    var uri = "https://localhost:8443/SGIFPCapture";

    var xmlhttp = new XMLHttpRequest();
    xmlhttp.onreadystatechange = function () {
      if (xmlhttp.readyState == 4 && xmlhttp.status == 200) {
        let result = JSON.parse(xmlhttp.responseText);
        if (result.ErrorCode == 0) {
          /* 	Display BMP data in image tag
            BMP data is in base 64 format 
        */
          if (result != null && result.BMPBase64.length > 0) {
            (document.getElementById("FPImage1") as any).src =
              "data:image/bmp;base64," + result.BMPBase64;
            document.getElementById("FPImageTemp").innerHTML =
              result.TemplateBase64;
            (document.getElementById("btnSaveFP") as any).disabled = false;
            //localStorage.setItem('src', result.BMPBase64)
          }
        } else
          alert(
            "Fingerprint Capture Error Code:  " +
              result.ErrorCode +
              ".\nDescription:  " +
              JSON.stringify(result.ErrorCode) +
              "."
          );
      } else if (xmlhttp.status == 404) {
        alert(
          "Check if SGIBIOSRV is running; Status = " + xmlhttp.status + ":"
        );
      }
    };
    var params = "Timeout=" + "10000";
    params += "&Quality=" + "50";
    params +=
      "&licstr=" +
      encodeURIComponent(this.fileStorageService.GetSecugenLicenseKeyStr());
    params += "&templateFormat=" + "ISO";
    params += "&imageWSQRate=" + "0.75";
    xmlhttp.open("POST", uri, true);
    xmlhttp.send(params);

    xmlhttp.onerror = function () {
      alert(
        "Check if SGIBIOSRV is running; Status = " + xmlhttp.statusText + ":"
      );
    };
  }

  async PerformBioSearch() {
    if (!this.theBiometricThumb) {
      this.messageService.add({
        severity: "error",
        summary: "Notice",
        detail: "Select Finger To Scan First!",
      });
      return;
    }

    this.searching = true;
    this.showRightSide = true;
    this.bioHit = null;
    this.bioIndex = 0;
    this.showSearchResult = false;
    this.matchFound = false;
    this.matchImageUrl = "assets/no-profile.png";
    //this.allBioDatasInView =  this.allBioDatas.filter((x) => x.thumbprint == this.theBiometricThumb.key)
    this.allBioDatasInView = this.allBioDatas;

    localStorage.clear();
    localStorage.setItem("IMF", "0"); //Is MatchFound
    localStorage.setItem("TBCL", this.allBioDatasInView.length.toString()); //Total Bio Check Left
    this.allBioDatasInView.forEach((bio) => this.CheckIfItMatches(bio));
  }

  async CheckIfItMatches(data: ApplicantBioData) {
    let bio = document.getElementById("FPImageTemp").innerHTML;
    if (this.bioIndex >= this.allBioDatasInView.length) {
      //If it has no hit at this point it means match failed
      console.log("All Matches Failed");
      return;
    }

    var uri = "https://localhost:8443/SGIMatchScore";
    var xmlhttp = new XMLHttpRequest();
    xmlhttp.onreadystatechange = function (): boolean {
      if (xmlhttp.readyState == 4 && xmlhttp.status == 200) {
        let fpobject = JSON.parse(xmlhttp.responseText);
        if (fpobject.ErrorCode == 0) {
          if (fpobject.MatchingScore >= 100) return true;
        } else {
          alert(
            "Error Scanning Fingerprint ErrorCode = " +
              fpobject.ErrorCode +
              "; Kindly retry."
          );
        }
        return false;
      }
      return false;
    };

    xmlhttp.onerror = function () {
      alert("Error Scanning Fingerprint, Kindly retry.");
      return false;
    };
    var params = "template1=" + encodeURIComponent(bio);
    params += "&template2=" + encodeURIComponent(data.bmpBase64);
    params += "&licstr=" + encodeURIComponent("");
    params += "&templateFormat=" + "ISO";
    xmlhttp.addEventListener("load", this.MatchCheckCompleted);
    xmlhttp.open("POST", uri, false);
    setTimeout(() => {
      this.SearchResultListener();
    }, 5000);
    xmlhttp.send(params);
  }

  MatchCheckCompleted(event) {
    //Check If Match has been found
    let IMF = localStorage.getItem("IMF");
    if (+IMF >= 1) return;

    //Check If Successful
    let TBCL = localStorage.getItem("TBCL");
    let matchingScore = JSON.parse(event.currentTarget.response).MatchingScore;
    if (matchingScore > 100) {
      localStorage.setItem("IMF", "1");
      localStorage.setItem("MFC", (+TBCL).toString()); //Match Foun Count
    }

    //Not Succesful
    if (+TBCL > 0) {
      localStorage.setItem("TBCL", (+TBCL - 1).toString());
    }
  }

  SearchResultListener() {
    let IMF = localStorage.getItem("IMF");
    if (+IMF >= 1) {
      let MFC = localStorage.getItem("MFC");
      let matchIndex = this.allBioDatasInView.length - +MFC - 1;
      this.bioHit = this.allBioDatasInView[matchIndex];
      this.showSearchResult = true;
      // this.employmentService
      //   .GetApplicantEvaluationDetails(this.bioHit.applicantEvaluationId)
      //   .subscribe(
      //     async (data) => {
      //       this.matchName = data.fullname;
      //       this.matchImageUrl = data.imageUrl;
      //       this.matchFound = true;
      //     },
      //     (error) => {
      //       console.log("Error: " + JSON.stringify(error));
      //       this.messageService.add({
      //         severity: "error",
      //         summary: "Notice",
      //         detail:
      //           "Unable to get bio data at the moment.. Reason: [" + error
      //             ? error.error.message
      //             : "request failed - permission" + "]",
      //       });
      //     }
      //   );
      this.matchFound = true;

      this.searching = false;
      return;
    } else {
      let TBCL = localStorage.getItem("TBCL");
      if (+TBCL == 0) {
        this.showSearchResult = true;
        this.searching = false;
        return;
      }
    }

    setTimeout(() => {
      this.SearchResultListener();
    }, 5000);
  }
}
