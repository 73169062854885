import { Component, OnInit } from '@angular/core';
import {DynamicDialogConfig, DynamicDialogRef} from 'primeng/dynamicdialog';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ConfirmationService, MessageService} from 'primeng/api';

@Component({
    selector: 'app-task-details',
    templateUrl: './task-details.component.html',
    styleUrls: ['./task-details.component.css'],
    providers: [ConfirmationService]
})

export class TaskDetailsComponent implements OnInit {

    taskFulfillment: any;

    constructor(public config: DynamicDialogConfig,
                public taskDetailsModalRef: DynamicDialogRef,
                public confirmationService: ConfirmationService,
                public messageService: MessageService
    ) {}

    ngOnInit(): void {
        this.taskFulfillment = this.config.data.taskFulfillment;
    }

    closeModal() {
        this.taskDetailsModalRef.close();
    }
}
