import { PlatformBenefitService } from "./../../../services/platform-benefit.service";
import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { AngularFireStorage } from "@angular/fire/storage";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { ConfirmationService, Message, MessageService } from "primeng/api";
import { BreadcrumbService } from "src/app/breadcrumb.service";
import { EventInput } from "@fullcalendar/core";
import {
  CreatePlatformVM,
  Platform,
  UpdatePlatformVM,
} from "src/app/interfaces/platform-benefit";
import { finalize } from "rxjs/operators";
import { UserService } from "src/app/services/user.service";
import { CommonResponse, User } from "src/app/interfaces/home";
import { FileStorageService } from "src/app/services/file-storage.service";

@Component({
  selector: "app-pbm-create-platform",
  templateUrl: "./pbm-create-platform.component.html",
  styleUrls: ["./pbm-create-platform.component.scss"],
  providers: [MessageService],
})
export class PbmCreatePlatformComponent implements OnInit {
  @ViewChild("formWrapper") public formWrapper: ElementRef;
  platformForm: FormGroup;
  imageSrc: string;
  uploadingNewPicture = false;
  file: any;
  statusSwitch: boolean = false;
  editing: boolean;
  fetching: boolean;
  allPlatforms: Platform[] = [];
  summaryMsg: Message[] = [];
  platformToEdit: Platform;
  allUsers: User[] = [];

  constructor(
    private fb: FormBuilder,
    public fileStorageService: FileStorageService,
    public fireStorage: AngularFireStorage,
    public userService: UserService,
    public platformBenefitService: PlatformBenefitService,
    private breadcrumbService: BreadcrumbService,
    public confirmationService: ConfirmationService,
    public messageService: MessageService
  ) {
    this.platformForm = fb.group({
      ImageUrl: [""],
      Name: ["", Validators.required],
      Alias: ["", Validators.required],
      Description: ["", Validators.required],
    });
  }

  ngOnInit(): void {
    this.breadcrumbService.setItems([
      {
        label: "Platform Benefits Management",
        routerLink: ["/home/platform-benefit"],
      },
      {
        label: "Create Platform",
        routerLink: ["/home/platform-benefit/create-platform"],
      },
    ]);

    this.FetchAllUsers();
    this.ResetMessageToasters();
  }

  FetchAllPlatforms() {
    this.fetching = true;
    this.platformBenefitService.GetAllPlatforms().subscribe(
      async (data) => {
        this.allPlatforms = data;
        this.fetching = false;
        this.ResetMessageToasters();
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all platforms at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
        this.ResetMessageToasters();
        this.fetching = false;
      }
    );
  }

  async FetchAllUsers() {
    this.userService.allUser().subscribe(
      async (res) => {
        var data = res.responseData;
        data.forEach((user) => {
          user.fullName = user.lastName + " " + user.firstName;
          this.allUsers.push(user);
        });
        this.FetchAllPlatforms();
      },
      (err) => {
        console.log(err);
        this.messageService.add({
          severity: "error",
          summary: "Failed",
          detail: err ?? "Some errors occurred",
        });
      }
    );
  }

  GetUserFullName(id: number): string {
    let user = this.allUsers.find((x) => x.id == id);
    if (user) return user.fullName;

    return "N/A";
  }

  ResetMessageToasters() {
    this.summaryMsg = [];
    this.summaryMsg.push({
      severity: "info",
      summary: "Notice:",
      detail:
        "There are currently " +
        this.allPlatforms.length +
        " platforms created on the system.",
    });
  }

  onFileSelect(event) {
    if (!this.checkMimeType(event) || !this.checkFileSize(event)) {
      return;
    }

    this.uploadingNewPicture = true;
    const reader = new FileReader();
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      this.file = event.target.files[0];
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.imageSrc = reader.result as string;
      };
    }
  }

  checkMimeType = (event: EventInput): boolean => {
    const files = event.target.files[0];
    // list allow mime type
    const types = ["image/png", "image/jpeg", "image/jpg", "image/gif"];
    // loop access array
    if (!files) {
      this.messageService.add({
        severity: "error",
        summary: "Failed",
        detail: "Please select an Image file",
      });
      return false;
    }
    // compare file type find doesn't match
    if (types.every((type) => files.type !== type)) {
      // create error message and assign to container
      this.messageService.add({
        severity: "error",
        summary: "Failed",
        detail: "Image format not supported",
      });
      return false;
    }
    return true;
  };

  checkFileSize = (event: EventInput) => {
    const files = event.target.files[0];
    if (!files) return true;
    const size = 400000;
    if (files.size > size) {
      event.target.value = null;
      this.messageService.add({
        severity: "error",
        summary: "Failed",
        detail: "Image is too large, please pick a smaller file",
      });
      return false;
    }
    return true;
  };

  async CreatePlatform() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Creating Platform...",
    });

    const postData: CreatePlatformVM = {
      imageUrl: null,
      name: this.platformForm.get("Name").value,
      alias: this.platformForm.get("Alias").value,
      description: this.platformForm.get("Description").value,
      status: this.statusSwitch,
    };

    this.fileStorageService.UploadFileFromDataUrl(this.file);
    this.fileStorageService.onUploadFinished.subscribe(
      (resp: CommonResponse<string>) => {
        if (resp.responseCode != "00") {
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail: resp.responseMsg,
          });
          this.ResetMessageToasters();
        } else {
          postData.imageUrl = resp.responseData;
          this._createPlatform(postData);
        }
      },
      (error) => {
        console.log("Error while uploading file " + error);
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail: "ERR: Unable to upload image to storage",
        });
        this.ResetMessageToasters();
      }
    );
  }

  _createPlatform(postData: CreatePlatformVM) {
    this.platformBenefitService.CreatePlatform(postData).subscribe(
      async () => {
        this.messageService.add({
          severity: "success",
          summary: "Completed",
          detail: "Platform created successfully!",
        });

        this.platformForm.reset();
        this.statusSwitch = false;
        this.imageSrc = null;
        this.uploadingNewPicture = false;
        this.file = null;
        this.FetchAllPlatforms();
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to create platform at the moment.. Reason: [" +
            JSON.stringify(error) +
            "]",
        });
        this.ResetMessageToasters();
      }
    );
  }

  CloseEditing() {
    this.editing = false;
    this.platformForm.reset();
    this.platformToEdit = null;
    this.statusSwitch = false;
    this.imageSrc = null;
    this.uploadingNewPicture = false;
  }

  async UpdatePlatform() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Updating Platform...",
    });

    const id = this.platformToEdit.id;
    const postData: UpdatePlatformVM = {
      name: this.platformForm.get("Name").value,
      alias: this.platformForm.get("Alias").value,
      description: this.platformForm.get("Description").value,
      status: this.statusSwitch,
    };

    if (this.editing && !this.uploadingNewPicture) {
      this._updatePlatform(id, postData);
    } else {
      this.fileStorageService.UploadMultipleFilesFromDataUrl([this.file]);
      this.fileStorageService.onUploadFinished.subscribe(
        (resp: CommonResponse<string[]>) => {
          if (resp.responseCode != "00") {
            this.messageService.add({
              severity: "error",
              summary: "Notice",
              detail: resp.responseMsg,
            });
          } else {
            postData.imageUrl = resp.responseData[0];
            this._updatePlatform(id, postData);
          }
        },
        (error) => {
          console.log("Error while uploading files " + error);
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail: "ERR: Unable to upload image to storage",
          });
        }
      );
    }
  }

  _updatePlatform(id: number, postData: UpdatePlatformVM) {
    this.platformBenefitService.UpdatePlatform(id, postData).subscribe(
      async () => {
        this.messageService.add({
          severity: "success",
          summary: "Completed",
          detail: "Platform Updated Successfully.",
        });

        this.FetchAllPlatforms();
        this.editing = false;
        this.platformForm.reset();
        this.imageSrc = null;
        this.file = null;
        this.uploadingNewPicture = false;
        this.statusSwitch = false;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to update platform at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
        this.ResetMessageToasters();
      }
    );
  }

  SwitchStatus(item: Platform) {
    this.confirmationService.confirm({
      message: item.status
        ? "Are you sure you want to deactivate platform?"
        : "Are you sure you want to activate platform?",
      accept: () => {
        this.messageService.add({
          severity: "info",
          summary: "Notice",
          detail: "Performing Operation...",
        });

        this.platformBenefitService.SwitchPlatformStatus(item.id).subscribe(
          async () => {
            this.messageService.add({
              severity: "success",
              summary: "Removed",
              detail: "Removed successfully",
            });

            item.status = !item.status;
            this.ResetMessageToasters();
          },
          (error) => {
            console.log("Error: " + JSON.stringify(error));
            this.messageService.add({
              severity: "error",
              summary: "Notice",
              detail:
                "Unable to switch platform status at the moment.. Reason: [" +
                JSON.stringify(error) +
                "]",
            });
            this.ResetMessageToasters();
          }
        );
      },
    });
  }

  EditPlatform(item: Platform) {
    this.editing = true;
    this.platformForm.patchValue({
      Name: item.name,
      Alias: item.alias,
      Description: item.description,
    });
    this.imageSrc = item.imageUrl;
    this.statusSwitch = item.status;
    this.platformToEdit = item;

    this.formWrapper.nativeElement.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "start",
    });
  }

  DeletePlatform(item: Platform) {
    if (item.status) {
      this.messageService.add({
        severity: "error",
        summary: "Notice",
        detail: "You can't delete an active platform",
      });
      this.ResetMessageToasters();
      return;
    }

    this.confirmationService.confirm({
      message: "Are you sure you want to remove platform?",
      accept: () => {
        this.messageService.add({
          severity: "info",
          summary: "Notice",
          detail: "Removing platform...",
        });

        this.platformBenefitService.DeletePlatform(item.id).subscribe(
          async () => {
            this.messageService.add({
              severity: "success",
              summary: "Removed",
              detail: "Removed successfully",
            });

            this.fetching = true;
            const index = this.allPlatforms.indexOf(item);
            if (index > -1) {
              this.allPlatforms.splice(index, 1);
            }
            this.FetchAllPlatforms();
            this.fetching = false;
          },
          (error) => {
            console.log("Error: " + JSON.stringify(error));
            this.messageService.add({
              severity: "error",
              summary: "Notice",
              detail:
                "Unable to remove platform at the moment.. Reason: [" + error
                  ? error.error.message
                  : "request failed - permission" + "]",
            });
            this.ResetMessageToasters();
          }
        );
      },
    });
  }
}
