import { ComplaintHandlingService } from "./../../services/complaint-handling.service";
import { Complaint, ComplaintTrackingData } from "./../../interfaces/complaint";
import { ActivatedRoute } from "@angular/router";
import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { MessageService } from "primeng/api";
import { CommonResponse } from "src/app/interfaces/home";

@Component({
  selector: "app-confirm-complaint",
  templateUrl: "./confirm-complaint.component.html",
  styleUrls: ["./confirm-complaint.component.scss"],
})
export class ConfirmComplaintComponent implements OnInit {
  @ViewChild("formWrapper") public formWrapper: ElementRef;
  dark: boolean = true;
  checked: boolean;
  complaintTracking: ComplaintTrackingData;
  showOtherFooter = false;

  constructor(
    private route: ActivatedRoute,
    public complaintHandlingService: ComplaintHandlingService,
    public messageService: MessageService
  ) {}

  ngOnInit(): void {
    let complanitId = +this.route.snapshot.paramMap.get("id");

    this.GetComplaintInfoToConfirm(complanitId);
  }

  GetComplaintInfoToConfirm(id: number) {
    this.complaintHandlingService.trackComplaint("", id).subscribe(
      async (r: CommonResponse) => {
        var data = r.responseData;
        this.complaintTracking = data;
        if (data.complaint.isConfirmedResolved == true)
          this.showOtherFooter = true;
      },
      (error) => {
        this.ConnectionError(error.error.message);
      }
    );
  }

  GetComplainantName(item: Complaint) {
    return item.complainant == null
      ? "No Data"
      : item.complainant.supplierName
      ? item.complainant.supplierName
      : item.complainant.divisionName
      ? item.complainant.divisionName
      : item.complainant.lastName;
  }

  ConfirmComplaintResolved(id: number) {
    this.complaintHandlingService.confirmComplaintResolved(id).subscribe(
      async () => {
        this.messageService.add({
          severity: "success",
          summary: "Success",
          detail: "Thank you! Complaint has been confirmed resolved.",
        });
        this.complaintTracking = null;
        this.showOtherFooter = true;
      },
      () => {
        this.ConnectionError("Unable to confirm complaint resolved");
      }
    );
  }

  private ConnectionError(message = "") {
    this.messageService.add({
      severity: "error",
      summary: "Failed",
      detail: "Connection Error, Please try again " + message,
    });
  }
}
