import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../environments/environment";
import { Observable } from "rxjs";
import { CommonResponse, Division } from "../interfaces/home";

@Injectable({
  providedIn: "root",
})
export class DivisionService {
  constructor(private http: HttpClient) {}
  baseUrl = environment.baseUrl + "Division/";
  // fetch all Division in the database
  allDivisionData(): Observable<CommonResponse> {
    return this.http.get<CommonResponse>(this.baseUrl);
  }
  allDivisionDataWithSBU(): Observable<CommonResponse> {
    return this.http.get<CommonResponse>(
      this.baseUrl + "GetDivisionsOpEntityAndSbu"
    );
  }
  getDivision(id: any): Observable<CommonResponse> {
    return this.http.get<CommonResponse>(this.baseUrl + id);
  }
  getDivisionByName(name: any): Observable<CommonResponse> {
    return this.http.get<CommonResponse>(this.baseUrl + "name/" + name);
  }
  postDivision(data: any) {
    return this.http.post(this.baseUrl, data);
  }
  deleteDivision(id: any) {
    return this.http.delete(this.baseUrl + id);
  }
  updateDivision(id: any, data: any) {
    return this.http.put(this.baseUrl + id, data);
  }
  GetDivisions(): Observable<CommonResponse<Division[]>> {
    return this.http.get<CommonResponse<Division[]>>(
      this.baseUrl + "GetDivisions"
    );
  }
}
