import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import {
  ArmedEscortResourceRequiredPerService,
  CommanderResourceRequiredPerService,
  PilotResourceRequiredPerService,
  ServiceRegistration,
  VehicleResourceRequiredPerService,
} from "src/app/interfaces/armada";
import { CommonResponse, Services } from "src/app/interfaces/home";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class ServiceRegistrationService {
  baseUrl = environment.baseUrl;
  //baseUrl = "https://localhost:44344/api/v1/";
  constructor(private httpClient: HttpClient) {}

  allServiceReg(): Observable<CommonResponse> {
    return this.httpClient.get<CommonResponse>(
      this.baseUrl + "ServiceRegistration/GetAllRegisteredServices"
    );
  }

  getServiceReg(id: any): Observable<CommonResponse> {
    return this.httpClient.get<CommonResponse>(
      this.baseUrl + "ServiceRegistration/GetRegServiceById/" + id
    );
  }
  getServiceRegByServiceId(id: any): Observable<CommonResponse> {
    return this.httpClient.get<CommonResponse>(
      this.baseUrl + "ServiceRegistration/GetRegServiceByServiceId/" + id
    );
  }

  postServiceReg(data: any) {
    return this.httpClient.post(
      this.baseUrl + "ServiceRegistration/AddNewServiceReg",
      data
    );
  }
  deleteServiceReg(id: any) {
    return this.httpClient.delete(
      this.baseUrl + "ServiceRegistration/DeleteRegServiceById/" + id
    );
  }
  updateServiceReg(id: any, data: any) {
    return this.httpClient.put(
      this.baseUrl + "ServiceRegistration/UpdateRegServiceById/" + id,
      data
    );
  }
  allSecuredMobilityServices(): Observable<CommonResponse> {
    return this.httpClient.get<CommonResponse>(
      this.baseUrl + "Services/AllSecuredMobilityServices"
    );
    //return this.http.get<Approval[]>(this.baseUrl + 'GetUserPendingApprovals');
  }
  allServices(): Observable<CommonResponse> {
    return this.httpClient.get<CommonResponse>(this.baseUrl + "Services");
    //return this.http.get<Approval[]>(this.baseUrl + 'GetUserPendingApprovals');
  }

  //Update Types
  updateVehicleServiceType(id: any, data: any[]) {
    return this.httpClient.put(
      this.baseUrl + "ServiceRegistration/UpdateVehicleTypeServiceById/" + id,
      data
    );
  }

  updatePilotServiceType(id: any, data: any[]) {
    return this.httpClient.put(
      this.baseUrl + "ServiceRegistration/UpdatePilotTypeServiceById/" + id,
      data
    );
  }

  updateCommanderServiceType(id: any, data: any[]) {
    return this.httpClient.put(
      this.baseUrl + "ServiceRegistration/UpdateCommanderTypeServiceById/" + id,
      data
    );
  }

  updateArmedEscortServiceType(id: any, data: any[]) {
    return this.httpClient.put(
      this.baseUrl +
        "ServiceRegistration/UpdateArmedEscortTypeServiceById/" +
        id,
      data
    );
  }

  //ResourceRequired
  postResourceRequired(data: any) {
    return this.httpClient.post(
      this.baseUrl + "ServiceRegistration/AddNewResourceRequired",
      data
    );
  }

  //ArmedEscort
  allArmedEscortResource(): Observable<CommonResponse> {
    return this.httpClient.get<CommonResponse>(
      this.baseUrl + "ServiceRegistration/GetAllArmedEscortResourceRequired"
    );
  }

  getArmedEscortRescource(id: any): Observable<CommonResponse> {
    return this.httpClient.get<CommonResponse>(
      this.baseUrl +
        "ServiceRegistration/GetArmedEscortResourceRequiredById/" +
        id
    );
  }

  deleteArmedEscortResource(id: any) {
    return this.httpClient.delete(
      this.baseUrl +
        "ServiceRegistration/DeleteArmedEscortResourceRequiredById/" +
        id
    );
  }

  //Commander
  allCommanderResource(): Observable<CommonResponse> {
    return this.httpClient.get<CommonResponse>(
      this.baseUrl + "ServiceRegistration/GetAllCommanderResourceRequired"
    );
  }

  getCommanderRescource(id: any): Observable<CommonResponse> {
    return this.httpClient.get<CommonResponse>(
      this.baseUrl +
        "ServiceRegistration/GetCommanderResourceRequiredById/" +
        id
    );
  }

  deleteCommanderResource(id: any) {
    return this.httpClient.delete(
      this.baseUrl +
        "ServiceRegistration/DeleteCommanderResourceRequiredById/" +
        id
    );
  }

  //Pilot
  allPilotResource(): Observable<CommonResponse> {
    return this.httpClient.get<CommonResponse>(
      this.baseUrl + "ServiceRegistration/GetAllPilotResourceRequired"
    );
  }

  getPilotRescource(id: any): Observable<CommonResponse> {
    return this.httpClient.get<CommonResponse>(
      this.baseUrl + "ServiceRegistration/GetPilotResourceRequiredById/" + id
    );
  }

  deletePilotResource(id: any) {
    return this.httpClient.delete(
      this.baseUrl + "ServiceRegistration/DeletePilotResourceRequiredById/" + id
    );
  }

  //Vehicle
  allVehicleResource(): Observable<CommonResponse> {
    return this.httpClient.get<CommonResponse>(
      this.baseUrl + "ServiceRegistration/GetAllVehicleResourceRequired"
    );
  }

  getVehicleRescource(id: any): Observable<CommonResponse> {
    return this.httpClient.get<CommonResponse>(
      this.baseUrl + "ServiceRegistration/GetVehicleResourceRequiredById/" + id
    );
  }

  deleteVehicleResource(id: any) {
    return this.httpClient.delete(
      this.baseUrl +
        "ServiceRegistration/DeleteVehicleResourceRequiredById/" +
        id
    );
  }
}
