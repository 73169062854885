import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { TreeNode } from "primeng/api";
import { Observable } from "rxjs";
import { SMORegion, SMOReturnRoute, SMORoute } from "src/app/interfaces/armada";
import { CommonResponse } from "src/app/interfaces/home";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class SMORouteService {
  baseUrl = environment.baseUrl;
  //baseUrl = "https://localhost:44344/api/v1/";
  constructor(private httpClient: HttpClient) {}

  //Route
  allRoutes(): Observable<CommonResponse> {
    return this.httpClient.get<CommonResponse>(
      this.baseUrl + "SMORouteAndRegion/GetAllRoutes"
    );
  }

  allActiveRoutes(): Observable<CommonResponse> {
    return this.httpClient.get<CommonResponse>(
      this.baseUrl + "SMORouteAndRegion/GetAllSMORoutesWithReturnRoute"
    );
  }

  getRoute(id: any): Observable<CommonResponse> {
    return this.httpClient.get<CommonResponse>(
      this.baseUrl + "SMORouteAndRegion/GetRouteById/" + id
    );
  }

  checkIfFroState(stateId, routeId): Observable<CommonResponse> {
    return this.httpClient.get<CommonResponse>(
      this.baseUrl +
        "SMORouteAndRegion/CheckIfFroStateISAvailableOnRoute/" +
        stateId +
        "/" +
        routeId
    );
  }
  checkIfToState(stateId, routeId): Observable<CommonResponse> {
    return this.httpClient.get<CommonResponse>(
      this.baseUrl +
        "SMORouteAndRegion/CheckIfToStateISAvailableOnRoute/" +
        stateId +
        "/" +
        routeId
    );
  }
  postRoute(data: any) {
    return this.httpClient.post(
      this.baseUrl + "SMORouteAndRegion/AddNewRoute",
      data
    );
  }
  deleteRoute(id: any) {
    return this.httpClient.delete(
      this.baseUrl + "SMORouteAndRegion/DeleteRouteById/" + id
    );
  }
  updateRoute(id: any, data: any) {
    return this.httpClient.put(
      this.baseUrl + "SMORouteAndRegion/UpdateRouteById/" + id,
      data
    );
  }

  //Return Route
  postReturnRoute(data: any) {
    return this.httpClient.post(
      this.baseUrl + "SMORouteAndRegion/AddNewReturnRoute",
      data
    );
  }

  updateReturnRoute(id: any, data: any) {
    return this.httpClient.put(
      this.baseUrl + "SMORouteAndRegion/UpdateReturnRouteById/" + id,
      data
    );
  }

  deleteReturnRoute(id: any) {
    return this.httpClient.delete(
      this.baseUrl + "SMORouteAndRegion/DeleteReturnRouteById/" + id
    );
  }

  allReturnRoutes(): Observable<CommonResponse> {
    return this.httpClient.get<CommonResponse>(
      this.baseUrl + "SMORouteAndRegion/GetAllReturnRoutes"
    );
  }
  //Regions
  allRegions(): Observable<CommonResponse> {
    return this.httpClient.get<CommonResponse>(
      this.baseUrl + "SMORouteAndRegion/GetAllRegions"
    );
  }

  getRegion(id: any): Observable<CommonResponse> {
    return this.httpClient.get<CommonResponse>(
      this.baseUrl + "SMORouteAndRegion/GetRegionById/" + id
    );
  }

  postRegion(data: any) {
    return this.httpClient.post(
      this.baseUrl + "SMORouteAndRegion/AddNewRegion",
      data
    );
  }
  deleteRegion(id: any) {
    return this.httpClient.delete(
      this.baseUrl + "SMORouteAndRegion/DeleteRegionById/" + id
    );
  }
  updateRegion(id: any, data: any) {
    return this.httpClient.put(
      this.baseUrl + "SMORouteAndRegion/UpdateRegionById/" + id,
      data
    );
  }

  getFiles() {
    return this.httpClient
      .get<CommonResponse>(this.baseUrl + "SMORouteAndRegion/GetAllRegions")
      .toPromise()
      .then((res) => res.responseData as TreeNode[]);
  }

  //RouteMap
  allRouteMaps(): Observable<CommonResponse> {
    return this.httpClient.get<CommonResponse>(
      this.baseUrl + "SMORouteAndRegion/GetAllRouteMaps"
    );
  }

  getAllRouteMapsByRouteId(id: any): Observable<CommonResponse> {
    return this.httpClient.get<CommonResponse>(
      this.baseUrl + "SMORouteAndRegion/GetAllRouteMapsByRouteId/" + id
    );
  }
  getRouteMap(id: any): Observable<CommonResponse> {
    return this.httpClient.get<CommonResponse>(
      this.baseUrl + "SMORouteAndRegion/GetRouteMapById/" + id
    );
  }

  postRouteMap(data: any) {
    return this.httpClient.post(
      this.baseUrl + "SMORouteAndRegion/AddNewRouteMap",
      data
    );
  }
  deleteRouteMap(id: any) {
    return this.httpClient.delete(
      this.baseUrl + "SMORouteAndRegion/DeleteRouteMapById/" + id
    );
  }

  //To and Fro

  getToAndFroByRouteId(id: any): Observable<CommonResponse> {
    return this.httpClient.get<CommonResponse>(
      this.baseUrl + "SMORouteAndRegion/GetToAndFroMasterStateByRouteId/" + id
    );
  }
  getAllToAndFroByRouteId(id: any): Observable<CommonResponse> {
    return this.httpClient.get<CommonResponse>(
      this.baseUrl +
        "SMORouteAndRegion/GetAllToAndFroMasterStateByRouteId/" +
        id
    );
  }
  getAllToStateByRouteId(id: any): Observable<CommonResponse> {
    return this.httpClient.get<CommonResponse>(
      this.baseUrl + "SMORouteAndRegion/GetAllToStateMapByRouteId/" + id
    );
  }
  getAllFroStateByRouteId(id: any): Observable<CommonResponse> {
    return this.httpClient.get<CommonResponse>(
      this.baseUrl + "SMORouteAndRegion/GetAllFroStateMapByRouteId/" + id
    );
  }

  getToAndFroById(id: any): Observable<CommonResponse> {
    return this.httpClient.get<CommonResponse>(
      this.baseUrl + "SMORouteAndRegion/GetToandFroStateMapsById/" + id
    );
  }

  postNewToandFroMaster(data: any) {
    return this.httpClient.post(
      this.baseUrl + "SMORouteAndRegion/AddNewToAndFroStateMap",
      data
    );
  }
  deleteToandFroMaster(id: any) {
    return this.httpClient.delete(
      this.baseUrl + "SMORouteAndRegion/DeleteToAndFroMasterStateMap/" + id
    );
  }
  deleteToState(id: any) {
    return this.httpClient.delete(
      this.baseUrl + "SMORouteAndRegion/DeleteToStateMap/" + id
    );
  }
  deleteFroState(id: any) {
    return this.httpClient.delete(
      this.baseUrl + "SMORouteAndRegion/DeleteFroStateMap/" + id
    );
  }
  // getFiles() {
  //   return this.httpClient
  //     .get<any>("assets/demo/data/files.json")
  //     .toPromise()
  //     .then((res) => res.data as TreeNode[]);
  // }
}
