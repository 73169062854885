import { FileUpload } from "./../../demo/domain/FileUpload";
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { ArmadaRoutingModule } from "./armada-routing.module";
import { SituationTypeComponent } from "./setup/situation-type/situation-type.component";
import { CommanderRankComponent } from "./setup/commander-rank/commander-rank.component";
import { InputTextareaModule } from "primeng/inputtextarea";
import { TableModule } from "primeng/table";
import { InputTextModule } from "primeng/inputtext";
import { StickyNoteComponent } from "../../components/sticky-note/sticky-note.component";
import { DropdownModule } from "primeng/dropdown";
import { ButtonModule } from "primeng/button";
import { CommanderRegistrationComponent } from "./mobility-administration/commander-registration/commander-registration.component";
import { CardModule } from "primeng/card";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { ServiceRequestComponent } from "./mobility-administration/service-request/service-request.component";
import { CloseJourneyComponent } from "./mobility-administration/close-journey/close-journey.component";
import { ActiveServiceComponent } from "./dashboard/active-service/active-service.component";
import { RippleModule } from "primeng/ripple";
import { TooltipModule } from "primeng/tooltip";
import { ToastModule } from "primeng/toast";
import { ConfirmDialogModule } from "primeng/confirmdialog";
import { ConfirmationService, MessageService } from "primeng/api";
import { CreateButtonComponent } from "../../components/create-button/create-button.component";
import { RadioButtonModule } from "primeng/radiobutton";
import { TabViewModule } from "primeng/tabview";
import { ProgressBarModule } from "primeng/progressbar";
import { RatingModule } from "primeng/rating";
import { PanelModule } from "primeng/panel";
import { CheckboxModule } from "primeng/checkbox";
import { MultiSelectModule } from "primeng/multiselect";
import { FieldsetModule } from "primeng/fieldset";
import { SpinnerModule } from "primeng/spinner";
import { ChartModule } from "primeng/chart";
import { ArmedEscortRankComponent } from "./setup/armed-escort-rank/armed-escort-rank.component";
import { ArmedEscortTypeComponent } from "./setup/armed-escort-type/armed-escort-type.component";
import { ArmedEscortProfileComponent } from "./setup/armed-escort-profile/armed-escort-profile.component";
import { InputNumberModule } from "primeng/inputnumber";
import { ProgressSpinnerModule } from "primeng/progressspinner";
import { CalendarModule } from "primeng/calendar";
import { CompletedJourneyComponent } from "./mobility-administration/completed-journey/completed-journey.component";
import { MyCompletedJourneyComponent } from "./mobility-administration/my-completed-journey/my-completed-journey.component";
import { CompletedJourneyViewComponent } from "./mobility-administration/completed-journey-view/completed-journey-view.component";
import { CommanderTypeComponent } from "./setup/commander-type/commander-type.component";
import { ResourceTypeSetupComponent } from "./setup/resource-type-setup/resource-type-setup.component";
import { RankSettingsComponent } from "./setup/rank-settings/rank-settings.component";
import { ResourceRegistrationComponent } from "./setup/resource-registration/resource-registration.component";
import { AngularFireStorage } from "@angular/fire/storage";
import { RouteregionSegmentComponent } from "./setup/routeregion-segment/routeregion-segment.component";
import { DataViewModule } from "primeng/dataview";
import { InputSwitch, InputSwitchModule } from "primeng/inputswitch";
import { BusinessRuleComponent } from "./setup/business-rule/business-rule.component";
import { ServiceRegistrationComponent } from "./setup/service-registration/service-registration.component";
import { PriceRegisterComponent } from "./setup/price-register/price-register.component";
import { TreeModule } from "primeng/tree";
import { ResourceTieComponent } from "./setup/resource-tie/resource-tie.component";
import { ResourceSchedulingComponent } from "./setup/resource-scheduling/resource-scheduling.component";
import { TypesForServiceAssignmentComponent } from "./setup/types-for-service-assignment/types-for-service-assignment.component";
import { MasterServiceAssignmentComponent } from "./setup/master-service-assignment/master-service-assignment.component";
import { DialogModule } from "primeng/dialog";
import { GMapModule } from "primeng/gmap";
import { JmpInvoiceComponent } from "./setup/master-service-assignment/jmp-invoice/jmp-invoice.component";
import { MessagesModule } from "primeng/messages";
import { JourneyStartComponent } from "./setup/journey-start/journey-start.component";
import { SliderModule } from "primeng/slider";
import { DialogService } from "primeng/dynamicdialog";
import { RouteStateMapsComponent } from "./setup/routeregion-segment/route-state-maps/route-state-maps.component";
import { ResourceLookupComponent } from "./setup/resource-lookup/resource-lookup.component";
import { VehiclePilotMapComponent } from "./setup/vehicle-pilot-map/vehicle-pilot-map.component";
import { AdminEndJourneyComponent } from "./setup/journey-start/admin-end-journey/admin-end-journey.component";
import { PreferentialPricingComponent } from "./setup/preferential-pricing/preferential-pricing.component";
import { FileUploadModule } from "primeng/fileupload";
import { StatusDashboardComponent } from "./status-dashboard/status-dashboard.component";
import { MessageModule } from "primeng/message";
import { TruncatePipe } from "src/app/custom-pipes/truncate";

//import { JoEndComponent } from "./setup/jo-end/jo-end.component";
//import { JourneyEndFeedbackComponent } from "./setup/journey-end-feedback/journey-end-feedback.component";

@NgModule({
  declarations: [
    SituationTypeComponent,
    CommanderRankComponent,
    StickyNoteComponent,
    CommanderRegistrationComponent,
    ServiceRequestComponent,
    CloseJourneyComponent,
    ActiveServiceComponent,
    CreateButtonComponent,
    ArmedEscortRankComponent,
    ArmedEscortTypeComponent,
    ArmedEscortProfileComponent,
    CompletedJourneyComponent,
    MyCompletedJourneyComponent,
    CompletedJourneyViewComponent,
    CommanderTypeComponent,
    ResourceTypeSetupComponent,
    RankSettingsComponent,
    ResourceRegistrationComponent,
    RouteregionSegmentComponent,
    BusinessRuleComponent,
    ServiceRegistrationComponent,
    PriceRegisterComponent,
    ResourceTieComponent,
    ResourceSchedulingComponent,
    TypesForServiceAssignmentComponent,
    MasterServiceAssignmentComponent,
    JmpInvoiceComponent,
    JourneyStartComponent,
    RouteStateMapsComponent,
    ResourceLookupComponent,
    VehiclePilotMapComponent,
    AdminEndJourneyComponent,
    PreferentialPricingComponent,
    StatusDashboardComponent,
    TruncatePipe,
    //JoEndComponent,
    //JourneyEndFeedbackComponent,
  ],
  imports: [
    CommonModule,
    ArmadaRoutingModule,
    InputTextareaModule,
    TableModule,
    InputTextModule,
    DropdownModule,
    ButtonModule,
    CardModule,
    FormsModule,
    RippleModule,
    TooltipModule,
    ToastModule,
    ConfirmDialogModule,
    ReactiveFormsModule,
    RadioButtonModule,
    TabViewModule,
    ProgressBarModule,
    RatingModule,
    PanelModule,
    CheckboxModule,
    MultiSelectModule, 
    FieldsetModule,
    SpinnerModule,
    ChartModule,
    InputNumberModule,
    ProgressSpinnerModule,
    CalendarModule,
    DataViewModule,
    InputSwitchModule,
    TreeModule,
    DialogModule,
    GMapModule,
    MessagesModule,
    SliderModule,
    FileUploadModule,
    MessageModule,
  ],
  providers: [
    ConfirmationService,
    MessageService,
    AngularFireStorage,
    DialogService,
  ],
  exports: [CreateButtonComponent],
  entryComponents: [JmpInvoiceComponent],
})
export class ArmadaModule {}
