import { Component, OnInit } from "@angular/core";
import {
  DialogService,
  DynamicDialogConfig,
  DynamicDialogRef,
} from "primeng/dynamicdialog";
import {
  CData,
  Constant,
  GeneralService,
  QuoteServiceDocument,
  QuoteServices,
  RequiredServiceDocument,
  Services,
} from "../../../../interfaces/home";
import { ServiceDocumentFormComponent } from "../service-document-form/service-document-form.component";
import { LeadService } from "../../../../services/lead.service";
import { QuoteServiceDocumentService } from "../../../../services/quote-service-document.service";
import { QuoteServicesService } from "../../../../services/quote-services.service";

@Component({
  selector: "app-service-document",
  templateUrl: "./service-document.component.html",
  styleUrls: ["./service-document.component.scss"],
  providers: [Constant, DialogService],
})
export class ServiceDocumentComponent implements OnInit {
  quoteServiceDocuments: QuoteServiceDocument[];
  qualifyDocumentFormRef: DynamicDialogRef;
  selectedService: Services;
  selectedQuoteService: QuoteServices;
  invoiceInterval: CData[];
  paymentInterval: CData[];
  invoiceIntervalValue: CData;
  paymentCycleValue: CData;
  serviceRequiredDocument: RequiredServiceDocument[];
  value: false;
  private selectedGeneralService: GeneralService;
  private quoteService: QuoteServices;
  pageLoading = true;

  constructor(
    public config: DynamicDialogConfig,
    public serviceDocumentModalRef: DynamicDialogRef,
    public constant: Constant,
    public dialogService: DialogService,
    public leadService: LeadService,
    private quoteServiceDocumentService: QuoteServiceDocumentService,
    private quoteServiceServices: QuoteServicesService
  ) {
    this.invoiceInterval = constant.invoicingIntervals;
    this.paymentInterval = constant.paymentCycles;
  }

  ngOnInit(): void {
    if (this.config.data.hasOwnProperty("service")) {
      this.selectedGeneralService = this.config.data;
      this.selectedService = this.config.data.service;
      // this.selectedQuoteService = this.config.data.quoteService;
      this.serviceRequiredDocument =
        this.selectedService.requiredServiceDocument;
      // // set the invoice interval value
      // this.invoiceIntervalValue = this.invoiceInterval.find(interval => interval.id === this.selectedQuoteService.invoicingInterval);
      // this.paymentCycleValue = this.paymentInterval.find(interval => interval.id === this.selectedQuoteService.paymentCycle);
      // this.setDocument();
      this.fetchQuoteService();
    } else {
      this.selectedService = this.config.data;
    }
  }

  async fetchQuoteService() {
    this.quoteServiceServices
      .getQuoteServices(this.selectedGeneralService.quoteService.id)
      .subscribe(async (res) => {
        this.quoteService = res.responseData as QuoteServices;
        this.selectedQuoteService = this.quoteService;
        await this.getQuoteServiceDocuments();
        this.serviceRequiredDocument =
          this.selectedService.requiredServiceDocument;
        this.quoteServiceDocuments =
          this.selectedQuoteService.quoteServiceDocuments;
        this.invoiceIntervalValue = this.invoiceInterval.find(
          (interval) =>
            interval.id === this.selectedQuoteService.invoicingInterval
        );
        this.paymentCycleValue = this.paymentInterval.find(
          (interval) => interval.id === this.selectedQuoteService.paymentCycle
        );
        // this.serviceRequiredDocument = _requiredServiceDocuments;
        // if (_requiredServiceDocuments.length > 0) {
        //     // check if a the required document has been uploaded
        //     this.serviceRequiredDocument = [];
        //     for (const _document of _requiredServiceDocuments) {
        //         if (_document.type === 'negotiation') {
        //             this.serviceRequiredDocument.push(_document);
        //         }
        //     }
        // }
        this.pageLoading = false;
      });
  }

  // get all the quote service documents;
  getQuoteServiceDocuments() {
    this.quoteServiceDocumentService
      .getDocumentByQS(this.selectedQuoteService.id)
      .subscribe(async (result: any) => {
        // this.quoteServiceDocuments = result;
      });
  }

  closeServiceDocument() {
    this.serviceDocumentModalRef.close();
  }

  // set the document upload status
  setDocument() {
    console.log(this.selectedQuoteService.quoteServiceDocuments);
  }

  uploadDocument(serviceDocument: RequiredServiceDocument, id: number) {
    this.qualifyDocumentFormRef = this.dialogService.open(
      ServiceDocumentFormComponent,
      {
        header: "Quote service Document Upload",
        contentStyle: { "min-height": "120px", overflow: "auto" },
        baseZIndex: 100001,
        data: { document: serviceDocument, quoteServiceId: id },
      }
    );
    this.qualifyDocumentFormRef.onClose.subscribe(async (res) => {
      if (res === "complete") {
        await this.fetchQuoteService();
      }
    });
  }

  isDocumentUpload(caption: string): boolean {
    let _selectedDocument = null;
    _selectedDocument = this.quoteServiceDocuments.find(
      (rDocument) => rDocument.caption === caption
    );
    if (_selectedDocument) {
      return true;
    }
    return false;
  }
}
