import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {CommonResponse, Target} from '../../../interfaces/home';
import {ConfirmationService, MessageService} from 'primeng/api';
import {TargetService} from '../../../services/target.service';
import { BreadcrumbService } from 'src/app/breadcrumb.service';

@Component({
  selector: 'app-target',
  templateUrl: './target.component.html',
  styleUrls: ['./target.component.scss'],
  providers: [ConfirmationService, MessageService]
})
export class TargetComponent implements OnInit {
  @ViewChild('formWrapper') public formWrapper:ElementRef;
  fetchingTarget = true;
  targetForm: FormGroup;
  targets: Target[];
  selectedTarget: Target;
  editingTarget= false;
  public targetCols: any[];
  public exportTargetColumns: any[];
  private edTarget: Target;
  constructor(
      private formBuilder: FormBuilder,
      private targetService: TargetService,
      public messageService: MessageService,
      public confirmationService: ConfirmationService,
      private breadcrumbService: BreadcrumbService
  ) { }

  ngOnInit(): void {

      this.breadcrumbService.setItems([
          { label: 'Setup', routerLink: ['home/target'] },
          { label: 'Target', routerLink: ['home/target'] },
        ]);


    this.fetchTarget();
    this.targetForm = this.formBuilder.group({
      description: ['', Validators.required],
      caption: ['', [Validators.required]],
    });
    this.targetCols = [
      { field: 'caption', header: 'Caption' },
      { field: 'description', header: 'Description' },
    ];
    this.exportTargetColumns = this.targetCols.map(col => ({title: col.header, dataKey: col.field}));
  }
  fetchTarget(){
    this.targetService.allTarget().subscribe( async (target: CommonResponse) =>{
      this.targets = target.responseData;
      this.fetchingTarget = false;
    }, error => {
      this.fetchingTarget = false;
      this.connectionError();
    })
  }
  private connectionError() {
    this.messageService.add({severity:'error', summary: 'Failed',
      detail:'Connection Error, Please try again'});
  }
  createTarget(){
    this.messageService.add({severity:'info', summary: 'Notice',
      detail:'Creating new Target Records'});
    const postData = {
      description: this.targetForm.get('description').value,
      caption: this.targetForm.get('caption').value,
    };
    this.targetService.postTarget(postData).subscribe( async () =>{
      await this.messageService.add({severity:'success', summary: 'Completed',
        detail:'New Target Created'});
      await this.fetchTarget();
      await this.targetForm.reset();
    }, error => {
      this.messageService.add({severity:'error', summary: 'Notice',
        detail:'Unable to create new Target at the moment'});
    });
  }

  deleteTarget(target) {
    this.confirmationService.confirm({
      message: 'Are you sure you want to delete ' + target.name,
      accept: () => {
        this._deleteTarget(target);
      }
    });
  }
  _deleteTarget(target) {
    this.messageService.add({severity:'info', summary: 'Notice',
      detail:'Deleting target record'});
    this.targetService.deleteTarget(target.id).subscribe( async (result:any) => {
      await this.messageService.add({severity:'success', summary: 'Deleted',
        detail:'Target record removed'});
      await this.fetchTarget();
    }, error => {
      this.connectionError()
    });
  }
  editTarget(targets) {
    this.editingTarget = true;
    this.targetForm.addControl('targetId',  new FormControl({value: '', disabled: true}, Validators.required));
    this.edTarget = targets;
    this.targetForm.patchValue({
      caption: targets.caption,
      description: targets.description,
      targetId: targets.id,
    });
    // this.targetFormIn.nativeElement.focus();
    // document.getElementById('formFields').focus();
    // this.selectedHeadId = this.users.find(user => user.id === targets.head.id);
    this.formWrapper.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'start' });
  }

  updateTarget() {
    // update the selected target
    this.messageService.add({severity:'info', summary: 'Notice',
      detail:'Updating Target details'});
    const id = this.edTarget.id;
    const postData = {
      caption: this.targetForm.get('caption').value,
      description: this.targetForm.get('description').value,
    };
    this.targetService.updateTarget(id, postData).subscribe( async (result: Target) => {
      this.messageService.add({severity:'success', summary: 'Notice',
        detail:'Target details Updated'});
      await this.fetchTarget();
      await this.closeEditing();
    })
  }

  closeEditing() {
    this.editingTarget = false;
    this.targetForm.reset();
    this.edTarget = null;
  }
}
