import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ConfirmationService, MessageService } from "primeng/api";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { BreadcrumbService } from "src/app/breadcrumb.service";
import {
  ArmedEscortRank,
  ArmedEscortType,
  CommanderRank,
  CommanderType,
  PilotRank,
  PilotType,
} from "src/app/interfaces/armada";
import { CommonResponse } from "src/app/interfaces/home";
import { ArmedEscortRankService } from "src/app/services/armada/armed-escort-rank.service";
import { ArmedEscortTypeService } from "src/app/services/armada/armed-escort-type.service";
import { CommanderRankService } from "src/app/services/armada/commander-rank.service";
import { CommanderTypeService } from "src/app/services/armada/commander-type.service";
import { PilotRankService } from "src/app/services/armada/pilot-rank.service";
import { PilotTypeService } from "src/app/services/armada/pilot-type.service";

@Component({
  selector: "app-rank-settings",
  templateUrl: "./rank-settings.component.html",
  styleUrls: ["./rank-settings.component.scss"],
})
export class RankSettingsComponent implements OnInit, OnDestroy {
  @ViewChild("formWrapper") public formWrapper: ElementRef;
  private unsubscriber$ = new Subject<void>();

  public targetCols: any;
  private exportTargetColumns: any;
  public submittingData: boolean;
  public loading: boolean;
  public submitButtonLabel: string;
  //Armed Escort
  public armedEscortRanks: ArmedEscortRank[];
  public armedEscortRankForm: FormGroup;
  selectedArmedEscortRank: any;
  selectedArmedEscortType: ArmedEscortType;
  editingArmedEscortRank = false;
  private edArmedEscortRank: ArmedEscortRank;
  public armedEscortTypes: ArmedEscortType[];

  //Commander
  selectedCommanderRank: CommanderRank | null;
  public commanderRanks: CommanderRank[];
  public editingCommanderRank: boolean;
  private edCommanderRank: CommanderRank;
  public commanderTypes: CommanderType[];
  selectedCommanderType: CommanderType;
  //selectedCommanderTypee: CommanderType;
  public commanderRankForm: FormGroup;

  //pilot
  selectedPilotRank: PilotRank | null;
  public pilotRanks: PilotRank[];
  public editingPilotRank: boolean;
  private edPilotRank: PilotRank;
  public pilotTypes: PilotType[];
  selectedPilotType: PilotType;
  public pilotRankForm: FormGroup;

  constructor(
    private breadcrumbService: BreadcrumbService,
    private armedEscortRankService: ArmedEscortRankService,
    private armedEscortTypeService: ArmedEscortTypeService,
    private commanderRankService: CommanderRankService,
    private commanderTypeService: CommanderTypeService,
    private pilotRankService: PilotRankService,
    private pilotTypeService: PilotTypeService,
    public messageService: MessageService,
    public confirmationService: ConfirmationService,
    private formBuilder: FormBuilder
  ) {
    this.breadcrumbService.setItems([
      { label: "Setup" },
      {
        label: "Rank Settings",
        //routerLink: ["home/armada/setup/rank-settings"],
      },
    ]);
  }
  ngOnInit(): void {
    this.loading = true;
    // this.targetCols = [
    //   { field: 'rankName', header: 'Rank Name' },
    //   { field: 'alias', header: 'Alias' },
    //   { field: 'description', header: 'Description' },
    //   { field: 'armedEscortTypeId', header: 'Escort TypeId' },
    //   //{ field: 'codeName', header: 'Code Name' }
    // ];
    // this.exportTargetColumns = this.targetCols.map(col => ({title: col.header, dataKey: col.field}));
    // this.armedEscortRankForm = this.formBuilder.group( {
    //   rankName: ['', [Validators.required, Validators.minLength(3)]],
    //   alias: ['', [Validators.required, Validators.minLength(3)]],
    //   description: ['', [Validators.required, Validators.minLength(3)]],
    //   armedEscortTypeId: [null, [Validators.required]],
    //   //sequence: [''],
    // });
    //Commander
    this.targetCols = [
      { field: "rankName", header: "Rank Name" },
      { field: "alias", header: "Alias" },
      { field: "description", header: "Description" },
      { field: "commanderTypeId", header: "Commander Type" },
    ];
    this.exportTargetColumns = this.targetCols.map((col) => ({
      title: col.header,
      dataKey: col.field,
    }));

    this.commanderRankForm = this.formBuilder.group({
      rankName: ["", [Validators.required, Validators.minLength(5)]],
      alias: ["", [Validators.required, Validators.minLength(5)]],
      description: ["", [Validators.required, Validators.minLength(5)]],
      commanderTypeId: [null, [Validators.required]],
      // preceedingRankId: [{value: '', disabled:true}],
      // nextRankId: [{value: '', disabled:true}],
      // sequence: [{value: '', disabled:true}],
    });

    //armed escort
    this.targetCols = [
      { field: "rankName", header: "Rank Name" },
      { field: "alias", header: "Alias" },
      { field: "description", header: "Description" },
      { field: "armedEscortTypeId", header: "Escort TypeId" },
    ];
    this.exportTargetColumns = this.targetCols.map((col) => ({
      title: col.header,
      dataKey: col.field,
    }));
    this.armedEscortRankForm = this.formBuilder.group({
      rankName: ["", [Validators.required, Validators.minLength(3)]],
      alias: ["", [Validators.required, Validators.minLength(3)]],
      description: ["", [Validators.required, Validators.minLength(3)]],
      armedEscortTypeId: [null, [Validators.required]],
      //sequence: [''],
    });

    //pilot
    this.targetCols = [
      { field: "rankName", header: "Rank Name" },
      { field: "alias", header: "Alias" },
      { field: "description", header: "Description" },
      { field: "pilotTypeId", header: "pilot TypeId" },
    ];
    this.exportTargetColumns = this.targetCols.map((col) => ({
      title: col.header,
      dataKey: col.field,
    }));
    this.pilotRankForm = this.formBuilder.group({
      rankName: ["", [Validators.required, Validators.minLength(3)]],
      alias: ["", [Validators.required, Validators.minLength(3)]],
      description: ["", [Validators.required, Validators.minLength(3)]],
      pilotTypeId: [null, [Validators.required]],
    });

    this.fetchCommanderRank();
    this.fetchCommanderType();
    this.fetchAllArmedEscortType();
    this.fetchAllArmedEscortRank();
    this.fetchAllPilotType();
    this.fetchAllPilotRank();
    this.submitButtonLabel = "Create";
  }
  //Armed Escort
  fetchAllArmedEscortType(): void {
    this.armedEscortTypeService
      .allArmedEscortType()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            var res = r.responseData;
            this.armedEscortTypes = res;
          }
        },
        (error) => this.connectionError()
      );
  }
  fetchAllArmedEscortRank(): void {
    this.armedEscortRankService
      .allArmedEscortRank()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            var res = r.responseData;
            this.armedEscortRanks = res;
            this.loading = false;
          }
        },
        (error) => {
          this.loading = false;
          this.connectionError();
        }
      );
  }
  createArmedEscortRank() {
    this.submittingData = true;
    const formData = this.armedEscortRankForm.value;
    const data = {
      rankName: formData.rankName,
      alias: formData.alias,
      description: formData.description,
      armedEscortTypeId: this.selectedArmedEscortType.id,
      // sequence: 0
    };
    this.armedEscortRankService
      .postArmedEscortRank(data)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            this.messageService.add({
              summary: "Success",
              severity: "success",
              detail: "New Rank created",
            });
            this.fetchAllArmedEscortRank();
            this.submittingData = false;
            this.armedEscortRankForm.reset();
          } else {
            this.messageService.add({
              severity: "error",
              summary: "Failed",
              detail: " Error: " + r.responseMsg,
            });
            this.submittingData = false;
          }
        },
        (error) => {
          this.messageService.add({
            severity: "error",
            summary: "Failed",
            detail: "Connection Error " + error.error.message,
          });
          this.submittingData = false;
        }
      );
  }

  deleteArmedEscortRank(armedEscortRank: ArmedEscortRank): void {
    this.confirmationService.confirm({
      message: "Are you sure you want to delete " + armedEscortRank.rankName,
      accept: () => {
        this._deleteArmedEscortRank(armedEscortRank);
      },
    });
  }
  _deleteArmedEscortRank(armedEscortRank) {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Deleting Rank record",
    });
    this.armedEscortRankService.delete(armedEscortRank.id).subscribe(
      async (result: any) => {
        await this.messageService.add({
          severity: "success",
          summary: "Deleted",
          detail: "Rank record removed",
        });
        await this.fetchAllArmedEscortRank();
      },
      (error) => {
        this.connectionError();
      }
    );
  }
  editArmedEscortRank(armedEscortRank: ArmedEscortRank): void {
    this.edArmedEscortRank = armedEscortRank;
    this.formWrapper.nativeElement.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "start",
    });
    this.armedEscortRankForm.setValue({
      rankName: armedEscortRank.rankName,
      alias: armedEscortRank.alias,
      description: armedEscortRank.description,
      //sequence: armedEscortRank.sequence,
      armedEscortTypeId: armedEscortRank.armedEscortTypeId,
    });
    this.selectedArmedEscortType = this.armedEscortTypes.find(
      (escortType) => escortType.id === armedEscortRank.armedEscortTypeId
    );
    this.editingArmedEscortRank = true;
    this.submitButtonLabel = "Update";
  }
  updateArmedEscortRank(): boolean {
    this.submittingData = true;
    if (!this.edArmedEscortRank) {
      return false;
    }
    const formData = this.armedEscortRankForm.value;
    const data = {
      rankName: formData.rankName,
      alias: formData.alias,
      description: formData.description,
      armedEscortTypeId: this.selectedArmedEscortType.id,
      // sequence: 0
    };
    this.armedEscortRankService
      .updateArmedEscortRank(this.edArmedEscortRank.id, data)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            this.messageService.add({
              severity: "success",
              detail: "Armed Escort Type Updated",
              summary: "Completed",
            });
            this.submittingData = false;
            this.closeEditing();
            this.fetchAllArmedEscortRank();
            this.armedEscortRankForm.reset();
          }
        },
        (error) => {
          this.connectionError();
          this.submittingData = false;
        }
      );
  }

  //Commander
  fetchCommanderType() {
    this.commanderTypeService
      .allCommanderType()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            var res = r.responseData;
            this.commanderTypes = res;
          }
        },
        (error) => this.connectionError()
      );
    this.submittingData = false;
  }

  fetchCommanderRank() {
    this.loading = true;
    this.commanderRankService
      .allCommanderRank()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            var res = r.responseData;
            this.commanderRanks = res;
            this.loading = false;
          }
        },
        (error) => {
          this.connectionError();
          this.loading = false;
        }
      );
  }
  createCommanderRank() {
    this.submittingData = true;
    const formData = this.commanderRankForm.value;
    const data = {
      rankName: formData.rankName,
      alias: formData.alias,
      description: formData.description,
      commanderTypeId: this.selectedCommanderType.id,
    };
    this.commanderRankService
      .postCommanderRank(data)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            this.submittingData = false;
            this.fetchCommanderRank();
            this.closeEditing();
            this.commanderRankForm.reset();
            this.messageService.add({
              summary: "Successful",
              detail: "Commander Rank Created",
              severity: "success",
            });
          } else {
            this.messageService.add({
              severity: "error",
              summary: "Failed",
              detail: " Error: " + r.responseMsg,
            });
            this.submittingData = false;
          }
        },
        (error) => {
          this.messageService.add({
            severity: "error",
            summary: "Failed",
            detail: "Connection Error " + error.error.message,
          });
          this.submittingData = false;
        }
      );
  }
  deleteCommanderRank(commanderRanks: CommanderRank): void {
    this.confirmationService.confirm({
      message: "Are you sure you want to delete " + commanderRanks.rankName,
      accept: () => {
        this._deleteCommanderRank(commanderRanks);
      },
    });
  }

  editCommanderRank(commanderRanks: CommanderRank): void {
    this.edCommanderRank = commanderRanks;
    this.formWrapper.nativeElement.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "start",
    });
    console.log(this.edCommanderRank.id);
    this.commanderRankForm.setValue({
      rankName: commanderRanks.rankName,
      alias: commanderRanks.alias,
      description: commanderRanks.description,
      commanderTypeId: commanderRanks.commanderTypeId,
    });
    //chnage to typeid
    this.selectedCommanderType = this.commanderTypes.find(
      (commandType) => commandType.id === commanderRanks.commanderTypeId
    );

    this.editingCommanderRank = true;
    this.submitButtonLabel = "Update";
  }

  updateCommanderRank(): boolean {
    this.submittingData = true;
    if (!this.edCommanderRank) {
      return false;
    }
    const formData = this.commanderRankForm.value;
    const data = {
      rankName: formData.rankName,
      alias: formData.alias,
      description: formData.description,
      commanderTypeId: this.selectedCommanderType.id,
    };
    this.commanderRankService
      .updateCommanderRank(this.edCommanderRank.id, data)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            this.messageService.add({
              severity: "success",
              detail: "Commander Rank Updated",
              summary: "Completed",
            });
            this.submittingData = false;
            this.closeEditing();
            this.fetchCommanderRank();
            this.commanderRankForm.reset();
          }
        },
        (error) => {
          this.connectionError();
          this.submittingData = false;
        }
      );
  }
  _deleteCommanderRank(commanderRanks) {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Deleting commander Rank Type record",
    });
    this.commanderRankService.delete(commanderRanks.id).subscribe(
      async (result: any) => {
        await this.messageService.add({
          severity: "success",
          summary: "Deleted",
          detail: "commander Rank  record removed",
        });
        await this.fetchCommanderRank();
      },
      (error) => {
        this.connectionError();
      }
    );
  }

  //Pilot
  fetchAllPilotType(): void {
    this.pilotTypeService
      .allPilotType()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            var res = r.responseData;
            this.pilotTypes = res;
          }
        },
        (error) => this.connectionError()
      );
  }
  fetchAllPilotRank(): void {
    this.pilotRankService
      .allPilotRank()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            var res = r.responseData;
            this.pilotRanks = res;
            this.loading = false;
          }
        },
        (error) => {
          this.loading = false;
          this.connectionError();
        }
      );
  }
  createPilotRank() {
    this.submittingData = true;
    const formData = this.pilotRankForm.value;
    const data = {
      rankName: formData.rankName,
      alias: formData.alias,
      description: formData.description,
      pilotTypeId: this.selectedPilotType.id,
      // sequence: 0
    };
    this.pilotRankService
      .postPilotRank(data)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            this.messageService.add({
              summary: "Success",
              severity: "success",
              detail: "New Rank created",
            });
            this.fetchAllPilotRank();
            this.submittingData = false;
            this.pilotRankForm.reset();
          } else {
            this.messageService.add({
              severity: "error",
              summary: "Failed",
              detail: " Error: " + r.responseMsg,
            });
            this.submittingData = false;
          }
        },
        (error) => {
          this.messageService.add({
            severity: "error",
            summary: "Failed",
            detail: "Connection Error " + error.error.message,
          });
          this.submittingData = false;
        }
      );
  }

  deletePilotRank(pilotRank: PilotRank): void {
    this.confirmationService.confirm({
      message: "Are you sure you want to delete " + pilotRank.rankName,
      accept: () => {
        this._deletePilotRank(pilotRank);
      },
    });
  }
  _deletePilotRank(pilotRank) {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Deleting Rank record",
    });
    this.pilotRankService.delete(pilotRank.id).subscribe(
      async (result: any) => {
        await this.messageService.add({
          severity: "success",
          summary: "Deleted",
          detail: "Rank record removed",
        });
        await this.fetchAllPilotRank();
      },
      (error) => {
        this.connectionError();
      }
    );
  }
  editPilotRank(pilotRank: PilotRank): void {
    this.edPilotRank = pilotRank;
    this.formWrapper.nativeElement.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "start",
    });
    this.pilotRankForm.setValue({
      rankName: pilotRank.rankName,
      alias: pilotRank.alias,
      description: pilotRank.description,
      //sequence: armedEscortRank.sequence,
      pilotTypeId: pilotRank.pilotTypeId,
    });
    this.selectedPilotType = this.pilotTypes.find(
      (Type) => Type.id === pilotRank.pilotTypeId
    );
    this.editingPilotRank = true;
    this.submitButtonLabel = "Update";
  }
  updatePilotRank(): boolean {
    this.submittingData = true;
    if (!this.edPilotRank) {
      return false;
    }
    const formData = this.pilotRankForm.value;
    const data = {
      rankName: formData.rankName,
      alias: formData.alias,
      description: formData.description,
      pilotTypeId: this.selectedPilotType.id,
      // sequence: 0
    };
    this.pilotRankService
      .updatePilotRank(this.edPilotRank.id, data)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            this.messageService.add({
              severity: "success",
              detail: "Pilot rank Updated",
              summary: "Completed",
            });
            this.submittingData = false;
            this.closeEditing();
            this.fetchAllPilotRank();
            this.commanderRankForm.reset();
          }
        },
        (error) => {
          this.connectionError();
          this.submittingData = false;
        }
      );
  }
  closeEditing() {
    this.editingArmedEscortRank = false;
    this.armedEscortRankForm.reset();
    this.edArmedEscortRank = null;

    this.editingCommanderRank = false;
    this.commanderRankForm.reset();
    this.edCommanderRank = null;

    this.editingPilotRank = false;
    this.pilotRankForm.reset();
    this.edPilotRank = null;
    this.submittingData = false;
    this.submitButtonLabel = "Create";
  }
  private connectionError() {
    this.messageService.add({
      severity: "error",
      summary: "Failed",
      detail: "Connection Error, Please try again",
    });
  }
  ngOnDestroy(): void {
    this.unsubscriber$.complete();
    this.unsubscriber$.unsubscribe();
  }
}
