import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import {
  ArmedEscortServiceAssignmentDetail,
  CommanderServiceAssignmentDetail,
  Passenger,
  PilotServiceAssignmentDetail,
  VehicleServiceAssignmentDetail,
} from "src/app/interfaces/armada";
import { CommonResponse, ContractService } from "src/app/interfaces/home";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class ServiceAssignmentDetailService {
  baseUrl = environment.baseUrl + "ServiceAssignmentDetails/";
  //baseUrl = "https://localhost:44344/api/v1/ServiceAssignmentDetails/";
  constructor(private httpClient: HttpClient) {}

  //Vehicle
  allVehicleDetails(): Observable<CommonResponse> {
    return this.httpClient.get<CommonResponse>(
      this.baseUrl + "GetAllVehicleDetails"
    );
  }
  //
  allAvailableVehicles(): Observable<CommonResponse> {
    return this.httpClient.get<CommonResponse>(
      this.baseUrl + "GetAllAvailableVehicleDetails"
    );
  }

  allHeldVehicles(): Observable<CommonResponse> {
    return this.httpClient.get<CommonResponse>(
      this.baseUrl + "GetAllDistinctHeldVehicleDetails"
    );
  }

  allVehicleDetailsByAssignmentId(id: any): Observable<CommonResponse> {
    return this.httpClient.get<CommonResponse>(
      this.baseUrl + "GetAllNonReplacedVehicleDetailsByAssignmentId/" + id
    );
  }

  getVehicledetail(id: any): Observable<CommonResponse> {
    return this.httpClient.get<CommonResponse>(
      this.baseUrl + "GetVehicleDetailById/" + id
    );
  }

  postVehicleDetail(data: any) {
    return this.httpClient.post(this.baseUrl + "AddNewVehicleDetail", data);
  }

  postVehicleDetailReplacement(data: any) {
    return this.httpClient.post(
      this.baseUrl + "VehicleDetailReplacement",
      data
    );
  }
  deleteVehicleDetail(id: any) {
    return this.httpClient.delete(
      this.baseUrl + "DeleteVehicleDetailById/" + id
    );
  }
  releaseResourcesOfExpiredServiceAssignments(id: any) {
    return this.httpClient.delete(
      this.baseUrl +
        "ReleaseResourcesOfExpiredServiceAssignmentsByAssignmentId/" +
        id
    );
  }
  updateVehicleDetail(id: any, data: any) {
    return this.httpClient.put(
      this.baseUrl + "UpdateVehicleDetailById/" + id,
      data
    );
  }

  //ArmedEscort
  allArmedEscortDetails(): Observable<CommonResponse> {
    return this.httpClient.get<CommonResponse>(
      this.baseUrl + "GetAllArmedEscortDetails"
    );
  }

  allAvailableArmedEscorts(): Observable<CommonResponse> {
    return this.httpClient.get<CommonResponse>(
      this.baseUrl + "GetAllAvailableArmedEscortDetails"
    );
  }

  allHeldArmedEscorts(): Observable<CommonResponse> {
    return this.httpClient.get<CommonResponse>(
      this.baseUrl + "GetAllDistinctHeldArmedEscortDetails"
    );
  }

  allArmedEscortDetailsByAssignmentId(id: any): Observable<CommonResponse> {
    return this.httpClient.get<CommonResponse>(
      this.baseUrl + "GetAllNonReplacedArmedEscortDetailsByAssignmentId/" + id
    );
  }

  getArmedEscortDetail(id: any): Observable<CommonResponse> {
    return this.httpClient.get<CommonResponse>(
      this.baseUrl + "GetArmedEscortDetailById/" + id
    );
  }

  postArmedEscortDetail(data: any) {
    return this.httpClient.post(this.baseUrl + "AddNewArmedEscortDetail", data);
  }

  postArmedEscortDetailReplacement(data: any) {
    return this.httpClient.post(
      this.baseUrl + "ArmedEscortDetailReplacement",
      data
    );
  }
  deleteArmedEscortDetail(id: any) {
    return this.httpClient.delete(
      this.baseUrl + "DeleteArmedEscortDetailById/" + id
    );
  }
  updateArmedEscortDetail(id: any, data: any) {
    return this.httpClient.put(
      this.baseUrl + "UpdateArmedEscortDetailById/" + id,
      data
    );
  }

  //Commander
  allCommanderDetails(): Observable<CommonResponse> {
    return this.httpClient.get<CommonResponse>(
      this.baseUrl + "GetAllCommanderDetails"
    );
  }

  allAvailableCommanders(): Observable<CommonResponse> {
    return this.httpClient.get<CommonResponse>(
      this.baseUrl + "GetAllAvailableCommanderDetails"
    );
  }

  allHeldCommanders(): Observable<CommonResponse> {
    return this.httpClient.get<CommonResponse>(
      this.baseUrl + "GetAllDistinctHeldCommanderDetails"
    );
  }

  allCommanderDetailsByAssignmentId(id: any): Observable<CommonResponse> {
    return this.httpClient.get<CommonResponse>(
      this.baseUrl + "GetAllNonReplacedCommanderDetailsByAssignmentId/" + id
    );
  }

  allCommanderDetailsByProfileId(id: any): Observable<CommonResponse> {
    return this.httpClient.get<CommonResponse>(
      this.baseUrl + "GetAllCommanderDetailsByProfileId/" + id
    );
  }

  allOngoingTripsToBeEnded(): Observable<CommonResponse> {
    return this.httpClient.get<CommonResponse>(
      this.baseUrl + "GetAllOngoingTripsToBeEnded"
    );
  }

  getCommanderDetail(id: any): Observable<CommonResponse> {
    return this.httpClient.get<CommonResponse>(
      this.baseUrl + "GetCommanderDetailById/" + id
    );
  }

  postCommanderDetail(data: any) {
    return this.httpClient.post(this.baseUrl + "AddNewCommanderDetail", data);
  }

  postCommanderDetailReplacement(data: any) {
    return this.httpClient.post(
      this.baseUrl + "CommanderDetailReplacement",
      data
    );
  }
  deleteCommanderDetail(id: any) {
    return this.httpClient.delete(
      this.baseUrl + "DeleteCommanderDetailById/" + id
    );
  }
  updateCommanderDetail(id: any, data: any) {
    return this.httpClient.put(
      this.baseUrl + "UpdateCommanderDetailById/" + id,
      data
    );
  }

  //Pilot
  allPilotDetails(): Observable<CommonResponse> {
    return this.httpClient.get<CommonResponse>(
      this.baseUrl + "GetAllPilotDetails"
    );
  }

  allAvailablePilots(): Observable<CommonResponse> {
    return this.httpClient.get<CommonResponse>(
      this.baseUrl + "GetAllAvailablePilotDetails"
    );
  }

  allHeldPilots(): Observable<CommonResponse> {
    return this.httpClient.get<CommonResponse>(
      this.baseUrl + "GetAllDistinctHeldPilotDetails"
    );
  }

  allPilotDetailsByAssignmentId(id: any): Observable<CommonResponse> {
    return this.httpClient.get<CommonResponse>(
      this.baseUrl + "GetAllNonReplacedPilotDetailsByAssignmentId/" + id
    );
  }

  getPilotDetail(id: any): Observable<CommonResponse> {
    return this.httpClient.get<CommonResponse>(
      this.baseUrl + "GetPilotDetailById/" + id
    );
  }

  postPilotDetail(data: any) {
    return this.httpClient.post(this.baseUrl + "AddNewPilotDetail", data);
  }

  postPilotDetailReplacement(data: any) {
    return this.httpClient.post(this.baseUrl + "PilotDetailReplacement", data);
  }
  deletePilotDetail(id: any) {
    return this.httpClient.delete(this.baseUrl + "DeletePilotDetailById/" + id);
  }
  updatePilotDetail(id: any, data: any) {
    return this.httpClient.put(
      this.baseUrl + "UpdatePilotDetailById/" + id,
      data
    );
  }

  //Passenger
  allPassengerDetails(): Observable<CommonResponse> {
    return this.httpClient.get<CommonResponse>(
      this.baseUrl + "GetAllPassengers"
    );
  }

  allPassengerDetailsByAssignmentId(id: any): Observable<CommonResponse> {
    return this.httpClient.get<CommonResponse>(
      this.baseUrl + "GetAllPassengersByAssignmentId/" + id
    );
  }

  getPassenger(id: any): Observable<CommonResponse> {
    return this.httpClient.get<CommonResponse>(
      this.baseUrl + "GetPassengerById/" + id
    );
  }

  postPassenger(data: any) {
    return this.httpClient.post(this.baseUrl + "AddNewPassenger", data);
  }
  deletePassenger(id: any) {
    return this.httpClient.delete(this.baseUrl + "DeletePassengerById/" + id);
  }
  updatePassenger(id: any, data: any) {
    return this.httpClient.put(
      this.baseUrl + "UpdatePassengerById/" + id,
      data
    );
  }
  //ContractServices
  allContractServices(): Observable<CommonResponse> {
    return this.httpClient.get<CommonResponse>(
      this.baseUrl + "GetAllContractServices"
    );
  }

  allContractServicesById(customerDivisionId: any): Observable<CommonResponse> {
    return this.httpClient.get<CommonResponse>(
      this.baseUrl + "GetAllContractServices/" + customerDivisionId
    );
  }
  //Contracts
  allContracts(): Observable<CommonResponse> {
    return this.httpClient.get<CommonResponse>(
      this.baseUrl + "GetAllContracts"
    );
  }
}
