import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { MessageService, ConfirmationService, Message } from "primeng/api";
import { UIChart } from "primeng/chart";
import { BreadcrumbService } from "src/app/breadcrumb.service";
import { CommonResponse } from "src/app/interfaces/home";
import {
  GetManagementDashboardReportTransferDTO,
  LineChartDataFormat,
} from "src/app/interfaces/reporting";
import { ReportingService } from "src/app/services/reporting.service";

@Component({
  selector: "app-management-dashboard",
  templateUrl: "./management-dashboard.component.html",
  styleUrls: ["./management-dashboard.component.scss"],
  providers: [MessageService],
})
export class ManagementDashboardComponent implements OnInit {
  @ViewChild("formWrapper") public formWrapper: ElementRef;
  @ViewChild("chart1") chart1: UIChart;
  @ViewChild("chart2") chart2: UIChart;
  msg: Message[] = [];
  opportunityGenRate_Percent: number;
  opportunityGenRate_Degree: number;
  leadConversionRate_Percent: number;
  leadConversionRate_Degree: number;
  salesDistData: {
    labels: string[];
    datasets: {
      data: number[];
      backgroundColor: string[];
    }[];
  };
  salesVsCollectionsData: LineChartDataFormat;
  productionValue: number;
  totalCollectionsValue: number;
  receivablesDueValue: number;
  currentYearValue: string;
  fetchingAnalytics: boolean;
  totalNumberOfRenewals: string = "N/A";
  totalSumOfRenewals: string = "N/A";
  totalNumberOfReductions: string = "N/A";
  totalSumOfReductions: string = "N/A";
  amortizationNewSalesLength: string = "N/A";
  totalSalesMade: string = "N/A";
  totalNewSales: string = "N/A";
  totalTopUpSales: string = "N/A";

  constructor(
    private reportingService: ReportingService,
    public messageService: MessageService,
    private breadcrumbService: BreadcrumbService,
    public confirmationService: ConfirmationService
  ) {}

  ngOnInit(): void {
    this.breadcrumbService.setItems([
      {
        label: "Management Dashboard",
        routerLink: ["/home/management-dashboard"],
      },
    ]);

    this.salesDistData = {
      labels: [],
      datasets: [
        {
          data: [],
          backgroundColor: [],
        },
      ],
    };
    this.salesVsCollectionsData = {
      labels: [],
      datasets: [],
    };
    this.productionValue = 0;
    this.totalCollectionsValue = 0;
    this.receivablesDueValue = 0;

    this.GetDasboardAnalytics();
    this.ResetMessageToasters();
  }

  ResetMessageToasters() {
    this.msg = [];
    this.msg.push({
      severity: "info",
      summary: "Notice:",
      detail:
        "Hi, Welcome there. Please note that this management dashboard is as at today we are running it. The dashboard will continue to be updated with new analytics of time.",
    });
  }

  GetDasboardAnalytics() {
    this.fetchingAnalytics = true;
    this.CalculateCircularProgressValues(0, 0);
    this.reportingService.GetManagementDashboardReport().subscribe(
      async (data: CommonResponse<GetManagementDashboardReportTransferDTO>) => {
        this.fetchingAnalytics = false;
        if (data.responseCode == "00") {
          this.CalculateCircularProgressValues(
            data.responseData.opportuniyGenerationRate,
            data.responseData.leadConversionRate
          );
          this.productionValue = data.responseData.productionValue;
          this.totalCollectionsValue = data.responseData.totalCollectionValue;
          this.receivablesDueValue = data.responseData.receivablesDueValue;
          if (data.responseData.salesDistByDivisionData) {
            this.salesDistData.labels =
              data.responseData.salesDistByDivisionData.labels;
            this.salesDistData.datasets[0].backgroundColor =
              data.responseData.salesDistByDivisionData.backgroundColors;
            this.salesDistData.datasets[0].data =
              data.responseData.salesDistByDivisionData.datas;
          }
          this.salesVsCollectionsData =
            data.responseData.salesVsCollectionsData;
          this.currentYearValue = data.responseData.currentYearValue;

          this.totalNumberOfRenewals =
            data.responseData.numberOfRenewals.toLocaleString();
          this.totalSumOfRenewals =
            data.responseData.sumOfRenewals.toLocaleString();
          this.totalNumberOfReductions =
            data.responseData.numberOfReductions.toLocaleString();
          this.totalSumOfReductions =
            data.responseData.sumOfReductions.toLocaleString();
          this.amortizationNewSalesLength =
            data.responseData.totalAmortizationNewSales.toLocaleString();
          this.totalSalesMade =
            data.responseData.totalSalesMade.toLocaleString();
          this.totalNewSales = data.responseData.totalNewSales.toLocaleString();
          this.totalTopUpSales =
            data.responseData.totalTopUpSales.toLocaleString();
          this.chart1.reinit();
        } else {
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail: data.responseMsg,
          });
          this.ResetMessageToasters();
        }
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch dashboard analytics at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
        this.fetchingAnalytics = false;
        this.ResetMessageToasters();
      }
    );
  }

  CalculateCircularProgressValues(
    opportunityGenRate: number,
    leadConversionRate: number
  ) {
    this.opportunityGenRate_Percent = opportunityGenRate;
    this.opportunityGenRate_Degree = 3.6 * opportunityGenRate;
    let opValue = this.opportunityGenRate_Degree / 2;
    document.getElementById("OPFill1").style.transform =
      "rotate(" + opValue + "deg)";
    document.getElementById("OPFill2").style.transform =
      "rotate(" + opValue + "deg)";
    document.getElementById("OPFill3").style.transform =
      "rotate(" + opValue + "deg)";

    this.leadConversionRate_Percent = leadConversionRate;
    this.leadConversionRate_Degree = 3.6 * leadConversionRate;
    let lcValue = this.leadConversionRate_Degree / 2;
    document.getElementById("LCFill1").style.transform =
      "rotate(" + lcValue + "deg)";
    document.getElementById("LCFill2").style.transform =
      "rotate(" + lcValue + "deg)";
    document.getElementById("LCFill3").style.transform =
      "rotate(" + lcValue + "deg)";
  }
}
