import { ConvergenceIncident } from "./../../../../interfaces/convergence";
import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ConfirmationService, MessageService } from "primeng/api";
import { BreadcrumbService } from "src/app/breadcrumb.service";
import { EventSource, EventType } from "src/app/interfaces/convergence";
import { State, Lga, User } from "src/app/interfaces/home";
import { ConvergenceService } from "src/app/services/convergence.service";
import { UserService } from "src/app/services/user.service";
import { SMORouteService } from "src/app/services/armada/smoroute.service";
import { GmaEmploymentService } from "src/app/services/gma-employment.service";
import { SMORoute } from "src/app/interfaces/armada";

@Component({
  selector: "app-incidence-convergence-approval",
  templateUrl: "./incidence-convergence-approval.component.html",
  styleUrls: ["./incidence-convergence-approval.component.scss"],
})
export class IncidenceConvergenceApprovalComponent
  implements OnInit, AfterViewInit
{
  @ViewChild("formWrapper") public formWrapper: ElementRef;
  eventForm: FormGroup;
  allAwaitingApproval: ConvergenceIncident[];
  selectedAwaitingApproval: ConvergenceIncident[];
  pendingApprovalCols: any[];
  allEventSource: EventSource[];
  theEventSource: EventSource;
  allEventTypes: EventType[];
  theEventType: EventType;
  allStates: State[];
  theState: State;
  allLgas: Lga[];
  theLga: Lga;
  riskLevel: number = 0;
  allUsers: User[] = [];
  showReviewers: boolean;
  reviewers: {
    imageUrl: string;
    fullname: string;
    dateReviewed: Date;
  }[];
  comments: {
    imageUrl: string;
    fullname: string;
    comment: string;
    dateAdded: Date;
  }[];
  allRoutes: SMORoute[] = [];
  theRoute: SMORoute;
  openDocDialogue: boolean;
  docIncident: ConvergenceIncident;
  loadedEvent: ConvergenceIncident;
  showComments: boolean;
  openCordinatesDialogue: boolean;
  loadingCordinates: boolean;
  locationGeometry: string = "N/A";
  raisedByImgSrc: string;
  raisedByName: string;
  raisedByMobile: string;
  raisedByEmail: string;
  openRequesterInfoSection: boolean;

  constructor(
    private fb: FormBuilder,
    public userService: UserService,
    public smoRouteService: SMORouteService,
    public employmentService: GmaEmploymentService,
    public convergenceService: ConvergenceService,
    private breadcrumbService: BreadcrumbService,
    public confirmationService: ConfirmationService,
    public messageService: MessageService
  ) {
    this.eventForm = fb.group({
      Source: ["", Validators.required],
      Type: ["", Validators.required],
      State: ["", Validators.required],
      Lga: ["", Validators.required],
      Street: ["", Validators.required],
      DateOccurred: ["", Validators.required],
      Caption: ["", Validators.required],
      Description: ["", Validators.required],
      Advisory: ["", Validators.required],
      Route: [""],
      RiskLevel: ["", Validators.required],
    });
  }

  ngOnInit(): void {
    this.breadcrumbService.setItems([
      {
        label: "Convergence",
      },
      {
        label: "Incident Management",
      },
      {
        label: "Approval",
        routerLink: ["/home/convergence/incident-management/approval"],
      },
    ]);

    this.pendingApprovalCols = [
      { field: "dateRequested", header: "Date Requested" },
      { field: "type", header: "Event Type" },
      { field: "source", header: "Event Source" },
      { field: "caption", header: "Event Caption" },
      { field: "description", header: "Event Description" },
      { field: "dateOccurred", header: "Event Date" },
    ];

    this.FetchAllUsers();
  }

  ngAfterViewInit(): void {
    // Load google maps script after view init
    const DSLScript = document.createElement("script");
    DSLScript.src =
      "https://maps.googleapis.com/maps/api/js?key=AIzaSyCQuetprs2UHb_zKSXznyumyfvw95ERCDY"; // replace by your API key
    DSLScript.type = "text/javascript";
    document.body.appendChild(DSLScript);
    document.body.removeChild(DSLScript);
  }

  async FetchAllUsers() {
    this.userService.allUser().subscribe(
      async (res) => {
        var data = res.responseData;
        data.forEach((user) => {
          user.fullName = user.lastName + " " + user.firstName;
          this.allUsers.push(user);
        });

        this.FetchAllEventSource();
      },
      (err) => {
        console.log(err);
        this.messageService.add({
          severity: "error",
          summary: "Failed",
          detail: err ?? "Some errors occurred",
        });
      }
    );
  }

  FetchAllEventSource() {
    this.convergenceService.GetAllEventSources().subscribe(
      async (data) => {
        this.allEventSource = data;
        this.FetchAllEventType();
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all event sources at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  FetchAllEventType() {
    this.convergenceService.GetAllEventTypes().subscribe(
      async (data) => {
        this.allEventTypes = data;
        this.FetchAllStates();
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all event types at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  FetchAllStates() {
    this.employmentService.GetAllStates().subscribe(
      async (data) => {
        this.allStates = data;
        this.FetchAllRoutes();
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all states at the moment.. Reason: [" +
            error.error.message +
            "]",
        });
      }
    );
  }

  FetchAllRoutes() {
    this.smoRouteService.allRoutes().subscribe(
      async (data) => {
        this.allRoutes = data.responseData;
        this.FetchPendingIncidents();
      },
      (error) => {
        this.messageService.add({
          severity: "error",
          summary: "Failed",
          detail: error ?? "Some errors occurred",
        });
      }
    );
  }

  FetchPendingIncidents() {
    this.convergenceService.GetReviewedPendingIncidentsList().subscribe(
      async (data) => {
        this.allAwaitingApproval = data;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all awaiting incidents at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  OnStateChange() {
    this.theLga = null;
    this.allLgas = this.theState.lgas;
  }

  ActOnEvent(item: ConvergenceIncident) {
    this.loadedEvent = item;
    this.theEventSource = this.allEventSource.find(
      (x) => x.id == item.sourceId
    );
    this.theEventType = this.allEventTypes.find((x) => x.id == item.typeId);
    this.theState = this.allStates.find((x) => x.id == item.stateId);
    this.OnStateChange();
    this.theLga = this.allLgas.find((X) => X.id == item.lgaId);
    this.theRoute = this.allRoutes.find((x) => x.id == item.routeId);
    this.riskLevel = item.riskLevel;

    this.eventForm.patchValue({
      Caption: item.caption,
      Description: item.description,
      Advisory: item.advisory,
      Street: item.street,
      DateOccurred: item.dateOccurred.toLocaleString().slice(0, 10),
    });
  }

  ViewUploads(item: ConvergenceIncident) {
    this.openDocDialogue = true;
    this.docIncident = item;
  }

  ShowCommentsDialogue() {
    this.showComments = true;
    this.comments = [];
    this.loadedEvent.comments.forEach((x) => {
      let commentatorProfile = this.allUsers.find((u) => u.id == x.createdById);
      this.comments.push({
        imageUrl: commentatorProfile ? commentatorProfile.imageUrl : "",
        fullname: commentatorProfile ? commentatorProfile.fullName : "",
        comment: x.comment,
        dateAdded: x.createdAt,
      });
    });
  }

  ViewReviewers(item: ConvergenceIncident) {
    this.showReviewers = true;
    this.reviewers = [];
    item.reviews.forEach((x) => {
      let reviewProfile = this.allUsers.find(
        (u) => u.id == x.officerUserProfileId
      );
      this.reviewers.push({
        imageUrl: reviewProfile ? reviewProfile.imageUrl : "",
        fullname: reviewProfile ? reviewProfile.fullName : "",
        dateReviewed: x.dateReviewed,
      });
    });
  }

  HideReviewersDialogue() {
    this.showReviewers = false;
    this.reviewers = [];
  }

  HideDocDialog() {
    this.openDocDialogue = false;
    this.docIncident = null;
  }

  HideCommentsDialogue() {
    this.showComments = false;
    this.comments = [];
  }

  async ViewCordinates() {
    this.openCordinatesDialogue = true;

    this.loadingCordinates = true;
    await new Promise((resolve) => setTimeout(resolve, 5000)); // 5 secs
    this.initMap();
  }

  HideCordinatesDialog() {
    this.openCordinatesDialogue = false;
    this.loadingCordinates = false;
  }

  // Initialize and add the map
  initMap(): void {
    // The location of Loc
    const location = {
      lat: this.loadedEvent.locationLatitude,
      lng: this.loadedEvent.locationLongitude,
    };
    // The map, centered at Loc
    const map = new google.maps.Map(
      document.getElementById("map") as HTMLElement,
      {
        zoom: 4,
        center: location,
      }
    );

    // The marker, positioned at Loc
    const marker = new google.maps.Marker({
      position: location,
      map: map,
    });
    this.locationGeometry = this.loadedEvent.locationGeometry;
    this.loadingCordinates = false;
  }

  ActOnRequest(action: boolean, comment?: string) {
    this.convergenceService
      .ApproveDenyyIncident(this.loadedEvent.id, action, comment)
      .subscribe(
        async () => {
          this.messageService.add({
            severity: "success",
            summary: "Completed",
            detail: "Action Successfull.",
          });

          const index = this.allAwaitingApproval.indexOf(this.loadedEvent);
          if (index > -1) {
            this.allAwaitingApproval.splice(index, 1);
            this.eventForm.reset();
            this.loadedEvent = null;
          }
        },
        (error) => {
          console.log("Error: " + JSON.stringify(error));
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail:
              "Unable to act on incident at the moment.. Reason: [" +
              (error ? error.error.message : "request failed - permission") +
              "]",
          });
        }
      );
  }

  DenyEventRequest() {
    this.confirmationService.confirm({
      message:
        "You are about to deny this incident and it's irreversible. Do you still wish to proceed?",
      accept: () => {
        this.ActOnRequest(false);
      },
    });
  }

  ApproveEvent() {
    this.confirmationService.confirm({
      message:
        "You are about to approve this incident and it's irreversible. Do you still wish to proceed?",
      accept: () => {
        this.ActOnRequest(true);
      },
    });
  }

  ShowRequesterInfo(requesterUserId: number) {
    const raisedBy = this.allUsers.find((x) => x.id == requesterUserId);
    if (raisedBy) {
      this.raisedByImgSrc = raisedBy.imageUrl;
      this.raisedByName =
        raisedBy.lastName +
        " " +
        raisedBy.firstName +
        " " +
        (raisedBy.otherName ?? "");
      this.raisedByEmail = raisedBy.email;
      this.raisedByMobile = raisedBy.mobileNumber;
      this.openRequesterInfoSection = true;
    }
  }

  HideRequesterInfoBox() {
    this.openRequesterInfoSection = false;
    this.raisedByImgSrc = null;
    this.raisedByName = "";
    this.raisedByEmail = "";
    this.raisedByMobile = "";
  }
}
