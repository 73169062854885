import { Component, OnInit } from '@angular/core';
import {DynamicDialogConfig, DynamicDialogRef} from 'primeng/dynamicdialog';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ConfirmationService, MessageService} from 'primeng/api';

@Component({
    selector: 'app-deliverable-details',
    templateUrl: './deliverable-details.component.html',
    styleUrls: ['./deliverable-details.component.css'],
    providers: [ConfirmationService]
})

export class DeliverableDetailsComponent implements OnInit {

    deliverable: any;

    constructor(public config: DynamicDialogConfig,
                public deliverableDetailsModalRef: DynamicDialogRef,
                public confirmationService: ConfirmationService,
                public messageService: MessageService
    ) {}

    ngOnInit(): void {
        this.deliverable = this.config.data.deliverable;
    }


    closeModal() {
        this.deliverableDetailsModalRef.close();
    }
}
