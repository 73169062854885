import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ClientContactQualification, CommonResponse, Constant, Designation, Lead, LeadContact} from '../../../../interfaces/home';
import {LeadService} from '../../../../services/lead.service';
import {LeadContactService} from '../../../../services/lead-contact.service';
import {ConfirmationService, MessageService} from 'primeng/api';
import { DesignationService } from 'src/app/services/designation.service';
import { ClientContactQualificationService } from 'src/app/services/client-contact-qualification.service';

@Component({
  selector: 'app-secondary-contact',
  templateUrl: './secondary-contact.component.html',
  styleUrls: ['./secondary-contact.component.scss'],
  providers: [Constant]
})
export class SecondaryContactComponent implements OnInit {
  @Input() suggestedSecondaryContact: LeadContact|null;
  leadSecondaryContactForm: FormGroup;
  leadData: Lead = null;
  editingContact: boolean;
  titles : any[] = [];
  selectedTitle : any = null;
  genders : any[] = [];
  selectedGender : any = null;
  public yearRange: string;
  maxDateOfBirth: Date;
  designations: Designation[] = [];
  selectedDesignation: Designation = null;
  clientContactQualifications: ClientContactQualification[] = [];
  selectedClientContactQualification: ClientContactQualification = null;
  constructor(
      private leadService: LeadService,
      private formBuilder: FormBuilder,
      private leadContactService: LeadContactService,
      private designationService: DesignationService,
      private clientContactQualificationService: ClientContactQualificationService,
      private messageService: MessageService,
      private constants: Constant
  ) { }
  ngOnInit(): void {
    this.fetchDesignations();
    this.fetchContactQualifications();
    this.editingContact = false;
    this.titles = this.constants.titles;
    this.genders = this.constants.genders;

    this.maxDateOfBirth = new Date();
    const year = this.maxDateOfBirth.getFullYear() -18;
    this.maxDateOfBirth.setFullYear(year);
    this.yearRange = (year - 100) + ':' + year;

    this.leadSecondaryContactForm = this.formBuilder.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      mobileNumber: ['', Validators.required],
      designation: [null, Validators.required],
      contactQualification: [null, Validators.required],
      dateOfBirth: [''],
      title: ['',  Validators.required],
      gender: ['',  Validators.required],
      type: 1,
    });
    this.leadSecondaryContactForm.disable();
    this.leadService.activeLead().subscribe( async (res: any) => {
      if(res){
        this.leadData = res;
        this.leadSecondaryContactForm.enable();
        const _secondaryContact = res.secondaryContact;
        if(_secondaryContact){
          this.leadSecondaryContactForm.disable();
          this.leadSecondaryContactForm.patchValue({
            firstName: _secondaryContact.firstName,
            lastName: _secondaryContact.lastName,
            email: _secondaryContact.email,
            mobileNumber: _secondaryContact.mobileNumber,
            dateOfBirth: _secondaryContact.dateOfBirth,
          });
          this.selectedTitle = this.titles.find(title => title.value.toLowerCase() === _secondaryContact.title.toLowerCase());
          this.selectedGender = this.genders.find(gender => gender.value.toLowerCase() === _secondaryContact.gender.toLowerCase());
          this.selectedDesignation = this.designations.find(x => x.id === _secondaryContact.designationId);
          this.selectedClientContactQualification = this.clientContactQualifications
              .find(x => x.id === _secondaryContact.clientContactQualificationId);
        }
      }
    }, error => {
      // stop any operation
    });
  }

  async fetchDesignations() {
    await this.designationService.allDesignationData().subscribe(async (res: any) => {
        this.designations = res;
    }, error => this.connectionError());  
  }

  async fetchContactQualifications() {
    await this.clientContactQualificationService.allClientContactQualificationData().subscribe(async (res: any) => {
        this.clientContactQualifications = res;
    }, error => this.connectionError());  
  }

  createContact() {
    this.messageService.add({severity:'info', summary: 'Notice',
      detail:'Processing Secondary Contact Creation'});
    const postData= this.leadSecondaryContactForm.value;
    postData.gender = this.leadSecondaryContactForm.value.gender.value;
    postData.title = this.leadSecondaryContactForm.value.title.value;
    postData.designationId = this.selectedDesignation.id;
    postData.clientContactQualificationId = this.selectedClientContactQualification.id;
    if(this.leadSecondaryContactForm.valid) {
      this.leadContactService.postLeadContact(this.leadData.id, postData).subscribe( async (res: any) => {
        await this.messageService.add({severity:'success', summary: 'Notice',
          detail:'Secondary contact Submitted'});
        this.leadService.refreshData();
      } ) ;
    }
  }

  updateContact() {
    this.messageService.add({severity:'info', summary: 'Notice',
      detail:'Processing Secondary Contact Update'});
    const postData= this.leadSecondaryContactForm.value;
    postData.gender = this.leadSecondaryContactForm.value.gender.value;
    postData.title = this.leadSecondaryContactForm.value.title.value;
    postData.designationId = this.selectedDesignation.id;
    postData.clientContactQualificationId = this.selectedClientContactQualification.id;
    if(this.leadSecondaryContactForm.valid) {
      this.leadContactService.updateLeadContact(this.leadData.id, this.leadData.secondaryContact.id, postData).subscribe( async (res: any) => {
        await this.messageService.add({severity:'success', summary: 'Notice',
          detail:'Secondary contact Submitted'});
          this.cancelEditContact();
          this.leadService.refreshData();
      } ) ;
    }
  }

  fillSuggestedContact() {
    if(!this.suggestedSecondaryContact){
      this.messageService.add({
        severity: 'error',
        detail: 'Resources not loaded',
        summary: 'Notice'
      })
      return;
    }
    this.leadSecondaryContactForm.patchValue({
      firstName: this.suggestedSecondaryContact.firstName,
      lastName: this.suggestedSecondaryContact.lastName,
      email: this.suggestedSecondaryContact.email,
      mobileNumber: this.suggestedSecondaryContact.mobileNumber,
      dateOfBirth: this.suggestedSecondaryContact.dateOfBirth,
      type: 0,
    })
    this.selectedTitle = this.titles.find(title => title.value.toLowerCase() === this.suggestedSecondaryContact.title.toLowerCase());
    this.selectedGender = this.genders.find(gender => gender.value.toLowerCase() === this.suggestedSecondaryContact.gender.toLowerCase());
    this.selectedDesignation = this.designations.find(x => x.id === this.suggestedSecondaryContact.designationId);
    this.selectedClientContactQualification = this.clientContactQualifications
                .find(x => x.id === this.suggestedSecondaryContact.clientContactQualificationId);
  }

  cancelEditContact() {
    this.leadSecondaryContactForm.disable();
    this.editingContact = false;
    const _secondaryContact = this.leadData.secondaryContact;
    if(_secondaryContact){
      this.leadSecondaryContactForm.disable();
      this.leadSecondaryContactForm.patchValue({
        firstName: _secondaryContact.firstName,
        lastName: _secondaryContact.lastName,
        email: _secondaryContact.email,
        mobileNumber: _secondaryContact.mobileNumber,
        dateOfBirth: _secondaryContact.dateOfBirth,
        type: 1
      });
      this.selectedTitle = this.titles.find(title => title.value.toLowerCase() === _secondaryContact.title.toLowerCase());
      this.selectedGender = this.genders.find(gender => gender.value.toLowerCase() === _secondaryContact.gender.toLowerCase());
      this.selectedDesignation = this.designations.find(x => x.id === _secondaryContact.designationId);
      this.selectedClientContactQualification = this.clientContactQualifications
        .find(x => x.id === _secondaryContact.clientContactQualificationId);
    }
  }

  startEditing(){
    this.leadSecondaryContactForm.enable();
    this.editingContact = true;
  }

  private connectionError() {
    this.messageService.add({
        severity: 'error', summary: 'Failed',
        detail: 'Connection Error, Please try again'
    });
  }
}
