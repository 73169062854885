import { Supplier } from "src/app/interfaces/armada";
import { DeployedGuard, GeocodingAddressComponent } from "./employment";
import { CustomerDivision, Location, User } from "./home";

export const IncidentTypeActionEnum = {
  POST: 1,
  GET: 2,
  PATCH: 3,
  PUT: 4,
};
Object.freeze(IncidentTypeActionEnum);

export enum EntityTypeEnum {
  Supplier = 1,
  Client,
  Staff,
  Operative,
}

export interface CreateEventSourceVM {
  caption: string;
  description: string;
}

export interface UpdateEventSourceVM {
  caption?: string;
  description?: string;
}

export interface CreateEventTypeVM {
  imageUrl: string;
  caption: string;
  description: string;
  shortForm: string;
  categoryId: number;
  isPublic: boolean;
}

export interface UpdateEventTypeVM {
  imageUrl?: string;
  caption?: string;
  description?: string;
  shortForm?: string;
  categoryId?: number;
  isPublic?: boolean;
}

export interface EventSource {
  id: number;
  caption: string;
  description: string;
  entityTypeDefaults: ConvergenceEntityDefaultSource[];
  createdById: number;
  createdAt: Date;
}

export interface EventType {
  id: number;
  imageUrl: string;
  caption: string;
  description: string;
  shortForm: string;
  categoryId?: number;
  isPublic: boolean;
  createdById: number;
  createdAt: Date;
}

export interface ConvergenceReviewOffice {
  id: number;
  name: string;
  sequence: number;
  officers: ConvergenceReviewOfficer[];
  createdById: number;
  createdAt: Date;
}

export interface ConvergenceReviewOfficer {
  id?: number;
  userId?: number;
  user?: User;
  addedById?: number;
  dateAdded?: Date;
  reviewOfficeId?: number;
  createdById?: number;
  isDeleted?: boolean;
  createdAt?: Date;
}

export interface ConvergenceApprovalOffice {
  id: number;
  name: string;
  sequence: number;
  officers: ConvergenceApprovalOfficer[];
  createdById: number;
  createdAt: Date;
}

export interface ConvergenceApprovalOfficer {
  id?: number;
  userId?: number;
  user?: User;
  addedById?: number;
  dateAdded?: Date;
  approvalOfficeId?: number;
  createdById?: number;
  isDeleted?: boolean;
  createdAt?: Date;
}

export interface CreateReviewOfficeVM {
  name: string;
  officersIds: number[];
}

export interface UpdateReviewOfficeVM {
  officeName: string;
  officersIds: number[];
}

export interface MoveReviewOfficeSequenceVM {
  sequence: number;
  goesUp: boolean;
}

export interface CreateApprovalOfficeVM {
  name: string;
  officersIds: number[];
}

export interface UpdateApprovalOfficeVM {
  officeName: string;
  officersIds: number[];
}

export interface MoveApprovalOfficeSequenceVM {
  sequence: number;
  goesUp: boolean;
}

export interface RegisterCapturedIncidentVM {
  sourceId: number;
  sourceName: string;
  dateOccurred: Date;
  typeId: number;
  typeName: string;
  stateId: number;
  caption: string;
  lgaId: number;
  description: string;
  street: string;
  riskLevel: number;
  routeId?: number;
  uploads: string[];
  advisory: string;
  reportedByType: number;
  reportedBy: string;
  reportedById: number;
  locationLongitude: number;
  locationLatitude: number;
  locationGeometry: string;
}

export interface ConvergenceIncident {
  staff?: User;
  operativeId?: number;
  operative?: DeployedGuard;
  locationLongitude: number;
  locationLatitude: number;
  locationGeometry: string;
  currentReviewSequence: number;
  isFullyReviewed: boolean;
  dateFullyReviewed?: Date;
  currentApproveSequence: number;
  staffId?: number;
  isFullyApproved: boolean;
  deniedById?: number;
  deniedBy?: User;
  isDenied: boolean;
  dateDenied?: Date;
  comments: ConvergenceIncidentComment[];
  reviews: ConvergenceIncidentReview[];
  approvals: ConvergenceIncidentApproval[];
  createdById: number;
  dateFullyApproved?: Date;
  client?: CustomerDivision;
  clientId?: number;
  supplier?: Supplier;
  id: number;
  sourceId?: number;
  source?: EventSource;
  sourceName: string;
  dateOccurred: Date;
  typeId?: number;
  type?: EventType;
  typeName: string;
  lgaId?: number;
  stateId?: number;
  street: string;
  caption: string;
  description: string;
  riskLevel: number;
  routeId?: number;
  uploadUrls: string;
  advisory: string;
  reportedByType: number;
  reportedByEntityName: string;
  supplierId?: number;
  createdAt: Date;
}

export interface ConvergenceIncidentComment {
  id: number;
  incidentId?: number;
  incident?: ConvergenceIncident;
  comment: string;
  createdById?: number;
  createdAt: Date;
  updatedAt: Date;
  isDeleted: boolean;
}

export interface ConvergenceIncidentReview {
  id: number;
  incidentId?: number;
  officeSequece: number;
  officeId?: number;
  officerId?: number;
  officerUserProfileId?: number;
  status: number;
  dateReviewed: Date;
}

export interface ConvergenceIncidentApproval {
  id: number;
  incidentId?: number;
  officeSequece: number;
  officeId?: number;
  officerId?: number;
  officerUserProfileId?: number;
  status: number;
  dateAproved: Date;
}

export interface GetPendingIncidentsListVM {
  unpickedIncidents: ConvergenceIncident[];
  pickedIncidents: ConvergenceIncident[];
}

export interface UpdateCapturedIncidentVM {
  sourceId: number;
  sourceName: string;
  dateOccurred: Date;
  typeId: number;
  typeName: string;
  stateId: number;
  caption: string;
  lgaId: number;
  description: string;
  street: string;
  riskLevel: number;
  routeId: number;
  advisory: string;
}

export interface AddIncidentCommentVM {
  comment: string;
  incidentId: number;
}

export interface GetDashboardFilteredEventsVM {
  from?: Date;
  to?: Date;
  typeId?: number;
  sourceId?: number;
  stateId?: number;
  lgaId?: number;
  riskLevel?: number;
  reportedByType?: number;
  reportedBy?: number;
}

export interface ConvergenceInteractionType {
  id: number;
  caption: string;
  description: string;
  incidentTypeAction: number;
  isDeleted: boolean;
  createdById: number;
  createdAt: Date;
}

export interface CreateInteractionTypeVM {
  action: number;
  caption: string;
  description: string;
}

export interface UpdateInteractionTypeVM {
  action?: number;
  caption?: string;
  description?: string;
}

export interface DeviceCategory {
  id: number;
  imageUrl: string;
  caption: string;
  description: string;
  colorCode: string;
  deviceSubCategories: DeviceSubCategory[];
  isDeleted: boolean;
  createdById: number;
  createdAt: Date;
}

export interface DeviceSubCategory {
  id: number;
  caption: string;
  description: string;
  categoryId?: number;
  isDeleted: boolean;
  createdById: number;
  createdAt: Date;
}

export interface ConvergenceDevice {
  id: number;
  deviceName: string;
  deviceCategoryId?: number;
  deviceSubCategoryId?: number;
  supplierId?: number;
  deviceInteractions: ConvergenceDeviceInteraction[];
  deviceAttachments: ConvergenceDeviceAttachment[];
  isDeleted: boolean;
  createdById: number;
  createdAt: Date;
}

export interface ConvergenceDeviceInteraction {
  id?: number;
  deviceId?: number;
  interactionTypeId?: number;
  apiBaseUrl: string;
  inputParams?: string;
  outputParams?: string;
  apiToken?: string;
  addedById?: number;
  dateAdded?: Date;
  isDeleted?: boolean;
  inputParamsList?: string[];
  outputParamsList?: string[];
}

export interface CreateDeviceCategoryVM {
  imageUrl?: string;
  caption: string;
  description: string;
  colorCode: string;
}

export interface UpdateDeviceCategoryVM {
  imageUrl?: string;
  caption?: string;
  description?: string;
  colorCode?: string;
}

export interface CreateDeviceSubCategoryVM {
  caption: string;
  description: string;
  categoryId: number;
}

export interface UpdateDeviceSubCategoryVM {
  caption: string;
  description: string;
  categoryId?: number;
}

export interface CreateConvergenceDeviceVM {
  deviceName: string;
  deviceCategoryId: number;
  deviceSubCategoryId: number;
  supplierId: number;
  interactions: CreateDeviceInteractionVM[];
}

export interface CreateDeviceInteractionVM {
  id?: number;
  apiBaseUrl: string;
  inputParams: string[];
  outputParams: string[];
  token?: string;
  interactionTypeId: number;
}

export interface UpdateConvergenceDeviceVM {
  deviceName: string;
  deviceCategoryId?: number;
  deviceSubCategoryId?: number;
  supplierId?: number;
  interactions?: CreateDeviceInteractionVM[];
}

export interface ConvergenceDeviceAttachment {
  id: number;
  deviceId?: number;
  device?: ConvergenceDevice;
  clientId?: number;
  clientLocationId?: number;
  clientLocation?: Location;
  isDetached: boolean;
  dateDetached?: Date;
  createdById: number;
  createdAt: Date;
  updatedAt: Date;
}

export interface CreateDeviceAttachmentVM {
  clientId: number;
  clientLocationId: number;
  supplierId: number;
  deviceId: number;
}

export interface CreateDeviceAttachmentsVM {
  attachments: CreateDeviceAttachmentVM[];
}

export interface GetDeviceDashboardFilteredDataVM {
  from?: Date;
  to?: Date;
  interactionTypeId?: number;
  deviceCategoryId?: number;
  deviceSubCategoryId?: number;
  clientId?: number;
  locationId?: number;
  supplierId?: number;
}

export interface ConvergenceNotificationTemplate {
  id: number;
  name: string;
  description: string;
  riskLevel: number;
  variables: string;
  templateBody: string;
  isDeleted: boolean;
  createdById: number;
  createdAt: Date;
}

export interface ConvergenceNotificationPolicy {
  createdById: number;
  isDeleted: boolean;
  isActivated: boolean;
  triggerPolicyAnyDay: boolean;
  policyDays: string;
  triggerPolicyAnyTime: boolean;
  policyAttachments: ConvergenceNotificationPolicyAttachment[];
  policyTimes: ConvergenceNotificationPolicyTime[];
  notificationTemplates: ConvergenceNotificationPolicyTemplate[];
  policyServices: ConvergenceNotificationPolicyService[];
  policyStates: ConvergenceNotificationPolicyState[];
  endDate: Date;
  startDate: Date;
  description: string;
  name: string;
  id: number;
  createdAt: Date;
}

export interface ConvergenceNotificationPolicyTemplate {
  id: number;
  riskLevel: number;
  notificationTemplateId?: number;
  notificationTemplate?: ConvergenceNotificationTemplate;
  notificationPolicyId?: number;
  isDeleted: boolean;
  createdById: number;
  createdAt: Date;
}

export interface ConvergenceNotificationPolicyTime {
  id: number;
  notificationPolicyId?: number;
  startTime: Date;
  endTime: Date;
  isDeleted: boolean;
  createdById: number;
  createdAt: Date;
}

export interface ConvergenceNotificationPolicyAttachment {
  id: number;
  notificationPolicyId?: number;
  clientId?: number;
  client?: CustomerDivision;
  beneficiaryAttachments: ConvergenceBeneficiaryAttachment[];
  notificationWebhookUrl: string;
  isDeleted: boolean;
  createdById: number;
  createdAt: Date;
}

export interface CreateNotificationTemplateVM {
  templateName: string;
  templateDescription: string;
  riskLevel?: number;
  templateVariables: string[];
  templateBody: string;
}

export interface UpdateNotificationTemplateVM {
  templateName?: string;
  templateDescription?: string;
  riskLevel?: number;
  templateVariables?: string[];
  templateBody?: string;
}

export interface CreateNotificationPolicyVM {
  name: string;
  description: string;
  startDate: Date;
  endDate: Date;
  notificationTemplates: CreatePolicyNotificationTemplateVM[];
  serviceIds: number[];
  isAnyTimeNotification: boolean;
  notificationTimes?: CreatePolicyNotificationTimeVM[];
  isAnyDayNotification: boolean;
  notificationDays?: number[];
  isActivated: boolean;
  policyStates?: number[];
}

export interface CreatePolicyNotificationTemplateVM {
  riskLevel: number;
  notificationTemplateId: number;
}

export interface CreatePolicyNotificationTimeVM {
  startTime: Date;
  endTime: Date;
}

export interface CreateNotificationPolicyAttachmentVM {
  policyId: number;
  clientIds: number[];
}

export interface ConvergenceNotificationPolicyService {
  id: number;
  notificationPolicyId?: number;
  serviceId?: number;
  isDeleted: boolean;
  createdById: number;
  createdAt: Date;
}

export interface ConvergenceNotificationPolicyState {
  id: number;
  notificationPolicyId?: number;
  stateId?: number;
  isDeleted: boolean;
  createdById: number;
  createdAt: Date;
}

export interface ConvergenceRegion {
  id: number;
  name: string;
  description: string;
  regionStates: ConvergenceRegionState[];
  createdById: number;
  createdAt: Date;
}

export interface ConvergenceRegionState {
  id: number;
  regionId?: number;
  stateId?: number;
  createdAt: Date;
}

export interface CreateConvergenceRegionVM {
  name: string;
  description: string;
  statesInRegionIds: number[];
}

export interface UpdateConvergenceRegionVM {
  name?: string;
  description?: string;
  statesInRegionIds: number[];
}

export interface GetConvergenceReportVM {
  dateFrom: Date;
  dateTo: Date;
}

export interface GetConvergenceReportResultVM {
  occurredPerTypes: ChartDataFormat;
  occurredPerRegions: BarChartDataFormat;
  incidenceTypeLinearProgression: BarChartDataFormat;
  eventRadar: BarChartDataFormat;
  approvedIncidents: ConvergenceIncident[];
  regionEvaluations: ConvergenceRegionEvaluationVM[];
}

export interface ChartDataFormat {
  labels: string[];
  datas: number[];
  backgroundColors: string[];
}

export interface BarChartDataFormat {
  labels: string[];
  datasets: BarChartDataFormatDataSet[];
}

export interface BarChartDataFormatDataSet {
  label: string;
  backgroundColor: string;
  borderColor: string;
  pointBackgroundColor?: string;
  pointBorderColor?: string;
  pointHoverBackgroundColor?: string;
  pointHoverBorderColor?: string;
  fill?: boolean;
  data: number[];
}

export interface ConvergenceRegionEvaluationVM {
  region: string;
  evaluation: string;
  statesInRegion: number;
  regionIncidents: ConvergenceIncident[];
}

export interface AddNotificationPolicyAttachmentBeneficiaryVM {
  notificationPolicyAttachmentId: number;
  beneficiaryIds: number[];
}

export interface ConvergenceBeneficiaryAttachment {
  id: number;
  clientPolicyAttachmentId?: number;
  clientBeneficiaryId?: number;
  isDeleted: boolean;
  createdById: number;
  createdAt: Date;
}

export interface ConvergenceEntityDefaultSource {
  id: number;
  entityType: EntityTypeEnum;
  defaultEventSourceId?: number;
  isDeleted: boolean;
  createdById: number;
  createdAt: Date;
}

export interface CreateDefaultEventSourceVM {
  entityType: number;
  defaultEventSourceId: number;
}

export interface UpdateDefaultEventSourceVM {
  defaultEventSourceId?: number;
}

export interface ConvergenceAdvisory {
  id: number;
  imageUrl: string;
  caption: string;
  description: string;
  createdById: number;
  createdAt: Date;
}

export interface CreateAdvisoryVM {
  imageUrl: string;
  caption: string;
  description: string;
}

export interface UpdateAdvisoryVM {
  imageUrl?: string;
  caption?: string;
  description?: string;
}

export interface RegisterMobileCapturedIncidentVM {
  locationLongitude: number;
  locationLatitude: number;
  locationGeometry: string;
  typeId: number;
  typeName: string;
  riskLevel: number;
  uploads: string[];
  advisoryId: number;
  advisoryCaption: string;
  addressComponents: GeocodingAddressComponent[];
}

export interface ConvergenceCategory {
  id: number;
  caption: string;
  description: string;
  eventTypes: EventType[];
  isPublic: boolean;
  isDeleted: boolean;
  createdById: number;
  createdAt: Date;
}

export interface CreateConvergenceCategoryVM {
  caption: string;
  description: string;
  isPublic: boolean;
}

export interface UpdateConvergenceCategoryVM {
  caption?: string;
  description?: string;
  isPublic?: boolean;
}
