import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import {
  CreateCurrencyExchangeRateVM,
  CreateCurrencyVM,
  Currency,
  CurrencyExchangeRate,
  UpdateCurrencyVM,
} from "../interfaces/currency";

@Injectable({
  providedIn: "root",
})
export class CurrencyService {
  baseUrl = environment.gmaBaseUrl + "Currency/";

  constructor(private http: HttpClient) {}

  GetAllCurrencies(): Observable<Currency[]> {
    return this.http.get<Currency[]>(this.baseUrl + "GetAllCurrencies");
  }

  CreateCurrency(data: CreateCurrencyVM) {
    return this.http.post(this.baseUrl + "CreateCurrency", data);
  }

  UpdateCurrency(id: number, data: UpdateCurrencyVM) {
    return this.http.put(this.baseUrl + "UpdateCurrency/" + id, data);
  }

  DeleteCurrency(id: number) {
    return this.http.delete(this.baseUrl + "DeleteCurrency/" + id);
  }

  GetAllCurrencyExchangeRates(): Observable<CurrencyExchangeRate[]> {
    return this.http.get<CurrencyExchangeRate[]>(
      this.baseUrl + "GetAllCurrencyExchangeRates"
    );
  }

  CreateCurrencyExchangeRate(data: CreateCurrencyExchangeRateVM) {
    return this.http.post(this.baseUrl + "CreateCurrencyExchangeRate", data);
  }

  DeleteCurrencyExchangeRate(id: number) {
    return this.http.delete(this.baseUrl + "DeleteCurrencyExchangeRate/" + id);
  }
}
