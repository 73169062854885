import { Component, OnInit } from '@angular/core';
import {JourneyStart, TimeSpan} from '../../../../interfaces/armada';
import {DynamicDialogConfig, DynamicDialogRef} from 'primeng/dynamicdialog';

@Component({
  selector: 'app-end-journey-message',
  templateUrl: './end-journey-message.component.html',
  styleUrls: ['./end-journey-message.component.scss']
})
export class EndJourneyMessageComponent implements OnInit {
  duration: TimeSpan;
  journeyData: JourneyStart;

  constructor(
      private dialogRef: DynamicDialogRef,
      private dialogConfig: DynamicDialogConfig,
  ) { }

  ngOnInit(): void {
    this.duration = this.dialogConfig.data.duration;
    this.journeyData = this.dialogConfig.data.journeyData;
  }

  onClose() {
    this.dialogRef.close();
  }
}
