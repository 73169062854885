import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { CommonResponse } from "../interfaces/home";
import {
  PaystackBankListResponse,
  PaystackResolveAccountVM,
} from "../interfaces/paystack-gateway";

@Injectable({
  providedIn: "root",
})
export class PaystackGatewayService {
  baseUrl = environment.baseUrl + "PaystackGateway/";

  constructor(private http: HttpClient) {}

  GetBankList(): Observable<CommonResponse<PaystackBankListResponse>> {
    return this.http.get<CommonResponse<PaystackBankListResponse>>(
      this.baseUrl + "GetBankList"
    );
  }

  ResolveAccount(
    accountNumber: string,
    bankCode: string
  ): Observable<CommonResponse<PaystackResolveAccountVM>> {
    return this.http.get<CommonResponse<PaystackResolveAccountVM>>(
      this.baseUrl + "ResolveAccount/" + accountNumber + "/" + bankCode
    );
  }
}
