import {Component, OnDestroy, OnInit} from '@angular/core';
import {DynamicDialogConfig, DynamicDialogRef} from 'primeng/dynamicdialog';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {SituationReport, SituationType} from '../../../../interfaces/armada';
import {Lga, State} from '../../../../interfaces/home';
import {SituationReportService} from '../../../../services/armada/situation-report.service';
import {SituationTypeService} from '../../../../services/armada/situation-type.service';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {StateService} from '../../../../services/state.service';

@Component({
  selector: 'app-capture-situation-report',
  templateUrl: './capture-situation-report.component.html',
  styleUrls: ['./capture-situation-report.component.scss']
})
export class CaptureSituationReportComponent implements OnInit, OnDestroy {
  private unSubscriber$ = new Subject<void>();
  public situationForm: FormGroup;
  public situationTypes: SituationType[];
  public selectedSituationType: SituationType;
  public states: State[] = [];
  public lgas: Lga[] = [];
  public selectedState: State;
  public selectedLga: Lga;
  private serviceAssignmentId: number;
  processing: boolean;
  constructor(
      private dialogRef: DynamicDialogRef,
      private dialogConfig: DynamicDialogConfig,
      private formBuilder: FormBuilder,
      private situationReportService: SituationReportService,
      private situationTypeService: SituationTypeService,
      private stateService: StateService,
  ) { }

  ngOnInit(): void {
    this.fetchSituationType();
    this.fetchState();
    this.situationForm = this.formBuilder.group({
      address: ['', Validators.required],
      situationTypeId: [null, Validators.required],
      stateId: [null, Validators.required],
      lgaId: [null, Validators.required],
      location: ['', Validators.required],
      nearestLandmark:  ['', Validators.required],
      remark:  ['', Validators.required],
    });
    this.serviceAssignmentId = this.dialogConfig.data.serviceAssignmentId;
  }

  closeDialog(data?: SituationReport) {
    this.dialogRef.close(data);
  }
  fetchSituationType() {
    this.situationTypeService.allSituationTypes().pipe(takeUntil(this.unSubscriber$))
        .subscribe( (result: any) => {
      this.situationTypes = result;
    })
  }
  fetchState() {
    this.stateService.allState().pipe(takeUntil(this.unSubscriber$))
        .subscribe((states: any) => {
          this.states = states.result;
        })
  }
  createSituationReport() {
    const formData = this.situationForm.value;
    const postData = {
      situationTypeId: this.selectedSituationType.id,
      stateId: this.selectedState.id,
      lgaId: this.selectedLga.id,
      address: formData.address,
      location: formData.location,
      nearestLandmark: formData.nearestLandmark,
      serviceAssignmentId: this.serviceAssignmentId,
      contractServiceId: this.dialogConfig.data.contractServiceId,
      remark: formData.remark
    }
    this.closeDialog(postData);
  }

  setLga() {
    this.lgas = this.selectedState?.lgAs;
  }
  ngOnDestroy(): void {
    this.unSubscriber$.complete();
    this.unSubscriber$.unsubscribe();
  }
}
