import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from "@angular/core";
import {
  Branch,
  CommonResponse,
  Lga,
  Office,
  State,
  User,
} from "../../../interfaces/home";
import { StateService } from "../../../services/state.service";
import { ConfirmationService, MessageService } from "primeng/api";
import { UserService } from "../../../services/user.service";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { BranchService } from "../../../services/branch.service";
import { OfficeService } from "../../../services/office.service";
import { BreadcrumbService } from "src/app/breadcrumb.service";

@Component({
  selector: "app-branch-office",
  templateUrl: "./branch-office.component.html",
  styleUrls: ["./branch-office.component.css"],
  providers: [MessageService, ConfirmationService],
})
export class BranchOfficeComponent implements OnInit, AfterViewInit {
  @ViewChild("branchFormId") public branchFormId: ElementRef;
  @ViewChild("officeFormId") public officeFormId: ElementRef;
  selectedBranch: Branch[];
  selectedOffice: Office[];
  branches: Branch[];
  states: State[];
  officeState: State[];
  branchState: State[];
  users: User[];
  cols: any[];
  officeCols: any[];
  exportColumns: any;
  selectedOfficeState: State = null;
  selectedOfficeLga: Lga = null;
  officeLga: Lga[];
  selectedHeadId: any = null;
  branchForm: FormGroup;
  editingBranch = false;
  offices: Office[];
  officeForm: FormGroup;
  editingOffice = false;
  selectedOfficeBranch: Branch = null;
  selectedOfficeHeadId: any = null;
  private edBranch: Branch;
  public fetchingBranches: boolean;
  private edOffice: Office;
  loadingData: boolean = true;
  constructor(
    private stateService: StateService,
    private messageService: MessageService,
    private userService: UserService,
    private formBuilder: FormBuilder,
    private branchService: BranchService,
    private officeService: OfficeService,
    public confirmationService: ConfirmationService,
    private breadcrumbService: BreadcrumbService
  ) {
    this.branches = [];
    this.offices = [];
  }

  ngOnInit(): void {
    this.breadcrumbService.setItems([
      { label: "Setup", routerLink: ["/home/account/branch-office"] },
      { label: "Branch-Office", routerLink: ["home/account/branch-office"] },
    ]);

    this.cols = [
      { field: "name", header: "Name" },
      { field: "address", header: "Address" },
      { field: "description", header: "Description" },
      { field: "head", header: "Head" },
    ];
    this.exportColumns = this.cols.map((col) => ({
      title: col.header,
      dataKey: col.field,
    }));
    this.officeCols = [
      { field: "name", header: "Name" },
      { field: "state", header: "state" },
      { field: "lga", header: "lga" },
      { field: "branch", header: "Branch" },
      { field: "head", header: "Head" },
    ];
    this.exportColumns = this.officeCols.map((col) => ({
      title: col.header,
      dataKey: col.field,
    }));
    // fetch the state data
    this.stateService.allState().subscribe(
      async (r: CommonResponse) => {
        if (r.responseCode == "00") {
          var res = r.responseData;
          this.states = res; //result;
          this.officeState = this.branchState = this.states;
          this.loadingData = false;
        } else {
          this.messageService.add(res.message);
        }
      },
      (error) => {
        this.messageService.add({
          severity: "error",
          summary: "Failed",
          detail: "Connection error, Try again later",
        });
      }
    );
    this.userService.allUser().subscribe(async (res: CommonResponse) => {
      if (res.responseCode == "00") {
        this.users = res.responseData;
      }
    });
    this.branchForm = this.formBuilder.group({
      name: ["", Validators.required],
      address: ["", Validators.required],
      description: ["", Validators.required],
      headId: [null, Validators.required],
    });
    this.officeForm = this.formBuilder.group({
      name: ["", Validators.required],
      description: ["", Validators.required],
      state: [null, Validators.required],
      lga: [null, Validators.required],
      street: ["", Validators.required],
      phoneNumber: ["", Validators.required],
      head: [null, Validators.required],
      branch: [null, Validators.required],
    });
  }
  setLga() {
    if (this.selectedOfficeState) {
      this.officeLga = this.selectedOfficeState.lgAs;
    }
  }

  AddBranch() {
    const postData = {
      name: this.branchForm.get("name").value,
      address: this.branchForm.get("address").value,
      description: this.branchForm.get("description").value,
      headId: this.selectedHeadId.id,
    };
    this.branchService
      .postBranch(postData)
      .subscribe(async (res: CommonResponse) => {
        if (res.responseCode == "00") {
          this.messageService.add({
            severity: "success",
            summary: "New branch created",
            detail: "record added",
          });
          this.fetchBranches();
          this.branchForm.reset();
        }
      });
  }
  addOffice() {
    const _officeForm = this.officeForm;
    const postData = {
      name: _officeForm.get("name").value,
      description: _officeForm.get("description").value,
      stateId: this.selectedOfficeState.id,
      lgaId: this.selectedOfficeLga.id,
      street: _officeForm.get("street").value,
      phoneNumber: _officeForm.get("phoneNumber").value,
      headId: this.selectedOfficeHeadId.id,
      branchId: this.selectedOfficeBranch.id,
    };
    this.officeService
      .postOffice(postData)
      .subscribe(async (result: CommonResponse) => {
        if (result.responseCode == "00") {
          this.messageService.add({
            severity: "success",
            summary: "Request Complete",
            detail: "New office record added",
          });
          this.fetchOffice();
          this.officeForm.reset();
        }
      });
  }

  ngAfterViewInit(): void {
    this.fetchBranches();
    // fetch office data
    this.fetchOffice();
  }
  fetchOffice() {
    this.officeService.allOffice().subscribe(async (res: CommonResponse) => {
      if (res.responseCode == "00") {
        this.offices = res.responseData;
      }
    });
  }
  fetchBranches() {
    this.fetchingBranches = true;
    this.branchService.allBranch().subscribe(
      async (result: CommonResponse) => {
        if (result.responseCode == "00") {
          this.branches = result.responseData;
          this.fetchingBranches = false;
        }
      },
      (error) => {
        this.fetchingBranches = false;
        this.connectionError();
      }
    );
  }

  editOffice(office) {
    this.editingOffice = true;
    this.officeForm.addControl(
      "officeId",
      new FormControl({ value: office.id, disabled: true }, Validators.required)
    );
    this.edOffice = office;
    this.officeForm.patchValue({
      officeId: office.id,
      name: office.name,
      description: office.description,
      street: office.street,
      phoneNumber: office.phoneNumber,
    });
    this.selectedOfficeState = this.states.find(
      (state) => state.id === office.state.id
    );
    this.setLga();
    this.selectedOfficeBranch = this.branches.find(
      (branch) => branch.id === office.branch.id
    );
    this.selectedOfficeLga = this.officeLga.find(
      (lga) => lga.id === office.lga.id
    );
    this.selectedOfficeHeadId = office.head;
    this.officeFormId.nativeElement.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "start",
    });
  }

  editBranch(branches) {
    this.editingBranch = true;
    this.branchForm.addControl(
      "branchId",
      new FormControl({ value: "", disabled: true }, Validators.required)
    );
    this.edBranch = branches;
    this.branchForm.patchValue({
      name: branches.name,
      address: branches.address,
      description: branches.description,
      branchId: branches.id,
    });
    // this.selectedHeadId = this.users.find(user => user.id === branches.head.id);
    this.selectedHeadId = branches.head;
    this.branchFormId.nativeElement.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "start",
    });
  }

  updateBranch() {
    // update the selected branch
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Updating Branch details",
    });
    const id = this.edBranch.id;
    const postData = {
      name: this.branchForm.get("name").value,
      address: this.branchForm.get("address").value,
      description: this.branchForm.get("description").value,
      headId: this.selectedHeadId.id,
    };
    this.branchService
      .updateBranch(id, postData)
      .subscribe(async (r: CommonResponse) => {
        if (r.responseCode == "00") {
          this.messageService.add({
            severity: "success",
            summary: "Notice",
            detail: "Branch details Updated",
          });
          this.closeEditing();
          await this.fetchBranches();
        }
      });
  }

  closeEditing() {
    this.editingBranch = false;
    this.branchForm.reset();
    this.edBranch = null;
  }
  closeEditingOffice() {
    this.editingOffice = false;
    this.officeForm.reset();
    this.edOffice = null;
  }

  private connectionError() {
    this.messageService.add({
      severity: "error",
      summary: "Failed",
      detail: "Connection Error, Please try again",
    });
  }

  deleteBranch(branch) {
    this.confirmationService.confirm({
      message:
        "Are you sure you want to drop " +
        branch.name +
        ". All offices tied to it will also be dropped",
      accept: () => {
        this._deleteBranch(branch);
      },
    });
  }
  _deleteBranch(branch) {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Dropping branch record",
    });
    this.branchService.deleteBranch(branch.id).subscribe(
      async (result: any) => {
        await this.messageService.add({
          severity: "success",
          summary: "Dropped",
          detail: "Branch record removed",
        });
        await this.fetchBranches();
      },
      (error) => {
        this.connectionError();
      }
    );
  }
  deleteOffice(office) {
    this.confirmationService.confirm({
      message: "Are you sure you want to drop " + office.name,
      accept: () => {
        this._deleteOffice(office);
      },
    });
  }
  _deleteOffice(office) {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Dropping office record",
    });
    this.officeService.deleteOffice(office.id).subscribe(
      async (result: any) => {
        await this.messageService.add({
          severity: "success",
          summary: "Dropped",
          detail: "Office record dropped",
        });
        await this.fetchOffice();
      },
      (error) => {
        this.connectionError();
      }
    );
  }

  updateOffice() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Updating Office details",
    });
    const id = this.edOffice.id;
    const _officeForm = this.officeForm;
    const postData = {
      name: _officeForm.get("name").value,
      description: _officeForm.get("description").value,
      stateId: this.selectedOfficeState.id,
      lgaId: this.selectedOfficeLga.id,
      street: _officeForm.get("street").value,
      phoneNumber: _officeForm.get("phoneNumber").value,
      headId: this.selectedOfficeHeadId.id,
      branchId: this.selectedOfficeBranch.id,
    };
    this.officeService.updateOffice(id, postData).subscribe(
      async (result: Branch) => {
        this.messageService.add({
          severity: "success",
          summary: "Notice",
          detail: "Office details Updated",
        });
        await this.closeEditingOffice();
        await this.fetchOffice();
      },
      (error) => {
        this.connectionError();
      }
    );
  }
}
