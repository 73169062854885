import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ConfirmationService, MessageService, TreeNode} from 'primeng/api';
import { BreadcrumbService } from 'src/app/breadcrumb.service';
import {DialogService, DynamicDialogRef} from 'primeng/dynamicdialog';
import { FireBaseAuthService } from 'src/app/services/fire-base-auth.service';
import { CustomerDivisionService } from 'src/app/services/customer-division.service';
import { ClientPolicy, CommonResponse, Contract, ContractService, CustomerDivision, ServiceRelationshipEnum } from 'src/app/interfaces/home';
import { StrategicBusinessUnitService } from 'src/app/services/strategic-business-unit.service';
import { ClientPolicyService } from 'src/app/services/client-policy.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-client-policy',
  templateUrl: './client-policy.component.html',
  styleUrls: ['./client-policy.component.scss'],
  providers: [MessageService, ConfirmationService, DialogService]
})
export class ClientPolicyComponent implements OnInit {
  
  sbuClientsTree: TreeNode[];
  loadingSBUs = false;

  selectedCustomerDivision: any;

  customerDivisionInformation: any;

  fetchingContractServices = false;
  contracts: any;
  selectedContract: any;

  viewedContractService: any;

  clientPolicyForm: FormGroup;
  contractServices: any;
  selectedContractService: ContractService;
  matchingAdminContractService?: ContractService;

  contractServiceCols: any;
  contractServiceExportColumns: any;
  clientPolicyInformation: any;
  loadingClientsInfo = false;
  clientPolicy: ClientPolicy;
  theLegend: string;
  policyExists: boolean;

  routeId: string;
  customerDivisionId: string;
  fetchingClientPolicy: boolean;

  constructor(
      public formBuilder: FormBuilder,
      public messageService: MessageService,
      public firebaseAuthService: FireBaseAuthService,
      public customerDivisionService: CustomerDivisionService,
      public clientPolicyService: ClientPolicyService,
      public strategicBusinessUnitService: StrategicBusinessUnitService,
      public dialogService: DialogService,
      public confirmationService: ConfirmationService,
      private route: ActivatedRoute,
      private breadcrumbService: BreadcrumbService
  ) { }

  ngOnInit(): void {
    this.breadcrumbService.setItems([
      { label: 'Client Management', routerLink: ['home/client-policy'] },
      { label: 'Client Policy', routerLink: ['home/client-policy'] }
    ]);

    this.routeId = this.route.snapshot.paramMap.get('id') || null;
    if(this.routeId){
        const splitted = this.routeId.split('-');
        if (splitted.length === 1){
          this.customerDivisionId = splitted[0];
        }
    }

    this.fetchSbuWithClients();

    this.clientPolicyForm = this.formBuilder.group({
      autoRenew: [false],
      interval: [0, [Validators.required, Validators.min(1)]],
      reviewDate: [''],
      renewalTenor: [0, [Validators.required, Validators.min(1)]],

    });

    this.contractServiceCols = [
      { field: 'service', header: 'Service' },
      { field: 'serviceId', header: 'Service Id' },
      { field: 'status', header: 'Status' }
    ];

    this.contractServiceExportColumns = this.contractServiceCols.map(col => ({title: col.header, dataKey: col.field}));
  }

  fetchSbuWithClients(){
    this.loadingClientsInfo = true;
    this.strategicBusinessUnitService.allRMSbusWithClientInfo().subscribe(async (r: CommonResponse) => {
      this.loadingClientsInfo = false;
      var result = r.responseData;
      const sbus = result;
      
      const sbuTree = [];

      sbus.forEach((sbu, _) => {
        
        const clientTypesTree = [];

        const clientTypes = sbu.clients as any[];

        clientTypes.forEach((clientType, _) => {       
          
          for(const key in clientType){
            if(clientType.hasOwnProperty(key)){

              const clientTree = [];

              var clients = clientType[key] as CustomerDivision[];
              
              if(!this.selectedCustomerDivision && this.customerDivisionId){
                let client = clients.find(x => x.id == parseInt(this.customerDivisionId))
                if(client){
                  this.selectedCustomerDivision = client
                  this.getCustomerDivisionById(this.selectedCustomerDivision.id);
                }
              }

              clients.forEach((client, _) => {
                clientTree.push(
                  {
                    label: `${client.divisionName}`,
                    key: client.id,
                    icon: 'pi pi-fw pi-users',
                    data: client,
                    selectable: true,
                  }
                )
              })

              clientTypesTree.push(
                {
                  label: key,
                  key,
                  expandedIcon: 'pi pi-minus-circle',
                  collapsedIcon: 'pi pi-plus-circle',
                  expanded: true,
                  children: clientTree
                }
              )
            }
          }
        });

        sbuTree.push(
          {
            label: `${sbu.sbuName}`,
            key: sbu.id,
            expandedIcon: 'pi pi-minus-circle',
            collapsedIcon: 'pi pi-plus-circle',
            expanded: true,
            children: clientTypesTree
          }
        )

      });    

      this.sbuClientsTree = [
        {
          label: 'Halogen',
          key: 'Halogen Folder',
          expandedIcon: 'pi pi-minus-circle',
          collapsedIcon: 'pi pi-plus-circle',
          expanded: true,
          children: sbuTree
        }
      ];

    }, error => {
      this.loadingClientsInfo = false;
      this.connectionError()
    })
  }

  showClients(event){
    if(event.node.data){
      if(this.selectedCustomerDivision !== event.node.data){
        this.selectedCustomerDivision = event.node.data;
        this.searchContractServicesByClient();
      }
    }
  }

  searchContractServicesByClient() {
    this.customerDivisionInformation = null;
    this.selectedContract = null;
    this.contracts = null;
    this.viewedContractService = null;
    this.clientPolicy = null;
    this.getCustomerDivisionById(this.selectedCustomerDivision.id);
  }

  getCustomerDivisionById(customerDivisionId: any){
    this.messageService.add({severity:'info', summary: 'Notice',
        detail:'Retrieving Client Information'});

    this.fetchingContractServices = true;
    this.customerDivisionService.getCustomerDivision(customerDivisionId).subscribe( async (r: CommonResponse) => {
        await this.messageService.add({severity:'success', summary: 'Success',
            detail:'Retrieved Client Information Successfully'});

        var result = r.responseData;
        this.customerDivisionInformation = result;
        this.contracts = result.contracts;

        this.contracts.forEach(contract => {
          var contractServices = contract.contractServices as ContractService[];
          if(contractServices.every(x => x.groupInvoiceNumber != null)){
            contract.isGroupInvoice = true;
          }else{
            contract.isGroupInvoice = false;
          }
        });

        this.fetchingContractServices = false;
    }, error => {
        this.connectionError()
        this.fetchingContractServices = false;
    });
  }

  selectContract(contract?: Contract) {
    this.clientPolicy = null;
    this.selectedContract = contract;
    this.contractServices = contract.contractServices;
  }

  viewContractServicePolicy(contractService: ContractService) {
    this.viewedContractService = contractService;
    this.clientPolicyInformation = null;
    this.getPolicyByContractServiceId(contractService);
  }

  viewContractPolicy() {
    this.clientPolicyInformation = null;
    this.getPolicyByContractId(this.selectedContract);
  }

  getPolicyByContractServiceId(contractService: ContractService){   
    this.selectedContractService = contractService;

    this.clientPolicy = null;
    this.fetchingClientPolicy = true;
    this.theLegend = `Tag: ${contractService.uniqueTag}`
    this.clientPolicyService.getClientPolicyByContractServiceId(contractService.id)
      .subscribe(async (r: any) => {
      if(r.responseCode=='00'){
        var result = r.responseData ?? [];
        this.fetchingClientPolicy = false;
        this.policyExists = true;
        this.clientPolicy = result;  
        this.populateForm(result);
      }else{
        this.fetchingClientPolicy = false;
        this.policyExists = false;
        this.setupForm();
      }
    }, error => {
      // console.log(error);
      
    })
  }

  getPolicyByContractId(contract: Contract){
    this.clientPolicy = null;
    this.fetchingClientPolicy = true;
    this.theLegend = `${this.selectedCustomerDivision.divisionName}/${contract.id}-${contract.customerDivisionId}/${contract.caption || ""}`
    this.clientPolicyService.getClientPolicyByContractId(contract.id)
    .subscribe(async (r: any) => {
      if(r.responseCode=='00'){
        var result = r.responseData ?? [];
        this.fetchingClientPolicy = false;
        this.policyExists = true;
        this.clientPolicy = result;  
        this.populateForm(result);
      }else{
        this.fetchingClientPolicy = false;
        this.policyExists = false;
        this.setupForm();
      }
    }, error => {
      // console.log(error);
      
    })
  }

  populateForm(clientPolicy: ClientPolicy){
    this.clientPolicyForm.patchValue({
      autoRenew: clientPolicy.autoRenew,
      interval: clientPolicy.rateReviewInterval,
      reviewDate: new Date(clientPolicy.nextRateReviewDate).toUTCString(),
      renewalTenor: clientPolicy.renewalTenor
    })
  }

  setupForm(){
    this.clientPolicy = { customerDivisionId: this.selectedCustomerDivision.id }
    this.clientPolicyForm.patchValue({
      autoRenew: false,
      interval: 0,
      reviewDate: new Date().toUTCString()
    })
  }

  setNextReviewDate() {

    let currentDate = new Date()

    let interval = this.clientPolicyForm.value.interval as number;

    if(interval >= 0){
      let reviewDate = new Date(currentDate.setMonth(currentDate.getMonth() + interval)).toUTCString()

      // console.log(reviewDate)
  
      this.clientPolicyForm.patchValue({
        reviewDate
      })
    }
  }

  updateClientPolicy() {
    if(this.selectedContractService?.service?.serviceRelationshipEnum == ServiceRelationshipEnum.Admin){
      this.messageService.add({severity:'error', summary: 'Notice', detail:'You cannot set/update policy on admin service directly'});
      return;
    }

    if(this.selectedContractService?.service?.serviceRelationshipEnum == ServiceRelationshipEnum.Direct)
    {
      this.matchingAdminContractService = this.contractServices.find(x=>x.adminDirectTie==this.selectedContractService?.adminDirectTie && x.id != this.selectedContractService?.id);
      this.confirmationService.confirm({
        message: `This policy would also reflect on the admin '${this.matchingAdminContractService?.uniqueTag}'. Do want to continue?`,
        accept: () => {
            this._updateClientPolicy();
        }
      });
    }
    else
    {
      this.matchingAdminContractService = null;
     this.confirmationService.confirm({
      message: `Are you sure you want to save/update client policy ?`,
      accept: () => {
          this._updateClientPolicy();
      }
    });
    }
  }

  _updateClientPolicy(){

    if(this.policyExists){    

      this.messageService.add({severity:'info', summary: 'Notice', detail:'Updating Client Policy..'});

      var postData = []
      var policyForm = this.clientPolicyForm.value;
      const policyToUpdate = this.clientPolicy;
      policyToUpdate.autoRenew = policyForm.autoRenew;
      policyToUpdate.rateReviewInterval = policyForm.interval;
      policyToUpdate.nextRateReviewDate = policyForm.reviewDate;
      policyToUpdate.renewalTenor = policyForm.renewalTenor;

      postData.push(policyToUpdate);

      if(this.matchingAdminContractService)
      {
         var adminPolicy =  Object.assign({}, policyToUpdate);
         adminPolicy.contractServiceId = this.matchingAdminContractService?.id;
         postData.push(adminPolicy)
      }
      
      this.clientPolicyService.updateClientPolicy(postData).subscribe(async (result: CommonResponse) => {
        if(result.responseCode=='00'){
          this.messageService.add(
            {severity:'success',  summary: 'Success',  detail: 'Updated Client Policy Successfully.'}
          );
          this.clientPolicyForm.reset();
          this.clientPolicy = null;
        }
      }, error => {
        this.messageService.add({severity:'error', summary: 'Notice', detail:'Unable to update client policy.'});
      })

    }
    else
    {

      this.messageService.add({severity:'info', summary: 'Notice', detail:'Saving Client Policy..'});

      var policyForm = this.clientPolicyForm.value;
      var postData = [];

      var clientPolicy = {
        customerDivisionId: this.selectedCustomerDivision.id,
        contractId: this.selectedContract.isGroupInvoice ? this.selectedContract.id : null,
        contractServiceId: this.selectedContract.isGroupInvoice ? null : this.viewedContractService?.id,
        autoRenew: policyForm.autoRenew,
        rateReviewInterval: policyForm.interval,
        renewalTenor: policyForm.renewalTenor,
        nextRateReviewDate: policyForm.reviewDate //new Date(policyForm.reviewDate) //.toISOString()
      }

      postData.push(clientPolicy)
      
      if(this.matchingAdminContractService)
      {
         var admin =  {
          customerDivisionId: this.selectedCustomerDivision.id,
          contractId: this.selectedContract.isGroupInvoice ? this.selectedContract.id : null,
          contractServiceId: this.matchingAdminContractService?.id,
          autoRenew: policyForm.autoRenew,
          rateReviewInterval: policyForm.interval,
          renewalTenor: policyForm.renewalTenor,
          nextRateReviewDate: policyForm.reviewDate //new Date(policyForm.reviewDate).toISOString()
        }
        postData.push(admin)
      }


      // console.log('About to save ', this.clientPolicy)

      this.clientPolicyService.postClientPolicy(postData).subscribe(async (result: CommonResponse) => {
        if(result.responseCode=='00'){
          this.messageService.add(
            {severity:'success',  summary: 'Success',  detail: 'Saved Client Policy Successfully.'}
          );
          this.clientPolicyForm.reset();
          this.clientPolicy = null;
        }
      }, error => {
        this.messageService.add({severity:'error', summary: 'Notice', detail:'Unable to save client policy.'});
      })

    }
  }

  private connectionError() {
    this.messageService.add({severity:'error', summary: 'Failed',
        detail:'Connection Error, Please try again'});
  }
}