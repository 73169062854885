import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import {
  ConfirmationService,
  MessageService,
  SelectItem,
  TreeNode,
} from "primeng/api";
import {
  ApprovalLevel,
  ApprovalLimit,
  CommonResponse,
  ProcessRequiringApproval,
  Target,
} from "../../../interfaces/home";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { EventInput } from "@fullcalendar/core";
import { environment } from "../../../../environments/environment";
import { ApprovalLevelService } from "../../../services/approval-level.service";
import { ApprovalLimitService } from "../../../services/approval-limit.service";
import { ProcessRequiringApprovalService } from "../../../services/process-requiring-approval.service";
import { BreadcrumbService } from "src/app/breadcrumb.service";

@Component({
  selector: "app-approval-setup",
  templateUrl: "./approval-setup.component.html",
  styleUrls: ["./approval-setup.component.css"],
  providers: [MessageService, ConfirmationService],
})
export class ApprovalSetupComponent implements OnInit {
  @ViewChild("formWrapper") public formWrapper: ElementRef;

  approvalLevelForm: FormGroup;
  approvalLimitForm: FormGroup;

  approvalLevels: ApprovalLevel[];
  selectedApprovalLevel: ApprovalLevel;
  selectedApprovalLevelId: ApprovalLevel;

  modules: ProcessRequiringApproval[];
  selectedModule: ProcessRequiringApproval;

  fetchingModules = false;
  fetchingApprovalLevels = false;
  fetchingApprovalLimits = false;

  approvalLimits: ApprovalLimit[];
  selectedApprovalLimit: ApprovalLimit;

  levelCols: any;
  limitCols: any;
  exportLevelColumns: any;
  exportLimitColumns: any;
  lowerLimit: number;
  upperLimit: number;
  allApprovalLevels: ApprovalLevel[];

  selectedSequence: any;
  allSequences: SelectItem[];
  sequences: SelectItem[];
  edLevel: any;
  editingLevel: boolean;
  editingLimit: boolean;
  edLimit: any;

  constructor(
    public formBuilder: FormBuilder,
    public messageService: MessageService,
    public processRequiringApprovalService: ProcessRequiringApprovalService,
    public approvalLimitService: ApprovalLimitService,
    public approvalLevelService: ApprovalLevelService,
    public confirmationService: ConfirmationService,
    private breadcrumbService: BreadcrumbService
  ) {}

  ngOnInit(): void {
    this.breadcrumbService.setItems([
      { label: "Setup", routerLink: ["home/approval-setup"] },
      { label: "Approval-Setup", routerLink: ["home/approval-setup"] },
    ]);

    this.getModules();
    this.getApprovalLevels();
    this.getApprovalLimits();

    this.levelCols = [
      // { field: 'id', header: 'Id' },
      { field: "caption", header: "Caption" },
      { field: "alias", header: "Alias" },
      { field: "description", header: "Description" },
    ];

    this.limitCols = [
      { field: "module", header: "Module" },
      { field: "level", header: "level" },
      { field: "upperLimit", header: "Upper Limit" },
      { field: "lowerLimit", header: "Lower Limit" },
      { field: "sequence", header: "Sequence" },
    ];

    this.exportLevelColumns = this.levelCols.map((col) => ({
      title: col.header,
      dataKey: col.field,
    }));
    this.exportLimitColumns = this.limitCols.map((col) => ({
      title: col.header,
      dataKey: col.field,
    }));

    this.approvalLevelForm = this.formBuilder.group({
      caption: ["", Validators.required],
      alias: ["", Validators.required],
      description: ["", Validators.required],
    });

    this.approvalLimitForm = this.formBuilder.group({
      moduleId: [null, Validators.required],
      approvalLevelId: [null, Validators.required],
      sequence: ["", Validators.required],
      caption: ["", Validators.required],
      description: ["", Validators.required],
      upperLimit: ["", Validators.required],
      enableByPass: [false],
    });

    this.allSequences = this.sequences = [
      { label: "1", value: 1 },
      { label: "2", value: 2 },
      { label: "3", value: 3 },
      { label: "4", value: 4 },
      { label: "5", value: 5 },
    ];
  }

  getModules() {
    this.fetchingModules = true;
    this.processRequiringApprovalService
      .allProcessRequiringApprovalData()
      .subscribe(
        async (data: CommonResponse) => {
          this.modules = data.responseData;
          this.fetchingModules = false;
        },
        (error) => {
          this.fetchingModules = false;
          this.connectionError();
        }
      );
  }

  getApprovalLevels() {
    this.fetchingApprovalLevels = true;
    this.approvalLevelService.allApprovalLevelData().subscribe(
      async (r: CommonResponse) => {
        var data = r.responseData;
        this.allApprovalLevels = data;
        this.approvalLevels = data;
        this.fetchingApprovalLevels = false;
      },
      (error) => {
        this.fetchingApprovalLevels = false;
        this.connectionError();
      }
    );
  }

  getApprovalLimits() {
    this.fetchingApprovalLimits = true;
    this.approvalLimitService.allApprovalLimitData().subscribe(
      async (r: CommonResponse) => {
        var limits = r.responseData ?? [];
        limits.sort((a, b) =>
          a.processesRequiringApproval.caption >
          b.processesRequiringApproval.caption
            ? 1
            : -1
        );

        this.approvalLimits = limits;
        this.fetchingApprovalLimits = false;
      },
      (error) => {
        this.fetchingApprovalLimits = false;
        this.connectionError();
      }
    );
  }

  private connectionError() {
    this.messageService.add({
      severity: "error",
      summary: "Failed",
      detail: "Connection Error, Please try again",
    });
  }

  createApprovalLevel() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Creating new Approval Level",
    });
    const postData = {
      caption: this.approvalLevelForm.get("caption").value,
      alias: this.approvalLevelForm.get("alias").value,
      description: this.approvalLevelForm.get("description").value,
    };
    this.approvalLevelService.postApprovalLevel(postData).subscribe(
      async () => {
        await this.messageService.add({
          severity: "success",
          summary: "Completed",
          detail: "New Approval Level Created",
        });
        await this.getApprovalLevels();
        await this.approvalLevelForm.reset();
      },
      (error) => {
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail: "Unable to create Approval Level at the moment",
        });
      }
    );
  }

  createApprovalLimit() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Creating new Approval Limit",
    });

    const postData = {
      caption: this.approvalLimitForm.get("caption").value,
      description: this.approvalLimitForm.get("description").value,
      processesRequiringApprovalId: this.selectedModule.id,
      approverLevelId: this.selectedApprovalLevelId.id,
      upperlimitValue: this.approvalLimitForm.get("upperLimit").value,
      lowerlimitValue: this.lowerLimit,
      sequence: this.approvalLimitForm.get("sequence").value,
      isBypassRequired:
        this.approvalLimitForm.get("enableByPass").value ?? false,
    };

    this.approvalLimitService.postApprovalLimit(postData).subscribe(
      async () => {
        await this.messageService.add({
          severity: "success",
          summary: "Completed",
          detail: "New Approval Limit Created",
        });
        await this.getApprovalLimits();
        this.lowerLimit = null;
        await this.approvalLimitForm.reset();

        /*if(this.selectedSequence < 4){
                this.confirmationService.confirm({
                    message: `Kindly ensure to complete approval level(s) set up for ${this.selectedModule.caption}`,
                    accept: async () => {

                    } });
            }*/
      },
      (error) => {
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail: "Unable to create new Approval Limit at the moment",
        });
      }
    );
  }

  sequenceChanged() {
    if (this.selectedSequence) {
      // console.log('Selected Sequence ', this.selectedSequence);
      if (this.selectedSequence === 1) {
        this.lowerLimit = 0;
        return;
      }
    }

    if (this.selectedModule && this.selectedSequence) {
      const moduleApprovalLimit = this.approvalLimits.find(
        (x) =>
          x.processesRequiringApprovalId === this.selectedModule.id &&
          x.sequence === this.selectedSequence - 1
      );
      if (moduleApprovalLimit) {
        this.lowerLimit = moduleApprovalLimit.upperlimitValue + 1;
      }
    }
  }

  // Scenario #1. Module Selected
  // - Ensure that the levels that show up havent already been set up in the limits
  // - Ensure that only sequence that have not been set up in limit show up

  moduleSelected() {
    if (this.selectedModule) {
      // console.log('Selected Module ', this.selectedModule);
      // console.log('Approval Limits ', this.approvalLimits);
      const moduleApprovalLimits = this.approvalLimits.filter(
        (x) => x.processesRequiringApprovalId === this.selectedModule.id
      );
      // console.log('ModuleApprovalLimits ', moduleApprovalLimits);
      this.approvalLevels = this.allApprovalLevels.filter(
        (x) =>
          !moduleApprovalLimits.some(
            (limit) => limit.approverLevel.caption === x.caption
          )
      );
      // console.log('ApprovalLevels ', this.approvalLevels);
      const availableSequences = this.allSequences.filter(
        (x) => !moduleApprovalLimits.some((limit) => limit.sequence === x.value)
      );
      this.sequences = [
        availableSequences.sort((a, b) => a.value - b.value)[0],
      ];
    }
  }

  editLevel(detail) {
    this.editingLevel = true;
    this.approvalLevelForm.addControl(
      "levelId",
      new FormControl({ value: "", disabled: true }, Validators.required)
    );
    this.edLevel = detail;
    this.approvalLevelForm.patchValue({
      caption: detail.caption,
      alias: detail.alias,
      description: detail.description,
      levelId: detail.id,
    });

    this.formWrapper.nativeElement.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "start",
    });
  }

  closeEditing() {
    this.editingLevel = false;
    this.approvalLevelForm.reset();
    this.edLevel = null;
  }

  updateLevel() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Updating Level details",
    });
    const id = this.edLevel.id;
    const postData = {
      caption: this.edLevel.caption,
      alias: this.approvalLevelForm.get("alias").value,
      description: this.approvalLevelForm.get("description").value,
    };
    this.approvalLevelService
      .updateApprovalLevel(id, postData)
      .subscribe(async (result: Target) => {
        this.messageService.add({
          severity: "success",
          summary: "Notice",
          detail: "Level details Updated",
        });
        await this.getApprovalLevels();
        await this.closeEditing();
      });
  }

  editLimit(detail) {
    this.editingLimit = true;
    this.approvalLimitForm.addControl(
      "limitId",
      new FormControl({ value: "", disabled: true }, Validators.required)
    );
    this.edLimit = detail;
    this.approvalLimitForm.patchValue({
      caption: detail.caption,
      description: detail.description,
      upperLimit: detail.upperlimitValue,
      enableByPass: detail.isBypassRequired,
    });
    console.log("The detail ", detail);
    /*this.selectedSequence = detail.sequence;
        this.selectedApprovalLevel = detail.approverLevel;
        this.selectedModule = detail.processesRequiringApproval;*/
    this.lowerLimit = detail.lowerlimitValue;
  }

  deleteLimit(limit: any) {
    this.confirmationService.confirm({
      message:
        "Are you sure you want to remove approval limit, This action will remove every approval limit tied to it's module. Do you still wish to proceed?",
      accept: () => {
        this.messageService.add({
          severity: "info",
          summary: "Notice",
          detail: "Removing approval limit...",
        });

        this.approvalLimitService.DeleteApprovalLimitModule(limit.id).subscribe(
          async (data: any) => {
            if (data.responseCode != "00") {
              console.log("Error: " + JSON.stringify(data));
              this.messageService.add({
                severity: "error",
                summary: "Notice",
                detail: data.responseMsg,
              });
              return;
            }
            this.getApprovalLimits();
          },
          (error) => {
            console.log("Error: " + JSON.stringify(error));
            this.messageService.add({
              severity: "error",
              summary: "Notice",
              detail: "Unable to delete  Approval Limit at the moment",
            });
          }
        );
      },
    });
  }
}
