import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import {
  MessageService,
  ConfirmationService,
  MenuItem,
  Message,
} from "primeng/api";
import { BreadcrumbService } from "src/app/breadcrumb.service";
import {
  BankAccount,
  ExpenseBatchRequest,
  ExpenseBatchRequestPayment,
  UserExpenseRequestType,
} from "src/app/interfaces/finance-expense";
import { FinanceExpenseService } from "src/app/services/finance-expense.service";
import { UserService } from "src/app/services/user.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-finance-print-cheque",
  templateUrl: "./finance-print-cheque.component.html",
  styleUrls: ["./finance-print-cheque.component.scss"],
  providers: [MessageService],
})
export class FinancePrintChequeComponent implements OnInit {
  @ViewChild("formWrapper") public formWrapper: ElementRef;
  chequeForm: FormGroup;
  msg: Message[] = [];
  msg2: Message[] = [];
  steps: MenuItem[];
  activeStep: number;
  allExpenseBatches: ExpenseBatchRequest[];
  theExpenseBatch: ExpenseBatchRequest;
  selectBatchPayments: ExpenseBatchRequestPayment[];
  thePayment: ExpenseBatchRequestPayment;
  openBanksDialogue: boolean;
  allAvailableBanksToSelect: BankAccount[] = [];
  urlSafe: SafeResourceUrl;
  width = "100%";
  height = "1700";
  reportServer: string = `${environment.reportUrl}`;
  selectedBankAccount: BankAccount;

  constructor(
    fb: FormBuilder,
    public financeExpenseService: FinanceExpenseService,
    public userService: UserService,
    public messageService: MessageService,
    private breadcrumbService: BreadcrumbService,
    public confirmationService: ConfirmationService,
    public sanitizer: DomSanitizer
  ) {
    this.chequeForm = fb.group({
      ExpenseBatch: ["", Validators.required],
      ExpensePayment: ["", Validators.required],
      AmountToPrint: ["", Validators.required],
      Bank: ["", Validators.required],
      ChequeNo: ["", Validators.required],
      ChequeDate: ["", Validators.required],
    });
  }

  ngOnInit(): void {
    this.breadcrumbService.setItems([
      {
        label: "Finance",
        routerLink: ["/home/finance"],
      },
      {
        label: "Expense Tool",
        routerLink: ["/home/finance/expense"],
      },
      {
        label: "Print Cheque",
        routerLink: ["/home/finance/expense/print-cheque"],
      },
    ]);

    this.steps = [
      {
        label: "IDENTIFY CHEQUE PAYMENT",
      },
      {
        label: "PRINT CHEQUE",
      },
    ];
    this.activeStep = 0;
    this.urlSafe = "https://google.com";
    this.FetchAllAuthorizedCheques();
    this.ResetMessageToasters();
  }

  ResetMessageToasters() {
    this.msg = [];
    this.msg.push({
      severity: "info",
      summary:
        "You must set your printer default paper ize to A4 in print preferences. No other setup is required. This is a one-off process unless you changedit at a  later time. To print a cheque, just insert the cheque info at the upper manual paper tray with the guides adjusted to the size of your cheque. Then click the print button in the viewer beow to print.",
    });

    this.msg2 = [];
    this.msg2.push({
      severity: "warn",
      summary:
        "Make sure the appropriate printer is selected in the print dialog",
    });
  }

  FetchAllAuthorizedCheques() {
    this.financeExpenseService.GetAllAuthorizedExpenseBatches().subscribe(
      async (data) => {
        if (data.responseCode != "00") {
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail: data.responseMsg,
          });
          this.ResetMessageToasters();
          return;
        }

        this.allExpenseBatches = data.responseData;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch authorized batches at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
        this.ResetMessageToasters();
      }
    );
  }

  ProceedWithChequeGen() {
    if (this.thePayment.isPosted) {
      this.messageService.add({
        severity: "error",
        summary:
          "This payment has already been posted, so a cheque can't be printed for it.",
      });
      this.ResetMessageToasters();
      return;
    }
    if (this.selectedBankAccount) {
      this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(
        `${this.reportServer}report/Reports/${this.selectedBankAccount.bank.ssrsChequeUrl}?rs:Embed=true&rc:Parameters=true&rs:ParameterLanguage=en-us&rc:Toolbar=true`
      );
    }
    this.activeStep = 1;
  }

  OnExpenseBatchChange() {
    this.thePayment = null;
    this.selectBatchPayments = [];
    this.allAvailableBanksToSelect = [];
    this.chequeForm.patchValue({
      AmountToPrint: null,
      Bank: null,
      ChequeNo: null,
      ChequeDate: null,
    });
    if (this.theExpenseBatch) {
      this.messageService.add({
        severity: "info",
        summary: "Fetching Batch Payables",
      });

      this.financeExpenseService
        .GetExpenseBatchPayments(this.theExpenseBatch.id)
        .subscribe(
          async (data) => {
            if (data.responseCode != "00") {
              this.messageService.add({
                severity: "error",
                summary: "Notice",
                detail: data.responseMsg,
              });
              this.ResetMessageToasters();
              return;
            }

            this.selectBatchPayments = data.responseData;
          },
          (error) => {
            console.log("Error: " + JSON.stringify(error));
            this.messageService.add({
              severity: "error",
              summary: "Notice",
              detail:
                "Unable to fetch batch payables at the moment.. Reason: [" +
                (error ? error.error.message : "request failed - permission") +
                "]",
            });
            this.ResetMessageToasters();
          }
        );
    }
  }

  OnExpenseBatchPaymentChange() {
    this.allAvailableBanksToSelect = [];
    this.chequeForm.patchValue({
      AmountToPrint: null,
      Bank: null,
      ChequeNo: null,
      ChequeDate: null,
    });

    if (this.thePayment) {
      let amtToPrint =
        "N" +
        (
          this.thePayment.amountToPay + this.thePayment.vatAmount
        ).toLocaleString();

      let chqNo = this.thePayment.chequeNoSet;
      let chDate = this.thePayment.createdAt.toString().substring(0, 10);

      this.chequeForm.patchValue({
        AmountToPrint: amtToPrint,
        ChequeNo: chqNo,
        ChequeDate: chDate,
      });
      this.LoadEntityBank(this.theExpenseBatch);
    }
  }

  async LoadEntityBank(entity: ExpenseBatchRequest) {
    let entityId = 0;

    switch (entity.requestType) {
      case UserExpenseRequestType.Supplier:
        entityId = entity.supplierId;
        break;
      case UserExpenseRequestType.Staff:
        entityId = entity.staffId;
        break;
      case UserExpenseRequestType.Customer:
        entityId = entity.clientId;
        break;
      case UserExpenseRequestType.Operatives:
        entityId = entity.operativeId;
        break;
      default:
        this.messageService.add({
          severity: "error",
          summary: "Enity type has no bank account configuration(s)",
        });
        return;
    }

    this.financeExpenseService
      .GetExpenseBatchPaymentEntityBanks(entity.requestType, entityId)
      .subscribe(
        async (data) => {
          if (data.responseCode != "00") {
            this.messageService.add({
              severity: "error",
              summary: "Notice",
              detail: data.responseMsg,
            });
            this.ResetMessageToasters();
            return;
          }

          this.allAvailableBanksToSelect = data.responseData;
          let bnk: string = null;

          if (this.allAvailableBanksToSelect.length == 0) {
            this.messageService.add({
              severity: "error",
              summary: "No Bank Account Tied to the beneficiary",
            });
            this.ResetMessageToasters();
            return;
          }

          let primaryBank = this.allAvailableBanksToSelect.find(
            (x) => x.isPrimaryAccount
          );
          if (primaryBank) {
            bnk = primaryBank.bank.name + " - " + primaryBank.accountNumber;
            this.selectedBankAccount = primaryBank;
          } else {
            bnk =
              this.allAvailableBanksToSelect[0].bank.name +
              " - " +
              this.allAvailableBanksToSelect[0].accountNumber;
            this.selectedBankAccount = this.allAvailableBanksToSelect[0];
          }

          this.chequeForm.patchValue({
            Bank: bnk,
          });
        },
        (error) => {
          console.log("Error: " + JSON.stringify(error));
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail:
              "Unable to load entity bank accounts at the moment.. Reason: [" +
              (error ? error.error.message : "request failed - permission") +
              "]",
          });
          this.ResetMessageToasters();
        }
      );
  }

  SelectBankToPay() {
    this.openBanksDialogue = true;
  }

  ResetPrintCheque() {
    this.theExpenseBatch = null;
    this.allAvailableBanksToSelect = [];
    this.selectBatchPayments = [];
    this.thePayment = null;
    this.chequeForm.reset();
  }

  HideBanksDialog() {
    this.openBanksDialogue = false;
  }

  ApplySelectedBankToPay(item: BankAccount) {
    this.chequeForm.patchValue({
      Bank: item.bank.name + " - " + item.accountNumber,
    });
    this.selectedBankAccount = item;

    this.openBanksDialogue = false;
  }

  GoBackPrev() {
    this.activeStep = 0;
  }

  ConfirmPrint() {
    this.confirmationService.confirm({
      message:
        "You are about to confirm cheque print for this payment. This is an irreversible action. Do you still wish to proceed?",
      accept: () => {
        this.messageService.add({
          severity: "info",
          summary: "Notice",
          detail: "Confirming Cheque print...",
        });

        this.financeExpenseService
          .ConfirmPaymentChequePrinted(this.thePayment.id)
          .subscribe(
            async () => {
              await this.messageService.add({
                severity: "success",
                summary: "Confirmed Successfullt",
                detail: "Cheque has been printed for this payment!",
              });

              this.GoBackPrev();
              this.ResetPrintCheque();
            },
            (error) => {
              console.log("Error: " + JSON.stringify(error));
              this.messageService.add({
                severity: "error",
                summary: "Notice",
                detail:
                  "Unable to confirm cheque print at the moment.. Reason: [" +
                  error
                    ? error.error.message
                    : "request failed - permission" + "]",
              });
            }
          );
      },
    });
  }
}
