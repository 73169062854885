///<reference types="@types/googlemaps" />
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/storage';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ConfirmationService, MessageService, TreeNode } from 'primeng/api';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { BreadcrumbService } from 'src/app/breadcrumb.service';
import { CommonResponse, Lga, State } from 'src/app/interfaces/home';
import { LGARestingStationMaps, Locations, ResponseTeam, ResponseTeamRestingStationMap, ResponseTeamRestingStationMap2, ResponseTeamRestingStationMap3, RestingStation } from 'src/app/interfaces/response';
import { InventoryService } from 'src/app/services/inventory.service';
import { ResponseTeamServiceService } from 'src/app/services/response/response-team-service.service';
import { RestingStationServiceService } from 'src/app/services/response/resting-station-service.service';
import { StateService } from 'src/app/services/state.service';

@Component({
  selector: 'app-resp-team-reststation-map',
  templateUrl: './resp-team-reststation-map.component.html',
  styleUrls: ['./resp-team-reststation-map.component.scss']
})
export class RespTeamReststationMapComponent implements OnInit {
  @ViewChild("formWrapper") public formWrapper: ElementRef;
  private unsubscriber$ = new Subject<void>();
  zoneTree: TreeNode[];
  poolTree: TreeNode[];
  selectedZoneNode: TreeNode;
  //responseTeamRestingStationMaps2: TreeNode[];

  files2: TreeNode[];

  files3: TreeNode[];

  selectedFiles1: TreeNode;

  selectedFiles2: TreeNode[];

  selectedFiles3: TreeNode;

  cols: any[];
  
  public targetCols: any;
  private exportTargetColumns: any;
  public submittingData: boolean;
  public loading: boolean;
  public submitButtonLabel: string;
 //public selectedMultiVehicles: RestingStation[] = [];

  public responseTeamRestingStationMaps:ResponseTeamRestingStationMap[];
  public responseTeamRestingStationMaps2:ResponseTeamRestingStationMap2[];
  public responseTeamRestingStationMaps3:ResponseTeamRestingStationMap2[];
  public selectedResponseTeamRestingStationMap:ResponseTeamRestingStationMap;
  editingRestingStationMap:boolean;
  edLGARestingStation:LGARestingStationMaps;
 public responseTeamRestingStationMapForm: FormGroup;
  public restingStations:RestingStation[];
  public selectedRestingStation:RestingStation;
    public responseTeams:ResponseTeam[];
    public responseTeams2:ResponseTeam[];
    public responseTeams3:ResponseTeamRestingStationMap3[];
  public selectedResponseTeam:ResponseTeam;
 allStates: State[];
 selectedState:State;
  allLGAs: Lga[]
  selectedLGA: Lga;
  locations:Locations[];
  teamName:string;
  stationName:string;
  rowGroupMetadata: {};
  constructor(private breadcrumbService: BreadcrumbService,
    public messageService: MessageService,
    public confirmationService: ConfirmationService,
    public fireStorage: AngularFireStorage,
    public inventoryService: InventoryService,
    private formBuilder: FormBuilder,
    private restingStationService: RestingStationServiceService,
    private responseTeamServiceService: ResponseTeamServiceService,
    public stateService: StateService,
    ) 
    {this.breadcrumbService.setItems([
      { label: "Response Team Resting Station Map" },
      {
        label: "Response Team Resting Station Map",
        routerLink: ["/home/response/resp-team-reststation-map"],
      },
    ]); }

  ngOnInit(): void {
     this.targetCols = [
      { field: "restingStationId", header: "restingStationId" },
      { field: "responseTeamId", header: "responseTeamId" },
      
    
    ];
    this.exportTargetColumns = this.targetCols.map((col) => ({
      title: col.header,
      dataKey: col.field,
    }));
    this.responseTeamRestingStationMapForm = this.formBuilder.group({
      restingStationId: [null],
      responseTeamId: [null],
     
    });
    this.zoneTree = [
      {
        label: "Halogen",
        children: [],
      },
    ];
    this.fetchAllResponseTeams();
    this.fetchAllRestingStations();
    this.fetchAllResponseTeamRestingStationMaps();
    this.fetchAllResponseTeamRestingStationMaps2();
    //this.initMap();
  }
ngAfterViewInit(): void {
    // Load google maps script after view init
    const DSLScript = document.createElement("script");
    DSLScript.src =
      "https://maps.googleapis.com/maps/api/js?key=AIzaSyCQuetprs2UHb_zKSXznyumyfvw95ERCDY"; // replace by your API key
    DSLScript.type = "text/javascript";
    document.body.appendChild(DSLScript);
    document.body.removeChild(DSLScript);

   // this.initMap(); responseTeamServiceService.allResponseTeamRestionStationMaps()
  }

  FetchZonalMapViewSummary() {
    this.responseTeamServiceService.allResponseTeamRestionStationMaps().subscribe(
      async (data) => {
      

        this.zoneTree = [
          {
            label: "Halogen",
            children: [],
          },
        ];

        let allRegionTree: any[] = [];
        this.responseTeamRestingStationMaps.forEach((region) => {
          let treeRegion = {
            key: "00",
            label: region.restingStation.stationName,
            data: region,
            icon: "pi pi-globe",
            children: [],
            selectable: false,
          };

          //region.zones = data.zones.filter((x) => x.regionId == region.id);
          let allZoneTree: any[] = [];
          let countZone = 0;
        

          //treeRegion.children = allZoneTree;
          allRegionTree.push(treeRegion);
        });
        this.zoneTree[0].children = allRegionTree;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch zonal map view summary at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  async ShowLocationOperatives(event) {
    if (event.node.data) {
      // if (this.selectedNodeData !== event.node.data) {
      //   this.selectedNodeData = event.node.data;
      // }

      this.responseTeamServiceService
        .allResponseTeamRestionStationMaps()
        .subscribe(
          async (data: CommonResponse) => {
            if(data.responseCode === "00")
            this.responseTeamRestingStationMaps = data.responseData;
            //this.showOperativeList = true;
          },
          (error) => {
            console.log("Error: " + JSON.stringify(error));
            this.messageService.add({
              severity: "error",
              summary: "Notice",
              detail:
                "Unable to fetch all location operatives at the moment.. Reason: [" +
                error
                  ? error.error.message
                  : "request failed - permission" + "]",
            });
          }
        );
    }
  }


 initMap(): void {
    
      
      if (this.locations.length <= 0) return;

    // The map, centered at Loc
    const map = new google.maps.Map(
      document.getElementById("map") as HTMLElement,
      {
        zoom: 6,
         center: {
          lat: 9.0643305,
          lng: 7.4892974,
        },
        //center: this.locations[0],
      }
    );

    this.locations.forEach((x) => {
      // The marker, positioned at Loc
      const marker = new google.maps.Marker({
        position: x,
        map: map,
        //label: x.label,
      });
    });
    }
  
fetchMap(){
  this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "fetching locations...",
    });
  this.initMap();
}
  // addMarkers() {
  //   this.locations.forEach(location => {
  //     const marker = new google.maps.Marker({
  //       position: new google.maps.LatLng(location.lat, location.lng),
  //       map: map,
  //       title: 'Hello World!'
  //     });
  //   });
  // }
  //  fetchAllStates() {
  //   this.stateService
  //     .allState()
  //     .pipe(takeUntil(this.unsubscriber$))
  //     .subscribe((r: CommonResponse) => {
  //       if (r.responseCode == "00") {
  //         var res = r.responseData;
  //         this.allStates = res;
  //         this.loading=false;
  //         console.log(res);
  //       }
  //        else {
  //           this.messageService.add({
  //             severity: "error",
  //             summary: "Failed",
  //             detail: " Error: " + r.responseMsg,
  //           });
  //           this.submittingData = false;
  //         }
  //     });
  // }
   fetchAllResponseTeams() {
    this.loading=true;
    this.responseTeamServiceService
      .allResponseTeams()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe((r: CommonResponse) => {
        if (r.responseCode == "00") {
          var res = r.responseData;
          this.responseTeams = res;
          this.loading=false;
          console.log(res);
        }
         else {
            this.messageService.add({
              severity: "error",
              summary: "Failed",
              detail: " Error: " + r.responseMsg,
            });
            this.submittingData = false;
          }
      });
  }
   fetchAllRestingStations() {
    this.loading=true;
    this.restingStationService
      .allRestingStations()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe((r: CommonResponse) => {
        if (r.responseCode == "00") {
          var res = r.responseData;
          this.restingStations = res;
          this.loading=false;
          console.log(res);
        }
         else {
            this.messageService.add({
              severity: "error",
              summary: "Failed",
              detail: " Error: " + r.responseMsg,
            });
            this.loading=false;
            this.submittingData = false;
          }
      });
  }
  fetchAllResponseTeamRestingStationMaps() {
    this.loading=true;
    this.responseTeamServiceService
      .allResponseTeamRestionStationMaps()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe((r: CommonResponse) => {
        if (r.responseCode == "00") {
          var res = r.responseData;
          this.responseTeamRestingStationMaps = res;
         this.locations = [];
        res.forEach((response) => {
          this.locations.push({
            lat: response.restingStation.latitude,
            lng: response.restingStation.longitude,
          });
        });
        console.log("Loc", this.locations);
         this.initMap();
          this.loading=false;
          console.log("Ress",res);
        }
         else {
            this.messageService.add({
              severity: "error",
              summary: "Failed",
              detail: " Error: " + r.responseMsg,
            });
            this.loading=false;
            this.submittingData = false;
          }
      });
  }
  fetchAllResponseTeamRestingStationMaps2() {
    this.loading=true;
    this.responseTeamServiceService
      .allResponseTeamRestionStationMaps()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe((r: CommonResponse) => {
        if (r.responseCode == "00") {
          var res = r.responseData;
          //this.responseTeamRestingStationMaps = res;
          console.log("My res", res)
         this.responseTeamRestingStationMaps2 = [];
         //this.responseTeamRestingStationMaps2 = res;
         let restStationId;
         let respTeamId;
         let restStationName;
         let id;
         let respTeam = [];
         const distinctIds = [...new Set(res.map(item => item.restingStationId))];
         distinctIds.forEach((dis)=>{
          respTeam = [];
          res.forEach((resp)=>{
            if(dis === resp.restingStationId){
              respTeam.push(resp.responseTeam)
              this.responseTeamRestingStationMaps2.push({
                id: resp.id,
                restingStationName: resp.restingStation.stationName,
                restingStationState: resp.restingStation.state,
                restingStationId: resp.restingStationId,
                responseTeamId: resp.responseTeamId,
                responseTeam: respTeam,
              });
            }
            
          })
        
         })
     
         //console.log("My ress", this.responseTeamRestingStationMaps2);

        const distinctItems = this.responseTeamRestingStationMaps2.reduce((acc, curr) => {
          const found = acc.find(item => item.restingStationId === curr.restingStationId);
          if (!found) {
            acc.push(curr);
          }
          return acc;
        }, []);
        this.responseTeamRestingStationMaps3 = distinctItems;
        // let allRegionTree: any[] = [];
         console.log("distinct reduce", this.responseTeamRestingStationMaps3);
         this.responseTeams3 = [];
         this.responseTeamRestingStationMaps3.forEach((item) => {
          item.responseTeam.forEach((team)=>{
            console.log("team", team)
            this.responseTeams3.push(
              {
              id: item.id,
              responseTeamName: team.teamName,
              restingStationId: item.restingStationId,
              restingStationName: item.restingStationName,
              responseTeamId: team.id,
              restingStationState: ""
            })
          })
         
        });
          
          console.log("final res", this.responseTeams3);
        // distinctItems.forEach((item)=>{
        //   let clusterTree = {
        //     //key: countZone + "-" + countCluster,
        //     label: item.restingStationName,
        //     data: "Documents Folder",
        //     icon: "pi pi-compass",
        //     children: item.responseTeam,
        //     selectable: false,
        //   };

        //   allRegionTree.push(clusterTree);
        // })
        //this.responseTeamRestingStationMaps3.
        //this.files2 = allRegionTree;
          this.loading=false;
        }
         else {
            this.messageService.add({
              severity: "error",
              summary: "Failed",
              detail: " Error: " + r.responseMsg,
            });
            this.loading=false;
            this.submittingData = false;
          }
      });
  }
   createResponseTeamRestingStationMap() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Creating record...",
    });
    this.submittingData = true;
    const formData = this.responseTeamRestingStationMapForm.value;
    const postData ={
      restingStationId: this.selectedRestingStation.id,
      responseTeamId: this.selectedResponseTeam.id,
    }
    this.responseTeamServiceService
      .postResponseTeamRestionStationMap(postData)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            this.messageService.add({
              summary: "Success",
              severity: "success",
              detail: "New mapping created",
            });
            var res = r.responseData;
            this.fetchResponseTeamRestingStationMapsById(res.id);
            this.fetchAllResponseTeamRestingStationMaps();
            this.fetchAllResponseTeamRestingStationMaps2();
            console.log(res);
            this.submittingData = false;
            
            //this.lGaRestingStationMapForm.reset();
            
          } else {
            this.messageService.add({
              severity: "error",
              summary: "Failed",
              detail: " Error: " + r.responseMsg,
            });
            this.submittingData = false;
          }
        },
        (error) => {
          this.messageService.add({
            severity: "error",
            summary: "Failed",
            detail: "Connection Error " + error.error.message,
          });
          this.submittingData = false;
        }
      );
  }
  fetchResponseTeamRestingStationMapsById(id){
 this.loading=true;
    this.responseTeamServiceService
      .getResponseTeamRestionStationMap(id)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe((r: CommonResponse) => {
        if (r.responseCode == "00") {
          var res = r.responseData;
          //this.selectedResponseTeamRestingStationMap = res;
          this.teamName = res.responseTeam.teamName;
            this.stationName = res.restingStation.stationName;
          this.loading=false;
          console.log("Res:", res);
        }
         else {
            this.messageService.add({
              severity: "error",
              summary: "Failed",
              detail: " Error: " + r.responseMsg,
            });
            this.loading=false;
            this.submittingData = false;
          }
      });
  }
  deleteResponseTeamRestingStationMap(restingStation: ResponseTeamRestingStationMap): void {
    this.confirmationService.confirm({
      message: "Are you sure you want to delete mapping?",
      accept: () => {
        this._deleteResponseTeamRestingStationMap(restingStation);
      },
    });
  }
   _deleteResponseTeamRestingStationMap(restingStation) {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Deleting mapping record",
    });
    this.responseTeamServiceService.deleteResponseTeamRestionStationMap(restingStation.id).subscribe(
      async (result: any) => {
        await this.messageService.add({
          severity: "success",
          summary: "Deleted",
          detail: "Station record removed",
        });
        //await this.fetchAllLGARestingStationMaps();
      },
      (error) => {
        this.connectionError();
      }
    );
  }
   
  onSort() {
    this.updateRowGroupMetaData();
  }

  updateRowGroupMetaData() {
    this.rowGroupMetadata = {};
    
    if (this.responseTeams3) {
      for (let i = 0; i < this.responseTeams3.length; i++) {
        const rowData = this.responseTeams3[i];
        //console.log("rowdata", rowData.responseTeam)
        const representativeName = rowData.restingStationName;

        if (i === 0) {
          this.rowGroupMetadata[representativeName] = { index: 0, size: 1 };
        } else {
          //const previousRowData = this.responseTeamRestingStationMaps3[i - 1];
          const previousRowData = this.responseTeams3[i - 1];
          const previousRowGroup = previousRowData.restingStationName;
          if (representativeName === previousRowGroup) {
            this.rowGroupMetadata[representativeName].size++;
          } else {
            this.rowGroupMetadata[representativeName] = { index: i, size: 1 };
          }
        }
      }
    }
    
  }
  
  // get responseId() {
  //   return this.selectedResponseTeam.id;
  // }
 
  // editRestingStation(restingStation: RestingStation): void {
  //   this.editingRestingStation = true;
  //   this.formWrapper.nativeElement.scrollIntoView({
  //     behavior: "smooth",
  //     block: "end",
  //     inline: "start",
  //   });
  //   this.submitButtonLabel = "Update";
  //   this.edRestingStation = restingStation;
  //   this.restingStationForm.setValue({
  //     stationDescription: restingStation.stationDescription,
  //     stationName: restingStation.stationName,
  //     stateId: this.selectedState.id,
  //     lgaId: this.selectedLGA.id,
  //     street: restingStation.street,
  //     address: restingStation.address,
  //     latitude: restingStation.latitude,
  //     longitude: restingStation.longitude,
  //     imageUrl: "",
  //   });
  //   // this.selectedVehicleType = this.vehicleTypes.find(
  //   //   (Type) => Type.id === vehicleProfiles.vehicleTypeId
  //   // );
  // }

  // updateResponseTeamRestingStationMap(): boolean {
  //   this.submittingData = true;
  //   if (!this.edLGARestingStation) {
  //     return false;
  //   }
  //   const formData = this.responseTeamRestingStationMapForm.value;
  //    const data ={
  //     restingStationId: this.selectedRestingStation.id,
  //     localGovtId: this.selectedLGA.id,
  //   }
  //   this.responseTeamServiceService
  //     .updateResponseTeamRestionStationMap(this.edLGARestingStation.id, data)
  //     .pipe(takeUntil(this.unsubscriber$))
  //     .subscribe(
  //       (r: CommonResponse) => {
  //         if (r.responseCode == "00") {
  //           this.messageService.add({
  //             severity: "success",
  //             detail: "Mapping Updated",
  //             summary: "Completed",
  //           });
  //           this.submittingData = false;
  //           this.closeEditing();
  //           this.fetchAllLGARestingStationMaps();
  //           this.lGaRestingStationMapForm.reset();
  //         }
  //       },
  //       (error) => {
  //         this.connectionError();
  //         this.submittingData = false;
  //       }
  //     );
  // }
    
   private connectionError() {
    this.messageService.add({
      severity: "error",
      summary: "Failed",
      detail: "Connection Error, Please try again",
    });
  }

   closeEditing() {
    this.editingRestingStationMap = false;
    //this.lGaRestingStationMapForm.reset();
    //this.edTeamType = null;

    this.submittingData = false;
    this.submitButtonLabel = "Create";
  }

}
