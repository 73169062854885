import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CommonResponse } from '../interfaces/home';

@Injectable({
  providedIn: 'root'
})
export class ClientContactQualificationService {

  ccqobservable: Observable<CommonResponse>;

  constructor(private http: HttpClient) { }
  baseUrl = environment.baseUrl+'ClientContactQualification/';
  // fetch all ClientContactQualification in the database
  allClientContactQualificationData(): Observable<CommonResponse>{
    if(this.ccqobservable) return this.ccqobservable
    else {
      this.ccqobservable = this.http.get<CommonResponse>(this.baseUrl).pipe(shareReplay()); 
      return this.ccqobservable;
    }
  }
  getClientContactQualification(id: any):Observable<CommonResponse>{
    return this.http.get<CommonResponse>(this.baseUrl +id);
  }
  getClientContactQualificationByName(name: any):Observable<CommonResponse>{
    return this.http.get<CommonResponse>(this.baseUrl + 'name/' + name);
  }
  postClientContactQualification(data: any){
    return this.http.post(this.baseUrl, data);
  }
  deleteClientContactQualification(id: any){
    return this.http.delete(this.baseUrl +id);
  }
  updateClientContactQualification(id: any, data: any){
    return this.http.put(this.baseUrl +id, data);
  }
}
