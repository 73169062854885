import { BranchService } from "src/app/services/branch.service";
import { Branch, CommonResponse, User } from "src/app/interfaces/home";
import { Lga, State } from "./../../../interfaces/home";
import { StateService } from "./../../../services/state.service";
import {
  CentralStore,
  CompleteStoreDetails,
  CreateStore,
  RegionalStore,
  StockBatch,
  StoreItemBatchHistory,
  StoreItems,
  StoreManager,
  UpdateStoreReceivingDTO,
} from "./../../../interfaces/inventory";
import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { ConfirmationService, MessageService, TreeNode } from "primeng/api";
import { BreadcrumbService } from "src/app/breadcrumb.service";
import { InventoryService } from "src/app/services/inventory.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { finalize } from "rxjs/operators";
import { FileStorageService } from "src/app/services/file-storage.service";
import { UserService } from "src/app/services/user.service";

@Component({
  selector: "app-inventory-store",
  templateUrl: "./inventory-store.component.html",
  styleUrls: ["./inventory-store.component.scss"],
})
export class InventoryStoreComponent implements OnInit {
  @ViewChild("formWrapper") public formWrapper: ElementRef;
  inventoryStoreTree: TreeNode[];
  // selectedStore: CentralStore | RegionalStore | number;
  selectedStore: any;
  allCentralStores: CentralStore[];
  selectedCentralStore: CentralStore;
  allRegionalStores: RegionalStore[];
  selectedRegionalStore: RegionalStore;
  allStates: State[];
  theState: State;
  theEditState: State;
  allLGAs: Lga[];
  LgasToShow: Lga[];
  EditLgasToShow: Lga[];
  theLga: Lga;
  theEditLga: Lga;
  loadingInventoryStores: boolean = true;
  fetchingStoreItems: boolean;
  storeItems: StoreItems[];
  selectedStoreItem: StoreItems;
  storeItemCols: any[];
  storeDetailTable: {
    name: string;
    data: string;
  }[];
  selectedstoreDetailTable: {
    name: string;
    data: string;
  };
  form: FormGroup;
  editForm: FormGroup;
  imageSrc: string;
  allBranches: Branch[];
  theBranch: Branch;
  allStoreManagers: StoreManager[];
  theStoreManager: StoreManager;
  theDeputyStoreManager: StoreManager;
  theEditStoreManager: StoreManager;
  theEditDeputyStoreManager: StoreManager;
  uploadedStoreLogo: any[] = [];
  storeType: string;
  centralStoreName: string;
  isRegionalStore: boolean;
  cantCreateStoreUnder: boolean;
  openShowDialogue: boolean;
  openHistoryDialogue: boolean;
  storeItemToDisplay: StoreItems;
  canLoadStoreItems: boolean;
  fetchingItemBatchHistory: boolean;
  storeItemBatchHistory: StoreItemBatchHistory[];
  selectedStoreItemBatchHistory: StoreItemBatchHistory;
  storeItemHistoryCols: any[];
  historyStoreItem: StoreItems;
  isRegionBatchHistory: boolean = false;
  isCentralStoreInView: boolean = false;
  storeInViewDetails: CompleteStoreDetails;
  openEditStoreDialoge: boolean = false;
  storeInEdit: CompleteStoreDetails;
  editNode: TreeNode;
  loadedStoreInfo: string;
  allUsers: User[];
  selectedRestockers: User[] = [];

  constructor(
    private fb: FormBuilder,
    public userService: UserService,
    public fileStorageService: FileStorageService,
    public branchService: BranchService,
    public stateService: StateService,
    public inventoryService: InventoryService,
    private breadcrumbService: BreadcrumbService,
    public confirmationService: ConfirmationService,
    public messageService: MessageService
  ) {
    this.form = fb.group({
      StoreType: [""],
      CentralStore: [""],
      StoreName: ["", Validators.required],
      State: ["", Validators.required],
      LGA: ["", Validators.required],
      Street: ["", Validators.required],
      Address: [""],
      Branch: [""],
      StoreManager: ["", Validators.required],
      DeputyStoreManager: [""],
    });

    this.editForm = fb.group({
      StoreName: ["", Validators.required],
      State: ["", Validators.required],
      LGA: ["", Validators.required],
      Street: ["", Validators.required],
      Address: [""],
      StoreManager: ["", Validators.required],
      DeputyStoreManager: [""],
      Restockers: [""],
    });
  }

  ngOnInit(): void {
    this.breadcrumbService.setItems([
      {
        label: "Inventory Management",
        routerLink: ["/home/inventory-management/set-up"],
      },
      {
        label: "Inventory Store",
        routerLink: ["/home/inventory-management/inventory-store"],
      },
    ]);

    this.storeItemCols = [
      { field: "item.caption", header: "Item.caption" },
      { field: "item.description", header: "Item.description" },
      { field: "item.code", header: "Item.code" },
      { field: "quantity", header: "Quantity" },
      { field: "unitPrice", header: "UnitPrice" },
      { field: "entryBatchNumber", header: "EntryBatchNumber" },
    ];

    this.storeDetailTable = [
      {
        name: "Store Name",
        data: "Data",
      },
      {
        name: "Store ID",
        data: "Data",
      },
      {
        name: "Total Distinct Items Remaining",
        data: "Data",
      },
      {
        name: "Total Value",
        data: "Data",
      },
    ];

    this.storeItemHistoryCols = [
      { field: "batchRequest.name", header: "BatchRequest.name" },
      { field: "batchRequest.batchNumber", header: "BatchRequest.batchNumber" },
      { field: "batchRequest.description", header: "BatchRequest.description" },
      { field: "batchRequest.createdAt", header: "BatchRequest.createdAt" },
    ];

    this.allLGAs = [];
    this.allStoreManagers = [];
    this.FetchAllInventoryStores();
    this.FetchAllStates();
    this.FetchAllBranches();
    this.FetchAllStoreManagers();
    this.centralStoreName = "";
    this.GetAllUsers();
  }

  async GetAllUsers() {
    this.userService.allUser().subscribe(
      async (r) => {
        var data = r.responseData ?? [];
        this.allUsers = [];
        data.forEach((user) => {
          user.fullName = user.lastName + " " + user.firstName;
          this.allUsers.push(user);
        });
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all users at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  async ShowStoreData(event) {
    if (event.node.data) {
      this.editNode = event.node;
      if (this.selectedStore !== event.node.data) {
        this.selectedStore = event.node.data;
      }
    }

    if (this.selectedStore == 1001) {
      this.cantCreateStoreUnder = false;
      this.isRegionalStore = false;
      this.storeType = "Central";
      this.canLoadStoreItems = false;

      this.storeDetailTable[0].data = "Halogen Global Store";
      this.storeDetailTable[1].data = "GLOBAL-00000";
      this.storeDetailTable[2].data = "~";
      this.storeDetailTable[3].data = "~";
    } else {
      if (this.selectedStore.centralStoreId) {
        this.cantCreateStoreUnder = true;
        this.isRegionalStore = true;
        this.storeType = "Regional";
        this.imageSrc = "";

        this.storeDetailTable[1].data =
          "REGIONAL-" + String(this.selectedStore.id).padStart(5, "0");
      } else {
        this.cantCreateStoreUnder = false;
        this.isRegionalStore = true;
        this.storeType = "Regional";

        this.storeDetailTable[1].data =
          "CENTRAL-" + String(this.selectedStore.id).padStart(5, "0");
      }

      this.centralStoreName = this.selectedStore.storeName
        ? this.selectedStore.storeName
        : this.selectedStore.regionalStoreName;
      this.storeDetailTable[0].data = this.selectedStore.storeName
        ? this.selectedStore.storeName
        : this.selectedStore.regionalStoreName;
      this.storeDetailTable[2].data = "~";
      this.storeDetailTable[3].data = "~";
      this.canLoadStoreItems = true;
      this.isCentralStoreInView = this.selectedStore.storeName ? true : false;
      await this.CompletedStoreDetails(
        this.selectedStore.storeName ? true : false,
        this.selectedStore.id
      );
    }
  }

  async CompletedStoreDetails(isCentral: boolean, id: number) {
    this.inventoryService
      .CompletStoreDetail({ isCentral: isCentral, storeId: id })
      .subscribe(
        (data) => {
          this.storeDetailTable[2].data = data.itemsInStore + "";
          this.storeInViewDetails = data;
        },
        (error) => {
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail:
              "Unable to complete store details at the moment.. Reason: [" +
              error
                ? error.error.message
                : "request failed - permission" + "]",
          });
        }
      );
  }

  FetchAllInventoryStores() {
    this.inventoryService.GetAllInventoryStores().subscribe(
      (data) => {
        this.allCentralStores = data.centralStores;
        this.allRegionalStores = data.regionalStores;
        this.PopulateStoreTree();
      },
      (error) => {
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all inventory stores at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  FetchAllStates() {
    this.stateService.allState().subscribe(
      (data) => {
        this.allStates = data.responseData;
        this.allStates.forEach((state) => {
          state.lgAs.forEach((lga) => {
            this.allLGAs.push(lga);
          });
        });
        this.LgasToShow = this.allLGAs;
      },
      (error) => {
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all states at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  FetchAllLGAs() {
    this.stateService.allLgas().subscribe(
      (data) => {
        this.allLGAs = data.responseData;
        this.LgasToShow = data.responseData;
      },
      (error) => {
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all lgas at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  FetchAllBranches() {
    this.branchService.allBranch().subscribe(
      (data) => {
        this.allBranches = data.responseData;
      },
      (error) => {
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all branches at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  FetchAllStoreManagers() {
    this.inventoryService.GetAllStoreManagers().subscribe(
      (data) => {
        //this.allStoreManagers = data;
        data.forEach((user) => {
          user.manager.fullName =
            user.manager.lastName + " " + user.manager.firstName;
          this.allStoreManagers.push(user);
        });
      },
      (error) => {
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all store managers at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  PopulateStoreTree() {
    this.inventoryStoreTree = [];
    const centralStoresTree = [];
    this.loadingInventoryStores = false;

    this.allCentralStores.forEach((centralStore) => {
      const regionalStoresTree = [];
      this.allRegionalStores.forEach((regionStore) => {
        if (regionStore.centralStoreId == centralStore.id) {
          regionalStoresTree.push({
            label: regionStore.regionalStoreName,
            key: regionStore.id,
            icon: "pi pi-fw pi-users",
            data: regionStore,
            selectable: true,
          });
        }
      });

      centralStoresTree.push({
        label: centralStore.storeName,
        key: centralStore.id,
        expandedIcon: "pi pi-minus-circle",
        collapsedIcon: "pi pi-plus-circle",
        expanded: true,
        data: centralStore,
        children: regionalStoresTree,
        selectable: true,
      });
    });

    this.inventoryStoreTree = [
      {
        label: "Halogen",
        key: "Halogen Folder",
        expandedIcon: "pi pi-minus-circle",
        collapsedIcon: "pi pi-plus-circle",
        expanded: true,
        children: centralStoresTree,
        data: 1001,
        selectable: true,
      },
    ];
  }

  LoadStoreItems() {
    this.messageService.add({
      severity: "info",
      summary: "Info",
      detail: "Loading Store Items...",
    });
    this.fetchingStoreItems = true;
    this.loadedStoreInfo = null;
    if (this.selectedStore == 1001) {
      this.messageService.add({
        severity: "error",
        summary: "Invalid Action",
        detail: "Kindly select a central or regional store to load.",
      });
      this.fetchingStoreItems = false;
    } else {
      if (this.selectedStore.regionalStoreName) {
        this.inventoryService
          .GetAllRegionStoreItems(this.selectedStore.id)
          .subscribe(
            (data) => {
              this.fetchingStoreItems = false;
              this.storeItems = data;
              this.loadedStoreInfo =
                this.selectedStore.regionalStoreName + " Items Loaded...";
            },
            (error) => {
              this.messageService.add({
                severity: "error",
                summary: "Notice",
                detail:
                  "Unable to fetch all region store items at the moment.. Reason: [" +
                  error
                    ? error.error.message
                    : "request failed - permission" + "]",
              });
            }
          );
      } else {
        this.inventoryService
          .GetAllCentralStoreItems(this.selectedStore.id)
          .subscribe(
            (data) => {
              this.fetchingStoreItems = false;
              this.storeItems = data;
              this.loadedStoreInfo =
                this.selectedStore.storeName + " Items Loaded...";
            },
            (error) => {
              this.messageService.add({
                severity: "error",
                summary: "Notice",
                detail:
                  "Unable to fetch all central store items at the moment.. Reason: [" +
                  error
                    ? error.error.message
                    : "request failed - permission" + "]",
              });
            }
          );
      }
    }
  }

  PopulateLGAS() {
    this.LgasToShow = this.theState.lgAs;
  }

  onUpload(event, form) {
    this.uploadedStoreLogo = [];
    for (const file of event.files) {
      this.uploadedStoreLogo.push(file);
    }
    this.imageSrc = this.uploadedStoreLogo[0].objectURL;

    this.messageService.add({
      severity: "success",
      summary: "Success",
      detail: "Logo Uploaded Successfully!",
    });

    form.clear();
  }

  async CreateStore() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail:
        "Creating " +
        (this.isRegionalStore ? "Regional " : "Central ") +
        " Store...",
    });

    const postData: CreateStore = {
      isRegionalStore: this.isRegionalStore,
      storeName: this.form.get("StoreName").value,
      stateId: this.theState.id,
      lgaId: this.theLga.id,
      street: this.form.get("Street").value,
      address: this.StreetValue + ", " + this.LGAValue + ", " + this.StateValue,
      storeManagerId: this.theStoreManager.id,
      branchId: this.isRegionalStore ? this.theBranch.id : 0,
      centralStoreId: this.isRegionalStore ? this.selectedStore.id : 0,
      deputyStoreManagerId: this.theDeputyStoreManager
        ? this.theDeputyStoreManager.id
        : null,
    };

    this.fileStorageService.UploadFileFromDataUrl(this.uploadedStoreLogo[0]);
    this.fileStorageService.onUploadFinished.subscribe(
      (resp: CommonResponse<string>) => {
        if (resp.responseCode != "00") {
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail: resp.responseMsg,
          });
        } else {
          let url = resp.responseData;

          // post the form data
          postData.logoImageUrl = url;
          this.inventoryService.CreateInventoryStore(postData).subscribe(
            async () => {
              await this.messageService.add({
                severity: "success",
                summary: "Completed",
                detail:
                  "New " +
                  (this.isRegionalStore ? "Regional " : "Central ") +
                  "Store Created...",
              });

              this.form.reset();
              this.FetchAllInventoryStores();
              this.uploadedStoreLogo = [];
              this.imageSrc = "";
            },
            (error) => {
              this.messageService.add({
                severity: "error",
                summary: "Notice",
                detail:
                  "Unable to create " +
                  (this.isRegionalStore ? "Regional " : "Central ") +
                  "store at the moment.. Reason: [" +
                  error
                    ? error.error.message
                    : "request failed - permission" + "]",
              });
            }
          );
        }
      },
      (error) => {
        console.log("Error while uploading file " + error);
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail: "ERR: Unable to upload image to storage",
        });
      }
    );
  }

  ShowStoreItemHistory(item: StoreItems) {
    this.openHistoryDialogue = true;
    this.historyStoreItem = item;
    this.fetchingItemBatchHistory = true;
    this.inventoryService.GetStoreItemBatchHistory(item.id).subscribe(
      (data) => {
        this.storeItemBatchHistory = data;
        this.fetchingItemBatchHistory = false;
        if (data.length > 0) {
          if (data[0].batchRequest.regionalStoreId)
            this.isRegionBatchHistory = true;
          this.isRegionBatchHistory = false;
        }
      },
      (error) => {
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to load store item batch history at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
        this.fetchingItemBatchHistory = false;
      }
    );
  }

  GetItemInBatchQty(item: StockBatch) {
    let itemRequest = item.itemsRequested.find(
      (data) => data.itemId == this.historyStoreItem.itemId
    );

    return this.isCentralStoreInView
      ? itemRequest.quantityDispatched <= 0
        ? itemRequest.quantity
        : itemRequest.quantityDispatched
      : itemRequest.quantityConfirmed <= 0
      ? itemRequest.quantityDispatched
      : itemRequest.quantityConfirmed;
  }

  GetItemInBatchUnitPrice(item: StockBatch) {
    return item.itemsRequested.find(
      (data) => data.itemId == this.historyStoreItem.itemId
    ).unitPrice;
  }

  ShowStoreItemDetails(item: StoreItems) {
    this.openShowDialogue = true;
    this.storeItemToDisplay = item;
  }

  HideShowDialog() {
    this.openShowDialogue = false;
    this.storeItemToDisplay = null;
  }

  HideHistoryDialog() {
    this.openHistoryDialogue = false;
    this.historyStoreItem = null;
  }

  EditStoreInViewDetails() {
    this.openEditStoreDialoge = true;
    this.storeInEdit = this.storeInViewDetails;
    if (this.isCentralStoreInView) {
      this.editForm.patchValue({
        StoreName: this.storeInEdit.centralStore.storeName,
        Street: this.storeInEdit.centralStore.street,
      });
      this.theEditState = this.allStates.find(
        (x) => x.id == this.storeInEdit.centralStore.stateId
      );
      this.EditLgasToShow = this.theEditState.lgAs;
      this.theEditLga = this.EditLgasToShow.find(
        (x) => x.id == this.storeInEdit.centralStore.lgaId
      );
      this.theEditStoreManager = this.allStoreManagers.find(
        (x) => x.id == this.storeInEdit.centralStore.storeManagerId
      );
      this.theEditDeputyStoreManager = this.allStoreManagers.find(
        (x) => x.id == this.storeInEdit.centralStore.deputyStoreManagerId
      );
      let centralStoreInfo = this.allCentralStores.find(
        (x) => x.id == this.storeInEdit.centralStore.id
      );
      this.selectedRestockers = this.allUsers.filter(
        (x) =>
          centralStoreInfo.restockers
            .filter((x) => !x.isDeleted)
            .find((y) => y.restockerProfileId == x.id) != null
      );
    } else {
      this.editForm.patchValue({
        StoreName: this.storeInEdit.regionalStore.regionalStoreName,
        Street: this.storeInEdit.regionalStore.street,
      });
      this.theEditState = this.allStates.find(
        (x) => x.id == this.storeInEdit.regionalStore.stateId
      );
      this.EditLgasToShow = this.theEditState.lgAs;
      this.theEditLga = this.EditLgasToShow.find(
        (x) => x.id == this.storeInEdit.regionalStore.lgaId
      );
      this.theEditStoreManager = this.allStoreManagers.find(
        (x) => x.id == this.storeInEdit.regionalStore.storeManagerId
      );
      this.theEditDeputyStoreManager = this.allStoreManagers.find(
        (x) => x.id == this.storeInEdit.regionalStore.deputyStoreManagerId
      );
      let regionalStoreInfo = this.allRegionalStores.find(
        (x) => x.id == this.storeInEdit.regionalStore.id
      );
      this.selectedRestockers = this.allUsers.filter(
        (x) =>
          regionalStoreInfo.restockers
            .filter((x) => !x.isDeleted)
            .find((y) => y.restockerProfileId == x.id) != null
      );
    }
  }

  CancelStoreEditDialogue() {
    this.openEditStoreDialoge = false;
    this.editForm.reset();
    this.theEditLga = null;
    this.theEditState = null;
    this.EditLgasToShow = [];
    this.theEditStoreManager = null;
    this.storeInEdit = null;
    this.editNode = null;
    this.theEditDeputyStoreManager = null;
    this.selectedRestockers = [];
  }

  UpdateStoreData() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Updating Store Data",
    });

    const postData: UpdateStoreReceivingDTO = {
      storeName: this.editForm.get("StoreName").value,
      lGAID: this.theEditLga.id,
      street: this.editForm.get("Street").value,
      address:
        this.EditStreetValue +
        ", " +
        this.EditLGAValue +
        ", " +
        this.EditStateValue,
      storeManagerId: this.theEditStoreManager.id,
      deputyStoreManagerId: this.theEditDeputyStoreManager
        ? this.theEditDeputyStoreManager.id
        : null,
      storeRestockers: this.selectedRestockers.map((x) => x.id),
    };

    if (this.isCentralStoreInView) {
      this.inventoryService
        .UpdateCentralStore(this.storeInEdit.centralStore.id, postData)
        .subscribe(
          async () => {
            await this.messageService.add({
              severity: "success",
              summary: "Completed",
              detail: "Updated Successfully!",
            });

            this.storeDetailTable[0].data = postData.storeName;
            this.editNode.label = postData.storeName;
            this.storeInEdit.centralStore.storeName = postData.storeName;
            this.editNode.data = this.storeInEdit.centralStore;
            this.CancelStoreEditDialogue();
          },
          (error) => {
            console.log(error);
            this.messageService.add({
              severity: "error",
              summary: "Notice",
              detail: "Error - Request Failed",
            });
          }
        );
    } else {
      this.inventoryService
        .UpdateRegionalStore(this.storeInEdit.regionalStore.id, postData)
        .subscribe(
          async () => {
            await this.messageService.add({
              severity: "success",
              summary: "Completed",
              detail: "Updated Successfully!",
            });

            this.editNode.label = postData.storeName;
            this.storeDetailTable[0].data = postData.storeName;
            this.storeInEdit.regionalStore.regionalStoreName =
              postData.storeName;
            this.editNode.data = this.storeInEdit.regionalStore;
            this.CancelStoreEditDialogue();
          },
          (error) => {
            console.log(error);
            this.messageService.add({
              severity: "error",
              summary: "Notice",
              detail: "Error - Request Failed",
            });
          }
        );
    }
  }

  PopulateEditLGAS() {
    this.EditLgasToShow = this.theEditState.lgAs;
  }

  get StreetValue() {
    return this.form.get("Street").value;
  }

  get LGAValue() {
    return this.theLga ? this.theLga.name : "";
  }

  get StateValue() {
    return this.theState ? this.theState.name : "";
  }

  get EditStreetValue() {
    return this.editForm.get("Street").value;
  }

  get EditLGAValue() {
    return this.theEditLga ? this.theEditLga.name : "";
  }

  get EditStateValue() {
    return this.theEditState ? this.theEditState.name : "";
  }
}
