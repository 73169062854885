import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { ConfirmationService, MessageService } from "primeng/api";
import { BreadcrumbService } from "src/app/breadcrumb.service";
import {
  Division,
  OperatingEntity,
  ServiceCategory,
  ServiceGroup,
  Services,
  User,
} from "src/app/interfaces/home";
import {
  CreateNonPrincipalApprovingOfficeVM,
  NonPrincipalApprovingOffice,
  NonPrincipalApprovingOfficer,
  ServiceTypeEnum,
  UpdateNonPrincipalApprovingOfficeVM,
} from "src/app/interfaces/organization";
import { DivisionService } from "src/app/services/division.service";
import { OperatingEntityService } from "src/app/services/operating-entity.service";
import { OrganizationService } from "src/app/services/organization.service";
import { ServiceCategoryService } from "src/app/services/service-category.service";
import { ServiceGroupService } from "src/app/services/service-group.service";
import { ServicesService } from "src/app/services/services.service";
import { UserService } from "src/app/services/user.service";

@Component({
  selector: "app-non-principal-approval-setup",
  templateUrl: "./non-principal-approval-setup.component.html",
  styleUrls: ["./non-principal-approval-setup.component.scss"],
})
export class NonPrincipalApprovalSetupComponent implements OnInit {
  @ViewChild("formWrapper") public formWrapper: ElementRef;
  approvalForm: FormGroup;
  allDivisions: Division[];
  allDivisionsToShow: Division[] = [];
  theDivision: Division;
  allMarkets: OperatingEntity[];
  allMarketsToShow: OperatingEntity[] = [];
  theMarket: OperatingEntity;
  allServiceGroup: ServiceGroup[];
  allServiceGroupToShow: ServiceGroup[] = [];
  theServiceGroup: ServiceGroup;
  allServiceCategories: ServiceCategory[];
  allServiceCategoriesToShow: ServiceCategory[] = [];
  theServiceCategory: ServiceCategory;
  allServices: Services[];
  allServicesToShow: Services[] = [];
  selectedServices: Services[] = [];
  allUsers: User[];
  allUsersToShow: User[] = [];
  theApprovingUser: User;
  addedApprovingOfficers: NonPrincipalApprovingOfficer[] = [];
  selectedApprovingOfficer: NonPrincipalApprovingOfficer[] = [];
  addedAppOfficerCols: any[];
  fetchingAppOffices: boolean;
  allApprovingOffices: NonPrincipalApprovingOffice[] = [];
  selectedApprovingOffices: NonPrincipalApprovingOffice[] = [];
  cols: any[];
  editingApprovingOffice: boolean;
  allServiceTypes: {
    key: number;
    value: string;
  }[];
  theSereviceTypes: {
    key: number;
    value: string;
  };
  approvingOfficeToEdit: NonPrincipalApprovingOffice;
  isDivisionDoneLoading: boolean;
  isMarketDoneLoading: boolean;
  isServiceGroupDoneLoading: boolean;
  isServiceCategoryDoneLaoding: boolean;
  isServiceDoneLoading: boolean;

  constructor(
    private fb: FormBuilder,
    private organizationService: OrganizationService,
    private divisionService: DivisionService,
    private operatingEntityService: OperatingEntityService,
    private serviceGroupService: ServiceGroupService,
    private serviceCategoryService: ServiceCategoryService,
    private servicesService: ServicesService,
    private userService: UserService,
    private breadcrumbService: BreadcrumbService,
    public confirmationService: ConfirmationService,
    public messageService: MessageService
  ) {
    this.approvalForm = fb.group({
      OfficeName: ["", Validators.required],
      Division: ["", Validators.required],
      Market: ["", Validators.required],
      ServiceGroup: ["", Validators.required],
      ServiceCategory: ["", Validators.required],
      ServiceType: ["", Validators.required],
      Services: ["", Validators.required],
      ApprovingUser: [""],
    });
  }

  ngOnInit(): void {
    this.breadcrumbService.setItems([
      {
        label: "Setup",
        routerLink: ["/home/setups"],
      },
      {
        label: "Non-Principal Approval Setup",
        routerLink: ["/home/non-principal-approval-setup"],
      },
    ]);

    this.cols = [
      { field: "serviceType", header: "Service Type" },
      { field: "officeName", header: "Office Name" },
      { field: "sequence", header: "Sequence" },
      { field: "serviceCategoryName", header: "ServiceCategory Name" },
      { field: "serviceGroupName", header: "ServiceGroup Name" },
      { field: "marketName", header: "Market Name" },
      { field: "divsionName", header: "Divsion Name" },
    ];

    this.allServiceTypes = [
      {
        key: ServiceTypeEnum.ServiceCreation,
        value: "Service Creation",
      },
      {
        key: ServiceTypeEnum.ContractCreation,
        value: "Additional Contract Creation",
      },
      {
        key: ServiceTypeEnum.Contracts,
        value: "Contractract Creation",
      },
      {
        key: ServiceTypeEnum.Endorsements,
        value: "Endorsements",
      },
    ];

    this.GetAllDivisions();
    this.GetAllMarkets();
    this.GetAllServiceGroup();
    this.GetAllServiceCategory();
    this.GetAllServices();
    this.GetAllUser();
    this.GetAllNonPrincipalApprovingOffices();
  }

  async GetAllDivisions() {
    this.divisionService.allDivisionData().subscribe(
      async (data) => {
        this.allDivisions = data.responseData;
        this.allDivisionsToShow = this.allDivisions;
        this.isDivisionDoneLoading = true;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all divsions at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  async GetAllMarkets() {
    this.operatingEntityService.allData().subscribe(
      async (data) => {
        this.allMarkets = data.responseData;
        this.isMarketDoneLoading = true;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all operating entities at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  async GetAllServiceGroup() {
    this.serviceGroupService.allServiceGroup().subscribe(
      async (data) => {
        this.allServiceGroup = data.responseData;
        this.isServiceGroupDoneLoading = true;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all service group at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  async GetAllServiceCategory() {
    this.serviceCategoryService.allServiceCategory().subscribe(
      async (data) => {
        this.allServiceCategories = data.responseData;
        this.isServiceCategoryDoneLaoding = true;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all service categories at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  async GetAllServices() {
    this.servicesService.allService().subscribe(
      async (data) => {
        this.allServices = data.responseData;
        this.isServiceDoneLoading = true;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all services at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  async GetAllUser() {
    this.userService.allUser().subscribe(
      async (r) => {
        var data = r.responseData ?? [];
        this.allUsers = [];
        data.forEach((user) => {
          user.fullName = user.lastName + " " + user.firstName;
          this.allUsers.push(user);
        });
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all users at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  async GetAllNonPrincipalApprovingOffices() {
    this.fetchingAppOffices = true;
    this.organizationService.GetAllNonPrincipalApprovingOffices().subscribe(
      async (data) => {
        this.allApprovingOffices = data;
        this.fetchingAppOffices = false;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all offices at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
        this.fetchingAppOffices = false;
      }
    );
  }

  CreateApprovalOffice() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Creating Approval Office...",
    });

    const postData: CreateNonPrincipalApprovingOfficeVM = {
      divisionId: this.theDivision.id,
      divsionName: this.theDivision.name,
      marketId: this.theMarket.id,
      marketName: this.theMarket.name,
      serviceGroupId: this.theServiceGroup.id,
      serviceGroupName: this.theServiceGroup.name,
      serviceCategoryId: this.theServiceCategory.id,
      serviceCategoryName: this.theMarket.name,
      officeName: this.approvalForm.get("OfficeName").value,
      officerIds: this.addedApprovingOfficers.map((x) => x.userId),
      serviceType: this.theSereviceTypes.key,
      services: [],
    };

    this.selectedServices.forEach((service) => {
      postData.services.push({
        serviceId: service.id,
        serviceName: service.name,
      });
    });

    this.organizationService
      .CreateNonPrincipalApprovingOffice(postData)
      .subscribe(
        async () => {
          await this.messageService.add({
            severity: "success",
            summary: "Completed",
            detail: "Approving Office Created Successfully!",
          });

          this.approvalForm.reset();
          this.addedApprovingOfficers = [];
          this.selectedServices = [];
          this.GetAllNonPrincipalApprovingOffices();
        },
        (error) => {
          console.log("Error: " + JSON.stringify(error));
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail:
              "Unable to create approving office at the moment.. Reason: [" +
              (error ? error.error.message : "request failed - permission") +
              "]",
          });
        }
      );
  }

  OnDivisionChange() {
    this.theMarket = null;

    if (this.theDivision) {
      this.allMarketsToShow = this.allMarkets.filter(
        (x) => x.divisionId == this.theDivision.id
      );
    } else {
      this.allMarketsToShow = [];
    }

    this.allServiceGroupToShow = [];
    this.theServiceGroup = null;
    this.allServiceCategoriesToShow = [];
    this.theServiceCategory = null;
    this.allServicesToShow = [];
    this.selectedServices = [];
    this.allUsersToShow = [];
    this.theApprovingUser = null;
  }

  OnMarketChange() {
    this.theServiceGroup = null;

    if (this.theMarket) {
      this.allServiceGroupToShow = this.allServiceGroup.filter(
        (x) => x.operatingEntity.id == this.theMarket.id
      );
    } else {
      this.allServiceGroupToShow = [];
    }

    this.allServiceCategoriesToShow = [];
    this.theServiceCategory = null;
    this.allServicesToShow = [];
    this.selectedServices = [];
    this.allUsersToShow = [];
    this.theApprovingUser = null;
  }

  OnServiceGroupChange() {
    this.theServiceCategory = null;

    if (this.theServiceGroup) {
      this.allServiceCategoriesToShow = this.allServiceCategories.filter(
        (x) => x.serviceGroupId == this.theServiceGroup.id
      );
    } else {
      this.allServiceCategoriesToShow = [];
    }

    this.allServicesToShow = [];
    this.selectedServices = [];
    this.allUsersToShow = [];
    this.theApprovingUser = null;
  }

  OnServiceCategoryChange() {
    this.selectedServices = [];
    this.allUsersToShow = [];
    this.theApprovingUser = null;

    if (this.theServiceCategory) {
      this.allServicesToShow = this.allServices.filter(
        (x) => x.serviceCategoryId == this.theServiceCategory.id
      );
      this.allUsersToShow = this.allUsers;
    } else {
      this.allServicesToShow = [];
    }
  }

  AddApprovingOfficerToList() {
    if (!this.theApprovingUser) {
      this.messageService.add({
        severity: "error",
        summary: "Failed",
        detail: "You must select the officer to add",
      });
      return;
    }

    let alreadyAdded = false;
    this.addedApprovingOfficers.forEach((item) => {
      if (item.userId == this.theApprovingUser.id) {
        alreadyAdded = true;
      }
    });

    if (alreadyAdded) {
      this.messageService.add({
        severity: "error",
        summary: "Failed",
        detail: "Officer with the same info has already been added to list.",
      });
      return;
    }

    if (this.addedApprovingOfficers.length > 0) {
      this.messageService.add({
        severity: "error",
        summary: "Failed",
        detail:
          "Policy Flag: You can only add one approving officer per office.",
      });
      return;
    }

    this.addedApprovingOfficers.push({
      userId: this.theApprovingUser.id,
      user: this.theApprovingUser,
    });

    this.theApprovingUser = null;
    this.messageService.add({
      severity: "success",
      summary: "Added",
      detail: "Approving Officer added successfully",
    });
  }

  RemoveOfficer(item: NonPrincipalApprovingOfficer) {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Removing Approving Officer...",
    });

    const index = this.addedApprovingOfficers.indexOf(item);
    if (index > -1) {
      this.addedApprovingOfficers.splice(index, 1);
    }

    this.messageService.add({
      severity: "success",
      summary: "Removed",
      detail: "Approving Officer Removed successfully",
    });
  }

  GetServiceTypeLabel(id: number): string {
    if (id == ServiceTypeEnum.ServiceCreation) return "Service Creation";
    if (id == ServiceTypeEnum.ContractCreation) return "Contract Creation";
    if (id == ServiceTypeEnum.Contracts) return "Contracts";
    if (id == ServiceTypeEnum.Endorsements) return "Endorsements";

    return "N/A";
  }

  DeleteApprovalOffice(item: NonPrincipalApprovingOffice) {
    this.confirmationService.confirm({
      message: "Are you sure you want to remove approving office?",
      accept: () => {
        this.messageService.add({
          severity: "info",
          summary: "Notice",
          detail: "Removing approving office...",
        });

        this.organizationService
          .DeleteNonPrincipalApprovingOffice(item.id)
          .subscribe(
            async () => {
              await this.messageService.add({
                severity: "success",
                summary: "Removed",
                detail: "Removed successfully",
              });
              this.GetAllNonPrincipalApprovingOffices();
            },
            (error) => {
              console.log("Error: " + JSON.stringify(error));
              this.messageService.add({
                severity: "error",
                summary: "Notice",
                detail:
                  "Unable to remove approving office at the moment.. Reason: [" +
                  error
                    ? error.error.message
                    : "request failed - permission" + "]",
              });
            }
          );
      },
    });
  }

  EditApprovalOffice(item: NonPrincipalApprovingOffice) {
    this.editingApprovingOffice = true;
    this.approvalForm.patchValue({
      OfficeName: item.officeName,
    });
    this.approvingOfficeToEdit = item;
    this.addedApprovingOfficers = item.approvingOfficers;
    this.addedApprovingOfficers.forEach((data) => {
      data.user = this.allUsers.find((appUser) => appUser.id == data.userId);
    });
    this.theDivision = this.allDivisions.find((x) => x.id == item.divisionId);
    this.OnDivisionChange();
    this.theMarket = this.allMarkets.find((x) => x.id == item.marketId);
    this.OnMarketChange();
    this.theServiceGroup = this.allServiceGroup.find(
      (x) => x.id == item.serviceGroupId
    );
    this.OnServiceGroupChange();
    this.theServiceCategory = this.allServiceCategories.find(
      (x) => x.id == item.serviceCategoryId
    );
    this.OnServiceCategoryChange();
    this.selectedServices = this.allServicesToShow.filter(
      (x) =>
        item.approvingServices.find((serv) => serv.serviceId == x.id) != null
    );
    this.theSereviceTypes = this.allServiceTypes.find(
      (x) => x.key == item.serviceType
    );

    this.formWrapper.nativeElement.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "start",
    });
  }

  UpdateApprovalOffice() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Updating Approval Office...",
    });

    const id = this.approvingOfficeToEdit.id;
    const postData: UpdateNonPrincipalApprovingOfficeVM = {
      divisionId: this.theDivision.id,
      divsionName: this.theDivision.name,
      marketId: this.theMarket.id,
      marketName: this.theMarket.name,
      serviceGroupId: this.theServiceGroup.id,
      serviceGroupName: this.theServiceGroup.name,
      serviceCategoryId: this.theServiceCategory.id,
      serviceCategoryName: this.theMarket.name,
      officeName: this.approvalForm.get("OfficeName").value,
      officerIds: this.addedApprovingOfficers.map((x) => x.userId),
      serviceType: this.theSereviceTypes.key,
      services: [],
    };

    this.selectedServices.forEach((service) => {
      postData.services.push({
        serviceId: service.id,
        serviceName: service.name,
      });
    });

    this.organizationService
      .UpdateNonPrincipalApprovingOffice(id, postData)
      .subscribe(
        async () => {
          await this.messageService.add({
            severity: "success",
            summary: "Completed",
            detail: "Approval Office Updated Successfully.",
          });

          this.addedApprovingOfficers = [];
          this.GetAllNonPrincipalApprovingOffices();
          this.editingApprovingOffice = false;
          this.approvalForm.reset();
        },
        (error) => {
          console.log("Error: " + JSON.stringify(error));
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail:
              "Unable to update approving office at the moment.. Reason: [" +
              (error ? error.error.message : "request failed - permission") +
              "]",
          });
        }
      );
  }

  CloseEditingApprovalOffice() {
    this.editingApprovingOffice = false;
    this.approvalForm.reset();
    this.addedApprovingOfficers = [];
    this.approvingOfficeToEdit = null;
  }

  MoveOfficeSequence(option: boolean, sequence: number, servicetype: number) {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Moving Approval Office Sequence...",
    });

    this.organizationService
      .MoveNonPrincipalApprovingOfficeSequence({
        sequence: sequence,
        goesUp: option,
        serviceType: servicetype,
      })
      .subscribe(
        async () => {
          await this.messageService.add({
            severity: "success",
            summary: "Removed",
            detail: "Approving office sequence updated successfully",
          });
          this.GetAllNonPrincipalApprovingOffices();
        },
        (error) => {
          console.log("Error: " + JSON.stringify(error));
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail:
              "Unable to move approving office sequence at the moment.. Reason: [" +
              error
                ? error.error.message
                : "request failed - permission" + "]",
          });
        }
      );
  }

  OnServiceTypeChange() {}
}
