import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import {
  ArmedEscortResourceTie,
  CommanderProfile,
  CommanderResourceTie,
  MeansOfId,
  PilotProfile,
  PilotResourceTie,
  VehicleProfile,
  VehicleResourceTie,
} from "src/app/interfaces/armada";
import { CommonResponse } from "src/app/interfaces/home";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class ResourceRegistrationService {
  baseUrl = environment.baseUrl;
  //baseUrl = "https://localhost:44344/api/v1/";
  meansOfId = environment.baseUrl + "MeansOfIdentification";
  constructor(private httpClient: HttpClient) { }

  //Vehicle
  allVehicle(): Observable<CommonResponse> {
    return this.httpClient.get<CommonResponse>(
      this.baseUrl + "VehicleRegistration/GetAllVehicles"
    );
  }
  allNotHeldVehicle(): Observable<CommonResponse> {
    return this.httpClient.get<CommonResponse>(
      this.baseUrl + "VehicleRegistration/GetAllVehiclesNotHeld"
    );
  }
  allReservedVehicles(): Observable<CommonResponse> {
    return this.httpClient.get<CommonResponse>(
      this.baseUrl + "VehicleRegistration/GetAllVehiclesReservedForResponse"
    );
  }
  getVehicleProfile(id: any): Observable<CommonResponse> {
    return this.httpClient.get<CommonResponse>(
      this.baseUrl + "VehicleRegistration/GetVehicleById/" + id
    );
  }

  postVehicleProfile(data: any) {
    return this.httpClient.post(
      this.baseUrl + "VehicleRegistration/AddNewVehicle",
      data
    );
  }
  deleteVehicle(id: any) {
    return this.httpClient.delete(
      this.baseUrl + "VehicleRegistration/DeleteVehicleById/" + id
    );
  }
  updateVehicleProfile(id: any, data: any) {
    return this.httpClient.put(
      this.baseUrl + "VehicleRegistration/UpdateVehicleById/" + id,
      data
    );
  }
  //Vehicle Tie
  allVehicleTies(): Observable<CommonResponse> {
    return this.httpClient.get<CommonResponse>(
      this.baseUrl + "VehicleRegistration/GetAllVehicleTies"
    );
    //return this.http.get<Approval[]>(this.baseUrl + 'GetUserPendingApprovals');
  }

  allVehicleTiesByResourceId(id: any): Observable<CommonResponse> {
    return this.httpClient.get<CommonResponse>(
      this.baseUrl +
      "VehicleRegistration/GetAllVehicleTiesByResourceId?id=" +
      id
    );
    //return this.http.get<Approval[]>(this.baseUrl + 'GetUserPendingApprovals');
  }

  getVehicleProfileTie(id: any): Observable<CommonResponse> {
    return this.httpClient.get<CommonResponse>(
      this.baseUrl + "VehicleRegistration/GetVehicleTieById/" + id
    );
  }

  postVehicleProfileTie(data: any) {
    return this.httpClient.post(
      this.baseUrl + "VehicleRegistration/AddNewVehicleTie",
      data
    );
  }
  deleteVehicleTie(id: any) {
    return this.httpClient.delete(
      this.baseUrl + "VehicleRegistration/DeleteVehicleTieById/" + id
    );
  }

  //Commander
  allCommanderProfile(): Observable<CommonResponse> {
    return this.httpClient.get<CommonResponse>(
      this.baseUrl + "CommanderRegistration/GetAllCommanderProfiles"
    );
  }

  allCommanderProfileNotHeld(): Observable<CommonResponse> {
    return this.httpClient.get<CommonResponse>(
      this.baseUrl + "CommanderRegistration/GetAllNotHeldCommanders"
    );
  }
  allReservedCommanderProfiles(): Observable<CommonResponse> {
    return this.httpClient.get<CommonResponse>(
      this.baseUrl + "CommanderRegistration/GetAllCommandersReservedForResponse"
    );
  }

  getCommanderProfile(id: any): Observable<CommonResponse> {
    return this.httpClient.get<CommonResponse>(
      this.baseUrl + "CommanderRegistration/GetProfileById/" + id
    );
  }

  postCommanderProfile(data: any) {
    return this.httpClient.post(
      this.baseUrl + "CommanderRegistration/AddNewCommanderProfile",
      data
    );
  }
  deleteCommander(id: any) {
    return this.httpClient.delete(
      this.baseUrl + "CommanderRegistration/DeleteProfileById/" + id
    );
  }
  updateCommanderProfile(id: any, data: any) {
    return this.httpClient.put(
      this.baseUrl + "CommanderRegistration/UpdateProfileById/" + id,
      data
    );
  }

  //Commander Tie
  allCommanderProfileTies(): Observable<CommonResponse> {
    return this.httpClient.get<CommonResponse>(
      this.baseUrl + "CommanderRegistration/GetAllCommanderTies"
    );
  }

  allCommanderProfileTiesByResourceId(id: any): Observable<CommonResponse> {
    return this.httpClient.get<CommonResponse>(
      this.baseUrl +
      "CommanderRegistration/GetAllCommanderTiesByResourceId?id=" +
      id
    );
  }

  getCommanderProfileTie(id: any): Observable<CommonResponse> {
    return this.httpClient.get<CommonResponse>(
      this.baseUrl + "CommanderRegistration/GetProfileTieById/" + id
    );
  }

  postCommanderProfileTie(data: any) {
    return this.httpClient.post(
      this.baseUrl + "CommanderRegistration/AddNewProfileTie",
      data
    );
  }
  deleteCommanderTie(id: any) {
    return this.httpClient.delete(
      this.baseUrl + "CommanderRegistration/DeleteProfileTieById/" + id
    );
  }

  //Pilot
  allPilotProfile(): Observable<CommonResponse> {
    return this.httpClient.get<CommonResponse>(
      this.baseUrl + "PilotRegistration/GetAllPilotProfiles"
    );
  }

  allPilotProfileNotHeld(): Observable<CommonResponse> {
    return this.httpClient.get<CommonResponse>(
      this.baseUrl + "PilotRegistration/GetAllNotHeldPilots"
    );
  }
  allReservedPilotProfiles(): Observable<CommonResponse> {
    return this.httpClient.get<CommonResponse>(
      this.baseUrl + "PilotRegistration/GetAllPilotsReservedForResponse"
    );
  }

  allMeansOfId(): Observable<CommonResponse> {
    return this.httpClient.get<CommonResponse>(this.meansOfId);
  }
  getPilotProfile(id: any): Observable<CommonResponse> {
    return this.httpClient.get<CommonResponse>(
      this.baseUrl + "PilotRegistration/GetProfileById/" + id
    );
  }

  postPilotProfile(data: any) {
    return this.httpClient.post(
      this.baseUrl + "PilotRegistration/AddNewProfile",
      data
    );
  }
  delete(id: any) {
    return this.httpClient.delete(
      this.baseUrl + "PilotRegistration/DeleteProfileById/" + id
    );
  }
  updatePilotProfile(id: any, data: any) {
    return this.httpClient.put(
      this.baseUrl + "PilotRegistration/UpdateProfileById/" + id,
      data
    );
  }

  //Pilot Tie
  allPilotProfileTies(): Observable<CommonResponse> {
    return this.httpClient.get<CommonResponse>(
      this.baseUrl + "PilotRegistration/GetAllPilotTies"
    );
    //return this.http.get<Approval[]>(this.baseUrl + 'GetUserPendingApprovals');
  }

  allPilotProfileTiesByResourceId(id: any): Observable<CommonResponse> {
    return this.httpClient.get<CommonResponse>(
      this.baseUrl + "PilotRegistration/GetAllPilotTiesByResourceId?id=" + id
    );
    //return this.http.get<Approval[]>(this.baseUrl + 'GetUserPendingApprovals');
  }

  getPilotProfileTie(id: any): Observable<CommonResponse> {
    return this.httpClient.get<CommonResponse>(
      this.baseUrl + "PilotRegistration/GetProfileTieById/" + id
    );
  }

  postPilotProfileTie(data: any) {
    return this.httpClient.post(
      this.baseUrl + "PilotRegistration/AddNewProfileTie",
      data
    );
  }
  deletePilotTie(id: any) {
    return this.httpClient.delete(
      this.baseUrl + "PilotRegistration/DeleteProfileTieById/" + id
    );
  }

  //Armed Escort
  allArmedEscortProfile(): Observable<CommonResponse> {
    return this.httpClient.get<CommonResponse>(
      this.baseUrl + "ArmedEscortRegistration/GetAllArmedEscortProfiless"
    );
  }

  allArmedEscortProfileNotHeld(): Observable<CommonResponse> {
    return this.httpClient.get<CommonResponse>(
      this.baseUrl + "ArmedEscortRegistration/GetAllNotHeldArmedEscorts"
    );
  }
  allReservedArmedEscortProfile(): Observable<CommonResponse> {
    return this.httpClient.get<CommonResponse>(
      this.baseUrl + "ArmedEscortRegistration/GetAllArmedEscortsReservedForResponse"
    );
  }

  getArmedEscortProfile(id: any): Observable<CommonResponse> {
    return this.httpClient.get<CommonResponse>(
      this.baseUrl + "ArmedEscortRegistration/GetProfileById/" + id
    );
  }

  postArmedEscortProfile(data: any) {
    return this.httpClient.post(
      this.baseUrl + "ArmedEscortRegistration/AddNewProfile",
      data
    );
  }
  deleteArmedEscort(id: any) {
    return this.httpClient.delete(
      this.baseUrl + "ArmedEscortRegistration/DeleteProfileById/" + id
    );
  }
  updateArmedEscortProfile(id: any, data: any) {
    return this.httpClient.put(
      this.baseUrl + "ArmedEscortRegistration/UpdateProfileById/" + id,
      data
    );
  }

  //Escort Tie
  allArmedEscortProfileTie(): Observable<CommonResponse> {
    return this.httpClient.get<CommonResponse>(
      this.baseUrl + "ArmedEscortRegistration/GetAllArmedEscortTies"
    );
  }

  allArmedEscortProfileTiesByResourceId(id: any): Observable<CommonResponse> {
    return this.httpClient.get<CommonResponse>(
      this.baseUrl +
      "ArmedEscortRegistration/GetAllArmedEscortTiesByResourceId?id=" +
      id
    );
  }

  getArmedEscortProfileTie(id: any): Observable<CommonResponse> {
    return this.httpClient.get<CommonResponse>(
      this.baseUrl + "ArmedEscortRegistration/GetProfileTieById/" + id
    );
  }

  postArmedEscortProfileTie(data: any) {
    return this.httpClient.post(
      this.baseUrl + "ArmedEscortRegistration/AddNewProfileTie",
      data
    );
  }
  deleteArmedEscortTie(id: any) {
    return this.httpClient.delete(
      this.baseUrl + "ArmedEscortRegistration/DeleteProfileTieById/" + id
    );
  }
}
