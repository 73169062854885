import { Component, OnInit } from '@angular/core';
import {FormGroup} from '@angular/forms';
import {MessageService} from 'primeng/api';
import {DynamicDialogConfig, DynamicDialogRef} from 'primeng/dynamicdialog';

@Component({
  selector: 'app-pick-deliverable',
  templateUrl: './pick-deliverable.component.html',
  styleUrls: ['./pick-deliverable.component.scss']
})
export class PickDeliverableComponent implements OnInit {
  message: string;
  displayLoading: boolean;
  pickDeliverableForm: FormGroup;
  constructor(
      private messageService: MessageService,
      public dialogRef: DynamicDialogRef,
      public config: DynamicDialogConfig
  ) { }
  ngOnInit(): void {
  }

  onFileSelect($event: Event) {
  }

  submitPickDeliverable() {
  }
}
