import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {BreadcrumbService} from '../../../../breadcrumb.service';
import {Subject} from 'rxjs';
import {SituationType} from '../../../../interfaces/armada';
import {SituationTypeService} from '../../../../services/armada/situation-type.service';
import {takeUntil} from 'rxjs/operators';
import {ConfirmationService, MessageService} from 'primeng/api';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

@Component({
  selector: 'app-situation-type',
  templateUrl: './situation-type.component.html',
  styleUrls: ['./situation-type.component.scss'],
  providers: [ConfirmationService],
})
export class SituationTypeComponent implements OnInit, OnDestroy {
  @ViewChild('formWrapper') public formWrapper:ElementRef;
  private unsubscriber$ = new Subject<void>();
  public situationTypes: SituationType[];
  public targetCols: any;
  private exportTargetColumns: any;
  public loading: boolean;
  public situationTypeForm: FormGroup;
  public submittingData: boolean;
  selectedSituationType: any;
  editingSituationType= false;
  private edSituationType: SituationType;
  submitButtonLabel: string;
  constructor(
      private breadcrumbService: BreadcrumbService,
      private situationTypeService: SituationTypeService,
      public messageService: MessageService,
      public confirmationService: ConfirmationService,
      private formBuilder: FormBuilder,
  ) {
    this.breadcrumbService.setItems([
      { label: 'Setup' },
      { label: 'Situation Type', routerLink: ['/setup/situation-type'] }
    ])
  }

  ngOnInit(): void {
    this.loading = true;
    this.targetCols = [
    { field: 'caption', header: 'Caption' },
    { field: 'description', header: 'Description' },
    { field: 'codeName', header: 'Code Name' },
  ];
    this.exportTargetColumns = this.targetCols.map(col => ({title: col.header, dataKey: col.field}));
    this.situationTypeForm = this.formBuilder.group( {
      caption: ['', [Validators.required, Validators.minLength(3)]],
      description: ['', [Validators.required, Validators.minLength(3)]],
      codeName: ['', [Validators.required, Validators.minLength(3)]],
    })
    this.fetchAllSituationType();
    this.submitButtonLabel = 'create';
  }
  fetchAllSituationType(): void {
    this.situationTypeService.allSituationTypes().pipe(takeUntil(this.unsubscriber$)).subscribe( (res: any) => {
      this.situationTypes = res;
      this.loading = false;
    });
  }
  createSituationType() {
    this.submittingData = true;
    this.situationTypeService.postSituationType(this.situationTypeForm.value).pipe(takeUntil(this.unsubscriber$))
        .subscribe( (result) => {
          this.messageService.add({
            summary: 'Success', severity: 'success', detail: 'New situation type created',
          });
          this.fetchAllSituationType();
          this.submittingData = false;
          this.situationTypeForm.reset();
        })
  }


  deleteSituationType(situationTypes: SituationType): void {
    this.confirmationService.confirm({
      message: 'Are you sure you want to delete ' + situationTypes.caption,
      accept: () => {
        this._deleteSituationType(situationTypes);
      }
    });
  }
  editSituationType(situationTypes: SituationType): void{
    this.formWrapper.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'start' });
    this.situationTypeForm.setValue({
      caption: situationTypes.caption,
      codeName: situationTypes.codeName,
      description: situationTypes.description,
    });
    this.edSituationType = situationTypes;
    this.editingSituationType = true;
    this.submitButtonLabel = 'Update';
  }
  updateSituationType(): boolean {
    this.submittingData = true;
    if(!this.edSituationType) {
      return false;
    }
    const formData = this.situationTypeForm.value;
    const data = {
      codeName: formData.codeName,
      caption: formData.caption,
      description: formData.description,
    }
    this.situationTypeService.updateSituationType(this.edSituationType.id, data)
        .pipe(takeUntil(this.unsubscriber$))
        .subscribe(() => {
          this.messageService.add({
            severity: 'success',
            detail: 'Armed Escort Type Updated',
            summary: 'Completed'
          });
          this.submittingData = false;
          this.closeEditing();
          this.fetchAllSituationType();
        }, error => {
          this.connectionError();
          this.submittingData = false;
        })
  }
  _deleteSituationType(situationTypes) {
    this.messageService.add({severity:'info', summary: 'Notice',
      detail:'Deleting Situation Type record'});
    this.situationTypeService.deleteSituationType(situationTypes.id).subscribe( async (result:any) => {
      await this.messageService.add({severity:'success', summary: 'Deleted',
        detail:'Situation Type record removed'});
      await this.fetchAllSituationType();
    }, error => {
      this.connectionError()
    });
  }
  closeEditing() {
    this.editingSituationType = false;
    this.situationTypeForm.reset();
    this.edSituationType = null;
    this.submitButtonLabel = 'create';
  }
  private connectionError() {
    this.messageService.add({severity:'error', summary: 'Failed',
      detail:'Connection Error, Please try again'});
  }
  ngOnDestroy(): void {
    this.unsubscriber$.complete();
    this.unsubscriber$.unsubscribe();
  }
}
