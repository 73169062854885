import { Component, OnInit } from "@angular/core";
import { ConfirmationService, MessageService, TreeNode } from "primeng/api";
import {
  AccountStatement,
  Account,
  AccountVoucherType,
  ControlAccount,
  AccountClass,
  CommonResponse,
} from "../../../interfaces/home";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { RoleService } from "../../../services/role.service";
import { AccountService } from "../../../services/account.service";
import { AccountClassService } from "../../../services/account-class.service";
import { ControlAccountService } from "../../../services/control-account.service";
import { AccountVoucherTypeService } from "../../../services/account-voucher-type.service";
import { Router } from "@angular/router";
import { BreadcrumbService } from "src/app/breadcrumb.service";

@Component({
  selector: "app-account-chart",
  templateUrl: "./account-chart.component.html",
  styleUrls: ["./account-chart.component.css"],
  providers: [MessageService, ConfirmationService],
})
export class AccountsChartComponent implements OnInit {
  statements: AccountStatement[];
  fetchingSubAccounts = false;
  cols: any;
  exportColumns: any[];
  selectedSubAccount: Account;
  accountClassTree: TreeNode[];
  loadingAccountClass = true;
  controlAccountDisplayForm: FormGroup;
  subAccountForm: FormGroup;
  accountClassDisplayForm: FormGroup;
  controlAccountForm: FormGroup;
  accountTypes: AccountVoucherType[];
  selectedControlAccount: ControlAccount;
  allControlAccounts: ControlAccount[];
  loadingControlAccounts = false;
  subAccounts: Account[];
  numberOfSubAccounts: number;
  numberOfActiveSubAccounts: number;
  selectedAccountClass: AccountClass;
  loadingVoucherTypes = false;
  private allAccountClasses: AccountClass[];

  constructor(
    public formBuilder: FormBuilder,
    private accountService: AccountService,
    private accountClassService: AccountClassService,
    private controlAccountService: ControlAccountService,
    private accountVoucherTypeService: AccountVoucherTypeService,
    public messageService: MessageService,
    public confirmationService: ConfirmationService,
    private router: Router,
    private breadcrumbService: BreadcrumbService
  ) {}

  ngOnInit(): void {
    this.breadcrumbService.setItems([
      { label: "Finance", routerLink: ["home/account-chart"] },
      { label: "Account-Chart", routerLink: ["home/account-chart"] },
    ]);

    this.FetchAccountClasses();
    // this.fetchAccountClassExtra();
    this.fetchControlAccounts();
    this.fetchAccountsVoucherType();
    this.controlAccountDisplayForm = this.formBuilder.group({});
    this.accountClassDisplayForm = this.formBuilder.group({});
    this.subAccountForm = this.formBuilder.group({
      name: ["", Validators.required],
      description: ["", Validators.required],
      alias: ["", Validators.required],
    });
    this.controlAccountForm = this.formBuilder.group({
      name: ["", Validators.required],
      description: ["", Validators.required],
      alias: ["", Validators.required],
    });

    this.cols = [
      { field: "id", header: "ID" },
      { field: "name", header: "Name" },
      { field: "description", header: "Description" },
      { field: "isActive", header: "Account Status" },
      { field: "alias", header: "Alias" },
      { field: "isDebitBalance", header: "Account Type" },
    ];
    this.exportColumns = this.cols.map((col) => ({
      title: col.header,
      dataKey: col.field,
    }));
  }

  async FetchAccountClasses() {
    this.loadingAccountClass = true;
    this.allAccountClasses = [];
    this.accountClassService.GetAccountClassesWithControlAccounts().subscribe(
      async (data) => {
        if (data.responseCode != "00") {
          this.messageService.add({
            severity: "error",
            summary: data.responseMsg,
          });
          this.loadingAccountClass = false;
          return;
        }

        this.allAccountClasses = data.responseData;
        this.loadingAccountClass = false;
        const accountClassTree = [];
        this.allAccountClasses.forEach((accountClass) => {
          const controlAccountsTree = [];

          accountClass.controlAccounts.forEach((controlAccount) => {
            controlAccountsTree.push({
              label: `${controlAccount.caption} (${controlAccount.accountNumber})`,
              key: controlAccount.id,
              icon: "pi pi-fw pi-briefcase",
              data: controlAccount,
              selectable: true,
            });
          });

          accountClassTree.push({
            label: `${accountClass.caption} (${accountClass.accountNumber})`,
            key: accountClass.id,
            expandedIcon: "pi pi-minus-circle",
            collapsedIcon: "pi pi-plus-circle",
            // expanded: true,
            data: accountClass,
            selectable: true,
            children: controlAccountsTree,
          });
        });

        this.accountClassTree = [
          {
            label: "Halogen",
            key: "Halogen Folder",
            expandedIcon: "pi pi-minus-circle",
            collapsedIcon: "pi pi-plus-circle",
            expanded: true,
            children: accountClassTree,
          },
        ];
        this.CalculateAccountsTotal();
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all account classes at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
        this.loadingAccountClass = false;
      }
    );
  }

  async CalculateAccountsTotal() {
    this.allAccountClasses.forEach((accountClass) => {
      let controlAccountIds = accountClass.controlAccounts.map((x) => x.id);
      this.accountService
        .CalculateControlAccountsTotal({ controlAccountIds })
        .subscribe(
          async (data) => {
            if (data.responseCode != "00") {
              this.messageService.add({
                severity: "error",
                summary: data.responseMsg,
              });
              return;
            }

            let accountClassTotal: number = 0.0;
            accountClass.controlAccounts.forEach((controlAccount) => {
              let controlAcctResult = data.responseData.find((x) => x.controlAccountId == controlAccount.id)
              controlAccount.total = controlAcctResult.totalValue;
              accountClassTotal += controlAcctResult.totalValue
            })
            accountClass.total = accountClassTotal;
          },
          (error) => {
            console.log("Error: " + JSON.stringify(error));
            this.messageService.add({
              severity: "error",
              summary: "Notice",
              detail:
                "Unable to fetch account class control account sub accounts at the moment.. Reason: [" +
                (error ? error.error.message : "request failed - permission") +
                "]",
            });
          }
        );
    });
  }

  fetchAccountClassExtra() {
    this.loadingAccountClass = true;
    this.accountClassService
      .allAccountClassesWithBalance()
      .subscribe(async (r: CommonResponse) => {
        this.loadingAccountClass = false;
        var res = r.responseData;

        const accountClasses = res;
        this.allAccountClasses = res;
        const accountClassTree = [];
        accountClasses.forEach((accountClass, k) => {
          const controlAccountsTree = [];

          accountClass.controlAccounts.forEach((v, key) => {
            controlAccountsTree.push({
              label: `${v.caption} (${v.accountNumber})`,
              key: v.id,
              icon: "pi pi-fw pi-briefcase",
              data: v,
              selectable: true,
            });
          });

          accountClassTree.push({
            label: `${accountClass.caption} (${accountClass.accountNumber})`,
            key: accountClass.id,
            expandedIcon: "pi pi-minus-circle",
            collapsedIcon: "pi pi-plus-circle",
            // expanded: true,
            data: accountClass,
            selectable: true,
            children: controlAccountsTree,
          });
        });

        this.accountClassTree = [
          {
            label: "Halogen",
            key: "Halogen Folder",
            expandedIcon: "pi pi-minus-circle",
            collapsedIcon: "pi pi-plus-circle",
            expanded: true,
            children: accountClassTree,
          },
        ];
      });
  }

  fetchControlAccounts() {
    this.loadingControlAccounts = true;
    this.controlAccountService
      .allControlAccounts()
      .subscribe(async (res: CommonResponse) => {
        this.loadingControlAccounts = false;
        this.allControlAccounts = res.responseData;
      });
  }

  fetchAccountsVoucherType() {
    this.accountVoucherTypeService
      .allAccountVoucherTypeData()
      .subscribe(async (res: CommonResponse) => {
        this.loadingVoucherTypes = false;
        this.accountTypes = res.responseData;
      });
  }

  viewStatement(subAccount: Account) {
    if (this.selectedControlAccount && !subAccount) {
      this.router.navigate([
        `/home/account-statement/${this.selectedControlAccount.id}`,
      ]);
    } else if (this.selectedControlAccount && subAccount) {
      this.router.navigate([
        `/home/account-statement/${this.selectedControlAccount.id}-${subAccount.id}`,
      ]);
    }
  }

  getAbs(val) {
    return Math.abs(val);
  }

  showAccountInfo(event) {
    const eventData: AccountClass | ControlAccount = event.node.data;

    if (eventData) {
      if (this.instanceOfControlAccount(eventData)) {
        if (this.selectedControlAccount !== eventData) {
          this.selectedAccountClass = null;
          this.selectedControlAccount = eventData;
          this.LoadControlAccountSubAccounts();
          // this.viewSubAccounts();
        }
      } else if (this.instanceOfAccountClass(eventData)) {
        if (this.selectedAccountClass !== eventData) {
          this.selectedControlAccount = null;
          this.subAccounts = null;
          this.selectedAccountClass = eventData;
        }
      } else {
        console.log("Hmm.. invalid type in tree..");
      }
    }
  }

  // to be reviewed.
  instanceOfControlAccount(data: any): data is ControlAccount {
    return "alias" in data;
  }

  instanceOfAccountClass(data: any): data is AccountClass {
    return "accountClassAlias" in data;
  }

  disableControlAccount() {
    this.confirmationService.confirm({
      message:
        "Are you sure you want to Disable Control Account " +
        this.selectedControlAccount.caption +
        " ?",
      accept: () => {
        this._disableControlAccount();
      },
    });
  }

  _disableControlAccount() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Disabling Control Account",
    });

    const controlAccount = this.selectedControlAccount;
    const id = controlAccount.id;

    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Control Account Disable endpoint not available.",
    });

    const postData = {
      /*name: this.controlAccountDisplayForm.get('name').value,
            address: this.controlAccountDisplayForm.get('address').value,*/
      /*description: this.branchForm.get('description').value,
            headId: this.selectedHeadId.id,*/
    };

    /*this.controlAccountService.updateControlAccount(id, postData).subscribe( async (result: Branch) => {
            this.messageService.add({severity:'success', summary: 'Notice',
                detail:'Control Account Disabled'});
            /!*await this.fetchBranches();*!/
        })*/
  }

  LoadControlAccountSubAccounts() {
    if (this.selectedControlAccount) {
      if (!this.selectedControlAccount.accountClass)
        this.selectedControlAccount.accountClass = this.allAccountClasses.find(
          (x) => x.id == this.selectedControlAccount.accountClassId
        );
      if (this.selectedControlAccount.accounts) {
        this.subAccounts = this.selectedControlAccount.accounts;
        this.numberOfSubAccounts = this.subAccounts.length;
        this.numberOfActiveSubAccounts = this.subAccounts.filter(
          (x) => x.isActive
        ).length;
      } else {
        this.accountService
          .GetAllAccountByControlAccountId(this.selectedControlAccount.id)
          .subscribe(
            async (data) => {
              if (data.responseCode != "00") {
                this.messageService.add({
                  severity: "error",
                  summary: data.responseMsg,
                });
                return;
              }

              this.selectedControlAccount.accounts = data.responseData;
              this.subAccounts = this.selectedControlAccount.accounts;
              this.numberOfSubAccounts = this.subAccounts.length;
              this.numberOfActiveSubAccounts = this.subAccounts.filter(
                (x) => x.isActive
              ).length;
            },
            (error) => {
              console.log("Error: " + JSON.stringify(error));
              this.messageService.add({
                severity: "error",
                summary: "Notice",
                detail:
                  "Unable to fetch all control account sub accounts at the moment.. Reason: [" +
                  (error
                    ? error.error.message
                    : "request failed - permission") +
                  "]",
              });
            }
          );
      }
    } else {
      this.subAccounts = [];
      this.numberOfSubAccounts = 0;
      this.numberOfActiveSubAccounts = 0;
    }
  }

  viewSubAccounts() {
    const selectedControlAccount = this.selectedControlAccount;
    const controlAccount = this.allControlAccounts.find(
      (x) => x.id === selectedControlAccount.id
    );
    if (controlAccount && controlAccount.accounts) {
      this.subAccounts = controlAccount.accounts;
      this.numberOfSubAccounts = this.subAccounts.length;
      this.numberOfActiveSubAccounts = this.subAccounts.filter(
        (x) => x.isActive
      ).length;
    } else {
      this.subAccounts = [];
      this.numberOfSubAccounts = 0;
      this.numberOfActiveSubAccounts = 0;
    }
  }

  addSubAccount() {
    this.confirmationService.confirm({
      message:
        "Are you sure you want to create Account " +
        this.subAccountForm.get("name").value,
      accept: () => {
        this._addSubAccount();
      },
    });
  }

  _addSubAccount() {
    const _subAccountForm = this.subAccountForm;
    const postData = {
      name: _subAccountForm.get("name").value,
      description: _subAccountForm.get("description").value,
      alias: _subAccountForm.get("alias").value,
      isDebitBalance: this.getIsDebitBalance(this.selectedControlAccount),
      controlAccountId: this.selectedControlAccount.id,
      isActive: true,
    };
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Creating new Sub Account",
    });
    this.accountService
      .postAccount(postData)
      .subscribe(async (result: CommonResponse) => {
        if (result.responseCode == "00") {
          this.messageService.add({
            severity: "success",
            summary: "Request Complete",
            detail: "New sub account record added",
          });
          this.subAccountForm.reset();
          this.updateSubAccountsTable();
        }
      });
  }

  getIsDebitBalance(controlAccount: ControlAccount): boolean {
    const accountClass = this.allAccountClasses.find((x) =>
      x.controlAccounts.some((y) => y.id === controlAccount.id)
    );
    return (
      accountClass.caption === "Asset" || accountClass.caption === "Expense"
    );
  }

  updateSubAccountsTable() {
    this.controlAccountService
      .allControlAccounts()
      .subscribe(async (res: CommonResponse) => {
        this.loadingControlAccounts = false;
        this.allControlAccounts = res.responseData;
        if (this.selectedControlAccount) {
          const controlAccount = this.allControlAccounts.find(
            (x) => x.id === this.selectedControlAccount.id
          );
          this.subAccounts = controlAccount.accounts;
          this.numberOfSubAccounts = this.subAccounts.length;
          this.numberOfActiveSubAccounts = this.subAccounts.filter(
            (x) => x.isActive
          ).length;
        }
      });
  }

  addControlAccount() {
    this.confirmationService.confirm({
      message:
        "Are you sure you want to create Control Account " +
        this.controlAccountForm.get("name").value,
      accept: () => {
        this._addControlAccount();
      },
    });
  }

  _addControlAccount() {
    const _controlAccountForm = this.controlAccountForm;
    const postData = {
      caption: _controlAccountForm.get("name").value,
      description: _controlAccountForm.get("description").value,
      alias: _controlAccountForm.get("alias").value,
      accountClassId: this.selectedAccountClass.id,
    };
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Creating new Control Account",
    });
    this.controlAccountService
      .postControlAccount(postData)
      .subscribe(async (result: CommonResponse) => {
        if (result.responseCode == "00") {
          this.messageService.add({
            severity: "success",
            summary: "Request Complete",
            detail: "New control account record added",
          });
          this.controlAccountForm.reset();
          this.fetchAccountClassExtra();
          this.updateSubAccountsTable();
        }
      });
  }
}
