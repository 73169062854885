import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { CommonResponse } from "../interfaces/home";
import {
  CreateReversalOfficesReceivingDTO,
  ReversalApprovingOffice,
  UpdateReversalOfficesReceivingDTO,
} from "../interfaces/reversal-office";

@Injectable({
  providedIn: "root",
})
export class ReversalOfficeService {
  baseUrl = environment.baseUrl + "ReversalOffice/";

  constructor(private http: HttpClient) {}

  GetAllReversalOffices(): Observable<
    CommonResponse<ReversalApprovingOffice[]>
  > {
    return this.http.get<CommonResponse<ReversalApprovingOffice[]>>(
      this.baseUrl + "GetAllReversalOffices"
    );
  }

  CreateReversalOffices(
    data: CreateReversalOfficesReceivingDTO
  ): Observable<CommonResponse<boolean>> {
    return this.http.post<CommonResponse<boolean>>(
      this.baseUrl + "CreateReversalOffices",
      data
    );
  }

  UpdateReversalOffices(
    id: number,
    data: UpdateReversalOfficesReceivingDTO
  ): Observable<CommonResponse<boolean>> {
    return this.http.put<CommonResponse<boolean>>(
      this.baseUrl + "UpdateReversalOffices/" + id,
      data
    );
  }

  DeleteReversalOffices(id: number): Observable<CommonResponse<boolean>> {
    return this.http.delete<CommonResponse<boolean>>(
      this.baseUrl + "DeleteReversalOffices/" + id
    );
  }
}
