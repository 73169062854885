import {
  BeatType,
  CreateBeatType,
  UpdateBeatType,
} from "./../../../interfaces/employment";
import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { ConfirmationService, Message, MessageService } from "primeng/api";
import { BreadcrumbService } from "src/app/breadcrumb.service";
import { CommonResponse } from "src/app/interfaces/home";
import { FileStorageService } from "src/app/services/file-storage.service";
import { GmaEmploymentService } from "src/app/services/gma-employment.service";

@Component({
  selector: "app-beat-type-setup",
  templateUrl: "./beat-type-setup.component.html",
  styleUrls: ["./beat-type-setup.component.scss"],
  providers: [MessageService],
})
export class BeatTypeSetupComponent implements OnInit {
  @ViewChild("formWrapper") public formWrapper: ElementRef;
  createBeatTypeForm: FormGroup;
  uploadedImages: any[] = [];
  userIsYetToClickUpload: boolean;
  editing: boolean = false;
  fetching: boolean = true;
  allBeatTypeSetups: BeatType[];
  selectedBeatTypeSetup: BeatType;
  cols: any[];
  beatTypeToEdit: BeatType;
  msgs: Message[] = [];

  constructor(
    private fb: FormBuilder,
    public employmentService: GmaEmploymentService,
    private breadcrumbService: BreadcrumbService,
    public confirmationService: ConfirmationService,
    public messageService: MessageService,
    private fileStorageService: FileStorageService
  ) {
    this.createBeatTypeForm = fb.group({
      Caption: ["", Validators.required],
      Description: ["", Validators.required],
    });
  }

  ngOnInit(): void {
    this.breadcrumbService.setItems([
      {
        label: "Client Management",
        routerLink: ["/home/client-management"],
      },
      {
        label: "Beat Type Set-Up",
        routerLink: ["/home/client-management/beat-type-setup"],
      },
    ]);

    this.cols = [
      { field: "caption", header: "Caption" },
      { field: "description", header: "Description" },
    ];

    this.RunMessageDialogue();
    this.FetchAllBeatTypeSetup();
  }

  RunMessageDialogue() {
    this.msgs = [];
    this.msgs.push({
      severity: "info",
      summary: "Notice:",
      detail:
        "Upload a visual representative of what the beat type looks like.",
    });
  }

  FetchAllBeatTypeSetup() {
    this.employmentService.GetAllBeatTypes().subscribe(
      async (data) => {
        this.allBeatTypeSetups = data;
        this.fetching = false;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to get all beat types at the moment.. Reason: [" +
            error.error.message +
            "]",
        });
        this.RunMessageDialogue();
        this.fetching = false;
      }
    );
  }

  async CreateBeatTypeSetup() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Creating Beat Type Setup...",
    });
    this.RunMessageDialogue();

    const postData: CreateBeatType = {
      caption: this.createBeatTypeForm.get("Caption").value,
      description: this.createBeatTypeForm.get("Description").value,
      visualsUrl: [],
    };

    if (this.uploadedImages.length > 0) {
      this.fileStorageService.UploadMultipleFilesFromDataUrl(
        this.uploadedImages
      );
      this.fileStorageService.onUploadFinished.subscribe(
        (resp: CommonResponse<string[]>) => {
          if (resp.responseCode != "00") {
            this.messageService.add({
              severity: "error",
              summary: "Notice",
              detail: resp.responseMsg,
            });
          } else {
            postData.visualsUrl = resp.responseData;
            this._createBeatTypeSetup(postData);
          }
        },
        (error) => {
          console.log("Error while uploading files " + error);
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail: "ERR: Unable to upload image to storage",
          });
        }
      );
    } else {
      this.messageService.add({
        severity: "error",
        summary: "Error",
        detail: "You must upload at least one visual.",
      });
      this.RunMessageDialogue();
    }
  }

  _createBeatTypeSetup(postData: CreateBeatType) {
    this.employmentService.CreateBeatTypeSetup(postData).subscribe(
      async () => {
        await this.messageService.add({
          severity: "success",
          summary: "Completed",
          detail: "Beat Type Created Successfully...",
        });

        this.RunMessageDialogue();
        this.fetching = true;
        this.createBeatTypeForm.reset();
        this.FetchAllBeatTypeSetup();
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to create beat type at the moment.. Reason: [" +
            error.error.message +
            "]",
        });
        this.RunMessageDialogue();
      }
    );
  }

  onCancelUpload() {
    this.uploadedImages = [];
  }

  NotifyUserToClickUpload() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Kindly ensure you click upload.",
    });

    this.RunMessageDialogue();
    this.userIsYetToClickUpload = true;
  }

  OnUploadDoc(event, form) {
    this.uploadedImages = [];
    for (const file of event.files) {
      this.uploadedImages.push(file);
    }

    this.messageService.add({
      severity: "success",
      summary: "Success",
      detail: "Doc(s) Uploaded",
    });
    this.RunMessageDialogue();

    this.userIsYetToClickUpload = false;
    form.clear();
  }

  CloseEditing() {
    this.editing = false;
    this.uploadedImages = [];
    this.createBeatTypeForm.reset();
  }

  Delete(item: BeatType) {
    this.confirmationService.confirm({
      message: "Are you sure you want to remove beat type?",
      accept: () => {
        this.messageService.add({
          severity: "info",
          summary: "Notice",
          detail: "Removing beat type...",
        });
        this.RunMessageDialogue();

        this.employmentService.DeleteBeatType(item.id).subscribe(
          async () => {
            await this.messageService.add({
              severity: "success",
              summary: "Removed",
              detail: "Removed successfully",
            });
            this.RunMessageDialogue();

            const index = this.allBeatTypeSetups.indexOf(item);
            if (index > -1) {
              this.allBeatTypeSetups.splice(index, 1);
            }
            this.FetchAllBeatTypeSetup();
          },
          (error) => {
            console.log("Error: " + JSON.stringify(error));
            this.messageService.add({
              severity: "error",
              summary: "Notice",
              detail:
                "Unable to remove beat type at the moment.. Reason: [" +
                error.error.message +
                "]",
            });
            this.RunMessageDialogue();
          }
        );
      },
    });
  }

  Edit(item: BeatType) {
    this.editing = true;
    this.createBeatTypeForm.addControl(
      "ID",
      new FormControl({ value: "", disabled: true }, Validators.required)
    );
    this.beatTypeToEdit = item;
    this.createBeatTypeForm.patchValue({
      ID: item.id,
      Caption: item.caption,
      Description: item.description,
    });
    this.uploadedImages = [];

    this.formWrapper.nativeElement.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "start",
    });
  }

  async UpdateBeatType() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Updating Beat Type...",
    });
    this.RunMessageDialogue();
    const id = this.beatTypeToEdit.id;
    const postData: UpdateBeatType = {
      caption: this.createBeatTypeForm.get("Caption").value,
      description: this.createBeatTypeForm.get("Description").value,
      visualsUrl: [],
    };

    if (this.uploadedImages.length > 0) {
      this.fileStorageService.UploadMultipleFilesFromDataUrl(
        this.uploadedImages
      );
      this.fileStorageService.onUploadFinished.subscribe(
        (resp: CommonResponse<string[]>) => {
          if (resp.responseCode != "00") {
            this.messageService.add({
              severity: "error",
              summary: "Notice",
              detail: resp.responseMsg,
            });
          } else {
            postData.visualsUrl = resp.responseData;
            this._updateBeatType(id, postData);
          }
        },
        (error) => {
          console.log("Error while uploading files " + error);
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail: "ERR: Unable to upload image to storage",
          });
        }
      );
    } else {
      this._updateBeatType(id, postData);
    }
  }

  _updateBeatType(beatTypeId: number, postData: UpdateBeatType) {
    this.employmentService.UpdateBeatType(beatTypeId, postData).subscribe(
      async () => {
        this.messageService.add({
          severity: "success",
          summary: "Notice",
          detail: "Update Successful!",
        });
        this.RunMessageDialogue();
        this.fetching = true;
        await this.FetchAllBeatTypeSetup();
        await this.CloseEditing();
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to update beat type at the moment.. Reason: [" +
            error.error.message +
            "]",
        });
        this.RunMessageDialogue();
      }
    );
  }

  GetFirstImageUrl(imageUrls: string): string {
    let imgArr = imageUrls.split(",");
    return imgArr[0];
  }
}
