import { GmaEmploymentService } from "src/app/services/gma-employment.service";
import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import {
  MessageService,
  ConfirmationService,
  Message,
  TreeNode,
} from "primeng/api";
import { BreadcrumbService } from "src/app/breadcrumb.service";
import {
  ApplicantMaterialRequest,
  ApplicantMaterialRequestBatch,
  ApplicantMaterialRequestItem,
  DistributeApplicantItems,
} from "src/app/interfaces/employment";

@Component({
  selector: "app-gma-material-distribution",
  templateUrl: "./gma-material-distribution.component.html",
  styleUrls: ["./gma-material-distribution.component.scss"],
  providers: [MessageService],
})
export class GmaMaterialDistributionComponent implements OnInit {
  @ViewChild("formWrapper") public formWrapper: ElementRef;
  formDispatch: FormGroup;
  allMaterialReqBatch: ApplicantMaterialRequestBatch[];
  theMaterialReqBatch: ApplicantMaterialRequestBatch;
  msgs: Message[] = [];
  bacthReqInfosTree: TreeNode[];
  allSelectedBatchItems: ApplicantMaterialRequestItem[];
  selectedBatchItem: ApplicantMaterialRequestItem;
  cols: any[];
  ditsTable: {
    name: string;
    data: string;
    isDate?: boolean;
  }[];
  selectedDitTable: {
    name: string;
    data: string;
    isDate?: boolean;
  };
  showDits: boolean = false;
  selectedNodeData: ApplicantMaterialRequest;
  applicantInViewName: string = "";
  applicantInViewMobile: string = "";
  itemDispatching: ApplicantMaterialRequestItem;
  openDispatchDialogue: boolean = false;

  constructor(
    fb: FormBuilder,
    public employmentService: GmaEmploymentService,
    public messageService: MessageService,
    private breadcrumbService: BreadcrumbService,
    public confirmationService: ConfirmationService
  ) {
    this.formDispatch = fb.group({
      QtyDistributed: ["", Validators.required],
    });
  }

  ngOnInit(): void {
    this.breadcrumbService.setItems([
      {
        label: "Guard Management",
        routerLink: ["/home/guard-management/item-settings"],
      },
      {
        label: "Material Distribution",
        routerLink: ["/home/guard-management/gma-material-distribution"],
      },
    ]);

    this.ResetMessageToaster();

    this.bacthReqInfosTree = [
      {
        label: "Material Requests",
        key: "Halogen Folder",
        expandedIcon: "pi pi-minus-circle",
        collapsedIcon: "pi pi-plus-circle",
        expanded: true,
        children: [],
        data: 1001,
        selectable: false,
      },
    ];

    this.cols = [
      {
        field: "operativeItem.item.caption",
        header: "OperativeItem.item.caption",
      },
      { field: "qtyConfirmed", header: "QtyConfirmed" },
      { field: "qtyDispatched", header: "QtyDispatched" },
      { field: "operativeItem.item.code", header: "OperativeItem.item.code" },
      {
        field: "operativeItem.quantitiesToBeIssued",
        header: "OperativeItem.quantitiesToBeIssued",
      },
    ];

    this.ditsTable = [
      {
        name: "Material Request Batch",
        data: "Data",
      },
      {
        name: "Material Request Date",
        data: "Data",
      },
      {
        name: "Applicant",
        data: "Data",
      },
      {
        name: "No Of Requested Items",
        data: "Data",
      },
      {
        name: "No of Items Distributed",
        data: "Data",
      },
    ];

    this.FetchAllMaterialRequestBatch();
  }

  ResetMessageToaster() {
    this.msgs = [];
    this.msgs.push({
      severity: "info",
      summary: "Summary:",
      detail: "3 Applicant(s), 2 Distribution(s), 2 outstanding(s).",
    });
  }

  FetchAllMaterialRequestBatch() {
    this.employmentService.GetAllMaterialRequestBatches().subscribe(
      async (data) => {
        this.allMaterialReqBatch = data;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to get all material request batches at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
        this.ResetMessageToaster();
      }
    );
  }

  LoadBatchData() {
    this.msgs = [];
    this.msgs.push({
      severity: "info",
      summary: "Summary:",
      detail:
        this.theMaterialReqBatch.applicantRequests.length +
        " Applicant(s) request in the selected batch.",
    });

    const applicants = [];
    this.theMaterialReqBatch.applicantRequests.forEach((applicantReq) => {
      this.applicantInViewName =
        applicantReq.jobApplication.personalInformation.lastname +
        " " +
        applicantReq.jobApplication.personalInformation.firstname;
      this.applicantInViewMobile =
        applicantReq.jobApplication.personalInformation.mobile;
      const applicantMaterials = [];
      applicantReq.itemsRequested.forEach((applicantItem) => {
        applicantMaterials.push({
          label: "Item: " + applicantItem.operativeItem.item.caption,
          key: applicantItem.id,
          icon: "pi pi-fw pi-align-justify",
          data: applicantItem,
          selectable: false,
        });
      });

      applicants.push({
        label:
          "Applicant: " +
          applicantReq.jobApplication.personalInformation.lastname +
          " " +
          applicantReq.jobApplication.personalInformation.firstname,
        key: applicantReq.id,
        expandedIcon: "pi pi-minus-circle",
        collapsedIcon: "pi pi-plus-circle",
        expanded: true,
        data: applicantReq,
        children: applicantMaterials,
        selectable: true,
      });
    });

    this.bacthReqInfosTree[0].children = applicants;
  }

  async ShowDetails(event) {
    if (event.node.data) {
      if (this.selectedNodeData !== event.node.data) {
        this.selectedNodeData = event.node.data;
      }

      this.allSelectedBatchItems = this.selectedNodeData.itemsRequested;
      this.ditsTable[0].data = this.theMaterialReqBatch.batchCode;
      this.ditsTable[1].data = this.theMaterialReqBatch.createdAt + "";
      this.ditsTable[1].isDate = true;
      this.ditsTable[2].data =
        this.selectedNodeData.jobApplication.personalInformation.lastname +
        " " +
        this.selectedNodeData.jobApplication.personalInformation.firstname;
      this.ditsTable[3].data =
        this.theMaterialReqBatch.applicantRequests.length + "";
      let totalApplicantItemsDistributed = 0;
      this.selectedNodeData.itemsRequested.forEach(
        (x) => (totalApplicantItemsDistributed += x.qtyDispatched)
      );
      this.ditsTable[4].data = totalApplicantItemsDistributed + "";
      this.showDits = true;
      this.CanDispatchAll();
    }
  }

  DistributeItem(item: ApplicantMaterialRequestItem) {
    this.openDispatchDialogue = true;
    this.itemDispatching = item;
  }

  DistributeAllItems() {
    this.confirmationService.confirm({
      message:
        "This action will distribute all remaining applicant items and it's an irreversible action. Do you still want to proceed?",
      accept: () => {
        this.messageService.add({
          severity: "info",
          summary: "Notice",
          detail: "Distributing All Applicant Requested Items...",
        });
        this.ResetMessageToaster();

        this.employmentService
          .DistributeAllApplicantRequestItems(this.selectedNodeData.id)
          .subscribe(
            () => {
              this.messageService.add({
                severity: "success",
                summary: "Completed",
                detail: "Items Distributed Successfully...",
              });
              this.ResetMessageToaster();

              this.selectedNodeData = null;
              this.theMaterialReqBatch = null;
              this.showDits = false;
              this.FetchAllMaterialRequestBatch();
            },
            (error) => {
              console.log("Error: " + JSON.stringify(error));
              this.messageService.add({
                severity: "error",
                summary: "Notice",
                detail:
                  "Unable to distribute all items at the moment.. Reason: [" +
                  error
                    ? error.error.message
                    : "request failed - permission" + "]",
              });
              this.ResetMessageToaster();
            }
          );
      },
    });
  }

  DistributeApplicantItem() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Distributing Applicant Requested Items...",
    });
    this.ResetMessageToaster();

    const postData: DistributeApplicantItems = {
      applicantRequestItemId: this.itemDispatching.id,
      qtyDistributed: this.formDispatch.get("QtyDistributed").value,
      mobile: this.applicantInViewMobile,
    };

    this.employmentService.DistributeApplicantRequestItems(postData).subscribe(
      () => {
        this.messageService.add({
          severity: "success",
          summary: "Completed",
          detail: "Items Distributed Successfully...",
        });
        this.ResetMessageToaster();

        this.itemDispatching.qtyDispatched =
          this.itemDispatching.qtyDispatched + postData.qtyDistributed;
        if (
          this.itemDispatching.operativeItem.quantitiesToBeIssued ==
          this.itemDispatching.qtyDispatched
        )
          this.itemDispatching.isFullyDispatched = true;
        let totalQtyDistributed = +this.ditsTable[4].data;
        this.ditsTable[4].data =
          totalQtyDistributed + postData.qtyDistributed + "";
        this.itemDispatching = null;
        this.openDispatchDialogue = false;
        this.FetchAllMaterialRequestBatch();
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to distribute item(s) at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
        this.ResetMessageToaster();
      }
    );
  }

  HideDispatchDialog() {
    this.formDispatch.reset();
    this.openDispatchDialogue = false;
    this.itemDispatching = null;
  }

  CanDispatchAll(): boolean {
    if (this.selectedNodeData) {
      let itemNotFullyDispatched = this.selectedNodeData.itemsRequested.find(
        (x) => x.isFullyDispatched == false
      );
      if (itemNotFullyDispatched == null) return false;
      else return true;
    }
    return false;
  }
}
