import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {LeadService} from '../../../../services/lead.service';
import {ClientContactQualification, CommonResponse, Constant, Designation, Lead, LeadContact} from '../../../../interfaces/home';
import {LeadContactService} from '../../../../services/lead-contact.service';
import {ConfirmationService, MessageService} from 'primeng/api';
import { DesignationService } from 'src/app/services/designation.service';
import { ClientContactQualificationService } from 'src/app/services/client-contact-qualification.service';

@Component({
  selector: 'app-primary-contact',
  templateUrl: './primary-contact.component.html',
  styleUrls: ['./primary-contact.component.scss'],
  providers: [Constant]
})
export class PrimaryContactComponent implements OnInit {
  @Input() suggestedPrimaryContact: LeadContact | null;
  leadPrimaryContactForm: FormGroup;
  CurrentLeadData: Lead = null;
  editingContact: boolean;
  titles : any[] = [];
  selectedTitle : any = null;
  genders : any[] = [];
  selectedGender : any = null;
  public yearRange: string;
  maxDateOfBirth: Date;
  designations: Designation[] = [];
  selectedDesignation: Designation = null;
  clientContactQualifications: ClientContactQualification[] = [];
  selectedClientContactQualification: ClientContactQualification = null;
  constructor(
      private leadService: LeadService,
      private formBuilder: FormBuilder,
      private leadContactService: LeadContactService,
      private clientContactQualificationService: ClientContactQualificationService,
      private designationService: DesignationService,
      private messageService: MessageService,
      private confirmationService: ConfirmationService,
      private constants: Constant
  ) { }
  ngOnInit(): void {
    this.fetchDesignations();
    this.fetchContactQualifications();
    this.editingContact = false;
    this.titles = this.constants.titles;
    this.genders = this.constants.genders;
    this.maxDateOfBirth = new Date();
    const year = this.maxDateOfBirth.getFullYear() -18;
    this.maxDateOfBirth.setFullYear(year);
    this.yearRange = (year - 100) + ':' + year;
    this.leadPrimaryContactForm = this.formBuilder.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      mobileNumber: ['', Validators.required],
      designation: [null, Validators.required],
      contactQualification: [null, Validators.required],
      dateOfBirth: [''],
      title: ['',  Validators.required],
      gender: ['',  Validators.required],
      type: 0,
    });
    this.leadPrimaryContactForm.disable();
    this.leadService.activeLead().subscribe( async (res: any) => {
      if(res){
        this.CurrentLeadData = res;
        await this.leadPrimaryContactForm.enable();
        const _primaryContact = res.primaryContact;
        if(_primaryContact){
          this.leadPrimaryContactForm.disable();
          this.leadPrimaryContactForm.patchValue({
            firstName: _primaryContact.firstName,
            lastName: _primaryContact.lastName,
            email: _primaryContact.email,
            mobileNumber: _primaryContact.mobileNumber,
            dateOfBirth: _primaryContact.dateOfBirth,
          });
          this.selectedTitle = this.titles.find(title => title.value.toLowerCase() === _primaryContact.title.toLowerCase());
          this.selectedGender = this.genders.find(gender => gender.value.toLowerCase() === _primaryContact.gender.toLowerCase());
          this.selectedDesignation = this.designations.find(x => x.id === _primaryContact.designationId);
          this.selectedClientContactQualification = this.clientContactQualifications
            .find(x => x.id === _primaryContact.clientContactQualificationId);
        }
      }
    });
  }

  async fetchDesignations() {
    await this.designationService.allDesignationData().subscribe(async (res: any) => {
        this.designations = res.responseData;
    }, error => this.connectionError());  
  }

  async fetchContactQualifications() {
    await this.clientContactQualificationService.allClientContactQualificationData().subscribe(async (res: CommonResponse) => {
        this.clientContactQualifications = res.responseData;
    }, error => this.connectionError());  
  }

  fillSuggestedContact() {
    if(!this.suggestedPrimaryContact){
      this.messageService.add({
        severity: 'error',
        detail: 'Resources not loaded',
        summary: 'Notice'
      })
      return;
    }
    this.leadPrimaryContactForm.patchValue({
      firstName: this.suggestedPrimaryContact.firstName,
      lastName: this.suggestedPrimaryContact.lastName,
      email: this.suggestedPrimaryContact.email,
      mobileNumber: this.suggestedPrimaryContact.mobileNumber,
      dateOfBirth: this.suggestedPrimaryContact.dateOfBirth,
      type: 0,
    })
    this.selectedTitle = this.titles.find(title => title.value.toLowerCase() === this.suggestedPrimaryContact.title.toLowerCase());
    this.selectedGender = this.genders.find(gender => gender.value.toLowerCase() === this.suggestedPrimaryContact.gender.toLowerCase());
    this.selectedDesignation = this.designations.find(x => x.id === this.suggestedPrimaryContact.designation.id);
    this.selectedClientContactQualification = this.clientContactQualifications
            .find(x => x.id === this.suggestedPrimaryContact.clientContactQualificationId);
  }
  createContact() {
    this.messageService.add({severity:'info', summary: 'Notice',
      detail:'Processing Primary Contact Creation'});
    const postData= this.leadPrimaryContactForm.value;
    postData.gender = this.leadPrimaryContactForm.value.gender.value;
    postData.title = this.leadPrimaryContactForm.value.title.value;
    postData.designationId = this.selectedDesignation.id;
    postData.clientContactQualificationId = this.selectedClientContactQualification.id;
    if(this.leadPrimaryContactForm.valid) {
      this.leadContactService.postLeadContact(this.CurrentLeadData.id, postData).subscribe( (res: any) => {
        this.messageService.add({severity:'success', summary: 'Notice',
          detail:'Primary contact Submitted'});
        this.leadService.refreshData();
      } ) ;
    }
  }

  updateContact() {
    this.messageService.add({severity:'info', summary: 'Notice',
      detail:'Processing Primary Contact Update'});
    const postData= this.leadPrimaryContactForm.value;
    postData.gender = this.leadPrimaryContactForm.value.gender.value;
    postData.title = this.leadPrimaryContactForm.value.title.value;
    postData.designationId = this.selectedDesignation.id;
    postData.clientContactQualificationId = this.selectedClientContactQualification.id;
    if(this.leadPrimaryContactForm.valid) {
      this.leadContactService.updateLeadContact(this.CurrentLeadData.id, this.CurrentLeadData.primaryContact.id, postData).subscribe( (res: any) => {
        this.messageService.add({severity:'success', summary: 'Notice',
          detail:'Primary contact Submitted'});
          this.cancelEditContact();
          this.leadService.refreshData();    
      } ) ;
    }
  }

  cancelEditContact() {
    console.log('In primary cancel edit contact')
    this.leadPrimaryContactForm.disable();
    this.editingContact = false;
    const _primaryContact = this.CurrentLeadData.primaryContact;
    if(_primaryContact){
      this.leadPrimaryContactForm.disable();
      this.leadPrimaryContactForm.patchValue({
        firstName: _primaryContact.firstName,
        lastName: _primaryContact.lastName,
        email: _primaryContact.email,
        mobileNumber: _primaryContact.mobileNumber,
        dateOfBirth: _primaryContact.dateOfBirth,
        type: 0
      });
      this.selectedTitle = this.titles.find(title => title.value.toLowerCase() === _primaryContact.title.toLowerCase());
      this.selectedGender = this.genders.find(gender => gender.value.toLowerCase() === _primaryContact.gender.toLowerCase());
      this.selectedDesignation = this.designations.find(x => x.id === _primaryContact.designationId);
      this.selectedClientContactQualification = this.clientContactQualifications
        .find(x => x.id === _primaryContact.clientContactQualificationId);
    }
  }

  startEditing(){
    this.leadPrimaryContactForm.enable();
    this.editingContact = true;
  }

  private connectionError() {
    this.messageService.add({
        severity: 'error', summary: 'Failed',
        detail: 'Connection Error, Please try again'
    });
  }
}
