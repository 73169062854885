import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import {environment} from '../../../../environments/environment';
import {Report} from '../../../interfaces/home';

@Component({
    selector: 'app-ssrs-component',
    template: '<iframe id="reportRenderFrame" seamless scrolling="no" width="100%" height="800px" [src]="reportUrl"></iframe>'
})
export class ReportingComponent implements OnInit {

    public reportUrl: SafeResourceUrl;
    @Input() report: Report;

    constructor(private domSanitizer: DomSanitizer) { }

    ngOnInit() {
        this.reportUrl = this.generateReportURLString(this.report);
    }

    ngOnChanges(changes: SimpleChanges) {
        this.reportUrl = this.generateReportURLString(changes.report.currentValue);
    }

    private generateReportURLString(report: Report): SafeResourceUrl {
        let reportServerUrl = environment.reportViewerHost + report.externalLocation;
        return this.domSanitizer.bypassSecurityTrustResourceUrl(reportServerUrl);
    }
}
