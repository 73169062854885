import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService, Message, MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { Subscription } from 'rxjs';
import { BudgetapiservicesService } from 'src/app/services/budget/budgetapiservices.service';
import { ConfirmEventType } from 'src/app/interfaces/armada';

interface Month {
  code: number,
  name: string,
  year: number
}

interface AllocationType {
  id: number,
  name: string
}

@Component({
  selector: 'app-month',
  templateUrl: './month.component.html',
  styleUrls: ['./month.component.scss']
})
export class MonthComponent implements OnInit, OnDestroy {
  msgs: Message[] | undefined;
  evenlyDistributeMsgs: Message[] | undefined;
  budgetDistributionForm!: FormGroup;
  budgetCycleForm = this.fb.group({
    budgetCycle: [''],
    percentageValue: [{value: '', disabled: false}, Validators.required],
    valueToResolve: [{value: '', disabled: false}, Validators.required],
    region: ['', Validators.required],
    allocationType: ['', Validators.required],
    distributionSetting: ['', Validators.required],
    totalValue: ['', Validators.required],
    comment: ['']
  })
  
  distributionChoice: any[] = [
    { label: 'Evenly Distribute', value: 'even'},
    { label: 'Manually Enter', value: 'man'}
  ];
  loadingData = true;
  budgetCycleLists: any[]=[];
  budgetByMonths: any[];

  monthName ="";
  allocationType: any;
  percentageValue = "";
  isPercentage: boolean | undefined;
  resolvedValue: number;
  totalValue: number;
  benchMarkValue: number;
  calculatedTotalValue: number;
  choice: boolean=true;
  findSum = 0;
  disableSendForReview: boolean = true;
  disableSaveButton: boolean = false;
  disableSelectAllocationType = false;
  disableReloadButton: boolean = true;
  disableEvenlyDistribute: boolean = false;
  isNewRecord: boolean = false;
  isExistingRecord: boolean = false;
  isEvenlyDistributeOnly:boolean = false;
  budgetCycleTotalNumber: number;
  budgetCycleTotalNumberCurrentYear: number;
  totalAllocatatedValue: number;
  totalUnallocatatedValue: number;
  isClearAll: boolean = false;
  itemComment = '';
  isRecordSentForReview: boolean = false;
  isRecordApproved: boolean = false;
  isRecordReturned: boolean = false;
  returnedBy: string = "";
  returnedComment: string = "";
  returnedBudgetDetail: any[];
  isEditingRecord: boolean = false;


  months: Month[]=[];
  selectedMonth: Month[]
  budgetDist: any[]=[];
  selectedBudget: any;

  allocationTypes: AllocationType[]
  selectedAllocationType: AllocationType

  budgetCycleSubscription: Subscription | undefined
  allocationTypeSubscription: Subscription | undefined

  constructor(private fb: FormBuilder, 
              private apiService: BudgetapiservicesService,
              private messageService: MessageService,
              private confirmationService: ConfirmationService,
              public dialogService: DialogService,
              private router: Router,
              private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.budgetCycleSubscription =this.loadBudgetCycles();
    
    this.allocationTypeSubscription = this.loadBudgetAllocationTypes();
    this.evenlyDistributeMsgs = [{
      severity: 'info',
      summary: 'Information',
      detail: 'Where evenly distribute applies and manually entered by percentage, it is based on benchmark value of budget cycle'
    }];
    
  }

  
  ngOnDestroy(): void {
    this.budgetCycleSubscription.unsubscribe();
    this.allocationTypeSubscription.unsubscribe();
  }
 

  displayMessage(severity, summary, message) {
    this.messageService.add({
      key: 'sideNotification',
      severity: severity,
      summary: summary,
      detail: message,
    });
  }


  loadBudgetAllocationTypes() {
    return this.apiService.getBudgetAllocationTypes().subscribe( res => {
      this.allocationTypes = res['responseData'].allocationTypes;
      console.log(`Regions: ${this.allocationTypes}`);
    }) 
  }


  loadBudgetCycles() {
    
    return this.apiService.getBudgetCycleList().subscribe( res => {
      this.budgetCycleLists = res['responseData'];
      console.log(this.budgetCycleLists)
      this.budgetCycleTotalNumber = this.budgetCycleLists.length;
      this.budgetCycleTotalNumberCurrentYear = this.budgetCycleLists.filter(e=> Number(e.budgetYear)== new Date().getFullYear()).length
      this.budgetCycleLists.forEach((elem, ind) => {
        if (new Date(elem.startDate).valueOf() > new Date(elem.endDate).valueOf()){
          this.budgetCycleLists.splice(ind, 1); // (elem)
        }
      })
      this.loadingData = false;
      console.log(res['responseData']);
      // this.budgetCycleLists.forEach((e) => {console.log(e.budgetYear)})
      // console.log(`Month Component: ${this.budgetCycleLists.forEach((e) => e.budgetYear)}`)
    }) 
  }
  onSelectedBudgetCycleChange(e: any) {
    let today = new Date();
    if (today.valueOf() < new Date(e.budgetSeason.startDate).valueOf())
    {
      console.log("Season has not started");
      this.displayMessage("info", "Information", "The budget preparatory start date has not commenced.")
      return;
    }
    else if (today.valueOf() > new Date(e.endDate).valueOf())
    {
      console.log("Season has closed");
      this.displayMessage("info", "Information", "The budget preparatory end date has elapsed.")
      return;
    }
    else
    {
      if (this.budgetCycleForm.get('budgetCycle').value['benchMarkValue']) {
        this.benchMarkValue = this.budgetCycleForm.get('budgetCycle').value['benchMarkValue']
        console.log(this.benchMarkValue)
        console.log(e);
        console.log(e.startDate);
        console.log(e.endDate);
        let d1 = new Date(e.startDate);
        let d2 = new Date(e.endDate);
        console.log(d1.setHours(d1.getHours()+1))
        console.log(d1)
        var months;
        months = (d2.getFullYear() - d1.getFullYear()) * 12;
        months -= d1.getMonth();
        months += d2.getMonth();
        const NAMES_OF_MONTH = ["January", "February", "March", "April", "May", "June",
          "July", "August", "September", "October", "November", "December"
        ];
        console.log(months <= 0 ? 0 : months);
        for (let ind= d1; ind < d2; ind.setMonth(ind.getMonth()+ 1)) {
          console.log(ind.getFullYear(), NAMES_OF_MONTH[ind.getMonth()]);
          
          this.months.push({
            code: ind.getMonth()+1, 
            name: NAMES_OF_MONTH[ind.getMonth()].toString(),
            year: ind.getFullYear()
          });
        };
        console.log(this.months);
        this.isPercentage = undefined;
        this.loadMonthlyBudget();
        this.disableReloadButton = false;
        // this.months=[]
      }
    }
    
  }

  onSelectedAllocationChange(e: any) {
    if (this.isEvenlyDistributeOnly){
      this.displayMessage("warn", "Warning", "You can only distribute evenly")
      return false;
    }
    console.log(e)
    if (e !=='' || e !== undefined) {
      this.isPercentage = e.id === 1 ? true : false;
      console.log(this.isPercentage)
    }
    else {
      this.isPercentage = undefined;
      console.log(this.isPercentage)
    }
    // this.budgetByMonths.forEach(el => {
    //   if (this.budgetByMonths.indexOf(item))
    // })
    // console.log(e[1].totalValue)
    // console.log(typeof(e[1].totalValue))

    // if (e[1].totalValue !==0 && e[1].isAlreadySaved == true )
    //   {
    //     this.displayMessage("warn","Warning","You can't edit this record as it has already been save")
    //     return false;
    //   }
    // if (e[0].id == 1) {
      
    //   e[1].isPercentage=true;
    //   e[1].allocationName = 'Percentage';
    //   e[1].allocationTypeId = 1
    //   // e[1].percentageOfBenchMarkValue = undefined
    //   // this.budgetCycleForm.get('resolvedValue').enable();
    //   // document.querySelector('#resolvedValue').setAttribute('disabled', '');
    //   // document.querySelector('#percentageValue').removeAttribute('disabled')
    // }
    // else if (e[0].id ==2)
    // {
    //   e[1].isPercentage=false;
    //   e[1].allocationName = 'Manual';
    //   e[1].allocationTypeId = 2
    //   e[1].resolvedValue=0;
      // this.budgetCycleForm.get('percentageValue').disable()
      // document.querySelector('#percentageValue').setAttribute('disabled', '');
      // document.querySelector('#resolvedValue').removeAttribute('disabled')
    // }
    
    // console.log(this.budgetCycleForm.get('allocationType').value)
    // console.log(e)
    
    // console.log(e[1])
  }

  onSettingsChange(e: any) {
    console.log(this.budgetByMonths.length);
    if (e.checked){
      console.log(this.choice);
      if (this.isEvenlyDistributeOnly) {
          this.displayMessage("warn", "Warning", "You can only distribute evenly")
          return false;
      }
      this.disableSelectAllocationType = false;
      
      this.calculatedTotalValue = 0;

      
      this.budgetByMonths.forEach(element => {
        element.percentageOfBenchMarkValue = 0;
          element.resolvedValue = '';
          // element.allocationName = null
          this.resolvedValue = 0;
          element.totalValue = 0;
          element.isPercentage = undefined;
          // element.allocationType = undefined;
          this.allocationType = '';
          this.budgetCycleForm.get('allocationType').setValue('');
          this.budgetCycleForm.get('percentageValue').setValue('');
          this.budgetCycleForm.get('valueToResolve').setValue('');
          this.budgetCycleForm.get('totalValue').setValue('');
          this.isPercentage = undefined;
          this.calculatedTotalValue = 0;
        // if (element.isAlreadySaved  && !this.isClearAll) {
        //   // this.budgetCycleForm.get('allocationType').setValue(e.allocationType);
        //   this.budgetCycleForm.get('percentageValue').setValue(element.percentageOfBenchMarkValue);
        //   this.budgetCycleForm.get('valueToResolve').setValue(element.resolvedValue);
        //   this.budgetCycleForm.get('totalValue').setValue(element.totalValue);
        //   this.calculatedTotalValue += element.totalValue;
        //   // element.percentageOfBenchMarkValue = 0;
        //   // element.resolvedValue = '';
        // }
        // else {
        //   element.percentageOfBenchMarkValue = 0;
        //   element.resolvedValue = '';
        //   // element.allocationName = null
        //   this.resolvedValue = 0;
        //   element.totalValue = 0;
        //   element.isPercentage = undefined;
        //   // element.allocationType = undefined;
        //   this.allocationType = '';
        //   this.budgetCycleForm.get('allocationType').setValue('');
        //   this.budgetCycleForm.get('percentageValue').setValue('');
        //   this.budgetCycleForm.get('valueToResolve').setValue('');
        //   this.budgetCycleForm.get('totalValue').setValue('');
        //   this.isPercentage = undefined;
        //   this.calculatedTotalValue = 0;
        // }         
      });
      return false;
    }
    else {
      this.disableSelectAllocationType = true;
      this.calculatedTotalValue = 0;
      this.evenlyDistributeBudget();
      this.allocationType=="Percentage";
      this.budgetCycleForm.get('allocationType').setValue('Percentage');
      // this.budgetCycleForm.get('percentageValue').setValue('');
      this.budgetByMonths.forEach(element => {
        element.isPercentage = true; 
        element.allocationName = 'Percentage'
        element.allocationType = element.allocationName=='Percentage' ? 1 : 2
      });
      console.log(this.choice);
    }
    // this.budgetCycleForm.get('allocationType').setValue(this.allocationType[0]);
    // this.allocationType = this.allocationTypes[0]
  }


  evenlyDistributeBudget() {
    let regions: number = this.budgetByMonths.length;
    
    const PERCENTAGE = 100;
    let amountPerRegion: number = this.benchMarkValue / regions;
    this.budgetByMonths.forEach(element => {
      element.percentageOfBenchMarkValue = PERCENTAGE / regions;
      element.resolvedValue = amountPerRegion;
      element.totalValue = element.resolvedValue
      element.budgetCycleId = this.selectedBudget.id,
      element.allocationTypeId = 1;
      this.resolvedValue = amountPerRegion;
      this.budgetCycleForm.get('percentageValue').setValue(element.percentageOfBenchMarkValue);
      this.budgetCycleForm.get('valueToResolve').setValue(element.resolvedValue);
      this.budgetCycleForm.get('totalValue').setValue(element.totalValue);
      this.calculatedTotalValue += element.totalValue;
    });

  }

  // resetControls(){ 
  //   this.budgetByRegions.forEach(element => {
  //     element.percentageOfBenchMarkValue = 100/regions;
  //     element.resolvedValue = amountPerRegion;
  //     this.resolvedValue = amountPerRegion;
  //   });
  // }


  loadMonthlyBudget() {
    this.loadingData = true;
    console.log(`Disable Allocation Button ${this.disableSelectAllocationType}`)
    if (this.budgetByMonths)
      this.budgetByMonths=[];
    this.calculatedTotalValue = 0
    this.isRecordSentForReview = false;
    this.isRecordApproved = false;
    this.isRecordReturned = false;
    this.isEditingRecord = false;
    this.isExistingRecord = false;
    this.disableSaveButton = false;
    this.disableSelectAllocationType = false;
    this.disableEvenlyDistribute = false;

    let params = {
      "period": this.months,
      "whereTo": "Month",
      "budgetCycleId": this.selectedBudget.id
    }
    // this.budgetDist.push({'Period': this.months});
    // this.budgetDist.push({"WhereTo":"Month"});
    // this.budgetDist.push({"budgetCycleId": this.selectedBudget.id});
    // console.log(this.selectedMonths);
    console.log(this.budgetDist);
    setTimeout(() => {
      this.displayMessage('info', "Information", "Processing request");
    }, 1000);
    // return false
    this.apiService.getBudgetDistribution(params).subscribe((res) => {
      var data = res['responseData'];
      this.budgetByMonths = data;
      this.disableSendForReview = true;

      this.budgetByMonths.forEach((e) => {
        e.percentageValue = 0
        e.isAlreadySaved = false;


        // if (e.resolvedValue!==0) {
        //   this.disableEvenlyDistribute = true;
        //   e.isAlreadySaved = true;
        // }

        if (e.allocationName && e.totalValue != 0) {
          this.budgetCycleForm.get('allocationType').setValue(e.allocationName);
          e.isAlreadySaved = true;
          this.isExistingRecord = true;
          this.isRecordSentForReview = e.isSentForReview;
          this.isRecordApproved = e.isApproved;
          this.isRecordReturned = e.isReturned;
          this.returnedBudgetDetail = e.returnedBudget;
          this.disableSendForReview = e.isSentForReview;
          this.calculatedTotalValue +=e.totalValue;
        }
        else {
          this.isNewRecord = true;
          // this.isExistingRecord = true;
          // this.isRecordSentForReview = e.isSentForReview;
          // this.disableSendForReview = e.isSentForReview;
          // this.calculatedTotalValue +=e.totalValue;
        }
        
      });
      this.loadingData = false;
      this.months=[];
      console.log(data)
      console.log(this.choice);
      this.budgetDist = [];
      setTimeout(() => {
        this.displayMessage('success', "Information", "Record loaded successfully");
      }, 1000);

      if (this.isRecordApproved){
        console.log('Record has been fully approved')            
        this.msgs = [{
          severity: 'success',
          summary: 'Information',
          detail: 'Record has been fully approved',
        }]
      }
      else if (this.isRecordSentForReview){
        console.log('Record has been sent for review')            
        this.msgs = [{
          severity: 'info',
          summary: 'Information',
          detail: 'Record has been sent for review',
        }]
      }
      else if (this.isRecordReturned) {
        console.log(`Record returned:`)
        console.log(this.returnedBudgetDetail)            
        this.msgs = [{
          severity: 'error',
          summary: `Returned By ${this.returnedBudgetDetail['returnedByName']} from ${this.returnedBudgetDetail['department']} office: ${this.returnedBudgetDetail['officeDetail']} `,
          detail: this.returnedBudgetDetail['comment'],
        }]
      }
      else {
        this.msgs=[];
      }
      
      if (this.isExistingRecord){
        console.log(`Existing Record inside ${this.isExistingRecord}`)
        this.disableEvenlyDistribute = true;
        this.disableSelectAllocationType = true;
        this.disableSaveButton = true;
      }
    },
    (error) => {
      console.log("Error: " + JSON.stringify(error));
      this.displayMessage("error","Error","Error loading record");
      this.loadingData = false;
    });
  }

  calculatePercentage(e: any) {
    if (this.choice !== false) {
        if (!this.isClearAll)
        {
          if(this.isPercentage == undefined)
          {
            this.displayMessage("warn", "Warning", "Select allocation type");
            return false;
          }
          if (this.isPercentage === false) {
            this.displayMessage("warn", "Warning", "You can only enter value");
            return false;
          }
          console.log(e)
          console.log(this.benchMarkValue);
          // e[1].percentageValue= e[0];
          // e[1].totalValue=Number(e[0]);
          let percentageTotal=0;
          let valueDist = 0;
    
          if (this.isEvenlyDistributeOnly){
            this.displayMessage("warn", "Warning", "You can only distribute evenly")
            this.budgetCycleForm.controls['docType'].disable();
            return false;
          }
    
          this.budgetByMonths.forEach(elem => {
            if (elem.percentageOfBenchMarkValue) 
            {
              percentageTotal += elem.percentageOfBenchMarkValue;
            }
          })
    
          console.log(e)
          console.log(this.benchMarkValue);
          e[1].percentageValue= e[0];
    
    
          this.budgetByMonths.forEach(elem => {
            // if (elem.resolvedValue === 0 || elem.resolvedValue === '' || elem.resolvedValue== undefined || isNaN(elem.resolvedValue)) {
              console.log(elem.percentageValue)
              elem.resolvedValue = elem.percentageValue/100 *  this.benchMarkValue;
              elem.totalValue = elem.resolvedValue;
              this.resolvedValue = elem.resolvedValue;
              percentageTotal += elem.percentageValue;
              valueDist += elem.totalValue;
              
            // }
            // else {
            //   percentageTotal += elem.percentageOfBenchMarkValue;
            // }
            console.log(percentageTotal)
            if (percentageTotal > 100) {
              console.log('Percent total cannot be greater than 100');
              this.displayMessage("error","Error","Percent total cannot be greater than 100");
              elem.resolvedValue = 0;
              elem.totalValue = 0;
              e[1].percentageValue = 0
              e[0] = ''
              e[0] = 0
              this.percentageValue = ''
              
              return false;
            }
            this.calculatedTotalValue = valueDist;
          })
        }
    }
    
  }
  calculateManually(e: any) {
    if (!this.isClearAll)
    {
      if (this.choice) {
        if(this.isPercentage == undefined)
        {
          this.displayMessage("warn", "Warning", "Select allocation type");
          return false;
        }
        if (this.isPercentage && this.isEvenlyDistributeOnly === false) {
          this.displayMessage("warn", "Warning", "You can only enter percentage");
          return false;
        }
        console.log(e)
        e[1].totalValue=Number(e[0]);
        
        console.log(this.budgetByMonths.length)
        let valueDist = 0;
        
        this.budgetByMonths.forEach(elem => {
          if (elem.percentageValue === 0) {
            console.log(elem.percentageValue)
            valueDist += elem.totalValue;
            console.log(valueDist)
          }
        });
        let totalPercent = 0;
        
        this.budgetByMonths.forEach(e => {
          if (e.percentageValue === 0) {
            // if (totalPercent >= 100) {
            //   this.displayMessage("error", "Error", "Cannot allocatate value as this has been fully allocated.")
            //   return false;
            // }
            e.percentageOfBenchMarkValue = e.totalValue / valueDist * 100;
          }
        })
        this.calculatedTotalValue = valueDist;
      }
      else {
        if (this.isEvenlyDistributeOnly){
          this.displayMessage("warn", "Warning", "You can only distribute evenly")
          return false;
        } 
      }
    }

  }

  saveAll(){
    
    this.confirmationService.confirm({
      message: !this.isEditingRecord ? `Proceed with saving?` : `Proceed with updating?`,
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.loadingData = true;
        let proceedWIthSave: boolean = true
        let budgetDistDetails: any[]=[];
        
        if (!this.isEvenlyDistributeOnly)
          {
            
            this.budgetByMonths.forEach( (e) => {
              if ((e.totalValue == 0 || e.totalValue ==undefined) &&
                (e.resolvedValue == 0 || e.resolvedValue == undefined)
              )
              {
                proceedWIthSave = false;
                return false;
              }
              
              e.resolvedValue = e.totalValue; // this is a work around
              e.budgetCycleId = this.selectedBudget.id,
              e.allocationTypeId = this.isPercentage == true ? 1 : 2;
              e.allocationName = this.isPercentage == true ? "Percent" : "Manual"; 
              e.percentageOfBenchMarkValue =  this.isPercentage == true ? e.percentageValue : e.percentageOfBenchMarkValue;
            });
            
            if (!proceedWIthSave) {
              this.displayMessage("error","Error","Fill all entry")
              this.loadingData = false;
              // proceedWIthSave = false;
              return false;
            }
          }

        console.log(this.budgetByMonths)
        budgetDistDetails.push({"BudgetDistibutions": this.budgetByMonths});
        budgetDistDetails.push({'WhereTo' : "Month"})
        budgetDistDetails.push({"budgetCycleId": this.selectedBudget.id});
        console.log(budgetDistDetails);
        setTimeout(() => {
          this.displayMessage('info', "Information", "Processing request ...");
        }, 1000);
        this.loadingData = true;
        this.apiService.saveBudgetDistributions(budgetDistDetails).subscribe((res) => {
          var data = res['responseData'];
          if (data == null) {
            this.displayMessage("error", "Error", "Record not saved");
            this.loadingData = false;
            return false;
          }
          setTimeout(() => {
            if (this.isEditingRecord){
              this.displayMessage('success', "Information", "Record updated successfully");
              this.isEditingRecord = false;
            }
            else{
              this.displayMessage('success', "Information", "Record saved successfully");
            }
            
            // this.reloadPage();
            
          }, 1000);
          this.disableSendForReview = false;
            this.disableSaveButton = true;
            this.disableEvenlyDistribute = true;
            this.disableSelectAllocationType = true;
            this.resolvedValue = undefined;
            // this.budgetCycleForm.reset();
            this.budgetCycleForm.get('allocationType').setValue('');
            this.budgetCycleForm.get('percentageValue').setValue('');
            this.budgetCycleForm.get('valueToResolve').setValue('');
            this.budgetCycleForm.get('comment').setValue('');
            this.loadingData = false;
            this.budgetByMonths.forEach( (e) => {
              e.isAlreadySaved = true;
            })
            this.budgetByMonths = undefined;
            this.selectedBudget = undefined;
            this.budgetCycleForm.get('budgetCycle').setValue('');
            this.calculatedTotalValue = undefined;
        }, 
        (err)=> {
          setTimeout(() => {
            this.displayMessage('error', "Error", "Unable to connect to server");
            this.loadingData = false;
          }, 1000);
        });
        // this.loadingData = false;
      },
      reject: (type: ConfirmEventType) => {
          switch (type) {
              case ConfirmEventType.REJECT:
                  this.displayMessage("warn","Cancelled","You have cancelled this operation");
                  break;
              case ConfirmEventType.CANCEL:
                  this.displayMessage("warn","Cancelled","You have cancelled this operation");
                  break;
          }
        }
    });
  }

  clearAll() {
    this.confirmationService.confirm({
      message: `Proceed with clearing all records?`,
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        // this.messageService.add({ severity: 'info', summary: 'Confirmed', detail: 'You have accepted' });
        this.displayMessage("info", "Information", "Processing operation")
        this.clearForm();
        
      },
      reject: (type: ConfirmEventType) => {
          switch (type) {
              case ConfirmEventType.REJECT:
                  this.displayMessage("warn","Cancelled","You have cancelled this operation");
                  break;
              case ConfirmEventType.CANCEL:
                  this.displayMessage("warn","Cancelled","You have cancelled this operation");
                  break;
          }
        }
    });
  }


  clearForm() {
    this.isClearAll = true;
    this.isEditingRecord = true;
    this.disableSelectAllocationType = false;
    this.disableEvenlyDistribute = false;
    this.disableSendForReview = true;
    this.disableSaveButton = false;
    this.percentageValue = ''
    this.choice = true;
    this.isEvenlyDistributeOnly = false;
    this.disableEvenlyDistribute = false;
    this.selectedAllocationType= null;
    // this.budgetCycleForm.reset();
    
    this.budgetCycleForm.get('allocationType').setValue('');
    this.budgetCycleForm.get('percentageValue').setValue('');
    this.budgetCycleForm.get('valueToResolve').setValue('');
    this.budgetCycleForm.get('comment').setValue('');
    this.isPercentage = undefined
    this.calculatedTotalValue = 0;

    this.budgetByMonths.forEach((elem) => {

      elem.isPercentage = undefined
      elem.percentageValue = 0;
      elem.totalValue = 0;
      elem.resolvedValue = 0;
      elem.allocationName = 0;
      elem.allocationTypeId = 0;
      elem.percentageOfBenchMarkValue = 0;
      // elem.isAlreadySaved = false;
    });
    this.isClearAll = false;
  }
  removeItem(item: any) {
    console.log(item);
    console.log(this.budgetByMonths.indexOf(item));
    // return false;
    this.confirmationService.confirm({
      message: `Proceed with removing this item?`,
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        // this.messageService.add({ severity: 'info', summary: 'Confirmed', detail: 'You have accepted' });
        this.displayMessage("info", "Information", "Processing delete operation")
        this.budgetByMonths.splice(this.budgetByMonths.indexOf(item), 1);
        
      },
      reject: (type: ConfirmEventType) => {
          switch (type) {
              case ConfirmEventType.REJECT:
                  this.displayMessage("warn","Cancelled","You have cancelled this operation");
                  break;
              case ConfirmEventType.CANCEL:
                  this.displayMessage("warn","Cancelled","You have cancelled this operation");
                  break;
          }
        }
    });
  }

  inputComment(item: any){
    console.log(item);
    item[1].comment = item[0]
  }

  // showSendForReview(){
  //   let isAllSavedRecords: boolean = true;
  //   this.budgetByMonths.forEach((e) => {
  //     if (e.resolvedValue) {
  //       isAllSavedRecords = false;
  //     }
  //   });
  //   return isAllSavedRecords;
  // }

  // enableSave() {
  //   let isAllClean: boolean = true;
  //   this.budgetByMonths.forEach((e) => {
  //     if (e.resolvedValue && e.percentageOfBenchMarkValue && e.totalValue) {
  //       isAllClean = false;
  //     }
  //   });
    // return isAllClean;
  // }
  sendForReview() {
    this.confirmationService.confirm({
      message: `Proceed with sending for review?`,
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.loadingData = true;
        let master_id = this.budgetByMonths[0].mon_MasterId;
        console.log(master_id);
        // return false;
        let data: any = {"Id": master_id, "WhereTo": "Month"};
        setTimeout(() => {
          this.displayMessage('info', "Information", "Processing request ...");
        }, 1000);
        this.apiService.sendBudgetForReview(data).subscribe((res) => {
          var data = res['responseData'];
          if (data == null || data == false) {
            this.displayMessage("error", "Error", "Record not sent for review");
            this.loadingData = false;
            return false;
          }
          setTimeout(() => {
            this.displayMessage('success', "Information", "Sent for review successful");
            this.loadingData = false;
            this.disableSendForReview = true;   
            
          }, 1000);
          this.reloadPage();
        }, (err)=> {
          setTimeout(() => {
            this.displayMessage('error', "Error", "Error occurred while processing");
            this.loadingData = false;
          }, 1000);
        });
        
      },
      reject: (type: ConfirmEventType) => {
          switch (type) {
              case ConfirmEventType.REJECT:
                  this.displayMessage("warn","Cancelled","You have cancelled this operation");
                  break;
              case ConfirmEventType.CANCEL:
                  this.displayMessage("warn","Cancelled","You have cancelled this operation");
                  break;
          }
        }
    });

    
  }

  reloadPage(){
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate(['./'], {
      relativeTo: this.route
    });
  }

  generateCycleMonths() {
    console.log('Generating months....')
  }

}
