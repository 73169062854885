import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {CommonResponse, OperatingEntity, StrategicBusinessUnit} from '../../../interfaces/home';
import {ConfirmationService, MessageService} from 'primeng/api';
import {StrategicBusinessUnitService} from '../../../services/strategic-business-unit.service';
import {OperatingEntityService} from '../../../services/operating-entity.service';
import { BreadcrumbService } from 'src/app/breadcrumb.service';
import { GroupTypeService } from 'src/app/services/group-type.service';
import { CustomerDivisionService } from 'src/app/services/customer-division.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-client-listing',
  templateUrl: './client-listing.component.html',
  styleUrls: ['./client-listing.component.scss'],
  providers: [ConfirmationService, MessageService]
})
export class ClientListingComponent implements OnInit {
  clientListingForm: FormGroup;
  sbus: any;
  selectedSbu: any;
  fetchingSbUnit = false;
  clientTypes: any;
  selectedClientType: any;

  fetchingClients = false;
  selectedClient: any;
  clients; any;
  clientCols: any;
  exportClientCols: any;

  constructor(
    private formBuilder: FormBuilder,
    private sbUnitService: StrategicBusinessUnitService,
    public messageService: MessageService,
    public confirmationService: ConfirmationService,
    private customerDivisionService: CustomerDivisionService,
    private groupTypeService: GroupTypeService,
    private router: Router,
    private breadcrumbService: BreadcrumbService
  ) { }

  ngOnInit(): void {
    this.breadcrumbService.setItems([
      { label: 'Client Management', routerLink: ['home/client-listing'] },
      { label: 'Client Listing', routerLink: ['home/client-listing'] }
    ]);

    this.fetchRMSbus();

    this.fetchClientTypes();

    this.clientListingForm = this.formBuilder.group({
      sbu: [null, Validators.required],
      clientType: [null, [Validators.required]]
    });

    this.clientCols = [
      { field: 'name', header: 'Name' },
      { field: 'description', header: 'Description' },
      { field: 'operatingEntity', header: 'Operating Entity' },
    ];
    this.exportClientCols = this.clientCols.map(col => ({title: col.header, dataKey: col.field}));
  }

  fetchRMSbus(){
    this.sbUnitService.allRMSbus().subscribe( async (sbUnit: CommonResponse) =>{
      this.sbus = sbUnit.responseData;
      this.fetchingSbUnit = false;
    }, error => {
      this.fetchingSbUnit = false;
      this.connectionError();
    })
  }

  fetchClientTypes(){
    this.groupTypeService.allGroupTypeData().subscribe(async (result: CommonResponse) => {
      this.clientTypes = result.responseData;
    }, error => {
      this.connectionError()
    })
  }

  extractClients(){
    const sbuId = this.selectedSbu.id;
    const clientTypeId = this.selectedClientType.id;

    this.fetchingClients = true;
    this.customerDivisionService.getRMClientsByGroupType(sbuId, clientTypeId).subscribe(async (result: CommonResponse) => {
      this.fetchingClients = false;
      this.clients = result.responseData;
    }, error => {
      this.fetchingClients = false;
      this.connectionError()
    })
  }

  goToClientView(client){
    this.router.navigate([`/home/client-view/${client.groupType}-${client.id}`])
  }

  goToPolicyStatus(client){
    this.router.navigate([`/home/client-policy/${client.id}`])
  }

  private connectionError() {
    this.messageService.add({severity:'error', summary: 'Failed',
      detail:'Connection Error, Please try again'});
  }
}
