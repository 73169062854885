import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { ConfirmationService, Message, MessageService } from "primeng/api";
import { BreadcrumbService } from "src/app/breadcrumb.service";
import { CommonResponse, CustomerDivision } from "src/app/interfaces/home";
import {
  GetRenewalReportReceivingDTO,
  GetRenewalReportTransferDTO,
} from "src/app/interfaces/reporting";
import { CustomerDivisionService } from "src/app/services/customer-division.service";
import { ReportingService } from "src/app/services/reporting.service";

@Component({
  selector: "app-renewal-view",
  templateUrl: "./renewal-view.component.html",
  styleUrls: ["./renewal-view.component.scss"],
  providers: [MessageService],
})
export class RenewalViewComponent implements OnInit {
  @ViewChild("formWrapper") public formWrapper: ElementRef;
  msg: Message[] = [];
  allThresholds: {
    key: number;
    value: string;
  }[] = [
    {
      key: 1,
      value: "1 Month",
    },
    {
      key: 2,
      value: "2 Months",
    },
    {
      key: 3,
      value: "3 Months",
    },
  ];
  theThreshold: {
    key: number;
    value: string;
  };
  allClients: CustomerDivision[];
  selectedClients: CustomerDivision[] = [];
  fetchingReports: boolean;
  showReport: boolean = true;
  allReports: GetRenewalReportTransferDTO[];
  selectedReports: GetRenewalReportTransferDTO[];
  reportCols: any[];

  constructor(
    private fb: FormBuilder,
    private reportingService: ReportingService,
    private customerDivisionService: CustomerDivisionService,
    private breadcrumbService: BreadcrumbService,
    public confirmationService: ConfirmationService,
    public messageService: MessageService
  ) {}

  ngOnInit(): void {
    this.breadcrumbService.setItems([
      {
        label: "Client Management",
      },
      {
        label: "Renewal View",
        routerLink: ["/home/client-management/renewal-view"],
      },
    ]);

    this.reportCols = [
      { field: "clientName", header: "Client Name" },
      { field: "contract", header: "Contract" },
      { field: "service", header: "Service" },
      { field: "serviceTag", header: "Service Tag" },
      { field: "contractServiceEndDate", header: "Contract End Date" },
      { field: "daysToExpiration", header: "Days To Expiration" },
      { field: "status", header: "Status" },
      { field: "invoicingInterval", header: "Invoicing Interval" },
    ];
    this.theThreshold = this.allThresholds[2];
    this.FetchAllCustomerDivisions();
    this.ResetMessageToasters();
  }

  ResetMessageToasters() {
    this.msg = [];
    if (this.theThreshold) {
      this.msg.push({
        severity: "info",
        summary:
          "Showing all contracts due for renewal in " +
          this.theThreshold.value +
          " by default.",
      });
    }
  }

  FetchAllCustomerDivisions() {
    this.customerDivisionService.allCustomerDivision().subscribe(
      async (data: CommonResponse<CustomerDivision[]>) => {
        if (data.responseCode == "00") {
          this.allClients = data.responseData;
        } else {
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail: data.responseMsg,
          });
          this.ResetMessageToasters();
        }
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all customer divisions at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
        this.ResetMessageToasters();
      }
    );
  }

  GetContractRowSpan(contractId: number): number {
    let rowSpan = this.allReports.filter(
      (x) => x.contractId == contractId
    ).length;
    console.log("Contract ID: " + contractId + " - RowSpan: " + rowSpan);
    return rowSpan;
  }

  GetClientRowSpan(clientId: number): number {
    let rowSpan = this.allReports.filter((x) => x.clientId == clientId).length;
    console.log("Client ID: " + clientId + " - RowSpan: " + rowSpan);
    return rowSpan;
  }

  RunReport() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Generating Report...",
    });
    this.ResetMessageToasters();

    this.showReport = false;
    this.fetchingReports = true;
    this.allReports = [];
    const postData: GetRenewalReportReceivingDTO = {
      clientIds: this.selectedClients.map((x) => x.id),
      threshold: this.theThreshold.key,
    };

    this.reportingService.GetRenewalReport(postData).subscribe(
      async (data) => {
        if (data.responseCode != "00") {
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail: data.responseMsg,
          });
          this.ResetMessageToasters();
          this.fetchingReports = false;
          this.showReport = false;
          return;
        }

        this.allReports = data.responseData;
        console.log(this.allReports);
        this.fetchingReports = false;
        this.showReport = true;

        this.messageService.add({
          severity: "success",
          summary: "Completed",
          detail: "Report Generated Successfully!",
        });
        this.ResetMessageToasters();
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to generate report at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
        this.ResetMessageToasters();
        this.fetchingReports = false;
        this.showReport = false;
      }
    );
  }

  FormatDateString(date: Date): string {
    let dateString = date.toString();

    try {
      // DateTimeFormatOptions
      const date: Date = new Date(dateString);
      const options: Intl.DateTimeFormatOptions = {
        year: "numeric",
        month: "long",
        day: "numeric",
      };
      const formattedDate: string = new Intl.DateTimeFormat(
        "en-US",
        options
      ).format(date);
      dateString = formattedDate;
    } catch (error) {
      console.log(error);
      console.log(date);
      console.log(
        "Error while converting date string " + date + " exception " + error
      );
    }

    return dateString;
  }
}
