import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { MessageService, ConfirmationService } from "primeng/api";
import { BreadcrumbService } from "src/app/breadcrumb.service";
import { User } from "src/app/interfaces/home";
import {
  CentralStore,
  GetStockIssueTransfer,
  GetStockIssueVM,
  IssuedToEntity,
  RegionalStore,
} from "src/app/interfaces/inventory";
import { CalendarMonthEnum } from "src/app/interfaces/payroll";
import { InventoryService } from "src/app/services/inventory.service";
import { UserService } from "src/app/services/user.service";

@Component({
  selector: "app-inv-stock-issue",
  templateUrl: "./inv-stock-issue.component.html",
  styleUrls: ["./inv-stock-issue.component.scss"],
})
export class InvStockIssueComponent implements OnInit {
  @ViewChild("formWrapper") public formWrapper: ElementRef;
  isRegional: boolean = false;
  allCentralStores: CentralStore[];
  theCentralStore: CentralStore;
  allRegionalStores: RegionalStore[];
  theRegionalStore: RegionalStore;
  requestStartDate: Date;
  requestEndDate: Date;
  showReport: boolean = false;
  reports: GetStockIssueTransfer[];
  selectedReports: GetStockIssueTransfer[];
  reportCols: any[];
  allIssuedToEntities: IssuedToEntity[];
  selectedIssuedTo: IssuedToEntity[];
  allUsers: User[];
  allSelectedUsers: User[];

  constructor(
    fb: FormBuilder,
    public userService: UserService,
    public inventoryService: InventoryService,
    public messageService: MessageService,
    private breadcrumbService: BreadcrumbService,
    public confirmationService: ConfirmationService
  ) {}

  ngOnInit(): void {
    this.breadcrumbService.setItems([
      {
        label: "Inventory Management",
        routerLink: ["/home/inventory-management/set-up"],
      },
      {
        label: "Stock Issue",
        routerLink: ["/home/inventory-management/stock-issue"],
      },
    ]);

    this.reportCols = [
      { field: "issuedTo", header: "Issued To" },
      { field: "itemName", header: "Item Name" },
      { field: "qty", header: "Quantity" },
      { field: "itemGroup", header: "Item Group" },
      { field: "batchName", header: "Batch Name" },
      { field: "monthOfIssue", header: "Month Of Issue" },
      { field: "yearOfIssue", header: "Year Of Issue" },
    ];

    this.FetchAllInventoryStores();
    this.FetchAllUsers();
  }

  FetchAllInventoryStores() {
    this.inventoryService.GetAllInventoryStores().subscribe(
      (data) => {
        this.allCentralStores = data.centralStores;
        this.allRegionalStores = data.regionalStores;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all inventory stores at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  FetchAllUsers() {
    this.userService.allUser().subscribe(
      async (r) => {
        var data = r.responseData ?? [];
        this.allUsers = [];
        data.forEach((user) => {
          user.fullName = user.lastName + " " + user.firstName;
          this.allUsers.push(user);
        });
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all users at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  RunCriteria() {
    if (this.requestEndDate < this.requestStartDate) {
      this.messageService.add({
        severity: "error",
        summary: "Notice",
        detail: "Start Date must not be greater than end date.",
      });
      return;
    }

    this.showReport = false;
    const postData: GetStockIssueVM = {
      isRegional: this.isRegional,
      storeId: this.isRegional
        ? this.theRegionalStore.id
        : this.theCentralStore.id,
      issuedToEntities: this.selectedIssuedTo,
      requestStartDate: this.requestStartDate,
      requestEndDate: this.requestEndDate,
    };

    this.inventoryService.GetStockIssue(postData).subscribe(
      async (data) => {
        this.reports = data;
        this.showReport = true;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to generate report at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
        this.showReport = false;
      }
    );
  }

  LoadIssuedToEntities() {
    this.allIssuedToEntities = [];
    this.selectedIssuedTo = [];

    if (this.isRegional) {
      this.allUsers.forEach((user) => {
        this.allIssuedToEntities.push({
          id: user.id,
          name: user.fullName,
          isStore: false,
        });
      });
    } else {
      this.allRegionalStores
        .filter((x) => x.centralStoreId == this.theCentralStore.id)
        .forEach((store) => {
          this.allIssuedToEntities.push({
            id: store.id,
            name: store.regionalStoreName,
            isStore: true,
          });
        });
    }
  }

  GetMonthLabel(month: number): string {
    if (month == CalendarMonthEnum.January) return "January";
    if (month == CalendarMonthEnum.February) return "February";
    if (month == CalendarMonthEnum.March) return "March";
    if (month == CalendarMonthEnum.April) return "April";
    if (month == CalendarMonthEnum.May) return "May";
    if (month == CalendarMonthEnum.June) return "June";
    if (month == CalendarMonthEnum.July) return "July";
    if (month == CalendarMonthEnum.August) return "August";
    if (month == CalendarMonthEnum.September) return "September";
    if (month == CalendarMonthEnum.October) return "October";
    if (month == CalendarMonthEnum.November) return "November";
    if (month == CalendarMonthEnum.December) return "December";
    return "N/A";
  }
}
