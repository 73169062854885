import {
  DeployedGuard,
  GuardDeployment,
} from "./../../../interfaces/employment";
import {
  AssignMembersToPayGradeVM,
  AssignMembersToPayGroupVM,
  PayGrade,
  PayGroup,
} from "./../../../interfaces/payroll";
import { CustomerDivision } from "./../../../interfaces/home";
import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { ConfirmationService, Message, MessageService } from "primeng/api";
import { BreadcrumbService } from "src/app/breadcrumb.service";
import { PayrollService } from "src/app/services/payroll.service";
import { Location } from "src/app/interfaces/home";

@Component({
  selector: "app-payroll-operative-attachment",
  templateUrl: "./payroll-operative-attachment.component.html",
  styleUrls: ["./payroll-operative-attachment.component.scss"],
  providers: [MessageService],
})
export class PayrollOperativeAttachmentComponent implements OnInit {
  @ViewChild("formWrapper") public formWrapper: ElementRef;
  allClients: CustomerDivision[];
  theClient: CustomerDivision;
  imageUrl: string;
  carouselResponsiveOptions: any[] = [
    {
      breakpoint: "1024px",
      numVisible: 3,
      numScroll: 3,
    },
    {
      breakpoint: "768px",
      numVisible: 2,
      numScroll: 2,
    },
    {
      breakpoint: "560px",
      numVisible: 1,
      numScroll: 1,
    },
  ];
  allClientLocations: Location[];
  allPayGrades: PayGrade[];
  thePayGrade: PayGrade;
  allPayGradeOperatives: DeployedGuard[] = [];
  selectedPayGradeOperative: DeployedGuard;
  paygradeCols: any[];
  allPayGroups: PayGroup[];
  thePayGroup: PayGroup;
  allPayGroupOperatives: DeployedGuard[] = [];
  selectedPayGroupOperative: DeployedGuard;
  showTheClientBox: boolean;
  theClientName: string;
  theClientRCNo: string;
  theClientEmail: string;
  theClientMobile: string;
  theClientActiveOP: string;
  theClientInactiveOP: string;
  theClientTotalOP: number;
  showBelowComponents: boolean;
  allGuardDeploymentWithPaygroups: GuardDeployment[] = [];
  allGuardDeploymentWithPaygrades: GuardDeployment[] = [];
  allGuardDeploymentWithoutPaygrades: GuardDeployment[] = [];
  locationInView: Location;
  msg: Message[] = [];
  //'assets/no-file.png'

  constructor(
    private fb: FormBuilder,
    private payrollService: PayrollService,
    private breadcrumbService: BreadcrumbService,
    public confirmationService: ConfirmationService,
    public messageService: MessageService
  ) {}

  ngOnInit(): void {
    this.breadcrumbService.setItems([
      {
        label: "Payroll Management",
        routerLink: ["/home/payroll/payroll-settings"],
      },
      {
        label: "Operative Attachment",
        routerLink: ["/home/payroll/operative-attachment"],
      },
    ]);

    this.paygradeCols = [
      {
        field: "jobApplication.personalInformation.lastname",
        header: "JobApplication.personalInformation.lastname",
      },
      {
        field: "jobApplication.personalInformation.firstname",
        header: "JobApplication.personalInformation.firstname",
      },
    ];

    this.FetchAllClients();
    // this.FetchAllPayGrades();
    // this.FetchAllPayGroups();
  }

  ResetMessageToasters() {
    this.msg = [];
    this.msg.push({
      severity: "info",
      summary: "Notice:",
      detail: "You can't detach a guard that is currenly in a payroll run.",
    });
  }

  FetchAllClients() {
    this.payrollService.GetAllOAClients().subscribe(
      async (data) => {
        this.allClients = data;
        this.ResetMessageToasters();
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable get all clients at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
        this.ResetMessageToasters();
      }
    );
  }

  FetchAllPayGrades() {
    this.payrollService.GetAllPayGradeWithMembers().subscribe(
      async (data) => {
        this.allPayGrades = data;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable get all paygrades at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
        this.ResetMessageToasters();
      }
    );
  }

  FetchAllPayGroups() {
    this.payrollService.GetAllPayGroupWithMembers().subscribe(
      async (data) => {
        this.allPayGroups = data;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable get all paygroups at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
        this.ResetMessageToasters();
      }
    );
  }

  ClientSelectionLoader() {
    this.showTheClientBox = this.theClient ? true : false;
    this.theClientName = this.theClient ? this.theClient.divisionName : null;
    this.theClientRCNo = this.theClient ? this.theClient.rcNumber : null;
    this.theClientEmail = this.theClient ? this.theClient.email : null;
    this.theClientMobile = this.theClient ? this.theClient.phoneNumber : null;
    this.imageUrl = this.theClient ? this.theClient.logoUrl : null;

    let count = 0;
    if (this.theClient) {
      this.theClient.locations.forEach(
        (x) => (count = count + this.GetGuardCount(x))
      );
    }
    //this.theClientActiveOP = this.theClient ? count : null;
    //this.theClientInactiveOP = this.theClient ? 0 : null;
    this.theClientActiveOP = null;
    this.theClientInactiveOP = null;
    this.theClientTotalOP = count;
    this.allClientLocations = this.theClient.locations.filter(
      (x) => x.isDeleted == false
    );
    this.showBelowComponents = false;
    this.allPayGradeOperatives = [];
    this.allPayGroupOperatives = [];
    this.thePayGrade = null;
    this.thePayGroup = null;
  }

  PaygradeSelectionLoader() {
    this.allPayGradeOperatives = this.thePayGrade
      ? this.thePayGrade.members.filter(
          (x) => this.DeterminOperativeIsInLocationInView(x) == true
        )
      : [];
  }

  RemovePaygradeOperative(item: DeployedGuard) {
    if (item.payGroupId) {
      this.messageService.add({
        severity: "error",
        summary: "Notice",
        detail:
          "You can't detach operative from paygrade because operative is currently attached to a paygroup!!",
      });
      this.ResetMessageToasters();
      return;
    }
    const index = this.allPayGradeOperatives.indexOf(item);
    if (index > -1) {
      this.allPayGradeOperatives.splice(index, 1);
    }
    this.allGuardDeploymentWithoutPaygrades.push({
      id:
        this.allGuardDeploymentWithoutPaygrades[
          this.allGuardDeploymentWithoutPaygrades.length - 1
        ].id + 1,
      deployedGuardId: item.id,
      deployedGuard: item,
    });
  }

  PaygroupSelectionLoader() {
    this.allPayGroupOperatives = this.thePayGroup
      ? this.thePayGroup.members.filter(
          (x) => this.DeterminOperativeIsInLocationInView(x) == true
        )
      : [];
  }

  RemovePaygroupOperative(item: DeployedGuard) {
    const index = this.allPayGroupOperatives.indexOf(item);
    if (index > -1) {
      this.allPayGroupOperatives.splice(index, 1);
    }
    this.allGuardDeploymentWithPaygrades.push({
      id:
        this.allGuardDeploymentWithPaygrades[
          this.allGuardDeploymentWithPaygrades.length - 1
        ].id + 1,
      deployedGuardId: item.id,
      deployedGuard: item,
    });
  }

  GetGuardCount(location: Location): number {
    return location.guardDeployments.filter((x) => x.isDeleted == false).length;
  }

  LoadLocationGuards(location: Location) {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Loading Location Info...",
    });
    this.allGuardDeploymentWithPaygroups = [];
    this.allGuardDeploymentWithPaygrades = [];
    this.allGuardDeploymentWithPaygroups = [];
    this.payrollService.GetAllLocationDeployedGuards(location.id).subscribe(
      async (data) => {
        this.messageService.add({
          severity: "success",
          summary: "Completed",
          detail: "Data Loaded Successfully!",
        });
        this.ResetMessageToasters();

        location.guardDeployments = data;
        this.allGuardDeploymentWithPaygrades = data.filter(
          (x) =>
            x.deployedGuard.payGradeId != null &&
            x.deployedGuard.payGroupId == null &&
            x.isDeleted == false
        );
        this.allGuardDeploymentWithoutPaygrades = data.filter(
          (x) =>
            x.deployedGuard.payGradeId == null &&
            x.deployedGuard.payGroupId == null &&
            x.isDeleted == false
        );
        this.allGuardDeploymentWithPaygroups = data.filter(
          (x) =>
            x.deployedGuard.payGroupId != null &&
            x.deployedGuard.payGradeId != null &&
            x.isDeleted == false
        );
        this.theClientActiveOP = "DATA";
        this.theClientInactiveOP = "DATA";
        this.showBelowComponents = true;
        this.locationInView = location;
        this.FetchAllPayGrades();
        this.FetchAllPayGroups();
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable get all location deployed guards info at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
        this.ResetMessageToasters();
      }
    );
  }

  AttachOPsToPayGrade() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Attaching members to Pay Grade...",
    });

    const postData: AssignMembersToPayGradeVM = {
      payGradeId: this.thePayGrade.id,
      memberIds: this.allPayGradeOperatives.map((x) => x.id),
      locationId: this.locationInView.id,
    };

    this.payrollService.AssignMembersToPayGrade(postData).subscribe(
      async () => {
        this.messageService.add({
          severity: "success",
          summary: "Completed",
          detail: "PayGrade Members Assigned Successfully!",
        });
        this.ResetMessageToasters();

        this.thePayGrade = null;
        this.allPayGradeOperatives = [];
        this.LoadLocationGuards(this.locationInView);
        this.FetchAllPayGrades();
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to assign members to paygrade at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
        this.ResetMessageToasters();
      }
    );
  }

  AttachOPToPayGrade(item: GuardDeployment) {
    if (this.thePayGrade) {
      this.allPayGradeOperatives.push(item.deployedGuard);
      const index = this.allGuardDeploymentWithoutPaygrades.indexOf(item);
      if (index > -1) {
        this.allGuardDeploymentWithoutPaygrades.splice(index, 1);
      }
    } else {
      this.messageService.add({
        severity: "error",
        summary: "Notice",
        detail: "No PayGrade Selected!!",
      });
      this.ResetMessageToasters();
    }
  }

  AttachOPsToPayGroup() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Attaching members to Pay Grade...",
    });

    const postData: AssignMembersToPayGroupVM = {
      payGroupId: this.thePayGroup.id,
      memberIds: this.allPayGroupOperatives.map((x) => x.id),
      locationId: this.locationInView.id,
    };

    this.payrollService.AssignMembersToPayGroup(postData).subscribe(
      async () => {
        this.messageService.add({
          severity: "success",
          summary: "Completed",
          detail: "PayGroup Members Assigned Successfully!",
        });
        this.ResetMessageToasters();

        this.thePayGroup = null;
        this.allPayGroupOperatives = [];
        this.thePayGrade = null;
        this.allPayGrades = [];
        this.allPayGradeOperatives = [];
        this.LoadLocationGuards(this.locationInView);
        this.FetchAllPayGroups();
        this.FetchAllPayGrades();
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to assign members to paygroup at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
        this.ResetMessageToasters();
      }
    );
  }

  AttachOPToPayGroup(item: GuardDeployment) {
    if (this.thePayGroup) {
      this.allPayGroupOperatives.push(item.deployedGuard);
      const index = this.allGuardDeploymentWithPaygrades.indexOf(item);
      if (index > -1) {
        this.allGuardDeploymentWithPaygrades.splice(index, 1);
      }
    } else {
      this.messageService.add({
        severity: "error",
        summary: "Notice",
        detail: "No PayGroup Selected!!",
      });
      this.ResetMessageToasters();
    }
  }

  DeterminOperativeIsInLocationInView(item: DeployedGuard): boolean {
    if (this.locationInView) {
      let locationDeployment = item.guardDeployments.find(
        (x) => x.locationId == this.locationInView.id && x.isDeleted == false
      );

      return locationDeployment != null;
    }

    return false;
  }
}
