import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../environments/environment";
import { Observable } from "rxjs";
import {
  CommonResponse,
  CustomerDivision,
  TaskFulfillment,
  ClientNotificationMail,
  AddNewClientNotificationEmailVM,
  UpdateClientNotificationEmailVM,
  AddMultipleClientNotificationEmailVM,
} from "../interfaces/home";

@Injectable({
  providedIn: "root",
})
export class CustomerDivisionService {
  baseUrl = environment.baseUrl + "CustomerDivision/";

  constructor(private http: HttpClient) {}

  getAllCreditNote(customerDivisionId: any) {
    throw new Error("Method not implemented.");
  }

  // fetch all customer in the database
  allCustomerDivision(): Observable<CommonResponse> {
    return this.http.get<CommonResponse>(this.baseUrl);
  }

  getClientsUnAssignedToRMSbu(): Observable<CommonResponse> {
    return this.http.get<CommonResponse>(
      this.baseUrl + "GetClientsUnAssignedToRMSbu"
    );
  }

  getCustomerDivision(id: any): Observable<CommonResponse> {
    return this.http.get<CommonResponse>(this.baseUrl + id);
  }

  getCustomerDivisionByDTrackCustomerNumber(
    dTrackCustomerNumber: string
  ): Observable<CommonResponse> {
    return this.http.get<CommonResponse>(
      this.baseUrl + "GetByCustomerNumber/" + dTrackCustomerNumber
    );
  }

  getTaskAndDeliverablesByCustomerDivisionId(
    id: any
  ): Observable<CommonResponse> {
    return this.http.get<CommonResponse>(
      this.baseUrl + "TaskAndDeliverables/" + id
    );
  }

  getCustomerDivisionByName(name: any): Observable<CommonResponse> {
    return this.http.get<CommonResponse>(this.baseUrl + "name/" + name);
  }

  getCustomerDivisionByGroupType(id: any) {
    return this.http.get(
      this.baseUrl + "GetCustomerDivisionsByGroupType/" + id
    );
  }

  postCustomerDivision(data: any) {
    return this.http.post(this.baseUrl, data);
  }

  deleteCustomerDivision(id: any) {
    return this.http.delete(this.baseUrl + id);
  }

  updateCustomerDivision(id: any, data: any) {
    return this.http.put(this.baseUrl + id, data);
  }

  updateCustomerDivisionForGenericNotifications(data: any) {
    return this.http.put(`${this.baseUrl}UpdateGenericNotification`, data);
  }

  getClientsAttachedToSbu(id: any): Observable<CommonResponse> {
    return this.http.get<CommonResponse>(
      this.baseUrl + "GetClientsAttachedToRMSbu/" + id
    );
  }

  attachClientToRMSbu(id: any, sbuId: any) {
    return this.http.put(
      this.baseUrl + "AttachClientToRMSbu/" + id + "/" + sbuId,
      {}
    );
  }

  getRMClientsByGroupType(sbuId: any, clientTypeId: any) {
    return this.http.get(
      this.baseUrl + "GetRMSbuClientsByGroupType/" + sbuId + "/" + clientTypeId
    );
  }

  GetCustomerDivisionswWithContracts(): Observable<CommonResponse> {
    return this.http.get<CommonResponse>(
      this.baseUrl + "GetCustomerDivisionswWithContracts"
    );
  }

  GetNotificationEmailsByCustomerDivisionId(
    customerDivisionId: number
  ): Observable<CommonResponse<ClientNotificationMail[]>> {
    return this.http.get<CommonResponse<ClientNotificationMail[]>>(
      this.baseUrl +
        "GetNotificationEmailsByCustomerDivisionId/" +
        customerDivisionId
    );
  }

  AddNewClientNotificationEmail(
    data: AddNewClientNotificationEmailVM
  ): Observable<CommonResponse> {
    return this.http.post<CommonResponse>(
      this.baseUrl + "AddNewClientNotificationEmail",
      data
    );
  }

  UpdateClientNotificationEmail(
    clientNotificationMailId: number,
    data: UpdateClientNotificationEmailVM
  ): Observable<CommonResponse> {
    return this.http.put<CommonResponse>(
      this.baseUrl +
        "UpdateClientNotificationEmail/" +
        clientNotificationMailId,
      data
    );
  }

  DeleteClientNotificationEmail(
    clientNotificationMailId: number
  ): Observable<CommonResponse> {
    return this.http.delete<CommonResponse>(
      this.baseUrl + "DeleteClientNotificationEmail/" + clientNotificationMailId
    );
  }

  AddMultipleClientNotificationEmail(
    data: AddMultipleClientNotificationEmailVM
  ): Observable<CommonResponse> {
    return this.http.post<CommonResponse>(
      this.baseUrl + "AddMultipleClientNotificationEmail",
      data
    );
  }
}
