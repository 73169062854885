import { environment } from "./../../../../environments/environment";
import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { MessageService, ConfirmationService } from "primeng/api";
import { UIChart } from "primeng/chart";
import { BreadcrumbService } from "src/app/breadcrumb.service";
import { TopStoreItemViewBox } from "src/app/interfaces/inventory";
import { InventoryService } from "src/app/services/inventory.service";

@Component({
  selector: "app-inventory-dashboard",
  templateUrl: "./inventory-dashboard.component.html",
  styleUrls: ["./inventory-dashboard.component.scss"],
})
export class InventoryDashboardComponent implements OnInit {
  @ViewChild("formWrapper") public formWrapper: ElementRef;
  @ViewChild("chart") chart: UIChart;
  isRegional: boolean = false;
  carouselResponsiveOptions: any[] = [
    {
      breakpoint: "1024px",
      numVisible: 3,
      numScroll: 3,
    },
    {
      breakpoint: "768px",
      numVisible: 2,
      numScroll: 2,
    },
    {
      breakpoint: "560px",
      numVisible: 1,
      numScroll: 1,
    },
  ];
  top10CentralStoreItems: TopStoreItemViewBox[];
  top10RegionalStoreItems: TopStoreItemViewBox[];
  requestTable: {
    name: string;
    data: number;
  }[];
  pieData: {
    labels: string[];
    datasets: {
      data: number[];
      backgroundColor: string[];
    }[];
  };
  pieSummary: {
    label: string;
    title: string;
  }[];
  centralDistinctCount: string;
  centralTotalCount: string;
  centralCompletedOrders: string;
  centralCompletedOrderYr: string = "YYYY";
  centralWeekCompletedOrders: string;
  regionalDistinctCount: string;
  regionalTotalCount: string;
  regionalCompletedOrders: string;
  regionalCompletedOrderYr: string = "YYYY";
  regionalWeekCompletedOrders: string;
  centralStoreManagedCount: string;
  regionalStoreManagedCount: string;
  totalStoreManagedCount: string;

  constructor(
    fb: FormBuilder,
    public inventoryService: InventoryService,
    public messageService: MessageService,
    private breadcrumbService: BreadcrumbService,
    public confirmationService: ConfirmationService
  ) {}

  ngOnInit(): void {
    this.breadcrumbService.setItems([
      {
        label: "Inventory Management",
        routerLink: ["/home/inventory-management/set-up"],
      },
      {
        label: "Dashboard",
        routerLink: ["/home/inventory-management/dashboard"],
      },
    ]);

    this.requestTable = [
      {
        name: "No of waiting inward requests",
        data: 0,
      },
      {
        name: "No of waiting outward requests",
        data: 0,
      },
      {
        name: "No of pending approvals for you",
        data: 0,
      },
      {
        name: "You confirmed out but not yet accepted in",
        data: 0,
      },
      {
        name: "You requested but not yet approved/confirmed out",
        data: 0,
      },
    ];

    this.pieData = {
      labels: [],
      datasets: [
        {
          data: [],
          backgroundColor: [],
        },
      ],
    };

    this.pieSummary = [];

    this.FetchAnalytics();
  }

  FetchAnalytics() {
    this.inventoryService.GetDashboardAnalytics().subscribe(
      async (data) => {
        this.centralDistinctCount =
          data.centralStore.totalDistinctItems.toLocaleString();
        this.centralTotalCount =
          data.centralStore.totalItemsInStore.toLocaleString();
        this.centralCompletedOrders =
          data.centralStore.totalYearCompletedOrder.toLocaleString();
        this.centralCompletedOrderYr =
          data.centralStore.yearOfCompletedOrder ?? "YYYY";
        this.centralWeekCompletedOrders =
          data.centralStore.totalWeekCompletedOrder.toLocaleString();

        this.regionalDistinctCount =
          data.regionalStore.totalDistinctItems.toLocaleString();
        this.regionalTotalCount =
          data.regionalStore.totalItemsInStore.toLocaleString();
        this.regionalCompletedOrders =
          data.regionalStore.totalYearCompletedOrder.toLocaleString();
        this.regionalCompletedOrderYr =
          data.regionalStore.yearOfCompletedOrder ?? "YYYY";
        this.regionalWeekCompletedOrders =
          data.regionalStore.totalWeekCompletedOrder.toLocaleString();

        this.centralStoreManagedCount =
          data.totalCentralStoresManaged.toLocaleString();
        this.regionalStoreManagedCount =
          data.totalRegionalStoresManaged.toLocaleString();
        this.totalStoreManagedCount = data.totalStoresManaged.toLocaleString();

        this.top10CentralStoreItems = data.centralTop10StoreItems;
        this.top10RegionalStoreItems = data.regionalTop10StoreItems;

        this.requestTable[0].data = data.totalPendingInwardRequests;
        this.requestTable[1].data = data.totalPendingOutwardRequests;
        this.requestTable[2].data = data.totalPendingApprovals;
        this.requestTable[3].data = data.totalCofirmedYetToBeAcknowledged;
        this.requestTable[4].data =
          data.totalRequestYetToBeApprovedOrConfirmedOut;

        this.pieData.labels = data.stockCategoryMix.labels;
        this.pieData.datasets[0].data = data.stockCategoryMix.datas;
        this.pieData.datasets[0].backgroundColor =
          data.stockCategoryMix.backgroundColors;

        this.pieSummary = [];
        let index = 0;
        data.stockCategoryMix.labels.forEach((x) => {
          this.pieSummary.push({
            label: data.stockCategoryMix.backgroundColors[index],
            title: x,
          });

          index++;
        });
        console.log(this.pieData);
        this.chart.reinit();
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to get dashboard analytics at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  GoToConfirmation() {
    window.open(
      environment.frontednUrl +
        "#/home/inventory-management/batch-confirmation",
      "_self"
    );
  }

  GoToApproval() {
    window.open(
      environment.frontednUrl + "#/home/inventory-management/batch-approval",
      "_self"
    );
  }
}
