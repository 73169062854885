import { OrganizationService } from "./../../../services/organization.service";
import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import {
  MessageService,
  ConfirmationService,
  TreeNode,
  Message,
} from "primeng/api";
import { BreadcrumbService } from "src/app/breadcrumb.service";
import {
  AddCompanyNodeMemberVM,
  AddCompanyNodeVM,
  AddOrganizationNodeVM,
  Company,
  OrganizationNode,
  OrganizationNodeMember,
  UpdateOrganizationNodeVM,
} from "src/app/interfaces/organization";
import { User } from "src/app/interfaces/home";
import { UserService } from "src/app/services/user.service";

@Component({
  selector: "app-organization-organogram",
  templateUrl: "./organization-organogram.component.html",
  styleUrls: ["./organization-organogram.component.scss"],
  providers: [MessageService],
})
export class OrganizationOrganogramComponent implements OnInit {
  @ViewChild("formWrapper") public formWrapper: ElementRef;
  allCompanies: Company[];
  theCompany: Company;
  OrganizationTree: TreeNode[];
  treeNodeCount: number = 0;
  selectedOrganizationNode: TreeNode;
  organizationNodeInView: OrganizationNode;
  selectedNodeCaption: string;
  selectedNodeNewCaption: string;
  selectedNodeDesc: string;
  selectedNodeNewDesc: string;
  allUsers: User[];
  theUser: User;
  theNewUser: User;
  addedNewNodeMembers: AddCompanyNodeMemberVM[] = [];
  selectedNodeMember: OrganizationNodeMember[] = [];
  selectedNewNodeMember: AddCompanyNodeMemberVM[] = [];
  nodeMemberCols: any[];
  allNodeMembers: OrganizationNodeMember[];
  nodeMsg: Message[] = [];
  selectedNodeCapt: string = "N/A";

  constructor(
    private organizationService: OrganizationService,
    private userService: UserService,
    public messageService: MessageService,
    private breadcrumbService: BreadcrumbService,
    public confirmationService: ConfirmationService
  ) {}

  ngOnInit(): void {
    this.breadcrumbService.setItems([
      {
        label: "Organization",
        routerLink: ["/home/ogranization"],
      },
      {
        label: "Oragnogram",
        routerLink: ["/home/organization/orgainzation-organogram"],
      },
    ]);

    this.nodeMemberCols = [{ field: "name", header: "Name" }];

    this.FetchAllCompanies();
    this.FetchAllUsers();
  }

  ResetMessageToasters() {
    this.nodeMsg = [];
    this.nodeMsg.push({
      severity: "info",
      summary: "Notice:",
      detail: "Selected Node -> " + this.selectedNodeCapt,
    });
  }

  FetchAllCompanies() {
    this.organizationService.GetAllCompanies().subscribe(
      async (data) => {
        this.allCompanies = data;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail: error
            ? "Unable get all companies at the moment.. Reason: [" +
              (error ? error.error.message : "request failed - permission") +
              "]"
            : "Request Failed",
        });
        this.ResetMessageToasters();
      }
    );
  }

  FetchAllUsers() {
    this.userService.allUser().subscribe(
      async (data) => {
        this.allUsers = [];
        data.responseData.forEach((user) => {
          user.fullName = user.lastName + " " + user.firstName;
          this.allUsers.push(user);
        });
      },
      () => {
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail: "Request Failed - Connection Error!",
        });
        this.ResetMessageToasters();
      }
    );
  }

  LoadOrganizationTree() {
    this.OrganizationTree = [
      {
        label: "Halogen- CEO",
        children: [],
      },
    ];
  }

  SelectOrganizationNodeData(event) {
    this.organizationNodeInView = null;
    this.theUser = null;
    this.selectedNodeCapt = this.theCompany.name;
    this.ResetNewNode();
    if (event.node.data) {
      if (this.selectedOrganizationNode !== event.node.data) {
        this.selectedOrganizationNode = event.node;
        this.organizationNodeInView = event.node.data;
        this.selectedNodeCapt = this.organizationNodeInView.caption;
        this.ResetMessageToasters();

        this.allNodeMembers = [];
        this.selectedNodeCaption = this.organizationNodeInView.caption;
        this.selectedNodeDesc = this.organizationNodeInView.description;
        this.organizationService
          .GetOrganizationNodeMembers(this.organizationNodeInView.id)
          .subscribe(
            async (data) => {
              this.allNodeMembers = data;
              this.organizationNodeInView.members = data;
            },
            (error) => {
              console.log("Error: " + JSON.stringify(error));
              this.messageService.add({
                severity: "error",
                summary: "Notice",
                detail: error
                  ? "Unable get all node members at the moment.. Reason: [" +
                    (error
                      ? error.error.message
                      : "request failed - permission") +
                    "]"
                  : "Request Failed",
              });
              this.ResetMessageToasters();
            }
          );
      }
    }
  }

  OnCompanyChange() {
    if (this.theCompany) {
      this.organizationService.GetCompanyNodes(this.theCompany.id).subscribe(
        async (data) => {
          this.OrganizationTree = [
            {
              label: this.theCompany.name,
              selectable: false,
              children: [],
            },
          ];

          this.selectedNodeCapt = this.theCompany.name;
          this.selectedOrganizationNode = null;
          data.forEach((node) => {
            this.GetOrganizationSubNodes(node, this.OrganizationTree[0], 0);
          });
          this.ResetMessageToasters();
        },
        (error) => {
          console.log("Error: " + JSON.stringify(error));
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail: error
              ? "Unable get company nodes at the moment.. Reason: [" +
                (error ? error.error.message : "request failed - permission") +
                "]"
              : "Request Failed",
          });
          this.ResetMessageToasters();
        }
      );
    }
  }

  GetOrganizationSubNodes(
    node: OrganizationNode,
    tree: any,
    index: number
  ): any {
    if (node == null) return;
    let treeSub = {
      key: this.treeNodeCount++,
      label: node.caption,
      data: node,
      icon: "pi pi-sitemap",
      children: [],
      selectable: true,
    };

    this.organizationService.GetSubNodes(node.id).subscribe(
      async (data) => {
        data.forEach((x) => {
          this.GetOrganizationSubNodes(x, treeSub, 0);
        });
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail: error
            ? "Unable to get sub nodes of node " + node.caption
            : "Request Failed",
        });
        this.ResetMessageToasters();
      }
    );

    tree.children.push(treeSub);
    return;
  }

  AddMemberToList() {
    if (this.allNodeMembers.find((x) => x.userId == this.theUser.id) != null) {
      this.messageService.add({
        severity: "error",
        summary: "Notice",
        detail: "Member has already been added!",
      });
      this.ResetMessageToasters();
      return;
    }

    this.allNodeMembers.push({
      imageUrl: this.theUser.imageUrl,
      firstname: this.theUser.firstName,
      lastname: this.theUser.lastName,
      level: 3,
      userId: this.theUser.id,
    });
  }

  MakeMemberHead(member: OrganizationNodeMember) {
    member.level = 1;
    this.allNodeMembers
      .filter((x) => x.userId != member.userId && x.level == 1)
      .forEach((x) => (x.level = 3));
  }

  MakeMemberDeputy(member: OrganizationNodeMember) {
    member.level = 2;
    this.allNodeMembers
      .filter((x) => x.userId != member.userId && x.level == 2)
      .forEach((x) => (x.level = 3));
  }

  ResetEditNode() {}

  UpdateNodeData() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Updating Organization node...",
    });
    this.ResetMessageToasters();

    const id = this.organizationNodeInView.id;
    const postData: UpdateOrganizationNodeVM = {
      caption: this.selectedNodeCaption,
      Description: this.selectedNodeDesc,
      members: [],
    };
    this.allNodeMembers.forEach((member) => {
      postData.members.push({
        imageUrl: member.imageUrl,
        lastName: member.lastname,
        firstName: member.firstname,
        userId: member.userId,
        level: member.level,
      });
    });

    this.organizationService.UpdateOrganizationNode(id, postData).subscribe(
      async () => {
        this.messageService.add({
          severity: "success",
          summary: "Completed",
          detail: "Updated Successfully.",
        });
        this.ResetMessageToasters();

        this.organizationNodeInView.caption = postData.caption;
        this.organizationNodeInView.description = postData.Description;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to update organization node data at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
        this.ResetMessageToasters();
      }
    );
  }

  AddNewMemberToList() {
    if (
      this.addedNewNodeMembers.find((x) => x.userId == this.theNewUser.id) !=
      null
    ) {
      this.messageService.add({
        severity: "error",
        summary: "Notice",
        detail: "Member has already been added!",
      });
      this.ResetMessageToasters();
      return;
    }

    this.addedNewNodeMembers.push({
      imageUrl: this.theNewUser.imageUrl,
      firstName: this.theNewUser.firstName,
      lastName: this.theNewUser.lastName,
      level: 3,
      userId: this.theNewUser.id,
    });
    this.theNewUser = null;
  }

  RemoveNewMember(item: any) {}

  MakeNewMemberDeputy(member: OrganizationNodeMember) {
    member.level = 2;
    this.addedNewNodeMembers
      .filter((x) => x.userId != member.userId && x.level == 2)
      .forEach((x) => (x.level = 3));
  }

  MakeNewMemberHead(member: OrganizationNodeMember) {
    member.level = 1;
    this.addedNewNodeMembers
      .filter((x) => x.userId != member.userId && x.level == 1)
      .forEach((x) => (x.level = 3));
  }

  ResetNewNode() {
    this.selectedNodeNewCaption = null;
    this.selectedNodeNewDesc = null;
    this.addedNewNodeMembers = [];
    this.theNewUser = null;
  }

  SaveNodeData() {
    if (this.organizationNodeInView == null) {
      this.messageService.add({
        severity: "info",
        summary: "Notice",
        detail: "Adding Company Node...",
      });
      this.ResetMessageToasters();

      const postData: AddCompanyNodeVM = {
        companyId: this.theCompany.id,
        caption: this.selectedNodeNewCaption,
        description: this.selectedNodeNewDesc,
        members: [],
      };
      this.addedNewNodeMembers.forEach((member) => [
        postData.members.push({
          imageUrl: member.imageUrl,
          firstName: member.firstName,
          lastName: member.lastName,
          level: member.level,
          userId: member.userId,
        }),
      ]);

      this.organizationService.AddCompanyNode(postData).subscribe(
        async () => {
          this.messageService.add({
            severity: "success",
            summary: "Completed",
            detail: "Company Node Added Successfully!",
          });
          this.ResetMessageToasters();

          this.selectedNodeNewCaption = null;
          this.selectedNodeNewDesc = null;
          this.theNewUser = null;
          this.addedNewNodeMembers = [];
          this.selectedNewNodeMember = [];
          this.OnCompanyChange();
        },
        (error) => {
          console.log("Error: " + JSON.stringify(error));
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail:
              "Unable to add company node at the moment.. Reason: [" +
              (error ? error.error.message : "request failed - permission") +
              "]",
          });
          this.ResetMessageToasters();
        }
      );
    } else {
      this.messageService.add({
        severity: "info",
        summary: "Notice",
        detail: "Adding Organization Node...",
      });

      const postData: AddOrganizationNodeVM = {
        organizationNodeId: this.organizationNodeInView.id,
        caption: this.selectedNodeNewCaption,
        description: this.selectedNodeNewDesc,
        members: [],
      };
      this.addedNewNodeMembers.forEach((member) => [
        postData.members.push({
          imageUrl: member.imageUrl,
          firstName: member.firstName,
          lastName: member.lastName,
          level: member.level,
          userId: member.userId,
        }),
      ]);

      this.organizationService.AddOrganizationNode(postData).subscribe(
        async () => {
          this.messageService.add({
            severity: "success",
            summary: "Completed",
            detail: "Organization Node Added Successfully!",
          });

          this.selectedNodeNewCaption = null;
          this.selectedNodeNewDesc = null;
          this.theNewUser = null;
          this.addedNewNodeMembers = [];
          this.selectedNewNodeMember = [];
          this.OnCompanyChange();
        },
        (error) => {
          console.log("Error: " + JSON.stringify(error));
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail:
              "Unable to add organization node at the moment.. Reason: [" +
              (error ? error.error.message : "request failed - permission") +
              "]",
          });
        }
      );
    }
  }

  GetMemberStatus(member: OrganizationNodeMember): string {
    if (member.level == 1) return "Head";
    if (member.level == 2) return "Deputy";
    return "Member";
  }

  RemoveNodeMember(member: OrganizationNodeMember) {
    this.confirmationService.confirm({
      message: "Are you sure you want to remove member?",
      accept: () => {
        this.messageService.add({
          severity: "info",
          summary: "Notice",
          detail: "Removing member...",
        });
        this.ResetMessageToasters();

        this.organizationService
          .RemoveOrganizationNodeMember(member.id)
          .subscribe(
            async () => {
              const index = this.allNodeMembers.indexOf(member);
              if (index > -1) {
                this.allNodeMembers.splice(index, 1);
              }

              this.messageService.add({
                severity: "success",
                summary: "Removed",
                detail: "Removed successfully",
              });
              this.ResetMessageToasters();
            },
            (error) => {
              console.log("Error: " + JSON.stringify(error));
              this.messageService.add({
                severity: "error",
                summary: "Notice",
                detail:
                  "Unable to remove node member at the moment.. Reason: [" +
                  (error
                    ? error.error.message
                    : "request failed - permission") +
                  "]",
              });
              this.ResetMessageToasters();
            }
          );
      },
    });
  }

  RemoveOrganizationNode() {
    this.confirmationService.confirm({
      message:
        "Are you sure you want to remove selected node " +
        this.organizationNodeInView.caption +
        "?",
      accept: () => {
        this.messageService.add({
          severity: "info",
          summary: "Notice",
          detail: "Removing organization node...",
        });
        this.ResetMessageToasters();

        this.organizationService
          .DeleteOrganizationNode(this.organizationNodeInView.id)
          .subscribe(
            async () => {
              this.messageService.add({
                severity: "success",
                summary: "Removed",
                detail: "Removed successfully",
              });
              this.ResetMessageToasters();

              this.OnCompanyChange();
            },
            (error) => {
              console.log("Error: " + JSON.stringify(error));
              this.messageService.add({
                severity: "error",
                summary: "Notice",
                detail:
                  "Unable to remove organization node at the moment.. Reason: [" +
                  (error
                    ? error.error.message
                    : "request failed - permission") +
                  "]",
              });
              this.ResetMessageToasters();
            }
          );
      },
    });
  }
}
