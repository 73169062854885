import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CommonResponse } from 'src/app/interfaces/home';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ResponseTeamServiceService {
baseUrl = environment.baseUrl + "ResponseTeam/";
  //baseUrl = "https://localhost:44344/api/v1/ResponseTeam/";
  constructor(private httpClient: HttpClient) { }

  //Response Team
  allResponseTeams(): Observable<CommonResponse> {
    return this.httpClient.get<CommonResponse>(
      this.baseUrl + "GetAllResponseTeams"
    );
  }
    getResponseTeam(id: any): Observable<CommonResponse> {
    return this.httpClient.get<CommonResponse>(
      this.baseUrl + "GetResponseTeamById/" + id
    );
  }
   
   postResponseTeam(data: any) {
    return this.httpClient.post(
      this.baseUrl + "AddNewResponseTeam",
      data
    );
  }
  deleteResponseTeam(id: any) {
    return this.httpClient.delete(
      this.baseUrl + "DeleteResponseTeam/" + id
    );
  }
   updateResponseTeam(id: any, data: any) {
    return this.httpClient.put(
      this.baseUrl + "UpdateResponseTeam/" + id,
      data
    );
  }

   //Response Team Type
  allResponseTeamTypes(): Observable<CommonResponse> {
    return this.httpClient.get<CommonResponse>(
      this.baseUrl + "GetAllResponseTeamTypes"
    );
  }
    getResponseTeamType(id: any): Observable<CommonResponse> {
    return this.httpClient.get<CommonResponse>(
      this.baseUrl + "GetResponseTeamTypeById/" + id
    );
  }
    
   postResponseTeamType(data: any) {
    return this.httpClient.post(
      this.baseUrl + "AddNewResponseTeamType",
      data
    );
  }
  deleteResponseTeamType(id: any) {
    return this.httpClient.delete(
      this.baseUrl + "DeleteResponseTeamTypeById/" + id
    );
  }
   updateResponseTeamType(id: any, data: any) {
    return this.httpClient.put(
      this.baseUrl + "UpdateResponseTeamTypeById/" + id,
      data
    );
  }

   //Response Team
  allSeverityLevels(): Observable<CommonResponse> {
    return this.httpClient.get<CommonResponse>(
      this.baseUrl + "GetAllSeverityLevels"
    );
  }
    getSeverityLevel(id: any): Observable<CommonResponse> {
    return this.httpClient.get<CommonResponse>(
      this.baseUrl + "GetSeverityLevelById/" + id
    );
  }
   postSeverityLevel(data: any) {
    return this.httpClient.post(
      this.baseUrl + "AddNewSeverityLevel",
      data
    );
  }
  deleteSeverityLevel(id: any) {
    return this.httpClient.delete(
      this.baseUrl + "DeleteSeverityLevel/" + id
    );
  }
   updateSeverityLevel(id: any, data: any) {
    return this.httpClient.put(
      this.baseUrl + "UpdateSeverityLevel/" + id,
      data
    );
  }
   //ResponseTeamRestionStationMap
            
  allResponseTeamRestionStationMaps(): Observable<CommonResponse> {
    return this.httpClient.get<CommonResponse>(
      this.baseUrl + "GetAllResponseTeamRestingStationMaps"
    );
  }
    getResponseTeamRestionStationMap(id: any): Observable<CommonResponse> {
    return this.httpClient.get<CommonResponse>(
      this.baseUrl + "GetResponseTeamRestingStationMapById/" + id
    );
  }
    
   postResponseTeamRestionStationMap(data: any) {
    return this.httpClient.post(
      this.baseUrl + "AddNewResponseTeamRestionStationMap",
      data
    );
  }
  deleteResponseTeamRestionStationMap(id: any) {
    return this.httpClient.delete(
      this.baseUrl + "DeleteResponseTeamRestionStationMap/" + id
    );
  }
   updateResponseTeamRestionStationMap(id: any, data: any) {
    return this.httpClient.put(
      this.baseUrl + "UpdateResponseTeamRestionStationMap/" + id,
      data
    );
  }
   getResponseTeamResourceAttachment(id: any): Observable<CommonResponse> {
    return this.httpClient.get<CommonResponse>(
      this.baseUrl + "GetAllResouceAttachmentsByTeamId/" + id
    );
  }
    postResponseTeamResourceAttachment(data: any) {
    return this.httpClient.post(
      this.baseUrl + "AddNewResponseTeamResourceAttachments",
      data
    );
  }
   MakeTeamLeadById(id: any, teamId, resourceType) {
    return this.httpClient.delete(
      this.baseUrl + "MakeTeamLeadByAttachmentId/" + id + "/" +teamId+ "/resourceType?resorceType="+resourceType
    );
  }
}
