import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { AngularFireStorage } from "@angular/fire/storage";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ConfirmationService, MessageService, SelectItem } from "primeng/api";
import { Subject } from "rxjs";
import { finalize, takeUntil } from "rxjs/operators";
import { BreadcrumbService } from "src/app/breadcrumb.service";
import {
  PilotProfile,
  SupplierServices,
  VehicleDriverMap,
  VehicleProfile,
} from "src/app/interfaces/armada";
import { CommonResponse } from "src/app/interfaces/home";
import { ResourceRegistrationService } from "src/app/services/armada/resource-registration.service";
import { VehicleTypeService } from "src/app/services/armada/vehicle-type.service";

@Component({
  selector: "app-vehicle-pilot-map",
  templateUrl: "./vehicle-pilot-map.component.html",
  styleUrls: ["./vehicle-pilot-map.component.scss"],
})
export class VehiclePilotMapComponent implements OnInit {
  @ViewChild("formWrapper") public formWrapper: ElementRef;
  private unsubscriber$ = new Subject<void>();

  public targetCols: any;
  private exportTargetColumns: any;
  public submittingData: boolean;
  public loading: boolean;
  public submitButtonLabel: string;
  sortOptions: SelectItem[];
  sortOrder: number;
  sortField: string;
  sortKey: any;

  public vehicleDriverMaps: VehicleDriverMap[];
  public selectedVehicleDriverMap: VehicleDriverMap;
  public selectedVehicleType: VehicleDriverMap;
  public vehicleProfiles: VehicleProfile[];
  public selectedVehicleProfile: VehicleProfile;
  public selectedSupplierServices: SupplierServices = null;
  public vehicleDriverMapForm: FormGroup;
  public vehicleDetailForm: FormGroup;
  selectedPilotProfile: PilotProfile | null;
  public pilotProfiles: PilotProfile[];

  showMappingCard: boolean;
  pilotRegId: Number;
  vehicleDriverMapId: Number;
  isDisbledDetach: boolean;

  constructor(
    private breadcrumbService: BreadcrumbService,

    public messageService: MessageService,
    public confirmationService: ConfirmationService,
    public fireStorage: AngularFireStorage,
    //private constants: ArmadaConstant,
    private formBuilder: FormBuilder,
    private resourceregistrationService: ResourceRegistrationService,
    private vehicleTypeService: VehicleTypeService
  ) {
    this.breadcrumbService.setItems([
      { label: "Setup" },
      {
        label: "Driver Vehicle Mapping",
        routerLink: ["/setup/vehicle-driver-mapping"],
      },
    ]);
  }

  ngOnInit(): void {
    this.loading = true;
    //Pilot
    this.targetCols = [
      { field: "identificationNumber", header: "identificationNumber" },
      { field: "serviceName", header: "serviceName" },
    ];
    this.exportTargetColumns = this.targetCols.map((col) => ({
      title: col.header,
      dataKey: col.field,
    }));
    this.vehicleDriverMapForm = this.formBuilder.group({
      registeredVehicleId: [null, Validators.required],
      registeredPilotId: [null, Validators.required],
    });
    this.vehicleDetailForm = this.formBuilder.group({
      DriverID: [null],
      name: [null],
      mobile: [null],
    });

    this.fetchAllVehicles();
    this.fetchAllPilotProfile();
  }

  fetchAllVehicles() {
    this.resourceregistrationService
      .allVehicle()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            var res = r.responseData;
            this.vehicleProfiles = res;
            console.log(this.vehicleProfiles);
            this.loading = false;
          }
        },
        (error) => {
          this.connectionError();
          this.loading = false;
          this.submittingData = false;
        }
      );
  }

  fetchAllPilotProfile(): void {
    this.resourceregistrationService
      .allPilotProfile()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            var res = r.responseData;
            this.pilotProfiles = res;
            this.pilotProfiles.forEach(
              (userProfile) =>
                (userProfile.fullName = `${userProfile.firstname} ${userProfile.lastname}`)
            );
            this.loading = false;
          }
        },
        (error) => {
          this.loading = false;
          this.connectionError();
        }
      );
    this.submittingData = false;
  }

  createVehicleDriverMap() {
    this.submittingData = true;
    const registrationFormValue = this.vehicleDriverMapForm.value;
    const formData = {
      registeredVehicleId: registrationFormValue.registeredVehicleId,
      registeredPilotId: this.selectedPilotProfile.id,
    };
    this.vehicleTypeService
      .postVehicleDrivermap(formData)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            //this.fetchCommanderUserProfile();
            this.submittingData = false;
            this.vehicleDriverMapForm.reset();
            this.showMappingCard = false;
            //this.fetchAllVehicles();
            this.messageService.add({
              severity: "success",
              detail: "New Vehicle Driver Mapping added",
              summary: "Completed",
            });
          } else {
            this.messageService.add({
              severity: "error",
              summary: "Failed",
              detail: " Error: " + r.responseMsg,
            });
            this.submittingData = false;
          }
        },
        (error) => {
          this.messageService.add({
            severity: "error",
            summary: "Failed",
            detail: "Connection Error " + error.error.message,
          });
          this.submittingData = false;
        }
      );
  }

  editVehicleProfile(vehicleProfile: VehicleProfile): void {
    // this.formWrapper.nativeElement.scrollIntoView({
    //   behavior: "smooth",
    //   block: "end",
    //   inline: "start",
    // });

    this.vehicleDetailForm.reset();
    this.selectedVehicleProfile = vehicleProfile;
    this.showMappingCard = true;
    this.vehicleDriverMapForm.patchValue({
      registeredVehicleId: vehicleProfile.id,
    });
    this.vehicleTypeService
      .getVehicleDriverMapByVehicleRegId(vehicleProfile.id)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            var res = r.responseData;
            this.isDisbledDetach = false;
            this.selectedVehicleDriverMap = res;
            console.log(res);
            this.vehicleDriverMapId = res.id;
            res.pilotRegistrationId = this.pilotRegId;
            console.log(this.vehicleDriverMapId);
            this.loading = false;
          } else if (r.responseCode == "01") {
            this.isDisbledDetach = true;
          }
        },
        (error) => {
          this.connectionError();
          this.loading = false;
          this.submittingData = false;
        }
      );
  }
  deleteVehicleDriverMap(id): void {
    this.confirmationService.confirm({
      message: "Are you sure you want to detach mapped resource?",
      accept: () => {
        this._deleteVehicleDriverMap(id);
      },
    });
  }
  _deleteVehicleDriverMap(vehicleMap) {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Detaching resource",
    });
    this.vehicleTypeService.deleteVehicleDriverMap(vehicleMap).subscribe(
      async (result: any) => {
        await this.messageService.add({
          severity: "success",
          summary: "Detached",
          detail: "Resource detached successfully",
        });
        this.vehicleDetailForm.reset();
        this.vehicleDriverMapForm.reset();
        this.showMappingCard = false;
        //await this.fetchAllVehicles();
      },
      (error) => {
        this.connectionError();
      }
    );
  }

  private connectionError() {
    this.messageService.add({
      severity: "error",
      summary: "Failed",
      detail: "Connection Error, Please try again",
    });
  }
  ngOnDestroy(): void {
    this.unsubscriber$.complete();
    this.unsubscriber$.unsubscribe();
  }
}
