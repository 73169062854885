import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import {
  CommonResponse,
  ContractService,
  UpdateContractServiceUnqueTagVM,
  UpdateContractServicesReceivingDTO,
} from "../interfaces/home";

@Injectable({
  providedIn: "root",
})
export class ContractServiceService {
  constructor(private http: HttpClient) {}
  baseUrl = environment.baseUrl + "ContractService/";

  getContractServiceByGroupInvoice(
    groupInvoiceNumber: string
  ): Observable<CommonResponse> {
    return this.http.get<CommonResponse>(
      this.baseUrl + "GroupInvoiceNumber/" + groupInvoiceNumber
    );
  }

  getContractServiceById(id: number): Observable<CommonResponse> {
    return this.http.get<CommonResponse>(this.baseUrl + id);
  }

  getContractServicesByTag(tag: any): Observable<CommonResponse> {
    return this.http.get<CommonResponse>(this.baseUrl + "ByTag/" + tag);
  }

  GetAllInactiveContractServicesByContractId(
    contractId: number
  ): Observable<CommonResponse> {
    return this.http.get<CommonResponse>(
      this.baseUrl + "GetAllInactiveContractServicesByContractId/" + contractId
    );
  }

  GetAllContractServciesByContractId(
    contractId: number
  ): Observable<CommonResponse<ContractService[]>> {
    return this.http.get<CommonResponse<ContractService[]>>(
      this.baseUrl + "GetAllContractServciesByContractId/" + contractId
    );
  }

  UpdateContractServices(
    data: UpdateContractServicesReceivingDTO
  ): Observable<CommonResponse> {
    return this.http.post<CommonResponse>(
      this.baseUrl + "UpdateContractServices",
      data
    );
  }

  UpdateContractServiceUnqueTag(
    data: UpdateContractServiceUnqueTagVM
  ): Observable<CommonResponse> {
    return this.http.post<CommonResponse>(
      this.baseUrl + "UpdateContractServiceUnqueTag",
      data
    );
  }

  GetMostRecentGroupedContractServices(
    contractId: number
  ): Observable<CommonResponse<ContractService[]>> {
    return this.http.get<CommonResponse<ContractService[]>>(
      this.baseUrl + "GetMostRecentGroupedContractServices/" + contractId
    );
  }
}
