///<reference types="@types/googlemaps" />
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/storage';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { BreadcrumbService } from 'src/app/breadcrumb.service';
import { CommonResponse, Lga, State } from 'src/app/interfaces/home';
import { RestingStation } from 'src/app/interfaces/response';
import { MasterServiceAssignmentService } from 'src/app/services/armada/master-service-assignment.service';
import { InventoryService } from 'src/app/services/inventory.service';
import { RestingStationServiceService } from 'src/app/services/response/resting-station-service.service';
import { StateService } from 'src/app/services/state.service';

@Component({
  selector: 'app-resting-stations-setup',
  templateUrl: './resting-stations-setup.component.html',
  styleUrls: ['./resting-stations-setup.component.scss']
})
export class RestingStationsSetupComponent implements OnInit {

  @ViewChild("formWrapper") public formWrapper: ElementRef;
  private unsubscriber$ = new Subject<void>();
  
  public targetCols: any;
  private exportTargetColumns: any;
  public submittingData: boolean;
  public loading: boolean;
  public submitButtonLabel: string;
 //public selectedMultiVehicles: RestingStation[] = [];

  public restingStations:RestingStation[];
  public selectedRestingStation:RestingStation;
  editingRestingStation:boolean;
  edRestingStation:RestingStation;
 public restingStationForm: FormGroup;
 allStates: State[];
 selectedState:State;
 state:State;
  allLGAs: Lga[]
   LgasToShow: Lga[] = [];
  selectedLGA: Lga;
  availableLGAs: Lga[];
locLatitude:number;
 locLongitude:number;
  locGeometry:string;
isShowAssignButton =false;

  constructor(private breadcrumbService: BreadcrumbService,
    public messageService: MessageService,
    public confirmationService: ConfirmationService,
    public fireStorage: AngularFireStorage,
    public inventoryService: InventoryService,
    private formBuilder: FormBuilder,
    private restingStationService: RestingStationServiceService,
    private serviceAssignmentService: MasterServiceAssignmentService,
    public stateService: StateService,
    ) 
    {this.breadcrumbService.setItems([
      { label: "Resting Station" },
      {
        label: "Resting Station Setup",
        routerLink: ["/home/response/resting-stations-setup"],
      },
    ]); }

  ngOnInit(): void {
     this.targetCols = [
      { field: "stationDescription", header: "stationDescription" },
      { field: "stationName", header: "stationName" },
      { field: "street", header: "street" },
      { field: "address", header: "address" },
      { field: "lgaId", header: "lgaId" },
      { field: "stateId", header: "stateId" },
      { field: "latitude", header: "latitude" },
      { field: "longitude", header: "longitude" },
    
    ];
    this.exportTargetColumns = this.targetCols.map((col) => ({
      title: col.header,
      dataKey: col.field,
    }));
    this.restingStationForm = this.formBuilder.group({
      stationDescription: ["", [Validators.required, Validators.minLength(3)]],
      stationName: ["", [Validators.required, Validators.minLength(3)]],
       street: ["", [Validators.required, Validators.minLength(5)]],
      address: [""],
      lgaId: [null, [Validators.required]],
      stateId: [null, [Validators.required]],//[Validators.required]
      latitude: [0], //[Validators.required]
      longitude: [0 ],//[Validators.required]
      imageUrl: [""],
    });
    this.fetchAllRestingStations();
    this.fetchAllStates();
   // this.GetCurrentLocation();
    this.fetchAllLGAs();
  }
  ngAfterViewInit(): void {
    // Load google maps script after view init
    const DSLScript = document.createElement("script");
    DSLScript.src =
      "https://maps.googleapis.com/maps/api/js?key=AIzaSyCQuetprs2UHb_zKSXznyumyfvw95ERCDY"; // replace by your API key
    DSLScript.type = "text/javascript";
    document.body.appendChild(DSLScript);
    document.body.removeChild(DSLScript);

    //this.initMap();
  }
   setLGAs() {
    this.availableLGAs = [];
    this.fetchStatesById(this.selectedState.id);
    console.log(this.allLGAs);
  }
   fetchAllStates() {
    this.stateService
      .allState()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe((r: CommonResponse) => {
        if (r.responseCode == "00") {
          var res = r.responseData;
          this.allStates = res;
          this.loading=false;
          console.log(res);
        }
         else {
            this.messageService.add({
              severity: "error",
              summary: "Failed",
              detail: " Error: " + r.responseMsg,
            });
            this.submittingData = false;
          }
      });
  }
  fetchStatesById(id) {
    this.stateService
      .getByID(id)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe((r: CommonResponse) => {
        if (r.responseCode == "00") {
          var res = r.responseData;
          this.state = res;
          this.allLGAs = this.state.lgAs
          this.loading=false;
          console.log(this.state.lgAs);
        }
         else {
            this.messageService.add({
              severity: "error",
              summary: "Failed",
              detail: " Error: " + r.responseMsg,
            });
            this.submittingData = false;
          }
      });
  }
  fetchAllLGAs() {
    this.stateService
      .allLgas()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe((r: CommonResponse) => {
        if (r.responseCode == "00") {
          var res = r.responseData;
          this.allLGAs = res;
          this.loading=false;
          console.log(res);
        }
         else {
            this.messageService.add({
              severity: "error",
              summary: "Failed",
              detail: " Error: " + r.responseMsg,
            });
            this.submittingData = false;
          }
      });
  }
  fetchAllRestingStations() {
    this.loading=true;
    this.restingStationService
      .allRestingStations()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe((r: CommonResponse) => {
        if (r.responseCode == "00") {
          var res = r.responseData;
          this.restingStations = res;
          this.loading=false;
          console.log(res);
        }
         else {
            this.messageService.add({
              severity: "error",
              summary: "Failed",
              detail: " Error: " + r.responseMsg,
            });
            this.loading=false;
            this.submittingData = false;
          }
      });
  }
   createRestingStation() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Creating record...",
    });
    this.submittingData = true;
    const formData = this.restingStationForm.value;
    const postData ={
      stationDescription: formData.stationDescription,
      stationName: formData.stationName,
      stateId: this.selectedState.id,
      lgaId: this.selectedLGA.id,
      street: formData.street,
      //address: formData.address,
      address: formData.street +
      "," +
      this.selectedLGA.name +
      "," +
      this.selectedState.name,
      latitude: this.locLatitude,
      longitude: this.locLongitude,
      imageUrl: "",
    }
    this.restingStationService
      .postRestingStation(postData)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            this.messageService.add({
              summary: "Success",
              severity: "success",
              detail: "New station created",
            });
            this.fetchAllRestingStations();
            this.submittingData = false;
            this.restingStationForm.reset();
            this.isShowAssignButton = false;
          } else {
            this.messageService.add({
              severity: "error",
              summary: "Failed",
              detail: " Error: " + r.responseMsg,
            });
            this.submittingData = false;
          }
        },
        (error) => {
          this.messageService.add({
            severity: "error",
            summary: "Failed",
            detail: "Connection Error " + error.error.message,
          });
          this.submittingData = false;
        }
      );
  }
  deleteRestingStation(restingStation: RestingStation): void {
    this.confirmationService.confirm({
      message: "Are you sure you want to delete " + restingStation.stationName,
      accept: () => {
        this._deleteRestingStation(restingStation);
      },
    });
  }
   _deleteRestingStation(restingStation) {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Deleting station record",
    });
    this.restingStationService.deleteRestingStation(restingStation.id).subscribe(
      async (result: any) => {
        await this.messageService.add({
          severity: "success",
          summary: "Deleted",
          detail: "Station record removed",
        });
        await this.fetchAllRestingStations();
      },
      (error) => {
        this.connectionError();
      }
    );
  }
  editRestingStation(restingStations: RestingStation): void {
    this.editingRestingStation = true;
    console.log(restingStations);
    this.formWrapper.nativeElement.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "start",
    });
    //this.submitButtonLabel = "Update";
    this.edRestingStation = restingStations;
    this.restingStationForm.setValue({
      stationDescription: restingStations.stationDescription,
      stationName: restingStations.stationName,
      stateId: restingStations.stateId,
      lgaId: restingStations.lgaId,
      street: restingStations.street,
      address: restingStations.address,
      latitude: restingStations.latitude,
      longitude: restingStations.longitude,
      imageUrl: "",
    });
    this.selectedLGA = this.allLGAs.find(
      (lga) => lga.id === restingStations.lgaId
    );
     this.selectedState = this.allStates.find(
      (state) => state.id === restingStations.stateId
    );
  }

  updateRestingStation(): boolean {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Updating record...",
    });
    this.submittingData = true;
    if (!this.edRestingStation) {
      return false;
    }
    const formData = this.restingStationForm.value;
     const data ={
      stationDescription: formData.stationDescription,
      stationName: formData.stationName,
      stateId: this.selectedState.id,
      lgaId: this.selectedLGA.id,
      street: formData.street,
      address: formData.address,
      latitude: formData.latitude,
      longitude: formData.longitude,
      imageUrl: "",
    }
    this.restingStationService
      .updateRestingStation(this.edRestingStation.id, data)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            this.messageService.add({
              severity: "success",
              detail: "Station Updated",
              summary: "Completed",
            });
            this.submittingData = false;
            this.closeEditing();
            this.fetchAllRestingStations();
            this.restingStationForm.reset();
          }
        },
        (error) => {
          this.connectionError();
          this.submittingData = false;
        }
      );
  }
    
   private connectionError() {
    this.messageService.add({
      severity: "error",
      summary: "Failed",
      detail: "Connection Error, Please try again",
    });
  }

   ConfirmLocationCordinates() {
    // this.openConfirmLocationDialogue = true;
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Getting Location",
    });

    let pickOffaddress: string =
      this.restingStationForm.get("street").value +
      "," +
      this.selectedLGA.name +
      "," +
      this.selectedState.name;

    this.GetPickOffInputtedLocationCordinates(pickOffaddress);
  }

  GetPickOffInputtedLocationCordinates(address: string) {
    this.serviceAssignmentService
      .GetStartAddressGeocodingLocation(address)
      .subscribe(
        async (data) => {
          if (data.status === "OK") {
            this.messageService.add({
              severity: "success",
              detail: "Successful",
              summary: "Completed",
            });
          } else {
            this.messageService.add({
              severity: "error",
              summary: "Notice",
              detail:
                "Unable to get address cordinates at the moment.. " +
                data.error_message,
            });
            return;
          }

          this.locLatitude = data.results[0].geometry.location.lat;
          this.locLongitude = data.results[0].geometry.location.lng;
          this.locGeometry = data.results[0].formatted_address;
          this.initMap();
          console.log(
            this.locLatitude +
              " and " +
              this.locLongitude +
              " geo " +
              this.locGeometry
          );
          this.submitButtonLabel = "Create";
          this.isShowAssignButton = true;
        },
        (error) => {
          console.log("Error: " + JSON.stringify(error));
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail:
              "Unable to get address cordinates at the moment.. Reason: [" +
              error.error.message +
              "]",
          });
        }
      );
  }

   initMap(): void {
    if (
      this.locLatitude &&
      this.locLongitude 
    ) {
      // The location of pickup
      const location = { lat: this.locLatitude, lng: this.locLongitude };
      
      // The map, centered at pickup Loc
      const map = new google.maps.Map(
        document.getElementById("map") as HTMLElement,
        {
          zoom: 8,
          center: location,
        }
      );
      // The marker, positioned at Loc
      const marker = new google.maps.Marker({
        position: location,
        map: map,
      });

      // Calculate and display the distance between markers
      // const distance = this.haversine_distance(marker, dropmarker);
      // const stringToconv = distance.toFixed(2);
      // this.distance = Number(stringToconv) * 1.609;
      // console.log(this.distance + " Km ");
    }
  }
   async GetCurrentLocation() {
    var uri =
      "https://www.googleapis.com/geolocation/v1/geolocate?key=AIzaSyCQuetprs2UHb_zKSXznyumyfvw95ERCDY";
    var xmlhttp = new XMLHttpRequest();
    xmlhttp.onreadystatechange = function () {
      if (xmlhttp.readyState == 4 && xmlhttp.status == 200) {
        let result = JSON.parse(xmlhttp.responseText);
        localStorage.setItem("longitude", result.location.lng);
        localStorage.setItem("latitude", result.location.lat);
        //localStorage.setItem("address", result.results[0].formatted_address);

        console.log(
          `current longitude ${result.location.lng} and current latitude ${result.location.lat} address ${result.formatted_address}`
        );
        //document.getElementById("btnVerifyGeo").click();
      } else if (xmlhttp.status == 404) {
        alert(
          "Unable to get current location cordinates = " + xmlhttp.status + "."
        );
      }
    };
    xmlhttp.open("POST", uri, true);
    xmlhttp.send();
    xmlhttp.onerror = function () {
      alert(
        "Unable to get current location cordinates = " +
          xmlhttp.statusText +
          "."
      );
    };
    this.locLatitude = Number(localStorage.getItem("latitude"));
    this.locLongitude = Number(localStorage.getItem("longitude"));
    //sconsole.log("For Lats and Lons: ", this.currectLat + " " + this.currentlon);
  }
  get StreetValue() {
    return this.restingStationForm.get("street").value;
  }

  get LGAValue() {
    return this.selectedLGA ? this.selectedLGA.name : "";
  }

  get StateValue() {
    return this.selectedState ? this.selectedState.name : "";
  }

   closeEditing() {
    this.editingRestingStation = false;
    this.restingStationForm.reset();
    //this.edTeamType = null;

    this.submittingData = false;
    this.submitButtonLabel = "Create";
  }

}
