import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {Observable} from 'rxjs';
import {CommonResponse} from '../interfaces/home';
import { shareReplay } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class GroupTypeService {
  gtobservable: Observable<CommonResponse>;
  constructor(private http: HttpClient) { }
  baseUrl = environment.baseUrl+'GroupType/';
  // fetch all GroupType in the database
  allGroupTypeData(): Observable<CommonResponse>{
    if(this.gtobservable) return this.gtobservable
    else {
      this.gtobservable = this.http.get<CommonResponse>(this.baseUrl).pipe(shareReplay()); 
      return this.gtobservable;
    }
  }
  getGroupType(id: any):Observable<CommonResponse>{
    return this.http.get<CommonResponse>(this.baseUrl +id);
  }
  getGroupTypeByName(name: any):Observable<CommonResponse>{
    return this.http.get<CommonResponse>(this.baseUrl + 'name/' + name);
  }
  postGroupType(data: any){
    return this.http.post(this.baseUrl, data);
  }
  deleteGroupType(id: any){
    return this.http.delete(this.baseUrl +id);
  }
  updateGroupType(id: any, data: any){
    return this.http.put(this.baseUrl +id, data);
  }
}
