///<reference types="@types/googlemaps" />

import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { ConfirmationService, MessageService } from "primeng/api";
import { BreadcrumbService } from "src/app/breadcrumb.service";
import {
  CreateTrainingFacilityVM,
  GMATrainingFacility,
} from "src/app/interfaces/employment";
import { GMAGenericResponse, Lga, State } from "src/app/interfaces/home";
import { GmaEmploymentService } from "src/app/services/gma-employment.service";
import { UserService } from "src/app/services/user.service";

@Component({
  selector: "app-gma-training-facility",
  templateUrl: "./gma-training-facility.component.html",
  styleUrls: ["./gma-training-facility.component.scss"],
})
export class GmaTrainingFacilityComponent implements OnInit {
  @ViewChild("formWrapper") public formWrapper: ElementRef;
  fetchingFacilities: boolean = true;
  allTrainingFacilities: GMATrainingFacility[];
  selectedFacilities: GMATrainingFacility[];
  openAddFacilDialogue: boolean = false;
  allStates: State[] = [];
  theState: State;
  allLGAS: Lga[];
  theLGA: Lga;
  facilAddress: string;
  facilCaption: string;
  facilDescription: string;
  showLocationDialogue: boolean = false;
  locLongitude: number;
  locLatitude: number;
  locGeometry: string;
  locationDoneLoading: boolean = false;
  isCreatingTrainingFacility: boolean = false;
  cols: any[];

  constructor(
    fb: FormBuilder,
    public employmentService: GmaEmploymentService,
    public userService: UserService,
    private breadcrumbService: BreadcrumbService,
    public confirmationService: ConfirmationService,
    public messageService: MessageService
  ) {}

  ngOnInit(): void {
    this.breadcrumbService.setItems([
      {
        label: "Guard Management",
        routerLink: ["/home/guard-management/item-settings"],
      },
      {
        label: "Add Training Facility",
        routerLink: ["/home/guard-management/training-facility"],
      },
    ]);

    this.cols = [
      { field: "caption", header: "Caption" },
      { field: "description", header: "Description" },
      { field: "createdBy.lastName", header: "CreatedBy.lastName" },
      { field: "street", header: "Street" },
    ];

    this.FetchAllStates();
    this.FetchAllTrainingFacilities();
  }

  ngAfterViewInit(): void {
    // Load google maps script after view init
    const DSLScript = document.createElement("script");
    DSLScript.src =
      "https://maps.googleapis.com/maps/api/js?key=AIzaSyCQuetprs2UHb_zKSXznyumyfvw95ERCDY"; // replace by your API key
    DSLScript.type = "text/javascript";
    document.body.appendChild(DSLScript);
    document.body.removeChild(DSLScript);
  }

  FetchAllStates() {
    this.employmentService.GetAllStates().subscribe(
      async (data) => {
        this.allStates = data;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all states at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  FetchAllTrainingFacilities() {
    this.employmentService.GetAllTrainingFacilities().subscribe(
      async (data) => {
        this.allTrainingFacilities = data;
        this.fetchingFacilities = false;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail: error
            ? "Unable to get all training facility at the moment.. Reason: [" +
              (error ? error.error.message : "request failed - permission") +
              "]"
            : "An error occurred",
        });
        this.fetchingFacilities = false;
      }
    );
  }

  OpenAddFacilDialogue() {
    this.theState = null;
    this.theLGA = null;
    this.allLGAS = [];
    this.facilCaption = null;
    this.facilDescription = null;
    this.facilAddress = null;
    this.locGeometry = null;
    this.locLatitude = null;
    this.locLongitude = null;
    this.showLocationDialogue = false;
    this.locationDoneLoading = false;
    this.isCreatingTrainingFacility = false;
    this.openAddFacilDialogue = true;
  }

  HideFacilDialog() {
    this.openAddFacilDialogue = false;
    this.theState = null;
    this.theLGA = null;
    this.facilCaption = null;
    this.facilDescription = null;
    this.facilAddress = null;
    this.locGeometry = null;
    this.locLatitude = null;
    this.locLongitude = null;
    this.showLocationDialogue = false;
    this.locationDoneLoading = false;
    this.isCreatingTrainingFacility = false;
    this.locationDoneLoading = false;
    this.allLGAS = [];
  }

  PopulateLGAS() {
    this.showLocationDialogue = this.theState ? true : false;
    this.allLGAS = this.theState.lgas;
    this.ResetLocationConfirmation();
  }

  CheckFacilityLocation() {
    let address: string =
      this.facilAddress + "," + this.theLGA.name + "," + this.theState.name;
    this.GetInputtedLocationCordinates(address);
  }

  GetInputtedLocationCordinates(address: string) {
    this.employmentService.GetAddressGeocodingLocation(address).subscribe(
      async (data) => {
        if (data.status != "OK") {
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail:
              "Unable to get address cordinates at the moment.. " +
              data.error_message,
          });
          return;
        }
        this.locLatitude = data.results[0].geometry.location.lat;
        this.locLongitude = data.results[0].geometry.location.lng;
        this.locGeometry = data.results[0].formatted_address;
        this.initMap();
        this.locationDoneLoading = true;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to get address cordinates at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  // Initialize and add the map
  initMap(): void {
    if (this.locLatitude && this.locLongitude) {
      // The location of Loc
      const location = { lat: this.locLatitude, lng: this.locLongitude };
      // The map, centered at Loc
      const map = new google.maps.Map(
        document.getElementById("map") as HTMLElement,
        {
          zoom: 4,
          center: location,
        }
      );

      // The marker, positioned at Loc
      const marker = new google.maps.Marker({
        position: location,
        map: map,
      });
    }
  }

  ResetLocationConfirmation() {
    this.showLocationDialogue = false;
    this.locationDoneLoading = false;
    this.showLocationDialogue = true;
  }

  SaveFacilityLocation() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Creating Training Facility...",
    });

    this.isCreatingTrainingFacility = true;

    const postData: CreateTrainingFacilityVM = {
      caption: this.facilCaption,
      description: this.facilDescription,
      street: this.facilAddress,
      stateId: this.theState.id,
      lgaId: this.theLGA.id,
      geometry: this.locGeometry,
      longitude: this.locLongitude,
      latitude: this.locLatitude,
    };

    this.employmentService.CreateTrainingFacility(postData).subscribe(
      async (data: GMAGenericResponse) => {
        if (data.responseCode != "00") {
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail: data.responeMessage,
          });

          this.locationDoneLoading = false;
          this.isCreatingTrainingFacility = false;
          return;
        }

        this.messageService.add({
          severity: "success",
          summary: "Completed",
          detail: "Training Location Created...",
        });

        this.HideFacilDialog();
        this.fetchingFacilities = true;
        this.FetchAllTrainingFacilities();
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail: error
            ? "Unable to create training facility at the moment.. Reason: [" +
              (error ? error.error.message : "request failed - permission") +
              "]"
            : "An error occurred",
        });
        this.locationDoneLoading = false;
        this.isCreatingTrainingFacility = false;
      }
    );
  }

  RemoveFacility(item: GMATrainingFacility) {
    this.confirmationService.confirm({
      message: "Are you sure you want to remove training facility?",
      accept: () => {
        this.messageService.add({
          severity: "info",
          summary: "Notice",
          detail: "Removing training facility...",
        });

        this.employmentService.RemoveTrainingFacility(item.id).subscribe(
          async () => {
            this.messageService.add({
              severity: "success",
              summary: "Removed",
              detail: "Removed successfully",
            });

            this.fetchingFacilities = true;
            const index = this.allTrainingFacilities.indexOf(item);
            if (index > -1) {
              this.allTrainingFacilities.splice(index, 1);
            }
            this.fetchingFacilities = false;
          },
          (error) => {
            console.log("Error: " + JSON.stringify(error));
            this.messageService.add({
              severity: "error",
              summary: "Notice",
              detail: error
                ? "Unable to remove training facility at the moment.. Reason: [" +
                  error
                  ? error.error.message
                  : "request failed - permission" + "]"
                : "An error occurred",
            });
          }
        );
      },
    });
  }

  GetStateLabel(stateId: number): string {
    let state = this.allStates.find((x) => x.id == stateId);
    if (state) return state.name;

    return "N/A";
  }

  GetLGALabel(stateId: number, lgaId: number): string {
    let state = this.allStates.find((x) => x.id == stateId);
    if (state) {
      let lga = state.lgas.find((x) => x.id == lgaId);
      if (lga) return lga.name;
    }

    return "N/A";
  }
}
