import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Subscription } from 'rxjs';
import { BreadcrumbService } from 'src/app/breadcrumb.service';
import { BudgetapiservicesService } from 'src/app/services/budget/budgetapiservices.service';
import { ConfirmEventType } from 'src/app/interfaces/armada';

@Component({
  selector: 'app-show-budget-cycles',
  templateUrl: './show-budget-cycles.component.html',
  styleUrls: ['./show-budget-cycles.component.css']
})
export class ShowBudgetCyclesComponent implements OnInit, OnDestroy {

  ref: DynamicDialogRef;
  // @Input() budgetCycle: any;
  budgetCycles: any=[] = []
  budgetSeasons: any=[] = []
  ModalTitle = "";
  budgetCycle: any;
  visible: boolean;
  editRecord: boolean = false;
  loadingData: boolean = true;
  isFormBusy: boolean = false;
  header = ""
  
  ActivateUpsertBudgetCycle: boolean;
  budgetCycleSubscription: Subscription | undefined;
  budgetSeasonSubscription: Subscription | undefined;

  constructor(private service: BudgetapiservicesService, 
              private messageService: MessageService, 
              private router: Router,
              private route: ActivatedRoute,
              private confirmationService: ConfirmationService,
              public dialogService: DialogService,
              private breadcrumbService: BreadcrumbService ) { }

  ngOnInit(): void {
    this.breadcrumbService.setItems([
      { label: "Budget Cycle Page", routerLink: ["home/budget-cycle"] },
      // { label: "Role", routerLink: ["home/role"] },
    ]);
    // this.budgetCycleSubscription = this.loadBudgetCycles();
    this.budgetSeasonSubscription = this.loadBudgetSeasons();
  }

  ngOnDestroy(): void {
    // this.allBudgetCats.unsubscribe();
    // this.budgetCycleSubscription.unsubscribe();
    this.budgetSeasonSubscription.unsubscribe();
  }


  displayMessage(severity, summary, message) {
    this.messageService.add({
      severity: severity,
      summary: summary,
      detail: message,
    });
  }
  
  loadBudgetCycles(){
    return this.service.getBudgetCycleList().subscribe(data => {
      this.budgetCycles = data['responseData'];
      console.log(this,this.budgetCycles)
      this.loadingData = false;
      console.log(data['responseData']);
    }, (err) => {
      this.displayMessage("Info", "Information", err);
      this.loadingData = false;
    })
  }

  loadBudgetSeasons() {
    this.isFormBusy = true;
    return this.service.getBudgetSeasons().subscribe(data => {
      this.budgetSeasons = data['responseData'];

      this.budgetSeasons = this.budgetSeasons.filter((element, ind) => {
        // if (element.isPublished)
        // {
        //   console.log(element.isPublished);
        //   return element;
        //   //this.budgetSeasons.splice(ind, 1);
        // }
        return element.isPublished ; //this.budgetSeasons;
      });
      this.isFormBusy = false;
      console.log(data['responseData'][0]);
      console.log(this.budgetSeasons);
      this.loadingData = false;

    }, err => {
      this.loadingData = false;
      this.displayMessage("error", "Error", "Error Loading record");
    });
  }

  CreateBudgetCycle() {
    if (this.isFormBusy)
        return false;
      this.ModalTitle = "Create Budget Cycle";
      this.header = "Create Budget Cycle";
      this.ActivateUpsertBudgetCycle = true;
      this.visible = true;
      this.editRecord = false;
      this.budgetCycle = {
        id: "0",
        seasonId: "",
        caption: "",
        description: "",
        startDate: "",
        endDate: "",
        budgetYear: "",
        endorsementTypeId: "",
        endorsementType: "",
        baseValue: "",
        currencyId: "",
        benchMarkValue: "",
        isDeleted: ""
      }
  }
  isBudgetCycleActive(cycle){
    // console.log(season.prepEndDate)
    return new Date(cycle.endDate).valueOf() < new Date().valueOf();
  }
  editCycle(budgetItem: any, budgetSeason: any) {
    this.budgetCycle = budgetItem;
    this.budgetCycle.budgetSeason = budgetSeason;
    this.loadingData = true;
    this.service.checkBudgetCycleB4Edit(budgetItem.id).subscribe(data => {
      console.log(data);
      if (data.responseCode == "00")
      {
        this.header = "Edit Budget Cycle";
        this.ActivateUpsertBudgetCycle = true;
        this.editRecord = true;
        this.visible = true;
        console.log(this.budgetCycle);
      }
      else if (data.responseCode == "13")
      {
        setTimeout(() => {
          this.displayMessage("warn", "Warning", "Budget Cycle cannot be edited because it has either been sent for review or approved.");
          
          return;
        }, 1000);
        
      }
      
    }, (err) => {
      this.displayMessage("error", "Error", `Error checking record: status\n${err.message}`);
      this.loadingData = false;
      this.isFormBusy = false;
      console.log(err)
    }, () => {
      this.loadingData = false;
    }); 
    
  }

  
  deleteCycle(item) {
    // console.log(item)
    this.confirmationService.confirm({
        message: `Are you sure that you want to proceed with deleting this record?`,
        header: 'Confirmation',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          // this.messageService.add({ severity: 'info', summary: 'Confirmed', detail: 'You have accepted' });
          // this.checkIfHasDistributions();
          this.loadingData = true;
          setTimeout(() => {
            this.displayMessage("info", "Information", "Processing delete operation")
            this.isFormBusy = true;
          }, 1000);
          
          this.service.deleteBudgetCycle(item.id).subscribe(data => {
            // alert(data.toString());
            console.log(data);
            if (data.responseCode == "00")
            {
              setTimeout(() => {
                this.displayMessage("success", "Success", "Delete Successful")
                this.loadingData = false;
                this.isFormBusy = false;
              }, 1000);
              
              setTimeout(() => {
                this.reloadPage();
              }, 3000);
            }
            else if (data.responseCode == "13")
            {
              setTimeout(() => {
                this.displayMessage("error", "Error", "Cannot delete record because it has distributions assigned.");
                this.loadingData = false;
                this.isFormBusy = false;
                return;
              }, 1000);
              
            }
            
          }, (err) => {
            this.displayMessage("error", "Error", `Error deleting record:\n${err.message}`);
            this.loadingData = false;
            this.isFormBusy = false;
            console.log(err)
          });  
        },
        reject: (type: ConfirmEventType) => {
            switch (type) {
                case ConfirmEventType.REJECT:
                    this.messageService.add({ severity: 'warn', summary: 'Cancelled', detail: 'You have cancelled this operation' });
                    break;
                case ConfirmEventType.CANCEL:
                    this.messageService.add({ severity: 'warn', summary: 'Cancelled', detail: 'You have cancelled this operation' });
                    break;
            }
          }
    });
  }
  checkIfHasDistributions() {
    throw new Error('Method not implemented.');
  }

  
  deleteCycleOld(item) {
    
    if (confirm('Are you sure??')) {
      console.log(item);
      console.log('deleted');
      console.log(this.router.url);
      // setTimeout(() => {
      //   this.displayMessage("success", "Success", "Successful delete of budget cycle")
      // }, 2000);
      // this.reloadPage();
      // window.location.reload();
      // this.router.navigate([this.router.url])  
      this.service.deleteBudgetCycle(item.id).subscribe(data => {
        // alert(data.toString());
        console.log(data);
        setTimeout(() => {
          this.displayMessage("success", "Success", "Delete Successful")
        }, 2000);
        this.reloadPage();
      }, (err) => {
        this.displayMessage("error", "Error", `Error deleting record:\n${err.message}`);
        // this.loadingData = false;
        console.log(err)
      });
    }
    else {
      this.displayMessage("info", "Information", "Delete Cancelled")
    }
  }

  closeModal() {
    // console.log('closing....');
    this.ActivateUpsertBudgetCycle = false;
    this.visible = false;
    this.loadBudgetCycles();
    // this.reloadPage();
   
  }

  reloadPage(){
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate(['./'], {
      relativeTo: this.route
    });
  }

}
