import { PriceRegisterService } from "./../../../../services/armada/price-register.service";
import { PreferentialPricing } from "./../../../../interfaces/armada";
///<reference types="@types/googlemaps" />
import { ElementSchemaRegistry, ThisReceiver } from "@angular/compiler";
import {
  AfterViewInit,
  AfterViewChecked,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { th } from "date-fns/locale";
import { ConfirmationService, Message, MessageService } from "primeng/api";
import { DialogService, DynamicDialogRef } from "primeng/dynamicdialog";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { BreadcrumbService } from "src/app/breadcrumb.service";
import {
  ArmedEscortProfile,
  ArmedEscortServiceAssignmentDetail,
  CommanderProfile,
  CommanderServiceAssignmentDetail,
  MasterServiceAssignment,
  Passenger,
  PassengerType,
  PilotProfile,
  PilotServiceAssignmentDetail,
  ServiceAssignment,
  ServiceRegistration,
  SMORegion,
  SMORoute,
  SMORouteAndSateMap,
  SourceType,
  VehicleProfile,
  VehicleServiceAssignmentDetail,
  adhovVal,
} from "src/app/interfaces/armada";
import {
  CommonResponse,
  Contract,
  ContractService,
  CustomerDivision,
  Lga,
  State,
} from "src/app/interfaces/home";
import { MasterServiceAssignmentService } from "src/app/services/armada/master-service-assignment.service";
import { ResourceRegistrationService } from "src/app/services/armada/resource-registration.service";
import { ServiceAssignmentDetailService } from "src/app/services/armada/service-assignment-detail.service";
import { ServiceRegistrationService } from "src/app/services/armada/service-registration.service";
import { SMORouteService } from "src/app/services/armada/smoroute.service";
import { TypesForServiceAssignmentService } from "src/app/services/armada/types-for-service-assignment.service";
import { ContractServiceService } from "src/app/services/contract-service.service";
import { CustomerDivisionService } from "src/app/services/customer-division.service";
import { StateService } from "src/app/services/state.service";
import { JmpInvoiceComponent } from "./jmp-invoice/jmp-invoice.component";
import { InvoiceService } from "src/app/services/invoice.sevice";
//declare var google: any;

@Component({
  selector: "app-master-service-assignment",
  templateUrl: "./master-service-assignment.component.html",
  styleUrls: ["./master-service-assignment.component.scss"],
})
export class MasterServiceAssignmentComponent
  implements OnInit, OnDestroy, AfterViewInit, AfterViewChecked {
  @ViewChild("formWrapper") public formWrapper: ElementRef;
  private unsubscriber$ = new Subject<void>();

  public targetCols: any;
  public escortTargetCols: any;
  public pilotTargetCols: any;
  public commanderTargetCols: any;
  public vehicleTargetCols: any;
  public scheduleTargetCols: any;
  private exportTargetColumns: any;
  private exportScheduleTargetColumns: any;
  public submittingData: boolean;
  public loading: boolean;
  public loading_: boolean;
  public submitButtonLabel: string;
  public submitVehicleButtonLabel: string;
  public submitCommanderButtonLabel: string;
  options: any;
  overlays: any[];
  vehicleProductDialog: boolean;
  commanderProductDialog: boolean;
  closedProductDialog: boolean;
  armedEscortProductDialog: boolean;
  pilotProductDialog: boolean;
  passengerProductDialog: boolean;
  viewAssignmentDetailDialog: boolean;
  expiredServiceProductDialog: boolean;
  viewInvoiceRef: DynamicDialogRef;
  isShowAssignButton: boolean;

  //ServiceAssignment
  public masterServiceAssignments: MasterServiceAssignment[];
  public serviceAssignmentForm: FormGroup;
  selectedmasterServiceAssignment: any;
  selectedmasterServiceAssignment2: MasterServiceAssignment;
  editingmasterServiceAssignment = false;
  private edmasterServiceAssignment: MasterServiceAssignment;

  public scheduledServiceAssignments: MasterServiceAssignment[];
  public scheduledServiceAssignments1: MasterServiceAssignment[];
  public scheduledServiceAssignments2: MasterServiceAssignment[];
  //public MasterServiceAssignments: MasterServiceAssignment[];

  selectedScheduledServiceAssignment: any;

  //ServiceAssignmentDetail
  //Escort
  public armedEscortServiceAssignmentDetails: ArmedEscortServiceAssignmentDetail[];
  public armedEscortServiceAssignmentDetailsFilter: ArmedEscortServiceAssignmentDetail[];
  public armedEscortServiceAssignmentDetailForm: FormGroup;
  public armedEscortServiceAssignmentDetailReplaceForm: FormGroup;
  selectedArmedEscortServiceAssignmentDetail: any;
  editingArmedEscortServiceAssignmentDetail = false;
  private edArmedEscortServiceAssignmentDetail: ArmedEscortServiceAssignmentDetail;
  //Commander
  public commanderServiceAssignmentDetails: CommanderServiceAssignmentDetail[];
  public commanderServiceAssignmentDetailsFilter: CommanderServiceAssignmentDetail[];
  public commanderServiceAssignmentDetailForm: FormGroup;
  public commanderServiceAssignmentDetailReplaceForm: FormGroup;
  selectedCommanderServiceAssignmentDetail: any;
  editingCommanderServiceAssignmentDetail = false;
  private edCommanderServiceAssignmentDetail: CommanderServiceAssignmentDetail;
  //Pilot
  public pilotServiceAssignmentDetails: PilotServiceAssignmentDetail[];
  public pilotServiceAssignmentDetailsFilter: PilotServiceAssignmentDetail[];
  public pilotServiceAssignmentDetailForm: FormGroup;
  public pilotServiceAssignmentDetailReplaceForm: FormGroup;
  selectedPilotServiceAssignmentDetail: any;
  editingPilotServiceAssignmentDetail = false;
  private edPilotServiceAssignmentDetail: PilotServiceAssignmentDetail;
  //vehicle
  public vehicleServiceAssignmentDetails: VehicleServiceAssignmentDetail[];
  public vehicleServiceAssignmentDetailsFilter: VehicleServiceAssignmentDetail[];
  public vehicleServiceAssignmentDetailForm: FormGroup;
  public vehicleServiceAssignmentDetailReplaceForm: FormGroup;
  selectedVehicleServiceAssignmentDetail: any;
  editingVehicleServiceAssignmentDetail = false;
  private edVehicleServiceAssignmentDetail: VehicleServiceAssignmentDetail;

  public SMORoutes: SMORoute[];
  public SMORegions: SMORegion[];
  selectedSMORoute: SMORoute;
  availableSMORoutes: SMORoute[];
  selectedSMORegion: SMORegion;
  isFroState: boolean = true;
  isToState: boolean = true;
  public SourceTypes: SourceType[];
  public customerDivisions: CustomerDivision[];
  selectedSourceType: SourceType;
  selectedCustomerDivision: CustomerDivision;
  public contractServices: ContractService[];
  selectedContractService: ContractService;
  public SecserviceRegistrations: ServiceRegistration[];
  public selectedMultiServices: ServiceRegistration[] = [];
  //public selectedMultiState: State[] = [];
  public contracts: Contract[];
  selectedContracts: Contract;
  avalableContracts: Contract[];
  availableContractServices: ContractService[];
  public serviceRegistrations: ServiceRegistration[];
  selectedserviceRegistration: ServiceRegistration;
  public preferentialPricing: PreferentialPricing[];
  public selectedPreferentialPricing: PreferentialPricing;
  isAdhoc: boolean;
  maxDateOfBirth = new Date();
  minDate = new Date();
  minDate_ = new Date();
  selectedDate;
  selectedTime;
  //maxDate = this.minDate.setHours(this.minDate.getHours() + 4);
  maxiDate = new Date();
  isForSchedule: boolean;
  isForReturnRoute: boolean;
  isRequireReturnRoute: boolean;
  isScheduleSwitchFlag: boolean;
  todays = new Date();
  todayminus24hr = new Date();
  getVat: number = 0;
  quantityAdoc: number = 1;
  isReturnedRoute: boolean;
  //Passenger
  public passengers: Passenger[];
  selectedPassenger: any;
  // public passengerTypes: PassengerType[];
  // selectedPassengerType: any;
  public passenger: Passenger;
  public passengerForm: FormGroup;

  editingPassenger = false;
  showResourceAllocation = false;
  private edPassenger: Passenger;
  isReturnSlider: boolean;
  //Armed Escort
  public armedEscortProfiles: ArmedEscortProfile[];
  selectedArmedEscortProfile: ArmedEscortProfile;
  //Pilot
  selectedPilotProfile: PilotProfile | null;
  public pilotProfiles: PilotProfile[];
  //public editingPilotProfile: boolean;
  //private edPilotProfile: PilotProfile;

  //Commander
  public commanderProfiles: CommanderProfile[];
  selectedCommanderProfile: CommanderProfile;

  //Vehicle
  public vehicleProfiles: VehicleProfile[];
  public selectedVehicleProfile: VehicleProfile;
  //public selectedSupplierServices: SupplierServices = null;
  public passengerTypes: PassengerType[];
  selectedPassengerType: any;
  minimumAge: number;
  public yearRange: string;
  msgs: Message[] = [];
  sMORouteAndStateMaps: SMORouteAndSateMap[];
  //allavailableRoutesForStates: SMORouteAndSateMap[];
  allavailableRoutesForStates: State[];
  public selectedRouteMap: SMORouteAndSateMap = null;
  allStates: State[];
  allavailableStates: State[];
  allDropStates: State[];
  theState: State;
  theDropState: State;
  allLGAs: Lga[] = [];
  allDropLGAs: Lga[] = [];
  LgasToShow: Lga[] = [];
  LgasToShow2: Lga[] = [];
  lgas: any[];
  droplga: [];
  dropLgasToShow: Lga[] = [];
  dropLgasToShow2: Lga[] = [];
  theLga: Lga;
  theDropLga: Lga;
  openConfirmLocationDialogue: boolean = false;
  dontOpen: boolean = false;
  locLongitude: number; //= -73.989308;
  locLatitude: number; //= 40.741895;
  locGeometry: string;

  droplocLongitude: number; //= -73.989308;
  droplocLatitude: number; //= 40.741895;
  droplocGeometry: string;
  distance: number;

  currectLat: number;
  currentlon: number;
  getAssignId: number;
  isServiceCodeMisMatched: boolean;
  isAdhocs: boolean = true;
  isServiceCodeExist: boolean;
  isMultiServiceCodeMisMatched: boolean;
  isHasReturnRoute: boolean;
  codeServices = [];
  replaceAEscort: boolean;
  replaceCommander: boolean;
  replacePilot: boolean;
  replaceVehicle: boolean;
  badge: any;
  availableHours: number = 20;
  errorMsg = "";
  //availablePilotRanks: PilotRank[];
  //availableCommanderRanks: CommanderRank[];

  constructor(
    private breadcrumbService: BreadcrumbService,
    private smoRouteAndRegionService: SMORouteService,
    public messageService: MessageService,
    public confirmationService: ConfirmationService,
    private formBuilder: FormBuilder,
    private resourceregistrationService: ResourceRegistrationService,
    private typesForServiceAssignmentService: TypesForServiceAssignmentService,
    private serviceAssignmentService: MasterServiceAssignmentService,
    private serviceAssignmentDetailService: ServiceAssignmentDetailService,
    private customerDivisionService: CustomerDivisionService,
    private contractServiceService: ContractServiceService,
    private serviceRegistrationService: ServiceRegistrationService,
    private priceRegisterService: PriceRegisterService,
    public dialogService: DialogService,
    public stateService: StateService,
    public sMORouteService: SMORouteService,
    private invoiceService: InvoiceService
  ) {
    this.breadcrumbService.setItems([
      { label: "Setup" },
      {
        label: "assignment",
        routerLink: ["home/armada/setup/master-service-assignment"],
      },
    ]);
  }

  ngOnInit(): void {
    this.loading = true;
    this.getAssignId = Number(localStorage.getItem("getId"));
    //ServiceAssignment
    this.targetCols = [
      //{ field: "sourceTypeId", header: "sourceTypeId" },
      { field: "id", header: "Id" },
      { field: "name", header: "Service" },
      { field: "client", header: "Client" },
      { field: "sMORouteId", header: "SMORouteId" },
      { field: "assignmentStatus", header: "AssStatus" },
      { field: "sMORegionId", header: "SMORegionId" },
      { field: "isReturnJourney", header: "isReturnJourney" },
      { field: "pickoffLocation", header: "pickoffLocation" },
      { field: "dropoffLocation", header: "dropoffLocation" },
      { field: "pickupDate", header: "pickupDate" },
      { field: "pickoffTime", header: "pickoffTime" },
      //{ field: 'codeName', header: 'Code Name' }
      { field: "Street", header: "Street" },
      { field: "dropStreet", header: "dropStreet" },
      { field: "dropLGA", header: "dropLGA" },
    ];
    this.exportTargetColumns = this.targetCols.map((col) => ({
      title: col.header,
      dataKey: col.field,
    }));
    this.serviceAssignmentForm = this.formBuilder.group({
      customerDivisionId: [null, [Validators.required]],
      contractServiceId: [null, [Validators.required]],
      serviceRegistrationId: [null, [Validators.required]],
      sMORouteId: [null, [Validators.required]],
      sMORegionId: [null, [Validators.required]],
      pickoffLocation: [""],
      dropoffLocation: [""],
      isReturnJourney: [false, [Validators.required]],
      isScheduled: [false, [Validators.required]],
      //isInHouseAssignment: [true, [Validators.required]],
      pickupDate: ["", [Validators.required]],
      pickoffTime: ["", [Validators.required]],
      preferentialPricing: [null, [Validators.required]],
      description: [""],
      //contractId: [null],
      secondaryServiceRegistrationId: [""],
      // dropoffLocationLongitude: [null, [Validators.required]],

      //pickupLocationLatitude: [null, [Validators.required]],
      //pickupLocationLongitude: [null, [Validators.required]],
      //distanceInKM: [null, [Validators.required]],
      //sequence: [''],
      LGA: ["", [Validators.required]],
      dropLGA: ["", [Validators.required]],
      State: ["", [Validators.required]],
      dropState: ["", [Validators.required]],
      Street: ["", [Validators.required]],
      dropStreet: ["", [Validators.required]],
    });

    this.scheduleTargetCols = [
      //{ field: "sourceTypeId", header: "sourceTypeId" },
      { field: "id", header: "Id" },
      { field: "name", header: "Service" },
      { field: "client", header: "Client" },
      { field: "sMORouteId", header: "SMORouteId" },
      { field: "sMORegionId", header: "SMORegionId" },
      //{ field: 'codeName', header: 'Code Name' }
    ];
    this.exportScheduleTargetColumns = this.scheduleTargetCols.map((col) => ({
      title: col.header,
      dataKey: col.field,
    }));

    //Passenger

    this.targetCols = [
      //{ field: "sourceTypeId", header: "sourceTypeId" },
      { field: "firstname", header: "FirstName" },
      { field: "lastname", header: "LastName" },
      { field: "serviceAssignmentId", header: "AssignmentID" },
      // { field: "email", header: "email" },
      // { field: "instagramHandle", header: "instagramHandle" },
      // { field: "twitterHandle", header: "twitterHandle" },
      // { field: "facebooHandle", header: "facebooHandle" },
      { field: "mobile", header: "Mobile" },
      //{ field: 'codeName', header: 'Code Name' }
    ];
    this.exportTargetColumns = this.targetCols.map((col) => ({
      title: col.header,
      dataKey: col.field,
    }));
    this.passengerForm = this.formBuilder.group({
      passengerTypeId: [null, [Validators.required]],
      serviceAssignmentId: [null, [Validators.required]],
      email: ["", [Validators.email]],
      mobile: ["", [Validators.required, Validators.minLength(11)]],
      firstname: ["", [Validators.required, Validators.minLength(3)]],
      lastname: ["", [Validators.required, Validators.minLength(3)]],
      othername: ["", [Validators.minLength(3)]],
      twitterHandle: [""],
      facebookHandle: [""],
      instagramHandle: [""],

      //sequence: [''],
    });

    //EscortServiceAssignment
    this.escortTargetCols = [
      { field: "armedEscortResourceId", header: "armedEscortResourceId" },
      { field: "ArmedEscortAssigned", header: "ArmedEscortAssigned" },
      { field: "serviceAssignmentId", header: "AssignmentID" },
      //{ field: 'codeName', header: 'Code Name' }
    ];
    this.exportTargetColumns = this.escortTargetCols.map((col) => ({
      title: col.header,
      dataKey: col.field,
    }));
    this.armedEscortServiceAssignmentDetailForm = this.formBuilder.group({
      armedEscortResourceId: [null, [Validators.required]],
      serviceAssignmentId: [null, [Validators.required]],
      //sMORouteId: [null, [Validators.required]],
      //sMORegionId: [null, [Validators.required]],
      //sequence: [''],
    });

    //Replace A.Escort
    this.targetCols = [
      { field: "oldResourceId", header: "oldResourceId" },
      { field: "newResourceId", header: "new ResourceId" },
      {
        field: "masterServiceAssignmentId",
        header: "masterService AssignmentId",
      },
      { field: "reasonForReplacement", header: "reason For Replacement" },
    ];
    this.exportTargetColumns = this.escortTargetCols.map((col) => ({
      title: col.header,
      dataKey: col.field,
    }));
    this.armedEscortServiceAssignmentDetailReplaceForm = this.formBuilder.group(
      {
        oldResourceId: [null, [Validators.required]],
        newResourceId: [null, [Validators.required]],
        masterServiceAssignmentId: [null, [Validators.required]],
        reasonForReplacement: ["", [Validators.required]],
        //sequence: [''],
      }
    );

    //CommanderServiceAssignment
    this.targetCols = [
      { field: "commanderResourceId", header: "commanderResourceId" },
      { field: "CommanderAssigned", header: "CommanderAssigned" },
      { field: "serviceAssignmentId", header: "AssignmentID" },
    ];
    this.exportTargetColumns = this.targetCols.map((col) => ({
      title: col.header,
      dataKey: col.field,
    }));
    this.commanderServiceAssignmentDetailForm = this.formBuilder.group({
      commanderResourceId: [null, [Validators.required]],
      tiedVehicleResourceId: [null, [Validators.required]],
      serviceAssignmentId: [null, [Validators.required]],

      //sequence: [''],
    });

    //Replace Commander
    this.targetCols = [
      { field: "oldResourceId", header: "oldResourceId" },
      { field: "newResourceId", header: "new ResourceId" },
      {
        field: "masterServiceAssignmentId",
        header: "masterService AssignmentId",
      },
      { field: "reasonForReplacement", header: "reason For Replacement" },
    ];
    this.exportTargetColumns = this.targetCols.map((col) => ({
      title: col.header,
      dataKey: col.field,
    }));
    this.commanderServiceAssignmentDetailReplaceForm = this.formBuilder.group({
      oldResourceId: [null, [Validators.required]],
      newResourceId: [null, [Validators.required]],
      masterServiceAssignmentId: [null, [Validators.required]],
      reasonForReplacement: ["", [Validators.required]],
      //sequence: [''],
    });

    //PilotServiceAssignment
    this.targetCols = [
      { field: "pilotResourceId", header: "pilotResourceId" },
      { field: "serviceAssignmentId", header: "AssignmentID" },
      { field: "PilotAssigned", header: "PilotAssigned" },
    ];
    this.exportTargetColumns = this.targetCols.map((col) => ({
      title: col.header,
      dataKey: col.field,
    }));
    this.pilotServiceAssignmentDetailForm = this.formBuilder.group({
      pilotResourceId: [null, [Validators.required]],
      tiedVehicleResourceId: [null, [Validators.required]],
      serviceAssignmentId: [null, [Validators.required]],
      //sequence: [''],
    });

    //Replace Pilot
    this.targetCols = [
      { field: "oldResourceId", header: "oldResourceId" },
      { field: "newResourceId", header: "new ResourceId" },
      {
        field: "masterServiceAssignmentId",
        header: "masterService AssignmentId",
      },
      { field: "reasonForReplacement", header: "reason For Replacement" },
    ];
    this.exportTargetColumns = this.targetCols.map((col) => ({
      title: col.header,
      dataKey: col.field,
    }));
    this.pilotServiceAssignmentDetailReplaceForm = this.formBuilder.group({
      oldResourceId: [null, [Validators.required]],
      newResourceId: [null, [Validators.required]],
      masterServiceAssignmentId: [null, [Validators.required]],
      reasonForReplacement: ["", [Validators.required]],
      //sequence: [''],
    });

    //VehicleServiceAssignment
    this.targetCols = [
      { field: "vehicleResourceId", header: "vehicleResourceId" },
      { field: "AssignmentID", header: "AssignmentID" },
      { field: "VehicleAssigned", header: "VehicleAssigned" },
    ];
    this.exportTargetColumns = this.targetCols.map((col) => ({
      title: col.header,
      dataKey: col.field,
    }));
    this.vehicleServiceAssignmentDetailForm = this.formBuilder.group({
      vehicleResourceId: [null, [Validators.required]],
      serviceAssignmentId: [null, [Validators.required]],
      //sequence: [''],
    });

    //Replace Vehicle
    this.targetCols = [
      { field: "oldResourceId", header: "oldResourceId" },

      { field: "newResourceId", header: "new ResourceId" },
      {
        field: "masterServiceAssignmentId",
        header: "masterService AssignmentId",
      },
      { field: "reasonForReplacement", header: "reason For Replacement" },
    ];
    this.exportTargetColumns = this.targetCols.map((col) => ({
      title: col.header,
      dataKey: col.field,
    }));
    this.vehicleServiceAssignmentDetailReplaceForm = this.formBuilder.group({
      oldResourceId: [null, [Validators.required]],
      newResourceId: [null, [Validators.required]],
      masterServiceAssignmentId: [null, [Validators.required]],
      reasonForReplacement: ["", [Validators.required]],
      //sequence: [''],
    });

    // this.serviceAssignmentForm.valueChanges
    //   .pipe(takeUntil(this.unsubscriber$))
    //   .subscribe(() => {
    //     this.selectedmasterServiceAssignment =
    //       this.serviceAssignmentForm.get("userProfile").value;
    //   });

    // //this.minimumAge = 18;
    this.maxDateOfBirth = new Date();
    const year = this.maxDateOfBirth.getFullYear() - 0;
    this.maxDateOfBirth.setFullYear(year);
    this.yearRange = year - 0 + ":" + year;

    //maxDate for booknow
    this.maxiDate = new Date();
    const days = this.maxiDate.getHours() + 24;
    //const days = this.maxiDate.getHours() + 24;
    this.maxiDate.setHours(days);

    //minDate
    this.minDate = new Date();
    const minDay = this.minDate.getHours() + 3;
    this.minDate.setHours(minDay);

    //minDate for schedule
    this.minDate_ = new Date();
    const _minDay = this.minDate_.getHours() + 48;
    this.minDate_.setHours(_minDay);

    //minDate for minues24hrs
    this.minDate_ = new Date();
    const _minhrs = this.todayminus24hr.getHours() - 24;
    this.todayminus24hr.setHours(_minhrs);

    // const DSLScript = document.createElement("script");
    // DSLScript.src =
    //   "https://maps.googleapis.com/maps/api/js?key=AIzaSyCQuetprs2UHb_zKSXznyumyfvw95ERCDY"; // replace by your API key
    // DSLScript.type = "text/javascript";
    // document.body.appendChild(DSLScript);
    // document.body.removeChild(DSLScript);

    //this.initMap();
    this.isShowAssignButton = false;

    this.fetchAllArmedEscortProfile();
    this.fetchAllCommanderProfiles();
    this.fetchAllPilotProfile();
    this.fetchAllVehicles();
    //this.fetchAllMasters();
    this.fetchAllMasterServiceAssignments();
    this.fetchAllScheduledServiceAssignments();
    this.fetchAllCustomerDivisions();
    this.fetchAllRegisteredServices();
    this.fetchAllSMORegions();
    this.fetchAllSMORoutes();
    this.fetchAllPassengers();
    this.fetchAllPassengerTypes();
    //These 4 are for serviceassignmentDetailsTab for all assignments
    this.fetchAllVehicleServiceAssignmentDetails();
    this.fetchAllArmedEscortServiceAssignmentDetails();
    this.fetchAllCommanderServiceAssignmentDetails();
    this.fetchAllPilotServiceAssignmentDetails();
    //Ehds here
    this.fetchAllVehicleServiceAssignmentDetailsByAssId();
    //this.fetchAllContractServices();
    this.fetchAllSecondaryRegisteredServices();
    this.fetchAllContracts();
    this.FetchAllStates();
    this.GetCurrentLocation();
    this.FetchAllDropStates();
    this.fetchAllRouteMaps();

    localStorage.mobile = "mobile";
    //this.showResourceAllocation = false;

    localStorage.getItem("serviceAssId");
    //this.RunVehicleMessage();
    // this.getReverseGeocodingData(this.currectLat, this.currentlon);
    //console.log(this.minDate + " Dates" + this.minimumAge);
    //this.submitButtonLabel = "Assign";
  }

  ngAfterViewInit(): void {
    // Load google maps script after view init
    const DSLScript = document.createElement("script");
    DSLScript.src =
      "https://maps.googleapis.com/maps/api/js?key=AIzaSyCQuetprs2UHb_zKSXznyumyfvw95ERCDY"; // replace by your API key
    DSLScript.type = "text/javascript";
    document.body.appendChild(DSLScript);
    document.body.removeChild(DSLScript);

    //this.initMap();
  }

  ngAfterViewChecked(): void {
    //this.initMap();
  }

  async fetchAllMasters() {
    await this.fetchAllMasterServiceAssignments();
  }

  //FetchAllDropDowns
  fetchAllPilotProfile(): void {
    this.resourceregistrationService
      .allPilotProfileNotHeld()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            var res = r.responseData;
            this.pilotProfiles = res;
            this.pilotProfiles.forEach(
              (userProfile) =>
                (userProfile.fullName = `${userProfile.firstname} ${userProfile.lastname}`)
            );
            //this.loading = false;
          }
        },
        (error) => {
          //this.loading = false;
          this.connectionError();
        }
      );
    //this.submittingData = false;
  }

  fetchAllArmedEscortProfile(): void {
    this.resourceregistrationService
      .allArmedEscortProfileNotHeld()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            var res = r.responseData;
            var data = [];
            data = res;
            this.armedEscortProfiles = data.filter(x => x.reserveFroPatrolAndOrEmergencyResponse === false);
            this.armedEscortProfiles.forEach(
              (userProfile) =>
                (userProfile.fullName = `${userProfile.firstName} ${userProfile.lastName}`)
            );
            //this.loading = false;
          }
        },
        (error) => this.connectionError()
      );
    this.submittingData = false;
  }

  fetchAllCommanderProfiles() {
    this.resourceregistrationService
      .allCommanderProfileNotHeld()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe((r: CommonResponse) => {
        if (r.responseCode == "00") {
          var res = r.responseData;
          this.commanderProfiles = res;
          this.commanderProfiles.forEach(
            (userProfile) =>
              (userProfile.fullName = `${userProfile.profile.firstName} ${userProfile.profile.lastName}`)
          );
          //this.loading = false;
        }
      });
  }

  fetchAllVehicles() {
    this.resourceregistrationService
      .allNotHeldVehicle()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            var res = r.responseData;
            this.vehicleProfiles = res;
            console.log(res);
            // this.userProfiles.forEach(userProfile => {
            //   userProfile.fullName = `${userProfile.firstName} ${userProfile.lastName}`
            // });
            //this.loading = false;
          }
        },
        (error) => {
          this.connectionError();
          //this.loading = false;
          this.submittingData = false;
        }
      );
  }

  fetchAllSourceTypes() {
    this.typesForServiceAssignmentService
      .allSourceTypes()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (res: any) => {
          this.SourceTypes = res;
          //this.loading = false;
        },
        (error) => {
          this.connectionError();
          //this.loading = false;
          this.submittingData = false;
        }
      );
  }
  fetchAllSMORoutes() {
    this.smoRouteAndRegionService
      .allRoutes()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            var res = r.responseData;
            this.SMORoutes = res;
            //this.loading = false;
          }
        },
        (error) => {
          this.connectionError();
          //this.loading = false;
          this.submittingData = false;
        }
      );
  }
  fetchAllPreferentialPricingByContractAndClientId(contractServId, clientId) {
    //this.loading = true;
    this.priceRegisterService
      .getAllPreferentialPricingsByContractServiceAndClientId(
        contractServId,
        clientId
      )
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            var res = r.responseData;
            this.preferentialPricing = res;
          }
        },
        (error) => {
          //this.loading = false;
          this.connectionError();
        }
      );
    this.submittingData = false;
  }

  fetchAllSMORegions() {
    this.smoRouteAndRegionService
      .allRegions()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            var res = r.responseData;
            this.SMORegions = res;
            //this.loading = false;
          }
        },
        (error) => {
          this.connectionError();
          //this.loading = false;
          this.submittingData = false;
        }
      );
  }

  fetchAllPassengerTypes() {
    this.typesForServiceAssignmentService
      .allPassengerTypes()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            var res = r.responseData;
            this.passengerTypes = res;
            //this.loading = false;
          }
        },
        (error) => {
          this.connectionError();
          //this.loading = false;
          this.submittingData = false;
        }
      );
  }
  fetchAllContractServices() {
    this.serviceAssignmentDetailService
      .allContractServices()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            var res = r.responseData;
            this.contractServices = res;
            console.log(res);
            //this.loading = false;
          }
        },
        (error) => {
          this.connectionError();
          //this.loading = false;
          this.submittingData = false;
        }
      );
  }
  fetchAllContracts() {
    this.serviceAssignmentDetailService
      .allContracts()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            var res = r.responseData;
            this.contracts = res;
            //this.loading = false;
          }
        },
        (error) => {
          this.connectionError();
          //this.loading = false;
          this.submittingData = false;
        }
      );
  }
  fetchAllCustomerDivisions() {
    this.serviceAssignmentService
      .allCustomerDivision_()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            var res = r.responseData;
            this.customerDivisions = res;
            //this.loading = false;
            //this.getAvailableContractsFromDivision();
          }
        },
        (error) => {
          this.connectionError();
          //this.loading = false;
          this.submittingData = false;
        }
      );
  }
  fetchAllRegisteredServices(): void {
    this.serviceRegistrationService
      .allServiceReg()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            var res = r.responseData;
            this.serviceRegistrations = res;
            //this.loading = false;
          }
        },
        (error) => {
          //this.loading = false;
          this.connectionError();
        }
      );
    this.submittingData = false;
  }

  fetchAllSecondaryRegisteredServices(): void {
    this.serviceRegistrationService
      .allServiceReg()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            var res = r.responseData;
            this.SecserviceRegistrations = res;
            //this.loading = false;
          }
        },
        (error) => {
          //this.loading = false;
          this.connectionError();
        }
      );
    this.submittingData = false;
  }

  getAvailableContractsFromDivision() {
    this.avalableContracts = [];
    this.availableContractServices = [];
    this.contracts.forEach((item) => {
      if (item.customerDivisionId === this.selectedCustomerDivision.id) {
        this.avalableContracts.push(item);
        this.avalableContracts.forEach(
          (items) => (items.uniqueidCaption = `${items.id} - ${items.caption}`)
        );
        // this.contractServices.forEach((item2) => {
        //   if (item2.contractId === item.id) {
        //     console.log(item2);
        //     this.availableContractServices.push(item2);
        //     this.availableContractServices.forEach(
        //       (items) =>
        //         (items.uniqueTagandCaption = `${items.uniqueTag} - ${items.service.name}`)
        //     );
        //   }
        // });
        //console.log(Rank);
      }
      // this.contractServices.forEach((item) => {
      //   if (item.contractId === this.selectedContracts.id) {
      //     console.log(item);
      //     this.availableContractServices.push(item);
      //     this.availableContractServices.forEach(
      //       (items) =>
      //         (items.uniqueTagandCaption = `${items.uniqueTag} - ${items.service.name}`)
      //     );
      //     //console.log(Rank);
      //   }
      // });
    });
  }

  getAvailableContractServiceFromContracts() {
    this.availableContractServices = [];
    this.contractServices.forEach((item) => {
      if (item.contractId === this.selectedContracts.id) {
        console.log(item);

        this.availableContractServices.push(item);
        this.availableContractServices.forEach(
          (items) =>
            (items.uniqueTagandCaption = `${items.uniqueTag} - ${items.service.name}`)
        );
        //console.log(Rank);
      }
    });
  }

  getAvailableContractServicesFromDivision() {
    this.serviceAssignmentDetailService
      .allContractServicesById(this.selectedCustomerDivision.id)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            var res = r.responseData;
            this.contractServices = res;
            this.contractServices.forEach(
              (items) =>
                (items.uniqueTagandCaption = `${items.uniqueTag} - ${items.service.name}`)
            );
            console.log(res);

            //this.loading = false;
          }
        },
        (error) => {
          this.connectionError();
          this.loading = false;
          this.submittingData = false;
        }
      );
  }
  // setSMORoute() {
  //   this.allavailableRoutesForStates = [];
  //   this.sMORouteAndStateMaps.forEach((Rank) => {
  //     if (Rank.smoRegionId === this.selectedSMORegion.id) {
  //       this.allavailableRoutesForStates.push(Rank);
  //       //console.log(Rank);
  //     }
  //   });
  // }
  setSMORoute() {
    this.availableSMORoutes = [];
    this.SMORoutes.forEach((Rank) => {
      if (Rank.smoRegionId === this.selectedSMORegion.id) {
        this.availableSMORoutes.push(Rank);
        //console.log(Rank);
      }
    });
  }
  FetchAllStates() {
    this.stateService.allState().subscribe(
      (res) => {
        var data = res.responseData;
        this.allStates = data;
        this.allStates.forEach((state) => {
          state.lgAs.forEach((lga) => {
            this.allLGAs.push(lga);
          });
        });
        this.LgasToShow = this.allLGAs;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all states at the moment.. Reason: [" +
            error.error.message +
            "]",
        });
      }
    );
  }

  FetchAllDropStates() {
    this.stateService.allState().subscribe(
      (res) => {
        var data = res.responseData;
        this.allDropStates = data;
        console.log(this.allDropStates);
        this.allDropStates.forEach((state) => {
          state.lgAs.forEach((lga) => {
            this.allDropLGAs.push(lga);
          });
        });
        this.dropLgasToShow = this.allDropLGAs;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all states at the moment.. Reason: [" +
            error.error.message +
            "]",
        });
      }
    );
  }

  PopulateLGAS() {
    this.sMORouteService
      .checkIfFroState(this.theState.id, this.selectedSMORoute.id)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            var res = r.responseData;
            //this.sMORouteAndStateMaps = res;
            if (res === true) {
              this.isFroState = true;
              this.allavailableRoutesForStates.forEach((state) => {
                if (state.name === this.theState.name) {
                  let items = this.allStates.filter(
                    (x) => x.name == state.name
                  );
                  items.forEach((state) => {
                    this.LgasToShow2 = state.lgAs;
                  });
                }
              });
            } else {
              this.isFroState = false;
            }
            console.log(res);
            //this.loading = false;
          }
        },
        (error) => {
          //this.loading = false;
          this.connectionError();
        }
      );

    // this.allavailableRoutesForStates.forEach((state) => {
    //   if (state.name === this.theState.name) {
    //     let items = this.allStates.filter((x) => x.name == state.name);
    //     items.forEach((state) => {
    //       this.LgasToShow2 = state.lgAs;
    //     });
    //   }
    // });
  }

  PopulateDropLGAS() {
    this.sMORouteService
      .checkIfToState(this.theDropState.id, this.selectedSMORoute.id)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            var res = r.responseData;
            //this.sMORouteAndStateMaps = res;
            if (res === true) {
              this.isToState = true;
              this.allavailableRoutesForStates.forEach((state) => {
                if (state.name === this.theDropState.name) {
                  let items = this.allStates.filter(
                    (x) => x.name == state.name
                  );
                  items.forEach((state) => {
                    this.dropLgasToShow2 = state.lgAs;
                  });
                }
              });
            } else {
              this.isToState = false;
            }
            console.log(res);
            //this.loading = false;
          }
        },
        (error) => {
          //this.loading = false;
          this.connectionError();
        }
      );

    //this.dropLgasToShow = this.theDropState.lgAs;
  }
  fetchAllRouteMaps(): void {
    this.sMORouteService
      .allRouteMaps()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            var res = r.responseData;
            this.sMORouteAndStateMaps = res;
            console.log(res);
            //this.loading = false;
          }
        },
        (error) => {
          //this.loading = false;
          this.connectionError();
        }
      );
    this.submittingData = false;
  }
  // CheckFroSate(stateId, routeId): boolean {
  //   this.sMORouteService
  //     .checkIfFroState(stateId,routeId)
  //     .pipe(takeUntil(this.unsubscriber$))
  //     .subscribe(
  //       (r: CommonResponse) => {
  //         if (r.responseCode == "00") {
  //           var res = r.responseData;
  //           //this.sMORouteAndStateMaps = res;
  //           if(res === true){
  //               return true
  //           }
  //           else{
  //             return false
  //           }
  //           console.log(res);
  //           //this.loading = false;
  //         }
  //       },
  //       (error) => {
  //         //this.loading = false;
  //         this.connectionError();
  //       }
  //     );
  //   this.submittingData = false;
  // }

  setStatesFromRoute() {
    //this.validateRouteIsReturnRoute();
    if (this.selectedSMORoute.isReturnRouteRequired === true) {
      this.isRequireReturnRoute = true;
    } else {
      this.isRequireReturnRoute = false;
    }
    this.allavailableRoutesForStates = [];
    this.sMORouteAndStateMaps.forEach((Rank) => {
      if (Rank.smoRouteId === this.selectedSMORoute.id) {
        this.allavailableRoutesForStates.push(Rank.state);

        console.log(this.allavailableRoutesForStates);
      }
    });
  }

  setDropStatesFromRoute() {
    this.allavailableStates = [];
    this.allStates.forEach((Rank) => {
      if (Rank.id === this.selectedRouteMap.stateId) {
        this.allavailableStates.push(Rank);
        //console.log(Rank);
      }
      this.allavailableStates.forEach((state) => {
        state.lgAs.forEach((lga) => {
          this.allDropLGAs.push(lga);
        });
      });
      this.dropLgasToShow = this.allDropLGAs;
    });
  }

  setQuantities() {
    this.serviceAssignmentForm.patchValue({
      isReturnJourney: false,
      isScheduled: false,
    });
  }

  resetPickupDate() {
    this.serviceAssignmentForm.patchValue({
      pickupDate: "",
    });
  }

  //Service Assignment

  fetchAllMasterServiceAssignments(): void {
    this.submittingData = true;
    this.loading = true;
    this.serviceAssignmentService
      .allServiceAssignments()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        async (r: CommonResponse) => {
          if (r.responseCode == "00") {
            var res = r.responseData;
            this.masterServiceAssignments = res;
            this.loading = false;
          }
        },
        (error) => {
          this.loading = false;
          this.connectionError();
        }
      );
    this.submittingData = false;
  }
  fetchMasterServiceAssignmentByPrimaryTripId(id): void {
    this.submittingData = true;
    this.loading = true;
    this.serviceAssignmentService
      .getServiceAssignmentByPrimaryTripId(id)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        async (r: CommonResponse) => {
          if (r.responseCode == "00") {
            var res = r.responseData;

            //this.masterServiceAssignments = res;
            this.loading = false;
          }
        },
        (error) => {
          this.loading = false;
          this.connectionError();
        }
      );
    this.submittingData = false;
  }

  createServiceAssignment() {
    const formData = this.serviceAssignmentForm.value;
    //const date = formData.pickupDate;
    const postData = {
      contractServiceId: this.selectedContractService.id,
      customerDivisionId: this.selectedCustomerDivision.id,
      serviceRegistrationId: this.selectedserviceRegistration.id,
      sMORegionId: this.selectedSMORegion.id,
      sMORouteId: this.selectedSMORoute.id,
      secondaryServiceRegistrationId: this.selectedMultiServices.map(
        (x) => x.id
      ),
      //pickupDate: new Date(formData.pickupDate),
      pickupDate: new Date(
        formData.pickupDate - new Date().getTimezoneOffset() * 1000 * 60
      ),
      //pickoffTime: formData.pickoffTime,
      pickoffTime: new Date(formData.pickoffTime.getTime() + 60 * 60000), //the * 60 adds 60min
      pickoffLocation:
        this.serviceAssignmentForm.get("Street").value +
        "," +
        this.theLga.name +
        "," +
        this.theState.name,
      dropoffLocation:
        this.serviceAssignmentForm.get("dropStreet").value +
        "," +
        this.theDropLga.name +
        "," +
        this.theDropState.name,
      dropOffLocationGeometry: this.droplocGeometry,
      pickUpLocationGeometry: this.locGeometry,
      description: formData.description,
      //preferentialPricing: formData.preferentialPricing,
      preferentialPricing: this.selectedPreferentialPricing.price,
      isReturnJourney: formData.isReturnJourney,
      inhouseAssignment: true,
      //isScheduled: false,
      isScheduled: formData.isScheduled,
      isScheduledTrip: formData.isScheduled,
      dropoffLocationLatitude: this.droplocLatitude,
      dropoffLocationLongitude: this.droplocLongitude,
      pickupLocationLatitude: this.locLatitude,
      pickupLocationLongitude: this.locLongitude,
      distanceInKM: this.distance,
      // Street: formData.Street,
      // dropStreet: formData.dropStreet,
      // dropLGA: formData.dropLGA,
      // LGA: formData.LGA,
      // dropState: formData.dropState,
      // State: formData.State,
    };
    this.serviceAssignmentService
      .postServiceAssignment(postData)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            this.messageService.add({
              summary: "Success",
              severity: "success",
              detail: "New Service  created",
            });
            var res = r.responseData;
            this.getAssignId = res.id;

            console.log(this.getAssignId);
            this.isReturnSlider = res.isReturnJourney;
            if (this.isReturnSlider === true) {
              this.quantityAdoc = 2;
            }
            this.createPreformalInvoice();
            this.fetchAllMasterServiceAssignments();
            this.fetchAllScheduledServiceAssignments();
            this.submittingData = false;

            this.serviceAssignmentForm.reset();
            this.isShowAssignButton = false;
            this.setQuantities();
            //window.location.reload();
            this.closeEditing();
          } else {
            this.messageService.add({
              severity: "error",
              summary: "Failed",
              detail: " Error: " + r.responseMsg,
            });
            this.submittingData = false;
          }
        },
        (error) => {
          this.messageService.add({
            severity: "error",
            summary: "Failed",
            detail: "Connection Error " + error.error.message,
          });
          this.submittingData = false;
        }
      );
    this.submittingData = false;
  }

  createScheduledServiceAssignment() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Creating record...",
    });
    const formData = this.serviceAssignmentForm.value;

    //const date = formData.pickupDate;
    const postData = {
      //contractServiceId: this.selectedContractService.id,
      contractServiceId: this.selectedContractService.id,
      customerDivisionId: this.selectedCustomerDivision.id,
      //contractId: this.selectedContracts.id,
      serviceRegistrationId: this.selectedserviceRegistration.id,
      sMORegionId: this.selectedSMORegion.id,
      sMORouteId: this.selectedSMORoute.id,
      secondaryServiceRegistrationId: this.selectedMultiServices.map(
        (x) => x.id
      ),
      //pickupDate: new Date(formData.pickupDate),
      pickupDate: new Date(
        formData.pickupDate - new Date().getTimezoneOffset() * 1000 * 60
      ),
      //pickoffTime: formData.pickoffTime,
      pickoffTime: new Date(formData.pickoffTime.getTime() + 60 * 60000), //the 60 adds extra 60 min

      pickoffLocation:
        this.serviceAssignmentForm.get("Street").value +
        "," +
        this.theLga.name +
        "," +
        this.theState.name,
      dropoffLocation:
        this.serviceAssignmentForm.get("dropStreet").value +
        "," +
        this.theDropLga.name +
        "," +
        this.theDropState.name,
      dropOffLocationGeometry: this.droplocGeometry,
      pickUpLocationGeometry: this.locGeometry,
      description: formData.description,
      //preferentialPricing: formData.preferentialPricing,
      preferentialPricing: this.selectedPreferentialPricing.price,
      isReturnJourney: formData.isReturnJourney,
      inhouseAssignment: true,
      isScheduled: formData.isScheduled,
      isScheduledTrip: formData.isScheduled,
      dropoffLocationLatitude: this.droplocLatitude,
      dropoffLocationLongitude: this.droplocLongitude,
      pickupLocationLatitude: this.locLatitude,
      pickupLocationLongitude: this.locLongitude,
      distanceInKM: this.distance,
      // Street: formData.Street,
      // dropStreet: formData.dropStreet,
      // dropLGA: formData.dropLGA,
      // LGA: formData.LGA,
      // dropState: formData.dropState,
      // State: formData.State,
    };
    this.serviceAssignmentService
      .postServiceAssignment(postData)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            this.messageService.add({
              summary: "Success",
              severity: "success",
              detail: "New schedule created",
            });
            var res = r.responseData;
            this.getAssignId = res.id;
            this.isReturnSlider = res.isReturnJourney;
            if (this.isReturnSlider === true) {
              this.quantityAdoc = 2;
            }
            this.createPreformalInvoice();
            this.fetchAllMasterServiceAssignments();
            this.fetchAllScheduledServiceAssignments();
            this.submittingData = false;
            this.serviceAssignmentForm.reset();
            this.isShowAssignButton = false;
            console.log(r.responseData);
            this.setQuantities();
            //window.location.reload();
            this.closeEditing();
          } else {
            this.messageService.add({
              severity: "error",
              summary: "Failed",
              detail: " Error: " + r.responseMsg,
            });
            this.submittingData = false;
          }
        },
        (error) => {
          this.messageService.add({
            severity: "error",
            summary: "Failed",
            detail: "Connection Error " + error.error.message,
          });
          this.submittingData = false;
        }
      );
    this.submittingData = false;
  }

  createForAutoServiceAssignment() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Creating record...",
    });
    const formData = this.serviceAssignmentForm.value;
    const postData = {
      contractServiceId: this.selectedContractService.id,
      customerDivisionId: this.selectedCustomerDivision.id,
      serviceRegistrationId: this.selectedserviceRegistration.id,
      sMORegionId: this.selectedSMORegion.id,
      sMORouteId: this.selectedSMORoute.id,
      secondaryServiceRegistrationId: this.selectedMultiServices.map(
        (x) => x.id
      ),
      //pickupDate: new Date(formData.pickupDate),
      pickupDate: new Date(
        formData.pickupDate - new Date().getTimezoneOffset() * 1000 * 60
      ),
      //pickoffTime: formData.pickoffTime,
      pickoffTime: new Date(formData.pickoffTime.getTime() + 60 * 60000),
      pickoffLocation:
        this.serviceAssignmentForm.get("Street").value +
        "," +
        this.theLga.name +
        "," +
        this.theState.name,
      dropoffLocation:
        this.serviceAssignmentForm.get("dropStreet").value +
        "," +
        this.theDropLga.name +
        "," +
        this.theDropState.name,
      dropOffLocationGeometry: this.droplocGeometry,
      pickUpLocationGeometry: this.locGeometry,
      description: formData.description,
      //preferentialPricing: formData.preferentialPricing,
      preferentialPricing: this.selectedPreferentialPricing.price,
      isReturnJourney: formData.isReturnJourney,
      inhouseAssignment: true,
      isScheduled: formData.isScheduled,
      dropoffLocationLatitude: this.droplocLatitude,
      dropoffLocationLongitude: this.droplocLongitude,
      pickupLocationLatitude: this.locLatitude,
      pickupLocationLongitude: this.locLongitude,
      distanceInKM: this.distance,
    };
    this.serviceAssignmentService
      .postAutoServiceAssignment(postData)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            this.messageService.add({
              summary: "Success",
              severity: "success",
              detail: "New Service Successfully Assigned",
            });
            var res = r.responseData;
            this.getAssignId = res.id;
            this.isReturnSlider = res.isReturnJourney;
            if (this.isReturnSlider === true) {
              this.quantityAdoc = 2;
            }
            this.createPreformalInvoice();
            this.fetchAllMasterServiceAssignments();
            this.fetchAllScheduledServiceAssignments();

            this.submittingData = false;
            this.serviceAssignmentForm.reset();
            this.isShowAssignButton = false;
            this.setQuantities();
            //window.location.reload();
            this.closeEditing();
          } else {
            this.errorMsg = r.responseMsg;
            this.messageService.add({
              severity: "error",
              summary: "Failed",
              detail: " Error: " + r.responseMsg,
            });
            this.submittingData = false;
          }
        },

        (error) => {
          this.messageService.add({
            severity: "error",
            summary: "Failed",
            detail: "Connection Error " + this.errorMsg,
          });
          this.submittingData = false;
        }
      );
    this.submittingData = false;
  }

  deleteServiceAssignment(
    masterServiceAssignment: MasterServiceAssignment
  ): void {
    this.confirmationService.confirm({
      message: "Are you sure you want to delete this Service?",
      accept: () => {
        this._deleteServiceAssignment(masterServiceAssignment);
      },
    });
  }
  _deleteServiceAssignment(masterServiceAssignment) {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Deleting service record",
    });
    this.serviceAssignmentService
      .deleteServiceAssignment(masterServiceAssignment.id)
      .subscribe(
        async (result: CommonResponse) => {
          if (result.responseCode === "00") {
            await this.messageService.add({
              severity: "success",
              summary: "Deleted",
              detail: "Service record removed",
            });
            await this.fetchAllMasterServiceAssignments();
            await this.fetchAllArmedEscortServiceAssignmentDetails();
            await this.fetchAllCommanderServiceAssignmentDetails();
            await this.fetchAllVehicleServiceAssignmentDetails();
            await this.fetchAllPilotServiceAssignmentDetails();
            await this.fetchAllPassengers();
          } else {
            this.messageService.add({
              severity: "error",
              summary: "Failed",
              detail: " Error: " + result.responseMsg,
            });
            this.submittingData = false;
          }
        },
        (error) => {
          this.connectionError();
        }
      );
  }
  editServiceAssignment(
    masterServiceAssignments: MasterServiceAssignment
  ): void {
    this.formWrapper.nativeElement.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "start",
    });
    this.edmasterServiceAssignment = masterServiceAssignments;
    this.editingmasterServiceAssignment = true;
    // this.imageSrc = pilotProfiles.imageUrl;
    this.serviceAssignmentForm.setValue({
      contractServiceId: masterServiceAssignments.contractServiceId,
      //description: serviceRegistrations.description,
      customerDivisionId: masterServiceAssignments.customerDivisionId,
      serviceRegistrationId: masterServiceAssignments.serviceRegistrationId,
      SMORegionId: masterServiceAssignments.sMORegionId,
      SMORouteId: masterServiceAssignments.sMORouteId,
      pickupDate: new Date(
        masterServiceAssignments.pickupDate ?? this.maxDateOfBirth
      ),

      pickoffTime: masterServiceAssignments.pickoffTime,
      pickoffLocation: masterServiceAssignments.pickoffLocation,
      dropoffLocation: masterServiceAssignments.dropoffLocation,
      isReturnJourney: masterServiceAssignments.isReturnJourney,
    });
    // this.selectedPilotType = this.pilotTypes.find(
    //   (Type) => Type.id === pilotProfiles.pilotTypeId
    // );

    this.submitButtonLabel = "Update";
  }
  updateServiceAssignment(): boolean {
    this.submittingData = true;
    if (!this.edmasterServiceAssignment) {
      return false;
    }
    const formData = this.serviceAssignmentForm.value;
    const data = {
      contractServiceId: this.selectedContractService.id,
      customerDivisionId: this.selectedCustomerDivision.id,
      serviceRegistrationId: this.selectedserviceRegistration.id,
      SMORegionId: this.selectedSMORegion.id,
      SMORouteId: this.selectedSMORoute.id,
      pickupDate: formData.pickupDate,
      pickoffTime: formData.pickoffTime,
      pickoffLocation: formData.pickoffLocation,
      dropoffLocation: formData.dropoffLocation,
      //pilotTypeId: this.selectedPilotType.id,
      isReturnJourney: formData.isReturnJourney,
      //rankId: this.selectedPilotRank.id,
      // sequence: 0
    };
    this.serviceAssignmentService
      .updateServiceAssignment(this.edmasterServiceAssignment.id, data)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            this.messageService.add({
              severity: "success",
              detail: "Registered Service Updated",
              summary: "Completed",
            });
            this.submittingData = false;
            this.closeEditing();
            this.fetchAllMasterServiceAssignments();
          } else {
            this.connectionError();
          }
        },
        (error) => {
          this.connectionError();
          this.submittingData = false;
        }
      );
  }

  viewServiceAssignmentInfo(
    masterServiceAssignment: MasterServiceAssignment
  ) {
    this.viewAssignmentDetailDialog = true;
    this.selectedTime = masterServiceAssignment.pickoffTime;
    this.selectedDate = masterServiceAssignment.pickupDate;
    this.selectedmasterServiceAssignment2 = masterServiceAssignment;
  }

  _allocateUpdateForSchedule(
    masterServiceAssignment: MasterServiceAssignment
  ): void {
    this.confirmationService.confirm({
      message: "Are you sure you want to allocate resource?",
      accept: () => {
        this.allocateUpdateForSchedule(masterServiceAssignment);
      },
    });
  }

  allocateUpdateForSchedule(masterServiceAssignments) {
    this.submittingData = true;
    this.loading = true;
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Allocating Resource",
    });
    this.serviceAssignmentService
      .allocateResourceForScheduledServiceForCronJobOrInHouseByAssignmentId(
        masterServiceAssignments.id
      )
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode === "00") {
            this.messageService.add({
              severity: "success",
              detail: "Resources Allocated successfully",
              summary: "Completed",
            });
            this.fetchAllScheduledServiceAssignments();
            this.fetchAllMasterServiceAssignments();
            //this.submittingData = false;
            this.loading = false;
          } else {
            this.messageService.add({
              severity: "error",
              summary: "Failed",
              detail: " Error " + r.responseMsg,
            });
            this.loading = false;
          }
        },
        (error) => {
          //this.connectionError();
          this.messageService.add({
            severity: "error",
            summary: "Failed",
            detail: "Connection Error " + error.error.message,
          });
          this.loading = false;
          this.submittingData = false;
        }
      );
  }

  deleteServiceAssignmentSchedule(
    masterServiceAssignment: MasterServiceAssignment
  ): void {
    this.confirmationService.confirm({
      message: "Are you sure you want to delete schedule?",
      accept: () => {
        this._deleteServiceAssignmentSchedule(masterServiceAssignment);
      },
    });
  }
  _deleteServiceAssignmentSchedule(masterServiceAssignment) {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Deleting scheduled Service record",
    });
    this.serviceAssignmentService
      .deleteServiceAssignmentSchedule(masterServiceAssignment.id)
      .subscribe(
        async (result: any) => {
          await this.messageService.add({
            severity: "success",
            summary: "Deleted",
            detail: "Service record removed",
          });
          await this.fetchAllMasterServiceAssignments();
          await this.fetchAllScheduledServiceAssignments();
          //await this.fetchAllArmedEscortServiceAssignmentDetails();
          //await this.fetchAllCommanderServiceAssignmentDetails();
          //await this.fetchAllVehicleServiceAssignmentDetails();
          //await this.fetchAllPilotServiceAssignmentDetails();
          //await this.fetchAllPassengers();
        },
        (error) => {
          this.connectionError();
        }
      );
  }

  ShowAlloccateButton(item: MasterServiceAssignment): string {
    var minDate = new Date();
    var minDate_ = new Date(minDate);
    var pickupDate = new Date(item.pickupDate);
    const _minhrs = minDate_.getHours() + 23;
    minDate_.setHours(_minhrs);
    var date2 = new Date(minDate_);
    date2.setHours(0, 0, 0, 0);
    pickupDate.setHours(0, 0, 0, 0);
    //console.log(`Pickupdate: ${pickupDate} and mindate: ${date2}`);
    // if (pickupDate.getTime() === date2.getTime()) {
    //   //console.log(`Pickupdate: ${pickupDate} and mindate: ${date2}`);
    //   console.log("show");
    //   return "show";
    // }
    var gethr = minDate_.getHours();
    //gethr <= this.availableHours &&
    if (pickupDate.getTime() === date2.getTime()) {
      return "show";
    }

    return "Err";
  }

  // GetScheduleAssignmentStatus(item: MasterServiceAssignment): string {
  //   var minDate = new Date();
  //   var pickTime = new Date(item.pickoffTime);
  //   var todayTime = minDate.getMinutes();
  //   if (pickTime.getTime() === date2.getTime()) {
  //     //console.log(`Pickupdate: ${pickupDate} and mindate: ${date2}`);
  //     this.badge = "success";
  //     console.log("isDue");
  //     return "isDue";
  //   }
  //   // var gethr = minDate_.getHours();
  //   // if (gethr <= 15 && pickupDate.getTime() === date2.getTime()) {
  //   //   console.log("isDue");
  //   //   return "isDue";
  //   // }
  //   if (date2.getTime() < pickupDate.getTime()) {
  //     //this.badge = "primary";
  //     //var gethr = minDate_.getHours();
  //     //console.log("notDue minhrs:", gethr);
  //     return "notDue";
  //   }
  //   //|| gethr > 15
  //   if (date2.getTime() > pickupDate.getTime()) {
  //     this.badge = "danger";
  //     console.log("isExpired");
  //     return "isExpired";
  //   }

  //   return "Err";
  // }

  GetScheduleAssignmentStatus(item: MasterServiceAssignment): string {
    var minDate = new Date();
    var minDate_ = new Date(minDate);
    var pickupDate = new Date(item.pickupDate);
    const _minhrs = minDate_.getHours() + 23;
    minDate_.setHours(_minhrs);
    var date2 = new Date(minDate_);
    date2.setHours(0, 0, 0, 0);
    pickupDate.setHours(0, 0, 0, 0);
    //console.log(`Pickupdate: ${pickupDate} and mindate: ${date2}`);
    // if (pickupDate.getTime() === date2.getTime()) {
    //   //console.log(`Pickupdate: ${pickupDate} and mindate: ${date2}`);
    //   this.badge = "success";
    //   console.log("isDue");
    //   return "isDue";
    // }
    var gethr = minDate_.getHours();
    //gethr <= this.availableHours &&
    if (pickupDate.getTime() === date2.getTime()) {
      //console.log("isDue");
      //console.log("isDue :", date2.getTime(), pickupDate.getTime());
      return "isDue";
    }
    if (date2.getTime() < pickupDate.getTime()) {
      //this.badge = "primary";
      //var gethr = minDate_.getHours();
      //console.log("notDue :", date2.getTime(), pickupDate.getTime());
      return "notDue";
    }
    //|| gethr > this.availableHours
    if (date2.getTime() > pickupDate.getTime()) {
      this.badge = "danger";
      //console.log("isExpired", gethr, this.availableHours);
      //console.log("expired :", date2.getTime(), pickupDate.getTime());
      return "isExpired";
    }

    //if (pickupDate) return "notDue";
    //if (today >= start && today <= stop) return "Active";
    return "Err";
  }
  fetchAllScheduledServiceAssignments(): void {
    this.submittingData = true;
    this.loading = true;
    this.serviceAssignmentService
      .allScheduledServiceAssignments()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            var res = r.responseData;
            this.scheduledServiceAssignments = res;
            this.loading = false;
          }
        },
        (error) => {
          this.loading = false;
          this.connectionError();
        }
      );
    this.submittingData = false;
  }

  filterExpiredScheduleMasterService(): void {
    this.submittingData = true;
    this.loading = true;
    this.serviceAssignmentService
      .allExpiredScheduledServiceAssignments()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            var res = r.responseData;
            this.scheduledServiceAssignments = [];
            this.scheduledServiceAssignments = res;
            // const _assignments = this.scheduledServiceAssignments1;
            // this.scheduledServiceAssignments = [];
            // var minDate = new Date();
            // var minDate_ = new Date(minDate);
            // const _minhrs = minDate_.getHours() + 23;
            // minDate_.setHours(_minhrs);
            // // var date2 = new Date(minDate_);
            // // date2.setHours(0, 0, 0, 0);
            // var gethr = minDate_.getHours();
            // // this.scheduledServiceAssignments = _assignments.filter(
            // //   (ass) =>
            // //     date2.getTime() > new Date(ass.pickupDate).getTime() ||
            // //     gethr > this.availableHours
            // // );
            // let date = new Date();
            // let nextDay = date.setDate(date.getDate() + 1);
            // //let datee = new Date(nextDay);

            // console.log(new Date(nextDay).setHours(0, 0, 0, 0));
            // this.scheduledServiceAssignments = _assignments.filter(
            //   (ass) =>
            //     new Date(nextDay).setHours(0, 0, 0, 0) >
            //       new Date(ass.pickupDate).setHours(0, 0, 0, 0) ||
            //     gethr > this.availableHours
            // );
            // this.submittingData = false;
            this.loading = false;
          }
        },
        (error) => {
          this.loading = false;
          this.connectionError();
        }
      );
    //const _assignments = this.activeMasterServiceAssignments;
    //this.activeFilterMasterServiceAssignments = [];
    this.submittingData = false;
  }

  filterIsDueScheduleMasterService(): void {
    this.submittingData = true;
    this.loading = true;
    this.serviceAssignmentService
      .allDueScheduledServiceAssignments()
      //.allDueScheduledServiceAssignments()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            var res = r.responseData;
            this.scheduledServiceAssignments = [];
            this.scheduledServiceAssignments = res;
            //             const now = new Date();
            //             const oneDayFromNow = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1);
            //             console.log(oneDayFromNow);
            //             const _assignments = this.scheduledServiceAssignments1;
            //             this.scheduledServiceAssignments = [];
            //             this.scheduledServiceAssignments = _assignments.filter(type =>
            //   new Date(type.pickupDate) === oneDayFromNow
            // );

            // const _assignments = this.scheduledServiceAssignments1;
            // var minDate = new Date();
            // var minDate_ = new Date(minDate);
            // const _minhrs = minDate_.getHours() + 23;
            // minDate_.setHours(_minhrs);
            // // var date2 = new Date(minDate_);
            // // date2.setHours(0, 0, 0, 0);
            // var gethr = minDate_.getHours();

            // let date = new Date();
            // let nextDay = date.setDate(date.getDate() + 1);
            // let datee = new Date(nextDay);
            // //let date2 = new Date(datee.setHours(0, 0, 0, 0));
            // //let date2 = new Date(date.setHours(0, 0, 0, 0)).getTime();
            // console.log(new Date(nextDay).setHours(0, 0, 0, 0));
            // //console.log(new Date(date2.getTime()));
            // this.scheduledServiceAssignments = _assignments.filter(
            //   (ass) =>
            //     new Date(ass.pickupDate).setHours(0, 0, 0, 0) ===
            //       new Date(nextDay).setHours(0, 0, 0, 0) &&
            //     gethr <= this.availableHours
            // );
            // _assignments.forEach((date) => {
            //   let pickupDatee = new Date(date.pickupDate);
            //   let pickupDate1 = new Date(pickupDatee.setHours(0, 0, 0, 0));
            //   console.log("pickupDate:", new Date(pickupDate1.getTime()));
            //   console.log(
            //     "pickup:",
            //     new Date(date.pickupDate).setHours(0, 0, 0, 0)
            //   );
            //   // if (
            //   //   new Date(pickupDate1.getTime()) === new Date(date2.getTime())
            //   // ) {
            //   //   this.scheduledServiceAssignments2.push(date);
            //   //   console.log("ddd");
            //   // }
            // });

            this.loading = false;
          }
        },

        (error) => {
          this.loading = false;
          this.connectionError();
        }
      );
    //const _assignments = this.activeMasterServiceAssignments;
    //this.activeFilterMasterServiceAssignments = [];
    this.submittingData = false;
  }

  filterNotDueScheduleMasterService(): void {
    this.submittingData = true;
    this.loading = true;
    this.serviceAssignmentService
      .allNotDueScheduledServiceAssignments()
      //.allScheduledServiceAssignments()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            var res = r.responseData;

            //const _assignments = this.scheduledServiceAssignments1;
            this.scheduledServiceAssignments = [];
            this.scheduledServiceAssignments = res;
            //              const tomorrow = new Date();
            // tomorrow.setDate(tomorrow.getDate() + 1);
            //             console.log(tomorrow);
            //             console.log("assign",_assignments);

            //             this.scheduledServiceAssignments = _assignments.filter(type =>
            //   new Date(type.pickupDate).getDate() > tomorrow.getDate()
            // );
            // var minDate = new Date();
            // var minDate_ = new Date(minDate);
            // const _minhrs = minDate_.getHours() + 23;
            // minDate_.setHours(_minhrs);
            // var date2 = new Date(minDate_);
            // date2.setHours(0, 0, 0, 0);
            // this.scheduledServiceAssignments = _assignments.filter(
            //   (ass) => date2.getTime() < new Date(ass.pickupDate).getTime()
            // );
            // let date = new Date();
            // let nextDay = date.setDate(date.getDate() + 1);
            // //let datee = new Date(nextDay);

            // console.log(new Date(nextDay).setHours(0, 0, 0, 0));
            // this.scheduledServiceAssignments = _assignments.filter(
            //   (ass) =>
            //     new Date(nextDay).setHours(0, 0, 0, 0) <
            //     new Date(ass.pickupDate).setHours(0, 0, 0, 0)
            // );

            this.loading = false;
          }
        },
        (error) => {
          this.loading = false;
          this.connectionError();
        }
      );
    //const _assignments = this.activeMasterServiceAssignments;
    //this.activeFilterMasterServiceAssignments = [];
    this.submittingData = false;
  }

  ValidateServiceCodes(): boolean {
    this.setQuantities();
    console.log(this.selectedContractService.invoicingInterval);
    this.fetchAllPreferentialPricingByContractAndClientId(
      this.selectedContractService.id,
      this.selectedCustomerDivision.id
    );
    if (this.selectedContractService.invoicingInterval !== adhovVal) {
      this.isAdhocs = false;
    } else {
      this.isAdhocs = true;
    }
    if (this.selectedContractService.vat > 0) {
      this.getVat = 0.075;
      console.log("vatable");
    } else {
      this.getVat = 0;
      console.log("not vatable");
    }
    if (
      this.selectedContractService.service?.serviceCode !==
      this.selectedserviceRegistration.service?.serviceCode
    ) {
      this.isServiceCodeMisMatched = true;
      return false;
    } else {
      this.isServiceCodeMisMatched = false;
      return true;
    }
  }

  ValidateSecServiceCodeAndContractServiceCodes(): boolean {
    const services = [];
    this.contractServices.forEach((items) =>
      services.push(items.service.serviceCode)
    );
    this.selectedMultiServices.forEach((item) => {
      if (services.includes(item.service.serviceCode)) {
        this.isServiceCodeExist = true;
      } else {
        this.isServiceCodeExist = false;
      }
    });
    // let isServiceCodeExist = this.selectedMultiServices.every((a) =>
    //   services.includes(a)
    // );
    //this.isServiceCodeExist === true
    if (this.isServiceCodeExist === true) {
      this.isMultiServiceCodeMisMatched = false;
      //console.log(services);
      return false;
    } else {
      this.isMultiServiceCodeMisMatched = true;
      //console.log(services);
      return true;
    }
  }

  validateRouteIsReturnRoute(): boolean {
    if (this.isForReturnRoute === true) {
      if (
        this.selectedSMORoute.isReturnRouteRequired === true &&
        this.isForReturnRoute === true
      ) {
        this.isHasReturnRoute = true;
        console.log("route check true", this.isHasReturnRoute);
        return true;
      } else {
        this.isHasReturnRoute = false;
        console.log("route check false", this.isHasReturnRoute);
        return false;
      }
    } else {
      this.isHasReturnRoute = true;
      console.log("route check true", this.isHasReturnRoute);
      return true;
    }
  }
  // this.selectedMultiServices
  // .map((x) => x.service.serviceCode)
  // .some((som) => services.includes(som));
  setVal() {
    this.serviceAssignmentForm.patchValue({
      isReturnJourney: false,
      isScheduled: false,
    });
  }
  //ServiceAssignmentDetail
  //Escort

  fetchAllArmedEscortServiceAssignmentDetails(): void {
    this.serviceAssignmentDetailService
      .allArmedEscortDetails()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            var res = r.responseData;
            this.armedEscortServiceAssignmentDetails = res;
            this.loading = false;
          }
        },
        (error) => {
          this.loading = false;
          this.connectionError();
        }
      );
    this.submittingData = false;
  }

  fetchAllArmedEscortServiceAssignmentDetailsByAssId(
    serviceAssignments: ArmedEscortServiceAssignmentDetail
  ): void {
    this.serviceAssignmentDetailService
      .allArmedEscortDetailsByAssignmentId(
        serviceAssignments.serviceAssignmentId
      )
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            var res = r.responseData;
            this.armedEscortServiceAssignmentDetails = res;
            this.loading = false;
          }
        },
        (error) => {
          this.loading = false;
          this.connectionError();
        }
      );
    this.submittingData = false;
  }
  createArmedEscortServiceAssignmentDetail() {
    this.submittingData = true;
    this.loading = true;
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Saving Record",
    });
    const formData = this.armedEscortServiceAssignmentDetailForm.value;
    const postData = {
      armedEscortResourceId: this.selectedArmedEscortProfile.id,
      serviceAssignmentId: formData.serviceAssignmentId,
      // sMORegionId: formData.sMORegionId,
      // sMORouteId: formData.sMORouteId,
    };
    this.serviceAssignmentDetailService
      .postArmedEscortDetail(postData)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            this.messageService.add({
              summary: "Success",
              severity: "success",
              detail: "New Service  created",
            });
            var getId = r.responseData.serviceAssignmentId;
            localStorage.setItem("serviceAssignmentId", getId);
            //this.fetchAllArmedEscortServiceAssignmentDetails();
            this.submittingData = false;
            this.loading = false;
            //this.armedEscortServiceAssignmentDetailForm.reset();
            //starts
            this.serviceAssignmentDetailService
              .allArmedEscortDetailsByAssignmentId(this.getAssignId)
              .pipe(takeUntil(this.unsubscriber$))
              .subscribe(
                (r: CommonResponse) => {
                  if (r.responseCode == "00") {
                    var res = r.responseData;
                    this.armedEscortServiceAssignmentDetails = res;
                    this.loading = false;
                  }
                },
                (error) => {
                  this.loading = false;
                  this.connectionError();
                }
              );
            //ends
            this.fetchAllMasterServiceAssignments();
            this.closeEditing();
          } else {
            this.messageService.add({
              severity: "error",
              summary: "Failed",
              detail: " Error: " + r.responseMsg,
            });
            this.submittingData = false;
            this.loading = false;
          }
        },
        (error) => {
          this.messageService.add({
            severity: "error",
            summary: "Failed",
            detail: "Connection Error " + error.error.message,
          });
          this.submittingData = false;
          this.loading = false;
        }
      );
    this.submittingData = false;
  }

  createArmedEscortServiceAssignmentDetailReplacement() {
    this.submittingData = true;
    this.loading = true;
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Replacing Record",
    });
    const formData = this.armedEscortServiceAssignmentDetailReplaceForm.value;
    const postData = {
      newResourceId: this.selectedArmedEscortProfile.id,
      oldResourceId: formData.oldResourceId,
      masterServiceAssignmentId: formData.masterServiceAssignmentId,
      reasonForReplacement: formData.reasonForReplacement,
    };
    this.serviceAssignmentDetailService
      .postArmedEscortDetailReplacement(postData)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            this.messageService.add({
              summary: "Success",
              severity: "success",
              detail: "Replacement Successful",
            });
            var getId = r.responseData.masterServiceAssignmentId;
            localStorage.setItem("serviceAssignmentId", getId);
            //this.fetchAllArmedEscortServiceAssignmentDetails();
            this.submittingData = false;
            this.loading = false;
            //this.armedEscortServiceAssignmentDetailForm.reset();
            //starts
            this.serviceAssignmentDetailService
              .allArmedEscortDetailsByAssignmentId(this.getAssignId)
              .pipe(takeUntil(this.unsubscriber$))
              .subscribe(
                (r: CommonResponse) => {
                  if (r.responseCode == "00") {
                    var res = r.responseData;
                    this.armedEscortServiceAssignmentDetails = res;
                    this.loading = false;
                  }
                },
                (error) => {
                  this.loading = false;
                  this.connectionError();
                }
              );
            //ends
            this.fetchAllMasterServiceAssignments();
            this.closeEditing();
          } else {
            this.messageService.add({
              severity: "error",
              summary: "Failed",
              detail: " Error: " + r.responseMsg,
            });
            this.submittingData = false;
            this.loading = false;
          }
        },
        (error) => {
          this.messageService.add({
            severity: "error",
            summary: "Failed",
            detail: "Connection Error " + error.error.message,
          });
          this.submittingData = false;
          this.loading = false;
        }
      );
    this.submittingData = false;
  }

  deleteArmedEscortServiceAssignmentDetail(
    ServiceAssignment: ArmedEscortServiceAssignmentDetail
  ): void {
    this.confirmationService.confirm({
      message: "Are you sure you want to delete " + "Service",
      accept: () => {
        this._deleteArmedEscortServiceAssignmentDetail(ServiceAssignment);
      },
    });
  }
  _deleteArmedEscortServiceAssignmentDetail(ServiceAssignment) {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Deleting Service record",
    });
    this.serviceAssignmentDetailService
      .deleteArmedEscortDetail(ServiceAssignment.id)
      .subscribe(
        async (result: any) => {
          await this.messageService.add({
            severity: "success",
            summary: "Deleted",
            detail: "Service record removed",
          });
          await this.fetchAllArmedEscortServiceAssignmentDetails();
        },
        (error) => {
          this.connectionError();
        }
      );
  }
  editArmedEscortServiceAssignmentDetail(
    serviceAssignments: ArmedEscortServiceAssignmentDetail
  ): void {
    this.formWrapper.nativeElement.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "start",
    });
    this.edArmedEscortServiceAssignmentDetail = serviceAssignments;
    this.editingArmedEscortServiceAssignmentDetail = true;
    // this.imageSrc = pilotProfiles.imageUrl;
    this.armedEscortServiceAssignmentDetailForm.setValue({
      armedEscortResourceId: serviceAssignments.armedEscortResourceId,
      //customerDivisionId: masterServiceAssignments.customerDivisionId,
    });
    // this.selectedPilotType = this.pilotTypes.find(
    //   (Type) => Type.id === pilotProfiles.pilotTypeId
    // );

    this.submitButtonLabel = "Update";
  }
  updateArmedEscortServiceAssignmentDetail(): boolean {
    this.submittingData = true;
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Updating Record",
    });
    if (!this.edArmedEscortServiceAssignmentDetail) {
      return false;
    }
    const formData = this.armedEscortServiceAssignmentDetailForm.value;
    const data = {
      armedEscortResourceId: this.selectedArmedEscortProfile.id,
      //customerDivisionId: this.selectedCustomerDivision.id,
    };
    this.serviceAssignmentDetailService
      .updateArmedEscortDetail(
        this.edArmedEscortServiceAssignmentDetail.id,
        data
      )
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            this.messageService.add({
              severity: "success",
              detail: "Registered Service Updated",
              summary: "Completed",
            });
            this.submittingData = false;
            this.closeEditing();
            this.fetchAllArmedEscortServiceAssignmentDetails();
            this.armedEscortServiceAssignmentDetailForm.reset();
          } else {
            this.connectionError();
          }
        },
        (error) => {
          this.connectionError();
          this.submittingData = false;
        }
      );
  }

  //Commander
  fetchAllCommanderServiceAssignmentDetails(): void {
    this.serviceAssignmentDetailService
      .allCommanderDetails()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            var res = r.responseData;
            this.commanderServiceAssignmentDetails = res;
            this.loading = false;
          }
        },
        (error) => {
          this.loading = false;
          this.connectionError();
        }
      );
    this.submittingData = false;
  }

  fetchAllCommanderServiceAssignmentDetailsById(
    serviceAssignments: CommanderServiceAssignmentDetail
  ): void {
    this.serviceAssignmentDetailService
      .allCommanderDetailsByAssignmentId(serviceAssignments.serviceAssignmentId)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            var res = r.responseData;
            this.commanderServiceAssignmentDetails = res;
            this.loading = false;
          }
        },
        (error) => {
          this.loading = false;
          this.connectionError();
        }
      );
    this.submittingData = false;
  }
  createCommanderServiceAssignmentDetail() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Saving Record",
    });
    const formData = this.commanderServiceAssignmentDetailForm.value;
    const postData = {
      commanderResourceId: this.selectedCommanderProfile.id,
      tiedVehicleResourceId:
        this.selectedVehicleServiceAssignmentDetail.vehicleResourceId,
      serviceAssignmentId: formData.serviceAssignmentId,
    };
    this.serviceAssignmentDetailService
      .postCommanderDetail(postData)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            this.messageService.add({
              summary: "Success",
              severity: "success",
              detail: "New Service  created",
            });
            // this.fetchAllCommanderServiceAssignmentDetails();
            this.submittingData = false;
            this.loading = false;
            this.commanderServiceAssignmentDetailForm.reset();
            this.fetchAllMasterServiceAssignments();
            this.fetchAllCommanderServiceAssignmentDetailsById(
              this.selectedmasterServiceAssignment.id
            );
            this.closeEditing();
          } else {
            this.messageService.add({
              severity: "error",
              summary: "Failed",
              detail: " Error: " + r.responseMsg,
            });
            this.submittingData = false;
            this.loading = false;
          }
        },
        (error) => {
          this.messageService.add({
            severity: "error",
            summary: "Failed",
            detail: "Connection Error " + error.error.message,
          });
          this.submittingData = false;
          this.loading = false;
        }
      );

    this.submittingData = false;
  }

  createCommanderServiceAssignmentDetailReplacement() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Replacing Record",
    });
    const formData = this.commanderServiceAssignmentDetailReplaceForm.value;
    const postData = {
      newResourceId: this.selectedCommanderProfile.id,
      oldResourceId: formData.oldResourceId,
      masterServiceAssignmentId: formData.masterServiceAssignmentId,
      reasonForReplacement: formData.reasonForReplacement,
    };
    this.serviceAssignmentDetailService
      .postCommanderDetailReplacement(postData)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            this.messageService.add({
              summary: "Success",
              severity: "success",
              detail: "Replacement Successful",
            });
            // this.fetchAllCommanderServiceAssignmentDetails();
            this.submittingData = false;
            this.loading = false;
            this.commanderServiceAssignmentDetailForm.reset();
            this.fetchAllMasterServiceAssignments();

            this.closeEditing();
          } else {
            this.messageService.add({
              severity: "error",
              summary: "Failed",
              detail: " Error: " + r.responseMsg,
            });
            this.submittingData = false;
            this.loading = false;
          }
        },
        (error) => {
          this.messageService.add({
            severity: "error",
            summary: "Failed",
            detail: "Connection Error " + error.error.message,
          });
          this.submittingData = false;
          this.loading = false;
        }
      );

    this.submittingData = false;
  }

  deleteCommanderServiceAssignmentDetail(
    ServiceAssignment: CommanderServiceAssignmentDetail
  ): void {
    this.confirmationService.confirm({
      message: "Are you sure you want to delete " + "Service",
      accept: () => {
        this._deleteCommanderServiceAssignmentDetail(ServiceAssignment);
      },
    });
  }
  _deleteCommanderServiceAssignmentDetail(ServiceAssignment) {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Deleting Service record",
    });
    this.serviceAssignmentDetailService
      .deleteCommanderDetail(ServiceAssignment.id)
      .subscribe(
        async (result: any) => {
          await this.messageService.add({
            severity: "success",
            summary: "Deleted",
            detail: "Service record removed",
          });
          await this.fetchAllCommanderServiceAssignmentDetails();
        },
        (error) => {
          this.connectionError();
        }
      );
  }
  editCommanderServiceAssignmentDetail(
    serviceAssignments: CommanderServiceAssignmentDetail
  ): void {
    this.formWrapper.nativeElement.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "start",
    });
    this.edCommanderServiceAssignmentDetail = serviceAssignments;
    this.editingCommanderServiceAssignmentDetail = true;
    // this.imageSrc = pilotProfiles.imageUrl;
    this.commanderServiceAssignmentDetailForm.patchValue({
      commanderResourceId: serviceAssignments.commanderResourceId,
      tiedVehicleResourceId: serviceAssignments.tiedVehicleResourceId,
    });
    // this.selectedPilotType = this.pilotTypes.find(
    //   (Type) => Type.id === pilotProfiles.pilotTypeId
    // );

    this.submitButtonLabel = "Update";
  }
  updateCommanderServiceAssignmentDetail(): boolean {
    this.submittingData = true;
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Updating Record",
    });
    if (!this.edCommanderServiceAssignmentDetail) {
      return false;
    }
    const formData = this.commanderServiceAssignmentDetailForm.value;
    const data = {
      commanderResourceId: this.selectedCommanderProfile.id,
      tiedVehicleResourceId: this.selectedVehicleProfile.id,
    };
    this.serviceAssignmentDetailService
      .updateCommanderDetail(this.edCommanderServiceAssignmentDetail.id, data)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        () => {
          this.messageService.add({
            severity: "success",
            detail: "Registered Service Updated",
            summary: "Completed",
          });
          this.submittingData = false;
          this.closeEditing();
          this.fetchAllCommanderServiceAssignmentDetails();
          this.commanderServiceAssignmentDetailForm.reset();
        },
        (error) => {
          this.connectionError();
          this.submittingData = false;
        }
      );
  }

  //Pilot
  fetchAllPilotServiceAssignmentDetails(): void {
    this.serviceAssignmentDetailService
      .allPilotDetails()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            var res = r.responseData;
            this.pilotServiceAssignmentDetails = res;
            this.loading = false;
          }
        },
        (error) => {
          this.loading = false;
          this.connectionError();
        }
      );
    this.submittingData = false;
  }
  fetchAllPilotServiceAssignmentDetailsById(
    serviceAssignments: PilotServiceAssignmentDetail
  ): void {
    this.serviceAssignmentDetailService
      .allPilotDetailsByAssignmentId(serviceAssignments.serviceAssignmentId)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            var res = r.responseData;
            this.pilotServiceAssignmentDetails = res;
            this.loading = false;
          }
        },
        (error) => {
          this.loading = false;
          this.connectionError();
        }
      );
    this.submittingData = false;
  }
  createPilotServiceAssignmentDetail() {
    this.submittingData = true;
    this.loading = true;
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Saving Record",
    });
    const formData = this.pilotServiceAssignmentDetailForm.value;
    const postData = {
      pilotResourceId: this.selectedPilotProfile.id,
      tiedVehicleResourceId:
        this.selectedVehicleServiceAssignmentDetail.vehicleResourceId,
      serviceAssignmentId: formData.serviceAssignmentId,
    };
    this.serviceAssignmentDetailService
      .postPilotDetail(postData)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            this.messageService.add({
              summary: "Success",
              severity: "success",
              detail: "New Service  created",
            });
            //this.fetchAllPilotServiceAssignmentDetails();
            this.submittingData = false;
            this.loading = false;
            this.fetchAllMasterServiceAssignments();
            this.pilotServiceAssignmentDetailForm.reset();
            this.closeEditing();
          } else {
            this.messageService.add({
              severity: "error",
              summary: "Failed",
              detail: " Error: " + r.responseMsg,
            });
            this.submittingData = false;
            this.loading = false;
          }
        },
        (error) => {
          this.messageService.add({
            severity: "error",
            summary: "Failed",
            detail: "Connection Error " + error.error.message,
          });
          this.submittingData = false;
          this.loading = false;
        }
      );
    this.submittingData = false;
  }

  createPilotServiceAssignmentDetailReplacement() {
    this.submittingData = true;
    this.loading = true;
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Replacing Record",
    });
    const formData = this.pilotServiceAssignmentDetailReplaceForm.value;
    const postData = {
      newResourceId: this.selectedPilotProfile.id,
      oldResourceId: formData.oldResourceId,
      masterServiceAssignmentId: formData.masterServiceAssignmentId,
      reasonForReplacement: formData.reasonForReplacement,
    };
    this.serviceAssignmentDetailService
      .postPilotDetailReplacement(postData)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            this.messageService.add({
              summary: "Success",
              severity: "success",
              detail: "Replacement Successful",
            });
            //this.fetchAllPilotServiceAssignmentDetails();
            this.submittingData = false;
            this.loading = false;
            this.fetchAllMasterServiceAssignments();
            this.pilotServiceAssignmentDetailForm.reset();
            this.closeEditing();
          } else {
            this.messageService.add({
              severity: "error",
              summary: "Failed",
              detail: " Error: " + r.responseMsg,
            });
            this.submittingData = false;
            this.loading = false;
          }
        },
        (error) => {
          this.messageService.add({
            severity: "error",
            summary: "Failed",
            detail: "Connection Error " + error.error.message,
          });
          this.submittingData = false;
          this.loading = false;
        }
      );
    this.submittingData = false;
  }

  deletePilotServiceAssignmentDetail(
    ServiceAssignment: PilotServiceAssignmentDetail
  ): void {
    this.confirmationService.confirm({
      message: "Are you sure you want to delete " + "Service",
      accept: () => {
        this._deletePilotServiceAssignmentDetail(ServiceAssignment);
      },
    });
  }
  _deletePilotServiceAssignmentDetail(ServiceAssignment) {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Deleting Service record",
    });
    this.serviceAssignmentDetailService
      .deletePilotDetail(ServiceAssignment.id)
      .subscribe(
        async (result: any) => {
          await this.messageService.add({
            severity: "success",
            summary: "Deleted",
            detail: "Service record removed",
          });
          await this.fetchAllPilotServiceAssignmentDetails();
        },
        (error) => {
          this.connectionError();
        }
      );
  }
  editPilotServiceAssignmentDetail(
    serviceAssignments: PilotServiceAssignmentDetail
  ): void {
    this.formWrapper.nativeElement.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "start",
    });
    this.edPilotServiceAssignmentDetail = serviceAssignments;
    this.editingPilotServiceAssignmentDetail = true;
    // this.imageSrc = pilotProfiles.imageUrl;
    this.pilotServiceAssignmentDetailForm.patchValue({
      pilotResourceId: serviceAssignments.pilotResourceId,
      tiedVehicleResourceId: serviceAssignments.tiedVehicleResourceId,
    });
    // this.selectedPilotType = this.pilotTypes.find(
    //   (Type) => Type.id === pilotProfiles.pilotTypeId
    // );

    this.submitButtonLabel = "Update";
  }
  updatePilotServiceAssignmentDetail(): boolean {
    this.submittingData = true;
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Updating Record",
    });
    if (!this.edPilotServiceAssignmentDetail) {
      return false;
    }
    const formData = this.pilotServiceAssignmentDetailForm.value;
    const data = {
      pilotResourceId: this.selectedPilotProfile.id,
      tiedVehicleResourceId: this.selectedVehicleProfile.id,
    };
    this.serviceAssignmentDetailService
      .updatePilotDetail(this.edPilotServiceAssignmentDetail.id, data)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        () => {
          this.messageService.add({
            severity: "success",
            detail: "Registered Service Updated",
            summary: "Completed",
          });
          this.submittingData = false;
          this.closeEditing();
          this.fetchAllPilotServiceAssignmentDetails();
          this.pilotServiceAssignmentDetailForm.reset();
        },
        (error) => {
          this.connectionError();
          this.submittingData = false;
        }
      );
  }

  //Vehicle
  fetchAllVehicleServiceAssignmentDetails(): void {
    this.serviceAssignmentDetailService
      .allVehicleDetails()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            var res = r.responseData;
            //let veh = res.
            this.vehicleServiceAssignmentDetails = res;
            console.log("Vehicles: ", this.vehicleServiceAssignmentDetails);
            this.loading = false;
          }
        },
        (error) => {
          this.loading = false;
          this.connectionError();
        }
      );
    this.submittingData = false;
  }

  fetchAllVehicleServiceAssignmentDetailsByAssId(): void {
    this.serviceAssignmentDetailService
      .allVehicleDetailsByAssignmentId(this.getAssignId)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            var res = r.responseData;

            this.vehicleServiceAssignmentDetails = res;
            // let vehArr = this.vehicleServiceAssignmentDetailsFilter.filter(
            //   (x) => x.isTemporarilyHeld === true
            // );
            // this.vehicleServiceAssignmentDetails = vehArr;
            this.loading = false;
          }
        },
        (error) => {
          this.loading = false;
          this.connectionError();
        }
      );
    this.submittingData = false;
  }
  createVehicleServiceAssignmentDetail() {
    this.submittingData = true;
    this.loading = true;
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Saving Record",
    });
    const formData = this.vehicleServiceAssignmentDetailForm.value;
    const postData = {
      //pilotResourceId: this.selectedPilotProfile.id,
      vehicleResourceId: this.selectedVehicleProfile.id,
      serviceAssignmentId: formData.serviceAssignmentId,
    };
    this.serviceAssignmentDetailService
      .postVehicleDetail(postData)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            this.messageService.add({
              summary: "Success",
              severity: "success",
              detail: "New Service  created",
            });
            var getId = r.responseData.id;
            localStorage.setItem("serviceAssId", getId);
            //this.fetchAllVehicleServiceAssignmentDetails();
            this.submittingData = false;
            this.loading = false;
            //start

            //end
            //this.vehicleServiceAssignmentDetailForm.reset();
            this.fetchAllVehicleServiceAssignmentDetailsByAssId();
            this.fetchAllMasterServiceAssignments();
            this.closeEditing();
          } else {
            this.messageService.add({
              severity: "error",
              summary: "Failed",
              detail: " Error: " + r.responseMsg,
            });
            this.submittingData = false;
            this.loading = false;
          }
        },
        (error) => {
          this.messageService.add({
            severity: "error",
            summary: "Failed",
            detail: "Connection Error " + error.error.message,
          });
          this.submittingData = false;
          this.loading = false;
        }
      );
    this.submittingData = false;
  }

  createVehicleServiceAssignmentDetailReplacement() {
    this.submittingData = true;
    this.loading = true;
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Replacing Record",
    });
    const formData = this.vehicleServiceAssignmentDetailReplaceForm.value;
    const postData = {
      newResourceId: this.selectedVehicleProfile.id,
      oldResourceId: formData.oldResourceId,
      masterServiceAssignmentId: formData.masterServiceAssignmentId,
      reasonForReplacement: formData.reasonForReplacement,
    };
    this.serviceAssignmentDetailService
      .postVehicleDetailReplacement(postData)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            this.messageService.add({
              summary: "Success",
              severity: "success",
              detail: " Resource Replaced",
            });
            var getId = r.responseData.id;
            localStorage.setItem("serviceAssId", getId);
            //this.fetchAllVehicleServiceAssignmentDetails();
            this.submittingData = false;
            this.loading = false;
            //start

            //end
            //this.vehicleServiceAssignmentDetailForm.reset();
            this.fetchAllVehicleServiceAssignmentDetailsByAssId();
            this.closeEditing();
          } else {
            this.messageService.add({
              severity: "error",
              summary: "Failed",
              detail: " Error: " + r.responseMsg,
            });
            this.submittingData = false;
            this.loading = false;
          }
        },
        (error) => {
          this.messageService.add({
            severity: "error",
            summary: "Failed",
            detail: "Connection Error " + error.error.message,
          });
          this.submittingData = false;
          this.loading = false;
        }
      );
    this.submittingData = false;
  }

  deleteVehicleServiceAssignmentDetail(
    ServiceAssignment: VehicleServiceAssignmentDetail
  ): void {
    this.confirmationService.confirm({
      message: "Are you sure you want to delete " + "Service",
      accept: () => {
        this._deleteVehicleServiceAssignmentDetail(ServiceAssignment);
      },
    });
  }
  _deleteVehicleServiceAssignmentDetail(ServiceAssignment) {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Deleting Service record",
    });
    this.serviceAssignmentDetailService
      .deleteVehicleDetail(ServiceAssignment.id)
      .subscribe(
        async (result: any) => {
          await this.messageService.add({
            severity: "success",
            summary: "Deleted",
            detail: "Service record removed",
          });
          await this.fetchAllVehicleServiceAssignmentDetails();
        },
        (error) => {
          this.connectionError();
        }
      );
  }
  editVehicleServiceAssignmentDetail(
    serviceAssignments: VehicleServiceAssignmentDetail
  ): void {
    this.formWrapper.nativeElement.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "start",
    });
    this.edVehicleServiceAssignmentDetail = serviceAssignments;
    this.editingVehicleServiceAssignmentDetail = true;
    // this.imageSrc = pilotProfiles.imageUrl;
    this.vehicleServiceAssignmentDetailForm.setValue({
      //pilotResourceId: serviceAssignments.pilotResourceId,
      vehicleResourceId: serviceAssignments.vehicleResourceId,
    });
    // this.selectedPilotType = this.pilotTypes.find(
    //   (Type) => Type.id === pilotProfiles.pilotTypeId
    // );

    this.submitButtonLabel = "Update";
  }
  updateVehicleServiceAssignmentDetail(): boolean {
    this.submittingData = true;
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Updating Record",
    });
    if (!this.edVehicleServiceAssignmentDetail) {
      return false;
    }
    const formData = this.vehicleServiceAssignmentDetailForm.value;
    const data = {
      //pilotResourceId: this.selectedPilotProfile.id,
      vehicleResourceId: this.selectedVehicleProfile.id,
    };
    this.serviceAssignmentDetailService
      .updateVehicleDetail(this.edPilotServiceAssignmentDetail.id, data)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        () => {
          this.messageService.add({
            severity: "success",
            detail: "Registered Service Updated",
            summary: "Completed",
          });
          this.submittingData = false;
          this.closeEditing();
          this.fetchAllPilotServiceAssignmentDetails();
          this.pilotServiceAssignmentDetailForm.reset();
        },
        (error) => {
          this.connectionError();
          this.submittingData = false;
        }
      );
  }

  //Passenger
  fetchAllPassengers(): void {
    this.serviceAssignmentDetailService
      .allPassengerDetails()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            var res = r.responseData;
            this.passengers = res;
            console.log(this.passengers);
            this.loading = false;
          }
        },
        (error) => {
          this.loading = false;
          this.connectionError();
        }
      );
    this.submittingData = false;
  }
  fetchAllPassengersById(passenger: Passenger): void {
    this.serviceAssignmentDetailService
      .allPassengerDetailsByAssignmentId(passenger.serviceAssignmentId)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            var res = r.responseData;
            this.passengers = res;
            this.loading = false;
          }
        },
        (error) => {
          this.loading = false;
          this.connectionError();
        }
      );
    this.submittingData = false;
  }
  createPassenger() {
    this.submittingData = true;
    this.loading = true;
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Saving Record",
    });
    const formData = this.passengerForm.value;
    const postData = {
      passengerTypeId: this.selectedPassengerType.id,
      firstname: formData.firstname,
      lastname: formData.lastname,
      othername: formData.othername,
      instagramHandle: formData.instagramHandle,
      email: formData.email,
      mobile: formData.mobile,
      facebookHandle: formData.facebookHandle,
      twitterHandle: formData.twitterHandle,
      serviceAssignmentId: formData.serviceAssignmentId,
      //firstname: formData.firstname,
    };
    this.serviceAssignmentDetailService
      .postPassenger(postData)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            this.messageService.add({
              summary: "Success",
              severity: "success",
              detail: "New Service  created",
            });
            //this.fetchAllPassengers();
            this.submittingData = false;
            this.passengerForm.reset();
            this.loading = false;
            this.closeEditing();
          } else {
            this.messageService.add({
              severity: "error",
              summary: "Failed",
              detail: " Error: " + r.responseMsg,
            });
            this.submittingData = false;
            this.loading = false;
          }
        },
        (error) => {
          this.messageService.add({
            severity: "error",
            summary: "Failed",
            detail: "Connection Error " + error.error.message,
          });
          this.submittingData = false;
          this.loading = false;
        }
      );
    this.submittingData = false;
  }

  deletePassenger(passenger: Passenger): void {
    this.confirmationService.confirm({
      message: "Are you sure you want to delete " + "Service",
      accept: () => {
        this._deletePassenger(passenger);
      },
    });
  }
  _deletePassenger(ServiceAssignment) {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Deleting Service record",
    });
    this.serviceAssignmentDetailService
      .deletePassenger(ServiceAssignment.id)
      .subscribe(
        async (result: any) => {
          await this.messageService.add({
            severity: "success",
            summary: "Deleted",
            detail: "Service record removed",
          });
          //await
          //this.fetchAllPilotServiceAssignmentDetails();
        },
        (error) => {
          this.connectionError();
        }
      );
  }
  editPassengers(passengers: Passenger): void {
    this.formWrapper.nativeElement.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "start",
    });
    this.edPassenger = passengers;
    this.editingPassenger = true;
    // this.imageSrc = pilotProfiles.imageUrl;
    this.passengerForm.patchValue({
      passengerTypeId: passengers.passengerTypeId,
      //passengerTypeId: null,
      firstname: passengers.firstname,
      lastname: passengers.lastname,
      othername: passengers.othername,
      instagramHandle: passengers.instagramHandle,
      email: passengers.email,
      mobile: passengers.mobile,
      facebookHandle: passengers.facebookHandle,
      twitterHandle: passengers.twitterHandle,
    });
    this.selectedPassengerType = this.passengerTypes.find(
      (Type) => Type.id === passengers.passengerTypeId
    );

    this.submitButtonLabel = "Update";
  }
  updatePassenger(): boolean {
    this.submittingData = true;
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Updating Record",
    });
    if (!this.edPassenger) {
      return false;
    }
    const formData = this.passengerForm.value;
    const data = {
      passengerTypeId: this.selectedPassengerType.id,
      firstname: formData.firstname,
      lastname: formData.lastname,
      othername: formData.othername,
      instagramHandle: formData.instagramHandle,
      email: formData.email,
      mobile: formData.mobile,
      facebookHandle: formData.facebookHandle,
      twitterHandle: formData.twitterHandle,
    };
    this.serviceAssignmentDetailService
      .updatePassenger(this.edPassenger.id, data)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        () => {
          this.messageService.add({
            severity: "success",
            detail: "Registered Service Updated",
            summary: "Completed",
          });
          this.submittingData = false;
          this.closeEditing();
          //this.fetchAllPilotServiceAssignmentDetails();
          this.passengerForm.reset();
        },
        (error) => {
          this.connectionError();
          this.submittingData = false;
        }
      );
  }

  //Editd

  editVehicleServiceAssignment(
    masterServiceAssignments: MasterServiceAssignment
  ) {
    this.selectedmasterServiceAssignment = null;
    this.selectedmasterServiceAssignment = masterServiceAssignments;
    this.vehicleServiceAssignmentDetailForm.setValue({
      serviceAssignmentId: masterServiceAssignments.id,
      vehicleResourceId: null,
    });
    this.serviceAssignmentDetailService
      .allVehicleDetailsByAssignmentId(masterServiceAssignments.id)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            var res = r.responseData;
            this.vehicleServiceAssignmentDetailsFilter = res;
            let vehArr = this.vehicleServiceAssignmentDetailsFilter.filter(
              (x) => x.isTemporarilyHeld === true
            );
            this.vehicleServiceAssignmentDetails = vehArr;
            //this.vehicleServiceAssignmentDetails = res;
            this.loading = false;
          }
        },
        (error) => this.connectionError()
      );

    const today = new Date();
    if (
      new Date(masterServiceAssignments.pickupDate) < today &&
      masterServiceAssignments.readyStatus === false
    ) {
      this.expiredServiceProductDialog = true;
    } else if (masterServiceAssignments.saExecutionStatus === 2) {
      this.closedProductDialog = true;
    } else {
      this.vehicleProductDialog = true;
    }
    console.log("pickup" + new Date(masterServiceAssignments.pickupDate));
    console.log("today" + today);
    //this.vehicleServiceAssignmentDetailForm.reset();
  }
  //one can use patvhValue instead of setValue for if not all fields are wanted
  editArmedEscortServiceAssignment(
    masterServiceAssignments: MasterServiceAssignment
  ) {
    this.selectedmasterServiceAssignment = null;
    this.selectedmasterServiceAssignment = masterServiceAssignments;

    this.armedEscortServiceAssignmentDetailForm.setValue({
      serviceAssignmentId: masterServiceAssignments.id,
      armedEscortResourceId: null,
      // sMORouteId: masterServiceAssignments.sMORouteId,
      // sMORegionId: masterServiceAssignments.sMORegionId,
    });
    this.serviceAssignmentDetailService
      .allArmedEscortDetailsByAssignmentId(masterServiceAssignments.id)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            var res = r.responseData;
            this.armedEscortServiceAssignmentDetailsFilter = res;
            let vehArr = this.armedEscortServiceAssignmentDetailsFilter.filter(
              (x) => x.isTemporarilyHeld === true
            );
            this.armedEscortServiceAssignmentDetails = vehArr;
            //this.armedEscortServiceAssignmentDetails = res;
            this.loading = false;
          }
        },
        (error) => this.connectionError()
      );
    //this.armedEscortProductDialog = true;
    const today = new Date();
    if (
      new Date(masterServiceAssignments.pickupDate) < today &&
      masterServiceAssignments.readyStatus === false
    ) {
      this.expiredServiceProductDialog = true;
    } else if (masterServiceAssignments.saExecutionStatus === 2) {
      this.closedProductDialog = true;
    } else {
      this.armedEscortProductDialog = true;
    }
  }
  editCommanderServiceAssignment(
    masterServiceAssignments: MasterServiceAssignment
  ) {
    this.selectedmasterServiceAssignment = null;
    this.loading = true;
    this.selectedmasterServiceAssignment = masterServiceAssignments;
    this.commanderServiceAssignmentDetailForm.patchValue({
      serviceAssignmentId: masterServiceAssignments.id,
      //commanderResourceId: null,
      //tiedVehicleResourceId: null,
    });
    this.serviceAssignmentDetailService
      .allCommanderDetailsByAssignmentId(masterServiceAssignments.id)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            var res = r.responseData;
            // this.commanderServiceAssignmentDetailsFilter = res;
            // let vehArr = this.commanderServiceAssignmentDetailsFilter.filter(
            //   (x) => x.isTemporarilyHeld === true
            // );
            // this.commanderServiceAssignmentDetails = vehArr;
            this.commanderServiceAssignmentDetails = res;
            this.loading = false;
          }
        },
        (error) => this.connectionError()
      );
    this.serviceAssignmentDetailService
      .allVehicleDetailsByAssignmentId(masterServiceAssignments.id)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            var res = r.responseData;
            this.vehicleServiceAssignmentDetails = res;
            this.loading = false;
          }
        },
        (error) => this.connectionError()
      );
    const today = new Date();
    if (
      new Date(masterServiceAssignments.pickupDate) < today &&
      masterServiceAssignments.readyStatus === false
    ) {
      this.expiredServiceProductDialog = true;
    } else if (masterServiceAssignments.saExecutionStatus === 2) {
      this.closedProductDialog = true;
    } else {
      this.commanderProductDialog = true;
    }
    //this.commanderProductDialog = true;
  }
  editPilotServiceAssignment(
    masterServiceAssignments: MasterServiceAssignment
  ) {
    this.selectedmasterServiceAssignment = null;
    this.selectedmasterServiceAssignment = masterServiceAssignments;
    this.pilotServiceAssignmentDetailForm.patchValue({
      serviceAssignmentId: masterServiceAssignments.id,
      //pilotResourceId: null,
      //vehicleResourceId: null,
    });
    this.serviceAssignmentDetailService
      .allPilotDetailsByAssignmentId(masterServiceAssignments.id)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            var res = r.responseData;
            this.pilotServiceAssignmentDetailsFilter = res;
            let vehArr = this.pilotServiceAssignmentDetailsFilter.filter(
              (x) => x.isTemporarilyHeld === true
            );
            this.pilotServiceAssignmentDetails = vehArr;
            //this.pilotServiceAssignmentDetails = res;
            this.loading = false;
          }
        },
        (error) => this.connectionError()
      );
    this.serviceAssignmentDetailService
      .allVehicleDetailsByAssignmentId(masterServiceAssignments.id)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            var res = r.responseData;
            this.vehicleServiceAssignmentDetails = res;
            this.loading = false;
          }
        },
        (error) => this.connectionError()
      );
    const today = new Date();
    if (
      new Date(masterServiceAssignments.pickupDate) < today &&
      masterServiceAssignments.readyStatus === false
    ) {
      this.expiredServiceProductDialog = true;
    } else if (masterServiceAssignments.saExecutionStatus === 2) {
      this.closedProductDialog = true;
    } else {
      this.pilotProductDialog = true;
    }
    //this.pilotProductDialog = true;
  }
  editPassenger(masterServiceAssignments: MasterServiceAssignment) {
    this.selectedmasterServiceAssignment = masterServiceAssignments;
    this.passengerForm.patchValue({
      serviceAssignmentId: masterServiceAssignments.id,
      //pilotResourceId: null,
      //vehicleResourceId: null,
    });
    this.serviceAssignmentDetailService
      .allPassengerDetailsByAssignmentId(masterServiceAssignments.id)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            var res = r.responseData;
            this.passengers = res;
            this.loading = false;
          }
        },
        (error) => this.connectionError()
      );
    const today = new Date();
    if (
      new Date(masterServiceAssignments.pickupDate) < today &&
      masterServiceAssignments.readyStatus === false
    ) {
      this.expiredServiceProductDialog = true;
    } else if (masterServiceAssignments.saExecutionStatus === 2) {
      this.closedProductDialog = true;
    } else {
      this.passengerProductDialog = true;
    }
    //this.passengerProductDialog = true;
  }

  //Replacement
  ReplaceEscort(serviceAssignments: ArmedEscortServiceAssignmentDetail) {
    this.replaceAEscort = true;
    this.selectedArmedEscortServiceAssignmentDetail = serviceAssignments;
    this.armedEscortServiceAssignmentDetailReplaceForm.patchValue({
      oldResourceId: serviceAssignments.armedEscortResourceId,
      masterServiceAssignmentId: serviceAssignments.serviceAssignmentId,
    });
    //alert("Api not yet available");
  }
  ReplaceCommander(serviceAssignments: CommanderServiceAssignmentDetail) {
    this.replaceCommander = true;
    this.selectedCommanderServiceAssignmentDetail = serviceAssignments;
    this.commanderServiceAssignmentDetailReplaceForm.patchValue({
      oldResourceId: serviceAssignments.commanderResourceId,
      masterServiceAssignmentId: serviceAssignments.serviceAssignmentId,
    });
    //alert("Api not yet available");
  }
  ReplacePilot(serviceAssignments: PilotServiceAssignmentDetail) {
    this.replacePilot = true;
    this.selectedPilotServiceAssignmentDetail = serviceAssignments;
    this.pilotServiceAssignmentDetailReplaceForm.patchValue({
      oldResourceId: serviceAssignments.pilotResourceId,
      masterServiceAssignmentId: serviceAssignments.serviceAssignmentId,
    });
    //alert("Api not yet available");
  }
  ReplaceVehicle(serviceAssignments: VehicleServiceAssignmentDetail) {
    this.replaceVehicle = true;
    this.selectedVehicleServiceAssignmentDetail = serviceAssignments;
    this.vehicleServiceAssignmentDetailReplaceForm.patchValue({
      oldResourceId: serviceAssignments.vehicleResourceId,
      masterServiceAssignmentId: serviceAssignments.serviceAssignmentId,
    });
    //alert("Api not yet available");
  }

  hideDialog() {
    this.commanderProductDialog = false;
    this.armedEscortProductDialog = false;
    this.vehicleProductDialog = false;
    this.pilotProductDialog = false;
    this.passengerProductDialog = false;
    this.expiredServiceProductDialog = false;

    this.replaceAEscort = false;
    this.replaceCommander = false;
    this.replaceVehicle = false;
    this.replacePilot = false;
    this.cancel();
    //this.submitted = false;
  }

  RunVehicleMessage() {
    this.msgs = [];
    this.msgs.push({
      severity: "info",
      summary: "Notice:",
      detail:
        "Upload a visual representative of what the beat type looks like.",
    });
  }

  //Map location
  get StreetValue() {
    return this.serviceAssignmentForm.get("Street").value;
  }

  get LGAValue() {
    return this.theLga ? this.theLga.name : "";
  }

  get StateValue() {
    return this.theState ? this.theState.name : "";
  }
  //drop
  get dropStreetValue() {
    return this.serviceAssignmentForm.get("dropStreet").value;
  }

  get dropLGAValue() {
    return this.theDropLga ? this.theDropLga.name : "";
  }

  get dropStateValue() {
    return this.theDropState ? this.theDropState.name : "";
  }

  ConfirmLocationCordinates() {
    // this.openConfirmLocationDialogue = true;
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Getting Location",
    });

    let pickOffaddress: string =
      this.serviceAssignmentForm.get("Street").value +
      "," +
      this.theLga.name +
      "," +
      this.theState.name;

    let dropOffaddress: string =
      this.serviceAssignmentForm.get("dropStreet").value +
      "," +
      this.theDropLga.name +
      "," +
      this.theDropState.name;

    this.GetPickOffInputtedLocationCordinates(pickOffaddress);
    this.GetInputtedDropoffLocationCordinates(dropOffaddress);
    this.getReverseGeocodingData(this.currectLat, this.currentlon);


  }

  GetPickOffInputtedLocationCordinates(address: string) {
    this.serviceAssignmentService
      .GetStartAddressGeocodingLocation(address)
      .subscribe(
        async (data) => {
          if (data.status === "OK") {
            this.messageService.add({
              severity: "success",
              detail: "Successful",
              summary: "Completed",
            });
          } else {
            this.messageService.add({
              severity: "error",
              summary: "Notice",
              detail:
                "Unable to get address cordinates at the moment.. " +
                data.error_message,
            });
            return;
          }

          this.locLatitude = data.results[0].geometry.location.lat;
          this.locLongitude = data.results[0].geometry.location.lng;
          this.locGeometry = data.results[0].formatted_address;
          this.initMap();
          console.log(
            this.locLatitude +
            " and " +
            this.locLongitude +
            " geo " +
            this.locGeometry
          );
          this.submitButtonLabel = "Assign";
          this.isShowAssignButton = true;
        },
        (error) => {
          console.log("Error: " + JSON.stringify(error));
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail:
              "Unable to get address cordinates at the moment.. Reason: [" +
              error.error.message +
              "]",
          });
        }
      );
  }

  GetInputtedDropoffLocationCordinates(address: string) {
    this.serviceAssignmentService
      .GetDropAddressGeocodingLocation(address)
      .subscribe(
        async (data) => {
          if (data.status != "OK") {
            this.messageService.add({
              severity: "error",
              summary: "Notice",
              detail:
                "Unable to get address cordinates at the moment.. " +
                data.error_message,
            });
            return;
          }
          this.droplocLatitude = data.results[0].geometry.location.lat;
          this.droplocLongitude = data.results[0].geometry.location.lng;
          this.droplocGeometry = data.results[0].formatted_address;
          console.log(this.droplocGeometry);
          this.initMap();
        },
        (error) => {
          console.log("Error: " + JSON.stringify(error));
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail:
              "Unable to get address cordinates at the moment.. Reason: [" +
              error.error.message +
              "]",
          });
        }
      );
  }

  //Get Distance between locs
  haversine_distance(mk1, mk2) {
    //var R = 3958.8; // Radius of the Earth in miles
    var R = 6371.071; //use 6371.0710 to get the Radius in kilometers
    var rlat1 = mk1.position.lat() * (Math.PI / 180); // Convert degrees to radians
    var rlat2 = mk2.position.lat() * (Math.PI / 180); // Convert degrees to radians
    var difflat = rlat2 - rlat1; // Radian difference (latitudes)
    var difflon = (mk2.position.lng() - mk1.position.lng()) * (Math.PI / 180); // Radian difference (longitudes)

    var d =
      2 *
      R *
      Math.asin(
        Math.sqrt(
          Math.sin(difflat / 2) * Math.sin(difflat / 2) +
          Math.cos(rlat1) *
          Math.cos(rlat2) *
          Math.sin(difflon / 2) *
          Math.sin(difflon / 2)
        )
      );
    return d;
  }

  // Initialize and add the map
  initMap(): void {
    if (
      this.locLatitude &&
      this.locLongitude &&
      this.droplocLatitude &&
      this.droplocLongitude
    ) {
      // The location of pickup
      const location = { lat: this.locLatitude, lng: this.locLongitude };
      const droplocation = {
        lat: this.droplocLatitude,
        lng: this.droplocLongitude,
      };
      // The map, centered at pickup Loc
      const map = new google.maps.Map(
        document.getElementById("map") as HTMLElement,
        {
          zoom: 8,
          center: location,
        }
      );

      // The marker, positioned at Loc
      const marker = new google.maps.Marker({
        position: location,
        map: map,
      });

      const dropmarker = new google.maps.Marker({
        position: droplocation,
        map: map,
      });

      // Calculate and display the distance between markers
      const distance = this.haversine_distance(marker, dropmarker);
      const stringToconv = distance.toFixed(2);
      this.distance = Number(stringToconv) * 1.609;
      console.log(this.distance + " Km ");
    }
  }
  async getReverseGeocodingData(lat, lng) {
    var latlng = new google.maps.LatLng(lat, lng);
    // This is making the Geocode request
    var geocoder = new google.maps.Geocoder();
    geocoder.geocode({ location: latlng }, (results, status) => {
      if (status !== google.maps.GeocoderStatus.OK) {
        alert(status);
      }
      // This is checking to see if the Geoeode Status is OK before proceeding
      if (status == google.maps.GeocoderStatus.OK) {
        console.log(results);
        var address = results[0].formatted_address;
        console.log(
          "Lat of current location: " +
          this.currectLat +
          " Lon of current location: " +
          this.currentlon
        );
        console.log("Address of current location: " + address);
      }
    });
  }
  async GetCurrentLocation() {
    var uri =
      "https://www.googleapis.com/geolocation/v1/geolocate?key=AIzaSyCQuetprs2UHb_zKSXznyumyfvw95ERCDY";
    var xmlhttp = new XMLHttpRequest();
    xmlhttp.onreadystatechange = function () {
      if (xmlhttp.readyState == 4 && xmlhttp.status == 200) {
        let result = JSON.parse(xmlhttp.responseText);
        localStorage.setItem("longitude", result.location.lng);
        localStorage.setItem("latitude", result.location.lat);
        //localStorage.setItem("address", result.results[0].formatted_address);

        console.log(
          `current longitude ${result.location.lng} and current latitude ${result.location.lat} address ${result.formatted_address}`
        );
        //document.getElementById("btnVerifyGeo").click();
      } else if (xmlhttp.status == 404) {
        alert(
          "Unable to get current location cordinates = " + xmlhttp.status + "."
        );
      }
    };
    xmlhttp.open("POST", uri, true);
    xmlhttp.send();
    xmlhttp.onerror = function () {
      alert(
        "Unable to get current location cordinates = " +
        xmlhttp.statusText +
        "."
      );
    };
    this.currectLat = Number(localStorage.getItem("latitude"));
    this.currentlon = Number(localStorage.getItem("longitude"));
    console.log("For Lats and Lons: ", this.currectLat + " " + this.currentlon);
  }

  displayJPM(id: number) {
    this.viewInvoiceRef = this.dialogService.open(JmpInvoiceComponent, {
      width: "950px",
      contentStyle: { "max-width": "100vw", height: "90vh", overflow: "auto" },
      baseZIndex: 100,
      data: { id },
    });

    this.viewInvoiceRef.onClose.subscribe(() => { });
  }

  createPreformalInvoice() {
    this.submittingData = true;
    this.loading = true;
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Adding Preformal Invoice",
    });
    var totalPrice = this.quantityAdoc * this.selectedPreferentialPricing.price;
    var getVatAmount = this.getVat * totalPrice;
    const postData = {
      unitPrice: this.selectedPreferentialPricing.price,
      quantity: this.quantityAdoc,
      discount: this.selectedContractService.discount,
      value: getVatAmount + totalPrice,
      vat: getVatAmount,
      billableAmount:
        this.quantityAdoc * this.selectedPreferentialPricing.price,
      //dateToBeSent: this.preformalInvoiceForm.get("invoiceSendDate").value,
      dateToBeSent: new Date(),
      description: this.serviceAssignmentForm.get("description").value,
      startDate: new Date(),
      endDate: new Date(),
      masterServiceAssignmentId: this.getAssignId,
      // endDate: this.selectedContractService.contractEndDate,
      customerDivisionId: this.selectedCustomerDivision.id,
      contractServiceId: this.selectedContractService.id,
    };
    this.invoiceService
      .postAdHocInvoice(postData)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            this.messageService.add({
              summary: "Success",
              severity: "success",
              detail: "Preformal Invoice Added",
            });
            this.submittingData = false;
            this.loading = false;
            this.cancel();
          } else {
            this.messageService.add({
              severity: "error",
              summary: "Failed",
              detail: " Error: " + r.responseMsg,
            });
            this.submittingData = false;
            this.loading = false;
          }
        },
        (error) => {
          this.messageService.add({
            severity: "error",
            summary: "Failed",
            detail: "Connection Error " + error.error.message,
          });
          this.submittingData = false;
          this.loading = false;
        }
      );
    this.submittingData = false;
  }

  closeEditing() {
    // this.editingPilotProfile = false;
    // this.pilotProfileForm.reset();
    // this.edPilotProfile = null;

    // this.editingArmedEscortProfile = false;
    // this.armedEscortProfileForm.reset();
    // this.edArmedEscortProfile = null;

    // this.editingCommanderProfile = false;
    // this.commanderRegisterForm.reset();
    // this.edCommanderProfile = null;

    this.editingPassenger = false;
    this.passengerForm.reset();
    this.edPassenger = null;

    this.submitButtonLabel = "Assign";
    //this.submitVehicleButtonLabel = "Create";
  }
  cancel() {
    this.commanderServiceAssignmentDetailForm.reset();
    this.vehicleServiceAssignmentDetailForm.reset();
    this.pilotServiceAssignmentDetailForm.reset();
    this.armedEscortServiceAssignmentDetailForm.reset();
    this.commanderServiceAssignmentDetailReplaceForm.reset();
    this.vehicleServiceAssignmentDetailReplaceForm.reset();
    this.pilotServiceAssignmentDetailReplaceForm.reset();
    this.armedEscortServiceAssignmentDetailReplaceForm.reset();
    this.passengerForm.reset();
  }
  private connectionError() {
    this.messageService.add({
      severity: "error",
      summary: "Failed",
      detail: "Connection Error, Please try again",
    });
  }
  ngOnDestroy(): void {
    this.unsubscriber$.complete();
    this.unsubscriber$.unsubscribe();
  }
}
