import { ConvergenceDevice } from "./convergence";

export enum BankAccountTypeEnum {
  SAVINGS = 1,
  CURRENT,
  LOAN,
}

export interface Supplier {
  id?: number;
  supplierName: string;
  description: string;
  supplierCategoryId?: number;
  supplierCategory?: SupplierCategory;
  supplierEmail: string;
  mobileNumber: string;
  stateId?: number;
  state?: object;
  lgaId?: number;
  lga?: object;
  street: string;
  address: string;
  imageUrl: string;
  payableAccountId?: number;
  primaryContactName: string;
  primaryContactEmail: string;
  primaryContactMobile: string;
  primaryContactGender: string;
  supplierDevices?: ConvergenceDevice[];
  banks: SupplierBank[];
}

export interface SupplierBank {
  id: number;
  supplierId?: number;
  bankId?: number;
  accountNumber: string;
  accountName: string;
  accountType: BankAccountTypeEnum;
  isPrimaryAccount: boolean;
  paystackTransferReference: string;
  isDeleted: boolean;
  createdById?: number;
  createdAt: Date;
}

export interface SupplierCategory {
  id?: number;
  categoryName: string;
  description: string;
}

export interface SupplierServ {
  id?: number;
  serviceName: string;
  description: string;
  make: string;
  model: string;
  modelNumber: number;
  serialNumber: number;
  identificationNumber: number;
  referenceNumber1: string;
  referenceNumber2: string;
  referenceNumber3: string;
  unitCostPrice: string;
  standardDiscount?: string;
  averagePrice: string;
  supplierId?: number;
  supplier?: object;
  frontViewImage: string;
  leftViewImage: string;
  rightViewImage: string;
  rearViewImage: string;
  topViewImage: string;
  interiorViewImage: string;
}

export interface AddSupplierBankAccountVM {
  supplierId: number;
  bankId: number;
  bankAccountName: string;
  accountType: BankAccountTypeEnum;
  accountNumber: string;
  isPrimaryAccount: boolean;
  bankCode: string;
}
