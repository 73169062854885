import { Component, OnInit } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { AccountStatement, ContractService, CustomerDivision, Invoice } from 'src/app/interfaces/home';
import { CustomerDivisionService } from 'src/app/services/customer-division.service';
import { QuoteService } from 'src/app/services/quote.service';
import { ReceiptService } from 'src/app/services/receipt.service';

@Component({
  selector: 'app-view-account-statement',
  templateUrl: './view-account-statement.component.html',
  styleUrls: ['./view-account-statement.component.scss']
})
export class ViewAccountStatementComponent implements OnInit {

  statements: any[];
  statementForm: any;
  customerDivision: CustomerDivision;
  accountAlias: string;

  currentDate = new Date(Date.now())
  formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'NGN'
  })

  totalDebit = 0.0
  totalCredit = 0.0

  constructor(
    public receiptService: ReceiptService,
    public messageService: MessageService,
    public modalConfig: DynamicDialogConfig,
    private accountStatementModalRef: DynamicDialogRef,
    public confirmationService: ConfirmationService,
    private customerDivisionService: CustomerDivisionService,
    private quoteService: QuoteService,
  ) { }

  ngOnInit(): void {
    this.statements = this.modalConfig.data.statements;
    this.statementForm = this.modalConfig.data.statementForm;
    this.accountAlias = this.modalConfig.data.accountAlias

    this.setupStatement()
    this.fetchCustomerInformation()
  }
  
  setupStatement(){
    let balance = 0.0
    let totalDebit = 0.0
    let totalCredit = 0.0

    for(const statement of this.statements){

      totalDebit += statement.debit
      totalCredit += statement.credit
      balance = balance + statement.debit - statement.credit 
      statement.balance = balance
    }

    this.totalCredit = totalCredit
    this.totalDebit = totalDebit
  }

  fetchCustomerInformation(){
    this.customerDivisionService.getCustomerDivisionByDTrackCustomerNumber(this.accountAlias)
    .subscribe(async (res) => {
      this.customerDivision = res.responseData;
    }, error => {
      this.connectionError()
    })
  }

  private connectionError() {
    this.messageService.add({severity:'error', summary: 'Failed',
        detail:'Connection Error, Please try again'});
  }

  printPage(){
    window.print()
  }

  confirmSendEmail() {
    this.accountStatementModalRef.close()
    this.confirmationService.confirm({
      message: 'Are you sure you want to send this statement ?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.sendStatement();
      },
      reject: (type) => {
        this.messageService.add({severity:'error', summary:'Rejected', detail:'Declined Sending Statement'});
      }
    });
  }

  sendStatement(){
    this.messageService.add({severity:'info', summary: 'Notice', detail:'Sending Statement'});
    this.sendMail(this.customerDivision)
  }

  sendMail(customerDivision: CustomerDivision){

    let statementsTemp = ''
    let balance = 0.0
    let totalDebit = 0.0
    let totalCredit = 0.0

    for(const statement of this.statements){

      totalDebit += statement.debit
      totalCredit += statement.credit
      balance = balance + statement.debit - statement.credit 

      statementsTemp += 
      `
      <tr>
          <td style="border: 1px solid #dddddd; text-align: center; padding: 8px;">${new Date(statement?.transactionDate).toDateString()}</td>
          <td style="border: 1px solid #dddddd; text-align: center; padding: 8px;">${statement?.accountMasterId == 0 ? '-' : statement?.accountMasterId}</td>
          <td style="border: 1px solid #dddddd; text-align: center; padding: 8px;">${statement?.description}</td>
          <td style="border: 1px solid #dddddd; text-align: center; padding: 8px;">${statement?.debit !== 0 ? this.formatter.format(statement.debit) : '-'}</td>
          <td style="border: 1px solid #dddddd; text-align: center; padding: 8px;">${statement?.credit !== 0 ? this.formatter.format(statement.credit) : '-'}</td>
          <td style="border: 1px solid #dddddd; text-align: center; padding: 8px;">${this.formatter.format(balance)}</td>
      </tr>
      `
    }

    const innerBody = 
        `
        <div style="border: black 2px solid; border-radius: 5px; width: 850px; margin: auto">
            <div style="background-color: rgb(255, 255, 255); margin: 15px;">
            
            <table style="width: 100%;">
                <tr>
                    <td>
                        <div style="margin: 0px;">
                            <img src="https://halobiz.herokuapp.com/assets/layout/images/HALOGENLOGO.png" height="150px"/>
                        </div>
                        <div>Enabling Security In An Open World</div>
                        <div>RC 199684</div>
                    </td>
                    <td>
                        <div style="margin: 0px 8px; float: right;">
                            <h3>STATEMENT OF ACCOUNT</h3>
                            <p>DATE: ${this.currentDate.toDateString()}</p>
                            <p>From : ${this.statementForm.get('transactionStartDate').value}</p>
                            <p>To : ${this.statementForm.get('transactionEndDate').value}</p>
                            <p>Currency : NGN</p>
                        </div>
                    </td>
                </tr>
            </table>
        
            <hr>
        
            <div style="margin-bottom: 30px;">
                <h4>
                    HALOGEN SECURITY COMPANY
                </h4>
                <p><i class="pi pi-mobile"></i> (Phone) +234-1-3429012, +234-1-3429021</p>
                <p><i class="pi pi-envelope"></i> (Email) info@halogen-group.com</p>
                <p><i class="pi pi-map-marker"></i> (Address) 19B, Mobolaji Bank Anthony Way Ikeja, Lagos</p>
            </div>
        
            <table style="width: 100%; margin-bottom: 15px;">
                <tr>
                    <td>
                        <div>
                            <h4>SEND TO :</h4>
                            <p><i class="pi pi-user"></i> (Client) ${customerDivision.divisionName}</p>
                            <p><i class="pi pi-map-marker"></i> (Address) ${customerDivision.address}</p>
                        </div>
                    </td>
                    <td>

                    </td>
                </tr>
            </table>
        
            <div style="border: black 1px solid; padding: 10px 10px; border-radius: 5px;">
                <h4 style="margin-left: 10px;">STATEMENT OF ACCOUNT DETAILS (N)</h4>
                <table style="font-size: 0.8rem; font-family: arial, sans-serif; border-collapse: collapse; width: 100%; border: solid 0.5px rgba(0, 0, 0, 0.2)">
                    <tr>
                        <th style="background-color: #dddddd;border: 1px solid #dddddd; text-align: center; padding: 8px;">Date</th>
                        <th style="background-color: #dddddd;border: 1px solid #dddddd; text-align: center; padding: 8px;">Reference Number</th>
                        <th style="background-color: #dddddd;border: 1px solid #dddddd; text-align: center; padding: 8px;">Details</th>
                        <th style="background-color: #dddddd;border: 1px solid #dddddd; text-align: center; padding: 8px;">Debit</th>
                        <th style="background-color: #dddddd;border: 1px solid #dddddd; text-align: center; padding: 8px;">Credit</th>
                        <th style="background-color: #dddddd;border: 1px solid #dddddd; text-align: center; padding: 8px;">Balance</th>
                    </tr>

                    ${statementsTemp}
                    
                    <tr>
                        <td></td>
                        <td></td>
                        <td style="border: 1px solid #dddddd; text-align: center; padding: 8px; font-weight: 700;">End of statement</td>
                        <td style="border: 1px solid #dddddd; text-align: center; padding: 8px; font-weight: 700;">${this.formatter.format(totalDebit)}</td>
                        <td style="border: 1px solid #dddddd; text-align: center; padding: 8px; font-weight: 700;">${this.formatter.format(totalCredit)}</td>
                        <td style="border: 1px solid #dddddd; text-align: center; padding: 8px; font-weight: 700;">${this.formatter.format(totalDebit - totalCredit)}</td>
                    </tr>
                </table>
            </div>
        
            <hr>
        
            <table style="width: 100%;">
                <tr>
                    <td>
                        <div>
                            <p><i class="pi pi-twitter"></i> (Twitter) @HalogenNG</p>
                            <p><i class="pi pi-facebook"></i> (Facebook) <a href="https://www.facebook.com/halogenNGR" target="_blank">https://www.facebook.com/halogenNGR</a></p>
                            <p> (LinkedIn) <a href="https://www.linkedin.com/in/halogen-group-01" target="_blank">https://www.linkedin.com/in/halogen-group-01</a></p>
                            <p> (Instagram) <a href="https://www.instagram.com/halogengrp" target="_blank">https://www.instagram.com/halogengrp</a></p>
                        </div>
                    </td>
                    <td>
                        <div style="border: black 0px solid; float: right;">
                        <img src="https://halobiz.herokuapp.com/assets/layout/images/ISOLOGO.jpg" height="100px" width="100px">
                        </div>
                    </td>
                </tr>
            </table>
            
            </div>
        </div>
        `

    const messageBody = `<!DOCTYPE html>
    <html>
        <head>
            <meta charset="utf-8">
            <meta name="viewport" content="width=device-width, initial-scale=1.0, shrink-to-fit=no">
            <title>Quote</title>
            <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/twitter-bootstrap/4.5.2/css/bootstrap.min.css">
            <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css">
            <style type="text/css">
            </style>
        </head>
        <body>

        ${innerBody}

        <script src="https://cdnjs.cloudflare.com/ajax/libs/jquery/3.5.1/jquery.min.js"></script>
        <script src="https://cdnjs.cloudflare.com/ajax/libs/twitter-bootstrap/4.5.2/js/bootstrap.bundle.min.js"></script>
        </body>
    </html>`

    let recipients = [customerDivision.email]
    const mailMessage = {recipients, message: messageBody, subject: 'Statement of Account'};

    this.quoteService.sendEmailToClient(mailMessage).subscribe( res => {
        this.messageService.add({
            detail: 'Message sent to the client',
            summary: 'Request Complete',
            severity: 'success',
        });
    }, error => {
        this.connectionError();
    })
  }
}
