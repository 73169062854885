import { ResourceRegistrationService } from 'src/app/services/armada/resource-registration.service';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { ArmedEscortProfile, CommanderProfile, PilotProfile, VehicleProfile } from 'src/app/interfaces/armada';
import { AllResourceAttachmentsTransferDTO, ResponseTeam, ResponseTeamType } from 'src/app/interfaces/response';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BreadcrumbService } from 'src/app/breadcrumb.service';
import { ConfirmationService, MessageService } from 'primeng/api';
import { InventoryService } from 'src/app/services/inventory.service';
import { AngularFireStorage } from '@angular/fire/storage';
import { ResponseTeamServiceService } from 'src/app/services/response/response-team-service.service';
import { takeUntil } from 'rxjs/operators';
import { CommonResponse } from 'src/app/interfaces/home';

@Component({
  selector: 'app-response-teams',
  templateUrl: './response-teams.component.html',
  styleUrls: ['./response-teams.component.scss']
})
export class ResponseTeamsComponent implements OnInit {
@ViewChild("formWrapper") public formWrapper: ElementRef;
  private unsubscriber$ = new Subject<void>();

  public targetCols: any;
  private exportTargetColumns: any;
  public submittingData: boolean;
  public loading: boolean;
  public submitButtonLabel: string;
 public selectedMultiVehicles: VehicleProfile[] = [];
 public selectedMultiPilots: PilotProfile[] = [];
 public selectedMultiArmedEscorts: ArmedEscortProfile[] = [];
 public selectedMultiCommanders: CommanderProfile[] = [];
  public responseTeamTypes:ResponseTeamType[];
  public selectedResponseTeamTypes:ResponseTeamType;
  editingResponseTeam:boolean;
  edResponseTeam:ResponseTeam;
   public responseTeams:ResponseTeam[];
  public selectedResponseTeam:ResponseTeam;
   public allResourceAttachments:AllResourceAttachmentsTransferDTO[];
  public selectedResourceAttachments:AllResourceAttachmentsTransferDTO;
   public commanderProfiles: CommanderProfile[];
  selectedCommanderProfile: CommanderProfile;
    public armedEscortProfiles: ArmedEscortProfile[];
    public selectedArmedEscortProfile: ArmedEscortProfile;
     public vehicleProfiles: VehicleProfile[];
     selectedVehicleProfile:VehicleProfile;
      selectedPilotProfile: PilotProfile;
  public pilotProfiles: PilotProfile[];
  public responseTeamForm: FormGroup;
  public resourceAttachmentForm: FormGroup;
isArmedPersonelAllowed:boolean = false;
isCommanderAllowed:boolean = false;
isPilotAllowed:boolean = false;
isVehicleAllowed:boolean = false;
showattachmenttab:boolean = false;
teamId:number;
galleriaResponsiveOptions: any[] = [
        {
            breakpoint: '1024px',
            numVisible: 5
        },
        {
            breakpoint: '960px',
            numVisible: 4
        },
        {
            breakpoint: '768px',
            numVisible: 3
        },
        {
            breakpoint: '560px',
            numVisible: 1
        }
    ];

    carouselResponsiveOptions: any[] = [
        {
            breakpoint: '1024px',
            numVisible: 3,
            numScroll: 3
        },
        {
            breakpoint: '768px',
            numVisible: 2,
            numScroll: 2
        },
        {
            breakpoint: '560px',
            numVisible: 1,
            numScroll: 1
        }
    ];
  displayLoading: any;

  constructor(
    private breadcrumbService: BreadcrumbService,
    public messageService: MessageService,
    public confirmationService: ConfirmationService,
    public fireStorage: AngularFireStorage,
    public inventoryService: InventoryService,
    private formBuilder: FormBuilder,
    private responseTeamServiceService: ResponseTeamServiceService,
    private resourceRegistrationService: ResourceRegistrationService,
  ) { this.breadcrumbService.setItems([
      { label: "Response" },
      {
        label: "Response Team",
        routerLink: ["/home/response/response-teams"],
      },
    ]);}

  ngOnInit(): void {
     //ResponseTeam
    this.targetCols = [
      { field: "teamDescription", header: "teamDescription" },
      { field: "teamName", header: "teamName" },
      { field: "teamTypeId", header: "teamTypeId" },
      { field: "isArmedPersonelAllowed", header: "isArmedPersonelAllowed" },
      { field: "isArmedCommanderAllowed", header: "isArmedCommanderAllowed" },
      { field: "isArmedPilotAllowed", header: "isArmedPilotAllowed" },
      { field: "isArmedVehicleAllowed", header: "isArmedVehicleAllowed" },
    
    ];
    this.exportTargetColumns = this.targetCols.map((col) => ({
      title: col.header,
      dataKey: col.field,
    }));
    this.responseTeamForm = this.formBuilder.group({
      teamDescription: ["", [Validators.required, Validators.minLength(3)]],
      teamName: ["", [Validators.required, Validators.minLength(3)]],
      teamTypeId: [null, [Validators.required]],
      isArmedPersonelAllowed: [false, [Validators.required]],
      isCommanderAllowed: [false, [Validators.required]],
      isPilotAllowed: [false, [Validators.required]],
      isVehicleAllowed: [false, [Validators.required]],
    });

       //ResourceAttach
    this.targetCols = [
      { field: "responseTeamId", header: "responseTeamId" },
      { field: "vehicleResourceId", header: "VehicleResourceId" },
      { field: "armedEscortResourceId", header: "ArmedEscortResourceId" },
      { field: "commanderResourceId", header: "CommanderResourceId" },
      { field: "pilotResourceId", header: "PilotResourceId" },
        
    
    ];
    this.exportTargetColumns = this.targetCols.map((col) => ({
      title: col.header,
      dataKey: col.field,
    }));
    this.resourceAttachmentForm = this.formBuilder.group({
      vehicleResourceId: [null],
      responseTeamId: [null, [Validators.required]],
      armedEscortResourceId: [null],
      commanderResourceId: [null],
      pilotResourceId: [null],
    });

    this.fetchAllArmedEscortProfile();
    this.fetchAllCommanderProfiles();
    this.fetchAllPilotProfile();
    this.fetchAllResponseTeamTypes();
    this.fetchAllResponseTeams();
    this.fetchAllVehicles();

    this.submitButtonLabel = "Create";
  }

    //response team
  fetchAllResponseTeamTypes() {
    this.loading=true;
    this.responseTeamServiceService
      .allResponseTeamTypes()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe((r: CommonResponse) => {
        if (r.responseCode == "00") {
          var res = r.responseData;
          this.responseTeamTypes = res;
          this.loading=false;
          console.log(res);
        }
         else {
            this.messageService.add({
              severity: "error",
              summary: "Failed",
              detail: " Error: " + r.responseMsg,
            });
            this.submittingData = false;
          }
      });
  }
  fetchAllResponseTeams() {
    this.loading=true;
    this.responseTeamServiceService
      .allResponseTeams()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe((r: CommonResponse) => {
        if (r.responseCode == "00") {
          var res = r.responseData;
          this.responseTeams = res;
          this.loading=false;
          console.log(res);
        }
         else {
            this.messageService.add({
              severity: "error",
              summary: "Failed",
              detail: " Error: " + r.responseMsg,
            });
            this.submittingData = false;
          }
      });
  }
   fetchResourceAttachmentById(id) {
    this.loading=true;
    this.responseTeamServiceService
      .getResponseTeamResourceAttachment(id)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe((r: CommonResponse) => {
        if (r.responseCode == "00") {
          var res = r.responseData;
          this.allResourceAttachments = res;
          this.showattachmenttab =true;
          this.loading=false;
          console.log(res);
        }
         else {
            this.messageService.add({
              severity: "error",
              summary: "Failed",
              detail: " Error: " + r.responseMsg,
            });
            this.submittingData = false;
          }
      });
  }
   createResponseTeam() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Creating record...",
    });
    this.submittingData = true;
    const formData = this.responseTeamForm.value;
    const postData ={
      teamName: formData.teamName,
      teamDescription: formData.teamDescription,
      teamTypeId: this.selectedResponseTeamTypes.id,
      isArmedPersonelAllowed: formData.isArmedPersonelAllowed,
      isCommanderAllowed: formData.isCommanderAllowed,
      isPilotAllowed: formData.isPilotAllowed,
      isVehicleAllowed: formData.isVehicleAllowed,
    }
    this.responseTeamServiceService
      .postResponseTeam(postData)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            this.messageService.add({
              summary: "Success",
              severity: "success",
              detail: "New team type created",
            });
            this.fetchAllResponseTeams();
            this.submittingData = false;
            this.responseTeamForm.reset();
          } else {
            this.messageService.add({
              severity: "error",
              summary: "Failed",
              detail: " Error: " + r.responseMsg,
            });
            this.submittingData = false;
          }
        },
        (error) => {
          this.messageService.add({
            severity: "error",
            summary: "Failed",
            detail: "Connection Error " + error.error.message,
          });
          this.submittingData = false;
        }
      );
  }
  deleteResponseTeam(responseTeam: ResponseTeam): void {
    this.confirmationService.confirm({
      message: "Are you sure you want to delete " + responseTeam.teamName,
      accept: () => {
        this._deleteResponseTeam(responseTeam);
      },
    });
  }
   _deleteResponseTeam(responseTeam) {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Deleting team  record",
    });
    this.responseTeamServiceService.deleteResponseTeam(responseTeam.id).subscribe(
      async (result: any) => {
        await this.messageService.add({
          severity: "success",
          summary: "Deleted",
          detail: "Team  record removed",
        });
        await this.fetchAllResponseTeams();
      },
      (error) => {
        this.connectionError();
      }
    );
  }
    makeTeamLead(responseTeam: AllResourceAttachmentsTransferDTO): void {
    this.confirmationService.confirm({
      message: "Are you sure you want to make " + responseTeam.resourceName + " team lead?",
      accept: () => {
        this._makeTeamLead(responseTeam);
      },
    });
  }
   _makeTeamLead(responseTeam) {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Assigning team lead",
    });
    this.responseTeamServiceService.MakeTeamLeadById(responseTeam.resourceAttachmentId, responseTeam.teamId, responseTeam.resourceType).subscribe(
      async (result: any) => {
        await this.messageService.add({
          severity: "success",
          summary: "Assigned",
          detail: "Team lead assigned",
        });
        //this.showattachmenttab = false;
        this.fetchResourceAttachmentById(this.teamId);
      },
      (error) => {
        this.connectionError();
      }
    );
  }
  editResponseTeam(responseTeam: ResponseTeam): void {
    this.editingResponseTeam = true;
    this.formWrapper.nativeElement.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "start",
    });
    this.submitButtonLabel = "Update";
    this.edResponseTeam = responseTeam;
    this.responseTeamForm.setValue({
     teamName: responseTeam.teamName,
      teamDescription: responseTeam.teamDescription,
      teamTypeId: responseTeam.teamTypeId,
      isArmedPersonelAllowed: responseTeam.isArmedPersonelAllowed,
      isCommanderAllowed: responseTeam.isCommanderAllowed,
      isPilotAllowed: responseTeam.isPilotAllowed,
      isVehicleAllowed: responseTeam.isVehicleAllowed,
    });
    this.selectedResponseTeamTypes = this.responseTeamTypes.find(
      (team) => team.id === responseTeam.teamTypeId
    );
  }
  updateResponseTeam(): boolean {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Updating record...",
    });
    this.submittingData = true;
    if (!this.edResponseTeam) {
      return false;
    }
    const formData = this.responseTeamForm.value;
     const data ={
      teamName: formData.teamName,
      teamDescription: formData.teamDescription,
      teamTypeId: this.selectedResponseTeamTypes.id,
      isArmedPersonelAllowed: formData.isArmedPersonelAllowed,
      isCommanderAllowed: formData.isCommanderAllowed,
      isPilotAllowed: formData.isPilotAllowed,
      isVehicleAllowed: formData.isVehicleAllowed,
    }
    this.responseTeamServiceService
      .updateResponseTeam(this.edResponseTeam.id, data)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            this.messageService.add({
              severity: "success",
              detail: "Team Updated",
              summary: "Completed",
            });
            this.submittingData = false;
            this.closeEditing();
            this.fetchAllResponseTeams();
            this.responseTeamForm.reset();
          }
        },
        (error) => {
          this.connectionError();
          this.submittingData = false;
        }
      );
  }
goToAttachment(responseTeam: ResponseTeam){
  this.displayLoading = true;
  this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Getting record...",
    });
  this.selectedResponseTeam = responseTeam;
  this.teamId = responseTeam.id;
  if(responseTeam){
    this.fetchResourceAttachmentById(responseTeam.id);
    this.displayLoading = false;
    //this.showattachmenttab = true;
  }
  else{
    this.messageService.add({
              severity: "error",
              summary: "Failed",
              detail: "Error has occured",
            });
            this.displayLoading = false;
  }
  console.log(this.teamId);
  
}
  //ResponseAttach
  // fetchAllResponseTeams() {
  //   this.loading=true;
  //   this.responseTeamServiceService
  //     .allResponseTeams()
  //     .pipe(takeUntil(this.unsubscriber$))
  //     .subscribe((r: CommonResponse) => {
  //       if (r.responseCode == "00") {
  //         var res = r.responseData;
  //         this.responseTeams = res;
  //         this.loading=false;
  //         console.log(res);
  //       }
  //        else {
  //           this.messageService.add({
  //             severity: "error",
  //             summary: "Failed",
  //             detail: " Error: " + r.responseMsg,
  //           });
  //           this.submittingData = false;
  //         }
  //     });
  // }
   createResponseResourceAttachment() {
    //this.submittingData = true;
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Creating attachment...",
    });
    const formData = this.resourceAttachmentForm.value;
    const postData ={
      responseTeamId: this.teamId,
      //vehicleResourceId: this.selectedVehicleProfile.id,
      vehicleResourceId: this.selectedMultiVehicles.map((x) => x.id),
      armedEscortResourceId: this.selectedMultiArmedEscorts.map((x) => x.id),
      commanderResourceId: this.selectedMultiCommanders.map((x) => x.id),
      pilotResourceId: this.selectedMultiPilots.map((x) => x.id),
    }
    this.responseTeamServiceService
      .postResponseTeamResourceAttachment(postData)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            this.messageService.add({
              summary: "Success",
              severity: "success",
              detail: "New Attachment created",
            });
            //this.fetch();
            //this.showattachmenttab =false;
            this.submittingData = false;
            this.resourceAttachmentForm.patchValue({
              responseTeamId: this.teamId,
              //vehicleResourceId: this.selectedVehicleProfile.id,
              vehicleResourceId: [],
              armedEscortResourceId: [],
              commanderResourceId: [],
              pilotResourceId: [],
            })
            this.fetchResourceAttachmentById(this.teamId);
            this.fetchAllArmedEscortProfile();
            this.fetchAllCommanderProfiles();
            this.fetchAllPilotProfile();
            this.fetchAllVehicles();
            //this.resourceAttachmentForm.reset();
          } else {
            this.messageService.add({
              severity: "error",
              summary: "Failed",
              detail: " Error: " + r.responseMsg,
            });
            this.submittingData = false;
          }
        },
        (error) => {
          this.messageService.add({
            severity: "error",
            summary: "Failed",
            detail: "Connection Error " + error.error.message,
          });
          this.submittingData = false;
        }
      );
  }
  deletePilotAttachment(responseTeam: ResponseTeam): void {
    this.confirmationService.confirm({
      message: "Are you sure you want to delete " + responseTeam.teamName,
      accept: () => {
        this._deletePilotAttachment(responseTeam);
      },
    });
  }
   _deletePilotAttachment(responseTeam) {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Deleting team  record",
    });
    this.responseTeamServiceService.deleteResponseTeam(responseTeam.id).subscribe(
      async (result: any) => {
        await this.messageService.add({
          severity: "success",
          summary: "Deleted",
          detail: "Team  record removed",
        });
        await this.fetchAllResponseTeams();
      },
      (error) => {
        this.connectionError();
      }
    );
  }
 
 

   fetchAllArmedEscortProfile(): void {
    this.resourceRegistrationService
      .allReservedArmedEscortProfile()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            var res = r.responseData;
            this.armedEscortProfiles = res;
            this.armedEscortProfiles.forEach(
              (userProfile) =>
                (userProfile.fullName = `${userProfile.firstName} ${userProfile.lastName}`)
            );
            this.loading = false;
          }
        },
        (error) => this.connectionError()
      );
    this.submittingData = false;
  }
   fetchAllPilotProfile(): void {
    this.resourceRegistrationService
      .allReservedPilotProfiles()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            var res = r.responseData;
            this.pilotProfiles = res;
            this.pilotProfiles.forEach(
              (userProfile) =>
                (userProfile.fullName = `${userProfile.firstname} ${userProfile.lastname}`)
            );
            this.loading = false;
          }
        },
        (error) => {
          this.loading = false;
          this.connectionError();
        }
      );
    this.submittingData = false;
  }
   fetchAllCommanderProfiles() {
    this.resourceRegistrationService
      .allReservedCommanderProfiles()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe((r: CommonResponse) => {
        if (r.responseCode == "00") {
          var res = r.responseData;
          this.commanderProfiles = res;
           this.commanderProfiles.forEach(
            (userProfile) =>
              (userProfile.fullName = `${userProfile.profile.firstName} ${userProfile.profile.lastName}`)
          );
        }
      });
  }
   fetchAllVehicles() {
    this.resourceRegistrationService
      .allReservedVehicles()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            var res = r.responseData;
            this.vehicleProfiles = res;
            console.log(this.vehicleProfiles);
            this.loading = false;
          }
        },
        (error) => {
          this.connectionError();
          this.loading = false;
          this.submittingData = false;
        }
      );
  }

   closeEditing() {
    this.editingResponseTeam = false;
    this.responseTeamForm.reset();
    //this.edTeamType = null;

    this.submittingData = false;
    this.submitButtonLabel = "Create";
  }
   private connectionError() {
    this.messageService.add({
      severity: "error",
      summary: "Failed",
      detail: "Connection Error, Please try again",
    });
  }

}
