import {Component, DoCheck, OnInit} from '@angular/core';
import {DynamicDialogConfig, DynamicDialogRef} from 'primeng/dynamicdialog';
import {Constant, DeliverableFulfillment, TaskFulfillment, User} from '../../../interfaces/home';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {formatDate, NgSwitch} from '@angular/common';
import {UserService} from '../../../services/user.service';
import {MessageService} from 'primeng/api';
import {DeliverableFulfillmentService} from '../../../services/deliverable-fulfillment.service';

@Component({
    selector: 'app-deliverable-form',
    templateUrl: './deliverable-form.component.html',
    styleUrls: ['./deliverable-form.component.scss'],
    providers: [Constant]
})
export class DeliverableFormComponent implements OnInit {
    deliverableFulfilment: DeliverableFulfillment
    deliverableFulfilmentForm: FormGroup;
    today: Date;
    minStartDate: Date;
    maxStartDate: Date;
    maxEndDate: Date;
    minDeliveryDate: Date;
    validName: RegExp = /[0-9]/;
    taskEndDate: Date;
    public loadingUser: boolean;
    public users: User[];
    selectedUser: User = null;
    public activeTask: TaskFulfillment;
    public submittingTask: boolean;
    public selectedEndDate: Date;
    actionType: any;
    pageLegend: string;
    priorities: {id: number, value: string}[];
    selectedPriority:  {id: number, value: string};
    constructor(
        private formBuilder: FormBuilder,
        public modalRef: DynamicDialogRef,
        private modalConfig: DynamicDialogConfig,
        private userService: UserService,
        public messageService: MessageService,
        private deliverableFulfilmentService: DeliverableFulfillmentService,
        private contants : Constant
    ) {
    }

    async ngOnInit() {
        
        this.today = new Date();
        this.actionType = this.modalConfig.data.type;
        let _title = '';
        switch (this.actionType) {
            case 'new':
                _title = 'Create New';
                break;
            case 'assign':
                _title = 'Assign';
                break;
            case 're-assign':
                _title = 'Re Assign';
                break;
        }
        this.priorities = this.contants.priority;
        this.pageLegend = _title + ' Deliverable';
        this.activeTask = this.modalConfig.data.task;
        this.deliverableFulfilmentForm = this.formBuilder.group({
            // deliverable: [this.deliverableFulfilment.deliverable, Validators.required],
            description: ['', Validators.required],
            startDate: [new Date(this.activeTask.startDate), Validators.required],
            duration: [0, Validators.required],
            endDate: [new Date(this.activeTask.startDate)],
            taskEndDate: [new Date(this.activeTask.endDate)],
            priority: ['', Validators.required],
            responsibleId: [null, Validators.required],// assigned
            budget: ['', Validators.required],
            escallationTimeDurationForPicking: [0, Validators.required]
        });
        if (this.actionType !== 'new') {
            this.deliverableFulfilment = this.modalConfig.data.deliverableFulfillment;
            if (!this.deliverableFulfilment.isPicked) {
                // await this.setDeliverableIsPicked(this.deliverableFulfilment.id);
            }
        } else {
            // add new control to the form group
            this.deliverableFulfilmentForm.addControl('caption', new FormControl('', [Validators.required]))
        }
        await this.fetchUser();
        const _today = this.today.getDate();
        this.minStartDate = new Date();
        this.maxStartDate = new Date(this.activeTask.endDate);
        this.minStartDate.setDate(_today);
        this.minDeliveryDate = new Date();
        this.minDeliveryDate.setDate(_today);
        this.taskEndDate = new Date(this.activeTask.endDate)
      if(this.actionType === 're-assign'){
        // set the form information
          // cal duration
        this.deliverableFulfilmentForm.patchValue({
          caption: this.deliverableFulfilment.caption,
          description: this.deliverableFulfilment.description,
          budget: this.deliverableFulfilment.budget,
          escallationTimeDurationForPicking: this.deliverableFulfilment.escallationTimeDurationForPicking,
        })
        this.selectedPriority = this.priorities.find(priority => priority.id == this.deliverableFulfilment.priority)
          // (this.deliverableFulfilment.startDate - this.deliverableFulfilment.endDate)
      } else if(this.actionType === 'assign'){
          await this.calcEndDate();
          this.deliverableFulfilmentForm.patchValue({
              description: this.deliverableFulfilment.description
          })
      }
    }

    async setDeliverableIsPicked(id) {
        await this.deliverableFulfilmentService.setIsPicked(id).subscribe(() => {
            // picked
        })
    }

    fetchUser() {
        this.loadingUser = true;
        this.userService.allUser().subscribe(async (res: any) => {
            this.loadingUser = false;
            this.users = res;
            if (this.actionType === 're-assign') {
                this.selectedUser = this.users.find(user => user.id === this.deliverableFulfilment.responsibleId);
            }
        });
    }

    calcEndDate() {
        const _endDate = new Date(this.activeTask.endDate);
        // todo: set maximum date
        const _startDateControl = this.deliverableFulfilmentForm.get('startDate');
        const _endDateControl = this.deliverableFulfilmentForm.get('endDate');
        const _durationControl = this.deliverableFulfilmentForm.get('duration');
        let durationValue = parseInt(_durationControl.value, 10);
        if (!durationValue) {
            durationValue = 0;
        }
        const _startDateValueC = _startDateControl.value;
        if (_startDateValueC) {
            const _startDateValue = new Date(_startDateValueC);
            const day = _startDateValue.getDate();
            const _hour = _startDateValue.getHours();
            const _minute = _startDateValue.getMinutes();
            const endDateValue = new Date(_startDateValue);
            endDateValue.setHours(_hour + durationValue, _minute)
            const _endDateTime = endDateValue.getTime();
            if (_endDateTime > _endDate.getTime()) {
                _durationControl.setErrors(['the duration provided has exceed Task End Date']);
                _endDateControl.markAsDirty();
                this.selectedEndDate = _endDate;
                return;
            }
            this.selectedEndDate = endDateValue;
            _endDateControl.setValue(this.selectedEndDate);
        }
    }

    assignTask() {
        this.submittingTask = true;
        if (this.deliverableFulfilmentForm.valid) {
            const deliverableFulfilmentValue = this.deliverableFulfilmentForm.value;
            const postData = {
                description: this.deliverableFulfilment.description,
                caption: this.deliverableFulfilment.caption,
                taskFullfillmentId: this.deliverableFulfilment.taskFullfillmentId,
                isPicked: true,
                priority: this.deliverableFulfilmentForm.get("priority").value.id,
                dateAndTimePicked: this.deliverableFulfilment.dateAndTimePicked,
                dateAndTimeOfProvidedEvidence: this.deliverableFulfilment.dateAndTimeOfProvidedEvidence,
                deliverableCompletionDate: this.deliverableFulfilment.deliverableCompletionDate,
                deliverableCompletionTime: this.deliverableFulfilment.deliverableCompletionTime,
                serviceCode: this.deliverableFulfilment.serviceCode,
                responsibleId: this.selectedUser.id,
                startDate: formatDate(deliverableFulfilmentValue.startDate, 'yyyy-MM-ddTHH:mm:ss', 'en-us'),
                endDate: formatDate(deliverableFulfilmentValue.endDate, 'yyyy-MM-ddTHH:mm:ss', 'en-us'),
                deliveryDate: formatDate(deliverableFulfilmentValue.endDate, 'yyyy-MM-ddTHH:mm:ss', 'en-us'),
                budget: deliverableFulfilmentValue.budget,
                escallationTimeDurationForPicking: deliverableFulfilmentValue.escallationTimeDurationForPicking,
            }
            if(this.actionType === 're-assign'){
                this.deliverableFulfilmentService.reAssignTask(this.deliverableFulfilment.id, postData).subscribe( async (res: any) => {
                    this.submittingTask = false;
                    await this.closeDialog('complete');
                });
            } else {
                this.deliverableFulfilmentService.updateDeliverableFulfillment(this.deliverableFulfilment.id, postData).subscribe(async () => {
                    this.submittingTask = false;
                    await this.closeDialog('complete');
                });
            }
        }
    }

    createDeliverableFulfilment() {
        this.submittingTask = true;
        const deliverableFulfilmentValue = this.deliverableFulfilmentForm.value;
        const postData = {
            description: deliverableFulfilmentValue.description,
            caption: deliverableFulfilmentValue.caption,
            taskFullfillmentId: this.activeTask.id,
            responsibleId: this.selectedUser.id,
            startDate: formatDate(deliverableFulfilmentValue.startDate, 'yyyy-MM-ddTHH:mm:ss', 'en-us'),
            endDate: formatDate(deliverableFulfilmentValue.startDate, 'yyyy-MM-ddTHH:mm:ss', 'en-us'),
            deliveryDate: formatDate(deliverableFulfilmentValue.deliveryDate, 'yyyy-MM-ddTHH:mm:ss', 'en-us'),
            isRequestedForValidation: false,
            deliverableStatus: false,
            budget: parseFloat(deliverableFulfilmentValue.budget),
            serviceCode: this.activeTask.serviceCode,
            escallationTimeDurationForPicking: parseInt(deliverableFulfilmentValue.escallationTimeDurationForPicking, 10),
        }
        this.deliverableFulfilmentService.postDeliverableFulfillment(postData).subscribe( async (res: any) => {
          this.submittingTask = false;
          await this.closeDialog('complete');
        }
       /* , error => {
          this.messageService.add({
            severity: 'error',
            summary: 'Process Terminated',
            detail: error,
          });
          this.submittingTask = false;
        }*/
        )
    }

    closeDialog(res?: any) {
        return this.modalRef.close(res);
    }
}
