import {
  AddNewCheckListVM,
  ChecklistCategory,
  CreateCheckListCategoryVM,
  GMAChecklist,
  UpdateChecklistCategoryVM,
  UpdateCheckListVM,
} from "./../../../interfaces/employment";
import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { ConfirmationService, MessageService } from "primeng/api";
import { BreadcrumbService } from "src/app/breadcrumb.service";
import { GmaEmploymentService } from "src/app/services/gma-employment.service";

@Component({
  selector: "app-location-checklist-setup",
  templateUrl: "./location-checklist-setup.component.html",
  styleUrls: ["./location-checklist-setup.component.scss"],
})
export class LocationChecklistSetupComponent implements OnInit {
  @ViewChild("formWrapper") public formWrapper: ElementRef;
  categoryForm: FormGroup;
  checklistForm: FormGroup;
  formDecline: FormGroup;
  editingCheckListCateg: boolean = false;
  fetchingChecklistCateg: boolean = true;
  chkCategCols: any[];
  allCheckListCategories: ChecklistCategory[] = [];
  selectedCheckListCategory: ChecklistCategory;
  allCategCheckList: GMAChecklist[] = [];
  selectedCategChkList: GMAChecklist;
  chkListCols: any[];
  checkListCategoryInView: ChecklistCategory;
  checkListCategInView: ChecklistCategory;
  checkListInView: GMAChecklist;
  openAddChecklistDialogue: boolean = false;
  editingCheckList: boolean = false;
  openDeleteDialogue: boolean = false;
  deleteText: string;
  categoryToDelete: ChecklistCategory;
  deleteCode: number;

  constructor(
    private fb: FormBuilder,
    public employmentService: GmaEmploymentService,
    private breadcrumbService: BreadcrumbService,
    public confirmationService: ConfirmationService,
    public messageService: MessageService
  ) {
    this.categoryForm = fb.group({
      Caption: ["", Validators.required],
      Description: ["", Validators.required],
    });

    this.checklistForm = fb.group({
      Caption: ["", Validators.required],
      Description: ["", Validators.required],
    });

    this.formDecline = fb.group({
      Comment: [""],
    });
  }

  ngOnInit(): void {
    this.breadcrumbService.setItems([
      {
        label: "Client Management",
        routerLink: ["/home/client-management/"],
      },
      {
        label: "Location Check-list",
        routerLink: ["/home/client-management/location-checklist-setup"],
      },
    ]);

    this.chkCategCols = [
      { field: "caption", header: "Caption" },
      { field: "description", header: "Description" },
    ];

    this.chkListCols = [
      { field: "caption", header: "Caption" },
      { field: "description", header: "Description" },
    ];

    this.FetchAllCheckListCategory();
  }

  FetchAllCheckListCategory() {
    this.employmentService.GetAllChecklistCategories().subscribe(
      async (data) => {
        this.allCheckListCategories = data;
          this.fetchingChecklistCateg = false;
       
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));    
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to get all check list categories at the moment.. Reason: [" +
            error.error.message +
            "]",
        });
        this.fetchingChecklistCateg = false;    
      }
    );
  }

  CreateCheckListCategory() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Creating Check List Category...",
    });

    const postData: CreateCheckListCategoryVM = {
      caption: this.categoryForm.get("Caption").value,
      description: this.categoryForm.get("Description").value,
    };

    this.employmentService.CreateCheckListCategory(postData).subscribe(
      async () => {
        await this.messageService.add({
          severity: "success",
          summary: "Completed",
          detail: "Check-list Category Created Successfully...",
        });

        this.fetchingChecklistCateg = true;
        this.categoryForm.reset();
        this.FetchAllCheckListCategory();
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to create check-list category at the moment.. Reason: [" +
            error.error.message +
            "]",
        });
      }
    );
  }

  CloseCategEditing() {
    this.checkListCategoryInView = null;
    this.editingCheckListCateg = false;
    this.categoryForm.reset();
  }

  UpdateCheckListCateg() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Updating Check-list Category...",
    });

    const id = this.checkListCategoryInView.id;
    const postData: UpdateChecklistCategoryVM = {
      caption: this.categoryForm.get("Caption").value,
      description: this.categoryForm.get("Description").value,
    };

    this.employmentService.UpdateChecklistCategory(id, postData).subscribe(
      async () => {
        this.messageService.add({
          severity: "success",
          summary: "Notice",
          detail: "Update Successful!",
        });

        this.fetchingChecklistCateg = true;
        await this.FetchAllCheckListCategory();
        await this.CloseCategEditing();
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to update check-list category at the moment.. Reason: [" +
            error.error.message +
            "]",
        });
      }
    );
  }

  EditChkListCateg(item: ChecklistCategory) {
    this.editingCheckListCateg = true;
    this.categoryForm.addControl(
      "ID",
      new FormControl({ value: "", disabled: true }, Validators.required)
    );
    this.checkListCategoryInView = item;
    this.categoryForm.patchValue({
      ID: item.id,
      Caption: item.caption,
      Description: item.description,
    });

    this.formWrapper.nativeElement.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "start",
    });
  }

  DeleteChkListCateg(item: ChecklistCategory) {
    if (item.checklists.filter((x) => x.isDeleted == false).length > 0) {
      this.categoryToDelete = item;
      this.deleteText =
        "The category you are about to delete has " +
        item.checklists.filter((x) => x.isDeleted == false).length +
        " active checklist(s) under it, removing this category will also remove all the checklist(s) under it. Do you still wish to proceed?";
      this.deleteCode = 1;
      this.openDeleteDialogue = true;
      return;
    }

    this.confirmationService.confirm({
      message: "Are you sure you want to remove check-list category?",
      accept: () => {
        this.messageService.add({
          severity: "info",
          summary: "Notice",
          detail: "Removing check-list category...",
        });

        this.employmentService.DeleteChecklistCategory(item.id).subscribe(
          async () => {
            await this.messageService.add({
              severity: "success",
              summary: "Removed",
              detail: "Removed successfully",
            });

            const index = this.allCheckListCategories.indexOf(item);
            if (index > -1) {
              this.allCheckListCategories.splice(index, 1);
            }
            this.FetchAllCheckListCategory();
          },
          (error) => {
            console.log("Error: " + JSON.stringify(error));
            this.messageService.add({
              severity: "error",
              summary: "Notice",
              detail:
                "Unable to remove check-list category at the moment.. Reason: [" +
                error.error.message +
                "]",
            });
          }
        );
      },
    });
  }

  ViewCheckList(item: ChecklistCategory) {
    this.checkListCategInView = item;
    this.allCategCheckList = [];
    item.checklists.forEach((x) =>
      x.isDeleted == false ? this.allCategCheckList.push(x) : x
    );
  }

  HideChkDialog() {
    this.openAddChecklistDialogue = false;
    this.CloseChecklistEditing();
  }

  EditCheckList(item: GMAChecklist) {
    this.editingCheckList = true;
    this.checkListInView = item;
    this.checklistForm.patchValue({
      Caption: item.caption,
      Description: item.description,
    });
    this.openAddChecklistDialogue = true;
  }

  DeleteCheckList(item: GMAChecklist) {
    this.confirmationService.confirm({
      message: "Are you sure you want to remove check-list?",
      accept: () => {
        this.messageService.add({
          severity: "info",
          summary: "Notice",
          detail: "Removing check-list...",
        });

        this.employmentService.DeleteChecklist(item.id).subscribe(
          async () => {
            await this.messageService.add({
              severity: "success",
              summary: "Removed",
              detail: "Removed successfully",
            });

            const index = this.allCategCheckList.indexOf(item);
            if (index > -1) {
              this.allCategCheckList.splice(index, 1);
            }
            this.FetchAllCheckListCategory();
          },
          (error) => {
            console.log("Error: " + JSON.stringify(error));
            this.messageService.add({
              severity: "error",
              summary: "Notice",
              detail:
                "Unable to remove check-list at the moment.. Reason: [" +
                error.error.message +
                "]",
            });
          }
        );
      },
    });
  }

  AddNewCheckList() {
    this.openAddChecklistDialogue = true;
    this.checklistForm.reset();
  }

  SaveNewCheckList() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Adding Check List...",
    });

    const postData: AddNewCheckListVM = {
      caption: this.checklistForm.get("Caption").value,
      description: this.checklistForm.get("Description").value,
      checklistCategoryId: this.checkListCategInView.id,
    };

    this.employmentService.AddNewCheckList(postData).subscribe(
      async () => {
        await this.messageService.add({
          severity: "success",
          summary: "Completed",
          detail: "Check-list Added Successfully...",
        });

        this.checkListCategInView.checklists.push({
          caption: postData.caption,
          description: postData.description,
          checklistCategoryId: postData.checklistCategoryId,
        });
        this.allCategCheckList = this.checkListCategInView.checklists.filter(
          (x) => x.isDeleted == false
        );
        this.checklistForm.reset();
        this.HideChkDialog();
        this.FetchAllCheckListCategory();
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to add check-list at the moment.. Reason: [" +
            error.error.message +
            "]",
        });
      }
    );
  }

  GetCheckListCount(item: ChecklistCategory): number {
    if (item.checklists.length == 0) return 0;

    let count = 0;
    item.checklists.forEach((x) => (x.isDeleted == false ? count++ : count));
    return count;
  }

  CloseChecklistEditing() {
    this.checklistForm.reset();
    this.editingCheckList = false;
    this.checkListInView = null;
  }

  UpdateCheckList() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Updating Check-list...",
    });

    const id = this.checkListInView.id;
    const postData: UpdateCheckListVM = {
      caption: this.checklistForm.get("Caption").value,
      description: this.checklistForm.get("Description").value,
    };

    this.employmentService.UpdateCheckList(id, postData).subscribe(
      async () => {
        this.messageService.add({
          severity: "success",
          summary: "Notice",
          detail: "Update Successful!",
        });

        this.checkListInView.caption = postData.caption;
        this.checkListInView.description = postData.description;
        await this.FetchAllCheckListCategory();
        await this.HideChkDialog();
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to update check-list at the moment.. Reason: [" +
            error.error.message +
            "]",
        });
      }
    );
  }

  async DeleteAction() {
    if (this.deleteCode == 1) {
      this.messageService.add({
        severity: "info",
        summary: "Notice",
        detail: "Removing check-list category...",
      });

      this.employmentService
        .DeleteChecklistCategory(this.categoryToDelete.id)
        .subscribe(
          async () => {
            await this.messageService.add({
              severity: "success",
              summary: "Removed",
              detail: "Removed successfully",
            });

            const index = this.allCheckListCategories.indexOf(
              this.categoryToDelete
            );
            if (index > -1) {
              this.allCheckListCategories.splice(index, 1);
            }
            this.FetchAllCheckListCategory();
          },
          (error) => {
            console.log("Error: " + JSON.stringify(error));
            this.messageService.add({
              severity: "error",
              summary: "Notice",
              detail:
                "Unable to remove check-list category at the moment.. Reason: [" +
                error.error.message +
                "]",
            });
          }
        );
    }
  }

  HideDeleteDialog() {
    this.categoryToDelete = null;
    this.openDeleteDialogue = false;
    this.deleteText = "";
  }
}
