import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-types-for-service-assignment',
  templateUrl: './types-for-service-assignment.component.html',
  styleUrls: ['./types-for-service-assignment.component.scss']
})
export class TypesForServiceAssignmentComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
