import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {RequiredServiceQualElement, ServiceCategory} from '../../../interfaces/home';
import {ConfirmationService, MessageService} from 'primeng/api';
import {RequiredServiceQualElementService} from '../../../services/required-service-qual-element.service';
import {ServiceCategoryService} from '../../../services/service-category.service';
import { ServiceGroupService } from 'src/app/services/service-group.service';
import { BreadcrumbService } from 'src/app/breadcrumb.service';

@Component({
  selector: 'app-required-service-qual-element',
  templateUrl: './required-service-qual-element.component.html',
  styleUrls: ['./required-service-qual-element.component.scss'],
  providers: [ConfirmationService, MessageService]
})
export class RequiredServiceQualElementComponent implements OnInit {
  @ViewChild('formWrapper') public formWrapper:ElementRef;
  fetchingRequiredServiceQualElement = true;
  requiredServiceQualElementForm: FormGroup;
  requiredServiceQualElements: RequiredServiceQualElement[];
  selectedRequiredServiceQualElement: RequiredServiceQualElement;
  editingRequiredServiceQualElement= false;
  public requiredServiceQualElementCols: any[];
  public exportRequiredServiceQualElementColumns: any[];
  private edRequiredServiceQualElement: RequiredServiceQualElement;
  serviceCategories: ServiceCategory[] = [];
  selectedServiceCategory: ServiceCategory | number | null;
  elementTypes: {type: string, value: string}[];
  selectedType: {type: string, value: string};
  serviceGroups: any[];
  constructor(
      private formBuilder: FormBuilder,
      private requiredServiceQualElementService: RequiredServiceQualElementService,
      public messageService: MessageService,
      public confirmationService: ConfirmationService,
      private serviceCategoryService: ServiceCategoryService,
      private serviceGroupService: ServiceGroupService,
      private breadcrumbService: BreadcrumbService
      ) { }
  
      ngOnInit(): void {
          this.breadcrumbService.setItems([
              { label: 'Setup', routerLink: ['home/required-service-qualification-element'] },
              { label: 'Services', routerLink: ['home/required-service-qualification-element'] },
              { label: 'Required Service Qualification Element', routerLink: ['home/required-service-qualification-element'] }
            ]);
    this.fetchServiceGroup();
    this.fetchRequiredServiceQualElement();
    this. fetchServiceCateory();

    this.elementTypes = [
      {type: "Text", value: "text"},
      {type: "DateTime", value: "dateTime"},
      {type: "Date", value: "date"},
      {type: "Number", value: "number"},
      {type: "Mobile", value: "tel"}
    ]
    this.requiredServiceQualElementForm = this.formBuilder.group({
      description: ['', Validators.required],
      caption: ['', [Validators.required]],
      type: ['', [Validators.required]],
      serviceCategory: ['', [Validators.required]]
    });
    this.requiredServiceQualElementCols = [
      { field: 'caption', header: 'Caption' },
      { field: 'description', header: 'Description' },
      { field: 'type', header: 'Field Type' },
      { field: 'serviceCategory', header: 'Service Category' },
    ];
    this.exportRequiredServiceQualElementColumns = this.requiredServiceQualElementCols.map(
        col => ({title: col.header, dataKey: col.field}));
  }
  fetchRequiredServiceQualElement(){
    this.requiredServiceQualElementService.allData().subscribe( async (requiredServiceQualElement: any) =>{
      this.requiredServiceQualElements = requiredServiceQualElement;
      this.fetchingRequiredServiceQualElement = false;
    }, error => {
      this.fetchingRequiredServiceQualElement = false;
      this.connectionError();
    })
  }

  fetchServiceCateory(): any {
    this.fetchingRequiredServiceQualElement = true;
    this.serviceCategoryService.allServiceCategory().subscribe(async (serviceCategories: any) => {
      this.serviceCategories = serviceCategories;
      this.fetchingRequiredServiceQualElement = false;
    }, error => {
      this.fetchingRequiredServiceQualElement = false;
      this.connectionError();
    })
  }

  fetchServiceGroup(): any {
    this.fetchingRequiredServiceQualElement = true;
    this.serviceGroupService.allServiceGroup().subscribe(async (serviceGroups: any) => {
      this.serviceGroups = serviceGroups.map(group => {
        return {
          label : group.name,
          items : group.serviceCategories.map(category => { return{
              label : category.name,
              value : category.id
          }})
      }
      })
      
      this.fetchingRequiredServiceQualElement = false;
    }, error => {
      this.fetchingRequiredServiceQualElement = false;
      this.connectionError();
    })
  }

  private connectionError() {
    this.messageService.add({severity:'error', summary: 'Failed',
      detail:'Connection Error, Please try again'});
  }
  createRequiredServiceQualElement(){
    this.messageService.add({severity:'info', summary: 'Notice',
      detail:'Creating new Required Service Qualification Element Records'});
      console.log();
      
    const postData = {
      description: this.requiredServiceQualElementForm.get('description').value,
      caption: this.requiredServiceQualElementForm.get('caption').value,
      type: this.requiredServiceQualElementForm.get('type').value.value,
      serviceCategoryId: this.requiredServiceQualElementForm.get('serviceCategory').value
    };
    this.requiredServiceQualElementService.postRequiredServiceQualElement(postData).subscribe( async () =>{
      await this.messageService.add({severity:'success', summary: 'Completed',
        detail:'New Required Service Qualification Element Created'});
      await this.fetchRequiredServiceQualElement();
      await this.requiredServiceQualElementForm.reset();
    }, error => {
      this.messageService.add({severity:'error', summary: 'Notice',
        detail:'Unable to create new Required Service Qualification Element at the moment'});
    });
  }

  deleteRequiredServiceQualElement(requiredServiceQualElement) {
    this.confirmationService.confirm({
      message: 'Are you sure you want to delete ' + requiredServiceQualElement.name,
      accept: () => {
        this._deleteRequiredServiceQualElement(requiredServiceQualElement);
      }
    });
  }
  _deleteRequiredServiceQualElement(requiredServiceQualElement) {
    this.messageService.add({severity:'info', summary: 'Notice',
      detail:'Deleting Required Service Qualification Element record'});
    this.requiredServiceQualElementService.deleteRequiredServiceQualElement(requiredServiceQualElement.id).subscribe(
        async (result:any) => {
      await this.messageService.add({severity:'success', summary: 'Deleted',
        detail:'Required Service Qualification Element record removed'});
      await this.fetchRequiredServiceQualElement();
    }, error => {
      this.connectionError()
    });
  }
  editRequiredServiceQualElement(requiredServiceQualElements) {
    
    this.editingRequiredServiceQualElement = true;
    this.requiredServiceQualElementForm.addControl(
        'requiredServiceQualElementId',  new FormControl({value: '', disabled: true}, Validators.required));
    this.edRequiredServiceQualElement = requiredServiceQualElements;
    this.requiredServiceQualElementForm.patchValue({
      caption: requiredServiceQualElements.caption,
      description: requiredServiceQualElements.description,
      requiredServiceQualElementId: requiredServiceQualElements.id,
    });
      requiredServiceQualElementId: requiredServiceQualElements.id,
    this.selectedServiceCategory =  requiredServiceQualElements.serviceCategoryId; 
    this.selectedType = this.elementTypes.find(element => element.value == requiredServiceQualElements.type)
    this.formWrapper.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'start' });
  }

  updateRequiredServiceQualElement() {
    // update the selected requiredServiceQualElement
    this.messageService.add({severity:'info', summary: 'Notice',
      detail:'Updating Required Service Qualification Element details'});
    const id = this.edRequiredServiceQualElement.id;
    const postData = {
      caption: this.requiredServiceQualElementForm.get('caption').value,
      description: this.requiredServiceQualElementForm.get('description').value,
      type: this.requiredServiceQualElementForm.get('type').value.value,
      serviceCategoryId: this.requiredServiceQualElementForm.get('serviceCategory').value
    };
    this.requiredServiceQualElementService.updateRequiredServiceQualElement(id, postData).subscribe(
        async (result: RequiredServiceQualElement) => {
      this.messageService.add({severity:'success', summary: 'Notice',
        detail:'Required Service Qualification Element details Updated'});
      await this.fetchRequiredServiceQualElement();
      await this.closeEditing();
    })
  }

  closeEditing() {
    this.editingRequiredServiceQualElement = false;
    this.requiredServiceQualElementForm.reset();
    this.edRequiredServiceQualElement = null;
  }

  getServiceCategoryName(serviceCateoryId: number | null): string{    
    let categories = this.serviceGroups.reduce((accumulator, value) => accumulator.concat(this.concatGroupAndCategory(value.label, value.items))
    , [])
    let category = categories.find(category => category.value == serviceCateoryId)
    return category? category.name : "";
  }

  concatGroupAndCategory(group, categories){    
    return categories.map(category => {
      category.name = group + "/" + category.label;
      return category;
    })
  }
}
