import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { ConfirmationService, MessageService } from "primeng/api";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { BreadcrumbService } from "src/app/breadcrumb.service";
import {
  ArmedEscortDTSMasters,
  ArmedEscortProfile,
  ArmedEscortResourceTie,
  ArmedEscortServiceAssignmentDetail,
  CommanderDTSMasters,
  CommanderProfile,
  CommanderResourceTie,
  CommanderServiceAssignmentDetail,
  MasterServiceAssignment,
  PilotDTSMasters,
  PilotProfile,
  PilotResourceTie,
  PilotServiceAssignmentDetail,
  VehicleDTSMasters,
  VehicleProfile,
  VehicleResourceTie,
  VehicleServiceAssignmentDetail,
} from "src/app/interfaces/armada";
import { CommonResponse } from "src/app/interfaces/home";
import { MasterServiceAssignmentService } from "src/app/services/armada/master-service-assignment.service";
import { ResourceRegistrationService } from "src/app/services/armada/resource-registration.service";
import { ResourceSchedulingService } from "src/app/services/armada/resource-scheduling.service";
import { ServiceAssignmentDetailService } from "src/app/services/armada/service-assignment-detail.service";
import { TypesForServiceAssignmentService } from "src/app/services/armada/types-for-service-assignment.service";

@Component({
  selector: "app-resource-lookup",
  templateUrl: "./resource-lookup.component.html",
  styleUrls: ["./resource-lookup.component.scss"],
})
export class ResourceLookupComponent implements OnInit {
  @ViewChild("formWrapper") public formWrapper: ElementRef;
  private unsubscriber$ = new Subject<void>();

  public targetCols: any;
  private exportTargetColumns: any;
  public loading: boolean;
  public loading_: boolean;
  public submitButtonLabel: string;
  isFreeResource: boolean;
  width: string = "55%";
  //breadcrumbService: any;

  public masterServiceAssignments: MasterServiceAssignment;
  public masterServiceAssignment: MasterServiceAssignment;
  selectedmasterServiceAssignment: any;
  public armedEscortServiceAssignmentDetails: ArmedEscortServiceAssignmentDetail[];
  selectedArmedEscortServiceAssignmentDetail: any;
  public commanderServiceAssignmentDetails: CommanderServiceAssignmentDetail[];
  selectedCommanderServiceAssignmentDetail: any;
  public pilotServiceAssignmentDetails: PilotServiceAssignmentDetail[];
  selectedPilotServiceAssignmentDetail: any;
  public vehicleServiceAssignmentDetails: VehicleServiceAssignmentDetail[];
  selectedVehicleServiceAssignmentDetail: any;
  public armedEscortProfiles: ArmedEscortProfile[];
  selectedArmedEscortProfile: any;
  public commanderProfiles: CommanderProfile[];
  selectedCommanderProfile: CommanderProfile;
  selectedPilotProfile: PilotProfile;
  public pilotProfiles: PilotProfile[];
  public vehicleProfiles: VehicleProfile[];
  selectedVehicleProfile: any;

  //Tie
  public armedEscortTies: ArmedEscortResourceTie[];
  selectedArmedEscortTie: ArmedEscortResourceTie = null;
  public commanderTies: CommanderResourceTie[];
  selectedCommanderTie: CommanderResourceTie = null;
  public pilotTies: PilotResourceTie[];
  selectedPilotTie: PilotResourceTie = null;
  public vehicleTies: VehicleResourceTie[];
  selectedVehicleTie: VehicleResourceTie = null;

  // Schedule
  public armedEscortDTSMasters: ArmedEscortDTSMasters[];
  public armedEscortDTSMasters_: ArmedEscortDTSMasters[];
  selectedArmedEscortDTSMasters: any;
  public commanderDTSMasters: CommanderDTSMasters[];
  public commanderDTSMasters_: CommanderDTSMasters[];
  selectedCommanderDTSMasters: any;
  public pilotDTSMasters: PilotDTSMasters[];
  public pilotDTSMasters_: PilotDTSMasters[];
  selectedPilotDTSMasters: any;
  public vehicleDTSMasters: VehicleDTSMasters[];
  public vehicleDTSMasters_: VehicleDTSMasters[];
  selectedVehicleDTSMasters: any;

  //assignment dialog
  openServiceAssignmentDialogue: boolean = false;
  //ArmedEscort
  openArmedEscortScheduleDialogue: boolean = false;
  openArmedEscortRouteTieDialogue: boolean = false;
  openArmedEscortSettingTypeDialogue: boolean = false;
  openArmedEscortServiceAssignmentDialogue: boolean = false;
  //Commander
  openCommanderScheduleDialogue: boolean = false;
  openCommanderRouteTieDialogue: boolean = false;
  openCommanderSettingTypeDialogue: boolean = false;
  openCommanderServiceAssignmentDialogue: boolean = false;
  //Pilot
  openPilotScheduleDialogue: boolean = false;
  openPilotRouteTieDialogue: boolean = false;
  openPilotSettingTypeDialogue: boolean = false;
  openPilotServiceAssignmentDialogue: boolean = false;
  //Vehicle
  openVehicleScheduleDialogue: boolean = false;
  openVehicleRouteTieDialogue: boolean = false;
  openVehicleSettingTypeDialogue: boolean = false;
  openVehicleServiceAssignmentDialogue: boolean = false;

  constructor(
    public messageService: MessageService,
    private breadcrumbService: BreadcrumbService,
    public confirmationService: ConfirmationService,
    private formBuilder: FormBuilder,
    private typesForServiceAssignmentService: TypesForServiceAssignmentService,
    private serviceAssignmentService: MasterServiceAssignmentService,
    private serviceAssignmentDetailService: ServiceAssignmentDetailService,
    private resourceregistrationService: ResourceRegistrationService,
    private resourceSchedulingService: ResourceSchedulingService
  ) {
    this.breadcrumbService.setItems([
      { label: "Setup" },
      {
        label: "lookup",
        routerLink: ["home/armada/setup/resource-lookup"],
      },
    ]);
  }

  ngOnInit(): void {
    //Available
    this.fetchAllAvailableArmedEscorts();
    this.fetchAllAvailableCommanders();
    this.fetchAllAvailablePilots();
    this.fetchAllAvailableVehicles();

    //this.availableArmedEscortRouteTieDialogue();

    //Held
    this.fetchAllHeldArmedEscorts();
    this.fetchAllHeldCommanders();
    this.fetchAllHeldPilots();
    this.fetchAllHeldVehicles();

    this.isFreeResource = true;
  }

  //pilot
  fetchAllAvailablePilots(): void {
    this.serviceAssignmentDetailService
      .allAvailablePilots()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            var res = r.responseData;
            this.pilotProfiles = res;
            this.pilotProfiles.forEach(
              (userProfile) =>
                (userProfile.fullName = `${userProfile.firstname} ${userProfile.lastname}`)
            );
            this.loading = false;
          }
        },
        (error) => {
          this.loading = false;
          this.connectionError();
        }
      );
    //this.submittingData = false;
  }
  fetchAllHeldPilots(): void {
    this.serviceAssignmentDetailService
      .allHeldPilots()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            var res = r.responseData;
            this.pilotServiceAssignmentDetails = res;
            // this.pilotProfiles.forEach(
            //   (userProfile) =>
            //     (userProfile.fullName = `${userProfile.firstname} ${userProfile.lastname}`)
            // );
            this.loading = false;
          }
        },
        (error) => {
          this.loading = false;
          this.connectionError();
        }
      );
    //this.submittingData = false;
  }
  fetchAllPilotTiess(): void {
    this.resourceregistrationService
      .allPilotProfileTies()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            var res = r.responseData;
            this.pilotTies = res;
            // this.pilotProfiles.forEach(
            //   (userProfile) =>
            //     (userProfile.fullName = `${userProfile.firstname} ${userProfile.lastname}`)
            // );
            this.loading = false;
          }
        },
        (error) => {
          this.loading = false;
          this.connectionError();
        }
      );
    //this.submittingData = false;
  }

  //Armed Escort
  fetchAllAvailableArmedEscorts(): void {
    this.serviceAssignmentDetailService
      .allAvailableArmedEscorts()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            var res = r.responseData;
            this.armedEscortProfiles = res;
            this.armedEscortProfiles.forEach(
              (userProfile) =>
                (userProfile.fullName = `${userProfile.firstName} ${userProfile.lastName}`)
            );
            //console.log(this.armedEscortProfiles);
            this.loading = false;
          }
        },
        (error) => this.connectionError()
      );
    //this.submittingData = false;
  }
  fetchAllHeldArmedEscorts(): void {
    this.serviceAssignmentDetailService
      .allHeldArmedEscorts()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            var res = r.responseData;
            this.armedEscortServiceAssignmentDetails = res;
            // this.armedEscortProfiles.forEach(
            //   (userProfile) =>
            //     (userProfile.fullName = `${userProfile.firstName} ${userProfile.lastName}`)
            // );
            this.loading = false;
          }
        },
        (error) => this.connectionError()
      );
    //this.submittingData = false;
  }
  fetchAllArmedEscortTiess(): void {
    this.resourceregistrationService
      .allArmedEscortProfileTie()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            var res = r.responseData;
            this.armedEscortTies = res;
            // this.armedEscortProfiles.forEach(
            //   (userProfile) =>
            //     (userProfile.fullName = `${userProfile.firstName} ${userProfile.lastName}`)
            // );
            this.loading = false;
          }
        },
        (error) => this.connectionError()
      );
    //this.submittingData = false;
  }

  //Commander
  fetchAllAvailableCommanders() {
    this.serviceAssignmentDetailService
      .allAvailableCommanders()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe((r: CommonResponse) => {
        if (r.responseCode == "00") {
          var res = r.responseData;
          this.commanderProfiles = res;
          this.commanderProfiles.forEach(
            (userProfile) =>
              (userProfile.fullName = `${userProfile.profile.firstName} ${userProfile.profile.lastName}`)
          );
          this.loading = false;
        }
      });
  }

  fetchAllHeldCommanders() {
    this.serviceAssignmentDetailService
      .allHeldCommanders()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe((r: CommonResponse) => {
        if (r.responseCode == "00") {
          var res = r.responseData;
          this.commanderServiceAssignmentDetails = res;
          console.log(this.commanderServiceAssignmentDetails)
          console.log(JSON.stringify(this.commanderServiceAssignmentDetails))
          // this.commanderProfiles.forEach(
          //   (userProfile) =>
          //     (userProfile.fullName = `${userProfile.profile.firstName} ${userProfile.profile.lastName}`)
          // );
          this.loading = false;
        }
      });
  }

  //vehicles
  fetchAllAvailableVehicles() {
    this.serviceAssignmentDetailService
      .allAvailableVehicles()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            var res = r.responseData;
            this.vehicleProfiles = res;
            //console.log(res);
            // this.userProfiles.forEach(userProfile => {
            //   userProfile.fullName = `${userProfile.firstName} ${userProfile.lastName}`
            // });
            this.loading = false;
          }
        },
        (error) => {
          this.connectionError();
          this.loading = false;
          //this.submittingData = false;
        }
      );
  }
  fetchAllHeldVehicles() {
    this.serviceAssignmentDetailService
      .allHeldVehicles()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            var res = r.responseData;
            this.vehicleServiceAssignmentDetails = res;
            //console.log(res);
            // this.userProfiles.forEach(userProfile => {
            //   userProfile.fullName = `${userProfile.firstName} ${userProfile.lastName}`
            // });
            this.loading = false;
          }
        },
        (error) => {
          this.connectionError();
          this.loading = false;
          //this.submittingData = false;
        }
      );
  }

  //Available
  availableArmedEscortRouteTieDialogue(resource: ArmedEscortProfile) {
    this.openArmedEscortRouteTieDialogue = true;
    this.resourceregistrationService
      .allArmedEscortProfileTiesByResourceId(resource.id)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            var res = r.responseData;
            this.armedEscortTies = res;
            //console.log("reslt ties", res);
            this.loading = false;
          }
        },
        (error) => {
          this.connectionError();
          this.loading = false;
          //this.submittingData = false;
        }
      );
  }
  // availableArmedEscortSettingTypeDialogue(resource: ArmedEscortProfile){
  //   this.openArmedEscortSettingTypeDialogue = true;
  //   this.resourceregistrationService
  //   .allArmedEscortProfileTiesByResourceId(resource.id)
  //   .pipe(takeUntil(this.unsubscriber$))
  //   .subscribe(
  //     (r: CommonResponse) => {
  //       if (r.responseCode == "00") {
  //         var res = r.responseData;
  //         this.armedEscortTies = res;
  //         //console.log(res);
  //         this.loading = false;
  //       }
  //     },
  //     (error) => {
  //       this.connectionError();
  //       this.loading = false;
  //       //this.submittingData = false;
  //     }
  //   );
  // }
  availableArmedEscortScheduleDialogue(resource: ArmedEscortProfile) {
    this.openArmedEscortScheduleDialogue = true;
    this.resourceSchedulingService
      .allArmedEscortMastersByResourceId(resource.id)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            var res = r.responseData;
            this.armedEscortDTSMasters_ = res;

            const _schedule = this.armedEscortDTSMasters_;
            //this.armedEscortDTSMasters = res;
            var today = new Date();

            this.armedEscortDTSMasters = _schedule.filter(
              (ass) =>
                today >= new Date(ass.availabilityStart) &&
                today <= new Date(ass.availablilityEnd)
            );
            console.log(this.armedEscortDTSMasters);
            this.loading = false;
          }
        },
        (error) => this.connectionError()
      );
  }

  availableCommanderRouteTieDialogue(resource: CommanderProfile) {
    this.openCommanderRouteTieDialogue = true;
    this.resourceregistrationService
      .allCommanderProfileTiesByResourceId(resource.id)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            var res = r.responseData;
            this.commanderTies = res;
            console.log("commander ", res);
            this.loading = false;
          }
        },
        (error) => {
          this.connectionError();
          this.loading = false;
          //this.submittingData = false;
        }
      );
  }

  availableCommanderScheduleDialogue(resource: CommanderProfile) {
    this.openCommanderScheduleDialogue = true;
    this.resourceSchedulingService
      .allCommanderMastersByResourceId(resource.id)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            var res = r.responseData;
            this.commanderDTSMasters_ = res;
            const _schedule = this.commanderDTSMasters_;

            var today = new Date();

            this.commanderDTSMasters = _schedule.filter(
              (ass) =>
                today >= new Date(ass.availabilityStart) &&
                today <= new Date(ass.availablilityEnd)
            );
            this.loading = false;
          }
        },
        (error) => this.connectionError()
      );
  }

  availablePilotRouteTieDialogue(resource: PilotProfile) {
    this.openPilotRouteTieDialogue = true;
    this.resourceregistrationService
      .allPilotProfileTiesByResourceId(resource.id)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            var res = r.responseData;
            this.pilotTies = res;
            //console.log(res);
            this.loading = false;
          }
        },
        (error) => {
          this.connectionError();
          this.loading = false;
          //this.submittingData = false;
        }
      );
  }

  availablePilotScheduleDialogue(resource: PilotProfile) {
    this.openPilotScheduleDialogue = true;
    this.resourceSchedulingService
      .allPilotMastersByResourceId(resource.id)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            var res = r.responseData;
            this.pilotDTSMasters_ = res;

            const _schedule = this.pilotDTSMasters_;

            var today = new Date();

            this.pilotDTSMasters = _schedule.filter(
              (ass) =>
                today >= new Date(ass.availabilityStart) &&
                today <= new Date(ass.availablilityEnd)
            );
            this.loading = false;
          }
        },
        (error) => this.connectionError()
      );
  }

  availableVehicleRouteTieDialogue(resource: VehicleProfile) {
    this.openVehicleRouteTieDialogue = true;
    this.resourceregistrationService
      .allVehicleTiesByResourceId(resource.id)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            var res = r.responseData;
            this.vehicleTies = res;
            //console.log(res);
            this.loading = false;
          }
        },
        (error) => {
          this.connectionError();
          this.loading = false;
          //this.submittingData = false;
        }
      );
  }

  availableVehicleScheduleDialogue(resource: VehicleProfile) {
    this.openVehicleScheduleDialogue = true;
    this.resourceSchedulingService
      .allVehicleMastersByResourceId(resource.id)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            var res = r.responseData;
            this.vehicleDTSMasters_ = res;
            const _schedule = this.vehicleDTSMasters_;

            var today = new Date();

            this.vehicleDTSMasters = _schedule.filter(
              (ass) =>
                today >= new Date(ass.availabilityStart) &&
                today <= new Date(ass.availablilityEnd)
            );

            this.loading = false;
          }
        },
        (error) => this.connectionError()
      );
  }

  //Held doalog
  heldArmedEscortRouteTieDialogue(
    resource: ArmedEscortServiceAssignmentDetail
  ) {
    this.openArmedEscortRouteTieDialogue = true;
    this.resourceregistrationService
      .allArmedEscortProfileTiesByResourceId(resource.armedEscortResourceId)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            var res = r.responseData;
            this.armedEscortTies = res;
            //console.log("reslt ties", res);
            this.loading = false;
          }
        },
        (error) => {
          this.connectionError();
          this.loading = false;
          //this.submittingData = false;
        }
      );
  }
  heldArmedEscortScheduleDialogue(
    resource: ArmedEscortServiceAssignmentDetail
  ) {
    this.openArmedEscortScheduleDialogue = true;
    this.resourceSchedulingService
      .allArmedEscortMastersByResourceId(resource.armedEscortResourceId)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            var res = r.responseData;
            this.armedEscortDTSMasters_ = res;

            const _schedule = this.armedEscortDTSMasters_;
            //this.armedEscortDTSMasters = res;
            var today = new Date();

            this.armedEscortDTSMasters = _schedule.filter(
              (ass) =>
                today >= new Date(ass.availabilityStart) &&
                today <= new Date(ass.availablilityEnd)
            );
            //console.log(this.armedEscortDTSMasters);
            this.loading = false;
          }
        },
        (error) => this.connectionError()
      );
  }
  heldCommanderRouteTieDialogue(resource: CommanderServiceAssignmentDetail) {
    this.openCommanderRouteTieDialogue = true;
    this.resourceregistrationService
      .allCommanderProfileTiesByResourceId(resource.commanderResourceId)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            var res = r.responseData;
            this.commanderTies = res;
            console.log("commander ", res);
            this.loading = false;
          }
        },
        (error) => {
          this.connectionError();
          this.loading = false;
          //this.submittingData = false;
        }
      );
  }
  heldCommanderScheduleDialogue(resource: CommanderServiceAssignmentDetail) {
    this.openCommanderScheduleDialogue = true;
    this.resourceSchedulingService
      .allCommanderMastersByResourceId(resource.commanderResourceId)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            var res = r.responseData;
            this.commanderDTSMasters_ = res;
            const _schedule = this.commanderDTSMasters_;

            var today = new Date();

            this.commanderDTSMasters = _schedule.filter(
              (ass) =>
                today >= new Date(ass.availabilityStart) &&
                today <= new Date(ass.availablilityEnd)
            );
            this.loading = false;
          }
        },
        (error) => this.connectionError()
      );
  }
  heldPilotRouteTieDialogue(resource: PilotServiceAssignmentDetail) {
    this.openPilotRouteTieDialogue = true;
    this.resourceregistrationService
      .allPilotProfileTiesByResourceId(resource.pilotResourceId)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            var res = r.responseData;
            this.pilotTies = res;
            //console.log(res);
            this.loading = false;
          }
        },
        (error) => {
          this.connectionError();
          this.loading = false;
          //this.submittingData = false;
        }
      );
  }
  heldPilotScheduleDialogue(resource: PilotServiceAssignmentDetail) {
    this.openPilotScheduleDialogue = true;
    this.resourceSchedulingService
      .allPilotMastersByResourceId(resource.pilotResourceId)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            var res = r.responseData;
            this.pilotDTSMasters_ = res;

            const _schedule = this.pilotDTSMasters_;

            var today = new Date();

            this.pilotDTSMasters = _schedule.filter(
              (ass) =>
                today >= new Date(ass.availabilityStart) &&
                today <= new Date(ass.availablilityEnd)
            );
            this.loading = false;
          }
        },
        (error) => this.connectionError()
      );
  }
  heldVehicleRouteTieDialogue(resource: VehicleServiceAssignmentDetail) {
    this.openVehicleRouteTieDialogue = true;
    this.resourceregistrationService
      .allVehicleTiesByResourceId(resource.vehicleResourceId)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            var res = r.responseData;
            this.vehicleTies = res;
            //console.log(res);
            this.loading = false;
          }
        },
        (error) => {
          this.connectionError();
          this.loading = false;
          //this.submittingData = false;
        }
      );
  }
  heldVehicleScheduleDialogue(resource: VehicleServiceAssignmentDetail) {
    this.openVehicleScheduleDialogue = true;
    this.resourceSchedulingService
      .allVehicleMastersByResourceId(resource.vehicleResourceId)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            var res = r.responseData;
            this.vehicleDTSMasters_ = res;
            const _schedule = this.vehicleDTSMasters_;

            var today = new Date();

            this.vehicleDTSMasters = _schedule.filter(
              (ass) =>
                today >= new Date(ass.availabilityStart) &&
                today <= new Date(ass.availablilityEnd)
            );

            this.loading = false;
          }
        },
        (error) => this.connectionError()
      );
  }

  //assignments
  getArmedEscortServiceAssignment(
    resource: ArmedEscortServiceAssignmentDetail
  ) {
    //this.submittingData = true;
    //this.loading = true;
    this.openServiceAssignmentDialogue = true;
    this.serviceAssignmentService
      .getServiceAssignment(resource.serviceAssignmentId)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            var res = r.responseData;
            // const resArray = Object.keys(res).map((index) => {
            //   let result = res[index];
            //   return result;
            // });
            //console.log(res);
            this.masterServiceAssignments = res;
            //console.log(this.masterServiceAssignments);
            this.loading = false;
          }
        },
        (error) => {
          this.loading = false;
          this.connectionError();
        }
      );
    //this.submittingData = false;
  }
  getCommanderServiceAssignment(resource: CommanderServiceAssignmentDetail) {
    this.openServiceAssignmentDialogue = true;
    this.serviceAssignmentService
      .getServiceAssignment(resource.serviceAssignmentId)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            var res = r.responseData;
            this.masterServiceAssignments = res;
            this.loading = false;
          }
        },
        (error) => {
          this.loading = false;
          this.connectionError();
        }
      );
  }
  getPilotServiceAssignment(resource: PilotServiceAssignmentDetail) {
    this.openServiceAssignmentDialogue = true;
    this.serviceAssignmentService
      .getServiceAssignment(resource.serviceAssignmentId)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        async (r: CommonResponse) => {
          if (r.responseCode == "00") {
            var res = r.responseData;
            this.masterServiceAssignments = res;
            this.loading = false;
          }
        },
        (error) => {
          this.loading = false;
          this.connectionError();
        }
      );
  }
  getVehicleServiceAssignment(resource: VehicleServiceAssignmentDetail) {
    this.openServiceAssignmentDialogue = true;
    this.serviceAssignmentService
      .getServiceAssignment(resource.serviceAssignmentId)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        async (r: CommonResponse) => {
          if (r.responseCode == "00") {
            var res = r.responseData;
            this.masterServiceAssignments = res;
            this.loading = false;
          }
        },
        (error) => {
          this.loading = false;
          this.connectionError();
        }
      );
  }

  hideDialog() {
    this.openArmedEscortRouteTieDialogue = false;
    //this.openArmedEscortServiceAssignmentDialogue = false;
    this.openServiceAssignmentDialogue = false;
    this.openArmedEscortScheduleDialogue = false;
    this.openCommanderRouteTieDialogue = false;
    this.openCommanderScheduleDialogue = false;
    //this.openCommanderServiceAssignmentDialogue = false;

    this.openPilotRouteTieDialogue = false;
    this.openPilotScheduleDialogue = false;
    //this.openPilotServiceAssignmentDialogue = false;
    this.openVehicleRouteTieDialogue = false;
    this.openVehicleScheduleDialogue = false;
    //this.openVehicleServiceAssignmentDialogue = false;

    //this.submitted = false;
  }

  notImplemented = () => {
    this.messageService.add({
      severity: "info",
      summary: "info",
      detail: "Not yet implemented",
    });
  };

  private connectionError() {
    this.messageService.add({
      severity: "error",
      summary: "Failed",
      detail: "Connection Error, Please try again",
    });
  }
}
