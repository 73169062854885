import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { ConfirmationService, MessageService } from "primeng/api";
import { BreadcrumbService } from "src/app/breadcrumb.service";
import {
  ConvergenceAdvisory,
  ConvergenceCategory,
  EventType,
  RegisterMobileCapturedIncidentVM,
} from "src/app/interfaces/convergence";
import { GeocodingAddressComponent } from "src/app/interfaces/employment";
import { CommonResponse } from "src/app/interfaces/home";
import { ConvergenceService } from "src/app/services/convergence.service";
import { FileStorageService } from "src/app/services/file-storage.service";
import { GmaEmploymentService } from "src/app/services/gma-employment.service";

@Component({
  selector: "app-incidence-convergence-capture-mobile",
  templateUrl: "./incidence-convergence-capture-mobile.component.html",
  styleUrls: ["./incidence-convergence-capture-mobile.component.scss"],
})
export class IncidenceConvergenceCaptureMobileComponent
  implements OnInit, AfterViewInit
{
  @ViewChild("formWrapper") public formWrapper: ElementRef;
  locationGeometry: string;
  isSettingLocationManually: boolean;
  manualLocAddress: string;
  manualLocLatitude: number;
  manualLocLongitude: number;
  locationLatitude: number;
  locationLongitude: number;
  locationAddressComponents: GeocodingAddressComponent[] = [];
  riskLevel: number = 3;
  uploadedPictures: any[] = [];
  userIsYetToClickUploadPicture: boolean;
  allEventTypes: EventType[] = [];
  isTypeRadioButton: number;
  allAdvisories: ConvergenceAdvisory[] = [];
  isAdvisoryRadioButton: number;
  showUplGrid: boolean = true;
  allCategoryEventTypes: EventType[] = [];
  allCategories: ConvergenceCategory[] = [];
  isCategoryButton: number;

  constructor(
    private fb: FormBuilder,
    private fileStorageService: FileStorageService,
    public employmentService: GmaEmploymentService,
    public convergenceService: ConvergenceService,
    private breadcrumbService: BreadcrumbService,
    public confirmationService: ConfirmationService,
    public messageService: MessageService
  ) {}

  ngOnInit(): void {
    this.breadcrumbService.setItems([
      {
        label: "Convergence",
      },
      {
        label: "Quick Capture",
        routerLink: ["/home/convergence/incident-management/mobile-capture"],
      },
    ]);

    this.FetchAllEventType();
    this.FetchAllAdvisories();
    this.FetchAllCategories();
  }

  ngAfterViewInit(): void {
    // Load google maps script after view init
    const DSLScript = document.createElement("script");
    DSLScript.src =
      "https://maps.googleapis.com/maps/api/js?key=AIzaSyCQuetprs2UHb_zKSXznyumyfvw95ERCDY"; // replace by your API key
    DSLScript.type = "text/javascript";
    document.body.appendChild(DSLScript);
    // document.body.removeChild(DSLScript);
  }

  async FetchAllEventType() {
    this.convergenceService.GetAllEventTypes().subscribe(
      async (data) => {
        this.allEventTypes = data;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all event types at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  async FetchAllAdvisories() {
    this.convergenceService.GetAllAdvisories().subscribe(
      async (data) => {
        this.allAdvisories = data;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all advisories at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  async PickCurrentLocation() {
    this.isSettingLocationManually = false;
    await this.GetCurrentLocation();
  }

  async FetchAllCategories() {
    this.convergenceService.GetAllConvergenceCategories().subscribe(
      async (data) => {
        this.allCategories = data;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all categories at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  SetLocationManually() {
    this.isSettingLocationManually = true;
  }

  async GetCurrentLocation() {
    var uri =
      "https://www.googleapis.com/geolocation/v1/geolocate?key=AIzaSyCQuetprs2UHb_zKSXznyumyfvw95ERCDY";
    var xmlhttp = new XMLHttpRequest();
    xmlhttp.onreadystatechange = function () {
      if (xmlhttp.readyState == 4 && xmlhttp.status == 200) {
        let result = JSON.parse(xmlhttp.responseText);
        localStorage.setItem("longitude", result.location.lng);
        localStorage.setItem("latitude", result.location.lat);
        document.getElementById("btnVerifyGeo").click();
      } else if (xmlhttp.status == 404) {
        alert(
          "Unable to get current location cordinates = " + xmlhttp.status + "."
        );
      }
    };
    xmlhttp.open("POST", uri, true);
    xmlhttp.send();
    xmlhttp.onerror = function () {
      alert(
        "Unable to get current location cordinates = " +
          xmlhttp.statusText +
          "."
      );
    };
  }

  GetCurrentLocationDetails() {
    let lat = localStorage.getItem("latitude");
    let lng = localStorage.getItem("longitude");
    localStorage.clear();
    console.log("Latitude " + lat + " Longitude: " + lng);
    if (!lng || !lat) {
      this.messageService.add({
        severity: "error",
        summary: "Notice",
        detail:
          "Unable to get your current location cordinates, Kindly check your internet connection and try again.",
      });
      return;
    }
    this.manualLocLatitude = +lat;
    this.manualLocLongitude = +lng;
    console.log(
      "manualLocLatitude " +
        this.manualLocLatitude +
        " manualLocLongitude: " +
        this.manualLocLongitude
    );
    this.LoadManualLocationCordinates();
  }

  async ClearReport() {
    this.locationGeometry = null;
    this.locationLatitude = null;
    this.locationLongitude = null;
    this.locationAddressComponents = [];
    this.isTypeRadioButton = null;
    this.riskLevel = 3;
    this.isAdvisoryRadioButton = null;
    this.showUplGrid = false;
    this.uploadedPictures = [];
    await new Promise((resolve) => setTimeout(resolve, 2000)); // 2 secs
    this.showUplGrid = true;
  }

  async SubmitReport() {
    this.confirmationService.confirm({
      message: "Are you sure you want to submit this incidence report?",
      accept: () => {
        this.messageService.add({
          severity: "info",
          summary: "Notice",
          detail: "Submitting Incidence Report...",
        });

        const postData: RegisterMobileCapturedIncidentVM = {
          locationLongitude: this.locationLongitude,
          locationLatitude: this.locationLatitude,
          locationGeometry: this.locationGeometry,
          typeId: this.isTypeRadioButton,
          typeName: this.allEventTypes.find(
            (x) => x.id == this.isTypeRadioButton
          ).caption,
          riskLevel: this.riskLevel,
          uploads: [],
          advisoryId: this.isAdvisoryRadioButton,
          advisoryCaption: this.allAdvisories.find(
            (x) => x.id == this.isAdvisoryRadioButton
          ).description,
          addressComponents: this.locationAddressComponents,
        };

        if (this.uploadedPictures.length > 0) {
          this.fileStorageService.UploadMultipleFilesFromDataUrl(
            this.uploadedPictures
          );
          this.fileStorageService.onUploadFinished.subscribe(
            (resp: CommonResponse<string[]>) => {
              if (resp.responseCode != "00") {
                this.messageService.add({
                  severity: "error",
                  summary: "Notice",
                  detail: resp.responseMsg,
                });
              } else {
                postData.uploads = resp.responseData;
                this._submitReport(postData);
              }
            },
            (error) => {
              console.log("Error while uploading files " + error);
              this.messageService.add({
                severity: "error",
                summary: "Notice",
                detail: "ERR: Unable to upload image to storage",
              });
            }
          );
        } else {
          this._submitReport(postData);
        }
      },
    });
  }

  _submitReport(postData: RegisterMobileCapturedIncidentVM) {
    this.convergenceService.RegisterMobileCapturedIncident(postData).subscribe(
      async () => {
        this.messageService.add({
          severity: "success",
          summary: "Completed",
          detail: "Incident Report Submitted Successfully!",
        });

        this.ClearReport();
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to submit incidence report at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  onCancelUpload() {
    this.uploadedPictures = [];
  }

  NotifyUserToClickUpload() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Kindly ensure you click upload.",
    });

    this.userIsYetToClickUploadPicture = true;
  }

  onUploadPicture(event) {
    this.uploadedPictures = [];
    for (const file of event.files) {
      this.uploadedPictures.push(file);
    }

    this.messageService.add({
      severity: "success",
      summary: "Success",
      detail: "Picture(s) Uploaded",
    });

    this.userIsYetToClickUploadPicture = false;
  }

  LoadManualLocationAddress() {
    this.GetInputtedLocationCordinates(this.manualLocAddress);
  }

  GetInputtedLocationCordinates(address: string) {
    this.employmentService.GetAddressGeocodingLocation(address).subscribe(
      async (data) => {
        if (data.status != "OK") {
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail:
              "Unable to get address cordinates at the moment.. " +
              data.error_message,
          });
          return;
        }
        this.locationLatitude = data.results[0].geometry.location.lat;
        this.locationLongitude = data.results[0].geometry.location.lng;
        this.locationGeometry = data.results[0].formatted_address;
        this.locationAddressComponents = data.results[0].address_components;
        this.isSettingLocationManually = false;
        this.manualLocAddress = null;
        this.manualLocAddress = null;
        this.manualLocLongitude = null;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to get address cordinates at the moment.. Reason: [" +
            error.error.message +
            "]",
        });
      }
    );
  }

  LoadManualLocationCordinates() {
    this.employmentService
      .GetReverseGeocodingLocation(
        this.manualLocLatitude,
        this.manualLocLongitude
      )
      .subscribe(
        async (data) => {
          console.log(data);
          if (data.status.toLowerCase() == "ok") {
            this.locationLatitude = this.manualLocLatitude;
            this.locationLongitude = this.manualLocLongitude;
            this.locationGeometry = data.results[0].formatted_address;
            this.locationAddressComponents = data.results[0].address_components;
            this.isSettingLocationManually = false;
            this.manualLocAddress = null;
            this.manualLocLatitude = null;
            this.manualLocLongitude = null;
          }
        },
        (error) => {
          console.log("Error: " + JSON.stringify(error));
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail:
              "Unable to get cordinates location at the moment.. Reason: [" +
              (error ? error.error.message : "request failed - permission") +
              "]",
          });
        }
      );
  }

  OnCategoryChange() {
    this.allCategoryEventTypes = [];
    this.isTypeRadioButton = null;
    if (this.isCategoryButton) {
      this.allCategoryEventTypes = this.allEventTypes.filter(
        (x) => x.categoryId == this.isCategoryButton
      );
    }
  }

  async GetUserCurrentCordinates() {
    const DSLScript = document.createElement("script");
    DSLScript.src = "http://www.google.com/jsapi";
    DSLScript.type = "text/javascript";
    document.body.appendChild(DSLScript);
    console.log("In GetUserCurrentCordinates");
    navigator.geolocation.getCurrentPosition(async (position) => {
      console.log("Result IN GetUserCurrentCordinates");
      console.log(position);
      console.log("Result OUT GetUserCurrentCordinates");
    });
    console.log("Out GetUserCurrentCordinates");
  }
}
