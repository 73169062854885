import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ConfirmEventType, ConfirmationService, MessageService } from 'primeng/api';
import { EndorsementType } from 'src/app/interfaces/home';
import { BudgetapiservicesService } from 'src/app/services/budget/budgetapiservices.service';

@Component({
  selector: 'app-endorsement-type',
  templateUrl: './endorsement-type.component.html',
  styleUrls: ['./endorsement-type.component.css']
})
export class EndorsementTypeComponent implements OnInit {

  setupForm = this.fb.group({
    id: [''],
    caption: ['', Validators.required],
    description: ['', Validators.required],
    isBudgetRelated: [false]
  });

  endorsementTypeList: EndorsementType[]=[]
  endorsementType: EndorsementType
  selectedEndorsementType: EndorsementType[]=[]
  checked: boolean = false;
  loadingData: boolean = false;
  isFormBusy: boolean = false;

  editFormRecord: boolean = false;

  constructor(private fb: FormBuilder,
      private apiService: BudgetapiservicesService,
      private messageService: MessageService,
      private confirmationService: ConfirmationService,) { }

  ngOnInit(): void {
    this.getEndorsementTypes();
  }

  change(evt) { 
    this.checked = evt.checked
    console.log(evt.checked ?  "Checked" : "Unchecked"); 
  } 
  changeState(event) { 
    console.log(this.setupForm.getRawValue()); 
    console.log(event)
  } 

  getEndorsementTypes() {
    this.loadingData = true;
    this.apiService.getEndorsementTypes().subscribe((data) => {
      console.log(data);
      this.endorsementTypeList = data['responseData'].endorseTypeList;
    }, (error) => {
      this.loadingData = false;
      console.log(JSON.stringify(error));
    }, () => {
      this.loadingData = false;
    })
  }

  createEndorsementType() {

    this.confirmationService.confirm({
      message: `Proceed with saving this record?`,
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        // this.messageService.add({ severity: 'info', summary: 'Confirmed', detail: 'You have accepted' });
        // this.checkIfHasDistributions();
            this.loadingData = true;
            this.isFormBusy = true;
            setTimeout(() => {
              this.displayMessage("info", "Information", "Processing operation")
              this.endorsementTypeList = undefined;
            }, 1000);
            setTimeout(() => {
              this.confirmedEndorsementCreation();
              // this.loadingData=false;
              // this.isFormBusy=false;
              
            }, 2000)
            
            // this.loadingData = false;
            // this.isFormBusy = false;
            
        
      },
      reject: (type: ConfirmEventType) => {
          switch (type) {
              case ConfirmEventType.REJECT:
                  this.messageService.add({ severity: 'warn', summary: 'Cancelled', detail: 'You have cancelled this operation' });
                  break;
              case ConfirmEventType.CANCEL:
                  this.messageService.add({ severity: 'warn', summary: 'Cancelled', detail: 'You have cancelled this operation' });
                  break;
          }
        }
    });
  }

  confirmedEndorsementCreation() {
    this.loadingData = true;
    this.displayMessage("info","Info","Processing ...");
    var data2Save = this.setupForm.getRawValue();
    console.log(data2Save);
    this.apiService.createEndorsementTypes(data2Save).subscribe((data) => {
      console.log(data);
      if (data["responseCode"] == "00") {
        this.displayMessage("success","Success","Record created successfully");
        this.getEndorsementTypes();
        this.cancelEdit();
      }
      else {
        this.displayMessage("error","Error",`Error Creating record\n${data['responseMsg']}`);
        this.cancelEdit();
      }

    }, (error) => {
      this.loadingData = false;
      this.isFormBusy=false;
      console.log(JSON.stringify(error));
      this.displayMessage("error","Error",`Error creating record.\n${JSON.stringify(error.error.errors)}`);
      this.cancelEdit();
    }, () => {
      this.loadingData = false;
      this.isFormBusy=false;
    })
  }


  updateEndorsementType() {
    this.confirmationService.confirm({
      message: `Proceed with updating this record?`,
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.loadingData = true;
        this.isFormBusy = true;
        setTimeout(() => {
          this.displayMessage("info", "Information", "Processing operation")
          this.endorsementTypeList = undefined;
        }, 1000);
        setTimeout(() => {
          this.confirmedEndorsementUpdate();
          
          // this.setupForm.reset();
          // this.getEndorsementTypes();
        }, 2000)
            
        
      },
      reject: (type: ConfirmEventType) => {
        switch (type) {
            case ConfirmEventType.REJECT:
                this.messageService.add({ severity: 'warn', summary: 'Cancelled', detail: 'You have cancelled this operation' });
                break;
            case ConfirmEventType.CANCEL:
                this.messageService.add({ severity: 'warn', summary: 'Cancelled', detail: 'You have cancelled this operation' });
                break;
        }
      }
    });
    
  }

  confirmedEndorsementUpdate() {
    this.loadingData = true;
    var data2Update = this.setupForm.getRawValue();
    console.log(data2Update);
    this.apiService.updateEndorsementTypes(data2Update).subscribe((data) => {
      if (data["responseCode"] == "00") {
        this.displayMessage("success","Success","Record updated successfully");
        
        this.cancelEdit();
      }
      else {
        this.displayMessage("error","Error",`Error updating record.\n${data['responseMessage']}`);
        this.cancelEdit();
      }
    }, (error) => {
      this.loadingData = false;
      this.isFormBusy=false;
      console.log(JSON.stringify(error));
      this.displayMessage("error","Error",`Error updating record.\n${JSON.stringify(error.error.errors)}`);
      this.cancelEdit();
    }, () => {
      this.loadingData = false;
      this.isFormBusy=false;
      this.getEndorsementTypes();
    })
  }

  editEndorsementType(item) {
    console.log(item);
    this.editFormRecord = true;
    this.setupForm.patchValue({
      id: item.id,
      caption: item.caption,
      description: item.description,
      isBudgetRelated: item.isBudgetRelated
    })
  }

  cancelEdit() {
    this.editFormRecord = false;
    this.setupForm.reset({
      id: '',
      caption: '',
      description: '',
      isBudgetRelated: false
    });
  }

  deleteEndorsementType(item) {
    this.confirmationService.confirm({
      message: `Proceed with deleteing this record?`,
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.loadingData = true;
        this.isFormBusy = true;
        setTimeout(() => {
          this.displayMessage("info", "Information", "Processing operation")
          this.endorsementTypeList = undefined;
        }, 1000);
        setTimeout(() => {
          this.confirmedEndorsementDelete(item);
          // this.loadingData=false;
          // this.isFormBusy=false;
          // this.setupForm.reset();
          this.getEndorsementTypes();
        }, 2000)
            
        
      },
      reject: (type: ConfirmEventType) => {
        switch (type) {
            case ConfirmEventType.REJECT:
                this.messageService.add({ severity: 'warn', summary: 'Cancelled', detail: 'You have cancelled this operation' });
                break;
            case ConfirmEventType.CANCEL:
                this.messageService.add({ severity: 'warn', summary: 'Cancelled', detail: 'You have cancelled this operation' });
                break;
        }
      }
    });
    
  }

  confirmedEndorsementDelete(item) {
    console.log(item);
    this.apiService.deleteEndorsementTypes(item.id).subscribe((data) => {
      if (data['responseCode'] == "00") {
        this.displayMessage("success", "Success", "Record deleted successfully");
        this.getEndorsementTypes();
        this.cancelEdit();
      }
      else {
        this.displayMessage("error","Error",`Error deleting record.\n${data['responseMessage']}`);
      }
    }, (error) => {
      this.loadingData = false;
      this.isFormBusy=false;
      this.displayMessage("error","Error",`Error deleting record.\n${JSON.stringify(error.error.errors)}`);
      console.log(JSON.stringify(error));
      this.cancelEdit();
    }, () => {
      this.loadingData = false;
      this.isFormBusy=false;
    })
  }

  displayMessage(severity, summary, message) {
    this.messageService.add({
      severity: severity,
      summary: summary,
      detail: message,
    });
  }

}



