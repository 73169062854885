import {
  ActOnPayrollApprovalVM,
  PayrollApproval,
  PayrollApprovalStatusEnum,
  PayrollApprovingOffice,
  PayrollRun,
  PayrollRunOperation,
  PayrollRunTypeEnum,
} from "./../../../interfaces/payroll";
import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { ConfirmationService, MessageService, TreeNode } from "primeng/api";
import { BreadcrumbService } from "src/app/breadcrumb.service";
import { PayrollService } from "src/app/services/payroll.service";
import { GuardDeployment } from "src/app/interfaces/employment";

@Component({
  selector: "app-payroll-approval",
  templateUrl: "./payroll-approval.component.html",
  styleUrls: ["./payroll-approval.component.scss"],
})
export class PayrollApprovalComponent implements OnInit {
  @ViewChild("formWrapper") public formWrapper: ElementRef;
  allPendingApprovals: PayrollRun[];
  selectedPendingApprovals: PayrollRun[];
  pendingApprovalCols: any[];
  allPendingApprovalDits: PayrollApproval[];
  selectedPendingApprovalDits: PayrollApproval[];
  pendingApprovalDitsCols: any[];
  totAllowance: string;
  totDeduct: string;
  totTax: string;
  totNetPay: string;
  allApprovalInViewDetails: PayrollRunOperation[];
  approvalViewDitsCols: any[];
  payrollRunInView: PayrollRun;
  isViewingStats: boolean = false;
  isViewingDetails: boolean = false;
  canApproveOrDecline: boolean = false;
  officeId: number;
  officerId: number;
  officeSequence: number;
  allOfficesToApprove: PayrollApprovingOffice[];
  payrollOperationInView: PayrollRunOperation;
  openBreakdownDialogue: boolean;
  operationTree: TreeNode[];
  selectedOperationTree: TreeNode[];
  cols: any[];
  allApprovalDetailsDeployments: GuardDeployment[];

  constructor(
    private fb: FormBuilder,
    private payrollService: PayrollService,
    private breadcrumbService: BreadcrumbService,
    public confirmationService: ConfirmationService,
    public messageService: MessageService
  ) {}

  ngOnInit(): void {
    this.breadcrumbService.setItems([
      {
        label: "Payroll",
        routerLink: ["/home/payroll/payroll-settings"],
      },
      {
        label: "Batch Payroll Approval",
        routerLink: ["/home/payroll/approval"],
      },
    ]);

    this.pendingApprovalCols = [
      { field: "payGroup.caption", header: "PayGroup.caption" },
    ];

    this.pendingApprovalDitsCols = [
      { field: "actedOnSequence", header: "ActedOnSequence" },
      { field: "createdBy.fullName", header: "CreatedBy.fullName" },
    ];

    this.approvalViewDitsCols = [
      { field: "location", header: "Location" },
      {
        field: "deployedGuard.jobApplication.personalInformation.firstname",
        header: "Firstname",
      },
      {
        field: "deployedGuard.jobApplication.personalInformation.lastname",
        header: "Lastname",
      },
      { field: "deployedGuardId", header: "OperativeID" },
      { field: "totalAllowancesFigure", header: "Total Allowance" },
      { field: "totalDeductionsFigure", header: "Total Deduction" },
      { field: "absenceDeductions", header: "Absence Deduction" },
      { field: "netPayFigure", header: "Net Pay" },
    ];

    this.cols = [
      { field: "name", header: "Name" },
      {
        field: "amount",
        header: "Amount",
      },
    ];

    this.operationTree = [];
    this.FetchAllApprovals();
    this.FetchAllConfiguredApprovals();
  }

  FetchAllApprovals() {
    this.payrollService.GetAllPayrollApprovalItems().subscribe(
      async (data) => {
        this.allPendingApprovals = data.notPicked.filter(
          (x) =>
            x.isUsingBatchApproval == null || x.isUsingBatchApproval == true
        );
        this.officeId = data.approvingOfficeId;
        this.officerId = data.approvingOfficerId;
        this.officeSequence = data.approvingOfficeSequence;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to get all approvals at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  FetchAllConfiguredApprovals() {
    this.payrollService.GetAllApprovingOffice().subscribe(
      async (data) => {
        this.allOfficesToApprove = data;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to get all approval offices to approve at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  GetRunTypeLabel(runType: number): string {
    if (runType == PayrollRunTypeEnum.TrialRun) return "Trial Run";
    if (runType == PayrollRunTypeEnum.FinalRun) return "Final Run";
    return "";
  }

  PickBatchToActOn(item: PayrollRun) {
    this.payrollRunInView = item;
    item.approvals.forEach((x) => {
      x.createdBy.fullName = x.createdBy.lastName + " " + x.createdBy.firstName;
    });
    this.allPendingApprovalDits = [];
    this.allOfficesToApprove.forEach((office) => {
      let approvedUser = item.approvals.find(
        (x) =>
          x.payrollApprovingOfficeId == office.id ||
          x.actedOnSequence == office.sequence
      );

      this.allPendingApprovalDits.push({
        actedOnSequence: office.sequence,
        createdBy: approvedUser ? approvedUser.createdBy : null,
        status: approvedUser ? approvedUser.status : 1,
      });
    });
    this.isViewingStats = true;
    this.isViewingDetails = false;

    let totalAllowance: number = 0;
    let totalDeduction: number = 0;
    let totalTax: number = 0;
    let totalNetPay: number = 0;

    item.payrollRunOperations.forEach((operations) => {
      totalAllowance = totalAllowance + operations.totalAllowancesFigure;
      totalDeduction = totalDeduction + operations.totalDeductionsFigure;
      totalTax = totalTax + operations.appliedTaxAmount;
      totalNetPay = totalNetPay + operations.netPayFigure;
    });
    this.totAllowance = "N" + totalAllowance.toLocaleString();
    this.totDeduct = "N" + totalDeduction.toLocaleString();
    this.totTax = "N" + totalTax.toLocaleString();
    this.totNetPay = "N" + totalNetPay.toLocaleString();

    let officeApproval = item.approvals.find(
      (x) =>
        x.payrollApprovingOfficeId == this.officeId ||
        x.actedOnSequence == this.officeSequence
    );
    if (officeApproval) this.canApproveOrDecline = false;
    else this.canApproveOrDecline = true;
  }

  CompareWithPrevMonth() {}

  ViewDetailsBelow() {
    this.isViewingDetails = true;

    this.payrollService
      .GetPayrollRunOperationsWithReportView(this.payrollRunInView.id)
      .subscribe(
        async (data) => {
          this.allApprovalDetailsDeployments = data.guardDeployments;
          this.allApprovalInViewDetails = data.payrollOperations;
        },
        (error) => {
          console.log("Error: " + JSON.stringify(error));
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail:
              "Unable to load payroll run report at the moment.. Reason: [" +
              error
                ? error.error.message
                : "request failed - permission" + "]",
          });
        }
      );
  }

  ApprovePayrollRun() {
    this.confirmationService.confirm({
      message: "Are you sure you want to approve this payroll run?",
      header: "Confirm",
      icon: "pi pi-exclamation-triangle",
      accept: () => {
        this.messageService.add({
          severity: "info",
          summary: "Notice",
          detail: "Approving payroll run...",
        });

        const postData: ActOnPayrollApprovalVM = {
          payrollRunId: this.payrollRunInView.id,
          officerId: this.officerId,
          actionStatus: PayrollApprovalStatusEnum.Approved,
        };

        this.payrollService.ActOnPayrollApproval(postData).subscribe(
          async () => {
            this.messageService.add({
              severity: "success",
              summary: "Successful",
              detail: "Approved Successful",
              life: 3000,
            });

            this.payrollRunInView = null;
            this.isViewingStats = false;
            this.isViewingDetails = false;
            this.allPendingApprovals = [];
            this.FetchAllApprovals();
          },
          (error) => {
            console.log("Error: " + JSON.stringify(error));
            this.messageService.add({
              severity: "error",
              summary: "Notice",
              detail:
                "Unable to approve payroll run at the moment.. Reason: [" +
                error
                  ? error.error.message
                  : "request failed - permission" + "]",
            });
          }
        );
      },
    });
  }

  DeclinePayrollRun() {
    this.confirmationService.confirm({
      message: "Are you sure you want to decline this payroll run?",
      header: "Confirm",
      icon: "pi pi-exclamation-triangle",
      accept: () => {
        this.messageService.add({
          severity: "info",
          summary: "Notice",
          detail: "Declining payroll run...",
        });

        const postData: ActOnPayrollApprovalVM = {
          payrollRunId: this.payrollRunInView.id,
          officerId: this.officerId,
          actionStatus: PayrollApprovalStatusEnum.Declined,
        };

        this.payrollService.ActOnPayrollApproval(postData).subscribe(
          async () => {
            this.messageService.add({
              severity: "success",
              summary: "Successful",
              detail: "Declined Successful",
              life: 3000,
            });

            this.payrollRunInView = null;
            this.isViewingStats = false;
            this.isViewingDetails = false;
            this.allPendingApprovals = [];
            this.FetchAllApprovals();
          },
          (error) => {
            console.log("Error: " + JSON.stringify(error));
            this.messageService.add({
              severity: "error",
              summary: "Notice",
              detail:
                "Unable to decline payroll run at the moment.. Reason: [" +
                error
                  ? error.error.message
                  : "request failed - permission" + "]",
            });
          }
        );
      },
    });
  }

  ViewOperationBreakdown(item: PayrollRunOperation) {
    this.operationTree = [];

    let allowanceTree: TreeNode = {
      data: {
        name: "Allowance(s)",
        amount: null,
      },
      children: [],
    };
    let deductionTree: TreeNode = {
      data: {
        name: "Deduction(s)",
        amount: null,
      },
      children: [],
    };

    item.allowances.forEach((allowance) => {
      allowanceTree.children.push({
        data: {
          name: allowance.allowanceName,
          amount: allowance.amount,
        },
      });
    });
    item.deductions.forEach((deduction) => {
      deductionTree.children.push({
        data: {
          name: deduction.deductionName,
          amount: deduction.amount,
        },
      });
    });

    this.operationTree.push(allowanceTree);
    this.operationTree.push(deductionTree);
    this.payrollOperationInView = item;
    this.openBreakdownDialogue = true;
  }

  HideBreakdownDialog() {
    this.openBreakdownDialogue = false;
    this.payrollOperationInView = null;
  }

  GetOperativeLocation(item: PayrollRunOperation): string {
    let deployment = this.allApprovalDetailsDeployments.find(
      (x) => x.deployedGuardId == item.deployedGuardId
    );
    if (deployment && deployment.location) return deployment.location.name;

    return "Pool";
  }
}
