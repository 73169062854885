import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CommonResponse } from 'src/app/interfaces/home';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PbmSpecialRequestWorkbenchServiceService {

  constructor(private http: HttpClient) { }
  baseUrl = environment.baseUrl+'SpecialRequestWorkbench/';
  // fetch all OperatingEntity in the database
  allData(): Observable<CommonResponse>{
    return this.http.get<CommonResponse>(this.baseUrl);
  }

  getAllSpecialRequests(): Observable<CommonResponse>{
    return this.http.get<CommonResponse>(this.baseUrl + 'GetAllSpecialRequests');
  }

  convertRequestsToContracts(requests: any): Observable<CommonResponse>{
    return this.http.post<CommonResponse>(this.baseUrl + 'CreateContractsAndInvoices', requests);
  }

  updateSpecialRequest(requests: any): Observable<CommonResponse>{
    return this.http.post<CommonResponse>(this.baseUrl + 'UpdateSpecialRequest', requests);
  }

  getTaskStatuses(task: any):  Observable<CommonResponse>{
    return this.http.get<CommonResponse>(this.baseUrl + 'GetDeliverableStatuses?taskId='+ task);
  }

  updateDeliverableStatus(deliverableId: any, statusId: any):Observable<CommonResponse>{
    return this.http.put<CommonResponse>(this.baseUrl + 'UpdateDeliverable/' + deliverableId + '/' + statusId, null);
  }

  sendInvoices(invoices: any):Observable<CommonResponse>{
    return this.http.post<CommonResponse>(this.baseUrl + 'SendInvoices', invoices);
  }
}
