import { Injectable } from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {AssignmentAcceptanceStatus} from '../../interfaces/armada';

@Injectable({
  providedIn: 'root'
})
export class AssignmentAcceptanceStatusService {
  baseUrl = environment.armadaBaseUrl+'AssignmentAcceptanceStatus/';
  constructor(private httpClient: HttpClient) { }
  allAssignmentAcceptanceStatus(): Observable<AssignmentAcceptanceStatus>{
    return this.httpClient.get<AssignmentAcceptanceStatus>(this.baseUrl);
  }
  getAssignmentAcceptanceStatus(id: any):Observable<AssignmentAcceptanceStatus>{
    return this.httpClient.get<AssignmentAcceptanceStatus>(this.baseUrl +id);
  }
  getAssignmentAcceptanceStatusByName(name: any):Observable<AssignmentAcceptanceStatus>{
    return this.httpClient.get<AssignmentAcceptanceStatus>(this.baseUrl + 'name/' + name);
  }
  postAssignmentAcceptanceStatus(data: any){
    return this.httpClient.post(this.baseUrl, data);
  }
  delete(id: any){
    return this.httpClient.delete(this.baseUrl +id);
  }
  updateAssignmentAcceptanceStatus(id: any, data: any){
    return this.httpClient.put(this.baseUrl +id, data);
  }
  acceptServiceAssignment(serviceAssignmentId: number) {
    return this.httpClient.put(`${this.baseUrl}AcceptServiceAssignment/${serviceAssignmentId}`, '');
  }
  rejectServiceAssignment(serviceAssignmentId: number) {
    return this.httpClient.put(`${this.baseUrl}DeclineServiceAssignment/${serviceAssignmentId}`, '');
  }
  getCommanderAcceptanceStatus(serviceAssignmentId: number) {
    return this.httpClient.get(`${this.baseUrl}GetCommanderAcceptanceStatus/${serviceAssignmentId}`);
  }
}
