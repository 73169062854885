import {
  ConvergenceReviewOffice,
  ConvergenceApprovalOffice,
  CreateReviewOfficeVM,
  ConvergenceReviewOfficer,
  ConvergenceApprovalOfficer,
  UpdateReviewOfficeVM,
  CreateApprovalOfficeVM,
  UpdateApprovalOfficeVM,
} from "./../../../../interfaces/convergence";
import { ConvergenceService } from "./../../../../services/convergence.service";
import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ConfirmationService, MessageService } from "primeng/api";
import { BreadcrumbService } from "src/app/breadcrumb.service";
import { UserService } from "src/app/services/user.service";
import { User } from "src/app/interfaces/home";

@Component({
  selector: "app-incidence-convergence-setting",
  templateUrl: "./incidence-convergence-setting.component.html",
  styleUrls: ["./incidence-convergence-setting.component.scss"],
})
export class IncidenceConvergenceSettingComponent implements OnInit {
  @ViewChild("formWrapper") public formWrapper: ElementRef;
  reviewOfficeForm: FormGroup;
  approvalOfficeForm: FormGroup;
  theReviewUser: User;
  allUsers: User[];
  addedReviewingOfficers: ConvergenceReviewOfficer[];
  selectedReviewingOfficer: ConvergenceReviewOfficer[];
  addedReviewOfficerCols: any[];
  editingReviewOffice: boolean;
  fetchingReviewOffices: boolean;
  allReviewingOffices: ConvergenceReviewOffice[];
  selectedReviewingOffices: ConvergenceReviewOffice[];
  reviewOfficeCols: any[];
  theApprovalUser: User;
  addedApprovingOfficers: ConvergenceApprovalOfficer[];
  selectedApprovingOfficer: ConvergenceApprovalOfficer[];
  addedApprovalOfficerCols: any[];
  editingApprovalOffice: boolean;
  fetchingApprovalOffices: boolean;
  allApprovingOffices: ConvergenceApprovalOffice[];
  selectedApprovingOffices: ConvergenceApprovalOffice[];
  approvalOfficeCols: any[];
  reviewOfficeToEdit: ConvergenceReviewOffice;
  approvingOfficeToEdit: ConvergenceApprovalOffice;

  constructor(
    private fb: FormBuilder,
    public userService: UserService,
    public convergenceService: ConvergenceService,
    private breadcrumbService: BreadcrumbService,
    public confirmationService: ConfirmationService,
    public messageService: MessageService
  ) {
    this.reviewOfficeForm = fb.group({
      ReviewUser: [""],
      OfficeName: ["", Validators.required],
    });

    this.approvalOfficeForm = fb.group({
      ApprovalUser: [""],
      OfficeName: ["", Validators.required],
    });
  }

  ngOnInit(): void {
    this.breadcrumbService.setItems([
      {
        label: "Convergence",
      },
      {
        label: "Incident Management",
      },
      {
        label: "Setting",
        routerLink: ["/home/convergence/incident-management/setting"],
      },
    ]);

    this.addedReviewOfficerCols = [
      { field: "user.lastName", header: "Lastname" },
      { field: "createdAt", header: "Date Created" },
    ];
    this.addedApprovalOfficerCols = [
      { field: "user.lastName", header: "Lastname" },
      { field: "createdAt", header: "Date Created" },
    ];
    this.reviewOfficeCols = [{ field: "name", header: "Name" }];
    this.approvalOfficeCols = [{ field: "name", header: "Name" }];

    this.addedReviewingOfficers = [];
    this.addedApprovingOfficers = [];

    this.FetchAllUsers();
    this.FetchAllReviewOffice();
    this.FetchAllApprovalOffice();
  }

  get ReviewingUser() {
    return this.reviewOfficeForm.get("ReviewUser").value;
  }

  get ApprovingUser() {
    return this.approvalOfficeForm.get("ApprovalUser").value;
  }

  FetchAllReviewOffice() {
    this.fetchingReviewOffices = true;
    this.convergenceService.GetAllReviewOffices().subscribe(
      async (data) => {
        this.allReviewingOffices = data;
        this.fetchingReviewOffices = false;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all review offices at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
        this.fetchingReviewOffices = false;
      }
    );
  }

  FetchAllApprovalOffice() {
    this.convergenceService.GetAllApprovalOffices().subscribe(
      async (data) => {
        this.allApprovingOffices = data;
        this.fetchingApprovalOffices = false;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all approving office at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
        this.fetchingApprovalOffices = false;
      }
    );
  }

  FetchAllUsers() {
    this.userService.allUser().subscribe(
      async (r) => {
        var data = r.responseData ?? [];
        this.allUsers = [];
        data.forEach((user) => {
          user.fullName = user.lastName + " " + user.firstName;
          this.allUsers.push(user);
        });
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all users at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  CreateReviewOffice() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Creating Review Office...",
    });

    const postData: CreateReviewOfficeVM = {
      name: this.reviewOfficeForm.get("OfficeName").value,
      officersIds: this.addedReviewingOfficers.map((x) => x.userId),
    };

    this.convergenceService.CreateReviewOffice(postData).subscribe(
      async (data) => {
        this.messageService.add({
          severity: "success",
          summary: "Completed",
          detail: "Approving Office Created Successfully!",
        });

        this.reviewOfficeForm.reset();
        this.addedReviewingOfficers = [];
        this.FetchAllReviewOffice();
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to create reviewing office at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  AddReviewOfficerToList() {
    if (!this.ReviewingUser) {
      this.messageService.add({
        severity: "error",
        summary: "Failed",
        detail: "You must select the officer to add",
      });
      return;
    }

    let alreadyAdded = false;
    this.addedReviewingOfficers.forEach((item) => {
      if (item.userId == this.ReviewingUser.id) {
        alreadyAdded = true;
      }
    });

    if (alreadyAdded) {
      this.messageService.add({
        severity: "error",
        summary: "Failed",
        detail: "Officer with the same info has already been added to list.",
      });
      return;
    }

    this.addedReviewingOfficers.push({
      userId: this.ReviewingUser.id,
      user: this.ReviewingUser,
    });

    this.theReviewUser = null;
    this.messageService.add({
      severity: "success",
      summary: "Added",
      detail: "Reviewing Officer added successfully",
    });
  }

  RemoveReviewOfficer(item: ConvergenceReviewOfficer) {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Removing Reviewing Officer...",
    });

    const index = this.addedReviewingOfficers.indexOf(item);
    if (index > -1) {
      this.addedReviewingOfficers.splice(index, 1);
    }

    this.messageService.add({
      severity: "success",
      summary: "Removed",
      detail: "Reviewing Officer Removed successfully",
    });
  }

  CloseEditingReviewOffice() {
    this.editingReviewOffice = false;
    this.reviewOfficeForm.reset();
    this.addedReviewingOfficers = [];
    this.reviewOfficeToEdit = null;
  }

  UpdateReviewOffice() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Updating Review Office...",
    });

    let addedOfficerIds: number[] = [];
    this.addedReviewingOfficers.forEach((officer) => {
      addedOfficerIds.push(officer.userId);
    });

    const id = this.reviewOfficeToEdit.id;
    const postData: UpdateReviewOfficeVM = {
      officeName: this.reviewOfficeForm.get("OfficeName").value,
      officersIds: addedOfficerIds,
    };

    this.convergenceService.UpdateReviewOffice(id, postData).subscribe(
      async () => {
        this.messageService.add({
          severity: "success",
          summary: "Completed",
          detail: "Review Office Updated Successfully.",
        });

        this.addedReviewingOfficers = [];
        this.FetchAllReviewOffice();
        this.editingReviewOffice = false;
        this.reviewOfficeForm.reset();
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to update reviewing office at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  DeleteReviewalOffice(item: ConvergenceReviewOffice) {
    this.confirmationService.confirm({
      message: "Are you sure you want to remove review office?",
      accept: () => {
        this.messageService.add({
          severity: "info",
          summary: "Notice",
          detail: "Removing review office...",
        });

        this.convergenceService.DeleteReviewOffice(item.id).subscribe(
          async () => {
            this.messageService.add({
              severity: "success",
              summary: "Removed",
              detail: "Removed successfully",
            });

            this.fetchingReviewOffices = true;
            const index = this.allReviewingOffices.indexOf(item);
            if (index > -1) {
              this.allReviewingOffices.splice(index, 1);
            }
            this.fetchingReviewOffices = false;
          },
          (error) => {
            console.log("Error: " + JSON.stringify(error));
            this.messageService.add({
              severity: "error",
              summary: "Notice",
              detail:
                "Unable to remove review office at the moment.. Reason: [" +
                error
                  ? error.error.message
                  : "request failed - permission" + "]",
            });
          }
        );
      },
    });
  }

  EditReviewalOffice(item: ConvergenceReviewOffice) {
    this.editingReviewOffice = true;
    this.reviewOfficeForm.patchValue({
      OfficeName: item.name,
    });
    this.reviewOfficeToEdit = item;
    this.addedReviewingOfficers = item.officers;
    this.addedReviewingOfficers.forEach((data) => {
      data.user = this.allUsers.find((appUser) => appUser.id == data.userId);
    });

    this.formWrapper.nativeElement.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "start",
    });
  }

  MoveReviewOfficeSequence(option: boolean, sequence: number) {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Moving Review Office Sequence...",
    });

    this.convergenceService
      .MoveReviewOfficeSequence({ sequence: sequence, goesUp: option })
      .subscribe(
        async () => {
          this.messageService.add({
            severity: "success",
            summary: "Removed",
            detail: "Reviewing office sequence updated successfully",
          });
          this.FetchAllReviewOffice();
        },
        (error) => {
          console.log("Error: " + JSON.stringify(error));
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail:
              "Unable to move reviewing office sequence at the moment.. Reason: [" +
              error
                ? error.error.message
                : "request failed - permission" + "]",
          });
        }
      );
  }

  CreateApprovalOffice() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Creating Approval Office...",
    });

    const postData: CreateApprovalOfficeVM = {
      name: this.approvalOfficeForm.get("OfficeName").value,
      officersIds: this.addedApprovingOfficers.map((x) => x.userId),
    };

    this.convergenceService.CreateApprovalOffice(postData).subscribe(
      async () => {
        await this.messageService.add({
          severity: "success",
          summary: "Completed",
          detail: "Approving Office Created Successfully!",
        });

        this.approvalOfficeForm.reset();
        this.addedApprovingOfficers = [];
        this.FetchAllApprovalOffice();
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to create approving office at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  AddApprovalOfficerToList() {
    if (!this.ApprovingUser) {
      this.messageService.add({
        severity: "error",
        summary: "Failed",
        detail: "You must select the officer to add",
      });
      return;
    }

    let alreadyAdded = false;
    this.addedApprovingOfficers.forEach((item) => {
      if (item.userId == this.ApprovingUser.id) {
        alreadyAdded = true;
      }
    });

    if (alreadyAdded) {
      this.messageService.add({
        severity: "error",
        summary: "Failed",
        detail: "Officer with the same info has already been added to list.",
      });
      return;
    }

    this.addedApprovingOfficers.push({
      userId: this.ApprovingUser.id,
      user: this.ApprovingUser,
    });

    this.theApprovalUser = null;
    this.messageService.add({
      severity: "success",
      summary: "Added",
      detail: "Approving Officer added successfully",
    });
  }

  RemoveApprovalOfficer(item: ConvergenceApprovalOfficer) {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Removing Approving Officer...",
    });

    const index = this.addedApprovingOfficers.indexOf(item);
    if (index > -1) {
      this.addedApprovingOfficers.splice(index, 1);
    }

    this.messageService.add({
      severity: "success",
      summary: "Removed",
      detail: "Approving Officer Removed successfully",
    });
  }

  CloseEditingApprovalOffice() {
    this.editingApprovalOffice = false;
    this.approvalOfficeForm.reset();
    this.addedApprovingOfficers = [];
    this.approvingOfficeToEdit = null;
  }

  UpdateApprovalOffice() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Updating Approval Office...",
    });

    let addedApproveOfficerIds: number[] = [];
    this.addedApprovingOfficers.forEach((officer) => {
      addedApproveOfficerIds.push(officer.userId);
    });

    const id = this.approvingOfficeToEdit.id;
    const postData: UpdateApprovalOfficeVM = {
      officeName: this.approvalOfficeForm.get("OfficeName").value,
      officersIds: addedApproveOfficerIds,
    };

    this.convergenceService.UpdateApprovalOffice(id, postData).subscribe(
      async () => {
        await this.messageService.add({
          severity: "success",
          summary: "Completed",
          detail: "Approval Office Updated Successfully.",
        });

        this.addedApprovingOfficers = [];
        this.FetchAllApprovalOffice();
        this.editingApprovalOffice = false;
        this.approvalOfficeForm.reset();
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to update approving office at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  DeleteApprovalOffice(item: ConvergenceApprovalOffice) {
    this.confirmationService.confirm({
      message: "Are you sure you want to remove approving office?",
      accept: () => {
        this.messageService.add({
          severity: "info",
          summary: "Notice",
          detail: "Removing approving office...",
        });

        this.convergenceService.DeleteApprovalOffice(item.id).subscribe(
          async () => {
            await this.messageService.add({
              severity: "success",
              summary: "Removed",
              detail: "Removed successfully",
            });

            this.fetchingApprovalOffices = true;
            const index = this.allApprovingOffices.indexOf(item);
            if (index > -1) {
              this.allApprovingOffices.splice(index, 1);
            }
            this.fetchingApprovalOffices = false;
          },
          (error) => {
            console.log("Error: " + JSON.stringify(error));
            this.messageService.add({
              severity: "error",
              summary: "Notice",
              detail:
                "Unable to remove approving office at the moment.. Reason: [" +
                error
                  ? error.error.message
                  : "request failed - permission" + "]",
            });
          }
        );
      },
    });
  }

  EditApprovalOffice(item: ConvergenceApprovalOffice) {
    this.editingApprovalOffice = true;
    this.approvalOfficeForm.patchValue({
      OfficeName: item.name,
    });
    this.approvingOfficeToEdit = item;
    this.addedApprovingOfficers = item.officers;
    this.addedApprovingOfficers.forEach((data) => {
      data.user = this.allUsers.find((appUser) => appUser.id == data.userId);
    });

    this.formWrapper.nativeElement.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "start",
    });
  }

  MoveApprovalOfficeSequence(option: boolean, sequence: number) {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Moving Approval Office Sequence...",
    });

    this.convergenceService
      .MoveApprovalOfficeSequence({ sequence: sequence, goesUp: option })
      .subscribe(
        async () => {
          await this.messageService.add({
            severity: "success",
            summary: "Removed",
            detail: "Approving office sequence updated successfully",
          });
          this.FetchAllApprovalOffice();
        },
        (error) => {
          console.log("Error: " + JSON.stringify(error));
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail:
              "Unable to move approving office sequence at the moment.. Reason: [" +
              error
                ? error.error.message
                : "request failed - permission" + "]",
          });
        }
      );
  }
}
