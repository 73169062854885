import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { MessageService, ConfirmationService } from "primeng/api";
import { BreadcrumbService } from "src/app/breadcrumb.service";
import { DeployedGuard } from "src/app/interfaces/employment";
import {
  BankAccount,
  CreateBankAccountVM,
} from "src/app/interfaces/finance-expense";
import { Bank, CustomerDivision, User } from "src/app/interfaces/home";
import { BankAccountTypeEnum } from "src/app/interfaces/supplier";
import { BankAccountService } from "src/app/services/bank-account.service";
import { BankService } from "src/app/services/bank.service";
import { CustomerDivisionService } from "src/app/services/customer-division.service";
import { GmaEmploymentService } from "src/app/services/gma-employment.service";
import { UserService } from "src/app/services/user.service";

@Component({
  selector: "app-bank-account-setup",
  templateUrl: "./bank-account-setup.component.html",
  styleUrls: ["./bank-account-setup.component.scss"],
})
export class BankAccountSetupComponent implements OnInit {
  @ViewChild("formWrapper") public formWrapper: ElementRef;
  fetchingStaffAccounts: boolean;
  fetchingClientAccounts: boolean;
  fetchingOperativeAccounts: boolean;
  colsStaff: any[];
  colsClient: any[];
  colsOP: any[];
  showStaffDetails: boolean;
  showClientDetails: boolean;
  showOperativeDetails: boolean;
  staffInView: User;
  clientInView: CustomerDivision;
  operativeInView: DeployedGuard;
  showAddStaffBank: boolean;
  showAddClientBank: boolean;
  showAddOperativeBank: boolean;
  allBanks: Bank[];
  theStaffBank: Bank;
  theClientBank: Bank;
  theOperativeBank: Bank;
  staffAccountName: string = "";
  staffAccountNumber: string = "";
  clientAccountName: string = "";
  clientAccountNumber: string = "";
  operativeAccountName: string = "";
  operativeAccountNumber: string = "";
  allBankAccountTypes: {
    key: number;
    value: string;
  }[] = [];
  theStaffBankAccountType: {
    key: number;
    value: string;
  };
  theClientBankAccountType: {
    key: number;
    value: string;
  };
  theOperativeBankAccountType: {
    key: number;
    value: string;
  };
  isStaffPrimaryBankAccount: boolean;
  isClientPrimaryBankAccount: boolean;
  isOperativePrimaryBankAccount: boolean;
  carouselResponsiveOptions: any[] = [
    {
      breakpoint: "1024px",
      numVisible: 3,
      numScroll: 3,
    },
    {
      breakpoint: "768px",
      numVisible: 2,
      numScroll: 2,
    },
    {
      breakpoint: "560px",
      numVisible: 1,
      numScroll: 1,
    },
  ];
  selectedStaffBankAccounts: BankAccount[];
  selectedClientBankAccounts: BankAccount[];
  selectedOperativeBankAccounts: BankAccount[];
  allUsers: User[];
  selectedUsers: User[];
  allClients: CustomerDivision[];
  selectedClients: CustomerDivision[];
  allOperatives: DeployedGuard[];
  selectedOperatives: DeployedGuard[];
  allBankAccounts: BankAccount[] = [];

  constructor(
    public bankService: BankService,
    public clientService: CustomerDivisionService,
    public employmentService: GmaEmploymentService,
    public bankAccountService: BankAccountService,
    public userService: UserService,
    public messageService: MessageService,
    private breadcrumbService: BreadcrumbService,
    public confirmationService: ConfirmationService
  ) {}

  ngOnInit(): void {
    this.breadcrumbService.setItems([
      {
        label: "Setup",
        routerLink: ["/home/setup"],
      },
      {
        label: "Bank Account",
        routerLink: ["/home/setup/bank-account"],
      },
    ]);

    this.colsStaff = [
      { field: "lastName", header: "Last Name" },
      { field: "firstName", header: "First Name" },
      { field: "otherName", header: "Other Name" },
      { field: "email", header: "Email Address" },
      { field: "mobile", header: "Mobile" },
    ];

    this.colsClient = [
      { field: "divisionName", header: "Division Name" },
      { field: "phoneNumber", header: "Phone Number" },
      { field: "email", header: "Email Address" },
    ];

    this.colsOP = [
      {
        field: "jobApplication.personalInformation.lastname",
        header: "Last Name",
      },
      {
        field: "jobApplication.personalInformation.firstname",
        header: "First Name",
      },
      {
        field: "jobApplication.personalInformation.othername",
        header: "Other Name",
      },
      {
        field: "jobApplication.personalInformation.email",
        header: "Email Address",
      },
      { field: "jobApplication.personalInformation.mobile", header: "Mobile" },
    ];

    this.allBankAccountTypes = [
      {
        key: BankAccountTypeEnum.SAVINGS,
        value: "SAVINGS",
      },
      {
        key: BankAccountTypeEnum.CURRENT,
        value: "CURRENT",
      },
      {
        key: BankAccountTypeEnum.LOAN,
        value: "LOAN",
      },
    ];

    this.FetchAllBanks();
    this.FetchAllUsers();
    this.FetchAllClients();
    this.FetchAllOperatives();
  }

  async FetchAllBanks() {
    this.bankService.allBankData().subscribe(
      async (data) => {
        if (data.responseCode != "00") {
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail: data.responseMsg,
          });
          return;
        }

        this.allBanks = data.responseData;
      },
      (error) => {
        this.messageService.add({
          severity: "error",
          summary: "Failed",
          detail: error ?? "Some errors occurred",
        });
      }
    );
  }

  async FetchAllClients() {
    this.fetchingClientAccounts = true;
    this.clientService.allCustomerDivision().subscribe(
      async (data) => {
        this.allClients = data.responseData;
        this.fetchingClientAccounts = false;
      },
      (error) => {
        console.log(error);
        this.messageService.add({
          severity: "error",
          summary: "Failed",
          detail: error ?? "Some errors occurred",
        });
        this.fetchingClientAccounts = false;
      }
    );
  }

  async FetchAllUsers() {
    this.fetchingStaffAccounts = true;
    this.userService.allUser().subscribe(
      async (res) => {
        var data = res.responseData;
        this.allUsers = [];
        data.forEach((user) => {
          user.fullName = user.lastName + " " + user.firstName;
          this.allUsers.push(user);
        });
        this.FetchAllBankAccounts();
      },
      (err) => {
        console.log(err);
        this.messageService.add({
          severity: "error",
          summary: "Failed",
          detail: err ?? "Some errors occurred",
        });
        this.fetchingStaffAccounts = false;
      }
    );
  }

  async FetchAllOperatives() {
    this.fetchingOperativeAccounts = true;
    this.employmentService.GetAllOperativesDataOnly().subscribe(
      async (data) => {
        this.allOperatives = data;
        // data.forEach((x) => {
        //   x.guardName =
        //     x.jobApplication.personalInformation.lastname +
        //     " " +
        //     x.jobApplication.personalInformation.firstname +
        //     " " +
        //     x.jobApplication.personalInformation.othername;
        //   this.allOperatives.push(x);
        // });
        this.fetchingOperativeAccounts = false;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all operatives at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
        this.fetchingOperativeAccounts = false;
      }
    );
  }

  FetchAllBankAccounts() {
    this.fetchingStaffAccounts = true;
    this.fetchingClientAccounts = true;
    this.fetchingOperativeAccounts = true;
    this.bankAccountService.GetAllBankAccounts().subscribe(
      async (data) => {
        if (data.responseCode != "00") {
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail: data.responseMsg,
          });
          this.fetchingStaffAccounts = false;
          this.fetchingClientAccounts = false;
          this.fetchingOperativeAccounts = false;
          return;
        }

        this.allBankAccounts = data.responseData;
        this.fetchingStaffAccounts = false;
        this.fetchingClientAccounts = false;
        this.fetchingOperativeAccounts = false;
      },
      (error) => {
        this.messageService.add({
          severity: "error",
          summary: "Failed",
          detail: error ?? "Some errors occurred",
        });
        this.fetchingStaffAccounts = false;
        this.fetchingClientAccounts = false;
        this.fetchingOperativeAccounts = false;
      }
    );
  }

  ShowStaffBankInfo(item: User) {
    this.selectedStaffBankAccounts = this.allBankAccounts.filter(
      (x) => x.userProfileId == item.id
    );
    this.staffInView = item;
    this.showStaffDetails = true;
    this.ResetAddNewStaffBank();

    this.formWrapper.nativeElement.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "end",
    });
  }

  ShowAddNewStaffBank() {
    this.ResetAddNewStaffBank();
    this.showAddStaffBank = true;
  }

  AddNewStaffBankAccount() {
    this.confirmationService.confirm({
      message:
        "You are about to add a new bank account for selected staff. Do you still wish to proceed?",
      accept: () => {
        this.messageService.add({
          severity: "info",
          summary: "Notice",
          detail: "Adding New Bank Account...",
        });

        const postData: CreateBankAccountVM = {
          accountOwnerType: 1,
          accountOwnerId: this.staffInView.id,
          bankId: this.theStaffBank.id,
          accountName: this.staffAccountName,
          accountType: this.theStaffBankAccountType.key,
          accountNumber: this.staffAccountNumber,
          isPrimaryAccount: this.isStaffPrimaryBankAccount,
          bankCode: this.theStaffBank.bankCode,
        };

        this.bankAccountService.CreateBankAccount(postData).subscribe(
          async (data) => {
            if (data.responseCode != "00") {
              this.messageService.add({
                severity: "error",
                summary: "Notice",
                detail: data.responseMsg,
              });
              this.fetchingStaffAccounts = false;
              return;
            }

            this.messageService.add({
              severity: "success",
              summary: "Completed",
              detail: "Bank Account Added Successfully!",
            });

            this.staffInView = null;
            this.ResetAddNewStaffBank();
            this.showStaffDetails = false;
            this.FetchAllBankAccounts();
          },
          (error) => {
            console.log("Error: " + JSON.stringify(error));
            this.messageService.add({
              severity: "error",
              summary: "Notice",
              detail:
                "Unable to add new bank account at the moment.. Reason: [" +
                error
                  ? error.error.message
                  : "request failed - permission" + "]",
            });
          }
        );
      },
    });
  }

  GetBankName(bankId: number): string {
    let bank = this.allBanks.find((x) => x.id == bankId);
    if (bank) return bank.name;

    return "N/a";
  }

  GetAccountType(accountTypeId): string {
    let acctType = this.allBankAccountTypes.find((x) => x.key == accountTypeId);
    if (acctType) return acctType.value;

    return "N/A";
  }

  ResetAddNewStaffBank() {
    this.theStaffBank = null;
    this.staffAccountName = null;
    this.staffAccountNumber = null;
    this.theStaffBankAccountType = null;
    this.isStaffPrimaryBankAccount = false;
    this.showAddStaffBank = false;
  }

  GetBankLogoUrl(bankId: number): string | null {
    let bank = this.allBanks.find((x) => x.id == bankId);
    if (bank) return bank.logoUrl;

    return null;
  }

  StaffHasBankAccounts(staffId: number): boolean {
    return this.allBankAccounts.find((x) => x.userProfileId == staffId) != null;
  }

  ClientHasBankAccounts(clientId: number): boolean {
    return (
      this.allBankAccounts.find((x) => x.customerDivisionId == clientId) != null
    );
  }

  ShowClientBankInfo(item: CustomerDivision) {
    this.selectedClientBankAccounts = this.allBankAccounts.filter(
      (x) => x.customerDivisionId == item.id
    );
    this.clientInView = item;
    this.showClientDetails = true;
    this.ResetAddNewClientBank();

    this.formWrapper.nativeElement.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "end",
    });
  }

  ResetAddNewClientBank() {
    this.theClientBank = null;
    this.clientAccountName = null;
    this.clientAccountNumber = null;
    this.theClientBankAccountType = null;
    this.isClientPrimaryBankAccount = false;
    this.showAddClientBank = false;
  }

  ShowAddNewClientBank() {
    this.ResetAddNewClientBank();
    this.showAddClientBank = true;
  }

  AddNewClientBankAccount() {
    this.confirmationService.confirm({
      message:
        "You are about to add a new bank account for selected client. Do you still wish to proceed?",
      accept: () => {
        this.messageService.add({
          severity: "info",
          summary: "Notice",
          detail: "Adding New Bank Account...",
        });

        const postData: CreateBankAccountVM = {
          accountOwnerType: 2,
          accountOwnerId: this.clientInView.id,
          bankId: this.theClientBank.id,
          accountName: this.clientAccountName,
          accountType: this.theClientBankAccountType.key,
          accountNumber: this.clientAccountNumber,
          isPrimaryAccount: this.isClientPrimaryBankAccount,
          bankCode: this.theClientBank.bankCode,
        };

        this.bankAccountService.CreateBankAccount(postData).subscribe(
          async (data) => {
            if (data.responseCode != "00") {
              this.messageService.add({
                severity: "error",
                summary: "Notice",
                detail: data.responseMsg,
              });
              return;
            }

            this.messageService.add({
              severity: "success",
              summary: "Completed",
              detail: "Bank Account Added Successfully!",
            });

            this.clientInView = null;
            this.ResetAddNewClientBank();
            this.showClientDetails = false;
            this.FetchAllBankAccounts();
          },
          (error) => {
            console.log("Error: " + JSON.stringify(error));
            this.messageService.add({
              severity: "error",
              summary: "Notice",
              detail:
                "Unable to add new bank account at the moment.. Reason: [" +
                error
                  ? error.error.message
                  : "request failed - permission" + "]",
            });
          }
        );
      },
    });
  }

  OperativeHasBankAccounts(operativeId: number): boolean {
    return (
      this.allBankAccounts.find((x) => x.deployedGuardId == operativeId) != null
    );
  }

  ShowOperativeBankInfo(item: DeployedGuard) {
    this.selectedOperativeBankAccounts = this.allBankAccounts.filter(
      (x) => x.deployedGuardId == item.id
    );
    this.operativeInView = item;
    this.showOperativeDetails = true;
    this.ResetAddNewOperativeBank();

    this.formWrapper.nativeElement.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "end",
    });
  }

  ResetAddNewOperativeBank() {
    this.theOperativeBank = null;
    this.operativeAccountName = null;
    this.operativeAccountNumber = null;
    this.theOperativeBankAccountType = null;
    this.isOperativePrimaryBankAccount = false;
    this.showAddOperativeBank = false;
  }

  ShowAddNewOperativeBank() {
    this.ResetAddNewOperativeBank();
    this.showAddOperativeBank = true;
  }

  AddNewOperativeBankAccount() {
    this.confirmationService.confirm({
      message:
        "You are about to add a new bank account for selected operative. Do you still wish to proceed?",
      accept: () => {
        this.messageService.add({
          severity: "info",
          summary: "Notice",
          detail: "Adding New Bank Account...",
        });

        const postData: CreateBankAccountVM = {
          accountOwnerType: 3,
          accountOwnerId: this.operativeInView.id,
          bankId: this.theOperativeBank.id,
          accountName: this.operativeAccountName,
          accountType: this.theOperativeBankAccountType.key,
          accountNumber: this.operativeAccountNumber,
          isPrimaryAccount: this.isOperativePrimaryBankAccount,
          bankCode: this.theOperativeBank.bankCode,
        };

        this.bankAccountService.CreateBankAccount(postData).subscribe(
          async (data) => {
            if (data.responseCode != "00") {
              this.messageService.add({
                severity: "error",
                summary: "Notice",
                detail: data.responseMsg,
              });
              return;
            }

            this.messageService.add({
              severity: "success",
              summary: "Completed",
              detail: "Bank Account Added Successfully!",
            });

            this.operativeInView = null;
            this.ResetAddNewOperativeBank();
            this.showOperativeDetails = false;
            this.FetchAllBankAccounts();
          },
          (error) => {
            console.log("Error: " + JSON.stringify(error));
            this.messageService.add({
              severity: "error",
              summary: "Notice",
              detail:
                "Unable to add new bank account at the moment.. Reason: [" +
                error
                  ? error.error.message
                  : "request failed - permission" + "]",
            });
          }
        );
      },
    });
  }
}
