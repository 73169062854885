import { ConvergenceService } from "./../../../services/convergence.service";
import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { ConfirmationService, MessageService } from "primeng/api";
import { BreadcrumbService } from "src/app/breadcrumb.service";
import { StateService } from "src/app/services/state.service";

@Component({
  selector: "app-convergence-capture-event",
  templateUrl: "./convergence-capture-event.component.html",
  styleUrls: ["./convergence-capture-event.component.scss"],
})
export class ConvergenceCaptureEventComponent implements OnInit {
  @ViewChild("formWrapper") public formWrapper: ElementRef;

  constructor(
    private fb: FormBuilder,
    public convergenceService: ConvergenceService,
    public stateService: StateService,
    private breadcrumbService: BreadcrumbService,
    public confirmationService: ConfirmationService,
    public messageService: MessageService
  ) {}

  ngOnInit(): void {
    this.breadcrumbService.setItems([
      {
        label: "Convergence",
        routerLink: ["/home/convergence"],
      },
      {
        label: "Gma Org Structure",
        routerLink: ["/home/convergence/capture-event"],
      },
    ]);
  }
}
