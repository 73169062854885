import {
  CreateNotificationPolicyVM,
  CreatePolicyNotificationTimeVM,
} from "./../../../../interfaces/convergence";
import { ServicesService } from "src/app/services/services.service";
import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { ConfirmationService, MessageService } from "primeng/api";
import { BreadcrumbService } from "src/app/breadcrumb.service";
import {
  ConvergenceNotificationPolicy,
  ConvergenceNotificationTemplate,
} from "src/app/interfaces/convergence";
import { ConvergenceService } from "src/app/services/convergence.service";
import { Services, State } from "src/app/interfaces/home";
import { StateService } from "src/app/services/state.service";

@Component({
  selector: "app-incident-notification-policy",
  templateUrl: "./incident-notification-policy.component.html",
  styleUrls: ["./incident-notification-policy.component.scss"],
})
export class IncidentNotificationPolicyComponent implements OnInit {
  @ViewChild("formWrapper") public formWrapper: ElementRef;
  policyCaption: string;
  policyDescription: string;
  policyStartDate: any;
  policyEndDate: any;
  allNotificationTemplatesPick: {
    riskLevelId: number;
    riskLevel: string;
    notificationTemplatePicked?: ConvergenceNotificationTemplate;
  }[] = [
    {
      riskLevelId: 1,
      riskLevel: "Level 1 (Very Low)",
    },
    {
      riskLevelId: 2,
      riskLevel: "Level 2 (Low)",
    },
    {
      riskLevelId: 3,
      riskLevel: "Level 3 (Medium)",
    },
    {
      riskLevelId: 4,
      riskLevel: "Level 4 (High)",
    },
    {
      riskLevelId: 5,
      riskLevel: "Level 5 (Very Hight)",
    },
  ];
  allNotificationTemplates: ConvergenceNotificationTemplate[];
  allServices: Services[];
  selectedServices: Services[] = [];
  notificationTimeRadioButton: number = 0;
  notificationStartTime: Date;
  notificationEndTime: Date;
  policyStatus: boolean = false;
  notificationDayRadioButton: number = 0;
  allNotificationDays: { key: number; value: string }[] = [
    {
      key: 0,
      value: "Sunday",
    },
    {
      key: 1,
      value: "Monday",
    },
    {
      key: 2,
      value: "Tuesday",
    },
    {
      key: 3,
      value: "Wednesday",
    },
    {
      key: 4,
      value: "Thursday",
    },
    {
      key: 5,
      value: "Friday",
    },
    {
      key: 6,
      value: "Saturday",
    },
  ];
  selectedNotificationDays: { key: number; value: string }[] = [];
  allNotificationPolicies: ConvergenceNotificationPolicy[];
  selectedNotificationPolicies: ConvergenceNotificationPolicy[];
  notificationPolicyInView: ConvergenceNotificationPolicy;
  notificationPolicyCols: any[];
  openTemplateDialogue: boolean;
  templateRiskLevelCapt: string = "N/A";
  templatePreviewName: string = "N/A";
  loadingTemplate: boolean = false;
  templateInView: ConvergenceNotificationTemplate;
  allAddedNotificationTime: CreatePolicyNotificationTimeVM[] = [];
  notificationPolicyToEdit: ConvergenceNotificationPolicy;
  isEditing: boolean;
  triedEditTimeOnce: boolean;
  allStates: State[] = [];
  selectedStates: State[] = [];

  constructor(
    private fb: FormBuilder,
    public stateService: StateService,
    public serviceService: ServicesService,
    public convergenceService: ConvergenceService,
    private breadcrumbService: BreadcrumbService,
    public confirmationService: ConfirmationService,
    public messageService: MessageService
  ) {}

  ngOnInit(): void {
    this.breadcrumbService.setItems([
      {
        label: "Convergence",
      },
      {
        label: "Incident Management",
      },
      {
        label: "Notification Policy",
        routerLink: [
          "/home/convergence/incident-management/notification-policy",
        ],
      },
    ]);

    this.notificationPolicyCols = [
      { field: "name", header: "Policy Name" },
      { field: "description", header: "Policy Description" },
      { field: "createdAt", header: "Date Created" },
    ];

    this.FetchAllStates();
    this.FetchAllNotificationTemplates();
    this.GetAllServices();
    this.FetchAllPolicies();
  }

  FetchAllPolicies() {
    this.convergenceService.GetAllNotificationPolicies().subscribe(
      async (data) => {
        this.allNotificationPolicies = data;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all policies at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  FetchAllNotificationTemplates() {
    this.convergenceService.GetAllConvergenceNotificationTemplates().subscribe(
      async (data) => {
        this.allNotificationTemplates = data;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all templates at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  GetAllServices() {
    this.serviceService.allService().subscribe(
      async (data) => {
        this.allServices = data.responseData;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all services at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  async PreviewNotificationTemplate(riskLevel: number) {
    document.getElementById("previewCard").innerHTML = "";
    this.templateRiskLevelCapt = "Loading....";
    this.templateInView = null;
    await new Promise((resolve) => setTimeout(resolve, 3000)); // 3 secs
    let template = this.notificationPolicyInView.notificationTemplates.find(
      (x) => x.riskLevel == riskLevel
    );
    if (template) {
      this.templateInView = template.notificationTemplate;
      this.templateRiskLevelCapt =
        "Risk Level " +
        template.riskLevel +
        " (" +
        this.templateInView.name +
        ")";
      document.getElementById("previewCard").innerHTML =
        this.templateInView.templateBody;
    }
  }

  async ViewTemplate() {
    this.openTemplateDialogue = true;
    this.templatePreviewName = this.templateInView.name;
    this.loadingTemplate = true;
    await new Promise((resolve) => setTimeout(resolve, 5000)); // 5 secs
    this.loadingTemplate = false;
    document.getElementById("templatePopup").innerHTML =
      this.templateInView.templateBody;
  }

  async FetchAllStates() {
    this.stateService.GetStatesOnly().subscribe(
      async (data) => {
        if (data.responseCode != "00") {
          this.messageService.add({
            severity: "error",
            summary: data.responseMsg,
          });
          return;
        }

        this.allStates = data.responseData;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary:
            "Unable to fetch all states at the moment.. Reason: [" +
            error.error.message +
            "]",
        });
      }
    );
  }

  HideTemplateDialog() {
    this.openTemplateDialogue = false;
    this.templatePreviewName = "N/A";
    this.loadingTemplate = false;
  }

  GetPolicyStatus(item: ConvergenceNotificationPolicy): boolean {
    // Thu Oct 13 2022 21:34:32 GMT+0100 (West Africa Standard Time)
    let today = new Date();
    let today_day = today.toString().substring(8, 10);
    let today_month = this.GetShortMonthValue(today.toString().substring(4, 7));
    let today_year = today.toString().substring(11, 15);
    // console.log("Today " + today_day + "/" + today_month + "/" + today_year);

    // 2022-10-16T00:00:00
    let endDate_day = item.endDate.toString().substring(8, 10);
    let endDate_month = +item.endDate.toString().substring(5, 7);
    let endDate_year = item.endDate.toString().substring(0, 4);
    // console.log(
    //   "End Date " + endDate_day + "/" + endDate_month + "/" + endDate_year
    // );

    if (today_year > endDate_year) return false;
    if (today_month > endDate_month) return false;
    if (today_day > endDate_day) return false;

    return true;
  }

  GetShortMonthValue(month: string): number {
    switch (month.toLowerCase()) {
      case "jan":
        return 1;
      case "feb":
        return 2;
      case "mar":
        return 3;
      case "apr":
        return 4;
      case "may":
        return 5;
      case "jun":
        return 6;
      case "jul":
        return 7;
      case "aug":
        return 8;
      case "sep":
        return 9;
      case "oct":
        return 10;
      case "nov":
        return 11;
      case "dec":
        return 12;
      default:
        return 0;
    }
  }

  async PreviewPolicyTemplates(item: ConvergenceNotificationPolicy) {
    if (this.notificationPolicyInView) {
      this.notificationPolicyInView = null;
      this.templateInView = null;
      this.templateRiskLevelCapt = "Loading...";
      document.getElementById("previewCard").innerHTML = "";
      await new Promise((resolve) => setTimeout(resolve, 2000)); // 2 secs
    }
    this.notificationPolicyInView = item;
    let template = item.notificationTemplates.find((x) => x.riskLevel == 1);
    if (template) {
      this.templateInView = template.notificationTemplate;
      this.templateRiskLevelCapt =
        "Risk Level " +
        template.riskLevel +
        " (" +
        this.templateInView.name +
        ")";
      await new Promise((resolve) => setTimeout(resolve, 5000)); // 5 secs
      document.getElementById("previewCard").innerHTML =
        this.templateInView.templateBody;
    }
  }

  AddNotificationTime() {
    if (this.notificationStartTime > this.notificationEndTime) {
      this.messageService.add({
        severity: "error",
        summary: "Notice",
        detail:
          "Err: Notification End Time must be later than notification start time",
      });
      return;
    }

    let find = this.allAddedNotificationTime.find(
      (x) =>
        x.startTime == this.notificationStartTime &&
        x.endTime == this.notificationEndTime
    );
    if (find) {
      this.messageService.add({
        severity: "error",
        summary: "Notice",
        detail: "Notification Time already added",
      });
      return;
    }

    if (this.allAddedNotificationTime.length > 0) {
      let findLast =
        this.allAddedNotificationTime[this.allAddedNotificationTime.length - 1];
      let failValidation = false;
      if (this.isEditing && !this.triedEditTimeOnce) {
        // 15:21
        let start_hour = +this.notificationStartTime.toString().substring(0, 2);
        let start_minutes = +this.notificationStartTime
          .toString()
          .substring(3, 5);
        // console.log("Start - M " + start_hour + "-" + start_minutes);

        // 2022-10-13T14:22:00
        let end_hour = +findLast.endTime.toString().substring(11, 13);
        let end_minutes = +findLast.endTime.toString().substring(14, 16);
        // console.log("Last End M -  " + end_hour + "-" + end_minutes);

        if (end_hour > start_hour) failValidation = true;
        else if (end_hour == start_hour)
          if (end_minutes > start_minutes) failValidation = true;

        if (!failValidation) this.triedEditTimeOnce = true;
      } else if (findLast.endTime > this.notificationStartTime)
        failValidation = true;

      if (failValidation) {
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Notification Start Time must be later than the last notification end time",
        });
        return;
      }
    }

    this.allAddedNotificationTime.push({
      startTime: this.notificationStartTime,
      endTime: this.notificationEndTime,
    });

    this.notificationStartTime = null;
    this.notificationEndTime = null;
  }

  RemoveNotificationTime(item: CreatePolicyNotificationTimeVM) {
    const index = this.allAddedNotificationTime.indexOf(item);
    if (index > -1) {
      this.allAddedNotificationTime.splice(index, 1);
    }
  }

  ResetCreatePolicyForm() {
    this.policyCaption = null;
    this.policyDescription = null;
    this.policyStartDate = null;
    this.policyEndDate = null;
    this.allNotificationTemplatesPick = [
      {
        riskLevelId: 1,
        riskLevel: "Level 1 (Very Low)",
      },
      {
        riskLevelId: 2,
        riskLevel: "Level 2 (Low)",
      },
      {
        riskLevelId: 3,
        riskLevel: "Level 3 (Medium)",
      },
      {
        riskLevelId: 4,
        riskLevel: "Level 4 (High)",
      },
      {
        riskLevelId: 5,
        riskLevel: "Level 5 (Very Hight)",
      },
    ];
    this.selectedServices = [];
    this.notificationTimeRadioButton = 0;
    this.allAddedNotificationTime = [];
    this.notificationDayRadioButton = 0;
    this.selectedNotificationDays = [];
    this.selectedStates = [];
    this.policyStatus = false;
  }

  SavePolicy() {
    if (this.isEditing) return this.UpdatePolicy();

    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Saving Notification Policy...",
    });

    if ((this.policyStartDate as Date) > (this.policyEndDate as Date)) {
      this.messageService.add({
        severity: "error",
        summary: "Notice",
        detail: "Policy Start Date must not be greater than policy end date",
      });
      return;
    }

    const postData: CreateNotificationPolicyVM = {
      name: this.policyCaption,
      description: this.policyDescription,
      startDate: this.policyStartDate as Date,
      endDate: this.policyEndDate as Date,
      notificationTemplates: [],
      serviceIds: this.selectedServices.map((x) => x.id),
      isAnyTimeNotification: this.notificationTimeRadioButton == 1,
      notificationTimes: [],
      isAnyDayNotification: this.notificationDayRadioButton == 1,
      notificationDays: [],
      isActivated: this.policyStatus,
      policyStates: this.selectedStates.map((x) => x.id),
    };

    this.allNotificationTemplatesPick.forEach((x) => {
      if (x.notificationTemplatePicked != null) {
        postData.notificationTemplates.push({
          riskLevel: x.riskLevelId,
          notificationTemplateId: x.notificationTemplatePicked.id,
        });
      }
    });

    if (!postData.isAnyTimeNotification) {
      if (this.allAddedNotificationTime.length == 0) {
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail: "At least One Notification Time must be added.",
        });
        return;
      }

      postData.notificationTimes = this.allAddedNotificationTime;
    }

    if (!postData.isAnyDayNotification) {
      if (this.selectedNotificationDays.length == 0) {
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail: "At least One Notification Day must be added.",
        });
        return;
      }

      postData.notificationDays = this.selectedNotificationDays.map(
        (x) => x.key
      );
    }

    this.convergenceService.CreateNotificationPolicy(postData).subscribe(
      async () => {
        this.messageService.add({
          severity: "success",
          summary: "Completed",
          detail: "Notifiation Policy Created Successfully!",
        });

        this.ResetCreatePolicyForm();
        this.FetchAllPolicies();
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to create notification policy at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  DeletePolicy(item: ConvergenceNotificationPolicy) {
    this.confirmationService.confirm({
      message: "Are you sure you want to remove " + item.name,
      accept: () => {
        this.messageService.add({
          severity: "info",
          summary: "Notice",
          detail: "Removing Policy...",
        });

        this.convergenceService.DeleteNotificationPolicy(item.id).subscribe(
          async () => {
            this.messageService.add({
              severity: "success",
              summary: "Removed",
              detail: "Removed successfully",
            });

            const index = this.allNotificationPolicies.indexOf(item);
            if (index > -1) {
              this.allNotificationPolicies.splice(index, 1);
            }
            this.notificationPolicyInView = null;
            this.templateInView = null;
            this.templateRiskLevelCapt = "Loading...";
            document.getElementById("previewCard").innerHTML = "";
            this.allNotificationPolicies = [];
            this.CancelEditingPolicy();
            this.FetchAllPolicies();
          },
          (error) => {
            console.log("Error: " + JSON.stringify(error));
            this.messageService.add({
              severity: "error",
              summary: "Notice",
              detail:
                "Unable to remove policy at the moment.. Reason: [" + error
                  ? error.error.message
                  : "request failed - permission" + "]",
            });
          }
        );
      },
    });
  }

  EditPolicy(item: ConvergenceNotificationPolicy) {
    this.isEditing = true;
    this.notificationPolicyInView = null;
    this.notificationPolicyToEdit = item;
    this.policyCaption = item.name;
    this.policyDescription = item.description;
    this.policyStartDate = item.startDate.toString().split("T")[0];
    this.policyEndDate = item.endDate.toString().split("T")[0];
    this.allNotificationTemplatesPick.forEach((tempPick) => {
      let tempProf = item.notificationTemplates.find(
        (x) => x.riskLevel == tempPick.riskLevelId
      );
      if (tempProf)
        tempPick.notificationTemplatePicked =
          this.allNotificationTemplates.find(
            (x) => x.id == tempProf.notificationTemplateId
          );
    });
    this.selectedServices = this.allServices.filter(
      (x) => item.policyServices.find((y) => y.serviceId == x.id) != null
    );
    this.selectedStates = this.allStates.filter(
      (x) => item.policyStates.find((y) => y.stateId == x.id) != null
    );
    this.notificationTimeRadioButton = item.triggerPolicyAnyTime ? 1 : 2;
    this.notificationDayRadioButton = item.triggerPolicyAnyDay ? 1 : 2;
    item.policyTimes.forEach((polTime) => {
      this.allAddedNotificationTime.push({
        startTime: polTime.startTime,
        endTime: polTime.endTime,
      });
    });
    let daysPol = item.policyDays.split(";");
    this.selectedNotificationDays = this.allNotificationDays.filter(
      (x) => daysPol.find((y) => +y == x.key) != null
    );
    this.policyStatus = item.isActivated;
  }

  UpdatePolicy() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Updating...",
    });

    if ((this.policyStartDate as Date) > (this.policyEndDate as Date)) {
      this.messageService.add({
        severity: "error",
        summary: "Notice",
        detail: "Policy Start Date must not be greater than policy end date",
      });
      return;
    }

    const id = this.notificationPolicyToEdit.id;
    const postData: CreateNotificationPolicyVM = {
      name: this.policyCaption,
      description: this.policyDescription,
      startDate: this.policyStartDate as Date,
      endDate: this.policyEndDate as Date,
      notificationTemplates: [],
      serviceIds: this.selectedServices.map((x) => x.id),
      isAnyTimeNotification: this.notificationTimeRadioButton == 1,
      notificationTimes: [],
      isAnyDayNotification: this.notificationDayRadioButton == 1,
      notificationDays: [],
      isActivated: this.policyStatus,
      policyStates: this.selectedStates.map((x) => x.id),
    };

    this.allNotificationTemplatesPick.forEach((x) => {
      if (x.notificationTemplatePicked != null) {
        postData.notificationTemplates.push({
          riskLevel: x.riskLevelId,
          notificationTemplateId: x.notificationTemplatePicked.id,
        });
      }
    });

    if (!postData.isAnyTimeNotification) {
      if (this.allAddedNotificationTime.length == 0) {
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail: "At least One Notification Time must be added.",
        });
        return;
      }

      postData.notificationTimes = this.allAddedNotificationTime;
    }

    if (!postData.isAnyDayNotification) {
      if (this.selectedNotificationDays.length == 0) {
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail: "At least One Notification Day must be added.",
        });
        return;
      }

      postData.notificationDays = this.selectedNotificationDays.map(
        (x) => x.key
      );
    }

    this.convergenceService.UpdateNotificationPolicy(id, postData).subscribe(
      async () => {
        this.messageService.add({
          severity: "success",
          summary: "Completed",
          detail: "Notifiation Policy Updated Successfully!",
        });

        this.CancelEditingPolicy();
        this.FetchAllPolicies();
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to update notification policy at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  CancelEditingPolicy() {
    this.notificationPolicyToEdit = null;
    this.isEditing = false;
    this.triedEditTimeOnce = false;
    this.ResetCreatePolicyForm();
  }
}
