import {Component, OnDestroy, OnInit} from '@angular/core';
import {BreadcrumbService} from '../../../../breadcrumb.service';
import {FormBuilder} from '@angular/forms';
import {ServiceAssignmentService} from '../../../../services/armada/service-assignment.service';
import {MessageService} from 'primeng/api';
import {CommanderUserProfile, ServiceAssignment} from '../../../../interfaces/armada';
import {CommanderRegistrationService} from '../../../../services/armada/commander-registration.service';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';

@Component({
  selector: 'app-completed-journey',
  templateUrl: './completed-journey.component.html',
  styleUrls: ['./completed-journey.component.scss']
})
export class CompletedJourneyComponent implements OnInit, OnDestroy {
  public serviceAssignments: ServiceAssignment[];
  public loading: boolean;
  public commanderUserProfiles: CommanderUserProfile[];
  public loadingCommanders: boolean;
  private unsubscriber$ = new Subject<void>();
  public selectedCommander: CommanderUserProfile;
  public selectedAssignment: ServiceAssignment;
  loadingCommanderAssignment: boolean;
  showAssignment: boolean;
  constructor(
      private breadcrumbService: BreadcrumbService,
      private formBuilder: FormBuilder,
      private serviceAssignmentServices: ServiceAssignmentService,
      private messageService: MessageService,
      private commanderUserProfileService: CommanderRegistrationService,
  ) {
    this.breadcrumbService.setItems([
      { label: 'Secured Mobility Administration' },
      { label: 'Commander\'s Journeys' }
    ])
  }

  ngOnInit(): void {
    this.fetchCommanders();
  }
  private fetchCommanders() {
    this.commanderUserProfileService.allCommanderUserProfile().pipe(takeUntil(this.unsubscriber$))
        .subscribe( (res: CommanderUserProfile[]) => {
          this.commanderUserProfiles = res;
          this.commanderUserProfiles.forEach( xd => xd.fullName = `${xd.firstName} ${xd.lastName}`)
          this.loadingCommanders = false
        }, error => {
          this.connectionError();
          this.loadingCommanders = false;
        })
  }
  public fetchCommanderJourneys(): void{
    if(this.selectedCommander) {
      this.fetchCommanderJourneyById(this.selectedCommander.id);
    }
  }
  public fetchCommanderJourneyById(commanderId: number) {
    this.loadingCommanderAssignment =true;
    this.clearCommanderAssignments();
    this.serviceAssignmentServices.getMyCompletedJourneysByCommanderId(commanderId)
        .pipe(takeUntil(this.unsubscriber$))
        .subscribe( (result: ServiceAssignment[]) => {
          this.loadingCommanderAssignment = false;
          this.serviceAssignments = result;
          this.serviceAssignments.forEach( xdS => xdS.tempName = `${xdS.contractService?.service?.name}`)
        }, error => {
          this.connectionError();
          this.loadingCommanderAssignment = false;
        })
  }
  private clearCommanderAssignments() {
    this.serviceAssignments = null;
    this.selectedAssignment = null;
  }
  private connectionError() {
    this.messageService.add({severity:'error', summary: 'Failed',
      detail:'Connection Error, Please try again'});
  }
  ngOnDestroy(): void {
    this.unsubscriber$.complete();
    this.unsubscriber$.unsubscribe();
  }

  loadAssignment() {
    if(this.selectedAssignment) {
      this.showAssignmentDetails();
    }
  }

  private showAssignmentDetails() {
    this.loading = true;
    this.showAssignment = true;
    setTimeout(
        () => {
          this.loading =false;
        }, 200
    )
  }
}
