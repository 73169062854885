import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { ConfirmationService, MessageService } from "primeng/api";
import { BreadcrumbService } from "src/app/breadcrumb.service";
import { CommonResponse } from "src/app/interfaces/home";
import { Make, MakeDTO, Platform } from "src/app/interfaces/make";
import { MakeService } from "src/app/services/make.service";

@Component({
  selector: "app-make-settings",
  templateUrl: "./make-settings.component.html",
  styleUrls: ["./make-settings.component.scss"],
})
export class MakeSettingsComponent implements OnInit {
  @ViewChild("formWrapper") public formWrapper: ElementRef;
  fetchingMake = true;
  makeForm: FormGroup;
  makes: Make[];
  platform: Platform[];
  selectedPlatform: Platform;
  selectedMake: Make;
  editingMake = false;
  public makeCols: any[];
  public exportMakeColumns: any[];
  private edMake: Make;
  constructor(
    private formBuilder: FormBuilder,
    private makeService: MakeService,
    public messageService: MessageService,
    public confirmationService: ConfirmationService,
    private breadcrumbService: BreadcrumbService
  ) {}

  ngOnInit(): void {
    this.breadcrumbService.setItems([
      { label: "Supplier", routerLink: ["home/supplier"] },
      { label: "Make Settings", routerLink: ["home/supplier/settings/make"] },
    ]);
    this.fetchMake();
    this.makeForm = this.formBuilder.group({
      description: ["", Validators.required],
      caption: ["", [Validators.required]],
      platformId: [null, [Validators.required]],
    });
    this.makeCols = [
      { field: "caption", header: "Caption" },
      { field: "description", header: "Description" },
      { field: "platformId", header: "PlatformId" },
    ];
    this.exportMakeColumns = this.makeCols.map((col) => ({
      title: col.header,
      dataKey: col.field,
    }));
    this.fetchAllPlatforms();
  }

  fetchMake() {
    this.makeService.getAll().subscribe(
      async (make: CommonResponse) => {
        this.makes = make.responseData;
        console.log(this.makes);
        this.fetchingMake = false;
      },
      (error) => {
        this.fetchingMake = false;
        this.connectionError();
      }
    );
  }

  fetchAllPlatforms() {
    this.makeService.getAllPlatforms().subscribe(
      async (res: CommonResponse) => {
        this.platform = res.responseData;
        //this.fetchingMake = false;
      },
      (error) => {
        //this.fetchingMake = false;
        this.connectionError();
      }
    );
  }

  private connectionError() {
    this.messageService.add({
      severity: "error",
      summary: "Failed",
      detail: "Connection Error, Please try again",
    });
  }

  closeEditing() {
    this.editingMake = false;
    this.makeForm.reset();
    this.edMake = null;
  }

  createMake() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Creating New Make",
    });
    const postData: MakeDTO = {
      Description: this.makeForm.get("description").value,
      Caption: this.makeForm.get("caption").value,
      PlatformId: this.selectedPlatform.id,
      //PlatformId: this.makeForm.get("platformId").value,
    };
    this.makeService.create(postData).subscribe(
      async (res) => {
        if (res.responseCode != "00") {
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail: "Unable to create new make at the moment",
          });
        }
        await this.messageService.add({
          severity: "success",
          summary: "Completed",
          detail: "New Make Created",
        });
        await this.fetchMake();
        await this.makeForm.reset();
      },
      (error) => {
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail: "Unable to create new make at the moment",
        });
      }
    );
  }

  editMake(make: Make) {
    this.editingMake = true;
    this.makeForm.addControl(
      "Id",
      new FormControl({ value: "", disabled: true }, Validators.required)
    );
    this.edMake = make;
    this.makeForm.patchValue({
      caption: make.caption,
      description: make.description,
      platformId: make.platformId,
      Id: make.id,
    });
    this.formWrapper.nativeElement.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "start",
    });

    this.selectedPlatform = this.platform.find(
      (Type) => Type.id === make.platformId
    );
  }

  deleteMake(make: Make) {
    this.confirmationService.confirm({
      message: "Are you sure you want to drop " + make.caption,
      accept: () => {
        this._deleteMake(make);
      },
    });
  }

  _deleteMake(make: Make) {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Dropping Make record",
    });
    this.makeService.delete(parseInt(make.id)).subscribe(
      async (result: CommonResponse) => {
        if (result.responseCode != "00") {
          this.connectionError();
        }
        await this.messageService.add({
          severity: "success",
          summary: "Dropped",
          detail: "Make record dropped",
        });
        await this.fetchMake();
      },
      (error) => {
        this.connectionError();
      }
    );
  }

  updateMake() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Updating Make details",
    });
    const id = this.edMake.id;
    const postData: MakeDTO = {
      Caption: this.makeForm.get("caption").value,
      Description: this.makeForm.get("description").value,
      PlatformId: this.selectedPlatform.id,
    };
    this.makeService.update(postData, parseInt(id)).subscribe(
      async (result: CommonResponse) => {
        if (result.responseCode != "00") {
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail: "Unable to update make at the moment",
          });
        }
        this.messageService.add({
          severity: "success",
          summary: "Notice",
          detail: "Make details Updated",
        });
        await this.fetchMake();
        await this.closeEditing();
      },
      (error) => {
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail: "Unable to create new make at the moment",
        });
      }
    );
  }
}
