import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import firebase from "firebase";
import { AngularFireAuth } from "@angular/fire/auth";
import { UserService } from "./user.service";
import { MessageService } from "primeng/api";
import { HttpErrorResponse } from "@angular/common/http";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { AngularFireStorage } from "@angular/fire/storage";
import { Idle } from "@ng-idle/core";

@Injectable({
  providedIn: "root",
})
export class FireBaseAuthService {
  userEmail: string;

  private logginInSubject = new BehaviorSubject<boolean>(false);
  loggingIn$ = this.logginInSubject.asObservable();
  authUserRoleClaims: any;

  constructor(
    public afAuth: AngularFireAuth,
    public router: Router,
    public userService: UserService,
    public messageService: MessageService,
    public idle: Idle,
    private fireStorage: AngularFireStorage
  ) {}

  async login(email: string, password: string) {
    await this.afAuth.signInWithEmailAndPassword(email, password);
  }

  async logout() {
    this.logginInSubject.next(false);
    await this.afAuth.signOut();
    this.removeAllLocalStorageItems();
    this.router.navigate(["/login"]);
  }

  async loginWithGoogle() {
    this.afAuth
      .signInWithPopup(new firebase.auth.GoogleAuthProvider())
      .then(async (result: any) => {
        if (result && result.user) {
          this.userEmail = result.user.email;
          if (this.emailIsValid(this.userEmail)) {
            await this.fetchByUserEmail(
              result.additionalUserInfo.profile,
              result.credential.idToken
            );
          } else {
            this.messageService.add({
              severity: "error",
              summary: "Authentication Error",
              detail:
                "You are not allow to access these page, please login with your halogen account",
            });
            this.logout();
          }
        } else {
          this.messageService.add({
            severity: "error",
            summary: "Authentication Error",
            detail:
              "External authentication provider not available at the moment, try again later.",
          });
        }
      });
  }

  emailIsValid(email: string): boolean {
    return (
      email.trim().endsWith("halogen-group.com") ||
      email.trim().endsWith("avanthalogen.com") ||
      email.trim().endsWith("averthalogen.com") ||
      email.trim().endsWith("armourxhalogen.com") ||
      email.trim().endsWith("pshalogen.com") ||
      email.trim().endsWith("academyhalogen.com") ||
      email.trim().endsWith("armadahalogen.com")
    );
  }

  async grantSessionBasicSiginin(auth) {
    this.logginInSubject.next(true);
    this.setUserRelatedLocalStorageItems(auth);
    await this.goHome();
  }

  async fetchByUserEmail(profile, token: string) {
    this.logginInSubject.next(true);

    this.userService.authenticateUser(token).subscribe(
      async (result) => {
        if (result.responseCode == "00") {
          var auth = result.responseData;
          this.setUserRelatedLocalStorageItems(auth);
          await this.goHome();
        } else {
          if (result.responseCode == "04") {
            var auth = result.responseData;
            this.setUserRelatedLocalStorageItems(auth);
            await this.goHome();
          } else {
            this.messageService.add({
              severity: "error",
              summary: "Authentication Error",
              detail:
                "You are not allow to access these page, please login with your halogen account",
            });
            this.logout();
          }
        }
      },
      async (error: HttpErrorResponse) => {
        // An halogen email was successfully used to login but profile does
        // not exist on the backend.
      }
    );
  }

  async createUser(postData) {
    this.userService.createAndAuthenticateUser(postData).subscribe(
      async (result: any) => {
        if (result.responseCode == "00") {
          var auth = result.responseData;
          this.setUserRelatedLocalStorageItems(auth);
          await this.goHome();
        }
      },
      (error: HttpErrorResponse) => {
        this.logout();
      }
    );
  }

  setUserRelatedLocalStorageItems(result: any) {
    const token = result.token;
    const userProfile = result.userProfile;
    const refreshToken = result.refreshToken;
    const roles = result.roles;
    const userPermissions = result.userPermissions;

    this.setSessionStorageItem("token", token);
    this.setSessionStorageItem("userProfile", userProfile);
    this.setSessionStorageItem("refresh-token", refreshToken);
    this.setSessionStorageItem("userRoles", roles);
    this.setSessionStorageItem("userPermissions", userPermissions);
  }

  replaceToken(token) {
    this.setSessionStorageItem("token", token);
  }

  goHome() {
    return this.goLoginDashboard();

    this.logginInSubject.next(false);
    this.messageService.add({
      severity: "success",
      summary: "Authentication Pass",
      detail: "You successfully Login, wait while we redirect your connection",
    });
    return this.router.navigate(["/home/profile"]);
  }

  goLoginDashboard() {
    this.logginInSubject.next(false);
    this.messageService.add({
      severity: "success",
      summary: "Authentication Pass",
      detail: "You successfully Login, wait while we redirect your connection",
    });
    return this.router.navigate(["/home/login-dashboard"]);
  }

  async getDownloadRef(docUrl: string) {
    const httpsReference = await this.fireStorage.refFromURL(docUrl);
    httpsReference.getDownloadURL().subscribe(async (res) => {
      console.log(res);
    });
  }

  get isLoggedIn(): boolean {
    return this.token !== null && this.authUserProfile !== null;
  }

  get token() {
    return this.getSessionStorageItem("token");
  }

  get refreshToken() {
    return this.getSessionStorageItem("refresh-token");
  }

  get authUserProfile() {
    return this.getSessionStorageItem("userProfile");
  }

  get authUserRoles() {
    return this.getSessionStorageItem("userRoles");
  }

  get authUserPermissions() {
    return this.getSessionStorageItem("userPermissions");
  }

  // get authUserRoleClaims(){
  //  return this.getSessionStorageItem('userRoleClaims');
  // }

  // set authUserRoleClaims(roleClaims){
  //   this.setSessionStorageItem('userRoleClaims', roleClaims)
  // }

  setSessionStorageItem(key: string, data: any) {
    sessionStorage.setItem(key, JSON.stringify(data));
  }

  getSessionStorageItem(key: string): any {
    return JSON.parse(sessionStorage.getItem(key));
  }

  removeAllLocalStorageItems() {
    sessionStorage.clear();
  }
}
