import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { ConfirmationService, MessageService } from "primeng/api";
import { BreadcrumbService } from "src/app/breadcrumb.service";
import {
  Benefit,
  CreateBenefitVM,
  UpdateBenefitVM,
} from "src/app/interfaces/platform-benefit";
import { PlatformBenefitService } from "src/app/services/platform-benefit.service";

@Component({
  selector: "app-pbm-create-benefit",
  templateUrl: "./pbm-create-benefit.component.html",
  styleUrls: ["./pbm-create-benefit.component.scss"],
})
export class PbmCreateBenefitComponent implements OnInit {
  @ViewChild("formWrapper") public formWrapper: ElementRef;
  benefitForm: FormGroup;
  editingBenefit: boolean;
  allBenefits: Benefit[];
  selectedBenefits: Benefit[];
  benefitCols: any[];
  fetchingBenefit: boolean;
  benefitToEdit: Benefit;

  constructor(
    private fb: FormBuilder,
    public platformBenefitService: PlatformBenefitService,
    private breadcrumbService: BreadcrumbService,
    public confirmationService: ConfirmationService,
    public messageService: MessageService
  ) {
    this.benefitForm = fb.group({
      Name: ["", Validators.required],
      Code: [""],
      Description: ["", Validators.required],
    });
  }

  ngOnInit(): void {
    this.breadcrumbService.setItems([
      {
        label: "Platform Benefits Management",
        routerLink: ["/home/platform-benefit"],
      },
      {
        label: "Create Benefit",
        routerLink: ["/home/platform-benefit/create-benefit"],
      },
    ]);

    this.benefitCols = [
      { field: "uniqueCode", header: "Code" },
      { field: "name", header: "Name" },
      { field: "description", header: "Description" },
    ];

    this.FetchAllBenefits();
  }

  FetchAllBenefits() {
    this.fetchingBenefit = true;
    this.platformBenefitService.GetAllBenefits().subscribe(
      async (data) => {
        this.allBenefits = data;
        this.fetchingBenefit = false;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all benefits at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
        this.fetchingBenefit = false;
      }
    );
  }

  CreateBenefit() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Creating Benefit...",
    });

    const postData: CreateBenefitVM = {
      name: this.benefitForm.get("Name").value,
      description: this.benefitForm.get("Description").value,
    };

    this.platformBenefitService.CreateBenefit(postData).subscribe(
      async () => {
        this.messageService.add({
          severity: "success",
          summary: "Completed",
          detail: "Benefit Created Successfully!",
        });

        this.benefitForm.reset();
        this.FetchAllBenefits();
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to create benefit at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  CloseEditingBenefit() {
    this.editingBenefit = false;
    this.benefitForm.reset();
    this.benefitToEdit = null;
  }

  UpdateBenefit() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Updating Benefit...",
    });

    const id = this.benefitToEdit.id;
    const postData: UpdateBenefitVM = {
      name: this.benefitForm.get("Name").value,
      description: this.benefitForm.get("Description").value,
    };

    this.platformBenefitService.UpdateBenefit(id, postData).subscribe(
      async () => {
        this.messageService.add({
          severity: "success",
          summary: "Completed",
          detail: "Benefit Updated Successfully!",
        });

        this.CloseEditingBenefit();
        this.FetchAllBenefits();
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to update benefit at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  DeleteBenefit(item: Benefit) {
    this.confirmationService.confirm({
      message: "Are you sure you want to remove benefit?",
      accept: () => {
        this.messageService.add({
          severity: "info",
          summary: "Notice",
          detail: "Removing benefit...",
        });

        this.platformBenefitService.DeleteBenefit(item.id).subscribe(
          async () => {
            this.messageService.add({
              severity: "success",
              summary: "Removed",
              detail: "Removed successfully",
            });

            this.fetchingBenefit = true;
            const index = this.allBenefits.indexOf(item);
            if (index > -1) {
              this.allBenefits.splice(index, 1);
            }
            this.FetchAllBenefits();
            this.fetchingBenefit = false;
          },
          (error) => {
            console.log("Error: " + JSON.stringify(error));
            this.messageService.add({
              severity: "error",
              summary: "Notice",
              detail:
                "Unable to remove benefit at the moment.. Reason: [" + error
                  ? error.error.message
                  : "request failed - permission" + "]",
            });
          }
        );
      },
    });
  }

  EditBenefit(item: Benefit) {
    this.editingBenefit = true;
    this.benefitForm.patchValue({
      Name: item.name,
      Description: item.description,
      Code: item.uniqueCode,
    });
    this.benefitToEdit = item;

    this.formWrapper.nativeElement.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "start",
    });
  }
}
