import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CommonResponse, LeadEngagement } from '../interfaces/home';

@Injectable({
  providedIn: 'root'
})
export class LeadEngagementService {

  observablesCache: any = {};

  constructor(private http: HttpClient) { }
  baseUrl = environment.baseUrl+'LeadEngagement/';
  // fetch all LeadEngagement in the database
  allLeadEngagementsData(): Observable<CommonResponse>{
    return this.http.get<CommonResponse>(this.baseUrl);
  }
  getLeadEngagement(id: any):Observable<CommonResponse>{
    return this.http.get<CommonResponse>(this.baseUrl +id);
  }
  getLeadEngagementsByLeadId(leadId: any):Observable<CommonResponse>{
    if(this.observablesCache[leadId]) return this.observablesCache[leadId]
    else {
      this.observablesCache[leadId] = this.http.get<CommonResponse>(this.baseUrl + 'GetLeadEngagementsByLeadId/' + leadId).pipe(shareReplay()); 
      return this.observablesCache[leadId];
    }
  }
  getLeadEngagementByName(name: any):Observable<CommonResponse>{
    return this.http.get<CommonResponse>(this.baseUrl + 'name/' + name);
  }
  postLeadEngagement(data: any){
    return this.http.post(this.baseUrl, data);
  }
  deleteLeadEngagement(id: any){
    return this.http.delete(this.baseUrl +id);
  }
  updateLeadEngagement(id: any, data: any){
    return this.http.put(this.baseUrl +id, data);
  }
}
