import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { AngularFireStorage } from "@angular/fire/storage";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ConfirmationService, MessageService } from "primeng/api";
import { Observable, Subject, Subscription, timer } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { BreadcrumbService } from "src/app/breadcrumb.service";
import {
  ArmadaJourneyStart,
  CommanderServiceAssignmentDetail,
  MasterServiceAssignment,
} from "src/app/interfaces/armada";
import { CommonResponse } from "src/app/interfaces/home";
import { JourneyStartAndStopService } from "src/app/services/armada/journey-start-and-stop.service";
import { MasterServiceAssignmentService } from "src/app/services/armada/master-service-assignment.service";
import { ServiceAssignmentDetailService } from "src/app/services/armada/service-assignment-detail.service";

@Component({
  selector: "app-admin-end-journey",
  templateUrl: "./admin-end-journey.component.html",
  styleUrls: ["./admin-end-journey.component.scss"],
})
export class AdminEndJourneyComponent implements OnInit {
  @ViewChild("formWrapper") public formWrapper: ElementRef;

  private unsubscriber$ = new Subject<void>();

  public targetCols: any;
  private exportTargetColumns: any;
  public submittingData: boolean;
  public loading: boolean;
  public submitButtonLabel: string;
  actualLatitude: number = 0.0;
  actualLongitude: number = 0.0;
  actualAddress: string = "Head Office";
  public masterServiceAssignments: MasterServiceAssignment[];
  public masterServiceAssignments2: MasterServiceAssignment[];
  public masterServiceAssignments3: MasterServiceAssignment[];
  public commanderServiceAssignmentDetails: CommanderServiceAssignmentDetail[];
  selectedmasterServiceAssignment: MasterServiceAssignment;
  selectedmasterServiceAssignment3: MasterServiceAssignment;
  selectedCommanderServiceAssignmentDetail: any;
  isViewClicked: boolean;
  journeyStartId;
  public armadaJourneyEndForm: FormGroup;
  public armadaJourneyStart: ArmadaJourneyStart;
  subscription: Subscription;
  everyTwoMinutes: Observable<number> = timer(0, 120000);
  constructor(
    private breadcrumbService: BreadcrumbService,
    public messageService: MessageService,
    public confirmationService: ConfirmationService,
    private formBuilder: FormBuilder,
    public journeyStartAndStopService: JourneyStartAndStopService,
    private serviceAssignmentService: MasterServiceAssignmentService,
    private serviceAssignmentDetailService: ServiceAssignmentDetailService,
    public fireStorage: AngularFireStorage
  ) {
    this.breadcrumbService.setItems([
      { label: "Setup" },
      { label: "admin journey end", routerLink: ["/setup/admin-end-journey"] },
    ]);
  }

  ngOnInit(): void {
    this.armadaJourneyEndForm = this.formBuilder.group({
      serviceAssignmentId: [null, [Validators.required]],
    });
    this.subscription = this.everyTwoMinutes.subscribe(() => {
      this.fetchAllOngoingAndAssignedTripsToBeEnded();
    this.fetchAllExpiredAssignmetsToBeReleased();
    this.fetchAllComingFromOnlineAndBookedNow();
    });
    this.fetchAllOngoingAndAssignedTripsToBeEnded();
    this.fetchAllExpiredAssignmetsToBeReleased();
    this.fetchAllComingFromOnlineAndBookedNow();
  }

  fetchAllOngoingAndAssignedTripsToBeEnded() {
    this.serviceAssignmentService
      .allAssignedOrServiceAssignmentsWithResources()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            var res = r.responseData;
            this.masterServiceAssignments2 = res;
            console.log(res);
          }
        },
        (error) => {
          this.loading = false;
          this.connectionError();
        }
      );
    this.submittingData = false;
  }
  filterOngoingServiceAssignments() {
    this.serviceAssignmentService
    .allAssignedOrServiceAssignmentsWithResources()
    .pipe(takeUntil(this.unsubscriber$))
    .subscribe(
      (r: CommonResponse) => {
        if (r.responseCode == "00") {
          var res = r.responseData;
          const _assignments = res;
          this.masterServiceAssignments2 = [];
          this.masterServiceAssignments2 = _assignments.filter(
            (ass) => ass.saExecutionStatus === 1
          );
          console.log(res);
        }
      },
      (error) => {
        this.loading = false;
        this.connectionError();
      }
    );
  this.submittingData = false;
  }
  filterAssignedServiceAssignments() {
    this.serviceAssignmentService
      .allAssignedOrServiceAssignmentsWithResources()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            var res = r.responseData;
            const _assignments = res;
            this.masterServiceAssignments2 = [];
            this.masterServiceAssignments2 = _assignments.filter(
              (ass) => ass.saExecutionStatus === 0
            );
            console.log(res);
          }
        },
        (error) => {
          this.loading = false;
          this.connectionError();
        }
      );
    this.submittingData = false;
  }
  fetchAllComingFromOnlineAndBookedNow() {
    this.serviceAssignmentService
      .allllAssignedWithResourcesComingFromOnlineAndBookedNow()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            var res = r.responseData;
            this.masterServiceAssignments3 = res;
            console.log(res);
          }
        },
        (error) => {
          this.loading = false;
          this.connectionError();
        }
      );
    this.submittingData = false;
  }
  fetchAllExpiredAssignmetsToBeReleased() {
    this.serviceAssignmentService
      .allExpiredServiceAssignments()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            var res = r.responseData;
            this.masterServiceAssignments = res;
            console.log(res);
          }
        },
        (error) => {
          this.loading = false;
          this.connectionError();
        }
      );
    this.submittingData = false;
  }

  getSelectedCommanderAssignmentDetail(
    serviceAssignmentDetails: CommanderServiceAssignmentDetail
  ): void {
    this.formWrapper.nativeElement.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "start",
    });

    this.selectedCommanderServiceAssignmentDetail = serviceAssignmentDetails;
    this.armadaJourneyEndForm.patchValue({
      serviceAssignmentId: serviceAssignmentDetails.serviceAssignmentId,
    });

    this.journeyStartAndStopService
      .getJourneyStartByAssignmentId(
        serviceAssignmentDetails.serviceAssignmentId
      )
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            //
            var res = r.responseData;
            this.journeyStartId = res.id;
            this.armadaJourneyStart = res;
            this.submittingData = false;
            //this.armadaJourneyEndForm.reset();
          } else {
            this.connectionError();
          }
        },
        (error) => {
          this.connectionError();
          this.submittingData = false;
        }
      );

    this.isViewClicked = true;
    //this.submitButtonLabel = "Update";
  }

  createJourneyStart(masterServiceAssignment: MasterServiceAssignment): void {
    this.confirmationService.confirm({
      message: "Are you sure you want to start journey",
      accept: () => {
        this._createJourneyStart(masterServiceAssignment);
      },
    });
    //this.confirmCurrentLocationInfos();
  }
  _createJourneyStart(masterServiceAssignment) {
    this.submittingData = true;
    this.loading = true;
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Starting Journey",
    });
    //const formData = this.armadaJourneyStartForm.value;
    const postData = {
      serviceAssignmentId: masterServiceAssignment.id,
      journeyStartActualAddress: this.actualAddress,
      journeyStartActualLatitude: this.actualLatitude,
      journeyStartActualLongitude: this.actualLongitude,
    };
    this.journeyStartAndStopService
      .postJourneyStartForAdmin(postData)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            this.messageService.add({
              summary: "Success",
              severity: "success",
              detail: "Journey Started",
            });
            var getId = r.responseData.id;
            this.journeyStartId = getId;
            // var getAssignId = r.responseData.serviceAssignmentId;
            // localStorage.setItem("journeyStartID", getId);
            // this.journeyStartId = Number(
            //   localStorage.getItem("journeyStartID")
            // );
            // localStorage.setItem("serviceAssignmentId", getAssignId);
            // this.serviceAssignmentId = Number(
            //   localStorage.getItem("serviceAssignmentId")
            // );
            //this.fetchJourneyStartById();
            this.submittingData = false;
            this.loading = false;

            this.fetchAllOngoingAndAssignedTripsToBeEnded();
           
          } else {
            this.messageService.add({
              severity: "error",
              summary: "Failed",
              detail: " Error: " + r.responseMsg,
            });
            //this.submittingData = false;
            //this.loading = false;
          }
        },
        (error) => {
          this.messageService.add({
            severity: "error",
            summary: "Failed",
            detail: "Connection Error " + error.error.message,
          });
          this.submittingData = false;
          this.loading = false;
        }
      );
    //this.fetchJourneyStartById();
    this.submittingData = false;
  }
  

  updateJourneyEnd(masterServiceAssignment: MasterServiceAssignment): void {
    this.confirmationService.confirm({
      message: "Are you sure you want to end journey",
      accept: () => {
        this._updateEndJourneyStart(masterServiceAssignment);
      },
    });
  }
  _updateEndJourneyStart(masterServiceAssignment): void {
    this.submittingData = true;
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Ending Journey",
    });
    // if (!this.edArmadaJourneyStart) {
    //   return false;
    // }
    //const formData = this.armadaJourneyEndForm.value;
    const data = {
      serviceAssignmentId: masterServiceAssignment.id,
      journeyEndActualAddress: this.actualAddress,
      journeyEndActualLatitude: this.actualLatitude,
      journeyEndActualLongitude: this.actualLongitude,
    };
    this.journeyStartAndStopService
      .updateEndJourneyStartByAssignId(masterServiceAssignment.id, data)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (res: any) => {
          if (res.responseCode == "00") {
            this.messageService.add({
              severity: "success",
              detail: "Journey Ended",
              summary: "Completed",
            });
            this.submittingData = false;
            //this.fetchAllArmedEscortServiceAssignmentDetails();
            this.fetchAllOngoingAndAssignedTripsToBeEnded();
          } else {
            this.connectionError();
          }
        },
        (error) => {
          this.connectionError();
          this.submittingData = false;
        }
      );
  }

  releaseServiceAssignment(
    masterServiceAssignment: MasterServiceAssignment
  ): void {
    this.confirmationService.confirm({
      message:
        "Are you sure you want to release resource(s) attached to this service?",
      accept: () => {
        this._releaseServiceAssignment(masterServiceAssignment);
      },
    });
  }
  _releaseServiceAssignment(masterServiceAssignment) {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Releasing resources if any",
    });
    this.serviceAssignmentDetailService
      .releaseResourcesOfExpiredServiceAssignments(masterServiceAssignment.id)
      .subscribe(
        async (result: any) => {
          await this.messageService.add({
            severity: "success",
            summary: "Deleted",
            detail: "Resource(s) released",
          });
          await this.fetchAllExpiredAssignmetsToBeReleased();
        },
        (error) => {
          this.connectionError();
        }
      );
  }
 GetServiceAssignmentStatus(item: MasterServiceAssignment): string {
    const today = new Date();
    //var start = new Date(item.journeyStartDatetime);
    var pickUpdate = new Date(item.pickupDate);
    var saExecution = item.saExecutionStatus;
    if (saExecution === 1) {
      //this.getStatusColor = "p-badge p-badge-success";
      return "In Motion";
    }
    // if (saExecution === 2) {
    //   //this.getStatusColor = "p-badge p-badge-success";
    //   return "Journey Ended";
    // }
    // if (saExecution === 3) {
    //   //this.getStatusColor = "p-badge p-badge-success";
    //   return "Journey Cancelled";
    // }
    if (today > pickUpdate && saExecution === 0) {
      //this.getStatusColor = "p-badge p-badge-danger";
      return "Behind Schedule";
    }
    if (today < pickUpdate && saExecution === 0) {
      //this.getStatusColor = "p-badge p-badge-primary";
      return "On Time";
    }
    //if (today >= start && today <= pickUpdate) return "Active";
    return "Null";
  }
  GetServiceAssignmentTimeStatus(item: MasterServiceAssignment): string {
    //let today = new Date();
    //var start = new Date(item.journeyStartDatetime);
    //var pickUpTime = new Date(item.pickoffTime);
    var pickUpTime = new Date(item.createdAt);
    console.log(pickUpTime)
    var saExecution = item.saExecutionStatus;
    var currentTime = new Date();

// Calculate the time difference in milliseconds
var timeDifference = currentTime.getTime() - pickUpTime.getTime();

// Convert the time difference to hours
var hoursDifference = timeDifference / (1000 * 60 * 60);

// Check if the time difference is less than or equal to 3 hours
if (hoursDifference <= 3) {
  console.log('The time is less than or equal to 3 hours from the created DateTime item.');
} else {
  console.log('The time is more than 3 hours from the created DateTime item.');
}
   
    if (hoursDifference <= 3) {
      //this.getStatusColor = "p-badge p-badge-danger";
      return "Awaiting";
    }
    else{
      return "Release";
    }
  }

  deleteServiceAssignment(
    masterServiceAssignment: MasterServiceAssignment
  ): void {
    this.confirmationService.confirm({
      message:
        "Are you sure you want to delete assignment?",
      accept: () => {
        this._deleteServiceAssignment(masterServiceAssignment);
      },
    });
  }
  _deleteServiceAssignment(masterServiceAssignment) {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Deleting assignment",
    });
    this.serviceAssignmentService
      .deleteServiceAssignment(masterServiceAssignment.id)
      .subscribe(
        async (result: any) => {
          await this.messageService.add({
            severity: "success",
            summary: "Deleted",
            detail: "Assignment deleted and resources are released",
          });
          await this.fetchAllComingFromOnlineAndBookedNow();
        },
        (error) => {
          this.connectionError();
        }
      );
  }
  private connectionError() {
    this.messageService.add({
      severity: "error",
      summary: "Failed",
      detail: "Connection Error, Please try again",
    });
  }
}
