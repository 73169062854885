import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subject} from 'rxjs';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {JourneyStop, SituationType} from '../../../../interfaces/armada';
import {Lga, State} from '../../../../interfaces/home';
import {DynamicDialogConfig, DynamicDialogRef} from 'primeng/dynamicdialog';
import {SituationReportService} from '../../../../services/armada/situation-report.service';
import {SituationTypeService} from '../../../../services/armada/situation-type.service';
import {StateService} from '../../../../services/state.service';
import {takeUntil} from 'rxjs/operators';

@Component({
  selector: 'app-capture-journey-stop',
  templateUrl: './capture-journey-stop.component.html',
  styleUrls: ['./capture-journey-stop.component.scss']
})
export class CaptureJourneyStopComponent implements OnInit, OnDestroy {
  private unSubscriber$ = new Subject<void>();
  public journeyStopForm: FormGroup;
  public situationTypes: SituationType[];
  public selectedSituationType: SituationType;
  public states: State[] = [];
  public lgas: Lga[] = [];
  public selectedState: State;
  public selectedLga: Lga;
  private contractServiceId: number;
  processing: boolean;
  constructor(
      private dialogRef: DynamicDialogRef,
      private dialogConfig: DynamicDialogConfig,
      private formBuilder: FormBuilder,
      private situationReportService: SituationReportService,
      private situationTypeService: SituationTypeService,
      private stateService: StateService,
  ) { }

  ngOnInit(): void {
    this.fetchSituationType();
    this.fetchState();
    this.journeyStopForm = this.formBuilder.group({
      address: ['', Validators.required],
      stateId: [null, Validators.required],
      lgaId: [null, Validators.required],
      location: [''],
      landmark:  ['', Validators.required],
      remark:  ['', Validators.required],
      isPlanned: [false, Validators.required]
    });
    this.contractServiceId = this.dialogConfig.data.contractServiceId;
  }

  closeDialog(data?: JourneyStop) {
    this.dialogRef.close(data);
  }
  fetchSituationType() {
    this.situationTypeService.allSituationTypes().pipe(takeUntil(this.unSubscriber$))
        .subscribe( (result: any) => {
          this.situationTypes = result;
        })
  }
  fetchState() {
    this.stateService.allState().pipe(takeUntil(this.unSubscriber$))
        .subscribe((states: any) => {
          this.states = states.result;
        })
  }
  createJourneyStop() {
    const formData = this.journeyStopForm.value;
    const postData = {
      stateId: this.selectedState.id,
      lgaId: this.selectedLga.id,
      address: formData.address,
      location: formData.location,
      landmark: formData.landmark,
      contractServiceId: this.contractServiceId,
      serviceAssignmentId: this.dialogConfig.data.serviceAssignmentId,
      remark: formData.remark,
      isPlanned: formData.isPlanned
    }
    this.closeDialog(postData);
  }

  setLga() {
    this.lgas = this.selectedState?.lgAs;
  }
  ngOnDestroy(): void {
    this.unSubscriber$.complete();
    this.unSubscriber$.unsubscribe();
  }
}
