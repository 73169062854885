import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import {
  CreateReviewOfficeVM,
  UpdateReviewOfficeVM,
  MoveReviewOfficeSequenceVM,
  ConvergenceApprovalOffice,
  CreateApprovalOfficeVM,
  UpdateApprovalOfficeVM,
  MoveApprovalOfficeSequenceVM,
  RegisterCapturedIncidentVM,
  ConvergenceIncident,
  GetPendingIncidentsListVM,
  AddIncidentCommentVM,
  UpdateCapturedIncidentVM,
  GetDashboardFilteredEventsVM,
  ConvergenceInteractionType,
  CreateInteractionTypeVM,
  UpdateInteractionTypeVM,
  DeviceCategory,
  EventSource,
  EventType,
  CreateEventSourceVM,
  CreateEventTypeVM,
  UpdateEventSourceVM,
  UpdateEventTypeVM,
  ConvergenceReviewOffice,
  CreateDeviceCategoryVM,
  UpdateDeviceCategoryVM,
  DeviceSubCategory,
  CreateDeviceSubCategoryVM,
  UpdateDeviceSubCategoryVM,
  ConvergenceDevice,
  CreateConvergenceDeviceVM,
  UpdateConvergenceDeviceVM,
  CreateDeviceAttachmentsVM,
  ConvergenceDeviceAttachment,
  GetDeviceDashboardFilteredDataVM,
  ConvergenceNotificationTemplate,
  UpdateNotificationTemplateVM,
  CreateNotificationTemplateVM,
  ConvergenceNotificationPolicy,
  CreateNotificationPolicyVM,
  ConvergenceNotificationPolicyAttachment,
  CreateNotificationPolicyAttachmentVM,
  ConvergenceRegion,
  CreateConvergenceRegionVM,
  UpdateConvergenceRegionVM,
  GetConvergenceReportVM,
  GetConvergenceReportResultVM,
  AddNotificationPolicyAttachmentBeneficiaryVM,
  ConvergenceEntityDefaultSource,
  CreateDefaultEventSourceVM,
  UpdateDefaultEventSourceVM,
  ConvergenceAdvisory,
  CreateAdvisoryVM,
  UpdateAdvisoryVM,
  RegisterMobileCapturedIncidentVM,
  ConvergenceCategory,
  CreateConvergenceCategoryVM,
  UpdateConvergenceCategoryVM,
} from "../interfaces/convergence";
import { Location } from "../interfaces/home";

@Injectable({
  providedIn: "root",
})
export class ConvergenceService {
  baseUrl = environment.gmaBaseUrl + "Convergence/";

  constructor(private http: HttpClient) {}

  GetAllEventSources(): Observable<EventSource[]> {
    return this.http.get<EventSource[]>(this.baseUrl + "GetAllEventSources");
  }

  CreateEventSource(data: CreateEventSourceVM) {
    return this.http.post(this.baseUrl + "CreateEventSource", data);
  }

  UpdateEventSource(id: number, data: UpdateEventSourceVM) {
    return this.http.put(this.baseUrl + "UpdateEventSource/" + id, data);
  }

  DeleteEventSource(id: number) {
    return this.http.delete(this.baseUrl + "DeleteEventSource/" + id);
  }

  GetAllEventTypes(): Observable<EventType[]> {
    return this.http.get<EventType[]>(this.baseUrl + "GetAllEventTypes");
  }

  CreateEventType(data: CreateEventTypeVM) {
    return this.http.post(this.baseUrl + "CreateEventType", data);
  }

  UpdateEventType(id: number, data: UpdateEventTypeVM) {
    return this.http.put(this.baseUrl + "UpdateEventType/" + id, data);
  }

  DeleteEventType(id: number) {
    return this.http.delete(this.baseUrl + "DeleteEventType/" + id);
  }

  GetAllReviewOffices(): Observable<ConvergenceReviewOffice[]> {
    return this.http.get<ConvergenceReviewOffice[]>(
      this.baseUrl + "GetAllReviewOffices"
    );
  }

  CreateReviewOffice(data: CreateReviewOfficeVM) {
    return this.http.post(this.baseUrl + "CreateReviewOffice", data);
  }

  UpdateReviewOffice(id: number, data: UpdateReviewOfficeVM) {
    return this.http.put(this.baseUrl + "UpdateReviewOffice/" + id, data);
  }

  DeleteReviewOffice(id: number) {
    return this.http.delete(this.baseUrl + "DeleteReviewOffice/" + id);
  }

  MoveReviewOfficeSequence(data: MoveReviewOfficeSequenceVM) {
    return this.http.post(this.baseUrl + "MoveReviewOfficeSequence", data);
  }

  GetAllApprovalOffices(): Observable<ConvergenceApprovalOffice[]> {
    return this.http.get<ConvergenceApprovalOffice[]>(
      this.baseUrl + "GetAllApprovalOffices"
    );
  }

  CreateApprovalOffice(data: CreateApprovalOfficeVM) {
    return this.http.post(this.baseUrl + "CreateApprovalOffice", data);
  }

  UpdateApprovalOffice(id: number, data: UpdateApprovalOfficeVM) {
    return this.http.put(this.baseUrl + "UpdateApprovalOffice/" + id, data);
  }

  DeleteApprovalOffice(id: number) {
    return this.http.delete(this.baseUrl + "DeleteApprovalOffice/" + id);
  }

  MoveApprovalOfficeSequence(data: MoveApprovalOfficeSequenceVM) {
    return this.http.post(this.baseUrl + "MoveApprovalOfficeSequence", data);
  }

  RegisterCapturedIncident(data: RegisterCapturedIncidentVM) {
    return this.http.post(this.baseUrl + "RegisterCapturedIncident", data);
  }

  GetAllRegisteredIncidents(): Observable<ConvergenceIncident[]> {
    return this.http.get<ConvergenceIncident[]>(
      this.baseUrl + "GetAllRegisteredIncidents"
    );
  }

  DeleteCapturedIncident(id: number) {
    return this.http.delete(this.baseUrl + "DeleteCapturedIncident/" + id);
  }

  GetPendingIncidentsList(): Observable<GetPendingIncidentsListVM> {
    return this.http.get<GetPendingIncidentsListVM>(
      this.baseUrl + "GetPendingIncidentsList"
    );
  }

  PickIncidentToReview(id: number) {
    return this.http.get(this.baseUrl + "PickIncidentToReview/" + id);
  }

  UpdateCapturedIncident(id: number, data: UpdateCapturedIncidentVM) {
    return this.http.put(this.baseUrl + "UpdateCapturedIncident/" + id, data);
  }

  AddIncidentComment(data: AddIncidentCommentVM) {
    return this.http.post(this.baseUrl + "AddIncidentComment", data);
  }

  ReviewIncident(id: number, action: boolean) {
    return this.http.post(this.baseUrl + "ReviewIncident", {
      incidentId: id,
      action: action,
    });
  }

  GetReviewedPendingIncidentsList(): Observable<ConvergenceIncident[]> {
    return this.http.get<ConvergenceIncident[]>(
      this.baseUrl + "GetReviewedPendingIncidentsList"
    );
  }

  ApproveDenyyIncident(id: number, action: boolean, comment?: string) {
    return this.http.post(this.baseUrl + "ApproveDenyyIncident", {
      incidentId: id,
      action: action,
      comment: comment,
    });
  }

  GetDashboardFilteredEvents(
    data: GetDashboardFilteredEventsVM
  ): Observable<ConvergenceIncident[]> {
    return this.http.post<ConvergenceIncident[]>(
      this.baseUrl + "GetDashboardFilteredEvents",
      data
    );
  }

  GetAllInteractionTypes(): Observable<ConvergenceInteractionType[]> {
    return this.http.get<ConvergenceInteractionType[]>(
      this.baseUrl + "GetAllInteractionTypes"
    );
  }

  CreateInteractionType(data: CreateInteractionTypeVM) {
    return this.http.post(this.baseUrl + "CreateInteractionType", data);
  }

  UpdateInteractionType(id: number, data: UpdateInteractionTypeVM) {
    return this.http.put(this.baseUrl + "UpdateInteractionType/" + id, data);
  }

  DeleteInteractionType(id: number) {
    return this.http.delete(this.baseUrl + "DeleteInteractionType/" + id);
  }

  GetAllDeviceCategories(): Observable<DeviceCategory[]> {
    return this.http.get<DeviceCategory[]>(
      this.baseUrl + "GetAllDeviceCategories"
    );
  }

  CreateDeviceCategory(data: CreateDeviceCategoryVM) {
    return this.http.post(this.baseUrl + "CreateDeviceCategory", data);
  }

  UpdateDeviceCategory(id: number, data: UpdateDeviceCategoryVM) {
    return this.http.put(this.baseUrl + "UpdateDeviceCategory/" + id, data);
  }

  DeleteDeviceCategory(id: number) {
    return this.http.delete(this.baseUrl + "DeleteDeviceCategory/" + id);
  }

  GetAllDeviceSubCategories(): Observable<DeviceSubCategory[]> {
    return this.http.get<DeviceSubCategory[]>(
      this.baseUrl + "GetAllDeviceSubCategories"
    );
  }

  CreateDeviceSubCategory(data: CreateDeviceSubCategoryVM) {
    return this.http.post(this.baseUrl + "CreateDeviceSubCategory", data);
  }

  UpdateDeviceSubCategory(id: number, data: UpdateDeviceSubCategoryVM) {
    return this.http.put(this.baseUrl + "UpdateDeviceSubCategory/" + id, data);
  }

  DeleteDeviceSubCategory(id: number) {
    return this.http.delete(this.baseUrl + "DeleteDeviceSubCategory/" + id);
  }

  GetAllConvergenceDevices(): Observable<ConvergenceDevice[]> {
    return this.http.get<ConvergenceDevice[]>(
      this.baseUrl + "GetAllConvergenceDevices"
    );
  }

  CreateConvergenceDevice(data: CreateConvergenceDeviceVM) {
    return this.http.post(this.baseUrl + "CreateConvergenceDevice", data);
  }

  UpdateConvergenceDevice(id: number, data: UpdateConvergenceDeviceVM) {
    return this.http.put(this.baseUrl + "UpdateConvergenceDevice/" + id, data);
  }

  DeleteConvergenceDevice(id: number) {
    return this.http.delete(this.baseUrl + "DeleteConvergenceDevice/" + id);
  }

  GetAllLocations(): Observable<Location[]> {
    return this.http.get<Location[]>(this.baseUrl + "GetAllLocations");
  }

  GetAllConvergenceDevicesWithAttachmentInfo(): Observable<
    ConvergenceDevice[]
  > {
    return this.http.get<ConvergenceDevice[]>(
      this.baseUrl + "GetAllConvergenceDevicesWithAttachmentInfo"
    );
  }

  CreateDeviceAttachment(data: CreateDeviceAttachmentsVM) {
    return this.http.post(this.baseUrl + "CreateDeviceAttachment", data);
  }

  DeleteDeviceAttachment(id: number) {
    return this.http.delete(this.baseUrl + "DeleteDeviceAttachment/" + id);
  }

  GetAllDeviceAttachments(): Observable<ConvergenceDeviceAttachment[]> {
    return this.http.get<ConvergenceDeviceAttachment[]>(
      this.baseUrl + "GetAllDeviceAttachments"
    );
  }

  GetDeviceDashboardFilteredData(
    data: GetDeviceDashboardFilteredDataVM
  ): Observable<ConvergenceDeviceAttachment[]> {
    return this.http.post<ConvergenceDeviceAttachment[]>(
      this.baseUrl + "GetDeviceDashboardFilteredData",
      data
    );
  }

  GetAllConvergenceNotificationTemplates(): Observable<
    ConvergenceNotificationTemplate[]
  > {
    return this.http.get<ConvergenceNotificationTemplate[]>(
      this.baseUrl + "GetAllConvergenceNotificationTemplates"
    );
  }

  CreateNotificationTemplate(data: CreateNotificationTemplateVM) {
    return this.http.post(this.baseUrl + "CreateNotificationTemplate", data);
  }

  UpdateNotificationTemplate(id: number, data: UpdateNotificationTemplateVM) {
    return this.http.put(
      this.baseUrl + "UpdateNotificationTemplate/" + id,
      data
    );
  }

  DeleteNotificationTemplate(id: number) {
    return this.http.delete(this.baseUrl + "DeleteNotificationTemplate/" + id);
  }

  GetAllNotificationPolicies(): Observable<ConvergenceNotificationPolicy[]> {
    return this.http.get<ConvergenceNotificationPolicy[]>(
      this.baseUrl + "GetAllNotificationPolicies"
    );
  }

  CreateNotificationPolicy(data: CreateNotificationPolicyVM) {
    return this.http.post(this.baseUrl + "CreateNotificationPolicy", data);
  }

  UpdateNotificationPolicy(id: number, data: CreateNotificationPolicyVM) {
    return this.http.put(this.baseUrl + "UpdateNotificationPolicy/" + id, data);
  }

  DeleteNotificationPolicy(id: number) {
    return this.http.delete(this.baseUrl + "DeleteNotificationPolicy/" + id);
  }

  GetAllIncidentNotificationPolicies(): Observable<
    ConvergenceNotificationPolicy[]
  > {
    return this.http.get<ConvergenceNotificationPolicy[]>(
      this.baseUrl + "GetAllIncidentNotificationPolicies"
    );
  }

  GetNotificationPolicyAttachments(
    policyId: number
  ): Observable<ConvergenceNotificationPolicyAttachment[]> {
    return this.http.get<ConvergenceNotificationPolicyAttachment[]>(
      this.baseUrl + "GetNotificationPolicyAttachments/" + policyId
    );
  }

  CreateNotificationPolicyAttachment(
    data: CreateNotificationPolicyAttachmentVM
  ) {
    return this.http.post(
      this.baseUrl + "CreateNotificationPolicyAttachment",
      data
    );
  }

  UpdateNotificationPolicyAttachment(id: number, data: { webhookUrl: string }) {
    return this.http.put(
      this.baseUrl + "UpdateNotificationPolicyAttachment/" + id,
      data
    );
  }

  DeleteNotificationPolicyAttachment(id: number) {
    return this.http.delete(
      this.baseUrl + "DeleteNotificationPolicyAttachment/" + id
    );
  }

  GetAllConvergenceRegions(): Observable<ConvergenceRegion[]> {
    return this.http.get<ConvergenceRegion[]>(
      this.baseUrl + "GetAllConvergenceRegions"
    );
  }

  CreateConvergenceRegion(data: CreateConvergenceRegionVM) {
    return this.http.post(this.baseUrl + "CreateConvergenceRegion", data);
  }

  UpdateConvergenceRegion(id: number, data: UpdateConvergenceRegionVM) {
    return this.http.put(this.baseUrl + "UpdateConvergenceRegion/" + id, data);
  }

  DeleteConvergenceRegion(id: number) {
    return this.http.delete(this.baseUrl + "DeleteConvergenceRegion/" + id);
  }

  GetConvergenceReport(
    data: GetConvergenceReportVM
  ): Observable<GetConvergenceReportResultVM> {
    return this.http.post<GetConvergenceReportResultVM>(
      this.baseUrl + "GetConvergenceReport",
      data
    );
  }

  AddNotificationPolicyAttachmentBeneficiary(
    data: AddNotificationPolicyAttachmentBeneficiaryVM
  ) {
    return this.http.post(
      this.baseUrl + "AddNotificationPolicyAttachmentBeneficiary",
      data
    );
  }

  GetAllDefaultEventSources(): Observable<ConvergenceEntityDefaultSource[]> {
    return this.http.get<ConvergenceEntityDefaultSource[]>(
      this.baseUrl + "GetAllDefaultEventSources"
    );
  }

  CreateDefaultEventSource(data: CreateDefaultEventSourceVM) {
    return this.http.post(this.baseUrl + "CreateDefaultEventSource", data);
  }

  UpdateDefaultEventSource(id: number, data: UpdateDefaultEventSourceVM) {
    return this.http.put(this.baseUrl + "UpdateDefaultEventSource/" + id, data);
  }

  DeleteDefaultEventSource(id: number) {
    return this.http.delete(this.baseUrl + "DeleteDefaultEventSource/" + id);
  }

  GetAllAdvisories(): Observable<ConvergenceAdvisory[]> {
    return this.http.get<ConvergenceAdvisory[]>(
      this.baseUrl + "GetAllAdvisories"
    );
  }

  CreateAdvisory(data: CreateAdvisoryVM) {
    return this.http.post(this.baseUrl + "CreateAdvisory", data);
  }

  UpdateAdvisory(id: number, data: UpdateAdvisoryVM) {
    return this.http.put(this.baseUrl + "UpdateAdvisory/" + id, data);
  }

  DeleteAdvisory(id: number) {
    return this.http.delete(this.baseUrl + "DeleteAdvisory/" + id);
  }

  RegisterMobileCapturedIncident(data: RegisterMobileCapturedIncidentVM) {
    return this.http.post(
      this.baseUrl + "RegisterMobileCapturedIncident",
      data
    );
  }

  GetAllConvergenceCategories(): Observable<ConvergenceCategory[]> {
    return this.http.get<ConvergenceCategory[]>(
      this.baseUrl + "GetAllConvergenceCategories"
    );
  }

  CreateConvergenceCategory(data: CreateConvergenceCategoryVM) {
    return this.http.post(this.baseUrl + "CreateConvergenceCategory", data);
  }

  UpdateConvergenceCategory(id: number, data: UpdateConvergenceCategoryVM) {
    return this.http.put(
      this.baseUrl + "UpdateConvergenceCategory/" + id,
      data
    );
  }

  DeleteConvergenceCategory(id: number) {
    return this.http.delete(this.baseUrl + "DeleteConvergenceCategory/" + id);
  }
}
