import { Component, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { ConfirmationService, MenuItem, MessageService } from "primeng/api";
import {
  DialogService,
  DynamicDialogConfig,
  DynamicDialogRef,
} from "primeng/dynamicdialog";
import { FileUpload } from "primeng/fileupload";
import { finalize } from "rxjs/operators";
import { ProjectAllocationService } from "../project-allocation/project-allocation.service";
import { EventApi, EventInput } from "@fullcalendar/core";
import { ContactsService } from "./contacts.service";
import { FileStorageService } from "src/app/services/file-storage.service";
import { CommonResponse, Contact, User } from "src/app/interfaces/home";
import { UserService } from "src/app/services/user.service";
@Component({
  selector: "app-contacts",
  templateUrl: "./contacts.component.html",
  styleUrls: ["./contacts.component.scss"],
})
export class ContactsComponent implements OnInit {
  @ViewChild("fubauto") fubauto: FileUpload;
  displayModal: boolean = false;
  selectedApproval: any;
  displayContactCreation = false;
  defaultImage = "https://www.freeiconspng.com/uploads/profile-icon-9.png";
  items: { label: string; routerLink: string }[];
  tabMenuItems: MenuItem[];
  gender: any[];
  title: { name: string; value: number }[];
  contactPriority: any;
  createContactForm: any;
  createPersonalForm: any;
  createOtherForm: any;
  fileToBeUploaded: any;
  displayContactEditting = false;
  workspaceIsLoading: boolean;
  fileToBeUploadedName: any;
  imageUrl: any;
  myfiles: any[];
  imageSrc: string;
  file: any;
  designation: { name: string; value: number }[];
  divisionList: any;
  designationList: any[];
  constacts: any[] = [];
  profilePic: string;
  todaysDate: number;
  contactCols: { field: string; header: string }[];
  exportColumns: any;
  EditContactForm: any;
  pictureToEdit: any;
  contactId: any;
  contactBirthDate: any;
  openMoreInformationDialogie: boolean;
  moreInfoImgSrc: string;
  contactInView: Contact;
  allUsers: User[] = [];

  constructor(
    private formBuilder: FormBuilder,
    private userService: UserService,
    private constactsService: ContactsService,
    public confirmationService: ConfirmationService,
    public dialogService: DialogService,
    public messageService: MessageService,
    public fileStorageService: FileStorageService
  ) {
    this.createPersonalForm = this.formBuilder.group({
      title: [null, Validators.required],
      firstName: [null, Validators.required],
      middleName: [null],
      lastName: [null, Validators.required],
      gender: [null, Validators.required],
      dob: [null],
    });

    this.createContactForm = this.formBuilder.group({
      mobile1: [null, Validators.required],
      mobile2: [null],
      email1: [null, [Validators.required, Validators.email]],
      email2: [null],
    });

    this.createOtherForm = this.formBuilder.group({
      instagram: [null],
      facebook: [null],
      twitter: [null],
    });

    this.EditContactForm = this.formBuilder.group({
      title: [null],
      firstName: [null],
      middleName: [null],
      lastName: [null],
      gender: [null],
      dob: [null],
      mobile: [null],
      mobile2: [null],
      email: [null, Validators.email],
      email2: [null],
      instagram: [null],
      facebook: [null],
      twitter: [null],
    });
  }

  ngOnInit(): void {
    this.gender = [
      { name: "Male", value: 1 },
      { name: "Female", value: 2 },
    ];

    this.title = [
      { name: "Mr", value: 1 },
      { name: "Mrs", value: 2 },
      { name: "Miss", value: 3 },
      { name: "Ms", value: 4 },
      { name: "Dr", value: 5 },
      { name: "Chief", value: 6 },
      { name: "Others", value: 7 },
    ];

    this.contactPriority = [
      { name: "Primary", value: 1 },
      { name: "Secondary", value: 2 },
      { name: "Other", value: 3 },
    ];

    this.FetchAllUsers();
    this.getCustomerDivisionList();
    this.getCustomerDesignationList();
    this.getAllContacts();
    this.getTodaysDate();

    this.contactCols = [
      { field: "titie", header: "Title" },
      { field: "firstName", header: "FirstName" },
      { field: "lastName", header: "LastName" },
      { field: "middleName", header: "MiddleName" },
      { field: "gender", header: "Gender" },
      { field: "mobile", header: "Mobile" },
      { field: "email", header: "Email" },
      { field: "dob", header: "DOB" },
      { field: "instagram", header: "Instagram" },
      { field: "facebook", header: "Facebook" },
      { field: "twitter", header: "Twitter" },
      { field: "status", header: "Is Attached" },
      { field: "createdBy", header: "Created By" },
    ];
    this.exportColumns = this.contactCols.map((col) => ({
      title: col.header,
      dataKey: col.field,
    }));
  }

  async FetchAllUsers() {
    this.userService.allUser().subscribe(
      async (r) => {
        var data = r.responseData ?? [];
        this.allUsers = data;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all users at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  openContactCreation() {
    this.displayContactCreation = true;
    this.imageSrc = null;
    this.file = null;
  }

  editContact(payload) {
    this.messageService.add({
      severity: "success",
      summary: "Notice",
      detail: "All forms were left filled",
    });
    console.log("Here is the payload", payload);
    this.constactsService.updateContacts(this.contactId, payload).subscribe(
      async (res: any) => {
        if (res.responseCode == "00") {
          console.log("this is the result", res.responseData);
          this.workspaceIsLoading = false;
          this.messageService.add({
            severity: "success",
            summary: "Success",
            detail: "Contact was successfully created",
          });
          this.displayContactEditting = false;
          this.EditContactForm.reset();
          this.file = null;
          this.imageUrl = null;
          this.imageSrc = null;
          this.getAllContacts();
        } else {
          this.workspaceIsLoading = false;
          this.messageService.add({
            severity: "error",
            summary: "Failed",
            detail: res.responseMsg,
          });
        }
      },
      (error) => {
        this.workspaceIsLoading = false;
        this.messageService.add({
          severity: "error",
          summary: "Failed",
          detail: "An error occured",
        });
      }
    );
  }

  saveContact() {
    if (
      this.createPersonalForm.invalid ||
      this.createContactForm.invalid ||
      this.createOtherForm.invalid
    ) {
      this.messageService.add({
        severity: "error",
        summary: "Notice",
        detail: "Some forms were left unfilled",
      });
    } else {
      this.messageService.add({
        severity: "success",
        summary: "Notice",
        detail: "All forms were left filled",
      });

      const payload = {
        title: this.createPersonalForm.value.title.name,
        gender: this.createPersonalForm.value.gender.value,
        dateOfBirth: this.createPersonalForm.value.dob ?? "",
        email: this.createContactForm.value.email1,
        email2: this.createContactForm.value.email2 ?? "",
        mobile: this.createContactForm.value.mobile1,
        mobile2: this.createContactForm.value.mobile2 ?? "",
        lastName: this.createPersonalForm.value.lastName ?? " ",
        firstName: this.createPersonalForm.value.firstName,
        middleName: this.createPersonalForm.value.middleName ?? "",
        twitter: this.createOtherForm.value.twitter ?? "",
        facebook: this.createOtherForm.value.facebook ?? "",
        instagram: this.createOtherForm.value.instagram ?? "",
        profilePicture: this.imageUrl,
      };

      console.log("Here is the payload", payload);

      this.constactsService.createContact(payload).subscribe(
        async (res: any) => {
          if (res.responseCode == "00") {
            console.log("this is the result", res.responseData);
            this.constacts.push(res.responseData);
            this.workspaceIsLoading = false;
            this.messageService.add({
              severity: "success",
              summary: "Success",
              detail: "Contact was successfully created",
            });
            this.displayContactCreation = false;
            this.createPersonalForm.reset();
            this.createContactForm.reset();
            this.createOtherForm.reset();
            this.imageSrc = null;
            this.file = null;
            this.imageUrl = null;
          } else {
            this.workspaceIsLoading = false;
            this.messageService.add({
              severity: "error",
              summary: "Failed",
              detail: res.responseMsg,
            });
          }
        },
        (error) => {
          this.workspaceIsLoading = false;
          this.messageService.add({
            severity: "error",
            summary: "Failed",
            detail: "An error occured",
          });
        }
      );
    }
  }

  checkMimeType = (event: EventInput): boolean => {
    const files = event.target.files[0];
    // list allow mime type
    const types = ["image/png", "image/jpeg", "image/jpg", "image/gif"];
    // loop access array
    if (!files) {
      this.messageService.add({
        severity: "error",
        summary: "Failed",
        detail: "Please select an Image file",
      });
      return false;
    }
    // compare file type find doesn't match
    if (types.every((type) => files.type !== type)) {
      // create error message and assign to container
      this.messageService.add({
        severity: "error",
        summary: "Failed",
        detail: "Image format not supported",
      });
      return false;
    }
    return true;
  };

  checkFileSize = (event: EventInput) => {
    const files = event.target.files[0];
    if (!files) return true;
    const size = 400000;
    if (files.size > size) {
      event.target.value = null;
      this.messageService.add({
        severity: "error",
        summary: "Failed",
        detail: "Image is too large, please pick a smaller file",
      });
      return false;
    }
    return true;
  };

  onfileSelect(event) {
    if (!this.checkMimeType(event) || !this.checkFileSize(event)) {
      return;
    } else {
      // this.uploadingNewPicture = true;
      // this.isEditing = true;
      this.createPersonalForm.enable();
      const reader = new FileReader();
      if (event.target.files && event.target.files.length) {
        const [file] = event.target.files;
        this.file = event.target.files[0];
        reader.readAsDataURL(file);
        reader.onload = () => {
          this.imageSrc = reader.result as string;
        };
      }
    }
  }

  cancelImage() {
    this.imageSrc = null;
    this.file = null;
  }

  openEddittingDialog(contact) {
    this.contactBirthDate = contact.dateOfBirth;
    this.contactId = contact.id;
    this.EditContactForm.reset();
    this.pictureToEdit = contact.profilePicture;
    console.log("this is the result of log", contact);
    this.displayContactEditting = true;
    this.EditContactForm.patchValue({
      title: contact.title,
      firstName: contact.firstName,
      middleName: contact.middleName,
      lastName: contact.lastName,
      gender: contact.gender,
      dob: new Date(contact.dateOfBirth),
      mobile: contact.mobile,
      mobile2: contact.mobile2,
      email: contact.email,
      email2: contact.email2,
      instagram: contact.instagram,
      facebook: contact.facebook,
      twitter: contact.twitter,
    });
  }

  cancelEdit() {
    this.EditContactForm.reset();
    this.displayContactEditting = false;
  }

  EditContact() {
    if (this.EditContactForm.dirty || this.imageSrc != null) {
      this.workspaceIsLoading = true;
      if (this.file) {
        this.fileStorageService.UploadFileFromDataUrl(this.file);
        this.fileStorageService.onUploadFinished.subscribe(
          (resp: CommonResponse<string>) => {
            if (resp.responseCode != "00") {
              this.messageService.add({
                severity: "error",
                summary: "Notice",
                detail: resp.responseMsg,
              });
            } else {
              this.imageUrl = resp.responseData;
              this.myfiles = [];
              var payload1 = this.buildPayload(this.imageUrl);
              this.editContact(payload1);
              console.log("this is the payload1 ", payload1);

              this.messageService.add({
                severity: "success",
                summary: "Notice",
                detail: "File was successfully uploaded..",
              });
            }
          },
          (error) => {
            console.log("Error while uploading file " + error);
            this.messageService.add({
              severity: "error",
              summary: "Notice",
              detail: "ERR: Unable to upload image to storage",
            });
          }
        );
      } else {
        var payload2 = this.buildPayload(this.pictureToEdit);
        this.editContact(payload2);
        console.log("this is the second payload", payload2);
      }

      //console.log("this is editpayload",payload)
    }
  }

  buildPayload(image): Object {
    const payload = {
      title: this.EditContactForm.value.title,
      firstName: this.EditContactForm.value.firstName,
      middleName: this.EditContactForm.value.middleName ?? "",
      lastName: this.EditContactForm.value.lastName ?? "",
      gender: this.EditContactForm.value.gender,
      dateOfBirth: this.EditContactForm.value.dob ?? "",
      mobile: this.EditContactForm.value.mobile,
      mobile2: this.EditContactForm.value.mobile2 ?? "",
      email: this.EditContactForm.value.email,
      email2: this.EditContactForm.value.email2 ?? "",
      instagram: this.EditContactForm.value.instagram ?? "",
      facebook: this.EditContactForm.value.facebook ?? "",
      twitter: this.EditContactForm.value.twitter ?? "",
      profilePicture: image,
    };

    return payload;
  }

  getAllContacts() {
    this.workspaceIsLoading = true;
    this.constactsService.GetAllContactsWithAttachmentStatus().subscribe(
      async (data) => {
        if (data.responseCode == "00") {
          this.constacts = data.responseData;
          this.constacts.forEach(
            (x) => (x.createdBy = this.GetUserName(x.createdById))
          );
          this.workspaceIsLoading = false;
          console.warn("these are the data", this.constacts);
          this.messageService.add({
            severity: "success",
            summary: "Notice",
            detail: "Contacts was fetched successfully",
          });
        } else {
          this.workspaceIsLoading = false;
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail: "No data avaliable",
          });
        }
      },
      (error) => {
        this.workspaceIsLoading = false;
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail: "An error occured",
        });
      }
    );
  }

  // Depreciated on 10-10-2023
  getAllContacts_Depreciated() {
    this.workspaceIsLoading = true;
    this.constactsService.getContactList().subscribe(
      async (data: any) => {
        if (data.responseCode == "00") {
          this.constacts = data.responseData;
          this.workspaceIsLoading = false;
          console.warn("these are the data", this.constacts);
          this.messageService.add({
            severity: "success",
            summary: "Notice",
            detail: "Contacts was fetched successfully",
          });
        } else {
          this.workspaceIsLoading = false;
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail: "No data avaliable",
          });
        }
      },
      (error) => {
        this.workspaceIsLoading = false;
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail: "An error occured",
        });
      }
    );
  }

  getTodaysDate() {
    const n = Date.now();
    this.todaysDate = n;
  }

  createNewContact() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Contact is being created",
    });
    this.workspaceIsLoading = true;
    if (this.file) {
      this.fileStorageService.UploadFileFromDataUrl(this.file);
      this.fileStorageService.onUploadFinished.subscribe(
        (resp: CommonResponse<string>) => {
          if (resp.responseCode != "00") {
            this.messageService.add({
              severity: "error",
              summary: "Notice",
              detail: resp.responseMsg,
            });
          } else {
            this.imageUrl = resp.responseData;
            this.myfiles = [];
            this.saveContact();
            console.log("this is the fieUpload ", this.imageUrl);
            this.messageService.add({
              severity: "success",
              summary: "Notice",
              detail: "File was successfully uploaded..",
            });
          }
        },
        (error) => {
          console.log("Error while uploading file " + error);
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail: "ERR: Unable to upload image to storage",
          });
        }
      );
    } else {
      this.saveContact();
    }
    //return this.imageUrl;
  }

  disableContact(contact) {
    console.log("this is contact", contact);
    this.confirmationService.confirm({
      message: `Are you sure that you want to disable ${contact.firstName} ${contact.lastName} ? `,
      header: "Confirmation",
      icon: "pi pi-exclamation-triangle",
      accept: () => {
        this.messageService.add({
          severity: "info",
          summary: "Confirmed",
          detail: `You are disabling ${contact.firstName} ${contact.lastName}`,
        });
        this.constactsService.disableContact(contact.id).subscribe(
          async (data: any) => {
            if (data.responseCode == "00") {
              var contactIndex = this.constacts.indexOf(contact.id);
              this.constacts.splice(contactIndex, 1);
              this.getAllContacts();
              this.messageService.add({
                severity: "success",
                summary: "Confirmed",
                detail: `You have successfully disabled ${contact.firstName} ${contact.lastName}`,
              });
            } else {
              this.messageService.add({
                severity: "error",
                summary: "Confirmed",
                detail: `Could not  disabled ${contact.firstName} ${contact.lastName}`,
              });
            }
          },
          (error) => {
            this.messageService.add({
              severity: "error",
              summary: "Confirmed",
              detail: `An error occurred`,
            });
          }
        );
      },
      reject: () => {},
    });
  }

  pushFileToStorage(): string {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "requirement file is being uploaded....",
    });
    this.workspaceIsLoading = true;

    this.fileStorageService.UploadFileFromDataUrl(this.file);
    this.fileStorageService.onUploadFinished.subscribe(
      (resp: CommonResponse<string>) => {
        if (resp.responseCode != "00") {
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail: resp.responseMsg,
          });
        } else {
          this.imageUrl = resp.responseData;
          this.myfiles = [];

          console.log("this is the fieUpload ", this.imageUrl);
          this.messageService.add({
            severity: "success",
            summary: "Notice",
            detail: "File was successfully uploaded..",
          });
        }
      },
      (error) => {
        console.log("Error while uploading file " + error);
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail: "ERR: Unable to upload image to storage",
        });
      }
    );
    return this.imageUrl;
  }

  getCustomerDesignationList() {
    this.constactsService.getCustomerDesignationList().subscribe(
      async (res: any) => {
        if (res.responseCode == "00") {
          this.designationList = [];
          this.designationList = res.responseData;
        } else {
          this.designationList = [];
        }
      },
      (error) => {
        this.designationList = [];
      }
    );
  }

  getCustomerDivisionList() {
    this.constactsService.getCustomerDivisionList().subscribe(
      async (res: any) => {
        if (res.responseCode == "00") {
          this.divisionList = [];
          this.divisionList = res.responseData;
        } else {
          this.divisionList = [];
        }
      },
      (error) => {
        this.divisionList = [];
      }
    );
  }

  OpenMoreInformationBox(item: Contact) {
    this.contactInView = item;
    this.moreInfoImgSrc = item.profilePicture;
    this.openMoreInformationDialogie = true;
  }

  HideMoreInformationBox() {
    this.openMoreInformationDialogie = false;
    this.contactInView = null;
    this.moreInfoImgSrc = null;
  }

  GetUserName(userId: number): string {
    let user = this.allUsers.find((x) => x.id == userId);
    if (user) return user.lastName + " " + user.firstName;

    return "N/A";
  }
}
