import { User } from "./home";

export const OrganizationNodeLevelEnum = {
  Head: 1,
  Deputy: 2,
  Member: 3,
};
Object.freeze(OrganizationNodeLevelEnum);

export const ServiceTypeEnum = {
  ServiceCreation: 1,
  ContractCreation: 2,
  Contracts: 3,
  Endorsements: 4,
};
Object.freeze(ServiceTypeEnum);

export interface Company {
  id: number;
  name: string;
  description: string;
  address: number;
}

export interface OrganizationNode {
  id: number;
  caption: string;
  description: string;
  companyId?: number;
  headNodeId?: number;
  organizationLevel?: number;
  subNodes?: OrganizationNode[];
  members?: OrganizationNodeMember[];
  isDeleted: boolean;
  createdById: number;
  createdAt: Date;
}

export interface OrganizationNodeMember {
  id?: number;
  imageUrl: string;
  lastname: string;
  firstname: string;
  userId?: number;
  nodeId?: number;
  user?: User;
  level: number;
  organizationLevel?: number;
  dateAdded?: Date;
}

export interface AddCompanyNodeVM {
  companyId: number;
  caption: string;
  description: string;
  members: AddCompanyNodeMemberVM[];
}

export interface AddCompanyNodeMemberVM {
  imageUrl: string;
  firstName: string;
  lastName: string;
  level: number;
  userId: number;
}

export interface AddOrganizationNodeVM {
  organizationNodeId: number;
  caption: string;
  description: string;
  members: AddCompanyNodeMemberVM[];
}

export interface UpdateOrganizationNodeVM {
  caption?: string;
  Description?: string;
  members: UpdateCompanyNodeMemberVM[];
}

export interface UpdateCompanyNodeMemberVM {
  imageUrl?: string;
  firstName?: string;
  lastName?: string;
  level: number;
  userId: number;
}

export interface NonPrincipalApprovingOffice {
  id: number;
  officeName: string;
  sequence: string;
  serviceCategoryName: string;
  serviceCategoryId?: number;
  serviceGroupName: string;
  serviceGroupId?: number;
  marketName: string;
  marketId?: number;
  serviceType: number;
  divsionName: string;
  divisionId?: number;
  approvingServices: NonPrincipalApprovingService[];
  approvingOfficers: NonPrincipalApprovingOfficer[];
  createdById: number;
  createdAt: Date;
}

export interface NonPrincipalApprovingOfficer {
  id?: number;
  approvingOfficeId?: number;
  approvingOfficeName?: string;
  userId?: number;
  user?: User;
  createdAt?: Date;
}

export interface NonPrincipalApprovingService {
  id: number;
  approvingOfficeId?: number;
  approvingOfficeName: string;
  serviceId?: number;
  serviceName: string;
  createdById: number;
  createdAt: Date;
}

export interface CreateNonPrincipalApprovingOfficeVM {
  divisionId: number;
  divsionName: string;
  marketId: number;
  marketName: string;
  serviceGroupId: number;
  serviceGroupName: string;
  serviceCategoryId: number;
  serviceCategoryName: string;
  serviceType: number;
  officeName: string;
  officerIds: number[];
  services: CreateNonPrinicipalApprovingServiceVM[];
}

export interface CreateNonPrinicipalApprovingServiceVM {
  serviceId: number;
  serviceName: string;
}

export interface UpdateNonPrincipalApprovingOfficeVM {
  divisionId?: number;
  divsionName?: string;
  marketId?: number;
  marketName?: string;
  serviceGroupId?: number;
  serviceGroupName?: string;
  serviceCategoryId?: number;
  serviceCategoryName?: string;
  serviceType?: number;
  officeName?: string;
  officerIds: number[];
  services: CreateNonPrinicipalApprovingServiceVM[];
}

export interface MoveNonPrincipalApprovingOfficeSequenceVM {
  sequence: number;
  goesUp: boolean;
  serviceType: number;
}
