import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import {
  ArmedEscortDTSDetailGenericDay,
  ArmedEscortDTSMasters,
  CommanderDTSDetailGenericDay,
  CommanderDTSMasters,
  PilotDTSDetailGenericDay,
  PilotDTSMasters,
  VehicleDTSDetailGenericDay,
  VehicleDTSMasters,
} from "src/app/interfaces/armada";
import { CommonResponse } from "src/app/interfaces/home";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class ResourceSchedulingService {
  baseUrl = environment.baseUrl + "DTSMasters/";
  baseUrl_ = environment.baseUrl + "DTSDetailGenericDays/";
  //baseUrl = "https://localhost:44344/api/v1/DTSMasters/";
  //baseUrl_ = "https://localhost:44344/api/v1/DTSDetailGenericDays/";

  constructor(private httpClient: HttpClient) {}

  //DTS Masters
  //Armed Escort
  allArmedEscortMasters(): Observable<CommonResponse> {
    return this.httpClient.get<CommonResponse>(
      this.baseUrl + "GetAllArmedEscortMasters"
    );
  }

  getArmedEscortMaster(id: any): Observable<CommonResponse> {
    return this.httpClient.get<CommonResponse>(
      this.baseUrl + "GetArmedEscortMasterById/" + id
    );
  }
  allArmedEscortMastersByResourceId(id: any): Observable<CommonResponse> {
    return this.httpClient.get<CommonResponse>(
      this.baseUrl + "GetAllArmedEscortMastersByResourceId/" + id
    );
  }

  postArmedEscortMaster(data: any) {
    return this.httpClient.post(this.baseUrl + "AddNewArmedEscortMaster", data);
  }
  deleteArmedEscortMaster(id: any) {
    return this.httpClient.delete(
      this.baseUrl + "DeleteArmedEscortMasterById/" + id
    );
  }
  updateArmedEscortMaster(id: any, data: any) {
    return this.httpClient.put(
      this.baseUrl + "UpdateArmedEscortMasterById/" + id,
      data
    );
  }

  //Commander
  allCommanderMasters(): Observable<CommonResponse> {
    return this.httpClient.get<CommonResponse>(
      this.baseUrl + "GetAllCommanderMasters"
    );
  }

  getCommanderMaster(id: any): Observable<CommonResponse> {
    return this.httpClient.get<CommonResponse>(
      this.baseUrl + "GetCommanderMasterById/" + id
    );
  }

  allCommanderMastersByResourceId(id: any): Observable<CommonResponse> {
    return this.httpClient.get<CommonResponse>(
      this.baseUrl + "GetAllCommanderMastersByResourceId/" + id
    );
  }

  postCommanderMaster(data: any) {
    return this.httpClient.post(this.baseUrl + "AddNewCommanderMaster", data);
  }
  deleteCommanderMaster(id: any) {
    return this.httpClient.delete(
      this.baseUrl + "DeleteCommanderMasterById/" + id
    );
  }
  updateCommanderMaster(id: any, data: any) {
    return this.httpClient.put(
      this.baseUrl + "UpdateCommanderMasterById/" + id,
      data
    );
  }

  //Pilot
  allPilotMasters(): Observable<CommonResponse> {
    return this.httpClient.get<CommonResponse>(
      this.baseUrl + "GetAllPilotMasters"
    );
  }

  getPilotMaster(id: any): Observable<CommonResponse> {
    return this.httpClient.get<CommonResponse>(
      this.baseUrl + "GetPilotMasterById/" + id
    );
  }

  allPilotMastersByResourceId(id: any): Observable<CommonResponse> {
    return this.httpClient.get<CommonResponse>(
      this.baseUrl + "GetAllPilotMastersByResourceId/" + id
    );
  }

  postPilotMaster(data: any) {
    return this.httpClient.post(this.baseUrl + "AddNewPilotMaster", data);
  }
  deletePilotMaster(id: any) {
    return this.httpClient.delete(this.baseUrl + "DeletePilotMasterById/" + id);
  }
  updatePilotMaster(id: any, data: any) {
    return this.httpClient.put(
      this.baseUrl + "UpdatePilotMasterById/" + id,
      data
    );
  }

  //Vehicle
  allVehicleMasters(): Observable<CommonResponse> {
    return this.httpClient.get<CommonResponse>(
      this.baseUrl + "GetAllVehicleMasters"
    );
  }

  getVehicleMaster(id: any): Observable<CommonResponse> {
    return this.httpClient.get<CommonResponse>(
      this.baseUrl + "GetVehicleMasterById/" + id
    );
  }

  allVehicleMastersByResourceId(id: any): Observable<CommonResponse> {
    return this.httpClient.get<CommonResponse>(
      this.baseUrl + "GetAllVehicleMastersByResourceId/" + id
    );
  }

  postVehicleMaster(data: any) {
    return this.httpClient.post(this.baseUrl + "AddNewVehicleMaster", data);
  }
  deleteVehicleMaster(id: any) {
    return this.httpClient.delete(
      this.baseUrl + "DeleteVehicleMasterById/" + id
    );
  }
  updateVehicleMaster(id: any, data: any) {
    return this.httpClient.put(
      this.baseUrl + "UpdateVehicleMasterById/" + id,
      data
    );
  }

  RenewResourceContract(id: any, data: any) {
    return this.httpClient.put(
      this.baseUrl + "RenewResourceContract/" + id,
      data
    );
  }

  //DTS Generic Days
  //Armed Escort
  allArmedEscortGenerics(): Observable<CommonResponse> {
    return this.httpClient.get<CommonResponse>(
      this.baseUrl_ + "GetAllArmedEscortGenerics"
    );
  }

  allArmedEscortGenericsByMasterId(id: any): Observable<CommonResponse> {
    return this.httpClient.get<CommonResponse>(
      this.baseUrl_ + "GetArmedEscortGenericsByMasterId/" + id
    );
  }

  allArmedEscortGenericsByResourceId(id: any): Observable<CommonResponse> {
    return this.httpClient.get<CommonResponse>(
      this.baseUrl_ + "GetArmedEscortGenericsByResourceId/" + id
    );
  }

  getArmedEscortGeneric(id: any): Observable<CommonResponse> {
    return this.httpClient.get<CommonResponse>(
      this.baseUrl_ + "GetArmedEscortGenericsById/" + id
    );
  }

  postArmedEscortGeneric(data: any) {
    return this.httpClient.post(
      this.baseUrl_ + "AddNewArmedEscortGeneric",
      data
    );
  }
  deleteArmedEscortGeneric(id: any) {
    return this.httpClient.delete(
      this.baseUrl_ + "DeleteArmedEscortGenericById/" + id
    );
  }
  updateArmedEscortGeneric(id: any, data: any) {
    return this.httpClient.put(
      this.baseUrl_ + "UpdateArmedEscortGenericById/" + id,
      data
    );
  }

  //Commander
  allCommanderGenerics(): Observable<CommonResponse> {
    return this.httpClient.get<CommonResponse>(
      this.baseUrl_ + "GetAllCommanderGenerics"
    );
  }
  allCommanderGenericsById(id: any): Observable<CommonResponse> {
    return this.httpClient.get<CommonResponse>(
      this.baseUrl_ + "GetCommanderGenericsByMasterId/" + id
    );
  }
  allCommanderGenericsByResourceId(id: any): Observable<CommonResponse> {
    return this.httpClient.get<CommonResponse>(
      this.baseUrl_ + "GetCommanderGenericsByResourceId/" + id
    );
  }
  getCommanderGeneric(id: any): Observable<CommonResponse> {
    return this.httpClient.get<CommonResponse>(
      this.baseUrl_ + "GetCommanderGenericsById/" + id
    );
  }

  postCommanderGeneric(data: any) {
    return this.httpClient.post(this.baseUrl_ + "AddNewCommanderGeneric", data);
  }
  deleteCommanderGeneric(id: any) {
    return this.httpClient.delete(
      this.baseUrl_ + "DeleteCommanderGenericById/" + id
    );
  }
  updateCommanderGeneric(id: any, data: any) {
    return this.httpClient.put(
      this.baseUrl_ + "UpdateCommanderGenericById/" + id,
      data
    );
  }

  //Pilot
  allPilotGenerics(): Observable<CommonResponse> {
    return this.httpClient.get<CommonResponse>(
      this.baseUrl_ + "GetAllPilotGenerics"
    );
  }
  allPilotGenericsById(id: any): Observable<CommonResponse> {
    return this.httpClient.get<CommonResponse>(
      this.baseUrl_ + "GetPilotGenericsByMasterId/" + id
    );
  }
  allPilotGenericsByResourceId(id: any): Observable<CommonResponse> {
    return this.httpClient.get<CommonResponse>(
      this.baseUrl_ + "GetPilotGenericsByResourceId/" + id
    );
  }
  getPilotGeneric(id: any): Observable<CommonResponse> {
    return this.httpClient.get<CommonResponse>(
      this.baseUrl_ + "GetPilotGenericsById/" + id
    );
  }

  postPilotGeneric(data: any) {
    return this.httpClient.post(this.baseUrl_ + "AddNewPilotGeneric", data);
  }
  deletePilotGeneric(id: any) {
    return this.httpClient.delete(
      this.baseUrl_ + "DeletePilotGenericById/" + id
    );
  }
  updatePilotGeneric(id: any, data: any) {
    return this.httpClient.put(
      this.baseUrl_ + "UpdatePilotGenericById/" + id,
      data
    );
  }

  //Vehicle
  allVehicleGenerics(): Observable<CommonResponse> {
    return this.httpClient.get<CommonResponse>(
      this.baseUrl_ + "GetAllVehicleGenerics"
    );
  }
  allVehicleGenericsById(id: any): Observable<CommonResponse> {
    return this.httpClient.get<CommonResponse>(
      this.baseUrl_ + "GetVehicleGenericsByMasterId/" + id
    );
  }
  allVehicleGenericsByResourceId(id: any): Observable<CommonResponse> {
    return this.httpClient.get<CommonResponse>(
      this.baseUrl_ + "GetVehicleGenericsByResourceId/" + id
    );
  }
  getvehicleGeneric(id: any): Observable<CommonResponse> {
    return this.httpClient.get<CommonResponse>(
      this.baseUrl_ + "GetvehicleGenericsById/" + id
    );
  }

  postVehicleGeneric(data: any) {
    return this.httpClient.post(this.baseUrl_ + "AddNewVehicleGeneric", data);
  }
  deleteVehicleGeneric(id: any) {
    return this.httpClient.delete(
      this.baseUrl_ + "DeleteVehicleGenericById/" + id
    );
  }
  updateVehicleGeneric(id: any, data: any) {
    return this.httpClient.put(
      this.baseUrl_ + "UpdateVehicleGenericById/" + id,
      data
    );
  }


}
