import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home-spinner',
  templateUrl: './home-spinner.component.html',
  styleUrls: ['./home-spinner.component.scss']
})
export class HomeSpinnerComponent implements OnInit {

  constructor(http:HttpClient) { }

  ngOnInit(): void {
  }

  
  

}
