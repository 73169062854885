import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/storage';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { startOfWeekWithOptions, startOfWeekYearWithOptions } from 'date-fns/fp';
import { ConfirmationService, MessageService, TreeNode } from 'primeng/api';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { BreadcrumbService } from 'src/app/breadcrumb.service';
import { CommonResponse, Lga, State } from 'src/app/interfaces/home';
import { LGARestingStationMaps, RestingStation } from 'src/app/interfaces/response';
import { InventoryService } from 'src/app/services/inventory.service';
import { RestingStationServiceService } from 'src/app/services/response/resting-station-service.service';
import { StateService } from 'src/app/services/state.service';

@Component({
  selector: 'app-resting-station-lga-map',
  templateUrl: './resting-station-lga-map.component.html',
  styleUrls: ['./resting-station-lga-map.component.scss']
})
export class RestingStationLgaMapComponent implements OnInit {

  @ViewChild("formWrapper") public formWrapper: ElementRef;
  private unsubscriber$ = new Subject<void>();
  
  public targetCols: any;
  private exportTargetColumns: any;
  public submittingData: boolean;
  public loading: boolean;
  public submitButtonLabel: string;
    rowGroupMetadata: any;
 //public selectedMultiVehicles: RestingStation[] = [];

  public lGARestingStationMaps:LGARestingStationMaps[];
  public selectedlGARestingStationMaps:LGARestingStationMaps;
  public selectedlGARestingStationMapsTree:TreeNode[];
  editingRestingStationMap:boolean;
  edLGARestingStation:LGARestingStationMaps;
 public lGaRestingStationMapForm: FormGroup;
  public restingStations:RestingStation[];
  public selectedRestingStation:RestingStation;
   public selectedMultiLGAs: Lga[] = [];
   selectedRouteTree:TreeNode[];
 allStates: State[];
 selectedState:State;
  allLGAs: Lga[];
  state:State;
  selectedLGA: Lga;
  constructor(private breadcrumbService: BreadcrumbService,
    public messageService: MessageService,
    public confirmationService: ConfirmationService,
    public fireStorage: AngularFireStorage,
    public inventoryService: InventoryService,
    private formBuilder: FormBuilder,
    private restingStationService: RestingStationServiceService,
    public stateService: StateService,
    ) 
    {this.breadcrumbService.setItems([
      { label: "Resting Station" },
      {
        label: "Station Coverage",
        routerLink: ["/home/response/resting-station-lga-map"],
      },
    ]); }

  ngOnInit(): void {
     this.targetCols = [
      { field: "restingStationId", header: "restingStationId" },
      { field: "localGovtId", header: "localGovtId" },
    
    ];
    this.exportTargetColumns = this.targetCols.map((col) => ({
      title: col.header,
      dataKey: col.field,
    }));
    this.lGaRestingStationMapForm = this.formBuilder.group({
      restingStationId: [null],
      localGovtId: [null, [Validators.required]],
      state: ["", [Validators.required]],
    });
    this.fetchAllStates();
    this.fetchAllRestingStations();
   this.fetchAllLGARestingStationMaps();
  }

   fetchAllStates() {
    this.stateService
      .allState()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe((r: CommonResponse) => {
        if (r.responseCode == "00") {
          var res = r.responseData;
          this.allStates = res;
          this.loading=false;
          console.log(res);
        }
         else {
            this.messageService.add({
              severity: "error",
              summary: "Failed",
              detail: " Error: " + r.responseMsg,
            });
            this.submittingData = false;
          }
      });
  }
   fetchAllRestingStations() {
    this.loading=true;
    this.restingStationService
      .allRestingStations()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe((r: CommonResponse) => {
        if (r.responseCode == "00") {
          var res = r.responseData;
          this.restingStations = res;
          this.loading=false;
          console.log(res);
        }
         else {
            this.messageService.add({
              severity: "error",
              summary: "Failed",
              detail: " Error: " + r.responseMsg,
            });
            this.loading=false;
            this.submittingData = false;
          }
      });
  }
  fetchAllLGARestingStationMaps() {
    this.loading=true;
    this.restingStationService
      .allLocalGovtRestingStationMaps()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe((r: CommonResponse) => {
        if (r.responseCode == "00") {
          var res = r.responseData;
          this.lGARestingStationMaps = res;
          //this.PopulateRouteTree();
          this.loading=false;
          console.log(res);
        }
         else {
            this.messageService.add({
              severity: "error",
              summary: "Failed",
              detail: " Error: " + r.responseMsg,
            });
            this.loading=false;
            this.submittingData = false;
          }
      });
  }
    fetchStatesById(id) {
    this.stateService
      .getByID(id)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe((r: CommonResponse) => {
        if (r.responseCode == "00") {
          var res = r.responseData;
          this.state = res;
          this.allLGAs = this.state.lgAs
          this.loading=false;
          console.log(this.state.lgAs);
        }
         else {
            this.messageService.add({
              severity: "error",
              summary: "Failed",
              detail: " Error: " + r.responseMsg,
            });
            this.submittingData = false;
          }
      });
  }
   createLGARestingStationMap() {
    this.submittingData = true;
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Creating record...",
    });
    const formData = this.lGaRestingStationMapForm.value;
    const postData ={
      restingStationId: this.selectedRestingStation.id,
      //restingStationId: formData.restingStationId,
      localGovtId: this.selectedMultiLGAs.map((x) => x.id),
    }
    this.restingStationService
      .postLocalGovtRestingStationMap(postData)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            this.messageService.add({
              summary: "Success",
              severity: "success",
              detail: "New mapping created",
            });
            this.fetchAllLGARestingStationMaps();
            this.submittingData = false;
            this.lGaRestingStationMapForm.reset();
          } else {
            this.messageService.add({
              severity: "error",
              summary: "Failed",
              detail: " Error: " + r.responseMsg,
            });
            this.submittingData = false;
          }
        },
        (error) => {
          this.messageService.add({
            severity: "error",
            summary: "Failed",
            detail: "Connection Error " + error.error.message,
          });
          this.submittingData = false;
        }
      );
  }
  deleteLGARestingStationMap(restingStation: LGARestingStationMaps): void {
    this.confirmationService.confirm({
      message: "Are you sure you want to delete mapping?",
      accept: () => {
        this._deleteLGARestingStationMap(restingStation);
      },
    });
  }
   _deleteLGARestingStationMap(restingStation) {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Deleting mapping record",
    });
    this.restingStationService.deleteLocalGovtRestingStationMap(restingStation.id).subscribe(
      async (result: any) => {
        await this.messageService.add({
          severity: "success",
          summary: "Deleted",
          detail: "Station record removed",
        });
        await this.fetchAllLGARestingStationMaps();
      },
      (error) => {
        this.connectionError();
      }
    );
  }
   onSort() {
    this.updateRowGroupMetaData();
  }

  updateRowGroupMetaData() {
    this.rowGroupMetadata = {};
    
    if (this.lGARestingStationMaps) {
      for (let i = 0; i < this.lGARestingStationMaps.length; i++) {
        const rowData = this.lGARestingStationMaps[i];
        const representativeName = rowData.restingStation.stationName;

        if (i === 0) {
          this.rowGroupMetadata[representativeName] = { index: 0, size: 1 };
        } else {
          const previousRowData = this.lGARestingStationMaps[i - 1];
          const previousRowGroup = previousRowData.restingStation.stationName;
          if (representativeName === previousRowGroup) {
            this.rowGroupMetadata[representativeName].size++;
          } else {
            this.rowGroupMetadata[representativeName] = { index: i, size: 1 };
          }
        }
      }
    }
    
  }
   setLGAs() {
    this.fetchStatesById(this.selectedState.id);
   // console.log(this.allLGAs);
    
  }
   PopulateRouteTree() {
    this.loading = true;
    //this.lGARestingStationMaps = [];
    const centralStoresTree = [];
    //this.loadingPriceRegisters = false;

    this.lGARestingStationMaps.forEach((stations) => {
      const RuotesTree = [];
      // this.armedEscortProfiles.forEach((profileStore) => {
      centralStoresTree.push({
          label: stations.restingStation.stationName,
          key: stations.id,
          
          data: stations.restingStation,
          children: RuotesTree,
          selectable: false,
        });
    });

    this.selectedlGARestingStationMapsTree = [
      {
        //label: "Armed Escort",
        label: "stations",
        key: "Route Folder",
       
        expanded: true,
        children: centralStoresTree,
        data: 1001,
        selectable: false,
      },
    ];
    this.loading = false;
  }
  // editRestingStation(restingStation: RestingStation): void {
  //   this.editingRestingStation = true;
  //   this.formWrapper.nativeElement.scrollIntoView({
  //     behavior: "smooth",
  //     block: "end",
  //     inline: "start",
  //   });
  //   this.submitButtonLabel = "Update";
  //   this.edRestingStation = restingStation;
  //   this.restingStationForm.setValue({
  //     stationDescription: restingStation.stationDescription,
  //     stationName: restingStation.stationName,
  //     stateId: this.selectedState.id,
  //     lgaId: this.selectedLGA.id,
  //     street: restingStation.street,
  //     address: restingStation.address,
  //     latitude: restingStation.latitude,
  //     longitude: restingStation.longitude,
  //     imageUrl: "",
  //   });
  //   // this.selectedVehicleType = this.vehicleTypes.find(
  //   //   (Type) => Type.id === vehicleProfiles.vehicleTypeId
  //   // );
  // }

  updateLGARestingStationMap(): boolean {
    this.submittingData = true;
    if (!this.edLGARestingStation) {
      return false;
    }
    const formData = this.lGaRestingStationMapForm.value;
     const data ={
      restingStationId: this.selectedRestingStation.id,
      localGovtId: this.selectedLGA.id,
    }
    this.restingStationService
      .updateRestingStation(this.edLGARestingStation.id, data)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            this.messageService.add({
              severity: "success",
              detail: "Mapping Updated",
              summary: "Completed",
            });
            this.submittingData = false;
            this.closeEditing();
            this.fetchAllLGARestingStationMaps();
            this.lGaRestingStationMapForm.reset();
          }
        },
        (error) => {
          this.connectionError();
          this.submittingData = false;
        }
      );
  }
    
   private connectionError() {
    this.messageService.add({
      severity: "error",
      summary: "Failed",
      detail: "Connection Error, Please try again",
    });
  }

   closeEditing() {
    this.editingRestingStationMap = false;
    this.lGaRestingStationMapForm.reset();
    //this.edTeamType = null;

    this.submittingData = false;
    this.submitButtonLabel = "Create";
  }

}
