import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import {
  CommonResponse,
  RequiredServiceDocument,
} from "../../../interfaces/home";
import { ConfirmationService, MessageService } from "primeng/api";
import { RequiredServiceDocumentService } from "../../../services/required-service-document.service";
import { BreadcrumbService } from "src/app/breadcrumb.service";

@Component({
  selector: "app-required-service-document",
  templateUrl: "./required-service-document.component.html",
  styleUrls: ["./required-service-document.component.scss"],
  providers: [ConfirmationService, MessageService],
})
export class RequiredServiceDocumentComponent implements OnInit {
  @ViewChild("formWrapper") public formWrapper: ElementRef;
  fetchingRequiredServiceDocument = true;
  requiredServiceDocumentForm: FormGroup;
  requiredServiceDocuments: RequiredServiceDocument[];
  selectedRequiredServiceDocument: RequiredServiceDocument;
  editingRequiredServiceDocument = false;
  documentTypes: { field: string; value: string }[];
  selectedType: { field: string; value: string };
  public requiredServiceDocumentCols: any[];
  public exportRequiredServiceDocumentColumns: any[];
  private edRequiredServiceDocument: RequiredServiceDocument;
  constructor(
    private formBuilder: FormBuilder,
    private requiredServiceDocumentService: RequiredServiceDocumentService,
    public messageService: MessageService,
    public confirmationService: ConfirmationService,
    private breadcrumbService: BreadcrumbService
  ) {}

  ngOnInit(): void {
    this.breadcrumbService.setItems([
      { label: "Setup", routerLink: ["home/required-service-document"] },
      { label: "Services", routerLink: ["home/required-service-document"] },
      {
        label: "Required Service Documents",
        routerLink: ["home/required-service-document"],
      },
    ]);
    this.fetchRequiredServiceDocument();
    this.requiredServiceDocumentForm = this.formBuilder.group({
      description: ["", Validators.required],
      caption: ["", [Validators.required]],
      type: ["", [Validators.required]],
    });
    this.documentTypes = [
      { field: "Capture", value: "capture" },
      { field: "Negotiation", value: "negotiation" },
      { field: "Closure", value: "closure" },
    ];
    this.requiredServiceDocumentCols = [
      { field: "caption", header: "Caption" },
      { field: "description", header: "Description" },
      { field: "type", header: "Type" },
    ];
    this.exportRequiredServiceDocumentColumns =
      this.requiredServiceDocumentCols.map((col) => ({
        title: col.header,
        dataKey: col.field,
      }));
  }
  fetchRequiredServiceDocument() {
    this.requiredServiceDocumentService.allRequiredServiceDocument().subscribe(
      async (r: CommonResponse) => {
        if (r.responseCode == "00") {
          this.requiredServiceDocuments = r.responseData;
          this.fetchingRequiredServiceDocument = false;
        }
      },
      (error) => {
        this.fetchingRequiredServiceDocument = false;
        this.connectionError();
      }
    );
  }
  private connectionError() {
    this.messageService.add({
      severity: "error",
      summary: "Failed",
      detail: "Connection Error, Please try again",
    });
  }
  createRequiredServiceDocument() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Creating new Required Service Document Records",
    });
    const postData = {
      description: this.requiredServiceDocumentForm.get("description").value,
      caption: this.requiredServiceDocumentForm.get("caption").value,
      type: this.requiredServiceDocumentForm.get("type").value.value,
    };
    this.requiredServiceDocumentService
      .postRequiredServiceDocument(postData)
      .subscribe(
        async (r: CommonResponse) => {
          if (r.responseCode == "00") {
            await this.messageService.add({
              severity: "success",
              summary: "Completed",
              detail: "New Required Service Document Created",
            });
            await this.fetchRequiredServiceDocument();
            await this.requiredServiceDocumentForm.reset();
          } else {
            this.messageService.add({
              severity: "error",
              summary: "Failure",
              detail: r.responseMsg,
            });
          }
        },
        (error) => {
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail:
              "Unable to create new Required Service Document at the moment",
          });
        }
      );
  }

  deleteRequiredServiceDocument(requiredServiceDocument) {
    this.confirmationService.confirm({
      message:
        "Are you sure you want to delete " + requiredServiceDocument.name,
      accept: () => {
        this._deleteRequiredServiceDocument(requiredServiceDocument);
      },
    });
  }
  _deleteRequiredServiceDocument(requiredServiceDocument) {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Deleting required Service Document record",
    });
    this.requiredServiceDocumentService
      .deleteRequiredServiceDocument(requiredServiceDocument.id)
      .subscribe(
        async (result: any) => {
          await this.messageService.add({
            severity: "success",
            summary: "Deleted",
            detail: "Required Service Document record removed",
          });
          await this.fetchRequiredServiceDocument();
        },
        (error) => {
          this.connectionError();
        }
      );
  }
  editRequiredServiceDocument(requiredServiceDocuments) {
    this.editingRequiredServiceDocument = true;
    this.requiredServiceDocumentForm.addControl(
      "requiredServiceDocumentId",
      new FormControl({ value: "", disabled: true }, Validators.required)
    );
    this.edRequiredServiceDocument = requiredServiceDocuments;
    this.requiredServiceDocumentForm.patchValue({
      caption: requiredServiceDocuments.caption,
      description: requiredServiceDocuments.description,
      requiredServiceDocumentId: requiredServiceDocuments.id,
    });

    (this.selectedType = this.documentTypes.find(
      (doc) => doc.value == requiredServiceDocuments.type
    )),
      // this.requiredServiceDocumentFormIn.nativeElement.focus();
      // document.getElementById('formFields').focus();
      // this.selectedHeadId = this.users.find(user => user.id === requiredServiceDocuments.head.id);
      this.formWrapper.nativeElement.scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "start",
      });
  }

  updateRequiredServiceDocument() {
    // update the selected requiredServiceDocument
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Updating Required Service Document details",
    });
    const id = this.edRequiredServiceDocument.id;
    const postData = {
      caption: this.requiredServiceDocumentForm.get("caption").value,
      description: this.requiredServiceDocumentForm.get("description").value,
      type: this.requiredServiceDocumentForm.get("type").value.value,
    };
    this.requiredServiceDocumentService
      .updateRequiredServiceDocument(id, postData)
      .subscribe(async (result: CommonResponse) => {
        if (result.responseCode == "00") {
          this.messageService.add({
            severity: "success",
            summary: "Notice",
            detail: "Required Service Document details Updated",
          });
          await this.fetchRequiredServiceDocument();
          await this.closeEditing();
        }
      });
  }

  closeEditing() {
    this.editingRequiredServiceDocument = false;
    this.requiredServiceDocumentForm.reset();
    this.edRequiredServiceDocument = null;
  }
}
