import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../environments/environment";
import { Observable } from "rxjs";
import {
  CloseOutProfomaInvoiceVM,
  CommonResponse,
  ConfigureInvoiceAutoSendVM,
  Contract,
  GenerateProfomaInvoiceVM,
  GetAllUnMappedInvoicesAndCreditNotesView,
  GetGeneratedProfomaInvoiceDetailView,
  GetInvoicesAggregatedDitsVM,
  Invoice,
  InvoiceEvidenceSubmission,
  InvoiceValidForReplacementVM,
  PerformInvoiceReplacementVM,
  ProfomaInvoiceView,
  ReverseGroupInvoiceVM,
  SaveInvoiceEvidenceSubmissionVM,
  UpdateInvoiceInfoVM,
} from "../interfaces/home";

@Injectable({
  providedIn: "root",
})
export class InvoiceService {
  constructor(private http: HttpClient) {}
  baseUrl = environment.baseUrl + "Invoice/";
  //baseUrl = "https://localhost:44344/api/v1/Invoice/";
  // fetch all Invoice in the database
  allInvoices(): Observable<Invoice> {
    return this.http.get<Invoice>(this.baseUrl);
  }
  getInvoice(id: any): Observable<CommonResponse<Invoice>> {
    return this.http.get<CommonResponse<Invoice>>(this.baseUrl + id);
  }
  getInvoicesByContractDivisionId(
    contractDivisionId: number
  ): Observable<CommonResponse> {
    return this.http.get<CommonResponse>(
      this.baseUrl + "ContractDivision/" + contractDivisionId
    );
  }
  getProformaInvoicesByContractDivisionId(
    contractDivisionId: number
  ): Observable<CommonResponse> {
    return this.http.get<CommonResponse>(
      this.baseUrl + "Proforma/ContractDivision/" + contractDivisionId
    );
  }

  getGroupInvoiceSendDateByContractId(
    contractId: number
  ): Observable<CommonResponse> {
    return this.http.get<CommonResponse>(
      this.baseUrl + "GetGroupInvoiceSendDateByContractId/" + contractId
    );
  }
  getInvoiceByName(name: any): Observable<Invoice> {
    return this.http.get<Invoice>(this.baseUrl + "name/" + name);
  }

  getInvoiceDetails(invoiceId: number): Observable<CommonResponse> {
    return this.http.get<CommonResponse>(
      this.baseUrl + "GetInvoiceDetails/" + invoiceId
    );
  }

  sendInvoice(invoiceId: number): Observable<CommonResponse> {
    return this.http.get<CommonResponse>(
      this.baseUrl + "SendInvoice/" + invoiceId
    );
  }
  postAdHocInvoice(data: any) {
    return this.http.post(this.baseUrl + "AdHocInvoice/", data);
  }

  postGroupAdHocInvoice(data: any) {
    return this.http.post(this.baseUrl + "GroupAdHocInvoice/", data);
  }
  deleteInvoice(id: any) {
    return this.http.delete(this.baseUrl + id);
  }
  updateInvoice(id: any, data: any) {
    return this.http.put(this.baseUrl + id, data);
  }
  converToFinalInvoice(data: any) {
    return this.http.put(this.baseUrl + "ConvertToFinalInvoice", data);
  }

  removeProformaInvoice(id: any, data: any) {
    return this.http.put(this.baseUrl + "RemoveProformaInvoice/" + id, data);
  }

  GetContractServiceInvoicesGrouped(
    contractServiceId: number
  ): Observable<CommonResponse> {
    return this.http.get<CommonResponse>(
      this.baseUrl + "GetContractServiceInvoicesGrouped/" + contractServiceId
    );
  }

  ReverseGroupInvoice(data: ReverseGroupInvoiceVM): Observable<CommonResponse> {
    return this.http.post<CommonResponse>(
      this.baseUrl + "RevereGroupInvoice",
      data
    );
  }

  GetAllInvoicesTiedToContractService(
    contractServiceId: number
  ): Observable<CommonResponse<Invoice[]>> {
    return this.http.get<CommonResponse<Invoice[]>>(
      this.baseUrl + "GetAllInvoicesTiedToContractService/" + contractServiceId
    );
  }

  UpdateInvoiceInfo(
    invoiceId: number,
    data: UpdateInvoiceInfoVM
  ): Observable<CommonResponse> {
    return this.http.put<CommonResponse>(
      this.baseUrl + "UpdateInvoiceInfo/" + invoiceId,
      data
    );
  }

  GetInvoicesAggregatedDits(
    invoiceId: number
  ): Observable<CommonResponse<GetInvoicesAggregatedDitsVM>> {
    return this.http.get<CommonResponse<GetInvoicesAggregatedDitsVM>>(
      this.baseUrl + "GetInvoicesAggregatedDits/" + invoiceId
    );
  }

  ResetTransmissionById(journalHeaderId: number): Observable<CommonResponse> {
    return this.http.get<CommonResponse>(
      this.baseUrl + "ResetTransmissionById/" + journalHeaderId
    );
  }

  GetInvoiceValidForReplacementByContractId(
    contractId: number
  ): Observable<CommonResponse<InvoiceValidForReplacementVM[]>> {
    return this.http.get<CommonResponse<InvoiceValidForReplacementVM[]>>(
      this.baseUrl + "GetInvoiceValidForReplacementByContractId/" + contractId
    );
  }

  PerformInvoiceReplacement(
    data: PerformInvoiceReplacementVM
  ): Observable<CommonResponse> {
    return this.http.post<CommonResponse>(
      this.baseUrl + "PerformInvoiceReplacement",
      data
    );
  }

  GetInvoiceEvidenceSubmission(
    invoiceId: number
  ): Observable<CommonResponse<InvoiceEvidenceSubmission>> {
    return this.http.get<CommonResponse<InvoiceEvidenceSubmission>>(
      this.baseUrl + "GetInvoiceEvidenceSubmission/" + invoiceId
    );
  }

  SaveInvoiceEvidenceSubmission(
    data: SaveInvoiceEvidenceSubmissionVM
  ): Observable<CommonResponse> {
    return this.http.post<CommonResponse>(
      this.baseUrl + "SaveInvoiceEvidenceSubmission",
      data
    );
  }

  GetProfomaInvoicesByContractId(
    contractId: number
  ): Observable<CommonResponse<ProfomaInvoiceView[]>> {
    return this.http.get<CommonResponse<ProfomaInvoiceView[]>>(
      this.baseUrl + "GetProfomaInvoicesByContractId/" + contractId
    );
  }

  GenerateProfomaInvoice(
    data: GenerateProfomaInvoiceVM
  ): Observable<CommonResponse> {
    return this.http.post<CommonResponse>(
      this.baseUrl + "GenerateProfomaInvoice",
      data
    );
  }

  RemoveGenerateProfomaInvoices(
    transactionId: string
  ): Observable<CommonResponse> {
    return this.http.delete<CommonResponse>(
      this.baseUrl + "RemoveGenerateProfomaInvoices/" + transactionId
    );
  }

  GetGeneratedProfomaInvoiceDetail(
    transactionId: string
  ): Observable<CommonResponse<GetGeneratedProfomaInvoiceDetailView>> {
    return this.http.get<CommonResponse<GetGeneratedProfomaInvoiceDetailView>>(
      this.baseUrl + "GetGeneratedProfomaInvoiceDetail/" + transactionId
    );
  }

  SendProfomaInvoice(transactionId: string): Observable<CommonResponse> {
    return this.http.get<CommonResponse>(
      this.baseUrl + "SendProfomaInvoice/" + transactionId
    );
  }

  GetAllUnClosedProfomaInvoices(): Observable<
    CommonResponse<ProfomaInvoiceView[]>
  > {
    return this.http.get<CommonResponse<ProfomaInvoiceView[]>>(
      this.baseUrl + "GetAllUnClosedProfomaInvoices"
    );
  }

  GetAllUnMappedInvoicesAndCreditNotes(
    clientId: number
  ): Observable<CommonResponse<GetAllUnMappedInvoicesAndCreditNotesView>> {
    return this.http.get<
      CommonResponse<GetAllUnMappedInvoicesAndCreditNotesView>
    >(this.baseUrl + "GetAllUnMappedInvoicesAndCreditNotes/" + clientId);
  }

  CloseOutProfomaInvoice(
    data: CloseOutProfomaInvoiceVM
  ): Observable<CommonResponse> {
    return this.http.post<CommonResponse>(
      this.baseUrl + "CloseOutProfomaInvoice",
      data
    );
  }

  ConfigureInvoiceAutoSend(
    data: ConfigureInvoiceAutoSendVM
  ): Observable<CommonResponse> {
    return this.http.post<CommonResponse>(
      this.baseUrl + "ConfigureInvoiceAutoSend",
      data
    );
  }

  GetClientInvoiceAutoSendConfigs(
    customerDivisionId: number
  ): Observable<CommonResponse<Contract[]>> {
    return this.http.get<CommonResponse<Contract[]>>(
      this.baseUrl + "GetClientInvoiceAutoSendConfigs/" + customerDivisionId
    );
  }
}
