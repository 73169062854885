import {
  CreatePlatformServiceVM,
  CreateServiceBenefitVM,
  UpdateServiceBenefitVM,
} from "./../interfaces/platform-benefit";
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import {
  Benefit,
  CreateBenefitVM,
  CreatePlatformVM,
  Platform,
  UpdateBenefitVM,
  UpdatePlatformVM,
} from "../interfaces/platform-benefit";
import { Services } from "../interfaces/home";

@Injectable({
  providedIn: "root",
})
export class PlatformBenefitService {
  baseUrl = environment.gmaBaseUrl + "PlatformBenefit/";

  constructor(private http: HttpClient) {}

  GetAllPlatforms(): Observable<Platform[]> {
    return this.http.get<Platform[]>(this.baseUrl + "GetAllPlatforms");
  }

  CreatePlatform(data: CreatePlatformVM) {
    return this.http.post(this.baseUrl + "CreatePlatform", data);
  }

  UpdatePlatform(id: number, data: UpdatePlatformVM) {
    return this.http.put(this.baseUrl + "UpdatePlatform/" + id, data);
  }

  DeletePlatform(id: number) {
    return this.http.delete(this.baseUrl + "DeletePlatform/" + id);
  }

  SwitchPlatformStatus(id: number) {
    return this.http.get(this.baseUrl + "SwitchPlatformStatus/" + id);
  }

  GetAllBenefits(): Observable<Benefit[]> {
    return this.http.get<Benefit[]>(this.baseUrl + "GetAllBenefits");
  }

  CreateBenefit(data: CreateBenefitVM) {
    return this.http.post(this.baseUrl + "CreateBenefit", data);
  }

  UpdateBenefit(id: number, data: UpdateBenefitVM) {
    return this.http.put(this.baseUrl + "UpdateBenefit/" + id, data);
  }

  DeleteBenefit(id: number) {
    return this.http.delete(this.baseUrl + "DeleteBenefit/" + id);
  }

  GetAllPlatformsWithServices(): Observable<Platform[]> {
    return this.http.get<Platform[]>(
      this.baseUrl + "GetAllPlatformsWithServices"
    );
  }

  CreatePlatformService(data: CreatePlatformServiceVM) {
    return this.http.post(this.baseUrl + "CreatePlatformService", data);
  }

  DeletePlatformService(id: number) {
    return this.http.delete(this.baseUrl + "DeletePlatformService/" + id);
  }

  GetAllServicesWithBenefits(): Observable<Services[]> {
    return this.http.get<Services[]>(
      this.baseUrl + "GetAllServicesWithBenefits"
    );
  }

  CreateServiceBenefit(data: CreateServiceBenefitVM) {
    return this.http.post(this.baseUrl + "CreateServiceBenefit", data);
  }

  UpdateServiceBenefit(id: number, data: UpdateServiceBenefitVM) {
    return this.http.put(this.baseUrl + "UpdateServiceBenefit/" + id, data);
  }

  DeleteServiceBenefit(id: number) {
    return this.http.delete(this.baseUrl + "DeleteServiceBenefit/" + id);
  }
}
