import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../environments/environment";
import { Observable } from "rxjs";
import {
  AccountMaster,
  AddPostingVM,
  CommonResponse,
  DtrackJournalHeader,
  ItemGroupConsumption,
  ProcessBulkSettlementVM,
  ProcessCreditNoteReversalVM,
  UnUsedAdvancePaymentView,
} from "../interfaces/home";

@Injectable({
  providedIn: "root",
})
export class AccountMasterService {
  constructor(private http: HttpClient) {}
  baseUrl = environment.baseUrl + "AccountMaster/";
  // fetch all Account Master in the database
  allAccountMasterData(): Observable<CommonResponse> {
    return this.http.get<CommonResponse>(this.baseUrl);
  }

  getAccountMaster(id: any): Observable<CommonResponse<AccountMaster>> {
    return this.http.get<CommonResponse<AccountMaster>>(this.baseUrl + id);
  }

  getAccountMasterByName(name: any): Observable<CommonResponse> {
    return this.http.get<CommonResponse>(this.baseUrl + "name/" + name);
  }

  postAccountMaster(data: any) {
    return this.http.post(this.baseUrl, data);
  }

  getAccountMasterByTransactionId(transactionId: any) {
    return this.http.get(
      this.baseUrl + "GetAccountMasterByTransactionId/" + transactionId
    );
  }

  getCreditNotes(customerDivisionId) {
    return this.http.get(this.baseUrl + "GetCreditNotes/" + customerDivisionId);
  }

  getNoteDetail(accountMasterId) {
    return this.http.get(this.baseUrl + "GetNoteDetails/" + accountMasterId);
  }

  getDebitNote(customerDivisionId) {
    return this.http.get(this.baseUrl + "GetDebitNotes/" + customerDivisionId);
  }

  getAccountMasterByCustomerId(data: any) {
    return this.http.post(
      this.baseUrl + "GetAccountMasterByCustomerIdAndContractYears",
      data
    );
  }

  getAccountMasterByVoucherType(startDate, endDate, voucherTypeIds) {
    return this.http.get(
      this.baseUrl +
        `?StartDate=${startDate}&EndDate=${endDate}${voucherTypeIds}`
    );
  }

  deleteAccountMaster(id: any) {
    return this.http.delete(this.baseUrl + id);
  }

  updateAccountMaster(id: any, data: any) {
    return this.http.put(this.baseUrl + id, data);
  }

  GetAccountMasterByTransactionIdString(data: {
    transactionId: string;
  }): Observable<CommonResponse<AccountMaster[]>> {
    return this.http.post<CommonResponse<AccountMaster[]>>(
      this.baseUrl + "GetAccountMasterByTransactionIdString",
      data
    );
  }

  ReversePosting(accountMasterId): Observable<CommonResponse> {
    return this.http.get<CommonResponse>(
      this.baseUrl + "ReversePosting/" + accountMasterId
    );
  }

  AddPosting(data: AddPostingVM): Observable<CommonResponse> {
    return this.http.post<CommonResponse>(this.baseUrl + "AddPosting", data);
  }

  GetAllUnusedCreditNotes(
    skip: number,
    take: number
  ): Observable<CommonResponse<UnUsedAdvancePaymentView[]>> {
    return this.http.get<CommonResponse<UnUsedAdvancePaymentView[]>>(
      this.baseUrl + "GetAllUnusedCreditNotes/" + skip + "/" + take
    );
  }

  ProcessBulkSettlement(
    data: ProcessBulkSettlementVM
  ): Observable<CommonResponse> {
    return this.http.post<CommonResponse>(
      this.baseUrl + "ProcessBulkSettlement",
      data
    );
  }

  GetUnReversedCreditNotes(
    customerDivisionId: number
  ): Observable<CommonResponse<AccountMaster[]>> {
    return this.http.get<CommonResponse<AccountMaster[]>>(
      this.baseUrl + "GetUnReversedCreditNotes/" + customerDivisionId
    );
  }

  ProcessCreditNoteReversal(
    data: ProcessCreditNoteReversalVM
  ): Observable<CommonResponse> {
    return this.http.post<CommonResponse>(
      this.baseUrl + "ProcessCreditNoteReversal",
      data
    );
  }

  GetCreditNotesNotSubmitted(
    customerDivisionId: number
  ): Observable<CommonResponse<AccountMaster[]>> {
    return this.http.get<CommonResponse<AccountMaster[]>>(
      this.baseUrl + "GetCreditNotesNotSubmitted/" + customerDivisionId
    );
  }

  GetFailedDtrackTransmissions(): Observable<
    CommonResponse<DtrackJournalHeader[]>
  > {
    return this.http.get<CommonResponse<DtrackJournalHeader[]>>(
      this.baseUrl + "GetFailedDtrackTransmissions"
    );
  }

  ResetDtrackTransmission(journalHeaderId: number): Observable<CommonResponse> {
    return this.http.get<CommonResponse>(
      this.baseUrl + "ResetDtrackTransmission/" + journalHeaderId
    );
  }

  GetUnpostedItemConsumptionGroup(): Observable<
    CommonResponse<ItemGroupConsumption[]>
  > {
    return this.http.get<CommonResponse<ItemGroupConsumption[]>>(
      this.baseUrl + "GetUnpostedItemConsumptionGroup"
    );
  }

  PostItemGroupConsumption(
    itemGroupConsumptionId: number
  ): Observable<CommonResponse> {
    return this.http.get<CommonResponse>(
      this.baseUrl + "PostItemGroupConsumption/" + itemGroupConsumptionId
    );
  }
}
