import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { Message, ConfirmationService, MessageService } from "primeng/api";
import { BreadcrumbService } from "src/app/breadcrumb.service";
import { DeployedGuard } from "src/app/interfaces/employment";
import { CustomerDivision } from "src/app/interfaces/home";
import {
  SpecialDeduction,
  KeyValuePairTempVM,
  CalendarMonthEnum,
  GetSpecialDeductionRequestDitsVM,
  SpecialDeductionRequestBatch,
  SpecialDeductionRequest,
  CreateSpecialDeductionBatchVM,
} from "src/app/interfaces/payroll";
import { PayrollService } from "src/app/services/payroll.service";

@Component({
  selector: "app-payroll-special-request2",
  templateUrl: "./payroll-special-request2.component.html",
  styleUrls: ["./payroll-special-request2.component.scss"],
})
export class PayrollSpecialRequest2Component implements OnInit {
  @ViewChild("formWrapper") public formWrapper: ElementRef;
  deductionRequestForm: FormGroup;
  bulkRequestForm: FormGroup;
  cautionForm: FormGroup;
  allClients: CustomerDivision[];
  theClient: CustomerDivision;
  allMonths: {
    key: number;
    value: string;
  }[];
  theMonth: {
    key: number;
    value: string;
  };
  allSpecialDeductions: SpecialDeduction[];
  theSpecialDeduction: SpecialDeduction;
  theBulkSpecialDeduction: SpecialDeduction;
  allOperatives: DeployedGuard[];
  selectedOperatives: DeployedGuard[];
  allYears: KeyValuePairTempVM[];
  theYear: KeyValuePairTempVM;
  fetchingSingleEntryBatch: boolean = true;
  allSingleEntryBatchInfo: SpecialDeductionRequest[] = [];
  selectedSingleEntryBatchInfo: SpecialDeductionRequest[] = [];
  alloweeCols: any[];
  msg: Message[] = [];
  batchNo: string = "N/A";
  batchFigure: number = 0;
  openCautionDialogue: boolean;
  cautionText: string;
  cautionCode: number;

  constructor(
    private fb: FormBuilder,
    private payrollService: PayrollService,
    private breadcrumbService: BreadcrumbService,
    public confirmationService: ConfirmationService,
    public messageService: MessageService
  ) {
    this.deductionRequestForm = fb.group({
      Client: ["", Validators.required],
      Month: ["", Validators.required],
      NoOfDays: ["", Validators.required],
      Operatives: ["", Validators.required],
      Year: ["", Validators.required],
      Rate: ["", Validators.required],
      Deductions: ["", Validators.required],
    });

    this.bulkRequestForm = fb.group({
      Allowances: ["", Validators.required],
    });

    this.cautionForm = fb.group({});
  }

  ngOnInit(): void {
    this.breadcrumbService.setItems([
      {
        label: "Payroll Management",
        routerLink: ["/home/payroll/payroll-settings"],
      },
      {
        label: "Special Deduction Request",
        routerLink: ["/home/payroll/special-request2"],
      },
    ]);

    this.alloweeCols = [
      { field: "operativeName", header: "OperativeName" },
      { field: "operativeId", header: "OperativeId" },
      { field: "clientName", header: "ClientName" },
      { field: "applicableMonth", header: "ApplicableMonth" },
      { field: "noOfDays", header: "NoOfDays" },
      { field: "applicableRate", header: "Amount" },
      { field: "totalValue", header: "Total" },
    ];

    this.allMonths = [
      {
        key: CalendarMonthEnum.January,
        value: "January",
      },
      {
        key: CalendarMonthEnum.February,
        value: "February",
      },
      {
        key: CalendarMonthEnum.March,
        value: "March",
      },
      {
        key: CalendarMonthEnum.April,
        value: "April",
      },
      {
        key: CalendarMonthEnum.May,
        value: "May",
      },
      {
        key: CalendarMonthEnum.June,
        value: "June",
      },
      {
        key: CalendarMonthEnum.July,
        value: "July",
      },
      {
        key: CalendarMonthEnum.August,
        value: "August",
      },
      {
        key: CalendarMonthEnum.September,
        value: "September",
      },
      {
        key: CalendarMonthEnum.October,
        value: "October",
      },
      {
        key: CalendarMonthEnum.November,
        value: "November",
      },
      {
        key: CalendarMonthEnum.December,
        value: "December",
      },
    ];

    this.ResetMessageToasters();
    this.FetchPageData();
    this.FetchCurrentOvertimeBatch();
  }

  ResetMessageToasters() {
    this.msg = [];
    this.msg.push({
      severity: "info",
      summary: "Notice:",
      detail:
        "Total Deduction Value for batch " +
        this.batchNo +
        ": N" +
        this.batchFigure.toLocaleString(),
    });
  }

  FetchPageData() {
    this.payrollService.GetSpecialDeductionRequestDits().subscribe(
      async (data) => {
        if (data.responseCode != "00") {
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail: data.responeMessage,
          });

          this.ResetMessageToasters();
          return;
        }
        var result = data.responseObject as GetSpecialDeductionRequestDitsVM;

        this.allClients = result.clients;
        this.allSpecialDeductions = result.applicableDeductions;
        this.allYears = result.applicableYears;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable get all page data at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
        this.ResetMessageToasters();
      }
    );
  }

  FetchCurrentOvertimeBatch() {
    this.payrollService.GetCurrentSpecialDeductionBatch().subscribe(
      async (data) => {
        if (data.responseCode != "00") {
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail: data.responeMessage,
          });

          this.fetchingSingleEntryBatch = false;
          this.ResetMessageToasters();
          return;
        }
        var result = data.responseObject as SpecialDeductionRequestBatch;

        this.batchNo = result ? result.batchCode : "N/A";
        this.allSingleEntryBatchInfo = result
          ? result.requests.filter((x) => x.isDeleted == false)
          : [];
        this.fetchingSingleEntryBatch = false;
        let totatFig = 0;
        this.allSingleEntryBatchInfo.forEach((x) => (totatFig += x.totalValue));
        this.batchFigure = totatFig;
        this.ResetMessageToasters();
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable get all ongoing overtime batch request at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
        this.fetchingSingleEntryBatch = false;
        this.ResetMessageToasters();
      }
    );
  }

  CreateDeducteeRequest() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Creating Special Deduction Batch Request(s)...",
    });
    this.ResetMessageToasters();

    const postData: CreateSpecialDeductionBatchVM = {
      clientId: this.theClient.id,
      clientName: this.theClient.divisionName,
      applicableMonth: this.theMonth.key,
      noOfDays: this.deductionRequestForm.get("NoOfDays").value,
      applicableDeductionId: this.theSpecialDeduction.id,
      applicableDeductionName: this.theSpecialDeduction.caption,
      applicableYear: this.theYear.keyDate,
      applicableRate: this.deductionRequestForm.get("Rate").value,
      operatives: [],
    };

    this.selectedOperatives.forEach((operative) => {
      postData.operatives.push({
        key: operative.id,
        value:
          operative.jobApplication.personalInformation.lastname +
          " " +
          operative.jobApplication.personalInformation.firstname,
        keyDate: null,
      });
    });

    this.payrollService.CreateSpecialDeductionBatch(postData).subscribe(
      async (data) => {
        if (data.responseCode != "00") {
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail: data.responeMessage,
          });

          this.ResetMessageToasters();
          return;
        }

        this.messageService.add({
          severity: "success",
          summary: "Completed",
          detail: "Special Deduction Request Created Successfully!",
        });
        this.ResetMessageToasters();

        this.deductionRequestForm.reset();
        this.theClient = null;
        this.theMonth = null;
        this.theBulkSpecialDeduction = null;
        this.theYear = null;
        this.fetchingSingleEntryBatch = true;
        this.FetchCurrentOvertimeBatch();
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to create special deduction request at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
        this.ResetMessageToasters();
      }
    );
  }

  OnClientChanged() {
    this.allOperatives = [];
    this.selectedOperatives = [];

    if (this.theClient) {
      this.payrollService.GetClientOperatives(this.theClient.id).subscribe(
        async (data) => {
          if (data.responseCode != "00") {
            this.messageService.add({
              severity: "error",
              summary: "Notice",
              detail: data.responeMessage,
            });

            this.ResetMessageToasters();
            return;
          }
          var result = data.responseObject as DeployedGuard[];
          this.allOperatives = result;
        },
        (error) => {
          console.log("Error: " + JSON.stringify(error));
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail:
              "Unable get client operatives at the moment.. Reason: [" +
              (error ? error.error.message : "request failed - permission") +
              "]",
          });
          this.ResetMessageToasters();
        }
      );
    }
  }

  OnMonthChanged() {}

  OnADeducteeChanged() {}

  OnYearChanged() {}

  DeleteDeducteeRequest(item: SpecialDeductionRequest) {
    this.confirmationService.confirm({
      message: "Are you sure you want to remove request?",
      accept: () => {
        this.messageService.add({
          severity: "info",
          summary: "Notice",
          detail: "Removing request...",
        });
        this.ResetMessageToasters();

        this.payrollService.DeleteSpecialDeductionRequest(item.id).subscribe(
          async (data) => {
            if (data.responseCode != "00") {
              this.messageService.add({
                severity: "error",
                summary: "Notice",
                detail: data.responeMessage,
              });

              this.ResetMessageToasters();
              return;
            }

            this.messageService.add({
              severity: "success",
              summary: "Removed",
              detail: "Removed successfully",
            });
            this.ResetMessageToasters();

            this.fetchingSingleEntryBatch = true;
            const index = this.allSingleEntryBatchInfo.indexOf(item);
            if (index > -1) {
              this.allSingleEntryBatchInfo.splice(index, 1);
            }
            this.fetchingSingleEntryBatch = false;
          },
          (error) => {
            console.log("Error: " + JSON.stringify(error));
            this.messageService.add({
              severity: "error",
              summary: "Notice",
              detail:
                "Unable to remove reuqest at the moment.. Reason: [" + error
                  ? error.error.message
                  : "request failed - permission" + "]",
            });
            this.ResetMessageToasters();
          }
        );
      },
    });
  }

  SendBatchForApproval() {
    this.cautionText =
      "Are you sure you want to send this batch for approval. This is an irreversible action. Do you still wish to proceed?";
    this.cautionCode = 1;
    this.openCautionDialogue = true;
  }

  CreateBulkRequest() {}

  OnBulkAlloweeChanged() {}

  onBasicUpload(event) {
    this.messageService.add({
      severity: "info",
      summary: "Success",
      detail: "File Uploaded with Basic Mode",
    });
  }

  HideCautionDialog() {
    this.openCautionDialogue = false;
    this.cautionCode = null;
    this.cautionText = null;
  }

  CautionAction() {
    if (this.cautionCode == 1) {
      this.messageService.add({
        severity: "info",
        summary: "Notice",
        detail: "Sending Batch For Approval...",
      });
      this.ResetMessageToasters();

      this.payrollService.SendSpecialDeductionForApproval().subscribe(
        async (data) => {
          if (data.responseCode != "00") {
            this.messageService.add({
              severity: "error",
              summary: "Notice",
              detail: data.responeMessage,
            });

            this.ResetMessageToasters();
            this.HideCautionDialog();
            return;
          }

          this.messageService.add({
            severity: "success",
            summary: "Completed",
            detail: "Batch Sent For Approval Successfully!",
          });

          this.ResetMessageToasters();
          this.HideCautionDialog();

          this.batchFigure = 0;
          this.batchNo = "N/A";
          this.allSingleEntryBatchInfo = [];
          this.FetchCurrentOvertimeBatch();
        },
        (error) => {
          console.log("Error: " + JSON.stringify(error));
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail:
              "Unable to send batch for approval at the moment.. Reason: [" +
              error
                ? error.error.message
                : "request failed - permission" + "]",
          });
          this.ResetMessageToasters();
          this.HideCautionDialog();
        }
      );
    }
  }
}
