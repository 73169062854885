import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MessageService, ConfirmationService } from "primeng/api";
import { BreadcrumbService } from "src/app/breadcrumb.service";
import {
  AccountDetail,
  AccountMaster,
  CommonResponse,
  CustomerDivision,
} from "src/app/interfaces/home";
import { AccountMasterService } from "src/app/services/account-master";
import { AccountVoucherTypeService } from "src/app/services/account-voucher-type.service";
import { CustomerDivisionService } from "src/app/services/customer-division.service";

@Component({
  selector: "app-reverse-voucher",
  templateUrl: "./reverse-voucher.component.html",
  styleUrls: ["./reverse-voucher.component.scss"],
})
export class ReverseVoucherComponent implements OnInit {
  @ViewChild("formWrapper") public formWrapper: ElementRef;
  clientForm: FormGroup;
  transactionForm: FormGroup;
  allClients: CustomerDivision[];
  theClient: CustomerDivision;
  fetchingAccountMasters: boolean;
  accountMasters: AccountMaster[];
  selectedAccountMaster: AccountMaster[];
  accountMasterCols: any[];
  accountDetailCols: any[];
  fetchingAccountDetails: boolean;
  transactionDetails: AccountDetail[];
  selectedAccountDetail: AccountDetail[];
  showTransactions: boolean;
  showDetails: boolean;

  constructor(
    public formBuilder: FormBuilder,
    public messageService: MessageService,
    public customerDivisionService: CustomerDivisionService,
    public accountVoucherTypeService: AccountVoucherTypeService,
    public accountMasterService: AccountMasterService,
    public confirmationService: ConfirmationService,
    private breadcrumbService: BreadcrumbService
  ) {
    this.clientForm = this.formBuilder.group({
      Client: [null, Validators.required],
      ContractYear: [null, Validators.required],
    });

    this.transactionForm = this.formBuilder.group({
      TransactionId: ["", Validators.required],
    });
  }

  ngOnInit(): void {
    this.breadcrumbService.setItems([
      { label: "Finance", routerLink: ["home"] },
      {
        label: "Reverse Voucher",
        routerLink: ["home/reverse-voucher"],
      },
    ]);

    this.accountMasterCols = [
      { field: "id", header: "Id" },
      { field: "voucherType", header: "Voucher Type Id" },
      { field: "value", header: "Value" },
      { field: "transactionId", header: "Transaction Id" },
      { field: "description", header: "Description" },
      { field: "integrationFlag", header: "Integration Flag" },
    ];

    this.accountDetailCols = [
      { field: "id", header: "Transaction ID" },
      { field: "date", header: "Transaction Date" },
      { field: "description", header: "Description" },
      { field: "dr", header: "Dr" },
      { field: "cr", header: "Cr" },
    ];

    this.FetchAllClients();
  }

  FetchAllClients() {
    this.customerDivisionService.allCustomerDivision().subscribe(
      async (res: CommonResponse) => {
        if (res.responseCode != "00") {
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail: res.responseMsg,
          });
          return;
        }

        this.allClients = res.responseData;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to get all clients at the moment.. Reason: [" +
            error.error.message +
            "]",
        });
      }
    );
  }

  SearchByClient() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Searching ..",
    });

    this.accountMasters = [];
    this.transactionDetails = [];
    this.fetchingAccountMasters = true;
    this.showTransactions = true;
    this.showDetails = false;

    const postData = {
      clientId: this.theClient.id,
      years: [this.clientForm.get("ContractYear").value],
      isSalesVoucherOnly: true,
    };

    this.accountMasterService.getAccountMasterByCustomerId(postData).subscribe(
      async (r: CommonResponse) => {
        if (r.responseCode != "00") {
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail: r.responseMsg,
          });
          this.fetchingAccountMasters = false;
          return;
        }

        this.messageService.add({
          severity: "success",
          summary: "Completed",
          detail: "Search Successful.",
        });
        this.accountMasters = r.responseData;
        this.fetchingAccountMasters = false;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to search by clients at the moment.. Reason: [" +
            error.error.message +
            "]",
        });
        this.fetchingAccountMasters = false;
      }
    );
  }

  SearchByTransaction() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Searching ..",
    });

    this.accountMasters = [];
    this.transactionDetails = [];
    this.fetchingAccountMasters = true;
    this.showTransactions = true;
    this.showDetails = false;

    const transactionId = this.transactionForm.get("TransactionId").value;

    this.accountMasterService
      .GetAccountMasterByTransactionIdString({ transactionId })
      .subscribe(
        async (data: CommonResponse) => {
          if (data.responseCode != "00") {
            this.messageService.add({
              severity: "error",
              summary: "Notice",
              detail: data.responseMsg,
            });
            this.fetchingAccountMasters = false;
            return;
          }

          this.messageService.add({
            severity: "success",
            summary: "Completed",
            detail: "Search Successful.",
          });
          this.accountMasters = data.responseData;
          this.fetchingAccountMasters = false;
        },
        (error) => {
          console.log("Error: " + JSON.stringify(error));
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail:
              "Unable to search by transaction at the moment.. Reason: [" +
              error.error.message +
              "]",
          });
          this.fetchingAccountMasters = false;
        }
      );
  }

  ViewDetails(item: AccountMaster) {
    this.showDetails = true;
    this.fetchingAccountDetails = true;
    this.transactionDetails = item.accountDetails;
    this.fetchingAccountDetails = false;
    this.formWrapper.nativeElement.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "start",
    });
  }

  ReversePosting(item: AccountMaster) {
    this.confirmationService.confirm({
      message:
        "Are you sure you want to reverse this voucher, Kindly note that all postings tied to this voucher will be reversed?",
      accept: () => {
        this.messageService.add({
          severity: "info",
          summary: "Notice",
          detail: "Reversing Voucher......",
        });

        this.accountMasterService.ReversePosting(item.id).subscribe(
          async (res: CommonResponse) => {
            if (res.responseCode == "00") {
              this.messageService.add({
                severity: "success",
                summary: "Removed",
                detail: "Voucher Reversed successfully",
              });
            } else {
              this.messageService.add({
                severity: "error",
                summary: "Failure",
                detail:
                  "Err: Could not reverse voucher. Reason -> " +
                  res.responseMsg,
              });
            }
          },
          (error) => {
            console.log("Error: " + JSON.stringify(error));
            this.messageService.add({
              severity: "error",
              summary: "Notice",
              detail:
                "Unable to reverse voucher at the moment.. Reason: [" +
                error.error.message +
                "]",
            });
          }
        );
      },
    });
  }
}
