import { HandlerRating } from "./../interfaces/complaint";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import {
  ComplaintHandling,
  ComplaintHandlingStats,
  ComplaintTrackingData,
  EscalationLevelDetails,
  MoveComplaintToNextStage,
} from "../interfaces/complaint";
import { CommonResponse } from "../interfaces/home";

@Injectable({
  providedIn: "root",
})
export class ComplaintHandlingService {
  baseUrl = environment.baseUrl + "ComplaintHandling/";

  constructor(private http: HttpClient) {}

  getStats(): Observable<CommonResponse> {
    return this.http.get<CommonResponse>(
      this.baseUrl + "GetComplaintHandlingStats"
    );
  }

  getComplaintsToHandle(): Observable<CommonResponse> {
    return this.http.get<CommonResponse>(this.baseUrl);
  }

  pickComplaint(complaintId: number) {
    return this.http.post(this.baseUrl, {
      complaintId: complaintId,
    });
  }

  moveToNextStage(data: MoveComplaintToNextStage) {
    return this.http.post(this.baseUrl + "MoveComplaintToNextStage", data);
  }

  trackComplaint(
    trackingID?: string,
    complaintId?: number
  ): Observable<CommonResponse> {
    return this.http.post<CommonResponse>(
      this.baseUrl + "TrackComplaint",
      {
        trackingNo: trackingID,
        complaintId: complaintId,
      }
    );
  }

  confirmComplaintResolved(complaintId: number) {
    return this.http.get(
      this.baseUrl + "ConfirmComplaintResolved/" + complaintId
    );
  }

  getUserEscalationLevelDetails(): Observable<CommonResponse> {
    return this.http.get<CommonResponse>(
      this.baseUrl + "GetUserEscalationLevelDetails"
    );
  }

  assignComplaint(userProfileID: number, complaintID: number) {
    return this.http.post(this.baseUrl + "AssignComplaintToUser", {
      UserId: userProfileID,
      ComplaintId: complaintID,
    });
  }

  miniTrackComplaint(complaintId): Observable<CommonResponse> {
    return this.http.get<CommonResponse>(
      this.baseUrl + "TrackComplaint/" + complaintId
    );
  }

  getHandlersRatings(handlerIds: number[]): Observable<CommonResponse> {
    return this.http.post<CommonResponse>(this.baseUrl + "HandlersRating", {
      handlersIDs: handlerIds,
    });
  }
}
