import { ArmadaModule } from "./armada/armada.module";
import { TreeTableModule } from "primeng/treetable";
import { ChipsModule } from "primeng/chips";
import { ChipModule } from "primeng/chip";
import { CarouselModule } from "primeng/carousel";
import { StepsModule } from "primeng/steps";
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import {
  CommonModule,
  CurrencyPipe,
  DatePipe,
  NgSwitch,
} from "@angular/common";

import { HomeRoutingModule } from "./home-routing.module";
import { ProgressBarModule } from "primeng/progressbar";
import { ButtonModule } from "primeng/button";
import { RippleModule } from "primeng/ripple";
import { TabViewModule } from "primeng/tabview";
import { InputTextModule } from "primeng/inputtext";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HomeComponent } from "./home.component";
import { AccountComponent } from "./account/account.component";
import { BranchOfficeComponent } from "./setups/branch-office/branch-office.component";
import { LeadAdminModule } from "./lead-admin/lead-admin.module";
import { DropdownModule } from "primeng/dropdown";
import { TableModule } from "primeng/table";
import { ToastModule } from "primeng/toast";
import { SpinnerModule } from "primeng/spinner";
import { ProgressSpinnerModule } from "primeng/progressspinner";
import { DialogModule } from "primeng/dialog";
import { LoadingComponent } from "../components/loading/loading.component";
import { TooltipModule } from "primeng/tooltip";
import { ConfirmDialogModule } from "primeng/confirmdialog";
import { DivisionComponent } from "./setups/division/division.component";
import { DivisionOpSetupComponent } from "./setups/division-op-setup/division-op-setup.component";
import { OperatingEntityComponent } from "./setups/operating-entity/operating-entity.component";
import { ServiceGroupComponent } from "./setups/service-group/service-group.component";
import { ServiceCategoryComponent } from "./setups/service-category/service-category.component";
import { ServiceCategoryTaskComponent } from "./service-setup/service-category-task/service-category-task.component";
import { QualificationModule } from "./qualification/qualification.module";
import { SBUComponent } from "./setups/sbu/sbu.component";
import { TargetComponent } from "./setups/target/target.component";
import { BankComponent } from "./setups/bank/bank.component";
import { InputSwitchModule } from "primeng/inputswitch";
import { RadioButtonModule } from "primeng/radiobutton";
import { StandardSlaOperatingComponent } from "./setups/standard-sla-operating/standard-sla-operating.component";
import { FileUploadModule } from "primeng/fileupload";
import { RequiredServiceDocumentComponent } from "./service-setup/required-service-document/required-service-document.component";
import { RequiredServiceQualElementComponent } from "./service-setup/required-service-qual-element/required-service-qual-element.component";
import { ServiceTaskDeliverableComponent } from "./service-setup/service-task-deliverable/service-task-deliverable.component";
import { ServiceTypeComponent } from "./service-setup/service-type/service-type.component";
import { ServiceComponent } from "./service-setup/service/service.component";
import { ServiceFormComponent } from "./service-setup/service/service-form/service-form.component";
import { RoleComponent } from "./role/role.component";
import { CardModule } from "primeng/card";
import { MultiSelectModule } from "primeng/multiselect";
import { TreeModule } from "primeng/tree";
import { KeyFilterModule } from "primeng/keyfilter";
import { ContactComponent } from "../components/contact/contact.component";
import { ProjectManagementComponent } from "./project-management/project-management.component";
import { PanelModule } from "primeng/panel";
import { ChartModule } from "primeng/chart";
import { BadgeModule } from "primeng/badge";

import {
  DialogService,
  DynamicDialogConfig,
  DynamicDialogRef,
} from "primeng/dynamicdialog";
import { DeliverableFormComponent } from "./project-management/deliverable-form/deliverable-form.component";
import { FieldsetModule } from "primeng/fieldset";
import { CalendarModule } from "primeng/calendar";
import { PickListModule } from "primeng/picklist";
import { ApprovalsComponent } from "./approvals/approvals.component";
import { TabMenuModule } from "primeng/tabmenu";

import { AccountStatementComponent } from "./finance/account-statement/account-statement.component";
import { AccountsChartComponent } from "./finance/account-chart/account-chart.component";
import { AccountTransactionMdComponent } from "./finance/account-transaction-md/account.transaction-md.component";
import { UserDeliverableComponent } from "./project-management/user-deliverable/user-deliverable.component";
import { DeliverableInfoComponent } from "./project-management/deliverable-info/deliverable-info.component";
import { ChartWidgetComponent } from "../components/chart-widget/chart-widget.component";
import { PickDeliverableComponent } from "./project-management/pick-deliverable/pick-deliverable.component";
import { InputTextareaModule } from "primeng/inputtextarea";
import { CloseDeliverableComponent } from "./project-management/close-deliverable/close-deliverable.component";

import { SbuUsersComponent } from "./setups/sbu-users/sbu-users.component";
import { AttachUserSbuComponent } from "./setups/sbu-users/attach-user-sbu/attach-user-sbu.component";
import { ReceiptingComponent } from "./finance/receipting/receipting.component";
import { ClientViewComponent } from "./client-management/client-view/client-view.component";
import { ApprovalSetupComponent } from "./setups/approval-setup/approval-setup.component";
import { AutoCompleteModule } from "primeng/autocomplete";
import { ApproveDeliverableComponent } from "./project-management/approve-deliverable/approve-deliverable.component";
import { InvoicesComponent } from "./client-management/client-view/invoices/invoices.component";
import { KeyPersonnelComponent } from "./client-management/client-view/key-personnel/key-personnel.component";
import { RatingModule } from "primeng/rating";
import { ClientEndorsementComponent } from "./client-management/client-endorsement/client-endorsement.component";
import { InvoiceCreationComponent } from "./client-management/invoice-creation/invoice-creation.component";
import { BeneficiaryComponent } from "./client-management/beneficiary/beneficiary.component";
import { AddDependentBeneficiaryComponent } from "./client-management/beneficiary/add-dependent-beneficiary/add-dependent-beneficiary.component";
import { CorporateAdjustmentSetupComponent } from "./setups/corporate-adjustment-setup/corporate-adjustment-setup.component";
import { InputNumberModule } from "primeng/inputnumber";
import { ViewQuoteserviceDetailsComponent } from "./approvals/view-quoteservice-details/view-quoteservice-details.component";
import { TaskDetailsComponent } from "./client-management/client-view/task-details/task-details.component";
import { DeliverableDetailsComponent } from "./client-management/client-view/deliverable-details/deliverable-details.component";
import { ReportingComponent } from "./reporting/ssrs/reporting.component";
import { ReportViewComponent } from "./reporting/report-view/report-view.component";
import { DragDropModule } from "primeng/dragdrop";
import { ViewInvoiceComponent } from "./client-management/invoice-creation/view-invoice/view-invoice.component";
import { RoleclaimAttributesComponent } from "./role/roleclaim-attributes/roleclaim-attributes.component";
import { HumanizePipe } from "../custom-pipes/humanize";
import { TimeoutComponent } from "../components/timeout/timeout.component";
import { ServicePricingComponent } from "./service-setup/service-pricing/service-pricing.component";
import { ViewContractServiceComponent } from "./armada/mobility-administration/service-request/view-contract-service/view-contract-service.component";
import { ViewDivisionComponent } from "./armada/mobility-administration/service-request/view-division/view-division.component";
import { EndJorneyComponent } from "../components/end-jorney/end-jorney.component";
import { StartJourneyComponent } from "./armada/mobility-administration/start-journey/start-journey.component";
import { SupplierComponent } from "./supplier/supplier.component";
import { SupplierCategoryComponent } from "./supplier/supplier-category/supplier-category.component";
import { SupplierServiceComponent } from "./supplier/supplier-service/supplier-service.component";
import { ModeoftransportComponent } from "./modeoftransport/modeoftransport.component";
import { ComplaintOriginComponent } from "./complaint-origin/complaint-origin.component";
import { ComplaintTypeComponent } from "./complaint-origin/complaint-type/complaint-type.component";
import { EscalationLevelComponent } from "./complaint-origin/escalation-level/escalation-level.component";
import { ProfileEscalationLevelComponent } from "./complaint-origin/profile-escalation-level/profile-escalation-level.component";
import { ComplaintSetupComponent } from "./setups/complaint-setup/complaint-setup.component";
import { RegisterComplaintsComponent } from "./register-complaints/register-complaints.component";
import { ComplaintSourceComponent } from "./complaint-origin/complaint-source/complaint-source.component";
import { EscalationMatrixComponent } from "./complaint-origin/escalation-matrix/escalation-matrix.component";
import { ComplaintsHandlingComponent } from "./register-complaints/complaints-handling/complaints-handling.component";
import { RealEscalationMatrixComponent } from "./complaint-origin/real-escalation-matrix/real-escalation-matrix.component";
import { ComplaintTrackingComponent } from "./register-complaints/complaint-tracking/complaint-tracking.component";
import { CaptureJourneyStopComponent } from "./armada/mobility-administration/capture-journey-stop/capture-journey-stop.component";
import { CaptureSituationReportComponent } from "./armada/mobility-administration/capture-situation-report/capture-situation-report.component";
import { EscalationManagementComponent } from "./register-complaints/escalation-management/escalation-management.component";
import { AttachClientToRMComponent } from "./client-management/attach-client-to-rm/attach-client-to-rm.component";
import { ClientPolicyComponent } from "./client-management/client-policy/client-policy.component";
import { ClientListingComponent } from "./client-management/client-listing/client-listing.component";
import { CheckboxModule } from "primeng/checkbox";
import { SliderModule } from "primeng/slider";
import { PassengerComponent } from "./armada/mobility-administration/service-request/passenger/passenger.component";
import { SplitterModule } from "primeng/splitter";
import { ReceiptBreakdownComponent } from "./finance/receipting/receipt-breakdown/receipt-breakdown/receipt-breakdown.component";
import { RedoProjectManagementComponent } from "./project-management/redo-project-management/redo-project-management.component";
import { InventoryManagementComponent } from "./inventory-management/inventory-management.component";
import { InventoryStoreComponent } from "./inventory-management/inventory-store/inventory-store.component";
import { InventoryStockComponent } from "./inventory-management/inventory-stock/inventory-stock.component";
import { BatchApprovalComponent } from "./inventory-management/batch-approval/batch-approval.component";
import { BatchConfirmationComponent } from "./inventory-management/batch-confirmation/batch-confirmation.component";
import { StockPolicyComponent } from "./inventory-management/stock-policy/stock-policy.component";
import { RegionalRequestComponent } from "./inventory-management/regional-request/regional-request.component";
import { UserRequestComponent } from "./inventory-management/user-request/user-request.component";
import { RequestAuditComponent } from "./inventory-management/request-audit/request-audit.component";
import { NewLeadDashboardComponent } from "./new-lead-admin/new-lead-dashboard/new-lead-dashboard.component";
import { DataViewModule } from "primeng/dataview";
import { AdminPriceComponent } from "./client-management/client-endorsement/admin-price/admin-price.component";
import { GuardItemSettingsComponent } from "./gma/guard-item-settings/guard-item-settings.component";
import { GmaCreateJobComponent } from "./gma/gma-create-job/gma-create-job.component";
import { GmaJobListingComponent } from "./gma/gma-job-listing/gma-job-listing.component";
import { InventoryDashboardComponent } from "./inventory-management/inventory-dashboard/inventory-dashboard.component";
import { GmaSetupComponent } from "./gma/gma-setup/gma-setup.component";
import { GmaSettingsComponent } from "./gma/gma-settings/gma-settings.component";
import { GmaManageJobComponent } from "./gma/gma-manage-job/gma-manage-job.component";
import { GmaTrainingSchoolComponent } from "./gma/gma-training-school/gma-training-school.component";
import { GmaApprovalComponent } from "./gma/gma-approval/gma-approval.component";
import { ClientLocationComponent } from "./client-management/client-location/client-location.component";
import { GmaMaterialRequestComponent } from "./gma/gma-material-request/gma-material-request.component";
import { BeatTypeSetupComponent } from "./client-management/beat-type-setup/beat-type-setup.component";
import { BeatCreationComponent } from "./client-management/beat-creation/beat-creation.component";
import { GmaOrgStructureComponent } from "./gma/gma-org-structure/gma-org-structure.component";
import { LocationChecklistSetupComponent } from "./client-management/location-checklist-setup/location-checklist-setup.component";
import { EndJourneyMessageComponent } from "./armada/mobility-administration/end-journey-message/end-journey-message.component";
import { JourneyManagementPlanComponent } from "./armada/mobility-administration/service-request/journey-management-plan/journey-management-plan.component";
import { CreditDebitNoteComponent } from "./client-management/client-endorsement/credit-debit-note/credit-debit-note.component";
import { ViewAccountStatementComponent } from "./finance/account-statement/view-account-statement/view-account-statement.component";
import { GmaRedeploymentComponent } from "./gma/gma-redeployment/gma-redeployment.component";
import { GmaZonalMapViewComponent } from "./gma/gma-zonal-map-view/gma-zonal-map-view.component";
import { SuspectCaptureComponent } from "./prospects/suspect-capture/suspect-capture.component";
import { SuspectQualificationComponent } from "./prospects/suspect-qualification/suspect-qualification.component";
import { GroupSelectionComponent } from "./role/group-selection/group-selection.component";
import { GmaBeatAllocationComponent } from "./gma/gma-beat-allocation/gma-beat-allocation.component";
import { GmaClusterReportingComponent } from "./gma/gma-cluster-reporting/gma-cluster-reporting.component";
import { GmaSupervisionPolicyComponent } from "./gma/gma-supervision-policy/gma-supervision-policy.component";
import { GmaMaterialDistributionComponent } from "./gma/gma-material-distribution/gma-material-distribution.component";
import { GmaOperativeManagementComponent } from "./gma/gma-operative-management/gma-operative-management.component";
import { GmaConfirmDistributionComponent } from "./gma/gma-confirm-distribution/gma-confirm-distribution.component";
import { GmaBioRecaptureComponent } from "./gma/gma-bio-recapture/gma-bio-recapture.component";
import { MessageModule } from "primeng/message";
import { PayrollRunsComponent } from "./payroll/payroll-runs/payroll-runs.component";
import { PayrollAbsenceAppComponent } from "./payroll/payroll-absence-app/payroll-absence-app.component";
import { PayrollAbsenceReqComponent } from "./payroll/payroll-absence-req/payroll-absence-req.component";
import { PayrollOperativeAttachmentComponent } from "./payroll/payroll-operative-attachment/payroll-operative-attachment.component";
import { PayrollPayGradeComponent } from "./payroll/payroll-pay-grade/payroll-pay-grade.component";
import { PayrollPayGroupComponent } from "./payroll/payroll-pay-group/payroll-pay-group.component";
import { PayrollSettingsComponent } from "./payroll/payroll-settings/payroll-settings.component";
import { PayrollTaxSettingsComponent } from "./payroll/payroll-tax-settings/payroll-tax-settings.component";
import { PayrollManagementComponent } from "./payroll/payroll-management/payroll-management.component";
import { PayrollApprovalComponent } from "./payroll/payroll-approval/payroll-approval.component";
import { ContactsComponent } from "./contacts/contacts.component";
import { InputMaskModule } from "primeng/inputmask";
import { ConfirmationService } from "primeng/api";
import { ProjectAllocationService } from "./project-allocation/project-allocation.service";
import { NodeService } from "../demo/service/nodeservice";
import { ProductService } from "../demo/service/productservice";
import { HomeSpinnerComponent } from "./home-spinner/home-spinner.component";
import { ContactsService } from "./contacts/contacts.service";
import { LeadEngagementsComponent } from "./lead-engagements/lead-engagements.component";
import { LeadEngagementsService } from "./lead-engagements/lead-engagements.service";
import { AccordionModule } from "primeng/accordion";
import { EngagementDashboardComponent } from "./engagement-dashboard/engagement-dashboard.component";
import { EngagementContactsComponent } from "./engagement-contacts/engagement-contacts.component";
import { EngagementActivitiesComponent } from "./engagement-activities/engagement-activities.component";
import { EngagementContactsService } from "./engagement-contacts/engagement-contacts.service";
import { AvatarModule } from "primeng/avatar";
import { AvatarGroupModule } from "primeng/avatargroup";
import { EngagementActivitiesService } from "./engagement-activities/engagement-activities.service";

import { EventService } from "../demo/service/eventservice";
import listPlugin from "@fullcalendar/list";
import resourceTimelinePlugin from "@fullcalendar/resource-timeline";
import { adapterFactory } from "angular-calendar/date-adapters/date-fns";
import { ToggleButtonModule } from "primeng/togglebutton";
import { EngagementServiceService } from "./engagement-dashboard/engagement-service.service";
import { QualificationsComponent } from "./qualifications/qualifications.component";
import { QualificationsService } from "./qualifications/qualifications.service";
import { OpportunitiesComponent } from "./opportunities/opportunities.component";
import { KnobModule } from "primeng/knob";
import { OpportunitiesService } from "./opportunities/opportunities.service";
import { DeliverableDashboardComponent } from "./workspace-management/deliverable-dashboard/deliverable-dashboard.component";
import { DeliverableDashboardService } from "./workspace-management/deliverable-dashboard/deliverable-dashboard.service";
//import { FullCalendarModule } from "primeng/fullcalendar";

//import { ContactsComponent } from "./contacts/contacts.component";
import { PayrollApprovalSetupComponent } from "./payroll/payroll-approval-setup/payroll-approval-setup.component";
import { MakeSettingsComponent } from "./supplier/make-settings/make-settings.component";
import { ModelSettingsComponent } from "./supplier/model-settings/model-settings.component";
import { PayrollVsContractComponent } from "./payroll/payroll-vs-contract/payroll-vs-contract.component";
import { GmaMaterialRefreshComponent } from "./gma/gma-material-refresh/gma-material-refresh.component";
import { GmaDashboardComponent } from "./gma/gma-dashboard/gma-dashboard.component";
import { SalesDashboardComponent } from "./sales-dashboard/sales-dashboard.component";
import { SalesDashboardService } from "./sales-dashboard/sales-dashboard.service";
import { GmaGuarantorComponent } from "./gma/gma-guarantor/gma-guarantor.component";
import { GmaMaterialReplacementComponent } from "./gma/gma-material-replacement/gma-material-replacement.component";
import { GmaTrainingScheduleComponent } from "./gma/gma-training-schedule/gma-training-schedule.component";
import { FullCalendarModule } from "primeng/fullcalendar";
import { GmaFacilitatorComponent } from "./gma/gma-facilitator/gma-facilitator.component";
import { JourneyEndFeedbackComponent } from "./armada/setup/journey-end-feedback/journey-end-feedback.component";
import { SbuInfoComponent } from "./client-management/client-endorsement/sbu-info/sbu-info.component";
import { SbuContractServiceProportionService } from "../services/sbu-contract-service-proportion.service";
import { GmaSanctionsComponent } from "./gma/gma-sanctions/gma-sanctions.component";
import { GmaApplicantListingComponent } from "./gma/gma-applicant-listing/gma-applicant-listing.component";
//import { JoEndComponent } from "./armada/setup/jo-end/jo-end.component";
import { JoEndComponent } from "./armada/setup/jo-end/jo-end.component";
import { ConvergenceCaptureEventComponent } from "./convergence/convergence-capture-event/convergence-capture-event.component";
import { CompanyComponent } from "./setups/company/company.component";
import { EngagementLeadQualificationComponent } from "./engagement-lead-qualification/engagement-lead-qualification.component";
//import { JoEndComponent } from "./armada/setup/jo-end/jo-end.component";
import { GmaTrainingFacilityComponent } from "./gma/gma-training-facility/gma-training-facility.component";
import { PayrollPayeStateReportComponent } from "./payroll/payroll-paye-state-report/payroll-paye-state-report.component";
import { PayrollPaidVsQtyComponent } from "./payroll/payroll-paid-vs-qty/payroll-paid-vs-qty.component";
import { PayrollSpecialSetupComponent } from "./payroll/payroll-special-setup/payroll-special-setup.component";
import { SendMailComponent } from "./send-mail/send-mail.component";
import { ToolbarModule } from "primeng/toolbar";
import { SendMailService } from "./send-mail/send-mail.service";
import { ApprovalSetup2Component } from "./setups/approval-setup2/approval-setup2.component";
import { PayrollApproval2Component } from "./payroll/payroll-approval2/payroll-approval2.component";
import { PayrollSpecialReqApprovalComponent } from "./payroll/payroll-special-req-approval/payroll-special-req-approval.component";
import { PayrollSpecialRequestComponent } from "./payroll/payroll-special-request/payroll-special-request.component";
import { PayrollSpecialRequest2Component } from "./payroll/payroll-special-request2/payroll-special-request2.component";
import { InvStockPositionViewComponent } from "./inventory-management/inv-stock-position-view/inv-stock-position-view.component";
import { OrganizationOrganogramComponent } from "./organization/organization-organogram/organization-organogram.component";
import { OrganizationLineManagerApprovalComponent } from "./organization/organization-line-manager-approval/organization-line-manager-approval.component";
import { InvStockReceiptComponent } from "./inventory-management/inv-stock-receipt/inv-stock-receipt.component";
import { ClientInfoWidgetComponent } from "./client-info-widget/client-info-widget.component";
import { AdvancePaymentComponent } from "./finance/advance-payment/advance-payment.component";
import { InvStockIssueComponent } from "./inventory-management/inv-stock-issue/inv-stock-issue.component";
import { ProfileComponent } from "./profile/profile.component";
import { OtherUsersComponent } from "./profile/other-users/other-users.component";
import { IncidenceConvergenceSetupComponent } from "./convergence/incident-management/incidence-convergence-setup/incidence-convergence-setup.component";
import { IncidenceConvergenceCaptureEventComponent } from "./convergence/incident-management/incidence-convergence-capture-event/incidence-convergence-capture-event.component";
import { IncidenceConvergenceDashboardComponent } from "./convergence/incident-management/incidence-convergence-dashboard/incidence-convergence-dashboard.component";
import { DeviceConvergenceSetupComponent } from "./convergence/device-management/device-convergence-setup/device-convergence-setup.component";
import { DeviceConvergenceConnectivityComponent } from "./convergence/device-management/device-convergence-connectivity/device-convergence-connectivity.component";
import { PbmCreatePlatformComponent } from "./platform-benefits-manager/pbm-create-platform/pbm-create-platform.component";
import { PbmMapPlatformServiceComponent } from "./platform-benefits-manager/pbm-map-platform-service/pbm-map-platform-service.component";
import { ColorPickerModule } from "primeng/colorpicker";
import { DeviceConvergenceDashboardComponent } from "./convergence/device-management/device-convergence-dashboard/device-convergence-dashboard.component";
import { DeviceConvergenceRegistrationComponent } from "./convergence/device-management/device-convergence-registration/device-convergence-registration.component";
import { IncidenceConvergenceApprovalComponent } from "./convergence/incident-management/incidence-convergence-approval/incidence-convergence-approval.component";
import { IncidenceConvergenceReviewComponent } from "./convergence/incident-management/incidence-convergence-review/incidence-convergence-review.component";
import { IncidenceConvergenceSettingComponent } from "./convergence/incident-management/incidence-convergence-setting/incidence-convergence-setting.component";
import { GmaBioSearchComponent } from "./gma/gma-bio-search/gma-bio-search.component";
import { PbmCreateBenefitComponent } from "./platform-benefits-manager/pbm-create-benefit/pbm-create-benefit.component";
import { PbmMapServiceBenefitComponent } from "./platform-benefits-manager/pbm-map-service-benefit/pbm-map-service-benefit.component";
import { NonPrincipalApprovalSetupComponent } from "./setups/non-principal-approval-setup/non-principal-approval-setup.component";
import { SupplierOfficeSetupComponent } from "./supplier-office-setup/supplier-office-setup.component";
import { SupplierApprovalRequestComponent } from "./supplier-approval-request/supplier-approval-request.component";
import { SupplierApprovalListingComponent } from "./supplier-approval-listing/supplier-approval-listing.component";
import { AgmCoreModule } from "@agm/core";
import { GMapModule } from "primeng/gmap";
import { SupplierApprovalRequestService } from "./supplier-approval-request/supplier-approval-request.service";
import { SupplierOfficeSetupService } from "./supplier-office-setup/supplier-office-setup.service";
import { IncidentNotificationTemplateComponent } from "./convergence/incident-management/incident-notification-template/incident-notification-template.component";
import { IncidentNotificationPolicyComponent } from "./convergence/incident-management/incident-notification-policy/incident-notification-policy.component";
import { IncidentClientNotificationComponent } from "./convergence/incident-management/incident-client-notification/incident-client-notification.component";
import { CreditDebitnoteViewComponent } from "./client-management/credit-debitnote-view/credit-debitnote-view.component";
import { ViewNoteDetailsComponent } from "./client-management/credit-debitnote-view/view-note-details/view-note-details.component";
import { ViewReceiptComponent } from "./finance/receipting/view-receipt/view-receipt.component";
import { ReportViewerModule } from "ngx-ssrs-reportviewer";
import { ViewUsageComponent } from "./finance/advance-payment/view-usage/view-usage.component";
import { SubletPaymentComponent } from "./finance/advance-payment/sublet-payment/sublet-payment.component";
import { CurrencySetupComponent } from "./setups/currency-setup/currency-setup.component";
import { ClientInformationUpdateComponent } from "./client-management/client-information-update/client-information-update.component";
import { ReverseReceiptingComponent } from "./finance/reverse-receipting/reverse-receipting.component";
import { ReverseInvoiceComponent } from "./finance/reverse-invoice/reverse-invoice.component";
import { ServiceIncomeAccountComponent } from "./service-setup/service-income-account/service-income-account.component";
import { IncidenceConvergenceReportComponent } from "./convergence/incident-management/incidence-convergence-report/incidence-convergence-report.component";
import { ReceivableReportComponent } from "./reporting/receivable-report/receivable-report.component";
import { ReversalOfficeComponent } from "./finance/reversal-office/reversal-office.component";
import { ReverseVoucherComponent } from "./finance/reverse-voucher/reverse-voucher.component";
import { HalogenUsersComponent } from "./profile/halogen-users/halogen-users.component";
import { ManagementDashboardComponent } from "./management-dashboard/management-dashboard.component";
import { BatchRequestTrackingComponent } from "./inventory-management/batch-request-tracking/batch-request-tracking.component";
import { ExpenseLineSetupComponent } from "./finance/expense-line-setup/expense-line-setup.component";
import { ExpenseRequesterAttachmentComponent } from "./finance/expense-requester-attachment/expense-requester-attachment.component";
import { ExpenseApprovalSetupComponent } from "./finance/expense-approval-setup/expense-approval-setup.component";
import { AdvancePaymentViewComponent } from "./finance/advance-payment/advance-payment-view/advance-payment-view.component";
import { ExpenseBudgetsComponent } from "./finance/expense-budgets/expense-budgets.component";
import { IncidenceConvergenceCaptureMobileComponent } from "./convergence/incident-management/incidence-convergence-capture-mobile/incidence-convergence-capture-mobile.component";
import { ExpenseUserRequestComponent } from "./finance/expense-user-request/expense-user-request.component";
import { ExpenseLineApprovalComponent } from "./organization/expense-line-approval/expense-line-approval.component";
import { ResponseTeamsComponent } from "./response/response-teams/response-teams.component";
import { ResponseTeamTypeComponent } from "./response/response-team-type/response-team-type.component";
import { RespTeamReststationMapComponent } from "./response/resp-team-reststation-map/resp-team-reststation-map.component";
import { RestingStationsSetupComponent } from "./response/resting-stations-setup/resting-stations-setup.component";
import { RestingStationLgaMapComponent } from "./response/resting-station-lga-map/resting-station-lga-map.component";
import { CreateButtonComponent } from "../components/create-button/create-button.component";
import { AdvancePaymentReversalComponent } from "./finance/advance-payment/advance-payment-reversal/advance-payment-reversal.component";
import { VoucherPostingComponent } from "./finance/voucher-posting/voucher-posting.component";
import { PeriodOpeningClosingComponent } from "./finance/period-opening-closing/period-opening-closing.component";
import { ExpenseApprovalComponent } from "./finance/expense-approval/expense-approval.component";
import { ExpenseAuthorizationSetupComponent } from "./finance/expense-authorization-setup/expense-authorization-setup.component";
import { ExpenseAuthorizationComponent } from "./finance/expense-authorization/expense-authorization.component";
import { ExpensePaymentComponent } from "./finance/expense-payment/expense-payment.component";
import { ExpensePayablesComponent } from "./finance/expense-payables/expense-payables.component";
import { FinancePrintChequeComponent } from "./finance/finance-print-cheque/finance-print-cheque.component";
import { FinanceBankPaymentComponent } from "./finance/finance-bank-payment/finance-bank-payment.component";
import { ExpenseRequestTrackerComponent } from "./finance/expense-request-tracker/expense-request-tracker.component";
import { SupplierBankAccountComponent } from "./supplier/supplier-bank-account/supplier-bank-account.component";
import { BulkApprovalComponent } from "./approvals/bulk-approval/bulk-approval.component";
import { BankAccountSetupComponent } from "./setups/bank-account-setup/bank-account-setup.component";
import { ContractExtensionApprovalSetupComponent } from "./client-management/contract-extension-approval-setup/contract-extension-approval-setup.component";
import { ContractExtensionRequestComponent } from "./client-management/contract-extension-request/contract-extension-request.component";
import { ContractExtensionApprovalComponent } from "./client-management/contract-extension-approval/contract-extension-approval.component";
import { BudgetSeasonComponent } from "./budget/budget-season/budget-season.component";
import { BudgetCycleComponent } from "./budget/budget-cycle/budget-cycle.component";
import { BudgetDistributionsComponent } from "./budget/budget-distributions/budget-distributions.component";
import { UpsertBudgetCycleComponent } from "./budget/budget-cycle/upsert-budget-cycle/upsert-budget-cycle.component";
import { ShowBudgetCyclesComponent } from "./budget/budget-cycle/show-budget-cycles/show-budget-cycles.component";
import { AddEditBudgetSeasonComponent } from "./budget/budget-season/add-edit-budget-season/add-edit-budget-season.component";
import { ShowBudgetSeasonComponent } from "./budget/budget-season/show-budget-season/show-budget-season.component";
import { RegionComponent } from "./budget/budget-distributions/region/region.component";
import { ProductCatComponent } from "./budget/budget-distributions/product-cat/product-cat.component";
import { IndividualComponent } from "./budget/budget-distributions/individual/individual.component";
import { MonthComponent } from "./budget/budget-distributions/month/month.component";
import { BudgetApprovalSetupComponent } from "./budget/budget-approval-setup/budget-approval-setup.component";
import { BudgetApprovalComponent } from "./budget/budget-approval/budget-approval.component";
import { BudgetReviewerComponent } from "./budget/budget-reviewer/budget-reviewer.component";
import { BudgetReviewerSetupComponent } from "./budget/budget-reviewer-setup/budget-reviewer-setup.component";
import { BudgetComponent } from "./budget/budget.component";
import { AccountReconciliationComponent } from "./finance/account-reconciliation/account-reconciliation.component";

import { InvoiceUpdateComponent } from "./finance/invoice-update/invoice-update.component";
import { FinanceBulkSettlementComponent } from "./finance/finance-bulk-settlement/finance-bulk-settlement.component";
import { PbmSpecialRequestWorkbenchComponent } from "./platform-benefits-manager/pbm-special-request-workbench/pbm-special-request-workbench.component";
import { GmaGuardRegistrationComponent } from "./gma/gma-guard-registration/gma-guard-registration.component";
import { ClientMergeComponent } from "./client-management/client-merge/client-merge.component";
import { ClientMergeRequestComponent } from "./client-management/client-merge-request/client-merge-request.component";
import { ClientMergeApprovalComponent } from "./client-management/client-merge-approval/client-merge-approval.component";
import { RevenueArmotizationViewComponent } from "./client-management/revenue-armotization-view/revenue-armotization-view.component";
import { RenewalViewComponent } from "./client-management/renewal-view/renewal-view.component";
import { ProductionDashboardComponent } from "./production-dashboard/production-dashboard.component";
import { ApprovalDashboardComponent } from "./approval-dashboard/approval-dashboard.component";
import { CreditNoteReversalComponent } from "./finance/credit-note-reversal/credit-note-reversal.component";
import { InvoiceReplacementComponent } from "./finance/invoice-replacement/invoice-replacement.component";
import { ApprovalChecklistComponent } from "./approvals/approval-checklist/approval-checklist.component";
import { GenericNotificationComponent } from "./convergence/incident-management/incidence-convergence-setup/generic-notification/generic-notification.component";
import { ExpressPerformaInvComponent } from "./finance/express-performa-inv/express-performa-inv.component";
import { ExpressPerformaCloseoutComponent } from "./finance/express-performa-closeout/express-performa-closeout.component";
import { RawInflowUplComponent } from "./finance/raw-inflow-upl/raw-inflow-upl.component";
import { RawInflowConfirmationComponent } from "./finance/raw-inflow-confirmation/raw-inflow-confirmation.component";
import { AutoRenewPolicyComponent } from "./finance/auto-renew-policy/auto-renew-policy.component";
import { DtrackTransmissionComponent } from "./finance/dtrack-transmission/dtrack-transmission.component";
import { InvAggregatedViewComponent } from "./inventory-management/inv-aggregated-view/inv-aggregated-view.component";
import { LoginDashboardComponent } from "./login-dashboard/login-dashboard.component";
import { ClientStateMappingComponent } from "./convergence/incident-management/client-state-mapping/client-state-mapping.component";
import { ApprovalSequenceComponent } from "./approvals/approval-sequence/approval-sequence.component";
import { ContractRenewNotificationComponent } from "./client-management/contract-renew-notification/contract-renew-notification.component";
import { ServiceCostAnalysisComponent } from "./client-management/service-cost-analysis/service-cost-analysis.component";

@NgModule({
  declarations: [
    HomeComponent,
    AccountComponent,
    SupplierOfficeSetupComponent,
    SupplierApprovalRequestComponent,
    SupplierApprovalListingComponent,
    BranchOfficeComponent,
    LoadingComponent,
    DivisionComponent,
    DivisionOpSetupComponent,
    OperatingEntityComponent,
    ServiceGroupComponent,
    ServiceCategoryComponent,
    ServiceCategoryTaskComponent,
    SBUComponent,
    TargetComponent,
    StandardSlaOperatingComponent,
    BankComponent,
    RequiredServiceDocumentComponent,
    RequiredServiceQualElementComponent,
    ServiceTaskDeliverableComponent,
    ServiceTypeComponent,
    ServiceComponent,
    ServiceFormComponent,
    ProjectManagementComponent,
    DeliverableFormComponent,
    RoleComponent,
    ApprovalsComponent,
    AccountStatementComponent,
    AccountsChartComponent,
    AccountTransactionMdComponent,
    UserDeliverableComponent,
    SbuUsersComponent,
    AttachUserSbuComponent,
    DeliverableInfoComponent,
    ChartWidgetComponent,
    PickDeliverableComponent,
    CloseDeliverableComponent,
    SbuUsersComponent,
    ReceiptingComponent,
    ClientViewComponent,
    InvoicesComponent,
    KeyPersonnelComponent,
    ApprovalSetupComponent,
    ApproveDeliverableComponent,
    ClientEndorsementComponent,
    InvoiceCreationComponent,
    BeneficiaryComponent,
    AddDependentBeneficiaryComponent,
    CorporateAdjustmentSetupComponent,
    ViewQuoteserviceDetailsComponent,
    TaskDetailsComponent,
    DeliverableDetailsComponent,
    ReportingComponent,
    ReportViewComponent,
    ViewInvoiceComponent,
    ReportingComponent,
    ReportViewComponent,
    RoleclaimAttributesComponent,
    HumanizePipe,
    TimeoutComponent,
    ViewContractServiceComponent,
    ViewDivisionComponent,
    EndJorneyComponent,
    StartJourneyComponent,
    ServicePricingComponent,
    SupplierCategoryComponent,
    SupplierServiceComponent,
    SupplierComponent,
    ModeoftransportComponent,
    ComplaintOriginComponent,
    ComplaintTypeComponent,
    EscalationLevelComponent,
    ProfileEscalationLevelComponent,
    ComplaintSetupComponent,
    RegisterComplaintsComponent,
    ComplaintSourceComponent,
    EscalationMatrixComponent,
    ComplaintsHandlingComponent,
    RealEscalationMatrixComponent,
    ComplaintTrackingComponent,
    AttachClientToRMComponent,
    ClientPolicyComponent,
    ClientListingComponent,
    PassengerComponent,
    CaptureJourneyStopComponent,
    CaptureSituationReportComponent,
    CaptureJourneyStopComponent,
    SuspectCaptureComponent,
    SuspectQualificationComponent,
    JourneyManagementPlanComponent,
    EndJourneyMessageComponent,
    EscalationManagementComponent,
    ReceiptBreakdownComponent,
    CreditDebitNoteComponent,
    ViewAccountStatementComponent,
    RedoProjectManagementComponent,
    InventoryManagementComponent,
    InventoryStoreComponent,
    InventoryStockComponent,
    BatchApprovalComponent,
    BatchConfirmationComponent,
    StockPolicyComponent,
    RegionalRequestComponent,
    UserRequestComponent,
    RequestAuditComponent,
    NewLeadDashboardComponent,
    AdminPriceComponent,
    GuardItemSettingsComponent,
    GmaCreateJobComponent,
    GmaJobListingComponent,
    InventoryDashboardComponent,
    GmaSetupComponent,
    GmaSettingsComponent,
    GmaManageJobComponent,
    GmaTrainingSchoolComponent,
    GmaApprovalComponent,
    ClientLocationComponent,
    GmaMaterialRequestComponent,
    BeatTypeSetupComponent,
    BeatCreationComponent,
    GmaOrgStructureComponent,
    LocationChecklistSetupComponent,
    GmaRedeploymentComponent,
    GmaZonalMapViewComponent,
    GroupSelectionComponent,
    GmaMaterialDistributionComponent,
    GmaOperativeManagementComponent,
    GmaBeatAllocationComponent,
    GmaClusterReportingComponent,
    GmaSupervisionPolicyComponent,
    GmaConfirmDistributionComponent,
    GmaBioRecaptureComponent,
    PayrollSettingsComponent,
    PayrollPayGradeComponent,
    PayrollTaxSettingsComponent,
    PayrollPayGroupComponent,
    PayrollOperativeAttachmentComponent,
    PayrollAbsenceReqComponent,
    PayrollAbsenceAppComponent,
    PayrollRunsComponent,
    PayrollManagementComponent,
    PayrollApprovalComponent,
    ContactsComponent,
    HomeSpinnerComponent,
    LeadEngagementsComponent,
    EngagementDashboardComponent,
    EngagementContactsComponent,
    EngagementActivitiesComponent,
    PayrollApprovalSetupComponent,
    QualificationsComponent,
    OpportunitiesComponent,
    DeliverableDashboardComponent,
    PayrollVsContractComponent,
    GmaMaterialRefreshComponent,
    MakeSettingsComponent,
    ModelSettingsComponent,
    GmaDashboardComponent,
    SalesDashboardComponent,
    GmaGuarantorComponent,
    GmaMaterialReplacementComponent,
    GmaTrainingScheduleComponent,
    GmaFacilitatorComponent,
    //JourneyEndFeedbackComponent,
    //JoEndComponent,
    SbuInfoComponent,
    GmaSanctionsComponent,
    GmaApplicantListingComponent,
    //JourneyEndFeedbackComponent,
    //JoEndComponent,
    ConvergenceCaptureEventComponent,
    CompanyComponent,
    EngagementLeadQualificationComponent,
    GmaTrainingFacilityComponent,
    PayrollPayeStateReportComponent,
    PayrollPaidVsQtyComponent,
    PayrollSpecialSetupComponent,
    SendMailComponent,
    PayrollSpecialRequestComponent,
    PayrollSpecialRequest2Component,
    PayrollSpecialReqApprovalComponent,
    PayrollApproval2Component,
    ApprovalSetup2Component,
    InvStockPositionViewComponent,
    ClientInfoWidgetComponent,
    AdvancePaymentComponent,
    OrganizationOrganogramComponent,
    OrganizationLineManagerApprovalComponent,
    InvStockReceiptComponent,
    InvStockIssueComponent,
    ProfileComponent,
    OtherUsersComponent,
    GmaBioSearchComponent,
    NonPrincipalApprovalSetupComponent,
    IncidenceConvergenceSetupComponent,
    IncidenceConvergenceSettingComponent,
    IncidenceConvergenceCaptureEventComponent,
    IncidenceConvergenceReviewComponent,
    IncidenceConvergenceApprovalComponent,
    IncidenceConvergenceDashboardComponent,
    DeviceConvergenceSetupComponent,
    DeviceConvergenceRegistrationComponent,
    DeviceConvergenceConnectivityComponent,
    DeviceConvergenceDashboardComponent,
    PbmCreatePlatformComponent,
    PbmCreateBenefitComponent,
    PbmMapPlatformServiceComponent,
    PbmMapServiceBenefitComponent,
    CreditDebitnoteViewComponent,
    ViewNoteDetailsComponent,
    IncidentNotificationTemplateComponent,
    IncidentNotificationPolicyComponent,
    IncidentClientNotificationComponent,
    ViewReceiptComponent,
    ViewUsageComponent,
    SubletPaymentComponent,
    CurrencySetupComponent,
    ClientInformationUpdateComponent,
    ReverseReceiptingComponent,
    ReverseInvoiceComponent,
    ServiceIncomeAccountComponent,
    IncidenceConvergenceReportComponent,
    ReceivableReportComponent,
    ReversalOfficeComponent,
    ReverseVoucherComponent,
    HalogenUsersComponent,
    ManagementDashboardComponent,
    AdvancePaymentViewComponent,
    BatchRequestTrackingComponent,
    ExpenseLineSetupComponent,
    ExpenseRequesterAttachmentComponent,
    ExpenseApprovalSetupComponent,
    ExpenseBudgetsComponent,
    IncidenceConvergenceCaptureMobileComponent,
    ExpenseUserRequestComponent,
    ExpenseLineApprovalComponent,
    ResponseTeamsComponent,
    ResponseTeamTypeComponent,
    RespTeamReststationMapComponent,
    RestingStationsSetupComponent,
    RestingStationLgaMapComponent,
    //CreateButtonComponent,
    AdvancePaymentReversalComponent,
    VoucherPostingComponent,
    PbmSpecialRequestWorkbenchComponent,
    PeriodOpeningClosingComponent,
    ExpenseApprovalComponent,
    ExpenseAuthorizationSetupComponent,
    ExpenseAuthorizationComponent,
    ExpensePaymentComponent,
    ExpensePayablesComponent,
    SupplierBankAccountComponent,
    FinancePrintChequeComponent,
    FinanceBankPaymentComponent,
    ExpenseRequestTrackerComponent,
    BulkApprovalComponent,
    BankAccountSetupComponent,
    ContractExtensionApprovalSetupComponent,
    ContractExtensionRequestComponent,
    ContractExtensionApprovalComponent,
    BudgetSeasonComponent,
    BudgetCycleComponent,
    BudgetDistributionsComponent,
    UpsertBudgetCycleComponent,
    ShowBudgetCyclesComponent,
    AddEditBudgetSeasonComponent,
    ShowBudgetSeasonComponent,
    RegionComponent,
    ProductCatComponent,
    IndividualComponent,
    MonthComponent,
    BudgetApprovalSetupComponent,
    BudgetApprovalComponent,
    BudgetReviewerComponent,
    BudgetReviewerSetupComponent,
    BudgetComponent,
    AccountReconciliationComponent,
    InvoiceUpdateComponent,
    FinanceBulkSettlementComponent,
    GmaGuardRegistrationComponent,
    ClientMergeComponent,
    ClientMergeRequestComponent,
    ClientMergeApprovalComponent,
    RevenueArmotizationViewComponent,
    RenewalViewComponent,
    ProductionDashboardComponent,
    ApprovalDashboardComponent,
    CreditNoteReversalComponent,
    InvoiceReplacementComponent,
    ApprovalChecklistComponent,
    GenericNotificationComponent,
    ExpressPerformaInvComponent,
    ExpressPerformaCloseoutComponent,
    RawInflowUplComponent,
    RawInflowConfirmationComponent,
    AutoRenewPolicyComponent,
    DtrackTransmissionComponent,
    InvAggregatedViewComponent,
    LoginDashboardComponent,
    ClientStateMappingComponent,
    ApprovalSequenceComponent,
    ContractRenewNotificationComponent,
    ServiceCostAnalysisComponent,
  ],
  imports: [
    AvatarModule,
    AgmCoreModule.forRoot({
      apiKey: "AIzaSyAjeJEPREBQFvAIqDSZliF0WjQrCld-Mh0",
    }),
    AvatarGroupModule,
    GMapModule,
    CommonModule,
    ReportViewerModule,
    HomeRoutingModule,
    ProgressBarModule,
    ButtonModule,
    InputNumberModule,
    ToolbarModule,
    InputMaskModule,
    KnobModule,
    RippleModule,
    TabViewModule,
    ToggleButtonModule,
    AccordionModule,
    CalendarModule,
    InputTextModule,
    ReactiveFormsModule,
    LeadAdminModule,
    DropdownModule,
    FormsModule,
    TableModule,
    ToastModule,
    SpinnerModule,
    ProgressSpinnerModule,
    DialogModule,
    TooltipModule,
    ConfirmDialogModule,
    QualificationModule,
    InputSwitchModule,
    RadioButtonModule,
    FileUploadModule,
    CardModule,
    MultiSelectModule,
    TreeModule,
    KeyFilterModule,
    PanelModule,
    ChartModule,
    FieldsetModule,
    CalendarModule,
    PickListModule,
    TabMenuModule,
    InputTextareaModule,
    AutoCompleteModule,
    RatingModule,
    DragDropModule,
    StepsModule,
    CheckboxModule,
    SliderModule,
    SplitterModule,
    DataViewModule,
    InputSwitchModule,
    CarouselModule,
    ChipsModule,
    ChipModule,
    TreeTableModule,
    MessageModule,
    FullCalendarModule,
    BadgeModule,
    ColorPickerModule,
    ArmadaModule,
  ],
  //exports: [CreateButtonComponent],
  exports: [],
  providers: [
    DialogService,
    NgSwitch,
    DynamicDialogConfig,
    DynamicDialogRef,
    ConfirmationService,
    ProjectAllocationService,
    SupplierOfficeSetupService,
    NodeService,
    ProductService,
    CurrencyPipe,
    ContactsService,
    DatePipe,
    EventService,
    EngagementServiceService,
    SupplierApprovalRequestService,
    LeadEngagementsService,
    EngagementContactsService,
    EngagementActivitiesService,
    QualificationsService,
    OpportunitiesService,
    DeliverableDashboardService,
    SalesDashboardService,
    SbuContractServiceProportionService,
    SendMailService,
  ],
  entryComponents: [
    ContactsComponent,
    DeliverableFormComponent,
    KeyPersonnelComponent,
    DeliverableInfoComponent,
    PickDeliverableComponent,
    CloseDeliverableComponent,
    AddDependentBeneficiaryComponent,
    ViewQuoteserviceDetailsComponent,
    TaskDetailsComponent,
    DeliverableDetailsComponent,
    RoleclaimAttributesComponent,
    TimeoutComponent,
    ApproveDeliverableComponent,
    ViewInvoiceComponent,
    ViewContractServiceComponent,
    ViewDivisionComponent,
    EndJorneyComponent,
    StartJourneyComponent,
    PassengerComponent,
    CaptureSituationReportComponent,
    CaptureJourneyStopComponent,
    JourneyManagementPlanComponent,
    EndJourneyMessageComponent,
    AttachUserSbuComponent,
    ReceiptBreakdownComponent,
    ViewAccountStatementComponent,
    ServiceFormComponent,
    GroupSelectionComponent,
    SbuInfoComponent,
    ViewNoteDetailsComponent,
    ViewReceiptComponent,
    AdminPriceComponent,
    ViewUsageComponent,
    SubletPaymentComponent,
    AdvancePaymentViewComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class HomeModule {}
