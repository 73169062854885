import { Lga } from "./home";
import { State } from "src/app/interfaces/home";
import { Item } from "./inventory";
import {
  ApplicantEvaluation,
  ApplicantMaterialRequestBatch,
  Evaluation,
} from "./employment";

export interface CreateOperativeType {
  caption: string;
  description: string;
  uploadUrls?: string[];
}

export interface OperativeType {
  id?: number;
  caption?: string;
  description?: string;
  approvalInstruction?: string;
  code?: string;
  operativeItems?: OperativeItem[];
  createdAt?: Date;
}

export interface OperativeItem {
  id?: number;
  operativeTypeId?: number;
  quantitiesToBeIssued?: number;
  item?: Item;
  is30DaysNotificationEnabled?: boolean;
  is15DaysNotificationEnabled?: boolean;
  is10DaysNotificationEnabled?: boolean;
  is5DaysNotificationEnabled?: boolean;
  is2DaysNotificationEnabled?: boolean;
  is1DayNotificationEnabled?: boolean;
  createdAt?: Date;
  isSelected?: boolean;
}

export interface CreateOperativeItem {
  operativeTypeId: number;
  itemId: number;
  qtyIssued: number;
  daysSpan: number;
  expirationAction: number;
  notificationSettings: number[];
  supportingDoc: string[];
}

export interface RemoveOperativeItems {
  operativeTypeId: number;
  operativeItemIds: number[];
}

export const JobTypeEnum = {
  Fulltime: 1,
  Contract: 2,
  PartTime: 3,
};
Object.freeze(JobTypeEnum);

export const EducationLevelEnum = {
  Primary: 1,
  Secondary: 2,
  Tertiary: 3,
  PostGraduate: 4,
};
Object.freeze(EducationLevelEnum);

export const ExpirationActionEnum = {
  Recycle: 0,
  Discard: 1,
  Allocate_To_Issuer: 2,
  Allocate_To_Other: 3,
};
Object.freeze(ExpirationActionEnum);

export const JobApplicationStatusEnum = {
  PersonalInfo: 1,
  BackgroundInfo: 2,
  NextOfKinInfo: 3,
  EducationalInfo: 4,
  PrevEmploymentInfo: 5,
  MedicalAccountInfo: 6,
  AdditionalInfo: 7,
  Submitted: 8,
};
Object.freeze(JobApplicationStatusEnum);

export const MaritalStatusEnum = {
  Single: 1,
  Married: 2,
  Divorced: 3,
  Widowed: 4,
};
Object.freeze(MaritalStatusEnum);

export const GenderEnum = {
  Male: 1,
  Female: 2,
  Unspecified: 3,
};
Object.freeze(GenderEnum);

export const NextOfKinRelationshipEnum = {
  Mother: 1,
  Father: 2,
  Sister: 3,
  Brother: 4,
  Son: 5,
  Daughter: 6,
};
Object.freeze(NextOfKinRelationshipEnum);

export const BankEnum = {
  Firstbank: 1,
  ZenithBank: 2,
  GTBank: 3,
  PolarisBank: 4,
  KeystoneBank: 5,
  FCMB: 6,
  KudaBank: 7,
  SterlingBank: 8,
  WemaBank: 9,
  ProvidusBank: 10,
};
Object.freeze(BankEnum);

export const BiometricCaptureAction = {
  Left_Pinky: 1,
  Left_RingFinger: 2,
  Left_MiddleFinger: 3,
  Left_IndexFinger: 4,
  Left_Thumb: 5,
  Right_Thumb: 6,
  Right_IndexFinger: 7,
  Right_MiddleFinger: 8,
  Right_RingFinger: 9,
  Right_Pinky: 10,
};
Object.freeze(BiometricCaptureAction);

export interface CreateJobPost {
  jobType: number;
  jobTitle: string;
  validityStartPeriod: Date;
  validityStopPeriod: Date;
  jobSummary: string;
  operativeTypeId: number;
  stateId: number;
  minEducationLevel: number;
  minimumYearsOfExperience: number;
  maximumApplicantsNo: number;
  jobRequirements: string;
  jobDescriptions: string[];
  jobExperiences: string[];
  trainingExaminationId: number;
  physicalExaminationId: number;
  applicationExaminationId: number;
  cognitiveExaminationId: number;
  backgroundExaminationId: number;
}

export interface JobDescription {
  id?: number;
  description?: string;
}

export interface JobExperience {
  id?: number;
  description?: string;
}

export interface JobPost {
  createdAt?: Date;
  createdById?: number;
  jobExperiences?: JobExperience[];
  jobDescriptions?: JobDescription[];
  requirements?: string;
  maximumApplicantsNo?: number;
  minimumExperienceYrs?: number;
  minimumEducationLevel?: number;
  location?: State;
  locationId?: number;
  operativeType?: OperativeType;
  operativeTypeId?: number;
  validityStop?: Date;
  validityStart?: Date;
  description?: string;
  title?: string;
  type?: number;
  id?: number;
  jobCode?: string;
  applicationExaminationId?: number;
  applicationExamination?: Evaluation;
  applicants?: JobApplication[];
  jobMaterialRequestBatches?: ApplicantMaterialRequestBatch[];
}

export interface StartJobApplication {
  jobPostId: number;
  passportImageUrl?: string;
  firstname: string;
  lastname: string;
  email?: string;
  stateId?: number;
  othernames: string;
  lGAId: number;
  nickname: string;
  street: string;
  mobile: string;
  gender: number;
  dOB: Date;
  applicationLetterUrl: string[];
}

export interface JobApplication {
  createdAt?: Date;
  additionalInformation?: AdditionalInfo;
  additionalInformationId?: number;
  medicalAccountInformation?: MedicalAccountInfo;
  medicalAccountInformationId?: number;
  prevEmployerInformations?: PrevEmployerInfo[];
  educationalInformations?: EducationalInfo[];
  nextOfKinInformations?: NextOfKinInfo[];
  backgroundInformationId?: number;
  personalInformation?: PersonalInfo;
  personalInformationId?: number;
  isConsentGiven?: boolean;
  currentStatus?: number;
  trackingNo?: string;
  jobPost?: JobPost;
  jobPostId?: number;
  id?: number;
  backgroundInformation?: BackgroundInfo;
  applicantEvaluation?: ApplicantEvaluation;
  applicantEvaluationId?: number;
  applicantMaterialRequests?: ApplicantMaterialRequest[];
}

export interface ApplicantMaterialRequest {
  id?: number;
  applicantId?: number;
  operativeItemId?: number;
  createdAt?: Date;
}

export interface AdditionalInfo {
  createdAt?: Date;
  taxIdentificationNo?: string;
  pensionPin?: string;
  secondaryBankAcctNo?: string;
  primaryBankAcctNo?: string;
  // secondaryBank?: number;
  // primaryBank?: number;
  secondaryBank?: string;
  primaryBank?: string;
  isSufferingUnknown?: boolean;
  isDiabetic?: boolean;
  hasArthritis?: boolean;
  isHypertensive?: boolean;
  isGambling?: boolean;
  isSmoking?: boolean;
  isDrinking?: boolean;
  id?: number;
  unknownDescription?: string;
}

export interface MedicalAccountInfo {
  id?: number;
  isExMilitary?: boolean;
  hasCombatTraining?: boolean;
  hasBeenArrested?: boolean;
  hasBeenConvicted?: boolean;
  hasBeenAskedToResign?: boolean;
}

export interface PrevEmployerInfo {
  createdAt?: Date;
  reasonForTermination?: string;
  yearOfTermination?: Date;
  yearOfEmployment?: Date;
  street?: string;
  lga?: Lga;
  lgaId?: number;
  state?: State;
  stateId?: number;
  name?: string;
  id?: number;
  roleOccupied?: string;
}

export interface EducationalInfo {
  id?: number;
  level?: number;
  institution?: string;
  qualificationAttained?: string;
  programStartDate?: Date;
  programStopDate?: Date;
  gradeAwarded?: string;
}

export interface NextOfKinInfo {
  createdAt?: Date;
  email?: string;
  street?: string;
  lga?: Lga;
  state?: State;
  stateId?: number;
  relationship?: number;
  othername?: string;
  lastname?: string;
  firstname?: string;
  id?: number;
  lgaId?: number;
}

export interface PersonalInfo {
  createdAt?: Date;
  applicationLetter?: string;
  imageUrl?: string;
  dateOfBirth?: Date;
  street?: string;
  lga?: Lga;
  lgaId?: number;
  stateId?: number;
  email?: string;
  mobile?: string;
  nickname?: string;
  othername?: string;
  lastname?: string;
  firstname?: string;
  id?: number;
  state?: State;
  fullname?: string;
  gender?: number;
}

export interface BackgroundInfo {
  createdAt?: Date;
  children?: ChildInfo[];
  motherAddress?: string;
  motherFullname?: string;
  fatherAddress?: string;
  fatherFullname?: string;
  spouseOccupation?: string;
  spouseLastname?: string;
  spouseFirstname?: string;
  isBelongingToAClub?: boolean;
  marriageLocation?: string;
  maritalDate?: Date;
  maritalStatus?: number;
  tribe?: string;
  lgaOfOrigin?: Lga;
  lgaOfOriginId?: number;
  stateOfOrigin?: State;
  stateOfOriginId?: number;
  id?: number;
  clubName?: string;
}

export interface ChildInfo {
  id?: number;
  firstname?: string;
  lastname?: string;
  gender?: number;
  dateOfBirth?: Date;
}

export interface AddBackgroundInfo {
  trackingNo: string;
  stateOfOrigin: number;
  lGAOfOrigin: number;
  tribe: string;
  maritalStatus: number;
  marriageDate: Date;
  marriageLocation: string;
  belongsToAClub: boolean;
  clubName?: string;
  spouseFirstname: string;
  spouseLastname: string;
  spouseOccupation: string;
  fatherFullname: string;
  fatherAddress: string;
  motherFullname: string;
  motherAddress: string;
  children?: AddChildInfo[];
}

export interface AddChildInfo {
  firstname: string;
  lastname: string;
  gender: number;
  dateOfBirth: Date;
}

export interface AddNextOfKin {
  firstname: string;
  lastname: string;
  state: number;
  stateEntity?: State;
  lGA: number;
  lGAEntity?: Lga;
  othername?: string;
  street: string;
  relationship: number;
  relationshipValue?: string;
  mobile: string;
  email?: string;
}

export interface AddNextOfKins {
  trackingNo: string;
  nextOfKins: AddNextOfKin[];
}

export interface AddEducationalBackground {
  educationalLevel: number;
  educationalLevelValue?: string;
  programStartDate: Date;
  institution: string;
  programEndDate: Date;
  qualificationAttained: string;
  gradeAwarded: string;
}

export interface AddEducationalBackgrounds {
  trackingNo: string;
  backgrounds: AddEducationalBackground[];
}

export interface AddPrevEmployer {
  name: string;
  role: string;
  state: number;
  stateName?: string;
  lGA: number;
  lGAName?: string;
  street: string;
  yearOfEmployment: Date;
  yearOfTermination: Date;
  reasonForTermination: string;
}

export interface AddPrevEmployers {
  trackingNo: string;
  employers: AddPrevEmployer[];
}

export interface AddMedicalAccountInfo {
  trackingNo: string;
  isDrinking: boolean;
  isSmoking: boolean;
  isGambling: boolean;
  isHypertensive: boolean;
  hasArthritis: boolean;
  isDiabetic: boolean;
  isSufferingUnknown: boolean;
  unknownDiseaseDescription?: string;
  primaryBank: string;
  primaryBankAcctNo: string;
  secondaryBank: string;
  secondaryBankAcctNo: string;
  pensionPin: string;
  tIN?: string;
  bVN: string;
  pensionPayeeID: string;
  nIN?: string;
  primaryAccountBankId: number;
  secondaryAccountBankId: number;
  primaryAccountName?: string;
  secondaryAccountName?: string;
}

export interface AddAdditionalInfo {
  trackingNo: string;
  isExMilitant: boolean;
  hasCombatTraining: boolean;
  hasBeenArrested: boolean;
  hasBeenConvicted: boolean;
  hasBeenAskedToResign: boolean;
  agreesToTerms: boolean;
  hasDeclared: boolean;
}
