import {
  AddNewClusterSupervisionPolicy,
  Cluster,
  ClusterSupervisionPolicy,
  SupervisionCycleEnum,
} from "./../../../interfaces/employment";
import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MessageService, ConfirmationService, TreeNode } from "primeng/api";
import { BreadcrumbService } from "src/app/breadcrumb.service";
import { GmaEmploymentService } from "src/app/services/gma-employment.service";

@Component({
  selector: "app-gma-supervision-policy",
  templateUrl: "./gma-supervision-policy.component.html",
  styleUrls: ["./gma-supervision-policy.component.scss"],
})
export class GmaSupervisionPolicyComponent implements OnInit {
  @ViewChild("formWrapper") public formWrapper: ElementRef;
  policyForm: FormGroup;
  myZonesTree: TreeNode[];
  cols: any[];
  selectedZoneTree: TreeNode[];
  allClusterPolicies: ClusterSupervisionPolicy[];
  selectedClusterPolicy: ClusterSupervisionPolicy;
  spCols: any[];
  zoneCount: number = 0;
  clusterCount: number = 0;
  clusterWithActivePolicy: number = 0;
  clusterWithoutActivePolicy: number = 0;
  clusterInView: Cluster;
  showAddNewCluster: boolean = false;
  allSupervisionCycles: {
    key: number;
    value: string;
  }[];
  theSupervisionCycle: {
    key: number;
    value: string;
  };

  constructor(
    fb: FormBuilder,
    public messageService: MessageService,
    public employmentService: GmaEmploymentService,
    private breadcrumbService: BreadcrumbService,
    public confirmationService: ConfirmationService
  ) {
    this.policyForm = fb.group({
      PolicyStartDate: ["", Validators.required],
      PolicyEndDate: ["", Validators.required],
      LocationsCount: ["", Validators.required],
      MinCoverage: ["", Validators.required],
      MinLocations: ["", Validators.required],
      CoverageThreshold: ["", Validators.required],
      SupervisionCycle: ["", Validators.required],
    });
  }

  ngOnInit(): void {
    this.breadcrumbService.setItems([
      {
        label: "Guard Management",
        routerLink: ["/home/guard-management/item-settings"],
      },
      {
        label: "Supervision Policy",
        routerLink: ["/home/guard-management/gma-supervision-policy"],
      },
    ]);

    this.cols = [
      { field: "name", header: "Name" },
      {
        field: "activePolicyStatus",
        header: "Active Policy Status",
      },
      {
        field: "complaintOfficerImg",
        header: "Compliant Officer",
      },
      { field: "view", header: "View" },
    ];

    this.spCols = [
      { field: "name", header: "Name" },
      { field: "schoolCode", header: "SchoolCode" },
      { field: "description", header: "Description" },
    ];

    this.myZonesTree = [
      // {
      //   data: {
      //     name: "Zone A",
      //     activePolicyStatus: null,
      //     complaintOfficerImg: null,
      //     view: null,
      //   },
      //   children: [
      //     {
      //       data: {
      //         name: "Cluster 1",
      //         activePolicyStatus: true,
      //         complaintOfficerImg: "assets/no-file.png",
      //         view: 12,
      //       },
      //     },
      //     {
      //       data: {
      //         name: "Cluster 2",
      //         activePolicyStatus: false,
      //         complaintOfficerImg: "assets/no-file.png",
      //         view: 121,
      //       },
      //       children: [],
      //     },
      //   ],
      // },
    ];

    this.allSupervisionCycles = [
      {
        key: SupervisionCycleEnum.Weekly,
        value: "Weekly",
      },
      // {
      //   key: SupervisionCycleEnum.BiMonthly,
      //   value: "BiMonthly",
      // },
      {
        key: SupervisionCycleEnum.Monthly,
        value: "Monthly",
      },
      {
        key: SupervisionCycleEnum.Quartely,
        value: "Quartely",
      },
    ];

    this.FetchAllUserZones();
  }

  FetchAllUserZones() {
    this.employmentService.GetAllUserZones().subscribe(
      async (data) => {
        this.zoneCount = data.length;
        this.myZonesTree = [];
        data.forEach((zone) => {
          let zoneTreeNode: TreeNode = {
            data: {
              name: zone.caption,
              activePolicyStatus: null,
              complaintOfficerImg: null,
              view: null,
            },
            children: [],
          };
          zone.clusters
            .filter((x) => x.isDeleted == false)
            .forEach((cluster) => {
              this.clusterCount++;
              let hasActivePolicy =
                cluster.supervisionPolicies.find((x) => x.isDeleted == false) !=
                null
                  ? true
                  : false;
              if (hasActivePolicy) this.clusterWithActivePolicy++;
              else this.clusterWithoutActivePolicy++;
              zoneTreeNode.children.push({
                data: {
                  name: cluster.caption,
                  activePolicyStatus: hasActivePolicy,
                  complaintOfficerImg: cluster.clusterHead.imageUrl,
                  view: cluster,
                },
              });
            });

          this.myZonesTree.push(zoneTreeNode);
        });
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to get all user zones at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  ViewClusterPolicies(item: Cluster) {
    this.clusterInView = item;
    this.allClusterPolicies = item.supervisionPolicies.reverse();
  }

  ShowAddNewPolicy() {
    this.showAddNewCluster = true;
    this.policyForm.reset();
    this.policyForm.patchValue({
      LocationsCount: this.clusterInView.locations.length,
      MinCoverage: 0,
      MinLocations: 0,
    });
  }

  AddClusterPolicy() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Adding Cluster Policy...",
    });

    const postData: AddNewClusterSupervisionPolicy = {
      clusterId: this.clusterInView.id,
      policyStartDate: this.policyForm.get("PolicyStartDate").value,
      policyEndDate: this.policyForm.get("PolicyEndDate").value,
      minimumCoverageExpected: this.policyForm.get("MinCoverage").value,
      minimumLocationsToCover: this.policyForm.get("MinLocations").value,
      dailyThresholdCoverage: this.policyForm.get("CoverageThreshold").value,
      supervisionCycle: this.theSupervisionCycle.key,
    };

    if (
      postData.minimumLocationsToCover > this.clusterInView.locations.length
    ) {
      this.messageService.add({
        severity: "error",
        summary: "Notice",
        detail:
          "Minimum Locations to Cover cannot be more than the total locations.",
      });
      return;
    }

    if (
      postData.minimumLocationsToCover > this.clusterInView.locations.length
    ) {
      this.messageService.add({
        severity: "error",
        summary: "Notice",
        detail: "Invalid minimum locations to cover",
      });
      return;
    }

    this.employmentService.AddNewClusterSupervisionPolicy(postData).subscribe(
      () => {
        this.messageService.add({
          severity: "success",
          summary: "Notice",
          detail: "Cluster Policy Added Successful!",
        });

        this.clusterInView.supervisionPolicies.push({
          clusterId: postData.clusterId,
          policyStartDate: postData.policyStartDate,
          policyStopDate: postData.policyEndDate,
          minimumLocationsToCover: postData.minimumLocationsToCover,
          minimumCoverageExpected: postData.minimumCoverageExpected,
          isDeleted: false,
          supervisionCycle: postData.supervisionCycle,
        });
        this.allClusterPolicies = this.clusterInView.supervisionPolicies;
        this.theSupervisionCycle = null;
        this.policyForm.reset();
        this.showAddNewCluster = false;
        this.FetchAllUserZones();
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to add new cluster policy the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  MinLocationToCoverEvent(value: number) {
    let perc = (value / this.clusterInView.locations.length) * 100;
    this.policyForm.patchValue({
      MinCoverage: perc,
    });
  }

  LoadSupervisionSuggest() {
    if (this.theSupervisionCycle) {
      let suggestion: number = 0;
      let minCov = +this.policyForm.get("MinLocations").value;
      if (this.theSupervisionCycle.key == 1) {
        suggestion = minCov / 5;
      } else if (this.theSupervisionCycle.key == 2) {
        suggestion = minCov / 10;
      } else if (this.theSupervisionCycle.key == 3) {
        suggestion = minCov / 23;
      } else if (this.theSupervisionCycle.key == 4) {
        suggestion = minCov / 69;
      }

      suggestion = Math.round(suggestion);
      if (suggestion == 0) suggestion = 1;

      this.policyForm.patchValue({
        LocationsCount: this.clusterInView.locations.length,
        CoverageThreshold: suggestion,
      });
    }
  }

  GetSupervisionCycleLabel(cycle: number): string {
    if (cycle == SupervisionCycleEnum.Weekly) return "Weekly";
    if (cycle == SupervisionCycleEnum.BiMonthly) return "BiMonthly";
    if (cycle == SupervisionCycleEnum.Monthly) return "Monthly";
    if (cycle == SupervisionCycleEnum.Quartely) return "Quartely";
    return "";
  }

  DeletePolicy(item: ClusterSupervisionPolicy) {
    this.confirmationService.confirm({
      message:
        "You are about to deactivate this cluster policy now!!. Do you still want to proceed?",
      accept: () => {
        this.messageService.add({
          severity: "info",
          summary: "Notice",
          detail: "Deactivating Policy Now...",
        });

        this.employmentService.DeactivateClusterPolicy(item.id).subscribe(
          () => {
            this.messageService.add({
              severity: "success",
              summary: "Removed",
              detail: "Policy Deactivated Successfully",
            });
            item.isDeleted = true;
          },
          (error) => {
            console.log("Error: " + JSON.stringify(error));
            this.messageService.add({
              severity: "error",
              summary: "Notice",
              detail:
                "Unable to deactivate cluster policy at the moment.. Reason: [" +
                error
                  ? error.error.message
                  : "request failed - permission" + "]",
            });
          }
        );
      },
    });
  }
}
