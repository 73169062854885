import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../environments/environment";
import { Observable } from "rxjs";
import { AccountVoucherType, CommonResponse } from "../interfaces/home";

@Injectable({
  providedIn: "root",
})
export class AccountVoucherTypeService {
  constructor(private http: HttpClient) {}
  baseUrl = environment.baseUrl + "FinanceVoucherType/";
  // fetch all AccountVoucherType in the database
  allAccountVoucherTypeData(): Observable<
    CommonResponse<AccountVoucherType[]>
  > {
    return this.http.get<CommonResponse<AccountVoucherType[]>>(this.baseUrl);
  }
  getAccountVoucherType(id: any): Observable<CommonResponse> {
    return this.http.get<CommonResponse>(this.baseUrl + id);
  }
  getAccountVoucherTypeByName(name: any): Observable<CommonResponse> {
    return this.http.get<CommonResponse>(this.baseUrl + "name/" + name);
  }
  postAccountVoucherType(data: any) {
    return this.http.post(this.baseUrl, data);
  }
  deleteAccountVoucherType(id: any) {
    return this.http.delete(this.baseUrl + id);
  }
  updateAccountVoucherType(id: any, data: any) {
    return this.http.put(this.baseUrl + id, data);
  }
}
