import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { MessageService, ConfirmationService } from "primeng/api";
import { BreadcrumbService } from "src/app/breadcrumb.service";
import { Bank } from "src/app/interfaces/home";
import {
  AddSupplierBankAccountVM,
  BankAccountTypeEnum,
  Supplier,
  SupplierBank,
  SupplierCategory,
} from "src/app/interfaces/supplier";
import { BankService } from "src/app/services/bank.service";
import { SupplierCategoryService } from "src/app/services/supplier-category.service";
import { SupplierService } from "src/app/services/supplier.service";

@Component({
  selector: "app-supplier-bank-account",
  templateUrl: "./supplier-bank-account.component.html",
  styleUrls: ["./supplier-bank-account.component.scss"],
})
export class SupplierBankAccountComponent implements OnInit {
  @ViewChild("formWrapperMain") public formWrapperMain: ElementRef;
  @ViewChild("formWrapper") public formWrapper: ElementRef;
  fetching: boolean;
  allSuppliers: Supplier[];
  selectedSuppliers: Supplier[];
  supplierInView: Supplier;
  cols: any[];
  showDetails: boolean;
  allBanks: Bank[];
  theBank: Bank;
  accountName: string = "";
  accountNumber: string = "";
  allBankAccountTypes: {
    key: number;
    value: string;
  }[] = [];
  theBankAccountType: {
    key: number;
    value: string;
  };
  isPrimaryBankAccount: boolean = false;
  carouselResponsiveOptions: any[] = [
    {
      breakpoint: "1024px",
      numVisible: 3,
      numScroll: 3,
    },
    {
      breakpoint: "768px",
      numVisible: 2,
      numScroll: 2,
    },
    {
      breakpoint: "560px",
      numVisible: 1,
      numScroll: 1,
    },
  ];
  allSupplierCategories: SupplierCategory[];
  selectedSupplierBankAccounts: SupplierBank[];
  showAddBank: boolean;

  constructor(
    public bankService: BankService,
    public supplierCategoryService: SupplierCategoryService,
    public supplierService: SupplierService,
    public messageService: MessageService,
    private breadcrumbService: BreadcrumbService,
    public confirmationService: ConfirmationService
  ) {}

  ngOnInit(): void {
    this.breadcrumbService.setItems([
      {
        label: "Supplier",
        routerLink: ["/home/supplier"],
      },
      {
        label: "Bank Account",
        routerLink: ["/home/supplier/bank-accounts"],
      },
    ]);

    this.cols = [
      { field: "supplierName", header: "Supplier Name" },
      { field: "supplierCategoryId", header: "Supplier Category" },
      { field: "address", header: "Address" },
    ];

    this.allBankAccountTypes = [
      {
        key: BankAccountTypeEnum.SAVINGS,
        value: "SAVINGS",
      },
      {
        key: BankAccountTypeEnum.CURRENT,
        value: "CURRENT",
      },
      {
        key: BankAccountTypeEnum.LOAN,
        value: "LOAN",
      },
    ];

    this.FetchAllBanks();
    this.FetchAllSupplierCategories();
    this.FetchAllSuppliers();
  }

  async FetchAllBanks() {
    this.bankService.allBankData().subscribe(
      async (data) => {
        if (data.responseCode != "00") {
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail: data.responseMsg,
          });
          return;
        }

        this.allBanks = data.responseData;
      },
      (error) => {
        this.messageService.add({
          severity: "error",
          summary: "Failed",
          detail: error ?? "Some errors occurred",
        });
      }
    );
  }

  async FetchAllSupplierCategories() {
    this.supplierCategoryService.getAll().subscribe(
      async (data) => {
        if (data.responseCode != "00") {
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail: data.responseMsg,
          });
          return;
        }

        this.allSupplierCategories = data.responseData;
      },
      (error) => {
        this.messageService.add({
          severity: "error",
          summary: "Failed",
          detail: error ?? "Some errors occurred",
        });
      }
    );
  }

  async FetchAllSuppliers() {
    this.fetching = true;
    this.supplierService.GetAllSuppliersWithBank().subscribe(
      async (data) => {
        if (data.responseCode != "00") {
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail: data.responseMsg,
          });
          this.fetching = false;
          return;
        }

        this.allSuppliers = data.responseData;
        this.fetching = false;
      },
      (error) => {
        this.messageService.add({
          severity: "error",
          summary: "Failed",
          detail: error ?? "Some errors occurred",
        });
        this.fetching = false;
      }
    );
  }

  ShowAddNewBank() {
    this.ResetAddNewBank();
    this.showAddBank = true;
  }

  AddNewBankAccount() {
    this.confirmationService.confirm({
      message:
        "You are about to add a new bank account. Do you still wish to proceed?",
      accept: () => {
        this.messageService.add({
          severity: "info",
          summary: "Notice",
          detail: "Adding New Bank Account...",
        });

        const postData: AddSupplierBankAccountVM = {
          supplierId: this.supplierInView.id,
          bankId: this.theBank.id,
          bankAccountName: this.accountName,
          accountType: this.theBankAccountType.key,
          accountNumber: this.accountNumber,
          isPrimaryAccount: this.isPrimaryBankAccount,
          bankCode: this.theBank.bankCode,
        };

        this.supplierService.AddSupplierBankAccount(postData).subscribe(
          async (data) => {
            if (data.responseCode != "00") {
              this.messageService.add({
                severity: "error",
                summary: "Notice",
                detail: data.responseMsg,
              });
              this.fetching = false;
              return;
            }

            this.messageService.add({
              severity: "success",
              summary: "Completed",
              detail: "Bank Account Added Successfully!",
            });

            this.supplierInView = null;
            this.ResetAddNewBank();
            this.showDetails = false;
            this.FetchAllSuppliers();
          },
          (error) => {
            console.log("Error: " + JSON.stringify(error));
            this.messageService.add({
              severity: "error",
              summary: "Notice",
              detail:
                "Unable to add new bank account at the moment.. Reason: [" +
                error
                  ? error.error.message
                  : "request failed - permission" + "]",
            });
          }
        );
      },
    });
  }

  SwitchIsPrimaryBank() {}

  ShowSupplierBankInfo(item: Supplier) {
    console.log(item);
    this.selectedSupplierBankAccounts = item.banks;
    this.supplierInView = item;
    this.showDetails = true;
    this.ResetAddNewBank();

    this.formWrapperMain.nativeElement.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "end",
    });
  }

  GetSupplierCategoryLabel(categoryId: number): string {
    let categ = this.allSupplierCategories.find((x) => x.id == categoryId);
    if (categ) return categ.categoryName;

    return "N/a";
  }

  ResetAddNewBank() {
    this.theBank = null;
    this.accountName = null;
    this.accountNumber = null;
    this.theBankAccountType = null;
    this.isPrimaryBankAccount = false;
    this.showAddBank = false;
  }

  GetBankLogoUrl(bankId: number): string | null {
    let bank = this.allBanks.find((x) => x.id == bankId);
    if (bank) return bank.logoUrl;

    return null;
  }

  GetBankName(bankId: number): string {
    let bank = this.allBanks.find((x) => x.id == bankId);
    if (bank) return bank.name;

    return "N/a";
  }

  GetAccountType(accountTypeId): string {
    let acctType = this.allBankAccountTypes.find((x) => x.key == accountTypeId);
    if (acctType) return acctType.value;

    return "N/A";
  }
}
