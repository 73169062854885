import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { ConfirmationService, MessageService } from "primeng/api";
import { BreadcrumbService } from "src/app/breadcrumb.service";
import { Currency, CurrencyExchangeRate } from "src/app/interfaces/currency";
import {
  Account,
  AccountClass,
  AccountVoucherType,
  AddPostingVM,
  Branch,
  ControlAccount,
  CustomerDivision,
  Office,
  OperatingEntity,
  ServiceCategory,
} from "src/app/interfaces/home";
import { AccountClassService } from "src/app/services/account-class.service";
import { AccountMasterService } from "src/app/services/account-master";
import { AccountVoucherTypeService } from "src/app/services/account-voucher-type.service";
import { AccountService } from "src/app/services/account.service";
import { BranchService } from "src/app/services/branch.service";
import { ControlAccountService } from "src/app/services/control-account.service";
import { CurrencyService } from "src/app/services/currency.service";
import { CustomerDivisionService } from "src/app/services/customer-division.service";
import { OfficeService } from "src/app/services/office.service";
import { OperatingEntityService } from "src/app/services/operating-entity.service";
import { ServiceCategoryService } from "src/app/services/service-category.service";

@Component({
  selector: "app-voucher-posting",
  templateUrl: "./voucher-posting.component.html",
  styleUrls: ["./voucher-posting.component.scss"],
})
export class VoucherPostingComponent implements OnInit {
  @ViewChild("formWrapper") public formWrapper: ElementRef;
  allVoucherTypes: AccountVoucherType[];
  theVoucherType: AccountVoucherType;
  vocuherTotalValue: number = 0;
  allBranches: Branch[];
  theBranch: Branch;
  allMarketAreas: OperatingEntity[];
  theMarketArea: OperatingEntity;
  allCustomers: CustomerDivision[];
  theCustomer: CustomerDivision;
  voucherDate: Date;
  allOffices: Office[];
  theOffice: Office;
  allServiceCategories: ServiceCategory[];
  theServiceCategory: ServiceCategory;
  voucherDescription: string;
  voucherDetails: {
    index?: number;
    isTotal: boolean;
    allAccountClass?: AccountClass[];
    accountClass?: AccountClass;
    allControlAccounts?: ControlAccount[];
    controlAccount?: ControlAccount;
    allPostingAccount?: Account[];
    postingAccount?: Account;
    description?: string;
    drValue: number;
    crValue: number;
    isCreditAcct: boolean;
    isDebitAcct: boolean;
  }[] = [];
  allAccountClasses: AccountClass[] = [];
  allControlAccounts: ControlAccount[] = [];
  allAccounts: Account[] = [];
  totalDRValue: number = 0;
  totalCRValue: number = 0;
  allCurrencies: Currency[] = [];
  theCurrency: Currency;
  theQuoteCurrency: Currency;
  theExchangeRateToUse: CurrencyExchangeRate;
  allExchangeRates: CurrencyExchangeRate[] = [];
  openDetailDialogue: boolean;

  constructor(
    private fb: FormBuilder,
    public currencyService: CurrencyService,
    public accountMasterService: AccountMasterService,
    public accountVoucherTypeService: AccountVoucherTypeService,
    public accountClassService: AccountClassService,
    public accountService: AccountService,
    public clientService: CustomerDivisionService,
    public operatingEntityService: OperatingEntityService,
    public serviceCategoryService: ServiceCategoryService,
    public controlAccountService: ControlAccountService,
    public branchService: BranchService,
    public officeService: OfficeService,
    private breadcrumbService: BreadcrumbService,
    public confirmationService: ConfirmationService,
    public messageService: MessageService
  ) {}

  ngOnInit(): void {
    this.breadcrumbService.setItems([
      {
        label: "Finance",
      },
      {
        label: "Voucher Posting",
        routerLink: ["/home/finance/voucher-posting"],
      },
    ]);

    this.voucherDetails.push({
      isTotal: true,
      isCreditAcct: true,
      isDebitAcct: false,
      crValue: 0,
      drValue: 0,
    });

    this.FetchAllExchangeRates();
    this.FetchAllCurrencies();
    this.FetchAllVoucherTypes();
    this.FetchAllClients();
    this.FetchAllBranches();
    this.FetchAllOffices();
    this.FetchAllMarketAreas();
    this.FetchAllServiceCategories();
    this.FetchAllAccountClasses();
    this.FetchAllControlAccounts();
    this.FetchAllAccounts();
  }

  async FetchAllVoucherTypes() {
    this.accountVoucherTypeService.allAccountVoucherTypeData().subscribe(
      async (data) => {
        this.allVoucherTypes = data.responseData.filter(
          (x) => x.isAllowedForStandaloneVoucherPosting
        );
      },
      (error) => {
        this.messageService.add({
          severity: "error",
          summary: "Failed",
          detail: error ?? "Some errors occurred",
        });
      }
    );
  }

  async FetchAllClients() {
    this.clientService.allCustomerDivision().subscribe(
      async (data) => {
        this.allCustomers = data.responseData;
      },
      (error) => {
        this.messageService.add({
          severity: "error",
          summary: "Failed",
          detail: error ?? "Some errors occurred",
        });
      }
    );
  }

  async FetchAllBranches() {
    this.branchService.allBranch().subscribe(
      async (data) => {
        this.allBranches = data.responseData;
      },
      (error) => {
        this.messageService.add({
          severity: "error",
          summary: "Failed",
          detail: error ?? "Some errors occurred",
        });
      }
    );
  }

  async FetchAllOffices() {
    this.officeService.allOffice().subscribe(
      async (data) => {
        this.allOffices = data.responseData;
      },
      (error) => {
        this.messageService.add({
          severity: "error",
          summary: "Failed",
          detail: error ?? "Some errors occurred",
        });
      }
    );
  }

  async FetchAllMarketAreas() {
    this.operatingEntityService.allData().subscribe(
      async (data) => {
        this.allMarketAreas = data.responseData;
      },
      (error) => {
        this.messageService.add({
          severity: "error",
          summary: "Failed",
          detail: error ?? "Some errors occurred",
        });
      }
    );
  }

  async FetchAllServiceCategories() {
    this.serviceCategoryService.allServiceCategory().subscribe(
      async (data) => {
        this.allServiceCategories = data.responseData;
      },
      (error) => {
        this.messageService.add({
          severity: "error",
          summary: "Failed",
          detail: error ?? "Some errors occurred",
        });
      }
    );
  }

  async FetchAllAccountClasses() {
    this.accountClassService.allAccountClasses().subscribe(
      async (data) => {
        this.allAccountClasses = data.responseData;
      },
      (error) => {
        this.messageService.add({
          severity: "error",
          summary: "Failed",
          detail: error ?? "Some errors occurred",
        });
      }
    );
  }

  async FetchAllControlAccounts() {
    this.controlAccountService.allControlAccounts().subscribe(
      async (data) => {
        this.allControlAccounts = data.responseData;
      },
      (error) => {
        this.messageService.add({
          severity: "error",
          summary: "Failed",
          detail: error ?? "Some errors occurred",
        });
      }
    );
  }

  async FetchAllAccounts() {
    this.accountService.allAccount().subscribe(
      async (res) => {
        this.allAccounts = res.responseData;
      },
      (error) => {
        this.messageService.add({
          severity: "error",
          summary: "Failed",
          detail: error ?? "Connection error, Try again later",
        });
      }
    );
  }

  async FetchAllCurrencies() {
    this.currencyService.GetAllCurrencies().subscribe(
      async (data) => {
        this.allCurrencies = data;
        this.theQuoteCurrency = this.allCurrencies.find(
          (x) => x.name == "Naira"
        );
        if (this.theQuoteCurrency) {
          this.allCurrencies = this.allCurrencies.filter(
            (x) => x.id != this.theQuoteCurrency.id
          );
        }
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all currencies at the moment.. Reason: [" + error
              ? error.error.message
              : "request failed - permission" + "]",
        });
      }
    );
  }

  async FetchAllExchangeRates() {
    this.currencyService.GetAllCurrencyExchangeRates().subscribe(
      async (data) => {
        this.allExchangeRates = data;
        this.allExchangeRates.reverse();
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all exchange rates at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  AddNewDetailLine() {
    let indexL = this.voucherDetails.length;
    this.voucherDetails.unshift({
      index: indexL,
      isTotal: false,
      isCreditAcct: false,
      allAccountClass: this.allAccountClasses,
      allControlAccounts: [],
      allPostingAccount: [],
      isDebitAcct: false,
      crValue: 0,
      drValue: 0,
    });
  }

  PostVoucher() {
    if (
      this.GetActualCurrencyValue(this.totalCRValue) !=
      this.GetActualCurrencyValue(this.totalDRValue)
    ) {
      this.messageService.add({
        severity: "error",
        summary: "Failure",
        detail: "Total Debit Value Must Match Total Credit Value ",
      });
      return;
    }
    if (
      this.GetActualCurrencyValue(this.totalCRValue) !=
      this.GetActualCurrencyValue(this.vocuherTotalValue)
    ) {
      this.messageService.add({
        severity: "error",
        summary: "Failure",
        detail: "Total Voucher Value Must Match Total Voucher Details Value ",
      });
      return;
    }

    this.confirmationService.confirm({
      message:
        "You are about to post this voucher transaction, Do you still wish to proceed?",
      accept: () => {
        this.messageService.add({
          severity: "info",
          summary: "Notice",
          detail: "Posting Transaction......",
        });

        const postData: AddPostingVM = {
          voucherTypeId: this.theVoucherType.id,
          totalValue: this.GetActualCurrencyValue(this.vocuherTotalValue),
          branchId: this.theBranch.id,
          operatingEntityId: this.theMarketArea.id,
          customerId: this.theCustomer.id ?? null,
          transactionDate: this.voucherDate,
          officeId: this.theOffice.id,
          serviceCategoryId: this.theServiceCategory.id,
          transactionDecription: this.voucherDescription,
          currencyId: this.theCurrency.id,
          currencyExchangeRateId: this.theExchangeRateToUse?.id,
          rateApplied: this.theExchangeRateToUse?.exchangeRate,
          postingDetails: [],
        };

        this.voucherDetails
          .filter((x) => !x.isTotal)
          .forEach((detail) =>
            postData.postingDetails.push({
              accountId: detail.postingAccount.id,
              description: detail.description,
              debitValue: this.GetActualCurrencyValue(detail.drValue),
              creditValue: this.GetActualCurrencyValue(detail.crValue),
            })
          );

        this.accountMasterService.AddPosting(postData).subscribe(
          async (data) => {
            if (data.responseCode == "00") {
              this.messageService.add({
                severity: "success",
                summary: "Removed",
                detail: "Transaction posted successfully",
              });
              this.theVoucherType = null;
              this.theCurrency = null;
              this.theExchangeRateToUse = null;
              this.openDetailDialogue = false;
              this.ResetForm();
            } else {
              this.messageService.add({
                severity: "error",
                summary: "Failure",
                detail:
                  "Err: Could not post transaction. Reason -> " +
                  data.responseMsg,
              });
            }
          },
          (error) => {
            console.log("Error: " + JSON.stringify(error));
            this.messageService.add({
              severity: "error",
              summary: "Notice",
              detail:
                "Unable to post transaction at the moment.. Reason: [" +
                error.error.message +
                "]",
            });
          }
        );
      },
    });
  }

  CheckDetailsLength(): boolean {
    return this.voucherDetails.length > 1;
  }

  OnAccountClassChange(index: number) {
    let item = this.voucherDetails.find((x) => x.index == index);
    if (item) {
      item.allControlAccounts = [];
      item.controlAccount = null;
      item.allPostingAccount = [];
      item.postingAccount = null;
      item.drValue = 0;
      item.crValue = 0;
      if (item.accountClass) {
        item.allControlAccounts = this.allControlAccounts.filter(
          (x) => x.accountClassId == item.accountClass.id
        );
      }
    }
  }

  OnControlAccountChange(index: number) {
    let item = this.voucherDetails.find((x) => x.index == index);
    if (item) {
      item.allPostingAccount = [];
      item.postingAccount = null;
      item.drValue = 0;
      item.crValue = 0;
      if (item.controlAccount) {
        item.allPostingAccount = this.allAccounts.filter(
          (x) => x.controlAccountId == item.controlAccount.id
        );
      }
    }
  }

  OnAccountChange(index: number) {
    let item = this.voucherDetails.find((x) => x.index == index);
    if (item) {
      item.drValue = 0;
      item.isDebitAcct = false;
      item.isCreditAcct = false;
      item.crValue = 0;
      if (item.postingAccount) {
        item.isCreditAcct = !item.postingAccount.isDebitBalance;
        item.isDebitAcct = item.postingAccount.isDebitBalance;
      }
      this.ReCalculateTotalValues();
    }
  }

  ReCalculateTotalCredit() {
    this.totalCRValue = 0;
    this.voucherDetails.forEach((x) => (this.totalCRValue += x.crValue));
  }

  ReCalculateTotalDebit() {
    this.totalDRValue = 0;
    this.voucherDetails.forEach((x) => (this.totalDRValue += x.drValue));
  }

  ReCalculateTotalValues() {
    this.totalCRValue = 0;
    this.totalDRValue = 0;
    this.voucherDetails.forEach((x) => {
      this.totalCRValue += x.crValue;
      this.totalDRValue += x.drValue;
    });
  }

  ResetForm() {
    this.theVoucherType = null;
    this.vocuherTotalValue = 0;
    this.theBranch = null;
    this.theMarketArea = null;
    this.theCustomer = null;
    this.voucherDate = null;
    this.theOffice = null;
    this.theServiceCategory = null;
    this.voucherDescription = null;
    this.voucherDetails = [];
    this.voucherDetails.push({
      isTotal: true,
      isCreditAcct: true,
      isDebitAcct: false,
      crValue: 0,
      drValue: 0,
    });
    this.totalCRValue = 0;
    this.totalDRValue = 0;
  }

  PostVoucherView() {
    if (this.totalCRValue != this.totalDRValue) {
      this.messageService.add({
        severity: "error",
        summary: "Failure",
        detail: "Total Debit Value Must Match Total Credit Value ",
      });
      return;
    }
    if (this.totalCRValue != this.vocuherTotalValue) {
      this.messageService.add({
        severity: "error",
        summary: "Failure",
        detail: "Total Voucher Value Must Match Total Voucher Details Value ",
      });
      return;
    }

    this.theExchangeRateToUse = null;
    if (this.theCurrency.id != this.theQuoteCurrency.id) {
      this.theExchangeRateToUse = this.allExchangeRates.find(
        (x) =>
          x.baseCurrencyId == this.theCurrency.id &&
          x.quoteCurrencyId == this.theQuoteCurrency.id
      );
      if (this.theExchangeRateToUse == null) {
        this.messageService.add({
          severity: "error",
          summary:
            "Error can't find currency exchange rate to use. Base Currency ID -> " +
            this.theCurrency.id +
            "  against Quote Currency ID -> " +
            this.theQuoteCurrency.id,
        });
        return;
      }
    }
    this.openDetailDialogue = true;
  }

  GetActualCurrencyValue(value: number): number {
    if (this.theExchangeRateToUse && value) {
      return value * this.theExchangeRateToUse.exchangeRate;
    }
    return value ?? 0;
  }
}
