import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../environments/environment";
import { Observable } from "rxjs";
import { BankReceivingDTO, CommonResponse } from "../interfaces/home";

@Injectable({
  providedIn: "root",
})
export class BankService {
  constructor(private http: HttpClient) {}
  baseUrl = environment.baseUrl + "Bank/";
  // fetch all Bank in the database
  allBankData(): Observable<CommonResponse> {
    return this.http.get<CommonResponse>(this.baseUrl);
  }
  getBank(id: any): Observable<CommonResponse> {
    return this.http.get<CommonResponse>(this.baseUrl + id);
  }
  getBankByName(name: any): Observable<CommonResponse> {
    return this.http.get<CommonResponse>(this.baseUrl + "name/" + name);
  }
  postBank(data: BankReceivingDTO) {
    return this.http.post(this.baseUrl, data);
  }
  deleteBank(id: any) {
    return this.http.delete(this.baseUrl + id);
  }
  updateBank(id: any, data: any) {
    return this.http.put(this.baseUrl + id, data);
  }
}
