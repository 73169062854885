import {
  Allowance,
  CreateAllowance,
  CreateDeductionVM,
  Deduction,
  DeductionTypeEnum,
  UpdateAllowance,
  UpdateDeductionVM,
} from "./../../../interfaces/payroll";
import { Account } from "./../../../interfaces/home";
import { PayrollService } from "./../../../services/payroll.service";
import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ConfirmationService, Message, MessageService } from "primeng/api";
import { BreadcrumbService } from "src/app/breadcrumb.service";
import {
  PayrollFrequencyEnum,
  PayrollMonthEnum,
} from "src/app/interfaces/payroll";

@Component({
  selector: "app-payroll-settings",
  templateUrl: "./payroll-settings.component.html",
  styleUrls: ["./payroll-settings.component.scss"],
  providers: [MessageService],
})
export class PayrollSettingsComponent implements OnInit {
  @ViewChild("formWrapper") public formWrapper: ElementRef;
  allowanceForm: FormGroup;
  deductionForm: FormGroup;
  allFrequencies: {
    key: number;
    value: string;
  }[];
  theAllowanceFrequency: {
    key: number;
    value: string;
  };
  theDeductionFrequency: {
    key: number;
    value: string;
  };
  allPayMonths: {
    key: number;
    value: string;
  }[];
  theAllowancePayMonth: {
    key: number;
    value: string;
  };
  isTaxableRadioButton: number;
  isStatutoryRadioButton: number;
  allExpenseAccounts: Account[];
  theAllowanceExpenseAcct: Account;
  editingAllowance: boolean = false;
  fetchingAllowances: boolean = true;
  allowanceCols: any[];
  allAllowances: Allowance[];
  selectedAllowance: Allowance;
  allowanceMsg: Message[] = [];
  deductionMsg: Message[] = [];
  isAccountTypeRadioButton: number;
  isTaxReliefRadioButton: number;
  allDeductionMonths: {
    key: number;
    value: string;
  }[];
  theDeductionMonth: {
    key: number;
    value: string;
  };
  allAssetAccounts: Account[];
  allLiabilityAccounts: Account[];
  allDeductionAccounts: Account[];
  theDeductionAccount: Account;
  allDeductionTypes: {
    key: number;
    value: string;
  }[];
  theDeductionType: {
    key: number;
    value: string;
  };
  isDeductionStatutoryButton: number;
  editingDeductions: boolean = false;
  fetchingDeductions: boolean = true;
  deductionCols: any[];
  allDeductions: Deduction[];
  selectedDeduction: Deduction;
  allowanceToEdit: Allowance;
  deductionToEdit: Deduction;
  preLoadAllPayMonths: {
    key: number;
    value: string;
  }[];

  constructor(
    private fb: FormBuilder,
    private payrollService: PayrollService,
    private breadcrumbService: BreadcrumbService,
    public confirmationService: ConfirmationService,
    public messageService: MessageService
  ) {
    this.allowanceForm = fb.group({
      Caption: ["", Validators.required],
      Frequency: ["", Validators.required],
      IsTaxable: ["", Validators.required],
      Description: ["", Validators.required],
      Paymonth: ["", Validators.required],
      IsStatutory: ["", Validators.required],
      ExpenseAccount: ["", Validators.required],
    });

    this.deductionForm = fb.group({
      Caption: ["", Validators.required],
      Frequency: ["", Validators.required],
      AccountType: ["", Validators.required],
      IsTaxRelief: ["", Validators.required],
      Description: ["", Validators.required],
      DeductMonth: ["", Validators.required],
      Account: ["", Validators.required],
      DeductionType: ["", Validators.required],
      IsStatutory: ["", Validators.required],
    });
  }

  ngOnInit(): void {
    this.breadcrumbService.setItems([
      {
        label: "Payroll Management",
        routerLink: ["/home/payroll/payroll-settings"],
      },
      {
        label: "Set-Up",
        routerLink: ["/home/payroll/payroll-settings"],
      },
    ]);

    this.allowanceCols = [
      { field: "caption", header: "Caption" },
      { field: "description", header: "Description" },
      { field: "createdAt", header: "CreatedAt" },
    ];

    this.deductionCols = [
      { field: "caption", header: "Caption" },
      { field: "description", header: "Description" },
      { field: "createdAt", header: "CreatedAt" },
    ];

    this.allFrequencies = [
      {
        key: PayrollFrequencyEnum.Monthly,
        value: "Monthly",
      },
      {
        key: PayrollFrequencyEnum.Quarterly,
        value: "Quarterly",
      },
      {
        key: PayrollFrequencyEnum.HalfYearly,
        value: "HalfYearly",
      },
      {
        key: PayrollFrequencyEnum.Yearly,
        value: "Yearly",
      },
    ];
    this.preLoadAllPayMonths = [
      {
        key: PayrollMonthEnum.FirstMonth,
        value: "First Month",
      },
      {
        key: PayrollMonthEnum.SecondMonth,
        value: "Second Month",
      },
      {
        key: PayrollMonthEnum.ThirdMonth,
        value: "Third Month",
      },
      {
        key: PayrollMonthEnum.FourthMonth,
        value: "Fourth Month",
      },
      {
        key: PayrollMonthEnum.FifthMonth,
        value: "Fifth Month",
      },
      {
        key: PayrollMonthEnum.SixthMonth,
        value: "Sixth Month",
      },
      {
        key: PayrollMonthEnum.SeventhMonth,
        value: "Seventh Month",
      },
      {
        key: PayrollMonthEnum.EighthMonth,
        value: "Eighth Month",
      },
      {
        key: PayrollMonthEnum.NinethMonth,
        value: "Nineth Month",
      },
      {
        key: PayrollMonthEnum.TenthMonth,
        value: "Tenth Month",
      },
      {
        key: PayrollMonthEnum.EleventhMonth,
        value: "Eleventh Month",
      },
      {
        key: PayrollMonthEnum.TwelvethMonth,
        value: "Twelveth Month",
      },
    ];
    this.allDeductionTypes = [
      {
        key: DeductionTypeEnum.EmployeeOnly,
        value: "Employee Only",
      },
      {
        key: DeductionTypeEnum.EmployerOnly,
        value: "Employer Only",
      },
      {
        key: DeductionTypeEnum.Both,
        value: "Both",
      },
    ];
    this.allPayMonths = [];
    this.allDeductionMonths = [];

    this.ResetMessageToasters();
    this.FetchAllPayrollSetupAccounts();
    this.FetchAllAllowances();
    this.FetchAllDeductions();
  }

  ResetMessageToasters() {
    this.allowanceMsg = [];
    this.allowanceMsg.push({
      severity: "info",
      summary: "Summary:",
      detail:
        "Manages all the monetary benefit types that make up employee pay.",
    });

    this.deductionMsg = [];
    this.deductionMsg.push({
      severity: "info",
      summary: "Summary:",
      detail:
        "Manages all your statutory and non-statutory payroll deduction types.",
    });
  }

  FetchAllPayrollSetupAccounts() {
    this.payrollService.GetAllAccountsForPayrollSetup().subscribe(
      async (data) => {
        this.allExpenseAccounts = data.expenseAccounts;
        this.allAssetAccounts = data.assetAccounts;
        this.allLiabilityAccounts = data.liabilityAccounts;
        this.GetExpenseAccountsLabel();
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
      }
    );
  }

  FetchAllAllowances() {
    this.payrollService.GetAllAllowances().subscribe(
      async (data) => {
        this.allAllowances = data;
        this.fetchingAllowances = false;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail: error
            ? "Unable get all allowances at the moment.. Reason: [" +
              (error ? error.error.message : "request failed - permission") +
              "]"
            : "Request Failed",
        });
        this.fetchingAllowances = false;
        this.ResetMessageToasters();
      }
    );
  }

  CreateAllowance() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Creating Allowance...",
    });
    this.ResetMessageToasters();

    const postData: CreateAllowance = {
      caption: this.allowanceForm.get("Caption").value,
      description: this.allowanceForm.get("Description").value,
      frequency: this.theAllowanceFrequency.key,
      payMonth: this.theAllowancePayMonth.key,
      expenseAccountId: this.theAllowanceExpenseAcct.id,
      isTaxable: this.isTaxableRadioButton == 1 ? true : false,
      isStatutory: this.isStatutoryRadioButton == 1 ? true : false,
    };

    this.payrollService.CreateAllowance(postData).subscribe(
      async (data) => {
        this.messageService.add({
          severity: "success",
          summary: "Completed",
          detail: "Allowance Created Successfully!",
        });
        this.ResetMessageToasters();

        this.allowanceForm.reset();
        this.theAllowanceExpenseAcct = null;
        this.theAllowancePayMonth = null;
        this.theAllowanceFrequency = null;
        this.isTaxableRadioButton = null;
        this.isStatutoryRadioButton = null;
        this.fetchingAllowances = true;
        this.FetchAllAllowances();
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to create allowance at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
        this.ResetMessageToasters();
      }
    );
  }

  UpdateAllowance() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Updating Allowance...",
    });
    this.ResetMessageToasters();

    const id = this.allowanceToEdit.id;
    const postData: UpdateAllowance = {
      caption: this.allowanceForm.get("Caption").value,
      description: this.allowanceForm.get("Description").value,
      frequency: this.theAllowanceFrequency.key,
      payMonth: this.theAllowancePayMonth.key,
      expenseAccountId: this.theAllowanceExpenseAcct.id,
      isTaxable: this.isTaxableRadioButton == 1 ? true : false,
      isStatutory: this.isStatutoryRadioButton == 1 ? true : false,
    };

    this.payrollService.UpdateAllowance(id, postData).subscribe(
      async () => {
        this.messageService.add({
          severity: "success",
          summary: "Completed",
          detail: "Allowance Updated Successfully.",
        });
        this.ResetMessageToasters();

        this.allowanceForm.reset();
        this.theAllowanceExpenseAcct = null;
        this.theAllowancePayMonth = null;
        this.theAllowanceFrequency = null;
        this.isTaxableRadioButton = null;
        this.isStatutoryRadioButton = null;
        this.editingAllowance = false;
        this.fetchingAllowances = true;
        this.allowanceToEdit = null;
        this.FetchAllAllowances();
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to update allowance at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
        this.ResetMessageToasters();
      }
    );
  }

  CloseEditingAllowance() {
    this.allowanceForm.reset();
    this.theAllowanceExpenseAcct = null;
    this.theAllowancePayMonth = null;
    this.theAllowanceFrequency = null;
    this.isTaxableRadioButton = null;
    this.isStatutoryRadioButton = null;
    this.editingAllowance = false;
    this.allowanceToEdit = null;
    this.allPayMonths = [];
  }

  DeleteAllowance(item: Allowance) {
    this.confirmationService.confirm({
      message: "Are you sure you want to remove allowance?",
      accept: () => {
        this.messageService.add({
          severity: "info",
          summary: "Notice",
          detail: "Removing allowance...",
        });
        this.ResetMessageToasters();

        this.payrollService.DeleteAllowance(item.id).subscribe(
          async () => {
            this.messageService.add({
              severity: "success",
              summary: "Removed",
              detail: "Removed successfully",
            });

            this.fetchingAllowances = true;
            const index = this.allAllowances.indexOf(item);
            if (index > -1) {
              this.allAllowances.splice(index, 1);
            }
            this.fetchingAllowances = false;
          },
          (error) => {
            console.log("Error: " + JSON.stringify(error));
            this.messageService.add({
              severity: "error",
              summary: "Notice",
              detail:
                "Unable to remove allowance at the moment.. Reason: [" + error
                  ? error.error.message
                  : "request failed - permission" + "]",
            });
            this.ResetMessageToasters();
          }
        );
      },
    });
  }

  EditAllowance(item: Allowance) {
    this.editingAllowance = true;
    this.allowanceForm.patchValue({
      Caption: item.caption,
      Description: item.description,
    });
    this.allPayMonths = this.preLoadAllPayMonths;
    this.theAllowanceFrequency = this.allFrequencies.find(
      (x) => x.key == item.frequency
    );
    this.theAllowancePayMonth = this.allPayMonths.find(
      (x) => x.key == item.payMonth
    );
    this.theAllowanceExpenseAcct = this.allExpenseAccounts.find(
      (x) => x.id == item.expenseAccountId
    );
    this.isTaxableRadioButton = item.isTaxable
      ? (this.isTaxableRadioButton = 1)
      : (this.isTaxableRadioButton = 0);
    this.isStatutoryRadioButton = item.isStatutory
      ? (this.isStatutoryRadioButton = 1)
      : (this.isStatutoryRadioButton = 0);
    this.allowanceToEdit = item;

    this.formWrapper.nativeElement.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "start",
    });
  }

  GetFrequencyLabel(frequency: number): string {
    if (frequency == PayrollFrequencyEnum.Monthly) return "Monthly";
    if (frequency == PayrollFrequencyEnum.Quarterly) return "Quarterly";
    if (frequency == PayrollFrequencyEnum.HalfYearly) return "Half Yearly";
    if (frequency == PayrollFrequencyEnum.Yearly) return "Yearly";
    return "N/A";
  }

  GetPayMonthLabel(payMonth: number): string {
    if (payMonth == PayrollMonthEnum.FirstMonth) return "First Month";
    if (payMonth == PayrollMonthEnum.SecondMonth) return "Second Month";
    if (payMonth == PayrollMonthEnum.ThirdMonth) return "Third Month";
    if (payMonth == PayrollMonthEnum.FourthMonth) return "Fourth Month";
    if (payMonth == PayrollMonthEnum.FifthMonth) return "Fifth Month";
    if (payMonth == PayrollMonthEnum.SixthMonth) return "Sixth Month";
    if (payMonth == PayrollMonthEnum.SeventhMonth) return "Seventh Month";
    if (payMonth == PayrollMonthEnum.EighthMonth) return "Eighth Month";
    if (payMonth == PayrollMonthEnum.NinethMonth) return "Nineth Month";
    if (payMonth == PayrollMonthEnum.TenthMonth) return "Tenth Month";
    if (payMonth == PayrollMonthEnum.EleventhMonth) return "Eleventh Month";
    if (payMonth == PayrollMonthEnum.TwelvethMonth) return "Twelveth Month";
    return "N/A";
  }

  OnFrequencyChange() {
    if (this.theAllowanceFrequency) {
      if (this.theAllowanceFrequency.key == PayrollFrequencyEnum.Monthly) {
        this.allPayMonths = [
          {
            key: PayrollMonthEnum.FirstMonth,
            value: "First Month",
          },
        ];
        this.theAllowancePayMonth = null;
      } else if (
        this.theAllowanceFrequency.key == PayrollFrequencyEnum.Quarterly
      ) {
        this.allPayMonths = [
          {
            key: PayrollMonthEnum.FirstMonth,
            value: "First Month",
          },
          {
            key: PayrollMonthEnum.SecondMonth,
            value: "Second Month",
          },
          {
            key: PayrollMonthEnum.ThirdMonth,
            value: "Third Month",
          },
        ];
        this.theAllowancePayMonth = null;
      } else if (
        this.theAllowanceFrequency.key == PayrollFrequencyEnum.HalfYearly
      ) {
        this.allPayMonths = [
          {
            key: PayrollMonthEnum.FirstMonth,
            value: "First Month",
          },
          {
            key: PayrollMonthEnum.SecondMonth,
            value: "Second Month",
          },
          {
            key: PayrollMonthEnum.ThirdMonth,
            value: "Third Month",
          },
          {
            key: PayrollMonthEnum.FourthMonth,
            value: "Fourth Month",
          },
          {
            key: PayrollMonthEnum.FifthMonth,
            value: "Fifth Month",
          },
          {
            key: PayrollMonthEnum.SixthMonth,
            value: "Sixth Month",
          },
        ];
        this.theAllowancePayMonth = null;
      } else if (
        this.theAllowanceFrequency.key == PayrollFrequencyEnum.Yearly
      ) {
        this.allPayMonths = [
          {
            key: PayrollMonthEnum.FirstMonth,
            value: "First Month",
          },
          {
            key: PayrollMonthEnum.SecondMonth,
            value: "Second Month",
          },
          {
            key: PayrollMonthEnum.ThirdMonth,
            value: "Third Month",
          },
          {
            key: PayrollMonthEnum.FourthMonth,
            value: "Fourth Month",
          },
          {
            key: PayrollMonthEnum.FifthMonth,
            value: "Fifth Month",
          },
          {
            key: PayrollMonthEnum.SixthMonth,
            value: "Sixth Month",
          },
          {
            key: PayrollMonthEnum.SeventhMonth,
            value: "Seventh Month",
          },
          {
            key: PayrollMonthEnum.EighthMonth,
            value: "Eighth Month",
          },
          {
            key: PayrollMonthEnum.NinethMonth,
            value: "Nineth Month",
          },
          {
            key: PayrollMonthEnum.TenthMonth,
            value: "Tenth Month",
          },
          {
            key: PayrollMonthEnum.EleventhMonth,
            value: "Eleventh Month",
          },
          {
            key: PayrollMonthEnum.TwelvethMonth,
            value: "Twelveth Month",
          },
        ];
        this.theAllowancePayMonth = null;
      }
    } else {
      this.allPayMonths = [];
      this.theAllowancePayMonth = null;
    }
  }

  GetExpenseAccountsLabel() {
    this.allExpenseAccounts.forEach((acct) => {
      acct.label = acct.accountNumber + " - " + acct.name;
    });
    this.allAssetAccounts.forEach((acct) => {
      acct.label = acct.accountNumber + " - " + acct.name;
    });
    this.allLiabilityAccounts.forEach((acct) => {
      acct.label = acct.accountNumber + " - " + acct.name;
    });
  }

  CreateDeduction() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Creating Deduction...",
    });
    this.ResetMessageToasters();

    const postData: CreateDeductionVM = {
      caption: this.deductionForm.get("Caption").value,
      description: this.deductionForm.get("Description").value,
      frequency: this.theDeductionFrequency.key,
      deductionMonth: this.theDeductionMonth.key,
      deductionType: this.theDeductionType.key,
      accountType: this.isAccountTypeRadioButton,
      accountId: this.theDeductionAccount.id,
      isStatutory: this.isDeductionStatutoryButton == 1 ? true : false,
      isTaxRelief: this.isTaxReliefRadioButton == 1 ? true : false,
    };

    this.payrollService.CreateDeduction(postData).subscribe(
      async () => {
        this.messageService.add({
          severity: "success",
          summary: "Completed",
          detail: "Deduction Created Successfully!",
        });
        this.ResetMessageToasters();

        this.deductionForm.reset();
        this.theDeductionAccount = null;
        this.allDeductionMonths = [];
        this.theDeductionMonth = null;
        this.theDeductionFrequency = null;
        this.isTaxReliefRadioButton = null;
        this.isDeductionStatutoryButton = null;
        this.theDeductionType = null;
        this.fetchingDeductions = true;
        this.FetchAllDeductions();
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to create deduction at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
        this.ResetMessageToasters();
      }
    );
  }

  FetchAllDeductions() {
    this.payrollService.GetAllDeductions().subscribe(
      async (data) => {
        this.allDeductions = data;
        this.fetchingDeductions = false;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail: error
            ? "Unable get all deductions at the moment.. Reason: [" +
              (error ? error.error.message : "request failed - permission") +
              "]"
            : "Request Failed",
        });
        this.fetchingDeductions = false;
        this.ResetMessageToasters();
      }
    );
  }

  UpdateDeduction() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Updating Deduction...",
    });
    this.ResetMessageToasters();

    const id = this.deductionToEdit.id;
    const postData: UpdateDeductionVM = {
      caption: this.deductionForm.get("Caption").value,
      description: this.deductionForm.get("Description").value,
      frequency: this.theDeductionFrequency.key,
      deductionMonth: this.theDeductionMonth.key,
      deductionType: this.theDeductionType.key,
      accountType: this.isAccountTypeRadioButton,
      accountId: this.theDeductionAccount.id,
      isStatutory: this.isDeductionStatutoryButton == 1 ? true : false,
      isTaxRelief: this.isTaxReliefRadioButton == 1 ? true : false,
    };

    this.payrollService.UpdateDeduction(id, postData).subscribe(
      async () => {
        this.messageService.add({
          severity: "success",
          summary: "Completed",
          detail: "Deduction Updated Successfully.",
        });
        this.ResetMessageToasters();

        this.deductionToEdit = null;
        this.deductionForm.reset();
        this.theDeductionAccount = null;
        this.theDeductionMonth = null;
        this.theDeductionFrequency = null;
        this.isTaxReliefRadioButton = null;
        this.isDeductionStatutoryButton = null;
        this.theDeductionType = null;
        this.editingDeductions = false;
        this.allDeductionMonths = [];
        this.FetchAllDeductions();
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to update deduction at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
        this.ResetMessageToasters();
      }
    );
  }

  CloseEditingDeduction() {
    this.deductionForm.reset();
    this.theDeductionAccount = null;
    this.theDeductionMonth = null;
    this.theDeductionFrequency = null;
    this.isTaxReliefRadioButton = null;
    this.isDeductionStatutoryButton = null;
    this.theDeductionType = null;
    this.allDeductionMonths = [];
    this.editingDeductions = false;
    this.deductionToEdit = null;
  }

  DeleteDeduction(item: Deduction) {
    this.confirmationService.confirm({
      message: "Are you sure you want to remove deduction?",
      accept: () => {
        this.messageService.add({
          severity: "info",
          summary: "Notice",
          detail: "Removing deduction...",
        });
        this.ResetMessageToasters();

        this.payrollService.DeleteDeduction(item.id).subscribe(
          async () => {
            this.messageService.add({
              severity: "success",
              summary: "Removed",
              detail: "Removed successfully",
            });
            this.ResetMessageToasters();

            this.fetchingDeductions = true;
            const index = this.allDeductions.indexOf(item);
            if (index > -1) {
              this.allDeductions.splice(index, 1);
            }
            this.fetchingDeductions = false;
          },
          (error) => {
            console.log("Error: " + JSON.stringify(error));
            this.messageService.add({
              severity: "error",
              summary: "Notice",
              detail:
                "Unable to remove deduction at the moment.. Reason: [" + error
                  ? error.error.message
                  : "request failed - permission" + "]",
            });
            this.ResetMessageToasters();
          }
        );
      },
    });
  }

  EditDeduction(item: Deduction) {
    this.editingDeductions = true;
    this.deductionForm.patchValue({
      Caption: item.caption,
      Description: item.description,
    });
    this.allDeductionMonths = this.preLoadAllPayMonths;
    this.theDeductionFrequency = this.allFrequencies.find(
      (x) => x.key == item.frequency
    );
    this.theDeductionMonth = this.allDeductionMonths.find(
      (x) => x.key == item.deductionMonth
    );
    this.theDeductionAccount =
      item.accountType == 1
        ? this.allAssetAccounts.find((x) => x.id == item.accountId)
        : this.allLiabilityAccounts.find((x) => x.id == item.accountId);
    this.theDeductionType = this.allDeductionTypes.find(
      (x) => x.key == item.deductionType
    );
    this.isAccountTypeRadioButton = item.accountType;
    this.isTaxReliefRadioButton = item.isTaxRelief
      ? (this.isTaxReliefRadioButton = 1)
      : (this.isTaxReliefRadioButton = 0);
    this.isDeductionStatutoryButton = item.isStatutory
      ? (this.isDeductionStatutoryButton = 1)
      : (this.isDeductionStatutoryButton = 0);
    this.deductionToEdit = item;

    this.formWrapper.nativeElement.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "start",
    });
  }

  OnDeductionFrequencyChange() {
    if (this.theDeductionFrequency) {
      if (this.theDeductionFrequency.key == PayrollFrequencyEnum.Monthly) {
        this.allDeductionMonths = [
          {
            key: PayrollMonthEnum.FirstMonth,
            value: "First Month",
          },
        ];
        this.theDeductionMonth = null;
      } else if (
        this.theDeductionFrequency.key == PayrollFrequencyEnum.Quarterly
      ) {
        this.allDeductionMonths = [
          {
            key: PayrollMonthEnum.FirstMonth,
            value: "First Month",
          },
          {
            key: PayrollMonthEnum.SecondMonth,
            value: "Second Month",
          },
          {
            key: PayrollMonthEnum.ThirdMonth,
            value: "Third Month",
          },
        ];
        this.theDeductionMonth = null;
      } else if (
        this.theDeductionFrequency.key == PayrollFrequencyEnum.HalfYearly
      ) {
        this.allDeductionMonths = [
          {
            key: PayrollMonthEnum.FirstMonth,
            value: "First Month",
          },
          {
            key: PayrollMonthEnum.SecondMonth,
            value: "Second Month",
          },
          {
            key: PayrollMonthEnum.ThirdMonth,
            value: "Third Month",
          },
          {
            key: PayrollMonthEnum.FourthMonth,
            value: "Fourth Month",
          },
          {
            key: PayrollMonthEnum.FifthMonth,
            value: "Fifth Month",
          },
          {
            key: PayrollMonthEnum.SixthMonth,
            value: "Sixth Month",
          },
        ];
        this.theDeductionMonth = null;
      } else if (
        this.theDeductionFrequency.key == PayrollFrequencyEnum.Yearly
      ) {
        this.allDeductionMonths = [
          {
            key: PayrollMonthEnum.FirstMonth,
            value: "First Month",
          },
          {
            key: PayrollMonthEnum.SecondMonth,
            value: "Second Month",
          },
          {
            key: PayrollMonthEnum.ThirdMonth,
            value: "Third Month",
          },
          {
            key: PayrollMonthEnum.FourthMonth,
            value: "Fourth Month",
          },
          {
            key: PayrollMonthEnum.FifthMonth,
            value: "Fifth Month",
          },
          {
            key: PayrollMonthEnum.SixthMonth,
            value: "Sixth Month",
          },
          {
            key: PayrollMonthEnum.SeventhMonth,
            value: "Seventh Month",
          },
          {
            key: PayrollMonthEnum.EighthMonth,
            value: "Eighth Month",
          },
          {
            key: PayrollMonthEnum.NinethMonth,
            value: "Nineth Month",
          },
          {
            key: PayrollMonthEnum.TenthMonth,
            value: "Tenth Month",
          },
          {
            key: PayrollMonthEnum.EleventhMonth,
            value: "Eleventh Month",
          },
          {
            key: PayrollMonthEnum.TwelvethMonth,
            value: "Twelveth Month",
          },
        ];
        this.theDeductionMonth = null;
      }
    } else {
      this.allDeductionMonths = [];
      this.theDeductionMonth = null;
    }
  }
}
