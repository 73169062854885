import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import {
  ServiceCategoryTask,
  ServiceCategory,
  EndorsementType,
  CommonResponse,
} from "../../../interfaces/home";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { ConfirmationService, MessageService } from "primeng/api";
import { ServiceCategoryService } from "../../../services/service-category.service";
import { ServiceCategoryTaskService } from "../../../services/service-category-task.service";
import { BreadcrumbService } from "src/app/breadcrumb.service";
import { EndorsementTypeService } from "src/app/services/endorsement-type.service";
import { resourceLimits } from "worker_threads";

@Component({
  selector: "app-service-category-task",
  templateUrl: "./service-category-task.component.html",
  styleUrls: ["./service-category-task.component.scss"],
  providers: [MessageService, ConfirmationService],
})
export class ServiceCategoryTaskComponent implements OnInit {
  @ViewChild("formWrapper") public formWrapper: ElementRef;
  serviceCategories: ServiceCategory[];
  selectedServiceCategoryId: ServiceCategory = null;
  fetchingServiceCategoryTask = true;
  serviceCategoryTaskForm: FormGroup;
  serviceCategoryTasks: ServiceCategoryTask[];
  selectedServiceCategoryTask: ServiceCategoryTask;
  editingServiceCategoryTask = false;
  public serviceCategoryTaskCols: any[];
  public exportServiceCategoryTaskColumns: any[];
  private edServiceCategoryTask: ServiceCategoryTask;
  endorsementTypes: EndorsementType[];
  selectedEndorsementType: EndorsementType;
  constructor(
    private formBuilder: FormBuilder,
    private serviceCategoryTaskService: ServiceCategoryTaskService,
    public messageService: MessageService,
    public confirmationService: ConfirmationService,
    private serviceCategoryService: ServiceCategoryService,
    private endorsementTypeService: EndorsementTypeService,
    private breadcrumbService: BreadcrumbService
  ) {}

  ngOnInit(): void {
    this.breadcrumbService.setItems([
      { label: "Setup", routerLink: ["home/service-category-task"] },
      { label: "Services", routerLink: ["home/service-category-task"] },
      {
        label: "Service Category Task",
        routerLink: ["home/service-category-task"],
      },
    ]);
    this.fetchEndorsementTypes();
    this.fetchServiceCategories();
    this.fetchServiceCategoryTask();
    this.serviceCategoryTaskForm = this.formBuilder.group({
      description: ["", Validators.required],
      caption: ["", [Validators.required, Validators.minLength(3)]],
      serviceCategoryId: ["", [Validators.required]],
      endorsementTypeId: [null, Validators.required],
    });
    this.serviceCategoryTaskCols = [
      { field: "caption", header: "Caption" },
      { field: "description", header: "Description" },
      { field: "serviceCategory", header: "Service Category" },
      { field: "endorsementType", header: "Endorsement Type" },
    ];
    this.exportServiceCategoryTaskColumns = this.serviceCategoryTaskCols.map(
      (col) => ({ title: col.header, dataKey: col.field })
    );
  }
  fetchEndorsementTypes() {
    this.endorsementTypeService
      .allEndorsementTypeData()
      .subscribe(async (res: CommonResponse) => {
        this.endorsementTypes = res.responseData;
      });
  }
  async fetchServiceCategories() {
    this.serviceCategoryService.allServiceCategory().subscribe(
      (result: CommonResponse) => {
        if (result.responseCode == "00") {
          this.serviceCategories = result.responseData;
        } else {
          this.messageService.add({
            severity: "error",
            summary: "Failed",
            detail: result.responseMsg,
          });
        }
      },
      (error) => {
        this.messageService.add({
          severity: "error",
          summary: "Failed",
          detail: error.error.message,
        });
      }
    );
  }

  fetchServiceCategoryTask() {
    this.serviceCategoryTaskService.allServiceCategoryTask().subscribe(
      async (serviceCategoryTask: any) => {
        this.serviceCategoryTasks = serviceCategoryTask.responseData;
        this.fetchingServiceCategoryTask = false;
      },
      (error) => {
        this.fetchingServiceCategoryTask = false;
        this.connectionError();
      }
    );
  }

  private connectionError() {
    this.messageService.add({
      severity: "error",
      summary: "Failed",
      detail: "Connection Error, Please try again",
    });
  }

  createServiceCategoryTask() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Creating new Service Category Task Records",
    });
    const postData = {
      description: this.serviceCategoryTaskForm.get("description").value,
      caption: this.serviceCategoryTaskForm.get("caption").value,
      serviceCategoryId: this.selectedServiceCategoryId.id,
      endorsementTypeId: this.selectedEndorsementType.id,
    };
    this.serviceCategoryTaskService.postServiceCategoryTask(postData).subscribe(
      async (r: CommonResponse) => {
        if (r.responseCode == "00") {
          await this.messageService.add({
            severity: "success",
            summary: "Completed",
            detail: "New Service Category Task Created",
          });
          await this.fetchServiceCategoryTask();
          await this.serviceCategoryTaskForm.reset();
        } else {
          this.messageService.add({
            severity: "error",
            summary: "Failure",
            detail: r.responseMsg,
          });
        }
      },
      (error) => {
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail: "Unable to create new Service Category Task at the moment",
        });
      }
    );
  }

  deleteServiceCategoryTask(serviceCategoryTask) {
    this.confirmationService.confirm({
      message:
        "Are you sure you want to delete <strong>" +
        serviceCategoryTask.caption +
        "</strong>",
      accept: () => {
        this._deleteServiceCategoryTask(serviceCategoryTask);
      },
    });
  }

  _deleteServiceCategoryTask(serviceCategoryTask) {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Deleting Service Category Task record",
    });
    this.serviceCategoryTaskService
      .deleteServiceCategoryTask(serviceCategoryTask.id)
      .subscribe(
        async (result: any) => {
          await this.messageService.add({
            severity: "success",
            summary: "Deleted",
            detail: "Service Category Task record removed",
          });
          await this.fetchServiceCategoryTask();
        },
        (error) => {
          this.connectionError();
        }
      );
  }

  editServiceCategoryTask(serviceCategoryTasks) {
    this.editingServiceCategoryTask = true;
    this.serviceCategoryTaskForm.addControl(
      "serviceCategoryTaskId",
      new FormControl({ value: "", disabled: true }, Validators.required)
    );
    this.edServiceCategoryTask = serviceCategoryTasks;
    this.serviceCategoryTaskForm.patchValue({
      caption: serviceCategoryTasks.caption,
      description: serviceCategoryTasks.description,
      serviceCategoryTaskId: serviceCategoryTasks.id,
    });
    this.selectedServiceCategoryId = this.serviceCategories.find(
      (serviceCategory) =>
        serviceCategory.id === serviceCategoryTasks.serviceCategory.id
    );
    this.selectedEndorsementType = this.endorsementTypes.find(
      (x) => x.id === serviceCategoryTasks.endorsementType.id
    );
    this.formWrapper.nativeElement.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "start",
    });
  }

  updateServiceCategoryTask() {
    // update the selected serviceCategoryTask
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Updating Service Category Task details",
    });
    const id = this.edServiceCategoryTask.id;
    const postData = {
      caption: this.serviceCategoryTaskForm.get("caption").value,
      description: this.serviceCategoryTaskForm.get("description").value,
      serviceCategoryId: this.selectedServiceCategoryId.id,
      endorsementTypeId: this.selectedEndorsementType.id,
    };
    this.serviceCategoryTaskService
      .updateServiceCategoryTask(id, postData)
      .subscribe(async (r: CommonResponse) => {
        if (r.responseCode == "00") {
          this.messageService.add({
            severity: "success",
            summary: "Notice",
            detail: "Service Category Task details Updated",
          });
          await this.fetchServiceCategoryTask();
          await this.closeEditing();
        } else {
          this.messageService.add({
            severity: "success",
            summary: "Failure",
            detail: r.responseMsg,
          });
        }
      });
  }

  closeEditing() {
    this.editingServiceCategoryTask = false;
    this.serviceCategoryTaskForm.reset();
    this.edServiceCategoryTask = null;
  }
}
