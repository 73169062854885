import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable()
export class EngagementServiceService {

  constructor(public http:HttpClient) { }

  getDashboard(suspectId:number){
    return this.http.get(
      environment.baseUrl + "contact/GetDashBoardSummary/"+suspectId
    )
  }
}
