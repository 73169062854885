import {
  ActOnBatchRequest,
  ApprovalLog,
  ApprovingOffice,
  BatchRequestItem,
  CentralStore,
  ItemDetail,
  RegionalStore,
  StockBatch,
} from "./../../../interfaces/inventory";
import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { ConfirmationService, MessageService } from "primeng/api";
import { BreadcrumbService } from "src/app/breadcrumb.service";
import { InventoryService } from "src/app/services/inventory.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";

@Component({
  selector: "app-batch-approval",
  templateUrl: "./batch-approval.component.html",
  styleUrls: ["./batch-approval.component.scss"],
})
export class BatchApprovalComponent implements OnInit {
  @ViewChild("formWrapper") public formWrapper: ElementRef;
  form: FormGroup;
  formAccept: FormGroup;
  allPendingBatchRequest: StockBatch[];
  selectedPendingBatchRequest: StockBatch;
  batchRequestCols: any[];
  batchRequestItemCols: any[];
  batchRequestAppOfficeCols: any[];
  fetchingPendingBatchReq: boolean = true;
  batchToActOn: StockBatch;
  batchToActOnItems: BatchRequestItem[];
  selectedBatchToActOnItem: BatchRequestItem[] = [];
  //approvedBatchItemList: BatchRequestItem[];
  batchToActOnApprovalOffices: ApprovingOffice[];
  selectedBatchToActOnApprovalOffice: ApprovingOffice;
  loadingApprovalLevelDetails: boolean = true;
  itemDetail: ItemDetail;
  detailTable: {
    name: string;
    data: string;
  }[];
  selectedDetailTable: {
    name: string;
    data: string;
  };
  detailTable2: {
    name: string;
    data: string;
  }[];
  selectedDetailTable2: {
    name: string;
    data: string;
  };
  openDialogue: boolean;
  allCentralStores: CentralStore[];
  allRegionalStores: RegionalStore[];
  openAcceptDialogue: boolean;
  openDocDialogue: boolean;
  docBatchRequest: StockBatch;

  constructor(
    fb: FormBuilder,
    public inventoryService: InventoryService,
    public messageService: MessageService,
    private breadcrumbService: BreadcrumbService,
    public confirmationService: ConfirmationService
  ) {
    this.form = fb.group({
      Comment: ["", Validators.required],
    });
    this.formAccept = fb.group({
      Comment: ["", Validators.required],
    });
  }

  ngOnInit(): void {
    this.breadcrumbService.setItems([
      {
        label: "Inventory Management",
        routerLink: ["/home/inventory-management/set-up"],
      },
      {
        label: "Batch Approval",
        routerLink: ["/home/inventory-management/batch-approval"],
      },
    ]);

    this.batchRequestCols = [
      { field: "name", header: "Name" },
      { field: "description", header: "Description" },
      { field: "batchDescription", header: "BatchDescription" },
    ];

    this.batchRequestItemCols = [
      { field: "item.name", header: "Item.name" },
      { field: "item.description", header: "Item.description" },
      { field: "quantity", header: "Quantity" },
      { field: "unitPrice", header: "UnitPrice" },
    ];

    this.detailTable = [
      {
        name: "Item",
        data: "Data",
      },
      {
        name: "Item Code",
        data: "Data",
      },
    ];

    this.detailTable2 = [
      {
        name: "Store",
        data: "Data",
      },
      {
        name: "Item Count",
        data: "Data",
      },
      {
        name: "Reorder Level",
        data: "Data",
      },
    ];

    //this.approvedBatchItemList = [];
    this.batchRequestAppOfficeCols = [{ field: "name", header: "Name" }];

    this.FetchAllBatchPendingApproval();
    this.FetchAllInventoryStores();
  }

  FetchAllBatchPendingApproval() {
    this.inventoryService.GetPendingBatchRequestApproval().subscribe(
      (data) => {
        this.allPendingBatchRequest = data;
        this.fetchingPendingBatchReq = false;
      },
      (error) => {
        this.messageService.add({
          severity: "error",
          summary: "Failed",
          detail:
            "Connection Error, Unable to fetch all batch requests pending approval " +
            (error ? error.error.message : "request failed - permission"),
        });
        this.fetchingPendingBatchReq = false;
      }
    );
  }

  PickBatchToActOn(item: StockBatch) {
    this.batchToActOn = item;
    this.batchToActOnItems = [];
    if (item.approval.isApprovalStarted) {
      this.batchToActOnItems = [];
      item.itemsRequested.forEach((item) => {
        if (item.isApproved) this.batchToActOnItems.push(item);
      });
    } else {
      this.batchToActOnItems = item.itemsRequested;
    }

    this.batchToActOnApprovalOffices = [];

    item.approval.approvalConfiguration.approvalConfigurationEntities.forEach(
      (app) => {
        this.batchToActOnApprovalOffices.push(app.approvingOffice);
      }
    );

    this.loadingApprovalLevelDetails = false;
  }

  RunApprovalOfficeCheck(approvingOfficeid: number): ApprovalLog {
    return this.batchToActOn.approval.approvalLogs.find(
      (log) => log.approvingOfficeId == approvingOfficeid
    );
  }

  LoadItemStockCount(item: BatchRequestItem) {
    this.itemDetail = {
      storeName: this.batchToActOn.name,
      itemImage: item.item.itemImage,
    };
    this.detailTable[0].data = item.item.caption;
    this.detailTable[1].data = item.item.code;
    this.detailTable2[0].data = this.batchToActOn.centralStoreId
      ? this.allCentralStores.find(
          (data) => data.id == this.batchToActOn.centralStoreId
        ).storeName
      : this.allRegionalStores.find(
          (data) => data.id == this.batchToActOn.regionalStoreId
        ).regionalStoreName;
    this.detailTable2[1].data = "~";
    this.detailTable2[2].data = "~";
  }

  ShowDeclineBatch() {
    this.openDialogue = true;
  }

  HideDeclineBatchDialogue() {
    this.openDialogue = false;
  }

  HideAcceptBatchDialogue() {
    this.openAcceptDialogue = false;
  }

  DeclineBatch() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Declining Batch Request" + this.batchToActOn.name + "...",
    });

    const postData: ActOnBatchRequest = {
      isApproved: false,
      comment: this.form.get("Comment").value,
      isCentralStore: true,
      batch: this.batchToActOn,
      approvedBatchItemsIds: [],
    };

    this.inventoryService.ActOnInventoryBatch(postData).subscribe(
      async () => {
        await this.messageService.add({
          severity: "success",
          summary: "Completed",
          detail: "Batch Request Declined Successfully.",
        });

        this.FetchAllBatchPendingApproval();
        this.batchToActOn = null;
        this.itemDetail = null;
        this.openDialogue = false;
      },
      (error) => {
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to decline batch request at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
        this.openDialogue = false;
      }
    );
  }

  ApproveBatch() {
    if (this.selectedBatchToActOnItem.length == 0) {
      this.messageService.add({
        severity: "error",
        summary: "Notice",
        detail:
          "Unable to approve batch request at the moment.. Reason: [You must approve at least one batch item to proceed.]",
      });
      return;
    }

    if (this.batchToActOnItems.length != this.selectedBatchToActOnItem.length) {
      this.openAcceptDialogue = true;
    } else {
      this.confirmationService.confirm({
        message:
          "Kindly Confirm that you would like to proceed with this batch approval?",
        header: "Approve Batch Request",
        accept: () => {
          this._performBatchApproval();
        },
      });
    }
  }

  _performBatchApproval() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Approving Batch Request" + this.batchToActOn.name + "...",
    });

    let approvedBatchItemIds: number[] = [];
    this.selectedBatchToActOnItem.forEach((approved) => {
      approvedBatchItemIds.push(approved.id);
    });

    const postData: ActOnBatchRequest = {
      isApproved: true,
      isCentralStore: true,
      batch: this.batchToActOn,
      approvedBatchItemsIds: approvedBatchItemIds,
      comment: this.formAccept.get("Comment").value,
    };

    this.inventoryService.ActOnInventoryBatch(postData).subscribe(
      async () => {
        await this.messageService.add({
          severity: "success",
          summary: "Completed",
          detail: "Batch Request Approved Successfully.",
        });

        this.openAcceptDialogue = false;
        this.FetchAllBatchPendingApproval();
        this.batchToActOn = null;
        this.itemDetail = null;
        this.selectedBatchToActOnItem = [];
      },
      (error) => {
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to approve batch request at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  FetchAllInventoryStores() {
    this.inventoryService.GetAllInventoryStores().subscribe(
      (data) => {
        this.allCentralStores = data.centralStores;
        this.allRegionalStores = data.regionalStores;
      },
      (error) => {
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all inventory stores at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  UpdateApprovedToList(item: BatchRequestItem) {
    // if (this.approvedBatchItemList.indexOf(item) > -1) {
    //   this.approvedBatchItemList.splice(
    //     this.approvedBatchItemList.indexOf(item),
    //     1
    //   );
    // } else {
    //   this.approvedBatchItemList.push(item);
    // }
  }

  ViewDocs(item: StockBatch) {
    this.openDocDialogue = true;
    this.docBatchRequest = item;
  }

  HideDocDialog() {
    this.openDocDialogue = false;
    this.docBatchRequest = null;
  }

  GetBatchType(item: StockBatch): string {
    if (item.centralStoreId)
      if (item.regionalStoreId) return "Regional";
      else return "Central";
    else if (item.regionalStoreId)
      if (item.userRequestedId) return "User";
      else return "Regional";
    else if (item.userRequestedId) return "User";

    return "N/A";
  }
}
