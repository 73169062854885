import { Injectable } from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {SupplierServices} from '../../interfaces/armada';
import {SupplierService} from './supplier.service';

@Injectable({
  providedIn: 'root'
})
export class SupplierServicesService {

  constructor(private http: HttpClient) { }
  baseUrl = environment.baseUrl+'SupplierService/';
  allSupplierService():Observable<SupplierServices> {
    return this.http.get<SupplierServices>(this.baseUrl);
  }
}
