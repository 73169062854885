import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable()
export class DeliverableDashboardService {

  constructor(private http: HttpClient) { }

  getDeliverableDashboard(year,month){
    return this.http.get(
      environment.baseUrl +"contact/GetDeliverableDashboard/"+year+"/"+month
    )
    
  }
  
}
