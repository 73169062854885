import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../environments/environment";
import { Observable } from "rxjs";
import { CommonResponse, PostDateEndrosementForApprovalVM } from "../interfaces/home";

@Injectable({
  providedIn: "root",
})
export class EndorsementService {
  constructor(private http: HttpClient) {}
  baseUrl = environment.baseUrl + "Endorsement/";

  allUnapprovedEndorsements(): Observable<CommonResponse> {
    return this.http.get<CommonResponse>(
      this.baseUrl + "UnApprovedEndorsements"
    );
  }

  //GetNewContractAdditionEndorsement
  getEndorsementDetails(endorsementId: number): Observable<CommonResponse> {
    return this.http.get<CommonResponse>(
      this.baseUrl + "GetEndorsementDetails/" + endorsementId
    );
  }

  getEndorsementOnServiceAddition(
    endorsementId: number
  ): Observable<CommonResponse> {
    return this.http.get<CommonResponse>(
      this.baseUrl + "GetEndorsementServiceAddition/" + endorsementId
    );
  }

  getNewContractAdditionEndorsement(
    customerDivisionId: number
  ): Observable<CommonResponse> {
    return this.http.get<CommonResponse>(
      this.baseUrl + "GetNewContractAdditionEndorsement/" + customerDivisionId
    );
  }

  GetNewContractAdditionEndorsementV2(
    customerDivisionId: number
  ): Observable<CommonResponse> {
    return this.http.get<CommonResponse>(
      this.baseUrl + "GetNewContractAdditionEndorsementV2/" + customerDivisionId
    );
  }

  getEndorsementPossibleStartDates(contractServiceId: any) {
    return this.http.get(
      this.baseUrl + "EndorsementPossibleStartDates/" + contractServiceId
    );
  }

  approveEndorsement(id: any, sequence: any) {
    return this.http.put(
      this.baseUrl + "ApproveEndorsement/" + id + "/" + sequence,
      {}
    );
  }

  declineEndorsementApproval(id: any, sequence: any) {
    return this.http.put(
      this.baseUrl + "DeclineEndorsementApproval/" + id + "/" + sequence,
      {}
    );
  }

  postEndorsement(data: any) {
    return this.http.post(this.baseUrl, data);
  }

  endorsementConvertToContract(contractServiceForEndorsementId: any) {
    return this.http.put(
      this.baseUrl + "ConverToContract/" + contractServiceForEndorsementId,
      {}
    );
  }

  endorsementConvertDebitCreditNote(contractServiceForEndorsementId: any) {
    return this.http.put(
      this.baseUrl +
        "ConvertDebitCreditNote/" +
        contractServiceForEndorsementId,
      {}
    );
  }

  endorsementopeningClosing(data: any) {
    return this.http.post(
      this.baseUrl + "PostEndorsementOpeningAndClosing",
      data,
      {}
    );
  }

  PostDateEndrosementForApproval(
    data: PostDateEndrosementForApprovalVM
  ): Observable<CommonResponse<boolean>> {
    return this.http.post<CommonResponse<boolean>>(
      this.baseUrl + "PostDateEndrosementForApproval",
      data
    );
  }
}
