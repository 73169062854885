import { Component, OnInit } from '@angular/core';
import {DynamicDialogConfig, DynamicDialogRef} from 'primeng/dynamicdialog';
import {StrategicBusinessUnit, User} from '../../../../interfaces/home';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ConfirmationService} from 'primeng/api';

@Component({
    selector: 'app-attach-user-sbu',
    templateUrl: './attach-user-sbu.component.html',
    styleUrls: ['./attach-user-sbu.component.css'],
    providers: [ConfirmationService]
})

export class AttachUserSbuComponent implements OnInit {

    attachUserToSBUForm: FormGroup;
    selectedSBU: StrategicBusinessUnit;
    selectedUser: User;
    users: User[];
    sbus: StrategicBusinessUnit[];

    constructor(public config: DynamicDialogConfig,
                private formBuilder: FormBuilder,
                public attachModalRef: DynamicDialogRef,
                public confirmationService: ConfirmationService
    ) {}

    ngOnInit(): void {
        this.sbus = this.config.data.sbus;
        this.users = this.config.data.users;

        this.users.forEach(x => {
            x.fullName = `${x.firstName} ${x.lastName}`
        });

        this.attachUserToSBUForm = this.formBuilder.group ({
            sbu: [null, Validators.required],
            user: [null, Validators.required]
        });
    }

    closeAttachUserToSBU() {
        this.attachModalRef.close();
    }

    attachUserToSbu() {
        this.attachModalRef.close(this.attachUserToSBUForm.value);
    }
}
