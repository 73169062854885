import { SupplierCategoryService } from "./../../../services/supplier-category.service";
import { SupplierCategory } from "./../../../interfaces/supplier";
import { Component, OnInit, ElementRef, ViewChild } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { ConfirmationService, MessageService } from "primeng/api";
import { BreadcrumbService } from "src/app/breadcrumb.service";

@Component({
  selector: "app-supplier-category",
  templateUrl: "./supplier-category.component.html",
  styleUrls: ["./supplier-category.component.scss"],
})
export class SupplierCategoryComponent implements OnInit {
  @ViewChild("formWrapper") public formWrapper: ElementRef;
  form: FormGroup;
  editing = false;
  fetching = true;
  supplierCategories: SupplierCategory[];
  selectedSupplierCategory: SupplierCategory;
  cols: any[];
  private supplierCategoryToEdit: SupplierCategory;

  constructor(
    fb: FormBuilder,
    public messageService: MessageService,
    public supplierCategoryService: SupplierCategoryService,
    private breadcrumbService: BreadcrumbService,
    public confirmationService: ConfirmationService
  ) {
    this.form = fb.group({
      Name: ["", Validators.required],
      Description: ["", Validators.required],
    });
  }

  ngOnInit(): void {
    this.breadcrumbService.setItems([
      { label: "Supplier" },
      { label: "Category", routerLink: ["home/supplier/category"] },
    ]);
    this.cols = [
      { field: "name", header: "Name" },
      { field: "description", header: "Description" },
    ];

    this.fetchingAllSupplierCategories();
  }

  createSupplierCategory() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Creating Supplier Category...",
    });

    const postData: SupplierCategory = {
      categoryName: this.form.get("Name").value,
      description: this.form.get("Description").value,
    };

    this.supplierCategoryService.create(postData).subscribe(
      async () => {
        await this.messageService.add({
          severity: "success",
          summary: "Completed",
          detail: "New Supplier Category Created...",
        });

        this.fetchingAllSupplierCategories();
        this.form.reset();
      },
      (error) => {
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to create supplier category at the moment.. Reason: [" +
            JSON.stringify(error) +
            "]",
        });
      }
    );
  }

  fetchingAllSupplierCategories() {
    this.supplierCategoryService.getAll().subscribe(
      async (supplierCategories: any) => {
        this.supplierCategories = supplierCategories?.responseData;
        this.fetching = false;
      },
      (error) => {
        this.fetching = false;
        this.connectionError();
      }
    );
  }

  deleteSupplierCategory(item: SupplierCategory) {
    this.confirmationService.confirm({
      message: "Are you sure you want to remove " + item.categoryName,
      accept: () => {
        this.messageService.add({
          severity: "info",
          summary: "Notice",
          detail: "Removing supplier category " + item.categoryName,
        });
        this.supplierCategoryService.delete(item.id).subscribe(
          async () => {
            await this.messageService.add({
              severity: "success",
              summary: "Removed",
              detail: item.categoryName + " removed successfully",
            });
            await this.fetchingAllSupplierCategories();
          },
          (error) => {
            this.connectionError();
          }
        );
      },
    });
  }

  editSupplierCategory(item: SupplierCategory) {
    this.editing = true;
    this.form.addControl(
      "ID",
      new FormControl({ value: "", disabled: true }, Validators.required)
    );
    this.supplierCategoryToEdit = item;
    this.form.patchValue({
      ID: item.id,
      Name: item.categoryName,
      Description: item.description,
    });
    this.formWrapper.nativeElement.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "start",
    });
  }

  updateSupplierCategory() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Updating Selected Supplier Category...",
    });
    const id = this.supplierCategoryToEdit.id;
    const postData: SupplierCategory = {
      categoryName: this.form.get("Name").value,
      description: this.form.get("Description").value,
    };
    this.supplierCategoryService.update(id, postData).subscribe(
      async (result: SupplierCategory) => {
        this.messageService.add({
          severity: "success",
          summary: "Notice",
          detail: "Update Successful!",
        });
        await this.fetchingAllSupplierCategories();
        await this.closeEditing();
      },
      (error) => {
        this.messageService.add({
          severity: "error",
          summary: "Unable To Update",
          detail:
            "Unable to update supplier category at the moment.. Reason: [" +
            JSON.stringify(error) +
            "]",
        });
      }
    );
  }

  closeEditing() {
    this.editing = false;
    this.form.reset();
    this.supplierCategoryToEdit = null;
  }

  private connectionError() {
    this.messageService.add({
      severity: "error",
      summary: "Failed",
      detail: "Connection Error, Please try again",
    });
  }
}
