import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {CommonResponse, OperatingEntity, StrategicBusinessUnit} from '../../../interfaces/home';
import {ConfirmationService, MessageService} from 'primeng/api';
import {StrategicBusinessUnitService} from '../../../services/strategic-business-unit.service';
import {OperatingEntityService} from '../../../services/operating-entity.service';
import { BreadcrumbService } from 'src/app/breadcrumb.service';

@Component({
  selector: 'app-sbu',
  templateUrl: './sbu.component.html',
  styleUrls: ['./sbu.component.scss'],
  providers: [ConfirmationService, MessageService]
})
export class SBUComponent implements OnInit {
  @ViewChild('formWrapper') public formWrapper:ElementRef;
  fetchingSbUnit = true;
  sbUnitForm: FormGroup;
  sbUnits: StrategicBusinessUnit[];
  selectedSbUnit: StrategicBusinessUnit;
  selectedOperatingEntity: OperatingEntity = null;
  operatingEntities: OperatingEntity[];
  editingSbUnit= false;
  public sbUnitCols: any[];
  public exportSbUnitColumns: any[];
  private edSbUnit: StrategicBusinessUnit;
  selectedOperatingEntityId: OperatingEntity = null;
  constructor(
      private formBuilder: FormBuilder,
      private sbUnitService: StrategicBusinessUnitService,
      public messageService: MessageService,
      public confirmationService: ConfirmationService,
      private operatingEntityService: OperatingEntityService,
      private breadcrumbService: BreadcrumbService
      ) { }
  
      ngOnInit(): void {
          this.breadcrumbService.setItems([
              { label: 'Setup', routerLink: ['home/SBU'] },
              { label: 'Strategic Business Unit', routerLink: ['home/SBU'] }
            ]);
  
    this.fetchSbUnit();
    this.fetchOperatingEntity();
    this.sbUnitForm = this.formBuilder.group({
      description: ['', Validators.required],
      name: ['', [Validators.required]],
      operatingEntityId: [null, Validators.required],
    });
    this.sbUnitCols = [
      { field: 'name', header: 'Name' },
      { field: 'description', header: 'Description' },
      { field: 'operatingEntity', header: 'Operating Entity' },
    ];
    this.exportSbUnitColumns = this.sbUnitCols.map(col => ({title: col.header, dataKey: col.field}));
  }
  async fetchOperatingEntity(){
    await this.operatingEntityService.allData().subscribe( async (result: CommonResponse) => {
      this.operatingEntities = result.responseData;
    }, error => {})
  }
  fetchSbUnit(){
    this.sbUnitService.allStrategicBusinessUnit().subscribe( async (sbUnit: CommonResponse) =>{
      this.sbUnits = sbUnit.responseData;
      this.fetchingSbUnit = false;
    }, error => {
      this.fetchingSbUnit = false;
      this.connectionError();
    })
  }
  private connectionError() {
    this.messageService.add({severity:'error', summary: 'Failed',
      detail:'Connection Error, Please try again'});
  }
  createSbUnit(){
    this.messageService.add({severity:'info', summary: 'Notice',
      detail:'Creating new Strategic Business Unit Records'});
    const postData = {
      description: this.sbUnitForm.get('description').value,
      name: this.sbUnitForm.get('name').value,
      operatingEntityId: this.selectedOperatingEntityId.id,
    };
    this.sbUnitService.postStrategicBusinessUnit(postData).subscribe( async () =>{
      await this.messageService.add({severity:'success', summary: 'Completed',
        detail:'New Strategic Business Unit Created'});
      await this.fetchSbUnit();
      await this.sbUnitForm.reset();
    }, error => {
      this.messageService.add({severity:'error', summary: 'Notice',
        detail:'Unable to create new Strategic Business Unit at the moment'});
    });
  }

  deleteSbUnit(sbUnit) {
    this.confirmationService.confirm({
      message: 'Are you sure you want to delete ' + sbUnit.name,
      accept: () => {
        this._deleteSbUnit(sbUnit);
      }
    });
  }
  _deleteSbUnit(sbUnit) {
    this.messageService.add({severity:'info', summary: 'Notice',
      detail:'Deleting sbUnit record'});
    this.sbUnitService.deleteStrategicBusinessUnit(sbUnit.id).subscribe( async (result:any) => {
      await this.messageService.add({severity:'success', summary: 'Deleted',
        detail:'Strategic Business Unit record removed'});
      await this.fetchSbUnit();
    }, error => {
      this.connectionError()
    });
  }
  editSbUnit(sbUnits) {
    this.editingSbUnit = true;
    this.sbUnitForm.addControl('sbUnitId',  new FormControl({value: '', disabled: true}, Validators.required));
    this.edSbUnit = sbUnits;
    this.sbUnitForm.patchValue({
      name: sbUnits.name,
      description: sbUnits.description,
      sbUnitId: sbUnits.id,
    });
    // this.sbUnitFormIn.nativeElement.focus();
    // document.getElementById('formFields').focus();
    this.selectedOperatingEntityId = this.operatingEntities.find(opEntity => opEntity.id === sbUnits.operatingEntityId);
    this.formWrapper.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'start' });
  }

  updateSbUnit() {
    // update the selected sbUnit
    this.messageService.add({severity:'info', summary: 'Notice',
      detail:'Updating SbUnit details'});
    const id = this.edSbUnit.id;
    const postData = {
      name: this.sbUnitForm.get('name').value,
      description: this.sbUnitForm.get('description').value,
      operatingEntityId: this.selectedOperatingEntityId.id,
    };
    this.sbUnitService.updateStrategicBusinessUnit(id, postData).subscribe( async (result: StrategicBusinessUnit) => {
      this.messageService.add({severity:'success', summary: 'Notice',
        detail:'Strategic Business Unit details Updated'});
      await this.fetchSbUnit();
      await this.closeEditing();
    })
  }

  closeEditing() {
    this.editingSbUnit = false;
    this.sbUnitForm.reset();
    this.edSbUnit = null;
  }

}
