import { Injectable } from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {JourneyStart} from '../../interfaces/armada';

@Injectable({
  providedIn: 'root'
})
export class JourneyStartService {
  baseUrl = environment.armadaBaseUrl+'JourneyStart/';
  constructor(private httpClient: HttpClient) { }
  allJourneyStart(): Observable<JourneyStart>{
    return this.httpClient.get<JourneyStart>(this.baseUrl);
  }
  getJourneyStart(id: any):Observable<JourneyStart>{
    return this.httpClient.get<JourneyStart>(this.baseUrl +id);
  }
  getJourneyStartByName(name: any):Observable<JourneyStart>{
    return this.httpClient.get<JourneyStart>(this.baseUrl + 'name/' + name);
  }
  postJourneyStart(data: any){
    return this.httpClient.post(this.baseUrl, data);
  }
  delete(id: any){
    return this.httpClient.delete(this.baseUrl +id);
  }
  updateJourneyStart(id: any, data: any){
    return this.httpClient.put(this.baseUrl +id, data);
  }
}
