import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MessageService, ConfirmationService } from "primeng/api";
import { BreadcrumbService } from "src/app/breadcrumb.service";
import { User } from "src/app/interfaces/home";
import {
  CentralStore,
  GetJustUserBatchRequestsVM,
  GetUserBatchRequestMovement,
  GetUserBatchRequestsApprover,
  GetUserBatchRequestsItems,
  GetUserBatchRequestsTransferDTO,
  GetUserBatchRequestsVM,
  RegionalStore,
  StockBatch,
  TrackCentralRequestVM,
  TrackRegionalRequestVM,
} from "src/app/interfaces/inventory";
import { InventoryService } from "src/app/services/inventory.service";
import { UserService } from "src/app/services/user.service";

@Component({
  selector: "app-batch-request-tracking",
  templateUrl: "./batch-request-tracking.component.html",
  styleUrls: ["./batch-request-tracking.component.scss"],
})
export class BatchRequestTrackingComponent implements OnInit {
  @ViewChild("formWrapper") public formWrapper: ElementRef;
  userReqForm: FormGroup;
  regionalReqForm: FormGroup;
  centralReqForm: FormGroup;
  allUsers: User[];
  theUserRequesters: User[] = [];
  allUserBatches: StockBatch[];
  selectedUserBatches: StockBatch[] = [];
  allUserReports: GetUserBatchRequestsTransferDTO[];
  selectedUserReports: GetUserBatchRequestsTransferDTO[];
  userReportCols: any[];
  allRegionalStores: RegionalStore[];
  selectedRegionalStores: RegionalStore[] = [];
  allSelectedRegionalBatches: StockBatch[];
  selectedRegionalBatches: StockBatch[] = [];
  allRegionalReports: GetUserBatchRequestsTransferDTO[];
  selectedRegionalReports: GetUserBatchRequestsTransferDTO[];
  regionalReportCols: any[];
  allCentralStores: CentralStore[];
  selectedCentralStores: CentralStore[] = [];
  allSelectedCentralBatches: StockBatch[];
  selectedCentralBatches: StockBatch[] = [];
  allCentralReports: GetUserBatchRequestsTransferDTO[];
  selectedCentralReports: GetUserBatchRequestsTransferDTO[] = [];
  centralReportCols: any[];
  fetchingUserReports: boolean;
  viewItemsSideBar: boolean;
  openItemsDialogue: boolean;
  itemsInBatchList: GetUserBatchRequestsItems[];
  selectedItemInBatchList: GetUserBatchRequestsItems[];
  itemsInBatchCols: any[];
  openApprovalsDialogue: boolean;
  batchApprovalsList: GetUserBatchRequestsApprover[];
  batchApprovalCols: any[];
  fetchingRegionalReports: boolean;
  fetchingCentralReports: boolean;
  openItemsMovementDialogue: boolean;
  itemMovementsList: GetUserBatchRequestMovement[];
  selectedItemMovementsList: GetUserBatchRequestMovement[];
  itemsMovementCols: any[];
  quantityLabel: string = "Quantity";

  constructor(
    fb: FormBuilder,
    public userService: UserService,
    public inventoryService: InventoryService,
    public messageService: MessageService,
    private breadcrumbService: BreadcrumbService,
    public confirmationService: ConfirmationService
  ) {
    this.userReqForm = fb.group({
      Requester: ["", Validators.required],
      Batch: ["", Validators.required],
    });

    this.regionalReqForm = fb.group({
      Requester: ["", Validators.required],
      Batch: ["", Validators.required],
    });

    this.centralReqForm = fb.group({
      Requester: ["", Validators.required],
      Batch: ["", Validators.required],
    });
  }

  ngOnInit(): void {
    this.breadcrumbService.setItems([
      {
        label: "Inventory Management",
        routerLink: ["/home/inventory-management/set-up"],
      },
      {
        label: "Batch Request Tracking",
        routerLink: ["/home/inventory-management/batch-request-tracking"],
      },
    ]);

    this.userReportCols = [
      { field: "batchName", header: "Batch Name" },
      { field: "batchNo", header: "Batch No" },
      { field: "itemCount", header: "Item Count" },
      { field: "fullname", header: "Requester" },
      { field: "lineApproval", header: "Has Line Manager Approval" },
      { field: "batchApproval", header: "Has Batch Approval" },
      { field: "outwardConfirmed", header: "Is Fully Dispatched" },
      { field: "inwardConfirmed", header: "Is Fully Confirmed" },
    ];

    this.regionalReportCols = [
      { field: "batchName", header: "Batch Name" },
      { field: "batchNo", header: "Batch No" },
      { field: "itemCount", header: "Item Count" },
      { field: "fullname", header: "Requester" },
      { field: "batchApproval", header: "Has Batch Approval" },
      { field: "outwardConfirmed", header: "Is Fully Dispatched" },
      { field: "inwardConfirmed", header: "Is Fully Confirmed" },
    ];

    this.centralReportCols = [
      { field: "batchName", header: "Batch Name" },
      { field: "batchNo", header: "Batch No" },
      { field: "itemCount", header: "Item Count" },
      { field: "fullname", header: "Requester" },
      { field: "batchApproval", header: "Has Batch Approval" },
      { field: "inwardConfirmed", header: "Is Fully Confirmed" },
    ];

    this.itemsInBatchCols = [
      { field: "itemImage", header: "Item Image Url" },
      { field: "itemName", header: "Item Name" },
      { field: "itemQuantity", header: "Quantity Requested" },
    ];

    this.batchApprovalCols = [
      { field: "approverImageUrl", header: "Image Url" },
      { field: "approverName", header: "Name" },
      { field: "dateTimeApproved", header: "Date/Time Approved" },
    ];

    this.itemsMovementCols = [
      { field: "itemImage", header: "Item Image Url" },
      { field: "itemName", header: "Item Name" },
      { field: "quantityRequested", header: "Quantity Requested" },
      { field: "quantityMoved", header: "Quantity" },
      { field: "message", header: "Action" },
    ];

    this.GetAllUsers();
    this.GetAllRegionalStores();
    this.GetAllCentralStores();
  }

  async GetAllUsers() {
    this.userService.allUser().subscribe(
      async (r) => {
        var data = r.responseData ?? [];
        this.allUsers = [];
        data.forEach((user) => {
          user.fullName = user.lastName + " " + user.firstName;
          this.allUsers.push(user);
        });
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all users at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  async GetAllRegionalStores() {
    this.inventoryService.GetAllRegionalInventoryStore().subscribe(
      async (data) => {
        this.allRegionalStores = data;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Failed",
          detail:
            "Unable to get all regional stores at the moment | reason: " + error
              ? error.error.message
              : "request failed - permission",
        });
      }
    );
  }

  async GetAllCentralStores() {
    this.inventoryService.GetAllCentralInventoryStore().subscribe(
      async (data) => {
        this.allCentralStores = data;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Failed",
          detail:
            "Unable to get all central stores at the moment | reason: " + error
              ? error.error.message
              : "request failed - permission",
        });
      }
    );
  }

  TrackUserRequest() {
    this.allUserReports = [];
    this.selectedUserReports = [];

    if (this.selectedUserBatches.length > 0) {
      this.fetchingUserReports = true;
      const postData: GetUserBatchRequestsVM = {
        userIds: this.theUserRequesters.map((x) => x.id),
        batchIds: this.selectedUserBatches.map((x) => x.id),
      };

      this.inventoryService.GetUserBatchRequests(postData).subscribe(
        async (data) => {
          this.allUserReports = data;
          this.fetchingUserReports = false;
        },
        (error) => {
          console.log("Error: " + JSON.stringify(error));
          this.messageService.add({
            severity: "error",
            summary: "Failed",
            detail:
              "Unable to get user request batches at the moment | reason: " +
              error
                ? error.error.message
                : "request failed - permission",
          });
          this.fetchingUserReports = false;
        }
      );
    }
  }

  TrackRegionalRequest() {
    this.allRegionalReports = [];
    this.selectedRegionalReports = [];

    if (this.selectedRegionalBatches.length > 0) {
      this.fetchingRegionalReports = true;
      const postData: TrackRegionalRequestVM = {
        regionalStoreIds: this.selectedRegionalStores.map((x) => x.id),
        batchIds: this.selectedRegionalBatches.map((x) => x.id),
      };

      this.inventoryService.TrackRegionalRequest(postData).subscribe(
        async (data) => {
          this.allRegionalReports = data;
          this.fetchingRegionalReports = false;
        },
        (error) => {
          console.log("Error: " + JSON.stringify(error));
          this.messageService.add({
            severity: "error",
            summary: "Failed",
            detail:
              "Unable to get regional request batches at the moment | reason: " +
              error
                ? error.error.message
                : "request failed - permission",
          });
          this.fetchingRegionalReports = false;
        }
      );
    }
  }

  TrackCentralRequest() {
    this.allCentralReports = [];
    this.selectedCentralReports = [];

    if (this.selectedCentralBatches.length > 0) {
      this.fetchingCentralReports = true;
      const postData: TrackCentralRequestVM = {
        centralStoreIds: this.selectedCentralStores.map((x) => x.id),
        batchIds: this.selectedCentralBatches.map((x) => x.id),
      };

      this.inventoryService.TrackCentralRequest(postData).subscribe(
        async (data) => {
          this.allCentralReports = data;
          this.fetchingCentralReports = false;
        },
        (error) => {
          console.log("Error: " + JSON.stringify(error));
          this.messageService.add({
            severity: "error",
            summary: "Failed",
            detail:
              "Unable to get central request batches at the moment | reason: " +
              error
                ? error.error.message
                : "request failed - permission",
          });
          this.fetchingCentralReports = false;
        }
      );
    }
  }

  OnUserRequesterChange() {
    this.allUserBatches = [];
    this.selectedUserBatches = [];
    this.allUserReports = [];
    this.selectedUserReports = [];
    if (this.theUserRequesters.length > 0) {
      const postData: GetJustUserBatchRequestsVM = {
        requestIds: this.theUserRequesters.map((x) => x.id),
        requestType: 1,
      };

      this.inventoryService.GetJustUserBatchRequests(postData).subscribe(
        async (data) => {
          this.allUserBatches = data;
        },
        (error) => {
          console.log("Error: " + JSON.stringify(error));
          this.messageService.add({
            severity: "error",
            summary: "Failed",
            detail:
              "Unable to get user batches at the moment | reason: " + error
                ? error.error.message
                : "request failed - permission",
          });
        }
      );
    }
  }

  ViewItemsInBatch(items: GetUserBatchRequestsItems[]) {
    this.viewItemsSideBar = true;
    this.itemsInBatchList = items;
    this.openItemsDialogue = true;
  }

  ViewApprovals(items: GetUserBatchRequestsApprover[]) {
    this.batchApprovalsList = items;
    this.openApprovalsDialogue = true;
  }

  HideItemDialog() {
    this.openItemsDialogue = false;
    this.itemsInBatchList = [];
    this.selectedItemInBatchList = [];
  }

  HideApprovalsDialog() {
    this.openApprovalsDialogue = false;
    this.batchApprovalsList = [];
  }

  OnRegionalStoreChange() {
    this.allSelectedRegionalBatches = [];
    this.selectedRegionalBatches = [];
    this.allRegionalReports = [];
    this.selectedRegionalReports = [];
    if (this.selectedRegionalStores.length > 0) {
      const postData: GetJustUserBatchRequestsVM = {
        requestIds: this.selectedRegionalStores.map((x) => x.id),
        requestType: 2,
      };

      this.inventoryService.GetJustUserBatchRequests(postData).subscribe(
        async (data) => {
          this.allSelectedRegionalBatches = data;
        },
        (error) => {
          console.log("Error: " + JSON.stringify(error));
          this.messageService.add({
            severity: "error",
            summary: "Failed",
            detail:
              "Unable to get regional batches at the moment | reason: " + error
                ? error.error.message
                : "request failed - permission",
          });
        }
      );
    }
  }

  OnCentralStoreChange() {
    this.allSelectedCentralBatches = [];
    this.selectedCentralBatches = [];
    this.allCentralReports = [];
    this.selectedCentralBatches = [];
    if (this.selectedCentralStores.length > 0) {
      const postData: GetJustUserBatchRequestsVM = {
        requestIds: this.selectedCentralStores.map((x) => x.id),
        requestType: 3,
      };

      this.inventoryService.GetJustUserBatchRequests(postData).subscribe(
        async (data) => {
          this.allSelectedCentralBatches = data;
        },
        (error) => {
          console.log("Error: " + JSON.stringify(error));
          this.messageService.add({
            severity: "error",
            summary: "Failed",
            detail:
              "Unable to get central batches at the moment | reason: " + error
                ? error.error.message
                : "request failed - permission",
          });
        }
      );
    }
  }

  ViewItemsMovements(items: GetUserBatchRequestMovement[], label: string) {
    this.openItemsMovementDialogue = true;
    this.itemMovementsList = items;
    this.quantityLabel = label;
  }

  HideItemMovementDialog() {
    this.openItemsMovementDialogue = false;
    this.itemMovementsList = [];
  }
}
