import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { Complaint, ComplaintStats } from "../interfaces/complaint";
import { CommonResponse } from "../interfaces/home";

@Injectable({
  providedIn: "root",
})
export class ComplaintService {
  baseUrl = environment.baseUrl + "Complaint/";

  constructor(private http: HttpClient) {}

  getAll(): Observable<CommonResponse> {
    return this.http.get<CommonResponse>(this.baseUrl);
  }

  getAllComplaints(): Observable<CommonResponse> {
    return this.http.get<CommonResponse>(this.baseUrl + "GetAllComplaints");
  }

  getByID(id: number): Observable<CommonResponse> {
    return this.http.get<CommonResponse>(this.baseUrl + id);
  }

  getComplaintStats(): Observable<CommonResponse> {
    return this.http.get<CommonResponse>(this.baseUrl + "GetComplaintsStats");
  }

  create(data: Complaint): Observable<CommonResponse> {
    return this.http.post<CommonResponse>(this.baseUrl, data);
  }

  delete(id: number) {
    return this.http.delete(this.baseUrl + id);
  }

  update(id: any, data: Complaint) {
    return this.http.put(this.baseUrl + id, data);
  }

  GetComplaintsByClientId(
    customerDivisionId: number
  ): Observable<CommonResponse> {
    return this.http.get<CommonResponse>(
      this.baseUrl + "GetComplaintsByClientId/" + customerDivisionId
    );
  }
}
