import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { GroupedPermissions } from 'src/app/interfaces/home';

@Component({
  selector: 'app-group-selection',
  templateUrl: './group-selection.component.html',
  styleUrls: ['./group-selection.component.scss']
})
export class GroupSelectionComponent implements OnInit {

  groupPermissionsList: GroupedPermissions[] = [];
  selectedGroups: GroupedPermissions[];

  constructor(public config: DynamicDialogConfig,
    public attribModalRef: DynamicDialogRef) { 

  }

  ngOnInit(): void {
    this.groupPermissionsList = this.config.data.groupPermissions;
  }

  onSelected(){
     this.attribModalRef.close(this.selectedGroups)
  }
}
