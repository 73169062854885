import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { errorMonitor } from "events";
import { ConfirmationService, MessageService } from "primeng/api";
import { DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";
import { CommonResponse, InvoiceMailDTO } from "src/app/interfaces/home";
import { InvoiceService } from "src/app/services/invoice.sevice";
import { UtilService } from "src/app/services/util.service";
import domtoimage from "dom-to-image";
import { jsPDF } from "jspdf";

@Component({
  selector: "app-view-invoice",
  templateUrl: "./view-invoice2.component.html",
  styleUrls: ["./view-invoice.component.scss"],
  providers: [],
})
export class ViewInvoiceComponent implements OnInit {
  invoiceDetails: InvoiceMailDTO;
  fetchingInvoiceDetails: boolean;
  isGroupedAdhoc = false;
  label = "Invoice";
  amountInWords: string = "";

  constructor(
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private invoiceService: InvoiceService,
    public modalConfig: DynamicDialogConfig,
    private viewInvoiceModalRef: DynamicDialogRef,
    public utilService: UtilService
  ) {}

  ngOnInit(): void {
    this.fetchingInvoiceDetails = true;
    this.isGroupedAdhoc = this.modalConfig.data.isGroupedAdhoc;
    if (this.modalConfig.data.type) {
      this.label = this.modalConfig.data.type;
    }

    this.fetchInvoiceDetails(this.modalConfig.data.invoiceId);
  }

  printPage() {
    window.print();
  }

  pdfDownloadPage() {
    this.utilService.ConvertHTMLPageToPDF("parentdiv", this.label);
  }

  pdfDownloadPage_Old() {
    // window.print();

    var node = document.getElementById("parentdiv");
    var img;
    var filename;
    var newImage;
    domtoimage
      .toPng(node, { bgcolor: "#fff" })
      .then(function (dataUrl) {
        img = new Image();
        img.src = dataUrl;
        newImage = img.src;
        img.onload = function () {
          var pdfWidth = img.width;
          var pdfHeight = img.height;
          // FileSaver.saveAs(dataUrl, 'my-pdfimage.png'); // Save as Image
          var doc;
          if (pdfWidth > pdfHeight) {
            doc = new jsPDF("l", "px", [pdfWidth, pdfHeight]);
          } else {
            doc = new jsPDF("p", "px", [pdfWidth, pdfHeight]);
          }
          var width = doc.internal.pageSize.getWidth();
          var height = doc.internal.pageSize.getHeight();

          doc.addImage(newImage, "PNG", 10, 10, width, height);
          filename = "receipt" + ".pdf";
          doc.save(filename);
        };
      })
      .catch(function (error) {
        // Error Handling
      });
  }

  sendInvoice(invoiceId: number) {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Sending Invoice",
    });

    this.invoiceService.sendInvoice(invoiceId).subscribe(
      async (res: CommonResponse) => {
        this.viewInvoiceModalRef.close();
        var response = res.responseData as boolean;
        if (response)
          this.messageService.add({
            severity: "success",
            summary: "Successful",
            detail: "Invoice Sent",
          });
      },
      (error) => {
        this.messageService.add({
          severity: "errorr",
          summary: "Failed",
          detail: error.error.message,
        });
      }
    );
  }

  confirmSendEmail(invoiceId: number) {
    this.viewInvoiceModalRef.close();
    this.confirmationService.confirm({
      message: "Are you sure you want to send this invoice ?",
      header: "Confirmation",
      icon: "pi pi-exclamation-triangle",
      accept: () => {
        this.sendInvoice(invoiceId);
      },
      reject: (type) => {
        this.messageService.add({
          severity: "error",
          summary: "Rejected",
          detail: "Declined Sending Invoice",
        });
      },
    });
  }

  fetchInvoiceDetails(invoiceId: number) {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Fetching Invoice Details",
    });

    this.invoiceService.getInvoiceDetails(invoiceId).subscribe(
      async (r: CommonResponse) => {
        var invoiceDetails = r.responseData as InvoiceMailDTO;
        this.invoiceDetails = invoiceDetails;

        let koboPart = 0;
        let amount = invoiceDetails.subTotal.toFixed(2);
        let doubleAmt = amount.toString();
        var splitAmt = doubleAmt.split(".");
        var nairaPart = parseInt(splitAmt[0]);
        this.amountInWords =
          this.utilService.NumberToWords(nairaPart) + " Naira";
        if (splitAmt.length > 1) {
          koboPart = parseInt(splitAmt[1]);
          if (koboPart > 0) {
            this.amountInWords +=
              " " + this.utilService.NumberToWords(koboPart) + " Kobo";
          }
        }

        this.fetchingInvoiceDetails = false;
        this.messageService.add({
          severity: "success",
          summary: "Successful",
          detail: "Successfully Fetched Invoices",
        });
      },
      (error) => {
        this.messageService.add({
          severity: "errorr",
          summary: "Failed",
          detail: error.error.message,
        });
        this.fetchingInvoiceDetails = false;
      }
    );
  }
}
