import {Component, OnDestroy, OnInit} from '@angular/core';
import {MenuItem, MessageService} from 'primeng/api';
import {Subscription} from 'rxjs';
import { BreadcrumbService } from 'src/app/breadcrumb.service';
import { CommonResponse } from 'src/app/interfaces/home';
import {LeadService} from '../../services/lead.service';

@Component({
  selector: 'app-lead-admin',
  templateUrl: './lead-admin.component.html',
  styleUrls: ['./lead-admin.component.css'],
  providers: [MessageService]
})
export class LeadAdminComponent implements OnInit, OnDestroy {
  items: MenuItem[];
  subscription: Subscription;
  constructor(
            public messageService: MessageService,
            public leadService: LeadService,
     ) {
  }
    ngOnInit(): void {
     
      this.items = [
        {
          label: 'Initiate Lead',
          routerLink: 'capture'
        },
        {
          label: 'Deal Capture',
          routerLink: 'qualification'
        },
        {
          label: 'Negotiations & Documentations',
          routerLink: 'negotiation'
        },
        {
          label: 'Lead Conversion',
          routerLink: 'conversion'
        }
      ];
      this.subscription = this.leadService.paymentComplete$.subscribe((r: CommonResponse) =>{
        var personalInformation = r.responseData;
        this.messageService.add({severity:'success',
          summary:'Order submitted', detail: 'Dear, ' + personalInformation.firstname + ' ' + personalInformation.lastname + ' your order completed.'});
      });
    }
  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    // this.leadService.leadData.unsubscribe();
  }}
