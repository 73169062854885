import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import {
  CreateClientContactVM,
  CreateContractContactVM,
  UpdateClientContractInfoVM,
  UpdateClientInfoVM,
} from "../interfaces/customer";
import { Contact, Contract, CustomerDivision } from "../interfaces/home";

@Injectable({
  providedIn: "root",
})
export class GmaCustomerService {
  baseUrl = environment.gmaBaseUrl + "Customer/";

  constructor(private http: HttpClient) {}

  GetAllClients(): Observable<CustomerDivision[]> {
    return this.http.get<CustomerDivision[]>(this.baseUrl + "GetAllClients");
  }

  GetClientInformation(
    customerDivisionId: number
  ): Observable<CustomerDivision> {
    return this.http.get<CustomerDivision>(
      this.baseUrl + "GetClientInformation/" + customerDivisionId
    );
  }

  UpdateClientInfo(id: number, data: UpdateClientInfoVM) {
    return this.http.put(this.baseUrl + "UpdateClientInfo/" + id, data);
  }

  CreateClientContact(data: CreateClientContactVM) {
    return this.http.post(this.baseUrl + "CreateClientContact", data);
  }

  CreateContractContact(data: CreateContractContactVM) {
    return this.http.post(this.baseUrl + "CreateContractContact", data);
  }

  DetachClientContact(id: number) {
    return this.http.delete(this.baseUrl + "DetachClientContact/" + id);
  }

  DetachContractContact(id: number) {
    return this.http.delete(this.baseUrl + "DetachContractContact/" + id);
  }

  GetAllContacts(): Observable<Contact[]> {
    return this.http.get<Contact[]>(this.baseUrl + "GetAllContacts");
  }

  UpdateClientContractInfo(id: number, data: UpdateClientContractInfoVM) {
    return this.http.put(this.baseUrl + "UpdateClientContractInfo/" + id, data);
  }

  GetAllContracts(): Observable<Contract[]> {
    return this.http.get<Contract[]>(this.baseUrl + "GetAllContracts");
  }
}
