import {Injectable, OnDestroy} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {BehaviorSubject, Observable, Subject, Subscription} from 'rxjs';
import {CommonResponse, LeadDivision} from '../interfaces/home';

@Injectable({
  providedIn: 'root'
})
export class LeadDivisionService implements OnDestroy{
  subscription: Subscription;
  private currentLeadDivisionData = new BehaviorSubject<any>(null);
  currentLeadDivisionData$ = this.currentLeadDivisionData.asObservable();
  constructor(private http: HttpClient) { }
  baseUrl = environment.baseUrl+'LeadDivision/';
  // fetch all Division in the database
  allLeadDivisionData(): Observable<CommonResponse>{
    return this.http.get<CommonResponse>(this.baseUrl);
  }
  getLeadDivision(id: any):Observable<CommonResponse>{
    return this.http.get<CommonResponse>(this.baseUrl +id);
  }
  getLeadDivisionByName(name: any):Observable<CommonResponse>{
    return this.http.get<CommonResponse>(this.baseUrl + 'name/' + name);
  }
  postLeadDivision(data: any){
    return this.http.post(this.baseUrl, data);
  }
  deleteLeadDivision(id: any){
    return this.http.delete(this.baseUrl +id);
  }
  updateLeadDivision(id: any, data: any){
    return this.http.put(this.baseUrl +id, data);
  }
  setCurrentLeadDivision(leadDivision?: LeadDivision){
    this.currentLeadDivisionData.next(leadDivision);
  }
  activeLeadDivision(){
    return this.currentLeadDivisionData$;
  }
  ngOnDestroy(): void {
  }
}
