import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { Console } from 'console';
import { ConfirmationService, MessageService, TreeNode } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { DeliverableDashboardService } from './deliverable-dashboard.service';
import {ProgressBarModule} from 'primeng/progressbar';
import * as moment from 'moment';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-deliverable-dashboard',
  templateUrl: './deliverable-dashboard.component.html',
  styleUrls: ['./deliverable-dashboard.component.scss']
})
export class DeliverableDashboardComponent implements OnInit {
  deliverables:any[];
  completion = 0;
  workspaceIsLoading=false;
  totaldeliverable: any[];
  projectArray: TreeNode[]=[];
  chosenTask: any;
  statuses: any[]=[];
  deliverableList: any[]=[];
  taskArray: any[]=[];
  gottenResponse: any;
  deliverableArray: any[]=[];
  statusArray: any[]=[];
  projectList: any[]=[];
  finalProjectArray: any[]=[];
  monthsArray: { value: number; name: string; }[] = [];
  currentData: { index: number; year: number; };
  dateDropdown: any[]=[];
  selectedMonth = {
    value:0,
    name:""
  };
  selectedYear={
    index:0,
    year:0
  };
  currentMonth: string;

  constructor(
    private deliverableService:DeliverableDashboardService,
    private route: ActivatedRoute,
    public confirmationService: ConfirmationService,
    public dialogService: DialogService,
    public messageService: MessageService,
    private sanitizer: DomSanitizer,
    ) { 
      this.getAllMonth();
    }

  ngOnInit(): void {
    this.getDeliverablesForWatcher();
    this.getListYear();
  }

  getAllMonth(){
    this.monthsArray = [
      {value:1,name:"January"},
      {value:2,name:"February"},
      {value:3,name:"March"},
      {value:4,name:"April"},
      {value:5,name:"May"},
      {value:6,name:"June"},
      {value:7,name:"July"},
      {value:8,name:"August"},
      {value:9,name:"September"},
      {value:10,name:"October"},
      {value:11,name:"November"},
      {value:12,name:"December"},
      {value:13,name:"View all"}
    ]
  }

  getListYear(){
    let currentYear = new Date().getFullYear();
    let earliestYear = 1970;
    var index = 0;
   
    while (currentYear >= earliestYear) {
      this.currentData = {
        index:0,
        year:0
      }
      this.currentData.index = index++
      this.currentData.year = currentYear;
      this.dateDropdown.push(this.currentData);
      currentYear -= 1;
    }

  }


  // getDeliverablesForWatcher(){
  //   //this.workspaceIsLoading = true;
  //   // this.selectedMonth.value = 1;
  //   // this.selectedYear.year = 2022;
  //   if(this.selectedMonth.value == 0){
  //     let month = new Date().getMonth()+1
  //     console.log("this is current month",month)
  //     this.currentMonth = this.monthsArray.filter((e)=>{return e.value === month})[0]?.name
  //     this.selectedMonth.value = month;
      
  //   }
  //   if(this.selectedYear.year == 0){
  //     let year = new Date().getFullYear();
  //     this.selectedYear.year = year
  //     console.log("this is current month",year)
  //   }
  // }

  getDeliverablesForWatcher(){
    this.workspaceIsLoading = true;
    if(this.selectedMonth.value == 0 || this.selectedMonth.value == null){
      let month = new Date().getMonth()+1
      console.log("this is current month",month)
      this.currentMonth = this.monthsArray.filter((e)=>{return e.value === month})[0]?.name
      this.selectedMonth.value = month;
      
    }
    if(this.selectedYear.year == 0){
      let year = new Date().getFullYear();
      this.selectedYear.year = year
      console.log("this is current month",year)
    }
     this.deliverableService.getDeliverableDashboard(this.selectedYear.year,this.selectedMonth.value)
     .subscribe(async(result:any)=>{ 
      this.gottenResponse = null;
      this.gottenResponse = result;
      console.log("this is gottenResponse",this.gottenResponse.responseData);
      this.taskArray = this.gottenResponse?.responseData.tasks;
      // console.log("this is taskArray",this.taskArray);
      this.projectList = this.gottenResponse.responseData.projects;
      this.deliverableArray = this.gottenResponse.responseData.deliverables;
      this.statusArray = this.gottenResponse.responseData.statusFlows;

      console.log("Deliverables was gotten here ",this.projectList)
        this.workspaceIsLoading = false;
        const projectArray = []
        this.projectList.forEach(project =>{
            const taskArray = []
            this.taskArray.forEach(task =>{
                const deliverableArray = []
                                    this.deliverableArray.forEach(deliverable=>{
                                      if(deliverable.taskId === task.id){
                                        deliverableArray.push({
                                          caption:deliverable.caption,
                                          label:deliverable.caption,
                                          statusName: deliverable.status == null ? 'Not avaliable':this.statusArray.find(status=>status.id==deliverable.statusId).caption,
                                          description:deliverable.description,
                                          alias:deliverable.alias,
                                          // assignTask:deliverable.assignTask,
                                          // assignTaskId:deliverable.assignTaskId,
                                          // balances:deliverable.balances,
                                          //budget:deliverable.budget,
                                          status:this.statusArray.filter((status)=>{return status.workspaceId == project.workspaceId}),
                                          createdAt:deliverable.createdAt,
                                          createdBy:deliverable.createdBy,
                                          createdById:deliverable.createdById,
                                          datePicked:deliverable.datePicked,
                                          datePushedForApproval:deliverable.datePushedForApproval,
                                          deliverableAssignees:deliverable.deliverableAssignees,
                                          dependencies:deliverable.dependencies,
                                          dependentType:deliverable.dependentType,
                                          documents:deliverable.documents,
                                          endDate:deliverable.endDate,
                                          id:deliverable.id,
                                          taskName:task.caption,
                                          isApproved:deliverable.isApproved,
                                          isDeclined:deliverable.isDeclined,
                                          isPicked:deliverable.isPicked,
                                          isPushedForApproval:deliverable.isPushedForApproval,
                                          notes:deliverable.notes,
                                          pictures:deliverable.pictures,
                                          requirements:deliverable.requirements,
                                          startDate:deliverable.startDate,
                                          statusId:deliverable.statusId,
                                          taskId:deliverable.taskId,
                                          timeEstimate:deliverable.timeEstimate,
                                          workspace:project.workspace,
                                          icon: "pi pi-fw pi-sitemap",
                                          selectable: true,
                                        })
                                      }
                                    })
  
                             if(task.projectId == project.id){
                               console.log("this is the deliverable array created", deliverableArray);
                              taskArray.push({
                                label:` ${task.caption} - (${deliverableArray.filter((el)=>{return el.statusId !== null && el.taskId == task.id}).length})`,
                                alias:task.alias,
                                caption:task.caption,
                                createdAt:task.createdAt,
                                createdBy:task.createdBy,
                                createdById:task.createdById,
                                deliverables:deliverableArray.filter((el)=>{return el.status !== null && el.taskId == task.id}),
                                description:task.description,
                                dueTime:task.dueTime,
                                id:task.id,
                                expanded: false,
                                statusArray:this.statusArray.filter((status)=>{return status.workspaceId == project.workspaceId}),
                                isActive:task.isActive,
                                isAssigned:task.isAssigned,
                                isMilestone:task.isMilestone,
                                isPickedUp:task.isPickedUp,
                                isReassigned:task.isReassigned,
                                isWorkbenched:task.isWorkbenched,
                                projectId:task.projectId,
                                taskAssignees:task.taskAssignees,
                                taskEndDate:task.taskEndDate,
                                taskOwnership:task.taskOwnership,
                                taskOwnershipId:task.taskOwnershipId,
                                taskStartDate:task.taskStartDate,
                                workingManHours:task.workingManHours,
                                icon: "pi pi-check-square",
                                selectable: true,
                                deliverableArray:deliverableArray
                                //children:deliverableArray
                               })
                            }
                          })
                          const newData:TreeNode = {
                            label: `${project.caption}`,
                            expandedIcon: "pi pi-minus-circle",
                            collapsedIcon: "pi pi-plus-circle",
                            expanded: true,
                            data:project,
                            children:taskArray,
                            selectable: false,
                          }
                          this.projectArray.push(newData);
                          });

     })
    

    
      
        
                          
     
 
    
console.log("this is the tree ",this.projectArray)

  }

  nodeSelect(event) {
   this.chosenTask = null;
   console.log("this is the event",event.node);
   this.chosenTask = event.node;
   this.statuses = this.chosenTask.statusArray;
   this.deliverableList = this.chosenTask.deliverables
    //this.calculateTaskCompletionRate(this.chosenTask);

}

  calculateTaskCompletionRate(task){
    let finalRate = 0
    if(task.deliverables.length != 0){
      var approvedDeliverables = 0
      task.deliverables.forEach(deliverable =>{
        if(deliverable.isApproved == true && deliverable.status != null){
            approvedDeliverables = approvedDeliverables + 1
        }
     })
     finalRate = Math.round((approvedDeliverables/task.deliverables.filter((el)=>{return el.status !== null}).length) * 100)
     console.log("this is final",finalRate);
     return finalRate
    }
    else{
      return finalRate;
    }
  }


  getStatusDeliverables(status):any{
    var deliverableArray = []
    this.deliverableList.forEach(deliverable =>{
        if(deliverable.statusId == status.id){
          deliverableArray.push(deliverable);
        }
    })

    return deliverableArray;
  }

  calculatePercentage(endDate:any,startDate):number{
    var totalPercent = 0;
     let now:any = moment();
     startDate = moment(startDate);
     endDate = moment(endDate);
     var percentage_complete = (now - startDate) / (endDate - startDate) * 100;
    
     if(percentage_complete > 100){
       percentage_complete = 100;
     }
     else if(percentage_complete  < 0){
       percentage_complete = 0;
     }
     var percentage_rounded = (Math.round(percentage_complete * 100) / 100); 
     return percentage_rounded;
      
    }

    calculateStatusOfWork(endDate:any,startDate):string{
    
      var start:any = new Date(startDate),
      end:any = new Date(endDate),
      today:any = new Date();
      var percentage_complete = (today-start)/(end-start)*100;
      var percentage_rounded = (Math.round(percentage_complete * 100) / 100); 
      //console.warn("the pecentage_complete",percentage_complete);
    
      if(percentage_rounded >= 100){
        return "Overdue"
      }
      else if(percentage_rounded  < 0){
        return "Not Started"
      }
        
      if(percentage_rounded < 90 && percentage_complete > 0){
        return "On Track"
      }
      else if(percentage_rounded >= 90 && percentage_rounded < 99.9 ){
        return "Due"
      }
      
    }

}
