import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {CommonResponse, Relationship} from '../../../interfaces/home';
import {ConfirmationService, MessageService} from 'primeng/api';
import {RelationshipService} from '../../../services/relationship.service';
import { BreadcrumbService } from 'src/app/breadcrumb.service';

@Component({
  selector: 'app-relationship-type',
  templateUrl: './relationship-type.component.html',
  styleUrls: ['./relationship-type.component.scss'],
  providers: [ConfirmationService, MessageService]
})
export class RelationshipTypeComponent implements OnInit {
  @ViewChild('formWrapper') public formWrapper:ElementRef;
  fetchingRelationship = true;
  relationshipForm: FormGroup;
  relationships: Relationship[];
  selectedRelationship: Relationship;
  editingRelationship= false;
  public relationshipCols: any[];
  public exportRelationshipColumns: any[];
  private edRelationship: Relationship;
  constructor(
      private formBuilder: FormBuilder,
      private relationshipService: RelationshipService,
      public messageService: MessageService,
      public confirmationService: ConfirmationService,
      private breadcrumbService: BreadcrumbService
  ) { }

  ngOnInit(): void {
    this.breadcrumbService.setItems([
      { label: 'Qualification', routerLink: ['home/qualification'] },
      { label: 'Relationship Type', routerLink: ['home/qualification/relationship-type'] },
    ]);

    this.fetchRelationship();
    this.relationshipForm = this.formBuilder.group({
      description: ['', Validators.required],
      caption: ['', [Validators.required]],
    });
    this.relationshipCols = [
      { field: 'caption', header: 'Caption' },
      { field: 'description', header: 'Description' },
    ];
    this.exportRelationshipColumns = this.relationshipCols.map(col => ({title: col.header, dataKey: col.field}));
  }
  fetchRelationship(){
    this.relationshipService.allRelationshipData().subscribe( async (relationship: CommonResponse) =>{
      this.relationships = relationship.responseData;
      this.fetchingRelationship = false;
    }, error => {
      this.fetchingRelationship = false;
      this.connectionError();
    })
  }
  private connectionError() {
    this.messageService.add({severity:'error', summary: 'Failed',
      detail:'Connection Error, Please try again'});
  }
  createRelationship(){
    this.messageService.add({severity:'info', summary: 'Notice',
      detail:'Creating new Relationship Records'});
    const postData = {
      description: this.relationshipForm.get('description').value,
      caption: this.relationshipForm.get('caption').value,
    };
    this.relationshipService.postRelationship(postData).subscribe( async () =>{
      await this.messageService.add({severity:'success', summary: 'Completed',
        detail:'New Relationship Created'});
      await this.fetchRelationship();
      await this.relationshipForm.reset();
    }, error => {
      this.messageService.add({severity:'error', summary: 'Notice',
        detail:'Unable to create new Relationship at the moment'});
    });
  }

  deleteRelationship(relationship) {
    this.confirmationService.confirm({
      message: 'Are you sure you want to drop ' + relationship.caption,
      accept: () => {
        this._deleteRelationship(relationship);
      }
    });
  }
  _deleteRelationship(relationship) {
    this.messageService.add({severity:'info', summary: 'Notice',
      detail:'Dropping relationship record'});
    this.relationshipService.deleteRelationship(relationship.id).subscribe( async (result:any) => {
      await this.messageService.add({severity:'success', summary: 'Dropped',
        detail:'Relationship record dropped'});
      await this.fetchRelationship();
    }, error => {
      this.connectionError()
    });
  }
  editRelationship(relationships) {
    this.editingRelationship = true;
    this.relationshipForm.addControl('relationshipId',  new FormControl({value: '', disabled: true}, Validators.required));
    this.edRelationship = relationships;
    this.relationshipForm.patchValue({
      caption: relationships.caption,
      description: relationships.description,
      relationshipId: relationships.id,
    });
    // this.relationshipFormIn.nativeElement.focus();
    // document.getElementById('formFields').focus();
    // this.selectedHeadId = this.users.find(user => user.id === relationships.head.id);
    this.formWrapper.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'start' });
  }

  updateRelationship() {
    // update the selected relationship
    this.messageService.add({severity:'info', summary: 'Notice',
      detail:'Updating Relationship details'});
    const id = this.edRelationship.id;
    const postData = {
      caption: this.relationshipForm.get('caption').value,
      description: this.relationshipForm.get('description').value,
    };
    this.relationshipService.updateRelationship(id, postData).subscribe( async (result: Relationship) => {
      this.messageService.add({severity:'success', summary: 'Notice',
        detail:'Relationship details Updated'});
      await this.fetchRelationship();
      await this.closeEditing();
    })
  }

  closeEditing() {
    this.editingRelationship = false;
    this.relationshipForm.reset();
    this.edRelationship = null;
  }
}
