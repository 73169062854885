///<reference types="@types/googlemaps" />

import {
  CreateClientLocation,
  UpdateClientLocation,
} from "./../../../interfaces/employment";
import {
  CommonResponse,
  CustomerDivision,
  Lga,
  Location,
  ServiceCategory,
  State,
} from "./../../../interfaces/home";
import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from "@angular/core";
import { MessageService, ConfirmationService } from "primeng/api";
import { BreadcrumbService } from "src/app/breadcrumb.service";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { StateService } from "src/app/services/state.service";
import { GmaEmploymentService } from "src/app/services/gma-employment.service";
import { FileStorageService } from "src/app/services/file-storage.service";

@Component({
  selector: "app-client-location",
  templateUrl: "./client-location.component.html",
  styleUrls: ["./client-location.component.scss"],
})
export class ClientLocationComponent implements OnInit, AfterViewInit {
  @ViewChild("formWrapper") public formWrapper: ElementRef;
  addClientLocForm: FormGroup;
  allClients: CustomerDivision[] = [];
  theClient: CustomerDivision;
  clientInfosTable: {
    name: string;
    data: string;
  }[];
  selectedClientInfoTable: {
    name: string;
    data: string;
  };
  allClientLocations: Location[];
  selectedClientLocation: Location;
  clientLocCols: any[];
  imageSrc: string;
  uploadeLocationImage: any[] = [];
  locationImageIsChanged: boolean;
  allStates: State[];
  theState: State;
  allLGAs: Lga[] = [];
  LgasToShow: Lga[] = [];
  theLga: Lga;
  allServiceCategories: ServiceCategory[] = [];
  selectedServiceCategories: ServiceCategory[] = [];
  editingClientLoc: boolean;
  showClientDetails: boolean = false;
  theClientContractServices: number;
  theClientContracts: number;
  theClientLocations: number;
  showClientLocationDetails: boolean = false;
  showAddNewClientLocation: boolean = false;
  clientLocationToEdit: Location;
  confirmedLocation: boolean = false;
  doneLoadingDits: boolean = false;
  openConfirmLocationDialogue: boolean = false;
  dontOpen: boolean = false;
  locLongitude: number;
  locLatitude: number;
  locGeometry: string;
  manualLocCordinateDialogue: boolean = false;
  manualLocAddress: string;
  manualLocLatitude: number;
  manualLocLongitude: number;

  constructor(
    private fb: FormBuilder,
    public employmentService: GmaEmploymentService,
    public stateService: StateService,
    public messageService: MessageService,
    private breadcrumbService: BreadcrumbService,
    public confirmationService: ConfirmationService,
    private fileStorageService: FileStorageService
  ) {
    this.addClientLocForm = fb.group({
      PictureUrl: [""],
      Name: ["", Validators.required],
      Description: ["", Validators.required],
      State: ["", Validators.required],
      LGA: ["", Validators.required],
      Street: ["", Validators.required],
      ServiceCategories: ["", Validators.required],
      Address: [""],
    });
  }

  ngOnInit(): void {
    this.breadcrumbService.setItems([
      {
        label: "Client Management",
        routerLink: ["/home/client-view"],
      },
      {
        label: "Client Location",
        routerLink: ["/home/client-management/client-location"],
      },
    ]);

    this.clientInfosTable = [
      {
        name: "Client Name",
        data: "Data",
      },
      {
        name: "Client Type",
        data: "Data",
      },
      {
        name: "Client Address",
        data: "Data",
      },
      {
        name: "Official Phone",
        data: "Data",
      },
      {
        name: "Official Email",
        data: "Data",
      },
    ];

    this.clientLocCols = [
      { field: "name", header: "Name" },
      { field: "description", header: "Description" },
      { field: "street", header: "Street" },
    ];

    this.FetchAllStates();
    this.FetchAllClients();
    this.FetchAllServiceCategories();
  }

  ngAfterViewInit(): void {
    // Load google maps script after view init
    const DSLScript = document.createElement("script");
    DSLScript.src =
      "https://maps.googleapis.com/maps/api/js?key=AIzaSyCQuetprs2UHb_zKSXznyumyfvw95ERCDY"; // replace by your API key
    DSLScript.type = "text/javascript";
    document.body.appendChild(DSLScript);
    document.body.removeChild(DSLScript);
  }

  FetchAllStates() {
    this.employmentService.GetAllStates().subscribe(
      async (data) => {
        this.allStates = data;
        // this.allStates.forEach((state) => {
        //   state.lgAs.forEach((lga) => {
        //     this.allLGAs.push(lga);
        //   });
        // });
        // this.LgasToShow = this.allLGAs;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all states at the moment.. Reason: [" +
            error.error.message +
            "]",
        });
      }
    );
  }

  FetchAllClients() {
    this.employmentService.GetAllClientsForClientLoc().subscribe(
      async (data) => {
        this.allClients = data;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all clients at the moment.. Reason: [" +
            error.error.message +
            "]",
        });
      }
    );
  }

  FetchAllServiceCategories() {
    this.employmentService.GetAllServiceCategories().subscribe(
      async (data) => {
        this.allServiceCategories = data;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all service categories at the moment.. Reason: [" +
            error.error.message +
            "]",
        });
      }
    );
  }

  LoadClientDetails() {
    if (this.theClient) {
      this.clientInfosTable[0].data = this.theClient.divisionName;
      this.clientInfosTable[1].data = !this.theClient.customer
        ? ""
        : !this.theClient.customer.groupType
        ? ""
        : this.theClient.customer.groupType.caption;
      this.clientInfosTable[2].data = this.theClient.address;
      this.clientInfosTable[3].data = this.theClient.phoneNumber;
      this.clientInfosTable[4].data = this.theClient.email;
      this.theClient.contracts = [];
      this.theClient.locations = [];
      this.theClientContractServices = 0;
      this.theClientContracts = 0;
      this.theClientLocations = 0;
      this.showClientDetails = true;
      this.doneLoadingDits = false;
      this.GetClientRemainingDits(this.theClient.id);
    } else this.showClientDetails = false;

    this.showClientLocationDetails = false;
    this.showAddNewClientLocation = false;
  }

  GetClientRemainingDits(clientId) {
    this.employmentService.GetClientRemDetailsForClientLoc(clientId).subscribe(
      async (data) => {
        this.theClient.contracts = data.contracts;
        this.theClient.locations = data.locations;
        let contractServiceCount = 0;
        this.theClient.contracts.forEach(
          (contract) =>
            (contractServiceCount =
              contractServiceCount +
              contract.contractServices.filter((x) => x.version == 0).length)
        );
        this.theClientContractServices = contractServiceCount;
        this.theClientContracts = this.theClient.contracts.length;
        this.theClientLocations = this.theClient.locations.filter(
          (x) => x.isDeleted == false
        ).length;
        this.doneLoadingDits = true;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch client dits at the moment.. Reason: [" +
            error.error.message +
            "]",
        });
      }
    );
  }

  ViewClientLocations() {
    this.allClientLocations = [];
    this.theClient.locations.forEach((loc) => {
      if (!loc.isDeleted) this.allClientLocations.push(loc);
    });
    this.showClientLocationDetails = true;
  }

  ShowAddClientLocation() {
    this.showAddNewClientLocation = true;
    this.confirmedLocation = false;
    this.openConfirmLocationDialogue = false;
    this.addClientLocForm.reset();
    this.editingClientLoc = false;
    this.selectedServiceCategories = [];
    this.imageSrc = null;
    this.locLatitude = null;
    this.locLongitude = null;
    this.locGeometry = null;
  }

  DeleteClientLocation(data: Location) {
    this.confirmationService.confirm({
      message: "Are you sure you want to remove client location?",
      accept: () => {
        this.messageService.add({
          severity: "info",
          summary: "Notice",
          detail: "Removing client location...",
        });

        this.employmentService.DeleteClientLocation(data.id).subscribe(
          async () => {
            await this.messageService.add({
              severity: "success",
              summary: "Removed",
              detail: "Removed successfully",
            });

            const index = this.allClientLocations.indexOf(data);
            if (index > -1) {
              this.allClientLocations.splice(index, 1);
            }
            this.FetchAllClients();
          },
          (error) => {
            console.log("Error: " + JSON.stringify(error));
            this.messageService.add({
              severity: "error",
              summary: "Notice",
              detail:
                "Unable to remove client location at the moment.. Reason: [" +
                error.error.message +
                "]",
            });
          }
        );
      },
    });
  }

  EditClientLocation(item: Location) {
    this.addClientLocForm.addControl(
      "ID",
      new FormControl({ value: "", disabled: true }, Validators.required)
    );
    this.addClientLocForm.patchValue({
      PictureUrl: item.imageUrl,
      Name: item.name,
      Description: item.description,
      Street: item.street,
    });
    this.imageSrc = item.imageUrl;
    this.theState = this.allStates.find((state) => state.id == item.stateId);
    this.LgasToShow = this.theState.lgAs;
    this.theLga = this.LgasToShow.find((lga) => lga.id == item.lgaId);
    this.selectedServiceCategories = item.serviceCategories.map(
      (x) => x.serviceCategory
    );

    this.clientLocationToEdit = item;
    this.editingClientLoc = true;
    this.locationImageIsChanged = false;
    this.showAddNewClientLocation = true;
    this.openConfirmLocationDialogue = false;
    this.locLatitude = null;
    this.locLongitude = null;
    this.formWrapper.nativeElement.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "end",
    });
  }

  async AddClientLocation() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Creating Client Location...",
    });

    const postData: CreateClientLocation = {
      imageUrl: "",
      name: this.addClientLocForm.get("Name").value,
      description: this.addClientLocForm.get("Description").value,
      stateId: this.theState.id,
      lgaId: this.theLga.id,
      street: this.addClientLocForm.get("Street").value,
      serviceCategoriesIds: this.selectedServiceCategories.map((x) => x.id),
      customerDivisionId: this.theClient.id,
      longitude: this.locLongitude,
      latitude: this.locLatitude,
    };

    this.fileStorageService.UploadMultipleFilesFromDataUrl(
      this.uploadeLocationImage
    );
    this.fileStorageService.onUploadFinished.subscribe(
      (resp: CommonResponse<string[]>) => {
        if (resp.responseCode != "00") {
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail: resp.responseMsg,
          });
        } else {
          postData.imageUrl = resp.responseData[0];
          this.employmentService.CreateClientLocation(postData).subscribe(
            async () => {
              await this.messageService.add({
                severity: "success",
                summary: "Completed",
                detail: "Client Location Created...",
              });

              this.addClientLocForm.reset();
              this.showAddNewClientLocation = false;
              let theClientLoc: Location = {
                stateId: postData.stateId,
                street: postData.street,
                imageUrl: postData.imageUrl,
                description: postData.description,
                lgaId: postData.lgaId,
                name: postData.name,
              };
              theClientLoc.serviceCategories = [];
              postData.serviceCategoriesIds.forEach((servCategId) => {
                let locServ = this.allServiceCategories.find(
                  (servCateg) => servCateg.id == servCategId
                );
                theClientLoc.serviceCategories.push({
                  serviceCategoryId: locServ.id,
                  serviceCategory: locServ,
                });
              });
              this.allClientLocations.push(theClientLoc);
              this.uploadeLocationImage = [];
              this.imageSrc = "";
              this.FetchAllClients();
            },
            (error) => {
              console.log("Error: " + JSON.stringify(error));
              this.messageService.add({
                severity: "error",
                summary: "Notice",
                detail:
                  "Unable to create client location at the moment.. Reason: [" +
                  error.error.message +
                  "]",
              });
            }
          );
        }
      },
      (error) => {
        console.log("Error while uploading files " + error);
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail: "ERR: Unable to upload image to storage",
        });
      }
    );
  }

  onUpload(event, form) {
    this.uploadeLocationImage = [];
    for (const file of event.files) {
      this.uploadeLocationImage.push(file);
    }
    this.imageSrc = this.uploadeLocationImage[0].objectURL;
    this.locationImageIsChanged = true;

    this.messageService.add({
      severity: "success",
      summary: "Success",
      detail: "Imgage Uploaded Successfully!",
    });
    form.clear();
  }

  PopulateLGAS() {
    this.LgasToShow = this.theState.lgas;
  }

  CloseItemEditing() {
    this.addClientLocForm.reset();
    this.imageSrc = "";
    this.selectedServiceCategories = [];
    this.theState = null;
    this.theLga = null;
    this.clientLocationToEdit = null;
    this.editingClientLoc = false;
    this.showAddNewClientLocation = false;
  }

  async UpdateItem() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Updating Client Location...",
    });

    const postData: UpdateClientLocation = {
      imageUrl: "",
      name: this.addClientLocForm.get("Name").value,
      description: this.addClientLocForm.get("Description").value,
      stateId: this.theState.id,
      lgaId: this.theLga.id,
      street: this.addClientLocForm.get("Street").value,
      serviceCategoriesIds: this.selectedServiceCategories.map((x) => x.id),
      customerDivisionId: this.theClient.id,
    };

    if (this.locationImageIsChanged) {
      this.fileStorageService.UploadMultipleFilesFromDataUrl(
        this.uploadeLocationImage
      );
      this.fileStorageService.onUploadFinished.subscribe(
        async (resp: CommonResponse<string[]>) => {
          if (resp.responseCode != "00") {
            this.messageService.add({
              severity: "error",
              summary: "Notice",
              detail: resp.responseMsg,
            });
          } else {
            postData.imageUrl = resp.responseData[0];
            await this._updateClientLocation(postData);
          }
        },
        (error) => {
          console.log("Error while uploading files " + error);
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail: "ERR: Unable to upload image to storage",
          });
        }
      );
    } else {
      postData.imageUrl = this.clientLocationToEdit.imageUrl;
      await this._updateClientLocation(postData);
    }
  }

  async _updateClientLocation(postData: UpdateClientLocation) {
    this.employmentService
      .UpdateClientLocation(this.clientLocationToEdit.id, postData)
      .subscribe(
        async () => {
          await this.messageService.add({
            severity: "success",
            summary: "Completed",
            detail: "Client Location Updated...",
          });

          this.addClientLocForm.reset();
          this.showAddNewClientLocation = false;
          let theClientLoc = this.allClientLocations.find(
            (clientLoc) => clientLoc.id == this.clientLocationToEdit.id
          );
          theClientLoc.name = postData.name;
          theClientLoc.description = postData.description;
          theClientLoc.imageUrl = postData.imageUrl;
          theClientLoc.lgaId = postData.lgaId;
          theClientLoc.stateId = postData.stateId;
          theClientLoc.street = postData.street;
          theClientLoc.serviceCategories = [];
          postData.serviceCategoriesIds.forEach((servCategId) => {
            let locServ = this.allServiceCategories.find(
              (servCateg) => servCateg.id == servCategId
            );
            theClientLoc.serviceCategories.push({
              serviceCategoryId: locServ.id,
              serviceCategory: locServ,
            });
          });
          this.uploadeLocationImage = [];
          this.imageSrc = "";
          this.locationImageIsChanged = false;
          this.selectedServiceCategories = [];
          this.theState = null;
          this.theLga = null;
          this.clientLocationToEdit = null;
          this.editingClientLoc = false;
          this.FetchAllClients();
        },
        (error) => {
          console.log("Error: " + JSON.stringify(error));
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail:
              "Unable to update client location at the moment.. Reason: [" +
              error.error.message +
              "]",
          });
        }
      );
  }

  get StreetValue() {
    return this.addClientLocForm.get("Street").value;
  }

  get LGAValue() {
    return this.theLga ? this.theLga.name : "";
  }

  get StateValue() {
    return this.theState ? this.theState.name : "";
  }

  ConfirmLocationCordinates() {
    this.openConfirmLocationDialogue = true;
    let address: string =
      this.addClientLocForm.get("Street").value +
      "," +
      this.theLga.name +
      "," +
      this.theState.name;
    this.GetInputtedLocationCordinates(address);
  }

  GetInputtedLocationCordinates(address: string) {
    this.employmentService.GetAddressGeocodingLocation(address).subscribe(
      async (data) => {
        if (data.status != "OK") {
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail:
              "Unable to get address cordinates at the moment.. " +
              data.error_message,
          });
          return;
        }
        this.locLatitude = data.results[0].geometry.location.lat;
        this.locLongitude = data.results[0].geometry.location.lng;
        this.locGeometry = data.results[0].formatted_address;
        this.initMap();
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to get address cordinates at the moment.. Reason: [" +
            error.error.message +
            "]",
        });
      }
    );
  }

  HideLocationDialog() {
    this.openConfirmLocationDialogue = false;
  }

  HideManualLocationDialog() {
    this.manualLocCordinateDialogue = false;
  }

  // Initialize and add the map
  initMap(): void {
    if (this.locLatitude && this.locLongitude) {
      // The location of Loc
      const location = { lat: this.locLatitude, lng: this.locLongitude };
      // The map, centered at Loc
      const map = new google.maps.Map(
        document.getElementById("map") as HTMLElement,
        {
          zoom: 4,
          center: location,
        }
      );

      // The marker, positioned at Loc
      const marker = new google.maps.Marker({
        position: location,
        map: map,
      });
    }
  }

  ManualLocationCordinate() {
    this.manualLocCordinateDialogue = true;
  }

  ConfirmLocation() {
    this.openConfirmLocationDialogue = false;
    this.manualLocCordinateDialogue = false;
    this.confirmedLocation = true;
  }

  LoadManualLocationAddress() {
    this.GetInputtedLocationCordinates(this.manualLocAddress);
    this.manualLocCordinateDialogue = false;
  }

  LoadManualLocationCordinates() {
    this.locLatitude = this.manualLocLatitude;
    this.locLongitude = this.manualLocLongitude;
    this.locGeometry = "Manually Inputted Raw Cordinate(s)";
    this.initMap();
    this.manualLocCordinateDialogue = false;
  }
}
