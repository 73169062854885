import { Component, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { CommonResponse, ContractService, Invoice } from 'src/app/interfaces/home';
import { ReceiptService } from 'src/app/services/receipt.service';

@Component({
  selector: 'app-receipt-breakdown',
  templateUrl: './receipt-breakdown.component.html',
  styleUrls: ['./receipt-breakdown.component.scss']
})
export class ReceiptBreakdownComponent implements OnInit {

  receiptValue: number;
  invoice: Invoice;

  fetchingBreakdown = true;
  breakdown: any;
  selectedItem: any;
  breakdownCols: any;

  constructor(
    public receiptService: ReceiptService,
    public messageService: MessageService,
    public modalConfig: DynamicDialogConfig,
    private viewBreakdownModalRef: DynamicDialogRef,
  ) { }

  ngOnInit(): void {
    console.log(this.modalConfig.data)
    this.receiptValue = this.modalConfig.data.receiptValue;
    this.invoice = this.modalConfig.data.invoice
    this.fetchBreakDown()
    this.breakdownCols = [
      { field: 'invoiceNumber', header: 'Invoice Number' },
      { field: 'invoiceValue', header: 'Invoice Value' },
      { field: 'invoiceMonth', header: 'Invoice Month' },
      { field: 'invoiceYear', header: 'Invoice Year' },
      { field: 'receiptStatus', header: 'Receipt Status' }
    ];
  }

  fetchBreakDown(){
    this.fetchingBreakdown = true
    this.receiptService.getBreakdown(this.invoice.id, this.receiptValue).subscribe(async (res: CommonResponse) => {
      if(res.responseCode=='00'){
        this.breakdown = res.responseData;
      this.fetchingBreakdown = false
      }else{
        this.messageService.add({severity:'error', summary: 'Failed', detail:res.responseMsg});

      }
    }, error => {
      this.fetchingBreakdown = false
      this.messageService.add({severity:'error', summary: 'Failed', detail:'Could not get breakdown.'});
    })
  }

  proceed() {
    this.viewBreakdownModalRef.close(true)
  }

  cancel(){
    this.viewBreakdownModalRef.close(false)
  }
}
