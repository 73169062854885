import { CustomerDivision, Location } from "./../../../../interfaces/home";
import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from "@angular/core";
import { ConfirmationService, MessageService } from "primeng/api";
import { BreadcrumbService } from "src/app/breadcrumb.service";
import {
  ConvergenceDeviceAttachment,
  ConvergenceInteractionType,
  DeviceCategory,
  DeviceSubCategory,
  GetDeviceDashboardFilteredDataVM,
} from "src/app/interfaces/convergence";
import { ConvergenceService } from "src/app/services/convergence.service";
import { Supplier } from "src/app/interfaces/supplier";
import { CustomerDivisionService } from "src/app/services/customer-division.service";
import { SupplierService } from "src/app/services/supplier.service";

@Component({
  selector: "app-device-convergence-dashboard",
  templateUrl: "./device-convergence-dashboard.component.html",
  styleUrls: ["./device-convergence-dashboard.component.scss"],
})
export class DeviceConvergenceDashboardComponent
  implements OnInit, AfterViewInit
{
  @ViewChild("formWrapper") public formWrapper: ElementRef;
  dateFrom: Date;
  dateTo: Date;
  allInteractionTypes: ConvergenceInteractionType[];
  theInteractionType: ConvergenceInteractionType;
  allDeviceCategories: DeviceCategory[];
  theDeviceCategory: DeviceCategory;
  allDeviceSubCategoriesToShow: DeviceSubCategory[];
  allDeviceSubCategories: DeviceSubCategory[];
  theDeviceSubCategory: DeviceSubCategory;
  allClients: CustomerDivision[];
  theClient: CustomerDivision;
  allClientLocation: Location[];
  theClientLocation: Location;
  allSuppliers: Supplier[];
  theSupplier: Supplier;
  viewSwitch: boolean;
  locations: {
    lat: number;
    lng: number;
  }[] = [];
  viewCols: any[];
  allLocations: Location[] = [];
  allDeviceAttachments: ConvergenceDeviceAttachment[];
  selectedDeviceAttachments: ConvergenceDeviceAttachment[];

  constructor(
    public supplierService: SupplierService,
    public clientService: CustomerDivisionService,
    public convergenceService: ConvergenceService,
    private breadcrumbService: BreadcrumbService,
    public confirmationService: ConfirmationService,
    public messageService: MessageService
  ) {}

  ngOnInit(): void {
    this.breadcrumbService.setItems([
      {
        label: "Convergence",
      },
      {
        label: "Device Management",
      },
      {
        label: "Dasboard",
        routerLink: ["/home/convergence/device-management/dashboard"],
      },
    ]);

    this.viewCols = [
      { field: "createdAt", header: "Date Registered" },
      { field: "device.deviceName", header: "Device Name" },
      // { field: "interactionTypeId", header: "Interaction Type" },
      { field: "device.deviceCategoryId", header: "Device Category" },
      { field: "device.deviceSubCategoryId", header: "Device Sub-Category" },
      { field: "clientId", header: "Client" },
      { field: "clientLocation.name", header: "Location" },
      { field: "device.supplierId", header: "Supplier" },
    ];

    this.FetchAllInteractionTypes();
    this.FetchAllDeviceCategories();
    this.FetchAllDeviceSubCategories();
    this.FetchAllClients();
    this.FetchAllLocations();
    this.FetchAllSuppliers();
  }

  async FetchAllInteractionTypes() {
    this.convergenceService.GetAllInteractionTypes().subscribe(
      async (data) => {
        this.allInteractionTypes = data;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all interaction types at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  async FetchAllDeviceCategories() {
    this.convergenceService.GetAllDeviceCategories().subscribe(
      async (data) => {
        this.allDeviceCategories = data;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all interaction types at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  async FetchAllDeviceSubCategories() {
    this.convergenceService.GetAllDeviceSubCategories().subscribe(
      async (data) => {
        this.allDeviceSubCategories = data;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all device sub-categories at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  async FetchAllClients() {
    this.clientService.allCustomerDivision().subscribe(
      async (data) => {
        this.allClients = data.responseData;
      },
      (error) => {
        this.messageService.add({
          severity: "error",
          summary: "Failed",
          detail: error ?? "Some errors occurred",
        });
      }
    );
  }

  async FetchAllLocations() {
    this.convergenceService.GetAllLocations().subscribe(
      async (data) => {
        this.allLocations = data;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all locations at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  async FetchAllSuppliers() {
    this.supplierService.getAll().subscribe(
      async (data) => {
        this.allSuppliers = data.responseData;
      },
      (error) => {
        this.messageService.add({
          severity: "error",
          summary: "Failed",
          detail: error ?? "Some errors occurred",
        });
      }
    );
  }

  ngAfterViewInit(): void {
    // Load google maps script after view init
    const DSLScript = document.createElement("script");
    DSLScript.src =
      "https://maps.googleapis.com/maps/api/js?key=AIzaSyCQuetprs2UHb_zKSXznyumyfvw95ERCDY"; // replace by your API key
    DSLScript.type = "text/javascript";
    document.body.appendChild(DSLScript);
    document.body.removeChild(DSLScript);
  }

  OnDeviceCategoryChange() {
    this.allDeviceSubCategoriesToShow = [];
    if (this.theDeviceCategory)
      this.allDeviceSubCategoriesToShow = this.allDeviceSubCategories.filter(
        (x) => x.categoryId == this.theDeviceCategory.id
      );
  }

  OnClientChange() {
    this.allClientLocation = [];
    if (this.theClient)
      this.allClientLocation = this.allLocations.filter(
        (x) => x.customerDivisionId == this.theClient.id
      );
  }

  async handleViewChange() {
    if (!this.viewSwitch) {
      await new Promise((resolve) => setTimeout(resolve, 5000)); // 5 secs
      this.initMap();
    }
  }

  // Initialize and add the map
  initMap(): void {
    if (this.locations.length <= 0) return;

    // The map, centered at Loc
    const map = new google.maps.Map(
      document.getElementById("map") as HTMLElement,
      {
        zoom: 6,
        center: this.locations[0],
      }
    );

    this.locations.forEach((x) => {
      // The marker, positioned at Loc
      const marker = new google.maps.Marker({
        position: x,
        map: map,
      });
    });
  }

  ApplyFilters() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Applying Filters...",
    });

    const postData: GetDeviceDashboardFilteredDataVM = {
      from: this.dateFrom,
      to: this.dateTo,
      interactionTypeId: this.theInteractionType
        ? this.theInteractionType.id
        : null,
      deviceCategoryId: this.theDeviceCategory
        ? this.theDeviceCategory.id
        : null,
      deviceSubCategoryId: this.theDeviceSubCategory
        ? this.theDeviceSubCategory.id
        : null,
      clientId: this.theClient ? this.theClient.id : null,
      locationId: this.theClientLocation ? this.theClientLocation.id : null,
      supplierId: this.theSupplier ? this.theSupplier.id : null,
    };

    this.allDeviceAttachments = [];
    this.locations = [];
    this.convergenceService.GetDeviceDashboardFilteredData(postData).subscribe(
      async (data) => {
        data.forEach((deviceAttachment) => {
          deviceAttachment.clientLocation = this.allLocations.find(
            (x) => x.id == deviceAttachment.clientLocationId
          );
          if (deviceAttachment.clientLocation) {
            this.locations.push({
              lat: deviceAttachment.clientLocation.latitude,
              lng: deviceAttachment.clientLocation.longitutude,
            });
          }
          this.allDeviceAttachments.push(deviceAttachment);
        });
        this.allDeviceAttachments = data;
        if (!this.viewSwitch) this.initMap();
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to get filtered data at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  GetInteractionTypeLabel(identifier: number): string {
    let data = this.allInteractionTypes.find((x) => x.id == identifier);
    if (data) return data.caption;

    return "N/A";
  }

  GetCategoryLabel(identifier: number): string {
    let data = this.allDeviceCategories.find((x) => x.id == identifier);
    if (data) return data.caption;

    return "N/A";
  }

  GetSubCategoryLabel(identifier: number): string {
    let data = this.allDeviceSubCategories.find((x) => x.id == identifier);
    if (data) return data.caption;

    return "N/A";
  }

  GetClientLabel(identifier: number): string {
    let data = this.allClients.find((x) => x.id == identifier);
    if (data) return data.divisionName;

    return "N/A";
  }

  GetSupplierLabel(identifier: number): string {
    let data = this.allSuppliers.find((x) => x.id == identifier);
    if (data) return data.supplierName;

    return "N/A";
  }

  ResetFilters() {
    this.dateFrom = null;
    this.dateTo = null;
    this.theInteractionType = null;
    this.theDeviceCategory = null;
    this.allDeviceSubCategoriesToShow = [];
    this.theDeviceSubCategory = null;
    this.theClient = null;
    this.allClientLocation = [];
    this.theClientLocation = null;
    this.theSupplier = null;
  }
}
