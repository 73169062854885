import {
  ApprovalLog,
  BatchRequestItem,
  CentralStore,
  RequestBatchesMade,
} from "./../../../interfaces/inventory";
import { User } from "src/app/interfaces/home";
import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { MessageService, ConfirmationService } from "primeng/api";
import { BreadcrumbService } from "src/app/breadcrumb.service";
import { InventoryService } from "src/app/services/inventory.service";
import { RegionalStore, StockBatch } from "src/app/interfaces/inventory";
import { UserService } from "src/app/services/user.service";

@Component({
  selector: "app-request-audit",
  templateUrl: "./request-audit.component.html",
  styleUrls: ["./request-audit.component.scss"],
})
export class RequestAuditComponent implements OnInit {
  @ViewChild("formWrapper") public formWrapper: ElementRef;
  isRegional: boolean;
  allUsers: User[];
  theUser: User;
  allRegionalStores: RegionalStore[];
  theRegionalStore: RegionalStore;
  allCentralStores: CentralStore[];
  requestToAuditInfo: {
    imageUrl: string;
    name: string;
    email?: string;
    phone?: string;
    location?: string;
    address?: string;
    isRegional: boolean;
  };
  dateRangeFrom: Date;
  dateRangeTo: Date;
  isRequestBatchesMadeLoaded: boolean;
  fetchingRequestBatchesMade: boolean;
  requestedBatches: StockBatch[];
  selectedRequestedBatch: StockBatch;
  reqBatchCols: any[];
  isBatchDetailLoaded: boolean;
  detailTable: {
    name: string;
    data: string;
  }[];
  selectedDetailTable: {
    name: string;
    data: string;
  };
  batchToDisplay: StockBatch;
  allBatchItems: BatchRequestItem[];
  selectedBatchItem: BatchRequestItem;
  itemCols: any[];
  isBatchItemLoaded: boolean;
  openApprovalDialogue: boolean;
  allApprovalLogs: ApprovalLog[];
  selectedApprovalLog: ApprovalLog;
  approvalCols: any[];

  constructor(
    public userService: UserService,
    public inventoryService: InventoryService,
    public messageService: MessageService,
    private breadcrumbService: BreadcrumbService,
    public confirmationService: ConfirmationService
  ) {}

  ngOnInit(): void {
    this.breadcrumbService.setItems([
      {
        label: "Inventory Management",
        routerLink: ["/home/inventory-management/set-up"],
      },
      {
        label: "Request Audit",
        routerLink: ["/home/inventory-management/request-audit"],
      },
    ]);

    this.reqBatchCols = [
      { field: "batchName", header: "BatchName" },
      { field: "batchNumber", header: "BatchNumber" },
    ];

    this.itemCols = [
      { field: "item.name", header: "Item.name" },
      { field: "item.code", header: "Item.code" },
      { field: "item.description", header: "Item.description" },
      { field: "quantity", header: "Quantity" },
      { field: "quantityDispatched", header: "QuantityDispatched" },
      { field: "quantityConfirmed", header: "QuantityConfirmed" },
    ];

    this.approvalCols = [{ field: "createdAt", header: "CreatedAt" }];

    this.detailTable = [
      {
        name: "Batch Name",
        data: "Data",
      },
      {
        name: "Batch Number",
        data: "Data",
      },
      {
        name: "Store Requested From",
        data: "Data",
      },
      {
        name: "Item Count",
        data: "Data",
      },
      {
        name: "Date Created",
        data: "Data",
      },
    ];

    this.FetchAllUsers();
    this.FetchAllRegionalStores();
    this.FetchAllCentralStores();
  }

  LoadInfo(isReg: boolean) {
    this.isRequestBatchesMadeLoaded = false;
    this.dateRangeFrom = null;
    this.dateRangeTo = null;
    this.batchToDisplay = null;
    this.isBatchDetailLoaded = false;
    this.isBatchItemLoaded = false;
    this.requestToAuditInfo = {
      imageUrl: isReg ? this.theRegionalStore.imageUrl : this.theUser.imageUrl,
      name: isReg
        ? this.theRegionalStore.regionalStoreName
        : this.theUser.lastName + this.theUser.firstName,
      email: isReg ? "" : this.theUser.email,
      phone: isReg ? "" : this.theUser.mobileNumber,
      location: isReg ? this.theRegionalStore.regionalStoreName : "",
      address: isReg ? this.theRegionalStore.address : "",
      isRegional: isReg,
    };
  }

  FetchAllUsers() {
    this.userService.allUser().subscribe(
      async (data) => {
        this.allUsers = [];
        data.responseData.forEach((user) => {
          user.fullName = user.lastName + " " + user.firstName;
          this.allUsers.push(user);
        });
      },
      (error) => {
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all users at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  FetchAllRegionalStores() {
    this.inventoryService.GetAllRegionalInventoryStore().subscribe(
      (data) => {
        this.allRegionalStores = data;
      },
      (error) => {
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all regional stores at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  FetchAllCentralStores() {
    this.inventoryService.GetAllCentralInventoryStore().subscribe(
      (data) => {
        this.allCentralStores = data;
      },
      (error) => {
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all central stores at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  GetStoreName(item: StockBatch): string {
    if (item.userRequestedId && item.regionalStoreId) {
      return this.allRegionalStores.find(
        (data) => data.id == item.regionalStoreId
      ).regionalStoreName;
    } else {
      return this.allCentralStores.find(
        (data) =>
          data.id ==
          this.allRegionalStores.find(
            (regData) => regData.id == item.regionalStoreId
          ).centralStoreId
      ).storeName;
    }
  }

  formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }

  LoadBatchDetails(item: StockBatch) {
    this.batchToDisplay = item;
    this.isBatchDetailLoaded = true;
    this.isBatchItemLoaded = false;
    this.detailTable[0].data = item.name;
    this.detailTable[1].data = item.batchNumber;
    this.detailTable[2].data = this.GetStoreName(item);
    this.detailTable[3].data = item.itemsRequested.length + "";
    this.detailTable[4].data = this.formatDate(item.createdAt);
  }

  LoadRequestMade() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Loading batch requests made...",
    });

    this.isRequestBatchesMadeLoaded = true;
    this.fetchingRequestBatchesMade = true;

    var postData: RequestBatchesMade = {
      entityId: this.isRegional ? this.theRegionalStore.id : this.theUser.id,
      isRegional: this.isRegional,
      dateFrom: this.dateRangeFrom,
      dateTo: this.dateRangeTo,
    };

    this.inventoryService.GetRequestBatchesMade(postData).subscribe(
      (data) => {
        this.requestedBatches = data;
        this.fetchingRequestBatchesMade = false;
      },
      (error) => {
        this.isRequestBatchesMadeLoaded = false;
        this.fetchingRequestBatchesMade = false;
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to load batch requests made at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  ShowBatchItems() {
    this.isBatchItemLoaded = true;
    this.allBatchItems = this.batchToDisplay.itemsRequested;
  }

  ShowApprovals() {
    this.openApprovalDialogue = true;
    this.allApprovalLogs = this.batchToDisplay.approval.approvalLogs;
  }

  HideApprovalDialog() {
    this.openApprovalDialogue = false;
    this.allApprovalLogs = null;
  }
}
