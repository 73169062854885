import { GmaCustomerService } from "./../../../services/gma-customer.service";
import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MessageService, ConfirmationService } from "primeng/api";
import { BreadcrumbService } from "src/app/breadcrumb.service";
import {
  AccountVoucherType,
  AddMultipleClientNotificationEmailVM,
  AddNewClientNotificationEmailVM,
  AutoInvoiceSendLog,
  Branch,
  ClientNotificationMail,
  CommonResponse,
  ConfigureInvoiceAutoSendVM,
  Contact,
  Contract,
  ContractService,
  CustomerDivision,
  Office,
  UpdateClientNotificationEmailVM,
  UpdateContractServiceUnqueTagVM,
  UpdateContractServicesReceivingDTO,
} from "src/app/interfaces/home";
import { EventInput } from "@fullcalendar/core";
import {
  ContactDesignationEnum,
  ContactPriorityEnum,
  ContactQualificationEnum,
  ContractContact,
  CreateClientContactVM,
  CreateContractContactVM,
  CustomerContact,
  UpdateClientContractInfoVM,
  UpdateClientInfoVM,
} from "src/app/interfaces/customer";
import { ContractServiceService } from "src/app/services/contract-service.service";
import { BranchService } from "src/app/services/branch.service";
import { OfficeService } from "src/app/services/office.service";
import { AccountVoucherTypeService } from "src/app/services/account-voucher-type.service";
import { CustomerDivisionService } from "src/app/services/customer-division.service";
import { InvoiceService } from "src/app/services/invoice.sevice";
import { ContractsService } from "src/app/services/contracts.service";
import { FileStorageService } from "src/app/services/file-storage.service";
import { SystemPermission } from "src/app/interceptors/system.permission";
import { PermissionEnum } from "src/app/interfaces/permission";

@Component({
  selector: "app-client-information-update",
  templateUrl: "./client-information-update.component.html",
  styleUrls: ["./client-information-update.component.scss"],
})
export class ClientInformationUpdateComponent implements OnInit {
  @ViewChild("formWrapper") public formWrapper: ElementRef;
  autoSendForm: FormGroup;
  clientForm: FormGroup;
  formDecline: FormGroup;
  notificationForm: FormGroup;
  clientContractForm: FormGroup;
  allClients: CustomerDivision[] = [];
  theClient: CustomerDivision;
  imageSrc: string;
  uploadingNewPicture = false;
  file: any;
  carouselResponsiveOptions: any[] = [
    {
      breakpoint: "1024px",
      numVisible: 3,
      numScroll: 3,
    },
    {
      breakpoint: "768px",
      numVisible: 2,
      numScroll: 2,
    },
    {
      breakpoint: "560px",
      numVisible: 1,
      numScroll: 1,
    },
  ];
  attachedClientContacts: CustomerContact[];
  attachedClientContractContacts: ContractContact[];
  allContacts: Contact[];
  theContact: Contact;
  theContractContact: Contact;
  allDesignations: {
    key: number;
    value: string;
  }[];
  theDesignation: {
    key: number;
    value: string;
  };
  theContractDesignation: {
    key: number;
    value: string;
  };
  allQualifications: {
    key: number;
    value: string;
  }[];
  theQualification: {
    key: number;
    value: string;
  };
  theContractQualification: {
    key: number;
    value: string;
  };
  allContactTypes: {
    key: number;
    value: string;
  }[];
  theContactType: {
    key: number;
    value: string;
  };
  theContractContactType: {
    key: number;
    value: string;
  };
  allClientContracts: Contract[];
  theClientContract: Contract;
  showClientInfo: boolean;
  clientInView: CustomerDivision;
  contractTempId: string;
  allIndustries: {
    key: string;
    value: string;
  }[] = [
    {
      key: "Affinity groups and Associations",
      value: "Affinity groups and Associations",
    },
    {
      key: "Advertising",
      value: "Advertising",
    },
    {
      key: "Agriculture",
      value: "Agriculture",
    },
    {
      key: "Aviation",
      value: "Aviation",
    },
    {
      key: "Banking & Finance",
      value: "Banking & Finance",
    },
    {
      key: "Construction",
      value: "Construction",
    },
    {
      key: "Consulting",
      value: "Consulting",
    },
    {
      key: "Education",
      value: "Education",
    },
    {
      key: "Embassies & Foreign Missions",
      value: "Embassies & Foreign Missions",
    },
    {
      key: "Engineering",
      value: "Engineering",
    },
    {
      key: "Events & Entertainment",
      value: "Events & Entertainment",
    },
    {
      key: "Food and Beverages",
      value: "Food and Beverages",
    },
    {
      key: "Gambling",
      value: "Gambling",
    },
    {
      key: "Gaming",
      value: "Gaming",
    },
    {
      key: "Health Care",
      value: "Health Care",
    },
    {
      key: "Hospitality",
      value: "Hospitality",
    },
    {
      key: "Insurance",
      value: "Insurance",
    },
    {
      key: "Manufacturing",
      value: "Manufacturing",
    },
    {
      key: "Maritime",
      value: "Maritime",
    },
    {
      key: "NGO",
      value: "NGO",
    },
    {
      key: "Oil & Gas",
      value: "Oil & Gas",
    },
    {
      key: "Public Sector",
      value: "Public Sector",
    },
    {
      key: "Real Estate",
      value: "Real Estate",
    },
    {
      key: "Religion",
      value: "Religion",
    },
    {
      key: "Residence",
      value: "Residence",
    },
    {
      key: "Retail Sales & Distribution",
      value: "Retail Sales & Distribution",
    },
    {
      key: "Safety & Security",
      value: "Safety & Security",
    },
    {
      key: "Sports",
      value: "Sports",
    },
    {
      key: "Technology",
      value: "Technology",
    },
    {
      key: "Telecommunication",
      value: "Telecommunication",
    },
    {
      key: "Transport & Logistics",
      value: "Transport & Logistics",
    },
    {
      key: "Wholesale Distribution",
      value: "Wholesale Distribution",
    },
    {
      key: "Other",
      value: "Other",
    },
  ];
  theIndustry: {
    key: string;
    value: string;
  };
  theClientContractRegion: Contract;
  theClientContractTag: Contract;
  regionalContractId: string = "";
  tagContractId: string = "";
  regionalContractDesc: string = "";
  tagContractDesc: string = "";
  fetchingContractServices: boolean;
  fetchingContractServices2: boolean;
  allRegionalContractSerices: ContractService[];
  allTagContractSerices: ContractService[] = [];
  selectedRegionalContractSerices: ContractService[] = [];
  selectedTagContractSerices: ContractService[] = [];
  regionContractServicesInView: ContractService[] = [];
  tagContractServicesInView: ContractService[] = [];
  contractServCols: any[];
  allRegions: Branch[];
  theRegion: Branch;
  allOffices: Office[];
  theOffice: Office;
  isUpdatingRegion: boolean;
  isUpdatingTag: boolean;
  isSingleRegionUpdateMode: boolean;
  currentTag: string = "";
  updateTag: string = "";
  openCautionDialogue: boolean;
  cautionAction: number;
  cautionText: string;
  allFinanceVoucherTypes: AccountVoucherType[];
  selectedFinanceVoucherTypes: AccountVoucherType[] = [];
  theFinanceVoucherType: AccountVoucherType;
  editingNotificationEmail: boolean;
  fetchingNotificationEmails: boolean;
  allClientNotificationEmails: ClientNotificationMail[];
  selectedClientNotificationEmail: ClientNotificationMail[];
  clientNotifyEmailCols: any[];
  clientNotificationMailInView: ClientNotificationMail;
  allClientAutoSendConfigs: Contract[];
  selectedClientAutoSendConfigs: Contract[];
  clientAutoSendCols: any[];
  fetchingClientAutoSendConfigs: boolean;
  theAutoSendClientContract: Contract;
  allClientContractServices: ContractService[];
  selectedClientContractServices: ContractService[] = [];
  editingAutoSendConfig: boolean;
  openLogsDialogue: boolean;
  fetchingLogs: boolean;
  autoSendLogs: AutoInvoiceSendLog[];
  logCols: any[];
  canUserUpdateContractService: boolean 

  constructor(
    private fb: FormBuilder,
    public contractService: ContractsService,
    public invoiceService: InvoiceService,
    public customerDivisionService: CustomerDivisionService,
    private accountVoucherTypeService: AccountVoucherTypeService,
    public customerService: GmaCustomerService,
    public contractServiceService: ContractServiceService,
    public branchService: BranchService,
    public officeService: OfficeService,
    public messageService: MessageService,
    private breadcrumbService: BreadcrumbService,
    public confirmationService: ConfirmationService,
    private fileStorageService: FileStorageService
  ) {
    this.clientForm = fb.group({
      ImageUrl: [""],
      Name: ["", Validators.required],
      Email: ["", Validators.required],
      Mobile: ["", Validators.required],
      Industry: ["", Validators.required],
    });

    this.clientContractForm = fb.group({
      ContractID: [""],
      Description: ["", Validators.required],
    });

    this.formDecline = fb.group({
      Comment: [""],
    });

    this.notificationForm = fb.group({
      Category: ["", Validators.required],
      Email: ["", Validators.required],
      Note: ["", Validators.required],
    });

    this.autoSendForm = fb.group({
      Contract: ["", Validators.required],
      ContractService: ["", Validators.required],
    });
  }

  ngOnInit(): void {
    this.breadcrumbService.setItems([
      {
        label: "Client Management",
        routerLink: ["/home/client-management"],
      },
      {
        label: "Information Update",
        routerLink: ["/home/client-management/information-update"],
      },
    ]);

    this.allDesignations = [
      {
        key: ContactDesignationEnum.CEO,
        value: "CEO",
      },
      {
        key: ContactDesignationEnum.CFO,
        value: "CFO",
      },
      {
        key: ContactDesignationEnum.CTO,
        value: "CTO",
      },
      {
        key: ContactDesignationEnum.Admin,
        value: "Admin",
      },
      {
        key: ContactDesignationEnum.Self,
        value: "Self",
      },
    ];

    this.allQualifications = [
      {
        key: ContactQualificationEnum.Owner,
        value: "Owner",
      },
      {
        key: ContactQualificationEnum.Influencer,
        value: "Influencer",
      },
      {
        key: ContactQualificationEnum.GateKeeper,
        value: "GateKeeper",
      },
      {
        key: ContactQualificationEnum.DecisionMaker,
        value: "DecisionMaker",
      },
      {
        key: ContactQualificationEnum.Self,
        value: "Self",
      },
    ];

    this.allContactTypes = [
      {
        key: ContactPriorityEnum.PrimaryContact,
        value: "Primary Contact",
      },
      {
        key: ContactPriorityEnum.SecondaryContact,
        value: "Secondary Contact",
      },
      {
        key: ContactPriorityEnum.OtherContact,
        value: "Other Contact",
      },
    ];

    this.contractServCols = [
      { field: "service.name", header: "Service" },
      { field: "serviceId", header: "Service ID" },
    ];

    this.clientNotifyEmailCols = [
      { field: "emailAddress", header: "Email Address" },
      { field: "note", header: "Note" },
    ];

    this.clientAutoSendCols = [
      { field: "id", header: " Contact ID" },
      { field: "description", header: " Contract Description" },
    ];

    this.logCols = [
      { field: "id", header: "ID" },
      { field: "message", header: "Message" },
      { field: "status", header: "Status" },
      { field: "createdAt", header: "Date Logged" },
    ];

    this.FetchAllClients();
    this.FetchAllContacts();
    this.FetchAllBranches();
    this.FetchAllOffices();
    this.FetchAllAccountVoucherType();
    this.UpdateLoggedInUserPermission()
  }

  FetchAllClients() {
    this.customerService.GetAllClients().subscribe(
      async (data) => {
        this.allClients = data;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all clients the moment.. Reason: [" +
            error.error.message +
            "]",
        });
      }
    );
  }

  FetchAllContacts() {
    this.customerService.GetAllContacts().subscribe(
      async (data) => {
        this.allContacts = data;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all contacts at the moment.. Reason: [" +
            error.error.message +
            "]",
        });
      }
    );
  }

  FetchAllBranches() {
    this.branchService.allBranch().subscribe(
      async (res: CommonResponse) => {
        if (res.responseCode == "00") {
          this.allRegions = res.responseData;
        } else {
          this.messageService.add({
            severity: "error",
            summary: "Failure",
            detail: res.responseMsg,
          });
        }
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to retrieve branch/region at the moment.. Reason: [" +
            error.error.message +
            "]",
        });
      }
    );
  }

  FetchAllOffices() {
    this.officeService.allOffice().subscribe(
      async (res: CommonResponse) => {
        if (res.responseCode == "00") {
          this.allOffices = res.responseData;
        } else {
          this.messageService.add({
            severity: "error",
            summary: "Failure",
            detail: res.responseMsg,
          });
        }
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to retrieve offices at the moment.. Reason: [" +
            error.error.message +
            "]",
        });
      }
    );
  }

  FetchAllAccountVoucherType() {
    this.accountVoucherTypeService.allAccountVoucherTypeData().subscribe(
      async (accountVoucherType: CommonResponse) => {
        this.allFinanceVoucherTypes = accountVoucherType.responseData.filter(
          (x) => x.isApplicableForNotification
        );
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to retrieve branch/region at the moment.. Reason: [" +
            error.error.message +
            "]",
        });
      }
    );
  }

  async UpdateLoggedInUserPermission(){
    this.canUserUpdateContractService = new SystemPermission().HasPermission(PermissionEnum.Client_InformationUpdate_ContractService)
  }

  async FetchClientInViewNotificationMails() {
    this.allClientNotificationEmails = [];
    this.selectedClientNotificationEmail = [];
    if (this.clientInView) {
      this.customerDivisionService
        .GetNotificationEmailsByCustomerDivisionId(this.clientInView.id)
        .subscribe(
          async (data) => {
            if (data.responseCode != "00") {
              this.messageService.add({
                severity: "error",
                summary: data.responseMsg,
              });
              return;
            }

            this.allClientNotificationEmails = data.responseData;
          },
          (error) => {
            console.log("Error: " + JSON.stringify(error));
            this.messageService.add({
              severity: "error",
              summary: "Notice",
              detail:
                "Unable to retrieve client notification mails at the moment.. Reason: [" +
                error.error.message +
                "]",
            });
          }
        );
    }
  }

  async LoadClientInvoiceAutoSendConfigs() {
    if (this.clientInView) {
      this.fetchingClientAutoSendConfigs = true;
      this.invoiceService
        .GetClientInvoiceAutoSendConfigs(this.clientInView.id)
        .subscribe(
          async (data) => {
            if (data.responseCode != "00") {
              this.messageService.add({
                severity: "error",
                summary: data.responseMsg,
              });
              this.fetchingClientAutoSendConfigs = false;
              return;
            }

            this.allClientAutoSendConfigs = data.responseData;
            this.fetchingClientAutoSendConfigs = false;
          },
          (error) => {
            console.log("Error: " + JSON.stringify(error));
            this.messageService.add({
              severity: "error",
              summary: "Notice",
              detail:
                "Unable to retrieve client invoice auto-send configs at the moment.. Reason: [" +
                error.error.message +
                "]",
            });
            this.fetchingClientAutoSendConfigs = false;
          }
        );
    }
  }

  LoadClientInformation() {
    this.showClientInfo = false;
    this.clientInView = null;
    if (this.theClient) {
      this.clientInView = this.theClient;
      if (this.theClient.customer) this.PopulateClientFormData();
      else {
        this.customerService.GetClientInformation(this.theClient.id).subscribe(
          async (data) => {
            this.theClient = data;
            this.clientInView = data;
            this.PopulateClientFormData();
          },
          (error) => {
            console.log("Error: " + JSON.stringify(error));
            this.messageService.add({
              severity: "error",
              summary: "Notice",
              detail:
                "Unable to fetch client info at the moment.. Reason: [" +
                error.error.message +
                "]",
            });
          }
        );
        this.FetchClientInViewNotificationMails();
      }
      this.LoadClientInvoiceAutoSendConfigs();
    }
  }

  PopulateClientFormData() {
    this.clientForm.patchValue({
      ImageUrl: "",
      Name: this.theClient.divisionName,
      Email: this.theClient.email,
      Mobile: this.theClient.phoneNumber,
    });
    this.theIndustry = this.allIndustries.find(
      (x) => x.key == this.theClient.industry
    );
    this.attachedClientContacts = this.theClient.customer.contacts.filter(
      (x) => x.isDeleted == false
    );
    this.imageSrc = this.theClient.logoUrl;
    this.allClientContracts = this.theClient.contracts;
    this.allClientContracts.forEach(
      (x) =>
        (x.caption =
          "Contract-" +
          x.id.toString().padStart(5, "0") +
          "-" +
          (x.description ?? ""))
    );
    this.showClientInfo = true;
  }

  async SaveClientInformation() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Updating Client Information...",
    });

    const id = this.clientInView.id;
    const postData: UpdateClientInfoVM = {
      name: this.clientForm.get("Name").value,
      email: this.clientForm.get("Email").value,
      mobile: this.clientForm.get("Mobile").value,
      industry: this.theIndustry.key,
    };

    if (this.uploadingNewPicture) {
      this.fileStorageService.UploadMultipleFilesFromDataUrl([this.file]);
      this.fileStorageService.onUploadFinished.subscribe(
        (resp: CommonResponse<string[]>) => {
          if (resp.responseCode != "00") {
            this.messageService.add({
              severity: "error",
              summary: "Notice",
              detail: resp.responseMsg,
            });
          } else {
            postData.imageUrl = resp.responseData[0];
            this._saveClientInformation(id, postData);
          }
        },
        (error) => {
          console.log("Error while uploading files " + error);
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail: "ERR: Unable to upload image to storage",
          });
        }
      );
    } else this._saveClientInformation(id, postData);
  }

  _saveClientInformation(id: number, postData: UpdateClientInfoVM) {
    this.customerService.UpdateClientInfo(id, postData).subscribe(
      async () => {
        this.messageService.add({
          severity: "success",
          summary: "Completed",
          detail: "Updated Successfully.",
        });
        this.theClient = this.allClients.find(
          (x) => x.id == this.clientInView.id
        );
        this.imageSrc = null;
        this.file = null;
        this.uploadingNewPicture = false;
        this.LoadClientInformation();
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to update client info at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  onFileSelect(event) {
    if (!this.checkMimeType(event) || !this.checkFileSize(event)) {
      return;
    }

    this.uploadingNewPicture = true;
    const reader = new FileReader();
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      this.file = event.target.files[0];
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.imageSrc = reader.result as string;
      };
    }
  }

  checkMimeType = (event: EventInput): boolean => {
    const files = event.target.files[0];
    // list allow mime type
    const types = ["image/png", "image/jpeg", "image/jpg", "image/gif"];
    // loop access array
    if (!files) {
      this.messageService.add({
        severity: "error",
        summary: "Failed",
        detail: "Please select an Image file",
      });
      return false;
    }
    // compare file type find doesn't match
    if (types.every((type) => files.type !== type)) {
      // create error message and assign to container
      this.messageService.add({
        severity: "error",
        summary: "Failed",
        detail: "Image format not supported",
      });
      return false;
    }
    return true;
  };

  checkFileSize = (event: EventInput) => {
    const files = event.target.files[0];
    if (!files) return true;
    const size = 400000;
    if (files.size > size) {
      event.target.value = null;
      this.messageService.add({
        severity: "error",
        summary: "Failed",
        detail: "Image is too large, please pick a smaller file",
      });
      return false;
    }
    return true;
  };

  DetachContact(item: CustomerContact) {
    this.confirmationService.confirm({
      message: "Are you sure you want to detach customer contact?",
      accept: () => {
        this.messageService.add({
          severity: "info",
          summary: "Notice",
          detail: "Detaching customer contact...",
        });

        this.customerService.DetachClientContact(item.id).subscribe(
          async () => {
            this.messageService.add({
              severity: "success",
              summary: "Removed",
              detail: "Detached successfully",
            });

            const index = this.attachedClientContacts.indexOf(item);
            if (index > -1) {
              this.attachedClientContacts.splice(index, 1);
            }
            this.theClient = this.allClients.find(
              (x) => x.id == this.clientInView.id
            );
            this.LoadClientInformation();
          },
          (error) => {
            console.log("Error: " + JSON.stringify(error));
            this.messageService.add({
              severity: "error",
              summary: "Notice",
              detail:
                "Unable to detach customer contact at the moment.. Reason: [" +
                error.error.message +
                "]",
            });
          }
        );
      },
    });
  }

  OnContactChange() {}

  OnDesignationChange() {}

  OnQualificationChange() {}

  OnContactTypeChange() {}

  SaveClientContact() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Attaching new client contact...",
    });

    const postData: CreateClientContactVM = {
      customerId: this.clientInView.customerId,
      contactId: this.theContact.id,
      designation: this.theDesignation.key,
      qualification: this.theQualification.key,
      contactType: this.theContactType.key,
    };

    this.customerService.CreateClientContact(postData).subscribe(
      async () => {
        this.messageService.add({
          severity: "success",
          summary: "Completed",
          detail: "Attached Successfully...",
        });

        this.theContact = null;
        this.theDesignation = null;
        this.theQualification = null;
        this.theContactType = null;
        this.theClient = this.allClients.find(
          (x) => x.id == this.clientInView.id
        );
        this.LoadClientInformation();
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to create client contact at the moment.. Reason: [" +
            error.error.message +
            "]",
        });
      }
    );
  }

  LoadContractInformation() {
    this.contractTempId = "";
    if (this.theClientContract) {
      this.clientContractForm.patchValue({
        ContractID: this.theClientContract.id.toString().padStart(5, "0"),
        Description: this.theClientContract.description,
      });
      this.contractTempId = this.theClientContract.id
        .toString()
        .padStart(5, "0");
      this.attachedClientContractContacts =
        this.theClientContract.contacts.filter((x) => x.isDeleted == false);
    } else {
      this.clientContractForm.reset();
      this.attachedClientContractContacts = [];
      this.theContractContact = null;
      this.theContractDesignation = null;
      this.theContractContactType = null;
      this.theContractQualification = null;
    }
  }

  SaveClientContractInfo() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Updating Contract...",
    });

    const id = this.theClientContract.id;
    const postData: UpdateClientContractInfoVM = {
      description: this.clientContractForm.get("Description").value,
    };

    this.customerService.UpdateClientContractInfo(id, postData).subscribe(
      async () => {
        this.messageService.add({
          severity: "success",
          summary: "Completed",
          detail: "Updated Successfully.",
        });
        this.theClientContract.description = postData.description;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to update client contact info at the moment.. Reason: [" +
            error.error.message +
            "]",
        });
      }
    );
  }

  DetachContractContact(item: ContractContact) {
    this.confirmationService.confirm({
      message: "Are you sure you want to detach contract contact?",
      accept: () => {
        this.messageService.add({
          severity: "info",
          summary: "Notice",
          detail: "Detaching contract contact...",
        });

        this.customerService.DetachContractContact(item.id).subscribe(
          async () => {
            this.messageService.add({
              severity: "success",
              summary: "Removed",
              detail: "Detached successfully",
            });

            const index = this.attachedClientContractContacts.indexOf(item);
            if (index > -1) {
              this.attachedClientContractContacts.splice(index, 1);
            }
            this.clientContractForm.reset();
            this.theClientContract = null;
            this.attachedClientContractContacts = [];
            this.theContractContact = null;
            this.theContractDesignation = null;
            this.theContractContactType = null;
            this.theContractQualification = null;
            this.theClient = this.allClients.find(
              (x) => x.id == this.clientInView.id
            );
            this.LoadClientInformation();
          },
          (error) => {
            console.log("Error: " + JSON.stringify(error));
            this.messageService.add({
              severity: "error",
              summary: "Notice",
              detail:
                "Unable to detach contract contact at the moment.. Reason: [" +
                error.error.message +
                "]",
            });
          }
        );
      },
    });
  }

  OnContractContactChange() {}

  OnContractDesignationChange() {}

  OnContractQualificationChange() {}

  OnContractContactTypeChange() {}

  SaveClientContractContact() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Updating Contract...",
    });

    const postData: CreateContractContactVM = {
      contractId: this.theClientContract.id,
      contactId: this.theContractContact.id,
      designation: this.theContractDesignation.key,
      qualification: this.theContractQualification.key,
      contactType: this.theContractContactType.key,
    };

    this.customerService.CreateContractContact(postData).subscribe(
      async () => {
        this.messageService.add({
          severity: "success",
          summary: "Completed",
          detail: "Attached Successfully...",
        });

        this.theClientContract = null;
        this.attachedClientContractContacts = [];
        this.theClientContract = null;
        this.theContractContact = null;
        this.theContractDesignation = null;
        this.theContractQualification = null;
        this.theContractContactType = null;
        this.theClient = this.allClients.find(
          (x) => x.id == this.clientInView.id
        );
        this.LoadClientInformation();
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to create contract contact at the moment.. Reason: [" +
            error.error.message +
            "]",
        });
      }
    );
  }

  LoadRegionalInformation() {
    this.fetchingContractServices = true;
    this.isUpdatingRegion = false;
    this.regionalContractId = "";
    this.regionalContractDesc = "";

    if (this.theClientContractRegion) {
      this.regionalContractId = this.theClientContractRegion.id
        .toString()
        .padStart(5, "0");
      this.regionalContractDesc = this.theClientContractRegion.description;
      this.contractServiceService
        .GetAllContractServciesByContractId(this.theClientContractRegion.id)
        .subscribe(
          async (res: CommonResponse) => {
            if (res.responseCode == "00") {
              this.allRegionalContractSerices = res.responseData;
              this.fetchingContractServices = false;
            } else {
              this.messageService.add({
                severity: "error",
                summary: "Failure",
                detail:
                  "Err: Could not get all contract services. Reason -> " +
                  res.responseMsg,
              });
              this.fetchingContractServices = false;
            }
          },
          (error) => {
            console.log("Error: " + JSON.stringify(error));
            this.messageService.add({
              severity: "error",
              summary: "Notice",
              detail:
                "Unable to get contract services at the moment.. Reason: [" +
                error.error.message +
                "]",
            });
            this.fetchingContractServices = false;
          }
        );
    } else {
      this.fetchingContractServices = false;
    }
  }

  EditContractService(item: ContractService) {
    this.isSingleRegionUpdateMode = true;
    this.isUpdatingRegion = true;
    this.regionContractServicesInView = [item];
  }

  UpdateAllSelected() {
    this.isSingleRegionUpdateMode = false;
    this.isUpdatingRegion = true;
    this.regionContractServicesInView = this.selectedRegionalContractSerices;
  }

  SaveRegionChanges() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Updating Contract Service(s)...",
    });

    const postData: UpdateContractServicesReceivingDTO = {
      contractServiceIds: this.regionContractServicesInView.map((x) => x.id),
      branchId: this.theRegion.id,
      officeId: this.theOffice.id,
    };

    this.contractServiceService.UpdateContractServices(postData).subscribe(
      async () => {
        this.messageService.add({
          severity: "success",
          summary: "Completed",
          detail: "Updated Successfully.",
        });
        this.isUpdatingRegion = false;
        this.theRegion = null;
        this.theOffice = null;
        this.regionContractServicesInView = [];
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to update contract service(s) at the moment.. Reason: [" +
            error.error.message +
            "]",
        });
      }
    );
  }

  LoadTagInformation() {
    this.fetchingContractServices2 = true;
    this.isUpdatingTag = false;
    this.tagContractId = "";
    this.tagContractDesc = "";

    if (this.theClientContractTag) {
      this.tagContractId = this.theClientContractTag.id
        .toString()
        .padStart(5, "0");
      this.tagContractDesc = this.theClientContractTag.description;
      this.contractServiceService
        .GetAllContractServciesByContractId(this.theClientContractTag.id)
        .subscribe(
          async (res) => {
            if (res.responseCode == "00") {
              this.allTagContractSerices = res.responseData.filter(
                (x) => x.version == 0
              );
              this.fetchingContractServices2 = false;
            } else {
              this.messageService.add({
                severity: "error",
                summary: "Failure",
                detail:
                  "Err: Could not get all contract services. Reason -> " +
                  res.responseMsg,
              });
              this.fetchingContractServices2 = false;
            }
          },
          (error) => {
            console.log("Error: " + JSON.stringify(error));
            this.messageService.add({
              severity: "error",
              summary: "Notice",
              detail:
                "Unable to get contract services at the moment.. Reason: [" +
                error.error.message +
                "]",
            });
            this.fetchingContractServices2 = false;
          }
        );
    } else {
      this.fetchingContractServices2 = false;
    }
  }

  EditContractService2(item: ContractService) {
    this.isUpdatingTag = true;
    this.currentTag = item.uniqueTag;
    this.tagContractServicesInView = [item];
  }

  UpdateAllSelected2() {
    this.cautionAction = 2;
    this.cautionText =
      "This will update all contract services tied to this contract with the first contract service unique tag based on the service. This is an irreversible action, Do you still wish to proceed?";
    this.openCautionDialogue = true;
  }

  SaveTagChanges() {
    this.cautionAction = 1;
    this.cautionText =
      "This action will update the selected contract service unique tag. This is an irreversible action, Do you still wish to proceed?";
    this.openCautionDialogue = true;
  }

  HideCautionDialog() {
    this.openCautionDialogue = false;
    this.cautionText = null;
    this.cautionAction = null;
  }

  CautionAction() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Updating Contract Service(s)...",
    });

    let postData: UpdateContractServiceUnqueTagVM;

    if (this.cautionAction == 1) {
      postData = {
        isAllContractServices: false,
        contractId: this.theClientContractTag.id,
        contractServiceId: this.tagContractServicesInView[0].id,
        newTag: this.updateTag,
      };
    } else if (this.cautionAction == 2) {
      postData = {
        isAllContractServices: true,
        contractId: this.theClientContractTag.id,
        contractServiceId: 0,
        newTag: "------",
      };
    }

    this.contractServiceService
      .UpdateContractServiceUnqueTag(postData)
      .subscribe(
        async () => {
          this.messageService.add({
            severity: "success",
            summary: "Completed",
            detail: "Updated Successfully.",
          });
          this.isUpdatingTag = false;
          this.currentTag = null;
          this.updateTag = null;
          if (this.cautionAction == 1) {
            this.tagContractServicesInView[0].uniqueTag = postData.newTag;
          } else if (this.cautionAction == 2) {
            this.allTagContractSerices = [];
            this.theClientContractTag = null;
          }

          this.tagContractServicesInView = [];
          this.HideCautionDialog();
        },
        (error) => {
          console.log("Error: " + JSON.stringify(error));
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail:
              "Unable to update contract service(s) at the moment.. Reason: [" +
              error.error.message +
              "]",
          });
        }
      );
  }

  AddNotificationEmail() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Adding Client Notification Mail...",
    });

    const postData: AddNewClientNotificationEmailVM = {
      clientId: this.clientInView.id,
      categoryId: this.theFinanceVoucherType.id,
      email: this.notificationForm.get("Email").value,
      note: this.notificationForm.get("Note").value,
    };

    this.customerDivisionService
      .AddNewClientNotificationEmail(postData)
      .subscribe(
        async (data) => {
          if (data.responseCode != "00") {
            this.messageService.add({
              severity: "error",
              summary: data.responseMsg,
            });
            return;
          }

          this.messageService.add({
            severity: "success",
            summary: "Completed",
            detail: "Client Notification Mail Added Successfully!",
          });
          this.CloseEditingNotificationEmail();
          this.FetchClientInViewNotificationMails();
        },
        (error) => {
          console.log("Error: " + JSON.stringify(error));
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail:
              "Unable to add client notification mail at the moment.. Reason: [" +
              (error ? error.error.message : "request failed - permission") +
              "]",
          });
        }
      );
  }

  AddMultipleNotificationEmail() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Adding Client Notification Mail(s)...",
    });

    const postData: AddMultipleClientNotificationEmailVM = {
      clientId: this.clientInView.id,
      categoriesIds: this.selectedFinanceVoucherTypes.map((x) => x.id),
      email: this.notificationForm.get("Email").value,
      note: this.notificationForm.get("Note").value,
    };

    this.customerDivisionService
      .AddMultipleClientNotificationEmail(postData)
      .subscribe(
        async (data) => {
          if (data.responseCode != "00") {
            this.messageService.add({
              severity: "error",
              summary: data.responseMsg,
            });
            return;
          }

          this.messageService.add({
            severity: "success",
            summary: "Completed",
            detail: "Client Notification Mail(s) Added Successfully!",
          });
          this.CloseEditingNotificationEmail();
          this.FetchClientInViewNotificationMails();
        },
        (error) => {
          console.log("Error: " + JSON.stringify(error));
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail:
              "Unable to add client notification mail at the moment.. Reason: [" +
              (error ? error.error.message : "request failed - permission") +
              "]",
          });
        }
      );
  }

  CloseEditingNotificationEmail() {
    this.editingNotificationEmail = false;
    this.notificationForm.reset();
    this.clientNotificationMailInView = null;
    this.selectedFinanceVoucherTypes = [];
  }

  UpdateNotificationEmail() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Updating Client Notification Mail...",
    });

    const id = this.clientNotificationMailInView.id;
    const postData: UpdateClientNotificationEmailVM = {
      email: this.notificationForm.get("Email").value,
      categoryId: this.theFinanceVoucherType.id,
      note: this.notificationForm.get("Note").value,
    };

    this.customerDivisionService
      .UpdateClientNotificationEmail(id, postData)
      .subscribe(
        async (data) => {
          if (data.responseCode != "00") {
            this.messageService.add({
              severity: "error",
              summary: data.responseMsg,
            });
            return;
          }

          this.messageService.add({
            severity: "success",
            summary: "Completed",
            detail: "Client Notification Mail Updated Successfully.",
          });
          this.CloseEditingNotificationEmail();
          this.FetchAllAccountVoucherType();
        },
        (error) => {
          console.log("Error: " + JSON.stringify(error));
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail:
              "Unable to update client notification mail at the moment.. Reason: [" +
              (error ? error.error.message : "request failed - permission") +
              "]",
          });
        }
      );
  }

  DeleteNotificationEmail(item: ClientNotificationMail) {
    this.confirmationService.confirm({
      message: "Are you sure you want to remove client notification mail?",
      accept: () => {
        this.messageService.add({
          severity: "info",
          summary: "Notice",
          detail: "Removing client notification mail...",
        });

        this.customerDivisionService
          .DeleteClientNotificationEmail(item.id)
          .subscribe(
            async (data) => {
              if (data.responseCode != "00") {
                this.messageService.add({
                  severity: "error",
                  summary: data.responseMsg,
                });
                return;
              }

              this.messageService.add({
                severity: "success",
                summary: "Removed",
                detail: "Removed successfully",
              });
              this.fetchingNotificationEmails = true;
              const index = this.allClientNotificationEmails.indexOf(item);
              if (index > -1) {
                this.allClientNotificationEmails.splice(index, 1);
              }
              this.fetchingNotificationEmails = false;
            },
            (error) => {
              console.log("Error: " + JSON.stringify(error));
              this.messageService.add({
                severity: "error",
                summary: "Notice",
                detail:
                  "Unable to remove client notification emails at the moment.. Reason: [" +
                  error
                    ? error.error.message
                    : "request failed - permission" + "]",
              });
            }
          );
      },
    });
  }

  EditNotificationEmail(item: ClientNotificationMail) {
    this.editingNotificationEmail = true;
    this.notificationForm.patchValue({
      Email: item.emailAddress,
      Note: item.note,
    });
    this.theFinanceVoucherType = this.allFinanceVoucherTypes.find(
      (x) => x.id == item.financeVoucherTypeId
    );
    this.clientNotificationMailInView = item;

    this.formWrapper.nativeElement.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "start",
    });
  }

  GetFinanceVouherTypeLabel(voucherId: number): string {
    let voucher = this.allFinanceVoucherTypes.find((x) => x.id == voucherId);
    if (voucher) return voucher.voucherType;

    return "N/A";
  }

  AddInvoiceAutoSendConfig() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Save Client Invoice Auto-Send Configuration...",
    });

    const postData: ConfigureInvoiceAutoSendVM = {
      contractId: this.theAutoSendClientContract.id,
      contractServiceIds: this.selectedClientContractServices.map((x) => x.id),
    };

    this.invoiceService.ConfigureInvoiceAutoSend(postData).subscribe(
      async (data) => {
        if (data.responseCode != "00") {
          this.messageService.add({
            severity: "error",
            summary: data.responseMsg,
          });
          return;
        }

        this.theAutoSendClientContract.isConfiguredForAutoInvoiceSend = true;
        this.theAutoSendClientContract.dateConfiguredForAutoInvoiceSend =
          new Date();
        this.messageService.add({
          severity: "success",
          summary: "Completed",
          detail: "Invoice Auto-Send Configuration Saved Successfully!",
        });
        this.CloseEditingAutoSendConfig();
        this.LoadClientInvoiceAutoSendConfigs();
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to save configuration at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  LoadContractServices() {
    this.allClientContractServices = [];
    if (this.theAutoSendClientContract) {
      this.contractServiceService
        .GetAllContractServciesByContractId(this.theAutoSendClientContract.id)
        .subscribe(
          async (res) => {
            if (res.responseCode == "00") {
              this.allClientContractServices = res.responseData.filter(
                (x) => x.version == 0
              );
              this.allClientContractServices.forEach(
                (x) =>
                  (x.label =
                    x.service.name +
                    "/" +
                    this.FormatDateString(new Date(x.contractStartDate)) +
                    " - " +
                    this.FormatDateString(new Date(x.contractEndDate)))
              );
              this.selectedClientContractServices =
                this.allClientContractServices.filter(
                  (x) => x.isConfiguredForAutoInvoiceSend
                );
            } else {
              this.messageService.add({
                severity: "error",
                summary: "Failure",
                detail:
                  "Err: Could not get all contract services. Reason -> " +
                  res.responseMsg,
              });
            }
          },
          (error) => {
            console.log("Error: " + JSON.stringify(error));
            this.messageService.add({
              severity: "error",
              summary: "Notice",
              detail:
                "Unable to get contract services at the moment.. Reason: [" +
                error.error.message +
                "]",
            });
          }
        );
    }
  }

  CloseEditingAutoSendConfig() {
    this.autoSendForm.reset();
    this.theAutoSendClientContract = null;
    this.selectedClientContractServices = [];
    this.editingAutoSendConfig = false;
    this.allClientContractServices = [];
  }

  FormatDateString(date: Date): string {
    let dateString = date.toString();

    try {
      // DateTimeFormatOptions
      const date: Date = new Date(dateString);
      const options: Intl.DateTimeFormatOptions = {
        year: "numeric",
        month: "long",
        day: "numeric",
      };
      const formattedDate: string = new Intl.DateTimeFormat(
        "en-US",
        options
      ).format(date);
      dateString = formattedDate;
    } catch (error) {
      console.log(error);
      console.log(date);
      console.log(
        "Error while converting date string " + date + " exception " + error
      );
    }

    return dateString;
  }

  DeleteInvoiceAutoSendConfig(item: Contract) {
    this.confirmationService.confirm({
      message: "Are you sure you want to delete invoice auto-send config?",
      accept: () => {
        this.messageService.add({
          severity: "info",
          summary: "Notice",
          detail: "Deleting invoice auto-send config...",
        });

        const postData: ConfigureInvoiceAutoSendVM = {
          contractId: this.theAutoSendClientContract.id,
          contractServiceIds: [],
          isDeleting: true,
        };

        this.invoiceService.ConfigureInvoiceAutoSend(postData).subscribe(
          async (data) => {
            if (data.responseCode != "00") {
              this.messageService.add({
                severity: "error",
                summary: data.responseMsg,
              });
              return;
            }

            this.messageService.add({
              severity: "success",
              summary: "Removed",
              detail: "Deleted successfully",
            });

            const index = this.allClientAutoSendConfigs.indexOf(item);
            if (index > -1) {
              this.allClientAutoSendConfigs.splice(index, 1);
            }
            this.CloseEditingAutoSendConfig();
          },
          (error) => {
            console.log("Error: " + JSON.stringify(error));
            this.messageService.add({
              severity: "error",
              summary: "Notice",
              detail:
                "Unable to detach customer contact at the moment.. Reason: [" +
                error.error.message +
                "]",
            });
          }
        );
      },
    });
  }

  EditInvoiceAutoSendConfig(item: Contract) {
    this.editingAutoSendConfig = true;
    this.theAutoSendClientContract = this.allClientContracts.find(
      (x) => x.id == item.id
    );
    item.contractServices.forEach(
      (x) =>
        (x.label = x.label =
          x.service.name +
          "/" +
          this.FormatDateString(new Date(x.contractStartDate)) +
          " - " +
          this.FormatDateString(new Date(x.contractEndDate)))
    );
    this.allClientContractServices = item.contractServices.filter(
      (x) => x.version == 0
    );
    this.selectedClientContractServices = this.allClientContractServices.filter(
      (x) => x.isConfiguredForAutoInvoiceSend
    );

    this.formWrapper.nativeElement.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "start",
    });
  }

  ViewAutoSendLogs(item: Contact) {
    this.openLogsDialogue = true;
    this.fetchingLogs = true;
    this.contractService.GetInvoiceAutoSendLogs(item.id).subscribe(
      async (data) => {
        if (data.responseCode != "00") {
          this.messageService.add({
            severity: "error",
            summary: data.responseMsg,
          });
          this.fetchingLogs = false;
          return;
        }

        this.autoSendLogs = data.responseData;
        this.fetchingLogs = false;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch logs at the moment.. Reason: [" +
            error.error.message +
            "]",
        });
        this.fetchingLogs = false;
      }
    );
  }

  HideLogsDialog() {
    this.openLogsDialogue = false;
    this.autoSendLogs = [];
  }
}
