import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class CustomerDivisionService {
  public baseUrl = environment.armadaBaseUrl + "CustomerDivision/";

  constructor(private httpClient: HttpClient) {}

  public allWithSecureMobility() {
    return this.httpClient.get(
      this.baseUrl + "GetAllClientsWithSecuredMobilityContractServices"
    );
  }
  public getClient(contractServiceId: number) {
    return this.httpClient.get(this.baseUrl + "GetClient/" + contractServiceId);
  }
  public allWithAssignedSecureMobility() {
    return this.httpClient.get(
      this.baseUrl + "GetAllClientsWithAssignedSecuredMobilityContractServices"
    );
  }
  public allWithUnAssignedSecureMobility() {
    return this.httpClient.get(
      this.baseUrl +
        "GetAllClientsWithUnAssignedSecuredMobilityContractServices"
    );
  }
}
