import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { CommonResponse } from "../../../interfaces/home";
import { ConfirmationService, MessageService } from "primeng/api";
import { User } from "../../../interfaces/home";
import { OtherUsersService } from "src/app/services/other-users.service";
import { BreadcrumbService } from "src/app/breadcrumb.service";
import { EventInput } from "@fullcalendar/core";
import { FileStorageService } from "src/app/services/file-storage.service";

@Component({
  selector: "app-halogen-users",
  templateUrl: "./halogen-users.component.html",
  styleUrls: ["./halogen-users.component.scss"],
})
export class HalogenUsersComponent implements OnInit {
  public otherUsersForm: FormGroup;

  fetchingOtherUser = true;
  otherUsers: User;
  selectedOtherUsers: User;
  editingOtherUsers = false;
  public userCols: any[];
  public exportUserColumns: any[];
  imageSrc: string;
  file: any[] = [];

  private edUser: User;
  submitted: boolean = false;
  @ViewChild("formWrapper") public formWrapper: ElementRef;
  uniqueEmailError: string;
  hiddenfileinput: any;
  uploadingNewPicture: boolean;
  constructor(
    public formBuilder: FormBuilder,
    public messageService: MessageService,
    public confirmationService: ConfirmationService,
    public otherUsersService: OtherUsersService,
    private breadcrumbService: BreadcrumbService,
    private fileStorageService: FileStorageService
  ) {}

  ngOnInit() {
    this.breadcrumbService.setItems([
      { label: "Profile", routerLink: ["home/profile"] },
      { label: "Halogen Users", routerLink: ["home/profile/halogen-users"] },
    ]);

    this.otherUsersForm = this.formBuilder.group({
      imageUrl: [""],
      firstName: ["", Validators.required],
      lastName: ["", Validators.required],
      email: ["", Validators.required],
      mobileNumber: ["", Validators.required],
      address: ["", Validators.required],
      otherUserId: [""],
    });

    this.userCols = [
      { field: "firstname", header: "firstName" },
      { field: "lastName", header: "lastName" },
      { field: "email", header: "email" },
      { field: "mobileNumber", header: "mobileNumber" },
      { field: "address", header: "address" },
    ];
    this.exportUserColumns = this.userCols.map((col) => ({
      title: col.header,
      dataKey: col.field,
    }));

    this.fetchOtherUser();
  }

  fetchOtherUser() {
    this.otherUsersService.allHalogenUsersData().subscribe(
      async (otherUser: CommonResponse) => {
        this.otherUsers = otherUser.responseData;
        this.fetchingOtherUser = false;
      },
      (error) => {
        this.fetchingOtherUser = false;
        this.connectionError();
      }
    );
  }

  private connectionError() {
    this.messageService.add({
      severity: "error",
      summary: "Failed",
      detail: "Connection Error, Please try again",
    });
  }

  createUser() {
    this.submitted = true;

    if (!this.otherUsersForm.valid) {
      return;
    }
    const postData = this.otherUsersForm.value;

    this.otherUsersService.getUsersByEmail(postData.email).subscribe(
      async (res: CommonResponse) => {
        if (res.responseCode == "00") {
          this.messageService.add({
            severity: "error",
            summary: "Email Already Exist",
            detail: "This Email has already been used by another user",
          });
        } else {
          this._createOtherUser(postData);
        }
      },
      (error) => {
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail: "Error Validating user Email",
        });
      }
    );
  }

  onClickSave() {
    if (this.file.length > 0) {
      this.uploadImage();
    } else {
      this.createUser();
    }
  }

  uploadImage() {
    if (this.file.length > 0) {
      this.fileStorageService.UploadMultipleFilesFromDataUrl(this.file);
      this.fileStorageService.onUploadFinished.subscribe(
        (resp: CommonResponse<string[]>) => {
          if (resp.responseCode != "00") {
            this.messageService.add({
              severity: "error",
              summary: "Notice",
              detail: resp.responseMsg,
            });
          } else {
            var imageUrl = resp.responseData;
            this.otherUsersForm.patchValue({
              imageUrl: imageUrl[0],
            });
            if (this.editingOtherUsers) {
              this.updateOtherUsers();
            } else {
              this.createUser();
            }
          }
        },
        (error) => {
          console.log("Error while creating platform " + error);
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail: "ERR: Unable to upload image to storage",
          });
        }
      );
    }
  }

  private _createOtherUser(postData) {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Creating new User Records",
    });
    this.otherUsersService.postHalogenUsers(postData).subscribe(
      async (res: CommonResponse) => {
        if (res.responseCode == "00") {
          await this.messageService.add({
            severity: "success",
            summary: "Completed",
            detail: "New Halogen User Created",
          });
          this.submitted = false;
          await this.fetchOtherUser();
          await this.otherUsersForm.reset();
        } else {
          await this.messageService.add({
            severity: "error",
            summary: "Failure",
            detail: res.responseMsg,
          });
        }
      },
      (error) => {
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail: "Unable to create new User at the moment",
        });
      }
    );
  }

  deleteOtherUsers(otherUser) {
    this.confirmationService.confirm({
      message: "Are you sure you want to disable this user ",
      accept: () => {
        this._deleteOtherUsers(otherUser);
      },
    });
  }
  _deleteOtherUsers(otherUser) {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Disabling user record",
    });
    this.otherUsersService.disableUsers([otherUser.email]).subscribe(
      async (result: any) => {
        if (result.responseCode != "00") {
          await this.messageService.add({
            severity: "error",
            summary: "Failure",
            detail: result.responseMsg,
          });
        } else {
          await this.fetchOtherUser();
          await this.messageService.add({
            severity: "success",
            summary: "Notice",
            detail: "User disabled successfully",
          });
        }
      },
      (error) => {
        this.connectionError();
      }
    );
  }
  resetUserPassword(otherUser) {
    this.confirmationService.confirm({
      message: "Are you sure you want to reset this user's password ",
      accept: () => {
        this._resetUserPassword(otherUser);
      },
    });
  }
  _resetUserPassword(otherUser) {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Resetting user password",
    });
    this.otherUsersService.resetPasswordUsers([otherUser.email]).subscribe(
      async (result: any) => {
        if (result.responseCode != "00") {
          await this.messageService.add({
            severity: "error",
            summary: "Failure",
            detail: result.responseMsg,
          });
        } else {
          await this.fetchOtherUser();
          await this.messageService.add({
            severity: "success",
            summary: "Notice",
            detail: "User password reset successfully",
          });
        }
      },
      (error) => {
        this.connectionError();
      }
    );
  }

  onClickUpdate() {
    if (this.file.length > 0) {
      this.editingOtherUsers = true;
      this.uploadImage();
    } else {
      this.updateOtherUsers();
    }
  }
  editUser(otherUser: User) {
    this.editingOtherUsers = true;
    this.otherUsersForm.addControl(
      "otherUserId",
      new FormControl({ value: "", disabled: true }, Validators.required)
    );
    this.edUser = otherUser;
    this.otherUsersForm.patchValue({
      firstName: otherUser.firstName,
      lastName: otherUser.lastName,
      email: otherUser.email,
      mobileNumber: otherUser.mobileNumber,
      address: otherUser.address,
      otherUserId: otherUser.id,
    });
    // this.bankFormIn.nativeElement.focus();
    // document.getElementById('formFields').focus();
    // this.selectedHeadId = this.users.find(user => user.id === banks.head.id);

    this.imageSrc = otherUser.imageUrl;

    this.formWrapper.nativeElement.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "start",
    });
  }

  updateOtherUsers() {
    // update the selected bank
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Updating Halogen user details",
    });
    const id = this.edUser.id;
    const postData = this.otherUsersForm.value;
    // {
    //   firstName: this.otherUsersForm.get('firstName').value,
    //   lastName: this.otherUsersForm.get('lastName').value,
    //   email: this.otherUsersForm.get('email').value,
    //   mobileNumber: this.otherUsersForm.get('mobileNumber').value,
    //   address: this.otherUsersForm.get('address').value
    // };
    this.otherUsersService.updateHalogenUserusers(id, postData).subscribe(
      async (result: CommonResponse) => {
        if (result.responseCode == "00") {
          this.messageService.add({
            severity: "success",
            summary: "Notice",
            detail: "Halogen user details Updated",
          });
          this.fetchOtherUser();
          this.closeEditing();
        } else {
          this.messageService.add({
            severity: "error",
            summary: "Failure",
            detail: result.responseMsg,
          });
        }
        //this.fetchOtherUser();
        //this.closeEditing();
      },
      (error) => {
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail: "Some system error occured",
        });
      }
    );
  }

  closeEditing() {
    this.editingOtherUsers = false;
    this.otherUsersForm.reset();
    this.imageSrc = null;
    this.edUser = null;
  }

  checkUniqueEmail(event) {
    var Email = this.otherUsersForm.controls.email.value;

    this.otherUsersService.getUsersByEmail(Email).subscribe(
      async (res) => {
        if (res.responseCode == "00") {
          //does not exist
          this.otherUsersForm.get("email").setErrors(null);
          this.flagNotUniqueEmail(Email);
        }
      },
      async (error: any) => {}
    );
  }

  private flagNotUniqueEmail(Email: string) {
    //value exists, indicate to user
    this.uniqueEmailError = `The email address '${Email}' has been taken`;
    this.otherUsersForm.get("email").setErrors({ "not-unique": true });
  }

  onFileSelect(event) {
    if (!this.checkMimeType(event) || !this.checkFileSize(event)) {
      return;
    }

    this.uploadingNewPicture = true;
    //this.isEditing = true;
    this.otherUsersForm.enable();
    const reader = new FileReader();
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      this.file.push(event.target.files[0]);
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.imageSrc = reader.result as string;
        this.otherUsersForm.patchValue({
          imageUrl: reader.result,
        });
      };
    }
  }

  checkMimeType = (event: EventInput): boolean => {
    const files = event.target.files[0];
    const types = ["image/png", "image/jpeg", "image/jpg", "image/gif"];
    if (!files) {
      this.messageService.add({
        severity: "error",
        summary: "Failed",
        detail: "Please select an Image file",
      });
      return false;
    }
    if (types.every((type) => files.type !== type)) {
      this.messageService.add({
        severity: "error",
        summary: "Failed",
        detail: "Image format not supported",
      });
      return false;
    }
    return true;
  };

  checkFileSize = (event: EventInput) => {
    const files = event.target.files[0];
    if (!files) return true;
    const size = 400000;
    if (files.size > size) {
      event.target.value = null;
      this.messageService.add({
        severity: "error",
        summary: "Failed",
        detail: "Image is too large, please pick a smaller file",
      });
      return false;
    }
    return true;
  };
}
