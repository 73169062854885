import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { PilotType } from "src/app/interfaces/armada";
import { CommonResponse } from "src/app/interfaces/home";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class PilotTypeService {
  baseUrl = environment.baseUrl + "Pilot/";
  constructor(private httpClient: HttpClient) {}
  allPilotType(): Observable<CommonResponse> {
    return this.httpClient.get<CommonResponse>(
      this.baseUrl + "GetAllPilotTypes"
    );
    //return this.http.get<Approval[]>(this.baseUrl + 'GetUserPendingApprovals');
  }
  getPilotType(id: any): Observable<CommonResponse> {
    return this.httpClient.get<CommonResponse>(
      this.baseUrl + "GetTypeById/" + id
    );
  }

  postPilotType(data: any) {
    return this.httpClient.post(this.baseUrl + "AddNewType", data);
  }
  delete(id: any) {
    return this.httpClient.delete(this.baseUrl + "DeleteTypeById/" + id);
  }
  updatePilotType(id: any, data: any) {
    return this.httpClient.put(this.baseUrl + "UpdateTypeById/" + id, data);
  }
}
