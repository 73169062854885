import {
  CreateGMAanctionsVM,
  GMASanction,
  GuardDeployment,
  SanctionReasonEnum,
  SanctionSeverityLevelEnum,
  SanctionTypeEnum,
  UnsanctionedDeployedGuardVM,
} from "./../../../interfaces/employment";
import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { ConfirmationService, Message, MessageService } from "primeng/api";
import { BreadcrumbService } from "src/app/breadcrumb.service";
import { GmaEmploymentService } from "src/app/services/gma-employment.service";
import { UserService } from "src/app/services/user.service";
import { FileStorageService } from "src/app/services/file-storage.service";
import { finalize } from "rxjs/operators";
import { CommonResponse } from "src/app/interfaces/home";

@Component({
  selector: "app-gma-sanctions",
  templateUrl: "./gma-sanctions.component.html",
  styleUrls: ["./gma-sanctions.component.scss"],
  providers: [MessageService],
})
export class GmaSanctionsComponent implements OnInit {
  @ViewChild("formWrapper") public formWrapper: ElementRef;
  cautionForm: FormGroup;
  fetchingSanctions: boolean = true;
  allSanctions: GMASanction[];
  selectedSanctions: GMASanction[];
  cols: any[];
  allOperatives: UnsanctionedDeployedGuardVM[];
  selectedOperatives: UnsanctionedDeployedGuardVM[] = [];
  allSanctionTypes: {
    key: number;
    value: string;
  }[];
  theSanctionType: {
    key: number;
    value: string;
  };
  allSanctionReasons: {
    key: number;
    value: string;
  }[];
  theSanctionReason: {
    key: number;
    value: string;
  };
  allSanctionSeverityLevels: {
    key: number;
    value: string;
  }[];
  theSanctionSeverityLevel: {
    key: number;
    value: string;
  };
  DDOccurred: Date;
  uploadedEvidence: any[] = [];
  userIsYetToClickUplDoc: boolean;
  sancComment: string;
  showPlaceSanction: boolean = false;
  isPlacingSanction: boolean = false;
  evidencesUrl: string[];
  msg: Message[] = [];
  openCautionDialogue: boolean = false;
  cautionText: string;
  cautionCode: number;

  constructor(
    fb: FormBuilder,
    public employmentService: GmaEmploymentService,
    public userService: UserService,
    private breadcrumbService: BreadcrumbService,
    public confirmationService: ConfirmationService,
    public messageService: MessageService,
    private fileStorageService: FileStorageService
  ) {
    this.cautionForm = fb.group({});
  }

  ngOnInit(): void {
    this.breadcrumbService.setItems([
      {
        label: "Guard Management",
        routerLink: ["/home/guard-management/item-settings"],
      },
      {
        label: "Sanctions",
        routerLink: ["/home/guard-management/sanctions"],
      },
    ]);

    this.cols = [
      { field: "imageUrl", header: "ImageUrl" },
      { field: "firstname", header: "Firstname" },
      { field: "lastname", header: "Lastname" },
      { field: "operativeID", header: "OperativeID" },
      { field: "sanctionType", header: "SanctionType" },
      { field: "reason", header: "Reason" },
      { field: "comment", header: "Comment" },
    ];

    this.allSanctionTypes = [
      {
        key: SanctionTypeEnum.Warning,
        value: "Warning",
      },
      {
        key: SanctionTypeEnum.Suspension,
        value: "Suspension",
      },
      {
        key: SanctionTypeEnum.Blacklist,
        value: "Blacklist",
      },
    ];

    this.allSanctionReasons = [
      {
        key: SanctionReasonEnum.Theft,
        value: "Theft",
      },
      {
        key: SanctionReasonEnum.Fighting,
        value: "Fighting",
      },
      {
        key: SanctionReasonEnum.Robbery,
        value: "Robbery",
      },
      {
        key: SanctionReasonEnum.Fraud,
        value: "Fraud",
      },
      {
        key: SanctionReasonEnum.Kidnapping,
        value: "Kidnapping",
      },
      {
        key: SanctionReasonEnum.AwayWithoutLeave,
        value: "Away Without Leave",
      },
      {
        key: SanctionReasonEnum.AbuseOfPriviledges,
        value: "Abuse Of Priviledges",
      },
      {
        key: SanctionReasonEnum.AidingAndAbetting,
        value: "Aiding And Abetting",
      },
      {
        key: SanctionReasonEnum.Collusion,
        value: "Collusion",
      },
      {
        key: SanctionReasonEnum.FalseRepresentation,
        value: "False Representation",
      },
      {
        key: SanctionReasonEnum.Impersonation,
        value: "Impersonation",
      },
      {
        key: SanctionReasonEnum.Truancy,
        value: "Truancy",
      },
      {
        key: SanctionReasonEnum.GivingMisInformationOrDisInformation,
        value: "Giving MisInformation / DisInformation",
      },
    ];

    this.allSanctionSeverityLevels = [
      {
        key: SanctionSeverityLevelEnum.Severity1,
        value: "Severity 1",
      },
      {
        key: SanctionSeverityLevelEnum.Severity2,
        value: "Severity 2",
      },
      {
        key: SanctionSeverityLevelEnum.Severity3,
        value: "Severity 3",
      },
    ];

    this.FetchAllSanctions();
    this.FetchAllUnsanctionedGuards();
  }

  ResetMessageToasters() {
    this.msg = [];
    this.msg.push({
      severity: "info",
      summary: "Notice:",
      detail: "Severity Level Changed...",
    });
  }

  async FetchAllSanctions() {
    this.employmentService.GetAllGMASanctions().subscribe(
      async (data) => {
        this.allSanctions = data;
        this.fetchingSanctions = false;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all sanctions at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
        this.fetchingSanctions = false;
      }
    );
  }

  async FetchAllUnsanctionedGuards() {
    this.employmentService.GettAllUnsanctionOperatives().subscribe(
      async (data) => {
        this.allOperatives = data;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all unsanctioned operatives at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  ReInstateOP(item: GMASanction) {
    this.confirmationService.confirm({
      message: "Are you sure you want to reinstate this operative?",
      accept: () => {
        this.messageService.add({
          severity: "info",
          summary: "Notice",
          detail: "Reinstating Operative...",
        });

        this.employmentService.RemoveGMASanction(item.id).subscribe(
          async () => {
            this.messageService.add({
              severity: "success",
              summary: "Completed",
              detail: "Sanction Removed Successfully!",
            });

            this.fetchingSanctions = true;
            const index = this.allSanctions.indexOf(item);
            if (index > -1) {
              this.allSanctions.splice(index, 1);
            }
            this.fetchingSanctions = false;

            this.showPlaceSanction = false;
            this.selectedOperatives = [];
            this.FetchAllUnsanctionedGuards();
          },
          (error) => {
            console.log("Error: " + JSON.stringify(error));
            this.messageService.add({
              severity: "error",
              summary: "Notice",
              detail:
                "Unable to remove sanction at the moment.. Reason: [" + error
                  ? error.error.message
                  : "request failed - permission" + "]",
            });
          }
        );
      },
    });
  }

  ShowPlaceSanctions() {
    this.showPlaceSanction = true;
  }

  PlaceSanctions() {
    this.cautionCode = 1;
    this.cautionText =
      "You are about to sanction the selected operatives. This is an irreversible action. Do you still wish to proceed?";
    this.openCautionDialogue = true;
  }

  async _placeSanctions() {
    const postData: CreateGMAanctionsVM = {
      deployedGuardIds: this.selectedOperatives.map((x) => x.id),
      sanctionType: this.theSanctionType.key,
      sanctionReason: this.theSanctionReason.key,
      sanctionSeverityLevel: this.theSanctionSeverityLevel.key,
      dateOccurred: this.DDOccurred,
      comment: this.sancComment,
      evidenceUrls: this.evidencesUrl,
    };

    this.employmentService.CreateGMSanctions(postData).subscribe(
      async () => {
        this.messageService.add({
          severity: "success",
          summary: "Completed",
          detail: "Sanction(s) Placed Successfully!",
        });

        if (
          this.theSanctionSeverityLevel.key ==
          SanctionSeverityLevelEnum.Severity3
        ) {
          this.selectedOperatives.forEach((x) => {
            const index = this.allOperatives.indexOf(x);
            if (index > -1) {
              this.allOperatives.splice(index, 1);
            }
          });
        }

        this.selectedOperatives = [];
        this.theSanctionType = null;
        this.theSanctionReason = null;
        this.theSanctionSeverityLevel = null;
        this.DDOccurred = null;
        this.sancComment = null;
        this.evidencesUrl = [];
        this.uploadedEvidence = [];
        this.showPlaceSanction = false;
        this.fetchingSanctions = true;
        this.allSanctionSeverityLevels = [
          {
            key: SanctionSeverityLevelEnum.Severity1,
            value: "Severity 1",
          },
          {
            key: SanctionSeverityLevelEnum.Severity2,
            value: "Severity 2",
          },
          {
            key: SanctionSeverityLevelEnum.Severity3,
            value: "Severity 3",
          },
        ];
        this.FetchAllSanctions();
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail: error
            ? "Unable to place sanctions at the moment.. Reason: [" +
              (error ? error.error.message : "request failed - permission") +
              "]"
            : "Request Failed",
        });
        this.isPlacingSanction = false;
      }
    );
  }

  onCancelDUpload() {
    this.uploadedEvidence = [];
  }

  NotifyUserToClickUpload(identifier: number) {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Kindly ensure you click upload.",
    });

    this.userIsYetToClickUplDoc = true;
  }

  onUploadVetDoc(event) {
    this.uploadedEvidence = [];
    for (const file of event.files) {
      this.uploadedEvidence.push(file);
    }

    this.messageService.add({
      severity: "success",
      summary: "Success",
      detail: "Evidence(s) Uploaded",
    });

    this.userIsYetToClickUplDoc = false;
  }

  GetSanctionTypeLabel(type: number): string {
    if (type == SanctionTypeEnum.Warning) return "Warning";
    if (type == SanctionTypeEnum.Suspension) return "Suspension";
    if (type == SanctionTypeEnum.Blacklist) return "Blacklist";

    return "N/A";
  }

  GetSanctionReasonLabel(reason: number): string {
    if (reason == SanctionReasonEnum.Theft) return "Theft";
    if (reason == SanctionReasonEnum.Fighting) return "Fighting";
    if (reason == SanctionReasonEnum.Robbery) return "Robbery";
    if (reason == SanctionReasonEnum.Fraud) return "Fraud";
    if (reason == SanctionReasonEnum.Kidnapping) return "Kidnapping";
    if (reason == SanctionReasonEnum.AwayWithoutLeave)
      return "Away Without Leave";
    if (reason == SanctionReasonEnum.AbuseOfPriviledges)
      return "Abuse Of Priviledges";
    if (reason == SanctionReasonEnum.AidingAndAbetting)
      return "Aiding And Abetting";
    if (reason == SanctionReasonEnum.Collusion) return "Collusion";
    if (reason == SanctionReasonEnum.FalseRepresentation)
      return "False Representation";
    if (reason == SanctionReasonEnum.Impersonation) return "Impersonation";
    if (reason == SanctionReasonEnum.Truancy) return "Truancy";
    if (reason == SanctionReasonEnum.GivingMisInformationOrDisInformation)
      return "Giving MisInformation Or DisInformation";

    return "N/A";
  }

  GetDeploymentLocation(item: GuardDeployment[]): string {
    let activeDeployment = item.find((x) => x.isDeleted == false);
    if (activeDeployment) return activeDeployment.location.name;
    else return "Pool";
  }

  OnSeverityLevelChange() {
    if (this.theSanctionSeverityLevel) {
      let message: string;
      switch (this.theSanctionSeverityLevel.key) {
        case SanctionSeverityLevelEnum.Severity1:
          message =
            "Operative(s) will be sent a sanctioned text message and it will be recorded.";
          break;
        case SanctionSeverityLevelEnum.Severity2:
          message =
            "Operative(s) will be sent a sanctioned text and moved to the pool (Suspended)";
          break;
        case SanctionSeverityLevelEnum.Severity3:
          message =
            "Operative(s) will be sent a sanctioned text, moved to the pool (Suspended) and deactivated.";
          break;
        default:
          message = "";
          break;
      }

      this.msg = [];
      this.msg.push({
        severity: "info",
        summary: "Notice:",
        detail: message,
      });
    } else this.msg = [];
  }

  HideCautionDialog() {
    this.openCautionDialogue = false;
    this.cautionCode = null;
    this.cautionText = null;
  }

  async CautionAction() {
    if (this.cautionCode == 1) {
      this.messageService.add({
        severity: "info",
        summary: "Notice",
        detail: "Placing Sanctions...",
      });

      this.isPlacingSanction = true;
      this.evidencesUrl = [];
      if (this.uploadedEvidence.length > 0) {
        this.fileStorageService.UploadMultipleFilesFromDataUrl(
          this.uploadedEvidence
        );
        this.fileStorageService.onUploadFinished.subscribe(
          async (resp: CommonResponse<string[]>) => {
            if (resp.responseCode != "00") {
              this.messageService.add({
                severity: "error",
                summary: "Notice",
                detail: resp.responseMsg,
              });
            } else {
              this.evidencesUrl = resp.responseData;
              await this._placeSanctions();
            }
          },
          (error) => {
            console.log("Error while uploading files " + error);
            this.messageService.add({
              severity: "error",
              summary: "Notice",
              detail: "ERR: Unable to upload image to storage",
            });
          }
        );
      } else {
        this._placeSanctions();
      }

      this.HideCautionDialog();
    }
  }

  OnSeverityTypeChange() {
    if (this.theSanctionType) {
      if (this.theSanctionType.key == SanctionTypeEnum.Blacklist) {
        this.allSanctionSeverityLevels = [
          {
            key: SanctionSeverityLevelEnum.Severity3,
            value: "Severity 3",
          },
        ];
        this.theSanctionSeverityLevel = this.allSanctionSeverityLevels[0];
      } else if (this.theSanctionType.key == SanctionTypeEnum.Suspension) {
        this.allSanctionSeverityLevels = [
          {
            key: SanctionSeverityLevelEnum.Severity2,
            value: "Severity 2",
          },
          {
            key: SanctionSeverityLevelEnum.Severity3,
            value: "Severity 3",
          },
        ];
        this.theSanctionSeverityLevel = null;
      } else {
        this.allSanctionSeverityLevels = [
          {
            key: SanctionSeverityLevelEnum.Severity1,
            value: "Severity 1",
          },
          {
            key: SanctionSeverityLevelEnum.Severity2,
            value: "Severity 2",
          },
          {
            key: SanctionSeverityLevelEnum.Severity3,
            value: "Severity 3",
          },
        ];
        this.theSanctionSeverityLevel = null;
      }
    }
  }
}
