import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../environments/environment";
import { Observable } from "rxjs";
import {
  CommonResponse,
  CreateSBUAttachmentVM,
  SbuAttachment,
} from "../interfaces/home";

@Injectable({
  providedIn: "root",
})
export class StrategicBusinessUnitService {
  constructor(private http: HttpClient) {}
  baseUrl = environment.baseUrl + "StrategicBusinessUnit/";

  allStrategicBusinessUnit(): Observable<CommonResponse> {
    return this.http.get<CommonResponse>(this.baseUrl);
  }

  allRMSbus(): Observable<CommonResponse> {
    return this.http.get<CommonResponse>(this.baseUrl + "GetRMSbus");
  }

  allRMSbusWithClientInfo() {
    return this.http.get(this.baseUrl + "GetRMSbusWithClientsInfo");
  }

  getStrategicBusinessUnit(id: any): Observable<CommonResponse> {
    return this.http.get<CommonResponse>(this.baseUrl + id);
  }

  getStrategicBusinessUnitByName(name: any): Observable<CommonResponse> {
    return this.http.get<CommonResponse>(this.baseUrl + "name/" + name);
  }

  postStrategicBusinessUnit(data: any) {
    return this.http.post(this.baseUrl, data);
  }

  deleteStrategicBusinessUnit(id: any) {
    return this.http.delete(this.baseUrl + id);
  }

  updateStrategicBusinessUnit(id: any, data: any) {
    return this.http.put(this.baseUrl + id, data);
  }

  GetAllSBUAttachments(): Observable<CommonResponse<SbuAttachment[]>> {
    return this.http.get<CommonResponse<SbuAttachment[]>>(
      this.baseUrl + "GetAllSBUAttachments"
    );
  }

  CreateSBUAttachment(data: CreateSBUAttachmentVM): Observable<CommonResponse> {
    return this.http.post<CommonResponse>(
      this.baseUrl + "CreateSBUAttachment",
      data
    );
  }

  DetachSBUAttachment(sbuAttachmentId: number): Observable<CommonResponse> {
    return this.http.delete<CommonResponse>(
      this.baseUrl + "DetachSBUAttachment/" + sbuAttachmentId
    );
  }

  GetAllAttachmentsBySBUID(
    sbuId: number
  ): Observable<CommonResponse<SbuAttachment[]>> {
    return this.http.get<CommonResponse<SbuAttachment[]>>(
      this.baseUrl + "GetAllAttachmentsBySBUID/" + sbuId
    );
  }

  DetachClientFromSBU(
    clientId: number
  ): Observable<CommonResponse<SbuAttachment[]>> {
    return this.http.get<CommonResponse<SbuAttachment[]>>(
      this.baseUrl + "DetachClientFromSBU/" + clientId
    );
  }
}
