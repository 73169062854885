import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {LeadAdminComponent} from './lead-admin.component';
import {LeadCaptureComponent} from './lead-capture/lead-capture.component';
import {LeadNegotiationComponent} from './lead-negotiation/lead-negotiation.component';
import {LeadQualificationComponent} from './lead-qualification/lead-qualification.component';
import {ConversionComponent} from './conversion/conversion.component';

const routes: Routes = [
  {
    path:'',
    component: LeadAdminComponent,
    children: [
      {
        path: '',
        redirectTo: 'capture',
        pathMatch: 'full'
      },
      {
        path: 'capture',
        component: LeadCaptureComponent,
      },
      {
        path: 'capture/:referenceNo',
        component: LeadCaptureComponent,
      },
      {
        path: 'negotiation/:referenceNo',
        component: LeadNegotiationComponent,
      },
      {
        path: 'qualification/:referenceNo',
        component: LeadQualificationComponent,
      },
      {
        path: 'conversion/:referenceNo',
        component: ConversionComponent,
      },
      {
        path: '**',
        redirectTo: 'capture',
        pathMatch: 'full'
      }
    ]
  },
  {
    path: '',
    redirectTo: 'lead-admin/capture',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class LeadAdminRoutingModule { }
