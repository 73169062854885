import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { ConfirmationService, MessageService } from 'primeng/api';
import { InputNumberModule } from 'primeng/inputnumber';
import { BreadcrumbService } from 'src/app/breadcrumb.service';
import { CommonResponse, OperatingEntity, SBUProportion } from 'src/app/interfaces/home';
import { OperatingEntityService } from 'src/app/services/operating-entity.service';
import { SbuProportionService } from 'src/app/services/sbu-proportion.service';

@Component({
  selector: 'app-corporate-adjustment-setup',
  templateUrl: './corporate-adjustment-setup.component.html',
  styleUrls: ['./corporate-adjustment-setup.component.scss'],
  providers: [MessageService, ConfirmationService, InputNumberModule]
})
export class CorporateAdjustmentSetupComponent implements OnInit {


  @ViewChild('formWrapper') public formWrapper:ElementRef;
  operatingEntities: OperatingEntity[];
  fetchingOperatingEntity: boolean;
  operatingEntityCols: any;
  selectedOperatingEntity: OperatingEntity;
  sbuProportionForm;
  editingSbuProportionForm = false;
  savingSbuProportionForm = false;
  validationError = false;
  leadClosure = 0;
  leadGeneration = 0;

  


  constructor(private operatingEntityService: OperatingEntityService,
              private sbuProportionService: SbuProportionService,
              private formBuilder: FormBuilder,
              public messageService: MessageService,
              public confirmationService: ConfirmationService,     
              private breadcrumbService: BreadcrumbService 
    ) { }

  ngOnInit(): void {

    this.breadcrumbService.setItems([
      { label: 'Setup', routerLink: ['home/corporate-adjustment-setup'] },
      { label: 'Corporate-Adjustment', routerLink: ['home/corporate-adjustment-setup'] }
  ]);

   this.fetchOperatingEntityWithSBUProportion();
   this.selectedOperatingEntity = null;

   this.sbuProportionForm = this.formBuilder.group({
    operatingEntity: ['', Validators.required],
    leadGenerationProportion: ['',Validators.required],
    leadClosureProportion: ['', [Validators.required]],
    });

   this.operatingEntityCols = [
    { field: 'name', header: 'OperatingEntity' },
    { field: 'leadGenerationProportion', header: 'Lead Generation %' },
    { field: 'leadClosureProportion', header: 'Lead Closure %' },
    { field: 'total', header: 'Total' },
  ];
  }

  fetchOperatingEntityWithSBUProportion(){
    this.fetchingOperatingEntity = true;
    this.operatingEntityService.getOperatingEntityAndSBUProportion().subscribe(
      async (res: CommonResponse) => {
        this.operatingEntities = res.responseData;
        console.log(res);
      },
      error => {
        this.connectionError();
      }
    )
    this.fetchingOperatingEntity = false;
  }



  saveSbuProportion(operatingEntity: OperatingEntity){
    this.resetForm();
    this.savingSbuProportionForm = true;
    this.selectedOperatingEntity = operatingEntity;
    this.sbuProportionForm.patchValue({
      operatingEntity: operatingEntity.name,
    });
    this.formWrapper.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'start' });
  }


  postSbuProportion(){
    let sbuToPost = {
      leadClosureProportion : this.sbuProportionForm.get('leadClosureProportion').value,
      leadGenerationProportion: this.sbuProportionForm.get('leadGenerationProportion').value,
      operatingEntityId: this.selectedOperatingEntity.id,
    }
    this.messageService.add({
      severity:'info', summary: 'Notice',
      detail:'Creating Corporate Adjustment'
    })

    this.sbuProportionService.post(sbuToPost).subscribe(
      async (res: CommonResponse) => {
        this.setSbuProportions(res.responseData);
        this.resetForm();
        this.messageService.add({
          severity:'success', summary: 'Corporate Adjustment Created',
          detail:'Corporate Adjustment created successfully'
        })
      },
      error => {
        this.messageService.add({severity:'error', summary: 'Failed',
        detail: error.error.message});
      }
    )
  }


  editSbuProportion(operatingEntity: OperatingEntity) {
    this.resetForm();
    this.editingSbuProportionForm = true;
    this.selectedOperatingEntity = operatingEntity;
    

    this.sbuProportionForm.patchValue({
      operatingEntity: operatingEntity.name,
    });
    this.leadClosure = operatingEntity.sbuProportion.leadClosureProportion;
    this.leadGeneration = operatingEntity.sbuProportion.leadGenerationProportion;
    this.formWrapper.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'start' });
  }


  updateSbuProportion() {
    this.messageService.add({severity:'info', summary: 'Notice',
      detail:'Updating Corporate Adjustment details'});
    
    let sbuProportionId = this.selectedOperatingEntity.sbuProportion.id;
    const updateData = {
      leadClosureProportion : this.sbuProportionForm.get('leadClosureProportion').value,
      leadGenerationProportion: this.sbuProportionForm.get('leadGenerationProportion').value,
      operatingEntityId: this.selectedOperatingEntity.id
    };
    this.sbuProportionService.updateSBUProportion(sbuProportionId, updateData).subscribe( async (result: CommonResponse) => {
      this.messageService.add({severity:'success', summary: 'Notice',
        detail:'Adjustment details Updated'});
      this.setSbuProportions(result.responseData)
      this.resetForm();
    }, error => {
      this.connectionError();
    })
  }

  resetForm() {
    this.editingSbuProportionForm = false;
    this.savingSbuProportionForm = false;
    this.sbuProportionForm.reset();
    this.selectedOperatingEntity = null;
  }


  setSbuProportions(sbuProportion: SBUProportion){
    console.log(sbuProportion);
    this.fetchingOperatingEntity = true;
    
    this.operatingEntities.forEach(opEntity => {
      if(opEntity.id === sbuProportion.operatingEntityId){
        opEntity.sbuProportion = sbuProportion;
      }
    })

    this.fetchingOperatingEntity = false;
  }


  private connectionError() {
    this.messageService.add({severity:'error', summary: 'Failed',
      detail:'Connection Error, Please try again'});
  }

  handleChange(){    
    if(this.leadGeneration > 100 || (this.leadClosure + this.leadGeneration) > 100){
        this.validationError = true;
        this.leadClosure = 0;
    }else{
      this.validationError = false;
      this.leadClosure = 100 - this.leadGeneration
    }
    
  }



}
