import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../environments/environment";
import { Observable } from "rxjs";
import { CommonResponse } from "../interfaces/home";

@Injectable({
  providedIn: "root",
})
export class TaskFulfillmentService {
  constructor(private http: HttpClient) {}
  baseUrl = environment.baseUrl + "TaskFulfillment/";
  // fetch all TaskFulfillment in the database
  allTaskFulfillment(): Observable<CommonResponse> {
    return this.http.get<CommonResponse>(this.baseUrl);
  }
  getTaskFulfillment(id: any): Observable<CommonResponse> {
    return this.http.get<CommonResponse>(this.baseUrl + id);
  }
  postTaskFulfillment(data: any): Observable<any> {
    return this.http.post(this.baseUrl, data);
  }
  setIsPicked(id) {
    return this.http.put(this.baseUrl + "SetIsPicked/" + id, "");
  }
  dropPicked(id) {
    return this.http.put(this.baseUrl + "DropPicked/" + id, "");
  }
  deleteTaskFulfillment(id: any) {
    return this.http.delete(this.baseUrl + id);
  }
  updateTaskFulfillment(id: any, data: any): Observable<any> {
    return this.http.put(this.baseUrl + id, data);
  }
  getUncompleteTaskByOwner(id) {
    return this.http.get(
      this.baseUrl + "UnCompletedTaskFulfillmentForTaskOwner/" + id
    );
  }
  getAllTaskByOwner(id) {
    return this.http.get(this.baseUrl + "AllTaskFulfillmentForTaskOwner/" + id);
  }
  getTaskOwnerPmWidget(id): Observable<any> {
    return this.http.get(this.baseUrl + "TaskOwnerPmWidget/" + id);
  }
  getTaskDeliverableSummary(id) {
    return this.http.get(this.baseUrl + "TaskDeliverableSummary/" + id);
  }
}
