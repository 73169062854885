import { Component, OnInit } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { MessageService } from "primeng/api";
import { FireBaseAuthService } from "src/app/services/fire-base-auth.service";
import { UserService } from "src/app/services/user.service";

@Component({
  selector: "app-halobiz-login",
  templateUrl: "./halobiz-login.component.html",
  styleUrls: ["./halobiz-login.component.scss"],
})
export class HalobizLoginComponent implements OnInit {
  loginForm: FormGroup;
  dark: true;
  checked: boolean;

  constructor(
    private userService: UserService,
    private messageService: MessageService,
    private router: Router,
    private formBuilder: FormBuilder,
    private fireBaseAuthService: FireBaseAuthService
  ) {}

  ngOnInit(): void {
    this.loginForm = this.formBuilder.group({
      email: ["", [Validators.required, Validators.email]],
      password: [""],
      remember: [null],
    });
  }

  async loginWithGoogle() {
    await this.fireBaseAuthService.loginWithGoogle();
  }

  async login() {
    const email = this.loginForm.value.email;
    const password = this.loginForm.value.password;
    this.userService.authenticateOtherUser(email, password).subscribe(
      async (res) => {
        if (res.responseCode == "00") {
          var data = res.responseData;
          this.fireBaseAuthService.grantSessionBasicSiginin(data);
        } else {
          this.messageService.add({
            severity: "error",
            summary: "Login failure",
            detail: res.responseMsg,
          });
        }
      },
      (error) => {
        this.messageService.add({
          severity: "error",
          summary: "Authentication Error",
          detail: "Invalid email or password",
        });
      }
    );
  }
}
