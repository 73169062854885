import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";

@Injectable()
export class EngagementContactsService {
  constructor(public http: HttpClient) {}

  getCustomerDivisionList() {
    return this.http.get(environment.baseUrl + "Division");
  }

  getCustomerDesignationList() {
    return this.http.get(environment.baseUrl + "Designation");
  }

  createContact(body: any) {
    return this.http.post(
      environment.baseUrl + "contact/CreateNewContact",
      body
    );
  }

  attachContactToSuspect(body: any) {
    return this.http.post(
      environment.baseUrl + "contact/AttachContactToSuspect",
      body
    );
  }

  getContactList() {
    return this.http.get(environment.baseUrl + "contact/GetAllContacts");
  }

  disableContact(contactId: number) {
    return this.http.delete(
      environment.baseUrl + "contact/DisableContact/" + contactId
    );
  }

  updateContacts(contactId: number, body) {
    return this.http.put(
      environment.baseUrl + "contact/UpdateContact/" + contactId,
      body
    );
  }

  getAllContactsAttachedToSuspects(suspectId: number) {
    return this.http.get(
      environment.baseUrl + "contact/GetContactsAttachedToSuspects/" + suspectId
    );
  }

  getAllContactsAttachedToCustomer(customerId: number) {
    return this.http.get(
      environment.baseUrl +
        "contact/GetContactsAttachedToCustomer/" +
        customerId
    );
  }

  detachContact(suspectId: number, contactId: number) {
    var body;
    return this.http.put(
      environment.baseUrl +
        "contact/DetatchContact/" +
        suspectId +
        "/" +
        contactId,
      body
    );
  }
}
