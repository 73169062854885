import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../environments/environment";
import { Observable } from "rxjs";
import {
  AdvancePayment,
  BulkUploadVM,
  CommonResponse,
  PostMultipleRawInflowItemVM,
  PostRawInflowItemVM,
  ProcessBulkSettlementVM,
  RawInflowItem,
  SubmitRawInflowItemVM,
  UnUsedAdvancePaymentView,
  UploadRawInflowItemsVM,
} from "../interfaces/home";

@Injectable({
  providedIn: "root",
})
export class AdvancePaymentsService {
  constructor(private http: HttpClient) {}
  baseUrl = environment.baseUrl + "AdvancePayment/";

  getAdvancePayment(
    customerDivisionId: any
  ): Observable<CommonResponse<AdvancePayment[]>> {
    return this.http.get<CommonResponse<AdvancePayment[]>>(
      this.baseUrl + "getByCustomerDivisionId/" + customerDivisionId
    );
  }

  post(data: any) {
    return this.http.post(this.baseUrl, data);
  }

  delete(id: any) {
    return this.http.delete(this.baseUrl + id);
  }

  update(id: any, data: any) {
    return this.http.put(this.baseUrl + id, data);
  }

  Sublet(data: any) {
    return this.http.post(this.baseUrl + "Sublet", data);
  }

  BulkUpload(data: BulkUploadVM): Observable<CommonResponse> {
    return this.http.post<CommonResponse>(this.baseUrl + "BulkUpload", data);
  }

  ReversePosting(accountMasterId): Observable<CommonResponse> {
    return this.http.get<CommonResponse>(
      this.baseUrl + "ReversePosting/" + accountMasterId
    );
  }

  BulkUploadV2(data: BulkUploadVM): Observable<CommonResponse> {
    return this.http.post<CommonResponse>(this.baseUrl + "BulkUploadV2", data);
  }

  GetAllUnusedAdvancePayments(
    skip: number,
    take: number
  ): Observable<CommonResponse<UnUsedAdvancePaymentView[]>> {
    return this.http.get<CommonResponse<UnUsedAdvancePaymentView[]>>(
      this.baseUrl + "GetAllUnusedAdvancePayments/" + skip + "/" + take
    );
  }

  ProcessBulkSettlement(
    data: ProcessBulkSettlementVM
  ): Observable<CommonResponse> {
    return this.http.post<CommonResponse>(
      this.baseUrl + "ProcessBulkSettlement",
      data
    );
  }

  UploadRawInflowItems(
    data: UploadRawInflowItemsVM
  ): Observable<CommonResponse> {
    return this.http.post<CommonResponse>(
      this.baseUrl + "UploadRawInflowItems",
      data
    );
  }

  GetUnsubmittedRawInflows(): Observable<CommonResponse<RawInflowItem[]>> {
    return this.http.get<CommonResponse<RawInflowItem[]>>(
      this.baseUrl + "GetUnsubmittedRawInflows"
    );
  }

  SubmitRawInflowItem(data: SubmitRawInflowItemVM): Observable<CommonResponse> {
    return this.http.post<CommonResponse>(
      this.baseUrl + "SubmitRawInflowItem",
      data
    );
  }

  GetUnpostedRawInflows(): Observable<CommonResponse<RawInflowItem[]>> {
    return this.http.get<CommonResponse<RawInflowItem[]>>(
      this.baseUrl + "GetUnpostedRawInflows"
    );
  }

  PostRawInflowItem(
    data: PostRawInflowItemVM
  ): Observable<CommonResponse<RawInflowItem[]>> {
    return this.http.post<CommonResponse<RawInflowItem[]>>(
      this.baseUrl + "PostRawInflowItem",
      data
    );
  }

  GetRawInflows(): Observable<CommonResponse<RawInflowItem[]>> {
    return this.http.get<CommonResponse<RawInflowItem[]>>(
      this.baseUrl + "GetRawInflows"
    );
  }

  PostMultipleRawInflowItem(
    data: PostMultipleRawInflowItemVM
  ): Observable<CommonResponse> {
    return this.http.post<CommonResponse>(
      this.baseUrl + "PostMultipleRawInflowItem",
      data
    );
  }
}
