import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../environments/environment";
import { Observable } from "rxjs";
import { CommonResponse, ServiceCategory } from "../interfaces/home";

@Injectable({
  providedIn: "root",
})
export class ServiceCategoryService {
  constructor(private http: HttpClient) {}
  baseUrl = environment.baseUrl + "ServiceCategory/";

  allServiceCategory(): Observable<CommonResponse> {
    return this.http.get<CommonResponse>(this.baseUrl);
  }

  getCategory(id: any): Observable<CommonResponse> {
    return this.http.get<CommonResponse>(this.baseUrl + id);
  }

  getServiceCategoryByName(name: any): Observable<CommonResponse> {
    return this.http.get<CommonResponse>(this.baseUrl + "name/" + name);
  }

  postServiceCategory(data: any) {
    return this.http.post(this.baseUrl, data);
  }

  deleteServiceCategory(id: any) {
    return this.http.delete(this.baseUrl + id);
  }

  updateServiceCategory(id: any, data: any) {
    return this.http.put(this.baseUrl + id, data);
  }

  GetAllServiceCategories(): Observable<CommonResponse<ServiceCategory[]>> {
    return this.http.get<CommonResponse<ServiceCategory[]>>(
      this.baseUrl + "GetAllServiceCategories"
    );
  }

  GetAllServiceCategoriesWithRelationship(): Observable<
    CommonResponse<ServiceCategory[]>
  > {
    return this.http.get<CommonResponse<ServiceCategory[]>>(
      this.baseUrl + "GetAllServiceCategoriesWithRelationship"
    );
  }
}
