import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CommonResponse } from '../interfaces/home';
import { Model } from '../interfaces/model';

@Injectable({
  providedIn: 'root'
})
export class ModelService {

  baseUrl = environment.baseUrl + "Model/";

  constructor(private http: HttpClient) { }

  getByMake(makeid: number): Observable<CommonResponse> {
    return this.http.get<CommonResponse>(`${this.baseUrl}make/${makeid}`);
  }

  getByID(id: number): Observable<CommonResponse> {
    return this.http.get<CommonResponse>(this.baseUrl + id);
  }

  getAll(): Observable<CommonResponse> {
    return this.http.get<CommonResponse>(this.baseUrl);
  }

  create(data: Model): Observable<CommonResponse> {
    return this.http.post<CommonResponse>(this.baseUrl, data);
  }

  update(data: Model, id: number): Observable<CommonResponse> {
    return this.http.put<CommonResponse>(this.baseUrl + id, data);
  }

  delete(id: number) {
    return this.http.delete(this.baseUrl + id);
  }
}
