import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { errorMonitor } from "events";
import { ConfirmationService, MessageService } from "primeng/api";
import { DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";
import { CommonResponse, InvoiceMailDTO } from "src/app/interfaces/home";
import { InvoiceService } from "src/app/services/invoice.sevice";
import { UtilService } from "src/app/services/util.service";
import domtoimage from "dom-to-image";
import { jsPDF } from "jspdf";
import { AccountMasterService } from "src/app/services/account-master";

@Component({
  selector: "app-view-receipt",
  templateUrl: "./view-receipt.component.html",
  styleUrls: ["./view-receipt.component.scss"],
})
export class ViewReceiptComponent implements OnInit {
  noteDetails: any;
  noteMaster: any;
  fetchingInvoiceDetails: boolean = false;
  isGroupedAdhoc = false;
  amountInWords: string = "";
  showTableRowThree: boolean = false;
  VAT;
  subTotal: number = 0;

  constructor(
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private invoiceService: InvoiceService,
    public modalConfig: DynamicDialogConfig,
    private viewInvoiceModalRef: DynamicDialogRef,
    public utilService: UtilService
  ) {}

  ngOnInit(): void {
    this.fetchingInvoiceDetails = true;
    this.noteMaster = this.modalConfig.data.receipt;
    this.fetchingInvoiceDetails = false;
    this.showTableRowThree = true;
    this.putAmountInWords();
  }

  private putAmountInWords() {
    this.subTotal = this.noteMaster.reduce((a, b) => {
      return a + b.receiptValue;
    }, 0);

    let koboPart = 0;
    //let amount = this.noteMaster.receiptValue.toFixed(2);
    let doubleAmt = this.subTotal.toString();
    var splitAmt = doubleAmt.split(".");
    var nairaPart = parseInt(splitAmt[0]);
    this.amountInWords = this.utilService.NumberToWords(nairaPart) + " Naira";
    if (splitAmt.length > 1) {
      koboPart = parseInt(splitAmt[1]);
      if (koboPart > 0) {
        this.amountInWords +=
          " " + this.utilService.NumberToWords(koboPart) + " Kobo";
      }
    }
  }

  printPage() {
    window.print();
  }

  pdfDownloadPage() {
    var node = document.getElementById("parentdiv");
    var img;
    var filename;
    var newImage;
    domtoimage
      .toPng(node, { bgcolor: "#fff" })
      .then(function (dataUrl) {
        img = new Image();
        img.src = dataUrl;
        newImage = img.src;
        img.onload = function () {
          var pdfWidth = img.width;
          var pdfHeight = img.height;
          // FileSaver.saveAs(dataUrl, 'my-pdfimage.png'); // Save as Image
          var doc;
          if (pdfWidth > pdfHeight) {
            doc = new jsPDF("l", "px", [pdfWidth, pdfHeight]);
          } else {
            doc = new jsPDF("p", "px", [pdfWidth, pdfHeight]);
          }
          var width = doc.internal.pageSize.getWidth();
          var height = doc.internal.pageSize.getHeight();

          doc.addImage(newImage, "PNG", 10, 10, width, height);
          filename = "invoice" + ".pdf";
          doc.save(filename);
        };
      })
      .catch(function (error) {
        // Error Handling
      });
  }

  sendInvoice(invoiceId: number) {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Sending Invoice",
    });

    this.invoiceService.sendInvoice(invoiceId).subscribe(
      async (res: CommonResponse) => {
        var response = res.responseData as boolean;
        if (response)
          this.messageService.add({
            severity: "success",
            summary: "Successful",
            detail: "Invoice Sent",
          });
      },
      (error) => {
        this.messageService.add({
          severity: "errorr",
          summary: "Failed",
          detail: error.error.message,
        });
      }
    );
  }

  confirmSendEmail(invoiceId: number) {
    this.viewInvoiceModalRef.close();
    this.confirmationService.confirm({
      message: "Are you sure you want to send this invoice ?",
      header: "Confirmation",
      icon: "pi pi-exclamation-triangle",
      accept: () => {
        this.sendInvoice(invoiceId);
      },
      reject: (type) => {
        this.showTableRowThree = false;

        this.messageService.add({
          severity: "error",
          summary: "Rejected",
          detail: "Declined Sending Invoice",
        });
      },
    });
  }
}
