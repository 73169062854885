import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import {
  PassengerType,
  ReleaseType,
  SourceType,
  TripType,
} from "src/app/interfaces/armada";
import { CommonResponse } from "src/app/interfaces/home";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class TypesForServiceAssignmentService {
  baseUrl = environment.baseUrl + "TypesForServiceAssignment/";
  //baseUrl = "https://localhost:44344/api/v1/TypesForServiceAssignment/";
  constructor(private httpClient: HttpClient) {}

  //PassengerTypes
  allPassengerTypes(): Observable<CommonResponse> {
    return this.httpClient.get<CommonResponse>(
      this.baseUrl + "GetAllPassengerTypes"
    );
  }
  getPassengerType(id: any): Observable<CommonResponse> {
    return this.httpClient.get<CommonResponse>(
      this.baseUrl + "GetPassengerTypeById/" + id
    );
  }
  postPassengerType(data: any) {
    return this.httpClient.post(this.baseUrl + "AddNewPassengerType", data);
  }
  deletePassengerType(id: any) {
    return this.httpClient.delete(
      this.baseUrl + "DeletePassengerTypeById/" + id
    );
  }
  updatePassengerType(id: any, data: any) {
    return this.httpClient.put(
      this.baseUrl + "UpdatePassengerTypeById/" + id,
      data
    );
  }

  //ReleaseTypes
  allReleaseTypes(): Observable<CommonResponse> {
    return this.httpClient.get<CommonResponse>(
      this.baseUrl + "GetAllReleaseTypes"
    );
  }
  getReleaseType(id: any): Observable<CommonResponse> {
    return this.httpClient.get<CommonResponse>(
      this.baseUrl + "GetReleaseTypeById/" + id
    );
  }
  postReleaseType(data: any) {
    return this.httpClient.post(this.baseUrl + "AddNewReleaseType", data);
  }
  deleteReleaseType(id: any) {
    return this.httpClient.delete(this.baseUrl + "DeleteReleaseTypeById/" + id);
  }
  updateReleaseType(id: any, data: any) {
    return this.httpClient.put(
      this.baseUrl + "UpdateReleaseTypeById/" + id,
      data
    );
  }

  //SourceTypes
  allSourceTypes(): Observable<CommonResponse> {
    return this.httpClient.get<CommonResponse>(
      this.baseUrl + "GetAllSourceTypes"
    );
  }
  getSourceType(id: any): Observable<CommonResponse> {
    return this.httpClient.get<CommonResponse>(
      this.baseUrl + "GetSourceTypeById/" + id
    );
  }
  postSourceType(data: any) {
    return this.httpClient.post(this.baseUrl + "AddNewSourceType", data);
  }
  deleteSourceType(id: any) {
    return this.httpClient.delete(this.baseUrl + "DeleteSourceTypeById/" + id);
  }
  updateSourceType(id: any, data: any) {
    return this.httpClient.put(
      this.baseUrl + "UpdateSourceTypeById/" + id,
      data
    );
  }

  //TripTypes
  allTripTypes(): Observable<CommonResponse> {
    return this.httpClient.get<CommonResponse>(
      this.baseUrl + "GetAllTripTypes"
    );
  }
  getTripType(id: any): Observable<CommonResponse> {
    return this.httpClient.get<CommonResponse>(
      this.baseUrl + "GetTripTypeById/" + id
    );
  }
  postTripType(data: any) {
    return this.httpClient.post(this.baseUrl + "AddNewTripType", data);
  }
  deleteTripType(id: any) {
    return this.httpClient.delete(this.baseUrl + "DeleteTripTypeById/" + id);
  }
  updateTripType(id: any, data: any) {
    return this.httpClient.put(this.baseUrl + "UpdateTripTypeById/" + id, data);
  }
}
