import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { AngularFireStorage } from "@angular/fire/storage";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { Router } from "@angular/router";
import { EventInput } from "@fullcalendar/core";
import { ConfirmationService, MessageService } from "primeng/api";
import { forkJoin, Observable } from "rxjs";
import { finalize } from "rxjs/operators";
import { BreadcrumbService } from "src/app/breadcrumb.service";
import {
  Branch,
  CommonResponse,
  GroupType,
  Industry,
  LeadOrigin,
  LeadType,
  Office,
  Services,
  State,
  Suspect,
  SuspectReceivingDTO,
} from "src/app/interfaces/home";
import { BranchService } from "src/app/services/branch.service";
import { FileStorageService } from "src/app/services/file-storage.service";
import { GroupTypeService } from "src/app/services/group-type.service";
import { IndustryService } from "src/app/services/industry.service";
import { LeadOriginService } from "src/app/services/lead-origin.service";
import { LeadTypeService } from "src/app/services/lead-type.service";
import { OfficeService } from "src/app/services/office.service";
import { StateService } from "src/app/services/state.service";
import { SuspectService } from "src/app/services/suspect.service";

@Component({
  selector: "app-suspect-capture",
  templateUrl: "./suspect-capture.component.html",
  styleUrls: ["./suspect-capture.component.scss"],
})
export class SuspectCaptureComponent implements OnInit {
  startUpData$: Observable<CommonResponse[]>;

  branches: Branch[];
  leadOrigins: LeadOrigin[];
  selectedLeadOrigin: LeadOrigin;
  leadTypes: LeadType[];
  selectedLeadType: LeadType;
  states: State[];
  suspects: Suspect[];

  suspectForm: FormGroup;
  suspectIndividualForm: FormGroup;
  suspectCooperateForm: FormGroup;
  suspectGovernmentForm: FormGroup;
  suspectSMEForm: FormGroup;

  selectedSuspect: any;
  suspectCols: any;

  fetchingStartUpData = false;
  industries: Industry[];

  imageSrc: string;
  uploadingNewPicture: boolean;
  file: any[] = [];
  fetchingSuspects: boolean;
  groupTypes: GroupType[];
  selectedGroupType: GroupType;

  offices: any;
  lgas: any;

  selectedOffice: any;
  selectedState: any;
  selectedLga: any;
  selectedBranch: any;
  selectedIndustry: any;

  street: string;

  downloadURL: Observable<string>;
  validatingItemName: string;
  validating: boolean = false;
  isInternalClientType: boolean = false;
  IsCreatingAsContact: boolean = true;

  constructor(
    private formBuilder: FormBuilder,
    private suspectService: SuspectService,
    private leadOriginService: LeadOriginService,
    private industryService: IndustryService,
    private leadTypeService: LeadTypeService,
    private groupTypeService: GroupTypeService,
    private branchService: BranchService,
    private officeService: OfficeService,
    private stateService: StateService,
    private router: Router,
    public fireStorage: AngularFireStorage,
    public messageService: MessageService,
    public confirmationService: ConfirmationService,
    private breadcrumbService: BreadcrumbService,
    private fileStorageService: FileStorageService
  ) {}

  ngOnInit(): void {
    this.fetchStartupData();
    this.fetchSuspects();

    this.breadcrumbService.setItems([
      { label: "Prospects", routerLink: ["home/suspect-capture"] },
      { label: "Suspects Capture", routerLink: ["home/suspect-capture"] },
    ]);

    this.suspectForm = this.formBuilder.group({
      imageUrl: ["", [Validators.required]],
      street: ["", [Validators.required]],
      address: [""],
      gtype: [null, [Validators.required]],
      ltype: [null, [Validators.required]],
      origin: [null, [Validators.required]],
      branch: [null, [Validators.required]],
      office: [null, [Validators.required]],
      state: [null, [Validators.required]],
      lga: [null, [Validators.required]],
    });

    this.suspectIndividualForm = this.formBuilder.group({
      firstName: ["", [Validators.required]],
      lastName: ["", [Validators.required]],
      otherName: ["", [Validators.required]],
      email: ["", [Validators.required]],
      phoneNumber: ["", [Validators.required]],
      industry: [null, [Validators.required]],
      CreateAsContact: [null, [Validators.required]],
    });

    this.suspectCooperateForm = this.formBuilder.group({
      companyName: ["", [Validators.required]],
      rcNumber: ["", [Validators.required]],
      industry: [null, [Validators.required]],
      email: ["", [Validators.required]],
      phoneNumber: ["", [Validators.required]],
    });

    this.suspectGovernmentForm = this.formBuilder.group({
      companyName: ["", [Validators.required]],
      tier: ["", [Validators.required]],
      email: ["", [Validators.required]],
      phoneNumber: ["", [Validators.required]],
    });

    this.suspectSMEForm = this.formBuilder.group({
      businessName: ["", [Validators.required]],
      industry: [null, [Validators.required]],
      email: ["", [Validators.required]],
      phoneNumber: ["", [Validators.required]],
    });
  }

  fetchStartupData() {
    this.fetchingStartUpData = true;
    this.startUpData$ = forkJoin([
      this.branchService.allBranch(),
      this.leadOriginService.allLeadOrigin(),
      this.leadTypeService.allLeadTypeData(),
      this.officeService.allOffice(),
      this.industryService.allIndustry(),
      this.groupTypeService.allGroupTypeData(),
      this.stateService.allState(),
    ]);

    this.startUpData$.subscribe(
      async (data) => {
        const [
          branches,
          leadOrigins,
          leadTypes,
          offices,
          industries,
          groupTypes,
          states,
        ] = data;
        this.branches = branches.responseData;
        this.leadOrigins = leadOrigins.responseData;
        this.leadTypes = leadTypes.responseData;
        this.offices = offices.responseData;
        this.industries = industries.responseData;
        this.groupTypes = groupTypes.responseData;
        this.states = states.responseData;
        this.fetchingStartUpData = false;
      },
      (error) => {
        this.fetchingStartUpData = false;
        this.connectionError();
      }
    );
  }

  setLga() {
    if (this.selectedState) {
      this.lgas = this.selectedState.lgAs;
    }
  }

  setOffices() {
    if (this.selectedBranch) {
      this.offices = this.selectedBranch.offices;
    }
  }

  groupTypeChanged() {
    console.log(this.selectedGroupType);
  }

  change(e) {
    this.isInternalClientType = e.checked;
  }

  suspectFormDisabled() {
    if (!this.selectedGroupType) return true;
    const val = this.selectedGroupType.caption;
    if (val == "Individual")
      return this.suspectForm.invalid || this.suspectIndividualForm.invalid;

    if (val == "Corporate")
      return this.suspectForm.invalid || this.suspectCooperateForm.invalid;

    if (val == "Government")
      return this.suspectForm.invalid || this.suspectGovernmentForm.invalid;

    if (val == "SME")
      return this.suspectForm.invalid || this.suspectSMEForm.invalid;
    return true;
  }

  createSuspect() {
    if (this.isInternalClientType) {
      this.confirmationService.confirm({
        message: `Are you sure this is an internal entity?`,
        accept: () => {
          this.createSuspectAfterConfirmation();
        },
        header: "Confirm Internal",
      });
    } else {
      this.createSuspectAfterConfirmation();
    }
  }

  createSuspectAfterConfirmation() {
    let email = "";
    let phone = "";
    if (this.selectedGroupType.caption == "Individual") {
      email = this.suspectIndividualForm.get("email").value;
      phone = this.suspectIndividualForm.get("phoneNumber").value;
    } else if (this.selectedGroupType.caption == "Corporate") {
      email = this.suspectCooperateForm.get("email").value;
      phone = this.suspectCooperateForm.get("phoneNumber").value;
    } else if (this.selectedGroupType.caption == "Government") {
      email = this.suspectGovernmentForm.get("email").value;
      phone = this.suspectGovernmentForm.get("phoneNumber").value;
    } else if (this.selectedGroupType.caption == "SME") {
      email = this.suspectSMEForm.get("email").value;
      phone = this.suspectSMEForm.get("phoneNumber").value;
    }

    if (this.file) {
      this.validating = true;
      this.validatingItemName = "Validating email";
      this.suspectService
        .getSuspectByEmail(email)
        .subscribe(async (r: CommonResponse) => {
          if (r.responseCode == "00") {
            this.messageService.add({
              severity: "error",
              summary: "Notice",
              detail: "This email has been chosen",
            });
            this.validating = false;
          } else {
            this.validatingItemName = "Valdating phone number";
            this.suspectService
              .getSuspectByPhone(phone)
              .subscribe(async (r: CommonResponse) => {
                if (r.responseCode == "00") {
                  this.messageService.add({
                    severity: "error",
                    summary: "Notice",
                    detail: "This phone number has been chosen",
                  });
                  this.validating = false;
                } else {
                  if (this.file.length > 0) {
                    this.fileStorageService.UploadMultipleFilesFromDataUrl(
                      this.file
                    );

                    this.fileStorageService.onUploadFinished.subscribe(
                      (resp: CommonResponse<string[]>) => {
                        if (resp.responseCode != "00") {
                          this.messageService.add({
                            severity: "error",
                            summary: "Notice",
                            detail: resp.responseMsg,
                          });
                        } else {
                          var imageUrl = resp.responseData;
                          this._createSuspect(imageUrl[0]);
                        }
                      },
                      (error) => {
                        console.log("Error while creating platform " + error);
                        this.messageService.add({
                          severity: "error",
                          summary: "Notice",
                          detail: "ERR: Unable to upload image to storage",
                        });
                      }
                    );
                  }
                }
              });
          }
        });
    } else {
      this.messageService.add({
        severity: "info",
        summary: "Notice",
        detail: "Cannot proceed without suspect image.",
      });
      return;
    }
  }

  _createSuspect(imageUrl) {
    let postData: any = {
      imageUrl: imageUrl, //this.suspectForm.get("imageUrl").value,
      street: this.suspectForm.get("street").value,
      address: `${this.street}, ${this.selectedLga.name}, ${this.selectedState.name} State`,
      leadOriginId: this.selectedLeadOrigin.id,
      groupTypeId: this.selectedGroupType.id,
      leadTypeId: this.selectedLeadType.id,
      branchId: this.selectedBranch.id,
      officeId: this.selectedOffice.id,
      stateId: this.selectedState.id,
      lgaId: this.selectedLga.id,
      isInternalClientType: this.isInternalClientType,
    };

    if (this.selectedGroupType.caption == "Individual") {
      postData.firstName = this.suspectIndividualForm.get("firstName").value;
      postData.lastName = this.suspectIndividualForm.get("lastName").value;
      postData.otherName = this.suspectIndividualForm.get("otherName").value;
      postData.email = this.suspectIndividualForm.get("email").value;
      postData.mobileNumber =
        this.suspectIndividualForm.get("phoneNumber").value;
      postData.industryId = this.selectedIndustry.id;
      this.validateIndividual(postData);
    } else if (this.selectedGroupType.caption == "Corporate") {
      postData.businessName =
        this.suspectCooperateForm.get("companyName").value;
      postData.rcNumber = this.suspectCooperateForm.get("rcNumber").value;
      postData.industryId = this.selectedIndustry.id;
      postData.email = this.suspectCooperateForm.get("email").value;
      postData.mobileNumber =
        this.suspectCooperateForm.get("phoneNumber").value;
      this.validateBusinessName(postData);
    } else if (this.selectedGroupType.caption == "Government") {
      postData.businessName =
        this.suspectGovernmentForm.get("companyName").value;
      postData.tier = this.suspectGovernmentForm.get("tier").value;
      postData.email = this.suspectGovernmentForm.get("email").value;
      postData.mobileNumber =
        this.suspectGovernmentForm.get("phoneNumber").value;
      this.validateBusinessName(postData);
    } else if (this.selectedGroupType.caption == "SME") {
      postData.businessName = this.suspectSMEForm.get("businessName").value;
      postData.industryId = this.selectedIndustry.id;
      postData.email = this.suspectSMEForm.get("email").value;
      postData.mobileNumber = this.suspectSMEForm.get("phoneNumber").value;
      this.validateBusinessName(postData);
    }
  }

  validateIndividual(postData) {
    this.validatingItemName = "Validating firstname and lastname";
    this.suspectService
      .getSuspectByNames(postData.firstName, postData.lastName)
      .subscribe(
        async (r: CommonResponse) => {
          if (r.responseCode != "00") {
            this.postSuspect(postData);
          } else {
            this.messageService.add({
              severity: "error",
              summary: "Notice",
              detail: "Firstname and Lastname have been chosen",
            });
            this.validating = false;
          }
        },
        (error) => {
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail: "Unable to create new suspect at the moment",
          });
          this.validating = false;
        }
      );
  }

  validateRC(postData) {
    this.validatingItemName = "Validating RC number";
    this.suspectService.getSuspectByRC(postData.rcNumber).subscribe(
      async (r: CommonResponse) => {
        if (r.responseCode != "00") {
          this.postSuspect(postData);
        } else {
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail: "RC number already taken",
          });
          this.validating = false;
        }
      },
      (error) => {
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail: "Unable to create new suspect at the moment",
        });
        this.validating = false;
      }
    );
  }

  validateBusinessName(postData) {
    this.validatingItemName = "Validating business name";
    this.suspectService
      .getSuspectByBusinessName(postData.businessName)
      .subscribe(
        async (r: CommonResponse) => {
          if (r.responseCode != "00") {
            if (this.selectedGroupType.caption == "Corporate") {
              this.validateRC(postData);
            } else {
              this.postSuspect(postData);
            }
          } else {
            this.messageService.add({
              severity: "error",
              summary: "Notice",
              detail: "Business name already taken",
            });
            this.validating = false;
          }
        },
        (error) => {
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail: "Unable to create new suspect at the moment",
          });
        }
      );
  }

  postSuspect(postData: any) {
    this.validatingItemName = "Creating new suspect record";
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Creating new suspect record",
    });
    if (this.selectedGroupType.caption == "Individual") {
      postData.isCreatingAsContact = this.IsCreatingAsContact;
    }
    this.suspectService.postSuspect(postData).subscribe(
      async (r: CommonResponse) => {
        this.validating = false;
        if (r.responseCode == "00") {
          await this.messageService.add({
            severity: "success",
            summary: "Completed",
            detail: "New Suspect Created",
          });
          this.fetchSuspects();
          this.suspectForm.reset();
          this.IsCreatingAsContact = true;
        } else {
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail: r.responseMsg,
          });
        }
      },
      (error) => {
        this.validating = false;
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail: "Unable to create new suspect at the moment",
        });
      }
    );
  }

  fetchSuspects() {
    this.fetchingSuspects = true;
    this.suspectService.allUserSuspects().subscribe(
      async (res) => {
        if (res.responseCode == "00") {
          this.suspects = res.responseData;
          this.fetchingSuspects = false;
        } else {
          this.fetchingSuspects = false;
          this.connectionError();
        }
      },
      (error) => {}
    );
  }

  qualifySuspect(suspect: Suspect) {
    this.router.navigate([`/home/suspect-qualification/${suspect.id}`]);
  }

  viewMore(suspect: Suspect) {}

  onFileSelect(event) {
    if (!this.checkMimeType(event) || !this.checkFileSize(event)) {
      return;
    }

    this.uploadingNewPicture = true;
    //this.isEditing = true;
    this.suspectForm.enable();
    const reader = new FileReader();
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      this.file.push(event.target.files[0]);
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.imageSrc = reader.result as string;
        this.suspectForm.patchValue({
          imageUrl: reader.result,
        });
      };
    }
  }

  checkMimeType = (event: EventInput): boolean => {
    const files = event.target.files[0];
    const types = ["image/png", "image/jpeg", "image/jpg", "image/gif"];
    if (!files) {
      this.messageService.add({
        severity: "error",
        summary: "Failed",
        detail: "Please select an Image file",
      });
      return false;
    }
    if (types.every((type) => files.type !== type)) {
      this.messageService.add({
        severity: "error",
        summary: "Failed",
        detail: "Image format not supported",
      });
      return false;
    }
    return true;
  };

  checkFileSize = (event: EventInput) => {
    const files = event.target.files[0];
    if (!files) return true;
    const size = 400000;
    if (files.size > size) {
      event.target.value = null;
      this.messageService.add({
        severity: "error",
        summary: "Failed",
        detail: "Image is too large, please pick a smaller file",
      });
      return false;
    }
    return true;
  };

  private connectionError() {
    this.messageService.add({
      severity: "error",
      summary: "Failed",
      detail: "Connection Error, Please try again",
    });
  }
}
