import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-admin-price',
  templateUrl: './admin-price.component.html',
  styleUrls: ['./admin-price.component.scss']
})
export class AdminPriceComponent implements OnInit {

  adminForm: FormGroup;
  isSubmitted: boolean = false;
  priceRangeError: string;

  constructor(public config: DynamicDialogConfig,
    public qualifyModalRef: DynamicDialogRef,
    private formBuilder: FormBuilder) { }

  ngOnInit(): void {
    var price = this.config.data.price;
    var minPrice = this.config.data.minPrice;
    var maxPrice = this.config.data.maxPrice;


    this.adminForm = this.formBuilder.group ({      
      unitPrice: [Validators.required] });

      this.adminForm.patchValue({
        unitPrice: price
      })

      if(minPrice && maxPrice){
        this.adminForm.get('unitPrice').setValidators([Validators.required, Validators.min(minPrice), Validators.max(maxPrice)]);
        this.priceRangeError = `Price must be between ${minPrice} and ${maxPrice}`
      }
  }

  submit(){
    this.isSubmitted = true;
    if(this.adminForm.invalid){
      return;
    }

    this.qualifyModalRef.close(this.adminForm.value);

  }

  closeQualifyService() {
    this.qualifyModalRef.close();
  }

}
