import { Component, OnInit } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { BreadcrumbService } from "src/app/breadcrumb.service";
import { ConfirmationService, MessageService } from "primeng/api";
import { CommonResponse } from "src/app/interfaces/home";
import { AccountService } from "src/app/services/account.service";

@Component({
  selector: "app-service-income-account",
  templateUrl: "./service-income-account.component.html",
  styleUrls: ["./service-income-account.component.scss"],
  providers: [MessageService, ConfirmationService],
})
export class ServiceIncomeAccountComponent implements OnInit {
  serviceIncomeForm: FormGroup;
  fetchingIncomeService: any;
  selectedIncomeService: any;
  incomeService: any[];
  serviceIncomeCols: any[];

  constructor(
    private formBuilder: FormBuilder,
    public messageService: MessageService,
    public confirmationService: ConfirmationService,
    private breadcrumbService: BreadcrumbService,
    private incomeAccountService: AccountService
  ) {}

  ngOnInit(): void {
    this.breadcrumbService.setItems([
      { label: "Setup", routerLink: ["home/service-category-task"] },
      { label: "Services", routerLink: ["home/service-category-task"] },
      {
        label: "Service Income Account",
        routerLink: ["home/service-category-task"],
      },
    ]);
    this.serviceIncomeForm = this.formBuilder.group({
      accountName: ["", [Validators.required, Validators.minLength(3)]],
      uniqueCode: ["", [Validators.required]],
    });

    this.getIncomeAccount();
  }

  createNewIncomeAccount() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Posting income acount",
    });

    var postData = this.serviceIncomeForm.value;
    this.incomeAccountService.postDtrackIncomeAccounts(postData).subscribe(
      async (r: CommonResponse) => {
        if (r.responseCode == "00") {
          await this.messageService.add({
            severity: "success",
            summary: "Completed",
            detail: "Income account posted successfully",
          });
          this.getIncomeAccount();
        } else {
          this.messageService.add({
            severity: "error",
            summary: "Failure",
            detail: r.responseMsg,
          });
        }
      },
      (error) => {
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail: "Unable to post income account",
        });
      }
    );
  }

  getIncomeAccount() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Fetching income acount",
    });
    this.incomeAccountService.getDtrackIncomeAccounts().subscribe(
      async (res: any) => {
        this.incomeService = res.responseData;
        this.fetchingIncomeService = false;
      },
      (error) => {
        this.fetchingIncomeService = false;
        //this.connectionError();
      }
    );
  }

  // deleteServiceCategoryTask(serviceCategoryTask) {
  //   this.confirmationService.confirm({
  //     message:
  //       "Are you sure you want to delete <strong>" +
  //       serviceCategoryTask.caption +
  //       "</strong>",
  //     accept: () => {
  //       this._deleteServiceCategoryTask(incomeService.id);
  //     },
  //   });
  // }

  // _deleteServiceCategoryTask(incomeService) {
  //   this.messageService.add({
  //     severity: "info",
  //     summary: "Notice",
  //     detail: "Deleting Service Category Task record",
  //   });
  //   this.serviceCategoryTaskService
  //     .getDtrackIncomeAccounts(incomeService.id)
  //     .subscribe(
  //       async (result: any) => {
  //         await this.messageService.add({
  //           severity: "success",
  //           summary: "Deleted",
  //           detail: "Service Category Task record removed",
  //         });
  //         await this.fetchServiceCategoryTask();
  //       },
  //       (error) => {
  //         this.connectionError();
  //       }
  //     );
  // }
}
