import { ViewReceiptComponent } from "./view-receipt/view-receipt.component";
import { ChildInfo } from "./../../../interfaces/premployment";
import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { ConfirmationService, MessageService } from "primeng/api";
import {
  Account,
  CommonResponse,
  Contract,
  ContractService,
  CustomerDivision,
  GroupContractCategory,
  Invoice,
} from "../../../interfaces/home";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { EventInput } from "@fullcalendar/core";
import { environment } from "../../../../environments/environment";
import { AccountVoucherTypeService } from "../../../services/account-voucher-type.service";
import { CustomerDivisionService } from "../../../services/customer-division.service";
import { InvoiceService } from "../../../services/invoice.sevice";
import { AccountService } from "../../../services/account.service";
import { ReceiptService } from "../../../services/receipt.service";
import { AngularFireStorage } from "@angular/fire/storage";
import { finalize } from "rxjs/operators";
import { Observable } from "rxjs";
import { BreadcrumbService } from "src/app/breadcrumb.service";
import { DialogService, DynamicDialogRef } from "primeng/dynamicdialog";
import { ReceiptBreakdownComponent } from "./receipt-breakdown/receipt-breakdown/receipt-breakdown.component";
import { AdvancePaymentsService } from "src/app/services/advance-payments.service";
import { AccountMasterService } from "src/app/services/account-master";
import { CurrencyService } from "src/app/services/currency.service";
import { CurrencyExchangeRate } from "src/app/interfaces/currency";
import { FileStorageService } from "src/app/services/file-storage.service";

@Component({
  selector: "app-receipting",
  templateUrl: "./receipting.component.html",
  styleUrls: ["./receipting.component.css"],
  providers: [MessageService, ConfirmationService],
})
export class ReceiptingComponent implements OnInit {
  contractServiceCols: any;
  contractServiceExportColumns: any;

  invoiceCols: any;
  invoiceExportColumns: any;

  clientForm: FormGroup;
  receiptEntryForm: FormGroup;
  bulkReceiptUploadForm: FormGroup;

  customerDivisions: CustomerDivision[];
  selectedCustomerDivision: CustomerDivision;

  fetchingContractServices = false;
  contractServices: ContractService[];
  selectedContractService: ContractService;
  fecthingContractServiceInvoices = false;
  invoices: Invoice[];
  selectedInvoice: Invoice;
  receipts: any[];
  selectedReceipt: any[] = [];

  yesnoOptions: any[];
  selectedOption: any;
  imageSrc: string;
  isEditing = false;
  file: any[] = [];
  uploadingReceipt = false;
  customerDivisionInformation: any;
  selectedContract: any;
  contracts: any[];
  invoiceSelected: Invoice;
  primaryContactFullName: string;
  secondaryContactFullName: string;
  viewedContractService: ContractService;
  invoiceValueBalance: number;
  invoiceValueBalanceForInvoice: number;
  amountReceived: any;
  //amountReceivedIsValid: boolean;

  cashBookAccounts: Account[];
  selectedCashBookAccount: Account;
  wht: number = 0;
  downloadURL: Observable<string>;
  withHeld: number;

  viewBreakdownRef: DynamicDialogRef;
  amountPaidPlusWithTaxWithheld: number;
  selectedCategory: any = null;
  isDirectPayment = true;
  sourceOfPayment: any[] = [
    { name: "Direct Payment", key: "1" },
    { name: "Credit Note", key: "2" },
    { name: "Advance Payment", key: "3" },
  ];
  cashReceivedLabel = "Amount Received";
  advancePayOrCreditNoteList: any[] = [];
  advancePaymentList: any[] = [];
  creditNoteList: any[] = [];
  sourceInUse: string = "Advance Payment";
  source: number = 0;
  submitted: boolean = false;
  filteredContractServices: ContractService[];
  includeExpiredContractService: boolean = false;
  formControlLabelsmap = {};
  fetchingReceipts: boolean = false;
  receiptCols: any;
  isFixedValueWHT: boolean = false;
  whtLabel = "WHT Value or %";
  availableReceipt: any[];
  availableCurrency: any[];
  fetchingCurrencies: boolean;
  fetchingExchangeRates: boolean;
  allExchangeRates: CurrencyExchangeRate[];
  exchangeRate: number | null;
  exchangeRateId: number | null;
  contractIsSelected: boolean;
  disableProcessReceipt: boolean = false;
  clientInView: CustomerDivision;

  constructor(
    public formBuilder: FormBuilder,
    public messageService: MessageService,
    public customerDivisionService: CustomerDivisionService,
    public accountVoucherTypeService: AccountVoucherTypeService,
    public invoiceService: InvoiceService,
    public accountService: AccountService,
    public receiptService: ReceiptService,
    public fireStorage: AngularFireStorage,
    public dialogService: DialogService,
    public confirmationService: ConfirmationService,
    private breadcrumbService: BreadcrumbService,
    private advancePayments: AdvancePaymentsService,
    private accountMasterService: AccountMasterService,
    private currency: CurrencyService,
    private fileStorageService: FileStorageService
  ) {}

  ngOnInit(): void {
    this.breadcrumbService.setItems([
      { label: "Finance", routerLink: ["home/receipting"] },
      { label: "Receipt Capture", routerLink: ["home/receipting"] },
    ]);

    this.getCustomerDivisions();
    this.getCashBookAccounts();

    this.contractServiceCols = [
      { field: "service", header: "Service" },
      { field: "serviceId", header: "Service Id" },
      { field: "status", header: "Status" },
    ];

    this.receiptCols = [
      { field: "narration", header: "Narration" },
      { field: "receiptValue", header: "receiptValue" },
    ];

    this.invoiceCols = [
      { field: "invoiceNumber", header: "Invoice Number" },
      { field: "invoiceValue", header: "Invoice Value" },
      { field: "invoiceMonth", header: "Invoice Month" },
      { field: "invoiceYear", header: "Invoice Year" },
      { field: "receiptStatus", header: "Receipt Status" },
    ];

    this.contractServiceExportColumns = this.contractServiceCols.map((col) => ({
      title: col.header,
      dataKey: col.field,
    }));
    this.invoiceExportColumns = this.invoiceCols.map((col) => ({
      title: col.header,
      dataKey: col.field,
    }));

    this.clientForm = this.formBuilder.group({
      customerId: [null, Validators.required],
    });

    this.setForm();

    this.bulkReceiptUploadForm = this.formBuilder.group({
      transactionId: ["", Validators.required],
    });

    this.yesnoOptions = [{ val: "Yes" }, { val: "No" }];

    this.formControlLabelsmap = {
      receiptDateTime: "Date fund received",
      amountReceived: "Amount received",
      balanceInclusiveOfWHT: "WHT%",
      wht: "WHT deducted",
      cashAccount: "Cash book/Bank account",
      evidenceOfReceipt: "Evidence of receipt",
      depositor: "Depositor",
      source: "Source of payment",
      amountLeft: "Availiable balance",
    };
    this.contractIsSelected = false;
  }

  private setForm() {
    this.receiptEntryForm = this.formBuilder.group({
      receiptDateTime: [""],
      amountReceived: ["", [Validators.min(1), Validators.required]],
      balanceInclusiveOfWHT: ["", Validators.required],
      wht: [""],
      cashAccount: [null],
      evidenceOfReceipt: [""],
      depositor: [""],
      source: [""],
      advancePayOrCredit: [""],
      amountLeft: [""],
      creditNoteOrAdvancePayId: [""],
      narration: [""],
      referenceNumber: [""],
      whtOption: ["0"],
      localCurrrency: [""],
      selectedQuoteCurrency: [""],
      rateApplied: [""],
      quoteAmount: [""],
    });
  }

  sourceOfPaymentChange() {
    //get the index selected
    this.source = this.receiptEntryForm.get("source").value?.key;
    this.zeroAmountLeft();

    var b = [];
    var y = [];

    if (this.source == 1) {
      this.isDirectPayment = true;
      this.cashReceivedLabel = "Amount Received";
      this.receiptEntryForm
        .get("evidenceOfReceipt")
        .setValidators([Validators.required]);
      this.receiptEntryForm
        .get("receiptDateTime")
        .setValidators([Validators.required]);
    } else {
      this.isDirectPayment = false;
      this.cashReceivedLabel = "Amount to receipt";
      this.receiptEntryForm.get("evidenceOfReceipt").setValidators(null);
      this.receiptEntryForm.get("receiptDateTime").setValidators(null);

      if (this.source == 2) {
        this.creditNoteList;
        this.sourceInUse = "Credit Note";
        for (var i = 0; i < this.creditNoteList.length; ++i) {
          var p = this.creditNoteList[i];
          var a = p.isUsedUp;
          if (a == false) {
            y.push(p);
          }
        }

        this.creditNoteList = y;
        this.advancePayOrCreditNoteList = this.creditNoteList;
      } else {
        this.sourceInUse = "Advance Payment";
        for (var i = 0; i < this.advancePaymentList.length; ++i) {
          var p = this.advancePaymentList[i];
          var a = p.isUsedUp;
          if (a == false) {
            b.push(p);
          }
        }
        this.advancePaymentList = b;

        this.advancePayOrCreditNoteList = this.advancePaymentList;
      }
    }

    this.receiptEntryForm.get("advancePayOrCredit").setValue("");
  }

  toggleExpiredContractService() {
    if (this.includeExpiredContractService)
      this.filteredContractServices = this.contractServices;
    else
      this.filteredContractServices = this.contractServices.filter(
        (x) => x.isActive
      );
  }

  private sumTotalAmountUsed(array: any[]) {
    var total = 0;
    for (var i = 0; i < array.length; i++) {
      total += array[i].amountUsed;
    }
    return total;
  }

  onSelectCreditNoteOrAdvancePay() {
    var selected = this.receiptEntryForm.get("advancePayOrCredit").value;
    this.receiptEntryForm.get("creditNoteOrAdvancePayId").setValue(selected.id);

    if (selected.usage?.length > 0) {
      var amountLeft = selected.value - this.sumTotalAmountUsed(selected.usage);
      this.receiptEntryForm.get("amountLeft").setValue(amountLeft.toFixed(2));
      return;
    }

    this.receiptEntryForm.get("amountLeft").setValue(selected.value);
  }

  onSelectCurrency() {
    //amountReceived
    this.exchangeRateId = null;
    var quoteAmount = this.receiptEntryForm.get("quoteAmount").value;
    var selectedBaseCurrency =
      this.receiptEntryForm.get("localCurrrency").value;
    var selectedQuoteCurrency = this.receiptEntryForm.get(
      "selectedQuoteCurrency"
    ).value;

    if (!selectedBaseCurrency) {
      return;
    }

    if (!selectedQuoteCurrency) {
      return;
    }

    var exchangeRecord = this.allExchangeRates.find(
      (x) =>
        x.baseCurrencyId == selectedQuoteCurrency.id &&
        x.quoteCurrencyId == selectedBaseCurrency.id
    );

    if (exchangeRecord) {
      this.exchangeRateId = exchangeRecord.id;
      this.exchangeRate = exchangeRecord?.exchangeRate;
    }

    if (quoteAmount && exchangeRecord) {
      this.receiptEntryForm.patchValue({
        amountReceived: Number.parseFloat(
          (quoteAmount * this.exchangeRate).toFixed(2)
        ),
      });
    } else {
      this.exchangeRate = null;
      this.receiptEntryForm.patchValue({
        amountReceived: "",
      });
    }
  }

  private zeroAmountLeft() {
    this.receiptEntryForm.get("amountLeft").setValue(0);
  }

  getCustomerAdancePaymentsAndCreditNotes(id) {
    this.advancePayments
      .getAdvancePayment(id)
      .subscribe(async (res: CommonResponse) => {
        if (res.responseCode == "00") {
          var list = res.responseData;
          this.advancePaymentList = list.map(
            ({ id, amount, isCompletelyUsed, advancePaymentUsages }) => ({
              id: id,
              value: amount,
              isUsedUp: isCompletelyUsed,
              usage: advancePaymentUsages,
            })
          );
        } else {
          this.advancePaymentList = [];
        }
      });

    this.accountMasterService
      .getCreditNotes(id)
      .subscribe(async (res: CommonResponse) => {
        if (res.responseCode == "00") {
          var list = res.responseData;
          if (list) {
            this.creditNoteList = list.map(
              ({ id, value, creditNoteUsages, isCreditNoteCompletedUsed }) => ({
                id: id,
                value: value,
                usage: creditNoteUsages,
                isUsedUp: isCreditNoteCompletedUsed,
              })
            );
          }
        } else {
          this.creditNoteList = [];
        }
      });
  }

  getCustomerDivisions() {
    this.customerDivisionService.allCustomerDivision().subscribe(
      async (res: CommonResponse) => {
        this.customerDivisions = res.responseData;
      },
      (error) => {
        this.messageService.add({
          severity: "error",
          summary: "Failed",
          detail: "Connection error, Try again later",
        });
      }
    );
  }

  getCustomerDivisionById(customerDivisionId: any) {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Retrieving Client Information",
    });

    this.fetchingContractServices = true;
    this.customerDivisionService
      .getCustomerDivision(customerDivisionId)
      .subscribe(
        async (r: CommonResponse) => {
          await this.messageService.add({
            severity: "success",
            summary: "Success",
            detail: "Retrieved Client Information Successfully",
          });

          var result = r.responseData;
          this.customerDivisionInformation = result;
          const primaryContact =
            this.customerDivisionInformation.primaryContact;
          const secondaryContact =
            this.customerDivisionInformation.secondaryContact;

          if (primaryContact) {
            this.primaryContactFullName = `${primaryContact.firstName} ${primaryContact.lastName}`;
          } else {
            this.primaryContactFullName = "Not Available.";
          }

          if (secondaryContact) {
            this.secondaryContactFullName = `${secondaryContact.firstName} ${secondaryContact.lastName}`;
          } else {
            this.secondaryContactFullName = "Not Available.";
          }
          this.contracts = result.contracts;
          this.fetchingContractServices = false;
        },
        (error) => {
          this.connectionError();
          this.fetchingContractServices = false;
        }
      );
  }

  getCurrency() {
    this.fetchingCurrencies = true;
    this.currency.GetAllCurrencies().subscribe(
      async (res: any) => {
        this.availableCurrency = res;
        this.fetchingCurrencies = false;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all currencies at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
        this.fetchingCurrencies = false;
      }
    );
  }

  FetchAllExchangeRates() {
    this.fetchingExchangeRates = true;
    this.currency.GetAllCurrencyExchangeRates().subscribe(
      async (data) => {
        this.allExchangeRates = data;
        this.allExchangeRates.reverse();
        // this.receiptEntryForm
        //   .get("rateApplied")
        //   .setValue(this.allExchangeRates);
        this.fetchingExchangeRates = false;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all exchange rates at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
        this.fetchingExchangeRates = false;
      }
    );
  }

  getCashBookAccounts() {
    this.accountService.getCashBookAccounts().subscribe(
      async (res: CommonResponse) => {
        this.cashBookAccounts = res.responseData;
      },
      (error) => {
        this.messageService.add({
          severity: "error",
          summary: "Failed",
          detail: "Connection error, Try again later",
        });
      }
    );
  }

  private connectionError() {
    this.messageService.add({
      severity: "error",
      summary: "Failed",
      detail: "Connection Error, Please try again",
    });
  }

  searchContractServicesByClient() {
    this.clientInView = this.selectedCustomerDivision;
    this.customerDivisionInformation = null;
    this.selectedContract = null;
    this.contracts = null;
    this.invoices = null;
    this.viewedContractService = null;
    this.getCustomerDivisionById(this.selectedCustomerDivision.id);
    this.getCustomerAdancePaymentsAndCreditNotes(
      this.selectedCustomerDivision.id
    );
  }

  getInvoicesByContractServiceId(contractServiceId: any) {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Retrieving Invoices",
    });
    this.invoiceService
      .getInvoicesByContractDivisionId(contractServiceId)
      .subscribe(
        async (r: CommonResponse) => {
          await this.messageService.add({
            severity: "success",
            summary: "Fetching",
            detail: "Invoices Retrieved Successfully.",
          });
          // console.log('Invoices ' , result)
          var result = r.responseData;
          this.invoices = result;
          this.invoices.forEach((x) => {
            const receiptStatus = x.isReceiptedStatus;
            const check1 = receiptStatus === 0 || receiptStatus === 1;
            x.canPickInvoice = check1;
            if (check1) {
              const cantReceipt = this.invoices.some((invoice) => {
                return (
                  new Date(invoice.startDate) < new Date(x.startDate) &&
                  invoice.isReceiptedStatus !== 2
                );
              });
              x.canPickInvoice = !cantReceipt;
            }
          });
        },
        (error) => {
          this.connectionError();
        }
      );
  }

  getReceipt(contractServiceId: any) {
    this.fetchingReceipts = true;
    this.receiptService.getReceiptOnContract(contractServiceId).subscribe(
      async (r: CommonResponse) => {
        if (r.responseCode == "00") {
          this.fetchingReceipts = false;
          await this.messageService.add({
            severity: "success",
            summary: "Notice",
            detail: "Invoices Retrieved Successfully.",
          });

          var result = r.responseData;
          this.receipts = result;
        }
      },
      (error) => {
        this.connectionError();
      }
    );
  }

  viewContractServiceDetails(contractService: ContractService) {
    this.viewedContractService = contractService;
    this.invoices = null;
    this.getInvoicesByContractServiceId(contractService.id);
    this.getCurrency();
    this.FetchAllExchangeRates();
  }

  viewReceiptDetails(recepit: any) {}

  viewOtherContactInfo() {}

  pickInvoice(invoice: Invoice) {
    this.file = [];
    this.setForm();
    this.invoiceSelected = invoice;
    this.invoiceValueBalanceForInvoice = Number.parseFloat(
      (
        this.invoiceSelected.value - this.invoiceSelected.totalAmountReceipted
      ).toFixed(2)
    );
  }

  handleReceiptSubmission() {
    this.disableProcessReceipt = true;
    var option = this.receiptEntryForm.get("whtOption").value;
    if (
      this.selectedOption?.val === "Yes" &&
      (this.wht == 0 || !this.wht || option == 0)
    ) {
      this.messageService.add({
        severity: "error",
        summary: "Declined",
        detail:
          "You need to select WHT option (WHT fixed or %) and enter the figure",
      });
      this.disableProcessReceipt = false;
      return;
    }

    if (option == 2 && this.wht > 100) {
      this.messageService.add({
        severity: "error",
        summary: "Declined",
        detail: "The WHT % cannot be greater than 100",
      });
      this.disableProcessReceipt = false;
      return;
    }

    if (!this.exchangeRateId && this.isDirectPayment) {
      this.messageService.add({
        severity: "error",
        summary: "Declined",
        detail:
          "The local currency and quote currency do not have a match in exchange rates table",
      });
      this.disableProcessReceipt = false;
      return;
    }

    this.submitted = true;
    if (this.source == 0) {
      this.messageService.add({
        severity: "error",
        summary: "Declined",
        detail: "Please choose source of payment",
      });
      this.disableProcessReceipt = false;
      return;
    }

    if (
      this.submitted == true &&
      this.source == 1 &&
      !this.receiptEntryForm.get("depositor").value
    ) {
      this.receiptEntryForm
        .get("depositor")
        .setValidators([Validators.required]);
      this.messageService.add({
        severity: "error",
        summary: "Declined",
        detail: "Please enter depositor's name",
      });
      this.disableProcessReceipt = false;
      return;
    }

    var counter = 0;
    const controls = this.receiptEntryForm.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
        if (name == "amountReceived" && this.source != 1) {
          ++counter;
          this.messageService.add({
            severity: "error",
            summary: "Error",
            detail: "Amount to receipt" + " is required",
          });
        } else if (name == "receiptDateTime") {
          if (this.source == 1) {
            ++counter;
            this.messageService.add({
              severity: "error",
              summary: "Error",
              detail: this.formControlLabelsmap[name] + " is required",
            });
          }
        } else {
          ++counter;
          this.messageService.add({
            severity: "error",
            summary: "Error",
            detail: this.formControlLabelsmap[name] + " is required",
          });
        }
      }
    }

    if (counter > 0) {
      this.disableProcessReceipt = false;
      return;
    }
    if (
      this.receiptEntryForm.controls["amountReceived"].value + this.withHeld >
      this.invoiceValueBalanceForInvoice
    ) {
      this.messageService.add({
        severity: "error",
        summary: "Cancelled",
        detail: "Amount entered in greater than invoice balance",
      });
      this.disableProcessReceipt = false;
      return;
    }

    var amountLeft = this.receiptEntryForm.get("amountLeft").value;
    if (!this.isDirectPayment) {
      if (this.amountReceived > amountLeft) {
        this.disableProcessReceipt = false;
        return;
      }
    }

    if (this.receiptEntryForm.invalid) {
      this.messageService.add({
        severity: "error",
        summary: "Cancelled",
        detail: "Some required fields are missing",
      });
      this.disableProcessReceipt = false;
      return;
    }
    if (
      this.selectedContract.groupContractCategory ==
      GroupContractCategory.IndividualContract
    ) {
      this.processReceipt();
    } else {
      this.displayReceiptingBreakdown();
    }
  }

  displayReceiptingBreakdown() {
    const amountReceived = this.receiptEntryForm.get("amountReceived").value;
    const taskWitheld = this.selectedOption.val === "Yes";
    var receiptValue: number = taskWitheld
      ? amountReceived + this.withHeld
      : amountReceived;
    receiptValue = Number.parseFloat(receiptValue.toFixed(2));
    this.viewBreakdownRef = this.dialogService.open(ReceiptBreakdownComponent, {
      width: "1300px",
      contentStyle: { "max-width": "100vw", height: "50vh", overflow: "auto" },
      baseZIndex: 100,
      data: { receiptValue, invoice: this.invoiceSelected },
      header: "Receipt Breakdown",
    });

    this.viewBreakdownRef.onClose.subscribe((toProceed) => {
      if (toProceed) this.processReceipt(); 
      else {
        this.messageService.add({
          severity: "error",
          summary: "Failed",
          detail: "Receipt processing cancelled.",
        });
        this.disableProcessReceipt = false;
      }
    });
  }

  processReceipt() {
    if (this.isDirectPayment) {
      if (this.file.length == 0) {
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail: "Cannot Proceed without receipt image.",
        });
        this.disableProcessReceipt = false;
        return;
      }

      this.fileStorageService.UploadMultipleFilesFromDataUrl(this.file);
      this.fileStorageService.onUploadFinished.subscribe(
        (resp: CommonResponse<string[]>) => {
          if (resp.responseCode != "00") {
            this.messageService.add({
              severity: "error",
              summary: "Notice",
              detail: resp.responseMsg,
            });
            this.disableProcessReceipt = false;
          } else {
            var imageUrl = resp.responseData;
            this.receiptEntryForm.patchValue({
              evidenceOfReceipt: imageUrl[0],
            });
            this._processReceipt();
          }
        },
        (error) => {
          console.log("Error while creating platform " + error);
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail: "ERR: Unable to upload image to storage",
          });
          this.disableProcessReceipt = false;
        }
      );
    } else {
      this._processReceipt();
    }
  }

  _processReceipt() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Posting Receipt...",
    });

    const wht = this.receiptEntryForm.get("wht").value;
    const receiptValue = this.receiptEntryForm.get("amountReceived").value;
    const depositor = this.receiptEntryForm.get("depositor").value;
    const invoiceNumber = this.invoiceSelected?.invoiceNumber || "";
    var option = this.receiptEntryForm.get("whtOption").value;

    const taskWitheld = this.selectedOption.val === "Yes";
    var balanceBeforeInvoicing =
      this.invoiceSelected.value - this.invoiceSelected.totalAmountReceipted;
    var amtRecv = parseFloat(receiptValue);
    this.invoiceValueBalance = taskWitheld
      ? balanceBeforeInvoicing - this.amountPaidPlusWithTaxWithheld
      : balanceBeforeInvoicing - amtRecv;

    var postData = {
      caption: `Received ${receiptValue} from ${depositor} for INVOICE ${invoiceNumber}`,
      invoiceNumber,
      invoiceValue: this.invoiceSelected.value,
      invoiceValueBalanceBeforeReceipting: balanceBeforeInvoicing,
      receiptValue: taskWitheld ? receiptValue + this.withHeld : receiptValue,
      depositor,
      invoiceValueBalanceAfterReceipting: this.invoiceValueBalance,
      isTaskWitheld: taskWitheld,
      valueOfWHT: !wht || wht === "" ? 0 : option == 1 ? this.withHeld : wht,
      invoiceId: this.invoiceSelected.id,
      accountId: this.selectedCashBookAccount?.id,
      source: this.source,
      creditNoteOrAdvancePaymentId: this.receiptEntryForm.get(
        "creditNoteOrAdvancePayId"
      ).value,
      isFixedWHT: option == 1,
      currencyExchangeRateId: this.exchangeRateId,
    };

    if (this.isDirectPayment) {
      postData["dateAndTimeOfFundsReceived"] =
        this.receiptEntryForm.get("receiptDateTime").value;
      postData["evidenceOfPaymentUrl"] =
        this.receiptEntryForm.get("evidenceOfReceipt").value;
      postData["caption"] = this.receiptEntryForm.get("narration").value;
      postData["referenceNumber"] =
        this.receiptEntryForm.get("referenceNumber").value;
    }

    this.receiptService.postReceipt(postData).subscribe(
      async (res: CommonResponse) => {
        this.submitted = false;
        if (res.responseCode == "00") {
          await this.messageService.add({
            severity: "success",
            summary: "Posted",
            detail: "Receipt Posted Successfully.",
          });
          this.disableProcessReceipt = false;

          this.invoiceSelected = null;
          this.receiptEntryForm.reset();
          this.getInvoicesByContractServiceId(this.viewedContractService.id);
          this.getCustomerAdancePaymentsAndCreditNotes(
            this.selectedCustomerDivision.id
          );
        } else {
          await this.messageService.add({
            severity: "error",
            summary: "Failure",
            detail: "Receipt Posting not Successful.",
          });
          await this.messageService.add({
            severity: "error",
            summary: "Failure",
            detail: res.responseMsg,
          });
          this.disableProcessReceipt = false;
        }
      },
      (error) => {
        this.submitted = false;
        this.messageService.add({
          severity: "error",
          summary: "Failed",
          detail: "Error posting receipt. Try again later.",
        });
        this.disableProcessReceipt = false;
      }
    );
  }

  submitBulkUpload() {}

  selectContract(contract?: Contract) {
    // console.log('Selected Contract ', contract)
    this.selectedContract = contract;
    this.contractServices = contract.contractServices;
    this.contractIsSelected = true;
    this.toggleExpiredContractService();
    this.getReceipt(contract.id);
  }

  displayReceipt(receipt: any) {
    this.viewBreakdownRef = this.dialogService.open(ViewReceiptComponent, {
      width: "950px",
      contentStyle: { "max-width": "100vw", height: "90vh", overflow: "auto" },
      baseZIndex: 100,
      data: { receipt: this.selectedReceipt },
    });

    this.viewBreakdownRef.onClose.subscribe(async (res: any[]) => {
      // this.availableReceipt = [];
      // res.forEach((value, i) => {
      //   this.availableReceipt.push(value);
      // });
    });
  }

  amtWhtChanged() {
    var option = this.receiptEntryForm.get("whtOption").value;
    this.whtLabel = option == 1 ? "WHT value" : "WHT %";

    this.amountReceived = this.receiptEntryForm.get("amountReceived").value;
    this.wht = this.receiptEntryForm.get("wht").value;

    if (!this.amountReceived) return;

    var amtRecvd = parseFloat(this.amountReceived);
    // const invoiceValueBalance = this.invoiceSelected?.value - this.invoiceSelected?.totalAmountReceipted;

    if (this.selectedOption && this.selectedOption.val === "Yes" && this.wht) {
      if (option == 1) {
        this.amountPaidPlusWithTaxWithheld = Number.parseFloat(
          (amtRecvd + this.wht).toFixed(2)
        );

        this.withHeld = Number.parseFloat(
          (this.amountPaidPlusWithTaxWithheld - amtRecvd).toFixed(2)
        );
      } else if (option == 2) {
        const wht = this.wht / 100;

        this.amountPaidPlusWithTaxWithheld = Number.parseFloat(
          (amtRecvd + (amtRecvd * wht) / (1 - wht)).toFixed(2)
        );

        this.withHeld = Number.parseFloat(
          (this.amountPaidPlusWithTaxWithheld - amtRecvd).toFixed(2)
        );
      }
    } else {
      this.withHeld = 0;
      this.amountPaidPlusWithTaxWithheld = 0;
    }
  }

  onFileSelect(event) {
    if (!this.checkMimeType(event) || !this.checkFileSize(event)) {
      return;
    }

    this.uploadingReceipt = true;
    this.isEditing = true;
    this.receiptEntryForm.enable();
    const reader = new FileReader();
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      this.file.push(event.target.files[0]);
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.imageSrc = reader.result as string;
        this.receiptEntryForm.patchValue({
          evidenceOfReceipt: reader.result,
        });
      };
    }
  }

  checkMimeType = (event: EventInput): boolean => {
    const files = event.target.files[0];
    // list allow mime type
    const types = ["image/png", "image/jpeg", "image/jpg", "image/gif"];
    // loop access array
    if (!files) {
      this.messageService.add({
        severity: "error",
        summary: "Failed",
        detail: "Please select an Image file",
      });
      return false;
    }
    // compare file type find doesn't match
    if (types.every((type) => files.type !== type)) {
      // create error message and assign to container
      this.messageService.add({
        severity: "error",
        summary: "Failed",
        detail: "Image format not supported",
      });
      return false;
    }
    return true;
  };

  checkFileSize = (event: EventInput) => {
    const files = event.target.files[0];
    if (!files) return true;
    const size = 400000;
    if (files.size > size) {
      event.target.value = null;
      this.messageService.add({
        severity: "error",
        summary: "Failed",
        detail: "Image is too large, please pick a smaller file",
      });
      return false;
    }
    return true;
  };

  PerformBulkAdvanceReceipting(item: ContractService) {
    this.confirmationService.confirm({
      message:
        "You are about to perform a bulk receipting using the client's unused advance payments. This is an irreversible action, Do you still wish to proceed?",
      accept: () => {
        this.messageService.add({
          severity: "info",
          summary: "Notice",
          detail: "Performing bulk receipting using advance payment...",
        });

        this.receiptService
          .BulkReceiptingUsingAdvancePayment(item.id, this.clientInView.id)
          .subscribe(
            async (data) => {
              if (data.responseCode != "00") {
                this.messageService.add({
                  severity: "error",
                  summary: data.responseMsg,
                });
                return;
              }

              this.messageService.add({
                severity: "success",
                summary: "Operation Successful",
                detail: "Bulk Receipting performed successfully!",
              });
              this.getCustomerAdancePaymentsAndCreditNotes(
                this.clientInView.id
              );
              this.setForm();
            },
            (error) => {
              console.log("Error: " + JSON.stringify(error));
              this.messageService.add({
                severity: "error",
                summary: "Notice",
                detail:
                  "Unable to perform bulk receipting using client advance payment(s) at the moment.. Reason: [" +
                  error
                    ? error.error.message
                    : "request failed - permission" + "]",
              });
            }
          );
      },
    });
  }

  FormatDateString(date: Date): string {
    let dateString = date.toString();

    try {
      // DateTimeFormatOptions
      const date: Date = new Date(dateString);
      const options: Intl.DateTimeFormatOptions = {
        year: "numeric",
        month: "long",
        day: "numeric",
      };
      const formattedDate: string = new Intl.DateTimeFormat(
        "en-US",
        options
      ).format(date);
      dateString = formattedDate;
    } catch (error) {
      console.log(error);
      console.log(date);
      console.log(
        "Error while converting date string " + date + " exception " + error
      );
    }

    return dateString;
  }
}
