import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { MessageService, ConfirmationService } from "primeng/api";
import { BreadcrumbService } from "src/app/breadcrumb.service";
import {
  CreateExpenseAuthorizationSetupVM,
  ExpenseAuthorizationOffice,
  ExpenseAuthorizationSetup,
  UpdateExpenseAuthorizationSetupVM,
} from "src/app/interfaces/finance-expense";
import { ServiceCategory, User } from "src/app/interfaces/home";
import { FinanceExpenseService } from "src/app/services/finance-expense.service";
import { ServiceCategoryService } from "src/app/services/service-category.service";
import { UserService } from "src/app/services/user.service";

@Component({
  selector: "app-expense-authorization-setup",
  templateUrl: "./expense-authorization-setup.component.html",
  styleUrls: ["./expense-authorization-setup.component.scss"],
})
export class ExpenseAuthorizationSetupComponent implements OnInit {
  @ViewChild("formWrapper") public formWrapper: ElementRef;
  authorizationSetupForm: FormGroup;
  allServiceCategories: ServiceCategory[];
  selectedServiceCategories: ServiceCategory[] = [];
  allUsers: User[];
  theUser: User;
  fetchingAuthorizationSetups: boolean;
  allAuthorizationExpenseSetups: ExpenseAuthorizationSetup[] = [];
  allAuthorizationSetups: {
    serviceCategory: string;
    setups: ExpenseAuthorizationSetup[];
  }[];
  addedApprovingOfficers: {
    userId: number;
    user: User;
  }[] = [];
  selectedApprovingOfficer: {
    userId: number;
    user: User;
  }[] = [];
  isEditing: boolean;
  officeEditing: ExpenseAuthorizationOffice;
  authorizationSetupCols: any[];
  setupDetailsCols: any[];

  constructor(
    fb: FormBuilder,
    public financeExpenseService: FinanceExpenseService,
    public userService: UserService,
    public serviceCategoryService: ServiceCategoryService,
    public messageService: MessageService,
    private breadcrumbService: BreadcrumbService,
    public confirmationService: ConfirmationService
  ) {
    this.authorizationSetupForm = fb.group({
      ServiceCateg: ["", Validators.required],
      OfficeName: ["", Validators.required],
      Officer: [""],
      LowerLimit: ["", Validators.required],
      UpperLimit: ["", Validators.required],
    });
  }

  ngOnInit(): void {
    this.breadcrumbService.setItems([
      {
        label: "Finance",
        routerLink: ["/home/finance"],
      },
      {
        label: "Expense Tool",
        routerLink: ["/home/finance/expense"],
      },
      {
        label: "Authorization Setup",
        routerLink: ["/home/finance/expense/authorization-setup"],
      },
    ]);

    this.authorizationSetupCols = [
      { field: "serviceCategory", header: "Expense Category" },
    ];

    this.setupDetailsCols = [
      { field: "officer", header: "Offcer" },
      { field: "lowerLimit", header: "Lower Limit" },
      { field: "upperLimit", header: "Upper Limit" },
    ];
    this.GetAllServiceCategories();
  }

  async GetAllServiceCategories() {
    this.serviceCategoryService.GetAllServiceCategories().subscribe(
      async (data) => {
        if (data.responseCode == "00") {
          this.allServiceCategories = data.responseData;
          this.GetAllUsers();
        } else {
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail: data.responseMsg,
          });
        }
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all service categories at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  async GetAllUsers() {
    this.userService.allUser().subscribe(
      async (r) => {
        if (r.responseCode == "00") {
          var data = r.responseData ?? [];
          this.allUsers = [];
          data.forEach((user) => {
            user.fullName = user.lastName + " " + user.firstName;
            this.allUsers.push(user);
          });
          this.GetAllExpenseAuthorizationOffices();
        } else {
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail: r.responseMsg,
          });
        }
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all users at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  async GetAllExpenseAuthorizationOffices() {
    this.fetchingAuthorizationSetups = true;
    this.financeExpenseService.GetAllExpenseAuthorizationSetups().subscribe(
      async (data) => {
        if (data.responseCode == "00") {
          this.allAuthorizationExpenseSetups = data.responseData;
          let groupedByCateg = [
            ...new Set(data.responseData.map((req) => req.serviceCategoryId)),
          ];

          this.allAuthorizationSetups = [];
          groupedByCateg.forEach((categId) => {
            let categModel = this.allServiceCategories.find(
              (x) => x.id == categId
            );
            if (categModel) {
              let approvalData: {
                serviceCategory: string;
                setups: any[];
              } = {
                serviceCategory: categModel.name,
                setups: data.responseData.filter(
                  (x) => x.serviceCategoryId == categId
                ),
              };

              this.allAuthorizationSetups.push(approvalData);
            }
          });
          this.fetchingAuthorizationSetups = false;
        } else {
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail: data.responseMsg,
          });
          this.fetchingAuthorizationSetups = false;
        }
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all expense authorization setups at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
        this.fetchingAuthorizationSetups = false;
      }
    );
  }

  CreateAuthorizationSetup() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Creating Expense Authorization Setup...",
    });

    const postData: CreateExpenseAuthorizationSetupVM = {
      serviceCategoriesIds: this.selectedServiceCategories.map((x) => x.id),
      officeName: this.authorizationSetupForm.get("OfficeName").value,
      officersIds: this.addedApprovingOfficers.map((x) => x.userId),
      lowerLimit: this.authorizationSetupForm.get("LowerLimit").value,
      upperLimit: this.authorizationSetupForm.get("UpperLimit").value,
    };

    this.financeExpenseService
      .CreateExpenseAuthorizationSetup(postData)
      .subscribe(
        async (data) => {
          if (data.responseCode != "00") {
            this.messageService.add({
              severity: "error",
              summary: "Notice",
              detail: data.responseMsg,
            });
            return;
          }

          this.CloseEditingAuthorizationSetup();
          this.GetAllExpenseAuthorizationOffices();
        },
        (error) => {
          console.log("Error: " + JSON.stringify(error));
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail:
              "Unable to create expense authorization setups at the moment.. Reason: [" +
              (error ? error.error.message : "request failed - permission") +
              "]",
          });
        }
      );
  }

  AddApprovingOfficerToList() {
    if (this.theUser == null) {
      this.messageService.add({
        severity: "error",
        summary: "Failed",
        detail: "You must select the officer to add",
      });
      return;
    }

    let alreadyAdded = false;
    this.addedApprovingOfficers.forEach((item) => {
      if (item.userId == this.theUser.id) {
        alreadyAdded = true;
      }
    });

    if (alreadyAdded) {
      this.messageService.add({
        severity: "error",
        summary: "Failed",
        detail: "Officer with the same info has already been added to list.",
      });
      return;
    }

    this.addedApprovingOfficers.push({
      userId: this.theUser.id,
      user: this.theUser,
    });

    this.theUser = null;
    this.messageService.add({
      severity: "success",
      summary: "Added",
      detail: "Authorization Officer added successfully",
    });
  }

  RemoveOfficer(item: any) {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Removing Authorization Officer...",
    });

    const index = this.addedApprovingOfficers.indexOf(item);
    if (index > -1) {
      this.addedApprovingOfficers.splice(index, 1);
    }

    this.messageService.add({
      severity: "success",
      summary: "Removed",
      detail: "Authorization Officer Removed successfully",
    });
  }

  DeleteAuthorizationOffice(item: ExpenseAuthorizationSetup) {
    this.confirmationService.confirm({
      message: "Are you sure you want to remove expense authorization setup?",
      accept: () => {
        this.messageService.add({
          severity: "info",
          summary: "Notice",
          detail: "Removing expense authorization setup...",
        });

        this.financeExpenseService
          .DeleteExpenseAuthorizationSetup(item.id)
          .subscribe(
            async (data) => {
              if (data.responseCode != "00") {
                this.messageService.add({
                  severity: "error",
                  summary: "Notice",
                  detail: data.responseMsg,
                });
                return;
              }

              this.GetAllExpenseAuthorizationOffices();
            },
            (error) => {
              console.log("Error: " + JSON.stringify(error));
              this.messageService.add({
                severity: "error",
                summary: "Notice",
                detail:
                  "Unable to remove expense authorization setup at the moment.. Reason: [" +
                  error
                    ? error.error.message
                    : "request failed - permission" + "]",
              });
            }
          );
      },
    });
  }

  GetOfficerFullName(profileId: number): string {
    let profile = this.allUsers.find((x) => x.id == profileId);
    if (profile)
      return (
        profile.lastName + " " + profile.firstName + " " + profile.otherName
      );

    return "N/A";
  }

  EditAuthorizationOffice(item: ExpenseAuthorizationSetup) {
    this.isEditing = true;
    this.authorizationSetupForm.patchValue({
      OfficeName: item.expenseAuthorizationOffice.officeName,
      LowerLimit: item.lowerLimit,
      UpperLimit: item.upperLimit,
    });
    let allCategOffices = this.allAuthorizationExpenseSetups.filter(
      (x) => x.expenseAuthorizationOfficeId == item.expenseAuthorizationOfficeId
    );
    let otherCategsIds = allCategOffices.map((x) => x.serviceCategoryId);
    otherCategsIds.push(item.serviceCategoryId);
    this.selectedServiceCategories = this.allServiceCategories.filter(
      (x) => otherCategsIds.find((y) => y == x.id) != null
    );
    this.officeEditing = item.expenseAuthorizationOffice;
    this.addedApprovingOfficers = [];
    item.expenseAuthorizationOffice.officers
      .filter((x) => x.isDeleted == false)
      .forEach((x) => {
        this.addedApprovingOfficers.push({
          userId: x.officerProfileId,
          user: this.allUsers.find((y) => y.id == x.officerProfileId),
        });
      });

    this.formWrapper.nativeElement.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "start",
    });
  }

  CloseEditingAuthorizationSetup() {
    this.authorizationSetupForm.reset();
    this.addedApprovingOfficers = [];
    this.selectedServiceCategories = [];
    this.selectedApprovingOfficer = [];
    this.isEditing = false;
  }

  UpdateAuthorizationSetup() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Updating Authorization Office...",
    });

    const id = this.officeEditing.id;
    const postData: UpdateExpenseAuthorizationSetupVM = {
      officersIds: this.addedApprovingOfficers.map((x) => x.userId),
    };

    this.financeExpenseService
      .UpdateExpenseAuthorizationSetup(id, postData)
      .subscribe(
        async (data) => {
          if (data.responseCode != "00") {
            this.messageService.add({
              severity: "error",
              summary: "Notice",
              detail: data.responseMsg,
            });
            return;
          }

          this.messageService.add({
            severity: "success",
            summary: "Completed",
            detail: "Expense Authorization Office Updated Successfully!",
          });

          this.CloseEditingAuthorizationSetup();
          this.GetAllExpenseAuthorizationOffices();
        },
        (error) => {
          console.log("Error: " + JSON.stringify(error));
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail:
              "Unable to update expense authorization office at the moment.. Reason: [" +
              (error ? error.error.message : "request failed - permission") +
              "]",
          });
        }
      );
  }
}
