import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { MessageService, ConfirmationService, Message } from "primeng/api";
import { BreadcrumbService } from "src/app/breadcrumb.service";
import { FileStorageService } from "src/app/services/file-storage.service";
import { FireBaseAuthService } from "src/app/services/fire-base-auth.service";
import { EventInput } from "@fullcalendar/core";
import {
  Account,
  BooleanOption,
  CustomerDivision,
  PostMultipleRawInflowItemVM,
  PostRawInflowItemVM,
  RawInflowItem,
  UploadRawInflowItemsDataVM,
  UploadRawInflowItemsVM,
} from "src/app/interfaces/home";
import { CustomerDivisionService } from "src/app/services/customer-division.service";
import { AccountService } from "src/app/services/account.service";
import { AdvancePaymentsService } from "src/app/services/advance-payments.service";
import { CurrencyService } from "src/app/services/currency.service";
import { Currency, CurrencyExchangeRate } from "src/app/interfaces/currency";

@Component({
  selector: "app-raw-inflow-upl",
  templateUrl: "./raw-inflow-upl.component.html",
  styleUrls: ["./raw-inflow-upl.component.scss"],
  providers: [MessageService],
})
export class RawInflowUplComponent implements OnInit {
  @ViewChild("formWrapper") public formWrapper: ElementRef;
  @ViewChild("hiddenfileinput") public hiddenfileinput: ElementRef;
  file: any;
  msg: Message[] = [];
  msg2: Message[] = [];
  uploadContents: UploadRawInflowItemsDataVM[] = [];
  bulkCols: any[];
  showUpls: boolean = true;
  errorLists: {
    sn: number;
    reason: string;
    data: string;
  }[] = [];
  errorCols: any[];
  customerDivisions: any[] = [];
  cashBookAccounts: Account[] = [];
  processingBulkUpload: boolean;
  openErrorDialogue: boolean;
  allClients: CustomerDivision[] = [];
  fileInput: boolean;
  allRawInflowItems: RawInflowItem[] = [];
  selectedRawInflowItems: RawInflowItem[] = [];
  fetchingRawInflowItems: boolean;
  cols: any[];
  fetchedText: string = "Resolved";
  rawInflowItemInView: RawInflowItem;
  openPostingDialogue: boolean;
  whtValue: number;
  vatOutput: number;
  onePercentLocal: number;
  allCurrencies: Currency[] = [];
  allExchangeRates: CurrencyExchangeRate[] = [];
  isDefaultPosting: boolean;
  theClient: CustomerDivision;
  theQuoteCurrency: Currency;
  theExchangeRateToUse: CurrencyExchangeRate;
  allBooleanOptions: BooleanOption[] = [
    {
      key: false,
      value: "No",
    },
    {
      key: true,
      value: "Yes",
    },
  ];
  theBooleanOption: BooleanOption = this.allBooleanOptions[0];

  constructor(
    public currencyService: CurrencyService,
    public accountService: AccountService,
    private advancePaymentService: AdvancePaymentsService,
    private clientService: CustomerDivisionService,
    public messageService: MessageService,
    private breadcrumbService: BreadcrumbService,
    public confirmationService: ConfirmationService,
    private fileStorageService: FileStorageService
  ) {}

  ngOnInit(): void {
    this.breadcrumbService.setItems([
      {
        label: "Finance",
      },
      {
        label: "Raw Inflow",
      },
      {
        label: "Upload",
        routerLink: ["/home/finance/raw-inflow-upload"],
      },
    ]);

    this.bulkCols = [
      { field: "sn", header: "S/N" },
      { field: "depositor", header: "Depositor" },
      { field: "narration", header: "Narration" },
      { field: "accountNumber", header: "Bank (Halobiz Bank Account)" },
      { field: "amountPaid", header: "Amount Paid" },
      { field: "currencyId", header: "Currency Selected" },
      { field: "datePaid", header: "Date Paid" },
    ];

    this.cols = [
      { field: "depositor", header: "Depositor" },
      { field: "bankNarration", header: "Narration" },
      { field: "halobizBankAcount", header: "Bank (Halobiz Bank Account)" },
      { field: "amountPaid", header: "Amount Paid" },
      { field: "datePaid", header: "Date Paid" },
      { field: "isWHTDeducted", header: "Is WHT Deducted" },
      { field: "whtValue", header: "WHT Value" },
      { field: "isLocalDeducted", header: "Is 1% local Content Deducted" },
      { field: "localValue", header: "1% local Content Value" },
      { field: "isVatDeducted", header: "Is VAT Deducted" },
      { field: "vatValue", header: "VAT Value" },
      { field: "clientName", header: "Client" },
    ];

    this.errorCols = [
      { field: "sn", header: "S/N" },
      { field: "reason", header: "Error" },
      { field: "data", header: "Data" },
    ];

    this.FetchAllCurrencies();
    this.FetchAllExchangeRates();
    this.FetchAllClients();
    this.GetCashBookAccounts();
    this.ResetMessageToasters("No Upload errors");
  }

  ResetMessageToasters(msg: string) {
    this.msg = [];
    if (msg) {
      this.msg.push({
        severity: "info",
        summary: msg,
      });
    }

    this.msg2 = [];
    this.msg2.push({
      severity: "info",
      summary:
        "Showing " +
        this.fetchedText +
        " Total Count: " +
        this.allRawInflowItems.length +
        " | Kindly Note: Unconfirmed Raw Inflow Item(s) Will Be Made Available for Default Posting(s) After 30 Days From Upload Date.",
    });
  }

  async FetchAllClients() {
    this.fetchingRawInflowItems = true;
    this.clientService.allCustomerDivision().subscribe(
      async (data) => {
        this.allClients = data.responseData;
        this.FetchRawInflows();
      },
      (error) => {
        this.messageService.add({
          severity: "error",
          summary: "Failed",
          detail: error ?? "Some errors occurred",
        });
        this.ResetMessageToasters("No Upload errors");
        this.fetchingRawInflowItems = false;
      }
    );
  }

  async GetCashBookAccounts() {
    this.accountService.getCashBookAccounts().subscribe(
      async (res) => {
        this.cashBookAccounts = res.responseData;
      },
      (error) => {
        this.messageService.add({
          severity: "error",
          summary: "Failed",
          detail: "Connection error, Try again later",
        });
      }
    );
  }

  async FetchRawInflows() {
    this.fetchingRawInflowItems = true;
    this.allRawInflowItems = [];
    this.selectedRawInflowItems = [];
    this.advancePaymentService.GetUnpostedRawInflows().subscribe(
      async (data) => {
        if (data.responseCode != "00") {
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail: data.responseMsg,
          });
          this.fetchingRawInflowItems = false;
          this.ResetMessageToasters("No Upload errors");
          return;
        }

        this.allRawInflowItems = data.responseData;
        this.fetchedText = "Resolved";
        this.fetchingRawInflowItems = false;
        this.ResetMessageToasters("No Upload errors");
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch raw inflows at the moment.. Reason: [" + error
              ? error.error.message
              : "request failed - permission" + "]",
        });
        this.fetchingRawInflowItems = false;
        this.ResetMessageToasters("No Upload errors");
      }
    );
  }

  async ShowUnResolvedRawInflows() {
    this.fetchingRawInflowItems = true;
    this.allRawInflowItems = [];
    this.selectedRawInflowItems = [];
    this.advancePaymentService.GetUnsubmittedRawInflows().subscribe(
      async (data) => {
        if (data.responseCode != "00") {
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail: data.responseMsg,
          });
          this.fetchingRawInflowItems = false;
          this.ResetMessageToasters("No Upload errors");
          return;
        }

        this.allRawInflowItems = data.responseData;
        this.fetchedText = "UnResolved";
        this.fetchingRawInflowItems = false;
        this.ResetMessageToasters("No Upload errors");
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch raw inflows at the moment.. Reason: [" + error
              ? error.error.message
              : "request failed - permission" + "]",
        });
        this.fetchingRawInflowItems = false;
        this.ResetMessageToasters("No Upload errors");
      }
    );
  }

  async ShowAllRawInflows() {
    this.fetchingRawInflowItems = true;
    this.allRawInflowItems = [];
    this.selectedRawInflowItems = [];
    this.advancePaymentService.GetRawInflows().subscribe(
      async (data) => {
        if (data.responseCode != "00") {
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail: data.responseMsg,
          });
          this.fetchingRawInflowItems = false;
          this.ResetMessageToasters("No Upload errors");
          return;
        }

        this.allRawInflowItems = data.responseData;
        this.fetchedText = "All";
        this.fetchingRawInflowItems = false;
        this.ResetMessageToasters("No Upload errors");
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch raw inflows at the moment.. Reason: [" + error
              ? error.error.message
              : "request failed - permission" + "]",
        });
        this.fetchingRawInflowItems = false;
        this.ResetMessageToasters("No Upload errors");
      }
    );
  }

  async FetchAllCurrencies() {
    this.currencyService.GetAllCurrencies().subscribe(
      async (data) => {
        this.allCurrencies = data;
        this.theQuoteCurrency = this.allCurrencies.find(
          (x) => x.name == "Nigerian Naira"
        );
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all currencies at the moment.. Reason: [" + error
              ? error.error.message
              : "request failed - permission" + "]",
        });
        this.ResetMessageToasters("No Upload errors");
      }
    );
  }

  async FetchAllExchangeRates() {
    this.currencyService.GetAllCurrencyExchangeRates().subscribe(
      async (data) => {
        this.allExchangeRates = data;
        this.allExchangeRates.reverse();
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all exchange rates at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  checkMimeType2 = (event: EventInput): boolean => {
    const files = event.target.files[0];
    // list allow mime type
    const types = ["text/csv"];
    // loop access array
    if (!files) {
      this.messageService.add({
        severity: "error",
        summary: "Failed",
        detail: "Reset Occurred: Please select a CSV file.",
      });
      this.ResetMessageToasters("Reset Occurred: Please select a CSV file.");
      return false;
    }
    // compare file type find doesn't match
    if (types.every((type) => files.type !== type)) {
      // create error message and assign to container
      this.messageService.add({
        severity: "error",
        summary: "Failed",
        detail: "Image format not supported",
      });
      this.ResetMessageToasters("Image format not supported");
      return false;
    }
    return true;
  };

  checkFileSize2 = (event: EventInput) => {
    const files = event.target.files[0];
    if (!files) return true;
    const size = 400000;
    if (files.size > size) {
      event.target.value = null;
      this.messageService.add({
        severity: "error",
        summary: "Failed",
        detail: "Image is too large, please pick a smaller file",
      });
      this.ResetMessageToasters(
        "Image is too large, please pick a smaller file"
      );
      return false;
    }
    return true;
  };

  onBulkFileSelect(event) {
    this.uploadContents = [];
    this.errorLists = [];
    this.file = null;
    if (!this.checkMimeType2(event) || !this.checkFileSize2(event)) {
      return;
    }

    const reader = new FileReader();
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      this.file = event.target.files[0];
      reader.readAsText(file);
      reader.onload = () => {
        // Entire file
        const text = reader.result as string;
        // By lines
        var lines = text.split("\n");
        for (var line = 1; line < lines.length; line++) {
          let lineStrArr = lines[line].split(",");
          try {
            let cashAccount = this.cashBookAccounts.find(
              (x) => x.accountNumber == lineStrArr[2]
            );
            if (cashAccount) {
              let currencyToUse = this.allCurrencies.find(
                (x) => x.id == +lineStrArr[5]
              );
              if (currencyToUse) {
                this.uploadContents.push({
                  sn: line + 1,
                  depositorName: lineStrArr[0],
                  bankNarration: lineStrArr[1],
                  bankAccount:
                    cashAccount.name + "-" + cashAccount.accountNumber,
                  bankAccountId: cashAccount.id,
                  amountPaid: +lineStrArr[3],
                  currencyId: +lineStrArr[5],
                  datePaid: new Date(lineStrArr[4]),
                });
              } else {
                this.errorLists.push({
                  sn: this.errorLists.length + 1,
                  reason:
                    "Line " +
                    (line + 1) +
                    " skipped, invalid currency selected (MUST BE VALID CURRENCY) -" +
                    lineStrArr[0],
                  data: lines[line],
                });
              }
            } else {
              this.errorLists.push({
                sn: this.errorLists.length + 1,
                reason:
                  "Line " +
                  (line + 1) +
                  " skipped, invalid halobiz bank account info (MUST BE VALID CASHBOOK ACCOUNT) -" +
                  lineStrArr[0],
                data: lines[line],
              });
            }
          } catch {
            this.errorLists.push({
              sn: this.errorLists.length + 1,
              reason: "Exception occurred",
              data: lines[line],
            });
          }
        }
      };
    }
  }

  ShowErrorDialogue() {
    this.openErrorDialogue = true;
  }

  HideErrorDialog() {
    this.openErrorDialogue = false;
  }

  FormatDateString(date: Date): string {
    let dateString = date.toString();

    try {
      // DateTimeFormatOptions
      const date: Date = new Date(dateString);
      const options: Intl.DateTimeFormatOptions = {
        year: "numeric",
        month: "long",
        day: "numeric",
      };
      const formattedDate: string = new Intl.DateTimeFormat(
        "en-US",
        options
      ).format(date);
      dateString = formattedDate;
    } catch (error) {
      console.log(error);
      console.log(date);
      console.log(
        "Error while converting date string " + date + " exception " + error
      );
    }

    return dateString;
  }

  ClearUpload() {
    this.file = null;
    this.uploadContents = [];
    this.errorLists = [];
    this.hiddenfileinput.nativeElement.value = "";
    this.processingBulkUpload = false;
  }

  SaveUploadedContents() {
    this.confirmationService.confirm({
      header: "Confirmation Dialog",
      icon: "pi pi-exclamation-triangle",
      message:
        "You are about to save uploaded raw inflow items, it's is an irreversible action. Do you still wish to proceed?",
      acceptLabel: "Yes, Please Proceed",
      rejectLabel: "No, Don't Proceed",
      accept: async () => {
        this.messageService.add({
          severity: "info",
          summary: "Notice",
          detail: "Saving Upload Raw Inflow Item......",
        });
        this.ResetMessageToasters("No Upload errors");

        const postData: UploadRawInflowItemsVM = {
          items: this.uploadContents,
        };

        this.processingBulkUpload = true;
        this.advancePaymentService.UploadRawInflowItems(postData).subscribe(
          async (data) => {
            if (data.responseCode != "00") {
              this.processingBulkUpload = false;
              this.messageService.add({
                severity: "error",
                summary: "Notice",
                detail: data.responseMsg,
              });
              this.ResetMessageToasters(data.responseMsg);
              return;
            }

            this.messageService.add({
              severity: "success",
              summary: "Removed",
              detail: "Bulk Raw Inflow Items Upload Successful",
            });
            this.ResetMessageToasters(
              "Bulk Raw Inflow Items Upload Successful"
            );

            this.ClearUpload();
          },
          (error) => {
            console.log("Error: " + JSON.stringify(error));
            this.messageService.add({
              severity: "error",
              summary: "Notice",
              detail:
                "Unable to run bulk upload at the moment.. Reason: [" + error
                  ? error.error.message
                  : "request failed - permission" + "]",
            });
            this.processingBulkUpload = false;
            this.ResetMessageToasters("Upload Error");
          }
        );
      },
    });
  }

  GetClientName(clientId: number) {
    let client = this.allClients.find((x) => x.id == clientId);
    if (client) return client.divisionName;

    return "N/A";
  }

  PostRawDataInflow() {
    let item = this.rawInflowItemInView;
    if (!this.theBooleanOption) {
      this.theBooleanOption = this.allBooleanOptions[0];
    }

    if (this.isDefaultPosting && !this.theClient) {
      this.messageService.add({
        severity: "error",
        summary: "Please specify client before proceeding.",
      });
      this.ResetMessageToasters("No Upload Errors!");
      return;
    }
    if (item.isWHTDeducted && !this.whtValue) {
      this.messageService.add({
        severity: "error",
        summary: "Please provide WHT Value before proceeding.",
      });
      this.ResetMessageToasters("No Upload Errors!");
      return;
    }
    if (item.isLocalDeducted && !this.onePercentLocal) {
      this.messageService.add({
        severity: "error",
        summary: "Please provide 1% Local Content Value before proceeding.",
      });
      this.ResetMessageToasters("No Upload Errors!");
      return;
    }
    if (item.isVatDeducted && !this.vatOutput) {
      this.messageService.add({
        severity: "error",
        summary: "Please provide Vat Output before proceeding.",
      });
      this.ResetMessageToasters("No Upload Errors!");
      return;
    }

    if (!this.theQuoteCurrency) {
      this.messageService.add({
        severity: "error",
        summary: "Error can't find quote currency 'Nigerian Naira'",
      });
      this.ResetMessageToasters("No Upload Errors!");
      return;
    }

    if (item.currencyId != this.theQuoteCurrency.id) {
      this.theExchangeRateToUse = this.allExchangeRates.find(
        (x) =>
          x.baseCurrencyId == item.currencyId &&
          x.quoteCurrencyId == this.theQuoteCurrency.id
      );
      if (this.theExchangeRateToUse == null) {
        this.messageService.add({
          severity: "error",
          summary:
            "Error can't find currency exchange rate to use. Base Currency ID -> " +
            item.currencyId +
            "  against Quote Currency ID -> " +
            this.theQuoteCurrency.id,
        });
        this.ResetMessageToasters("No Upload Errors!");
        return;
      }
    }

    this.confirmationService.confirm({
      header: "Confirmation Dialog",
      icon: "pi pi-exclamation-triangle",
      message:
        "You are about to post raw inflow item, it's is an irreversible action. Do you still wish to proceed?",
      acceptLabel: "Yes, Please Proceed",
      rejectLabel: "No, Don't Proceed",
      accept: async () => {
        this.messageService.add({
          severity: "info",
          summary: "Notice",
          detail: "Submitting Raw Inflow Item......",
        });
        this.ResetMessageToasters("No Upload Errors!");

        const postData: PostRawInflowItemVM = {
          rawInflowItemId: item.id,
          whtValue: this.whtValue,
          onePercentValue: this.onePercentLocal,
          vatOutput: this.vatOutput,
          applyAutomaticSettlement: this.theBooleanOption.key,
        };
        if (this.isDefaultPosting) {
          postData.clientId = this.theClient.id;
        }
        if (this.theExchangeRateToUse) {
          postData.exchangeRateUsedId = this.theExchangeRateToUse.id;
          postData.actualValuePostingWith =
            item.amountPaid * this.theExchangeRateToUse.exchangeRate;
          if (postData.whtValue) {
            postData.whtValue =
              postData.whtValue * this.theExchangeRateToUse.exchangeRate;
          }
          if (postData.onePercentValue) {
            postData.onePercentValue =
              postData.onePercentValue * this.theExchangeRateToUse.exchangeRate;
          }
          if (postData.vatOutput) {
            postData.vatOutput =
              postData.vatOutput * this.theExchangeRateToUse.exchangeRate;
          }
        }

        this.advancePaymentService.PostRawInflowItem(postData).subscribe(
          async (data) => {
            if (data.responseCode != "00") {
              this.messageService.add({
                severity: "error",
                summary: data.responseMsg,
              });
              this.ResetMessageToasters("No Upload Errors!");

              if (data.responseMsg.includes("Do not repeat action")) {
                this.EndItemPosting();
                const index = this.allRawInflowItems.indexOf(item);
                if (index > -1) {
                  this.allRawInflowItems.splice(index, 1);
                }
                this.fetchingRawInflowItems = true;
                this.fetchingRawInflowItems = false;
              }
              return;
            }

            this.EndItemPosting();
            const index = this.allRawInflowItems.indexOf(item);
            if (index > -1) {
              this.allRawInflowItems.splice(index, 1);
            }
            this.fetchingRawInflowItems = true;
            this.messageService.add({
              severity: "success",
              summary: "Removed",
              detail: "Raw Inflow Item Posted Successful",
            });
            this.fetchingRawInflowItems = false;
            this.ResetMessageToasters("No Upload Errors!");
          },
          (error) => {
            console.log("Error: " + JSON.stringify(error));
            this.messageService.add({
              severity: "error",
              summary: "Notice",
              detail:
                "Unable to post raw inflow item at the moment.. Reason: [" +
                error
                  ? error.error.message
                  : "request failed - permission" + "]",
            });
            this.ResetMessageToasters("No Upload Errors!");
          }
        );
      },
    });
  }

  StartItemPosting(item: RawInflowItem, isDefault: boolean = false) {
    this.rawInflowItemInView = item;
    this.theBooleanOption = this.allBooleanOptions[0];

    if (
      !item.isWHTDeducted &&
      !item.isLocalDeducted &&
      !item.isLocalDeducted &&
      !isDefault &&
      !this.theBooleanOption
    ) {
      this.PostRawDataInflow();
    } else {
      this.openPostingDialogue = true;
      this.isDefaultPosting = isDefault;
      this.whtValue = item.whtValue;
      this.onePercentLocal = item.localValue;
      this.vatOutput = item.vatValue;
    }
  }

  EndItemPosting() {
    this.openPostingDialogue = false;
    this.isDefaultPosting = false;
    this.theExchangeRateToUse = null;
    this.rawInflowItemInView = null;
    this.whtValue = null;
    this.onePercentLocal = null;
    this.theClient = null;
    this.vatOutput = null;
  }

  GetCurrencyName(currencyId: number): string {
    let currency = this.allCurrencies.find((x) => x.id == currencyId);
    if (currency) return currency.name;

    return "N/A";
  }

  GetCurrencySymbol(currencyId: number): string {
    let currency = this.allCurrencies.find((x) => x.id == currencyId);
    if (currency) return currency.symbol;

    return "~";
  }

  PostAllSelectedRawInflows(applyAutomaticSettlement: boolean) {
    this.confirmationService.confirm({
      message:
        "Kindly note that items requiring VAT Output and 1% Local Content that has not been captured will be defaulted to zero. This is an irreversible action, do you still wish to proceed?",
      header: "Confirmation",
      icon: "pi pi-exclamation-triangle",
      acceptLabel: applyAutomaticSettlement
        ? "Post With Automatic Settlement"
        : "Post Without Automatic Settlement",
      rejectLabel: "Cancel",
      accept: () => {
        this.messageService.add({
          severity: "info",
          summary: "Notice",
          detail: applyAutomaticSettlement
            ? "Posting Selected Raw Inflow(s) With Automatic Settlement......"
            : "Posting Selected Raw Inflow(s) Without Automatic Settlement......",
        });
        this.ResetMessageToasters("No Upload Errors!");

        const postData: PostMultipleRawInflowItemVM = {
          rawInflowItemIds: this.selectedRawInflowItems.map((x) => x.id),
          applyAutomaticSettlement: applyAutomaticSettlement,
        };
        this.advancePaymentService
          .PostMultipleRawInflowItem(postData)
          .subscribe(
            async (data) => {
              if (data.responseCode != "00") {
                this.messageService.add({
                  severity: "error",
                  summary: data.responseMsg,
                });
                this.ResetMessageToasters("No Upload Errors!");

                if (data.responseMsg.includes("Do not repeat action")) {
                  this.EndItemPosting();
                  this.EndItemPosting();
                  this.ShowUnResolvedRawInflows();
                }
                return;
              }

              this.messageService.add({
                severity: "success",
                summary: "Removed",
                detail: "Raw Inflow Item Posted Successful",
              });
              this.ResetMessageToasters("No Upload Errors!");
              this.EndItemPosting();
              this.ShowUnResolvedRawInflows();
            },
            (error) => {
              console.log("Error: " + JSON.stringify(error));
              this.messageService.add({
                severity: "error",
                summary: "Notice",
                detail:
                  "Unable to post raw inflow item at the moment.. Reason: [" +
                  error
                    ? error.error.message
                    : "request failed - permission" + "]",
              });
              this.ResetMessageToasters("No Upload Errors!");
            }
          );
      },
    });
  }
}
