import {
  GuardDeployment,
  Beat,
  AssignGuardDeploymentsToBeat,
} from "./../../../interfaces/employment";
import { CustomerDivision, Location } from "./../../../interfaces/home";
import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MessageService, ConfirmationService } from "primeng/api";
import { BreadcrumbService } from "src/app/breadcrumb.service";
import { GmaEmploymentService } from "src/app/services/gma-employment.service";

@Component({
  selector: "app-gma-beat-allocation",
  templateUrl: "./gma-beat-allocation.component.html",
  styleUrls: ["./gma-beat-allocation.component.scss"],
})
export class GmaBeatAllocationComponent implements OnInit {
  @ViewChild("formWrapper") public formWrapper: ElementRef;
  beatForm: FormGroup;
  allClients: CustomerDivision[] = [];
  theClient: CustomerDivision;
  allLocations: Location[] = [];
  theLocation: Location;
  allLocationOperatives: GuardDeployment[] = [];
  selectedLocationOperative: GuardDeployment;
  cols: any[];
  allSelectedOperatives: GuardDeployment[] = [];
  allLocationBeats: Beat[] = [];
  theLocationBeat: Beat;
  beatImage: string;
  loadingOPs: boolean = false;

  constructor(
    fb: FormBuilder,
    public employmentService: GmaEmploymentService,
    public messageService: MessageService,
    private breadcrumbService: BreadcrumbService,
    public confirmationService: ConfirmationService
  ) {
    this.beatForm = fb.group({
      BeatName: ["", Validators.required],
      BeatType: ["", Validators.required],
    });
  }

  ngOnInit(): void {
    this.breadcrumbService.setItems([
      {
        label: "Guard Management",
        routerLink: ["/home/guard-management/item-settings"],
      },
      {
        label: "Beat Allocation",
        routerLink: ["/home/guard-management/gma-beat-allocation"],
      },
    ]);

    this.cols = [
      {
        field: "name",
        header: "Name",
      },
    ];

    this.FetchAllClients();
  }

  FetchAllClients() {
    this.employmentService.GetAllClientsBeatAllocate().subscribe(
      async (data) => {
        this.allClients = data;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable get all clients at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  PopulateLocationBeatList(locationId: number) {
    this.employmentService.GetAllLocationBeats(locationId).subscribe(
      async (data) => {
        this.allLocationBeats = data;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable get all location beats at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  ClientSelectionLoader() {
    this.allLocations = this.theClient.locations;
  }

  LocationSelectionLoader() {
    // this.allLocationOperatives = this.theLocation.guardDeployments.filter(
    //   (x) => x.isDeleted == false
    // );

    console.log("Hit 1");

    if (this.theLocation) {
      console.log("Hit 2");
      if (!this.theLocation.guardDeployments[0].isLoaded) {
        console.log("Hit 3");
        this.loadingOPs = true;
        this.employmentService
          .GetAllClientsBeatAllocateLocDits(this.theLocation.id)
          .subscribe(
            async (data) => {
              console.log("Hit 4");
              this.allLocationOperatives = data;
              this.loadingOPs = false;
              this.theLocation.guardDeployments = data;
              this.theLocation.guardDeployments[0].isLoaded = true;
              this.PopulateLocationBeatList(this.theLocation.id);
            },
            (error) => {
              console.log("Error: " + JSON.stringify(error));
              this.messageService.add({
                severity: "error",
                summary: "Notice",
                detail:
                  "Unable get location deployments at the moment.. Reason: [" +
                  error
                    ? error.error.message
                    : "request failed - permission" + "]",
              });
              this.loadingOPs = false;
            }
          );
      } else {
        this.PopulateLocationBeatList(this.theLocation.id);
      }
    }

    //this.PopulateLocationBeatList(this.theLocation.id);
  }

  PickOperative(item: GuardDeployment) {
    let index = this.allSelectedOperatives.indexOf(item);
    if (index == -1) this.allSelectedOperatives.push(item);
  }

  RemoveSelectedOperative(item: GuardDeployment) {
    let index = this.allSelectedOperatives.indexOf(item);
    if (index > -1) {
      this.allSelectedOperatives.splice(index, 1);
    }
  }

  BeatSelectionLoader() {
    if (this.theLocationBeat) {
      this.beatForm.patchValue({
        BeatName: this.theLocationBeat.caption,
        BeatType: this.theLocationBeat.beatType.caption,
      });
      this.beatImage = this.theLocationBeat.beatType.visual.split(",")[0];
    } else {
      this.beatForm.reset();
      this.beatImage = "";
    }
  }

  AssignOperativesToBeat() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Assign Guards To Client Location Beat...",
    });

    const postData: AssignGuardDeploymentsToBeat = {
      beatId: this.theLocationBeat.id,
      guardDeploymentIds: this.allSelectedOperatives.map((x) => x.id),
    };

    this.employmentService.AssignGuardDeploymentsToBeat(postData).subscribe(
      () => {
        this.messageService.add({
          severity: "success",
          summary: "Notice",
          detail: "Guards assigned to Beat Successful!",
        });

        this.allSelectedOperatives.forEach((op) => {
          op.beatAssignments = [
            {
              beatAssignedToId: this.theLocationBeat.id,
              beatAssignedTo: this.theLocationBeat,
            },
          ];
        });

        this.beatForm.reset();
        this.beatForm = null;
        this.theLocationBeat = null;
        this.allSelectedOperatives = [];
        this.FetchAllClients();
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to assign guards to beat at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }
}
