import {Component, Input, OnInit} from '@angular/core';
import {LeadService} from '../../../services/lead.service';
import {DropReason, Lead} from '../../../interfaces/home';
import {MessageService} from 'primeng/api';
import {ActivatedRoute} from '@angular/router';
import {DropReasonService} from '../../../services/drop-reason.service';
import {DialogService, DynamicDialogRef} from 'primeng/dynamicdialog';
import {LeadDropFormComponent} from './lead-drop-form/lead-drop-form.component';

@Component({
  selector: 'app-lead-drop',
  templateUrl: './lead-drop.component.html',
  styleUrls: ['./lead-drop.component.scss'],
  providers: [DialogService],
})
export class LeadDropComponent implements OnInit {
  @Input() currentLead: Lead = null;
  leadRef: string = null;
  dropReasons: DropReason[] = [];
  leadDropRef: DynamicDialogRef;
  constructor(
      private leadService: LeadService,
      private messageService: MessageService,
      private route: ActivatedRoute,
      private dropReasonService: DropReasonService,
      private dialogService: DialogService,
              ) { }
    ngOnInit():void {
  }

  async dropLead(){
    this.leadDropRef =  await this.dialogService.open(LeadDropFormComponent, {
      header: 'Confirm Lead Drop',
      width: '536px',
      contentStyle: {'min-height': '120px', overflow: 'auto'},
      baseZIndex: 100001,
      data: {lead: this.currentLead, dropReason: this.dropReasons},
    });
    await this.leadDropRef.onClose.subscribe( res => {
      if(res === 'complete'){
        // refresh the data in the lead service
      } else{
        this.messageService.add({
          severity: 'info',
          summary: 'cancelled',
          detail: 'Lead Drop Cancelled'
        })
      }
    })
  }

}
