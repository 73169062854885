import { GmaEmploymentService } from "./../../../services/gma-employment.service";
import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { MessageService, ConfirmationService } from "primeng/api";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: "app-gma-confirm-distribution",
  templateUrl: "./gma-confirm-distribution.component.html",
  styleUrls: ["./gma-confirm-distribution.component.scss"],
})
export class GmaConfirmDistributionComponent implements OnInit {
  @ViewChild("formWrapper") public formWrapper: ElementRef;
  message: string;

  constructor(
    private route: ActivatedRoute,
    public employmentService: GmaEmploymentService,
    public messageService: MessageService,
    public confirmationService: ConfirmationService
  ) {}

  ngOnInit(): void {
    let materialConfirmationId = +this.route.snapshot.paramMap.get("id");

    this.message = this.getTimeOfDay();
    this.ConfirmItemDistribution(materialConfirmationId);
  }

  getTimeOfDay(): string {
    const now = new Date();
    const hour = now.getHours();

    let shift = "Good ";
    shift += hour >= 4 && hour <= 11 ? "Morning" : "";
    shift += hour >= 12 && hour <= 16 ? "Afternoon" : "";
    shift += hour >= 17 && hour <= 20 ? "Evening" : "";
    shift += hour >= 21 || hour <= 3 ? "Evening" : "";
    return shift;
  }

  ConfirmItemDistribution(materialConfirmationId: number) {
    this.employmentService
      .ConfirmMaterialRequestItemDistribution(materialConfirmationId)
      .subscribe(
        () => {
          this.messageService.add({
            severity: "success",
            summary: "Notice",
            detail: "Confirmed Successfully!",
          });
        },
        (error) => {
          console.log("Error: " + JSON.stringify(error));
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail:
              "Unable to confirm distribution at the moment, Kindly try again.. Reason: [" +
              error
                ? error.error.message
                : "request failed - permission" + "]",
          });
        }
      );
  }
}
