import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { MessageService, ConfirmationService, Message } from "primeng/api";
import { BreadcrumbService } from "src/app/breadcrumb.service";
import { Currency } from "src/app/interfaces/currency";
import {
  BooleanOption,
  CustomerDivision,
  RawInflowItem,
  SubmitRawInflowItemVM,
} from "src/app/interfaces/home";
import { AdvancePaymentsService } from "src/app/services/advance-payments.service";
import { CurrencyService } from "src/app/services/currency.service";
import { CustomerDivisionService } from "src/app/services/customer-division.service";
import { FileStorageService } from "src/app/services/file-storage.service";

@Component({
  selector: "app-raw-inflow-confirmation",
  templateUrl: "./raw-inflow-confirmation.component.html",
  styleUrls: ["./raw-inflow-confirmation.component.scss"],
  providers: [MessageService],
})
export class RawInflowConfirmationComponent implements OnInit {
  @ViewChild("formWrapper") public formWrapper: ElementRef;
  msg: Message[] = [];
  allRawInflowItems: RawInflowItem[] = [];
  fetchingInflows: boolean;
  cols: any[];
  BooleanOptions: BooleanOption[] = [
    {
      key: true,
      value: "YES",
    },
    {
      key: false,
      value: "NO",
    },
  ];
  allClients: CustomerDivision[] = [];
  rawInflowItemInView: RawInflowItem;
  openPostingDialogue: boolean;
  whtValue: number;
  vatOutput: number;
  onePercentLocal: number;
  allCurrencies: Currency[] = [];
  paymentDate: Date;
  additonalDesc: string;

  constructor(
    public currencyService: CurrencyService,
    private clientService: CustomerDivisionService,
    private advancePaymentService: AdvancePaymentsService,
    public messageService: MessageService,
    private breadcrumbService: BreadcrumbService,
    public confirmationService: ConfirmationService,
    private fileStorageService: FileStorageService
  ) {}

  ngOnInit(): void {
    this.breadcrumbService.setItems([
      {
        label: "Finance",
      },
      {
        label: "Raw Inflow",
      },
      {
        label: "Confirmation",
        routerLink: ["/home/finance/raw-inflow-confirmation"],
      },
    ]);

    this.cols = [
      { field: "depositor", header: "Depositor" },
      { field: "bankNarration", header: "Narration" },
      { field: "halobizBankAcount", header: "Bank (Halobiz Bank Account)" },
      { field: "amountPaid", header: "Amount Paid" },
      { field: "datePaid", header: "Date Paid" },
      { field: "isWHTDeducted", header: "Is WHT Deducted" },
      { field: "isLocalDeducted", header: "Is 1% local Content Deducted" },
      { field: "isVatDeducted", header: "Is VAT Deducted" },
      { field: "clientName", header: "Client" },
    ];

    this.FetchAllCurrencies();
    this.FetchAllClients();
    this.FetchRawInflows();
    this.ResetMessageToasters();
  }

  ResetMessageToasters() {
    this.msg = [];
    this.msg.push({
      severity: "info",
      summary:
        "You have a total of " +
        this.allRawInflowItems.length +
        " inflow items yet to be identified.",
    });
  }

  async FetchAllClients() {
    this.clientService.allCustomerDivision().subscribe(
      async (data) => {
        this.allClients = data.responseData;
      },
      (error) => {
        this.messageService.add({
          severity: "error",
          summary: "Failed",
          detail: error ?? "Some errors occurred",
        });
        this.ResetMessageToasters();
      }
    );
  }

  async FetchRawInflows() {
    this.fetchingInflows = true;
    this.advancePaymentService.GetUnsubmittedRawInflows().subscribe(
      async (data) => {
        if (data.responseCode != "00") {
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail: data.responseMsg,
          });
          this.fetchingInflows = false;
          this.ResetMessageToasters();
          return;
        }

        this.allRawInflowItems = data.responseData;
        this.fetchingInflows = false;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch raw inflows at the moment.. Reason: [" + error
              ? error.error.message
              : "request failed - permission" + "]",
        });
        this.fetchingInflows = false;
        this.ResetMessageToasters();
      }
    );
  }

  async FetchAllCurrencies() {
    this.currencyService.GetAllCurrencies().subscribe(
      async (data) => {
        this.allCurrencies = data;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all currencies at the moment.. Reason: [" + error
              ? error.error.message
              : "request failed - permission" + "]",
        });
        this.ResetMessageToasters();
      }
    );
  }

  DefineRawDataInflow() {
    let item = this.rawInflowItemInView;
    if (!item.client) {
      this.messageService.add({
        severity: "error",
        summary: "Please select client before submitting.",
      });
      this.ResetMessageToasters();
      return;
    }
    if (item.isWHTDeductedOption?.key && !this.whtValue) {
      this.messageService.add({
        severity: "error",
        summary: "Please provide WHT Value before proceeding.",
      });
      this.ResetMessageToasters();
      return;
    }

    let total = 0;
    if (this.whtValue) {
      if (this.whtValue > item.amountPaid) {
        this.messageService.add({
          severity: "error",
          summary: "WHT Value can't be greater than amount paid.",
        });
        this.ResetMessageToasters();
        return;
      }
      total += this.whtValue;
    }

    if (this.onePercentLocal) {
      if (this.onePercentLocal > item.amountPaid) {
        this.messageService.add({
          severity: "error",
          summary: "1% Local Content Value can't be greater than amount paid.",
        });
        this.ResetMessageToasters();
        return;
      }
      total += this.onePercentLocal;
    }

    if (this.vatOutput) {
      if (this.vatOutput > item.amountPaid) {
        this.messageService.add({
          severity: "error",
          summary: "Vat output can't be greater than amount paid.",
        });
        this.ResetMessageToasters();
        return;
      }
      total += this.vatOutput;
    }

    if (total > item.amountPaid) {
      this.messageService.add({
        severity: "error",
        summary: "Error: Sum of Value(s) can't be greater than amount paid.",
      });
      this.ResetMessageToasters();
      return;
    }

    if (this.paymentDate == null) {
      this.messageService.add({
        severity: "error",
        summary: "Error: Please provide period payment is for.",
      });
      this.ResetMessageToasters();
      return;
    }

    this.confirmationService.confirm({
      header: "Confirmation Dialog",
      icon: "pi pi-exclamation-triangle",
      message:
        "You are about to submit raw inflow item definition, it's is an irreversible action. Do you still wish to proceed?",
      acceptLabel: "Yes, Please Proceed",
      rejectLabel: "No, Don't Proceed",
      accept: async () => {
        this.messageService.add({
          severity: "info",
          summary: "Notice",
          detail: "Submitting Raw Inflow Item......",
        });
        this.ResetMessageToasters();

        const postData: SubmitRawInflowItemVM = {
          rawInflowItemId: item.id,
          isWHTDeducted: item.isWHTDeductedOption?.key ?? false,
          isLocalDeducted: item.isLocalDeductedOption?.key ?? false,
          isVatDeducted: item.isVatDeductedOption?.key ?? false,
          clientId: item.client.id,
          whtValue: this.whtValue,
          onePercentValue: this.onePercentLocal,
          vatOutput: this.vatOutput,
          paymentDate: this.paymentDate,
          additionalDescription: this.additonalDesc,
        };

        this.advancePaymentService.SubmitRawInflowItem(postData).subscribe(
          async (data) => {
            if (data.responseCode != "00") {
              this.messageService.add({
                severity: "error",
                summary: data.responseMsg,
              });
              this.ResetMessageToasters();
              return;
            }

            this.EndItemDefinition();
            const index = this.allRawInflowItems.indexOf(item);
            if (index > -1) {
              this.allRawInflowItems.splice(index, 1);
            }
            this.fetchingInflows = true;
            this.messageService.add({
              severity: "success",
              summary: "Removed",
              detail: "Raw Inflow Item Submitted Successful",
            });
            this.fetchingInflows = false;
            this.ResetMessageToasters();
          },
          (error) => {
            console.log("Error: " + JSON.stringify(error));
            this.messageService.add({
              severity: "error",
              summary: "Notice",
              detail:
                "Unable to submit raw inflow item at the moment.. Reason: [" +
                error
                  ? error.error.message
                  : "request failed - permission" + "]",
            });
            this.ResetMessageToasters();
          }
        );
      },
    });
  }

  SetClientName(item: RawInflowItem) {
    if (item.client) {
      item.clientName = item.client.divisionName;
    }
  }

  FormatDateString(date: Date): string {
    let dateString = date.toString();

    try {
      // DateTimeFormatOptions
      const date: Date = new Date(dateString);
      const options: Intl.DateTimeFormatOptions = {
        year: "numeric",
        month: "long",
        day: "numeric",
      };
      const formattedDate: string = new Intl.DateTimeFormat(
        "en-US",
        options
      ).format(date);
      dateString = formattedDate;
    } catch (error) {
      console.log(error);
      console.log(date);
      console.log(
        "Error while converting date string " + date + " exception " + error
      );
    }

    return dateString;
  }

  StartRawItemDefine(item: RawInflowItem) {
    this.rawInflowItemInView = item;
    let hasRequired = true;

    if (
      !item.isWHTDeductedOption?.key &&
      !item.isLocalDeductedOption?.key &&
      !item.isVatDeductedOption?.key &&
      !hasRequired
    ) {
      this.DefineRawDataInflow();
    } else {
      this.openPostingDialogue = true;
    }
  }

  EndItemDefinition() {
    this.openPostingDialogue = false;
    this.rawInflowItemInView = null;
    this.whtValue = null;
    this.onePercentLocal = null;
    this.vatOutput = null;
    this.paymentDate = null;
    this.additonalDesc = null;
  }

  GetCurrencyName(currencyId: number): string {
    let currency = this.allCurrencies.find((x) => x.id == currencyId);
    if (currency) return currency.name;

    return "N/A";
  }

  GetCurrencySymbol(currencyId: number): string {
    let currency = this.allCurrencies.find((x) => x.id == currencyId);
    if (currency) return currency.symbol;

    return "~";
  }
}
