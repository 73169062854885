import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {Lead, CommonResponse} from '../interfaces/home';
import {Router} from '@angular/router';
import {MessageService} from 'primeng/api';

@Injectable({
  providedIn: 'root'
})
export class LeadService {
  edLead: any;
  private _isRetail : boolean;
  private leadCreationComplete = new Subject<any>();
  private paymentComplete = new Subject<any>();
  public leadData = new BehaviorSubject<any>(null);
  leadData$ = this.leadData.asObservable();
  paymentComplete$ = this.paymentComplete.asObservable();
  requalifyService: boolean;

  constructor(
      private httpClient: HttpClient,
      private router: Router,
      private messageService: MessageService
  ) { }
  baseUrl = environment.baseUrl + 'Lead/';
  allLeads(): Observable<CommonResponse>{
    return this.httpClient.get<CommonResponse>(this.baseUrl);
  }
  allUserLeads(): Observable<CommonResponse>{
    return this.httpClient.get<CommonResponse>(this.baseUrl + 'GetUserLeads');
  }
  createLead(data){
    return this.httpClient.post(this.baseUrl, data);
  }
  getUnApprovedLeads(): Observable<CommonResponse>{
    return this.httpClient.get<CommonResponse>(this.baseUrl + 'GetUnApprovedLeads');
  }
  getLeadById(id: any){
    return this.httpClient.get(this.baseUrl + id);
  }
  updateLead(data, id){
    return this.httpClient.put(this.baseUrl + id, data);
  }
  getLeadByRef(ref){
    return this.httpClient.get(this.baseUrl + 'ReferenceNumber/' + ref);
  }

 
  updateLeadCaptured(id, data) {
    return this.httpClient.put(this.baseUrl + id +'/UpdateLeadCaptured/', data);
  }
  updateLeadQualification(id){
    return this.httpClient.put(this.baseUrl + id + '/UpdateLeadQualification', '');
  }
  updateLeadOpportunity(id){
    return this.httpClient.put(this.baseUrl + id + '/UpdateLeadOpportunity', '');
  }
  updateLeadClosure(id){
    return this.httpClient.put(this.baseUrl + id + '/UpdateLeadClosure', '');
  }
  convertLeadCustomer(id){
    return this.httpClient.put(this.baseUrl + 'ConvertLeadToClient/' + id, '');
  }
  setUpLeadForApprovals(id){
    return this.httpClient.put(this.baseUrl + 'SetUpLeadForApproval/' + id, '');
  }
  approveQuoteService(leadId, quoteServiceId, sequence){
    return this.httpClient.put(this.baseUrl + 'approve-quote-service/' + [leadId] + '/' + quoteServiceId + '/' + sequence, '');
  }
  disapproveQuoteService(leadId, quoteServiceId, sequence, comment){
    return this.httpClient.put(this.baseUrl + 'disapprove-quote-service/' + leadId + '/' + quoteServiceId + '/' + sequence + '/' + comment, '');
  }
  dropLead(id, data){
    return this.httpClient.put(this.baseUrl + id + '/DropLead', data);
  }
  searchLead(refCode: any): Observable<CommonResponse>{
    return this.httpClient.get<CommonResponse>(this.baseUrl + 'ReferenceNumber/' + refCode);
  }
  setCurrentLead(ref){
    this.getLeadByRef(ref).subscribe( async (res: any) => {
      this.startLead(res);
      // check the current state of the lead
      const _lead: Lead = res.responseData;
      // check if the lead has been drop
      if(_lead?.isLeadDropped){
        this.router.navigate(['/home/lead-admin/capture']).then(() => {
          this.messageService.add({
            severity: 'error',
            detail: 'These lead was dropped',
            summary: 'notice',
          });
        })
      }else if(_lead?.leadClosureStatus){
        this.router.navigate(['/home/lead-admin/capture']).then(() => {
          this.messageService.add({
            severity: 'error',
            detail: 'The selected lead has been closed',
            summary: 'notice',
          });
        })
      }else if(_lead?.leadCaptureStatus === false){
        // send the user to the next page
        this.navigateToPage('capture');
      } else if(_lead?.leadQualificationStatus === false){
        this.navigateToPage('qualification');
      }
       else if(_lead?.leadQualificationStatus === true && this.requalifyService){
        this.navigateToPage('qualification');
        this.requalifyService = false;
      } else if(_lead?.leadOpportunityStatus === false){
        this.navigateToPage('negotiation');
      } else if(_lead?.leadClosureStatus === false){
        if(_lead?.denialReason)
        { 
            //this guy was not denied approval, so force to conversion page
            if(this.router.url.includes("qualification")){
              this.navigateToPage('qualification');
            }else if(this.router.url.includes("negotiation")){
              this.navigateToPage('negotiation');
            }
            else{
              this.navigateToPage('conversion');
            }
        }else{
          this.navigateToPage('conversion');
        }
      } else {
        this.router.navigate(['/home/lead-admin/capture']).then(() => {
          this.messageService.add({
            severity: 'error',
            detail: 'This lead service is complete already',
            summary: 'Notice'
          });
        })
      }
    })
  }
  navigateToPage(page) {
    var referenceNo = "";
    if(this.edLead.responseData){
        referenceNo = this.edLead.responseData.referenceNo;
    }else{
       referenceNo = this.edLead.referenceNo;
    }
    this.router.navigate(['/home/lead-admin/'+page, referenceNo]).then( () => {
      this.messageService.add({
        severity: 'info',
        detail: 'Continue from where you stop from',
        summary: 'Welcome Back!'
      });
    });
    return;
  }
  // get lead from local storage
  startLead(edLead){
    this.edLead = edLead;
    return this.leadData.next(edLead);
  }
  activeLead():Observable<any> {
    return this.leadData$;
  }
  getCurrentLead(){
    return this.edLead;
  }
  refreshData(){
    // get active lead data
   this.setCurrentLead(this.edLead.referenceNo);
  }

  public get isRetail() : boolean {
    return this._isRetail;
  }
  public set isRetail(v : boolean) {
    this._isRetail = v;
  }
}
