import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { CommanderRank } from "src/app/interfaces/armada";
import { CommonResponse } from "src/app/interfaces/home";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class CommanderTypeService {
  baseUrl = environment.baseUrl + "Commander/";

  constructor(private httpClient: HttpClient) {}
  allCommanderType(): Observable<CommonResponse> {
    return this.httpClient.get<CommonResponse>(
      this.baseUrl + "GetAllCommanderTypes"
    );
  }
  getCommanderType(id: any): Observable<CommonResponse> {
    return this.httpClient.get<CommonResponse>(
      this.baseUrl + "GetTypeById/" + id
    );
  }
  // getCommanderTypeByName(name: any):Observable<CommonResponse>{
  //   return this.httpClient.get<CommonResponse>(this.baseUrl + 'name/' + name);
  // }
  postCommanderType(data: any) {
    return this.httpClient.post(this.baseUrl + "AddNewCommanderType", data);
  }
  delete(id: any) {
    return this.httpClient.delete(this.baseUrl + "DeleteTypeById/" + id);
  }
  updateCommanderType(id: any, data: any) {
    return this.httpClient.put(this.baseUrl + "UpdateTypeById/" + id, data);
  }
}
