import { Component, OnInit } from '@angular/core';
import {DynamicDialogConfig, DynamicDialogRef} from 'primeng/dynamicdialog';
import {Invoice, StrategicBusinessUnit, User} from '../../../../interfaces/home';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ConfirmationService} from 'primeng/api';

@Component({
    selector: 'app-key-personnel',
    templateUrl: './key-personnel.component.html',
    styleUrls: ['./key-personnel.component.css'],
    providers: [ConfirmationService]
})

export class KeyPersonnelComponent implements OnInit {

    keyPersonnels: any[];
    selectedKeyPersonnel: any;
    keyPersonCols: any;
    private keyPersonExportColumns: any;
    editable: boolean = false;

    constructor(public config: DynamicDialogConfig,
                public keyPersonnelModelRef: DynamicDialogRef,
                public confirmationService: ConfirmationService
    ) {}

    ngOnInit(): void {
        this.keyPersonnels = this.config.data.keyPersonnels;
        this.editable = this.config.data.editable || false;

        this.keyPersonCols = [
            { field: 'firstName', header: 'First Name' },
            { field: 'lastName', header: 'Last Name' },
            { field: 'mobileNumber', header: 'Mobile Number' },
            { field: 'email', header: 'Email' },
            { field: 'designation', header: 'Design' },
            { field: 'dateOfBirth', header: 'Date of Birth' }
        ];
        this.keyPersonExportColumns = this.keyPersonCols.map(col => ({title: col.header, dataKey: col.field}));
    }

    closeModal() {
        this.keyPersonnelModelRef.close();
    }

    editClicked(selectedKeyPersonnel){
        this.keyPersonnelModelRef.close(selectedKeyPersonnel);
    }
}
