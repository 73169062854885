import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { AccountVoucherType, CommonResponse } from "../../../interfaces/home";
import { ConfirmationService, MessageService } from "primeng/api";
import { AccountVoucherTypeService } from "../../../services/account-voucher-type.service";
import { BreadcrumbService } from "src/app/breadcrumb.service";

@Component({
  selector: "app-account-voucher-type",
  templateUrl: "./account-voucher-type.component.html",
  styleUrls: ["./account-voucher-type.component.scss"],
  providers: [ConfirmationService, MessageService],
})
export class AccountVoucherTypeComponent implements OnInit {
  @ViewChild("formWrapper") public formWrapper: ElementRef;
  fetchingAccountVoucherType = true;
  accountVoucherTypeForm: FormGroup;
  accountVoucherTypes: AccountVoucherType[];
  selectedAccountVoucherType: AccountVoucherType;
  editingAccountVoucherType = false;
  public accountVoucherTypeCols: any[];
  public exportAccountVoucherTypeColumns: any[];
  private edAccountVoucherType: AccountVoucherType;
  isAllowedForStandalone: boolean = false;
  isApplicableForNotification: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private accountVoucherTypeService: AccountVoucherTypeService,
    public messageService: MessageService,
    public confirmationService: ConfirmationService,
    private breadcrumbService: BreadcrumbService
  ) {}

  ngOnInit(): void {
    this.breadcrumbService.setItems([
      { label: "Qualification", routerLink: ["home/qualification"] },
      {
        label: "Account Voucher Type",
        routerLink: ["home/qualification/account-voucher-type"],
      },
    ]);

    this.fetchAccountVoucherType();
    this.accountVoucherTypeForm = this.formBuilder.group({
      description: ["", Validators.required],
      alias: ["", Validators.required],
      voucherType: ["", [Validators.required]],
      IsAllowedForStandalone: [""],
      isApplicableForNotification: [""],
    });
    this.accountVoucherTypeCols = [
      { field: "voucherType", header: "Voucher Type" },
      { field: "alias", header: "Alias" },
      { field: "description", header: "Description" },
    ];
    this.exportAccountVoucherTypeColumns = this.accountVoucherTypeCols.map(
      (col) => ({ title: col.header, dataKey: col.field })
    );
  }
  fetchAccountVoucherType() {
    this.accountVoucherTypeService.allAccountVoucherTypeData().subscribe(
      async (accountVoucherType: CommonResponse) => {
        this.accountVoucherTypes = accountVoucherType.responseData;
        this.fetchingAccountVoucherType = false;
      },
      (error) => {
        this.fetchingAccountVoucherType = false;
        this.connectionError();
      }
    );
  }
  private connectionError() {
    this.messageService.add({
      severity: "error",
      summary: "Failed",
      detail: "Connection Error, Please try again",
    });
  }
  createAccountVoucherType() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Creating new Account Voucher Type Records",
    });
    const postData = {
      description: this.accountVoucherTypeForm.get("description").value,
      alias: this.accountVoucherTypeForm.get("alias").value,
      voucherType: this.accountVoucherTypeForm.get("voucherType").value,
      isAllowedForStandaloneVoucherPosting: this.isAllowedForStandalone,
      isApplicableForNotification: this.isApplicableForNotification,
    };
    this.accountVoucherTypeService.postAccountVoucherType(postData).subscribe(
      async () => {
        await this.messageService.add({
          severity: "success",
          summary: "Completed",
          detail: "New Account Voucher Type Created",
        });
        await this.fetchAccountVoucherType();
        await this.accountVoucherTypeForm.reset();
        this.isAllowedForStandalone = false;
      },
      (error) => {
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail: "Unable to create new Account Voucher Type at the moment",
        });
      }
    );
  }

  deleteAccountVoucherType(accountVoucherType) {
    this.confirmationService.confirm({
      message:
        "Are you sure you want to drop " + accountVoucherType.voucherType,
      accept: () => {
        this._deleteAccountVoucherType(accountVoucherType);
      },
    });
  }
  _deleteAccountVoucherType(accountVoucherType) {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Dropping account Voucher Type record",
    });
    this.accountVoucherTypeService
      .deleteAccountVoucherType(accountVoucherType.id)
      .subscribe(
        async (result: any) => {
          await this.messageService.add({
            severity: "success",
            summary: "Dropped",
            detail: "Account Voucher Type record dropped",
          });
          await this.fetchAccountVoucherType();
        },
        (error) => {
          this.connectionError();
        }
      );
  }
  editAccountVoucherType(accountVoucherTypes) {
    this.editingAccountVoucherType = true;
    this.accountVoucherTypeForm.addControl(
      "accountVoucherTypeId",
      new FormControl({ value: "", disabled: true }, Validators.required)
    );
    this.edAccountVoucherType = accountVoucherTypes;
    this.accountVoucherTypeForm.patchValue({
      voucherType: accountVoucherTypes.voucherType,
      description: accountVoucherTypes.description,
      alias: accountVoucherTypes.alias,
      accountVoucherTypeId: accountVoucherTypes.id,
    });
    this.isAllowedForStandalone =
      accountVoucherTypes.isAllowedForStandaloneVoucherPosting;
    this.isApplicableForNotification =
      accountVoucherTypes.isApplicableForNotification;
    // this.accountVoucherTypeFormIn.nativeElement.focus();
    // document.getElementById('formFields').focus();
    // this.selectedHeadId = this.users.find(user => user.id === accountVoucherTypes.head.id);
    this.formWrapper.nativeElement.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "start",
    });
  }

  updateAccountVoucherType() {
    // update the selected accountVoucherType
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Updating Account Voucher Type details",
    });
    const id = this.edAccountVoucherType.id;
    const postData = {
      voucherType: this.accountVoucherTypeForm.get("voucherType").value,
      description: this.accountVoucherTypeForm.get("description").value,
      alias: this.accountVoucherTypeForm.get("alias").value,
      isAllowedForStandaloneVoucherPosting: this.isAllowedForStandalone,
      isApplicableForNotification: this.isApplicableForNotification,
    };
    this.accountVoucherTypeService
      .updateAccountVoucherType(id, postData)
      .subscribe(async (result: AccountVoucherType) => {
        this.messageService.add({
          severity: "success",
          summary: "Notice",
          detail: "Account Voucher Type details Updated",
        });
        await this.fetchAccountVoucherType();
        await this.closeEditing();
      });
  }

  closeEditing() {
    this.editingAccountVoucherType = false;
    this.accountVoucherTypeForm.reset();
    this.isAllowedForStandalone = false;
    this.isApplicableForNotification = false;
    this.edAccountVoucherType = null;
  }
}
