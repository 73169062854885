import { Component, OnInit } from "@angular/core";
import { DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";
import {
  Invoice,
  SBUQuoteServiceProportion,
  StrategicBusinessUnit,
  User,
} from "../../../interfaces/home";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ConfirmationService, MessageService } from "primeng/api";
import { SbuQuoteServiceProportionService } from "../../../services/sbu-quote-service-proportion.service";
import { SbuContractServiceProportionService } from "src/app/services/sbu-contract-service-proportion.service";

@Component({
  selector: "app-view-quoteservice-details",
  templateUrl: "./view-quoteservice-details.component.html",
  styleUrls: ["./view-quoteservice-details.component.css"],
  providers: [ConfirmationService],
})
export class ViewQuoteserviceDetailsComponent implements OnInit {
  invoices: Invoice[];
  selectedProportion: any;
  proportionsCols: any;
  proportionsExportColumns: any;
  quoteService: any;

  invoicingInterval: string;
  paymentCycle: string;
  proportions: SBUQuoteServiceProportion[];
  toFetchProportions: boolean;
  isQuote: boolean = false;

  constructor(
    public config: DynamicDialogConfig,
    public quoteServiceModelRef: DynamicDialogRef,
    public confirmationService: ConfirmationService,
    public messageService: MessageService,
    public sbuQuoteServiceProportionService: SbuQuoteServiceProportionService,
    public sbuContractServiceProportionService: SbuContractServiceProportionService
  ) {}

  ngOnInit(): void {
    this.quoteService = this.config.data.quoteService;
    this.toFetchProportions = this.config.data.fetchProportions;
    this.isQuote = this.config.data.isQuote;

    this.invoicingInterval = this.getTimeCycle(
      this.quoteService.invoicingInterval
    );
    this.paymentCycle = this.getTimeCycle(this.quoteService.paymentCycle);

    if (this.isQuote) this.fetchProportions(this.quoteService.id);
    else this.fetchContractProportions(this.quoteService.id);

    console.log("What got in ", this.quoteService);

    this.proportionsCols = [
      { field: "invoiceNumber", header: "Invoice Number" },
      { field: "invoiceValue", header: "Invoice Value" },
      { field: "invoiceMonth", header: "Invoice Month" },
      { field: "invoiceYear", header: "Invoice Year" },
      { field: "receiptStatus", header: "Receipt Status" },
    ];
    this.proportionsExportColumns = this.proportionsCols.map((col) => ({
      title: col.header,
      dataKey: col.field,
    }));
  }

  fetchProportions(id) {
    this.sbuQuoteServiceProportionService
      .getSBUQuoteServiceProportion(id)
      .subscribe(
        (r) => {
          var proportions = r.responseData ?? [];
          console.log("Proportions ", proportions);
          this.proportions = proportions;
        },
        (error) => {
          this.connectionError();
        }
      );
  }

  fetchContractProportions(id) {
    this.sbuContractServiceProportionService
      .getSBUContractServiceProportion(id)
      .subscribe(
        (r) => {
          var proportions = r.responseData ?? [];
          console.log("Proportions ", proportions);
          this.proportions = proportions;
        },
        (error) => {
          this.connectionError();
        }
      );
  }

  getTimeCycle(cycle: number) {
    switch (cycle) {
      case 0:
        return "Monthly";
      case 1:
        return "BiMonthly";
      case 2:
        return "Quarterly";
      case 3:
        return "Annually";
      case 4:
        return "BiAnnually";
      case 5:
        return "OneTime";
      case 6:
        return "Adhoc";
      default:
        return "UnKnown Cycle";
    }
  }

  private connectionError() {
    this.messageService.add({
      severity: "error",
      summary: "Failed",
      detail: "Connection Error, Please try again",
    });
  }

  closeModal() {
    this.quoteServiceModelRef.close();
  }
}
