import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { MessageService, ConfirmationService } from "primeng/api";
import { BreadcrumbService } from "src/app/breadcrumb.service";
import {
  CentralStore,
  GetInvStoreReportTransferVM,
  RegionalStore,
  StoreItems,
} from "src/app/interfaces/inventory";
import { InventoryService } from "src/app/services/inventory.service";

@Component({
  selector: "app-inv-stock-position-view",
  templateUrl: "./inv-stock-position-view.component.html",
  styleUrls: ["./inv-stock-position-view.component.scss"],
})
export class InvStockPositionViewComponent implements OnInit {
  @ViewChild("formWrapper") public formWrapper: ElementRef;
  isRegional: boolean = false;
  allCentralStores: CentralStore[];
  theCentralStore: CentralStore;
  allRegionalStores: RegionalStore[];
  theRegionalStore: RegionalStore;
  allStoreItems: StoreItems[];
  theStoreItem: StoreItems;
  showReport: boolean = false;
  stockReports: GetInvStoreReportTransferVM[];
  stockReportCols: any[];
  reportCols: any[];

  constructor(
    fb: FormBuilder,
    public inventoryService: InventoryService,
    public messageService: MessageService,
    private breadcrumbService: BreadcrumbService,
    public confirmationService: ConfirmationService
  ) {}

  ngOnInit(): void {
    this.breadcrumbService.setItems([
      {
        label: "Inventory Management",
        routerLink: ["/home/inventory-management/set-up"],
      },
      {
        label: "Stock Position",
        routerLink: ["/home/inventory-management/stock-position"],
      },
    ]);

    this.stockReportCols = [
      { field: "itemName", header: "ItemName" },
      { field: "itemCode", header: "ItemCode" },
      { field: "totalQuantity", header: "TotalQuantity" },
      { field: "quantityInHold", header: "QuantityInHold" },
      { field: "totalValue", header: "TotalValue" },
      { field: "firstEntryDate", header: "FirstEntryDate" },
      { field: "report", header: "Report" },
    ];

    this.reportCols = [
      { field: "itemCode", header: "ItemCode" },
      { field: "batchNo", header: "BatchNo" },
      { field: "unitPrice", header: "UnitPrice" },
      { field: "quantity", header: "Quantity" },
      { field: "totalPrice", header: "TotalPrice" },
    ];

    this.FetchAllInventoryStores();
  }

  FetchAllInventoryStores() {
    this.inventoryService.GetAllInventoryStores().subscribe(
      (data) => {
        this.allCentralStores = data.centralStores;
        this.allRegionalStores = data.regionalStores;
      },
      (error) => {
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all inventory stores at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  LoadStoreItems() {
    if (!this.isRegional) {
      this.inventoryService
        .GetAllCentralStoreItems(this.theCentralStore.id)
        .subscribe(
          (data) => {
            this.allStoreItems = data;
          },
          (error) => {
            this.messageService.add({
              severity: "error",
              summary: "Notice",
              detail:
                "Unable to fetch all store items in central store at the moment.. Reason: [" +
                error
                  ? error.error.message
                  : "request failed - permission" + "]",
            });
          }
        );
    } else {
      this.inventoryService
        .GetAllRegionStoreItems(this.theRegionalStore.id)
        .subscribe(
          (data) => {
            this.allStoreItems = data;
          },
          (error) => {
            this.messageService.add({
              severity: "error",
              summary: "Notice",
              detail:
                "Unable to fetch all store items in regional store at the moment.. Reason: [" +
                error
                  ? error.error.message
                  : "request failed - permission" + "]",
            });
          }
        );
    }
  }

  RunCriteria() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Generating Report...",
    });

    this.showReport = false;
    this.inventoryService
      .GetInvStoreReport(
        this.isRegional,
        this.isRegional ? this.theRegionalStore.id : this.theCentralStore.id
      )
      .subscribe(
        async (data) => {
          this.stockReports = data;
          this.showReport = true;
        },
        (error) => {
          console.log("Error: " + JSON.stringify(error));
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail:
              "Unable to generate report at the moment.. Reason: [" +
              (error ? error.error.message : "request failed - permission") +
              "]",
          });
          this.showReport = false;
        }
      );
  }
}
