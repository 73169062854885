import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import {
  ApprovalChecklist,
  CreateApprovalChecklistVM,
  MoveApprovalChecklistSequenceVM,
  UpdateApprovalChecklistVM,
} from "../interfaces/approval-checklist";
import { CommonResponse } from "../interfaces/home";

@Injectable({
  providedIn: "root",
})
export class ApprovalChecklistService {
  baseUrl = environment.baseUrl + "ApprovalChecklist/";

  constructor(private http: HttpClient) {}

  GetAllApprovalChecklists(): Observable<CommonResponse<ApprovalChecklist[]>> {
    return this.http.get<CommonResponse<ApprovalChecklist[]>>(
      this.baseUrl + "GetAllApprovalChecklists"
    );
  }

  CreateApprovalChecklist(
    data: CreateApprovalChecklistVM
  ): Observable<CommonResponse<boolean>> {
    return this.http.post<CommonResponse<boolean>>(
      this.baseUrl + "CreateApprovalChecklist",
      data
    );
  }

  UpdateApprovalChecklist(
    id: number,
    data: UpdateApprovalChecklistVM
  ): Observable<CommonResponse<boolean>> {
    return this.http.put<CommonResponse<boolean>>(
      this.baseUrl + "UpdateApprovalChecklist/" + id,
      data
    );
  }

  DeleteApprovalChecklist(id: number): Observable<CommonResponse<boolean>> {
    return this.http.delete<CommonResponse<boolean>>(
      this.baseUrl + "DeleteApprovalChecklist/" + id
    );
  }

  MoveApprovalChecklistSequence(
    id: number,
    data: MoveApprovalChecklistSequenceVM
  ): Observable<CommonResponse<boolean>> {
    return this.http.put<CommonResponse<boolean>>(
      this.baseUrl + "MoveApprovalChecklistSequence/" + id,
      data
    );
  }
}
