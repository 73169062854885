export const environment = {
  production: true,
  staging: false,
  dev: false,
  gmaBaseUrl: "https://gma.halobizapps.com/api/v1/",
  baseUrl: "https://halobiz.halobizapps.com/api/v1/",
  reportUrl: "https://report.halobizapps.com/Reports/",
  identityBaseUrl: "https://identity.halobizapps.com/api/v1/",
  onlineBackendBaseUrl: "https://online.halobizapps.com/api/",
  onlineBackendBaseUrl_: "https://online.halobizapps.com/api/",
  schedulerBaseUrl: "https://scheduler.halobizapps.com/api/v1/",
  baseMailUrl: "https://mail.halobizapps.com/Mail/",
  frontednUrl: "https://halobiz-live.halobizapps.com/",

  armadaBaseUrl: "https://halobiz.halobizapps.com/api/v1/",
  testProjectAll: "https://halobiz.halobizapps.com/api/v1/",

  liabilityControlAccountId: "1200000000", //Assets::Receivable
  assetControlAccountId: "5100000000", //Expenses::PayrollAccount
  expenseControlAccountId: "1300000000", //Specific Control Account To Be Created
  defaultImage: "/assets/layout/images/users-vector.jpg",
  defaultServiceImage:
    "https://firebasestorage.googleapis.com/v0/b/halo-biz.appspot.com/o/serviceImage%2FGuard.png?alt=media&token=2c6c2279-5508-4d8a-8169-a468595bb2f6",
  amarda:
    "https://firebasestorage.googleapis.com/v0/b/halo-biz.appspot.com/o/serviceImage%2FAMARDA.jpeg?alt=media&token=d29b3f37-4060-4e41-b99b-942d7518098f",
  "physical security":
    "https://firebasestorage.googleapis.com/v0/b/halo-biz.appspot.com/o/serviceImage%2FPS.jpeg?alt=media&token=4cfbec59-64c8-4dfe-9950-59b7a17b4c25",
  academy:
    "https://firebasestorage.googleapis.com/v0/b/halo-biz.appspot.com/o/serviceImage%2FACADEMY.jpeg?alt=media&token=825a9119-2bc5-452c-83a5-f10afbc94aeb",
  avant:
    "https://firebasestorage.googleapis.com/v0/b/halo-biz.appspot.com/o/serviceImage%2FAVANT.jpeg?alt=media&token=46908f3c-a5c8-4e25-8739-fc8f7813a814",
  avert:
    "https://firebasestorage.googleapis.com/v0/b/halo-biz.appspot.com/o/serviceImage%2FAVERT.jpeg?alt=media&token=6e3f1302-bc95-493c-b65c-139a8f20e7c3",
  "armour x":
    "https://firebasestorage.googleapis.com/v0/b/halo-biz.appspot.com/o/serviceImage%2FAMOUR-X.jpeg?alt=media&token=b5865ace-0878-4eaa-a69f-28a7a1b4cbf9",
  reportViewerHost:
    "https://reportserver.northeurope.cloudapp.azure.com/reports/report/",

  endorsementType: {
    New: 1,
    ServiceAddition: 2,
    ServiceTopUp: 3,
    ServiceReduction: 4,
    ServiceRetention: 5,
    CreditNote: 8,
    DebitNote: 9,
  },

  AssetAccounClassId: 1,
  AssetControlAccountReceivableId: 3,
  SecuGenLicenseKey:
    "9Ap8bvGGGspZ7nWPsjcDXwD6Jr4aALHNf9CBcpGyRnCQYyYoI91R6c5hkFhbGM8x",
  HaloWorldRealEstateBaseUrl: "https://haloestate-dev.azurewebsites.net/api/",
};
