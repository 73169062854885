import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ConfirmationService, MessageService } from 'primeng/api';
import { BreadcrumbService } from 'src/app/breadcrumb.service';
import { CommonResponse } from 'src/app/interfaces/home';
import { Make } from 'src/app/interfaces/make';
import { Model } from 'src/app/interfaces/model';
import { MakeService } from 'src/app/services/make.service';
import { ModelService } from 'src/app/services/model.service';

@Component({
  selector: 'app-model-settings',
  templateUrl: './model-settings.component.html',
  styleUrls: ['./model-settings.component.scss']
})
export class ModelSettingsComponent implements OnInit {

  @ViewChild('formWrapper') public formWrapper: ElementRef;
  fetchingModel = true;
  modelForm: FormGroup;
  models: Model[];
  selectedModel: Model;
  editingModel = false;
  theMake: Make;
  makes: Make[];
  public modelCols: any[];
  public exportModelColumns: any[];
  private edModel: Model;
  constructor(
    private formBuilder: FormBuilder,
    private makeService: MakeService,
    private modelService: ModelService,
    public messageService: MessageService,
    public confirmationService: ConfirmationService,
    private breadcrumbService: BreadcrumbService
  ) { }

  ngOnInit(): void {
    this.breadcrumbService.setItems([
      { label: 'Supplier', routerLink: ['home/supplier'] },
      { label: 'Model Settings', routerLink: ['home/supplier/settings/model'] }
    ]);
    this.fetchModel();
    this.fetchMakes();
    this.modelForm = this.formBuilder.group({
      description: ['', Validators.required],
      caption: ['', [Validators.required]],
      makeId: ['', Validators.required]
    });
    this.modelCols = [
      { field: 'caption', header: 'Caption' },
      { field: 'make', header: 'Make' },
      { field: 'description', header: 'Description' }
    ];
    this.exportModelColumns = this.modelCols.map(col => ({ title: col.header, dataKey: col.field }));
  }

  fetchModel() {
    this.modelService.getAll().subscribe(async (model: CommonResponse) => {
      this.models = model.responseData;
      this.fetchingModel = false;
    }, error => {
      this.fetchingModel = false;
      this.connectionError();
    })
  }

  fetchMakes() {
    this.makeService.getAll().subscribe(async (make: CommonResponse) => {
      this.makes = make.responseData;

      this.models = this.models.map(x => {
        let makeValue = this.makes.find(j => j.id == x.makeId);
        return { ...x, make: makeValue.caption };
      });

    }, error => {
      this.connectionError();
    })
  }

  private connectionError() {
    this.messageService.add({
      severity: 'error', summary: 'Failed',
      detail: 'Connection Error, Please try again'
    });
  }

  closeEditing() {
    this.editingModel = false;
    this.modelForm.reset();
    this.edModel = null;
  }

  createModel() {
    this.messageService.add({
      severity: 'info', summary: 'Notice',
      detail: 'Creating New Model'
    });
    let make: Make = this.modelForm.get('makeId').value;
    const postData: Model = {
      description: this.modelForm.get('description').value,
      caption: this.modelForm.get('caption').value,
      makeId: make.id
    };
    this.modelService.create(postData).subscribe(async (res) => {
      if (res.responseCode != '00') {
        this.messageService.add({
          severity: 'error', summary: 'Notice',
          detail: 'Unable to create new model at the moment'
        });
      }
      await this.messageService.add({
        severity: 'success', summary: 'Completed',
        detail: 'New Model Created'
      });
      this.fetchModel();
      this.fetchMakes();
      this.modelForm.reset();
    }, error => {
      this.messageService.add({
        severity: 'error', summary: 'Notice',
        detail: 'Unable to create new model at the moment'
      });
    });
  }

  editModel(model: Model) {
    this.editingModel = true;
    this.modelForm.addControl('Id', new FormControl({ value: '', disabled: true }, Validators.required));
    this.edModel = model;
    this.modelForm.patchValue({
      caption: model.caption,
      description: model.description,
      makeId: model.makeId
    });
    this.theMake = this.makes.find(make => make.id == model.makeId);
    this.modelForm.controls.makeId.setValue(this.theMake);
    this.formWrapper.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'start' });
  }

  deleteModel(model: Model) {
    this.confirmationService.confirm({
      message: 'Are you sure you want to drop ' + model.caption,
      accept: () => {
        this._deleteModel(model);
      }
    });
  }

  _deleteModel(model: Model) {
    this.messageService.add({
      severity: 'info', summary: 'Notice',
      detail: 'Dropping Model record'
    });
    this.modelService.delete(parseInt(model.id)).subscribe(async (result: CommonResponse) => {
      if (result.responseCode != '00') {
        this.connectionError();
      }
      await this.messageService.add({
        severity: 'success', summary: 'Dropped',
        detail: 'Model record dropped'
      });
      await this.fetchModel();
      this.fetchMakes();
    }, error => {
      this.connectionError()
    });
  }

  updateModel() {
    this.messageService.add({
      severity: 'info', summary: 'Notice',
      detail: 'Updating Model details'
    });
    const id = this.edModel.id;
    let make: Make = this.modelForm.get('makeId').value;
    const postData: Model = {
      caption: this.modelForm.get('caption').value,
      description: this.modelForm.get('description').value,
      makeId: make.id
    };
    this.modelService.update(postData, parseInt(id)).subscribe(async (result: CommonResponse) => {
      if (result.responseCode != '00') {
        this.messageService.add({
          severity: 'error', summary: 'Notice',
          detail: 'Unable to update model at the moment'
        });
      }
      this.messageService.add({
        severity: 'success', summary: 'Notice',
        detail: 'Model details Updated'
      });
      await this.fetchModel();
      this.fetchMakes();
      await this.closeEditing();
    }, error => {
      this.messageService.add({
        severity: 'error', summary: 'Notice',
        detail: 'Unable to create new model at the moment'
      });
    })
  }

}
