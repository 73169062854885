import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CommonResponse } from 'src/app/interfaces/home';
import { environment } from 'src/environments/environment';

@Injectable()
export class SupplierApprovalRequestService {

  constructor(public http:HttpClient) { }
  userBaseUrl = environment.baseUrl + 'User/';
  supplierBaseUrl = environment.baseUrl + 'SupplierApprovals/';
  allUser(): Observable<CommonResponse>{
    return this.http.get<CommonResponse>(this.userBaseUrl);
  }
 
  allSupplierRequest(): Observable<CommonResponse>{
    return this.http.get<CommonResponse>(this.supplierBaseUrl+"getAllSupplierApproval");
  }

  processRequest(supplierId:any):Observable<CommonResponse>{
    return this.http.put<CommonResponse>(this.supplierBaseUrl+"processRequest/"+supplierId,null);
  }

  proceedRequest(supplierId:any):Observable<CommonResponse>{
    return this.http.get<CommonResponse>(this.supplierBaseUrl+"getSupplier/"+supplierId);
  }

  approvedRequest(approvalId:any):Observable<CommonResponse>{
    return this.http.put<CommonResponse>(this.supplierBaseUrl+"approveRequest/"+approvalId,null);
  }

  declineRequest(approvalId:any,remarks:any):Observable<CommonResponse>{
    return this.http.put<CommonResponse>(this.supplierBaseUrl+"declineRequest/"+approvalId+"/"+remarks,null);
  }

  
}
