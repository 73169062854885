import { Component, OnDestroy, OnInit } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Subscription } from 'rxjs';
import { flatMap } from 'rxjs/operators';
import { BreadcrumbService } from 'src/app/breadcrumb.service';
import { CustomerDivisionService } from 'src/app/services/customer-division.service';

@Component({
  selector: 'app-generic-notification',
  templateUrl: './generic-notification.component.html',
  styleUrls: ['./generic-notification.component.scss']
})
export class GenericNotificationComponent implements OnInit, OnDestroy {

  allClients: any[];
  selectedClients: any[] | undefined = []
  selectedClientsCopy: any[] | undefined = []
  isGenericNotificationSet: boolean = false;
  clientsSubscription: Subscription | undefined;
  loadingData = false;
  constructor(
    private clientService: CustomerDivisionService,
    private breadcrumbService: BreadcrumbService,
    public confirmationService: ConfirmationService,
    public messageService: MessageService) {
      
    
  }

  ngOnInit(): void {
    this.clientsSubscription = this.FetchAllClients();
    
  }
  ngOnDestroy(): void {
    this.clientsSubscription.unsubscribe();
  }

  FetchAllClients() {
    this.loadingData = true;
    return this.clientService.allCustomerDivision().subscribe(
      (data) => {
        this.allClients = data.responseData;
        console.log(this.allClients[1]);
        console.log(this.allClients[1].divisionName);
        this.selectedClients = this.allClients.filter((ele) => {
          return ele.isGenericSendIncidentReportEnabled
        })
        this.selectedClientsCopy = this.selectedClients.slice();
        console.log(this.selectedClients)
      },
      (error) => {
        this.messageService.add({
          severity: "error",
          summary: "Failed",
          detail: error ?? "Some errors occurred",
        });
      }, 
      () => {
        console.log('completed');
        this.loadingData = false;
      }
    );
    
  }

  onSettingsChange(evt) {
    console.log(evt);
    console.log(`isGenericNotificationSet: ${this.isGenericNotificationSet}`);
    if (this.selectedClients.length == 0) {
      if (this.isGenericNotificationSet == true) {
        this.displayMessage("error", "Error","You have to select at least one customer")
        this.isGenericNotificationSet = false;
        return;
      }
      
    }
  }

  onRowSelect(evt) {
    console.log(evt);
    console.log(this.selectedClients)
  }

  onRowUnSelect(evt) {
    console.log(evt);
    console.log(this.selectedClients)
  }
  saveSelectedRecord(evt) {
    console.log(evt)
    if (this.selectedClients){
      this.selectedClients.forEach((res) => {
        res.isGenericSendIncidentReportEnabled = true;
      })
    }

    
    // console.log(this.selectedClients)
    // console.log(this.selectedClientsCopy)

    const disableClients = this.selectedClientsCopy.filter(x => !this.selectedClients.includes(x))
    // console.log( disableClients );

    // const disableClients2 = [...disableClients]
    // disableClients2[0].isGenericSendIncidentReportEnabled = false;
    // console.log( disableClients2 );
    // if (this.selectedClientsCopy.includes(ele)) {
        //   console.log("YES IT IS INSIDE")
        //   this.selectedClientsCopy.filter(ele1=> {
        //     if (ele1 === ele)
        //       ele.isGenericSendIncidentReportEnabled = false;
        //   })
        // }

    if (disableClients.length > 0) {
      disableClients.forEach( (ele) => {
        ele.isGenericSendIncidentReportEnabled = false;
        
      })
    }
    this.updateRecordForGenericNotificationSend(this.selectedClientsCopy);
    // console.log(this.selectedClientsCopy)
    // console.log( disableClients2 );
    // console.log(this.allClients)
    console.log(`Saving: ${evt}`)
  }

  updateRecordForGenericNotificationSend(data) {
    this.loadingData = true;
    return this.clientService.updateCustomerDivisionForGenericNotifications(data).subscribe(
      (data) => {
        if (data['responseCode'] == "00")
        {
          this.displayMessage("success", "Success", "Successfully set notifications");
        }
        else {
          this.displayMessage("error", "Error", "Error in setting notifications");
        }
        
      },
      (error) => {
        this.messageService.add({
          severity: "error",
          summary: "Failed",
          detail: error ?? "Some errors occurred",
        });
      }, 
      () => {
        console.log('completed');
        this.loadingData = false;
      }
    );
  }

  changeGenericSelection() {
    console.log('Check for generic')
  }

  displayMessage(severity, summary, message) {
    this.messageService.add({
      severity: severity,
      summary: summary,
      detail: message,
    });
  }
}
