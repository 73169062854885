import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute} from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import {DialogService } from 'primeng/dynamicdialog';
import { EventService } from 'src/app/demo/service/eventservice';
import { UserService } from 'src/app/services/user.service';
import { EngagementServiceService } from './engagement-service.service';

@Component({
  selector: 'app-engagement-dashboard',
  templateUrl: './engagement-dashboard.component.html',
  styleUrls: ['./engagement-dashboard.component.scss']
})
export class EngagementDashboardComponent implements OnInit {
  subscriber: any;
  newId: string;
  dashboardValue: any;
  workspaceIsLoading=false;
  GoalArray: any[]=[];
  MeetingArray: any[]=[];
  SuspectsContacts: any[]=[];
  meetingDueToday: any[]=[];
  TodoDueToday: any[]=[];
  meetingTypes: { name: string; value: number; }[];

  constructor(    
    public engagementService:EngagementServiceService,
    private route: ActivatedRoute,
    public confirmationService: ConfirmationService,
    public dialogService: DialogService,
    public messageService: MessageService,
    private sanitizer: DomSanitizer,
    private eventService: EventService,
    private userService:UserService,
    private formBuilder: FormBuilder,) { }

  ngOnInit(): void {

    this.subscriber = this.route.paramMap.subscribe((params) => {
      this.newId = params.get("id");
      this.getDashboardBySuspectid(this.newId);
    });
    this.meetingTypes = [
      { name: "Webinar", value: 1 },
      { name: "Seminar", value: 2 },
      { name: "Meet And Greet", value: 3 },
      { name: "CloseOut", value: 4},
      { name: "Presentation", value: 5 },
      { name: "Negotiations", value: 6 },
      { name: "Product Briefing", value: 7 },
    ];
  }


  getMeetingType(id){
    return this.meetingTypes.find(x=>x.value == id);
  }

   getDashboardBySuspectid(id) {
     this.workspaceIsLoading = true;
    this.engagementService
      .getDashboard(id)
      .subscribe(async (data: any) => {
        if (data.responseCode == "00") {
          this.dashboardValue = data.responseData
          this.GoalArray = this.dashboardValue.goal;
          this.MeetingArray = this.dashboardValue.meeting;
          this.TodoDueToday = this.dashboardValue.todoDueToday
          this.meetingDueToday = this.dashboardValue.meetingDueToday
          this.SuspectsContacts = this.dashboardValue.suspects;
          console.log("this is dashboardValue array", this.dashboardValue);
          this.messageService.add({
            severity: "success",
            summary: "Notice",
            detail: "Dashboard  was fectched successsfully",
          });
          this.workspaceIsLoading = false
        }
        else{
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail: data.responseMsg,
          });
          this.workspaceIsLoading = false
        }
      },error=>{
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail: "An error occurred",
        });
      }
      );
  }

}
