import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { TreeNode } from "primeng/api";
import { Observable, Subject } from "rxjs";
import { retry } from "rxjs/operators";
import { environment } from "src/environments/environment";

@Injectable()
export class ProjectAllocationService {
  private subject = new Subject<any>();

  constructor(private http: HttpClient) {}

  getFiles() {
    return this.http
      .get<any>("assets/demo/data/halogen-structure.json")
      .toPromise()
      .then((res) => res.data as TreeNode[]);
  }

  getLazyFiles() {
    return this.http
      .get<any>("assets/demo/data/files-lazy.json")
      .toPromise()
      .then((res) => res.data as TreeNode[]);
  }

  getFilesystem() {
    return this.http
      .get<any>("assets/demo/data/filesystem.json")
      .toPromise()
      .then((res) => res.data as TreeNode[]);
  }

  addNewManager(data: any): Observable<any> {
    return this.http.post(environment.baseUrl + "ProjectManagement", data);
  }

  createNewProject(data: any): Observable<any> {
    return this.http.post(
      environment.baseUrl + "ProjectManagement/CreateProject",
      data
    );
  }

  createNewTask(projectId: any, body: any): Observable<any> {
    return this.http.post(
      environment.baseUrl + "ProjectManagement/CreateTask/" + projectId,
      body
    );
  }

  getPojectTree1() {
    return this.http
      .get<any>(
        environment.baseUrl + "ProjectManagement/GetDeliverableForApproval"
      )
      .toPromise()
      .then((res) => <TreeNode[]>res.responseData);
  }

  getPiechart() {
    return this.http.get(
      environment.baseUrl + "ProjectManagement/GetTaskValueForPieChart"
    );
  }

  getProjectCountBarChart(): Observable<any> {
    return this.http.get(
      environment.baseUrl + "ProjectManagement/GetProjectCountBarChart"
    );
  }

  getWorkspaceCountBarChartCountBarCharttPi() {
    return this.http.get(
      environment.baseUrl + "ProjectManagement/GetWorkspaceCountBarChart"
    );
  }

  getTaskOwnershipDTO() {
    return this.http.get(
      environment.baseUrl + "ProjectManagement/GetTaskOwnershipDTO"
    );
  }

  getTaskMilestone() {
    return this.http.get(
      environment.baseUrl + "ProjectManagement/GetAllMileStoneTaskDueToday"
    );
  }

  getTaskDueToday() {
    return this.http.get(
      environment.baseUrl + "ProjectManagement/GetAllTaskDueToday"
    );
  }

  getAssignedTask() {
    return this.http.get(
      environment.baseUrl + "ProjectManagement/AssignedTask"
    );
  }

  getDeliverableAwaitingApprorval() {
    return this.http.get(
      environment.baseUrl + "ProjectManagement/GetDeliverableForApproval"
    );
  }

  getDeliverableAwaitingApproved() {
    return this.http.get(
      environment.baseUrl + "ProjectManagement/GetDeliverableApproved"
    );
  }

  getDeliverableIsDeclined() {
    return this.http.get(
      environment.baseUrl + "ProjectManagement/GetDeliverableDeclined"
    );
  }

  getProjectForWatchers() {
    return this.http.get(
      environment.baseUrl + "ProjectManagement/GetWorkspacewithWatcher"
    );
  }

  getTaskFromproject(projectId: any) {
    return this.http.get(
      environment.baseUrl + "ProjectManagement/GetTaskFromProject/" + projectId
    );
  }

  getTaskFromprojectRevamped(projectId: any) {
    return this.http.get(
      environment.baseUrl +
        "ProjectManagement/GetTaskFromProjectRevamped/" +
        projectId
    );
  }

  getAllWorkspaceForProjectCreator() {
    return this.http.get(
      environment.baseUrl + "ProjectManagement/GetAllWorkspaceForProjectCreator"
    );
  }

  getAllWatcherProjectAndTask(): Observable<any> {
    return this.http.get(
      environment.baseUrl + "ProjectManagement/GetAllWatcherProjects"
    );
  }

  createDeliverable(taskId: any, body: any): Observable<any> {
    return this.http.post(
      environment.baseUrl + "ProjectManagement/CreateNewDeliverable/" + taskId,
      body
    );
  }

  createDeliverableOnTaskManager(taskId: any, body: any): Observable<any> {
    return this.http.post(
      environment.baseUrl +
        "ProjectManagement/CreateNewDeliverableOnTask/" +
        taskId,
      body
    );
  }

  assignDeliverable(
    taskId: any,
    deliverableId: any,
    assignDeliverableId: any,
    body: any
  ): Observable<any> {
    return this.http.post(
      environment.baseUrl +
        "ProjectManagement/AssignDeliverable/" +
        deliverableId +
        "/" +
        taskId +
        "/" +
        assignDeliverableId,
      body
    );
  }

  createDeliverableIllustrations(
    taskId: any,
    deliverableId: any,
    body: any
  ): Observable<any> {
    return this.http.post(
      environment.baseUrl +
        "ProjectManagement/createDeliverableIllustration/" +
        deliverableId +
        "/" +
        taskId,
      body
    );
  }

  createTaskIllustration(taskId: any, body: any): Observable<any> {
    return this.http.post(
      environment.baseUrl +
        "ProjectManagement/CreateTaskIllustration/" +
        taskId,
      body
    );
  }

  createNewWorkspace(data: any) {
    return this.http.post(
      environment.baseUrl + "ProjectManagement/workspace",
      data
    );
  }

  uploadRequirementFiles(body: any) {
    return this.http.post(
      environment.baseUrl + "ProjectManagement/UploadRequirementsDetails",
      body
    );
  }

  pickDeliverable(deliverableId) {
    var body;
    return this.http.post(
      environment.baseUrl +
        "ProjectManagement/PickDeliverable/" +
        deliverableId,
      body
    );
  }

  saveAmount(amount, deliverableId) {
    var body;
    return this.http.post(
      environment.baseUrl +
        "ProjectManagement/saveAmount/" +
        amount +
        "/" +
        deliverableId,
      body
    );
  }

  addComments(body: any, deliverableId) {
    return this.http.post(
      environment.baseUrl + "ProjectManagement/AddComments/" + deliverableId,
      body
    );
  }

  disableComment(commentId: any, deliverableId: any) {
    return this.http.delete(
      environment.baseUrl +
        "ProjectManagement/disableComments/" +
        commentId +
        "/" +
        deliverableId
    );
  }

  selectStatus(statusId: any, deliverableId) {
    var body;
    return this.http.post(
      environment.baseUrl +
        "ProjectManagement/SelectStatus/" +
        statusId +
        "/" +
        deliverableId,
      body
    );
  }

  approveDeliverable(deliverableId: any) {
    var body;
    return this.http.put(
      environment.baseUrl +
        "ProjectManagement/ApproveDeliverable/" +
        deliverableId,
      body
    );
  }

  declineDeliverable(deliverableId: any, reason: any) {
    var body;
    return this.http.put(
      environment.baseUrl +
        "ProjectManagement/declineDeliverable/" +
        deliverableId +
        "/" +
        reason,
      body
    );
  }

  moveToAnotherStatus(
    body: any,
    statusId: any,
    deliverableId: any,
    statusCode: any
  ) {
    return this.http.post(
      environment.baseUrl +
        "ProjectManagement/moveToAnotherStatus/" +
        statusId +
        "/" +
        deliverableId +
        "/" +
        statusCode,
      body
    );
  }

  disableUploadedRequirement(uploadedRequirementId: any) {
    return this.http.delete(
      environment.baseUrl +
        "ProjectManagement/disableUploadedRequirement/" +
        uploadedRequirementId
    );
  }

  getProjectByCaption(data: any) {
    return this.http.get(
      environment.baseUrl + "ProjectManagement/GetProjectByName/" + data
    );
  }

  getRequirementsByDeliverableId(data: any) {
    return this.http.get(
      environment.baseUrl +
        "ProjectManagement/GetRequirementsByDeliverableId/" +
        data
    );
  }

  getProjectByWorkspaceId(data: any) {
    return this.http.get(
      environment.baseUrl +
        "ProjectManagement/GetAllProjectByWorkspaceId/" +
        data
    );
  }

  getBarchartData(taskId: any) {
    return this.http.get(
      environment.baseUrl + "ProjectManagement/GetBarChart/" + taskId
    );
  }

  getDeliverableStatus() {
    return this.http.get(
      environment.baseUrl + "ProjectManagement/GetDeliverableStatus"
    );
  }

  getWorkspaceWithStatus() {
    return this.http.get(
      environment.baseUrl + "ProjectManagement/GetWorkspaceStatus"
    );
  }

  getAssignedDeliverableStatus(deliverableId) {
    return this.http.get(
      environment.baseUrl +
        "ProjectManagement/GetAssignedDeliverableStatus/" +
        deliverableId
    );
  }

  getPrivateUsersByWorkspaceId(workspaceId: any) {
    return this.http.get(
      environment.baseUrl +
        "ProjectManagement/GetPrivacyAccessByWorkspaceId/" +
        workspaceId
    );
  }

  getDeliverablesByTaskId(taskId: any) {
    return this.http.get(
      environment.baseUrl +
        "ProjectManagement/GetDeliverableByTaskIdRevamped/" +
        taskId
    );
  }

  getDeliverableById(deliverableId: any) {
    return this.http.get(
      environment.baseUrl +
        "ProjectManagement/GetDeliverableById/" +
        deliverableId
    );
  }

  getTaskIllustration(taskId: any) {
    return this.http.get(
      environment.baseUrl +
        "ProjectManagement/GetAllTaskIllustrations/" +
        taskId
    );
  }

  disableTaskIllustration(taskId: any, illustrationId: any): Observable<any> {
    return this.http.delete(
      environment.baseUrl +
        "ProjectManagement/removeTaskIllustration/" +
        taskId +
        "/" +
        illustrationId
    );
  }

  disableTask(taskId: any, projectId: any): Observable<any> {
    return this.http.delete(
      environment.baseUrl +
        "ProjectManagement/DisableTask/" +
        taskId +
        "/" +
        projectId
    );
  }

  disableDeliverable(deliverableId: any, taskId: any): Observable<any> {
    return this.http.delete(
      environment.baseUrl +
        "ProjectManagement/DisableDeliverable/" +
        deliverableId +
        "/" +
        taskId
    );
  }

  disableProject(projectId: any): Observable<any> {
    return this.http.delete(
      environment.baseUrl + "ProjectManagement/DisableProject/" + projectId
    );
  }

  updateTask(taskId: any, data: any) {
    return this.http.put(
      environment.baseUrl + "ProjectManagement/UpdateTask/" + taskId,
      data
    );
  }

  pushForApproval(deliverableId: any) {
    var data;
    return this.http.post(
      environment.baseUrl +
        "ProjectManagement/PushForApproval/" +
        deliverableId,
      data
    );
  }

  reverseApproval(deliverableId: any) {
    var data;
    return this.http.post(
      environment.baseUrl +
        "ProjectManagement/ReverseApproval/" +
        deliverableId,
      data
    );
  }

  getWatchers(data: any) {
    return this.http.get(
      environment.baseUrl + "ProjectManagement/GetWatchersByProjectId/" + data
    );
  }

  getProjecById(projectId) {
    return this.http.get(
      environment.baseUrl + "ProjectManagement/GetProjectById/" + projectId
    );
  }

  getTaskByCaption(data: any) {
    return this.http.get(
      environment.baseUrl + "ProjectManagement/GetTaskByCaption/" + data
    );
  }

  getWorkspacesByCaption(caption: any) {
    return this.http.get(
      environment.baseUrl + "ProjectManagement/GetWorkspaceByCaption/" + caption
    );
  }

  getWorkspacesById(workspaceId: any) {
    return this.http.get(
      environment.baseUrl +
        "ProjectManagement/GetWorkspaceByIdRevamped/" +
        workspaceId
    );
  }

  getTaskSummary(projectId: any) {
    return this.http.get(
      environment.baseUrl +
        "ProjectManagement/GetAllTaskByProjectId/" +
        projectId
    );
  }

  getTaskByTaskId(taskId: any) {
    return this.http.get(
      environment.baseUrl + "ProjectManagement/GetTaskByTaskId/" + taskId
    );
  }

  getAllWorkspaces(): Observable<any> {
    return this.http.get(
      environment.baseUrl + "ProjectManagement/GetAllWorkspaces"
    );
  }

  getAllWorkspacesRevamped(): Observable<any> {
    return this.http
      .get(environment.baseUrl + "ProjectManagement/GetAllWorkspacesRevamped")
      .pipe(retry(5));
  }

  getAllValuesForSideBar(): Observable<any> {
    return this.http
      .get(environment.baseUrl + "ProjectManagement/GetAllSideBarValues")
      .pipe(retry(10));
  }

  getDefaultStatus(): Observable<any> {
    return this.http.get(
      environment.baseUrl + "ProjectManagement/GetDefaultStatus"
    );
  }

  getAllManagersFromCategory(data: any): Observable<any> {
    return this.http.get(environment.baseUrl + "ProjectManagement/" + data);
  }

  getAllProjects(): Observable<any> {
    return this.http.get(
      environment.baseUrl + "ProjectManagement/GetAllProjects"
    );
  }

  getAllTask(): Observable<any> {
    return this.http.get(
      environment.baseUrl + "ProjectManagement/GetAssignedTask"
    );
  }

  getAllSummary(): Observable<any> {
    return this.http.get(environment.baseUrl + "ProjectManagement/GetAllTask");
  }

  getAllDeliverables(): Observable<any> {
    return this.http
      .get(environment.baseUrl + "ProjectManagement/GetAllDeliverable")
      .pipe(retry(3));
  }

  getAllDeliverablesRevamp(): Observable<any> {
    return this.http.get(
      environment.baseUrl + "ProjectManagement/GetAllDeliverableRevamped"
    );
  }

  getUserList(taskId: any): Observable<any> {
    return this.http.get(
      environment.baseUrl + "ProjectManagement/GetTaskAssignees/" + taskId
    );
  }

  getAllDeliverablesById(taskId: any): Observable<any> {
    return this.http.get(
      environment.baseUrl + "ProjectManagement/GetDeliverableByTaskId/" + taskId
    );
  }

  updateWorkspaceName(id: any, body: any): Observable<any> {
    return this.http.put(
      environment.baseUrl + "ProjectManagement/workspace/" + id,
      body
    );
  }

  updateDeliverable(
    taskId: any,
    deliverableId: any,
    body: any
  ): Observable<any> {
    return this.http.put(
      environment.baseUrl +
        "ProjectManagement/UpdateDeliverable/" +
        taskId +
        "/" +
        deliverableId,
      body
    );
  }

  addMoreWatchers(id: any, body: any) {
    return this.http.put(
      environment.baseUrl + "ProjectManagement/AddmoreWatchers/" + id,
      body
    );
  }

  addMoreAssignees(id: any, body: any) {
    return this.http.post(
      environment.baseUrl + "ProjectManagement/AddmoreTaskAssignees/" + id,
      body
    );
  }

  removeIllustration(
    taskId: any,
    deliverableId: any,
    illustrationId: any
  ): Observable<any> {
    return this.http.delete(
      environment.baseUrl +
        "ProjectManagement/removeIllustration/" +
        taskId +
        "/" +
        deliverableId +
        "/" +
        illustrationId
    );
  }

  updateProject(id: any, body: any): Observable<any> {
    return this.http.put(
      environment.baseUrl + "ProjectManagement/updateProject/" + id,
      body
    );
  }

  addMoreProjectCreators(id: any, body: any): Observable<any> {
    return this.http.put(
      environment.baseUrl + "ProjectManagement/addMoreProjectCreators/" + id,
      body
    );
  }

  addMoreStatus(id: any, body: any): Observable<any> {
    return this.http.put(
      environment.baseUrl + "ProjectManagement/AddMoreStatus/" + id,
      body
    );
  }

  removeWatcher(projectId: any, projectWatcherId): Observable<any> {
    return this.http.delete(
      environment.baseUrl +
        "ProjectManagement/DisableWatcher/" +
        projectId +
        "/" +
        projectWatcherId
    );
  }

  removeAssignee(taskId: any, assigneeId): Observable<any> {
    return this.http.delete(
      environment.baseUrl +
        "ProjectManagement/DisableTaskAssignee/" +
        taskId +
        "/" +
        assigneeId
    );
  }

  moveStatusSequence(id: any, body: any): Observable<any> {
    return this.http.put(
      environment.baseUrl + "ProjectManagement/MoveSequence/" + id,
      body
    );
  }

  addMorePrivateUsers(workspaceId: any, body: any): Observable<any> {
    return this.http.put(
      environment.baseUrl +
        "ProjectManagement/addMorePrivateUsers/" +
        workspaceId,
      body
    );
  }

  removeProjectCreator(workspaceId: any, projectCreatorId): Observable<any> {
    return this.http.delete(
      environment.baseUrl +
        "ProjectManagement/RemoveProjectCreator/" +
        workspaceId +
        "/" +
        projectCreatorId
    );
  }

  ChangeStatusFlowOptionToDefault(workspaceId: any): Observable<any> {
    var body;
    return this.http.put(
      environment.baseUrl +
        "ProjectManagement/ChangeStatusFlowOptionToDefault/" +
        workspaceId,

      body
    );
  }

  ChangeStatusFlowOptionToCustom(workspaceId: any, body: any): Observable<any> {
    return this.http.put(
      environment.baseUrl +
        "ProjectManagement/ChangeStatusFlowOptionToCustom/" +
        workspaceId,
      body
    );
  }

  changePrivateToPublic(workspaceId: any, body: any): Observable<any> {
    return this.http.put(
      environment.baseUrl + "ProjectManagement/UpdateToPublic/" + workspaceId,
      body
    );
  }

  pickUpTask(taskId: any, body?: any): Observable<any> {
    return this.http.put(
      environment.baseUrl + "ProjectManagement/PickUpTask/" + taskId,
      body
    );
  }

  dropTask(taskId: any, taskOwnerId: any, body?: any): Observable<any> {
    return this.http.put(
      environment.baseUrl +
        "ProjectManagement/DropTask/" +
        taskId +
        "/" +
        taskOwnerId,
      body
    );
  }

  updateStatus(statusFlow: any, workspaceId: any, body: any): Observable<any> {
    return this.http.put(
      environment.baseUrl +
        "ProjectManagement/UpdateStatus/" +
        workspaceId +
        "/" +
        statusFlow,
      body
    );
  }

  removePrivateUsers(workspaceId: any, privateUserId): Observable<any> {
    console.log("gotten at the service layer", workspaceId, privateUserId);
    return this.http.delete(
      environment.baseUrl +
        "ProjectManagement/DisablePrivateUser/" +
        workspaceId +
        "/" +
        privateUserId
    );
  }

  removestatus(workspaceId: any, statusId: any): Observable<any> {
    console.log("gotten at the service layer", workspaceId, statusId);
    return this.http.delete(
      environment.baseUrl +
        "ProjectManagement/DisableStatus/" +
        workspaceId +
        "/" +
        statusId
    );
  }

  getAllManagers(): Observable<any> {
    return this.http.get(
      environment.baseUrl + "ProjectManagement/GetAllProjectManagers"
    );
  }

  getAllPickedTask(): Observable<any> {
    return this.http.get(
      environment.baseUrl + "ProjectManagement/GetAllPickedTask"
    );
  }

  getAllCustomerDivison(): Observable<any> {
    return this.http.get(
      environment.baseUrl + "ProjectManagement/GetCustomerDivision"
    );
  }

  detachFromCategory(
    data: any,
    categoryId: any,
    projectAllocationId
  ): Observable<any> {
    return this.http.delete(
      environment.baseUrl +
        "ProjectManagement/" +
        data +
        "/" +
        categoryId +
        "/" +
        projectAllocationId
    );
  }

  disableWorkspace(id: any): Observable<any> {
    return this.http.delete(
      environment.baseUrl + "ProjectManagement/workspace/" + id
    );
  }

  createProjectFufilment(body): Observable<any> {
    return this.http.post(
      environment.baseUrl + "ProjectManagement/ResolveQuotesToProjects",
      body
    );
  }

  getLazyFilesystem() {
    return this.http
      .get<any>("assets/demo/data/filesystem-lazy.json")
      .toPromise()
      .then((res) => res.data as TreeNode[]);
  }

  sendClickEvent() {
    this.subject.next();
  }

  getClickEvent(): Observable<any> {
    return this.subject.asObservable();
  }

  createComment(body: any, projectId: any): Observable<any> {
    return this.http.post(
      environment.baseUrl + "ProjectManagement/CreateComments/" + projectId,
      body
    );
  }

  createTaskComment(body: any, taskId: any): Observable<any> {
    return this.http.post(
      environment.baseUrl + "ProjectManagement/CreateTaskComments/" + taskId,
      body
    );
  }

  getAllComments(): Observable<any> {
    return this.http.get(
      environment.baseUrl + "ProjectManagement/GetAllProjectsComment"
    );
  }

  getAllTaskComments(): Observable<any> {
    return this.http.get(
      environment.baseUrl + "ProjectManagement/GetAllTaskComments"
    );
  }

  disableProjectComment(commentId: any): Observable<any> {
    return this.http.delete(
      environment.baseUrl + "ProjectManagement/DisableComment/" + commentId
    );
  }

  disableTaskComment(commentId: any): Observable<any> {
    return this.http.delete(
      environment.baseUrl + "ProjectManagement/DisableTaskComment/" + commentId
    );
  }
}
