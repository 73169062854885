import {
  ApprovalConfig,
  ApprovingOffice,
  ApprovingOfficer,
  CentralStore,
  CreateApprovalConfiguration,
  CreateApprovingOffice,
  InventoryStores,
  Item,
  ItemGroup,
  RegionalStore,
  StoreManager,
} from "./../../interfaces/inventory";
import { InventoryService } from "./../../services/inventory.service";
import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { ConfirmationService, MessageService } from "primeng/api";
import { BreadcrumbService } from "src/app/breadcrumb.service";
import { finalize } from "rxjs/operators";
import {
  Account,
  CommonResponse,
  ControlAccount,
  User,
} from "src/app/interfaces/home";
import { UserService } from "src/app/services/user.service";
import { FileStorageService } from "src/app/services/file-storage.service";
import { ControlAccountService } from "src/app/services/control-account.service";

@Component({
  selector: "app-inventory-management",
  templateUrl: "./inventory-management.component.html",
  styleUrls: ["./inventory-management.component.scss"],
})
export class InventoryManagementComponent implements OnInit {
  @ViewChild("formWrapper") public formWrapper: ElementRef;
  itemForm: FormGroup;
  itemGroupForm: FormGroup;
  storeManagerForm: FormGroup;
  approvingOfficeForm: FormGroup;
  approvalConfigForm: FormGroup;
  itemCols: any[];
  itemGroupCols: any[];
  storeManagerCols: any[];
  addedAppOfficerCols: any[];
  addedAppOfficeCols: any[];
  approvalOfficeCols: any[];
  approvalConfigCols: any[];
  editingItem = false;
  editingItemGroup = false;
  fetchingItem = true;
  fetchingItemGroup = true;
  fetchingStoreManagers = true;
  fetchingApprovalOffices = true;
  fetchingApprovalConfig = true;
  itemCategories: {
    id: number;
    label: string;
  }[];
  selectedItemCategory: {
    id: number;
    label: string;
  };
  itemGroups: ItemGroup[];
  selectedItemGroup: ItemGroup;
  theItemGroup: ItemGroup;
  items: Item[];
  selectedItem: Item;
  itemGroupToEdit: ItemGroup;
  itemToEdit: Item;
  categoryRadioButton: number;
  imageSrc: string;
  uploadedItemImage: any[] = [];
  itemImageIsChanged: boolean;
  allUsers: User[];
  theUser: User;
  theApprovingUser: User;
  allStoreManagers: StoreManager[];
  selectedStoreManager: StoreManager;
  openStoresDialogue: boolean;
  storeMangerStores: InventoryStores;
  fetchingStoreManagerStores: boolean;
  editingApprovingOffice: boolean;
  isForCentralStore: boolean = false;
  isForConfigCentralStore: boolean = false;
  isForRestockingStore: boolean = false;
  allCentralStores: CentralStore[] = [];
  theCentralStore: CentralStore;
  theConfigCentralStore: CentralStore;
  allRegionalStores: RegionalStore[] = [];
  theRegionalStore: RegionalStore;
  theConfigRegionalStore: RegionalStore;
  addedApprovingOfficers: ApprovingOfficer[];
  selectedApprovingOfficer: ApprovingOfficer;
  allApprovingOffice: ApprovingOffice[];
  approvingOfficeToShow: ApprovingOffice[];
  theApprovingOffice: ApprovingOffice;
  selectedApprovingOffice: ApprovingOffice;
  approvingOfficeToEdit: ApprovingOffice;
  addedApprovingOffice: ApprovingOffice[];
  selectedAddedAppOffice: ApprovingOffice;
  editingApprovalConfig: boolean;
  allApprovalConfigs: ApprovalConfig[];
  selectedApprovalConfig: ApprovalConfig;
  approvalConfigToEdit: ApprovalConfig;
  allConsumptionAccounts: Account[]; // Expense Accounts
  theConusmptionAccount: Account;
  allCreditAccounts: Account[]; // Asset Accounts
  theCreditAccount: Account;
  readonly ASSETS = "ASSETS";
  readonly EXPENSES = "EXPENSES";

  constructor(
    private fb: FormBuilder,
    public controlAccountService: ControlAccountService,
    public fileStorageService: FileStorageService,
    public inventoryService: InventoryService,
    public userService: UserService,
    private breadcrumbService: BreadcrumbService,
    public confirmationService: ConfirmationService,
    public messageService: MessageService
  ) {
    this.itemGroupForm = fb.group({
      Caption: ["", Validators.required],
      Description: ["", Validators.required],
      ConsumptionAccount: ["", Validators.required],
      CreditAccount: ["", Validators.required],
    });

    this.itemForm = fb.group({
      Name: ["", Validators.required],
      Description: ["", Validators.required],
      ImageUrl: [""],
      ItemGroup: [""],
      ItemCategory: [""],
    });

    this.storeManagerForm = fb.group({
      Manager: ["", Validators.required],
    });

    this.approvingOfficeForm = fb.group({
      CentralStore: [""],
      RegionalStore: [""],
      IsForCentral: [""],
      ApprovingUser: [""],
      OfficeName: ["", Validators.required],
    });

    this.approvalConfigForm = fb.group({
      CentralStore: [""],
      RegionalStore: [""],
      IsForConfigCentral: [""],
      ApprovingOffice: [""],
      IsForRestocking: [""],
    });
  }

  ngOnInit(): void {
    this.breadcrumbService.setItems([
      {
        label: "Inventory Management",
        routerLink: ["/home/inventory-management/set-up"],
      },
      {
        label: "Set-Up",
        routerLink: ["/home/inventory-management/set-up"],
      },
    ]);

    this.itemCategories = [
      {
        id: 1,
        label: "CAPEX",
      },
      {
        id: 2,
        label: "OPEX",
      },
    ];

    this.itemGroupCols = [
      { field: "caption", header: "Caption" },
      { field: "description", header: "Description" },
    ];

    this.itemCols = [
      { field: "caption", header: "Caption" },
      { field: "description", header: "Description" },
      { field: "code", header: "Code" },
    ];

    this.storeManagerCols = [
      { field: "manager.lastName", header: "Manager.lastName" },
      { field: "manager.firstName", header: "Manager.firstName" },
      { field: "createdAt", header: "CreatedAt" },
    ];

    this.addedAppOfficerCols = [
      { field: "userProfile.lastName", header: "UserProfile.lastName" },
      { field: "createdAt", header: "CreatedAt" },
    ];

    this.approvalOfficeCols = [
      {
        field: "GetRegionalStoreDetails(regionalStoreId)",
        header: "RegionalStoreId",
      },
      { field: "name", header: "Name" },
    ];

    this.approvalConfigCols = [
      {
        field: "approvingOffice.name",
        header: "ApprovingOffice.name",
      },
    ];

    this.isForRestockingStore = false;
    this.isForConfigCentralStore = false;
    this.addedAppOfficeCols = [{ field: "name", header: "name" }];

    this.addedApprovingOfficers = [];
    this.addedApprovingOffice = [];
    this.FetchAssetAccounts();
    this.FetchExpenseAccounts();
    this.FetchAllItemGroup();
    this.FetchAllItems();
    this.FetchAllStoreManagers();
    this.FetchAllUsers();
    this.FetchAllInventoryStores();
    this.FetchAllApprovingOffices();
    this.FetchAllApprovalConfigurations();
  }

  FetchAllItems() {
    this.inventoryService.GetAllItem().subscribe(
      (data) => {
        this.items = data;
        this.fetchingItem = false;
      },
      (error) => {
        this.messageService.add({
          severity: "error",
          summary: "Failed",
          detail:
            "Connection Error, Unable to fetch all items " +
            (error ? error.error.message : "request failed - permission"),
        });
        this.fetchingItem = false;
      }
    );
  }

  FetchAllItemGroup() {
    this.inventoryService.GetAllItemGroup().subscribe(
      (data) => {
        this.itemGroups = data;
        this.fetchingItemGroup = false;
      },
      (error) => {
        this.messageService.add({
          severity: "error",
          summary: "Failed",
          detail:
            "Connection Error, Unable to fetch all item groups " +
            (error ? error.error.message : "request failed - permission"),
        });
        this.fetchingItemGroup = false;
      }
    );
  }

  async FetchAssetAccounts() {
    this.controlAccountService
      .GetAllByAccountClassCaption(this.ASSETS)
      .subscribe(
        async (data) => {
          if (data.responseCode != "00") {
            this.messageService.add({
              severity: "error",
              summary: data.responseMsg,
            });
            return;
          }

          let controlAccts: ControlAccount[] = [];
          data.responseData.forEach((x) => controlAccts.push(x));
          this.allCreditAccounts = [];
          controlAccts.forEach((x) =>
            this.allCreditAccounts.push(...x.accounts)
          );
        },
        (error) => {
          console.log("Error: " + JSON.stringify(error));
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail:
              "Unable to get all accounts.. Reason: [" + error
                ? error.error.message
                : "request failed - permission" + "]",
          });
        }
      );
  }

  async FetchExpenseAccounts() {
    this.controlAccountService
      .GetAllByAccountClassCaption(this.EXPENSES)
      .subscribe(
        async (data) => {
          if (data.responseCode != "00") {
            this.messageService.add({
              severity: "error",
              summary: data.responseMsg,
            });
            return;
          }

          let controlAccts: ControlAccount[] = [];
          data.responseData.forEach((x) => controlAccts.push(x));
          this.allConsumptionAccounts = [];
          controlAccts.forEach((x) =>
            this.allConsumptionAccounts.push(...x.accounts)
          );
        },
        (error) => {
          console.log("Error: " + JSON.stringify(error));
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail:
              "Unable to get all accounts.. Reason: [" + error
                ? error.error.message
                : "request failed - permission" + "]",
          });
        }
      );
  }

  async CreateItem() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Creating Inventory Item...",
    });

    const postData: Item = {
      name: this.itemForm.get("Name").value,
      description: this.itemForm.get("Description").value,
      itemGroupId: this.theItemGroup.id,
      itemCategory: this.categoryRadioButton,
    };

    this.fileStorageService.UploadFileFromDataUrl(this.uploadedItemImage[0]);
    this.fileStorageService.onUploadFinished.subscribe(
      (resp: CommonResponse<string>) => {
        if (resp.responseCode != "00") {
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail: resp.responseMsg,
          });
        } else {
          let url = resp.responseData;

          // post the form data
          postData.itemImage = url;
          this.inventoryService.CreateItem(postData).subscribe(
            async () => {
              await this.messageService.add({
                severity: "success",
                summary: "Completed",
                detail: "New Inventory Item Created...",
              });

              this.itemForm.reset();
              this.FetchAllItems();
              this.uploadedItemImage = [];
              this.imageSrc = "";
            },
            (error) => {
              this.messageService.add({
                severity: "error",
                summary: "Notice",
                detail:
                  "Unable to create item at the moment.. Reason: [" + error
                    ? error.error.message
                    : "request failed - permission" + "]",
              });
            }
          );
        }
      },
      (error) => {
        console.log("Error while uploading file " + error);
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail: "ERR: Unable to upload image to storage",
        });
      }
    );
  }

  CreateItemGroup() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Creating Inventory Item Group...",
    });

    const postData: ItemGroup = {
      caption: this.itemGroupForm.get("Caption").value,
      description: this.itemGroupForm.get("Description").value,
      consumptionAccountId: this.theConusmptionAccount.id,
      creditAccountId: this.theCreditAccount.id,
    };

    this.inventoryService.CreateItemGroup(postData).subscribe(
      async () => {
        await this.messageService.add({
          severity: "success",
          summary: "Completed",
          detail: "New Inventory Item Group Created...",
        });

        this.itemGroupForm.reset();
        this.theConusmptionAccount = null;
        this.theCreditAccount = null;
        this.FetchAllItemGroup();
      },
      (error) => {
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to create item group at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  EditItem(item: Item) {
    this.editingItem = true;
    this.itemForm.addControl(
      "ID",
      new FormControl({ value: "", disabled: true }, Validators.required)
    );
    this.itemToEdit = item;
    this.itemForm.patchValue({
      ID: item.id,
      Name: item.caption,
      Description: item.description,
      ImageUrl: item.itemImage,
    });
    this.imageSrc = item.itemImage;
    this.categoryRadioButton = item.itemCategory;
    this.theItemGroup = this.itemGroups.find(
      (data) => data.id == item.itemGroupId
    );
    this.formWrapper.nativeElement.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "start",
    });
  }

  EditItemGroup(item: ItemGroup) {
    this.editingItemGroup = true;
    this.itemGroupForm.addControl(
      "ID",
      new FormControl({ value: "", disabled: true }, Validators.required)
    );
    this.itemGroupToEdit = item;
    this.itemGroupForm.patchValue({
      ID: item.id,
      Caption: item.caption,
      Description: item.description,
    });
    this.theConusmptionAccount = this.allConsumptionAccounts.find(
      (x) => x.id == item.consumptionAccountId
    );
    this.theCreditAccount = this.allCreditAccounts.find(
      (x) => x.id == item.creditAccountId
    );
    this.formWrapper.nativeElement.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "start",
    });
  }

  async UpdateItem() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Updating Selected Inventory Item...",
    });
    const id = this.itemToEdit.id;
    const postData: Item = {
      name: this.itemForm.get("Name").value,
      description: this.itemForm.get("Description").value,
      itemGroupId: this.theItemGroup.id,
      itemCategory: this.categoryRadioButton,
    };

    if (this.itemImageIsChanged) {
      this.fileStorageService.UploadMultipleFilesFromDataUrl(
        this.uploadedItemImage
      );
      this.fileStorageService.onUploadFinished.subscribe(
        (resp: CommonResponse<string[]>) => {
          if (resp.responseCode != "00") {
            this.messageService.add({
              severity: "error",
              summary: "Notice",
              detail: resp.responseMsg,
            });
          } else {
            let url = resp.responseData;

            // post the form data
            postData.itemImage = url[0];
            this._updateItem(id, postData);
          }
        },
        (error) => {
          console.log("Error while uploading file " + error);
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail: "ERR: Unable to upload image to storage",
          });
        }
      );
    } else {
      postData.itemImage = this.itemToEdit.itemImage;
      this._updateItem(id, postData);
    }
  }

  async _updateItem(id: number, postData: Item) {
    this.inventoryService.UpdateInventoryItem(id, postData).subscribe(
      async () => {
        await this.messageService.add({
          severity: "success",
          summary: "Completed",
          detail: " Inventory Item Updated...",
        });

        this.CloseItemEditing();
        this.FetchAllItems();
      },
      (error) => {
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to update item at the moment.. Reason: [" + error
              ? error.error.message
              : "request failed - permission" + "]",
        });
      }
    );
  }

  UpdateItemGroup() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Updating Selected Inventory Item Group...",
    });
    const id = this.itemGroupToEdit.id;
    const postData: ItemGroup = {
      caption: this.itemGroupForm.get("Caption").value,
      description: this.itemGroupForm.get("Description").value,
      consumptionAccountId: this.theConusmptionAccount.id,
      creditAccountId: this.theCreditAccount.id,
    };
    this.inventoryService.UpdateItemGroup(id, postData).subscribe(
      async () => {
        this.messageService.add({
          severity: "success",
          summary: "Notice",
          detail: "Update Successful!",
        });
        await this.FetchAllItemGroup();
        await this.CloseItemGroupEditing();
      },
      (error) => {
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to update item group at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  DeleteItem(item: Item) {
    this.confirmationService.confirm({
      message: "Are you sure you want to remove " + item.name,
      accept: () => {
        this.messageService.add({
          severity: "info",
          summary: "Notice",
          detail: "Removing item " + item.name,
        });
      },
    });
  }

  DeleteItemGroup(item: ItemGroup) {
    this.confirmationService.confirm({
      message: "Are you sure you want to remove " + item.caption,
      accept: () => {
        this.messageService.add({
          severity: "info",
          summary: "Notice",
          detail: "Removing item group " + item.caption,
        });
        this.inventoryService.DeleteItemGroup(item.id).subscribe(
          async () => {
            await this.messageService.add({
              severity: "success",
              summary: "Removed",
              detail: item.caption + " removed successfully",
            });
            await this.FetchAllItemGroup();
          },
          (error) => {
            this.messageService.add({
              severity: "error",
              summary: "Notice",
              detail:
                "Unable to update item group at the moment.. Reason: [" + error
                  ? error.error.message
                  : "request failed - permission" + "]",
            });
          }
        );
      },
    });
  }

  onUpload(event, form) {
    this.uploadedItemImage = [];
    for (const file of event.files) {
      this.uploadedItemImage.push(file);
    }
    this.imageSrc = this.uploadedItemImage[0].objectURL;
    this.itemImageIsChanged = true;

    this.messageService.add({
      severity: "success",
      summary: "Success",
      detail: "Imgage Uploaded Successfully!",
    });

    form.clear();
    //this.userIsYetToClickUpload = false;
  }

  FetchAllStoreManagers() {
    this.inventoryService.GetAllStoreManagers().subscribe(
      (data) => {
        this.allStoreManagers = data;
        this.fetchingStoreManagers = false;
      },
      (error) => {
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all store managers at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  MakeStoreManager() {
    this.confirmationService.confirm({
      message:
        "Are you sure you want to make " +
        this.theUser.lastName +
        " " +
        this.theUser.firstName +
        " a store manager",
      accept: () => {
        this.messageService.add({
          severity: "info",
          summary: "Notice",
          detail: "Making user a store manager ",
        });
        this.inventoryService.MakeUserAStoreManager(this.theUser.id).subscribe(
          async () => {
            await this.messageService.add({
              severity: "success",
              summary: "Removed",
              detail: this.theUser.lastName + " is now a store manager.",
            });
            await this.FetchAllStoreManagers();
          },
          (error) => {
            this.messageService.add({
              severity: "error",
              summary: "Notice",
              detail:
                "Unable to make user a store manager at the moment.. Reason: [" +
                error
                  ? error.error.message
                  : "request failed - permission" + "]",
            });
          }
        );
      },
    });
  }

  FetchAllUsers() {
    this.userService.allUser().subscribe(
      async (data) => {
        this.allUsers = [];
        data.responseData.forEach((user) => {
          user.fullName = user.lastName + " " + user.firstName;
          this.allUsers.push(user);
        });
        //this.allUsers = data;
      },
      () => {
        this.ConnectionError();
      }
    );
  }

  CloseItemEditing() {
    this.itemImageIsChanged = false;
    this.imageSrc = null;
    this.editingItem = false;
    this.itemForm.reset();
    this.itemToEdit = null;
    this.uploadedItemImage = [];
  }

  CloseItemGroupEditing() {
    this.editingItemGroup = false;
    this.theConusmptionAccount = null;
    this.theCreditAccount = null;
    this.itemGroupForm.reset();
    this.itemGroupToEdit = null;
  }

  ViewStores(item: StoreManager) {
    this.fetchingStoreManagerStores = true;
    this.openStoresDialogue = true;
    this.inventoryService.GetAllStoreManagerStores(item.id).subscribe(
      (data) => {
        console.log(data);
        this.fetchingStoreManagerStores = false;
        this.storeMangerStores = data;
      },
      (error) => {
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to make get all store manager stores at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
        this.fetchingStoreManagerStores = false;
      }
    );
  }

  HideStoresDialog() {
    this.openStoresDialogue = false;
    this.storeMangerStores = {
      centralStores: [],
      regionalStores: [],
    };
    this.fetchingStoreManagerStores = false;
  }

  CreateApprovingOffice() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Creating Approving Office...",
    });

    let addedApproveOfficerIds: number[] = [];
    this.addedApprovingOfficers.forEach((officer) => {
      addedApproveOfficerIds.push(officer.userProfileId);
    });

    const postData: CreateApprovingOffice = {
      officeName: this.approvingOfficeForm.get("OfficeName").value,
      isForCentralStore: this.isForCentralStore,
      storeId: this.isForCentralStore
        ? this.theCentralStore.id
        : this.theRegionalStore.id,
      officersIds: addedApproveOfficerIds,
    };

    this.inventoryService.CreateInventoryApprovalOffice(postData).subscribe(
      async () => {
        await this.messageService.add({
          severity: "success",
          summary: "Completed",
          detail: "Approval Office Created Successfully.",
        });

        this.addedApprovingOfficers = [];
        this.FetchAllApprovingOffices();
        this.approvingOfficeForm.reset();
        this.isForCentralStore = false;
      },
      (error) => {
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to create approval office at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  SwitchIsCentral() {
    if (this.editingApprovingOffice) {
      this.messageService.add({
        severity: "error",
        summary: "Invalid",
        detail: "Apologies, you can't switch on Edit Mode.",
      });
      this.isForCentralStore = !this.isForCentralStore;
      return;
    }

    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail:
        "Switched to " +
        (this.isForCentralStore ? "central store" : "regional store"),
    });
  }

  SwitchIsConfigCentral() {
    if (this.editingApprovalConfig) {
      this.messageService.add({
        severity: "error",
        summary: "Invalid",
        detail: "Apologies, you can't switch on Edit Mode.",
      });
      this.isForConfigCentralStore = !this.isForConfigCentralStore;
      return;
    }

    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail:
        "Switched to " +
        (this.isForConfigCentralStore
          ? "config central store approval"
          : "config regional store approval"),
    });
  }

  SwitchIsForRestocking() {
    if (this.editingApprovalConfig) {
      this.messageService.add({
        severity: "error",
        summary: "Invalid",
        detail: "Apologies, you can't switch on Edit Mode.",
      });
      this.isForRestockingStore = !this.isForRestockingStore;
      return;
    }

    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail:
        "Switched to " +
        (this.isForRestockingStore
          ? "configuring approval for restocking."
          : "configuring approval for request out of store."),
    });
  }

  FetchAllInventoryStores() {
    this.inventoryService.GetAllInventoryStores().subscribe(
      (data) => {
        this.allCentralStores = data.centralStores;
        this.allRegionalStores = data.regionalStores;
      },
      (error) => {
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all inventory stores at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  AddApprovingOfficerToList() {
    if (!this.ApprovingUser) {
      this.messageService.add({
        severity: "error",
        summary: "Failed",
        detail: "You must select the officer to add",
      });
      return;
    }

    let alreadyAdded = false;
    this.addedApprovingOfficers.forEach((item) => {
      if (item.userProfileId == this.ApprovingUser.id) {
        alreadyAdded = true;
      }
    });

    if (alreadyAdded) {
      this.messageService.add({
        severity: "error",
        summary: "Failed",
        detail: "Officer with the same info has already been added to list.",
      });
      return;
    }

    this.addedApprovingOfficers.push({
      userProfileId: this.ApprovingUser.id,
      userProfile: this.ApprovingUser,
    });

    this.messageService.add({
      severity: "success",
      summary: "Added",
      detail: "Approving Officer added successfully",
    });
  }

  AddApprovingOfficeToList() {
    if (!this.ApprovingOffice) {
      this.messageService.add({
        severity: "error",
        summary: "Failed",
        detail: "You must select the office to add",
      });
      return;
    }

    let alreadyAdded = false;
    this.addedApprovingOffice.forEach((item) => {
      if (item.id == this.ApprovingOffice.id) {
        alreadyAdded = true;
      }
    });

    if (alreadyAdded) {
      this.messageService.add({
        severity: "error",
        summary: "Failed",
        detail: "Office with the same info has already been added to list.",
      });
      return;
    }

    this.addedApprovingOffice.push(this.ApprovingOffice);
    this.messageService.add({
      severity: "success",
      summary: "Added",
      detail: "Approving Office added successfully",
    });
  }

  RemoveOfficer(item: ApprovingOfficer) {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Removing Approving Officer...",
    });

    const index = this.addedApprovingOfficers.indexOf(item);
    if (index > -1) {
      this.addedApprovingOfficers.splice(index, 1);
    }

    this.messageService.add({
      severity: "success",
      summary: "Removed",
      detail: "Approving Officer Removed successfully",
    });
  }

  RemoveOffice(item: ApprovingOffice) {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Removing Approving Office...",
    });

    const index = this.addedApprovingOffice.indexOf(item);
    if (index > -1) {
      this.addedApprovingOffice.splice(index, 1);
    }

    this.messageService.add({
      severity: "success",
      summary: "Removed",
      detail: "Approving Office Removed successfully",
    });
  }

  get ApprovingUser() {
    return this.approvingOfficeForm.get("ApprovingUser").value;
  }

  get ApprovingOffice() {
    return this.approvalConfigForm.get("ApprovingOffice").value;
  }

  FetchAllApprovingOffices() {
    this.inventoryService.GetAllInventoryApprovalOffices().subscribe(
      (data) => {
        this.allApprovingOffice = data;
        this.approvingOfficeToShow = data;
        this.fetchingApprovalOffices = false;
      },
      (error) => {
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all inventory approval offices at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
        this.fetchingApprovalOffices = false;
      }
    );
  }

  EditApprovalOffice(item: ApprovingOffice) {
    this.editingApprovingOffice = true;
    this.approvingOfficeForm.addControl(
      "ID",
      new FormControl({ value: "", disabled: true }, Validators.required)
    );
    this.approvingOfficeForm.patchValue({
      ID: item.id,
      OfficeName: item.name,
    });
    this.approvingOfficeToEdit = item;
    this.isForCentralStore = item.requestType == 1 ? false : true;
    this.theRegionalStore = this.isForCentralStore
      ? null
      : this.allRegionalStores.find((data) => data.id == item.regionalStoreId);
    this.theCentralStore = this.isForCentralStore
      ? this.allCentralStores.find((data) => data.id == item.centralStoreId)
      : null;
    this.addedApprovingOfficers = item.approvingOfficers;
    this.addedApprovingOfficers.forEach((data) => {
      data.userProfile = this.allUsers.find(
        (appUser) => appUser.id == data.userProfileId
      );
    });

    this.formWrapper.nativeElement.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "start",
    });
  }

  EditApprovalConfig(item: ApprovalConfig) {
    this.editingApprovalConfig = true;
    this.approvalConfigForm.addControl(
      "ID",
      new FormControl({ value: "", disabled: true }, Validators.required)
    );

    this.approvalConfigToEdit = item;
    this.isForConfigCentralStore = item.requestType == 1 ? false : true;
    this.theConfigRegionalStore = this.isForConfigCentralStore
      ? null
      : this.allRegionalStores.find((data) => data.id == item.regionalStoreId);
    this.theConfigCentralStore = this.isForConfigCentralStore
      ? this.allCentralStores.find((data) => data.id == item.centralStoreId)
      : null;
    this.isForRestockingStore = item.isRestocking;
    this.addedApprovingOffice = [];
    item.approvalConfigurationEntities.forEach((appConfigEntity) => {
      this.addedApprovingOffice.push(
        this.allApprovingOffice.find(
          (office) => office.id == appConfigEntity.approvingOfficeId
        )
      );
    });

    this.formWrapper.nativeElement.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "start",
    });
  }

  UpdateApprovalOffice() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Updating Inventory Approval Office...",
    });

    let addedApproveOfficerIds: number[] = [];
    this.addedApprovingOfficers.forEach((officer) => {
      addedApproveOfficerIds.push(officer.userProfileId);
    });

    const id = this.approvingOfficeToEdit.id;
    const postData: CreateApprovingOffice = {
      officeName: this.approvingOfficeForm.get("OfficeName").value,
      isForCentralStore: this.isForCentralStore,
      storeId: this.isForCentralStore
        ? this.theCentralStore.id
        : this.theRegionalStore.id,
      officersIds: addedApproveOfficerIds,
    };

    this.inventoryService.UpdateInventoryApprovalOffice(id, postData).subscribe(
      async () => {
        await this.messageService.add({
          severity: "success",
          summary: "Completed",
          detail: "Approval Office Updated Successfully.",
        });

        this.addedApprovingOfficers = [];
        this.FetchAllApprovingOffices();
        this.editingApprovingOffice = false;
        this.approvingOfficeForm.reset();
        this.isForCentralStore = false;
      },
      (error) => {
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to update approval office at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  UpdateApprovalConfig() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Updating Approval Configuration...",
    });

    this.messageService.add({
      severity: "error",
      summary: "Notice",
      detail:
        "Unable to update approval configuration at the moment.. Reason: [This action has been blocked by admin]",
    });
    return;

    let addedApproveOfficeIds: number[] = [];
    this.addedApprovingOffice.forEach((office) => {
      addedApproveOfficeIds.push(office.id);
    });

    const id = this.approvalConfigToEdit.id;
    const postData: CreateApprovalConfiguration = {
      isForCentralStore: this.isForConfigCentralStore,
      storeId: this.isForConfigCentralStore
        ? this.theConfigCentralStore.id
        : this.theConfigRegionalStore.id,
      officesIds: addedApproveOfficeIds,
      isRestocking: this.isForRestockingStore,
    };

    this.inventoryService
      .UpdateInventoryApprovalConfiguration(id, postData)
      .subscribe(
        async () => {
          await this.messageService.add({
            severity: "success",
            summary: "Completed",
            detail: "Approval Configuration Updated Successfully.",
          });

          this.addedApprovingOffice = [];
          this.FetchAllApprovalConfigurations();
          this.editingApprovalConfig = false;
          this.approvalConfigForm.reset();
        },
        (error) => {
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail:
              "Unable to update approval configuration at the moment.. Reason: [" +
              error
                ? error.error.message
                : "request failed - permission" + "]",
          });
        }
      );
  }

  DeleteApprovalOffice(item: ApprovingOffice) {
    this.confirmationService.confirm({
      message: "Are you sure you want to remove approving office?",
      accept: () => {
        this.messageService.add({
          severity: "info",
          summary: "Notice",
          detail: "Removing approving office...",
        });
        this.inventoryService.RemoveInventoryApprovingOffice(item.id).subscribe(
          async () => {
            await this.messageService.add({
              severity: "success",
              summary: "Removed",
              detail: "Removed successfully",
            });
            this.FetchAllApprovingOffices();
          },
          (error) => {
            this.messageService.add({
              severity: "error",
              summary: "Notice",
              detail:
                "Unable to remove inventory approving offices at the moment.. Reason: [" +
                error
                  ? error.error.message
                  : "request failed - permission" + "]",
            });
          }
        );
      },
    });
  }

  DeleteApprovalConfig(item: ApprovalConfig) {
    this.confirmationService.confirm({
      message: "Are you sure you want to remove approval configuration?",
      accept: () => {
        this.messageService.add({
          severity: "info",
          summary: "Notice",
          detail: "Removing approval configuration...",
        });
        this.inventoryService
          .RemoveInventoryApprovalConfiguration(item.id)
          .subscribe(
            async () => {
              await this.messageService.add({
                severity: "success",
                summary: "Removed",
                detail: "Removed successfully",
              });
              this.FetchAllApprovalConfigurations();
            },
            (error) => {
              this.messageService.add({
                severity: "error",
                summary: "Notice",
                detail:
                  "Unable to remove inventory approval configuration at the moment.. Reason: [" +
                  error
                    ? error.error.message
                    : "request failed - permission" + "]",
              });
            }
          );
      },
    });
  }

  GetCentralStoreDetails(id: number): CentralStore {
    return this.allCentralStores.find((data) => data.id == id);
  }

  GetRegionalStoreDetails(id: number): RegionalStore {
    return this.allRegionalStores.find((data) => data.id == id);
  }

  CloseEditingApprovalOffice() {
    this.editingApprovingOffice = false;
    this.approvingOfficeForm.reset();
    this.addedApprovingOfficers = [];
    this.approvingOfficeToEdit = null;
  }

  CloseEditingApprovalConfig() {
    this.editingApprovalConfig = false;
    this.approvalConfigForm.reset();
    this.addedApprovingOffice = [];
    this.approvalConfigToEdit = null;
  }

  CreateApprovalConfiguration() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Creating Approval Configuration...",
    });

    let addedApprovingOfficeIds: number[] = [];
    this.addedApprovingOffice.forEach((office) => {
      addedApprovingOfficeIds.push(office.id);
    });

    const postData: CreateApprovalConfiguration = {
      isForCentralStore: this.isForConfigCentralStore,
      storeId: this.isForConfigCentralStore
        ? this.theConfigCentralStore.id
        : this.theConfigRegionalStore.id,
      officesIds: addedApprovingOfficeIds,
      isRestocking: this.isForRestockingStore,
    };

    this.inventoryService
      .CreateInventoryApprovalConfiguration(postData)
      .subscribe(
        async () => {
          await this.messageService.add({
            severity: "success",
            summary: "Completed",
            detail: "Approval Configuration Created Successfully.",
          });

          this.addedApprovingOffice = [];
          this.FetchAllApprovalConfigurations();
          this.approvalConfigForm.reset();
          this.isForConfigCentralStore = false;
          this.isForRestockingStore = false;
        },
        (error) => {
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail:
              "Unable to create approval configuration at the moment.. Reason: [" +
              error
                ? error.error.message
                : "request failed - permission" + "]",
          });
        }
      );
  }

  FetchAllApprovalConfigurations() {
    this.inventoryService.GetAllInventoryApprovalConfigurations().subscribe(
      (data) => {
        this.allApprovalConfigs = data;
        this.fetchingApprovalConfig = false;
      },
      (error) => {
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all inventory approval configurations at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
        this.fetchingApprovalOffices = false;
      }
    );
  }

  DecideOfficeToShowCentral() {
    this.approvingOfficeToShow = [];
    this.allApprovingOffice.find((data) => {
      if (data.centralStoreId == this.theConfigCentralStore.id) {
        this.approvingOfficeToShow.push(data);
      }
    });
  }

  DecideOfficeToShowRegion() {
    this.approvingOfficeToShow = [];
    this.allApprovingOffice.find((data) => {
      if (data.regionalStoreId == this.theConfigRegionalStore.id) {
        this.approvingOfficeToShow.push(data);
      }
    });
  }

  GetConsumptionAccount(accountId: number): string {
    let acct = this.allConsumptionAccounts.find((x) => x.id == accountId);
    if (acct) return acct.name;

    return "N/A";
  }

  GetCreditAccount(accountId: number): string {
    let acct = this.allCreditAccounts.find((x) => x.id == accountId);
    if (acct) return acct.name;

    return "N/A";
  }

  private ConnectionError() {
    this.messageService.add({
      severity: "error",
      summary: "Failed",
      detail: "Connection Error, Please try again",
    });
  }
}
