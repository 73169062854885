import {
  CentralStore,
  RegionalStore,
  SetStockPolicy,
  StoreItems,
} from "./../../../interfaces/inventory";
import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { MessageService, ConfirmationService, Message } from "primeng/api";
import { BreadcrumbService } from "src/app/breadcrumb.service";
import { InventoryService } from "src/app/services/inventory.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";

@Component({
  selector: "app-stock-policy",
  templateUrl: "./stock-policy.component.html",
  styleUrls: ["./stock-policy.component.scss"],
  providers: [MessageService],
})
export class StockPolicyComponent implements OnInit {
  @ViewChild("formWrapper") public formWrapper: ElementRef;
  form: FormGroup;
  isRegional: boolean;
  allCentralStores: CentralStore[];
  theCentralStore: CentralStore;
  allRegionalStores: RegionalStore[];
  theRegionalStore: RegionalStore;
  allStoreItems: StoreItems[];
  theStoreItem: StoreItems;
  isSettingStockPolicy: boolean = false;
  suggested: number = 0;
  msgs: Message[] = [];

  constructor(
    fb: FormBuilder,
    public inventoryService: InventoryService,
    public messageService: MessageService,
    private breadcrumbService: BreadcrumbService,
    public confirmationService: ConfirmationService
  ) {
    this.form = fb.group({
      AvgLeadTime: ["", Validators.required],
      AvgDailyDisbursements: ["", Validators.required],
      LeadTimeDemand: [""],
      MinStockLevel: ["", Validators.required],
      ReorderLevel: [""],
    });
  }

  ngOnInit(): void {
    this.breadcrumbService.setItems([
      {
        label: "Inventory Management",
        routerLink: ["/home/inventory-management/set-up"],
      },
      {
        label: "Stock Policy",
        routerLink: ["/home/inventory-management/stock-policy"],
      },
    ]);

    this.FetchAllInventoryStores();
  }

  FetchAllInventoryStores() {
    this.inventoryService.GetAllInventoryStores().subscribe(
      (data) => {
        this.allCentralStores = data.centralStores;
        this.allRegionalStores = data.regionalStores;
      },
      (error) => {
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all inventory stores at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  LoadStoreItems() {
    if (!this.isRegional) {
      this.inventoryService
        .GetAllCentralStoreItems(this.theCentralStore.id)
        .subscribe(
          (data) => {
            this.allStoreItems = data;
          },
          (error) => {
            this.messageService.add({
              severity: "error",
              summary: "Notice",
              detail:
                "Unable to fetch all store items in central store at the moment.. Reason: [" +
                error
                  ? error.error.message
                  : "request failed - permission" + "]",
            });
          }
        );
    } else {
      this.inventoryService
        .GetAllRegionStoreItems(this.theRegionalStore.id)
        .subscribe(
          (data) => {
            this.allStoreItems = data;
          },
          (error) => {
            this.messageService.add({
              severity: "error",
              summary: "Notice",
              detail:
                "Unable to fetch all store items in regional store at the moment.. Reason: [" +
                error
                  ? error.error.message
                  : "request failed - permission" + "]",
            });
          }
        );
    }
  }

  sleep(time) {
    return new Promise((resolve) => setTimeout(resolve, time));
  }

  SetStockPolicy() {
    const postData: SetStockPolicy = {
      storeItemId: this.theStoreItem.id,
      minimumStockLevel: this.MinimumStockLevel,
      reorderLevel:
        this.LeadTime * this.DailyDisbursed + this.MinimumStockLevel,
    };

    this.confirmationService.confirm({
      message: "Kindly confirm you want to proceed with this action?",
      accept: () => {
        this.messageService.add({
          severity: "info",
          summary: "Notice",
          detail: "Performing action...",
        });
        this.inventoryService.SetStockPolicy(postData).subscribe(
          async () => {
            await this.messageService.add({
              severity: "success",
              summary: "Completed",
              detail: "Action Successfull!.",
            });

            this.FetchAllInventoryStores();
            this.theStoreItem = null;
            this.isSettingStockPolicy = false;
            this.allStoreItems = [];
            this.theCentralStore = null;
            this.theRegionalStore = null;
            this.form.reset();
          },
          (error) => {
            this.messageService.add({
              severity: "error",
              summary: "Notice",
              detail:
                "Unable to perform action at the moment.. Reason: [" + error
                  ? error.error.message
                  : "request failed - permission" + "]",
            });
          }
        );
      },
    });
  }

  ViewSetStockPolicy() {
    this.isSettingStockPolicy = true; //And thestoreitem is not null for the view

    this.inventoryService
      .GetStoreItemAvgDailyDisbursementSuggestion(this.theStoreItem.id)
      .subscribe(
        (data) => {
          this.suggested = data;
          this.msgs = [];
          this.msgs.push({
            severity: "info",
            summary: "Suggestion:",
            detail: this.suggested + "",
            // detail:
            //   this.suggested +
            //   " (Total number of items given out this year divided by number of days spent in the year so far.)",
          });
        },
        (error) => {
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail:
              "Unable to get suggestion data at the moment.. Reason: [" + error
                ? error.error.message
                : "request failed - permission" + "]",
          });
        }
      );
  }

  get LeadTime() {
    return this.form.get("AvgLeadTime").value;
  }

  get DailyDisbursed() {
    return this.form.get("AvgDailyDisbursements").value;
  }

  get MinimumStockLevel() {
    return this.form.get("MinStockLevel").value;
  }
}
