import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {BreadcrumbService} from '../../../../breadcrumb.service';
import {Branch, Office, User} from '../../../../interfaces/home';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ConfirmationService, MessageService} from 'primeng/api';
import {CommanderRank, CommanderUserProfile} from '../../../../interfaces/armada';
import {CommanderRegistrationService} from '../../../../services/armada/commander-registration.service';
import {OfficeService} from '../../../../services/office.service';
import {BranchService} from '../../../../services/branch.service';
import {Subject} from 'rxjs';
import {CommanderRankService} from '../../../../services/armada/commander-rank.service';
import {takeUntil} from 'rxjs/operators';
import {UserService} from '../../../../services/armada/user.service';

@Component({
  selector: 'app-commander-registration',
  templateUrl: './commander-registration.component.html',
  styleUrls: ['./commander-registration.component.scss']
})
export class CommanderRegistrationComponent implements OnInit, OnDestroy {
  @ViewChild('formWrapper') public formWrapper:ElementRef;
  public userProfiles: User[];
  public selectedUserProfile: User = null;
  public commanderRegisterForm: FormGroup;
  public commanderUserProfiles: CommanderUserProfile[] = [];
  public submitting: boolean;
  public creatingCommanderProfile: boolean;
  public selectedUser: User | null;
  public commanderRanks: CommanderRank[];
  public selectedCommanderRank: CommanderRank;
  branches: Branch[];
  offices: Office[];
  private unsubscriber$ = new Subject<void>();
  private selectedBranch: Branch;
  userForm: FormGroup;
  selectedCommanderUserProfile: CommanderUserProfile;
  public loading: boolean;
  public targetCols: any;
  public exportTargetColumns: any;
  public loadingUsers: boolean;
  registeringCommander: boolean;

  constructor(
      private breadcrumbService: BreadcrumbService,
      private messageService: MessageService,
      private formBuilder: FormBuilder,
      private confirmationService: ConfirmationService,
      private commanderRegistrationService: CommanderRegistrationService,
      private officeService: OfficeService,
      private branchServices: BranchService,
      private commanderRankService: CommanderRankService,
      private userService: UserService,
  ) {
    this.breadcrumbService.setItems([
      { label: 'Secured Mobility Administration' },
      { label: 'Commander Registration' }
    ])
  }

  ngOnInit(): void {
    this.registeringCommander = false;
    this.loading = true;
    this.loadingUsers = true;
    this.targetCols = [
      { field: 'caption', header: 'Caption' },
      { field: 'description', header: 'Description' },
      { field: 'codeName', header: 'Code Name' },
    ];
    this.exportTargetColumns = this.targetCols.map(col => ({title: col.header, dataKey: col.field}));
    this.fetchBranches();
    this.fetchCommanderRanks();
    this.fetchUsers();
    this.fetchCommanderUserProfile();
    this.commanderRegisterForm = this.formBuilder.group({
      commanderRankId: ['', Validators.required],
      branchId: ['', Validators.required],
      officeId: ['', Validators.required],
      staffId: [{value:'', disabled:true}, Validators.required]
    })
    this.commanderRegisterForm.disable();
    this.userForm = this.formBuilder.group({
      userProfile: [''],
    })
    this.commanderRegisterForm.get('branchId').valueChanges.pipe()
        .subscribe( () => {
          this.selectedBranch = this.commanderRegisterForm.get('branchId').value;
          this.offices = this.selectedBranch?.offices;
        })
    this.userForm.valueChanges.pipe(takeUntil(this.unsubscriber$)).subscribe(() => {
     this.selectedUserProfile =  this.userForm.get('userProfile').value;
    })
    if(this.submitting){
      this.userForm.disable();
    } else {
      this.userForm.reset();
    }
  }



  fetchBranches() {
    this.branchServices.allBranch().subscribe( (res: any) => {
      this.branches = res;
    })
  }

  fetchCommanderUserProfile() {
    this.commanderRegistrationService.allCommanderUserProfile().pipe(takeUntil(this.unsubscriber$))
        .subscribe( (res: any) => {
          this.commanderUserProfiles = res;
          this.commanderUserProfiles.forEach(userProfile => {
            userProfile.fullName = `${userProfile.firstName} ${userProfile.lastName}`
          });
          this.loading = false;
        }, error => {
          this.connectionError();
          this.loading = false;
        })
  }

  fetchCommanderRanks() {
    this.commanderRankService.allCommanderRank().pipe(takeUntil(this.unsubscriber$))
        .subscribe( (res: any) => {
          this.commanderRanks = res;
        })
  }
  fetchUsers(){
    this.userService.getUnRegisteredUsers().pipe(takeUntil(this.unsubscriber$))
        .subscribe( (res: any) => {
          this.userProfiles = res;
          this.userProfiles.forEach(userProfile => {
            userProfile.fullName = `${userProfile.firstName} ${userProfile.lastName}`
          });
          this.loadingUsers = false;
        }, error => {
          this.connectionError();
          this.loadingUsers = false;
        });
  }
  registerCommander() {
    this.commanderRegisterForm.enable();
    this.commanderRegisterForm.get('staffId').disable();
    this.registeringCommander = true;
    this.formWrapper.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'end' });
  }
  createCommander(){
    this.submitting = true;
    const registrationFormValue = this.commanderRegisterForm.value;
    const formData = {
      codeName: this.selectedUserProfile.codeName,
      firstName: this.selectedUserProfile.firstName,
      lastName: this.selectedUserProfile.lastName,
      middleName: this.selectedUserProfile.otherName,
      dateOfBirth: this.selectedUserProfile.dateOfBirth,
      email: this.selectedUserProfile.email,
      mobileNumber: this.selectedUserProfile.mobileNumber,
      imageUrl: this.selectedUserProfile.imageUrl,
      address: this.selectedUserProfile.address,
      branchId: registrationFormValue.branchId.id,
      officeId: registrationFormValue.officeId.id,
      staffId: this.selectedUserProfile.staffId,
      userProfileId: this.selectedUserProfile.id,
      commanderRankId: registrationFormValue.commanderRankId.id,
    };
    this.commanderRegistrationService.postCommanderUserProfile(formData).pipe(takeUntil(this.unsubscriber$))
        .subscribe( () => {
          this.fetchCommanderUserProfile();
          this.submitting = false;
          this.commanderRegisterForm.reset();
          this.userForm.reset();
          this.selectedUserProfile = null;
          this.fetchUsers();
          this.messageService.add({
            severity: 'success',
            detail: 'New commander Registered',
            summary: 'Completed',
          });
        }, error => {
          this.connectionError();
          this.submitting = false;
        });
  }
  private connectionError() {
    this.messageService.add({severity:'error', summary: 'Failed',
      detail:'Connection Error, Please try again'});
  }
  ngOnDestroy(): void {
    this.unsubscriber$.complete();
    this.unsubscriber$.unsubscribe();
  }
}
