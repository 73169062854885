import { Component, Input, OnInit } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/storage';
import { UploadTaskSnapshot } from '@angular/fire/storage/interfaces';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { forkJoin, Observable } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';
import { CommonResponse, Constant, EngagementReason, EngagementType, Lead, LeadDivision, LeadDivisionContact, LeadDivisionKeyPerson, LeadEngagement, User } from 'src/app/interfaces/home';
import { EngagementReasonService } from 'src/app/services/engagement-reason.service';
import { EngagementTypeService } from 'src/app/services/engagement-type.service';
import { LeadDivisionService } from 'src/app/services/lead-division.service';
import { LeadEngagementService } from 'src/app/services/lead-engagement.service';
import { LeadService } from 'src/app/services/lead.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-capture-lead-engagement',
  templateUrl: './capture-lead-engagement.component.html',
  styleUrls: ['./capture-lead-engagement.component.scss'],
  providers: [Constant]
})
export class CaptureLeadEngagementComponent implements OnInit {
  @Input() leadCaptureStage: string;
  leadEngagementForm: FormGroup;
  currentLeadData: Lead;
  engagementTypes: EngagementType[];
  selectedEngagementType: EngagementType;
  engagementReasons: EngagementReason[];
  selectedEngagementReason: EngagementReason;
  editingLeadEngagement = false;
  leadEngagements: LeadEngagement[];
  users: User[] = [];
  selectedUsers: User[] = [];
  startUpData$: Observable<[CommonResponse, CommonResponse, CommonResponse]>;
  leadDivisionKeyPersons: LeadDivisionKeyPerson[] = [];
  selectedKeyPersons: LeadDivisionKeyPerson[] = [];
  leadDivisionContacts: LeadDivisionContact[] = [];
  selectedContacts: LeadDivisionContact[] = [];
  leadDivisions: LeadDivision[];
  selectedDivision: LeadDivision;
  selectedEngagements: any;
  loadingEngagements = false;
  engagementCols: any;
  exportEngagementColumns: any;
  edLeadEngagement: LeadEngagement;
  selectedFiles: File[] = [];
  documentUrls: string[] = [];
  settingUpStartUpData = false;

  constructor(
    private leadService: LeadService,
    private leadDivisionService: LeadDivisionService,
    private formBuilder: FormBuilder,
    private engagementTypeService: EngagementTypeService,
    private engagementReasonService: EngagementReasonService,
    private leadEngagementService: LeadEngagementService,
    private userService: UserService,
    private angularFireStorage: AngularFireStorage,
    private constants: Constant,
    private messageService: MessageService,
    private dialogService: DialogService,
    private confirmationService: ConfirmationService,
  ) { }

  ngOnInit(): void {
    this.leadEngagementForm = this.formBuilder.group({
      caption: ['', [Validators.required, Validators.minLength(3)]],
      engagementDiscussion: ['', [Validators.required, Validators.minLength(3)]],
      engagementType: [null, Validators.required],
      date: ['', Validators.required],
      reasonForEngagement: ['',  Validators.required],
      engagementOutcome: ['',  Validators.required],
      usersEngaged: [''],
      contactsEngaged: [''],
      keyPersonsEngaged: [''],
      documents: ['']
    });

    this.engagementCols = [
      { field: 'caption', header: 'Caption' },
      { field: 'engagementDiscussion', header: 'Engagement Discussion' },
      { field: 'engagementType', header: 'Engagement Type' },
      { field: 'date', header: 'Date' },
      { field: 'reasonForEngagement', header: 'Reason For Engagement' },
      { field: 'engagementOutcome', header: 'Engagement Outcome' },
    ];
    this.exportEngagementColumns = this.engagementCols.map(col => ({title: col.header, dataKey: col.field}));

    this.fetchStartUpData();
  }

  fetchStartUpData(){

    this.startUpData$ = forkJoin([
      this.userService.allUser(),
      this.engagementTypeService.allEngagementTypeData(),
      this.engagementReasonService.allEngagementReasonData()
    ]);

    this.settingUpStartUpData = true;
    this.startUpData$.subscribe(async (res) => {
      const [usersR, engagementTypesR, engagementReasonsR] = res;
      var users = usersR.responseData ?? [];
      users.forEach(user => {
        user.fullName = `${user.firstName} ${user.lastName}`
      });
      this.users = users;
      this.engagementTypes = engagementTypesR.responseData ?? [];
      this.engagementReasons = engagementReasonsR.responseData ?? [];
      this.fetchActiveLead();
    }, error => {
      this.settingUpStartUpData = false;
      this.connectionError();
    })
  }

  fetchActiveLead(){
    this.leadService.activeLead().subscribe(async (res: any) => {
      this.settingUpStartUpData = false;
      if(res?.responseCode=='00'){
        this.currentLeadData = res.responseData as Lead;
        this.leadDivisions = this.currentLeadData.leadDivisions;
        if(this.leadDivisions.length > 0){
          if(!this.selectedDivision){
            this.selectDivision(this.leadDivisions[0]);
          }else{
            this.selectDivision(this.selectedDivision)
          }
        }
        this.fetchLeadEngagements(this.currentLeadData.id);
      }
    }, error => {
      this.settingUpStartUpData = false;
      this.connectionError();
    })
  }

  selectDivision(leadDivision?: LeadDivision) {
    this.selectedUsers = [];
    this.selectedKeyPersons = [];
    this.selectedContacts = [];

    // this.leadDivisionService.setCurrentLeadDivision(leadDivision);

    this.selectedDivision = this.leadDivisions.find(x => x.id === leadDivision?.id);

    const keyPersons = this.selectedDivision?.leadDivisionKeyPeople;
    if(keyPersons){
      keyPersons.forEach(kp => {
        kp.fullName = `${kp.firstName} ${kp.lastName}`
      });
      this.leadDivisionKeyPersons = keyPersons;
    }

    const contacts = [];
    if(this.selectedDivision.primaryContact){
      contacts.push(this.selectedDivision.primaryContact)
    }
    if(this.selectedDivision.secondaryContact){
      contacts.push(this.selectedDivision.secondaryContact)
    }
    contacts.forEach(c => {
      if(c) c.fullName = `${c.firstName} ${c.lastName}`
    });

    this.leadDivisionContacts = contacts;
  }

  fetchLeadEngagements(leadId: number){
    this.loadingEngagements = true;
    this.leadEngagementService.getLeadEngagementsByLeadId(leadId).subscribe(async (res) => {
      this.leadEngagements = res.responseData ?? [] as LeadEngagement[];
      this.loadingEngagements = false;
    }, error => {
      this.loadingEngagements = false;
      this.connectionError();
    })
  }

  captureLeadEngagment(){

    if(this.selectedUsers.length < 1 && this.selectedContacts.length < 1 && this.selectedKeyPersons.length < 1){
        this.messageService.add({
          severity: 'error', summary: 'Validation Error',
          detail: 'Please select at least 1 individual.'
        });
        return;
    }

    if (!this.selectedFiles || this.selectedFiles.length === 0) {
      this.messageService.add({
        severity: 'error', summary: 'Validation Error',
        detail: 'Please select at least 1 file.'
      });
      return;
    }

    this.messageService.add({ severity: 'info', summary: 'Information', detail: 'Uploading documents.'});

    this.documentUrls = []

    const n = Date.now();
    const fileUploads = this.selectedFiles.map(file => {

      const filePath = `EngagementDocument/${n}_${file.name}`;
      const ref = this.angularFireStorage.ref(filePath);
      const task = this.angularFireStorage.upload(filePath, file);
      return {ref, snapshot: task.snapshotChanges()};
    });

    const refs = fileUploads.map(x => x.ref);
    const snapShots = fileUploads.map(x => x.snapshot);

    forkJoin(snapShots).subscribe(async (res) => {
      for (let i = 0; i < refs.length; i++) {
        const ref = refs[i];
        const downloadURL = await ref.getDownloadURL().toPromise();
        this.documentUrls.push(downloadURL);
      }
      this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Document uploaded successfully.'});
      this._captureLeadEngagement();
    }, error => {
      this.connectionError();
    })
  }

  _captureLeadEngagement(){

    this.messageService.add({ severity: 'info', summary: 'Information', detail: 'Creating Lead Engagement.'});

    const formData = this.leadEngagementForm.value

    formData.leadCaptureStage = this.leadCaptureStage;
    formData.leadId = this.currentLeadData.id;
    formData.engagementTypeId = this.selectedEngagementType.id;
    formData.engagementReasonId = this.selectedEngagementReason.id;
    formData.usersEngagedIds = this.selectedUsers.map(x => x.id);
    formData.contactsEngagedIds = this.selectedContacts.map(x => x.id);
    formData.keyPersonsEngagedIds = this.selectedKeyPersons.map(x => x.id);
    formData.documentsUrl = this.documentUrls.join(',');

    // console.log('The current form data => ', formData)

    this.leadEngagementService.postLeadEngagement(formData).subscribe(async (res: any) => {
      this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Lead Engagement Captured Successfully.'});
      this.leadEngagementForm.reset();
      this.selectedFiles = [];
      this.documentUrls = [];
      this.fetchLeadEngagements(this.currentLeadData.id);
    }, error => {
      this.connectionError();
    })
  }

  updateLeadEngagement(){
    this.messageService.add({ severity: 'info', summary: 'Information', detail: 'Update Lead Engagement not yet available.'});
  }

  editEngagement(leadEngagement?: LeadEngagement){
    this.editingLeadEngagement = true;
    this.leadEngagementForm.addControl('leadEngagementId',  new FormControl({value: leadEngagement.id, disabled: true}, Validators.required));
    this.edLeadEngagement = leadEngagement;
    this.leadEngagementForm.patchValue({
      leadEngagementId: leadEngagement.id,
      caption: leadEngagement.caption,
      engagementDiscussion: leadEngagement.engagementDiscussion,
      date: leadEngagement.date,
      engagementOutcome: leadEngagement.engagementOutcome
    });
    this.selectedEngagementType = this.engagementTypes.find(x => x.id === leadEngagement.engagementTypeId);
    this.selectedEngagementReason = this.engagementReasons.find(x => x.id === leadEngagement.engagementReasonId);
  }

  cancelEditEngagement(){
    this.editingLeadEngagement = false;
    this.leadEngagementForm.reset();
    this.edLeadEngagement = null;
  }

  chooseFile(files: FileList){
    this.selectedFiles = [];
    if(files.length === 0) return;

    for (let i = 0; i < files.length; i++) {
      this.selectedFiles.push(files.item(i));
    }
  }

  private connectionError() {
    this.messageService.add({
        severity: 'error', summary: 'Failed',
        detail: 'Connection Error, Please try again'
    });
  }

}