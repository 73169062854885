import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import {Observable} from 'rxjs';
import { CommonResponse } from '../interfaces/home';

@Injectable({
  providedIn: 'root'
})
export class SbuProportionService {

  constructor(private http: HttpClient) { }
  baseUrl = environment.baseUrl + 'SBUProportion/';
  allSBUProportion(): Observable<CommonResponse>{
    return this.http.get<CommonResponse>(this.baseUrl);
  }
  getSBUProportion(id: any):Observable<CommonResponse>{
    return this.http.get<CommonResponse>(this.baseUrl +id);
  }
  post(data: any){
    return this.http.post(this.baseUrl, data);
  }
  deleteSBUProportion(id: any){
    return this.http.delete(this.baseUrl +id);
  }
  updateSBUProportion(id: any, data: any){
    return this.http.put(this.baseUrl +id, data);
  }
}
