import { ConvergenceIncident } from "./../../../../interfaces/convergence";
import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from "@angular/core";
import { ConfirmationService, MessageService } from "primeng/api";
import { BreadcrumbService } from "src/app/breadcrumb.service";
import { Supplier } from "src/app/interfaces/armada";
import {
  EventSource,
  EventType,
  GetDashboardFilteredEventsVM,
} from "src/app/interfaces/convergence";
import { DeployedGuard } from "src/app/interfaces/employment";
import { CustomerDivision, Lga, State, User } from "src/app/interfaces/home";
import { ConvergenceService } from "src/app/services/convergence.service";
import { CustomerDivisionService } from "src/app/services/customer-division.service";
import { GmaEmploymentService } from "src/app/services/gma-employment.service";
import { SupplierService } from "src/app/services/supplier.service";
import { UserService } from "src/app/services/user.service";

@Component({
  selector: "app-incidence-convergence-dashboard",
  templateUrl: "./incidence-convergence-dashboard.component.html",
  styleUrls: ["./incidence-convergence-dashboard.component.scss"],
})
export class IncidenceConvergenceDashboardComponent
  implements OnInit, AfterViewInit
{
  @ViewChild("formWrapper") public formWrapper: ElementRef;
  allEventTypes: EventType[];
  theEventType: EventType;
  allEventSource: EventSource[];
  theEventSource: EventSource;
  allStates: State[];
  theState: State;
  allLgas: Lga[];
  theLga: Lga;
  allRiskLevels: { key: number; value: string }[] = [
    {
      key: 1,
      value: "Level 1",
    },
    {
      key: 2,
      value: "Level 2",
    },
    {
      key: 3,
      value: "Level 4",
    },
    {
      key: 4,
      value: "Level 4",
    },
    {
      key: 5,
      value: "Level 5",
    },
  ];
  theRiskLevel: { key: number; value: string };
  dateFrom: Date;
  dateTo: Date;
  allReportedType: any[] = [
    {
      id: 1,
      caption: "Supplier",
    },
    {
      id: 2,
      caption: "Client",
    },
    {
      id: 3,
      caption: "Staff",
    },
    {
      id: 4,
      caption: "Operatives",
    },
  ];
  theReportedType: {
    id: number;
    caption: string;
  };
  allReportedBy: {
    id: number;
    caption: string;
  }[];
  theReportedBy: {
    id: number;
    caption: string;
  };
  viewSwitch: boolean;
  allIncidents: ConvergenceIncident[];
  selectedIncidents: ConvergenceIncident[];
  incidentCols: any[];
  locations: {
    lat: number;
    lng: number;
  }[] = [];
  allSuppliers: Supplier[] = [];
  allClients: CustomerDivision[] = [];
  allUsers: User[] = [];
  allOperatives: DeployedGuard[] = [];
  openDocDialogue: boolean;
  docIncident: ConvergenceIncident;
  showComments: boolean;
  comments: {
    imageUrl: string;
    fullname: string;
    comment: string;
    dateAdded: Date;
  }[];

  constructor(
    public employmentService: GmaEmploymentService,
    public supplierService: SupplierService,
    public userService: UserService,
    public clientService: CustomerDivisionService,
    public convergenceService: ConvergenceService,
    private breadcrumbService: BreadcrumbService,
    public confirmationService: ConfirmationService,
    public messageService: MessageService
  ) {}

  ngOnInit(): void {
    this.breadcrumbService.setItems([
      {
        label: "Convergence",
      },
      {
        label: "Incident Management",
      },
      {
        label: "Dasboard",
        routerLink: ["/home/convergence/incident-management/dashboard"],
      },
    ]);

    this.incidentCols = [
      { field: "dateRequested", header: "Date Requested" },
      { field: "type", header: "Event Type" },
      { field: "source", header: "Event Source" },
      { field: "caption", header: "Event Caption" },
      { field: "description", header: "Event Description" },
      { field: "reportedByEntityName", header: "Reported By" },
      { field: "dateOccurred", header: "Event Date" },
    ];

    this.FetchAllSuppliers();
    this.FetchAllClients();
    this.FetchAllUsers();
    this.FetchAllOperatives();
    this.FetchAllEventSource();
    this.FetchAllEventType();
    this.FetchAllStates();
  }

  ngAfterViewInit(): void {
    // Load google maps script after view init
    const DSLScript = document.createElement("script");
    DSLScript.src =
      "https://maps.googleapis.com/maps/api/js?key=AIzaSyCQuetprs2UHb_zKSXznyumyfvw95ERCDY"; // replace by your API key
    DSLScript.type = "text/javascript";
    document.body.appendChild(DSLScript);
    document.body.removeChild(DSLScript);
  }

  FetchAllEventSource() {
    this.convergenceService.GetAllEventSources().subscribe(
      async (data) => {
        this.allEventSource = data;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all event sources at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  FetchAllEventType() {
    this.convergenceService.GetAllEventTypes().subscribe(
      async (data) => {
        this.allEventTypes = data;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all event types at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  FetchAllStates() {
    this.employmentService.GetAllStates().subscribe(
      async (data) => {
        this.allStates = data;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all states at the moment.. Reason: [" +
            error.error.message +
            "]",
        });
      }
    );
  }

  async FetchAllSuppliers() {
    this.supplierService.getAll().subscribe(
      async (data) => {
        this.allSuppliers = data.responseData;
      },
      (error) => {
        this.messageService.add({
          severity: "error",
          summary: "Failed",
          detail: error ?? "Some errors occurred",
        });
      }
    );
  }

  async FetchAllClients() {
    this.clientService.allCustomerDivision().subscribe(
      async (data) => {
        this.allClients = data.responseData;
      },
      (error) => {
        this.messageService.add({
          severity: "error",
          summary: "Failed",
          detail: error ?? "Some errors occurred",
        });
      }
    );
  }

  async FetchAllUsers() {
    this.userService.allUser().subscribe(
      async (res) => {
        var data = res.responseData;
        data.forEach((user) => {
          user.fullName = user.lastName + " " + user.firstName;
          this.allUsers.push(user);
        });
      },
      (err) => {
        console.log(err);
        this.messageService.add({
          severity: "error",
          summary: "Failed",
          detail: err ?? "Some errors occurred",
        });
      }
    );
  }

  async FetchAllOperatives() {
    this.employmentService.GetAllOperativesDataOnly().subscribe(
      async (data) => {
        this.allOperatives = data;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all operatives at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  async handleViewChange() {
    if (!this.viewSwitch) {
      await new Promise((resolve) => setTimeout(resolve, 5000)); // 5 secs
      this.initMap();
    }
  }

  OnStateChange() {
    this.theLga = null;
    this.allLgas = this.theState.lgas;
  }

  ViewUploads(item: ConvergenceIncident) {
    this.openDocDialogue = true;
    this.docIncident = item;
  }

  // Initialize and add the map
  initMap(): void {
    if (this.locations.length <= 0) return;

    // The map, centered at Loc
    const map = new google.maps.Map(
      document.getElementById("map") as HTMLElement,
      {
        zoom: 6,
        center: this.locations[0],
      }
    );

    this.locations.forEach((x) => {
      // The marker, positioned at Loc
      const marker = new google.maps.Marker({
        position: x,
        map: map,
      });
    });
  }

  OnReportedTypeChange() {
    this.allReportedBy = [];
    if (this.theReportedType.caption.toLowerCase() == "supplier") {
      this.allSuppliers.forEach((x) =>
        this.allReportedBy.push({
          id: x.id,
          caption: x.supplierName,
        })
      );
    } else if (this.theReportedType.caption.toLowerCase() == "client") {
      this.allClients.forEach((x) =>
        this.allReportedBy.push({
          id: x.id,
          caption: x.divisionName,
        })
      );
    } else if (this.theReportedType.caption.toLowerCase() == "staff") {
      this.allUsers.forEach((x) =>
        this.allReportedBy.push({
          id: x.id,
          caption: x.lastName + " " + x.firstName,
        })
      );
    } else if (this.theReportedType.caption.toLowerCase() == "operatives") {
      this.allOperatives.forEach((x) =>
        this.allReportedBy.push({
          id: x.id,
          caption:
            x.jobApplication.personalInformation.lastname +
            " " +
            x.jobApplication.personalInformation.firstname,
        })
      );
    }
  }

  ApplyFilters() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Applying Filters...",
    });

    const postData: GetDashboardFilteredEventsVM = {
      from: this.dateFrom,
      to: this.dateTo,
      typeId: this.theEventType ? this.theEventType.id : null,
      sourceId: this.theEventSource ? this.theEventSource.id : null,
      stateId: this.theState ? this.theState.id : null,
      lgaId: this.theLga ? this.theLga.id : null,
      riskLevel: this.theRiskLevel ? this.theRiskLevel.key : null,
      reportedByType: this.theReportedType ? this.theReportedType.id : null,
      reportedBy: this.theReportedBy ? this.theReportedBy.id : null,
    };

    this.convergenceService.GetDashboardFilteredEvents(postData).subscribe(
      async (data) => {
        this.allIncidents = data;
        this.locations = [];
        data.forEach((incident) => {
          this.locations.push({
            lat: incident.locationLatitude,
            lng: incident.locationLongitude,
          });
        });
        if (!this.viewSwitch) this.initMap();
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to get filtered data at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  ResetFilters() {
    this.theEventSource = null;
    this.theEventType = null;
    this.dateFrom = null;
    this.dateTo = null;
    this.theState = null;
    this.theLga = null;
    this.theRiskLevel = null;
    this.theReportedBy = null;
    this.theReportedType = null;
  }

  HideDocDialog() {
    this.openDocDialogue = false;
    this.docIncident = null;
  }

  ShowCommentsDialogue(item: ConvergenceIncident) {
    this.showComments = true;
    this.comments = [];
    item.comments.forEach((x) => {
      let commentatorProfile = this.allUsers.find((u) => u.id == x.createdById);
      this.comments.push({
        imageUrl: commentatorProfile ? commentatorProfile.imageUrl : "",
        fullname: commentatorProfile ? commentatorProfile.fullName : "",
        comment: x.comment,
        dateAdded: x.createdAt,
      });
    });
  }

  HideCommentsDialogue() {
    this.showComments = false;
    this.comments = [];
  }
}
