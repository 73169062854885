import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../environments/environment";
import { Observable } from "rxjs";
import {
  AllowedAlternateApproversVM,
  AlternateBulkApproveVM,
  ApprovingLevelOffice,
  ApprovingLevelOfficer,
  BulkApprovalActionVM,
  BulkApprovalExecutionVM,
  CommonResponse,
  GetAllowedAlternateApproversVM,
  GetApprovalSummaryInfoReceivingDTO,
  GetBulkApprovalDetailView,
  GetBulkApprovalViewModel,
  GetBulkApprovalViewVM,
  GetOutstandingApproveesInfoView,
  GetOutstandingApproveesInfoView_V2,
  GetPendingApprovalsBulkView,
  SendFinalApprovalVM,
} from "../interfaces/home";

@Injectable({
  providedIn: "root",
})
export class ApprovalService {
  constructor(private http: HttpClient) {}
  baseUrl = environment.baseUrl + "Approval/";
  // fetch all Approval in the database
  allApprovalData(): Observable<CommonResponse> {
    return this.http.get<CommonResponse>(this.baseUrl);
  }
  allPendingUserApprovals(): Observable<CommonResponse> {
    return this.http.get<CommonResponse>(
      this.baseUrl + "GetUserPendingApprovals"
    );
  }
  getPendingApprovalsByServiceId(id: any): Observable<CommonResponse> {
    return this.http.get<CommonResponse>(
      this.baseUrl + "GetPendingApprovalsByServiceId/" + id
    );
  }
  getApprovalsByServiceId(id: any): Observable<CommonResponse> {
    return this.http.get<CommonResponse>(
      this.baseUrl + "GetApprovalsByServiceId/" + id
    );
  }
  getPendingApprovalsByQuoteId(id: any): Observable<CommonResponse> {
    return this.http.get<CommonResponse>(
      this.baseUrl + "GetPendingApprovalsByQuoteId/" + id
    );
  }
  getApprovalsByQuoteId(id: any): Observable<CommonResponse> {
    return this.http.get<CommonResponse>(
      this.baseUrl + "GetApprovalsByQuoteId/" + id
    );
  }
  getPendingApprovalsByContractId(id: any): Observable<CommonResponse> {
    return this.http.get<CommonResponse>(
      this.baseUrl + "GetPendingApprovalsByContractId/" + id
    );
  }
  getApprovalsByContractId(id: any): Observable<CommonResponse> {
    return this.http.get<CommonResponse>(
      this.baseUrl + "GetApprovalsByContractId/" + id
    );
  }
  getApprovalsByEndorsementId(id: any): Observable<CommonResponse> {
    return this.http.get<CommonResponse>(
      this.baseUrl + "GetApprovalsByEndorsementId/" + id
    );
  }

  disapproveOrApproveContractService(isApproved, approvalId, contractId) {
    return this.http.put(
      this.baseUrl + "disapprove-or-approve-contractservice",
      { isApproved: isApproved, approvalId: approvalId, contractId: contractId }
    );
  }

  getApproval(id: any): Observable<CommonResponse> {
    return this.http.get<CommonResponse>(this.baseUrl + id);
  }
  getApprovalByName(name: any): Observable<CommonResponse> {
    return this.http.get<CommonResponse>(this.baseUrl + "name/" + name);
  }
  postApproval(data: any) {
    return this.http.post(this.baseUrl, data);
  }
  deleteApproval(id: any) {
    return this.http.delete(this.baseUrl + id);
  }
  updateApproval(id: any, data: any) {
    return this.http.put(this.baseUrl + id, data);
  }

  GetApprovingLevelOfficeData(): Observable<CommonResponse> {
    return this.http.get<CommonResponse>(
      this.baseUrl + "GetApprovingLevelOfficeData"
    );
  }

  SendFinalApprovalNotification(data: SendFinalApprovalVM) {
    return this.http.post(this.baseUrl + "SendFinalApprovalNotification", data);
  }

  GetApprovalSummaryInfo(
    data: GetApprovalSummaryInfoReceivingDTO
  ): Observable<CommonResponse> {
    return this.http.post<CommonResponse>(
      this.baseUrl + "GetApprovalSummaryInfo",
      data
    );
  }

  GetPendingApprovalsBulk(): Observable<
    CommonResponse<GetPendingApprovalsBulkView[]>
  > {
    return this.http.get<CommonResponse<GetPendingApprovalsBulkView[]>>(
      this.baseUrl + "GetPendingApprovalsBulk"
    );
  }

  GetBulkApprovalView(
    data: GetBulkApprovalViewVM
  ): Observable<CommonResponse<GetBulkApprovalViewModel[]>> {
    return this.http.post<CommonResponse<GetBulkApprovalViewModel[]>>(
      this.baseUrl + "GetBulkApprovalView",
      data
    );
  }

  GetBulkApprovalDetail(
    data: GetBulkApprovalViewVM
  ): Observable<CommonResponse<GetBulkApprovalDetailView>> {
    return this.http.post<CommonResponse<GetBulkApprovalDetailView>>(
      this.baseUrl + "GetBulkApprovalDetail",
      data
    );
  }

  BulkApprovalAction(
    data: BulkApprovalActionVM
  ): Observable<CommonResponse<boolean>> {
    return this.http.post<CommonResponse<boolean>>(
      this.baseUrl + "BulkApprovalAction",
      data
    );
  }

  BulkApprovalExecution(
    data: BulkApprovalExecutionVM
  ): Observable<CommonResponse<boolean>> {
    return this.http.post<CommonResponse<boolean>>(
      this.baseUrl + "BulkApprovalExecution",
      data
    );
  }

  GetAllowedAlternateApprovers(
    data: GetAllowedAlternateApproversVM
  ): Observable<CommonResponse<AllowedAlternateApproversVM[]>> {
    return this.http.post<CommonResponse<AllowedAlternateApproversVM[]>>(
      this.baseUrl + "GetAllowedAlternateApprovers",
      data
    );
  }

  AlternateBulkApprove(
    data: AlternateBulkApproveVM
  ): Observable<CommonResponse<boolean>> {
    return this.http.post<CommonResponse<boolean>>(
      this.baseUrl + "AlternateBulkApprove",
      data
    );
  }

  GetOutstandingApproveesInfo(): Observable<
    CommonResponse<GetOutstandingApproveesInfoView[]>
  > {
    return this.http.get<CommonResponse<GetOutstandingApproveesInfoView[]>>(
      this.baseUrl + "GetOutstandingApproveesInfo"
    );
  }

  GetOutstandingApprovalInfo(
    responsibleId: number
  ): Observable<CommonResponse<GetPendingApprovalsBulkView[]>> {
    return this.http.get<CommonResponse<GetPendingApprovalsBulkView[]>>(
      this.baseUrl + "GetOutstandingApprovalInfo/" + responsibleId
    );
  }

  GetOutstandingApproveesInfo_V2(): Observable<
    CommonResponse<GetOutstandingApproveesInfoView_V2[]>
  > {
    return this.http.get<CommonResponse<GetOutstandingApproveesInfoView_V2[]>>(
      this.baseUrl + "GetOutstandingApproveesInfo_V2"
    );
  }

  GetApproverAlternates(
    approverUserId: number
  ): Observable<CommonResponse<ApprovingLevelOfficer[]>> {
    return this.http.get<CommonResponse<ApprovingLevelOfficer[]>>(
      this.baseUrl + "GetApproverAlternates/" + approverUserId
    );
  }

  GetAllMyAlternates(): Observable<CommonResponse<ApprovingLevelOffice[]>> {
    return this.http.get<CommonResponse<ApprovingLevelOffice[]>>(
      this.baseUrl + "GetAllMyAlternates"
    );
  }

  OptOutOfApprovalInsights(): Observable<CommonResponse> {
    return this.http.get<CommonResponse>(
      this.baseUrl + "OptOutOfApprovalInsights"
    );
  }
}
