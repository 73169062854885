import { Component, ElementRef, Input, OnInit, ViewChild } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  ValidatorFn,
  Validators,
} from "@angular/forms";
import {
  CommonResponse,
  Contract,
  ContractService,
  Invoice,
  InvoiceMailDTO,
  Role,
  RoleClaim,
  ServiceCategory,
  ServiceRelationshipEnum,
  Services,
  User,
} from "../../../interfaces/home";
import { ConfirmationService, MessageService, TreeNode } from "primeng/api";
import { DialogService, DynamicDialogRef } from "primeng/dynamicdialog";
import { CustomerDivisionService } from "../../../services/customer-division.service";
import { BreadcrumbService } from "src/app/breadcrumb.service";
import { InvoiceService } from "src/app/services/invoice.sevice";
import { ContractServiceService } from "src/app/services/contract-service.service";
import { ViewInvoiceComponent } from "./view-invoice/view-invoice.component";
import { Table } from "primeng/table";

@Component({
  selector: "app-invoice-creation",
  templateUrl: "./invoice-creation.component.html",
  styleUrls: ["./invoice-creation.component.css"],
  providers: [MessageService, ConfirmationService, DialogService],
})
export class InvoiceCreationComponent implements OnInit {
  clientForm: FormGroup;
  customerDivisions: any;
  selectedCustomerDivision: any;
  viewedContractService: null;
  customerDivisionInformation: any;
  selectedContract: any;
  contracts: any[];
  primaryContactFullName: string;
  secondaryContactFullName: string;
  fetchingContractServices = false;
  contractServices: ContractService[];
  selectedContractService: ContractService;
  contractServiceCols: any;
  preformalInvoiceForm: FormGroup;
  // preformalGroupInvoiceForm: FormGroup;
  fetchingFinalInvoices = false;
  fetchingProformaInvoices = false;
  finalInvoices: any[];
  selectedFinalInvoice: any;
  finalInvoiceCols: any;
  preformalInvoices: Invoice[] = [];
  selectedPreformalInvoice: Invoice[] = [];
  preformalInvoiceCols: any;
  generatePreformalClicked = false;
  contractServiceClicked = false;
  isGroupInvoice = false;
  hiddenIsGroupedAdhoc = false;
  isAdHocContractService = false;
  billable: number;
  vat: number = 0;
  value: number;
  unInvoicedAmount: number;
  totalContractBillable: number;
  groupBillable: number;
  groupVat: number;
  groupValue: number;
  groupUnInvoicedAmount: number;
  groupTotalContractBillable: number;
  groupInvoiceContractServices: ContractService[];
  showMaximizableDialog: any;
  fetchingInvoiceDetails = false;
  invoiceDetails: InvoiceMailDTO = null;
  displayMaximizable = false;
  viewInvoiceRef: DynamicDialogRef;
  showCreateProformaForm: boolean = true;
  selectContractGroupSendDate: any = null;
  invalidInvoiceSendDate: boolean = false;
  contractIdTracker: number = 0;
  invoiceCreationSubmitted: boolean = false;
  submitted: boolean = false;
  minStartDate: Date;
  maxStartDate: Date;
  maxEndDate: Date;
  minEndDate: Date;
  @ViewChild(Table) proforma: Table;
  contractServiceUnderEndorsement: any;
  valueToCalculateVat: boolean;
  option: any = 1;
  totalBillable: number;
  unitPrice: any;
  qty: any;
  isDoneLoadingContractService: boolean;
  directExistsValidation: boolean;

  constructor(
    public formBuilder: FormBuilder,
    private customerDivisionService: CustomerDivisionService,
    public messageService: MessageService,
    public confirmationService: ConfirmationService,
    private breadcrumbService: BreadcrumbService,
    private invoiceService: InvoiceService,
    private contractServiceService: ContractServiceService,
    private dialogService: DialogService
  ) {}

  ngOnInit(): void {
    this.breadcrumbService.setItems([
      { label: "AdHoc-Invoice", routerLink: ["home/invoice-creation"] },
    ]);

    this.getCustomerDivisions();
    this.clientForm = this.formBuilder.group({
      customerId: [null, Validators.required],
    });

    this.preformalInvoiceForm = this.formBuilder.group({
      contractServiceUnitPrice: ["", [Validators.required]],
      contractServiceQuantity: ["", [Validators.required]],
      unitPrice: [""],
      quantity: [""],
      invoiceSendDate: ["", Validators.required],
      description: ["", Validators.required],
      startDate: ["", Validators.required],
      endDate: ["", Validators.required],
      billOption: ["", [Validators.required]],
      billable: ["", Validators.required],
    });
  }

  getCustomerDivisions() {
    this.customerDivisionService.allCustomerDivision().subscribe(
      async (res: CommonResponse) => {
        this.customerDivisions = res.responseData;
      },
      (error) => {
        this.messageService.add({
          severity: "error",
          summary: "Failed",
          detail: "Connection error, Try again later",
        });
      }
    );
  }

  searchContractServicesByClient() {
    this.customerDivisionInformation = null;
    this.selectedContract = null;
    this.contracts = null;
    this.viewedContractService = null;
    this.contractServiceClicked = false;
    this.generatePreformalClicked = false;
    this.getCustomerDivisionById(this.selectedCustomerDivision.id);
  }

  removeInvoice(id: number) {
    //then remove
    this.confirmationService.confirm({
      message: "Are you sure you want to remove this proforma invoice?",
      header: "Confirmation",
      icon: "pi pi-exclamation-triangle",
      accept: () => {
        this._removeInvoice(id);
      },
      reject: (type) => {
        this.messageService.add({
          severity: "error",
          summary: "Rejected",
          detail: "Declined removing invoice",
        });
      },
    });
  }

  private _removeInvoice(invoiceId: number) {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Removing proforma invoice",
    });

    this.invoiceService.removeProformaInvoice(invoiceId, null).subscribe(
      async (res: CommonResponse) => {
        if (res.responseCode == "00") {
          this.preformalInvoices = this.preformalInvoices.filter(
            (x) => x.id != invoiceId
          );
          this.messageService.add({
            severity: "success",
            summary: "Notice",
            detail: "Proforma invoice removed successfully",
          });
        }
      },
      (error) => {
        this.messageService.add({
          severity: "error",
          summary: "Failed",
          detail: error.error.message,
        });
      }
    );
  }

  getCustomerDivisionById(customerDivisionId: any) {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Retrieving Client Information",
    });

    this.fetchingContractServices = true;
    this.customerDivisionService
      .getCustomerDivision(customerDivisionId)
      .subscribe(
        async (r: CommonResponse) => {
          await this.messageService.add({
            severity: "success",
            summary: "Success",
            detail: "Retrieved Client Information Successfully",
          });

          this.customerDivisionInformation = r.responseData;
          const primaryContact =
            this.customerDivisionInformation.primaryContact;
          const secondaryContact =
            this.customerDivisionInformation.secondaryContact;

          if (primaryContact) {
            this.primaryContactFullName = `${primaryContact.firstName} ${primaryContact.lastName}`;
          } else {
            this.primaryContactFullName = "Not Available.";
          }

          if (secondaryContact) {
            this.secondaryContactFullName = `${secondaryContact.firstName} ${secondaryContact.lastName}`;
          } else {
            this.secondaryContactFullName = "Not Available.";
          }

          this.contracts = r.responseData.contracts;
          this.fetchingContractServices = false;
        },
        (error) => {
          this.connectionError();
          this.fetchingContractServices = false;
        }
      );
  }

  selectBillableType() {
    this.option = this.preformalInvoiceForm.get("billOption").value;
    //console.log(this.option);
    if (this.option == 2) {
      this.valueToCalculateVat = true;
      this.preformalInvoiceForm.controls["unitPrice"].setValidators([
        Validators.required,
        Validators.min(1),
      ]);
      this.preformalInvoiceForm.controls["quantity"].setValidators([
        Validators.required,
        Validators.min(1),
      ]);
    } else if (this.option == 1) {
      this.valueToCalculateVat = false;
      this.preformalInvoiceForm.get("unitPrice").setValidators(null);
      this.preformalInvoiceForm.get("quantity").setValidators(null);
    }
  }

  billableValuePlusVAT() {
    this.unitPrice = this.preformalInvoiceForm.get("unitPrice").value;
    this.qty = this.preformalInvoiceForm.get("quantity").value;
    var total = this.unitPrice * this.qty;
    var vat = 0;
    if (this.selectedContractService?.service?.isVatable)
      vat = Number.parseFloat((total * (7.5 / 100)).toFixed(2));
    this.billable = Number.parseFloat((total + vat).toFixed(2));
    this.preformalInvoiceForm.patchValue({
      billable: this.billable,
    });
    this.vat = vat; //Number.parseFloat((this.billable * (7.5 / 107.5)).toFixed(2));
    this.value = Number.parseFloat(total.toFixed(2));
    console.log(this.billable);
    //validatebillableInput();
  }

  refreshContractService() {
    var contractserviceId = this.selectedContractService?.id;
    this.contractServiceService
      .getContractServiceById(contractserviceId)
      .subscribe(
        async (r: CommonResponse) => {
          if (r.responseCode == "00") {
            this.selectedContractService = r.responseData;
            this.minStartDate = new Date(
              this.selectedContractService.contractStartDate
            );
            this.minEndDate = new Date(
              this.selectedContractService.contractEndDate
            );
            //find the index of this contract service in the array
            var index = this.contractServices.findIndex(
              (x) => x.id == contractserviceId
            );
            this.contractServices[index] = r.responseData;
            this.fetchInvoicesByContractServiceId(
              this.selectedContractService?.id
            );
          }
        },
        (error) => {
          this.connectionError();
          this.fetchingContractServices = false;
        }
      );
  }

  selectContract(contract?: Contract) {
    this.selectedContract = contract;
    this.contractServices = contract.contractServices;
    if (contract.groupInvoiceNumber) {
      this.refreshInvoiceSendDateForContract();
    }
  }

  viewContractServiceDetails(contractService: ContractService) {
    this.isDoneLoadingContractService = false;
    this.preformalInvoices = [];
    this.showCreateProformaForm = false;
    this.isAdHocContractService =
      contractService.invoicingInterval === 6 ? true : false;

    this.isGroupInvoice = false;
    if (this.isAdHocContractService) {
      this.hiddenIsGroupedAdhoc = this.selectedContract.groupInvoiceNumber
        ? true
        : false;
    } else {
      this.hiddenIsGroupedAdhoc = false;
    }

    this.fetchInvoicesByContractServiceId(contractService.id);
    this.contractServiceClicked = true;
    this.selectedContractService = contractService;
    this.minStartDate = new Date(
      this.selectedContractService.contractStartDate
    );
    this.maxStartDate = new Date(this.selectedContractService.contractEndDate);
    this.minEndDate = new Date(this.selectedContractService.contractStartDate);
    this.maxEndDate = new Date(this.selectedContractService.contractEndDate);

    this.refreshProformaTable();
  }

  private refreshProformaTable() {
    if (this.proforma) this.proforma.reset();
  }

  private refreshInvoiceSendDateForContract() {
    if (!this.hiddenIsGroupedAdhoc) {
      return;
    }

    //fetch if the selected contract changes
    if (this.contractIdTracker != this.selectedContract?.id) {
      this.contractIdTracker = this.selectedContract?.id;
      this._changeInvoiceSendDateForGroup();
    } else if (!this.selectContractGroupSendDate) {
      //else do not select except no date was previously set
      this._changeInvoiceSendDateForGroup();
    }
  }

  private _changeInvoiceSendDateForGroup() {
    var contractId = this.contractIdTracker;
    this.invoiceService
      .getGroupInvoiceSendDateByContractId(contractId)
      .subscribe(
        async (res) => {
          if (res.responseCode == "00") {
            var invoice = res.responseData as Invoice;
            this.selectContractGroupSendDate = invoice.dateToBeSent;
          }
        },
        (error: any) => {
          this.messageService.add({
            severity: "error",
            summary: "Failed",
            detail:
              "Fetching group send date failed. Reason: " + error.error.message,
          });
        }
      );
  }

  private fetchInvoicesByContractServiceId(contractServiceId: number): void {
    this.fetchingFinalInvoices = true;

    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Fetching Invoices",
    });

    this.invoiceService
      .getProformaInvoicesByContractDivisionId(contractServiceId)
      .subscribe(
        async (res) => {
          this.preformalInvoices = res.responseData ?? [];
          this.messageService.add({
            severity: "success",
            summary: "Successful",
            detail: "Successfully Fetched Proforma Invoices",
          });
          this.fetchingProformaInvoices = false;
          this.isDoneLoadingContractService = true;
        },
        (error: any) => {
          this.fetchingProformaInvoices = false;
          this.isDoneLoadingContractService = true;

          this.messageService.add({
            severity: "error",
            summary: "Failed",
            detail: error.error.message,
          });
        }
      );

    this.invoiceService
      .getInvoicesByContractDivisionId(contractServiceId)
      .subscribe(
        async (res) => {
          this.finalInvoices = res.responseData ?? [];
          this.messageService.add({
            severity: "success",
            summary: "Successful",
            detail: "Successfully Fetched Invoices",
          });
          this.fetchingFinalInvoices = false;
        },
        (error) => {
          this.fetchingFinalInvoices = false;

          this.messageService.add({
            severity: "error",
            summary: "Failed",
            detail: error.error.message,
          });
        }
      );
  }

  private connectionError() {
    this.messageService.add({
      severity: "error",
      summary: "Failed",
      detail: "Connection Error, Please try again",
    });
  }

  createPreformalInvoice() {
    this.submitted = true;
    //verifyDate();

    if (this.hiddenIsGroupedAdhoc && this.invalidInvoiceSendDate) {
      return;
    }

    if (this.option == 2 && this.qty > this.selectedContractService.quantity) {
      return;
    }

    if (this.option == 1) {
      this.qty = 1;
      this.unitPrice = this.billable;
    }

    const invoice = {
      contractServiceUnitPrice: this.selectedContractService.unitPrice,
      contractServiceQuantity: this.selectedContractService.quantity,
      discount: this.selectedContractService.discount,
      value: this.billable,
      vat: this.vat,
      billableAmount: this.selectedContractService.billableAmount,
      dateToBeSent: this.preformalInvoiceForm.get("invoiceSendDate").value,
      description: this.preformalInvoiceForm.get("description").value,
      startDate: this.preformalInvoiceForm.get("startDate").value,
      endDate: this.preformalInvoiceForm.get("endDate").value,
      customerDivisionId: this.selectedCustomerDivision.id,
      contractServiceId: this.selectedContractService.id,
      unitPrice: this.unitPrice,
      quantity: this.qty,
    };

    this.postAdHocInvoice(invoice);
  }

  onSendDateChange() {
    if (!this.hiddenIsGroupedAdhoc) {
      return;
    }
    var invoiceSendDate =
      this.preformalInvoiceForm.get("invoiceSendDate").value;
    if (this.selectContractGroupSendDate) {
      //check that this date aligns with the selected send date
      var a = new Date(this.selectContractGroupSendDate);
      var b = new Date(invoiceSendDate);
      if (
        a.getFullYear() == b.getFullYear() &&
        a.getMonth() == b.getMonth() &&
        a.getDate() == b.getDate()
      ) {
        this.invalidInvoiceSendDate = false;
      } else {
        this.invalidInvoiceSendDate = true;
      }
    }
  }

  verifyDate() {}

  postAdHocInvoice(invoice) {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Creating Preformal Invoice",
    });

    this.invoiceService.postAdHocInvoice(invoice).subscribe(
      async (r: CommonResponse) => {
        if (r.responseCode == "00") {
          var res = r.responseData;
          this.preformalInvoices.push(res);
          this.selectedContractService.adHocInvoicedAmount += res.value;
          this.generatePreformalClicked = false;
          this.billable = 0;
          this.vat = 0;
          this.preformalInvoiceForm.reset();

          this.messageService.add({
            severity: "success",
            summary: "Notice",
            detail: "Preformal Invoice Created",
          });
          this.submitted = false;

          this.refreshInvoiceSendDateForContract();
        } else {
          this.messageService.add({
            severity: "error",
            summary: "error",
            detail: r.responseMsg,
          });
        }
      },
      (error) => {
        this.messageService.add({
          severity: "error",
          summary: "Failed to Create Invoice",
          detail: error.error.message,
        });
      }
    );
  }

  confirm(preformalInvoiceId: number) {
    this.confirmationService.confirm({
      message: "Are you sure you want to Convert this Invoice ?",
      header: "Confirmation",
      icon: "pi pi-exclamation-triangle",
      accept: () => {
        this.convertPreformalInvoice(preformalInvoiceId);
      },
      reject: (type) => {
        this.messageService.add({
          severity: "error",
          summary: "Rejected",
          detail: "Declined converting Invoice",
        });
      },
    });
  }

  confirmGroupConvertion() {
    let capturedIds: number[] = [];
    let dontProceed: boolean;
    this.selectedPreformalInvoice.forEach((proformaInv) => {
      if (capturedIds.find((x) => x == proformaInv.id) == null) {
        capturedIds.push(proformaInv.id);
        let contractServ = this.contractServices.find(
          (x) => x.id == proformaInv.contractServiceId
        );
        if (!contractServ) {
          this.messageService.add({
            severity: "error",
            summary:
              "Error Profoma Inv " +
              proformaInv.description +
              " has invalid data.",
          });
          dontProceed = true;
          return;
        }

        if (contractServ.adminDirectTie != "") {
          if (
            contractServ.service.serviceRelationshipEnum !=
            ServiceRelationshipEnum.Standalone
          ) {
            let correspondingTie = this.contractServices.find(
              (x) =>
                x.adminDirectTie == contractServ.adminDirectTie &&
                x.id != contractServ.id
            );
            if (!correspondingTie) {
              this.messageService.add({
                severity: "error",
                summary:
                  "Error Profoma Inv - Can't find corresponding tie (contract service) " +
                  proformaInv.description,
              });
              dontProceed = true;
              return;
            }
            let correspondingProfomaInv = this.selectedPreformalInvoice.find(
              (x) =>
                x.contractServiceId == correspondingTie.id &&
                capturedIds.find((y) => y == x.id) == null
            );
            if (correspondingProfomaInv) {
              capturedIds.push(correspondingProfomaInv.id);
            } else {
              this.messageService.add({
                severity: "error",
                summary:
                  "Error Profoma Inv - Can't find corresponding tie " +
                  proformaInv.description,
              });
              dontProceed = true;
              return;
            }
          }
        }
      }
    });

    if (dontProceed) return;

    this.confirmationService.confirm({
      message:
        "Are you sure you want to convert the selected invoices in a group?",
      header: "Confirmation",
      icon: "pi pi-exclamation-triangle",
      accept: () => {
        this.convertPreformalInvoiceInGroup();
      },
      reject: (type) => {
        this.messageService.add({
          severity: "error",
          summary: "Rejected",
          detail: "Declined converting Invoice",
        });
      },
    });
  }

  private convertPreformalInvoiceInGroup() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Converting to final Invoice",
    });
    var invoiceIds = [];
    for (let index = 0; index < this.selectedPreformalInvoice.length; index++) {
      invoiceIds.push(this.selectedPreformalInvoice[index]?.id);
    }
    this.invoiceService.converToFinalInvoice(invoiceIds).subscribe(
      async (r: CommonResponse) => {
        if (r.responseCode == "00") {
          this.selectedPreformalInvoice = [];
          this.preformalInvoices = this.preformalInvoices.filter(
            (x) => !invoiceIds.includes(x.id)
          );
          this.messageService.add({
            severity: "success",
            summary: "Notice",
            detail: "Converted to Final Invoice(s)",
          });
          this.refreshContractService();
        } else {
          this.messageService.add({
            severity: "error",
            summary: "Failure",
            detail: r.responseMsg,
          });
        }
      },
      (error) => {
        this.messageService.add({
          severity: "error",
          summary: "Failed",
          detail: error.error.message,
        });
      }
    );
  }

  convertPreformalInvoice(preformalInvoiceId: number) {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Converting to final Invoice",
    });
    var invoiceIds = [preformalInvoiceId];
    this.invoiceService.converToFinalInvoice(invoiceIds).subscribe(
      async (r: CommonResponse) => {
        if (r.responseCode == "00") {
          this.preformalInvoices = this.preformalInvoices.filter(
            (x) => x.id !== preformalInvoiceId
          );
          this.messageService.add({
            severity: "success",
            summary: "Notice",
            detail: "Converted to Final Invoice",
          });
          this.refreshContractService();
        } else {
          this.messageService.add({
            severity: "error",
            summary: "Failed",
            detail: r.responseMsg,
          });
        }
      },
      (error) => {
        this.messageService.add({
          severity: "error",
          summary: "Failed",
          detail: error.error.message,
        });
      }
    );
  }

  generatePreformal() {
    let directInv: Invoice = null;
    this.directExistsValidation = false;
    this.preformalInvoiceForm.reset();

    if (this.selectedContractService.adminDirectTie != "") {
      if (
        this.selectedContractService.service.serviceRelationshipEnum ==
        ServiceRelationshipEnum.Admin
      ) {
        let directContractService = this.contractServices.find(
          (x) =>
            x.adminDirectTie == this.selectedContractService.adminDirectTie &&
            x.id != this.selectedContractService.id
        );
        if (!directContractService) {
          this.messageService.add({
            severity: "error",
            summary: "Can't Find Direct Service Among Contract Service List.",
          });
          return;
        }

        this.preformalInvoices
          .filter(
            (x) =>
              !x.isMatched && x.contractServiceId == directContractService.id
          )
          .forEach((prefInv) => {
            let adminInv = this.preformalInvoices.find(
              (x) =>
                x.contractServiceId == this.selectedContractService.id &&
                !x.isMatched
            );
            if (adminInv) {
              adminInv.isMatched = true;
              prefInv.isMatched = true;
            }
          });

        let directExists = this.preformalInvoices.find(
          (x) => x.contractServiceId == directContractService.id && !x.isMatched
        );
        if (!directExists) {
          this.messageService.add({
            severity: "error",
            summary: "Please start with Direct Service First.",
          });
          return;
        }
        directInv = directExists;
        this.directExistsValidation = true;
      } else if (
        this.selectedContractService.service.serviceRelationshipEnum ==
        ServiceRelationshipEnum.Direct
      ) {
        let adminContractService = this.contractServices.find(
          (x) =>
            x.adminDirectTie == this.selectedContractService.adminDirectTie &&
            x.id != this.selectedContractService.id
        );
        if (!adminContractService) {
          this.messageService.add({
            severity: "error",
            summary: "Can't Find Admin Service Among Contract Service List.",
          });
          return;
        }

        let adminList = this.preformalInvoices.filter(
          (x) => x.contractServiceId == adminContractService.id
        );
        let directList = this.preformalInvoices.filter(
          (x) => x.contractServiceId == this.selectedContractService.id
        );
        if (adminList.length != directList.length) {
          this.messageService.add({
            severity: "error",
            summary:
              "More than one orphaned Direct Service at a time is not allowed.",
          });
          return;
        }
      }
    }

    this.generatePreformalClicked = true;
    this.populateNonGroupAdHocForm(directInv);
  }

  private populateNonGroupAdHocForm(directInvoice: Invoice) {
    this.showCreateProformaForm = true;
    this.preformalInvoiceForm.patchValue({
      contractServiceUnitPrice: this.selectedContractService.unitPrice,
      contractServiceQuantity: this.selectedContractService.quantity,
    });

    if (directInvoice) {
      this.preformalInvoiceForm.patchValue({
        quantity: directInvoice.quantity,
        startDate: this.FormatDateToCalendarString(directInvoice.startDate),
        endDate: this.FormatDateToCalendarString(directInvoice.endDate),
      });
    }

    this.totalContractBillable = this.selectedContractService.billableAmount;
    this.unInvoicedAmount =
      this.totalContractBillable -
      this.selectedContractService.adHocInvoicedAmount;
  }

  private FormatDateToCalendarString(date: Date): string {
    let dateString = date.toString();

    try {
      // DateTimeFormatOptions
      const date: Date = new Date(dateString);
      const options: Intl.DateTimeFormatOptions = {
        day: "numeric",
        month: "short",
        year: "numeric",
      };
      const formattedDate: string = new Intl.DateTimeFormat(
        "en-US",
        options
      ).format(date);
      dateString = formattedDate;
    } catch (error) {
      console.log(error);
      console.log(date);
      console.log(
        "Error while converting date string " + date + " exception " + error
      );
    }
    console.log(dateString);
    let day = dateString.substring(4, 6);
    let month = dateString.substring(0, 3);
    let year = dateString.substring(8, 13);
    console.log(day);
    console.log(month);
    console.log(year);
    let result = day + "-" + month + "-" + year;
    console.log(result);
    return result;
  }

  populateGroupAdHocForm(groupInvoiceNumber: string) {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Fetching Group Contract Services",
    });
    this.groupInvoiceContractServices = this.contractServices.filter(
      (x) => x.groupInvoiceNumber == groupInvoiceNumber
    );

    this.groupTotalContractBillable = this.groupInvoiceContractServices.reduce(
      (sum, contractService) => (sum += contractService.billableAmount),
      0
    );

    let groupadHocInvoicedAmount = this.groupInvoiceContractServices.reduce(
      (sum, contractService) => (sum += contractService.adHocInvoicedAmount),
      0
    );

    this.groupUnInvoicedAmount =
      this.groupTotalContractBillable - groupadHocInvoicedAmount;
  }

  validatebillableInput() {
    if (this.option == 2) {
      return;
    }
    this.billable = this.preformalInvoiceForm.value.billable;
    if (this.billable && this.billable <= this.unInvoicedAmount) {
      //billable is Amount + VAt
      if (this.selectedContractService?.service?.isVatable)
        this.vat = Number.parseFloat(
          (this.billable * (7.5 / 107.5)).toFixed(2)
        );

      this.value = Number.parseFloat((this.billable - this.vat).toFixed(2));
    } else if (this.billable > this.unInvoicedAmount) {
      this.vat = 0;
      this.value = 0;
    }
  }

  validateGroupBillableInput() {
    if (
      this.groupBillable &&
      this.groupBillable <= this.groupUnInvoicedAmount
    ) {
      this.groupVat = Number.parseFloat(
        (this.groupBillable * (7.5 / 100.0)).toFixed(2)
      );
      this.groupValue = parseFloat(
        (this.groupBillable - this.groupVat).toFixed(2)
      );
    } else if (this.groupBillable > this.groupUnInvoicedAmount) {
      this.groupVat = 0;
      this.groupValue = 0;
    }
  }

  displayInvoice(invoiceId: number) {
    this.viewInvoiceRef = this.dialogService.open(ViewInvoiceComponent, {
      width: "950px",
      contentStyle: { "max-width": "100vw", height: "90vh", overflow: "auto" },
      baseZIndex: 100,
      data: { invoiceId, isGroupedAdhoc: this.hiddenIsGroupedAdhoc },
    });

    this.viewInvoiceRef.onClose.subscribe(() => {});
  }

  FormatDateString(date: Date): string {
    let dateString = date.toString();

    try {
      // DateTimeFormatOptions
      const date: Date = new Date(dateString);
      const options: Intl.DateTimeFormatOptions = {
        year: "numeric",
        month: "long",
        day: "numeric",
      };
      const formattedDate: string = new Intl.DateTimeFormat(
        "en-US",
        options
      ).format(date);
      dateString = formattedDate;
    } catch (error) {
      console.log(error);
      console.log(date);
      console.log(
        "Error while converting date string " + date + " exception " + error
      );
    }

    return dateString;
  }
}
