import { Component, OnInit } from '@angular/core';
import {ConfirmationService, MessageService} from 'primeng/api';
import { BreadcrumbService } from 'src/app/breadcrumb.service';

@Component({
  selector: 'app-division-op-setup',
  templateUrl: './division-op-setup.component.html',
  styleUrls: ['./division-op-setup.component.scss'],
  providers: [ConfirmationService, MessageService]
})
export class DivisionOpSetupComponent implements OnInit {

  constructor(
      public confirmationService: ConfirmationService,
      public messageService: MessageService,
      private breadcrumbService: BreadcrumbService
  ) {
    this.breadcrumbService.setItems([
      { label: 'Setup', routerLink: ['/home/division-op-setup'] },
      { label: 'Division Op Setup', routerLink: ['/home/division-op-setup'] }
    ]);
   }

  ngOnInit(): void {
  }

}
