import {
  AddAdditionalInfo,
  AddBackgroundInfo,
  AddEducationalBackgrounds,
  AddMedicalAccountInfo,
  AddNextOfKins,
  AddPrevEmployers,
  CreateJobPost,
  JobApplication,
  JobPost,
  OperativeItem,
  RemoveOperativeItems,
  StartJobApplication,
} from "./../interfaces/premployment";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { Observable } from "rxjs";
import {
  CreateOperativeItem,
  CreateOperativeType,
  OperativeType,
} from "../interfaces/premployment";

@Injectable({
  providedIn: "root",
})
export class GmaPremploymentService {
  baseUrl = environment.gmaBaseUrl + "Premployment/";

  constructor(private http: HttpClient) {}

  CreateOperativeType(data: CreateOperativeType) {
    return this.http.post(this.baseUrl + "CreateOperativeType", data);
  }

  GetAllOperativeType(): Observable<OperativeType[]> {
    return this.http.get<OperativeType[]>(this.baseUrl + "GetAllOperativeType");
  }

  AddItemToOperativeType(data: CreateOperativeItem) {
    return this.http.post(this.baseUrl + "AddItemToOperativeType", data);
  }

  RemoveItemFromOperativeType(operativeItemId: number) {
    return this.http.delete(
      this.baseUrl + "RemoveItemFromOperativeType/" + operativeItemId
    );
  }

  GetAllItemsForOperativeType(
    operativeTypeId: number
  ): Observable<OperativeItem[]> {
    return this.http.get<OperativeItem[]>(
      this.baseUrl + "GetAllItemsForOperativeType/" + operativeTypeId
    );
  }

  RemoveItemsFromOperativeType(data: RemoveOperativeItems) {
    return this.http.post(this.baseUrl + "RemoveItemsFromOperativeType", data);
  }

  CreateJobPost(data: CreateJobPost) {
    return this.http.post(this.baseUrl + "CreateJobPost", data);
  }

  GetAllJobPosts(): Observable<JobPost[]> {
    return this.http.get<JobPost[]>(this.baseUrl + "GetAllJobPosts");
  }

  GetAllAvailableJobPost(): Observable<JobPost[]> {
    return this.http.get<JobPost[]>(this.baseUrl + "GetAllAvailableJobPost");
  }

  StartJobApplication(data: StartJobApplication): Observable<string> {
    return this.http.post<string>(this.baseUrl + "StartJobApplication", data);
  }

  TrackJobApplication(trackingNo: string): Observable<JobApplication> {
    return this.http.post<JobApplication>(
      this.baseUrl + "TrackJobApplication",
      { trackingNo: trackingNo }
    );
  }

  AddBackgroundInfoToJobApplication(data: AddBackgroundInfo) {
    return this.http.post(
      this.baseUrl + "AddBackgroundInfoToJobApplication",
      data
    );
  }

  AddNextOfKinInfoToJobApplication(data: AddNextOfKins) {
    return this.http.post(
      this.baseUrl + "AddNextOfKinInfoToJobApplication",
      data
    );
  }

  AddEducationalInfoToJobApplication(data: AddEducationalBackgrounds) {
    return this.http.post(
      this.baseUrl + "AddEducationalInfoToJobApplication",
      data
    );
  }

  AddPrevEmployerInfoToJobApplication(data: AddPrevEmployers) {
    return this.http.post(
      this.baseUrl + "AddPrevEmployerInfoToJobApplication",
      data
    );
  }

  AddMedicalAccountInfoToJobApplication(data: AddMedicalAccountInfo) {
    return this.http.post(
      this.baseUrl + "AddMedicalAccountInfoToJobApplication",
      data
    );
  }

  AddAdditionalInfoToJobApplication(data: AddAdditionalInfo) {
    return this.http.post(
      this.baseUrl + "AddAdditionalInfoToJobApplication",
      data
    );
  }
}
