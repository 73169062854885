import { ViewportScroller } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ConfirmationService, MessageService } from 'primeng/api';
import { CommonResponse } from 'src/app/interfaces/home';
import { childrenInstance } from '../supplier-approval-request/childrenInstance';
import { SupplierApprovalRequestService } from '../supplier-approval-request/supplier-approval-request.service';

@Component({
  selector: 'app-supplier-approval-listing',
  templateUrl: './supplier-approval-listing.component.html',
  styleUrls: ['./supplier-approval-listing.component.scss']
})
export class SupplierApprovalListingComponent implements OnInit {
  supplierRequest: any[]=[];
  approvalList: any[]=[];
  users: any[]=[];
  userList:any[]=[];
  supplierSelection:any;
  canApprove: boolean;
  selectedRequest: any[]=[];
  showInfo: boolean;
  supplierCols :any[];
  requestDetails: any;
  convertedArray:any[]=[];
  children:childrenInstance;
  lat: number;
  currentChildrenArray:any[]=[];
  lng: number;
  zoom: number;
  options: any;
  visibleSidebar2;
  childrenUpdate:any[]=[];
  childrenArray:any[]=[];
  documents: any[]=[];
  documentCategoryLength: any;
  services: any[]=[];
  constructor(
    private supplierApprovalService : SupplierApprovalRequestService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private sanitizer: DomSanitizer,
    private vps: ViewportScroller
  ) { }

  ngOnInit(): void {
    this.getAllSupplierRequest();
    this.getAllUsers();
  }

  getAllUsers(){
    this.supplierApprovalService.allUser().subscribe(async(res: CommonResponse) => {
      if(res.responseCode=="00"){
        this.users = res.responseData;
        this.users.forEach(user => {
         user.fullName = user.firstName + " " + user.lastName;
        });
        console.log("List of users",this.users);
      }
    })
  }

  getAllSupplierRequest(){
    this.supplierApprovalService.allSupplierRequest().subscribe(async(res: CommonResponse) => {
      if(res.responseCode=="00"){
        this.supplierRequest = res.responseData;
        console.log("List of supplierRequest",this.supplierRequest);
      }
    })
  }


  transform(image) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(image);
  }

  processRequest(supplier,el: HTMLElement){
    this.messageService.add({severity:'info', summary:"retrieving processed supplier request......"})
    this.supplierApprovalService.processRequest(supplier.id).subscribe(async(res:CommonResponse)=>{
      if(res.responseCode == "00"){
        this.userList = [];
        this.approvalList = [];
        console.log("Res:::::::: ",res.responseData);
        this.selectedRequest = res.responseData;
        //el.scrollIntoView({behavior: 'smooth'}); 
        this.visibleSidebar2 = true;
      }
    })
   
  }

  proceedToRequest(supplier){
    this.showInfo = true;
    this.messageService.add({severity:'info', summary:"retrieving processes........."})
    this.supplierApprovalService.proceedRequest(supplier.id).subscribe(async(result:CommonResponse)=>{
      if(result.responseCode == "00"){
        this.requestDetails = result.responseData
        console.log("This is the response from serve ",this.requestDetails);
        this.documents = this.requestDetails.documents;
        this.services = [...new Map(this.requestDetails.supplierServices.map(item =>[item.make, item])).values()];
        console.log("This are the services ", this.services);
        this.documentCategoryLength = this.documents.length
        this.vps.scrollToPosition([0, 300]);
      }
    })
  }

  getProducts(make){
     var currentProduct = this.requestDetails.supplierServices.filter(x=>{
      return x.make == make
     })
     console.log("This are the products ", currentProduct);
     var filteredProduct =  [...new Set(currentProduct.map(item => item.model))];
     console.log("This are the filteredproducts ", filteredProduct);
     return filteredProduct.join(',')
  }

  viewFile(value){
    window.open(value.documentUrl, '_blank');
  }


  exportAll(){

    var dateTime = new Date();
    var deliverableArray = [];
    var supplierArray = [];
    supplierArray = this.supplierRequest;
    supplierArray.forEach(x=>{
      deliverableArray.push({
        address : x.address,
        createdAt : x.createdAt,
        description : x.description,
        locationLatutude : x.locationLatutude,
        locationLongitude :x.locationLongitude,
        mobileNumber : x.mobileNumber,
        supplierEmail : x.primaryContactEmail,
        supplierName : x.supplierName,
        supplierCategory : x.supplierCategory.categoryName,
        vendorType : x.vendorType,
        status : x.status,
        requestPlatform : x.requestPlatform,
        requestSubmissionDate : x.requestSubmissionDate
      })

    })
    console.warn("this is the array to be printed ",deliverableArray);
    const replacer = (key, value) => (value === null ? '' : value); // specify how you want to handle null values here
    const header = Object.keys(deliverableArray[0]);
    const csv = deliverableArray.map((row) =>
      header
        .map((fieldName) => JSON.stringify(row[fieldName], replacer))
        .join(',')
    );
    csv.unshift(header.join(','));
    const csvArray = csv.join('\r\n');
  
    const a = document.createElement('a');
    const blob = new Blob([csvArray], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
  
    a.href = url;
    a.download = `${ "All Supplier Approvals" + dateTime.getMilliseconds()}.csv`;
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
    


  }

  exportSingle(supplier:any){

    var dateTime = new Date();
    var deliverableArray = [];
    var supplierArray = []
    supplierArray.push(supplier)

    supplierArray.forEach(x=>{
      deliverableArray.push({
        address : x.address,
        createdAt : x.createdAt,
        description : x.description,
        locationLatutude : x.locationLatutude,
        locationLongitude :x.locationLongitude,
        mobileNumber : x.mobileNumber,
        supplierEmail : x.primaryContactEmail,
        supplierName : x.supplierName,
        supplierCategory : x.supplierCategory.categoryName,
        vendorType : x.vendorType,
        status : x.status,
        requestPlatform : x.requestPlatform,
        requestSubmissionDate : x.requestSubmissionDate
      })

    })
    console.warn("this is the array to be printed ",deliverableArray);
    const replacer = (key, value) => (value === null ? '' : value); // specify how you want to handle null values here
    const header = Object.keys(deliverableArray[0]);
    const csv = deliverableArray.map((row) =>
      header
        .map((fieldName) => JSON.stringify(row[fieldName], replacer))
        .join(',')
    );
    csv.unshift(header.join(','));
    const csvArray = csv.join('\r\n');
  
    const a = document.createElement('a');
    const blob = new Blob([csvArray], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
  
    a.href = url;
    a.download = `${ supplier.supplierName + dateTime.getMilliseconds()}.csv`;
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
    


  }




}
