import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {Observable} from 'rxjs';
import {GroupedPermissions, Role, CommonResponse, User} from '../interfaces/home';

@Injectable({
  providedIn: 'root'
})
export class RoleService {
  constructor(private http: HttpClient) { }
  baseUrl = environment.baseUrl + 'Role/';
  allRole(): Observable<Role>{
    return this.http.get<Role>(this.baseUrl);
  }
  allApplicationClaims(): Observable<CommonResponse>{
    return this.http.get<CommonResponse>(this.baseUrl + 'GetAllApplicationClaims');
  }
  
  getPermissionsOnRole(name: any):Observable<Role>{
    return this.http.get<Role>(this.baseUrl +'getPermissionsOnRole/' +name);
  }
  getGroupedPermissions(): Observable<GroupedPermissions[]>{
    return this.http.get<GroupedPermissions[]>(this.baseUrl + 'getgroupedpermissions');
  }
  getUserRoleClaims(): Observable<User> {
    return this.http.get<User>(this.baseUrl + 'GetUserRoleClaims');
  }
  getRole(id: any):Observable<Role>{
    return this.http.get<Role>(this.baseUrl +id);
  }
  postRole(data: any){
    return this.http.post(this.baseUrl, data);
  }
  deleteRole(id: any){
    return this.http.delete(this.baseUrl +id);
  }
  updateRole(id: any, data: any){
    return this.http.put(this.baseUrl +id, data);
  }
  getRoleByEmail(email: any):Observable<Role>{
    return this.http.get<Role>(this.baseUrl + 'email/' + email);
  }
  getRolesByUser(userId: any):Observable<Role[]>{
    return this.http.get<Role[]>(this.baseUrl + 'getRolesByUser/' + userId);
  }

}
