import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { Observable, Subscription } from 'rxjs';
import { SettlementsService } from 'src/app/services/paystack-finance/settlements.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-account-reconciliation',
  templateUrl: './account-reconciliation.component.html',
  styleUrls: ['./account-reconciliation.component.css']
})
export class AccountReconciliationComponent implements OnInit, OnDestroy  {

  
  settlementData: any[]=[];
  selectedSettlementData: any;
  totalSettlement: number;
  totalFees: number = 0;
  dialogVisible: boolean = false;


  settlementSubscription: Subscription | undefined
  loadingData = false;
  
  constructor(private apiService: SettlementsService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    public dialogService: DialogService) { }

  ngOnInit(): void {
    // this.countDownTimer();
    this.settlementSubscription = this.loadAllSettlements();
  }


  ngOnDestroy(): void {
    this.settlementSubscription.unsubscribe();
    // this.allocationTypeSubscription.unsubscribe();
  }


  loadAllSettlements()
  {
    this.loadingData = true;
    let calculate_amount = 0;
    return this.apiService.getAllSettlements()
      .subscribe((res) => {
        if (res['responseCode'] == "00")
        {
          this.settlementData = res['responseData']['data']
          console.log(this.settlementData);
          this.settlementData.forEach(rec => {
            rec.total_amount /= 100;
            rec.total_fees /= 100;
            calculate_amount += rec.total_amount
            this.totalFees += rec.total_fees;
          })
          this.totalSettlement = calculate_amount;
          this.loadingData = false;
        }
        else{

        }
        
      },
      (err) => {
        console.log(err);
        this.displayMessage("error", "Error", "Error loading records");
      })
  }

  loadIndividualSettlements(id)
  {
    this.loadingData = true;
    let calculate_amount = 0;
    return this.apiService.getIndividualSettlement(id)
      .subscribe((res) => {
        if (res['responseCode'] == "00")
        {
          
          let data = res['responseData']['data']
          console.log(res)
          console.log(data);
          data.forEach(rec => {
            rec.amount /= 100;
            rec.fees /= 100;
          })
          let email = data[0].customer.email;
          console.log(email)
          // let email = this.selectedSettlementData[0]['customer']['email'];
          this.apiService.getIndividualDetails(email)
            .subscribe((res) => {
              console.log(res)
              if (res['responseCode'] == "00") {
                data.forEach((element) => {
                  element.customer.name = res['responseData']['name'];
                  element.customer['phone'] = res['responseData']['phoneNumber']
                });
                // this.selectedSettlementData[0].customer.name = res['responseData']['name']
                // this.selectedSettlementData[0].customer['phone'] = res['responseData']['phoneNumber']
              }
            }, (err) => {
              
              this.loadingData = false;
              this.displayMessage("error", "Error","Error fetching Name and Phone number");
              return;
            });
            this.selectedSettlementData = data;
          console.log(this.selectedSettlementData)
          this.loadingData = false;
          this.dialogVisible = true;
        }
        else{

        }
        
      },
      (err) => {
        console.log(err);
        this.displayMessage("error", "Error", "Error loading records");
        this.loadingData = false;
      })
  }

  displayMessage(severity, summary, message) {
    this.messageService.add({
      severity: severity,
      summary: summary,
      detail: message,
    });
  }

  getDetail(data) {
    console.log(data);
    this.displayMessage("info", "Information", "Processing ...");
    this.loadIndividualSettlements(data.id);
  }
  
  countDownTimer()
  {
    // Set the date we're counting down to
    var countDownDate = new Date("Sep 1, 2023 23:59:59").getTime();

    // Update the count down every 1 second
    var x = setInterval(function() {

      // Get todays date and time
      var now = new Date().getTime();

      // Find the distance between now an the count down date
      var distance = countDownDate - now;

      // Time calculations for days, hours, minutes and seconds
      var days = Math.floor(distance / (1000 * 60 * 60 * 24));
      var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      var seconds = Math.floor((distance % (1000 * 60)) / 1000);

      // Display the result in an element with id="demo"
      document.getElementById("demo").innerHTML = days + "d " + hours + "h "
      + minutes + "m " + seconds + "s ";

      // If the count down is finished, write some text
      if (distance < 0) {
        clearInterval(x);
        document.getElementById("demo").innerHTML = "EXPIRED";
      }
    }, 1000);
  }

}
