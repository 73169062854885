import { Component, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { FireBaseAuthService } from "../../services/fire-base-auth.service";
import { UserService } from "../../services/user.service";
import { CommonResponse, User } from "../../interfaces/home";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { MessageService } from "primeng/api";
import { AngularFireStorage } from "@angular/fire/storage";
import { finalize } from "rxjs/operators";
import { Observable } from "rxjs";
import { EventApi, EventInput } from "@fullcalendar/core";
import { environment } from "../../../environments/environment";
import { BreadcrumbService } from "src/app/breadcrumb.service";
import { FileStorageService } from "src/app/services/file-storage.service";

@Component({
  selector: "app-profile",
  templateUrl: "./profile.component.html",
  styleUrls: ["./profile.component.css"],
})
export class ProfileComponent implements OnInit, OnDestroy {
  activeTabIndex: number = 0;
  displayLoading: boolean;
  profileStage: number;
  pageLoading = true;
  userEmail: any;
  authUser: User;
  ProfileForm: FormGroup;
  isEditing = false;
  imageSrc: string;
  uploadingNewPicture = false;
  file: any[] = [];
  downloadURL: Observable<string>;
  fb: any;
  invalidImage = false;
  hasPasswordSet: boolean = false;
  constructor(
    public fireBaseAuthService: FireBaseAuthService,
    public userService: UserService,
    public formBuilder: FormBuilder,
    public messageService: MessageService,
    private fileStorageService: FileStorageService,
    private breadcrumbService: BreadcrumbService
  ) {
    this.profileStage = 0;
    this.displayLoading = false;
  }

  ngOnInit(): void {
    this.authUser = this.fireBaseAuthService.authUserProfile;
    this.userEmail = this.authUser.email;
    var data = this.authUser;

    this.breadcrumbService.setItems([
      { label: "Self Service", routerLink: ["/home/profile"] },
      { label: "Profile", routerLink: ["/home/profile"] },
    ]);

    this.ProfileForm = this.formBuilder.group({
      firstName: ["", [Validators.required]],
      lastName: [""],
      otherName: [""],
      codeName: [""],
      dateOfBirth: ["", [Validators.required]],
      email: ["", [Validators.required]],
      mobileNumber: ["", [Validators.required, Validators.minLength(11)]],
      altEmail: [""],
      altMobileNumber: [""],
      address: ["", [Validators.required]],
      linkedInHandle: [""],
      facebookHandle: [""],
      twitterHandle: [""],
      instagramHandle: [""],
      imageUrl: [""],
      staffId: ["0"],
      password: [""],
      confirmPassword: [""],
      currentPassword: [""],
    });

    // fetch and user user email
    this.fetchData(data);

    // set the progress
    this.ProfileForm.markAllAsTouched();
    this.ProfileForm.valueChanges.subscribe((res) => {
      const totalField = Object.keys(this.ProfileForm.value).length;
      let invalidField = 0;
      const controls = this.ProfileForm.controls;
      for (const name in controls) {
        // if(controls[name].invalid || controls[name].value == null){
        if (controls[name].invalid) {
          ++invalidField;
        }
      }

      if (
        this.ProfileForm.get("imageUrl") != null &&
        this.ProfileForm.get("imageUrl").value.indexOf("googleusercontent") > -1
      ) {
        ++invalidField;
      }
      this.profileStage = ((totalField - invalidField) / totalField) * 100;
    });
  }

  toggleEdit() {
    this.isEditing = true;
    this.ProfileForm.enable();
  }
  fetchData(data: any = null) {
    if (data) {
      this.fillForm(data);
    } else {
      this.userService.getUserByEmail(this.userEmail).subscribe(
        async (result: CommonResponse) => {
          if (result.responseCode != "00") {
            this.messageService.add({
              severity: "error",
              summary: "error",
              detail: result.responseMsg,
            });
            this.pageLoading = false;
            return;
          }

          if (result?.responseCode == "00") {
            this.fireBaseAuthService.setSessionStorageItem(
              "userProfile",
              result?.responseData
            );

            this.authUser = result.responseData;
            var res = result.responseData;
            this.fillForm(res);
            this.pageLoading = false;
          }
        },
        (error) => {
          this.messageService.add({
            severity: "error",
            summary: "Connection Error",
            detail: "Something went wrong, Contact administrator",
          });
          this.pageLoading = false;
        }
      );
    }
  }

  fillForm(res: any) {
    if (res.isPasswordGenerated) {
      this.activeTabIndex = 3;
      this.messageService.add({
        severity: "info",
        summary: "Password Change",
        detail: "Please kindly change your password",
      });
    } else {
      this.activeTabIndex = 0;
    }
    this.imageSrc = res.imageUrl;
    this.hasPasswordSet = res.hasSetPassword;
    this.ProfileForm.patchValue({
      firstName: res.firstName,
      lastName: res.lastName,
      otherName: res.otherName,
      codeName: res.codeName,
      dateOfBirth: res.dateOfBirth,
      email: res.email,
      mobileNumber: res.mobileNumber,
      imageUrl: res.imageUrl,
      altEmail: res.altEmail,
      altMobileNumber: res.altMobileNumber,
      address: res.address,
      linkedInHandle: res.linkedInHandle,
      facebookHandle: res.facebookHandle,
      twitterHandle: res.twitterHandle,
      instagramHandle: res.instagramHandle,
    });
    this.pageLoading = false;
    if (this.imageSrc === environment.defaultImage) {
      this.invalidImage = true;
    }
    if (this.ProfileForm.invalid) {
    } else {
      this.ProfileForm.disable();
    }
  }

  ifSame(string1: FormControl, string2: FormControl): boolean {
    if (
      string1.value == null ||
      string2.value == null ||
      string1.value.trim() === ""
    ) {
      return false;
    }
    return string1.value.toLowerCase() === string2.value.toLowerCase();
  }

  async updateProfile() {
    var counter = 0;
    const controls = this.ProfileForm.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
        if (name != "confirmPassword") {
          ++counter;
          this.messageService.add({
            severity: "error",
            summary: "Error",
            detail: name + " is invalid",
          });
        }
      }
    }

    if (counter > 0) {
      return;
    }

    const pwd = this.ProfileForm.get("password").value;
    const cpwd = this.ProfileForm.get("confirmPassword").value;
    const curpwd = this.ProfileForm.get("currentPassword").value;

    if (pwd) {
      //check if confirm password matches
      const compareValue = pwd.localeCompare(cpwd);
      if (compareValue == 0) {
        this.ProfileForm.get("confirmPassword").setErrors(null);
      } else {
        this.ProfileForm.get("confirmPassword").setErrors({
          "pwd-mismatch": true,
        });
        return;
      }

      if (this.hasPasswordSet) {
        if (!curpwd) {
          this.ProfileForm.get("currentPassword").setErrors({
            "need-current-password": true,
          });
          return;
        } else {
          this.ProfileForm.get("currentPassword").setErrors(null);
        }
      }
    }

    // console.log(this.ProfileForm);
    // check if there is image file and proceed to upload or use the existing picture url
    this.displayLoading = true;
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Saving user information",
    });

    if (this.file.length > 0) {
      this.fileStorageService.UploadMultipleFilesFromDataUrl(this.file);

      this.fileStorageService.onUploadFinished.subscribe(
        (resp: CommonResponse) => {
          if (resp.responseCode != "00") {
            this.messageService.add({
              severity: "error",
              summary: "Notice",
              detail: resp.responseMsg,
            });
          } else {
            var imageUrl = resp.responseData;
            this.saveUserData(imageUrl[0]);
            this.displayLoading = false;
          }
        },
        (error) => {
          console.log("Error while creating platform " + error);
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail: "ERR: Unable to upload image to storage",
          });
        }
      );
    } else {
      this.saveUserData(this.authUser.imageUrl);
      this.displayLoading = false;
    }
    this.displayLoading = false;
  }

  async saveUserData(url) {
    //determine if password was changed
    var isPasswordChanged = false;

    var input = this.ProfileForm.value;
    input.imageUrl = url;
    if (input.currentPassword && input.password && input.confirmPassword) {
      isPasswordChanged = true;
      await this.userService.updatePassword(input).subscribe((res: any) => {
        if (res.responseCode == "00") {
          this.messageService.add({
            severity: "success",
            summary: "Password Updated",
            detail: "Passowrd updated successfully",
          });
          this.updateUserDetails(input);
        } else {
          this.messageService.add({
            severity: "error",
            summary: "Password Update Failure",
            detail: res.responseMsg,
          });
        }
      });
    } else if (input.password && input.confirmPassword) {
      isPasswordChanged = true;
      await this.userService.createPassword(input).subscribe((res: any) => {
        if (res.responseCode == "00") {
          this.messageService.add({
            severity: "success",
            summary: "Updated",
            detail: "Create password was successfully",
          });
          this.updateUserDetails(input);
        } else {
          this.messageService.add({
            severity: "error",
            summary: "Create password Failure",
            detail: res.responseMsg,
          });
        }
      });
    }

    if (!isPasswordChanged) {
      this.updateUserDetails(input);
    }
  }

  private async updateUserDetails(input) {
    await this.userService
      .updateUser(this.authUser.id, input)
      .subscribe((res: any) => {
        if (res.responseCode == "00") {
          this.messageService.add({
            severity: "success",
            summary: "Updated",
            detail: "Profile updated successfully",
          });
          this.isEditing = false;
          this.fetchData();
          //location.reload();
        } else {
          this.messageService.add({
            severity: "error",
            summary: "Failure",
            detail: res.responseMsg,
          });
        }
      });
  }
  onFileSelect(event) {
    if (!this.checkMimeType(event) || !this.checkFileSize(event)) {
      return;
    }

    this.uploadingNewPicture = true;
    this.isEditing = true;
    this.ProfileForm.enable();
    const reader = new FileReader();
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      this.file.push(event.target.files[0]);
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.imageSrc = reader.result as string;
        this.ProfileForm.patchValue({
          imageUrl: reader.result,
        });
      };
    }
  }

  checkMimeType = (event: EventInput): boolean => {
    const files = event.target.files[0];
    // list allow mime type
    const types = ["image/png", "image/jpeg", "image/jpg", "image/gif"];
    // loop access array
    if (!files) {
      this.messageService.add({
        severity: "error",
        summary: "Failed",
        detail: "Please select an Image file",
      });
      return false;
    }
    // compare file type find doesn't match
    if (types.every((type) => files.type !== type)) {
      // create error message and assign to container
      this.messageService.add({
        severity: "error",
        summary: "Failed",
        detail: "Image format not supported",
      });
      return false;
    }
    return true;
  };

  checkFileSize = (event: EventInput) => {
    const files = event.target.files[0];
    if (!files) return true;
    const size = 400000;
    if (files.size > size) {
      event.target.value = null;
      this.messageService.add({
        severity: "error",
        summary: "Failed",
        detail: "Image is too large, please pick a smaller file",
      });
      return false;
    }
    return true;
  };

  ngOnDestroy(): void {}
}
