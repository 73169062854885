import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import {
  AllSMORoutesAndRegion,
  PriceRegister,
} from "src/app/interfaces/armada";
import { CommonResponse } from "src/app/interfaces/home";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class PriceRegisterService {
  baseUrl = environment.baseUrl;
  //baseUrl = "https://localhost:44344/api/v1/";
  constructor(private httpClient: HttpClient) {}

  allPriceRegisters(): Observable<CommonResponse> {
    return this.httpClient.get<CommonResponse>(
      this.baseUrl + "PriceRegister/GetAllPriceRegisters"
    );
  }

  allPreferentialPricings(): Observable<CommonResponse> {
    return this.httpClient.get<CommonResponse>(
      this.baseUrl + "PriceRegister/GetAllPreferentialPrices"
    );
  }
  getAllPreferentialPricingsByContractServiceAndClientId(
    cserviceID,
    clientId
  ): Observable<CommonResponse> {
    return this.httpClient.get<CommonResponse>(
      this.baseUrl +
        "PriceRegister/GetAllPreferencialPricesByContractServiceAndClientId/" +
        cserviceID +
        "/" +
        clientId
    );
  }

  getPriceRegister(id: any): Observable<CommonResponse> {
    return this.httpClient.get<CommonResponse>(
      this.baseUrl + "PriceRegister/GetPriceRegisterById/" + id
    );
  }
  getPreferentialPricing(id: any): Observable<CommonResponse> {
    return this.httpClient.get<CommonResponse>(
      this.baseUrl + "PriceRegister/GetPreferentialPriceById/" + id
    );
  }

  getAllPriceRegisterByRouteId(id: any): Observable<CommonResponse> {
    return this.httpClient.get<CommonResponse>(
      this.baseUrl + "PriceRegister/GetAllPriceRegistersByRouteId/" + id
    );
  }

  postPriceRegister(data: any) {
    return this.httpClient.post(
      this.baseUrl + "PriceRegister/AddNewPriceRegister",
      data
    );
  }
  postPreferentialPrice(data: any) {
    return this.httpClient.post(
      this.baseUrl + "PriceRegister/AddNewPreferentialPrice",
      data
    );
  }
  deletePriceRegister(id: any) {
    return this.httpClient.delete(
      this.baseUrl + "PriceRegister/DeletePriceRegisterById/" + id
    );
  }
  deletePreferentialPrice(id: any) {
    return this.httpClient.delete(
      this.baseUrl + "PriceRegister/DeletePreferentialPrice/" + id
    );
  }
  updatePriceRegister(id: any, data: any) {
    return this.httpClient.put(
      this.baseUrl + "PriceRegister/UpdatePriceRegisterById/" + id,
      data
    );
  }

  // allSMORoutesAndRegions(): Observable<AllSMORoutesAndRegion> {
  //   return this.httpClient.get<AllSMORoutesAndRegion>(
  //     this.baseUrl + "PriceRegister/GetAllPriceRegisters"
  //   );
  // }
}
