import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { MessageService, ConfirmationService, Message } from "primeng/api";
import { BreadcrumbService } from "src/app/breadcrumb.service";
import {
  DtrackJournalHeader,
  DtrackJournalLine,
} from "src/app/interfaces/home";
import { AccountMasterService } from "src/app/services/account-master";
import { FileStorageService } from "src/app/services/file-storage.service";

@Component({
  selector: "app-dtrack-transmission",
  templateUrl: "./dtrack-transmission.component.html",
  styleUrls: ["./dtrack-transmission.component.scss"],
  providers: [MessageService],
})
export class DtrackTransmissionComponent implements OnInit {
  @ViewChild("formWrapper") public formWrapper: ElementRef;
  msg: Message[] = [];
  fetching: boolean;
  cols: any[];
  allFailedTransmissions: DtrackJournalHeader[] = [];
  selectedFailedTransmissions: DtrackJournalHeader[];
  transmissionLines: DtrackJournalLine[] = [];
  openDialogue: boolean;
  fetchingLogs: boolean;
  logCols: any[];

  constructor(
    public accountMasterService: AccountMasterService,
    public messageService: MessageService,
    private breadcrumbService: BreadcrumbService,
    public confirmationService: ConfirmationService,
    private fileStorageService: FileStorageService
  ) {}

  ngOnInit(): void {
    this.breadcrumbService.setItems([
      {
        label: "Finance",
      },
      {
        label: "Dtrack Transmission",
        routerLink: ["/home/finance/dtrack-transmission"],
      },
    ]);

    this.cols = [
      { field: "id", header: "ID" },
      { field: "year", header: "Year" },
      { field: "month", header: "Month" },
      { field: "subAccount", header: "Sub Account" },
      { field: "accountSection", header: "Account Section" },
      { field: "currencyCode", header: "Currency Code" },
      { field: "exchangeRate", header: "Exchange Rate" },
      { field: "tranDate", header: "Transaction Date" },
      { field: "documentDate", header: "Document Date" },
      { field: "tranType", header: "Tran Type" },
      { field: "module", header: "Module" },
      { field: "transactionReference", header: "Transaction Reference" },
      { field: "note", header: "Note" },
      { field: "methodName", header: "Method Name" },
      { field: "dtrackJournalCode", header: "Dtrack Journal Code" },
      { field: "isSentToDtrack", header: "Is Sent To Dtrack" },
      { field: "dateSentToDtrack", header: "Date Sent To Dtrack" },
      { field: "isDtrackPushFailed", header: "Is Dtrack Push Failed" },
      { field: "dtrackFailedResponse", header: "Failure Message" },
      { field: "dateCreated", header: "Date Created" },
    ];

    this.logCols = [
      { field: "id", header: "ID" },
      { field: "glAccount", header: "GL Account" },
      { field: "subAccount", header: "Sub Account" },
      { field: "accountSection", header: "Account Section" },
      { field: "costCenter", header: "Cost Center" },
      { field: "amount", header: "Amount" },
      { field: "factor", header: "Factor" },
      { field: "details", header: "Details" },
      { field: "sourceCode", header: "Source Code" },
      { field: "dateCreated", header: "Date Created" },
    ];

    this.FetchFailedTransmissions();
    this.ResetMessageToasters();
  }

  ResetMessageToasters() {
    this.msg = [];
    this.msg.push({
      severity: "info",
      summary:
        "There are currently " +
        this.allFailedTransmissions.length +
        " failed Dtrack Transmissions.",
    });
  }

  async FetchFailedTransmissions() {
    this.fetching = true;
    this.accountMasterService.GetFailedDtrackTransmissions().subscribe(
      async (data) => {
        if (data.responseCode != "00") {
          this.messageService.add({
            severity: "error",
            summary: data.responseMsg,
          });
          this.fetching = false;
          this.ResetMessageToasters();
          return;
        }

        this.allFailedTransmissions = data.responseData;
        this.ResetMessageToasters();
        this.fetching = false;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary:
            "Unable to fetch failed transmissions at the moment.. Reason: [" +
            error.error.message +
            "]",
        });
        this.ResetMessageToasters();
        this.fetching = false;
      }
    );
  }

  ViewJournalLines(item: DtrackJournalHeader) {
    this.openDialogue = true;
    this.transmissionLines = item.journalLines;
  }

  ResetTransmission(item: DtrackJournalHeader) {
    this.confirmationService.confirm({
      header: "Confirmation Dialog",
      icon: "pi pi-exclamation-triangle",
      message:
        "You are about to reset this failed transmission for re-transmission, It is an irreversible action. Do you still wish to proceed?",
      acceptLabel: "Yes, Please Proceed",
      rejectLabel: "No, Don't Proceed",
      accept: async () => {
        this.messageService.add({
          severity: "info",
          summary: "Notice",
          detail: "Resetting Failed Transmission......",
        });

        this.accountMasterService.ResetDtrackTransmission(item.id).subscribe(
          async (data) => {
            if (data.responseCode != "00") {
              this.messageService.add({
                severity: "error",
                summary: data.responseMsg,
              });
              this.ResetMessageToasters();
              return;
            }

            this.messageService.add({
              severity: "success",
              summary: "Completed",
              detail: "Failed Transmission Reset Successfully!",
            });
            this.ResetMessageToasters();
            this.FetchFailedTransmissions();
          },
          (error) => {
            console.log("Error: " + JSON.stringify(error));
            this.messageService.add({
              severity: "error",
              summary: "Notice",
              detail:
                "Unable to reset transmission at the moment.. Reason: [" +
                (error ? error.error.message : "request failed - permission") +
                "]",
            });
            this.ResetMessageToasters();
          }
        );
      },
    });
  }

  HideDialog() {
    this.openDialogue = false;
  }
}
