import { Component, Input, OnInit } from "@angular/core";
import {
  DialogService,
  DynamicDialogConfig,
  DynamicDialogRef,
} from "primeng/dynamicdialog";
import {
  AccountMaster,
  CommonResponse,
  GetInvoicesAggregatedDitsBreakdownVM,
  Invoice,
  InvoiceEvidenceSubmission,
  SaveInvoiceEvidenceSubmissionVM,
  StrategicBusinessUnit,
  User,
} from "../../../../interfaces/home";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ConfirmationService, MessageService } from "primeng/api";
import { ViewInvoiceComponent } from "../../invoice-creation/view-invoice/view-invoice.component";
import { InvoiceService } from "src/app/services/invoice.sevice";
import { FileStorageService } from "src/app/services/file-storage.service";
import { AccountMasterService } from "src/app/services/account-master";

@Component({
  selector: "app-invoices",
  templateUrl: "./invoices.component.html",
  styleUrls: ["./invoices.component.css"],
  providers: [ConfirmationService, MessageService],
})
export class InvoicesComponent implements OnInit {
  @Input() listOfInvoices: Invoice[];

  invoices: Invoice[];
  selectedInvoice: any;
  invoiceCols: any;
  private invoiceExportColumns: any;
  viewInvoiceRef: DynamicDialogRef;
  openPostingDetailsDialogue: boolean;
  fetchingDetails: boolean;
  postingDetails: GetInvoicesAggregatedDitsBreakdownVM[] = [];
  amtInWords: string;
  invoiceNo: string;
  transactionDate: string;
  invoiceDescription: string;
  currencyCode: string;
  transactionReference: string;
  transmissionStatus: string;
  transmissionMsg: string;
  transmissionResetStatus: string;
  journalHeaderId: number;
  errorResponseMsg: string;
  openEvidenceSubDialogue: boolean;
  totalInvValue: string;
  invStartDate: string;
  submittedInvForPeriod: number;
  submittedInvForCreditNote: number;
  allClientInvCreditNotes: AccountMaster[];
  selectedClientInvCreditNotes: AccountMaster[] = [];
  uploadedFiles: any[] = [];
  userIsYetToClickUploadFile: boolean;
  loadingEvidenceSubmission: boolean;
  invoiceEvidenceSubmission: InvoiceEvidenceSubmission;
  invInViewForSubmission: Invoice;

  constructor(
    //public config: DynamicDialogConfig,
    //public invoicesModelRef: DynamicDialogRef,
    private accountMasterService: AccountMasterService,
    private fileStorageService: FileStorageService,
    public invoiceService: InvoiceService,
    public dialogService: DialogService,
    public confirmationService: ConfirmationService,
    public messageService: MessageService
  ) {}

  ngOnInit(): void {
    //this.invoices = this.config?.data?.invoices ?? this.listOfInvoices;

    this.invoices = this.listOfInvoices;

    this.invoiceCols = [
      { field: "invoiceNumber", header: "Invoice Number" },
      { field: "invoiceValue", header: "Invoice Value" },
      { field: "invoiceMonth", header: "Invoice Month" },
      { field: "invoiceYear", header: "Invoice Year" },
      { field: "receiptStatus", header: "Receipt Status" },
    ];
    this.invoiceExportColumns = this.invoiceCols.map((col) => ({
      title: col.header,
      dataKey: col.field,
    }));

    this.FetchInvoicesExtraData();
  }

  closeModal() {
    //this.invoicesModelRef.close();
  }

  displayInvoice(invoiceId: number) {
    this.viewInvoiceRef = this.dialogService.open(ViewInvoiceComponent, {
      width: "950px",
      contentStyle: { "max-width": "100vw", height: "90vh", overflow: "auto" },
      baseZIndex: 100,
      data: { invoiceId: invoiceId, type: "Invoice" },
    });

    this.viewInvoiceRef.onClose.subscribe(() => {
      let invDisplayed = this.invoices.find((x) => x.id == invoiceId);
      this.invoiceService.getInvoice(invoiceId).subscribe(async (data) => {
        if (data.responseCode == "00") {
          invDisplayed.invoiceEvidenceSubmissionId =
            data.responseData.invoiceEvidenceSubmissionId;
        }
      });
    });
  }

  async FetchInvoicesExtraData() {
    let invoicesNo = this.invoices.map((x) => x.invoiceNumber);
  }

  async FetchUnsubmittedCredits() {
    this.accountMasterService
      .GetCreditNotesNotSubmitted(
        this.invInViewForSubmission.customerDivisionId
      )
      .subscribe(
        async (data) => {
          if (data.responseCode != "00") {
            this.messageService.add({
              severity: "error",
              summary: "Notice",
              detail: data.responseMsg,
            });
            return;
          }

          this.allClientInvCreditNotes = data.responseData;
        },
        (error) => {
          console.log("Error: " + JSON.stringify(error));
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail:
              "Unable to get unsubmitted credit notes at the moment.. Reason: [" +
              (error ? error.error.message : "request failed - permission") +
              "]",
          });
        }
      );
  }

  DisplayPostingDetails(invoiceId: number) {
    this.transmissionResetStatus = null;
    this.openPostingDetailsDialogue = true;
    this.fetchingDetails = true;
    this.invoiceService.GetInvoicesAggregatedDits(invoiceId).subscribe(
      async (data) => {
        if (data.responseCode != "00") {
          this.messageService.add({
            severity: "error",
            summary: data.responseMsg,
          });
          this.fetchingDetails = false;
          this.errorResponseMsg = data.responseMsg;
          return;
        }

        this.journalHeaderId = data.responseData.journalHeaderId;
        this.currencyCode = data.responseData.currencyCode;
        this.transactionReference = data.responseData.transactionReference;
        this.invoiceNo = data.responseData.invoiceNumber;
        this.invoiceDescription = data.responseData.invoiceDescription;
        this.transactionDate = this.FormatDateString(
          new Date(data.responseData.transactionDate)
        );
        this.transmissionStatus =
          data.responseData.transmissionStatus.toUpperCase();
        this.transmissionMsg =
          data.responseData.transmissionMessage.toUpperCase();
        this.postingDetails = data.responseData.breakdown;
        this.amtInWords = this.NumInWords(
          this.postingDetails.find((x) => x.isTotal).drAmount
        ).toUpperCase();
        this.errorResponseMsg = null;
        this.fetchingDetails = false;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to get posting details at the moment.. Reason: [" +
            error.error.message +
            "]",
        });
        this.errorResponseMsg = error.error.message;
        this.fetchingDetails = false;
      }
    );
  }

  HidePostingDetailsDialogue() {
    this.openPostingDetailsDialogue = false;
    this.postingDetails = [];
    this.currencyCode = null;
    this.transactionReference = null;
    this.invoiceNo = null;
    this.invoiceDescription = null;
    this.transactionDate = null;
    this.transmissionStatus = null;
    this.transmissionMsg = null;
    this.fetchingDetails = false;
  }

  FormatDateString(date: Date): string {
    let dateString = date.toString();

    try {
      // DateTimeFormatOptions
      const date: Date = new Date(dateString);
      const options: Intl.DateTimeFormatOptions = {
        year: "numeric",
        month: "long",
        day: "numeric",
      };
      const formattedDate: string = new Intl.DateTimeFormat(
        "en-US",
        options
      ).format(date);
      dateString = formattedDate;
    } catch (error) {
      console.log(error);
      console.log(date);
      console.log(
        "Error while converting date string " + date + " exception " + error
      );
    }

    return dateString;
  }

  NumInWords(number: number): string {
    const first = [
      "",
      "one ",
      "two ",
      "three ",
      "four ",
      "five ",
      "six ",
      "seven ",
      "eight ",
      "nine ",
      "ten ",
      "eleven ",
      "twelve ",
      "thirteen ",
      "fourteen ",
      "fifteen ",
      "sixteen ",
      "seventeen ",
      "eighteen ",
      "nineteen ",
    ];
    const tens = [
      "",
      "",
      "twenty",
      "thirty",
      "forty",
      "fifty",
      "sixty",
      "seventy",
      "eighty",
      "ninety",
    ];
    const mad = ["", "thousand", "million", "billion", "trillion"];
    let word = "";

    for (let i = 0; i < mad.length; i++) {
      let tempNumber = number % (100 * Math.pow(1000, i));
      if (Math.floor(tempNumber / Math.pow(1000, i)) !== 0) {
        if (Math.floor(tempNumber / Math.pow(1000, i)) < 20) {
          word =
            first[Math.floor(tempNumber / Math.pow(1000, i))] +
            mad[i] +
            " " +
            word;
        } else {
          word =
            tens[Math.floor(tempNumber / (10 * Math.pow(1000, i)))] +
            "-" +
            first[Math.floor(tempNumber / Math.pow(1000, i)) % 10] +
            mad[i] +
            " " +
            word;
        }
      }

      tempNumber = number % Math.pow(1000, i + 1);
      if (Math.floor(tempNumber / (100 * Math.pow(1000, i))) !== 0)
        word =
          first[Math.floor(tempNumber / (100 * Math.pow(1000, i)))] +
          "hunderd " +
          word;
    }
    return word;
  }

  ResetTransmission() {
    this.transmissionResetStatus =
      "Please hold on, resetting transmission status..";

    if (!this.journalHeaderId) {
      this.transmissionResetStatus =
        "Reset Failed! Missing Transmission ID Info";
      return;
    }

    this.invoiceService.ResetTransmissionById(this.journalHeaderId).subscribe(
      async (data) => {
        if (data.responseCode != "00") {
          this.transmissionResetStatus = data.responseMsg;
        } else {
          this.transmissionResetStatus = data.responseMsg;
          this.transmissionStatus = "Pending";
          this.transmissionMsg = "Awaiting Transmission To Dtrack";
        }
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.transmissionResetStatus =
          "Error: Unable to reset transmission at the moment.. Reason: [" +
          error.error.message +
          "]";
      }
    );
  }

  HideEvidenceSubDialogue() {
    this.openEvidenceSubDialogue = false;
    this.uploadedFiles = [];
    this.userIsYetToClickUploadFile = false;
    this.submittedInvForPeriod = null;
    this.submittedInvForCreditNote = null;
    this.totalInvValue = null;
    this.invStartDate = null;
    this.invInViewForSubmission = null;
    this.invoiceEvidenceSubmission = null;
    this.allClientInvCreditNotes = [];
    this.selectedClientInvCreditNotes = [];
    this.allClientInvCreditNotes = [];
  }

  onCancelUpload() {
    this.uploadedFiles = [];
  }

  NotifyUserToClickUpload() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Kindly ensure you click upload.",
    });

    this.userIsYetToClickUploadFile = true;
  }

  onUploadFile(event) {
    this.uploadedFiles = [];
    for (const file of event.files) {
      this.uploadedFiles.push(file);
    }

    this.messageService.add({
      severity: "success",
      summary: "Success",
      detail: "File(s) Uploaded",
    });

    this.userIsYetToClickUploadFile = false;
  }

  async SubmitInvoiceEvidence() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Submitting Invoice Evidence...",
    });

    const postData: SaveInvoiceEvidenceSubmissionVM = {
      invoiceId: this.invInViewForSubmission.id,
      isDeclaredFactorSubmission: this.submittedInvForCreditNote == 1,
      isDeclaredPeriodSubmission: this.submittedInvForPeriod == 1,
      creditNoteIds: this.selectedClientInvCreditNotes.map((x) => x.id),
      evidenceUrls: [],
    };

    if (this.uploadedFiles.length > 0) {
      this.fileStorageService.UploadMultipleFilesFromDataUrl(
        this.uploadedFiles
      );
      this.fileStorageService.onUploadFinished.subscribe(
        (resp: CommonResponse<string[]>) => {
          if (resp.responseCode != "00") {
            this.messageService.add({
              severity: "error",
              summary: "Notice",
              detail: resp.responseMsg,
            });
          } else {
            postData.evidenceUrls = resp.responseData;
            this._submitInvoiceEvidence(postData);
          }
        },
        (error) => {
          console.log("Error while uploading files " + error);
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail: "ERR: Unable to upload file(s) to storage",
          });
        }
      );
    } else this._submitInvoiceEvidence(postData);
  }

  _submitInvoiceEvidence(postData: SaveInvoiceEvidenceSubmissionVM) {
    this.invoiceService.SaveInvoiceEvidenceSubmission(postData).subscribe(
      async (data) => {
        if (data.responseCode != "00") {
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail: data.responseMsg,
          });
          return;
        }

        this.messageService.add({
          severity: "success",
          summary: "Completed",
          detail: "Submitted Successfully!",
        });
        this.invInViewForSubmission.invoiceEvidenceSubmissionId = 10;
        this.HideEvidenceSubDialogue();
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to submit at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  InvoiceEvidenceSubmissionView(item: Invoice) {
    this.invInViewForSubmission = item;
    this.FetchUnsubmittedCredits();
    this.totalInvValue = "N" + item.value.toLocaleString();
    this.invStartDate = this.FormatDateString(item.startDate);
    this.openEvidenceSubDialogue = true;
    this.loadingEvidenceSubmission = true;
    if (!item.invoiceEvidenceSubmissionId) {
      this.loadingEvidenceSubmission = false;
      return;
    }
    this.invoiceService.GetInvoiceEvidenceSubmission(item.id).subscribe(
      async (data) => {
        if (data.responseCode != "00") {
          this.messageService.add({
            severity: "error",
            summary: data.responseMsg,
          });
          this.loadingEvidenceSubmission = false;
          this.HideEvidenceSubDialogue();
          return;
        }

        this.invoiceEvidenceSubmission = data.responseData;
        if (this.invoiceEvidenceSubmission) {
          this.submittedInvForPeriod = this.invoiceEvidenceSubmission
            .isDeclaredSubmission
            ? 1
            : 2;
          this.submittedInvForCreditNote = this.invoiceEvidenceSubmission
            .isDeclaredFactoredCreditNote
            ? 1
            : 2;
        }
        this.loadingEvidenceSubmission = false;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to get data at the moment.. Reason: [" +
            error.error.message +
            "]",
        });
        this.loadingEvidenceSubmission = false;
        this.HideEvidenceSubDialogue();
      }
    );
  }
}
