import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {  ConfirmationService, MessageService } from 'primeng/api';
import { Subscription } from 'rxjs';
import { BreadcrumbService } from 'src/app/breadcrumb.service';
import { BudgetapiservicesService } from 'src/app/services/budget/budgetapiservices.service';
import { ConfirmEventType } from 'src/app/interfaces/armada';

@Component({
  selector: 'app-show-budget-season',
  templateUrl: './show-budget-season.component.html',
  styleUrls: ['./show-budget-season.component.css']
})
export class ShowBudgetSeasonComponent implements OnInit {
  @Output() enableEditRecord = new EventEmitter<boolean>();
  @Output("reloadPage") reloadPage: EventEmitter<any> = new EventEmitter();
  budgetSeasonListForm = this.fb.group({
    search: ['', ]
  })

  budgetSeasonSubscription: Subscription | undefined;
  message: string;
  isEditingRecord: boolean = false;

  constructor(private service: BudgetapiservicesService,
              private messageService: MessageService,
              private fb: FormBuilder,
              private router: Router,
              private route: ActivatedRoute,
              private confirmationService: ConfirmationService,
              private breadcrumbService: BreadcrumbService) { }


  
  BudgetSeasonList: any[] = [];
  BudgetSeasonListDisplay: any[] | undefined;
  responsiveOptions: any[];
  ModalTitle = "";
  caption = "";
  prepEndDate = "";
  cyclesCreated = "";
  isPublished = "";
  today =  new Date()
  loadingData: boolean = false;
  isFormBusy: boolean = false;
  

  // ActivateAddEditEmpComp: boolean = false;
  // seasonData: any;

  ngOnInit(): void {
    this.breadcrumbService.setItems([
      { label: "Budget Seasons", routerLink: ["home/budget-season"] },
      // { label: "Role", routerLink: ["home/role"] },
    ]);
;
    this.budgetSeasonSubscription = this.loadBudgetSeasonList();
    this.responsiveOptions = [
      {
          breakpoint: '1400px',
          numVisible: 3,
          numScroll: 3
      },
      {
          breakpoint: '1220px',
          numVisible: 2,
          numScroll: 2
      },
      {
          breakpoint: '1100px',
          numVisible: 1,
          numScroll: 1
      }
    ];
    console.log(this.today);
    console.log(typeof(this.today));
  }

  ngOnDestroy(): void {
    this.budgetSeasonSubscription.unsubscribe();
  }

  loadBudgetSeasonList() {
    this.loadingData = true;
    return this.service.getBudgetSeasons()
      .subscribe(data => {
        this.BudgetSeasonList = data['responseData'];
        this.BudgetSeasonListDisplay = this.BudgetSeasonList;
        this.loadingData = false;
        console.log(data['responseData'][0]);
      }, err =>{
        console.log(err)
        this.loadingData = false;
      });
  }

  isBudgetSessionExpired(season){
    // console.log(season.prepEndDate)
    return new Date(season.prepEndDate).valueOf() < new Date(this.today).valueOf();
  }

  publishBudget(season) {
    season['isPublished'] = true
    this.service.updateBudgetSeason(season).subscribe(data => {
      this.loadingData = false;
      console.log(data);
      setTimeout(() => {
        this.displayMessage("success", "Success", "Budget season succefully published");
      }, 3000);
    });
  }

  displayMessage(severity, summary, message) {
    this.messageService.add({
      severity: severity,
      summary: summary,
      detail: message,
    });
  }

  searchBudgetList(){

  }

  checkIfCaptionExist(evt) {
   var filteredList =  this.BudgetSeasonList.filter(rec => {
      return rec.caption.toLowerCase().includes(evt.toLowerCase());
    });
    console.log(filteredList);
    this.BudgetSeasonListDisplay = filteredList;
  }

  deleteSeason(arg) {
    this.confirmationService.confirm({
      message: `This action will delete record from database. Proceed with it?`,
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        // this.messageService.add({ severity: 'info', summary: 'Confirmed', detail: 'You have accepted' });
        this.displayMessage("info", "Information", "Processing operation")
        this.loadingData=true;
        this.deleteConfirmed(arg);
        
      },
      reject: (type: ConfirmEventType) => {
          switch (type) {
              case ConfirmEventType.REJECT:
                  this.displayMessage("warn","Cancelled","You have cancelled this operation");
                  break;
              case ConfirmEventType.CANCEL:
                  this.displayMessage("warn","Cancelled","You have cancelled this operation");
                  break;
          }
        }
    });
    
  }

  deleteConfirmed(arg) {
    console.log(arg)
    // return
    this.service.deleteBudgetSeason(arg.id)
      .subscribe((resp) => {
        if(resp["responseCode"] =="00") {
          setTimeout(() => {
            this.displayMessage("success", "Success", "Successfully deleted record")
            this.loadingData = false;
            this.isFormBusy = false;
          }, 2000)

          this.reloadPage.emit();
        }
        else {
          setTimeout(() => {
            this.displayMessage("error", "Error", "Error deleting record")
            
          }, 2000)
        }
      }, (err) => {
        setTimeout(() => {
          this.displayMessage("error", "Error", `${JSON.stringify(err)}`);
          
        }, 2000)
        
        console.log(err);
        
      });
      this.loadingData = false;
      this.isFormBusy = false;
  }

  editSeason(arg) {
    console.log(arg)
    this.isEditingRecord = true;
    this.enableEditRecord.emit(this.isEditingRecord);
    this.service.displaySelectedBudgetToEdit(arg);
  }

  cancelEdit(){
    this.isEditingRecord = false;
    this.enableEditRecord.emit(this.isEditingRecord);
    this.service.cancelSelectedBudgetToEdit();
  }

  // reloadPage(){
  //   this.router.routeReuseStrategy.shouldReuseRoute = () => false;
  //   this.router.onSameUrlNavigation = 'reload';
  //   this.router.navigate(['./'], {
  //     relativeTo: this.route
  //   });
  // }

}
