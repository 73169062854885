import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { GroupType } from "../../../interfaces/home";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { ConfirmationService, MessageService } from "primeng/api";
import { GroupTypeService } from "../../../services/group-type.service";
import { BreadcrumbService } from "src/app/breadcrumb.service";

@Component({
  selector: "app-group-type",
  templateUrl: "./group-type.component.html",
  styleUrls: ["./group-type.component.scss"],
  providers: [ConfirmationService, MessageService],
})
export class GroupTypeComponent implements OnInit {
  @ViewChild("formWrapper") public formWrapper: ElementRef;
  fetchingGroupType = true;
  groupTypeForm: FormGroup;
  groupTypes: GroupType[];
  selectedGroupType: GroupType;
  editingGroupType = false;
  public groupTypeCols: any[];
  public exportGroupTypeColumns: any[];
  private edGroupType: GroupType;
  constructor(
    private formBuilder: FormBuilder,
    private groupTypeService: GroupTypeService,
    public messageService: MessageService,
    public confirmationService: ConfirmationService,
    private breadcrumbService: BreadcrumbService
  ) {}

  ngOnInit(): void {
    this.breadcrumbService.setItems([
      { label: "Qualification", routerLink: ["home/qualification"] },
      { label: "Group Type", routerLink: ["home/qualification/group-type"] },
    ]);
    this.fetchGroupType();
    this.groupTypeForm = this.formBuilder.group({
      description: ["", Validators.required],
      caption: ["", [Validators.required]],
    });
    this.groupTypeCols = [
      { field: "caption", header: "Caption" },
      { field: "description", header: "Description" },
    ];
    this.exportGroupTypeColumns = this.groupTypeCols.map((col) => ({
      title: col.header,
      dataKey: col.field,
    }));
  }
  fetchGroupType() {
    this.groupTypeService.allGroupTypeData().subscribe(
      async (groupType: any) => {
        this.groupTypes = groupType.responseData;
        this.fetchingGroupType = false;
      },
      (error) => {
        this.fetchingGroupType = false;
        this.connectionError();
      }
    );
  }
  private connectionError() {
    this.messageService.add({
      severity: "error",
      summary: "Failed",
      detail: "Connection Error, Please try again",
    });
  }
  createGroupType() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Creating new Group Type Records",
    });
    const postData = {
      description: this.groupTypeForm.get("description").value,
      caption: this.groupTypeForm.get("caption").value,
    };
    this.groupTypeService.postGroupType(postData).subscribe(
      async () => {
        await this.messageService.add({
          severity: "success",
          summary: "Completed",
          detail: "New Group Type Created",
        });
        await this.fetchGroupType();
        await this.groupTypeForm.reset();
      },
      (error) => {
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail: "Unable to create new Group Type at the moment",
        });
      }
    );
  }

  deleteGroupType(groupType) {
    this.confirmationService.confirm({
      message: "Are you sure you want to drop " + groupType.caption,
      accept: () => {
        this._deleteGroupType(groupType);
      },
    });
  }
  _deleteGroupType(groupType) {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Dropping groupType record",
    });
    this.groupTypeService.deleteGroupType(groupType.id).subscribe(
      async (result: any) => {
        await this.messageService.add({
          severity: "success",
          summary: "Dropped",
          detail: "GroupType record dropped",
        });
        await this.fetchGroupType();
      },
      (error) => {
        this.connectionError();
      }
    );
  }
  editGroupType(groupTypes) {
    this.editingGroupType = true;
    this.groupTypeForm.addControl(
      "groupTypeId",
      new FormControl({ value: "", disabled: true }, Validators.required)
    );
    this.edGroupType = groupTypes;
    this.groupTypeForm.patchValue({
      caption: groupTypes.caption,
      description: groupTypes.description,
      groupTypeId: groupTypes.id,
    });
    // this.groupTypeFormIn.nativeElement.focus();
    // document.getElementById('formFields').focus();
    // this.selectedHeadId = this.users.find(user => user.id === groupTypes.head.id);
    this.formWrapper.nativeElement.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "start",
    });
  }

  updateGroupType() {
    // update the selected groupType
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Updating GroupType details",
    });
    const id = this.edGroupType.id;
    const postData = {
      caption: this.groupTypeForm.get("caption").value,
      description: this.groupTypeForm.get("description").value,
    };
    this.groupTypeService
      .updateGroupType(id, postData)
      .subscribe(async (result: GroupType) => {
        this.messageService.add({
          severity: "success",
          summary: "Notice",
          detail: "GroupType details Updated",
        });
        await this.fetchGroupType();
        await this.closeEditing();
      });
  }

  closeEditing() {
    this.editingGroupType = false;
    this.groupTypeForm.reset();
    this.edGroupType = null;
  }
}
