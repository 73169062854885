///<reference types="@types/googlemaps" />
import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { EventInput } from "@fullcalendar/core";
import { ConfirmationService, MessageService } from "primeng/api";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { BreadcrumbService } from "src/app/breadcrumb.service";
import {
  ArmadaJourneyStart,
  ArmadaJourneyStop,
  CommanderServiceAssignmentDetail,
  JourneyIncident,
  JourneyIncidentPic,
  JourneyLeadCommander,
  JourneyNote,
  MasterServiceAssignment,
} from "src/app/interfaces/armada";
import { CommonResponse } from "src/app/interfaces/home";
import { JourneyStartAndStopService } from "src/app/services/armada/journey-start-and-stop.service";
import { MasterServiceAssignmentService } from "src/app/services/armada/master-service-assignment.service";
import { ServiceAssignmentDetailService } from "src/app/services/armada/service-assignment-detail.service";
import { FileStorageService } from "src/app/services/file-storage.service";

@Component({
  selector: "app-journey-start",
  templateUrl: "./journey-start.component.html",
  styleUrls: ["./journey-start.component.scss"],
})
export class JourneyStartComponent implements OnInit, AfterViewInit {
  @ViewChild("formWrapper") public formWrapper: ElementRef;
  @ViewChild("noteDesc") noteDesc;
  @ViewChild("noteCap") noteCap;
  @ViewChild("stopDesc") stopDesc;
  @ViewChild("stopCap") stopCap;
  @ViewChild("incidentDesc") incidentDesc;
  @ViewChild("incidentCap") incidentCap;
  @ViewChild("incidentType") incidentType;
  private unsubscriber$ = new Subject<void>();

  public targetCols: any;
  private exportTargetColumns: any;
  public submittingData: boolean;
  public loading: boolean;
  public submitButtonLabel: string;

  public masterServiceAssignments: MasterServiceAssignment[];
  public availableReadyAssignments: MasterServiceAssignment[];
  selectedmasterServiceAssignment: MasterServiceAssignment;

  //For all
  actualLatitude: number;
  actualLongitude: number;
  actualAddress: string;
  //ArmadaJourneyStart
  public armadaJourneyStarts: ArmadaJourneyStart[];
  public armadaJourneyStart: ArmadaJourneyStart;
  journeyStartId: number;
  public armadaJourneyStartForm: FormGroup;
  public armadaJourneyEndForm: FormGroup;
  selectedArmadaJourneyStart: any;
  editingArmadaJourneyStart = false;
  private edArmadaJourneyStart: ArmadaJourneyStart;
  startLatitude: number;
  startLongitudw: number;
  startAddress: string;
  endLatitude: number;
  endLongitudw: number;
  endAddress: string;

  //ArmadaJourneyStop
  public armadaJourneyStops: ArmadaJourneyStop[];
  public armadaJourneyStopForm: FormGroup;
  selectedArmadaJourneyStop: any;
  editingArmadaJourneyStop = false;
  private edArmadaJourneyStop: ArmadaJourneyStop;
  stopLatitude: number;
  stopLongitudw: number;
  stopAddress: string;

  //ArmadaJourneyIncident
  public journeyIncidents: JourneyIncident[];
  public journeyIncidentForm: FormGroup;
  journeyIncidentId: number;
  selectedJourneyIncident: any;
  editingJourneyIncident = false;
  private edJourneyIncident: JourneyIncident;
  incidentLatitude: number;
  incidentLongitudw: number;
  incidentAddress: string;

  public commanderServiceAssignmentDetails: CommanderServiceAssignmentDetail[];
  //public commanderServiceAssignmentDetailForm: FormGroup;
  selectedCommanderServiceAssignmentDetail: any;
  private message: { type: string; value: string };
  journeyIncidentTypes = [
    { id: 1, value: "Fire" },
    { id: 2, value: "Robbery" },
    { id: 3, value: "Accident" },
  ];
  journeyIncidentType: any;
  selectedJourneyIncidentType: any;
  profileId: any;
  valSlider: number = 2;

  //ArmadaJourneyIncidentPic
  public journeyIncidentPics: JourneyIncidentPic[];
  public journeyIncidentPicForm: FormGroup;
  selectedJourneyIncidentPic: any;
  editingJourneyIncidentPic = false;
  private edJourneyIncidentPic: JourneyIncidentPic;
  private downloadUrl: any;
  imageSrc: string;
  uploadedItemImage: any[] = [];
  private documentFIle: any;

  //ArmadaJourneyLeadCommander
  public journeyLeadCommanders: JourneyLeadCommander[];
  journeyLeadId: number;
  public journeyLeadCommanderForm: FormGroup;
  selectedJourneyLeadCommander: any;
  editingJourneyLeadCommander = false;
  private edJourneyLeadCommander: JourneyLeadCommander;

  //ArmadaJourneyNote
  public journeyNotes: JourneyNote[];
  public journeyNoteForm: FormGroup;
  selectedJourneyNote: any;
  editingJourneyNote = false;
  private edJourneyNote: JourneyNote;

  isStartButtonEnabled: boolean;
  isEndButtonEnabled: boolean;
  isViewClicked: boolean;
  isRelinquishButton: boolean;
  showStartSection: boolean;

  map: any;
  public clock;
  name = "Angular 6";
  timeLeft: number = 0;
  minute: number = 0;
  hour: number;
  interval;

  //dialogues
  journeyStopDialog: boolean;
  journeyincidentDialog: boolean;
  journeyincidentPicDialog: boolean;
  journeyNoteDialog: boolean;
  serviceAssignmentId: number;

  constructor(
    private breadcrumbService: BreadcrumbService,
    public messageService: MessageService,
    public confirmationService: ConfirmationService,
    private formBuilder: FormBuilder,
    public journeyStartAndStopService: JourneyStartAndStopService,
    private serviceAssignmentService: MasterServiceAssignmentService,
    private serviceAssignmentDetailService: ServiceAssignmentDetailService,
    private fileStorageService: FileStorageService
  ) {
    this.breadcrumbService.setItems([
      { label: "Setup" },
      { label: "journey start and stop", routerLink: ["/setup/journey-start"] },
    ]);
  }

  ngOnInit(): void {
    this.journeyStartId = Number(localStorage.getItem("journeyStartID"));
    this.journeyLeadId = Number(localStorage.getItem("journeyLeadId"));
    this.journeyIncidentId = Number(localStorage.getItem("journeyIncidentId"));
    this.serviceAssignmentId = Number(
      localStorage.getItem("serviceAssignmentId")
    );
    //JStart
    this.targetCols = [
      //{ field: "sourceTypeId", header: "sourceTypeId" },
      { field: "serviceAssignmentId", header: "serviceAssignmentId" },
      {
        field: "journeyStartActualAddress",
        header: "journeyStartActualAddress",
      },
      {
        field: "journeyStartActualLatitude",
        header: "journeyStartActualLatitude",
      },
      {
        field: "journeyStartActualLongitude",
        header: "journeyStartActualLongitude",
      },
    ];
    this.exportTargetColumns = this.targetCols.map((col) => ({
      title: col.header,
      dataKey: col.field,
    }));
    this.armadaJourneyStartForm = this.formBuilder.group({
      serviceAssignmentId: [null, [Validators.required]],

      //journeyStartActualAddress: ["", [Validators.required]],

      //journeyStartActualLatitude: [null, [Validators.required]],
      //journeyStartActualLongitude: [null, [Validators.required]],
      //sMORegionId: [null, [Validators.required]],
    });

    //JEnd
    this.targetCols = [
      //{ field: "sourceTypeId", header: "sourceTypeId" },
      //{ field: "serviceAssignmentId", header: "serviceAssignmentId" },
      { field: "totalTimeSpentOnJourney", header: "totalTimeSpentOnJourney" },
      {
        field: "journeyEndActualAddress",
        header: "journeyEndActualAddress",
      },
      {
        field: "journeyEndActualLatitude",
        header: "journeyEndActualLatitude",
      },
      {
        field: "journeyEndActualLongitude",
        header: "journeyEndActualLongitude",
      },
    ];
    this.exportTargetColumns = this.targetCols.map((col) => ({
      title: col.header,
      dataKey: col.field,
    }));
    this.armadaJourneyEndForm = this.formBuilder.group({
      serviceAssignmentId: [null, [Validators.required]],
      //totalTimeSpentOnJourney: [null, [Validators.required]],
      //journeyEndActualAddress: ["", [Validators.required]],
      //journeyEndActualLatitude: [null, [Validators.required]],
      //journeyEndActualLongitude: [null, [Validators.required]],
    });

    //JStop
    this.targetCols = [
      //{ field: "sourceTypeId", header: "sourceTypeId" },
      //{ field: "serviceAssignmentId", header: "serviceAssignmentId" },
      //{ field: "journeyStartId", header: "journeyStartId" },
      {
        field: "caption",
        header: "caption",
      },
      {
        field: "description",
        header: "description",
      },
      // {
      //   field: "journeyStopActualAddress",
      //   header: "journeyStopActualAddress",
      // },
      // {
      //   field: "journeyStopActualLatitude",
      //   header: "journeyStopActualLatitude",
      // },
      // {
      //   field: "journeyStopActualLongitude",
      //   header: "journeyStopActualLongitude",
      // },
      // {
      //   field: "isJourneyStopPlanned",
      //   header: "isJourneyStopPlanned",
      // },
    ];
    this.exportTargetColumns = this.targetCols.map((col) => ({
      title: col.header,
      dataKey: col.field,
    }));
    this.armadaJourneyStopForm = this.formBuilder.group({
      //journeyStartId: [null, [Validators.required]],
      journeyStopCaption: ["", [Validators.required, Validators.min(3)]],
      journeyStopDescription: ["", [Validators.required, Validators.min(3)]],
      //journeyStopActualAddress: ["", [Validators.required]],
      //journeyStopActualLatitude: [null, [Validators.required]],
      //journeyStopActualLongitude: [null, [Validators.required]],
      isJourneyStopPlanned: [false, [Validators.required]],
    });

    //JIncident
    this.targetCols = [
      //{ field: "sourceTypeId", header: "sourceTypeId" },
      //{ field: "serviceAssignmentId", header: "serviceAssignmentId" },
      //{ field: "journeyStartId", header: "journeyStartId" },
      {
        field: "caption",
        header: "caption",
      },
      {
        field: "description",
        header: "description",
      },
      // {
      //   field: "journeyIncidentActualAddress",
      //   header: "journeyIncidentActualAddress",
      // },
      // {
      //   field: "journeyIncidentActualLatitude",
      //   header: "journeyIncidentActualLatitude",
      // },
      // {
      //   field: "journeyIncidentActualLongitude",
      //   header: "journeyIncidentActualLongitude",
      // },
      // {
      //   field: "journeyIncidentType",
      //   header: "journeyIncidentType",
      // },
    ];
    this.exportTargetColumns = this.targetCols.map((col) => ({
      title: col.header,
      dataKey: col.field,
    }));
    this.journeyIncidentForm = this.formBuilder.group({
      //journeyStartId: [null, [Validators.required]],
      journeyIncidentCaption: ["", [Validators.required, Validators.min(3)]],
      journeyIncidentDescription: [
        "",
        [Validators.required, Validators.min(3)],
      ],
      //journeyIncidentActualAddress: ["", [Validators.required]],
      //journeyIncidentActualLatitude: [null, [Validators.required]],
      //journeyIncidentActualLongitude: [null, [Validators.required]],
      journeyIncidentTypeId: [null, [Validators.required]],
      journeyIncidentSeverityRating: [
        null,
        [Validators.required, Validators.max(5)],
      ],
    });

    //JincidentPic
    this.targetCols = [
      //{ field: "sourceTypeId", header: "sourceTypeId" },
      //{ field: "serviceAssignmentId", header: "serviceAssignmentId" },
      { field: "journeyIncidentId", header: "journeyIncidentId" },
      {
        field: "journeyPicture",
        header: "journeyPicture",
      },
    ];
    this.exportTargetColumns = this.targetCols.map((col) => ({
      title: col.header,
      dataKey: col.field,
    }));
    this.journeyIncidentPicForm = this.formBuilder.group({
      journeyIncidentId: [this.journeyIncidentId, [Validators.required]],
      journeyPicture: ["", [Validators.required]],
    });

    //JLeadCommander
    this.targetCols = [
      //{ field: "sourceTypeId", header: "sourceTypeId" },
      //{ field: "serviceAssignmentId", header: "serviceAssignmentId" },
      { field: "journeyStartId", header: "journeyStartId" },
      {
        field: "leadCommanderId",
        header: "leadCommanderId",
      },
    ];
    this.exportTargetColumns = this.targetCols.map((col) => ({
      title: col.header,
      dataKey: col.field,
    }));
    this.journeyLeadCommanderForm = this.formBuilder.group({
      //journeyStartId: [null, [Validators.required]],
      leadCommanderId: [null, [Validators.required]],
    });

    //JNote
    this.targetCols = [
      //{ field: "sourceTypeId", header: "sourceTypeId" },
      //{ field: "serviceAssignmentId", header: "serviceAssignmentId" },
      //{ field: "journeyStartId", header: "journeyStartId" },
      {
        field: "caption",
        header: "caption",
      },
      {
        field: "description",
        header: "description",
      },
    ];
    this.exportTargetColumns = this.targetCols.map((col) => ({
      title: col.header,
      dataKey: col.field,
    }));
    this.journeyNoteForm = this.formBuilder.group({
      //journeyStartId: [null, [Validators.required]],
      journeyNoteCaption: ["", [Validators.required, Validators.min(3)]],
      journeyNoteDescription: ["", [Validators.required, Validators.min(3)]],
    });

    this.getProfileId();
    console.log("ProfileId: " + this.profileId);
    this.GetCurrentLocation();

    //this.getLocation();
    //this.getReverseGeocodingData(this.actualLatitude, this.actualLongitude);
    this.fetchAllAssignedCommandersServiceAssignmentsByprofileId(
      this.profileId
    );
    //this.fetchAllJourneyIncidentsByStartId();
    this.fetchJourneyStartById();
    this.fetchAllJourneyIncidentPicsByIncidentId();
    this.fetchAllJourneyNotesByStartId();
    this.fetchAllJourneyStopsByStartId();
    localStorage.mobile = "mobile";
    var today = new Date();
    console.log("Date" + today);
    //this.getLocation();
    //this.time();
    //this.startTimer();
    //this.edit
    this.submitButtonLabel = "create";
  } //oninit

  // time() {
  //   let date = new Date();
  //   let second: number | string = date.getSeconds();
  //   let minute: number | string = date.getMinutes();
  //   let hour: number | string = date.getHours();
  //   if (second < 10) {
  //     second = "0" + second;
  //   }
  //   if (minute < 0) {
  //     minute = "0" + minute;
  //   }
  //   this.clock = hour + ":" + minute + ":" + second;
  // }

  startTimer() {
    this.interval = setInterval(() => {
      if (this.timeLeft > 0) {
        this.timeLeft++;
      } else {
        this.timeLeft = 1;
      }
    }, 100);
  }

  pauseTimer() {
    clearInterval(this.interval);
  }

  getProfileId() {
    let result = JSON.parse(sessionStorage.getItem("userProfile"));
    sessionStorage.setItem("id", result.id);
    this.profileId = sessionStorage.getItem("id");
  }
  //Assignment
  fetchAllActiveServiceAssignments() {
    this.serviceAssignmentService
      .allServiceAssignments()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            var res = r.responseData;
            this.masterServiceAssignments = res;
            this.availableReadyAssignments = [];
            this.masterServiceAssignments.forEach((Rank) => {
              if (
                Rank.readyStatus === true &&
                Rank.assignmentStatus === "Open"
              ) {
                this.availableReadyAssignments.push(Rank);
                this.loading = false;
                //console.log(Rank);
              }
            });
          }
        },
        (error) => {
          this.loading = false;
          this.connectionError();
        }
      );
    this.submittingData = false;
  }
  fetchAllAssignedCommandersServiceAssignmentsByprofileId(id: any) {
    this.serviceAssignmentDetailService
      .allCommanderDetailsByProfileId(id)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            var res = r.responseData;
            this.commanderServiceAssignmentDetails = res;
            console.log(res);
          }
        },
        (error) => {
          this.loading = false;
          this.connectionError();
        }
      );
    this.submittingData = false;
  }
  getSelectedAssignment(serviceAssignments: MasterServiceAssignment): void {
    this.formWrapper.nativeElement.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "start",
    });
    this.selectedmasterServiceAssignment = serviceAssignments;

    //this.submitButtonLabel = "Update";
  }
  getSelectedCommanderAssignmentDetail(
    serviceAssignmentDetails: CommanderServiceAssignmentDetail
  ): void {
    this.formWrapper.nativeElement.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "start",
    });

    this.selectedCommanderServiceAssignmentDetail = serviceAssignmentDetails;
    this.journeyLeadCommanderForm.patchValue({
      leadCommanderId: serviceAssignmentDetails.commanderResourceId,
    });
    this.armadaJourneyStartForm.patchValue({
      serviceAssignmentId: serviceAssignmentDetails.serviceAssignmentId,
    });
    this.armadaJourneyEndForm.patchValue({
      serviceAssignmentId: serviceAssignmentDetails.serviceAssignmentId,
    });

    this.isViewClicked = true;
    //this.submitButtonLabel = "Update";
  }

  //Start
  fetchAllJourneyStarts(): void {
    this.journeyStartAndStopService
      .allJourneyStarts()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            var res = r.responseData;
            this.armadaJourneyStarts = res;
            this.loading = false;
          }
        },
        (error) => {
          this.loading = false;
          this.connectionError();
        }
      );
    this.submittingData = false;
  }

  fetchJourneyStartById() {
    this.journeyStartAndStopService
      .getJourneyStart(this.journeyStartId)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            var res = r.responseData;
            this.armadaJourneyStart = res;
            this.loading = false;
          }
        },
        (error) => {
          this.loading = false;
          //this.connectionError();
        }
      );
    this.submittingData = false;
  }

  createJourneyStart(): void {
    this.confirmationService.confirm({
      message: "Are you sure you want to start journey",
      accept: () => {
        this._createJourneyStart();
      },
    });
    //this.confirmCurrentLocationInfos();
  }
  _createJourneyStart() {
    this.submittingData = true;
    this.loading = true;
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Starting Journey",
    });
    const formData = this.armadaJourneyStartForm.value;
    const postData = {
      serviceAssignmentId: formData.serviceAssignmentId,
      journeyStartActualAddress: this.actualAddress,
      journeyStartActualLatitude: this.actualLatitude,
      journeyStartActualLongitude: this.actualLongitude,
    };
    this.journeyStartAndStopService
      .postJourneyStart(postData)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            this.messageService.add({
              summary: "Success",
              severity: "success",
              detail: "Journey Started",
            });
            //this.fetchAllArmedEscortServiceAssignmentDetails();
            var getId = r.responseData.id;
            this.journeyStartId = getId;
            var getAssignId = r.responseData.serviceAssignmentId;
            //localStorage.removeItem("journeyStartID");
            localStorage.setItem("journeyStartID", getId);
            this.journeyStartId = Number(
              localStorage.getItem("journeyStartID")
            );
            localStorage.setItem("serviceAssignmentId", getAssignId);
            this.serviceAssignmentId = Number(
              localStorage.getItem("serviceAssignmentId")
            );
            //this.fetchJourneyStartById();
            this.submittingData = false;
            this.loading = false;

            this.armadaJourneyStartForm.reset();
            this.fetchJourneyStartById();
            window.location.reload();
            this.isStartButtonEnabled = false;
            this.isEndButtonEnabled = true;
          } else {
            this.messageService.add({
              severity: "error",
              summary: "Failed",
              detail: " Error: " + r.responseMsg,
            });
            //this.submittingData = false;
            //this.loading = false;
          }
        },
        (error) => {
          this.messageService.add({
            severity: "error",
            summary: "Failed",
            detail: "Connection Error " + error.error.message,
          });
          this.submittingData = false;
          this.loading = false;
        }
      );
    //this.fetchJourneyStartById();
    this.submittingData = false;
  }

  deleteJourneyStart(journey: ArmadaJourneyStart): void {
    this.confirmationService.confirm({
      message: "Are you sure you want to delete journey",
      accept: () => {
        this._deleteJourneyStart(journey);
      },
    });
  }
  _deleteJourneyStart(journey) {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Deleting journey record",
    });
    this.journeyStartAndStopService.deleteJourneyStart(journey.id).subscribe(
      async (result: any) => {
        await this.messageService.add({
          severity: "success",
          summary: "Deleted",
          detail: "jouney record removed",
        });
        //await this.fetchAllArmedEscortServiceAssignmentDetails();
      },
      (error) => {
        this.connectionError();
      }
    );
  }

  editJourneyStart(journey: ArmadaJourneyStart): void {
    this.formWrapper.nativeElement.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "start",
    });
    this.edArmadaJourneyStart = journey;
    this.editingArmadaJourneyStart = true;
    // this.imageSrc = pilotProfiles.imageUrl;
    this.armadaJourneyStartForm.setValue({
      serviceAssignmentId: journey.serviceAssignmentId,
      journeyStartActualAddress: journey.journeyStartActualAddress,
      journeyStartActualLatitude: journey.journeyStartActualLatitude,
      journeyStartActualLongitude: journey.journeyStartActualLongitude,
    });

    this.submitButtonLabel = "Update";
  }

  updateJourneyEnd(): void {
    this.confirmationService.confirm({
      message: "Are you sure you want to end journey",
      accept: () => {
        this._updateEndJourneyStart();
      },
    });
  }
  _updateEndJourneyStart(): void {
    this.submittingData = true;
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Ending Journey",
    });
    // if (!this.edArmadaJourneyStart) {
    //   return false;
    // }
    const formData = this.armadaJourneyEndForm.value;
    const data = {
      serviceAssignmentId: this.serviceAssignmentId,
      journeyEndActualAddress: this.actualAddress,
      //totalTimeSpentOnJourney: formData.totalTimeSpentOnJourney,
      journeyEndActualLatitude: this.actualLatitude,
      journeyEndActualLongitude: this.actualLongitude,
    };
    this.journeyStartAndStopService
      .updateEndJourneyStart(this.journeyStartId, data)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (res: any) => {
          if (res.responseCode == "00") {
            this.messageService.add({
              severity: "success",
              detail: "Journey Ended",
              summary: "Completed",
            });
            this.submittingData = false;
            //this.fetchAllArmedEscortServiceAssignmentDetails();
            window.location.reload();
            this.armadaJourneyEndForm.reset();
          } else {
            this.connectionError();
          }
        },
        (error) => {
          this.connectionError();
          this.submittingData = false;
        }
      );
  }

  //Stop
  fetchAllJourneyStops(): void {
    this.journeyStartAndStopService
      .allJourneyStops()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            var res = r.responseData;
            this.armadaJourneyStops = res;
            this.loading = false;
          }
        },
        (error) => {
          this.loading = false;
          this.connectionError();
        }
      );
    this.submittingData = false;
  }

  fetchAllJourneyStopsByStartId(): void {
    this.journeyStartAndStopService
      .allJourneyStopsByStartId(this.journeyStartId)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            var res = r.responseData;
            this.armadaJourneyStops = res;
            this.loading = false;
          }
        },
        (error) => {
          this.loading = false;
          this.connectionError();
        }
      );
    this.submittingData = false;
  }
  createJourneyStop() {
    this.submittingData = true;
    this.loading = true;
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Stopping Journey",
    });
    const formData = this.armadaJourneyStopForm.value;
    const postData = {
      //journeyStartId: formData.journeyStartId,
      journeyStartId: this.journeyStartId,
      journeyStopCaption: formData.journeyStopCaption,
      journeyStopDescription: formData.journeyStopDescription,
      journeyStopActualAddress: this.actualAddress,
      journeyStopActualLatitude: this.actualLatitude,
      journeyStopActualLongitude: this.actualLongitude,
      isJourneyStopPlanned: formData.isJourneyStopPlanned,
    };
    this.journeyStartAndStopService
      .postJourneyStop(postData)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            this.messageService.add({
              summary: "Success",
              severity: "success",
              detail: "Journey Stopped",
            });
            this.fetchAllJourneyStopsByStartId();
            this.submittingData = false;
            this.loading = false;
            this.cancel();
            // this.armadaJourneyStopForm.controls[
            //   "journeyStopDescription"
            // ].reset();
            // this.armadaJourneyStopForm.controls["journeyStopCaption"].reset();
            //this.armadaJourneyStopForm.reset();
            //window.location.reload();
            //this.isStartButtonEnabled = false;
          } else {
            this.messageService.add({
              severity: "error",
              summary: "Failed",
              detail: " Error: " + r.responseMsg,
            });
            this.submittingData = false;
            this.loading = false;
          }
        },
        (error) => {
          this.messageService.add({
            severity: "error",
            summary: "Failed",
            detail: "Connection Error " + error.error.message,
          });
          this.submittingData = false;
          this.loading = false;
        }
      );
    this.submittingData = false;
  }

  deleteJourneyStop(journey: ArmadaJourneyStop): void {
    this.confirmationService.confirm({
      message: "Are you sure you want to delete journey",
      accept: () => {
        this._deleteJourneyStop(journey);
      },
    });
  }
  _deleteJourneyStop(journey) {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Deleting journey record",
    });
    this.journeyStartAndStopService.deleteJourneyStop(journey.id).subscribe(
      async (result: any) => {
        await this.messageService.add({
          severity: "success",
          summary: "Deleted",
          detail: "jouney record removed",
        });
        await this.fetchAllJourneyStops();
      },
      (error) => {
        this.connectionError();
      }
    );
  }

  editJourneyStop(journey: ArmadaJourneyStop): void {
    this.formWrapper.nativeElement.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "start",
    });
    this.edArmadaJourneyStop = journey;
    this.editingArmadaJourneyStop = true;
    // this.imageSrc = pilotProfiles.imageUrl;
    this.armadaJourneyStopForm.setValue({
      journeyStopDescription: journey.journeyStopDescription,
      journeyStopCaption: journey.journeyStopCaption,
    });

    this.submitButtonLabel = "Update";
  }

  updateJourneyStop(): boolean {
    this.submittingData = true;
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Updating Stopped Journey",
    });
    if (!this.edArmadaJourneyStop) {
      return false;
    }
    const formData = this.armadaJourneyStopForm.value;
    const data = {
      journeyStopDescription: formData.journeyStopDescription,
      journeyStopCaption: formData.journeyStopCaption,
    };
    this.journeyStartAndStopService
      .updateJourneyStop(this.edArmadaJourneyStop.id, data)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (res: CommonResponse) => {
          if (res.responseCode == "00") {
            this.messageService.add({
              severity: "success",
              detail: "Journey Updated Successfully",
              summary: "Completed",
            });
            this.submittingData = false;
            //this.fetchAllArmedEscortServiceAssignmentDetails();
            this.armadaJourneyStopForm.reset();
          } else {
            this.connectionError();
          }
        },
        (error) => {
          this.connectionError();
          this.submittingData = false;
        }
      );
  }

  //Incident
  fetchAllJourneyIncidents(): void {
    this.journeyStartAndStopService
      .allJourneyIncidents()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            var res = r.responseData;
            this.journeyIncidents = res;
            this.loading = false;
          }
        },
        (error) => {
          this.loading = false;
          this.connectionError();
        }
      );
    this.submittingData = false;
  }

  fetchAllJourneyIncidentsByStartId(): void {
    this.journeyStartAndStopService
      .allJourneyIncidentsByStartId(this.journeyStartId)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            var res = r.responseData;
            this.journeyIncidents = res;
            this.loading = false;
          }
        },
        (error) => {
          this.loading = false;
          this.connectionError();
        }
      );
    this.submittingData = false;
  }
  createJourneyIncident() {
    this.submittingData = true;
    this.loading = true;
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Saving Journey Incident",
    });
    const formData = this.journeyIncidentForm.value;
    const postData = {
      //journeyStartId: formData.journeyStartId,
      journeyStartId: this.journeyStartId,
      journeyIncidentCaption: formData.journeyIncidentCaption,
      journeyIncidentDescription: formData.journeyIncidentDescription,
      journeyIncidentActualAddress: this.actualAddress,
      journeyIncidentActualLatitude: this.actualLatitude,
      journeyIncidentActualLongitude: this.actualLongitude,
      journeyIncidentTypeId: this.selectedJourneyIncidentType.id,
      journeyIncidentSeverityRating: formData.journeyIncidentSeverityRating,
    };
    this.journeyStartAndStopService
      .postJourneyIncident(postData)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            this.messageService.add({
              summary: "Success",
              severity: "success",
              detail: "Journey Incident Added",
            });
            var getId = r.responseData.id;
            localStorage.setItem("journeyIncidentId", getId);
            this.journeyIncidentId = Number(
              localStorage.getItem("journeyIncidentId")
            );
            this.fetchAllJourneyIncidentsByStartId();
            this.cancel();
            this.submittingData = false;
            this.loading = false;
            //window.location.reload();
            //this.journeyIncidentForm.reset();
            //this.isStartButtonEnabled = false;
          } else {
            this.messageService.add({
              severity: "error",
              summary: "Failed",
              detail: " Error: " + r.responseMsg,
            });
            this.submittingData = false;
            this.loading = false;
          }
        },
        (error) => {
          this.messageService.add({
            severity: "error",
            summary: "Failed",
            detail: "Connection Error " + error.error.message,
          });
          this.submittingData = false;
          this.loading = false;
        }
      );
    this.submittingData = false;
  }

  deleteJourneyIncident(journey: JourneyIncident): void {
    this.confirmationService.confirm({
      message: "Are you sure you want to delete journey incident",
      accept: () => {
        this._deleteJourneyIncident(journey);
      },
    });
  }
  _deleteJourneyIncident(journey) {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Deleting journey incident record",
    });
    this.journeyStartAndStopService.deleteJourneyIncident(journey.id).subscribe(
      async (result: any) => {
        await this.messageService.add({
          severity: "success",
          summary: "Deleted",
          detail: "jouney record removed",
        });
        await this.fetchAllJourneyIncidents();
      },
      (error) => {
        this.connectionError();
      }
    );
  }

  editJourneyIncident(journeys: JourneyIncident): void {
    this.formWrapper.nativeElement.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "start",
    });
    this.edJourneyIncident = journeys;
    this.editingJourneyIncident = true;
    // this.imageSrc = pilotProfiles.imageUrl;
    this.journeyIncidentForm.setValue({
      journeyIncidentDescription: journeys.journeyIncidentDescription,
      journeyIncidentCaption: journeys.journeyIncidentCaption,
      journeyIncidentType: journeys.journeyIncidentType,
    });
    this.selectedJourneyIncidentType = this.journeyIncidentTypes.find(
      (Type) => Type.id === journeys.journeyIncidentType
    );

    this.submitButtonLabel = "Update";
  }

  updateJourneyIncident(): boolean {
    this.submittingData = true;
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Updating Journey incident",
    });
    if (!this.edJourneyIncident) {
      return false;
    }
    const formData = this.journeyIncidentForm.value;
    const data = {
      journeyIncidentDescription: formData.journeyIncidentDescription,
      journeyIncidentCaption: formData.journeyIncidentCaption,
      journeyIncidentTypeId: formData.journeyIncidentType.id,
    };
    this.journeyStartAndStopService
      .updateJourneyIncident(this.edJourneyIncident.id, data)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (res: CommonResponse) => {
          if (res.responseCode == "00") {
            this.messageService.add({
              severity: "success",
              detail: "Journey Updated Successfully",
              summary: "Completed",
            });
            this.submittingData = false;
            //this.fetchAllArmedEscortServiceAssignmentDetails();
            this.journeyIncidentForm.reset();
          } else {
            this.connectionError();
          }
        },
        (error) => {
          this.connectionError();
          this.submittingData = false;
        }
      );
  }

  //IncidentPics
  fetchAllJourneyIncidentPics(): void {
    this.journeyStartAndStopService
      .allJourneyIncidentPics()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            var res = r.responseData;
            this.journeyIncidentPics = res;
            this.loading = false;
          }
        },
        (error) => {
          this.loading = false;
          this.connectionError();
        }
      );
    this.submittingData = false;
  }

  fetchAllJourneyIncidentPicsByIncidentId(): void {
    this.journeyStartAndStopService
      .allJourneyIncidentPicsByIncidentId(this.journeyIncidentId)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            var res = r.responseData;
            this.journeyIncidentPics = res;
            this.loading = false;
          }
        },
        (error) => {
          this.loading = false;
          this.connectionError();
        }
      );
    this.submittingData = false;
  }
  saveJourneyPic() {
    this.submittingData = true;
    if (this.documentFIle) {
      this.fileStorageService.UploadMultipleFilesFromDataUrl([
        this.documentFIle,
      ]);
      this.fileStorageService.onUploadFinished.subscribe(
        (resp: CommonResponse<string[]>) => {
          if (resp.responseCode != "00") {
            this.messageService.add({
              severity: "error",
              summary: "Notice",
              detail: resp.responseMsg,
            });
          } else {
            this.downloadUrl = resp.responseData[0];
            if (!this.editingJourneyIncidentPic) {
              this.createJourneyIncidentPic();
            }
          }
        },
        (error) => {
          console.log("Error while uploading files " + error);
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail: "ERR: Unable to upload image to storage",
          });
        }
      );
    } else {
      this.downloadUrl = this.edJourneyIncidentPic.journeyPicture ?? "";
      if (!this.editingJourneyIncidentPic) {
        this.createJourneyIncidentPic();
      }
    }
  }
  createJourneyIncidentPic() {
    this.submittingData = true;
    this.loading = true;
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Saving Journey Incident  pic",
    });
    const formData = this.journeyIncidentPicForm.value;
    const postData = {
      journeyIncidentId: formData.journeyIncidentId,
      journeyPicture: this.downloadUrl,
    };
    this.journeyStartAndStopService
      .postJourneyIncidentPic(postData)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            this.messageService.add({
              summary: "Success",
              severity: "success",
              detail: "Journey Incident Pic Added",
            });
            // let incidentId =
            //   this.journeyIncidentPicForm.get("journeyIncidentId").value;
            this.fetchAllJourneyIncidentPicsByIncidentId();
            this.submittingData = false;
            this.loading = false;
            //this.journeyIncidentPicForm.reset();
            this.cancel();
            this.imageSrc = "./assets/caution.png";
            //window.location.reload();
            //this.isStartButtonEnabled = false;
          } else {
            this.messageService.add({
              severity: "error",
              summary: "Failed",
              detail: " Error: " + r.responseMsg,
            });
            this.submittingData = false;
            this.loading = false;
          }
        },
        (error) => {
          this.messageService.add({
            severity: "error",
            summary: "Failed",
            detail: "Connection Error " + error.error.message,
          });
          this.submittingData = false;
          this.loading = false;
        }
      );
    this.submittingData = false;
  }

  deleteJourneyIncidentPic(journey: JourneyIncidentPic): void {
    this.confirmationService.confirm({
      message: "Are you sure you want to delete journey incident Pic",
      accept: () => {
        this._deleteJourneyIncidentPic(journey);
      },
    });
  }
  onFileSelect(event) {
    if (!this.checkMimeType(event) || !this.checkFileSize(event)) {
      this.journeyIncidentPicForm.get("journeyPicture").reset();
      return;
    }
    const reader = new FileReader();
    this.message = null;
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      this.documentFIle = event.target.files[0];
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.imageSrc = reader.result as string;
      };
    }
  }
  checkMimeType(event) {
    const files = event.target.files[0];
    // list allow mime type
    const types = ["image/jpeg", "image/png", "image/jpg"];
    // loop access array
    if (!files) {
      this.messageService.add({
        severity: "error",
        summary: "Failed",
        detail: "Please select a file to continue",
      });
      this.message = {
        type: "danger",
        value: "Please select a file to continue",
      };
      return false;
    }
    // compare file type find doesn't match
    if (types.every((type) => files.type !== type)) {
      // create error message and assign to container
      this.messageService.add({
        severity: "error",
        summary: "Failed",
        detail: "Image format not supported",
      });
      this.message = { type: "danger", value: "Image format not supported" };
      return false;
    }
    return true;
  }

  checkFileSize(event: EventInput) {
    const files = event.target.files[0];
    if (!files) return true;
    const size = 1500000;
    if (files.size > size) {
      event.target.value = null;
      this.messageService.add({
        severity: "error",
        summary: "Failed",
        detail: "Image is too large, please pick a smaller file",
      });
      this.message = {
        type: "danger",
        value: "Image is too large, please pick a smaller file",
      };
      return false;
    }
    return true;
  }
  // editJourneyIncident(journeys: JourneyIncident): void {
  //   this.formWrapper.nativeElement.scrollIntoView({
  //     behavior: "smooth",
  //     block: "end",
  //     inline: "start",
  //   });
  //   this.edJourneyIncident = journeys;
  //   this.editingJourneyIncident = true;
  //   // this.imageSrc = pilotProfiles.imageUrl;
  //   this.journeyIncidentForm.setValue({
  //     journeyIncidentDescription: journeys.journeyIncidentDescription,
  //     journeyIncidentCaption: journeys.journeyIncidentCaption,
  //     journeyIncidentTypeId: journeys.journeyIncidentType,
  //   });
  //   this.selectedJourneyIncidentType = this.journeyIncidentType.find(
  //     (Type) => Type.id === journeys.journeyIncidentTypeId
  //   );

  //   this.submitButtonLabel = "Update";
  // }

  // updateJourneyIncident(): boolean {
  //   this.submittingData = true;
  //   this.messageService.add({
  //     severity: "info",
  //     summary: "Notice",
  //     detail: "Updating Journey incident",
  //   });
  //   if (!this.edJourneyIncident) {
  //     return false;
  //   }
  //   const formData = this.journeyIncidentForm.value;
  //   const data = {
  //     journeyIncidentDescription: formData.journeyIncidentDescription,
  //     journeyIncidentCaption: formData.journeyIncidentCaption,
  //     journeyIncidentTypeId: formData.journeyIncidentType.id,
  //   };
  //   this.journeyStartAndStopService
  //     .updateJourneyIncident(this.edJourneyIncident.id, data)
  //     .pipe(takeUntil(this.unsubscriber$))
  //     .subscribe(
  //       (res: CommonResponse) => {
  //         if (res.responseCode == "00") {
  //           this.messageService.add({
  //             severity: "success",
  //             detail: "Journey Updated Successfully",
  //             summary: "Completed",
  //           });
  //           this.submittingData = false;
  //           //this.fetchAllArmedEscortServiceAssignmentDetails();
  //           this.journeyIncidentForm.reset();
  //         } else {
  //           this.connectionError();
  //         }
  //       },
  //       (error) => {
  //         this.connectionError();
  //         this.submittingData = false;
  //       }
  //     );
  // }
  _deleteJourneyIncidentPic(journey) {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Deleting journey incident pic record",
    });
    this.journeyStartAndStopService
      .deleteJourneyIncidentPic(journey.id)
      .subscribe(
        async (result: any) => {
          await this.messageService.add({
            severity: "success",
            summary: "Deleted",
            detail: "jouney pic record removed",
          });
          await this.fetchAllJourneyIncidentPicsByIncidentId();
        },
        (error) => {
          this.connectionError();
        }
      );
  }

  //LeadCommander
  fetchAllJourneyLeadCommanders(): void {
    this.journeyStartAndStopService
      .allJourneyLeadCommanders()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            var res = r.responseData;
            this.journeyLeadCommanders = res;
            this.loading = false;
          }
        },
        (error) => {
          this.loading = false;
          this.connectionError();
        }
      );
    this.submittingData = false;
  }

  createJourneyLeadCommander() {
    this.submittingData = true;
    this.loading = true;
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Setting Journey Lead",
    });
    const formData = this.journeyLeadCommanderForm.value;
    const postData = {
      //journeyStartId: this.journeyStartId,
      leadCommanderId: formData.leadCommanderId,
      // this.journeyLeadCommanderForm.get("leadCommanderId").value,
    };
    this.journeyStartAndStopService
      .postJourneyLeadCommander(postData)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            this.messageService.add({
              summary: "Success",
              severity: "success",
              detail: "Journey Lead Set",
            });
            var getId = r.responseData.id;
            localStorage.setItem("journeyLeadId", getId);
            this.journeyLeadId = Number(localStorage.getItem("journeyLeadId"));
            this.submittingData = false;
            this.loading = false;
            this.isRelinquishButton = true;
            this.showStartSection = true;
            //this.journeyIncidentForm.reset();
            //this.isStartButtonEnabled = false;
          } else {
            this.messageService.add({
              severity: "error",
              summary: "Failed",
              detail: " Error: " + r.responseMsg,
            });
            this.submittingData = false;
            this.loading = false;
          }
        },
        (error) => {
          this.messageService.add({
            severity: "error",
            summary: "Failed",
            detail: "Connection Error " + error.error.message,
          });
          this.submittingData = false;
          this.loading = false;
        }
      );
    this.submittingData = false;
  }

  get LeadCommanderIdValue() {
    return this.journeyLeadCommanderForm.get("leadCommanderId").value;
  }

  RelinquishJourneyLead(): void {
    this.confirmationService.confirm({
      message: "Are you sure you want to relinquish journey lead",
      accept: () => {
        this._RelinquishJourneyLead();
      },
    });
    console.log("journeyLead" + this.journeyLeadId);
    // this.journeyLeadCommanderForm.patchValue({
    //   //leadCommanderId: journey.id,
    // });
  }
  _RelinquishJourneyLead() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Relinquishing Lead",
    });
    this.journeyStartAndStopService
      .relinquishJourneyLeadCommander(this.journeyLeadId)
      .subscribe(
        async (result: any) => {
          await this.messageService.add({
            severity: "success",
            summary: "Reliqnquished",
            detail: "journey lead relinquised",
          });
          //await this.fetchAllJourneyIncidents();
        },
        (error) => {
          this.connectionError();
        }
      );
  }

  //Notes
  fetchAllJourneyNotess(): void {
    this.journeyStartAndStopService
      .allJourneyNotes()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            var res = r.responseData;
            this.journeyNotes = res;
            this.loading = false;
          }
        },
        (error) => {
          this.loading = false;
          this.connectionError();
        }
      );
    this.submittingData = false;
  }

  fetchAllJourneyNotesByStartId(): void {
    this.journeyStartAndStopService
      .allJourneyNotesByStartId(this.journeyStartId)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            var res = r.responseData;
            this.journeyNotes = res;
            this.loading = false;
          }
        },
        (error) => {
          this.loading = false;
          this.connectionError();
        }
      );
    this.submittingData = false;
  }
  createJourneyNote() {
    this.submittingData = true;
    this.loading = true;
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Saving Journey Note",
    });
    const formData = this.journeyNoteForm.value;
    const postData = {
      //journeyStartId: formData.journeyStartId,
      journeyStartId: this.journeyStartId,
      journeyNoteCaption: formData.journeyNoteCaption,
      journeyNoteDescription: formData.journeyNoteDescription,
    };
    this.journeyStartAndStopService
      .postJourneyNote(postData)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            this.messageService.add({
              summary: "Success",
              severity: "success",
              detail: "Journey Note Added",
            });

            this.fetchAllJourneyNotesByStartId();
            this.submittingData = false;
            this.loading = false;
            //this.journeyIncidentForm.reset();
            //window.location.reload();
            this.cancel();
            //this.isStartButtonEnabled = false;
          } else {
            this.messageService.add({
              severity: "error",
              summary: "Failed",
              detail: " Error: " + r.responseMsg,
            });
            this.submittingData = false;
            this.loading = false;
          }
        },
        (error) => {
          this.messageService.add({
            severity: "error",
            summary: "Failed",
            detail: "Connection Error " + error.error.message,
          });
          this.submittingData = false;
          this.loading = false;
        }
      );
    this.submittingData = false;
  }

  deleteJourneyNote(journey: JourneyNote): void {
    this.confirmationService.confirm({
      message: "Are you sure you want to delete journey note",
      accept: () => {
        this._deleteJourneyNote(journey);
      },
    });
  }
  _deleteJourneyNote(journey) {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Deleting journey note record",
    });
    this.journeyStartAndStopService.deleteJourneyNote(journey.id).subscribe(
      async (result: any) => {
        await this.messageService.add({
          severity: "success",
          summary: "Deleted",
          detail: "jouney record removed",
        });
        await this.fetchAllJourneyNotesByStartId();
      },
      (error) => {
        this.connectionError();
      }
    );
  }

  editJourneyNote(journeys: JourneyNote): void {
    this.formWrapper.nativeElement.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "start",
    });
    this.edJourneyNote = journeys;
    this.editingJourneyIncident = true;
    // this.imageSrc = pilotProfiles.imageUrl;
    this.journeyIncidentForm.setValue({
      journeyNoteCaption: journeys.journeyNoteCaption,
      journeyNoteDescription: journeys.journeyNoteDescription,
      //journeyIncidentTypeId: journeys.journeyIncidentType,
    });
    this.submitButtonLabel = "Update";
  }

  //GetItems
  getForJourneyStop() {
    // this.selectedmasterServiceAssignment = masterServiceAssignments;
    // this.armadaJourneyStopForm.patchValue({
    //   serviceAssignmentId: masterServiceAssignments.id,

    // });
    this.fetchAllJourneyStopsByStartId();
    this.journeyStopDialog = true;
  }
  getForJourneyIncident() {
    // this.selectedmasterServiceAssignment = masterServiceAssignments;
    // this.armadaJourneyStopForm.patchValue({
    //   serviceAssignmentId: masterServiceAssignments.id,

    // });
    this.journeyincidentDialog = true;
    this.fetchAllJourneyIncidentsByStartId();
  }

  getForJourneyIncidentPic(journey: JourneyIncident) {
    // this.selectedmasterServiceAssignment = masterServiceAssignments;
    this.journeyIncidentPicForm.patchValue({
      journeyIncidentId: journey.id,
    });
    this.fetchAllJourneyIncidentPicsByIncidentId();
    this.journeyincidentPicDialog = true;
  }

  getForJourneyNote() {
    // this.selectedmasterServiceAssignment = masterServiceAssignments;
    // this.armadaJourneyStopForm.patchValue({
    //   serviceAssignmentId: masterServiceAssignments.id,

    // });
    this.fetchAllJourneyNotesByStartId();
    this.journeyNoteDialog = true;
  }

  updateJourneyNote() {
    // this.submittingData = true;
    // this.messageService.add({
    //   severity: "info",
    //   summary: "Notice",
    //   detail: "Updating Journey note",
    // });
    // if (!this.edJourneyNote) {
    //   return false;
    // }
    // const formData = this.journeyNoteForm.value;
    // const data = {
    //   journeyNoteCaption: formData.journeyNoteCaption,
    //   journeyNoteDescription: formData.journeyNoteDescription,
    // };
    // this.journeyStartAndStopService
    //   .updateJourneyNote(this.edJourneyIncident.id, data)
    //   .pipe(takeUntil(this.unsubscriber$))
    //   .subscribe(
    //     (res: CommonResponse) => {
    //       if (res.responseCode == "00") {
    //         this.messageService.add({
    //           severity: "success",
    //           detail: "Journey Updated Successfully",
    //           summary: "Completed",
    //         });
    //         this.submittingData = false;
    //         //this.fetchAllArmedEscortServiceAssignmentDetails();
    //         this.journeyIncidentForm.reset();
    //       } else {
    //         this.connectionError();
    //       }
    //     },
    //     (error) => {
    //       this.connectionError();
    //       this.submittingData = false;
    //     }
    //   );
  }

  async GetCurrentLocation() {
    var uri =
      "https://www.googleapis.com/geolocation/v1/geolocate?key=AIzaSyCQuetprs2UHb_zKSXznyumyfvw95ERCDY";
    var xmlhttp = new XMLHttpRequest();
    xmlhttp.onreadystatechange = function () {
      if (xmlhttp.readyState == 4 && xmlhttp.status == 200) {
        let result = JSON.parse(xmlhttp.responseText);
        localStorage.setItem("longitude", result.location.lng);
        localStorage.setItem("latitude", result.location.lat);
        //localStorage.setItem("address", result.results[0].formatted_address);

        console.log(
          `current longitude ${result.location.lng} and current latitude ${result.location.lat}`
        );
        //document.getElementById("btnVerifyGeo").click();
      } else if (xmlhttp.status == 404) {
        alert(
          "Unable to get current location cordinates = " + xmlhttp.status + "."
        );
      }
    };
    xmlhttp.open("POST", uri, true);
    xmlhttp.send();
    xmlhttp.onerror = function () {
      alert(
        "Unable to get current location cordinates = " +
          xmlhttp.statusText +
          "."
      );
    };

    this.actualLongitude = Number(localStorage.getItem("longitude"));
    this.actualLatitude = Number(localStorage.getItem("latitude"));
    console.log(
      "Latituder: " + this.actualLatitude + "Longi: " + this.actualLongitude
    );
  }

  // async getLocation() {
  //   if (navigator.geolocation) {
  //     navigator.geolocation.getCurrentPosition(
  //       this.showPosition,
  //       this.showError
  //     );
  //   } else {
  //     console.log("Geolocation is not supported by this browser.");
  //   }
  // }

  // showPosition(position) {
  //   console.log(
  //     "New Latitude: " +
  //       position.coords.latitude +
  //       "<br>New Longitude: " +
  //       position.coords.longitude
  //   );
  // }

  // showError() {
  //   alert("Sorry, could not obtain location.");
  // }
  async getReverseGeocodingData(lat, lng) {
    var latlng = new google.maps.LatLng(lat, lng);
    // This is making the Geocode request
    var geocoder = new google.maps.Geocoder();
    geocoder.geocode({ location: latlng }, (results, status) => {
      if (status !== google.maps.GeocoderStatus.OK) {
        alert(status);
      }
      // This is checking to see if the Geoeode Status is OK before proceeding
      if (status == google.maps.GeocoderStatus.OK) {
        console.log(results);
        var address = results[0].formatted_address;
        console.log("Address of current location: " + address);
      }
      this.actualAddress = address;
    });
  }
  initMap(): void {
    if (this.actualLatitude && this.actualLongitude) {
      // The location of pickup
      const location = { lat: this.actualLatitude, lng: this.actualLongitude };

      // The map, centered at pickup Loc
      const map = new google.maps.Map(
        document.getElementById("map") as HTMLElement,
        {
          zoom: 2,
          center: location,
        }
      );

      // The marker, positioned at Loc
      const marker = new google.maps.Marker({
        position: location,
        map: map,
      });
    }
  }

  confirmCurrentLocationInfos() {
    this.initMap();
    this.getReverseGeocodingData(this.actualLatitude, this.actualLongitude);
  }

  ngAfterViewInit(): void {
    // Load google maps script after view init
    const DSLScript = document.createElement("script");
    DSLScript.src =
      "https://maps.googleapis.com/maps/api/js?key=AIzaSyCQuetprs2UHb_zKSXznyumyfvw95ERCDY"; // replace by your API key
    DSLScript.type = "text/javascript";
    document.body.appendChild(DSLScript);
    document.body.removeChild(DSLScript);

    //this.initMap();
  }

  hideDialog() {
    this.journeyNoteDialog = false;
    this.journeyStopDialog = false;
    this.journeyincidentDialog = false;
    this.journeyincidentPicDialog = false;

    this.cancel();
    //this.submitted = false;
  }

  // getLocation() {
  //   if (navigator.geolocation) {
  //     navigator.geolocation.getCurrentPosition(this.showPosition);
  //   } else {
  //     let res = "Geolocation is not supported by this browser.";
  //     console.log(res);
  //   }
  // }

  // showPosition(position) {
  //   var latandLong =
  //     "Latitude: " +
  //     position.coords.latitude +
  //     "<br>Longitude: " +
  //     position.coords.longitude;
  //   console.log(latandLong);
  // }

  cancel() {
    this.armadaJourneyStopForm.controls["journeyStopDescription"].reset();
    this.armadaJourneyStopForm.controls["journeyStopCaption"].reset();
    this.journeyNoteForm.controls["journeyNoteDescription"].reset();
    this.journeyNoteForm.controls["journeyNoteCaption"].reset();
    this.journeyIncidentForm.controls["journeyIncidentDescription"].reset();
    this.journeyIncidentForm.controls["journeyIncidentCaption"].reset();
    this.journeyIncidentForm.controls["journeyIncidentTypeId"].reset();
    this.journeyIncidentPicForm.controls["journeyPicture"].reset();
    // this.journeyNoteForm.reset();
    // this.journeyIncidentForm.reset();
    // this.journeyIncidentPicForm.reset();
    // this.armadaJourneyStopForm.reset();
  }

  private connectionError() {
    this.messageService.add({
      severity: "error",
      summary: "Failed",
      detail: "Connection Error, Please try again",
    });
  }
}
