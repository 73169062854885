import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { AngularFireStorage } from "@angular/fire/storage";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ConfirmationService, MessageService } from "primeng/api";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { BreadcrumbService } from "src/app/breadcrumb.service";
import {
  ArmedEscortDTSDetailGenericDay,
  ArmedEscortDTSMasters,
  ArmedEscortProfile,
  CommanderDTSDetailGenericDay,
  CommanderDTSMasters,
  CommanderProfile,
  PilotDTSDetailGenericDay,
  PilotDTSMasters,
  PilotProfile,
  RenewContract,
  VehicleDTSDetailGenericDay,
  VehicleDTSMasters,
  VehicleProfile,
} from "src/app/interfaces/armada";
import { CommonResponse } from "src/app/interfaces/home";
import { ResourceRegistrationService } from "src/app/services/armada/resource-registration.service";
import { ResourceSchedulingService } from "src/app/services/armada/resource-scheduling.service";
import { InventoryService } from "src/app/services/inventory.service";

@Component({
  selector: "app-resource-scheduling",
  templateUrl: "./resource-scheduling.component.html",
  styleUrls: ["./resource-scheduling.component.scss"],
})
export class ResourceSchedulingComponent implements OnInit, OnDestroy {
  @ViewChild("formWrapper") public formWrapper: ElementRef;
  private unsubscriber$ = new Subject<void>();

  public targetCols: any;
  //public escortTargetCols: any;
  public pilotTargetCols: any;
  public commanderTargetCols: any;
  public vehicleTargetCols: any;
  private exportTargetColumns: any;
  public submittingData: boolean;
  public loading: boolean;
  public submitButtonLabel: string;
  public submitVehicleButtonLabel: string;
  public submitCommanderButtonLabel: string;

  //Armed Escort Masters
  public armedEscortDTSMasters: ArmedEscortDTSMasters[]; //ArmedEscortDTSDetailGenericDay
  selectedArmedEscortDTSMasters: any;
  public armedEscortDTSMastersForm: FormGroup;
  public resourceRenewalForm: FormGroup;
  public armedEscortUseForm: FormGroup;
  valRadio:string;
  editingArmedEscortDTSMasters = false;
  private edArmedEscortDTSMasters: ArmedEscortDTSMasters;
  public armedEscortProfiles: ArmedEscortProfile[];
  getScheduleId:number;
  getScheduleCaption:string;
  getResourceType:string;
  selectedArmedEscortProfile: any;

  //Escort Generic
  public armedEscortDTSGenerics: ArmedEscortDTSDetailGenericDay[];
  public armedEscortDTSGenericsForm: FormGroup;
  selectedArmedEscortDTSGenerics: any;
  editingArmedEscortDTSGenerics = false;
  private edArmedEscortDTSGenerics: ArmedEscortDTSDetailGenericDay;

  //Commander Masters
  public commanderDTSMasters: CommanderDTSMasters[];
  selectedCommanderDTSMasters: any;
  public commanderDTSMastersForm: FormGroup;
  public commanderUseForm: FormGroup;

  editingCommanderDTSMasters = false;
  private edCommanderDTSMasters: CommanderDTSMasters;
  public commanderProfiles: CommanderProfile[];
  selectedCommanderProfile: CommanderProfile;

  //Commander Generic
  public commanderDTSGenerics: CommanderDTSDetailGenericDay[];
  public commanderDTSGenericsForm: FormGroup;
  selectedCommanderDTSGenerics: any;
  editingCommanderDTSGenerics = false;
  private edCommanderDTSGenerics: CommanderDTSDetailGenericDay;

  //Pilot Masters
  public pilotDTSMasters: PilotDTSMasters[];
  selectedPilotDTSMasters: any;
  public pilotDTSMastersForm: FormGroup;
  public pilotUseForm: FormGroup;

  editingPilotDTSMasters = false;
  private edPilotDTSMasters: PilotDTSMasters;
  public pilotProfiles: PilotProfile[];
  selectedPilotProfile: any;

  //Pilot Generic
  public pilotDTSGenerics: PilotDTSDetailGenericDay[];
  public pilotDTSGenericsForm: FormGroup;
  selectedPilotDTSGenerics: any;
  editingPilotDTSGenerics = false;
  private edPilotDTSGenerics: PilotDTSDetailGenericDay;

  //Vehicle Masters
  public vehicleDTSMasters: VehicleDTSMasters[];
  selectedVehicleDTSMasters: any;
  public vehicleDTSMastersForm: FormGroup;
  public vehicleUseForm: FormGroup;

  editingVehicleDTSMasters = false;
  private edVehicleDTSMasters: VehicleDTSMasters;
  public vehicleProfiles: VehicleProfile[];
  selectedVehicleProfile: any;
  isShowRenewDialog = false;

  //Renew
  public RenewContract : RenewContract;
  //Vehicle Generic
  public vehicleDTSGenerics: VehicleDTSDetailGenericDay[];
  public vehicleDTSGenericsForm: FormGroup;
  selectedVehicleDTSGenerics: any;
  editingVehicleDTSGenerics = false;
  private edVehicleDTSGenerics: VehicleDTSDetailGenericDay;
  isScheduleFormExpanded: boolean = false;
  isCommanderScheduleFormExpanded: boolean = false;
  isPilotScheduleFormExpanded: boolean = false;
  isVehicleScheduleFormExpanded: boolean = false;
  //selectedArmedEscortType: ArmedEscortType;
  //selectedArmedEscortRank: ArmedEscortRank;

  maxDateOfBirth: Date;
  public yearRange: string;
  minDate = new Date();

  constructor(
    private breadcrumbService: BreadcrumbService,
    public messageService: MessageService,
    public confirmationService: ConfirmationService,
    public fireStorage: AngularFireStorage,
    public inventoryService: InventoryService,
    private formBuilder: FormBuilder,
    private resourceregistrationService: ResourceRegistrationService,
    private resourceSchedulingService: ResourceSchedulingService
  ) {
    this.breadcrumbService.setItems([
      { label: "Setup" },
      {
        label: "Resource Scheduling",
        routerLink: ["/setup/resource-scheduling"],
      },
    ]);
  }

  ngOnInit(): void {
    this.loading = true;
    //Pilot Masters

    this.pilotTargetCols = [
      { field: "pilotResourceId", header: "pilotResourceId" },
      { field: "availabilityStart", header: "availablilityStart" },
      { field: "availablilityEnd", header: "availablilityEnd" },
      { field: "caption", header: "caption" },
    ];
    this.exportTargetColumns = this.pilotTargetCols.map((col) => ({
      title: col.header,
      dataKey: col.field,
    }));
    this.pilotDTSMastersForm = this.formBuilder.group({
      pilotResourceId: [{ value: null, disabled: true }, [Validators.required]],
      caption: ["", [Validators.required, Validators.minLength(3)]],
      availabilityStart: ["", [Validators.required]],
      availablilityEnd: ["", [Validators.required]],
    });

    this.resourceRenewalForm = this.formBuilder.group({
      resourceType: ["", [Validators.required]],
      numberOfPeriod: [null, [Validators.required, Validators.min(1)]],
      period: ["", [Validators.required]],
    });

    //Pilot Generics
    this.targetCols = [
      { field: "dTSMasterId", header: "dTSMasterId" },
      { field: "monday", header: "monday" },
      { field: "tuesday", header: "tuesday" },
      { field: "wednesday", header: "wednesday" },
      { field: "thursday", header: "thursday" },
      { field: "friday", header: "friday" },
      { field: "saturday", header: "saturday" },
      { field: "sunday", header: "sunday" },
      { field: "openingTime", header: "openingTime" },
      { field: "closingTime", header: "closingTime" },
      //{ field: 'codeName', header: 'Code Name' }
    ];
    this.exportTargetColumns = this.targetCols.map((col) => ({
      title: col.header,
      dataKey: col.field,
    }));
    this.pilotDTSGenericsForm = this.formBuilder.group({
      dTSMasterId: [null, [Validators.required]],
      monday: [false, [Validators.required]],
      tuesday: [false, [Validators.required]],
      wednesday: [false, [Validators.required]],
      thursday: [false, [Validators.required]],
      friday: [false, [Validators.required]],
      saturday: [false, [Validators.required]],
      sunday: [false, [Validators.required]],
      openingTime: ["", [Validators.required]],
      closingTime: ["", [Validators.required]],
      caption: [{ value: "", disabled: true }],
    });

    //Armed Escort Masters
    this.targetCols = [
      { field: "armedEscortResourceId", header: "armedEscortResourceId" },
      { field: "availabilityStart", header: "availabilityStart" },
      { field: "availablilityEnd", header: "availablilityEnd" },
      { field: "caption", header: "caption" },
    ];
    // this.minimumAge = 18;
    // this.maxDateOfBirth = new Date();
    // const year = this.maxDateOfBirth.getFullYear() - 0;
    // this.maxDateOfBirth.setFullYear(year);
    // this.yearRange = year - 10 + ":" + year;

    this.exportTargetColumns = this.targetCols.map((col) => ({
      title: col.header,
      dataKey: col.field,
    }));
    this.armedEscortDTSMastersForm = this.formBuilder.group({
      armedEscortResourceId: [
        { value: null, disabled: true },
        [Validators.required],
      ],
      caption: ["", [Validators.required, Validators.minLength(3)]],
      availabilityStart: ["", [Validators.required]],
      availablilityEnd: ["", [Validators.required]],
    });

    //Escort Generics
    this.targetCols = [
      { field: "dTSMasterId", header: "dTSMasterId" },
      { field: "monday", header: "monday" },
      { field: "tuesday", header: "tuesday" },
      { field: "wednesday", header: "wednesday" },
      { field: "thursday", header: "thursday" },
      { field: "friday", header: "friday" },
      { field: "saturday", header: "saturday" },
      { field: "sunday", header: "sunday" },
      { field: "openingTime", header: "openingTime" },
      { field: "closingTime", header: "closingTime" },
    ];
    this.exportTargetColumns = this.targetCols.map((col) => ({
      title: col.header,
      dataKey: col.field,
    }));
    this.armedEscortDTSGenericsForm = this.formBuilder.group({
      dTSMasterId: [null, [Validators.required]],
      monday: [false, [Validators.required]],
      tuesday: [false, [Validators.required]],
      wednesday: [false, [Validators.required]],
      thursday: [false, [Validators.required]],
      friday: [false, [Validators.required]],
      saturday: [false, [Validators.required]],
      sunday: [false, [Validators.required]],
      openingTime: ["", [Validators.required]],
      closingTime: ["", [Validators.required]],
      caption: [{ value: "", disabled: true }],
    });

    //Commander Masters
    this.targetCols = [
      { field: "commanderResourceId", header: "commanderResourceId" },
      { field: "availablilityStart", header: "availablilityStart" },
      { field: "availablilityEnd", header: "availablilityEnd" },
      { field: "caption", header: "caption" },
    ];

    this.exportTargetColumns = this.targetCols.map((col) => ({
      title: col.header,
      dataKey: col.field,
    }));
    this.commanderDTSMastersForm = this.formBuilder.group({
      commanderResourceId: [
        { value: null, disabled: true },
        [Validators.required],
      ],
      caption: ["", [Validators.required, Validators.minLength(3)]],
      availabilityStart: ["", [Validators.required]],
      availablilityEnd: ["", [Validators.required]],
    });

    //Commander Generics
    this.targetCols = [
      { field: "dTSMasterId", header: "dTSMasterId" },
      { field: "monday", header: "monday" },
      { field: "tuesday", header: "tuesday" },
      { field: "wednesday", header: "wednesday" },
      { field: "thursday", header: "thursday" },
      { field: "friday", header: "friday" },
      { field: "saturday", header: "saturday" },
      { field: "sunday", header: "sunday" },
      { field: "openingTime", header: "openingTime" },
      { field: "closingTime", header: "closingTime" },
    ];
    this.exportTargetColumns = this.targetCols.map((col) => ({
      title: col.header,
      dataKey: col.field,
    }));
    this.commanderDTSGenericsForm = this.formBuilder.group({
      dTSMasterId: [null, [Validators.required]],
      monday: [false, [Validators.required]],
      tuesday: [false, [Validators.required]],
      wednesday: [false, [Validators.required]],
      thursday: [false, [Validators.required]],
      friday: [false, [Validators.required]],
      saturday: [false, [Validators.required]],
      sunday: [false, [Validators.required]],
      openingTime: ["", [Validators.required]],
      closingTime: ["", [Validators.required]],
      caption: [{ value: "", disabled: true }],
    });

    //Vehicle Masters
    this.targetCols = [
      { field: "vehicleResourceId", header: "vehicleResourceId" },
      { field: "availablilityStart", header: "availablilityStart" },
      { field: "availablilityEnd", header: "availablilityEnd" },
      { field: "caption", header: "caption" },
    ];

    this.exportTargetColumns = this.targetCols.map((col) => ({
      title: col.header,
      dataKey: col.field,
    }));
    this.vehicleDTSMastersForm = this.formBuilder.group({
      vehicleResourceId: [
        { value: null, disabled: true },
        [Validators.required],
      ],
      caption: ["", [Validators.required, Validators.minLength(3)]],
      availabilityStart: ["", [Validators.required]],
      availablilityEnd: ["", [Validators.required]],
    });

    //Vehicle Generics
    this.targetCols = [
      { field: "dTSMasterId", header: "dTSMasterId" },
      { field: "monday", header: "monday" },
      { field: "tuesday", header: "tuesday" },
      { field: "wednesday", header: "wednesday" },
      { field: "thursday", header: "thursday" },
      { field: "friday", header: "friday" },
      { field: "saturday", header: "saturday" },
      { field: "sunday", header: "sunday" },
      { field: "openingTime", header: "openingTime" },
      { field: "closingTime", header: "closingTime" },
    ];
    this.exportTargetColumns = this.targetCols.map((col) => ({
      title: col.header,
      dataKey: col.field,
    }));
    this.vehicleDTSGenericsForm = this.formBuilder.group({
      dTSMasterId: [null, [Validators.required]],
      monday: [false, [Validators.required]],
      tuesday: [false, [Validators.required]],
      wednesday: [false, [Validators.required]],
      thursday: [false, [Validators.required]],
      friday: [false, [Validators.required]],
      saturday: [false, [Validators.required]],
      sunday: [false, [Validators.required]],
      openingTime: ["", [Validators.required]],
      closingTime: ["", [Validators.required]],
      caption: [{ value: "", disabled: true }],
    });

    //Escort
    this.armedEscortUseForm = this.formBuilder.group({
      userProfile: [""],
    });
    this.armedEscortUseForm.valueChanges
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(() => {
        this.selectedArmedEscortProfile =
          this.armedEscortUseForm.get("userProfile").value;
      });

    //Commander
    this.commanderUseForm = this.formBuilder.group({
      userProfile: [""],
    });
    this.commanderUseForm.valueChanges
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(() => {
        this.selectedCommanderProfile =
          this.commanderUseForm.get("userProfile").value;
      });

    //pilot
    this.pilotUseForm = this.formBuilder.group({
      userProfile: [""],
    });
    this.pilotUseForm.valueChanges
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(() => {
        this.selectedPilotProfile = this.pilotUseForm.get("userProfile").value;
      });

    //Vehicle
    this.vehicleUseForm = this.formBuilder.group({
      userProfile: [""],
    });
    this.vehicleUseForm.valueChanges
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(() => {
        this.selectedVehicleProfile =
          this.vehicleUseForm.get("userProfile").value;
      });

    this.fetchAllPilotProfile();
    this.fetchAllVehicles();
    this.fetchAllCommanderProfiles();
    this.fetchAllArmedEscortProfile();
    this.fetchAllCommanderDTSMasters();
    this.fetchAllArmedEscortDTSMasters();
    this.fetchAllArmedEscortDTSGenerics();
    this.fetchAllCommanderDTSGenerics();
    this.fetchAllCommanderDTSMasters();
    this.fetchAllPilotDTSMasters();
    this.fetchAllVehicleDTSMasters();
    this.fetchAllPilotDTSGenerics();
    this.fetchAllVehicleDTSGenerics();

    this.isScheduleFormExpanded = false;
    this.isCommanderScheduleFormExpanded = false;

    // var today = new Date();
    // console.log(today);

    this.submitCommanderButtonLabel = "Create";
    this.submitVehicleButtonLabel = "Create";
    this.submitButtonLabel = "Create";
  }

  //DTS Masters
  //Armed Escort
  fetchAllArmedEscortProfile(): void {
    this.resourceregistrationService
      .allArmedEscortProfile()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            var res = r.responseData;
            this.armedEscortProfiles = res;
            this.armedEscortProfiles.forEach(
              (userProfile) =>
                (userProfile.fullName = `${userProfile.firstName} ${userProfile.lastName}`)
            );
            this.loading = false;
          }
        },
        (error) => this.connectionError()
      );
    this.submittingData = false;
  }
  fetchAllArmedEscortDTSMasters(): void {
    this.resourceSchedulingService
      .allArmedEscortMasters()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            var res = r.responseData;
            this.armedEscortDTSMasters = res;
            this.loading = false;
          }
        },
        (error) => this.connectionError()
      );
    this.submittingData = false;
  }
  createArmedEscortMaster() {
    const formData = this.armedEscortDTSMastersForm.value;
    const postData = {
      armedEscortResourceId: this.selectedArmedEscortProfile.id,
      caption: formData.caption,
      availabilityStart: formData.availabilityStart,
      availablilityEnd: formData.availablilityEnd,
    };
    this.resourceSchedulingService
      .postArmedEscortMaster(postData)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            this.messageService.add({
              summary: "Success",
              severity: "success",
              detail: "New Schedule  created",
            });

            this.submittingData = false;
            this.armedEscortUseForm.reset();
            //window.location.reload();
            this.fetchAllArmedEscortDTSMasters();
            this.armedEscortDTSMastersForm.reset();
            this.closeEditing();
          } else {
            this.messageService.add({
              severity: "error",
              summary: "Failed",
              detail: " Error: " + r.responseMsg,
            });
            this.submittingData = false;
          }
        },
        (error) => {
          this.messageService.add({
            severity: "error",
            summary: "Failed",
            detail: "Connection Error " + error.error.message,
          });
          this.submittingData = false;
        }
      );
    this.submittingData = false;
  }

  deleteArmedEscortMaster(armedEscortDTSMaster: ArmedEscortDTSMasters): void {
    this.confirmationService.confirm({
      message:
        "Are you sure you want to delete " + armedEscortDTSMaster.caption,
      accept: () => {
        this._deleteArmedEscortMaster(armedEscortDTSMaster);
      },
    });
  }
  _deleteArmedEscortMaster(armedEscortDTSMaster) {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Deleting Schedule record",
    });
    this.resourceSchedulingService
      .deleteArmedEscortMaster(armedEscortDTSMaster.id)
      .subscribe(
        async (result: any) => {
          await this.messageService.add({
            severity: "success",
            summary: "Deleted",
            detail: "Schedule removed",
          });
          await this.fetchAllArmedEscortDTSMasters();
        },
        (error) => {
          this.connectionError();
        }
      );
  }
  editArmedEscortMaster(armedEscortDTSMasters: ArmedEscortDTSMasters): void {
    this.formWrapper.nativeElement.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "start",
    });
    this.edArmedEscortDTSMasters = armedEscortDTSMasters;
    this.editingArmedEscortDTSMasters = true;
    this.armedEscortDTSMastersForm.setValue({
      armedEscortResourceId: armedEscortDTSMasters.armedEscortResourceId,
      caption: armedEscortDTSMasters.caption,
      availabilityStart: armedEscortDTSMasters.availabilityStart,
      availablilityEnd: armedEscortDTSMasters.availablilityEnd,
    });
    // this.selectedPilotType = this.pilotTypes.find(
    //   (Type) => Type.id === pilotProfiles.pilotTypeId
    // );

    this.submitButtonLabel = "Update";
  }
  getArmedEscortMasterId(armedEscortDTSMasters: ArmedEscortDTSMasters): void {
    this.formWrapper.nativeElement.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "start",
    });
    //this.ToggleSchedule();
    this.selectedArmedEscortDTSMasters = armedEscortDTSMasters;
    this.isScheduleFormExpanded = true;
    this.armedEscortDTSGenericsForm.setValue({
      dTSMasterId: armedEscortDTSMasters.id,
      monday: false,
      tuesday: false,
      wednesday: false,
      thursday: false,
      friday: false,
      saturday: false,
      sunday: false,
      closingTime: null,
      openingTime: null,
      caption: armedEscortDTSMasters.caption,
    });
    this.resourceSchedulingService
      .allArmedEscortGenericsByMasterId(armedEscortDTSMasters.id)
      //.allArmedEscortGenericsByResourceId(armedEscortDTSMasters.armedEscortResourceId)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            var res = r.responseData;
            this.armedEscortDTSGenerics = res;
            this.loading = false;
          }
        },
        (error) => this.connectionError()
      );
    // this.selectedPilotType = this.pilotTypes.find(
    //   (Type) => Type.id === pilotProfiles.pilotTypeId
    // );

    //this.submitButtonLabel = "Update";
  }
  updateArmedEscortMaster(): boolean {
    this.submittingData = true;
    if (!this.edArmedEscortDTSMasters) {
      return false;
    }
    const formData = this.armedEscortDTSMastersForm.value;
    const data = {
      caption: formData.caption,
      availablilityStart: formData.availablilityStart,
      availablilityEnd: formData.availablilityEnd,
    };
    this.resourceSchedulingService
      .updateArmedEscortMaster(this.edArmedEscortDTSMasters.id, data)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            this.messageService.add({
              severity: "success",
              detail: "Resource Schedule Updated",
              summary: "Completed",
            });
            this.submittingData = false;
            this.closeEditing();
            this.fetchAllArmedEscortDTSMasters();
          }
        },
        (error) => {
          this.connectionError();
          this.submittingData = false;
        }
      );
  }

  RenewResourceContract() {
    this.submittingData = true;
    if (!this.getScheduleId) {
      return false;
    }
    const formData = this.resourceRenewalForm.value;
    const data = {
      period: formData.period,
      numberOfPeriod: formData.numberOfPeriod,
      resourceType: formData.resourceType,
    };
    this.resourceSchedulingService
      .RenewResourceContract(this.getScheduleId, data)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            this.messageService.add({
              severity: "success",
              detail: "Resource Contract Renewed",
              summary: "Completed",
            });
            this.submittingData = false;
            this.closeEditing();
            if(this.getResourceType.toLowerCase() === "marshal"){
              this.fetchAllArmedEscortDTSMasters();
            }
            else if(this.getResourceType.toLowerCase() === "commander"){
              this.fetchAllCommanderDTSMasters();
            }
            else if(this.getResourceType.toLowerCase() === "pilot"){
              this.fetchAllPilotDTSMasters();
            }
            else if(this.getResourceType.toLowerCase() === "vehicle"){
              this.fetchAllVehicleDTSMasters();
            }
          }
          else{
            this.messageService.add({
              severity: "error",
              summary: "Failed",
              detail: " Error: " + r.responseMsg,
            });
            this.submittingData = false;
          }
        },
        (error) => {
          this.connectionError();
          this.submittingData = false;
        }
      );
  }

  showMarshalRenewDialog(armedEscortDTSMasters: ArmedEscortDTSMasters){
    this.isShowRenewDialog = true;
    this.resourceRenewalForm.reset();
    this.getScheduleId = null;
    this.getScheduleCaption = null;
    this.getResourceType = null;
    this.edArmedEscortDTSMasters = armedEscortDTSMasters;
    this.selectedArmedEscortDTSMasters = armedEscortDTSMasters;
    this.getScheduleId = armedEscortDTSMasters.id;
    this.getScheduleCaption = armedEscortDTSMasters.caption;
    this.getResourceType = "marshal";
    this.resourceRenewalForm.patchValue({
      resourceType: "marshal",
    })
    //alert("id, caption" +  this.getScheduleId +  this.getScheduleCaption);
  }

  RenewResourceContract_(): void {
    this.confirmationService.confirm({
      message:
        "Are you sure you want to renew this contract? ",
      accept: () => {
        this.RenewResourceContract();
      },
    });
  }

  showCommanderRenewDialog(commanderDTSMasters: CommanderDTSMasters){
    this.isShowRenewDialog = true;
    this.resourceRenewalForm.reset();
    this.getScheduleId = null;
    this.getResourceType = null;
    this.getScheduleCaption = null;
    this.selectedCommanderDTSMasters = commanderDTSMasters;
    this.getScheduleId = commanderDTSMasters.id;
    this.getScheduleCaption = commanderDTSMasters.caption;
    this.getResourceType = "commander";
    this.resourceRenewalForm.patchValue({
      resourceType: "commander",
    })
    //alert("id, caption" +  this.getScheduleId +  this.getScheduleCaption);
  }

  showPilotRenewDialog(pilotDTSMasters: PilotDTSMasters){
    this.isShowRenewDialog = true;
    this.resourceRenewalForm.reset();
    this.getScheduleId = null;
    this.getResourceType = null;
    this.getScheduleCaption = null;
    this.selectedPilotDTSMasters = pilotDTSMasters;
    this.getScheduleId = pilotDTSMasters.id;
    this.getScheduleCaption = pilotDTSMasters.caption;
    this.getResourceType = "pilot";
    this.resourceRenewalForm.patchValue({
      resourceType: "pilot",
    })
  }
  showVehicleRenewDialog(vehicleDTSMasters: VehicleDTSMasters){
    this.isShowRenewDialog = true;
    this.resourceRenewalForm.reset();
    this.getScheduleId = null;
    this.getScheduleCaption = null;
    this.getResourceType = null;
    this.selectedVehicleDTSMasters = vehicleDTSMasters;
    this.getScheduleId = vehicleDTSMasters.id;
    this.getScheduleCaption = vehicleDTSMasters.caption;
    this.getResourceType = "vehicle";
    this.resourceRenewalForm.patchValue({
      resourceType: "vehicle",
    })
  }
  //Commander
  fetchAllCommanderProfiles() {
    this.resourceregistrationService
      .allCommanderProfile()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe((r: CommonResponse) => {
        if (r.responseCode == "00") {
          var res = r.responseData;
          this.commanderProfiles = res;
          this.commanderProfiles.forEach(
            (userProfile) =>
              (userProfile.fullName = `${userProfile.profile.firstName} ${userProfile.profile.lastName}`)
          );
          this.loading = false;
        }
      });
  }

  fetchAllCommanderDTSMasters(): void {
    this.resourceSchedulingService
      .allCommanderMasters()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            var res = r.responseData;
            this.commanderDTSMasters = res;
            this.loading = false;
          }
        },
        (error) => this.connectionError()
      );
    this.submittingData = false;
  }
  createCommanderMaster() {
    const formData = this.commanderDTSMastersForm.value;
    const postData = {
      commanderResourceId: this.selectedCommanderProfile.id,
      caption: formData.caption,
      availabilityStart: formData.availabilityStart,
      availablilityEnd: formData.availablilityEnd,
    };
    this.resourceSchedulingService
      .postCommanderMaster(postData)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            this.messageService.add({
              summary: "Success",
              severity: "success",
              detail: "New Schedule  created",
            });

            this.submittingData = false;
            this.commanderDTSMastersForm.reset();
            //window.location.reload();
            this.fetchAllCommanderDTSMasters();
            this.commanderUseForm.reset();
            this.closeEditing();
          } else {
            this.messageService.add({
              severity: "error",
              summary: "Failed",
              detail: " Error: " + r.responseMsg,
            });
            this.submittingData = false;
          }
        },
        (error) => {
          this.messageService.add({
            severity: "error",
            summary: "Failed",
            detail: "Connection Error " + error.error.message,
          });
          this.submittingData = false;
        }
      );
    this.submittingData = false;
  }

  deleteCommanderMaster(commanderDTSMaster: CommanderDTSMasters): void {
    this.confirmationService.confirm({
      message: "Are you sure you want to delete " + commanderDTSMaster.caption,
      accept: () => {
        this._deleteCommanderMaster(commanderDTSMaster);
      },
    });
  }
  _deleteCommanderMaster(commanderDTSMaster) {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Deleting Schedule record",
    });
    this.resourceSchedulingService
      .deleteCommanderMaster(commanderDTSMaster.id)
      .subscribe(
        async (result: any) => {
          await this.messageService.add({
            severity: "success",
            summary: "Deleted",
            detail: "Schedule removed",
          });
          await this.fetchAllCommanderDTSMasters();
        },
        (error) => {
          this.connectionError();
        }
      );
  }
  editCommanderMaster(commanderDTSMasters: CommanderDTSMasters): void {
    this.formWrapper.nativeElement.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "start",
    });
    this.edCommanderDTSMasters = commanderDTSMasters;
    this.editingCommanderDTSMasters = true;
    this.commanderDTSMastersForm.setValue({
      commanderResourceId: commanderDTSMasters.commanderResourceId,
      caption: commanderDTSMasters.caption,
      availabilityStart: commanderDTSMasters.availabilityStart,
      availablilityEnd: commanderDTSMasters.availablilityEnd,
    });
    // this.selectedPilotType = this.pilotTypes.find(
    //   (Type) => Type.id === pilotProfiles.pilotTypeId
    // );

    this.submitCommanderButtonLabel = "Update";
  }
  getCommanderMasterId(commanderDTSMasters: CommanderDTSMasters): void {
    this.formWrapper.nativeElement.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "start",
    });
    //this.ToggleCommanderSchedule();
    this.selectedArmedEscortDTSMasters = commanderDTSMasters;
    this.isCommanderScheduleFormExpanded = true;
    this.commanderDTSGenericsForm.setValue({
      dTSMasterId: commanderDTSMasters.id,
      monday: false,
      tuesday: false,
      wednesday: false,
      thursday: false,
      friday: false,
      saturday: false,
      sunday: false,
      closingTime: null,
      openingTime: null,
      caption: commanderDTSMasters.caption,
      //availabilityStart: armedEscortDTSMasters.availabilityStart,
    });
    this.resourceSchedulingService
      .allCommanderGenericsById(commanderDTSMasters.id)
      //.allCommanderGenericsByResourceId(commanderDTSMasters.commanderResourceId)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            var res = r.responseData;
            this.commanderDTSGenerics = res;
            this.loading = false;
          }
        },
        (error) => this.connectionError()
      );
    // this.selectedPilotType = this.pilotTypes.find(
    //   (Type) => Type.id === pilotProfiles.pilotTypeId
    // );

    //this.submitButtonLabel = "Update";
  }
  updateCommanderMaster(): boolean {
    this.submittingData = true;
    if (!this.edCommanderDTSMasters) {
      return false;
    }
    const formData = this.commanderDTSMastersForm.value;
    const data = {
      caption: formData.caption,
      availablilityStart: formData.availablilityStart,
      availablilityEnd: formData.availablilityEnd,
    };
    this.resourceSchedulingService
      .updateCommanderMaster(this.edArmedEscortDTSMasters.id, data)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            this.messageService.add({
              severity: "success",
              detail: "Resource Schedule Updated",
              summary: "Completed",
            });
            this.submittingData = false;
            this.closeEditing();
            this.fetchAllCommanderDTSMasters();
          }
        },
        (error) => {
          this.connectionError();
          this.submittingData = false;
        }
      );
  }
 
  //Pilot
  fetchAllPilotProfile(): void {
    this.resourceregistrationService
      .allPilotProfile()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            var res = r.responseData;
            this.pilotProfiles = res;
            this.pilotProfiles.forEach(
              (userProfile) =>
                (userProfile.fullName = `${userProfile.firstname} ${userProfile.lastname}`)
            );
            this.loading = false;
          } else {
            this.connectionError();
          }
        },
        (error) => {
          this.loading = false;
          this.connectionError();
        }
      );
    this.submittingData = false;
  }

  fetchAllPilotDTSMasters(): void {
    this.resourceSchedulingService
      .allPilotMasters()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            var res = r.responseData;
            this.pilotDTSMasters = res;
            this.loading = false;
          }
        },
        (error) => this.connectionError()
      );
    this.submittingData = false;
  }

  createPilotMaster() {
    const formData = this.pilotDTSMastersForm.value;
    const postData = {
      pilotResourceId: this.selectedPilotProfile.id,
      caption: formData.caption,
      availabilityStart: formData.availabilityStart,
      availablilityEnd: formData.availablilityEnd,
    };
    this.resourceSchedulingService
      .postPilotMaster(postData)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            this.messageService.add({
              summary: "Success",
              severity: "success",
              detail: "New Schedule  created",
            });

            this.submittingData = false;
            this.pilotDTSMastersForm.reset();
            //window.location.reload();
            this.fetchAllPilotDTSMasters();
            this.pilotUseForm.reset();
            this.closeEditing();
          } else {
            this.messageService.add({
              severity: "error",
              summary: "Failed",
              detail: " Error: " + r.responseMsg,
            });
            this.submittingData = false;
          }
        },
        (error) => {
          this.messageService.add({
            severity: "error",
            summary: "Failed",
            detail: "Connection Error " + error.error.message,
          });
          this.submittingData = false;
        }
      );
    this.submittingData = false;
  }

  deletePilotMaster(pilotDTSMasters: PilotDTSMasters): void {
    this.confirmationService.confirm({
      message: "Are you sure you want to delete " + pilotDTSMasters.caption,
      accept: () => {
        this._deletePilotMaster(pilotDTSMasters);
      },
    });
  }
  _deletePilotMaster(pilotDTSMasters) {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Deleting Schedule record",
    });
    this.resourceSchedulingService
      .deletePilotMaster(pilotDTSMasters.id)
      .subscribe(
        async (result: any) => {
          await this.messageService.add({
            severity: "success",
            summary: "Deleted",
            detail: "Schedule removed",
          });
          await this.fetchAllPilotDTSMasters();
        },
        (error) => {
          this.connectionError();
        }
      );
  }
  editPilotMaster(pilotDTSMasters: PilotDTSMasters): void {
    this.formWrapper.nativeElement.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "start",
    });
    this.edPilotDTSMasters = pilotDTSMasters;
    this.editingPilotDTSMasters = true;
    this.pilotDTSMastersForm.setValue({
      pilotResourceId: pilotDTSMasters.pilotResourceId,
      caption: pilotDTSMasters.caption,
      availabilityStart: pilotDTSMasters.availabilityStart,
      availablilityEnd: pilotDTSMasters.availablilityEnd,
    });
    // this.selectedPilotType = this.pilotTypes.find(
    //   (Type) => Type.id === pilotProfiles.pilotTypeId
    // );

    this.submitCommanderButtonLabel = "Update";
  }
  getPilotMasterId(pilotDTSMasters: PilotDTSMasters): void {
    this.formWrapper.nativeElement.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "start",
    });
    //this.ToggleCommanderSchedule();
    this.selectedPilotDTSMasters = pilotDTSMasters;
    this.isPilotScheduleFormExpanded = true;
    this.pilotDTSGenericsForm.setValue({
      dTSMasterId: pilotDTSMasters.id,
      monday: false,
      tuesday: false,
      wednesday: false,
      thursday: false,
      friday: false,
      saturday: false,
      sunday: false,
      closingTime: null,
      openingTime: null,
      caption: pilotDTSMasters.caption,
    });
    this.resourceSchedulingService
      .allPilotGenericsById(pilotDTSMasters.id)
      //.allPilotGenericsByResourceId(pilotDTSMasters.pilotResourceId)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            var res = r.responseData;
            this.pilotDTSGenerics = res;
            this.loading = false;
          }
        },
        (error) => this.connectionError()
      );

    //this.submitButtonLabel = "Update";
  }
  updatePilotMaster(): boolean {
    this.submittingData = true;
    if (!this.edPilotDTSMasters) {
      return false;
    }
    const formData = this.pilotDTSMastersForm.value;
    const data = {
      caption: formData.caption,
      availablilityStart: formData.availablilityStart,
      availablilityEnd: formData.availablilityEnd,
    };
    this.resourceSchedulingService
      .updatePilotMaster(this.edPilotDTSMasters.id, data)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            this.messageService.add({
              severity: "success",
              detail: "Resource Schedule Updated",
              summary: "Completed",
            });
            this.submittingData = false;
            this.closeEditing();
            this.fetchAllPilotDTSMasters();
          }
        },
        (error) => {
          this.connectionError();
          this.submittingData = false;
        }
      );
  }
 

  //Vehicle
  fetchAllVehicles() {
    this.resourceregistrationService
      .allVehicle()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            var res = r.responseData;
            this.vehicleProfiles = res;
            console.log(this.vehicleProfiles);
            this.loading = false;
          }
        },
        (error) => {
          this.connectionError();
          this.loading = false;
          this.submittingData = false;
        }
      );
  }

  fetchAllVehicleDTSMasters(): void {
    this.resourceSchedulingService
      .allVehicleMasters()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            var res = r.responseData;
            this.vehicleDTSMasters = res;
            console.log(this.vehicleDTSMasters);
            this.loading = false;
          }
        },
        (error) => this.connectionError()
      );
    this.submittingData = false;
  }
  createVehicleMaster() {
    const formData = this.vehicleDTSMastersForm.value;
    const postData = {
      vehicleResourceId: this.selectedVehicleProfile.id,
      caption: formData.caption,
      availabilityStart: formData.availabilityStart,
      availablilityEnd: formData.availablilityEnd,
    };
    this.resourceSchedulingService
      .postVehicleMaster(postData)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            this.messageService.add({
              summary: "Success",
              severity: "success",
              detail: "New Schedule  created",
            });

            this.submittingData = false;
            this.vehicleDTSMastersForm.reset();
            //window.location.reload();
            this.fetchAllVehicleDTSMasters();
            this.vehicleUseForm.reset();
            this.closeEditing();
          } else {
            this.messageService.add({
              severity: "error",
              summary: "Failed",
              detail: " Error: " + r.responseMsg,
            });
            this.submittingData = false;
          }
        },
        (error) => {
          this.messageService.add({
            severity: "error",
            summary: "Failed",
            detail: "Connection Error " + error.error.message,
          });
          this.submittingData = false;
        }
      );
    this.submittingData = false;
  }

  deleteVehicleMaster(vehicleDTSMasters: VehicleDTSMasters): void {
    this.confirmationService.confirm({
      message: "Are you sure you want to delete " + vehicleDTSMasters.caption,
      accept: () => {
        this._deleteVehicleMaster(vehicleDTSMasters);
      },
    });
  }
  _deleteVehicleMaster(vehicleDTSMasters) {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Deleting Schedule record",
    });
    this.resourceSchedulingService
      .deleteVehicleMaster(vehicleDTSMasters.id)
      .subscribe(
        async (result: any) => {
          await this.messageService.add({
            severity: "success",
            summary: "Deleted",
            detail: "Schedule removed",
          });
          await this.fetchAllVehicleDTSMasters();
        },
        (error) => {
          this.connectionError();
        }
      );
  }
  editVehicleMaster(vehicleDTSMasters: VehicleDTSMasters): void {
    this.formWrapper.nativeElement.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "start",
    });
    this.edVehicleDTSMasters = vehicleDTSMasters;
    this.editingVehicleDTSMasters = true;
    this.vehicleDTSMastersForm.setValue({
      pilotResourceId: vehicleDTSMasters.vehicleResourceId,
      caption: vehicleDTSMasters.caption,
      availabilityStart: vehicleDTSMasters.availabilityStart,
      availablilityEnd: vehicleDTSMasters.availablilityEnd,
    });
    // this.selectedPilotType = this.pilotTypes.find(
    //   (Type) => Type.id === pilotProfiles.pilotTypeId
    // );

    this.submitButtonLabel = "Update";
  }
  getVehicleMasterId(vehicleDTSMasters: VehicleDTSMasters): void {
    this.formWrapper.nativeElement.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "start",
    });
    //this.ToggleCommanderSchedule();
    this.selectedVehicleDTSMasters = vehicleDTSMasters;
    this.isVehicleScheduleFormExpanded = true;
    this.vehicleDTSGenericsForm.setValue({
      dTSMasterId: vehicleDTSMasters.id,
      monday: false,
      tuesday: false,
      wednesday: false,
      thursday: false,
      friday: false,
      saturday: false,
      sunday: false,
      closingTime: null,
      openingTime: null,
      caption: vehicleDTSMasters.caption,
      //availabilityStart: armedEscortDTSMasters.availabilityStart,
    });
    this.resourceSchedulingService
      .allVehicleGenericsById(vehicleDTSMasters.id)
      //.allVehicleGenericsByResourceId(vehicleDTSMasters.vehicleResourceId)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            var res = r.responseData;
            this.vehicleDTSGenerics = res;
            this.loading = false;
          }
        },
        (error) => this.connectionError()
      );

    //this.submitButtonLabel = "Update";
  }
  updateVehicleMaster(): boolean {
    this.submittingData = true;
    if (!this.edVehicleDTSMasters) {
      return false;
    }
    const formData = this.vehicleDTSMastersForm.value;
    const data = {
      caption: formData.caption,
      availabilityStart: formData.availabilityStart,
      availablilityEnd: formData.availablilityEnd,
    };
    this.resourceSchedulingService
      .updateVehicleMaster(this.edVehicleDTSMasters.id, data)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            this.messageService.add({
              severity: "success",
              detail: "Resource Schedule Updated",
              summary: "Completed",
            });
            this.submittingData = false;
            this.closeEditing();
            this.fetchAllVehicleDTSMasters();
          }
        },
        (error) => {
          this.connectionError();
          this.submittingData = false;
        }
      );
  }
  

  //DTS Generics
  //Armed Escort
  fetchAllArmedEscortDTSGenerics(): void {
    this.resourceSchedulingService
      .allArmedEscortGenerics()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            var res = r.responseData;
            this.armedEscortDTSGenerics = res;
            this.loading = false;
          }
        },
        (error) => this.connectionError()
      );
    this.submittingData = false;
  }
  createArmedEscortDTSGeneric() {
    const formData = this.armedEscortDTSGenericsForm.value;
    const postData = {
      dTSMasterId: formData.dTSMasterId,
      monday: formData.monday,
      tuesday: formData.tuesday,
      wednesday: formData.wednesday,
      thursday: formData.thursday,
      friday: formData.friday,
      saturday: formData.saturday,
      sunday: formData.sunday,
      closingTime: formData.closingTime,
      openingTime: formData.openingTime,
    };
    this.resourceSchedulingService
      .postArmedEscortGeneric(postData)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            this.messageService.add({
              summary: "Success",
              severity: "success",
              detail: "New Schedule  created",
            });

            this.submittingData = false;
            //window.location.reload();
            //this.fetchAllArmedEscortDTSGenerics();
            //this.getArmedEscortMasterId();
            this.isScheduleFormExpanded = false;
            this.armedEscortDTSGenericsForm.reset();
            this.closeEditing();
          } else {
            this.messageService.add({
              severity: "error",
              summary: "Failed",
              detail: " Error: " + r.responseMsg,
            });
            this.submittingData = false;
          }
        },
        (error) => {
          this.messageService.add({
            severity: "error",
            summary: "Failed",
            detail: "Connection Error " + error.error.message,
          });
          this.submittingData = false;
        }
      );
    this.submittingData = false;
  }

  deleteArmedEscortDTSGeneric(
    armedEscortDTSDetailGenericDay: ArmedEscortDTSDetailGenericDay
  ): void {
    this.confirmationService.confirm({
      message: "Are you sure you want to delete " + "Contract",
      accept: () => {
        this._deleteArmedEscortDTSGeneric(armedEscortDTSDetailGenericDay);
      },
    });
  }
  _deleteArmedEscortDTSGeneric(armedEscortDTSDetailGenericDay) {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Deleting Schedule record",
    });
    this.resourceSchedulingService
      .deleteArmedEscortGeneric(armedEscortDTSDetailGenericDay.id)
      .subscribe(
        async (result: any) => {
          await this.messageService.add({
            severity: "success",
            summary: "Deleted",
            detail: "Schedule removed",
          });
          this.isScheduleFormExpanded = false;
          //await this.fetchAllArmedEscortDTSGenerics();
        },
        (error) => {
          this.connectionError();
        }
      );
  }
  editArmedEscortDTSGeneric(
    armedEscortDTSDetailGenericDays: ArmedEscortDTSDetailGenericDay
  ): void {
    this.formWrapper.nativeElement.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "start",
    });
    this.edArmedEscortDTSGenerics = armedEscortDTSDetailGenericDays;
    this.editingArmedEscortDTSGenerics = true;
    this.armedEscortDTSGenericsForm.setValue({
      dTSMasterId: armedEscortDTSDetailGenericDays.dtsMasterId,
      monday: armedEscortDTSDetailGenericDays.monday,
      tuesday: armedEscortDTSDetailGenericDays.tuesday,
      wednesday: armedEscortDTSDetailGenericDays.wednesday,
      thursday: armedEscortDTSDetailGenericDays.thursday,
      friday: armedEscortDTSDetailGenericDays.friday,
      saturday: armedEscortDTSDetailGenericDays.saturday,
      sunday: armedEscortDTSDetailGenericDays.sunday,
      closingTime: armedEscortDTSDetailGenericDays.closingTime,
      openingTime: armedEscortDTSDetailGenericDays.openingTime,
    });
    // this.selectedPilotType = this.pilotTypes.find(
    //   (Type) => Type.id === pilotProfiles.pilotTypeId
    // );

    this.submitButtonLabel = "Update";
  }
  updateArmedEscortDTSGeneric(): boolean {
    this.submittingData = true;
    if (!this.edArmedEscortDTSGenerics) {
      return false;
    }
    const formData = this.armedEscortDTSGenericsForm.value;
    const data = {
      monday: formData.monday,
      tuesday: formData.tuesday,
      wednesday: formData.wednesday,
      thursday: formData.thursday,
      friday: formData.friday,
      saturday: formData.saturday,
      sunday: formData.sunday,
      closingTime: formData.closingTime,
      openingTime: formData.openingTime,
    };
    this.resourceSchedulingService
      .updateArmedEscortGeneric(this.edArmedEscortDTSGenerics.id, data)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            this.messageService.add({
              severity: "success",
              detail: "Resource Schedule Updated",
              summary: "Completed",
            });
            this.submittingData = false;
            this.closeEditing();
            this.isScheduleFormExpanded = false;
            //this.fetchAllArmedEscortDTSGenerics();
          }
        },
        (error) => {
          this.connectionError();
          this.submittingData = false;
        }
      );
  }

  //Commander
  fetchAllCommanderDTSGenerics(): void {
    this.resourceSchedulingService
      .allCommanderGenerics()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            var res = r.responseData;
            this.commanderDTSGenerics = res;
            this.loading = false;
          }
        },
        (error) => this.connectionError()
      );
    this.submittingData = false;
  }
  createCommanderDTSGeneric() {
    const formData = this.commanderDTSGenericsForm.value;
    const postData = {
      dTSMasterId: formData.dTSMasterId,
      monday: formData.monday,
      tuesday: formData.tuesday,
      wednesday: formData.wednesday,
      thursday: formData.thursday,
      friday: formData.friday,
      saturday: formData.saturday,
      sunday: formData.sunday,
      closingTime: formData.closingTime,
      openingTime: formData.openingTime,
    };
    this.resourceSchedulingService
      .postCommanderGeneric(postData)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            this.messageService.add({
              summary: "Success",
              severity: "success",
              detail: "New Schedule  created",
            });

            this.submittingData = false;
            //window.location.reload();
            //this.fetchAllCommanderDTSGenerics();
            this.isCommanderScheduleFormExpanded = false;
            this.commanderDTSGenericsForm.reset();
            this.closeEditing();
          } else {
            this.messageService.add({
              severity: "error",
              summary: "Failed",
              detail: " Error: " + r.responseMsg,
            });
            this.submittingData = false;
          }
        },
        (error) => {
          this.messageService.add({
            severity: "error",
            summary: "Failed",
            detail: "Connection Error " + error.error.message,
          });
          this.submittingData = false;
        }
      );
    this.submittingData = false;
  }

  deleteCommanderDTSGeneric(
    commanderDTSDetailGenericDay: CommanderDTSDetailGenericDay
  ): void {
    this.confirmationService.confirm({
      message: "Are you sure you want to delete " + "schedule?",

      accept: () => {
        this._deleteCommanderDTSGeneric(commanderDTSDetailGenericDay);
      },
    });
  }
  _deleteCommanderDTSGeneric(commanderDTSDetailGenericDay) {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Deleting Schedule record",
    });
    this.resourceSchedulingService
      .deleteCommanderGeneric(commanderDTSDetailGenericDay.id)
      .subscribe(
        async (result: any) => {
          await this.messageService.add({
            severity: "success",
            summary: "Deleted",
            detail: "Schedule removed",
          });
          //await this.fetchAllCommanderDTSGenerics();
          this.isCommanderScheduleFormExpanded = false;
        },
        (error) => {
          this.connectionError();
        }
      );
  }

  editCommanderDTSGeneric(
    commanderDTSDetailGenericDays: CommanderDTSDetailGenericDay
  ): void {
    this.formWrapper.nativeElement.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "start",
    });
    this.edCommanderDTSGenerics = commanderDTSDetailGenericDays;
    this.editingCommanderDTSGenerics = true;
    this.commanderDTSGenericsForm.setValue({
      dTSMasterId: commanderDTSDetailGenericDays.dTSMasterId,
      monday: commanderDTSDetailGenericDays.monday,
      tuesday: commanderDTSDetailGenericDays.tuesday,
      wednesday: commanderDTSDetailGenericDays.wednesday,
      thursday: commanderDTSDetailGenericDays.thursday,
      friday: commanderDTSDetailGenericDays.friday,
      saturday: commanderDTSDetailGenericDays.saturday,
      sunday: commanderDTSDetailGenericDays.sunday,
      closingTime: commanderDTSDetailGenericDays.closingTime,
      openingTime: commanderDTSDetailGenericDays.openingTime,
    });
    // this.selectedPilotType = this.pilotTypes.find(
    //   (Type) => Type.id === pilotProfiles.pilotTypeId
    // );

    this.submitButtonLabel = "Update";
  }
  updateCommanderDTSGeneric(): boolean {
    this.submittingData = true;
    if (!this.edCommanderDTSGenerics) {
      return false;
    }
    const formData = this.commanderDTSGenericsForm.value;
    const data = {
      monday: formData.monday,
      tuesday: formData.tuesday,
      wednesday: formData.wednesday,
      thursday: formData.thursday,
      friday: formData.friday,
      saturday: formData.saturday,
      sunday: formData.sunday,
      closingTime: formData.closingTime,
      openingTime: formData.openingTime,
    };
    this.resourceSchedulingService
      .updateCommanderGeneric(this.edCommanderDTSGenerics.id, data)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            this.messageService.add({
              severity: "success",
              detail: "Resource Schedule Updated",
              summary: "Completed",
            });
            this.submittingData = false;
            this.closeEditing();
            this.isCommanderScheduleFormExpanded = false;
            //this.fetchAllCommanderDTSGenerics();
          }
        },
        (error) => {
          this.connectionError();
          this.submittingData = false;
        }
      );
  }

  //Pilot
  fetchAllPilotDTSGenerics(): void {
    this.resourceSchedulingService
      .allPilotGenerics()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            var res = r.responseData;
            this.pilotDTSGenerics = res;
            this.loading = false;
          }
        },
        (error) => this.connectionError()
      );
    this.submittingData = false;
  }

  createPilotDTSGeneric() {
    const formData = this.pilotDTSGenericsForm.value;
    const postData = {
      dTSMasterId: formData.dTSMasterId,
      monday: formData.monday,
      tuesday: formData.tuesday,
      wednesday: formData.wednesday,
      thursday: formData.thursday,
      friday: formData.friday,
      saturday: formData.saturday,
      sunday: formData.sunday,
      closingTime: formData.closingTime,
      openingTime: formData.openingTime,
    };
    this.resourceSchedulingService
      .postPilotGeneric(postData)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            this.messageService.add({
              summary: "Success",
              severity: "success",
              detail: "New Schedule  created",
            });

            this.submittingData = false;
            //window.location.reload();
            //this.fetchAllCommanderDTSGenerics();
            this.isPilotScheduleFormExpanded = false;
            this.pilotDTSGenericsForm.reset();
            this.closeEditing();
          } else {
            this.messageService.add({
              severity: "error",
              summary: "Failed",
              detail: "Connection Error " + r.responseMsg,
            });
            this.submittingData = false;
          }
        },
        (error) => {
          this.messageService.add({
            severity: "error",
            summary: "Failed",
            detail: "Connection Error " + error.error.message,
          });
          this.submittingData = false;
        }
      );
    this.submittingData = false;
  }

  deletePilotDTSGeneric(
    pilotDTSDetailGenericDay: PilotDTSDetailGenericDay
  ): void {
    this.confirmationService.confirm({
      message: "Are you sure you want to delete " + "schedule?",

      accept: () => {
        this._deletePilotDTSGeneric(pilotDTSDetailGenericDay);
      },
    });
  }
  _deletePilotDTSGeneric(pilotDTSDetailGenericDay) {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Deleting Schedule record",
    });
    this.resourceSchedulingService
      .deletePilotGeneric(pilotDTSDetailGenericDay.id)
      .subscribe(
        async (result: any) => {
          await this.messageService.add({
            severity: "success",
            summary: "Deleted",
            detail: "Schedule removed",
          });
          this.isPilotScheduleFormExpanded = false;
          //await this.fetchAllCommanderDTSGenerics();
        },
        (error) => {
          this.connectionError();
        }
      );
  }

  editPilotDTSGeneric(
    pilotDTSDetailGenericDay: PilotDTSDetailGenericDay
  ): void {
    this.formWrapper.nativeElement.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "start",
    });
    this.edPilotDTSGenerics = pilotDTSDetailGenericDay;
    this.editingPilotDTSGenerics = true;
    this.pilotDTSGenericsForm.setValue({
      dTSMasterId: pilotDTSDetailGenericDay.dTSMasterId,
      monday: pilotDTSDetailGenericDay.monday,
      tuesday: pilotDTSDetailGenericDay.tuesday,
      wednesday: pilotDTSDetailGenericDay.wednesday,
      thursday: pilotDTSDetailGenericDay.thursday,
      friday: pilotDTSDetailGenericDay.friday,
      saturday: pilotDTSDetailGenericDay.saturday,
      sunday: pilotDTSDetailGenericDay.sunday,
      closingTime: pilotDTSDetailGenericDay.closingTime,
      openingTime: pilotDTSDetailGenericDay.openingTime,
    });

    this.submitButtonLabel = "Update";
  }

  updatePilotDTSGeneric(): boolean {
    this.submittingData = true;
    if (!this.edPilotDTSGenerics) {
      return false;
    }
    const formData = this.pilotDTSGenericsForm.value;
    const data = {
      monday: formData.monday,
      tuesday: formData.tuesday,
      wednesday: formData.wednesday,
      thursday: formData.thursday,
      friday: formData.friday,
      saturday: formData.saturday,
      sunday: formData.sunday,
      closingTime: formData.closingTime,
      openingTime: formData.openingTime,
    };
    this.resourceSchedulingService
      .updatePilotGeneric(this.edPilotDTSGenerics.id, data)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            this.messageService.add({
              severity: "success",
              detail: "Resource Schedule Updated",
              summary: "Completed",
            });
            this.submittingData = false;
            this.closeEditing();
            this.isPilotScheduleFormExpanded = false;
            //this.fetchAllCommanderDTSGenerics();
          }
        },
        (error) => {
          this.connectionError();
          this.submittingData = false;
        }
      );
  }

  //Vehicle
  fetchAllVehicleDTSGenerics(): void {
    this.resourceSchedulingService
      .allVehicleGenerics()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            var res = r.responseData;
            this.vehicleDTSGenerics = res;
            this.loading = false;
          }
        },
        (error) => this.connectionError()
      );
    this.submittingData = false;
  }

  createVehicleDTSGeneric() {
    const formData = this.vehicleDTSGenericsForm.value;
    const postData = {
      dTSMasterId: formData.dTSMasterId,
      monday: formData.monday,
      tuesday: formData.tuesday,
      wednesday: formData.wednesday,
      thursday: formData.thursday,
      friday: formData.friday,
      saturday: formData.saturday,
      sunday: formData.sunday,
      closingTime: formData.closingTime,
      openingTime: formData.openingTime,
    };
    this.resourceSchedulingService
      .postVehicleGeneric(postData)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            this.messageService.add({
              summary: "Success",
              severity: "success",
              detail: "New Schedule  created",
            });

            this.submittingData = false;
            //window.location.reload();
            //this.fetchAllCommanderDTSGenerics();
            this.isVehicleScheduleFormExpanded = false;
            this.vehicleDTSGenericsForm.reset();
            this.closeEditing();
          } else {
            this.messageService.add({
              severity: "error",
              summary: "Failed",
              detail: " Error: " + r.responseMsg,
            });
            this.submittingData = false;
          }
        },
        (error) => {
          this.messageService.add({
            severity: "error",
            summary: "Failed",
            detail: "Connection Error " + error.error.message,
          });
          this.submittingData = false;
        }
      );
    this.submittingData = false;
  }

  deleteVehicleDTSGeneric(
    vehicleDTSDetailGenericDay: VehicleDTSDetailGenericDay
  ): void {
    this.confirmationService.confirm({
      message: "Are you sure you want to delete " + "schedule?",

      accept: () => {
        this._deleteVehicleDTSGeneric(vehicleDTSDetailGenericDay);
      },
    });
  }
  _deleteVehicleDTSGeneric(vehicleDTSDetailGenericDay) {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Deleting Schedule record",
    });
    this.resourceSchedulingService
      .deleteVehicleGeneric(vehicleDTSDetailGenericDay.id)
      .subscribe(
        async (result: any) => {
          await this.messageService.add({
            severity: "success",
            summary: "Deleted",
            detail: "Schedule removed",
          });
          this.isVehicleScheduleFormExpanded = false;
          //await this.fetchAllCommanderDTSGenerics();
        },
        (error) => {
          this.connectionError();
        }
      );
  }

  editVehicleDTSGeneric(
    vehicleDTSDetailGenericDay: VehicleDTSDetailGenericDay
  ): void {
    this.formWrapper.nativeElement.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "start",
    });
    this.edVehicleDTSGenerics = vehicleDTSDetailGenericDay;
    this.editingVehicleDTSGenerics = true;
    this.vehicleDTSGenericsForm.setValue({
      dTSMasterId: vehicleDTSDetailGenericDay.dTSMasterId,
      monday: vehicleDTSDetailGenericDay.monday,
      tuesday: vehicleDTSDetailGenericDay.tuesday,
      wednesday: vehicleDTSDetailGenericDay.wednesday,
      thursday: vehicleDTSDetailGenericDay.thursday,
      friday: vehicleDTSDetailGenericDay.friday,
      saturday: vehicleDTSDetailGenericDay.saturday,
      sunday: vehicleDTSDetailGenericDay.sunday,
      closingTime: vehicleDTSDetailGenericDay.closingTime,
      openingTime: vehicleDTSDetailGenericDay.openingTime,
    });

    this.submitButtonLabel = "Update";
  }

  updateVehicleDTSGeneric(): boolean {
    this.submittingData = true;
    if (!this.edVehicleDTSGenerics) {
      return false;
    }
    const formData = this.vehicleDTSGenericsForm.value;
    const data = {
      monday: formData.monday,
      tuesday: formData.tuesday,
      wednesday: formData.wednesday,
      thursday: formData.thursday,
      friday: formData.friday,
      saturday: formData.saturday,
      sunday: formData.sunday,
      closingTime: formData.closingTime,
      openingTime: formData.openingTime,
    };
    this.resourceSchedulingService
      .updateVehicleGeneric(this.edVehicleDTSGenerics.id, data)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            this.messageService.add({
              severity: "success",
              detail: "Resource Schedule Updated",
              summary: "Completed",
            });
            this.submittingData = false;
            this.closeEditing();
            this.isVehicleScheduleFormExpanded = false;
            //this.fetchAllCommanderDTSGenerics();
          }
        },
        (error) => {
          this.connectionError();
          this.submittingData = false;
        }
      );
  }

  //Status
  GetArmedEscortDTSMasterStatus(item: ArmedEscortDTSMasters): string {
    var today = new Date();
    var start = new Date(item.availabilityStart);
    var stop = new Date(item.availablilityEnd);
    if (today < start) return "InActive";
    if (today > stop) return "Expired";
    if (today >= start && today <= stop) return "Active";
    return "Err";
  }
  GetCommandetDTSMasterStatus(item: CommanderDTSMasters): string {
    var today = new Date();
    var start = new Date(item.availabilityStart);
    var stop = new Date(item.availablilityEnd);
    if (today < start) return "InActive";
    if (today > stop) return "Expired";
    if (today >= start && today <= stop) return "Active";
    return "Err";
  }
  GetPilotDTSMasterStatus(item: PilotDTSMasters): string {
    var today = new Date();
    var start = new Date(item.availabilityStart);
    var stop = new Date(item.availablilityEnd);
    if (today < start) return "InActive";
    if (today > stop) return "Expired";
    if (today >= start && today <= stop) return "Active";
    return "Err";
  }
  GetVehicleDTSMasterStatus(item: VehicleDTSMasters): string {
    var today = new Date();
    var start = new Date(item.availabilityStart);
    var stop = new Date(item.availablilityEnd);
    if (today < start) return "InActive";
    if (today > stop) return "Expired";
    if (today >= start && today <= stop) return "Active";
    return "Err";
  }

  getDate() {
    let localDate = new Date(
      this.armedEscortDTSMastersForm.value.availabilityStart.getTime()
      // +
      //   Math.abs(
      //     this.armedEscortDTSMastersForm.value.availabilityStart.getTimezoneOffset()
      //   )
    );
    console.log(this.armedEscortDTSMastersForm.value);
    console.log(localDate);
  }
  //Toggle
  ToggleSchedule() {
    this.isScheduleFormExpanded = !this.isScheduleFormExpanded;
  }
  ToggleCommanderSchedule() {
    this.isCommanderScheduleFormExpanded =
      !this.isCommanderScheduleFormExpanded;
  }
  TogglePilotSchedule() {
    this.isPilotScheduleFormExpanded = !this.isPilotScheduleFormExpanded;
  }
  ToggleVehicleSchedule() {
    this.isVehicleScheduleFormExpanded = !this.isVehicleScheduleFormExpanded;
  }
  closeEditing() {
    this.editingArmedEscortDTSMasters = false;
    this.armedEscortDTSMastersForm.reset();
    this.edArmedEscortDTSMasters = null;

    this.editingCommanderDTSMasters = false;
    this.commanderDTSMastersForm.reset();
    this.edCommanderDTSMasters = null;

    this.editingPilotDTSMasters = false;
    this.pilotDTSMastersForm.reset();
    this.edPilotDTSMasters = null;

    this.editingVehicleDTSMasters = false;
    this.vehicleDTSMastersForm.reset();
    this.edVehicleDTSMasters = null;
    this.isShowRenewDialog = false;
    this.submitButtonLabel = "Submit";
    this.submitVehicleButtonLabel = "Submit";
  }
  private connectionError() {
    this.messageService.add({
      severity: "error",
      summary: "Failed",
      detail: "Connection Error, Please try again",
    });
  }
  ngOnDestroy(): void {
    this.unsubscriber$.complete();
    this.unsubscriber$.unsubscribe();
  }
}
