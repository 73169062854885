import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {Observable} from 'rxjs';
import {CommonResponse} from '../interfaces/home';

@Injectable({
  providedIn: 'root'
})
export class DropReasonService {
  constructor(private http: HttpClient) { }
  baseUrl = environment.baseUrl+'DropReason/';
  // fetch all DropReason in the database
  allDropReason(): Observable<CommonResponse>{
    return this.http.get<CommonResponse>(this.baseUrl);
  }
  getDropReason(id: any):Observable<CommonResponse>{
    return this.http.get<CommonResponse>(this.baseUrl +id);
  }
  getDropReasonByName(title: any):Observable<CommonResponse>{
    return this.http.get<CommonResponse>(this.baseUrl + 'title/' + title);
  }
  postDropReason(data: any){
    return this.http.post(this.baseUrl, data);
  }
  deleteDropReason(id: any){
    return this.http.delete(this.baseUrl +id);
  }
  updateDropReason(id: any, data: any){
    return this.http.put(this.baseUrl +id, data);
  }
}
