import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { MasterServiceAssignment } from "src/app/interfaces/armada";
import { GeocodingGetAddressCordinateVM } from "src/app/interfaces/employment";
import { CommonResponse } from "src/app/interfaces/home";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class MasterServiceAssignmentService {
  baseUrl = environment.baseUrl + "MasterServiceAssignment/";
  //baseUrl = "https://localhost:44344/api/v1/MasterServiceAssignment/";
  baseUrlPortal = environment.onlineBackendBaseUrl_;
  //baseUrlPortal = "https://localhost:44353/api/";
  _baseUrl = environment.gmaBaseUrl + "Employment/";
  constructor(private httpClient: HttpClient) {}
  //======
  allServiceAssignments(): Observable<CommonResponse> {
    return this.httpClient.get<CommonResponse>(
      this.baseUrl + "GetAllServiceAssignmentMasters"
    );
  }
  allServiceAssignmentsForDashboard(): Observable<CommonResponse> {
    return this.httpClient.get<CommonResponse>(
      this.baseUrl + "GetAllServiceAssignmentMastersForDashboard"
    );
  }

  allExpiredServiceAssignments(): Observable<CommonResponse> {
    return this.httpClient.get<CommonResponse>(
      this.baseUrl + "GetAllExpiredServiceAssignments"
    );
  }
  allOngoingTripsToBeEnded(): Observable<CommonResponse> {
    return this.httpClient.get<CommonResponse>(
      this.baseUrl + "GetAllOngoingTrips"
    );
  }
  allAssignedOrServiceAssignmentsWithResources(): Observable<CommonResponse> {
    return this.httpClient.get<CommonResponse>(
      this.baseUrl + "GetAllAssignedOrServiceAssignmentsWithResources"
    );
  }
  allllAssignedWithResourcesComingFromOnlineAndBookedNow(): Observable<CommonResponse> {
    return this.httpClient.get<CommonResponse>(
      this.baseUrl + "GetAllAssignedWithResourcesComingFromOnlineAndBookedNow"
    );
  }
  allScheduledServiceAssignments(): Observable<CommonResponse> {
    return this.httpClient.get<CommonResponse>(
      this.baseUrl + "GetAllScheduledServiceAssignmentMasters"
    );
    //return this.http.get<Approval[]>(this.baseUrl + 'GetUserPendingApprovals');
  }
  allExpiredScheduledServiceAssignments(): Observable<CommonResponse> {
    return this.httpClient.get<CommonResponse>(
      this.baseUrl + "GetAllExpiredScheduledServiceAssignmentMasters"
    );
  }
  allDueScheduledServiceAssignments(): Observable<CommonResponse> {
    return this.httpClient.get<CommonResponse>(
      this.baseUrl + "GetAllDueScheduledServiceAssignmentMasters"
    );
  }
  allNotDueScheduledServiceAssignments(): Observable<CommonResponse> {
    return this.httpClient.get<CommonResponse>(
      this.baseUrl + "GetAllNotDueScheduledServiceAssignmentMasters"
    );
  }

  getServiceAssignment(id: any): Observable<CommonResponse> {
    return this.httpClient.get<CommonResponse>(
      this.baseUrl + "GetServiceAssignmentMasterById/" + id
    );
  }
  getServiceAssignmentByPrimaryTripId(id: any): Observable<CommonResponse> {
    return this.httpClient.get<CommonResponse>(
      this.baseUrl + "GetMasterServiceAssignmentByPrimaryTripAssignId/" + id
    );
  }
  //AllocateResourceForScheduledServiceAssignmentByAssignmentId
  allocateResourceForScheduledAssignmentByAssignmentId(
    id: any
  ): Observable<CommonResponse> {
    return this.httpClient.get<CommonResponse>(
      this.baseUrl +
        "AllocateResourceForScheduledServiceForCronJobOrInHouseByAssignmentId/" +
        id
    );
  }
  allocateResourceForScheduledServiceForCronJobOrInHouseByAssignmentId(
    id: any
  ): Observable<CommonResponse> {
    return this.httpClient.get<CommonResponse>(
      this.baseUrlPortal +
        "SMSContract/AllocateResourceForScheduledServiceForCronJobOrInHouseByAssignmentId/" +
        id
    );
  }

  getJMPDetails(id: any): Observable<CommonResponse> {
    return this.httpClient.get<CommonResponse>(
      this.baseUrl + "GetJMPDetails/" + id
    );
  }

  postServiceAssignment(data: any) {
    return this.httpClient.post(
      this.baseUrl + "AddNewServiceAssignmentMaster",
      data
    );
  }

  postAutoServiceAssignment(data: any) {
    return this.httpClient.post(
      this.baseUrl + "AddNewAutoServiceAssignmentMaster",
      data
    );
  }

  deleteServiceAssignment(id: any) {
    return this.httpClient.delete(
      this.baseUrl + "DeleteServiceAssignmentMasterById/" + id
    );
  }

  deleteServiceAssignmentSchedule(id: any) {
    return this.httpClient.delete(
      this.baseUrl + "DeleteServiceAssignmentMasterScheduleById/" + id
    );
  }
  updateServiceAssignment(id: any, data: any) {
    return this.httpClient.put(
      this.baseUrl + "UpdateServiceAssignmentMasterById/" + id,
      data
    );
  }

  sendJMP(assId: number): Observable<CommonResponse> {
    return this.httpClient.get<CommonResponse>(
      this.baseUrl + "SendJMP/" + assId
    );
  }

  allCustomerDivision(): Observable<CommonResponse> {
    return this.httpClient.get<CommonResponse>(
      this.baseUrl + "GetAllCustomerDivisions"
    );
  }

  allCustomerDivision_(): Observable<CommonResponse> {
    return this.httpClient.get<CommonResponse>(
      this.baseUrl + "GetAllCustomerDivisions_"
    );
  }

  GetStartAddressGeocodingLocation(
    pickOffAddress: string
  ): Observable<GeocodingGetAddressCordinateVM> {
    return this.httpClient.get<GeocodingGetAddressCordinateVM>(
      this._baseUrl + "GetAddressGeocodingLocation/" + pickOffAddress
    );
  }
  GetDropAddressGeocodingLocation(
    dropOffAddress: string
  ): Observable<GeocodingGetAddressCordinateVM> {
    return this.httpClient.get<GeocodingGetAddressCordinateVM>(
      this._baseUrl + "GetAddressGeocodingLocation/" + dropOffAddress
    );
  }

  statusDashboadDetail(): Observable<CommonResponse> {
    return this.httpClient.get<CommonResponse>(
      this.baseUrl + "StatusDashboardDetails"
    );
  }
}
