import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { ConfirmationService, MessageService } from "primeng/api";
import { DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";
import { LeadDivision } from "src/app/interfaces/home";
import { QuoteService } from "src/app/services/quote.service";

@Component({
  selector: "app-view-quote",
  templateUrl: "./view-quote.component.html",
  styleUrls: ["./view-quote.component.scss"],
})
export class ViewQuoteComponent implements OnInit {
  fetchingQuoteDetails = false;
  quote: any;
  generalServices: any[];
  totalCost: number = 0.0;
  totalContractCost: number = 0.0;
  totalDiscount: number = 0.0;
  totalContractDiscount: number = 0.0;
  totalVAT: number = 0.0;
  totalContractVAT: number = 0.0;
  private messageBody: string;
  sendingEmail: boolean;
  leadDivision: LeadDivision;
  currentDate: Date = new Date();

  constructor(
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    public modalConfig: DynamicDialogConfig,
    private viewQuoteModalRef: DynamicDialogRef,
    private quoteService: QuoteService
  ) {}

  ngOnInit(): void {
    if (this.modalConfig.data.quote) {
      this.quote = this.modalConfig.data.quote;
    } else if (this.modalConfig.data.quoteId) {
      this.fetchQuoteDetails(this.modalConfig.data.quoteId);
    }

    if (this.modalConfig.data.generalServices) {
      this.generalServices = this.modalConfig.data.generalServices;
      this.generalServices.forEach((generalService) => {
        this.totalCost +=
          generalService.quoteService.quantity *
          generalService.quoteService.unitPrice;
        this.totalDiscount += generalService.quoteService?.discount ?? 0;
        this.totalVAT += generalService.quoteService?.vat ?? 0;

        const d1 = new Date(generalService.quoteService.contractStartDate);
        const d2 = new Date(generalService.quoteService.contractEndDate);
        generalService.duration =
          (d2.getFullYear() - d1.getFullYear()) * 12 -
          d1.getMonth() +
          d2.getMonth() +
          1;

        generalService.fullContractValue =
          generalService.duration *
          (generalService.quoteService.quantity *
            generalService.quoteService.unitPrice);

        this.totalContractCost += generalService.fullContractValue;
        this.totalContractDiscount +=
          generalService.duration * generalService.quoteService.discount;
        this.totalContractVAT +=
          generalService.duration * generalService.quoteService.vat;
      });
    }

    if (this.modalConfig.data.leadDivision) {
      this.leadDivision = this.modalConfig.data.leadDivision;
    }
  }

  fetchQuoteDetails(quoteId) {}

  printPage() {
    window.print();
  }

  confirmSendEmail(quoteId: number) {
    this.viewQuoteModalRef.close();
    this.confirmationService.confirm({
      message: "Are you sure you want to send this quote ?",
      header: "Confirmation",
      icon: "pi pi-exclamation-triangle",
      accept: () => {
        this.sendQuote(quoteId);
      },
      reject: (type) => {
        this.messageService.add({
          severity: "error",
          summary: "Rejected",
          detail: "Declined Sending Quote",
        });
      },
    });
  }

  sendQuote(quoteId?: number) {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Sending Quote",
    });
    this.sendMailV2();
  }

  sendMail() {
    let generalServicesTemp = "";

    for (const generalService of this.generalServices) {
      generalServicesTemp += `
      <tr>
          <td style="border: 1px solid #dddddd; text-align: left; padding: 8px; min-width: 10rem">
              ${generalService?.service?.name}
          </td>
          <td style="border: 1px solid #dddddd; text-align: center; padding: 8px;" class="others">${
            generalService?.quoteService?.quantity
          }</td>
          <td style="border: 1px solid #dddddd; text-align: center; padding: 8px;" class="others">${
            generalService?.quoteService?.unitPrice
          }</td>
          <td style="border: 1px solid #dddddd; text-align: center; padding: 8px;" class="others">${
            generalService?.quoteService?.quantity *
            generalService?.quoteService?.unitPrice
          }</td>
      </tr>
      `;
    }

    const innerBody = `
    <div style="width: 595px; margin: auto;">
        <div style="display: flex; justify-content: space-between;">
            <div class="logo">
            <img style=" width: 6rem; height: auto"  src="https://halobiz.herokuapp.com/assets/layout/images/HALOGENLOGO.png" alt="">
            </div>
            <h2 style="font-size: 2rem;">
                Quote
            </h2>
        </div>
        <section style="display: flex; flex-flow: row wrap; justify-content: space-between">
            <p>Halogen Security Company<br>Phone: +234-1-3429012, +234-1-3429021<br>
                info@halogen-group.com<br>
                19B, Mobolaji Bank Anthony Way, <br> Ikeja, Lagos
            </p>
        </section>
        
        <table style="font-size: 0.8rem; font-family: arial, sans-serif; border-collapse: collapse;  width: 100%; margin: 2rem 0; border: solid 0.5px rgba(0, 0, 0, 0.2)">
            <tr>
                <th style="min-width: 70% ; background-color: #dddddd; border: 1px solid #dddddd; text-align: left;padding: 8px;">
                    Description</th>
                <th style="background-color: #dddddd;border: 1px solid #dddddd;  text-align: center">Quantity</th>
                <th style="background-color: #dddddd;border: 1px solid #dddddd; text-align: center">Price</th>
                <th style="background-color: #dddddd;border: 1px solid #dddddd; text-align: center">Amount</th>
            </tr>
        
            ${generalServicesTemp}

            <tr>
                <td></td>
                <td></td>
                <td style="border: 1px solid #dddddd; text-align: center; padding: 8px;">Subtotal</td>
                <td style="border: 1px solid #dddddd; text-align: center; padding: 8px;">${
                  this.totalCost
                }</td>
            </tr>

            <tr>
                <td></td>
                <td></td>
                <td style="border: 1px solid #dddddd; text-align: center; padding: 8px;">Discount</td>
                <td style="border: 1px solid #dddddd; text-align: center; padding: 8px;">${
                  this.totalDiscount
                }</td>
            </tr>

            <tr>
                <td></td>
                <td></td>
                <td style="border: 1px solid #dddddd; text-align: center; padding: 8px;">VAT</td>
                <td style="border: 1px solid #dddddd; text-align: center; padding: 8px;">${
                  this.totalVAT
                }</td>
            </tr>
        
            <tr>
                <td></td>
                <td></td>
                <td style="border: 1px solid #dddddd; text-align: center; padding: 8px; font-weight: 700;">Total</td>
                <td style="border: 1px solid #dddddd; text-align: center; padding: 8px; font-weight: 700;">${
                  this.totalCost - this.totalDiscount + this.totalVAT
                }</td>
            </tr>
            
            <tr>
                <td colspan="3" style="border: 1px solid #dddddd; text-align: center; padding: 8px; font-weight: 700;">
                    Payable amount for this quote
                </td>
                <td style="border: 1px solid #dddddd; text-align: center; padding: 8px; font-weight: 700;">
                    ${this.totalCost - this.totalDiscount + this.totalVAT}
                </td>
            </tr>

        </table>
    </div>
    `;

    this.messageBody = `<!DOCTYPE html>
    <html>
      <head>
          <meta charset="utf-8">
          <meta name="viewport" content="width=device-width, initial-scale=1.0, shrink-to-fit=no">
          <title>Quote</title>
          <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/twitter-bootstrap/4.5.2/css/bootstrap.min.css">
          <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css">
          <style type="text/css">
          </style>
      </head>
      <body>

        ${innerBody}

      <script src="https://cdnjs.cloudflare.com/ajax/libs/jquery/3.5.1/jquery.min.js"></script>
      <script src="https://cdnjs.cloudflare.com/ajax/libs/twitter-bootstrap/4.5.2/js/bootstrap.bundle.min.js"></script>
      </body>
    </html>`;
    if (this.leadDivision?.email) {
      let recipients = [this.leadDivision?.email];
      if (this.leadDivision?.primaryContact?.email) {
        recipients = [...recipients, this.leadDivision?.primaryContact?.email];
      }
      const mailMessage = {
        recipients,
        message: this.messageBody,
        subject: "Quote",
      };
      this.sendingEmail = true;
      this.quoteService.sendEmailToClient(mailMessage).subscribe(
        (res) => {
          this.messageService.add({
            detail: "Message sent to the client",
            summary: "Request Complete",
            severity: "success",
          });
          this.sendingEmail = false;
        },
        (error) => {
          this.connectionError();
          this.sendingEmail = false;
        }
      );
    } else {
      this.messageService.add({
        severity: "error",
        summary: "Failed",
        detail: "The selected lead division does not have an email",
      });
    }
  }

  sendMailV2() {
    let generalServicesTemp = "";

    for (const generalService of this.generalServices) {
      generalServicesTemp += `
      <tr>
        <td style="border: 1px solid #dddddd; text-align: center; padding: 8px;">${
          generalService?.service?.name
        }</td>
        <td style="border: 1px solid #dddddd; text-align: center; padding: 8px;">${
          generalService?.quoteService?.uniqueTag
        }</td>
        <td style="border: 1px solid #dddddd; text-align: center; padding: 8px;">${
          generalService?.quoteService?.quantity
        }</td>
        <td style="border: 1px solid #dddddd; text-align: center; padding: 8px;">${
          generalService?.duration
        }</td>
        <td style="border: 1px solid #dddddd; text-align: center; padding: 8px;">${
          generalService?.quoteService?.unitPrice
        }</td>
        <td style="border: 1px solid #dddddd; text-align: center; padding: 8px;">${
          generalService?.quoteService?.quantity *
          generalService?.quoteService?.unitPrice
        }</td>
        <td style="border: 1px solid #dddddd; text-align: center; padding: 8px;">${
          generalService?.fullContractValue
        }</td>
      </tr>
      `;
    }

    const innerBody = `
    <div style="border: black 2px solid; border-radius: 5px; width: 850px; margin: auto">
        <div style="background-color: rgb(255, 255, 255); margin: 15px;">
        
          <table style="width: 100%;">
            <tr>
                <td>
                    <div style="margin: 0px;">
                        <img src="https://halobiz.herokuapp.com/assets/layout/images/HALOGENLOGO.png" height="150px"/>
                    </div>
                    <div>Enabling Security In An Open World</div>
                    <div>RC 199684</div>
                </td>
                <td>
                    <div style="margin: 0px 8px; float: right;">
                        <h3>QUOTATION</h3>
                        <p>DATE: ${this.currentDate.toDateString()}</p>
                        <p>Quotation# : #${this.quote?.id}</p>
                        <p>Prospect# : #${this.leadDivision.id}</p>
                    </div>
                </td>
            </tr>
          </table>
      
          <hr>
      
          <div style="margin-bottom: 30px;">
              <h4>
                  HALOGEN SECURITY COMPANY
              </h4>
              <p><i class="pi pi-mobile"></i> (Phone) +234-1-3429012, +234-1-3429021</p>
              <p><i class="pi pi-envelope"></i> (Email) info@halogen-group.com</p>
              <p><i class="pi pi-map-marker"></i> (Address) 19B, Mobolaji Bank Anthony Way Ikeja, Lagos</p>
          </div>
      
          <table style="width: 100%; margin-bottom: 15px;">
            <tr>
                <td>
                    <div>
                        <h4>Quotation For :</h4>
                        <p><i class="pi pi-user"></i> (Client) ${
                          this.leadDivision.divisionName
                        }</p>
                        <p><i class="pi pi-map-marker"></i> (Address) ${
                          this.leadDivision.address
                        }</p>
                    </div>
                </td>
                <td>
                    <div style="border: black 1px solid; width: 400px; border-radius: 2px; float: right;">
                        <div style="border-bottom:black 1px solid;text-align: center;">
                            <h5>TERMS AND CONDITIONS</h5>
                        </div>    
                        <p>1. Customers will be billed after indicating acceptance by way of award letter or go ahead notice</p>
                        <p>2. Quote is valid for a minimum of 2 weeks or until a subsequent quote is issued</p>
                    </div>
                </td>
            </tr>
          </table>
      
          <div style="border: black 1px solid; padding: 10px 10px; border-radius: 5px;">
              <h4 style="margin-left: 10px;">Quotation details :</h4>
              <table style="font-size: 0.8rem; font-family: arial, sans-serif; border-collapse: collapse; width: 100%; border: solid 0.5px rgba(0, 0, 0, 0.2)">
                  <tr>
                      <th style="background-color: #dddddd;border: 1px solid #dddddd; text-align: center; padding: 8px;">Service</th>
                      <th style="background-color: #dddddd;border: 1px solid #dddddd; text-align: center; padding: 8px;">Tag</th>
                      <th style="background-color: #dddddd;border: 1px solid #dddddd; text-align: center; padding: 8px;">Quantity</th>
                      <th style="background-color: #dddddd;border: 1px solid #dddddd; text-align: center; padding: 8px;">Duration (months)</th>
                      <th style="background-color: #dddddd;border: 1px solid #dddddd; text-align: center; padding: 8px;">Unit Price</th>
                      <th style="background-color: #dddddd;border: 1px solid #dddddd; text-align: center; padding: 8px;">Total Periodic Price</th>
                      <th style="background-color: #dddddd;border: 1px solid #dddddd; text-align: center; padding: 8px;">Total Contract Price</th>
                  </tr>

                  ${generalServicesTemp}
                  
                  <tr>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td style="border: 1px solid #dddddd; text-align: center; padding: 8px; font-weight: 700;">Subtotal</td>
                      <td style="border: 1px solid #dddddd; text-align: center; padding: 8px; font-weight: 700;">${this.totalCost.toFixed(
                        2
                      )}</td>
                      <td style="border: 1px solid #dddddd; text-align: center; padding: 8px; font-weight: 700;">${this.totalContractCost.toFixed(
                        2
                      )}</td>
                  </tr>
                  <tr>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td style="border: 1px solid #dddddd; text-align: center; padding: 8px; font-weight: 700;">Discount</td>
                      <td style="border: 1px solid #dddddd; text-align: center; padding: 8px; font-weight: 700;">${this.totalDiscount.toFixed(
                        2
                      )}</td>
                      <td style="border: 1px solid #dddddd; text-align: center; padding: 8px; font-weight: 700;">${this.totalContractDiscount.toFixed(
                        2
                      )}</td>
                  </tr>
                  <tr>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td style="border: 1px solid #dddddd; text-align: center; padding: 8px; font-weight: 700;">VAT</td>
                      <td style="border: 1px solid #dddddd; text-align: center; padding: 8px; font-weight: 700;">${this.totalVAT.toFixed(
                        2
                      )}</td>
                      <td style="border: 1px solid #dddddd; text-align: center; padding: 8px; font-weight: 700;">${this.totalContractVAT.toFixed(
                        2
                      )}</td>
                  </tr>
                  <tr>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td style="border: 1px solid #dddddd; text-align: center; padding: 8px; font-weight: 700;">Total</td>
                      <td style="border: 1px solid #dddddd; text-align: center; padding: 8px; font-weight: 700;">${(
                        this.totalCost -
                        this.totalDiscount +
                        this.totalVAT
                      ).toFixed(2)}</td>
                      <td style="border: 1px solid #dddddd; text-align: center; padding: 8px; font-weight: 700;">${(
                        this.totalContractCost -
                        this.totalContractDiscount +
                        this.totalContractVAT
                      ).toFixed(2)}</td>
                  </tr>
                  <tr>
                      <td colspan="4" style="text-align: center; border: 1px solid #dddddd; text-align: center; padding: 8px; font-weight: 700;">
                          Payable amount for this quote
                      </td>
                      <td style="border: 1px solid #dddddd; text-align: center; padding: 8px; font-weight: 700;">
                          ${(
                            this.totalCost -
                            this.totalDiscount +
                            this.totalVAT
                          ).toFixed(2)}
                      </td>
                  </tr>
              </table>
          </div>
      
          <hr>
      
          <table style="width: 100%;">
            <tr>
                <td>
                    <div>
                        <p><i class="pi pi-twitter"></i> (Twitter) @HalogenNG</p>
                        <p><i class="pi pi-facebook"></i> (Facebook) <a href="https://www.facebook.com/halogenNGR" target="_blank">https://www.facebook.com/halogenNGR</a></p>
                        <p> (LinkedIn) <a href="https://www.linkedin.com/in/halogen-group-01" target="_blank">https://www.linkedin.com/in/halogen-group-01</a></p>
                        <p> (Instagram) <a href="https://www.instagram.com/halogengrp" target="_blank">https://www.instagram.com/halogengrp</a></p>
                    </div>
                </td>
                <td>
                    <div style="border: black 0px solid; float: right;">
                      <img src="https://halobiz.herokuapp.com/assets/layout/images/ISOLOGO.jpg" height="100px" width="100px">
                    </div>
                </td>
            </tr>
          </table>
        
        </div>
    </div>
    `;

    this.messageBody = `<!DOCTYPE html>
    <html>
      <head>
          <meta charset="utf-8">
          <meta name="viewport" content="width=device-width, initial-scale=1.0, shrink-to-fit=no">
          <title>Quote</title>
          <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/twitter-bootstrap/4.5.2/css/bootstrap.min.css">
          <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css">
          <style type="text/css">
          </style>
      </head>
      <body>

        ${innerBody}

      <script src="https://cdnjs.cloudflare.com/ajax/libs/jquery/3.5.1/jquery.min.js"></script>
      <script src="https://cdnjs.cloudflare.com/ajax/libs/twitter-bootstrap/4.5.2/js/bootstrap.bundle.min.js"></script>
      </body>
    </html>`;

    if (this.leadDivision?.email) {
      let recipients = [this.leadDivision?.email];
      if (this.leadDivision?.primaryContact?.email) {
        recipients = [...recipients, this.leadDivision?.primaryContact?.email];
      }
      const mailMessage = {
        recipients,
        message: this.messageBody,
        subject: "Quote",
      };
      this.sendingEmail = true;
      this.quoteService.sendEmailToClient(mailMessage).subscribe(
        (res) => {
          this.messageService.add({
            detail: "Message sent to the client",
            summary: "Request Complete",
            severity: "success",
          });
          this.sendingEmail = false;
        },
        (error) => {
          this.connectionError();
          this.sendingEmail = false;
        }
      );
    } else {
      this.messageService.add({
        severity: "error",
        summary: "Failed",
        detail: "The selected lead division does not have an email",
      });
    }
  }

  private connectionError() {
    this.messageService.add({
      severity: "error",
      summary: "Failed",
      detail: "Connection Error, Please try again",
    });
  }
}
