import {
  Beat,
  BeatType,
  CreateNewBeat,
  UpdateBeat,
} from "./../../../interfaces/employment";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { Location } from "./../../../interfaces/home";
import { CustomerDivision } from "src/app/interfaces/home";
import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { MessageService, ConfirmationService } from "primeng/api";
import { BreadcrumbService } from "src/app/breadcrumb.service";
import { GmaEmploymentService } from "src/app/services/gma-employment.service";

@Component({
  selector: "app-beat-creation",
  templateUrl: "./beat-creation.component.html",
  styleUrls: ["./beat-creation.component.scss"],
})
export class BeatCreationComponent implements OnInit {
  @ViewChild("formWrapper") public formWrapper: ElementRef;
  createLocationBeatForm: FormGroup;
  allClients: CustomerDivision[] = [];
  theClient: CustomerDivision;
  allClientLocations: Location[] = [];
  selectedClientLocation: Location;
  fetchingLocationBeats: boolean = false;
  allSelectedLocationBeats: Beat[] = [];
  selectedLocationBeat: Beat;
  locBeatsCols: any[];
  allBeatTypes: BeatType[] = [];
  theBeatType: BeatType;
  editingLocationBeat: boolean = false;
  showAddNewBeatForm: boolean = false;
  beatToEdit: Beat;

  constructor(
    private fb: FormBuilder,
    public employmentService: GmaEmploymentService,
    public messageService: MessageService,
    private breadcrumbService: BreadcrumbService,
    public confirmationService: ConfirmationService
  ) {
    this.createLocationBeatForm = fb.group({
      Caption: ["", Validators.required],
      Alias: ["", Validators.required],
      Description: ["", Validators.required],
      BeatType: ["", Validators.required],
    });
  }

  ngOnInit(): void {
    this.breadcrumbService.setItems([
      {
        label: "Client Management",
        routerLink: ["/home/client-management"],
      },
      {
        label: "Approval",
        routerLink: ["/home/client-management/beat-creation"],
      },
    ]);

    this.locBeatsCols = [
      { field: "caption", header: "Caption" },
      { field: "description", header: "Descrition" },
      { field: "alias", header: "Alias" },
    ];

    this.FetchAllClients();
    this.FetchAllBeatTypes();
  }

  FetchAllClients() {
    this.employmentService.GetAllBeatsClients().subscribe(
      async (data) => {
        this.allClients = data;        
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all clients the moment.. Reason: [" +
            error.error.message +
            "]",
        });
      }
    );
  }

  FetchAllBeatTypes() {
    this.employmentService.GetAllBeatTypes().subscribe(
      async (data) => {
        this.allBeatTypes = data;       
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all beat type at the moment.. Reason: [" +
            error.error.message +
            "]",
        });
      }
    );
  }

  LoadClientLocations() {
    this.allClientLocations = this.theClient.locations;
    this.allSelectedLocationBeats = [];
  }

  CreatLocationBeat() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Creating Location Beat...",
    });

    const postData: CreateNewBeat = {
      caption: this.createLocationBeatForm.get("Caption").value,
      description: this.createLocationBeatForm.get("Description").value,
      alias: this.createLocationBeatForm.get("Alias").value,
      locationId: this.selectedClientLocation.id,
      beatTypeId: this.theBeatType.id,
    };

    this.employmentService.CreateNewBeat(postData).subscribe(
      async () => {
        await this.messageService.add({
          severity: "success",
          summary: "Completed",
          detail: "Beat Type Created Successfully...",
        });

        this.theBeatType = null;
        this.showAddNewBeatForm = false;
        this.createLocationBeatForm.reset();
        this.allSelectedLocationBeats.push({
          caption: postData.caption,
          alias: postData.alias,
          description: postData.description,
          beatTypeId: postData.beatTypeId,
          locationId: postData.locationId,
        });
        this.FetchAllClients();
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to create beat at the moment.. Reason: [" +
            error.error.message +
            "]",
        });
      }
    );
  }

  CloseEditingLocationBeat() {
    this.createLocationBeatForm.reset();
    this.theBeatType = null;
    this.showAddNewBeatForm = false;
  }

  SetSelectedClientLocation(item: Location) {
    this.selectedClientLocation = item;
    this.allSelectedLocationBeats = item.beats;
  }

  AddNewLocationBeat() {
    if (this.selectedClientLocation) {
      this.showAddNewBeatForm = true;
    }
  }

  DeleteLocationBeat(item: Beat) {
    this.confirmationService.confirm({
      message: "Are you sure you want to remove beat?",
      accept: () => {
        this.messageService.add({
          severity: "info",
          summary: "Notice",
          detail: "Removing beat...",
        });

        this.employmentService.DeleteBeat(item.id).subscribe(
          async () => {
            await this.messageService.add({
              severity: "success",
              summary: "Removed",
              detail: "Removed successfully",
            });

            const index = this.allSelectedLocationBeats.indexOf(item);
            if (index > -1) {
              this.allSelectedLocationBeats.splice(index, 1);
            }
            this.FetchAllClients();
          },
          (error) => {
            console.log("Error: " + JSON.stringify(error));
            this.messageService.add({
              severity: "error",
              summary: "Notice",
              detail:
                "Unable to remove beat at the moment.. Reason: [" +
                error.error.message +
                "]",
            });
          }
        );
      },
    });
  }

  EditLocationBeat(item: Beat) {
    this.editingLocationBeat = true;
    this.createLocationBeatForm.addControl(
      "ID",
      new FormControl({ value: "", disabled: true }, Validators.required)
    );
    this.beatToEdit = item;
    this.createLocationBeatForm.patchValue({
      ID: item.id,
      Caption: item.caption,
      Description: item.description,
      Alias: item.alias,
    });
    this.theBeatType = this.allBeatTypes.find((x) => x.id == item.beatTypeId);
    this.showAddNewBeatForm = true;
  }

  UpdateBeat() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Updating Beat...",
    });

    const id = this.beatToEdit.id;
    const postData: UpdateBeat = {
      caption: this.createLocationBeatForm.get("Caption").value,
      description: this.createLocationBeatForm.get("Description").value,
      alias: this.createLocationBeatForm.get("Alias").value,
      locationId: this.selectedClientLocation.id,
      beatTypeId: this.theBeatType.id,
    };

    this.employmentService.UpdateBeat(id, postData).subscribe(
      async () => {
        this.messageService.add({
          severity: "success",
          summary: "Notice",
          detail: "Update Successful!",
        });

        this.CloseEditingLocationBeat();
        this.FetchAllClients();
        this.theClient = null;
        this.allSelectedLocationBeats = [];
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to update beat at the moment.. Reason: [" +
            error.error.message +
            "]",
        });
      }
    );
  }
}
