import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { ConfirmationService, MessageService, TreeNode } from "primeng/api";
import {
  Account,
  AdminDirectRelationships,
  CommonResponse,
  ControlAccount,
  OperatingEntity,
  RequiredServiceDocument,
  RequiredServiceQualElement,
  ServiceCategory,
  ServiceRelationshipEnum,
  Services,
  ServiceType,
  Target,
} from "../../../interfaces/home";
import { ServicesService } from "../../../services/services.service";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { EventInput } from "@fullcalendar/core";
import { RequiredServiceDocumentService } from "../../../services/required-service-document.service";
import { TargetService } from "../../../services/target.service";
import { RequiredServiceQualElementService } from "../../../services/required-service-qual-element.service";
import { AccountService } from "../../../services/account.service";
import { ServiceTypeService } from "../../../services/service-type.service";
import { ServiceCategoryService } from "../../../services/service-category.service";
import { DivisionService } from "../../../services/division.service";
import { environment } from "../../../../environments/environment";
import { map } from "rxjs/operators";
import { BreadcrumbService } from "src/app/breadcrumb.service";
import { ControlAccountService } from "src/app/services/control-account.service";
import { DialogService, DynamicDialogRef } from "primeng/dynamicdialog";
import { ServiceFormComponent } from "./service-form/service-form.component";
import { Table } from "primeng/table";

@Component({
  selector: "app-service",
  templateUrl: "./service.component.html",
  styleUrls: ["./service.component.scss"],
  providers: [MessageService, ConfirmationService, DialogService],
})
export class ServiceComponent implements OnInit {
  @ViewChild(Table) servicesTable: Table;

  serviceColumn: any;
  operatingEntities: OperatingEntity[] = [];
  selectedServices: Services[] = [];
  services: Services[] = [];
  servicesAll: Services[] = [];
  unmappedDirectServices: Services[] = [];
  allAdminDirectRelationshp: AdminDirectRelationships[] = [];
  relationshipsToUse: any[] = [];
  selectedAdminService: Services = null;
  loadingServices = true;
  loadingDivision = true;
  public exportServiceColumns: any;
  editingServices = false;
  serviceTypes: ServiceType[];
  selectedServiceType?: ServiceType;
  selectedServiceElements: any;
  serviceElements: any;
  requiredServiceDocuments: RequiredServiceDocument[] = [];
  selectedRequiredServiceDocument: RequiredServiceDocument[] = [];
  serviceTree: TreeNode[];
  serviceTreeChildren: TreeNode[] = [];
  serviceRelationships: any;
  isSubmitted = false;
  public selectedServiceCategory: ServiceCategory;
  allServiceCategories: ServiceCategory[] = [];
  targets: Target[] = [];
  selectedTarget?: Target;
  requiredServiceFields: RequiredServiceQualElement[] = [];
  serviceRequiredField: RequiredServiceQualElement[] = [];
  selectedRequiredServiceFields: RequiredServiceQualElement[] = [];
  accounts: Account[] = [];
  controlAccounts: ControlAccount[] = [];
  selectedAccount: Account = null;
  editingService = false;
  private edService: Services;
  serviceImg: any;
  file: any;
  public uploadingNewPicture: boolean;
  public selectedOperatingEntity: OperatingEntity;
  public loadingEditServices: boolean;
  isSelectedViaTree: boolean = false;
  selectedServiceFields: any[] = [];
  selectedDocuments: any[] = [];
  serviceFormDialog: DynamicDialogRef;

  serviceAccounts: Account[] = [];
  DtrackIncomeAccounts: any[] = [];

  serviceAccountMapping: any[] = [];
  DtrackIncomeAccountMapping: any[] = [];

  selectedServiceAccountMapping: any;
  selectedIncomeAccountMapping: any;
  //DtrackIncomeAccountMapping: any;

  constructor(
    private messageService: MessageService,
    private formBuilder: FormBuilder,
    private confirmationService: ConfirmationService,
    private servicesService: ServicesService,
    private divisionService: DivisionService,
    private serviceTypeService: ServiceTypeService,
    private requiredServiceDocumentService: RequiredServiceDocumentService,
    private targetService: TargetService,
    private requiredServiceQualElementService: RequiredServiceQualElementService,
    private accountService: AccountService,
    private controlAccountService: ControlAccountService,
    private serviceCategoryService: ServiceCategoryService,
    private breadcrumbService: BreadcrumbService,
    public dialogService: DialogService,
    private incomeAccountService: ServicesService
  ) {
    this.serviceImg = environment.defaultServiceImage;
    this.editingService = false;
  }

  //get the controls of this with f('name')
  //   get (){
  //       return this.serviceForm.controls;
  //   }

  ngOnInit(): void {
    this.serviceRelationships = [
      { name: "No dependency", code: "1" },
      { name: "Direct Service", code: "2" },
      { name: "Admin Service", code: "3" },
    ];

    this.breadcrumbService.setItems([
      { label: "Setup", routerLink: ["home/service"] },
      { label: "Services", routerLink: ["home/service"] },
      { label: "Service", routerLink: ["home/service"] },
    ]);
    this.fetchServiceType();
    this.fetchService();
    this.fetchUnmappedDirectServices();
    this.fetchAdminDirectRelationships();
    this.fetchRequiredServiceDocument();
    this.fetchTarget();
    this.fetchRequiredField();
    // this.fetchAccount();
    this.fetchIncomeControlAccounts();
    this.fetchServiceAccount();
    this.fetchDtrackIncomeAccounts();
    this.fetchServiceAccountMapping();
    this.fetchIncomeAccountServiceMapping();

    // this.fetchDtrackIncomeAccounts();

    this.serviceColumn = [
      { field: "name", header: "Name" },
      { field: "createdAt", header: "created At" },
      { field: "name", header: "Name" },
      { field: "description", header: "Description" },
      { field: "unitPrice", header: "Unit Price" },
      { field: "isPublished", header: "Is Published" },
      { field: "alias", header: "Alias" },
    ];
    this.exportServiceColumns = this.serviceColumn.map((col) => ({
      title: col.header,
      dataKey: col.field,
    }));
    this.divisionService.allDivisionData().subscribe(
      async (r: CommonResponse) => {
        if (r.responseCode == "00") {
          var res = r.responseData;
          if (res.length > 0) {
            res.forEach((value, key) => {
              const operationEntities = value.operatingEntities;
              const operationEntityTree = [];
              if (operationEntities.length > 0) {
                operationEntities.forEach((valueA, keyA) => {
                  this.operatingEntities.push(valueA);
                  const serviceGroups = valueA.serviceGroups;
                  const serviceGroupsTree = [];
                  if (serviceGroups.length > 0) {
                    serviceGroups.forEach((value1, key2) => {
                      const groupCategory = value1.serviceCategories;
                      const serviceCategoriesTree = [];
                      if (groupCategory.length > 0) {
                        //add the groups here
                        this.allServiceCategories.push(...groupCategory);
                        groupCategory.forEach((valueC, keyC) => {
                          serviceCategoriesTree.push({
                            key: valueC.description,
                            label: valueC.name,
                            icon: "pi pi-fw pi-file",
                            data: valueC,
                            selectable: true,
                          });
                        });
                      }
                      serviceGroupsTree.push({
                        label: value1.name,
                        key: value1.description,
                        expandedIcon: "pi pi-minus-circle",
                        collapsedIcon: "pi pi-plus-circle",
                        leaf: false,
                        children: serviceCategoriesTree,
                      });
                    });
                  }
                  operationEntityTree.push({
                    label: valueA.name,
                    key: valueA.description,
                    expandedIcon: "pi pi-minus-circle",
                    collapsedIcon: "pi pi-plus-circle",
                    leaf: false,
                    children: serviceGroupsTree,
                  });
                });
              }
              this.serviceTreeChildren.push({
                label: value.name,
                key: value.description,
                expandedIcon: "pi pi-minus-circle",
                collapsedIcon: "pi pi-plus-circle",
                leaf: false,
                expanded: true,
                children: operationEntityTree,
              });
            });
          }
        }
        this.loadingDivision = false;
      },
      (error) => {
        this.loadingDivision = false;
        this.messageService.add({
          severity: "error",
          summary: "Failed",
          detail: "Connection failed",
        });
      }
    );
    this.serviceTree = [
      {
        label: "Halogen",
        key: "Storage Folder",
        expandedIcon: "pi pi-minus-circle",
        collapsedIcon: "pi pi-plus-circle",
        expanded: true,
        children: this.serviceTreeChildren,
      },
    ];
  }

  refreshServiceTable() {
    this.servicesTable.reset();
  }

  fetchService() {
    this.servicesService.allService().subscribe(async (res: CommonResponse) => {
      if (res?.responseCode == "00") {
        this.loadingServices = false;
        this.services = res.responseData;
        this.servicesAll = res.responseData;
      }
    });
  }

  fetchUnmappedDirectServices() {
    this.servicesService
      .getUnmappedDirectService()
      .subscribe(async (res: CommonResponse) => {
        this.unmappedDirectServices = res.responseData;
        this.relationshipsToUse = this.unmappedDirectServices;
      });
  }

  fetchAdminDirectRelationships() {
    this.servicesService
      .getAllRelationships()
      .subscribe(async (res: CommonResponse) => {
        this.allAdminDirectRelationshp = res.responseData;
      });
  }

  fetchRequiredServiceDocument() {
    this.requiredServiceDocumentService
      .allRequiredServiceDocument()
      .subscribe((res: CommonResponse) => {
        this.requiredServiceDocuments = res.responseData;
      });
  }

  fetchTarget() {
    this.targetService.allTarget().subscribe((res: CommonResponse) => {
      this.targets = res.responseData;
    });
  }

  fetchAccount() {
    this.accountService
      .getIncomeReceivableAccount()
      .subscribe((res: CommonResponse) => {
        this.accounts = res.responseData;
      });
  }

  fetchServiceAccount() {
    this.accountService
      .getAccountForService()
      .subscribe((res: CommonResponse) => {
        this.serviceAccounts = res.responseData;
      });
  }

  fetchDtrackIncomeAccounts() {
    this.accountService
      .getDtrackIncomeAccounts()
      .subscribe((res: CommonResponse) => {
        this.DtrackIncomeAccounts = res.responseData;
        //var p = res.responseCode;
      });
  }

  fetchServiceAccountMapping() {
    this.accountService
      .getServiceAccounts()
      .subscribe((res: CommonResponse) => {
        this.serviceAccountMapping = res.responseData ?? [];
      });
  }

  fetchIncomeAccountServiceMapping() {
    this.accountService
      .allIncomeAccountService()
      .subscribe((res: CommonResponse) => {
        this.DtrackIncomeAccountMapping = res.responseData ?? [];
      });
  }

  fetchIncomeControlAccounts() {
    this.controlAccountService
      .allIncomeControlAccounts()
      .subscribe(async (res: CommonResponse) => {
        this.controlAccounts = res.responseData;
      });
  }
  fetchRequiredField() {
    this.requiredServiceQualElementService
      .allData()
      .subscribe((res: CommonResponse) => {
        this.requiredServiceFields = res.responseData;
      });
  }
  fetchServiceType() {
    this.serviceTypeService.allData().subscribe((res: any) => {
      this.serviceTypes = res.responseData;
    });
  }
  showService(event) {
    if (event.node.data) {
      this.isSelectedViaTree = true;
      //this must be set first
      this.selectedServiceCategory = event.node.data;
      //then this next
      this.setServiceGroupAndDocument(this.selectedServiceCategory.id);
      this.fetchServiceByCategory();
    }
  }

  fetchServiceByCategory() {
    this.isSubmitted = false;
    this.editingService = false;
    this.services = this.servicesAll.filter(
      (x) => x.serviceCategoryId == this.selectedServiceCategory.id
    );
    this.refreshServiceTable();
  }

  deleteService(service) {
    this.confirmationService.confirm({
      message: "Are you sure you want to delete " + service.name,
      accept: () => {
        this._deleteService(service);
      },
    });
  }
  _deleteService(service) {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Deleting service record",
    });
    this.servicesService.deleteServices(service.id).subscribe(
      async (result: any) => {
        await this.messageService.add({
          severity: "success",
          summary: "Deleted",
          detail: "Service record removed",
        });
        await this.fetchService();
      },
      (error) => {
        this.connectionError();
      }
    );
  }

  private setServiceGroupAndDocument(serviceCategoryId: number) {
    this.serviceRequiredField = this.requiredServiceFields.filter(
      (x) => x.serviceCategoryId === serviceCategoryId
    );

    this.selectedOperatingEntity = this.operatingEntities.find(
      (oP) => oP.id === this.selectedServiceCategory.operatingEntityId
    );
    if (this.selectedOperatingEntity) {
      const _imageName = this.selectedOperatingEntity.name.toLowerCase();
      this.serviceImg =
        environment[_imageName.trim()] ?? environment.defaultServiceImage;
    }
  }

  async addService(event) {
    event.preventDefault();
    if (!this.selectedServiceCategory) {
      await this.messageService.add({
        severity: "error",
        summary: "Failure",
        detail: "Please select a category first",
      });
      return;
    }

    this.showServiceForm(null);
  }

  async editService(service) {
    this.selectedServiceAccountMapping = this.serviceAccountMapping.find(
      (x) => x.serviceId == service.id
    );

    this.selectedIncomeAccountMapping = this.DtrackIncomeAccountMapping.find(
      (x) => x.serviceId == service.id
    );
    this.edService = service;
    this.showServiceForm(service);
  }

  private showServiceForm(service?: Services) {
    this.resetPairedDirect();
    //select the required fields
    if (!this.isSelectedViaTree) {
      //this must be define first
      this.selectedServiceCategory = this.allServiceCategories.find(
        (x) => x.id == service.serviceCategoryId
      );
      //then this next
      this.setServiceGroupAndDocument(service.serviceCategoryId);
    }

    this.resetPairedDirect();

    var data = {
      serviceImg: this.serviceImg,
      service: service,
      relationshipsToUse: this.relationshipsToUse,
      selectedServiceCategory: this.selectedServiceCategory,
      allAdminDirectRelationshp: this.allAdminDirectRelationshp,
      controlAccounts: this.controlAccounts,
      serviceRequiredField: this.serviceRequiredField,
      requiredServiceDocuments: this.requiredServiceDocuments,
      targets: this.targets,
      serviceTypes: this.serviceTypes,
      serviceAccounts: this.serviceAccounts,
      selectedServiceAccountMapping: this.selectedServiceAccountMapping,
      selectedIncomeAccountMapping: this.selectedIncomeAccountMapping,
      DtrackIncomeAccounts: this.DtrackIncomeAccounts,
    };

    this.serviceFormDialog = this.dialogService.open(ServiceFormComponent, {
      header: service
        ? `Editing ${service.name}`
        : `Adding service to ${this.selectedServiceCategory?.description}`,
      width: "60%",
      contentStyle: { "min-height": "500px", overflow: "auto" },
      baseZIndex: 10000,
      data: data,
    });

    this.serviceFormDialog.onClose.subscribe(async (res: CommonResponse) => {
      if (!this.isSelectedViaTree) {
        this.selectedServiceCategory = null;
      }

      if (service) {
        if (res?.responseCode == "00") {
          this.messageService.add({
            severity: "success",
            summary: "Update Success",
            detail: "Success in updating service",
          });
          this.refreshKeyFetches();
        }
      } else {
        if (res?.responseCode == "00") {
          this.messageService.add({
            severity: "success",
            summary: "Create Success",
            detail: "Success in updating service",
          });
          this.refreshKeyFetches();
        }
      }
    });
  }

  private resetPairedDirect() {
    this.relationshipsToUse = this.unmappedDirectServices.filter(
      (x) => x.id > 0
    );
  }

  private async refreshKeyFetches() {
    this.fetchService();
    this.fetchAdminDirectRelationships();
    this.fetchUnmappedDirectServices();
    if (this.selectedServiceCategory) {
      this.fetchServiceByCategory();
    }
    this.fetchServiceAccountMapping();
    this.fetchIncomeAccountServiceMapping();
  }

  private connectionError() {
    this.messageService.add({
      severity: "error",
      summary: "Failed",
      detail: "Connection Error, Please try again",
    });
  }
}
