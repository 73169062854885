import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { AngularFireStorage } from "@angular/fire/storage";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ConfirmationService, MessageService, TreeNode } from "primeng/api";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { BreadcrumbService } from "src/app/breadcrumb.service";
import {
  ArmedEscortProfile,
  ArmedEscortResourceTie,
  CommanderProfile,
  CommanderResourceTie,
  PilotProfile,
  PilotResourceTie,
  SMORegion,
  SMORoute,
  VehicleProfile,
  VehicleResourceTie,
} from "src/app/interfaces/armada";
import { CommonResponse } from "src/app/interfaces/home";
import { ResourceRegistrationService } from "src/app/services/armada/resource-registration.service";
import { SMORouteService } from "src/app/services/armada/smoroute.service";
import { InventoryService } from "src/app/services/inventory.service";
import { UserService } from "src/app/services/user.service";

@Component({
  selector: "app-resource-tie",
  templateUrl: "./resource-tie.component.html",
  styleUrls: ["./resource-tie.component.scss"],
})
export class ResourceTieComponent implements OnInit {
  @ViewChild("formWrapper") public formWrapper: ElementRef;
  private unsubscriber$ = new Subject<void>();
  public targetCols: any;
  private exportTargetColumns: any;
  public submittingData: boolean;
  public loading: boolean;
  public submitButtonLabel: string;
  public submitVehicleButtonLabel: string;
  public submitCommanderButtonLabel: string;
  //ArmedEscort
  public armedEscortProfiles: ArmedEscortProfile[];
  selectedArmedEscortProfile: ArmedEscortProfile = null;
  public armedEscortTies: ArmedEscortResourceTie[];
  selectedArmedEscortTie: ArmedEscortResourceTie = null;
  public armedEscortRouteTies: ArmedEscortResourceTie[];
  selectedArmedEscortRouteTie: ArmedEscortResourceTie = null;
  public armedEscortTieForm: FormGroup;
  selectedSMORegion: SMORegion;
  selectedSMORoute: SMORoute;
  smoRegions: SMORegion[];
  smoRoutes: SMORoute[];
  availableSMORoutes: SMORoute[];
  public selectedMultiSMORoutes: SMORoute[] = [];
  availableMultiSMORoutes: SMORoute[] = [];
  public armedEscortUseForm: FormGroup;
  private edArmedEscortProfileTiw: ArmedEscortProfile;
  //public userProfiles: User[];
  //public selectedUserProfile: User = null;
  files1: TreeNode[];
  selectedFiles3: TreeNode;
  files3: TreeNode[];
  selectedRouteTree: TreeNode[];
  selectedCommanderRouteTree: TreeNode[];
  selectedPilotRouteTree: TreeNode[];
  selectedVehicleRouteTree: TreeNode[];
  loadingPriceRegisters: boolean;

  //private edArmedEscortRank: ArmedEscortRank;
  //Pilot
  public pilotProfiles: PilotProfile[];
  selectedPilotProfile: PilotProfile = null;
  public pilotTies: PilotResourceTie[];
  selectedPilotTie: PilotResourceTie = null;
  public pilotUseForm: FormGroup;
  public pilotTieForm: FormGroup;

  //Vehicle
  public vehicleProfiles: VehicleProfile[];
  selectedVehicleProfile: VehicleProfile = null;
  public vehicleTies: VehicleResourceTie[];
  selectedVehicleTie: VehicleResourceTie = null;
  public vehicleUseForm: FormGroup;
  public vehicleTieForm: FormGroup;

  //Commander
  public commanderProfiles: CommanderProfile[];
  selectedCommanderProfile: CommanderProfile = null;
  public commanderTies: CommanderResourceTie[];
  selectedCommanderTie: CommanderResourceTie = null;
  public commanderUseForm: FormGroup;
  public commanderTieForm: FormGroup;

  constructor(
    private breadcrumbService: BreadcrumbService,
    private smoRouteRegionService: SMORouteService,
    private resourceregistrationService: ResourceRegistrationService,
    private userService: UserService,

    public messageService: MessageService,
    public confirmationService: ConfirmationService,
    public fireStorage: AngularFireStorage,
    public inventoryService: InventoryService,
    //private constants: ArmadaConstant,
    private formBuilder: FormBuilder
  ) {
    this.breadcrumbService.setItems([
      { label: "Setup" },
      {
        label: "Registered Resource Tie",
        routerLink: ["/setup/resource-tie"],
      },
    ]);
  }

  ngOnInit(): void {
    this.loading = true;
    //Pilot
    this.targetCols = [
      { field: "resourceId", header: "resourceId" },
      { field: "smoRegionId", header: "smoRegionId" },
      { field: "smoRouteId", header: "smoRouteId" },
    ];
    this.exportTargetColumns = this.targetCols.map((col) => ({
      title: col.header,
      dataKey: col.field,
    }));
    this.pilotTieForm = this.formBuilder.group({
      resourceId: [{ value: null, disabled: true }, Validators.required],
      smoRegionId: ["", [Validators.required]],
      smoRouteId: ["", [Validators.required]],
    });
    this.pilotUseForm = this.formBuilder.group({
      pilotProfile: [""],
    });
    this.pilotUseForm.valueChanges
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(() => {
        this.selectedPilotProfile = this.pilotUseForm.get("pilotProfile").value;
      });
    //Armed Escort
    this.targetCols = [
      { field: "resourceId", header: "resourceId" },
      { field: "smoRegionId", header: "smoRegionId" },
      { field: "smoRouteId", header: "smoRouteId" },
    ];
    this.exportTargetColumns = this.targetCols.map((col) => ({
      title: col.header,
      dataKey: col.field,
    }));
    this.armedEscortTieForm = this.formBuilder.group({
      //resourceId: ["", [Validators.required]],
      resourceId: [{ value: null, disabled: true }, Validators.required],
      smoRegionId: ["", [Validators.required]],
      smoRouteId: ["", [Validators.required]],
    });
    this.armedEscortUseForm = this.formBuilder.group({
      //userProfile: [""],
      armedEscortProfile: [""],
    });
    this.armedEscortUseForm.valueChanges
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(() => {
        this.selectedArmedEscortProfile =
          this.armedEscortUseForm.get("armedEscortProfile").value;
      });

    //Commander
    this.targetCols = [
      { field: "resourceId", header: "resourceId" },
      { field: "smoRegionId", header: "smoRegionId" },
      { field: "smoRouteId", header: "smoRouteId" },
    ];
    this.exportTargetColumns = this.targetCols.map((col) => ({
      title: col.header,
      dataKey: col.field,
    }));
    this.commanderTieForm = this.formBuilder.group({
      //resourceId: ["", [Validators.required]],
      resourceId: [{ value: null, disabled: true }, Validators.required],
      smoRegionId: ["", [Validators.required]],
      smoRouteId: ["", [Validators.required]],
    });
    this.commanderUseForm = this.formBuilder.group({
      //userProfile: [""],
      commanderProfile: [""],
    });
    this.commanderUseForm.valueChanges
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(() => {
        this.selectedCommanderProfile =
          this.commanderUseForm.get("commanderProfile").value;
      });

    //Vehicle
    this.targetCols = [
      { field: "resourceId", header: "resourceId" },
      { field: "smoRegionId", header: "smoRegionId" },
      { field: "smoRouteId", header: "smoRouteId" },
    ];
    this.exportTargetColumns = this.targetCols.map((col) => ({
      title: col.header,
      dataKey: col.field,
    }));
    this.vehicleTieForm = this.formBuilder.group({
      resourceId: [{ value: null, disabled: true }, Validators.required],
      smoRegionId: ["", [Validators.required]],
      smoRouteId: ["", [Validators.required]],
    });
    this.vehicleUseForm = this.formBuilder.group({
      //userProfile: [""],
      vehicleProfile: [""],
    });
    this.vehicleUseForm.valueChanges
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(() => {
        this.selectedVehicleProfile =
          this.vehicleUseForm.get("vehicleProfile").value;
      });

    this.fetchAllRegions();
    //this.FetchAllSelectedRoutes();
    this.fetchAllVehicleProfile();
    this.fetchAllVehicleProfileTies();
    this.fetchAllArmedEscortProfile();
    this.fetchAllArmedEscortProfileTies();
    this.fetchAllCommanderProfileTies();
    this.fetchAllCommanderProfile();
    this.fetchAllPilotProfile();
    this.fetchAllPilotProfileTies();

    this.fetchAllRoutes();
    // this.smoRouteRegionService.getFiles().then((files) => {
    //   this.files3 = [
    //     {
    //       label: "Root",
    //       children: files,
    //     },
    //   ];
    // });

    this.submitButtonLabel = "Create";
  }

  //Routes and Region
  fetchAllRoutes() {
    this.smoRouteRegionService
      .allRoutes()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          var res = r.responseData;
          if (r.responseCode == "00") {
            this.smoRoutes = res;
            //this.smoReturnRoutes = res;
            //this.loading = false;
          }
        },
        (error) => {
          this.messageService.add({
            severity: "error",
            summary: "Failed",
            detail: "Connection Error,  " + error.error.message,
          });
          this.submittingData = false;
        }
      );
  }

  fetchAllRegions(): void {
    this.smoRouteRegionService
      .allRegions()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            var res = r.responseData;
            this.smoRegions = res;
            this.loading = false;
          } else {
            this.connectionError();
          }
        },
        (error) => {
          this.messageService.add({
            severity: "error",
            summary: "Failed",
            detail: "Connection Error,  " + error.error.message,
          });
          this.submittingData = false;
        }
      );
  }
  //Pilot
  fetchAllPilotProfile(): void {
    this.resourceregistrationService
      .allPilotProfile()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            var res = r.responseData;
            this.pilotProfiles = res;
            this.pilotProfiles.forEach(
              (userProfile) =>
                (userProfile.fullName = `${userProfile.firstname} ${userProfile.lastname}`)
            );
            this.loading = false;
          }
        },
        (error) => {
          this.loading = false;
          this.connectionError();
        }
      );
    this.submittingData = false;
  }
  fetchAllPilotProfileTies(): void {
    this.resourceregistrationService
      .allPilotProfileTies()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            var res = r.responseData;
            this.pilotTies = res;
            this.PopulatePilotRouteTree();
            this.pilotTies.forEach(
              (userProfile) =>
                (userProfile.fullName = `${userProfile.resource.firstname} ${userProfile.resource.lastname}`)
            );
            this.loading = false;
          }
        },
        (error) => {
          this.loading = false;
          this.connectionError();
        }
      );
    this.submittingData = false;
  }

  createPilotTie() {
    this.submittingData = true;
    const formData = this.pilotTieForm.value;
    const postData = {
      resourceId: this.selectedPilotProfile.id,
      smoRegionId: this.selectedSMORegion.id,
      smoRouteId: this.selectedMultiSMORoutes.map((x) => x.id),
      // rankId: this.selectedPilotRank.id,
    };
    this.resourceregistrationService
      .postPilotProfileTie(postData)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            this.messageService.add({
              summary: "Success",
              severity: "success",
              detail: "New Pilot SMO Route Tie(s) created",
            });
            //this.fetchAllRoutes();
            this.fetchAllPilotProfileTies();
            this.submittingData = false;
            this.pilotTieForm.reset();
            this.pilotUseForm.reset();
          } else {
            this.messageService.add({
              severity: "error",
              summary: "Failed",
              detail: " Error: " + r.responseMsg,
            });
            this.submittingData = false;
          }
        },
        (error) => {
          this.messageService.add({
            severity: "error",
            summary: "Failed",
            detail: "Connection Error " + error.error.message,
          });
          this.submittingData = false;
        }
      );
  }

  deletePilotTie(pilotResourceTie: PilotResourceTie): void {
    this.confirmationService.confirm({
      message:
        "Are you sure you want to delete " +
        pilotResourceTie.resource.firstname,
      accept: () => {
        this._deletePilotTie(pilotResourceTie);
      },
    });
  }

  _deletePilotTie(pilotResourceTie) {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Deleting Route record",
    });
    this.resourceregistrationService
      .deletePilotTie(pilotResourceTie.id)
      .subscribe(
        async (result: any) => {
          await this.messageService.add({
            severity: "success",
            summary: "Deleted",
            detail: "Route Tie record removed",
          });
          await this.fetchAllPilotProfileTies();
          this.pilotTieForm.reset();
          this.pilotUseForm.reset();
        },
        (error) => {
          this.connectionError();
        }
      );
  }

  //Armed Escort
  fetchAllArmedEscortProfile(): void {
    this.resourceregistrationService
      .allArmedEscortProfile()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            var res = r.responseData;
            this.armedEscortProfiles = res;
            this.armedEscortProfiles.forEach(
              (userProfile) =>
                (userProfile.fullName = `${userProfile.firstName} ${userProfile.lastName}`)
            );
            this.loading = false;
          }
        },
        (error) => {
          this.loading = false;
          this.connectionError();
        }
      );
    this.submittingData = false;
  }
  fetchAllArmedEscortProfileTies(): void {
    this.loading = true;
    this.resourceregistrationService
      .allArmedEscortProfileTie()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            var res = r.responseData;
            this.armedEscortTies = res;
            this.PopulateRouteTree();
            this.armedEscortTies.forEach(
              (userProfile) =>
                (userProfile.fullName = `${userProfile.resource.firstName} ${userProfile.resource.lastName}`)
            );
            //this.loading = false;
          }
        },
        (error) => {
          this.loading = false;
          this.connectionError();
        }
      );
    this.loading = false;
    this.submittingData = false;
  }
  // fetchAllRoutesByResourceId(): void {
  //   this.resourceregistrationService
  //     .allArmedEscortProfileTiesByResourceId(this.selectedArmedEscortProfile.id)
  //     .pipe(takeUntil(this.unsubscriber$))
  //     .subscribe(
  //       (res: any) => {
  //         this.armedEscortTies = res;
  //         this.PopulateRouteTree();
  //         //this.smoReturnRoutes = res;
  //         this.loading = false;
  //       },
  //       (error) => {
  //         this.messageService.add({
  //           severity: "error",
  //           summary: "Failed",
  //           detail: "Connection Error,  " + error.error.message,
  //         });
  //         this.submittingData = false;
  //       }
  //     );
  // }

  createArmedEscortTie() {
    this.submittingData = true;
    const formData = this.armedEscortTieForm.value;
    const postData = {
      resourceId: this.selectedArmedEscortProfile.id,
      smoRegionId: this.selectedSMORegion.id,
      smoRouteId: this.selectedMultiSMORoutes.map((x) => x.id),
      // rankId: this.selectedPilotRank.id,
    };
    this.resourceregistrationService
      .postArmedEscortProfileTie(postData)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            this.messageService.add({
              summary: "Success",
              severity: "success",
              detail: "New Armed Escort SMO Route Tie(s) created",
            });
            //this.fetchAllRoutes();
            this.fetchAllArmedEscortProfileTies();
            this.submittingData = false;
            this.armedEscortTieForm.reset();
            this.armedEscortUseForm.reset();
          } else {
            this.messageService.add({
              severity: "error",
              summary: "Failed",
              detail: " Error: " + r.responseMsg,
            });
            this.submittingData = false;
          }
        },
        (error) => {
          this.messageService.add({
            severity: "error",
            summary: "Failed",
            detail: "Connection Error " + error.error.message,
          });
          this.submittingData = false;
        }
      );
  }

  deleteArmedEscortTie(armedEscortResourceTie: ArmedEscortResourceTie): void {
    this.confirmationService.confirm({
      message:
        "Are you sure you want to delete " +
        armedEscortResourceTie.resource.firstName,
      accept: () => {
        this._deleteEscortTie(armedEscortResourceTie);
      },
    });
  }

  _deleteEscortTie(armedEscortResourceTie) {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Deleting Tie record",
    });
    this.resourceregistrationService
      .deleteArmedEscortTie(armedEscortResourceTie.id)
      .subscribe(
        async (result: any) => {
          await this.messageService.add({
            severity: "success",
            summary: "Deleted",
            detail: "Armed Escort Tie record removed",
          });
          await this.fetchAllArmedEscortProfileTies();
          this.armedEscortTieForm.reset();
          this.armedEscortUseForm.reset();
        },
        (error) => {
          this.connectionError();
        }
      );
  }

  //Commander
  fetchAllCommanderProfile(): void {
    this.resourceregistrationService
      .allCommanderProfile()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            var res = r.responseData;
            this.commanderProfiles = res;
            this.commanderProfiles.forEach(
              (userProfile) =>
                (userProfile.fullName = `${userProfile.profile.firstName} ${userProfile.profile.lastName}`)
            );
            this.loading = false;
          }
        },
        (error) => {
          this.loading = false;
          this.connectionError();
        }
      );
    this.submittingData = false;
  }

  fetchAllCommanderProfileTies(): void {
    this.resourceregistrationService
      .allCommanderProfileTies()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            var res = r.responseData;
            this.commanderTies = res;
            this.PopulateCommanderRouteTree();
            this.commanderTies.forEach(
              (userProfile) =>
                (userProfile.fullName = `${userProfile.resource.profile.firstName} ${userProfile.resource.profile.lastName}`)
            );
            this.loading = false;
          }
        },
        (error) => {
          this.loading = false;
          this.connectionError();
        }
      );
    this.submittingData = false;
  }

  createCommanderTie() {
    this.submittingData = true;
    const formData = this.commanderTieForm.value;
    const postData = {
      resourceId: this.selectedCommanderProfile.id,
      smoRegionId: this.selectedSMORegion.id,
      smoRouteId: this.selectedMultiSMORoutes.map((x) => x.id),
      // rankId: this.selectedPilotRank.id,
    };
    this.resourceregistrationService
      .postCommanderProfileTie(postData)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            this.messageService.add({
              summary: "Success",
              severity: "success",
              detail: "New Commander SMO Route Tie(s) created",
            });
            //this.fetchAllRoutes();
            this.fetchAllCommanderProfileTies();
            this.submittingData = false;
            this.commanderTieForm.reset();
            this.commanderUseForm.reset();
          } else {
            this.messageService.add({
              severity: "error",
              summary: "Failed",
              detail: " Error: " + r.responseMsg,
            });
            this.submittingData = false;
          }
        },
        (error) => {
          this.messageService.add({
            severity: "error",
            summary: "Failed",
            detail: "Connection Error " + error.error.message,
          });
          this.submittingData = false;
        }
      );
  }

  deleteCommanderTie(commanderResourceTie: CommanderResourceTie): void {
    this.confirmationService.confirm({
      message:
        "Are you sure you want to delete " +
        commanderResourceTie.resource.profile.firstName,
      accept: () => {
        this._deleteCommanderTie(commanderResourceTie);
      },
    });
  }

  _deleteCommanderTie(commanderResourceTie) {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Deleting Tie record",
    });
    this.resourceregistrationService
      .deleteCommanderTie(commanderResourceTie.id)
      .subscribe(
        async (result: any) => {
          await this.messageService.add({
            severity: "success",
            summary: "Deleted",
            detail: "Commander Tie record removed",
          });
          await this.fetchAllCommanderProfileTies();
          this.commanderTieForm.reset();
          this.commanderUseForm.reset();
        },
        (error) => {
          this.connectionError();
        }
      );
  }

  //Vehicle
  fetchAllVehicleProfile(): void {
    this.resourceregistrationService
      .allVehicle()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            var res = r.responseData;
            this.vehicleProfiles = res;
            //console.log(this.vehicleProfiles);
            this.loading = false;
          }
        },
        (error) => {
          this.loading = false;
          this.connectionError();
        }
      );
    this.submittingData = false;
  }

  fetchAllVehicleProfileTies(): void {
    this.resourceregistrationService
      .allVehicleTies()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            var res = r.responseData;
            this.vehicleTies = res;
            this.PopulateVehicleRouteTree();

            this.loading = false;
          }
        },
        (error) => {
          this.loading = false;
          this.connectionError();
        }
      );
    this.submittingData = false;
  }

  createVehicleTie() {
    this.submittingData = true;
    const formData = this.vehicleTieForm.value;
    const postData = {
      resourceId: this.selectedVehicleProfile.id,
      smoRegionId: this.selectedSMORegion.id,
      smoRouteId: this.selectedMultiSMORoutes.map((x) => x.id),
      // rankId: this.selectedPilotRank.id,
    };
    this.resourceregistrationService
      .postVehicleProfileTie(postData)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            this.messageService.add({
              summary: "Success",
              severity: "success",
              detail: "New Vehicle SMO Route Tie(s) created",
            });
            //this.fetchAllRoutes();
            this.fetchAllVehicleProfileTies();
            this.submittingData = false;
            this.vehicleTieForm.reset();
            this.vehicleUseForm.reset();
          } else {
            this.messageService.add({
              severity: "error",
              summary: "Failed",
              detail: " Error: " + r.responseMsg,
            });
            this.submittingData = false;
          }
        },
        (error) => {
          this.messageService.add({
            severity: "error",
            summary: "Failed",
            detail: "Connection Error " + error.error.message,
          });
          this.submittingData = false;
        }
      );
  }

  deleteVehicleTie(vehicleResourceTie: VehicleResourceTie): void {
    this.confirmationService.confirm({
      message:
        "Are you sure you want to delete " +
        vehicleResourceTie.resource.supplierService.serviceName,
      accept: () => {
        this._deleteVehicleTie(vehicleResourceTie);
      },
    });
  }

  _deleteVehicleTie(vehicleResourceTie) {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Deleting Tie record",
    });
    this.resourceregistrationService
      .deleteVehicleTie(vehicleResourceTie.id)
      .subscribe(
        async (result: any) => {
          await this.messageService.add({
            severity: "success",
            summary: "Deleted",
            detail: "Vehicle Tie record removed",
          });
          await this.fetchAllVehicleProfileTies();
          this.vehicleTieForm.reset();
          this.vehicleUseForm.reset();
        },
        (error) => {
          this.connectionError();
        }
      );
  }

  setRoutes() {
    this.availableMultiSMORoutes = [];
    this.smoRoutes.forEach((Rank) => {
      if (Rank.smoRegionId === this.selectedSMORegion.id) {
        this.availableMultiSMORoutes.push(Rank);
        //console.log(Rank);
      }
    });
  }

  FetchAllSelectedRoutes() {
    this.resourceregistrationService
      .allArmedEscortProfileTie()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            var res = r.responseData;
            this.armedEscortTies = res;
            //this.armedEscortProfiles = res;
            this.PopulateRouteTree();
            console.log(res);
            this.loading = false;
          }
        },
        (error) => {
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail:
              "Unable to fetch all inventory stores at the moment.. Reason: [" +
              error.error.message +
              "]",
          });
        }
      );
  }

  PopulateRouteTree() {
    this.loading = true;
    this.selectedRouteTree = [];
    const centralStoresTree = [];
    this.loadingPriceRegisters = false;

    this.armedEscortTies.forEach((regionStore) => {
      const RuotesTree = [];
      // this.armedEscortProfiles.forEach((profileStore) => {
      if (regionStore.resourceId === this.selectedArmedEscortProfile.id) {
        centralStoresTree.push({
          label: regionStore.smoRoute.routeName,
          key: regionStore.id,
          //expandedIcon: "pi pi-map-marker",
          //collapsedIcon: "pi pi-map-marker",
          //expanded: true,
          data: regionStore,
          children: RuotesTree,
          selectable: false,
        });
      }
      //});
      // if (regionStore.routeName) {
      // RuotesTree.push({
      //   label: regionStore.routeName,
      //   key: regionStore.id,
      //   icon: "pi pi-fw pi-sitemap",
      //   data: regionStore,
      //   selectable: true,
      // });
      // }
    });

    this.selectedRouteTree = [
      {
        //label: "Armed Escort",
        label: this.selectedArmedEscortProfile.fullName,
        key: "Route Folder",
        // expandedIcon: "pi pi-map-marker",
        // collapsedIcon: "pi pi-map-marker",
        //expandedIcon: "pi pi-minus-circle",
        //collapsedIcon: "pi pi-plus-circle",
        expanded: true,
        children: centralStoresTree,
        data: 1001,
        selectable: false,
      },
    ];
    this.loading = false;
  }

  PopulateCommanderRouteTree() {
    this.loading = true;
    this.selectedCommanderRouteTree = [];
    const centralStoresTree = [];
    this.loadingPriceRegisters = false;

    this.commanderTies.forEach((regionStore) => {
      const RuotesTree = [];
      // this.armedEscortProfiles.forEach((profileStore) => {
      if (regionStore.resourceId === this.selectedCommanderProfile.id) {
        centralStoresTree.push({
          label: regionStore.smoRoute.routeName,
          key: regionStore.id,
          //expandedIcon: "pi pi-map-marker",
          //collapsedIcon: "pi pi-map-marker",
          data: regionStore,
          children: RuotesTree,
          selectable: false,
        });
      }
    });

    this.selectedCommanderRouteTree = [
      {
        //label: "Armed Escort",
        label: this.selectedCommanderProfile.fullName,
        key: "Route Folder",
        // expandedIcon: "pi pi-map-marker",
        // collapsedIcon: "pi pi-map-marker",
        //expandedIcon: "pi pi-minus-circle",
        //collapsedIcon: "pi pi-plus-circle",
        expanded: true,
        children: centralStoresTree,
        data: 1001,
        selectable: false,
      },
    ];
    this.loading = false;
  }
  PopulatePilotRouteTree() {
    this.selectedPilotRouteTree = [];
    const centralStoresTree = [];
    this.loadingPriceRegisters = false;

    this.pilotTies.forEach((regionStore) => {
      const RuotesTree = [];
      // this.armedEscortProfiles.forEach((profileStore) => {
      if (regionStore.resourceId === this.selectedPilotProfile.id) {
        centralStoresTree.push({
          label: regionStore.smoRoute.routeName,
          key: regionStore.id,
          //expandedIcon: "pi pi-map-marker",
          //collapsedIcon: "pi pi-map-marker",
          data: regionStore,
          children: RuotesTree,
          selectable: false,
        });
      }
    });

    this.selectedPilotRouteTree = [
      {
        //label: "Armed Escort",
        label: this.selectedPilotProfile.fullName,
        key: "Route Folder",
        // expandedIcon: "pi pi-map-marker",
        // collapsedIcon: "pi pi-map-marker",
        //expandedIcon: "pi pi-minus-circle",
        //collapsedIcon: "pi pi-plus-circle",
        expanded: true,
        children: centralStoresTree,
        data: 1001,
        selectable: false,
      },
    ];
  }
  PopulateVehicleRouteTree() {
    this.selectedVehicleRouteTree = [];
    const centralStoresTree = [];
    this.loadingPriceRegisters = false;

    this.vehicleTies.forEach((regionStore) => {
      const RuotesTree = [];
      // this.armedEscortProfiles.forEach((profileStore) => {
      if (regionStore.resourceId === this.selectedVehicleProfile.id) {
        centralStoresTree.push({
          label: regionStore.smoRoute.routeName,
          key: regionStore.id,
          //expandedIcon: "pi pi-map-marker",
          //collapsedIcon: "pi pi-map-marker",
          data: regionStore,
          children: RuotesTree,
          selectable: false,
        });
      }
    });

    this.selectedVehicleRouteTree = [
      {
        //label: "Armed Escort",
        label: this.selectedVehicleProfile.supplierService.serviceName,
        key: "Route Folder",
        // expandedIcon: "pi pi-map-marker",
        // collapsedIcon: "pi pi-map-marker",
        //expandedIcon: "pi pi-minus-circle",
        //collapsedIcon: "pi pi-plus-circle",
        expanded: true,
        children: centralStoresTree,
        data: 1001,
        selectable: false,
      },
    ];
  }
  private connectionError() {
    this.messageService.add({
      severity: "error",
      summary: "Failed",
      detail: "Connection Error, Please try again",
    });
  }
}
