import {
  BatchRequestItem,
  CentralStore,
  RegionalStore,
  StockBatch,
} from "./../../../interfaces/inventory";
import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { ConfirmationService, MessageService } from "primeng/api";
import { BreadcrumbService } from "src/app/breadcrumb.service";
import { InventoryService } from "src/app/services/inventory.service";

@Component({
  selector: "app-batch-confirmation",
  templateUrl: "./batch-confirmation.component.html",
  styleUrls: ["./batch-confirmation.component.scss"],
})
export class BatchConfirmationComponent implements OnInit {
  @ViewChild("formWrapper") public formWrapper: ElementRef;
  processOptions: {
    id: number;
    caption: string;
  }[];
  theProcessOption: {
    id: number;
    caption: string;
  };
  processOptions2: {
    id: number;
    caption: string;
  }[];
  theProcessOption2: {
    id: number;
    caption: string;
  };
  userCentralStores: CentralStore[];
  theUserCentralStore: CentralStore;
  theUserCentralStore2: CentralStore;
  userRegionalStores: RegionalStore[];
  theUserRegionalStore: RegionalStore;
  theUserRegionalStore2: RegionalStore;
  fetchingStoreApprovedBatches: boolean;
  fetchingStoreApprovedBatches2: boolean;
  approvedCols: any[];
  approvedCols2: any[];
  approvedBatches: StockBatch[];
  selectedApprovedBatch: StockBatch;
  approvedBatches2: StockBatch[];
  selectedApprovedBatch2: StockBatch;
  batchToConfirm: StockBatch;
  batchToConfirm2: StockBatch;
  approvedDetailTable: {
    name: string;
    data: string;
  }[];
  selectedApprovedDetailTable: {
    name: string;
    data: string;
  };
  approvedDetailTable2: {
    name: string;
    data: string;
  }[];
  selectedApprovedDetailTable2: {
    name: string;
    data: string;
  };
  confirmedBatchItems: BatchRequestItem[];
  selectedConfirmBatchItem: BatchRequestItem[] = [];
  confirmedBatchItems2: BatchRequestItem[];
  selectedConfirmBatchItem2: BatchRequestItem[] = [];
  approvedItemCols: any[];
  approvedItemCols2: any[];
  isApprovedBatchesLoaded: boolean = false;
  isConfirmBatchDetailsLoaded: boolean = false;
  isConfirmBatchItemsLoaded: boolean = false;
  isApprovedBatchesLoaded2: boolean = false;
  isConfirmBatchDetailsLoaded2: boolean = false;
  isConfirmBatchItemsLoaded2: boolean = false;
  //confirmedBatchRequestItems: BatchRequestItem[];
  //dispatchedRequestItems: BatchRequestItem[];
  showIsRegionalAcceptance: boolean;
  showIsRegionalAcceptance2: boolean;
  batchItemsQtyReceived: { key: number; value: number }[] = [];
  batchItemsQtyDispatched: { key: number; value: number }[] = [];
  isCheckedConfirmBtch1: boolean = false;
  isCheckedConfirmBtch2: boolean = false;

  constructor(
    public inventoryService: InventoryService,
    private breadcrumbService: BreadcrumbService,
    public confirmationService: ConfirmationService,
    public messageService: MessageService
  ) {}

  ngOnInit(): void {
    this.breadcrumbService.setItems([
      {
        label: "Inventory Management",
        routerLink: ["/home/inventory-management/set-up"],
      },
      {
        label: "Batch Confirmation",
        routerLink: ["/home/inventory-management/batch-confirmation"],
      },
    ]);

    this.processOptions = [
      {
        id: 1,
        caption: "Inward Central",
      },
      {
        id: 2,
        caption: "Inward Regional",
      },
      {
        id: 3,
        caption: "Inward Personal",
      },
    ];

    this.processOptions2 = [
      {
        id: 1,
        caption: "Outward From Central",
      },
      {
        id: 2,
        caption: "Outward From Regional",
      },
    ];

    this.approvedCols = [
      { field: "batchName", header: "BatchName" },
      { field: "batchDescription", header: "BatchDescription" },
      { field: "batchNumber", header: "BatchNumber" },
    ];

    this.approvedCols2 = [
      { field: "batchName", header: "BatchName" },
      { field: "batchDescription", header: "BatchDescription" },
      { field: "batchNumber", header: "BatchNumber" },
    ];

    this.approvedItemCols = [
      { field: "item.caption", header: "Item.caption" },
      { field: "item.description", header: "Item.description" },
      { field: "quantity", header: "Quantity" },
      { field: "quantityDispatched", header: "QuantityDispatched" },
      { field: "quantityConfirmed", header: "QuantityConfirmed" },
    ];

    this.approvedItemCols2 = [
      { field: "item.caption", header: "Item.caption" },
      { field: "item.description", header: "Item.description" },
      { field: "quantity", header: "Quantity" },
      { field: "quantityDispatched", header: "QuantityDispatched" },
    ];

    this.ReInitializeApprovedDetailTable();

    //this.confirmedBatchRequestItems = [];
    //this.dispatchedRequestItems = [];
    this.batchItemsQtyReceived = [];
    this.batchItemsQtyDispatched = [];
    this.FetchAllStoreManagerInventoryStores();
  }

  CalculateBatchTotalValue(item: StockBatch): number {
    let totalValue = 0;
    item.itemsRequested.forEach((data) => {
      let sumOfBatchItem = data.unitPrice * data.quantity;
      totalValue = totalValue + sumOfBatchItem;
    });

    return totalValue;
  }

  ReInitializeApprovedDetailTable() {
    this.approvedDetailTable = [
      {
        name: "Batch Name",
        data: "Data",
      },
      {
        name: "Batch Description",
        data: "Data",
      },
      {
        name: "Total Value",
        data: "Data",
      },
      {
        name: "Confirmation Summary",
        data: "Data",
      },
    ];

    this.approvedDetailTable2 = [
      {
        name: "Batch Name",
        data: "Data",
      },
      {
        name: "Batch Description",
        data: "Data",
      },
      {
        name: "Dispatch Summary",
        data: "Data",
      },
    ];
  }

  LoadBatchDetails(item: StockBatch) {
    this.ReInitializeApprovedDetailTable();
    this.batchToConfirm = item;

    let confirmedItemCount = 0;
    let approvedItemCount = 0;
    item.itemsRequested.forEach((data) => {
      if (data.isAcknowledged) confirmedItemCount++;
      if (data.isApproved) approvedItemCount++;
    });
    this.approvedDetailTable[0].data = item.name;
    this.approvedDetailTable[1].data = item.description;
    this.approvedDetailTable[2].data = this.CalculateBatchTotalValue(item) + "";
    this.approvedDetailTable[3].data =
      "Out of a total of " +
      approvedItemCount +
      " item(s) approved," +
      confirmedItemCount +
      " has been fully confirmed.";

    if (this.showIsRegionalAcceptance)
      this.approvedDetailTable.splice(
        this.approvedDetailTable.indexOf(this.approvedDetailTable[2]),
        1
      );

    this.isConfirmBatchDetailsLoaded = true;
    this.selectedConfirmBatchItem = [];
  }

  LoadBatchDetails2(item: StockBatch) {
    this.ReInitializeApprovedDetailTable();
    this.batchToConfirm2 = item;

    let dispatchedItemCount = 0;
    let approvedItemCount = 0;
    item.itemsRequested.forEach((data) => {
      if (data.isDispatched) dispatchedItemCount++;
      if (data.isApproved) approvedItemCount++;
    });
    this.approvedDetailTable2[0].data = item.name;
    this.approvedDetailTable2[1].data = item.description;
    this.approvedDetailTable2[2].data =
      "Out of a total of " +
      approvedItemCount +
      " item(s) approved," +
      dispatchedItemCount +
      " has been fully dispatched.";

    this.isConfirmBatchDetailsLoaded2 = true;
  }

  ShowBatchItems() {
    this.isConfirmBatchItemsLoaded = true;
    this.confirmedBatchItems = [];
    this.batchToConfirm.itemsRequested.forEach((item) => {
      if (item.isApproved && !item.isFullyConfirmed)
        this.confirmedBatchItems.push(item);
    });

    this.batchItemsQtyReceived = [];
    this.isCheckedConfirmBtch1 = false;
    this.selectedConfirmBatchItem = [];
  }

  ShowBatchItems2() {
    this.isConfirmBatchItemsLoaded2 = true;
    this.confirmedBatchItems2 = [];
    this.batchToConfirm2.itemsRequested.forEach((item) => {
      if (item.isApproved && !item.isFullyConfirmed)
        this.confirmedBatchItems2.push(item);
    });

    this.batchItemsQtyDispatched = [];
    this.isCheckedConfirmBtch2 = false;
    this.selectedConfirmBatchItem2 = [];
  }

  ConfirmBatchItem(item: BatchRequestItem) {
    // if (this.confirmedBatchRequestItems.indexOf(item) > -1) {
    //   this.confirmedBatchRequestItems.splice(
    //     this.confirmedBatchRequestItems.indexOf(item),
    //     1
    //   );
    // } else {
    //   this.confirmedBatchRequestItems.push(item);
    // }
  }

  DispatchBatchItem(item: BatchRequestItem) {
    // if (this.dispatchedRequestItems.indexOf(item) > -1) {
    //   this.dispatchedRequestItems.splice(
    //     this.dispatchedRequestItems.indexOf(item),
    //     1
    //   );
    // } else {
    //   this.dispatchedRequestItems.push(item);
    // }
  }

  FetchAllStoreManagerInventoryStores() {
    this.inventoryService.GetAllStoreManagerInventoryStores().subscribe(
      (data) => {
        this.userCentralStores = data.centralStores;
        this.userRegionalStores = data.regionalStores;
      },
      (error) => {
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all store manager inventory stores at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  ListenToProcessOption3() {
    if (this.theProcessOption && this.theProcessOption.id == 3) {
      this.isConfirmBatchDetailsLoaded = false;
      this.isConfirmBatchItemsLoaded = false;
      this.showIsRegionalAcceptance = true;
      this.isApprovedBatchesLoaded = true;
      this.fetchingStoreApprovedBatches = true;

      this.inventoryService.GetAllUserRequestApprovedBatches().subscribe(
        (data) => {
          this.approvedBatches = data;
          this.fetchingStoreApprovedBatches = false;
        },
        (error) => {
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail:
              "Unable to load user request approved batches at the moment.. Reason: [" +
              error
                ? error.error.message
                : "request failed - permission" + "]",
          });
          this.fetchingStoreApprovedBatches = false;
        }
      );
    }
  }

  LoadStoreApprovedBatchs(isCentral: boolean) {
    this.isConfirmBatchDetailsLoaded = false;
    this.isConfirmBatchItemsLoaded = false;
    this.showIsRegionalAcceptance = !isCentral;
    this.isApprovedBatchesLoaded = true;
    this.fetchingStoreApprovedBatches = true;
    if (
      (isCentral && this.theUserCentralStore) ||
      (!isCentral && this.theUserRegionalStore)
    ) {
      this.inventoryService
        .GetAllStoreApprovedBatches({
          storeId: isCentral
            ? this.theUserCentralStore.id
            : this.theUserRegionalStore.id,
          isCentral: isCentral,
          isDispatching: false,
        })
        .subscribe(
          (data) => {
            this.approvedBatches = data;
            this.fetchingStoreApprovedBatches = false;
          },
          (error) => {
            this.messageService.add({
              severity: "error",
              summary: "Notice",
              detail:
                "Unable to load store approved batches at the moment.. Reason: [" +
                error
                  ? error.error.message
                  : "request failed - permission" + "]",
            });
            this.fetchingStoreApprovedBatches = false;
          }
        );
    } else {
      this.fetchingStoreApprovedBatches = false;
      this.isApprovedBatchesLoaded = false;
    }
  }

  LoadStoreApprovedBatches2(isCentral: boolean) {
    this.isConfirmBatchDetailsLoaded2 = false;
    this.isConfirmBatchItemsLoaded2 = false;
    this.showIsRegionalAcceptance2 = !isCentral;
    this.isApprovedBatchesLoaded2 = true;
    this.fetchingStoreApprovedBatches2 = true;
    if (
      (isCentral && this.theUserCentralStore2) ||
      (!isCentral && this.theUserRegionalStore2)
    ) {
      this.inventoryService
        .GetAllStoreApprovedBatches({
          storeId: isCentral
            ? this.theUserCentralStore2.id
            : this.theUserRegionalStore2.id,
          isCentral: isCentral,
          isDispatching: true,
        })
        .subscribe(
          (data) => {
            this.approvedBatches2 = data;
            this.fetchingStoreApprovedBatches2 = false;
          },
          (error) => {
            this.messageService.add({
              severity: "error",
              summary: "Notice",
              detail:
                "Unable to load store approved batches at the moment.. Reason: [" +
                error
                  ? error.error.message
                  : "request failed - permission" + "]",
            });
            this.fetchingStoreApprovedBatches2 = false;
          }
        );
    } else {
      this.fetchingStoreApprovedBatches2 = false;
      this.isApprovedBatchesLoaded2 = false;
    }
  }

  SaveBatchItemsConfirmation() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Saving Batch Items Confirmations...",
    });

    if (this.selectedConfirmBatchItem.length == 0) {
      this.messageService.add({
        severity: "error",
        summary: "Notice",
        detail:
          "Unable to confirm batch request items at the moment.. Reason: [At least one item must be confirmed]",
      });
      return;
    }

    let confirmedItemIds: number[] = [];
    this.selectedConfirmBatchItem.forEach((item) => {
      confirmedItemIds.push(item.id);
    });

    this.inventoryService
      .ConfirmBatchRequestItems({
        itemIds: confirmedItemIds,
        batchId: this.batchToConfirm.id,
        quantitiesReceived: this.batchItemsQtyReceived,
      })
      .subscribe(
        async () => {
          await this.messageService.add({
            severity: "success",
            summary: "Completed",
            detail: "Batch Request Items Confirmed Successfully.",
          });

          this.selectedConfirmBatchItem = [];
          this.isApprovedBatchesLoaded = false;
          this.isConfirmBatchDetailsLoaded = false;
          this.isConfirmBatchItemsLoaded = false;
          this.theUserCentralStore = null;
          this.theUserRegionalStore = null;
          this.isCheckedConfirmBtch2 = false;
        },
        (error) => {
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail:
              "Unable to confirm batch request items at the moment.. Reason: [" +
              error
                ? error.error.message
                : "request failed - permission" + "]",
          });
        }
      );
  }

  SaveBatchItemsAcknowledgement() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Saving Batch Items Dispatched...",
    });

    if (this.selectedConfirmBatchItem2.length == 0) {
      this.messageService.add({
        severity: "error",
        summary: "Notice",
        detail:
          "Unable to dispatch batch request items at the moment.. Reason: [At least one item must be dispatched]",
      });
      return;
    }

    let dispatchedItemIds: number[] = [];
    this.selectedConfirmBatchItem2.forEach((item) => {
      dispatchedItemIds.push(item.id);
    });

    this.inventoryService
      .DispatchBatchRequestItems({
        itemIds: dispatchedItemIds,
        batchId: this.batchToConfirm2.id,
        quantitiesDispatched: this.batchItemsQtyDispatched,
      })
      .subscribe(
        async () => {
          await this.messageService.add({
            severity: "success",
            summary: "Completed",
            detail: "Batch Request Items Dispactched Successfully.",
          });

          this.selectedConfirmBatchItem2 = [];
          this.isApprovedBatchesLoaded2 = false;
          this.isConfirmBatchDetailsLoaded2 = false;
          this.isConfirmBatchItemsLoaded2 = false;
          this.theUserCentralStore2 = null;
          this.theUserRegionalStore2 = null;
        },
        (error) => {
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail:
              "Unable to dispatch batch request items at the moment.. Reason: [" +
              error
                ? error.error.message
                : "request failed - permission" + "]",
          });
        }
      );
  }

  LogQtyReceived(qty: number, batchItemId: number, maxQty: number) {
    if (qty < 0) {
      this.messageService.add({
        severity: "info",
        summary: "Notice",
        detail:
          "Kindly note the quantity you inputted is less than the minimum quantity to be confirmed. The system will automatically set the quantity to 0",
      });

      qty = 0;
    }
    if (qty > maxQty) {
      this.messageService.add({
        severity: "info",
        summary: "Notice",
        detail:
          "Kindly note the quantity you inputted is greater than the maximum quantity to be confirmed. The system will automatically set the quantity to " +
          maxQty,
      });

      qty = maxQty;
    }
    let item = this.batchItemsQtyReceived.find(
      (data) => data.key == batchItemId
    );
    if (item == null) {
      this.batchItemsQtyReceived.push({
        key: batchItemId,
        value: qty,
      });
    } else {
      if (this.batchItemsQtyReceived.indexOf(item) > -1) {
        this.batchItemsQtyReceived.splice(
          this.batchItemsQtyReceived.indexOf(item),
          1
        );
        this.batchItemsQtyReceived.push({
          key: batchItemId,
          value: qty,
        });
      } else {
        this.batchItemsQtyReceived.push({
          key: batchItemId,
          value: qty,
        });
      }
    }
  }

  LogQtyDispached(qty: number, batchItemId: number, maxQty: number) {
    if (qty < 0) {
      this.messageService.add({
        severity: "info",
        summary: "Notice",
        detail:
          "Kindly note the quantity you inputted is less than the minimum quantity to be dispatched. The system will automatically set the quantity to 0",
      });

      qty = 0;
    }
    if (qty > maxQty) {
      this.messageService.add({
        severity: "info",
        summary: "Notice",
        detail:
          "Kindly note the quantity you inputted is greater than the maximum quantity to be dispatched. The system will automatically set the quantity to " +
          maxQty,
      });

      qty = maxQty;
    }
    let item = this.batchItemsQtyDispatched.find(
      (data) => data.key == batchItemId
    );
    if (item == null) {
      this.batchItemsQtyDispatched.push({
        key: batchItemId,
        value: qty,
      });
    } else {
      if (this.batchItemsQtyDispatched.indexOf(item) > -1) {
        this.batchItemsQtyDispatched.splice(
          this.batchItemsQtyDispatched.indexOf(item),
          1
        );
        this.batchItemsQtyDispatched.push({
          key: batchItemId,
          value: qty,
        });
      } else {
        this.batchItemsQtyDispatched.push({
          key: batchItemId,
          value: qty,
        });
      }
    }
  }

  GetQtyDispatchedLogValue(batchItemId: number): string {
    let batch = this.batchItemsQtyReceived.find((x) => x.key == batchItemId);
    if (batch) return batch.value + "";

    return "";
  }

  GetQtyReceivedLogValue(batchItemId: number): string {
    let batch = this.batchItemsQtyDispatched.find((x) => x.key == batchItemId);
    if (batch) return batch.value + "";

    return "";
  }

  EquateAllConfirmation() {
    this.confirmedBatchItems2.forEach((item) => {
      let maxQty = item.quantity - item.quantityDispatched;
      this.LogQtyDispached(maxQty, item.id, maxQty);
      this.selectedConfirmBatchItem2.push(item);
      this.isCheckedConfirmBtch2 = true;
    });
  }

  EquateAllAcceptIn() {
    this.confirmedBatchItems.forEach((item) => {
      let maxQty = item.quantityDispatched - item.quantityConfirmed;
      this.LogQtyReceived(maxQty, item.id, maxQty);
      this.selectedConfirmBatchItem.push(item);
      this.isCheckedConfirmBtch1 = true;
    });
  }
}
