import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, EventEmitter } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { Subscription } from 'rxjs/internal/Subscription';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class BudgetapiservicesService {
  readonly budegtSeasonBaseURL = environment.baseUrl+'BudgetSeason'; //'https://localhost:44344/api/BudgetSeason';
  readonly budgetCycleBaseURL = environment.baseUrl+'BudgetCycle'; //'https://localhost:44344/api/BudgetCycle';
  
  onBudgetSeasonEditClick = new EventEmitter<any>();
  onBudgetSeasonCancelEditClick = new EventEmitter<any>();

  displaySelectedBudgetToEdit(data: any) {
    this.onBudgetSeasonEditClick.emit(data);
  }

  cancelSelectedBudgetToEdit() {
    this.onBudgetSeasonCancelEditClick.emit();
  }

  constructor(private http: HttpClient) { }

  /*
    const req = new HttpRequest('POST', `${this.baseUrl}/sendfile`, formData, {
      reportProgress: true,
      responseType: 'json'
    });

    return this.http.request(req);
  */
    
  //Budget Season
  getBudgetSeasons(): Observable<any[]> {
    // this.invokeLoadBudgetSeasonListFromCreationComponent.emit();
    return this.http.get<any[]>(`${this.budegtSeasonBaseURL}/BudgetSeasons`)
  }

  createBudgetSeason(seasonData: any): Observable<any> {
    // const budegtSeasonUrl = 'CreateBudgetSeason/';
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' })};
    return this.http.post<any>(`${this.budegtSeasonBaseURL}/CreateBudgetSeason`, seasonData, httpOptions)
  }

  updateBudgetSeason(seasonData: any): Observable<any> {
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' })};
    return this.http.put<any>(`${this.budegtSeasonBaseURL}/UpdateBudgetSeason`, seasonData, httpOptions)
  }

  deleteBudgetSeason(id: any): Observable<any> {
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' })};
    return this.http.delete<any>(`${this.budegtSeasonBaseURL}/DeleteBudgetSeason/${id}`)
  }

  //Budget Cycle
  getBudgetCycleList(): Observable<any[]> {
    //CreateBudgetCycle
    return this.http.get<any[]>(`${this.budgetCycleBaseURL}`)
  }

  createBudgetCycle(cycleData: any): Observable<any> {
    // const budegtSeasonUrl = 'CreateBudgetSeason/';
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' })};
    return this.http.post<any>(`${this.budgetCycleBaseURL}/CreateBudgetCycle`, cycleData, httpOptions)
  }

  updateBudgetCycle(id: any, cycleData: any): Observable<any> {
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' })};
    return this.http.put<any>(`${this.budgetCycleBaseURL}/UpdateBudgetCycle/${id}`, cycleData, httpOptions)
  }

  deleteBudgetCycle(id: any): Observable<any> {
    // const budegtSeasonUrl = 'CreateBudgetSeason/';
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' })};
    return this.http.delete<any>(`${this.budgetCycleBaseURL}/BudgetCycle/${id}`)
  }

  checkBudgetCycleB4Edit(id: any): Observable<any> {
    return this.http.get<any>(`${this.budgetCycleBaseURL}/CheckBudgetCycleStatus/${id}`)
  }

  getBudgetDistribution(data: any): Observable<any[]> {
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' })};
    return this.http.post<any[]>(`${this.budgetCycleBaseURL}/BudgetDistributions`, data)
  }
  
  saveBudgetDistributions(data: any[]): Observable<any[]> {
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' })};
    return this.http.post<any>(`${this.budgetCycleBaseURL}/SaveBudgetDistributions`, data, httpOptions)
  }
  
  deleteBudgetDistributions(id: number, dept: string): Observable<any> {
    return this.http.delete<any[]>(`${this.budgetCycleBaseURL}/DeleteBudgetApprovalSetup?id=${id}&request=${dept}`)
  }

  getCurrencies(): Observable<any[]> {
    return this.http.get<any[]>(`${this.budgetCycleBaseURL}/currencies`)
  }

  getRegions(): Observable<any[]> {
    return this.http.get<any[]>(`${this.budgetCycleBaseURL}/AllRegions`)
  }

  getBudgetCatory(data: any): Observable<any[]> {
    return this.http.get<any[]>(`${this.budgetCycleBaseURL}/BudgetCategory/${data}`)
  }

  getBudgetAllocationTypes(): Observable<any[]> {
    return this.http.get<any[]>(`${this.budgetCycleBaseURL}/AllocationTypes`)
  }

  getExchangeRates(data: any): Observable<any> {
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' })};
    return this.http.get<any>(`${this.budgetCycleBaseURL}/CurrencyExRates`, {params: data})
  }

  getEndorsementTypes(): Observable<any[]> {
    return this.http.get<any[]>(`${this.budgetCycleBaseURL}/EndorsementTypes`)
  }

  createEndorsementTypes(data: any): Observable<any[]> {
    return this.http.post<any[]>(`${this.budgetCycleBaseURL}/CreateEndorsementType`, data)
  }

  updateEndorsementTypes(data: any): Observable<any[]> {
    return this.http.put<any[]>(`${this.budgetCycleBaseURL}/UpdateEndorsementType`, data)
  }

  deleteEndorsementTypes(id: number): Observable<any[]> {
    return this.http.delete<any[]>(`${this.budgetCycleBaseURL}/DeleteEndorsementType/${id}`)
  }
  sendBudgetForReview(data: any): Observable<any> {
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' })};
    return this.http.put<any>(`${this.budgetCycleBaseURL}/SendForReview`, data, httpOptions)
  }

  getAllDistributionCategories(): Observable<any[]> {
    return this.http.get<any[]>(`${this.budgetCycleBaseURL}/BudgetDistributionCategories`)
  }

  getBudgetApprovalSetups(): Observable<any[]> {
    return this.http.get<any[]>(`${this.budgetCycleBaseURL}/BudgetApprovalSetups`)
  }

  deleteBudgetApprovalSetup(id: number, dept: string): Observable<any[]> {
    return this.http.delete<any[]>(`${this.budgetCycleBaseURL}/DeleteBudgetApprovalSetup?id=${id}&dept=${dept}`)
  }

  moveApprovalOfficeSequence(id: number, data: any): Observable<any> {
    return this.http.put<any>(`${this.budgetCycleBaseURL}/MoveBudgetApprovalOfficeSequence/${id}`, data);
  }

  createApprovalSetup(data: any): Observable<any> {
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' })};
    return this.http.post<any>(`${this.budgetCycleBaseURL}/CreateBudgetApprovalSetup`, data, httpOptions);
  }

  updateBudgetApprovalSetup(id: any, data: any): Observable<any> {
    return this.http.put<any>(`${this.budgetCycleBaseURL}/UpdateBudgetApprovalSetup/${id}`, data);
  }


  // THE BELOW SECTION IS FOR BUDGET REVIEW
  getBudgetReviewingSetups(): Observable<any[]> {
    return this.http.get<any[]>(`${this.budgetCycleBaseURL}/BudgetReviewingSetups`)
  }

  getBudgetsForReviewing(): Observable<any[]> {
    return this.http.get<any[]>(`${this.budgetCycleBaseURL}/BudgetsForReview`)
  }

  getBudgetsForApproval(): Observable<any[]> {
    return this.http.get<any[]>(`${this.budgetCycleBaseURL}/BudgetsForApproval`)
  }

  processBudget(data: any): Observable<any[]> {
    return this.http.put<any[]>(`${this.budgetCycleBaseURL}/ProcessBudget`, data)
  }
  
}
