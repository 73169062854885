import { User } from "./home";

export enum FinanceReversalType {
  ReceiptReversal = 1,
  InvoiceReversal = 2,
  VoucherReversal = 3,
}

export interface ReversalApprovingOffice {
  id: number;
  name: string;
  reversalType: FinanceReversalType;
  officers: ReversalApprovingOfficer[];
  createdById: number;
  isDeleted: boolean;
  createdAt: Date;
}

export interface ReversalApprovingOfficer {
  id?: number;
  userProfileId?: number;
  userProfile?: User;
  reversalApprovingOfficeId?: number;
  createdById?: number;
  isDeleted?: boolean;
  createdAt?: Date;
}

export interface CreateReversalOfficesReceivingDTO {
  reversalType: number;
  officeName: string;
  officersIds: number[];
}

export interface UpdateReversalOfficesReceivingDTO {
  officeName?: string;
  officersIds?: number[];
}
