import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ConfirmationService, MessageService } from "primeng/api";
import { BreadcrumbService } from "src/app/breadcrumb.service";
import {
  ActOnMergeRequestVM,
  ClientMergeApprovalLog,
  ClientMergeRequest,
  RepAmortizationMaster,
} from "src/app/interfaces/client-merge";
import { CustomerContact } from "src/app/interfaces/customer";
import {
  AdvancePayment,
  Contract,
  CustomerDivision,
  Invoice,
  User,
} from "src/app/interfaces/home";
import { ClientMergeService } from "src/app/services/client-merge.service";
import { CustomerDivisionService } from "src/app/services/customer-division.service";
import { FileStorageService } from "src/app/services/file-storage.service";
import { GmaCustomerService } from "src/app/services/gma-customer.service";
import { UserService } from "src/app/services/user.service";

@Component({
  selector: "app-client-merge-approval",
  templateUrl: "./client-merge-approval.component.html",
  styleUrls: ["./client-merge-approval.component.scss"],
})
export class ClientMergeApprovalComponent implements OnInit {
  @ViewChild("formWrapper") public formWrapper: ElementRef;
  allClients: CustomerDivision[];
  fetchingRequests: boolean = true;
  allPendingRequests: ClientMergeRequest[] = [];
  allUsers: User[];
  requestInView: ClientMergeRequest;
  mergeRequestApprovalLogs: ClientMergeApprovalLog[] = [];
  requesterName: string = "Yusuf Omoshola";
  requesterImg: string;
  requestJustification: string = "";
  requestDate: Date = new Date(Date.now());
  requestEvidenceUrls: string[] = [];
  requestComment: string = "";
  openViewDialogue: boolean;
  clientToMergeForm: FormGroup;
  masterClientForm: FormGroup;
  toMergeImageSrc: string;
  masterImageSrc: string;
  allIndustries: {
    key: string;
    value: string;
  }[] = [
    {
      key: "Advertising",
      value: "Advertising",
    },
    {
      key: "Agriculture",
      value: "Agriculture",
    },
    {
      key: "Banking & Finance",
      value: "Banking & Finance",
    },
    {
      key: "Construction",
      value: "Construction",
    },
    {
      key: "Education",
      value: "Education",
    },
    {
      key: "Engineering",
      value: "Engineering",
    },
    {
      key: "Health Care",
      value: "Health Care",
    },
    {
      key: "Hospitality",
      value: "Hospitality",
    },
    {
      key: "ICT",
      value: "ICT",
    },
    {
      key: "Insurance",
      value: "Insurance",
    },
    {
      key: "Manufacturing",
      value: "Manufacturing",
    },
    {
      key: "Real Estate",
      value: "Real Estate",
    },
    {
      key: "Safety & Security",
      value: "Safety & Security",
    },
    {
      key: "Telecommunication",
      value: "Telecommunication",
    },
    {
      key: "Transport",
      value: "Transport",
    },
    {
      key: "Other",
      value: "Other",
    },
  ];
  theIndustryForClientToMerge: {
    key: string;
    value: string;
  };
  theIndustryForMasterClient: {
    key: string;
    value: string;
  };
  attachedClientToMergeContacts: CustomerContact[];
  attachedMasterClientContacts: CustomerContact[];
  carouselResponsiveOptions: any[] = [
    {
      breakpoint: "1024px",
      numVisible: 3,
      numScroll: 3,
    },
    {
      breakpoint: "768px",
      numVisible: 2,
      numScroll: 2,
    },
    {
      breakpoint: "560px",
      numVisible: 1,
      numScroll: 1,
    },
  ];
  theClientToMerge: CustomerDivision;
  theMasterClient: CustomerDivision;
  allClientToMergeContracts: Contract[] = [];
  allMasterClientContracts: Contract[] = [];
  clientMergeDetailsMap: {
    clientId: number;
    contracts: Contract[];
    armotizations: RepAmortizationMaster[];
    invoices: Invoice[];
    advances: AdvancePayment[];
  }[] = [];
  allClientToMergeAmortizations: RepAmortizationMaster[] = [];
  allMasterClientAmortizations: RepAmortizationMaster[] = [];
  allClientToMergeInvoices: Invoice[] = [];
  allMasterClientInvoices: Invoice[] = [];
  allClientToMergeAdvances: AdvancePayment[] = [];
  allMasterClientAdvances: AdvancePayment[] = [];

  constructor(
    private fb: FormBuilder,
    public userService: UserService,
    public clientService: CustomerDivisionService,
    public clientMergeService: ClientMergeService,
    private breadcrumbService: BreadcrumbService,
    public confirmationService: ConfirmationService,
    public fileStorageService: FileStorageService,
    public customerService: GmaCustomerService,
    public messageService: MessageService
  ) {
    this.clientToMergeForm = fb.group({
      ImageUrl: [""],
      Name: ["", Validators.required],
      Email: ["", Validators.required],
      Mobile: ["", Validators.required],
      Industry: ["", Validators.required],
    });
    this.masterClientForm = fb.group({
      ImageUrl: [""],
      Name: ["", Validators.required],
      Email: ["", Validators.required],
      Mobile: ["", Validators.required],
      Industry: ["", Validators.required],
    });
  }

  ngOnInit(): void {
    this.breadcrumbService.setItems([
      {
        label: "Client Management",
      },
      {
        label: "Client Merge",
      },
      {
        label: "Client Merge Approval",
        routerLink: ["/home/client-management/client-merge-approval"],
      },
    ]);

    this.FetchAllUsers();
    this.FetchAllClients();
  }

  async FetchAllUsers() {
    this.userService.allUser().subscribe(
      async (r) => {
        var data = r.responseData ?? [];
        this.allUsers = [];
        data.forEach((user) => {
          user.fullName = user.lastName + " " + user.firstName;
          this.allUsers.push(user);
        });
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all users at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  async FetchAllClients() {
    this.clientService.allCustomerDivision().subscribe(
      async (data) => {
        this.allClients = data.responseData;
        this.FetchAllPendingRequests();
      },
      (error) => {
        this.messageService.add({
          severity: "error",
          summary: "Failed",
          detail: error ?? "Some errors occurred",
        });
      }
    );
  }

  async FetchAllPendingRequests() {
    this.fetchingRequests = true;
    this.allPendingRequests = [];
    this.clientMergeService.GetPendingRequestsToApprove().subscribe(
      async (data) => {
        if (data.responseCode != "00") {
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail: data.responseMsg,
          });
          this.fetchingRequests = false;
          return;
        }

        this.allPendingRequests = data.responseData;
        this.fetchingRequests = false;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all pending requests the moment.. Reason: [" +
            error.error.message +
            "]",
        });
        this.fetchingRequests = false;
      }
    );
  }

  GetCustomerDivisionImg(customerDivisionId: number): string {
    let customerDivision = this.allClients.find(
      (x) => x.id == customerDivisionId
    );
    if (customerDivision)
      return customerDivision.logoUrl
        ? customerDivision.logoUrl
        : "assets/no-profile.png";

    return "assets/no-profile.png";
  }

  GetCustomerDivisionName(customerDivisionId: number): string {
    let customerDivision = this.allClients.find(
      (x) => x.id == customerDivisionId
    );
    if (customerDivision) return customerDivision.divisionName;

    return "N/A";
  }

  GetUserName(userId: number): string {
    let user = this.allUsers.find((x) => x.id == userId);
    if (user) return user.fullName;

    return "N/A";
  }

  GetUserImg(userId: number): string {
    let user = this.allUsers.find((x) => x.id == userId);
    if (user) return user.imageUrl ? user.imageUrl : "assets/no-profile.png";

    return "N/A";
  }

  PickRequest(request: ClientMergeRequest) {
    this.requestInView = request;
    this.requestDate = request.createdAt;
    this.requestJustification = request.justification;
    this.requestEvidenceUrls = request.evidenceUrls.split(";");
    this.requesterName = this.GetUserName(request.createdById).toUpperCase();
    this.mergeRequestApprovalLogs = request.approvalLogs;
  }

  ViewClientMergeInfos() {
    this.openViewDialogue = true;

    this.theClientToMerge = this.allClients.find(
      (x) => x.id == this.requestInView.clientToBeMergedId
    );
    this.theMasterClient = this.allClients.find(
      (x) => x.id == this.requestInView.masterClientId
    );
    this.OnClientToMergeLoad();
    this.OnMasterClientLoad();
  }

  FormatDateString(date: Date): string {
    let dateString = date.toString();

    try {
      // DateTimeFormatOptions
      const date: Date = new Date(dateString);
      const options: Intl.DateTimeFormatOptions = {
        year: "numeric",
        month: "long",
        day: "numeric",
      };
      const formattedDate: string = new Intl.DateTimeFormat(
        "en-US",
        options
      ).format(date);
      dateString = formattedDate;
    } catch (error) {
      console.log(error);
      console.log(date);
      console.log(
        "Error while converting date string " + date + " exception " + error
      );
    }

    return dateString;
  }

  ApproveRequest() {
    this.confirmationService.confirm({
      message:
        "You are about to approve this client merge request. This is an irreversible action. Do you still wish to proceed?",
      accept: () => {
        this.messageService.add({
          severity: "info",
          summary: "Notice",
          detail: "Approving Client Merge Request...",
        });

        this.ActOnRequest(true);
      },
    });
  }

  DenyRequest() {
    this.confirmationService.confirm({
      message:
        "You are about to decline this client merge request. This is an irreversible action. Do you still wish to proceed?",
      accept: () => {
        this.messageService.add({
          severity: "info",
          summary: "Notice",
          detail: "Declining Client Merge Request...",
        });

        this.ActOnRequest(false);
      },
    });
  }

  ActOnRequest(isApproved: boolean) {
    const postData: ActOnMergeRequestVM = {
      isApproved: isApproved,
      comment: this.requestComment,
      clientMergeRequestId: this.requestInView.id,
    };

    this.clientMergeService.ActOnMergeRequest(postData).subscribe(
      async (data) => {
        if (data.responseCode != "00") {
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail: data.responseMsg,
          });
          return;
        }

        this.messageService.add({
          severity: "success",
          summary: "Completed",
          detail: "Operation Successfull!",
        });
        this.requestInView = null;
        this.FetchAllPendingRequests();
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to act on client merge request at the moment.. Reason: [" +
            error.error.message +
            "]",
        });
      }
    );
  }

  HideViewDialog() {
    this.openViewDialogue = false;
  }

  OnClientToMergeLoad() {
    if (this.theClientToMerge) {
      this.toMergeImageSrc = this.theClientToMerge.logoUrl;
      this.clientToMergeForm.patchValue({
        ImageUrl: "",
        Name: this.theClientToMerge.divisionName,
        Email: this.theClientToMerge.email,
        Mobile: this.theClientToMerge.phoneNumber,
      });
      this.theIndustryForClientToMerge = this.allIndustries.find(
        (x) => x.key == this.theClientToMerge.industry
      );
      if (this.theClientToMerge.customer) {
        this.attachedClientToMergeContacts =
          this.theClientToMerge.customer.contacts.filter(
            (x) => x.isDeleted == false
          );
      } else {
        this.customerService
          .GetClientInformation(this.theClientToMerge.id)
          .subscribe(
            async (data) => {
              this.theClientToMerge = data;
              this.attachedClientToMergeContacts =
                this.theClientToMerge.customer.contacts.filter(
                  (x) => x.isDeleted == false
                );
            },
            (error) => {
              console.log("Error: " + JSON.stringify(error));
              this.messageService.add({
                severity: "error",
                summary: "Notice",
                detail:
                  "Unable to fetch client contact(s) info at the moment.. Reason: [" +
                  error.error.message +
                  "]",
              });
            }
          );
      }

      let clientMergeDetails = this.clientMergeDetailsMap.find(
        (x) => x.clientId == this.theClientToMerge.id
      );
      if (clientMergeDetails == null) {
        this.clientMergeService
          .GetClientMergeDetails(this.theClientToMerge.id)
          .subscribe(
            async (data) => {
              if (data.responseCode != "00") {
                this.messageService.add({
                  severity: "error",
                  summary: "Notice",
                  detail: data.responseMsg,
                });
                return;
              }

              clientMergeDetails = {
                clientId: this.theClientToMerge.id,
                contracts: data.responseData.contracts,
                armotizations: data.responseData.repAmortizationMasters,
                invoices: data.responseData.invoices,
                advances: data.responseData.advancePayments,
              };
              this.clientMergeDetailsMap.push(clientMergeDetails);
              this.allClientToMergeContracts = clientMergeDetails.contracts;
              this.allClientToMergeAmortizations =
                clientMergeDetails.armotizations;
              this.allClientToMergeInvoices = clientMergeDetails.invoices;
              this.allClientToMergeAdvances = clientMergeDetails.advances;
            },
            (error) => {
              console.log("Error: " + JSON.stringify(error));
              this.messageService.add({
                severity: "error",
                summary: "Notice",
                detail:
                  "Unable to fetch client merge detail(s) at the moment.. Reason: [" +
                  error.error.message +
                  "]",
              });
            }
          );
      } else {
        this.allClientToMergeContracts = clientMergeDetails.contracts;
        this.allClientToMergeAmortizations = clientMergeDetails.armotizations;
        this.allClientToMergeInvoices = clientMergeDetails.invoices;
        this.allClientToMergeAdvances = clientMergeDetails.advances;
      }
    }
  }

  OnMasterClientLoad() {
    if (this.theMasterClient) {
      this.masterImageSrc = this.theMasterClient.logoUrl;
      this.masterClientForm.patchValue({
        ImageUrl: "",
        Name: this.theMasterClient.divisionName,
        Email: this.theMasterClient.email,
        Mobile: this.theMasterClient.phoneNumber,
      });
      this.theIndustryForMasterClient = this.allIndustries.find(
        (x) => x.key == this.theMasterClient.industry
      );
      if (this.theMasterClient.customer) {
        this.attachedMasterClientContacts =
          this.theMasterClient.customer.contacts.filter(
            (x) => x.isDeleted == false
          );
      } else {
        this.customerService
          .GetClientInformation(this.theMasterClient.id)
          .subscribe(
            async (data) => {
              this.theMasterClient = data;
              this.attachedMasterClientContacts =
                this.theMasterClient.customer.contacts.filter(
                  (x) => x.isDeleted == false
                );
            },
            (error) => {
              console.log("Error: " + JSON.stringify(error));
              this.messageService.add({
                severity: "error",
                summary: "Notice",
                detail:
                  "Unable to fetch client contact(s) info at the moment.. Reason: [" +
                  error.error.message +
                  "]",
              });
            }
          );
      }

      let clientMergeDetails = this.clientMergeDetailsMap.find(
        (x) => x.clientId == this.theMasterClient.id
      );
      if (clientMergeDetails == null) {
        this.clientMergeService
          .GetClientMergeDetails(this.theMasterClient.id)
          .subscribe(
            async (data) => {
              if (data.responseCode != "00") {
                this.messageService.add({
                  severity: "error",
                  summary: "Notice",
                  detail: data.responseMsg,
                });
                return;
              }

              clientMergeDetails = {
                clientId: this.theMasterClient.id,
                contracts: data.responseData.contracts,
                armotizations: data.responseData.repAmortizationMasters,
                invoices: data.responseData.invoices,
                advances: data.responseData.advancePayments,
              };
              this.clientMergeDetailsMap.push(clientMergeDetails);
              this.allMasterClientContracts = clientMergeDetails.contracts;
              this.allMasterClientAmortizations =
                clientMergeDetails.armotizations;
              this.allMasterClientInvoices = clientMergeDetails.invoices;
              this.allMasterClientAdvances = clientMergeDetails.advances;
            },
            (error) => {
              console.log("Error: " + JSON.stringify(error));
              this.messageService.add({
                severity: "error",
                summary: "Notice",
                detail:
                  "Unable to fetch client merge detail(s) at the moment.. Reason: [" +
                  error.error.message +
                  "]",
              });
            }
          );
      } else {
        this.allMasterClientContracts = clientMergeDetails.contracts;
        this.allMasterClientAmortizations = clientMergeDetails.armotizations;
        this.allMasterClientInvoices = clientMergeDetails.invoices;
        this.allMasterClientAdvances = clientMergeDetails.advances;
      }
    }
  }

  GetMonthString(identifier: number): string {
    if (identifier == 1) return "JANUARY";
    if (identifier == 2) return "FEBRUARY";
    if (identifier == 3) return "MARCH";
    if (identifier == 4) return "APRIL";
    if (identifier == 5) return "MAY";
    if (identifier == 6) return "JUNE";
    if (identifier == 7) return "JULY";
    if (identifier == 8) return "AUGUST";
    if (identifier == 9) return "SEPTEMPER";
    if (identifier == 10) return "OCTOBER";
    if (identifier == 11) return "NOVEMBER";
    if (identifier == 12) return "DECEMBER";

    return "N/A";
  }
}
