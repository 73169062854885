import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { MessageService, ConfirmationService } from "primeng/api";
import { BreadcrumbService } from "src/app/breadcrumb.service";
import { Supplier } from "src/app/interfaces/armada";
import { DeployedGuard } from "src/app/interfaces/employment";
import {
  ExpenseBatchRequest,
  SearchExpenseBatchesVM,
  TrackExpenseBatchRequestView,
  UserExpenseRequestType,
} from "src/app/interfaces/finance-expense";
import { User, CustomerDivision } from "src/app/interfaces/home";
import { CustomerDivisionService } from "src/app/services/customer-division.service";
import { FinanceExpenseService } from "src/app/services/finance-expense.service";
import { GmaEmploymentService } from "src/app/services/gma-employment.service";
import { SupplierService } from "src/app/services/supplier.service";
import { UserService } from "src/app/services/user.service";

@Component({
  selector: "app-expense-request-tracker",
  templateUrl: "./expense-request-tracker.component.html",
  styleUrls: ["./expense-request-tracker.component.scss"],
})
export class ExpenseRequestTrackerComponent implements OnInit {
  @ViewChild("formWrapper") public formWrapper: ElementRef;
  cols: any[];
  dateFrom: Date;
  dateTo: Date;
  allRequestTypes: {
    key: number;
    value: string;
  }[];
  theRequestType: {
    key: number;
    value: string;
  };
  allSuppliers: Supplier[];
  theSupplier: Supplier;
  allUsers: User[];
  theUser: User;
  allClients: CustomerDivision[];
  theClient: CustomerDivision;
  allOperatives: DeployedGuard[];
  theOperative: DeployedGuard;
  allBatchRequests: ExpenseBatchRequest[];
  selectedBatchRequests: ExpenseBatchRequest[];
  trackingExpenseBatch: boolean;
  batchTrackingInfo: TrackExpenseBatchRequestView[];
  fetchingBatchReq: boolean;
  showTrackInfo: boolean;
  showBatchList: boolean;
  trackedBatchName: string = "Batch Name";

  constructor(
    fb: FormBuilder,
    public userService: UserService,
    public supplierService: SupplierService,
    public clientService: CustomerDivisionService,
    public employmentService: GmaEmploymentService,
    public financeExpenseService: FinanceExpenseService,
    public messageService: MessageService,
    private breadcrumbService: BreadcrumbService,
    public confirmationService: ConfirmationService
  ) {}

  ngOnInit(): void {
    this.breadcrumbService.setItems([
      {
        label: "Finance",
        routerLink: ["/home/finance"],
      },
      {
        label: "Expense Tool",
        routerLink: ["/home/finance/expense"],
      },
      {
        label: "Request Tracker",
        routerLink: ["/home/finance/expense/request-tracker"],
      },
    ]);

    this.allRequestTypes = [
      {
        key: UserExpenseRequestType.Supplier,
        value: "Supplier",
      },
      {
        key: UserExpenseRequestType.Staff,
        value: "Staff",
      },
      {
        key: UserExpenseRequestType.Customer,
        value: "Customer",
      },
      {
        key: UserExpenseRequestType.Operatives,
        value: "Operative",
      },
      {
        key: UserExpenseRequestType.Miscellanous,
        value: "Miscellanous",
      },
    ];

    this.cols = [
      { field: "batch", header: "Batch" },
      { field: "description", header: "Description" },
      { field: "requester", header: "Requester" },
      { field: "dateRequested", header: "Date Requested" },
      { field: "requestType", header: "Request Type" },
    ];

    this.FetchAllClients();
    this.FetchAllSuppliers();
    this.FetchAllUsers();
    this.FetchAllOperatives();
  }

  async FetchAllSuppliers() {
    this.supplierService.getAll().subscribe(
      async (data) => {
        this.allSuppliers = data.responseData;
      },
      (error) => {
        this.messageService.add({
          severity: "error",
          summary: "Failed",
          detail: error ?? "Some errors occurred",
        });
      }
    );
  }

  async FetchAllClients() {
    this.clientService.allCustomerDivision().subscribe(
      async (data) => {
        this.allClients = data.responseData;
      },
      (error) => {
        this.messageService.add({
          severity: "error",
          summary: "Failed",
          detail: error ?? "Some errors occurred",
        });
      }
    );
  }

  async FetchAllUsers() {
    this.userService.allUser().subscribe(
      async (res) => {
        var data = res.responseData;
        this.allUsers = [];
        data.forEach((user) => {
          user.fullName = user.lastName + " " + user.firstName;
          this.allUsers.push(user);
        });
      },
      (err) => {
        console.log(err);
        this.messageService.add({
          severity: "error",
          summary: "Failed",
          detail: err ?? "Some errors occurred",
        });
      }
    );
  }

  async FetchAllOperatives() {
    this.employmentService.GetAllOperativesDataOnly().subscribe(
      async (data) => {
        this.allOperatives = [];
        data.forEach((x) => {
          x.guardName =
            x.jobApplication.personalInformation.lastname +
            " " +
            x.jobApplication.personalInformation.firstname +
            " " +
            x.jobApplication.personalInformation.othername;
          this.allOperatives.push(x);
        });
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all operatives at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  OnRequestTypeChange() {
    this.theClient = null;
    this.theSupplier = null;
    this.theOperative = null;
    this.theUser = null;
  }

  SearchUserRequests() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Searching Expense Batch Request(s)...",
    });

    this.showBatchList = true;
    this.showTrackInfo = false;
    this.fetchingBatchReq = true;
    const postData: SearchExpenseBatchesVM = {
      dateFrom: this.dateFrom,
      dateTo: this.dateTo,
    };

    if (this.theRequestType) {
      postData.requestType = this.theRequestType.key;
      if (this.theRequestType.key == UserExpenseRequestType.Supplier)
        if (this.theSupplier) postData.entityId = this.theSupplier.id;
      if (this.theRequestType.key == UserExpenseRequestType.Customer)
        if (this.theClient) postData.entityId = this.theClient.id;
      if (this.theRequestType.key == UserExpenseRequestType.Operatives)
        if (this.theClient) postData.entityId = this.theOperative.id;
      if (this.theRequestType.key == UserExpenseRequestType.Staff)
        if (this.theClient) postData.entityId = this.theUser.id;
    }

    this.financeExpenseService.SearchExpenseBatches(postData).subscribe(
      async (data) => {
        if (data.responseCode != "00") {
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail: data.responseMsg,
          });
          this.fetchingBatchReq = false;
          return;
        }

        this.messageService.add({
          severity: "success",
          summary: "Completed",
          detail: "Search Completed Successfully!",
        });

        this.allBatchRequests = data.responseData;
        this.fetchingBatchReq = false;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to search expense batch requests at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
        this.fetchingBatchReq = false;
      }
    );
  }

  GetUsername(userId: number): string {
    let sf = this.allUsers.find((x) => x.id == userId);
    if (sf)
      return (
        sf.lastName.toUpperCase() +
        " " +
        sf.firstName.toUpperCase() +
        " " +
        (sf.otherName == null ? "" : sf.otherName.toUpperCase())
      );

    return "N/A";
  }

  GetEntityName(item: ExpenseBatchRequest): string {
    if (item.requestType == UserExpenseRequestType.Customer) {
      let customer = this.allClients.find((x) => item.clientId);
      if (customer) return customer.divisionName;
    } else if (item.requestType == UserExpenseRequestType.Operatives) {
      let op = this.allOperatives.find((x) => x.id == item.operativeId);
      if (op)
        return (
          op.jobApplication.personalInformation.lastname +
          " " +
          op.jobApplication.personalInformation.firstname +
          " " +
          op.jobApplication.personalInformation.othername
        );
    } else if (item.requestType == UserExpenseRequestType.Staff) {
      return this.GetUsername(item.staffId);
    } else if (item.requestType == UserExpenseRequestType.Supplier) {
      let suppl = this.allSuppliers.find((x) => x.id == item.supplierId);
      if (suppl) return suppl.supplierName;
    } else if (item.requestType == UserExpenseRequestType.Miscellanous) {
      return "Miscellaneous";
    }

    return "N/A";
  }

  GetRequestTypeCaption(requestTypeId: number): string {
    let reqType = this.allRequestTypes.find((x) => x.key == requestTypeId);
    if (reqType) return reqType.value;
    return "N/A";
  }

  TrackBatch(item: ExpenseBatchRequest) {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Searching Expense Batch Request(s)...",
    });

    this.trackedBatchName = item.subject;
    this.showTrackInfo = true;
    this.trackingExpenseBatch = true;
    this.financeExpenseService.TrackExpenseBatchRequest(item.id).subscribe(
      async (data) => {
        if (data.responseCode != "00") {
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail: data.responseMsg,
          });
          this.fetchingBatchReq = false;
          return;
        }

        this.messageService.add({
          severity: "success",
          summary: "Completed",
          detail: "Tracking Expense Batch Request Completed Successfully!",
        });
        this.batchTrackingInfo = data.responseData;
        this.trackingExpenseBatch = false;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to track expense batch request at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
        this.trackingExpenseBatch = false;
      }
    );
  }

  ViewTrackDetail(item: any) {}
}
