import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MessageService, ConfirmationService } from "primeng/api";
import { BreadcrumbService } from "src/app/breadcrumb.service";
import {
  CreateExpenseRequesterAttachmentVM,
  ExpenseLineRequester,
} from "src/app/interfaces/finance-expense";
import { ServiceCategory, User } from "src/app/interfaces/home";
import { FinanceExpenseService } from "src/app/services/finance-expense.service";
import { ServiceCategoryService } from "src/app/services/service-category.service";
import { UserService } from "src/app/services/user.service";

@Component({
  selector: "app-expense-requester-attachment",
  templateUrl: "./expense-requester-attachment.component.html",
  styleUrls: ["./expense-requester-attachment.component.scss"],
})
export class ExpenseRequesterAttachmentComponent implements OnInit {
  @ViewChild("formWrapper") public formWrapper: ElementRef;
  attachRequesterForm: FormGroup;
  allServiceCategories: ServiceCategory[];
  selectedServiceCategories: ServiceCategory[] = [];
  allUsers: User[];
  selecedUserRequesters: User[] = [];
  fetchingUserRequesters: boolean;
  allUserRequesters: {
    categoryName: string;
    requesters: User[];
  }[];
  userRequestersCols: any[];
  requestersCols: any[];

  constructor(
    fb: FormBuilder,
    public financeExpenseService: FinanceExpenseService,
    public userService: UserService,
    public serviceCategoryService: ServiceCategoryService,
    public messageService: MessageService,
    private breadcrumbService: BreadcrumbService,
    public confirmationService: ConfirmationService
  ) {
    this.attachRequesterForm = fb.group({
      ServiceCateg: ["", Validators.required],
      Requester: ["", Validators.required],
    });
  }

  ngOnInit(): void {
    this.breadcrumbService.setItems([
      {
        label: "Finance",
        routerLink: ["/home/finance"],
      },
      {
        label: "Expense Tool",
        routerLink: ["/home/finance/expense"],
      },
      {
        label: "Expense Requester Attachment",
        routerLink: ["/home/finance/expense/requester-attachment"],
      },
    ]);

    this.userRequestersCols = [
      { field: "categoryName", header: "Expense Category" },
    ];

    this.requestersCols = [
      { field: "imageUrl", header: "Image" },
      { field: "firstName", header: "First Name" },
      { field: "lastName", header: "Last Name" },
      { field: "email", header: "Email" },
      { field: "mobileNumber", header: "Mobile" },
    ];

    this.GetAllServiceCategories();
  }

  async GetAllServiceCategories() {
    this.serviceCategoryService.GetAllServiceCategories().subscribe(
      async (data) => {
        if (data.responseCode == "00") {
          this.allServiceCategories = data.responseData;
          this.GetAllUsers();
        } else {
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail: data.responseMsg,
          });
        }
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all service categories at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  async GetAllUsers() {
    this.userService.allUser().subscribe(
      async (r) => {
        if (r.responseCode == "00") {
          var data = r.responseData ?? [];
          this.allUsers = [];
          data.forEach((user) => {
            user.fullName = user.lastName + " " + user.firstName;
            this.allUsers.push(user);
          });
          this.GetAllExpenseRequestAttachments();
        } else {
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail: r.responseMsg,
          });
        }
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all users at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  async GetAllExpenseRequestAttachments() {
    this.fetchingUserRequesters = true;
    this.financeExpenseService.GetExpenseRequesterAttachments().subscribe(
      async (data) => {
        if (data.responseCode == "00") {
          let groupedByCateg = [
            ...new Set(
              data.responseData.map((reqAttach) => reqAttach.serviceCategoryId)
            ),
          ];
          this.allUserRequesters = [];
          groupedByCateg.forEach((categ) => {
            let categRequesters = data.responseData.filter(
              (x) => x.serviceCategoryId == categ
            );
            let categRequestersIds = categRequesters.map((x) => x.requesterId);
            let userReq: {
              categoryName: string;
              requesters: User[];
            } = {
              categoryName: this.GetServiceCategoryLabel(categ),
              requesters: this.allUsers.filter(
                (x) => categRequestersIds.find((y) => y == x.id) != null
              ),
            };
            this.allUserRequesters.push(userReq);
          });
          this.fetchingUserRequesters = false;
        } else {
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail: data.responseMsg,
          });
          this.fetchingUserRequesters = false;
        }
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all expense request attachments at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
        this.fetchingUserRequesters = false;
      }
    );
  }

  CreateRequesterAttachment() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Creating Expense Requester Attachment...",
    });

    const postData: CreateExpenseRequesterAttachmentVM = {
      applicableCategoriesIds: this.selectedServiceCategories.map((x) => x.id),
      requestersIds: this.selecedUserRequesters.map((x) => x.id),
    };

    this.financeExpenseService
      .CreateExpenseRequesterAttachment(postData)
      .subscribe(
        async (data) => {
          if (data.responseCode != "00") {
            this.messageService.add({
              severity: "error",
              summary: "Notice",
              detail: data.responseMsg,
            });
            return;
          }

          this.messageService.add({
            severity: "success",
            summary: "Completed",
            detail: "Expense Requester Attachment Created Successfully!",
          });

          this.attachRequesterForm.reset();
          this.selecedUserRequesters = [];
          this.selectedServiceCategories = [];
          this.GetAllExpenseRequestAttachments();
        },
        (error) => {
          console.log("Error: " + JSON.stringify(error));
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail:
              "Unable to create expense requester attachments at the moment.. Reason: [" +
              (error ? error.error.message : "request failed - permission") +
              "]",
          });
        }
      );
  }

  GetUsernameLabel(userId: number): string {
    let user = this.allUsers.find((x) => x.id == userId);
    if (user) return user.fullName;

    return "N/A";
  }

  GetServiceCategoryLabel(identifier: number): string {
    let servCateg = this.allServiceCategories.find((x) => x.id == identifier);
    if (servCateg) return servCateg.name;

    return "N/A";
  }

  DeleteExpenseLine(item: ExpenseLineRequester) {
    this.confirmationService.confirm({
      message: "Are you sure you want to remove expense requester attachment?",
      accept: () => {
        this.messageService.add({
          severity: "info",
          summary: "Notice",
          detail: "Removing expense requester attachment...",
        });

        this.financeExpenseService
          .DeleteExpenseRequesterAttachment(item.id)
          .subscribe(
            async () => {
              await this.messageService.add({
                severity: "success",
                summary: "Removed",
                detail: "Removed successfully",
              });
              this.GetAllExpenseRequestAttachments();
            },
            (error) => {
              console.log("Error: " + JSON.stringify(error));
              this.messageService.add({
                severity: "error",
                summary: "Notice",
                detail:
                  "Unable to remove expense requester attachment at the moment.. Reason: [" +
                  error
                    ? error.error.message
                    : "request failed - permission" + "]",
              });
            }
          );
      },
    });
  }
}
