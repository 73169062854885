import { Component,Input, OnInit, Output, EventEmitter } from '@angular/core';
import { ReactiveFormsModule, FormBuilder, Validators } from '@angular/forms';
import { BudgetapiservicesService } from 'src/app/services/budget/budgetapiservices.service';
import { ShowBudgetSeasonComponent } from '../show-budget-season/show-budget-season.component';
import { ActivatedRoute, Router } from '@angular/router';
import {  ConfirmationService, MessageService } from 'primeng/api';
import {ConfirmEventType} from 'src/app/interfaces/armada';
import { Subscription } from 'rxjs';

interface BudgetSeason{
  id: number,
  caption: string,
  budgetYear: Date,
  createdAt:Date,
  description: string,
  isPublished: boolean,
  prepEndDate: Date,
  prepStartDate: Date
}

@Component({
  selector: 'app-add-edit-budget-season',
  templateUrl: './add-edit-budget-season.component.html',
  styleUrls: ['./add-edit-budget-season.component.css'],
  providers: [ShowBudgetSeasonComponent],
})
export class AddEditBudgetSeasonComponent implements OnInit {
  
  budgetSeasonForm = this.fb.group({
    // id: [''],
    caption: ['', Validators.required],
    description: ['', Validators.required],
    prepStartDate: ['', Validators.required],
    prepEndDate: ['', Validators.required],
    budgetYear: ['', Validators.required]
  })

  constructor(private fb: FormBuilder, 
              private service: BudgetapiservicesService,
              private router: Router,
              private route: ActivatedRoute,
              private confirmationService: ConfirmationService,
              private messageService: MessageService) { }

  // @Input() seasonData: any;
  @Input() budgetSeason: any; 
  @Input() isEditingRecord: any;
  @Input() budgetSeasonsData: any;
  @Output("reloadPage") reloadPage: EventEmitter<any> = new EventEmitter();
  caption = "";
  description = "";
  prepStartDate: any;
  prepEndDate: any;
  budgetYear = "";
  seasonData: any;
  years = [];
  listOfBudgetSeasons: any[];
  captionExist: boolean = false;
  isFormBusy: boolean = false;
  message: string;
  selectedBudgetYear: number | undefined
  // isEditingRecord: boolean = false;
  
  range: any = []; 
  
  clickEventsubscription:Subscription;
  ngOnInit(): void {
    this.generateCalandarYear();

    this.service.onBudgetSeasonEditClick.subscribe((data) => {
      this.seasonData = data;
      console.log(this.seasonData);
      this.populateRecord();
      console.log(this.isEditingRecord)
    });

    this.service.onBudgetSeasonCancelEditClick.subscribe(() => {
      this.clearForm();
    })

    
    this.getListOfBudgetSeasons();
    // this.clickEventsubscription = this.service.getClickEvent().subscribe(() => {
    //   this.populateRecord()
    // })
  }
  

  generateCalandarYear(){
    var year = new Date().getFullYear();
    console.log(year);
    this.range.push(year);
    this.range.push(++year);
    // for (let i = 1; i < 1; i++) {
    //   this.range.push(year + i);
    // }
    this.years = this.range;
  }

  createBudgetSeason() {
    // VALIDATION OF NOT MORE THAN 2 BUDGET SEASONS
    // console.log(`Selected year: ${this.selectedBudgetYear}`)
    let seasonsInYear = this.listOfBudgetSeasons.filter((rec) => {
      return rec.budgetYear ===this.selectedBudgetYear
    });
    console.log(seasonsInYear);
    if (seasonsInYear.length > 2) {
      this.displayMessage("warn", "Warning", "Cannot have more than two (2) budget seasons in a year")
      return false;
    }

    this.captionExist = false;
    

    if (this.validateEntry()==false){
      this.displayMessage("warn", "information", "End date cannot be lower than start date");
      return false;
    } 
    
    this.confirmationService.confirm({
      message: this.isEditingRecord ? `Proceed with updating this record?` : `Proceed with saving this record?`,
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.isFormBusy = true;
        // this.messageService.add({ severity: 'info', summary: 'Confirmed', detail: 'You have accepted' });
        this.displayMessage("info", "Information", "Processing operation")
        this.confirmUpsert();
        
      },
      reject: (type: ConfirmEventType) => {
          switch (type) {
              case ConfirmEventType.REJECT:
                  this.displayMessage("warn","Cancelled","You have cancelled this operation");
                  break;
              case ConfirmEventType.CANCEL:
                  this.displayMessage("warn","Cancelled","You have cancelled this operation");
                  break;
          }
        }
    });
  }
  confirmUpsert(){
    console.log(this.isEditingRecord);
    // return;
    
    // return;
    
    if (!this.isEditingRecord) {
      this.preventDuplicateCaption();
      if (this.captionExist){
        this.displayMessage("error", "Error", `A season with the same name already exist`);
        return false;
      }
      var data = this.budgetSeasonForm.value;
      console.log(data)
      // return;
      this.displayMessage('info', 'Information', "Saving record");
      this.service.createBudgetSeason(data).subscribe( res => {
          console.log(res);
          if(res["responseCode"] =="00") {
            setTimeout(() => {
              this.displayMessage("success", "Success", "Budget season created successfully");
  
            }, 2000);
           this.reloadPage.emit();
          }
          else {
            setTimeout(() => {
              this.displayMessage("error", "Error", `Error creating record: ${res["responseMsg"]}`)
              
            }, 2000)
          }

          
      }); 
    }
    else {
      
      var data: any = this.budgetSeasonForm.value;
      data['id'] = this.seasonData.id;
      data['isPublished'] = this.seasonData.isPublished;
      console.log(data);
      // return;
      console.log(`STATUS OF EDIT: ${this.isEditingRecord}`);
      this.displayMessage('info', 'Information', "Saving record");
      this.service.updateBudgetSeason(data).subscribe( resp => {
          console.log(resp);
          if(resp["responseCode"] =="00") {
            setTimeout(() => {
              this.displayMessage("success", "Success", "Budget season updated successfully");
  
            }, 3000);
           this.reloadPage.emit();
          }
          else {
            setTimeout(() => {
              this.displayMessage("error", "Error", "Error updating record")
              
            }, 2000)
          }
          
      }); 
    }
    this.isFormBusy = false;
    
     
  }
  
  validateEntry() {
      
      if (new Date(this.prepStartDate).valueOf() > new Date(this.prepEndDate).valueOf()) {        
        return false;
      }
  }

  // reloadPage(){
  //   this.router.routeReuseStrategy.shouldReuseRoute = () => false;
  //   this.router.onSameUrlNavigation = 'reload';
  //   this.router.navigate(['./'], {
  //     relativeTo: this.route
  //   });
  // }

  compareDates(event) {
    console.log(event)
    console.log(this.prepEndDate)
    if (this.prepEndDate.getTime() < this.prepStartDate.getTime())
    {
      this.displayMessage("warn", "information", "End date cannot be lower than start date");
      
      console.log("End date cannot be lower than start date")
    }
  }

  displayMessage(severity, summary, message) {
    this.messageService.add({
      severity: severity,
      summary: summary,
      detail: message,
    });
  }

  getListOfBudgetSeasons(){
    this.service.getBudgetSeasons().subscribe(data => {
      this.listOfBudgetSeasons = data['responseData'];
      console.log(data);
      // this.loadingData = false;
      console.log(this.listOfBudgetSeasons.length );
      console.log(typeof(this.listOfBudgetSeasons));
      console.log(this.listOfBudgetSeasons);
      
      // this.caption = this.seasonData.Caption;
      // this.prepEndDate = this.seasonData.PrepEndDate;
      // this.cyclesCreated = this.seasonData.CreatedCycle;
      // this.isPublished = this.seasonData.isPublished;
    });
  }

  checkIfCaptionExist(e: any){
    // this.listOfBudgetSeasons.forEach(item => {
    //   if (item.caption.toLowerCase() === e.toLowerCase()){
    //     this.captionExist = true;
    //     console.log(this.caption);
    //   }
    //   else{
    //     this.captionExist = false;
    //   }
    // })
  }

  preventDuplicateCaption(){
    // this.listOfBudgetSeasons.includes(this.budgetSeasonForm.get('caption').value.toLowerCase()))
    this.listOfBudgetSeasons.forEach(item => {
      if (item.caption.toLowerCase() === this.budgetSeasonForm.get('caption').value.toLowerCase()){
        this.captionExist = true;
        console.log(this.caption);
        return false;
      }
      
    })
  }

  populateRecord() {
    this.budgetSeasonForm.patchValue({
      // id: this.seasonData.id == "" ? 0 : this.seasonData.id,
      caption: this.seasonData.caption,
      description: this.seasonData.description,
      prepStartDate: new Date(this.seasonData.prepStartDate),
      prepEndDate: new Date(this.seasonData.prepEndDate),
      budgetYear: this.seasonData.budgetYear,
      
    });
    // console.log("long")
    // console.log(this.budgetSeason)
  }

  clearForm() {

    this.budgetSeasonForm.get('caption').setValue('');
    this.budgetSeasonForm.get('description').setValue('');
    this.budgetSeasonForm.get('prepStartDate').setValue('');
    this.budgetSeasonForm.get('prepEndDate').setValue('');
    this.budgetSeasonForm.get('budgetYear').setValue('');
  }

}
