import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ConfirmationService, MessageService } from "primeng/api";
import { BreadcrumbService } from "src/app/breadcrumb.service";
import {
  CreateMergeRequestVM,
  RepAmortizationMaster,
} from "src/app/interfaces/client-merge";
import { CustomerContact } from "src/app/interfaces/customer";
import {
  AdvancePayment,
  CommonResponse,
  Contract,
  CustomerDivision,
  Invoice,
} from "src/app/interfaces/home";
import { ClientMergeService } from "src/app/services/client-merge.service";
import { CustomerDivisionService } from "src/app/services/customer-division.service";
import { FileStorageService } from "src/app/services/file-storage.service";
import { GmaCustomerService } from "src/app/services/gma-customer.service";

@Component({
  selector: "app-client-merge-request",
  templateUrl: "./client-merge-request.component.html",
  styleUrls: ["./client-merge-request.component.scss"],
})
export class ClientMergeRequestComponent implements OnInit {
  @ViewChild("formWrapper") public formWrapper: ElementRef;
  allClients: CustomerDivision[];
  theClientToMerge: CustomerDivision;
  theMasterClient: CustomerDivision;
  clientToMergeForm: FormGroup;
  masterClientForm: FormGroup;
  toMergeImageSrc: string;
  masterImageSrc: string;
  allIndustries: {
    key: string;
    value: string;
  }[] = [
    {
      key: "Advertising",
      value: "Advertising",
    },
    {
      key: "Agriculture",
      value: "Agriculture",
    },
    {
      key: "Banking & Finance",
      value: "Banking & Finance",
    },
    {
      key: "Construction",
      value: "Construction",
    },
    {
      key: "Education",
      value: "Education",
    },
    {
      key: "Engineering",
      value: "Engineering",
    },
    {
      key: "Health Care",
      value: "Health Care",
    },
    {
      key: "Hospitality",
      value: "Hospitality",
    },
    {
      key: "ICT",
      value: "ICT",
    },
    {
      key: "Insurance",
      value: "Insurance",
    },
    {
      key: "Manufacturing",
      value: "Manufacturing",
    },
    {
      key: "Real Estate",
      value: "Real Estate",
    },
    {
      key: "Safety & Security",
      value: "Safety & Security",
    },
    {
      key: "Telecommunication",
      value: "Telecommunication",
    },
    {
      key: "Transport",
      value: "Transport",
    },
    {
      key: "Other",
      value: "Other",
    },
  ];
  theIndustryForClientToMerge: {
    key: string;
    value: string;
  };
  theIndustryForMasterClient: {
    key: string;
    value: string;
  };
  attachedClientToMergeContacts: CustomerContact[];
  attachedMasterClientContacts: CustomerContact[];
  carouselResponsiveOptions: any[] = [
    {
      breakpoint: "1024px",
      numVisible: 3,
      numScroll: 3,
    },
    {
      breakpoint: "768px",
      numVisible: 2,
      numScroll: 2,
    },
    {
      breakpoint: "560px",
      numVisible: 1,
      numScroll: 1,
    },
  ];
  openRequestDialogue: boolean;
  requestJustification: string;
  showUplGrid: boolean = true;
  uploadedFiles: any[] = [];
  userIsYetToClickUploadFile: boolean;
  isProcessingRequest: boolean;
  allClientToMergeContracts: Contract[] = [];
  allMasterClientContracts: Contract[] = [];
  clientMergeDetailsMap: {
    clientId: number;
    contracts: Contract[];
    armotizations: RepAmortizationMaster[];
    invoices: Invoice[];
    advances: AdvancePayment[];
  }[] = [];
  allClientToMergeAmortizations: RepAmortizationMaster[] = [];
  allMasterClientAmortizations: RepAmortizationMaster[] = [];
  allClientToMergeInvoices: Invoice[] = [];
  allMasterClientInvoices: Invoice[] = [];
  allClientToMergeAdvances: AdvancePayment[] = [];
  allMasterClientAdvances: AdvancePayment[] = [];

  constructor(
    private fb: FormBuilder,
    public clientService: CustomerDivisionService,
    public clientMergeService: ClientMergeService,
    private breadcrumbService: BreadcrumbService,
    public confirmationService: ConfirmationService,
    public customerService: GmaCustomerService,
    private fileStorageService: FileStorageService,
    public messageService: MessageService
  ) {
    this.clientToMergeForm = fb.group({
      ImageUrl: [""],
      Name: ["", Validators.required],
      Email: ["", Validators.required],
      Mobile: ["", Validators.required],
      Industry: ["", Validators.required],
    });
    this.masterClientForm = fb.group({
      ImageUrl: [""],
      Name: ["", Validators.required],
      Email: ["", Validators.required],
      Mobile: ["", Validators.required],
      Industry: ["", Validators.required],
    });
  }

  ngOnInit(): void {
    this.breadcrumbService.setItems([
      {
        label: "Client Management",
      },
      {
        label: "Client Merge",
      },
      {
        label: "Create Client Merge Request",
        routerLink: ["/home/client-management/client-merge-request"],
      },
    ]);

    this.FetchAllClients();
  }

  async FetchAllClients() {
    this.clientService.allCustomerDivision().subscribe(
      async (data) => {
        this.allClients = data.responseData;
      },
      (error) => {
        this.messageService.add({
          severity: "error",
          summary: "Failed",
          detail: error ?? "Some errors occurred",
        });
      }
    );
  }

  OnClientToMergeChange() {
    if (this.theClientToMerge) {
      this.toMergeImageSrc = this.theClientToMerge.logoUrl;
      this.clientToMergeForm.patchValue({
        ImageUrl: "",
        Name: this.theClientToMerge.divisionName,
        Email: this.theClientToMerge.email,
        Mobile: this.theClientToMerge.phoneNumber,
      });
      this.theIndustryForClientToMerge = this.allIndustries.find(
        (x) => x.key == this.theClientToMerge.industry
      );
      if (this.theClientToMerge.customer) {
        this.attachedClientToMergeContacts =
          this.theClientToMerge.customer.contacts.filter(
            (x) => x.isDeleted == false
          );
      } else {
        this.customerService
          .GetClientInformation(this.theClientToMerge.id)
          .subscribe(
            async (data) => {
              this.theClientToMerge = data;
              this.attachedClientToMergeContacts =
                this.theClientToMerge.customer.contacts.filter(
                  (x) => x.isDeleted == false
                );
            },
            (error) => {
              console.log("Error: " + JSON.stringify(error));
              this.messageService.add({
                severity: "error",
                summary: "Notice",
                detail:
                  "Unable to fetch client contact(s) info at the moment.. Reason: [" +
                  error.error.message +
                  "]",
              });
            }
          );
      }

      let clientMergeDetails = this.clientMergeDetailsMap.find(
        (x) => x.clientId == this.theClientToMerge.id
      );
      if (clientMergeDetails == null) {
        this.clientMergeService
          .GetClientMergeDetails(this.theClientToMerge.id)
          .subscribe(
            async (data) => {
              if (data.responseCode != "00") {
                this.messageService.add({
                  severity: "error",
                  summary: "Notice",
                  detail: data.responseMsg,
                });
                return;
              }

              clientMergeDetails = {
                clientId: this.theClientToMerge.id,
                contracts: data.responseData.contracts,
                armotizations: data.responseData.repAmortizationMasters,
                invoices: data.responseData.invoices,
                advances: data.responseData.advancePayments,
              };
              this.clientMergeDetailsMap.push(clientMergeDetails);
              this.allClientToMergeContracts = clientMergeDetails.contracts;
              this.allClientToMergeAmortizations =
                clientMergeDetails.armotizations;
              this.allClientToMergeInvoices = clientMergeDetails.invoices;
              this.allClientToMergeAdvances = clientMergeDetails.advances;
            },
            (error) => {
              console.log("Error: " + JSON.stringify(error));
              this.messageService.add({
                severity: "error",
                summary: "Notice",
                detail:
                  "Unable to fetch client merge detail(s) at the moment.. Reason: [" +
                  error.error.message +
                  "]",
              });
            }
          );
      } else {
        this.allClientToMergeContracts = clientMergeDetails.contracts;
        this.allClientToMergeAmortizations = clientMergeDetails.armotizations;
        this.allClientToMergeInvoices = clientMergeDetails.invoices;
        this.allClientToMergeAdvances = clientMergeDetails.advances;
      }
    }
  }

  OnMasterClientChange() {
    if (this.theMasterClient) {
      this.masterImageSrc = this.theMasterClient.logoUrl;
      this.masterClientForm.patchValue({
        ImageUrl: "",
        Name: this.theMasterClient.divisionName,
        Email: this.theMasterClient.email,
        Mobile: this.theMasterClient.phoneNumber,
      });
      this.theIndustryForMasterClient = this.allIndustries.find(
        (x) => x.key == this.theMasterClient.industry
      );
      if (this.theMasterClient.customer) {
        this.attachedMasterClientContacts =
          this.theMasterClient.customer.contacts.filter(
            (x) => x.isDeleted == false
          );
      } else {
        this.customerService
          .GetClientInformation(this.theMasterClient.id)
          .subscribe(
            async (data) => {
              this.theMasterClient = data;
              this.attachedMasterClientContacts =
                this.theMasterClient.customer.contacts.filter(
                  (x) => x.isDeleted == false
                );
            },
            (error) => {
              console.log("Error: " + JSON.stringify(error));
              this.messageService.add({
                severity: "error",
                summary: "Notice",
                detail:
                  "Unable to fetch client contact(s) info at the moment.. Reason: [" +
                  error.error.message +
                  "]",
              });
            }
          );
      }

      let clientMergeDetails = this.clientMergeDetailsMap.find(
        (x) => x.clientId == this.theMasterClient.id
      );
      if (clientMergeDetails == null) {
        this.clientMergeService
          .GetClientMergeDetails(this.theMasterClient.id)
          .subscribe(
            async (data) => {
              if (data.responseCode != "00") {
                this.messageService.add({
                  severity: "error",
                  summary: "Notice",
                  detail: data.responseMsg,
                });
                return;
              }

              clientMergeDetails = {
                clientId: this.theMasterClient.id,
                contracts: data.responseData.contracts,
                armotizations: data.responseData.repAmortizationMasters,
                invoices: data.responseData.invoices,
                advances: data.responseData.advancePayments,
              };
              this.clientMergeDetailsMap.push(clientMergeDetails);
              this.allMasterClientContracts = clientMergeDetails.contracts;
              this.allMasterClientAmortizations =
                clientMergeDetails.armotizations;
              this.allMasterClientInvoices = clientMergeDetails.invoices;
              this.allMasterClientAdvances = clientMergeDetails.advances;
            },
            (error) => {
              console.log("Error: " + JSON.stringify(error));
              this.messageService.add({
                severity: "error",
                summary: "Notice",
                detail:
                  "Unable to fetch client merge detail(s) at the moment.. Reason: [" +
                  error.error.message +
                  "]",
              });
            }
          );
      } else {
        this.allMasterClientContracts = clientMergeDetails.contracts;
        this.allMasterClientAmortizations = clientMergeDetails.armotizations;
        this.allMasterClientInvoices = clientMergeDetails.invoices;
        this.allMasterClientAdvances = clientMergeDetails.advances;
      }
    }
  }

  RequestForClientMerge() {
    if (this.theClientToMerge && this.theMasterClient) {
      if (this.theClientToMerge.id == this.theMasterClient.id) {
        this.messageService.add({
          severity: "error",
          summary: "Invalid Action",
          detail: "You can't merge the same clients together.",
        });
        return;
      }

      this.openRequestDialogue = true;
    }
  }

  HideRequestDialog() {
    this.openRequestDialogue = false;
    this.requestJustification = null;
    this.uploadedFiles = [];
    this.isProcessingRequest = false;
  }

  onUploadFile(event) {
    this.uploadedFiles = [];
    for (const file of event.files) {
      this.uploadedFiles.push(file);
    }

    this.messageService.add({
      severity: "success",
      summary: "Success",
      detail: "File(s) Uploaded",
    });
    this.userIsYetToClickUploadFile = false;
  }

  onCancelUpload() {
    this.uploadedFiles = [];
  }

  NotifyUserToClickUpload() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Kindly ensure you click upload.",
    });

    this.userIsYetToClickUploadFile = true;
  }

  async SendRequestForApproval() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Creating Client Merge Request...",
    });

    this.isProcessingRequest = true;
    const postData: CreateMergeRequestVM = {
      clientToMergeId: this.theClientToMerge.id,
      masterClientId: this.theMasterClient.id,
      justification: this.requestJustification,
      evidenceUrls: [],
    };

    if (this.uploadedFiles.length > 0) {
      this.fileStorageService.UploadMultipleFilesFromDataUrl(
        this.uploadedFiles
      );
      this.fileStorageService.onUploadFinished.subscribe(
        (resp: CommonResponse<string[]>) => {
          if (resp.responseCode != "00") {
            this.messageService.add({
              severity: "error",
              summary: "Notice",
              detail: resp.responseMsg,
            });
            this.isProcessingRequest = false;
          } else {
            postData.evidenceUrls = resp.responseData;
            this._sendRequestForApproval(postData);
          }
        },
        (error) => {
          console.log("Error while uploading files " + error);
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail: "ERR: Unable to upload file(s) to storage",
          });
          this.isProcessingRequest = false;
        }
      );
    } else this._sendRequestForApproval(postData);
  }

  _sendRequestForApproval(postData: CreateMergeRequestVM) {
    this.clientMergeService.CreateMergeRequest(postData).subscribe(
      async (data) => {
        if (data.responseCode != "00") {
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail: data.responseMsg,
          });
          this.isProcessingRequest = false;
          return;
        }

        this.messageService.add({
          severity: "success",
          summary: "Completed",
          detail: "Client Merge Request Submitted Successfully!",
        });

        this.HideRequestDialog();
        this.theMasterClient = null;
        this.theClientToMerge = null;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to process client merge request at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
        this.isProcessingRequest = false;
      }
    );
  }

  FormatDateString(date: Date): string {
    if (!date) return "N/A";
    let dateString = date.toString();

    try {
      // DateTimeFormatOptions
      const date: Date = new Date(dateString);
      const options: Intl.DateTimeFormatOptions = {
        year: "numeric",
        month: "long",
        day: "numeric",
      };
      const formattedDate: string = new Intl.DateTimeFormat(
        "en-US",
        options
      ).format(date);
      dateString = formattedDate;
    } catch (error) {
      console.log(error);
      console.log(date);
      console.log(
        "Error while converting date string " + date + " exception " + error
      );
    }

    return dateString;
  }

  GetMonthString(identifier: number): string {
    if (identifier == 1) return "JANUARY";
    if (identifier == 2) return "FEBRUARY";
    if (identifier == 3) return "MARCH";
    if (identifier == 4) return "APRIL";
    if (identifier == 5) return "MAY";
    if (identifier == 6) return "JUNE";
    if (identifier == 7) return "JULY";
    if (identifier == 8) return "AUGUST";
    if (identifier == 9) return "SEPTEMPER";
    if (identifier == 10) return "OCTOBER";
    if (identifier == 11) return "NOVEMBER";
    if (identifier == 12) return "DECEMBER";

    return "N/A";
  }
}
