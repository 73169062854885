import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { ConfirmationService, MessageService, TreeNode } from "primeng/api";
import { BreadcrumbService } from "src/app/breadcrumb.service";
import { GuardDeployment } from "src/app/interfaces/employment";
import {
  ApprovePayrollOperationsVM,
  PayrollApprovingOffice,
  PayrollRun,
  PayrollRunOperation,
  PayrollRunTypeEnum,
} from "src/app/interfaces/payroll";
import { PayrollService } from "src/app/services/payroll.service";

@Component({
  selector: "app-payroll-approval2",
  templateUrl: "./payroll-approval2.component.html",
  styleUrls: ["./payroll-approval2.component.scss"],
})
export class PayrollApproval2Component implements OnInit {
  @ViewChild("formWrapper") public formWrapper: ElementRef;
  cautionForm: FormGroup;
  allPendingApprovals: PayrollRun[] = [];
  selectedPendingApprovals: PayrollRun[] = [];
  pendingApprovalCols: any[];
  payrollRunInView: PayrollRun;
  officeId: number;
  officerId: number;
  officeSequence: number;
  allOfficesToApprove: PayrollApprovingOffice[];
  isViewingStats: boolean = false;
  isViewingDetails: boolean = false;
  allApprovalInViewDetails: PayrollRunOperation[] = [];
  selectedApprovalDetails: PayrollRunOperation[] = [];
  approvalViewDitsCols: any[];
  openBreakdownDialogue: boolean;
  operationTree: TreeNode[];
  selectedOperationTree: TreeNode[];
  payrollOperationInView: PayrollRunOperation;
  openApprovalsDialogue: boolean;
  allRequestApprovals: any[];
  openCautionDialogue: boolean;
  cautionText: string;
  cautionCode: number;
  totAllowance: string;
  totDeduct: string;
  totTax: string;
  totNetPay: string;
  allApprovalDetailsDeployments: GuardDeployment[] = [];
  cols: any[];

  constructor(
    private fb: FormBuilder,
    private payrollService: PayrollService,
    private breadcrumbService: BreadcrumbService,
    public confirmationService: ConfirmationService,
    public messageService: MessageService
  ) {
    this.cautionForm = fb.group({});
  }

  ngOnInit(): void {
    this.breadcrumbService.setItems([
      {
        label: "Payroll",
        routerLink: ["/home/payroll/payroll-settings"],
      },
      {
        label: "Single Payroll Approval",
        routerLink: ["/home/payroll/approval2"],
      },
    ]);

    this.pendingApprovalCols = [
      { field: "payGroup.caption", header: "PayGroup.caption" },
    ];

    this.approvalViewDitsCols = [
      { field: "location", header: "Location" },
      {
        field: "deployedGuard.jobApplication.personalInformation.firstname",
        header: "Firstname",
      },
      {
        field: "deployedGuard.jobApplication.personalInformation.lastname",
        header: "Lastname",
      },
      { field: "deployedGuardId", header: "OperativeID" },
      { field: "totalAllowancesFigure", header: "Total Allowance" },
      { field: "totalDeductionsFigure", header: "Total Deduction" },
      { field: "absenceDeductions", header: "Absence Deduction" },
      { field: "netPayFigure", header: "Net Pay" },
    ];

    this.cols = [
      { field: "name", header: "Name" },
      {
        field: "amount",
        header: "Amount",
      },
    ];

    this.FetchAllConfiguredApprovals();
    this.FetchAllApprovals();
  }

  FetchAllApprovals() {
    this.payrollService.GetAllPayrollApprovalItems().subscribe(
      async (data) => {
        this.allPendingApprovals = data.notPicked.filter(
          (x) => !x.isUsingBatchApproval
        );
        this.officeId = data.approvingOfficeId;
        this.officerId = data.approvingOfficerId;
        this.officeSequence = data.approvingOfficeSequence;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to get all approvals at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  FetchAllConfiguredApprovals() {
    this.payrollService.GetAllApprovingOffice().subscribe(
      async (data) => {
        this.allOfficesToApprove = data;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to get all approval offices to approve at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  GetRunTypeLabel(runType: number): string {
    if (runType == PayrollRunTypeEnum.TrialRun) return "Trial Run";
    if (runType == PayrollRunTypeEnum.FinalRun) return "Final Run";
    return "";
  }

  ViewDetailsBelow(item: PayrollRun) {
    this.payrollService
      .GetPayrollRunOperationsWithReportView(item.id)
      .subscribe(
        async (data) => {
          this.allApprovalDetailsDeployments = data.guardDeployments;
          // this.allApprovalInViewDetails = data.payrollOperations;
          this.PickBatchToActOn(item, data.payrollOperations);
        },
        (error) => {
          console.log("Error: " + JSON.stringify(error));
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail:
              "Unable to load payroll run report at the moment.. Reason: [" +
              error
                ? error.error.message
                : "request failed - permission" + "]",
          });
        }
      );
  }

  PickBatchToActOn(item: PayrollRun, data: PayrollRunOperation[]) {
    this.payrollRunInView = item;

    this.allApprovalInViewDetails = [];
    let office = this.allOfficesToApprove.find((x) => x.id == this.officeId);
    if (!office) return;
    data.forEach((operation) => {
      if (office.sequence == 1 && operation.approvals.length <= 0) {
        this.allApprovalInViewDetails.push(operation);
      } else {
        let sequenceInView = 1;
        while (sequenceInView <= office.sequence) {
          let sequenceOffices = this.allOfficesToApprove.filter(
            (x) => x.sequence == sequenceInView
          );

          let findApp = operation.approvals.find(
            (x) =>
              sequenceOffices.find((y) => y.id == x.payrollApprovingOfficeId) !=
              null
          );

          if (sequenceInView == office.sequence) {
            if (!findApp) {
              this.allApprovalInViewDetails.push(operation);
            }
          }
          if (findApp) {
            sequenceInView++;
          } else {
            break;
          }
        }
      }
    });

    let totalAllowance: number = 0;
    let totalDeduction: number = 0;
    let totalTax: number = 0;
    let totalNetPay: number = 0;

    this.allApprovalInViewDetails.forEach((operations) => {
      totalAllowance = totalAllowance + operations.totalAllowancesFigure;
      totalDeduction = totalDeduction + operations.totalDeductionsFigure;
      totalTax = totalTax + operations.appliedTaxAmount;
      totalNetPay = totalNetPay + operations.netPayFigure;
    });
    this.totAllowance = "N" + totalAllowance.toLocaleString();
    this.totDeduct = "N" + totalDeduction.toLocaleString();
    this.totTax = "N" + totalTax.toLocaleString();
    this.totNetPay = "N" + totalNetPay.toLocaleString();

    this.isViewingStats = true;
    this.isViewingDetails = true;
  }

  ViewOperationBreakdown(item: PayrollRunOperation) {
    this.operationTree = [];

    let allowanceTree: TreeNode = {
      data: {
        name: "Allowance(s)",
        amount: null,
      },
      children: [],
    };
    let deductionTree: TreeNode = {
      data: {
        name: "Deduction(s)",
        amount: null,
      },
      children: [],
    };

    item.allowances.forEach((allowance) => {
      allowanceTree.children.push({
        data: {
          name: allowance.allowanceName,
          amount: allowance.amount,
        },
      });
    });
    item.deductions.forEach((deduction) => {
      deductionTree.children.push({
        data: {
          name: deduction.deductionName,
          amount: deduction.amount,
        },
      });
    });

    this.operationTree.push(allowanceTree);
    this.operationTree.push(deductionTree);
    this.payrollOperationInView = item;
    this.openBreakdownDialogue = true;
  }

  HideBreakdownDialog() {
    this.openBreakdownDialogue = false;
    this.payrollOperationInView = null;
  }

  ApproveSelectedRequests() {
    this.cautionText =
      "Are you sure you want to approve this requests. This is an irreversible action. Do you still wish to proceed?";
    this.cautionCode = 1;
    this.openCautionDialogue = true;
  }

  ViewApprovals(item: any) {
    this.openApprovalsDialogue = true;
    this.allRequestApprovals = item.approvals;
  }

  HideAprovalsDialog() {
    this.openApprovalsDialogue = false;
    this.allRequestApprovals = [];
  }

  CautionAction() {
    if (this.cautionCode == 1) {
      this.messageService.add({
        severity: "info",
        summary: "Notice",
        detail: "Approving Requets...",
      });

      const postData: ApprovePayrollOperationsVM = {
        payrunId: this.payrollRunInView.id,
        requestIds: this.selectedApprovalDetails.map((x) => x.id),
      };

      this.payrollService.ApprovePayrollOperations(postData).subscribe(
        async (data) => {
          if (data.responseCode != "00") {
            this.messageService.add({
              severity: "error",
              summary: "Notice",
              detail: data.responeMessage,
            });
            return;
          }

          this.messageService.add({
            severity: "success",
            summary: "Completed",
            detail: "Approved Successfully!",
          });

          this.isViewingDetails = false;
          this.isViewingStats = false;
          this.payrollRunInView = null;
          this.allApprovalInViewDetails = [];
          this.selectedApprovalDetails = [];
          this.HideCautionDialog();
          this.FetchAllApprovals();
        },
        (error) => {
          console.log("Error: " + JSON.stringify(error));
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail:
              "Unable to approve requests at the moment.. Reason: [" + error
                ? error.error.message
                : "request failed - permission" + "]",
          });
        }
      );
    }
  }

  HideCautionDialog() {
    this.openCautionDialogue = false;
    this.cautionCode = null;
    this.cautionText = null;
    this.selectedApprovalDetails = [];
  }
}
