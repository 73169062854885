import { SeverityLevel } from './../../../interfaces/response';
//import { AcceptablePlanForPanic } from './../../../interfaces/response';
import { CommonResponse, Services } from './../../../interfaces/home';
import { ResponseTeamServiceService } from './../../../services/response/response-team-service.service';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/storage';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Subject } from 'rxjs';
import { BreadcrumbService } from 'src/app/breadcrumb.service';
import { AcceptablePlanForPanic, ResponseTeam, ResponseTeamRestingStationMap, ResponseTeamType } from 'src/app/interfaces/response';
import { InventoryService } from 'src/app/services/inventory.service';
import { ArmedEscortProfile, CommanderProfile, PilotProfile, VehicleProfile } from 'src/app/interfaces/armada';
import { takeUntil } from 'rxjs/operators';
import { RestingStationServiceService } from 'src/app/services/response/resting-station-service.service';
import { ServicesService } from 'src/app/services/services.service';

@Component({
  selector: 'app-response-team-type',
  templateUrl: './response-team-type.component.html',
  styleUrls: ['./response-team-type.component.scss']
})
export class ResponseTeamTypeComponent implements OnInit {
 @ViewChild("formWrapper") public formWrapper: ElementRef;
  private unsubscriber$ = new Subject<void>();



  public targetCols: any;
  private exportTargetColumns: any;
  public submittingData: boolean;
  public loading: boolean;
  public submitButtonLabel: string;
  public submitVehicleButtonLabel: string;
  public submitCommanderButtonLabel: string;

  public responseTeamTypes:ResponseTeamType[];
  public selectedResponseTeamTypes:ResponseTeamType;
  public edTeamType:ResponseTeamType;
   public severityLevels:SeverityLevel[];
  public selectedSeverityLevel:SeverityLevel;
  public edSeverityLevel:SeverityLevel;
  editingTeamType:boolean;
  editingSeverityLevel:boolean;
  editingAcceptablePlanForPanic:boolean;
  public edAcceptablePlanForPanic:AcceptablePlanForPanic;
   public responseTeams:ResponseTeam[];
  public selectedResponseTeam:ResponseTeam;

   public acceptablePlansForPanic:AcceptablePlanForPanic[];
  public selectedAcceptablePlanForPanic:AcceptablePlanForPanic;
public services:Services[];
  public selectedServices:Services;
    public commanderProfiles: CommanderProfile[];
  selectedCommanderProfile: CommanderProfile;
    public armedEscortProfiles: ArmedEscortProfile[];
    public selectedArmedEscortProfile: ArmedEscortProfile;
     public vehicleProfiles: VehicleProfile[];
     selectedVehicleProfile:VehicleProfile;
      selectedPilotProfile: PilotProfile;
  public pilotProfiles: PilotProfile[];
  public AcceptanceForm: FormGroup;
  public responseTeamTypeForm: FormGroup;
  public severityLevelForm: FormGroup;
  constructor(
     private breadcrumbService: BreadcrumbService,

    public messageService: MessageService,
    public confirmationService: ConfirmationService,
    public fireStorage: AngularFireStorage,
    public inventoryService: InventoryService,
    private formBuilder: FormBuilder,
    private responseTeamServiceService: ResponseTeamServiceService,
    private restingStationServiceService: RestingStationServiceService,
    private service: ServicesService,
  ) { this.breadcrumbService.setItems([
      { label: "Response" },
      {
        label: "Response Settings",
        routerLink: ["/home/response/response-team-type"],
      },
    ]);}

  ngOnInit(): void {
       this.loading = true;
    //ResponseTeamType
    this.targetCols = [
      { field: "Description", header: "Description" },
      { field: "caption", header: "caption" },
    
    ];
    this.exportTargetColumns = this.targetCols.map((col) => ({
      title: col.header,
      dataKey: col.field,
    }));
    this.responseTeamTypeForm = this.formBuilder.group({
      description: ["", [Validators.required, Validators.minLength(3)]],
      caption: ["", [Validators.required, Validators.minLength(3)]],
    });

      //Severity
    this.targetCols = [
      { field: "Description", header: "Description" },
      { field: "caption", header: "caption" },
      { field: "marker", header: "marker" },
    ];
    this.exportTargetColumns = this.targetCols.map((col) => ({
      title: col.header,
      dataKey: col.field,
    }));
    this.severityLevelForm = this.formBuilder.group({
      description: ["", [Validators.required, Validators.minLength(3)]],
      caption: ["", [Validators.required, Validators.minLength(3)]],
      //marker: ["", [Validators.required]],
    });
  
     //Aceptance
    this.targetCols = [
      { field: "serviceId", header: "serviceId" },
    
    ];
    this.exportTargetColumns = this.targetCols.map((col) => ({
      title: col.header,
      dataKey: col.field,
    }));
    this.AcceptanceForm = this.formBuilder.group({
      serviceId: [null, [Validators.required]],
      //Caption: ["", [Validators.required, Validators.minLength(3)]],
    });

    this.fetchAllAcceptablePlansForPanic();
    this.fetchAllResponseTeamTypes();
    this.fetchAllServices();
    this.fetchAllSeverityLevels();
    this.submitButtonLabel ="Create";
  }

  //teamtype
  fetchAllResponseTeamTypes() {
    this.loading=true;
    this.responseTeamServiceService
      .allResponseTeamTypes()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe((r: CommonResponse) => {
        if (r.responseCode == "00") {
          var res = r.responseData;
          this.responseTeamTypes = res;
          this.loading=false;
          console.log(res);
        }
         else {
            this.messageService.add({
              severity: "error",
              summary: "Failed",
              detail: " Error: " + r.responseMsg,
            });
            this.submittingData = false;
          }
      });
  }
   createResponseTeamType() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Creating record...",
    });
    this.submittingData = true;
    this.responseTeamServiceService
      .postResponseTeamType(this.responseTeamTypeForm.value)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            this.messageService.add({
              summary: "Success",
              severity: "success",
              detail: "New team type created",
            });
            this.fetchAllResponseTeamTypes();
            this.submittingData = false;
            this.responseTeamTypeForm.reset();
          } else {
            this.messageService.add({
              severity: "error",
              summary: "Failed",
              detail: " Error: " + r.responseMsg,
            });
            this.submittingData = false;
          }
        },
        (error) => {
          this.messageService.add({
            severity: "error",
            summary: "Failed",
            detail: "Connection Error " + error.error.message,
          });
          this.submittingData = false;
        }
      );
  }
  deleteResponseTeamType(teamType: ResponseTeamType): void {
    this.confirmationService.confirm({
      message: "Are you sure you want to delete " + teamType.caption,
      accept: () => {
        this._deleteTeamType(teamType);
      },
    });
  }
   _deleteTeamType(teamType) {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Deleting team type record",
    });
    this.responseTeamServiceService.deleteResponseTeamType(teamType.id).subscribe(
      async (result: any) => {
        await this.messageService.add({
          severity: "success",
          summary: "Deleted",
          detail: "Team type record removed",
        });
        await this.fetchAllResponseTeamTypes();
      },
      (error) => {
        this.connectionError();
      }
    );
  }
  editResponseTeamType(teamType: ResponseTeamType): void {
    this.editingTeamType = true;
    this.formWrapper.nativeElement.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "start",
    });
    this.submitButtonLabel = "Update";
    this.edTeamType = teamType;
    this.responseTeamTypeForm.setValue({
      caption: teamType.caption,
      description: teamType.description,
    });
  }
  updateResponseTeamType(): boolean {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Updating record...",
    });
    this.submittingData = true;
    if (!this.edTeamType) {
      return false;
    }
    this.responseTeamServiceService
      .updateResponseTeamType(this.edTeamType.id, this.responseTeamTypeForm.value)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            this.messageService.add({
              severity: "success",
              detail: "Team Type Updated",
              summary: "Completed",
            });
            this.submittingData = false;
            this.closeEditing();
            this.fetchAllResponseTeamTypes();
            this.responseTeamTypeForm.reset();
          }
        },
        (error) => {
          this.connectionError();
          this.submittingData = false;
        }
      );
  }

    //severityLevel
  fetchAllSeverityLevels() {
    this.loading=true;
    this.responseTeamServiceService
      .allSeverityLevels()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe((r: CommonResponse) => {
        if (r.responseCode == "00") {
          var res = r.responseData;
          this.severityLevels = res;
          this.loading=false;
          console.log(res);
        }
         else {
            this.messageService.add({
              severity: "error",
              summary: "Failed",
              detail: " Error: " + r.responseMsg,
            });
            this.submittingData = false;
          }
      });
  }
   createSeverityLevel() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Creating record...",
    });
    this.submittingData = true;
    const formData = this.severityLevelForm.value;
    const postData = {
      caption: formData.caption,
      description: formData.description,
      //marker: formData.marker,
     }
    this.responseTeamServiceService
      .postSeverityLevel(postData)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            this.messageService.add({
              summary: "Success",
              severity: "success",
              detail: "New team type created",
            });
            this.fetchAllSeverityLevels();
            this.submittingData = false;
            this.severityLevelForm.reset();
          } else {
            this.messageService.add({
              severity: "error",
              summary: "Failed",
              detail: " Error: " + r.responseMsg,
            });
            this.submittingData = false;
          }
        },
        (error) => {
          this.messageService.add({
            severity: "error",
            summary: "Failed",
            detail: "Connection Error " + error.error.message,
          });
          this.submittingData = false;
        }
      );
  }
  deleteSeverityLevel(severityLevel: SeverityLevel): void {
    this.confirmationService.confirm({
      message: "Are you sure you want to delete " + severityLevel.caption,
      accept: () => {
        this._deleteSeverityLevel(severityLevel);
      },
    });
  }
   _deleteSeverityLevel(severityLevel) {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Deleting team type record",
    });
    this.responseTeamServiceService.deleteSeverityLevel(severityLevel.id).subscribe(
      async (result: any) => {
        await this.messageService.add({
          severity: "success",
          summary: "Deleted",
          detail: " record removed",
        });
        await this.fetchAllSeverityLevels();
      },
      (error) => {
        this.connectionError();
      }
    );
  }
  editSeverityLevel(severityLevel: SeverityLevel): void {
    this.editingSeverityLevel = true;
    this.formWrapper.nativeElement.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "start",
    });
    this.submitButtonLabel = "Update";
    this.edSeverityLevel = severityLevel;
    this.severityLevelForm.setValue({
      caption: severityLevel.caption,
      description: severityLevel.description,
      //marker: severityLevel.marker,
    });
  }
  updateSeverityLevel(): boolean {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Updating record...",
    });
    this.submittingData = true;
    if (!this.edSeverityLevel) {
      return false;
    }
    const formData = this.severityLevelForm.value;
     const postData = {
      caption: formData.caption,
      description: formData.description,
      //marker: formData.marker,
     }
    this.responseTeamServiceService
      .updateSeverityLevel(this.edSeverityLevel.id, postData)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            this.messageService.add({
              severity: "success",
              detail: "Severity level Updated",
              summary: "Completed",
            });
            this.submittingData = false;
            this.closeEditing();
            this.fetchAllSeverityLevels();
            this.severityLevelForm.reset();
          }
          else{
             this.messageService.add({
            severity: "error",
            summary: "Failed",
            detail: " Error " + r.responseMsg,
          });
          this.submittingData =false;
          }
        },
        (error) => {
          this.connectionError();
          this.submittingData = false;
        }
      );
  }

   //AcceptablePlan
    fetchAllServices() {
    this.service
      .allService()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe((r: CommonResponse) => {
        if (r.responseCode == "00") {
          var res = r.responseData;
          this.services = res;
          //console.log(res);
        }
         else {
            this.messageService.add({
              severity: "error",
              summary: "Failed",
              detail: " Error: " + r.responseMsg,
            });
            this.submittingData = false;
          }
      });
  }
  fetchAllAcceptablePlansForPanic() {
    this.restingStationServiceService
      .allAcceptablePlansForPanic()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe((r: CommonResponse) => {
        if (r.responseCode == "00") {
          var res = r.responseData;
          this.acceptablePlansForPanic = res;
        }
         else {
            this.messageService.add({
              severity: "error",
              summary: "Failed",
              detail: " Error: " + r.responseMsg,
            });
            this.submittingData = false;
          }
      });
  }
   createAcceptablePlansForPanic() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Creating record...",
    });
    this.submittingData = true;
     const postData = {
      //contractServiceId: this.selectedContractService.id,
      serviceId: this.selectedServices.id,
     }
    this.restingStationServiceService
      .postAcceptablePlanForPanic(postData)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            this.messageService.add({
              summary: "Success",
              severity: "success",
              detail: "New plan created",
            });
            this.fetchAllAcceptablePlansForPanic();
            this.submittingData = false;
            this.AcceptanceForm.reset();
          } else {
            this.messageService.add({
              severity: "error",
              summary: "Failed",
              detail: " Error: " + r.responseMsg,
            });
            this.submittingData = false;
          }
        },
        (error) => {
          this.messageService.add({
            severity: "error",
            summary: "Failed",
            detail: "Connection Error " + error.error.message,
          });
          this.submittingData = false;
        }
      );
  }
  deleteAcceptablePlansForPanic(acceptablePlanForPanic: AcceptablePlanForPanic): void {
    this.confirmationService.confirm({
      message: "Are you sure you want to delete this plan",
      accept: () => {
        this._deleteAcceptablePlansForPanic(acceptablePlanForPanic);
      },
    });
  }
   _deleteAcceptablePlansForPanic(acceptablePlanForPanic) {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Deleting team type record",
    });
    this.restingStationServiceService.deleteAcceptablePlanForPanic(acceptablePlanForPanic.id).subscribe(
      async (result: any) => {
        await this.messageService.add({
          severity: "success",
          summary: "Deleted",
          detail: "Plan record removed",
        });
        await this.fetchAllAcceptablePlansForPanic();
      },
      (error) => {
        this.connectionError();
      }
    );
  }
  editAcceptablePlansForPanic(acceptablePlanForPanic: AcceptablePlanForPanic): void {
    this.editingAcceptablePlanForPanic = true;
    this.formWrapper.nativeElement.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "start",
    });
    this.submitButtonLabel = "Update";
    this.edAcceptablePlanForPanic = acceptablePlanForPanic;
    this.AcceptanceForm.setValue({
      ServiceId: acceptablePlanForPanic.serviceId,
    });
  }
  updateAcceptablePlansForPanic(): boolean {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Creating record...",
    });
    this.submittingData = true;
    if (!this.edAcceptablePlanForPanic) {
      return false;
    }
    this.restingStationServiceService
      .updateAcceptablePlanForPanic(this.edAcceptablePlanForPanic.id, this.AcceptanceForm.value)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            this.messageService.add({
              severity: "success",
              detail: "Team Type Updated",
              summary: "Completed",
            });
            this.submittingData = false;
            this.closeEditing();
            this.fetchAllAcceptablePlansForPanic();
            this.AcceptanceForm.reset();
          }
        },
        (error) => {
          this.connectionError();
          this.submittingData = false;
        }
      );
  }


 closeEditing() {
    this.editingTeamType = false;
    this.responseTeamTypeForm.reset();
    this.edTeamType = null;
    this.editingSeverityLevel = false;
    this.severityLevelForm.reset();
    this.edSeverityLevel = null;
   this.editingAcceptablePlanForPanic = false;
    this.AcceptanceForm.reset();
    this.edAcceptablePlanForPanic = null;

    this.submittingData = false;
    this.submitButtonLabel = "Create";
  }
   private connectionError() {
    this.messageService.add({
      severity: "error",
      summary: "Failed",
      detail: "Connection Error, Please try again",
    });
  }
  ngOnDestroy(): void {
    this.unsubscriber$.complete();
    this.unsubscriber$.unsubscribe();
  }
}
