import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ConfirmationService, MessageService } from "primeng/api";
import { finalize } from "rxjs/operators";
import { BreadcrumbService } from "src/app/breadcrumb.service";
import {
  AddNewGuarantorUploadsVM,
  CreateGMAGuarantorVM,
  OperativeGuarantor,
  UpdateOperativeGuarantorVM,
} from "src/app/interfaces/employment";
import { CommonResponse } from "src/app/interfaces/home";
import { FileStorageService } from "src/app/services/file-storage.service";
import { GmaEmploymentService } from "src/app/services/gma-employment.service";

@Component({
  selector: "app-gma-guarantor",
  templateUrl: "./gma-guarantor.component.html",
  styleUrls: ["./gma-guarantor.component.scss"],
})
export class GmaGuarantorComponent implements OnInit {
  @ViewChild("formWrapper") public formWrapper: ElementRef;
  guarantorForm: FormGroup;
  allGuarantors: OperativeGuarantor[];
  selectedGuarantors: OperativeGuarantor[];
  cols: any[];
  openDialogue: boolean = false;
  imageUrl: string;
  uploadedImage: any[] = [];
  uploadedVetDoc: any[] = [];
  uploadedGuaDoc: any[] = [];
  uploadedDoc: any[] = [];
  userIsYetToClickUploadVetDoc: boolean;
  userIsYetToClickUploadUPLSDoc: boolean;
  userIsYetToClickUploadDoc: boolean;
  editingGuarantor: boolean = false;
  guarantorToEdit: OperativeGuarantor;
  isCreatingGuarantor: boolean = false;
  uploadDataUrls: {
    vettingUploads: string[];
    guarantorUploads: string[];
  };
  fetchingGuarantors: boolean = true;
  isViewingVetDoc: boolean = false;
  openVieWDocDialogue: boolean = false;
  allUploads: {
    count: number;
    uploadUrl: string;
  }[] = [];
  canSaveUPLChanges: boolean = false;
  guarantorInView: OperativeGuarantor;
  imageChanged: boolean = false;

  constructor(
    fb: FormBuilder,
    public employmentService: GmaEmploymentService,
    private breadcrumbService: BreadcrumbService,
    public confirmationService: ConfirmationService,
    public messageService: MessageService,
    private fileStorageService: FileStorageService
  ) {
    this.guarantorForm = fb.group({
      Firstname: ["", Validators.required],
      Lastname: ["", Validators.required],
      Mobile: ["", Validators.required],
      Email: ["", Validators.required],
      Address: ["", Validators.required],
    });
  }

  ngOnInit(): void {
    this.breadcrumbService.setItems([
      {
        label: "Guard Management",
        routerLink: ["/home/guard-management/item-settings"],
      },
      {
        label: "Guarantor",
        routerLink: ["/home/guard-management/guarantor"],
      },
    ]);

    this.cols = [
      { field: "imageUrl", header: "ImageUrl" },
      { field: "firstname", header: "Firstname" },
      { field: "lastname", header: "Lastname" },
      { field: "mobile", header: "Mobile" },
      { field: "email", header: "Email" },
      { field: "address", header: "Address" },
      { field: "isVetted", header: "IsVetted" },
    ];

    this.uploadDataUrls = {
      vettingUploads: [],
      guarantorUploads: [],
    };

    this.FetchAllOperativeGuarantors();
  }

  FetchAllOperativeGuarantors() {
    this.employmentService.GetAllOperativeGuarantors().subscribe(
      async (data) => {
        this.allGuarantors = data;
        this.fetchingGuarantors = false;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable get all guarantors at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
        this.fetchingGuarantors = false;
      }
    );
  }

  OpenAddGuaDialogue() {
    this.openDialogue = true;
    this.guarantorForm.reset(0);
  }

  HideDialog() {
    this.guarantorForm.reset();
    this.imageUrl = null;
    this.uploadDataUrls.guarantorUploads = [];
    this.uploadDataUrls.vettingUploads = [];
    this.uploadedGuaDoc = [];
    this.uploadedVetDoc = [];
    this.openDialogue = false;
    this.imageChanged = false;
  }

  onUpload(event, form) {
    this.uploadedImage = [];
    for (const file of event.files) {
      this.uploadedImage.push(file);
    }
    this.imageUrl = this.uploadedImage[0].objectURL;
    if (this.editingGuarantor) this.imageChanged = true;

    this.messageService.add({
      severity: "success",
      summary: "Success",
      detail: "Imgage Uploaded Successfully!",
    });
    form.clear();
  }

  async CreateGuarantor() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Creating Guarantor...",
    });

    this.isCreatingGuarantor = true;
    await this._startGetUploadDatasChain();
  }

  async _startGetUploadDatasChain() {
    this.uploadDataUrls.vettingUploads = [];
    this.uploadDataUrls.guarantorUploads = [];

    await this._loadVetDocUrls();
  }

  async _loadVetDocUrls() {
    if (this.uploadedVetDoc.length > 0) {
      this.fileStorageService.UploadMultipleFilesFromDataUrl(
        this.uploadedVetDoc
      );
      this.fileStorageService.onUploadFinished.subscribe(
        async (resp: CommonResponse<string[]>) => {
          if (resp.responseCode != "00") {
            this.messageService.add({
              severity: "error",
              summary: "Notice",
              detail: resp.responseMsg,
            });
          } else {
            this.uploadDataUrls.vettingUploads = resp.responseData;
            await this._loadGuarantorDocUrls();
          }
        },
        (error) => {
          console.log("Error while uploading files " + error);
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail: "ERR: Unable to upload image to storage",
          });
        }
      );
    } else {
      await this._loadGuarantorDocUrls();
    }
  }

  async _loadGuarantorDocUrls() {
    if (this.uploadedGuaDoc.length > 0) {
      this.fileStorageService.UploadMultipleFilesFromDataUrl(
        this.uploadedGuaDoc
      );
      this.fileStorageService.onUploadFinished.subscribe(
        async (resp: CommonResponse<string[]>) => {
          if (resp.responseCode != "00") {
            this.messageService.add({
              severity: "error",
              summary: "Notice",
              detail: resp.responseMsg,
            });
          } else {
            this.uploadDataUrls.guarantorUploads = resp.responseData;
            await this._createGuarantor();
          }
        },
        (error) => {
          console.log("Error while uploading files " + error);
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail: "ERR: Unable to upload image to storage",
          });
        }
      );
    } else {
      await this._createGuarantor();
    }
  }

  async _createGuarantor() {
    const postData: CreateGMAGuarantorVM = {
      imageUrl: null,
      firstname: this.guarantorForm.get("Firstname").value,
      lastname: this.guarantorForm.get("Lastname").value,
      mobile: this.guarantorForm.get("Mobile").value,
      email: this.guarantorForm.get("Email").value,
      address: this.guarantorForm.get("Address").value,
      vettingDocUrls: this.uploadDataUrls.vettingUploads,
      guarantorDocUrls: this.uploadDataUrls.guarantorUploads,
    };

    this.fileStorageService.UploadMultipleFilesFromDataUrl(this.uploadedImage);
    this.fileStorageService.onUploadFinished.subscribe(
      async (resp: CommonResponse<string[]>) => {
        if (resp.responseCode != "00") {
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail: resp.responseMsg,
          });
        } else {
          postData.imageUrl = resp.responseData[0];
          this.employmentService.CreateGMAGuarantor(postData).subscribe(
            async () => {
              this.messageService.add({
                severity: "success",
                summary: "Completed",
                detail: "Guarantor Created Successfully!",
              });

              this.imageUrl = null;
              this.guarantorForm.reset();
              this.uploadedVetDoc = [];
              this.uploadedGuaDoc = [];
              this.openDialogue = false;
              this.isCreatingGuarantor = false;
              this.fetchingGuarantors = true;
              this.FetchAllOperativeGuarantors();
            },
            (error) => {
              console.log("Error: " + JSON.stringify(error));
              this.messageService.add({
                severity: "error",
                summary: "Notice",
                detail:
                  "Unable to create guarantor at the moment.. Reason: [" + error
                    ? error.error.message
                    : "request failed - permission" + "]",
              });
              this.isCreatingGuarantor = false;
            }
          );
        }
      },
      (error) => {
        console.log("Error while uploading files " + error);
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail: "ERR: Unable to upload image to storage",
        });
      }
    );
  }

  onCancelDVetDocUpload() {
    this.uploadedVetDoc = [];
  }

  NotifyUserToClickUpload(identifier: number) {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Kindly ensure you click upload.",
    });

    if (identifier == 1) {
      this.userIsYetToClickUploadVetDoc = true;
    } else if (identifier == 2) {
      this.userIsYetToClickUploadUPLSDoc = true;
    } else if (identifier == 3) {
      this.userIsYetToClickUploadDoc = true;
    }
  }

  onUploadVetDoc(event) {
    this.uploadedVetDoc = [];
    for (const file of event.files) {
      this.uploadedVetDoc.push(file);
    }

    this.messageService.add({
      severity: "success",
      summary: "Success",
      detail: "Vet Doc(s) Uploaded",
    });

    this.userIsYetToClickUploadVetDoc = false;
  }

  onCancelDGuaDocUpload() {
    this.uploadedGuaDoc = [];
  }

  onUploadGuaDoc(event) {
    this.uploadedGuaDoc = [];
    for (const file of event.files) {
      this.uploadedGuaDoc.push(file);
    }

    this.messageService.add({
      severity: "success",
      summary: "Success",
      detail: "Guarantor Doc(s) Uploaded",
    });

    this.userIsYetToClickUploadUPLSDoc = false;
  }

  CloseEditingGuarantor() {
    this.imageUrl = null;
    this.uploadedGuaDoc = [];
    this.uploadedVetDoc = [];
    this.editingGuarantor = false;

    this.HideDialog();
    //this.guarantorForm.reset();
  }

  async UpdateGuarantor() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Updating Guarantor...",
    });

    const id = this.guarantorToEdit.id;
    const postData: UpdateOperativeGuarantorVM = {
      firstname: this.guarantorForm.get("Firstname").value,
      lastname: this.guarantorForm.get("Lastname").value,
      mobile: this.guarantorForm.get("Mobile").value,
      email: this.guarantorForm.get("Email").value,
      address: this.guarantorForm.get("Address").value,
    };

    if (this.imageChanged) {
      this.fileStorageService.UploadMultipleFilesFromDataUrl(
        this.uploadedImage
      );
      this.fileStorageService.onUploadFinished.subscribe(
        async (resp: CommonResponse<string[]>) => {
          if (resp.responseCode != "00") {
            this.messageService.add({
              severity: "error",
              summary: "Notice",
              detail: resp.responseMsg,
            });
          } else {
            postData.imageUrl = resp.responseData[0];
            await this._updateGuarantor(id, postData);
          }
        },
        (error) => {
          console.log("Error while uploading files " + error);
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail: "ERR: Unable to upload image to storage",
          });
        }
      );
    } else {
      await this._updateGuarantor(id, postData);
    }
  }

  async _updateGuarantor(id: number, postData: UpdateOperativeGuarantorVM) {
    this.employmentService.UpdateOperativeGuarantor(id, postData).subscribe(
      async () => {
        this.messageService.add({
          severity: "success",
          summary: "Completed",
          detail: "Guarantor Updated Successfully.",
        });

        this.guarantorForm.reset();
        this.imageChanged = false;
        this.imageUrl = null;
        this.editingGuarantor = false;
        this.guarantorToEdit = null;
        this.CloseEditingGuarantor();
        this.fetchingGuarantors = true;
        this.FetchAllOperativeGuarantors();
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to update guarantor at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  OpenGuaVetDocDialogue(item: OperativeGuarantor) {
    this.openVieWDocDialogue = true;
    this.isViewingVetDoc = true;
    this.canSaveUPLChanges = false;
    this.guarantorInView = item;

    if (item.vettingDocUrls) {
      let uploadUrls = item.vettingDocUrls.split(";");
      let index = 1;
      uploadUrls.forEach((x) =>
        this.allUploads.push({
          count: index++,
          uploadUrl: x,
        })
      );
    }
  }

  OpenGuaDocDialogue(item: OperativeGuarantor) {
    this.openVieWDocDialogue = true;
    this.isViewingVetDoc = false;
    this.canSaveUPLChanges = false;
    this.guarantorInView = item;

    if (item.guarantorFormUrls) {
      let uploadUrls = item.guarantorFormUrls.split(";");
      let index = 1;
      uploadUrls.forEach((x) =>
        this.allUploads.push({
          count: index++,
          uploadUrl: x,
        })
      );
    }
  }

  HideViewDocDialog() {
    this.openVieWDocDialogue = false;
    this.uploadedDoc = [];
    this.allUploads = [];
    this.canSaveUPLChanges = false;
    this.guarantorInView = null;
  }

  onCancelDDocUpload() {
    this.uploadedDoc = [];
  }

  onUploadDoc(event) {
    this.uploadedGuaDoc = [];
    for (const file of event.files) {
      this.uploadedDoc.push(file);
    }

    this.messageService.add({
      severity: "success",
      summary: "Success",
      detail: "Upload(s) Successful",
    });

    this.userIsYetToClickUploadDoc = false;
  }

  async SaveUploads() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Saving Upload(s)...",
    });

    let uploadsUrls: string[] = [];

    this.fileStorageService.UploadMultipleFilesFromDataUrl(this.uploadedDoc);
    this.fileStorageService.onUploadFinished.subscribe(
      async (resp: CommonResponse<string[]>) => {
        if (resp.responseCode != "00") {
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail: resp.responseMsg,
          });
        } else {
          uploadsUrls = resp.responseData;
          const postData: AddNewGuarantorUploadsVM = {
            isVettingDoc: this.isViewingVetDoc,
            guarantorId: this.guarantorInView.id,
            vettiongDocs: this.isViewingVetDoc ? uploadsUrls : [],
            guarantorForms: this.isViewingVetDoc ? [] : uploadsUrls,
          };
          this.employmentService.AddNewGuarantorUploads(postData).subscribe(
            async (uplUrs) => {
              this.messageService.add({
                severity: "success",
                summary: "Completed",
                detail: "Upload(s) Saved Successfully!",
              });

              if (this.isViewingVetDoc)
                this.guarantorInView.vettingDocUrls = uplUrs;
              else this.guarantorInView.guarantorFormUrls = uplUrs;

              let count: number = 1;
              uploadsUrls.forEach((x) =>
                this.allUploads.push({
                  count: this.allUploads.length + count++,
                  uploadUrl: x,
                })
              );
              //this.HideViewDocDialog();
            },
            (error) => {
              console.log("Error: " + JSON.stringify(error));
              this.messageService.add({
                severity: "error",
                summary: "Notice",
                detail:
                  "Unable to save upload(s) at the moment.. Reason: [" + error
                    ? error.error.message
                    : "request failed - permission" + "]",
              });
            }
          );
        }
      },
      (error) => {
        console.log("Error while uploading files " + error);
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail: "ERR: Unable to upload image to storage",
        });
      }
    );
  }

  SaveUploadChanges() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Saving Upload Change(s)...",
    });

    if (this.allUploads.length == 0) {
      this.messageService.add({
        severity: "error",
        summary: "Notice",
        detail: "You can't save changes because uploads can't be empty",
      });
      return;
    }

    const postData: AddNewGuarantorUploadsVM = {
      isVettingDoc: this.isViewingVetDoc,
      guarantorId: this.guarantorInView.id,
      vettiongDocs: this.isViewingVetDoc
        ? this.allUploads.map((x) => x.uploadUrl)
        : [],
      guarantorForms: this.isViewingVetDoc
        ? []
        : this.allUploads.map((x) => x.uploadUrl),
      isKnownChanges: true,
    };

    this.employmentService.AddNewGuarantorUploads(postData).subscribe(
      async (uplUrs) => {
        this.messageService.add({
          severity: "success",
          summary: "Completed",
          detail: "Upload Change(s) Saved Successfully!",
        });

        if (this.isViewingVetDoc) this.guarantorInView.vettingDocUrls = uplUrs;
        else this.guarantorInView.guarantorFormUrls = uplUrs;
        this.HideViewDocDialog();
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to save upload change(s) at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  DeleteGuarantor(item: OperativeGuarantor) {
    this.confirmationService.confirm({
      message: "Are you sure you want to remove guarantor?",
      accept: () => {
        this.messageService.add({
          severity: "info",
          summary: "Notice",
          detail: "Removing Guarantor...",
        });

        this.employmentService.DeleteOperativeGuarantor(item.id).subscribe(
          async () => {
            this.messageService.add({
              severity: "success",
              summary: "Removed",
              detail: "Removed successfully",
            });

            this.fetchingGuarantors = true;
            const index = this.allGuarantors.indexOf(item);
            if (index > -1) {
              this.allGuarantors.splice(index, 1);
            }
            this.fetchingGuarantors = false;
          },
          (error) => {
            console.log("Error: " + JSON.stringify(error));
            this.messageService.add({
              severity: "error",
              summary: "Notice",
              detail:
                "Unable to remove GMA guarator at the moment.. Reason: [" +
                error
                  ? error.error.message
                  : "request failed - permission" + "]",
            });
          }
        );
      },
    });
  }

  EditGuarantor(item: OperativeGuarantor) {
    this.editingGuarantor = true;
    this.guarantorForm.patchValue({
      Firstname: item.firstname,
      Lastname: item.lastname,
      Mobile: item.mobile,
      Email: item.email,
      Address: item.address,
    });
    this.imageUrl = item.imageUrl;
    this.guarantorToEdit = item;

    this.openDialogue = true;
    this.formWrapper.nativeElement.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "start",
    });
  }

  ViewDoc(item: { count: number; uploadUrl: string }) {
    window.open(item.uploadUrl, "_blank");
  }

  DeleteUpload(item: { count: number; uploadUrl: string }) {
    const index = this.allUploads.indexOf(item);
    if (index > -1) {
      this.allUploads.splice(index, 1);

      this.messageService.add({
        severity: "info",
        summary: "Notice",
        detail: "Kindly ensure you click save changes.",
      });
      this.canSaveUPLChanges = true;
    }
  }
}
