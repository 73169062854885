import { Component, OnInit } from '@angular/core';
import {SelectItem} from 'primeng/api';
import {Customer} from '../../demo/domain/customer';
import {CustomerService} from '../../demo/service/customerservice';
import {Account} from '../../interfaces/account';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.css']
})
export class AccountComponent implements OnInit {
    selectedDrop: SelectItem;
    accountClass: SelectItem[];
    account: Account[];
    selectedCustomers1: Account[];
    exportColumns: any;
    cols: any[];
  constructor(private customerService: CustomerService) { }

  ngOnInit(): void {
    this.account = [
        {id: 1, cr: '2001', desc: 'description 1', dr: '23001', isIntegrated: false, transactionDate: '20-05-2020', voucherType: 'refund'},
        {id: 2, cr: '2002', desc: 'description 2', dr: '23002', isIntegrated: true, transactionDate: '20-05-2020', voucherType: 'Transfer'},
        {id: 3, cr: '2003', desc: 'description 3', dr: '23003', isIntegrated: false, transactionDate: '20-05-2020', voucherType: 'credit'},
        {id: 4, cr: '2004', desc: 'description 4', dr: '23004', isIntegrated: true, transactionDate: '20-05-2020', voucherType: 'refund'},
        {id: 5, cr: '2005', desc: 'description 5', dr: '23005', isIntegrated: false, transactionDate: '20-05-2020', voucherType: 'refund'},
    ];
    this.accountClass = [
      {label: 'New York', value: {id: 1, name: 'New York', code: 'NY'}},
      {label: 'Rome', value: {id: 2, name: 'Rome', code: 'RM'}},
      {label: 'London', value: {id: 3, name: 'London', code: 'LDN'}},
      {label: 'Istanbul', value: {id: 4, name: 'Istanbul', code: 'IST'}},
      {label: 'Paris', value: {id: 5, name: 'Paris', code: 'PRS'}}
    ];
      this.cols = [
          { field: 'cr', header: 'Cr' },
          { field: 'dr', header: 'Dr' },
          { field: 'desc', header: 'Desc' }
      ];
      this.exportColumns = this.cols.map(col => ({title: col.header, dataKey: col.field}));
  }
    // exportPdf() {
    //     import('jspdf').then(jsPDF => {
    //         import('jspdf-autotable').then(x => {
    //             const doc = new jsPDF.default(0,0);
    //             doc.autoTable(this.exportColumns, this.account);
    //             doc.save('products.pdf');
    //         })
    //     })
    // }
    //
    // exportExcel() {
    //     import('xlsx').then(xlsx => {
    //         const worksheet = xlsx.utils.json_to_sheet(this.account);
    //         const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
    //         const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
    //         this.saveAsExcelFile(excelBuffer, 'products');
    //     });
    // }
    //
    // saveAsExcelFile(buffer: any, fileName: string): void {
    //     import('file-saver').then(FileSaver => {
    //         const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    //         const EXCEL_EXTENSION = '.xlsx';
    //         const data: Blob = new Blob([buffer], {
    //             type: EXCEL_TYPE
    //         });
    //         FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
    //     });
    // }
}
