import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CommonResponse } from 'src/app/interfaces/home';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RestingStationServiceService {
baseUrl = environment.baseUrl + "RestingStation/";
  //baseUrl = "https://localhost:44344/api/RestingStation/";
  constructor(private httpClient: HttpClient) { }

  //Resting Station     
  allRestingStations(): Observable<CommonResponse> {
    return this.httpClient.get<CommonResponse>(
      this.baseUrl + "GetAllRestingStations"
    );
  }
    getRestingStation(id: any): Observable<CommonResponse> {
    return this.httpClient.get<CommonResponse>(
      this.baseUrl + "GetRestingStationById/" + id
    );
  }
   postRestingStation(data: any) {
    return this.httpClient.post(
      this.baseUrl + "AddNewRestingStation",
      data
    );
  }
  deleteRestingStation(id: any) {
    return this.httpClient.delete(
      this.baseUrl + "DeleteRestingStation/" + id
    );
  }
   updateRestingStation(id: any, data: any) {
    return this.httpClient.put(
      this.baseUrl + "UpdateRestingStation/" + id,
      data
    );
  }

  //AcceptablePlansForPanic     
  allAcceptablePlansForPanic(): Observable<CommonResponse> {
    return this.httpClient.get<CommonResponse>(
      this.baseUrl + "GetAllAcceptablePlansForPanic"
    );
  }
    getAcceptablePlanForPanic(id: any): Observable<CommonResponse> {
    return this.httpClient.get<CommonResponse>(
      this.baseUrl + "GetAcceptablePlanForPanicById/" + id
    );
  }
  
   postAcceptablePlanForPanic(data: any) {
    return this.httpClient.post(
      this.baseUrl + "AddNewAcceptablePlanForPanic",
      data
    );
  }
  deleteAcceptablePlanForPanic(id: any) {
    return this.httpClient.delete(
      this.baseUrl + "DeleteAcceptablePlanForPanic/" + id
    );
  }
   updateAcceptablePlanForPanic(id: any, data: any) {
    return this.httpClient.put(
      this.baseUrl + "UpdateAcceptablePlanForPanic/" + id,
      data
    );
  }

  //LocalGovtRestingStationMap     
  allLocalGovtRestingStationMaps(): Observable<CommonResponse> {
    return this.httpClient.get<CommonResponse>(
      this.baseUrl + "GetAllLocalGovtRestingStationMaps"
    );
  }
    getLocalGovtRestingStationMap(id: any): Observable<CommonResponse> {
    return this.httpClient.get<CommonResponse>(
      this.baseUrl + "GetLocalGovtRestingStationMapById/" + id
    );
  }
   postLocalGovtRestingStationMap(data: any) {
    return this.httpClient.post(
      this.baseUrl + "AddNewLocalGovtRestingStationMap",
      data
    );
  }
  deleteLocalGovtRestingStationMap(id: any) {
    return this.httpClient.delete(
      this.baseUrl + "DeleteLocalGovtRestingStationMap/" + id
    );
  }
   updateLocalGovtRestingStationMap(id: any, data: any) {
    return this.httpClient.put(
      this.baseUrl + "UpdateLocalGovtRestingStationMap/" + id,
      data
    );
  }
}
