import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { MessageService, ConfirmationService, Message } from "primeng/api";
import { UIChart } from "primeng/chart";
import { BreadcrumbService } from "src/app/breadcrumb.service";
import { BudgetapiservicesService } from "src/app/services/budget/budgetapiservices.service";
import { ReportingService } from "src/app/services/reporting.service";
import { BudgetSeason } from "../budget/budget-season/budget-season.component";
import { EndorsementTypeService } from "src/app/services/endorsement-type.service";
import { EndorsementType } from "src/app/interfaces/home";
import {
  GetProductionLowerDashboardReportDataView,
  GetProductionLowerDashboardReportVM,
} from "src/app/interfaces/reporting";

@Component({
  selector: "app-production-dashboard",
  templateUrl: "./production-dashboard.component.html",
  styleUrls: ["./production-dashboard.component.scss"],
  providers: [MessageService],
})
export class ProductionDashboardComponent implements OnInit {
  @ViewChild("formWrapper") public formWrapper: ElementRef;
  @ViewChild("chart1") chart1: UIChart;
  fetchingAnalytics: boolean = true;
  stillFetchingAnalytics: boolean;
  allYears: {
    key: number;
    value: string;
  }[];
  theYear: {
    key: number;
    value: string;
  };
  allBudgetSeasons: BudgetSeason[];
  allBudgetSeasonsToShow: BudgetSeason[] = [];
  theBudgetSeason: BudgetSeason;
  opportunityGenRate_Percent: number;
  currentYearValue: string;
  leadConversionRate_Percent: number;
  opportunityGenRate_Degree: number;
  leadConversionRate_Degree: number;
  budgetYrToDateRate_Percent: number;
  budgetYrToDateRate_Degree: number;
  msg: Message[] = [];
  allRegionPerformances: GetProductionLowerDashboardReportDataView[];
  allServiceCategPerformances: GetProductionLowerDashboardReportDataView[];
  allMonthPerformances: GetProductionLowerDashboardReportDataView[];
  allIndividualPerformances: GetProductionLowerDashboardReportDataView[];
  cols: any[];
  regionalContributionData: {
    labels: string[];
    datasets: {
      data: number[];
      backgroundColor: string[];
    }[];
  };
  serviceCategContributionData: {
    labels: string[];
    datasets: {
      data: number[];
      backgroundColor: string[];
    }[];
  };
  monthContributionData: {
    labels: string[];
    datasets: {
      data: number[];
      backgroundColor: string[];
    }[];
  };
  individualContributionData: {
    labels: string[];
    datasets: {
      data: number[];
      backgroundColor: string[];
    }[];
  };
  productionValueYTD: number;
  productionValueFullYear: number;
  collectionsValueYTD: number;
  receivablesDueValueYTD: number;
  allEndorsementTypes: EndorsementType[];
  theRegionEndorsementType: EndorsementType;
  theServiceCategEndorsementType: EndorsementType;
  theMonthEndorsementType: EndorsementType;
  theIndividualEndorsementType: EndorsementType;
  fetchingLowerAnalytics: boolean;
  regionHighestPerforming: string;
  serviceCategHighestPerforming: string;
  monthHighestPerforming: string;
  individualHighestPerforming: string;
  regionEndorsementType: string;
  serviceCategEndorsementType: string;
  monthEndorsementType: string;
  individualEndorsementType: string;
  totalNewValue: number = 0;
  totalServiceRetentionValue: number = 0;
  totalServiceTopupValue: number = 0;
  totalBusinessExpSameSellValue: number = 0;
  totalBusinessExpCrossSellValue: number = 0;

  constructor(
    private budgetApiService: BudgetapiservicesService,
    private endorsementTypeService: EndorsementTypeService,
    private reportingService: ReportingService,
    public messageService: MessageService,
    private breadcrumbService: BreadcrumbService,
    public confirmationService: ConfirmationService
  ) {}

  ngOnInit(): void {
    this.breadcrumbService.setItems([
      {
        label: "Management",
      },
      {
        label: "Production Dashboard",
        routerLink: ["/home/production-dashboard"],
      },
    ]);

    this.cols = [
      { field: "region", header: "Region" },
      { field: "budgetYTD", header: "Budget YTD" },
      { field: "actualYTD", header: "Actual YTD" },
      { field: "variance", header: "Variance" },
      { field: "percentage", header: "% Achieved" },
    ];

    this.regionalContributionData = {
      labels: ["Lagos Island", "Surulere", "V.I"],
      datasets: [
        {
          data: [49, 21, 30],
          backgroundColor: ["green", "pink", "yellow"],
        },
      ],
    };

    this.serviceCategContributionData = {
      labels: ["Lagos Island", "Surulere", "V.I"],
      datasets: [
        {
          data: [49, 21, 30],
          backgroundColor: ["green", "pink", "yellow"],
        },
      ],
    };

    this.monthContributionData = {
      labels: ["Lagos Island", "Surulere", "V.I"],
      datasets: [
        {
          data: [49, 21, 30],
          backgroundColor: ["green", "pink", "yellow"],
        },
      ],
    };

    this.individualContributionData = {
      labels: ["Lagos Island", "Surulere", "V.I"],
      datasets: [
        {
          data: [49, 21, 30],
          backgroundColor: ["green", "pink", "yellow"],
        },
      ],
    };

    this.PopulateYearsDDL();
    this.FetchAllEndorsementTypes();
    this.FetchAllBudgetSeasons();
    this.ResetMessageToasters();
  }

  async FetchAllBudgetSeasons() {
    this.budgetApiService.getBudgetSeasons().subscribe(
      async (data) => {
        if (data["responseCode"] != "00") {
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail: data["responseMsg"],
          });
          this.fetchingAnalytics = false;
          this.ResetMessageToasters();
          return;
        }

        this.allBudgetSeasons = data["responseData"];
        if (this.allBudgetSeasons.length > 0) {
          this.theBudgetSeason = this.allBudgetSeasons.filter(
            (x) => x.budgetYear == this.theYear.key
          )[0];
          let budgetSeasonYear = this.allYears.find(
            (x) => x.key == this.theBudgetSeason.budgetYear
          );
          if (budgetSeasonYear) {
            this.theYear = budgetSeasonYear;
          } else {
            this.allYears.push({
              key: this.theBudgetSeason.budgetYear,
              value: this.theBudgetSeason.budgetYear + "",
            });
            this.theYear = this.allYears[this.allYears.length - 1];
          }
          this.allBudgetSeasonsToShow = this.allBudgetSeasons;
          this.ReloadUpperDashboard();
        }
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all budget seasons at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
        this.fetchingAnalytics = false;
        this.ResetMessageToasters();
      }
    );
  }

  async FetchAllEndorsementTypes() {
    this.endorsementTypeService.allEndorsementTypeData().subscribe(
      async (data) => {
        if (data.responseCode != "00") {
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail: data["responseMsg"],
          });
          this.ResetMessageToasters();
          return;
        }

        this.allEndorsementTypes = data.responseData ?? [];
        this.allEndorsementTypes = this.allEndorsementTypes.filter(
          (x) => x.isBudgetRelated
        );
        if (this.allEndorsementTypes.length > 0) {
          this.theRegionEndorsementType = this.allEndorsementTypes[0];
          this.theServiceCategEndorsementType = this.allEndorsementTypes[0];
          this.theMonthEndorsementType = this.allEndorsementTypes[0];
          this.theIndividualEndorsementType = this.allEndorsementTypes[0];
        }
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all endorsement types at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
        this.ResetMessageToasters();
      }
    );
  }

  PopulateYearsDDL() {
    this.allYears = [];
    let currentYear = new Date(Date.now()).getFullYear();
    for (let i = 5; i > 0; i--) {
      this.allYears.push({
        key: currentYear - i,
        value: currentYear - i + "",
      });
    }
    this.allYears.push({
      key: currentYear,
      value: currentYear + "",
    });
    for (let i = 1; i <= 5; i++) {
      this.allYears.push({
        key: currentYear + i,
        value: currentYear + i + "",
      });
    }
    this.theYear = this.allYears[5];
  }

  ResetMessageToasters() {
    this.msg = [];
    this.msg.push({
      severity: "info",
      summary: "Performance By Category",
    });
  }

  async CalculateCircularProgressValues(
    opportunityGenRate: number,
    leadConversionRate: number,
    budgetYrToDateRate: number
  ) {
    this.opportunityGenRate_Percent = opportunityGenRate;
    this.opportunityGenRate_Degree = 3.6 * opportunityGenRate;
    let opValue = this.opportunityGenRate_Degree / 2;
    document.getElementById("OPFill1").style.transform =
      "rotate(" + opValue + "deg)";
    document.getElementById("OPFill2").style.transform =
      "rotate(" + opValue + "deg)";
    document.getElementById("OPFill3").style.transform =
      "rotate(" + opValue + "deg)";

    this.leadConversionRate_Percent = leadConversionRate;
    this.leadConversionRate_Degree = 3.6 * leadConversionRate;
    let lcValue = this.leadConversionRate_Degree / 2;
    document.getElementById("LCFill1").style.transform =
      "rotate(" + lcValue + "deg)";
    document.getElementById("LCFill2").style.transform =
      "rotate(" + lcValue + "deg)";
    document.getElementById("LCFill3").style.transform =
      "rotate(" + lcValue + "deg)";

    this.budgetYrToDateRate_Percent = budgetYrToDateRate;
    this.budgetYrToDateRate_Degree = 3.6 * budgetYrToDateRate;
    let bgValue = this.budgetYrToDateRate_Degree / 2;
    document.getElementById("BGFill1").style.transform =
      "rotate(" + bgValue + "deg)";
    document.getElementById("BGFill2").style.transform =
      "rotate(" + bgValue + "deg)";
    document.getElementById("BGFill3").style.transform =
      "rotate(" + bgValue + "deg)";
  }

  ReloadUpperDashboard() {
    this.ResetUpperDashboard();
    this.ResetLowerDashboard({
      year: 0,
      budgetSeasonId: 0,
      isAll: true,
      endorsementTypeId: 0,
    });
    if (this.theYear && this.theBudgetSeason) {
      if (this.theBudgetSeason.budgetYear != this.theYear.key) {
        this.allBudgetSeasonsToShow = this.allBudgetSeasons.filter(
          (x) => x.budgetYear == this.theYear.key
        );
        return;
      }
      this.fetchingAnalytics = false;
      this.stillFetchingAnalytics = true;
      this.reportingService
        .GetProductionUpperDashboardReport(
          this.theYear.key,
          this.theBudgetSeason.id
        )
        .subscribe(
          async (data) => {
            if (data.responseCode != "00") {
              this.messageService.add({
                severity: "error",
                summary: "Notice",
                detail: data.responseMsg,
              });
              this.ResetMessageToasters();
              this.stillFetchingAnalytics = false;
              return;
            }

            for (let i = 0; i < 5000; i++) {} // Wait for 5 milliseconds
            this.CalculateCircularProgressValues(
              data.responseData.opportuniyGenerationRate,
              data.responseData.leadConversionRate,
              data.responseData.budgetValueYTD
            );
            this.productionValueYTD = data.responseData.productionValueYTD;
            this.productionValueFullYear =
              data.responseData.productionValueFullYr;
            this.collectionsValueYTD = data.responseData.totalCollectionValue;
            this.receivablesDueValueYTD = data.responseData.receivablesDueValue;
            this.currentYearValue = data.responseData.currentYearValue;
            this.totalNewValue = data.responseData.totalNewValue;
            this.totalServiceRetentionValue =
              data.responseData.totalServiceRetentionValue;
            this.totalServiceTopupValue =
              data.responseData.totalServiceTopUpValue;
            this.totalBusinessExpSameSellValue =
              data.responseData.totalBusinessExpSameSellValue;
            this.totalBusinessExpCrossSellValue =
              data.responseData.totalBusinessExpCrossSellValue;
            this.ReloadLowerDashboard({
              year: this.theYear.key,
              budgetSeasonId: this.theBudgetSeason.id,
              isAll: true,
              endorsementTypeId: this.theRegionEndorsementType?.id ?? 0,
            });
            this.stillFetchingAnalytics = false;
          },
          (error) => {
            console.log("Error: " + JSON.stringify(error));
            this.messageService.add({
              severity: "error",
              summary: "Notice",
              detail:
                "Unable to fetch dashboard upper analytics at the moment.. Reason: [" +
                (error ? error.error.message : "request failed - permission") +
                "]",
            });
            this.stillFetchingAnalytics = false;
            this.ResetMessageToasters();
          }
        );
    }
  }

  ResetUpperDashboard() {
    this.CalculateCircularProgressValues(0, 0, 0);
    this.productionValueYTD = 0;
    this.collectionsValueYTD = 0;
    this.receivablesDueValueYTD = 0;
    this.currentYearValue = "N/A";
  }

  ReloadLowerDashboard(postData: GetProductionLowerDashboardReportVM) {
    this.fetchingLowerAnalytics = true;
    this.reportingService.GetProductionLowerDashboardReport(postData).subscribe(
      async (data) => {
        if (data.responseCode != "00") {
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail: data.responseMsg,
          });
          this.ResetMessageToasters();
          this.fetchingLowerAnalytics = false;
          return;
        }

        if (postData.isAll || postData.isByRegion) {
          this.allRegionPerformances = data.responseData.byRegionReport;
          this.regionalContributionData.labels =
            data.responseData.byRegionChart.labels;
          this.regionalContributionData.datasets = [
            {
              data: data.responseData.byRegionChart.datas,
              backgroundColor: data.responseData.byRegionChart.backgroundColors,
            },
          ];
          this.regionEndorsementType =
            data.responseData.byRegionChart.endorsementType;
          this.regionHighestPerforming =
            data.responseData.byRegionChart.highestPerformance;
        }
        if (postData.isAll || postData.isByServiceCateg) {
          this.allServiceCategPerformances =
            data.responseData.byServiceCategoryReport;
          this.serviceCategContributionData.labels =
            data.responseData.byServiceCategoryChart.labels;
          this.serviceCategContributionData.datasets = [
            {
              data: data.responseData.byServiceCategoryChart.datas,
              backgroundColor:
                data.responseData.byServiceCategoryChart.backgroundColors,
            },
          ];
          this.serviceCategEndorsementType =
            data.responseData.byServiceCategoryChart.endorsementType;
          this.serviceCategHighestPerforming =
            data.responseData.byServiceCategoryChart.highestPerformance;
        }
        if (postData.isAll || postData.isByMonth) {
          this.allMonthPerformances = data.responseData.byMonthReport;
          this.monthContributionData.labels =
            data.responseData.byMonthChart.labels;
          this.monthContributionData.datasets = [
            {
              data: data.responseData.byMonthChart.datas,
              backgroundColor: data.responseData.byMonthChart.backgroundColors,
            },
          ];
          this.monthEndorsementType =
            data.responseData.byMonthChart.endorsementType;
          this.monthHighestPerforming =
            data.responseData.byMonthChart.highestPerformance;
        }
        if (postData.isAll || postData.isByIndividual) {
          this.allIndividualPerformances = data.responseData.byIndividualReport;
          this.individualContributionData.labels =
            data.responseData.byIndividualChart.labels;
          this.individualContributionData.datasets = [
            {
              data: data.responseData.byIndividualChart.datas,
              backgroundColor:
                data.responseData.byIndividualChart.backgroundColors,
            },
          ];
          this.individualEndorsementType =
            data.responseData.byIndividualChart.endorsementType;
          this.individualHighestPerforming =
            data.responseData.byIndividualChart.highestPerformance;
        }
        this.fetchingLowerAnalytics = false;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch dashboard lower analytics at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
        this.fetchingLowerAnalytics = false;
        this.ResetMessageToasters();
      }
    );
  }

  ResetLowerDashboard(postData: GetProductionLowerDashboardReportVM) {
    if (postData.isAll || postData.isByRegion) {
      this.allRegionPerformances = [];
      this.regionalContributionData.labels = [];
      this.regionalContributionData.datasets = [
        {
          data: [],
          backgroundColor: [],
        },
      ];
      this.regionEndorsementType = "";
      this.regionHighestPerforming = "";
    }
    if (postData.isAll || postData.isByServiceCateg) {
      this.allServiceCategPerformances = [];
      this.serviceCategContributionData.labels = [];
      this.serviceCategContributionData.datasets = [
        {
          data: [],
          backgroundColor: [],
        },
      ];
      this.serviceCategEndorsementType = "";
      this.serviceCategHighestPerforming = "";
    }
    if (postData.isAll || postData.isByMonth) {
      this.allMonthPerformances = [];
      this.monthContributionData.labels = [];
      this.monthContributionData.datasets = [
        {
          data: [],
          backgroundColor: [],
        },
      ];
      this.monthEndorsementType = "";
      this.monthHighestPerforming = "";
    }
    if (postData.isAll || postData.isByIndividual) {
      this.allIndividualPerformances = [];
      this.individualContributionData.labels = [];
      this.individualContributionData.datasets = [
        {
          data: [],
          backgroundColor: [],
        },
      ];
      this.individualEndorsementType = "";
      this.individualHighestPerforming = "";
    }
  }

  LowerDashboardReloader(indicator: number) {
    let postData: GetProductionLowerDashboardReportVM = {
      year: this.theYear.key,
      budgetSeasonId: this.theBudgetSeason.id,
      endorsementTypeId: 0,
      isAll: false,
    };
    let resetData = {
      year: 0,
      budgetSeasonId: 0,
      isAll: false,
      isByRegion: false,
      isByServiceCateg: false,
      isByMonth: false,
      isByIndividual: false,
      endorsementTypeId: 0,
    };

    switch (indicator) {
      case 1:
        postData.endorsementTypeId = this.theRegionEndorsementType.id;
        postData.isByRegion = true;
        resetData.isByRegion = true;
        break;
      case 2:
        postData.endorsementTypeId = this.theServiceCategEndorsementType.id;
        postData.isByServiceCateg = true;
        resetData.isByServiceCateg = true;
        break;
      case 3:
        postData.endorsementTypeId = this.theMonthEndorsementType.id;
        postData.isByMonth = true;
        resetData.isByMonth = true;
        break;
      case 4:
        postData.endorsementTypeId = this.theIndividualEndorsementType.id;
        postData.isByIndividual = true;
        resetData.isByIndividual = true;
        break;
      default:
        return;
    }

    this.ResetLowerDashboard(resetData);
    this.ReloadLowerDashboard(postData);
  }
}
