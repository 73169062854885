import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { stringify } from 'querystring';
import { async } from 'rxjs/internal/scheduler/async';
import { CommonResponse, User } from 'src/app/interfaces/home';
import { SupplierOfficeSetupService } from './supplier-office-setup.service';
import {MessageService,ConfirmationService} from 'primeng/api';

@Component({
  selector: 'app-supplier-office-setup',
  templateUrl: './supplier-office-setup.component.html',
  styleUrls: ['./supplier-office-setup.component.scss'],
  providers: [ConfirmationService,MessageService]
})
export class SupplierOfficeSetupComponent implements OnInit {

  users:User[]=[];
  selectedUsers:User[]=[];
  officeName;
  officeDescription;
  createOffice:FormGroup;
  userList: any[]=[];
  officeList: any[]=[];
  displayModal:boolean=false;
  currentHeader: any;
  chosenOfficers: User[]=[];
  currentOfficers: User[]=[];
  currentUserCounts;
  updateFlag:Boolean=false;
  currentOfficeId: any;
  officers: any[] = [];
  moreSelectedUsers:User[]=[];
  constructor(
    private supplierService:SupplierOfficeSetupService,
    private fb:FormBuilder,
    private messageService: MessageService,
    private confirmationService: ConfirmationService
    ) { 
      this.officeForm();
    }

  ngOnInit(): void {
    this.getAllUsers();
    this.getAllOffices();
  }

  officeForm() {
    this.createOffice = this.fb.group({
    officeName: ['', Validators.required ],
    officeDescription:['',Validators.required]
    });
 }

  getAllUsers(){
    this.supplierService.allUser().subscribe(async(res: CommonResponse) => {
      if(res.responseCode=="00"){
        this.users = res.responseData;
        this.users.forEach(user => {
         user.fullName = user.firstName + " " + user.lastName;
        });
        console.log("List of users",this.users);
      }
    })
  }

  saveOffice(){
      if(this.createOffice.valid){
        this.messageService.add({severity:'info', summary:'New Office is currently being created'});
        console.log("This is the officeName and Description", this.createOffice.value.officeName,this.createOffice.value.officeDescription);
        console.log("This is the List of users", this.selectedUsers);
      
      if(this.selectedUsers.length != 0){
        this.selectedUsers.forEach(user => {
           this.userList.push({userId : user.id});
        });
      }
      const payload = {
        caption: this.createOffice.value.officeName,
        description: this.createOffice.value.officeDescription,
        officer : this.userList ?? []
      }

      console.log("Final payload ",payload);
      this.supplierService.create(payload).subscribe(async(result:CommonResponse)=>{
        if(result.responseCode == "00"){
          this.messageService.add({severity:'success', summary:result.responseMsg})
          this.createOffice.reset();
          this.selectedUsers = [];
          this.getAllOffices();
        }
        else{
          this.messageService.add({severity:'error', summary:result.responseMsg});
        }
      })
    }
  }

  getAllOffices(){
    this.messageService.add({severity:'success', summary:"Loading office list..."})
    this.supplierService.allSuppliers().subscribe(async(res: CommonResponse) => {
      if(res.responseCode=="00"){
        this.messageService.add({severity:'success', summary:res.responseMsg})
        this.officeList = res.responseData;
        this.officeList = this.officeList.sort(function (a, b) {  return a.sequence - b.sequence});
        console.log("List of offices",this.officeList);
      }
    })
  }

  reOrderOffice(office:any,reOrderOption:any){
    this.messageService.add({severity:'info', summary:"Moving " + office.caption + " up the sequence..."})
    this.supplierService.reOrderOffice(office.id,reOrderOption).subscribe(async(res:CommonResponse)=>{
       if(res.responseCode == "00"){
        this.messageService.add({severity:'success', summary:res.responseMsg});
        this.getAllOffices();
       }
       else{
        this.messageService.add({severity:'error', summary:res.responseMsg});
       }
    })
  }

  

  deactivateOffice(office){
    this.confirmationService.confirm({
      message: 'Do you want to delete ' + office.caption +'?',
      header:  'Delete Confirmation',
      icon: 'pi pi-info-circle',
      accept: () => {
          this.messageService.add({severity:'info', summary:'Confirmed', detail:'Record is currently being disabled'});
          this.supplierService.disableOffice(office.sequence).subscribe(async(res:CommonResponse)=>{
            if(res.responseCode == "00"){
              this.messageService.add({severity:'success', summary:res.responseMsg});
              this.getAllOffices();
            }
            else{
              this.messageService.add({severity:'error', summary:'Confirmed', detail:res.responseMsg});
            }
          })
      },
      reject: (type) => {

            this.messageService.add({severity:'error', summary:'Rejected', detail:'You have rejected'});
      }
  });
  }

  showModalDialog(office:any){
    this.chosenOfficers = [];
    this.messageService.add({severity:'info', summary:'Confirmed', detail:'Populating the record to update'});
      this.currentHeader = office.caption
      //this.officers = []
      //this.officers = office.officers
      console.log("This is under showModal " + office.officers)
      office.officers.forEach(officer => {
          this.users.forEach(user=>{
             if(officer.userId == user.id){
              this.chosenOfficers.push(user);
              console.log("userList " + this.chosenOfficers);
             }
          })
      });
      this.createOffice.patchValue(
        {  
          officeName:office.caption,
          officeDescription:office.description
        })
      
      this.currentOfficers = this.chosenOfficers;
      this.currentOfficeId = office.id;
      this.updateFlag = true;
  }

 
  cancelUpdate(){
    this.createOffice.reset();
    this.selectedUsers = [];
    this.updateFlag = false;
    this.moreSelectedUsers = [];
    this.userList = [];
  }

  updateReCord(){
      this.messageService.add({severity:'info', summary:'Confirmed', detail:'Updating records the records.....'});
      const payload = {
        caption:this.createOffice.value.officeName,
        description : this.createOffice.value.officeDescription
      }
      console.log("Values to update " + payload.caption, payload.description);
      this.supplierService.updateOffice(this.currentOfficeId,payload).subscribe(async(res:CommonResponse)=>{
         if(res.responseCode == "00"){
          this.messageService.add({severity:'success', summary:'Confirmed', detail:'Successfully updated the record'});
          this.getAllOffices();
          this.createOffice.reset();
         }
         else{
          this.messageService.add({severity:'error', summary:'Confirmed', detail:'Could not update record'});
         }
      })
  }

  disableOfficer(user){
    this.messageService.add({severity:'info', summary:'Confirmed', detail:'disabling officer.....'});
    this.supplierService.disableOfficer(user.id,this.currentOfficeId).subscribe(async(result:CommonResponse)=>{
      if(result.responseCode == "00"){
        this.messageService.add({severity:'success', summary:'Confirmed', detail:'Successfully disabled officer'});
        this.getAllOffices();
        this.currentOfficers = this.currentOfficers.filter(x=>{
          return x.id != user.id
        })
        console.log("current state of currentOfficers after update",this.currentOfficers);
       
      this.updateFlag = true;
       }
       else{
        this.messageService.add({severity:'error', summary:'Confirmed', detail:'Could not update record'});
       }
    })
  }

  updateOfficers(){
    this.messageService.add({severity:'info', summary:'Confirmed', detail:'updating officer(s).....'});
    if(this.moreSelectedUsers.length != 0){
      this.moreSelectedUsers.forEach(user => {
         this.userList.push({userId : user.id});
      });
    }
    console.log("this is the amount of things selected " + this.moreSelectedUsers)
    console.log("this is the amount of things processed " + this.userList)
    this.supplierService.addOfficers(this.currentOfficeId,this.userList).
    subscribe(async(result:CommonResponse)=>{
      if(result.responseCode == "00"){
        this.messageService.add({severity:'success', summary:'Confirmed', detail:'Successfully disabled officer'});
        console.log("This are the officers from server ",result.responseData);
        this.currentOfficers = [];
        result.responseData.forEach(officer=>{
          var currentUser = this.users.filter(user=>{
            return officer.userId == user.id
          })[0]
          this.currentOfficers.push(currentUser);
        })
        this.moreSelectedUsers = [];
        this.userList = [];
        this.ngOnInit();
      }
      else{
        this.messageService.add({severity:'error', summary:'Confirmed', detail:'Could not add officers successfully'});
      }
    })
  }



}
