import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from "@angular/core";
import { ConfirmationService, MessageService } from "primeng/api";
import { UIChart } from "primeng/chart";
import { BreadcrumbService } from "src/app/breadcrumb.service";
import {
  BarChartDataFormat,
  ChartDataFormat,
  ConvergenceIncident,
  ConvergenceRegionEvaluationVM,
  EventType,
  GetConvergenceReportVM,
} from "src/app/interfaces/convergence";
import { ConvergenceService } from "src/app/services/convergence.service";

@Component({
  selector: "app-incidence-convergence-report",
  templateUrl: "./incidence-convergence-report.component.html",
  styleUrls: ["./incidence-convergence-report.component.scss"],
})
export class IncidenceConvergenceReportComponent
  implements OnInit, AfterViewInit
{
  @ViewChild("formWrapper") public formWrapper: ElementRef;
  @ViewChild("chart1") chart1: UIChart;
  @ViewChild("chart2") chart2: UIChart;
  @ViewChild("chart3") chart3: UIChart;
  @ViewChild("chart4") chart4: UIChart;
  inidenceOccurrenceChartData: {
    labels: string[];
    datasets: {
      data: number[];
      backgroundColor: string[];
    }[];
  };
  occurrencePerRegionChartData: BarChartDataFormat;
  dateFrom: Date;
  dateTo: Date;
  allReports: ConvergenceRegionEvaluationVM[];
  selectedReports: ConvergenceRegionEvaluationVM[];
  reportCols: any[];
  locations: {
    lat: number;
    lng: number;
    label?: string;
  }[] = [];
  fetchingReports: boolean;
  showReport: boolean;
  showRegionIncidents: boolean;
  regionIncidents: ConvergenceIncident[] = [];
  regionIncidentCols: any[];
  allEventTypes: EventType[];
  showLegend: boolean;
  legendCols: any[];
  linearProgresionChartData: BarChartDataFormat;
  eventRadarChartData: BarChartDataFormat;

  constructor(
    public convergenceService: ConvergenceService,
    private breadcrumbService: BreadcrumbService,
    public confirmationService: ConfirmationService,
    public messageService: MessageService
  ) {}

  ngOnInit(): void {
    this.breadcrumbService.setItems([
      {
        label: "Convergence",
      },
      {
        label: "Incident Management",
      },
      {
        label: "Report",
        routerLink: ["/home/convergence/incident-management/report"],
      },
    ]);

    this.inidenceOccurrenceChartData = {
      labels: [],
      datasets: [
        {
          data: [],
          backgroundColor: [],
        },
      ],
    };

    this.occurrencePerRegionChartData = {
      labels: [],
      datasets: [],
    };

    this.linearProgresionChartData = {
      labels: [],
      datasets: [],
    };

    this.eventRadarChartData = {
      labels: [],
      datasets: [],
    };

    this.reportCols = [
      { field: "region", header: "Region" },
      { field: "evaluation", header: "Evaluation" },
      { field: "statesInRegion", header: "States In Region" },
    ];

    this.regionIncidentCols = [
      { field: "caption", header: "Caption" },
      { field: "description", header: "Description" },
      { field: "riskLevel", header: "Risk Level" },
      { field: "sourceName", header: "Source" },
      { field: "typeName", header: "Type" },
      { field: "reportedByEntityName", header: "Reorted By" },
      { field: "dateOccurred", header: "Date Occurred" },
    ];

    this.legendCols = [
      { field: "caption", header: "Caption" },
      { field: "description", header: "Description" },
      { field: "shortForm", header: "Legend" },
      { field: "createdAt", header: "Date Created" },
    ];

    this.FetchAllEventType();
  }

  ngAfterViewInit(): void {
    // Load google maps script after view init
    const DSLScript = document.createElement("script");
    DSLScript.src =
      "https://maps.googleapis.com/maps/api/js?key=AIzaSyCQuetprs2UHb_zKSXznyumyfvw95ERCDY"; // replace by your API key
    DSLScript.type = "text/javascript";
    document.body.appendChild(DSLScript);
    document.body.removeChild(DSLScript);
  }

  FetchAllEventType() {
    this.convergenceService.GetAllEventTypes().subscribe(
      async (data) => {
        this.allEventTypes = data;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all event types at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  LoadIncidenceReport() {
    if (this.dateFrom >= this.dateTo) {
      this.messageService.add({
        severity: "error",
        summary: "Notice",
        detail: "Date To must be greater than date from",
      });
      return;
    }

    this.fetchingReports = true;
    const postData: GetConvergenceReportVM = {
      dateFrom: this.dateFrom,
      dateTo: this.dateTo,
    };
    this.convergenceService.GetConvergenceReport(postData).subscribe(
      async (data) => {
        this.fetchingReports = false;
        this.showReport = true;

        if (data.occurredPerTypes) {
          this.inidenceOccurrenceChartData.labels =
            data.occurredPerTypes.labels;
          this.inidenceOccurrenceChartData.datasets[0].data =
            data.occurredPerTypes.datas;
          this.inidenceOccurrenceChartData.datasets[0].backgroundColor =
            data.occurredPerTypes.backgroundColors;
        }
        this.occurrencePerRegionChartData = data.occurredPerRegions;
        this.linearProgresionChartData = data.incidenceTypeLinearProgression;
        this.eventRadarChartData = data.eventRadar;

        if (data.approvedIncidents.length > 0) {
          data.approvedIncidents.forEach((x) =>
            this.locations.push({
              lat: x.locationLatitude,
              lng: x.locationLongitude,
              label:
                this.allEventTypes.find((e) => e.id == x.typeId) == null
                  ? ""
                  : this.allEventTypes.find((e) => e.id == x.typeId).shortForm,
            })
          );
          await new Promise((resolve) => setTimeout(resolve, 3000)); // 3 secs
          this.initMap();
        }

        this.allReports = data.regionEvaluations;

        this.chart1.reinit();
        // this.chart2.reinit();
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to get convergence report / analytics at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
        this.fetchingReports = false;
      }
    );
  }

  // Initialize and add the map
  initMap(): void {
    if (this.locations.length <= 0) return;

    // The map, centered at Loc
    const map = new google.maps.Map(
      document.getElementById("map") as HTMLElement,
      {
        zoom: 6,
        center: {
          lat: 9.0643305,
          lng: 7.4892974,
        },
      }
    );

    this.locations.forEach((x) => {
      // The marker, positioned at Loc
      const marker = new google.maps.Marker({
        position: x,
        map: map,
        label: x.label,
      });
    });
  }

  ViewRegionIncidents(item: ConvergenceRegionEvaluationVM) {
    this.regionIncidents = item.regionIncidents;
    this.showRegionIncidents = true;
  }

  HideRegionIncidentsDialogue() {
    this.showRegionIncidents = false;
    this.regionIncidents = [];
  }

  ShowTypeLegends() {
    this.showLegend = true;
  }

  HideLegendsDialogue() {
    this.showLegend = false;
  }
}
