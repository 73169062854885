import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CommonResponse } from '../interfaces/home';

@Injectable({
  providedIn: 'root'
})
export class ServicePricingService {

  constructor(private http: HttpClient) { }
  baseUrl = environment.baseUrl+'ServicePricing/';
  // fetch all ServicePricing in the database
  allServicePricingData(): Observable<CommonResponse>{
    return this.http.get<CommonResponse>(this.baseUrl);
  }
  getServicePricing(id: any):Observable<CommonResponse>{
    return this.http.get<CommonResponse>(this.baseUrl +id);
  }
  getServicePricingsByServiceId(serviceId: any):Observable<CommonResponse>{
    return this.http.get<CommonResponse>(this.baseUrl + 'ServiceId/' + serviceId);
  }
  getServicePricingsByBranchId(branchId: any):Observable<CommonResponse>{
    return this.http.get<CommonResponse>(this.baseUrl + 'BranchId/' + branchId);
  }
  postServicePricing(data: any){
    return this.http.post(this.baseUrl, data);
  }
  deleteServicePricing(id: any){
    return this.http.delete(this.baseUrl +id);
  }
  updateServicePricing(id: any, data: any){
    return this.http.put(this.baseUrl +id, data);
  }
}
