import { RegionalStore } from "src/app/interfaces/inventory";
import {
  ApplicantMaterialRequestBatch,
  LogMaterialBatchRequest,
  SetApplicationItems,
  TrackDetail,
  TrainingSchool,
} from "./../../../interfaces/employment";
import {
  JobApplication,
  JobPost,
  OperativeItem,
} from "./../../../interfaces/premployment";
import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { MessageService, ConfirmationService, TreeNode } from "primeng/api";
import { BreadcrumbService } from "src/app/breadcrumb.service";
import { GmaEmploymentService } from "src/app/services/gma-employment.service";

@Component({
  selector: "app-gma-material-request",
  templateUrl: "./gma-material-request.component.html",
  styleUrls: ["./gma-material-request.component.scss"],
})
export class GmaMaterialRequestComponent implements OnInit {
  @ViewChild("formWrapper") public formWrapper: ElementRef;
  allJobBatches: JobPost[];
  theJobBatch: JobPost;
  applicantsOngoingMaterialReq: JobApplication[];
  totalApplicants: number = 0;
  applicantsReady: number = 0;
  applicantsInProcess: number = 0;
  droppedApplicants: number = 0;
  carouselResponsiveOptions: any[] = [
    {
      breakpoint: "1024px",
      numVisible: 3,
      numScroll: 3,
    },
    {
      breakpoint: "768px",
      numVisible: 2,
      numScroll: 2,
    },
    {
      breakpoint: "560px",
      numVisible: 1,
      numScroll: 1,
    },
  ];
  allTrainingSchools: TrainingSchool[];
  pendingReqSwitch: boolean;
  fetchingMaterialReqsMade: boolean;
  allJobMaterialReqs: ApplicantMaterialRequestBatch[];
  selectedJobMaterialReq: ApplicantMaterialRequestBatch;
  materialReqCols: any[];
  materialBatchReqTrackTable: {
    name: string;
    status: number;
    data: TrackDetail[];
  }[];
  selectedMaterialBatchReqTrackTable: {
    name: string;
    status: number;
    data: TrackDetail[];
  };
  openSetItemsDialogue: boolean = false;
  allItemsForJobBatch: OperativeItem[] = [];
  selectedJobItem: OperativeItem;
  jobApplicantItemSetInView: JobApplication;
  applicantSelectedItems: OperativeItem[] = [];
  openLogMaterialReqDialogue: boolean = false;
  allRegionStores: RegionalStore[];
  theRegionStore: RegionalStore;
  canRaiseMaterialReq: boolean = false;
  openMaterialBatchInfoDialogue: boolean = false;
  applicantsWithMaterialReqsTree: TreeNode[];
  showTrackMaterialReqBatch: boolean = false;
  initReq: string;
  initReqBody: TrackDetail[];
  openProcessInfoDialogue: boolean = false;

  constructor(
    public employmentService: GmaEmploymentService,
    public messageService: MessageService,
    private breadcrumbService: BreadcrumbService,
    public confirmationService: ConfirmationService
  ) {}

  ngOnInit(): void {
    this.breadcrumbService.setItems([
      {
        label: "Guard Management",
        routerLink: ["/home/guard-management/set-up"],
      },
      {
        label: "Approval",
        routerLink: ["/home/guard-management/material-request"],
      },
    ]);

    this.materialReqCols = [
      { field: "batchCode", header: "BatchCode" },
      { field: "requestDesc", header: "RequestDesc" },
      { field: "createdAt", header: "CreatedAt" },
    ];

    this.materialBatchReqTrackTable = [
      {
        name: "Initial Request",
        status: 0,
        data: null,
      },
      {
        name: "Approval Queue",
        status: 0,
        data: null,
      },
      {
        name: "Confirmation Out",
        status: 0,
        data: null,
      },
      {
        name: "Acceptance In",
        status: 0,
        data: null,
      },
    ];

    this.FetchAllJobPosts();
    this.FetchAllTrainingSchools();
    this.FetchAllRegionStores();
  }

  FetchAllJobPosts() {
    this.employmentService.GetAllJobBatches().subscribe(
      async (data) => {
        this.allJobBatches = data;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable get all job batches at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  FetchAllTrainingSchools() {
    this.employmentService.GetAllTrainingSchoolsForManageReq().subscribe(
      async (data) => {
        this.allTrainingSchools = data;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable get all training schools at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  FetchAllRegionStores() {
    this.employmentService.GetAllRegionStores().subscribe(
      async (data) => {
        this.allRegionStores = data;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable get all regional stores at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  LoadJobBatch() {
    //this.applicantsOngoingMaterialReq = this.theJobBatch.applicants;
    this.canRaiseMaterialReq = false;
    this.totalApplicants = this.theJobBatch.applicants.length;
    this.applicantsReady = this.theJobBatch.applicants.filter(
      (applicant) =>
        applicant.applicantEvaluation.currentStage >= 6 &&
        applicant.applicantEvaluation.status != 2
    ).length;
    this.applicantsInProcess = this.theJobBatch.applicants.filter(
      (applicant) =>
        applicant.applicantEvaluation.currentStage < 6 &&
        applicant.applicantEvaluation.status != 2
    ).length;
    this.droppedApplicants = this.theJobBatch.applicants.filter(
      (applicant) => applicant.applicantEvaluation.status == 2
    ).length;
    this.applicantsOngoingMaterialReq = this.theJobBatch.applicants.filter(
      (applicant) =>
        applicant.applicantEvaluation.currentStage == 6 &&
        applicant.applicantEvaluation.status != 2
    );
    this.applicantsOngoingMaterialReq.forEach((applicant) => {
      if (
        applicant.applicantEvaluation.operativeItems.length > 0 &&
        applicant.applicantMaterialRequests.length == 0
      ) {
        this.canRaiseMaterialReq = true;
        return;
      }
    });
    this.allJobMaterialReqs = this.theJobBatch.jobMaterialRequestBatches;
  }

  GetTrainingSchoolCode(jobApplicationId: number): string {
    let trainingSch = this.GetTrainingSchool(jobApplicationId);
    if (trainingSch) return trainingSch.schoolCode;
    else return "N/A";
  }

  GetTrainingSchool(jobApplicationId: number): TrainingSchool {
    let trainingSchool: TrainingSchool;
    this.allTrainingSchools.forEach((sch) => {
      sch.students.forEach((stud) => {
        if (stud.jobApplicationId == jobApplicationId) {
          trainingSchool = sch;
          return trainingSchool;
        }
      });
    });

    return trainingSchool;
  }

  HideSetItemsDialog() {
    this.openSetItemsDialogue = false;
    this.applicantSelectedItems = [];
    this.allItemsForJobBatch.forEach((item) => (item.isSelected = false));
  }

  SetApplicantItemsRequired(item: JobApplication) {
    this.openSetItemsDialogue = true;
    this.jobApplicantItemSetInView = item;
    this.allItemsForJobBatch = this.theJobBatch.operativeType.operativeItems;
    this.applicantSelectedItems = [];

    if (item.applicantEvaluation.operativeItems.length > 0) {
      let revisedItemsForJobBatch: OperativeItem[] = [];
      this.applicantSelectedItems = [];
      this.allItemsForJobBatch.forEach((x) => {
        let check = item.applicantEvaluation.operativeItems.find(
          (y) => y.operativeItemId == x.id
        );
        if (check) {
          x.isSelected = true;
          this.applicantSelectedItems.push(x);
          revisedItemsForJobBatch.push(x);
        } else revisedItemsForJobBatch.push(x);

        this.allItemsForJobBatch = revisedItemsForJobBatch;
      });
    } else
      this.allItemsForJobBatch.forEach((item) => (item.isSelected = false));
  }

  AddItemToSelected(item: OperativeItem) {
    this.applicantSelectedItems.push(item);
    let operativeItem = this.allItemsForJobBatch.find((x) => x.id == item.id);
    operativeItem.isSelected = true;
  }

  RemoveItemFromSelected(item: OperativeItem) {
    const index = this.applicantSelectedItems.indexOf(item);
    if (index > -1) {
      this.applicantSelectedItems.splice(index, 1);
    }
    let operativeItem = this.allItemsForJobBatch.find((x) => x.id == item.id);
    operativeItem.isSelected = false;
  }

  SetApplicantItems() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Setting Applicant Operative Items...",
    });

    const postData: SetApplicationItems = {
      applicationEvaluationId:
        this.jobApplicantItemSetInView.applicantEvaluation.id,
      opertiveItemsIds: this.applicantSelectedItems.map((x) => x.id),
    };

    this.employmentService.SetApplicantMaterialItems(postData).subscribe(
      async () => {
        await this.messageService.add({
          severity: "success",
          summary: "Removed",
          detail: "Applicant Operative Items Set Successfully",
        });

        this.jobApplicantItemSetInView.applicantEvaluation.operativeItems = [];
        this.applicantSelectedItems.forEach((selectedItem) => {
          //Continue logic from here
          this.jobApplicantItemSetInView.applicantEvaluation.operativeItems.push(
            {
              operativeItemId: selectedItem.id,
              operativeItem: selectedItem,
            }
          );
        });
        this.jobApplicantItemSetInView = null;
        this.HideSetItemsDialog();
        this.LoadJobBatch();
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to set applicants opertaive items at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  HideRaiseReqDialog() {
    this.openLogMaterialReqDialogue = false;
  }

  OpenRaiseMaterialRequestDialogue() {
    this.openLogMaterialReqDialogue = true;
  }

  RaiseMaterialRequest() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Raising Material Request...",
    });

    const postData: LogMaterialBatchRequest = {
      jobPostId: this.theJobBatch.id,
      regionStoreId: this.theRegionStore.id,
      applicantRequest: [],
    };

    this.applicantsOngoingMaterialReq.forEach((applicant) => {
      if (
        applicant.applicantEvaluation.operativeItems.length > 0 &&
        applicant.applicantMaterialRequests.length == 0
      ) {
        postData.applicantRequest.push({
          jobApplicationId: applicant.id,
          operativeItems: applicant.applicantEvaluation.operativeItems.map(
            (x) => x.operativeItemId
          ),
        });
      }
    });

    this.employmentService.LogMaterialRequestBatch(postData).subscribe(
      async () => {
        await this.messageService.add({
          severity: "success",
          summary: "Removed",
          detail: "Material Request Raised Successfully",
        });

        this.canRaiseMaterialReq = false;
        this.theJobBatch = null;
        this.openLogMaterialReqDialogue = false;
        this.FetchAllJobPosts();
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to raise material request at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  TrackBatch(item: ApplicantMaterialRequestBatch) {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Tracking Batch Current Progress...",
    });

    this.employmentService.TrackMaterialRequestBatch(item.id).subscribe(
      async (data) => {
        await this.messageService.add({
          severity: "success",
          summary: "Removed",
          detail: "Material Request Batch Tracked Successfully",
        });

        this.materialBatchReqTrackTable[0].status = data[0].status;
        this.materialBatchReqTrackTable[1].status = data[1].status;
        this.materialBatchReqTrackTable[2].status = data[2].status;
        this.materialBatchReqTrackTable[3].status = data[3].status;

        this.materialBatchReqTrackTable[0].data = data[0].trackDetails;
        this.materialBatchReqTrackTable[1].data = data[1].trackDetails;
        this.materialBatchReqTrackTable[2].data = data[2].trackDetails;
        this.materialBatchReqTrackTable[3].data = data[3].trackDetails;

        this.showTrackMaterialReqBatch = true;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to track material request at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  ViewMoreInfo(item: ApplicantMaterialRequestBatch) {
    this.applicantsWithMaterialReqsTree = [];
    const applicants = [];

    item.applicantRequests.forEach((applicantReq) => {
      const applicantMaterials = [];
      applicantReq.itemsRequested.forEach((applicantItem) => {
        applicantMaterials.push({
          label: "Item: " + applicantItem.operativeItem.item.caption,
          key: applicantItem.id,
          icon: "pi pi-fw pi-align-justify",
          data: applicantItem,
          selectable: false,
        });
      });

      applicants.push({
        label:
          "Applicant: " +
          applicantReq.jobApplication.personalInformation.lastname +
          " " +
          applicantReq.jobApplication.personalInformation.othername,
        key: applicantReq.id,
        expandedIcon: "pi pi-minus-circle",
        collapsedIcon: "pi pi-plus-circle",
        expanded: true,
        data: applicantReq,
        children: applicantMaterials,
        selectable: true,
      });
    });

    this.applicantsWithMaterialReqsTree = [
      {
        label: "Material Requests",
        key: "Halogen Folder",
        expandedIcon: "pi pi-minus-circle",
        collapsedIcon: "pi pi-plus-circle",
        expanded: true,
        children: applicants,
        data: 1001,
        selectable: true,
      },
    ];
    this.openMaterialBatchInfoDialogue = true;
  }

  HideMaterialBatchInfoDialog() {
    this.openMaterialBatchInfoDialogue = false;
  }

  ViewBatchApprovalInfo(item: any) {
    this.openProcessInfoDialogue = true;
    this.initReq = item.name;
    this.initReqBody = item.data;
  }

  HideProcessInfoDialog() {
    this.openProcessInfoDialogue = false;
  }
}
