import { Component, OnInit } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { MessageService } from "primeng/api";
import {
  Branch,
  Contact,
  Customer,
  Division,
  Industry,
  Lead,
  LeadDivision,
  LeadOrigin,
  LeadType,
  Lga,
  State,
  Office,
  CommonResponse,
} from "../../../../interfaces/home";
import { LeadOriginService } from "../../../../services/lead-origin.service";
import { EventInput } from "@fullcalendar/core";
import { LeadDivisionService } from "../../../../services/lead-division.service";
import { LeadService } from "../../../../services/lead.service";
import { forkJoin, Observable } from "rxjs";
import { LeadTypeService } from "../../../../services/lead-type.service";
import { IndustryService } from "src/app/services/industry.service";
import { CustomerService } from "../../../../services/customer.service";
import { StateService } from "../../../../services/state.service";
import { BranchService } from "src/app/services/branch.service";
import { EngagementContactsService } from "src/app/home/engagement-contacts/engagement-contacts.service";
import { DomSanitizer } from "@angular/platform-browser";
import { FileStorageService } from "src/app/services/file-storage.service";

@Component({
  selector: "app-lead-division",
  templateUrl: "./lead-division.component.html",
  styleUrls: ["./lead-division.component.scss"],
})
export class LeadDivisionComponent implements OnInit {
  formData: any;
  leadDivisionForm: FormGroup;
  leadOrigins: LeadOrigin[] = [];
  imageSrc: any;
  edLeadDivision: any;
  uploadingNewPicture: boolean;
  isEditing: boolean;
  editingDivision: boolean;
  private imageFile: any;
  displayLoading: any;
  private fb: any;
  public CurrentLeadData: any;
  public downloadURL: Observable<any>;
  selectedLeadDivisionOrigin: LeadOrigin = null;
  selectedLeadDivision: LeadDivision = null;
  public leadDivisions: LeadDivision[] = [];
  public newLeadDivision = true;
  leadDivisionTypes: LeadType[] = [];
  public selectedLeadDivisionType: LeadType = null;
  isRetail = false;
  selectedIndustry: Industry = null;
  industry: Industry[] = [];
  suggestedDivisions: LeadDivision[] = [];
  suggestedCustomer: Customer = null;
  selectedSuggestDivision: LeadDivision | null;
  suggestedPrimaryContact: Contact | null;
  suggestedSecondaryContact: Contact | null;
  suggestedKeyPerson: Contact | null;
  states: State[];
  selectedState: State = null;
  lgas: Lga[];
  selectedLGA: Lga = null;
  street: any;
  startUpData$: Observable<CommonResponse[]>;
  branches: Branch[];
  selectedBranch: Branch = null;
  offices: Office[];
  selectedOffice: Office = null;
  suspectContact: any[];

  constructor(
    private formBuilder: FormBuilder,
    private messageService: MessageService,
    private leadOriginService: LeadOriginService,
    private fileStorageService: FileStorageService,
    private leadDivisionService: LeadDivisionService,
    public leadService: LeadService,
    public leadTypeService: LeadTypeService,
    private industryService: IndustryService,
    private customerService: CustomerService,
    private stateService: StateService,
    private branchService: BranchService,
    public engagementService: EngagementContactsService,
    private sanitizer: DomSanitizer
  ) {}

  async ngOnInit() {
    this.fetchStartUpData();

    this.isRetail = this.leadService.isRetail;

    this.leadDivisionForm = this.formBuilder.group({
      divisionName: ["", Validators.required],
      industry: ["", Validators.required],
      phoneNumber: [null, Validators.required],
      email: [null, [Validators.required, Validators.email]],
      leadOriginId: [null, Validators.required],
      leadTypeId: [null, Validators.required],
      state: [null, Validators.required],
      lga: [null, Validators.required],
      branch: [null, Validators.required],
      office: [null, Validators.required],
      street: ["", Validators.required],
      logoUrl: [""],
    });

    if (!this.isRetail) {
      this.leadDivisionForm.addControl(
        "rcNumber",
        new FormControl("", Validators.required)
      );
    }

    await this.leadDivisionForm.disable();
  }

  getAllContactsToSuspect(id) {
    this.engagementService.getAllContactsAttachedToSuspects(id).subscribe(
      async (res: any) => {
        if (res.responseCode == "00") {
          this.suspectContact = [];
          this.suspectContact = res.responseData.filter(
            (x) => x.contact.isDeleted == false
          );
        } else {
          this.suspectContact = [];
        }
      },
      (error) => {
        this.suspectContact = [];
      }
    );
  }

  fetchStartUpData() {
    this.startUpData$ = forkJoin([
      this.stateService.allState(),
      this.leadOriginService.allLeadOrigin(),
      this.leadTypeService.allLeadTypeData(),
      this.industryService.allIndustry(),
      this.branchService.allBranch(),
    ]);

    this.startUpData$.subscribe(
      async (res) => {
        const [states, leadOrigins, leadTypes, industries, branches] = res;

        this.states = states.responseData ?? [];
        this.leadOrigins = leadOrigins.responseData ?? [];
        this.leadDivisionTypes = leadTypes.responseData ?? [];
        this.industry = industries.responseData ?? [];
        this.branches = branches.responseData ?? [];

        this.fetchCurrentLead();
      },
      (error) => {
        this.connectionError();
      }
    );
  }

  setLga() {
    if (this.selectedState) {
      this.lgas = this.selectedState.lgAs;
    }
  }

  setOffices() {
    if (this.selectedBranch) {
      this.offices = this.selectedBranch.offices;
    }
  }

  async fetchCurrentLead() {
    this.leadService.activeLead().subscribe(async (res: any) => {
      if (res) {
        this.CurrentLeadData = res.responseData;
        //  await this.leadDivisionForm.enable();
        this.getAllContactsToSuspect(this.CurrentLeadData.suspectId);

        await this.renderSuggestion(
          res.responseData?.groupType.id,
          res.responseData?.groupName
        );
        // this.customerService.getCustomer(this.selectedGroupName.id).subscribe( async (result: Customer) => {
        //   this.leadSuggestion = true;
        //   const rcNumberControl = this.leadForm.get('rcNumber');
        //   rcNumberControl.setValue(result.rcNumber);
        //   this.leadForm.enable();
        //   rcNumberControl.disable();
        // })

        const _division = res.responseData?.leadDivisions;
        this.leadDivisions = _division;
        if (_division && _division.length > 0) {
          // do something with the selected division
          this.selectDivision(this.leadDivisions[0]);
        }
      }
    });
  }

  transform(image) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(image);
  }
  async renderSuggestion(groupTypeId, groupName) {
    this.customerService
      .fetchByGroupType(groupTypeId)
      .subscribe(async (r: any) => {
        if (r?.responseCode == "00") {
          let _customerGroups = [];
          var res = r.responseData;
          _customerGroups = res;
          const activeGroup = res.find(
            (group) => group.groupName.toLowerCase() === groupName.toLowerCase()
          );
          if (activeGroup) {
            this.customerService
              .getCustomer(activeGroup.id)
              .subscribe((r: CommonResponse) => {
                var result = r.responseData;
                this.suggestedCustomer = result;
                this.suggestedDivisions = result.customerDivisions;
                if (this.selectedLeadDivision) {
                  const _sDivision = this.suggestedDivisions.find(
                    (_ssDivision) =>
                      _ssDivision.divisionName ===
                      this.selectedLeadDivision.divisionName
                  );
                  if (_sDivision) {
                    this.suggestedPrimaryContact = _sDivision.primaryContact;
                    this.suggestedSecondaryContact =
                      _sDivision.secondaryContact;
                    // this.suggestedKeyPerson = _sDivision.key
                  }
                }
              });
          }
        }
      });
  }

  processForm(event) {
    console.log(event);
  }

  async saveLeadDivision() {
    // check if picture is selected
    this.displayLoading = true;
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Saving Lead Division information",
    });
    if (this.imageFile) {
      this.fileStorageService.UploadMultipleFilesFromDataUrl([this.imageFile]);
      this.fileStorageService.onUploadFinished.subscribe(
        async (resp: CommonResponse<string[]>) => {
          if (resp.responseCode != "00") {
            this.messageService.add({
              severity: "error",
              summary: "Notice",
              detail: resp.responseMsg,
            });
          } else {
            let url = resp.responseData[0];
            if (url) {
              this.fb = url;
              this.imageSrc = this.fb;
            }
            // this.leadDivisionForm.patchValue({logoUrl: this.fb});
            if (this.newLeadDivision) {
              await this.createLeadDivision();
            } else {
              await this.updateLeadDivision();
            }
            this.displayLoading = false;
          }
        },
        (error) => {
          console.log("Error while uploading files " + error);
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail: "ERR: Unable to upload image to storage",
          });
        }
      );
    } else {
      // this.leadDivisionForm.patchValue({logoUrl: ''}); // set the image url to the default set image
      // check if there is any selectedLeadDivision data is set
      if (this.newLeadDivision) {
        await this.createLeadDivision();
      } else {
        await this.updateLeadDivision();
      }
      this.displayLoading = false;
    }
    this.displayLoading = false;
  }
  onFileSelect(event) {
    if (!this.checkMimeType(event) || !this.checkFileSize(event)) {
      this.imageSrc = "";
      this.leadDivisionForm.get("logoUrl").reset();
      return;
    }
    this.uploadingNewPicture = true;
    this.isEditing = true;
    const reader = new FileReader();
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      this.imageFile = event.target.files[0];
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.imageSrc = reader.result as string;
        this.leadDivisionForm.patchValue({
          imageUrl: reader.result,
        });
      };
    }
  }

  checkMimeType(event) {
    const files = event.target.files[0];
    // list allow mime type
    const types = ["image/png", "image/jpeg", "image/jpg", "image/gif"];
    // loop access array
    if (!files) {
      this.messageService.add({
        severity: "error",
        summary: "Failed",
        detail: "Please select an Image file",
      });
      return false;
    }
    // compare file type find doesn't match
    if (types.every((type) => files.type !== type)) {
      // create error message and assign to container
      this.messageService.add({
        severity: "error",
        summary: "Failed",
        detail: "Image format not supported",
      });
      return false;
    }
    return true;
  }

  checkFileSize(event: EventInput) {
    const files = event.target.files[0];
    if (!files) return true;
    const size = 400000;
    if (files.size > size) {
      event.target.value = null;
      this.messageService.add({
        severity: "error",
        summary: "Failed",
        detail: "Image is too large, please pick a smaller file",
      });
      return false;
    }
    return true;
  }
  private connectionError() {
    this.messageService.add({
      severity: "error",
      summary: "Failed",
      detail: "Connection Error, Please try again",
    });
  }

  createLeadDivision() {
    this.messageService.add({
      severity: "success",
      summary: "Notice",
      detail: "Image Uploaded",
    });
    // create new lead
    const postData = {
      leadOriginId: this.selectedLeadDivisionOrigin.id,
      leadTypeId: this.selectedLeadDivisionType.id,
      industry: this.leadDivisionForm.value.industry.caption,
      rcNumber: this.isRetail
        ? "RC00000000"
        : this.leadDivisionForm.value.rcNumber,
      divisionName: this.leadDivisionForm.value.divisionName,
      phoneNumber: this.leadDivisionForm.value.phoneNumber,
      email: this.leadDivisionForm.value.email,
      logoUrl: this.fb,
      stateId: this.selectedState.id,
      lgaId: this.selectedLGA.id,
      street: this.street,
      address: `${this.street}, ${this.selectedLGA.name}, ${this.selectedState.name} State`,
      leadId: this.CurrentLeadData.id,
      branchId: this.selectedBranch.id,
      officeId: this.selectedOffice.id,
    };
    this.leadDivisionService
      .postLeadDivision(postData)
      .subscribe(async (res: any) => {
        this.messageService.add({
          severity: "success",
          summary: "Notice",
          detail: "Lead Division Created",
        });
        this.leadService.refreshData();
      });
  }

  updateLeadDivision() {
    // update lead
  }

  selectDivision(leadDivision?: LeadDivision) {
    this.newLeadDivision = false;
    this.edLeadDivision = true;
    this.selectedLeadDivision = leadDivision;
    this.leadDivisionService.setCurrentLeadDivision(this.selectedLeadDivision);
    this.leadDivisionForm.patchValue({
      divisionName: this.selectedLeadDivision.divisionName,
      email: this.selectedLeadDivision.email,
      rcNumber: this.selectedLeadDivision.rcNumber,
      phoneNumber: this.selectedLeadDivision.phoneNumber,
      address: this.selectedLeadDivision.address,
    });
    this.selectedState = this.states.find(
      (state) => state.id === this.selectedLeadDivision.stateId
    );
    this.selectedLGA = this.selectedState.lgAs.find(
      (lga) => lga.id === this.selectedLeadDivision.lgaId
    );
    this.selectedLeadDivisionOrigin = this.leadOrigins.find(
      (leadOrigin) => leadOrigin.id === this.selectedLeadDivision.leadOriginId
    );
    this.selectedLeadDivisionType = this.leadDivisionTypes.find(
      (leadType) => leadType.id === this.selectedLeadDivision.leadTypeId
    );
    this.selectedIndustry = this.industry.find(
      (industry) =>
        industry?.caption?.toLowerCase() ===
        this.selectedLeadDivision?.industry?.toLowerCase()
    );
    this.imageSrc = this.selectedLeadDivision.logoUrl;
    this.fb = this.selectedLeadDivision.logoUrl;
    this.selectedBranch = this.branches.find(
      (x) => x.id === this.selectedLeadDivision.branchId
    );
    this.selectedOffice = this.selectedBranch?.offices.find(
      (x) => x.id === this.selectedLeadDivision.officeId
    );
  }

  newDivision() {
    this.leadDivisionService.setCurrentLeadDivision(null);
    this.selectedLeadDivision = null;
    this.leadDivisionForm.reset();
    this.imageSrc = "";
    this.newLeadDivision = true;
  }

  onRowSelect(event: any) {
    const selectDivision = event.data;
    this.leadDivisionForm.patchValue({
      divisionName: selectDivision.divisionName,
      email: selectDivision.email,
      rcNumber: selectDivision.rcNumber,
      phoneNumber: selectDivision.phoneNumber,
      address: selectDivision.address,
    });
    this.selectedState = this.states.find(
      (state) => state.id === this.selectedLeadDivision.stateId
    );
    this.selectedLGA = this.selectedState.lgAs.find(
      (lga) => lga.id === this.selectedLeadDivision.lgaId
    );
    this.selectedLeadDivisionOrigin = this.leadOrigins.find(
      (leadOrigin) => leadOrigin.id === selectDivision.leadOriginId
    );
    this.selectedLeadDivisionType = this.leadDivisionTypes.find(
      (leadType) => leadType.id === selectDivision.leadTypeId
    );
    this.selectedIndustry = this.industry.find(
      (industry) =>
        industry.caption.toLowerCase() === selectDivision.industry.toLowerCase()
    );
    this.imageSrc = selectDivision.logoUrl;
    this.fb = selectDivision.logoUrl;
    this.selectedBranch = this.branches.find(
      (x) => x.id === this.selectedLeadDivision.branchId
    );
    this.selectedOffice = this.selectedBranch.offices.find(
      (x) => x.id === this.selectedLeadDivision.officeId
    );
  }
}
