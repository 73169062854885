import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ConfirmationService, MessageService } from "primeng/api";
import { finalize } from "rxjs/operators";
import { BreadcrumbService } from "src/app/breadcrumb.service";
import {
  CreateGMAExtFacilitatorVM,
  GMAFacilitator,
} from "src/app/interfaces/employment";
import { CommonResponse, User } from "src/app/interfaces/home";
import { FileStorageService } from "src/app/services/file-storage.service";
import { GmaEmploymentService } from "src/app/services/gma-employment.service";
import { UserService } from "src/app/services/user.service";

@Component({
  selector: "app-gma-facilitator",
  templateUrl: "./gma-facilitator.component.html",
  styleUrls: ["./gma-facilitator.component.scss"],
})
export class GmaFacilitatorComponent implements OnInit {
  @ViewChild("formWrapper") public formWrapper: ElementRef;
  facilitatorForm: FormGroup;
  fetchingFacilitators: boolean = true;
  allFacilitators: GMAFacilitator[];
  selectedFacilitators: GMAFacilitator[];
  openAddFacilDialogue: boolean;
  openAddExtFacilDialogue: boolean;
  allUsers: User[] = [];
  theUser: User;
  isCreatingExtFacilitator: boolean = false;
  imageUrl: string;
  uploadedImage: any[] = [];
  editingExtFacilitator: boolean;
  imageChanged: boolean = false;
  cols: any[];

  constructor(
    fb: FormBuilder,
    public employmentService: GmaEmploymentService,
    public userService: UserService,
    private breadcrumbService: BreadcrumbService,
    public confirmationService: ConfirmationService,
    public messageService: MessageService,
    private fileStorageService: FileStorageService
  ) {
    this.facilitatorForm = fb.group({
      Firstname: ["", Validators.required],
      Lastname: ["", Validators.required],
      Mobile: ["", Validators.required],
      Email: ["", Validators.required],
      Address: ["", Validators.required],
    });
  }

  ngOnInit(): void {
    this.breadcrumbService.setItems([
      {
        label: "Guard Management",
        routerLink: ["/home/guard-management/item-settings"],
      },
      {
        label: "Facilitator",
        routerLink: ["/home/guard-management/facilitator"],
      },
    ]);

    this.cols = [
      { field: "imageUrl", header: "ImageUrl" },
      { field: "firstname", header: "Firstname" },
      { field: "lastname", header: "Lastname" },
      { field: "mobile", header: "Mobile" },
      { field: "email", header: "Email" },
      { field: "address", header: "Address" },
    ];

    this.FetchAllUsers();
    this.FetchAllGMAFacilitators();
  }

  async FetchAllUsers() {
    this.userService.allUser().subscribe(
      async (r) => {
        var data = r.responseData;
        this.allUsers = data;
        this.allUsers.forEach((user) => {
          user.fullName = user.lastName + " " + user.firstName;
        });
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all users at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  async FetchAllGMAFacilitators() {
    this.employmentService.GetAllGMAFacilitators().subscribe(
      async (data) => {
        this.allFacilitators = data;
        this.fetchingFacilitators = false;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all facilitators at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
        this.fetchingFacilitators = false;
      }
    );
  }

  OpenAddFacilDialogue() {
    this.openAddFacilDialogue = true;
  }

  RemoveFacilitator(item: GMAFacilitator) {
    this.confirmationService.confirm({
      message: "Are you sure you want to remove facilitator?",
      accept: () => {
        this.messageService.add({
          severity: "info",
          summary: "Notice",
          detail: "Removing facilitator...",
        });

        this.employmentService.DeleteGMAFacilitator(item.id).subscribe(
          async () => {
            this.messageService.add({
              severity: "success",
              summary: "Removed",
              detail: "Removed successfully",
            });

            this.HideFacilDialog();
            this.fetchingFacilitators = true;
            const index = this.allFacilitators.indexOf(item);
            if (index > -1) {
              this.allFacilitators.splice(index, 1);
            }
            this.fetchingFacilitators = false;
          },
          (error) => {
            console.log("Error: " + JSON.stringify(error));
            this.messageService.add({
              severity: "error",
              summary: "Notice",
              detail:
                "Unable to remove facilitator at the moment.. Reason: [" + error
                  ? error.error.message
                  : "request failed - permission" + "]",
            });
          }
        );
      },
    });
  }

  HideFacilDialog() {
    this.openAddFacilDialogue = false;
    this.theUser = null;
  }

  MakeUserToFacilitator() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Creating GMA Facilitator...",
    });

    this.employmentService.CreateGMAFacilitator(this.theUser.id).subscribe(
      async () => {
        this.messageService.add({
          severity: "success",
          summary: "Completed",
          detail: "GMA Facilitator Created Successfully...",
        });

        this.theUser = null;
        this.fetchingFacilitators = true;
        this.HideFacilDialog();
        this.FetchAllGMAFacilitators();
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to create facilitator the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  OpenAddExtFacilDialogue() {
    this.openAddExtFacilDialogue = true;
  }

  HideExtFacilDialog() {
    this.openAddExtFacilDialogue = false;
    this.facilitatorForm.reset();
  }

  onUpload(event, form) {
    this.uploadedImage = [];
    for (const file of event.files) {
      this.uploadedImage.push(file);
    }
    this.imageUrl = this.uploadedImage[0].objectURL;
    if (this.editingExtFacilitator) this.imageChanged = true;

    this.messageService.add({
      severity: "success",
      summary: "Success",
      detail: "Imgage Uploaded Successfully!",
    });
    form.clear();
  }

  UpdateExtFacilitator() {}

  CloseEditingExtFacilitator() {
    this.imageUrl = null;
    this.uploadedImage = [];
    this.editingExtFacilitator = false;

    this.HideExtFacilDialog();
  }

  async CreateExtFacilitator() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Creating External Facilitator...",
    });

    this.isCreatingExtFacilitator = true;

    const postData: CreateGMAExtFacilitatorVM = {
      imageUrl: null,
      firstname: this.facilitatorForm.get("Firstname").value,
      lastname: this.facilitatorForm.get("Lastname").value,
      mobile: this.facilitatorForm.get("Mobile").value,
      email: this.facilitatorForm.get("Email").value,
      address: this.facilitatorForm.get("Address").value,
    };

    this.fileStorageService.UploadMultipleFilesFromDataUrl(this.uploadedImage);
    this.fileStorageService.onUploadFinished.subscribe(
      (resp: CommonResponse<string[]>) => {
        if (resp.responseCode != "00") {
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail: resp.responseMsg,
          });
        } else {
          postData.imageUrl = resp.responseData[0];
          this.employmentService.CreateGMAExtFacilitator(postData).subscribe(
            async () => {
              this.messageService.add({
                severity: "success",
                summary: "Completed",
                detail: "External Facilitator Created Successfully!",
              });

              this.imageUrl = null;
              this.facilitatorForm.reset();
              this.openAddExtFacilDialogue = false;
              this.isCreatingExtFacilitator = false;
              this.fetchingFacilitators = true;
              this.FetchAllGMAFacilitators();
            },
            (error) => {
              console.log("Error: " + JSON.stringify(error));
              this.messageService.add({
                severity: "error",
                summary: "Notice",
                detail:
                  "Unable to create external facilitator at the moment.. Reason: [" +
                  error
                    ? error.error.message
                    : "request failed - permission" + "]",
              });
              this.isCreatingExtFacilitator = false;
            }
          );
        }
      },
      (error) => {
        console.log("Error while uploading files " + error);
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail: "ERR: Unable to upload image to storage",
        });
      }
    );
  }
}
