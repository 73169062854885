import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService, MessageService, SelectItem } from 'primeng/api';
import { Observable } from 'rxjs';
import { BreadcrumbService } from 'src/app/breadcrumb.service';
import { CommonResponse, ServicePricing, ServiceQualification, ServiceRelationshipEnum, Suspect, SuspectQualification } from 'src/app/interfaces/home';
import { ServicePricingService } from 'src/app/services/service-pricing.service';
import { ServicesService } from 'src/app/services/services.service';
import { SuspectQualificationService } from 'src/app/services/suspect-qualification.service';
import { SuspectService } from 'src/app/services/suspect.service';
import { LeadEngagementsService } from '../lead-engagements/lead-engagements.service';

@Component({
  selector: 'app-engagement-lead-qualification',
  templateUrl: './engagement-lead-qualification.component.html',
  styleUrls: ['./engagement-lead-qualification.component.scss']
})
export class EngagementLeadQualificationComponent implements OnInit {
  fetchingSuspects = false;
  newId: any;
  subscriber: any;
  SuspectInstance: any;
  fetchingStartUpData = false;
  startUpData$: Observable<[CommonResponse]>;
  services: any[];
  
  suspects: Suspect[];
  selectedSuspect: Suspect;
  suspectCols: any;

  fetchingSuspectInformation = false;
  suspectInformation: Suspect;
  suspectQualification: SuspectQualification;
  serviceQualifications: ServiceQualification[];

  searchForm: FormGroup;
  challengeForm: FormGroup;
  timingForm: FormGroup;
  budgetForm: FormGroup;
  authorityForm: FormGroup;

  selectedServices: any[];
  dispositions: SelectItem[];
  selectedDisposition: SelectItem;
  valSlider = 1;
  totalBudget = '0';

  suspectQualifications: SuspectQualification[];
  selectedSuspectQualification: SuspectQualification;
  cols: any;

  branchServicePricings: ServicePricing[];
  fetchingServicePricings = false;
  currentSelectedLead: any;

  constructor(
    private suspectService: SuspectService,
    public messageService: MessageService,
    public confirmationService: ConfirmationService,
    private suspectQualificationService: SuspectQualificationService,
    private route: ActivatedRoute,
    public leadEngagementService:LeadEngagementsService,
    private sanitizer: DomSanitizer,
    private formBuilder: FormBuilder,
    private servicesService: ServicesService,
    private servicePricingService: ServicePricingService,
    private router: Router,
    private breadcrumbService: BreadcrumbService
  ) { }

  ngOnInit(): void {
    this.fetchStartupData();
    this.fetchSuspectQualifications();
    this.subscriber = this.route.paramMap.subscribe((params) => {
      this.newId = params.get("id");
      this.getSuspectById(this.newId);
      
    });
    console.log(this.newId);

    this.searchForm = this.formBuilder.group({
      suspect: [null, Validators.required],
      year: [2021, Validators.required],
    })

    this.challengeForm = this.formBuilder.group({
      problem: [null, Validators.required],
      planToAddress: [false, Validators.required],
      plans: [null, Validators.required],
      isPriority: [false, Validators.required], 
      rank: [null], 
      canBeSolved: [false], 
      services: [null], 
      productSuggestion: [null], 
      goal: [null, Validators.required],
      plan: [null, Validators.required],
    })

    this.timingForm = this.formBuilder.group({
      purpose: ['', Validators.required],
    })

    this.budgetForm = this.formBuilder.group({})

    this.authorityForm = this.formBuilder.group({
      decisionMaker: [null],
      influencer: [null],
      gateKeeper: [null],
      owner: [null],
      disposition: [null, Validators.required]
    })

    this.disableAllForms()

    this.dispositions = [
      {label: 'Likes Halogen', value: 1},
      {label: 'Hates Halogen', value: 2},
      {label: 'Indifferent', value: 3}
    ]
  }

  getSuspectById(id) {
    this.leadEngagementService
      .getSuspectById(id)
      .subscribe(async (data: any) => {
        if (data.responseCode == "00") {
          this.SuspectInstance = data.responseData
          console.log("this is leads SuspectInstance", this.SuspectInstance);
          this.messageService.add({
            severity: "success",
            summary: "Notice",
            detail: "All leads were fectched successsfully",
          });
        }
      });
  }

  transform(image) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(image);
  }

  

  private connectionError() {
    this.messageService.add({severity:'error', summary: 'Failed',
      detail:'Connection Error, Please try again'});
  }

  fetchStartupData() { 
    this.fetchingStartUpData = true;
    this.servicesService.allService().subscribe(async (res: any) => {
      this.services = res.responseData;
      console.log("this is service",this.services);
      this.fetchingStartUpData = false;

      this.fetchSuspects();
      this.fetchSuspectQualifications();
    }, error => {
      this.fetchingStartUpData = false;
      this.connectionError()
    })
  }

  fetchSuspects(){
    this.fetchingSuspects = false;
    this.suspectService.allUserSuspects().subscribe(async (res: CommonResponse) => {
      this.suspects = res.responseData;
      this.suspects.forEach(suspect => {
        suspect.name = suspect.businessName ? suspect.businessName : (suspect.firstName + ' ' + suspect.lastName)
      });
    }, error => {
      this.connectionError()
    })
  }

  fetchSuspectQualifications(){
    this.fetchingSuspects = false;
    this.suspectQualificationService.allUserSuspectQualifications().subscribe(async (res: CommonResponse) => {
      this.suspectQualifications = res.responseData;
      this.currentSelectedLead = this.suspectQualifications.filter((el)=>{
        return el.suspect.id == this.newId;
      })
      console.log("Thi is suspectQualifications",this.suspectQualifications);
    }, error => {
      this.connectionError()
    })
  }

  populateChallengeForm(){
    this.valSlider = this.suspectQualification.priority
    this.selectedServices = this.services.filter(x => this.serviceQualifications.some(y => y.serviceId == x.id))
    this.challengeForm.patchValue({
      problem: this.suspectQualification.problemStatement,
      planToAddress: this.suspectQualification.toBeAddressed,
      plans: this.suspectQualification.plans,
      isPriority: this.suspectQualification.isPriority,
      rank: this.suspectQualification.priority,
      canBeSolved: this.suspectQualification.canBeSolved,
      productSuggestion: this.suspectQualification.productSuggestion,
      goal: this.suspectQualification.goal,
      plan: this.suspectQualification.plan
    })
  }

  populateTimingForm(){
    this.timingForm.patchValue({
      purpose: this.suspectQualification.upcomingEvents,
    })

    if(this.serviceQualifications){
      this.serviceQualifications.forEach(serviceQualification => {
        const serviceCode = serviceQualification.service.serviceCode
        const key = 'Date_' + serviceCode
        this.timingForm.get(key).setValue(new Date(serviceQualification.dateToStart))
      });
    }
  }

  populateBudgetForm(){
    if(this.serviceQualifications){
      this.serviceQualifications.forEach(serviceQualification => {
        const serviceCode = serviceQualification.service.serviceCode
        const key1 = 'Qty_' + serviceCode
        const key2 = 'Budget_' + serviceCode
        const key3 = 'Duration_' + serviceCode
        this.budgetForm.get(key1).setValue(serviceQualification.quantityEstimate)
        this.budgetForm.get(key2).setValue(serviceQualification.budget)
        this.budgetForm.get(key3).setValue(serviceQualification.estimatedDurationInMonths)
      });
    }
  }

  populateAuthorityForm(){
    this.selectedDisposition = this.dispositions.find(x => x.value == this.suspectQualification.emotionalDisposition)
    this.authorityForm.patchValue({
      decisionMaker: this.suspectQualification.decisionMakerExists,
      influencer: this.suspectQualification.influencerExists,
      gateKeeper: this.suspectQualification.gateKeeperExists,
      owner: this.suspectQualification.ownersExists,
    })
  }

  resetAllForms(){
    this.challengeForm.reset()
    this.timingForm.reset()
    this.budgetForm.reset()
    this.authorityForm.reset()
    this.valSlider = 0
    this.selectedServices = []
    this.totalBudget = "0"
    this.suspectInformation = null
    this.suspectQualification = null
    this.serviceQualifications = null
  }

  disableAllForms(){
    this.challengeForm.disable()
    this.timingForm.disable()
    this.budgetForm.disable()
    this.authorityForm.disable()
  }

  fetchSuspectInformation(suspect?: any, toRequalify = false){
    console.log('The Suspect ', suspect)
    const suspectId = suspect.id;
    this.resetAllForms()
    this.fetchingSuspectInformation = true
    this.suspectService.getSuspect(suspectId).subscribe(async (r: CommonResponse) => {
      this.SuspectInstance = r.responseData ?? []
      this.fetchingSuspectInformation = false
      this.disableAllForms()
      this.suspectInformation = this.SuspectInstance;

      if(!toRequalify && this.suspectInformation.suspectQualifications && this.suspectInformation.suspectQualifications.length > 0){
        
        this.suspectQualification = this.suspectInformation.suspectQualifications[0];
        
        console.log('Suspect Qualification ', this.suspectQualification)

        if(this.suspectQualification.serviceQualifications 
          && this.suspectQualification.serviceQualifications.length > 0){
          this.serviceQualifications = this.suspectQualification.serviceQualifications;
          
          this.serviceQualifications.forEach(serviceQualification => {
            const serviceCode = serviceQualification.service.serviceCode
            this.timingForm.addControl(`Date_${serviceCode}`, new FormControl(null, [Validators.required]));
            this.budgetForm.addControl(`Qty_${serviceCode}`, new FormControl(0, [Validators.required]));
            this.budgetForm.addControl(`Budget_${serviceCode}`, new FormControl(0, [Validators.required]));        
            this.budgetForm.addControl(`Duration_${serviceCode}`, new FormControl(0, [Validators.required]));        
          })         
        }

        if(this.suspectQualification.challengeCompleted){
          this.populateChallengeForm()
        }else{
          this.challengeForm.enable()
        }

        if(this.suspectQualification.timingCompleted){
          this.populateTimingForm()
          this.timingForm.disable()
        }else{  
          this.timingForm.enable()          
        }

        if(this.suspectQualification.budgetCompleted){
          this.populateBudgetForm()
          this.calculateTotalAnnualBudget()
          this.budgetForm.disable()
        }else{   
          this.fetchingServicePricings = false
          this.servicePricingService
            .getServicePricingsByBranchId(this.SuspectInstance.branchId)
            .subscribe(async (r: CommonResponse) => {
              var pricings = r.responseData ?? []
              this.fetchingServicePricings = false      
              if(pricings && pricings.length > 0){
                this.serviceQualifications.forEach(x => {
                  let servicePricing = pricings.find(pricing => pricing.serviceId == x.serviceId)
                  if(servicePricing) x.service.servicePricing = servicePricing    
                })
              }         
              this.budgetForm.enable()
          }, error => {
            this.fetchingServicePricings = false
            this.connectionError()
          })
        }

        if(this.suspectQualification.authorityCompleted){         
          this.populateAuthorityForm()
        }else{
          this.authorityForm.enable()
        }

      }else{
        this.challengeForm.enable()
      }
    }, error => {
      this.fetchingSuspectInformation = false
      this.connectionError()
    })
  }

  completeChallenge(){

    const formData = this.challengeForm.value;
    if(formData.canBeSolved){
      this.confirmationService.confirm({
        message: `Are you sure you want to complete challange for ${this.SuspectInstance.businessName} ?`,
        accept: () => {
          this._completeChallenge(10);
        }
      });
    }else{
      this.confirmationService.confirm({
        message: `Are you sure you want to complete challange for ${this.SuspectInstance.businessName} ?
                  Kindly note that this will drop the suspect qualification`,
        accept: () => {
          this._completeChallenge(0);
        }
      });
    }    
  }

  _completeChallenge(score){
  
    const formData = this.challengeForm.value;

    for (const serviceId of this.selectedServices) {
      const service = this.services.find(x => x.id == serviceId)
      if(service.hasAdminComponent && this.selectedServices.find(x => x == service.adminServiceId) == null) {
        this.messageService.add({severity: 'error', summary: 'Canceled', detail: `Service ${service.name} need admin component`});
        return;
      }
      
      if(service.hasDirectComponent && this.selectedServices.find(x => x == service.directServiceId) == null) {
        this.messageService.add({severity: 'error', summary: 'Canceled', detail: `Service ${service.name} need direct component`});
        return;
      }
    }

    let serviceQualifications = [];
    if(this.selectedServices && this.selectedServices.length > 0){
      this.selectedServices.forEach(x => {
        serviceQualifications.push({serviceId: x})
      })
    }

    const postData = {
      suspectId: this.SuspectInstance.id,
      problemStatement: formData.problem,
      toBeAddressed: formData.planToAddress || false,
      plans: formData.plans,
      isPriority: formData.isPriority || false,
      priority: formData.rank,
      canBeSolved: formData.canBeSolved || false,
      serviceQualifications,
      productSuggestion: formData.productSuggestion,
      goal: formData.goal,
      plan: formData.plan,
      challengeCompleted: true,
      challengeScore: score,
      isActive: score > 0 
    }

    console.log("This is postedData",postData)

    this.messageService.add({severity:'info', summary: 'Notice', detail:'Saving response..'});
    this.suspectQualificationService.postSuspectQualification(postData).subscribe(async (res:any) => {
      this.messageService.add(
        {severity:'success',  summary: 'Success',  detail: 'Response saved Successfully.'}
      );
      this.challengeForm.disable()
      this.fetchSuspectInformation(this.SuspectInstance)
      this.fetchSuspectQualifications()
    }, error => {
      this.connectionError()
    })
  }

  completeTiming(){
    this.confirmationService.confirm({
      message: `Are you sure you want to complete timing for ${this.SuspectInstance.businessName} ?`,
      accept: () => {
          this._completeTiming();
      }
    });
  }

  _completeTiming(){
    
    let qualificationId = this.suspectQualification.id;
    let suspectQualification = this.suspectQualification;

    const formData = this.timingForm.value;

    let timingScore = 0
    if(this.serviceQualifications){
      this.serviceQualifications.forEach(x => {
        x.dateToStart = formData['Date_' + x.service.serviceCode]
      })

      let earliestDate = this.serviceQualifications.map(x => new Date(x.dateToStart))
        .sort((a, b) => a.getMilliseconds() - b.getMilliseconds())[0]

      if(earliestDate.getFullYear == new Date().getFullYear){
        timingScore = 10
      }
    }

    for(const sQ of this.serviceQualifications) {
      const service = sQ.service;
    }

    suspectQualification.upcomingEvents = formData.purpose
    suspectQualification.serviceQualifications = this.serviceQualifications
    suspectQualification.timingCompleted = true
    suspectQualification.timingScore = timingScore

    console.log(suspectQualification)

    this.messageService.add({severity:'info', summary: 'Notice', detail:'Updating qualification..'}); 
    this.suspectQualificationService.updateSuspectQualification(qualificationId, suspectQualification)
      .subscribe(async (res: any) => {
      this.messageService.add(
        {severity:'success',  summary: 'Success',  detail: 'Qualification updated successfully.'}
      );
      this.timingForm.disable()
      this.fetchSuspectInformation(this.SuspectInstance)
      this.fetchSuspectQualifications()
    }, error => {
      this.connectionError()
    })
  }

  completeBudget(){
    this.confirmationService.confirm({
      message: `Are you sure you want to complete budget for ${this.SuspectInstance?.businessName} ?`,
      accept: () => {
          this._completeBudget();
      }
    });
  }


  getCurrentLeadTable(){
    this.currentSelectedLead = this.suspectQualifications.filter((el)=>{
      return el.suspect.id == this.newId;
    })

     
  }

  _completeBudget(){

    let qualificationId = this.suspectQualification.id;
    let suspectQualification = this.suspectQualification;

    const formData = this.budgetForm.value;

    let oneBudgetCanAccomodate = false
    if(this.serviceQualifications){
      this.serviceQualifications.forEach(x => {
        x.quantityEstimate = formData['Qty_' + x.service.serviceCode]
        x.budget = formData['Budget_' + x.service.serviceCode]
        x.estimatedDurationInMonths = formData['Duration_' + x.service.serviceCode]

        if(!oneBudgetCanAccomodate){
          let canAccomodate = x.budget > (x.quantityEstimate * x.service.unitPrice * 0.8)
          if(canAccomodate) oneBudgetCanAccomodate = true
        }
      })
    }

    for(const sQ of this.serviceQualifications) {
      const service = sQ.service;
      if(service.serviceRelationshipEnum==ServiceRelationshipEnum.Admin){
     
      }

      if(service.serviceRelationshipEnum == ServiceRelationshipEnum.Direct) {
      
      }
    }

    suspectQualification.serviceQualifications = this.serviceQualifications
    suspectQualification.budgetCompleted = true
    suspectQualification.budgetScore = oneBudgetCanAccomodate ? 10 : 0

    console.log(suspectQualification)

    this.messageService.add({severity:'info', summary: 'Notice', detail:'Updating qualification..'}); 
    this.suspectQualificationService.updateSuspectQualification(qualificationId, suspectQualification)
      .subscribe(async (res: any) => {
      this.messageService.add(
        {severity:'success',  summary: 'Success',  detail: 'Qualification updated successfully.'}
      );
      this.budgetForm.disable()
      this.fetchSuspectInformation(this.SuspectInstance)
      this.fetchSuspectQualifications()
    }, error => {
      this.connectionError()
    })
  }

  completeAuthority(){
    this.confirmationService.confirm({
      message: `Are you sure you want to complete authority for ${this.SuspectInstance?.businessName} ?`,
      accept: () => {
          this._completeAuthority();
      }
    });
  }

  _completeAuthority(){
    let qualificationId = this.suspectQualification.id;
    let suspectQualification = this.suspectQualification;

    const formData = this.authorityForm.value;
    let fullScorePass = formData.decisionMaker && 
                        (formData.disposition == 1 || formData.disposition == 3)

    suspectQualification.influencerExists = formData.influencer || false
    suspectQualification.decisionMakerExists = formData.decisionMaker || false
    suspectQualification.gateKeeperExists = formData.gateKeeper || false
    suspectQualification.ownersExists = formData.owner || false
    suspectQualification.emotionalDisposition = formData.disposition
    suspectQualification.authorityCompleted = true
    suspectQualification.authorityScore = fullScorePass ? 10 : 0

    console.log(suspectQualification)

    this.messageService.add({severity:'info', summary: 'Notice', detail:'Updating qualification..'}); 
    this.suspectQualificationService.updateSuspectQualification(qualificationId, suspectQualification)
      .subscribe(async (res: any) => {
      this.messageService.add(
        {severity:'success',  summary: 'Success',  detail: 'Qualification updated successfully.'}
      );
      this.authorityForm.disable()
      this.fetchSuspectInformation(this.SuspectInstance)
      this.fetchSuspectQualifications()
    }, error => {
      this.connectionError()
    })
  }

  qualify(suspect){
    this.fetchSuspectInformation(suspect);
  }

  requalify(suspect, authorityCompleted){
    this.SuspectInstance = this.suspects.find(x => x.id == suspect.id)
    this.fetchSuspectInformation(suspect, authorityCompleted)
  }

  viewMore(suspect){
    this.messageService.add({severity:'info', summary: 'Notice', detail:'Not available yet.'});
  }

  capture(suspect){
    console.log(suspect)
    this.messageService.add({severity:'info', summary: 'Notice', detail:'Converting Suspect.'});
    this.suspectService.convertSuspect(suspect.id).subscribe(async (res: CommonResponse) => {
      if(res.responseCode=='00'){
        this.messageService.add(
          {severity:'success',  summary: 'Success',  detail: 'Suspect Converted Successfully.'}
        );
        var lead = res.responseData;
        this.router.navigate([`/home/lead-admin/capture/${lead}`])
      }else{
        this.messageService.add(
          {severity:'error',  summary: 'Failure',  detail: res.responseMsg}
        );
      }
    }, error => {
      this.connectionError()
    })
  }

  suspectChanged(){
    this.resetAllForms()
    this.disableAllForms()
  }

  calculateTotalAnnualBudget(){
    let total = 0
    const formData = this.budgetForm.value;
    this.serviceQualifications.forEach(x => {
      const serviceTotalBudget = formData['Budget_' + x.service.serviceCode] * formData['Duration_' + x.service.serviceCode]
      total += serviceTotalBudget
    });
    this.totalBudget = total.toString();
  }

}
