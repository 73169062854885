import { ConfirmComplaintComponent } from "./pages/confirm-complaint/confirm-complaint.component";
import { RouterModule } from "@angular/router";
import { NgModule } from "@angular/core";
import { AppMainComponent } from "./app.main.component";
import { AppNotfoundComponent } from "./pages/app.notfound.component";
import { AppErrorComponent } from "./pages/app.error.component";
import { AppAccessdeniedComponent } from "./pages/app.accessdenied.component";
import { AppLoginComponent } from "./pages/app.login.component";
import {
  AngularFireAuthGuard,
  redirectLoggedInTo,
  redirectUnauthorizedTo,
} from "@angular/fire/auth-guard";
import { AuthGuard } from "./guards/auth.guard";
import { GmaJobListingComponent } from "./home/gma/gma-job-listing/gma-job-listing.component";
import { GmaConfirmDistributionComponent } from "./home/gma/gma-confirm-distribution/gma-confirm-distribution.component";
import { JourneyEndFeedbackComponent } from "./home/armada/setup/journey-end-feedback/journey-end-feedback.component";
import { JoEndComponent } from "./home/armada/setup/jo-end/jo-end.component";
import { HalobizLoginComponent } from "./pages/halobiz-login/halobiz-login.component";
import { DisplayQrComponent } from "./pages/display-qr/display-qr.component";

const redirectLoggedInToHome = () => redirectLoggedInTo(["home"]);

const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(["login"]);

@NgModule({
  imports: [
    RouterModule.forRoot(
      [
        {
          path: "",
          component: AppMainComponent,
          children: [
            { path: "", redirectTo: "home", pathMatch: "full" },
            {
              path: "home",
              canActivate: [AuthGuard],
              data: { authGuardPipe: redirectUnauthorizedToLogin },
              loadChildren: () =>
                import("./home/home.module").then((m) => m.HomeModule),
            },
          ],
        },
        { path: "confirm-complaint/:id", component: ConfirmComplaintComponent },
        { path: "display-qr/:qrSrcId", component: DisplayQrComponent },
        {
          path: "gma-confirm-distribution/:id",
          component: GmaConfirmDistributionComponent,
        },
        { path: "job-listing", component: GmaJobListingComponent },
        { path: "error", component: AppErrorComponent },
        { path: "accessdenied", component: AppAccessdeniedComponent },
        { path: "notfound", component: AppNotfoundComponent },
        {
          path: "journey-end-feedback/:id",
          component: JourneyEndFeedbackComponent,
        },
        {
          path: "jo-end",
          component: JoEndComponent,
        },
        {
          path: "login",
          component: HalobizLoginComponent,
          // component: AppLoginComponent,
        },
        { path: "**", redirectTo: "/notfound" },
      ],
      { scrollPositionRestoration: "enabled" }
    ),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
