import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import {
  BankAccount,
  BatchApprovalExpenseBatchRequestActionVM,
  BatchAuthorizationExpenseBatchRequestActionVM,
  CheckBatchComentReadReceiptsVM,
  CreateBatchCommentVM,
  CreateExpenseApprovalSetupVM,
  CreateExpenseAuthorizationSetupVM,
  CreateExpenseBatchRequestItemVM,
  CreateExpenseBatchRequestVM,
  CreateExpenseBudgetVM,
  CreateExpenseLineVM,
  CreateExpenseRequesterAttachmentVM,
  ExpenseApprovalSetup,
  ExpenseAuthorizationSetup,
  ExpenseBatchRequest,
  ExpenseBatchRequestComment,
  ExpenseBatchRequestCommentReceipt,
  ExpenseBatchRequestPayment,
  ExpenseLine,
  ExpenseLineBudget,
  ExpenseLineRequester,
  GenerateExpensePaymentVoucherView,
  GetAllExpenseBatchPendingPaymentsVM,
  GetAllExpenseBudgetInitItemsVM,
  GetAllExpenseBudgetsVM,
  GetExpenseBankPaymentPayablesView,
  GetExpenseBatchAuthorizationSetupsView,
  GetPayablesConfigurationsVM,
  GetPendingBatchApprovalExpenseBatchRequestsView,
  LineManagerExpenseBatchRequestActionVM,
  MoveExpenseApprovalOfficeSequenceVM,
  PostExpenseBatchPayablesVM,
  PostExpenseBatchPaymentsVM,
  PostExpensePaymentsVM,
  SearchExpenseBatchesVM,
  TrackExpenseBatchRequestView,
  UpdateExpenseApprovalSetupVM,
  UpdateExpenseAuthorizationSetupVM,
  UpdateExpenseBatchRequestVM,
  UpdateExpenseLineVM,
  UserExpenseRequestType,
} from "../interfaces/finance-expense";
import { CommonResponse, ControlAccount } from "../interfaces/home";
import { SupplierBank } from "../interfaces/supplier";

@Injectable({
  providedIn: "root",
})
export class FinanceExpenseService {
  baseUrl = environment.baseUrl + "FinanceExpense/";

  constructor(private http: HttpClient) {}

  GetExpenseLineAccounts(): Observable<CommonResponse<ControlAccount[]>> {
    return this.http.get<CommonResponse<ControlAccount[]>>(
      this.baseUrl + "GetExpenseLineAccounts"
    );
  }

  CreateExpenseLine(
    data: CreateExpenseLineVM
  ): Observable<CommonResponse<boolean>> {
    return this.http.post<CommonResponse<boolean>>(
      this.baseUrl + "CreateExpenseLine",
      data
    );
  }

  GetAllExpenseLines(): Observable<CommonResponse<ExpenseLine[]>> {
    return this.http.get<CommonResponse<ExpenseLine[]>>(
      this.baseUrl + "GetAllExpenseLines"
    );
  }

  UpdateExpenseLine(
    id: number,
    data: UpdateExpenseLineVM
  ): Observable<CommonResponse<boolean>> {
    return this.http.put<CommonResponse<boolean>>(
      this.baseUrl + "UpdateExpenseLine/" + id,
      data
    );
  }

  DeleteExpenseLine(id: number): Observable<CommonResponse<boolean>> {
    return this.http.delete<CommonResponse<boolean>>(
      this.baseUrl + "DeleteExpenseLine/" + id
    );
  }

  GetExpenseRequesterAttachments(): Observable<
    CommonResponse<ExpenseLineRequester[]>
  > {
    return this.http.get<CommonResponse<ExpenseLineRequester[]>>(
      this.baseUrl + "GetExpenseRequesterAttachments"
    );
  }

  CreateExpenseRequesterAttachment(
    data: CreateExpenseRequesterAttachmentVM
  ): Observable<CommonResponse<boolean>> {
    return this.http.post<CommonResponse<boolean>>(
      this.baseUrl + "CreateExpenseRequesterAttachment",
      data
    );
  }

  DeleteExpenseRequesterAttachment(
    id: number
  ): Observable<CommonResponse<boolean>> {
    return this.http.delete<CommonResponse<boolean>>(
      this.baseUrl + "DeleteExpenseRequesterAttachment/" + id
    );
  }

  GetAllExpenseApprovalSetups(): Observable<
    CommonResponse<ExpenseApprovalSetup[]>
  > {
    return this.http.get<CommonResponse<ExpenseApprovalSetup[]>>(
      this.baseUrl + "GetAllExpenseApprovalSetups"
    );
  }

  CreateExpenseApprovalSetup(
    data: CreateExpenseApprovalSetupVM
  ): Observable<CommonResponse<boolean>> {
    return this.http.post<CommonResponse<boolean>>(
      this.baseUrl + "CreateExpenseApprovalSetup",
      data
    );
  }

  UpdateExpenseApprovalSetup(
    id: number,
    data: UpdateExpenseApprovalSetupVM
  ): Observable<CommonResponse<boolean>> {
    return this.http.put<CommonResponse<boolean>>(
      this.baseUrl + "UpdateExpenseApprovalSetup/" + id,
      data
    );
  }

  MoveExpenseApprovalOfficeSequence(
    id: number,
    data: MoveExpenseApprovalOfficeSequenceVM
  ): Observable<CommonResponse<boolean>> {
    return this.http.put<CommonResponse<boolean>>(
      this.baseUrl + "MoveExpenseApprovalOfficeSequence/" + id,
      data
    );
  }

  DeleteExpenseApprovalSetup(id: number): Observable<CommonResponse<boolean>> {
    return this.http.delete<CommonResponse<boolean>>(
      this.baseUrl + "DeleteExpenseApprovalSetup/" + id
    );
  }

  GetAllExpenseBudgetInitItems(): Observable<
    CommonResponse<GetAllExpenseBudgetInitItemsVM>
  > {
    return this.http.get<CommonResponse<GetAllExpenseBudgetInitItemsVM>>(
      this.baseUrl + "GetAllExpenseBudgetInitItems"
    );
  }

  GetAllExpenseBudgets(
    data: GetAllExpenseBudgetsVM
  ): Observable<CommonResponse<ExpenseLineBudget[]>> {
    return this.http.post<CommonResponse<ExpenseLineBudget[]>>(
      this.baseUrl + "GetAllExpenseBudgets",
      data
    );
  }

  CreateExpenseBudget(
    data: CreateExpenseBudgetVM
  ): Observable<CommonResponse<boolean>> {
    return this.http.post<CommonResponse<boolean>>(
      this.baseUrl + "CreateExpenseBudget",
      data
    );
  }

  GetUserExpenseBatchRequests(): Observable<
    CommonResponse<ExpenseBatchRequest[]>
  > {
    return this.http.get<CommonResponse<ExpenseBatchRequest[]>>(
      this.baseUrl + "GetUserExpenseBatchRequests"
    );
  }

  CreateExpenseBatchRequest(
    data: CreateExpenseBatchRequestVM
  ): Observable<CommonResponse<boolean>> {
    return this.http.post<CommonResponse<boolean>>(
      this.baseUrl + "CreateExpenseBatchRequest",
      data
    );
  }

  CreateExpenseBatchRequestItem(
    data: CreateExpenseBatchRequestItemVM
  ): Observable<CommonResponse<boolean>> {
    return this.http.post<CommonResponse<boolean>>(
      this.baseUrl + "CreateExpenseBatchRequestItem",
      data
    );
  }

  UpdateExpenseBatchRequest(
    id: number,
    data: UpdateExpenseBatchRequestVM
  ): Observable<CommonResponse<boolean>> {
    return this.http.put<CommonResponse<boolean>>(
      this.baseUrl + "UpdateExpenseBatchRequest/" + id,
      data
    );
  }

  DeleteExpenseBatchRequest(id: number): Observable<CommonResponse<boolean>> {
    return this.http.delete<CommonResponse<boolean>>(
      this.baseUrl + "DeleteExpenseBatchRequest/" + id
    );
  }

  DeleteExpenseBatchRequestItem(
    id: number
  ): Observable<CommonResponse<boolean>> {
    return this.http.delete<CommonResponse<boolean>>(
      this.baseUrl + "DeleteExpenseBatchRequestItem/" + id
    );
  }

  SendUserExpenseBatchRequestForApproval(
    id: number
  ): Observable<CommonResponse<boolean>> {
    return this.http.get<CommonResponse<boolean>>(
      this.baseUrl + "SendUserExpenseBatchRequestForApproval/" + id
    );
  }

  GetAllActiveExpenseBudgets(): Observable<
    CommonResponse<ExpenseLineBudget[]>
  > {
    return this.http.get<CommonResponse<ExpenseLineBudget[]>>(
      this.baseUrl + "GetAllActiveExpenseBudgets"
    );
  }

  GetPendingLineApprovalExpenseBatchRequests(): Observable<
    CommonResponse<ExpenseBatchRequest[]>
  > {
    return this.http.get<CommonResponse<ExpenseBatchRequest[]>>(
      this.baseUrl + "GetPendingLineApprovalExpenseBatchRequests"
    );
  }

  LineManagerExpenseBatchRequestAction(
    data: LineManagerExpenseBatchRequestActionVM
  ): Observable<CommonResponse<boolean>> {
    return this.http.post<CommonResponse<boolean>>(
      this.baseUrl + "LineManagerExpenseBatchRequestAction",
      data
    );
  }

  GetExpenseBatchComments(
    batchId: number
  ): Observable<CommonResponse<ExpenseBatchRequestComment[]>> {
    return this.http.get<CommonResponse<ExpenseBatchRequestComment[]>>(
      this.baseUrl + "GetExpenseBatchComments/" + batchId
    );
  }

  CreateBatchComment(
    data: CreateBatchCommentVM
  ): Observable<CommonResponse<boolean>> {
    return this.http.post<CommonResponse<boolean>>(
      this.baseUrl + "CreateBatchComment",
      data
    );
  }

  DeleteBatchComment(id: number): Observable<CommonResponse<boolean>> {
    return this.http.delete<CommonResponse<boolean>>(
      this.baseUrl + "DeleteBatchComment/" + id
    );
  }

  CreateBatchCommentReceipt(
    batchCommentId: number
  ): Observable<CommonResponse<boolean>> {
    return this.http.get<CommonResponse<boolean>>(
      this.baseUrl + "CreateBatchCommentReceipt/" + batchCommentId
    );
  }

  GetUserBatchCommentReceipts(): Observable<
    CommonResponse<ExpenseBatchRequestCommentReceipt[]>
  > {
    return this.http.get<CommonResponse<ExpenseBatchRequestCommentReceipt[]>>(
      this.baseUrl + "GetUserBatchCommentReceipts"
    );
  }

  CheckBatchComentReadReceipts(
    data: CheckBatchComentReadReceiptsVM
  ): Observable<CommonResponse<number[]>> {
    return this.http.post<CommonResponse<number[]>>(
      this.baseUrl + "CheckBatchComentReadReceipts",
      data
    );
  }

  GetPendingBatchApprovalExpenseBatchRequests(): Observable<
    CommonResponse<GetPendingBatchApprovalExpenseBatchRequestsView>
  > {
    return this.http.get<
      CommonResponse<GetPendingBatchApprovalExpenseBatchRequestsView>
    >(this.baseUrl + "GetPendingBatchApprovalExpenseBatchRequests");
  }

  PickExpenseBatchApproval(
    batchId: number
  ): Observable<CommonResponse<boolean>> {
    return this.http.get<CommonResponse<boolean>>(
      this.baseUrl + "PickExpenseBatchApproval/" + batchId
    );
  }

  BatchApprovalExpenseBatchRequestAction(
    data: BatchApprovalExpenseBatchRequestActionVM
  ): Observable<CommonResponse<boolean>> {
    return this.http.post<CommonResponse<boolean>>(
      this.baseUrl + "BatchApprovalExpenseBatchRequestAction",
      data
    );
  }

  GetAllExpenseAuthorizationSetups(): Observable<
    CommonResponse<ExpenseAuthorizationSetup[]>
  > {
    return this.http.get<CommonResponse<ExpenseAuthorizationSetup[]>>(
      this.baseUrl + "GetAllExpenseAuthorizationSetups"
    );
  }

  CreateExpenseAuthorizationSetup(
    data: CreateExpenseAuthorizationSetupVM
  ): Observable<CommonResponse<boolean>> {
    return this.http.post<CommonResponse<boolean>>(
      this.baseUrl + "CreateExpenseAuthorizationSetup",
      data
    );
  }

  UpdateExpenseAuthorizationSetup(
    id: number,
    data: UpdateExpenseAuthorizationSetupVM
  ): Observable<CommonResponse<boolean>> {
    return this.http.put<CommonResponse<boolean>>(
      this.baseUrl + "UpdateExpenseAuthorizationSetup/" + id,
      data
    );
  }

  DeleteExpenseAuthorizationSetup(
    id: number
  ): Observable<CommonResponse<boolean>> {
    return this.http.delete<CommonResponse<boolean>>(
      this.baseUrl + "DeleteExpenseAuthorizationSetup/" + id
    );
  }

  GetPendingBatchAuthorizationExpenseBatchRequests(): Observable<
    CommonResponse<ExpenseBatchRequest[]>
  > {
    return this.http.get<CommonResponse<ExpenseBatchRequest[]>>(
      this.baseUrl + "GetPendingBatchAuthorizationExpenseBatchRequests"
    );
  }

  GetExpenseBatchAuthorizationSetups(
    batchId: number
  ): Observable<CommonResponse<GetExpenseBatchAuthorizationSetupsView[]>> {
    return this.http.get<
      CommonResponse<GetExpenseBatchAuthorizationSetupsView[]>
    >(this.baseUrl + "GetExpenseBatchAuthorizationSetups/" + batchId);
  }

  BatchAuthorizationExpenseBatchRequestAction(
    data: BatchAuthorizationExpenseBatchRequestActionVM
  ): Observable<CommonResponse<boolean>> {
    return this.http.post<CommonResponse<boolean>>(
      this.baseUrl + "BatchAuthorizationExpenseBatchRequestAction",
      data
    );
  }

  GetAllExpenseBatchPendingPayables(): Observable<
    CommonResponse<ExpenseBatchRequest[]>
  > {
    return this.http.get<CommonResponse<ExpenseBatchRequest[]>>(
      this.baseUrl + "GetAllExpenseBatchPendingPayables"
    );
  }

  GetPayablesConfigurations(): Observable<
    CommonResponse<GetPayablesConfigurationsVM>
  > {
    return this.http.get<CommonResponse<GetPayablesConfigurationsVM>>(
      this.baseUrl + "GetPayablesConfigurations"
    );
  }

  PostExpenseBatchPayables(
    data: PostExpenseBatchPayablesVM
  ): Observable<CommonResponse<boolean>> {
    return this.http.post<CommonResponse<boolean>>(
      this.baseUrl + "PostExpenseBatchPayables",
      data
    );
  }

  GetAllExpenseBatchPendingPayments(): Observable<
    CommonResponse<GetAllExpenseBatchPendingPaymentsVM>
  > {
    return this.http.get<CommonResponse<GetAllExpenseBatchPendingPaymentsVM>>(
      this.baseUrl + "GetAllExpenseBatchPendingPayments"
    );
  }

  PostExpenseBatchPayments(
    data: PostExpenseBatchPaymentsVM
  ): Observable<CommonResponse<boolean>> {
    return this.http.post<CommonResponse<boolean>>(
      this.baseUrl + "PostExpenseBatchPayments",
      data
    );
  }

  GetAllAuthorizedExpenseBatches(): Observable<
    CommonResponse<ExpenseBatchRequest[]>
  > {
    return this.http.get<CommonResponse<ExpenseBatchRequest[]>>(
      this.baseUrl + "GetAllAuthorizedExpenseBatches"
    );
  }

  GetExpenseBatchPayments(
    batchId: number
  ): Observable<CommonResponse<ExpenseBatchRequestPayment[]>> {
    return this.http.get<CommonResponse<ExpenseBatchRequestPayment[]>>(
      this.baseUrl + "GetExpenseBatchPayments/" + batchId
    );
  }

  GetExpenseBatchPaymentEntityBanks(
    entityType: UserExpenseRequestType,
    entityId: number
  ): Observable<CommonResponse<BankAccount[]>> {
    return this.http.get<CommonResponse<BankAccount[]>>(
      this.baseUrl +
        "GetExpenseBatchPaymentEntityBanks/" +
        entityType +
        "/" +
        entityId
    );
  }

  SearchExpenseBatches(
    data: SearchExpenseBatchesVM
  ): Observable<CommonResponse<ExpenseBatchRequest[]>> {
    return this.http.post<CommonResponse<ExpenseBatchRequest[]>>(
      this.baseUrl + "SearchExpenseBatches",
      data
    );
  }

  TrackExpenseBatchRequest(
    batchId: number
  ): Observable<CommonResponse<TrackExpenseBatchRequestView[]>> {
    return this.http.get<CommonResponse<TrackExpenseBatchRequestView[]>>(
      this.baseUrl + "TrackExpenseBatchRequest/" + batchId
    );
  }

  GetExpenseBankPaymentPayables(): Observable<
    CommonResponse<GetExpenseBankPaymentPayablesView>
  > {
    return this.http.get<CommonResponse<GetExpenseBankPaymentPayablesView>>(
      this.baseUrl + "GetExpenseBankPaymentPayables"
    );
  }

  GetAllSupplierBanks(): Observable<CommonResponse<SupplierBank[]>> {
    return this.http.get<CommonResponse<SupplierBank[]>>(
      this.baseUrl + "GetAllSupplierBanks"
    );
  }

  GetAllBankAccouts(): Observable<CommonResponse<BankAccount[]>> {
    return this.http.get<CommonResponse<BankAccount[]>>(
      this.baseUrl + "GetAllBankAccouts"
    );
  }

  PostExpensePayments(
    data: PostExpensePaymentsVM
  ): Observable<CommonResponse<boolean>> {
    return this.http.post<CommonResponse<boolean>>(
      this.baseUrl + "PostExpensePayments",
      data
    );
  }

  GenerateExpensePaymentVoucher(
    paymentId: number
  ): Observable<CommonResponse<GenerateExpensePaymentVoucherView>> {
    return this.http.get<CommonResponse<GenerateExpensePaymentVoucherView>>(
      this.baseUrl + "GenerateExpensePaymentVoucher/" + paymentId
    );
  }

  ConfirmPaymentChequePrinted(
    paymentId: number
  ): Observable<CommonResponse<boolean>> {
    return this.http.get<CommonResponse<boolean>>(
      this.baseUrl + "ConfirmPaymentChequePrinted/" + paymentId
    );
  }
}
