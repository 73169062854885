import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormArray, FormGroup, FormsModule } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {  ConfirmationService, Message, MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { Observable, Subscription, of } from 'rxjs';
import { BreadcrumbService } from 'src/app/breadcrumb.service';
import { BudgetapiservicesService } from 'src/app/services/budget/budgetapiservices.service';
import { ConfirmEventType } from 'src/app/interfaces/armada';

interface Region {
  regionId: number,
  name: string,
  headId: number
}

interface AllocationType {
  id: number,
  name: string
}

@Component({
  selector: 'app-region',
  templateUrl: './region.component.html',
  styleUrls: ['./region.component.scss']
})
export class RegionComponent implements OnInit, OnDestroy {
  msgs: Message[] | undefined
  evenlyDistributeMsgs: Message[] | undefined
  budgetDistributionForm!: FormGroup;
  budgetCycleForm = this.fb.group({
    budgetCycle: [''],
    percentageValue: [{value: '', disabled: false}, Validators.required],
    valueToResolve: [{value: '', disabled: false}, Validators.required],
    // region: ['', Validators.required],
    allocationType: ['', Validators.required],
    distributionSetting: ['', Validators.required],
    totalValue: ['', Validators.required],
    comment: ['']
  })
  
  distributionChoice: any[] = [
    { label: 'Evenly Distribute', value: 'even'},
    { label: 'Manually Enter', value: 'man'}
  ];
  loadingData = true;
  budgetCycleLists: any[];
  budgetCycleLists$: Observable<any[]>;
  budgetByRegions: any[] | undefined;

  regionName ="";
  allocationType: any;
  percentageValue = "";
  isPercentage: boolean | undefined;
  resolvedValue: number;
  totalValue: number;
  benchMarkValue: number;
  calculatedTotalValue: number =0;
  choice: boolean=true;
  findSum = 0;
  disableSendForReview: boolean = true;
  disableSaveButton: boolean = false;
  disableDeleteButton: boolean = false;
  disableSelectAllocationType = false;
  disableReloadButton: boolean = true;
  disableEvenlyDistribute: boolean = false;
  isNewRecord: boolean = false;
  isExistingRecord: boolean = false;
  isEvenlyDistributeOnly:boolean = false;
  budgetCycleTotalNumber: number;
  budgetCycleTotalNumberCurrentYear: number;
  totalAllocatatedValue: number;
  totalUnallocatatedValue: number;
  isClearAll: boolean = false;
  itemComment = '';
  isRecordSentForReview: boolean = false;
  isRecordApproved: boolean = false;
  isRecordReturned: boolean = false;
  returnedBy: string = "";
  returnedComment: string = "";
  returnedBudgetDetail: any[];
  isEditingRecord: boolean = false;
  budgetCaption: string | undefined;

  regions: Region[];
  selectedRegions: [] | undefined;
  budgetDist: any[]=[];
  selectedBudget: any;
  budgetCycleSubscription: Subscription | undefined
  allocationTypeSubscription: Subscription | undefined
  

  allocationTypes: AllocationType[]
  selectedAllocationType: AllocationType

  constructor(private fb: FormBuilder, 
              private apiService: BudgetapiservicesService,
              private messageService: MessageService,
              private confirmationService: ConfirmationService,
              public dialogService: DialogService,
              private router: Router,
              private route: ActivatedRoute,
              private breadcrumbService: BreadcrumbService) { 
                // this.budgetCycleLists$ = this.apiService.getBudgetCycleList();
                // console.log(this.budgetCycleLists$)
              }

  
  ngOnInit(): void {
    
    this.budgetCycleSubscription = this.loadBudgetCycles();
    
    // this.loadRegions();
    this.allocationTypeSubscription = this.loadBudgetAllocationTypes();
    this.evenlyDistributeMsgs = [{
      severity: 'info',
      summary: 'Information',
      detail: 'Where evenly distribute applies and manually entered by percentage, it is based on benchmark value of budget cycle'
    }];
  }

  ngOnDestroy(): void {
    this.budgetCycleSubscription.unsubscribe();
    this.allocationTypeSubscription.unsubscribe();
  }
  

  displayMessage(severity, summary, message) {
    this.messageService.add({
      key: 'sideNotification',
      severity: severity,
      summary: summary,
      detail: message,
    });
  }

  loadRegions() {
    let param = "Region"
    return this.apiService.getBudgetCatory(param).subscribe( res => {
      this.regions = res['responseData'].budgetDistCatList;
      console.log(`Regions: ${this.regions}`);
    }); 
  }

  loadBudgetAllocationTypes() {
    return this.apiService.getBudgetAllocationTypes().subscribe( res => {
      this.allocationTypes = res['responseData'].allocationTypes;
      console.log(`Regions: ${this.allocationTypes}`);
    }) 
  }


  loadBudgetCycles() {
    
    return this.apiService.getBudgetCycleList().subscribe( res => {
      this.budgetCycleLists = res['responseData'];
      // this.budgetCycleLists$ = of(this.budgetCycleLists);
      // console.log(this.budgetCycleLists$);
      
      this.budgetCycleTotalNumber = res['responseData'].length;  //this.budgetCycleLists.length;
      this.budgetCycleTotalNumberCurrentYear = res['responseData'].filter(e=> Number(e.budgetYear)== new Date().getFullYear()).length
      // this.budgetCycleTotalNumberCurrentYear = this.budgetCycleLists.filter(e=> Number(e.budgetYear)== new Date().getFullYear()).length
      this.budgetCycleLists.forEach((elem, ind) => {
        if (new Date().valueOf() > new Date(elem.endDate).valueOf()){
          this.budgetCycleLists.splice(ind, 1); // (elem)
        }
      })
      this.loadingData = false;
      console.log(typeof(res['responseData']))
      // console.log(`Response from loading Budget cycle: ${JSON.stringify(res['responseData'][0])}`);
      console.log(this.budgetCycleLists)
    }) 
  }

  getDateOnly(value: any) {
    if ( value == "")
      return new Date(); // value
    let date = [new Date(value).getMonth()+1, new Date(value).getDate(), new Date(value).getFullYear()].join('/');
    console.log(date);
    // this.endDate = 
    return date;
  }
  onSelectedBudgetCycleChange(e: any) {
    // this.isEvenlyDistributeOnly = false;
    console.log(e);
    console.log(`Start Date: ${e.startDate} and converted Date: ${this.getDateOnly(e.startDate)} and ${new Date(e.startDate)}`);
    console.log(e.endDate);
    let today = new Date();
    if (today.valueOf() < new Date(e.budgetSeason.startDate).valueOf())
    {
      console.log("Season has not started");
      this.displayMessage("info", "Information", "The budget preparatory start date has not commenced.")
      return;
    }
    else if (today.valueOf() > new Date(e.budgetSeason.endDate).valueOf())
    {
      console.log("Season has closed");
      this.displayMessage("info", "Information", "The budget preparatory end date has elapsed.")
      return;
    }
    else
    {
      let currentYear = String(today.getFullYear());
      let currentMonth = String(today.getMonth()+1).padStart(2,"0");
      console.log()
      let currentDay = String(today.getDate()+1).padStart(2,"0");
      console.log(new Date(`${currentYear}-${currentMonth}-${currentDay}`))
      
        this.budgetCaption = e.caption;
        this.msgs=[];
    // this.evenlyDistributeMsgs=[];
    // console.log(this.selectedBudget)
        if (this.budgetCycleForm.get('budgetCycle').value['benchMarkValue']) {
          this.benchMarkValue = this.budgetCycleForm.get('budgetCycle').value['benchMarkValue']
          console.log(this.benchMarkValue)
        }
    
        this.isPercentage = undefined;
        this.loadRegionBudget();
        this.disableReloadButton = false;
    }
    
    
  }

  onSelectedAllocationChange(e: any) {
    // this.budgetByRegions.forEach(el => {
    //   if (this.budgetByRegions.indexOf(item))
    // })
    // if (this.isEvenlyDistributeOnly){
    //   this.displayMessage("warn", "Warning", "You can only distribute evenly")
    //   return false;
    // }
    console.log(e)
    console.log(typeof(e))
    if (e) { //!=='' || e !== undefined
      console.log(`The valie of e is ${e}`)
      this.isPercentage = e.id === 1 ? true : false;
      console.log(this.isPercentage)
    }
    else {
      this.isPercentage = undefined;
      console.log(this.isPercentage)
    }
    
    
    console.log(this.budgetCycleForm.get('allocationType').value)
    // console.log(e)
    
    // console.log(e[1])
  }

  onSettingsChange(e: any) {
    console.log(this.budgetByRegions)
    console.log(`Percentage: ${this.isPercentage}`)
    if (e.checked){
      this.isEvenlyDistributeOnly = false;
      console.log(this.choice);
      // if (this.isEvenlyDistributeOnly) {
      //     this.displayMessage("warn", "Warning", "You can only distribute evenly")
      //     return false;
      // }
      this.disableSelectAllocationType = false;
      
      this.calculatedTotalValue = 0;
      // this.percentageValue=''
      
      this.budgetByRegions.forEach(element => {
        if (element.isAlreadySaved  && !this.isClearAll) {
          // this.budgetCycleForm.get('allocationType').setValue(e.allocationType);
          this.budgetCycleForm.get('percentageValue').setValue(element.percentageOfBenchMarkValue);
          this.budgetCycleForm.get('valueToResolve').setValue(element.resolvedValue);
          this.budgetCycleForm.get('totalValue').setValue(element.totalValue);
          this.calculatedTotalValue += element.totalValue;
          // element.percentageOfBenchMarkValue = 0;
          // element.resolvedValue = '';
        }
        else {
          element.percentageOfBenchMarkValue = 0;
          element.resolvedValue = '';
          // element.allocationName = null
          this.resolvedValue = 0;
          element.totalValue = 0;
          element.isPercentage = undefined;
          // element.allocationType = undefined;
          this.allocationType = '';
          this.budgetCycleForm.get('allocationType').setValue('');
          this.budgetCycleForm.get('percentageValue').setValue('');
          this.budgetCycleForm.get('valueToResolve').setValue('');
          this.budgetCycleForm.get('totalValue').setValue('');
          this.isPercentage = undefined;
          this.calculatedTotalValue = 0;
        }
      });
      return false;
    }
    else {
      this.disableSelectAllocationType = true;
      this.calculatedTotalValue = 0;
      this.isEvenlyDistributeOnly = true;
      this.evenlyDistributeBudget();
      this.allocationType=="Percentage";
      this.budgetCycleForm.get('allocationType').setValue('Percentage');
      // this.budgetCycleForm.get('percentageValue').setValue('');
      this.budgetByRegions.forEach(element => {
        element.isPercentage = true; 
        element.allocationName = 'Percentage'
        element.allocationType = 1;
        // element.allocationType = element.allocationName=='Percentage' ? 1 : 2
      });
      console.log(this.choice);
    }
    // this.budgetCycleForm.get('allocationType').setValue(this.allocationType[0]);
    // this.allocationType = this.allocationTypes[0]
    console.log(this.budgetByRegions.length);
    console.log(this.budgetByRegions);
    console.log(this.budgetCycleForm.value);
  }


  evenlyDistributeBudget() {
    let regions: number = this.budgetByRegions.length;
    
    const PERCENTAGE = 100;
    let amountPerRegion: number = this.benchMarkValue / regions;
    this.budgetByRegions.forEach(element => {
      element.percentageOfBenchMarkValue = PERCENTAGE / regions;
      element.resolvedValue = amountPerRegion;
      element.totalValue = element.resolvedValue
      element.budgetCycleId = this.selectedBudget.id,
      element.allocationTypeId = 1;
      this.resolvedValue = amountPerRegion;
      this.budgetCycleForm.get('percentageValue').setValue(element.percentageOfBenchMarkValue);
      this.budgetCycleForm.get('valueToResolve').setValue(element.resolvedValue);
      this.budgetCycleForm.get('totalValue').setValue(element.totalValue);
      this.calculatedTotalValue += element.totalValue;
    });

  }


  loadRegionBudget() {
    this.loadingData = true;
    if (this.budgetByRegions)
        this.budgetByRegions=[]; // this.clearAll();
    
        this.calculatedTotalValue = 0
        this.calculatedTotalValue = 0
        this.isRecordApproved = false;
        this.isEditingRecord = false;
        this.isExistingRecord = false;
        this.disableSaveButton = false;
        this.disableSelectAllocationType = false;
        this.disableEvenlyDistribute = false;
        this.isRecordSentForReview = false;
    // let budgetDist: any[]=[];
    // if (!this.selectedRegions || this.selectedRegions.length === 0){
    //   this.displayMessage("warn","Warning","You must select at least one region");
    //   return false;
    // }
    // this.selectedRegions.bu
    // this.selectedRegions.forEach(e => {
    //   this.budgetDist.push(e);
    // })
    let params = {
      "WhereTo": "Region",
      "budgetCycleId": this.selectedBudget.id
    };

    console.log(`Selected Budget: ${this.selectedBudget.id}`)
    // this.loadingData = false;
    // return;
    
    let totalValueSave: number = 0;
    setTimeout(() => {
      this.displayMessage('info', "Information", "Processing request");
    }, 1000);
    // return false
    this.apiService.getBudgetDistribution(params).subscribe((res) => {
      var data = res['responseData'];
      this.budgetByRegions = data;
      this.disableSendForReview = true;

      this.budgetByRegions.forEach((e) => {
        e.percentageValue = 0
        e.isAlreadySaved = false;
        // if (e.budgetSeason.startDate >  Date.now())
        // console.log(e.budgetSeason.startDate)
    
        if (e.allocationName && e.totalValue != 0) {
          this.budgetCycleForm.get('allocationType').setValue(e.allocationName);
          e.isAlreadySaved = true;
          this.isExistingRecord = true;
          this.isRecordSentForReview = e.isSentForReview;
          this.isRecordApproved = e.isApproved;
          this.isRecordReturned = e.isReturned;
          this.returnedBudgetDetail = e.returnedBudget;
          this.disableSendForReview = e.isSentForReview;
          this.calculatedTotalValue +=e.totalValue;
        }
        else {
          this.isNewRecord = true;
          // this.isExistingRecord = true;
          // this.isRecordSentForReview = e.isSentForReview;
          // this.disableSendForReview = e.isSentForReview;
          // this.calculatedTotalValue +=e.totalValue;
        }
        // if (e.isSentForReview) {
        //   this.isRecordSentForReview = e.isSentForReview;
        //   this.disableSendForReview = e.isSentForReview;
        // }
        
      });
      
      // this.budgetDist=[];
      console.log(data)
      console.log(this.choice);
      this.budgetDist = [];

      setTimeout(() => {
        this.displayMessage('success', "Information", "Record loaded successfully");
        console.log(this.budgetCycleForm.value);
        console.log(this.budgetCycleForm.get('docType'));
        this.loadingData = false;
      }, 1000);

      if (this.isRecordApproved){
        console.log('Record has been fully approved')            
        this.msgs = [{
          severity: 'success',
          summary: 'Information',
          detail: 'Record has been fully approved',
        }]
      }
      else if (this.isRecordSentForReview){
        console.log('Record has been sent for review')            
        this.msgs = [{
          severity: 'info',
          summary: 'Information',
          detail: 'Record has been sent for review',
        }]
      }
      else if (this.isRecordReturned) {
        console.log(`Record returned:`)
        console.log(this.returnedBudgetDetail)            
        this.msgs = [{
          severity: 'error',
          summary: `Returned By ${this.returnedBudgetDetail['returnedByName']} from ${this.returnedBudgetDetail['department']} office: ${this.returnedBudgetDetail['officeDetail']} `,
          detail: this.returnedBudgetDetail['comment'],
        }]
      }
      else {
        this.msgs=[];
      }

      if (this.isExistingRecord){
        console.log(`Existing Record ${this.isExistingRecord}`)
        this.disableEvenlyDistribute = true;
        this.disableSelectAllocationType = true;
        this.disableSaveButton = true;
      }

      // if ( this.isNewRecord && this.isExistingRecord && this.calculatedTotalValue == this.benchMarkValue) {
      //   this.isEvenlyDistributeOnly = true;
      //   this.confirmationService.confirm({
      //     message: `There are unallocated new regions. You can only distibute evenly `,
      //     header: 'Confirmation',
      //     icon: 'pi pi-exclamation-triangle',
      //     accept: () => {
      //       // this.messageService.add({ severity: 'info', summary: 'Confirmed', detail: 'You have accepted' });
      //       this.displayMessage("info", "Information", "Processing operation")
      //     //  this.evenlyDistributeBudget();
           
      //     }
          
      //   });
      // }
    },
    (error) => {
      console.log("Error: " + JSON.stringify(error.error));
      this.displayMessage("error","Error","Error loading record");
      this.loadingData = false;
    });
  }

  calculatePercentage(e: any) {
    if (this.choice != false)
    {
      if (!this.isClearAll) {
        if(this.isPercentage == undefined )
        {
          this.displayMessage("warn", "Warning", "Select allocation type"); // from Percentage
          return false;
        }
        if (this.isPercentage === false) {
          this.displayMessage("warn", "Warning", "You can only enter value");
          return false;
        }

        let percentageTotal=0;
        let valueDist = 0
      
       
        // if (this.isEvenlyDistributeOnly){
        //   this.displayMessage("warn", "Warning", "You can only distribute evenly")
        //   this.budgetCycleForm.controls['docType'].disable();
        //   return false;
        // }
  
        this.budgetByRegions.forEach(elem => {
          if (elem.percentageOfBenchMarkValue) 
          {
            percentageTotal += elem.percentageOfBenchMarkValue;
          }
        });
  
        // if (e[1].totalValue !==0 && e[1].isAlreadySaved == true )
        // {
        //   this.displayMessage("warn","Warning","You can't edit this record as it has already been save")
        //   return false;
        // }
        // else {
          
          console.log(e)
          console.log(this.benchMarkValue);
          e[1].percentageValue= e[0];
        // }
        
        // e[1].totalValue=Number(e[0]);
        
        
        
        this.budgetByRegions.forEach(elem => {
          // if (elem.resolvedValue === 0 || elem.resolvedValue === '' || elem.resolvedValue== undefined || isNaN(elem.resolvedValue)) {
            console.log(elem.percentageValue)
            elem.resolvedValue = elem.percentageValue/100 *  this.benchMarkValue;
            elem.totalValue = elem.resolvedValue;
            this.resolvedValue = elem.resolvedValue;
            percentageTotal += elem.percentageValue;
            valueDist += elem.totalValue;
            // elem.allocationTypeId = this.isPercentage == true ? 1 : 2;
            // elem.allocationName = this.isPercentage == true ? "Percent" : "Manual"
            
          // }
          // else {
          //   percentageTotal += elem.percentageOfBenchMarkValue;
          // }
            console.log(percentageTotal)
            if (percentageTotal > 100) {
              console.log('Percent total cannot be greater than 100');
              this.displayMessage("error","Error","Percent total cannot be greater than 100");
              elem.resolvedValue = 0;
              elem.totalValue = 0;
              e[1].percentageValue = 0
              e[0] = ''
              e[0] = 0
              this.percentageValue = ''
              // elem.percentageValue = 0;
              // elem.percentageOfBenchMarkValue = undefined;
              // console.log(document.querySelector('#percentageValue').textContent)
              // document.querySelector('#percentageValue').textContent = ''
              // this.budgetCycleForm.get('percentageValue').setValue('');
              return false;
            }
            this.calculatedTotalValue = valueDist;
            // this.budgetCycleForm.get('percentageValue').setValue(elem.percentageOfBenchMarkValue);
            // this.budgetCycleForm.get('valueToResolve').setValue(this.resolvedValue);
            // this.budgetCycleForm.get('totalValue').setValue(this.resolvedValue);
            console.log(this.budgetCycleForm.value)
        })
      }
            
    }
    
  }


  calculateManually(e: any) {

    console.log(`Choice is ${this.choice}`)
    console.log(`isEvenlyDistribution: ${this.isEvenlyDistributeOnly}`)
    if (!this.isClearAll) {
      if (this.choice) 
      {
         console.log(`isPercentage is ${this.isPercentage}`)
   
         if(this.isPercentage === undefined)
         {
           this.displayMessage("warn", "Warning", "Select allocation type");
           return false;
         }
         if (this.isPercentage && this.isEvenlyDistributeOnly === false) {
           this.displayMessage("warn", "Warning", "You can only enter percentage value");
           return false;
         }
         console.log(e)
         e[1].totalValue = Number(e[0]);
         
         let valueDist = 0
         this.budgetByRegions.forEach(elem => {
           if (elem.percentageValue === 0) {
             valueDist += elem.totalValue;
             console.log(valueDist)
           }      
         })
         let totalPercent = 0;
         this.budgetByRegions.forEach(e => {
           if (e.percentageValue === 0) {
               e.percentageOfBenchMarkValue = e.totalValue / valueDist * 100;
           }
         });
         this.calculatedTotalValue = valueDist;
       }
    }
    // else {
    //   if (this.isEvenlyDistributeOnly){
    //     this.displayMessage("warn", "Warning", "You can only distribute evenly")
    //     return false;
    //   } 
    // }

  }

  saveAll(){
      this.confirmationService.confirm({
        message: !this.isEditingRecord ? `Proceed with saving?` : `Proceed with updating?`,
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        // this.messageService.add({ severity: 'info', summary: 'Confirmed', detail: 'You have accepted' });
          this.loadingData = true;
          let proceedWIthSave: boolean = true
          let existingRecord: boolean = false;
          let budgetDistDetails: any[]=[];
          // return false
          if (!this.isEvenlyDistributeOnly)
          {
            
            this.budgetByRegions.forEach( (e) => {
              if ((e.totalValue == 0 || e.totalValue ==undefined) &&
                (e.resolvedValue == 0 || e.resolvedValue == undefined)
              )
              {
                proceedWIthSave = false;
                return false;
              }
              
              e.resolvedValue = e.totalValue; // this is a work around
              e.budgetCycleId = this.selectedBudget.id,
              e.allocationTypeId = this.isPercentage == true ? 1 : 2;
              e.allocationName = this.isPercentage == true ? "Percent" : "Manual"; 
              e.percentageOfBenchMarkValue =  this.isPercentage == true ? e.percentageValue : e.percentageOfBenchMarkValue;          
            });
            
            if (!proceedWIthSave) {
              this.displayMessage("error","Error","Fill all entry")
              this.loadingData = false;
              // proceedWIthSave = false;
              return false;
            }
          }
          
          // if (existingRecord)
          // {
          //   this.displayMessage("error","Error","The records have been saved previously")
          //   return false;
          // }
          // this.loadingData = true;
          console.log(this.budgetByRegions)
          budgetDistDetails.push({"BudgetDistibutions": this.budgetByRegions});
          budgetDistDetails.push({'WhereTo' : "Region"})
          budgetDistDetails.push({"budgetCycleId": this.selectedBudget.id});
          console.log(budgetDistDetails);
          setTimeout(() => {
            this.displayMessage('info', "Information", "Processing request ...");
          }, 1000);

          this.apiService.saveBudgetDistributions(budgetDistDetails).subscribe((res) => {
            var data = res['responseData'];
            if (data == null) {
              this.displayMessage("error", "Error", "Record not saved");
              this.loadingData = false;
              return false;
            }
            setTimeout(() => {
              if (this.isEditingRecord){
                this.displayMessage('success', "Information", "Record updated successfully");
                this.isEditingRecord = false;
              }
              else{
                this.displayMessage('success', "Information", "Record saved successfully");
              }
              
              
            }, 1000);

            this.disableSendForReview = false;
            this.disableSaveButton = true;
            this.disableEvenlyDistribute = true;
            this.disableSelectAllocationType = true;
            this.resolvedValue = undefined;
            // this.budgetCycleForm.reset();
            this.budgetCycleForm.get('allocationType').setValue('');
            this.budgetCycleForm.get('percentageValue').setValue('');
            this.budgetCycleForm.get('valueToResolve').setValue('');
            this.budgetCycleForm.get('comment').setValue('');
            this.loadingData = false;
            // this.reloadPage();
            this.budgetByRegions.forEach( (e) => {
              e.isAlreadySaved = true;
            })
            this.budgetByRegions = undefined;
            this.selectedBudget = undefined;
            this.budgetCycleForm.get('budgetCycle').setValue('');
            this.calculatedTotalValue = undefined;
          }, 
          (err)=> {
            setTimeout(() => {
              this.displayMessage('error', "Error", `Unable to connect to server: ${err.message}`);
              this.loadingData = false;
            }, 1000);
          });
          // this.loadingData = false;
      },
      reject: (type: ConfirmEventType) => {
          switch (type) {
              case ConfirmEventType.REJECT:
                  this.displayMessage("warn","Cancelled","You have cancelled this operation");
                  break;
              case ConfirmEventType.CANCEL:
                  this.displayMessage("warn","Cancelled","You have cancelled this operation");
                  break;
          }
        }
    });

  }

  clearAll() {
    this.confirmationService.confirm({
      message: `Proceed with clearing all records?`,
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        // this.messageService.add({ severity: 'info', summary: 'Confirmed', detail: 'You have accepted' });
        this.displayMessage("info", "Information", "Processing operation")
        this.clearForm();
        
      },
      reject: (type: ConfirmEventType) => {
          switch (type) {
              case ConfirmEventType.REJECT:
                  this.displayMessage("warn","Cancelled","You have cancelled this operation");
                  break;
              case ConfirmEventType.CANCEL:
                  this.displayMessage("warn","Cancelled","You have cancelled this operation");
                  break;
          }
        }
    });
    
  }

  deleteAll() {
    this.confirmationService.confirm({
      message: `This action will delete record from database. Proceed with it?`,
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        // this.messageService.add({ severity: 'info', summary: 'Confirmed', detail: 'You have accepted' });
        this.displayMessage("info", "Information", "Processing operation")
        this.deleteDistributions();
        
      },
      reject: (type: ConfirmEventType) => {
          switch (type) {
              case ConfirmEventType.REJECT:
                  this.displayMessage("warn","Cancelled","You have cancelled this operation");
                  break;
              case ConfirmEventType.CANCEL:
                  this.displayMessage("warn","Cancelled","You have cancelled this operation");
                  break;
          }
        }
    });
  }
  deleteDistributions() {

    let masterId = 0;
    console.log(this.budgetByRegions)
    this.apiService.deleteBudgetDistributions(masterId, "Region").subscribe((res) => {
      var data = res['responseData'];
      if (data == null) {
        this.displayMessage("error", "Error", "Record not deleted");
        this.loadingData = false;
        return false;
      }
      setTimeout(() => {
        
          this.displayMessage('success', "Information", "Record deleted successfully");
          // this.loadingData = false;   
        
      }, 1000);
      this.disableSendForReview = false;
      this.disableSaveButton = true;
      this .disableDeleteButton = true;
      this.disableEvenlyDistribute = true;
      this.disableSelectAllocationType = true;
      this.resolvedValue = undefined;
      // this.budgetCycleForm.reset();
      // this.budgetCycleForm.get('allocationType').setValue('');
      // this.budgetCycleForm.get('percentageValue').setValue('');
      // this.budgetCycleForm.get('valueToResolve').setValue('');
      // this.budgetCycleForm.get('comment').setValue('');
      this.loadingData = false;
      // this.reloadPage();
      
      this.budgetByRegions = [];
      this.selectedBudget = undefined;
      this.budgetCycleForm.get('budgetCycle').setValue('');
      this.calculatedTotalValue = undefined;
    }, 
    (err)=> {
      setTimeout(() => {
        this.displayMessage('error', "Error", `Unable to connect to server: ${err.message}`);
        this.loadingData = false;
      }, 1000);
    });
  }

  clearForm() {
    this.isClearAll = true;
    this.isEditingRecord = true;
    this.disableSelectAllocationType = false;
    this.disableEvenlyDistribute = false;
    this.disableSendForReview = true;
    this.disableSaveButton = false;
    this.percentageValue = ''
    this.choice = true;
    this.isEvenlyDistributeOnly = false;
    this.disableEvenlyDistribute = false;
    this.selectedAllocationType= null;
    // this.budgetCycleForm.reset();
    this.budgetCycleForm.get('allocationType').setValue('');
    this.budgetCycleForm.get('percentageValue').setValue('');
    this.budgetCycleForm.get('valueToResolve').setValue('');
    this.budgetCycleForm.get('comment').setValue('');
    this.isPercentage = undefined
    this.calculatedTotalValue = 0;

    this.budgetByRegions.forEach((elem) => {
      elem.isPercentage = undefined
      elem.percentageValue = 0;
      elem.totalValue = 0;
      elem.resolvedValue = 0;
      elem.allocationName = 0;
      elem.allocationTypeId = 0;
      elem.percentageOfBenchMarkValue = 0;
      // elem.isAlreadySaved = false;
      
    })
    console.log(this.budgetByRegions)
    this.isClearAll = false;
  }

  removeItem(item: any) {
    console.log(item);
    console.log(this.budgetByRegions.indexOf(item));
    // return false;
    this.confirmationService.confirm({
      message: `Proceed with removing this item?`,
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        // this.messageService.add({ severity: 'info', summary: 'Confirmed', detail: 'You have accepted' });
        console.log(this.budgetByRegions.length)
        this.displayMessage("info", "Information", "Processing operation")
        this.budgetByRegions.splice(this.budgetByRegions.indexOf(item), 1);
        console.log(this.budgetByRegions.length)
       
      },
      reject: (type: ConfirmEventType) => {
          switch (type) {
              case ConfirmEventType.REJECT:
                  this.displayMessage("warn","Cancelled","You have cancelled this operation");
                  break;
              case ConfirmEventType.CANCEL:
                  this.displayMessage("warn","Cancelled","You have cancelled this operation");
                  break;
          }
        }
    });
  }

  displayCommentField(){
    // console.log(item);
    // item.comment = 
    // this.displayComment = !this.displayComment;
    // let commentBox = document.querySelector('#txtcomment') as HTMLTextAreaElement;
    // commentBox.style.display = 'block';
  }

  inputComment(item: any){
    console.log(item);
    // item[1].comment = item[0]
    this.itemComment = item[0]
    
    // item.comment = 
    // this.displayComment = !this.displayComment;
    // let commentBox = document.querySelector('#txtcomment') as HTMLTextAreaElement;
    // commentBox.style.display = 'block';
  }
  lostFocus() {
    // this.displayComment = !this.displayComment;
  }

  
  sendForReview() {
    this.confirmationService.confirm({
      message: `Proceed with sending for review?`,
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.loadingData = true;
        let master_id = this.budgetByRegions[0].regionMasterId;
        console.log(master_id);
        console.log(this.budgetByRegions);

        // return false;
        let data: any = {"Id": master_id, "WhereTo": "Region", "BudgetCaption": this.budgetCaption};
        console.log(data);
        // return false;
        setTimeout(() => {
          this.displayMessage('info', "Information", "Processing request ...");
        }, 1000);
        this.apiService.sendBudgetForReview(data)
          .subscribe(
            (res) => {
              var data = res['responseData'];
              if (data == null || data == false) {
                this.displayMessage("error", "Error", "Record not sent for review");
                this.loadingData = false;
                return false;
              }
              setTimeout(() => {
                this.displayMessage('success', "Information", "Sent for review successful");
                this.loadingData = false;
                this.disableSendForReview = true;    
              }, 1000);
              this.reloadPage();
            }, 
            (err)=> {
              setTimeout(() => {
                this.displayMessage('error', "Error", "Error occurred while processing");
                this.loadingData = false;
              }, 1000);
            }
          ); 
       
      },
      reject: (type: ConfirmEventType) => {
          switch (type) {
              case ConfirmEventType.REJECT:
                  this.displayMessage("warn","Cancelled","You have cancelled this operation");
                  break;
              case ConfirmEventType.CANCEL:
                  this.displayMessage("warn","Cancelled","You have cancelled this operation");
                  break;
          }
        }
    });
    
  }

  reloadPage(){
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate(['./'], {
      relativeTo: this.route
    });
  }

  onBlur(evt: any){
    let dd = evt[0].target.value;
    console.log(dd);
    console.log(evt[1])
    if (dd !== '' || dd !== undefined) {
      evt[1].resolvedValue = dd;
      // this.budgetCycleForm.get('valueToResolve').setValue(evt.target.value);
      // this.budgetCycleForm.get('totalValue').setValue(evt.target.value);
    }
    
    console.log(this.budgetCycleForm.value);
  }
}