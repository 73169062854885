import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "../../environments/environment";
import {
  AddContractRenewNotificationVM,
  AddMultipleContractRenewNotificationVM,
  AutoInvoiceSendLog,
  CommonResponse,
  Contract,
  ContractAutoRenewPolicy,
  ContractAutoRenewPolicyLog,
  ContractRenewNotification,
  SaveAutoRenewContractPolicyVM,
  UpdateAutoRenewContractPolicyVM,
} from "../interfaces/home";

@Injectable({
  providedIn: "root",
})
export class ContractsService {
  constructor(private http: HttpClient) {}
  baseUrl = environment.baseUrl + "Contract/";

  // getUnapprovedContracts(): Observable<CommonResponse> {
  //   return this.http.get<CommonResponse>(
  //     this.baseUrl + "GetUnApprovedContracts"
  //   );
  // }

  getUnapprovedContracts(): Observable<CommonResponse> {
    return this.http.get<CommonResponse>(
      this.baseUrl + "RedoGetUnApprovedContracts"
    );
  }

  gMAGetUnapprovedContracts(): Observable<Contract[]> {
    return this.http.get<Contract[]>(
      environment.gmaBaseUrl + "Customer/GetUnapprovedContracts"
    );
  }

  ToggleContractServiceStatus(id: number): Observable<CommonResponse> {
    return this.http.get<CommonResponse>(
      this.baseUrl + "ToggleContractServiceStatus/" + id
    );
  }

  GetClientContracts(cliendId: number): Observable<CommonResponse<Contract[]>> {
    return this.http.get<CommonResponse<Contract[]>>(
      this.baseUrl + "GetClientContracts/" + cliendId
    );
  }

  CheckContractValidity(
    contractId: number
  ): Observable<CommonResponse<boolean>> {
    return this.http.get<CommonResponse<boolean>>(
      this.baseUrl + "CheckContractValidity/" + contractId
    );
  }

  ReValidateContract(contractId: number): Observable<CommonResponse<boolean>> {
    return this.http.get<CommonResponse<boolean>>(
      this.baseUrl + "ReValidateContract/" + contractId
    );
  }

  GetAutoRenewContractPolicies(): Observable<
    CommonResponse<ContractAutoRenewPolicy[]>
  > {
    return this.http.get<CommonResponse<ContractAutoRenewPolicy[]>>(
      this.baseUrl + "GetAutoRenewContractPolicies"
    );
  }

  GetAutoRenewContractPoliciyLogs(
    policyId: number
  ): Observable<CommonResponse<ContractAutoRenewPolicyLog[]>> {
    return this.http.get<CommonResponse<ContractAutoRenewPolicyLog[]>>(
      this.baseUrl + "GetAutoRenewContractPoliciyLogs/" + policyId
    );
  }

  SaveAutoRenewContractPolicy(
    data: SaveAutoRenewContractPolicyVM
  ): Observable<CommonResponse<boolean>> {
    return this.http.post<CommonResponse<boolean>>(
      this.baseUrl + "SaveAutoRenewContractPolicy",
      data
    );
  }

  UpdateAutoRenewContractPolicy(
    policyId: number,
    data: UpdateAutoRenewContractPolicyVM
  ): Observable<CommonResponse<boolean>> {
    return this.http.put<CommonResponse<boolean>>(
      this.baseUrl + "UpdateAutoRenewContractPolicy/" + policyId,
      data
    );
  }

  GetInvoiceAutoSendLogs(
    contractId: number
  ): Observable<CommonResponse<AutoInvoiceSendLog[]>> {
    return this.http.get<CommonResponse<AutoInvoiceSendLog[]>>(
      this.baseUrl + "GetInvoiceAutoSendLogs/" + contractId
    );
  }

  GetContractRenewNotifications(): Observable<
    CommonResponse<ContractRenewNotification[]>
  > {
    return this.http.get<CommonResponse<ContractRenewNotification[]>>(
      this.baseUrl + "GetContractRenewNotifications"
    );
  }

  AddContractRenewNotification(
    data: AddContractRenewNotificationVM
  ): Observable<CommonResponse<boolean>> {
    return this.http.post<CommonResponse<boolean>>(
      this.baseUrl + "AddContractRenewNotification",
      data
    );
  }

  UpdateContractRenewNotification(
    notificationId: number,
    data: AddContractRenewNotificationVM
  ): Observable<CommonResponse<boolean>> {
    return this.http.put<CommonResponse<boolean>>(
      this.baseUrl + "UpdateContractRenewNotification/" + notificationId,
      data
    );
  }

  DeleteContractRenewNotification(
    id: number
  ): Observable<CommonResponse<boolean>> {
    return this.http.delete<CommonResponse<boolean>>(
      this.baseUrl + "DeleteContractRenewNotification/" + id
    );
  }

  AddMultipleContractRenewNotification(
    data: AddMultipleContractRenewNotificationVM
  ): Observable<CommonResponse<boolean>> {
    return this.http.post<CommonResponse<boolean>>(
      this.baseUrl + "AddMultipleContractRenewNotification",
      data
    );
  }
}
