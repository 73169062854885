import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import {
  CommonResponse,
  GroupedPermissions,
  Permissions,
  Role,
  RoleClaim,
  User,
} from "../../interfaces/home";
import { ConfirmationService, MessageService, TreeNode } from "primeng/api";
import { RoleService } from "../../services/role.service";
import { UserService } from "../../services/user.service";
import { environment } from "../../../environments/environment";
import { BreadcrumbService } from "src/app/breadcrumb.service";
import { DialogService, DynamicDialogRef } from "primeng/dynamicdialog";
import { RoleclaimAttributesComponent } from "./roleclaim-attributes/roleclaim-attributes.component";
import { FireBaseAuthService } from "src/app/services/fire-base-auth.service";
import { GroupSelectionComponent } from "./group-selection/group-selection.component";
import { ThisReceiver } from "@angular/compiler";
import { Table } from "primeng/table";
import { MultiSelect } from "primeng/multiselect";

@Component({
  selector: "app-role",
  templateUrl: "./role.component.html",
  styleUrls: ["./role.component.scss"],
  providers: [MessageService, ConfirmationService, DialogService],
})
export class RoleComponent implements OnInit {
  @ViewChild("formWrapper") public formWrapper: ElementRef;
  @ViewChild("formWrapper2") public formWrapper2: ElementRef;
  @ViewChild(Table) permTable: Table;
  @ViewChild(MultiSelect) multiSel: MultiSelect;

  fetchingRole = true;
  fetchingRoleClaims = true;

  roleForm: FormGroup;
  modifyUserRoleForm: FormGroup;

  roles: Role[];
  selectedRole: Role;
  editingRole = false;
  public roleCols: any[];
  public exportRoleColumns: any[];
  private edRole: Role;

  allClaims: RoleClaim[];
  allSourceClaims: RoleClaim[];
  allTargetClaims: RoleClaim[] = [];

  users: User[];
  selectedUserId: any = null;
  selectedRoleId: Role[] = [];

  selectedUser: User = null;
  imageSrc: string;

  userRoleTree: TreeNode[];

  roleMgtClaims: any;
  roleClaimAttributesRef: DynamicDialogRef;

  selectedPermissions: Permissions[] = [];
  availablePermissions: Permissions[] = [];
  groupedPermissions: GroupedPermissions[] = [];
  permissionsSelectionRef: DynamicDialogRef;

  constructor(
    public formBuilder: FormBuilder,
    private roleService: RoleService,
    private userService: UserService,
    public messageService: MessageService,
    public firebaseAuthService: FireBaseAuthService,
    public dialogService: DialogService,
    public confirmationService: ConfirmationService,
    private breadcrumbService: BreadcrumbService
  ) {}

  ngOnInit(): void {
    this.breadcrumbService.setItems([
      { label: "Role Management", routerLink: ["home/role"] },
      { label: "Role", routerLink: ["home/role"] },
    ]);
    // this.loadUserRoleClaims();
    this.fetchRoles();
    this.fetchUsers();
    this.fetchGroupedPermission();

    this.imageSrc = environment.defaultImage;
    this.roleForm = this.formBuilder.group({
      name: ["", [Validators.required]],
      description: ["", [Validators.required]],
      permissions: [null],
    });

    this.modifyUserRoleForm = this.formBuilder.group({
      userId: [null, [Validators.required]],
      roleId: [],
    });

    this.roleCols = [
      { field: "name", header: "Name" },
      { field: "description", header: "Description" },
    ];
    this.exportRoleColumns = this.roleCols.map((col) => ({
      title: col.header,
      dataKey: col.field,
    }));
  }

  fetchUsers() {
    this.userService.allUser().subscribe(async (users: CommonResponse) => {
      if (users.responseCode == "00") {
        this.users = users.responseData;
      }
    });
  }

  fetchRoles() {
    this.roleService.allRole().subscribe(
      async (roles: any) => {
        this.roles = roles?.responseData;
        this.fetchingRole = false;
      },
      (error) => {
        this.fetchingRole = false;
        this.connectionError();
      }
    );
  }

  fetchGroupedPermission() {
    this.roleService.getGroupedPermissions().subscribe(
      async (perm: any) => {
        this.groupedPermissions = perm.responseData;
      },
      (error) => {
        this.connectionError();
      }
    );
  }

  fetchRolePermissions(role: string) {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: `Retrieving permissions for role ${role}`,
    });
    this.roleService.getPermissionsOnRole(role).subscribe(
      async (perm: any) => {
        this.selectedPermissions = perm.responseData;
        this.refreshPermissionTable();
      },
      (error) => {
        this.connectionError();
      }
    );
  }

  // RoleManagement ClaimEnum = 12
  // fetchUserRoleClaims(){
  //     this.roleService.getUserRoleClaims().subscribe(async (user: any) => {
  //         console.log('User : ', user);
  //         const userRoleClaims = user.role.roleClaims;
  //         this.firebaseAuthService.authUserRoleClaims = userRoleClaims;
  //         const roleMgtClaims = userRoleClaims.find(x => x.claimEnum === 12);
  //         if(roleMgtClaims){
  //             this.roleMgtClaims = roleMgtClaims;
  //         }
  //     },error => {
  //         this.connectionError();
  //     });
  // }

  // loadUserRoleClaims(){
  //     const userRoleClaims = this.firebaseAuthService.authUserRoleClaims;
  //     if(userRoleClaims){
  //         const roleMgtClaims = userRoleClaims.find(x => x.claimEnum === 12);
  //         if(roleMgtClaims){
  //             this.roleMgtClaims = roleMgtClaims;
  //         }
  //     }
  // }

  private connectionError() {
    this.messageService.add({
      severity: "error",
      summary: "Failed",
      detail: "Connection Error, Please try again",
    });
  }

  createRole() {
    this.confirmationService.confirm({
      message: `Are you sure you want to create ${
        this.roleForm.get("name").value
      } ?`,
      accept: () => {
        this._createRole();
      },
    });
  }

  _createRole() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Creating new Role Record",
    });
    const postData = {
      name: this.roleForm.get("name").value,
      description: this.roleForm.get("description").value,
      permissions: this.selectedPermissions,
    };

    this.roleService.postRole(postData).subscribe(
      async (res: CommonResponse) => {
        if (res.responseCode == "00") {
          await this.messageService.add({
            severity: "success",
            summary: "Completed",
            detail: "New Role Created",
          });
          await this.fetchRoles();
          await this.roleForm.reset();
          //this.allSourceClaims = JSON.parse(JSON.stringify(this.allClaims));
          this.selectedPermissions = [];
          this.refreshPermissionTable();
        } else {
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail: res.responseMsg,
          });
        }
      },
      (error) => {
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail: "Unable to create new Role at the moment",
        });
      }
    );
  }

  addRoleToUser() {
    this.confirmationService.confirm({
      message: `Are you sure you want to save changes to the role assignment?`,
      accept: () => {
        this._addRoleToUser();
      },
    });
  }

  _addRoleToUser() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Attaching Role To User",
    });
    const postData = {
      userId: this.selectedUserId.id,
      roleId: this.selectedRoleId,
    };

    this.userService.updateUserRole(postData.userId, postData.roleId).subscribe(
      async (res: CommonResponse) => {
        if (res.responseCode == "00") {
          await this.messageService.add({
            severity: "success",
            summary: "Completed",
            detail: "Role Attached Successfully",
          });
          await this.fetchUsers();
          this.userRoleTree = [];
          await this.modifyUserRoleForm.reset();
          this.selectedRoleId = [];
        } else {
          this.messageService.add({
            severity: "error",
            summary: "Failure",
            detail: res.responseMsg,
          });
        }
      },
      (error) => {
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail: "Unable to attach Role at the moment",
        });
      }
    );
  }

  deleteRole(role) {
    this.confirmationService.confirm({
      message: `Are you sure you want to delete ${role.name} ?
                    All users previously attached to this role will be unassigned.`,
      accept: () => {
        this._deleteRole(role);
      },
    });
  }

  _deleteRole(role) {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Deleting role record",
    });
    this.roleService.deleteRole(role.id).subscribe(
      async (result: any) => {
        await this.messageService.add({
          severity: "success",
          summary: "Deleted",
          detail: "Role record removed",
        });
        await this.fetchRoles();
      },
      (error) => {
        this.connectionError();
      }
    );
  }

  editRole(role: Role) {
    this.editingRole = true;
    this.fetchRolePermissions(role.name);
    this.roleForm.addControl(
      "roleId",
      new FormControl({ value: "", disabled: true }, Validators.required)
    );
    this.edRole = role;
    this.roleForm.patchValue({
      name: role.name,
      description: role.description,
      roleId: role.id,
    });

    this.formWrapper.nativeElement.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "start",
    });
  }

  updateRole() {
    // update the selected role
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Updating Role details",
    });
    const id = this.edRole.id;
    const postData = {
      name: this.roleForm.get("name").value,
      description: this.roleForm.get("description").value,
      Permissions: this.selectedPermissions,
    };
    this.roleService
      .updateRole(id, postData)
      .subscribe(async (result: CommonResponse) => {
        if (result.responseCode == "00") {
          this.messageService.add({
            severity: "success",
            summary: "Notice",
            detail: "Role details Updated",
          });
          this.selectedPermissions = [];
          this.refreshPermissionTable();
          await this.fetchRoles();
          await this.closeEditing();
        } else {
          this.messageService.add({
            severity: "error",
            summary: "Failure",
            detail: result.responseMsg,
          });
        }
      });
  }

  closeEditing() {
    this.editingRole = false;
    this.roleForm.reset();
    // this.allSourceClaims = JSON.parse(JSON.stringify(this.allClaims));
    // this.allTargetClaims = [];
    this.selectedPermissions = [];
    this.refreshPermissionTable();
    this.edRole = null;
  }

  userSelectionChanged() {
    if (this.selectedUserId) {
      this.selectedUser = this.selectedUserId as User;
      const selectedUserId = this.selectedUser.id;

      this.messageService.add({
        severity: "info",
        summary: "Notice",
        detail: "Fetching roles of user",
      });

      this.selectedRoleId = [];

      this.roleService
        .getRolesByUser(selectedUserId)
        .subscribe(async (ans: any) => {
          if (ans.responseCode == "00") {
            var result = ans.responseData;
            this.roles.forEach((v, i) => {
              if (result.find((x) => x.id == v.id)) {
                this.selectedRoleId.push(v);
              }
            });
            this.multiSel.updateLabel();
          }
        });

      //fetch the roles of this user

      // const roleClaims = this.selectedUser.role.roleClaims;
      // const roleClaimsTree = [];
      // if(roleClaims.length > 0)
      // {
      //     roleClaims.forEach((val, key) => {

      //         const attributes = [];
      //         const checkIcon =  'pi pi-fw pi-check-circle';
      //         const timesIcon =  'pi pi-fw pi-times-circle';

      //         attributes.push({ label: `Can View`, key: 'Can View',
      //             icon: val.canView ? checkIcon : timesIcon,
      //             data: val.canView, selectable: false })

      //         attributes.push({ label: `Can Add`, key: 'Can Add',
      //             icon: val.canAdd ? checkIcon : timesIcon,
      //             data: val.canAdd, selectable: false })

      //         attributes.push({ label: `Can Update`, key: 'Can Update',
      //             icon: val.canUpdate ? checkIcon : timesIcon,
      //             data: val.canUpdate, selectable: false })

      //         attributes.push({ label: `Can Delete`, key: 'Can Delete',
      //             icon: val.canDelete ? checkIcon : timesIcon,
      //             data: val.canDelete, selectable: false })

      //         roleClaimsTree.push(
      //             {
      //                 key: val.description,
      //                 label: `${val.name}`,
      //                 expanded: true,
      //                 children: attributes
      //             }
      //         );
      //     })
      // }

      // this.userRoleTree = [
      //     {
      //         label: `User (${selectedUser.firstName})`,
      //         key: selectedUser.email,
      //         expanded: true,
      //         children: [{
      //             label: selectedUser.role.name,
      //             key: selectedUser.role.description,
      //             expanded: true,
      //            children: roleClaimsTree
      //         }],
      //     }
      // ];
    }
  }

  roleSelectionChanged() {
    this.selectedRoleId;
  }

  // roleSelectionChanged() {
  //     if(this.selectedUserId && this.selectedRoleId){
  //         const selectedUser = this.selectedUser;
  //         const selectedRole = (this.selectedRoleId as Role);
  //         const roleClaims = selectedRole.roleClaims;
  //         const roleClaimsTree = [];
  //         if(roleClaims.length > 0){
  //             roleClaims.forEach((val, key) => {

  //                 const attributes = [];
  //                 const checkIcon =  'pi pi-fw pi-check-circle';
  //                 const timesIcon =  'pi pi-fw pi-times-circle';

  //                 attributes.push({ label: `Can View`, key: 'Can View',
  //                     icon: val.canView ? checkIcon : timesIcon,
  //                     data: val.canView, selectable: false })

  //                 attributes.push({ label: `Can Add`, key: 'Can Add',
  //                     icon: val.canAdd ? checkIcon : timesIcon,
  //                     data: val.canAdd, selectable: false })

  //                 attributes.push({ label: `Can Update`, key: 'Can Update',
  //                     icon: val.canUpdate ? checkIcon : timesIcon,
  //                     data: val.canUpdate, selectable: false })

  //                 attributes.push({ label: `Can Delete`, key: 'Can Delete',
  //                     icon: val.canDelete ? checkIcon : timesIcon,
  //                     data: val.canDelete, selectable: false })

  //                 roleClaimsTree.push(
  //                     {
  //                         key: val.description,
  //                         label: `${val.name}`,
  //                         expanded: true,
  //                         children: attributes
  //                     }
  //                 );
  //             })
  //         }

  //         this.userRoleTree = [
  //             {
  //                 label: `User (${selectedUser.firstName})`,
  //                 key: selectedUser.email,
  //                 expanded: true,
  //                 children: [{
  //                     label: selectedRole.name,
  //                     key: selectedRole.description,
  //                     expanded: true,
  //                     children: roleClaimsTree
  //                 }],
  //             }
  //         ];
  //     }
  // }

  nodeSelected(event) {
    if (event.node.data) {
      // console.log(event.node.data);
    }
  }

  // detachRoleFromUser(){
  //     this.confirmationService.confirm({
  //         message: `Are you sure you want to detach ${this.selectedUserId.firstName} from ${this.selectedRoleId.role.name} ?`,
  //         accept: () => {
  //             this._detachRoleFromUser();
  //         }
  //     });
  // }

  // _detachRoleFromUser() {
  //     this.messageService.add({severity:'info', summary: 'Notice',
  //         detail:'Detaching User Role'});

  //     const unAssignedRole = this.roles.find(x => x.name === 'UnAssigned');

  //     const postData = {
  //         userId: this.selectedUserId.id,
  //         roleId: unAssignedRole.id
  //     };

  //     this.userService.updateUserRole(postData.userId, postData.roleId).subscribe( async () =>{
  //         await this.messageService.add({severity:'success', summary: 'Completed',
  //             detail:'Role Detached Successfully'});
  //         await this.fetchUsers();
  //         //await this.fetchUserRoleClaims();
  //         this.userRoleTree = []
  //         await this.modifyUserRoleForm.reset();
  //     }, error => {
  //         this.messageService.add({severity:'error', summary: 'Notice',
  //             detail:'Unable to detach Role at the moment'});
  //     });
  // }

  // assignAttributesForRoleClaims(event: any) {
  //     this.roleClaimAttributesRef = this.dialogService.open(RoleclaimAttributesComponent, {
  //         header: 'Assign attributes for the role claim(s)',
  //         width: '25%',
  //         contentStyle: {'min-height': '200px', overflow: 'auto'},
  //         baseZIndex: 10000,
  //         data: { roleClaims: event.items },
  //         closable: false
  //     });

  //     this.roleClaimAttributesRef.onClose.subscribe(async (res: any) => {
  //         this.messageService.add({severity:'info', summary: 'Notice',
  //             detail: 'Attributes set.'});
  //     })
  // }

  canCreateRole(): boolean {
    const canCreateRole =
      !this.editingRole && this.roleMgtClaims && this.roleMgtClaims.canAdd;
    return canCreateRole;
  }

  canUpdateRole(): boolean {
    const canUpdateRole =
      this.editingRole && this.roleMgtClaims && this.roleMgtClaims.canUpdate;
    return canUpdateRole;
  }

  addPermissions(event) {
    event.preventDefault();
    this.permissionsSelectionRef = this.dialogService.open(
      GroupSelectionComponent,
      {
        header: "Select Group of Permissions",
        width: "45%",
        contentStyle: { "min-height": "200px", overflow: "auto" },
        baseZIndex: 10000,
        data: { groupPermissions: this.groupedPermissions },
      }
    );

    this.permissionsSelectionRef.onClose.subscribe(
      async (res: GroupedPermissions[]) => {
        if (res.length == 0) return;

        this.availablePermissions = [];
        res.forEach((value, i) => {
          this.availablePermissions.push(...value.permissionList);
        });

        this.roleClaimAttributesRef = this.dialogService.open(
          RoleclaimAttributesComponent,
          {
            header: "Select permissions",
            width: "40%",
            contentStyle: { "min-height": "200px", overflow: "auto" },
            baseZIndex: 10000,
            data: { permmisions: this.availablePermissions },
          }
        );

        this.roleClaimAttributesRef.onClose.subscribe(
          async (res: Permissions[]) => {
            if (res?.length == 0) {
              return;
            }

            //check the array against duplicates
            res.forEach((value, i) => {
              var permission = this.selectedPermissions.find(
                (x) => x.permission == value.permission
              );
              if (!permission) {
                this.selectedPermissions.push(value);
              }
            });

            this.refreshPermissionTable();
          }
        );
      }
    );
  }

  refreshPermissionTable() {
    this.permTable.reset();
  }

  removePermission(perm: Permissions) {
    const index = this.selectedPermissions.findIndex(
      (x) => x.permission == perm.permission
    );
    this.selectedPermissions = this.selectedPermissions.filter(
      (val, i) => i !== index
    );
    this.refreshPermissionTable();
  }
}
