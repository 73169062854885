import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { ConfirmationService, MessageService } from "primeng/api";
import { BreadcrumbService } from "src/app/breadcrumb.service";
import {
  CommonResponse,
  CustomerDivision,
  Division,
  EndorsementType,
  OperatingEntity,
  ServiceCategory,
  Services,
} from "src/app/interfaces/home";
import {
  GetRevenueAmortizationReportReceivingDTO,
  GetRevenueAmortizationReportTransferDTO,
} from "src/app/interfaces/reporting";
import { CustomerDivisionService } from "src/app/services/customer-division.service";
import { DivisionService } from "src/app/services/division.service";
import { EndorsementTypeService } from "src/app/services/endorsement-type.service";
import { OperatingEntityService } from "src/app/services/operating-entity.service";
import { ReportingService } from "src/app/services/reporting.service";
import { ServiceCategoryService } from "src/app/services/service-category.service";
import { ServicesService } from "src/app/services/services.service";

@Component({
  selector: "app-revenue-armotization-view",
  templateUrl: "./revenue-armotization-view.component.html",
  styleUrls: ["./revenue-armotization-view.component.scss"],
})
export class RevenueArmotizationViewComponent implements OnInit {
  @ViewChild("formWrapper") public formWrapper: ElementRef;
  allClients: CustomerDivision[];
  selectedClients: CustomerDivision[] = [];
  allProductCategories: ServiceCategory[];
  selectedProductCategories: ServiceCategory[] = [];
  allDivisions: Division[];
  selectedDivisions: Division[] = [];
  allProducts: Services[];
  selectedProducts: Services[] = [];
  allMarkets: OperatingEntity[];
  selectedMarkets: OperatingEntity[] = [];
  allBusinessTypes: EndorsementType[];
  selectedBusinessTypes: EndorsementType[] = [];
  showReport: boolean = true;
  allCriteriaReports: GetRevenueAmortizationReportTransferDTO[];
  criteriaReportCols: any[];
  allYears: {
    key: number;
    value: string;
  }[];
  selectedYears: {
    key: number;
    value: string;
  }[] = [];
  totalRecord: GetRevenueAmortizationReportTransferDTO;

  constructor(
    private fb: FormBuilder,
    private reportingService: ReportingService,
    private endorsementTypeService: EndorsementTypeService,
    private serviceCategoryService: ServiceCategoryService,
    private servicesService: ServicesService,
    private customerDivisionService: CustomerDivisionService,
    private divisionService: DivisionService,
    private operatingEntityService: OperatingEntityService,
    private breadcrumbService: BreadcrumbService,
    public confirmationService: ConfirmationService,
    public messageService: MessageService
  ) {}

  ngOnInit(): void {
    this.breadcrumbService.setItems([
      {
        label: "Client Management",
      },
      {
        label: "Revenue Amortization View",
        routerLink: ["/home/client-management/revenue-armotization-view"],
      },
    ]);

    this.criteriaReportCols = [
      { field: "clientName", header: "Customer Name" },
      { field: "clientId", header: "Customer Number" },
      { field: "division", header: "Division" },
      { field: "market", header: "Market" },
      { field: "category", header: "Category" },
      { field: "product", header: "Product" },
      { field: "businessType", header: "Business Type" },
      { field: "january", header: "January" },
      { field: "february", header: "February" },
      { field: "march", header: "March" },
      { field: "april", header: "April" },
      { field: "may", header: "May" },
      { field: "june", header: "June" },
      { field: "july", header: "July" },
      { field: "august", header: "August" },
      { field: "september", header: "September" },
      { field: "october", header: "October" },
      { field: "november", header: "November" },
      { field: "december", header: "December" },
    ];

    this.FetchAllCustomerDivisions();
    this.GetAllServiceCategory();
    this.GetAllServices();
    this.GetAllDivisions();
    this.GetAllMarkets();
    this.GetAllBusinessTypes();
    this.PopulateYearsDDL();
  }

  async FetchAllCustomerDivisions() {
    this.customerDivisionService.allCustomerDivision().subscribe(
      async (data: CommonResponse<CustomerDivision[]>) => {
        if (data.responseCode == "00") {
          this.allClients = data.responseData;
        } else {
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail: data.responseMsg,
          });
        }
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all customer divisions at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  async GetAllDivisions() {
    this.divisionService.allDivisionData().subscribe(
      async (data) => {
        this.allDivisions = data.responseData;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all divsions at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  async GetAllMarkets() {
    this.operatingEntityService.allData().subscribe(
      async (data) => {
        this.allMarkets = data.responseData;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all operating entities at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  async GetAllServiceCategory() {
    this.serviceCategoryService.allServiceCategory().subscribe(
      async (data) => {
        this.allProductCategories = data.responseData;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all service categories at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  async GetAllServices() {
    this.servicesService.allService().subscribe(
      async (data) => {
        this.allProducts = data.responseData;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all services at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  async GetAllBusinessTypes() {
    this.endorsementTypeService.allEndorsementTypeData().subscribe(
      async (data) => {
        this.allBusinessTypes = data.responseData;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all business types at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  PopulateYearsDDL() {
    this.allYears = [];
    let currentYear = new Date(Date.now()).getFullYear();
    for (let i = 5; i > 0; i--) {
      this.allYears.push({
        key: currentYear - i,
        value: currentYear - i + "",
      });
    }
    this.allYears.push({
      key: currentYear,
      value: currentYear + "",
    });
    for (let i = 1; i <= 5; i++) {
      this.allYears.push({
        key: currentYear + i,
        value: currentYear + i + "",
      });
    }
  }

  RunCriteria() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Generating Report...",
    });

    this.showReport = false;
    this.allCriteriaReports = [];
    const postData: GetRevenueAmortizationReportReceivingDTO = {
      clientIds: this.selectedClients.map((x) => x.id),
      divisionIds: this.selectedDivisions.map((x) => x.id),
      operatingEntityIds: this.selectedMarkets.map((x) => x.id),
      serviceCategoryIds: this.selectedProductCategories.map((x) => x.id),
      serviceIds: this.selectedProducts.map((x) => x.id),
      endorsementTypeIds: this.selectedBusinessTypes.map((x) => x.id),
      years:
        this.selectedYears.length > 0
          ? this.selectedYears.map((x) => x.key)
          : [],
    };

    this.reportingService.GetRevenueAmortizationReport(postData).subscribe(
      async (data) => {
        if (data.responseCode != "00") {
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail: data.responseMsg,
          });
          this.showReport = false;
          return;
        }

        this.messageService.add({
          severity: "success",
          summary: "Completed",
          detail: "Report Generated Successfully!",
        });

        this.allCriteriaReports = [];
        data.responseData
          .filter((x) => !x.isTotal)
          .forEach((x) =>
            this.allCriteriaReports.push({
              isTotal: x.isTotal,
              year: x.year,
              clientId: x.clientId,
              clientName: this.allClients.find((y) => y.id == x.clientId)
                .divisionName,
              contractId: x.contractId,
              division: this.allDivisions.find((y) => y.id == +x.division).name,
              market: this.allMarkets.find((y) => y.id == +x.market).name,
              category: this.allProductCategories.find(
                (y) => y.id == +x.category
              ).name,
              product: this.allProducts.find((y) => y.id == +x.product).name,
              businessType: this.allBusinessTypes.find(
                (y) => y.id == +x.businessType
              ).caption,
              january:
                +x.january == 0 ? "-" : "N" + (+x.january).toLocaleString(),
              february:
                +x.february == 0 ? "-" : "N" + (+x.february).toLocaleString(),
              march: +x.march == 0 ? "-" : "N" + (+x.march).toLocaleString(),
              april: +x.april == 0 ? "-" : "N" + (+x.april).toLocaleString(),
              may: +x.may == 0 ? "-" : "N" + (+x.may).toLocaleString(),
              june: +x.june == 0 ? "-" : "N" + (+x.june).toLocaleString(),
              july: +x.july == 0 ? "-" : "N" + (+x.july).toLocaleString(),
              august: +x.august == 0 ? "-" : "N" + (+x.august).toLocaleString(),
              september:
                +x.september == 0 ? "-" : "N" + (+x.september).toLocaleString(),
              october:
                +x.october == 0 ? "-" : "N" + (+x.october).toLocaleString(),
              november:
                +x.november == 0 ? "-" : "N" + (+x.november).toLocaleString(),
              december:
                +x.december == 0 ? "-" : "N" + (+x.december).toLocaleString(),
            })
          );
        this.totalRecord = data.responseData.find((x) => x.isTotal);
        this.showReport = true;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to generate report at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
        this.showReport = false;
      }
    );
  }
}
