import { PayrollApproval } from "./../../../interfaces/payroll";
import { Branch, Location } from "src/app/interfaces/home";
import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import {
  ConfirmationService,
  Message,
  MessageService,
  TreeNode,
} from "primeng/api";
import { BreadcrumbService } from "src/app/breadcrumb.service";
import {
  Cluster,
  GMAZone,
  GuardDeployment,
} from "src/app/interfaces/employment";
import {
  GeneralPayrunComaprisonDataVM,
  GetAllActualEntityUnderPayrollRunVM,
  GetFilteredPayrunOperationsVM,
  GetGeneralPayrunComaprisonResultVM,
  GetSpecificOPPayrunComaprisonResultVM,
  PayGroup,
  PayrollCompareDurationEnum,
  PayrollGroupTypeEnum,
  PayrollItemEnum,
  PayrollRun,
  PayrollRunOperation,
  PayrollRunTypeEnum,
} from "src/app/interfaces/payroll";
import { PayrollService } from "src/app/services/payroll.service";

@Component({
  selector: "app-payroll-management",
  templateUrl: "./payroll-management.component.html",
  styleUrls: ["./payroll-management.component.scss"],
  providers: [MessageService],
})
export class PayrollManagementComponent implements OnInit {
  @ViewChild("formWrapper") public formWrapper: ElementRef;
  cautionForm: FormGroup;
  msg: Message[] = [];
  allRunTypes: {
    key: number;
    value: string;
  }[];
  theRunType: {
    key: number;
    value: string;
  };
  allRunPeriod: {
    key: string;
    value: string;
  }[] = [];
  theRunPeriod: {
    key: string;
    value: string;
  };
  allPayGroups: PayGroup[];
  thePayGroup: PayGroup;
  currentSearchCriteria: string;
  allPayrollItem: {
    key: number;
    value: string;
  }[];
  thePayrollItem: {
    key: number;
    value: string;
  };
  allGroupTypes: {
    key: number;
    value: string;
  }[];
  theGroupType: {
    key: number;
    value: string;
  };
  allCompareDuration: {
    key: number;
    value: string;
  }[];
  theCompareDuration: {
    key: number;
    value: string;
  };
  allGeneralSearchResults: GeneralPayrunComaprisonDataVM[];
  genRadarData: {
    labels: string[];
    datasets: {
      label: string;
      backgroundColor: string;
      borderColor: string;
      pointBackgroundColor: string;
      pointBorderColor: string;
      pointHoverBackgroundColor: string;
      pointHoverBorderColor: string;
      data: number[];
    }[];
  };
  theCompareComparisonCategory: {
    key: number;
    value: string;
  };
  allActualEntities: {
    key: number;
    value: string;
  }[];
  theActualEntity: {
    key: number;
    value: string;
  };
  theCompareDuration2: {
    key: number;
    value: string;
  };
  allSpecificAllowanceSearchResults: any[];
  specificAllowanceRadarData: {
    labels: string[];
    datasets: {
      label: string;
      backgroundColor: string;
      borderColor: string;
      pointBackgroundColor: string;
      pointBorderColor: string;
      pointHoverBackgroundColor: string;
      pointHoverBorderColor: string;
      data: number[];
    }[];
  };
  allSpecifiDeductibleSearchResults: any[];
  specificDeductibleRadarData: {
    labels: string[];
    datasets: {
      label: string;
      backgroundColor: string;
      borderColor: string;
      pointBackgroundColor: string;
      pointBorderColor: string;
      pointHoverBackgroundColor: string;
      pointHoverBorderColor: string;
      data: number[];
    }[];
  };
  allSpecificTaxesSearchResults: any[];
  specificTaxesRadarData: {
    labels: string[];
    datasets: {
      label: string;
      backgroundColor: string;
      borderColor: string;
      pointBackgroundColor: string;
      pointBorderColor: string;
      pointHoverBackgroundColor: string;
      pointHoverBorderColor: string;
      data: number[];
    }[];
  };
  allSpecificNetPaySearchResults: any[];
  specificNetPayRadarData: {
    labels: string[];
    datasets: {
      label: string;
      backgroundColor: string;
      borderColor: string;
      pointBackgroundColor: string;
      pointBorderColor: string;
      pointHoverBackgroundColor: string;
      pointHoverBorderColor: string;
      data: number[];
    }[];
  };
  allPayrollRuns: PayrollRun[];
  thePayrollRunInView: PayrollRun;
  showGeneralComparison: boolean = false;
  genCurrentRunLabel: string;
  specificAllowanceCurrentRunLabel: string;
  specificDeductibleCurrentRunLabel: string;
  specificTaxCurrentRunLabel: string;
  specificNetpayCurrentRunLabel: string;
  genLastRunLabel: string;
  specificAllowanceLastRunLabel: string;
  specificDeductibleLastRunLabel: string;
  specificTaxLastRunLabel: string;
  specificNetpayLastRunLabel: string;
  genLast2RunLabel: string;
  specificAllowanceLast2RunLabel: string;
  specificDeductibleLast2RunLabel: string;
  specificTaxLast2RunLabel: string;
  specificNetpayLast2RunLabel: string;
  genLast3RunLabel: string;
  specificAllowanceLast3RunLabel: string;
  specificDeductibleLast3RunLabel: string;
  specificTaxLast3RunLabel: string;
  specificNetpayLast3RunLabel: string;
  genGroupTypeLabel: string;
  specificGroupTypeLabel: string;
  showSpecificComparison: boolean = false;
  barData: {
    labels: string[];
    datasets: {
      label: string;
      backgroundColor: string;
      borderColor: string;
      data: number[];
    }[];
  };
  specialComparisonEntityData: GetAllActualEntityUnderPayrollRunVM;
  openCautionDialogue: boolean;
  cautionText: string;
  cautionCode: number;
  loadingPayrollRunClosureDits: boolean = false;
  postingTree: TreeNode[];
  selectedPostingTree: TreeNode[];
  cols: any[];
  breakdownDitCols: any[];
  allRegions: Branch[];
  theFilteredRegion: Branch;
  allZones: GMAZone[];
  theFilteredZone: GMAZone;
  allClusters: Cluster[];
  theFilteredCluster: Cluster;
  allLocations: Location[];
  theFilteredLocation: Location;
  totAllowance: string;
  totDeduct: string;
  totTax: string;
  totNetPay: string;
  allOPInPayrollDits: PayrollRunOperation[];
  opPayrollViewDitsCols: any[];
  genSearchCols: any[];
  specAllowaceSearchCols: any[];
  specDeductSearchCols: any[];
  specTaxSearchCols: any[];
  specNetPaySearchCols: any[];
  operationTree: TreeNode[];
  selectedOperationTree: TreeNode[];
  breakdownCols: any[];
  payrollOperationInView: PayrollRunOperation;
  openBreakdownDialogue: boolean;
  allOperationDetailsDeployments: GuardDeployment[];
  allPayrollInViewApprovals: PayrollApproval[] = [];

  constructor(
    private fb: FormBuilder,
    private payrollService: PayrollService,
    private breadcrumbService: BreadcrumbService,
    public confirmationService: ConfirmationService,
    public messageService: MessageService
  ) {
    this.cautionForm = fb.group({});
  }

  ngOnInit(): void {
    this.breadcrumbService.setItems([
      {
        label: "Payroll",
        routerLink: ["/home/payroll/payroll-settings"],
      },
      {
        label: "Payroll Management",
        routerLink: ["/home/payroll/management"],
      },
    ]);

    this.genRadarData = {
      labels: [
        "Eating",
        "Drinking",
        "Sleeping",
        "Designing",
        "Coding",
        "Cycling",
        "Running",
      ],
      datasets: [
        {
          label: "My First dataset",
          backgroundColor: "rgba(54, 162, 235,0.2)",
          borderColor: "rgba(54, 162, 235,1)",
          pointBackgroundColor: "rgba(54, 162, 235,1)",
          pointBorderColor: "#fff",
          pointHoverBackgroundColor: "#fff",
          pointHoverBorderColor: "rgba(54, 162, 235,1)",
          data: [65, 59, 90, 81, 56, 55, 40],
        },
        {
          label: "My Second dataset",
          backgroundColor: "rgba(255, 99, 132,0.2)",
          borderColor: "rgba(255, 99, 132,1)",
          pointBackgroundColor: "rgba(255, 99, 132,1)",
          pointBorderColor: "#fff",
          pointHoverBackgroundColor: "#fff",
          pointHoverBorderColor: "rgba(255, 99, 132,1)",
          data: [28, 48, 40, 19, 96, 27, 100],
        },
      ],
    };

    this.specificAllowanceRadarData = {
      labels: [],
      datasets: [],
    };
    this.specificDeductibleRadarData = {
      labels: [],
      datasets: [],
    };
    this.specificTaxesRadarData = {
      labels: [],
      datasets: [],
    };
    this.specificNetPayRadarData = {
      labels: [],
      datasets: [],
    };

    this.allRunTypes = [
      {
        key: PayrollRunTypeEnum.TrialRun,
        value: "Trial Run",
      },
      {
        key: PayrollRunTypeEnum.FinalRun,
        value: "Final Run",
      },
    ];

    this.allPayrollItem = [
      {
        key: PayrollItemEnum.TotalGross,
        value: "Total Gross",
      },
      {
        key: PayrollItemEnum.TotalDeductions,
        value: "Total Deductions",
      },
      {
        key: PayrollItemEnum.NetPay,
        value: "Net Pay",
      },
    ];

    this.allGroupTypes = [
      {
        key: PayrollGroupTypeEnum.Region,
        value: "Region",
      },
      {
        key: PayrollGroupTypeEnum.Zone,
        value: "Zone",
      },
      {
        key: PayrollGroupTypeEnum.Cluster,
        value: "Cluster",
      },
      {
        key: PayrollGroupTypeEnum.Location,
        value: "Location",
      },
    ];

    this.allCompareDuration = [
      {
        key: PayrollCompareDurationEnum.LastRun,
        value: "Last Run",
      },
      {
        key: PayrollCompareDurationEnum.Last2Run,
        value: "Last 2Run",
      },
      {
        key: PayrollCompareDurationEnum.Last3Run,
        value: "Last 3run",
      },
    ];

    this.allActualEntities = [
      {
        key: 1,
        value: "Actual Entity 1",
      },
      {
        key: 2,
        value: "Actual Entity 2",
      },
    ];

    this.cols = [
      { field: "description", header: "Description" },
      { field: "subDesc", header: "Detail" },
      { field: "cr", header: "CR" },
      { field: "dr", header: "DR" },
      { field: "account", header: "Account" },
      { field: "office", header: "Office" },
      { field: "branch", header: "Branch" },
      { field: "voucher", header: "Voucher" },
      { field: "acctMasterAmt", header: "Value" },
    ];

    this.opPayrollViewDitsCols = [
      { field: "deployedGuardLocationCustomerName", header: "Customer Name" },
      { field: "deployedGuardLocationName", header: "Location" },
      {
        field: "deployedGuard.jobApplication.personalInformation.firstname",
        header: "Firstname",
      },
      {
        field: "deployedGuard.jobApplication.personalInformation.lastname",
        header: "Lastname",
      },
      { field: "deployedGuardId", header: "OperativeID" },
      { field: "totalAllowancesFigure", header: "Total Allowance" },
      { field: "totalDeductionsFigure", header: "Total Deduction" },
      { field: "absenceDeductions", header: "Absence Deduction" },
      { field: "netPayFigure", header: "Net Pay" },
      {
        field: "totalDaysAttachedToLocation",
        header: "Days Attached To Location",
      },
      { field: "totalDaysAbsent", header: "Days Absent" },
      { field: "totalDaysPaidFor", header: "Days Paid For" },
    ];

    this.genSearchCols = [
      { field: "groupType", header: "Group Type" },
      { field: "currentRunAmount", header: "Current Run Amount" },
      { field: "lastRunAmount", header: "Last Run Amount" },
      { field: "last2RunAmount", header: "Last 2 Run Amount" },
      { field: "last3RunAmount", header: "Last 3 Run Amount" },
      { field: "diffAmount", header: "DiffAmount" },
    ];

    this.specAllowaceSearchCols = [
      { field: "groupType", header: "Group Type" },
      { field: "currentRunAmount", header: "Current Run Amount" },
      { field: "lastRunAmount", header: "Last Run Amount" },
      { field: "last2RunAmount", header: "Last 2 Run Amount" },
      { field: "last3RunAmount", header: "Last 3 Run Amount" },
      { field: "diffAmount", header: "DiffAmount" },
    ];

    this.specDeductSearchCols = [
      { field: "groupType", header: "Group Type" },
      { field: "currentRunAmount", header: "Current Run Amount" },
      { field: "lastRunAmount", header: "Last Run Amount" },
      { field: "last2RunAmount", header: "Last 2 Run Amount" },
      { field: "last3RunAmount", header: "Last 3 Run Amount" },
      { field: "diffAmount", header: "DiffAmount" },
    ];

    this.specTaxSearchCols = [
      { field: "groupType", header: "Group Type" },
      { field: "currentRunAmount", header: "Current Run Amount" },
      { field: "lastRunAmount", header: "Last Run Amount" },
      { field: "last2RunAmount", header: "Last 2 Run Amount" },
      { field: "last3RunAmount", header: "Last 3 Run Amount" },
      { field: "diffAmount", header: "DiffAmount" },
    ];

    this.specNetPaySearchCols = [
      { field: "groupType", header: "Group Type" },
      { field: "currentRunAmount", header: "Current Run Amount" },
      { field: "lastRunAmount", header: "Last Run Amount" },
      { field: "last2RunAmount", header: "Last 2 Run Amount" },
      { field: "last3RunAmount", header: "Last 3 Run Amount" },
      { field: "diffAmount", header: "DiffAmount" },
    ];

    this.breakdownDitCols = [
      { field: "name", header: "Name" },
      {
        field: "amount",
        header: "Amount",
      },
    ];

    this.postingTree = [];
    this.ResetMessageToasters();
    this.FetchInfomaticData();
  }

  ResetMessageToasters() {
    this.msg = [];
    this.msg.push({
      severity: "info",
      summary: "Notice:",
      detail:
        this.currentSearchCriteria ??
        "The search criteria message would be displayed here.",
    });
  }

  FetchInfomaticData() {
    this.payrollService.GetAllRunPeriodAndPaygroup().subscribe(
      async (data) => {
        this.allPayGroups = data.payGroups;
        data.runPeriods.forEach((payrun) => {
          let exists = this.allRunPeriod.find((x) => x.key == payrun.payPeriod);
          if (!exists) {
            this.allRunPeriod.push({
              key: payrun.payPeriod,
              value: payrun.payPeriod,
            });
          }
        });
        this.allPayrollRuns = data.runPeriods;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch data at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
        this.ResetMessageToasters();
      }
    );
  }

  RunTypeSelectionLoader() {}

  RunPeriodSelectionLoader() {}

  PayGroupSelectionLoader() {}

  ViewPayrollMG() {
    this.thePayrollRunInView = this.allPayrollRuns.find(
      (x) =>
        x.payPeriod == this.theRunPeriod.key &&
        x.runType == this.theRunType.key &&
        x.payGroupId == this.thePayGroup.id
    );

    if (!this.thePayrollRunInView) {
      this.messageService.add({
        severity: "error",
        summary: "Notice",
        detail: "No Payroll Run Matching this criteria exists.",
      });
      this.showGeneralComparison = false;
      this.showSpecificComparison = false;
    } else this.GetPayrollRunActualEntities(this.thePayrollRunInView.id);
  }

  PayrollItemSelectionLoader() {}

  GroupTypeSelectionLoader() {}

  CompareDurSelectionLoader() {}

  CheckGenPayrollMG() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Generating Report...",
    });
    this.ResetMessageToasters();

    const postData: GetGeneralPayrunComaprisonResultVM = {
      payrollRunId: this.thePayrollRunInView.id,
      payrollItem: this.thePayrollItem.key,
      payrollGroupType: this.theGroupType.key,
      payrollCompareDuration: this.theCompareDuration.key,
    };

    this.genRadarData.labels = [];
    this.genRadarData.datasets = [];
    this.payrollService.GetGeneralPayrunComaprisonResult(postData).subscribe(
      async (data) => {
        this.allGeneralSearchResults = data.comparisonDatas;
        console.log(this.allGeneralSearchResults);
        this.genCurrentRunLabel = data.currentRunLabel;
        this.genLastRunLabel = data.lastRunLabel;
        this.genLast2RunLabel = data.last2RunLabel;
        this.genLast3RunLabel = data.last3RunLabel;
        if (this.genCurrentRunLabel) {
          this.genRadarData.datasets.push({
            label: this.genCurrentRunLabel,
            backgroundColor: data.currentRunColor,
            borderColor: data.currentRunColor,
            pointBackgroundColor: data.currentRunColor,
            pointBorderColor: "#fff",
            pointHoverBackgroundColor: "#fff",
            pointHoverBorderColor: data.currentRunColor,
            data: [],
          });
        }
        if (this.genLastRunLabel) {
          this.genRadarData.datasets.push({
            label: this.genLastRunLabel,
            backgroundColor: data.lastRunColor,
            borderColor: data.lastRunColor,
            pointBackgroundColor: data.lastRunColor,
            pointBorderColor: "#fff",
            pointHoverBackgroundColor: "#fff",
            pointHoverBorderColor: data.lastRunColor,
            data: [],
          });
        }
        if (this.genLast2RunLabel) {
          this.genRadarData.datasets.push({
            label: this.genLast2RunLabel,
            backgroundColor: data.last2RunLabel,
            borderColor: data.last2RunLabel,
            pointBackgroundColor: data.last2RunLabel,
            pointBorderColor: "#fff",
            pointHoverBackgroundColor: "#fff",
            pointHoverBorderColor: data.last2RunLabel,
            data: [],
          });
        }
        if (this.genLast3RunLabel) {
          this.genRadarData.datasets.push({
            label: this.genLast3RunLabel,
            backgroundColor: data.last3RunLabel,
            borderColor: data.last3RunLabel,
            pointBackgroundColor: data.last3RunLabel,
            pointBorderColor: "#fff",
            pointHoverBackgroundColor: "#fff",
            pointHoverBorderColor: data.last3RunLabel,
            data: [],
          });
        }
        data.comparisonDatas.forEach((compareData) => {
          this.genRadarData.labels.push(compareData.groupType);
          if (this.genCurrentRunLabel)
            this.genRadarData.datasets[0].data.push(
              compareData.currentRunAmount
            );
          if (this.genLastRunLabel)
            this.genRadarData.datasets[1].data.push(compareData.lastRunAmount);
          if (this.genLast2RunLabel)
            this.genRadarData.datasets[2].data.push(compareData.last2RunAmount);
          if (this.genLast3RunLabel)
            this.genRadarData.datasets[3].data.push(compareData.last3RunAmount);
        });
        this.GetGenGroupTypeLabel();
        this.showGeneralComparison = true;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to generate report at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
        this.ResetMessageToasters();
        this.showGeneralComparison = false;
      }
    );
  }

  ComparisonCategSelectionLoader() {
    this.allActualEntities = [];
    this.theActualEntity = null;
    if (
      this.theCompareComparisonCategory.key == PayrollGroupTypeEnum.Location
    ) {
      this.specialComparisonEntityData.locations.forEach((location) => {
        this.allActualEntities.push({
          key: location.id,
          value: location.name,
        });
      });
    } else if (
      this.theCompareComparisonCategory.key == PayrollGroupTypeEnum.Cluster
    ) {
      this.specialComparisonEntityData.clusters.forEach((cluster) => {
        this.allActualEntities.push({
          key: cluster.id,
          value: cluster.caption,
        });
      });
    } else if (
      this.theCompareComparisonCategory.key == PayrollGroupTypeEnum.Zone
    ) {
      this.specialComparisonEntityData.zones.forEach((zone) => {
        this.allActualEntities.push({
          key: zone.id,
          value: zone.caption,
        });
      });
    } else if (
      this.theCompareComparisonCategory.key == PayrollGroupTypeEnum.Region
    ) {
      this.specialComparisonEntityData.regions.forEach((region) => {
        this.allActualEntities.push({
          key: region.id,
          value: region.name,
        });
      });
    }
  }

  ActualEntitySelectionLoader() {}

  CompareDur2SelectionLoader() {}

  CheckSpecificPayrollMG() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Generating Report...",
    });
    this.ResetMessageToasters();

    const postData: GetSpecificOPPayrunComaprisonResultVM = {
      payrollRunId: this.thePayrollRunInView.id,
      actualEntityId: this.theActualEntity.key,
      payrollGroupType: this.theCompareComparisonCategory.key,
      payrollCompareDuration: this.theCompareDuration2.key,
    };

    this.specificAllowanceRadarData.labels = [];
    this.specificAllowanceRadarData.datasets = [];
    this.specificDeductibleRadarData.labels = [];
    this.specificDeductibleRadarData.datasets = [];
    this.specificTaxesRadarData.labels = [];
    this.specificTaxesRadarData.datasets = [];
    this.specificNetPayRadarData.labels = [];
    this.specificNetPayRadarData.datasets = [];
    this.payrollService.GetSpecificOPPayrunComaprisonResult(postData).subscribe(
      async (data) => {
        this.allSpecificAllowanceSearchResults =
          data.allowances.comparisonDatas;
        this.allSpecifiDeductibleSearchResults =
          data.deductions.comparisonDatas;
        this.allSpecificTaxesSearchResults = data.taxes.comparisonDatas;
        this.allSpecificNetPaySearchResults = data.netPay.comparisonDatas;
        this.specificAllowanceCurrentRunLabel = data.allowances.currentRunLabel;
        this.specificDeductibleCurrentRunLabel =
          data.deductions.currentRunLabel;
        this.specificTaxCurrentRunLabel = data.taxes.currentRunLabel;
        this.specificNetpayCurrentRunLabel = data.netPay.currentRunLabel;
        this.specificAllowanceLastRunLabel = data.allowances.lastRunLabel;
        this.specificDeductibleLastRunLabel = data.deductions.lastRunLabel;
        this.specificTaxLastRunLabel = data.taxes.lastRunLabel;
        this.specificNetpayLastRunLabel = data.netPay.lastRunLabel;
        this.specificAllowanceLast2RunLabel = data.allowances.last2RunLabel;
        this.specificDeductibleLast2RunLabel = data.deductions.last2RunLabel;
        this.specificTaxLast2RunLabel = data.taxes.last2RunLabel;
        this.specificNetpayLast2RunLabel = data.netPay.last2RunLabel;
        this.specificAllowanceLast3RunLabel = data.allowances.last3RunLabel;
        this.specificDeductibleLast3RunLabel = data.deductions.last3RunLabel;
        this.specificTaxLast3RunLabel = data.taxes.last3RunLabel;
        this.specificNetpayLast3RunLabel = data.netPay.last3RunLabel;

        if (this.specificAllowanceCurrentRunLabel) {
          this.specificAllowanceRadarData.datasets.push({
            label: this.specificAllowanceCurrentRunLabel,
            // backgroundColor: data.allowances.currentRunColor,
            backgroundColor: "",
            borderColor: data.allowances.currentRunColor,
            pointBackgroundColor: data.allowances.currentRunColor,
            pointBorderColor: "#fff",
            pointHoverBackgroundColor: "#fff",
            pointHoverBorderColor: data.allowances.currentRunColor,
            data: [],
          });
        }
        if (this.specificDeductibleCurrentRunLabel) {
          this.specificDeductibleRadarData.datasets.push({
            label: this.specificDeductibleCurrentRunLabel,
            // backgroundColor: data.deductions.currentRunColor,
            backgroundColor: "",
            borderColor: data.deductions.currentRunColor,
            pointBackgroundColor: data.deductions.currentRunColor,
            pointBorderColor: "#fff",
            pointHoverBackgroundColor: "#fff",
            pointHoverBorderColor: data.deductions.currentRunColor,
            data: [],
          });
        }
        if (this.specificTaxCurrentRunLabel) {
          this.specificTaxesRadarData.datasets.push({
            label: this.specificTaxCurrentRunLabel,
            backgroundColor: data.taxes.currentRunColor,
            borderColor: data.taxes.currentRunColor,
            pointBackgroundColor: data.taxes.currentRunColor,
            pointBorderColor: "#fff",
            pointHoverBackgroundColor: "#fff",
            pointHoverBorderColor: data.taxes.currentRunColor,
            data: [],
          });
        }
        if (this.specificNetpayCurrentRunLabel) {
          this.specificNetPayRadarData.datasets.push({
            label: this.specificNetpayCurrentRunLabel,
            backgroundColor: data.netPay.currentRunColor,
            borderColor: data.netPay.currentRunColor,
            pointBackgroundColor: data.netPay.currentRunColor,
            pointBorderColor: "#fff",
            pointHoverBackgroundColor: "#fff",
            pointHoverBorderColor: data.netPay.currentRunColor,
            data: [],
          });
        }

        //Last Run
        if (this.specificAllowanceLastRunLabel) {
          this.specificAllowanceRadarData.datasets.push({
            label: this.specificAllowanceLastRunLabel,
            // backgroundColor: data.allowances.lastRunColor,
            backgroundColor: "",
            borderColor: data.allowances.lastRunColor,
            pointBackgroundColor: data.allowances.lastRunColor,
            pointBorderColor: "#fff",
            pointHoverBackgroundColor: "#fff",
            pointHoverBorderColor: data.allowances.lastRunColor,
            data: [],
          });
        }
        if (this.specificDeductibleLastRunLabel) {
          this.specificDeductibleRadarData.datasets.push({
            label: this.specificDeductibleLastRunLabel,
            // backgroundColor: data.deductions.lastRunColor,
            backgroundColor: "",
            borderColor: data.deductions.lastRunColor,
            pointBackgroundColor: data.deductions.lastRunColor,
            pointBorderColor: "#fff",
            pointHoverBackgroundColor: "#fff",
            pointHoverBorderColor: data.deductions.lastRunColor,
            data: [],
          });
        }
        if (this.specificTaxLastRunLabel) {
          this.specificTaxesRadarData.datasets.push({
            label: this.specificTaxLastRunLabel,
            backgroundColor: data.taxes.lastRunColor,
            borderColor: data.taxes.lastRunColor,
            pointBackgroundColor: data.taxes.lastRunColor,
            pointBorderColor: "#fff",
            pointHoverBackgroundColor: "#fff",
            pointHoverBorderColor: data.taxes.lastRunColor,
            data: [],
          });
        }
        if (this.specificNetpayLastRunLabel) {
          this.specificNetPayRadarData.datasets.push({
            label: this.specificNetpayLastRunLabel,
            backgroundColor: data.netPay.lastRunColor,
            borderColor: data.netPay.lastRunColor,
            pointBackgroundColor: data.netPay.lastRunColor,
            pointBorderColor: "#fff",
            pointHoverBackgroundColor: "#fff",
            pointHoverBorderColor: data.netPay.lastRunColor,
            data: [],
          });
        }

        //Last 2 Runs
        if (this.specificAllowanceLast2RunLabel) {
          this.specificAllowanceRadarData.datasets.push({
            label: this.specificAllowanceLast2RunLabel,
            // backgroundColor: data.allowances.last2RunColor,
            backgroundColor: "",
            borderColor: data.allowances.last2RunColor,
            pointBackgroundColor: data.allowances.last2RunColor,
            pointBorderColor: "#fff",
            pointHoverBackgroundColor: "#fff",
            pointHoverBorderColor: data.allowances.last2RunColor,
            data: [],
          });
        }
        if (this.specificDeductibleLast2RunLabel) {
          this.specificDeductibleRadarData.datasets.push({
            label: this.specificDeductibleLast2RunLabel,
            // backgroundColor: data.deductions.last2RunColor,
            backgroundColor: "",
            borderColor: data.deductions.last2RunColor,
            pointBackgroundColor: data.deductions.last2RunColor,
            pointBorderColor: "#fff",
            pointHoverBackgroundColor: "#fff",
            pointHoverBorderColor: data.deductions.last2RunColor,
            data: [],
          });
        }
        if (this.specificTaxLast2RunLabel) {
          this.specificTaxesRadarData.datasets.push({
            label: this.specificTaxLast2RunLabel,
            backgroundColor: data.taxes.last2RunColor,
            borderColor: data.taxes.last2RunColor,
            pointBackgroundColor: data.taxes.last2RunColor,
            pointBorderColor: "#fff",
            pointHoverBackgroundColor: "#fff",
            pointHoverBorderColor: data.taxes.last2RunColor,
            data: [],
          });
        }
        if (this.specificNetpayLast2RunLabel) {
          this.specificNetPayRadarData.datasets.push({
            label: this.specificNetpayLast2RunLabel,
            backgroundColor: data.netPay.last2RunColor,
            borderColor: data.netPay.last2RunColor,
            pointBackgroundColor: data.netPay.last2RunColor,
            pointBorderColor: "#fff",
            pointHoverBackgroundColor: "#fff",
            pointHoverBorderColor: data.netPay.last2RunColor,
            data: [],
          });
        }

        //Last 3 Run
        if (this.specificAllowanceLast3RunLabel) {
          this.specificAllowanceRadarData.datasets.push({
            label: this.specificAllowanceLast3RunLabel,
            // backgroundColor: data.allowances.last3RunColor,
            backgroundColor: "",
            borderColor: data.allowances.last3RunColor,
            pointBackgroundColor: data.allowances.last3RunColor,
            pointBorderColor: "#fff",
            pointHoverBackgroundColor: "#fff",
            pointHoverBorderColor: data.allowances.last3RunColor,
            data: [],
          });
        }
        if (this.specificDeductibleLast3RunLabel) {
          this.specificDeductibleRadarData.datasets.push({
            label: this.specificDeductibleLast3RunLabel,
            // backgroundColor: data.deductions.last3RunColor,
            backgroundColor: "",
            borderColor: data.deductions.last3RunColor,
            pointBackgroundColor: data.deductions.last3RunColor,
            pointBorderColor: "#fff",
            pointHoverBackgroundColor: "#fff",
            pointHoverBorderColor: data.deductions.last3RunColor,
            data: [],
          });
        }
        if (this.specificTaxLast3RunLabel) {
          this.specificTaxesRadarData.datasets.push({
            label: this.specificTaxLast3RunLabel,
            backgroundColor: data.taxes.last3RunColor,
            borderColor: data.taxes.last3RunColor,
            pointBackgroundColor: data.taxes.last3RunColor,
            pointBorderColor: "#fff",
            pointHoverBackgroundColor: "#fff",
            pointHoverBorderColor: data.taxes.last3RunColor,
            data: [],
          });
        }
        if (this.specificNetpayLast3RunLabel) {
          this.specificNetPayRadarData.datasets.push({
            label: this.specificNetpayLast3RunLabel,
            backgroundColor: data.netPay.last3RunColor,
            borderColor: data.netPay.last3RunColor,
            pointBackgroundColor: data.netPay.last3RunColor,
            pointBorderColor: "#fff",
            pointHoverBackgroundColor: "#fff",
            pointHoverBorderColor: data.netPay.last3RunColor,
            data: [],
          });
        }

        //Allowance
        data.allowances.comparisonDatas.forEach((compareData) => {
          this.specificAllowanceRadarData.labels.push(compareData.groupType);
          if (this.specificAllowanceCurrentRunLabel)
            this.specificAllowanceRadarData.datasets[0].data.push(
              compareData.currentRunAmount
            );
          if (this.specificAllowanceLastRunLabel)
            this.specificAllowanceRadarData.datasets[1].data.push(
              compareData.lastRunAmount
            );
          if (this.specificAllowanceLast2RunLabel)
            this.specificAllowanceRadarData.datasets[2].data.push(
              compareData.last2RunAmount
            );
          if (this.specificAllowanceLast3RunLabel)
            this.specificAllowanceRadarData.datasets[3].data.push(
              compareData.last3RunAmount
            );
        });

        //Deduction
        data.deductions.comparisonDatas.forEach((compareData) => {
          this.specificDeductibleRadarData.labels.push(compareData.groupType);
          if (this.specificDeductibleCurrentRunLabel)
            this.specificDeductibleRadarData.datasets[0].data.push(
              compareData.currentRunAmount
            );
          if (this.specificDeductibleLastRunLabel)
            this.specificDeductibleRadarData.datasets[1].data.push(
              compareData.lastRunAmount
            );
          if (this.specificDeductibleLast2RunLabel)
            this.specificDeductibleRadarData.datasets[2].data.push(
              compareData.last2RunAmount
            );
          if (this.specificDeductibleLast3RunLabel)
            this.specificDeductibleRadarData.datasets[3].data.push(
              compareData.last3RunAmount
            );
        });

        //Taxes
        data.taxes.comparisonDatas.forEach((compareData) => {
          this.specificTaxesRadarData.labels.push(compareData.groupType);
          if (this.specificTaxCurrentRunLabel)
            this.specificTaxesRadarData.datasets[0].data.push(
              compareData.currentRunAmount
            );
          if (this.specificTaxLastRunLabel)
            this.specificTaxesRadarData.datasets[1].data.push(
              compareData.lastRunAmount
            );
          if (this.specificTaxLast2RunLabel)
            this.specificTaxesRadarData.datasets[2].data.push(
              compareData.last2RunAmount
            );
          if (this.specificTaxLast3RunLabel)
            this.specificTaxesRadarData.datasets[3].data.push(
              compareData.last3RunAmount
            );
        });

        //Net Pay
        data.netPay.comparisonDatas.forEach((compareData) => {
          this.specificNetPayRadarData.labels.push(compareData.groupType);
          if (this.specificNetpayCurrentRunLabel)
            this.specificNetPayRadarData.datasets[0].data.push(
              compareData.currentRunAmount
            );
          if (this.specificNetpayLastRunLabel)
            this.specificNetPayRadarData.datasets[1].data.push(
              compareData.lastRunAmount
            );
          if (this.specificNetpayLast2RunLabel)
            this.specificNetPayRadarData.datasets[2].data.push(
              compareData.last2RunAmount
            );
          if (this.specificNetpayLast3RunLabel)
            this.specificNetPayRadarData.datasets[3].data.push(
              compareData.last3RunAmount
            );
        });

        this.GetSpecificGroupTypeLabel();
        this.showSpecificComparison = true;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to generate report at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
        this.ResetMessageToasters();
        this.showSpecificComparison = false;
      }
    );
  }

  GetGenGroupTypeLabel() {
    if (this.theGroupType.key == PayrollGroupTypeEnum.Location)
      this.genGroupTypeLabel = "Location";
    if (this.theGroupType.key == PayrollGroupTypeEnum.Cluster)
      this.genGroupTypeLabel = "Cluster";
    if (this.theGroupType.key == PayrollGroupTypeEnum.Zone)
      this.genGroupTypeLabel = "Zone";
    if (this.theGroupType.key == PayrollGroupTypeEnum.Region)
      this.genGroupTypeLabel = "Region";
  }

  GetSpecificGroupTypeLabel() {
    if (this.theCompareComparisonCategory.key == PayrollGroupTypeEnum.Location)
      this.specificGroupTypeLabel = "Location";
    if (this.theCompareComparisonCategory.key == PayrollGroupTypeEnum.Cluster)
      this.specificGroupTypeLabel = "Cluster";
    if (this.theCompareComparisonCategory.key == PayrollGroupTypeEnum.Zone)
      this.specificGroupTypeLabel = "Zone";
    if (this.theCompareComparisonCategory.key == PayrollGroupTypeEnum.Region)
      this.specificGroupTypeLabel = "Region";
  }

  GetPayrollRunActualEntities(payrollRunId) {
    this.payrollService
      .GetAllActualEntityUnderPayrollRun(payrollRunId)
      .subscribe(
        async (data) => {
          this.specialComparisonEntityData = data;
          this.totAllowance = "N" + data.totalAllowances.toLocaleString();
          this.totDeduct = "N" + data.totalDeductions.toLocaleString();
          this.totTax = "N" + data.totalTaxes.toLocaleString();
          this.totNetPay = "N" + data.totalNetPays.toLocaleString();

          this.allRegions = data.regions;
          this.allZones = data.zones;
          this.allClusters = data.clusters;
          this.allLocations = data.locations;
          this.allPayrollInViewApprovals = data.approvals;

          this.allRegions.push({
            id: 0,
            name: "All",
          });
          this.allZones.push({
            id: 0,
            caption: "All",
          });
          this.allClusters.push({
            id: 0,
            caption: "All",
          });
          this.allLocations.push({
            id: 0,
            name: "All",
          });

          this.allRegions.reverse();
          this.allZones.reverse();
          this.allClusters.reverse();
          this.allLocations.reverse();
        },
        (error) => {
          console.log("Error: " + JSON.stringify(error));
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail:
              "Unable to fetch payroll actual entities at the moment.. Reason: [" +
              error
                ? error.error.message
                : "request failed - permission" + "]",
          });
          this.ResetMessageToasters();
        }
      );
  }

  GetSpecificCriteriaType(): string {
    if (this.theCompareComparisonCategory) {
      return this.theCompareComparisonCategory.value;
    }
    return "";
  }

  GetSpecificCriteriaLabel(): string {
    if (this.theActualEntity) {
      return this.theActualEntity.value;
    }
    return "";
  }

  SendForApprovalPayrollMG() {
    this.cautionText =
      "Are you sure you want to send payroll run for approval. This will log this payroll for approval and is an irreversible action. Do you still wish to proceed?";
    this.cautionCode = 1;
    this.openCautionDialogue = true;
  }

  CautionAction() {
    if (this.cautionCode == 1) {
      this.messageService.add({
        severity: "info",
        summary: "Notice",
        detail: "Sending Final Payroll Run For Approval...",
      });
      this.ResetMessageToasters();

      this.payrollService
        .SendPayrollRunForApproval(this.thePayrollRunInView.id)
        .subscribe(
          async () => {
            this.messageService.add({
              severity: "success",
              summary: "Completed",
              detail: "Final Payroll Run Send For Approval Successfully!",
            });
            this.ResetMessageToasters();

            this.thePayrollRunInView.isSentForApproval = true;
            this.HideCautionDialog();
          },
          (error) => {
            console.log("Error: " + JSON.stringify(error));
            this.messageService.add({
              severity: "error",
              summary: "Notice",
              detail:
                "Unable to send final payroll run for approval at the moment.. Reason: [" +
                error
                  ? error.error.message
                  : "request failed - permission" + "]",
            });
            this.ResetMessageToasters();
          }
        );
    } else if (this.cautionCode == 2) {
      this.messageService.add({
        severity: "info",
        summary: "Notice",
        detail: "Closing Final Payroll Run...",
      });
      this.ResetMessageToasters();

      this.payrollService
        .CloseFinalPayrollRun(this.thePayrollRunInView.id)
        .subscribe(
          async () => {
            this.messageService.add({
              severity: "success",
              summary: "Completed",
              detail: "Final Payroll Run Has Been Closed Successfully!",
            });
            this.ResetMessageToasters();

            this.thePayrollRunInView.isClosed = true;
            this.HideCautionDialog();
          },
          (error) => {
            console.log("Error: " + JSON.stringify(error));
            this.messageService.add({
              severity: "error",
              summary: "Notice",
              detail:
                "Unable to close final payroll run at the moment.. Reason: [" +
                error
                  ? error.error.message
                  : "request failed - permission" + "]",
            });
            this.ResetMessageToasters();
          }
        );
    }
  }

  HideCautionDialog() {
    this.openCautionDialogue = false;
    this.cautionCode = null;
    this.cautionText = null;
    this.loadingPayrollRunClosureDits = false;
  }

  CloseRunPayrollMG() {
    this.cautionText =
      "Are you sure you want to close this payroll run for pay period " +
      this.thePayrollRunInView.payPeriod +
      ". This is an irreversible action. Do you still wish to proceed?";
    this.cautionCode = 2;
    this.openCautionDialogue = true;

    this.postingTree = [];
    this.loadingPayrollRunClosureDits = true;
    this.payrollService
      .GetPayrollRunClosureDetails(this.thePayrollRunInView.id)
      .subscribe(
        async (data) => {
          data.forEach((posting) => {
            let postingDataTree: TreeNode = {
              data: {
                description: posting.postingDesc,
                subDesc: null,
                cr: null,
                dr: null,
                account: null,
                office: posting.officePostingTo,
                branch: posting.branchPostingTo,
                voucher: posting.voucherInfo,
                acctMasterAmt: posting.postingAmount,
              },
              children: [],
            };

            posting.postingData.forEach((postingDits) => {
              postingDataTree.children.push({
                data: {
                  description: "",
                  subDesc: postingDits.subPostingDesc,
                  cr: postingDits.subPostingCRAmount,
                  dr: postingDits.subPostingDRAmount,
                  account: postingDits.accountNoPostingTo,
                  office: postingDits.officePostingTo,
                  branch: postingDits.branchPostingTo,
                  voucher: postingDits.voucherInfo,
                  acctMasterAmt: null,
                },
              });
            });

            this.postingTree.push(postingDataTree);
          });

          this.loadingPayrollRunClosureDits = false;
        },
        (error) => {
          console.log("Error: " + JSON.stringify(error));
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail:
              "Unable to close final payroll run at the moment.. Reason: [" +
              error
                ? error.error.message
                : "request failed - permission" + "]",
          });
          this.HideCautionDialog();
          this.ResetMessageToasters();
        }
      );
  }

  ApplyFilters() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Applying Filter(s)...",
    });

    const postData: GetFilteredPayrunOperationsVM = {
      payrollRunId: this.thePayrollRunInView.id,
      regionId: this.theFilteredRegion.id,
      zoneId: this.theFilteredZone.id,
      clusterId: this.theFilteredCluster.id,
      locationId: this.theFilteredLocation.id,
    };

    this.payrollService.GetFilteredPayrunOperations(postData).subscribe(
      async (data) => {
        this.messageService.add({
          severity: "success",
          summary: "Successful",
          detail: "Fetched Successful",
          life: 3000,
        });

        this.allOperationDetailsDeployments = data.guardDeployments;
        // data.payrollOperations.forEach((x) => {
        //   x.location = this.GetOperativeLocation(x);
        // });
        this.allOPInPayrollDits = data.payrollOperations;
        this.ResetMessageToasters();
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to load filtered data at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
        this.ResetMessageToasters();
      }
    );
  }

  ViewOperationBreakdown(item: PayrollRunOperation) {
    this.operationTree = [];

    let allowanceTree: TreeNode = {
      data: {
        name: "Allowance(s)",
        amount: null,
      },
      children: [],
    };
    let deductionTree: TreeNode = {
      data: {
        name: "Deduction(s)",
        amount: null,
      },
      children: [],
    };

    item.allowances.forEach((allowance) => {
      allowanceTree.children.push({
        data: {
          name: allowance.allowanceName,
          amount: allowance.amount,
        },
      });
    });
    item.deductions.forEach((deduction) => {
      deductionTree.children.push({
        data: {
          name: deduction.deductionName,
          amount: deduction.amount,
        },
      });
    });
    deductionTree.children.push({
      data: {
        name: "Tax",
        amount: item.appliedTaxAmount.toFixed(2),
      },
    });
    item.taxes.forEach((tax) => {
      deductionTree.children.push({
        data: {
          name: tax.taxName,
          amount: tax.amount.toFixed(2),
        },
      });
    });

    this.operationTree.push(allowanceTree);
    this.operationTree.push(deductionTree);
    this.payrollOperationInView = item;
    this.openBreakdownDialogue = true;
  }

  HideBreakdownDialog() {
    this.openBreakdownDialogue = false;
    this.payrollOperationInView = null;
  }

  GetOperativeLocation(item: PayrollRunOperation): string {
    let deployment = this.allOperationDetailsDeployments.find(
      (x) => x.deployedGuardId == item.deployedGuardId
    );
    if (deployment && deployment.location) return deployment.location.name;

    return "Pool";
  }
}
