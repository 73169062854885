import { Injectable } from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {ArmedEscortProfile} from '../../interfaces/armada';

@Injectable({
  providedIn: 'root'
})
export class ArmedEscortProfileService {
  baseUrl = environment.armadaBaseUrl+'ArmedEscortProfile/';
  constructor(private httpClient: HttpClient) { }
  allArmedEscortProfile(): Observable<ArmedEscortProfile>{
    return this.httpClient.get<ArmedEscortProfile>(this.baseUrl);
  }
  getUnAssignedEscorts(): Observable<ArmedEscortProfile[]>{
    return this.httpClient.get<ArmedEscortProfile[]>(this.baseUrl + 'GetUnAssignedEscorts');
  }
  getArmedEscortProfile(id: any):Observable<ArmedEscortProfile>{
    return this.httpClient.get<ArmedEscortProfile>(this.baseUrl +id);
  }
  getArmedEscortProfileByName(name: any):Observable<ArmedEscortProfile>{
    return this.httpClient.get<ArmedEscortProfile>(this.baseUrl + 'name/' + name);
  }
  postArmedEscortProfile(data: any){
    return this.httpClient.post(this.baseUrl, data);
  }
  delete(id: any){
    return this.httpClient.delete(this.baseUrl +id);
  }
  updateArmedEscortProfile(id: any, data: any){
    return this.httpClient.put(this.baseUrl +id, data);
  }
}
