import { ProfileEscalationLevel } from "./../interfaces/profile-escalation-level";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { CommonResponse } from "../interfaces/home";

@Injectable({
  providedIn: "root",
})
export class ProfileEscalationLevelService {
  baseUrl = environment.baseUrl + "ProfileEscalationLevel/";

  constructor(private http: HttpClient) {}

  getAll(): Observable<CommonResponse> {
    return this.http.get<CommonResponse>(this.baseUrl);
  }

  geAllHandlers(): Observable<CommonResponse> {
    return this.http.get<CommonResponse>(
      this.baseUrl + "GetAllHandlers"
    );
  }

  getByID(id: number): Observable<CommonResponse> {
    return this.http.get<CommonResponse>(this.baseUrl + id);
  }

  create(data: ProfileEscalationLevel) {
    return this.http.post(this.baseUrl, data);
  }

  delete(id: number) {
    return this.http.delete(this.baseUrl + id);
  }

  update(id: any, data: ProfileEscalationLevel) {
    return this.http.put(this.baseUrl + id, data);
  }
}
