import { ModeOfTransportService } from "./../../services/mode-of-transport.service";
import { ModeOfTransport } from "./../../interfaces/mode-of-transport";
import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { MessageService, ConfirmationService } from "primeng/api";
import { BreadcrumbService } from "src/app/breadcrumb.service";
import { CommonResponse } from "src/app/interfaces/home";

@Component({
  selector: "app-modeoftransport",
  templateUrl: "./modeoftransport.component.html",
  styleUrls: ["./modeoftransport.component.scss"],
})
export class ModeoftransportComponent implements OnInit {
  @ViewChild("formWrapper") public formWrapper: ElementRef;
  form: FormGroup;
  editing = false;
  fetching = true;
  modeOfTransports: ModeOfTransport[];
  selectedModeOfTransport: ModeOfTransport;
  cols: any[];
  private modeOfTransportToEdit: ModeOfTransport;

  constructor(
    fb: FormBuilder,
    public messageService: MessageService,
    public modeOfTransportService: ModeOfTransportService,
    private breadcrumbService: BreadcrumbService,
    public confirmationService: ConfirmationService
  ) {
    this.form = fb.group({
      Caption: ["", Validators.required],
      Description: ["", Validators.required],
    });
  }

  ngOnInit(): void {
    this.breadcrumbService.setItems([
      { label: "Mode Of Transport", routerLink: ["home/modeoftransport"] },
    ]);
    this.cols = [
      { field: "caption", header: "Caption" },
      { field: "description", header: "Description" },
    ];

    this.FetchAllModeOfTransport();
  }

  FetchAllModeOfTransport() {
    this.modeOfTransportService.getAll().subscribe(
      async (r: CommonResponse) => {
        this.modeOfTransports = r.responseData;
        this.fetching = false;
      },
      (error) => {
        this.fetching = false;
        this.connectionError();
      }
    );
  }

  CreateModeOfTransport() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Adding Mode Of Transporty...",
    });

    const postData: ModeOfTransport = {
      caption: this.form.get("Caption").value,
      description: this.form.get("Description").value,
    };

    this.modeOfTransportService.create(postData).subscribe(
      async () => {
        await this.messageService.add({
          severity: "success",
          summary: "Completed",
          detail: "New Mode Of Transport Added...",
        });

        this.FetchAllModeOfTransport();
        this.form.reset();
      },
      (error) => {
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to add mode of transport at the moment.. Reason: [" +
            JSON.stringify(error) +
            "]",
        });
      }
    );
  }

  DeleteModeOfTransport(item: ModeOfTransport) {
    this.confirmationService.confirm({
      message: "Are you sure you want to remove " + item.caption,
      accept: () => {
        this.messageService.add({
          severity: "info",
          summary: "Notice",
          detail: "Removing Mode Of Transport - " + item.caption,
        });
        this.modeOfTransportService.delete(item.id).subscribe(
          async () => {
            await this.messageService.add({
              severity: "success",
              summary: "Removed",
              detail: item.caption + " removed successfully",
            });
            await this.FetchAllModeOfTransport();
          },
          (error) => {
            this.connectionError();
          }
        );
      },
    });
  }

  EditModeOfTransport(item: ModeOfTransport) {
    this.editing = true;
    this.form.addControl(
      "ID",
      new FormControl({ value: "", disabled: true }, Validators.required)
    );
    this.modeOfTransportToEdit = item;
    this.form.patchValue({
      ID: item.id,
      Caption: item.caption,
      Description: item.description,
    });
    this.formWrapper.nativeElement.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "start",
    });
  }

  UpdateModeOfTransport() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Updating Mode Of Transport...",
    });
    const id = this.modeOfTransportToEdit.id;
    const postData: ModeOfTransport = {
      caption: this.form.get("Caption").value,
      description: this.form.get("Description").value,
    };

    this.modeOfTransportService.update(id, postData).subscribe(
      async () => {
        this.messageService.add({
          severity: "success",
          summary: "Notice",
          detail: "Update Successful!",
        });
        await this.FetchAllModeOfTransport();
        await this.CloseEditing();
      },
      (error) => {
        this.messageService.add({
          severity: "error",
          summary: "Unable To Update",
          detail:
            "Unable to update mode of transport at the moment.. Reason: [" +
            JSON.stringify(error) +
            "]",
        });
      }
    );
  }

  CloseEditing() {
    this.editing = false;
    this.form.reset();
    this.modeOfTransportToEdit = null;
  }

  private connectionError() {
    this.messageService.add({
      severity: "error",
      summary: "Failed",
      detail: "Connection Error, Please try again",
    });
  }
}
