import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CommonResponse, Lead } from 'src/app/interfaces/home';
import { LeadService } from 'src/app/services/lead.service';

@Component({
  selector: 'app-new-lead-dashboard',
  templateUrl: './new-lead-dashboard.component.html',
  styleUrls: ['./new-lead-dashboard.component.scss']
})
export class NewLeadDashboardComponent implements OnInit {

  searchForm: FormGroup;
  noteForm: FormGroup;
  mailForm: FormGroup;

  leads: Lead[];
  selectedLead: Lead;

  leadInformation: Lead;

  constructor(
    private leadService: LeadService,
    private formBuilder: FormBuilder
  ) { }

  ngOnInit(): void {
    this.searchForm = this.formBuilder.group({
      lead: [null, Validators.required]
    })

    this.noteForm = this.formBuilder.group({
      caption: ['', Validators.required],
      subject: ['', Validators.required],
      description: ['', Validators.required]
    })

    this.mailForm = this.formBuilder.group({
      subject: ['', Validators.required],
      body: ['', Validators.required]
    })

    this.fetchLeads();
  }

  fetchLeads(){
    this.leadService.allLeads()
      .subscribe(async (res: CommonResponse) => {
      this.leads = res.responseData;
    })
  }

  searchLead(){
    
  }

  leadChanged(){

  }

  sendMail(){

  }

  saveNote(){

  }
}
