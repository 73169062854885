import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { ConfirmationService, MessageService, TreeNode } from "primeng/api";
import {
  Account,
  AdminDirectRelationships,
  CommonResponse,
  ControlAccount,
  OperatingEntity,
  RequiredServiceDocument,
  RequiredServiceQualElement,
  ServiceCategory,
  ServiceRelationshipEnum,
  Services,
  ServiceType,
  Target,
} from "../../../../interfaces/home";
import { ServicesService } from "../../../../services/services.service";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { EventInput } from "@fullcalendar/core";
import { RequiredServiceDocumentService } from "../../../../services/required-service-document.service";
import { TargetService } from "../../../../services/target.service";
import { RequiredServiceQualElementService } from "../../../../services/required-service-qual-element.service";
import { AccountService } from "../../../../services/account.service";
import { ServiceTypeService } from "../../../../services/service-type.service";
import { ServiceCategoryService } from "../../../../services/service-category.service";
import { DivisionService } from "../../../../services/division.service";
import { environment } from "../../../../../environments/environment";
import { finalize, map } from "rxjs/operators";
import { BreadcrumbService } from "src/app/breadcrumb.service";
import { ControlAccountService } from "src/app/services/control-account.service";
import { DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";
import { Observable } from "rxjs/internal/Observable";
import { AngularFireStorage } from "@angular/fire/storage";
import { FileStorageService } from "src/app/services/file-storage.service";

@Component({
  selector: "app-service-form",
  templateUrl: "./service-form.component.html",
  styleUrls: ["./service-form.component.scss"],
  providers: [MessageService, ConfirmationService],
})
export class ServiceFormComponent implements OnInit {
  @ViewChild("formWrapper") public formWrapper: ElementRef;
  serviceColumn: any;
  operatingEntities: OperatingEntity[] = [];
  selectedServices: Services[] = [];
  services: Services[] = [];
  servicesAll: Services[] = [];
  unmappedDirectServices: Services[] = [];
  allAdminDirectRelationshp: AdminDirectRelationships[] = [];
  relationshipsToUse: any[] = [];
  selectedAdminService: Services = null;
  loadingServices = true;
  loadingDivision = true;
  public exportServiceColumns: any;
  editingServices = false;
  serviceTypes: ServiceType[];
  selectedServiceType?: ServiceType;
  serviceForm: FormGroup;
  selectedServiceElements: any;
  serviceElements: any;
  requiredServiceDocuments: RequiredServiceDocument[] = [];
  selectedRequiredServiceDocument: RequiredServiceDocument[] = [];
  serviceTree: TreeNode[];
  serviceTreeChildren: TreeNode[] = [];
  serviceRelationships: any;
  isSubmitted = false;
  public selectedServiceCategory: ServiceCategory;
  allServiceCategories: ServiceCategory[] = [];
  targets: Target[] = [];
  selectedTarget?: Target;
  requiredServiceFields: RequiredServiceQualElement[] = [];
  serviceRequiredField: RequiredServiceQualElement[] = [];
  selectedRequiredServiceFields: RequiredServiceQualElement[] = [];
  accounts: Account[] = [];
  controlAccounts: ControlAccount[] = [];
  selectedAccount: Account = null;
  editingService = false;
  private edService: Services;
  serviceImg: any;
  file: any[] = [];
  public uploadingNewPicture: boolean;
  public selectedOperatingEntity: OperatingEntity;
  public loadingEditServices: boolean;
  isSelectedViaTree: boolean = false;
  selectedServiceFields: any[] = [];
  selectedDocuments: any[] = [];
  backendErrorMessage: string;
  submitting: boolean = false;
  downloadURL: Observable<string>;
  isCreatingService: boolean = false;
  validationError: string = "";
  serviceAccounts: Account[] = [];
  DtrackIncomeAccounts: any[] = [];
  //selectedIncomeAccount: any;
  selectedServiceAccountMapping: any;
  selectedIncomeAccountMapping: any;
  selectedIncomeAccount: any;

  constructor(
    private messageService: MessageService,
    private formBuilder: FormBuilder,
    private servicesService: ServicesService,
    public attribModalRef: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private fileStorageService: FileStorageService
  ) {
    this.serviceImg = environment.defaultServiceImage;
    this.editingService = false;
  }

  //get the controls of this with f('name')
  get() {
    return this.serviceForm.controls;
  }

  private setupForm() {
    this.serviceForm = this.formBuilder.group({
      id: [""],
      name: ["", Validators.required],
      description: ["", Validators.required],
      imageUrl: [this.serviceImg],
      unitPrice: ["", Validators.required],
      targetId: [null, Validators.required],
      serviceTypeId: ["", Validators.required],
      controlAccountId: ["", Validators.required],
      requiredDocumentsId: [
        "",
        this.requiredServiceDocuments.length > 0
          ? Validators.required
          : !Validators.required,
      ],
      requiredServiceFieldsId: [
        "",
        this.serviceRequiredField.length > 0
          ? Validators.required
          : !Validators.required,
      ],
      isVatable: [false],
      canBeSoldOnline: [false],
      adminService: [null],
      directService: [null],
      relationships: [null, Validators.required],
      expenseAccount: [""],
      incomeAccount: ["", Validators.required],
    });
  }
  ngOnInit(): void {
    this.setupForm();
    this.serviceRelationships = [
      { name: "No dependency", code: "1" },
      { name: "Direct Service", code: "2" },
      { name: "Admin Service", code: "3" },
    ];

    var data = this.config.data;
    this.selectedServiceCategory = data.selectedServiceCategory;
    this.relationshipsToUse = data.relationshipsToUse;
    this.serviceImg = data.serviceImg;
    this.allAdminDirectRelationshp = data.allAdminDirectRelationshp;
    this.controlAccounts = data.controlAccounts;
    this.serviceRequiredField = data.serviceRequiredField;
    this.requiredServiceDocuments = data.requiredServiceDocuments;
    this.targets = data.targets;
    this.serviceTypes = data.serviceTypes;
    this.serviceAccounts = data.serviceAccounts;
    this.selectedServiceAccountMapping = data.selectedServiceAccountMapping;
    this.selectedIncomeAccountMapping = data.selectedIncomeAccountMapping;
    this.DtrackIncomeAccounts = data.DtrackIncomeAccounts;

    if (data.service) {
      //editing
      this.editingService = true;
      this.editService(data.service);
    }
  }

  createService(requestForPublish) {
    this.isSubmitted = true;

    if (!this.serviceForm.valid) {
      this.messageService.add({
        severity: "info",
        summary: "Validation Errors",
        detail: "Please fill out required fields",
      });
      return;
    }

    let ServiceRelationshipEnum = this.serviceForm.value?.relationships?.code;

    if (
      ServiceRelationshipEnum == "3" &&
      this.serviceForm.value?.directService?.id == null
    ) {
      this.validationError =
        "You need to select a child direct service for this admin";
      return;
    }

    this.messageService.add({
      severity: "info",
      summary: "Creating",
      detail: "Creating service record",
    });

    this.validationError = "";
    const requiredDocuments = [];
    if (this.serviceForm.value?.requiredDocumentsId?.length > 0) {
      this.serviceForm.value?.requiredDocumentsId.forEach((value) => {
        requiredDocuments.push(value.id);
      });
    }

    const requiredServiceFields = [];
    if (this.serviceForm.value?.requiredServiceFieldsId?.length > 0) {
      this.serviceForm.value?.requiredServiceFieldsId.forEach((value) => {
        requiredServiceFields.push(value.id);
      });
    }

    var incomeAccount = this.serviceForm.value.incomeAccount;

    var postData = {
      name: this.serviceForm.value.name,
      description: this.serviceForm.value.description,
      unitPrice: this.serviceForm.value.unitPrice,
      isRequestedForPublish: requestForPublish,
      targetId: this.serviceForm.value?.targetId?.id,
      serviceTypeId: this.serviceForm.value?.serviceTypeId?.id,
      controlAccountId: this.serviceForm.value?.controlAccountId?.id,
      serviceCategoryId: this.selectedServiceCategory?.id,
      serviceGroupId: this.selectedServiceCategory?.serviceGroupId,
      operatingEntityId: this.selectedServiceCategory?.operatingEntityId,
      divisionId: this.selectedServiceCategory.divisionId,
      requiredServiceFieldsId: requiredServiceFields,
      requiredDocumentsId: requiredDocuments,
      imageUrl: this.serviceForm.value.imageUrl ?? this.serviceImg,
      isVatable: this.serviceForm.value.isVatable ?? false,
      ServiceRelationshipEnum: ServiceRelationshipEnum,
      canBeSoldOnline: this.serviceForm.value.canBeSoldOnline ?? false,
      directServiceId: this.serviceForm.value?.directService?.id,
      incomeAccountId: incomeAccount.id,
    };

    var expenseAccount = this.serviceForm.value.expenseAccount;
    if (expenseAccount) {
      postData["serviceAccount"] = expenseAccount.id;
    }

    // var incomeAccount = this.serviceForm.value.incomeAccount;
    // if (incomeAccount) {
    //   postData["incomeAccountId"] = incomeAccount.Id;
    // }

    this.submitting = true;
    this.isCreatingService = true;
    if (this.file) this.fetchImageURL(postData);
    else this._createService(postData);
  }

  private _createService(postData) {
    this.servicesService.postServices(postData).subscribe(
      async (result: CommonResponse) => {
        if (result?.responseCode == "00") {
          this.attribModalRef.close(result);
        } else {
          this.backendErrorMessage = result.responseMsg;
        }
      },
      (error) => {
        this.connectionError();
      }
    );
  }

  async editService(service) {
    this.isSubmitted = false;
    this.edService = service;

    this.serviceForm.patchValue({
      id: service.id,
      name: service.name,
      description: service.description,
      serviceId: service.id,
      unitPrice: service.unitPrice,
      isVatable: service.isVatable,
      canBeSoldOnline: service.canBeSoldOnline,
      serviceTypeId:
        this.serviceTypes?.find(
          (x) => x.caption == service?.serviceType?.caption
        ) ?? {},
      targetId:
        this.targets?.find((x) => x.caption == service?.target?.caption) ?? {},
      relationships: this.serviceRelationships.find(
        (x) => x.code == service.serviceRelationshipEnum
      ),
      controlAccountId: this.controlAccounts.find(
        (x) => x.id == service.controlAccountId
      ),
      requiredDocumentsId: service.requiredServiceDocument,
      requiredServiceFieldsId: service.requiredServiceFields,
    });

    if (this.selectedServiceAccountMapping) {
      var p = this.selectedServiceAccountMapping.accountId;
      this.serviceForm.patchValue({
        expenseAccount: this.serviceAccounts.find((x) => x.id == p),
      });
    }

    //*************** */
    if (this.selectedIncomeAccountMapping) {
      var p = this.selectedIncomeAccountMapping.incomeAccountId;
      this.serviceForm.patchValue({
        incomeAccount: this.DtrackIncomeAccounts.find((x) => x.id == p),
      });
    }

    //if this is admin
    if (service.serviceRelationshipEnum == ServiceRelationshipEnum.Admin) {
      var adminDirect = this.allAdminDirectRelationshp.find(
        (x) => x.adminServiceId == service.id
      );
      //add the direct service to relationsToUse
      if (adminDirect) {
        this.relationshipsToUse.push(adminDirect.directService);
        this.serviceForm.patchValue({
          directService: this.relationshipsToUse.find(
            (x) => x.id == adminDirect.directServiceId
          ),
        });
      }
    }

    this.serviceImg = service.imageUrl;
    // this.selectedRequiredServiceDocument = service.requiredServiceDocument;
    // this.selectedServiceElements = service.requiredServiceFields;
    //this.selectedAccount = service.account;
    // this.selectedControlAccount = service.controlAccount;
  }

  updateService() {
    // update the selected service
    this.isSubmitted = true;

    let ServiceRelationshipEnum = this.serviceForm.value?.relationships?.code;
    if (
      ServiceRelationshipEnum == "3" &&
      this.serviceForm.value?.directService?.id == null
    ) {
      this.validationError =
        "You need to select a child direct service for this admin";
      return;
    }

    if (!this.serviceForm.valid) {
      return;
    }

    if (!this.serviceForm.value?.controlAccountId?.id) {
      this.validationError = "You need to select a control account";
      return;
    }

    if (!this.serviceForm.value?.serviceTypeId?.id) {
      this.validationError = "You need to select a service type";
      return;
    }

    if (!this.serviceForm.value?.incomeAccount?.id) {
      this.validationError = "You need to select an income account";
      return;
    }

    const requiredDocuments = [];
    if (this.serviceForm.value?.requiredDocumentsId?.length > 0) {
      this.serviceForm.value?.requiredDocumentsId.forEach((value) => {
        requiredDocuments.push(value.id);
      });
    }

    const requiredServiceFields = [];
    if (this.serviceForm.value?.requiredServiceFieldsId?.length > 0) {
      this.serviceForm.value?.requiredServiceFieldsId.forEach((value) => {
        requiredServiceFields.push(value.id);
      });
    }

    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Updating Service details",
    });

    const id = this.serviceForm.value.id;
    this.validationError = "";

    var incomeAccount = this.serviceForm.value.incomeAccount;
    // if (incomeAccountId) {
    //   postData["incomeAccountId"] = incomeAccountId.id;
    // }

    var postData = {
      id: this.serviceForm.value.id,
      name: this.serviceForm.value.name,
      description: this.serviceForm.value.description,
      unitPrice: this.serviceForm.value.unitPrice,
      isRequestedForPublish: this.edService.isRequestedForPublish,
      targetId: this.serviceForm.value?.targetId?.id,
      serviceTypeId: this.serviceForm.value?.serviceTypeId?.id,
      controlAccountId: this.serviceForm.value?.controlAccountId?.id,
      serviceCategoryId: this.selectedServiceCategory?.id,
      serviceGroupId: this.selectedServiceCategory.serviceGroupId,
      operatingEntityId: this.selectedServiceCategory.operatingEntityId,
      divisionId: this.selectedServiceCategory.divisionId,
      requiredServiceFieldsId: requiredServiceFields,
      requiredDocumentsId: requiredDocuments,
      imageUrl: this.serviceForm.value.imageUrl ?? this.serviceImg,
      isVatable: this.serviceForm.value.isVatable ?? false,
      serviceRelationshipEnum: ServiceRelationshipEnum,
      canBeSoldOnline: this.serviceForm.value.canBeSoldOnline ?? false,
      directServiceId: this.serviceForm.value?.directService?.id,
      incomeAccountId: incomeAccount.id,
    };

    var expenseAccount = this.serviceForm.value.expenseAccount;
    if (expenseAccount) {
      postData["serviceAccount"] = expenseAccount.id;
    }

    // var incomeAccountId = this.serviceForm.value.incomeAccount;
    // if (incomeAccountId) {
    //   postData["incomeAccountId"] = incomeAccountId.id;
    // }

    this.submitting = true;
    this.isCreatingService = false;
    if (this.file) this.fetchImageURL(postData);
    else this._updateService(postData.id, postData);
  }

  private _updateService(id, postData) {
    this.servicesService
      .updateServices(id, postData)
      .subscribe(async (result: any) => {
        if (result?.responseCode == "00") {
          this.attribModalRef.close(result);
        } else {
          this.validationError = result.responseMsg;
          this.submitting = false;
        }
      });
  }

  closeEditing() {
    this.editingService = false;
    this.serviceForm.reset();
    this.edService = null;
  }

  private connectionError() {
    this.messageService.add({
      severity: "error",
      summary: "Failed",
      detail: "Connection Error, Please try again",
    });
  }

  onFileSelect(event) {
    if (!this.checkMimeType(event) || !this.checkFileSize(event)) {
      return;
    }

    this.uploadingNewPicture = true;
    // this.editingService = true;
    this.serviceForm.enable();
    const reader = new FileReader();
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      this.file.push(event.target.files[0]);
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.serviceImg = reader.result as string;
        this.serviceForm.patchValue({
          imageUrl: reader.result,
        });
      };
    }
  }

  fetchImageURL(postData) {
    const n = Date.now();
    if (this.file.length > 0) {
      this.fileStorageService.UploadMultipleFilesFromDataUrl(this.file);

      this.fileStorageService.onUploadFinished.subscribe(
        (resp: CommonResponse<string[]>) => {
          if (resp.responseCode != "00") {
            this.messageService.add({
              severity: "error",
              summary: "Notice",
              detail: resp.responseMsg,
            });
          } else {
            var imageUrl = resp.responseData;
            postData.imageUrl = imageUrl[0];
            if (this.isCreatingService) {
              this._createService(postData);
            } else {
              this._updateService(postData.id, postData);
            }
          }
        },
        (error) => {
          console.log("Error while creating platform " + error);
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail: "ERR: Unable to upload image to storage",
          });
        }
      );
    }
  }

  checkMimeType = (event: EventInput): boolean => {
    const files = event.target.files[0];
    // list allow mime type
    const types = ["image/png", "image/jpeg", "image/jpg", "image/gif"];
    // loop access array
    if (!files) {
      this.messageService.add({
        severity: "error",
        summary: "Failed",
        detail: "Please select an Image file",
      });
      return false;
    }
    // compare file type find doesn't match
    if (types.every((type) => files.type !== type)) {
      // create error message and assign to container
      this.messageService.add({
        severity: "error",
        summary: "Failed",
        detail: "Image format not supported",
      });
      return false;
    }
    return true;
  };
  checkFileSize = (event: EventInput) => {
    const files = event.target.files[0];
    if (!files) return true;
    const size = 400000;
    if (files.size > size) {
      event.target.value = null;
      this.messageService.add({
        severity: "error",
        summary: "Failed",
        detail: "Image is too large, please pick a smaller file",
      });
      return false;
    }
    return true;
  };
}
