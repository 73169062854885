import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { EngagementType,CommonResponse } from '../interfaces/home';

@Injectable({
  providedIn: 'root'
})
export class EngagementTypeService {
  etobservable: Observable<CommonResponse>;
  constructor(private http: HttpClient) { }
  baseUrl = environment.baseUrl+'EngagementType/';
  // fetch all EngagementType in the database
  allEngagementTypeData(): Observable<CommonResponse>{
    if(this.etobservable) return this.etobservable
    else {
      this.etobservable = this.http.get<CommonResponse>(this.baseUrl).pipe(shareReplay()); 
      return this.etobservable;
    }
  }
  getEngagementType(id: any):Observable<EngagementType>{
    return this.http.get<EngagementType>(this.baseUrl +id);
  }
  getEngagementTypeByName(name: any):Observable<EngagementType>{
    return this.http.get<EngagementType>(this.baseUrl + 'name/' + name);
  }
  postEngagementType(data: any):Observable<EngagementType>{
    return this.http.post(this.baseUrl, data);
  }
  deleteEngagementType(id: any){
    return this.http.delete(this.baseUrl +id);
  }
  updateEngagementType(id: any, data: any):Observable<EngagementType>{
    return this.http.put(this.baseUrl +id, data);
  }
}
