import {
  GMAApprovingOffice,
  Student,
  TrainingSchool,
  TrainingSchoolApprovalLog,
} from "./../../../interfaces/employment";
import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { MessageService, ConfirmationService } from "primeng/api";
import { BreadcrumbService } from "src/app/breadcrumb.service";
import { GmaEmploymentService } from "src/app/services/gma-employment.service";

@Component({
  selector: "app-gma-approval",
  templateUrl: "./gma-approval.component.html",
  styleUrls: ["./gma-approval.component.scss"],
})
export class GmaApprovalComponent implements OnInit {
  @ViewChild("formWrapper") public formWrapper: ElementRef;
  fetchingAwaitingApprovals: boolean = true;
  fetchingPickedApprovals: boolean = true;
  allAwaitingApprovals: TrainingSchool[];
  selectedAwaitingApproval: TrainingSchool;
  awaitApprovalCols: any[];
  allPickedApprovals: TrainingSchool[];
  selectedPickedApproval: TrainingSchool;
  pickedApprovalCols: any[];
  allApprovalLogs: TrainingSchoolApprovalLog[];
  selectedApprovalLog: TrainingSchoolApprovalLog;
  appOfficeCols: any[];
  allApprovalDetails: Student[];
  selectedApprovalDetail: Student;
  showApprovingOffices: boolean = false;
  showApprovingDetails: boolean = false;
  dueCount: number = 0;
  notdueCount: number = 0;
  overdueCount: number = 0;
  tempTrue: boolean = true;
  currentUserApprovingOfficeID: number;
  currentUserApprovingOfficeSequence: number;
  trainingSchoolInView: TrainingSchool;
  allApprovalOffices: GMAApprovingOffice[];

  constructor(
    public employmentService: GmaEmploymentService,
    public messageService: MessageService,
    private breadcrumbService: BreadcrumbService,
    public confirmationService: ConfirmationService
  ) {}

  ngOnInit(): void {
    this.breadcrumbService.setItems([
      {
        label: "Guard Management",
        routerLink: ["/home/guard-management/set-up"],
      },
      {
        label: "Approval",
        routerLink: ["/home/guard-management/approval"],
      },
    ]);

    this.awaitApprovalCols = [
      { field: "name", header: "Name" },
      { field: "schoolCode", header: "SchoolCode" },
      { field: "description", header: "Description" },
    ];

    this.pickedApprovalCols = [
      { field: "name", header: "Name" },
      { field: "schoolCode", header: "SchoolCode" },
      { field: "description", header: "Description" },
    ];

    this.appOfficeCols = [
      { field: "name", header: "Name" },
      { field: "office", header: "Office" },
      { field: "status", header: "Status" },
    ];

    this.allApprovalLogs = [];
    this.GetAllTrainigSchoolInApprovalPhase();
  }

  GetAllTrainigSchoolInApprovalPhase() {
    this.employmentService.GetTrainingSchoolApprovalInfo().subscribe(
      async (data) => {
        this.currentUserApprovingOfficeSequence =
          data.currentUserApprovingOfficeSequence;
        this.allAwaitingApprovals = data.awaitingApproval;
        this.allPickedApprovals = data.pickedApproval.filter(
          (x) => this.DeterminApprovalStatus(x) == true
        );
        this.dueCount = data.dueCount;
        this.notdueCount = data.notDueCount;
        this.overdueCount = data.overDueCount;
        this.fetchingAwaitingApprovals = false;
        this.fetchingPickedApprovals = false;
        this.currentUserApprovingOfficeID = data.currentUserApprovingOfficeId;

        this.allApprovalOffices = data.approvingOffices;
        this.allApprovalLogs = [];
        data.approvingOffices.forEach((office) => {
          this.allApprovalLogs.push({
            approvingOfficeId: office.id,
            approvingOffice: office,
          });
        });
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all training schools in approval phase at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
        this.fetchingAwaitingApprovals = false;
        this.fetchingPickedApprovals = false;
      }
    );
  }

  DeterminApprovalStatus(data: TrainingSchool): boolean {
    this.allApprovalDetails = [];
    data.students.forEach((student) => {
      if (
        !student.approvals.find(
          (x) =>
            x.approvingOffice.sequence ==
            this.currentUserApprovingOfficeSequence
        )
      ) {
        if (this.currentUserApprovingOfficeSequence > 1) {
          let prevSequence = this.currentUserApprovingOfficeSequence - 1;
          if (
            student.approvals.find(
              (x) => x.approvingOffice.sequence == prevSequence
            )
          ) {
            this.allApprovalDetails.push(student);
          }
        } else {
          if (student.approvals.length <= 0)
            this.allApprovalDetails.push(student);
        }
      }
    });

    return this.allApprovalDetails.length > 0;
  }

  PickBatchToActOn(data: TrainingSchool) {
    this.confirmationService.confirm({
      message:
        "Once you pick this request for approval, You will be solely responsible for approving any students under this training school for your approval office. Do you still wish to proceed?",
      accept: () => {
        this.messageService.add({
          severity: "info",
          summary: "Notice",
          detail: "Picking Training School For Approval...",
        });

        this.employmentService.PickTrainingSchoolForApproval(data.id).subscribe(
          async () => {
            await this.messageService.add({
              severity: "success",
              summary: "Completed",
              detail: "Training School Approval Picked Successfully!",
            });

            let awaitingApproval = this.allAwaitingApprovals.find(
              (x) => x.id == data.id
            );
            let index = this.allAwaitingApprovals.indexOf(awaitingApproval);
            if (index > -1) {
              this.allAwaitingApprovals.splice(index, 1);
            }
            this.allPickedApprovals.push(awaitingApproval);
            this.showApprovingDetails = false;
          },
          (error) => {
            console.log("Error: " + JSON.stringify(error));
            this.messageService.add({
              severity: "error",
              summary: "Notice",
              detail:
                "Unable to pick training school for approval at the moment.. Reason: [" +
                error
                  ? error.error.message
                  : "request failed - permission" + "]",
            });
          }
        );
      },
    });
  }

  PickBatchToLoad(data: TrainingSchool) {
    this.allApprovalDetails = [];
    data.students.forEach((student) => {
      if (
        !student.approvals.find(
          (x) =>
            x.approvingOffice.sequence ==
            this.currentUserApprovingOfficeSequence
        )
      ) {
        if (this.currentUserApprovingOfficeSequence > 1) {
          let prevSequence = this.currentUserApprovingOfficeSequence - 1;
          if (
            student.approvals.find(
              (x) => x.approvingOffice.sequence == prevSequence
            )
          ) {
            this.allApprovalDetails.push(student);
          }
        } else {
          if (student.approvals.length <= 0)
            this.allApprovalDetails.push(student);
        }
      }
    });
    this.trainingSchoolInView = data;
    this.showApprovingDetails = true;
  }

  GetApprovalDetailStatus(data: Student): boolean {
    let approval = data.approvals.find(
      (log) => log.approvingOfficeId == this.currentUserApprovingOfficeID
    );
    if (approval) return true;
    else return false;
  }

  ApproveStudent(data: Student) {
    this.confirmationService.confirm({
      message:
        "You are about to approve this student. Once you approve this student profile, it's irreversible. Do you still wish to proceed?",
      accept: () => {
        this.messageService.add({
          severity: "info",
          summary: "Notice",
          detail: "Approving Student...",
        });

        this.employmentService.ApproveTrainingSchoolStudent(data.id).subscribe(
          async () => {
            await this.messageService.add({
              severity: "success",
              summary: "Completed",
              detail: "Training School Student Approved Successfully!",
            });

            this.showApprovingDetails = false;
            //this.showApprovingOffices = false;
            this.fetchingAwaitingApprovals = true;
            this.fetchingPickedApprovals = true;
            this.GetAllTrainigSchoolInApprovalPhase();
          },
          (error) => {
            console.log("Error: " + JSON.stringify(error));
            this.messageService.add({
              severity: "error",
              summary: "Notice",
              detail:
                "Unable to approve training school student at the moment.. Reason: [" +
                error
                  ? error.error.message
                  : "request failed - permission" + "]",
            });
          }
        );
      },
      reject: () => {
        this.showApprovingDetails = false;
      },
    });
  }

  ApproveAllStudents() {
    if (!this.trainingSchoolInView) {
      this.messageService.add({
        severity: "error",
        summary: "Notice",
        detail: "Err: No Batch Selected Yet!",
      });
      return;
    }

    this.confirmationService.confirm({
      message:
        "You are about to approve all student in this training school. Once you perform this action, it's irreversible. Do you still wish to proceed?",
      accept: () => {
        this.messageService.add({
          severity: "info",
          summary: "Notice",
          detail: "Approving All Student...",
        });

        this.employmentService
          .ApproveAllTrainingSchoolStudents(this.trainingSchoolInView.id)
          .subscribe(
            async () => {
              await this.messageService.add({
                severity: "success",
                summary: "Completed",
                detail: "Training School Student Approved Successfully!",
              });

              this.showApprovingDetails = false;
              this.showApprovingOffices = false;
              this.fetchingAwaitingApprovals = true;
              this.fetchingPickedApprovals = true;
              this.GetAllTrainigSchoolInApprovalPhase();
            },
            (error) => {
              console.log("Error: " + JSON.stringify(error));
              this.messageService.add({
                severity: "error",
                summary: "Notice",
                detail:
                  "Unable to approve all training school student(s) at the moment.. Reason: [" +
                  error
                    ? error.error.message
                    : "request failed - permission" + "]",
              });
            }
          );
      },
    });
  }
}
