import { Component, OnInit } from '@angular/core';
import {BreadcrumbService} from '../../../../breadcrumb.service';
import {FormBuilder} from '@angular/forms';
import {ServiceAssignmentService} from '../../../../services/armada/service-assignment.service';
import {MessageService} from 'primeng/api';
import {ServiceAssignment} from '../../../../interfaces/armada';

@Component({
  selector: 'app-my-completed-journey',
  templateUrl: './my-completed-journey.component.html',
  styleUrls: ['./my-completed-journey.component.scss']
})
export class MyCompletedJourneyComponent implements OnInit {
  serviceAssignments: ServiceAssignment[];
  loadingAssignment: boolean;
  selectedAssignment: ServiceAssignment;
  showAssignment: boolean;

  constructor(
      private breadcrumbService: BreadcrumbService,
      private formBuilder: FormBuilder,
      private serviceAssignmentServices: ServiceAssignmentService,
      private messageService: MessageService
  ) {
    this.breadcrumbService.setItems([
      { label: 'Secured Mobility Administration' },
      { label: 'My Completed Journey' }
    ])
  }

  ngOnInit(): void {
    this.fetchCommanderJourney();
  }

  fetchCommanderJourney(){
    this.loadingAssignment = true;
    this.serviceAssignmentServices.getMyCompletedJourneys()
        .pipe()
        .subscribe((result) =>{
          this.serviceAssignments = result;
          this.loadingAssignment = false;
            }
        )
  }

  OpenAssignment(serviceAssignment: ServiceAssignment) {
    this.showAssignment = true;
    this.selectedAssignment = serviceAssignment;
  }
}
