import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {Observable} from 'rxjs';
import {CommonResponse} from '../interfaces/home';
import { shareReplay } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class LeadContactService {

  observablesCache: any = {};

  constructor(private http: HttpClient) { }
  baseUrl = environment.baseUrl+'Lead/';
  // fetch all LeadContact in the database
  allLeadContact(): Observable<CommonResponse>{
    return this.http.get<CommonResponse>(this.baseUrl);
  }
  getLeadContact(id: any):Observable<CommonResponse>{
    if(this.observablesCache[id]) return this.observablesCache[id]
    else {
      this.observablesCache[id] = this.http.get<CommonResponse>(this.baseUrl +id).pipe(shareReplay()); 
      return this.observablesCache[id];
    }
  }
  postLeadContact(id, data: any):Observable<CommonResponse>{
    return this.http.post<CommonResponse>(this.baseUrl + id + '/LeadContact', data);
  }
  deleteLeadContact(id: any){
    return this.http.delete(this.baseUrl +id);
  }
  updateLeadContact(leadId: number, leadContactId: number, data: any):Observable<CommonResponse>{
    return this.http.put<CommonResponse>(this.baseUrl + leadId + '/LeadContact/' + leadContactId, data);
  }
}
