import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import {
  BankAccount,
  CreateBankAccountVM,
  UpdateBankAccountVM,
} from "../interfaces/finance-expense";
import { CommonResponse } from "../interfaces/home";

@Injectable({
  providedIn: "root",
})
export class BankAccountService {
  baseUrl = environment.baseUrl + "BankAccount/";

  constructor(private http: HttpClient) {}

  GetAllBankAccounts(): Observable<CommonResponse<BankAccount[]>> {
    return this.http.get<CommonResponse<BankAccount[]>>(
      this.baseUrl + "GetAllBankAccounts"
    );
  }

  CreateBankAccount(
    data: CreateBankAccountVM
  ): Observable<CommonResponse<boolean>> {
    return this.http.post<CommonResponse<boolean>>(
      this.baseUrl + "CreateBankAccount",
      data
    );
  }

  UpdateBankAccount(
    id: number,
    data: UpdateBankAccountVM
  ): Observable<CommonResponse<boolean>> {
    return this.http.put<CommonResponse<boolean>>(
      this.baseUrl + "UpdateBankAccount/" + id,
      data
    );
  }

  DeleteBankAccount(id: number): Observable<CommonResponse<boolean>> {
    return this.http.delete<CommonResponse<boolean>>(
      this.baseUrl + "DeleteBankAccount/" + id
    );
  }
}
