import {
  InitiatePayrollRunVM,
  PayGroup,
  PayrollRunStatusEnum,
  PayrollRunTypeEnum,
  SelectedPeriodRuns,
} from "./../../../interfaces/payroll";
import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { ConfirmationService, MessageService } from "primeng/api";
import { BreadcrumbService } from "src/app/breadcrumb.service";
import {
  PayrollPayPeriodsAvailableVM,
  PayrollRun,
} from "src/app/interfaces/payroll";
import { PayrollService } from "src/app/services/payroll.service";
import { environment } from "src/environments/environment";
import { DeployedGuard } from "src/app/interfaces/employment";

@Component({
  selector: "app-payroll-runs",
  templateUrl: "./payroll-runs.component.html",
  styleUrls: ["./payroll-runs.component.scss"],
})
export class PayrollRunsComponent implements OnInit {
  @ViewChild("formWrapper") public formWrapper: ElementRef;
  cautionForm: FormGroup;
  fetchingLastPayrollRuns: boolean = true;
  allLast5PayrollRuns: PayrollRun[];
  selectedLast5PayrollRuns: PayrollRun[];
  allPayPeriods: PayrollPayPeriodsAvailableVM[];
  thePayPeriod: PayrollPayPeriodsAvailableVM;
  allPayGroups: PayGroup[];
  selectedPayPeriodRuns: SelectedPeriodRuns[] = [];
  payrollRunsInProgress: PayrollRun[];
  eligibleOPs: number;
  uneligibleOPs: number;
  openCautionDialogue: boolean;
  cautionText: string;
  cautionCode: number;
  theProcessToRun: SelectedPeriodRuns;
  openPayrollResultDialogue: boolean;
  allOperativesInPayrollRunResult: DeployedGuard[] = [];
  theOperativeInPayrollRunResult: DeployedGuard;
  loadingOPInViewPayrollResult: boolean;
  OPInViewPayrollResultAllowances: {
    name: string;
    data: string;
  }[] = [];
  OPInViewPayrollResultDeductions: {
    name: string;
    data: string;
  }[] = [];
  totalAllowance: number;
  totalDeduction: number;
  netPay: number;
  payrunRunInView: PayrollRun;

  constructor(
    private fb: FormBuilder,
    private payrollService: PayrollService,
    private breadcrumbService: BreadcrumbService,
    public confirmationService: ConfirmationService,
    public messageService: MessageService
  ) {
    this.cautionForm = fb.group({});
  }

  ngOnInit(): void {
    this.breadcrumbService.setItems([
      {
        label: "Payroll Management",
        routerLink: ["/home/payroll/payroll-settings"],
      },
      {
        label: "Payroll Runs",
        routerLink: ["/home/payroll/runs"],
      },
    ]);

    this.FetchAnalytics();
  }

  FetchAnalytics() {
    this.payrollService.GetPayrollRunsAnalytics().subscribe(
      async (data) => {
        this.eligibleOPs = data.opsEligibleForPayroll;
        this.uneligibleOPs = data.opsNotEligibleForPayroll;
        if (!this.thePayPeriod) this.allPayPeriods = data.payPeriods;
        this.allLast5PayrollRuns = data.last5PayrollRuns;
        this.allPayGroups = data.payGroups;
        this.fetchingLastPayrollRuns = false;
        this.selectedPayPeriodRuns = [];
        this.allPayGroups.forEach((payGrp) => {
          this.selectedPayPeriodRuns.push({
            payGroup: payGrp.caption,
            payGroupId: payGrp.id,
            trialRunCutOffDay:
              payGrp.trialRunCutOffDay +
              this.GetDateDaySuffix(payGrp.trialRunCutOffDay),
            finalRunCutOffDay:
              payGrp.payrollCutoffDay +
              this.GetDateDaySuffix(payGrp.payrollCutoffDay),
          });
        });
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to get analytics at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
        this.fetchingLastPayrollRuns = false;
      }
    );
  }

  ViewPayollRun(item: PayrollRun) {
    this.openPayrollResultDialogue = true;
    this.allOperativesInPayrollRunResult = [];
    this.theOperativeInPayrollRunResult = null;
    this.payrollService.GetPayrollRunOPs(item.id).subscribe(
      async (data) => {
        data.forEach((operative) => {
          operative.jobApplication.personalInformation.fullname =
            operative.jobApplication.personalInformation.lastname +
            " " +
            operative.jobApplication.personalInformation.firstname;
          this.allOperativesInPayrollRunResult.push(operative);
        });
        this.payrunRunInView = item;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to get payroll run OPs at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  PayPeriodSelectionLoader() {
    if (this.thePayPeriod) {
      this.payrollService
        .GetPayrollRunsWithinDatePeriod(this.thePayPeriod.dateAppened)
        .subscribe(
          async (data) => {
            this.payrollRunsInProgress = data;
          },
          (error) => {
            console.log("Error: " + JSON.stringify(error));
            this.messageService.add({
              severity: "error",
              summary: "Notice",
              detail:
                "Unable to get payroll runs within the selected period at the moment.. Reason: [" +
                error
                  ? error.error.message
                  : "request failed - permission" + "]",
            });
          }
        );
    }
  }

  GetDateDaySuffix(day: number): string {
    switch (day) {
      case 1:
      case 21:
      case 31:
        return "st";
      case 2:
      case 22:
        return "nd";
      case 3:
      case 23:
        return "rd";
      default:
        return "th";
    }
  }

  StartTrialRun(item: SelectedPeriodRuns) {
    this.cautionText =
      "Are you sure you want to run trial payroll run. This will start a trial process of the payroll. Do you still wish to proceed?";
    this.cautionCode = 1;
    this.theProcessToRun = item;
    this.openCautionDialogue = true;
  }

  StartFinalRun(item: SelectedPeriodRuns) {
    this.cautionText =
      "Are you sure you want to run final payroll run. This will start a final process of the payroll. Do you still wish to proceed?";
    this.cautionCode = 2;
    this.theProcessToRun = item;
    this.openCautionDialogue = true;
  }

  HideCautionDialog() {
    this.openCautionDialogue = false;
    this.cautionCode = null;
    this.cautionText = null;
    this.theProcessToRun = null;
  }

  CautionAction() {
    if (this.cautionCode == 1) {
      this.messageService.add({
        severity: "info",
        summary: "Notice",
        detail: "Initiating Trial Payroll Run...",
      });

      const postData: InitiatePayrollRunVM = {
        payGroupId: this.theProcessToRun.payGroupId,
        runType: this.cautionCode,
        payPeriod: this.thePayPeriod.label,
        payrollPeriod: this.thePayPeriod.dateAppened,
      };

      this.payrollService.InitiatePayrollRun(postData).subscribe(
        async () => {
          this.messageService.add({
            severity: "success",
            summary: "Completed",
            detail: "Trial Payroll Run Initiated Successfully!",
          });

          this.fetchingLastPayrollRuns = true;
          this.theOperativeInPayrollRunResult = null;
          this.allOperativesInPayrollRunResult = [];
          this.HideCautionDialog();
          this.FetchAnalytics();
          this.PayPeriodSelectionLoader();
        },
        (error) => {
          console.log("Error: " + JSON.stringify(error));
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail:
              "Unable to initiate trial payroll run at the moment.. Reason: [" +
              error
                ? error.error.message
                : "request failed - permission" + "]",
          });
        }
      );
    } else if (this.cautionCode == 2) {
      this.messageService.add({
        severity: "info",
        summary: "Notice",
        detail: "Initiating Final Payroll Run...",
      });

      const postData: InitiatePayrollRunVM = {
        payGroupId: this.theProcessToRun.payGroupId,
        runType: this.cautionCode,
        payPeriod: this.thePayPeriod.label,
        payrollPeriod: this.thePayPeriod.dateAppened,
      };

      this.payrollService.InitiatePayrollRun(postData).subscribe(
        async () => {
          this.messageService.add({
            severity: "success",
            summary: "Completed",
            detail: "Final Payroll Run Initiated Successfully!",
          });

          this.theOperativeInPayrollRunResult = null;
          this.allOperativesInPayrollRunResult = [];
          this.fetchingLastPayrollRuns = true;
          this.HideCautionDialog();
          this.FetchAnalytics();
          this.PayPeriodSelectionLoader();
        },
        (error) => {
          console.log("Error: " + JSON.stringify(error));
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail:
              "Unable to initiate final payroll run at the moment.. Reason: [" +
              error
                ? error.error.message
                : "request failed - permission" + "]",
          });
        }
      );
    } else if (this.cautionCode == 3) {
      this.messageService.add({
        severity: "info",
        summary: "Notice",
        detail: "Initiating Payroll Run...",
      });
      this.HideCautionDialog();

      this.payrollService.RunPayrollRunTask().subscribe(
        async () => {
          this.messageService.add({
            severity: "success",
            summary: "Completed",
            detail: "Payroll Run Initiated Successfully...",
          });
          this.fetchingLastPayrollRuns = true;
          this.FetchAnalytics();
        },
        (error) => {
          console.log("Error: " + JSON.stringify(error));
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail:
              "Unable to initiate payroll run at the moment.. Reason: [" + error
                ? error.error.message
                : "request failed - permission" + "]",
          });
        }
      );
    }
  }

  GoToPayrollMGT() {
    window.open(environment.frontednUrl + "#/home/payroll/management", "_self");
  }

  GetPayrollRunTypeLabel(runType: number): string {
    if (runType == PayrollRunTypeEnum.TrialRun) return "TrialRun";
    if (runType == PayrollRunTypeEnum.FinalRun) return "FinalRun";
    return "N/A";
  }

  GetPayrollRunStatusLabel(runStatus: number): string {
    if (runStatus == PayrollRunStatusEnum.NotStarted) return "Not Started";
    if (runStatus == PayrollRunStatusEnum.InProgress) return "In Progress";
    if (runStatus == PayrollRunStatusEnum.Completed) return "Completed";
    if (runStatus == PayrollRunStatusEnum.Cancelled) return "Cancelled";
    return "N/A";
  }

  HideViewPayrollResultDialog() {
    this.openPayrollResultDialogue = false;
    this.payrunRunInView = null;
  }

  OPViewInPayrollSelectionLoader() {
    this.OPInViewPayrollResultAllowances = [];
    this.OPInViewPayrollResultDeductions = [];
    this.totalAllowance = null;
    this.totalDeduction = null;
    this.netPay = null;
    if (this.theOperativeInPayrollRunResult) {
      this.loadingOPInViewPayrollResult = true;
      this.payrollService
        .GetOperativePayslip(
          this.payrunRunInView.id,
          this.theOperativeInPayrollRunResult.id
        )
        .subscribe(
          async (data) => {
            data.allowances.forEach((x) => {
              this.OPInViewPayrollResultAllowances.push({
                name: x.allowanceName,
                data: "N" + x.amount.toLocaleString(),
              });
            });
            data.deductions.forEach((x) => {
              this.OPInViewPayrollResultDeductions.push({
                name: x.deductionName,
                data: "N" + x.amount.toLocaleString(),
              });
            });
            if (data.totalDaysAbsent > 0) {
              this.OPInViewPayrollResultDeductions.push({
                name: "Absence Deduction",
                data: "N" + data.absenceDeductions.toLocaleString(),
              });
            }
            this.OPInViewPayrollResultDeductions.push({
              name: "Tax",
              data: "N" + data.appliedTaxAmount.toLocaleString(),
            });
            data.taxes.forEach((x) => {
              this.OPInViewPayrollResultDeductions.push({
                name: x.taxName,
                data: "N" + x.amount.toLocaleString(),
              });
            });

            this.totalAllowance = data.grossPayFigure;
            this.totalDeduction = data.totalDeductionsFigure;
            this.netPay = data.netPayFigure;
            this.loadingOPInViewPayrollResult = false;
          },
          (error) => {
            console.log("Error: " + JSON.stringify(error));
            this.messageService.add({
              severity: "error",
              summary: "Notice",
              detail:
                "Unable to load OP payslip at the moment.. Reason: [" + error
                  ? error.error.message
                  : "request failed - permission" + "]",
            });
            this.loadingOPInViewPayrollResult = false;
          }
        );
    }
  }

  RunPayrollJob() {
    this.cautionText =
      "You are about to initiate a payroll run. This is an irreversible action. Do you still wish to proceed?";
    this.cautionCode = 3;
    this.openCautionDialogue = true;
  }
}
