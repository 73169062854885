import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { CommonResponse, QuoteServiceDocument } from "../../../interfaces/home";
import { DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";
import { MessageService } from "primeng/api";
import { EventInput } from "@fullcalendar/core";
import { DeliverableFulfillmentService } from "../../../services/deliverable-fulfillment.service";
import { FileStorageService } from "src/app/services/file-storage.service";

@Component({
  selector: "app-close-deliverable",
  templateUrl: "./close-deliverable.component.html",
  styleUrls: ["./close-deliverable.component.scss"],
})
export class CloseDeliverableComponent implements OnInit {
  deliverableClosureForm: FormGroup;
  displayLoading: boolean;
  public documentFIle: any;
  private imageSrc: string;
  public documentDownloadURL: any;
  private fb: any;
  public uploadStatus: string;
  deliverableSummaryData: any;
  message: { type: string; value: string };
  referenceNumber = "";

  constructor(
    public modalRef: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private formBuilder: FormBuilder,
    private fileStorageService: FileStorageService,
    public messageService: MessageService,
    private deliverableFulfillmentService: DeliverableFulfillmentService
  ) {
    this.displayLoading = false;
  }

  ngOnInit(): void {
    this.deliverableSummaryData = this.config.data;
    if (this.deliverableSummaryData) {
    }
    this.deliverableClosureForm = this.formBuilder.group({
      refNo: [""],
      documentUrl: [""],
    });
  }
  async submitServiceDocument() {
    const _formValue = this.deliverableClosureForm.value;
    if (_formValue) {
      // check if picture is selected
      // this.displayLoading = true;
      // check if any of the input
      if (_formValue.refNo || _formValue.documentUrl) {
        if (this.documentFIle) {
          this.fileStorageService.UploadMultipleFilesFromDataUrl([
            this.documentFIle,
          ]);
          this.fileStorageService.onUploadFinished.subscribe(
            (resp: CommonResponse<string[]>) => {
              if (resp.responseCode != "00") {
                this.messageService.add({
                  severity: "error",
                  summary: "Notice",
                  detail: resp.responseMsg,
                });
              } else {
                this.documentDownloadURL = resp.responseData[0];
                this.saveServiceDocument(this.deliverableSummaryData);
              }
            },
            (error) => {
              console.log("Error while uploading files " + error);
              this.messageService.add({
                severity: "error",
                summary: "Notice",
                detail: "ERR: Unable to upload image to storage",
              });
            }
          );
        } else {
          await this.saveServiceDocument(this.deliverableSummaryData);
        }
      } else {
        this.message = {
          type: "danger",
          value: "at least one of the information is required",
        };
        this.displayLoading = false;
      }
    }
  }
  async saveServiceDocument(documentSummaryId) {
    const postData = {
      deliverableCompletionReferenceUrl: this.documentDownloadURL,
      deliverableCompletionReferenceNo: this.deliverableClosureForm.value.refNo,
    };
    this.deliverableFulfillmentService
      .setRequestForValidation(documentSummaryId, postData)
      .subscribe((res) => {
        this.displayLoading = false;
        this.uploadStatus = "complete";
        this.message = { type: "success", value: "Document uploaded" };
        this.modalRef.close("complete");
      });
  }

  onFileSelect(event) {
    if (!this.checkMimeType(event) || !this.checkFileSize(event)) {
      this.deliverableClosureForm.get("documentUrl").reset();
      return;
    }
    const reader = new FileReader();
    this.message = null;
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      this.documentFIle = event.target.files[0];
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.imageSrc = reader.result as string;
      };
    }
  }

  checkMimeType(event) {
    const files = event.target.files[0];
    // list allow mime type
    const types = [
      "application/msword",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      "application/pdf",
      "image/jpeg",
      "image/png",
      "image/jpg",
    ];
    // loop access array
    if (!files) {
      this.messageService.add({
        severity: "error",
        summary: "Failed",
        detail: "Please select a file to continue",
      });
      this.message = {
        type: "danger",
        value: "Please select a file to continue",
      };
      return false;
    }
    // compare file type find doesn't match
    if (types.every((type) => files.type !== type)) {
      // create error message and assign to container
      this.messageService.add({
        severity: "error",
        summary: "Failed",
        detail: "Document format not supported",
      });
      this.message = { type: "danger", value: "Document format not supported" };
      return false;
    }
    return true;
  }

  checkFileSize(event: EventInput) {
    const files = event.target.files[0];
    if (!files) return true;
    const size = 1500000;
    if (files.size > size) {
      event.target.value = null;
      this.messageService.add({
        severity: "error",
        summary: "Failed",
        detail: "Document is too large, please pick a smaller file",
      });
      this.message = {
        type: "danger",
        value: "Document is too large, please pick a smaller file",
      };
      return false;
    }
    return true;
  }
}
