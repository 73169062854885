import { Injectable } from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {SituationReport} from '../../interfaces/armada';

@Injectable({
  providedIn: 'root'
})
export class SituationReportService {
  baseUrl = environment.armadaBaseUrl+'SituationReport/';
  constructor(private httpClient: HttpClient) { }
  allSituationReport(): Observable<SituationReport>{
    return this.httpClient.get<SituationReport>(this.baseUrl);
  }
  getSituationReport(id: any):Observable<SituationReport>{
    return this.httpClient.get<SituationReport>(this.baseUrl +id);
  }
  getSituationReportByName(name: any):Observable<SituationReport>{
    return this.httpClient.get<SituationReport>(this.baseUrl + 'name/' + name);
  }
  postSituationReport(data: any){
    return this.httpClient.post(this.baseUrl, data);
  }
  delete(id: any){
    return this.httpClient.delete(this.baseUrl +id);
  }
  updateSituationReport(id: any, data: any){
    return this.httpClient.put(this.baseUrl +id, data);
  }
  getByContractService(contractServiceId: number):Observable<SituationReport[]>{
    return this.httpClient.get<SituationReport[]>(`${this.baseUrl}GetByContractService/${contractServiceId}`);
  }
}
