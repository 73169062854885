import { ViewUsageComponent } from "./view-usage/view-usage.component";
import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { ConfirmationService, Message, MessageService } from "primeng/api";
import {
  Account,
  BulkUploadDetail,
  BulkUploadVM,
  CommonResponse,
  Contract,
  ContractService,
  CustomerDivision,
  GroupContractCategory,
  Invoice,
} from "../../../interfaces/home";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { EventInput } from "@fullcalendar/core";
import { environment } from "../../../../environments/environment";
import { AccountVoucherTypeService } from "../../../services/account-voucher-type.service";
import { CustomerDivisionService } from "../../../services/customer-division.service";
import { InvoiceService } from "../../../services/invoice.sevice";
import { AccountService } from "../../../services/account.service";
import { ReceiptService } from "../../../services/receipt.service";
import { AngularFireStorage } from "@angular/fire/storage";
import { finalize } from "rxjs/operators";
import { Observable } from "rxjs";
import { BreadcrumbService } from "src/app/breadcrumb.service";
import { DialogService, DynamicDialogRef } from "primeng/dynamicdialog";
import { AdvancePaymentsService } from "src/app/services/advance-payments.service";
import { SubletPaymentComponent } from "./sublet-payment/sublet-payment.component";
import { CurrencyExchangeRate } from "src/app/interfaces/currency";
import { CurrencyService } from "src/app/services/currency.service";
import isThisHour from "date-fns/isThisHour";
import { FileStorageService } from "src/app/services/file-storage.service";
import { AdvancePaymentViewComponent } from "./advance-payment-view/advance-payment-view.component";
import { SharedService } from "src/app/services/shared.service";

@Component({
  selector: "app-advance-payment",
  templateUrl: "./advance-payment.component.html",
  styleUrls: ["./advance-payment.component.scss"],
  providers: [MessageService],
})
export class AdvancePaymentComponent implements OnInit {
  invoiceCols: any;
  invoiceExportColumns: any;
  availableCurrency: any[];
  exchangeRate: number | null;
  allExchangeRates: CurrencyExchangeRate[];

  clientForm: FormGroup;
  advancePayForm: FormGroup;
  editingPay: boolean = false;

  customerDivisions: CustomerDivision[];
  clients: CustomerDivision[];
  selectedCustomerDivision: CustomerDivision;
  fetchingContractServices = false;

  invoiceSelected: any;

  yesnoOptions: any[];
  selectedOption: any;
  imageSrc: string;
  isEditing = false;
  file: any[] = [];
  uploadingReceipt = false;
  customerDivisionInformation: any;
  invoiceValueBalance: number;
  invoiceValueBalanceForInvoice: number;
  amount: any;

  downloadURL: Observable<string>;

  viewBreakdownRef: DynamicDialogRef;
  amountPaidPlusWithTaxWithheld: number;
  whtLabel = "WHT Value or %";

  advancePayments: any[] = [];
  fetchingTableData: boolean = false;
  selectedPay: any;
  submitted: boolean = false;
  completedLabel = "Yes";
  notCompletedLabel = "No";
  cashBookAccounts: Account[];
  @ViewChild("fileInput") fileInput: ElementRef;
  exchangeRateId: any;
  fetchingExchangeRates: boolean;
  fetchingCurrencies: boolean;
  totalAmountReceived: void;
  wht?: any;
  isTaxWitheld: boolean = false;
  isFixedWHT: boolean = false;
  withHeld: number;
  amountReceived: any;
  valueOfWHT?: number;
  //isTaxWitheld: boolean;
  bulkfile: any;
  bulkUploadContents: BulkUploadDetail[] = [];
  bulkCols: any[];
  msg: Message[] = [];
  processingBulkUpload: boolean;
  showCustDits: boolean = true;
  bulkUploadImagerSrc: string;
  bulkUploadFile: any;
  showUpls: boolean = true;
  uploadedUrl: string;
  isNewUpload: boolean;
  openErrorDialogue: boolean;
  errorLists: {
    sn: number;
    reason: string;
    data: string;
  }[] = [];
  errorCols: any[];

  constructor(
    public formBuilder: FormBuilder,
    public messageService: MessageService,
    public customerDivisionService: CustomerDivisionService,
    public accountVoucherTypeService: AccountVoucherTypeService,
    public invoiceService: InvoiceService,
    public accountService: AccountService,
    public receiptService: ReceiptService,
    public fireStorage: AngularFireStorage,
    public dialogService: DialogService,
    public confirmationService: ConfirmationService,
    private breadcrumbService: BreadcrumbService,
    private advancePaymentService: AdvancePaymentsService,
    private currency: CurrencyService,
    private fileStorageService: FileStorageService,
    private sharedService: SharedService
  ) {}

  ngOnInit(): void {
    this.breadcrumbService.setItems([
      { label: "Finance", routerLink: ["home/advance-payment"] },
      { label: "Advance Payment", routerLink: ["home/advance-payment"] },
    ]);

    this.getCustomerDivisions();
    this.getCashBookAccounts();

    this.clientForm = this.formBuilder.group({
      customerId: [null, Validators.required],
    });

    this.advancePayForm = this.formBuilder.group({
      amount: ["", [Validators.min(1), Validators.required]],
      evidenceOfPaymentUrl: [""],
      description: ["", Validators.required],
      CustomerDivisionId: [""],
      id: [0],
      cashAccount: ["", Validators.required],
      referenceNumber: ["", Validators.required],
      balanceInclusiveOfWHT: ["", Validators.required],
      wht: [""],
      whtOption: ["0"],
      localCurrrency: [""],
      selectedQuoteCurrency: [""],
      rateApplied: [""],
      quoteAmount: [""],
      FundReceivedDate: ["", Validators.required],
      vatOutput: [""],
      onePercent: [""],
      //valueOfWHT: [""],
      //isTaxWitheld: [""],
      //isFixedWHT: [""],
    });

    this.bulkCols = [
      { field: "sn", header: "S/N" },
      { field: "clientName", header: "Client Name" },
      { field: "description", header: "Description" },
      { field: "amount", header: "Amount" },
      { field: "accountNumber", header: "Account Number" },
      { field: "referenceNo", header: "Reference No" },
      { field: "isTaxWitheld", header: "Is Tax Witheld" },
      { field: "isFixedWHT", header: "Is Fixed WHT" },
      { field: "valueOfWHT", header: "Value of WHT" },
      { field: "fundReceivedDate", header: "Fund Received Date" },
    ];

    this.errorCols = [
      { field: "sn", header: "S/N" },
      { field: "reason", header: "Error" },
      { field: "data", header: "Data" },
    ];

    this.yesnoOptions = [{ val: "Yes" }, { val: "No" }];

    this.ResetMessageToasters();
  }

  ResetMessageToasters() {
    this.msg = [];
    this.msg.push({
      severity: "info",
      summary: "Notice:",
      detail:
        "Please While Upload & Processing Operation is ongoing, do not leave this page until its completed.",
    });
  }

  amtWhtChanged() {
    var option = this.advancePayForm.get("whtOption").value;
    this.whtLabel = option == 1 ? "WHT value" : "WHT %";

    this.amountReceived = this.advancePayForm.get("amount").value;
    this.wht = this.advancePayForm.get("wht").value;

    if (!this.amountReceived) return;

    var amtRecvd = parseFloat(this.amountReceived);
    // const invoiceValueBalance = this.invoiceSelected?.value - this.invoiceSelected?.totalAmountReceipted;

    if (this.selectedOption && this.selectedOption.val === "Yes" && this.wht) {
      this.isTaxWitheld = true;
      if (option == 1) {
        this.isFixedWHT = true; //********* */
        this.amountPaidPlusWithTaxWithheld = Number.parseFloat(
          (amtRecvd + this.wht).toFixed(2)
        );

        this.withHeld = Number.parseFloat(
          (this.amountPaidPlusWithTaxWithheld - amtRecvd).toFixed(2)
        );
      } else if (option == 2) {
        this.isFixedWHT = false; //********** */
        const wht = this.wht / 100;

        this.amountPaidPlusWithTaxWithheld = Number.parseFloat(
          (amtRecvd + (amtRecvd * wht) / (1 - wht)).toFixed(2)
        );

        this.withHeld = Number.parseFloat(
          (this.amountPaidPlusWithTaxWithheld - amtRecvd).toFixed(2)
        );
      }
    } else {
      this.isTaxWitheld = false;
      this.withHeld = 0;
      this.amountPaidPlusWithTaxWithheld = 0;
    }
  }

  onSelectCurrency() {
    //amountReceived
    this.exchangeRateId = null;
    var quoteAmount = this.advancePayForm.get("quoteAmount").value;
    var selectedBaseCurrency = this.advancePayForm.get("localCurrrency").value;
    var selectedQuoteCurrency = this.advancePayForm.get(
      "selectedQuoteCurrency"
    ).value;

    if (!selectedBaseCurrency) {
      return;
    }

    if (!selectedQuoteCurrency) {
      return;
    }

    var exchangeRecord = this.allExchangeRates.find(
      (x) =>
        x.baseCurrencyId == selectedQuoteCurrency.id &&
        x.quoteCurrencyId == selectedBaseCurrency.id
    );

    if (exchangeRecord) {
      this.exchangeRateId = exchangeRecord.id;
      this.exchangeRate = exchangeRecord?.exchangeRate;
    }

    if (quoteAmount && exchangeRecord) {
      this.advancePayForm.patchValue({
        amount: Number.parseFloat((quoteAmount * this.exchangeRate).toFixed(2)),
      });
    } else {
      this.exchangeRate = null;
      this.advancePayForm.patchValue({
        amount: "",
      });
    }
    this.totalAmountReceived = this.advancePayForm.get("amount").value;
  }

  FetchAllExchangeRates() {
    this.fetchingExchangeRates = true;
    this.currency.GetAllCurrencyExchangeRates().subscribe(
      async (data) => {
        this.allExchangeRates = data;
        this.allExchangeRates.reverse();
        // this.receiptEntryForm
        //   .get("rateApplied")
        //   .setValue(this.allExchangeRates);
        this.fetchingExchangeRates = false;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all exchange rates at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
        this.fetchingExchangeRates = false;
      }
    );
  }

  getCurrency() {
    this.fetchingCurrencies = true;
    this.currency.GetAllCurrencies().subscribe(
      async (res: any) => {
        this.availableCurrency = res;
        this.fetchingCurrencies = false;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all currencies at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
        this.fetchingCurrencies = false;
      }
    );
  }

  getCustomerDivisions() {
    this.customerDivisionService.allCustomerDivision().subscribe(
      async (res: CommonResponse) => {
        this.customerDivisions = res.responseData;
      },
      (error) => {
        this.messageService.add({
          severity: "error",
          summary: "Failed",
          detail: "Connection error, Try again later",
        });
      }
    );
  }

  async loadCustomerInformation() {
    this.showCustDits = false;
    if (this.selectedCustomerDivision) {
      this.customerDivisionInformation = this.selectedCustomerDivision;
      this.fetchingContractServices = false;
      this.getCustomerAdvancePayment(this.selectedCustomerDivision.id);
    }
    await new Promise((resolve) => setTimeout(resolve, 2000)); // 2 secs
    this.showCustDits = true;
    this.getCurrency();
    this.FetchAllExchangeRates();
    this.sharedService.sendClickEvent();
  }

  submitUpdatePayment() {
    this.submitted = true;
    if (
      !this.advancePayForm.get("amount").value ||
      !this.advancePayForm.get("description").value
    ) {
      this.messageService.add({
        severity: "error",
        summary: "Failed",
        detail: "Some fields are missing",
      });
      return;
    }

    var postData = this.advancePayForm.value;
    postData.CustomerDivisionId = this.selectedCustomerDivision?.id;
    postData.evidenceOfPaymentUrl = this.selectedPay.evidenceOfPaymentUrl;
    postData["referenceNumber"] =
      this.advancePayForm.get("referenceNumber").value;
    var id = this.selectedPay?.id;

    this.advancePaymentService.update(id, postData).subscribe(
      async (res: CommonResponse) => {
        if (res.responseCode == "00") {
          await this.messageService.add({
            severity: "success",
            summary: "Updated",
            detail: "Payment updated successfully.",
          });
          this.invoiceSelected = null;
          this.advancePayForm.reset();
          this.submitted = false;
          this.closeEditing();
          this.loadCustomerInformation();
        } else {
          await this.messageService.add({
            severity: "error",
            summary: "Failure",
            detail: res.responseMsg,
          });
        }
      },
      (error) => {
        this.messageService.add({
          severity: "error",
          summary: "Failed",
          detail: "Error updating payment. Try again later.",
        });
      }
    );
  }

  viewUsage(pay) {
    // var pay = this.advancePayments.find((x) => x.id == id);
    this.viewBreakdownRef = this.dialogService.open(ViewUsageComponent, {
      width: "950px",
      contentStyle: { "max-width": "100vw", height: "70vh", overflow: "auto" },
      baseZIndex: 100,
      data: { pay: pay },
    });

    this.viewBreakdownRef.onClose.subscribe(() => {});
  }

  viewAdvancePay(pay) {
    // var pay = this.advancePayments.find((x) => x.id == id);
    this.viewBreakdownRef = this.dialogService.open(
      AdvancePaymentViewComponent,
      {
        width: "950px",
        contentStyle: {
          "max-width": "100vw",
          height: "70vh",
          overflow: "auto",
        },
        baseZIndex: 100,
        data: { pay: pay },
      }
    );

    this.viewBreakdownRef.onClose.subscribe(() => {});
  }

  sublet(pay) {
    var usedTotal = pay.advancePaymentUsages.reduce((a, b) => {
      return a + b.amountUsed;
    }, 0);

    if (usedTotal.toFixed(2) == pay.amount.toFixed(2)) {
      this.messageService.add({
        severity: "error",
        summary: "Used up",
        detail: "Your advance payment has been used up",
      });
      return;
    }

    //SubletPaymentComponent
    var payobj = {
      advanceTotal: pay.amount,
      amountUsed: usedTotal,
      advancePaymentId: pay.id,
      subleterId: this.selectedCustomerDivision.id,
    };

    this.viewBreakdownRef = this.dialogService.open(SubletPaymentComponent, {
      header: "Sublet Advance Payment",
      width: "900px",
      contentStyle: {
        "min-height": "300px",
        height: "60vh",
        overflow: "auto",
      },
      baseZIndex: 10000,
      data: {
        paybody: payobj,
        clients: this.clients,
      },
    });

    this.viewBreakdownRef.onClose.subscribe((res: boolean) => {
      if (res) {
        this.getCustomerAdvancePayment(this.selectedCustomerDivision.id);
      }
    });
  }

  closeEditing() {
    this.editingPay = false;
    this.advancePayForm.reset();
    this.selectedPay = null;
    //this.fileInput.nativeElement.value = '';
  }

  getCustomerAdvancePayment(customerDivisionId: any) {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Fetching customer advance payments",
    });

    this.advancePaymentService.getAdvancePayment(customerDivisionId).subscribe(
      async (r: CommonResponse) => {
        // this.fetchingContractServices = false;
        if (r.responseCode == "00") {
          var result = r.responseData;
          this.advancePayments = result;
          this.clients = this.customerDivisions.filter(
            (x) => x.id != customerDivisionId
          );
        } else {
          this.advancePayments = [];
          this.closeEditing();
        }
      },
      (error) => {
        this.connectionError();
        this.fetchingContractServices = false;
      }
    );
  }

  getCashBookAccounts() {
    this.accountService.getCashBookAccounts().subscribe(
      async (res: CommonResponse) => {
        this.cashBookAccounts = res.responseData;
      },
      (error) => {
        this.messageService.add({
          severity: "error",
          summary: "Failed",
          detail: "Connection error, Try again later",
        });
      }
    );
  }

  handleSubmission() {
    this.submitted = true;
    if (this.advancePayForm.invalid) {
      this.messageService.add({
        severity: "error",
        summary: "Failed",
        detail: "Some fields are missing",
      });
      return;
    }

    this.confirmationService.confirm({
      message: `Are you sure you want to create this advance payment with ${
        this.advancePayForm.get("amount").value
      } ?`,
      accept: () => {
        this.evidenceOfPaymentUrl();
      },
    });
  }

  private connectionError() {
    this.messageService.add({
      severity: "error",
      summary: "Failed",
      detail: "Connection Error, Please try again",
    });
  }

  deletePayment(pay) {
    this.confirmationService.confirm({
      message: `Are you sure you want to delete this payment ?`,
      accept: () => {
        this._deletePayment(pay);
      },
    });
  }

  private _deletePayment(pay) {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Deleting payment record",
    });

    this.advancePaymentService.delete(pay.id).subscribe(
      async (r: CommonResponse) => {
        if (r.responseCode == "00") {
          await this.messageService.add({
            severity: "success",
            summary: "Deleted",
            detail: "Payment record removed",
          });
          await this.loadCustomerInformation();
        } else {
          await this.messageService.add({
            severity: "error",
            summary: "Failure",
            detail: r.responseMsg,
          });
        }
      },
      (error) => {
        this.connectionError();
      }
    );
  }

  evidenceOfPaymentUrl() {
    if (this.file) {
      this.messageService.add({
        severity: "info",
        summary: "Notice",
        detail: "Posting payment...",
      });
      if (this.file.length > 0) {
        this.fileStorageService.UploadMultipleFilesFromDataUrl(this.file);

        this.fileStorageService.onUploadFinished.subscribe(
          (resp: CommonResponse<string[]>) => {
            if (resp.responseCode != "00") {
              this.messageService.add({
                severity: "error",
                summary: "Notice",
                detail: resp.responseMsg,
              });
            } else {
              var imageUrl = resp.responseData;
              this.advancePayForm.patchValue({
                evidenceOfPaymentUrl: imageUrl[0],
              });
              this.postPayment();
            }
          },
          (error) => {
            console.log("Error while creating platform " + error);
            this.messageService.add({
              severity: "error",
              summary: "Notice",
              detail: "ERR: Unable to upload image to storage",
            });
          }
        );
      }
    } else {
      this.messageService.add({
        severity: "info",
        summary: "Notice",
        detail: "Cannot Proceed without payment evidence/image.",
      });
      return;
    }
  }

  async postPayment() {
    var postData = this.advancePayForm.value;
    postData.CustomerDivisionId = this.selectedCustomerDivision?.id;
    postData.id = 0;
    var account = postData.cashAccount?.id;
    postData.accountId = account;

    postData.isTaxWitheld = this.isTaxWitheld;
    postData["isFixedWHT"] = this.isFixedWHT;

    if (this.isTaxWitheld) {
      //postData.amount = this.amountPaidPlusWithTaxWithheld;
      postData.valueOfWHT = this.advancePayForm.get("wht").value;
      var wht = this.amountPaidPlusWithTaxWithheld - this.amountReceived;
      if (wht >= this.amountReceived) {
        await this.messageService.add({
          severity: "error",
          summary: "Failure",
          detail: `WHT amount of NGN${wht} cannot be greater than the advance payment amount of NGN${this.amountReceived}`,
        });
        return;
      }
    } else {
      postData.valueOfWHT = null;
    }

    this.advancePaymentService.post(postData).subscribe(
      async (res: CommonResponse) => {
        if (res.responseCode == "00") {
          await this.messageService.add({
            severity: "success",
            summary: "Posted",
            detail: "Payment posted successfully.",
          });
          this.invoiceSelected = null;
          this.advancePayForm.reset();
          this.submitted = false;
          this.loadCustomerInformation();
        } else {
          await this.messageService.add({
            severity: "error",
            summary: "Failure",
            detail: res.responseMsg,
          });
        }
      },
      (error) => {
        this.messageService.add({
          severity: "error",
          summary: "Failed",
          detail: "Error posting payment. Try again later.",
        });
      }
    );
  }

  onFileSelect(event) {
    if (!this.checkMimeType(event) || !this.checkFileSize(event)) {
      return;
    }

    this.uploadingReceipt = true;
    this.isEditing = true;
    this.advancePayForm.enable();
    const reader = new FileReader();
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      this.file.push(event.target.files[0]);
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.imageSrc = reader.result as string;
        this.advancePayForm.patchValue({
          evidenceOfPaymentUrl: reader.result,
        });
      };
    }
  }

  checkMimeType = (event: EventInput): boolean => {
    const files = event.target.files[0];
    // list allow mime type
    const types = ["image/png", "image/jpeg", "image/jpg", "image/gif"];
    // loop access array
    if (!files) {
      this.messageService.add({
        severity: "error",
        summary: "Failed",
        detail: "Please select an Image file",
      });
      return false;
    }
    // compare file type find doesn't match
    if (types.every((type) => files.type !== type)) {
      // create error message and assign to container
      this.messageService.add({
        severity: "error",
        summary: "Failed",
        detail: "Image format not supported",
      });
      return false;
    }
    return true;
  };

  checkFileSize = (event: EventInput) => {
    const files = event.target.files[0];
    if (!files) return true;
    const size = 400000;
    if (files.size > size) {
      event.target.value = null;
      this.messageService.add({
        severity: "error",
        summary: "Failed",
        detail: "Image is too large, please pick a smaller file",
      });
      return false;
    }
    return true;
  };

  onBulkFileSelect(event) {
    this.bulkUploadContents = [];
    this.errorLists = [];
    this.file = null;
    if (!this.checkMimeType2(event) || !this.checkFileSize2(event)) {
      return;
    }

    const reader = new FileReader();
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      this.file = event.target.files[0];
      reader.readAsText(file);
      reader.onload = () => {
        // Entire file
        const text = reader.result as string;
        // By lines
        var lines = text.split("\n");
        for (var line = 0; line < lines.length; line++) {
          let lineStrArr = lines[line].split(",");
          try {
            let clientInfo = this.customerDivisions.find(
              (x) => x.divisionName.toLowerCase() == lineStrArr[0].toLowerCase()
            );
            if (clientInfo) {
              let cashAccount = this.cashBookAccounts.find(
                (x) => x.accountNumber == lineStrArr[3]
              );
              if (cashAccount) {
                if (lineStrArr.length != 10) {
                  this.errorLists.push({
                    sn: this.errorLists.length + 1,
                    reason:
                      "Line " +
                      (line + 1) +
                      " skipped, error - incomplete parameters",
                    data: lines[line],
                  });
                } else {
                  this.bulkUploadContents.push({
                    sn: line + 1,
                    clientName: lineStrArr[0],
                    clientId: clientInfo.id,
                    description: lineStrArr[1],
                    amount: +lineStrArr[2],
                    accountNumber: lineStrArr[3],
                    accountId: cashAccount.id,
                    referenceNo: lineStrArr[4],
                    isTaxWitheld: +lineStrArr[5] == 1 ? true : false,
                    isFixedWHT: +lineStrArr[6] == 1 ? true : false,
                    valueOfWHT: +lineStrArr[7],
                    fundReceivedDate: lineStrArr[8],
                    applyAutomaticSettlement:
                      +lineStrArr[9] == 1 ? true : false,
                  });
                }
              } else {
                this.errorLists.push({
                  sn: this.errorLists.length + 1,
                  reason:
                    "Line " +
                    (line + 1) +
                    " skipped, error getting account info from cash book accounts -" +
                    lineStrArr[3],
                  data: lines[line],
                });
              }
            } else {
              this.errorLists.push({
                sn: this.errorLists.length + 1,
                reason:
                  "Line " +
                  (line + 1) +
                  " skipped, error getting client info -" +
                  lineStrArr[0],
                data: lines[line],
              });
            }
          } catch {
            this.errorLists.push({
              sn: this.errorLists.length + 1,
              reason: "Exception occurred",
              data: lines[line],
            });
          }
        }
      };
    }
  }

  RemoveBulkItem(item: BulkUploadDetail) {
    const index = this.bulkUploadContents.indexOf(item);
    if (index > -1) {
      this.bulkUploadContents.splice(index, 1);
    }
  }

  async SaveBulkUpload() {
    this.confirmationService.confirm({
      message:
        "Are you sure you want to send this bulk upload for advance payment creation. This is an irreversible action. Do you still wish to proceed?",
      accept: () => {
        this.messageService.add({
          severity: "info",
          summary: "Notice",
          detail: "Processing Advance Payment Bulk Creation...",
        });
        this.ResetMessageToasters();

        const postData: BulkUploadVM = {
          details: this.bulkUploadContents,
          evidenceUrl: "",
        };

        if (!this.isNewUpload && this.uploadedUrl) {
          postData.evidenceUrl = this.uploadedUrl;
          this._saveBulkUpload(postData);
        } else {
          this.isNewUpload = false;
          this.uploadedUrl = null;
          this.fileStorageService.UploadFileFromDataUrl(this.bulkUploadFile);
          this.fileStorageService.onUploadFinished.subscribe(
            (resp: CommonResponse<string>) => {
              if (resp.responseCode != "00") {
                this.messageService.add({
                  severity: "error",
                  summary: "Notice",
                  detail: resp.responseMsg,
                });
              } else {
                postData.evidenceUrl = resp.responseData;
                this.uploadedUrl = postData.evidenceUrl;
                this._saveBulkUpload(postData);
              }
            },
            (error) => {
              console.log("Error while uploading file " + error);
              this.messageService.add({
                severity: "error",
                summary: "Notice",
                detail: "ERR: Unable to upload image to storage",
              });
            }
          );
        }
      },
    });
  }

  _saveBulkUpload(postData: BulkUploadVM) {
    this.processingBulkUpload = true;
    this.advancePaymentService.BulkUploadV2(postData).subscribe(
      async (data) => {
        if (data.responseCode != "00") {
          this.processingBulkUpload = false;
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail: data.responseMsg,
          });

          if (data.responseMsg.includes("Do not repeat upload")) {
            this.errorLists = [];
            this.bulkUploadImagerSrc = null;
            this.uploadedUrl = null;
            this.isNewUpload = true;
            this.bulkUploadFile = null;
            this.bulkfile = null;
            this.bulkUploadContents = [];
            this.processingBulkUpload = false;
          }
          return;
        }

        this.messageService.add({
          severity: "success",
          summary: "Removed",
          detail: "Advance Payment Bulk Creation Successful",
        });
        this.ResetMessageToasters();

        this.errorLists = [];
        this.bulkUploadImagerSrc = null;
        this.uploadedUrl = null;
        this.isNewUpload = true;
        this.bulkUploadFile = null;
        this.bulkfile = null;
        this.bulkUploadContents = [];
        this.processingBulkUpload = false;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to run bulk creation at the moment.. Reason: [" + error
              ? error.error.message
              : "request failed - permission" + "]",
        });
        this.processingBulkUpload = false;
        this.ResetMessageToasters();
      }
    );
  }

  checkMimeType2 = (event: EventInput): boolean => {
    const files = event.target.files[0];
    // list allow mime type
    const types = ["text/csv"];
    // loop access array
    if (!files) {
      this.messageService.add({
        severity: "error",
        summary: "Failed",
        detail: "Reset Occurred: Please select a CSV file.",
      });
      return false;
    }
    // compare file type find doesn't match
    if (types.every((type) => files.type !== type)) {
      // create error message and assign to container
      this.messageService.add({
        severity: "error",
        summary: "Failed",
        detail: "Image format not supported",
      });
      return false;
    }
    return true;
  };

  checkFileSize2 = (event: EventInput) => {
    const files = event.target.files[0];
    if (!files) return true;
    const size = 400000;
    if (files.size > size) {
      event.target.value = null;
      this.messageService.add({
        severity: "error",
        summary: "Failed",
        detail: "Image is too large, please pick a smaller file",
      });
      return false;
    }
    return true;
  };

  onUploadFileSelect(event) {
    if (!this.checkMimeType(event) || !this.checkFileSize(event)) {
      return;
    }

    this.isNewUpload = true;
    const reader = new FileReader();
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      this.bulkUploadFile = event.target.files[0];
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.bulkUploadImagerSrc = reader.result as string;
      };
    }
  }

  ShowErrorDialogue() {
    this.openErrorDialogue = true;
  }

  HideErrorDialog() {
    this.openErrorDialogue = false;
  }

  MathRound(value: number, dc: number) {
    return value.toFixed(dc);
  }
}
