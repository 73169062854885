 import { MapsAPILoader } from '@agm/core';
import { Component, ElementRef, NgZone, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { truncate } from 'fs';
import { ConfirmationService, MessageService, TreeNode } from 'primeng/api';
import { CommonResponse } from 'src/app/interfaces/home';
import { SupplierApprovalRequestService } from './supplier-approval-request.service';
import { childrenInstance } from './childrenInstance';
import { ViewportScroller } from '@angular/common';
import { SupplierOfficeSetupService } from '../supplier-office-setup/supplier-office-setup.service';



@Component({
  selector: 'app-supplier-approval-request',
  templateUrl: './supplier-approval-request.component.html',
  styleUrls: ['./supplier-approval-request.component.scss']
})
export class SupplierApprovalRequestComponent implements OnInit {
  supplierRequest: any[]=[];
  approvalList: any[]=[];
  users: any[]=[];
  userList:any[]=[];
  canApprove: boolean;
  selectedRequest: any[]=[];
  showInfo: boolean;
  requestDetails: any;
  convertedArray:any[]=[];
  children:childrenInstance;
  lat: number;
  currentChildrenArray:any[]=[];
  lng: number;
  zoom: number;
  options: any;
  childrenUpdate:any[]=[];
  childrenArray:any[]=[];
  overlays: any[]=[];
  defaultImage = "https://via.placeholder.com/150"
  dialogVisible: boolean;
  markerTitle: string;
  selectedPosition: any;
  infoWindow: any;
  draggable: boolean;
  nodes: TreeNode[]=[];
  treekey:any[]=[];
  supplierServices: any[]=[];
  filteredArray: any[]=[];
  filterCount;
  documents: any[]=[];
  choosingDecline:boolean = false;
  documentCategoryLength: number;
  responsiveOptions:any[] = [
    {
        breakpoint: '1024px',
        numVisible: 5
    },
    {
        breakpoint: '768px',
        numVisible: 3
    },
    {
        breakpoint: '560px',
        numVisible: 1
    }
];
  detailDocument: any[]=[];
  @ViewChild('infos', { read: ElementRef }) public panel: ElementRef<any>;
  approval: any;
  officeList: any[]=[];
  lastOffice: any;
  currentSupplier: any;
  remarks: any;
  constructor(
    private supplierApprovalService : SupplierApprovalRequestService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private sanitizer: DomSanitizer,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    private vps: ViewportScroller,
    private supplierService:SupplierOfficeSetupService,
    
    ) { }
  ngOnInit(): void {
    this.getAllSupplierRequest();
    this.getAllUsers();
    this.getAllOffices();
    this.infoWindow = new google.maps.InfoWindow();
  }

  getAllUsers(){
    this.supplierApprovalService.allUser().subscribe(async(res: CommonResponse) => {
      if(res.responseCode=="00"){
        this.users = res.responseData;
        this.users.forEach(user => {
         user.fullName = user.firstName + " " + user.lastName;
        });
        console.log("List of users",this.users);
      }
    })
  }

  getAllOffices(){
    this.messageService.add({severity:'success', summary:"Loading office list..."})
    this.supplierService.allSuppliers().subscribe(async(res: CommonResponse) => {
      if(res.responseCode=="00"){
        this.messageService.add({severity:'success', summary:res.responseMsg})
        this.officeList = res.responseData;
        this.officeList = this.officeList.sort(function (a, b) {  return a.sequence - b.sequence});
        console.log("List of offices",this.officeList);
      }
    })
  }

  getAllSupplierRequest(){
    this.supplierApprovalService.allSupplierRequest().subscribe(async(res: CommonResponse) => {
      if(res.responseCode=="00"){
        this.supplierRequest = res.responseData;
        console.log("List of supplierRequest",this.supplierRequest);
      }
    })
  }


  transform(image) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(image);
  }

  processRequest(supplier){
    this.currentSupplier = supplier;
    this.messageService.add({severity:'info', summary:"retrieving processed supplier request......"})
    this.supplierApprovalService.processRequest(supplier.id).subscribe(async(res:CommonResponse)=>{
      if(res.responseCode == "00"){
        this.userList = [];
        this.approvalList = [];
        console.log("Res:::::::: ",res.responseData);
        this.selectedRequest = res.responseData;
        this.vps.scrollToPosition([0, 300]);
      }
    })
   
  }


  
  isEligible(body){
    if(body.officers.find(x=> x.userId == body.currentlyLogged) == null){
       return false;
    }
    else{
      return true;
    }
  }

  getUser(office){
    var user:any = this.users.find(x=> x.id == office.userId);
    user.imageUrl = user.imageUrl == "" ? this.defaultImage  : user.imageUrl;
    console.log("This is the provided user ",user);
    return user
  }

  getApprovingOfficer(officer){
    // console.log("this is officer gotten ",officer);
    var approvingOfficer = this.users.filter(x=>{
      return x.id == officer.approvingOfficerId 
    })[0]
    // console.log("this is approving office ",approvingOfficer);
    return approvingOfficer == undefined ? "Not yet processed " : approvingOfficer.fullName;
  }

  proceedToRequest(approval){
    this.approval = approval;
    console.log("this is the approval ",approval);
    this.messageService.add({severity:'info', summary:"retrieving processes........."})
    this.supplierApprovalService.proceedRequest(approval.supplierId).subscribe(async(result:CommonResponse)=>{
      if(result.responseCode == "00"){
        this.requestDetails = result.responseData
        console.log("This is the response from serve ",this.requestDetails);
        this.setCordinates(this.requestDetails);
        this.addMarker(this.requestDetails);
        this.resolveTree(this.requestDetails);
        this.documents = this.requestDetails.documents;
        this.documentCategoryLength = this.documents.length
      }
    })
  }


 
  setCordinates(request){
    this.options = {
      center: {lat: request.locationLatutude, lng: request.locationLongitude},
      zoom: 12
    };
  }

  handleMapClick(event) {
    this.dialogVisible = true;
    this.selectedPosition = event.latLng;
}

handleOverlayClick(event) {
    let isMarker = event.overlay.getTitle != undefined;

    if (isMarker) {
        let title = event.overlay.getTitle();
        this.infoWindow.setContent('' + title + '');
        this.infoWindow.open(event.map, event.overlay);
        event.map.setCenter(event.overlay.getPosition());

        this.messageService.add({severity:'info', summary:'Marker Selected', detail: title});
    }
    else {
        this.messageService.add({severity:'info', summary:'Shape Selected', detail: ''});
    }
}

addMarker(request) {
    this.overlays.push(new google.maps.Marker({position:{lat: request.locationLatutude, lng: request.locationLongitude}, title:request.address, draggable: this.draggable}));
}

handleDragEnd(event) {
    this.messageService.add({severity:'info', summary:'Marker Dragged', detail: event.overlay.getTitle()});
}

zoomIn(map) {
    map.setZoom(map.getZoom()+1);
}

zoomOut(map) {
    map.setZoom(map.getZoom()-1);
}

clear() {
    this.overlays = [];
}


resolveTree(request){
      this.supplierServices = request.supplierServices
      request.supplierServices.forEach(x=>{
        this.treekey.push({
          key:x.make,
          label:x.make,
          data:x.serviceName,
          children:[]
        })
      })
      this.nodes = [...new Map(this.treekey.map(item =>[item.key, item])).values()];
      this.filterCount = this.nodes.length;
      console.log("This is the distinct array ",this.nodes);
}

filterObject(value){
  this.filteredArray = this.supplierServices.filter(x=>{
    return x.make == value
  })
  console.log("filtered value ",this.filteredArray);
}

nodeSelect(event) {
  //this.messageService.add({severity: 'info', summary: 'Node Selected', detail: event.node.label});
  console.log("Node Selected",event.node.label)
  this.filterObject(event.node.label)
}

nodeUnselect(event) {
  this.messageService.add({severity: 'info', summary: 'Node Unselected', detail: event.node.label});
}

getDocument(document){
  console.log("This is the document selected ",document);
  this.detailDocument = [];
  this.detailDocument = document.details;
}

declineRequest(){
  this.confirmationService.confirm({
    message: "Are you sure you want to decline this request",
    header: 'Confirmation',
    icon: 'pi pi-exclamation-triangle',
    accept: () => {
        this.supplierApprovalService.declineRequest(this.approval.id,this.remarks).subscribe(async(result:CommonResponse)=>{
          if(result.responseCode == "00"){
            console.log("this is the result",result);
            this.messageService.add({severity:'success', summary:'Confirmed', detail:result.responseMsg});
            this.getAllSupplierRequest();
            this.processRequest(this.currentSupplier);
            this.requestDetails = null;
            this.closeDeclineButton();
          }else{
            this.messageService.add({severity:'error', summary:'Confirmed', detail:result.responseMsg});
          }
        })
    },
    reject: (type) => {
      this.messageService.add({severity:'error', summary:'Confirmed'});
    }
});
}

approveRequest(){
   this.officeList = this.officeList.sort((a, b) => {
    return a.sequence - b.sequence;
    });
    
   console.log("this is the current structured array ",this.officeList,this.approval.sequence)
   this.lastOffice = this.officeList[this.officeList.length - 1]
   console.log("this is the last office in the array",this.lastOffice);
  if(this.approval.sequence == this.lastOffice.sequence){
    var message = "Clicking the approve button will initiate a final approval for " 
    +this.supplierRequest.filter(x=>{return x.id == this.approval.supplierId})[0].supplierName +". Are you sure you want to continue?";
    this.confirmApproval(message);
  }
  else{
    var message = "Are you sure you want to initiate " + this.approval.approvalTitle;
    this.confirmApproval(message);
  }
}


confirmApproval(message) {
  this.confirmationService.confirm({
      message: message,
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
          this.supplierApprovalService.approvedRequest(this.approval.id).subscribe(async(result:CommonResponse)=>{
            if(result.responseCode == "00"){
              console.log("this is the result",result);
              this.messageService.add({severity:'success', summary:'Confirmed', detail:result.responseMsg});
              this.getAllSupplierRequest();
              this.processRequest(this.currentSupplier);
              this.requestDetails = null;
            }else{
              this.messageService.add({severity:'error', summary:'Confirmed', detail:result.responseMsg});
            }
          })
      },
      reject: (type) => {
        this.messageService.add({severity:'error', summary:'Confirmed'});
      }
  });
}

closeDeclineButton(){
  this.remarks = null;
  this.choosingDecline = false;
}

viewFile(value){
  window.open(value.documentUrl, '_blank');
}

}
