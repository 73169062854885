import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { ConfirmationService, MessageService } from "primeng/api";
import { BreadcrumbService } from "src/app/breadcrumb.service";
import { User } from "src/app/interfaces/home";
import {
  CreateReversalOfficesReceivingDTO,
  FinanceReversalType,
  ReversalApprovingOffice,
  ReversalApprovingOfficer,
  UpdateReversalOfficesReceivingDTO,
} from "src/app/interfaces/reversal-office";
import { ReversalOfficeService } from "src/app/services/reversal-office.service";
import { UserService } from "src/app/services/user.service";

@Component({
  selector: "app-reversal-office",
  templateUrl: "./reversal-office.component.html",
  styleUrls: ["./reversal-office.component.scss"],
})
export class ReversalOfficeComponent implements OnInit {
  @ViewChild("formWrapper") public formWrapper: ElementRef;
  officeForm: FormGroup;
  allUsers: User[];
  theUser: User;
  addedReversalOfficers: ReversalApprovingOfficer[] = [];
  editingOffice: boolean;
  reversalOfficeToEdit: any;
  fetchingOffices: boolean;
  allReversalOffices: ReversalApprovingOffice[];
  selectedReversalOffices: ReversalApprovingOffice[];
  officeCols: any[];
  allReversalTypes: {
    key: number;
    value: string;
  }[];
  theReversalType: {
    key: number;
    value: string;
  };

  constructor(
    private fb: FormBuilder,
    public userService: UserService,
    private reversalOfficeService: ReversalOfficeService,
    private breadcrumbService: BreadcrumbService,
    public confirmationService: ConfirmationService,
    public messageService: MessageService
  ) {
    this.officeForm = fb.group({
      ReversingUser: [""],
      OfficeName: ["", Validators.required],
      ReversalType: ["", Validators.required],
    });

    this.allReversalTypes = [
      {
        key: FinanceReversalType.ReceiptReversal,
        value: "Receipt Reversal",
      },
      {
        key: FinanceReversalType.InvoiceReversal,
        value: "Invoice Reversal",
      },
      {
        key: FinanceReversalType.VoucherReversal,
        value: "Voucher Reversal",
      },
    ];
  }

  ngOnInit(): void {
    this.breadcrumbService.setItems([
      {
        label: "Finance",
      },
      {
        label: "Reversal Office Setup",
        routerLink: ["/home/reversal-office"],
      },
    ]);

    this.officeCols = [{ field: "name", header: "Name" }];

    this.FetchAllUsers();
  }

  FetchAllUsers() {
    this.userService.allUser().subscribe(
      async (r) => {
        var data = r.responseData ?? [];
        this.allUsers = [];
        data.forEach((user) => {
          user.fullName = user.lastName + " " + user.firstName;
          this.allUsers.push(user);
        });
        this.FetchAllReversalOffices();
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all users at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  FetchAllReversalOffices() {
    this.fetchingOffices = true;
    this.reversalOfficeService.GetAllReversalOffices().subscribe(
      async (data) => {
        if (data.responseCode != "00") {
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail: data.responseMsg,
          });
          this.fetchingOffices = false;
          return;
        }

        this.allReversalOffices = data.responseData;
        this.allReversalOffices.forEach(
          (office) =>
            (office.officers = office.officers.filter(
              (x) => x.isDeleted == false
            ))
        );
        this.fetchingOffices = false;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all reersal office at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
        this.fetchingOffices = false;
      }
    );
  }

  CreateReversalOffice() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Creating Reversal Office...",
    });

    const postData: CreateReversalOfficesReceivingDTO = {
      reversalType: this.theReversalType.key,
      officeName: this.officeForm.get("OfficeName").value,
      officersIds: this.addedReversalOfficers.map((x) => x.userProfileId),
    };

    this.reversalOfficeService.CreateReversalOffices(postData).subscribe(
      async (data) => {
        if (data.responseCode != "00") {
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail: data.responseMsg,
          });
          return;
        }
        this.messageService.add({
          severity: "success",
          summary: "Completed",
          detail: "Reversal Office Created Successfully!",
        });

        this.FetchAllReversalOffices();
        this.CloseEditingOffice();
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to create reersal office at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  AddReversalOfficerToList() {
    if (!this.theUser) {
      this.messageService.add({
        severity: "error",
        summary: "Failed",
        detail: "You must select the officer to add",
      });
      return;
    }

    let alreadyAdded = false;
    this.addedReversalOfficers.forEach((item) => {
      if (item.userProfileId == this.theUser.id) {
        alreadyAdded = true;
      }
    });

    if (alreadyAdded) {
      this.messageService.add({
        severity: "error",
        summary: "Failed",
        detail: "Officer with the same info has already been added to list.",
      });
      return;
    }

    this.addedReversalOfficers.push({
      userProfileId: this.theUser.id,
      userProfile: this.theUser,
    });

    this.theUser = null;
    this.messageService.add({
      severity: "success",
      summary: "Added",
      detail: "Reversal Officer added successfully",
    });
  }

  RemoveOfficer(item: ReversalApprovingOfficer) {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Removing Reversal Officer...",
    });

    const index = this.addedReversalOfficers.indexOf(item);
    if (index > -1) {
      this.addedReversalOfficers.splice(index, 1);
    }

    this.messageService.add({
      severity: "success",
      summary: "Removed",
      detail: "Reversal Officer Removed successfully",
    });
  }

  CloseEditingOffice() {
    this.editingOffice = false;
    this.officeForm.reset();
    this.addedReversalOfficers = [];
    this.reversalOfficeToEdit = null;
  }

  UpdateReversalOffice() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Updating Approval Office...",
    });

    let addedApproveOfficerIds: number[] = [];
    this.addedReversalOfficers.forEach((officer) => {
      addedApproveOfficerIds.push(officer.userProfileId);
    });

    const id = this.reversalOfficeToEdit.id;
    const postData: UpdateReversalOfficesReceivingDTO = {
      officeName: this.officeForm.get("OfficeName").value,
      officersIds: addedApproveOfficerIds,
    };

    this.reversalOfficeService.UpdateReversalOffices(id, postData).subscribe(
      async (data) => {
        if (data.responseCode != "00") {
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail: data.responseMsg,
          });
          return;
        }
        this.messageService.add({
          severity: "success",
          summary: "Completed",
          detail: "Reversal Office Updated Successfully.",
        });

        this.CloseEditingOffice();
        this.FetchAllReversalOffices();
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to update reversal office at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  DeleteReversalOffice(item: ReversalApprovingOffice) {
    this.confirmationService.confirm({
      message: "Are you sure you want to remove reversal office?",
      accept: () => {
        this.messageService.add({
          severity: "info",
          summary: "Notice",
          detail: "Removing reversal office...",
        });

        this.reversalOfficeService.DeleteReversalOffices(item.id).subscribe(
          async () => {
            await this.messageService.add({
              severity: "success",
              summary: "Removed",
              detail: "Removed successfully",
            });
            this.FetchAllReversalOffices();
          },
          (error) => {
            console.log("Error: " + JSON.stringify(error));
            this.messageService.add({
              severity: "error",
              summary: "Notice",
              detail:
                "Unable to remove reveral office at the moment.. Reason: [" +
                error
                  ? error.error.message
                  : "request failed - permission" + "]",
            });
          }
        );
      },
    });
  }

  EditReversalOffice(item: ReversalApprovingOffice) {
    this.editingOffice = true;
    this.officeForm.patchValue({
      OfficeName: item.name,
    });
    this.reversalOfficeToEdit = item;
    this.theReversalType = this.allReversalTypes.find(
      (x) => x.key == item.reversalType
    );
    this.addedReversalOfficers = item.officers;

    this.formWrapper.nativeElement.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "start",
    });
  }

  GetOfficerName(officerId: number): string {
    let officer = this.allUsers.find((x) => x.id == officerId);
    if (officer) return officer.fullName;

    return "N/A";
  }

  GetReversalTypeLabel(id: number): string {
    let type = this.allReversalTypes.find((x) => x.key == id);
    if (type) return type.value;

    return "N/A";
  }
}
