import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { ConfirmationService, MessageService } from "primeng/api";
import { BreadcrumbService } from "src/app/breadcrumb.service";
import {
  GetPaidVsQtyBilledReportsVM,
  KeyValuePairTempVM,
  PaidVsQtyReportMaster,
  PayGroup,
} from "src/app/interfaces/payroll";
import { PayrollService } from "src/app/services/payroll.service";

@Component({
  selector: "app-payroll-paid-vs-qty",
  templateUrl: "./payroll-paid-vs-qty.component.html",
  styleUrls: ["./payroll-paid-vs-qty.component.scss"],
})
export class PayrollPaidVsQtyComponent implements OnInit {
  @ViewChild("formWrapper") public formWrapper: ElementRef;
  allRunTypes: KeyValuePairTempVM[];
  theRunType: KeyValuePairTempVM;
  allRunPeriods: KeyValuePairTempVM[];
  theRunPeriod: KeyValuePairTempVM;
  allPayGroups: PayGroup[];
  thePayGroup: PayGroup;
  allClients: KeyValuePairTempVM[];
  theClient: KeyValuePairTempVM;
  showReport: boolean = false;
  reportMasters: PaidVsQtyReportMaster[];
  reportMasterCols: any[];
  reportCols: any[];

  constructor(
    private fb: FormBuilder,
    private payrollService: PayrollService,
    private breadcrumbService: BreadcrumbService,
    public confirmationService: ConfirmationService,
    public messageService: MessageService
  ) {}

  ngOnInit(): void {
    this.breadcrumbService.setItems([
      {
        label: "Payroll",
        routerLink: ["/home/payroll/payroll-settings"],
      },
      {
        label: "Paid Vs Qty Billed",
        routerLink: ["/home/payroll/paid-vs-qty"],
      },
    ]);

    this.reportMasterCols = [
      { field: "clientName", header: "ClientName" },
      { field: "locationName", header: "LocationName" },
      { field: "qtyPaidFor", header: "QtyPaidFor" },
      { field: "qtyGiven", header: "QtyGiven" },
      { field: "report", header: "Report" },
    ];

    this.reportCols = [
      { field: "operativeFirstname", header: "OperativeFirstname" },
      { field: "operativeLastname", header: "OperativeLastname" },
      { field: "operativeID", header: "OperativeID" },
      { field: "activeContractService", header: "ActiveContractService" },
      { field: "netPay", header: "NetPay" },
    ];

    this.FetchFiltersDetails();
  }

  FetchFiltersDetails() {
    this.payrollService.GetPayrollVsContractRequestDits().subscribe(
      async (data) => {
        this.allRunTypes = data.runTypes;
        this.allRunPeriods = data.runPeriods;
        this.allPayGroups = data.payGroups;
        this.allClients = data.clients;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch filter details at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  RunCriteria() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Generating Report...",
    });

    this.showReport = false;
    const postData: GetPaidVsQtyBilledReportsVM = {
      runTypeId: this.theRunType.key,
      runPeriodId: this.theRunPeriod.key,
      runPeriodDate: this.theRunPeriod.keyDate,
      payGroupId: this.thePayGroup.id,
      clientId: this.theClient.key,
    };

    this.payrollService.GetPaidVsQtyBilledReports(postData).subscribe(
      async (data) => {
        if (data.responseCode != "00") {
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail: data.responeMessage,
          });
          this.showReport = false;
          return;
        }

        this.messageService.add({
          severity: "success",
          summary: "Completed",
          detail: "Report Generated Successfully!",
        });

        this.reportMasters = data.responseObject as PaidVsQtyReportMaster[];
        this.showReport = true;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to generate report at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
        this.showReport = false;
      }
    );
  }
}
