import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { ConfirmationService, MessageService } from "primeng/api";
import { BreadcrumbService } from "src/app/breadcrumb.service";
import {
  ConvergenceNotificationTemplate,
  CreateNotificationTemplateVM,
} from "src/app/interfaces/convergence";
import { ConvergenceService } from "src/app/services/convergence.service";

@Component({
  selector: "app-incident-notification-template",
  templateUrl: "./incident-notification-template.component.html",
  styleUrls: ["./incident-notification-template.component.scss"],
})
export class IncidentNotificationTemplateComponent implements OnInit {
  @ViewChild("formWrapper") public formWrapper: ElementRef;
  carouselResponsiveOptions: any[] = [
    {
      breakpoint: "1024px",
      numVisible: 3,
      numScroll: 3,
    },
    {
      breakpoint: "768px",
      numVisible: 2,
      numScroll: 2,
    },
    {
      breakpoint: "560px",
      numVisible: 1,
      numScroll: 1,
    },
  ];
  allNotiicationTemplates: ConvergenceNotificationTemplate[];
  templateName: string;
  templateDescription: string;
  allRiskLevels: {
    key: number;
    value: string;
  }[] = [
    {
      key: 1,
      value: "Level 1",
    },
    {
      key: 2,
      value: "Level 2",
    },
    {
      key: 3,
      value: "Level 3",
    },
    {
      key: 4,
      value: "Level 4",
    },
  ];
  theRiskLevel: {
    key: number;
    value: string;
  };
  templateBody: string;
  templateVariables: { key: string; value: string }[] = [];
  templateBodyPreviewed: string;
  openTemplateDialogue: boolean;
  templatePreviewName: string = "N/A";
  loadingTemplate: boolean = false;
  allowedNotificationTemplateVariables: { key: string; value: string }[] = [
    {
      key: "client_divisionName",
      value: "client_divisionName",
    },
    {
      key: "client_location",
      value: "client_location",
    },
    {
      key: "client_id",
      value: "client_id",
    },
    {
      key: "incidence_dateOccurred",
      value: "incidence_dateOccurred",
    },
    {
      key: "incidence_address",
      value: "incidence_address",
    },
    {
      key: "incidence_caption",
      value: "incidence_caption",
    },
    {
      key: "incidence_decription",
      value: "incidence_decription",
    },
    {
      key: "incidence_riskLevel",
      value: "incidence_riskLevel",
    },
    {
      key: "incidence_advisory",
      value: "incidence_advisory",
    },
  ];

  constructor(
    private fb: FormBuilder,
    public convergenceService: ConvergenceService,
    private breadcrumbService: BreadcrumbService,
    public confirmationService: ConfirmationService,
    public messageService: MessageService
  ) {}

  ngOnInit(): void {
    this.breadcrumbService.setItems([
      {
        label: "Convergence",
      },
      {
        label: "Incident Management",
      },
      {
        label: "Notification Template",
        routerLink: [
          "/home/convergence/incident-management/notification-template",
        ],
      },
    ]);

    this.FetchAllTemplates();
  }

  PreviewHTMLTemplate() {
    this.templateBodyPreviewed = this.templateBody;
    document.getElementById("previewCard").innerHTML =
      this.templateBodyPreviewed;
  }

  FetchAllTemplates() {
    this.convergenceService.GetAllConvergenceNotificationTemplates().subscribe(
      async (data) => {
        this.allNotiicationTemplates = data;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all templates at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  ResetNotifyBox() {
    this.templateName = null;
    this.templateDescription = null;
    this.theRiskLevel = null;
    this.templateVariables = [];
    this.templateBody = null;
    this.templateBodyPreviewed = null;
    document.getElementById("previewCard").innerHTML = "";
  }

  SaveNotificationTemplate() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Saving Notification Template...",
    });

    const postData: CreateNotificationTemplateVM = {
      templateName: this.templateName,
      templateDescription: this.templateDescription,
      templateVariables: this.templateVariables.map((x) => x.value),
      templateBody: this.templateBodyPreviewed,
    };

    this.convergenceService.CreateNotificationTemplate(postData).subscribe(
      async () => {
        this.messageService.add({
          severity: "success",
          summary: "Completed",
          detail: "Notifiation Template Created Successfully!",
        });

        this.ResetNotifyBox();
        this.FetchAllTemplates();
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to create notification template at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  DeleteTemplate(item: ConvergenceNotificationTemplate) {
    this.confirmationService.confirm({
      message:
        "Are you sure you want to remove " +
        item.name +
        " notification template?",
      accept: () => {
        this.messageService.add({
          severity: "info",
          summary: "Notice",
          detail: "Removing Template...",
        });

        this.convergenceService.DeleteNotificationTemplate(item.id).subscribe(
          async () => {
            this.messageService.add({
              severity: "success",
              summary: "Removed",
              detail: "Removed successfully",
            });
            this.FetchAllTemplates();
          },
          (error) => {
            console.log("Error: " + JSON.stringify(error));
            this.messageService.add({
              severity: "error",
              summary: "Notice",
              detail:
                "Unable to remove template at the moment.. Reason: [" + error
                  ? error.error.message
                  : "request failed - permission" + "]",
            });
          }
        );
      },
    });
  }

  async ViewTemplate(item: ConvergenceNotificationTemplate) {
    this.openTemplateDialogue = true;
    this.templatePreviewName = item.name;
    this.loadingTemplate = true;
    await new Promise((resolve) => setTimeout(resolve, 5000)); // 5 secs
    this.loadingTemplate = false;
    document.getElementById("templatePopup").innerHTML = item.templateBody;
  }

  HideTemplateDialog() {
    this.openTemplateDialogue = false;
    this.templatePreviewName = "N/A";
    this.loadingTemplate = false;
  }

  async PreviewTemplate() {
    this.openTemplateDialogue = true;
    this.templatePreviewName = this.templateName;
    this.loadingTemplate = true;
    await new Promise((resolve) => setTimeout(resolve, 5000)); // 5 secs
    this.loadingTemplate = false;
    document.getElementById("templatePopup").innerHTML =
      this.templateBodyPreviewed;
  }
}
