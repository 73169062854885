import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { ConfirmationService, Message, MessageService } from "primeng/api";
import { BreadcrumbService } from "src/app/breadcrumb.service";
import {
  AddNotificationPolicyAttachmentBeneficiaryVM,
  ConvergenceNotificationPolicy,
  ConvergenceNotificationPolicyAttachment,
  CreateNotificationPolicyAttachmentVM,
} from "src/app/interfaces/convergence";
import { ClientBeneficiary, CustomerDivision } from "src/app/interfaces/home";
import { ClientBeneficiaryService } from "src/app/services/client-beneficiary.service";
import { ConvergenceService } from "src/app/services/convergence.service";
import { CustomerDivisionService } from "src/app/services/customer-division.service";

@Component({
  selector: "app-incident-client-notification",
  templateUrl: "./incident-client-notification.component.html",
  styleUrls: ["./incident-client-notification.component.scss"],
  providers: [MessageService],
})
export class IncidentClientNotificationComponent implements OnInit {
  @ViewChild("formWrapper") public formWrapper: ElementRef;
  allIncidentNotificationPolicies: ConvergenceNotificationPolicy[];
  selectedIncidentNotificationPolicies: ConvergenceNotificationPolicy[];
  cols: any[];
  notificationPolicyInView: ConvergenceNotificationPolicy;
  allPolicyClientsAttached: ConvergenceNotificationPolicyAttachment[] = [];
  summaryMsg: Message[] = [];
  allClients: CustomerDivision[] = [];
  theClient: CustomerDivision;
  selectedClients: CustomerDivision[] = [];
  openConfigureDialogue: boolean;
  configureEntityInView: ConvergenceNotificationPolicyAttachment;
  fetchingConfiguration: boolean;
  allClientBeneficiaries: ClientBeneficiary[];
  selectedClientBeneficiaries: ClientBeneficiary[] = [];
  allBeneficiaryDependants: ClientBeneficiary[];
  selectedBeneficiaryDependants: ClientBeneficiary[] = [];
  beneficiaryCols: any[];
  beneficiaryDependantCols: any[];
  openWebhookConfig: boolean;
  policyAttachForWebhookUrlConfig: ConvergenceNotificationPolicyAttachment;

  constructor(
    private fb: FormBuilder,
    public clientBeneficiaryService: ClientBeneficiaryService,
    public clientService: CustomerDivisionService,
    public convergenceService: ConvergenceService,
    private breadcrumbService: BreadcrumbService,
    public confirmationService: ConfirmationService,
    public messageService: MessageService
  ) {}

  ngOnInit(): void {
    this.breadcrumbService.setItems([
      {
        label: "Convergence",
      },
      {
        label: "Incident Management",
      },
      {
        label: "Notifications",
        routerLink: [
          "/home/convergence/incident-management/client-notification",
        ],
      },
    ]);

    this.cols = [
      { field: "dateCreated", header: "Date Created" },
      { field: "name", header: "Policy Name" },
      { field: "description", header: "Policy Description" },
      { field: "startDate", header: "Start Date" },
      { field: "endDate", header: "End Date" },
      { field: "policyAttachments.length", header: "Cleints Attached" },
    ];

    this.beneficiaryCols = [
      { field: "title", header: "Title" },
      { field: "firstName", header: "First Name" },
      { field: "lastName", header: "Last Name" },
      { field: "middleName", header: "Middle Name" },
      { field: "mobile", header: "Mobile" },
      { field: "email", header: "Email" },
      { field: "address", header: "Address" },
    ];

    this.beneficiaryDependantCols = [
      { field: "beneficiaryName", header: "Beneficiary Name" },
      { field: "title", header: "Title" },
      { field: "firstName", header: "First Name" },
      { field: "lastName", header: "Last Name" },
      { field: "middleName", header: "Middle Name" },
      { field: "mobile", header: "Mobile" },
      { field: "email", header: "Email" },
      { field: "address", header: "Address" },
    ];

    this.FetchAllPolicies();
    this.FetchAllClients();
    this.ResetMessageToasters();
  }

  ResetMessageToasters() {
    this.summaryMsg = [];
    this.summaryMsg.push({
      severity: "info",
      summary: "Notice:",
      detail:
        this.notificationPolicyInView.name +
        "has " +
        this.allPolicyClientsAttached.length +
        " clients attached.",
    });
  }

  FetchAllPolicies() {
    this.allIncidentNotificationPolicies = [];
    this.convergenceService.GetAllIncidentNotificationPolicies().subscribe(
      async (data) => {
        this.allIncidentNotificationPolicies = data;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all policies at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
        this.ResetMessageToasters();
      }
    );
  }

  async FetchAllClients() {
    this.clientService.allCustomerDivision().subscribe(
      async (data) => {
        this.allClients = data.responseData;
      },
      (error) => {
        this.messageService.add({
          severity: "error",
          summary: "Failed",
          detail: error ?? "Some errors occurred",
        });
      }
    );
  }

  GetPolicyStatus(item: ConvergenceNotificationPolicy): boolean {
    // Thu Oct 13 2022 21:34:32 GMT+0100 (West Africa Standard Time)
    let today = new Date();
    let today_day = today.toString().substring(8, 10);
    let today_month = this.GetShortMonthValue(today.toString().substring(4, 7));
    let today_year = today.toString().substring(11, 15);
    // console.log("Today " + today_day + "/" + today_month + "/" + today_year);

    // 2022-10-16T00:00:00
    let endDate_day = item.endDate.toString().substring(8, 10);
    let endDate_month = +item.endDate.toString().substring(5, 7);
    let endDate_year = item.endDate.toString().substring(0, 4);
    // console.log(
    //   "End Date " + endDate_day + "/" + endDate_month + "/" + endDate_year
    // );

    if (today_year > endDate_year) return false;
    if (today_month > endDate_month) return false;
    if (today_day > endDate_day) return false;

    return true;
  }

  GetShortMonthValue(month: string): number {
    switch (month.toLowerCase()) {
      case "jan":
        return 1;
      case "feb":
        return 2;
      case "mar":
        return 3;
      case "apr":
        return 4;
      case "may":
        return 5;
      case "jun":
        return 6;
      case "jul":
        return 7;
      case "aug":
        return 8;
      case "sep":
        return 9;
      case "oct":
        return 10;
      case "nov":
        return 11;
      case "dec":
        return 12;
      default:
        return 0;
    }
  }

  ViewClientsAttached(item: ConvergenceNotificationPolicy) {
    this.notificationPolicyInView = item;
    this.allPolicyClientsAttached = item.policyAttachments.filter(
      (x) => x.isDeleted == false
    );
    this.allPolicyClientsAttached.forEach(
      (x) => (x.client = this.allClients.find((y) => y.id == x.clientId))
    );
    this.ResetMessageToasters();
  }

  AttachClient() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Attaching Client(s) to Notification Policy...",
    });

    const postData: CreateNotificationPolicyAttachmentVM = {
      policyId: this.notificationPolicyInView.id,
      clientIds: this.selectedClients.map((x) => x.id),
    };

    this.convergenceService
      .CreateNotificationPolicyAttachment(postData)
      .subscribe(
        async () => {
          this.messageService.add({
            severity: "success",
            summary: "Completed",
            detail: "Client Attached Successfully!",
          });

          this.notificationPolicyInView = null;
          this.allPolicyClientsAttached = [];
          this.selectedClients = [];
          this.FetchAllPolicies();
        },
        (error) => {
          console.log("Error: " + JSON.stringify(error));
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail:
              "Unable to attach clients at the moment.. Reason: [" +
              (error ? error.error.message : "request failed - permission") +
              "]",
          });
        }
      );
  }

  DetachClient(item: ConvergenceNotificationPolicyAttachment) {
    this.confirmationService.confirm({
      message:
        "Are you sure you want to detach client " + item.client.divisionName,
      accept: () => {
        this.messageService.add({
          severity: "info",
          summary: "Notice",
          detail: "Detaching Client...",
        });

        this.convergenceService
          .DeleteNotificationPolicyAttachment(item.id)
          .subscribe(
            async () => {
              this.messageService.add({
                severity: "success",
                summary: "Removed",
                detail: "Client Detached successfully",
              });

              this.notificationPolicyInView = null;
              this.allPolicyClientsAttached = [];
              this.selectedClients = [];
              this.FetchAllPolicies();
            },
            (error) => {
              console.log("Error: " + JSON.stringify(error));
              this.messageService.add({
                severity: "error",
                summary: "Notice",
                detail:
                  "Unable to detach client at the moment.. Reason: [" + error
                    ? error.error.message
                    : "request failed - permission" + "]",
              });
            }
          );
      },
    });
  }

  GetClientsAttachedCount(item: ConvergenceNotificationPolicy): string {
    return item.policyAttachments
      .filter((x) => x.isDeleted == false)
      .length.toLocaleString();
  }

  ConfigureClientBeneficiaries(item: ConvergenceNotificationPolicyAttachment) {
    this.openConfigureDialogue = true;
    this.configureEntityInView = item;
    this.fetchingConfiguration = true;
    this.clientBeneficiaryService
      .GetClientsBeneficiary(item.clientId)
      .subscribe(
        async (data) => {
          if (data.responseCode != "00") {
            this.messageService.add({
              severity: "error",
              summary: "Notice",
              detail: data.responseMsg,
            });
            this.fetchingConfiguration = false;
            return;
          }

          this.allClientBeneficiaries = data.responseData.filter(
            (x) => x.isPrincipal == true
          );
          this.allBeneficiaryDependants = data.responseData.filter(
            (x) => x.isPrincipal == false
          );

          let selectedBeneficiaries = item.beneficiaryAttachments.map(
            (x) => x.clientBeneficiaryId
          );
          this.selectedClientBeneficiaries = this.allClientBeneficiaries.filter(
            (x) => selectedBeneficiaries.find((y) => y == x.id) != null
          );
          this.selectedBeneficiaryDependants =
            this.allBeneficiaryDependants.filter(
              (x) => selectedBeneficiaries.find((y) => y == x.id) != null
            );
          this.fetchingConfiguration = false;
        },
        (error) => {
          console.log("Error: " + JSON.stringify(error));
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail:
              "Unable to get client beneficiaries at the moment.. Reason: [" +
              (error ? error.error.message : "request failed - permission") +
              "]",
          });
          this.fetchingConfiguration = false;
        }
      );
  }

  HideConfigureDialog() {
    this.openConfigureDialogue = false;
    this.configureEntityInView = null;
    this.fetchingConfiguration = false;
  }

  SaveBeneficiariesConfiguration() {
    this.confirmationService.confirm({
      message:
        "Are you sure you want to proceed with this beneficiary configuration?",
      accept: () => {
        this.messageService.add({
          severity: "info",
          summary: "Notice",
          detail: "Saving Beneficiary Conguration...",
        });

        const postData: AddNotificationPolicyAttachmentBeneficiaryVM = {
          notificationPolicyAttachmentId: this.configureEntityInView.id,
          beneficiaryIds: this.selectedClientBeneficiaries.map((x) => x.id),
        };
        postData.beneficiaryIds.push(
          ...this.selectedBeneficiaryDependants.map((x) => x.id)
        );

        this._saveBeneficairiesConfiguration(postData);
      },
    });
  }

  SaveDependantsConfiguration() {
    this.confirmationService.confirm({
      message:
        "Are you sure you want to proceed with this beneficiary dependant configuration?",
      accept: () => {
        this.messageService.add({
          severity: "info",
          summary: "Notice",
          detail: "Saving Beneficiary Dependant Conguration...",
        });

        const postData: AddNotificationPolicyAttachmentBeneficiaryVM = {
          notificationPolicyAttachmentId: this.configureEntityInView.id,
          beneficiaryIds: this.selectedBeneficiaryDependants.map((x) => x.id),
        };
        postData.beneficiaryIds.push(
          ...this.selectedClientBeneficiaries.map((x) => x.id)
        );

        this._saveBeneficairiesConfiguration(postData);
      },
    });
  }

  _saveBeneficairiesConfiguration(
    postData: AddNotificationPolicyAttachmentBeneficiaryVM
  ) {
    this.convergenceService
      .AddNotificationPolicyAttachmentBeneficiary(postData)
      .subscribe(
        async () => {
          this.messageService.add({
            severity: "success",
            summary: "Completed",
            detail: "Beneficiary Configuration Successfull!",
          });
          this.ResetMessageToasters();
          this.HideConfigureDialog();
          this.notificationPolicyInView = null;
          this.FetchAllPolicies();
        },
        (error) => {
          console.log("Error: " + JSON.stringify(error));
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail:
              "Unable to add beneficiary configuration at the moment.. Reason: [" +
              (error ? error.error.message : "request failed - permission") +
              "]",
          });
          this.ResetMessageToasters();
        }
      );
  }

  GetBeneficiaryName(beneficiaryFamilyCode: string): string {
    let beneficiary = this.allClientBeneficiaries.find(
      (x) => x.beneficiaryFamilyCode == beneficiaryFamilyCode
    );
    if (beneficiary) return beneficiary.lastName + " " + beneficiary.firstName;

    return "N/A";
  }

  ConfigureWebhookUrl(item: ConvergenceNotificationPolicyAttachment) {
    this.openWebhookConfig = true;
    this.policyAttachForWebhookUrlConfig = item;
  }

  HideConfigWebhook() {
    this.openWebhookConfig = false;
    this.policyAttachForWebhookUrlConfig = null;
  }

  SaveWebhookConfiguration() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Saving Config...",
    });

    this.convergenceService
      .UpdateNotificationPolicyAttachment(
        this.policyAttachForWebhookUrlConfig.id,
        {
          webhookUrl:
            this.policyAttachForWebhookUrlConfig.notificationWebhookUrl,
        }
      )
      .subscribe(
        async () => {
          this.messageService.add({
            severity: "success",
            summary: "Removed",
            detail: "Config Saved Successfully",
          });
          this.ResetMessageToasters();

          this.HideConfigWebhook();
        },
        (error) => {
          console.log("Error: " + JSON.stringify(error));
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail:
              "Unable to save config at the moment.. Reason: [" + error
                ? error.error.message
                : "request failed - permission" + "]",
          });
          this.ResetMessageToasters();
        }
      );
  }
}
