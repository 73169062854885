import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ClientContactQualification, CommonResponse, Constant, Designation, Lead, LeadContact} from '../../../../interfaces/home';
import {LeadService} from '../../../../services/lead.service';
import {LeadContactService} from '../../../../services/lead-contact.service';
import {ConfirmationService, MessageService} from 'primeng/api';
import {LeadKeyPersonService} from '../../../../services/lead-key-person.service';
import { DesignationService } from 'src/app/services/designation.service';
import { DialogService } from 'primeng/dynamicdialog';
import { KeyPersonnelComponent } from 'src/app/home/client-management/client-view/key-personnel/key-personnel.component';
import { ClientContactQualificationService } from 'src/app/services/client-contact-qualification.service';

@Component({
  selector: 'app-key-person',
  templateUrl: './key-person.component.html',
  styleUrls: ['./key-person.component.scss'],
  providers: [Constant]
})
export class KeyPersonComponent implements OnInit {
  @Input() isPopup: boolean = false;
  @Output() saveSuccessful = new EventEmitter<Boolean>();
  leadKeyPersonForm: FormGroup;
  CurrentLeadData: Lead = null;
  editingLeadKeyPerson: boolean;
  public selectedLeadKeyPerson: LeadContact;
  designations: Designation[] = [];
  selectedDesignation: Designation = null;
  keyPersonnelRef: any;
  leadKeyPersons: LeadContact[] = [];
  clientContactQualifications: ClientContactQualification[] = [];
  selectedClientContactQualification: ClientContactQualification = null;
  titles : any[] = [];
  selectedTitle : any = null;
  genders : any[] = [];
  selectedGender : any = null;
  constructor(
      private leadService: LeadService,
      private formBuilder: FormBuilder,
      private leadContactService: LeadContactService,
      private clientContactQualificationService: ClientContactQualificationService,
      private designationService: DesignationService,
      private messageService: MessageService,
      private dialogService: DialogService,
      private confirmationService: ConfirmationService,
      private leadKeyPersonServices: LeadKeyPersonService,  
      private constants: Constant
  ) { }
  ngOnInit(): void {
    this.fetchDesignations();
    this.fetchContactQualifications();
    this.editingLeadKeyPerson = false;
    this.titles = this.constants.titles;
    this.genders = this.constants.genders;
    this.leadKeyPersonForm = this.formBuilder.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      mobileNumber: ['', Validators.required],
      designation: [null, Validators.required],
      contactQualification: [null, Validators.required],
      dateOfBirth: [''],
      title: ['',  Validators.required],
      gender: ['',  Validators.required],
      leadId: [''],
    });
    this.leadKeyPersonForm.disable();
    this.leadService.activeLead().subscribe( async (res: any) => {
      if(res){
        this.CurrentLeadData = res;
        this.editingLeadKeyPerson = false;
        this.leadKeyPersonForm.reset();
        await this.leadKeyPersonForm.enable();
        const _leadKeyPersonContact = res.leadKeyPeople ?? [];
        console.log('Lead key persons ', _leadKeyPersonContact)
        this.leadKeyPersons = _leadKeyPersonContact;
        if(_leadKeyPersonContact && _leadKeyPersonContact.length > 0){
          /*this.editingLeadKeyPerson = true;
          this.selectedLeadKeyPerson = _leadKeyPersonContact[0];
          this.leadKeyPersonForm.patchValue({
            firstName: this.selectedLeadKeyPerson.firstName,
            lastName: this.selectedLeadKeyPerson.lastName,
            email: this.selectedLeadKeyPerson.email,
            mobileNumber: this.selectedLeadKeyPerson.mobileNumber,
            dateOfBirth: this.selectedLeadKeyPerson.dateOfBirth,
            leadId: this.selectedLeadKeyPerson.leadId,
          });
          this.selectedTitle = this.titles.find(title => title.value.toLowerCase() === this.selectedLeadKeyPerson.title.toLowerCase());
          this.selectedGender = this.genders.find(gender => gender.value.toLowerCase() === this.selectedLeadKeyPerson.gender.toLowerCase());
          this.selectedDesignation = this.designations.find(x => x.id === this.selectedLeadKeyPerson.designationId);
          this.selectedClientContactQualification = this.clientContactQualifications
              .find(x => x.id === this.selectedLeadKeyPerson.clientContactQualificationId);*/
        } 
      }
    });
  }

  async fetchDesignations() {
    await this.designationService.allDesignationData().subscribe(async (res: CommonResponse) => {
        this.designations = res.responseData;
    }, error => this.connectionError());  
  }

  async fetchContactQualifications() {
    await this.clientContactQualificationService.allClientContactQualificationData().subscribe(async (res: CommonResponse) => {
        this.clientContactQualifications = res.responseData;
    }, error => this.connectionError());  
  }

  createContact() {
    this.messageService.add({severity:'info', summary: 'Notice',
      detail:'Processing Lead Key Person Creation'});
    this.leadKeyPersonForm.get('leadId').setValue(this.CurrentLeadData.id);
    const postData= this.leadKeyPersonForm.value; 
    postData.gender = this.leadKeyPersonForm.value.gender.value;
    postData.title = this.leadKeyPersonForm.value.title.value;
    postData.designationId = this.selectedDesignation.id;
    postData.clientContactQualificationId = this.selectedClientContactQualification.id;
    if(this.leadKeyPersonForm.valid) {
      this.leadKeyPersonServices.postLeadKeyPerson(postData).subscribe(async (res: any) => {
        this.saveSuccessful.emit(true);
        this.messageService.add({severity:'success', summary: 'Notice',
          detail:'Lead Key Person Submitted'});
        await this.leadKeyPersonForm.reset();
        this.leadService.refreshData();
      });
    }
  }

  updateContact() {
    this.messageService.add({severity:'info', summary: 'Notice',
      detail:'Processing Lead Key Person Update'});
    this.leadKeyPersonForm.get('leadId').setValue(this.CurrentLeadData.id);
    const postData = this.leadKeyPersonForm.value; 
    postData.gender = this.leadKeyPersonForm.value.gender.value;
    postData.title = this.leadKeyPersonForm.value.title.value;
    postData.designationId = this.selectedDesignation.id;
    postData.clientContactQualificationId = this.selectedClientContactQualification.id;
    if(this.leadKeyPersonForm.valid) {
      this.leadKeyPersonServices.updateLeadKeyPerson(this.selectedLeadKeyPerson.id, postData).subscribe( (res: any) => {
        this.messageService.add({severity:'success', summary: 'Notice',
          detail:'Lead Key Person Submitted'});
        this.leadService.refreshData();
      } ) ;
    }
  }

  async cancelEditContact() {
    this.editingLeadKeyPerson = false;
    this.leadKeyPersonForm.reset();
    await this.leadKeyPersonForm.enable();
  }

  viewKeyPersonnels(){
    if(this.leadKeyPersons){
      this.keyPersonnelRef = this.dialogService.open(KeyPersonnelComponent, {
        header: 'Key Personnels',
        width: '80%',
        contentStyle: {'min-height': '350px', overflow: 'auto'},
        baseZIndex: 10000,
        data: {keyPersonnels: this.leadKeyPersons, editable: true}
      });

      this.keyPersonnelRef.onClose.subscribe(async (res: any) => {
        if(res){
            this.editingLeadKeyPerson = true;
            this.selectedLeadKeyPerson = res;
            this.leadKeyPersonForm.patchValue({
              firstName: this.selectedLeadKeyPerson.firstName,
              lastName: this.selectedLeadKeyPerson.lastName,
              email: this.selectedLeadKeyPerson.email,
              mobileNumber: this.selectedLeadKeyPerson.mobileNumber,
              dateOfBirth: this.selectedLeadKeyPerson.dateOfBirth,
              leadId: this.selectedLeadKeyPerson.leadId,
            });
            this.selectedTitle = this.titles.find(title => title.value.toLowerCase() === this.selectedLeadKeyPerson.title.toLowerCase());
            this.selectedGender = this.genders.find(gender => gender.value.toLowerCase() === this.selectedLeadKeyPerson.gender.toLowerCase());
            this.selectedDesignation = this.designations.find(x => x.id === this.selectedLeadKeyPerson.designationId);
            this.selectedClientContactQualification = this.clientContactQualifications
                .find(x => x.id === this.selectedLeadKeyPerson.clientContactQualificationId);
        }
      })
    }
  }

  private connectionError() {
    this.messageService.add({
        severity: 'error', summary: 'Failed',
        detail: 'Connection Error, Please try again'
    });
  }
}
