import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { ConfirmationService, MessageService } from "primeng/api";
import { BreadcrumbService } from "src/app/breadcrumb.service";
import {
  SpecialApprovalVM,
  SpecialRequest,
  SpecialRequestBatch,
} from "src/app/interfaces/employment";
import {
  ApproveSpecialRequestsVM,
  CalendarMonthEnum,
  PayrollApprovingOffice,
} from "src/app/interfaces/payroll";
import { PayrollService } from "src/app/services/payroll.service";

@Component({
  selector: "app-payroll-special-req-approval",
  templateUrl: "./payroll-special-req-approval.component.html",
  styleUrls: ["./payroll-special-req-approval.component.scss"],
})
export class PayrollSpecialReqApprovalComponent implements OnInit {
  @ViewChild("formWrapper") public formWrapper: ElementRef;
  cautionForm: FormGroup;
  allPendingApprovals: SpecialRequestBatch[];
  pendingApprovalCols: any[];
  allPendingApprovalDits: SpecialRequest[] = [];
  selectedPendingApprovalDits: SpecialRequest[] = [];
  pendingApprovalDitsCols: any[];
  allApprovingOffice: PayrollApprovingOffice[];
  showDits: boolean = false;
  openCautionDialogue: boolean;
  openApprovalsDialogue: boolean;
  cautionText: string;
  cautionCode: number;
  batchInView: SpecialRequestBatch;
  allRequestApprovals: SpecialApprovalVM[];

  constructor(
    private fb: FormBuilder,
    private payrollService: PayrollService,
    private breadcrumbService: BreadcrumbService,
    public confirmationService: ConfirmationService,
    public messageService: MessageService
  ) {
    this.cautionForm = fb.group({});
  }

  ngOnInit(): void {
    this.breadcrumbService.setItems([
      {
        label: "Payroll",
        routerLink: ["/home/payroll/payroll-settings"],
      },
      {
        label: "Special Request Approval",
        routerLink: ["/home/payroll/special-request-approval"],
      },
    ]);

    this.pendingApprovalCols = [{ field: "batchCode", header: "BatchCode" }];

    this.pendingApprovalDitsCols = [
      { field: "operativeName", header: "OperativeName" },
      { field: "clientName", header: "ClientName" },
      { field: "applicableRate", header: "ApplicableRate" },
      { field: "applicableYearDateTime", header: "ApplicableYearDateTime" },
      { field: "totalValue", header: "TotalValue" },
      { field: "applicableYear", header: "ApplicableYear" },
      { field: "noOfDays", header: "NoOfDays" },
      { field: "applicableMonthDateTime", header: "ApplicableMonthDateTime" },
      { field: "applicableMonth", header: "ApplicableMonth" },
    ];

    this.FetchAllApprovingOffice();
    this.GetAllSentForApproval();
  }

  GetAllSentForApproval() {
    this.payrollService.GetAllSpecialRequestsPendingApproval().subscribe(
      async (data) => {
        if (data.responseCode != "00") {
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail: data.responeMessage,
          });

          return;
        }
        var result = data.responseObject as SpecialRequestBatch[];
        this.allPendingApprovals = result;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to get all pending approval at the moment.. Reason: [" +
            error
              ? error.error.message
              : "request failed - permission" + "]",
        });
      }
    );
  }

  FetchAllApprovingOffice() {
    this.payrollService.GetAllSpecialApprovingOfficeAlone().subscribe(
      async (data) => {
        this.allApprovingOffice = data;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all approving office at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  ViewBatchInfo(item: SpecialRequestBatch) {
    // this.allPendingApprovalDits = item.requests;
    this.batchInView = item;
    this.showDits = true;
    this.allPendingApprovalDits = [];
    let office = this.allApprovingOffice.find((x) => x.isMyOffice);
    if (!office) return;
    item.requests.forEach((req) => {
      if (office.sequence == 1 && req.approvals.length <= 0) {
        this.allPendingApprovalDits.push(req);
      } else {
        let sequenceInView = 1;
        while (sequenceInView <= office.sequence) {
          let sequenceOffices = this.allApprovingOffice.filter(
            (x) => x.sequence == sequenceInView
          );

          let findApp = req.approvals.find(
            (x) =>
              sequenceOffices.find((y) => y.id == x.approvingOfficeId) != null
          );

          if (sequenceInView == office.sequence) {
            if (!findApp) {
              this.allPendingApprovalDits.push(req);
            }
          }
          if (findApp) {
            sequenceInView++;
          } else {
            break;
          }
        }
      }
    });
  }

  ApproveSelectedRequests() {
    this.cautionText =
      "Are you sure you want to approve this requests. This is an irreversible action. Do you still wish to proceed?";
    this.cautionCode = 1;
    this.openCautionDialogue = true;
  }

  GetMonthLabel(month: number): string {
    if (month == CalendarMonthEnum.January) return "January";
    if (month == CalendarMonthEnum.February) return "February";
    if (month == CalendarMonthEnum.March) return "March";
    if (month == CalendarMonthEnum.April) return "April";
    if (month == CalendarMonthEnum.May) return "May";
    if (month == CalendarMonthEnum.June) return "June";
    if (month == CalendarMonthEnum.July) return "July";
    if (month == CalendarMonthEnum.August) return "August";
    if (month == CalendarMonthEnum.September) return "September";
    if (month == CalendarMonthEnum.October) return "October";
    if (month == CalendarMonthEnum.November) return "November";
    if (month == CalendarMonthEnum.December) return "December";
    return "N/A";
  }

  HideCautionDialog() {
    this.openCautionDialogue = false;
    this.cautionCode = null;
    this.cautionText = null;
  }

  CautionAction() {
    if (this.cautionCode == 1) {
      this.messageService.add({
        severity: "info",
        summary: "Notice",
        detail: "Approving Requets...",
      });

      const postData: ApproveSpecialRequestsVM = {
        isAllowanceOperation: this.batchInView.isAllowanceOperation,
        batchId: this.batchInView.id,
        requestIds: this.selectedPendingApprovalDits.map((x) => x.id),
      };

      this.payrollService.ApproveSpecialRequests(postData).subscribe(
        async (data) => {
          if (data.responseCode != "00") {
            this.messageService.add({
              severity: "error",
              summary: "Notice",
              detail: data.responeMessage,
            });

            this.HideCautionDialog();
            return;
          }

          this.messageService.add({
            severity: "success",
            summary: "Completed",
            detail: "Approved Successfully!",
          });
          this.HideCautionDialog();

          this.showDits = false;
          this.batchInView = null;
          this.allPendingApprovalDits = [];
          this.selectedPendingApprovalDits = [];
          this.GetAllSentForApproval();
        },
        (error) => {
          console.log("Error: " + JSON.stringify(error));
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail:
              "Unable to approve requests at the moment.. Reason: [" + error
                ? error.error.message
                : "request failed - permission" + "]",
          });
          this.HideCautionDialog();
        }
      );
    }
  }

  ViewApprovals(item: SpecialRequest) {
    this.openApprovalsDialogue = true;
    this.allRequestApprovals = item.approvals;
  }

  HideAprovalsDialog() {
    this.openApprovalsDialogue = false;
    this.allRequestApprovals = [];
  }
}
