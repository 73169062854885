import {Component, OnInit} from '@angular/core';
import {DeliverableFulfillmentService} from '../../../services/deliverable-fulfillment.service';
import {TaskFulfillmentService} from '../../../services/task-fulfillment.service';
import {DeliverableStat, TaskDeliverableSummary, User} from '../../../interfaces/home';
import {FireBaseAuthService} from '../../../services/fire-base-auth.service';
import {DialogService, DynamicDialogRef} from 'primeng/dynamicdialog';
import {DeliverableInfoComponent} from '../deliverable-info/deliverable-info.component';
import {PickDeliverableComponent} from '../pick-deliverable/pick-deliverable.component';
import {ConfirmationService, MessageService} from 'primeng/api';
import {CloseDeliverableComponent} from '../close-deliverable/close-deliverable.component';
import { BreadcrumbService } from 'src/app/breadcrumb.service';

@Component({
    selector: 'app-user-deliverable',
    templateUrl: './user-deliverable.component.html',
    styleUrls: ['./user-deliverable.component.scss'],
    providers: [ConfirmationService]
})
export class UserDeliverableComponent implements OnInit {
    deliverableStat: DeliverableStat;
    authUser: User;
    public taskDeliverableSummaries: TaskDeliverableSummary[]
    loadingTaskDeliverable: boolean;
    today: Date;
    completionRateData: any;
    private dialogRef: DynamicDialogRef;
    workLoadData: any;
    deliverablePriority = ['low', 'medium', 'high'];
    deliveryState = ['OnTrack', 'AtRisk', 'Overdue']
    severity = ['success', 'warning', 'danger'];
    public settingIsPicked: boolean;
    pickRateData: any;

    constructor(
        private deliverableFulfilmentService: DeliverableFulfillmentService,
        private taskFulfillmentService: TaskFulfillmentService,
        private firebaseAuthService: FireBaseAuthService,
        private dialogService: DialogService,
        private confirmationService: ConfirmationService,
        private messageService: MessageService,
        private breadcrumbService: BreadcrumbService
    ) {
        this.today = new Date();
    }

    async ngOnInit() {
        this.breadcrumbService.setItems([
            { label: 'Project Management', routerLink: ['/home/my-task'] },
            { label: 'Pick and Close Deliverable', routerLink: ['/home/my-task'] }
          ]);
        this.authUser = await this.firebaseAuthService.authUserProfile;
        await this.fetchDeliverableStat();
        await this.fetchTaskDeliverableSummary();
    }

    fetchDeliverableStat() {
        this.deliverableFulfilmentService.getDeliverableStat(this.authUser.id).subscribe((res: any) => {
            this.deliverableStat = res;
            this.completionRateData = {
                labels: ['Completion Rate'],
                datasets: [
                    {
                        data: [this.deliverableStat.pickRate, 30],
                        backgroundColor: [
                            '#FF6384',
                            '#cccccc',
                        ],
                        hoverBackgroundColor: [
                            '#FF6384',
                        ]
                    }]
            }
            this.workLoadData = {
                labels: ['Work Load'],
                datasets: [
                    {
                        data: [this.deliverableStat.workLoad, 100-this.deliverableStat.workLoad],
                        backgroundColor: [
                            '#496ce9',
                            '#cccccc',
                        ],
                        hoverBackgroundColor: [
                            '#FF6384',
                        ]
                    }]
            };
            this.pickRateData = {
                showLabels: false,
                labels: ['pick Rate', ''],
                datasets: [
                    {
                        data: [this.deliverableStat.pickRate, 100 - this.deliverableStat.pickRate],
                        backgroundColor: [
                            '#064964',
                            '#cccccc',
                        ],
                        hoverBackgroundColor: [
                            '#FF6384',
                        ]
                    }]
            };
        })
    }

    fetchTaskDeliverableSummary() {
        this.loadingTaskDeliverable = true;
        this.taskFulfillmentService.getTaskDeliverableSummary(this.authUser.id).subscribe((result: any) => {
            this.taskDeliverableSummaries = result;
            this.loadingTaskDeliverable = false;
        })
    }

    viewDeliverable() {
        this.dialogRef = this.dialogService.open(DeliverableInfoComponent, {
            height: '100%',
        });
    }

    pickDeliverable(deliverableId: number) {
        this.confirmationService.confirm({
            message: 'Are you sure that you want to proceed?',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            reject: () => {
                this.messageService.add({severity: 'error', summary: 'Rejected', detail: 'Progress canceled'});
            },
            accept: () => {
                this.settingIsPicked = true;
                this.messageService.add({
                    severity: 'info',
                    summary: 'Confirmed',
                    detail: 'Accepting Deliverable Task, Please wait',
                    sticky: false
                });
                this.setDeliverableIsPicked(deliverableId);
            }
        });
    }

    closeDeliverables(deliverableId: number) {
        
      this.dialogRef = this.dialogService.open(CloseDeliverableComponent, {
          header: 'Close Deliverable',
          data: deliverableId
      });
      this.dialogRef.onClose.subscribe( res => {
          if(res === 'complete'){
              this.messageService.add({
                  severity: 'success',
                  summary: 'Confirmed',
                  detail: 'Task Deliverable closed',
                  sticky: false
              });
              this.fetchTaskDeliverableSummary();
          }
      })
    }

    private setDeliverableIsPicked(deliverableId: number) {
        // set the deliverable to isPicked
        this.deliverableFulfilmentService.setIsPicked(deliverableId).subscribe(async (res: any) => {
            this.settingIsPicked = false;
            this.messageService.add({severity: 'success', summary: 'Confirmed', detail: 'Deliverable Accepted', sticky: false});
            await this.fetchDeliverableStat();
            await this.fetchTaskDeliverableSummary();
        }, error => {
            this.settingIsPicked = false;
        })
    }
}
