import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { CommonResponse, Contact } from "src/app/interfaces/home";
import { environment } from "src/environments/environment";

@Injectable()
export class ContactsService {
  constructor(public http: HttpClient) {}

  getCustomerDivisionList() {
    return this.http.get(environment.baseUrl + "Division");
  }

  getCustomerDesignationList() {
    return this.http.get(environment.baseUrl + "Designation");
  }

  createContact(body: any) {
    return this.http.post(
      environment.baseUrl + "contact/CreateNewContact",
      body
    );
  }

  getContactList() {
    return this.http.get(environment.baseUrl + "contact/GetAllContacts");
  }

  getEntireContactList() {
    return this.http.get(environment.baseUrl + "Contact/GetEntireContacts");
  }

  disableContact(contactId: number) {
    return this.http.delete(
      environment.baseUrl + "contact/DisableContact/" + contactId
    );
  }

  updateContacts(contactId: number, body) {
    return this.http.put(
      environment.baseUrl + "contact/UpdateContact/" + contactId,
      body
    );
  }

  GetAllContactsWithAttachmentStatus(): Observable<CommonResponse<Contact[]>> {
    return this.http.get<CommonResponse<Contact[]>>(
      environment.baseUrl + "contact/GetAllContactsWithAttachmentStatus"
    );
  }
}
