import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {DynamicDialogRef} from 'primeng/dynamicdialog';

@Component({
  selector: 'app-start-journey',
  templateUrl: './start-journey.component.html',
  styleUrls: ['./start-journey.component.scss']
})
export class StartJourneyComponent implements OnInit {
  journeyForm: FormGroup
  constructor(
      private formBuilder: FormBuilder,
      private dialogRef: DynamicDialogRef,
  ) { }

  ngOnInit(): void {
    this.journeyForm = this.formBuilder.group({
      comment: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(1000)]],
    })
  }
    startJourney() {
    this.closeModal(this.journeyForm.value);
    }

    closeModal(data?: any) {
    this.dialogRef.close(data);
    }
}
