import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { AngularFireStorage } from "@angular/fire/storage";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { EventInput } from "@fullcalendar/core";
import { TimeGridSlicer } from "@fullcalendar/timegrid";
import { ConfirmationService, MessageService, SelectItem } from "primeng/api";
import { Subject } from "rxjs";
import { finalize, takeUntil } from "rxjs/operators";
import { BreadcrumbService } from "src/app/breadcrumb.service";
import {
  ArmadaConstant,
  ArmedEscortProfile,
  ArmedEscortRank,
  ArmedEscortType,
  CommanderProfile,
  CommanderRank,
  CommanderType,
  CommanderUserProfile,
  Gender,
  MeansOfId,
  PilotProfile,
  PilotRank,
  PilotType,
  Supplier,
  SupplierServices,
  VehicleProfile,
  VehicleType,
} from "src/app/interfaces/armada";
import {
  Branch,
  CommonResponse,
  Constant,
  Office,
  User,
} from "src/app/interfaces/home";
import { ArmedEscortRankService } from "src/app/services/armada/armed-escort-rank.service";
import { ArmedEscortTypeService } from "src/app/services/armada/armed-escort-type.service";
import { CommanderRankService } from "src/app/services/armada/commander-rank.service";
import { CommanderTypeService } from "src/app/services/armada/commander-type.service";
import { PilotRankService } from "src/app/services/armada/pilot-rank.service";
import { PilotTypeService } from "src/app/services/armada/pilot-type.service";
import { ResourceRegistrationService } from "src/app/services/armada/resource-registration.service";
import { VehicleTypeService } from "src/app/services/armada/vehicle-type.service";
import { BranchService } from "src/app/services/branch.service";
import { FileStorageService } from "src/app/services/file-storage.service";
import { InventoryService } from "src/app/services/inventory.service";
import { OfficeService } from "src/app/services/office.service";
import { SupplierServiceService } from "src/app/services/supplier-service.service";
import { SupplierService } from "src/app/services/supplier.service";
import { UserService } from "src/app/services/user.service";

@Component({
  selector: "app-resource-registration",
  templateUrl: "./resource-registration.component.html",
  styleUrls: ["./resource-registration.component.scss"],
})
export class ResourceRegistrationComponent implements OnInit {
  @ViewChild("formWrapper") public formWrapper: ElementRef;
  private unsubscriber$ = new Subject<void>();

  sortOptions: SelectItem[];
  sortOrder: number;
  sortField: string;
  sortKey: any;
  isOwned: boolean = false;
  isReserved: boolean = false;
  isOwnedCom: boolean = false;
  isReservedCom: boolean = false;
  isOwnedPi: boolean = false;
  isReservedPi: boolean = false;
  isOwnedArm: boolean = false;
  isReservedArm: boolean = false;

  public targetCols: any;
  private exportTargetColumns: any;
  public submittingData: boolean;
  public loading: boolean;
  public submitButtonLabel: string;
  public submitVehicleButtonLabel: string;
  public submitCommanderButtonLabel: string;

  public meansOfId: MeansOfId[];
  itemImageIsChanged: boolean;
  public yearRange: string;
  //Armed Escort
  public armedEscortProfiles: ArmedEscortProfile[];
  public armedEscortProfileForm: FormGroup;
  selectedArmedEscortProfile: any;
  selectedArmedEscortType: ArmedEscortType;
  selectedArmedEscortRank: ArmedEscortRank;
  editingArmedEscortProfile = false;
  private edArmedEscortRank: ArmedEscortRank;
  public armedEscortTypes: ArmedEscortType[];
  public armedEscortRanks: ArmedEscortRank[];
  private edArmedEscortProfile: ArmedEscortProfile;
  maxDateOfBirth: Date;
  availableArmedEscortRanks: ArmedEscortRank[];
  availablePilotRanks: PilotRank[];
  availableCommanderRanks: CommanderRank[];
  availableSupplierServices: SupplierServices[];
  //private constants: Constant;
  //genders: any[] = [];

  //Commander
  public userProfiles: User[];
  public selectedUserProfile: User = null;
  //selectedCommanderUserProfile: CommanderUserProfile;
  public commanderRegisterForm: FormGroup;
  //public commanderUserProfiles: CommanderUserProfile[] = [];

  public commanderProfiles: CommanderProfile[];
  selectedCommanderProfile: CommanderProfile;
  public creatingCommanderProfile: boolean;
  public selectedUser: User | null;
  branches: Branch[];
  offices: Office[];
  Vbranches: Branch[];
  Voffices: Office[];
  public supplierServices: SupplierServices[];
  //public selectedSupplierService: SupplierServices = null;
  public selectedBranch: Branch;
  public selectedOffice: Office;

  registeringCommander: boolean;
  public commanderRanks: CommanderRank[];
  public selectedCommanderRank: CommanderRank;
  public commanderTypes: CommanderType[];
  public selectedCommandertype: CommanderType;

  public vehicleTypes: VehicleType[];
  public selectedVehicleType: VehicleType;
  public vehicleProfiles: VehicleProfile[];
  public selectedSupplierServices: SupplierServices = null;
  public vehicleRegisterForm: FormGroup;
  public vehicleUpdateForm: FormGroup;

  editingVehicleProfile: boolean;
  private edVehicleProfile: VehicleProfile;

  public suppliers: Supplier[];
  public selectedSupplier: Supplier;

  //pilot
  selectedPilotProfile: PilotProfile | null;
  public pilotProfiles: PilotProfile[];
  public editingPilotProfile: boolean;
  private edPilotProfile: PilotProfile;
  public pilotTypes: PilotType[];
  selectedPilotType: PilotType;
  public pilotRanks: PilotRank[];
  //public commanderRanks: CommanderRank[];
  selectedPilotRank: PilotRank;
  public pilotProfileForm: FormGroup;
  userForm: FormGroup;
  public vehicleForm: FormGroup;
  imageSrc: string;
  commanderImageSrc: string;
  uploadedItemImage: any[] = [];
  private documentFIle: any;
  private downloadUrl: any;
  private downloadUrlEscort: any;
  private message: { type: string; value: string };
  public editingCommanderProfile: boolean;
  private edCommanderProfile: CommanderProfile;
  //private constants: ArmadaConstant;
  uploadedPictures: any[] = [];
  uploadedPicturesEscort: any[] = [];
  selectedMeansOfId: MeansOfId;
  selectedGender: Gender;

  genders = [
    {
      id: 1,
      name: "Male",
    },
    {
      id: 2,
      name: "Female",
    },
    {
      id: 3,
      name: "Not Specified",
    },
  ];
  gender: any;
  minimumAge: number;

  constructor(
    private breadcrumbService: BreadcrumbService,

    public messageService: MessageService,
    public confirmationService: ConfirmationService,
    public fireStorage: AngularFireStorage,
    public inventoryService: InventoryService,
    //private constants: ArmadaConstant,
    private formBuilder: FormBuilder,
    private armedEscortRankService: ArmedEscortRankService,
    private armedEscortTypeService: ArmedEscortTypeService,
    private commanderRankService: CommanderRankService,
    private commanderTypeService: CommanderTypeService,
    private fileStorageService: FileStorageService,
    private vehicleTypeService: VehicleTypeService,
    private supplierServiceService: SupplierServiceService,
    private supplierService: SupplierService,
    private officeService: OfficeService,
    private branchServices: BranchService,
    private resourceregistrationService: ResourceRegistrationService,
    private userService: UserService,
    private pilotRankService: PilotRankService,
    private pilotTypeService: PilotTypeService
  ) {
    this.breadcrumbService.setItems([
      { label: "Setup" },
      {
        label: "Resource Registration",
        routerLink: ["/setup/resource-registration"],
      },
    ]);
  }
  ngOnInit(): void {
    this.loading = true;
    //Pilot
    this.targetCols = [
      { field: "firstName", header: "First Name" },
      { field: "lastName", header: "Last Name" },
      { field: "mobile", header: "Mobile" },
      { field: "idNumber", header: "ID Number" },
      { field: "gender", header: "Gender" },
      { field: "dob", header: "dob" },
      { field: "address", header: "Address" },
      { field: "meansOfIdentificationId", header: "Means Of IdentificationId" },
      { field: "imageUrl", header: "imageUrl" },
      { field: "pilotTypeId", header: "Pilot TypeId" },
      { field: "rankId", header: "Rank Id" },
      //{ field: 'codeName', header: 'Code Name' }
    ];
    this.exportTargetColumns = this.targetCols.map((col) => ({
      title: col.header,
      dataKey: col.field,
    }));
    this.pilotProfileForm = this.formBuilder.group({
      firstname: ["", [Validators.required, Validators.minLength(3)]],
      lastname: ["", [Validators.required, Validators.minLength(3)]],
      mobile: ["", [Validators.required, Validators.minLength(3)]],
      idNumber: ["", [Validators.required, Validators.minLength(3)]],
      gender: ["", [Validators.required, Validators.minLength(3)]],
      dob: ["", [Validators.required, Validators.minLength(3)]],
      address: [""],
      meansOfIdentificationId: [
        null,
        [Validators.required, Validators.minLength(3)],
      ],
      imageUrl: [""],
      pilotTypeId: [null, [Validators.required]],
      rankId: [null, [Validators.required]],
      reserveFroPatrolAndOrEmergencyResponse: [false, [Validators.required]],
      isOwned: [false, [Validators.required]],
      //sequence: [''],
    });

    //Armed Escort
    this.targetCols = [
      { field: "firstName", header: "First Name" },
      { field: "lastName", header: "Last Name" },
      { field: "mobile", header: "mobile" },
      //{ field: 'forceId', header: 'ID ' },
      { field: "gender", header: "Gender" },
      { field: "dateOfBirth", header: "dateOfBirth" },
      //{ field: 'address', header: 'Address' },
      //{ field: 'meansOfIdentificationId', header: 'Means Of IdentificationId' },
      { field: "imageUrl", header: "imageUrl" },
      { field: "armedEscortTypeId", header: "ArmedEscort TypeId" },
      { field: "rankId", header: "Rank Id" },
      { field: "alias", header: "Code Name" },
    ];
    this.minimumAge = 18;
    this.maxDateOfBirth = new Date();
    const year = this.maxDateOfBirth.getFullYear() - 18;
    this.maxDateOfBirth.setFullYear(year);
    this.yearRange = year - 100 + ":" + year;
    //this.genders = this.constants.genders;
    this.exportTargetColumns = this.targetCols.map((col) => ({
      title: col.header,
      dataKey: col.field,
    }));
    this.armedEscortProfileForm = this.formBuilder.group({
      firstName: ["", [Validators.required, Validators.minLength(3)]],
      lastName: ["", [Validators.required, Validators.minLength(3)]],
      mobile: ["", [Validators.required, Validators.minLength(3)]],
      //idNumber: ['', [Validators.required, Validators.minLength(3)]],
      gender: ["", [Validators.required, Validators.minLength(3)]],
      dateOfBirth: ["", [Validators.required, Validators.minLength(3)]],
      //address: ['', [Validators.required, Validators.minLength(3)]],
      alias: ["", [Validators.required, Validators.minLength(3)]],
      imageUrl: [""],
      armedEscortTypeId: [null, [Validators.required]],
      rankId: [null, [Validators.required]],
      reserveFroPatrolAndOrEmergencyResponse: [
        this.isReservedArm,
        [Validators.required],
      ],
      isOwned: [this.isOwnedArm, [Validators.required]],
      //sequence: [''],
    });

    //Commander
    this.targetCols = [
      { field: "caption", header: "Caption" },
      { field: "lastName", header: "Last Name" },
      { field: "description", header: "Description" },
      { field: "codeName", header: "Code Name" },
    ];
    this.exportTargetColumns = this.targetCols.map((col) => ({
      title: col.header,
      dataKey: col.field,
    }));
    this.fetchBranches();
    this.fetchAllUsers();
    this.fetchCommanderTypes();
    this.fetchCommanderRanks();
    //Commander
    this.commanderRegisterForm = this.formBuilder.group({
      rankId: ["", Validators.required],
      branchId: ["", Validators.required],
      officeId: ["", Validators.required],
      commanderTypeId: ["", Validators.required],
      //staffId: ['', Validators.required],
      staffId: [{ value: null, disabled: true }, Validators.required],
      reserveFroPatrolAndOrEmergencyResponse: [false, [Validators.required]],
      isOwned: [false, [Validators.required]],
    });

    //this.commanderRegisterForm.disable();
    this.userForm = this.formBuilder.group({
      userProfile: [""],
    });
    this.commanderRegisterForm
      .get("branchId")
      .valueChanges.pipe()
      .subscribe(() => {
        this.selectedBranch = this.commanderRegisterForm.get("branchId").value;
        this.offices = this.selectedBranch?.offices;
      });

    this.userForm.valueChanges
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(() => {
        this.selectedUserProfile = this.userForm.get("userProfile").value;
      });
    // if (this.submittingData) {
    //   this.userForm.disable();
    // } else {
    //   this.userForm.reset();
    // }
    // Vehicle
    this.vehicleRegisterForm = this.formBuilder.group({
      branchId: ["", Validators.required],
      officeId: ["", Validators.required],
      vehicleTypeId: ["", Validators.required],
      supplierServiceId: [{ value: null, disabled: true }, Validators.required],
      reserveFroPatrolAndOrEmergencyResponse: [false, [Validators.required]],
      isOwned: [false, [Validators.required]],
    });

    this.vehicleForm = this.formBuilder.group({
      supplierId: [""],
      supplierService: [""],
    });

    this.vehicleRegisterForm
      .get("branchId")
      .valueChanges.pipe()
      .subscribe(() => {
        this.selectedBranch = this.vehicleRegisterForm.get("branchId").value;
        this.offices = this.selectedBranch?.offices;
      });
    // this.vehicleForm.valueChanges
    //   .pipe(takeUntil(this.unsubscriber$))
    //   .subscribe(() => {
    //     this.selectedSupplierServices =
    //       this.vehicleForm.get("supplierService").value;
    //   });

    // this.commanderRegisterForm.disable();

    this.fetchAllCommanderProfiles();
    this.fetchAllSupplierServices();
    this.fetchVehicleTypes();
    this.fetchAllSuppliers();
    this.fetchAllVehicles();
    this.fetchAllPilotType();
    this.fetchAllPilotRank();
    this.fetchAllArmedEscortRank();
    this.fetchAllArmedEscortType();
    this.fetchAllArmedEscortProfile();
    this.fetchAllMeansOfID();
    this.fetchAllPilotProfile();
    //this.fetchBranches();

    this.submitButtonLabel = "Create";
    this.submitVehicleButtonLabel = "Create";
    this.vehicleRegisterForm.reset();
  }

  //Vehicle

  fetchVehicleTypes() {
    this.vehicleTypeService
      .allVehicleType()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe((r: CommonResponse) => {
        if (r.responseCode == "00") {
          var res = r.responseData;
          this.vehicleTypes = res;
          console.log(res);
        }
      });
  }

  fetchAllVehicles() {
    this.resourceregistrationService
      .allVehicle()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            var res = r.responseData;
            this.vehicleProfiles = res;
            console.log(this.vehicleProfiles);
            this.loading = false;
          }
        },
        (error) => {
          this.connectionError();
          this.loading = false;
          this.submittingData = false;
        }
      );
  }

  fetchAllSuppliers() {
    this.supplierService
      .getAll()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            var res = r.responseData;
            this.suppliers = res;
            this.loading = false;
          }
        },
        (error) => {
          this.connectionError();
          this.loading = false;
          this.submittingData = false;
        }
      );
  }
  fetchAllSupplierServices() {
    this.supplierServiceService
      .getAll()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            var res = r.responseData;
            this.supplierServices = res;
            console.log(res);
            this.loading = false;
          }
        },
        (error) => {
          this.connectionError();
          this.loading = false;
          this.submittingData = false;
        }
      );
  }
  createVehicle() {
    this.submittingData = true;
    const registrationFormValue = this.vehicleRegisterForm.value;
    const formData = {
      attachedBranchId: registrationFormValue.branchId.id,
      attachedOfficeId: registrationFormValue.officeId.id,

      supplierServiceId: this.selectedSupplierServices.id,
      //rankId: registrationFormValue.rankId.id,
      vehicleTypeId: registrationFormValue.vehicleTypeId.id,
      reserveFroPatrolAndOrEmergencyResponse:
        registrationFormValue.registrationFormValue,
      isOwned: registrationFormValue.isOwned,
    };
    this.resourceregistrationService
      .postVehicleProfile(formData)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            //this.fetchCommanderUserProfile();
            this.submittingData = false;
            this.vehicleRegisterForm.reset();
            this.userForm.reset();
            this.selectedSupplierServices = null;
            this.fetchAllVehicles();
            this.messageService.add({
              severity: "success",
              detail: "New Vehcile Registered",
              summary: "Completed",
            });
            this.vehicleRegisterForm.patchValue({
              reserveFroPatrolAndOrEmergencyResponse: false,
              isOwned: false,
            });
          } else {
            this.messageService.add({
              severity: "error",
              summary: "Failed",
              detail: " Error: " + r.responseMsg,
            });
            this.submittingData = false;
          }
        },
        (error) => {
          this.connectionError();
          this.submittingData = false;
        }
      );
  }

  setSupplierService() {
    //console.log("heloo");
    this.availableSupplierServices = [];
    this.supplierServices.forEach((Rank) => {
      if (Rank.supplierId === this.selectedSupplier.id) {
        this.availableSupplierServices.push(Rank);
        console.log(Rank);
      }
    });
  }

  deleteVehicleProfile(vehicleProfile: VehicleProfile): void {
    this.confirmationService.confirm({
      message:
        "Are you sure you want to delete " +
        vehicleProfile.supplierService.vehicleName,
      accept: () => {
        this._deleteVehicleProfile(vehicleProfile);
      },
    });
  }
  _deleteVehicleProfile(vehicleProfile) {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Deleting Vehicle record",
    });
    this.resourceregistrationService.deleteVehicle(vehicleProfile.id).subscribe(
      async (result: any) => {
        await this.messageService.add({
          severity: "success",
          summary: "Deleted",
          detail: "Vehicle record removed",
        });
        await this.fetchAllVehicles();
      },
      (error) => {
        this.connectionError();
      }
    );
  }
  editVehicleProfile(vehicleProfiles: VehicleProfile): void {
    this.formWrapper.nativeElement.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "start",
    });
    this.edVehicleProfile = vehicleProfiles;
    this.editingVehicleProfile = true;
    //this.vehicleRegisterForm.controls["supplierServiceId"].enable();
    this.submitVehicleButtonLabel = "Update";
    // this.submitButtonLabel = "Update";
    //this.commanderImageSrc = vehicleProfile.supplierService.im;
    console.warn(vehicleProfiles);
    this.vehicleRegisterForm.setValue({
      officeId: vehicleProfiles.attachedOfficeId,
      branchId: vehicleProfiles.attachedBranchId,
      isOwned: vehicleProfiles.isOwned,
      supplierServiceId: vehicleProfiles.supplierServiceId,
      reserveFroPatrolAndOrEmergencyResponse:
        vehicleProfiles.reserveFroPatrolAndOrEmergencyResponse,
      vehicleTypeId: vehicleProfiles.vehicleTypeId,
    });

    this.selectedVehicleType = this.vehicleTypes.find(
      (Type) => Type.id === vehicleProfiles.vehicleTypeId
    );
    this.selectedBranch = vehicleProfiles.attachedBranch;
    this.selectedOffice = vehicleProfiles.attachedOffice;
    this.submitVehicleButtonLabel = "Update";
  }

  updateVehicleProfile(): boolean {
    this.submittingData = true;
    if (!this.edVehicleProfile) {
      return false;
    }
    const formData = this.vehicleRegisterForm.value;

    const data = {
      attachedOfficeId: this.selectedOffice.id,
      attachedBranchId: this.selectedBranch.id,
      vehicleTypeId: this.selectedVehicleType.id,
      supplierServiceId: formData.supplierServiceId,
      isOwned: formData.isOwned,
      reserveFroPatrolAndOrEmergencyResponse:
        formData.reserveFroPatrolAndOrEmergencyResponse,
      // sequence: 0
    };
    this.resourceregistrationService
      .updateVehicleProfile(this.edVehicleProfile.id, data)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            this.messageService.add({
              severity: "success",
              detail: "Vehicle  Updated",
              summary: "Completed",
            });
            this.isOwned = false;
            this.isReserved = false;
            this.submittingData = false;
            this.closeEditing();
            this.fetchAllVehicles();
          }
        },
        (error) => {
          this.connectionError();
          this.submittingData = false;
        }
      );
  }

  //Commander
  fetchBranches() {
    this.branchServices.allBranch().subscribe((r: CommonResponse) => {
      if (r.responseCode == "00") {
        var res = r.responseData;
        this.branches = res;
        // console.log(this.branches);
      }
    });
  }
  fetchOffices() {
    this.branchServices.allBranch().subscribe((r: CommonResponse) => {
      if (r.responseCode == "00") {
        var res = r.responseData;
        this.branches = res;
      }
    });
  }

  fetchCommanderRanks() {
    this.commanderRankService
      .allCommanderRank()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe((r: CommonResponse) => {
        if (r.responseCode == "00") {
          var res = r.responseData;
          this.commanderRanks = res;
        }
      });
  }

  fetchAllCommanderProfiles() {
    this.resourceregistrationService
      .allCommanderProfile()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe((r: CommonResponse) => {
        if (r.responseCode == "00") {
          var res = r.responseData;
          this.commanderProfiles = res;
          //console.log(this.commanderProfiles);
        }
      });
  }

  fetchCommanderTypes() {
    this.commanderTypeService
      .allCommanderType()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe((r: CommonResponse) => {
        if (r.responseCode == "00") {
          var res = r.responseData;
          this.commanderTypes = res;
        }
      });
  }

  fetchAllUsers() {
    this.userService
      .allUser()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            var res = r.responseData;
            this.userProfiles = res;
            this.userProfiles.forEach((userProfile) => {
              userProfile.fullName = `${userProfile.firstName} ${userProfile.lastName}`;
            });
            this.loading = false;
          }
        },
        (error) => {
          this.connectionError();
          this.loading = false;
          this.submittingData = false;
        }
      );
  }

  registerCommander() {
    this.commanderRegisterForm.enable();
    this.commanderRegisterForm.get("staffId").disable();
    this.registeringCommander = true;
    this.formWrapper.nativeElement.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "end",
    });
  }

  createCommander() {
    this.submittingData = true;
    const registrationFormValue = this.commanderRegisterForm.value;
    const formData = {
      attachedBranchId: registrationFormValue.branchId.id,
      attachedOfficeId: registrationFormValue.officeId.id,
      //profileId: registrationFormValue.staffId,
      profileId: this.selectedUserProfile.id,
      //userProfileId: this.selectedUserProfile.id,
      rankId: registrationFormValue.rankId.id,
      isOwned: registrationFormValue.isOwned,
      commanderTypeId: registrationFormValue.commanderTypeId.id,
      reserveFroPatrolAndOrEmergencyResponse:
        registrationFormValue.reserveFroPatrolAndOrEmergencyResponse,
      //rankId: this.selectedCommanderRank.id,
    };
    this.resourceregistrationService
      .postCommanderProfile(formData)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            //this.fetchCommanderUserProfile();
            this.submittingData = false;
            this.commanderRegisterForm.reset();
            this.userForm.reset();
            this.selectedUserProfile = null;
            this.fetchAllCommanderProfiles();
            this.messageService.add({
              severity: "success",
              detail: "New commander Registered",
              summary: "Completed",
            });
            this.commanderRegisterForm.patchValue({
              reserveFroPatrolAndOrEmergencyResponse: false,
              isOwned: false,
            });
          } else {
            this.messageService.add({
              severity: "error",
              summary: "Failed",
              detail: " Error: " + r.responseMsg,
            });
            this.submittingData = false;
          }
        },
        (error) => {
          this.connectionError();
          this.submittingData = false;
        }
      );
  }

  deleteCommanderProfile(commanderProfile: CommanderProfile): void {
    this.confirmationService.confirm({
      message:
        "Are you sure you want to delete " + commanderProfile.profile.firstName,
      accept: () => {
        this._deleteCommanderProfile(commanderProfile);
      },
    });
  }
  _deleteCommanderProfile(commanderProfile) {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Deleting Profile record",
    });
    this.resourceregistrationService
      .deleteCommander(commanderProfile.id)
      .subscribe(
        async (result: any) => {
          await this.messageService.add({
            severity: "success",
            summary: "Deleted",
            detail: "Profile record removed",
          });
          await this.fetchAllCommanderProfiles();
        },
        (error) => {
          this.connectionError();
        }
      );
  }
  editCommanderProfile(commanderProfiles: CommanderProfile): void {
    this.formWrapper.nativeElement.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "start",
    });
    this.edCommanderProfile = commanderProfiles;

    this.editingCommanderProfile = true;
    this.submitButtonLabel = "Update";
    this.commanderImageSrc = commanderProfiles.profile.imageUrl;
    this.commanderRegisterForm.setValue({
      officeId: commanderProfiles.attachedOfficeId,
      branchId: commanderProfiles.attachedBranchId,
      staffId: commanderProfiles.profileId,
      isOwned: commanderProfiles.isOwned,
      reserveFroPatrolAndOrEmergencyResponse:
        commanderProfiles.reserveFroPatrolAndOrEmergencyResponse,
      commanderTypeId: commanderProfiles.commanderTypeId,
      rankId: commanderProfiles.rankId,
    });

    this.selectedCommandertype = this.commanderTypes.find(
      (Type) => Type.id === commanderProfiles.commanderTypeId
    );
    this.selectedCommanderRank = this.commanderRanks.find(
      (Rank) => Rank.id === commanderProfiles.rankId
    );
    // this.selectedOffice = this.offices.find(
    //   (office) => office.id === commanderProfiles.attachedOfficeId
    // );
    // this.selectedBranch = this.branches.find(
    //   (branch) => branch.id === commanderProfiles.attachedBranchId
    // );
    this.selectedBranch = commanderProfiles.attachedBranch;
    this.selectedOffice = commanderProfiles.attachedOffice;

    this.submitButtonLabel = "Update";
  }

  updateCommanderProfile(): boolean {
    this.submittingData = true;
    if (!this.edCommanderProfile) {
      return false;
    }
    const formData = this.commanderRegisterForm.value;
    const data = {
      attachedOfficeId: this.selectedOffice.id,
      attachedBranchId: this.selectedBranch.id,
      commanderTypeId: this.selectedCommandertype.id,
      rankId: this.selectedCommanderRank.id,
      reserveFroPatrolAndOrEmergencyResponse:
        formData.reserveFroPatrolAndOrEmergencyResponse,
      isOwned: formData.isOwned,
      // sequence: 0
    };
    this.resourceregistrationService
      .updateCommanderProfile(this.edCommanderProfile.id, data)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            this.messageService.add({
              severity: "success",
              detail: "Commander Profile Updated",
              summary: "Completed",
            });
            this.submittingData = false;
            this.closeEditing();
            this.fetchAllCommanderProfiles();
            this.commanderRegisterForm.patchValue({
              reserveFroPatrolAndOrEmergencyResponse: false,
              isOwned: false,
            });
          }
        },
        (error) => {
          this.connectionError();
          this.submittingData = false;
        }
      );
  }

  //Pilot
  fetchAllPilotProfile(): void {
    this.resourceregistrationService
      .allPilotProfile()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            var res = r.responseData;
            this.pilotProfiles = res;
            this.pilotProfiles.forEach(
              (userProfile) =>
                (userProfile.fullName = `${userProfile.firstname} ${userProfile.lastname}`)
            );
            this.loading = false;
          }
        },
        (error) => {
          this.loading = false;
          this.connectionError();
        }
      );
    this.submittingData = false;
  }
  fetchAllPilotType(): void {
    this.pilotTypeService
      .allPilotType()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            var result = r.responseData;
            this.pilotTypes = result;
          }
        },
        (error) => this.connectionError()
      );
    this.submittingData = false;
  }

  fetchAllMeansOfID(): void {
    this.resourceregistrationService
      .allMeansOfId()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            var result = r.responseData;
            this.meansOfId = result;
          }
        },
        (error) => this.connectionError()
      );
  }
  fetchAllPilotRank(): void {
    this.pilotRankService
      .allPilotRank()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            var res = r.responseData;
            this.pilotRanks = res;
            this.loading = false;
          }
        },
        (error) => {
          //this.loading = false;
          this.connectionError();
        }
      );
  }
  onUploadPicture(event) {
    this.uploadedPictures = [];
    for (const file of event.files) {
      this.uploadedPictures.push(file);
    }
  }
  savePilotProfile() {
    this.submittingData = true;
    if (this.documentFIle) {
      this.fileStorageService.UploadFileFromDataUrl(this.documentFIle);
      this.fileStorageService.onUploadFinished.subscribe(
        (resp: CommonResponse<string[]>) => {
          if (resp.responseCode != "00") {
            this.messageService.add({
              severity: "error",
              summary: "Notice",
              detail: resp.responseMsg,
            });
          } else {
            this.downloadUrl = resp.responseData;
            if (!this.editingPilotProfile) {
              this.createPilotProfile();
            } else {
              this.updatePilotProfile();
            }
            console.log(resp);
            this.submittingData = false;
          }
        },
        (error) => {
          this.submittingData = false;
          console.log("Error while creating platform " + error);
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail: "ERR: Unable to upload image to storage",
          });
        }
      );
    } else {
      this.messageService.add({
        severity: "error",
        summary: "Notice",
        detail: "ERR: Unable to upload image to file",
      });
    }
  }

  savePilotProfileOld() {
    this.submittingData = true;
    if (this.documentFIle) {
      this.fileStorageService.UploadMultipleFilesFromDataUrl([
        this.documentFIle,
      ]);
      this.fileStorageService.onUploadFinished.subscribe(
        (resp: CommonResponse<string[]>) => {
          if (resp.responseCode != "00") {
            this.messageService.add({
              severity: "error",
              summary: "Notice",
              detail: resp.responseMsg,
            });
          } else {
            this.downloadUrl = resp.responseData[0];
            if (!this.editingPilotProfile) {
              this.createPilotProfile();
            } else {
              this.updatePilotProfile();
            }
          }
        },
        (error) => {
          console.log("Error while uploading files " + error);
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail: "ERR: Unable to upload image to storage",
          });
        }
      );
    } else {
      this.downloadUrl = this.edPilotProfile.imageUrl ?? "";
      if (!this.editingPilotProfile) {
        this.createPilotProfile();
      } else {
        this.updatePilotProfile();
      }
    }
  }

  createPilotProfile() {
    const formData = this.pilotProfileForm.value;
    const postData = {
      firstname: formData.firstname,
      lastname: formData.lastname,
      mobile: formData.mobile,
      idNumber: formData.idNumber,
      dob: formData.dob,
      address: formData.address,
      gender: formData.gender.name,
      isOwned: formData.isOwned,
      meansOfIdentificationId: this.selectedMeansOfId.id,
      imageUrl: this.downloadUrl,
      pilotTypeId: this.selectedPilotType.id,
      rankId: this.selectedPilotRank.id,
      reserveFroPatrolAndOrEmergencyResponse:
        formData.reserveFroPatrolAndOrEmergencyResponse,
    };
    this.resourceregistrationService
      .postPilotProfile(postData)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            this.messageService.add({
              summary: "Success",
              severity: "success",
              detail: "New Pilot Profile  created",
            });
            this.fetchAllPilotProfile();
            this.submittingData = false;
            this.pilotProfileForm.reset();

            this.imageSrc = "";
            this.pilotProfileForm.patchValue({
              reserveFroPatrolAndOrEmergencyResponse: false,
              isOwned: false,
            });
            this.closeEditing();
          } else {
            this.connectionError();
          }
        },
        (error) => {
          this.connectionError();
        }
      );
    this.submittingData = false;
  }
  clearImage() {
    //this.downloadUrl = "";
    this.imageSrc = "";
  }

  deletePilotProfile(pilotProfile: PilotProfile): void {
    this.confirmationService.confirm({
      message: "Are you sure you want to delete " + pilotProfile.firstname,
      accept: () => {
        this._deletePilotProfile(pilotProfile);
      },
    });
  }
  _deletePilotProfile(pilotProfile) {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Deleting Profile record",
    });
    this.resourceregistrationService.delete(pilotProfile.id).subscribe(
      async (result: any) => {
        await this.messageService.add({
          severity: "success",
          summary: "Deleted",
          detail: "Profile record removed",
        });
        await this.fetchAllPilotProfile();
      },
      (error) => {
        this.connectionError();
      }
    );
  }
  editPilotProfile(pilotProfiles: PilotProfile): void {
    this.formWrapper.nativeElement.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "start",
    });
    this.edPilotProfile = pilotProfiles;
    this.editingPilotProfile = true;
    this.imageSrc = pilotProfiles.imageUrl;
    this.pilotProfileForm.setValue({
      firstname: pilotProfiles.firstname,
      lastname: pilotProfiles.lastname,
      mobile: pilotProfiles.mobile,
      idNumber: pilotProfiles.idNumber,
      gender: pilotProfiles.gender,
      isOwned: pilotProfiles.isOwned,
      dob: new Date(pilotProfiles.dob ?? this.maxDateOfBirth),
      address: pilotProfiles.address,
      meansOfIdentificationId: pilotProfiles.meansOfIdentificationId,
      imageUrl: "",
      reserveFroPatrolAndOrEmergencyResponse:
        pilotProfiles.reserveFroPatrolAndOrEmergencyResponse,
      pilotTypeId: pilotProfiles.pilotTypeId,
      rankId: pilotProfiles.rankId,
    });
    this.selectedPilotType = this.pilotTypes.find(
      (Type) => Type.id === pilotProfiles.pilotTypeId
    );
    this.selectedPilotRank = this.pilotRanks.find(
      (Rank) => Rank.id === pilotProfiles.rankId
    );
    this.selectedGender = this.genders.find(
      (Rank) => Rank.name === pilotProfiles.gender
    );
    this.selectedMeansOfId = this.meansOfId.find(
      (Rank) => Rank.id === pilotProfiles.meansOfIdentificationId
    );
    this.selectedGender = this.genders.find(
      (gender) => gender.name === pilotProfiles.gender
    );
    this.submitButtonLabel = "Update";
  }
  updatePilotProfile(): boolean {
    this.submittingData = true;
    if (!this.edPilotProfile) {
      return false;
    }
    const formData = this.pilotProfileForm.value;
    const data = {
      firstname: formData.firstname,
      lastname: formData.lastname,
      mobile: formData.mobile,
      idNumber: formData.idNumber,
      gender: formData.gender.name,
      dob: formData.dob,
      isOwned: formData.isOwned,
      address: formData.address,
      meansOfIdentificationId: formData.meansOfIdentificationId.id,
      reserveFroPatrolAndOrEmergencyResponse:
        formData.reserveFroPatrolAndOrEmergencyResponse,
      imageUrl: this.downloadUrl,
      pilotTypeId: this.selectedPilotType.id,
      rankId: this.selectedPilotRank.id,
      // sequence: 0
    };
    this.resourceregistrationService
      .updatePilotProfile(this.edPilotProfile.id, data)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            this.messageService.add({
              severity: "success",
              detail: "Pilot Profile Updated",
              summary: "Completed",
            });
            this.submittingData = false;
            this.imageSrc = "";
            this.closeEditing();
            this.fetchAllPilotProfile();
            this.pilotProfileForm.patchValue({
              reserveFroPatrolAndOrEmergencyResponse: false,
              isOwned: false,
            });
          }
        },
        (error) => {
          this.connectionError();
          this.submittingData = false;
        }
      );
  }

  //Armed Escort
  fetchAllArmedEscortProfile(): void {
    this.resourceregistrationService
      .allArmedEscortProfile()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            var res = r.responseData;
            this.armedEscortProfiles = res;
            this.armedEscortProfiles.forEach(
              (userProfile) =>
                (userProfile.fullName = `${userProfile.firstName} ${userProfile.lastName}`)
            );
            this.loading = false;
          }
        },
        (error) => this.connectionError()
      );
    this.submittingData = false;
  }

  async fetchAllArmedEscortRank() {
    await this.armedEscortRankService
      .allArmedEscortRank()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            var res = r.responseData;
            this.armedEscortRanks = res;
          }
        },
        (error) => this.connectionError()
      );
  }
  NotifyUserToClickUpload() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Kindly ensure you click upload.",
    });

    //this.userIsYetToClickUploadPicture = true;
  }
  fetchAllArmedEscortType() {
    this.armedEscortTypeService
      .allArmedEscortType()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            var res = r.responseData;
            this.armedEscortTypes = res;
          }
        },
        (error) => this.connectionError()
      );
  }

  saveArmedEscortProfile() {
    this.submittingData = true;
    if (this.documentFIle) {
      this.fileStorageService.UploadFileFromDataUrl(this.documentFIle);
      this.fileStorageService.onUploadFinished.subscribe(
        (resp: CommonResponse<string[]>) => {
          if (resp.responseCode != "00") {
            this.messageService.add({
              severity: "error",
              summary: "Notice",
              detail: resp.responseMsg,
            });
          } else {
            this.downloadUrlEscort = resp.responseData;
            if (!this.editingArmedEscortProfile) {
              this.createArmedEscortProfile();
            } else {
              this.updateArmedEscortProfile();
            }
            console.log(resp);
            this.submittingData = false;
          }
        },
        (error) => {
          this.submittingData = false;
          console.log("Error while creating platform " + error);
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail: "ERR: Unable to upload image to storage",
          });
        }
      );
    } else {
      this.messageService.add({
        severity: "error",
        summary: "Notice",
        detail: "ERR: Unable to upload image to file",
      });
    }
  }
  saveArmedEscortProfileOld() {
    this.submittingData = true;
    if (this.documentFIle) {
      this.fileStorageService.UploadMultipleFilesFromDataUrl([
        this.documentFIle,
      ]);
      this.fileStorageService.onUploadFinished.subscribe(
        (resp: CommonResponse<string[]>) => {
          if (resp.responseCode != "00") {
            this.messageService.add({
              severity: "error",
              summary: "Notice",
              detail: resp.responseMsg,
            });
          } else {
            this.downloadUrl = resp.responseData[0];
            if (!this.editingArmedEscortProfile) {
              this.createArmedEscortProfile();
            } else {
              this.updateArmedEscortProfile();
            }
          }
        },
        (error) => {
          console.log("Error while uploading files " + error);
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail: "ERR: Unable to upload image to storage",
          });
        }
      );
    } else {
      this.downloadUrl = this.edArmedEscortProfile.imageUrl ?? "";
      if (!this.editingArmedEscortProfile) {
        this.createArmedEscortProfile();
      } else {
        this.updateArmedEscortProfile();
      }
    }
  }
  createArmedEscortProfile() {
    const formData = this.armedEscortProfileForm.value;
    const postData = {
      gender: formData.gender.name,
      imageUrl: this.downloadUrlEscort,
      rankId: this.selectedArmedEscortRank.id,
      armedEscortTypeId: this.selectedArmedEscortType.id,
      alias: formData.alias,
      mobile: formData.mobile,
      //isOwned: formData.isOwned,
      isOwned: this.isOwnedArm,
      dateOfBirth: formData.dateOfBirth,
      lastName: formData.lastName,
      firstName: formData.firstName,
      reserveFroPatrolAndOrEmergencyResponse: this.isReservedArm,
      //formData.reserveFroPatrolAndOrEmergencyResponse,
    };
    this.resourceregistrationService
      .postArmedEscortProfile(postData)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            this.messageService.add({
              summary: "Success",
              severity: "success",
              detail: "New Escort Profile  created",
            });
            this.fetchAllArmedEscortProfile();
            this.submittingData = false;
            this.armedEscortProfileForm.reset();
            this.imageSrc = "";
            this.closeEditing();
            this.armedEscortProfileForm.patchValue({
              reserveFroPatrolAndOrEmergencyResponse: false,
              isOwned: false,
            });
          } else {
            this.connectionError();
          }
        },
        (error) => {
          this.connectionError();
        }
      );
    this.submittingData = false;
  }

  deleteArmedEscortProfile(armedEscortProfiles: ArmedEscortProfile): void {
    this.confirmationService.confirm({
      message:
        "Are you sure you want to delete " + armedEscortProfiles.fullName,
      accept: () => {
        this._deleteArmedEscortProfile(armedEscortProfiles);
      },
    });
  }

  editArmedEscortProfile(armedEscortProfiles: ArmedEscortProfile): void {
    this.formWrapper.nativeElement.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "start",
    });
    this.edArmedEscortProfile = armedEscortProfiles;
    this.editingArmedEscortProfile = true;
    this.submitButtonLabel = "Update";
    this.imageSrc = armedEscortProfiles.imageUrl;
    this.armedEscortProfileForm.setValue({
      gender: armedEscortProfiles.gender,
      imageUrl: "",
      mobile: armedEscortProfiles.mobile,
      rankId: armedEscortProfiles.rankId,
      armedEscortTypeId: armedEscortProfiles.armedEscortTypeId,
      alias: armedEscortProfiles.alias,
      isOwned: armedEscortProfiles.isOwned,
      lastName: armedEscortProfiles.lastName,
      reserveFroPatrolAndOrEmergencyResponse:
        armedEscortProfiles.reserveFroPatrolAndOrEmergencyResponse,
      firstName: armedEscortProfiles.firstName,
      dateOfBirth: new Date(
        armedEscortProfiles.dateOfBirth ?? this.maxDateOfBirth
      ),
    });
    this.selectedArmedEscortType = this.armedEscortTypes.find(
      (escortType) => escortType.id === armedEscortProfiles.armedEscortTypeId
    );
    this.selectedArmedEscortRank = this.armedEscortRanks.find(
      (rank) => rank.id === armedEscortProfiles.rankId
    );
    this.selectedGender = this.genders.find(
      (gender) => gender.name === armedEscortProfiles.gender
    );
  }

  _deleteArmedEscortProfile(armedEscortProfiles) {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Deleting Situation Type record",
    });
    this.resourceregistrationService
      .deleteArmedEscort(armedEscortProfiles.id)
      .subscribe(
        async (result: any) => {
          await this.messageService.add({
            severity: "success",
            summary: "Deleted",
            detail: "Armed Escort record removed",
          });
          await this.fetchAllArmedEscortProfile();
        },
        (error) => {
          this.connectionError();
        }
      );
    this.submittingData = false;
  }

  setArmedEscortRank() {
    this.availableArmedEscortRanks = [];
    this.armedEscortRanks.forEach((escortRank) => {
      if (escortRank.armedEscortTypeId === this.selectedArmedEscortType.id) {
        this.availableArmedEscortRanks.push(escortRank);
      }
    });
  }

  setPilotRank() {
    this.availablePilotRanks = [];
    this.pilotRanks.forEach((Rank) => {
      if (Rank.pilotTypeId === this.selectedPilotType.id) {
        this.availablePilotRanks.push(Rank);
        //console.log(Rank);
      }
    });
  }

  setCommanderRank() {
    this.availableCommanderRanks = [];
    this.commanderRanks.forEach((Rank) => {
      if (Rank.commanderTypeId === this.selectedCommandertype.id) {
        this.availableCommanderRanks.push(Rank);
        console.log(Rank);
      }
    });
  }

  updateArmedEscortProfile() {
    const formData = this.armedEscortProfileForm.value;
    const postData = {
      gender: formData.gender.name,
      imageUrl: this.downloadUrlEscort,
      mobile: formData.mobile,
      rankId: this.selectedArmedEscortRank.id,
      armedEscortTypeId: this.selectedArmedEscortType.id,
      alias: formData.alias,
      dateOfBirth: formData.dateOfBirth,
      lastName: formData.lastName,
      firstName: formData.firstName,
      //isOwned: formData.isOwned,
      isOwned: this.isOwnedArm,
      reserveFroPatrolAndOrEmergencyResponse: this.isReservedArm,
      //formData.reserveFroPatrolAndOrEmergencyResponse,
    };
    this.resourceregistrationService
      .updateArmedEscortProfile(this.edArmedEscortProfile.id, postData)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            this.messageService.add({
              summary: "Success",
              severity: "success",
              detail: "Armed Escort Profile updated",
            });
            this.fetchAllArmedEscortProfile();
            this.submittingData = false;
            this.armedEscortProfileForm.reset();
            this.imageSrc = "";
            this.armedEscortProfileForm.patchValue({
              reserveFroPatrolAndOrEmergencyResponse: false,
              isOwned: false,
            });

            this.closeEditing();
          } else {
            this.messageService.add({
              severity: "error",
              summary: "Failed",
              detail: " Error: " + r.responseMsg,
            });
            this.submittingData = false;
          }
        },
        (error) => {
          this.connectionError();
          this.submittingData = false;
        }
      );
  }

  onFileSelect(event) {
    if (!this.checkMimeType(event) || !this.checkFileSize(event)) {
      this.armedEscortProfileForm.get("imageUrl").reset();
      return;
    }
    const reader = new FileReader();
    this.message = null;
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      this.documentFIle = event.target.files[0];
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.imageSrc = reader.result as string;
      };
    }
  }

  checkMimeType(event) {
    const files = event.target.files[0];
    // list allow mime type
    const types = ["image/jpeg", "image/png", "image/jpg"];
    // loop access array
    if (!files) {
      this.messageService.add({
        severity: "error",
        summary: "Failed",
        detail: "Please select a file to continue",
      });
      this.message = {
        type: "danger",
        value: "Please select a file to continue",
      };
      return false;
    }
    // compare file type find doesn't match
    if (types.every((type) => files.type !== type)) {
      // create error message and assign to container
      this.messageService.add({
        severity: "error",
        summary: "Failed",
        detail: "Image format not supported",
      });
      this.message = { type: "danger", value: "Image format not supported" };
      return false;
    }
    return true;
  }

  checkFileSize(event: EventInput) {
    const files = event.target.files[0];
    if (!files) return true;
    const size = 1500000;
    if (files.size > size) {
      event.target.value = null;
      this.messageService.add({
        severity: "error",
        summary: "Failed",
        detail: "Image is too large, please pick a smaller file",
      });
      this.message = {
        type: "danger",
        value: "Image is too large, please pick a smaller file",
      };
      return false;
    }
    return true;
  }

  closeEditing() {
    this.editingPilotProfile = false;
    this.pilotProfileForm.reset();
    this.edPilotProfile = null;

    this.editingArmedEscortProfile = false;
    this.armedEscortProfileForm.reset();
    this.edArmedEscortProfile = null;

    this.editingCommanderProfile = false;
    this.commanderRegisterForm.reset();
    this.edCommanderProfile = null;

    this.editingVehicleProfile = false;
    this.vehicleRegisterForm.reset();
    this.edVehicleProfile = null;
    this.submittingData = false;
    this.submitButtonLabel = "Create";
    this.submitVehicleButtonLabel = "Create";
  }
  private connectionError() {
    this.messageService.add({
      severity: "error",
      summary: "Failed",
      detail: "Connection Error, Please try again",
    });
  }
  ngOnDestroy(): void {
    this.unsubscriber$.complete();
    this.unsubscriber$.unsubscribe();
  }
}
