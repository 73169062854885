import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ConfirmationService, MessageService } from "primeng/api";
import { BreadcrumbService } from "src/app/breadcrumb.service";
import { CreateApprovingOffice } from "src/app/interfaces/employment";
import { Account, User } from "src/app/interfaces/home";
import {
  CreateSpecialSettingVM,
  PayrollApprovingOffice,
  PayrollApprovingOfficer,
  SpecialAllowance,
  SpecialDeduction,
  UpdateSpecialSettingVM,
} from "src/app/interfaces/payroll";
import { PayrollService } from "src/app/services/payroll.service";
import { UserService } from "src/app/services/user.service";

@Component({
  selector: "app-payroll-special-setup",
  templateUrl: "./payroll-special-setup.component.html",
  styleUrls: ["./payroll-special-setup.component.scss"],
})
export class PayrollSpecialSetupComponent implements OnInit {
  @ViewChild("formWrapper") public formWrapper: ElementRef;
  allowanceForm: FormGroup;
  deductionForm: FormGroup;
  editingAllowance: boolean = false;
  editingDeductee: boolean = false;
  allAssetAccounts: Account[];
  allExpenseAccounts: Account[];
  theExpenseAccount: Account;
  theAssetAccount: Account;
  fetchingAllowances: boolean = false;
  fetchingDeductions: boolean = false;
  allSpecialAllowances: SpecialAllowance[];
  selectedSpecialAllowances: SpecialAllowance[];
  alloweeCols: any[];
  allSpecialDeductions: SpecialDeduction[];
  selectedSpecialDeductions: SpecialDeduction[];
  deducteeCols: any[];
  allowanceToEdit: SpecialAllowance;
  deductionToEdit: SpecialDeduction;
  approvingOfficeForm: FormGroup;
  theApprovingUser: User;
  allUsers: User[];
  addedApprovingOfficers: PayrollApprovingOfficer[];
  selectedApprovingOfficer: PayrollApprovingOfficer;
  editingApprovingOffice: boolean;
  approvingOfficeToEdit: PayrollApprovingOffice;
  fetchingApprovalOffices = false;
  allApprovingOffice: PayrollApprovingOffice[];
  selectedApprovingOffice: PayrollApprovingOffice;
  approvalOfficeCols: any[];
  allTaxStatus: {
    key: number;
    value: string;
  }[];
  theTaxStatus: {
    key: number;
    value: string;
  };
  isTaxTypeRadioButton: number;
  taxTypeValue: number;
  allAccounts: Account[];
  theTaxAccount: Account;
  allTaxConfigurations: {
    key: number;
    value: string;
  }[];
  theTaxConfiguration: {
    key: number;
    value: string;
  };

  constructor(
    private fb: FormBuilder,
    public userService: UserService,
    private payrollService: PayrollService,
    private breadcrumbService: BreadcrumbService,
    public confirmationService: ConfirmationService,
    public messageService: MessageService
  ) {
    this.allowanceForm = fb.group({
      Caption: ["", Validators.required],
      Description: ["", Validators.required],
      ExpenseAccount: ["", Validators.required],
      TaxStatus: ["", Validators.required],
      TaxCongfiguration: [""],
      TaxAccount: [""],
    });

    this.deductionForm = fb.group({
      Caption: ["", Validators.required],
      Description: ["", Validators.required],
      AssetAccount: ["", Validators.required],
    });

    this.approvingOfficeForm = fb.group({
      ApprovingUser: [""],
      OfficeName: ["", Validators.required],
    });
  }

  ngOnInit(): void {
    this.breadcrumbService.setItems([
      {
        label: "Payroll Management",
        routerLink: ["/home/payroll/payroll-settings"],
      },
      {
        label: "Special Set-Ups",
        routerLink: ["/home/payroll/special-setup"],
      },
    ]);

    this.alloweeCols = [
      { field: "caption", header: "Caption" },
      { field: "description", header: "Description" },
    ];

    this.deducteeCols = [
      { field: "caption", header: "Caption" },
      { field: "description", header: "Description" },
    ];

    this.addedApprovingOfficers = [];
    this.approvalOfficeCols = [{ field: "name", header: "Name" }];

    this.allTaxStatus = [
      {
        key: 0,
        value: "Is Not Taxable",
      },
      {
        key: 1,
        value: "Is Taxable",
      },
    ];

    this.allTaxConfigurations = [
      {
        key: 0,
        value: "Custom Configuration",
      },
      {
        key: 1,
        value: "Payroll Tax Computation",
      },
    ];

    this.FetchAllFilteredAccounts();
    this.FetchAllSpecialAllowances();
    this.FetchAllSpecialDeductions();
    this.FetchAllUsers();
    this.FetchAllApprovingOffice();
  }

  get ApprovingUser() {
    return this.approvingOfficeForm.get("ApprovingUser").value;
  }

  FetchAllUsers() {
    this.userService.allUser().subscribe(
      async (r) => {
        var data = r.responseData ?? [];
        this.allUsers = [];
        data.forEach((user) => {
          user.fullName = user.lastName + " " + user.firstName;
          this.allUsers.push(user);
        });
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all users at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  FetchAllFilteredAccounts() {
    this.payrollService.GetFilteredAccountList().subscribe(
      async (data) => {
        data.crAccounts.forEach((acct) => {
          acct.label = acct.accountNumber + " - " + acct.name;
        });
        data.drAccounts.forEach((acct) => {
          acct.label = acct.accountNumber + " - " + acct.name;
        });

        this.allAssetAccounts = data.crAccounts;
        this.allExpenseAccounts = data.drAccounts;
        this.allAccounts = data.crAccounts;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable get all filtered accounts at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  FetchAllSpecialAllowances() {
    this.payrollService.GetAllSpecialAllowances().subscribe(
      async (data) => {
        if (data.responseCode != "00") {
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail: data.responeMessage,
          });

          this.fetchingAllowances = false;
          return;
        }

        this.allSpecialAllowances = data.responseObject as SpecialAllowance[];
        this.fetchingAllowances = false;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable get all special allowances at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
        this.fetchingAllowances = false;
      }
    );
  }

  FetchAllSpecialDeductions() {
    this.payrollService.GetAllSpecialDeductions().subscribe(
      async (data) => {
        if (data.responseCode != "00") {
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail: data.responeMessage,
          });

          this.fetchingDeductions = false;
          return;
        }

        this.allSpecialDeductions = data.responseObject as SpecialDeduction[];
        this.fetchingDeductions = false;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable get all special deductions at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
        this.fetchingDeductions = false;
      }
    );
  }

  CreateSAllowance() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Creating Special Allowance...",
    });

    const postData: CreateSpecialSettingVM = {
      caption: this.allowanceForm.get("Caption").value,
      description: this.allowanceForm.get("Description").value,
      accountId: this.theExpenseAccount.id,
      accountNumber: this.theExpenseAccount.accountNumber,
      isSpecialAllowance: true,
      isTaxable: this.theTaxStatus.key == 1 ? true : false,
      isUsingPayrollTaxComputation: this.theTaxConfiguration
        ? this.theTaxConfiguration.key == 1
        : false,
      taxTypeOption: this.isTaxTypeRadioButton,
      taxTypeValue: this.taxTypeValue,
      taxAccountId: this.theTaxAccount.id,
      taxAccountNumber: this.theTaxAccount.accountNumber,
    };

    this.payrollService.CreateSpecialSetting(postData).subscribe(
      async (data) => {
        if (data.responseCode != "00") {
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail: data.responeMessage,
          });

          return;
        }

        this.messageService.add({
          severity: "success",
          summary: "Completed",
          detail: "Special Allowance Created Successfully!",
        });

        this.allowanceForm.reset();
        this.theExpenseAccount = null;
        this.fetchingAllowances = true;
        this.theTaxStatus = null;
        this.isTaxTypeRadioButton = null;
        this.taxTypeValue = null;
        this.theTaxAccount = null;
        this.theTaxConfiguration = null;
        this.FetchAllSpecialAllowances();
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to create special allowance at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  CloseEditingAllowance() {
    this.allowanceForm.reset();
    this.theExpenseAccount = null;
    this.editingAllowance = false;
    this.allowanceToEdit = null;
    this.theTaxStatus = null;
    this.isTaxTypeRadioButton = null;
    this.taxTypeValue = null;
    this.theTaxAccount = null;
    this.theTaxConfiguration = null;
  }

  EditAllowee(item: SpecialAllowance) {
    this.editingAllowance = true;
    this.allowanceForm.patchValue({
      Caption: item.caption,
      Description: item.description,
    });
    this.theExpenseAccount = this.allExpenseAccounts.find(
      (x) => x.id == item.expenseAccountId
    );
    this.allowanceToEdit = item;
    this.theTaxStatus = item.isTaxable
      ? this.allTaxStatus[1]
      : this.allTaxStatus[0];
    this.isTaxTypeRadioButton = item.taxTypeOption;
    this.taxTypeValue = item.taxTypeValue;
    this.theTaxAccount = this.allAccounts.find(
      (x) => x.id == item.taxAccountId
    );

    this.formWrapper.nativeElement.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "start",
    });
  }

  DeleteAllowee(item: SpecialAllowance) {}

  CreateSDeduction() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Creating Special Deduction...",
    });

    const postData: CreateSpecialSettingVM = {
      caption: this.deductionForm.get("Caption").value,
      description: this.deductionForm.get("Description").value,
      accountId: this.theAssetAccount.id,
      accountNumber: this.theAssetAccount.accountNumber,
      isSpecialAllowance: false,
    };

    this.payrollService.CreateSpecialSetting(postData).subscribe(
      async (data) => {
        if (data.responseCode != "00") {
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail: data.responeMessage,
          });

          return;
        }

        this.messageService.add({
          severity: "success",
          summary: "Completed",
          detail: "Special Deduction Created Successfully!",
        });

        this.deductionForm.reset();
        this.theAssetAccount = null;
        this.fetchingDeductions = true;
        this.FetchAllSpecialDeductions();
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to create special deduction at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  CloseEditingDeduction() {
    this.deductionForm.reset();
    this.theAssetAccount = null;
    this.editingDeductee = false;
    this.deductionToEdit = null;
  }

  DeleteDeductee(item: SpecialDeduction) {}

  EditDeductee(item: SpecialDeduction) {
    this.editingDeductee = true;
    this.deductionForm.patchValue({
      Caption: item.caption,
      Description: item.description,
    });
    this.theAssetAccount = this.allAssetAccounts.find(
      (x) => x.id == item.assetAccountId
    );
    this.deductionToEdit = item;

    this.formWrapper.nativeElement.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "start",
    });
  }

  UpdateSpecialAllowance() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Updating Special Allowance...",
    });

    const id = this.allowanceToEdit.id;
    const postData: UpdateSpecialSettingVM = {
      caption: this.allowanceForm.get("Caption").value,
      description: this.allowanceForm.get("Description").value,
      accountId: this.theExpenseAccount.id,
      accountNumber: this.theExpenseAccount.accountNumber,
      isSpecialAllowance: true,
      isTaxable: this.theTaxStatus.key == 1 ? true : false,
      isUsingPayrollTaxComputation: this.theTaxConfiguration
        ? this.theTaxConfiguration.key == 1
        : false,
      taxTypeOption: this.isTaxTypeRadioButton,
      taxTypeValue: this.taxTypeValue,
      taxAccountId: this.theTaxAccount.id,
      taxAccountNumber: this.theTaxAccount.accountNumber,
    };

    this.payrollService.UpdateSpecialSetting(id, postData).subscribe(
      async (data) => {
        if (data.responseCode != "00") {
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail: data.responeMessage,
          });

          return;
        }

        this.messageService.add({
          severity: "success",
          summary: "Completed",
          detail: "Special Allowance Updated Successfully.",
        });

        this.allowanceToEdit = null;
        this.allowanceForm.reset();
        this.editingAllowance = false;
        this.theExpenseAccount = null;
        this.fetchingAllowances = true;
        this.theTaxStatus = null;
        this.isTaxTypeRadioButton = null;
        this.taxTypeValue = null;
        this.theTaxAccount = null;
        this.theTaxConfiguration = null;
        this.FetchAllSpecialAllowances();
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to update special allowance at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  UpdateSpecialDeduction() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Updating Special Deduction...",
    });

    const id = this.deductionToEdit.id;
    const postData: UpdateSpecialSettingVM = {
      caption: this.deductionForm.get("Caption").value,
      description: this.deductionForm.get("Description").value,
      accountId: this.theAssetAccount.id,
      accountNumber: this.theAssetAccount.accountNumber,
      isSpecialAllowance: false,
    };

    this.payrollService.UpdateSpecialSetting(id, postData).subscribe(
      async (data) => {
        if (data.responseCode != "00") {
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail: data.responeMessage,
          });

          return;
        }

        this.messageService.add({
          severity: "success",
          summary: "Completed",
          detail: "Special Deduction Updated Successfully.",
        });

        this.deductionToEdit = null;
        this.deductionForm.reset();
        this.editingDeductee = false;
        this.theAssetAccount = null;
        this.fetchingDeductions = true;
        this.FetchAllSpecialDeductions();
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to update special deduction at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  FetchAllApprovingOffice() {
    this.payrollService.GetAllSpecialApprovingOffice().subscribe(
      async (data) => {
        this.allApprovingOffice = data;
        this.fetchingApprovalOffices = false;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all approving office at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
        this.fetchingApprovalOffices = false;
      }
    );
  }

  CreateApprovingOffice() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Creating Approval Office...",
    });

    const postData: CreateApprovingOffice = {
      officeName: this.approvingOfficeForm.get("OfficeName").value,
      officersIds: this.addedApprovingOfficers.map((x) => x.userId),
    };

    this.payrollService.CreateSpecialApprovingOffice(postData).subscribe(
      async () => {
        await this.messageService.add({
          severity: "success",
          summary: "Completed",
          detail: "Approving Office Created Successfully!",
        });

        this.approvingOfficeForm.reset();
        this.addedApprovingOfficers = [];
        this.FetchAllApprovingOffice();
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to create approving office at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  AddApprovingOfficerToList() {
    if (!this.ApprovingUser) {
      this.messageService.add({
        severity: "error",
        summary: "Failed",
        detail: "You must select the officer to add",
      });
      return;
    }

    let alreadyAdded = false;
    this.addedApprovingOfficers.forEach((item) => {
      if (item.userId == this.ApprovingUser.id) {
        alreadyAdded = true;
      }
    });

    if (alreadyAdded) {
      this.messageService.add({
        severity: "error",
        summary: "Failed",
        detail: "Officer with the same info has already been added to list.",
      });
      return;
    }

    this.addedApprovingOfficers.push({
      userId: this.ApprovingUser.id,
      user: this.ApprovingUser,
    });

    this.theApprovingUser = null;
    this.messageService.add({
      severity: "success",
      summary: "Added",
      detail: "Approving Officer added successfully",
    });
  }

  RemoveOfficer(item: PayrollApprovingOfficer) {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Removing Approving Officer...",
    });

    const index = this.addedApprovingOfficers.indexOf(item);
    if (index > -1) {
      this.addedApprovingOfficers.splice(index, 1);
    }

    this.messageService.add({
      severity: "success",
      summary: "Removed",
      detail: "Approving Officer Removed successfully",
    });
  }

  CloseEditingApprovalOffice() {
    this.editingApprovingOffice = false;
    this.approvingOfficeForm.reset();
    this.addedApprovingOfficers = [];
    this.approvingOfficeToEdit = null;
  }

  UpdateApprovalOffice() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Updating Approval Office...",
    });

    let addedApproveOfficerIds: number[] = [];
    this.addedApprovingOfficers.forEach((officer) => {
      addedApproveOfficerIds.push(officer.userId);
    });

    const id = this.approvingOfficeToEdit.id;
    const postData: CreateApprovingOffice = {
      officeName: this.approvingOfficeForm.get("OfficeName").value,
      officersIds: addedApproveOfficerIds,
    };

    this.payrollService.UpdateSpecialApprovingOffice(id, postData).subscribe(
      async () => {
        await this.messageService.add({
          severity: "success",
          summary: "Completed",
          detail: "Approval Office Updated Successfully.",
        });

        this.addedApprovingOfficers = [];
        this.FetchAllApprovingOffice();
        this.editingApprovingOffice = false;
        this.approvingOfficeForm.reset();
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to update approving office at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  DeleteApprovalOffice(item: PayrollApprovingOffice) {
    this.confirmationService.confirm({
      message: "Are you sure you want to remove approving office?",
      accept: () => {
        this.messageService.add({
          severity: "info",
          summary: "Notice",
          detail: "Removing approving office...",
        });

        this.payrollService.DeleteSpecialApprovingOffice(item.id).subscribe(
          async () => {
            await this.messageService.add({
              severity: "success",
              summary: "Removed",
              detail: "Removed successfully",
            });
            this.FetchAllApprovingOffice();
          },
          (error) => {
            console.log("Error: " + JSON.stringify(error));
            this.messageService.add({
              severity: "error",
              summary: "Notice",
              detail:
                "Unable to remove approving office at the moment.. Reason: [" +
                error
                  ? error.error.message
                  : "request failed - permission" + "]",
            });
          }
        );
      },
    });
  }

  EditApprovalOffice(item: PayrollApprovingOffice) {
    this.editingApprovingOffice = true;
    this.approvingOfficeForm.patchValue({
      OfficeName: item.name,
    });
    this.approvingOfficeToEdit = item;
    this.addedApprovingOfficers = item.officers;
    this.addedApprovingOfficers.forEach((data) => {
      data.user = this.allUsers.find((appUser) => appUser.id == data.userId);
    });

    this.formWrapper.nativeElement.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "start",
    });
  }

  MoveOfficeSequence(option: boolean, sequence: number) {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Moving Approval Office Sequence...",
    });

    this.payrollService
      .MoveSpecialSequence({ sequence: sequence, goesUp: option })
      .subscribe(
        async () => {
          await this.messageService.add({
            severity: "success",
            summary: "Removed",
            detail: "Approving office sequence updated successfully",
          });
          this.FetchAllApprovingOffice();
        },
        (error) => {
          console.log("Error: " + JSON.stringify(error));
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail:
              "Unable to move approving office sequence at the moment.. Reason: [" +
              error
                ? error.error.message
                : "request failed - permission" + "]",
          });
        }
      );
  }

  OnTaxStatusChanged() {}

  OnTaxConfigurationChanged() {}
}
