import { AdvancePaymentsService } from "./../../../../services/advance-payments.service";
import { Component, OnInit } from "@angular/core";
import { DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";
import {
  Branch,
  CData,
  CommonResponse,
  Constant,
  GroupQuoteCategory,
  QuoteServices,
  RequiredServiceQualElement,
  ServicePricing,
  ServiceRelationshipEnum,
  Services,
} from "../../../../interfaces/home";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import * as EventEmitter from "events";
import { ServicesService } from "../../../../services/services.service";
import { ServicePricingService } from "src/app/services/service-pricing.service";
import { forkJoin, interval } from "rxjs";
import { BranchService } from "src/app/services/branch.service";
import { QuoteServicesService } from "src/app/services/quote-services.service";
import { Calendar } from "primeng/calendar";
import { ThrowStmt } from "@angular/compiler";
import { CustomerDivision } from "../../../../interfaces/home";
import { MessageService } from "primeng/api";
@Component({
  selector: "app-sublet-payment",
  templateUrl: "./sublet-payment.component.html",
  styleUrls: ["./sublet-payment.component.scss"],
})
export class SubletPaymentComponent implements OnInit {
  isReadOnly: boolean = false;
  subletform: FormGroup;
  isSubmitted: boolean = false;
  firstSendDateRangeError: string;
  advancePay: any;
  balance: number;
  customerDivisions: CustomerDivision;
  processing: boolean = false;
  processingItemName: string;
  //messageService: any;
  constructor(
    public config: DynamicDialogConfig,
    private formBuilder: FormBuilder,
    private advancepayment: AdvancePaymentsService,
    public messageService: MessageService,
    public qualifyModalRef: DynamicDialogRef
  ) {}

  async ngOnInit() {
    this.advancePay = this.config.data.paybody;
    this.balance = this.advancePay.advanceTotal - this.advancePay.amountUsed;
    this.customerDivisions = this.config.data.clients;
    this.setupForm();
    this.processing = false;
  }

  setupForm() {
    this.subletform = this.formBuilder.group({
      advancePaymentId: [""],
      amountToSublet: ["", Validators.required],
      customerId: ["", Validators.required],
      narration: ["", Validators.required],
      balance: [],
      payTotal: [],
    });

    this.subletform.patchValue({
      balance: this.balance,
      advancePaymentId: this.advancePay.advancePaymentId,
      payTotal: this.advancePay.advanceTotal,
    });
  }

  sublet() {
    this.processingItemName = "Processing sublet";
    var postData = this.subletform.value;
    var customer = postData["customerId"];
    if (customer == null || customer == "") {
      this.messageService.add({
        severity: "error",
        summary: "Declined",
        detail: "Please select customer to sublet to",
      });
      return;
    }

    postData["customerId"] = postData["customerId"].id;
    postData["subleterId"] = this.advancePay.subleterId;
    this.processing = true;

    this.advancepayment
      .Sublet(postData)
      .subscribe(async (r: CommonResponse) => {
        // this.fetchingContractServices = false;
        if (r.responseCode == "00") {
          this.messageService.add({
            severity: "success",
            summary: "success",
            detail: "Sublet sent successfully",
          });
          this.qualifyModalRef.close(true);
        } else {
          this.messageService.add({
            severity: "error",
            summary: "Error",
            detail: r.responseMsg,
          });
          this.qualifyModalRef.close(false);
        }
      });

    this.processing = true;
    // (error) => {
    //   this.connectionError();
    //   // this.fetchingContractServices = false;
    // }
  }

  checkAmount() {
    //subletBigger
    if (this.subletform.get("amountToSublet").value > this.balance) {
      this.subletform.get("amountToSublet").setErrors({ subletBigger: true });
      return;
    } else this.subletform.get("amountToSublet").setErrors(null);
  }

  closeQualifyService() {
    this.qualifyModalRef.close();
  }
}
