import { Component, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-create-key-person',
  templateUrl: './create-key-person.component.html',
  styleUrls: ['./create-key-person.component.scss']
})
export class CreateKeyPersonComponent implements OnInit {

  isLeadDivison: boolean = false;

  constructor(public config: DynamicDialogConfig,
              private messageService: MessageService,
              public keyPersonModalRef: DynamicDialogRef
  ) { }

  ngOnInit(): void {
    this.isLeadDivison = this.config.data.isLeadDivision;
  }

  saveSuccessful(event){
    this.closeCreateKeyPerson();
  }

  closeCreateKeyPerson() {
    this.keyPersonModalRef.close();
  }

  private connectionError() {
    this.messageService.add({
        severity: 'error', summary: 'Failed',
        detail: 'Connection Error, Please try again'
    });
  }
}
