import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  ValidatorFn,
  Validators,
} from "@angular/forms";
import {
  CommonResponse,
  Contract,
  ContractService,
  CustomerDivision,
  Invoice,
  InvoiceMailDTO,
  Role,
  RoleClaim,
  ServiceCategory,
  Services,
  User,
} from "../../../interfaces/home";
import { ConfirmationService, MessageService, TreeNode } from "primeng/api";
import { DialogService, DynamicDialogRef } from "primeng/dynamicdialog";
import { CustomerDivisionService } from "../../../services/customer-division.service";
import { BreadcrumbService } from "src/app/breadcrumb.service";
import { InvoiceService } from "src/app/services/invoice.sevice";
import { ContractServiceService } from "src/app/services/contract-service.service";
import { StrategicBusinessUnitService } from "src/app/services/strategic-business-unit.service";

@Component({
  selector: "app-attach-client-to-rm",
  templateUrl: "./attach-client-to-rm.component.html",
  styleUrls: ["./attach-client-to-rm.component.scss"],
})
export class AttachClientToRMComponent implements OnInit {
  sbus: any;
  selectedSBU: any;
  fetchingSBUs = false;

  sbuClients: any;
  selectedSBUClient: any;
  fetchingSBUClients = false;

  sbuMembers: any;
  selectedMember: any;

  sbuClientCols: any;

  unAssignedClients: any;
  selectedClient: any;
  unAssignedClientCols: any;
  fetchingUnAssignedClients = false;

  sbuForm: FormGroup;

  constructor(
    public formBuilder: FormBuilder,
    private customerDivisionService: CustomerDivisionService,
    public messageService: MessageService,
    public confirmationService: ConfirmationService,
    private breadcrumbService: BreadcrumbService,
    private strategicBusinessUnitService: StrategicBusinessUnitService,
    private dialogService: DialogService
  ) {}

  ngOnInit(): void {
    this.breadcrumbService.setItems([
      {
        label: "Attach Client To RM",
        routerLink: ["home/attach-client-to-rm"],
      },
    ]);

    this.sbuForm = this.formBuilder.group({
      sbu: [""],
    });

    this.fetchRMSbus();
    this.fetchUnAssignedClients();
  }

  fetchRMSbus() {
    this.fetchingSBUs = true;
    this.strategicBusinessUnitService.allRMSbus().subscribe(
      async (result: CommonResponse) => {
        this.fetchingSBUs = false;
        this.sbus = result.responseData;
      },
      (error) => {
        this.fetchingSBUs = false;
        this.connectionError();
      }
    );
  }

  fetchUnAssignedClients() {
    this.fetchingUnAssignedClients = true;
    this.customerDivisionService.getClientsUnAssignedToRMSbu().subscribe(
      async (result: CommonResponse) => {
        this.unAssignedClients = result.responseData;
        this.fetchingUnAssignedClients = false;
      },
      (error) => {
        this.fetchingUnAssignedClients = false;
        this.connectionError();
      }
    );
  }

  fetchClientBySBU() {
    console.log(this.selectedSBU);
    if (this.selectedSBU) {
      this.selectedMember = null;
      this.sbuMembers = this.selectedSBU.members;
      this.sbuClients = null;
      this.fetchingSBUClients = true;
      this.customerDivisionService
        .getClientsAttachedToSbu(this.selectedSBU.id)
        .subscribe(
          async (result: CommonResponse) => {
            this.sbuClients = result.responseData;
            this.fetchingSBUClients = false;
          },
          (error) => {
            this.fetchingSBUClients = false;
            this.connectionError();
          }
        );
    }
  }

  selectSBUMember(member) {
    this.selectedMember = member;
  }

  assignClientToSBU(client) {
    if (this.selectedSBU) {
      this.confirmationService.confirm({
        message: `Are you sure you want to attach ${client.divisionName} to ${this.selectedSBU.name}`,
        header: "Confirmation",
        icon: "pi pi-exclamation-triangle",
        accept: () => {
          this._attachClientToSBU(client);
        },
        reject: () => {
          this.messageService.add({
            severity: "error",
            summary: "Cancelled",
            detail: "Operation Cancelled",
          });
        },
      });
    }
  }

  _attachClientToSBU(client) {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Attaching client to sbu.",
    });

    this.customerDivisionService
      .attachClientToRMSbu(client.id, this.selectedSBU.id)
      .subscribe(
        async () => {
          this.messageService.add({
            severity: "success",
            summary: "Completed",
            detail: "Client attached to sbu successfully.",
          });
          this.fetchUnAssignedClients();
          this.fetchClientBySBU();
        },
        (error) => {
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail: "Unable to attach sbu to client.",
          });
        }
      );
  }

  DetachFromSbu(item: CustomerDivision) {
    this.confirmationService.confirm({
      header: "Confirmation",
      message: "Are you sure you want to detach this client from selected sbu?",
      acceptLabel: "Yes, Proceed",
      rejectLabel: "No, Don't proceed",
      accept: () => {
        this.messageService.add({
          severity: "info",
          summary: "Notice",
          detail: "Detaching Client...",
        });

        this.strategicBusinessUnitService
          .DetachClientFromSBU(item.id)
          .subscribe(
            async (data) => {
              if (data.responseCode != "00") {
                this.messageService.add({
                  severity: "error",
                  summary: "Notice",
                  detail: data.responseMsg,
                });
                return;
              }

              this.messageService.add({
                severity: "success",
                summary: "Completed",
                detail: "Client Detached Successfully!",
              });

              const index = this.sbuClients.indexOf(item);
              if (index > -1) {
                this.sbuClients.splice(index, 1);
              }
            },
            (error) => {
              console.log("Error: " + JSON.stringify(error));
              this.messageService.add({
                severity: "error",
                summary: "Notice",
                detail:
                  "Unable to detach client at the moment.. Reason: [" +
                  (error
                    ? error.error.message
                    : "request failed - permission") +
                  "]",
              });
            }
          );
      },
    });
  }

  private connectionError() {
    this.messageService.add({
      severity: "error",
      summary: "Failed",
      detail: "Connection Error, Please try again",
    });
  }
}
