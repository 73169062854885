import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {Observable} from 'rxjs';
import {CommonResponse, LeadType} from '../interfaces/home';
import { shareReplay } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class LeadTypeService {
  ltobservable: Observable<CommonResponse>;
  constructor(private http: HttpClient) { }
  baseUrl = environment.baseUrl+'LeadType/';
  // fetch all LeadType in the database
  allLeadTypeData(): Observable<CommonResponse>{
    if(this.ltobservable) return this.ltobservable
    else {
      this.ltobservable = this.http.get<CommonResponse>(this.baseUrl).pipe(shareReplay()); 
      return this.ltobservable;
    }
  }
  getLeadType(id: any):Observable<CommonResponse>{
    return this.http.get<CommonResponse>(this.baseUrl +id);
  }
  getLeadTypeByName(name: any):Observable<CommonResponse>{
    return this.http.get<CommonResponse>(this.baseUrl + 'name/' + name);
  }
  postLeadType(data: any){
    return this.http.post(this.baseUrl, data);
  }
  deleteLeadType(id: any){
    return this.http.delete(this.baseUrl +id);
  }
  updateLeadType(id: any, data: any){
    return this.http.put(this.baseUrl +id, data);
  }
}
