import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { MessageService, ConfirmationService } from "primeng/api";
import { UIChart } from "primeng/chart";
import { BreadcrumbService } from "src/app/breadcrumb.service";
import { GmaEmploymentService } from "src/app/services/gma-employment.service";

@Component({
  selector: "app-gma-dashboard",
  templateUrl: "./gma-dashboard.component.html",
  styleUrls: ["./gma-dashboard.component.scss"],
})
export class GmaDashboardComponent implements OnInit {
  @ViewChild("formWrapper") public formWrapper: ElementRef;
  @ViewChild("chart1") chart1: UIChart;
  @ViewChild("chart2") chart2: UIChart;
  @ViewChild("chart3") chart3: UIChart;
  @ViewChild("chart4") chart4: UIChart;
  @ViewChild("chart5") chart5: UIChart;
  @ViewChild("chart6") chart6: UIChart;
  gendDistPieData: {
    labels: string[];
    datasets: {
      data: number[];
      backgroundColor: string[];
    }[];
  };
  ageDistPieData: {
    labels: string[];
    datasets: {
      data: number[];
      backgroundColor: string[];
    }[];
  };
  regionDistPieData: {
    labels: string[];
    datasets: {
      data: number[];
      backgroundColor: string[];
    }[];
  };
  zoneDistPieData: {
    labels: string[];
    datasets: {
      data: number[];
      backgroundColor: string[];
    }[];
  };
  clusterDistPieData: {
    labels: string[];
    datasets: {
      data: number[];
      backgroundColor: string[];
    }[];
  };
  locationDistPieData: {
    labels: string[];
    datasets: {
      data: number[];
      backgroundColor: string[];
    }[];
  };
  totalActiveOP: string;
  totalOpInPool: string;
  totalACCOP: string;
  totalACCNTOP: string;

  constructor(
    public employmentService: GmaEmploymentService,
    public messageService: MessageService,
    private breadcrumbService: BreadcrumbService,
    public confirmationService: ConfirmationService
  ) {}

  ngOnInit(): void {
    this.breadcrumbService.setItems([
      {
        label: "Guard Management",
        routerLink: ["/home/guard-management/set-up"],
      },
      {
        label: "Dashboard",
        routerLink: ["/home/guard-management/dashboard"],
      },
    ]);

    this.gendDistPieData = {
      labels: [],
      datasets: [
        {
          data: [],
          backgroundColor: [],
        },
      ],
    };

    this.ageDistPieData = {
      labels: [],
      datasets: [
        {
          data: [],
          backgroundColor: [],
        },
      ],
    };

    this.regionDistPieData = {
      labels: [],
      datasets: [
        {
          data: [],
          backgroundColor: [],
        },
      ],
    };

    this.zoneDistPieData = {
      labels: [],
      datasets: [
        {
          data: [],
          backgroundColor: [],
        },
      ],
    };

    this.clusterDistPieData = {
      labels: [],
      datasets: [
        {
          data: [],
          backgroundColor: [],
        },
      ],
    };

    this.locationDistPieData = {
      labels: [],
      datasets: [
        {
          data: [],
          backgroundColor: [],
        },
      ],
    };

    this.FetchAnalytics();
  }

  FetchAnalytics() {
    this.employmentService.GetDashboardAnalytics().subscribe(
      async (data) => {
        this.totalActiveOP = data.totalActiveOperatives.toLocaleString();
        this.totalOpInPool = data.totalOperativesInPool.toLocaleString();
        this.totalACCOP = data.totalOperativesFullyAccredited.toLocaleString();
        this.totalACCNTOP =
          data.totalOperativesNotFullyAccredited.toLocaleString();

        this.gendDistPieData.labels = data.guardDistributionByGender.labels;
        this.gendDistPieData.datasets[0].data =
          data.guardDistributionByGender.datas;
        this.gendDistPieData.datasets[0].backgroundColor =
          data.guardDistributionByGender.backgroundColors;

        this.ageDistPieData.labels = data.guardDistributionByAge.labels;
        this.ageDistPieData.datasets[0].data =
          data.guardDistributionByAge.datas;
        this.ageDistPieData.datasets[0].backgroundColor =
          data.guardDistributionByAge.backgroundColors;

        this.regionDistPieData.labels = data.guardDistributionByRegion.labels;
        this.regionDistPieData.datasets[0].data =
          data.guardDistributionByRegion.datas;
        this.regionDistPieData.datasets[0].backgroundColor =
          data.guardDistributionByRegion.backgroundColors;

        this.zoneDistPieData.labels = data.guardDistributionByZone.labels;
        this.zoneDistPieData.datasets[0].data =
          data.guardDistributionByZone.datas;
        this.zoneDistPieData.datasets[0].backgroundColor =
          data.guardDistributionByZone.backgroundColors;

        this.clusterDistPieData.labels = data.guardDistributionByCluster.labels;
        this.clusterDistPieData.datasets[0].data =
          data.guardDistributionByCluster.datas;
        this.clusterDistPieData.datasets[0].backgroundColor =
          data.guardDistributionByCluster.backgroundColors;

        this.locationDistPieData.labels =
          data.guardDistributionByLocation.labels;
        this.locationDistPieData.datasets[0].data =
          data.guardDistributionByLocation.datas;
        this.locationDistPieData.datasets[0].backgroundColor =
          data.guardDistributionByLocation.backgroundColors;

        this.chart1.reinit();
        this.chart2.reinit();
        this.chart3.reinit();
        this.chart4.reinit();
        this.chart5.reinit();
        this.chart6.reinit();
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to get dashboard analytics at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }
}
