import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { CommonResponse, Contract } from "../interfaces/home";
import {
  ActOnContractExtensionRequestVM,
  CEApprovingOffice,
  ContractExtensionRequest,
  CreateCEApprovalOfficeVM,
  CreateContractExtensionRequestVM,
  GetContractExtensionRequestApprovalsVM,
  GetPendingCERequestsToApproveView,
  UpdateCEApprovalOfficeVM,
} from "../interfaces/contract-extension";

@Injectable({
  providedIn: "root",
})
export class ContractExtensionService {
  baseUrl = environment.baseUrl + "ContractExtension/";

  constructor(private http: HttpClient) {}

  GetAllCEApprovalOffices(): Observable<CommonResponse<CEApprovingOffice[]>> {
    return this.http.get<CommonResponse<CEApprovingOffice[]>>(
      this.baseUrl + "GetAllCEApprovalOffices"
    );
  }

  CreateCEApprovalOffice(
    data: CreateCEApprovalOfficeVM
  ): Observable<CommonResponse<boolean>> {
    return this.http.post<CommonResponse<boolean>>(
      this.baseUrl + "CreateCEApprovalOffice",
      data
    );
  }

  UpdateCEApprovalOffice(
    id: number,
    data: UpdateCEApprovalOfficeVM
  ): Observable<CommonResponse<boolean>> {
    return this.http.put<CommonResponse<boolean>>(
      this.baseUrl + "UpdateCEApprovalOffice/" + id,
      data
    );
  }

  DeleteCEApprovalOffice(id: number): Observable<CommonResponse<boolean>> {
    return this.http.delete<CommonResponse<boolean>>(
      this.baseUrl + "DeleteCEApprovalOffice/" + id
    );
  }

  MoveCEApprovingOfficeSequence(data: {
    sequence: number;
    goesUp: boolean;
  }): Observable<CommonResponse<boolean>> {
    return this.http.post<CommonResponse<boolean>>(
      this.baseUrl + "MoveCEApprovingOfficeSequence",
      data
    );
  }

  CreateContractExtensionRequest(
    data: CreateContractExtensionRequestVM
  ): Observable<CommonResponse<boolean>> {
    return this.http.post<CommonResponse<boolean>>(
      this.baseUrl + "CreateContractExtensionRequest",
      data
    );
  }

  GetClientContractExtensionRequests(
    clientId: number
  ): Observable<CommonResponse<ContractExtensionRequest[]>> {
    return this.http.get<CommonResponse<ContractExtensionRequest[]>>(
      this.baseUrl + "GetClientContractExtensionRequests/" + clientId
    );
  }

  GetContractExtensionRequestApprovals(
    contractExtensionRequestId: number
  ): Observable<CommonResponse<GetContractExtensionRequestApprovalsVM[]>> {
    return this.http.get<
      CommonResponse<GetContractExtensionRequestApprovalsVM[]>
    >(
      this.baseUrl +
        "GetContractExtensionRequestApprovals/" +
        contractExtensionRequestId
    );
  }

  GetPendingCERequestsToApprove(): Observable<
    CommonResponse<GetPendingCERequestsToApproveView[]>
  > {
    return this.http.get<CommonResponse<GetPendingCERequestsToApproveView[]>>(
      this.baseUrl + "GetPendingCERequestsToApprove"
    );
  }

  ActOnContractExtensionRequest(
    data: ActOnContractExtensionRequestVM
  ): Observable<CommonResponse<boolean>> {
    return this.http.post<CommonResponse<boolean>>(
      this.baseUrl + "ActOnContractExtensionRequest",
      data
    );
  }

  GetClientAllowedContracts(
    clientId: number
  ): Observable<CommonResponse<Contract[]>> {
    return this.http.get<CommonResponse<Contract[]>>(
      this.baseUrl + "GetClientAllowedContracts/" + clientId
    );
  }
}
