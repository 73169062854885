import { CreateApprovalChecklistEndType } from "./approval-checklist";
import { ApprovalComment } from "./approval-comment";
import { ConvergenceDeviceAttachment } from "./convergence";
import { ContractContact, CustomerContact } from "./customer";
import { Beat, GMAZone, GuardDeployment, Cluster } from "./employment";
import { ServiceBenefit } from "./platform-benefit";

export enum BankCoverageTypeEnum {
  State = 1,
  Regional,
  National,
}

export enum StatusEnum {
  Pending = 1,
  Approved,
  Denied,
}

export interface CommonResponse<T = any> {
  responseCode: string;
  responseData: T;
  responseMsg: string;
}

export interface Office {
  name?: string;
  description?: string;
  state?: State;
  lga?: Lga;
  street?: string;
  phoneNumber?: string;
  head?: User;
  branch?: Branch;
  id?: number;
}

export interface Branch {
  offices?: Office[];
  name?: string;
  description?: string;
  address?: string;
  head?: User;
  headId?: number;
  id?: number;
  phone?: string;
  office_id?: string;
  zones?: GMAZone[];
}

export interface State {
  id: number;
  stateCode: number;
  name: string;
  capital: string;
  slogan: string;
  lgAs: Lga[];
  lgas: Lga[];
}

export interface AdminDirectRelationships {
  id: number;
  adminServiceId: number;
  directServiceId: number;
  adminService: Services;
  directService: Services;
}

export interface Lga {
  id: number;
  name: string;
}
export interface User {
  id?: number;
  firstName?: string;
  lastName?: string;
  fullname?: string;
  privacyAccessId?: string;
  otherName?: string;
  codeName?: string;
  dateOfBirth?: string;
  email?: string;
  mobileNumber?: string;
  imageUrl?: string;
  altEmail?: string;
  altMobileNumber?: string;
  address?: string;
  linkedInHandle?: string;
  facebookHandle?: string;
  twitterHandle?: string;
  instagramHandle?: string;
  staffId?: number;
  profileStatus?: boolean;
  sbu?: StrategicBusinessUnit;
  sbuId?: number;
  roleId?: number;
  role?: Role;
  fullName?: string;
  isPasswordGenerated?: boolean;
  isDeleted?: boolean;
  optedOutOfBulkApprovalInsights?: boolean;
}

export interface AuthUser {
  token: string;
  userProfile: User;
}

export interface Division {
  divisionName: string;
  id?: number;
  name: string;
  description: string;
  missionStatement: string;
  headId?: number;
  head?: User;
  company?: any;
  operatingEntities?: OperatingEntity[];
}

export interface OperatingEntity {
  id?: number;
  name: string;
  description: string;
  alias?: string;
  headId?: number;
  divisionId?: number;
  head?: User;
  division?: Division;
  serviceGroups?: ServiceGroup[];
  strategicBusinessUnits: StrategicBusinessUnit[];
  sbuProportion: SBUProportion;
}

export interface ServiceGroup {
  id?: number;
  name: string;
  description: string;
  operatingEntityId?: number;
  divisionId?: number;
  operatingEntity?: OperatingEntity;
  division?: Division;
}

export interface ServiceCategory {
  id?: number;
  name: string;
  description?: string;
  serviceGroupId?: number;
  operatingEntityId?: number;
  divisionId?: number;
  serviceGroup?: ServiceGroup;
  operatingEntity?: OperatingEntity;
  division?: Division;
  services?: Services[];
}

export interface Services {
  id?: number;
  name?: string;
  serviceCode: string;
  description?: string;
  imageUrl?: string;
  quantity?: number;
  unitPrice?: number;
  price?: number;
  discount?: number;
  vat?: number;
  billableAmount?: number;
  serviceCategoryId?: number;
  serviceCategory?: ServiceCategory;
  serviceGroupId?: number;
  serviceGroup?: ServiceGroup;
  operatingEntityId?: number;
  operatingEntity?: OperatingEntity;
  divisionId?: number;
  division?: Division;
  createdById?: number;
  createdBy?: User;
  isPublished?: boolean;
  isRequestedForPublish?: boolean;
  publishedApprovedStatus?: boolean;
  target?: Target;
  targetId?: Target;
  controlAccount?: ControlAccount;
  controlAccountId?: number;
  serviceType?: ServiceType;
  serviceTypeId?: ServiceType;
  requiredServiceDocument?: RequiredServiceDocument[];
  requiredServiceFields?: RequiredServiceQualElement[];
  createdAt?: string;
  isVatable?: boolean;
  canBeSoldOnline?: boolean;
  directServiceId?: number;
  servicePricing?: ServicePricing;
  serviceRelationshipEnum: ServiceRelationshipEnum;
  directRelationship: DirectRelationship;
  adminRelationship: AdminRelationship;
  alias?: string;
  serviceBenefits?: ServiceBenefit[];
}

export interface DirectRelationship {
  id: number;
  adminServiceId: number;
  directServiceId: number;
}
export interface AdminRelationship {
  id: number;
  adminServiceId: number;
  directServiceId: number;
}

export enum ServiceRelationshipEnum {
  Standalone = 1,
  Direct = 2,
  Admin = 3,
}

export interface StrategicBusinessUnit {
  id?: number;
  name: string;
  description?: string;
  operatingEntityId?: number;
  operatingEntities?: OperatingEntity[];
  members: User[];
}

export interface WeatherForecast {
  date: any;
  temperatureC: number;
  temperatureF: number;
  summary: string;
}

export interface GroupType {
  id?: number;
  caption?: string;
  description?: string;
}

export interface LeadType {
  id?: number;
  caption?: string;
  description?: string;
}

export interface LeadOrigin {
  id?: number;
  caption?: string;
  description?: string;
  leadTypeId?: number;
  leadType?: LeadType;
}

export interface Relationship {
  id?: number;
  caption?: string;
  description?: string;
}

export interface AccountVoucherType {
  id?: number;
  caption?: string;
  alias?: string;
  description?: string;
  voucherType?: string;
  isAllowedForStandaloneVoucherPosting?: boolean;
  isApplicableForNotification?: boolean;
}

export interface MeansOfIdentification {
  id?: number;
  caption?: string;
  description?: string;
}

export interface Target {
  id?: number;
  caption?: string;
  description?: string;
}

export interface Bank {
  id?: number;
  name?: string;
  slogan?: string;
  alias?: string;
  isActive?: boolean;
  logoUrl?: string;
  sortCode?: string;
  bankCode?: string;
  ssrsChequeUrl?: string;
  coverageType?: BankCoverageTypeEnum;
}

export interface StandardSLA {
  id?: number;
  caption?: string;
  description?: string;
  documentUrl?: string;
  operatingEntityId?: number;
}

export class RequiredServiceDocument {
  id?: number;
  caption?: string;
  description?: string;
  type?: string;
}

export class RequiredServiceQualElement {
  id?: number;
  caption?: string;
  description?: string;
  serviceCategoryId?: number;
  serviceCategory?: ServiceCategory;
  type?: string;
}

export class ServiceCategoryTask {
  id?: number;
  caption?: string;
  description?: string;
  serviceCategoryId?: number;
  serviceCategory?: ServiceCategory;
  endorsementTypeId?: number;
  endorsementType?: EndorsementType;
}

export class ServiceTaskDeliverable {
  id?: number;
  caption?: string;
  description?: string;
  serviceCategoryTaskId?: number;
  serviceCategoryTask?: ServiceCategoryTask;
}

export class ServiceType {
  id?: number;
  caption?: string;
  description?: string;
}

export class Account {
  id?: number;
  name?: string;
  description: string;
  alias: string;
  controlAccountId?: number;
  accountMasters?: any;
  isDebitBalance?: boolean;
  isActive?: boolean;
  controlAccount?: ControlAccount;
  label?: string;
  accountNumber?: string;
  accountDetails?: AccountDetail[]
}

export interface Lead {
  id?: number;
  referenceNo: string;
  leadType: LeadType;
  leadOriginId: number;
  groupTypeId: number;
  leadOrigin: LeadOrigin;
  industry?: string;
  rcNumber?: string;
  groupName: string;
  groupType?: GroupType;
  logoUrl?: string;
  leadCaptureDocumentUrl?: string;
  leadCaptureStatus?: boolean;
  leadQualificationStatus?: boolean;
  leadOpportunityStatus?: boolean;
  leadClosureStatus?: boolean;
  leadConversionStatus?: boolean;
  isLeadDropped?: boolean;
  dropLearning?: string;
  dropReason?: string;
  primaryContact?: LeadContact;
  secondaryContact?: LeadContact;
  leadKeyPeople?: LeadContact[];
  leadDivisions?: LeadDivision[];
  createdBy?: string;
  createdAt?: string;
  suspectId: number;
  denialReason: string;
  denialPerson: string;
}

export interface LeadContact {
  fullName: string;
  firstName: string;
  lastName: string;
  mobileNumber: string;
  email: string;
  designationId: number;
  designation: Designation;
  clientContactQualificationId: number;
  clientContactQualification: ClientContactQualification;
  dateOfBirth: string;
  type?: number;
  leadId?: number;
  lead?: Lead;
  id?: number;
  gender?: string;
  title?: string;
}

export interface LeadDivisionContact extends Contact {
  leadDivisionId?: number;
  leadDivision?: LeadDivision;
}

export interface LeadDivisionKeyPerson extends Contact {
  leadDivisionId?: number;
  leadDivision?: LeadDivision;
}

export interface Contact {
  firstName?: string;
  lastName?: string;
  fullName?: string;
  fullname?: string;
  mobileNumber?: string;
  email?: string;
  imageUrl?: string;
  profilePicture?: string;
  designationId: number;
  designation?: Designation;
  clientContactQualificationId: number;
  clientContactQualification: ClientContactQualification;
  dateOfBirth?: string;
  type?: number;
  id?: number;
  gender?: string;
  title?: string;
  mobile?: string;
  middleName?: string;
  status?: boolean;
  createdById?: number;
  createdBy?: string;
}

export interface LeadDivision {
  id?: number;
  groupName: string;
  leadOriginId?: number;
  industry?: string;
  rcNumber: string;
  divisionName: string;
  phoneNumber: string;
  email?: string;
  logoUrl?: string;
  address: string;
  primaryContactId?: number;
  secondaryContactId?: number;
  primaryContact?: LeadContact;
  secondaryContact?: LeadContact;
  leadDivisionKeyPeople?: LeadDivisionKeyPerson[];
  branchId?: number;
  officeId?: number;
  office?: Office;
  leadId?: number;
  lead?: Lead;
  leadOrigin?: LeadOrigin;
  quote?: LeadQuote;
  quotes?: LeadQuote;
  leadTypeId?: number;
  leadType?: LeadType;
  stateId?: number;
  state?: State;
  lgaId?: number;
  lga?: Lga;
  street?: string;
}

export interface LeadQuote {
  id?: number;
  caption: string;
  groupInvoiceNumber?: string;
  leadDivisionId?: number;
  isConvertedToContract?: true;
  version?: number;
  quoteServices?: QuoteServices[];
  groupQuoteCategory: GroupQuoteCategory;
  hasLoadedCostAnalysis?: boolean;
}

export enum GroupQuoteCategory {
  IndividualQoutes = 1,
  GroupQuoteWithSameDetails = 2,
  GroupQuoteWithIndividualDetails = 3,
}

export enum GroupContractCategory {
  IndividualContract = 1,
  GroupContractWithSameDetails = 2,
  GroupContractWithIndividualDetails = 3,
}

export interface QuoteServices {
  id?: number;
  referenceNumber?: string;
  unitPrice?: number;
  quantity?: number;
  discount?: number;
  vat?: number;
  billableAmount?: number;
  budget?: number;
  contractStartDate?: Date;
  contractEndDate?: Date;
  paymentCycle?: number;
  paymentCycleInDays?: number;
  invoiceCycleInDays?: number;
  firstInvoiceSendDate?: Date;
  invoicingInterval?: number;
  problemStatement?: string;
  activationDate?: Date;
  fulfillmentStartDate?: Date;
  fulfillmentEndDate?: Date;
  tentativeDateForSiteSurvey?: string;
  pickupDateTime?: string;
  dropoffDateTime?: string;
  pickupLocation?: string;
  dropofflocation?: string;
  beneficiaryName?: string;
  beneficiaryIdentificationType?: string;
  benificiaryIdentificationNumber?: string;
  tentativeProofOfConceptStartDate?: string;
  tentativeProofOfConceptEndDate?: string;
  tentativeFulfillmentDate?: string;
  programCommencementDate?: string;
  programDuration?: number;
  programEndDate?: string;
  tentativeDateOfSiteVisit?: string;
  isConvertedToContractService?: boolean;
  serviceId?: number;
  version?: number;
  service?: Services;
  quoteServiceDocuments?: QuoteServiceDocument[];
  sbutoQuoteServiceProportions?: SBUQuoteServiceProportion[];
  sbutoContractServiceProportions?: SBUContractServiceProportion[];

  groupInvoiceNumber?: string;
  branchId: number;
  officeId: number;
  uniqueTag: string;
  adminDirectTie?: string;
  serviceRelationshipEnum: ServiceRelationshipEnum; //here for checking purpose only
  tempId?: number; //here for reference purpose only
  serviceName?: string;
  serviceCostAnalysisLogs?: QuoteServiceCostAnalysisLog[];
}

export interface GeneralService {
  service: Services;
  quoteService: QuoteServices;
}

export interface CData {
  id: number;
  value: string;
  description?: string;
}

export class Constant {
  paymentCycles: CData[] = [
    { id: 0, value: "Monthly" },
    { id: 1, value: "BiMonthly" },
    { id: 2, value: "Quarterly" },
    { id: 3, value: "Annually" },
    { id: 4, value: "BiAnnually" },
    { id: 5, value: "One time" },
    { id: 6, value: "Adhoc" },
  ];

  invoicingIntervals: CData[] = [
    { id: 0, value: "Monthly" },
    { id: 1, value: "BiMonthly" },
    { id: 2, value: "Quarterly" },
    { id: 3, value: "Annually" },
    { id: 4, value: "BiAnnually" },
    { id: 5, value: "One time" },
    { id: 6, value: "Adhoc" },
    { id: 7, value: "Monthly prorata" },
  ];
  leadRole: CData[] = [
    { id: 0, value: "leadGenerator", description: "Lead Generator" },
    { id: 1, value: "leadClosure", description: "Lead Closure" },
    {
      id: 2,
      value: "leadGeneratorAndClosure",
      description: "Lead Generator And Closure",
    },
  ];
  titles = [
    { value: "Mr" },
    { value: "Mrs" },
    { value: "Miss" },
    { value: "Dr" },
    { value: "Engr" },
  ];
  genders = [{ value: "Male" }, { value: "Female" }, { value: "Other" }];
  priority = [
    { id: 0, value: "low" },
    { id: 1, value: "medium" },
    { id: 2, value: "high" },
  ];
}

export interface NegotiationDocument {
  id?: number;
  description?: string;
  caption?: string;
  documentUrl?: string;
  quoteServiceId?: number;
}

export interface QuoteServiceDocument {
  id?: number;
  description?: string;
  caption?: string;
  documentUrl?: string;
  quoteServiceId?: number;
  type?: string;
}

export interface Role {
  id?: number;
  name: string;
  description: string;
  roleClaims: RoleClaim[];
  permissionList: Permissions[];
}

export interface RoleClaim {
  name: string;
  description: string;
  canView?: boolean;
  canAdd?: boolean;
  canUpdate?: boolean;
  canDelete?: boolean;
}

export interface SBUQuoteServiceProportion {
  id?: number;
  proportion: number;
  status: number;
  userInvolvedId: number;
  quoteServiceId: number;
  strategicBusinessUnitId: number;
  userInvolved?: User;
  strategicBusinessUnit?: StrategicBusinessUnit;
}

export interface SBUContractServiceProportion {
  id?: number;
  proportion: number;
  status: number;
  userInvolvedId: number;
  contractServiceId: number;
  strategicBusinessUnitId: number;
  userInvolved?: User;
  strategicBusinessUnit?: StrategicBusinessUnit;
}

export interface Industry {
  id?: number;
  caption?: string;
  description?: string;
}

export interface DropReason {
  id?: number;
  title?: string;
  createdById?: number;
  createdBy?: User;
}

export interface DropLeadForm {
  dropReasonId: number;
  dropLearning: string;
}

export interface TaskFulfillment {
  id?: number;
  caption?: string;
  description?: null;
  customerDivisionId?: number;
  customerDivision?: null;
  contractServiceId?: number;
  contractService?: QuoteServices;
  responsibleId?: number;
  responsible?: User;
  accountableId?: number;
  accountable?: User;
  consultedId?: number;
  consulted?: null;
  informedId?: number;
  informed?: null;
  supportId?: null;
  support?: null;
  budget?: null;
  isPicked?: boolean;
  dateTimePicked?: string | null;
  isAllDeliverableAssigned?: boolean;
  taskCompletionDateTime?: string | null;
  taskCompletionStatus?: boolean;
  startDate?: string;
  endDate?: string;
  serviceCode?: string;
  deliverableFulfillments?: DeliverableFulfillment[];
  projectCode: string;
  serviceDivisionDetails: ServiceDivisionDetails | null;
}

export interface DeliverableFulfillment {
  id: number;
  description: string;
  caption: string;
  taskFullfillmentId: number;
  responsibleId: null;
  responsible: null;
  startDate: null;
  endDate: null;
  priority: number;
  deliveryDate: null;
  isPicked: boolean;
  dateAndTimePicked: null;
  taskCompletionDate: null;
  taskCompletionTime: null;
  wasReassigned: boolean;
  dateTimeReassigned: null;
  isRequestedForValidation: boolean;
  dateTimeRequestedForValidation: null;
  deliverableStatus: boolean;
  budget: number | null;
  deliverableCompletionReferenceNo: string | null;
  deliverableCompletionReferenceUrl: string | null;
  dateAndTimeOfProvidedEvidence: string | null;
  deliverableCompletionDate: string | null;
  deliverableCompletionTime: string | null;
  serviceCode: string;
  escallationTimeDurationForPicking: number;
  createdById: number;
  createdBy: User | null;
  isDeleted: boolean;
  createdAt: string;
  updatedAt: string;
}

export interface TaskWidget {
  allTaskAssigned: number;
  completedTask: number;
  acceptedTask: number;
  qualifiedTasks: number;
}

export interface Approval {
  class?: string;
  id?: number;
  type?: string;
  name?: string;
  caption?: string;
  description?: string;
  dateTimeApproved?: string;
  isApproved?: boolean;
  responsibleId?: number;
  sequence?: number;
  quoteServiceId?: number;
  quoteId?: number;
  contractId?: number;
  contractServiceId?: number;
  servicesId?: number;
  canBeApproved?: boolean;
  documentUrl?: string;
  comments?: ApprovalComment[];
  createdById?: number;
  isUnread?: boolean;
}

export interface ApprovingLevelBackUp {
  currentUserId: number;
  backUpInfos: ApprovingLevelBackUpInfo[];
}

export interface ApprovingLevelBackUpInfo {
  userId?: number;
  username: string;
  canApprove: boolean;
}

export interface AccountStatement {
  id?: number;
  transactionDate?: string;
  transactionId?: string;
  description?: string;
  debit?: number;
  credit?: number;
}

export interface AccountClass {
  id?: number;
  caption?: string;
  description?: string;
  accountNumber?: string
  accountClassAlias?: number;
  controlAccounts?: ControlAccount[];
  total?: number;
}

export interface ControlAccount {
  id?: number;
  caption?: string;
  description?: string;
  alias?: number;
  accountClass?: AccountClass;
  accountClassId?: number;
  accounts?: Account[];
  total?: number;
  accountNumber?: number
}

export interface CustomerDivision {
  id?: number;
  industry?: string;
  rcNumber?: string;
  divisionName?: string;
  phoneNumber?: string;
  email?: string;
  logoUrl?: string;
  address?: string;
  customerId?: number;
  customer?: Customer;
  contracts?: Contract[];
  locations?: Location[];
  deviceAttachments?: ConvergenceDeviceAttachment[];
  sbuId?: number;
  clientId?: number;
}

export interface Location {
  id?: number;
  imageUrl?: string;
  name?: string;
  description?: string;
  stateId?: number;
  lgaId?: number;
  street?: string;
  serviceCategories?: LocationServiceCategory[];
  customerDivision?: CustomerDivision;
  guardDeployments?: GuardDeployment[];
  isDeleted?: boolean;
  beats?: Beat[];
  clusterId?: number;
  cluster?: Cluster;
  latitude?: number;
  longitutude?: number;
  customerDivisionId?: number;
}

export interface LocationServiceCategory {
  id?: number;
  serviceCategoryId?: number;
  serviceCategory?: ServiceCategory;
}

export interface Customer {
  id?: number;
  groupName?: string;
  phoneNumber?: string;
  email?: string;
  rcNumber?: string;
  groupTypeId?: number;
  groupType?: GroupType;
  customerDivisions?: CustomerDivision[];
  keyPeople?: Contact[];
  primaryContactId?: number;
  primaryContact?: Contact;
  secondaryContactId?: number;
  secondaryContact?: Contact;
  contacts?: CustomerContact[];
}

export interface AccountDetail {
  id?: number;
  description?: string;
  integrationFlag?: boolean;
  voucherId?: number;
  transactionId?: string;
  transactionDate?: string;
  credit?: number;
  debit?: number;
  branchId?: number;
  branch?: Branch;
  accountMasterId?: number;
  accountId?: number;
}

export interface AccountMaster {
  id?: number;
  description?: string;
  accountMasterAlias?: number;
  integrationFlag?: boolean;
  voucherId?: number;
  voucher?: AccountVoucherType;
  transactionId?: string;
  value?: number;
  branchId?: number;
  branch?: Branch;
  officeId?: number;
  office?: Office;
  customerDivisionId?: number;
  customerDivision?: CustomerDivision;
  accountDetails?: AccountDetail[];
  isCreditNoteCompletedUsed?: boolean;
  creditNoteUsages?: CreditNoteUsage[];
  contractId?: number;
  contract?: Contract;
}

export interface CreditNoteUsage {
  id: number;
  description: string;
  amountUsed: number;
  valueOfWht?: number;
  invoiceId?: number;
  accountMasterId?: number;
  createdById?: number;
  isDeleted: boolean;
  createdAt: Date;
}

export interface ServiceDivisionDetails {
  division: string;
  operatingEntity: string;
  serviceCategory: string;
  serviceGroup: string;
  service: string;
}

export interface DeliverableProportion {
  user?: User;
  proportion?: number;
}

export interface DeliverableStat {
  workLoad: number;
  userDeliverableOnTrack: number;
  userDeliverableInWorkBench: number;
  userDeliverableAtRisk: number;
  userDeliverableOverdue: number;
  pickRate: number;
  earlyDeliveryRate: number;
}

export interface TaskDeliverableSummary {
  productCode?: string;
  taskCaption?: string;
  taskId?: number;
  taskResponsibleId?: number;
  taskResponsibleImageUrl?: string;
  taskResponsibleName?: string;
  deliverableSummaries?: DeliverableSummaries[];
  myProperty: number;
}

export interface DeliverableSummaries {
  deliverableId: number;
  deliverableCaption: string;
  deliveryDate: Date;
  deliverableStatus: boolean;
  deliverableResponsibleId: number;
  isPicked: boolean;
  priority: number;
  deliveryState: number;
  isRequestedForValidation: boolean;
}

export interface ContractService {
  id?: number;
  referenceNumber?: string;
  referenceNo?: string;
  unitPrice?: number;
  quantity?: number;
  discount?: number;
  vat?: number;
  billableAmount?: number;
  budget?: number;
  contractStartDate?: string;
  contractEndDate?: string;
  paymentCycle?: number;
  paymentCycleInDays?: number;
  invoiceCycleInDays?: number;
  firstInvoiceSendDate?: string;
  invoicingInterval?: number;
  problemStatement?: string;
  activationDate?: string;
  fulfillmentStartDate?: string;
  fulfillmentEndDate?: string;
  tentativeDateForSiteSurvey?: string;
  pickupDateTime?: string;
  dropoffDateTime?: string;
  pickupLocation?: string;
  dropofflocation?: string;
  beneficiaryName?: string;
  beneficiaryIdentificationType?: string;
  benificiaryIdentificationNumber?: string;
  tentativeProofOfConceptStartDate?: string;
  tentativeProofOfConceptEndDate?: string;
  tentativeFulfillmentDate?: string;
  programCommencementDate?: string;
  programDuration?: number;
  programEndDate?: string;
  tentativeDateOfSiteVisit?: string;
  isConvertedToContractService?: boolean;
  serviceId?: number;
  version?: number;
  service?: Services;
  contract?: Contract;
  contractId?: number;
  quoteServiceDocuments?: QuoteServiceDocument[];
  sbutoContractServiceProportions?: SBUContractServiceProportion[];
  groupInvoiceNumber: string;
  adHocInvoicedAmount: number;
  // guardLocationDeployments?: GuardLocationDeployment[];
  guardDeployments?: GuardDeployment[];
  uniqueTag?: string;
  uniqueTagandCaption?: string;
  adminDirectTie?: string;
  label?: string;
  isActive: boolean;
  endorsementDescription?: string;
  endorsementTypeId?: number;
  isRequestedForApproval?: boolean;
  isApproved?: boolean;
  isDeclined?: boolean;
  previousContractServiceId?: number;
  dateForNewContractToTakeEffect?: Date;
  branchId?: null;
  officeId?: null;
  customerDivisionId?: number;
  documentUrl?: string;
  isVatable?: boolean;
  createdById?: number;
  isConfiguredForAutoInvoiceSend: boolean;
}

export interface GuardLocationDeployment {
  id?: number;
  locationId?: number;
  contractServiceId?: number;
  createdAt?: Date;
}

export interface Contract {
  id?: number;
  groupInvoiceNumber?: string;
  customerDivisionId?: number;
  customerDivision?: CustomerDivision;
  contractServices: ContractService[];
  contractServiceId?: number;
  quoteId?: number;
  quote?: LeadQuote;
  version?: number;
  createdById?: number;
  createdBy?: User;
  caption?: string;
  uniqueidCaption: string;
  groupContractCategory: GroupContractCategory;
  createdAt?: Date;
  description?: string;
  contacts?: ContractContact[];
  isDeleted?: boolean;
  isActive?: boolean;
  isConfiguredForAutoInvoiceSend: boolean;
  dateConfiguredForAutoInvoiceSend?: Date;
}

export interface SbuToQuoteServiceProp {
  status: number;
  userInvolvedId: number;
  quoteServiceId: number;
  strategicBusinessUnitId: number;
}
export interface SbuToContractServiceProp {
  status: number;
  userInvolvedId: number;
  contractServiceId: number;
  strategicBusinessUnitId: number;
}

export interface Permissions {
  controller: string;
  module: string;
  action: string;
  description: string;
  permission: number;
}

export interface GroupedPermissions {
  groupName: string;
  splitName: string;
  module: string;
  permissionList: Permissions[];
}

export interface Invoice {
  id?: number;
  description?: string;
  invoiceNumber?: string;
  unitPrice?: number;
  quantity?: number;
  discount?: number;
  value?: number;
  dateToBeSent?: Date;
  isInvoiceSent?: boolean;
  customerDivisionId?: number;
  customerDivision?: CustomerDivision;
  contractId?: number;
  contractServiceId?: number;
  contractService: ContractService;
  endDate?: Date;
  startDate?: Date;
  isReceiptedStatus?: number;
  canPickInvoice?: boolean;
  transactionId?: number;
  totalAmountReceipted?: number;
  // receipts: Receipt[];
  isFinalInvoice: boolean;
  toReceiptValue: number;
  totalReceiptApplied: number;
  masterServiceAssignmentId: number;
  isAccountPosted?: boolean;
  receipts?: Receipt[];
  invoiceEvidenceSubmissionId?: number;
  invoiceReferenceDateCaptureId?: number;
  isMatched?: boolean;
}

export interface ApprovalLevel {
  id?: number;
  caption?: string;
  alias?: string;
  description?: string;
}

export interface ApprovalLimit {
  id?: number;
  caption?: string;
  processesRequiringApprovalId: number;
  processesRequiringApproval: ProcessRequiringApproval;
  upperlimitValue?: number;
  lowerlimitValue?: number;
  approverLevelId?: number;
  approverLevel?: ApprovalLevel;
  sequence?: number;
  isBypassRequired?: false;
}

export interface ProcessRequiringApproval {
  id?: number;
  caption?: string;
  description?: string;
}

export interface EndorsementType {
  id?: number;
  caption?: string;
  description?: string;
  openingDate: Date;
  closingDate: Date;
  isBudgetRelated?: boolean;
  isBudgettableType?: boolean;
}

export interface ClientBeneficiary {
  id?: number;
  beneficiaryFamilyCode?: string;
  beneficiaryCode?: string;
  isPrincipal?: boolean;
  title?: string;
  firstName?: string;
  lastName?: string;
  middleName?: string;
  mobile?: string;
  email?: string;
  address?: string;
  meansOfIdentifcationId?: number;
  identificationNumber?: string;
  imageUrl?: string;
  relationshipId: number;
  clientId?: number;
}

export interface SBUProportion {
  id?: number;
  leadGenerationProportion: number;
  leadClosureProportion: number;
  operatingEntityId: number;
}

export class OtherLeadCaptureInfo {
  cooperateEstimatedAnnualIncome?: number;
  cooperateEstimatedAnnualProfit?: number;
  cooperateBalanceSheetSize?: number;
  cooperateStaffStrength?: number;
  cooperateCashBookSize?: number;
  individualEstimatedAnnualIncome?: number;
  individualDisposableIncome?: number;
  individualResidenceSize?: number;
  groupTypeId?: number;
  groupType?: GroupType | null;
  leadDivisionId?: number;
  leadDivision?: LeadDivision | null;
}

export class Report {
  externalLocation?: string;
}

export class Location {}

export interface Endorsement {
  id?: number;
  referenceNumber?: string;
  referenceNo?: string;
  unitPrice?: number;
  quantity?: number;
  discount?: number;
  vat?: number;
  billableAmount?: number;
  budget?: number;
  contractStartDate?: string;
  contractEndDate?: string;
  paymentCycle?: number;
  paymentCycleInDays?: number;
  invoiceCycleInDays?: number;
  firstInvoiceSendDate?: string;
  invoicingInterval?: number;
  problemStatement?: string;
  activationDate?: string;
  fulfillmentStartDate?: string;
  fulfillmentEndDate?: string;
  tentativeDateForSiteSurvey?: string;
  pickupDateTime?: string;
  dropoffDateTime?: string;
  pickupLocation?: string;
  dropofflocation?: string;
  beneficiaryName?: string;
  beneficiaryIdentificationType?: string;
  benificiaryIdentificationNumber?: string;
  tentativeProofOfConceptStartDate?: string;
  tentativeProofOfConceptEndDate?: string;
  tentativeFulfillmentDate?: string;
  programCommencementDate?: string;
  programDuration?: number;
  programEndDate?: string;
  tentativeDateOfSiteVisit?: string;
  isConvertedToContractService?: boolean;
  serviceId?: number;
  version?: number;
  service?: Services;
  quoteServiceDocuments?: QuoteServiceDocument[];
  sbuToQuoteServiceProportions?: SBUQuoteServiceProportion[];
  createdAt?: string;
  createdBy?: User;
  createdById?: number;
  isApproved?: boolean;
  endorsementType?: EndorsementType;
  endorsementDescription?: string;
  previousContractServiceId?: number;
  customerDivisionId?: number;
  customerDivision?: CustomerDivision;
  dateForNewContractToTakeEffect?: string;
  documentUrl?: string;
  branch?: Branch;
  office?: Office;
  daysAffected: number;
}

export interface InvoiceMailDTO {
  id: number;
  invoiceNumber: string;
  total: number;
  subTotal: number;
  vat: number;
  discount: number;
  unInvoicedAmount: number;
  invoicingCycle: string;
  startDate: Date;
  endDate: Date;
  daysUntilDeadline: number;
  subject: string;
  recepients: string[];
  clientInfo: ClientInfoMailDTO;
  contractServices: ContractServiceMailDTO[];
  contract: Contract;
}

export interface ContractServiceMailDTO {
  description: string;
  unitPrice: number;
  quantity: number;
  total: number;
  discount: number;
  uniqueTag: string;
  adminDirectTie: string;
  id: number;
}

export interface ClientInfoMailDTO {
  name: string;
  email: string;
  street: string;
  lga: string;
  state: string;
}

export interface Designation {
  id?: number;
  caption?: string;
  description?: string;
}

export interface ClientContactQualification {
  id?: number;
  caption?: string;
  description?: string;
}

export interface EngagementType {
  id?: number;
  caption?: string;
  description?: string;
}

export interface EngagementReason {
  id?: number;
  caption?: string;
  description?: string;
}

export interface LeadEngagement {
  id?: number;
  caption?: string;
  engagementDiscussion?: string;
  engagementTypeId?: number;
  engagementType?: EngagementType;
  engagementReasonId?: number;
  engagementReason?: EngagementReason;
  date?: Date;
  leadId?: number;
  leadCaptureStage?: string;
  engagementOutcome?: string;
  leadDivisionContactLeadEngagements?: any;
  leadDivisionKeyPersonLeadEngagements?: any;
  leadEngagementUserProfiles?: any;
}

export interface ServicePricing {
  id?: number;
  serviceId?: number;
  service?: Services;
  branchId?: number;
  branch?: Branch;
  minimumAmount?: number;
  maximumAmount?: number;
}

export interface ClientPolicy {
  id?: number;
  customerDivisionId: number;
  contractId?: number;
  contractServiceId?: number;
  autoRenew?: boolean;
  rateReviewInterval?: number;
  nextRateReviewDate?: string;
  renewalTenor?: number;
}

export interface Suspect {
  id?: number;
  leadOrigin?: LeadOrigin;
  leadType?: LeadType;
  branch?: Branch;
  office?: Office;
  leadOriginId?: number;
  groupTypeId?: number;
  groupType?: GroupType;
  branchId?: number;
  officeId?: number;
  industryId?: number;
  stateId?: number;
  lgaId?: number;
  firstName?: string;
  lastName?: string;
  otherName?: string;
  email?: string;
  mobileNumber?: string;
  imageUrl?: string;
  street?: string;
  address?: string;
  rcNumber?: string;
  businessName?: string;
  tier?: string;
  leadTypeId?: number;
  suspectQualifications: SuspectQualification[];
  name?: string;
}

export interface SuspectReceivingDTO {
  leadOriginId?: number;
  groupTypeId?: number;
  branchId?: number;
  officeId?: number;
  industryId?: number;
  stateId?: number;
  lgaId?: number;
  firstName?: string;
  lastName?: string;
  otherName?: string;
  email?: string;
  mobileNumber?: string;
  imageUrl?: string;
  street?: string;
  address?: string;
  rcNumber?: string;
  businessName?: string;
  tier?: string;
  leadTypeId?: number;
}

export interface SuspectQualification {
  id?: number;
  problemStatement?: string;
  plan?: string;
  goal?: string;
  plans?: string;
  challengeCompleted: boolean;
  timingCompleted: boolean;
  authorityCompleted: boolean;
  budgetCompleted: boolean;
  upcomingEvents: string;
  serviceQualifications?: ServiceQualification[];
  toBeAddressed?: boolean;
  isPriority?: boolean;
  priority?: number;
  canBeSolved?: boolean;
  productSuggestion?: string;
  influencerExists?: boolean;
  decisionMakerExists?: boolean;
  gateKeeperExists?: boolean;
  ownersExists?: boolean;
  emotionalDisposition?: number;
  suspect?: Suspect;
  challengeScore?: number;
  timingScore?: number;
  budgetScore?: number;
  authorityScore?: number;
  rank?: string;
}

export interface ServiceQualification {
  id?: number;
  serviceId?: number;
  service?: Services;
  dateToStart?: string;
  quantityEstimate?: number;
  estimatedDurationInMonths?: number;
  budget?: number;
}

export interface AppReview {
  id?: number;
  module?: string;
  lookAndFeelRating?: string;
  functionalityRating?: string;
  createdById?: number;
}

export interface AppFeedback {
  id?: number;
  feedbackType?: string;
  description?: string;
  documentsUrl?: string;
  createdById?: number;
}

export interface GMAGenericResponse<T = object> {
  responseCode: string;
  responeMessage: string;
  responseObject?: T;
}

export interface ApprovingLevelOfficeReceivingDTO {
  userId: number;
  officersIds: number[];
  endorsementTypes?: CreateApprovalChecklistEndType[];
}

export interface ApprovingLevelOffice {
  id: number;
  userId?: number;
  user?: User;
  typeName: string;
  endorsementTypeId?: number;
  approvingOfficers: ApprovingLevelOfficer[];
  createdById: number;
  isDeleted: boolean;
  createdAt: Date;
}

export interface ApprovingLevelOfficer {
  id: number;
  approvingLevelOfficeId?: number;
  approvingLevelOffice?: ApprovingLevelOffice;
  userId?: number;
  user?: User;
  typeName: string;
  endorsementTypeId?: number;
  createdById?: number;
  isDeleted: boolean;
  createdAt: Date;
}

export interface ApprovalNotificationReceivingDTO {
  approvals: Approval[];
  requestName: string;
  clientName: string;
  serviceName: string;
  groupName: string;
}

export interface SendFinalApprovalVM {
  approvals: Approval[];
  subject: string;
  requestName: string;
  requestType: string;
  initiator: string;
  decription: string;
}

export interface GetApprovalSummaryInfoReceivingDTO {
  leadIds: number[];
  serviceIds: number[];
  contractServiceIds: number[];
  contractIds: number[];
}

export interface GetApprovalSummaryInfoTransferDTO {
  totalApprovalRequests: number;
  totalPendingApprovalForMe: number;
  totalApprovalTypesWaitingForMe: number;
  totalApprovalOnQueue: number;
  approvalsForMeIds: number[];
}

export interface ReverseReceiptVM {
  receiptId: number;
}

export interface Receipt {
  id: number;
  caption: string;
  invoiceNumber: string;
  invoiceValue: number;
  transactionId: string;
  invoiceValueBalanceBeforeReceipting: number;
  dateAndTimeOfFundsReceived: Date;
  receiptValue: number;
  depositor: string;
  invoiceValueBalanceAfterReceipting: number;
  isTaskWitheld: boolean;
  valueOfWHT: number;
  receiptNumber: string;
  invoiceId: number;
  isReversed: boolean;
  isReversalReceipt: boolean;
  evidenceOfPaymentUrl: string;
  referenceNumber: string;
}

export interface RealGroupedReceipt {
  narration: string;
  invoiceNumber: string;
  receiptNumber: string;
  totalValue: number;
  receipts: GroupedReceiptDetail[];
}

export interface GroupedReceipt {
  narration: string;
  invoiceNumber: string;
  receiptNumber: string;
  totalValue: number;
  receipts: GroupedReceiptDetail[];
}

export interface GroupedReceiptDetail {
  receiptValue: number;
  depositor: string;
  dateReceipted: Date;
  receiptId: number;
  receipt: Receipt;
}

export interface ReverseGroupReceiptVM {
  invoiceNumber: string;
}

export interface GroupedInvoice {
  invoiceNumber: string;
  totalInvoiceValue: number;
  isNotFullyReceipted: boolean;
  isNotFullyReversed: boolean;
  invoices: Invoice[];
}

export interface ReverseGroupInvoiceVM {
  invoiceNumber: string;
  invoiceId: number;
}

export interface UpdateContractServicesReceivingDTO {
  contractServiceIds: number[];
  branchId: number;
  officeId: number;
}

export interface BulkUploadVM {
  details: BulkUploadDetail[];
  evidenceUrl: string;
}

export interface BulkUploadDetail {
  sn?: number;
  clientId: number;
  clientName?: string;
  description: string;
  amount: number;
  accountId: number;
  accountNumber?: string;
  referenceNo: string;
  isTaxWitheld: boolean;
  isFixedWHT: boolean;
  valueOfWHT: number;
  fundReceivedDate: string;
  applyAutomaticSettlement: boolean;
}

export interface AdvancePayment {
  createdById?: number;
  isDeleted: boolean;
  fundReceivedDate?: Date;
  valueOfWHT?: number;
  isFixedWHT: boolean;
  referenceNumber: string;
  advancePaymentUsages: AdvancePaymentUsage[];
  isCompletelyUsed: boolean;
  evidenceOfPaymentUrl: string;
  amount: number;
  customerDivisionId?: number;
  description: string;
  id: number;
  createdAt: Date;
  postingId?: number;
  datePosted?: Date;
}

export interface AdvancePaymentUsage {
  id: number;
  description: string;
  amountUsed: number;
  valueOfWht?: number;
  invoiceId?: number;
  advancePaymentId?: number;
  createdById?: number;
  isDeleted: boolean;
  createdAt: Date;
}

export interface AddPostingVM {
  voucherTypeId: number;
  totalValue: number;
  branchId: number;
  operatingEntityId: number;
  customerId?: number;
  transactionDate: Date;
  officeId: number;
  serviceCategoryId: number;
  transactionDecription: string;
  currencyId?: number;
  currencyExchangeRateId?: number;
  rateApplied?: number;
  postingDetails: AddPostingDetailVM[];
}

export interface AddPostingDetailVM {
  accountId: number;
  description: string;
  debitValue: number;
  creditValue: number;
}

export interface SpecialRequestWorkbenchVM {
  requestId: number;
  platformId: number;
  platform: string;
  requestById: number;
  requestBy: string;
  location: string;
  locationLongitude: number;
  locationLatitude: number;
  serviceId: number;
  service: string;
  tasksCount: number;
  deliverablesCount: number;
  quantitySuggested: number;
  quantityApproved: number;
  serviceRate: number;
  disount: number;
  vat: number;
  amount: number;
  totalAmount: number;
  requesterContactPhoneNumber: string;
  requesterContactEmail: string;
  requestDate: Date;
  tasks: ProjectTask[];
  deliverables: ProjectDeliverable[];
  contractId: any;
  hasContract: boolean;
}

export interface ProjectTask {
  taskId: number;
  task: string;
  taskDescription: string;
  taskStatus: string;
  // deliverables": []
}

export interface ProjectDeliverable {
  taskId: number;
  deliverableId: number;
  deliverable: string;
  dueDate: string;
  statusName: string;
  confidenceLevel: string;
  description: string;
}
export interface BankReceivingDTO {
  name: string;
  slogan: string;
  alias: string;
  isActive?: boolean;
  logoUrl: string;
  sortCode: string;
  bankCode: string;
  coverageType: BankCoverageTypeEnum;
  ssrsChequeUrl?: string;
}

export interface GetPendingApprovalsBulkView {
  id: number;
  className: string;
  typeName: string;
  name: string;
  description: string;
  documentUrl: string;
  contractId?: number;
  customerDivisionId?: number;
  createdAt: Date;
  createdById: number;
  joinedApprovalIds: number[];
  status: number;
  pendingParticipantsUserIds: number[];
  canApproveBasedOnSequence: boolean;
  canApproveBasedOnSequenceReason?: string;
  approversView?: GetBulkApprovalViewModel[];
}

export interface GetBulkApprovalViewVM {
  uniquqIdentifier: number;
  approvalType: string;
  customerDivisionId?: number;
  joinedUniqueIdentifiers?: number[];
}

export interface GetBulkApprovalViewModel {
  id: number;
  imageUrl: string;
  caption: string;
  level: string;
  whom: string;
  status: number;
  comments: string[];
  items: {
    approvalItem: string;
    level: string;
    levelId?: number;
  }[];
  levelIds?: number[];
}

export interface GetBulkApprovalDetailView {
  division: string;
  market: string;
  serviceGroup: string;
  serviceCategory: string;
  dateToTakeEffect?: Date;
  contractStartDate?: Date;
  contractEndDate?: Date;
  activationDate?: Date;
  invoicingInterval: string;
  paymentCycle: string;
  firstInvoiceSeedDate?: Date;
  description: string;
  daysAffected: number;
  details: GetBulkApprovalDetailViewList[];
  customerInfo: GetBulkApprovalDetailViewAdditional;
  contactsInfo: GetBulkApprovalDetailViewAdditional[];
}

export interface GetBulkApprovalDetailViewAdditional {
  imageUrl: string;
  name: string;
  email: string;
  mobile: string;
  industry: string;
}

export interface GetBulkApprovalDetailViewDoc {
  caption: string;
  docUrls: string[];
}

export interface GetBulkApprovalDetailViewList {
  isTotal: boolean;
  serviceName: string;
  uniqueTag: string;
  unitPrice: number;
  qty: number;
  vat: number;
  totalAmt: number;
  newUnitPrice: number;
  newQty: number;
  newVat: number;
  newTotalAmt: number;
  division: string;
  market: string;
  serviceGroup: string;
  serviceCategory: string;
  dateToTakeEffect?: Date;
  docs: GetBulkApprovalDetailViewDoc[];
  strategicBUInfos: GetBulkApprovalDetailViewSBU[];
  quoteServiceId?: number;
  serviceCostAnalysisLogs?: QuoteServiceCostAnalysisLog[];
  quoteServiceAdminDirectTie?: string;
  skipRendering?: boolean;
  renderServiceName?: string;
  tiedApprovalDetail?: GetBulkApprovalDetailViewList;
}

export interface GetBulkApprovalDetailViewSBU {
  id: number;
  proportion: number;
  status: string;
  strategicBU: string;
  user: string;
  image: string;
}

export interface BulkApprovalActionVM {
  uniquqIdentifier: number;
  approvalType: string;
  isApproved: boolean;
  joinedUniqueIdentifiers?: number[];
  comment?: string;
  checklists?: BulkApprovalExecutionChecklistVM[];
}

export interface BulkApprovalExecutionVM {
  uniquqIdentifier: number;
  approvalType: string;
  joinedUniqueIdentifiers?: number[];
  checklists?: BulkApprovalExecutionChecklistVM[];
}

export interface UpdateInvoiceInfoVM {
  invoiceNumber: string;
  invoiceDescription?: string;
  contractServicesIds: number[];
}

export interface GetAllowedAlternateApproversVM extends GetBulkApprovalViewVM {
  alternateEntityType: string;
}

export interface AllowedAlternateApproversVM {
  image: string;
  userId: number;
  userName: string;
}

export interface AlternateBulkApproveVM extends BulkApprovalActionVM {
  alternateProfileId: number;
}

export interface UnUsedAdvancePaymentView {
  id: number;
  clientNo: string;
  client: string;
  value: number;
  balance: number;
  contractId?: number;
  contracts: Contract[];
  contract?: Contract;
}

export interface ProcessBulkSettlementVM {
  isSpecificContracts: boolean;
  details: ProcessBulkSettlementDetails[];
}

export interface ProcessBulkSettlementDetails {
  advancePaymentId: number;
  contractId?: number;
}

export interface GetInvoicesAggregatedDitsVM {
  journalHeaderId: number;
  currencyCode: string;
  transactionReference: string;
  invoiceNumber: string;
  invoiceDescription: string;
  transactionDate: string;
  transmissionStatus: string;
  transmissionMessage: string;
  breakdown: GetInvoicesAggregatedDitsBreakdownVM[];
}

export interface GetInvoicesAggregatedDitsBreakdownVM {
  account: string;
  sourceCode: string;
  description: string;
  crAmount: number;
  drAmount: number;
  isTotal: boolean;
}

export interface GetOutstandingApproveesInfoView {
  responsibleUserId: number;
  numberOfApprovalWaiting: number;
  longestWaitingTime: string;
}

export interface GetOutstandingApproveesInfoView_V2
  extends GetOutstandingApproveesInfoView {
  userResponsible?: string;
  numberOfApprovalPending?: number;
  details: GetPendingApprovalsBulkViewV2[];
}

export interface ProcessCreditNoteReversalVM {
  creditNoteIds: number[];
}

export interface GetPendingApprovalsBulkViewV2
  extends GetPendingApprovalsBulkView {
  isOverdue: boolean;
}

export interface InvoiceValidForReplacementVM {
  invoiceNumber: string;
  invoiceValue: number;
  startDate: Date;
  endDate: Date;
  details: InvoiceValidForReplacementDitsVM[];
}

export interface InvoiceValidForReplacementDitsVM {
  invoiceId: number;
  serviceName: string;
  uniqueTag: string;
  adminDirectTie: string;
  unitPrice: number;
  vat: number;
  quantity: number;
  total: number;
  serviceRelationshipEnum: number;
  percentageReduction: number;
  isVatable: boolean;
}

export interface PerformInvoiceReplacementVM {
  contractId: number;
  invoicesToReplace: InvoiceValidForReplacementDitsVM[];
}

export interface BulkApprovalExecutionChecklistVM {
  approvalChecklistId: number;
  isDeclaredCompleted: boolean;
  isDeclaredComment?: string;
}

export interface InvoiceEvidenceSubmission {
  id: number;
  invoiceId?: number;
  invoiceNumber: string;
  contractId?: number;
  isDeclaredSubmission: boolean;
  isDeclaredFactoredCreditNote: boolean;
  evidenceUrls: string;
  creditNotes: InvoiceEvidenceSubmissionCreditNoteMap[];
  isDeleted: boolean;
  createdById: number;
  createdBy: User;
  createdAt: Date;
}

export interface InvoiceEvidenceSubmissionCreditNoteMap {
  id: number;
  invoiceEvidenceSubmissionId: number;
  creditNoteId: number;
  creditNote: AccountMaster;
  createdAt: Date;
}

export interface SaveInvoiceEvidenceSubmissionVM {
  invoiceId: number;
  isDeclaredPeriodSubmission: boolean;
  isDeclaredFactorSubmission: boolean;
  creditNoteIds: number[];
  evidenceUrls: string[];
}

export interface UpdateContractServiceUnqueTagVM {
  isAllContractServices: boolean;
  contractId: number;
  contractServiceId: number;
  newTag: string;
}

export interface SbuAttachment {
  id?: number;
  strategicBusinessUnitId?: number;
  attachedSBUId?: number;
  attachedSBU?: User;
  createdById?: number;
  lastModifiedById?: number;
  createdAt?: Date;
}

export interface CreateSBUAttachmentVM {
  strategicBUId: number;
  userId: number;
}

export interface ProfomaInvoiceView {
  id: number;
  invoiceNumber: string;
  invoiceDate: Date;
  invoiceValue: number;
  transactionId: string;
  isClosedOut: boolean;
  customerDivisionId?: number;
  contractId?: number;
  createdById?: number;
  contractDescription: string;
}

export interface GenerateProfomaInvoiceVM {
  contractId: number;
  customerDivisionId: number;
  transactionDateFrom: Date;
  tranactionDateTo: Date;
  contractServices: ContractService[];
}

export interface GetGeneratedProfomaInvoiceDetailView {
  total: number;
  discount: number;
  dits: GetGeneratedProfomaInvoiceDetailViewDit[];
}

export interface GetGeneratedProfomaInvoiceDetailViewDit {
  id: number;
  description: string;
  uniqueTag: string;
  quantity: number;
  unitPrice: number;
  amount: number;
  discount: number;
  adminDirectTie: string;
  startDate: string;
  vat: number;
  total: number;
}

export interface ProfomaInvoice {
  id: number;
  description: string;
  invoiceNumber: string;
  unitPrice: number;
  quantity: number;
  discount: number;
  value: number;
  dateToBeSent: Date;
  isInvoiceSent: boolean;
  customerDivisionId?: number;
  contractId?: number;
  contract?: Contract;
  contractServiceId: number;
  createdAt: Date;
  endDate: Date;
  startDate: Date;
  transactionId: string;
  createdById?: number;
  groupInvoiceNumber: string;
  mappings: ProfomaInvoiceMapping[];
  isClosedOut: boolean;
  dateClosedOut?: Date;
  closedOutById?: number;
}

export interface ProfomaInvoiceMapping {
  id: number;
  profomaInvoiceId?: number;
  invoiceId?: number;
  creditNoteId?: number;
  createdById?: number;
  createdAt: Date;
}

export interface GetAllUnMappedInvoicesAndCreditNotesView {
  recurringInvoices: GetAllUnMappedInvoicesAndCreditNotesViewDit[];
  adhocInvoices: GetAllUnMappedInvoicesAndCreditNotesViewDit[];
  creditNotes: GetAllUnMappedInvoicesAndCreditNotesViewDit[];
}

export interface GetAllUnMappedInvoicesAndCreditNotesViewDit {
  id: number;
  caption: string;
  value: number;
  contractId: number;
  startDate: Date;
  invoiceNumber?: string;
  groupIdentifier?: string;
}

export interface CloseOutProfomaInvoiceVM {
  transactionId: string;
  profomaInvoiceId: number;
  contractId: number;
  invoicesIds: number[];
  creditNoteIds: number[];
}

export interface UploadRawInflowItemsVM {
  items: UploadRawInflowItemsDataVM[];
}

export interface UploadRawInflowItemsDataVM {
  sn?: number;
  depositorName: string;
  bankNarration: string;
  bankAccount: string;
  bankAccountId: number;
  amountPaid: number;
  currencyId: number;
  datePaid: Date;
}

export interface RawInflowItem {
  id: number;
  batchUnqiueCode: number;
  depositor: string;
  bankNarration: string;
  halobizBankAcount: string;
  halobizAccountId?: number;
  amountPaid: number;
  currencyId?: number;
  datePaid: Date;
  isWHTDeducted: boolean;
  whtValue?: number;
  isWHTDeductedOption?: BooleanOption;
  isLocalDeducted: boolean;
  localValue?: number;
  isLocalDeductedOption?: BooleanOption;
  isVatDeducted: boolean;
  vatValue?: number;
  isVatDeductedOption?: BooleanOption;
  clientId?: number;
  client?: CustomerDivision;
  clientName?: string;
  isSubmitted: boolean;
  submittedById?: number;
  dateSubmitted?: Date;
  isPosted: boolean;
  postedById: number;
  datePoted?: Date;
  advancePaymentPostingId?: number;
  createdById?: number;
  isDeleted: boolean;
  timeSpanInHrs: number;
  createdAt: Date;
}

export interface SubmitRawInflowItemVM {
  rawInflowItemId: number;
  isWHTDeducted: boolean;
  isLocalDeducted: boolean;
  isVatDeducted: boolean;
  clientId: number;
  paymentDate: Date;
  additionalDescription?: string;
  vatOutput?: number;
  whtValue?: number;
  onePercentValue?: number;
}

export interface BooleanOption {
  key: boolean;
  value: string;
}

export interface PostRawInflowItemVM {
  rawInflowItemId: number;
  vatOutput?: number;
  whtValue?: number;
  onePercentValue?: number;
  clientId?: number;
  exchangeRateUsedId?: number;
  actualValuePostingWith?: number;
  applyAutomaticSettlement?: boolean;
}

export interface PostMultipleRawInflowItemVM {
  rawInflowItemIds: number[];
  applyAutomaticSettlement: boolean;
}

export interface ContractAutoRenewPolicy {
  id: number;
  clientName?: string;
  contractId?: number;
  contract?: Contract;
  daysBeforeExpiration: number;
  isActive: boolean;
  logs: ContractAutoRenewPolicyLog[];
  lastExecutionDate?: Date;
  daysTillContractExpires: number;
  createdById?: number;
  isDeleted: boolean;
  createdAt: Date;
}

export interface ContractAutoRenewPolicyLog {
  id: number;
  policyId?: number;
  message: string;
  status: boolean;
  createdAt: Date;
}

export interface SaveAutoRenewContractPolicyVM {
  contractId: number;
  daysBeforeExpiration: number;
}

export interface UpdateAutoRenewContractPolicyVM {
  daysBeforeExpiration?: number;
  toggleStatus: boolean;
  applySoftDelete: boolean;
}

export interface DtrackJournalHeader {
  id: number;
  year: number;
  month: number;
  subAccount: string;
  accountSection: string;
  currencyCode: string;
  exchangeRate: number;
  tranDate: string;
  documentDate: string;
  tranType: string;
  module: string;
  transactionReference: string;
  note: string;
  methodName: string;
  dtrackJournalCode: string;
  isSentToDtrack: boolean;
  dateSentToDtrack?: Date;
  isDtrackPushFailed: boolean;
  dtrackFailedResponse: string;
  journalLines: DtrackJournalLine[];
  accountMasters: AccountMaster[];
  dateCreated?: Date;
}

export interface DtrackJournalLine {
  id: number;
  journalHeaderId?: number;
  glAccount: string;
  subAccount: string;
  accountSection: string;
  costCenter: string;
  amount: number;
  factor: number;
  details: string;
  sourceCode: string;
  dateCreated?: Date;
}

export interface ItemGroupConsumption {
  id: number;
  itemGroupId?: number;
  totalAmount: number;
  isPosted: boolean;
  postingId: number;
  datePosted?: Date;
  month: number;
  year: number;
  details: ItemGroupConsumptionDetail[];
  createdAt: Date;
}

export interface ItemGroupConsumptionDetail {
  id: number;
  groupConsumptionId?: number;
  storeItemLogs: StoreItemLog[];
  itemId?: number;
  totalQuantity: number;
  totalAmount: number;
  createdAt: Date;
}

export interface StoreItemLog {
  id: number;
  storeItemId?: number;
  batchRequestId?: number;
  batchRequestItemId?: number;
  userRequestedId?: number;
  consumptionAggregatedDetailId?: number;
  quantity: number;
  amount: number;
  quantityConfirmed: number;
  quantityDispatched: number;
  isPendingConfirmationIn: boolean;
  isExhausted: boolean;
  createdAt: Date;
  createdById: number;
}

export interface ClientNotificationMail {
  id: number;
  customerDivisionId?: number;
  financeVoucherTypeId?: number;
  emailAddress: string;
  note: string;
  createdById?: number;
  isDeleted: boolean;
  createdAt: Date;
}

export interface AddNewClientNotificationEmailVM {
  clientId: number;
  categoryId: number;
  email: string;
  note?: string;
}

export interface UpdateClientNotificationEmailVM {
  email?: string;
  categoryId?: number;
  note?: string;
}

export interface AddMultipleClientNotificationEmailVM {
  clientId: number;
  categoriesIds: number[];
  email: string;
  note?: string;
}

export interface ConfigureInvoiceAutoSendVM {
  contractId: number;
  contractServiceIds: number[];
  isDeleting?: boolean;
}

export interface AutoInvoiceSendLog {
  id: number;
  note: string;
  contractId?: number;
  contractServiceId?: number;
  invoiceNumber: string;
  invoiceStartDate: Date;
  isSuccessful: boolean;
  message: string;
  dateSent: Date;
}

export interface ApprovalLevelSequence {
  id: number;
  endorsementTypeId?: number;
  approverLevelId?: number;
  isNonPrinicipalLevel: boolean;
  sequence: number;
  className: string;
  typeName: string;
  isDeleted: boolean;
  createdById: number;
  createdAt: Date;
  updatedAt: Date;
}

export interface CreateApprovingLevelSequenceVM {
  endorsementTypeId?: number;
  approvalLevelId: number;
  typeName: string;
}

export interface ContractRenewNotification {
  id: number;
  contractId?: number;
  contract?: Contract;
  contractDescription?: string;
  clientName?: string;
  renewalNoticePeriodInMonths: number;
  renewalNoticePeriodInMonthsStr: string;
  notificationEmails: string;
  noticePeriod?: string;
  dateLastSent?: Date;
  createdById?: number;
  isDeleted: boolean;
  createdAt: Date;
  updatedAt: Date;
}

export interface AddContractRenewNotificationVM {
  contractId: number;
  notificationEmails: string;
  selectedMonthNotice: number[];
}

export interface AddMultipleContractRenewNotificationVM {
  contractIds: number[];
  notificationEmails: string;
  selectedMonthNotice: number[];
}

export interface ServiceCostAnalysisLineItem {
  id?: number;
  serviceId?: number;
  costCaption: string;
  costDescription: string;
  createdById?: number;
  isDeleted?: boolean;
  createdAt?: Date;
  updatedAt?: Date;
  quantity?: number;
  unitPrice?: number;
}

export interface CreateServiceCostAnalysisItemsVM {
  serviceIds: number[];
  lineItems: CreateServiceCostAnalysisItemVM[];
}

export interface CreateServiceCostAnalysisItemVM {
  caption: string;
  description: string;
}

export interface UpdateServiceCostAnalysisItemVM
  extends CreateServiceCostAnalysisItemVM {
  id?: number;
}

export interface UpdateServiceCostAnalysisItemsVM {
  lineItems: UpdateServiceCostAnalysisItemVM[];
}

export interface GroupedServiceCostAnalysis {
  serviceId: number;
  serviceName: string;
  lineItemsCount: number;
  lineItems: ServiceCostAnalysisLineItem[];
}

export interface SaveServiceCostAnalysisVM {
  items: SaveServiceCostAnalysisItemVM[];
}

export interface SaveServiceCostAnalysisItemVM {
  quoteServiceId: number;
  serviceCostAnalysisLineItemId: number;
  quantity: number;
  unitPrice: number;
}

export interface QuoteServiceCostAnalysisLog {
  id?: number;
  quoteServiceId?: number;
  serviceCostAnalysisLineItemId?: number;
  serviceCostAnalysisLineItem?: ServiceCostAnalysisLineItem;
  quantity?: number;
  unitPrice?: number;
  costAmount?: number;
  isProfitMargin?: boolean;
  createdById?: number;
  isDeleted?: boolean;
  createdAt?: Date;
}

export interface GetQuoteServiceCostAnalysisVM {
  quoteServiceIds: number[];
}

export interface GenericKeyValueType<T, V>{
  Key: T,
  Value: V
}

export interface PostDateEndrosementForApprovalVM{
  isDifferentMonthChange: boolean,
  contractServiceIds: number[],
  newActivationDay: number,
  newActivationMonth?: number,
  newActivationYear?: number
}