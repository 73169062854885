import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {DropReason, Lead} from '../../../../interfaces/home';
import {LeadService} from '../../../../services/lead.service';
import {DynamicDialogConfig, DynamicDialogRef} from 'primeng/dynamicdialog';
import {DropReasonService} from '../../../../services/drop-reason.service';

@Component({
  selector: 'app-lead-drop-form',
  templateUrl: './lead-drop-form.component.html',
  styleUrls: ['./lead-drop-form.component.scss']
})
export class LeadDropFormComponent implements OnInit {
  dropLeadForm: FormGroup;
  displayLoading: boolean;
  public uploadStatus: string;
  message: {type: string, value: string };
  dropReasons: DropReason[] = [];
  private currentLead: Lead;
  constructor(
      private formBuilder: FormBuilder,
      private leadService: LeadService,
      private dropLeadFromRef: DynamicDialogRef,
      private dropReasonService: DropReasonService,
      private readonly dynamicDialogConfig: DynamicDialogConfig
  ) { }

  async ngOnInit() {
    const configData = this.dynamicDialogConfig.data;
    this.currentLead = configData.lead;
    this.dropReasons = configData.dropReasons;
    this.dropLeadForm = this.formBuilder.group({
      dropReasonId: [null, Validators.required],
      dropLearning: ['', Validators.required]
    });
    await this.fetchDropReason();
  }

  async fetchDropReason(){
    await this.dropReasonService.allDropReason().subscribe( async (result: any) => {
      this.dropReasons = result.responseData;
      console.log("*****",this.dropReasons)
    });
  }

  submitDropLeadForm() {
    this.message = {type:'info', value:'Dropping lead'};
    this.displayLoading = true;
    const dropData= {
      dropReasonId: (this.dropLeadForm.get('dropReasonId').value).id,
      dropLearning: this.dropLeadForm.get('dropLearning').value
    }
    // todo check if the lead can be drop
    this.leadService.dropLead(this.currentLead.id, dropData).subscribe( async (res: any) => {
      this.displayLoading = false;
      this.message = {type:'success', value:'Lead Dropped'};
      this.dropLeadFromRef.close('complete');
    })
  }
}
