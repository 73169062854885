import { ThrowStmt } from '@angular/compiler';
import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { EventService } from 'src/app/demo/service/eventservice';
import { UserService } from 'src/app/services/user.service';
import { QualificationsService } from '../qualifications/qualifications.service';
import { OpportunitiesService } from './opportunities.service';

@Component({
  selector: 'app-opportunities',
  templateUrl: './opportunities.component.html',
  styleUrls: ['./opportunities.component.scss']
})
export class OpportunitiesComponent implements OnInit {
  workspaceIsLoading= false;
  initiateColumn: any[]=[];
  displayAdvanced=false;
  leadInfoName:any;
  defaultNumber= 0;
  tempInitiateColumn: any[]=[];
  tempdealCapture: any[]=[];
  dealCapture: any[]=[];
  negotiations: any[]=[];
  tempnegotiations: any[]=[];
  conversion: any[]=[];
  tempconversion: any[]=[];
  closed: any[]=[];
  displayContracts=false;
  dropped: any[]=[];
  tempclosed: any[]=[];
  tempdropped: any[]=[];
  defaultImage = "https://www.freeiconspng.com/uploads/profile-icon-9.png"
  conversionRatio: any;
  totalAmount: number;
  totalLeads: any;
  totalDropped: any;
  totalConversion: any;
  users: any;
  startDate: any;
  endDate: any;
  totalData: any[]=[];
  totalClosure: any;
  selectedUser: any;
  displayContacts: boolean;
  contactList: any[]=[];
  
  selectedAdvancedUser: any;
  startDateAdvanced: any;
  endDateAdvanced: any;
  contracts: any[]=[];
  availableQuote: any;
  availableContract: any[]=[];
  contractArray: any[];
  contractsArray: any[]=[];
  initialvalue: any;
  conversionRate: any;
  QuoteList: any[];
  DivisionList: any[]=[];
  

  constructor(
    private opportunitiesService:OpportunitiesService,
    private route: ActivatedRoute,
    public dialogService: DialogService,
    public messageService: MessageService,
    private sanitizer: DomSanitizer,
    private eventService: EventService,
    private userService:UserService,
    private qualificationService: QualificationsService
   
  ) { }

  ngOnInit(): void {
    this.closestNumbers()
    this.getOpportunityData();
    this.getAllUsers();
    //this.getAllQuote();
    this.getAllDevisions();
   
  }

  getAllUsers(){
    this.userService.allUser()
    .subscribe(async(data:any)=>{
      if(data.responseCode == "00"){
        this.users = data.responseData;
        this.users = this.users.map((res: any) => {
          return {
            ...res,
            displayLabel2: res.firstName + ' ' + res.lastName
          };
        });
      }
    })
  }

  transform(image) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(image);
  }

  calculateCommissionRate(){

  }


  filterByDateRange(){
    if(this.startDate > this.endDate){
      this.messageService.add({
        severity: "error",
        summary: "Notice",
        detail: "Please fix your selection,start date cannot be greater than end date",
      });
    }
    else{
      this.initiateColumn = this.tempInitiateColumn
      this.dealCapture = this.tempdealCapture
      this.negotiations = this.tempnegotiations
      this.conversion = this.tempconversion
      this.closed = this.tempclosed
      this.dropped = this.tempdropped
      this.totalLeads = this.totalData.length;
      this.totalDropped = this.dropped.length;
      this.totalClosure = this.closed.length;  
      this.conversionRate = this.getRate(this.closed,this.totalLeads);
      this.messageService.add({
        severity: "info",
        summary: "Notice",
        detail: `Retrieving Qualifications created created between ${this.startDate} and ${this.endDate}`,
      });
      var start = new Date(this.startDate).getTime();
      var end = new Date(this.endDate).getTime();
      this.initiateColumn = this.initiateColumn.filter(function (el){return new Date(el.createdAt).getTime()>= start && new Date(el.createdAt).getTime() <= end});
      this.dealCapture = this.dealCapture.filter(function (el){return new Date(el.createdAt).getTime() >= start && new Date(el.createdAt).getTime() <= end});
      this.negotiations = this.negotiations.filter(function (el){return new Date(el.createdAt).getTime() >= start && new Date(el.createdAt).getTime() <= end});
      this.conversion = this.conversion.filter(function (el){return new Date(el.createdAt).getTime() >= start && new Date(el.createdAt).getTime() <= end});
      this.closed = this.closed.filter(function (el){return new Date(el.createdAt).getTime() >= start && new Date(el.createdAt).getTime() <= end});
      this.dropped = this.dropped.filter(function (el){return new Date(el.createdAt).getTime() >= start && new Date(el.createdAt).getTime() <= end});
      this.totalDropped = this.dropped.length;
      this.totalLeads = this.conversion.length + this.dropped.length + this.closed.length + this.dealCapture.length + this.initiateColumn.length + this.negotiations.length;
      this.totalClosure = this.closed.length;
      this.conversionRate = this.getRate(this.closed,this.totalLeads);
   
    }
    
  }


  resetFilter(){
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Reseting filter to original dataset",
    });
    this.initiateColumn = this.tempInitiateColumn
    this.dealCapture = this.tempdealCapture
    this.negotiations = this.tempnegotiations
    this.conversion = this.tempconversion
    this.closed = this.tempclosed
    this.dropped = this.tempdropped
    this.totalLeads = this.totalData.length;
    this.totalDropped = this.dropped.length;
    this.totalClosure = this.closed.length;
    this.conversionRatio = Math.round((100 * this.closed.length) / this.totalData.length);
  }


  filterByUser(event){
    this.startDate = null;
    this.endDate = null;
    this.initiateColumn = this.tempInitiateColumn
    this.dealCapture = this.tempdealCapture
    this.negotiations = this.tempnegotiations
    this.conversion = this.tempconversion
    this.closed = this.tempclosed
    this.dropped = this.tempdropped
    this.totalLeads = this.totalData.length;
    this.totalDropped = this.dropped.length;
    this.totalClosure = this.closed.length;
    this.conversionRatio = Math.round((100 * this.closed.length) / this.totalLeads)
    this.conversionRate =this.getRate(this.closed,this.totalLeads);
    console.log("conversion length",this.conversionRatio);
    console.log("conversion length",this.conversionRate);
    console.log("closed length",this.closed);
    console.log("totalLeads length",this.totalLeads);

    console.log("this is the evnt",event)
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: `Retrieving Qualifications created by ${event.firstName} ${event.lastName}`,
    });

    this.initiateColumn = this.initiateColumn.filter(function (el){return el.createdById == event.id});
    this.dealCapture = this.dealCapture.filter(function (el){return el.createdById == event.id});
    this.negotiations = this.negotiations.filter(function (el){return el.createdById == event.id});
    this.conversion = this.conversion.filter(function (el){return el.createdById == event.id});
    this.closed = this.closed.filter(function (el){return el.createdById == event.id});
    this.dropped = this.dropped.filter(function (el){return el.createdById == event.id});
    this.totalLeads = this.conversion.length + this.dropped.length + this.closed.length + this.dealCapture.length + this.initiateColumn.length + this.negotiations.length;
    console.log("this is important ", this.totalLeads);
    this.totalDropped = this.dropped.length;
    this.totalClosure = this.closed.length;
    this.conversionRate = this.getRate(this.closed,this.totalLeads);
    console.log("conversion length",this.conversionRatio);
    console.log("conversion length",this.conversionRate);
    console.log("closed length",this.closed.length);
    console.log("totalLeads length",this.totalLeads);
    
  }


  getRate(closed:any,total:any):Number{
    var rate =  Math.round((100 * this.closed.length) / this.totalLeads);
    console.log('calculating rate at this junction ',rate)
    if(isNaN(rate)){
      return 0;
    }
    else{
      return rate
    }
  }

  getOpportunityData(){
    this.workspaceIsLoading = true;
    this.opportunitiesService.getOpportunityData()
    .subscribe(async(data:any)=>{
      if(data.responseCode == "00"){
        this.closestNumbers();
        this.workspaceIsLoading = false;
        this.messageService.add({
          severity: "success",
          summary: "Notice",
          detail: "All Qualification data was fectched successsfully",
        });
         console.log("This is the response from backend",data.responseData);
          this.totalData = data.responseData;

          this.totalData.forEach(lead => {

            if(lead.leadCaptureStatus == false 
              && lead.leadQualificationStatus == false
              && lead.leadOpportunityStatus == false
              && lead.leadConversionStatus == false
              && lead.leadClosureStatus == false
              && lead.isLeadDropped == false){
              this.tempInitiateColumn.push(lead);
            }

            if(lead.leadCaptureStatus == true 
              && lead.leadQualificationStatus == false
              && lead.leadOpportunityStatus == false
              && lead.leadConversionStatus == false
              && lead.leadClosureStatus == false
              && lead.isLeadDropped == false){
              this.tempdealCapture.push(lead);
            }

            if(lead.leadCaptureStatus == true 
              && lead.leadQualificationStatus == true
              && lead.leadOpportunityStatus == false
              && lead.leadConversionStatus == false
              && lead.leadClosureStatus == false
              && lead.isLeadDropped == false){
              this.tempnegotiations.push(lead);
            }
            if(lead.leadCaptureStatus == true 
              && lead.leadQualificationStatus == true
              && lead.leadOpportunityStatus == true
              && lead.leadConversionStatus == false
              && lead.leadClosureStatus == false
              && lead.isLeadDropped == false){
                this.tempconversion.push(lead);
              }
              if(lead.leadCaptureStatus == true 
                && lead.leadQualificationStatus == true
                && lead.leadOpportunityStatus == true
                && lead.leadConversionStatus == true 
                || lead.leadConversionStatus == false
                && lead.leadClosureStatus == true 
                && lead.isLeadDropped == false)
              {
                this.tempclosed.push(lead);
              }
            if(lead.isLeadDropped == true)
              {
                this.tempdropped.push(lead);
              }
            
              
          });
          console.log("Initiate",this.tempInitiateColumn)
          console.log("DealCapture",this.tempdealCapture)
          console.log("Negotiation",this.tempnegotiations)
          console.log("Conversion",this.tempconversion)
          console.log("Closure",this.tempclosed)
          console.log("Dropped",this.tempdropped)

         //this.initiateColumn = data.responseData.initiate;
          this.initiateColumn = this.tempInitiateColumn;
          this.dealCapture = this.tempdealCapture;
          this.negotiations = this.tempnegotiations;
          this.conversion = this.tempconversion;
          this.closed = this.tempclosed;
          this.dropped = this.tempdropped;
          this.totalLeads = data.responseData.length;
          this.totalDropped = this.dropped.length;
          this.totalClosure = this.closed.length;
         
          this.conversionRatio = Math.round((100 * this.closed.length) / this.totalLeads)
          this.conversionRate = this.getRate(this.closed,this.totalLeads);
          console.log("conversion length",this.conversionRatio);
          console.log("conversion length",this.conversionRate);
          console.log("totalLeads length",this.initiateColumn)

          

      }
      else{
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail: "All Qualification data wasn't fectched successsfully",
        });
        this.workspaceIsLoading = false;
      }
    },error=>{
      this.messageService.add({
        severity: "error",
        summary: "Notice",
        detail: "All Qualification data wasn't fectched successsfully",
      });
      this.workspaceIsLoading = false;
    }
    )
  }

  checkPipelineStatus(lead):any{
    if(lead.leadCaptureStatus == true 
      && lead.leadQualificationStatus == true
      && lead.leadOpportunityStatus == true
      && lead.leadConversionStatus == false
      && lead.leadClosureStatus == true
      && lead.isLeadDropped == false){
        return false
      }
      else if(lead.leadCaptureStatus == true 
        && lead.leadQualificationStatus == true
        && lead.leadOpportunityStatus == true
        && lead.leadConversionStatus == true
        && lead.leadClosureStatus == true
        && lead.isLeadDropped == false){
          return true
        }
  }

  getAllDealWorth(lead):number{
      var totalDealWorth = 0
      this.DivisionList.forEach(division=>{
        if(division.suspectId == null || lead.suspectId == null){
          return totalDealWorth;
        }
        if(division.suspectId == lead.suspectId){
            division.quote.quoteServices.forEach(x=>{
              totalDealWorth = totalDealWorth + x.billableAmount
            })
        }
        return totalDealWorth;
      })
      return totalDealWorth;
  }

  searchAdvanced(){
    if(this.selectedAdvancedUser == null || this.startDateAdvanced == null || this.endDateAdvanced == null){
      this.messageService.add({
        severity: "error", 
        summary: "Notice",
        detail: `You need to select a creator and a date range to successfully search advanced`,
      });
    }else{
      console.log("this is the selected user1",this.selectedAdvancedUser)
      this.initiateColumn = this.initiateColumn.filter(function (el){return el.leadCaptureStatus == false});
      this.dealCapture = this.dealCapture.filter(function (el){return el.leadCaptureStatus == true && el.leadOpportunityStatus == true});
      this.negotiations = this.negotiations.filter(function (el){return el.leadConversionStatus == true && el.leadOpportunityStatus == true && el.suspect != null});
      this.conversion = this.conversion.filter(function (el){return el.leadCaptureDocumentUrl != null && el.leadOpportunityStatus == true && el.leadOpportunityStatus == true && el.suspect != null});
      this.closed = this.closed.filter(function (el){return  el.leadOpportunityStatus == true && el.leadClosureStatus == true  && el.suspect != null});
      this.dropped = this.dropped.filter(function (el){return  el.leadOpportunityStatus == true && el.isLeadDropped == true  && el.suspect != null});
      this.totalLeads = this.totalData.length;
      this.totalDropped = this.dropped.length;
      this.totalClosure = this.closed.length;
      this.conversionRatio = Math.round((100 * this.closed.length) / this.totalData.length);
      this.messageService.add({
        severity: "info", 
        summary: "Notice",
        detail: `Retrieving Qualifications created by ${this.selectedAdvancedUser.firstName} ${this.selectedAdvancedUser.lastName}`,
      });
  
      var start = new Date(this.startDateAdvanced).getTime();
      var end = new Date(this.endDateAdvanced).getTime();
      var user = this.selectedAdvancedUser
      console.log("this is user",user);
      this.initiateColumn = this.initiateColumn.filter(function (el){return el.createdById == user.id && new Date(el.createdAt).getTime() >= start && new Date(el.createdAt).getTime() <= end });
      this.dealCapture = this.dealCapture.filter(function (el){return el.createdById == user.id && new Date(el.createdAt).getTime() >= start && new Date(el.createdAt).getTime() <= end });
      this.negotiations = this.negotiations.filter(function (el){return el.createdById == user.id && new Date(el.createdAt).getTime() >= start && new Date(el.createdAt).getTime() <= end });
      this.conversion = this.conversion.filter(function (el){return el.createdById == user.id && new Date(el.createdAt).getTime() >= start && new Date(el.createdAt).getTime() <= end });
      this.closed = this.closed.filter(function (el){return el.createdById == user.id && new Date(el.createdAt).getTime() >= start && new Date(el.createdAt).getTime() <= end });
      this.dropped = this.dropped.filter(function (el){return el.createdById == user.id && new Date(el.createdAt).getTime() >= start && new Date(el.createdAt).getTime() <= end });
      this.totalLeads = this.conversion.length + this.dropped.length + this.closed.length + this.dealCapture.length + this.initiateColumn.length + this.negotiations.length;
      this.totalDropped = this.dropped.length;
      this.totalClosure = this.closed.length;
      this.conversionRatio = Math.round((100 * this.closed.length) / this.totalLeads)

      }
    }

    getTotalBudget(response): number {
      var result = response.suspect
      if (result.suspectQualifications) {
        if (result.suspectQualifications.length !== 0) {
          var finalAmmount = 0;
          result.suspectQualifications[0].serviceQualifications.forEach(
            (service) => {
              var budget = service.budget * service.estimatedDurationInMonths
              finalAmmount = finalAmmount + budget;
            }
          );
          this.totalAmount = finalAmmount;
        } else {
          return;
        }
      } else {
        this.totalAmount = 0;
      }
      return this.totalAmount;
    }
  
    getAllQuote(){
      this.opportunitiesService.getQuote()
      .subscribe(async(data:any)=>{
        this.QuoteList = data.responseCode == "00" ? data.responseData : [];
        console.log("these are the quote",this.QuoteList);
      })
    }

    getAllDevisions(){
      this.opportunitiesService.getAllDevisions()
      .subscribe(async(data:any)=>{
        this.DivisionList = data.responseCode == "00" ? data.responseData : [];
        console.log("these are the division",this.DivisionList);
      })
    }
 
 

  openAModal(lead){
    this.leadInfoName = lead.businessName;
    this.workspaceIsLoading = true
    console.log("this is the value",lead);
    this.qualificationService.getAllContactsAttachedToSuspects(lead.suspectId)
    .subscribe(async(data:any)=>{
      if(data.responseCode == "00"){
        console.log("response from serve",data.responseData);
        this.contactList = data.responseData
        this.messageService.add({
          severity: "success",
          summary: "Notice",
          detail: "All Qualification data was fectched successsfully",
        });
        this.displayContacts = true;
        this.workspaceIsLoading = false
      }
      else{
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail: "All Contacts attached data wasn't fectched successsfully",
        });
        this.workspaceIsLoading = false
      }
    },error=>{
      this.messageService.add({
        severity: "error",
        summary: "Notice",
        detail: "An error occurred",
      });
      this.workspaceIsLoading = false
    }
    )
  }


  openContractsModal(value){
    console.log("this is value id", value);
    this.workspaceIsLoading = true;
    this.opportunitiesService.getLeadQouteById(value.id)
    .subscribe(async(data:any)=>{
      if(data.responseCode == "00"){
        this.initialvalue = data.responseData;
        console.log("response from getLeadQouteById",data.responseData);
        this.availableContract = data.responseData;
        console.log("this is our contract",this.availableContract);
        this.displayContracts = true;
        this.messageService.add({severity: "success",summary: "Notice",detail: "All Available Quote  was fectched successsfully",});
        this.workspaceIsLoading = false
      }
      else
      {
        this.displayContracts = true;
        this.messageService.add({ severity: "error",summary: "Notice",detail: "Qoute couldn't be fectched successsfully",});
        this.workspaceIsLoading = false
      }
    },error=>{
      this.messageService.add({
        severity: "error",
        summary: "Notice",
        detail: "An error occurred",
      });
      this.workspaceIsLoading = false
    }
    )
  }



  closestNumbers() {
    // Write your code here
    var numbers = [6,2,4,10]
    numbers.sort(function(a,b){
        return a-b
    })
    
    var max = numbers[1]-numbers[0];
    var maxPairs = [numbers[0],numbers[1]];
    
    for(var i = 2; i < numbers.length; i++){
        var  first = numbers[i-1];
        var second = numbers[i];
        var difference = second - first;
        
        if(difference < max){
            max = difference;
            maxPairs = [first,second];
            console.log('it starts here',maxPairs)
        }
            
        else if(difference == max){
             maxPairs = maxPairs.concat([first,second])
        }
           
    }
    
    
}

}
